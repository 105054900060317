
import Button from '@/components/controls/Common/Button.vue'
import StoresNamespaces from '@/store/namespaces'
import { IPart } from '@/types/BuildPlans/IBuildPlan'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { PartListItemViewModel } from '../addPart/types'

const commonStore = namespace(StoresNamespaces.Common)

@Component({
  components: {
    Button,
  },
})
export default class LockedBodyFunctionModal extends Vue {
  @commonStore.Getter tooltipOpenDelay: number

  @Prop({ required: true }) value: boolean
  @Prop({ required: true }) lockedParts: IPart[] | PartListItemViewModel[]

  tooltipOpen = false
  iconSize = 18
  nudgeLeft = 0

  $refs: {
    tooltip: HTMLElement
  }

  get updateFunctionMessage() {
    return this.$t('lockedBodyFunctionInfoDialog.updatedFunctionMessage', { lockedPartsCount: this.lockedParts.length })
  }

  get partsNameList() {
    return this.lockedParts.map((part) => part.name)
  }

  @Watch('tooltipOpen')
  onTooltipOpen() {
    // horizontally center tooltip relative to icon
    if (this.tooltipOpen) {
      const tooltip = this.$refs.tooltip.parentElement
      tooltip.style.display = 'block'
      this.nudgeLeft = tooltip.offsetWidth / 2 - this.iconSize / 2
      tooltip.style.display = 'none'
    }
  }

  /*
  LMB double-click – select the text string that is under the mouse
  cursor between two space characters
  */
  onDoubleClick() {
    const selection = document.getSelection()
    const offset = selection.focusOffset
    const selectedNodeText = selection.focusNode.textContent

    const previousSpaceCharacter = selectedNodeText.lastIndexOf(' ', offset)
    const rangeStart = previousSpaceCharacter !== -1 ? previousSpaceCharacter : 0
    const nextSpaceCharacter = selectedNodeText.indexOf(' ', offset)
    const rangeEnd = nextSpaceCharacter !== -1 ? nextSpaceCharacter : selectedNodeText.length

    const range = selection.getRangeAt(0)
    range.setStart(selection.focusNode, rangeStart)
    range.setEnd(selection.focusNode, rangeEnd)
  }

  /*
  LMB triple click – Shall select all the text strings that belong to the list item that
  was under the mouse cursor
  */
  onTripleClick(event: PointerEvent) {
    if (event.detail === 3) {
      const selection = document.getSelection()
      const range = selection.getRangeAt(0)
      range.selectNode(selection.anchorNode)
    }
  }

  onCloseClick() {
    this.$emit('onClose')
  }
}
