
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Emit, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Icon, { IconNames } from '@/components/icons/Icon.vue'

import StoresNamespaces from '@/store/namespaces'
import { DataTableHeaderCell } from '@/types/Common/DataTable'
import { SortOrders } from '@/types/SortModes'
import { SortParams, SortParamsKey, TableSortParams } from '@/types/FileExplorer/SortParamsKey'
import { DataType } from '@/types/Common/DataType'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: {
    Icon,
  },
})
export default class TableHeaderCell extends Vue {
  @fileExplorerStore.Action updateSort: (sortParams: { key: string; sort: SortParams }) => void
  @fileExplorerStore.Getter getSortParams: TableSortParams

  @Prop() header: DataTableHeaderCell
  @Prop() sortKey: SortParamsKey
  @Prop() disabled: boolean

  get isActive() {
    return !Object.keys(this.status).length || this.status.sortBy !== this.header.sortBy || this.disabled
  }

  get isAsc() {
    return this.status.sortOrder === SortOrders.Ascending && this.status.sortBy === this.header.sortBy
  }

  get status() {
    const { sortBy, sortOrder } = this.getSortParams[this.sortKey] || {}

    if (!sortBy || !sortOrder) {
      return {}
    }

    return { sortBy, sortOrder }
  }

  get nextSortOrder() {
    return this.status.sortBy === this.header.sortBy && this.isAsc ? SortOrders.Descending : SortOrders.Ascending
  }

  setSortStatus() {
    if (this.disabled) {
      return
    }

    this.updateSort({
      key: this.sortKey,
      sort: {
        sortBy: this.header.sortBy,
        sortOrder: this.nextSortOrder,
      },
    })
  }
}
