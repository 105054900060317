import { ItemType, ItemSubType } from './ItemType'
import { Permission, PermissionsMap } from './Permission'
import { ItemAction } from './ItemAction'

export interface PaginatedFileExplorerData {
  data: FileExplorerItem[]
  meta: {
    currentPage: number
    totalItems: number
    totalPages: number
  }
}

export interface FileExplorerItem {
  id?: string
  itemType: ItemType
  subType: ItemSubType
  path: string
  parentId: string
  name: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
  isRemoved: boolean
  isShared: boolean
  isLocked: boolean
  lockWhitelist: LockWhitelistElementDto[]
  sharedDate?: string
  size: string
  permissions?: Permission[]
  jobCount: number
  grantedPermissions?: PermissionsMap
  parentPathLabel?: string
  parentFolderId?: string
  isParentFolderRemoved?: string
  previewImageUrl?: string
  hasNotAvailableRelatedItems: boolean
  lastAction: ItemAction
  lastActionAt: Date
  lastActionBy: string
  version: string
  absoluteUpdatedAt: Date
  absoluteUpdatedBy: string
}

export interface IFolderDto {
  name: string
  parentId?: string
}

export interface AMPNavigationItem {
  name: string
  label: string
  mdi: string
  hasMarginBottom: boolean
  testAttribute: string
  divide?: boolean
}

export type FileExplorerItemSharedByUser = FileExplorerItem & { sharedDate: Date }

export enum ItemFormat {
  Minimal = 'minimal',
}

export type RenameItemPayload = {
  id: string
  name: string
}

export interface LockWhitelistElementDto {
  userId: string
  lockDate: Date
}
