import { Collector, CollectorItem } from '@/visualization/rendering/Collector'
import { RenderScene } from '@/visualization/render-scene'
import { SelectedItem } from '@/types/OptionalMultiItemCollector/SelectedItem'
import { ISelectableNode } from '@/visualization/rendering/SelectionManager'
import { CollectorWithOptionalFilter } from '@/types/OptionalMultiItemCollector/MultiItemCollector'
import { VisualizationEvent } from '@/visualization/infrastructure/IVisualizationEvent'
import { SelectionUnit } from '@/types/BuildPlans/IBuildPlan'

export class CollectorManager {
  private collectors: Collector[] = []
  private activeCollector: Collector = null
  private renderScene: RenderScene
  private setCollectorItemsEvent: VisualizationEvent<{ items: SelectedItem[] }> = new VisualizationEvent()
  private collectorFilters: Map<SelectionUnit, (item: CollectorItem) => boolean> = new Map()

  get getActiveCollector() {
    return this.activeCollector
  }

  get setCollectorItems() {
    return this.setCollectorItemsEvent.expose()
  }

  get getCollectorFilters() {
    return this.collectorFilters
  }

  get allCollectors() {
    return this.collectors
  }

  constructor(renderScene: RenderScene) {
    this.renderScene = renderScene
    this.collectorFilters.set(SelectionUnit.Part, this.crossCollectorPartFilter.bind(this))
    this.collectorFilters.set(SelectionUnit.Body, this.crossCollectorBodyFilter.bind(this))
    this.collectorFilters.set(SelectionUnit.FaceAndEdge, this.crossCollectorFaceFilter.bind(this))
  }

  createCollector(collectorData: CollectorWithOptionalFilter) {
    const collector = new Collector(collectorData, this.renderScene)
    this.collectors.push(collector)
    setTimeout(() => this.renderScene.animate(), 0)
  }

  setActiveCollector(id: string) {
    if (!id) {
      this.activeCollector = null
      return
    }

    const foundCollector = this.collectors.find((collector) => collector.id === id)
    if (foundCollector) {
      this.activeCollector = foundCollector
    }
  }

  hover(pickedObject: ISelectableNode = null) {
    this.activeCollector.highlight(pickedObject ? [pickedObject] : [])
  }

  pick(pickedObject: ISelectableNode, attach: boolean = false) {
    if (pickedObject) {
      this.activeCollector.select([pickedObject], attach)
    }
  }

  deselectLastCollectorItem(collectorId: string) {
    const foundCollector = this.collectors.find((collector) => collector.id === collectorId)
    if (foundCollector) {
      foundCollector.items[foundCollector.items.length - 1].deselect()
      foundCollector.items = foundCollector.items.slice(0, foundCollector.items.length - 1)
      setTimeout(() => this.renderScene.animate(), 0)
    }
  }

  deselectAllCollectorItems(collectorId: string) {
    const foundCollector = this.collectors.find((collector) => collector.id === collectorId)
    if (foundCollector) {
      foundCollector.deselect(null, true)
      setTimeout(() => this.renderScene.animate(), 0)
    }
  }

  disableColoringForCollector(collectorId: string) {
    const foundCollector = this.collectors.find((collector) => collector.id === collectorId)
    if (foundCollector) {
      foundCollector.disableColoring()
      setTimeout(() => this.renderScene.animate(), 0)
    }
  }

  enableColoringForCollector(collectorId: string) {
    const foundCollector = this.collectors.find((collector) => collector.id === collectorId)
    if (foundCollector) {
      foundCollector.enableColoring()
      setTimeout(() => this.renderScene.animate(), 0)
    }
  }

  disposeCollectors() {
    this.collectors.forEach((collector) => {
      collector.dispose()
    })

    this.collectors = []
    setTimeout(() => this.renderScene.animate(), 0)
  }

  private getItemsFromNotActiveCollector() {
    const parts = []
    const bodies = []
    const faces = []

    this.collectors.forEach((collector) => {
      if (collector.id === this.activeCollector.id) {
        return
      }

      if (collector.type === SelectionUnit.Part) {
        parts.push(...collector.items)
      }

      if (collector.type === SelectionUnit.Body) {
        bodies.push(...collector.items)
      }

      if (collector.type === SelectionUnit.FaceAndEdge) {
        faces.push(...collector.items)
      }
    })

    return { parts, bodies, faces }
  }

  private crossCollectorFaceFilter(item: CollectorItem) {
    const { parts, bodies, faces } = this.getItemsFromNotActiveCollector()
    if (parts.find((part) => part.itemMetadata.buildPlanItemId === item.itemMetadata.buildPlanItemId)) {
      return false
    }

    if (
      bodies.find(
        (body) =>
          body.itemMetadata.buildPlanItemId === item.itemMetadata.buildPlanItemId &&
          body.itemMetadata.componentId === item.itemMetadata.componentId &&
          body.itemMetadata.geometryId === item.itemMetadata.geometryId,
      )
    ) {
      return false
    }

    if (
      faces.find(
        (face) =>
          face.itemMetadata.buildPlanItemId === item.itemMetadata.buildPlanItemId &&
          face.itemMetadata.componentId === item.itemMetadata.componentId &&
          face.itemMetadata.geometryId === item.itemMetadata.geometryId &&
          face.itemMetadata.faceName === item.itemMetadata.faceName,
      )
    ) {
      return false
    }

    return true
  }

  private crossCollectorBodyFilter(item: CollectorItem) {
    const { parts, bodies, faces } = this.getItemsFromNotActiveCollector()
    if (parts.find((part) => part.itemMetadata.buildPlanItemId === item.itemMetadata.buildPlanItemId)) {
      return false
    }

    const itemsToBodyCheck = [...bodies, ...faces]

    if (
      itemsToBodyCheck.find(
        (itemToBodyCheck) =>
          itemToBodyCheck.itemMetadata.buildPlanItemId === item.itemMetadata.buildPlanItemId &&
          itemToBodyCheck.itemMetadata.componentId === item.itemMetadata.componentId &&
          itemToBodyCheck.itemMetadata.geometryId === item.itemMetadata.geometryId,
      )
    ) {
      return false
    }

    return true
  }

  private crossCollectorPartFilter(item: CollectorItem) {
    const { parts, bodies, faces } = this.getItemsFromNotActiveCollector()
    const itemsToPartCheck = [...parts, ...bodies, ...faces]
    if (
      itemsToPartCheck.find(
        (itemToPartCheck) => itemToPartCheck.itemMetadata.buildPlanItemId === item.itemMetadata.buildPlanItemId,
      )
    ) {
      return false
    }

    return true
  }
}
