
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import SpinButtons from '@/components/controls/Common/SpinButtons.vue'
import NumberField from '../Common/NumberField.vue'

@Component({
  components: {
    NumberField,
    SpinButtons,
  },
})
export default class MultiSelectNumberField extends Vue {
  @Prop({ default: false }) disabled: boolean
  @Prop() suffix!: string
  @Prop() min!: number
  @Prop() max!: number
  @Prop() placeholder!: string
  @Prop({ type: [Number, String, null] }) value: number | string | null
  @Prop({ type: [Object, String], default: '' }) readonly rules: object | string
  @Prop({ type: [Object, null], default: null, required: false }) readonly customMessages: object | null
  @Prop() step!: number | string
  @Prop({ default: false }) showValidationErrorsInTooltip: boolean
  @Prop({ default: false }) showSpinButtons: boolean
  @Prop({ default: false }) hideDetails: boolean
  @Prop({ type: Number, default: 0 }) floatLimit!: number
  @Prop({ default: false }) toFixed: boolean // requires to have floatLimit value

  onInput(val) {
    this.$emit('input', val)
  }

  onChange(val) {
    this.$emit('change', val)
  }

  upSpinClick() {
    this.$emit('multipleChange', this.step)
  }

  downSpinClick() {
    this.$emit('multipleChange', -this.step)
  }

  get isShowMultiple() {
    return this.value === null
  }
}
