/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { AbstractMesh, TransformNode } from '@babylonjs/core/Meshes'
import { Angle, Axis, Matrix, Quaternion, Space, Vector3 } from '@babylonjs/core/Maths'
import { VisualizationEvent } from '@/visualization/infrastructure/IVisualizationEvent'
import { SelectionManager } from '@/visualization/rendering/SelectionManager'
import { IActiveToggle } from '@/visualization/infrastructure/IActiveToggle'
import { RenderScene } from '@/visualization/render-scene'
import {
  SINTER_PLAN_CONSTRAINTS,
  BUILD_PLAN_CONSTRAINTS,
  PARAMETER_SET_SCALE_NAME,
  SINTER_PART_ROTATION_INCREMENT,
} from '@/constants'
import { IConstraints } from '@/types/BuildPlans/IConstraints'
import { CustomGizmo } from './CustomGizmo'
import ITransformationDelta from '@/types/BuildPlans/ITransformationDelta'
import { ToolTypes } from '@/types/BuildPlans/ToolTypes'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { OnDragTransformation } from '@/types/UndoRedo/OnDragTransformation'
import { RestoreSelectedPartsType } from '@/types/BuildPlans/RestoreSelectedPartsType'
import { MoveViewMode, RotateViewMode } from '@/visualization/infrastructure/ViewMode'
import { IBuildPlanItem, PartTypes } from '@/types/BuildPlans/IBuildPlan'
import { IPartMetadata } from '@/visualization/types/SceneItemMetadata'
import { IBuildPlanItemTransformationData } from '@/types/IBuildPlanItemTransformationData'
import { IBuildPlanItemsTransformationData } from '@/types/IBuildPlanItemsTransformationData'
import { PrintingTypes } from '@/types/IMachineConfig'
import { PointerEventTypes } from '@babylonjs/core/Events/pointerEvents'
import { RotationGizmo } from '@babylonjs/core/Gizmos/rotationGizmo'
import { PositionGizmo } from '@babylonjs/core/Gizmos/positionGizmo'
import store from '@/store'
import { eventBus } from '@/services/EventBus'
import { BuildPlanEvents } from '@/types/Label/BuildPlanEvents'

export class Gizmo extends CustomGizmo {
  public rotatePartsIndependentlyMode = false

  private readonly onTransformationChange = new VisualizationEvent<IBuildPlanItemTransformationData>()
  private readonly onTransformationChangeBatch = new VisualizationEvent<IBuildPlanItemsTransformationData>()
  private readonly onGenerateOverhangMeshDebounced = new VisualizationEvent<{
    buildPlanItemId: string
    transformation: number[]
  }>()

  private readonly onDeleteOverhangsAndSupportsEvent = new VisualizationEvent<string>()
  private readonly onPartElevateEvent = new VisualizationEvent<{ buildPlanItemId: string; transformation: number[] }>()
  private selectionManager: SelectionManager
  private selectedMesh: AbstractMesh

  private axisOffset: number
  private isGizmosEnabled = false
  private constraints: IConstraints
  private collisionCallback: Function

  private beforeDragStartTransformation: OnDragTransformation[]
  private afterDragEndTransformation: OnDragTransformation[]
  private restoreOption: RestoreSelectedPartsType

  // The following flag indicates that we need rebuild supports
  // for the certain translation or rotation plain
  // when in the Supports Tool was pressed Undo/Redo button
  private forceSupportsUpdate: boolean = false
  private supportsRemoved: boolean = false

  private canvas: HTMLCanvasElement

  private startX: number = 0
  private startY: number = 0
  private startZ: number = 0

  constructor(
    renderScene: RenderScene,
    selectionManager: SelectionManager,
    dragListeners: IActiveToggle[],
    collisionCallback: Function,
  ) {
    super(renderScene, dragListeners)
    this.gizmoManager.usePointerToAttachGizmos = false
    this.renderScene = renderScene
    this.selectionManager = selectionManager
    this.isGizmosEnabled = true
    this.collisionCallback = collisionCallback
    this.canvas = renderScene.visualizationCanvas

    // Gizmos must be enabled before initialization because gizmos are initially null
    this.enableRotationGizmo()
    this.enableTranslationGizmo()

    const rotationGizmo = this.gizmoManager.gizmos.rotationGizmo as RotationGizmo
    rotationGizmo.updateGizmoRotationToMatchAttachedMesh = false
    this.setCustomRotationGizmo(rotationGizmo)

    const positionGizmo = this.gizmoManager.gizmos.positionGizmo as PositionGizmo
    positionGizmo.updateGizmoRotationToMatchAttachedMesh = false
    positionGizmo.planarGizmoEnabled = true
    this.setCustomDragGizmo(positionGizmo)

    this.disableRotationGizmos()
    this.disableTranslationGizmos()

    this.gizmoManager.utilityLayer.utilityLayerScene.onBeforeRenderObservable.add(() => {
      const activeCamera = this.gizmoManager.utilityLayer.utilityLayerScene.activeCamera
      if (positionGizmo && rotationGizmo) {
        this.updateCustomGizmoOrientation(activeCamera.position.x, activeCamera.position.y)
        this.updateGizmoAxesVisible()
      }
    })
    this.gizmoManager.utilityLayer.utilityLayerScene.onPointerObservable.add((eventData) => {
      if (eventData.type === PointerEventTypes.POINTERUP) {
        setTimeout(() => this.renderScene.animate(), 0)
      }
    })

    this.attachCollisionCheckToDragObservable()

    ////////////////////////////////
    // position gizmo events
    ////////////////////////////////

    // Generic gizmo

    this.gizmoManager.gizmos.positionGizmo.onDragStartObservable.add(() => {
      this.saveTransformationBeforeDragStart(this.selectedMesh)

      this.translateLabelsOrientation(this.selectedMesh.getChildMeshes(), false)
      this.isInDraggingState = true
      this.setMaterialOpacity(this.movementOpacity)
      this.dragListeners.map((listener) => listener.deactivate())
    })

    this.gizmoManager.gizmos.positionGizmo.onDragEndObservable.add(() => {
      this.isInDraggingState = false
      this.setMaterialOpacity(this.regularOpacity)
      this.selectedMesh.computeWorldMatrix(true)
      this.selectedMesh.getChildMeshes(true).forEach((mesh) => mesh.computeWorldMatrix(true))
      this.selectedMesh.getChildTransformNodes(true).forEach((node) => node.computeWorldMatrix(true))
      this.onDragEndTransformation(this.selectedMesh, this.supportsRemoved)
      this.dragListeners.map((listener) => listener.activate())
    })

    // Axis gizmos

    // X

    this.gizmoManager.gizmos.positionGizmo.xGizmo.dragBehavior.onDragStartObservable.add(() => {
      this.togglePositionSnapping(this.gizmoManager.gizmos.positionGizmo.xGizmo)
    })

    // Y

    this.gizmoManager.gizmos.positionGizmo.yGizmo.dragBehavior.onDragStartObservable.add(() => {
      this.togglePositionSnapping(this.gizmoManager.gizmos.positionGizmo.yGizmo)
    })

    // Z

    this.gizmoManager.gizmos.positionGizmo.zGizmo.dragBehavior.onDragStartObservable.add(() => {
      this.togglePositionSnapping(this.gizmoManager.gizmos.positionGizmo.zGizmo)
      this.forceSupportsUpdate = true
      const hasSupports = this.selectedMesh.getChildTransformNodes().find((m) => this.meshManager.isSupportMesh(m))
      if (hasSupports) {
        this.removeOverhangsAndSupportsFromScene()
        this.supportsRemoved = true
      }
    })

    this.gizmoManager.gizmos.positionGizmo.zGizmo.dragBehavior.onDragObservable.add(() => {
      this.limitTranslationGizmo(this.selectedMesh)
    })

    this.gizmoManager.gizmos.positionGizmo.zGizmo.dragBehavior.onDragEndObservable.add(() => {
      this.updateOverhangMeshes(this.selectedMesh)
    })

    // Plane gizmos

    // X

    this.gizmoManager.gizmos.positionGizmo.xPlaneGizmo.dragBehavior.onDragStartObservable.add(() => {
      const attachedMesh = this.gizmoManager.gizmos.positionGizmo.zPlaneGizmo.attachedMesh
      this.startY = attachedMesh.position.y % this.renderScene.buildPlanMoveIncrement
      this.startZ = attachedMesh.position.z % this.renderScene.buildPlanMoveIncrement
      this.forceSupportsUpdate = true
      const hasSupports = this.selectedMesh.getChildTransformNodes().find(this.meshManager.isSupportMesh)
      if (hasSupports) {
        this.removeOverhangsAndSupportsFromScene()
        this.supportsRemoved = true
      }
    })

    this.gizmoManager.gizmos.positionGizmo.xPlaneGizmo.dragBehavior.onDragObservable.add((param) => {
      const attachedMesh = this.gizmoManager.gizmos.positionGizmo.xPlaneGizmo.attachedMesh
      if (this.renderScene.shiftKey) {
        attachedMesh.position.y =
          this.startY +
          Math.round(param.dragPlanePoint.y / this.renderScene.buildPlanMoveIncrement) *
            this.renderScene.buildPlanMoveIncrement
        attachedMesh.position.z =
          this.startZ +
          Math.round(param.dragPlanePoint.z / this.renderScene.buildPlanMoveIncrement) *
            this.renderScene.buildPlanMoveIncrement
      }
      this.limitTranslationGizmo(this.selectedMesh)
    })

    this.gizmoManager.gizmos.positionGizmo.xPlaneGizmo.dragBehavior.onDragEndObservable.add(() => {
      this.startY = 0
      this.startZ = 0
      this.updateOverhangMeshes(this.selectedMesh)
    })

    // Y

    this.gizmoManager.gizmos.positionGizmo.yPlaneGizmo.dragBehavior.onDragStartObservable.add(() => {
      const attachedMesh = this.gizmoManager.gizmos.positionGizmo.zPlaneGizmo.attachedMesh
      this.startX = attachedMesh.position.x % this.renderScene.buildPlanMoveIncrement
      this.startZ = attachedMesh.position.z % this.renderScene.buildPlanMoveIncrement
      this.forceSupportsUpdate = true
      const hasSupports = this.selectedMesh.getChildTransformNodes().find(this.meshManager.isSupportMesh)
      if (hasSupports) {
        this.removeOverhangsAndSupportsFromScene()
        this.supportsRemoved = true
      }
    })

    this.gizmoManager.gizmos.positionGizmo.yPlaneGizmo.dragBehavior.onDragObservable.add((param) => {
      const attachedMesh = this.gizmoManager.gizmos.positionGizmo.yPlaneGizmo.attachedMesh
      if (this.renderScene.shiftKey) {
        attachedMesh.position.x =
          this.startX +
          Math.round(param.dragPlanePoint.x / this.renderScene.buildPlanMoveIncrement) *
            this.renderScene.buildPlanMoveIncrement
        attachedMesh.position.z =
          this.startZ +
          Math.round(param.dragPlanePoint.z / this.renderScene.buildPlanMoveIncrement) *
            this.renderScene.buildPlanMoveIncrement
      }
      this.limitTranslationGizmo(this.selectedMesh)
    })

    this.gizmoManager.gizmos.positionGizmo.yPlaneGizmo.dragBehavior.onDragEndObservable.add(() => {
      this.startX = 0
      this.startZ = 0
      this.updateOverhangMeshes(this.selectedMesh)
    })

    // Z

    this.gizmoManager.gizmos.positionGizmo.zPlaneGizmo.dragBehavior.onDragStartObservable.add(() => {
      const attachedMesh = this.gizmoManager.gizmos.positionGizmo.zPlaneGizmo.attachedMesh
      this.startX = attachedMesh.position.x % this.renderScene.buildPlanMoveIncrement
      this.startY = attachedMesh.position.y % this.renderScene.buildPlanMoveIncrement
    })

    this.gizmoManager.gizmos.positionGizmo.zPlaneGizmo.dragBehavior.onDragObservable.add((param) => {
      const attachedMesh = this.gizmoManager.gizmos.positionGizmo.zPlaneGizmo.attachedMesh
      if (this.renderScene.shiftKey) {
        attachedMesh.position.x =
          this.startX +
          Math.trunc(param.dragPlanePoint.x / this.renderScene.buildPlanMoveIncrement) *
            this.renderScene.buildPlanMoveIncrement
        attachedMesh.position.y =
          this.startY +
          Math.trunc(param.dragPlanePoint.y / this.renderScene.buildPlanMoveIncrement) *
            this.renderScene.buildPlanMoveIncrement
      }
      attachedMesh.computeWorldMatrix(true)
      attachedMesh.getChildMeshes(true).forEach((mesh) => mesh.computeWorldMatrix(true))
    })

    this.gizmoManager.gizmos.positionGizmo.zPlaneGizmo.dragBehavior.onDragEndObservable.add(() => {
      this.startX = 0
      this.startY = 0
    })

    ////////////////////////////////
    // rotation gizmo events
    ////////////////////////////////

    this.gizmoManager.gizmos.rotationGizmo.onDragStartObservable.add(() => {
      this.isInDraggingState = true
      this.translateLabelsOrientation(this.selectedMesh.getChildMeshes(), false)

      // Remove parameterSet scale
      const parts = this.selectedMesh.getChildTransformNodes(false, this.meshManager.isPartMesh)
      this.detachScaleNodes(parts)
      this.saveTransformationBeforeDragStart(this.selectedMesh)

      this.setMaterialOpacity(this.movementOpacity)
      this.dragListeners.map((listener) => listener.deactivate())
    })

    this.gizmoManager.gizmos.rotationGizmo.onDragEndObservable.add(() => {
      // Add parameterSet scale
      const parts = this.selectedMesh.getChildTransformNodes(true)
      this.attachScaleNodes(parts)

      this.onRotationEnd(this.selectedMesh)
      this.updateOverhangMeshes(this.selectedMesh)
    })

    this.gizmoManager.gizmos.rotationGizmo.onDragStartObservable.add(() => {
      this.forceSupportsUpdate = true
      const hasSupports = this.selectedMesh.getChildTransformNodes().find(this.meshManager.isSupportMesh)
      if (hasSupports) {
        this.removeOverhangsAndSupportsFromScene()
        this.supportsRemoved = true
      }
    })

    this.gizmoManager.gizmos.rotationGizmo.xGizmo.dragBehavior.onDragStartObservable.add(() => {
      this.toggleRotationSnapping(this.gizmoManager.gizmos.rotationGizmo.xGizmo)
    })

    this.gizmoManager.gizmos.rotationGizmo.yGizmo.dragBehavior.onDragStartObservable.add(() => {
      this.toggleRotationSnapping(this.gizmoManager.gizmos.rotationGizmo.yGizmo)
    })

    this.gizmoManager.gizmos.rotationGizmo.zGizmo.dragBehavior.onDragStartObservable.add(() => {
      this.toggleRotationSnapping(this.gizmoManager.gizmos.rotationGizmo.zGizmo)
    })

    this.gizmoManager.gizmos.rotationGizmo.xGizmo.dragBehavior.onDragObservable.add(() => {
      this.toggleRotationSnapping(this.gizmoManager.gizmos.rotationGizmo.xGizmo)
    })

    this.gizmoManager.gizmos.rotationGizmo.yGizmo.dragBehavior.onDragObservable.add(() => {
      this.toggleRotationSnapping(this.gizmoManager.gizmos.rotationGizmo.yGizmo)
    })

    this.gizmoManager.gizmos.rotationGizmo.zGizmo.dragBehavior.onDragObservable.add(() => {
      this.toggleRotationSnapping(this.gizmoManager.gizmos.rotationGizmo.zGizmo)
    })

    this.gizmoManager.gizmos.rotationGizmo.xGizmo.dragBehavior.onDragEndObservable.add(() => {
      this.limitRotationGizmo(this.selectedMesh.id, 'x')
    })

    this.gizmoManager.gizmos.rotationGizmo.yGizmo.dragBehavior.onDragEndObservable.add(() => {
      this.limitRotationGizmo(this.selectedMesh.id, 'y')
    })

    this.gizmoManager.gizmos.rotationGizmo.zGizmo.dragBehavior.onDragEndObservable.add(() => {
      this.limitRotationGizmo(this.selectedMesh.id, 'z')
    })

    // snapping behavior
    this.canvas.onblur = () => {
      this.renderScene.shiftKey = false
      this.gizmoManager.gizmos.positionGizmo.xGizmo.snapDistance = 0
      this.gizmoManager.gizmos.positionGizmo.yGizmo.snapDistance = 0
      this.gizmoManager.gizmos.positionGizmo.zGizmo.snapDistance = 0
      this.gizmoManager.gizmos.positionGizmo.xPlaneGizmo.snapDistance = 0
      this.gizmoManager.gizmos.positionGizmo.yPlaneGizmo.snapDistance = 0
      this.gizmoManager.gizmos.positionGizmo.zPlaneGizmo.snapDistance = 0
      this.gizmoManager.gizmos.rotationGizmo.xGizmo.snapDistance = 0
      this.gizmoManager.gizmos.rotationGizmo.yGizmo.snapDistance = 0
      this.gizmoManager.gizmos.rotationGizmo.zGizmo.snapDistance = 0
    }
  }

  get transformationChange() {
    return this.onTransformationChange.expose()
  }

  get transformationChangeBatch() {
    return this.onTransformationChangeBatch.expose()
  }

  get generateOverhangMeshEventDebounced() {
    return this.onGenerateOverhangMeshDebounced.expose()
  }

  get deleteOverhangsAndSupportsEvent() {
    return this.onDeleteOverhangsAndSupportsEvent.expose()
  }

  get isDragging() {
    return this.isInDraggingState
  }

  get onPartElevate() {
    return this.onPartElevateEvent.expose()
  }

  get isEnabled() {
    return this.isGizmosEnabled
  }

  set isEnabled(value) {
    this.isGizmosEnabled = value
  }

  enableRotationGizmo() {
    this.gizmoManager.rotationGizmoEnabled = true
    const rotationGizmo = this.gizmoManager.gizmos.rotationGizmo
    rotationGizmo.scaleRatio = this.computeGizmoScaleRatio()
  }

  enableTranslationGizmo() {
    this.gizmoManager.positionGizmoEnabled = true
    const positionGizmo = this.gizmoManager.gizmos.positionGizmo
    positionGizmo.scaleRatio = this.computeGizmoScaleRatio()
  }

  /** Pushes out the node if node under or intersect the build plate. */
  limitTranslationGizmo(node: TransformNode) {
    node.computeWorldMatrix(true)
    node.getChildTransformNodes().forEach((childNode) => childNode.computeWorldMatrix(true))
    const meshBBox = this.meshManager.getHullBInfo(node).boundingBox
    const absolutePosition = node.getAbsolutePosition()
    this.axisOffset = meshBBox.centerWorld.subtract(absolutePosition).z
    const groundBBox = this.renderScene.getBuildPlateManager().groundBox.getBoundingInfo().boundingBox
    const minPosition = groundBBox.maximumWorld.z + meshBBox.extendSizeWorld.z - this.axisOffset

    // limit mesh position only if it is getting into the build plate
    if (absolutePosition.z < minPosition) {
      absolutePosition.z = minPosition
      node.setAbsolutePosition(absolutePosition)
    }

    node.computeWorldMatrix(true)
    node.getChildTransformNodes().forEach((childNode) => childNode.computeWorldMatrix(true))
    // don't limit movement in Z+ direction past the build volume height
  }

  limitRotationGizmo(meshId: string, axis: string) {
    const mesh = this.scene.getMeshByID(meshId)
    mesh.computeWorldMatrix(true)
  }

  onRotationEnd(selected: AbstractMesh) {
    this.isInDraggingState = false
    this.setMaterialOpacity(this.regularOpacity)
    this.placeAboveGround(selected, this.renderScene.buildPlanType === ItemSubType.SinterPlan)
    this.onDragEndTransformation(selected, this.supportsRemoved)
    this.dragListeners.map((listener) => listener.activate())
    this.renderScene.getModelManager().triggerStabilityCheck()
    this.renderScene.getModelManager().triggerSafeDosingHeightCheck()
  }

  show(selected: AbstractMesh) {
    if (!this.isVisible || !this.isGizmosEnabled || !selected) {
      return
    }

    this.selectedMesh = selected
    this.recalculateConstraints()
    this.enableTranslationGizmo()
    this.enableRotationGizmo()
    this.gizmoManager.attachToMesh(selected)
    this.gizmoManager.utilityLayer.utilityLayerScene.render()
  }

  hide(silent?: boolean) {
    if (!silent && (this.gizmoManager.rotationGizmoEnabled || this.gizmoManager.positionGizmoEnabled)) {
      eventBus.$emit(BuildPlanEvents.HideGizmo, { silent: true })
    }

    this.selectedMesh = null
    this.gizmoManager.attachToMesh(null)
    this.renderScene.selectedElementsCollisions.trigger(null)
    this.disableRotationGizmos()
    this.disableTranslationGizmos()
    this.gizmoManager.utilityLayer.utilityLayerScene.render()
  }

  toggleVisibility(isVisible: boolean) {
    if (isVisible) {
      this.enableTranslationGizmo()
      this.enableRotationGizmo()
    } else {
      this.disableRotationGizmos()
      this.disableTranslationGizmos()
    }

    this.gizmoManager.utilityLayer.utilityLayerScene.render()
  }

  attachCollisionCheckToDragObservable() {
    const callback = () => {
      const selected = this.selectedMesh
        ? this.selectedMesh.getChildTransformNodes(false, this.meshManager.isPartMesh)
        : null
      if (selected) {
        this.collisionCallback(this.renderScene, selected)
      }
    }

    if (this.gizmoManager.gizmos.positionGizmo) {
      const positionGizmo = this.gizmoManager.gizmos.positionGizmo
      positionGizmo.xGizmo.dragBehavior.onDragObservable.add(callback)
      positionGizmo.yGizmo.dragBehavior.onDragObservable.add(callback)
      positionGizmo.zGizmo.dragBehavior.onDragObservable.add(callback)
      positionGizmo.xPlaneGizmo.dragBehavior.onDragObservable.add(callback)
      positionGizmo.yPlaneGizmo.dragBehavior.onDragObservable.add(callback)
      positionGizmo.zPlaneGizmo.dragBehavior.onDragObservable.add(callback)
    }

    if (this.gizmoManager.gizmos.rotationGizmo) {
      const rotationGizmo = this.gizmoManager.gizmos.rotationGizmo
      rotationGizmo.xGizmo.dragBehavior.onDragObservable.add(callback)
      rotationGizmo.yGizmo.dragBehavior.onDragObservable.add(callback)
      rotationGizmo.zGizmo.dragBehavior.onDragObservable.add(callback)
    }
  }

  dispose() {
    this.unregisterCanvasEvents()
    super.dispose()
  }

  async onDragEndTransformation(selected: AbstractMesh, updateGeometryProperties: boolean = true) {
    const children = selected.getChildTransformNodes(false, this.meshManager.isPartMesh)

    if (this.supportsRemoved) {
      this.supportsRemoved = false
      await this.deleteOverhangsAndSupports(selected, updateGeometryProperties || this.supportsRemoved)
    }

    this.afterDragEndTransformation = children.map((child) => this.getTransformationOnDragEvents(child))
    this.saveTransformationBatch(children, updateGeometryProperties)
  }

  async saveTransformation(mesh: TransformNode, updateStateOnly?: boolean) {
    const { transformationMatrix, buildPlanItemId } = this.getTransformationOnDragEvents(mesh)
    const translation = this.translateLabelsOfMesh(mesh)

    this.onTransformationChange.trigger({
      updateStateOnly,
      transformationMatrix,
      buildPlanItemId,
      translation,
      beforeDragStartTransformation: this.beforeDragStartTransformation,
      afterDragEndTransformation: this.afterDragEndTransformation,
      forceSupportsUpdate: this.forceSupportsUpdate,
    })

    // Set undefined if there was Gizmos drag event
    this.beforeDragStartTransformation = undefined
    this.afterDragEndTransformation = undefined
    this.forceSupportsUpdate = false
  }

  saveTransformationBatch(meshes: TransformNode[], updateGeometryProperties?: boolean) {
    const buildPlanItemsData = meshes.map((mesh: TransformNode) => {
      const { transformationMatrix, buildPlanItemId } = this.getTransformationOnDragEvents(mesh)
      const translation = this.translateLabelsOfMesh(mesh)

      return {
        transformationMatrix,
        buildPlanItemId,
        translation,
      }
    })

    const transformationData: IBuildPlanItemsTransformationData = {
      updateGeometryProperties,
      transformationData: buildPlanItemsData,
      afterDragEndTransformation: this.afterDragEndTransformation,
      beforeDragStartTransformation: this.beforeDragStartTransformation,
      forceSupportsUpdate: this.forceSupportsUpdate,
      restoreOption: this.restoreOption,
      updateStateOnly: false,
    }

    this.onTransformationChangeBatch.trigger(transformationData)

    this.beforeDragStartTransformation = undefined
    this.afterDragEndTransformation = undefined
    this.restoreOption = undefined
    this.forceSupportsUpdate = false
  }

  setBeforeActionTransformation(children: TransformNode[]) {
    this.beforeDragStartTransformation = children.map((child) => this.getTransformationOnDragEvents(child))
  }

  setAfterActionTransformation(children: TransformNode[]) {
    this.afterDragEndTransformation = children.map((child) => this.getTransformationOnDragEvents(child))
  }

  translateLabelsOfMesh(mesh: TransformNode) {
    mesh.metadata.hullBInfo = this.meshManager.getHullBInfo(mesh)
    const hullBBox = mesh.metadata.hullBInfo.boundingBox
    const translation = new Vector3(hullBBox.centerWorld.x, hullBBox.centerWorld.y, hullBBox.minimumWorld.z)
    this.translateLabelsOrientation(mesh.getChildMeshes(), true)

    return translation
  }

  updateOverhangMeshes(selected: AbstractMesh) {
    // bpId, transform
    const children = selected.getChildTransformNodes(false, this.meshManager.isPartMesh)
    children.forEach((child) => {
      const transformation = []
      child
        .getWorldMatrix()
        .transpose()
        .toArray()
        .forEach((item) => transformation.push(item))
      this.generateOverhangMeshEventDebounced.trigger({
        transformation,
        buildPlanItemId: child.metadata.buildPlanItemId,
      })
    })
  }

  removeOverhangMeshes(selected: AbstractMesh) {
    selected.getChildTransformNodes(false, this.meshManager.isPartMesh).forEach((mesh) => {
      this.renderScene.clearOverhangMesh(mesh.metadata.buildPlanItemId)
    })
  }

  removeSupportMeshes(selected: AbstractMesh, skipGeomProps?: boolean) {
    selected.getChildTransformNodes(false, this.meshManager.isPartMesh).forEach((mesh) => {
      this.renderScene.clearSupports(mesh.metadata.buildPlanItemId, null, skipGeomProps)
    })
  }

  placeAboveGround(mesh: TransformNode, ignoreMinZCheck?: boolean) {
    mesh.computeWorldMatrix(true)
    mesh.getChildMeshes().map((m) => m.computeWorldMatrix(true))
    mesh.getChildTransformNodes().forEach((tn) => tn.computeWorldMatrix(true))
    const meshBBox = this.meshManager.getHullBInfo(mesh).boundingBox
    this.axisOffset = meshBBox.centerWorld.subtract(mesh.position).z
    const groundBBox = this.renderScene.getBuildPlateManager().groundBox.getBoundingInfo().boundingBox
    const minPosition = groundBBox.maximumWorld.z + meshBBox.extendSizeWorld.z - this.axisOffset
    // place mesh on the build plate only if it got below the build plate
    if (ignoreMinZCheck || mesh.position.z < minPosition) {
      mesh.position.z = minPosition
      mesh.computeWorldMatrix(true)
      mesh.getChildTransformNodes().forEach((childNode) => childNode.computeWorldMatrix(true))
      mesh.getChildMeshes().forEach((child) => child.computeWorldMatrix(true))
    }
  }

  recalculateConstraints() {
    if (!this.selectedMesh) {
      return
    }

    const children = this.selectedMesh.getChildTransformNodes(false, this.meshManager.isPartMesh)
    let constraints: IConstraints
    if (this.renderScene.buildPlanType === ItemSubType.SinterPlan) {
      constraints = JSON.parse(JSON.stringify(SINTER_PLAN_CONSTRAINTS))
    } else {
      constraints = JSON.parse(JSON.stringify(BUILD_PLAN_CONSTRAINTS))
    }

    for (const child of children) {
      if (!child.metadata.constraints) continue
      constraints.rotation.x = child.metadata.constraints.rotation.x || constraints.rotation.x
      constraints.rotation.y = child.metadata.constraints.rotation.y || constraints.rotation.y
      constraints.rotation.z = child.metadata.constraints.rotation.z || constraints.rotation.z

      constraints.translation.x = child.metadata.constraints.translation.x || constraints.translation.x
      constraints.translation.y = child.metadata.constraints.translation.y || constraints.translation.y
      constraints.translation.z = child.metadata.constraints.translation.z || constraints.translation.z
    }

    this.constraints = constraints
    this.updateGizmoAxesVisible()
  }

  elevatePart(elevationValue: number) {
    const groupMesh = this.selectedMesh ? this.selectedMesh : this.selectionManager.getSelected(true)
    if (!groupMesh) {
      return
    }

    this.saveTransformationBeforeDragStart(groupMesh)
    this.translateLabelsOrientation(groupMesh.getChildMeshes(), false)

    const deltaMove = new Vector3(0, 0, elevationValue ? elevationValue : 0)
    if (deltaMove.z !== 0) {
      this.removeSupportMeshes(groupMesh)
    }

    groupMesh.position.addToRef(deltaMove, groupMesh.position)
    this.placeAboveGround(groupMesh, this.renderScene.buildPlanType === ItemSubType.SinterPlan)
    this.collisionCallback(this.renderScene, groupMesh.getChildTransformNodes(false, this.meshManager.isPartMesh))

    groupMesh.computeWorldMatrix(true)
    const children = groupMesh.getChildTransformNodes(false, this.meshManager.isPartMesh)
    children.forEach((child) => {
      child.computeWorldMatrix()
    })

    const bpItems = groupMesh.getChildTransformNodes(false, this.meshManager.isPartMesh)
    bpItems.forEach((child) => {
      const convertedTransformation = this.getTransformationOnDragEvents(child) as {
        transformationMatrix: number[]
        buildPlanItemId: string
      }

      this.onPartElevate.trigger({
        buildPlanItemId: convertedTransformation.buildPlanItemId,
        transformation: convertedTransformation.transformationMatrix,
      })
    })

    this.saveTransformationBatch(children, true)
    setTimeout(() => this.renderScene.animate(), 0)
  }

  async transformSelectedParts(delta: ITransformationDelta, type: ToolTypes) {
    const groupMesh = this.selectionManager.getSelected(true)
    if (!groupMesh) {
      return
    }

    this.saveTransformationBeforeDragStart(groupMesh)
    this.translateLabelsOrientation(groupMesh.getChildMeshes(), false)
    const hasSupports = (store.getters['buildPlans/getSelectedBuildPlanItems'] as IBuildPlanItem[]).some(
      (bpItem) => bpItem.supports && bpItem.supports.length,
    )
    let supportRemoved: boolean = false
    switch (type) {
      case ToolTypes.MoveTool:
        const deltaMove = new Vector3(delta.x ? delta.x : 0, delta.y ? delta.y : 0, delta.z ? delta.z : 0)
        if (deltaMove.z !== 0 && hasSupports) {
          await this.deleteOverhangsAndSupports(groupMesh, true)
          supportRemoved = true
        }

        groupMesh.position.addToRef(deltaMove, groupMesh.position)
        break
      case ToolTypes.RotateTool:
        if (hasSupports) {
          await this.deleteOverhangsAndSupports(groupMesh, true)
          supportRemoved = true
        }

        // Remove parameterSet scale
        const parts = groupMesh.getChildTransformNodes(false, this.meshManager.isPartMesh)
        this.detachScaleNodes(parts)

        if (this.rotatePartsIndependentlyMode) {
          for (const part of parts) {
            part.computeWorldMatrix(true)
            const xRad = Angle.FromDegrees(delta.x).radians()
            const yRad = Angle.FromDegrees(delta.y).radians()
            const zRad = Angle.FromDegrees(delta.z).radians()
            const parent = part.parent
            part.setParent(null)
            const centerBBox = (part.metadata as IPartMetadata).hullBInfo.boundingBox.centerWorld
            part.rotateAround(centerBBox, Axis.X, xRad)
            part.rotateAround(centerBBox, Axis.Y, yRad)
            part.rotateAround(centerBBox, Axis.Z, zRad)
            part.setParent(parent)
          }
        } else {
          groupMesh.computeWorldMatrix(true)
          const xRad = Angle.FromDegrees(delta.x).radians()
          const yRad = Angle.FromDegrees(delta.y).radians()
          const zRad = Angle.FromDegrees(delta.z).radians()
          groupMesh.rotate(Axis.X, xRad, Space.WORLD)
          groupMesh.rotate(Axis.Y, yRad, Space.WORLD)
          groupMesh.rotate(Axis.Z, zRad, Space.WORLD)
        }

        groupMesh.computeWorldMatrix(true)
        groupMesh.getChildTransformNodes().forEach((transformNode) => transformNode.computeWorldMatrix(true))
        this.attachScaleNodes(parts)
        // create command!
        break
      default:
        break
    }

    if (type === ToolTypes.RotateTool && this.rotatePartsIndependentlyMode) {
      const parts = groupMesh.getChildTransformNodes(false, this.meshManager.isPartMesh)
      parts.forEach((part) => this.placeAboveGround(part, this.renderScene.buildPlanType === ItemSubType.SinterPlan))
    } else {
      this.placeAboveGround(groupMesh, this.renderScene.buildPlanType === ItemSubType.SinterPlan)
    }
    this.collisionCallback(this.renderScene, groupMesh.getChildTransformNodes(false, this.meshManager.isPartMesh))

    groupMesh.computeWorldMatrix(true)
    this.onDragEndTransformation(groupMesh, supportRemoved)
    setTimeout(() => this.renderScene.animate(), 0)
    this.renderScene.getModelManager().triggerStabilityCheck()
    this.renderScene.getModelManager().triggerSafeDosingHeightCheck()
  }

  async restoreSelectedParts(type: RestoreSelectedPartsType) {
    let groupMesh = this.selectionManager.getSelected(true)
    if (!groupMesh) {
      return
    }

    this.restoreOption = type
    this.translateLabelsOrientation(groupMesh.getChildMeshes(), false)
    switch (type) {
      case RestoreSelectedPartsType.Recenter:
        this.recenter()
        break
      case RestoreSelectedPartsType.RestoreImportedLocation:
        await this.restoreImportedLocation()
        break
      case RestoreSelectedPartsType.RestoreImportedRotation:
        await this.restoreImportedRotation()
        if (store.getters['buildPlans/isSinterPlan']) {
          await this.restoreImportedLocation()
        }
        break
    }

    groupMesh = this.selectionManager.getSelected(true)
    this.collisionCallback(this.renderScene, groupMesh.getChildTransformNodes(false, this.meshManager.isPartMesh))
    groupMesh.computeWorldMatrix(true)
    const childrenTransformNodes = groupMesh.getChildTransformNodes(false, this.meshManager.isPartMesh)
    childrenTransformNodes.forEach(async (part) => {
      part.computeWorldMatrix()
    })
    this.saveTransformationBatch(childrenTransformNodes, true)
  }

  async deleteOverhangsAndSupports(selectedMesh: AbstractMesh = this.selectedMesh, skipGeomProps?: boolean) {
    this.removeOverhangMeshes(selectedMesh)
    this.removeSupportMeshes(selectedMesh, skipGeomProps)
    await Promise.all(
      selectedMesh.getChildTransformNodes(false, this.meshManager.isPartMesh).map((bpItem) =>
        store.dispatch('buildPlans/removeSupports', {
          params: {
            buildPlanItemId: bpItem.metadata.buildPlanItemId,
            silent: true,
          },
        }),
      ),
    )
  }

  removeOverhangsAndSupportsFromScene(selectedMesh: AbstractMesh = this.selectedMesh, skipGeomProps: boolean = true) {
    this.removeOverhangMeshes(selectedMesh)
    this.removeSupportMeshes(selectedMesh, skipGeomProps)
  }

  translateLabelsOrientation(meshes: AbstractMesh[], toWorld: boolean) {
    meshes.map((child) => {
      if (this.meshManager.isLabelMesh(child) && child.metadata.orientation) {
        this.renderScene.getModelManager().labelMgr.translateLabelOrientation(child, toWorld)
      }
    })
  }

  rebuildSupports() {
    this.updateOverhangMeshes(this.selectedMesh)
  }

  updateParameterSetScale(updateBuildPlanItems: Array<{ buildPlanItemId: string; scaling: number[] }>) {
    const buildPlanItems = []
    let hasSelected = false
    for (const { buildPlanItemId, scaling } of updateBuildPlanItems) {
      const buildPlanItem = this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)
      const parameterSetScaleNode = buildPlanItem.parent as TransformNode
      const scalingVector = Vector3.FromArray(scaling)
      if (parameterSetScaleNode.scaling.equalsWithEpsilon(scalingVector, 1e-6)) {
        continue
      }
      buildPlanItems.push(buildPlanItem)

      const meshes = buildPlanItem.getChildMeshes(false, this.meshManager.isComponentMesh)
      const oldCenterBBox = this.meshManager.getTotalBoundingInfo(meshes).boundingBox.centerWorld.clone()
      this.translateLabelsOrientation(buildPlanItem.getChildMeshes(), false)

      parameterSetScaleNode.scaling = scalingVector

      this.meshManager.translateBBoxCenterToPosition(buildPlanItem, oldCenterBBox)
      buildPlanItem.computeWorldMatrix()

      if (this.selectionManager.isSelected({ part: buildPlanItem })) {
        hasSelected = true
      } else {
        this.limitTranslationGizmo(buildPlanItem)
      }
    }

    if (!buildPlanItems.length) {
      return
    }

    // Pushes out selected items as a single body.
    if (hasSelected) {
      this.limitTranslationGizmo(this.selectedMesh)
    }

    this.collisionCallback(this.renderScene, buildPlanItems)
    this.saveTransformationBatch(buildPlanItems, true)
    this.renderScene.animate()
  }

  attachScaleNode(bpItem: TransformNode, bboxPositionCenter?: Vector3) {
    if (bpItem.metadata && bpItem.metadata.parameterSetScaleNode) {
      const node = bpItem.metadata.parameterSetScaleNode as TransformNode
      const parent = bpItem.parent
      bpItem.setParent(null)

      const scaling = node.scaling
      const translation = bpItem.position
      const translationMatrix = Matrix.Translation(translation.x, translation.y, translation.z)
      const invTranslationMatrix = translationMatrix.clone().invert()
      const scalingMatrix = Matrix.Scaling(scaling.x, scaling.y, scaling.z)
      const transform = invTranslationMatrix.multiply(scalingMatrix).multiply(translationMatrix)
      transform.decompose(node.scaling, node.rotationQuaternion, node.position)

      bpItem.parent = node
      if (bboxPositionCenter) {
        this.meshManager.translateBBoxCenterToPosition(bpItem, bboxPositionCenter)
      }

      node.setParent(parent)
    }
  }

  attachScaleNodes(bpItems: TransformNode[]) {
    bpItems.forEach((bpItem) => {
      const oldBox = this.meshManager.getTotalBoundingInfo(
        bpItem.getChildMeshes(false, this.meshManager.isComponentMesh),
      ).boundingBox

      this.attachScaleNode(bpItem, oldBox.centerWorld)
    })
  }

  detachScaleNode(bpItem: TransformNode, bboxPositionCenter?: Vector3) {
    if (bpItem.parent && bpItem.parent.name === PARAMETER_SET_SCALE_NAME) {
      const scaleNodeParent = bpItem.parent.parent
      ;(bpItem.parent as TransformNode).setParent(null)
      bpItem.parent = null
      bpItem.setParent(scaleNodeParent)
      if (bboxPositionCenter) {
        this.meshManager.translateBBoxCenterToPosition(bpItem, bboxPositionCenter)
      }
    }
  }

  detachScaleNodes(bpItems: TransformNode[]) {
    bpItems.forEach((bpItem) => {
      const oldBox = this.meshManager.getTotalBoundingInfo(
        bpItem.getChildMeshes(false, this.meshManager.isComponentMesh),
      ).boundingBox

      this.detachScaleNode(bpItem, oldBox.centerWorld)
    })
  }

  protected computeGizmoScaleRatio() {
    const camera = this.renderScene.getActiveCamera()
    return (camera.orthoRight - camera.orthoLeft) / 700
  }

  private saveTransformationBeforeDragStart(selected: AbstractMesh) {
    const children = selected.getChildTransformNodes(false, this.meshManager.isPartMesh)

    this.beforeDragStartTransformation = children.map((child) => this.getTransformationOnDragEvents(child))
  }

  private getTransformationOnDragEvents(mesh: TransformNode) {
    const transformation = []

    if (mesh && mesh.metadata && mesh.metadata.initialTransformation) {
      const unitFactor = this.meshManager.isPartMesh(mesh)
        ? mesh.metadata.unitFactor
        : this.meshManager.getBuildPlanItemMeshByChild(mesh).metadata.unitFactor
      const { initialTransformation } = mesh.metadata
      const partRelativeTransformation = this.meshManager.getRelativeTransformation(
        mesh.getWorldMatrix(),
        initialTransformation,
      )

      // make sure we store transposed version of mesh's matrix on the build plan
      // as back-end services expect column-matrix, not row-matrix
      this.meshManager
        .convertTranslationToMillimeters(partRelativeTransformation, unitFactor)
        .transpose()
        .asArray()
        .forEach((item) => transformation.push(item))

      return {
        transformationMatrix: transformation,
        buildPlanItemId: mesh.metadata.buildPlanItemId,
      }
    }

    throw new Error('Bad part transformation was provided')
  }

  private updateCustomGizmoOrientation(cameraX: number, cameraY: number) {
    const utilityLayerScene = this.gizmoManager.utilityLayer.utilityLayerScene
    const xAxisDragMesh = utilityLayerScene.getMeshByName('xAxisDragMesh')
    const yAxisDragMesh = utilityLayerScene.getMeshByName('yAxisDragMesh')
    const xAxisPlaneDragMesh = utilityLayerScene.getMeshByName('xAxisPlaneDragMesh')
    const yAxisPlaneDragMesh = utilityLayerScene.getMeshByName('yAxisPlaneDragMesh')
    const zAxisPlaneDragMesh = utilityLayerScene.getMeshByName('zAxisPlaneDragMesh')
    const xAxisPlaneRotationMesh = utilityLayerScene.getMeshByName('xAxisPlaneRotationMesh')
    const yAxisPlaneRotationMesh = utilityLayerScene.getMeshByName('yAxisPlaneRotationMesh')
    const zAxisPlaneRotationMesh = utilityLayerScene.getMeshByName('zAxisPlaneRotationMesh')
    if (cameraX >= 0) {
      xAxisDragMesh.rotation.z = 0
      yAxisPlaneDragMesh.rotation.z = 0
      yAxisPlaneRotationMesh.rotation.z = 0
      if (cameraY >= 0) {
        // X+ Y+ quadrant
        yAxisDragMesh.rotation.z = 0
        xAxisPlaneDragMesh.rotation.x = 0
        zAxisPlaneDragMesh.rotation.z = 0
        xAxisPlaneRotationMesh.rotation.z = 0
        zAxisPlaneRotationMesh.rotation.z = 0
      } else {
        // X+ Y- quadrant
        yAxisDragMesh.rotation.z = Math.PI
        xAxisPlaneDragMesh.rotation.x = Math.PI
        zAxisPlaneDragMesh.rotation.z = -Math.PI / 2
        xAxisPlaneRotationMesh.rotation.z = Math.PI
        zAxisPlaneRotationMesh.rotation.z = -Math.PI / 2
      }
    } else {
      xAxisDragMesh.rotation.z = Math.PI
      yAxisPlaneDragMesh.rotation.z = Math.PI / 2
      yAxisPlaneRotationMesh.rotation.z = Math.PI
      if (cameraY >= 0) {
        // X- Y+ quadrant
        yAxisDragMesh.rotation.z = 0
        xAxisPlaneDragMesh.rotation.x = 0
        zAxisPlaneDragMesh.rotation.z = Math.PI / 2
        xAxisPlaneRotationMesh.rotation.z = 0
        zAxisPlaneRotationMesh.rotation.z = Math.PI / 2
      } else {
        // X- Y- quadrant
        yAxisDragMesh.rotation.z = Math.PI
        xAxisPlaneDragMesh.rotation.x = Math.PI
        zAxisPlaneDragMesh.rotation.z = Math.PI
        xAxisPlaneRotationMesh.rotation.z = Math.PI
        zAxisPlaneRotationMesh.rotation.z = Math.PI
      }
    }
  }

  private updateGizmoAxesVisible() {
    if (!this.constraints) {
      return
    }

    const positionGizmo = this.gizmoManager.gizmos.positionGizmo
    const rotationGizmo = this.gizmoManager.gizmos.rotationGizmo
    const constraints: IConstraints = JSON.parse(JSON.stringify(this.constraints))
    const cameraPos = this.renderScene.getActiveCamera().position
    const cameraTarget = this.renderScene.getActiveCamera().target
    const direction = cameraTarget.subtract(cameraPos).normalize()

    const areCollinear = (a: Vector3, b: Vector3, epsilon: number = Number.EPSILON): boolean => {
      return a.cross(b).length() < epsilon
    }

    if (areCollinear(direction, new Vector3(0, 0, 1), 0.01)) {
      constraints.translation.z = true
      constraints.rotation.x = true
      constraints.rotation.y = true
    } else if (areCollinear(direction, new Vector3(0, 1, 0))) {
      constraints.translation.y = true
      constraints.rotation.x = true
      constraints.rotation.z = true
    } else if (areCollinear(direction, new Vector3(1, 0, 0))) {
      constraints.translation.x = true
      constraints.rotation.y = true
      constraints.rotation.z = true
    }
    let children: TransformNode[] = []
    if (this.selectedMesh) {
      children = this.selectedMesh.getChildTransformNodes(false, this.meshManager.isPartMesh)
    }

    const isMoveOrRotateMode =
      this.renderScene.getViewMode() instanceof MoveViewMode || this.renderScene.getViewMode() instanceof RotateViewMode

    if (positionGizmo) {
      this.disableAxisDragMesh(
        this.meshNames.xAxis,
        (!!constraints.translation.x && !isMoveOrRotateMode) ||
          this.renderScene.buildPlanType === ItemSubType.SinterPlan,
      )
      this.showAxisDragLock(
        this.meshNames.xAxis,
        isMoveOrRotateMode && !!constraints.translation.x && this.renderScene.buildPlanType !== ItemSubType.SinterPlan,
      )
      this.disableAxisDragMesh(
        this.meshNames.yAxis,
        (!!constraints.translation.y && !isMoveOrRotateMode) ||
          this.renderScene.buildPlanType === ItemSubType.SinterPlan,
      )
      this.showAxisDragLock(
        this.meshNames.yAxis,
        isMoveOrRotateMode && !!constraints.translation.y && this.renderScene.buildPlanType !== ItemSubType.SinterPlan,
      )
      this.disableAxisDragMesh(
        this.meshNames.zAxis,
        (!!constraints.translation.z && !isMoveOrRotateMode) ||
          this.renderScene.buildPlanType === ItemSubType.SinterPlan,
      )
      this.showAxisDragLock(
        this.meshNames.zAxis,
        isMoveOrRotateMode && !!constraints.translation.z && this.renderScene.buildPlanType !== ItemSubType.SinterPlan,
      )
      this.disablePlaneDragMesh(
        this.meshNames.xAxis,
        (!!(constraints.translation.y || constraints.translation.z) && !isMoveOrRotateMode) ||
          this.renderScene.buildPlanType === ItemSubType.SinterPlan,
      )
      this.disablePlaneDragMesh(
        this.meshNames.yAxis,
        (!!(constraints.translation.x || constraints.translation.z) && !isMoveOrRotateMode) ||
          this.renderScene.buildPlanType === ItemSubType.SinterPlan,
      )
      this.disablePlaneDragMesh(
        this.meshNames.zAxis,
        (!!(constraints.translation.x || constraints.translation.y) && !isMoveOrRotateMode) ||
          this.renderScene.buildPlanType === ItemSubType.SinterPlan,
      )
    }
    if (rotationGizmo) {
      this.disablePlaneRotationMesh(this.meshNames.xAxis, !!constraints.rotation.x && !isMoveOrRotateMode)
      this.showPlaneRotationLock(this.meshNames.xAxis, isMoveOrRotateMode && !!constraints.rotation.x)
      this.disablePlaneRotationMesh(this.meshNames.yAxis, !!constraints.rotation.y && !isMoveOrRotateMode)
      this.showPlaneRotationLock(this.meshNames.yAxis, isMoveOrRotateMode && !!constraints.rotation.y)
      this.disablePlaneRotationMesh(this.meshNames.zAxis, !!constraints.rotation.z && !isMoveOrRotateMode)
      this.showPlaneRotationLock(this.meshNames.zAxis, isMoveOrRotateMode && !!constraints.rotation.z)
    }

    this.renderScene.animate(true)
  }

  private disableAxisDragMesh(axisName: string, disable: boolean) {
    const utilityLayerScene = this.gizmoManager.utilityLayer.utilityLayerScene
    const gizmo = utilityLayerScene.getMeshByName(`${axisName}DragMesh`)
    if (gizmo.isPickable !== disable) return
    gizmo.isPickable = !disable

    const children = gizmo.getChildMeshes()
    for (const child of children) {
      child.isPickable = !disable
      if (disable) {
        child.material = this.disableGizmoMaterial
      } else {
        child.material = this.regularGizmoMaterial
      }
    }
  }

  private showAxisDragLock(axisName: string, show: boolean) {
    const lockSprite = this.spriteManager.sprites.find((sprite) => sprite.name === `${axisName}LockSprite`)
    lockSprite.isVisible = show && this.gizmoManager.positionGizmoEnabled
  }

  private disablePlaneDragMesh(axisName: string, disable: boolean) {
    const utilityLayerScene = this.gizmoManager.utilityLayer.utilityLayerScene
    const plateMesh = utilityLayerScene.getMeshByName(`${axisName}PlaneDragMesh`)
    if (plateMesh.isPickable !== disable) return
    plateMesh.isPickable = !disable

    if (disable) {
      plateMesh.material = this.disablePlaneMaterial
    } else {
      plateMesh.material = this.regularPlaneMaterial
    }
  }

  private disablePlaneRotationMesh = (axisName: string, disable: boolean) => {
    const utilityLayerScene = this.gizmoManager.utilityLayer.utilityLayerScene
    const rotationMesh = utilityLayerScene.getMeshByName(`${axisName}PlaneRotationMesh`)
    if (rotationMesh.isPickable !== disable) return
    rotationMesh.isPickable = !disable

    utilityLayerScene.meshes
      .filter((mesh) => mesh.name.includes(`${axisName}PlaneRotationHelperMesh`))
      .forEach((mesh) => (mesh.isPickable = !disable))

    if (disable) {
      rotationMesh.material = this.disableGizmoMaterial
    } else {
      rotationMesh.material = this.regularGizmoMaterial
    }
  }

  private showPlaneRotationLock(axisName: string, show: boolean) {
    const lockSprite = this.spriteManager.sprites.find((sprite) => sprite.name === `${axisName}RotationLockSprite`)
    lockSprite.isVisible = show && this.gizmoManager.rotationGizmoEnabled
  }

  private togglePositionSnapping = (positionGizmo: any) => {
    if (this.renderScene.shiftKey && positionGizmo.snapDistance === 0) {
      positionGizmo.snapDistance = this.renderScene.buildPlanMoveIncrement
    } else if (!this.renderScene.shiftKey && positionGizmo.snapDistance !== 0) {
      positionGizmo.snapDistance = 0
    }
  }

  private toggleRotationSnapping = (rotationGizmo: any) => {
    if (this.renderScene.modality === PrintingTypes.BinderJet && this.isCompensatedPartMeshSelected()) {
      rotationGizmo.snapDistance = SINTER_PART_ROTATION_INCREMENT
    } else if (this.renderScene.shiftKey && rotationGizmo.snapDistance === 0) {
      rotationGizmo.snapDistance = (this.renderScene.buildPlanRotateIncrement * Math.PI) / 180
    } else if (!this.renderScene.shiftKey && rotationGizmo.snapDistance !== 0) {
      rotationGizmo.snapDistance = 0
    }
  }

  private isCompensatedPartMeshSelected(): boolean {
    const isCompensatedPartMesh = (node: TransformNode) => {
      return this.meshManager.isSinterPartMesh(node) || this.meshManager.isIBCPartMesh(node)
    }
    return this.selectedMesh ? this.selectedMesh.getChildTransformNodes(false, isCompensatedPartMesh).length > 0 : false
  }

  private unregisterCanvasEvents() {
    this.canvas.onblur = null
  }

  /**
   * Place selected parts at the center of build/sinter plate.
   * Note that it use the center of bounding box of the part instead of imported origin.
   */
  private recenter() {
    const selectedParts = this.selectedMesh.getChildTransformNodes(false, this.meshManager.isPartMesh)
    const groundBbox = this.renderScene.getBuildPlateManager().groundBox.getBoundingInfo().boundingBox
    this.selectionManager.deselect()

    selectedParts.forEach((part) => {
      const bbox = this.meshManager.getHullBInfo(part).boundingBox
      const offset = bbox.centerWorld.subtract(part.absolutePosition)
      const newPosition = new Vector3(
        groundBbox.centerWorld.x - offset.x,
        groundBbox.centerWorld.y - offset.y,
        groundBbox.maximumWorld.z + bbox.extendSizeWorld.z - offset.z,
      )

      part.setAbsolutePosition(newPosition)
      part.computeWorldMatrix()
      this.placeAboveGround(part, true)

      this.selectionManager.select([{ part }], true)
    })

    this.selectionManager.showGizmos()
  }

  /** Place selected parts at the imported location. */
  private async restoreImportedLocation() {
    const children = this.selectionManager.getSelected()
    this.selectionManager.deselect()

    for (const part of children) {
      const supports = part.getChildMeshes().filter(this.meshManager.isSupportMesh)
      const isSupportsPresent = !!supports.length

      const initialPosition = Vector3.Zero()
      const oldPartPosition = part.getAbsolutePosition().clone()
      part.metadata.initialTransformation.decompose(null, null, initialPosition)

      part.setAbsolutePosition(initialPosition)

      this.selectionManager.select([{ part }], true)

      if (isSupportsPresent) {
        const isZReduced = part.absolutePosition.z < oldPartPosition.z
        if (isZReduced) {
          this.selectedMesh = this.selectionManager.getSelected(true)
          await this.deleteOverhangsAndSupports(this.selectedMesh, true)
        }
      }

      this.placeAboveGround(part)
      part.computeWorldMatrix()
    }

    this.selectionManager.showGizmos()
  }

  /** Rotate selected parts to imported orientation. */
  private async restoreImportedRotation() {
    const parts = this.selectionManager.getSelected()

    this.detachScaleNodes(parts)
    this.selectionManager.deselect()

    parts.forEach((part) => {
      part.computeWorldMatrix(true)
      const totalMeshes = this.meshManager.getMeshesWithBounds([part])
      const boundingInfo = this.meshManager.getTotalBoundingInfo(totalMeshes, true, true)

      const initialRotationQuaternion = Quaternion.Identity()
      part.metadata.initialTransformation.decompose(null, initialRotationQuaternion, null)

      const delta = initialRotationQuaternion.toEulerAngles().subtract(part.rotationQuaternion.clone().toEulerAngles())

      part.rotateAround(boundingInfo.boundingBox.centerWorld, Axis.Y, delta.y)
      part.rotateAround(boundingInfo.boundingBox.centerWorld, Axis.X, delta.x)
      part.rotateAround(boundingInfo.boundingBox.centerWorld, Axis.Z, delta.z)
    })

    this.attachScaleNodes(parts)

    parts.forEach((part) => {
      part.computeWorldMatrix(true)
      part.getChildTransformNodes().forEach((tn) => tn.computeWorldMatrix(true))
    })

    this.selectionManager.select(
      parts.map((part) => ({ part })),
      true,
    )
    this.selectionManager.showGizmos()

    const isSinterPlan = this.renderScene.buildPlanType === ItemSubType.SinterPlan
    if (this.rotatePartsIndependentlyMode) {
      parts.forEach((part) => this.placeAboveGround(part, isSinterPlan))
    } else {
      this.placeAboveGround(this.selectionManager.getSelected(true), isSinterPlan)
    }
    await this.deleteOverhangsAndSupports(this.selectionManager.getSelected(true), true)
    this.renderScene.getModelManager().triggerStabilityCheck()
    this.renderScene.getModelManager().triggerSafeDosingHeightCheck()
  }
}
