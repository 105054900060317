import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { MarkingContentElementType } from '@/types/Label/enums'
import { Matrix, Quaternion, Vector3 } from '@babylonjs/core/Maths/math'
import { ManualTrackableLabel, TrackableLabel } from '@/types/Label/TrackableLabel'

/**
 *
 * @param element Label dynamic text element
 * @returns Property in state for last updated element id
 */
export function getTextElementByType(elementType: MarkingContentElementType) {
  let textElement = null
  switch (elementType) {
    case MarkingContentElementType.Sequential_Integer:
      textElement = 'counterElementId'
      break
    case MarkingContentElementType.Grid_Letter:
      textElement = 'gridLetterElementId'
      break
    case MarkingContentElementType.User_Entry:
      textElement = 'userEntryElementId'
      break
    default:
      break
  }

  return textElement
}

/**
 * Returns isDirty value for passed trackable object
 * @param {TrackableLabel} label Trackable object to test isDirty
 * @returns {boolean} isDirty
 */
export const isDirty = (label: TrackableLabel): boolean => {
  return label.isDirty
}

/**
 * Gets transformation without scale of passed build plan item
 * @param {IBuildPlanItem} bpItem Build Plan Item
 * @returns {Array<number>} Transformation matrix
 */
export const getBuildPlanItemTransformationWithoutScale = (bpItem: IBuildPlanItem) => {
  const bpItemTransformation = JSON.parse(JSON.stringify(bpItem.transformationMatrix)) as number[]
  return getBuildPlanItemTransformationWithoutScaleFromTransformation(bpItemTransformation)
}

/**
 * Gets transformation without scale of passed build plan item transformation
 * @param {Array<number>} bpItemTransformation Build Plan Item transformation
 * @returns {Array<number>} Transformation matrix
 */
export const getBuildPlanItemTransformationWithoutScaleFromTransformation = (bpItemTransformation: number[]) => {
  const scaling = new Vector3(1, 1, 1)
  const translation = Vector3.Zero()
  const rotation = new Quaternion()
  Matrix.FromArray(bpItemTransformation).transpose().decompose(scaling, rotation, translation)
  const withoutScale = Matrix.Compose(new Vector3(1, 1, 1), rotation, translation).transpose()

  return Array.from(withoutScale.m)
}

/**
 * Check if label is manually placed
 * @param label - automatic or manual trackable label
 * @returns
 */
export const isManualLabel = (label: TrackableLabel): label is ManualTrackableLabel => {
  return (label as ManualTrackableLabel).manualPlacementId !== undefined
}
