
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component
export default class DetailsPanel extends Vue {
  @Prop({ default: true }) displayHeader: boolean
  @Prop({ default: 'Details' }) title: string
  @Prop({ default: 0 }) itemsNumber: number
  @Prop({ default: false }) isOpen: boolean
  @Prop({ default: false }) isForceUpdate: boolean

  get itemsNumberSelected() {
    return `${this.itemsNumber} selected items`
  }

  @Watch('isForceUpdate')
  onIsForceUpdateChange() {
    if (this.isForceUpdate) {
      this.$forceUpdate()
    }
  }
}
