import { EndpointsUrls } from '@/configs/config'
import { post, get, put, del, getHeaders } from '@/api/http'
import { ICostEstimation } from '@/types/CostEstimations/ICostEstimation'
import { IMaterial } from '@/types/IMaterial'
import { IPaginationOptions } from '@/types/IPaginationOptions'
import { PaginationKeyword } from '@/types/PaginationKeyword'
import { SortParamsMap, SortOrders } from '@/types/SortModes'
import { FilterParamsMap } from '@/types/Filter'
import { IFilteredResponse, handleAPIError } from './common'
import { IParameterSet } from '../types/BuildPlans/IBuildPlan'
import { IMachineConfig } from '@/types/IMachineConfig'

/**
 * Converts sort params to query string: 'sort=field1,-field2,+field3 ...'
 */
function sortToQueryStringParamValue(params: SortParamsMap): string {
  return Object.entries(params)
    .filter(([_, order]) => order)
    .map(([field, order]) => {
      const prefix = order === SortOrders.Ascending ? '' : '-'
      return `${prefix}${field}`
    })
    .join(',')
}
/**
 * Converts filter params to query string: 'filter[field1]=1,2,3,4&filter[field2]=a,b,c ...'
 */
function filterToQueryStringParamValue(params: FilterParamsMap): string {
  const parameterName = 'filter'
  return Object.entries(params)
    .filter(([_, values]) => values && values.length)
    .map(([field, values]) => {
      return `${parameterName}[${field}]=${values}`
    })
    .join('&')
}

export default {
  async getMaterials() {
    try {
      return await get<IMaterial[]>(EndpointsUrls.Materials)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getParameterSets() {
    try {
      return await get<IParameterSet[]>(EndpointsUrls.ParameterSets)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getMachineConfigs() {
    try {
      return await get<IMachineConfig[]>(EndpointsUrls.MachineConfigs)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getRegions() {
    try {
      return await get<string[]>(EndpointsUrls.Regions)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getCostEstimations() {
    try {
      const estimationsResponse = await get<IFilteredResponse<ICostEstimation>>(EndpointsUrls.CostEstimations)
      return this.filterEmptyCostEstimations(estimationsResponse)
    } catch (error) {
      handleAPIError(error)
      return { items: [], count: 0 }
    }
  },

  async getFilteredCostEstimations(options: IPaginationOptions) {
    const params = Object.entries(options)
      .map(([key, val]) => {
        switch (key) {
          case PaginationKeyword.Search:
            return `${val.field}=${val.value}`

          case PaginationKeyword.Filter:
            return filterToQueryStringParamValue(val)

          case PaginationKeyword.Sort:
            const strVal = sortToQueryStringParamValue(val)
            return strVal ? `${key}=${strVal}` : ''

          case PaginationKeyword.Page:
            return `${PaginationKeyword.Limit}=${val.limit}&${PaginationKeyword.Offset}=${val.offset}`
        }
      })
      .filter((param) => param)
      .join('&')

    const url = `${EndpointsUrls.CostEstimations}?${params}`
    try {
      return await get<IFilteredResponse<ICostEstimation>>(url)
    } catch (error) {
      handleAPIError(error)
      return { items: [], count: 0 }
    }
  },

  async fetchCostEstimationById(id: number) {
    try {
      return await get<ICostEstimation>(`${EndpointsUrls.CostEstimations}/${id}`)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getMaterialById(id: number) {
    try {
      return await get<IMaterial>(`${EndpointsUrls.Materials}/${id}`)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getMachineConfigById(id: number) {
    try {
      return await get<IMachineConfig>(`${EndpointsUrls.MachineConfigs}/${id}`)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async createCostEstimation(estimation: ICostEstimation) {
    try {
      return await post<ICostEstimation>(EndpointsUrls.CostEstimations, estimation)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async updateCostEstimationImage(id: number, image: File) {
    try {
      const url = `${EndpointsUrls.CostEstimations}/${id}`

      const headers = {
        'Content-Type': 'multipart/form-data',
      }

      const formData = new FormData()
      formData.append('image', image)

      return await put<FormData>(url, formData, { headers })
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async estimateCost(estimation: ICostEstimation) {
    try {
      const { id, createdAt, updatedAt, ...costEstimationUpdateDto } = estimation
      const url = `${EndpointsUrls.CostEstimations}/${id}/estimate`
      return await put<ICostEstimation>(url, costEstimationUpdateDto)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async deleteCostEstimation(id: number) {
    try {
      const url = `${EndpointsUrls.CostEstimations}/${id}`
      return await del(url)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  syncDeleteCostEstimation(id: number) {
    try {
      const url = `${window.env.VUE_APP_API_URL}/${EndpointsUrls.CostEstimations}/${id}`
      fetch(url, {
        method: 'DELETE',
        headers: getHeaders(),
        keepalive: true,
      })
    } catch (error) {
      handleAPIError(error)
    }
  },

  async clone(id: number) {
    try {
      return await post(`${window.env.VUE_APP_API_URL}/${EndpointsUrls.CostEstimations}/${id}/clone`, null)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getCostEstimationsToken() {
    try {
      return await get(EndpointsUrls.CostEstimationsToken)
    } catch (error) {
      handleAPIError(error)
    }
  },

  filterEmptyCostEstimations(response: IFilteredResponse<ICostEstimation>): IFilteredResponse<ICostEstimation> {
    const nonEmptyCostEstimations = response.items.filter(
      (costEstimation) => costEstimation.costEstimationSet.length && costEstimation.costEstimationSet[0].result,
    )
    return { items: nonEmptyCostEstimations, count: nonEmptyCostEstimations.length }
  },
}
