import { IAnnouncementState } from '@/store/modules/announcements/types'
import { IRootState } from '@/store/types'
import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'

export const state: IAnnouncementState = {
  announcement: null,
}

const namespaced: boolean = true

export const announcements: Module<IAnnouncementState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
