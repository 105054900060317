import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

export enum ExitToolAction {
  SaveData,
  DiscardData,
  DoNotExit,
  // Need this case to cancel tools which do not support unsaved changes report
  NoDataToSave,
}

@Component
export class ActiveToolUnsavedChangesMixin extends Vue {
  @buildPlansStore.Getter activeToolHasUnsavedData: boolean

  private resolveCanExitPromise: Function

  mounted() {
    this.$root.$on('toolExitedWithAction', this.onToolExited)
  }

  beforeDestroy() {
    this.$root.$off('toolExitedWithAction', this.onToolExited)
  }

  async canExitActiveTool(): Promise<ExitToolAction> {
    if (!this.activeToolHasUnsavedData) return ExitToolAction.NoDataToSave

    const canExitComplete = new Promise<ExitToolAction>((resolve, reject) => {
      this.resolveCanExitPromise = resolve
    })

    this.$root.$emit('showToolConfirmationDialog')
    return canExitComplete
  }

  private onToolExited(action: ExitToolAction) {
    if (this.resolveCanExitPromise) {
      this.resolveCanExitPromise(action)
    }
  }
}
