/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Color3, Vector3 } from '@babylonjs/core/Maths'
import { Effect } from '@babylonjs/core/Materials/effect'
import { ShaderMaterial } from '@babylonjs/core/Materials/shaderMaterial'
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight'
import { PointLight } from '@babylonjs/core/Lights/pointLight'
import { Scene } from '@babylonjs/core/scene'
import { float, FloatArray } from '@babylonjs/core/types'
import { OrthoCamera } from '@/visualization/components/OrthoCamera'
import {
  VERTEX_SHADER_COMMON,
  SECTION_COLORING,
  FACE_COLORING,
  FRAGMENT_SHADER_COMMON,
  VERTEX_SHADER,
  FRAGMENT_SHADER,
  POSITION_ATTRIBUTE,
  NORMAL_ATTRIBUTE,
  FACE_ID_ATTRIBUTE,
  WORLD_VIEW_PROJECTION_MATRIX,
  WORLD_MATRIX,
  ACCURACY,
  SECTIONS_COLORS,
  VERTICAL_SECTIONS_COUNT,
  HORIZONTAL_SECTIONS_COUNT,
  REGULAR_COLOR,
  HOVER_COLOR,
  SELECTED_FACES_ID,
  SELECTED_FACES_COUNT,
  SPECULAR_COLOR,
  CAMERA_POSITION,
  AMBIENT_LIGHT_DIRECTION,
  POINT_LIGHT_POSITION,
  RECOATER_DIRECTION,
  RECOATER_MAX_ANGLE,
  RECOATER_DIRECTION_COLOR,
  RECOATER_MAX_ANGLE_VALUE,
  OVERHANG_AREAS,
  OVERHANG_ANGLE,
  OVERHANG_AREA_COLOR,
  PICKING,
  SELECTION_COLOR,
  HOVER_FACE_ID,
  SHOW_HOVER,
  FLAT,
  FACET_COLOR_ATTRIBUTE,
  DEPTH,
  MAX_CAMERA_DISTANCE,
  LABEL_AREAS,
  LABEL_AREA_COLOR,
  MAX_LABEL_ANGLE,
  ERROR_FACES_ID,
  ERROR_FACES_COUNT,
  ERROR_COLOR,
  COLOR_SWITCHER,
  COLOR_FOR_PART,
  COLOR_FOR_FACE,
  COLOR_FOR_BODY,
  VIEW_PROJECTION_MATRIX,
  HIGHLIGHT_INSTANCE_ID,
  INSTANCE_ID,
  HOVER_FACET,
  FACET_COLORING,
  SHADER_DEFAULT_MATERIAL_NAME,
  MULTI_ITEM_FACE_COLORING,
  MULTI_ITEM_COLLECTOR_SELECTION_COLORS,
  MULTI_ITEM_COLLECTOR_INSTANCES_COLORS,
  INSTANCES_COUNT,
  MULTI_ITEM_COLLECTOR_INSTANCES,
  MULTI_ITEM_COLLECTOR_HIGHLIGHT_FACES,
  HIGHLIGHTED_FACES_COUNT,
  COLLECTOR_INSTANCE_ID,
} from '@/constants'
import { RenderScene } from '@/visualization/render-scene'
import { isNumber } from '@/utils/number'

export class HoverableFace {
  readonly id: number

  constructor(id: number) {
    this.id = id
  }

  public toArray(array: FloatArray, index: number = 0) {
    array[index] = this.id

    return this
  }
}

export abstract class MeshShader {
  static initializeShaderIncludes() {
    Effect.IncludesShadersStore[VERTEX_SHADER_COMMON] = `
      #ifdef GL_ES
      precision highp float;
      precision highp int;
      #endif

      // Attributes
      attribute vec3 ${POSITION_ATTRIBUTE};
      attribute vec3 ${NORMAL_ATTRIBUTE};
      
      // Uniforms
      uniform mat4 ${VIEW_PROJECTION_MATRIX};
      uniform mat4 ${WORLD_VIEW_PROJECTION_MATRIX};

      // Refs
      uniform vec3 ${REGULAR_COLOR};
      uniform vec3 ${SELECTION_COLOR};
      uniform vec3 ${HOVER_COLOR};
      
      // Varying
      varying vec3 vPosition;
      varying vec3 vNormal;
      varying vec3 vColor;
    `

    Effect.IncludesShadersStore[FRAGMENT_SHADER_COMMON] = `
      #ifdef GL_ES
      precision highp float;
      precision highp int;
      #endif

      #define EXT_ANGLE 180

      // Varying
      varying vec3 vPosition;
      varying vec3 vNormal;
      varying vec3 vColor;
      varying vec2 vUV;
      
      // Uniforms
      uniform mat4 ${WORLD_MATRIX};

      // Refs
      uniform vec3 ${CAMERA_POSITION};
      uniform vec3 ${REGULAR_COLOR};
      uniform vec3 ${SPECULAR_COLOR};
      uniform vec3 ${AMBIENT_LIGHT_DIRECTION};
      uniform vec3 ${POINT_LIGHT_POSITION};

      const float EPS = ${ACCURACY};
        
      bool equals(float l, float r) {
        return abs(l - r) < EPS;
      }

      vec3 CalcColorLights(vec3 pos, vec3 norm, vec3 color, vec3 cameraPos, vec3 ambientLightDir, vec3 pointLightPos) {
        vec3 ambientLightDirN = normalize(ambientLightDir);
        vec3 viewDirectionW = normalize(cameraPos - pos);

        // Hemisphere lightning
        // To compute the illumination at any point on the surface, we must compute the 
        // integral of the illumination received at that point:
        // Color = ndl · SkyColor + (1 - ndl) · GroundColor
        // But we can actually calculate ndl in another way that is simpler but roughly equivalent:
        float ndl = 0.5 + 0.5 * dot(ambientLightDirN, norm);

        // We first calculate the dot product between the view direction and the reflect direction 
        // and then raise it to the power of 64 (shininess value of the highlight). 
        // The higher the shininess value of an object, the more it properly reflects the light instead of 
        // scattering it all around and thus the smaller the highlight becomes.
        vec3 lightAngleW = normalize(viewDirectionW + ambientLightDirN);
        float specCompA = max(0., dot(norm, lightAngleW));
        specCompA = pow(specCompA, max(1., 64.));

        // Calculate point light direction
        vec3 cameraLightVectorW = normalize(pointLightPos - pos);
        // Get the intensity of the light
        float cameraNdl = max(0., dot(norm, cameraLightVectorW));

        // The same as calculation of specCompA
        vec3 cameraAngleW = normalize(viewDirectionW + cameraLightVectorW);
        float specCompP = max(0., dot(norm, cameraAngleW));
        specCompP = pow(specCompP, max(1., 64.));

        return (color * ndl + vec3(specCompA) * specularColor) + (color * cameraNdl + vec3(specCompP) * specularColor);
      }
    `
  }

  readonly vertexShaderName: string
  readonly fragmentShaderName: string

  protected shaderMaterial: ShaderMaterial
  protected scene: Scene

  private camera: OrthoCamera
  private regularMaterial: StandardMaterial
  private ambientLight: HemisphericLight
  private pointLight: PointLight

  constructor(renderScene: RenderScene, shaderName: string, regularMaterial?: StandardMaterial) {
    this.vertexShaderName = `${shaderName}${VERTEX_SHADER}`
    this.fragmentShaderName = `${shaderName}${FRAGMENT_SHADER}`
    this.scene = renderScene.getScene()
    this.regularMaterial = regularMaterial
      ? regularMaterial
      : (this.scene.getMaterialByName(SHADER_DEFAULT_MATERIAL_NAME) as StandardMaterial)
    this.camera = renderScene.getActiveCamera()
    this.ambientLight = renderScene.getAmbientLight() as HemisphericLight
    this.pointLight = renderScene.getPointLight() as PointLight
  }

  getMaterial() {
    return this.shaderMaterial
  }

  compileMaterial() {
    // precompile material before running your rendering in order to get the smoother experience possible.
    for (const mesh of this.scene.meshes) {
      this.shaderMaterial.forceCompilation(mesh)
    }
  }

  setupColorsAndLights() {
    if (!this.shaderMaterial) {
      return
    }

    if (this.camera) {
      this.shaderMaterial.setVector3(CAMERA_POSITION, this.camera.position)
    }
    if (this.regularMaterial) {
      this.shaderMaterial.setColor3(REGULAR_COLOR, this.regularMaterial.diffuseColor)
      this.shaderMaterial.setColor3(SPECULAR_COLOR, this.regularMaterial.specularColor)
    }
    if (this.ambientLight) {
      this.shaderMaterial.setVector3(AMBIENT_LIGHT_DIRECTION, this.ambientLight.direction)
    }
    if (this.pointLight) {
      this.shaderMaterial.setVector3(POINT_LIGHT_POSITION, this.pointLight.position)
    }
  }

  dispose() {
    this.shaderMaterial.dispose()
  }
}

export class FlatShader {
  readonly shaderMaterial: ShaderMaterial

  private readonly vertexShaderName
  private readonly fragmentShaderName

  constructor(scene: Scene) {
    this.vertexShaderName = `${FLAT}${VERTEX_SHADER}`
    this.fragmentShaderName = `${FLAT}${FRAGMENT_SHADER}`

    Effect.ShadersStore[this.vertexShaderName] = `
      #include<${VERTEX_SHADER_COMMON}>
      
      attribute vec4 ${FACET_COLOR_ATTRIBUTE};

      uniform mat4 worldView;

      // Varying
      flat out vec4 vColor4;
      
      void main(void) {
        gl_Position = worldViewProjection * vec4(position, 1.0);
        vColor4 = ${FACET_COLOR_ATTRIBUTE};
      }
    `

    Effect.ShadersStore[this.fragmentShaderName] = `
      #extension GL_OES_standard_derivatives : enable
      precision highp float;

      // Varying
      flat in vec4 vColor4;
      varying vec3 vertex_view_space;

      // Uniforms
      uniform mat4 ${WORLD_MATRIX};

      void main(void) {
        gl_FragColor = vColor4;
      }
    `

    this.shaderMaterial = new ShaderMaterial(
      `${FLAT}`,
      scene,
      {
        vertex: `${FLAT}`,
        fragment: `${FLAT}`,
      },
      {
        attributes: [POSITION_ATTRIBUTE, NORMAL_ATTRIBUTE, FACET_COLOR_ATTRIBUTE],
        uniforms: [WORLD_MATRIX, WORLD_VIEW_PROJECTION_MATRIX],
      },
    )

    this.shaderMaterial.backFaceCulling = false
  }

  dispose() {
    this.shaderMaterial.dispose()
  }
}

export class DepthShader extends MeshShader {
  constructor(renderScene: RenderScene) {
    super(renderScene, DEPTH)

    Effect.ShadersStore[this.vertexShaderName] = `
      #include<${VERTEX_SHADER_COMMON}>

      varying float distToCamera;

      uniform mat4 ${WORLD_MATRIX};
      uniform vec3 ${CAMERA_POSITION};
      uniform float ${MAX_CAMERA_DISTANCE};
      
      void main(void) {
        gl_Position = worldViewProjection * vec4(position, 1.0);
        vec4 positionW = world * vec4(position, 1.0);
        distToCamera = distance(${CAMERA_POSITION}, positionW.xyz) / ${MAX_CAMERA_DISTANCE};
      }
    `

    Effect.ShadersStore[this.fragmentShaderName] = `
      #include<${FRAGMENT_SHADER_COMMON}>
      
      varying float distToCamera;
      
      void main(void) {
        gl_FragColor = vec4(distToCamera, 0, 0, 1.);
      }
    `

    this.shaderMaterial = new ShaderMaterial(
      `${DEPTH}`,
      renderScene.getScene(),
      {
        vertex: `${DEPTH}`,
        fragment: `${DEPTH}`,
      },
      {
        attributes: [POSITION_ATTRIBUTE, NORMAL_ATTRIBUTE],
        uniforms: [WORLD_MATRIX, WORLD_VIEW_PROJECTION_MATRIX, CAMERA_POSITION, MAX_CAMERA_DISTANCE],
      },
    )

    this.shaderMaterial.backFaceCulling = false
    this.setupColorsAndLights()
    this.compileMaterial()
  }

  setMaxCameraDistance(distance: number) {
    this.shaderMaterial.setFloat(MAX_CAMERA_DISTANCE, distance)
  }

  dispose() {
    this.shaderMaterial.dispose()
  }
}

export class PickingShader {
  readonly shaderMaterial: ShaderMaterial

  private readonly vertexShaderName
  private readonly fragmentShaderName

  constructor(scene: Scene) {
    this.vertexShaderName = `${PICKING}${VERTEX_SHADER}`
    this.fragmentShaderName = `${PICKING}${FRAGMENT_SHADER}`

    Effect.ShadersStore[this.vertexShaderName] = `
      #include<${VERTEX_SHADER_COMMON}>
      #include<instancesDeclaration>

      // Attributes
      attribute vec3 ${COLOR_FOR_PART};
      attribute vec3 ${COLOR_FOR_FACE};
      attribute vec3 ${COLOR_FOR_BODY};
      
      // Varying
      varying vec3 vIdColor;

      // Uniforms
      uniform int ${COLOR_SWITCHER};
      
      void main(void) {
        #include<instancesVertex>
        gl_Position = ${VIEW_PROJECTION_MATRIX} * finalWorld * vec4(position, 1.0);
        if (${COLOR_SWITCHER} == 0) {
          vIdColor = ${COLOR_FOR_PART};
        }
        if (${COLOR_SWITCHER} == 1) {
          vIdColor = ${COLOR_FOR_FACE};
        }
        if (${COLOR_SWITCHER} == 2) {
          vIdColor = ${COLOR_FOR_BODY};
        }
      }
    `

    Effect.ShadersStore[this.fragmentShaderName] = `
      precision highp float;

      // Varying
      varying vec3 vIdColor;

      // Uniforms
      uniform mat4 ${WORLD_MATRIX};

      void main(void) {
        gl_FragColor = vec4(vIdColor, 1.0);
      }
    `

    this.shaderMaterial = new ShaderMaterial(
      `${PICKING}`,
      scene,
      {
        vertex: `${PICKING}`,
        fragment: `${PICKING}`,
      },
      {
        attributes: [POSITION_ATTRIBUTE, NORMAL_ATTRIBUTE, COLOR_FOR_PART, COLOR_FOR_FACE, COLOR_FOR_BODY],
        uniforms: [WORLD_MATRIX, WORLD_VIEW_PROJECTION_MATRIX, VIEW_PROJECTION_MATRIX],
      },
    )

    this.shaderMaterial.backFaceCulling = false
    this.enablePartsColoring()
  }

  enablePartsColoring() {
    this.shaderMaterial.setInt(COLOR_SWITCHER, 0)
  }

  enableFacesColoring() {
    this.shaderMaterial.setInt(COLOR_SWITCHER, 1)
  }

  enableBodiesColoring() {
    this.shaderMaterial.setInt(COLOR_SWITCHER, 2)
  }

  dispose() {
    this.shaderMaterial.dispose()
  }
}

export class SectionColoringShader extends MeshShader {
  private vSectionsNb = 4
  private hSectionsNb = 2
  private colors: Color3[] = []

  constructor(renderScene: RenderScene) {
    super(renderScene, SECTION_COLORING)
    for (let i = 0; i < this.vSectionsNb * this.hSectionsNb; i += 1) {
      if ((this.vSectionsNb * this.hSectionsNb - i) % 3 === 0) {
        this.colors.push(
          new Color3(
            i / (this.vSectionsNb * this.hSectionsNb),
            0.5 + i / (2 * this.vSectionsNb * this.hSectionsNb),
            0.0,
          ),
        )
        continue
      } else if (i % 3 === 0) {
        this.colors.push(
          new Color3(
            0.0,
            i / (this.vSectionsNb * this.hSectionsNb),
            0.5 + i / (2 * this.vSectionsNb * this.hSectionsNb),
          ),
        )
        continue
      } else {
        this.colors.push(
          new Color3(
            0.5 + i / (2 * this.vSectionsNb * this.hSectionsNb),
            0.0,
            i / (this.vSectionsNb * this.hSectionsNb),
          ),
        )
      }
    }

    Effect.ShadersStore[this.vertexShaderName] = `
    #include<${VERTEX_SHADER_COMMON}>
    
		void main(void) {
			vec4 outPosition = ${WORLD_VIEW_PROJECTION_MATRIX} * vec4(${POSITION_ATTRIBUTE}, 1.);
			gl_Position = outPosition;
		
			vPosition = ${POSITION_ATTRIBUTE};
			vNormal = ${NORMAL_ATTRIBUTE};
    }
    `

    Effect.ShadersStore[this.fragmentShaderName] = `
    #include<${FRAGMENT_SHADER_COMMON}>
		
		// Refs
		uniform vec3 ${SECTIONS_COLORS}[${this.vSectionsNb * this.hSectionsNb}];
		uniform int ${VERTICAL_SECTIONS_COUNT};
		uniform int ${HORIZONTAL_SECTIONS_COUNT};
		
		void main(void) {
			// World values
			vec3 vPositionW = vec3(${WORLD_MATRIX} * vec4(vPosition, 1.0));
			vec3 vNormalW = normalize(vec3(${WORLD_MATRIX} * vec4(vNormal, 0.0)));
					
			int Sy = int((2 * EXT_ANGLE) / ${VERTICAL_SECTIONS_COUNT});
			int Sx = int(EXT_ANGLE / ${HORIZONTAL_SECTIONS_COUNT});
			
			vec2 xAxis = vec2(1, 0);

			// Angle arounf Oy. Calculate in XZ plane
			vec2 projXZ = vec2(vNormalW.x, vNormalW.z);
			
			float angleXZ = 0.;

			if (equals(projXZ.x, 0.) && equals(projXZ.y, 0.)) {
				if (vNormalW.y > 0.) {
					angleXZ = 0.;
				} else {
					angleXZ = float(EXT_ANGLE);
				}
			}
			else
			{
				angleXZ = degrees(acos(dot(xAxis, normalize(projXZ))));
				if (projXZ.y < 0.) {
					angleXZ = float(2 * EXT_ANGLE) - angleXZ;
				}
			}
				
			// Angle aroung Ox. Calculate in YZ plane
			vec2 projYZ = vec2(vNormalW.y, vNormalW.z);
			
			float angleYZ = degrees(acos(dot(xAxis, normalize(projYZ))));
			
			// If x == 0 and y == 0 angleYZ is undefined
			if (equals(projYZ.x, 0.) && equals(projYZ.y, 0.)) {
				angleYZ = float(EXT_ANGLE / 2);
			}
			
			// Get color indices
			int row = int(ceil(angleYZ) / float(Sx));
			int col = int(ceil(angleXZ) / float(Sy));

			// Subtract 1 from the index if angles has maximum value
			// So the index does not fall outside the colors array
			if (row == ${HORIZONTAL_SECTIONS_COUNT}) {
				row -= 1;
			}
			if (col == ${VERTICAL_SECTIONS_COUNT}) {
				col -= 1;
			}

			// Apply color
			gl_FragColor = vec4(${SECTIONS_COLORS}[row * ${VERTICAL_SECTIONS_COUNT} + col].rgb, 1.);
		}`

    // Create babylon ShaderMaterial to use the shader as a material
    // Vertex and fragment shaders come from the shader store
    // An attribute defines a portion of a vertex
    // A uniform is a variable used by the shader and defined by the CPU
    this.shaderMaterial = new ShaderMaterial(
      SECTION_COLORING,
      this.scene,
      {
        vertex: SECTION_COLORING,
        fragment: SECTION_COLORING,
      },
      {
        attributes: [POSITION_ATTRIBUTE, NORMAL_ATTRIBUTE],
        uniforms: [WORLD_MATRIX, WORLD_VIEW_PROJECTION_MATRIX],
      },
    )

    this.shaderMaterial.backFaceCulling = false

    // Put colors array to the shader
    this.shaderMaterial.setColor3Array(SECTIONS_COLORS, this.colors)

    // Put sections count along ground normal and orthogonal normal
    this.shaderMaterial.setInt(VERTICAL_SECTIONS_COUNT, this.vSectionsNb)
    this.shaderMaterial.setInt(HORIZONTAL_SECTIONS_COUNT, this.hSectionsNb)

    this.compileMaterial()
  }
}

export class FaceColoringShader extends MeshShader {
  readonly maxSelectedFacesCount: number
  readonly maxInstancesCount: number

  constructor(
    renderScene: RenderScene,
    selectionMaterial?: StandardMaterial,
    highlightMaterial?: StandardMaterial,
    regularMaterial?: StandardMaterial,
  ) {
    super(renderScene, FACE_COLORING, regularMaterial)
    const engineCaps = this.scene.getEngine().getCaps()
    // engine has limitation of total max vertex uniform vectors depends on running machine
    // reserve some amount of uniforms for future use, left amount take for maximum amount of selected faces
    // note: uniform mat4 represents 4 uniform vectors
    const numberOfUniformVectors = 50
    this.maxSelectedFacesCount = engineCaps.maxVertexUniformVectors - numberOfUniformVectors

    Effect.ShadersStore[this.vertexShaderName] = `
      #include<${VERTEX_SHADER_COMMON}>
      #include<instancesDeclaration>

      // Attributes
      attribute float ${FACE_ID_ATTRIBUTE};
      attribute float ${HOVER_FACE_ID};
      
      // Uniforms
      uniform vec2 ${SELECTED_FACES_ID}[${this.maxSelectedFacesCount}];
      uniform int ${SELECTED_FACES_COUNT};
      uniform int ${SHOW_HOVER};
      
      void main(void) {
        #include<instancesVertex>
        vec4 worldPosition = finalWorld * vec4(${POSITION_ATTRIBUTE}, 1.); 
        vPosition = vec3(worldPosition);
        vNormal = normalize(vec3(finalWorld * vec4(${NORMAL_ATTRIBUTE}, 0.)));;
        vColor = ${REGULAR_COLOR};
        gl_Position = ${VIEW_PROJECTION_MATRIX} * worldPosition;
        
        if (bool(${SHOW_HOVER}) && int(${FACE_ID_ATTRIBUTE}) == int(${HOVER_FACE_ID})) {
          vColor = ${HOVER_COLOR};
        }

        for (int i = 0; i < ${SELECTED_FACES_COUNT}; i++) {
          if (gl_InstanceID == int(${SELECTED_FACES_ID}[i].x) && 
          int(${FACE_ID_ATTRIBUTE}) == int(${SELECTED_FACES_ID}[i].y)) {
            vColor = ${SELECTION_COLOR};
          }
        }
      }
    `

    Effect.ShadersStore[this.fragmentShaderName] = `
      #include<${FRAGMENT_SHADER_COMMON}>

      void main(void) {
        // World values
        vec3 vPositionW = vec3(${WORLD_MATRIX} * vec4(vPosition, 1.0));
        vec3 vNormalW = normalize(vec3(${WORLD_MATRIX} * vec4(vNormal, 0.0)));

        vec3 colorWithLights = CalcColorLights(vPositionW, vNormalW, vColor, 
          ${CAMERA_POSITION}, ${AMBIENT_LIGHT_DIRECTION}, ${POINT_LIGHT_POSITION});
        gl_FragColor = vec4(colorWithLights, 1.);
      }
    `

    this.shaderMaterial = new ShaderMaterial(
      FACE_COLORING,
      this.scene,
      {
        vertex: FACE_COLORING,
        fragment: FACE_COLORING,
      },
      {
        attributes: [POSITION_ATTRIBUTE, NORMAL_ATTRIBUTE, FACE_ID_ATTRIBUTE, HOVER_FACE_ID],
        uniforms: [WORLD_MATRIX, WORLD_VIEW_PROJECTION_MATRIX, VIEW_PROJECTION_MATRIX],
      },
    )

    this.shaderMaterial.backFaceCulling = false
    if (selectionMaterial) {
      this.shaderMaterial.setColor3(SELECTION_COLOR, selectionMaterial.diffuseColor)
    }
    if (highlightMaterial) {
      this.shaderMaterial.setColor3(HOVER_COLOR, highlightMaterial.diffuseColor)
    }
    this.shaderMaterial.setInt(SHOW_HOVER, 0)
    this.shaderMaterial.setInt(SELECTED_FACES_COUNT, 0)
    this.shaderMaterial.setArray2(SELECTED_FACES_ID, [-1, -1])
    this.setupColorsAndLights()
    this.compileMaterial()
  }

  setSelectedFacesId(facesIds: Map<number, number[]>) {
    const selected = []
    if (!facesIds) {
      selected.push(-1, -1)
    } else {
      facesIds.forEach((value, key) => {
        for (const faceId of value) {
          selected.push(key, faceId)
        }
      })
    }

    this.shaderMaterial.setInt(SELECTED_FACES_COUNT, selected.length / 2)
    this.shaderMaterial.setArray2(SELECTED_FACES_ID, selected)
  }

  showHover(showHover: boolean) {
    this.shaderMaterial.setInt(SHOW_HOVER, showHover ? 1 : 0)
  }
}

export class MultiItemFaceColoringShader extends MeshShader {
  readonly maxSelectedFacesCount: number
  readonly maxInstancesCount: number

  constructor(renderScene: RenderScene, highlightMaterial?: StandardMaterial, regularMaterial?: StandardMaterial) {
    super(renderScene, MULTI_ITEM_FACE_COLORING, regularMaterial)
    const engineCaps = this.scene.getEngine().getCaps()
    // engine has limitation of total max vertex uniform vectors depends on running machine
    // reserve some amount of uniforms for future use, left amount take for maximum amount of selected faces
    // note: uniform mat4 represents 4 uniform vectors
    const numberOfUniformVectors = 50
    this.maxSelectedFacesCount = engineCaps.maxVertexUniformVectors - numberOfUniformVectors

    Effect.ShadersStore[this.vertexShaderName] = `
      #include<${VERTEX_SHADER_COMMON}>
      #include<instancesDeclaration>

      // Attributes
      attribute float ${FACE_ID_ATTRIBUTE};
      attribute float ${HOVER_FACE_ID};
      attribute float ${COLLECTOR_INSTANCE_ID};
      
      // Uniforms
      uniform vec3 ${SELECTED_FACES_ID}[int(floor(float(${this.maxSelectedFacesCount / 5})))];
      uniform int ${SELECTED_FACES_COUNT};
      uniform int ${SHOW_HOVER};
      uniform vec3 ${MULTI_ITEM_COLLECTOR_SELECTION_COLORS}[int(floor(float(${this.maxSelectedFacesCount / 5})))];
      uniform vec3 ${MULTI_ITEM_COLLECTOR_INSTANCES_COLORS}[int(floor(float(${this.maxSelectedFacesCount / 5})))];
      uniform vec2 ${MULTI_ITEM_COLLECTOR_INSTANCES}[int(floor(float(${this.maxSelectedFacesCount / 5})))];
      uniform int ${INSTANCES_COUNT};
      uniform vec2 ${MULTI_ITEM_COLLECTOR_HIGHLIGHT_FACES}[int(floor(float(${this.maxSelectedFacesCount / 5})))];
      uniform int ${HIGHLIGHTED_FACES_COUNT};

      void main(void) {
        #include<instancesVertex>
        vec4 worldPosition = finalWorld * vec4(${POSITION_ATTRIBUTE}, 1.); 
        vPosition = vec3(worldPosition);
        vNormal = normalize(vec3(finalWorld * vec4(${NORMAL_ATTRIBUTE}, 0.)));;
        vColor = ${REGULAR_COLOR};
        gl_Position = ${VIEW_PROJECTION_MATRIX} * worldPosition;

        // set default instance color
        for (int i = 0; i < ${INSTANCES_COUNT}; i++) {
          if (int(${COLLECTOR_INSTANCE_ID}) == int(${MULTI_ITEM_COLLECTOR_INSTANCES}[i].x)) {
            vColor = ${MULTI_ITEM_COLLECTOR_INSTANCES_COLORS}[int(${MULTI_ITEM_COLLECTOR_INSTANCES}[i].y)];
          }
        }
      
        // set selected faces color
        for (int i = 0; i < ${SELECTED_FACES_COUNT}; i++) {
          if (int(${COLLECTOR_INSTANCE_ID}) == int(${SELECTED_FACES_ID}[i].x) && 
          int(${FACE_ID_ATTRIBUTE}) == int(${SELECTED_FACES_ID}[i].y)) {
            vColor = ${MULTI_ITEM_COLLECTOR_SELECTION_COLORS}[int(${SELECTED_FACES_ID}[i].z)];
          }
        }

        // set highlighted faces color
        for (int i = 0; i < ${HIGHLIGHTED_FACES_COUNT}; i++) {
          if (int(${COLLECTOR_INSTANCE_ID}) == int(${MULTI_ITEM_COLLECTOR_HIGHLIGHT_FACES}[i].x) &&
          int(${FACE_ID_ATTRIBUTE}) == int(${MULTI_ITEM_COLLECTOR_HIGHLIGHT_FACES}[i].y)
          ) {
          vColor = ${HOVER_COLOR};
          }
        }
       }
    `

    Effect.ShadersStore[this.fragmentShaderName] = `
      #include<${FRAGMENT_SHADER_COMMON}>

      void main(void) {
        // World values
        vec3 vPositionW = vec3(${WORLD_MATRIX} * vec4(vPosition, 1.0));
        vec3 vNormalW = normalize(vec3(${WORLD_MATRIX} * vec4(vNormal, 0.0)));

        vec3 colorWithLights = CalcColorLights(vPositionW, vNormalW, vColor, 
          ${CAMERA_POSITION}, ${AMBIENT_LIGHT_DIRECTION}, ${POINT_LIGHT_POSITION});
        gl_FragColor = vec4(colorWithLights, 1.);
      }
    `

    this.shaderMaterial = new ShaderMaterial(
      MULTI_ITEM_FACE_COLORING,
      this.scene,
      {
        vertex: MULTI_ITEM_FACE_COLORING,
        fragment: MULTI_ITEM_FACE_COLORING,
      },
      {
        attributes: [POSITION_ATTRIBUTE, NORMAL_ATTRIBUTE, FACE_ID_ATTRIBUTE, HOVER_FACE_ID, COLLECTOR_INSTANCE_ID],
        uniforms: [
          WORLD_MATRIX,
          WORLD_VIEW_PROJECTION_MATRIX,
          VIEW_PROJECTION_MATRIX,
          MULTI_ITEM_COLLECTOR_SELECTION_COLORS,
          MULTI_ITEM_COLLECTOR_HIGHLIGHT_FACES,
        ],
      },
    )

    this.shaderMaterial.backFaceCulling = false

    if (highlightMaterial) {
      this.shaderMaterial.setColor3(HOVER_COLOR, highlightMaterial.diffuseColor)
    }

    this.shaderMaterial.setInt(SHOW_HOVER, 0)
    this.shaderMaterial.setInt(SELECTED_FACES_COUNT, 0)
    this.shaderMaterial.setInt(INSTANCES_COUNT, 0)
    this.shaderMaterial.setInt(HIGHLIGHTED_FACES_COUNT, 0)
    this.shaderMaterial.setArray3(SELECTED_FACES_ID, [-1, -1, -1])
    this.shaderMaterial.setArray2(MULTI_ITEM_COLLECTOR_HIGHLIGHT_FACES, [-1, -1])
    this.setupColorsAndLights()
    this.compileMaterial()
  }

  setSelectedFacesId(facesMap: Map<number, Array<{ faceId: number; selectionColor: Color3 }>>) {
    const selected = []
    const selectionColors = []
    if (!facesMap) {
      selected.push(-1, -1, -1)
    } else {
      facesMap.forEach((value, key) => {
        for (const { faceId, selectionColor } of value) {
          let colorIndex = selectionColors.findIndex((color) => color === selectionColor)
          if (colorIndex === -1) {
            colorIndex = selectionColors.push(selectionColor) - 1
          }

          selected.push(key, faceId, colorIndex)
        }
      })
    }

    const length = selected.length / 3

    if (!selected.length) {
      selected.push(-1, -1, -1)
    }

    this.shaderMaterial.setInt(SELECTED_FACES_COUNT, length)
    if (length) {
      this.shaderMaterial.setColor3Array(MULTI_ITEM_COLLECTOR_SELECTION_COLORS, selectionColors)
    }

    this.shaderMaterial.setArray3(SELECTED_FACES_ID, selected)
  }

  showHover(showHover: boolean) {
    this.shaderMaterial.setInt(SHOW_HOVER, showHover ? 1 : 0)
  }

  setInstancesData(instances: [], colors: []) {
    this.shaderMaterial.setInt(INSTANCES_COUNT, instances.length / 2)
    this.shaderMaterial.setColor3Array(MULTI_ITEM_COLLECTOR_INSTANCES_COLORS, colors)
    this.shaderMaterial.setArray2(MULTI_ITEM_COLLECTOR_INSTANCES, instances.length ? instances : [-1, -1])
  }

  setHighlightedFacesId(facesIds: Map<number, number[]>) {
    const highlighted = []
    if (!facesIds || !facesIds.size) {
      highlighted.push(-1, -1)
    } else {
      facesIds.forEach((value, key) => {
        for (const faceId of value) {
          highlighted.push(key, faceId)
        }
      })
    }

    const length = highlighted.length / 2
    if (!highlighted.length) {
      highlighted.push(-1, -1)
    }

    this.shaderMaterial.setInt(HIGHLIGHTED_FACES_COUNT, length)
    this.shaderMaterial.setArray2(MULTI_ITEM_COLLECTOR_HIGHLIGHT_FACES, highlighted)
  }
}

export class FacetColoringShader extends MeshShader {
  constructor(renderScene: RenderScene, highlightMaterial?: StandardMaterial, regularMaterial?: StandardMaterial) {
    super(renderScene, FACET_COLORING, regularMaterial)

    Effect.ShadersStore[this.vertexShaderName] = `
      #include<${VERTEX_SHADER_COMMON}>
      #include<instancesDeclaration>

      // Uniforms
      uniform int ${HIGHLIGHT_INSTANCE_ID};
      uniform vec3 ${HOVER_FACET};

      void main(void) {
        #include<instancesVertex>
        vec4 worldPosition = finalWorld * vec4(${POSITION_ATTRIBUTE}, 1.); 
        vPosition = vec3(worldPosition);
        vNormal = normalize(vec3(finalWorld * vec4(${NORMAL_ATTRIBUTE}, 0.)));;
        vColor = ${REGULAR_COLOR};
        gl_Position = ${VIEW_PROJECTION_MATRIX} * worldPosition;

        if (${HIGHLIGHT_INSTANCE_ID} == gl_InstanceID && (int(${HOVER_FACET}.x) == gl_VertexID || 
        int(${HOVER_FACET}.y) == gl_VertexID || int(${HOVER_FACET}.z) == gl_VertexID)) {
          vColor = ${HOVER_COLOR};
        }
      }
    `

    Effect.ShadersStore[this.fragmentShaderName] = `
      #include<${FRAGMENT_SHADER_COMMON}>

      void main(void) {
        vec3 colorWithLights = CalcColorLights(vPosition, vNormal, vColor, 
          ${CAMERA_POSITION}, ${AMBIENT_LIGHT_DIRECTION}, ${POINT_LIGHT_POSITION});
        gl_FragColor = vec4(colorWithLights, 1.);
      }
    `

    this.shaderMaterial = new ShaderMaterial(
      FACET_COLORING,
      this.scene,
      {
        vertex: FACET_COLORING,
        fragment: FACET_COLORING,
      },
      {
        attributes: [POSITION_ATTRIBUTE, NORMAL_ATTRIBUTE, HIGHLIGHT_INSTANCE_ID, HOVER_FACET],
        uniforms: [WORLD_MATRIX, WORLD_VIEW_PROJECTION_MATRIX, VIEW_PROJECTION_MATRIX],
      },
    )

    this.shaderMaterial.backFaceCulling = false
    if (highlightMaterial) {
      this.shaderMaterial.setColor3(HOVER_COLOR, highlightMaterial.diffuseColor)
    }
    this.shaderMaterial.setInt(HIGHLIGHT_INSTANCE_ID, -1)
    this.shaderMaterial.setVector3(HOVER_FACET, new Vector3(-1, -1, -1))
    this.setupColorsAndLights()
    this.compileMaterial()
  }

  showHover(instanceId: number, facet: Vector3) {
    if (isNumber(instanceId) && facet) {
      this.shaderMaterial.setInt(HIGHLIGHT_INSTANCE_ID, instanceId)
      this.shaderMaterial.setVector3(HOVER_FACET, facet)
    } else {
      this.shaderMaterial.setInt(HIGHLIGHT_INSTANCE_ID, -1)
      this.shaderMaterial.setVector3(HOVER_FACET, new Vector3(-1, -1, -1))
    }
  }
}

export class OverhangShader extends MeshShader {
  private readonly maxSelectedFacesCount: number
  private readonly maxErrorFacesCount: number

  constructor(
    renderScene: RenderScene,
    selectionMaterial?: StandardMaterial,
    highlightMaterial?: StandardMaterial,
    regularMaterial?: StandardMaterial,
    errorMaterial?: StandardMaterial,
  ) {
    super(renderScene, FACE_COLORING, regularMaterial)
    const engineCaps = this.scene.getEngine().getCaps()
    // engine has limitation of total max vertex uniform vectors depends on running machine
    // reserve some amount of uniforms for future use, left amount take for maximum amount of selected faces
    // note: uniform mat4 represents 4 uniform vectors
    const numberOfUniformVectors = 50
    const coloredFacesLimit = engineCaps.maxVertexUniformVectors - numberOfUniformVectors
    this.maxSelectedFacesCount = Math.floor(coloredFacesLimit / 2)
    this.maxErrorFacesCount = this.maxSelectedFacesCount

    Effect.ShadersStore[this.vertexShaderName] = `
      #include<${VERTEX_SHADER_COMMON}>
      #include<instancesDeclaration>

      // Attributes
      attribute float ${FACE_ID_ATTRIBUTE};
      attribute float ${HOVER_FACE_ID};
      
      // Uniforms
      uniform vec2 ${SELECTED_FACES_ID}[${this.maxSelectedFacesCount}];
      uniform float ${ERROR_FACES_ID}[${this.maxErrorFacesCount}];
      uniform int ${SELECTED_FACES_COUNT};
      uniform int ${ERROR_FACES_COUNT};
      uniform int ${SHOW_HOVER};
      
      // Refs
      uniform vec3 ${ERROR_COLOR};

      void main(void) {
        #include<instancesVertex>
        gl_Position = ${VIEW_PROJECTION_MATRIX} * finalWorld * vec4(${POSITION_ATTRIBUTE}, 1.0);
        vPosition = ${POSITION_ATTRIBUTE};
        vNormal = ${NORMAL_ATTRIBUTE};
        vColor = ${REGULAR_COLOR};

        for (int i = 0; i < ${ERROR_FACES_COUNT}; i++) {
          if (int(${FACE_ID_ATTRIBUTE}) == int(${ERROR_FACES_ID}[i])) {
            vColor = ${ERROR_COLOR};
          }
        }

        for (int i = 0; i < ${SELECTED_FACES_COUNT}; i++) {
          if (gl_InstanceID == int(${SELECTED_FACES_ID}[i].x) && 
          int(${FACE_ID_ATTRIBUTE}) == int(${SELECTED_FACES_ID}[i].y)) {
            vColor = ${SELECTION_COLOR};
          }
        }

        if (bool(${SHOW_HOVER}) && int(${FACE_ID_ATTRIBUTE}) == int(${HOVER_FACE_ID})) {
          vColor = ${HOVER_COLOR};
        }
      }
    `

    Effect.ShadersStore[this.fragmentShaderName] = `
      #include<${FRAGMENT_SHADER_COMMON}>

      vec3 CalcDoublesidedColorLights(
        vec3 pos,
        vec3 norm,
        vec3 color,
        vec3 cameraPos,
        vec3 ambientLightDir,
        vec3 pointLightPos
      )
      {
        vec3 ambientLightDirN = normalize(ambientLightDir);
        vec3 viewDirectionW = normalize(cameraPos - pos);
        float ndl = 0.5 + 0.5 * dot(ambientLightDirN, norm);
        vec3 lightAngleW = normalize(viewDirectionW + ambientLightDirN);
        float specCompA = abs(dot(norm, lightAngleW));
        specCompA = pow(specCompA, max(1., 64.));
        vec3 cameraLightVectorW = normalize(pointLightPos - pos);
        float cameraNdl = abs(dot(norm, cameraLightVectorW));
        vec3 cameraAngleW = normalize(viewDirectionW + cameraLightVectorW);
        float specCompP = abs(dot(norm, cameraAngleW));
        specCompP = pow(specCompP, max(1., 64.));
        return (color * ndl + vec3(specCompA) * specularColor) + (color * cameraNdl + vec3(specCompP) * specularColor);
      }
      
      void main(void) {
        // World values
        vec3 vPositionW = vec3(${WORLD_MATRIX} * vec4(vPosition, 1.0));
        vec3 vNormalW = normalize(vec3(${WORLD_MATRIX} * vec4(vNormal, 0.0)));
        vec3 colorWithLights = CalcDoublesidedColorLights(vPositionW, vNormalW, vColor,
        ${CAMERA_POSITION}, ${AMBIENT_LIGHT_DIRECTION}, ${POINT_LIGHT_POSITION});
        // For now we use vColor instead of colorWithLights
        gl_FragColor = vec4(vColor, 1.);
      }
    `

    this.shaderMaterial = new ShaderMaterial(
      FACE_COLORING,
      this.scene,
      {
        vertex: FACE_COLORING,
        fragment: FACE_COLORING,
      },
      {
        attributes: [POSITION_ATTRIBUTE, NORMAL_ATTRIBUTE, FACE_ID_ATTRIBUTE, HOVER_FACE_ID],
        uniforms: [WORLD_MATRIX, WORLD_VIEW_PROJECTION_MATRIX, VIEW_PROJECTION_MATRIX],
      },
    )

    this.shaderMaterial.backFaceCulling = false
    if (selectionMaterial) {
      this.shaderMaterial.setColor3(SELECTION_COLOR, selectionMaterial.diffuseColor)
    }

    if (highlightMaterial) {
      this.shaderMaterial.setColor3(HOVER_COLOR, highlightMaterial.diffuseColor)
    }

    if (errorMaterial) {
      this.shaderMaterial.setColor3(ERROR_COLOR, errorMaterial.diffuseColor)
    }

    this.shaderMaterial.setInt(SHOW_HOVER, 0)
    this.shaderMaterial.setInt(SELECTED_FACES_COUNT, 0)
    this.shaderMaterial.setArray2(SELECTED_FACES_ID, [-1, -1])
    this.shaderMaterial.setInt(ERROR_FACES_COUNT, 0)
    this.shaderMaterial.setFloats(ERROR_FACES_ID, [-1])
    this.setupColorsAndLights()
    this.compileMaterial()
  }

  setSelectedFacesId(facesIds: Map<number, number[]>) {
    const selected = []
    if (!facesIds || !facesIds.size) {
      selected.push(-1, -1)
    } else {
      facesIds.forEach((value, key) => {
        for (const faceId of value) {
          selected.push(key, faceId)
        }
      })
    }

    this.shaderMaterial.setInt(SELECTED_FACES_COUNT, selected.length / 2)
    this.shaderMaterial.setArray2(SELECTED_FACES_ID, selected)
  }

  setErrorFacesId(facesId: number[]) {
    this.shaderMaterial.setInt(ERROR_FACES_COUNT, facesId.length)
    if (facesId.length) {
      this.shaderMaterial.setFloats(ERROR_FACES_ID, facesId)
    } else {
      this.shaderMaterial.setFloats(ERROR_FACES_ID, [-1])
    }
  }

  showHover(showHover: boolean) {
    this.shaderMaterial.setInt(SHOW_HOVER, showHover ? 1 : 0)
  }
}

export class OverhangAreasShader extends MeshShader {
  private overhangAreaColor = Color3.Green()

  constructor(renderScene: RenderScene, overhangAngle: float) {
    super(renderScene, OVERHANG_AREAS)
    Effect.ShadersStore[this.vertexShaderName] = `
      #include<${VERTEX_SHADER_COMMON}>
      #include<instancesDeclaration>
      
      flat out int ${INSTANCE_ID};
      attribute float ${COLLECTOR_INSTANCE_ID};

      void main(void) {
        #include<instancesVertex>
        vec4 worldPosition = finalWorld * vec4(${POSITION_ATTRIBUTE}, 1.); 
        
        vPosition = vec3(worldPosition);
        vNormal = normalize(vec3(finalWorld * vec4(${NORMAL_ATTRIBUTE}, 0.)));;

        gl_Position = ${VIEW_PROJECTION_MATRIX} * worldPosition;
        ${INSTANCE_ID} = int(${COLLECTOR_INSTANCE_ID});
      }
    `

    Effect.ShadersStore[this.fragmentShaderName] = `
      #include<${FRAGMENT_SHADER_COMMON}>
      
      // Refs
      uniform float ${OVERHANG_ANGLE};
      uniform vec3 ${OVERHANG_AREA_COLOR};
      uniform int ${HIGHLIGHT_INSTANCE_ID};

      flat in int ${INSTANCE_ID};

      void main(void) {
        // Calculate angle between -Z axis and vertex normal
        // Colorize vertex in special color if it overhangs
        vec3 zAxisDown = vec3(0., 0., -1.);
        float angle = degrees(acos(dot(zAxisDown, vNormal)));
        vec3 color = vec3(0., 0., 0.);
        if (${INSTANCE_ID} == ${HIGHLIGHT_INSTANCE_ID} && angle >= 0. && angle <= ${OVERHANG_ANGLE}) {
          color = ${OVERHANG_AREA_COLOR};
        } else {
          color = ${REGULAR_COLOR};
        }

        vec3 colorWithLights = CalcColorLights(vPosition, vNormal, color, 
          ${CAMERA_POSITION}, ${AMBIENT_LIGHT_DIRECTION}, ${POINT_LIGHT_POSITION});
        gl_FragColor = vec4(colorWithLights, 1.);
      }
    `

    // Create babylon ShaderMaterial to use the shader as a material
    // Vertex and fragment shaders come from the shader store
    // An attribute defines a portion of a vertex
    // A uniform is a variable used by the shader and defined by the CPU
    this.shaderMaterial = new ShaderMaterial(
      OVERHANG_AREAS,
      this.scene,
      {
        vertex: OVERHANG_AREAS,
        fragment: OVERHANG_AREAS,
      },
      {
        attributes: [POSITION_ATTRIBUTE, NORMAL_ATTRIBUTE, COLLECTOR_INSTANCE_ID],
        uniforms: [WORLD_MATRIX, WORLD_VIEW_PROJECTION_MATRIX, VIEW_PROJECTION_MATRIX, HIGHLIGHT_INSTANCE_ID],
      },
    )

    this.shaderMaterial.backFaceCulling = false
    this.shaderMaterial.setFloat(OVERHANG_ANGLE, overhangAngle)
    this.shaderMaterial.setColor3(OVERHANG_AREA_COLOR, this.overhangAreaColor)
    this.shaderMaterial.setInt(HIGHLIGHT_INSTANCE_ID, -1)
    this.setupColorsAndLights()
    this.compileMaterial()
  }
}

export class RecoaterDirectionShader extends MeshShader {
  private recoaterDirectionColor = Color3.Yellow()

  constructor(renderScene: RenderScene, recoaterDirection: Vector3) {
    super(renderScene, RECOATER_DIRECTION)
    Effect.ShadersStore[this.vertexShaderName] = `
      #include<${VERTEX_SHADER_COMMON}>
      #include<instancesDeclaration>
      
      flat out int ${INSTANCE_ID};
      attribute float ${COLLECTOR_INSTANCE_ID};

      void main(void) {
        #include<instancesVertex>
        vec4 worldPosition = finalWorld * vec4(${POSITION_ATTRIBUTE}, 1.); 
        
        vPosition = vec3(worldPosition);
        vNormal = normalize(vec3(finalWorld * vec4(${NORMAL_ATTRIBUTE}, 0.)));;

        gl_Position = ${VIEW_PROJECTION_MATRIX} * worldPosition;
        ${INSTANCE_ID} = int(${COLLECTOR_INSTANCE_ID});
      }
      `

    Effect.ShadersStore[this.fragmentShaderName] = `
      #include<${FRAGMENT_SHADER_COMMON}>

      // Refs
      uniform float ${RECOATER_MAX_ANGLE};
      uniform vec3 ${RECOATER_DIRECTION};
      uniform vec3 ${RECOATER_DIRECTION_COLOR};
      uniform int ${HIGHLIGHT_INSTANCE_ID};

      flat in int ${INSTANCE_ID};

      void main(void) {        
        // Calculate angle between opposite recoater direction and vertex normal
        // Colorize vertex in special color if it will grow towards the recoater
        vec3 projXY = normalize(vec3(vNormal.x, vNormal.y, 0.));
        float angle = degrees(acos(dot(${RECOATER_DIRECTION}, projXY)));
        vec3 color = vec3(0., 0., 0.);
        if (${INSTANCE_ID} == ${HIGHLIGHT_INSTANCE_ID} && angle >= 0. && angle < ${RECOATER_MAX_ANGLE}) {
          color = ${RECOATER_DIRECTION_COLOR};
        } else {
          color = ${REGULAR_COLOR};
        }

        vec3 colorWithLights = CalcColorLights(vPosition, vNormal, color, 
          ${CAMERA_POSITION}, ${AMBIENT_LIGHT_DIRECTION}, ${POINT_LIGHT_POSITION});
        gl_FragColor = vec4(colorWithLights, 1.);
      }
    `

    // Create babylon ShaderMaterial to use the shader as a material
    // Vertex and fragment shaders come from the shader store
    // An attribute defines a portion of a vertex
    // A uniform is a variable used by the shader and defined by the CPU
    this.shaderMaterial = new ShaderMaterial(
      RECOATER_DIRECTION,
      this.scene,
      {
        vertex: RECOATER_DIRECTION,
        fragment: RECOATER_DIRECTION,
      },
      {
        attributes: [POSITION_ATTRIBUTE, NORMAL_ATTRIBUTE, COLLECTOR_INSTANCE_ID],
        uniforms: [WORLD_MATRIX, WORLD_VIEW_PROJECTION_MATRIX, VIEW_PROJECTION_MATRIX, HIGHLIGHT_INSTANCE_ID],
      },
    )

    this.shaderMaterial.backFaceCulling = false
    this.shaderMaterial.setVector3(RECOATER_DIRECTION, recoaterDirection)
    this.shaderMaterial.setFloat(RECOATER_MAX_ANGLE, RECOATER_MAX_ANGLE_VALUE)
    this.shaderMaterial.setColor3(RECOATER_DIRECTION_COLOR, this.recoaterDirectionColor)
    this.shaderMaterial.setInt(HIGHLIGHT_INSTANCE_ID, -1)
    this.setupColorsAndLights()
    this.compileMaterial()
  }
}

export class LabelAreasShader extends MeshShader {
  private labelAreaColor = new Color3(0.68, 0.89, 0.9)

  constructor(renderScene: RenderScene, overhangAngle: float, regularMaterial?: StandardMaterial) {
    super(renderScene, LABEL_AREAS, regularMaterial)
    Effect.ShadersStore[this.vertexShaderName] = `
      #include<${VERTEX_SHADER_COMMON}>
      
      void main(void) {
          vec4 outPosition = ${WORLD_VIEW_PROJECTION_MATRIX} * vec4(${POSITION_ATTRIBUTE}, 1.);
          gl_Position = outPosition;
      
          vPosition = ${POSITION_ATTRIBUTE};
          vNormal = ${NORMAL_ATTRIBUTE};
      }
    `

    Effect.ShadersStore[this.fragmentShaderName] = `
      #include<${FRAGMENT_SHADER_COMMON}>
      
      // Refs
      uniform float ${MAX_LABEL_ANGLE};
      uniform vec3 ${LABEL_AREA_COLOR};
          
      void main(void) {
        // World values
        vec3 vPositionW = vec3(${WORLD_MATRIX} * vec4(vPosition, 1.0));
        vec3 vNormalW = normalize(vec3(${WORLD_MATRIX} * vec4(vNormal, 0.0)));
        
        // Calculate angle between -Z axis and vertex normal
        // Colorize vertex in special color if it overhangs
        vec3 zAxisDown = vec3(0., 0., 1.);
        float angle = degrees(acos(dot(zAxisDown, vNormalW)));
        vec3 color = vec3(0., 0., 0.);
        if (angle >= 0. && angle <= ${MAX_LABEL_ANGLE}) {
          color = ${LABEL_AREA_COLOR};
        } else {
          color = ${REGULAR_COLOR};
          color = CalcColorLights(vPositionW, vNormalW, color, 
            ${CAMERA_POSITION}, ${AMBIENT_LIGHT_DIRECTION}, ${POINT_LIGHT_POSITION});
        }

        gl_FragColor = vec4(color, 1.);
      }
    `

    // Create babylon ShaderMaterial to use the shader as a material
    // Vertex and fragment shaders come from the shader store
    // An attribute defines a portion of a vertex
    // A uniform is a variable used by the shader and defined by the CPU
    this.shaderMaterial = new ShaderMaterial(
      LABEL_AREAS,
      this.scene,
      {
        vertex: LABEL_AREAS,
        fragment: LABEL_AREAS,
      },
      {
        attributes: [POSITION_ATTRIBUTE, NORMAL_ATTRIBUTE],
        uniforms: [WORLD_MATRIX, WORLD_VIEW_PROJECTION_MATRIX],
      },
    )

    this.shaderMaterial.backFaceCulling = false
    this.shaderMaterial.setFloat(MAX_LABEL_ANGLE, overhangAngle)
    this.shaderMaterial.setColor3(LABEL_AREA_COLOR, this.labelAreaColor)
    this.setupColorsAndLights()
    this.compileMaterial()
  }
}
