import Vue from 'vue'
import router, { RouterPaths } from '@/router'
import { landingPage } from '@/configs/config'
import store from '@/store'

import { auth0Plugin } from '../auth'

export default function initAuth0(config) {
  Vue.use(auth0Plugin, {
    store,
    audience: config.VUE_APP_AUDIENCE,
    clientId: config.VUE_APP_CLIENT_ID,
    domain: config.VUE_APP_DOMAIN,
    redirectPath: landingPage,
    onRedirectCallback: (appState) => {
      // @ts-ignore
      router.safePush(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
    },
    onAutologinRedirect: () => {
      // @ts-ignore
      router.safePush(router.defaultRoute)
    },
    onProgressiveRegistrationRedirect: () => {
      // @ts-ignore
      router.safePush(RouterPaths.ProgressiveRegistration)
    },
    onLoginErrorRedirect: (message: string) => {
      // @ts-ignore
      router.safePush({ path: RouterPaths.Error, query: { message } })
    },
  })
}
