
/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import Component from 'vue-class-component'
import { Mixins, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { extend, ValidationObserver } from 'vee-validate'
import { size, ext } from 'vee-validate/dist/rules'
import CommonBuildPlanToolsMixin from './mixins/CommonBuildPlanToolsMixin'
import PrintLabelTooltipContent from '@/components/layout/buildPlans/printOrder/PrintLabelTooltipContent.vue'
import Selector from '@/components/controls/Common/Selector.vue'
import TextField from '@/components/controls/Common/TextField.vue'
import StoresNamespaces from '@/store/namespaces'
import { IBuildPlan, IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { ISite } from '@/types/ISite'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import { IMarkTemplate } from '@/types/Marking/IMarkTemplate'
import { IMarkPatch } from '@/types/Marking/IMarkPatch'
import { IMarkPrint } from '@/types/Marking/IMarkPrint'
import { ILabel } from '@/types/Marking/ILabel'
import { IJob } from '@/types/PartsLibrary/Job'
import messageService from '@/services/messageService'
import buildPlans from '@/api/buildPlans'
import LabelToolTextField from '@/components/controls/LabelToolControls/LabelToolTextField.vue'
import {
  DEFAULT_LABEL_SET_SETTING,
  LATIN_CHARACTERS_PATTERN,
  PRINT_SITE_DOWNLOAD_DEFAULT_NAME,
  TEST_TENANTS,
} from '@/constants'
import { IUser } from '@/types/User/IUser'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import {
  MarkingContentElementType,
  MarkingLocation,
  CharacterLengthControl,
  TextAlign,
  TextAlignVertical,
  LabelSetMode,
} from '@/types/Label/enums'
import { TextElement, UserEntryType } from '@/types/Label/TextElement'
import { UserEntry } from '@/types/PrintOrder/UserEntry'
import { Patch } from '@/types/Label/Patch'
import { Setting } from '@/types/Label/Setting'
import { PrintOrderPatch } from '@/types/PrintOrder/PrintOrderPatch'

import { DEFAULT_PROMPT_TEXT } from './marking/elementSettings/UserEntrySettings.vue'
import { v4 as uuidv4 } from 'uuid'
import { RouterPaths } from '@/router'
import { LabelSetDto } from '@/types/Label/LabelSetDto'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const commonStore = namespace(StoresNamespaces.Common)
const userStore = namespace(StoresNamespaces.User)
const labelStore = namespace(StoresNamespaces.Labels)

extend('ext', ext)
extend('size', size)

@Component({
  name: 'PrintOrderTab',
  components: {
    Selector,
    TextField,
    PrintLabelTooltipContent,
    LabelToolTextField,
  },
})
export default class BuildPlanPrintOrderTab extends Mixins(CommonBuildPlanToolsMixin) implements IToolComponent {
  @buildPlansStore.Action fetchBuildPlanJobs: Function

  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter getPrintSites: ISite[]
  @buildPlansStore.Getter getAllBuildPlanMarkLabels: ILabel[]
  @buildPlansStore.Getter getSelectedBuildPlanMarkJobs: IJob[]

  @commonStore.Getter tooltipOpenDelay: number

  @labelStore.Getter('labelSets') storedlabelSets: InteractiveLabelSet[]

  @labelStore.Action deHighlightLabels: Function
  @labelStore.Action highlightLabels: Function
  @labelStore.Action getLabelSetsByBuildPlanId: (payload: {
    buildPlanId: string
    dirtyStateAddIfNew?: boolean
  }) => Promise<LabelSetDto[]>

  @visualizationStore.Mutation toggleLabelHighlight: Function
  @visualizationStore.Mutation toggleHighlight: Function
  @visualizationStore.Mutation updateLabelAppearance: Function

  @userStore.Getter('getUserDetails') getUser: IUser

  $refs!: {
    form: InstanceType<typeof ValidationObserver>
    markTemplateFile: InstanceType<typeof ValidationObserver>
  }

  @Prop({ default: false }) readonlyState: boolean
  @Prop() job: IJob
  @Prop({ default: true }) includeMarkTemplateFileUploader: boolean

  selectedPrintSiteId: number = null
  markTemplates: IMarkTemplate[] = []
  markPatches: IMarkPatch[] = []
  printSites: ISite[] = []
  markTemplateFile: File = null
  userEntries: UserEntry[] = []
  labelSets: LabelSetDto[] = []
  currentFocusedUserEntryNumber: number = null
  userEntryToLabelSetsMap: Map<number, InteractiveLabelSet[]> = new Map()

  get labelTextValidationRules() {
    return {
      nativeRules: {
        onlyLatinCharacters: true,
      },
    }
  }

  /**************************************
   * Generic tool method implementations
   **************************************/
  // need to mention these generic optional methods even if they are not implemented by the tool
  // due to TypeScript's weak type detection per https://stackoverflow.com/a/47930521
  clickCancel?: () => void

  beforeMount() {
    this.extendValidationRules()
  }

  async mounted() {
    this.setOkDisabled(true)
    const fetchedLabelSets = await this.getLabelSetsByBuildPlanId({ buildPlanId: this.buildPlan.id })

    // seek for legacy labels only if label sets do not exist
    if (!(Array.isArray(fetchedLabelSets) && fetchedLabelSets.length > 0)) {
      this.labelSets = await this.convertFromLegacy()
    } else {
      this.labelSets = fetchedLabelSets
    }

    this.initializePrintSites()
    this.initializeUserEntries()
  }

  async clickOk() {
    const allPatches: PrintOrderPatch[] = []
    const allSettings: Setting[] = []
    let settingsId: number = 0
    const userEntriesMap: Map<number, UserEntry> = new Map()
    this.userEntries.forEach((userEntry) => {
      userEntriesMap.set(userEntry.elementIDNumber, userEntry)
    })

    this.labelSets.forEach((labelset) => {
      const setting = { ...labelset.settings }
      setting.uniqueID = settingsId
      setting.placementAutoLocations = [MarkingLocation.NearestToPoint]
      allSettings.push(setting)
      settingsId = settingsId + 1
      labelset.patches.forEach((labelSetPatch) => {
        const patch: Patch = { ...labelSetPatch }
        setting.textElements.forEach((textElement, index) => {
          if (textElement.type === MarkingContentElementType.User_Entry) {
            patch.textContent[index] = userEntriesMap
              .get(textElement.elementIDNumber)
              .value.replace(/{/g, '{{')
              .replace(/}/g, '}}')
          }
        })
        const runTimeContentText = this.getRuntimeTextContentForPatch({ ...patch }, setting)
        const printOrderPatch: PrintOrderPatch = {
          ...patch,
          runTimeContentText,
        }
        printOrderPatch.settingsIDForJson = setting.uniqueID
        allPatches.push(printOrderPatch)
      })
    })

    let markRecipe: any
    let markTemplate: any
    if (allPatches.length > 0) {
      markRecipe = {
        contentElements: [],
        listOfSettings: allSettings,
        patches: allPatches,
      }
    } else if (this.markTemplateFile) {
      try {
        const markTemplateFile = this.markTemplateFile
        markTemplate = JSON.parse(await markTemplateFile.text()) as any
        const transformationMatrix: number[] = this.buildPlan.buildPlanItems[0].transformationMatrix
        if (Array.isArray(markTemplate.Patches)) {
          markTemplate.Patches.forEach((patch) => {
            patch.transformationMatrix = transformationMatrix
          })
        }
      } catch (error) {
        messageService.showErrorMessage('Unable to parse content of mark template file')
        return
      }
    }

    const data: IMarkPrint = {
      markRecipe,
      markTemplate,
      printSite: this.getPrintSites.find((printSite) => printSite.id === this.selectedPrintSiteId).name,
    }
    try {
      this.$emit('creatingJob')
      const buildPlanUrl = window.location.origin + RouterPaths.EditBuildPlan.replace(':id', this.buildPlan.id)
      const dataWithBPUrl = { ...data, buildPlanUrl }
      await buildPlans.createMarkPrintJob(this.buildPlan.id, dataWithBPUrl)
    } catch (error) {
      messageService.showErrorMessage(error.message)
    } finally {
      this.$emit('jobCreated')
    }
  }

  extendValidationRules() {
    extend('onlyLatinCharacters', {
      validate: (value) => {
        return new RegExp(LATIN_CHARACTERS_PATTERN).test(value)
      },
      message: this.$t('latinCharactersMismatch').toString(),
    })
  }

  initializePrintSites() {
    if (this.getPrintSites) {
      this.printSites = JSON.parse(JSON.stringify(this.getPrintSites)) // deep copy
      this.printSites = this.printSites.filter(
        (item) => item.name.trim().toLocaleLowerCase() !== PRINT_SITE_DOWNLOAD_DEFAULT_NAME,
      )
      return true
    }
  }

  initializeUserEntries() {
    const userEntriesMap: Map<number, UserEntry> = new Map()
    this.labelSets.forEach((labelSet) => {
      if (labelSet.settings && Array.isArray(labelSet.settings.textElements)) {
        labelSet.settings.textElements.forEach((textElement) => {
          if (textElement.type === MarkingContentElementType.User_Entry) {
            const userEntry: UserEntry = {
              ...textElement,
              value: this.getUserEntryPreview(textElement) || '',
              isValid: false,
            }
            userEntry.isValid = this.validateUserEntry(userEntry)
            userEntriesMap.set(textElement.elementIDNumber, userEntry)

            let labelSetArray = []
            if (this.userEntryToLabelSetsMap.has(textElement.elementIDNumber)) {
              labelSetArray = this.userEntryToLabelSetsMap.get(textElement.elementIDNumber)
            }
            labelSetArray.push(labelSet)
            this.userEntryToLabelSetsMap.set(textElement.elementIDNumber, labelSetArray)
          }
        })
      }
    })
    this.userEntries = [...userEntriesMap.values()]
    this.$forceUpdate()
  }

  @Watch('userEntries', { deep: true })
  async onFormFailed(value: any[], oldValue: any[]) {
    if (oldValue.length > 0) {
      this.$nextTick(async () => {
        const isFormValid = await this.$refs.form.validate()
        let hasErrors = false
        this.userEntries.forEach((userEntry) => {
          const isValid = this.validateUserEntry(userEntry)
          userEntry.isValid = isValid
          if (!hasErrors && !isValid) {
            hasErrors = true
          }
        })
        this.setOkDisabled(!isFormValid || hasErrors)
        this.$forceUpdate()
      })
    }
  }

  validateUserEntry(userEntry: UserEntry) {
    const min = userEntry.lengthMin
    const max = userEntry.lengthMax
    const valueLength = userEntry.value.length
    let isValidLength: boolean = false
    let isValidTextInput: boolean = false
    if (userEntry.lengthControl === CharacterLengthControl.Specific) {
      if (valueLength === max) {
        isValidLength = true
      }
    } else if (valueLength >= min && valueLength <= max) {
      isValidLength = true
    }
    isValidTextInput = new RegExp(LATIN_CHARACTERS_PATTERN).test(userEntry.value)
    return isValidLength && isValidTextInput
  }

  @Watch('selectedPrintSiteId')
  async onSiteChanged() {
    this.$nextTick(async () => {
      const isFormValid = await this.$refs.form.validate()
      let hasErrors = false
      this.userEntries.forEach((userEntry) => {
        const isValid = this.validateUserEntry(userEntry)
        userEntry.isValid = isValid
        if (!hasErrors && !isValid) {
          hasErrors = true
        }
      })
      this.setOkDisabled(!isFormValid || hasErrors)
      this.$forceUpdate()
    })
  }

  getOkName() {
    return 'create'
  }

  setOkDisabled(disabled: boolean) {
    this.$emit('setOkDisabled', disabled)
  }

  getPrintSiteSelectorRules() {
    return {
      required: true,
    }
  }

  getMarkTemplateFileInputRules() {
    return {
      ext: 'mark',
      size: 1024,
    }
  }

  getUserEntryRules(userEntry: TextElement) {
    return {
      required: true,
      min_value: userEntry.lengthMin,
      max_value: userEntry.lengthMax,
      onlyLatinCharacters: true,
    }
  }

  activeLabelStyle(styleId) {
    return this.markTemplates.find((template) => template.uniqueID === styleId)
  }

  getMaxCharacters(userEntry: TextElement) {
    return userEntry.lengthMax
  }

  setFocusedUserEntry(userEntry: TextElement) {
    this.currentFocusedUserEntryNumber = userEntry.elementIDNumber
  }

  getUserEntryPrompt(userEntry: TextElement) {
    let promptText = userEntry.title
    const json = userEntry._cachedSpecificsJSON ? JSON.parse(userEntry._cachedSpecificsJSON) : null
    if (json && json.promptText) {
      promptText = json.promptText
    }
    return promptText
  }

  getUserEntryPreview(userEntry: TextElement) {
    let previewText = ""
    const json = userEntry._cachedSpecificsJSON ? JSON.parse(userEntry._cachedSpecificsJSON) : null
    if (json && json.previewText) {
      previewText = json.previewText
    }
    return previewText
  }

  toggleLabelAndBodyHighlight(markPath: IMarkPatch, showHighlight: boolean) {
    this.toggleLabelHighlight({ labelId: markPath.id, highlight: showHighlight })
    this.toggleHighlight({ buildPlanItemId: markPath.buildPlanItemId, highlight: showHighlight })
  }

  beforeDestroy() {
    if (this.readonlyState) return

    for (const label of this.getAllBuildPlanMarkLabels) {
      this.updateLabelAppearance({ id: label.id, style: label.style, shouldLabelBeSelected: false, updateStore: false })
    }
  }

  hoverIntoUserEntryRow(userEntry: UserEntry) {
    const labelSets = this.userEntryToLabelSetsMap.get(userEntry.elementIDNumber)
    const options = []
    labelSets.forEach((labelSet) => {
      const labelSetId = labelSet.id
      labelSet.patches.forEach((patch) => {
        const option = {
          labelSetId,
          parentId: patch.buildPlanItemId,
          componentId: patch.componentId,
          geometryId: patch.geometryId,
        }
        options.push(option)
      })
    })
    this.highlightLabels(options)
  }

  hoverOutOfUserEntryRow(userEntry: UserEntry) {
    const labelSets = this.userEntryToLabelSetsMap.get(userEntry.elementIDNumber)
    const options = []
    labelSets.forEach((labelSet) => {
      const labelSetId = labelSet.id
      labelSet.patches.forEach((patch) => {
        const option = {
          labelSetId,
          parentId: patch.buildPlanItemId,
          componentId: patch.componentId,
          geometryId: patch.geometryId,
        }
        options.push(option)
      })
    })
    this.deHighlightLabels(options)
  }

  @Watch('markTemplateFile', { deep: true })
  async onMarkFilePicked() {
    let isValid = await this.$refs.markTemplateFile.validate()
    isValid = await this.$refs.form.validate()
    this.setOkDisabled(!isValid)
  }

  get showMarkTemplateFilePicker() {
    let toShow =
      this.includeMarkTemplateFileUploader &&
      this.buildPlan &&
      Array.isArray(this.buildPlan.buildPlanItems) &&
      this.buildPlan.buildPlanItems.length === 1
    toShow = toShow && this.getUser && TEST_TENANTS[this.getUser.tenant.toLowerCase()]
    return toShow
  }

  private getRuntimeTextContentForPatch(patch: Patch, setting: Setting) {
    let runtimeTextContent = ''
    const textContent = setting.textContent
    let startParanthesisIndex = -1
    let endParanthesisIndex = -1
    for (let idx = 0; idx < textContent.length; idx += 1) {
      if (textContent[idx] === '{') {
        startParanthesisIndex = idx
      }

      if (textContent[idx] === '}') {
        endParanthesisIndex = idx
      }

      if (startParanthesisIndex === -1 && endParanthesisIndex === -1) {
        runtimeTextContent = runtimeTextContent.concat(textContent[idx])
      }

      if (startParanthesisIndex !== -1 && endParanthesisIndex !== -1) {
        const indexNumberStr = textContent.substr(
          startParanthesisIndex + 1,
          endParanthesisIndex - startParanthesisIndex - 1,
        )
        const indexNumber = Number(indexNumberStr) - 1
        runtimeTextContent = runtimeTextContent.concat(patch.textContent[indexNumber])
        startParanthesisIndex = -1
        endParanthesisIndex = -1
      }
    }
    return runtimeTextContent
  }

  private async convertFromLegacy(): Promise<LabelSetDto[]> {
    const labelSets: LabelSetDto[] = []
    const bpItemsWithLabels: IBuildPlanItem[] = this.buildPlan.buildPlanItems.filter(
      (bpItem: IBuildPlanItem) => bpItem.labels && bpItem.labels.length,
    )
    let orderIndexNumber = 0
    if (bpItemsWithLabels.length) {
      bpItemsWithLabels.forEach((bpItem: IBuildPlanItem) => {
        bpItem.labels.forEach((label: ILabel) => {
          const textElements = [this.createUserEntry(label, orderIndexNumber)]
          const font = label.style.fontFamily
          const fontSize = label.style.fontSize
          const margin = 0.05
          const textContent = '{1}'

          const labelSet: LabelSetDto = {
            id: uuidv4(),
            buildPlanId: this.buildPlan.id,
            settings: {
              ...JSON.parse(JSON.stringify(DEFAULT_LABEL_SET_SETTING)),
              textContent,
              textElements,
              uniqueID: 0,
              placementMethodAutomatic: false,
              allowFontSizeVariation: true,
              placementAutoLocations: [MarkingLocation.NearestToPoint],
              fontName: font,
              fontTargetSize: fontSize,
              fontMinSize: fontSize,
              textMarginSize: margin,
              textHorizontalAlignment: TextAlign.Center,
              textVerticalAlignment: TextAlignVertical.Center,
            },
            patches: [
              {
                autoLocation: MarkingLocation.NearestToPoint,
                settingsIDForJson: 0,
                id: label.id,
                orientation: label.orientation,
                textPointIn3D: label.orientation.origin,
                buildPlanItemId: bpItem.id,
                labelS3FileName: label.s3FileName,
                labelFileKey: label.fileKey,
                patchS3FileName: label.s3FileName,
                patchFileKey: label.fileKey,
                componentId: null,
                geometryId: null,
                textContent: [''],
                runTimeTextContent: '',
                shellID: orderIndexNumber,
                staticSurface: null,
                transformation: [],
              },
            ],
            orderIndex: orderIndexNumber,
            selectedBodies: [
              {
                buildPlanItemId: bpItem.id,
                componentId: label.componentId,
                geometryId: label.geometryId,
                partId: bpItem.part.id,
                selectedAt: new Date(),
              },
            ],
            relatedBodies: [],
            mode: LabelSetMode.Manual,
          }
          labelSets.push(labelSet)
          orderIndexNumber = orderIndexNumber + 1
        })
      })
    }
    return labelSets
  }

  private createUserEntry(label: ILabel, id: number): TextElement {
    const element = {
      title: `User Entry ${id}`,
      elementIDNumber: id,
      type: MarkingContentElementType.User_Entry,
      isStaticValue: true,
      lengthControl: CharacterLengthControl.Maximum,
      mandatory: true,
      lengthMin: 0,
      lengthMax: label.style.text.length,
      runTimeIndex: null,
      _cachedSpecificsJSON: JSON.stringify(this.generateSpecificJson(label)),
    }
    return element
  }

  private generateSpecificJson(label: ILabel) {
    return {
      entryType: UserEntryType.AlphaNumeric,
      selectedInputType: CharacterLengthControl.Maximum,
      minRange: 0,
      maxRange: label.style.text.length,
      min: 0,
      max: label.style.text.length,
      exactly: label.style.text.length,
      promptText: DEFAULT_PROMPT_TEXT,
    }
  }
}
