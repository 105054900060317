
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import MachineCard from '@/components/controls/MachineCard.vue'
import { IMachineCard } from '@/types/Common/IMachineCard'

const commonStore = namespace(StoresNamespaces.Common)

@Component({
  components: {
    MachineCard,
  },
})
export default class ConnectedPrinters extends Vue {
  @commonStore.Action fetchConnectedMachineConfigs: any
  @commonStore.Getter getConnectedMachineConfigs: any

  machinesCards: IMachineCard[] = []

  async mounted() {
    await this.fetchConnectedMachineConfigs()

    // TODO: this logic should be updated
    this.machinesCards = this.getConnectedMachineConfigs.map((machineConfig) => {
      return {
        id: machineConfig.id,
        image: machineConfig.imageUrl,
        type: machineConfig.name,
        status: '',
      }
    })
  }
}
