import Vue from 'vue'
import { MutationTree } from 'vuex'
import { ISitesState } from '@/store/modules/sites/types'
import {
  Site,
  MachineType,
  MachineMaterial,
  PrintStrategy,
  SiteDto,
  PrintStrategyInfo,
  AdditionalPrintStrategyInfo,
  PrintStrategyParameterSetDto,
  ParameterSet,
  DropDownItem,
  SiteMachineConfigMaterialBinderDto,
} from '@/types/Sites/Site'
import { InertGas, IProductionSet } from '@/types/BuildPlans/IBuildPlan'

export const mutations: MutationTree<ISitesState> = {
  setSites(state, sites: SiteDto[]) {
    state.sites = sites
  },

  setSelectedSite(state, site: Site) {
    state.selectedSite = site
  },

  async setMachineTypes(state, machineTypes: MachineType[]) {
    state.machineTypes = machineTypes
  },

  setMachineType(state, machineType: MachineType) {
    const index = state.machineTypes.findIndex((mt) => mt.id === machineType.id)

    if (index === -1) {
      state.machineTypes.push(machineType)
      return
    }

    Vue.set(state.machineTypes, index, machineType)
  },

  setSelectedMachineType(state, machineType: MachineType) {
    state.selectedMachineType = machineType
  },

  setMachineMaterials(state, machineMaterials: MachineMaterial[]) {
    state.machineMaterials = machineMaterials
  },

  updateMachineMaterial(state, machineMaterial: MachineMaterial) {
    const index = state.machineMaterials.findIndex((material) => material.id === machineMaterial.id)

    if (index !== -1) {
      Vue.set(state.machineMaterials, index, machineMaterial)
    }
  },

  setSelectedMaterial(state, selectedMaterial: MachineMaterial) {
    state.selectedMaterial = selectedMaterial
  },

  clearSelectedMaterial(state) {
    state.selectedMaterial = null
  },

  setPrintStrategies(state, printStrategies: PrintStrategy[]) {
    state.printStrategies = printStrategies
  },

  removePrintStrategy(state, printStrategyId: number) {
    state.printStrategies = state.printStrategies.filter((ps) => ps.printStrategyId !== printStrategyId)
  },

  updatePrintStrategiesList(state, printStrategy: PrintStrategy) {
    const index = state.printStrategies.findIndex((item) => item.printStrategyId === printStrategy.printStrategyId)
    state.printStrategies.splice(index, 1, printStrategy)
  },

  setPreviousPath(state, path) {
    state.previousPath = path
  },

  setSelectedPrintStrategy(state, printStrategy: PrintStrategyInfo) {
    state.printStrategy = printStrategy
  },

  clearPrintStrategyState(state) {
    state.printStrategy = null
    state.printStrategyAdditionalInfo.availablePartParameters = []
    state.printStrategyAdditionalInfo.availableProcessFiles = []
    state.printStrategyAdditionalInfo.partParametersInherentStrains = []
  },

  renameProcessParameter(state, payload: DropDownItem) {
    const parameter = state.printStrategyAdditionalInfo.availableProcessFiles.find(
      (item) => item.value === payload.value,
    )
    if (parameter) {
      parameter.text = payload.text
    }
  },

  renamePartParameter(state, payload: { id: number; name: string }) {
    const parameter = state.printStrategy.partParameters.find((item) => item.parameterSetId === payload.id)
    if (parameter) {
      parameter.name = payload.name
    }
  },

  addDmlmParameterSetToPrintStrategy(state, printStrategyParameterSet: PrintStrategyParameterSetDto) {
    state.printStrategy.partParameters.push(printStrategyParameterSet)
  },

  removeDmlmParameterSetFromPrintStrategy(state, printStrategyParameterSetId: string) {
    state.printStrategy.partParameters = state.printStrategy.partParameters.filter(
      (x) => x.id !== printStrategyParameterSetId,
    )
  },

  setPrintStrategyAdditionalInfo(state, printStrategyAdditionalInfo: AdditionalPrintStrategyInfo) {
    state.printStrategyAdditionalInfo = printStrategyAdditionalInfo
  },

  setBreadcrumbsReady(state, isReady: boolean) {
    state.isBreadcrumbsReady = isReady
  },

  setInertGases(state, inertGases: InertGas[]) {
    state.inertGases = inertGases
  },

  addAvailablePartParameter(state, item: ParameterSet) {
    const isItemExist = !!state.printStrategyAdditionalInfo.availablePartParameters.find((pp) => pp.value === item.id)
    if (isItemExist) return
    state.printStrategyAdditionalInfo.availablePartParameters.push({ text: item.parameterSetName, value: item.id })
  },

  setCopiedParameterSetId(state, id?: number) {
    state.copiedPartParameterId = id
  },

  addAvailableProcessFile(state, item: IProductionSet) {
    const isItemExist = !!state.printStrategyAdditionalInfo.availableProcessFiles.find((pp) => pp.value === item.id)
    if (isItemExist) return
    state.printStrategyAdditionalInfo.availableProcessFiles.push({ text: item.productionSetName, value: item.id })
  },

  setCopiedProductionSetId(state, id?: number) {
    state.copiedProductionSetId = id
  },

  setLoadingStatus(state, status: boolean) {
    state.isLoading = status
  },

  setBinders(state, binders: SiteMachineConfigMaterialBinderDto[]) {
    state.siteMachineConfigMaterialBinders = binders
  },

  updateBinder(state, siteMachineConfigMaterialBinder: SiteMachineConfigMaterialBinderDto) {
    const index = state.siteMachineConfigMaterialBinders.findIndex(
      (b) => b.binderId === siteMachineConfigMaterialBinder.binderId,
    )
    if (index < 0) return

    Vue.set(state.siteMachineConfigMaterialBinders, index, siteMachineConfigMaterialBinder)
  },

  setStrategyProcessParameterSolidLevel(state, solidLevel: number) {
    state.printStrategyProcessParameterSolidLevel = solidLevel
  },
}
