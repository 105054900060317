import { ActionTree } from 'vuex'
import { IVisualizationState } from './types'
import { IRootState } from '@/store/types'
import fileExplorerApi from '@/api/fileExplorer'
import { GeometryType, IBuildPlan, IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { OuterEvents } from '@/visualization/types/Common'
import { ProcessingStage } from '@/visualization/types/SimulationTypes'

export const actions: ActionTree<IVisualizationState, IRootState> = {
  changeSelectedColoringView: ({ commit, state }, viewName: string) => {
    commit('changeSelectedColoringView', viewName)
    // Select first view mode for the newly selected coloring view
    commit('changeSelectedColoringMode', { name: state.simulation.coloringModel.selectedView.modes[0].name })
  },

  async updateItemPreview({ commit }, params: { itemId: string; file: File }) {
    return await fileExplorerApi.updateItemPreviewImage(params.itemId, params.file)
  },

  generatePreviewCreationPromise({ commit }) {
    let done
    const promise = new Promise((resolve) => {
      done = resolve
    })

    const previewCreationPromise = { promise, done }
    commit('setPreviewCreationPromise', previewCreationPromise)
    return previewCreationPromise
  },

  updateGeometriesOnTypeChange(
    { commit },
    params: {
      items: Array<{ bodyIds: string[]; buildPlanItemId: string }>
      geometryType: GeometryType
      visibility: boolean
    },
  ) {
    commit('updateGeometriesTypeOnScene', { items: params.items, geometryType: params.geometryType })
    commit('setGeometriesVisibility', params)
  },

  /** Change visibility of buildPlanItem
   * @param bpItem - BuildPlanItem to change.
   * @param makeVisible - Set visibility to Visible if 'true' or Hidden if 'false'.
   * @param showAsTransparent Show item as transparent if hidden.
   * Note: 'showAsTransparent' should be 'true' if transparent mode is active or the item is selected.
   */
  setBpItemVisibility(
    { state, commit },
    payload: { bpItem: IBuildPlanItem; makeVisible: boolean; showAsTransparent: boolean },
  ) {
    const { bpItem, makeVisible, showAsTransparent } = payload

    commit('setIsHiddenForBuildPlanItemMesh', {
      buildPlanItemId: bpItem.id,
      makeHidden: !makeVisible,
      showHiddenAsTransparent: showAsTransparent,
    })
  },

  toggleIBCMeasurment({ rootGetters }, payload: { id: string; isVisible: boolean }) {
    rootGetters['visualizationModule/visualization'].toggleIBCMeasurement(payload.id, payload.isVisible)
  },

  highlightIBCMeasurement({ rootGetters }, payload: { id: string; showHighlight: boolean }) {
    rootGetters['visualizationModule/visualization'].highlightIBCMeasurement(payload.id, payload.showHighlight)
  },

  exportCompensationFiles({ rootGetters }, payload) {
    rootGetters['visualizationModule/visualization'].handleOuterEvent(OuterEvents.ExportCompensationFiles, payload)
  },

  setSimulationExportInfo({ rootGetters }, payload) {
    rootGetters['visualizationModule/visualization'].handleOuterEvent(OuterEvents.SetSimulationExportInfo, payload)
  },

  async loadPartConfig(
    { rootGetters, commit, getters },
    payload: {
      partName: string
      allowLoadPartConfigTermination?: boolean
      partId: string
      skipGlobalLoading: boolean
      customLoader: string
    },
  ) {
    if (!payload.skipGlobalLoading) {
      commit('setIsLoading', true)
    } else {
      commit(`${payload.customLoader}`, true)
    }
    await getters.visualization.loadDetailsPreviewPartConfig(payload)
    if (!payload.skipGlobalLoading) {
      commit('setIsLoading', false)
    } else {
      commit(`${payload.customLoader}`, false)
    }
  },

  async loadBuildPlan({ getters, commit }, buildPlan: IBuildPlan) {
    commit('setIsLoading', true)
    await getters.visualization.loadDetailsPreviewBuildPlan(buildPlan)
    commit('setIsLoading', false)
  },

  setDataJsonContent({ rootGetters }, payload) {
    rootGetters['visualizationModule/visualization'].handleOuterEvent(OuterEvents.SetDataJsonContent, payload)
  },

  setVisualizationConnectionParams({ rootGetters, commit }, payload) {
    rootGetters['visualizationModule/visualization'].handleOuterEvent(OuterEvents.ConnectToService, payload)
    commit('setCurrentProcessing', { stage: ProcessingStage.FetchingResults, enabled: true })
  },

  fetchResults({ rootGetters, commit }) {
    rootGetters['visualizationModule/visualization'].handleOuterEvent(OuterEvents.FetchResults)
    commit('setCurrentProcessing', { stage: ProcessingStage.FetchingResults, enabled: true })
  },
}
