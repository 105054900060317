export enum OrientGraphLineTypes {
  Selectable = 'Selectable',
  Selected = 'Selected',
  Imported = 'As Imported',
  LastSaved = 'Last Saved',
}

export enum EChartPlotTypes {
  line = 'line',
  dotted = 'dotted',
  dashed = 'dashed',
}

export type OrientSeriesItem = {
  type: string
  lineStyle: any
  data: OrientSeriesDataItem[]
  name: string
  emphasis: any
  inactiveOpacity: number
}

export type OrientSeriesDataItem = {
  value: number[]
  lineStyle: any
  orientationIndex: number
}
