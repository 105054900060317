
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import BuildPlateCanvas from '@/components/layout/admin/sites/BuildPlateCanvas.vue'
import ConfirmableSelector from '@/components/layout/admin/sites/ConfirmableSelector.vue'
import TenantAdminHeader from '@/components/layout/admin/sites/contentTable/TenantAdminHeader.vue'
import TenantAdminRow from '@/components/layout/admin/sites/contentTable/TenantAdminRow.vue'
import { SiteAdminMixin } from '@/components/layout/admin/sites/mixins/SiteAdminMixin'
import { TenantAdminContentTableMixin } from '@/components/layout/admin/sites/mixins/TenantAdminContentTableMixin'
import TableRowNumberUpdater from '@/components/layout/admin/sites/TableRowNumberUpdater.vue'
import StoresNamespaces from '@/store/namespaces'
import { Currency, Measurement } from '@/types/Sites/Site'
import { TenantAdminBuildPlatform, UpdateBuildPlatformDTO } from '@/types/Sites/TenantAdminBuildPlatform'
import { TableCell } from '@/types/Sites/TenantAdminContentTableCell'
import { TableHeader } from '@/types/Sites/TenantAdminContentTableHeader'
import { TableRow } from '@/types/Sites/TenantAdminContentTableRow'
import { SortOrders } from '@/types/SortModes'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component({
  components: { TenantAdminRow, TenantAdminHeader },
})
export default class TenantAdminBuildPlatformList extends Mixins(TenantAdminContentTableMixin, SiteAdminMixin) {
  @sitesStore.Action updateBuildPlate: (payload: {
    id: number
    dto: UpdateBuildPlatformDTO
  }) => Promise<TenantAdminBuildPlatform>

  loading = false

  @Prop({ default: () => [] }) platformList: TenantAdminBuildPlatform[]

  beforeMount() {
    this.setupHeaders()
  }

  get rowsFormatted(): TableRow[] {
    return this.setupRows()
  }

  sortChanged(header: TableHeader) {
    this.loading = true
    this.onSortChanged(header)
    this.$forceUpdate()
    this.$nextTick(() => (this.loading = false))
  }

  setupHeaders() {
    this.headers = [
      {
        headerName: this.$root.$t('tenantAdminContentTableHeaders.buildPlatformList.name').toString(),
        cols: 2,
        isSortCriteria: true,
        sortOrder: SortOrders.Descending,
      },
      {
        headerName: this.$root.$t('tenantAdminContentTableHeaders.buildPlatformList.size').toString(),
        cols: 3,
        isSortCriteria: false,
        sortOrder: null,
      },
      {
        headerName: this.$root.$t('tenantAdminContentTableHeaders.buildPlatformList.material').toString(),
        cols: 1,
        isSortCriteria: false,
        sortOrder: null,
      },
      {
        headerName: '',
        cols: 2,
        isSortCriteria: false,
        sortOrder: null,
      },
      {
        headerName: '',
        cols: 2,
        isSortCriteria: false,
        sortOrder: null,
      },
      {
        headerName: this.$root.$t('tenantAdminContentTableHeaders.printStrategyList.status').toString(),
        cols: 1,
        isSortCriteria: false,
        sortOrder: null,
      },
    ]
  }

  setupRows() {
    this.rows = this.generateRows(this.platformList)
    return this.rows
  }

  generateColumns(platform: TenantAdminBuildPlatform): TableCell[] {
    return [
      {
        // Name
        value: platform.name,
        text: platform.name,
        tooltipComponent: BuildPlateCanvas,
        componentOptions: {
          buildPlateId: platform.id,
        },
        cols: 2,
        cellName: this.$root.$t('tenantAdminContentTableHeaders.buildPlatformList.name').toString(),
      },
      {
        // Size
        value: this.getPlateSize(platform.size),
        upperDescription: this.$root.$t('tenantAdminContentTableHeaders.buildPlatformList.size'),
        text: this.getPlateSize(platform.size),
        cols: 3,
        cellName: this.$root.$t('tenantAdminContentTableHeaders.buildPlatformList.size').toString(),
      },
      {
        // Material
        value: platform.materialName,
        upperDescription: this.$root.$t('tenantAdminContentTableHeaders.buildPlatformList.material'),
        text: platform.materialName,
        cols: 1,
        cellName: this.$root.$t('tenantAdminContentTableHeaders.buildPlatformList.material').toString(),
      },
      {
        // Price
        value: platform.price.value,
        cellItemId: platform.id,
        upperDescription: this.$root.$t('tenantAdminContentTableHeaders.buildPlatformList.price'),
        text: `${platform.price.value} ${platform.price.currency ? platform.price.currency : Currency.USD}`,
        cols: 2,
        component: TableRowNumberUpdater,
        cellName: this.$root.$t('tenantAdminContentTableHeaders.buildPlatformList.price').toString(),
        componentOptions: {
          name: this.$root.$t('tenantAdminContentTableHeaders.buildPlatformList.price'),
          rules: this.validationRules.rules,
          customMessages: this.validationRules.customMessages,
          suffix: Currency.USD,
          componentHeight: '14px',
          hideDetails: true,
        },
        callbacks: {
          onUpdateSiteConstants: this.updatePrice,
        },
      },
      {
        // Platform life
        value: platform.life,
        cellItemId: platform.id,
        upperDescription: this.$root.$t('tenantAdminContentTableHeaders.buildPlatformList.life'),
        bottomDescription: this.$root.$t('tenantAdminContentTableHeaders.buildPlatformList.perPlatform'),
        text: platform.life.toString(),
        cols: 2,
        component: TableRowNumberUpdater,
        cellName: this.$root.$t('tenantAdminContentTableHeaders.buildPlatformList.life').toString(),
        componentOptions: {
          name: this.$root.$t('tenantAdminContentTableHeaders.buildPlatformList.life'),
          rules: this.recoaterAndFilterLifeValidationRules.rules,
          customMessages: this.recoaterAndFilterLifeValidationRules.customMessages,
          suffix: Measurement.Builds,
          componentHeight: '14px',
          hideDetails: true,
        },
        callbacks: {
          onUpdateSiteConstants: this.updateLife,
        },
      },
      {
        // Status
        value: platform.visibility,
        cellItemId: platform.id,
        componentOptions: {
          options: [
            { text: 'Active', value: true, color: 'green' },
            { text: 'Inactive', value: false, color: 'red' },
          ],
          zIndex: 9,
        },
        component: ConfirmableSelector,
        cellName: this.$root.$t('tenantAdminContentTableHeaders.printStrategyList.status').toString(),
        cols: 1,
        editable: true,
        callbacks: {
          submit: this.updateStatus,
        },
      },
    ]
  }

  async updateStatus(value: boolean, id: number) {
    const updatedPlate = await this.updateBuildPlate({ id, dto: { visibility: value } })
    const index = this.platformList.findIndex((listItem) => listItem.id === updatedPlate.id)
    this.$emit('updateList', index, updatedPlate)
  }

  async updatePrice(value: number, id: number) {
    const updatedPlate = await this.updateBuildPlate({ id, dto: { cost: value } })
    const index = this.platformList.findIndex((listItem) => listItem.id === updatedPlate.id)
    this.$emit('updateList', index, updatedPlate)
  }

  async updateLife(value: number, id: number) {
    const updatedPlate = await this.updateBuildPlate({ id, dto: { life: value } })
    const index = this.platformList.findIndex((listItem) => listItem.id === updatedPlate.id)
    this.$emit('updateList', index, updatedPlate)
  }

  getPlateSize(sizes) {
    const { length, width, height, measurement } = sizes.volume
    return `${length * 1000}x${width * 1000} / ${height * 1000} ${measurement}`
  }
}
