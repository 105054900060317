/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import VisualizationModeTypes from '../types/VisualizationModeTypes'
import {
  IVisualizationMode,
  DefaultVisualizationMode,
  OverhangAreasVisualizationMode,
  RecoaterDirectionVisualizationMode,
} from '../infrastructure/VisualizationMode'
import RecoaterDirection from '../types/RecoaterDirectionTypes'
import { Vector3, Axis } from '@babylonjs/core/Maths'
import { RenderScene } from '../render-scene'
import { VisualizationEvent } from '../infrastructure/IVisualizationEvent'

export class VisualizationModeManager {
  private renderScene: RenderScene
  private visualizationModes: Map<VisualizationModeTypes, IVisualizationMode> = new Map<
    VisualizationModeTypes,
    IVisualizationMode
  >()
  private visualizationMode: IVisualizationMode
  private recoaterDirections: Map<RecoaterDirection, Vector3> = new Map<RecoaterDirection, Vector3>()
  private recoaterDirection: Vector3
  private onVisualizationModeChanged = new VisualizationEvent<VisualizationModeTypes>()

  constructor(renderScene: RenderScene) {
    this.renderScene = renderScene
  }

  get visualizationModeChanged() {
    return this.onVisualizationModeChanged.expose()
  }

  init() {
    this.initRecoaterDirections()
    this.initVisualizationModes()
  }

  getMode() {
    return this.visualizationMode
  }

  getRecoaterDirection() {
    return this.recoaterDirection
  }

  isDefaultMode() {
    return this.visualizationMode instanceof DefaultVisualizationMode
  }

  changeVisualizationMode(mode: VisualizationModeTypes) {
    this.setVisualizationMode(mode)
    this.visualizationMode.render()
    this.renderScene.animate(true)
  }

  setOverhangAngle(overhangAngle: number) {
    ;(
      this.visualizationModes.get(VisualizationModeTypes.OverhangAreas) as OverhangAreasVisualizationMode
    ).setOverhangAngle(overhangAngle)
  }

  private initVisualizationModes() {
    this.visualizationModes.set(VisualizationModeTypes.Default, new DefaultVisualizationMode(this.renderScene))
    this.visualizationModes.set(
      VisualizationModeTypes.OverhangAreas,
      new OverhangAreasVisualizationMode(this.renderScene),
    )
    this.visualizationModes.set(
      VisualizationModeTypes.RecoaterDirection,
      new RecoaterDirectionVisualizationMode(this.renderScene),
    )
    this.setVisualizationMode(VisualizationModeTypes.Default)
  }

  private initRecoaterDirections() {
    this.recoaterDirections.set(RecoaterDirection.X, Axis.X)
    this.recoaterDirections.set(RecoaterDirection.Y, Axis.Y)
    this.recoaterDirections.set(RecoaterDirection.NegativeX, Axis.X.negate())
    this.recoaterDirections.set(RecoaterDirection.NegativeY, Axis.Y.negate())
    this.setRecoaterDirection(RecoaterDirection.X)
  }

  private setVisualizationMode(mode: VisualizationModeTypes) {
    this.visualizationMode = this.visualizationModes.get(mode)
  }

  private setRecoaterDirection(direction: RecoaterDirection) {
    this.recoaterDirection = this.recoaterDirections.get(direction)
  }
}
