import { Command } from '@/types/UndoRedo/Command'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { Commit, Dispatch } from 'vuex'
import { IConstraints } from '@/types/BuildPlans/IConstraints'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

enum ConstraintUpdateMode {
  BeforeApply,
  AfterApply,
}

export class ConstraintCommand extends Command implements ICommand {
  public commandType: CommandType
  public readonly toolName = ToolNames.CONSTRAIN

  private readonly constraintCommandPayloads: ConstraintCommandPayload[]
  private readonly onUpdatingCallback: Function

  constructor(
    commandType: CommandType,
    constraintCommandPayloads: ConstraintCommandPayload[],
    onUpdatingCallback: Function,
    protected dispatch: Dispatch,
    protected commit: Commit,
  ) {
    super()

    this.commandType = commandType
    this.constraintCommandPayloads = JSON.parse(JSON.stringify(constraintCommandPayloads))
    this.onUpdatingCallback = onUpdatingCallback
  }

  async redo(): Promise<void> {
    await this.updateConstraints(ConstraintUpdateMode.AfterApply)
  }

  async undo(): Promise<void> {
    await this.updateConstraints(ConstraintUpdateMode.BeforeApply)
  }

  private async updateConstraints(mode: ConstraintUpdateMode) {
    try {
      this.onUpdatingCallback(true)
      this.commit('visualizationModule/setIsLoading', true, this.rootLevel)

      await Promise.all(
        this.constraintCommandPayloads.map((item) => {
          return this.dispatch(
            'buildPlans/updateBuildPlanItem',
            {
              params: {
                buildPlanItemId: item.buildPlanItemId,
                constraints:
                  mode === ConstraintUpdateMode.BeforeApply
                    ? item.constraints.beforeApply
                    : item.constraints.afterApply,
              },
            },
            this.rootLevel,
          )
        }),
      )
    } finally {
      this.commit('visualizationModule/setIsLoading', false, this.rootLevel)
      this.onUpdatingCallback(false)
    }
  }
}

export type ConstraintCommandPayload = {
  buildPlanItemId: string
  constraints: {
    beforeApply: IConstraints
    afterApply: IConstraints
  }
}
