
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { IIBCPlan, IMeasurement } from '@/types/IBCPlans/IIBCPlan'
import StoresNamespaces from '@/store/namespaces'

const commonStore = namespace(StoresNamespaces.Common)

@Component
export default class IbcPlanCompensateSettings extends Vue {
  @commonStore.Getter tooltipOpenDelay: number

  @Prop({ default: () => [] }) measurements: IMeasurement[]
  @Prop({ type: Number }) flatHorizontalSurfaceCount: number

  getMeasurementListItemName(measurement: IMeasurement): string {
    return measurement.name || measurement.id
  }
}
