
import { extend, ValidationObserver } from 'vee-validate'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/components/controls/Common/Button.vue'
import Selector from '@/components/controls/Common/Selector.vue'
import TextField from '@/components/controls/Common/TextField.vue'
import LabelToolSelect from '@/components/controls/LabelToolControls/LabelToolSelect.vue'
import LabelToolTextField from '@/components/controls/LabelToolControls/LabelToolTextField.vue'
import FallbackImage from '@/components/layout/FallbackImage.vue'
import CreateAmpItemModalMixin from '@/components/layout/mixins/CreateAmpItemModalMixin'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import { ROOT_FOLDER_ID } from '@/constants'
import i18n from '@/plugins/i18n'
import localStorageService, { LocalStorageKeys } from '@/services/localStorageService'
import messageService from '@/services/messageService'
import { createVariantItemFromPlan } from '@/store/modules/buildPlans/actions'
import { CheckItemUniqPayload } from '@/store/modules/fileExplorer/types'
import StoresNamespaces from '@/store/namespaces'
import {
  IBuildPlan,
  IBuildPlanItem,
  ICreateBuildPlanDto,
  ICreateBuildPlanLastUsedValues,
  IUpdateBuildPlanItemParamsDto,
  IVariantItem,
  ProcessState,
} from '@/types/BuildPlans/IBuildPlan'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { FileExplorerItem, RenameItemPayload } from '@/types/FileExplorer/FileExplorerItem'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { PrintingTypes } from '@/types/IMachineConfig'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { Binder, MachineConfigMaterialBinder } from '@/types/Sites/Site'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { EditBuildPlanCommand } from '@/types/UndoRedo/EditBuildPlanCommand'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { debounce } from '@/utils/debounce'
import MachineMaterialSelectionMixin from './mixins/MachineMaterialSelection'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const commandManagerStore = namespace(StoresNamespaces.CommandManager)

const DEFAULT_BUILD_PLAN_NAME = 'Build Plan 1'
const DEBOUNCE_TIME = 600
const NAME_DELIMITER = ' ' // space

@Component({
  components: {
    TextField,
    Button,
    Selector,
    FallbackImage,
    LabelToolTextField,
    LabelToolSelect,
    ConfirmModal,
  },
})
export default class CreateBuildPlanModal extends Mixins(MachineMaterialSelectionMixin, CreateAmpItemModalMixin) {
  @buildPlansStore.Action fetchMaterials: Function
  @buildPlansStore.Action fetchMachineConfigMaterialBinders: Function
  @buildPlansStore.Action fetchMachineConfigs: Function
  @buildPlansStore.Action fetchActivePrintStrategies: Function
  @buildPlansStore.Action fetchBuildPlates: Function
  @buildPlansStore.Action fetchBuildPlateMaterials: Function
  @buildPlansStore.Action fetchBuildPlateMachineConfigs: Function
  @buildPlansStore.Action updateBuildPlanV1: (payload: {
    buildPlan: IBuildPlan
    hideAPIErrorMessages?: boolean
  }) => Promise<IBuildPlan>
  @buildPlansStore.Action getBuildPlanPrintStrategy: (printStrategyPk: VersionablePk) => Promise<void>
  @buildPlansStore.Action fetchBuildPlanPrintStrategyByPk: (
    printStrategyPk: VersionablePk,
  ) => Promise<BuildPlanPrintStrategyDto>
  @buildPlansStore.Action updateBuildPlanItem: (payload: {
    params: IUpdateBuildPlanItemParamsDto
    hideAPIErrorMessages?: boolean
    parameterSet?
  }) => Promise<void>
  @buildPlansStore.Action getBuildPlanById: (id: string) => Promise<IBuildPlan>
  @buildPlansStore.Action updateBuildPlanItemsDefaults: () => Promise<void>
  @buildPlansStore.Action areSelectedPrintStrategyScaleFactorsChanged: (
    currentPrintStrategyPk: VersionablePk,
  ) => Promise<boolean>

  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getAllBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getMachineConfigMaterialBinders: MachineConfigMaterialBinder[]
  @buildPlansStore.Getter getAllAvailableBuildPlanVariants: IBuildPlan[]
  @buildPlansStore.Getter getBindersBasedOnSelectedMaterial: (selectedMaterialPk: VersionablePk) => Binder[]
  @buildPlansStore.Getter isRecoaterDirectionChanged: (machineConfigPk: VersionablePk) => boolean
  @buildPlansStore.Getter areSelectedPrintStrategyDefaultsValid: boolean
  @buildPlansStore.Getter('getBuildPlanPrintStrategy') buildPlanPrintStrategy: BuildPlanPrintStrategyDto
  @buildPlansStore.Getter getCommandType: CommandType

  @buildPlansStore.Mutation addBuildPlan: Function
  @buildPlansStore.Mutation setBuildPlan: Function
  @buildPlansStore.Mutation setVariants: Function
  @buildPlansStore.Mutation selectBuildPlate: Function
  @buildPlansStore.Mutation updateVariant: (variant: IVariantItem) => void
  @buildPlansStore.Mutation setSelectedBuildPlanPrintStrategy: (
    buildPlanPrintStrategy: BuildPlanPrintStrategyDto,
  ) => void
  @buildPlansStore.Mutation setBuildPlanUpdatingState: (isBuildPlanUpdating: boolean) => void
  @buildPlansStore.Mutation setIsLoading: Function

  @fileExplorerStore.Action createBuildPlan: Function
  @fileExplorerStore.Action checkItemForUnique: (payload: CheckItemUniqPayload) => boolean
  @fileExplorerStore.Action generateUniqueName: (payload: {
    name: string
    parentId: string
    delimiter?: string
  }) => Promise<string>
  @fileExplorerStore.Action getParentFolder: (id: string) => Promise<FileExplorerItem>
  @fileExplorerStore.Action updateItem: (item: Partial<FileExplorerItem>) => Promise<FileExplorerItem>

  @fileExplorerStore.Getter getErrorText: string
  @fileExplorerStore.Getter isError: boolean
  @fileExplorerStore.Getter find: (itemId: string) => FileExplorerItem

  // @ts-ignore
  checkItemForUniqueDebounced = debounce<boolean>(DEBOUNCE_TIME, this.checkItemForUnique) as (
    payload: CheckItemUniqPayload,
  ) => Promise<boolean>

  @commandManagerStore.Mutation addCommand: (command: ICommand) => void

  @Prop({ required: true }) value: boolean
  @Prop({ required: false, default: false }) editMode: boolean
  @Prop({ required: false, default: null }) materialModality: PrintingTypes

  $refs!: {
    form: InstanceType<typeof ValidationObserver>
    nameTextField: TextField
    confirm: InstanceType<typeof ConfirmModal>
  }

  lastUsedValues: ICreateBuildPlanLastUsedValues
  buildPlanName: string = DEFAULT_BUILD_PLAN_NAME
  materialPk: VersionablePk = null
  machineConfigPk: VersionablePk = null
  printStrategyPk: VersionablePk = null
  buildPlatePk: VersionablePk = null
  binderPk: VersionablePk = null
  root: string = null
  buildPlanId: string = null

  isSubmitting = false
  isComponentMounted = false

  getBuildPlanNameRules() {
    return {
      required: true,
      unique: true,
      compact: true,
      noSpecials: true,
      noReservedNames: [this.buildPlanName],
    }
  }

  get isBinderJetMaterial() {
    if (!this.selectedMaterialPk) return false

    const isBinderJet = this.selectedMaterialModality === PrintingTypes.BinderJet

    if (!isBinderJet) {
      this.selectedBinderPk = null
    }

    return isBinderJet
  }

  get selectedMaterialModality() {
    if (!this.selectedMaterialPk) return null

    const material = this.materials.find(
      (m) => m.id === this.selectedMaterialPk.id && m.version === this.selectedMaterialPk.version,
    )

    return material && material.modality
  }

  get materials() {
    return this.getAvailableMaterialsByPrintingType(this.materialModality).map((m) => {
      return { ...m, pk: new VersionablePk(m.id, m.version) }
    })
  }

  get selectedMaterialPk() {
    return this.materialPk
  }

  set selectedMaterialPk(value) {
    this.materialPk = value

    if (this.selectedMaterialModality === PrintingTypes.BinderJet) {
      this.selectedBinderPk = this.getDefaultPk(this.lastUsedValues.binderId, this.binders)
      return
    }

    this.selectedBinderPk = null
    this.selectedMachineConfigPk = this.getDefaultPk(this.lastUsedValues.machineConfigId, this.machineConfigs)
  }

  get selectedBinderPk() {
    return this.binderPk
  }

  set selectedBinderPk(value) {
    this.binderPk = value

    if (!value) return

    const machineConfigIdToUse = this.selectedMachineConfigPk
      ? this.selectedMachineConfigPk.id
      : this.lastUsedValues.machineConfigId
    this.selectedMachineConfigPk = this.getDefaultPk(machineConfigIdToUse, this.machineConfigs)
  }

  get isBinderJetMachineSelected() {
    return this.selectedMachineConfig && this.selectedMachineConfig.printingType === PrintingTypes.BinderJet
  }

  get machineConfigs() {
    const machineConfigs = this.getAvailableMachineConfigsByMaterial(this.materialPk).map((m) => {
      return { ...m, pk: new VersionablePk(m.id, m.version) }
    })

    if (!this.selectedBinderPk || !this.selectedMaterialPk) return machineConfigs
    return machineConfigs.filter((mc) => {
      return this.getMachineConfigMaterialBinders.some(
        (mmb) =>
          mmb.materialId === this.selectedMaterialPk.id &&
          mmb.materialVersion === this.selectedMaterialPk.version &&
          mc.id === mmb.machineConfigId &&
          mc.version === mmb.machineConfigVersion,
      )
    })
  }

  get selectedMachineConfig() {
    return this.getMachineConfigByPk(this.machineConfigPk)
  }

  get selectedMachineConfigPk() {
    return this.machineConfigPk
  }

  set selectedMachineConfigPk(value) {
    this.machineConfigPk = value
    this.selectedPrintStrategyPk = this.newPrintStrategyPk
  }

  get newPrintStrategyPk() {
    let newPrintStrategyPk = this.getDefaultPk(this.lastUsedValues.printStrategyId, this.printStrategies)

    if (this.editMode && newPrintStrategyPk && newPrintStrategyPk.id !== this.buildPlanPrintStrategy.printStrategyId) {
      newPrintStrategyPk = null
    }

    return newPrintStrategyPk
  }

  get printStrategies() {
    let printStrategies = this.getPrintStrategiesByMachineConfigAndMaterial(this.machineConfigPk, this.materialPk)

    if (this.selectedBinderPk) {
      printStrategies = printStrategies.filter(
        (p) =>
          p.machineConfigMaterialBinder &&
          p.machineConfigMaterialBinder.binder &&
          p.machineConfigMaterialBinder.binder.id === this.selectedBinderPk.id &&
          p.machineConfigMaterialBinder.binder.version === this.selectedBinderPk.version,
      )
    }

    return printStrategies.map((ps) => {
      return { ...ps, pk: new VersionablePk(ps.id, ps.version) }
    })
  }

  get selectedPrintStrategyPk() {
    return this.printStrategyPk
  }

  set selectedPrintStrategyPk(value) {
    this.printStrategyPk = value
    this.selectedBuildPlatePk = this.getDefaultPk(this.lastUsedValues.buildPlateId, this.buildPlates)
  }

  get selectedPrintStrategy() {
    return this.getAllActivePrintStrategies.find(
      (set) => set.id === this.selectedPrintStrategyPk.id && set.version === this.selectedPrintStrategyPk.version,
    )
  }

  get buildPlates() {
    return this.getAvailableBuildPlatesByMachineConfigAndMaterial(this.machineConfigPk, this.materialPk).map(
      (plate) => {
        return { ...plate, pk: new VersionablePk(plate.id, plate.version) }
      },
    )
  }

  get selectedBuildPlatePk() {
    return this.buildPlatePk
  }

  set selectedBuildPlatePk(value) {
    this.buildPlatePk = value
  }

  get imageUrl() {
    if (!this.selectedMachineConfig || !this.selectedMachineConfig.imageUrl) {
      return ''
    }

    return this.selectedMachineConfig.imageUrl
  }

  get binders() {
    return this.getBindersBasedOnSelectedMaterial(this.selectedMaterialPk)
  }

  get buildPlanBinderId() {
    const bpPrintStrategy = this.getAllActivePrintStrategies.find(
      (ps) => ps.id === this.getBuildPlan.printStrategyId && ps.version === this.getBuildPlan.printStrategyVersion,
    )

    return (
      bpPrintStrategy &&
      bpPrintStrategy.machineConfigMaterialBinder &&
      bpPrintStrategy.machineConfigMaterialBinder.binder &&
      bpPrintStrategy.machineConfigMaterialBinder.binder.id
    )
  }

  @Watch('printStrategyPk')
  async onPrintStrategyPkUpdated() {
    if (this.printStrategyPk) return

    this.$nextTick(async () => {
      await this.$refs.form.validate()
    })
  }

  async beforeMount() {
    this.extendValidationRules()

    if (this.editMode) {
      this.buildPlanId = this.$route.params.id
    }

    await Promise.all([
      this.fetchMaterials(),
      this.fetchMachineConfigs(),
      this.fetchActivePrintStrategies(this.buildPlanId),
      this.fetchBuildPlates(),
      this.fetchBuildPlateMaterials(),
      this.fetchBuildPlateMachineConfigs(),
      this.fetchMachineConfigMaterialBinders(),
    ])

    if (this.editMode) {
      const parentFolder = await this.getParentFolder(this.buildPlanId)

      this.root = parentFolder ? parentFolder.id : null
      this.lastUsedValues = {
        materialId: this.getBuildPlan.materialId,
        machineConfigId: this.getBuildPlan.machineConfigId,
        printStrategyId: this.getBuildPlan.printStrategyId,
        buildPlateId: this.getBuildPlan.buildPlateId,
        binderId: this.buildPlanBinderId,
      }
      this.selectedMaterialPk = this.getDefaultPk(this.lastUsedValues.materialId, this.materials)

      this.lastUsedValues.binderId =
        this.buildPlanPrintStrategy &&
        this.buildPlanPrintStrategy.machineConfigMaterialBinder &&
        this.buildPlanPrintStrategy.machineConfigMaterialBinder.binderId

      this.selectedBinderPk = this.getDefaultPk(this.lastUsedValues.binderId, this.binders)

      this.buildPlanName = this.getBuildPlan.name
    } else {
      const currentFolderId = this.$route.params.itemId
      this.root = currentFolderId === ROOT_FOLDER_ID ? null : currentFolderId
      this.lastUsedValues = localStorageService.get(LocalStorageKeys.CreateBuildPlanLastUsedValues) || {}
      this.selectedMaterialPk = this.getDefaultPk(this.lastUsedValues.materialId, this.materials)
      this.selectedBinderPk = this.getDefaultPk(this.lastUsedValues.binderId, this.binders)

      await this.generateUniqueBuildPlanName()
    }
  }

  mounted() {
    this.setFocus()
    this.isComponentMounted = true
  }

  setFocus() {
    setTimeout(() => {
      if (this.$refs.nameTextField) {
        this.$refs.nameTextField.focus()
      }
    }, 0)
  }

  extendValidationRules() {
    extend('unique', {
      validate: async () => {
        if (this.editMode && this.getBuildPlan.name === this.buildPlanName) {
          return true
        }

        const isNameExists = await this.checkItemForUniqueDebounced({
          name: this.buildPlanName,
          parentId: this.root,
          excludeItemId: this.getBuildPlan && this.getBuildPlan.id,
          itemType: ItemType.BuildPlan,
          itemSubType: ItemSubType.None,
        })

        return !isNameExists
      },
      message: 'Name must be unique',
    })

    // TODO: Create validation mixin and move most common validation rules into it
    // There is a data base column limitation that does not allow to use strings which are more than 255 symbols
    extend('compact', {
      validate: (value: string) => {
        return value.length <= 255
      },
      message: i18n.t('invalidNameLength') as string,
    })

    this.addSpecialCharacterVerificationRule()
    this.addReservedNamesVerificationRule()
  }

  async submit() {
    const isFormValid = await this.$refs.form.validate()
    if (!isFormValid) {
      return
    }

    this.isSubmitting = true

    if (!this.editMode) {
      this.close()
      this.isSubmitting = false
      return this.createNewBuildPlan()
    }

    const currentPrintStrategyPk = new VersionablePk(
      this.getBuildPlan.printStrategyId,
      this.getBuildPlan.printStrategyVersion,
    )

    const selectedPrintStrategy = await this.fetchBuildPlanPrintStrategyByPk(this.selectedPrintStrategyPk)
    this.setSelectedBuildPlanPrintStrategy(selectedPrintStrategy)

    if (!this.getBuildPlan.buildPlanItems.length) {
      this.close()
      this.isSubmitting = false
      return this.editBuildPlan()
    }

    if (!this.areSelectedPrintStrategyDefaultsValid) {
      messageService.showErrorMessage(this.$t('failedPrintStrategyDefaults').toString())
      this.isSubmitting = false
      return
    }

    let confirmed = true
    const isRecoaterDirectionChanged = this.isRecoaterDirectionChanged(this.selectedMachineConfigPk)

    if (this.materialModality === PrintingTypes.DMLM) {
      if (isRecoaterDirectionChanged) {
        confirmed = await this.$refs.confirm.open(
          this.$t('updateBuildPlan'),
          this.$t('editDmlmBuildPlanConfirmationMessage'),
        )
      }
    } else if (this.materialModality === PrintingTypes.BinderJet) {
      const areScaleFactorsDifferent = await this.areSelectedPrintStrategyScaleFactorsChanged(currentPrintStrategyPk)
      const existNominalPart = this.getBuildPlan.buildPlanItems.some(
        (bpItem) => bpItem.partProperties[0].processState === ProcessState.Nominal,
      )

      if ((areScaleFactorsDifferent && existNominalPart) || isRecoaterDirectionChanged) {
        confirmed = await this.$refs.confirm.open(
          this.$t('updateBuildPlan'),
          this.$t('editBjtBuildPlanConfirmationMessage'),
        )
      }
    }

    if (!confirmed) {
      this.isSubmitting = false
      return
    }

    try {
      this.setBuildPlanUpdatingState(true)
      this.setIsLoading(true)
      await this.editBuildPlan()
      this.close()
    } finally {
      this.isSubmitting = false
      this.setBuildPlanUpdatingState(false)
      this.setIsLoading(false)
    }
  }

  close() {
    this.$emit('close')
  }

  async generateUniqueBuildPlanName() {
    this.buildPlanName = await this.generateUniqueName({
      name: DEFAULT_BUILD_PLAN_NAME,
      parentId: this.root,
      delimiter: NAME_DELIMITER,
    })
  }

  async createNewBuildPlan() {
    const bpDto: ICreateBuildPlanDto = {
      name: this.buildPlanName,
      machineConfigId: this.selectedMachineConfigPk.id,
      machineConfigVersion: this.selectedMachineConfigPk.version,
      materialId: this.selectedMaterialPk.id,
      materialVersion: this.selectedMaterialPk.version,
      printStrategyId: this.selectedPrintStrategyPk.id,
      printStrategyVersion: this.selectedPrintStrategyPk.version,
      buildPlateId: this.buildPlatePk.id,
      buildPlateVersion: this.buildPlatePk.version,
      modality: this.selectedMachineConfig.printingType,
      subType: ItemSubType.None,
    }
    const createdBp = await this.createBuildPlan(bpDto)

    if (this.isError) {
      messageService.showErrorMessage(this.getErrorText)
      return
    }

    this.addBuildPlan(createdBp)

    localStorageService.set(LocalStorageKeys.CreateBuildPlanLastUsedValues, {
      materialId: this.selectedMaterialPk.id,
      machineConfigId: this.selectedMachineConfigPk.id,
      printStrategyId: this.selectedPrintStrategyPk.id,
      buildPlateId: this.selectedBuildPlatePk.id,
      binderId: this.selectedBinderPk && this.selectedBinderPk.id,
    })
  }

  async editBuildPlan() {
    const bp = this.getBuildPlan
    const printStrategyId = this.selectedPrintStrategyPk.id
    let printStrategyVersion = this.selectedPrintStrategyPk.version

    // if print strategy was not changed in modal - we have to keep originally selected version for BP
    if (bp.printStrategyId === printStrategyId) {
      printStrategyVersion = bp.printStrategyVersion
    }

    const updatedBp = {
      printStrategyId,
      printStrategyVersion,
      name: this.buildPlanName,
      materialId: this.materialPk.id,
      materialVersion: this.materialPk.version,
      machineConfigId: this.machineConfigPk.id,
      machineConfigVersion: this.machineConfigPk.version,
      buildPlateId: this.buildPlatePk.id,
      buildPlateVersion: this.buildPlatePk.version,
      modality: this.selectedMachineConfig.printingType,
      constraints:
        this.selectedMachineConfig.printingType === PrintingTypes.DMLM
          ? { wallsMargin: 5, partMargin: 2 }
          : { wallsMargin: 0, partMargin: 2 },
    } as IBuildPlan

    const wasUpdated =
      bp.materialId !== updatedBp.materialId ||
      bp.materialVersion !== updatedBp.materialVersion ||
      bp.machineConfigId !== updatedBp.machineConfigId ||
      bp.machineConfigVersion !== updatedBp.machineConfigVersion ||
      bp.printStrategyId !== updatedBp.printStrategyId ||
      bp.printStrategyVersion !== updatedBp.printStrategyVersion ||
      bp.buildPlateId !== updatedBp.buildPlateId ||
      bp.buildPlateVersion !== updatedBp.buildPlateVersion
    const wasRenamed = bp.name !== updatedBp.name

    if (!wasUpdated && !wasRenamed) {
      return
    }

    if (wasUpdated) {
      const record = await this.updateBuildPlanV1({ buildPlan: updatedBp })
      await this.getBuildPlanPrintStrategy(new VersionablePk(updatedBp.printStrategyId, updatedBp.printStrategyVersion))

      this.updateVariant(createVariantItemFromPlan(record))
      this.selectBuildPlate({
        buildPlatePk: new VersionablePk(updatedBp.buildPlateId, updatedBp.buildPlateVersion),
        machineConfigPk: new VersionablePk(updatedBp.machineConfigId, updatedBp.machineConfigVersion),
        buildPlanSubType: bp.subType,
        modality: updatedBp.modality,
      })

      await this.updateBuildPlanItemsDefaults()
    }

    if (wasRenamed && !this.isError) {
      const renamePayload: RenameItemPayload = { id: bp.id, name: updatedBp.name }

      await this.updateItem(renamePayload)

      if (!this.isError) {
        this.setBuildPlan({ ...this.getBuildPlan, ...updatedBp })
        this.setVariants(
          this.getAllAvailableBuildPlanVariants.map((variant) => {
            return { ...variant, name: updatedBp.name }
          }),
        )
      }
    }

    if (this.isError) {
      messageService.showErrorMessage(this.getErrorText)
      return
    }

    this.addCommand(
      new EditBuildPlanCommand(
        bp,
        updatedBp,
        bp.buildPlanItems,
        this.getAllBuildPlanItems,
        this.getCommandType,
        wasUpdated,
        wasRenamed,
        this.getAllAvailableBuildPlanVariants,
        this.$store.dispatch,
        this.$store.commit,
      ),
    )
  }
}
