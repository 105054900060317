var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('span',{class:{
      selectable: !_vm.isPartSelectionDisabled,
      disabled: _vm.isDisabled,
    },on:{"click":function($event){$event.stopPropagation();return _vm.clickOnPart(_vm.insight.details.bpItemId)},"mouseover":function($event){return _vm.hoverIntoPart(_vm.insight.details.bpItemId)},"mouseleave":function($event){return _vm.hoverOutOfPart(_vm.insight.details.bpItemId)}}},[_vm._v(_vm._s(_vm.partName))]),_vm._v(": "),_c('span',{class:{
      selectable: !_vm.isPartSelectionDisabled,
      disabled: _vm.isDisabled || _vm.isSupportToolPreparing,
    },on:{"click":function($event){$event.stopPropagation();return _vm.clickOnSet(_vm.insight.details.bpItemId)}}},[_vm._v(_vm._s(_vm.failedOverhangSets))])])
}
var staticRenderFns = []

export { render, staticRenderFns }