
import Vue from 'vue'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'
import { Mixins, Prop, Watch } from 'vue-property-decorator'
import { LocaleMessages } from 'vue-i18n'
import Button from '@/components/controls/Common/Button.vue'
import StoresNamespaces from '@/store/namespaces'
import NumberField from '@/components/controls/Common/NumberField.vue'
import DecimalNumberField from '@/components/controls/Common/DecimalNumberField.vue'
import TextField from '@/components/controls/Common/TextField.vue'
import buildPlans from '@/api/buildPlans'
import buildPlates from '@/api/buildPlates'
import {
  GeometryType,
  IParameterSet,
  IBuildPlanItem,
  IProductionSet,
  IDmlmProductionSetContent,
  IDmlmParameterSetContent,
  SelectionUnit,
  Visibility,
} from '@/types/BuildPlans/IBuildPlan'
import {
  BinderJetAnalysisTypeOptions,
  BinderJetMaterialModelOptions,
  BinderJetMeshContactOptions,
  BinderJetParallelMemoryOptions,
  BinderJetSolverTypeOptions,
  calculateMacroLayers,
  calculateMaxMinWallThickness,
  calculateMeshSizeRange,
  convertArrayToTemperatureProfile,
  convertTemperatureProfileToArray,
  copyTemperatureProfile,
  FrictionCoefficientType,
  IBaseSimulateCompensation,
  IBinderJetSimulateCompensation,
  IBinderJetCompensationConstrainst,
  BinderJetCompensationConstraintTypes,
  IDmlmSimulateCompensation,
  IDmlmInherentStrainParameters,
  IDmlmLaserPrameters,
  InfoStatusCode,
  IPartHeights,
  isValidationsRuleSatisfied,
  roundNumberByDigits,
  SimProcessType,
  SimSpeed,
  SimSpeedUI,
  StepProcessType,
  TemperatureProfileType,
} from '@/types/Simulation/SimulationCompensation'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import { PrintingTypes } from '@/types/IMachineConfig'
import { JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import round from '@/utils/arithmetic/round'
import { convert as convertLength } from '@/utils/converter/lengthConverter'
import { IMaterial, IPhysicalProperty } from '@/types/IMaterial'
import { ISinteringSurface } from '@/types/ISinteringSurface'
import draggable from 'vuedraggable'
import { isTabVisible } from '@/utils/common'
import {
  DEFAULT_DECIMAL_PRECISION,
  DEFAULT_MACHINE_BUILD_CHAMBER_UNIT,
  ORIENT_PREFERRED_SURFACE_COLOR,
} from '@/constants'
import { IBuildPlanInsight, InsightErrorCodes, IPendingInsights } from '@/types/BuildPlans/IBuildPlanInsight'
import { InsightsSeverity } from '@/types/Common/Insights'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { BoundingBox } from '@/visualization/models/DataModel'
import Menu from '@/components/controls/Common/Menu.vue'
import { CalculateLayerThicknessMixin } from '@/components/layout/mixins/CalculateLayerThicknessMixin'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { VersionableModel } from '@/types/Common/VersionableModel'
import { extend } from 'vee-validate'
import { equalWithTolerance } from '@/utils/number'
import { SupportTypes } from '@/types/BuildPlans/IBuildPlanItemSettings'
import { getDefaultBaseOnType } from '@/utils/parameterSet/parameterSetUtils'
import LabeledSelector from '@/components/controls/Common/LabeledSelector.vue'
import { ClickOutsideMixin } from '@/components/layout/mixins/ClickOutsideMixin'
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo'
import { MultiItemCollector } from '@/types/OptionalMultiItemCollector/MultiItemCollector'
import OptionalMultiItemCollector from '@/components/layout/buildPlans/OptionalMultiItemCollector.vue'
import { SelectedItem } from '@/types/OptionalMultiItemCollector/SelectedItem'
import { v4 as uuidv4 } from 'uuid'
import { CollectorItem } from '@/visualization/rendering/Collector'
import AlertModal from '@/components/modals/AlertModal.vue'
import { Matrix, Vector3 } from '@babylonjs/core/Maths'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const commonStore = namespace(StoresNamespaces.Common)
const optionalMultiItemCollectorStore = namespace(StoresNamespaces.OptionalMultiItemCollector)
const visualizationStore = namespace(StoresNamespaces.Visualization)

const S_FACTORS = 'ScaleFactors'
const S_FACTOR_X = 'ScaleFactorX'
const S_FACTOR_Y = 'ScaleFactorY'
const S_FACTOR_Z = 'ScaleFactorZ'
const CUSTOM = 'Custom'
const DEFAULT_PLATE_OPTION = 'Ceramic'
const KELVIN_TO_CELSIUS = -273.15
const METER_TO_MM = 1000
const HT_TIME_INCREMENT = 10 // +10 minutes
const HT_TEMP_INCREMENT = 50 // +50 degrees
const STEP_INDEX_DIFERENCE = 2
const BUILD_PLATE = 'build plate'
const SOLIDUS_TEMPERATURE = 'Solidus Temperature'
const RED_COLOR = '#d80000'
const ORANGE_COLOR = '#ffa500'
const BLUE_COLOR = '#005eb8'
const BLACK_COLOR = '#000000'
const WARNING_MAX_MACRO_LAYER = 6
const MINIMUM_PARAMETER_VALUE = 0.001
const DEFAULT_CONTACT_DAMPING_FACTOR = 0.1
const PARALLEL_MEMORY_OPTIONS = 'parallelMemoryOptions.'
const MATERIAL_MODEL_OPTIONS = 'materialModelOptions.'
const SOLVER_TYPE_OPTIONS = 'solverTypeOptions.'
const MESH_CONTACT_OPTIONS = 'meshContactOptions.'
const ANALYSIS_TYPE_OPTIONS = 'bjAnalysisTypeOptions.'

const FINISHED_JOBS = [JobStatusCode.COMPLETE, JobStatusCode.ERROR, JobStatusCode.CANCELLED, JobStatusCode.WARNING]
const RUNNING_JOBS = [JobStatusCode.RUNNING, JobStatusCode.QUEUED]

@Component({
  components: {
    Button,
    NumberField,
    DecimalNumberField,
    TextField,
    draggable,
    Menu,
    LabeledSelector,
    OptionalMultiItemCollector,
    AlertModal,
  },
})
export default class BuildPlanCompensationTab
  extends Mixins(Vue, CalculateLayerThicknessMixin, ClickOutsideMixin)
  implements IToolComponent
{
  @buildPlansStore.Getter getSimulationSpeed: SimSpeed[]
  @buildPlansStore.Getter getMaterialByPk: (pk: VersionablePk) => IMaterial
  @buildPlansStore.Getter getBuildPlanProductionSet: () => IProductionSet
  @buildPlansStore.Getter getSimulationParmeters: IBaseSimulateCompensation
  @buildPlansStore.Getter('isSimulateReadOnly') isToolReadOnly: boolean
  @buildPlansStore.Getter insights: IBuildPlanInsight[]
  @buildPlansStore.Getter pendingInsights: IPendingInsights[]

  @buildPlansStore.Action updateBuildPlanSimulationParams: (payload: {
    simulationParams: IBaseSimulateCompensation
    hideAPIErrorMessages?: boolean
  }) => Promise<void>
  @buildPlansStore.Action fetchInsightsByBuildPlanId: (payload: {
    buildPlanId: string
    changeState: boolean
  }) => Promise<IBuildPlanInsight[]>
  @buildPlansStore.Action createInsightMultiple: (insights: IBuildPlanInsight[]) => Promise<void>

  @buildPlansStore.Mutation reportInsightIssues: (issues: IPendingInsights[]) => void
  @buildPlansStore.Mutation removeInsights: (insightsIds: string[]) => void
  @buildPlansStore.Mutation updateInsights: (insights: IBuildPlanInsight[]) => void

  @commonStore.Getter tooltipOpenDelay: number

  @optionalMultiItemCollectorStore.Getter getCollectorById: (id: string) => MultiItemCollector
  @optionalMultiItemCollectorStore.Mutation setActiveCollector: (id: string) => void
  @optionalMultiItemCollectorStore.Mutation deselectLastCollectorItem: (id: string) => void
  @optionalMultiItemCollectorStore.Getter isCollectorActive: (id: string) => boolean

  @visualizationStore.Getter boundingBox: BoundingBox
  @visualizationStore.Getter getPartHeights: (buildPlanItems: IBuildPlanItem[]) => IPartHeights[]
  @visualizationStore.Getter getPartsBoundingBox: () => Promise<BoundingInfo>
  @visualizationStore.Getter('getFacetDataByFaceId') getFacetDataByFaceId: Function
  @visualizationStore.Getter('getWorldMatrixByBuildPlanItemId') getWorldMatrixByBuildPlanItemId: (
    buildPlanItemId: string,
  ) => Matrix
  @visualizationStore.Action setBpItemVisibility: (payload: {
    bpItem: IBuildPlanItem
    makeVisible: boolean
    showAsTransparent: boolean
  }) => void

  $refs: {
    minWall: InstanceType<typeof DecimalNumberField>
    contactDampingFactor: InstanceType<typeof DecimalNumberField>
    bpt: InstanceType<typeof DecimalNumberField>
    alert: InstanceType<typeof AlertModal>
  }

  @Prop({ required: true }) showAlert!: (
    title: string | LocaleMessages,
    message: string | LocaleMessages,
    submitLabel?: string,
    options?: object,
  ) => Promise<boolean>
  @Prop({ default: false }) simuTabExpanded: boolean
  // Parameters

  isToolMounted: boolean = false

  // -- Compensation --
  // IBaseSimulateCompensation structure
  viewBaseParameters: IBaseSimulateCompensation = {
    buildPlanId: null,
    runCompensation: false,
    maxIterations: 5,
    convergenceValue: 1,
  }
  compensationBlocked: boolean = true

  // -- DMLM --
  // IDmlmSimulateCompensation structure
  viewDmlmParameters: IDmlmSimulateCompensation = {
    computeType: PrintingTypes.DMLM.toLowerCase(),
    buildPlanId: this.viewBaseParameters.buildPlanId,
    runCompensation: this.viewBaseParameters.runCompensation,
    maxIterations: this.viewBaseParameters.maxIterations,
    convergenceValue: this.viewBaseParameters.convergenceValue,
    ambientTemperatureInCelsius: 25,
    buildPlateTemperatureInCelsius: 28,
    buildPlateThicknessInMm: 25.4,
    minWallThicknessInMm: 1,
    speedVsAccuracy: SimSpeed.STANDARD,
    creepDataValidation: true,
    meshOnly: true,
    processSteps: [
      {
        processType: StepProcessType.Thermal,
        index: 0,
      },
      {
        processType: StepProcessType.Mechanical,
        index: 1,
      },
    ],
    inherentStrainParameters: [],
  }

  supportFileNames: string[] = []
  currentSimulationProcess: SimProcessType = SimProcessType.BuildSimulation
  currentHtOption: string
  material: IMaterial
  creepDataValidated: boolean
  creepDataValidatedMessage: string = ' '
  tProfileFile: File = null
  fileTemperatureProfile: number[][]
  defaultTemperatureProfile: TemperatureProfileType[] = []
  customTemperatureProfile: TemperatureProfileType[] = []
  customDefined: boolean = false
  customString: string = ''
  minWallInformation: string = ''
  lowNumberMacroLayersParts: IPartHeights[] = []
  storedPartHeights: IPartHeights[] = []

  // fileString: String = 'Pick a Temperature Profile file'
  // fileErrorMessage: String = ''
  visibleMRBP: boolean = false
  visibleMRSp: boolean = false
  visibleSR: boolean = false
  firstProcess: boolean = true
  lastProcess: boolean = true
  removableProcess: boolean = false
  isSimulateReadOnly: boolean = false
  isSimulationJobCreated: boolean = false
  numberMacroLayers: number = 0
  macroLayerThickness: number = 0
  infoStatus: InfoStatusCode = InfoStatusCode.INFO
  simulationProcessAvailable: SimProcessType[] = [
    SimProcessType.MaterialRemovalBP,
    SimProcessType.MaterialRemovalSupport,
    SimProcessType.StressRelief,
  ]
  openedSections = {
    settings: true,
    compensation: true,
    processSteps: true,
    summary: true,
  }
  simulationProcessList = []
  selectedSimulationProcessStep = null
  openedStressReliefProcessStep = null
  printing = SimProcessType.BuildSimulation

  processIndex: number = 0
  isShownMenu: boolean = false
  x = 0
  y = 0
  timeSingleValue: number = 0
  temperatureSingleValue: number = 50
  temperatureProfilesList = []
  isShownTimeTempMenu: boolean = false
  timeTempIndex: number = 0
  firstTimeTemp: boolean = true
  lastTimeTemp: boolean = true
  nullSetpSelection: boolean = true
  heatTreatmentExandIcon: string = 'mdi-chevron-down'
  addStepSelected: boolean = false
  srHeaders = [
    { text: this.getStressReliefTime(), sortable: false, align: 'center' },
    { text: this.getStressReliefTemp(), sortable: false, align: 'center' },
    { text: '', sortable: false, align: 'left' },
  ]
  currentHeatTreatment: TemperatureProfileType = null
  isHeatTreatmentInUse: boolean = false
  addHeatTreatmentActivated: boolean = false
  isRemoveHTAllowed: boolean = true
  simulationSetToDelete = null

  settingsDmlmPanelActive: boolean = false
  settingsInherentStrainPanelActive: boolean = false
  settingsLaserPanelActive: boolean = false
  latestParameterSets = []

  // -- Binder Jet --

  // Compensation Constraints
  transitionTolerance = 2.4 // 2.4 mm
  constraintTolerance = 0.1 // 0.1 mm
  constraintType = null
  binderJetCompensationConstraintTypes = this.getBinderJetCompensationConstraintTypes()

  // IBinderJetSimulateCompensation structure
  viewBinderJetParameters: IBinderJetSimulateCompensation = {
    computeType: PrintingTypes.BinderJet.toLowerCase(),
    buildPlanId: this.viewBaseParameters.buildPlanId,
    runCompensation: this.viewBaseParameters.runCompensation,
    maxIterations: this.viewBaseParameters.maxIterations,
    convergenceValue: this.viewBaseParameters.convergenceValue,
    sinterPlateThicknessInMm: 6,
    speedVsAccuracy: SimSpeed.STANDARD,
    shrinkageActivation: true,
    selectedFrictionCoefficientPlate: null,
    selectedFrictionCoefficientSupport: null,
    frictionCoefficient: [],
    binderjetScalingFactor: [],
    autoMeshSizeActive: true,
    meshSize: 0,
    meshSizeUnit: 'mm',
    contactDampingFactor: DEFAULT_CONTACT_DAMPING_FACTOR,
    materialModelOption: BinderJetMaterialModelOptions.SYMMETRIC,
    parallelMemoryOption: BinderJetParallelMemoryOptions.SHARED,
    solverType: BinderJetSolverTypeOptions.DIRECT,
    remesh: false,
    meshContact: BinderJetMeshContactOptions.FRICTIONAL,
    transitionTol: this.transitionTolerance,
    compensationConstraints: [],
    generalContact: false,
  }
  waitingMode: boolean = false

  // Scaling Factor
  scaleFactorActive: boolean = true
  viewScalingFactor: number[] = []
  defaultScalingFactor: number[] = []
  userScalingFactor: number[] = []

  // Friction Coefficient
  frictionCoefficientPlateListOfOptions: string[] = []
  defaultFrictionCoefficient: FrictionCoefficientType[] = []
  plateCustomFrictionCoefficient = 0.4
  plateCustomActive: boolean = false
  frictionCoefficientSupportListOfOptions: string[] = []
  supportCustomFrictionCoefficient = 0.4
  supportCustomActive: boolean = false
  supportFrictionCoefficientActive: boolean = false

  binderJetAnalysisTypeOptions = this.getBinderJetAnalysisTypeOptions()
  currentBjAnalysisTypeOption = 1 // friction + gravity
  binderJetMaterialModelOptions = this.getBinderJetMaterialModelOptions()
  currentBjMaterialModelOption = 1 // symmetric
  binderJetParallelMemoryOptions = this.getBinderJetParallelMemoryOptions()
  currentBjParallelMemoryOption = 0
  binderJetSolverTypeOptions = this.getBinderJetSolverTypeOptions()
  currentBjSolverTypeOption = 0
  binderJetMeshContactOptions = this.getBinderJetMeshContactOptions()
  currentBjMeshContactOption = 0
  bjAnalysisTypeMessage: string = ' '
  materialModelMessage: string = ' '
  parallelMemoryMessage: string = ' '
  solverTypeMessage: string = ' '
  meshContactMessage: string = ' '

  settingsPanelActive: boolean = false
  lastValidStabilizationFactor = DEFAULT_CONTACT_DAMPING_FACTOR

  // -- All --
  isOkDisabled: boolean = false
  currentSimCompJob = null
  intervalId = null
  okIntervalId = null
  noSupports: boolean = true
  layerThickness: number
  saveParametersActive: boolean = false
  precisionNumber: number = DEFAULT_DECIMAL_PRECISION
  redColor: string = RED_COLOR

  // Analysis Speed
  currentSimuSpeed: SimSpeed = SimSpeed.STANDARD
  currentUISimuSpeed: SimSpeedUI = SimSpeedUI.STANDARD
  uISimuSpeed: string[] = []
  currentSlicer: number = 2

  // Insights
  currentInsightCode: InsightErrorCodes

  // Surface Collector for surfaces to keep flat
  flatSurfacesMultiItemCollector: MultiItemCollector = null
  itemFilter = null
  flatSurfaceZPosition = null

  // Validation Rules
  ambientTemperatureValidationRule = {
    rules: {
      required: true,
      range: {
        min_value: 20.0,
        max_value: 100.0,
        unit: this.getTemperatureUnit(),
      },
    },
    customMessages: {
      required: '',
    },
  }

  plateThicknessValidationRule = {
    rules: {
      required: true,
      range: {
        min_value: 0.001,
        max_value: 3000.0,
        unit: this.getLengthUnit(),
      },
    },
    customMessages: {
      required: '',
    },
  }

  minWallThicknessValidationRule = {
    rules: {
      required: true,
      range: {
        min_value: 0.001,
        max_value: 1000.0,
        unit: this.getLengthUnit(),
      },
    },
    customMessages: {
      required: '',
    },
  }

  frictionCoefficientValidationRule = {
    rules: {
      required: true,
      range: {
        min_value: 0.001,
        max_value: 1000.0,
        unit: '',
      },
    },
    customMessages: {
      required: '',
    },
  }

  meshSizeValidationRule = {
    rules: {
      required: true,
      min_range: {
        min_value: 0.001,
        unit: this.getLengthUnit(),
      },
    },
    customMessages: {
      required: '',
    },
  }

  meshSizeRange = {
    min_value: this.meshSizeValidationRule.rules.min_range.min_value,
    max_value: Number.MAX_VALUE,
    unit: this.meshSizeValidationRule.rules.min_range.unit,
  }

  maxIterationValidationRule = {
    rules: {
      required: true,
      range: {
        min_value: 1,
        max_value: 20,
        unit: '',
      },
    },
    customMessages: {
      required: '',
    },
  }

  maxAllowableDeviationValidationRule = {
    rules: {
      required: true,
      range: {
        min_value: 0.01,
        max_value: 100.0,
        unit: this.getLengthUnit(),
      },
    },
    customMessages: {
      required: '',
    },
  }

  maxAllowableToleranceRule = {
    rules: {
      required: true,
      range: {
        min_value: 0.001,
        max_value: 250.0,
        unit: this.getLengthUnit(),
      },
    },
    customMessages: {
      required: '',
    },
  }

  betweenZeroAndOneValidationRule = {
    rules: {
      required: true,
      range: {
        min_value: 0.0,
        max_value: 1.0,
        unit: this.getLengthUnit(),
      },
    },
    customMessages: {
      required: '',
    },
  }

  numericalFromOneToOnePointFiveValidationRule = {
    rules: {
      required: true,
      range: {
        min_value: 1.005,
        max_value: 1.5,
        unit: '',
      },
    },
    customMessages: {
      required: '',
    },
  }

  numericalFromMinusOneToOneValidationRule = {
    rules: {
      required: true,
      range: {
        min_value: -1.0,
        max_value: 1.0,
        unit: '',
      },
    },
    customMessages: {
      required: '',
    },
  }

  numericalTimeValidationRule = {
    rules: {
      required: true,
      min_range: {
        min_value: 0.0,
        unit: '',
      },
    },
    customMessages: {
      required: '',
    },
  }

  numericalTimeValidationRuleNotRequired = {
    rules: {
      required: true,
      min_range: {
        min_value: 0.0,
        unit: '',
      },
    },
    customMessages: {
      required: '',
    },
  }

  numericalTemperatureValidationRule = {
    rules: {
      required: true,
      range: {
        min_value: 0.0,
        max_value: 1200.0,
        unit: this.getTemperatureUnit(),
      },
    },
    customMessages: {
      required: '',
    },
  }

  buildPlateTemperatureValidationRule = {
    rules: {
      required: true,
      range: {
        min_value: 20.0,
        max_value: 1700.0,
        unit: this.getTemperatureUnit(),
      },
    },
    customMessages: {
      required: '',
    },
  }

  powerValidationRule = {
    rules: {
      required: true,
      min_range: {
        min_value: 0.0,
        unit: this.getPowerUnit(),
      },
    },
    customMessages: {
      required: '',
    },
  }

  microLengthValidationRule = {
    rules: {
      required: true,
      min_range: {
        min_value: 0.001,
        unit: this.getMicroLengthUnit(),
      },
    },
    customMessages: {
      required: '',
    },
  }

  speedValidationRule = {
    rules: {
      required: true,
      min_range: {
        min_value: 0.001,
        unit: this.getSpeedUnit(),
      },
    },
    customMessages: {
      required: '',
    },
  }

  /**************************************
   * Generic tool method implementations
   **************************************/
  // need to mention these generic optional methods even if they are not implemented by the tool
  // due to TypeScript's weak type detection per https://stackoverflow.com/a/47930521
  clickCancel: () => void

  // Process List
  async onCreateProcessClick(uiItem) {
    const length = this.simulationProcessList.length

    this.currentSimulationProcess = uiItem
    this.nullSetpSelection = this.currentSimulationProcess === null
    this.stepsVisibility()

    const selectedSimuProcess = {
      index: length,
      simulationProcess: this.currentSimulationProcess,
      userDefined: false,
      temperatureProfile: [],
      customTemperatureProfile: [],
      htListOfOptions: [],
    }
    if (this.currentSimulationProcess !== SimProcessType.StressRelief) {
      const removeIndex = this.simulationProcessAvailable.indexOf(this.currentSimulationProcess)
      this.simulationProcessAvailable.splice(removeIndex, 1)
    } else {
      copyTemperatureProfile(selectedSimuProcess.temperatureProfile, this.defaultTemperatureProfile)
      copyTemperatureProfile(selectedSimuProcess.customTemperatureProfile, this.defaultTemperatureProfile)
      selectedSimuProcess.htListOfOptions = [this.material.name]
    }

    this.currentSimulationProcess = null
    this.simulationProcessList.push(selectedSimuProcess)
    this.selectProcessStep(selectedSimuProcess)
    this.nullSetpSelection = true
    await this.saveDmlmProcesStep()
  }

  onCreateStressReliefProcessClick() {
    if (this.isOnlyStressReliefProcessAvailable()) {
      this.onCreateProcessClick(SimProcessType.StressRelief)
    }
  }

  async onMoveProcessClick(uiItem, allItems) {
    if (!this.isSimulateReadOnly) {
      this.selectedSimulationProcessStep = uiItem
      const lastIndex = this.simulationProcessList.length - 1
      for (let i = 0; i <= lastIndex; i += 1) {
        if (this.simulationProcessList[i] !== allItems[i]) {
          this.simulationProcessList[i] = allItems[i]
          this.simulationProcessList[i].index = i
        }
      }
      await this.saveDmlmProcesStep()
    }
  }

  async onRemoveProcessClick(uiItem) {
    const currentSimuP = uiItem
    this.simulationSetToDelete = uiItem
    this.processIndex = this.simulationProcessList.indexOf(currentSimuP)
    if (currentSimuP.simulationProcess !== SimProcessType.StressRelief) {
      this.simulationProcessAvailable.push(currentSimuP.simulationProcess)
    }
    this.simulationProcessList.splice(this.processIndex, 1)

    const lastIndex = this.simulationProcessList.length - 1
    for (let i = this.processIndex; i <= lastIndex; i += 1) {
      this.simulationProcessList[i].index = this.simulationProcessList[i].index - 1
    }

    if (this.simulationProcessList.length > 0) {
      this.selectProcessStep(this.simulationProcessList[0])
    }
    await this.saveDmlmProcesStep()
    this.setRunSimulationAvailable()
    this.simulationSetToDelete = null
  }

  async addStepSelection() {
    if (!this.isSimulateReadOnly) {
      this.addStepSelected = true
    }
  }

  async addStep() {
    this.addStepSelected = false
  }

  async onStressReliefClick(uiItem) {
    if (this.isStressReliefStep(uiItem)) {
      if (this.openedStressReliefProcessStep === uiItem) {
        this.openedStressReliefProcessStep = null
      } else {
        this.openedStressReliefProcessStep = uiItem
        this.selectProcessStep(this.openedStressReliefProcessStep)
      }
    }
  }

  getProcesessStepCardClass(uiItem) {
    const selectedCard = this.isSelecetedStep(uiItem)
    if (this.isStressReliefStep(uiItem) && !this.stressReliefValidStep(uiItem)) {
      return selectedCard ? 'error-selected-card' : 'error-card'
    }
    return selectedCard ? 'selected-card' : 'single-card'
  }
  isSelecetedStep(uiItem): boolean {
    return this.selectedSimulationProcessStep === uiItem
  }

  isOpenedStressReliefStep(uiItem): boolean {
    return this.openedStressReliefProcessStep === uiItem
  }

  isStressReliefStep(uiItem): boolean {
    return uiItem.simulationProcess === SimProcessType.StressRelief
  }

  isHeatTreatmentActive(uiItem): boolean {
    return this.isOpenedStressReliefStep(uiItem) && this.isStressReliefStep(uiItem)
  }

  getheatTreatmentButton(uiItem): string {
    let status = 'mdi-chevron-right'
    if (this.isHeatTreatmentActive(uiItem)) {
      status = 'mdi-chevron-down'
    }
    return status
  }

  isOnlyStressReliefProcessAvailable(): boolean {
    return (
      this.simulationProcessAvailable.length === 1 && this.simulationProcessAvailable[0] === SimProcessType.StressRelief
    )
  }

  getAddStepString(): string {
    return this.isOnlyStressReliefProcessAvailable() ? `${this.$t('addStepStressRelief')}` : `${this.$t('addStep')}`
  }

  selectProcessStep(uiItem) {
    if (this.selectedSimulationProcessStep !== uiItem) {
      this.processIndex = this.simulationProcessList.indexOf(uiItem)
      this.deSelectProcessStep(uiItem)
      this.selectedSimulationProcessStep = this.simulationProcessList[this.processIndex]
    }
    switch (this.selectedSimulationProcessStep.simulationProcess) {
      case SimProcessType.StressRelief:
        {
          if (
            this.selectedSimulationProcessStep === this.openedStressReliefProcessStep &&
            this.selectedSimulationProcessStep.temperatureProfile.length > 0
          ) {
            this.updateAddHeatTreatmentValues()
            this.customDefined = this.selectedSimulationProcessStep.userDefined
            this.assignHtOption()
          }
        }
        break
    }
  }

  deSelectProcessStep(uiItem) {
    if (this.selectedSimulationProcessStep === uiItem) {
      this.selectedSimulationProcessStep = null
    }
  }

  // Temperature Profile (Heat Treatment)
  assignHtOption() {
    if (this.customDefined) {
      if (!this.selectedSimulationProcessStep.htListOfOptions.some((op) => op === this.customString)) {
        this.selectedSimulationProcessStep.htListOfOptions.push(this.customString)
      }
      this.currentHtOption = this.customString
    } else {
      // Default option
      this.currentHtOption = this.selectedSimulationProcessStep.htListOfOptions[0]
    }
  }

  addTimeTemperatureChanged() {
    if (this.addHeatTreatmentActivated && this.isHeatTreatmentInUse) {
      this.onCreateTimeTempClick()
    }
  }

  async onCreateTimeTempClick() {
    const length = this.selectedSimulationProcessStep.temperatureProfile.length

    const selectedTimeTemp: TemperatureProfileType = {
      index: length,
      timeItem: this.timeSingleValue,
      temperatureItem: this.temperatureSingleValue,
    }
    this.selectedSimulationProcessStep.temperatureProfile.push(selectedTimeTemp)
    await this.onHTParamterChanged()
  }

  sortByTime(i1: TemperatureProfileType, i2: TemperatureProfileType) {
    if (i1.index === 0 || i2.index === 0) {
      return 0
    }
    return i1.timeItem - i2.timeItem
  }

  async onMoveTimeTempClick() {
    this.selectedSimulationProcessStep.temperatureProfile.sort((a: TemperatureProfileType, b: TemperatureProfileType) =>
      this.sortByTime(a, b),
    )
    const lastIndex = this.selectedSimulationProcessStep.temperatureProfile.length - 1
    for (let i = 0; i <= lastIndex; i += 1) {
      this.selectedSimulationProcessStep.temperatureProfile[i].index = i
    }
    await this.saveDmlmProcesStep()
  }

  async selectedHtOption(option: string, update: boolean = true) {
    this.customDefined = option === this.customString
    this.assignHtOption()

    if (this.selectedSimulationProcessStep.userDefined !== this.customDefined) {
      this.selectedSimulationProcessStep.userDefined = this.customDefined

      if (update) {
        const length = this.selectedSimulationProcessStep.temperatureProfile.length
        if (this.customDefined && this.selectedSimulationProcessStep.customTemperatureProfile.length > 1) {
          copyTemperatureProfile(
            this.selectedSimulationProcessStep.temperatureProfile,
            this.selectedSimulationProcessStep.customTemperatureProfile,
          )
        } else {
          // Default Profile
          copyTemperatureProfile(this.selectedSimulationProcessStep.temperatureProfile, this.defaultTemperatureProfile)
        }
        this.updateAddHeatTreatmentValues()
      }
      await this.onMoveTimeTempClick()
    }
  }

  updateAddHeatTreatmentValues() {
    const lastIndex = this.selectedSimulationProcessStep.temperatureProfile.length - 1
    const lastTimeItem = this.selectedSimulationProcessStep.temperatureProfile[lastIndex].timeItem
    const lastTemperatureItem = this.selectedSimulationProcessStep.temperatureProfile[lastIndex].temperatureItem

    this.timeSingleValue = lastTimeItem + HT_TIME_INCREMENT
    const newAddTemperature = lastTemperatureItem - HT_TEMP_INCREMENT
    const ambientTemperature = this.viewDmlmParameters.ambientTemperatureInCelsius
    this.temperatureSingleValue = newAddTemperature < ambientTemperature ? ambientTemperature : newAddTemperature
  }

  async onHTParamterChanged() {
    // Save customTemperatureProfile
    if (this.isHeatTreatmentInUse) {
      this.isHeatTreatmentInUse = false
    }
    copyTemperatureProfile(
      this.selectedSimulationProcessStep.customTemperatureProfile,
      this.selectedSimulationProcessStep.temperatureProfile,
    )

    if (!this.customDefined) {
      this.currentHtOption = this.customString
      await this.selectedHtOption(this.currentHtOption, false)
    }

    await this.onMoveTimeTempClick()
    this.updateAddHeatTreatmentValues()
    await this.saveDmlm()
  }

  async onRemoveTimeTempClick(uiItem) {
    const index = this.selectedSimulationProcessStep.temperatureProfile.indexOf(uiItem)
    if (index === 0) {
      return
    }
    this.selectedSimulationProcessStep.temperatureProfile.splice(index, 1)
    const lastIndex = this.selectedSimulationProcessStep.temperatureProfile.length - 1

    for (let i = index; i <= lastIndex; i += 1) {
      this.selectedSimulationProcessStep.temperatureProfile[i].index =
        this.selectedSimulationProcessStep.temperatureProfile[i].index - 1
    }
    await this.onHTParamterChanged()
  }

  getStressReliefTime(): string {
    return `${this.$t('stressRelief.timeSeconds')}`
  }

  getStressReliefTemp(): string {
    return `${this.$t('stressRelief.temperatureCelcius')}`
  }

  removeHeatTreatmentButton() {
    this.isRemoveHTAllowed =
      this.currentHeatTreatment.index === 0 ? false : this.selectedSimulationProcessStep.temperatureProfile.length > 1
  }

  selectHeatTreatment(uiItem) {
    this.currentHeatTreatment = uiItem
    this.removeHeatTreatmentButton()
    this.addHeatTreatmentActivated = false
  }

  deSelectHeatTreatment(uiItem) {
    if (this.currentHeatTreatment === uiItem) {
      this.currentHeatTreatment = null
    }
  }

  heatTreatmentSelected() {
    this.isHeatTreatmentInUse = true
  }

  heatTreatmentDeselected() {
    this.addTimeTemperatureChanged()
    this.isHeatTreatmentInUse = false
  }

  heatTreatmentEscapeDeselected() {
    this.isHeatTreatmentInUse = false
    this.updateAddHeatTreatmentValues()
  }

  isHeatTreatmentSelected(uiItem): boolean {
    return this.currentHeatTreatment === uiItem
  }

  selectAddHeatTreatment() {
    if (this.isHeatTreatmentInUse) {
      return
    }
    this.addHeatTreatmentActivated = true
    this.currentHeatTreatment = null
  }

  deSelectAddHeatTreatment() {
    if (this.isHeatTreatmentInUse) {
      this.heatTreatmentEscapeDeselected()
    }
    this.addHeatTreatmentActivated = false
  }

  getHeatTreatmetFieldClass(uiItem, temperatureProfile: TemperatureProfileType[], isTime: boolean) {
    if (isTime) {
      return this.isHeatTreatmentTimeValid(uiItem, temperatureProfile) ? 'field-number-ht' : 'field-number-ht-error'
    }
    return this.isHeatTreatmentTempValid(uiItem) ? 'field-number-ht' : 'field-number-ht-error'
  }

  getHeatTreatmetValueClass(uiItem, isAdd: boolean, temperatureProfile: TemperatureProfileType[], isTime: boolean) {
    const valueClass = isAdd ? 'value-add-ht' : 'value-ht'

    if (isTime) {
      return this.isHeatTreatmentTimeValid(uiItem, temperatureProfile) ? valueClass : 'value-ht-error'
    }
    return this.isHeatTreatmentTempValid(uiItem) ? valueClass : 'value-ht-error'
  }

  getHeatTreatmetFieldVisualization(uiItem, isTime: boolean = false): boolean {
    const isFirstTimeValue = uiItem.timeItem === 0 && isTime && uiItem.index === 0
    return !this.isSimulateReadOnly && this.isHeatTreatmentSelected(uiItem) && !isFirstTimeValue
  }

  isHeatTreatmentTimeDuplicated(uiItem, temperatureProfile) {
    return temperatureProfile.filter((ht) => ht.timeItem === uiItem).length > 1
  }

  isHeatTreatmentTimeValid(uiItem, temperatureProfile: TemperatureProfileType[] = null): boolean {
    const timeValidation =
      temperatureProfile === null ? true : !this.isHeatTreatmentTimeDuplicated(uiItem, temperatureProfile)

    return isValidationsRuleSatisfied(uiItem, this.numericalTimeValidationRule.rules) && timeValidation
  }

  isHeatTreatmentTempValid(uiItem): boolean {
    return isValidationsRuleSatisfied(uiItem, this.numericalTemperatureValidationRule.rules.range)
  }

  get simuProcess(): SimProcessType[] {
    return this.simulationProcessAvailable
  }

  get isDMLM() {
    return this.buildPlan.modality === PrintingTypes.DMLM
  }

  get isSinterPlan() {
    return this.buildPlan.subType === ItemSubType.SinterPlan
  }

  getLengthUnit() {
    return this.$t('millimeterAbbr').toString()
  }

  getMicroLengthUnit() {
    return this.$t('microLengthAbbr').toString()
  }

  getTemperatureUnit(): string {
    return `${this.$t('celsiusDegreeAbbr').toString()}`
  }

  getPowerUnit(): string {
    return `${this.$t('wattAbbr').toString()}`
  }

  getSpeedUnit(): string {
    return `${this.$t('millimeterPerSecAbbr').toString()}`
  }

  get simulationSpeedString() {
    const simuSpeedString: string[] = []
    const simSpeed: SimSpeed[] = this.getSimulationSpeed
    for (const speed of simSpeed) {
      switch (speed) {
        case SimSpeed.FAST:
          {
            simuSpeedString.push(this.$t('analysisSpeed.fast') as string)
          }
          break
        case SimSpeed.STANDARD:
          {
            simuSpeedString.push(this.$t('analysisSpeed.standard') as string)
          }
          break
        case SimSpeed.ACCURATE:
          {
            simuSpeedString.push(this.$t('analysisSpeed.accurate') as string)
          }
          break
      }
    }
    return simuSpeedString
  }

  get meshSizeRecommendedRange() {
    return this.$t('recommendedMeshSizeValueMessage', {
      min_value: this.meshSizeRange.min_value,
      max_value: this.meshSizeRange.max_value,
      unit: this.meshSizeRange.unit,
    }).toString()
  }

  get isSurfaceCollectorReadOnly() {
    if (this.isSimulateReadOnly) {
      return true
    }

    // TODO: update or remove this
    // if (this.selectedBuildPlanItem && this.selectedBuildPlanItem.part != null) {
    //   if (this.getIsSelectedPartBrep()) {
    //     return false
    //   }
    // }

    return false
  }

  isMinWallThicknessValid(): boolean {
    return (
      this.viewDmlmParameters.minWallThicknessInMm > this.minWallThicknessValidationRule.rules.range.min_value ||
      equalWithTolerance(
        this.viewDmlmParameters.minWallThicknessInMm,
        this.minWallThicknessValidationRule.rules.range.min_value,
        Number.EPSILON,
      )
    )
  }

  getMinWallIcon(): string {
    switch (this.infoStatus) {
      case InfoStatusCode.INFO: {
        return 'mdi-information-outline'
      }
      case InfoStatusCode.WARNING:
      case InfoStatusCode.ERROR: {
        return 'mdi-alert-circle'
      }
    }
  }

  getMinWallIconColor(): string {
    switch (this.infoStatus) {
      case InfoStatusCode.INFO: {
        return BLUE_COLOR
      }
      case InfoStatusCode.WARNING: {
        return ORANGE_COLOR
      }
      case InfoStatusCode.ERROR: {
        return RED_COLOR
      }
    }
  }

  refreshMinWallThicknessField() {
    if (this.$refs.minWall.model === null) {
      this.$refs.minWall.resetNumeric()
      this.$refs.minWall.resetToZero()
    }
    this.$refs.minWall.validate()
  }

  updateMinWallThicknessValidationRule() {
    if (this.boundingBox.maxZ <= MINIMUM_PARAMETER_VALUE) {
      throw new Error(`Min wall thickness validation rule maximum value cannot be calculated.
        Because bounding box max Z value is ${this.boundingBox.maxZ}.`)
    }
    let maxValue = calculateMaxMinWallThickness(this.currentSimuSpeed, this.boundingBox.maxZ)
    if (maxValue <= MINIMUM_PARAMETER_VALUE) {
      maxValue = MINIMUM_PARAMETER_VALUE * 2
    }
    this.minWallThicknessValidationRule.rules.range.max_value = roundNumberByDigits(
      maxValue - MINIMUM_PARAMETER_VALUE,
      this.precisionNumber,
    )
  }

  stressReliefValidStep(step): boolean {
    const heatTreatment = step.temperatureProfile as TemperatureProfileType[]
    return (
      heatTreatment.every(
        (ht) =>
          this.isHeatTreatmentTimeValid(ht.timeItem, heatTreatment) &&
          this.isHeatTreatmentTempValid(ht.temperatureItem),
      ) && heatTreatment.length > 1
    )
  }

  setpsValidationSatisfied(): boolean {
    const stressReliefSteps = this.simulationProcessList.filter(
      (spl) => spl.simulationProcess === SimProcessType.StressRelief,
    )
    for (const step of stressReliefSteps) {
      if (!this.stressReliefValidStep(step)) {
        return false
      }
    }

    return true
  }

  setRunSimulationAvailable() {
    // before checking any further conditions set
    // availability based on running/completed/failed jobs
    let isAvailable: boolean =
      !this.currentSimCompJob ||
      (this.currentSimCompJob &&
        [JobStatusCode.COMPLETE, JobStatusCode.WARNING].indexOf(this.currentSimCompJob.code) === -1)

    if (this.viewBaseParameters.runCompensation && isAvailable) {
      // Compensation
      isAvailable =
        isValidationsRuleSatisfied(
          // Max Iterations
          this.viewBaseParameters.maxIterations,
          this.maxIterationValidationRule.rules.range,
        ) &&
        isValidationsRuleSatisfied(
          // Convergence
          this.viewBaseParameters.convergenceValue,
          this.maxAllowableDeviationValidationRule.rules.range,
        ) &&
        isValidationsRuleSatisfied(
          // Constraint Tolerance
          this.constraintTolerance,
          this.maxAllowableToleranceRule.rules.range,
        ) &&
        isValidationsRuleSatisfied(
          // Transition Tolerance
          this.transitionTolerance,
          this.maxAllowableToleranceRule.rules.range,
        )
    }

    if (this.isDMLM && isAvailable) {
      // Dmlm
      isAvailable =
        isValidationsRuleSatisfied(
          // Min Wall
          this.viewDmlmParameters.minWallThicknessInMm,
          this.minWallThicknessValidationRule.rules.range,
        ) &&
        isValidationsRuleSatisfied(
          // Plate Thickness
          this.viewDmlmParameters.buildPlateThicknessInMm,
          this.plateThicknessValidationRule.rules.range,
        ) &&
        isValidationsRuleSatisfied(
          // Ambient Temperature
          this.viewDmlmParameters.ambientTemperatureInCelsius,
          this.ambientTemperatureValidationRule.rules.range,
        ) &&
        isValidationsRuleSatisfied(
          // Build Plate Temperature
          this.viewDmlmParameters.buildPlateTemperatureInCelsius,
          this.buildPlateTemperatureValidationRule.rules.range,
        )
      if (isAvailable) {
        // Steps Validation
        isAvailable = this.setpsValidationSatisfied()
        // Macro Layer Validation
        isAvailable = isAvailable && this.numberMacroLayers > 1
      }
      if (isAvailable) {
        // Dmlm Advance parameters Validation
        isAvailable = this.getDmlmAdvanceValidation()
      }
    } else if (!this.isDMLM && isAvailable) {
      // BinderJet
      if (!this.viewBinderJetParameters.autoMeshSizeActive && isAvailable) {
        isAvailable = isValidationsRuleSatisfied(
          // Mesh Size
          this.viewBinderJetParameters.meshSize,
          this.meshSizeValidationRule.rules.min_range,
        )
      }

      if (this.plateCustomActive && isAvailable) {
        isAvailable = isValidationsRuleSatisfied(
          // Plate Friction Coefficient
          this.plateCustomFrictionCoefficient,
          this.frictionCoefficientValidationRule.rules.range,
        )
      }

      if (this.supportCustomActive && isAvailable) {
        isAvailable = isValidationsRuleSatisfied(
          // Support Friction Coefficient
          this.supportCustomFrictionCoefficient,
          this.frictionCoefficientValidationRule.rules.range,
        )
      }

      if (this.viewBinderJetParameters.contactDampingFactor && isAvailable) {
        isAvailable =
          this.viewBinderJetParameters.contactDampingFactor >=
          this.numericalTimeValidationRuleNotRequired.rules.min_range.min_value
      }

      if (!this.scaleFactorActive && isAvailable) {
        isAvailable =
          isValidationsRuleSatisfied(
            // Scale Factor X
            this.viewScalingFactor[0],
            this.numericalFromOneToOnePointFiveValidationRule.rules.range,
          ) &&
          isValidationsRuleSatisfied(
            // Scale Factor Y
            this.viewScalingFactor[1],
            this.numericalFromOneToOnePointFiveValidationRule.rules.range,
          ) &&
          isValidationsRuleSatisfied(
            // Scale Factor Z
            this.viewScalingFactor[2],
            this.numericalFromOneToOnePointFiveValidationRule.rules.range,
          )
      }

      isAvailable = isAvailable && this.viewBinderJetParameters.materialModelOption != null
      isAvailable = isAvailable && this.viewBinderJetParameters.parallelMemoryOption != null
      isAvailable = isAvailable && this.viewBinderJetParameters.solverType != null
      isAvailable = isAvailable && this.viewBinderJetParameters.meshContact != null
    }
    this.isOkDisabled = !isAvailable
    this.updateOkStatus()
  }

  @Watch('viewDmlmParameters.ambientTemperatureInCelsius')
  @Watch('viewDmlmParameters.buildPlateTemperatureInCelsius')
  @Watch('viewDmlmParameters.buildPlateThicknessInMm')
  @Watch('viewDmlmParameters.minWallThicknessInMm')
  @Watch('viewBaseParameters.maxIterations')
  @Watch('viewBaseParameters.convergenceValue')
  @Watch('compensationBlocked')
  onSimulationParametersChanged(simulationParameter) {
    if (simulationParameter === null) {
      this.isOkDisabled = true
    } else if (this.isDMLM || !this.waitingMode) {
      this.setRunSimulationAvailable()
    }
  }

  async onSimSpeedSlicerChange() {
    switch (this.currentSlicer) {
      case 1:
        {
          this.currentSimuSpeed = SimSpeed.FAST
          this.currentUISimuSpeed = SimSpeedUI.LOW
        }
        break
      case 2:
        {
          this.currentSimuSpeed = SimSpeed.STANDARD
          this.currentUISimuSpeed = SimSpeedUI.STANDARD
        }
        break
      case 3:
        {
          this.currentSimuSpeed = SimSpeed.ACCURATE
          this.currentUISimuSpeed = SimSpeedUI.HIGH
        }
        break
    }

    if (this.isDMLM) {
      await this.saveDmlm()
      this.refreshMinWallThicknessField()
    } else {
      // BinderJet
      await this.saveBinderJet()
    }
  }

  stepsVisibility() {
    switch (this.currentSimulationProcess) {
      case SimProcessType.BuildSimulation:
        {
          this.visibleMRBP = false
          this.visibleMRSp = false
          this.visibleSR = false
        }
        break

      case SimProcessType.MaterialRemovalBP:
        {
          this.visibleMRBP = true
          this.visibleMRSp = false
          this.visibleSR = false
        }
        break
      case SimProcessType.MaterialRemovalSupport:
        {
          this.visibleMRBP = true
          this.visibleMRSp = true
          this.visibleSR = false
        }
        break

      case SimProcessType.StressRelief:
        {
          this.visibleMRBP = true
          this.visibleMRSp = true
          this.visibleSR = true
        }
        break
    }
  }

  // toggle events
  async toggleCompensation() {
    this.compensationBlocked = !this.viewBaseParameters.runCompensation
    if (!this.isDMLM) {
      if (this.viewBaseParameters.runCompensation) {
        this.initilizeFlatSurfacesCollector()
        this.showsFlatSurfacesCollector()
      } else {
        this.onclickFlatSurfacesMultiItemCollector()
      }
    }
    await this.saveCompensation()
  }

  toggleSection(name: string) {
    if (this.openedSections[name] !== undefined) {
      this.openedSections[name] = !this.openedSections[name]
    }
  }

  setMaterialData() {
    this.material = this.getMaterialByPk(new VersionablePk(this.buildPlan.materialId, this.buildPlan.materialVersion))
  }

  async setCreepData() {
    this.creepDataValidated = await buildPlans.getMaterialCreepDataValidation(this.material.id, this.material.version)
    this.creepDataValidatedMessage = this.$t('simProcessStep.creepDataTooltip', [this.material.name]).toString()
  }

  async getSolidusTemperatureValue(): Promise<number> {
    const matPhysicalProperties: IPhysicalProperty[] = await buildPlans.getMaterialPhysicalProperties(
      VersionableModel.getPk(this.material),
    )
    const property = matPhysicalProperties.find((prop) => prop.propertyType === SOLIDUS_TEMPERATURE)
    if (property && property.propertyValue.length === 1) {
      return property.propertyValue[0] + KELVIN_TO_CELSIUS
    }
    return null
  }

  @Watch('currentBjAnalysisTypeOption')
  async onshrinkageActivationChanged(value) {
    const frictionValue = BinderJetAnalysisTypeOptions.FRICTION.toUpperCase()
    const shrinkageActivation = this.binderJetAnalysisTypeOptions[value].value === frictionValue
    this.supportFrictionCoefficientActive = !this.noSupports && shrinkageActivation
    this.plateCustomActive =
      this.viewBinderJetParameters.selectedFrictionCoefficientPlate === CUSTOM && shrinkageActivation

    if (this.viewBinderJetParameters.selectedFrictionCoefficientSupport && !this.noSupports) {
      this.supportCustomActive =
        this.viewBinderJetParameters.selectedFrictionCoefficientSupport === CUSTOM && shrinkageActivation
    }
    this.setBinderJetAnalysisTypeOption(this.binderJetAnalysisTypeOptions[value].value)

    this.viewBinderJetParameters.shrinkageActivation = shrinkageActivation
    if (!this.waitingMode) {
      await this.saveBinderJet()
    }
  }

  @Watch('plateCustomFrictionCoefficient')
  async onPlateCustomFrictionCoefficientChanged(value) {
    this.viewBinderJetParameters.frictionCoefficient[0] = this.plateCustomFrictionCoefficient
    await this.saveBinderJet()
  }

  @Watch('supportCustomFrictionCoefficient')
  async onSupportCustomFrictionCoefficientChanged(value) {
    this.viewBinderJetParameters.frictionCoefficient[1] = this.supportCustomFrictionCoefficient
    await this.saveBinderJet()
  }

  get flatSurfacesCollector() {
    if (this.flatSurfacesMultiItemCollector) {
      return this.getCollectorById(this.flatSurfacesMultiItemCollector.id)
    }
    return null
  }

  onclickFlatSurfacesMultiItemCollector() {
    if (this.flatSurfacesCollector) {
      const isActive = this.isCollectorActive(this.flatSurfacesCollector.id)
      this.buildPlan.buildPlanItems.forEach((bpItem) => {
        if (bpItem.visibility === Visibility.Hidden) {
          if (isActive) {
            // Display the selected part as opaque but do not change Visibility settings ob bpItem.
            this.setBpItemVisibility({ bpItem, makeVisible: true, showAsTransparent: false })
          } else {
            // Turn back initial display settings of hidden part. Show part as transparent because it's selected.
            this.setBpItemVisibility({ bpItem, makeVisible: false, showAsTransparent: true })
          }
        }
      })
    }
  }

  @Watch('flatSurfacesCollector', { deep: true })
  async flatSurfacesChanged() {
    if (this.isToolMounted && this.flatSurfacesCollector) {
      // Clear compensationConstraints
      this.viewBinderJetParameters.compensationConstraints = []
      if (this.flatSurfacesCollector.items && this.flatSurfacesCollector.items.length) {
        for (const flatSurfaceItem of this.flatSurfacesCollector.items) {
          const facetData = this.getFacetDataByFaceId(
            flatSurfaceItem.componentId,
            flatSurfaceItem.geometryId,
            flatSurfaceItem.buildPlanItemId,
            flatSurfaceItem.faceName,
          )
          // check for surface flatness first
          const worldMatrix = this.getWorldMatrixByBuildPlanItemId(flatSurfaceItem.buildPlanItemId)
          // first transform positions vectors by the mesh worldMatrix
          for (const positions of facetData.positions) {
            for (let positionIdx = 0; positionIdx < positions.length; positionIdx += 1) {
              positions[positionIdx] = Vector3.TransformCoordinates(positions[positionIdx], worldMatrix)
            }
          }
          const baseZ = facetData.positions[0][0].z
          const flatnessToleranceMM = 0.1
          let isSurfaceFlat = true
          for (const position of facetData.positions) {
            for (const vertexIndex in position) {
              if (Math.abs(position[vertexIndex].z - baseZ) > flatnessToleranceMM) {
                isSurfaceFlat = false
                break
              }
            }
          }

          if (!isSurfaceFlat) {
            await this.showAlert(
              this.$i18n.t('invalidSurface'),
              this.$i18n.t('invalidSurfaceMessage'),
              this.$t('close').toString(),
              {
                width: 360,
                center: true,
              },
            )
            this.setActiveCollector(this.flatSurfacesMultiItemCollector.id)
            this.deselectLastCollectorItem(this.flatSurfacesMultiItemCollector.id)
          } else {
            // UI doesn't need to do the following, as it will be done on the simcomp side:
            // 1) adjust for part's z-displacement in world space
            // 2) apply Z Scale factor to the z coordinate of flat surface constraint
            this.flatSurfaceZPosition = facetData.positions[0][0].z

            let isNewPlane = true
            for (const constraint of this.viewBinderJetParameters.compensationConstraints) {
              const zCoordinate = constraint.planePoint[2]
              const limitInf = zCoordinate - this.constraintTolerance
              const limitSup = zCoordinate + this.constraintTolerance
              if (this.flatSurfaceZPosition >= limitInf && this.flatSurfaceZPosition <= limitSup) {
                constraint.surfaceItem.push(flatSurfaceItem)
                isNewPlane = false
                break
              }
            }

            // save compensationConstraints
            if (isNewPlane) {
              const constraint: IBinderJetCompensationConstrainst = {
                planePoint: [0.0, 0.0, this.flatSurfaceZPosition],
                constraintType: this.constraintType,
                constraintTol: this.constraintTolerance,
                surfaceItem: [flatSurfaceItem],
              }
              this.viewBinderJetParameters.compensationConstraints.push(constraint)
            }
          }
        }
      }
      await this.saveBinderJet()
    }
  }

  @Watch('constraintTolerance')
  async constraintToleranceChanged() {
    const validRange: boolean = isValidationsRuleSatisfied(
      this.constraintTolerance,
      this.maxAllowableToleranceRule.rules.range,
    )
    if (validRange) {
      await this.flatSurfacesChanged()
    } else {
      this.setRunSimulationAvailable()
    }
  }

  @Watch('constraintType')
  async constraintTypeChanged() {
    this.viewBinderJetParameters.compensationConstraints.forEach((constraint) => {
      constraint.constraintType = this.constraintType
    })
    this.saveBinderJet()
  }

  @Watch('transitionTolerance')
  async transitionToleranceChanged() {
    const validRange: boolean = isValidationsRuleSatisfied(
      this.transitionTolerance,
      this.maxAllowableToleranceRule.rules.range,
    )
    if (validRange) {
      this.viewBinderJetParameters.transitionTol = this.transitionTolerance
    }
    this.setRunSimulationAvailable()
  }

  async initilizeFlatSurfacesCollector() {
    // Surface collector initialization
    const flatSurfaceCollectorId = uuidv4()
    // TODO: initialize items from selected faces when their storing is implemented
    const itemsToKeepFlat: SelectedItem[] = []
    // TODO: remove itemFilter if it's not needed
    this.itemFilter = (item: CollectorItem) => {
      return true
    }
    this.flatSurfacesMultiItemCollector = {
      id: flatSurfaceCollectorId,
      type: SelectionUnit.FaceAndEdge,
      selectionColor: ORIENT_PREFERRED_SURFACE_COLOR,
      items: itemsToKeepFlat,
    }
  }

  showsFlatSurfacesCollector() {
    // constraint surfaces selected
    let updateConstraintsTolType: boolean = true
    // Default constraintType
    this.constraintType = this.binderJetCompensationConstraintTypes[0]
    if (this.viewBinderJetParameters.transitionTol) {
      // only overwrite default value if a valid one is already stored
      this.transitionTolerance = this.viewBinderJetParameters.transitionTol
    }
    this.viewBinderJetParameters.compensationConstraints.forEach((constraint) => {
      if (updateConstraintsTolType) {
        // All constraints have the same ConstraintType, ConstraintTolerance and TransitionTolerance
        this.constraintType = constraint.constraintType
        this.constraintTolerance = constraint.constraintTol
        updateConstraintsTolType = false
      }
      // backwards compatibility with existing sinter plans
      if (!Array.isArray(constraint.surfaceItem)) {
        constraint.surfaceItem = [constraint.surfaceItem]
      }
      constraint.surfaceItem.forEach((item) => {
        const isSameBpItem: boolean = this.buildPlan.buildPlanItems.some((bpItem) => {
          return bpItem.id === item.buildPlanItemId
        })
        if (!isSameBpItem) {
          let newBuildItemId: string = null
          this.buildPlan.buildPlanItems.forEach((bpItem) => {
            const isSamePart = bpItem.partProperties.some((pProperty) => {
              return pProperty.geometryId.startsWith(item.componentId) && pProperty.geometryId.endsWith(item.geometryId)
            })
            if (isSamePart) {
              newBuildItemId = bpItem.id
            }
          })
          item.buildPlanItemId = newBuildItemId
        }
        if (item.buildPlanItemId) {
          this.flatSurfacesMultiItemCollector.items.push(item)
        }
      })
    })
  }

  getBinderJetCompensationConstraintTypes() {
    const types = []
    for (const key in BinderJetCompensationConstraintTypes) {
      if (BinderJetCompensationConstraintTypes.hasOwnProperty(key)) {
        types.push(BinderJetCompensationConstraintTypes[key])
      }
    }
    return types
  }

  async assignDefaultScalingFactor() {
    const buildPlanItem = this.buildPlan.buildPlanItems[0]
    if (buildPlanItem.partProperties) {
      const partProperty = buildPlanItem.partProperties[0]
      const printStrategyParameterSetPk = partProperty.printStrategyParameterSetId
        ? new VersionablePk(partProperty.printStrategyParameterSetId, partProperty.printStrategyParameterSetVersion)
        : getDefaultBaseOnType(this.getBuildPlanPrintStrategy.defaults, partProperty.type, partProperty.bodyType)

      const printStrategyParameterSet = this.getPrintStrategyParameterSetByPk(printStrategyParameterSetPk)
      if (!printStrategyParameterSet) {
        throw new Error(
          `Wrong print strategy parameter ${printStrategyParameterSetPk.id}, for ${buildPlanItem.part.name}`,
        )
      }
      const parameterSet = printStrategyParameterSet.parameterSet as IParameterSet
      const jsonParameter = parameterSet.partParameters

      this.defaultScalingFactor[0] = jsonParameter[S_FACTORS][S_FACTOR_X]
      this.defaultScalingFactor[1] = jsonParameter[S_FACTORS][S_FACTOR_Y]
      this.defaultScalingFactor[2] = jsonParameter[S_FACTORS][S_FACTOR_Z]
    }
  }

  async assignDefaultSinteringSurfaceParameters() {
    // Filling default FrictionCoefficient values
    const dataBaseSinteringSurface: ISinteringSurface[] = await buildPlans.getSinteringSurface(
      this.buildPlan.materialId,
      this.buildPlan.materialVersion,
    )
    // Friction Coefficient Custom selector value, is not stored in database
    this.frictionCoefficientPlateListOfOptions.push(CUSTOM)
    this.frictionCoefficientSupportListOfOptions.push(CUSTOM)

    dataBaseSinteringSurface.forEach((row) => {
      const dBFrictionCoefficient: FrictionCoefficientType = {
        surface: row.sinteringSurface,
        plateFrictionCoefficient: row.frictionalCoefficientGreenToPlate,
        supportFrictionCoefficient: row.frictionalCoefficientGreenToGreen,
      }
      this.defaultFrictionCoefficient.push(dBFrictionCoefficient)
      // Plate
      if (row.frictionalCoefficientGreenToPlate) {
        this.frictionCoefficientPlateListOfOptions.push(row.sinteringSurface)
      }
      // Support
      if (row.frictionalCoefficientGreenToGreen) {
        this.frictionCoefficientSupportListOfOptions.push(row.sinteringSurface)
      }
    })
    const frictionCoefficient: FrictionCoefficientType = {
      surface: CUSTOM,
      plateFrictionCoefficient: null,
      supportFrictionCoefficient: null,
    }
    this.defaultFrictionCoefficient.push(frictionCoefficient)
    // Plate
    let defaultPlateOption = this.frictionCoefficientPlateListOfOptions.find((surface) =>
      surface.includes(DEFAULT_PLATE_OPTION),
    )
    if (!defaultPlateOption) {
      defaultPlateOption = CUSTOM
    }
    if (this.viewBinderJetParameters.frictionCoefficient.length === 0) {
      // No plate values
      const defaultPlateCoefficient = this.defaultFrictionCoefficient.find((fC) => fC.surface === defaultPlateOption)
      this.viewBinderJetParameters.frictionCoefficient.push(defaultPlateCoefficient.plateFrictionCoefficient)
    }
    if (this.viewBinderJetParameters.frictionCoefficient.length > 0) {
      this.plateCustomFrictionCoefficient = this.viewBinderJetParameters.frictionCoefficient[0]
    }
    // Default plate options
    if (!this.viewBinderJetParameters.selectedFrictionCoefficientPlate) {
      this.viewBinderJetParameters.selectedFrictionCoefficientPlate = defaultPlateOption
    }
    await this.selectedFrictionCoefficientPlateOption()
    this.supportFrictionCoefficientActive = !this.noSupports && this.viewBinderJetParameters.shrinkageActivation
    // Support
    if (!this.noSupports) {
      // Default support options
      let defaultSupportOption = this.frictionCoefficientSupportListOfOptions[0]
      if (this.frictionCoefficientSupportListOfOptions.length > 1) {
        defaultSupportOption = this.frictionCoefficientSupportListOfOptions[1]
      } else if (!defaultSupportOption) {
        defaultSupportOption = CUSTOM
      }
      if (this.viewBinderJetParameters.frictionCoefficient.length < 1) {
        // No support value
        const defaultSupportCoefficient = this.defaultFrictionCoefficient.find(
          (fC) => fC.surface === defaultSupportOption,
        )
        this.viewBinderJetParameters.frictionCoefficient.push(defaultSupportCoefficient.supportFrictionCoefficient)
      }
      if (this.viewBinderJetParameters.frictionCoefficient.length > 1) {
        this.supportCustomFrictionCoefficient = this.viewBinderJetParameters.frictionCoefficient[1]
      }
      if (!this.viewBinderJetParameters.selectedFrictionCoefficientSupport) {
        this.viewBinderJetParameters.selectedFrictionCoefficientSupport = defaultSupportOption
      }
      await this.selectedFrictionCoefficientSupportOption()
    } else if (this.viewBinderJetParameters.frictionCoefficient.length > 1) {
      // There was a support but has been removed
      this.viewBinderJetParameters.frictionCoefficient.splice(1, 1)
    }
    while (this.viewBinderJetParameters.frictionCoefficient.length > 2) {
      // Clean-up
      this.viewBinderJetParameters.frictionCoefficient.splice(2, 1)
    }
    this.waitingMode = false
  }

  async selectedFrictionCoefficientPlateOption() {
    const option: string = this.viewBinderJetParameters.selectedFrictionCoefficientPlate
    if (!option) {
      return
    }
    const frictionCoefficient = this.defaultFrictionCoefficient.find((fc) => fc.surface === option)
    this.plateCustomActive = option === CUSTOM && this.viewBinderJetParameters.shrinkageActivation
    const currentValue =
      option === CUSTOM ? this.plateCustomFrictionCoefficient : frictionCoefficient.plateFrictionCoefficient

    if (this.viewBinderJetParameters.frictionCoefficient.length > 0) {
      // Has plate value
      this.viewBinderJetParameters.frictionCoefficient[0] = currentValue
    } else {
      this.viewBinderJetParameters.frictionCoefficient.push(currentValue)
    }
    await this.saveBinderJet()
  }

  async selectedFrictionCoefficientSupportOption() {
    const option: string = this.viewBinderJetParameters.selectedFrictionCoefficientSupport
    if (!option) {
      return
    }
    const frictionCoefficient = this.defaultFrictionCoefficient.find((fc) => fc.surface === option)
    this.supportCustomActive = option === CUSTOM && this.viewBinderJetParameters.shrinkageActivation
    const currentValue =
      option === CUSTOM ? this.supportCustomFrictionCoefficient : frictionCoefficient.supportFrictionCoefficient

    if (this.viewBinderJetParameters.frictionCoefficient.length > 1) {
      // Has support value
      this.viewBinderJetParameters.frictionCoefficient[1] = currentValue
    } else {
      this.viewBinderJetParameters.frictionCoefficient.push(currentValue)
    }
    await this.saveBinderJet()
  }

  @Watch('isToolReadOnly')
  onIsToolReadOnlyChanged() {
    this.isSimulateReadOnly = this.isToolReadOnly
  }

  @Watch('currentBjMaterialModelOption')
  async onBinderJetMaterialModelOptionChange(value) {
    this.viewBinderJetParameters.materialModelOption = this.binderJetMaterialModelOptions[value].enumValue
    this.setBinderJetMaterialModelOption(this.viewBinderJetParameters.materialModelOption.toString())
    if (!this.waitingMode) {
      await this.saveBinderJet()
    }
  }

  @Watch('currentBjParallelMemoryOption')
  async onBinderJetParallelMemoryOptionChange(value) {
    this.viewBinderJetParameters.parallelMemoryOption = this.binderJetParallelMemoryOptions[value].enumValue
    this.setBinderJetParallelMemoryOption(this.viewBinderJetParameters.parallelMemoryOption.toString())
    if (!this.waitingMode) {
      await this.saveBinderJet()
    }
  }

  @Watch('currentBjSolverTypeOption')
  async onBinderJetSolverTypeOptionChange(value) {
    this.viewBinderJetParameters.solverType = this.binderJetSolverTypeOptions[value].enumValue
    this.setBinderJetSolverTypeOption(this.viewBinderJetParameters.solverType.toString())
    if (!this.waitingMode) {
      await this.saveBinderJet()
    }
  }

  @Watch('currentBjMeshContactOption')
  async onBinderJetMeshContactOptionChange(value) {
    this.viewBinderJetParameters.meshContact = this.binderJetMeshContactOptions[value].enumValue
    this.setBinderJetMeshContactOption(this.viewBinderJetParameters.meshContact.toString())
    if (!this.waitingMode) {
      await this.saveBinderJet()
    }
  }

  settingsPanelActiveOnOff() {
    this.settingsPanelActive = !this.settingsPanelActive
    if (!this.settingsPanelActive && !this.isDMLM && this.$refs.contactDampingFactor) {
      this.onContactDampingFactorRollBack()
    }
  }

  async onContactDampingFactorRollBack() {
    if (!this.$refs.contactDampingFactor.model || this.$refs.contactDampingFactor.model === '-') {
      this.$refs.contactDampingFactor.resetToZero()
    }
    if (this.viewBinderJetParameters.contactDampingFactor !== this.lastValidStabilizationFactor) {
      this.viewBinderJetParameters.contactDampingFactor = this.lastValidStabilizationFactor
      this.setRunSimulationAvailable()
      await this.saveBinderJet()
    }
  }

  @Watch('viewBinderJetParameters.contactDampingFactor')
  onContactDampingFactorChanged() {
    if (
      this.$refs.contactDampingFactor &&
      this.$refs.contactDampingFactor.model &&
      this.$refs.contactDampingFactor.model !== '-' &&
      this.viewBinderJetParameters.contactDampingFactor >=
        this.numericalTimeValidationRuleNotRequired.rules.min_range.min_value
    ) {
      this.lastValidStabilizationFactor = this.viewBinderJetParameters.contactDampingFactor
    }
  }

  settingsDmlmPanelActiveOnOff() {
    this.settingsDmlmPanelActive = !this.settingsDmlmPanelActive
  }

  settingsInherentStrainPanelActiveOnOff(item: any) {
    this.latestParameterSets.forEach((element) => {
      if (element !== item) {
        element.inherentStrainPanelActive = false
      }
    })
    item.inherentStrainPanelActive = !item.inherentStrainPanelActive
    this.settingsInherentStrainPanelActive = item.inherentStrainPanelActive
  }

  settingsLaserPanelActiveOnOff(item: any) {
    this.latestParameterSets.forEach((element) => {
      if (element !== item) {
        element.laserPanelActive = false
      }
    })
    item.laserPanelActive = !item.laserPanelActive
    this.settingsLaserPanelActive = item.laserPanelActive
  }

  getButtonToggleCondition(optionModel: string): boolean {
    return (
      optionModel === BinderJetMaterialModelOptions.ASYMMETRIC ||
      optionModel === BinderJetParallelMemoryOptions.SHARED ||
      optionModel === BinderJetSolverTypeOptions.DIRECT ||
      optionModel === BinderJetMeshContactOptions.FRICTIONAL ||
      optionModel === BinderJetAnalysisTypeOptions.GRAVITY
    )
  }

  getButtonToggleClass(value) {
    return this.getButtonToggleCondition(value.toLowerCase())
      ? 'button-toggle-inactive-right'
      : 'button-toggle-inactive-left'
  }

  getButtonToggleActiveClass(value) {
    return this.getButtonToggleCondition(value.toLowerCase())
      ? 'button-toggle-active-right'
      : 'button-toggle-active-left'
  }

  getBinderJetAnalysisTypeOptions() {
    const options = []
    for (const value in BinderJetAnalysisTypeOptions) {
      if (BinderJetAnalysisTypeOptions.hasOwnProperty(value)) {
        const i8nValue = this.$t(`${ANALYSIS_TYPE_OPTIONS}${BinderJetAnalysisTypeOptions[value]}`)
        options.push({ i8n: i8nValue, value: value as string, enumValue: value })
      }
    }
    return options
  }

  setBinderJetAnalysisTypeOption(bjAnalysisTypeOption: string) {
    this.bjAnalysisTypeMessage = this.getButtonToggleCondition(bjAnalysisTypeOption.toLowerCase())
      ? this.$t(`${ANALYSIS_TYPE_OPTIONS}gravityMessage`).toString()
      : this.$t(`${ANALYSIS_TYPE_OPTIONS}frictionMessage`).toString()
  }

  getBinderJetMaterialModelOptions() {
    const options = []
    for (const value in BinderJetMaterialModelOptions) {
      if (BinderJetMaterialModelOptions.hasOwnProperty(value)) {
        const i8nValue = this.$t(`${MATERIAL_MODEL_OPTIONS}${BinderJetMaterialModelOptions[value]}`)
        options.push({ i8n: i8nValue, value: value as string, enumValue: value })
      }
    }
    return options
  }

  setBinderJetMaterialModelOption(materialModelSelected: string) {
    this.materialModelMessage = this.getButtonToggleCondition(materialModelSelected.toLowerCase())
      ? this.$t(`${MATERIAL_MODEL_OPTIONS}asymmetricMessage`).toString()
      : this.$t(`${MATERIAL_MODEL_OPTIONS}symmetricMessage`).toString()
  }

  getBinderJetParallelMemoryOptions() {
    const options = []
    for (const value in BinderJetParallelMemoryOptions) {
      if (BinderJetParallelMemoryOptions.hasOwnProperty(value)) {
        const i8nValue = this.$t(`${PARALLEL_MEMORY_OPTIONS}${BinderJetParallelMemoryOptions[value]}`)
        options.push({ i8n: i8nValue, value: value as string, enumValue: value })
      }
    }
    return options
  }

  setBinderJetParallelMemoryOption(parallelMemorySelected: string) {
    this.parallelMemoryMessage = this.getButtonToggleCondition(parallelMemorySelected.toLowerCase())
      ? this.$t(`${PARALLEL_MEMORY_OPTIONS}sharedMessage`).toString()
      : this.$t(`${PARALLEL_MEMORY_OPTIONS}distributedMessage`).toString()
  }

  getBinderJetSolverTypeOptions() {
    const options = []
    for (const value in BinderJetSolverTypeOptions) {
      if (BinderJetSolverTypeOptions.hasOwnProperty(value)) {
        const i8nValue = this.$t(`${SOLVER_TYPE_OPTIONS}${BinderJetSolverTypeOptions[value]}`)
        options.push({ i8n: i8nValue, value: value as string, enumValue: value })
      }
    }
    return options
  }

  setBinderJetSolverTypeOption(solverTypeSelected: string) {
    this.solverTypeMessage = this.getButtonToggleCondition(solverTypeSelected.toLowerCase())
      ? this.$t(`${SOLVER_TYPE_OPTIONS}directMessage`).toString()
      : this.$t(`${SOLVER_TYPE_OPTIONS}iterativeMessage`).toString()
  }

  getBinderJetMeshContactOptions() {
    const options = []
    for (const value in BinderJetMeshContactOptions) {
      if (BinderJetMeshContactOptions.hasOwnProperty(value)) {
        let i8nValue = this.$t(`${MESH_CONTACT_OPTIONS}${BinderJetMeshContactOptions[value]}`)
        if (value === BinderJetMeshContactOptions.FRICTIONAL.toUpperCase()) {
          i8nValue = this.$t(`${MESH_CONTACT_OPTIONS}sliding`).toString()
        }
        options.push({ i8n: i8nValue, value: value as string, enumValue: value })
      }
    }
    return options
  }

  setBinderJetMeshContactOption(meshContactSelected: string) {
    this.meshContactMessage = this.getButtonToggleCondition(meshContactSelected.toLowerCase())
      ? this.$t(`${MESH_CONTACT_OPTIONS}frictionalMessage`).toString()
      : this.$t(`${MESH_CONTACT_OPTIONS}bondedMessage`).toString()
  }

  async clickOk() {
    this.isSimulateReadOnly = this.isToolReadOnly
    if (this.isDMLM) {
      await this.onDmlmRunSimulationClick()
    } else {
      await this.onBinderJetRunSimulationClick()
    }
  }

  @Watch('simuTabExpanded')
  closeSimCompPanels() {
    if (!this.simuTabExpanded) {
      if (this.isDMLM) {
        this.settingsDmlmPanelActive = false
        this.settingsInherentStrainPanelActive = false
        this.settingsLaserPanelActive = false
      } else {
        this.settingsPanelActive = false
      }
    }
  }

  @Watch('settingsPanelActive')
  settingsPanelActiveListener() {
    this.setListenerForClickOutside(true, null, this.customHandler)
  }

  @Watch('settingsDmlmPanelActive')
  settingDmlmsPanelActiveListener() {
    this.setListenerForClickOutside(true, null, this.customHandler)
    if (this.settingsDmlmPanelActive) {
      this.isOkDisabled = true
    } else {
      this.setRunSimulationAvailable()
      this.saveDmlmAdvancedParameters()
    }
  }

  @Watch('settingsInherentStrainPanelActive')
  settingsInherentStrainPanelActiveListener() {
    this.setListenerForClickOutside(true, null, this.customHandler)
    if (!this.settingsInherentStrainPanelActive) {
      this.latestParameterSets.forEach((item) => {
        item.inherentStrainPanelActive = false
      })
    }
  }

  @Watch('settingsLaserPanelActive')
  settingsLaserPanelActiveListener() {
    this.setListenerForClickOutside(true, null, this.customHandler)
    if (!this.settingsLaserPanelActive) {
      this.latestParameterSets.forEach((item) => {
        item.laserPanelActive = false
      })
    }
  }

  getInherentStrainValueNames(index: number) {
    switch (index) {
      case 0:
        return this.$t('dmlmAdvancedOptions.inherentStrainXX')
      case 1:
        return this.$t('dmlmAdvancedOptions.inherentStrainXY')
      case 2:
        return this.$t('dmlmAdvancedOptions.inherentStrainXZ')
      case 3:
        return this.$t('dmlmAdvancedOptions.inherentStrainYY')
      case 4:
        return this.$t('dmlmAdvancedOptions.inherentStrainYZ')
      case 5:
        return this.$t('dmlmAdvancedOptions.inherentStrainZZ')
      default:
        throw new Error(`Index ${index} is out of inherent strain parameters`)
    }
  }

  saveInherentStrainValues(item: any, index: number, value: any) {
    const alreadyExists = this.viewDmlmParameters.inherentStrainParameters.some(
      (inherentStrainParameter) => inherentStrainParameter.parameterSetId === item.id,
    )
    if (alreadyExists) {
      const indexSelected = this.viewDmlmParameters.inherentStrainParameters.findIndex(
        (inherentStrainParameter) => inherentStrainParameter.parameterSetId === item.id,
      )

      if (!this.viewDmlmParameters.inherentStrainParameters[indexSelected].inherentStrainsValues) {
        this.viewDmlmParameters.inherentStrainParameters[indexSelected].inherentStrainsValues = [
          null,
          null,
          null,
          null,
          null,
          null,
        ]
      }
      this.viewDmlmParameters.inherentStrainParameters[indexSelected].inherentStrainsValues[index] = value

      this.saveDmlmAdvancedParameters()
    } else {
      throw new Error(`Wrong part parameters ${item.name}`)
    }
  }

  saveLaserParameters(id: number, value: any, parameter: string) {
    const indexSelected = this.viewDmlmParameters.inherentStrainParameters.findIndex(
      (inherentStrainParameter) => inherentStrainParameter.parameterSetId === id,
    )
    if (parameter === this.$t('dmlmAdvancedOptions.laserPower')) {
      this.viewDmlmParameters.inherentStrainParameters[indexSelected].laserParameters.laserPower = value
    } else if (parameter === this.$t('dmlmAdvancedOptions.laserSpeed')) {
      this.viewDmlmParameters.inherentStrainParameters[indexSelected].laserParameters.laserSpeed = value
    } else if (parameter === this.$t('dmlmAdvancedOptions.laserBeamDiameter')) {
      this.viewDmlmParameters.inherentStrainParameters[indexSelected].laserParameters.laserBeamDiameter = value
    } else if (parameter === this.$t('dmlmAdvancedOptions.hatchSpace')) {
      this.viewDmlmParameters.inherentStrainParameters[indexSelected].laserParameters.hatchSpace = value
    } else {
      throw new Error(`Wrong laser parameter ${parameter}, does not exits in this context.`)
    }

    this.saveDmlmAdvancedParameters()
  }

  saveDmlmAdvancedParameters() {
    this.latestParameterSets.forEach((parameterSet) => {
      const inherentStrainParameterSelected = this.viewDmlmParameters.inherentStrainParameters.find(
        (inherentStrainParameter) => inherentStrainParameter.parameterSetId === parameterSet.id,
      )
      if (inherentStrainParameterSelected) {
        inherentStrainParameterSelected.inherentStrainsValues = parameterSet.userInherentStrain
          ? parameterSet.values
          : null

        inherentStrainParameterSelected.laserParameters = parameterSet.userLaserParameters
          ? parameterSet.laserParameters
          : null
      }
    })
    this.updateBuildPlanSimulationParams({
      simulationParams: this.viewDmlmParameters as IBaseSimulateCompensation,
    })
  }

  get partParametersTableHeaders() {
    return [
      {
        text: this.$t('dmlmAdvancedOptions.parameterSet'),
        value: 'name',
        sortable: false,
        width: '300px',
      },
      {
        text: this.$t('tenantAdminMessages.printStrategy.headers.layerThickness'),
        value: 'layerThickness',
        sortable: false,
        align: 'left',
        width: '100px',
      },
      {
        text: this.$t('dmlmAdvancedOptions.customizeInherentStrains'),
        value: 'allowInherentStrain',
        sortable: false,
        align: 'left',
        width: '150px',
      },
      {
        text: this.$t('dmlmAdvancedOptions.customizeLaserInformation'),
        value: 'allowLaserParameters',
        sortable: false,
        align: 'left',
        width: '150px',
      },
    ]
  }

  addLatestParameterSet(printStrategyParameterSetPk: VersionablePk, partName: string): number {
    const printStrategyParameterSet = this.getPrintStrategyParameterSetByPk(printStrategyParameterSetPk)
    if (!printStrategyParameterSet) {
      throw new Error(`Wrong print strategy parameter ${printStrategyParameterSetPk.id}, for ${partName}`)
    }
    if (printStrategyParameterSet.layerThickness <= 0) {
      throw new Error(`Wrong layer thickness value = ${printStrategyParameterSet.layerThickness}
        in parameterSet ${printStrategyParameterSet.name}`)
    }
    const isParameterSetRepeated = this.latestParameterSets.some(
      (parameterSet) => parameterSet.id === printStrategyParameterSet.id,
    )
    if (!isParameterSetRepeated) {
      const parameterSet = printStrategyParameterSet.parameterSet as IParameterSet
      const dmlmParameters = parameterSet.partParameters as IDmlmParameterSetContent
      const allowLaserInfo =
        dmlmParameters.laserPower >= 0 &&
        dmlmParameters.laserSpeed > 0 &&
        dmlmParameters.beamDiameter > 0 &&
        dmlmParameters.hatchDistance > 0

      const emptyLaserParameters: IDmlmLaserPrameters = {
        laserPower: null,
        laserSpeed: null,
        laserBeamDiameter: null,
        hatchSpace: null,
      }
      const selectedParameterSet = {
        id: printStrategyParameterSet.id,
        name: printStrategyParameterSet.name,
        layerThickness: roundNumberByDigits(
          printStrategyParameterSet.layerThickness * METER_TO_MM,
          this.precisionNumber,
        ),
        allowInherentStrain: printStrategyParameterSet.allowSimCom,
        userInherentStrain: !printStrategyParameterSet.allowSimCom,
        values: [null, null, null, null, null, null],
        inherentStrainPanelActive: false,
        allowLaserParameters: allowLaserInfo,
        userLaserParameters: !allowLaserInfo,
        laserParameters: emptyLaserParameters,
        laserPanelActive: false,
      }
      this.latestParameterSets.push(selectedParameterSet)
      return selectedParameterSet.id
    }
    return -1
  }

  cleanInherentStrainParameters(dmlmSimCompParams: IDmlmSimulateCompensation) {
    const lastStepIndex = dmlmSimCompParams.inherentStrainParameters.length - 1
    for (let i = 0; i < lastStepIndex; i = i + 1) {
      const isParameterSetRepeated = this.latestParameterSets.some(
        (parameterSet) =>
          dmlmSimCompParams.inherentStrainParameters[i] &&
          parameterSet.id === dmlmSimCompParams.inherentStrainParameters[i].parameterSetId,
      )
      if (!isParameterSetRepeated) {
        dmlmSimCompParams.inherentStrainParameters.splice(i, 1)
      }
    }
  }

  updateLatestParameterSets(dmlmSimCompParams: IDmlmSimulateCompensation, lastParameterSetId: number) {
    if (lastParameterSetId > -1) {
      // Update view parameter set
      const viewParameterSet = this.latestParameterSets.find((parameterSet) => parameterSet.id === lastParameterSetId)
      const isLastParameterSetIn = dmlmSimCompParams.inherentStrainParameters.some(
        (inherentStrainParameter) => inherentStrainParameter.parameterSetId === lastParameterSetId,
      )

      if (isLastParameterSetIn) {
        const inherentStrainParameters = dmlmSimCompParams.inherentStrainParameters.find(
          (parameterSet) => parameterSet.parameterSetId === lastParameterSetId,
        )
        if (inherentStrainParameters.inherentStrainsValues) {
          viewParameterSet.values = []
          const lastInherentStrainIndex = inherentStrainParameters.inherentStrainsValues.length
          for (let i = 0; i < lastInherentStrainIndex; i = i + 1) {
            viewParameterSet.values[i] = inherentStrainParameters.inherentStrainsValues[i]
          }
        }
        if (inherentStrainParameters.laserParameters) {
          viewParameterSet.laserParameters = inherentStrainParameters.laserParameters
        }
        viewParameterSet.userInherentStrain = viewParameterSet.allowInherentStrain
          ? inherentStrainParameters.inherentStrainsValues !== null
          : true
        viewParameterSet.userLaserParameters = inherentStrainParameters.laserParameters
      } else {
        // Add a new ParameterSet
        const selectedinherentStrainParameters: IDmlmInherentStrainParameters = {
          parameterSetId: viewParameterSet.id,
          inherentStrainsValues: null,
          laserParameters: null,
        }
        dmlmSimCompParams.inherentStrainParameters.push(selectedinherentStrainParameters)
      }
    }
  }

  assignLatestParameterSets(dmlmSimCompParams: IDmlmSimulateCompensation) {
    if (!dmlmSimCompParams.inherentStrainParameters) {
      dmlmSimCompParams.inherentStrainParameters = []
    }
    this.buildPlan.buildPlanItems.forEach((buildPlanItem) => {
      let lastParameterSetId: number = -1
      // Parts
      if (buildPlanItem.partProperties) {
        buildPlanItem.partProperties.forEach((partProperty) => {
          const printStrategyParameterSetPk = partProperty.printStrategyParameterSetId
            ? new VersionablePk(partProperty.printStrategyParameterSetId, partProperty.printStrategyParameterSetVersion)
            : getDefaultBaseOnType(this.getBuildPlanPrintStrategy.defaults, partProperty.type, partProperty.bodyType)
          lastParameterSetId = this.addLatestParameterSet(printStrategyParameterSetPk, buildPlanItem.part.name)
          this.updateLatestParameterSets(dmlmSimCompParams, lastParameterSetId)
        })
      }
      // Support
      if (buildPlanItem.supports) {
        buildPlanItem.supports.forEach((support) => {
          const printStrategyParameterSetPk = support.settings.printStrategyParameterSetId
            ? new VersionablePk(
                support.settings.printStrategyParameterSetId,
                support.settings.printStrategyParameterSetVersion,
              )
            : getDefaultBaseOnType(this.getBuildPlanPrintStrategy.defaults, GeometryType.Support, null)
          lastParameterSetId = this.addLatestParameterSet(printStrategyParameterSetPk, buildPlanItem.part.name)
          this.updateLatestParameterSets(dmlmSimCompParams, lastParameterSetId)
        })
      }
    })
    // Delete updated parameter set
    this.cleanInherentStrainParameters(dmlmSimCompParams)
  }

  getAllowInherentStrainsValuesMessage(item: any) {
    if (!item.allowInherentStrain) {
      return this.$t('dmlmAdvancedOptions.noDefaultParametersMessage', [
        this.$t('dmlmAdvancedOptions.inherentStrains'),
      ]).toString()
    }
    return this.$t('dmlmAdvancedOptions.allowUserValesMessage').toString()
  }

  getAllowLaserParametersMessage(item: any) {
    if (!item.allowLaserParameters) {
      return this.$t('dmlmAdvancedOptions.noDefaultParametersMessage', [
        this.$t('dmlmAdvancedOptions.laserInformation'),
      ]).toString()
    }
    return this.$t('dmlmAdvancedOptions.allowUserValesMessage').toString()
  }

  getEditInherentStrainsValuesMessage(item: any) {
    if (!item.userInherentStrain) {
      return this.$t('dmlmAdvancedOptions.editInherentStrainDisableMessage').toString()
    }
    return this.$t('dmlmAdvancedOptions.editInherentStrainAvailableMessage').toString()
  }

  getEditLaserParametersMessage(item: any) {
    if (!item.userLaserParameters) {
      return this.$t('dmlmAdvancedOptions.editLaserInformationDisableMessage').toString()
    }
    return this.$t('dmlmAdvancedOptions.editLaserInformationAvailableMessage').toString()
  }

  getDmlmAdvanceValidation(): boolean {
    let validation: boolean = true
    this.latestParameterSets.forEach((parameterSet) => {
      validation =
        validation && this.getInherentStrainValidation(parameterSet) && this.getLaserParametersValidation(parameterSet)
    })
    return validation
  }

  getInherentStrainValidation(item: any): boolean {
    if (!item.userInherentStrain) {
      return true
    }
    let validation: boolean = true
    item.values.forEach((value) => {
      validation =
        validation &&
        value !== null &&
        isValidationsRuleSatisfied(value, this.numericalFromMinusOneToOneValidationRule.rules.range)
    })
    return validation
  }

  getLaserParametersValidation(item: any): boolean {
    if (!item.userLaserParameters) {
      return true
    }
    let validation =
      item.laserParameters.laserPower !== null &&
      isValidationsRuleSatisfied(item.laserParameters.laserPower, this.powerValidationRule.rules.min_range)
    if (validation) {
      validation =
        validation &&
        item.laserParameters.laserSpeed !== null &&
        isValidationsRuleSatisfied(item.laserParameters.laserSpeed, this.speedValidationRule.rules.min_range)
    }
    if (validation) {
      validation =
        validation &&
        item.laserParameters.laserBeamDiameter !== null &&
        isValidationsRuleSatisfied(
          item.laserParameters.laserBeamDiameter,
          this.microLengthValidationRule.rules.min_range,
        )
    }
    if (validation) {
      validation =
        validation &&
        item.laserParameters.hatchSpace !== null &&
        isValidationsRuleSatisfied(item.laserParameters.hatchSpace, this.meshSizeValidationRule.rules.min_range)
    }
    return validation
  }

  async assignDefaultFurnanceTemperatureValues(dmlmSimCompParams: IDmlmSimulateCompensation) {
    const dataBaseTemperatureProfile = await buildPlans.getHtFurnanceProfile(this.material.id, this.material.version)
    const lastIndex = dataBaseTemperatureProfile.length - 1
    for (let i = 0; i <= lastIndex; i += 1) {
      const timeTemp: TemperatureProfileType = {
        index: i,
        timeItem: Math.ceil(dataBaseTemperatureProfile[i][0] / 60), // From seconds to minutes
        temperatureItem: roundNumberByDigits(dataBaseTemperatureProfile[i][1] + KELVIN_TO_CELSIUS, 1),
      }
      this.defaultTemperatureProfile.push(timeTemp)
    }
    if (dmlmSimCompParams.processSteps && dmlmSimCompParams.processSteps.length > 0) {
      this.showStoredProcessSteps(dmlmSimCompParams)
    }
  }

  customHandler(event: PointerEvent) {
    this.dialogOutsideClickHandler(
      event,
      'build-plan-compensation-tab',
      'advanced',
      this.settingsPanelActiveOnOff,
      this.closeSimCompPanels,
    )
  }

  async onDmlmRunSimulationClick() {
    const hideAPIErrorMessages = true
    await this.saveDmlm(hideAPIErrorMessages)
    // Compensation
    if (!this.viewDmlmParameters.runCompensation) {
      this.viewDmlmParameters.maxIterations = 0.0
    }

    // Heat treatments
    this.convertSimulationProcessList(true)
    this.viewDmlmParameters.creepDataValidation =
      this.creepDataValidated ||
      !this.viewDmlmParameters.processSteps.some((step) => step.processType === StepProcessType.StressRelief)
    this.$emit('creatingJob')
    try {
      this.isOkDisabled = true
      this.currentSimCompJob = await buildPlans.createDmlmSimulationCompensation(
        this.buildPlan.id,
        this.viewDmlmParameters,
        hideAPIErrorMessages,
      )

      this.currentSimCompJob.code = this.currentSimCompJob.status.code
      await this.triggerStatusCheck(hideAPIErrorMessages)
    } catch (error) {
      this.$emit('failedJob')

      // This error will be caught in the parent component (BuildPlanSidebar)
      throw error
    } finally {
      // Compensation
      this.viewDmlmParameters.maxIterations = this.viewBaseParameters.maxIterations

      // Heat treatments
      await this.saveDmlmProcesStep(hideAPIErrorMessages)
    }
    this.$emit('jobCreated')

    this.isSimulationJobCreated = true
    this.updateInfoStatus()
  }

  async onBinderJetRunSimulationClick() {
    const hideAPIErrorMessages = false
    await this.saveBinderJet(hideAPIErrorMessages)
    // Mesh Size
    const meshSizeStored = this.viewBinderJetParameters.meshSize
    if (this.viewBinderJetParameters.autoMeshSizeActive) {
      this.viewBinderJetParameters.meshSize = null
    }
    // Friction Coefficient
    const frictionCoefficientStored = []
    if (!this.viewBinderJetParameters.shrinkageActivation) {
      this.viewBinderJetParameters.frictionCoefficient.forEach((fc) => frictionCoefficientStored.push(fc))
      const frictionCoefficientLength = this.viewBinderJetParameters.frictionCoefficient.length
      this.viewBinderJetParameters.frictionCoefficient.splice(0, frictionCoefficientLength)
    }

    // Compensation
    if (!this.viewBinderJetParameters.runCompensation) {
      this.viewBinderJetParameters.maxIterations = 0.0
    }
    this.$emit('creatingJob')
    try {
      this.isOkDisabled = true
      this.currentSimCompJob = await buildPlans.createBinderJetSimulationCompensation(
        this.buildPlan.id,
        this.viewBinderJetParameters,
        hideAPIErrorMessages,
      )
      this.currentSimCompJob.code = this.currentSimCompJob.status.code

      this.viewBinderJetParameters.maxIterations = this.viewBaseParameters.maxIterations
      if (!this.viewBinderJetParameters.meshSize) {
        this.viewBinderJetParameters.meshSize = meshSizeStored
      }
      // Friction Coefficient
      if (this.viewBinderJetParameters.frictionCoefficient.length === 0) {
        frictionCoefficientStored.forEach((fc) => this.viewBinderJetParameters.frictionCoefficient.push(fc))
      }

      await this.triggerStatusCheck(hideAPIErrorMessages)
    } catch (error) {
      this.$emit('failedJob')
      // This error will be caught in the parent component (BuildPlanSidebar)
      throw error
    }
    this.$emit('jobCreated')
  }

  async triggerStatusCheck(hideAPIErrorMessages = false) {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }

    this.intervalId = setInterval(async () => {
      if (isTabVisible()) {
        const jobs = await buildPlans.getJobs(this.buildPlan.id, hideAPIErrorMessages)
        this.currentSimCompJob = jobs.find((item) => item.number === this.currentSimCompJob.number)
        if (
          this.currentSimCompJob &&
          (FINISHED_JOBS.includes(this.currentSimCompJob.code) || RUNNING_JOBS.includes(this.currentSimCompJob.code))
        ) {
          clearInterval(this.intervalId)
          this.setRunSimulationAvailable()
        }
      }
    }, 3000)
  }

  async saveCompensation() {
    const stored = this.isDMLM ? this.viewDmlmParameters : this.viewBinderJetParameters
    const view = this.viewBaseParameters
    if (stored.runCompensation !== view.runCompensation) {
      stored.runCompensation = view.runCompensation
    }

    if (stored.maxIterations !== view.maxIterations) {
      stored.maxIterations = view.maxIterations
    }

    if (stored.convergenceValue !== view.convergenceValue) {
      stored.convergenceValue = view.convergenceValue
    }

    // prevent update requests in invalid state
    if (this.isOkDisabled) {
      return
    }

    await this.updateBuildPlanSimulationParams({ simulationParams: stored as IBaseSimulateCompensation })
  }

  convertSimulationProcessList(convertMode: boolean = false) {
    // Cleaning no build process stpes
    const lastStepIndex = this.viewDmlmParameters.processSteps.length - 1
    for (let i = lastStepIndex; i > 1; i = i - 1) {
      this.viewDmlmParameters.processSteps.splice(i)
    }
    // Adding no build process stpes
    const lastIndex = this.simulationProcessList.length - 1
    for (let i = 0; i <= lastIndex; i = i + 1) {
      switch (this.simulationProcessList[i].simulationProcess) {
        case SimProcessType.MaterialRemovalBP:
          {
            const step = {
              processType: StepProcessType.MaterialRemoval,
              index: this.simulationProcessList[i].index + STEP_INDEX_DIFERENCE,
              itemsToRemove: [BUILD_PLATE],
            }
            this.viewDmlmParameters.processSteps.push(step)
          }
          break

        case SimProcessType.MaterialRemovalSupport:
          {
            const step = {
              processType: StepProcessType.MaterialRemoval,
              index: this.simulationProcessList[i].index + STEP_INDEX_DIFERENCE,
              itemsToRemove: this.supportFileNames,
            }
            this.viewDmlmParameters.processSteps.push(step)
          }
          break

        case SimProcessType.StressRelief:
          {
            const step = {
              processType: StepProcessType.StressRelief,
              index: this.simulationProcessList[i].index + STEP_INDEX_DIFERENCE,
              userDefined: this.simulationProcessList[i].userDefined,
              ovenTemperatureProfile: convertTemperatureProfileToArray(
                this.simulationProcessList[i].temperatureProfile,
                convertMode,
              ),
            }
            this.viewDmlmParameters.processSteps.push(step)
          }
          break
      }
    }
  }

  calculatePartHeights() {
    if (this.storedPartHeights.length === 0) {
      this.storedPartHeights = this.getPartHeights(this.buildPlan.buildPlanItems)
    }
    this.storedPartHeights.forEach((ph) => {
      const totalMacroLayers = Math.ceil(Math.abs(ph.height) / this.macroLayerThickness)
      if (totalMacroLayers < WARNING_MAX_MACRO_LAYER) {
        this.lowNumberMacroLayersParts.push(ph)
      }
    })
  }

  updateMacroLayerInsight(insight: IBuildPlanInsight) {
    if (insight) {
      if (this.lowNumberMacroLayersParts.length > 0) {
        this.updateInsights([insight])
      } else {
        this.removeInsights([insight.id])
      }
    }
  }

  isMacroLayerWarningCondition(): boolean {
    this.lowNumberMacroLayersParts = []
    if (this.isToolReadOnly) {
      this.currentInsightCode = InsightErrorCodes.SimCompToolMacroLayersWarn
      const activeInsights = this.getActiveInsights()
      const macroLayerInsight = activeInsights.find((insight) => insight.itemId === this.buildPlan.id)

      if (macroLayerInsight) {
        this.calculatePartHeights()
        if (
          this.lowNumberMacroLayersParts.length !== macroLayerInsight.details.parts.length ||
          !macroLayerInsight.details.XX
        ) {
          macroLayerInsight.details = {
            XX: this.lowNumberMacroLayersParts.length,
            parts: this.lowNumberMacroLayersParts,
          }
          this.updateMacroLayerInsight(macroLayerInsight)
        } else {
          this.lowNumberMacroLayersParts = macroLayerInsight.details.parts
        }
      }
    } else {
      this.calculatePartHeights()
    }
    return this.lowNumberMacroLayersParts.length > 0
  }

  updateInfoStatus() {
    if (this.numberMacroLayers <= 1) {
      this.infoStatus = InfoStatusCode.ERROR
    } else if (this.isMacroLayerWarningCondition()) {
      this.infoStatus = InfoStatusCode.WARNING
      if (this.isSimulationJobCreated) {
        this.executeInsight(InsightErrorCodes.SimCompToolMacroLayersWarn)
      }
    } else {
      this.infoStatus = InfoStatusCode.INFO
      if (this.isSimulationJobCreated) {
        const macroLayersWarnInsights = this.insights.filter(
          (insight) => insight.errorCode === InsightErrorCodes.SimCompToolMacroLayersWarn,
        )
        this.deleteInsights(macroLayersWarnInsights)
      }
    }
  }

  updateMinWallInformation() {
    const wallThickness = this.viewDmlmParameters.minWallThicknessInMm
    const layersInfo = calculateMacroLayers(
      wallThickness,
      this.currentSimuSpeed,
      this.layerThickness,
      this.boundingBox.maxZ,
    )
    this.macroLayerThickness = layersInfo.macroLayerThickness
    this.numberMacroLayers = layersInfo.totalMacroLayers

    this.updateInfoStatus()
    this.updateMinWallThicknessValidationRule()
    const mm = this.getLengthUnit()
    const statusColor = this.getMinWallIconColor()
    const alertCircle = `<span style="background-color:${statusColor};
        color:#ffffff; border-radius:50%;
        white-space: pre">  !  </span> `

    this.minWallInformation = `${this.$t('macroLayers.explanation')}\n
        ${this.$t('macroLayers.minElementSize')}
        <b>${layersInfo.minElementSize.toFixed(3)} ${mm}</b>\n
        ${this.$t('macroLayers.macroLayerThickness')}
        <b>${layersInfo.macroLayerThickness.toFixed(3)} ${mm}</b>\n
        ${this.$t('macroLayers.totalMacroLayers')}\n`

    switch (this.infoStatus) {
      case InfoStatusCode.INFO:
        {
          this.minWallInformation += `<b>${layersInfo.totalMacroLayers}</b>\n`
        }
        break
      case InfoStatusCode.WARNING:
        {
          let warningColor = statusColor
          if (this.numberMacroLayers > 5) {
            warningColor = BLACK_COLOR
          }
          this.minWallInformation += `<b style="color:${warningColor}";>${layersInfo.totalMacroLayers}</b>\n
          ${alertCircle} <i>${this.$t('macroLayers.macroLayersGreaterThanSix')}</i>\n
          <i>${this.$t('macroLayers.advisement')}</i>`
        }
        break
      case InfoStatusCode.ERROR:
        {
          this.minWallInformation += `<b style="color:${statusColor}";>${layersInfo.totalMacroLayers}</b>\n
          ${alertCircle} <i>${this.$t('macroLayers.macroLayersGreaterThanOne')}</i>\n
          <i>${this.$t('macroLayers.advisement')}</i>`
        }
        break
    }
    this.setRunSimulationAvailable()
  }

  async saveDmlmProcesStep(hideAPIErrorMessages: boolean = false) {
    this.convertSimulationProcessList()
    await this.updateBuildPlanSimulationParams({
      hideAPIErrorMessages,
      simulationParams: this.viewDmlmParameters as IBaseSimulateCompensation,
    })
  }

  async saveDmlm(hideAPIErrorMessages = false) {
    // prevent update requests in read only state
    if (this.isSimulateReadOnly) {
      return
    }

    // Analysis Speed
    if (this.viewDmlmParameters.speedVsAccuracy !== this.currentSimuSpeed) {
      this.viewDmlmParameters.speedVsAccuracy = this.currentSimuSpeed
    }

    this.setRunSimulationAvailable()
    this.updateMinWallInformation()

    // also prevent update requests in invalid state
    if (this.isToolReadOnly) {
      return
    }
    await this.updateBuildPlanSimulationParams({
      hideAPIErrorMessages,
      simulationParams: this.viewDmlmParameters as IBaseSimulateCompensation,
    })
  }

  async scaleFactorChange() {
    for (let i = 0; i < 3; i += 1) {
      this.viewScalingFactor[i] = this.scaleFactorActive ? this.defaultScalingFactor[i] : this.userScalingFactor[i]
    }
    this.executeInsight(InsightErrorCodes.SimCompToolCustomScaleFactor)
    if (!this.waitingMode) {
      await this.saveBinderJet()
    }
  }

  // Insights
  async deleteInsights(insights: IBuildPlanInsight[]) {
    if (insights.length > 0) {
      const insightIds = insights.map((ins) => ins.id)
      this.removeInsights(insightIds)
    }
  }

  addDetailsToScaleFactorInsight(insight: IBuildPlanInsight) {
    insight.details = {
      customScalingFactor: [this.viewScalingFactor[0], this.viewScalingFactor[1], this.viewScalingFactor[2]],
      defaultScalingFactor: [this.defaultScalingFactor[0], this.defaultScalingFactor[1], this.defaultScalingFactor[2]],
    }
  }

  addScaleFactorInsight() {
    const insight = {
      itemId: this.buildPlan.id,
      accepted: false,
      severity: InsightsSeverity.Warning,
      tool: ToolNames.SIMULATE,
      errorCode: this.currentInsightCode,
    } as IBuildPlanInsight
    this.addDetailsToScaleFactorInsight(insight)

    this.insights.push(insight)
  }

  updateScaleFactorInsight(insight: IBuildPlanInsight) {
    if (insight) {
      this.addDetailsToScaleFactorInsight(insight)
      this.updateInsights([insight])
    }
  }

  getActiveInsights(): IBuildPlanInsight[] {
    if (!this.currentInsightCode) {
      return null
    }
    return this.insights.filter((ig) => ig.errorCode === this.currentInsightCode)
  }

  @Watch('viewScalingFactor')
  scaleFactorValuesChanged() {
    this.executeInsight(InsightErrorCodes.SimCompToolCustomScaleFactor)
  }

  async executeInsight(insightCode: InsightErrorCodes) {
    this.currentInsightCode = insightCode
    const activeInsights = this.getActiveInsights()
    if (!activeInsights) return

    switch (this.currentInsightCode) {
      case InsightErrorCodes.SimCompToolCustomScaleFactor:
        {
          let areDefaultValues: boolean = true
          for (let i = 0; i < 3; i += 1) {
            if (this.viewScalingFactor[i] !== this.defaultScalingFactor[i]) {
              areDefaultValues = false
              break
            }
          }

          if (areDefaultValues) {
            // Delete insights
            await this.deleteInsights(activeInsights)
          } else if (activeInsights.length === 1) {
            // Update insight
            this.updateScaleFactorInsight(activeInsights[0])
          } else {
            // New insight
            if (activeInsights.length > 1) {
              await this.deleteInsights(activeInsights)
            }
            this.addScaleFactorInsight()
          }
        }
        break
      case InsightErrorCodes.SimCompToolMacroLayersWarn:
        {
          if (activeInsights.length === 0) {
            // Create insight
            const insight = {
              itemId: this.buildPlan.id,
              accepted: false,
              severity: InsightsSeverity.Warning,
              tool: ToolNames.SIMULATE,
              errorCode: this.currentInsightCode,
            } as IBuildPlanInsight

            insight.details = {
              XX: this.lowNumberMacroLayersParts.length,
              parts: this.lowNumberMacroLayersParts,
            }
            this.reportInsightIssues([{ insights: [insight], tool: ToolNames.SIMULATE }])
          }
        }
        break
    }
  }

  async saveBinderJet(hideAPIErrorMessages = false) {
    // prevent update requests in read only state
    if (this.isSimulateReadOnly) {
      return
    }
    // Analysis Speed
    if (this.viewBinderJetParameters.speedVsAccuracy !== this.currentSimuSpeed) {
      this.viewBinderJetParameters.speedVsAccuracy = this.currentSimuSpeed
    }
    // Scaling Factor
    for (let i = 0; i < 3; i += 1) {
      if (this.viewBinderJetParameters.binderjetScalingFactor[i] !== this.viewScalingFactor[i]) {
        this.viewBinderJetParameters.binderjetScalingFactor[i] = this.viewScalingFactor[i]
      }
      // User defined
      if (!this.scaleFactorActive) {
        this.userScalingFactor[i] = this.viewScalingFactor[i]
      }
    }
    // stored as boolean instead of by name
    const checkShrinkageActivation =
      this.binderJetAnalysisTypeOptions[this.currentBjAnalysisTypeOption].enumValue ===
      BinderJetAnalysisTypeOptions.FRICTION.toUpperCase()
    if (this.viewBinderJetParameters.shrinkageActivation !== checkShrinkageActivation) {
      this.viewBinderJetParameters.shrinkageActivation = checkShrinkageActivation
    }
    if (
      this.viewBinderJetParameters.materialModelOption !==
      this.binderJetMaterialModelOptions[this.currentBjMaterialModelOption].enumValue
    ) {
      this.viewBinderJetParameters.materialModelOption =
        this.binderJetMaterialModelOptions[this.currentBjMaterialModelOption].enumValue
    }
    if (
      this.viewBinderJetParameters.parallelMemoryOption !==
      this.binderJetParallelMemoryOptions[this.currentBjParallelMemoryOption].enumValue
    ) {
      this.viewBinderJetParameters.parallelMemoryOption =
        this.binderJetParallelMemoryOptions[this.currentBjParallelMemoryOption].enumValue
    }
    if (
      this.viewBinderJetParameters.solverType !==
      this.binderJetSolverTypeOptions[this.currentBjSolverTypeOption].enumValue
    ) {
      this.viewBinderJetParameters.solverType =
        this.binderJetSolverTypeOptions[this.currentBjSolverTypeOption].enumValue
    }

    if (
      this.viewBinderJetParameters.meshContact !==
      this.binderJetMeshContactOptions[this.currentBjMeshContactOption].enumValue
    ) {
      this.viewBinderJetParameters.meshContact =
        this.binderJetMeshContactOptions[this.currentBjMeshContactOption].enumValue
    }

    if (!this.waitingMode) {
      this.setRunSimulationAvailable()
    }
    // also prevent update requests in invalid state
    if (this.isOkDisabled) {
      return
    }
    if (this.saveParametersActive) {
      const payloadSimParams = this.viewBinderJetParameters as IBaseSimulateCompensation
      const lowerCaseOptions = ['materialModelOption', 'parallelMemoryOption', 'solverType', 'meshContact']
      lowerCaseOptions.forEach((key) => {
        if (payloadSimParams.hasOwnProperty(key)) {
          if (payloadSimParams[key]) {
            payloadSimParams[key] = payloadSimParams[key].toLowerCase() as string
          }
        }
      })

      await this.updateBuildPlanSimulationParams({
        hideAPIErrorMessages,
        simulationParams: payloadSimParams,
      })
    }
  }

  showStoredProcessSteps(dmlmSimCompParams: IDmlmSimulateCompensation) {
    let sortIndex: number = 0
    for (let i = 2; i < dmlmSimCompParams.processSteps.length; i += 1) {
      let stepProces: SimProcessType
      let tProfile: TemperatureProfileType[] = []
      let userTProfile: TemperatureProfileType[]
      const stressReliefOptions: string[] = []
      const custom = dmlmSimCompParams.processSteps[i].userDefined

      switch (dmlmSimCompParams.processSteps[i].processType) {
        case StepProcessType.MaterialRemoval:
          {
            if (
              dmlmSimCompParams.processSteps[i].itemsToRemove.length === 1 &&
              dmlmSimCompParams.processSteps[i].itemsToRemove[0] === BUILD_PLATE
            ) {
              stepProces = SimProcessType.MaterialRemovalBP
            } else if (this.noSupports) {
              sortIndex = -1
              break
            } else {
              stepProces = SimProcessType.MaterialRemovalSupport
            }
            const removeIndex = this.simulationProcessAvailable.indexOf(stepProces)
            this.simulationProcessAvailable.splice(removeIndex, 1)
          }
          break

        case StepProcessType.StressRelief:
          {
            stepProces = SimProcessType.StressRelief
            userTProfile = []
            tProfile = convertArrayToTemperatureProfile(dmlmSimCompParams.processSteps[i].ovenTemperatureProfile)
            stressReliefOptions.push(this.material.name)
            this.currentHtOption = this.material.name
            if (custom) {
              copyTemperatureProfile(userTProfile, tProfile)
              stressReliefOptions.push(this.customString)
              this.currentHtOption = this.customString
            } else {
              copyTemperatureProfile(tProfile, this.defaultTemperatureProfile)
            }
          }
          break
        default:
          throw new Error(`Unknown step process type ${dmlmSimCompParams.processSteps[i].processType}`)
      }

      if (stepProces) {
        const selectedSimuProcess = {
          index: dmlmSimCompParams.processSteps[i].index - STEP_INDEX_DIFERENCE + sortIndex,
          simulationProcess: stepProces,
          userDefined: custom,
          temperatureProfile: tProfile,
          customTemperatureProfile: userTProfile,
          htListOfOptions: stressReliefOptions,
        }
        this.simulationProcessList.push(selectedSimuProcess)
      }
    }
    if (this.simulationProcessList.length > 0) {
      this.selectProcessStep(this.simulationProcessList[0])
    }
  }

  showStoredParameters(simCompParams: IBaseSimulateCompensation) {
    // Compensation
    this.viewBaseParameters.runCompensation = simCompParams.runCompensation
    this.compensationBlocked = !this.viewBaseParameters.runCompensation
    this.viewBaseParameters.maxIterations = simCompParams.maxIterations

    if (simCompParams.convergenceValue === null) {
      this.viewBaseParameters.convergenceValue = 0.1
    } else {
      this.viewBaseParameters.convergenceValue = simCompParams.convergenceValue
    }

    let analysisSpeed: SimSpeed = null
    let bjMaterialModelOption: BinderJetMaterialModelOptions = null
    let bjParallelMemoryOption: BinderJetParallelMemoryOptions = null
    let bjSolverTypeOption: BinderJetSolverTypeOptions = null
    let bjMeshContactOption: BinderJetMeshContactOptions = null
    // DMLM
    if (simCompParams.computeType === PrintingTypes.DMLM.toLowerCase()) {
      const viewParams = simCompParams as IDmlmSimulateCompensation
      analysisSpeed = viewParams.speedVsAccuracy
    } else {
      // BinderJet
      const viewParams = simCompParams as IBinderJetSimulateCompensation

      analysisSpeed = viewParams.speedVsAccuracy
      bjMaterialModelOption = viewParams.materialModelOption
      bjParallelMemoryOption = viewParams.parallelMemoryOption
      bjSolverTypeOption = viewParams.solverType
      bjMeshContactOption = viewParams.meshContact

      if (bjMaterialModelOption) {
        this.updateCurrentBinderJetMaterialModelOption(bjMaterialModelOption)
      }
      if (bjParallelMemoryOption) {
        this.updateCurrentBinderJetParallelMemoryOption(bjParallelMemoryOption)
      }
      if (bjSolverTypeOption) {
        this.updateCurrentBinderJetSolverTypeOption(bjSolverTypeOption)
      }
      if (bjMeshContactOption) {
        this.updateCurrentBinderJetMeshContactOption(bjMeshContactOption)
      }

      const userEmpty = this.userScalingFactor.length === 0
      this.scaleFactorActive = true
      for (let i = 0; i < 3; i += 1) {
        // Stored parameters
        if (
          this.viewBinderJetParameters.binderjetScalingFactor.length === 3 &&
          this.viewBinderJetParameters.binderjetScalingFactor[i] !== undefined
        ) {
          this.viewScalingFactor[i] = viewParams.binderjetScalingFactor[i]
        } else {
          // Default parameters
          this.viewScalingFactor[i] = this.defaultScalingFactor[i]
          this.viewBinderJetParameters.binderjetScalingFactor[i] = this.defaultScalingFactor[i]
        }

        if (userEmpty) {
          this.userScalingFactor[i] = this.viewScalingFactor[i]
        }
        if (this.viewScalingFactor[i] !== this.defaultScalingFactor[i]) {
          // It's user selected
          this.scaleFactorActive = false
        }
      }
      this.scaleFactorValuesChanged()
      this.showsFlatSurfacesCollector()
    }

    // Analysis speed
    this.currentSimuSpeed = analysisSpeed
    switch (analysisSpeed) {
      case SimSpeed.FAST:
        {
          this.currentSlicer = 1
          this.currentUISimuSpeed = SimSpeedUI.LOW
        }
        break
      case SimSpeed.STANDARD:
        {
          this.currentSlicer = 2
          this.currentUISimuSpeed = SimSpeedUI.STANDARD
        }
        break
      case SimSpeed.ACCURATE:
        {
          this.currentSlicer = 3
          this.currentUISimuSpeed = SimSpeedUI.HIGH
        }
        break
    }
  }

  updateCurrentBinderJetAnalysisTypeOption(o: BinderJetAnalysisTypeOptions) {
    this.currentBjAnalysisTypeOption = this.getCurrentIndex(o, this.binderJetAnalysisTypeOptions)
  }

  updateCurrentBinderJetMaterialModelOption(o: BinderJetMaterialModelOptions) {
    this.currentBjMaterialModelOption = this.getCurrentIndex(o, this.binderJetMaterialModelOptions)
  }

  updateCurrentBinderJetParallelMemoryOption(o: BinderJetParallelMemoryOptions) {
    this.currentBjParallelMemoryOption = this.getCurrentIndex(o, this.binderJetParallelMemoryOptions)
  }

  updateCurrentBinderJetSolverTypeOption(o: BinderJetSolverTypeOptions) {
    this.currentBjSolverTypeOption = this.getCurrentIndex(o, this.binderJetSolverTypeOptions)
  }

  updateCurrentBinderJetMeshContactOption(o: BinderJetMeshContactOptions) {
    this.currentBjMeshContactOption = this.getCurrentIndex(o, this.binderJetMeshContactOptions)
  }

  getCurrentIndex(o, list) {
    for (let index = 0; index < list.length; index += 1) {
      if (list[index].value === o.toUpperCase()) {
        return index
      }
    }
    return -1
  }

  // No supports calculate
  bpItemHasSupports(bpItem): boolean {
    const validSupports =
      bpItem.supports && bpItem.supports.filter((support) => support.settings.strategy !== SupportTypes.NoSupports)
    return (
      (validSupports && validSupports.length) || bpItem.partProperties.some((pp) => pp.type === GeometryType.Support)
    )
  }

  extendValidationRules() {
    extend('range', {
      validate: (
        value: number,
        { min_value, max_value, unit }: { min_value: number; max_value: number; unit: string },
      ) => {
        return (
          (value >= min_value && value < max_value) ||
          equalWithTolerance(value, min_value, Number.EPSILON) ||
          equalWithTolerance(value, max_value, Number.EPSILON)
        )
      },
      params: ['min_value', 'max_value', 'unit'],
      message: this.$i18n.t('rangeValidationMessage') as string,
    })

    extend('min_range', {
      validate: (value: number, { min_value, unit }: { min_value: number; unit: string }) => {
        return value >= min_value || equalWithTolerance(value, min_value, Number.EPSILON)
      },
      params: ['min_value', 'unit'],
      message: this.$i18n.t('minimumValueValidationMessage') as string,
    })
  }

  getRangeValidationMessage(rule: any): string {
    return this.$t('rangeValidationMessage', {
      min_value: rule.rules.range.min_value,
      max_value: rule.rules.range.max_value,
      unit: rule.rules.range.unit,
    }).toString()
  }
  getMinimumValueValidationMessage(rule: any): string {
    return this.$t('minimumValueValidationMessage', {
      min_value: rule.rules.min_range.min_value,
      unit: rule.rules.min_range.unit,
    }).toString()
  }

  setValidationRulesCustomMessages() {
    // min_range
    this.numericalTimeValidationRule.customMessages.required = this.getMinimumValueValidationMessage(
      this.numericalTimeValidationRule,
    )
    this.numericalTimeValidationRuleNotRequired.customMessages.required = this.getMinimumValueValidationMessage(
      this.numericalTimeValidationRuleNotRequired,
    )
    this.meshSizeValidationRule.customMessages.required = this.getMinimumValueValidationMessage(
      this.meshSizeValidationRule,
    )
    this.powerValidationRule.customMessages.required = this.getMinimumValueValidationMessage(this.powerValidationRule)
    this.microLengthValidationRule.customMessages.required = this.getMinimumValueValidationMessage(
      this.microLengthValidationRule,
    )
    this.speedValidationRule.customMessages.required = this.getMinimumValueValidationMessage(this.speedValidationRule)

    // range
    this.ambientTemperatureValidationRule.customMessages.required = this.getRangeValidationMessage(
      this.ambientTemperatureValidationRule,
    )
    this.plateThicknessValidationRule.customMessages.required = this.getRangeValidationMessage(
      this.plateThicknessValidationRule,
    )
    this.minWallThicknessValidationRule.customMessages.required = this.getRangeValidationMessage(
      this.minWallThicknessValidationRule,
    )
    this.frictionCoefficientValidationRule.customMessages.required = this.getRangeValidationMessage(
      this.frictionCoefficientValidationRule,
    )
    this.maxIterationValidationRule.customMessages.required = this.getRangeValidationMessage(
      this.maxIterationValidationRule,
    )
    this.maxAllowableDeviationValidationRule.customMessages.required = this.getRangeValidationMessage(
      this.maxAllowableDeviationValidationRule,
    )
    this.maxAllowableToleranceRule.customMessages.required = this.getRangeValidationMessage(
      this.maxAllowableToleranceRule,
    )
    this.betweenZeroAndOneValidationRule.customMessages.required = this.getRangeValidationMessage(
      this.betweenZeroAndOneValidationRule,
    )
    this.numericalFromOneToOnePointFiveValidationRule.customMessages.required = this.getRangeValidationMessage(
      this.numericalFromOneToOnePointFiveValidationRule,
    )
    this.numericalTemperatureValidationRule.customMessages.required = this.getRangeValidationMessage(
      this.numericalTemperatureValidationRule,
    )
    this.buildPlateTemperatureValidationRule.customMessages.required = this.getRangeValidationMessage(
      this.buildPlateTemperatureValidationRule,
    )
    this.numericalFromMinusOneToOneValidationRule.customMessages.required = this.getRangeValidationMessage(
      this.numericalFromMinusOneToOneValidationRule,
    )
  }

  async created() {
    // plateThickness ValidationRule max value
    const buildPlatePk = new VersionablePk(this.buildPlan.buildPlateId, this.buildPlan.buildPlateVersion)
    const machineConfigPk = new VersionablePk(this.buildPlan.machineConfigId, this.buildPlan.machineConfigVersion)
    const buildPlate = await buildPlates.getBuildPlateByPk(buildPlatePk, machineConfigPk)

    if (buildPlate) {
      const height = round(
        convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, this.getLengthUnit(), buildPlate.buildableVolumeZ),
        2,
      )
      this.plateThicknessValidationRule.rules.range.max_value = height
    }
  }

  async beforeMount() {
    this.waitingMode = true
    this.isOkDisabled = true
    this.updateOkStatus()
    this.extendValidationRules()
    this.initilizeFlatSurfacesCollector()
  }

  async mounted() {
    this.isSimulateReadOnly = this.isToolReadOnly
    this.$emit('mounted')
    this.customString = this.$t('custom') as string
    const jobs = await buildPlans.getJobs(this.buildPlan.id)
    jobs.sort((a, b) => b.number - a.number)
    const simCompJobs = jobs.filter((item) =>
      [JobType.MESH, JobType.SIMULATE, JobType.COMPENSATE].includes(item.jobType),
    )

    if (simCompJobs.length > 0) {
      this.currentSimCompJob = simCompJobs[0]
      if (
        this.currentSimCompJob &&
        (FINISHED_JOBS.includes(this.currentSimCompJob.code) || RUNNING_JOBS.includes(this.currentSimCompJob.code))
      ) {
        await this.triggerStatusCheck()
      }
    } else {
      this.isOkDisabled = false
    }

    // Simulation Parameters
    this.setMaterialData()
    const simulationParameters = this.getSimulationParmeters
    let maxPartSize: number = 0.0
    let minPartSize = Number.MAX_VALUE
    this.layerThickness = this.calculateMinLayerThickness(this.getLengthUnit())

    // Creep data validation
    this.setCreepData()

    // BuildPlanItems loop
    // No supports
    this.noSupports = !this.buildPlan.buildPlanItems.some((bpItem) => {
      return this.bpItemHasSupports(bpItem)
    })

    this.buildPlan.buildPlanItems.forEach(async (bpItem) => {
      const boundingInfo = await this.getPartsBoundingBox()
      const xDimension = Math.abs(boundingInfo.boundingBox.maximum.x - boundingInfo.boundingBox.minimum.x)
      const yDimension = Math.abs(boundingInfo.boundingBox.maximum.y - boundingInfo.boundingBox.minimum.y)
      const zDimension = Math.abs(boundingInfo.boundingBox.maximum.z - boundingInfo.boundingBox.minimum.z)
      minPartSize = Math.min(xDimension, yDimension, zDimension, minPartSize)
      maxPartSize = Math.max(xDimension, yDimension, zDimension, maxPartSize)
    })

    if (this.isDMLM) {
      if (simulationParameters) {
        const dmlmSimComParameters = simulationParameters as IDmlmSimulateCompensation
        this.viewDmlmParameters = {
          computeType: dmlmSimComParameters.computeType,
          buildPlanId: this.buildPlan.id,
          runCompensation: dmlmSimComParameters.runCompensation,
          maxIterations: dmlmSimComParameters.maxIterations,
          convergenceValue: dmlmSimComParameters.convergenceValue,
          ambientTemperatureInCelsius: dmlmSimComParameters.ambientTemperatureInCelsius,
          buildPlateTemperatureInCelsius: dmlmSimComParameters.buildPlateTemperatureInCelsius,
          buildPlateThicknessInMm: dmlmSimComParameters.buildPlateThicknessInMm,
          minWallThicknessInMm: dmlmSimComParameters.minWallThicknessInMm,
          speedVsAccuracy: dmlmSimComParameters.speedVsAccuracy,
          processSteps: dmlmSimComParameters.processSteps,
          inherentStrainParameters: dmlmSimComParameters.inherentStrainParameters,
          meshOnly: dmlmSimComParameters.meshOnly,
          creepDataValidation: dmlmSimComParameters.creepDataValidation,
        }

        // if undefined, set to true for handling different sizes
        if (!this.viewDmlmParameters.meshOnly) {
          this.viewDmlmParameters.meshOnly = true
        }
        this.showStoredParameters(this.viewDmlmParameters)
      } else {
        this.viewDmlmParameters.buildPlanId = this.buildPlan.id
      }
      this.assignDefaultFurnanceTemperatureValues(this.viewDmlmParameters)
      this.assignLatestParameterSets(this.viewDmlmParameters)
      this.updateMinWallInformation()
      this.waitingMode = false
    } else {
      // BinderJet
      if (simulationParameters) {
        const binderJetSimComParameters = simulationParameters as IBinderJetSimulateCompensation
        this.viewBinderJetParameters = {
          computeType: binderJetSimComParameters.computeType,
          buildPlanId: this.buildPlan.id,
          runCompensation: binderJetSimComParameters.runCompensation,
          maxIterations: binderJetSimComParameters.maxIterations,
          convergenceValue: binderJetSimComParameters.convergenceValue,
          sinterPlateThicknessInMm: binderJetSimComParameters.sinterPlateThicknessInMm,
          shrinkageActivation: binderJetSimComParameters.shrinkageActivation,
          selectedFrictionCoefficientPlate: binderJetSimComParameters.selectedFrictionCoefficientPlate,
          selectedFrictionCoefficientSupport: binderJetSimComParameters.selectedFrictionCoefficientSupport,
          frictionCoefficient: binderJetSimComParameters.frictionCoefficient,
          speedVsAccuracy: binderJetSimComParameters.speedVsAccuracy,
          binderjetScalingFactor: binderJetSimComParameters.binderjetScalingFactor,
          autoMeshSizeActive: binderJetSimComParameters.autoMeshSizeActive,
          meshSize: binderJetSimComParameters.meshSize,
          meshSizeUnit: this.getLengthUnit(),
          contactDampingFactor: binderJetSimComParameters.contactDampingFactor,
          materialModelOption: binderJetSimComParameters.materialModelOption,
          parallelMemoryOption: binderJetSimComParameters.parallelMemoryOption,
          solverType: binderJetSimComParameters.solverType,
          remesh: false,
          meshContact: binderJetSimComParameters.meshContact,
          transitionTol: binderJetSimComParameters.transitionTol,
          compensationConstraints: binderJetSimComParameters.compensationConstraints,
          generalContact: binderJetSimComParameters.generalContact,
        }

        const updateCurrentBjAnalysisTypeOption = this.viewBinderJetParameters.shrinkageActivation
          ? BinderJetAnalysisTypeOptions.FRICTION
          : BinderJetAnalysisTypeOptions.GRAVITY
        this.updateCurrentBinderJetAnalysisTypeOption(updateCurrentBjAnalysisTypeOption)

        if (this.viewBinderJetParameters.compensationConstraints === undefined) {
          this.viewBinderJetParameters.compensationConstraints = []
        }

        if (this.viewBinderJetParameters.generalContact === undefined) {
          this.viewBinderJetParameters.generalContact = false
        }

        const materialModelOptionKey = 'materialModelOption'
        if (simulationParameters.hasOwnProperty(materialModelOptionKey)) {
          this.updateCurrentBinderJetMaterialModelOption(simulationParameters[materialModelOptionKey].toUpperCase())
          this.viewBinderJetParameters.materialModelOption =
            this.binderJetMaterialModelOptions[this.currentBjMaterialModelOption].enumValue
        }

        const parallelMemoryOptionKey = 'parallelMemoryOption'
        if (simulationParameters.hasOwnProperty(parallelMemoryOptionKey)) {
          this.updateCurrentBinderJetParallelMemoryOption(simulationParameters[parallelMemoryOptionKey].toUpperCase())
          this.viewBinderJetParameters.parallelMemoryOption =
            this.binderJetParallelMemoryOptions[this.currentBjParallelMemoryOption].enumValue
        }

        const solverTypeOptionKey = 'solverType'
        if (simulationParameters.hasOwnProperty(solverTypeOptionKey)) {
          this.updateCurrentBinderJetSolverTypeOption(simulationParameters[solverTypeOptionKey].toUpperCase())
          this.viewBinderJetParameters.solverType =
            this.binderJetSolverTypeOptions[this.currentBjSolverTypeOption].enumValue
        }

        const meshContactOptionKey = 'meshContact'
        if (simulationParameters.hasOwnProperty(meshContactOptionKey)) {
          this.updateCurrentBinderJetMeshContactOption(simulationParameters[meshContactOptionKey].toUpperCase())
          this.viewBinderJetParameters.meshContact =
            this.binderJetMeshContactOptions[this.currentBjMeshContactOption].enumValue
        }

        if (this.viewBinderJetParameters.contactDampingFactor === null) {
          this.viewBinderJetParameters.contactDampingFactor = DEFAULT_CONTACT_DAMPING_FACTOR
        } else {
          this.lastValidStabilizationFactor = this.viewBinderJetParameters.contactDampingFactor
        }
        this.initilizeFlatSurfacesCollector()
        if (!this.viewBinderJetParameters.transitionTol) {
          this.viewBinderJetParameters.transitionTol = this.transitionTolerance
        }
      } else {
        this.viewBinderJetParameters.buildPlanId = this.buildPlan.id
        this.viewBinderJetParameters.meshSizeUnit = this.getLengthUnit()
        this.viewBinderJetParameters.materialModelOption = BinderJetMaterialModelOptions.SYMMETRIC
        this.viewBinderJetParameters.parallelMemoryOption = BinderJetParallelMemoryOptions.SHARED
        this.viewBinderJetParameters.solverType = BinderJetSolverTypeOptions.DIRECT
        this.viewBinderJetParameters.meshContact = BinderJetMeshContactOptions.FRICTIONAL
      }
      await this.assignDefaultScalingFactor()
      this.showStoredParameters(this.viewBinderJetParameters)
      await this.assignDefaultSinteringSurfaceParameters()
      await this.onshrinkageActivationChanged(this.currentBjAnalysisTypeOption)
      await this.onBinderJetMaterialModelOptionChange(this.currentBjMaterialModelOption)
      await this.onBinderJetSolverTypeOptionChange(this.currentBjSolverTypeOption)
      await this.onBinderJetParallelMemoryOptionChange(this.currentBjParallelMemoryOption)
      await this.onBinderJetMeshContactOptionChange(this.currentBjMeshContactOption)
      // flat surface Validation
      this.isToolMounted = true
      await this.flatSurfacesChanged()
      this.isToolMounted = false
    }
    // Analysis speed UI
    this.uISimuSpeed.push(this.$t('analysisSpeed.low').toString())
    this.uISimuSpeed.push('')
    this.uISimuSpeed.push(this.$t('analysisSpeed.high').toString())

    // Validation Rules MaxValues
    if (this.isDMLM) {
      const solidusTemperature = await this.getSolidusTemperatureValue()
      if (solidusTemperature) {
        const solidusTemperatureRounded = roundNumberByDigits(solidusTemperature, 3)
        this.numericalTemperatureValidationRule.rules.range.max_value = solidusTemperatureRounded
        this.buildPlateTemperatureValidationRule.rules.range.max_value = solidusTemperatureRounded
        this.$refs.bpt.validate()
      }
    } else {
      // BinderJet MeshSize
      this.meshSizeValidationRule.rules.min_range.min_value = MINIMUM_PARAMETER_VALUE
      const meshSizeValues = calculateMeshSizeRange(this.boundingBox)
      this.meshSizeRange.min_value = meshSizeValues.min_value
      this.meshSizeRange.max_value = meshSizeValues.max_value
      const defaultMeshSize = (this.meshSizeRange.max_value - this.meshSizeRange.min_value) / 2
      if (!this.viewBinderJetParameters.meshSize) {
        this.viewBinderJetParameters.meshSize = defaultMeshSize
      }
    }
    this.setValidationRulesCustomMessages()

    // Material Removal Support available
    if (this.noSupports) {
      const removeIndex = this.simulationProcessAvailable.indexOf(SimProcessType.MaterialRemovalSupport)
      this.simulationProcessAvailable.splice(removeIndex, 1)
    }
    this.saveParametersActive = true

    this.updateOkStatus()
    this.okIntervalId = setInterval(this.updateOkStatus, 2000)
    this.isToolMounted = true
  }

  destroyed() {
    clearInterval(this.intervalId)
    clearInterval(this.okIntervalId)
    this.onclickFlatSurfacesMultiItemCollector()
  }

  updateOkStatus() {
    if (!this.isToolReadOnly) {
      this.$emit('setOkDisabled', this.isOkDisabled)
    }
  }

  get runSimCompBtnText() {
    // TODO: since it is for sample UI not fallowing localization
    let btnText = 'Run Simulation'
    if (
      this.currentSimCompJob &&
      [JobStatusCode.COMPLETE, JobStatusCode.WARNING, JobStatusCode.ERROR].indexOf(this.currentSimCompJob.code) === -1
    ) {
      btnText = `Simulate/Compensate: ${this.currentSimCompJob.code}`
    }
    return btnText
  }

  get settingBtnClass() {
    if ((!this.isDMLM && this.settingsPanelActive) || (this.isDMLM && this.settingsDmlmPanelActive)) {
      return 'settings-btn-active'
    }
    return 'settings-btn'
  }
}
