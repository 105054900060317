
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ItemTypeSelector from '@/components/controls/FileExplorer/ItemTypeSelector.vue'
import ActionBar from '@/components/layout/FileExplorer/ActionBar.vue'
import DetailsPanel from '@/components/layout/FileExplorer/DetailsPanel.vue'
import InfinityScrollArea from '@/components/layout/FileExplorer/InfinityScrollArea.vue'
import MoveErrorModal from '@/components/layout/FileExplorer/MoveErrorModal.vue'
import MoveOrCopyModal from '@/components/layout/FileExplorer/MoveOrCopyModal.vue'
import MoveToTrashItemErrorModal from '@/components/layout/FileExplorer/MoveToTrashItemErrorModal.vue'
import MoveToTrashItemModal from '@/components/layout/FileExplorer/MoveToTrashItemModal.vue'
import RenameItemModal from '@/components/layout/FileExplorer/RenameItemModal.vue'
import ShareItemModal from '@/components/layout/FileExplorer/ShareItemModal.vue'
import AllFilesSearchViewMixin from '@/components/layout/FileExplorer/Table/mixins/AllFilesSearchViewMixin'
import FileExplorerRedirectionMixin from '@/components/layout/FileExplorer/Table/mixins/FileExplorerRedirectionMixin'
import SharedByMeItemHeader from '@/components/layout/FileExplorer/Table/SharedByMe/SharedByMeItemHeader.vue'
import SharedByMeItemListRow from '@/components/layout/FileExplorer/Table/SharedByMe/SharedByMeItemListRow.vue'
import SharedDetails from '@/components/layout/FileExplorer/Table/SharedByMe/SharedDetails.vue'
import UnshareModal from '@/components/layout/FileExplorer/Table/SharedByMe/UnshareModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import messageService from '@/services/messageService'
import StoresNamespaces from '@/store/namespaces'
import { ContextMenuItem } from '@/types/FileExplorer/ContextMenuItem'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { FilterParamsKey, TableFilterParams } from '@/types/FileExplorer/FilterParamsKey'
import { ItemAction } from '@/types/FileExplorer/ItemAction'
import { Permission } from '@/types/FileExplorer/Permission'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import { TableSortParams } from '@/types/FileExplorer/SortParamsKey'
import { DetailsPanelViewMode } from '@/types/FileExplorer/ViewMode'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const partsStore = namespace(StoresNamespaces.Parts)

@Component({
  components: {
    SharedDetails,
    DetailsPanel,
    SharedByMeItemHeader,
    SharedByMeItemListRow,
    ActionBar,
    RenameItemModal,
    MoveErrorModal,
    MoveToTrashItemModal,
    ShareItemModal,
    ItemTypeSelector,
    MoveToTrashItemErrorModal,
    UnshareModal,
    ConfirmModal,
    InfinityScrollArea,
    MoveOrCopyModal,
  },
})
export default class SharedByMe extends Mixins(AllFilesSearchViewMixin, FileExplorerRedirectionMixin) {
  @fileExplorerStore.Getter getSharedByMeItems: FileExplorerItem[]
  @fileExplorerStore.Getter isLoading: boolean
  @fileExplorerStore.Getter getSortParams: () => TableSortParams
  @fileExplorerStore.Getter getFilterParams: () => TableFilterParams

  @fileExplorerStore.Action unshareItems: (items: FileExplorerItem[]) => void
  @fileExplorerStore.Action getAllMyPermissions: any
  @fileExplorerStore.Action fetchSharedByMeItems: any
  @fileExplorerStore.Action getGetRunningAndFailedJobsByItemIds: Function
  @fileExplorerStore.Action clearPaginatedData: Function

  @partsStore.Action actualizeParts: (items?: FileExplorerItem[]) => Promise<void>

  @fileExplorerStore.Mutation unsetSharedItem: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation clearSharedItems: Function
  @fileExplorerStore.Mutation setIsMoveItems: (val: boolean) => void

  existingNames: string[] = []

  filterType = FilterParamsKey.Shared

  isShownUnshareModal = false
  isSubmitting = false
  contextMenuItems: ContextMenuItem[] = []

  $refs!: {
    confirm: InstanceType<typeof ConfirmModal>
    actionBar: ActionBar
  }

  // @ts-ignore
  get items() {
    return this.getSharedByMeItems
  }

  get sharedPermissions(): Permission[] {
    const isCurrentUser = (p: Permission) => p.grantedTo === this.userDetails.id
    return this.permissions.filter((p) => !isCurrentUser(p))
  }

  async beforeMount() {
    this.unselectAllItems()
    this.setRoot(null)
    await this.refreshItems()
  }

  beforeDestroy() {
    this.setDetailsPanelMode(DetailsPanelViewMode.Default)
  }

  async unshareUser(permission: Permission) {
    const isCurrentUser = permission.grantedTo === this.userDetails.id
    const displayName = this.lookupFullNameById(permission.grantedTo)
    let shouldDelete = await this.shouldDeletePermission(displayName, isCurrentUser, this.$refs.confirm)
    if (shouldDelete) {
      try {
        if (permission.isInherited) {
          this.$refs.confirm.cancel()
          shouldDelete = await this.shouldDeleteInheritedPermission(
            displayName,
            permission.inheritedFrom,
            this.$refs.confirm,
          )
          if (!shouldDelete) {
            this.$refs.confirm.cancel()
            return
          }
        }

        const item = this.items.find((i: FileExplorerItem) => permission.itemId === i.id)
        await this.deletePermission({ permissionId: permission.id })
        await this.setLastAction({ itemId: item.id, action: ItemAction.Unshared })

        // If there are no permissions (users that this item was shared to) left then remove this item from the list
        if (!this.permissions || (this.permissions && !this.permissions.length)) {
          this.unsetSharedItem(item)
          this.unselectItem({ item, selectionType: SelectionTypes.Single })
        }
      } catch (error) {
        messageService.showErrorMessage(error.message)
      }
    }
  }

  async onItemListRowClick(payload) {
    this.setSelectedItemsCanBeTrashed(null)

    const item: FileExplorerItem = payload.item

    payload.item = await this.refreshItemIfNeeded(item)

    await this.getAllMyPermissions()

    await this.onItemClick(payload)
    this.defineDetailsViewMode()
  }

  async onUnshareConfirm(items: FileExplorerItem[]) {
    if (this.isSubmitting) {
      return
    }

    try {
      this.isSubmitting = true

      await this.unshareItems(items)

      this.toggleUnshareModal()

      const resultMessage =
        items.length > 1
          ? this.$i18n.t('unshareDialog.multipleSuccess', { number: items.length })
          : this.$i18n.t('unshareDialog.singleSuccess')
      messageService.showSuccessMessage(resultMessage.toString())
    } catch (error) {
      messageService.showErrorMessage(error.message)
    } finally {
      this.isSubmitting = false
    }
  }

  toggleUnshareModal() {
    this.isShownUnshareModal = !this.isShownUnshareModal
  }

  onShareModalClose(event) {
    if (event.isUnsetSharedItem) {
      this.unselectItem({ item: this.getSelectedItems[0], selectionType: SelectionTypes.Single })
    }
    this.toggleShareItemModal(false)
  }

  onIntersection() {
    this.fetchItems()
  }

  @Watch('getFilterParams', { deep: true })
  @Watch('getSortParams', { deep: true })
  async refreshItems() {
    await this.clearPaginatedData()
    this.clearSharedItems()
    this.fetchItems()
  }

  async fetchItems() {
    const itemsToGetJobs = await this.fetchSharedByMeItems()
    await this.actualizeParts(itemsToGetJobs)
    const itemIds = itemsToGetJobs.map((item: FileExplorerItem) => item.id)
    this.getGetRunningAndFailedJobsByItemIds(itemIds)
  }

  async beforeContextMenuOpen() {
    await this.fetchItemById(this.getSelectedItem.id)

    this.contextMenuItems = [
      {
        title: this.$i18n.t('open') as string,
        divide: false,
        clickHandler: this.$refs.actionBar.openItem,
        condition: true,
      },
      {
        title: this.$i18n.t('share') as string,
        divide: false,
        clickHandler: this.toggleShareItemModal,
        condition: true,
      },
      {
        title: this.$i18n.t('rename') as string,
        divide: false,
        clickHandler: this.toggleRenameItemModal,
        condition: this.canRename,
      },
      {
        title: this.$i18n.t('moveOrCopy') as string,
        divide: false,
        clickHandler: this.toggleMoveOrCopyModal,
        testAttribute: 'copy-button',
        condition: this.getSelectedItem.grantedPermissions.itemMove,
      },
      {
        title: this.$i18n.t('unshare') as string,
        divide: this.isTrashSelectedItemsEnabled,
        clickHandler: this.toggleUnshareModal,
        condition: true,
      },
      {
        title: this.$i18n.t('trash') as string,
        divide: false,
        clickHandler: this.trashSelectedItems,
        condition: this.isTrashSelectedItemsEnabled,
      },
    ]
  }
}
