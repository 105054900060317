
import Vue from 'vue'
import Component from 'vue-class-component'
import SiteListRow from '@/components/layout/admin/sites/SiteListRow.vue'
import { Site } from '@/types/Sites/Site'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'
import { PRINT_SITE_DOWNLOAD_DEFAULT_NAME } from '@/constants'
import { RouterNames } from '@/router'
import { orderBy } from '@/utils/array'
import { SortOrders } from '@/types/SortModes'

const sitesStore = namespace(StoresNamespaces.Sites)

const DEFAULT_SORT_CRITERIA = 'name'
const DEFAULT_SORT_ORDER = SortOrders.Ascending

@Component({
  components: { SiteListRow },
})
export default class SitesHolder extends Vue {
  @sitesStore.Action fetchAllSites: () => Promise<Site[]>
  @sitesStore.Getter getAllSites: Site[]
  @sitesStore.Mutation setPreviousPath: (path: string) => void

  beforeMount() {
    this.fetchAllSites()
  }

  beforeRouteEnter(to, from, next) {
    next(async (component: SitesHolder) => {
      if (
        from.name !== RouterNames.JobsDashboard &&
        from.name !== RouterNames.UserAdmin &&
        (!from.meta || from.meta.routerName !== RouterNames.SitesAdmin)
      ) {
        component.setPreviousPath(from.path)
      }
    })
  }

  get headers(): string[] {
    return [
      this.$t('tenantAdminMessages.siteRowHeaders.electricityPrice').toString(),
      this.$t('tenantAdminMessages.siteRowHeaders.argonPrice').toString(),
      this.$t('tenantAdminMessages.siteRowHeaders.nitrogenPrice').toString(),
      this.$t('tenantAdminMessages.siteRowHeaders.machineTypes').toString(),
    ]
  }

  get items(): Site[] {
    const filteredSites = this.getAllSites.filter(
      (item) => item.name.trim().toLocaleLowerCase() !== PRINT_SITE_DOWNLOAD_DEFAULT_NAME,
    )
    return orderBy(filteredSites, [DEFAULT_SORT_CRITERIA], [DEFAULT_SORT_ORDER])
  }

  get hasItems() {
    return this.getAllSites.length
  }

  itemUpdated() {
    this.fetchAllSites()
  }
}
