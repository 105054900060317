import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { ItemDetailsPayload } from '@/store/modules/fileExplorer/types'
import StoresNamespaces from '@/store/namespaces'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { IItemClick } from '@/types/FileExplorer/IItemClick'
import { ItemDetailsDto } from '@/types/FileExplorer/ItemDetails'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { isOfType } from '@/utils/common'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component
export class RowSelection extends Vue {
  @fileExplorerStore.Getter getSelectedItems: FileExplorerItem[]
  @fileExplorerStore.Getter getSelectedItem: FileExplorerItem
  @fileExplorerStore.Getter getIsMoveItems: boolean

  @fileExplorerStore.Action fetchItemById: (itemId: string) => Promise<FileExplorerItem>
  @fileExplorerStore.Action fetchItemDetails: (payload: ItemDetailsPayload) => Promise<ItemDetailsDto>

  @fileExplorerStore.Mutation unselectAllItems: () => void
  @fileExplorerStore.Mutation selectItem: (payload: {
    item: FileExplorerItem | PrintOrder
    selectionType: SelectionTypes
  }) => void
  @fileExplorerStore.Mutation unselectItem: (payload: {
    item: FileExplorerItem | PrintOrder
    selectionType: SelectionTypes
  }) => void

  items: any

  lastSelectedItem: FileExplorerItem | PrintOrder = null

  get canRenameItem(): boolean {
    const selectedItem = (this.getSelectedItems.length === 1 && this.getSelectedItems[0]) || this.getSelectedItem

    if (isOfType<PrintOrder>(selectedItem, 'site')) {
      return false
    }

    return selectedItem ? selectedItem.grantedPermissions.itemRename : false
  }

  async onItemClick(payload: IItemClick, fetchDetails = true) {
    if (this.getIsMoveItems) {
      return
    }

    const selectedItems = this.getSelectedItems
    const { item, ctrlKey, shiftKey, metaKey } = payload

    // Do not allow selecting multiple Print Order rows or Print Order and item row
    if (
      (ctrlKey || shiftKey || metaKey) &&
      selectedItems.length !== 0 &&
      (isOfType<PrintOrder>(selectedItems[0], 'site') || isOfType<PrintOrder>(item, 'site'))
    ) {
      return
    }

    if (shiftKey && !!this.lastSelectedItem && this.lastSelectedItem.id !== item.id) {
      const prevIndex = this.items.findIndex((o) => this.lastSelectedItem.id === o.id)
      const curIndex = this.items.findIndex((o) => item.id === o.id)

      const start = prevIndex < curIndex ? prevIndex : curIndex
      const end = prevIndex < curIndex ? curIndex : prevIndex

      this.unselectAllItems()

      // tslint:disable-next-line: no-increment-decrement
      for (let i = start; i <= end; i++) {
        const curItem = this.items[i]
        this.selectItem({ item: curItem, selectionType: SelectionTypes.Multiple })
        this.lastSelectedItem = curItem
      }
      // On Macintosh keyboards the metaKey will be true when Command key is pressed.
    } else if (ctrlKey || metaKey) {
      if (selectedItems.find((o) => o.id === item.id)) {
        this.unselectItem({ item, selectionType: SelectionTypes.Multiple })
        this.lastSelectedItem = this.getSelectedItems.length ? this.getSelectedItems.slice(-1)[0] : null
        if (
          fetchDetails &&
          this.getSelectedItem &&
          (!this.getSelectedItems.length || this.getSelectedItems.length === 1)
        ) {
          await this.fetchItemDetails({ itemId: this.getSelectedItem.id, itemType: this.getSelectedItem.itemType })
        }
      } else {
        this.selectItem({ item, selectionType: SelectionTypes.Multiple })
        if (fetchDetails && this.getSelectedItems.length === 1) {
          await this.fetchItemDetails({ itemId: item.id, itemType: (item as FileExplorerItem).itemType })
        }

        this.lastSelectedItem = item
      }
    } else {
      if (!!selectedItems.find((o) => o.id === item.id) && selectedItems.length === 1) {
        this.unselectItem({ item, selectionType: SelectionTypes.Single })
        this.lastSelectedItem = this.getSelectedItems.length ? this.getSelectedItems.slice(-1)[0] : null
        if (fetchDetails && this.getSelectedItem && !this.getSelectedItems.length) {
          await this.fetchItemDetails({ itemId: this.getSelectedItem.id, itemType: this.getSelectedItem.itemType })
        }
      } else {
        this.selectItem({ item, selectionType: SelectionTypes.Single })
        if (fetchDetails) {
          await this.fetchItemDetails({ itemId: item.id, itemType: (item as FileExplorerItem).itemType })
        }

        this.lastSelectedItem = item
      }
    }
  }

  async refreshItemIfNeeded(item: FileExplorerItem): Promise<FileExplorerItem> {
    const isItemSelected = this.getSelectedItems.some((selectedItem) => selectedItem.id === item.id)

    // If the item is shared we need always receive up-to-date data,
    // because the role may have changed or the item was renamed.
    if (!isItemSelected && item.isShared) {
      return this.fetchItemById(item.id)
    }

    return item
  }
}
