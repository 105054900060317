
import { Component, Vue, Prop } from 'vue-property-decorator'

import Icon, { IconNames } from '@/components/icons/Icon.vue'

@Component({
  components: {
    Icon,
  },
})
export default class ToggleDetailsPanelButton extends Vue {
  @Prop({ default: false }) isActive: boolean
}
