import '@/window'
import Vue from 'vue'
import App from '@/pages/App.vue'
import store from '@/store'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import initAuth0 from '@/plugins/auth0'
import '@/validators'
import '@/plugins/snackbar'
import '@/filters'
import '@/directives'
import 'vuetify/dist/vuetify.min.css'
import '@/assets/styles/styles.scss'
import '@/plugins/vue-sanitize'
import VueCookies from 'vue-cookies'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'
import { CaptureConsole as CaptureConsoleIntegration, HttpClient as HttpClientIntegration } from '@sentry/integrations'

Vue.config.productionTip = false
Vue.use(VueCookies)

async function enableApiMocking() {
  const mockModule = await import('@/mocks')
  return mockModule.registerServiceWorker()
}

function initMarketo(mrktoInst) {
  let didInit = false
  function initMunchkin() {
    if (!didInit) {
      didInit = true
      window.Munchkin.init(mrktoInst, { apiOnly: true })
    }
  }
  const s = document.createElement('script')
  s.type = 'text/javascript'
  s.async = true
  s.src = '//munchkin.marketo.net/munchkin.js'
  s.onload = initMunchkin
  document.getElementsByTagName('head')[0].appendChild(s)
}

// Userback
function initUserback(userbackToken) {
  window.Userback = window.Userback || {}
  window.Userback.access_token = userbackToken
  const userbackURL = `https://static.userback.io/widget/v1.js`
  const userbackScriptElement: HTMLScriptElement = document.createElement('script')
  userbackScriptElement.async = true
  userbackScriptElement.src = userbackURL
    ; (document.head || document.body).appendChild(userbackScriptElement)
}

// Sentry
function initSentry(sentryUrl) {
  Sentry.init({
    Vue,
    dsn: sentryUrl,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new CaptureConsoleIntegration({
        levels: ['error', 'warn', 'log'],
      }),
      new HttpClientIntegration({
        // This array can contain tuples of `[begin, end]` (both inclusive)
        failedRequestStatusCodes: [[500, 599]],
      }),
    ],
    logErrors: true,
    environment: window.location.host,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    maxBreadcrumbs: 50,
    tracePropagationTargets: ['localhost', 'localhost:8080', 'beta.geadditive.com', 'my.geadditive.com'],
  })
}

async function initConfig() {
  let apiUrl
  if (process.env.VUE_APP_API_URL) {
    apiUrl = process.env.VUE_APP_API_URL
  } else {
    apiUrl = location.origin
  }

  const response: Response = await fetch(`${apiUrl}/api/ui/config`, {
    credentials: 'same-origin',
  })
  if (response) {
    const data = await response.json()
    // @ts-ignore
    window.env = {
      VUE_APP_AMP_VERSION: data.VUE_APP_AMP_VERSION || 'dev',
      VUE_APP_CHART_VERSION: data.VUE_APP_CHART_VERSION || 'dev',
      VUE_APP_API_URL: apiUrl,
      VUE_APP_AUDIENCE: data.VUE_APP_AUDIENCE,
      VUE_APP_CLIENT_ID: data.VUE_APP_CLIENT_ID,
      VUE_APP_DOMAIN: data.VUE_APP_DOMAIN,
      VUE_APP_CAD_HELPER_VERSION: data.VUE_APP_CAD_HELPER_VERSION,
      VUE_APP_S3_BASE_URL: data.VUE_APP_S3_BASE_URL,
      VUE_APP_TRACING_DISABLED: data.VUE_APP_TRACING_DISABLED,
      VUE_APP_SUPPORTS_SVC_URL: data.VUE_APP_SUPPORTS_SVC_URL,
      VUE_APP_SERVICE_AGREEMENT_URL: data.VUE_APP_SERVICE_AGREEMENT_URL,
      VUE_APP_RELEASE_NOTES_URL: data.VUE_APP_RELEASE_NOTES_URL,
      VUE_APP_DMLM_SIM_SETUP_URL: data.VUE_APP_DMLM_SIM_SETUP_URL,
      VUE_APP_BJ_SINTERING_SIM_SETUP_URL: data.VUE_APP_BJ_SINTERING_SIM_SETUP_URL,
      VUE_APP_UNDERSTANDING_SIM_RESULTS_URL: data.VUE_APP_UNDERSTANDING_SIM_RESULTS_URL,
      VUE_APP_LABEL_SVC_URL: data.VUE_APP_LABEL_SVC_URL,
      VUE_APP_DEFAULT_TENANT: data.VUE_APP_DEFAULT_TENANT,
    }
    if (data.VUE_APP_MARKETO_INSTANCE) {
      initMarketo(data.VUE_APP_MARKETO_INSTANCE)
    }
    // if (data.VUE_APP_WHATFIX_URL) {
    //   initWhatFix(data.VUE_APP_WHATFIX_URL)
    // }
    if (data.VUE_APP_USERBACK_TOKEN) {
      window.env.VUE_APP_USERBACK_TOKEN = data.VUE_APP_USERBACK_TOKEN
      initUserback(data.VUE_APP_USERBACK_TOKEN)
    }
    if (data.VUE_APP_SENTRY_URL) {
      window.env.VUE_APP_SENTRY_URL = data.VUE_APP_SENTRY_URL
      initSentry(data.VUE_APP_SENTRY_URL)
    }
  }
}

function checkWindowReady() {
  if (window.env) {
    initAuth0(window.env)
    new Vue({
      store,
      router,
      i18n,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app')
  } else {
    setTimeout(checkWindowReady, 300) // check again in 300ms
  }
}

async function init() {
  await initConfig()
  if (process.env.VUE_APP_API_MOCKING === 'true') {
    enableApiMocking().then((reg) => {
      if (reg.active) checkWindowReady()
    })
  } else {
    checkWindowReady()
  }
}

init()
