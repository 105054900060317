import { GetterTree } from 'vuex'
import { IBuildPlansState } from './types'
import { IRootState } from '@/store/types'
import { DEFAULT_BUILD_PLAN_COST, ViewModes } from '@/constants'
import {
  AddPartToolState,
  BuildPlanCost,
  BuildPlanTime,
  defaultDisplayToolbarState,
  GeometryType,
  IBuildPlan,
  IBuildPlanItem,
  IDisplayToolbarState,
  IGeometryProperties,
  ILoadingPart,
  IPrintStrategyParameterSet,
  IProductionSet,
  ISelectable,
  SelectionUnit,
  IMeshGeometryProperties,
  Visibility,
  IBuildChamberPart,
  IVariantItem,
  IIBCDisplayToolbarState,
  defaultIBCDisplayToolbarState,
} from '@/types/BuildPlans/IBuildPlan'
import { ISite } from '@/types/ISite'
import { IJob, JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import { IBaseSimulateCompensation, SimProcessType, SimSpeed } from '@/types/Simulation/SimulationCompensation'
import { IBuildPlate, IBuildPlateMachineConfig, IBuildPlateMaterial } from '@/types/BuildPlates/IBuildPlate'
import { FillTypes } from '../../../types/BuildPlans/IBuildPlanItemSettings'
import { IMachineConfig, PrintingTypes } from '@/types/IMachineConfig'
import { IMaterial } from '@/types/IMaterial'
import { ILabel } from '@/types/Marking/ILabel'
import { ContentViewModeTypes } from '@/visualization/types/ContentViewMode'
import { CommandType } from '@/types/UndoRedo/CommandType'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { CostEvaluationResultAdapterFactory } from '@/pages/CostEstimations/adapters'
import { CostEvaluationResultViewModel } from '@/types/CostEstimations/ICostEstimation'
import { BuildPlanCostItemDTO } from '@/pages/BuildPlans/dtos/BuildPlanCostItemDTO'
import { IBuildPlanInsight, IInsightSettings, IPendingInsights } from '@/types/BuildPlans/IBuildPlanInsight'
import { orderBy } from '@/utils/array'
import { SortOrders } from '@/types/SortModes'
import { IUser } from '@/types/User/IUser'
import { ItemPermissionsRole, Permission } from '@/types/FileExplorer/Permission'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { IInsightsCount } from '@/types/Common/Insights'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import {
  Binder,
  MachineConfigMaterialBinder,
  PrintStrategyInfo,
  PrintStrategyShortInfo,
} from '../../../types/Sites/Site'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { VersionableModel } from '@/types/Common/VersionableModel'
import {
  getDefaultVariantIdFromVersionAndPath,
  isItemLockedForUser,
} from '@/utils/fileExplorerItem/fileExplorerItemUtils'
import { IIBCPlan, IIBCPlanItem } from '@/types/IBCPlans/IIBCPlan'
import { RouterNames } from '@/router'

export const getters: GetterTree<IBuildPlansState, IRootState> = {
  getBuildPlanVariants(state): IVariantItem[] {
    return state.buildPlanVariants.filter((variant) => !variant.isRemoved)
  },

  getAllAvailableBuildPlanVariants(state): IVariantItem[] {
    return state.buildPlanVariants
  },

  getBuildPlanVariantById:
    (state) =>
      (id: string): IVariantItem => {
        return state.buildPlanVariants.find((variant) => variant.id === id)
      },

  getIsVariantSelected:
    (state) =>
      (variantIndex: string): boolean => {
        return state.buildPlan.id === state.buildPlanVariants[variantIndex].id
      },

  getBuildPlan(state: IBuildPlansState): IBuildPlan {
    return state.buildPlan
  },

  getAllBuildPlanItems(state: IBuildPlansState): IBuildPlanItem[] {
    return state.buildPlan && state.buildPlan.buildPlanItems
  },

  // Returns an array of unique parts on the plate
  getBuildChamberParts(state: IBuildPlansState): IBuildChamberPart[] {
    // Map that has part's ID as a key and value is a number of parts with such ID and a part's name
    const uniqueParts = new Map<string, IBuildChamberPart>()
    state.buildPlan.buildPlanItems.forEach(({ part }) => {
      if (!uniqueParts.has(part.id)) {
        uniqueParts.set(part.id, { id: part.id, count: 1, name: part.name })
      } else {
        uniqueParts.get(part.id).count += 1
      }
    })

    return Array.from(uniqueParts.values())
  },

  getBuildPlanItemsByPartId:
    (state) =>
      (partId: string): IBuildPlanItem[] => {
        return state.buildPlan.buildPlanItems.filter((bpItem) => bpItem.part.id === partId)
      },

  getIBCPlanItemsByPartId:
    (state) =>
      (partId: string): IIBCPlanItem[] => {
        return state.ibcPlan.ibcPlanItems.filter((bpItem) => bpItem.partId === partId)
      },

  getSelectedViewMode(state): ViewModes {
    return state && state.selectedViewMode
  },

  getBuildPlanViewMode(state): string {
    return state && state.selectedBuildPlanViewMode
  },

  getMachineConfigByPk:
    (state) =>
      (machineConfigPk: VersionablePk): IMachineConfig => {
        if (!machineConfigPk) {
          return null
        }

        return machineConfigPk.version
          ? state.machineConfigs.find(
            (config) => config.id === machineConfigPk.id && config.version === machineConfigPk.version,
          )
          : VersionableModel.getLatestVersion(state.machineConfigs.filter((config) => config.id === machineConfigPk.id))
      },

  getAllMachineConfigs(state): IMachineConfig[] {
    return state && state.machineConfigs
  },

  getAllMaterials(state): IMaterial[] {
    return state && state.materials
  },

  getMaterialByPk:
    (state) =>
      (materialPk: VersionablePk): IMaterial => {
        if (!materialPk) {
          return null
        }

        return materialPk.version
          ? state.materials.find((material) => material.id === materialPk.id && material.version === materialPk.version)
          : VersionableModel.getLatestVersion(state.materials.filter((material) => material.id === materialPk.id))
      },

  getSelectedParts(state: IBuildPlansState): ISelectable[] {
    return state.selectedItems
  },

  getSelectedOrientIndex(state): number {
    return state.selectedOrientationIndex
  },

  getHoverBodyId(state: IBuildPlansState): { bodyId: string; bpItemId: string } {
    return state.hoverBodyId
  },

  getSelectedBuildPlanItems(state: IBuildPlansState): IBuildPlanItem[] {
    return state.selectedItems
      .filter((selectable) => selectable.type === SelectionUnit.Part)
      .reduce((acc, selectable) => {
        const buildPlanItem = state.buildPlan.buildPlanItems.find((bpItem) => bpItem.id === selectable.id)
        if (buildPlanItem) {
          acc.push(buildPlanItem)
        }

        return acc
      }, [])
  },

  selectedLabel(state: IBuildPlansState): ILabel {
    return state.selectedLabel
  },

  isLabelInstancing(state: IBuildPlansState): boolean {
    return state.isLabelInstancing
  },

  getPrintStrategyParameterSetByPk:
    (state) =>
      (pk: VersionablePk): IPrintStrategyParameterSet => {
        if (!pk) {
          return null
        }

        return pk.version
          ? state.printStrategyParameterSets.find((ps) => ps.id === pk.id && ps.version === pk.version)
          : VersionableModel.getLatestVersion(state.printStrategyParameterSets.filter((ps) => ps.id === pk.id))
      },

  getPrintStrategyByPk:
    (state) =>
      (pk: VersionablePk): PrintStrategyShortInfo => {
        if (!pk) {
          return null
        }

        return pk.version
          ? state.printStrategies.find((ps) => ps.id === pk.id && ps.version === pk.version)
          : VersionableModel.getLatestVersion(state.printStrategies.filter((ps) => ps.id === pk.id))
      },

  getLoadingParts(state: IBuildPlansState): ILoadingPart[] {
    return state.loadingParts
  },

  getSelectedItemsNames(state: IBuildPlansState): string[] {
    return state.selectedItems.map((selected) => {
      let name
      if (selected.type === SelectionUnit.Part) {
        const bpItem = state.buildPlan.buildPlanItems.find((item) => item.id === selected.id)

        name = bpItem ? bpItem.part.name : null
      } else {
        name = selected.name
      }

      return name
    })
  },

  getSelectedPartsCollisions(state: IBuildPlansState): string[] {
    return state.selectedPartsCollisions
  },

  getAllBuildPlanMarkLabels(state: IBuildPlansState) {
    let labels = []
    if (state.buildPlan && state.buildPlan.buildPlanItems) {
      const items = state.buildPlan.buildPlanItems
      labels = items
        .filter((item) => item.labels && item.labels.length)
        .flatMap((i) => i.labels)
        .sort((a, b) => +new Date(a.createdAt) - +new Date(b.createdAt))
    }
    return labels
  },

  getBuildPlanItemByLabelId:
    (state) =>
      (id: string): IBuildPlanItem => {
        return state.buildPlan.buildPlanItems.find((item) => {
          if (!item.labels || !item.labels.length) {
            return false
          }

          return item.labels.some((mp) => mp.id === id)
        })
      },

  getIsLoading(state): boolean {
    return state && state.isLoading
  },

  isLayersLoading(state): boolean {
    return state && state.isLayersLoading
  },

  getSelectedBuildPlanJobs(state: IBuildPlansState): IJob[] {
    return state.selectedBuildPlanJobs
  },

  getSelectedBuildPlanPrintJobs(state: IBuildPlansState): IJob[] {
    return state.selectedBuildPlanJobs.filter((job) => job.jobType === JobType.PRINT)
  },

  getSelectedBuildPlanFinalizingJobs(state: IBuildPlansState): IJob[] {
    // a build plan having these job types can no longer be edited
    const finalizingJobs = state.selectedBuildPlanJobs.filter((job) => {
      return (
        ((job.jobType === JobType.SIMULATE ||
          job.jobType === JobType.COMPENSATE ||
          job.jobType === JobType.SIM ||
          job.jobType === JobType.COMP ||
          job.jobType === JobType.SLICE) &&
          [
            JobStatusCode.RUNNING,
            JobStatusCode.COMPLETE,
            JobStatusCode.WARNING,
            JobStatusCode.ERROR,
            JobStatusCode.CANCELLED,
            JobStatusCode.CANCELLING,
          ].includes(job.code)) ||
        (job.jobType === JobType.NEST && job.code === JobStatusCode.RUNNING)
      )
    })
    return finalizingJobs
  },

  getBpNonSimCompFinalizingJobs(state: IBuildPlansState): IJob[] {
    // a build plan having these job types can no longer be edited
    const finalizingJobs = state.selectedBuildPlanJobs.filter((job) => {
      return (
        (job.jobType === JobType.SLICE &&
          [
            JobStatusCode.RUNNING,
            JobStatusCode.COMPLETE,
            JobStatusCode.WARNING,
            JobStatusCode.ERROR,
            JobStatusCode.CANCELLED,
            JobStatusCode.CANCELLING,
          ].includes(job.code)) ||
        (job.jobType === JobType.NEST && job.code === JobStatusCode.RUNNING)
      )
    })
    return finalizingJobs
  },

  getSelectedBuildPlanSimulationJob(state: IBuildPlansState): IJob {
    const simJobs = state.selectedBuildPlanJobs.filter(
      (job) => job.jobType === JobType.SIMULATE || job.jobType === JobType.COMPENSATE,
    )
    const [simWithHigherNumber] = simJobs.sort((a, b) => b.number - a.number)

    return simWithHigherNumber
  },

  getSelectedBuildPlanMeshJob(state: IBuildPlansState): IJob {
    const simJobs = state.selectedBuildPlanJobs.filter((job) => job.jobType === JobType.MESH)
    const [meshWithHigherNumber] = simJobs.sort((a, b) => b.number - a.number)
    return meshWithHigherNumber
  },

  getSelectedBuildPlanMarkJobs(state: IBuildPlansState): IJob[] {
    return state.selectedBuildPlanJobs.filter((job) => job.jobType === JobType.MARK)
  },

  getSelectedBuildPlanRunningJobs(state: IBuildPlansState, localGetters): IJob[] {
    return localGetters.getSelectedBuildPlanJobs.filter(
      (job) => job.code === JobStatusCode.QUEUED || job.code === JobStatusCode.RUNNING,
    )
  },

  getSelectedBuildPlanFailedJobs(state: IBuildPlansState, localGetters): IJob[] {
    return orderBy(
      localGetters.getSelectedBuildPlanJobs.filter(
        (job) => job.code === JobStatusCode.ERROR || job.code === JobStatusCode.CANCELLED,
      ),
      ['updatedDate'],
      [SortOrders.Descending],
    )
  },

  getSelectedBuildPlanCompletedJobs(state: IBuildPlansState, localGetters): IJob[] {
    return orderBy(
      localGetters.getSelectedBuildPlanJobs.filter(
        (job) => job.code === JobStatusCode.COMPLETE || job.code === JobStatusCode.WARNING,
      ),
      ['updatedDate'],
      [SortOrders.Descending],
    )
  },

  getSelectedBuildPlanExistingSimCompJobs(state: IBuildPlansState, localGetters): IJob[] {
    return localGetters.getSelectedBuildPlanJobs.filter((job) => {
      return (
        (job.jobType === JobType.MESH && [JobStatusCode.COMPLETE, JobStatusCode.WARNING].includes(job.code)) ||
        ([JobType.COMPENSATE, JobType.SIMULATE].includes(job.jobType) &&
          [
            JobStatusCode.COMPLETE,
            JobStatusCode.WARNING,
            JobStatusCode.CANCELLING,
            JobStatusCode.CANCELLED,
            JobStatusCode.ERROR,
            JobStatusCode.RUNNING,
            JobStatusCode.QUEUED,
          ].includes(job.code))
      )
    })
  },

  getSelectedBuildPlanSimCompJobs(state: IBuildPlansState, localGetters): IJob[] {
    const codes = [
      JobStatusCode.COMPLETE,
      JobStatusCode.WARNING,
      JobStatusCode.CREATED,
      JobStatusCode.QUEUED,
      JobStatusCode.RUNNING,
    ]
    return localGetters.getSelectedBuildPlanJobs.filter((job) => {
      return (
        (job.jobType === JobType.MESH && codes.includes(job.code)) ||
        ([JobType.COMPENSATE, JobType.SIMULATE].includes(job.jobType) &&
          codes.concat([JobStatusCode.CANCELLING, JobStatusCode.CANCELLED, JobStatusCode.ERROR]).includes(job.code))
      )
    })
  },

  getSelectionModes(state: IBuildPlansState) {
    return [SelectionUnit.Part, SelectionUnit.Body, SelectionUnit.FaceAndEdge]
  },

  getActiveSelectionMode(state: IBuildPlansState): SelectionUnit {
    return state.selectionMode
  },

  getSimulationProcess(): SimProcessType[] {
    return [
      SimProcessType.BuildSimulation,
      SimProcessType.MaterialRemovalBP,
      SimProcessType.MaterialRemovalSupport,
      SimProcessType.StressRelief,
    ]
  },

  getActiveSimulationProcess(): SimProcessType {
    return SimProcessType.BuildSimulation
  },

  getSimulationSpeed(): SimSpeed[] {
    return [SimSpeed.FAST, SimSpeed.STANDARD, SimSpeed.ACCURATE]
  },

  getSimulationParmeters(state: IBuildPlansState): IBaseSimulateCompensation {
    return state.buildPlan.simulationParameters
  },

  getPrintSites(state: IBuildPlansState): ISite[] {
    return state.printSites
  },

  getMachineConfigMaterialBinders(state: IBuildPlansState): MachineConfigMaterialBinder[] {
    return state.machineConfigMaterialBinders
  },

  getBindersBasedOnSelectedMaterial:
    (state) =>
      (selectedMaterialPk: VersionablePk): Binder[] => {
        let allMachineConfigMaterialBinders = state.machineConfigMaterialBinders
        if (selectedMaterialPk) {
          allMachineConfigMaterialBinders = allMachineConfigMaterialBinders.filter(
            (mmb) => mmb.materialId === selectedMaterialPk.id,
          )
        }
        const binders = allMachineConfigMaterialBinders.map((mmb) => {
          return {
            ...mmb.binder,
            pk: new VersionablePk(mmb.binder.id, mmb.binder.version),
          }
        })

        const uniqueBinders = Array.from(new Set(binders.map((a) => a.id))).map((id) => {
          return binders.find((a) => a.id === id)
        })

        return uniqueBinders as Binder[]
      },

  getAllBuildPlates(state: IBuildPlansState): IBuildPlate[] {
    return state.buildPlates
  },

  getBuildPlateByPk:
    (state) =>
      (buildPlatePk: VersionablePk): IBuildPlate => {
        if (!buildPlatePk) {
          return null
        }

        return buildPlatePk.version
          ? state.buildPlates.find((bp) => bp.id === buildPlatePk.id && bp.version === buildPlatePk.version)
          : VersionableModel.getLatestVersion(state.buildPlates.filter((bp) => bp.id === buildPlatePk.id))
      },

  getSelectedBuildPlate(state: IBuildPlansState, allGetters): IBuildPlate {
    return allGetters.getBuildPlateByPk(
      new VersionablePk(allGetters.getBuildPlan.buildPlateId, allGetters.getBuildPlan.buildPlateVersion),
    )
  },

  getAllBuildPlateMaterials(state: IBuildPlansState): IBuildPlateMaterial[] {
    return state.buildPlateMaterials
  },

  getAllBuildPlateMachineConfigs(state: IBuildPlansState): IBuildPlateMachineConfig[] {
    return state.buildPlateMachineConfigs
  },

  getAllActivePrintStrategies(state: IBuildPlansState): PrintStrategyInfo[] {
    return state.activePrintStrategies
  },

  getSelectedMachineConfigPk(state: IBuildPlansState) {
    return state.buildPlan && state.buildPlan.machineConfigId
      ? new VersionablePk(state.buildPlan.machineConfigId, state.buildPlan.machineConfigVersion)
      : null
  },

  getSelectedMaterialPk(state: IBuildPlansState, allGetters) {
    return allGetters.getSelectedMachineConfigPk && state.buildPlan && state.buildPlan.materialId
      ? new VersionablePk(state.buildPlan.materialId, state.buildPlan.materialVersion)
      : null
  },

  parameterSets(state: IBuildPlansState, allGetters): IPrintStrategyParameterSet[] {
    return allGetters.getBuildPlanPrintStrategy.printStrategyParameterSets
  },

  parameterSetsLatestVersions(state: IBuildPlansState, allGetters): IPrintStrategyParameterSet[] {
    return VersionableModel.getLatestVersions(allGetters.getBuildPlanPrintStrategy.printStrategyParameterSets)
  },

  getBPNameByBPId:
    (state: IBuildPlansState) =>
      (id: string): string => {
        const items = state.buildPlan.buildPlanItems
        const bpItem = items.find((item) => item.id === id)
        return bpItem.part.name
      },

  getFillTypes(): FillTypes[] {
    return [FillTypes.Solid, FillTypes.Lattice, FillTypes.LatticeAndSolid]
  },

  getContentViewMode(state: IBuildPlansState): ContentViewModeTypes {
    return state.contentViewMode
  },

  insights(state: IBuildPlansState): IBuildPlanInsight[] {
    return state.insights
  },

  pendingInsights(state: IBuildPlansState): IPendingInsights[] {
    return state.pendingInsights
  },

  labelInsights(state: IBuildPlansState): IBuildPlanInsight[] {
    return state.insights.filter((insight) => insight.tool === ToolNames.LABEL)
  },

  buildPlanItemById:
    (state) =>
      (id: string): IBuildPlanItem => {
        return state.buildPlan.buildPlanItems.find((bpItem: IBuildPlanItem) => bpItem.id === id)
      },

  getBuildPlanCost(state: IBuildPlansState, allGetters): BuildPlanCost {
    const buildPlanCost = JSON.parse(JSON.stringify(DEFAULT_BUILD_PLAN_COST))
    const machineConfig = allGetters.getMachineConfigByPk(allGetters.getSelectedMachineConfigPk)

    if (
      !state.buildPlan ||
      !state.buildPlan.cost ||
      !allGetters.getSelectedMachineConfigPk ||
      !machineConfig ||
      !machineConfig.id ||
      !allGetters.getSelectedMaterialPk
    ) {
      return buildPlanCost
    }

    const resultAdapter = CostEvaluationResultAdapterFactory.getAdapter(machineConfig.printingType)
    const cost: CostEvaluationResultViewModel = resultAdapter.adapt(state.buildPlan.cost)

    buildPlanCost.min = cost.totalBuildCostMin
    buildPlanCost.max = cost.totalBuildCostMax

    return buildPlanCost
  },

  getCalcCostInProgress(state: IBuildPlansState): boolean {
    return state.calcCostInProgress
  },

  getBuildPlanTime(state: IBuildPlansState, allGetters): BuildPlanTime | null {
    const machineConfig = allGetters.getMachineConfigByPk(allGetters.getSelectedMachineConfigPk)

    if (
      !state.buildPlan ||
      !state.buildPlan.cost ||
      !allGetters.getSelectedMachineConfigPk ||
      !machineConfig ||
      !machineConfig.id ||
      !allGetters.getSelectedMaterialPk
    ) {
      return null
    }

    const resultAdapter = CostEvaluationResultAdapterFactory.getAdapter(machineConfig.printingType)
    const cost: CostEvaluationResultViewModel = resultAdapter.adapt(state.buildPlan.cost)

    return {
      min: cost.machineBuildTimeMin,
      max: cost.machineBuildTimeMax,
    }
  },

  getBuildPlanItemDTOs(state: IBuildPlansState, allGetters, _, rootGetters): BuildPlanCostItemDTO[] {
    return allGetters.getAllBuildPlanItems.map((item: IBuildPlanItem): BuildPlanCostItemDTO => {
      const geometryProperties: IGeometryProperties = item.geometryProperties
      const dimensions = rootGetters['visualizationModule/visualization'].getBpItemDimensions(item.id)
      return (
        geometryProperties && {
          itemId: item.id,
          length: dimensions.yDimension,
          width: dimensions.xDimension,
          height: dimensions.zDimension,
          volume: geometryProperties.volume,
          surfaceArea: geometryProperties.surfaceArea,
          scaling: { x: item.part.scaleX, y: item.part.scaleY, z: item.part.scaleZ },
        }
      )
    })
  },

  getCommandType(state: IBuildPlansState): CommandType {
    const activeBuildPlanViewMode = state.selectedBuildPlanViewMode

    switch (activeBuildPlanViewMode) {
      case ViewModeTypes.Layout:
      case ViewModeTypes.Duplicate:
      case ViewModeTypes.Orientation:
      case null: // Remove tool is selected
        return CommandType.BuildPlanCommand
      case ViewModeTypes.Marking:
      case ViewModeTypes.Move:
      case ViewModeTypes.Nesting:
      case ViewModeTypes.Constrain:
      case ViewModeTypes.Support:
      case ViewModeTypes.PrintOrderPreview:
      case ViewModeTypes.Publish:
      case ViewModeTypes.Rotate:
      case ViewModeTypes.SimulationCompensation:
        return CommandType.ToolCommand
      default:
        return CommandType.BuildPlanCommand
    }
  },

  getDuplicateToolState(state: IBuildPlansState) {
    return state.duplicateTool
  },

  insightSettings(state: IBuildPlansState): IInsightSettings {
    return state.insightsSettings
  },

  activeInsightToolComponent: (state: IBuildPlansState): number => {
    return state.insightTool.activeComponent
  },

  insightsCount(state: IBuildPlansState): IInsightsCount {
    return state.insightTool.insightCount
  },

  isShownNoMaterialParamsTooltip(state: IBuildPlansState): boolean {
    return state.isShownNoMaterialParamsTooltip
  },

  printingType(state: IBuildPlansState): string {
    return state.buildPlan.modality
  },

  isLockedForViewer(state: IBuildPlansState, _, __, rootGetters) {
    let isLocked = true
    const plan: IBuildPlan | IIBCPlan = state.ibcPlan || state.buildPlan

    if (plan) {
      const user: IUser = rootGetters['user/getUserDetails']
      if (user) {
        const permissions: Permission[] = rootGetters['fileExplorer/getDirectOrInheritedPermissionsByItemPath'](
          plan.path,
        )
        const userPermission = permissions.find((permission) => permission.grantedTo === user.id)

        isLocked = userPermission ? userPermission.role === ItemPermissionsRole.Viewer : false
      }
    }

    return isLocked
  },

  isLockedForUser(state: IBuildPlansState, _, __, rootGetters) {
    let isLocked = true
    const plan: IBuildPlan | IIBCPlan = state.ibcPlan || state.buildPlan

    if (plan) {
      const user: IUser = rootGetters['user/getUserDetails']
      const item: FileExplorerItem = rootGetters['fileExplorer/find'](plan.id)
      if (item && user) {
        isLocked = isItemLockedForUser(item, user)
      }
    }

    return isLocked
  },

  // default option available if a job is not running, or simulation or slice results does not exist
  defaultPartParamOptionAvailable(state: IBuildPlansState, localGetters): boolean {
    const jobsBlockingDefaults = state.selectedBuildPlanJobs.filter((job) => {
      return (
        (job.jobType === JobType.SIMULATE ||
          job.jobType === JobType.COMPENSATE ||
          job.jobType === JobType.SIM ||
          job.jobType === JobType.COMP ||
          job.jobType === JobType.SLICE) &&
        [JobStatusCode.RUNNING, JobStatusCode.COMPLETE, JobStatusCode.WARNING].includes(job.code)
      )
    })
    return jobsBlockingDefaults.length === 0
  },

  // Global *read only* indicator
  isReadOnly(state: IBuildPlansState, localGetters): boolean {
    return localGetters.isLockedForViewer || localGetters.isLockedForUser
  },

  // Orient is *read only* if a job is running, or simulation or slice results exist
  isOrientReadOnly(state: IBuildPlansState, localGetters): boolean {
    if (localGetters.isReadOnly) {
      return localGetters.isReadOnly
    }

    return (
      localGetters.getSelectedBuildPlanRunningJobs.length > 0 ||
      localGetters.getSelectedBuildPlanFinalizingJobs.length > 0
    )
  },

  // // Support is *read only* if a job is running, or simulation or slice results exist
  isSupportReadOnly(state: IBuildPlansState, localGetters): boolean {
    if (localGetters.isReadOnly) {
      return localGetters.isReadOnly
    }

    return (
      localGetters.getSelectedBuildPlanRunningJobs.length > 0 ||
      localGetters.getSelectedBuildPlanFinalizingJobs.length > 0
    )
  },

  // // Constrain is *read only* if a job is running, or simulation or slice results exist
  isConstrainReadOnly(state: IBuildPlansState, localGetters): boolean {
    if (localGetters.isReadOnly) {
      return localGetters.isReadOnly
    }

    return (
      localGetters.getSelectedBuildPlanRunningJobs.length > 0 ||
      localGetters.getSelectedBuildPlanFinalizingJobs.length > 0
    )
  },

  // // Arrange is *read only* if a job is running, or simulation or slice results exist
  isArrangeReadOnly(state: IBuildPlansState, localGetters): boolean {
    if (localGetters.isReadOnly) {
      return localGetters.isReadOnly
    }

    return (
      localGetters.getSelectedBuildPlanRunningJobs.length > 0 ||
      localGetters.getSelectedBuildPlanFinalizingJobs.length > 0
    )
  },

  // Simulate is *read only* if a job is running or finalizing, or simulation or slice results exist
  isSimulateReadOnly(state: IBuildPlansState, localGetters, _, rootGetters): boolean {
    if (localGetters.isReadOnly) {
      return localGetters.isReadOnly
    }

    if (localGetters.getSelectedBuildPlanRunningJobs.length > 0) {
      return true
    }

    const getResultsAvailable = rootGetters['visualizationModule/getResultsAvailable']
    if (!!getResultsAvailable && getResultsAvailable.available !== undefined) {
      return getResultsAvailable.available
    }

    return localGetters.getSelectedBuildPlanFinalizingJobs.length > 0
  },

  // // Label is *read-only* if a job is running or a completed slicing job exists
  isLabelReadOnly(state: IBuildPlansState, localGetters): boolean {
    if (localGetters.isReadOnly) {
      return localGetters.isReadOnly
    }

    const slicingJob = localGetters.getSelectedBuildPlanFinalizingJobs.find((job) => job.jobType === JobType.SLICE)
    const hasRunningJobs = localGetters.getAllCurrentVariantRunningJobs.length > 0

    return slicingJob !== undefined || hasRunningJobs
  },

  isNewPrintOrderReadOnly(state: IBuildPlansState): boolean {
    return false
  },

  isSceneReadOnly(state: IBuildPlansState, localGetters) {
    return (
      localGetters.isReadOnly ||
      localGetters.isOrientReadOnly ||
      localGetters.isSupportReadOnly ||
      localGetters.isConstrainReadOnly ||
      localGetters.isArrangeReadOnly ||
      localGetters.isLabelReadOnly ||
      localGetters.isNewPrintOrderReadOnly ||
      localGetters.isSupportReadOnly
    )
  },

  isSelectedToolReadOnly(state: IBuildPlansState, localGetters) {
    switch (localGetters.getBuildPlanViewMode) {
      case ViewModeTypes.Move:
      case ViewModeTypes.Rotate:
      case ViewModeTypes.Part:
      case ViewModeTypes.Publish:
      case ViewModeTypes.Duplicate:
      case ViewModeTypes.TransferProps:
        return false
      case ViewModeTypes.Constrain:
        return localGetters.isConstrainReadOnly
      case ViewModeTypes.Orientation:
        return localGetters.isOrientReadOnly
      case ViewModeTypes.Support:
        return localGetters.isSupportReadOnly
      case ViewModeTypes.Nesting:
        return localGetters.isArrangeReadOnly
      case ViewModeTypes.Marking:
        return localGetters.isLabelReadOnly
      case ViewModeTypes.Print:
        return localGetters.isNewPrintOrderReadOnly
      case ViewModeTypes.SimulationCompensation:
        return localGetters.isSimulateReadOnly
    }
  },

  isNotAbleToCreateBuildPlanVariant(state: IBuildPlansState, allGetters, _, rootGetters): boolean {
    const user: IUser = rootGetters['user/getUserDetails']
    if (user) {
      return !state.canCreateVariants
    }
    return true
  },

  lockedBy(state: IBuildPlansState, allGetters, _, rootGetters): string {
    if (state.buildPlan) {
      const item: FileExplorerItem = rootGetters['fileExplorer/find'](state.buildPlan.id)
      if (item) {
        const whitelist = item.lockWhitelist ? item.lockWhitelist : []
        const userId = whitelist.length === 0 ? null : item.lockWhitelist[0].userId
        const currentUser: IUser = rootGetters['user/getUserDetails']
        if (userId && currentUser && currentUser.id !== userId) {
          const user = rootGetters['user/getUserById'](userId)
          const userName = user ? `${user.firstName} ${user.lastName}` : userId

          return userName
        }
      }
    }
  },

  getPartImportJobDescriptionByItemId:
    (state) =>
      (itemId: string): string => {
        const jobs = state.partImportJobs.filter((job) => job.itemId === itemId)
        return jobs[0] ? jobs[0].description : ''
      },

  previewPartGeometryProperties(state: IBuildPlansState) {
    return state.addPartTool.geometryProperties
  },

  insightSelectionEnabled(state): boolean {
    return state.insightTool.insightSelectionEnabled
  },

  printOrderNavigatedFrom(state): string {
    return state.previewPrintOrder.navigatedFrom
  },

  getIsVariantLockedForUserByVariantId:
    (state, localGetters, _, rootGetters) =>
      (variantId): boolean => {
        const variant = state.buildPlanVariants.find((v) => v.id === variantId)
        const user: IUser = rootGetters['user/getUserDetails']
        if (user) {
          return isItemLockedForUser(variant, user)
        }

        return true
      },

  getLockedByForVariant:
    (state, localGetters, _, rootGetters) =>
      (variantId): string => {
        let userName = null
        const variant = state.buildPlanVariants.find((v) => v.id === variantId)
        const lockWhitelist = variant.lockWhitelist && variant.lockWhitelist.length > 0 ? variant.lockWhitelist : null
        const userId = lockWhitelist ? lockWhitelist[0].userId : null
        if (userId) {
          const user = rootGetters['user/getUserById'](userId)
          userName = user ? `${user.firstName} ${user.lastName}` : userId
        }
        return userName
      },

  getAllVariantsJobs(state: IBuildPlansState): IJob[] {
    return state.buildPlanVariantJobs
  },

  getRunningJobsByVariantId:
    (state) =>
      (variantId: string): IJob[] => {
        const variantJobs = state.buildPlanVariantJobs.filter(
          (job) => job.itemId === variantId || job.relatedPlanId === variantId,
        )
        return variantJobs.filter((job) => job.code === JobStatusCode.QUEUED || job.code === JobStatusCode.RUNNING)
      },

  getRunningSimulationJobsByVariantId:
    (state, localGetters) =>
      (variantId: string): IJob[] => {
        const runningJobs: IJob[] = localGetters.getRunningJobsByVariantId(variantId)
        return runningJobs.filter((job) => job.jobType === JobType.SIMULATE || job.jobType === JobType.COMPENSATE)
      },

  getRunningMeshJobsByVariantId:
    (state, localGetters) =>
      (variantId: string): IJob[] => {
        const runningJobs: IJob[] = localGetters.getRunningJobsByVariantId(variantId)
        return runningJobs.filter((job) => job.jobType === JobType.MESH)
      },

  getRunningSlicingJobsByVariantId:
    (state, localGetters) =>
      (variantId: string): IJob[] => {
        const runningJobs: IJob[] = localGetters.getRunningJobsByVariantId(variantId)
        return runningJobs.filter((job) => job.jobType === JobType.SLICE)
      },

  getRunningNestingJobsByVariantId:
    (state, localGetters) =>
      (variantId: string): IJob[] => {
        const runningJobs: IJob[] = localGetters.getRunningJobsByVariantId(variantId)
        return runningJobs.filter((job) => job.jobType === JobType.NEST)
      },

  getPrintJobsByVariantId:
    (state) =>
      (variantId: string): IJob[] => {
        const variantJobs = state.buildPlanVariantJobs.filter((job) => job.itemId === variantId)
        return variantJobs.filter((job) => job.jobType === JobType.PRINT)
      },

  getAllCurrentVariantRunningJobs(state: IBuildPlansState, localGetters) {
    if (state.buildPlan) {
      const variantId = state.buildPlan.id

      return localGetters.getRunningJobsByVariantId(variantId)
    }
    return []
  },

  getCompleteVariantSlicingJobs:
    (state) =>
      (variantId: string): IJob[] => {
        return state.buildPlanVariantJobs
          .filter((job) => job.itemId === variantId)
          .filter((job) => job.jobType === JobType.SLICE)
          .filter((job) => job.code === JobStatusCode.COMPLETE || job.code === JobStatusCode.WARNING)
      },

  getCompleteVariantPublishJobs:
    (state) =>
      (variantId: string): IJob[] => {
        return state.buildPlanVariantJobs
          .filter((job) => job.relatedPlanId === variantId)
          .filter((job) => job.jobType === JobType.PUBLISH)
          .filter((job) => job.code === JobStatusCode.COMPLETE || job.code === JobStatusCode.WARNING)
      },

  getCompleteVariantIbcPublishJobs:
    (state) =>
      (variantId: string): IJob[] => {
        return state.ibcPlanJobs
          .filter((job) => job.relatedPlanId === variantId)
          .filter((job) => job.jobType === JobType.IBC_PUBLISH)
          .filter((job) => job.code === JobStatusCode.COMPLETE || job.code === JobStatusCode.WARNING)
      },

  getCompleteAndRunningSlicingJobs:
    (state) =>
      (variantId: string): IJob[] => {
        const runningAndCompleteCodes = [
          JobStatusCode.COMPLETE,
          JobStatusCode.RUNNING,
          JobStatusCode.QUEUED,
          JobStatusCode.PENDING,
        ]
        return state.buildPlanVariantJobs
          .filter((job) => job.itemId === variantId)
          .filter((job) => job.jobType === JobType.SLICE)
          .filter((job) => runningAndCompleteCodes.includes(job.code))
      },

  isVariantProcessing(state: IBuildPlansState) {
    return state.isVariantProcessing
  },

  isVariantCreating(state: IBuildPlansState) {
    return state.isVariantProcessing
  },

  isBuildPlanDisposing(state: IBuildPlansState) {
    return state.isBuildPlanDisposing
  },

  getBuildPlanDisposePromise(state: IBuildPlansState) {
    return state.buildPlanDisposePromise
  },

  isBuildPlanContainsSheetBodies(state: IBuildPlansState): boolean {
    return state.buildPlan.buildPlanItems.some((bpItem) => bpItem.part.hasSheetBodies)
  },

  isBuildPlanContainsProductionOrCouponBodies(state: IBuildPlansState): boolean {
    const productionOrCouponBodies = []
    state.buildPlan.buildPlanItems.forEach((bpItem) => {
      const result = bpItem.partProperties.filter((partProperty) => {
        return [GeometryType.Production, GeometryType.Coupon].includes(partProperty.type)
      })
      productionOrCouponBodies.push(...result)
    })
    return productionOrCouponBodies.length > 0
  },

  getNumProductionBodies(state: IBuildPlansState): number {
    const productionBodies = []
    state.buildPlan.buildPlanItems.forEach((bpItem) => {
      const result = bpItem.partProperties.filter((partProperty) => {
        return [GeometryType.Production].includes(partProperty.type)
      })
      productionBodies.push(...result)
    })
    return productionBodies.length
  },

  getBinderJetProductionSets(state: IBuildPlansState): IProductionSet[] {
    const productionSets = (state.productionSets as IProductionSet[]).filter(
      (productionSet) => productionSet.modality === PrintingTypes.BinderJet,
    )

    return productionSets
  },

  variantHasRemovedParent:
    (state) =>
      (parentVariantId: string): boolean => {
        const parentVariant = state.buildPlanVariants.find((variant) => variant.id === parentVariantId)

        if (!parentVariant) {
          // parent is folder
          return false
        }
        return parentVariant.isRemoved
      },

  isSinterPlan(state: IBuildPlansState) {
    return state.buildPlan && state.buildPlan.subType === ItemSubType.SinterPlan
  },

  isBuildPlan(state: IBuildPlansState): boolean {
    return state.buildPlan && state.buildPlan.subType === ItemSubType.None
  },

  /**
   * Single mode activates only on BinderJet or SinterPlan
   */
  isSinglePartPropertyMode(state: IBuildPlansState) {
    return (
      state.buildPlan &&
      (state.buildPlan.modality === PrintingTypes.BinderJet || state.buildPlan.subType === ItemSubType.SinterPlan)
    )
  },

  isSelectedSinterPart(state: IBuildPlansState): boolean {
    return (
      state.addPartTool.selectedParts.length && state.addPartTool.selectedParts[0].subType === ItemSubType.SinterPart
    )
  },

  isSelectedIbcPart(state: IBuildPlansState): boolean {
    return state.addPartTool.selectedParts.length && state.addPartTool.selectedParts[0].subType === ItemSubType.IbcPart
  },

  displayToolbarStates(state: IBuildPlansState): Array<{ buildPlanId: string; state: IDisplayToolbarState }> {
    return state.displayToolbarStates
  },

  displayToolbarStateByVariantId:
    (state) =>
      (buildPlanId: string): IDisplayToolbarState => {
        const VIEW_MODES_TO_CHECK = [ViewModeTypes.SimulationCompensation]

        let displayToolbarState
        if (VIEW_MODES_TO_CHECK.includes(state.selectedBuildPlanViewMode)) {
          displayToolbarState = state.displayToolbarStates.find(
            (s) => s.buildPlanId === buildPlanId && s.viewMode === state.selectedBuildPlanViewMode,
          )
        } else {
          displayToolbarState = state.displayToolbarStates.find((s) => s.buildPlanId === buildPlanId)
        }

        // we directly modify the returned value later, so need to clone defaultDisplayToolbarState before returning
        return displayToolbarState ? displayToolbarState.state : JSON.parse(JSON.stringify(defaultDisplayToolbarState))
      },

  getCollaboratorsCount(state: IBuildPlansState): number {
    return state.collaboratorsCount
  },

  ibcDisplayToolbarStateByVariantId:
    (state) =>
      (ibcPlanId: string): IIBCDisplayToolbarState => {
        const displayToolbarState = state.ibcDisplayToolbarStates.find((s) => s.ibcPlanId === ibcPlanId)
        // we directly modify the returned value later, so need to clone defaultIBCDisplayToolbarState before returning
        return displayToolbarState ? displayToolbarState.state : JSON.parse(JSON.stringify(defaultIBCDisplayToolbarState))
      },

  getBuildPlanPrintStrategy(state: IBuildPlansState): BuildPlanPrintStrategyDto {
    return state.buildPlanPrintStrategy
  },

  getBuildPlanPrintStrategyPk(state: IBuildPlansState): VersionablePk {
    const printStrategy = state.buildPlanPrintStrategy
    if (!printStrategy) {
      return null
    }
    return new VersionablePk(printStrategy.printStrategyId, printStrategy.printStrategyVersion)
  },

  getBuildPlanProductionSet: (state) => (): IProductionSet => {
    return state.buildPlanPrintStrategy.productionSet
  },

  getBuildPlanProductionSetId: (state) => (): number => {
    return state.buildPlanPrintStrategy.productionSet.id
  },

  getAddPartToolState(state: IBuildPlansState): AddPartToolState {
    return state.addPartTool
  },

  getAddPartToolSelectedParts(state: IBuildPlansState): PartListItemViewModel[] {
    return state.addPartTool.selectedParts
  },

  isBuildPlanContainsCouponBody(state: IBuildPlansState): boolean {
    return state.buildPlan.buildPlanItems.some((bpItem) =>
      bpItem.partProperties.some((partProperty) => partProperty.type === GeometryType.Coupon),
    )
  },

  getSelectedBuildPlanPrintStrategy(state: IBuildPlansState): BuildPlanPrintStrategyDto {
    return state.selectedBuildPlanPrintStrategy
  },

  getSelectedPrintStrategyDefaultByGeometryType:
    (state) =>
      (type: GeometryType): IPrintStrategyParameterSet => {
        const { couponId, couponVersion, productionId, productionVersion, supportAmpLineId, supportAmpLineVersion } =
          state.selectedBuildPlanPrintStrategy.defaults

        const newPrintStrategyParameterSets: IPrintStrategyParameterSet[] =
          state.selectedBuildPlanPrintStrategy.printStrategyParameterSets
        let defaultPrintStretegyParameterSet = null

        switch (type) {
          case GeometryType.Production:
            defaultPrintStretegyParameterSet = newPrintStrategyParameterSets.find(
              (ps) => ps.id === productionId && ps.version === productionVersion,
            )
            break
          case GeometryType.Coupon:
            defaultPrintStretegyParameterSet = newPrintStrategyParameterSets.find(
              (ps) => ps.id === couponId && ps.version === couponVersion,
            )
            break
          case GeometryType.Support:
            defaultPrintStretegyParameterSet = newPrintStrategyParameterSets.find(
              (ps) => ps.id === supportAmpLineId && ps.version === supportAmpLineVersion,
            )
            break
        }

        return defaultPrintStretegyParameterSet
      },

  isRecoaterDirectionChanged:
    (state, allGetters) =>
      (machineConfigPk: VersionablePk): boolean => {
        const newRecoaterDirection = allGetters.getMachineConfigByPk(machineConfigPk).recoaterDirection
        const currentRecoaterDirection = allGetters.getMachineConfigByPk(
          new VersionablePk(state.buildPlan.machineConfigId, state.buildPlan.machineConfigVersion),
        ).recoaterDirection

        return newRecoaterDirection !== currentRecoaterDirection
      },

  areSelectedPrintStrategyDefaultsValid(state, allGetters) {
    const newDefaultProductionPrintStrategyParameterSet = allGetters.getSelectedPrintStrategyDefaultByGeometryType(
      GeometryType.Production,
    )
    const newDefaultCouponPrintStrategyParameterSet = allGetters.getSelectedPrintStrategyDefaultByGeometryType(
      GeometryType.Coupon,
    )
    const newDefaultSupportPrintStrategyParameterSet = allGetters.getSelectedPrintStrategyDefaultByGeometryType(
      GeometryType.Support,
    )

    const existProductionBuildPlanItem = state.buildPlan.buildPlanItems.some(
      (bpItem) => bpItem.partProperties[0].type === GeometryType.Production,
    )
    const existCouponBuildPlanItem = state.buildPlan.buildPlanItems.some(
      (bpItem) => bpItem.partProperties[0].type === GeometryType.Coupon,
    )
    const existSupportBuildPlanItem = state.buildPlan.buildPlanItems.some(
      (bpItem) => bpItem.partProperties[0].type === GeometryType.Support,
    )

    return !(
      (!newDefaultProductionPrintStrategyParameterSet && existProductionBuildPlanItem) ||
      (!newDefaultCouponPrintStrategyParameterSet && existCouponBuildPlanItem) ||
      (!newDefaultSupportPrintStrategyParameterSet && existSupportBuildPlanItem)
    )
  },

  getRequiresLabelSetUpdates(state: IBuildPlansState) {
    return state.requiresLabelSetUpdates
  },

  isSinterPartSelected(state: IBuildPlansState, localGetters): boolean {
    return localGetters.getSelectedBuildPlanItems.some(
      (selectedBpItem) => selectedBpItem.part && selectedBpItem.part.subType === ItemSubType.SinterPart,
    )
  },

  /**
   * Component is visible if all part is visible or hiddenBodies doesn't include this component
   * @param {string} bpItemId - The build plan item ID (part ID).
   * @param {string} geometryId - The component ID.
   */
  isBuildPlanItemComponentVisible:
    (state) =>
      (bpItemId: string, geometryId: string): boolean => {
        let isVisible = true
        const buildPlanItem = state.buildPlan.buildPlanItems.find((bpItem) => bpItem.id === bpItemId)
        if (buildPlanItem) {
          isVisible = buildPlanItem.visibility === Visibility.Visible && !buildPlanItem.hiddenBodies.includes(geometryId)
        }

        return isVisible
      },

  isBuildPlanUpdating(state): boolean {
    return state.isBuildPlanUpdating
  },

  isPartElevationUpdating(state): boolean {
    return state.numberOfPendingPartElevationRequests > 0
  },

  printOrderLabelSearchInput(state): string {
    return state.previewPrintOrder.labelSearchInput
  },

  isLabelToolPreparing(state): boolean {
    return state.isLabelToolPreparing
  },

  isLabelRestoreInProgress(state): boolean {
    return state.isLabelRestoreInProgress
  },

  getModelGeometryPropertiesFromCache:
    (state) =>
      (documentComponentsID: string): IMeshGeometryProperties => {
        return state.geometryPropertiesCache.get(documentComponentsID)
      },

  activeToolHasUnsavedData(state: IBuildPlansState): boolean {
    return state.activeToolHasUnsavedData
  },

  ibcPlanNavigatedFrom(state): {
    name: RouterNames
    params: {
      id?: string
      itemId?: string
    }
  } {
    return state.editIbcPlan.navigatedFrom
  },

  getIBCPlan(state: IBuildPlansState): IIBCPlan {
    return state.ibcPlan
  },

  getIbcPlanJobs(state: IBuildPlansState): IJob[] {
    return state.ibcPlanJobs
  },

  isToolMaskDisplaying(state: IBuildPlansState): boolean {
    return state.isToolMaskDisplaying
  },

  getLoadingItemsData(state) {
    return state.loadingItemsData
  },

  isSupportToolPreparing(state: IBuildPlansState): boolean {
    return state.isSupportToolPreparing
  },

  isTransferToolBusy(state: IBuildPlansState): boolean {
    return state.isTransferToolBusy
  },
}
