
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class ProgressModal extends Vue {
  @Prop({ default: false }) show: boolean
  @Prop({ required: true }) title: string

  // This template must support the following values: "{current} {total}"
  @Prop({ required: true }) contentTemplate: string
  @Prop({ required: true }) current: number
  @Prop({ required: true }) total: number
  @Prop({ default: '' }) footer: string
  @Prop({
    default: () => ({ width: 475, showOverlay: false, overlayTop: '0px' }),
  })
  options: { width: number; showOverlay: number; overlayTop: string }

  get content() {
    return this.$t(this.contentTemplate, {
      current: this.current < this.total ? this.current + 1 : this.current,
      total: this.total,
    })
  }

  get progressValue() {
    if (!this.total) return 0
    return (this.current * 100) / this.total
  }

  get overlayZIndex() {
    return this.options.showOverlay ? this.options.showOverlay : -1
  }
}
