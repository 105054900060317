
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { Prop, Watch } from 'vue-property-decorator'
import { InsightsHelper } from '@/utils/insights/InsightsHelper'
import ByInsight from '@/components/layout/buildPlans/insight/group/ByInsight.vue'
import {
  IBuildPlanInsight,
  IInsightSettings,
  InsightGroups,
  IPendingInsights,
} from '@/types/BuildPlans/IBuildPlanInsight'
import CommunicationService from '@/services/CommunicationService'
import { BrokerEvents } from '@/types/Common/BrokerEvents'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component({
  components: {},
})
export default class BuildPlanInsightsPanel extends Vue {
  //region Build Plan store
  @buildPlansStore.Getter insights: IBuildPlanInsight[]
  @buildPlansStore.Getter pendingInsights: IPendingInsights[]
  @buildPlansStore.Getter insightSettings: IInsightSettings
  @buildPlansStore.Getter getIBCPlan: IIBCPlan

  @buildPlansStore.Action fetchInsightsByBuildPlanId: (payload: {
    buildPlanId: string
    changeState: boolean
  }) => Promise<IBuildPlanInsight[]>
  @buildPlansStore.Action createInsightMultiple: (payload: {
    insights: IBuildPlanInsight[]
    stateOnly: boolean
  }) => void
  @buildPlansStore.Action deleteInsightMultiple: (payload: { insightsIds: string[]; stateOnly: boolean }) => void

  @buildPlansStore.Mutation clearInsights: () => void
  @buildPlansStore.Mutation removeInsightsByInsights: (insightsToRemove: IBuildPlanInsight[]) => void
  //endregion Build Plan store

  @Prop({ default: null }) buildPlanId: string
  @Prop({ default: true }) analyzeInsights: boolean

  public storingData: boolean = false
  public needsCheck: boolean = false
  public insightGroups = InsightGroups
  connector: CommunicationService = null

  get buildPlanInsights(): IBuildPlanInsight[] {
    return this.insights
  }

  insightGroupComponent() {
    return ByInsight
  }

  beforeMount() {
    this.connector = CommunicationService.getConnector()
  }

  async mounted() {
    this.loadInsightsData()
    this.connector.subscribe(BrokerEvents.InsightsCreated, this.loadInsightsData)
  }

  destroyed() {
    this.connector.unsubscribe(BrokerEvents.InsightsCreated, this.loadInsightsData)
  }

  async loadInsightsData() {
    let id: string

    if (this.getIBCPlan) {
      id = this.getIBCPlan.id
    } else {
      id = this.buildPlanId || this.$route.params.id
    }

    if (!id) return

    return this.fetchInsightsByBuildPlanId({ buildPlanId: id, changeState: true })
  }

  @Watch('pendingInsights')
  async onPendingInsights() {
    if (!this.pendingInsights || !this.analyzeInsights) {
      return
    }

    if (!this.storingData) {
      this.storingData = true
      for (const pendingInsight of this.pendingInsights) {
        const insightHelper = new InsightsHelper(this.insights, pendingInsight.insights, pendingInsight.tool)
        const { add, remove } = insightHelper.processPending()

        if (remove && remove.length) {
          const insightsWithIdIds = []
          const insightsWithoutId = []
          remove.forEach((insight) => {
            insight.id ? insightsWithIdIds.push(insight.id) : insightsWithoutId.push(insight)
          })

          if (insightsWithIdIds.length) {
            await this.deleteInsightMultiple({ insightsIds: insightsWithIdIds, stateOnly: pendingInsight.stateOnly })
          }

          // insights without id should be removed in another way
          if (insightsWithoutId.length) {
            this.removeInsightsByInsights(insightsWithoutId)
          }
        }

        if (add && add.length) {
          await this.createInsightMultiple({ insights: add, stateOnly: pendingInsight.stateOnly })
        }

        this.storingData = false
        if (this.needsCheck) {
          this.needsCheck = false
          await this.onPendingInsights()
        }
      }
    } else {
      this.needsCheck = true
    }
  }

  beforeDestroy() {
    this.clearInsights()
  }
}
