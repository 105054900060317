export type SearchResultSnapshot = {
  id: string
  name: string
  wayOfFinding: SearchResultWayOfFinding
}

export enum SearchResultWayOfFinding {
  ByName,
  ByLabel,
  ByUserInfo,
  ByPrintStrategy,
}
