
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'

const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component({
  components: {},
})
export default class BuildPlateCanvas extends Vue {
  @visualizationStore.Mutation initBuildPlatformCanvas: (payload: { buildPlateId: string; canvasId: string }) => void

  @Prop() buildPlateId: string
  @Prop() canvasKey: number

  mounted() {
    this.initBuildPlatformCanvas({
      buildPlateId: this.buildPlateId,
      canvasId: `build-plate-canvas-${this.canvasKey}`,
    })
  }
}
