
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import { SimulationReadyStatuses } from '@/types/Sites/SimulationReady'
import { TranslateResult } from 'vue-i18n'

@Component({
  components: {},
})
export default class SimulationReady extends Vue {
  @Prop() value: SimulationReadyStatuses

  get icon(): string {
    switch (this.value) {
      case SimulationReadyStatuses.All:
        return 'mdi-checkbox-blank-circle'
      case SimulationReadyStatuses.None:
        return 'mdi-checkbox-blank-circle-outline'
      case SimulationReadyStatuses.Some:
        return 'mdi-circle-double'
    }
  }

  get text(): string | TranslateResult {
    switch (this.value) {
      case SimulationReadyStatuses.All:
        return this.$root.$t('tenantAdminContentTableHeaders.printStrategyList.all')
      case SimulationReadyStatuses.None:
        return this.$root.$t('tenantAdminContentTableHeaders.printStrategyList.none')
      case SimulationReadyStatuses.Some:
        return this.$root.$t('tenantAdminContentTableHeaders.printStrategyList.some')
    }
  }
}
