
import StoresNamespaces from '@/store/namespaces'
import { IGeometryProperties } from '@/types/BuildPlans/IBuildPlan'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { formatDecimal } from '@/utils/number'
import { BoundingInfo } from '@babylonjs/core/Culling'
import i18n from '@/plugins/i18n'
import Icon, { getIconName } from '@/components/icons/Icon.vue'

const partsStore = namespace(StoresNamespaces.Parts)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component({
  components: {
    Icon,
  },
})
export default class PartPreviewFooter extends Vue {
  @partsStore.Getter('getPreviewedPartGeometryProps') geometryProps: IGeometryProperties
  @visualizationStore.Getter getPartsBoundingBox: () => Promise<BoundingInfo>

  partSize: string = ''
  partSurfaceArea: string = ''
  partVolume: string = ''

  async mounted() {
    this.init()
  }

  @Watch('geometryProps')
  async init() {
    if (this.geometryProps) {
      this.setPartSize(await this.getPartsBoundingBox())
      this.setPartVolume(this.geometryProps)
      this.setPartSurfaceArea(this.geometryProps)
    }
  }

  getIconName(view: string) {
    return getIconName(view)
  }

  setPartSize(boundingInfo: BoundingInfo) {
    if (boundingInfo.boundingBox.minimum && boundingInfo.boundingBox.maximum) {
      const minimum = boundingInfo.boundingBox.minimum
      const maximum = boundingInfo.boundingBox.maximum

      const xDimensionString = formatDecimal(Math.abs(maximum.x - minimum.x)).toLocaleString()
      const yDimensionString = formatDecimal(Math.abs(maximum.y - minimum.y)).toLocaleString()
      const zDimensionString = formatDecimal(Math.abs(maximum.z - minimum.z)).toLocaleString()
      this.partSize = `${xDimensionString} X ${yDimensionString} X ${zDimensionString} ${i18n.t('millimeterAbbr')}`
    } else {
      this.partSize = `N/A`
    }
  }

  setPartSurfaceArea(geometryProperties: IGeometryProperties) {
    if (geometryProperties.surfaceArea > 0) {
      this.partSurfaceArea = `${formatDecimal(geometryProperties.surfaceArea, 0).toLocaleString()} ${i18n.t(
        'millimeterSquaredAbbr',
      )}`
    } else {
      this.partSurfaceArea = '-'
    }
  }

  setPartVolume(geometryProperties: IGeometryProperties) {
    if (geometryProperties.volume > 0) {
      this.partVolume = `${formatDecimal(geometryProperties.volume, 0).toLocaleString()} ${i18n.t(
        'millimeterCubedAbbr',
      )}`
    } else {
      this.partVolume = '-'
    }
  }
}
