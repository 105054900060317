
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ItemTypeSelector from '@/components/controls/FileExplorer/ItemTypeSelector.vue'
import ActionBar from '@/components/layout/FileExplorer/ActionBar.vue'
import DetailsPanel from '@/components/layout/FileExplorer/DetailsPanel.vue'
import InfinityScrollArea from '@/components/layout/FileExplorer/InfinityScrollArea.vue'
import MoveErrorModal from '@/components/layout/FileExplorer/MoveErrorModal.vue'
import MoveOrCopyModal from '@/components/layout/FileExplorer/MoveOrCopyModal.vue'
import MoveToTrashItemErrorModal from '@/components/layout/FileExplorer/MoveToTrashItemErrorModal.vue'
import MoveToTrashItemModal from '@/components/layout/FileExplorer/MoveToTrashItemModal.vue'
import RenameItemModal from '@/components/layout/FileExplorer/RenameItemModal.vue'
import ShareItemModal from '@/components/layout/FileExplorer/ShareItemModal.vue'
import ItemListRow from '@/components/layout/FileExplorer/Table/ItemListRow.vue'
import AllFilesSearchViewMixin from '@/components/layout/FileExplorer/Table/mixins/AllFilesSearchViewMixin'
import FileExplorerRedirectionMixin from '@/components/layout/FileExplorer/Table/mixins/FileExplorerRedirectionMixin'
import TableHeader from '@/components/layout/FileExplorer/Table/TableHeader.vue'
import VariantIsLockedModal from '@/components/layout/FileExplorer/VariantIsLockedModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import SsoMigrationModal from '@/components/modals/SsoMigrationModal.vue'
import { INCORRECT_UUID_MESSAGE_ERROR, ROOT_FOLDER_ID } from '@/constants'
import { RouterNames, RouterPaths } from '@/router'
import messageService from '@/services/messageService'
import StoresNamespaces from '@/store/namespaces'
import { IBuildPlanLockDto } from '@/types/BuildPlans/IBuildPlan'
import { DataState } from '@/types/Common/DataState'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { TableFilterParams } from '@/types/FileExplorer/FilterParamsKey'
import { ItemAction } from '@/types/FileExplorer/ItemAction'
import { ItemType } from '@/types/FileExplorer/ItemType'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import { TableSortParams } from '@/types/FileExplorer/SortParamsKey'
import { DetailsPanelViewMode, ViewMode } from '@/types/FileExplorer/ViewMode'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { IUser } from '@/types/User/IUser'
import { debounce } from '@/utils/debounce'
import isEqual from 'lodash/isEqual'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const partsStore = namespace(StoresNamespaces.Parts)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const userStore = namespace(StoresNamespaces.User)

const SCROLL_TO_NEW_ITEM_TIMEOUT = 300

@Component({
  components: {
    TableHeader,
    ItemListRow,
    RenameItemModal,
    DetailsPanel,
    MoveErrorModal,
    MoveToTrashItemModal,
    ShareItemModal,
    ItemTypeSelector,
    ActionBar,
    MoveToTrashItemErrorModal,
    ConfirmModal,
    VariantIsLockedModal,
    MoveOrCopyModal,
    InfinityScrollArea,
    SsoMigrationModal,
  },
})
export default class AllFiles extends Mixins(AllFilesSearchViewMixin, FileExplorerRedirectionMixin) {
  @fileExplorerStore.Action('fetchItems') fetchAllItems: () => Promise<FileExplorerItem[]>
  @fileExplorerStore.Action getGetRunningAndFailedJobsByItemIds: Function
  @fileExplorerStore.Action getTableState: Function
  @fileExplorerStore.Action clearPaginatedData: Function

  @partsStore.Action actualizeParts: (items?: FileExplorerItem[]) => Promise<void>
  @partsStore.Action fetchAllParts: Function
  @partsStore.Action fetchAllSinterParts: Function
  @partsStore.Action fetchAllIbcParts: () => Promise<IPartDto[]>

  @fileExplorerStore.Getter getItemsWithoutFolders: FileExplorerItem[]
  @fileExplorerStore.Getter isItemFavorite: (itemId: string) => boolean
  @fileExplorerStore.Getter getLastAddedItem: FileExplorerItem
  @fileExplorerStore.Getter isForceUpdateDetails: () => boolean
  @fileExplorerStore.Getter getNumberOfHiddenItemsInCurrentFolder: () => number
  @fileExplorerStore.Getter isShownLockedBuildPlanModal: boolean
  @fileExplorerStore.Getter getLockItemInfo: { editVariant: IBuildPlanLockDto; previewVariant: IBuildPlanLockDto }
  @fileExplorerStore.Getter getSortParams: () => TableSortParams
  @fileExplorerStore.Getter getFilterParams: () => TableFilterParams
  @fileExplorerStore.Getter isLoading: boolean
  @fileExplorerStore.Getter isShownCreateBuildPlanDialog: boolean
  @fileExplorerStore.Getter isShownCreateSinterPlanDialog: boolean
  @fileExplorerStore.Getter isShownCreateFolderModal: boolean
  @fileExplorerStore.Getter('find') findItemById: (itemId: string) => FileExplorerItem

  @visualizationStore.Getter getPreviewCreationPromise: { promise: Promise<void>; done: Function }

  @fileExplorerStore.Mutation setLastAddedItem: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation clearItems: () => void
  @fileExplorerStore.Mutation clearFolders: () => void
  @fileExplorerStore.Mutation setNumberOfHiddenItemsInCurrentFolder: (num: number) => void
  @fileExplorerStore.Mutation setDataState: (dataState: { state: DataState; errorText?: string }) => void

  @userStore.Getter getUserDetails: IUser

  tableViewMode = ViewMode
  types = ItemType
  routeQueryTimeout = null
  isFolderNoItems = false
  isFolderLoadingItems = false
  isFolderHiddenItems = false

  scrollToCopiedItem = debounce(SCROLL_TO_NEW_ITEM_TIMEOUT, this.scrollToNewItem)

  $refs!: {
    confirm: InstanceType<typeof ConfirmModal>
    ssoMigrationModal: InstanceType<typeof SsoMigrationModal>
    fileListContent: HTMLElement
  }

  get isActionDialogOpened() {
    return (
      this.isShownCreateBuildPlanDialog ||
      this.isShownCreateSinterPlanDialog ||
      this.isShownCreateFolderModal ||
      this.isShownShareItemModal ||
      this.isShownMoveOrCopyModal ||
      this.getRenameDialogState
    )
  }

  async beforeMount() {
    return this.beforeMountImplementation()
  }

  async beforeMountImplementation() {
    this.setClickedNameItem(null)
    this.setNumberOfHiddenItemsInCurrentFolder(0)
    this.setDetailsPanelMode(DetailsPanelViewMode.Default)
    this.unselectAllItems()
    await this.clearPaginatedData()
    this.clearItems()
    if (this.getPreviewCreationPromise) {
      this.setDataState({ state: DataState.Loading })
      await this.getPreviewCreationPromise.promise
      this.setDataState({ state: DataState.Loaded })
    }

    let itemIds = []
    const newRootId = this.$route.params.itemId

    if (newRootId === undefined) {
      return
    }

    const config = await this.getTableState()
    const isListViewMode = config && config.viewMode === ViewMode.List

    if (isListViewMode) {
      await this.fetchAllItems()
    } else {
      if (newRootId !== ROOT_FOLDER_ID) {
        const newRootItem = this.findItemById(newRootId) || (await this.fetchItemById(newRootId))
        if (newRootItem.isRemoved) {
          // @ts-ignore
          this.$router.safePush(RouterPaths.DefaultFileExplorer)
          return
        }

        this.setRoot(newRootItem)
        await this.setLastAction({ itemId: newRootId, action: ItemAction.Opened })
        await this.getItemPermissions(newRootId)
      } else {
        this.setRoot(null)
      }

      await this.fetchItemsInFolder({ folderId: newRootId, abortIfRootChanged: true })
    }

    await Promise.all([
      this.fetchAllSinterParts(),
      this.fetchAllParts(),
      this.fetchAllIbcParts(),
      this.actualizeParts(),
    ])

    if (this.isError) {
      const errMsg =
        this.getErrorText === INCORRECT_UUID_MESSAGE_ERROR
          ? `${this.$i18n.t('incorrectFolderId')}: ${newRootId}`
          : this.getErrorText
      messageService.showErrorMessage(errMsg)

      this.setRoot(null)
      if (isListViewMode) {
        await this.fetchAllItems()
      } else {
        await this.fetchItemsInFolder({ folderId: ROOT_FOLDER_ID, abortIfRootChanged: true })
      }

      await this.actualizeParts()

      itemIds = this.items.map((item: FileExplorerItem) => item.id)
      await this.getGetRunningAndFailedJobsByItemIds(itemIds)
      return
    }

    if (newRootId === ROOT_FOLDER_ID || isListViewMode) {
      itemIds = this.items.map((item: FileExplorerItem) => item.id)
      await this.getGetRunningAndFailedJobsByItemIds(itemIds)
      this.setLockModalData()
      return
    }

    if (!this.getSelectedItems.length) {
      await this.fetchItemDetails({ itemId: newRootId, itemType: ItemType.Folder })

      if (this.getRootItem && this.getRootItem.isShared) {
        const isAllowed = await this.checkAccessToEndCollaboration([this.getRootItem.id])
        this.setIsAllowedToEndCollaboration(isAllowed)
      }
    }

    this.setDetailsPanelMode(DetailsPanelViewMode.Folder)

    itemIds = this.items.map((item: FileExplorerItem) => item.id)
    await this.getGetRunningAndFailedJobsByItemIds(itemIds)

    this.checkRouteQuery()

    if (!this.getSelectedItem && this.getClickedNameItem) {
      await this.onItemListRowClick({ item: this.getClickedNameItem, ctrlKey: false, shiftKey: false })
    }

    this.setLockModalData()
  }

  async mounted() {
    const user = this.getUserDetails
    const isSsoMigrationNeeded =
      user.oldUserId && !(user.ssoMigratedTenants && user.ssoMigratedTenants.includes(user.tenant))

    if (isSsoMigrationNeeded) {
      // Open migration modal
      await this.$refs.ssoMigrationModal.open()

      // Refresh items after User ID migrated to SSO
      await this.beforeMountImplementation()
    }
  }

  /**
   * Eliminates visual blinking of folder content title for loading/hidden/no items
   */
  @Watch('items')
  @Watch('isLoading')
  @Watch('isActionDialogOpened')
  @Watch('getNumberOfHiddenItemsInCurrentFolder')
  onFolderContentChanged() {
    const loadingItems = !this.items.length && this.isLoading && !this.isActionDialogOpened
    const noItems =
      !this.items.length &&
      !this.getNumberOfHiddenItemsInCurrentFolder &&
      (this.isActionDialogOpened || !this.isLoading)
    const hiddenItems = !!this.getNumberOfHiddenItemsInCurrentFolder

    if (this.items.length) {
      this.isFolderLoadingItems = loadingItems
    }

    if (loadingItems && !this.isFolderLoadingItems) {
      this.isFolderLoadingItems = loadingItems
      return
    }

    this.setFolderContentTitle({ loadingItems, noItems, hiddenItems })
  }

  @Watch('$route', { immediate: true })
  onUrlChange() {
    this.$nextTick(() => {
      this.checkRootItem()
      this.checkRouteQuery()
    })
  }

  @Watch('getLastAddedItem')
  onNewItem() {
    if (!this.getLastAddedItem) return
    if (this.getSelectedItem && !this.getSelectedItems.length) {
      this.fetchItemDetails({ itemId: this.getSelectedItem.id, itemType: this.getSelectedItem.itemType })
    }

    // timeout is needed here for search the element in the correct table
    setTimeout(() => {
      if (!this.getLastAddedItem) return

      this.onNewTableItem(this.getLastAddedItem.itemType)
    }, SCROLL_TO_NEW_ITEM_TIMEOUT)
  }

  setFolderContentTitle(folderContentTitle: { loadingItems: boolean; noItems: boolean; hiddenItems: boolean }) {
    this.isFolderLoadingItems = folderContentTitle.loadingItems
    this.isFolderNoItems = folderContentTitle.noItems
    this.isFolderHiddenItems = folderContentTitle.hiddenItems
  }

  async checkRootItem() {
    const routeId = this.$route.params.itemId
    if (this.getRootItem && routeId && this.getRootItem.id !== routeId) {
      if (routeId === ROOT_FOLDER_ID) {
        this.setRoot(null)
        return
      }
      let item = this.findItemById(routeId)
      if (!item) {
        item = await this.fetchItemById(routeId)
      }
      this.setRoot(item)
    }
  }

  onNewTableItem(itemType: ItemType) {
    switch (itemType) {
      case ItemType.BuildPart:
      case ItemType.Folder:
        this.scrollToNewItem(this.getLastAddedItem.id)
        break
      case ItemType.BuildPlan:
        this.openBuildPlanForEdit(this.getLastAddedItem.id)
        break
    }
  }

  scrollToNewItem(itemId: string) {
    if (!itemId) {
      return
    }

    const index = this.getTableItemIndexById(itemId)
    const table = this.$el.querySelector(`#${this.itemsTableId}`)
    const targetItem = table ? table.querySelector(`#${this.tableItemIdPattern}${index}`) : null

    if (targetItem) {
      targetItem.scrollIntoView()
    }
  }

  openBuildPlanForEdit(buildPlanId: string) {
    // This is fix for redirecting after closing the Build Plan
    this.setLastAddedItem(null)

    const route = {
      name: RouterNames.EBP_AddPart,
      params: { id: buildPlanId },
    }

    // @ts-ignore
    this.$router.safePush(route)
  }

  getTableItemIndexById(id: string | number) {
    return this.items.findIndex((item) => item.id === id)
  }

  checkRouteQuery() {
    if (this.$route.query) {
      const previewItemId = this.$route.query.item as string
      if (previewItemId) {
        const index = this.getTableItemIndexById(previewItemId)
        const table = this.$el.querySelector(`#${this.itemsTableId}`)
        const targetItem = table ? table.querySelector(`#${this.tableItemIdPattern}${index}`) : null
        if (targetItem) {
          clearTimeout(this.routeQueryTimeout)
          this.routeQueryTimeout = setTimeout(() => {
            targetItem.scrollIntoView()
            ;(targetItem as HTMLElement).click()
          }, 1000)
        }
      }
    }
  }

  // @ts-ignore
  get items(): FileExplorerItem[] {
    let items: FileExplorerItem[] = []
    // items for folders view with all types
    if (this.getViewMode === ViewMode.Folders) {
      items = this.getItemsFromRoot
    } else {
      items = this.getItemsWithoutFolders
    }

    return items
  }

  async changeViewMode(mode) {
    this.setClickedNameItem(null)
    this.setDetailsPanelMode(DetailsPanelViewMode.Default)
    this.setViewMode(mode)
    this.setRoot(null)
    await this.clearPaginatedData()
    this.clearItems()

    if (this.getViewMode === ViewMode.Folders) {
      await this.fetchItemsInFolder({ folderId: ROOT_FOLDER_ID, abortIfRootChanged: true })
    } else {
      await this.fetchAllItems()
    }

    const id = this.$route.params.itemId
    if (id === ROOT_FOLDER_ID) {
      return
    }

    const route = {
      name: RouterNames.FE_AllFiles,
      params: {
        itemId: ROOT_FOLDER_ID,
      },
    }
    // @ts-ignore
    this.$router.safePush(route)
  }

  beforeDestroy() {
    this.clearItems()
    this.clearFolders()
    this.setNumberOfHiddenItemsInCurrentFolder(0)
    this.setDetailsPanelMode(DetailsPanelViewMode.Default)
  }

  setLockModalData() {
    if (!this.isShownLockedBuildPlanModal) {
      return
    }

    const { editVariant, previewVariant } = this.getLockItemInfo
    this.editVariant = editVariant
    this.previewVariant = previewVariant

    // select item to show in the details panel
    const item = this.find(previewVariant.id)
    this.selectItem({ item, selectionType: SelectionTypes.Single })
    this.setDetailsPanelMode(DetailsPanelViewMode.BuildPlan)
    this.fetchItemDetails({ itemId: item.id, itemType: item.itemType })
    this.isShownVariantIsLockedModal = this.isShownLockedBuildPlanModal
  }

  onIntersection() {
    this.fetchItems()
  }

  onEndCollaboration() {
    let itemsToEndCollaboration: FileExplorerItem[]

    if (this.getSelectedItems.length > 0) {
      itemsToEndCollaboration = this.getSelectedItems
    } else if (this.getRootItem) {
      itemsToEndCollaboration = [this.getRootItem]
    } else {
      itemsToEndCollaboration = []
    }

    this.endCollaborationOnSelectedItem(itemsToEndCollaboration)
  }

  @Watch('getFilterParams', { deep: true })
  @Watch('getSortParams', { deep: true })
  async refreshItems(newValue, oldValue) {
    if (newValue === oldValue || isEqual(newValue, oldValue)) {
      return
    }

    await this.clearPaginatedData()
    this.clearItems()
    this.fetchItems()
  }

  async fetchItems() {
    const parentId = this.$route.params.itemId
    const config = await this.getTableState()
    let itemsToGetJobs

    if (config && config.viewMode && config.viewMode === ViewMode.List) {
      itemsToGetJobs = await this.fetchAllItems()
    } else {
      itemsToGetJobs = await this.fetchItemsInFolder({ folderId: parentId, abortIfRootChanged: true })
    }

    await this.actualizeParts()

    const itemIds = itemsToGetJobs.map((item: FileExplorerItem) => item.id)
    await this.getGetRunningAndFailedJobsByItemIds(itemIds)
  }
}
