import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import ModalsStateMixin from '@/components/layout/FileExplorer/Table/mixins/ModalsStateMixin'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import { ROOT_FOLDER_ID } from '@/constants'
import { RouterNames } from '@/router'
import StoresNamespaces from '@/store/namespaces'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemAction } from '@/types/FileExplorer/ItemAction'
import { Permission, PermissionCollaborator } from '@/types/FileExplorer/Permission'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import { DetailsPanelViewMode } from '@/types/FileExplorer/ViewMode'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component
export default class EndCollaborationMixin extends Mixins(ModalsStateMixin) {
  @fileExplorerStore.Getter permissionsByItemId: Record<string, Permission[]>
  @fileExplorerStore.Getter getItemPermissionCollaboratorsById: (id: string) => PermissionCollaborator[]

  @fileExplorerStore.Action deletePermission: (payload: {
    permissionId: string
    fullDeletion?: boolean
  }) => Promise<void>
  @fileExplorerStore.Action fetchItemsByIds: (ids: string[]) => Promise<FileExplorerItem[]>

  @fileExplorerStore.Mutation deleteItem: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation unselectItem: (payload: {
    item: FileExplorerItem | PrintOrder
    selectionType: SelectionTypes
  }) => void

  async onRemoveFromCollaborationClick(
    itemsToCancelCollaboration: FileExplorerItem[],
    confirmModal: InstanceType<typeof ConfirmModal>,
  ): Promise<void> {
    if (!this.getUserDetails) {
      return
    }

    const permissions: PermissionCollaborator[] = []
    const currentUserId = this.getUserDetails.id

    for (const item of itemsToCancelCollaboration) {
      const itemPermission = this.getItemPermissionCollaboratorsById(item.id)
      if (!itemPermission) continue

      const element = itemPermission.find((p) => p.grantedTo === currentUserId)
      if (element) permissions.push(element)
    }

    if (permissions.length !== itemsToCancelCollaboration.length) return

    const inheritedItemPermissions = permissions.filter((p) => p.isInherited)
    const parentPermissions = permissions.filter((p) => !p.isInherited)
    const parentItems = inheritedItemPermissions.length
      ? await this.fetchItemsByIds(inheritedItemPermissions.map((p) => p.inheritedFrom))
      : []
    const modalTitle = this.$root.$t('removeFromCollaborationDialog.title')
    const modalMessage = `<p class="text t-14">${this.$root.$t(
      'removeFromCollaborationDialog.removeYourselfLabel',
    )}</p>`
    let shouldDelete = await confirmModal.open(modalTitle, modalMessage)

    if (shouldDelete) {
      if (parentItems.length) {
        confirmModal.cancel()

        const listOfParentItems = `<ul>${parentItems.map((item) => `<li>${item.name}</li>`).join('')}</ul><br>`
        const user = this.getUserDetails
        const displayName = `${user.firstName} ${user.lastName}`
        const title = this.$root.$t('removeFromCollaborationDialog.fromParentTitle')
        const message = `<div class="text t-14"><p>${this.$root.$t('removeFromCollaborationDialog.fromParentLabel', {
          collaboratorName: displayName,
          parentFolderName: listOfParentItems,
        })}</p></div>`

        shouldDelete = await confirmModal.open(title, message)

        if (!shouldDelete) {
          confirmModal.cancel()
          return
        }
      }

      const lastItemToCancelCollaboration = itemsToCancelCollaboration[itemsToCancelCollaboration.length - 1]
      await this.setLastAction({ itemId: lastItemToCancelCollaboration.id, action: ItemAction.Unshared })

      let permissionsToDelete: Array<Promise<void>> = []

      for (const parentPermission of parentPermissions) {
        const itemToDelete = itemsToCancelCollaboration.find((item) => item.id === parentPermission.itemId)

        if (!itemToDelete) continue

        this.unselectItem({ item: itemToDelete, selectionType: SelectionTypes.Multiple })
        this.deleteItem(itemToDelete)

        permissionsToDelete.push(this.deletePermission({ permissionId: parentPermission.id, fullDeletion: true }))
      }

      const inheritedItems = inheritedItemPermissions.map((item) =>
        itemsToCancelCollaboration.find((i) => i.id === item.itemId),
      )

      for (const item of inheritedItems) {
        this.unselectItem({ item, selectionType: SelectionTypes.Multiple })
        this.deleteItem(item)
      }

      permissionsToDelete = permissionsToDelete.concat(
        parentItems.map((pi) =>
          this.deletePermission({ permissionId: inheritedItemPermissions.find((ip) => ip.inheritedFrom === pi.id).id }),
        ),
      )

      await Promise.all(permissionsToDelete)
      this.setDetailsPanelMode(DetailsPanelViewMode.Default)

      const isEndCollaborationFromFolder =
        this.getRootItem &&
        itemsToCancelCollaboration.length === 1 &&
        itemsToCancelCollaboration[0].id === this.getRootItem.id

      if (parentItems.length || isEndCollaborationFromFolder) {
        // @ts-ignore
        this.$router.safePush({ name: RouterNames.FE_AllFiles, params: { itemId: ROOT_FOLDER_ID } })
      }
    }
  }
}
