
import Component from 'vue-class-component'
import { IBuildPlan, IBuildPlanItem, ISelectable } from '@/types/BuildPlans/IBuildPlan'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import BuildPlanPartPropertiesPart from '@/components/layout/buildPlans/partProperties/BuildPlanPartPropertiesPart.vue'
import PartPropertiesToolbarBJT from '@/components/layout/buildPlans/partProperties/PartPropertiesToolbarBJT.vue'
import PartPropertiesToolbarDMLM from '@/components/layout/buildPlans/partProperties/PartPropertiesToolbarDMLM.vue'
import { PrintingTypes } from '@/types/IMachineConfig'
import { Prop } from 'vue-property-decorator'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { CreateIbcMixin } from '@/components/layout/buildPlans/mixins/CreateIbcMixin'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const commonStore = namespace(StoresNamespaces.Common)

@Component({
  components: {
    BuildPlanPartPropertiesPart,
    PartPropertiesToolbarBJT,
    PartPropertiesToolbarDMLM,
  },
})
export default class BuildPlanPartPropertiesParts extends CreateIbcMixin {
  @buildPlansStore.Getter getAllBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getSelectedParts: ISelectable[]
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes
  @buildPlansStore.Getter getIBCPlan: IIBCPlan

  @commonStore.Getter tooltipOpenDelay: number

  @Prop({ default: false }) canRemoveParts: boolean
  @Prop({ default: false }) hideActionItems: boolean
  @Prop({ default: false }) readonly isPrintOrder: boolean

  get bpItems() {
    const bpItems = this.getAllBuildPlanItems
    const selectedPartsIds = this.getSelectedParts.map((p) => p.id)
    return bpItems.filter((bpItem: IBuildPlanItem) => selectedPartsIds.includes(bpItem.id))
  }

  get isBinderJetModality(): boolean {
    return this.getBuildPlan && this.getBuildPlan.modality === PrintingTypes.BinderJet
  }

  toggleRemovePartModal() {
    this.$emit('toggleRemovePartModal')
  }

  toggleProcessStateChangeModal() {
    this.$emit('toggleProcessStateChangeModal', ...arguments)
  }
}
