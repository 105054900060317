import { Command } from '@/types/UndoRedo/Command'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { BuildPlanItemSupportMetadata } from '@/components/layout/buildPlans/BuildPlanSupportTab.vue'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

export type SupportPropertyValue = string | number | boolean

export class SupportCommand extends Command implements ICommand {
  readonly commandType: CommandType
  readonly toolName = ToolNames.SUPPORT

  private readonly propertyPreviousValue: BuildPlanItemSupportMetadata
  private readonly propertyNextValue: BuildPlanItemSupportMetadata
  private readonly onChangeSupportPropertyCallback: Function

  constructor(
    commandType: CommandType,
    propertyPreviousValue: BuildPlanItemSupportMetadata,
    propertyNextValue: BuildPlanItemSupportMetadata,
    onChangeSupportPropertyCallback: Function,
  ) {
    super()

    this.commandType = commandType
    this.propertyPreviousValue = propertyPreviousValue
    this.propertyNextValue = propertyNextValue
    this.onChangeSupportPropertyCallback = onChangeSupportPropertyCallback
  }

  async redo(): Promise<void> {
    this.onChangeSupportPropertyCallback(this.propertyNextValue, false)
  }

  async undo(): Promise<void> {
    this.onChangeSupportPropertyCallback(this.propertyPreviousValue, false)
  }
}
