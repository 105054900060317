import { InstancedMesh, Mesh, Vector3, VertexBuffer } from '@babylonjs/core';

export class STLExport {
    /**
     * Creates binary STL file from provided meshes
     * @param meshes meshes that will be included in STL file
     * @param fileName STL file name
     * @param isLittleEndian If false or undefined, a big-endian value should be written
     */
    public static CreateSTL(
        meshes: (Mesh | InstancedMesh)[],
        fileName: string = "stlmesh",
        isLittleEndian: boolean = true,
    ) {
        let faceCount = 0
        for (const mesh of meshes) {
            const indices = mesh.getIndices()
            faceCount += indices ? indices.length / 3 : 0
        }

        // Create DataView for STL file
        // Buffer size equal to 84 + 50 * faceCount
        const bufferSize = 84 + 50 * faceCount
        const buffer = new ArrayBuffer(bufferSize)
        const data = new DataView(buffer)
        let offset = 0

        // First 80 bytes is stl file header
        offset += 80
        data.setUint32(offset, faceCount, isLittleEndian)
        // Number of triangles takes 4 byte
        offset += 4

        const v0 = new Vector3()
        const v1 = new Vector3()
        const v2 = new Vector3()
        const v0v1 = new Vector3()
        const v2v1 = new Vector3()
        const n = new Vector3()
        for (const mesh of meshes) {
            const indices = mesh.getIndices()
            const positions = mesh.getVerticesData(VertexBuffer.PositionKind)
            mesh.computeWorldMatrix(true)
            const worldMatrix = mesh.getWorldMatrix()

            // Each triangle takes 50 bytes
            // Normal vector - 12 bytes
            // Vertex 1 - 12 bytes
            // Vertex 2 - 12 bytes
            // Vertex 3 - 12 bytes
            // Attribute byte count - 2 bytes
            for (let i = 0; i < indices.length; i = i + 3) {
                const id0 = indices[i + 0] * 3
                const id1 = indices[i + 1] * 3
                const id2 = indices[i + 2] * 3

                v0.set(positions[id0], positions[id0 + 1], positions[id0 + 2])
                Vector3.TransformCoordinatesToRef(v0, worldMatrix, v0)

                v1.set(positions[id1], positions[id1 + 1], positions[id1 + 2])
                Vector3.TransformCoordinatesToRef(v1, worldMatrix, v1)

                v2.set(positions[id2], positions[id2 + 1], positions[id2 + 2])
                Vector3.TransformCoordinatesToRef(v2, worldMatrix, v2)

                v0.subtractToRef(v1, v0v1)
                v2.subtractToRef(v1, v2v1)
                Vector3.CrossToRef(v2v1, v0v1, n)
                n.normalize()

                offset = this.writeVector(data, offset, n, isLittleEndian)
                offset = this.writeVector(data, offset, v0, isLittleEndian)
                offset = this.writeVector(data, offset, v1, isLittleEndian)
                offset = this.writeVector(data, offset, v2, isLittleEndian)
                // Add 2 to offset to skip attribute byte count
                offset = offset + 2
            }
        }

        const a = document.createElement("a");
        const blob = new Blob([data], { type: "application/octet-stream" });
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName + ".stl";
        a.click();
    }

    private static writeVector(dataView: DataView, offset: number, vector: Vector3, isLittleEndian: boolean) {
        offset = this.writeFloat(dataView, offset, vector.x, isLittleEndian)
        offset = this.writeFloat(dataView, offset, vector.y, isLittleEndian)
        return this.writeFloat(dataView, offset, vector.z, isLittleEndian)
    }

    private static writeFloat(dataView: DataView, offset: number, value: number, isLittleEndian: boolean) {
        dataView.setFloat32(offset, value, isLittleEndian)
        return offset + 4
    }
}
