
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { TranslateResult } from 'vue-i18n'

import { IBuildPlan, ISelectable } from '@/types/BuildPlans/IBuildPlan'
import Button from '@/components/controls/Common/Button.vue'
import StoresNamespaces from '@/store/namespaces'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { LabelSetDto } from '@/types/Label/LabelSetDto'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const labelsStore = namespace(StoresNamespaces.Labels)

@Component({
  components: {
    Button,
  },
})
export default class RemovePartModal extends Vue {
  @buildPlansStore.Getter getSelectedParts: ISelectable[]
  @buildPlansStore.Getter getSelectedItemsNames: string[]
  @buildPlansStore.Getter getBuildPlan: IBuildPlan

  @labelsStore.Action updateRelatedLabelsOnRemove: () => Promise<number>
  @labelsStore.Action getLabelSetsByBuildPlanId: (payload: {
    buildPlanId: string
    dirtyStateAddIfNew?: boolean
  }) => Promise<LabelSetDto[]>
  @labelsStore.Action updateLabelRelatedInsightsOnPartsRemoval: Function

  @buildPlansStore.Action deleteSelectedParts: Function

  @Prop({ required: true }) value: boolean

  isLoading: boolean = false

  get label(): TranslateResult {
    let itemName = ''

    const isMultipleRemove: boolean = this.getSelectedParts.length > 1

    if (isMultipleRemove) {
      itemName = `${this.getSelectedParts.length} ${this.$i18n.t('removePartDialog.selectedItems')}`
    } else {
      itemName = this.getSelectedItemsNames[0]
    }

    return this.$i18n.t('removePartDialog.label', { itemName })
  }

  onCancelClick() {
    this.$emit('close')
  }

  async onConfirmClick() {
    if (this.isLoading) {
      return
    }

    try {
      this.isLoading = true

      const changed = await this.updateRelatedLabelsOnRemove()
      await this.updateLabelRelatedInsightsOnPartsRemoval()
      await this.deleteSelectedParts()
      if (changed) {
        await this.getLabelSetsByBuildPlanId({ buildPlanId: this.getBuildPlan.id })
      }

      this.$emit('close')
    } finally {
      this.isLoading = false
    }
  }
}
