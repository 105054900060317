
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { io, Socket } from 'socket.io-client'
import { namespace } from 'vuex-class'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import { IUser } from '@/types/User/IUser'
import StoresNamespaces from '@/store/namespaces'
import partsService from '@/api/parts'
import {
  BuildPlanItemOverhang,
  BuildPlanItemSupport,
  BuildPlanItemSupportSettings,
  ContourTypes,
  GeometryType,
  IBuildPlan,
  IBuildPlanItem,
  IBuildPlanItemSettings,
  IDisplayToolbarState,
  IPrintStrategyParameterSet,
  ISelectable,
  IUpdateBuildPlanItemParamsDto,
  LineShapeTypes,
  SelectionUnit,
  SupportedElementTypes,
} from '@/types/BuildPlans/IBuildPlan'
import { FileTypes } from '@/types/PartsLibrary/Job'
import { createGuid } from '@/utils/common'
import {
  IInteractiveServiceCommand,
  InteractiveServiceCommandNames,
} from '@/types/InteractiveService/IInteractiveServiceCommand'
import {
  IInteractiveServiceMessage,
  OperationStatus,
  ISupportError,
} from '@/types/InteractiveService/IInteractiveServiceMessage'
import messageService from '@/services/messageService'
import { InteractiveServiceEvents } from '@/types/InteractiveService/InteractiveServiceEvents'
import { eventBus } from '@/services/EventBus'
import { ISaveFileDto } from '@/types/InteractiveService/ISaveFileDto'
import { getLastPathItems } from '@/utils/string'
import NumberField from '@/components/controls/Common/NumberField.vue'
import LabelToolTextField from '@/components/controls/LabelToolControls/LabelToolTextField.vue'
import {
  FillTypes,
  LinearAlgorithmTypes,
  SupportTypes,
  TreeAlgorithmTypes,
} from '@/types/BuildPlans/IBuildPlanItemSettings'
import Selector from '@/components/controls/Common/Selector.vue'
import Menu from '@/components/controls/Common/Menu.vue'
import Splitter from '@/components/layout/Splitter.vue'
import LabeledSelector from '@/components/controls/Common/LabeledSelector.vue'
import { IConstraints } from '@/types/BuildPlans/IConstraints'
import { extend } from 'vee-validate'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { SupportCommand, SupportPropertyValue } from '@/types/UndoRedo/SupportCommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { IFileTags } from '@/types/Common/IFileDetails'
import { SaveSupportsCommand } from '@/types/UndoRedo/SaveSupportsCommand'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { IOverhangConfig } from '@/visualization/rendering/OverhangManager'
import MultiSelectNumberField from '@/components/controls/SupportToolControls/MultiSelectNumberField.vue'
import { DEFAULT_SELF_SUPPORTING_ANGLE } from '@/constants'
import { convert } from '@/utils/converter/lengthConverter'
import { defaultNameBasedOnSupportType, getSupportDefaultBasedOnType } from '@/utils/parameterSet/parameterSetUtils'
import { formatDecimal } from '@/utils/number'
import ITransformationDelta from '@/types/BuildPlans/ITransformationDelta'
import { ConnectionState } from '@/utils/socketConnection'

const userStore = namespace(StoresNamespaces.User)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const commandManagerStore = namespace(StoresNamespaces.CommandManager)
const commonStore = namespace(StoresNamespaces.Common)

const OVERHANG_PATH_SIGNIFICANT_NUMBER = 4
const SUPPORT_PATH_SIGNIFICANT_NUMBER = 3
const SUPPORTS_BVH_FILE_NAME = 'BVH.JSON'
const SUPPORTS_HULL_FILE_NAME = 'HULL.JSON'
const DEFAULT_OVERHANG_ANGLE = DEFAULT_SELF_SUPPORTING_ANGLE
const DEBOUNCE_TIME = 1000
const DEFAULT_ATTACHMENT_DEPTH_MULTIPLIER = 4
const MAX_ATTACHMENT_DEPTH_MULTIPLIER = 10
const MAX_PERFORATION_WIDTH_DECREASE_VALUE = 0.02
const MAX_NECK_WIDTH_DECREASE_VALUE = 0.00001
const DEFAULT_PART_ELEVATION = 0
const MAX_CONNECTION_SPACING = 100
const GLOBAL_SUPPORT_ERROR_MESSAGES = [
  'Client already assigned',
  'Invalid file path',
  'Invalid model file path',
  'Invalid overhang file path',
  'Invalid output folder path',
  'Invalid output file path',
  'Authentication error: invalid signature',
]

const SUPPORT_COMMAND_NAMES = [
  InteractiveServiceCommandNames.LinearSupports,
  InteractiveServiceCommandNames.TreeSupports,
  InteractiveServiceCommandNames.NoSupports,
]

const OVERHANG_ELEMENT_TYPES = {
  [SupportedElementTypes.Vertex]: 'Point',
  [SupportedElementTypes.Edge]: 'Edge',
  [SupportedElementTypes.Area]: 'Surface',
}

const SUPPORT_STRATEGIES = {
  [SupportTypes.Lines1]: 'Line',
  [SupportTypes.Solid]: SupportTypes.Solid,
  [SupportTypes.NoSupports]: 'None',
}

const PROPERTY_NAMES_TO_UPDATE_NOTCH_OFFSET = ['thickness', 'perforationWidth', 'perforationAngleDeg']

const MESSAGE_MAP_BY_LINE_SUPPORT_ERROR_CODE = {
  9500: 'supportTool.errorMessages.supportSmallSpaceBetweenPartAndPlate',
  9001: 'supportTool.errorMessages.notchWasNotAddedToAllSegments',
  9002: 'supportTool.errorMessages.blendWasNotAddedToAllSegments',
  9503: 'supportTool.errorMessages.supportIntersectionWithPart',
}

const PARTS_FOLDER = 'parts'
const SUPPORTS_FOLDER = 'supports'

interface BuildPlanItemFiles {
  partId: string
  absoluteFilePath: string
  overhangsFilePath: string
  supportsFilePath: string[]
  constraints: IConstraints
}

export interface BuildPlanItemSupportMetadata {
  settings: IBuildPlanItemSettings
  supports: BuildPlanItemSupport[]
  selectedElements: BuildPlanItemSupport[]
  hoveredElement: BuildPlanItemSupport
  hasTempSupports: boolean
  updatedZones: Set<string>
  latestTransformation: number[]
  isLockedOverhangsAndSupportsRendering: boolean
}

@Component({
  components: { NumberField, Selector, Menu, LabelToolTextField, Splitter, LabeledSelector, MultiSelectNumberField },
})
export default class BuildPlanSupportTab extends Vue implements IToolComponent {
  @userStore.Getter getUserDetails: IUser
  @userStore.Action prepareSupports: any

  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getFillTypes: FillTypes[]
  @buildPlansStore.Getter isSupportReadOnly: boolean
  @buildPlansStore.Getter getSelectedBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getCommandType: CommandType
  @buildPlansStore.Getter getBuildPlanPrintStrategy: BuildPlanPrintStrategyDto
  @buildPlansStore.Getter parameterSetsLatestVersions: IPrintStrategyParameterSet[]
  @buildPlansStore.Getter displayToolbarStateByVariantId: (buildPlanId: string) => IDisplayToolbarState
  @buildPlansStore.Getter defaultPartParamOptionAvailable: boolean
  @buildPlansStore.Getter getSelectedParts: ISelectable[]
  @buildPlansStore.Getter getAllBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter isPartElevationUpdating: boolean

  @commonStore.Getter tooltipOpenDelay: number

  @visualizationStore.Getter getPartZTranslation: Function

  @buildPlansStore.Action updateBuildPlanItem: (payload: {
    params: IUpdateBuildPlanItemParamsDto
    hideAPIErrorMessages?: boolean
  }) => Promise<void>
  @buildPlansStore.Action erasePreviousOverhangContour: Function
  @buildPlansStore.Action elevatePart: (elevationValue: number) => void

  @buildPlansStore.Mutation addPendingPartElevationRequest: Function
  @buildPlansStore.Mutation clearPartElevationRequests: Function

  @visualizationStore.Mutation addOverhangMesh: Function
  @visualizationStore.Mutation addSupportMesh: Function
  @visualizationStore.Mutation addSupportBvhAndHull: Function
  @visualizationStore.Mutation clearSupports: Function
  @visualizationStore.Mutation clearOverhangMesh: Function
  @visualizationStore.Mutation highlightErrorOverhangZone: Function
  @visualizationStore.Mutation setDefaultOverhangMaterial: Function
  @visualizationStore.Mutation highlightSupports: Function
  @visualizationStore.Mutation setIsShowingSupports: Function
  @visualizationStore.Mutation setGeometriesVisibility: Function
  @visualizationStore.Mutation setSelectionModeAndReselect: Function
  @visualizationStore.Mutation hoverSupport: Function
  @visualizationStore.Mutation updateOverhangMesh: Function
  @visualizationStore.Mutation updateSupports: Function
  @visualizationStore.Mutation setOverhangMeshVisibility: Function
  @visualizationStore.Mutation applyTransformationMatrix: Function
  @visualizationStore.Mutation addFailedOverhangZone: Function
  @buildPlansStore.Mutation setBuildPlanItem: Function

  @commandManagerStore.Mutation addCommand: (command: ICommand) => void
  @commandManagerStore.Mutation disable: () => void

  lastSelectedSupport: BuildPlanItemSupport = null
  defaultOverhangAngle: number = DEFAULT_OVERHANG_ANGLE
  defaultPartElevation: number = DEFAULT_PART_ELEVATION

  socket: Socket = null
  partFiles: Map<string, { partId: string; files: Array<{ s3filename: string; name: string }> }> = new Map()
  token: string
  commandMap: Map<
    string,
    { handler: Function; commandName: InteractiveServiceCommandNames; buildPlanItemId?: string }
  > = new Map()
  bpItemFilesMap: Map<string, BuildPlanItemFiles> = new Map()

  overhangTimeouts: Map<string, number> = new Map()
  bpItemSettingsMap: { [key: string]: BuildPlanItemSupportMetadata } = {}
  bpItemSupportSettingsMap: Map<string, BuildPlanItemSupport> = new Map()
  supportTypes = SupportTypes
  supportedElementTypes = SupportedElementTypes
  overhangElementTypes = OVERHANG_ELEMENT_TYPES
  supportStrategies = SUPPORT_STRATEGIES
  maxAttachmentDepthMultiplier = MAX_ATTACHMENT_DEPTH_MULTIPLIER

  isSaved: Promise<ISaveFileDto[]> = new Promise((resolve, reject) => {
    this.resolveIsSavedPromise = resolve
    this.rejectIsSavedPromise = reject
  })
  resolveIsSavedPromise: Function
  rejectIsSavedPromise: Function
  isRunning: boolean = false
  isOkDisabled: boolean = true
  overhangCommand: IInteractiveServiceCommand = null
  isSetupInProgress: boolean = true
  lastAddedOverhangId: string
  isCloseToolHandled: boolean = false
  openedPanel: number[] = null
  isSupportsRendering = false
  oldPartElevation: number = DEFAULT_PART_ELEVATION
  initialPartElevation: number = DEFAULT_PART_ELEVATION
  undoCommandSupportMetadata = null
  /* TODO
    Consider removing this flag as it its incorrect value leads to showing modal dialog in wrong use cases
    Instead when closing the tool from the client side
    we can unsubscribe from connection disconnect event before closing the socket
    thus ensure modal dialog will show up only when disconnect event is fired from the server side
  */
  isDisconnectTriggeredByUser: boolean = false

  private dataToAddSupportMeshFromUndoManager: Array<{ belongsToOverhangElementName: string; sdata: ArrayBuffer }> = []

  constructor() {
    super()
    this.onSocketConnectError = this.onSocketConnectError.bind(this)
  }

  get isSupportButtonAvailable(): boolean {
    const buildPlanItem = this.getSelectedBuildPlanItems[0]

    if (!buildPlanItem) {
      return false
    }

    const buildPlanItemSettings = this.bpItemSettingsMap[buildPlanItem.id]

    if (!buildPlanItemSettings || !buildPlanItemSettings.supports) {
      return false
    }

    // The 'Support' push button shall be available if support type  is not 'None' for at least one defined overhang
    const isSupportTypeDefined = (s: BuildPlanItemSupport) => s.settings.strategy !== SupportTypes.NoSupports
    const isAvailable = buildPlanItemSettings.supports.some(isSupportTypeDefined)

    if (!this.validateOverhangSettings()) {
      return false
    }

    if (isAvailable && this.selectedOverhangElements.length !== 0) {
      return this.validateSupportSettings(buildPlanItemSettings.supports)
    }

    if (!buildPlanItem.supports) {
      return false
    }

    return isAvailable
  }

  @Watch('isSupportButtonAvailable')
  onSupportButtonAvailabilityChange(isAvailable: boolean) {
    if (!isAvailable) {
      this.isOkDisabled = true
      this.updateOkStatus()
    }
  }

  /**************************************
   * Generic tool method implementations
   **************************************/
  // need to mention these generic optional methods even if they are not implemented by the tool
  // due to TypeScript's weak type detection per https://stackoverflow.com/a/47930521
  async clickOk() {
    if (!this.socket || !this.socket.connected) {
      // This error will be caught in the parent component (BuildPlanSidebar)
      throw new Error('Socked is disconnected!')
    }

    // Disable Undo/Redo command manager while supports are saving
    this.disable()

    this.isDisconnectTriggeredByUser = true
    const saveCommand: IInteractiveServiceCommand = {
      id: createGuid(),
      name: InteractiveServiceCommandNames.Save,
      parameters: { itemId: this.getBuildPlan.id, buildPlanItemId: this.getSelectedBuildPlanItems[0].id },
    }

    this.commandMap.set(saveCommand.id, {
      handler: this.handleSaveResponse,
      commandName: saveCommand.name,
    })
    this.socket.emit('command', saveCommand)
    try {
      const files = await this.isSaved
      for (const [buildPlanItemId, bpItemFiles] of this.bpItemFilesMap) {
        if (!this.bpItemSettingsMap[buildPlanItemId].hasTempSupports) {
          continue
        }

        // Need to check only last n path items to get rid of different paths
        const overhangFileDto = files.find(
          (file) =>
            getLastPathItems(file.localFilename, OVERHANG_PATH_SIGNIFICANT_NUMBER) ===
            getLastPathItems(bpItemFiles.overhangsFilePath, OVERHANG_PATH_SIGNIFICANT_NUMBER),
        )
        const overhang: BuildPlanItemOverhang = {
          fileKey: overhangFileDto.fileKey,
          awsFileKey: overhangFileDto.s3filename,
        }

        const supportsWithFiles: BuildPlanItemSupport[] = files
          .filter((file) =>
            bpItemFiles.supportsFilePath.find(
              (supportPath) =>
                getLastPathItems(supportPath, SUPPORT_PATH_SIGNIFICANT_NUMBER) ===
                getLastPathItems(file.localFilename, SUPPORT_PATH_SIGNIFICANT_NUMBER),
            ),
          )
          .map((supportFile) => ({
            fileKey: supportFile.fileKey,
            settings: null,
            overhangElementType: SupportedElementTypes.Area,
            overhangElementName: supportFile.localFilename.split('/').pop().split('.')[0],
            awsFileKey: supportFile.s3filename,
            groupId: null,
            overhangArea: null,
            overhangObb: null,
          }))

        const supportGroupId = createGuid()
        const supports: BuildPlanItemSupport[] = this.bpItemSettingsMap[buildPlanItemId].supports.map((support) => {
          const supportWithFileKey = supportsWithFiles.find(
            (supportWithFile) => supportWithFile.overhangElementName === support.overhangElementName,
          )

          if (supportWithFileKey) {
            support.fileKey = supportWithFileKey.fileKey
            support.awsFileKey = supportWithFileKey.awsFileKey
          } else if (
            this.bpItemSettingsMap[buildPlanItemId].updatedZones.has('*') ||
            this.bpItemSettingsMap[buildPlanItemId].updatedZones.has(support.overhangElementName)
          ) {
            support.fileKey = null
            support.awsFileKey = null
          }

          /**
           * If support groupId wasn't set we should set initial value for the support groupId property
           */
          if (!support.groupId) {
            const supportByParameterSetId = this.bpItemSettingsMap[buildPlanItemId].supports.find(
              (s) =>
                s.settings.printStrategyParameterSetId === support.settings.printStrategyParameterSetId &&
                s.settings.printStrategyParameterSetVersion === support.settings.printStrategyParameterSetVersion,
            )

            support.groupId =
              supportByParameterSetId && supportByParameterSetId.groupId
                ? supportByParameterSetId.groupId
                : supportGroupId
          }

          return support
        })

        const supportsBvhFile = files.find((file) =>
          file.localFilename.toUpperCase().endsWith(`${buildPlanItemId.toUpperCase()}/${SUPPORTS_BVH_FILE_NAME}`),
        )
        const supportsBvhFileKey = supportsBvhFile ? supportsBvhFile.fileKey : null
        const supportsHullFile = files.find((file) =>
          file.localFilename.toUpperCase().endsWith(`${buildPlanItemId.toUpperCase()}/${SUPPORTS_HULL_FILE_NAME}`),
        )
        const supportsHullFileKey = supportsHullFile ? supportsHullFile.fileKey : null

        const buildPlanItemToUpdate: IUpdateBuildPlanItemParamsDto = {
          buildPlanItemId,
          supports,
          supportsBvhFileKey,
          supportsHullFileKey,
          overhangs: overhang,
          settings: this.bpItemSettingsMap[buildPlanItemId].settings,
          constraints: {
            translation: Object.assign(bpItemFiles.constraints.translation, { z: true }),
            rotation: Object.assign(bpItemFiles.constraints.rotation, { x: true, y: true, z: true }),
          },
        }

        await this.updateBuildPlanItem({ params: buildPlanItemToUpdate, hideAPIErrorMessages: true })
        this.updateOverhangMesh({
          buildPlanItemId: buildPlanItemToUpdate.buildPlanItemId,
          id: this.lastAddedOverhangId,
          overhang: buildPlanItemToUpdate.overhangs,
        })
        this.updateSupports({ buildPlanItemId, supports })

        this.addSupportBvhAndHull({ buildPlanItemId, supportsBvhFileKey, supportsHullFileKey })

        this.addCommand(
          new SaveSupportsCommand(
            buildPlanItemToUpdate.buildPlanItemId,
            buildPlanItemToUpdate.supports,
            buildPlanItemToUpdate.supportsBvhFileKey,
            buildPlanItemToUpdate.supportsHullFileKey,
            buildPlanItemToUpdate.overhangs,
            buildPlanItemToUpdate.settings,
            buildPlanItemToUpdate.constraints,
            this.dataToAddSupportMeshFromUndoManager,
            this.getCommandType,
            this.$store.dispatch,
            this.$store.commit,
          ),
        )
      }

      this.isCloseToolHandled = true
    } catch (error) {
      // This error will be caught in the parent component (BuildPlanSidebar)
      throw new error()
    }
  }

  async clickCancel() {
    // TODO: Send EXIT command to stop running interactive service instance
    this.isDisconnectTriggeredByUser = true
    this.closeSocket()
    for (const bpItem of this.getBuildPlan.buildPlanItems) {
      if (this.initialPartElevation !== this.oldPartElevation) {
        this.changePartElevation(this.initialPartElevation)
      }

      if (!bpItem.overhangs && (!bpItem.supports || !bpItem.supports.length)) {
        this.clearSupports({ buildPlanItemId: bpItem.id })
        this.clearOverhangMesh(bpItem.id)
        this.dataToAddSupportMeshFromUndoManager = []
      }

      if (this.bpItemSettingsMap[bpItem.id].hasTempSupports) {
        this.clearSupports({ buildPlanItemId: bpItem.id })
        this.clearOverhangMesh(bpItem.id)
        this.dataToAddSupportMeshFromUndoManager = []

        if (bpItem.overhangs) {
          const payload = {
            buildPlanItemId: bpItem.id,
            id: createGuid(),
            config: { overhang: bpItem.overhangs, isInvisible: true } as IOverhangConfig,
          }

          this.addOverhangMesh(payload)
        }

        if (bpItem.supports && bpItem.supports.length) {
          await Promise.all(
            bpItem.supports.map(async (support) => {
              if (support.fileKey) {
                const supportFile = await partsService.getTextFileFromAws(support.awsFileKey)
                this.addSupportMesh({
                  buildPlanItemId: bpItem.id,
                  belongsToOverhangElementName: support.overhangElementName,
                  sdata: supportFile,
                })
              }
            }),
          )

          this.addSupportBvhAndHull({
            buildPlanItemId: bpItem.id,
            supportsBvhFileKey: bpItem.supportsBvhFileKey,
            supportsHullFileKey: bpItem.supportsHullFileKey,
          })
        }
      }
    }

    this.isCloseToolHandled = true
  }

  clickClose() {
    this.isDisconnectTriggeredByUser = true
  }

  getOkName() {
    return 'save'
  }

  async beforeMount() {
    const token = await Vue.prototype.$auth.getTokenSilently()
    // Erase previous overhang contours
    this.erasePreviousOverhangContour()
    this.clearPartElevationRequests()
    this.token = token
    await this.getPartFiles()
    this.getBuildPlan.buildPlanItems.forEach((bpItem) => {
      const settings = JSON.parse(JSON.stringify(bpItem.settings))
      const supports = JSON.parse(JSON.stringify(bpItem.supports)) as BuildPlanItemSupport[]

      if (supports) {
        supports
          .sort(
            (a, b) =>
              +a.overhangElementName.slice(a.overhangElementName.lastIndexOf('_') + 1) -
              +b.overhangElementName.slice(b.overhangElementName.lastIndexOf('_') + 1),
          )
          .sort((a, b) => a.overhangElementType.localeCompare(b.overhangElementType))
      }

      const selectedPart = this.getSelectedParts.find((part) => bpItem.id === part.id)
      let partElevation = 0
      if (selectedPart) {
        partElevation = Number.parseFloat(formatDecimal(this.getPartZTranslation(selectedPart.id)))
        this.oldPartElevation = partElevation
        this.initialPartElevation = partElevation
      }

      let overhangAngleDeg
      if (settings) {
        overhangAngleDeg = settings.overhangAngleDeg
      } else if (bpItem.orientCriteria && bpItem.orientCriteria.supportAngle) {
        overhangAngleDeg = bpItem.orientCriteria.supportAngle
      } else {
        overhangAngleDeg = DEFAULT_OVERHANG_ANGLE
      }

      const selectedElements = []

      if (supports) {
        if (this.$route.params.failedFirst) {
          const failedSupport = supports.find((el) => el.settings.hasSupportError)
          selectedElements.push(failedSupport ? failedSupport : supports[0])
        } else {
          selectedElements.push(supports[0])
        }
      }

      Vue.set(this.bpItemSettingsMap, bpItem.id, {
        supports,
        selectedElements,
        settings: { ...(settings || this.createDefaultSettings()), partElevation, overhangAngleDeg },
        hoveredElement: null,
        hasTempSupports: false,
        latestTransformation: bpItem.transformationMatrix,
        isLockedOverhangsAndSupportsRendering: false,
        updatedZones: new Set(),
      } as BuildPlanItemSupportMetadata)
    })

    // provide support data
    this.setSelectionModeAndReselect({ mode: SelectionUnit.PartAndSupport })
    this.setOverhangMeshVisibility({
      items: this.getBuildPlan.buildPlanItems.map((bpItem) => ({ buildPlanItemId: bpItem.id })),
      visibility: true,
    })
    this.extendValidationRules()

    if (this.isSupportReadOnly) {
      this.isSetupInProgress = false
      return
    }

    this.createSocketConnection()
    eventBus.$on(InteractiveServiceEvents.GenerateOverhangMeshDebounced, this.generateOverhangMeshDebounced)
    eventBus.$on(
      InteractiveServiceEvents.GenerateOverhanhgMeshByClick,
      this.generateOverhangsAndSupportsForSelectedItem,
    )
    eventBus.$on(InteractiveServiceEvents.GetOverhangElements, this.onGetOverhangElements)
    eventBus.$on(InteractiveServiceEvents.LockOverhangsAndSupportsRendering, this.onLockOverhangAndSupportsRendering)
    eventBus.$on(InteractiveServiceEvents.SelectSupport, this.onSelectSupport)
    eventBus.$on(InteractiveServiceEvents.HoverSupport, this.onHoverSupport)
    eventBus.$on(InteractiveServiceEvents.OnPartElevate, this.handlePartElevateEvent)
  }

  mounted() {
    this.$emit('mounted')
    this.updateOkStatus()
  }

  extendValidationRules() {
    extend('less_value', {
      validate: (value: number, { limit }: { limit: number }) => {
        return value < limit
      },
      params: ['limit'],
      message: this.$i18n.t('lessValidationMessage') as string,
    })

    extend('more_value', {
      validate: (value: number, { limit }: { limit: number }) => {
        return value > limit
      },
      params: ['limit'],
      message: this.$i18n.t('moreValidationMessage') as string,
    })
  }

  updateOkStatus() {
    this.$emit('setOkDisabled', this.isOkDisabled)
  }

  get supportSettings() {
    const bpItem = this.getSelectedBuildPlanItems[0]
    const supportMetadata = this.bpItemSettingsMap[bpItem.id]
    if (supportMetadata && supportMetadata.settings) {
      return JSON.parse(JSON.stringify(supportMetadata.settings)) as IBuildPlanItemSettings
    }
  }

  get selectedOverhangElements() {
    const bpItem = this.getSelectedBuildPlanItems[0]
    const supportMetadata = this.bpItemSettingsMap[bpItem.id]

    return (supportMetadata && supportMetadata.selectedElements) || []
  }

  get supports() {
    const bpItem = this.getSelectedBuildPlanItems[0]
    const supportMetadata = this.bpItemSettingsMap[bpItem.id]

    return (supportMetadata && supportMetadata.supports) || []
  }

  get selectedOverhangElement() {
    return this.selectedOverhangElements[0]
  }

  get selectedOverhangElementsSettings() {
    if (!this.selectedOverhangElements.length) {
      return
    }

    if (this.selectedOverhangElements.length === 1) {
      return this.selectedOverhangElement
    }

    const defaultSelectedOverhang = this.selectedOverhangElements[0]

    const hasSameSupportType = this.selectedOverhangElements.every(
      (o) => o.settings.strategy === defaultSelectedOverhang.settings.strategy,
    )
    const hasSameOverhangType = this.selectedOverhangElements.every(
      (o) => o.overhangElementType === defaultSelectedOverhang.overhangElementType,
    )

    if (hasSameSupportType) {
      const selectedSupportsData = {
        overhangElementType: hasSameOverhangType ? defaultSelectedOverhang.overhangElementType : null,
        settings: {
          strategy: defaultSelectedOverhang.settings.strategy,
          ...this.selectedOverhangElements.reduce(
            (acc, o) => {
              return {
                contourType: o.settings.contourType === acc.contourType ? acc.contourType : LineShapeTypes.Multiple,
                overhangSampleDirectionDeg:
                  o.settings.overhangSampleDirectionDeg === acc.overhangSampleDirectionDeg
                    ? acc.overhangSampleDirectionDeg
                    : null,
                thickness: o.settings.thickness === acc.thickness ? acc.thickness : null,
                minSupportSpacing:
                  o.settings.minSupportSpacing === acc.minSupportSpacing ? acc.minSupportSpacing : null,
                neckWidth: o.settings.neckWidth === acc.neckWidth ? acc.neckWidth : null,
                neckHeight: o.settings.neckHeight === acc.neckHeight ? acc.neckHeight : null,
                addPerforations: o.settings.addPerforations === acc.addPerforations ? acc.addPerforations : null,
                perforationWidth: o.settings.perforationWidth === acc.perforationWidth ? acc.perforationWidth : null,
                perforationAngleDeg:
                  o.settings.perforationAngleDeg === acc.perforationAngleDeg ? acc.perforationAngleDeg : null,
                perforationMinHeight:
                  o.settings.perforationMinHeight === acc.perforationMinHeight ? acc.perforationMinHeight : null,
                addBlend: o.settings.addBlend === acc.addBlend ? acc.addBlend : null,
                blendRadius: o.settings.blendRadius === acc.blendRadius ? acc.blendRadius : null,
                attachmentDepth: o.settings.attachmentDepth === acc.attachmentDepth ? acc.attachmentDepth : null,
                parameterLayerThickness:
                  o.settings.parameterLayerThickness === acc.parameterLayerThickness
                    ? acc.parameterLayerThickness
                    : null,
                printStrategyParameterSetId:
                  o.settings.printStrategyParameterSetId === acc.printStrategyParameterSetId
                    ? acc.printStrategyParameterSetId
                    : null,
                addOverhangBoundaries:
                  o.settings.addOverhangBoundaries === acc.addOverhangBoundaries ? acc.addOverhangBoundaries : null,
                maxConnectionSpacing:
                  o.settings.maxConnectionSpacing === acc.maxConnectionSpacing ? acc.maxConnectionSpacing : null,
                maxSegmentLength: o.settings.maxSegmentLength === acc.maxSegmentLength ? acc.maxSegmentLength : null,
                minSegmentBreak: o.settings.minSegmentBreak === acc.minSegmentBreak ? acc.minSegmentBreak : null,
              }
            },
            {
              contourType: defaultSelectedOverhang.settings.contourType,
              overhangSampleDirectionDeg: defaultSelectedOverhang.settings.overhangSampleDirectionDeg,
              thickness: defaultSelectedOverhang.settings.thickness,
              minSupportSpacing: defaultSelectedOverhang.settings.minSupportSpacing,
              neckWidth: defaultSelectedOverhang.settings.neckWidth,
              neckHeight: defaultSelectedOverhang.settings.neckHeight,
              addPerforations: defaultSelectedOverhang.settings.addPerforations,
              perforationWidth: defaultSelectedOverhang.settings.perforationWidth,
              perforationAngleDeg: defaultSelectedOverhang.settings.perforationAngleDeg,
              perforationMinHeight: defaultSelectedOverhang.settings.perforationMinHeight,
              addBlend: defaultSelectedOverhang.settings.addBlend,
              blendRadius: defaultSelectedOverhang.settings.blendRadius,
              attachmentDepth: defaultSelectedOverhang.settings.attachmentDepth,
              parameterLayerThickness: defaultSelectedOverhang.settings.parameterLayerThickness,
              printStrategyParameterSetId: defaultSelectedOverhang.settings.printStrategyParameterSetId,
              addOverhangBoundaries: defaultSelectedOverhang.settings.addOverhangBoundaries,
              maxConnectionSpacing: defaultSelectedOverhang.settings.maxConnectionSpacing,
              maxSegmentLength: defaultSelectedOverhang.settings.maxSegmentLength,
              minSegmentBreak: defaultSelectedOverhang.settings.minSegmentBreak,
            },
          ),
        },
      }

      return selectedSupportsData
    }

    return {
      overhangElementType: hasSameOverhangType ? defaultSelectedOverhang.overhangElementType : null,
      settings: {
        strategy: null,
        contourType: null,
        overhangSampleDirectionDeg: null,
        thickness: null,
        minSupportSpacing: null,
        neckWidth: null,
        neckHeight: null,
        addPerforations: null,
        perforationWidth: null,
        perforationAngleDeg: null,
        perforationMinHeight: null,
        addBlend: null,
        blendRadius: null,
        attachmentDepth: null,
        parameterLayerThickness: null,
        printStrategyParameterSetId: null,
        addOverhangBoundaries: null,
        maxConnectionSpacing: null,
        maxSegmentLength: null,
        minSegmentBreak: null,
      },
    }
  }

  changeSupportProperty(propertyName: string, value: SupportPropertyValue, shouldUpdateSupports: boolean = true) {
    let isNeededToUpdate = false
    const bpItem = this.getSelectedBuildPlanItems[0]
    const supportMetadata = this.bpItemSettingsMap[bpItem.id]
    const selectedOverhangElements = supportMetadata.selectedElements
    let prevSupportMetadata

    if (this.selectedOverhangElements.length) {
      if (shouldUpdateSupports) {
        prevSupportMetadata = this.undoCommandSupportMetadata
          ? this.undoCommandSupportMetadata
          : JSON.parse(JSON.stringify(supportMetadata))
      }
      isNeededToUpdate = true
      selectedOverhangElements.forEach((o) => {
        o.settings[propertyName] = typeof value === 'number' ? Number.parseFloat(value.toFixed(3)) : value
      })
    }

    if (PROPERTY_NAMES_TO_UPDATE_NOTCH_OFFSET.includes(propertyName)) {
      selectedOverhangElements.forEach((o) => {
        const { thickness, perforationWidth, perforationAngleDeg } = o.settings
        o.settings.perforationMinHeight = this.calcNotchOffset(thickness, perforationWidth, perforationAngleDeg)
      })
    }

    if (shouldUpdateSupports && isNeededToUpdate) {
      this.onSupportSettingsChanged()
      const nextSupportMetadata = JSON.parse(JSON.stringify(supportMetadata))

      const supportUndoRedoCommand = new SupportCommand(
        this.getCommandType,
        prevSupportMetadata,
        nextSupportMetadata,
        this.undoRedoActionHandler,
      )
      this.addCommand(supportUndoRedoCommand)
      this.undoCommandSupportMetadata = null
    }
  }

  changeMultipleSupportsProperty(
    propertyName: string,
    value: SupportPropertyValue,
    forceUndoRedoCommandCreation: boolean = true,
  ) {
    let isNeededToUpdate = false
    const bpItem = this.getSelectedBuildPlanItems[0]
    const supportMetadata = this.bpItemSettingsMap[bpItem.id]
    const selectedOverhangElements = supportMetadata.selectedElements
    let prevSupportMetadata

    if (selectedOverhangElements.length) {
      prevSupportMetadata = JSON.parse(JSON.stringify(supportMetadata))
      isNeededToUpdate = true
      const defaultSettings = this.createDefaultSupportSettings()
      const defaultPropertyValue = defaultSettings[propertyName]
      selectedOverhangElements.forEach((o) => {
        o.settings[propertyName] = Number.parseFloat((defaultPropertyValue + value).toFixed(5))
      })
    }

    if (PROPERTY_NAMES_TO_UPDATE_NOTCH_OFFSET.includes(propertyName)) {
      selectedOverhangElements.forEach((o) => {
        const { thickness, perforationWidth, perforationAngleDeg } = o.settings
        o.settings.perforationMinHeight = this.calcNotchOffset(thickness, perforationWidth, perforationAngleDeg)
      })
    }

    if (isNeededToUpdate) {
      this.onSupportSettingsChanged()

      if (forceUndoRedoCommandCreation) {
        const nextSupportMetadata = JSON.parse(JSON.stringify(supportMetadata))
        const supportUndoRedoCommand = new SupportCommand(
          this.getCommandType,
          prevSupportMetadata,
          nextSupportMetadata,
          this.undoRedoActionHandler,
        )
        this.addCommand(supportUndoRedoCommand)
      }
    }
  }

  undoRedoActionHandler(undoRedoSupportMetadata: BuildPlanItemSupportMetadata) {
    const bpItem = this.getSelectedBuildPlanItems[0]
    const supportMetadata = this.bpItemSettingsMap[bpItem.id]

    if (supportMetadata.settings.overhangAngleDeg !== undoRedoSupportMetadata.settings.overhangAngleDeg) {
      this.onOverhangSettingsChanged('overhangAngleDeg', undoRedoSupportMetadata.settings.overhangAngleDeg, false)
    } else {
      supportMetadata.supports.forEach((support, index) => {
        support.settings = { ...undoRedoSupportMetadata.supports[index].settings }
      })
      this.onSupportSettingsChanged()
    }
  }

  async createSocketConnection() {
    if (this.socket) {
      return this.socket.connected ? undefined : this.socket.connect()
    }

    const connectOptions = {
      rejectUnauthorized: false,
      query: {
        token: this.token,
        tenant: this.getUserDetails && this.getUserDetails.tenant,
        buildPlanId: this.getBuildPlan.id,
      },
      agent: false,
      upgrade: false,
      transports: ['websocket', 'polling'],
      path: '/supports',
      reconnection: false,
    }

    const supportsReady = await this.prepareSupports()
    if (!this.$route.path.endsWith('/support')) {
      // don't try to connect the socket if we've left Support tab prematurely
      return
    }
    this.socket = io(window.env.VUE_APP_SUPPORTS_SVC_URL, connectOptions)

    this.socket.on('connect', () => {
      const parameters = []
      this.partFiles.forEach((file) => parameters.push(file))
      const setupCommand: IInteractiveServiceCommand = {
        parameters,
        id: createGuid(),
        name: InteractiveServiceCommandNames.Setup,
      }

      this.commandMap.set(setupCommand.id, {
        handler: this.handleSetupResponse,
        commandName: setupCommand.name,
      })
      this.socket.emit('command', setupCommand)
      this.isRunning = true
    })

    this.socket.on('message', (msg: IInteractiveServiceMessage, callback) => {
      const sentCommand = this.commandMap.get(msg.id)
      if (sentCommand && msg.status === OperationStatus.Success) {
        sentCommand.handler(msg, sentCommand.buildPlanItemId)
      } else if (msg.status === OperationStatus.Success) {
        console.log(`success message: ${JSON.stringify(msg)}`)
      } else if (msg.status === OperationStatus.Done) {
        // If it is DONE status this means that generate supports command has finished its calculations
        if (!sentCommand) {
          console.warn(msg)
          return
        }
        this.commandMap.delete(msg.id)

        // Check if there is no outstanding overhangs or supports command then turn off loading indication
        if (!this.commandMap.size) {
          if (SUPPORT_COMMAND_NAMES.includes(sentCommand.commandName)) {
            if (this.isSupportButtonAvailable) {
              this.isOkDisabled = false
              this.updateOkStatus()
            }
          }
          // Unset loading status
          this.isRunning = false
          this.isSupportsRendering = false
        }
      } else {
        if (msg.status === OperationStatus.Failure) {
          const support = this.supports.find((el) => el.overhangElementName === msg.item)
          if (support) {
            support.settings = {
              ...support.settings,
              isLoading: false,
              hasSupportError: true,
              hasSupportWarnings: msg.errors && msg.errors.length > 1,
              // Maintain errors for Solid supports, which errors not implemented yet
              supportErrors: msg.errors
                ? msg.errors
                : [{ category: msg.category, code: msg.code, message: msg.message }],
            }
          }
        }
        if (typeof msg.message === 'string') {
          if (!sentCommand) {
            console.warn(msg)
            return
          }
          // Connect / Setup error
          if (
            GLOBAL_SUPPORT_ERROR_MESSAGES.includes(msg.message) ||
            sentCommand.commandName === InteractiveServiceCommandNames.Setup
          ) {
            messageService.showErrorMessage(msg.message)
            this.$emit('closeTool')
          }

          if (msg.item && sentCommand) {
            this.highlightErrorOverhangZone({
              buildPlanItemId: sentCommand.buildPlanItemId,
              overhangZoneName: msg.item,
            })
            this.addFailedOverhangZone({
              buildPlanItemId: sentCommand.buildPlanItemId,
              overhangZoneName: msg.item,
            })
          }

          // Global support generation error
          if (!msg.id) {
            // For now we assume that failed support was the last one
            const [lastCommandId, { buildPlanItemId }] = [...this.commandMap.entries()][this.commandMap.size - 1]
            // For now clear all sent commands from command map
            this.commandMap.clear()
            this.isRunning = false

            if (this.bpItemSettingsMap[buildPlanItemId]) {
              const selectedZones = this.bpItemSettingsMap[buildPlanItemId].selectedElements.map(
                (selected) => selected.overhangElementName,
              )
              if (selectedZones.length) {
                selectedZones.forEach((overhangZoneName) => {
                  this.highlightErrorOverhangZone({ buildPlanItemId, overhangZoneName })
                })
              } else {
                this.highlightErrorOverhangZone({ buildPlanItemId })
              }
            }
          }
        } else if (typeof msg.message === 'object') {
          messageService.showErrorMessage(JSON.stringify(msg.message))
        } else {
          messageService.showErrorMessage('Interactive service returned unexpected error')

          this.commandMap.delete(msg.id)
          // Check if there is no outstanding overhangs or supports command then turn off loading indication
          if (!this.commandMap.size) {
            // Unset loading status
            this.isRunning = false
          }
        }
      }

      if (callback) {
        callback()
      }
    })

    this.socket.io.on('error', (error) => {
      if (!this.socket.connected) {
        this.$emit('triggerSocketDisconnectModal', ConnectionState.FailedToConnect)
      }
    })

    this.socket.on('error', (err) => {
      messageService.showErrorMessage(`Interactive service error: ${err}`)
      // Clear all commands in order to prevent infinity progress
      this.commandMap.clear()
      this.isRunning = false
    })

    this.socket.on('connect_failed', (err) => {
      messageService.showErrorMessage(`Couldn't connect to the interactive service`)
    })

    this.socket.on('connect_error', this.onSocketConnectError)
    this.socket.on('custom_connect_error', this.onSocketConnectError)

    this.socket.on('disconnect', () => {
      this.isRunning = false
      this.commandMap.clear()
      if (!this.isDisconnectTriggeredByUser) {
        this.$emit('triggerSocketDisconnectModal', ConnectionState.SocketClosed)
      }
    })
  }

  closeSocket() {
    if (this.socket) {
      this.socket.disconnect()
    }
  }

  async getPartFiles() {
    if (this.getSelectedBuildPlanItems.length !== 1) {
      throw new Error('Only one build plan item can be selected')
    }

    const selectedBpItem = this.getSelectedBuildPlanItems[0]
    const partId = selectedBpItem.part.id
    // Load list of files for a part
    const partFiles = (await partsService.getFilesByItemId(partId))
      .filter(
        (file) =>
          (file.fileType === FileTypes.DRACO && file.tag === IFileTags.Slice) ||
          file.fileType === FileTypes.DRACO_CONFIG,
      )
      .map((file) => ({ s3filename: file.s3filename, name: file.name }))

    this.partFiles.set(partId, { partId, files: partFiles })

    this.bpItemFilesMap.set(selectedBpItem.id, {
      partId,
      absoluteFilePath: null,
      overhangsFilePath: null,
      supportsFilePath: [],
      constraints: selectedBpItem.constraints,
    })
  }

  handleSetupResponse(msg: IInteractiveServiceMessage) {
    this.isSetupInProgress = false
    this.isRunning = false
    // Remove command handler from cache
    this.commandMap.delete(msg.id)

    const partIdToLocalPathMap = msg.content as { [key: string]: string }
    this.bpItemFilesMap.forEach((bpItemFiles) => {
      bpItemFiles.absoluteFilePath = partIdToLocalPathMap[bpItemFiles.partId]
    })

    const bpItem = this.getSelectedBuildPlanItems[0]
    this.bpItemSettingsMap[bpItem.id].latestTransformation = bpItem.transformationMatrix
    const cmd: IInteractiveServiceCommand = this.generateOverhangCommand(bpItem.id)
    if (bpItem.overhangs) {
      this.commandMap.set(cmd.id, {
        handler: this.handleRegenerateOverhangResponse,
        buildPlanItemId: bpItem.id,
        commandName: cmd.name,
      })
    } else {
      this.commandMap.set(cmd.id, {
        handler: this.handleOverhangResponse,
        buildPlanItemId: bpItem.id,
        commandName: cmd.name,
      })
    }

    this.socket.emit('command', cmd)
  }

  handleOverhangResponse(msg: IInteractiveServiceMessage, buildPlanItemId: string) {
    if (this.bpItemSettingsMap[buildPlanItemId].isLockedOverhangsAndSupportsRendering) {
      return
    }

    const payload = { buildPlanItemId, id: msg.id, config: { drc: msg.content } }
    // TODO: Store overhang mesh file path in this.bpItemFilesMap
    const bpItemFiles = this.bpItemFilesMap.get(buildPlanItemId)
    bpItemFiles.overhangsFilePath = msg.message
    this.lastAddedOverhangId = msg.id
    this.addOverhangMesh(payload)

    const bpItem = this.getBuildPlan.buildPlanItems.find((item) => item.id === buildPlanItemId)

    this.bpItemSettingsMap[bpItem.id].hasTempSupports = true
    this.isSetupInProgress = false
    const supportCommand: IInteractiveServiceCommand[] = this.generateSupportCommand(buildPlanItemId)

    if (supportCommand.length) {
      supportCommand.forEach((command) => {
        this.commandMap.set(command.id, {
          handler: this.handleSupportResponse,
          buildPlanItemId: bpItem.id,
          commandName: command.name,
        })

        this.socket.emit('command', command)
        this.bpItemSettingsMap[bpItem.id].hasTempSupports = true
        this.setUpdatedZones(bpItem.id, (command.parameters as BuildPlanItemSupportSettings).selectedOverhangElements)
      })

      this.toggleBackSupportVisibility()
      this.isRunning = true
      this.isOkDisabled = true
      this.updateOkStatus()
    }
  }

  handleRegenerateOverhangResponse(msg: IInteractiveServiceMessage, buildPlanItemId: string) {
    const bpItemFiles = this.bpItemFilesMap.get(buildPlanItemId)
    bpItemFiles.overhangsFilePath = msg.message
  }

  handleSaveResponse(msg: IInteractiveServiceMessage) {
    // Remove command handler from cache
    this.commandMap.delete(msg.id)

    if (msg.status === OperationStatus.Success) {
      this.resolveIsSavedPromise(msg.content as ISaveFileDto[])
    } else {
      this.rejectIsSavedPromise(msg.message)
    }
  }

  handleSupportResponse(msg: IInteractiveServiceMessage, buildPlanItemId: string) {
    // Support response is split into multiple responses so command is removed from commandMap
    // when DONE status message received
    if (this.bpItemSettingsMap[buildPlanItemId].isLockedOverhangsAndSupportsRendering) {
      return
    }

    const bpItemFiles = this.bpItemFilesMap.get(buildPlanItemId)
    bpItemFiles.supportsFilePath.push(msg.message)
    const supportMetadata = this.bpItemSettingsMap[buildPlanItemId]

    const belongsToOverhangElementName = msg.message.split('/').pop().split('.')[0]

    this.dataToAddSupportMeshFromUndoManager.push({
      belongsToOverhangElementName,
      sdata: msg.content as ArrayBuffer,
    })

    this.addSupportMesh({ buildPlanItemId, belongsToOverhangElementName, sdata: msg.content })
    if (supportMetadata.selectedElements.length) {
      this.highlightSupports({
        buildPlanItemId,
        overhangElementNames: supportMetadata.selectedElements.map((element) => element.overhangElementName),
      })
    }
    const support = this.supports.find((el) => el.overhangElementName === belongsToOverhangElementName)
    support.settings.isLoading = false

    if (msg.errors) {
      this.addFailedOverhangZone({
        buildPlanItemId,
        overhangZoneName: belongsToOverhangElementName,
      })

      support.settings.hasSupportWarnings = true
      support.settings.supportErrors = msg.errors
    }
  }

  generateOverhangMesh(payload: { buildPlanItemId: string; transformation: number[] }) {
    if (this.bpItemSettingsMap[payload.buildPlanItemId].isLockedOverhangsAndSupportsRendering) {
      return
    }

    this.bpItemSettingsMap[payload.buildPlanItemId].latestTransformation = payload.transformation
    this.isRunning = true
    this.sendAbortCommandIfItIsNecessary(payload.buildPlanItemId)
    this.clearSupports({ buildPlanItemId: payload.buildPlanItemId })
    this.dataToAddSupportMeshFromUndoManager = []
    this.bpItemSettingsMap[payload.buildPlanItemId].selectedElements = []
    this.highlightSupports({ buildPlanItemId: payload.buildPlanItemId, overhangElementNames: [] })
    const bpItem = this.getBuildPlan.buildPlanItems.find((item) => item.id === payload.buildPlanItemId)
    const cmd: IInteractiveServiceCommand = this.generateOverhangCommand(bpItem.id)

    this.commandMap.set(cmd.id, {
      handler: this.handleOverhangResponse,
      buildPlanItemId: payload.buildPlanItemId,
      commandName: cmd.name,
    })

    this.socket.emit('command', cmd)
  }

  generateOverhangMeshDebounced(payload: { buildPlanItemId: string; transformation: number[] }) {
    this.bpItemSettingsMap[payload.buildPlanItemId].isLockedOverhangsAndSupportsRendering = false
    this.sendAbortCommandIfItIsNecessary(payload.buildPlanItemId)
    const timeout = this.overhangTimeouts.get(payload.buildPlanItemId)
    if (timeout) {
      clearTimeout(timeout)
    }

    this.overhangTimeouts.set(
      payload.buildPlanItemId,
      setTimeout(this.generateOverhangMesh, DEBOUNCE_TIME, payload) as unknown as number,
    )
  }

  onSelectSupport(payload: { buildPlanItemId: string; overhangZoneName: string; attach: boolean }) {
    const supportMetadata = this.bpItemSettingsMap[payload.buildPlanItemId]

    if (!payload.attach) {
      supportMetadata.selectedElements = []
    }

    if (payload.overhangZoneName) {
      const support = supportMetadata.supports.find((item) => item.overhangElementName === payload.overhangZoneName)
      const selectedSupportIndex = supportMetadata.selectedElements.findIndex((item) => item === support)
      if (selectedSupportIndex === -1) {
        supportMetadata.selectedElements.push(support)
        this.scrollToSupport(support)
      } else {
        supportMetadata.selectedElements.splice(selectedSupportIndex, 1)
      }
    }
  }

  onHoverSupport(payload: { buildPlanItemId: string; overhangZoneName: string }) {
    const supportMetadata = this.bpItemSettingsMap[payload.buildPlanItemId]
    if (payload.overhangZoneName) {
      supportMetadata.hoveredElement = supportMetadata.supports.find(
        (item) => item.overhangElementName === payload.overhangZoneName,
      )
    } else {
      supportMetadata.hoveredElement = null
    }
  }

  //  The function is used to automatically generate support command when overhang angle is changed
  regenerateSupportCommand(
    buildPlanItemId: string,
    overhangElement: string,
    supportMetaData: BuildPlanItemSupport,
  ): IInteractiveServiceCommand {
    const bpItem = this.getBuildPlan.buildPlanItems.find((buildPlanItem) => buildPlanItem.id === buildPlanItemId)
    const indexOfPartFolder = this.bpItemFilesMap.get(buildPlanItemId).absoluteFilePath.search(PARTS_FOLDER)
    const outputSdataFolder = `${this.bpItemFilesMap
      .get(buildPlanItemId)
      .absoluteFilePath.slice(0, indexOfPartFolder)}${SUPPORTS_FOLDER}/${this.getBuildPlan.id}/${bpItem.id}`

    const supportSettings = supportMetaData.settings
    return this.createSupportCommand(buildPlanItemId, supportSettings, [overhangElement], outputSdataFolder)
  }

  generateSupportCommand(buildPlanItemId: string): IInteractiveServiceCommand[] {
    const bpItem = this.getBuildPlan.buildPlanItems.find((buildPlanItem) => buildPlanItem.id === buildPlanItemId)
    const selected = this.bpItemSettingsMap[bpItem.id].selectedElements
    const indexOfPartFolder = this.bpItemFilesMap.get(buildPlanItemId).absoluteFilePath.search(PARTS_FOLDER)
    const outputSdataFolder = `${this.bpItemFilesMap
      .get(buildPlanItemId)
      .absoluteFilePath.slice(0, indexOfPartFolder)}${SUPPORTS_FOLDER}/${this.getBuildPlan.id}/${bpItem.id}`

    return selected.map((overhangElement) => {
      const supportSettings = overhangElement.settings
      const elementName = overhangElement.overhangElementName
      return this.createSupportCommand(buildPlanItemId, supportSettings, [elementName], outputSdataFolder)
    })
  }

  generateBatchSupportCommand(buildPlanItemId: string): IInteractiveServiceCommand {
    const bpItem = this.getBuildPlan.buildPlanItems.find((buildPlanItem) => buildPlanItem.id === buildPlanItemId)
    const bpItemSettings = this.bpItemSettingsMap[buildPlanItemId].settings
    const selected = this.bpItemSettingsMap[buildPlanItemId].selectedElements
    const supportSettings = selected.length ? selected[0].settings : bpItemSettings
    const selectedOverhangElements = selected.length ? selected.map((element) => element.overhangElementName) : ['*']

    const indexOfPartFolder = this.bpItemFilesMap.get(buildPlanItemId).absoluteFilePath.search(PARTS_FOLDER)
    const outputSdataFolder = `${this.bpItemFilesMap
      .get(buildPlanItemId)
      .absoluteFilePath.slice(0, indexOfPartFolder)}${SUPPORTS_FOLDER}/${this.getBuildPlan.id}/${bpItem.id}`

    return this.createSupportCommand(buildPlanItemId, supportSettings, selectedOverhangElements, outputSdataFolder)
  }

  getAlgorithmType(lineShapeType: LineShapeTypes, addOverhangBoundaries: boolean) {
    if (lineShapeType === LineShapeTypes.Parallel) {
      if (addOverhangBoundaries) {
        return TreeAlgorithmTypes.Combined
      }
      return TreeAlgorithmTypes.Lines
    }
    return TreeAlgorithmTypes.Contour
  }

  getContourType(lineShapeType: LineShapeTypes, addOverhangBoundaries: boolean) {
    let contourType

    switch (lineShapeType) {
      case LineShapeTypes.Parallel:
        contourType = ContourTypes.PerimeterAndHolesIn
        break
      case LineShapeTypes.OffsetFromCenter:
        contourType = addOverhangBoundaries ? ContourTypes.InsideOutWrap : ContourTypes.InsideOutNoWrap
        break
      case LineShapeTypes.OffsetOuterBoundary:
        contourType = ContourTypes.PerimeterIn
        break
      case LineShapeTypes.OffsetAllBoundaries:
        contourType = ContourTypes.PerimeterAndHolesIn
        break
      default:
        contourType = ContourTypes.InsideOutNoWrap
    }

    return contourType
  }

  generateOverhangCommand(buildPlanItemId: string): IInteractiveServiceCommand {
    const bpItem = this.getBuildPlan.buildPlanItems.find((buildPlanItem) => buildPlanItem.id === buildPlanItemId)
    const bpItemSettings = this.bpItemSettingsMap[bpItem.id].settings
    const invalidSettings = this.getInvalidSettings(bpItemSettings)
    const defaultSettings = this.createDefaultSettings()

    const overhangCommand: IInteractiveServiceCommand = {
      id: createGuid(),
      name: InteractiveServiceCommandNames.Overhang,
      parameters: {
        modelFilePath: this.bpItemFilesMap.get(bpItem.id).absoluteFilePath,
        modelTransform: this.bpItemSettingsMap[bpItem.id].latestTransformation,
        overhangAngleDeg: invalidSettings.get('overhangAngleDeg')
          ? defaultSettings.overhangAngleDeg
          : bpItemSettings.overhangAngleDeg,
        minRequiredTrianglesArea: bpItemSettings.minRequiredTrianglesArea,
        minRequiredConnectedEdgesLength: bpItemSettings.minRequiredConnectedEdgesLength,
        edgeLaminarAngleDeg: bpItemSettings.edgeLaminarAngleDeg,
        elementsCanBeCoincident: bpItemSettings.elementsCanBeCoincident,
        outputFilePath: this.bpItemFilesMap
          .get(bpItem.id)
          .absoluteFilePath.replace(
            `${PARTS_FOLDER}/${bpItem.part.id}`,
            `${SUPPORTS_FOLDER}/${this.getBuildPlan.id}/${bpItem.id}`,
          )
          .replace('.drc', '_overhang.drc'),
      },
    }

    return overhangCommand
  }

  generateOverhangsAndSupportsForSelectedItem(payload: { buildPlanItemId: string; transformation: number[] }) {
    if (this.isSetupInProgress) {
      return
    }

    const bpItem = this.getBuildPlan.buildPlanItems.find((item) => item.id === payload.buildPlanItemId)
    const supportMetadata = this.bpItemSettingsMap[bpItem.id]
    if ((bpItem.overhangs && bpItem.supports && bpItem.supports.length) || supportMetadata.hasTempSupports) {
      this.highlightSupports({
        buildPlanItemId: bpItem.id,
        overhangElementNames: supportMetadata.selectedElements.map((element) => element.overhangElementName),
      })
      return
    }

    this.generateOverhangMesh(payload)
  }

  generateAbortCommand(buildPlanItemId: string, predicate?: (value) => boolean) {
    const runningCommndsForBpItem = []
    for (const [commandId, commandOptions] of this.commandMap) {
      if (commandOptions.buildPlanItemId === buildPlanItemId) {
        if (predicate) {
          if (predicate(commandOptions)) {
            runningCommndsForBpItem.push(commandId)
          }
        } else {
          runningCommndsForBpItem.push(commandId)
        }
      }
    }

    const abortCommand: IInteractiveServiceCommand = {
      id: createGuid(),
      name: InteractiveServiceCommandNames.Abort,
      parameters: { ids: runningCommndsForBpItem },
    }

    return abortCommand
  }

  sendAbortCommandIfItIsNecessary(buildPlanItemId: string, predicate?: (value) => boolean) {
    const abortCommand = this.generateAbortCommand(buildPlanItemId, predicate)
    if ((abortCommand.parameters as { ids: string[] }).ids.length) {
      this.commandMap.set(abortCommand.id, {
        buildPlanItemId,
        handler: null,
        commandName: abortCommand.name,
      })
      ;(abortCommand.parameters as { ids: string[] }).ids.forEach((commandId) => {
        this.commandMap.delete(commandId)
      })

      this.socket.emit('command', abortCommand)
    }
  }

  onSupportSettingsChanged() {
    const isValid = this.validateSupportSettings(this.selectedOverhangElements)
    if (!isValid) {
      return
    }

    const bpItem = this.getSelectedBuildPlanItems[0]
    if (bpItem) {
      this.sendAbortCommandIfItIsNecessary(
        bpItem.id,
        (commandOption) =>
          commandOption.commandName !== InteractiveServiceCommandNames.Overhang &&
          commandOption.commandName !== InteractiveServiceCommandNames.Setup,
      )

      const selectedElements = this.bpItemSettingsMap[bpItem.id].selectedElements
      const overhangElementsToClear = selectedElements.length
        ? selectedElements.map((element) => element.overhangElementName)
        : null
      this.setDefaultOverhangMaterial({ overhangElementsToClear, buildPlanItemId: bpItem.id })
      this.clearSupports({ overhangElementsToClear, buildPlanItemId: bpItem.id })
      this.dataToAddSupportMeshFromUndoManager = []
      this.clearSupportErrorMessages(selectedElements)
      this.addSupportLoading(selectedElements)

      const runningCommndsForBpItem = []
      for (const [commandId, commandOptions] of this.commandMap) {
        if (
          commandOptions.buildPlanItemId === bpItem.id &&
          (commandOptions.commandName === InteractiveServiceCommandNames.Overhang ||
            commandOptions.commandName === InteractiveServiceCommandNames.Setup)
        ) {
          runningCommndsForBpItem.push(commandId)
        }
      }

      // Send support command only if overhang or setup commands are already finished
      if (!runningCommndsForBpItem.length) {
        const supportCommand = this.generateSupportCommand(bpItem.id)
        if (supportCommand.length) {
          supportCommand.forEach((command) => {
            this.commandMap.set(command.id, {
              handler: this.handleSupportResponse,
              commandName: command.name,
              buildPlanItemId: bpItem.id,
            })

            this.socket.emit('command', command)
            this.bpItemSettingsMap[bpItem.id].hasTempSupports = true
            this.setUpdatedZones(
              bpItem.id,
              (command.parameters as BuildPlanItemSupportSettings).selectedOverhangElements,
            )
          })

          this.toggleBackSupportVisibility()
          this.isRunning = true
          this.isOkDisabled = true
          this.isSupportsRendering = true
          this.updateOkStatus()
        }
      }
    }
  }

  onOverhangSettingsChanged(
    propertyName: string,
    value: SupportPropertyValue,
    forceUndoRedoCommandCreation: boolean = true,
  ) {
    if (this.isOverhangSettingsChanged(propertyName, value)) {
      const bpItem = this.getSelectedBuildPlanItems[0]
      const supportMetadata = this.bpItemSettingsMap[bpItem.id]
      const prevSupportMetadata = JSON.parse(JSON.stringify(supportMetadata))

      this.updateOverhangSettingsValue(propertyName, value)

      if (!this.validateOverhangSettings()) {
        return
      }

      supportMetadata.supports.forEach((support) => {
        this.bpItemSupportSettingsMap.set(support.overhangElementName, support)
      })

      this.getSelectedBuildPlanItems.forEach((selectedItem) => {
        if (selectedItem) {
          this.sendAbortCommandIfItIsNecessary(
            selectedItem.id,
            (commandOption) => commandOption.commandName !== InteractiveServiceCommandNames.Setup,
          )

          this.clearOverhangMesh(selectedItem.id)
          this.clearSupports({ buildPlanItemId: selectedItem.id })
          this.dataToAddSupportMeshFromUndoManager = []
          this.bpItemSettingsMap[selectedItem.id].selectedElements = []
          this.highlightSupports({ buildPlanItemId: selectedItem.id, overhangElementNames: [] })
          const runningCommndsForBpItem = []
          for (const [commandId, commandOptions] of this.commandMap) {
            if (
              commandOptions.buildPlanItemId === selectedItem.id &&
              commandOptions.commandName === InteractiveServiceCommandNames.Setup
            ) {
              runningCommndsForBpItem.push(commandId)
            }
          }

          // Send overhang command only if setup command is already finished
          if (!runningCommndsForBpItem.length) {
            this.overhangCommand = this.generateOverhangCommand(selectedItem.id)
            this.commandMap.set(this.overhangCommand.id, {
              handler: this.handleOverhangResponse,
              buildPlanItemId: selectedItem.id,
              commandName: this.overhangCommand.name,
            })

            this.socket.emit('command', this.overhangCommand)
            this.isSetupInProgress = true
            this.isRunning = true

            if (forceUndoRedoCommandCreation) {
              const nextSupportMetadata = JSON.parse(JSON.stringify(supportMetadata))
              const supportUndoRedoCommand = new SupportCommand(
                this.getCommandType,
                prevSupportMetadata,
                nextSupportMetadata,
                this.undoRedoActionHandler,
              )
              this.addCommand(supportUndoRedoCommand)
            }
          }
        }
      })
    }
  }

  onGetOverhangElements(payload: {
    elements: Array<{
      overhangElementName: string
      overhangElementType: SupportedElementTypes
      area?: number
      obb?: number[]
    }>
    bpItemId: string
    overhangContourMap: Map<string, string[]>
  }) {
    let supports: BuildPlanItemSupport[] = []
    if (payload.overhangContourMap.size === 0) {
      supports = payload.elements.map((element) => {
        return {
          fileKey: null,
          settings: this.createDefaultSupportSettings(),
          overhangElementType: element.overhangElementType,
          overhangElementName: element.overhangElementName,
          awsFileKey: null,
          groupId: null,
          overhangArea: element.area,
          overhangObb: element.obb,
        }
      })
    } else {
      payload.overhangContourMap.forEach((value: string[], key: string) => {
        let isNewOverhang = true
        let buildPlanSupport: BuildPlanItemSupport
        this.bpItemSupportSettingsMap.forEach((support: BuildPlanItemSupport, name: string) => {
          if (name === value[0]) {
            isNewOverhang = false
            buildPlanSupport = {
              fileKey: support.fileKey,
              settings: JSON.parse(JSON.stringify(support.settings)),
              overhangElementType: support.overhangElementType,
              overhangElementName: key,
              awsFileKey: support.awsFileKey,
              groupId: support.groupId,
              overhangArea: support.overhangArea,
              overhangObb: support.overhangObb,
            }

            const c = support
            const d = value[0]
            const command = this.regenerateSupportCommand(payload.bpItemId, key, c)
            this.commandMap.set(command.id, {
              handler: this.handleSupportResponse,
              commandName: command.name,
              buildPlanItemId: payload.bpItemId,
            })

            this.socket.emit('command', command)
            this.bpItemSettingsMap[payload.bpItemId].hasTempSupports = true
            this.setUpdatedZones(
              payload.bpItemId,
              (command.parameters as BuildPlanItemSupportSettings).selectedOverhangElements,
            )

            this.toggleBackSupportVisibility()
            this.isRunning = true
            this.isOkDisabled = true
            this.isSupportsRendering = true
            this.updateOkStatus()
          }
        })

        if (isNewOverhang) {
          payload.elements.forEach((element) => {
            if (element.overhangElementName === key) {
              buildPlanSupport = {
                fileKey: null,
                settings: this.createDefaultSupportSettings(),
                overhangElementType: SupportedElementTypes.Area,
                overhangElementName: key,
                awsFileKey: null,
                groupId: null,
                overhangArea: element.area,
                overhangObb: element.obb,
              }
              return
            }
          })
        }

        supports.push(buildPlanSupport)
      })
    }

    if (supports) {
      supports
        .sort(
          (a, b) =>
            +a.overhangElementName.slice(a.overhangElementName.lastIndexOf('_') + 1) -
            +b.overhangElementName.slice(b.overhangElementName.lastIndexOf('_') + 1),
        )
        .sort((a, b) => a.overhangElementType.localeCompare(b.overhangElementType))
    }

    this.bpItemSettingsMap[payload.bpItemId].supports = supports

    this.selectOverhangElement(this.bpItemSettingsMap[this.getSelectedBuildPlanItems[0].id].supports[0], {
      ctrlKey: false,
      shiftKey: false,
      metaKey: false,
    })
  }

  onLockOverhangAndSupportsRendering(bpItemId: string) {
    this.bpItemSettingsMap[bpItemId].isLockedOverhangsAndSupportsRendering = true
  }

  get getOverhangAngleDegRules() {
    const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxSurfaceAngle')

    return {
      rules: {
        required: true,
        min_value: 0,
        max_value: 90,
      },
      customMessages: {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
        max_value: validationErrorMessage,
      },
    }
  }

  get getPartElevationRules() {
    const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxPartElevation')

    return {
      rules: {
        required: true,
        min_value: 0,
        max_value: 500,
      },
      customMessages: {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
        max_value: validationErrorMessage,
      },
    }
  }

  get getOverhangSampleDirectionDegRules() {
    const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxAngleToRecoater')

    return {
      rules: {
        required: true,
        min_value: 0,
        max_value: 180,
      },
      customMessages: {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
        max_value: validationErrorMessage,
      },
    }
  }

  get getLineBaseThicknessRules() {
    const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxSupportThickness')
    const maxConnectionWidthValue = Number.parseFloat(
      this.getSelectedSupportPropertyMinMaxValue('neckWidth', true).toFixed(5),
    )

    const thickness = this.selectedOverhangElementsSettings.settings.thickness

    if (typeof thickness === 'number' && thickness <= maxConnectionWidthValue) {
      const errorMessage = this.$t('supportTool.validationMessages.validMinMaxThicknessNeckWidth', {
        connectionThickness: maxConnectionWidthValue,
      })

      return {
        rules: {
          required: true,
          min_value: maxConnectionWidthValue + MAX_NECK_WIDTH_DECREASE_VALUE,
          max_value: 20,
        },
        customMessages: {
          required: errorMessage,
          min_value: errorMessage,
          max_value: errorMessage,
        },
      }
    }
    return {
      rules: {
        required: true,
        min_value: 0.01,
        max_value: 20,
      },
      customMessages: {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
        max_value: validationErrorMessage,
      },
    }
  }

  get getLineBaseGapRules() {
    let rules: { required: boolean; min_value: number; max_value?: number } = null
    let customMessages = null

    const minLineBaseGapValue = 0.5
    const maxLineBaseGapValue = Number.parseFloat(
      (
        this.getSelectedSupportPropertyMinMaxValue('maxConnectionSpacing') +
        this.getSelectedSupportPropertyMinMaxValue('neckWidth') -
        this.getSelectedSupportPropertyMinMaxValue('thickness', true)
      ).toFixed(5),
    )

    if (minLineBaseGapValue < maxLineBaseGapValue) {
      const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxSupportSpacing', {
        maxLineBaseGapValue,
      })

      rules = {
        required: true,
        min_value: minLineBaseGapValue,
        max_value: maxLineBaseGapValue,
      }
      customMessages = {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
        max_value: validationErrorMessage,
      }
    } else {
      const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxSupportSpacing', {
        maxLineBaseGapValue: '...',
      })

      rules = {
        required: true,
        min_value: minLineBaseGapValue,
      }
      customMessages = {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
      }
    }

    return {
      rules,
      customMessages,
    }
  }

  get getNeckWidthRules() {
    const maxNeckWidthValue = Number.parseFloat(
      (this.minSelectedSupportsThickness - MAX_NECK_WIDTH_DECREASE_VALUE).toFixed(5),
    )
    const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxConnectionThickness', {
      supportThickness: maxNeckWidthValue,
    })

    return {
      rules: {
        required: true,
        min_value: 0.01,
        max_value: maxNeckWidthValue,
      },
      customMessages: {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
        max_value: validationErrorMessage,
      },
    }
  }

  get getNeckHeightRules() {
    const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxConnectionOffset')

    return {
      rules: {
        required: true,
        min_value: 0,
        max_value: 20,
      },
      customMessages: {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
        max_value: validationErrorMessage,
      },
    }
  }

  get getPerforationWidthRules() {
    let rules: { required: boolean; min_value: number; max_value: number } = null
    let customMessages = null
    const maxPerforationWidthValue = this.minSelectedSupportsThickness - MAX_PERFORATION_WIDTH_DECREASE_VALUE

    if (this.selectedOverhangElementsSettings && this.selectedOverhangElementsSettings.settings.addPerforations) {
      rules = {
        required: true,
        min_value: 0.01,
        max_value: maxPerforationWidthValue,
      }
      const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxNotchThickness', {
        supportThickness: maxPerforationWidthValue,
      })
      customMessages = {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
        max_value: validationErrorMessage,
      }
    }

    return {
      rules,
      customMessages,
    }
  }

  get getPerforationAngleDegRules() {
    let rules: { required: boolean; min_value: number; max_value: number } = null
    let customMessages = null

    if (this.selectedOverhangElementsSettings && this.selectedOverhangElementsSettings.settings.addPerforations) {
      rules = {
        required: true,
        min_value: 60,
        max_value: 120,
      }
      const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxNotchAngle')
      customMessages = {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
        max_value: validationErrorMessage,
      }
    }

    return {
      rules,
      customMessages,
    }
  }

  get getBlendRadiusRules() {
    let rules: { required: boolean; min_value: number; max_value?: number } = null
    let customMessages = null

    if (this.selectedOverhangElementsSettings && this.selectedOverhangElementsSettings.settings.addBlend) {
      if (this.selectedOverhangElementsSettings.settings.strategy !== this.supportTypes.Solid) {
        rules = {
          required: true,
          min_value: 0.01,
          max_value: this.minSelectedSupportsGap / 2,
        }
      } else {
        rules = {
          required: true,
          min_value: 0.01,
          max_value: 20,
        }
      }
      const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxBlendRadius', {
        maxBlendRadius: rules ? rules.max_value : 20,
      })
      customMessages = {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
        max_value: validationErrorMessage,
      }
    }

    return {
      rules,
      customMessages,
    }
  }

  get getAttachmentDepthRules() {
    let rules: { required: boolean; min_value: number; max_value: number } = null
    let customMessages = null
    const maxAttachmentDepthValue = this.minSelectedParameterLayerThickness * MAX_ATTACHMENT_DEPTH_MULTIPLIER

    if (this.selectedOverhangElementsSettings && this.selectedOverhangElement.settings.parameterLayerThickness) {
      rules = {
        required: true,
        min_value: 0,
        max_value: maxAttachmentDepthValue,
      }
      const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxAttachmentDepth', {
        layerThickness: maxAttachmentDepthValue,
      })
      customMessages = {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
        max_value: validationErrorMessage,
      }
    }

    return {
      rules,
      customMessages,
    }
  }

  get getMaxConnectionSpacingRules() {
    let rules: { required: boolean; min_value?: number; max_value: number } = null
    let customMessages = null

    const minConnectionSpacingValue = Number.parseFloat(
      (
        this.getSelectedSupportPropertyMinMaxValue('minSupportSpacing', true) +
        this.getSelectedSupportPropertyMinMaxValue('thickness', true) -
        this.getSelectedSupportPropertyMinMaxValue('neckWidth')
      ).toFixed(5),
    )
    const maxConnectionSpacingValue = MAX_CONNECTION_SPACING

    if (minConnectionSpacingValue < maxConnectionSpacingValue) {
      const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxConnectionSpacing', {
        minConnectionSpacingValue,
      })

      rules = {
        required: true,
        min_value: minConnectionSpacingValue,
        max_value: maxConnectionSpacingValue,
      }
      customMessages = {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
        max_value: validationErrorMessage,
      }
    } else {
      const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxConnectionSpacing', {
        minConnectionSpacingValue: '...',
      })

      rules = {
        required: true,
        max_value: maxConnectionSpacingValue,
      }
      customMessages = {
        required: validationErrorMessage,
        max_value: validationErrorMessage,
      }
    }

    return {
      rules,
      customMessages,
    }
  }

  get getMaxSegmentLengthRules() {
    const minSegmentLengthValue = this.getSelectedSupportPropertyMinMaxValue('thickness', true)

    const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxSegmentLength', {
      minSegmentLengthValue,
    })

    return {
      rules: {
        required: true,
        min_value: minSegmentLengthValue,
        max_value: 25,
      },
      customMessages: {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
        max_value: validationErrorMessage,
      },
    }
  }

  get getMinSegmentBreakRules() {
    let rules: { required: boolean; min_value?: number; max_value: number } = null
    let customMessages = null

    if (this.selectedOverhangElementsSettings.overhangElementType === SupportedElementTypes.Area) {
      const maxSegmentBreakValue = this.getSelectedSupportPropertyMinMaxValue('maxConnectionSpacing')

      const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxSegmentBreak', {
        maxSegmentBreakValue,
      })

      rules = {
        required: true,
        min_value: 0.5,
        max_value: maxSegmentBreakValue,
      }

      customMessages = {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
        max_value: validationErrorMessage,
      }
    } else {
      const validationErrorMessage = this.$t('supportTool.validationMessages.validMinMaxSegmentBreak', {
        maxSegmentBreakValue: MAX_CONNECTION_SPACING,
      })

      rules = {
        required: true,
        min_value: 0.5,
        max_value: MAX_CONNECTION_SPACING,
      }

      customMessages = {
        required: validationErrorMessage,
        min_value: validationErrorMessage,
        max_value: validationErrorMessage,
      }
    }

    return {
      rules,
      customMessages,
    }
  }

  validateOverhangSettings() {
    return this.getSelectedBuildPlanItems.every((bpItem) => {
      const bpItemSettings = this.bpItemSettingsMap[bpItem.id].settings
      return (
        bpItemSettings.overhangAngleDeg &&
        bpItemSettings.overhangAngleDeg >= this.getOverhangAngleDegRules.rules.min_value &&
        bpItemSettings.overhangAngleDeg <= this.getOverhangAngleDegRules.rules.max_value
      )
    })
  }

  isOverhangSettingsChanged(propertyName: string, value: SupportPropertyValue) {
    return this.getSelectedBuildPlanItems.every((bpItem) => {
      const bpItemSettings = this.bpItemSettingsMap[bpItem.id].settings
      return bpItemSettings[propertyName] !== value
    })
  }

  updateOverhangSettingsValue(propertyName: string, value: SupportPropertyValue) {
    this.getSelectedBuildPlanItems.forEach((bpItem) => {
      const bpItemSettings = this.bpItemSettingsMap[bpItem.id].settings
      bpItemSettings[propertyName] = value
    })
  }

  validateSupportSettings(supports: BuildPlanItemSupport[]) {
    return supports.every((support) => {
      const settings = support.settings

      if (settings.strategy === SupportTypes.NoSupports) {
        return true
      }

      if (
        !settings.thickness ||
        settings.thickness < this.getLineBaseThicknessRules.rules.min_value ||
        settings.thickness > this.getLineBaseThicknessRules.rules.max_value
      ) {
        return false
      }

      if (
        !settings.neckWidth ||
        settings.neckWidth < this.getNeckWidthRules.rules.min_value ||
        settings.neckWidth > settings.thickness - MAX_NECK_WIDTH_DECREASE_VALUE
      ) {
        return false
      }

      if (
        !settings.neckHeight ||
        settings.neckHeight < this.getNeckHeightRules.rules.min_value ||
        settings.neckHeight > this.getNeckHeightRules.rules.max_value
      ) {
        return false
      }

      if (
        !settings.maxSegmentLength ||
        settings.maxSegmentLength < settings.thickness ||
        settings.maxSegmentLength > this.getMaxSegmentLengthRules.rules.max_value
      ) {
        return false
      }
      if (
        !settings.minSegmentBreak ||
        settings.minSegmentBreak < this.getMinSegmentBreakRules.rules.min_value ||
        (support.overhangElementType === SupportedElementTypes.Area &&
          settings.minSegmentBreak > settings.maxConnectionSpacing) ||
        (support.overhangElementType !== SupportedElementTypes.Area &&
          settings.minSegmentBreak > MAX_CONNECTION_SPACING)
      ) {
        return false
      }

      if (
        this.getPerforationWidthRules.rules &&
        (!settings.perforationWidth ||
          settings.perforationWidth < this.getPerforationWidthRules.rules.min_value ||
          settings.perforationWidth > settings.thickness - MAX_PERFORATION_WIDTH_DECREASE_VALUE)
      ) {
        return false
      }

      if (
        this.getPerforationAngleDegRules.rules &&
        (!settings.perforationAngleDeg ||
          settings.perforationAngleDeg < this.getPerforationAngleDegRules.rules.min_value ||
          settings.perforationAngleDeg > this.getPerforationAngleDegRules.rules.max_value)
      ) {
        return false
      }

      if (
        this.getBlendRadiusRules.rules &&
        (!settings.blendRadius ||
          settings.blendRadius < this.getBlendRadiusRules.rules.min_value ||
          settings.blendRadius > this.getBlendRadiusRules.rules.max_value)
      ) {
        return false
      }

      if (
        this.getAttachmentDepthRules.rules &&
        (!settings.attachmentDepth ||
          settings.attachmentDepth < this.getAttachmentDepthRules.rules.min_value ||
          settings.attachmentDepth > settings.parameterLayerThickness * MAX_ATTACHMENT_DEPTH_MULTIPLIER)
      ) {
        return false
      }

      if (support.overhangElementType === SupportedElementTypes.Area) {
        if (
          settings.overhangSampleDirectionDeg === null ||
          settings.overhangSampleDirectionDeg === undefined ||
          settings.overhangSampleDirectionDeg < this.getOverhangSampleDirectionDegRules.rules.min_value ||
          settings.overhangSampleDirectionDeg > this.getOverhangSampleDirectionDegRules.rules.max_value
        ) {
          return false
        }

        if (
          !settings.minSupportSpacing ||
          settings.minSupportSpacing < this.getLineBaseGapRules.rules.min_value ||
          settings.minSupportSpacing > settings.maxConnectionSpacing + settings.neckWidth - settings.thickness
        ) {
          return false
        }

        if (
          !settings.maxConnectionSpacing ||
          settings.maxConnectionSpacing < settings.minSupportSpacing + settings.thickness - settings.neckWidth ||
          settings.maxConnectionSpacing > this.getMaxConnectionSpacingRules.rules.max_value
        ) {
          return false
        }
      }

      return true
    })
  }

  validatePartElevationSettings(partElevation: number) {
    return !(
      partElevation < this.getPartElevationRules.rules.min_value ||
      partElevation > this.getPartElevationRules.rules.max_value
    )
  }

  getInvalidSettings(bpItemSettings: IBuildPlanItemSettings) {
    const invalidSettings: Map<string, boolean> = new Map()
    if (
      !bpItemSettings.overhangAngleDeg ||
      bpItemSettings.overhangAngleDeg < this.getOverhangAngleDegRules.rules.min_value ||
      bpItemSettings.overhangAngleDeg > this.getOverhangAngleDegRules.rules.max_value
    ) {
      invalidSettings.set('overhangAngleDeg', true)
    }

    return invalidSettings
  }

  toggleBackSupportVisibility() {
    if (!this.displayToolbarStateByVariantId(this.getBuildPlan.id).isShowingSupportGeometry) {
      this.setIsShowingSupports(!this.displayToolbarStateByVariantId(this.getBuildPlan.id).isShowingSupportGeometry)
      const items = []
      this.getBuildPlan.buildPlanItems.forEach((bpItem) => {
        items.push({ buildPlanItemId: bpItem.id })
      })
      this.setGeometriesVisibility({
        items,
        geometryType: GeometryType.Support,
        visibility: this.displayToolbarStateByVariantId(this.getBuildPlan.id).isShowingSupportGeometry,
      })
    }
  }

  beforeDestroy() {
    eventBus.$off(InteractiveServiceEvents.GenerateOverhangMeshDebounced, this.generateOverhangMeshDebounced)
    eventBus.$off(
      InteractiveServiceEvents.GenerateOverhanhgMeshByClick,
      this.generateOverhangsAndSupportsForSelectedItem,
    )
    eventBus.$off(InteractiveServiceEvents.GetOverhangElements, this.onGetOverhangElements)
    eventBus.$off(InteractiveServiceEvents.LockOverhangsAndSupportsRendering, this.onLockOverhangAndSupportsRendering)
    eventBus.$off(InteractiveServiceEvents.SelectSupport, this.onSelectSupport)
    eventBus.$off(InteractiveServiceEvents.HoverSupport, this.onHoverSupport)
    eventBus.$off(InteractiveServiceEvents.OnPartElevate, this.handlePartElevateEvent)
    this.isDisconnectTriggeredByUser = true
    this.closeSocket()

    this.clearPartElevationRequests()
    if (!this.isCloseToolHandled) {
      this.clickCancel()
    }

    this.setSelectionModeAndReselect({ mode: SelectionUnit.Part })
    this.setOverhangMeshVisibility({
      items: this.getBuildPlan.buildPlanItems.map((bpItem) => ({ buildPlanItemId: bpItem.id })),
      visibility: false,
    })
  }

  selectOverhangElement(support: BuildPlanItemSupport, event = null) {
    if (support) {
      const { ctrlKey, shiftKey, metaKey } = event

      const bpItem = this.getSelectedBuildPlanItems[0]
      const supportMetadata = this.bpItemSettingsMap[bpItem.id]

      if (!this.lastSelectedSupport) {
        this.lastSelectedSupport = supportMetadata.selectedElements[0]
      }

      if (
        shiftKey &&
        !!this.lastSelectedSupport &&
        this.lastSelectedSupport.overhangElementName !== support.overhangElementName
      ) {
        const prevIndex = supportMetadata.supports.findIndex(
          (o) => this.lastSelectedSupport.overhangElementName === o.overhangElementName,
        )
        const curIndex = supportMetadata.supports.findIndex(
          (o) => support.overhangElementName === o.overhangElementName,
        )

        const start = prevIndex < curIndex ? prevIndex : curIndex
        const end = prevIndex < curIndex ? curIndex : prevIndex

        supportMetadata.selectedElements = []

        // tslint:disable-next-line: no-increment-decrement
        for (let i = start; i <= end; i++) {
          const curItem = supportMetadata.supports[i]
          supportMetadata.selectedElements.push(curItem)
        }
        // On Macintosh keyboards the metaKey will be true when Command key is pressed.
      } else if (ctrlKey || metaKey) {
        if (supportMetadata.selectedElements.some((e) => e.overhangElementName === support.overhangElementName)) {
          if (supportMetadata.selectedElements.length === 1) {
            return
          }
          supportMetadata.selectedElements = supportMetadata.selectedElements.filter(
            (e) => e.overhangElementName !== support.overhangElementName,
          )
        } else {
          supportMetadata.selectedElements.push(support)
          this.lastSelectedSupport = support
        }
      } else {
        if (
          supportMetadata.selectedElements.some((e) => e.overhangElementName === support.overhangElementName) &&
          supportMetadata.selectedElements.length === 1
        ) {
          return
        }
        supportMetadata.selectedElements = [support]
        this.lastSelectedSupport = support
      }

      this.highlightSupports({
        buildPlanItemId: bpItem.id,
        overhangElementNames: supportMetadata.selectedElements.map((element) => element.overhangElementName),
      })
    } else {
      messageService.showErrorMessage('There is no overhang element with such name')
    }
  }

  hoverOverhangElement(support?: BuildPlanItemSupport) {
    const bpItem = this.getSelectedBuildPlanItems[0]
    const supportMetadata = this.bpItemSettingsMap[bpItem.id]

    if (support) {
      supportMetadata.hoveredElement = support
      this.hoverSupport({ buildPlanItemId: bpItem.id, overhangElementName: support.overhangElementName })
    } else {
      supportMetadata.hoveredElement = null
      this.hoverSupport({ buildPlanItemId: bpItem.id, overhangElementName: null })
    }
  }

  get lineShapeTypes() {
    const types = [
      { id: LineShapeTypes.Parallel, name: this.$t(`lineShape.parallel`) },
      { id: LineShapeTypes.OffsetFromCenter, name: this.$t(`lineShape.offsetFromCenter`) },
    ]

    if (this.selectedOverhangElementsSettings.settings.addOverhangBoundaries) {
      types.push(
        { id: LineShapeTypes.OffsetAllBoundaries, name: this.$t(`lineShape.offsetAllBoundaries`) },
        { id: LineShapeTypes.OffsetOuterBoundary, name: this.$t(`lineShape.offsetOuterBoundary`) },
      )
    }

    if (this.selectedOverhangElementsSettings.settings.contourType === LineShapeTypes.Multiple) {
      types.push({ id: LineShapeTypes.Multiple, name: this.$t(`lineShape.multiple`) })
    }

    return types
  }

  hasSameOverhangType(support: BuildPlanItemSupport) {
    const bpItem = this.getSelectedBuildPlanItems[0]
    const supportMetadata = this.bpItemSettingsMap[bpItem.id]

    return supportMetadata.supports
      .filter((s) => s !== support)
      .some((s) => s.overhangElementType === support.overhangElementType)
  }

  get getOverhangAngle(): number {
    return this.supportSettings && typeof this.supportSettings.overhangAngleDeg === 'number'
      ? this.supportSettings.overhangAngleDeg
      : this.defaultOverhangAngle
  }

  get getPartElevation(): number {
    return this.supportSettings && typeof this.supportSettings.partElevation === 'number'
      ? this.supportSettings.partElevation
      : this.defaultPartElevation
  }

  get partParameterMenuOptions(): Array<{ id: number; name: string }> {
    const parameterSets = this.parameterSetsLatestVersions || []
    const dropDownItems = parameterSets.map((ps) => {
      const layerThickness = parseFloat(convert('m', 'mm', ps.layerThickness).toPrecision(3))
      const thicknessText = ps.id ? ` - ${layerThickness}mm` : ''
      const name = ps.name + thicknessText
      return { name, id: ps.id }
    })
    if (this.defaultPartParamOptionAvailable) {
      dropDownItems.unshift({
        id: null,
        name: defaultNameBasedOnSupportType(this.selectedOverhangElementsSettings.settings.strategy as SupportTypes),
      })
    }
    return dropDownItems
  }

  handlePartElevateEvent(payload: { buildPlanItemId: string; transformation: number[] }) {
    const supportMetadata = this.bpItemSettingsMap[payload.buildPlanItemId]
    if (!supportMetadata) {
      return
    }

    supportMetadata.latestTransformation = payload.transformation
    supportMetadata.isLockedOverhangsAndSupportsRendering = false
    const prevSelectedElements = supportMetadata.selectedElements
    supportMetadata.selectedElements = supportMetadata.supports
    this.onSupportSettingsChanged()
    supportMetadata.selectedElements = prevSelectedElements
  }

  get isAngleToRecoater() {
    if (!this.hasSurfaceOverhangType) {
      return false
    }

    return this.selectedOverhangElements.some(
      (el) => el.settings.strategy === this.supportTypes.Lines1 && el.settings.contourType === LineShapeTypes.Parallel,
    )
  }

  get isConnectionThicknessDisabled() {
    return this.selectedOverhangElementsSettings.settings.neckHeight === 0
  }

  get hasSurfaceOverhangType() {
    return this.selectedOverhangElements.some((el) => el.overhangElementType === SupportedElementTypes.Area)
  }

  get hasEdgeOverhangType() {
    return this.selectedOverhangElements.some((el) => el.overhangElementType === SupportedElementTypes.Edge)
  }

  get isStrategyNotSolid() {
    return this.selectedOverhangElementsSettings.settings.strategy !== this.supportTypes.Solid
  }

  get minSelectedSupportsThickness() {
    if (!this.selectedOverhangElements.length) {
      return
    }

    if (this.selectedOverhangElementsSettings.settings.thickness !== null) {
      return this.selectedOverhangElementsSettings.settings.thickness
    }

    return this.selectedOverhangElements.reduce((acc, el) => {
      return acc > el.settings.thickness ? el.settings.thickness : acc
    }, this.selectedOverhangElement.settings.thickness)
  }

  get minSelectedSupportsGap() {
    if (!this.selectedOverhangElements.length) {
      return
    }

    if (this.selectedOverhangElementsSettings.settings.minSupportSpacing !== null) {
      return this.selectedOverhangElementsSettings.settings.minSupportSpacing
    }

    return this.selectedOverhangElements.reduce((acc, el) => {
      return acc > el.settings.minSupportSpacing ? el.settings.minSupportSpacing : acc
    }, this.selectedOverhangElement.settings.minSupportSpacing)
  }

  get minSelectedParameterLayerThickness() {
    if (!this.selectedOverhangElements.length) {
      return
    }

    if (this.selectedOverhangElementsSettings.settings.parameterLayerThickness !== null) {
      return this.selectedOverhangElementsSettings.settings.parameterLayerThickness
    }

    return this.selectedOverhangElements.reduce((acc, el) => {
      return acc > el.settings.parameterLayerThickness ? el.settings.parameterLayerThickness : acc
    }, this.selectedOverhangElement.settings.parameterLayerThickness)
  }

  get isSingleOverhang() {
    return this.bpItemSettingsMap[this.getSelectedBuildPlanItems[0].id].supports.length === 1
  }

  getSelectedSupportPropertyMinMaxValue(property: string, getMax = false) {
    if (!this.selectedOverhangElements.length) {
      return
    }

    if (this.selectedOverhangElementsSettings.settings[property] !== null) {
      return this.selectedOverhangElementsSettings.settings[property]
    }

    return this.selectedOverhangElements.reduce((acc, el) => {
      if (getMax) {
        return acc > el.settings[property] ? el.settings[property] : acc
      }

      return acc < el.settings[property] ? el.settings[property] : acc
    }, this.selectedOverhangElement.settings[property])
  }

  scrollToSupport(support: BuildPlanItemSupport) {
    setTimeout(() => {
      const bpItem = this.getSelectedBuildPlanItems[0]
      const supportMetadata = this.bpItemSettingsMap[bpItem.id]
      const index = supportMetadata.supports.findIndex((el) => el.overhangElementName === support.overhangElementName)
      const supportElement = this.$el.getElementsByClassName('overhang-list__item')[index]

      if (supportElement) {
        supportElement.scrollIntoView({ behavior: 'smooth' })
      }
    }, 50)
  }

  onApplyToSimilar(support: BuildPlanItemSupport) {
    const bpItem = this.getSelectedBuildPlanItems[0]
    const supportMetadata = this.bpItemSettingsMap[bpItem.id]
    let prevSupportMetadata

    if (support) {
      prevSupportMetadata = JSON.parse(JSON.stringify(supportMetadata))
      const settings =
        support.settings && support.settings.strategy
          ? support.settings
          : JSON.parse(JSON.stringify(this.createDefaultSupportSettings()))

      if (support.settings) {
        settings.printStrategyParameterSetId = support.settings.printStrategyParameterSetId
        settings.printStrategyParameterSetVersion = support.settings.printStrategyParameterSetVersion
      }

      supportMetadata.supports.forEach((el) => {
        if (el.overhangElementType === support.overhangElementType) {
          el.settings = JSON.parse(JSON.stringify(settings))
        }
      })

      supportMetadata.selectedElements = supportMetadata.supports.filter(
        (el) =>
          el.overhangElementType === support.overhangElementType &&
          el.overhangElementName !== support.overhangElementName,
      )

      this.generateSupportsOnAppliedOverhangs(supportMetadata.selectedElements)
      supportMetadata.selectedElements.push(support)

      const nextSupportMetadata = JSON.parse(JSON.stringify(supportMetadata))
      const supportUndoRedoCommand = new SupportCommand(
        this.getCommandType,
        prevSupportMetadata,
        nextSupportMetadata,
        this.undoRedoActionHandler,
      )
      this.addCommand(supportUndoRedoCommand)
    }
  }

  generateSupportsOnAppliedOverhangs(supportsToUpdate: BuildPlanItemSupport[]) {
    const isValid = this.validateSupportSettings(this.selectedOverhangElements)
    if (!isValid) {
      return
    }

    const bpItem = this.getSelectedBuildPlanItems[0]
    if (bpItem) {
      this.sendAbortCommandIfItIsNecessary(
        bpItem.id,
        (commandOption) =>
          commandOption.commandName !== InteractiveServiceCommandNames.Overhang &&
          commandOption.commandName !== InteractiveServiceCommandNames.Setup,
      )

      const overhangElementsToClear = supportsToUpdate.length
        ? supportsToUpdate.map((element) => element.overhangElementName)
        : null
      this.setDefaultOverhangMaterial({ overhangElementsToClear, buildPlanItemId: bpItem.id })
      this.clearSupports({ overhangElementsToClear, buildPlanItemId: bpItem.id })
      this.dataToAddSupportMeshFromUndoManager = []
      this.clearSupportErrorMessages(supportsToUpdate)
      this.addSupportLoading(supportsToUpdate)

      const runningCommndsForBpItem = []
      for (const [commandId, commandOptions] of this.commandMap) {
        if (
          commandOptions.buildPlanItemId === bpItem.id &&
          (commandOptions.commandName === InteractiveServiceCommandNames.Overhang ||
            commandOptions.commandName === InteractiveServiceCommandNames.Setup)
        ) {
          runningCommndsForBpItem.push(commandId)
        }
      }

      // Send support command only if overhang or setup commands are already finished
      if (!runningCommndsForBpItem.length) {
        const supportCommand = this.generateBatchSupportCommand(bpItem.id)
        if (supportCommand) {
          const params = supportCommand.parameters as BuildPlanItemSupportSettings
          params.selectedOverhangElements = overhangElementsToClear
          this.commandMap.set(supportCommand.id, {
            handler: this.handleSupportResponse,
            commandName: supportCommand.name,
            buildPlanItemId: bpItem.id,
          })

          this.socket.emit('command', supportCommand)
          this.bpItemSettingsMap[bpItem.id].hasTempSupports = true
          this.setUpdatedZones(
            bpItem.id,
            (supportCommand.parameters as BuildPlanItemSupportSettings).selectedOverhangElements,
          )

          this.toggleBackSupportVisibility()
          this.isRunning = true
          this.isOkDisabled = true
          this.isSupportsRendering = true
          this.updateOkStatus()
        }
      }
    }
  }

  getSupportElementName(support: BuildPlanItemSupport) {
    const type = support.overhangElementType
    const strategy = support.settings.strategy

    if (
      (type === SupportedElementTypes.Edge || type === SupportedElementTypes.Vertex) &&
      strategy === SupportTypes.Lines1
    ) {
      return `${this.overhangElementTypes[type]}`
    }

    return `${this.overhangElementTypes[type]} - ${this.supportStrategies[strategy]}`
  }

  getDefaultPartParameterIdBySupportType(type: SupportTypes) {
    const { defaults } = this.getBuildPlanPrintStrategy

    let parameterId: number

    switch (type) {
      case SupportTypes.Solid:
        parameterId = defaults.supportAmpSolidId
        break

      case SupportTypes.Lines1:
        parameterId = defaults.supportAmpLineId
        break

      default:
        parameterId = null
    }

    return parameterId
  }

  changeSupportType(type: SupportTypes) {
    if (type) {
      const bpItem = this.getSelectedBuildPlanItems[0]
      const supportMetadata = this.bpItemSettingsMap[bpItem.id]
      this.undoCommandSupportMetadata = JSON.parse(JSON.stringify(supportMetadata))
      this.changeSupportProperty('strategy', type, false)
      this.setDefaultPartParameterBySupportType(type)
    }
  }

  setDefaultPartParameterBySupportType(supportType: SupportTypes) {
    const parameterId = this.getDefaultPartParameterIdBySupportType(supportType)
    this.changeSupportProperty('printStrategyParameterSetId', null, false)
    this.setDefaultAttachmentDepthByParameterId(parameterId)
    this.setPrintStrategyParameterSetVersion(null)
  }

  changePartParameter(parameterId: number) {
    const bpItem = this.getSelectedBuildPlanItems[0]
    const supportMetadata = this.bpItemSettingsMap[bpItem.id]
    this.undoCommandSupportMetadata = JSON.parse(JSON.stringify(supportMetadata))
    this.changeSupportProperty('printStrategyParameterSetId', parameterId, false)
    this.setDefaultAttachmentDepthByParameterId(parameterId)
    this.setPrintStrategyParameterSetVersion(parameterId)
  }

  changeOverhangBoundaries(state: boolean) {
    const contourType = this.selectedOverhangElementsSettings.settings.contourType
    if (
      !state &&
      (!contourType ||
        contourType === LineShapeTypes.OffsetAllBoundaries ||
        contourType === LineShapeTypes.OffsetOuterBoundary)
    ) {
      this.changeSupportProperty('contourType', LineShapeTypes.Parallel, false)
    }
    this.changeSupportProperty('addOverhangBoundaries', state)
  }

  changeLineShape(lineShape: LineShapeTypes) {
    if (lineShape === LineShapeTypes.Multiple) {
      return
    }
    this.changeSupportProperty('contourType', lineShape)
  }

  async onPartElevationChanged(partElevationValue: number) {
    if (partElevationValue === this.oldPartElevation) {
      return
    }

    const isValid = this.validatePartElevationSettings(partElevationValue)
    if (!isValid) {
      return
    }

    this.changePartElevation(partElevationValue)
  }

  changePartElevation(partElevation: number) {
    const delta: ITransformationDelta = { x: 0, y: 0, z: partElevation - this.oldPartElevation }

    this.oldPartElevation = partElevation
    this.addPendingPartElevationRequest()
    this.elevatePart(delta.z)
  }

  setDefaultAttachmentDepthByParameterId(parameterId: number) {
    if (!parameterId) {
      return
    }
    const { layerThickness } = this.parameterSetsLatestVersions.find((ps) => ps.id === parameterId)
    const layerThicknessToMm = parseFloat(convert('m', 'mm', layerThickness).toPrecision(3))
    this.changeSupportProperty('attachmentDepth', layerThicknessToMm * DEFAULT_ATTACHMENT_DEPTH_MULTIPLIER, false)
    this.changeSupportProperty('parameterLayerThickness', layerThicknessToMm, false)
  }

  setPrintStrategyParameterSetVersion(parameterId: number) {
    if (!parameterId) {
      const { defaults } = this.getBuildPlanPrintStrategy
      const printStrategyParameterSetPk = getSupportDefaultBasedOnType(
        defaults,
        this.selectedOverhangElementsSettings.settings.strategy as SupportTypes,
      )
      this.changeSupportProperty(
        'printStrategyParameterSetVersion',
        printStrategyParameterSetPk ? printStrategyParameterSetPk.version : null,
      )
    } else {
      const { version } = this.parameterSetsLatestVersions.find((ps) => ps.id === parameterId)

      this.changeSupportProperty('printStrategyParameterSetVersion', version)
    }
  }

  isIndeterminate(value) {
    return value === null
  }

  @Watch('getAllBuildPlanItems')
  selectedPartsChanged() {
    if (this.isPartElevationUpdating) {
      return
    }
    const bpItem = this.getSelectedBuildPlanItems[0]
    const supportMetadata = this.bpItemSettingsMap[bpItem.id]
    if (!supportMetadata) {
      return
    }
    const part = this.getSelectedParts.find((p) => p.id === bpItem.id)
    const partElevation = Number.parseFloat(formatDecimal(this.getPartZTranslation(part.id)))

    // YPVJZ-19387 - watcher triggers before move result from part elevation change came
    // changing oldPartElevation but not value in part elevation number field
    // and so next delta between number field and old elevation is bigger than should be
    if (supportMetadata.settings.partElevation !== partElevation) {
      supportMetadata.settings.partElevation = partElevation
      this.oldPartElevation = partElevation
    }
  }

  clearSupportErrorMessages(selectedElements?: BuildPlanItemSupport[]) {
    const selectedNames = selectedElements ? selectedElements.map((el) => el.overhangElementName) : []
    this.selectedOverhangElements.forEach((el) => {
      // skip in case if selected elements passed
      if (selectedElements && !selectedNames.includes(el.overhangElementName)) {
        return
      }

      el.settings.hasSupportError = false
      el.settings.hasSupportWarnings = false
      el.settings.supportErrors = []
    })
  }

  addSupportLoading(selectedElements?: BuildPlanItemSupport[]) {
    const selectedNames = selectedElements ? selectedElements.map((el) => el.overhangElementName) : []
    this.selectedOverhangElements.forEach((el) => {
      // skip in case if selected elements passed
      if (selectedElements && !selectedNames.includes(el.overhangElementName)) {
        return
      }

      if (el.settings.strategy !== SupportTypes.NoSupports) {
        el.settings.isLoading = true
      }
    })
  }

  calcNotchOffset(thickness: number, perforationWidth: number, perforationAngleDeg: number) {
    const notchDepth = (thickness - perforationWidth) / 2
    const notchAngleInRadians = (perforationAngleDeg * Math.PI) / 180
    const notchHeight = 2 * Math.tan(notchAngleInRadians / 2) * notchDepth + 4

    return Number.parseFloat(notchHeight.toFixed(5))
  }

  private createSupportCommand(
    buildPlanItemId: string,
    supportSettings: BuildPlanItemSupportSettings | IBuildPlanItemSettings,
    selectedOverhangElements,
    outputSdataFolder,
  ): IInteractiveServiceCommand {
    switch (supportSettings.strategy) {
      case SupportTypes.Lines1:
        return {
          id: createGuid(),
          name: InteractiveServiceCommandNames.TreeSupports,
          parameters: {
            selectedOverhangElements,
            outputSdataFolder,
            recoaterDirection: supportSettings.recoaterDirection,
            modelFilePath: this.bpItemFilesMap.get(buildPlanItemId).absoluteFilePath,
            overhangsFilePath: this.bpItemFilesMap.get(buildPlanItemId).overhangsFilePath,
            modelTransform: this.bpItemSettingsMap[buildPlanItemId].latestTransformation,
            strategy: this.getAlgorithmType(supportSettings.contourType, supportSettings.addOverhangBoundaries),
            overhangSampleDirectionDeg: supportSettings.overhangSampleDirectionDeg,
            lineBaseThickness: supportSettings.thickness,
            lineBaseGap: supportSettings.minSupportSpacing,
            useTooth: supportSettings.addNeck,
            toothWidth: supportSettings.neckWidth,
            toothHeight: supportSettings.neckHeight,
            usePerforations: supportSettings.addPerforations,
            perforationMinHeight: supportSettings.perforationMinHeight,
            perforationWidth: supportSettings.perforationWidth,
            perforationAngleDeg: supportSettings.perforationAngleDeg,
            blendRadius: supportSettings.addBlend ? supportSettings.blendRadius : 0,
            toothHeightOffset: supportSettings.attachmentDepth,
            maxAllowedLength: supportSettings.maxSegmentLength,
            minBreakLength: supportSettings.minSegmentBreak,
            toothMaxSpacing: supportSettings.maxConnectionSpacing,
            contourType: this.getContourType(supportSettings.contourType, supportSettings.addOverhangBoundaries),
          },
        }

      case SupportTypes.Solid:
        return {
          id: createGuid(),
          name: InteractiveServiceCommandNames.LinearSupports,
          parameters: {
            selectedOverhangElements,
            outputSdataFolder,
            modelFilePath: this.bpItemFilesMap.get(buildPlanItemId).absoluteFilePath,
            overhangsFilePath: this.bpItemFilesMap.get(buildPlanItemId).overhangsFilePath,
            blendRadius: supportSettings.addBlend ? supportSettings.blendRadius : 0,
            strategy: LinearAlgorithmTypes.Solid,
            modelTransform: this.bpItemSettingsMap[buildPlanItemId].latestTransformation,
            toothHeightOffset: supportSettings.attachmentDepth,
          },
        }

      case SupportTypes.NoSupports:
        return {
          id: createGuid(),
          name: InteractiveServiceCommandNames.NoSupports,
          parameters: {
            selectedOverhangElements,
            outputSdataFolder,
          },
        }

      default:
        return undefined
    }
  }

  private onSocketConnectError(error) {
    console.warn(`Socket connection to the supports app failed ${error}`)
    messageService.showErrorMessage(`Socket connection to the supports app failed ${error}`)
    this.$emit('closeTool')
  }

  private createDefaultSettings(): IBuildPlanItemSettings {
    return {
      overhangAngleLimit: 5,
      overhangDistance: 5,
      strategy: SupportTypes.NoSupports,
      thickness: 2,
      overhangAngleDeg: 45,
      minSupportSpacing: 0.5,
      draftAngle: 5,
      fillType: FillTypes.Solid,
      blend: false,
      addNeck: true,
      baseHeight: 5,
      neckHeight: 1,
      neckWidth: 1,
      minRequiredTrianglesArea: 4,
      minRequiredConnectedEdgesLength: 2,
      edgeLaminarAngleDeg: 15,
      elementsCanBeCoincident: false,
      recoaterDirection: [1, 0, 0],
      selectedOverhangElements: ['*'],
      overhangSampleDirectionDeg: 85,
      addPerforations: false,
      perforationMinHeight: this.calcNotchOffset(2, 1, 90),
      perforationWidth: 1,
      perforationAngleDeg: 90,
      contourType: LineShapeTypes.Parallel,
      blendRadius: 0.25,
      addBlend: false,
      attachmentDepth: null,
      parameterLayerThickness: null,
      addOverhangBoundaries: true,
      maxConnectionSpacing: 3,
      maxSegmentLength: 25,
      minSegmentBreak: 0.5,
      partElevation: 0,
    }
  }

  private createDefaultSupportSettings(): BuildPlanItemSupportSettings {
    return {
      recoaterDirection: [1, 0, 0],
      strategy: SupportTypes.NoSupports,
      selectedOverhangElements: ['*'],
      overhangSampleDirectionDeg: 85,
      thickness: 2,
      minSupportSpacing: 0.5,
      addNeck: true,
      neckWidth: 1,
      neckHeight: 1,
      printStrategyParameterSetId: null,
      printStrategyParameterSetVersion: null,
      addPerforations: false,
      perforationMinHeight: this.calcNotchOffset(2, 1, 90),
      perforationWidth: 1,
      perforationAngleDeg: 90,
      contourType: LineShapeTypes.Parallel,
      blendRadius: 0.25,
      addBlend: false,
      attachmentDepth: null,
      parameterLayerThickness: null,
      addOverhangBoundaries: true,
      maxConnectionSpacing: 3,
      maxSegmentLength: 25,
      minSegmentBreak: 0.5,
      hasSupportError: false,
      hasSupportWarnings: false,
      supportErrors: [],
      isLoading: false,
    }
  }

  private setUpdatedZones(bpItemId: string, zones: string[]) {
    const bpMetadata = this.bpItemSettingsMap[bpItemId]

    // In this case all zones marked as updated
    if (bpMetadata.updatedZones.has('*')) {
      return
    }

    // In this case we should set only '*'
    if (zones.includes('*')) {
      bpMetadata.updatedZones.clear()
      bpMetadata.updatedZones.add('*')
    }

    zones.forEach((zone) => bpMetadata.updatedZones.add(zone))
  }

  private getSupportErrorMessage(supportError: ISupportError, supportType: SupportTypes) {
    if (!supportError) {
      return ''
    }

    let message
    switch (supportType) {
      case SupportTypes.Lines1:
        message = MESSAGE_MAP_BY_LINE_SUPPORT_ERROR_CODE[supportError.code]
        break
      case SupportTypes.Solid:
        // Not implemented yet.
        break
      default:
        break
    }

    // TODO: Show general error message if message is undefined.
    return message ? this.$t(message) : supportError.message
  }
}
