
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ItemTypeSelector from '@/components/controls/FileExplorer/ItemTypeSelector.vue'
import ActionBar from '@/components/layout/FileExplorer/ActionBar.vue'
import DetailsPanel from '@/components/layout/FileExplorer/DetailsPanel.vue'
import InfinityScrollArea from '@/components/layout/FileExplorer/InfinityScrollArea.vue'
import MoveErrorModal from '@/components/layout/FileExplorer/MoveErrorModal.vue'
import MoveOrCopyModal from '@/components/layout/FileExplorer/MoveOrCopyModal.vue'
import MoveToTrashItemErrorModal from '@/components/layout/FileExplorer/MoveToTrashItemErrorModal.vue'
import MoveToTrashItemModal from '@/components/layout/FileExplorer/MoveToTrashItemModal.vue'
import RenameItemModal from '@/components/layout/FileExplorer/RenameItemModal.vue'
import ShareItemModal from '@/components/layout/FileExplorer/ShareItemModal.vue'
import FavoritesListRow from '@/components/layout/FileExplorer/Table/Favorites/FavoritesListRow.vue'
import FavoritesTableHeader from '@/components/layout/FileExplorer/Table/Favorites/FavoritesTableHeader.vue'
import AllFilesSearchViewMixin from '@/components/layout/FileExplorer/Table/mixins/AllFilesSearchViewMixin'
import FileExplorerRedirectionMixin from '@/components/layout/FileExplorer/Table/mixins/FileExplorerRedirectionMixin'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import { IFileExplorerState } from '@/store/modules/fileExplorer/types'
import StoresNamespaces from '@/store/namespaces'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { FilterParamsKey, TableFilterParams } from '@/types/FileExplorer/FilterParamsKey'
import { TableSortParams } from '@/types/FileExplorer/SortParamsKey'
import { DetailsPanelViewMode } from '@/types/FileExplorer/ViewMode'
import { IPartDto } from '@/types/PartsLibrary/Parts'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const partsStore = namespace(StoresNamespaces.Parts)

@Component({
  components: {
    FavoritesTableHeader,
    FavoritesListRow,
    DetailsPanel,
    InfinityScrollArea,
    ActionBar,
    RenameItemModal,
    MoveErrorModal,
    MoveToTrashItemModal,
    ShareItemModal,
    ItemTypeSelector,
    MoveToTrashItemErrorModal,
    MoveOrCopyModal,
    ConfirmModal,
  },
})
export default class Favorites extends Mixins(AllFilesSearchViewMixin, FileExplorerRedirectionMixin) {
  @fileExplorerStore.Getter getFavoriteItems: FileExplorerItem[]
  @fileExplorerStore.Getter getSelectedItems: FileExplorerItem[]
  @fileExplorerStore.Getter getClickedNameItem: FileExplorerItem
  @fileExplorerStore.Getter getIsMoveItems: boolean
  @fileExplorerStore.Getter getRenameDialogState: boolean
  @fileExplorerStore.Getter isLoading: boolean
  @fileExplorerStore.Getter getNumberOfHiddenItemsInFavorites: number

  @fileExplorerStore.Action fetchFavoritesSnapshot: () => Promise<void>
  @fileExplorerStore.Action fetchFavorites: () => Promise<FileExplorerItem[]>
  @fileExplorerStore.Action removeAllFromFavorites: Function
  @fileExplorerStore.Action getGetRunningAndFailedJobsByItemIds: Function
  @fileExplorerStore.Action clearPaginatedData: Function

  @partsStore.Action actualizeParts: (items?: FileExplorerItem[]) => Promise<void>
  @partsStore.Action fetchAllParts: Function
  @partsStore.Action fetchAllSinterParts: Function
  @partsStore.Action fetchAllIbcParts: () => Promise<IPartDto[]>

  @fileExplorerStore.Mutation setDetailsPanelMode: (mode: DetailsPanelViewMode) => void
  @fileExplorerStore.Mutation clearPaginationData: () => void
  @fileExplorerStore.Mutation clearItems: () => void
  @fileExplorerStore.Mutation clearFolders: () => void
  @fileExplorerStore.Mutation clearFavorites: () => void

  @fileExplorerStore.State((s: IFileExplorerState) => s.sortParams.favorites) sortParams: TableSortParams
  @fileExplorerStore.State((s: IFileExplorerState) => s.filterParams.favoritesFilter) filterParams: TableFilterParams
  @fileExplorerStore.State((s: IFileExplorerState) => s.favorites.snapshot) snapshot: string[]
  @fileExplorerStore.State((s: IFileExplorerState) => s.favorites.pageRange) pageRange: [number, number]

  filterType = FilterParamsKey.Favorites

  // @ts-ignore
  get items(): FileExplorerItem[] {
    return this.getFavoriteItems
  }

  get hasMoreItems(): boolean {
    const [startIndex] = this.pageRange
    return startIndex < this.snapshot.length
  }

  @Watch('sortParams')
  @Watch('filterParams')
  async refreshItems() {
    await this.clearPaginatedData()
    this.clearFavorites()
    await this.fetchFavoritesSnapshot()
    await this.fetchItems()
  }

  async beforeMount() {
    this.unselectAllItems()
    this.setRoot(null)
    await this.fetchFavoritesSnapshot()
    await this.fetchItems()
  }

  async fetchItems() {
    const itemsToGetJobs = await this.fetchFavorites()
    await Promise.all([
      this.fetchAllSinterParts(),
      this.fetchAllParts(),
      this.fetchAllIbcParts(),
      this.actualizeParts(itemsToGetJobs),
    ])
    if (itemsToGetJobs.length > 0) {
      const itemIds = itemsToGetJobs.map((item: FileExplorerItem) => item.id)
      await this.getGetRunningAndFailedJobsByItemIds(itemIds)
    }
  }

  async onItemListRowClick(payload) {
    this.setSelectedItemsCanBeTrashed(null)

    const item: FileExplorerItem = payload.item

    payload.item = await this.refreshItemIfNeeded(item)

    await this.onItemClick(payload)
    this.defineDetailsViewMode()
  }

  async onIntersection() {
    await this.fetchItems()
  }

  beforeDestroy() {
    this.setDetailsPanelMode(DetailsPanelViewMode.Default)
    this.unselectAllItems()
    this.clearPaginationData()
    this.clearFavorites()
  }
}
