
import Vue from 'vue'
import Component from 'vue-class-component'
import { HELP_LIBRARY_CONTENT_TYPES } from '@/constants'
import IHelpLibraryItem from '@/types/HelpLibrary/IHelpLibraryItem'

@Component
export default class HelpLibrary extends Vue {
  $refs!: {
    videoIFrame: HTMLElement
    documentIFrame: HTMLElement
  }

  contentLoading: boolean = true

  dmlmSimSetupLink: string = window.env.VUE_APP_DMLM_SIM_SETUP_URL || ''
  bjSinterSimSetupLink: string = window.env.VUE_APP_BJ_SINTERING_SIM_SETUP_URL || ''
  understandingSimResultsLink: string = window.env.VUE_APP_UNDERSTANDING_SIM_RESULTS_URL || ''
  releaseNotesLink: string = `${window.env.VUE_APP_RELEASE_NOTES_URL}?timestamp=${window.env.VUE_APP_AMP_VERSION}` || ''
  helpLibraryContent: IHelpLibraryItem[] = [
    {
      topic: 'Amp Manager',
      type: HELP_LIBRARY_CONTENT_TYPES.Video,
      videoId: 'CY-12eXmdsw',
      title: 'Upload Part',
    },
    {
      topic: 'Amp Manager',
      type: HELP_LIBRARY_CONTENT_TYPES.Video,
      videoId: 'E2SKDAkaYCk',
      title: 'Move & Copy',
    },
    {
      topic: 'Amp Manager',
      type: HELP_LIBRARY_CONTENT_TYPES.Video,
      videoId: 'xMri4L1dHmQ',
      title: 'Create a Folder - Build Plan',
    },
    {
      topic: 'Amp Manager',
      type: HELP_LIBRARY_CONTENT_TYPES.Video,
      videoId: 'ROeQ2m4zj00',
      title: 'Deleting objects and restoring back from trash',
    },
    {
      topic: 'Amp Manager',
      type: HELP_LIBRARY_CONTENT_TYPES.Video,
      videoId: 'AUSmKdq-Thg',
      title: 'Sharing content',
    },
    {
      topic: 'Build Plan and 3D data',
      type: HELP_LIBRARY_CONTENT_TYPES.Video,
      videoId: '6jXORyy_AXc',
      title: 'Adding parts',
    },
    {
      topic: 'Build Plan and 3D data',
      type: HELP_LIBRARY_CONTENT_TYPES.Video,
      videoId: '4mGvBGKvRaU',
      title: '3D Scene, Selection and how to use mouse and Gizmo',
    },
    {
      topic: 'Build Plan and 3D data',
      type: HELP_LIBRARY_CONTENT_TYPES.Video,
      videoId: 'ydZR5GcU87c',
      title: 'Rotate, Move and Constrain parts',
    },
    {
      topic: 'Build Plan and 3D data',
      type: HELP_LIBRARY_CONTENT_TYPES.Video,
      videoId: 'jWYTaLoaxP8',
      title: 'Dealing with Bad 3D data',
    },
    {
      topic: 'Simulation',
      type: HELP_LIBRARY_CONTENT_TYPES.Document,
      sourceURL: this.dmlmSimSetupLink,
      title: 'DMLM',
    },
    {
      topic: 'Simulation',
      type: HELP_LIBRARY_CONTENT_TYPES.Document,
      sourceURL: this.bjSinterSimSetupLink,
      title: 'Binder Jet',
    },
    {
      topic: 'Simulation',
      type: HELP_LIBRARY_CONTENT_TYPES.Document,
      sourceURL: this.understandingSimResultsLink,
      title: 'Understanding Simulation Results',
    },
  ]
  currentItem = this.helpLibraryContent[0]
  expandedPanel = 0
  // future topics
  futureTopics = ['Admin', 'Use cases', 'Deep Dive']

  mounted() {
    this.$refs.videoIFrame.addEventListener('load', () => {
      this.contentLoading = false
    })
    this.$refs.documentIFrame.addEventListener('load', () => {
      this.contentLoading = false
    })
  }

  get uniqueTopics() {
    const uniqueTopics = []
    this.helpLibraryContent.map((item) => {
      if (!uniqueTopics.includes(item.topic)) {
        uniqueTopics.push(item.topic)
      }
    })
    // adding extra topics without videos for TG4
    // remove them when there are actual videos added for these
    uniqueTopics.push(...this.futureTopics)
    return uniqueTopics
  }

  isContentVideo(item) {
    return item.type === HELP_LIBRARY_CONTENT_TYPES.Video
  }

  isContentDocument(item) {
    return item.type === HELP_LIBRARY_CONTENT_TYPES.Document
  }

  getVideosForTopic(topic) {
    return this.helpLibraryContent.filter((item) => item.topic === topic)
  }

  getVideoLink(item) {
    return `https://www.youtube-nocookie.com/embed/${item.videoId}?vq=hd1080`
  }

  getVideoThumbnail(item) {
    return `https://img.youtube.com/vi/${item.videoId}/maxresdefault.jpg`
  }

  loadRequestedContent(item) {
    this.currentItem = item
    this.contentLoading = true
  }

  getPDFLink(item) {
    return `${item.sourceURL}#view=fitH`
  }

  openExternalLink(link) {
    window.open(link, '_blank')
  }
}
