
import StoresNamespaces from '@/store/namespaces'
import { IBuildChamberPart } from '@/types/BuildPlans/IBuildPlan'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { PartListItemViewModel } from '../addPart/types'
import BuildPlanPartMixin from '../mixins/BuildPlanPartMixin'
import Icon from '@/components/icons/Icon.vue'

const partsStore = namespace(StoresNamespaces.Parts)
const commonStore = namespace(StoresNamespaces.Common)

const TOOLTIP_TOP_OFFSET = 5
const TOOLTIP_DEFAULT_MAX_WIDTH = 300
const TOOLTIP_WIDTH_INCREMENT = 100

@Component({
  components: { Icon },
})
export default class BuildPlanDetailsPartListItem extends BuildPlanPartMixin {
  @partsStore.Getter partById: (id: string) => IPartDto
  @partsStore.Getter getAllSinterParts: IPartDto[]
  @partsStore.Getter getAllIbcParts: IPartDto[]

  @commonStore.Getter tooltipOpenDelay: number

  @Prop() buildChamberPart: IBuildChamberPart
  @Prop() isPartInteractionAvailable: boolean
  @Prop() clickable: boolean

  showTooltip = false
  closeTimeout: NodeJS.Timeout = null
  openTimeout: NodeJS.Timeout = null
  tooltipTop = 0
  tooltipMaxWidth = TOOLTIP_DEFAULT_MAX_WIDTH

  $refs!: {
    partText: Element
    disabledPartText: Element
    tooltip: Element
  }

  get part() {
    return this.partById(this.buildChamberPart.id)
  }

  get partLocation() {
    return this.formatPartLocation(this.part as PartListItemViewModel)
  }

  get buildChamberPartName() {
    return this.buildChamberPart.count === 1
      ? this.buildChamberPart.name
      : `${this.buildChamberPart.name} (${this.buildChamberPart.count})`
  }

  get sinterPart() {
    return this.part.subType === ItemSubType.SinterPart
      ? this.getAllSinterParts.find((part) => part.id === this.part.id)
      : null
  }

  get sinterPlanIconColor() {
    return '#E86B5F'
  }

  get sinterPlanIconName() {
    return 'mdi-fire'
  }

  get sinterPlanLocation() {
    if (!this.sinterPart) {
      return null
    }

    return `${this.partLocation}/${this.sinterPart.sinterPlanName}`
  }

  get ibcPart() {
    return this.part.subType === ItemSubType.IbcPart
      ? this.getAllIbcParts.find((part) => part.id === this.part.id)
      : null
  }

  get ibcPlanIconColor() {
    return '#1d9a00'
  }

  get ibcPlanIconName() {
    return 'mdi-store-search-outline'
  }

  get ibcPlanLocation() {
    if (!this.ibcPart) {
      return null
    }

    return `${this.partLocation}/${this.ibcPart.ibcPlanName}`
  }

  clickOnPartGroup(id: string) {
    this.$emit('clickOnPartGroup', id)
  }

  hoverIntoPartGroup(id: string) {
    this.openTooltip()
    this.$emit('hoverIntoPartGroup', id)
  }

  hoverOutOfPartGroup(id: string) {
    this.closeTooltip()
    this.$emit('hoverOutOfPartGroup', id)
  }

  /**
   * Creates timeout to open tooltip. Cancels close timeout if such was set.
   * Sets right tooltip top position and width.
   */
  openTooltip() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout)
      this.closeTimeout = null
    }

    this.setTooltipTop()

    this.openTimeout = setTimeout(() => {
      this.showTooltip = true
      this.increaseTooltipWidth()
    }, this.tooltipOpenDelay)
  }

  increaseTooltipWidth() {
    Vue.nextTick().then(() => {
      const tooltipBottom = this.$refs.tooltip.clientHeight + this.tooltipTop + this.getApplicationHeaderHeight()

      if (tooltipBottom > window.innerHeight && this.tooltipMaxWidth < window.innerWidth) {
        this.tooltipMaxWidth += TOOLTIP_WIDTH_INCREMENT
        this.increaseTooltipWidth()
      }
    })
  }

  /**
   * Creates timeout to close tooltip. Cancels open timeout if such was set
   */
  closeTooltip() {
    if (this.openTimeout) {
      clearTimeout(this.openTimeout)
      this.openTimeout = null
    }

    this.closeTimeout = setTimeout(() => {
      this.showTooltip = false
      this.tooltipMaxWidth = TOOLTIP_DEFAULT_MAX_WIDTH
    }, this.tooltipOpenDelay)
  }

  getApplicationHeaderHeight() {
    return parseInt(document.documentElement.style.getPropertyValue('--application-header-height'), 0)
  }

  setTooltipTop() {
    const boundingRect = this.$refs.partText
      ? this.$refs.partText.getBoundingClientRect()
      : this.$refs.disabledPartText.getBoundingClientRect()
    const partTextTop = boundingRect.y + boundingRect.height

    this.tooltipTop = partTextTop - this.getApplicationHeaderHeight() + TOOLTIP_TOP_OFFSET
  }

  beforeDestroy() {
    this.clearTimeouts()
  }

  clearTimeouts() {
    if (this.openTimeout) {
      clearTimeout(this.openTimeout)
      this.openTimeout = null
    }
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout)
      this.closeTimeout = null
    }
  }
}
