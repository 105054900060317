
import Vue from 'vue'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'
import StoresNamespaces from '@/store/namespaces'
import { Watch, Prop } from 'vue-property-decorator'
import { IJob, JobStatusCode, JobType, FileTypes } from '@/types/PartsLibrary/Job'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import { PrintingTypes } from '@/types/IMachineConfig'
import BuildPlanSidebarTools from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import buildPlans from '@/api/buildPlans'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import messageService from '@/services/messageService'
import i18n from '@/plugins/i18n'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer, {
  defaultOptions: {
    inline: true,
  },
  inline: true,
})

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const jobsStore = namespace(StoresNamespaces.Jobs)

@Component
export default class BuildPlanRasterViewer extends Vue {
  @buildPlansStore.Getter('getSelectedBuildPlanJobs') getSelectedBuildPlanJobs: IJob[]
  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter getIsLoading: boolean
  @buildPlansStore.Getter isLayersLoading: boolean

  @visualizationStore.Getter('isSliderActive') isSliderActive: boolean
  @visualizationStore.Getter('getSliderLayerNumber') sliderLayerNumber: number

  @visualizationStore.Mutation('setIsLoading') setIsLoading: Function
  @visualizationStore.Mutation('setSliderLayerNumber') setSliderLayerNumber: Function

  @jobsStore.Action fetchJob: (id: string) => IJob

  rasterImages: any[] = []
  rasterFilesList: any[] = []
  currentFileKey: string = ''
  layerChangeThrottleId = null
  jobCount: number = 0
  options = {
    inline: true,
    movable: true,
    backdrop: false,
    navbar: false,
    fullscreen: false,
    toolbar: false,
    button: false,
    title: false,
    className: 'raster-root',
  }

  @Prop() printJob: IJob

  @Watch('getSelectedBuildPlanJobs')
  @Watch('isSliderActive')
  async onBuildPlanItemsOrJobsChange() {
    if (this.buildPlan.modality === PrintingTypes.BinderJet && this.isSliderActive) {
      // if ratser file list is already loaded then avoid reloading it
      // reloading raster file list only in case of job count change
      if (
        (this.getSelectedBuildPlanJobs && this.jobCount !== this.getSelectedBuildPlanJobs.length) ||
        !(this.rasterFilesList.length > 0)
      ) {
        this.jobCount = this.getSelectedBuildPlanJobs.length
        const subType = this.buildPlan.subType ? this.buildPlan.subType : ItemSubType.None
        const sidebarItems = BuildPlanSidebarTools.getBuildPlanTools(subType, this.buildPlan.modality)
        const slicePublishIndex = sidebarItems.findIndex((sidebarItem) => sidebarItem.key === 'slicePublish')
        if (slicePublishIndex > 0) {
          const completedSlicePublishJobs = this.getSelectedBuildPlanJobs.filter((job) => {
            return (
              [JobType.MARK, ...sidebarItems[slicePublishIndex].jobTypes].includes(job.jobType) &&
              [JobStatusCode.COMPLETE].includes(job.code)
            )
          })

          if (completedSlicePublishJobs.length > 0) {
            const exposureJob = completedSlicePublishJobs.find(
              (completedJob) => completedJob.jobType === JobType.EXPOSURE,
            )
            if (exposureJob) {
              let markJob
              if (this.printJob && this.printJob.parameters) {
                const printJobParameters = JSON.parse(this.printJob.parameters)
                if (printJobParameters.markJobId) {
                  markJob = completedSlicePublishJobs.find(
                    (completedJob) => completedJob.id === printJobParameters.markJobId,
                  )
                }
              }

              const job = markJob ? markJob : exposureJob
              await this.loadRasterFile(job)
              await this.onSliderLayerChanged(this.sliderLayerNumber)
            }
          }
        }
      }
    }
  }

  @Watch('sliderLayerNumber')
  async onSliderLayerChanged(layerNumber) {
    if (this.isLayersLoading) return

    if (this.buildPlan.modality === PrintingTypes.BinderJet && this.isSliderActive && this.rasterFilesList.length) {
      const rasterFileNameWithoutExtension = this.getRasterFileNameWithoutExtensionFromSliderLayer(layerNumber)
      await this.updateImageData(rasterFileNameWithoutExtension)
    }
  }

  async loadRasterFile(job: IJob) {
    this.setIsLoading(true)
    if (JobType.MARK === job.jobType) {
      const outFileKeys: [string] = await buildPlans.getJobOutputFiles(this.buildPlan.id, job.number)
      const allFiles = await buildPlans.getAllFiles(this.buildPlan.id)
      const files = allFiles.filter((file) => {
        return [FileTypes.ARSF].includes(file.fileType) && [...outFileKeys].includes(file.key)
      })
      this.currentFileKey = files[0].key
    } else {
      this.currentFileKey = await buildPlans.getJobOutputFiles(this.buildPlan.id, job.number)
    }
    let fileList = await buildPlans.getS3ZipMetaInfo(this.currentFileKey)
    fileList = fileList.filter((fileName) => fileName.indexOf('.tif') > -1)

    fileList.sort((a, b) => {
      const aNum = parseInt(a.split('slice')[1].split('_')[0], 10)
      const bNum = parseInt(b.split('slice')[1].split('_')[0], 10)
      return aNum - bNum
    })
    this.rasterFilesList = fileList
    this.setIsLoading(false)
  }

  async updateImageData(rasterFileNameWithoutExtension) {
    this.setIsLoading(true)
    this.rasterImages = []
    if (this.layerChangeThrottleId) {
      clearTimeout(this.layerChangeThrottleId)
      this.layerChangeThrottleId = null
      this.setIsLoading(false)
    }
    const fileName = this.rasterFilesList.find(
      (rasterFileName) => rasterFileName.indexOf(rasterFileNameWithoutExtension) > -1,
    )
    if (fileName) {
      this.layerChangeThrottleId = setTimeout(async () => {
        this.setIsLoading(true)
        const image = await buildPlans.getSpecificFileFromZipFile(this.currentFileKey, fileName)
        this.rasterImages = []
        const currentSliceFileName = this.getRasterFileNameWithoutExtensionFromSliderLayer(this.sliderLayerNumber)
        if (currentSliceFileName === rasterFileNameWithoutExtension) {
          this.rasterImages.push({
            fileName,
            src: URL.createObjectURL(image),
            width: '200px',
            height: '200px',
          })
        }
        this.setIsLoading(false)
      }, 500)
    } else {
      if (this.sliderLayerNumber >= 0) {
        const errorMessage = `No raster file found for layer number ${this.sliderLayerNumber}`
        messageService.showErrorMessage(`${i18n.t('unexpectedError')}: ${errorMessage}`)
      }
      this.setIsLoading(false)
    }
  }

  getRasterFileNameWithoutExtensionFromSliderLayer(layerNumber) {
    const digits = layerNumber.toString().length
    let layerNumberString = ''
    if (digits < 4) {
      layerNumberString = '0'.repeat(4 - digits)
    }
    return `slice${layerNumberString}${layerNumber}_0`
  }
}
