/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Vector3, Color3, Viewport, Color4, Axis } from '@babylonjs/core/Maths'
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { CylinderBuilder, Mesh, TransformNode, MeshBuilder, AbstractMesh } from '@babylonjs/core/Meshes'
import { PointLight } from '@babylonjs/core/Lights/pointLight'
import { Scene } from '@babylonjs/core/scene'
import { Nullable } from '@babylonjs/core/types'
import { Engine } from '@babylonjs/core/Engines/engine'
import { AdvancedDynamicTexture } from '@babylonjs/gui/2D/advancedDynamicTexture'
import { Rectangle } from '@babylonjs/gui/2D/controls/rectangle'
import { TextBlock } from '@babylonjs/gui/2D/controls/textBlock'
import { GridMaterial } from '@babylonjs/materials/grid/gridMaterial'
import { OrthoCamera } from './OrthoCamera'
import { RenderScene } from '../render-scene'

export class AdvancedAxesViewer {
  readonly camera: OrthoCamera
  readonly light: PointLight

  private renderScene: RenderScene
  private scene: Scene
  private ground: AbstractMesh
  private axesUI: AdvancedDynamicTexture
  private axesViewer: Mesh
  private scaleLines: number
  private scaleLinesFactor: number
  private renderingGroupId: number
  private xAxis: TransformNode
  private yAxis: TransformNode
  private zAxis: TransformNode

  /**
   * Creates a new AdvancedAxesViewer
   * @param scene defines the hosting scene
   * @param scaleLines defines a number used to scale line length (1 by default)
   * @param renderingGroupId defines a number used to set the renderingGroupId of the meshes (2 by default)
   * @param xAxis defines the node hierarchy used to render the x-axis
   * @param yAxis defines the node hierarchy used to render the y-axis
   * @param zAxis defines the node hierarchy used to render the z-axis
   */
  constructor(
    renderScene: RenderScene,
    scaleLines?: number,
    renderingGroupId?: Nullable<number>,
    xAxis?: TransformNode,
    yAxis?: TransformNode,
    zAxis?: TransformNode,
  ) {
    const engine = renderScene.getScene().getEngine()
    this.renderScene = renderScene
    this.createScene(engine)
    this.scaleLines = scaleLines === void 0 ? 1 : scaleLines
    this.renderingGroupId = renderingGroupId === void 0 ? 2 : renderingGroupId
    this.scaleLinesFactor = 4

    this.axesViewer = new Mesh('axesViewer', this.scene)
    this.axesUI = AdvancedDynamicTexture.CreateFullscreenUI('axesLabels')

    if (!xAxis) {
      const redColoredMaterial = new StandardMaterial('xAxisMaterial', this.scene)
      redColoredMaterial.diffuseColor = Color3.FromHexString('#CE484C')
      redColoredMaterial.specularColor = new Color3(0.7, 0.7, 0.7)
      this.xAxis = this.createArrow(this.scene, redColoredMaterial, 'X')
      this.xAxis.parent = this.axesViewer
    } else {
      this.xAxis = xAxis
    }
    if (!yAxis) {
      const greenColoredMaterial = new StandardMaterial('yAxisMaterial', this.scene)
      greenColoredMaterial.diffuseColor = Color3.FromHexString('#5DA133')
      greenColoredMaterial.specularColor = new Color3(0.7, 0.7, 0.7)
      this.yAxis = this.createArrow(this.scene, greenColoredMaterial, 'Y')
      this.yAxis.parent = this.axesViewer
    } else {
      this.yAxis = yAxis
    }
    if (!zAxis) {
      const blueColoredMaterial = new StandardMaterial('zAxisMaterial', this.scene)
      blueColoredMaterial.diffuseColor = Color3.FromHexString('#2B53BB')
      blueColoredMaterial.specularColor = new Color3(0.7, 0.7, 0.7)
      this.zAxis = this.createArrow(this.scene, blueColoredMaterial, 'Z')
      this.zAxis.parent = this.axesViewer
    } else {
      this.zAxis = zAxis
    }
    this.setRenderingGroupId(this.xAxis, this.renderingGroupId)
    this.setRenderingGroupId(this.yAxis, this.renderingGroupId)
    this.setRenderingGroupId(this.zAxis, this.renderingGroupId)
    this.update(new Vector3(), Vector3.Right(), Vector3.Up(), Vector3.Forward())
    this.setLayerMask(2)

    /** Add ground plane */
    this.addGroundPlane()
    /** Camera for the axes viewer */
    const canvas = engine.getRenderingCanvas()
    this.camera = new OrthoCamera('axesCamera', new Viewport(-0.05, 0, 0.2, 0.2), this.scene, canvas, renderScene)
    /** Detach all input controls */
    this.camera.inputs.attached.keyboard.detachControl()
    this.camera.inputs.attached.pointers.detachControl()
    /** Fixation camera */
    this.camera.panningDistanceLimit = 0.000001
    this.camera.upperRadiusLimit = this.camera.radius
    /** Light for the axes viewer */
    this.light = new PointLight('axesCameraLight', new Vector3(0, 0, 1), this.scene)
  }

  update(position: Vector3, xaxis: Vector3, yaxis: Vector3, zaxis: Vector3) {
    this.xAxis.setDirection(xaxis)
    this.xAxis.scaling.setAll(this.scaleLines * this.scaleLinesFactor)
    this.yAxis.setDirection(yaxis)
    this.yAxis.scaling.setAll(this.scaleLines * this.scaleLinesFactor)
    this.zAxis.setDirection(zaxis)
    this.zAxis.scaling.setAll(this.scaleLines * this.scaleLinesFactor)
    this.axesViewer.position.copyFrom(position)
  }

  getScene() {
    return this.scene
  }

  dispose() {
    this.camera.dispose()
    this.light.dispose()
    this.scene.dispose()
    this.ground.dispose()
    this.axesUI.dispose()
    this.axesViewer.dispose()
    this.xAxis.dispose()
    this.yAxis.dispose()
    this.zAxis.dispose()
  }

  private createScene(engine: Engine) {
    // create a basic BJS Scene object
    this.scene = new Scene(engine)
    this.scene.useRightHandedSystem = true
    this.scene.clearColor = new Color4(1, 1, 1, 1)
    this.scene.autoClear = false
    this.scene.onBeforeRenderObservable.add(() => {
      this.light.position = this.camera.position
    })
  }

  private setLayerMask(mask: number) {
    this.xAxis.getChildMeshes().map((mesh) => (mesh.layerMask = mask))
    this.yAxis.getChildMeshes().map((mesh) => (mesh.layerMask = mask))
    this.zAxis.getChildMeshes().map((mesh) => (mesh.layerMask = mask))
  }

  private setRenderingGroupId(node: TransformNode, id: number) {
    node.getChildMeshes().forEach((mesh: Mesh) => {
      mesh.renderingGroupId = id
    })
  }

  private createArrow(scene: Scene, material: StandardMaterial, axisLabel: string) {
    const arrow = new Mesh('arrow', scene)
    const cone = CylinderBuilder.CreateCylinder(
      `axis${axisLabel}`,
      {
        diameterTop: 0,
        height: 0.075,
        diameterBottom: 0.09,
        tessellation: 96,
      },
      scene,
    )
    const line = CylinderBuilder.CreateCylinder(
      `axis${axisLabel}`,
      {
        diameterTop: 0.04,
        height: 0.375,
        diameterBottom: 0.04,
        tessellation: 96,
      },
      scene,
    )

    cone.material = material
    line.material = material
    cone.parent = arrow
    line.parent = arrow

    /** Position arrow pointing in its drag axis */
    cone.rotation.x = Math.PI / 2
    cone.position.z += 0.4
    line.position.z += 0.375 / 2
    line.rotation.x = Math.PI / 2

    /** Axes labels */
    const label = new Rectangle(`label for ${cone.name}`)
    label.background = 'transparent'
    label.height = '60px'
    label.width = '60px'
    label.thickness = 0
    this.axesUI.addControl(label)

    const textBlock = new TextBlock()
    switch (axisLabel) {
      case 'X':
        label.linkOffsetY = -100
        textBlock.color = 'red'
        break
      case 'Y':
        label.linkOffsetY = -100
        textBlock.color = 'green'
        break
      case 'Z':
        label.linkOffsetY = -70
        textBlock.color = 'blue'
        break
    }
    textBlock.text = axisLabel
    textBlock.fontSize = '70pt'
    textBlock.fontWeight = 'bold'

    label.addControl(textBlock)
    label.linkWithMesh(cone)

    return arrow
  }

  private addGroundPlane() {
    const boundingBox = this.scene.getWorldExtends((mesh) => mesh.visibility === 1)
    const width = boundingBox.max.x - boundingBox.min.x
    const height = boundingBox.max.y - boundingBox.min.y
    const maxDimension = Math.max(width, height) * 2
    const groundPosition = new Vector3(
      (boundingBox.max.x + boundingBox.min.x) / 2,
      (boundingBox.max.y + boundingBox.min.y) / 2,
      boundingBox.min.z,
    )
    this.ground = MeshBuilder.CreateGround('ground', { width: maxDimension, height: maxDimension }, this.scene)
    this.ground.position = groundPosition
    this.ground.isPickable = false
    this.ground.isVisible = false
    this.ground.rotate(Axis.X, Math.PI / 2)

    const groundMaterial = new GridMaterial('groundMaterial', this.scene)
    this.ground.material = groundMaterial
  }
}
