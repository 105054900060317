import { render, staticRenderFns } from "./MultiSelectNumberField.vue?vue&type=template&id=bca025a4&scoped=true"
import script from "./MultiSelectNumberField.vue?vue&type=script&lang=ts"
export * from "./MultiSelectNumberField.vue?vue&type=script&lang=ts"
import style0 from "./MultiSelectNumberField.vue?vue&type=style&index=0&id=bca025a4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bca025a4",
  null
  
)

export default component.exports