
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { IJob, JobStatusCode } from '@/types/PartsLibrary/Job'
import { format } from 'date-fns'
import Selector from '@/components/controls/Common/Selector.vue'
import SortableHeader from '@/components/layout/SortableHeader.vue'
import { RouterPaths } from '@/router'

const jobsStore = namespace(StoresNamespaces.Jobs)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: {
    Selector,
    SortableHeader,
  },
})
export default class JobsDashboard extends Vue {
  @jobsStore.Action('fetchJobs') fetchJobs: () => Promise<IJob[]>
  @jobsStore.Action('cancelJob') cancelJob: (jobId: string) => void
  @jobsStore.Getter('getJobs') getJobs: IJob[]

  allJobs = []
  filteredJobs = []
  jobTypes = ['All task types']
  selectedJobType = this.jobTypes[0]
  statuses = ['All statuses']
  selectedStatus = this.statuses[0]
  sortField = 'createdDate'
  sortDirection = 1
  isFetchingJobs = false

  async beforeMount() {
    await this.fetchAllJobs()
  }

  goToTenantAdmin() {
    // @ts-ignore
    this.$router.safePush(RouterPaths.TenantAdmin)
  }

  async fetchAllJobs() {
    this.isFetchingJobs = true
    await this.fetchJobs()
    this.allJobs = []
    this.getJobs.map((job) => {
      if (!this.jobTypes.includes(job.jobType)) {
        this.jobTypes.push(job.jobType)
      }

      if (!this.statuses.includes(job.code)) {
        this.statuses.push(job.code)
      }

      this.allJobs.push(job)
    })

    this.applyFilters()
    this.isFetchingJobs = false
  }

  async clearFilters() {
    this.selectedJobType = this.jobTypes[0]
    this.selectedStatus = this.statuses[0]
    this.applyFilters()
  }

  formatDate(dateStr: string) {
    let result = 'null'
    if (dateStr) {
      result = format(new Date(dateStr), this.$t('dateAndTimeFormatShort') as string)
    }

    return result
  }

  changeSelectedJobType(value) {
    this.selectedJobType = value
    this.applyFilters()
  }

  changeSelectedStatus(value) {
    this.selectedStatus = value
    this.applyFilters()
  }

  applyFilters() {
    this.filteredJobs = this.allJobs
    if (this.selectedJobType !== this.jobTypes[0]) {
      this.filteredJobs = this.filteredJobs.filter((job) => job.jobType === this.selectedJobType)
    }
    if (this.selectedStatus !== this.statuses[0]) {
      this.filteredJobs = this.filteredJobs.filter((job) => job.code === this.selectedStatus)
    }

    this.sortOutput()
  }

  sortOutput(sortField?: string) {
    if (sortField) {
      if (this.sortField === sortField) {
        this.sortDirection = -this.sortDirection
      } else {
        this.sortField = sortField
      }
    }

    this.filteredJobs.sort((a, b) => {
      let result = 0
      if (!a[this.sortField] || !b[this.sortField]) {
        const aString = a[this.sortField] ? a[this.sortField] : 'null'
        const bString = b[this.sortField] ? b[this.sortField] : 'null'
        result = aString.localeCompare(bString) * this.sortDirection
      } else {
        result = a[this.sortField].localeCompare(b[this.sortField]) * this.sortDirection
      }

      return result
    })
  }

  isJobCancellable(job) {
    return job.code === JobStatusCode.RUNNING || job.code === JobStatusCode.QUEUED
  }

  async cancelRunningJob(jobId: string) {
    await this.cancelJob(jobId)
    setTimeout(() => {
      this.fetchAllJobs()
    }, 1000)
  }
}
