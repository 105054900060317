import { ActionTree } from 'vuex'
import { IAnnouncementState } from '@/store/modules/announcements/types'
import { IRootState } from '@/store/types'
import announcementsApi from '@/api/annoucements'
import { AnnouncementType } from '@/constants'

export const actions: ActionTree<IAnnouncementState, IRootState> = {
  async fetchLatestUnreadAnnouncementByType({ commit }, announcementType: AnnouncementType) {
    const announcement = await announcementsApi.getLatestUnreadAnnouncementByType(announcementType)
    commit('setAnnouncement', announcement)
  },

  async readAnnouncement({ commit }, announcementId: string) {
    await announcementsApi.readAnnouncement(announcementId)
  },
}
