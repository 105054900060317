
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import CreateBuildPlanModal from '@/components/layout/buildPlans/CreateBuildPlanModal.vue'
import CreateSinterPlanModal from '@/components/layout/buildPlans/CreateSinterPlanModal.vue'
import CreateFolderModal from '@/components/layout/CreateFolderModal.vue'
import UploadPart from '@/components/layout/UploadPart.vue'
import Menu from '@/components/controls/Common/Menu.vue'

import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemPermissionsRole, ROLES_DETAILS } from '@/types/FileExplorer/Permission'
import { DetailsPanelViewMode } from '@/types/FileExplorer/ViewMode'

import StoresNamespaces from '@/store/namespaces'
import { ROOT_FOLDER_ID } from '@/constants'
import { RouterNames } from '@/router'
import VBreadcrumbs from 'vuetify/lib/components/VBreadcrumbs'
import { PrintStrategyInfo } from '@/types/Sites/Site'
import { IMaterial } from '@/types/IMaterial'
import { IMachineConfig, PrintingTypes } from '@/types/IMachineConfig'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const commonStore = namespace(StoresNamespaces.Common)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate'])
@Component({
  components: {
    CreateBuildPlanModal,
    CreateSinterPlanModal,
    CreateFolderModal,
    UploadPart,
    Menu,
  },
})
export default class AllFilesTopBar extends Vue {
  @fileExplorerStore.Action getItemPermissions: (itemId: string) => Promise<void>
  @fileExplorerStore.Action fetchItemsInFolder: (payload: {
    folderId: string
    abortIfRootChanged: boolean
  }) => Promise<FileExplorerItem[]>
  @fileExplorerStore.Action fetchItemById: (itemId: string) => Promise<FileExplorerItem>
  @fileExplorerStore.Action getBreadcrumbsForRoot: Function
  @fileExplorerStore.Action createFolder: Function

  @buildPlansStore.Action fetchMachineConfigs: Function
  @buildPlansStore.Action fetchMaterials: Function
  @buildPlansStore.Action fetchActivePrintStrategies: Function

  @fileExplorerStore.Getter getRootRole: () => ItemPermissionsRole
  @fileExplorerStore.Getter getRootItem: FileExplorerItem
  @fileExplorerStore.Getter isShownCreateBuildPlanDialog: boolean
  @fileExplorerStore.Getter isShownCreateSinterPlanDialog: boolean
  @fileExplorerStore.Getter isShownCreateFolderModal: boolean

  @commonStore.Getter tooltipOpenDelay: number

  @buildPlansStore.Getter getAllMachineConfigs: IMachineConfig[]
  @buildPlansStore.Getter getAllMaterials: IMaterial[]
  @buildPlansStore.Getter getAllActivePrintStrategies: PrintStrategyInfo[]

  @fileExplorerStore.Mutation setRoot: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation setClickedNameItem: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation setDetailsPanelMode: (mode: DetailsPanelViewMode) => void
  @fileExplorerStore.Mutation setIsShownCreateBuildPlanDialog: (value: boolean) => void
  @fileExplorerStore.Mutation setIsShownCreateSinterPlanDialog: (value: boolean) => void
  @fileExplorerStore.Mutation setIsShownCreateFolderModal: (value: boolean) => void

  @fileExplorerStore.State root: any

  @Prop() customTitle: boolean
  @Prop() pageTitle: string

  $refs!: {
    breadcrumbs: VBreadcrumbs
    global: HTMLElement
    menu: HTMLElement
  }

  isSubmitting: boolean = false
  role: ItemPermissionsRole = ItemPermissionsRole.Owner
  breadcrumbItems = []
  collapsedBreadcrumbItems = []
  notCollapsedBreadcrumbItems = []
  shouldTruncateBreadcrubm: boolean = false

  @Watch('root', { deep: true })
  async onRootChange(val, oldVal) {
    this.role = this.getRootRole()
    this.breadcrumbItems = await this.getBreadcrumbsForRoot()
    this.updateCollapsedBreadcrumbs()
  }

  async beforeRouteEnter(to, from, next) {
    next(async (vm: AllFilesTopBar) => {
      vm.$nextTick(async () => {
        if (!vm.root) {
          vm.breadcrumbItems = await vm.getBreadcrumbsForRoot()
          vm.updateCollapsedBreadcrumbs()
        }
      })
    })
  }

  async beforeRouteUpdate(to, from, next) {
    if (!this.root) {
      this.breadcrumbItems = await this.getBreadcrumbsForRoot()
      this.updateCollapsedBreadcrumbs()
    }

    next()
  }

  async beforeMount() {
    this.role = this.getRootRole()
    this.onResize = this.onResize.bind(this)
    window.addEventListener('resize', this.onResize)
    await Promise.all([this.fetchMachineConfigs(), this.fetchMaterials(), this.fetchActivePrintStrategies()])
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }

  get locationRole(): string {
    return ROLES_DETAILS[this.role] ? ROLES_DETAILS[this.role].text : ''
  }

  get isCurrentRoleViewer() {
    return this.getRootRole() === ItemPermissionsRole.Viewer
  }

  updateCollapsedBreadcrumbs() {
    this.collapsedBreadcrumbItems = []
    this.notCollapsedBreadcrumbItems = [...this.breadcrumbItems]

    if (!this.breadcrumbItems.length) {
      return
    }

    this.$nextTick(() => {
      const breadcrumbs = this.$refs.breadcrumbs
      const global = this.$refs.global
      const menu = this.$refs.menu

      if (!menu) {
        return
      }

      const padding =
        parseFloat(getComputedStyle(breadcrumbs.$el).paddingLeft) +
        parseFloat(getComputedStyle(breadcrumbs.$el).paddingRight)

      let sumOfBreadcrambsWidths = padding

      breadcrumbs.$children.forEach((child) => {
        // Breadcrumb's dividers have different parentElements but located inside breadcrumbs childen list
        if (child.$el.parentElement !== breadcrumbs.$el) {
          sumOfBreadcrambsWidths += child.$el.parentElement.clientWidth
        } else {
          sumOfBreadcrambsWidths += child.$el.clientWidth
        }
      })

      const breadcrumbsWidths = breadcrumbs.$children.map((child) => {
        if (child.$el.parentElement !== breadcrumbs.$el) {
          return child.$el.parentElement.clientWidth
        }
        return child.$el.clientWidth
      })

      if (sumOfBreadcrambsWidths > global.clientWidth) {
        // Adding size of collapsible breadcrumbs
        sumOfBreadcrambsWidths += parseFloat(getComputedStyle(menu).width)

        while (sumOfBreadcrambsWidths > global.clientWidth && breadcrumbsWidths.length > 1) {
          const firstItemWidth = breadcrumbsWidths[0] + (breadcrumbsWidths[1] || 0)
          breadcrumbsWidths.splice(0, 2)
          sumOfBreadcrambsWidths -= firstItemWidth
          this.collapsedBreadcrumbItems.push(this.notCollapsedBreadcrumbItems.shift())
        }
      }
    })
  }

  async newBuildPlan() {
    this.setIsShownCreateBuildPlanDialog(true)
  }

  async newSinterPlan() {
    this.setIsShownCreateSinterPlanDialog(true)
  }

  async newFolder() {
    this.setIsShownCreateFolderModal(true)
  }

  async closeCreateBuildPlanDialog() {
    this.setIsShownCreateBuildPlanDialog(false)
  }

  async closeCreateSinterPlanDialog() {
    this.setIsShownCreateSinterPlanDialog(false)
  }

  async closeCreateFolderModal() {
    this.setIsShownCreateFolderModal(false)
  }

  async changeRoot(item: FileExplorerItem) {
    const itemId = item && item.id ? item.id : ROOT_FOLDER_ID
    const route = {
      name: RouterNames.FE_AllFiles,
      params: {
        itemId,
      },
    }
    // @ts-ignore
    this.$router.safePush(route)
  }

  async createNewFolder(folderName: string) {
    if (this.isSubmitting) {
      return
    }

    this.isSubmitting = true

    const folder: FileExplorerItem = await this.createFolder(folderName)

    if (folder) {
      this.closeCreateFolderModal()
    }

    this.isSubmitting = false
  }

  onResize() {
    this.updateCollapsedBreadcrumbs()
  }

  get isAvailableSinterPlanMachines() {
    const printStrategiesMaterialIds = this.getAllActivePrintStrategies.map((x) => x.materialId)
    const materialsIds = this.getAllMaterials
      .filter(
        (mat) =>
          mat.visibility && printStrategiesMaterialIds.includes(mat.id) && mat.modality === PrintingTypes.BinderJet,
      )
      .map((mat) => mat.id)

    const printStrategiesMachineConfigIds = this.getAllActivePrintStrategies
      .filter((x) => materialsIds.includes(x.materialId))
      .map((x) => x.machineConfigId)

    const machineConfigs = this.getAllMachineConfigs.filter((config) =>
      printStrategiesMachineConfigIds.includes(config.id),
    )

    return !!machineConfigs.length
  }
}
