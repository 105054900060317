
import Vue from 'vue'
import Component from 'vue-class-component'
import {
  IBuildPlan,
  IBuildPlanItem,
  IPrintStrategyParameterSet,
  ISelectable,
  PartProperty,
  IUpdateBuildPlanItemParamsDto,
  Visibility,
} from '@/types/BuildPlans/IBuildPlan'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { Prop } from 'vue-property-decorator'
import PartPropertyListItem from '@/components/layout/buildPlans/addPart/PartPropertyListItem.vue'
import SupportPropertyListItem from '@/components/layout/buildPlans/partProperties/SupportPropertyListItem.vue'
import { createGuid } from '@/utils/common'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { PART_BODY_ID_DELIMITER } from '@/constants'
import SinglePartPropertyItem from '@/components/layout/buildPlans/addPart/SinglePartPropertyItem.vue'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { ChangePartPropertiesCommand } from '@/types/UndoRedo/ChangePartPropertiesCommand'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { SupportTypes } from '@/types/BuildPlans/IBuildPlanItemSettings'
import PartPropertiesToolbarMixin from '@/components/layout/buildPlans/mixins/PartPropertiesToolbarMixin'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const commandManager = namespace(StoresNamespaces.CommandManager)
const commonStore = namespace(StoresNamespaces.Common)
const labelsStore = namespace(StoresNamespaces.Labels)

@Component({
  components: {
    SupportPropertyListItem,
    PartPropertyListItem,
    SinglePartPropertyItem,
  },
})
export default class BuildPlanPartPropertiesPart extends PartPropertiesToolbarMixin {
  @Prop() bpItem: IBuildPlanItem
  @Prop({ default: false }) hideActionItems: boolean
  @Prop({ default: false }) isPrintOrder: boolean

  @visualizationStore.Mutation('highlightBody') highlightBody: Function
  @visualizationStore.Mutation toggleHighlight: (payload: { buildPlanItemId: string; highlight: boolean }) => void
  @visualizationStore.Mutation('setIsLoading') setIsLoading: Function

  @visualizationStore.Mutation highlightSupport: (payload: {
    bpItemId: string
    overhangZoneName: string
    showHighlight: boolean
  }) => void

  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter('getSelectedParts') selectedParts: ISelectable[]
  @buildPlansStore.Getter parameterSetsLatestVersions: IPrintStrategyParameterSet[]
  @buildPlansStore.Getter isSceneReadOnly: boolean
  @buildPlansStore.Getter isSinglePartPropertyMode: boolean
  @buildPlansStore.Getter getCommandType: CommandType
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes

  @commandManager.Mutation addCommand: (command: ICommand) => void

  @visualizationStore.Getter viewMode: { name: ViewModeTypes; isSelectable: boolean }

  @buildPlansStore.Action updateBuildPlanItem: (payload: {
    params: IUpdateBuildPlanItemParamsDto
    hideAPIErrorMessages?: boolean
  }) => Promise<void>

  @labelsStore.Action updateRelatedLabelsOnBodyFunctionChange: (d: {
    before: PartProperty
    after: PartProperty
    bpItem: IBuildPlanItem
  }) => void

  opened: boolean = false
  updating: boolean = false
  isHoveringPartName: boolean = false

  get supportsWithSelectedStrategies() {
    return (
      this.bpItem &&
      this.bpItem.supports &&
      this.bpItem.supports.filter((x) => x.settings.strategy !== SupportTypes.NoSupports)
    )
  }

  get supportViewModeEnabled(): boolean {
    return this.viewMode.name === ViewModeTypes.Support
  }

  get detailsExpanded(): boolean {
    return !this.supportViewModeEnabled && this.opened
  }

  get partProperties() {
    return this.bpItem.partProperties.map((a) => ({ ...a })).sort((a, b) => a.id - b.id)
  }

  get partNameTooltip() {
    return this.bpItem && this.bpItem.part && this.bpItem.part.name
  }

  get isShowingVisibilityIcon() {
    return this.bpItem.visibility === Visibility.Hidden || this.isHoveringPartName
  }

  get partVisibilityTooltip() {
    return this.bpItem.visibility === Visibility.Visible
      ? (this.$t('partVisibleTooltip') as string)
      : (this.$t('partHiddenTooltip') as string)
  }

  get visibilityIcon() {
    let icon
    switch (this.bpItem.visibility) {
      case Visibility.Visible:
        icon = 'mdi-eye'
        break
      case Visibility.Hidden:
        icon = 'mdi-eye-off'
        break
    }

    return icon
  }

  get isDisabled() {
    return this.updating || this.isPrintOrder
  }

  async onPartPropertyChange(updatedProp) {
    this.updating = true
    this.setIsLoading(true)
    try {
      const buildPlanItemId = this.bpItem.id

      // Get part property by geometry type and parameter set id
      const targetProperty = this.bpItem.partProperties.find(
        (prop) =>
          prop.type === updatedProp.type &&
          prop.printStrategyParameterSetId === updatedProp.printStrategyParameterSetId &&
          prop.printStrategyParameterSetVersion === updatedProp.printStrategyParameterSetVersion &&
          prop.bodyType === updatedProp.bodyType,
      )

      const partProperties = this.bpItem.partProperties.map((p: PartProperty) => {
        // If part property by geometry type and parameters set id, exists
        // need to compare each property from the build plan item part properties array
        // by geometry type and parameter set id.
        // If geometry type and part properties equals current property from the part properties array
        // need to assign the same group id property from the target property.
        // Otherwise, assign newly generated group id.
        if (p.id === updatedProp.id) {
          if (
            targetProperty &&
            updatedProp.type === targetProperty.type &&
            updatedProp.printStrategyParameterSetId === targetProperty.printStrategyParameterSetId &&
            updatedProp.printStrategyParameterSetVersion === targetProperty.printStrategyParameterSetVersion &&
            updatedProp.bodyType === targetProperty.bodyType
          ) {
            p.groupId = targetProperty.groupId
            updatedProp.groupId = targetProperty.groupId
          } else {
            const groupId = createGuid()

            p.groupId = groupId
            updatedProp.groupId = groupId
          }

          return updatedProp
        }

        return p
      })

      const newUpdatePartPropertiesDto = { buildPlanItemId, partProperties }
      const newUpdateDtoPayload = { params: newUpdatePartPropertiesDto }

      if (!this.getSelectedBuildPlanItems.length) {
        await this.updateBuildPlanItem(newUpdateDtoPayload)
        return
      }

      const currentPartProperties = this.getSelectedBuildPlanItems[0].partProperties
      const currentUpdatePartPropertiesDto = { buildPlanItemId, partProperties: currentPartProperties }

      await this.updateBuildPlanItem(newUpdateDtoPayload)

      this.addCommand(
        new ChangePartPropertiesCommand(
          this.getCommandType,
          this.getSelectedBuildPlanItems[0],
          { before: currentUpdatePartPropertiesDto, after: newUpdatePartPropertiesDto },
          this.$store.dispatch,
          this.$store.commit,
        ),
      )
    } finally {
      this.updating = false
      this.setIsLoading(false)
    }
  }

  async onSupportParameterSetPkChange(updatedProp) {
    this.updating = true
    this.setIsLoading(true)
    try {
      const buildPlanItemId = this.bpItem.id
      const supports = this.bpItem.supports.map((support) => {
        if (support.fileKey !== updatedProp.id) {
          return support
        }

        const updatedSettings = updatedProp.support.settings
        const sup = this.bpItem.supports.find(
          (s) =>
            s.fileKey !== updatedProp.id &&
            s.settings.printStrategyParameterSetId === updatedSettings.printStrategyParameterSetId &&
            s.settings.printStrategyParameterSetVersion === updatedSettings.printStrategyParameterSetVersion,
        )
        updatedProp.support.groupId = sup && sup.groupId ? sup.groupId : createGuid()

        return updatedProp.support
      })

      await this.updateBuildPlanItem({ params: { buildPlanItemId, supports } })
    } finally {
      this.updating = false
      this.setIsLoading(false)
    }
  }

  toggleItemDetails() {
    if (!this.supportViewModeEnabled) {
      this.opened = !this.opened
    }
  }

  onPartPropertyHover(property: PartProperty, isHover: boolean) {
    this.highlightBody({ id: property.geometryId, showHighlight: isHover, bpItemId: this.bpItem.id })
  }

  onSupportProperty(overhangZoneName: string, isHover: boolean) {
    this.highlightSupport({ overhangZoneName, bpItemId: this.bpItem.id, showHighlight: isHover })
  }

  onHoverPartName(isHover) {
    this.isHoveringPartName = isHover
    if (!this.supportViewModeEnabled) {
      this.toggleHighlight({ buildPlanItemId: this.bpItem.id, highlight: isHover })
    }
  }

  async onPartPropertyCopyInstances(sourceProperty: PartProperty) {
    this.updating = true
    this.setIsLoading(true)
    try {
      const sourceBodyId: string = sourceProperty.geometryId.split(PART_BODY_ID_DELIMITER)[1]
      const buildPlanItemId = this.bpItem.id
      const partProperties = this.bpItem.partProperties.map((targetProperty) => {
        const targetBodyId: string = targetProperty.geometryId.split(PART_BODY_ID_DELIMITER)[1]
        if (targetBodyId === sourceBodyId && sourceProperty.id !== targetProperty.id) {
          const hasPrintStrategyParameterSetInSource =
            sourceProperty.hasOwnProperty('printStrategyParameterSetId') &&
            sourceProperty.hasOwnProperty('printStrategyParameterSetVersion')
          const printStrategyParameterSetId = hasPrintStrategyParameterSetInSource
            ? sourceProperty.printStrategyParameterSetId
            : targetProperty.printStrategyParameterSetId
          const printStrategyParameterSetVersion = hasPrintStrategyParameterSetInSource
            ? sourceProperty.printStrategyParameterSetVersion
            : targetProperty.printStrategyParameterSetVersion
          return {
            ...targetProperty,
            printStrategyParameterSetId,
            printStrategyParameterSetVersion,
            type: sourceProperty.type ? sourceProperty.type : targetProperty.type,
          }
        }
        return targetProperty
      })
      await this.updateBuildPlanItem({ params: { buildPlanItemId, partProperties } })
    } finally {
      this.updating = false
      this.setIsLoading(false)
    }
  }

  async onPartPropertyCopyAll(sourceProperty: PartProperty) {
    this.updating = true
    this.setIsLoading(true)
    try {
      const buildPlanItemId = this.bpItem.id

      const partProperties = this.bpItem.partProperties.map((targetProperty) => {
        const hasPrintStrategyParameterSetInSource =
          sourceProperty.hasOwnProperty('printStrategyParameterSetId') &&
          sourceProperty.hasOwnProperty('printStrategyParameterSetVersion')
        const printStrategyParameterSetId = hasPrintStrategyParameterSetInSource
          ? sourceProperty.printStrategyParameterSetId
          : targetProperty.printStrategyParameterSetId
        const printStrategyParameterSetVersion = hasPrintStrategyParameterSetInSource
          ? sourceProperty.printStrategyParameterSetVersion
          : targetProperty.printStrategyParameterSetVersion
        return {
          ...targetProperty,
          printStrategyParameterSetId,
          printStrategyParameterSetVersion,
          type: sourceProperty.type || targetProperty.type,
          processState: sourceProperty.processState || targetProperty.processState,
        }
      })

      const currentPartProperties = this.getSelectedBuildPlanItems[0].partProperties
      const currentUpdatePartPropertiesDto = { buildPlanItemId, partProperties: currentPartProperties }
      const newUpdatePartPropertiesDto = { buildPlanItemId, partProperties }

      await this.updateBuildPlanItem({ params: newUpdatePartPropertiesDto })

      this.addCommand(
        new ChangePartPropertiesCommand(
          this.getCommandType,
          this.getSelectedBuildPlanItems[0],
          { before: currentUpdatePartPropertiesDto, after: newUpdatePartPropertiesDto },
          this.$store.dispatch,
          this.$store.commit,
        ),
      )
    } finally {
      this.updating = false
      this.setIsLoading(false)
    }
  }

  async onBodyFunctionChange(payload: { before: PartProperty; after: PartProperty; bpItem: IBuildPlanItem }) {
    await this.updateRelatedLabelsOnBodyFunctionChange(payload)
  }

  get isBpItemHidden() {
    return this.bpItem.visibility === Visibility.Hidden
  }

  // Part properties cannot be changed if scene readonly or any tool is active (GEAMPREQ-420)
  get isPartPropertiesReadOnly() {
    return this.isSceneReadOnly || !!this.getBuildPlanViewMode
  }
}
