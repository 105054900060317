
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Icon, { IconNames } from '@/components/icons/Icon.vue'

@Component
export default class IconButton extends Vue {
  @Prop() id: string
  @Prop() color: string
  @Prop() icon: string
  @Prop() size: string | number
  @Prop() href: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) loading: boolean
  @Prop({ default: false }) block: boolean
  @Prop() onClick: () => void

  // IconNames contains icon (icon in SVG)
  // not in the mdi (material design icon) library
  get isSvgIcon(): boolean {
    const names: string[] = Object.values(IconNames)
    return names.includes(this.icon)
  }

  onButtonClick() {
    if (this.onClick) {
      this.onClick()
    }
  }
}
