/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Mesh } from '@babylonjs/core/Meshes'
import { GeometryTypes } from '@/visualization/models/DataModel'

export class ModelItem {
  public id: string
  public geometryMeshes: Array<{ mesh: Mesh; type: GeometryTypes }>

  constructor(id: string, geometryMeshes: Array<{ mesh: Mesh; type: GeometryTypes }>) {
    this.id = id
    this.geometryMeshes = geometryMeshes
  }
}
