import {
  IInteractiveServiceCommand,
  InteractiveServiceCommandNames,
} from '@/types/InteractiveService/IInteractiveServiceCommand'
import { IInteractiveServiceMessage, OperationStatus } from '@/types/InteractiveService/IInteractiveServiceMessage'
import { Socket, io } from 'socket.io-client'
import messageService from '@/services/messageService'
import store from '@/store'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'

const CLIENT_ALREADY_ASSINGED_MESSAGE = 'Client already assigned'

export default class InteractiveCommunicationService {
  /* TODO 
    Consider removing this flag as it its incorrect value leads to showing modal dialog in wrong use cases
    Instead when closing the tool from the client side
    we can unsubscribe from connection disconnect event before closing the socket
    thus ensure modal dialog will show up only when disconnect event is fired from the server side 
  */
  isDisconnectTriggeredByUser: boolean = false
  private socket: Socket = null
  private isRunning: boolean = false
  private isSetupInProgress: boolean = true
  private commandHandlers: Map<InteractiveServiceCommandNames, Map<OperationStatus, Function>> = new Map()

  get connectionSocket() {
    return this.socket
  }

  get isCommandRunning() {
    return this.isRunning
  }

  get interactiveCommandHandlers() {
    return this.commandHandlers
  }

  get isSetupCommandRunning() {
    return this.isSetupInProgress
  }

  set isSetupCommandRunning(isRunning: boolean) {
    this.isSetupInProgress = isRunning
  }

  connectionHandler: () => void = () => {
    // empty handler
  }
  connectErrorHandler: (msg?: IInteractiveServiceMessage) => void = () => {
    // empty handler
  }

  disconnectHandler: () => void = () => {
    // empty handler
  }

  public connect(uri: string, path: string, tenant: string, token: string, queryOptions?) {
    const query = queryOptions ? { token, tenant, ...queryOptions } : { token, tenant }

    const connectOptions = {
      query,
      rejectUnauthorized: false,
      agent: false,
      upgrade: false,
      transports: ['websocket', 'polling'],
      path: `/${path}`,
    }

    this.socket = io(uri, connectOptions)

    this.socket.on('connect', this.connectionHandler)
    this.socket.on('message', (msg: IInteractiveServiceMessage, callback) => {
      this.onMessage(msg)

      if (callback) {
        callback(msg.id)
      }
    })
    this.socket.on('error', (err) => {
      messageService.showErrorMessage(`Interactive service error: ${err}`)
      // Clear all commands in order to prevent infinity progress
      this.setCurrentCommand(null)
      this.isRunning = false
      this.isSetupInProgress = true
      console.log(err)
    })

    this.socket.on('connect_failed', (err) => {
      messageService.showErrorMessage(`Couldn't connect to the interactive service`)
    })

    this.socket.on('custom_connect_error', (err) => {
      this.connectErrorHandler()
      console.log(err)
    })

    this.socket.on('connect_error', (err) => {
      this.connectErrorHandler()
      console.log(err)
    })

    this.socket.on('disconnect', (err) => {
      this.isRunning = false
      this.isSetupInProgress = true
      this.setCurrentCommand(null)
      console.log(err)
      this.disconnectHandler()
    })
  }

  public closeSocket() {
    this.isSetupInProgress = true
    if (this.socket) {
      this.socket.disconnect()
    }
  }

  public sendCommand(cmd: IInteractiveServiceCommand) {
    const handler = this.commandHandlers.get(cmd.name)
    if (!handler) {
      return
    }

    this.setCurrentCommand(cmd)
    if (cmd.name !== InteractiveServiceCommandNames.Abort) {
      this.isRunning = true
    }

    this.socket.emit('command', cmd)
  }

  public onMessage(msg: IInteractiveServiceMessage) {
    const sentCommand = this.getCurrentCommand()
    if (sentCommand) {
      // Have to handle such case due to fact that we need to notify users
      // when we are not in the label tool
      if (msg.message === CLIENT_ALREADY_ASSINGED_MESSAGE && this.getBuildPlanViewMode() !== ViewModeTypes.Marking) {
        messageService.showErrorMessage(msg.message)
      }

      // This means that we are trying to handle message with the wrong handler
      // Such case should be skipped
      if (sentCommand.id !== msg.id) {
        return
      }

      const commandHandler = this.commandHandlers.get(sentCommand.name).get(msg.status)
      if (commandHandler) {
        commandHandler(msg)
      }

      // We should not reset current command in 2 cases:
      // 1. If current command is already null
      // 2. If current command is not equal to the sent command. This may occur in cases when a new command was launched
      // inside the handler of a previous one
      const currentCommand = this.getCurrentCommand()
      const shouldResetToNull = currentCommand && sentCommand.id === currentCommand.id
      if (shouldResetToNull) {
        this.setCurrentCommand(null)
      }
    } else if (!msg.id) {
      /**
       * If there is no command in command map we assume that this message is global interactive error
       * For now we close connection and tool for such cases
       */
      this.connectErrorHandler(msg)
    }
  }

  public addCommandHandler(commandName: InteractiveServiceCommandNames, status: OperationStatus, handler: Function) {
    const existing = this.commandHandlers.get(commandName)
    if (!existing) {
      const statusHandlers = new Map()
      statusHandlers.set(status, handler)
      this.commandHandlers.set(commandName, statusHandlers)
    } else {
      existing.set(status, handler)
    }
  }

  /**
   * Set command as current
   * @param command Interactive service command to set as current
   */
  private setCurrentCommand(command: IInteractiveServiceCommand) {
    store.commit('label/setCurrentCommand', command)
  }

  /**
   * Returns current command
   * @returns {IInteractiveServiceCommand} Current command
   */
  private getCurrentCommand() {
    return store.getters['label/getCurrentCommand']
  }

  /**
   * Returns current view mode type
   * @returns {ViewModeTypes} Current view mode type
   */
  private getBuildPlanViewMode(): ViewModeTypes {
    return store.getters['buildPlans/getBuildPlanViewMode']
  }
}
