import { SnackbarMessageType } from '@/types/messages/SnackbarMessageType'
import { SNACKBAR_TIMEOUT } from '@/constants'

export default class SnackbarMessage {
  message: string
  type: SnackbarMessageType
  timeout: number
  multiline = false
  closeable = true

  constructor(
    message: string = '',
    type: SnackbarMessageType = SnackbarMessageType.Success,
    timeout = SNACKBAR_TIMEOUT,
  ) {
    this.message = message
    this.type = type
    this.timeout = timeout
  }
}
