import { render, staticRenderFns } from "./XRayWidget.vue?vue&type=template&id=102860c6&scoped=true&lang=html"
import script from "./XRayWidget.vue?vue&type=script&lang=ts"
export * from "./XRayWidget.vue?vue&type=script&lang=ts"
import style0 from "./XRayWidget.vue?vue&type=style&index=0&id=102860c6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "102860c6",
  null
  
)

export default component.exports