
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Prop, Watch } from 'vue-property-decorator'
import Selector from '@/components/controls/Common/Selector.vue'
import { ILabel } from '@/types/Marking/ILabel'
import StoresNamespaces from '@/store/namespaces'
import MarkingTooltipContent from './MarkingTooltipContent.vue'
import LabelSidebarMixin from '../mixins/LabelSidebarMixin'
import { LabelTabStyleViewMode } from '@/constants'
import UpsertMarkingLabel from '@/components/layout/buildPlans/marking/UpsertMarkingLabel.vue'
import LabelToolTextField from '@/components/controls/LabelToolControls/LabelToolTextField.vue'
import LabelToolSelect from '@/components/controls/LabelToolControls/LabelToolSelect.vue'
import TextField from '@/components/controls/Common/TextField.vue'
import NumberField from '@/components/controls/Common/NumberField.vue'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const commonStore = namespace(StoresNamespaces.Common)

@Component({
  components: {
    NumberField,
    TextField,
    Selector,
    MarkingTooltipContent,
    LabelToolTextField,
    LabelToolSelect,
    UpsertMarkingLabel,
  },
})
export default class MarkingLabels extends LabelSidebarMixin {
  @buildPlansStore.Getter getAllBuildPlanMarkLabels: ILabel[]
  @buildPlansStore.Getter selectedLabel: ILabel
  @buildPlansStore.Getter isLabelInstancing: boolean
  @buildPlansStore.Getter insights: IBuildPlanInsight[]
  @buildPlansStore.Getter isLabelReadOnly: boolean

  @commonStore.Getter tooltipOpenDelay: number

  @visualizationStore.Getter isLabelCreationMode: boolean
  @visualizationStore.Getter getVisualizationLoading: boolean
  @visualizationStore.Mutation toggleLabelHighlight: Function

  /***
   * Controls the opened/closed state of content in the expansion-panel.
   * Corresponds to a zero-based index of the currently opened content.
   */
  addPanel: number = null
  openedPanel: number = null
  panelBeforeDelete: number = null
  buildPlanMarkingLabels: ILabel[] = []

  get labelCreationMode() {
    return LabelTabStyleViewMode.Create
  }

  get labelEditMode() {
    return LabelTabStyleViewMode.Edit
  }

  get isOpenAddLabelPanel() {
    return this.addPanel === this.openedPanel
  }

  get isAllPanelsCollapsed() {
    return !this.openedPanel
  }

  get toggleNewLabelStyle() {
    if (this.isAllPanelsCollapsed) {
      return true
    }

    return this.addPanel !== this.openedPanel
  }

  @Watch('getAllBuildPlanMarkLabels', { immediate: true, deep: true })
  onBuildPlanMarkLabelsChanged() {
    this.buildPlanMarkingLabels = this.getAllBuildPlanMarkLabels.map((item, index) => {
      return {
        index,
        ...item,
      }
    })
  }

  @Watch('getAllBuildPlanMarkLabels.length')
  onBuildPlanMarkLabelsLengthChanged(newValue: number, oldValue: number) {
    if (oldValue > newValue) {
      // On delete label recalculate ad panel index
      if (this.openedPanel < this.addPanel && newValue < this.addPanel) {
        this.addPanel -= 1
      }

      this.panelBeforeDelete = this.openedPanel
      this.openedPanel = null
    } else {
      if (this.isLabelInstancing) {
        return
      }

      // Open new added label panel
      if (!this.isOpenAddLabelPanel) {
        this.openedPanel =
          this.panelBeforeDelete === this.addPanel ? this.panelBeforeDelete + 1 : this.panelBeforeDelete
        this.panelBeforeDelete = null
      } else {
        this.openedPanel = this.buildPlanMarkingLabels.length
      }
    }
  }

  hasInsight(labelId: string): boolean {
    const hasInsight = this.insights.find((insight) => insight.details.labelId === labelId)
    return !!hasInsight
  }

  @Watch('selectedLabel')
  onSelectedLabel() {
    if (!this.selectedLabel) {
      if (!this.isLabelCreationMode) {
        return
      }

      this.openedPanel = null
      return
    }

    const index = this.buildPlanMarkingLabels.findIndex((l) => l.id === this.selectedLabel.id)
    if (index === -1 || this.isOpenLabelPanel(index)) {
      return
    }

    if (index < this.addPanel) {
      this.openedPanel = index
    } else {
      this.openedPanel = index + 1
    }
  }

  beforeMount() {
    this.addPanel = this.buildPlanMarkingLabels.length
    // The 'Label Properties' section shall be displayed automatically
    // as the tool is launched and no label is yet defined.
    if (!this.buildPlanMarkingLabels.length) {
      this.openedPanel = 0
      return
    }

    if (this.selectedLabel) {
      const index = this.buildPlanMarkingLabels.findIndex((l) => l.id === this.selectedLabel.id)
      this.openedPanel = index
    }
  }

  beforeDestroy() {
    this.openedPanel = null
  }

  isOpenLabelPanel(index: number) {
    return (
      (index !== this.addPanel && index === this.openedPanel && this.openedPanel < this.addPanel) ||
      (this.addPanel <= index && index === this.openedPanel - 1)
    )
  }

  getLabelName(index: number) {
    const runningIndex = (index + 1).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })
    return `${this.$t('label')} ${runningIndex}`
  }

  scrollToOpenedPanel() {
    setTimeout(() => {
      const openPanel = this.$el.getElementsByClassName('v-expansion-panel-header--active')[0]

      if (openPanel) {
        openPanel.scrollIntoView({ behavior: 'smooth' })
      }
    }, 50)
  }

  toggleHighlight(label: ILabel, showHighlight: boolean) {
    if (this.selectedLabel && this.selectedLabel.id === label.id) {
      return
    }

    this.toggleLabelHighlight({ labelId: label.id, highlight: showHighlight })
  }
}
