import Component from 'vue-class-component'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'
import { isOfType } from '@/utils/common'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { Prop } from 'vue-property-decorator'
import { PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import { DateByUserMixin } from '@/components/layout/mixins/DateByUserMixin'

@Component
export class ActionDateByUserMixin extends DateByUserMixin {
  @Prop() item: FileExplorerItem | PrintOrder | PartListItemViewModel

  /**
   * Provides item`s createdAt and createdBy fields in formated way
   * @returns string formated created info, example 'Jun 6, 2022 by John Doe'
   */
  get createdInfo() {
    const { createdAt, createdBy } = this.item
    return this.getFormatedActionInfo(createdAt, createdBy)
  }

  /**
   * Provides item`s updatedAt and updatedBy fields in formated way
   * For Print Order returns created info instead
   * @returns string formated updated info, example 'Jun 6, 2022 by John Doe'
   */
  get updatedInfo() {
    if (isOfType<PrintOrder>(this.item, 'site')) {
      return this.createdInfo
    }

    const { updatedAt, updatedBy } = this.item
    return this.getFormatedActionInfo(updatedAt, updatedBy)
  }

  /**
   * Provides item`s absoluteUpdatedAt and absoluteUpdatedBy fields in formated way
   * For PrintOrder returns created info instead
   * For PartListItemViewModel returns created info instead
   * @returns string formated absolute updated info, example 'Jun 6, 2022 by John Doe'
   */
  get absoluteUpdatedInfo() {
    if (isOfType<PrintOrder>(this.item, 'site')) {
      return this.createdInfo
    }

    if (isOfType<PartListItemViewModel>(this.item, 'partType')) {
      return this.updatedInfo
    }

    const { absoluteUpdatedAt, absoluteUpdatedBy } = this.item
    if (!absoluteUpdatedAt || !absoluteUpdatedBy) {
      return this.updatedInfo
    }

    return this.getFormatedActionInfo(absoluteUpdatedAt, absoluteUpdatedBy)
  }
}
