export enum ItemPermissionsRole {
  CoOwner,
  Editor,
  Viewer,
  Owner,
}

export type PermissionRoleDetails = {
  [key in ItemPermissionsRole]: {
    text: string
    name: string
    isAvailableForShare: boolean
    value: ItemPermissionsRole
  }
}

export const ROLES_DETAILS: PermissionRoleDetails = {
  [ItemPermissionsRole.Owner]: {
    name: 'Owner',
    text: 'Owner',
    isAvailableForShare: false,
    value: ItemPermissionsRole.CoOwner,
  },

  // granted (shared roles):
  [ItemPermissionsRole.CoOwner]: {
    name: 'Owner',
    text: 'Owner',
    isAvailableForShare: true,
    value: ItemPermissionsRole.CoOwner,
  },
  [ItemPermissionsRole.Editor]: {
    name: 'Editor',
    text: 'Editor',
    isAvailableForShare: true,
    value: ItemPermissionsRole.Editor,
  },
  [ItemPermissionsRole.Viewer]: {
    name: 'Viewer',
    text: 'Viewer',
    isAvailableForShare: true,
    value: ItemPermissionsRole.Viewer,
  },
}

export type Permission = {
  id?: string
  createdBy: string
  grantedTo: string
  role: ItemPermissionsRole
  createdDate: Date
  updatedDate: Date
  itemId: string
  isInherited: boolean
  inheritedFrom: string
}

export type SimplePermission = {
  id: string
  createdBy: string
  grantedTo: string
  role: ItemPermissionsRole
  itemId: string
}

export type PermissionCollaborator = Permission & { isEditable: boolean }

export type ItemCollaborationDto = {
  itemId: string
  canManageCollaboration: boolean
  collaborators: PermissionCollaborator[]
  relatedPermissions: PermissionCollaborator[]
  amountOfNestedItemsWithoutPermissionsToView: number
  grantedParentPermissions?: SimplePermission[]
}

export type PermissionCollaboratorViewModel = {
  id?: string
  grantedTo: string
  displayName: string
  role: ItemPermissionsRole
  itemId: string
  isEditable: boolean
  isInherited: boolean
  inheritedFrom: string
  hasChanged: boolean
  onlyWaterfall?: boolean
}

export type PermissionsPayload = {
  create: PermissionCollaboratorViewModel[]
  update: PermissionCollaboratorViewModel[]
}

export type GrantPermissionDto = {
  userId: string
  itemId: string
  targetUserId: string
  role: ItemPermissionsRole
  message: string
}

export type UpdatePermissionDto = {
  role: ItemPermissionsRole
}

export enum ItemPermissionRule {
  Share = 'itemShare',
  Rename = 'itemRename',
  Move = 'itemMove',
  Create = 'itemCreate',
  Upload = 'itemUpload',
  Delete = 'itemDelete',
  Trash = 'itemTrash',
  Edit = 'itemEdit',
}

export type PermissionsMap = { [key in ItemPermissionRule]: boolean }

export enum ModalLabelType {
  ChangeInherited = 'changeInherited',
  Delete = 'delete',
  DeleteInherited = 'deleteInherited',
}

export type ItemOwnerIds = {
  itemId: string
  ownerId: string
}
