
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import VTextField from 'vuetify/lib/components/VTextField'

@Component
export default class EditableLabel extends Vue {
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) disabledConfirm: boolean
  @Prop({ default: false }) editable: boolean
  @Prop() placeholder: string
  @Prop() label: string
  @Prop({ type: String }) value: string
  @Prop({ type: [Object, String], default: '' }) readonly rules: object | string
  @Prop({ type: String }) prependIcon: string
  @Prop({ default: true }) showEditIconOnDisabled: boolean
  @Prop({ type: String }) contentClass: string
  @Prop({ type: String, default: '400px' }) maxWidth: string

  $refs!: {
    textField: VTextField
  }

  model: string = null

  get shoudDisplayEditIcon(): boolean {
    if (!this.showEditIconOnDisabled && this.disabled) {
      return false
    }

    return true
  }

  beforeMount() {
    this.model = this.value ? String(this.value) : ''
  }

  updated() {
    if (!this.editable) {
      this.model = this.value
    }
  }

  edit() {
    this.$emit('edit', this.$attrs.name)
    this.focus()
    this.selectAll()
  }

  confirm(isValid: boolean) {
    if (!isValid) {
      this.focus()
      return
    }
    this.$emit('confirm', this.model)
  }

  cancel() {
    this.model = this.value
    this.$emit('cancel')
  }

  focus() {
    setTimeout(() => {
      this.$refs.textField.focus()
    }, 0)
  }

  selectAll() {
    setTimeout(() => {
      this.$refs.textField.$el.querySelector('input').select()
    }, 0)
  }

  isChanged(): boolean {
    return this.value !== this.model
  }

  getValue() {
    return this.model
  }
}
