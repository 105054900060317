
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'

import StoresNamespaces from '@/store/namespaces'
import { IAnnouncement } from '@/types/Announcement/IAnnouncement'
import variables from '@/assets/styles/variables.scss'
import { Prop } from 'vue-property-decorator'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const announcementsStore = namespace(StoresNamespaces.Announcements)

@Component
export default class ToolMask extends Vue {
  @buildPlansStore.Mutation setIsToolMaskDisplaying: (value: boolean) => void

  @announcementsStore.Getter('getAnnouncement') announcement: IAnnouncement

  @Prop({ default: false }) isPrintOrder: boolean

  get maskContainer() {
    return {
      position: 'absolute',
      width: `${278}px`,
      top: variables.sidebarToolName,
      bottom: this.isPrintOrder ? 0 : variables.varitantsPanelHeight,
    }
  }
}
