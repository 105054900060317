
/**
 * Confirm Modal component
 *
 * Insert component where you want to use it:
 * <ConfirmModal ref="confirm"></ConfirmModal>
 *
 * Call it:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { width: 200 })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 */
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import Button from '@/components/controls/Common/Button.vue'

@Component({
  components: {
    Button,
  },
})
export default class ConfirmModal extends Vue {
  @Prop({ default: 'Yes' }) confirmLabel?: string
  @Prop({ default: 'Cancel' }) cancelLabel?: string

  isShow = false
  title = null
  message = null
  resolve = null

  options = {
    width: 475,
    zIndex: 200,
  }

  open(title, message, options?: object): Promise<boolean> {
    this.isShow = true
    this.title = title
    this.message = message
    this.options = Object.assign(this.options, options)
    return new Promise((resolve) => {
      this.resolve = resolve
    })
  }

  confirm() {
    this.resolve(true)
    this.isShow = false
  }

  cancel() {
    this.resolve(false)
    this.isShow = false
  }
}
