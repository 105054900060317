
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop, Mixins } from 'vue-property-decorator'
import { IBuildPlanItem, IBuildPlan, IPrintStrategyParameterSet } from '@/types/BuildPlans/IBuildPlan'
import { InsightMixin } from '@/components/layout/buildPlans/mixins/InsightMixin'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { Vector3 } from '@babylonjs/core/Maths'
import { IBuildPlanInsight, InsightErrorCodes } from '@/types/BuildPlans/IBuildPlanInsight'
import _ from 'lodash'
import { InsightsSeverity } from '@/types/Common/Insights'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { getDefaultBaseOnType, getSupportDefaultBasedOnType } from '@/utils/parameterSet/parameterSetUtils'
import { SupportTypes } from '@/types/BuildPlans/IBuildPlanItemSettings'
import { VersionablePk } from '@/types/Common/VersionablePk'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

const messageMap = {
  [InsightErrorCodes.SliceToolThinContour]: 'insightsLabels.SliceTool.byInsight.thinContour',
  [InsightErrorCodes.SliceToolSmallVoidBetweenContours]: 'insightsLabels.SliceTool.byInsight.smallVoidBetweenContours',
  [InsightErrorCodes.SliceToolOverlappingContours]: 'insightsLabels.SliceTool.byInsight.overlappingContours',
  [InsightErrorCodes.SliceToolSelfIntersectingContours]: 'insightsLabels.SliceTool.byInsight.selfIntersectingContours',
}

@Component({
  components: {},
})
export default class SliceIssueByInsight extends Mixins(Vue, InsightMixin) {
  //region Build Plan store
  @buildPlansStore.Getter buildPlanItemById: (id: string) => IBuildPlanItem
  @buildPlansStore.Getter('getBuildPlan') getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getBuildPlanPrintStrategy: BuildPlanPrintStrategyDto
  @buildPlansStore.Getter getPrintStrategyParameterSetByPk: (pk: VersionablePk) => IPrintStrategyParameterSet
  //endregion Build Plan store

  //region Visualization store
  @visualizationStore.Getter('isSlicerModeEnabled') isSlicerModeEnabled: boolean
  @visualizationStore.Mutation('showSlicePolylines') showSlicePolylines: Function
  @visualizationStore.Mutation('hideSlicePolylines') hideSlicePolylines: Function
  @visualizationStore.Mutation('setSliderLayerNumber') setSliderLayerNumber: Function
  @visualizationStore.Mutation changeView: Function

  //endregion Visualization store

  @Prop() insight: IBuildPlanInsight

  partName: string = null
  additionalPartName = null
  layerHeightInMillimetres: number = 0.05 // 50 microns
  metresToMmConversionMultiplier: number = 1000
  hasHovered: boolean = false
  insightDetails: any[] = []

  get message() {
    this.setMessageParams()
    return this.insight && messageMap[this.insight.errorCode]
  }

  get sliceLayerClass() {
    if (this.isSlicerModeEnabled) {
      return 'selectable'
    }
    return 'layer-underline'
  }

  async mounted() {
    if (this.insight.details && this.insight.details.length) {
      this.insightDetails = this.insight.details
      // sorting insight details based on layer height
      this.insightDetails.sort((layerCurrent, layerNext) => layerCurrent.layerHeight - layerNext.layerHeight)
    }
    await this.calculateLayerHeightInMillimetres()
  }

  getSliceLayerNumber(layerHeight: number) {
    return Math.abs(Math.round(layerHeight / this.layerHeightInMillimetres)) + 1
  }

  setMessageParams() {
    this.partName = null
    this.additionalPartName = null
    const bpItem = this.buildPlanItemById(this.insight.details.issueSubjectBuildPlanItemId)
    if (bpItem) {
      this.partName = bpItem.part.name
      if (this.insight.details.collidedWithBuildPlanItemId) {
        const additionalBpItem = this.buildPlanItemById(this.insight.details.collidedWithBuildPlanItemId)
        if (additionalBpItem) {
          this.additionalPartName = additionalBpItem.part.name
        }
      }
    }
  }

  get severityClass() {
    return {
      warning: this.insight.severity === InsightsSeverity.Warning,
      error: this.insight.severity === InsightsSeverity.Error,
    }
  }

  hoverIntoLayer(layerHeight: number) {
    this.hasHovered = true
    const sliceLayerNumer = this.getSliceLayerNumber(layerHeight)
    const insightDetail = this.insight.details.find((detail) => detail.layerHeight === layerHeight)
    if (insightDetail) {
      const detail = _.cloneDeep(insightDetail) // deep copy
      const points = detail.points
      const lines = detail.lines
      const unitsToMillimtersConversion = 0.0001
      const polylines = this.getPolylinesFromLines([...lines, ...points], unitsToMillimtersConversion, sliceLayerNumer)
      this.showSlicePolylines({
        polylines,
        type: this.insight.severity.toUpperCase(),
        polylineMeshName: 'SliceInsight',
      })
    }
  }

  hoverOutOfLayer() {
    if (this.hasHovered) {
      this.hideSlicePolylines({
        type: this.insight.severity.toUpperCase(),
        polylineMeshName: 'SliceInsight',
      })
    }
    this.hasHovered = false
  }

  clickOnLayer(layerHeight: number) {
    const sliceLayerNumber = this.getSliceLayerNumber(layerHeight)
    this.setSliderLayerNumber(sliceLayerNumber)
    this.changeView('Z+')
  }

  async calculateLayerHeightInMillimetres() {
    const sliceHeights: number[] = []
    if (this.getBuildPlan && this.getBuildPlan.buildPlanItems) {
      for (const buildPlanItem of this.getBuildPlan.buildPlanItems) {
        for (const partProperty of buildPlanItem.partProperties) {
          let printStrategyParameterSetPk: VersionablePk
          if (partProperty.printStrategyParameterSetId) {
            printStrategyParameterSetPk = new VersionablePk(
              partProperty.printStrategyParameterSetId,
              partProperty.printStrategyParameterSetVersion,
            )
          } else {
            const defaults = this.getBuildPlanPrintStrategy.defaults
            printStrategyParameterSetPk = getDefaultBaseOnType(defaults, partProperty.type, partProperty.bodyType)
          }
          const printStrategyParameterSet = this.getPrintStrategyParameterSetByPk(printStrategyParameterSetPk)
          sliceHeights.push(printStrategyParameterSet.layerThickness)
        }
        if (buildPlanItem.supports) {
          for (const support of buildPlanItem.supports) {
            const settings = support.settings
            let printStrategyParameterSetPk: VersionablePk
            if (settings.printStrategyParameterSetId) {
              printStrategyParameterSetPk = new VersionablePk(
                settings.printStrategyParameterSetId,
                settings.printStrategyParameterSetVersion,
              )
            } else {
              const defaults = this.getBuildPlanPrintStrategy.defaults
              printStrategyParameterSetPk = getSupportDefaultBasedOnType(defaults, settings.strategy as SupportTypes)
            }
            const printStrategyParameterSet = this.getPrintStrategyParameterSetByPk(printStrategyParameterSetPk)
            sliceHeights.push(printStrategyParameterSet.layerThickness)
          }
        }
      }

      const sliceHeightInMetres = Math.min(...sliceHeights)
      this.layerHeightInMillimetres = sliceHeightInMetres * this.metresToMmConversionMultiplier
    }
  }

  private getPolylinesFromLines(lines: number[][], unitsToMillimtersConversion: number, sliceLayerNumber: number) {
    const polylines = []
    let polylineIndex = 0
    const z = (sliceLayerNumber - 1) * this.layerHeightInMillimetres
    for (const line of lines) {
      polylines[polylineIndex] = []
      for (let index = 0; index < line.length; index += 1) {
        const x = line[index] * unitsToMillimtersConversion
        index += 1
        const y = line[index] * unitsToMillimtersConversion
        polylines[polylineIndex].push(new Vector3(x, y, z))
      }
      polylineIndex += 1
    }
    return polylines
  }
}
