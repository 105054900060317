
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import {
  labelInsightWarningCodes,
  LabelServiceMixin,
} from '@/components/layout/buildPlans/marking/mixins/LabelServiceMixin'
import { LabelListMixin } from '@/components/layout/buildPlans/marking/mixins/LabelListMixin'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { TrackableLabel } from '@/types/Label/TrackableLabel'
import { CachedLabelInsight, IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'
import { IDisplayToolbarState, SelectionUnit } from '@/types/BuildPlans/IBuildPlan'
import { LabelDirtyState } from '@/types/Label/enums'
import { LabelInsightRelatedItem } from '@/types/InteractiveService/LabelMessageContent'
import { InsightsSeverity } from '@/types/Common/Insights'

const labelStore = namespace(StoresNamespaces.Labels)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

interface IMixinInterface extends Vue, LabelServiceMixin, LabelListMixin {}

@Component({})
export default class LabelSetListRow extends Mixins<IMixinInterface>(Vue, LabelServiceMixin, LabelListMixin) {
  @labelStore.Action highlightLabels: (
    payload?: Array<{
      labelSetId?: string
      parentId?: string
      componentId?: string
      geometryId?: string
      patchId?: string
      isPrintOrderPreviewLabel: boolean
    }>,
  ) => void
  @labelStore.Action deHighlightLabels: (
    options?: Array<{
      labelSetId?: string
      parentId?: string
      componentId?: string
      geometryId?: string
      patchId?: string
      labelSetHighlight?: boolean
      isPrintOrderPreviewLabel: boolean
    }>,
  ) => void

  @labelStore.Mutation setHighlightedLabelSetId: (labelSetId: string) => void

  @buildPlansStore.Getter insights: IBuildPlanInsight[]
  @buildPlansStore.Getter displayToolbarStateByVariantId: (buildPlanId: string) => IDisplayToolbarState

  @visualizationStore.Mutation setLabeledBodiesVisibility: (payload: {
    activeLabelSetId: string
    visibility: boolean
  }) => void

  @Prop({ default: null }) labelSetId: string

  get shouldShowAdditionalRow(): boolean {
    // Additional row should be shown in cases if labels has warnings or errors or/and execute queue in progress
    return (
      (!!this.insightErrors || !!this.insightWarnings || !!this.numberOfLabelsToProcess) &&
      (!this.queuedForUpdate || (this.queuedForUpdate && this.shouldShowLoader(this.labelSet.id)))
    )
  }

  get processedLabelsWhileUpdate() {
    // If execute queue is in progress - calculate number taking into an account labels with insights
    if (this.shouldShowLoader(this.labelSet.id)) {
      const labelsWithoutInsights = this.numberOfProcessedLabels - (this.insightWarnings + this.insightErrors)
      return labelsWithoutInsights > 0 ? labelsWithoutInsights : 0
    }

    return this.numberOfProcessedLabels
  }

  get labelSet(): InteractiveLabelSet {
    return this.getLabelSetById(this.labelSetId)
  }

  get numberOfProcessedLabels(): number {
    // Number of total labels minus labels to process
    return (
      (this.labelSet &&
        this.labelSet.labels.length -
          this.labelSet.labels.filter((l) => l.isDirty && l.dirtyState !== LabelDirtyState.Remove).length) ||
      0
    )
  }

  get numberOfLabelsToProcess(): number {
    // Dirty labels in non-remove state
    return (
      (this.labelSet &&
        this.labelSet.labels.filter((l) => l.isDirty && l.dirtyState !== LabelDirtyState.Remove).length) ||
      0
    )
  }

  get numberOfStaticLabels(): number {
    // Clean and dirty labels in non-remove state
    return (this.labelSet && this.labelSet.labels.filter((l) => l.dirtyState !== LabelDirtyState.Remove).length) || 0
  }

  get numberOfLabelsWithoutInsights(): number {
    const numberOfLabelsWithoutInsights =
      this.numberOfStaticLabels -
      ([
        ...new Set(
          this.insights
            // Get label tool related insights
            .filter((insight: IBuildPlanInsight) => insight.tool === ToolNames.LABEL)
            // Find labels ids by label set id
            .flatMap((insight: IBuildPlanInsight) => {
              if (insight.details && insight.details.relatedItems && insight.severity === InsightsSeverity.Warning) {
                return insight.details.relatedItems
                  .filter((item: LabelInsightRelatedItem) => {
                    return item.labelSetId && item.labelSetId === this.labelSet.id
                  })
                  .map((item: LabelInsightRelatedItem) => item.labelId)
              }
            }),
        ),
      ].length +
        this.labelSet.labels.filter((label) => label.errorCode !== null).length)
    return numberOfLabelsWithoutInsights >= 0 ? numberOfLabelsWithoutInsights : 0
  }

  get hasInsights(): boolean {
    return (
      this.insights
        // Use only label-related insights
        .filter((insight: IBuildPlanInsight) => insight.tool === ToolNames.LABEL)
        // Search through insights label set related entries
        .some((insight: IBuildPlanInsight) => {
          if (insight.details && insight.details.relatedItems && insight.severity === InsightsSeverity.Warning) {
            return insight.details.relatedItems.some((item: LabelInsightRelatedItem) => {
              return item.labelSetId && item.labelSetId === this.labelSet.id
            })
          }
        }) || this.labelSet.labels.some((label) => label.errorCode !== null)
    )
  }

  get queuedForUpdate(): boolean {
    return (this.labelSet && this.labelSet.labels.some((l) => l.isDirty)) || false
  }

  async toggleLabel(labelSet: InteractiveLabelSet) {
    this.setIsSilent(true)

    // Need deselect all from the scene here, because when all sets are collapsed part selection mode is enabled
    if (!!this.activeLabelSet && this.activeLabelSet.id === labelSet.id) {
      this.deselect({ items: null, isSilent: true })
      await this.setSelectionMode({ mode: SelectionUnit.Part, options: { shouldAffectSelectionBox: false } })
    }

    if (!this.activeLabelSet) {
      this.deselect()
      await this.setSelectionMode({ mode: SelectionUnit.Body, options: { shouldAffectSelectionBox: false } })
    }

    this.setActiveLabelSet(this.activeLabelSet && this.activeLabelSet.id === labelSet.id ? null : labelSet)
    this.setLabeledBodiesVisibility({
      activeLabelSetId: this.activeLabelSet ? this.activeLabelSet.id : null,
      visibility: this.displayToolbarStateByVariantId(this.getBuildPlan.id).isShowingAllLabledBodies,
    })
    await this.$nextTick()
    if (this.activeLabelSet) {
      const activeLabelSetName: HTMLElement = document.querySelector('.label-grid-wrapper.active .label-text')
      activeLabelSetName.scrollIntoView()
      this.removeHighlightFromLabelSet(labelSet)

      this.setIsSilent(false)
    }
  }

  highlightLabelSet(labelSet: InteractiveLabelSet) {
    const labelSetId = labelSet.id
    const options = []
    const bodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]
    bodies.forEach((patch) => {
      const option = {
        labelSetId,
        parentId: patch.buildPlanItemId,
        componentId: patch.componentId,
        geometryId: patch.geometryId,
      }
      options.push(option)
    })
    this.setHighlightedLabelSetId(labelSet.id)
    this.highlightLabels(options)
  }

  removeHighlightFromLabelSet(labelSet: InteractiveLabelSet) {
    const labelSetId = labelSet.id
    const options = []
    const bodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]
    bodies.forEach((patch) => {
      const option = {
        labelSetId,
        parentId: patch.buildPlanItemId,
        componentId: patch.componentId,
        geometryId: patch.geometryId,
        labelSetHighlight: true,
      }
      options.push(option)
    })
    this.setHighlightedLabelSetId(null)
    this.deHighlightLabels(options)
  }

  /** Checks whether loader should be shown for a particular label set by id
   * @param {string} labelSetId - id of a label set to be tested
   * @returns {boolean} - if should loader be shown for a label set
   */
  shouldShowLoader(labelSetId: string): boolean {
    if (!this.getCurrentCommand) {
      return false
    }

    const labelSet = this.labelSets.find((ls: InteractiveLabelSet) => ls.id === labelSetId)
    return (
      (this.labelSet &&
        labelSet.labels.some((label: TrackableLabel) => label.commandId === this.getCurrentCommand.id)) ||
      false
    )
  }

  get insightWarnings(): number {
    let labelsWithErrors = []
    let labelsWithWarnings = []
    // If execute queue is in progress and if we have intermediate insights information - we should use it
    if (this.getCachedInsights.length) {
      this.getCachedInsights
        // Filter insights by label set id and use only those, which has insights codes in them
        .filter(
          (cachedInsight: CachedLabelInsight) =>
            cachedInsight.labelSetId === this.labelSet.id && cachedInsight.content.length,
        )
        // For each insight decide whether label should go into warnings or errors array. Labels that contain errors
        // should not appear in warnings array
        .forEach((cachedInsight: CachedLabelInsight) => {
          const addToWarnings = cachedInsight.content.every((code: string) => labelInsightWarningCodes.includes(+code))
          if (addToWarnings) {
            labelsWithWarnings.push(cachedInsight.itemId.labelId)
          } else {
            labelsWithErrors.push(cachedInsight.itemId.labelId)
          }
        })
      // Make sure all ids in array are unique and there are no label ids in warnings that are part of errors
      labelsWithErrors = [...new Set(labelsWithErrors)]
      labelsWithWarnings = [...new Set(labelsWithWarnings)].filter(
        (labelId: string) => !labelsWithErrors.includes(labelId),
      )
    } else {
      // If execute queue is not in progress - just get insights from state by severity making sure all ids in array are
      // unique and there are no label ids in warnings that are part of errors
      labelsWithErrors = this.labelsWithInsightsBySeverity(InsightsSeverity.Error)
      labelsWithWarnings = this.labelsWithInsightsBySeverity(InsightsSeverity.Warning).filter(
        (labelId: string) => !labelsWithErrors.includes(labelId),
      )
    }

    return labelsWithWarnings.length
  }

  get insightErrors(): number {
    return this.labelSet.labels.filter((label) => label.errorCode !== null).length
  }

  labelsWithInsightsBySeverity(severity: InsightsSeverity) {
    const labels = []
    this.insights
      // Use only label-related insights
      .filter((insight: IBuildPlanInsight) => insight.tool === ToolNames.LABEL)
      .forEach((insight: IBuildPlanInsight) => {
        // Use only insights with needed severity and insights that contains related items
        if (insight.details && insight.details.relatedItems && insight.severity === severity) {
          insight.details.relatedItems
            .filter((item: LabelInsightRelatedItem) => item.labelSetId && item.labelSetId === this.labelSet.id)
            .forEach((item: LabelInsightRelatedItem) => {
              // Add only unique entries of label ids
              if (!labels.includes(item.labelId)) {
                labels.push(item.labelId)
              }
            })
        }
      })
    return labels
  }

  confirmRemoveSet(event: MouseEvent, labelSet: InteractiveLabelSet) {
    event.stopPropagation()
    this.$emit('confirmRemoveSet', labelSet)
  }
}
