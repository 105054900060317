
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import Button from '@/components/controls/Common/Button.vue'
import { Mixins, Watch } from 'vue-property-decorator'
import {
  createExecuteProcessComplete,
  createSaveProcessComplete,
  createUpdateProcessComplete,
  executeProcessComplete,
  isSaved,
  LabelServiceMixin,
  resolveIsUpdatedPromise,
} from '@/components/layout/buildPlans/marking/mixins/LabelServiceMixin'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import ProgressModal from '@/components/modals/ProgressModal.vue'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { TrackableLabel } from '@/types/Label/TrackableLabel'
import { LabelDirtyState } from '@/types/Label/enums'
import { IInteractiveServiceCommand } from '@/types/InteractiveService/IInteractiveServiceCommand'

const labelStore = namespace(StoresNamespaces.Labels)

@Component({ components: { ProgressModal, Button } })
export default class LabelSetsUpdateDialog extends Mixins<Vue, LabelServiceMixin>(Vue, LabelServiceMixin) {
  @labelStore.Getter getLabelUpdateInProgress: boolean
  @labelStore.Getter getIsSaving: boolean

  @labelStore.Action resetLabelSetsIDsForUpdate: (labelSetsIDs: string[]) => void

  visible: boolean = false
  totalLabelsToUpdate: number = 0

  get width() {
    return this.getIsSaving ? 280 : 230
  }

  get label() {
    return this.getIsSaving ? this.$t('labelTool.savingLabelsTitle') : this.$t('label')
  }

  get message() {
    return this.getIsSaving ? this.$t('labelTool.savingLabelsMessage') : this.$t('labelTool.labelsBeingUpdated')
  }

  get successfullyUpdatedLabels(): number {
    return this.numberOfDirtyLabels < this.totalLabelsToUpdate ? this.totalLabelsToUpdate - this.numberOfDirtyLabels : 0
  }

  get numberOfDirtyLabels(): number {
    return this.labelSets.flatMap((labelSet: InteractiveLabelSet) => {
      return labelSet.labels.filter(
        (label: TrackableLabel) => label.isDirty && label.dirtyState !== LabelDirtyState.Remove,
      )
    }).length
  }

  @Watch('getIsSaving') onIsSavingChange() {
    this.visible = this.getIsSaving
  }

  @Watch('getLabelUpdateInProgress')
  onUpdateStatusChange() {
    this.totalLabelsToUpdate = this.numberOfDirtyLabels
    this.visible = this.getLabelUpdateInProgress
  }

  @Watch('getCurrentCommand')
  async onInteractiveServiceSetupEnd(curr: IInteractiveServiceCommand, prev: IInteractiveServiceCommand) {
    if (this.watchToolReady && prev && !curr) {
      createExecuteProcessComplete()
      createSaveProcessComplete()
      this.setWatchToolReady(false)
      this.setLabelUpdateInProgress(true)
      this.getLabelSetsIDsForUpdate.forEach((labelSetId) => this.setLabelSetPatches({ labelSetId, patches: [] }))
      await this.removeLabels({
        labelsInfo: this.getLabelSetsIDsForUpdate.map((labelSetId) => ({ labelSetId })),
        stateOnly: true,
      })
      this.execute()
      await executeProcessComplete
      this.getJsonPatches()
      await isSaved
      await this.updateBuildPlan({
        buildPlan: {
          ...this.getBuildPlan,
          labelSetIDsToUpdate: null,
        },
      })
      if (!this.getLabelUpdateInProgress) {
        // If the update process has finished midway, we should re-create update-related promises, reset data,
        // if needed, and finish the update process. Also, we should remove the need to update label sets so user
        // can interact with label tool and fix issue that occurred during the update.
        if (this.getBuildPlanViewMode !== ViewModeTypes.Marking) {
          this.clearLabelSetsSnapshot(true)
          this.resetLabelSetData(true)
        }
        this.setRequiresLabelSetUpdates(false)
        this.resetLabelSetsIDsForUpdate([])
        createExecuteProcessComplete()
        createSaveProcessComplete()
        resolveIsUpdatedPromise()
        createUpdateProcessComplete()
        return
      }
      await this.saveLabelSets(true)
      this.setRequiresLabelSetUpdates(false)
      this.setLabelUpdateInProgress(false)
      this.resetLabelSetsIDsForUpdate([])
      this.cacheLabelInsights([...this.labelInsights])
      await this.saveLabelRelatedInsights()
      if (this.getBuildPlanViewMode !== ViewModeTypes.Marking) {
        this.clearLabelSetsSnapshot(true)
        this.resetLabelSetData(true)
      } else {
        // clear old snapshot
        this.clearLabelSetsSnapshot(true)
        // create new snapshot
        this.createLabelSetsSnapshot()

        // validate tool
        this.validateTool()

        // re-create promises
        createExecuteProcessComplete()
        createSaveProcessComplete()
      }
      resolveIsUpdatedPromise()
      createUpdateProcessComplete()
    }
  }

  updateLabels() {
    if (this.getBuildPlanViewMode !== ViewModeTypes.Marking) {
      this.establishConnection()
    }

    this.totalLabelsToUpdate = this.labelSets.flatMap((labelSet: InteractiveLabelSet) => {
      return labelSet.labels.filter((label: TrackableLabel) => label.isDirty)
    }).length
    this.visible = true
  }
}
