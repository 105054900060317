import { LinesMesh, VertexData } from '@babylonjs/core/Meshes'
import { VertexBuffer } from '@babylonjs/core/Buffers'
import { MeshHandler } from '@/visualization/rendering/MeshHandler'
import { GeometryType, ResultsManagerEvent } from '@/visualization/types/SimulationTypes'
import { MESHING_MATERIAL } from '@/constants'
import { Color3 } from '@babylonjs/core/Maths'
import { ResultsManager } from '@/visualization/rendering//ResultsManager'
import { DracoDecoder } from '@/visualization/components/DracoDecoder'

export class MeshingHandler extends MeshHandler {
  private linesId: string
  private visitedIndices: Map<number, number[]>
  private topologyDataType: string
  private linesIndices: number[]

  constructor(manager: ResultsManager, path: string) {
    super(manager, { type: GeometryType.Meshing, materialId: MESHING_MATERIAL, paths: path })
    this.linesId = this.getMeshId()
    this.visitedIndices = new Map<number, number[]>()
    this.linesIndices = []
  }

  clear() {
    super.clear()
    const lines = this.getMeshById(this.linesId)
    this.visitedIndices.clear()
    this.clearCurrentIndices()
    if (lines) {
      this.manager.getScene.removeMesh(lines)
      lines.dispose()
    }
  }

  get visible() {
    if (!super.visible) return false
    return this.getMeshById(this.linesId).visibility === 1
  }

  set visible(value: boolean) {
    if (!this.isReady) return
    super.visible = value
    this.getMeshById(this.linesId).visibility = value ? 1 : 0
  }

  protected edgeIsNotVisited(indexA: number, indexB: number) {
    const minIndex = Math.min(indexA, indexB)
    const maxIndex = Math.max(indexA, indexB)

    if (!this.visitedIndices.get(minIndex)) {
      this.visitedIndices.set(minIndex, [maxIndex])
      return true
    }

    const visitedNodes = this.visitedIndices.get(minIndex)
    if (visitedNodes.indexOf(maxIndex) === -1) {
      visitedNodes.push(maxIndex)
      return true
    }

    return false
  }

  protected handleLoadDatasetMessage(result) {
    this.topologyDataType = result.indices.dataType

    this.getBinaryBuffer(result.points.hash).then((data) => {
      DracoDecoder.Default.decodeMeshPreservingOrder(data).then((decompressed) => {
        const mesh = super.createMesh(decompressed)
        mesh.onMeshReadyObservable.addOnce(() => {
          this.refreshCameraView()
          this.renderHandlerFirstTime()
        })

        this.getBinaryBuffer(result.indices.hash).then((indicesData) => this.handleLoadTopologyMessage(indicesData))
      })
    })
  }

  private handleLoadTopologyMessage(data) {
    const topology = this.getArrayFromBinaryData(data, this.topologyDataType)
    const mesh = this.getMeshById(this.meshId)
    let currentIndex = 0

    while (currentIndex < topology.length) {
      if (topology[currentIndex] === 4) {
        this.addLinesFromSquare(
          topology[currentIndex + 1],
          topology[currentIndex + 2],
          topology[currentIndex + 3],
          topology[currentIndex + 4],
        )
      } else {
        this.addLinesFromTriangle(topology[currentIndex + 1], topology[currentIndex + 2], topology[currentIndex + 3])
      }

      currentIndex = topology[currentIndex] + currentIndex + 1
    }

    this.visitedIndices.clear()
    const lineSystem = new LinesMesh(this.linesId, this.manager.getScene)
    const linesData = new VertexData()
    linesData.indices = this.linesIndices
    linesData.positions = mesh.getVerticesData(VertexBuffer.PositionKind)
    linesData.applyToMesh(lineSystem)
    this.clearCurrentIndices()

    lineSystem.isPickable = false
    lineSystem.color = Color3.Blue()
    lineSystem.visibility = this.defaultVisibility ? 1 : 0

    this.stopSocketCommunication()
    this.triggerEvent(ResultsManagerEvent.AdditionalGeometryDetected, {
      type: this.geometryType,
      loaded: true,
      visible: this.defaultVisibility,
    })
    this.manager.sliceManager.adjustSlicingPlane()
    lineSystem.onMeshReadyObservable.addOnce(() => {
      if (this.onHandlerReady) {
        this.onHandlerReady()
        this.onHandlerReady = undefined
      }
    })
  }

  private addLinesFromSquare(a: number, b: number, c: number, d: number) {
    this.addLine(a, b)
    this.addLine(b, c)
    this.addLine(c, d)
    this.addLine(d, a)
  }

  private addLinesFromTriangle(a: number, b: number, c: number) {
    this.addLine(a, b)
    this.addLine(b, c)
    this.addLine(c, a)
  }

  private addLine(a: number, b: number) {
    if (this.edgeIsNotVisited(a, b)) {
      this.linesIndices.push(a, b)
    }
  }

  private clearCurrentIndices() {
    this.linesIndices.splice(0, this.linesIndices.length)
  }
}
