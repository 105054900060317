export enum PrintOrderJobStatusCode {
  CREATED = 'PREPARING',
  COMPLETE = 'PRINT COMPLETE',
  CANCELLING = 'CANCELLING',
  CANCELLED = 'CANCELLED',
  WARNING = 'COMPLETE',
  ERROR = 'ERROR',
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  PENDING = 'PREPARED',
  IN_PRINT_QUEUE = 'IN PRINT QUEUE',
  LOADED = 'LOADED',
  PRINTING = 'PRINTING',
  UNKNOWN = 'UNKNOWN',
  PRINT_CANCELLED = 'PRINTING CANCELLED',
  PRINT_ERROR = 'PRINTING FAILED',
  SITE_DOWNLOAD_ERROR = 'TRANSFER TO SITE FAILED',
}
