export enum MarkingLocation {
  Top = 0,
  Bottom = 1,
  Left = 2,
  Right = 3,
  Front = 4,
  Back = 5,
  NearestToPoint = 7,
  Anywhere = 8,
  FarBarEnd = 9,
  NearBarEnd = 10,
}

export enum MarkingStatus {
  OutOfDate,
  MissingGeometry,
  Calculating,
  Pass,
  Fail,
  UnloadedGeometry,
}

export enum BooleanType {
  Add,
  Subtract,
}

export enum MarkingContentElementType {
  Symbol,
  User_Entry,
  Sequential_Integer,
  Coordinate_Value,
  ID_Pulled_From_Source,
  CAD_Attribute,
  Build_Attribute,
  Grid_Letter,
}

export enum CharacterLengthControl {
  Specific,
  Minimum,
  Maximum,
  Range,
}

export enum SupportedFontStyles {
  Thin = 100,
  Extra_Light = 200,
  Light = 300,
  Regular = 400,
  Medium = 500,
  Semi_Bold = 600,
  Bold = 700,
  Extra_Bold = 800,
  Black = 900,
  Extra_Black = 1000,
}

export enum TextAlign {
  Left,
  Center,
  Right,
}

export enum TextAlignVertical {
  Bottom,
  Center,
  Top,
}

/**
 * We are using enum value as a priority of operation
 * Please add new element or reorder it wisely
 */
export enum LabelDirtyState {
  None = 0,
  Update = 1,
  Add = 2,
  Remove = 3,
}

export enum LabelSetMode {
  Manual = 'Manual',
  Views = 'Views',
  Bars = 'Bars',
}
