
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Icon, { IconNames } from '@/components/icons/Icon.vue'
import { DataType, ICostEstimationListHeader } from '../types/DataTable'
import { SortOrders } from '@/types/SortModes'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import Menu from '@/components/controls/Common/Menu.vue'

const commonStore = namespace(StoresNamespaces.Common)

@Component({
  components: {
    Icon,
    Menu,
  },
})
export default class CostEstimationListHeader extends Vue {
  @commonStore.Getter tooltipOpenDelay: number

  @Prop() header: ICostEstimationListHeader
  @Prop({ default: () => [] }) filtered: string[] | number[]
  @Prop({ default: null }) sort: SortOrders

  isMenuShown = false

  updateSort(val) {
    this.$emit('update-sort', {
      field: this.header.headerKey || this.header.value,
      order: val,
    })
  }

  updateFilter(val) {
    this.$emit('update-filter', {
      field: this.header.headerKey || this.header.value,
      value: val,
    })
  }

  get filteredNames() {
    const mapIdToName = this.header.data.filterOptions.reduce((map, current) => {
      map[current.value] = current.name
      return map
    }, {})
    return (this.filtered as Array<string | number>)
      .map((id) => mapIdToName[id])
      .sort()
      .join(', ')
  }

  get isAllSelected() {
    return this.filtered.length === this.header.data.filterOptions.length
  }

  get isSomeSelected() {
    return this.filtered.length > 0 && !this.isAllSelected
  }

  get sortIndicatorIconName() {
    switch (this.header.meta.dataType) {
      case DataType.Text:
        return this.sort === SortOrders.Ascending ? IconNames.SortAlphaDownShort : IconNames.SortAlphaUpShort
      case DataType.Number:
        return this.sort === SortOrders.Descending ? IconNames.SortNumericDownShort : IconNames.SortNumericUpShort
      default:
        return ''
    }
  }

  get sortIconName() {
    let asc = ''
    let desc = ''

    switch (this.header.meta.dataType) {
      case DataType.Text:
        asc = IconNames.SortAlphaDown
        desc = IconNames.SortAlphaUp
        break
      case DataType.Number:
        asc = IconNames.SortNumericDown
        desc = IconNames.SortNumericUp
        break
    }

    return {
      asc,
      desc,
    }
  }

  get canActivateMenu() {
    return this.header.meta.sortable || this.header.meta.filterable
  }

  toggleAll() {
    this.$emit('update-filter', {
      field: this.header.headerKey || this.header.value,
      value: this.isAllSelected ? [] : this.header.data.filterOptions.map((option) => option.value),
    })
  }
}
