import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { IUsersState } from './types'
import { IRootState } from '@/store/types'

export const state: IUsersState = {
  login: '',
  user: null,
  isLoggedIn: false,
  isAuthenticating: true,
  isLoadingUsers: false,
  auth0Client: null,
  authPromise: null,
  users: {
    byId: {},
  },
  countries: null,
}

const namespaced: boolean = true

export const user: Module<IUsersState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
