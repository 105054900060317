
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Mixins, Watch } from 'vue-property-decorator'

import BuildPlanLayoutToolbar from '@/components/layout/buildPlans/BuildPlanLayoutToolbar.vue'
import BuildPlanDetails from '@/components/layout/buildPlans/BuildPlanDetails.vue'
import BuildPlanSidebar from '@/components/layout/buildPlans/BuildPlanSidebar.vue'
import BuildPlanSlider from '@/components/layout/buildPlans/BuildPlanSlider.vue'
import BuildPlanRasterViewer from '@/components/layout/buildPlans/BuildPlanRasterViewer.vue'
import ToggleDetailsPanelButton from '@/components/layout/FileExplorer/Details/ToggleDetailsPanelButton.vue'
import DetailsPanel from '@/components/layout/FileExplorer/DetailsPanel.vue'
import CadHelperRunner from '@/components/layout/CadHelperRunner.vue'

import { RouterNames, RouterPaths } from '@/router'

import StoresNamespaces from '@/store/namespaces'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import VisualizationModeTypes from '@/visualization/types/VisualizationModeTypes'
import { getGPUInfo } from '@/utils/gpu'
import { IJob, JobStatusCode } from '@/types/PartsLibrary/Job'
import {
  IBuildPlan,
  IBuildPlanItem,
  IBuildPlanLockDto,
  IDisplayToolbarState,
  ISelectable,
  SelectionUnit,
} from '@/types/BuildPlans/IBuildPlan'
import { IGPUInfo } from '@/types/IGPUInfo'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import Icon, { IconNames } from '@/components/icons/Icon.vue'
import { ContentViewModeTypes } from '@/visualization/types/ContentViewMode'
import variables from '@/assets/styles/variables.scss'
import { IBuildPlansState } from '@/store/modules/buildPlans/types'
import { PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import ModalsStateMixin from '@/components/layout/FileExplorer/Table/mixins/ModalsStateMixin'
import { ItemPermissionsRole, Permission } from '@/types/FileExplorer/Permission'
import { IUser } from '@/types/User/IUser'
import BuildPlanPrintOrderTab from '@/components/layout/buildPlans/BuildPlanPrintOrderTab.vue'
import PrintOrderPreviewDetails from '@/components/layout/printOrder/PrintOrderPreviewDetails.vue'
import PrintOrderPreviewLabelDetails from '@/components/layout/printOrder/PrintOrderPreviewLabelDetails.vue'
import BuildPlanReportSelector from '@/components/controls/PrintOrderPreview/BuildPlanReportSelector.vue'
import Button from '@/components/controls/Common/Button.vue'
import CadHelperLinkUpdateMixin from '@/components/layout/FileExplorer/Table/mixins/CadHelperLinkUpdateMixin'
import { SceneMode } from '@/visualization/types/SceneTypes'
import { IMachineConfig, PrintingTypes } from '@/types/IMachineConfig'
import CommunicationService from '@/services/CommunicationService'
import messageService from '@/services/messageService'
import { BrokerEvents } from '@/types/Common/BrokerEvents'
import { BodyTypeIcons, PART_BODY_ID_DELIMITER } from '@/constants'
import { VersionablePk } from '@/types/Common/VersionablePk'
import buildPlans from '@/api/buildPlans'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import Menu from '@/components/controls/Common/Menu.vue'
import AlertModal from '@/components/modals/AlertModal.vue'
import { getDownloadPrintOrder, getDownloadPrintOrderFileKey } from '@/utils/download'
import ToggleButton from '@/components/controls/Common/ToggleButton.vue'
import { eventBus } from '@/services/EventBus'
import { BuildPlanEvents } from '@/types/Label/BuildPlanEvents'
import { ClearanceTypes, DimensionBox } from '@/visualization/types/ClearanceTypes'
import BuildPlanStickyComponent from '@/components/layout/buildPlans/stickyToPart/BuildPlanStickyComponent.vue'
import ClearanceToggleButton from '@/components/controls/ClearanceTool/ClearanceToggleButton.vue'
import { getDefaultVariantIdFromVersionAndPath } from '@/utils/fileExplorerItem/fileExplorerItemUtils'
import ProgressModal from '@/components/modals/ProgressModal.vue'

const TIMEOUT_FOR_UPDATE_GRID = 50
const NO_ACCESS_BUILD_PLAN_PARTS_MESSAGE = `You don't have access to some parts in this plan.`
const NO_ACCESS_PRINT_ORDER_PARTS_MESSAGE = `You don't have access to some parts in this print order.`

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const partsStore = namespace(StoresNamespaces.Parts)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const userStore = namespace(StoresNamespaces.User)
const jobsStore = namespace(StoresNamespaces.Jobs)
const commonStore = namespace(StoresNamespaces.Common)
const cadHelperStore = namespace(StoresNamespaces.CadHelper)

@Component({
  components: {
    BuildPlanDetails,
    BuildPlanLayoutToolbar,
    BuildPlanPrintOrderTab,
    BuildPlanRasterViewer,
    BuildPlanSidebar,
    BuildPlanSlider,
    Button,
    PrintOrderPreviewLabelDetails,
    BuildPlanReportSelector,
    CadHelperRunner,
    DetailsPanel,
    Icon,
    PrintOrderPreviewDetails,
    ToggleDetailsPanelButton,
    Menu,
    AlertModal,
    ToggleButton,
    ClearanceToggleButton,
    BuildPlanStickyComponent,
    ProgressModal,
  },

  beforeRouteUpdate(to, from, next) {
    this.$refs.sidebar.onRouteChanged(to)
    next()
  },
})
export default class PreviewPrintOrder extends Mixins(ModalsStateMixin, CadHelperLinkUpdateMixin) {
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getIsLoading: boolean
  @buildPlansStore.Getter getContentViewMode: ContentViewModeTypes
  @buildPlansStore.Getter getSelectedBuildPlanPrintJobs: IJob[]
  @buildPlansStore.Getter isSceneReadOnly: boolean
  @buildPlansStore.Getter isReadOnly: boolean
  @buildPlansStore.Getter lockedBy: string
  @buildPlansStore.Getter printOrderNavigatedFrom: string
  @buildPlansStore.Getter getMachineConfigByPk: (machineConfigPk: VersionablePk) => IMachineConfig
  @buildPlansStore.Getter getSelectedMachineConfigPk: VersionablePk
  @buildPlansStore.Getter displayToolbarStateByVariantId: (buildPlanId: string) => IDisplayToolbarState
  @buildPlansStore.Getter getSelectedParts: ISelectable[]
  @buildPlansStore.Getter isToolMaskDisplaying: boolean

  @visualizationStore.Getter isViewLocked: boolean
  @visualizationStore.Getter getVisualizationLoading: boolean
  @visualizationStore.Getter isLabelCreationMode: boolean
  @visualizationStore.Getter getPreviewCreationPromise: { promise: Promise<void>; done: Function }
  @visualizationStore.Getter isShowHiddenPartsAsTransparentMode: boolean
  @visualizationStore.Getter isCrossSectionModeEnabled: Function
  @visualizationStore.Getter isClearanceToolEnabled: Function
  @visualizationStore.Getter isClearanceFromEnabled: Function
  @visualizationStore.Getter isClearanceToEnabled: Function
  @visualizationStore.Getter isRubberBandShown: Function
  @visualizationStore.Getter highlightedClearanceIds: string[]
  @visualizationStore.Getter enabledClearanceFrom: ClearanceTypes
  @visualizationStore.Getter enabledClearanceTo: ClearanceTypes
  @visualizationStore.Getter isMeshToMeshClearance: Function
  @visualizationStore.Getter dimensionBoxes: DimensionBox[]

  @fileExplorerStore.Getter find: (itemId: string) => FileExplorerItem
  @fileExplorerStore.Getter getRootItem: FileExplorerItem
  @fileExplorerStore.Getter permissionsByItemId: Record<string, Permission[]>
  @fileExplorerStore.Action getParentFolder: (id: string) => Promise<FileExplorerItem>

  @userStore.Getter getUserDetails: IUser

  @jobsStore.Action fetchJob: (id: string) => IJob
  @jobsStore.Action fetchPrintOrderById: (id: string) => Promise<PrintOrder>
  @jobsStore.Getter getPrintOrder: PrintOrder
  @buildPlansStore.Action getSignedUrlV1: (payload: { fileKey: string; newName?: string }) => Promise<string>
  @buildPlansStore.Action getBuildPlanById: (id: string) => Promise<IBuildPlan>
  @buildPlansStore.Action getBuildPlanPrintStrategy: (printStrategyPk: VersionablePk) => Promise<void>
  @partsStore.Action fetchAllParts: Function
  @partsStore.Action fetchAllSinterParts: Function
  @partsStore.Action fetchAllIbcParts: Function
  @buildPlansStore.Action lockBuildPlanVariant: () => Promise<void>
  @buildPlansStore.Action unlockBuildPlanVariant: () => Promise<void>
  @buildPlansStore.Action fetchPrintSites: Function
  @fileExplorerStore.Action getItemPermissions: (itemId: string) => Promise<void>
  @buildPlansStore.Action fetchBuildPlanJobs: Function
  @buildPlansStore.Action getHeadObject: Function
  @buildPlansStore.Action fetchMachineConfigs: Function
  @buildPlansStore.Action changeRecoaterDirectionLabelVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action changeGasFlowDirectionVisibility: (isVisble: boolean) => void
  @buildPlansStore.Action changeBuildPlanVolumeVisibility: (isVisble: boolean) => void
  @buildPlansStore.Action changeBuildPlateVisibility: (isVisble: boolean) => void
  @buildPlansStore.Action changePrintHeadVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action changeSupportGeometryVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action changePrintHeadLanesVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action changeProductionGeometryVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action changeCouponGeometryVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action loadPrintOrder: Function
  @buildPlansStore.Action showLoadingPart: Function
  @buildPlansStore.Action hideLoadingPart: Function
  @buildPlansStore.Action updateLoadingPartPosition: Function
  @buildPlansStore.Action changeSceneReadOnly: Function
  @buildPlansStore.Action setSelectionMode: Function

  @visualizationStore.Action generatePreviewCreationPromise: () => { promise: Promise<void>; done: Function }

  @buildPlansStore.Mutation setBuildPlan: Function
  @buildPlansStore.Mutation deselectBuildPlan: Function
  @buildPlansStore.Mutation setInsights: Function
  @buildPlansStore.Mutation setIsReadOnly: Function
  @buildPlansStore.Mutation setInsightSelectionIsEnabled: Function
  @buildPlansStore.Mutation setPrintOrderNavigatedFrom: Function
  @buildPlansStore.Mutation setDisplayToolbarStateForPrintOrder: Function
  @buildPlansStore.Mutation setBuildPlanViewMode: Function
  @buildPlansStore.Mutation setIBCPlanNavigatedFrom: Function

  @visualizationStore.Mutation setIsShowingPrintHeadLanes: Function
  @visualizationStore.Mutation init: Function
  @visualizationStore.Mutation changeVisualizationMode: Function
  @visualizationStore.Mutation changeView: Function
  @visualizationStore.Mutation setViewLocked: Function
  @visualizationStore.Mutation dispose: Function
  @visualizationStore.Mutation delayDisposing: Function
  @visualizationStore.Mutation updateItemPreview: Function
  @visualizationStore.Mutation changeViewMode: Function
  @visualizationStore.Mutation enableCrossSectionMode: Function
  @visualizationStore.Mutation enableClearanceTool: Function
  @visualizationStore.Mutation enableClearanceModeFrom: Function
  @visualizationStore.Mutation enableClearanceModeTo: Function
  @visualizationStore.Mutation measureDistanceToEnvironment: Function
  @visualizationStore.Mutation recenterCrossSection: Function
  @visualizationStore.Mutation axisAlignCrossSection: Function
  @visualizationStore.Mutation resizeCanvas: Function
  @visualizationStore.Mutation zoomToFitCamera: Function
  @visualizationStore.Mutation enableSlicerMode: Function
  @visualizationStore.Mutation setShowHiddenPartsAsTransparent: Function
  @visualizationStore.Mutation showHiddenPartsTransparent: Function
  @visualizationStore.Mutation hideTransparentParts: Function
  @visualizationStore.Mutation hideGizmos: Function
  @visualizationStore.Mutation showRubberBand: Function
  @visualizationStore.Mutation changeClearanceSelectionObserver: Function
  @visualizationStore.Mutation deselect: () => void

  @fileExplorerStore.Mutation setIsShownLockedBuildPlanModal: Function
  @fileExplorerStore.Mutation setLockItemInfo: (payload: {
    editVariant: IBuildPlanLockDto
    previewVariant: IBuildPlanLockDto
  }) => void

  @buildPlansStore.State buildPlan: IBuildPlan
  @buildPlansStore.State((state: IBuildPlansState) => state.addPartTool.selectedParts)
  selectedPartToAdd: PartListItemViewModel

  @commonStore.Getter tooltipOpenDelay: number

  @cadHelperStore.Getter('getDownloadLink') getDownloadLinkOfCadHelper: string
  @cadHelperStore.Getter('getVersion') getVersionOfCadHelper: string

  @partsStore.Action('getPartToken') getPartToken: any

  printOrderName: string = null
  job: IJob
  fileType: string = 'pkg'
  csvFileSize: number = 0
  arsfFileSize: number = 0
  errorMsg: string = null
  isOpenDetails = true
  isCheckingAccess = false
  sliderDisabled = false
  showSlider = false
  showSliceToolbarButton = true
  isCrossSectionModeActive = false
  crossSectionDisabled = false
  clearanceToolDisabled = false
  isBuildPlanReleased = false
  printHeadLanesAvailable: boolean = false

  buildPlanId: string
  buildPlanNameTruncated = ''
  buildPlanVersionLabelTruncated = ''
  connector: CommunicationService = null
  contentViewModes = ContentViewModeTypes
  clearanceTypes = ClearanceTypes
  downloadFileUrl: string = null
  fileSize: number = 0
  gpuInfo: IGPUInfo = null
  isMouseOverCanvas: boolean = false
  isShownError: boolean = false
  oldIsViewLocked: boolean = false
  partToken: string = null
  version = null
  viewModes = ViewModeTypes
  visualizationModes = VisualizationModeTypes
  overflowMenuIsShown: boolean = false
  sliderPromise: { promise: Promise<void>; done: Function } = null

  $refs!: {
    buildPlanNameField: Element
    buildPlanVersionLabelField: Element
    slider: InstanceType<typeof BuildPlanSlider>
    alert: InstanceType<typeof AlertModal>
  }

  get canBeDownloaded(): boolean {
    if (!this.buildPlan || !this.job) {
      return false
    }
    return true
  }

  get productionBodyIcon() {
    return BodyTypeIcons.production
  }

  get supportBodyIcon() {
    return BodyTypeIcons.support
  }

  get couponBodyIcon() {
    return BodyTypeIcons.coupon
  }

  get modeToolbarComponent() {
    switch (this.getBuildPlanViewMode) {
      default:
        return null
    }
  }

  get showVisualizationModes() {
    return this.getBuildPlanViewMode === ViewModeTypes.Orientation
  }

  get buildPlanName() {
    return (this.getBuildPlan || {}).name || ''
  }

  get buildPlanVersionLabel() {
    return (this.getBuildPlan || {}).versionLabel || ''
  }

  get addLabelPointer() {
    return this.isLabelCreationMode && this.isMouseOverCanvas
  }

  get viewModePointerClass(): { [key: string]: boolean } {
    let className: string

    switch (this.getBuildPlanViewMode) {
      case ViewModeTypes.TransferProps:
        className = 'view-mode-pointer--transfer-props'
        break
      default:
        className = ''
    }

    return {
      ...(className && { [className]: true }),
    }
  }

  get isBinderJetModality() {
    if (this.getBuildPlan && this.getBuildPlan.modality === PrintingTypes.BinderJet) {
      return true
    }

    return false
  }

  get isDMLMModality() {
    return this.getBuildPlan && this.getBuildPlan.modality === PrintingTypes.DMLM
  }

  get showCanvasContainer() {
    if (this.getBuildPlan && this.getBuildPlan.modality === PrintingTypes.BinderJet && this.showSlider) {
      return false
    }

    // As the Add Part tool (Part view mode) is activated the "main" canvas should be hidden
    // The canvas for single part should be displayed instead
    if ([ViewModeTypes.Part, ViewModeTypes.Replace].includes(this.getBuildPlanViewMode)) {
      return false
    }

    return true
  }

  get gridColumns() {
    const dpWidth = variables.buildPlanDetailsPanelWidth
    const lsbWidth = variables.buildPlanLeftSidebarWidth

    const gridTemplateAreasValue = this.isOpenDetails
      ? '"header header header" "sidebar content details"'
      : '"header header" "sidebar content"'
    const gridTemplateColumnsValue = this.isOpenDetails ? `${lsbWidth} auto ${dpWidth}` : `${lsbWidth} auto`
    return `grid-template-areas: ${gridTemplateAreasValue}; grid-template-columns: ${gridTemplateColumnsValue};`
  }

  get detailsBtnXPosition() {
    const defaultRightPosition = variables.buildPlanDefDetailBtnPos
    const openedPanelRightPosition = variables.buildPlanOpenedDetailBtnPos
    const position = this.isOpenDetails ? openedPanelRightPosition : defaultRightPosition
    return `right: ${position};`
  }

  get shouldDisplayToolbar(): boolean {
    return this.buildPlan && ![ViewModeTypes.Part].includes(this.getBuildPlanViewMode)
  }

  get numberOfParts() {
    return this.buildPlan && this.buildPlan.buildPlanItems && this.buildPlan.buildPlanItems.length
  }

  get buildPlanVariant() {
    return this.buildPlan && this.buildPlan.version
  }

  get downloadFileName() {
    if (this.fileType === 'pkg') {
      return getDownloadPrintOrder(this.job)
    }
    if (this.fileType === 'csv') {
      const parameters = this.job.parameters && JSON.parse(this.job.parameters)
      // there can be scenarios where job does not have any parameters
      if (parameters) {
        return parameters && parameters.modality === PrintingTypes.BinderJet
          ? parameters.buildLayoutCsv && parameters.buildLayoutCsv.name
          : null
      }
    }
    return null
  }

  get isPartSelected(): boolean {
    return this.getSelectedParts.length === 1
  }

  get selectedBuildPlanItem(): IBuildPlanItem {
    const bpItems = this.buildPlan.buildPlanItems
    if (this.getSelectedParts.length === 1) {
      return bpItems.find((bpItem) => bpItem.id === this.getSelectedParts[0].id)
    }
  }

  get printOrderVersion() {
    if (!this.getPrintOrder) return ''

    const version = +this.getPrintOrder.parentVersion

    if (Number.isNaN(version)) {
      return this.getPrintOrder.parentVersion
    }

    return `${this.$t('variant')} ${version}`
  }

  get isOverflowMenuActive(): boolean {
    return (
      this.displayToolbarState.isShowingRecoaterDirection ||
      (this.isDMLMModality && this.displayToolbarState.isShowingGasFlowDirection) ||
      (this.isBinderJetModality && this.displayToolbarState.isShowingPrintHead) ||
      (this.printHeadLanesAvailable && this.displayToolbarState.isShowingPrintHeadLanes) ||
      this.displayToolbarState.isShowingBuildPlanVolume ||
      this.displayToolbarState.isShowingBuildPlate
    )
  }

  get displayToolbarState(): IDisplayToolbarState {
    return this.displayToolbarStateByVariantId(this.buildPlan.id)
  }

  get isClearanceModeToDisabled(): boolean {
    return (
      !this.isClearanceFromEnabled(this.clearanceTypes.Bodies) &&
      !this.isClearanceFromEnabled(this.clearanceTypes.Parts)
    )
  }

  get isSliderDisabled(): boolean {
    return this.sliderDisabled || this.getIsLoading
  }

  get loadingItemsTitle() {
    return this.$t('openBuildPlanProgressModalInfo.printOrderTitle').toString()
  }

  getIconName(view: string) {
    let iconName
    switch (view) {
      case 'Y-':
        iconName = IconNames.FrontView
        break
      case 'Y+':
        iconName = IconNames.BackView
        break
      case 'X-':
        iconName = IconNames.LeftView
        break
      case 'X+':
        iconName = IconNames.RightView
        break
      case 'Z+':
        iconName = IconNames.TopView
        break
      case 'Z-':
        iconName = IconNames.BottomView
        break
      case 'XYZ':
        iconName = IconNames.IsometricView
        break
    }
    return iconName
  }

  disableSliceToolbarButton(toDisable: boolean) {
    this.sliderDisabled = toDisable
  }

  @Watch('isSceneReadOnly')
  onSceneReadOnlyChange() {
    this.changeSceneReadOnly()
  }

  setMouseOverCanvas(isOver: boolean) {
    this.isMouseOverCanvas = isOver
  }

  async beforeMount() {
    try {
      this.setBuildPlanViewMode(null)
      const initialPath = this.$route.path
      this.isCheckingAccess = true

      const jobId = this.$route.params.id
      const job = await this.fetchJob(jobId)

      await this.fetchPrintOrderById(jobId)

      if (!job) {
        // @ts-ignore
        this.$router.safePush(RouterPaths.Home)
        return
      }

      this.connector = CommunicationService.getConnector()
      this.connector.subscribe(BrokerEvents.PrintJobCreated, this.printjobCreated)

      this.job = job

      this.buildPlanId = job.itemId
      const hasAccess = await this.checkAccess(this.buildPlanId)

      if (!hasAccess) {
        // @ts-ignore
        this.$router.safePush(RouterPaths.Home)
        return
      }

      this.setInsightSelectionIsEnabled(false)

      const pkgDownloadFileKey = this.getDownloadFileKey('pkg')
      if (pkgDownloadFileKey) {
        this.arsfFileSize = (await this.getHeadObject(pkgDownloadFileKey)).ContentLength
      }
      const csvDownloadFileKey = this.getDownloadFileKey('csv')
      if (csvDownloadFileKey) {
        this.csvFileSize = (await this.getHeadObject(csvDownloadFileKey)).ContentLength
      }

      const isLockedForUser = await this.checkVariantIsLockedForUser(this.buildPlanId)
      const isViewer = await this.checkForViewAccess(this.buildPlanId)

      this.isCheckingAccess = false

      const buildPlan = await this.fetchBuildPlan(this.buildPlanId)

      if (!buildPlan) {
        return
      }

      await this.getBuildPlanPrintStrategy(new VersionablePk(buildPlan.printStrategyId, buildPlan.printStrategyVersion))

      this.version = buildPlan.version

      const allParts = this.fetchAllParts()
      const sinterParts = this.fetchAllSinterParts()
      const ibcParts = this.fetchAllIbcParts()
      const printSites = this.fetchPrintSites()
      const machineConfigs = this.fetchMachineConfigs()

      const [parts] = await Promise.all([allParts, sinterParts, ibcParts, printSites, machineConfigs])

      // TODO: worked around for the part value getting as part id until we clean up data
      buildPlan.buildPlanItems.forEach((bpItem) => {
        if (typeof bpItem.part === 'string' || bpItem.part instanceof String) {
          bpItem.part = parts.find((part) => part.id === bpItem.part)
        }
      })
      if (this.$route.path === initialPath) {
        this.setBuildPlan(buildPlan)
      }

      // There is no bp and we shouldn't initialize render scene
      if (!this.buildPlan) {
        return
      }

      this.setDisplayToolbarStateForPrintOrder()

      this.subscribeToEventBus()
      this.gpuInfo = getGPUInfo()
      this.init({
        canvasId: 'visualization_canvas',
        sceneMode: SceneMode.PreviewPrintOrder,
        viewMode: ViewModeTypes.PrintOrderPreview,
        loadDefaultPlate: !buildPlan.buildPlateId,
      })

      this.loadPrintOrder({ buildPlan, printOrderId: jobId })
      await this.fetchBuildPlanJobs(buildPlan.id)
      this.printOrderName = this.getPrintOrder.name

      const machineConfig = this.getMachineConfigByPk(this.getSelectedMachineConfigPk)
      this.printHeadLanesAvailable = machineConfig && machineConfig.numberOfPrintHeadLanes > 0

      // make buildPlan readonly
      if (isViewer || isLockedForUser || this.getSelectedBuildPlanPrintJobs.length) {
        this.setIsReadOnly({ value: true })
      } else {
        this.setIsReadOnly({ value: false })
      }

      this.changeSceneReadOnly()
      this.onHeaderResize()

      await Promise.all([this.fetchPrintOrderStatus(), this.fetchLinks()])

      const { token } = await this.getPartToken()
      this.partToken = token
      this.changeViewMode(ViewModeTypes.PrintOrderPreview)
    } catch (error) {
      console.error(`Error while receiving data: ${error}`)
      this.isCheckingAccess = false
    }
  }

  mounted() {
    if (this.isReadOnly) {
      window.addEventListener('beforeunload', this.unlockBuildPlanVariant)
    }
  }

  subscribeToEventBus() {
    eventBus.$on(BuildPlanEvents.HideGizmo, this.hideGizmos)
    eventBus.$on(BuildPlanEvents.LoadBuildPlanLoadingProgressStarts, this.onLoadBuildPlanLoadingProgressStarts)
    eventBus.$on(BuildPlanEvents.LoadBuildPlanLoadingProgressUpdates, this.onLoadBuildPlanLoadingProgressUpdates)
    eventBus.$on(BuildPlanEvents.LoadBuildPlanLoadingProgressEnds, this.onLoadBuildPlanLoadingProgressEnds)
  }

  unsubscribeFromEventBus() {
    eventBus.$off(BuildPlanEvents.HideGizmo, this.hideGizmos)
    eventBus.$off(BuildPlanEvents.LoadBuildPlanLoadingProgressStarts, this.onLoadBuildPlanLoadingProgressStarts)
    eventBus.$off(BuildPlanEvents.LoadBuildPlanLoadingProgressUpdates, this.onLoadBuildPlanLoadingProgressUpdates)
    eventBus.$off(BuildPlanEvents.LoadBuildPlanLoadingProgressEnds, this.onLoadBuildPlanLoadingProgressEnds)
  }

  async fetchBuildPlan(buildPlanId: string): Promise<IBuildPlan> {
    try {
      return await buildPlans.getBuildPlanById(buildPlanId, true)
    } catch (error) {
      if (error.message === NO_ACCESS_BUILD_PLAN_PARTS_MESSAGE) {
        messageService.showErrorMessage(NO_ACCESS_PRINT_ORDER_PARTS_MESSAGE)
      } else {
        messageService.showErrorMessage(error.message)
      }
    }
  }

  getParsedDimensionBoxText(text: string) {
    const splitText = text.split(' ')
    const units = splitText.pop()
    const value = splitText.join(' ')

    return { value, units }
  }

  isNoClearanceBox(dimensionBox: DimensionBox): boolean {
    return dimensionBox.text === this.$i18n.t('clearanceTool.noClearance').toString()
  }

  getDownloadFileKey(type) {
    if (type === 'pkg') {
      return getDownloadPrintOrderFileKey(this.job)
    }
    if (type === 'csv') {
      const parameters = this.job.parameters && JSON.parse(this.job.parameters)
      // there can be scenarios where job does not have any parameters
      if (parameters) {
        return parameters && parameters.modality === PrintingTypes.BinderJet
          ? parameters.buildLayoutCsv && parameters.buildLayoutCsv.s3FileName
          : null
      }
    }
    return null
  }

  async downloadFile(type) {
    this.fileType = type
    const downloadFileKey = this.getDownloadFileKey(type)
    if (downloadFileKey) {
      const signedUrl = await this.getSignedUrlV1({
        fileKey: downloadFileKey,
        newName: this.downloadFileName,
      })
      if (signedUrl) {
        window.open(signedUrl)
      }
    } else {
      if (
        this.job.code === JobStatusCode.CREATED ||
        this.job.code === JobStatusCode.QUEUED ||
        this.job.code === JobStatusCode.RUNNING
      ) {
        await this.$refs.alert.open(this.$i18n.t('printOrderDownload'), this.$i18n.t('printOrderPrepUnderProcess'))
      } else {
        if (type === 'pkg') {
          await this.$refs.alert.open(this.$i18n.t('printOrderDownload'), this.$i18n.t('printOrderPkgNotavailable'))
        }
        if (type === 'csv') {
          await this.$refs.alert.open(
            this.$i18n.t('printOrderDownload'),
            this.$i18n.t('printOrderBldReportNotavailable'),
          )
        }
      }
    }
  }

  async checkAccess(buildPlanId: string): Promise<boolean> {
    let item: FileExplorerItem = this.find(buildPlanId)
    await this.getItemPermissions(buildPlanId)
    if (!item) {
      item = await this.fetchItemById(buildPlanId)
    }

    const defaultVersionId = getDefaultVariantIdFromVersionAndPath(item)
    if (
      !this.getUserDetails ||
      (!this.permissionsByItemId[defaultVersionId] &&
        !this.permissionsByItemId[item.id] &&
        item.createdBy !== this.getUserDetails.id)
    ) {
      return false
    }

    return true
  }

  async checkForViewAccess(buildPlanId: string): Promise<boolean> {
    await this.getItemPermissions(buildPlanId)

    if (!this.permissionsByItemId[buildPlanId] || !this.getUserDetails) {
      return false
    }

    const userPermission = this.permissionsByItemId[buildPlanId].find(
      (permission) => permission.grantedTo === this.getUserDetails.id,
    )

    return userPermission ? userPermission.role === ItemPermissionsRole.Viewer : false
  }

  toggleDetails() {
    this.isOpenDetails = !this.isOpenDetails
    // This timeout is needed to render the canvas after resizing grid and canvas
    setTimeout(() => {
      this.resizeCanvas()
    }, TIMEOUT_FOR_UPDATE_GRID)
  }

  async toggleCrossSection() {
    this.isCrossSectionModeActive = !this.isCrossSectionModeActive
    if (this.isCrossSectionModeActive && this.showSlider) {
      let done
      const promise = new Promise<void>((resolve) => (done = resolve))
      this.sliderPromise = { done, promise }
      this.toggleSlider()
      await promise
      this.sliderPromise = null
    }

    if (this.isCrossSectionModeActive && this.isClearanceToolEnabled) {
      this.enableClearanceTool({
        isEnabled: false,
        restoreSelectionManagerState: true,
      })
    }

    this.enableCrossSectionMode({
      isEnabled: this.isCrossSectionModeActive,
      crossSectionMatrix: this.buildPlan.crossSectionMatrix,
    })
    if (this.isCrossSectionModeActive) {
      this.changeViewMode(ViewModeTypes.CrossSection)
    } else {
      this.changeViewMode(ViewModeTypes.PrintOrderPreview)
    }
  }

  /**
   * Toggles clearance tool
   * @param isEnabled
   * if true activates clearance tool
   * Otherwise, deactivates clearance tool
   */
  async toggleClearanceTool(isEnabled: boolean) {
    if (isEnabled && this.showSlider) {
      let done
      const promise = new Promise<void>((resolve) => (done = resolve))
      this.sliderPromise = { done, promise }
      this.toggleSlider()
      await promise
      this.sliderPromise = null
    }

    if (isEnabled && this.isCrossSectionModeEnabled) {
      this.toggleCrossSection()
    }

    this.enableClearanceTool({
      isEnabled,
      restoreSelectionManagerState: true,
    })
  }

  @Watch('isToolMaskDisplaying')
  onToolMaskDisplayChanged(isDisplayed: boolean) {
    if (!isDisplayed && this.isClearanceToolEnabled) {
      this.toggleClearanceTool(false)
    }
  }

  @Watch('getSelectedParts')
  onSelectionChanged(partsInSelection: ISelectable[], partsInPrevSelection: ISelectable[]) {
    if (!this.isClearanceToolEnabled || this.isMeshToMeshClearance || partsInPrevSelection.length !== 1) {
      return
    }

    const from = this.enabledClearanceFrom as ClearanceTypes
    const to = this.enabledClearanceTo as ClearanceTypes
    const selected = partsInPrevSelection[0]
    if (selected.type === SelectionUnit.Part) {
      const buildPlanItemId = selected.id
      this.measureDistanceToEnvironment({ from, to, buildPlanItemId })
    } else if (selected.type === SelectionUnit.Body) {
      const [buildPlanItemId, componentId, geometryId] = selected.id.split(PART_BODY_ID_DELIMITER)
      this.measureDistanceToEnvironment({ from, to, buildPlanItemId, componentId, geometryId })
    }
  }

  toggleClearanceModeFrom(clearanceType: ClearanceTypes) {
    if (this.isClearanceFromEnabled(clearanceType)) {
      return
    }

    this.showRubberBand({ isShown: false })
    this.deselect()

    this.enableClearanceModeFrom({ clearanceType })
    if (this.isClearanceFromEnabled(ClearanceTypes.Bodies)) {
      this.setSelectionMode({ mode: SelectionUnit.Body })
    } else if (this.isClearanceFromEnabled(ClearanceTypes.Parts)) {
      this.setSelectionMode({ mode: SelectionUnit.Part })
    } else if (this.isClearanceToEnabled(ClearanceTypes.Bodies)) {
      this.setSelectionMode({ mode: SelectionUnit.Body })
    } else if (this.isClearanceToEnabled(ClearanceTypes.Parts)) {
      this.setSelectionMode({ mode: SelectionUnit.Part })
    }
  }

  toggleClearanceModeTo(clearanceType: ClearanceTypes) {
    if (this.isClearanceToEnabled(clearanceType)) {
      return
    }

    if (
      (clearanceType !== ClearanceTypes.Bodies && clearanceType !== ClearanceTypes.Parts) ||
      !this.isRubberBandShown
    ) {
      this.showRubberBand({ isShown: false })
      this.deselect()
    }

    this.enableClearanceModeTo({ clearanceType })
    if (this.isClearanceFromEnabled(ClearanceTypes.Bodies)) {
      this.setSelectionMode({ mode: SelectionUnit.Body })
    } else if (this.isClearanceFromEnabled(ClearanceTypes.Parts)) {
      this.setSelectionMode({ mode: SelectionUnit.Part })
    } else if (this.isClearanceToEnabled(ClearanceTypes.Bodies)) {
      this.setSelectionMode({ mode: SelectionUnit.Body })
    } else if (this.isClearanceToEnabled(ClearanceTypes.Parts)) {
      this.setSelectionMode({ mode: SelectionUnit.Part })
    }
  }

  @Watch('isMeshToMeshClearance')
  onMeshToMeshClearanceEnabledChanged(isEnabled: boolean) {
    if (this.isClearanceToolEnabled) {
      this.changeClearanceSelectionObserver({ isEnabled })
    }
  }

  toggleSlider() {
    this.showSlider = !this.showSlider
    if (this.showSlider && this.isCrossSectionModeActive) {
      this.toggleCrossSection()
    }

    if (this.showSlider && this.isClearanceToolEnabled) {
      this.enableClearanceTool({
        isEnabled: false,
        restoreSelectionManagerState: true,
      })
    }

    if (this.isBinderJetModality) {
      if (this.showSlider) {
        this.oldIsViewLocked = this.isViewLocked
      }

      this.setViewLocked(this.oldIsViewLocked || this.showSlider)
    }

    if (this.showSlider) {
      this.changeViewMode(ViewModeTypes.Slicing)
    } else {
      this.changeViewMode(ViewModeTypes.PrintOrderPreview)
    }

    if (this.sliderPromise) {
      this.sliderPromise.done()
    }
  }

  changeHiddenPartsVisibility(show: boolean) {
    this.setShowHiddenPartsAsTransparent(show)
    if (show) {
      this.showHiddenPartsTransparent()
    } else {
      this.hideTransparentParts()
    }
  }

  @Watch('getBuildPlanViewMode')
  buildPlanViewModeChanged(viewMode: ViewModeTypes) {
    if (viewMode !== ViewModeTypes.Slicing && viewMode !== null) {
      this.showSlider = false
      this.sliderDisabled = true
      this.crossSectionDisabled = true
      this.isCrossSectionModeActive = false
      this.enableCrossSectionMode(this.isCrossSectionModeActive)
    }

    if (viewMode !== ViewModeTypes.ClearanceTool && viewMode !== null) {
      this.showSlider = false
      if (this.isBinderJetModality) {
        this.setViewLocked(this.oldIsViewLocked || this.showSlider)
      }

      this.disableSliceToolbarButton(true)
      this.clearanceToolDisabled = ![
        ViewModeTypes.Duplicate,
        ViewModeTypes.Rotate,
        ViewModeTypes.Move,
        ViewModeTypes.Slicing,
      ].includes(viewMode)

      if (this.isClearanceToolEnabled) {
        this.enableClearanceTool({
          isEnabled: false,
          restoreSelectionManagerState: this.clearanceToolDisabled,
        })
      }
    }

    if (viewMode === null) {
      this.sliderDisabled = false
      this.crossSectionDisabled = false
      this.clearanceToolDisabled = false
    }
  }

  async onCloseClick() {
    // Generate promise to await loading outdated files
    this.generatePreviewCreationPromise()
    const navigatedFrom = this.printOrderNavigatedFrom
    if (navigatedFrom === RouterNames.EditBuildPlan) {
      // @ts-ignore
      await this.$router.safePush({ name: RouterNames.EditBuildPlan, params: { id: this.getBuildPlan.id } })
      this.setPrintOrderNavigatedFrom(null)
      return
    }
    // @ts-ignore
    await this.$router.safePush(RouterPaths.PrintOrdersList)
  }

  updatePreview() {
    if (this.buildPlan) {
      this.updateItemPreview({ itemId: this.buildPlan.id })
    } else {
      this.getPreviewCreationPromise.done()
    }
  }

  @Watch('getIsLoading')
  async addPreviewUpdateEvent(isLoading: boolean) {
    if (isLoading) {
      window.removeEventListener('beforeunload', this.updatePreview)
    } else {
      window.addEventListener('beforeunload', this.updatePreview)
    }
  }

  async beforeDestroy() {
    this.enableClearanceTool({
      isEnabled: false,
      restoreSelectionManagerState: true,
    })
    // Unlock BP variant by current user to enable editing to other users only if user has rights to unlock
    this.isBuildPlanReleased = true
    this.setInsightSelectionIsEnabled(true)
    if (!this.isReadOnly) {
      await this.unlockBuildPlanVariant()
    }

    if (!this.getIsLoading) {
      this.updatePreview()
    } else {
      this.getPreviewCreationPromise.done()
    }
    this.enableSlicerMode(false)

    if (this.$router.currentRoute.name !== RouterNames.IBC_Inspections) this.setPrintOrderNavigatedFrom(null)

    window.removeEventListener('beforeunload', this.updatePreview)
    window.removeEventListener('beforeunload', this.unlockBuildPlanVariant)
    this.deselectBuildPlan()
    this.unsubscribeFromEventBus()
    this.dispose()
  }

  @Watch('buildPlanName')
  @Watch('buildPlanVersionLabel')
  @Watch('lockedBy')
  onHeaderResize() {
    this.$nextTick(() => {
      this.buildPlanNameTruncated = this.middleTruncateTextForElement(this.buildPlanName, this.$refs.buildPlanNameField)
    })

    this.$nextTick(() => {
      this.buildPlanVersionLabelTruncated = this.middleTruncateTextForElement(
        this.buildPlanVersionLabel,
        this.$refs.buildPlanVersionLabelField,
      )
    })
  }

  async printjobCreated(jobData) {
    if (jobData && jobData.message && jobData.message.jobId && jobData.message.jobId === this.$route.params.id) {
      const jobId = this.$route.params.id
      const job = await this.fetchJob(jobId)
      const buildPlan = this.buildPlan
      if (!job) {
        // @ts-ignore
        this.$router.safePush(RouterPaths.Home)
        return
      }
      this.job = job
      const pkgDownloadFileKey = this.getDownloadFileKey('pkg')
      if (pkgDownloadFileKey) {
        this.arsfFileSize = (await this.getHeadObject(pkgDownloadFileKey)).ContentLength
      }
      const csvDownloadFileKey = this.getDownloadFileKey('csv')
      if (csvDownloadFileKey) {
        this.csvFileSize = (await this.getHeadObject(csvDownloadFileKey)).ContentLength
      }
      this.loadPrintOrder({ buildPlan, printOrderId: jobId })
      if (this.$refs.slider && this.$refs.slider.loadData) {
        this.$refs.slider.loadData()
      }
    }
  }

  async fetchPrintOrderStatus() {
    if (this.job.id === this.$route.params.id) {
      const job = await this.fetchJob(this.$route.params.id)
      if (this.job.status !== job.status) {
        this.job = job
      }
      if (
        !(
          job.code === JobStatusCode.ERROR ||
          job.code === JobStatusCode.COMPLETE ||
          job.code === JobStatusCode.WARNING ||
          job.code === JobStatusCode.CANCELLED ||
          job.code === JobStatusCode.PENDING
        )
      ) {
        setTimeout(() => {
          this.fetchPrintOrderStatus()
        }, 10000)
      }
    }
  }

  private middleTruncateTextForElement(text: string, element: Element) {
    if (!element) {
      return text
    }

    const maxWidth = element.parentElement.offsetWidth
    const canvas = document.createElement('canvas')
    const elementStyle = window.getComputedStyle(element)
    const ctx = canvas.getContext('2d')
    ctx.font = elementStyle.font
      ? elementStyle.font
      : `${elementStyle.fontWeight} ${elementStyle.fontSize} / ${elementStyle.lineHeight} ${elementStyle.fontFamily}`

    if (ctx.measureText(text).width <= maxWidth) {
      return text
    }

    const delimiter = '...'
    const textLength = text.length
    const middle = Math.floor(textLength / 2)
    const tail = textLength % 2 === 0 ? null : text[textLength - 1]

    for (let i = middle; i > 0; i -= 1) {
      const truncatedText = tail
        ? `${text.substr(0, i)}${delimiter}${text.substr(textLength - i, i - 1)}${tail}`
        : `${text.substr(0, i)}${delimiter}${text.substr(textLength - i, i)}`

      if (ctx.measureText(truncatedText).width <= maxWidth) {
        return truncatedText
      }
    }

    return delimiter
  }
}
