
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import StoresNamespaces from '@/store/namespaces'
import { IBuildPlan, IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { IConstraints } from '@/types/BuildPlans/IConstraints'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { ConstraintCommand, ConstraintCommandPayload } from '@/types/UndoRedo/ConstraintCommand'
import { ICommand } from '@/types/UndoRedo/ICommand'
import CommonBuildPlanToolsMixin from './mixins/CommonBuildPlanToolsMixin'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const commandManager = namespace(StoresNamespaces.CommandManager)

@Component
export default class BuildPlanConstrainTab extends Mixins(CommonBuildPlanToolsMixin) implements IToolComponent {
  @buildPlansStore.Action updateConstraintsForBuildPlanItems: (payload: { bpItems: IBuildPlanItem[] }) => Promise<void>

  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter('getSelectedBuildPlanItems') buildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter('isConstrainReadOnly') isConstrainReadOnly: boolean
  @buildPlansStore.Getter getCommandType: CommandType

  @visualizationStore.Mutation('setIsLoading') setIsLoading: Function

  @commandManager.Getter getToolUndoStack: ICommand[]
  @commandManager.Mutation addCommand: (command: ICommand) => void
  /**************************************
   * Generic tool method implementations
   **************************************/
  // need to mention these generic optional methods even if they are not implemented by the tool
  // due to TypeScript's weak type detection per https://stackoverflow.com/a/47930521
  clickOk?: () => void
  clickCancel?: () => void
  // read-only tool mode flag

  buildPlanItemsConstraints: IConstraints = {
    translation: {
      x: false,
      y: false,
      z: false,
      variability: false,
    },
    rotation: {
      x: false,
      y: false,
      z: false,
      variability: false,
    },
  }
  updating: boolean = false

  get disabled() {
    return this.buildPlanItems.length === 0 || this.updating
  }

  mounted() {
    this.$emit('mounted')
    this.updateBuildPlanItemsConstraintsLocally()
  }

  @Watch('buildPlanItems')
  updateBuildPlanItemsConstraintsLocally() {
    if (!this.buildPlanItems.length) {
      this.buildPlanItemsConstraints = {
        translation: {
          x: false,
          y: false,
          z: false,
          variability: false,
        },
        rotation: {
          x: false,
          y: false,
          z: false,
          variability: false,
        },
      }
    } else {
      this.buildPlanItemsConstraints = JSON.parse(JSON.stringify(this.buildPlanItems[0].constraints))

      for (const bpItem of this.buildPlanItems) {
        for (const key in this.buildPlanItemsConstraints.translation) {
          if (this.buildPlanItemsConstraints.translation[key] !== bpItem.constraints.translation[key]) {
            this.buildPlanItemsConstraints.translation[key] = null
          }
        }

        for (const key in this.buildPlanItemsConstraints.rotation) {
          if (this.buildPlanItemsConstraints.rotation[key] !== bpItem.constraints.rotation[key]) {
            this.buildPlanItemsConstraints.rotation[key] = null
          }
        }
      }
    }
  }

  async onUpdateConstraints(optionsPropertyName: string, constraintPropertyName: string) {
    this.updating = true
    this.setIsLoading(true)

    try {
      const constraintCommandPayloads: ConstraintCommandPayload[] = []

      const bpItemWithUpdatedConstraints = this.buildPlanItems.map((bpItem) => {
        constraintCommandPayloads.push({
          buildPlanItemId: bpItem.id,
          constraints: {
            beforeApply: JSON.parse(JSON.stringify(bpItem.constraints)),
            afterApply: JSON.parse(JSON.stringify(this.buildPlanItemsConstraints)),
          },
        })

        bpItem.constraints[optionsPropertyName][constraintPropertyName] =
          this.buildPlanItemsConstraints[optionsPropertyName][constraintPropertyName]
        return bpItem
      })
      await this.updateConstraintsForBuildPlanItems({ bpItems: bpItemWithUpdatedConstraints })

      this.addCommand(
        new ConstraintCommand(
          this.getCommandType,
          constraintCommandPayloads,
          this.onUpdatingCallback,
          this.$store.dispatch,
          this.$store.commit,
        ),
      )
    } finally {
      this.updating = false
      this.setIsLoading(false)
    }
  }

  clickClose() {
    this.saveUndoData()
  }

  private saveUndoData() {
    /**
     * GEAMPREQ-141
     * If the tool was completed by clicking the “Close” and a change was done – the stored build plan stack
     * will be used again, and the tool undo stack will be added to it
     */
    const undoCommands = this.getToolUndoStack.filter(
      (command) => command instanceof ConstraintCommand,
    ) as ConstraintCommand[]
    undoCommands.forEach((command) => {
      command.commandType = CommandType.BuildPlanCommand
      this.addCommand(command)
    })
  }

  private onUpdatingCallback(isUpdating: boolean) {
    this.updating = isUpdating
  }
}
