export enum SupportTypes {
  Lines1 = 'Lines 1',
  Solid = 'Solid',
  NoSupports = 'No Supports',
}

export enum TreeAlgorithmTypes {
  Lines = 'lines',
  Contour = 'contour',
  Combined = 'combined',
}

export enum LinearAlgorithmTypes {
  Lines = 'lines',
  Solid = 'solid',
}

export enum FillTypes {
  Solid = 'Solid',
  Lattice = 'Lattice',
  LatticeAndSolid = 'Lattice & Solid',
}
export interface BuildPlanItemSettingsDto {
  overhangAngleDeg: number
  minRequiredTrianglesArea: number
  minRequiredConnectedEdgesLength: number
  edgeLaminarAngleDeg: number
  elementsCanBeCoincident: boolean
  recoaterDirection: number[]
  strategy: string
  selectedOverhangElements: string[]
  overhangSampleDirectionDeg: number
  overhangAngleLimit: number
  overhangDistance: number
  thickness: number
  gap: number
  draftAngle: number
  fillType: FillTypes
  baseHeight: number
  blend: boolean
  addNeck: boolean
  neckWidth: number
  neckHeight: number
  addPerforations: boolean
  perforationMinHeight: number
  perforationWidth: number
  perforationAngleDeg: number
}
