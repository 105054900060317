import { ICommand } from '@/types/UndoRedo/ICommand'
import { IUpdatableCommand } from '@/types/UndoRedo/IUpdatableCommand'
import { OnDragTransformation } from '@/types/UndoRedo/OnDragTransformation'
import { BuildPlanItemsCommand } from '@/types/UndoRedo/BuildPlanItemsCommand'
import { ArrangeToolCommand } from '@/types/UndoRedo/ArrangeToolCommand'
import { ChangePartOrientationCommand } from '@/types/UndoRedo/ChangePartOrientationCommand'
import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'

enum UndoableStack {
  BuildPlanRedoStack,
  BuildPlanUndoStack,
}

/***
 * Updates buildPlanItemId property inside the DragCommand for the dragTransformation field.
 * @param transformationToUpdate - Transformation to update (beforeDragStartTransformation | afterDragEndTransformation)
 * @param idToUpdate - Old build plan item id that was assigned before remove.
 * @param newBuildPlanItemId - Build plan item id that have to be assigned in the transformationToUpdate.buildPlanItemId
 */
function updateDragTransformationBuildPlanItemIds(
  transformationToUpdate: OnDragTransformation[],
  idToUpdate,
  newBuildPlanItemId,
) {
  transformationToUpdate.forEach((transformation) => {
    if (transformation.buildPlanItemId === idToUpdate) {
      transformation.buildPlanItemId = newBuildPlanItemId
    }
  })
}

function getCommandsFromUndoableStackToUpdate(
  stackToGetCommands: UndoableStack,
  store,
): ICommand[] | IUpdatableCommand[] {
  const stack =
    stackToGetCommands === UndoableStack.BuildPlanRedoStack
      ? store.state.commandManager.buildPlanRedoStack
      : store.state.commandManager.buildPlanUndoStack

  // Returns only commands that recreate build plan item ids.
  return stack.filter((item) => item instanceof BuildPlanItemsCommand)
}

/**
 * Walk throughout build plan items in the appropriate stack, and if build plan id was recreated
 * updates old build plan item id with new one.
 * @param commandsToUpdate - Commands that have to updated from the appropriate stack.
 * @param stackToUpdate - Destination stack to update build plan items ids.
 * @param store
 */
function updateUndoableCommandsInStack(
  commandsToUpdate: ICommand[] | IUpdatableCommand[],
  stackToUpdate: UndoableStack,
  store,
) {
  const stack =
    stackToUpdate === UndoableStack.BuildPlanRedoStack
      ? store.state.commandManager.buildPlanRedoStack
      : store.state.commandManager.buildPlanUndoStack

  commandsToUpdate.forEach((command) => {
    command.idsToUpdate.oldBuildPlanItemIds.forEach((idToUpdate, index) => {
      stack.forEach((item) => {
        if (item instanceof ArrangeToolCommand) {
          updateBuildPlanItemIdInArrangeCommand(item, idToUpdate, command.idsToUpdate.newBuildPlanItemIds[index])
        } else if (item instanceof ChangePartOrientationCommand) {
          updateBuildPlanItemIdInOrientCommand(item, idToUpdate, command.idsToUpdate.newBuildPlanItemIds[index])
        } else {
          item.buildPlanItems.forEach((buildPlanItem) => {
            if (buildPlanItem.id === idToUpdate) {
              buildPlanItem.id = command.idsToUpdate.newBuildPlanItemIds[index]
            }

            // If command in the stack has dragTransformation field
            // there need to update old buildPlanItemId properties with new one
            if (item.dragTransformation) {
              const { beforeDragStartTransformation, afterDragEndTransformation } = item.dragTransformation
              const newBuildPlanItemId = command.idsToUpdate.newBuildPlanItemIds[index]

              // Updates buildPlanItemId properties for the transformation that was stored before Gizmos drag event
              // and after Gizmos drag event
              updateDragTransformationBuildPlanItemIds(beforeDragStartTransformation, idToUpdate, newBuildPlanItemId)
              updateDragTransformationBuildPlanItemIds(afterDragEndTransformation, idToUpdate, newBuildPlanItemId)
            }
          })
        }
      })
    })
  })
}

function updateBuildPlanItemIdInArrangeCommand(
  command: ArrangeToolCommand,
  idToUpdate: string,
  newBuildPlanItemId: string,
) {
  updateArrangeCommandBuildPlanItemIds(command.buildPlanItemsBeforeArrangeApplied, idToUpdate, newBuildPlanItemId)
  updateArrangeCommandBuildPlanItemIds(command.buildPlanItemsAfterArrangeApplied, idToUpdate, newBuildPlanItemId)
}

function updateArrangeCommandBuildPlanItemIds(
  buildPlanItems: IBuildPlanItem[],
  idToUpdate: string,
  newBuildPlanItemId: string,
) {
  buildPlanItems.forEach((item) => {
    if (item.id === idToUpdate) {
      item.id = newBuildPlanItemId
    }
  })
}

function updateBuildPlanItemIdInOrientCommand(command: ChangePartOrientationCommand, oldId: string, newId: string) {
  if (command.getBuildPlanItemId() === oldId) {
    command.setBuildPlanItemId(newId)
  }
}

export default function actionsListener() {
  return (store) => {
    store.subscribeAction({
      /*
        For the following actions:
         - 'commandManager/undoCommand'
         - 'commandManager/redoCommand'
        We subscribed on after action dispatch event, because we need that command from undo stack  was already moved
        to redo stack and vice versa.
      */
      after: (action) => {
        switch (action.type) {
          case 'commandManager/undoCommand':
            // When Undo button is pressed.
            // We are retrieving commands that have to update from the build plan redo stack.
            const undoCommandsToUpdate = getCommandsFromUndoableStackToUpdate(UndoableStack.BuildPlanRedoStack, store)

            // Run updating build plan items ids in the build plan undo stack.
            updateUndoableCommandsInStack(undoCommandsToUpdate, UndoableStack.BuildPlanUndoStack, store)
            break
          case 'commandManager/redoCommand':
            // When Redo button is pressed.
            // We are retrieving commands that have to update from the build plan undo stack.
            const redoCommandsToUpdate = getCommandsFromUndoableStackToUpdate(UndoableStack.BuildPlanUndoStack, store)

            // Run updating build plan items ids in the build plan redo stack.
            updateUndoableCommandsInStack(redoCommandsToUpdate, UndoableStack.BuildPlanRedoStack, store)
            break
          default:
            // Ignore other actions.
            break
        }
      },
    })
  }
}
