import Vue from 'vue'
import { MutationTree } from 'vuex'
import { Vector3 } from '@babylonjs/core/Maths'
import { find } from 'lodash'

import { IBuildPlansState } from './types'
import {
  IBuildPlan,
  ILoadingPart,
  SelectionUnit,
  ISelectable,
  IBuildPlanItem,
  IVariantTree,
  AddPartToolState,
  DuplicateToolState,
  IGeometryProperties,
  defaultDisplayToolbarState,
  IDisplayToolbarState,
  IBuildPlanLockDto,
  IMeshGeometryProperties,
  IVariantItem,
  ILoadingItemsData,
  IIBCDisplayToolbarState,
  defaultIBCDisplayToolbarState,
} from '@/types/BuildPlans/IBuildPlan'
import { ISite } from '@/types/ISite'
import { IMachineConfig, PrintingTypes } from '@/types/IMachineConfig'
import { IMaterial } from '@/types/IMaterial'
import { ViewModes } from '@/constants'
import { IJob } from '@/types/PartsLibrary/Job'
import { CollisionEventArgs } from '@/visualization/rendering/CollisionManager'
import { IBuildPlate, IBuildPlateMachineConfig, IBuildPlateMaterial } from '@/types/BuildPlates/IBuildPlate'
import { IConstraints } from '@/types/BuildPlans/IConstraints'
import ITransformationDelta from '@/types/BuildPlans/ITransformationDelta'
import { ToolTypes } from '@/types/BuildPlans/ToolTypes'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { ILabel } from '@/types/Marking/ILabel'
import { ContentViewModeTypes } from '@/visualization/types/ContentViewMode'
import { IBuildPlanInsight, IPendingInsights } from '@/types/BuildPlans/IBuildPlanInsight'
import { FileExplorerItem, LockWhitelistElementDto } from '@/types/FileExplorer/FileExplorerItem'
import { IInsightsCount } from '@/types/Common/Insights'
import { RestoreSelectedPartsType } from '@/types/BuildPlans/RestoreSelectedPartsType'
import { MachineConfigMaterialBinder, PrintStrategyInfo, PrintStrategyShortInfo } from '@/types/Sites/Site'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import { PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import { LoadBuildPlanOptions } from '@/visualization/types/LoadBuildPlanOptions'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { IPartRenderable } from '@/types/Parts/IPartRenderable'
import { IbcMeasurementDeleteDto, IIBCPlan, IMeasurementWithProperties } from '@/types/IBCPlans/IIBCPlan'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { RouterNames } from '@/router'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'

export const mutations: MutationTree<IBuildPlansState> = {
  sortVariants(state): IVariantTree {
    state.buildPlanVariants.sort((a, b) => {
      const timestampA = new Date(a.createdAt).getTime()
      const timestampB = new Date(b.createdAt).getTime()
      return timestampA - timestampB
    })
    return
  },

  setVariantIsLocked(state, payload: { variantId: string; lockWhitelist: LockWhitelistElementDto[] }) {
    const bpVariant = state.buildPlanVariants.find((variant) => variant.id === payload.variantId)
    if (!bpVariant) {
      return
    }

    bpVariant.isLocked = true
    bpVariant.lockWhitelist = payload.lockWhitelist

    if (state.buildPlan.id === payload.variantId) {
      state.buildPlan.isLocked = true
      state.buildPlan.lockWhitelist = payload.lockWhitelist
    }
  },

  unsetActiveVariantIsLocked(state: IBuildPlansState) {
    const bpVariant = state.buildPlanVariants.find((bp) => bp.id === state.buildPlan.id)
    if (!bpVariant) {
      return
    }

    bpVariant.isLocked = false
    bpVariant.lockWhitelist = []
  },

  updateVariantLockInfo(state, buildPlanLockInfo: IBuildPlanLockDto) {
    const buildPlan = state.buildPlanVariants.find((variant) => variant.id === buildPlanLockInfo.id)
    if (!buildPlan) {
      return
    }

    buildPlan.isLocked = buildPlanLockInfo.isLocked
    buildPlan.lockWhitelist = buildPlanLockInfo.lockWhitelist
  },

  setPreviewImageUrl(state, url: string) {
    if (state.buildPlan) {
      state.buildPlan.previewImageUrl = url
    }
  },

  addVariant(state, buildPlan: IVariantItem) {
    state.buildPlanVariants.push(buildPlan)
  },

  setVariants(state, buildPlans: IVariantItem[]) {
    state.buildPlanVariants = buildPlans
  },

  updateVariant(state: IBuildPlansState, buildPlan: IVariantItem) {
    const variantIndex = state.buildPlanVariants.findIndex((variant) => variant.id === buildPlan.id)
    if (variantIndex !== -1) {
      Vue.set(state.buildPlanVariants, variantIndex, buildPlan)
    }
  },

  setBuildPlan(state: IBuildPlansState, buildPlan: IBuildPlan) {
    state.buildPlan = buildPlan
  },

  loadIBCPlan(state: IBuildPlansState, payload: { ibcPlan: IIBCPlan; options?: LoadBuildPlanOptions }) {
    // This mutation passes payload to Babylon side
  },

  addToLoadingParts(state: IBuildPlansState, loadingPart: ILoadingPart) {
    state.loadingParts.push({ ...loadingPart, isSuccess: null })
  },

  updateLoadingPart(state: IBuildPlansState, loadingPart: ILoadingPart) {
    const index: number = state.loadingParts.findIndex((part) => part.id === loadingPart.id)
    if (index > -1) {
      const updatedLoadingPart: ILoadingPart = { ...state.loadingParts[index], ...loadingPart }
      state.loadingParts.splice(index, 1, updatedLoadingPart)
    }
  },

  setSelectedViewMode(state: IBuildPlansState, mode: ViewModes) {
    state.selectedViewMode = mode
  },

  setBuildPlanViewMode(state: IBuildPlansState, mode: ViewModeTypes) {
    state.selectedBuildPlanViewMode = mode
  },

  setMaterials(state: IBuildPlansState, materials: IMaterial[]) {
    state.materials = materials
  },

  setPrintSites(state: IBuildPlansState, sites: ISite[]) {
    state.printSites = sites
  },

  setMachineConfigMaterialBinders(
    state: IBuildPlansState,
    machineConfigMaterialBinders: MachineConfigMaterialBinder[],
  ) {
    state.machineConfigMaterialBinders = machineConfigMaterialBinders
  },

  setActivePrintStrategies(state, printStrategies: PrintStrategyInfo[]) {
    state.activePrintStrategies = printStrategies
  },

  setPrintStrategies(state, printStrategies: PrintStrategyShortInfo[]) {
    state.printStrategies = printStrategies
  },

  setMachineConfigs(state: IBuildPlansState, machineConfigs: IMachineConfig[]) {
    state.machineConfigs = machineConfigs
  },

  selectBuildPlate(
    state: IBuildPlansState,
    params: {
      buildPlatePk: VersionablePk
      machineConfigPk: VersionablePk
      buildPlanSubType: ItemSubType
      modality: PrintingTypes
    },
  ) {
    state.buildPlan.buildPlateId = params.buildPlatePk.id
    state.buildPlan.buildPlateVersion = params.buildPlatePk.version
  },

  setBuildPlate(state: IBuildPlansState, pk: VersionablePk) {
    state.buildPlan.buildPlateId = pk.id
    state.buildPlan.buildPlateVersion = pk.version
  },

  setIsLoading(state: IBuildPlansState, value: boolean) {
    state.isLoading = value
  },

  setIsLayersLoading(state: IBuildPlansState, value: boolean) {
    state.isLayersLoading = value
  },

  setSelectedBuildPlanJobs(state: IBuildPlansState, jobs: IJob[]) {
    state.selectedBuildPlanJobs = jobs
  },

  setSelectionMode(state: IBuildPlansState, mode: SelectionUnit) {
    state.selectionMode = mode
  },

  addBuildPlan(state: IBuildPlansState, newBuildPlan: IBuildPlan) {
    state.buildPlans = [...state.buildPlans.filter((element) => element.id !== newBuildPlan.id), newBuildPlan]
  },

  updateBuildPlan(state: IBuildPlansState, newBuildPlan: IBuildPlan) {
    const index = state.buildPlans.findIndex((bp) => bp.id === newBuildPlan.id)
    if (index) {
      state.buildPlans.splice(index, 1, newBuildPlan)
    }
  },

  setBuildPlanItem(state, params: { buildPlanItem: IBuildPlanItem; buildPlanItemId?: string }) {
    if (!state.buildPlan) {
      return
    }

    if (params.buildPlanItemId) {
      const arrayIndex = state.buildPlan.buildPlanItems.findIndex((bpItem) => bpItem.id === params.buildPlanItemId)
      if (arrayIndex > -1) {
        Vue.set(state.buildPlan.buildPlanItems, arrayIndex, params.buildPlanItem)
      }
    } else {
      state.buildPlan.buildPlanItems = [...state.buildPlan.buildPlanItems, params.buildPlanItem]
    }
  },

  addBuildPlanItems(state, buildPlanItems: IBuildPlanItem[]) {
    const bpItems = [...state.buildPlan.buildPlanItems, ...buildPlanItems]
    Vue.set(state.buildPlan, 'buildPlanItems', bpItems)
  },

  updateBuildPlanItems(state, buildPlanItems: IBuildPlanItem[]) {
    for (const buildPlanItem of buildPlanItems) {
      const buildPlanItemIndex = state.buildPlan.buildPlanItems.findIndex((bpItem) => bpItem.id === buildPlanItem.id)

      if (buildPlanItemIndex !== -1) {
        Vue.set(state.buildPlan.buildPlanItems, buildPlanItemIndex, buildPlanItem)
      }
    }
  },

  selectItems(state: IBuildPlansState, payload: { selectedItems: ISelectable[]; attach: boolean; silent?: boolean }) {
    let selectedItems = [...state.selectedItems]
    if (payload.selectedItems === null || !payload.selectedItems.length) {
      Vue.set(state, 'selectedItems', [])
      return
    }

    if (payload.attach) {
      for (const item of payload.selectedItems) {
        const includeIndex = selectedItems.findIndex((selected) => {
          return selected.id === item.id && selected.name === item.name && selected.type === item.type
        })
        if (includeIndex !== -1) {
          selectedItems.splice(includeIndex, 1)
        } else {
          selectedItems.push(item)
        }
      }
    } else {
      selectedItems = payload.selectedItems
    }

    Vue.set(state, 'selectedItems', selectedItems)
  },

  preferredItems(
    state: IBuildPlansState,
    payload: { selectedItems: ISelectable[]; attach: boolean; silent?: boolean },
  ) {
    if (payload.selectedItems === null || !payload.selectedItems.length) {
      state.preferredSupportItems = []
      return
    }

    if (payload.attach) {
      for (const item of payload.selectedItems) {
        const includeIndex = state.preferredSupportItems.findIndex((selected) => {
          return selected.id === item.id && selected.name === item.name && selected.type === item.type
        })
        if (includeIndex !== -1) {
          state.preferredSupportItems.splice(includeIndex, 1)
        } else {
          state.preferredSupportItems.push(item)
        }
      }
    } else {
      state.preferredSupportItems = payload.selectedItems
    }
  },

  prohibitedItems(
    state: IBuildPlansState,
    payload: { selectedItems: ISelectable[]; attach: boolean; silent?: boolean },
  ) {
    if (payload.selectedItems === null || !payload.selectedItems.length) {
      state.prohibitedSupportItems = []
      return
    }

    if (payload.attach) {
      for (const item of payload.selectedItems) {
        const includeIndex = state.prohibitedSupportItems.findIndex((selected) => {
          return selected.id === item.id && selected.name === item.name && selected.type === item.type
        })
        if (includeIndex !== -1) {
          state.prohibitedSupportItems.splice(includeIndex, 1)
        } else {
          state.prohibitedSupportItems.push(item)
        }
      }
    } else {
      state.prohibitedSupportItems = payload.selectedItems
    }
  },

  updateSelectedItemTranslation(state: IBuildPlansState, payload: { buildPlanItemId: string; translation: Vector3 }) {
    const selectedItem = state.selectedItems.find((item) => item.id === payload.buildPlanItemId)
    if (selectedItem) {
      selectedItem.translation = payload.translation
    }
  },

  updateSelectedItemGeometryProps(
    state: IBuildPlansState,
    payload: { buildPlanItemId: string; geometryProps: IGeometryProperties },
  ) {
    const selectedItem = state.selectedItems.find((item) => item.id === payload.buildPlanItemId)
    if (selectedItem) {
      selectedItem.geometryProperties = payload.geometryProps
    }
  },

  hoverBody(state: IBuildPlansState, payload: { bpItemId: string; bodyId: string }) {
    if (payload) {
      if (
        !state.hoverBodyId ||
        state.hoverBodyId.bpItemId !== payload.bpItemId ||
        state.hoverBodyId.bodyId !== payload.bodyId
      ) {
        state.hoverBodyId = payload
      }

      return
    }

    if (state.hoverBodyId) {
      state.hoverBodyId = payload
    }
  },

  deletePartsInState(state: IBuildPlansState, partIds: string[]) {
    state.buildPlan.buildPlanItems = state.buildPlan.buildPlanItems.filter((partItem) => !partIds.includes(partItem.id))
  },

  deselectBuildPlan(state: IBuildPlansState) {
    state.buildPlan = null
    state.selectedBuildPlanJobs = []
    state.selectedItems = []
    state.selectedPartsCollisions = []
    state.loadingParts = []
    state.collaboratorsCount = 0
  },

  setSelectedPartsCollisions(state: IBuildPlansState, payload: { elements: CollisionEventArgs[]; silent?: boolean }) {
    state.selectedPartsCollisions = []
    if (payload.elements === null) {
      return
    }

    for (const part of payload.elements) {
      const partName =
        part.buildPlanItemId !== null
          ? state.buildPlan.buildPlanItems.find((bpItem) => bpItem.id === part.buildPlanItemId).part.name
          : part.name
      state.selectedPartsCollisions.push(partName)
    }
  },

  setDuplicateToolState(state: IBuildPlansState, payload: Partial<DuplicateToolState>) {
    state.duplicateTool = { ...state.duplicateTool, ...payload }
  },

  setBuildPlates(state: IBuildPlansState, plates: IBuildPlate[]) {
    state.buildPlates = plates
  },

  setBuildPlateMaterials(state: IBuildPlansState, bpMaterials: IBuildPlateMaterial[]) {
    state.buildPlateMaterials = bpMaterials
  },

  setBuildPlateMachineConfigs(state: IBuildPlansState, bpMachineConfigs: IBuildPlateMachineConfig[]) {
    state.buildPlateMachineConfigs = bpMachineConfigs
  },

  setCrossSectionMatrix(state: IBuildPlansState, crossSectionMatrix: number[]) {
    state.crossSectionMatrix = crossSectionMatrix
  },

  setSelectedLabel(state: IBuildPlansState, label: ILabel) {
    state.selectedLabel = label
  },

  setIsLabelInstancing(state: IBuildPlansState, isInstancing: boolean) {
    state.isLabelInstancing = isInstancing
  },

  setContentViewMode(state: IBuildPlansState, mode: ContentViewModeTypes) {
    state.contentViewMode = mode
  },

  loadInsights(state: IBuildPlansState, insights: IBuildPlanInsight[]) {
    state.insights = insights
  },

  addInsights(state: IBuildPlansState, insights: IBuildPlanInsight[]) {
    state.insights.push(...insights)
  },

  removeInsights(state: IBuildPlansState, insightsIds: string[]) {
    state.insights = state.insights.filter((insight: IBuildPlanInsight) => !insightsIds.includes(insight.id))
  },

  removeInsightsByInsights(state: IBuildPlansState, insightsToRemove: IBuildPlanInsight[]) {
    state.insights = state.insights.filter((insight: IBuildPlanInsight) => !find(insightsToRemove, insight))
  },

  removeInsightsByTool(state: IBuildPlansState, toolName: ToolNames) {
    state.insights = state.insights.filter((insight: IBuildPlanInsight) => insight.tool !== toolName)
  },

  updateInsights(state: IBuildPlansState, insights: IBuildPlanInsight[]) {
    insights.forEach((insight: IBuildPlanInsight) => {
      const index = state.insights.findIndex((i) => i.id === insight.id)
      if (index >= 0) {
        Vue.set(state.insights, index, insight)
      } else {
        state.insights.push(insight)
      }
    })
  },

  clearInsights(state: IBuildPlansState) {
    Vue.set(state, 'insights', [])
  },

  reportInsightIssues(state: IBuildPlansState, issues: IPendingInsights[]) {
    state.pendingInsights = issues
  },

  setCalcCostInProgress(state: IBuildPlansState, value: boolean) {
    state.calcCostInProgress = value
  },

  setAddPartToolState(state: IBuildPlansState, newState: Partial<AddPartToolState>) {
    state.addPartTool = { ...state.addPartTool, ...newState }
  },

  setInsightSettings(state: IBuildPlansState, insightSettings) {
    state.insightsSettings = insightSettings
  },

  setActiveInsightToolComponent(state: IBuildPlansState, cId: number) {
    state.insightTool.activeComponent = cId
  },

  setInsightsCount(state: IBuildPlansState, count: IInsightsCount) {
    state.insightTool.insightCount = count
  },

  setIsShownNoMaterialParamsTooltip(state: IBuildPlansState, value: boolean) {
    state.isShownNoMaterialParamsTooltip = value
  },

  setIsReadOnly(state: IBuildPlansState, payload: { value: boolean; global?: boolean }) {
    state.isReadOnly = payload.value
  },

  updatePartImportJobs(state: IBuildPlansState, jobs: IJob[]) {
    jobs.forEach((job) => {
      const index = state.partImportJobs.findIndex((j) => j.id === job.id)
      if (index >= 0) {
        Vue.set(state.partImportJobs, index, job)
      } else {
        state.partImportJobs.push(job)
      }
    })
  },

  setAddPartGeometryProps(state: IBuildPlansState, geometryProps: IGeometryProperties) {
    state.addPartTool.geometryProperties = geometryProps
  },

  setParentFolder(state: IBuildPlansState, folder: FileExplorerItem) {
    state.parentFolder = folder
  },

  setInsightSelectionIsEnabled(state: IBuildPlansState, enabled: boolean) {
    state.insightTool.insightSelectionEnabled = enabled
  },

  setPrintOrderNavigatedFrom(state: IBuildPlansState, from: string) {
    state.previewPrintOrder.navigatedFrom = from
  },

  setIsVariantProcessing(state: IBuildPlansState, value: boolean) {
    state.isVariantProcessing = value
  },

  setIsVariantCreating(state: IBuildPlansState, value: boolean) {
    state.isVariantProcessing = value
  },

  setIsBuildPlanDisposing(state: IBuildPlansState, value: boolean) {
    state.isBuildPlanDisposing = value
  },

  setBuildPlanDisposePromise(state: IBuildPlansState, value: { promise: Promise<void>; done: Function }) {
    state.buildPlanDisposePromise = value
  },

  updateBuildPlanVariantJobs(state: IBuildPlansState, jobs: IJob[]) {
    jobs.forEach((job) => {
      const index = state.buildPlanVariantJobs.findIndex((j) => j.id === job.id)
      if (index >= 0) {
        Vue.set(state.buildPlanVariantJobs, index, job)
      } else {
        state.buildPlanVariantJobs.push(job)
      }
    })
  },

  updateIbcPlanJobs(state: IBuildPlansState, jobs: IJob[]) {
    jobs.forEach((job) => {
      const index = state.ibcPlanJobs.findIndex((j) => j.id === job.id)
      if (index >= 0) {
        Vue.set(state.ibcPlanJobs, index, job)
      } else {
        state.ibcPlanJobs.push(job)
      }
    })
  },

  setDisplayToolbarStates(state: IBuildPlansState) {
    state.buildPlanVariants.forEach((variant) => {
      if (variant.isRemoved) {
        return
      }

      if (variant.itemType === ItemType.IbcPlan) {
        if (state.ibcDisplayToolbarStates.find((toolbar) => toolbar.ibcPlanId === variant.id)) {
          return
        }

        state.ibcDisplayToolbarStates.push({
          ibcPlanId: variant.id,
          state: JSON.parse(JSON.stringify(defaultIBCDisplayToolbarState)),
        })
      } else {
        if (state.displayToolbarStates.find((toolbar) => toolbar.buildPlanId === variant.id)) {
          return
        }

        state.displayToolbarStates.push({
          buildPlanId: variant.id,
          state: JSON.parse(JSON.stringify(defaultDisplayToolbarState)),
        })
      }
    })
  },

  setDisplayToolbarStateForPrintOrder(state: IBuildPlansState) {
    state.displayToolbarStates.push({
      buildPlanId: state.buildPlan.id,
      state: JSON.parse(JSON.stringify(defaultDisplayToolbarState)),
    })
  },

  addDisplayToolbarState(state: IBuildPlansState, payload: { buildPlanId: string; state: IDisplayToolbarState }) {
    state.displayToolbarStates.push({ buildPlanId: payload.buildPlanId, state: payload.state })
  },

  updateDisplayToolbarState(state: IBuildPlansState, payload: { buildPlanId: string; state: IDisplayToolbarState }) {
    const VIEW_MODES_TO_CHECK = [ViewModeTypes.SimulationCompensation]
    let index
    if (VIEW_MODES_TO_CHECK.includes(state.selectedBuildPlanViewMode)) {
      index = state.displayToolbarStates.findIndex(
        (s) => s.buildPlanId === payload.buildPlanId && s.viewMode === state.selectedBuildPlanViewMode,
      )
    } else {
      index = state.displayToolbarStates.findIndex((s) => s.buildPlanId === payload.buildPlanId)
    }

    if (index > -1) {
      state.displayToolbarStates[index].state = payload.state
    } else {
      state.displayToolbarStates.push({
        buildPlanId: payload.buildPlanId,
        state: payload.state,
        viewMode: state.selectedBuildPlanViewMode,
      })
    }
  },

  deleteDisplayToolbarState(state: IBuildPlansState, buildPlanId: string) {
    const index = state.displayToolbarStates.findIndex((s) => s.buildPlanId === buildPlanId)
    if (index > -1) {
      state.displayToolbarStates.splice(index, 1)
    }
  },

  addIBCDisplayToolbarState(state: IBuildPlansState, payload: { ibcPlanId: string; state: IIBCDisplayToolbarState }) {
    state.ibcDisplayToolbarStates.push({ ibcPlanId: payload.ibcPlanId, state: payload.state })
  },

  updateIBCDisplayToolbarState(
    state: IBuildPlansState,
    payload: { ibcPlanId: string; state: IIBCDisplayToolbarState },
  ) {
    const index = state.ibcDisplayToolbarStates.findIndex((s) => s.ibcPlanId === payload.ibcPlanId)

    if (index > -1) {
      state.ibcDisplayToolbarStates[index].state = payload.state
    } else {
      state.ibcDisplayToolbarStates.push({
        ibcPlanId: payload.ibcPlanId,
        state: payload.state,
      })
    }
  },

  deleteIBCDisplayToolbarState(state: IBuildPlansState, ibcPlanId: string) {
    const index = state.ibcDisplayToolbarStates.findIndex((s) => s.ibcPlanId === ibcPlanId)
    if (index > -1) {
      state.ibcDisplayToolbarStates.splice(index, 1)
    }
  },

  setBuildPlanPrintStrategy(state: IBuildPlansState, printStrategy: BuildPlanPrintStrategyDto) {
    state.buildPlanPrintStrategy = printStrategy
    if (state.printStrategyParameterSets.length > 0) {
      printStrategy.printStrategyParameterSets.forEach((psps) => {
        const index = state.printStrategyParameterSets.findIndex(
          (statePsps) => statePsps.id === psps.id && statePsps.version === psps.version,
        )
        if (index >= 0) {
          state.printStrategyParameterSets.splice(index, 1, psps)
        } else {
          state.printStrategyParameterSets.push(psps)
        }
      })
    } else {
      state.printStrategyParameterSets = printStrategy.printStrategyParameterSets
    }
  },

  setCollaboratorsCount(state: IBuildPlansState, count: number) {
    state.collaboratorsCount = count
  },

  removePrintStrategyParameterSet(state: IBuildPlansState, printStrategyParameterSetId: number) {
    const index = state.printStrategyParameterSets.findIndex(({ id }) => id === printStrategyParameterSetId)
    if (index !== -1) {
      state.printStrategyParameterSets.splice(index, 1)
    }
  },

  setCanCreateVariants(state: IBuildPlansState, value: boolean) {
    state.canCreateVariants = value
  },

  selectPart(state, payload: { item: PartListItemViewModel; selectionType: SelectionTypes }) {
    if (payload.item.id) {
      switch (payload.selectionType) {
        case SelectionTypes.Single:
          state.addPartTool.selectedParts = [payload.item]
          break

        case SelectionTypes.Multiple:
          state.addPartTool.selectedParts.push(payload.item)
          break

        default:
          throw new Error(`Unknown selection type: ${payload.item.name}`)
      }
    }
  },

  unselectPart(state, payload: { item: PartListItemViewModel; selectionType: SelectionTypes }) {
    if (payload.item.id) {
      switch (payload.selectionType) {
        case SelectionTypes.Single:
          state.addPartTool.selectedParts = []
          break

        case SelectionTypes.Multiple:
          state.addPartTool.selectedParts = state.addPartTool.selectedParts.filter((o) => o.id !== payload.item.id)
          break

        default:
          throw new Error(`Unknown selection type: ${payload.item.name}`)
      }
    }
  },

  unselectAllParts(state) {
    state.addPartTool.selectedParts = []
  },

  setSelectedOrientationIndex(state, value) {
    state.selectedOrientationIndex = value
  },

  setSelectedBuildPlanPrintStrategy(state, buildPlanPrintStrategy: BuildPlanPrintStrategyDto): void {
    state.selectedBuildPlanPrintStrategy = buildPlanPrintStrategy
  },

  setRequiresLabelSetUpdates(state, value: boolean) {
    Vue.set(state, 'requiresLabelSetUpdates', value)
  },

  setlockBuildPlanIntervalId(state, value: number) {
    Vue.set(state, 'lockBuildPlanIntervalId', value)
  },

  setBuildPlanUpdatingState(state, isBuildPlanUpdating: boolean) {
    state.isBuildPlanUpdating = isBuildPlanUpdating
  },

  addPendingPartElevationRequest(state) {
    state.numberOfPendingPartElevationRequests += 1
  },

  removePendingPartElevationRequest(state) {
    if (state.numberOfPendingPartElevationRequests > 0) {
      state.numberOfPendingPartElevationRequests -= 1
    }
  },

  clearPartElevationRequests(state) {
    state.numberOfPendingPartElevationRequests = 0
  },

  setPrintOrderLabelSearch(state, input: string) {
    state.previewPrintOrder.labelSearchInput = input
  },

  setLabelToolPreparing(state, value: boolean) {
    Vue.set(state, 'isLabelToolPreparing', value)
  },

  setLabelRestoreInProgress(state, value: boolean) {
    Vue.set(state, 'isLabelRestoreInProgress', value)
  },

  addGeometryPropertiesToCache(
    state: IBuildPlansState,
    payload: {
      documentComponentsID: string
      geometryProperties: IMeshGeometryProperties
    },
  ) {
    state.geometryPropertiesCache.set(payload.documentComponentsID, payload.geometryProperties)
  },

  setActiveToolHasUnsavedData(state: IBuildPlansState, hasData: boolean) {
    state.activeToolHasUnsavedData = hasData
  },

  setIBCPlanNavigatedFrom(
    state: IBuildPlansState,
    from: {
      name: RouterNames
      params: {
        id?: string
        itemId?: string
      }
    },
  ) {
    state.editIbcPlan.navigatedFrom = from
  },

  setIBCPlan(state: IBuildPlansState, ibcPlan: IIBCPlan) {
    state.ibcPlan = ibcPlan
  },

  deselectIbcPlan(state: IBuildPlansState) {
    state.ibcPlan = null
    state.ibcPlanJobs = []
  },

  deleteMeasurements(state: IBuildPlansState, measurements: IbcMeasurementDeleteDto[]) {
    const ids = measurements.map((measurement) => measurement.measurementId)
    state.ibcPlan.measurements = state.ibcPlan.measurements.filter((measurement) => !ids.includes(measurement.id))
  },

  setIbcPlanItemMeasurement(state: IBuildPlansState, measurement: IMeasurementWithProperties) {
    const measurementIndex = state.ibcPlan.measurements.findIndex(
      (mes) => mes.ibcPlanItemMeasurementId === measurement.ibcPlanItemMeasurementId,
    )
    if (measurementIndex !== -1) {
      Vue.set(state.ibcPlan.measurements, measurementIndex, measurement)
    }
  },

  setIsToolMaskDisplaying(state: IBuildPlansState, value: boolean) {
    state.isToolMaskDisplaying = value
  },

  setLoadingItemsData(state, data: ILoadingItemsData) {
    state.loadingItemsData = data
  },

  setSupportToolPreparing(state, value: boolean) {
    Vue.set(state, 'isSupportToolPreparing', value)
  },

  setIsTransferToolBusy(state: IBuildPlansState, value: boolean) {
    Vue.set(state, 'isTransferToolBusy', value)
  },

  setSinterPlanVisibleWhenPublished(state: IBuildPlansState, value: boolean) {
    state.buildPlan.visibleWhenPublished = value
  },

  setIbcPlanVisibleWhenPublished(state: IBuildPlansState, value: boolean) {
    state.ibcPlan.visibleWhenPublished = value
  },
}
