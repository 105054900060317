
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/components/controls/Common/Button.vue'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import StoresNamespaces from '@/store/namespaces'
import { getItemTypeName } from '@/utils/fileExplorerItem/fileExplorerItemUtils'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: {
    Button,
  },
})
export default class RestoreWithRelatedModal extends Vue {
  @fileExplorerStore.Getter isLoading: boolean

  @Prop() onClose: () => void
  @Prop() onConfirm: () => Promise<void>
  @Prop() relatedItems: FileExplorerItem[]

  dialog: boolean = true

  get getSortedRelatedItemsLabels() {
    return this.relatedItems
      .sort((a, b) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
      .sort((a, b) => {
        return a.itemType - b.itemType
      })
      .map((item) => `${getItemTypeName(item)} ${item.name}`)
  }

  async onCancelClick() {
    this.dialog = false
    this.onClose()
  }

  async onDeleteClick() {
    await this.onConfirm()
    this.dialog = false
  }
}
