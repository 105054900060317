import '@/window'
import { IUser } from '@/types/User/IUser'
import userService from '@/api/users'
import { IMarketoUser } from '@/types/User/IMarketoUser'
import { PLATFORM_NAME } from '@/constants'

export default {
  async associateLead(user: IUser) {
    if (window.Munchkin) {
      const munchkinKey = await userService.getMunchkinKey()
      const marketoUser: IMarketoUser = {
        FirstName: user.firstName,
        LastName: user.lastName,
        Email: user.email,
        Company: user.company,
        Country: user.country,
        optIn: user.optIn,
        webAPISourceDetails: PLATFORM_NAME,
      }
      window.Munchkin.munchkinFunction('associateLead', marketoUser, munchkinKey)
    }
  },

  visitWebPage(params: string) {
    if (window.Munchkin) {
      const url = window.location.pathname
      window.Munchkin.munchkinFunction('visitWebPage', { url, params })
    }
  },
}
