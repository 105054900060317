import { GetterTree } from 'vuex'
import { IRootState } from '@/store/types'
import { IFileExplorerState } from './types'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { DEFAULT_ITEM_VERSION, FILE_EXPLORER_PATH_DELIMITER } from '@/constants'
import { ItemType } from '@/types/FileExplorer/ItemType'
import { Permission, ItemPermissionsRole, PermissionCollaborator } from '@/types/FileExplorer/Permission'
import { IUser } from '@/types/User/IUser'
import { JobStatusCode, IJob, JobType } from '@/types/PartsLibrary/Job'
import { orderBy } from '@/utils/array'
import { SortOrders } from '@/types/SortModes'
import { CanBeTrashedResult, CanBeTrashedStatus } from '@/types/FileExplorer/CanBeTrashed'
import { SearchResultItem } from '@/types/FileExplorer/SearchResultItem'

export const getters: GetterTree<IFileExplorerState, IRootState> = {
  getNumberOfHiddenItemsInCurrentFolder(state) {
    return state.numberOfHiddenItemsInCurrentFolder
  },

  getSelectedItems(state) {
    return state.selectedItems
  },

  getSelectedItem(state) {
    return state.selectedItems && state.selectedItems.length === 1
      ? state.selectedItems[0]
      : state.root
      ? state.root
      : null
  },

  getLastAddedItem(state) {
    return state.lastAddedItem
  },

  find:
    (state) =>
    (id: string | number): FileExplorerItem => {
      return state.items.byId[id]
    },

  list(state, allGetters) {
    return state.items.allIds.map((id) => allGetters.find(id))
  },

  getRootItem(state) {
    return state && state.root
  },

  rootContainsSelectedItem(state) {
    const rootId = state.root ? state.root.id : null
    return state.selectedItems.some((item) => item.parentId === rootId)
  },

  getItemsByParent:
    (state, allGetters) =>
    (parentId: string): FileExplorerItem[] => {
      return state.items.allIds
        .map<FileExplorerItem>((itemId) => allGetters.find(itemId))
        .filter((item) => {
          if (item.isRemoved) {
            return false
          }

          // {parentFolderId} is used when the item is one of the build/sinter plan variants
          // and the {parentId} contains ID of another variant (not a folder)
          return parentId !== item.id && (parentId === item.parentId || parentId === item.parentFolderId)
        })
    },

  isItemFavorite:
    (state) =>
    (itemId: string): boolean => {
      return state.items.favoriteIds.some((id) => id === itemId)
    },

  favoriteIdsSet(state): Set<string> {
    return new Set([...state.items.favoriteIds])
  },

  getItemsWithoutFolders(state, allGetters): FileExplorerItem[] {
    return state.items.allIds
      .map((id) => allGetters.find(id))
      .filter((item) => item.itemType !== ItemType.Folder && !item.isRemoved)
  },

  getItemsFromRoot(state, allGetters) {
    const rootId = state.root ? state.root.id : null
    return rootId ? allGetters.getItemsByParent(rootId) : allGetters.getRootItems
  },

  getRootItems(state, allGetters) {
    return state.items.allIds
      .map<FileExplorerItem>((id) => allGetters.find(id))
      .filter((item) => allGetters.isItemInAllFilesRoot(item))
  },

  getFolderItems(state, allGetters) {
    const rootId = state.root ? state.root.id : null
    const items = rootId ? allGetters.getItemsByParent(rootId) : allGetters.getRootItems
    return items.filter((item) => item.itemType === ItemType.Folder && !item.isRemoved)
  },

  getSharedByMeItems(state, allGetters): FileExplorerItem[] {
    return state.items.sharedIds.map((id) => allGetters.find(id)).filter((item) => item !== undefined)
  },

  getFavoriteItems(state): FileExplorerItem[] {
    return state.favorites.items
  },

  getRemovedItems(state): FileExplorerItem[] {
    return Object.values(state.items.byId).filter((item) => item.isRemoved)
  },

  getRecentItems(state, allGetters): FileExplorerItem[] {
    return state.items.recentIds
      .map((id) => allGetters.find(id))
      .filter((item: FileExplorerItem) => item && !item.isRemoved)
  },

  // get all permissions
  permissionsByItemId(state) {
    const initialValue: Record<string, Permission[]> = {}
    return Object.values(state.permissions.byId).reduce((map, permission: Permission) => {
      map[permission.itemId] = map[permission.itemId] || []
      map[permission.itemId].push(permission)
      if (permission.isInherited) {
        map[permission.inheritedFrom] = map[permission.inheritedFrom] || []
        map[permission.inheritedFrom].push(permission)
      }
      return map
    }, initialValue)
  },

  getDirectOrInheritedPermissionsByItemPath:
    (state, allGetters) =>
    (path: string): Permission[] => {
      const pathIds = path.split(FILE_EXPLORER_PATH_DELIMITER).slice(1).reverse()

      const allPermissions = []
      const userIds = new Set<string>()
      for (const pathId of pathIds) {
        const permissions: Permission[] = allGetters.permissionsByItemId[pathId]
        if (permissions && permissions.length) {
          for (const permission of permissions) {
            if (!userIds.has(permission.grantedTo)) {
              allPermissions.push(permission)
              userIds.add(permission.grantedTo)
            }
          }
        }
      }
      return allPermissions
    },

  getUserDirectOrParentPermissions:
    (state, allGetters) =>
    (userId: string, path: string, excludePathIds: string[] = []): Permission[] => {
      const pathIds = path.split(FILE_EXPLORER_PATH_DELIMITER).slice(1)

      const userPermissions = []
      for (const pathId of pathIds) {
        const permissions: Permission[] = allGetters.permissionsByItemId[pathId]
        if (permissions && permissions.length) {
          for (const permission of permissions) {
            if (permission.grantedTo === userId && !excludePathIds.includes(pathId)) {
              userPermissions.push(permission)
            }
          }
        }
      }
      return userPermissions
    },

  getRootRole: (state, allGetters, _, rootGetters) => () => {
    if (state.root === null) {
      return ItemPermissionsRole.Owner
    }

    const user: IUser = rootGetters['user/getUserDetails']
    const permissions: Permission[] = allGetters.permissionsByItemId[state.root.id] || []

    const userPermission = permissions.find((permission) => permission.grantedTo === user.id)

    if (userPermission) {
      return userPermission.role
    }

    return ItemPermissionsRole.Owner
  },

  getItemRole: (state, allGetters, _, rootGetters) => (itemId: string) => {
    const user: IUser = rootGetters['user/getUserDetails']
    const permissions: Permission[] = allGetters.permissionsByItemId[itemId] || []

    const userPermission = permissions.find((permission) => permission.grantedTo === user.id)

    if (userPermission) {
      return userPermission.role
    }

    return ItemPermissionsRole.Owner
  },

  getViewMode(state) {
    return state.viewMode
  },

  isLoading(state) {
    const { numberOfPendingRequests } = state.dataState
    return numberOfPendingRequests > 0
  },

  isError(state) {
    const { numberOfFailedRequests } = state.dataState
    return numberOfFailedRequests > 0
  },

  getErrorText(state) {
    return state.dataState.numberOfFailedRequests > 0 && state.dataState.failedMessages[0]
  },

  getDetailsRequestRaceConditionObj(state): object {
    return state.requestRaceConditionTokenObjects.detailsRequest
  },

  getIsMoveItems(state) {
    return state && state.isMoveItems
  },

  getClickedNameItem(state) {
    return state.clickedNameItem
  },

  hasRunningJob:
    (state) =>
    (itemId: string): boolean => {
      const jobs = state.jobs.byItemId[itemId]
      return jobs && jobs.some((job) => job.code === JobStatusCode.RUNNING || job.code === JobStatusCode.QUEUED)
    },

  hasRunningImportJob:
    (state) =>
    (itemId: string): boolean => {
      const jobs = state.jobs.byItemId[itemId]
      return (
        jobs &&
        jobs.some(
          (job) =>
            job.jobType === JobType.IMPORT && (job.code === JobStatusCode.RUNNING || job.code === JobStatusCode.QUEUED),
        )
      )
    },

  hasFailedJobs:
    (state) =>
    (itemId: string): boolean => {
      const jobs = state.jobs.byItemId[itemId]
      return jobs && jobs.some((job) => job.code === JobStatusCode.ERROR || job.code === JobStatusCode.CANCELLED)
    },

  getRunningJobsByItemId:
    (state) =>
    (id: string): IJob[] => {
      const itemJobs = state.jobs.byItemId[id]
      if (!itemJobs) {
        return []
      }

      return itemJobs.filter((job) => job.code === JobStatusCode.RUNNING || job.code === JobStatusCode.QUEUED)
    },

  getFailedJobsByItemId:
    (state) =>
    (id: string): IJob[] => {
      const itemJobs = state.jobs.byItemId[id]
      if (!itemJobs) {
        return []
      }

      const completedJobs = itemJobs.filter(
        (job) => job.code === JobStatusCode.COMPLETE || job.code === JobStatusCode.WARNING,
      )
      const failedJobs = itemJobs.filter(
        (job) =>
          job.code === JobStatusCode.ERROR ||
          job.code === JobStatusCode.CANCELLED ||
          job.code === JobStatusCode.CANCELLING,
      )
      const failedJobsByType = {}
      let resultJobs = []

      let failedJobTypes = failedJobs.map((job) => job.jobType)
      // leave only unique values in failedJobTypes
      failedJobTypes = [...new Set(failedJobTypes)]
      for (const failedJobType of failedJobTypes) {
        failedJobsByType[failedJobType] = []
      }
      // for each failed job check whether there is a later completed job of the same jobType
      // and if there is exclude this failed job from returned results
      if (completedJobs.length > 0 && failedJobs.length > 0) {
        for (const failedJob of failedJobs) {
          let completedJobsOfSameType = completedJobs.filter((job) => job.jobType === failedJob.jobType)
          if (completedJobsOfSameType.length > 0) {
            completedJobsOfSameType = orderBy(completedJobsOfSameType, ['updatedDate'], [SortOrders.Descending])
            if (failedJob.updatedDate > completedJobsOfSameType[0].updatedDate) {
              failedJobsByType[failedJob.jobType].push(failedJob)
            }
          } else {
            failedJobsByType[failedJob.jobType].push(failedJob)
          }
        }
        // also we only want to return the latest failed job for each jobType, if there are still several of them
        for (const failedJobType of failedJobTypes) {
          if (failedJobsByType[failedJobType].length > 0) {
            failedJobsByType[failedJobType] = orderBy(
              failedJobsByType[failedJobType],
              ['updatedDate'],
              [SortOrders.Descending],
            )
            resultJobs.push(failedJobsByType[failedJobType][0])
          }
        }
      } else {
        resultJobs = failedJobs
      }
      return resultJobs
    },

  getRenameDialogState(state): boolean {
    return state.isOpenRenameDialog
  },

  getMoveToTrashErrorDialogState(state): boolean {
    return state.isOpenMoveToTrashErrorDialog
  },

  getMoveToTrashItemDialogState(state): boolean {
    return state.isOpenMoveToTrashItemDialog
  },

  getSortParams(state) {
    return state.sortParams
  },

  isForceUpdateDetails(state): boolean {
    return state.isForceUpdateDetails
  },

  canManageCollaboration(state): boolean {
    return state.collaborationData.canManageCollaboration
  },

  getCollaborators(state): PermissionCollaborator[] {
    return state.collaborationData.collaborators
  },

  getSelectedItemsCanBeTrashed(state): CanBeTrashedResult {
    return state.canBeTrashedSelectedItemsResult
  },

  isCanBeTrashedStatusSuccess(state) {
    return (
      state.canBeTrashedSelectedItemsResult.permissionsResult.status === CanBeTrashedStatus.Success &&
      state.canBeTrashedSelectedItemsResult.relateToPrintOrdersResult.status === CanBeTrashedStatus.Success
    )
  },

  getItemDetails(state, allGetters, _, rootGetters) {
    const allUsers: IUser[] = rootGetters['user/getAllUsers']
    const activeUsers = allUsers.filter((user) => !user.isBlocked)

    return {
      ...state.itemDetails,
      collaborators: state.itemDetails.collaborators.filter((c) => activeUsers.some((user) => user.id === c.grantedTo)),
    }
  },

  getItemPermissionCollaboratorsById: (state) => (id: string) => {
    return state.itemPermissionCollaborators.byId[id]
  },

  getRelatedItemsToDelete(state): FileExplorerItem[] {
    return state.trashActionsInfo.relatedItemsToDelete
  },

  getHasDeletePermissions(state): boolean {
    return state.trashActionsInfo.hasDeletePermission
  },

  getRelatedItemsToRestore(state): FileExplorerItem[] {
    return state.trashActionsInfo.relatedItemsToRestore
  },

  getHasRestorePermissions(state): boolean {
    return state.trashActionsInfo.hasRestorePermission
  },

  isShownLockedBuildPlanModal(state): boolean {
    return state.isShownLockedBuildPlanModal
  },

  getLockItemInfo(state) {
    return state.lockItemInfo
  },

  getSearchResultItems(state): SearchResultItem[] {
    return state.search.items
  },

  getCurrentPageIndex(state): number {
    return state.paginationData.currentPage
  },

  getTotalPages(state): number {
    return state.paginationData.totalPages
  },

  getPageSize(state): number {
    return state.paginationData.size
  },

  getFilterParams(state) {
    return state.filterParams
  },

  getCollaboratorsToRemoveList(state) {
    return state.collaboratorsToRemoveList
  },

  isShownCreateBuildPlanDialog(state): boolean {
    return state.isShownCreateBuildPlanDialog
  },

  isShownCreateSinterPlanDialog(state): boolean {
    return state.isShownCreateSinterPlanDialog
  },

  isShownCreateFolderModal(state): boolean {
    return state.isShownCreateFolderModal
  },

  getNumberOfHiddenItemsInFavorites(state) {
    return state.numberOfHiddenItemsInFavorites
  },

  getNumberOfHiddenItemsInRecent(state) {
    return state.numberOfHiddenItemsInRecent
  },

  getItemsWithoutAccess(state) {
    return state.itemsWithoutAccess
  },

  isPrintOrderDetailsLoading(state) {
    return state.isPrintOrderDetailsLoading
  },

  getAmountOfNestedItemsWithoutPermissionsToView(state) {
    return state.collaborationData.amountOfNestedItemsWithoutPermissionsToView
  },

  getCollaboratorsGrantedParentPermissions(state) {
    return state.collaborationData.grantedParentPermissions
  },

  getSearchResultSnapshot(state) {
    return state.search.snapshot
  },

  // returns true if item should be shown in root of All files folder view
  isItemInAllFilesRoot:
    (state) =>
    (item: FileExplorerItem): boolean => {
      if (item.isRemoved) {
        return false
      }

      // check parentFolderId only if item is not default plan variant
      // otherwise it is always null
      if (
        (item.itemType !== ItemType.BuildPlan && item.itemType !== ItemType.IbcPlan) ||
        item.version === DEFAULT_ITEM_VERSION
      ) {
        return !item.parentId
      }
      return !item.parentFolderId
    },

  isAllowedToEndCollaboration(state) {
    return state.isAllowedToEndCollaboration
  },
}
