export default {
  set: (key: string | LocalStorageKeys, data: any) => {
    localStorage.setItem(key, JSON.stringify(data))
  },

  get: (key: string | LocalStorageKeys) => {
    return JSON.parse(localStorage.getItem(key))
  },
}

export enum LocalStorageKeys {
  CreateBuildPlanLastUsedValues = 'CreateBuildPlanLastUsedValues',
  CreateSinterPlanLastUsedValues = 'CreateSinterPlanLastUsedValues',
  AddPartToolSettings = 'AddPartToolSettings',
}
