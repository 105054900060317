import { Commit, Dispatch } from 'vuex'
import { Command } from '@/types/UndoRedo/Command'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import { IArrangeConstraints } from '../BuildPlans/IConstraints'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

const UPDATE_BUILD_PLAN_ACTION_NAME = 'buildPlans/updateBuildPlanV1'

export class ChangeArrangeConstraintsCommand extends Command implements ICommand {
  public readonly toolName = ToolNames.ARRANGE

  constructor(
    public readonly commandType: CommandType,
    private readonly repeatBuildPlanParams: IRepeatConstraintParams,
    private readonly buildPlan: IBuildPlan,
    protected dispatch: Dispatch,
    protected commit: Commit,
  ) {
    super()

    this.commandType = commandType
    this.repeatBuildPlanParams = repeatBuildPlanParams
    this.buildPlan = buildPlan
  }

  async undo(): Promise<void> {
    await this.applyUpdateBuildPlan(this.repeatBuildPlanParams.before)
  }

  async redo(): Promise<void> {
    await this.applyUpdateBuildPlan(this.repeatBuildPlanParams.after)
  }

  private async applyUpdateBuildPlan(constraints: IArrangeConstraints): Promise<void> {
    const buildPlan = this.buildPlan
    buildPlan.constraints = constraints

    await this.dispatch(UPDATE_BUILD_PLAN_ACTION_NAME, { buildPlan }, this.rootLevel)
  }
}

export interface IRepeatConstraintParams {
  before: IArrangeConstraints
  after: IArrangeConstraints
}
