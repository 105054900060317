import i18n from '@/plugins/i18n'

const MIN_RECOATER_CHART = 'recoater_min'
const MAX_RECOATER_CHART = 'recoater_max'
const ENHANCED_RECOATER_CHART = 'recoater_min_enhanced'
const DOSING_FACTOR_ADJUSTMENT_CHART = 'Dosing Factor Adjustment'
const MAX_ERROR_CHART = 'Max Error'
const MEAN_ERROR_CHART = 'Mean Error'
const LINE_PLOT_DATA = 'Line_Plot_Data'
const SURFACE_PLOT_DATA = 'Surface_Plot_Data'
const CONVERGENCE_VALUE = 'Convergence Value'
const OPTIMAL_ANGLES = 'optimal_angles'
const MM_FORMATTER = '{value} mm'
const DEFAULT_TOLERANCE = 2
export const OANGLE_SURFACE_LINE_WIDTH = 5
export const OANGLE_CONTOUR_LINE_WIDTH = 2
export const SCALE_RANGE_COEFFICIENT = 1.05

const maxForRecoaterHeight = (value) => {
  const maxIncrement = value.max * 0.1
  const max = maxIncrement > value.min ? value.max + value.min : value.max + maxIncrement
  return Math.ceil(max)
}

const recoaterHeightXAxisConfig = {
  name: 'Recoater Height',
  max: maxForRecoaterHeight,
  axisLabel: {
    formatter: MM_FORMATTER,
    showMaxLabel: true,
  },
}

const chartsDataMapping = {
  recoater_max: {
    name: 'Maximum Recoater Clearance',
    xAxisConfig: recoaterHeightXAxisConfig,
    yAxisConfig: {
      name: 'Clearance (%)',
    },
    max: Number.MIN_SAFE_INTEGER,
  },
  recoater_min: {
    name: 'Minimum Recoater Clearance',
    xAxisConfig: recoaterHeightXAxisConfig,
    yAxisConfig: {
      name: 'Clearance (%)',
    },
    max: Number.MIN_SAFE_INTEGER,
  },
  transient_part_displacement: {
    title: 'Normalized Transient Layer-wise Part Movement',
    name: 'Transient Part Displacement',
    xAxisConfig: recoaterHeightXAxisConfig,
    yAxisConfig: {
      name: 'Normalized part movement',
    },
    traceName: 'Total (xyz) layerwise part movement',
  },
  transient_part_temperature: {
    title: 'Transient Part Temperature (Before Recoating)',
    name: 'Transient Part Temperature',
    xAxisConfig: recoaterHeightXAxisConfig,
    yAxisConfig: {
      name: 'Temperature',
      axisLabel: {
        formatter: '{value} °C',
      },
    },
    traceName: 'Maximum transient temperature',
    tolerance: 1,
  },
  'Max Error': {
    title: 'Maximum Predicted Error (mm)',
    name: 'Maximum Error',
    xAxisConfig: {
      name: 'Iteration Number',
      interval: 1,
    },
    yAxisConfig: {
      name: 'Error (mm)',
    },
    traceName: 'Error max per iteration',
    addConvergenceValue: true,
    tolerance: 3,
  },
  'Mean Error': {
    title: 'Mean Predicted Error (mm)',
    name: 'Mean Error',
    xAxisConfig: {
      name: 'Iteration Number',
      interval: 1,
    },
    yAxisConfig: {
      name: 'Error (mm)',
    },
    traceName: 'Error mean per iteration',
    addConvergenceValue: true,
    tolerance: 3,
  },
  dosing_factor: {
    title: 'Dosing Factor % Adjustment',
    name: 'Dosing Factor Adjustment Auto',
    xAxisConfig: {
      name: 'Build Height (mm)',
      max: maxForRecoaterHeight,
      axisLabel: {
        formatter: MM_FORMATTER,
        showMaxLabel: true,
      },
    },
    yAxisConfig: {
      name: 'Dosing Factor Adjustment (%)',
    },
    traceName: 'Transient volume change',
  },
  'Dosing Factor Adjustment': {
    title: 'Dosing Factor % Adjustment',
    name: 'Dosing Factor Adjustment',
    xAxisConfig: {
      name: 'Build Height (mm)',
      max: maxForRecoaterHeight,
      axisLabel: {
        formatter: MM_FORMATTER,
        showMaxLabel: true,
      },
    },
    yAxisConfig: {
      name: 'Dosing Factor Adjustment (%)',
    },
    traceName: 'Transient volume change',
  },
  Recoater_Orientation_Optimization1D: {
    title: 'Min Recoater Clearance versus In Plane Orientation',
    name: 'Recoater Orientation Optimization',
    xAxisConfig: {
      name: 'Recoater Angle Change',
      max: 'dataMax',
      interval: 30,
      axisLabel: {
        formatter: '{value}°',
      },
    },
    yAxisConfig: {
      name: 'Min Optimization Clearance',
    },
    traceName: 'Min Recoater Clearance',
    max: Number.MIN_SAFE_INTEGER,
    addOptimalAngles: true,
  },
}

const surfaceChartsDataMapping = {
  Recoater_Orientation_OptimizationContour: {
    title: 'Part Recoater Clearance as function of Recoater Direction',
    name: 'Recoater Orientation Optimization Contour',
    xAxisConfig: {
      name: 'Build Height (mm)',
      type: 'value',
      nameGap: 20,
    },
    yAxisConfig: {
      name: 'Recoater Angle Change (deg)',
      type: 'value',
      nameGap: 20,
      max: 'dataMax',
      min: 'dataMin',
      interval: 30,
    },
    zAxisConfig: {
      name: 'Optimization Clearance',
      type: 'value',
      scale: 'true',
    },
    traceName: 'Recoater Orientation',
    colorscale: ['rgb(165,0,38)', 'rgb(245,119,72)', 'rgb(244,250,176)', 'rgb(120,197,101)', 'rgb(0,103,54)'],
    max: Number.MIN_SAFE_INTEGER,
    min: 0,
    addOptimalAngles: true,
  },
}

export class ChartController {
  private convergenceValue: number = 0
  private optimalAngles: number[]

  adjustChartsData(rawData) {
    if (rawData[CONVERGENCE_VALUE]) {
      this.convergenceValue = rawData[CONVERGENCE_VALUE]
    }

    if (rawData[OPTIMAL_ANGLES]) {
      let angles = rawData[OPTIMAL_ANGLES]
      while (Array.isArray(angles) && Array.isArray(angles[0])) {
        angles = angles[0]
      }

      this.optimalAngles = angles
    } else {
      this.optimalAngles = []
    }

    const result = this.getLineChartsData(rawData[LINE_PLOT_DATA] || rawData)
    // Temporary disable surface plots due to security issues. 
    // For more details please see https://jira-ebm.additive.ge.com/browse/YPVJZ-25534
    // if (rawData[SURFACE_PLOT_DATA]) {
    //   result.push(...this.getSurfaceChartsData(rawData[SURFACE_PLOT_DATA]))
    // }

    return result
  }

  get convergence(): number {
    return this.convergenceValue
  }

  private get convergenceTolerance() {
    const exponent = this.convergenceValue.toExponential()
    return Math.abs(+exponent.split(/[eE]/)[1]) + 1
  }

  private getLineChartsData(rawData) {
    let lineChartsData = rawData
    if (Array.isArray(lineChartsData)) {
      lineChartsData = lineChartsData[0]
    }

    const enhancedRecoaterData = lineChartsData[ENHANCED_RECOATER_CHART]
    if (lineChartsData[MIN_RECOATER_CHART] && enhancedRecoaterData) {
      lineChartsData[MIN_RECOATER_CHART].push(...enhancedRecoaterData)
      delete lineChartsData[ENHANCED_RECOATER_CHART]
    }

    const dosingFactor = lineChartsData[DOSING_FACTOR_ADJUSTMENT_CHART]
    if (dosingFactor) {
      for (const trace of dosingFactor) {
        if (!trace.x && !trace.y) {
          const xData = trace[chartsDataMapping[DOSING_FACTOR_ADJUSTMENT_CHART].xAxisConfig.name]
          const yData = trace[chartsDataMapping[DOSING_FACTOR_ADJUSTMENT_CHART].yAxisConfig.name]
          trace.x = xData
          trace.y = yData
          delete trace[chartsDataMapping[DOSING_FACTOR_ADJUSTMENT_CHART].xAxisConfig.name]
          delete trace[chartsDataMapping[DOSING_FACTOR_ADJUSTMENT_CHART].yAxisConfig.name]
        }
      }
    }

    return Object.keys(lineChartsData)
      .filter((k) => lineChartsData[k] && chartsDataMapping[k] && this.lineChartDataIsValid(lineChartsData[k]))
      .map((key) => {
        const arrayData = Array.isArray(lineChartsData[key])
        if ((key === MAX_ERROR_CHART || key === MEAN_ERROR_CHART) && arrayData) {
          lineChartsData[key] = lineChartsData[key].slice(-1)
        }

        chartsDataMapping[key].type = ChartType.Line
        chartsDataMapping[key].tolerance = chartsDataMapping[key].tolerance || DEFAULT_TOLERANCE
        if (arrayData) {
          chartsDataMapping[key].chartData = lineChartsData[key].map((d) => {
            this.trySetMaxValue(chartsDataMapping[key], d.y)

            return {
              name:
                key === MIN_RECOATER_CHART || key === MAX_RECOATER_CHART ? d.iter : chartsDataMapping[key].traceName,
              data: d.x.map((item, i) => [item, d.y[i]]),
              type: 'line',
              animation: false,
            }
          })
        } else {
          this.trySetMaxValue(chartsDataMapping[key], lineChartsData[key].y)

          chartsDataMapping[key].chartData = [
            {
              name: chartsDataMapping[key].traceName,
              data: lineChartsData[key].x.map((item, i) => [item, lineChartsData[key].y[i]]),
              type: 'line',
              animation: false,
            },
          ]
        }

        if (chartsDataMapping[key].addConvergenceValue) {
          if (this.convergence < 1) {
            const convTolerance = this.convergenceTolerance
            if (chartsDataMapping[key].tolerance < convTolerance) {
              chartsDataMapping[key].tolerance = convTolerance
            }
          }

          this.addConvergenceValue(chartsDataMapping[key])
        }

        if (chartsDataMapping[key].addOptimalAngles) {
          this.addOptimalAngles(chartsDataMapping[key])
        }

        return chartsDataMapping[key]
      })
  }

  private getSurfaceChartsData(rawData) {
    let surfaceChartsData = rawData
    if (Array.isArray(surfaceChartsData)) {
      surfaceChartsData = surfaceChartsData[0]
    }

    return Object.keys(surfaceChartsData)
      .filter(
        (k) =>
          surfaceChartsData[k] && surfaceChartsDataMapping[k] && this.surfaceChartDataIsValid(surfaceChartsData[k]),
      )
      .map((key) => {
        this.trySetMaxValue(surfaceChartsDataMapping[key], surfaceChartsData[key].z)
        this.trySetMinValue(surfaceChartsDataMapping[key], surfaceChartsData[key].z)

        const data = []
        const rData = surfaceChartsData[key]
        for (let i = 0; i < rData.y.length; i += 1) {
          for (let j = 0; j < rData.x.length; j += 1) {
            // As there are no settings for 3D charts tooltip formatting
            // as a temporary solution we are rounding the data
            data.push([+rData.x[j].toFixed(DEFAULT_TOLERANCE), rData.y[i], +rData.z[i][j].toFixed(DEFAULT_TOLERANCE)])
          }
        }

        surfaceChartsDataMapping[key].type = ChartType.Surface
        surfaceChartsDataMapping[key].chartData = [
          {
            data,
            name: surfaceChartsDataMapping[key].traceName,
            type: 'surface',
            shading: 'color',
          },
        ]

        if (surfaceChartsDataMapping[key].colorscale) {
          surfaceChartsDataMapping[key].chartData[0].colorscale = surfaceChartsDataMapping[key].colorscale
        }

        if (surfaceChartsDataMapping[key].addOptimalAngles) {
          this.addOptimalAnglesSurface(surfaceChartsDataMapping[key], surfaceChartsData[key])
        }

        return surfaceChartsDataMapping[key]
      })
  }

  private addConvergenceValue(chart) {
    const data: number[] = chart.chartData[0].data
    const yValues = data.map((row) => row[1])
    const xValues = data.map((row) => row[0])
    let yMax = yValues.reduce((a, b) => Math.max(a, b))
    yMax =
      yMax > this.convergenceValue
        ? +(yMax * SCALE_RANGE_COEFFICIENT).toFixed(2)
        : +(this.convergenceValue * SCALE_RANGE_COEFFICIENT).toFixed(2)

    chart.yAxisConfig.max = yMax
    chart.yAxisConfig.min = 0
    chart.yAxisConfig.axisLine = {
      onZero: false,
    }
    chart.xAxisConfig.min = -1
    chart.xAxisConfig.max = xValues[xValues.length - 1] + 1
    chart.xAxisConfig.axisLabel = {
      showMinLabel: false,
      showMaxLabel: false,
    }

    chart.chartData.push({
      type: 'line',
      name: i18n.t('convergenceValue'),
      markLine: {
        precision: chart.tolerance,
        symbol: 'circle',
        data: [{ yAxis: this.convergenceValue }],
        label: {
          position: 'insideStartTop',
          formatter: i18n.t('convergenceValue'),
        },
      },
      animation: false,
    })
  }

  private addOptimalAngles(chart) {
    this.optimalAngles.forEach((angle) => {
      chart.chartData.push({
        type: 'line',
        name: i18n.t('optimalAngle'),
        markLine: {
          symbol: 'circle',
          data: [{ xAxis: angle }],
          label: {
            formatter: i18n.t('optimalAngle'),
          },
        },
        animation: false,
      })
    })
  }

  private addOptimalAnglesSurface(chart, rawChartData) {
    let added = 0
    this.optimalAngles.forEach((angle) => {
      const angleIndex = rawChartData.y.findIndex((v) => v === angle)
      if (angleIndex !== -1) {
        const data = rawChartData.x.map((value, i) => [
          +value.toFixed(DEFAULT_TOLERANCE),
          angle,
          +rawChartData.z[angleIndex][i].toFixed(DEFAULT_TOLERANCE),
        ])
        chart.chartData.push({
          data,
          name: i18n.t('optimalAngle'),
          type: 'line3D',
          lineStyle: {
            width: 3,
            color: '#005eb8',
          },
        })
        added += 1
      }
    })

    chart.optimalAnglesAdded = added
  }

  private trySetMaxValue(targetChart, array: number[]) {
    if (targetChart.hasOwnProperty('max')) {
      targetChart.max = array.flat().reduce((x, y) => Math.max(x, y))
    }
  }

  private trySetMinValue(targetChart, array: number[]) {
    if (targetChart.hasOwnProperty('min')) {
      targetChart.min = array.flat().reduce((x, y) => Math.min(x, y))
    }
  }

  private lineChartDataIsValid(data) {
    if (Array.isArray(data)) {
      return data.length && data.find((e) => !this.lineChartEntryIsValid(e)) === undefined
    }

    return this.lineChartEntryIsValid(data)
  }

  private surfaceChartDataIsValid(data) {
    if (Array.isArray(data)) {
      return data.length && data.find((e) => !this.surfaceChartEntryIsValid(e)) === undefined
    }

    return this.surfaceChartEntryIsValid(data)
  }

  private lineChartEntryIsValid(data): boolean {
    return (
      data.hasOwnProperty('x') &&
      data.hasOwnProperty('y') &&
      Array.isArray(data.x) &&
      Array.isArray(data.y) &&
      data.x.length &&
      data.y.length
    )
  }

  private surfaceChartEntryIsValid(data): boolean {
    return this.lineChartEntryIsValid(data) && data.hasOwnProperty('z') && Array.isArray(data.z) && data.z.length
  }
}

export const xAxisConfig = {
  nameLocation: 'center',
  nameGap: 30,
  scale: true,
  type: 'value',
}

export const yAxisConfig = {
  nameLocation: 'center',
  nameGap: 50,
  scale: true,
  boundaryGap: ['10%', '10%'],
}

export enum ChartType {
  Line,
  Surface,
}

export enum ChartView {
  Contour = 'contour',
  Surface = 'surface',
}
