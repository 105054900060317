import { render, staticRenderFns } from "./EditBuildPlan.vue?vue&type=template&id=cc588da4&scoped=true"
import script from "./EditBuildPlan.vue?vue&type=script&lang=ts"
export * from "./EditBuildPlan.vue?vue&type=script&lang=ts"
import style0 from "./EditBuildPlan.vue?vue&type=style&index=0&id=cc588da4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc588da4",
  null
  
)

export default component.exports