
import Vue from 'vue'
import { namespace } from 'vuex-class'
import { GChart } from 'vue-google-charts'
import Component from 'vue-class-component'
import html2canvas from 'html2canvas'

import StoresNamespaces from '@/store/namespaces'
import { RouterPaths, RouterNames } from '@/router'
import {
  ICostEstimation,
  ICostEstimationSet,
  IResult,
  CostEvaluationResultViewModel,
} from '@/types/CostEstimations/ICostEstimation'

import FallbackImage from '@/components/layout/FallbackImage.vue'
import CostEstimationSidePanel from '@/components/layout/CostEstimationSidePanel.vue'
import Button from '@/components/controls/Common/Button.vue'
import IconButton from '@/components/controls/Common/IconButton.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'

import SingleMachineResult from './results/SingleMachineResult.vue'
import TwoMachinesResult from './results/TwoMachinesResult.vue'
import ThreeMachinesResult from './results/ThreeMachinesResult.vue'
import TwoMachinesBar from './results/TwoMachinesBar.vue'
import ThreeMachinesBar from './results/ThreeMachinesBar.vue'
import { measurementUnits, DEFAULT_UNITS_ID } from '@/constants'
import { CostEstimationChartColors } from '@/types/CostEstimations/CostEstimationChartColors'
import { CostEvaluationResultAdapterFactory } from './adapters'
import munchkin from '@/utils/marketo/munchkin'
import { isAdBlockDetected } from '@/utils/adBlockDetection'
import round from '@/utils/arithmetic/round'
import messageService from '@/services/messageService'

const DEFAULT_HEADER_HEIGHT = 120 // header without machines bar
const EXTENDED_HEADER_HEIGHT = 190 // header with machine image and title
const DEFAULT_RESULT_PADDINGS = 332 // default result container
const EXTENDED_RESULT_PADDINGS = 402 // result container paddings with extended header

enum MachineResultType {
  SingleMachineResult = 'SingleMachineResult',
  TwoMachinesResult = 'TwoMachinesResult',
  ThreeMachinesResult = 'ThreeMachinesResult',
}

enum MachineBarType {
  TwoMachinesBar = 'TwoMachinesBar',
  ThreeMachinesBar = 'ThreeMachinesBar',
}

const costEstimationStore = namespace(StoresNamespaces.CostEstimation)
@Component({
  components: {
    IconButton,
    Button,
    GChart,
    FallbackImage,
    CostEstimationSidePanel,
    SingleMachineResult,
    TwoMachinesResult,
    ThreeMachinesResult,
    TwoMachinesBar,
    ThreeMachinesBar,
    ConfirmModal,
  },
})
export default class CostEstimation extends Vue {
  @costEstimationStore.Action fetchAllCostEstimations: any
  @costEstimationStore.Action fetchMaterials: any
  @costEstimationStore.Action fetchParameterSets: any
  @costEstimationStore.Action fetchMachineConfigs: any
  @costEstimationStore.Action fetchRegions: any
  @costEstimationStore.Action getMaterialById: any
  @costEstimationStore.Action getMachineConfigById: any
  @costEstimationStore.Action getCostEstimationById: any
  @costEstimationStore.Action createCostEstimation: any
  @costEstimationStore.Action deleteCostEstimation: any
  @costEstimationStore.Action cloneCostEstimation: any

  @costEstimationStore.Getter getAllCostEstimations: any
  @costEstimationStore.Getter getDefaultCostEstimation: any
  @costEstimationStore.Getter getDefaultRegion: any

  costEstimation: ICostEstimation = null
  estimationResults: IResult[] = null
  materialName: string = ''
  unitsName = ''
  costEstimationsList = null
  isExportingToPdf: boolean = false
  isAdBlockDetected = false

  chartData = []
  chartColors: string[] = [
    CostEstimationChartColors.MaterialCost,
    CostEstimationChartColors.Maintenance,
    CostEstimationChartColors.Machine,
    CostEstimationChartColors.Consumables,
  ]
  chartOptions = {
    pieHole: 0.6,
    colors: this.chartColors,
    pieSliceText: 'none',
    tooltip: {
      isHtml: true,
      ignoreBounds: false,
      showColorCode: true,
      trigger: 'both',
      textStyle: {
        fontSize: 14,
      },
    },
    width: '100%',
    height: 200,
    legend: 'none',
    chartArea: { left: 10, top: 30, right: 10, bottom: 30 },
  }
  chartColorScheme = [
    { name: 'materialCost', color: CostEstimationChartColors.MaterialCost },
    { name: 'maintenance', color: CostEstimationChartColors.Maintenance },
    { name: 'machine', color: CostEstimationChartColors.Machine },
    { name: 'consumables', color: CostEstimationChartColors.Consumables },
  ]

  $refs!: {
    confirm: InstanceType<typeof ConfirmModal>
  }

  async mounted() {
    await this.detectAdBlock()
  }

  async beforeMount() {
    const id = parseInt(this.$route.params.costEstimationId, 10)
    await this.getPageReady(id)

    // log page visit with the necessary parameters into Marketo
    const material = this.materialName
    const selectedMachines = this.estimationResults.map((result) => result.machineName).join(', ')
    const volume = `${this.costEstimation.volume} ${this.unitsName}\u00B3`
    const surfaceArea = `${this.costEstimation.surfaceArea} ${this.unitsName}\u00B2`
    const marketoParams = JSON.stringify({
      Material: material,
      'Number of parts per year': this.costEstimation.numberOfPartsPerYear,
      Volume: volume,
      'Surface area': surfaceArea,
      Machines: selectedMachines,
    })
    munchkin.visitWebPage(marketoParams)
  }

  async detectAdBlock() {
    this.isAdBlockDetected = await isAdBlockDetected()
  }

  async getPageReady(id) {
    try {
      await this.fetchAllCostEstimations()
      this.costEstimationsList = this.getAllCostEstimations
      this.costEstimation = await this.getCostEstimationById(id)

      if (!this.costEstimation) {
        throw new Error('Cost Estimation does not exist!')
      }

      const material = await this.getMaterialById(this.costEstimation.materialId)
      const units = measurementUnits.find((item) => item.id === this.costEstimation.unitsId)

      this.materialName = material ? material.name : ''
      this.unitsName = units ? units.alias : ''

      this.estimationResults = await this.getCostEstimationResults()

      // Some fields may come from server with null/undefined values that breaks further code.
      // All of corrupted values should be zeroed
      this.estimationResults.forEach((estimation, index) => {
        if (estimation.result) {
          Object.keys(estimation.result).map((key) => {
            if (!this.estimationResults[index].result[key]) {
              this.estimationResults[index].result[key] = 0
            }
          })
        }
      })

      this.chartData = this.estimationResults.map((res) => {
        const { complexMaterialCostAverage, maintenanceAverage, printerAverage, consumablesAverage } = res.result
        return [
          ['', ''], // required header
          [this.upperFirstLetters(this.$root.$t('materialCost')), round(complexMaterialCostAverage, 0)],
          [this.upperFirstLetters(this.$root.$t('maintenance')), round(maintenanceAverage, 0)],
          [this.upperFirstLetters(this.$root.$t('machine')), round(printerAverage, 0)],
          [this.upperFirstLetters(this.$root.$t('consumables')), round(consumablesAverage, 0)],
        ]
      })
    } catch (error) {
      console.error(`Error while receiving Cost Estimation: ${error}`)
      // @ts-ignore
      this.$router.safePush(RouterPaths.ManageCostEstimations)
      return
    }
  }

  async getCostEstimationResults() {
    return await Promise.all(this.costEstimation.costEstimationSet.map((s) => this.createResult(s)))
  }

  async createResult(costEstimationSet: ICostEstimationSet): Promise<IResult> {
    const machineConfig = await this.getMachineConfigById(costEstimationSet.machineConfigId)
    const resultAdapter = CostEvaluationResultAdapterFactory.getAdapter(machineConfig.printingType)
    let estimResult = new CostEvaluationResultViewModel()

    if (costEstimationSet.result) {
      try {
        estimResult = resultAdapter.adapt(costEstimationSet.result)
      } catch (error) {
        const message = `${this.$i18n.t('costEstimationOutdated')} ${this.$i18n.t('costEstimationOutdatedPrompt')}`
        messageService.showErrorMessage(message)
      }
    }

    return {
      machineName: machineConfig.name,
      machineImageUrl: machineConfig.imageUrl,
      result: estimResult,
    }
  }

  get headerHeightStyle() {
    const headerHeight =
      this.estimationResults && this.estimationResults.length > 1 ? EXTENDED_HEADER_HEIGHT : DEFAULT_HEADER_HEIGHT
    return `height: ${headerHeight}px`
  }

  get resultHeightStyle() {
    const resultPaddings =
      this.estimationResults && this.estimationResults.length > 1 ? EXTENDED_RESULT_PADDINGS : DEFAULT_RESULT_PADDINGS
    return `height: calc(100vh - ${resultPaddings}px)`
  }

  get machineResultComponent() {
    if (!this.estimationResults) {
      return
    }
    switch (this.estimationResults.length) {
      case 1:
        return MachineResultType.SingleMachineResult

      case 2:
        return MachineResultType.TwoMachinesResult

      default:
        return MachineResultType.ThreeMachinesResult
    }
  }

  get machineBarComponent() {
    if (!this.estimationResults) {
      return
    }
    switch (this.estimationResults.length) {
      case 3:
        return MachineBarType.ThreeMachinesBar

      case 2:
        return MachineBarType.TwoMachinesBar

      default:
        return
    }
  }

  upperFirstLetters(str) {
    return str
      .split(' ')
      .map((w) => w.charAt(0).toUpperCase() + w.substring(1))
      .join(' ')
  }

  async onExportPdfClick() {
    this.isExportingToPdf = true
    await this.detectAdBlock()

    const costEstimationEl = document.getElementById('cost-estimation-results')
    const machinesSectionEl = document.getElementById('machines-section')
    const brandingEl = document.getElementById('ce-branding')

    const costEstimationWidth = costEstimationEl.offsetWidth
    const costEstimationHeight = costEstimationEl.offsetHeight

    // 841 units - standard A4 page height
    // 595 - standart A4 page width
    // 80 - empty space at right to fit the image correctly
    const pageWidth = 595 - 80
    const pageHeight = 841 - 80
    const title = `${this.costEstimation.projectName} ${this.upperFirstLetters(this.$t('results'))}`

    let brandingImageDefinition
    let machinesImageDefinition
    let resultsImageDefinition

    const brandingWidth = brandingEl.offsetWidth
    // header height (defined in styles section, current: 56px)
    // + default margin bottom (defined in next onclone func, current: 20px)
    const brandingHeight = 76

    const brandingImageDefinitionPromise = await html2canvas(brandingEl, {
      width: brandingWidth,
      height: brandingHeight,
      scale: 2,
      allowTaint: false,
      useCORS: true,
      onclone(document) {
        // unhidding branding header
        document.getElementById('ce-branding-content').style.display = 'flex'
        document.getElementById('ce-branding-content').style.marginBottom = '20px'
      },
    })
      .then((canvas) => {
        const dataUrl = canvas.toDataURL('image/png')
        return (brandingImageDefinition = {
          image: dataUrl,
          fit: [pageWidth, pageHeight],
        })
      })
      .catch((err) => {
        this.isExportingToPdf = false
        throw err
      })

    brandingImageDefinition = await Promise.resolve(brandingImageDefinitionPromise)

    const docDefinition = {
      pageOrientation: 'portrait',
      info: {
        title: `${title}`,
      },
      content: [brandingImageDefinition],
    }

    if (machinesSectionEl) {
      const machinesSectionWidth = machinesSectionEl.offsetWidth
      const machinesSectionHeight = machinesSectionEl.offsetHeight

      const machinesImageDefinitionPromise = await html2canvas(machinesSectionEl, {
        width: machinesSectionWidth,
        height: machinesSectionHeight,
        scale: 2,
        allowTaint: false,
        useCORS: true,
        onclone(document) {
          // setting additional left padding to add empty space instead of tooltip icon
          document.querySelectorAll('.pdf-add-padding-35').forEach((el: HTMLElement) => (el.style.paddingLeft = '35px'))
        },
      })
        .then((canvas) => {
          const dataUrl = canvas.toDataURL('image/png')
          return (machinesImageDefinition = {
            image: dataUrl,
            fit: [pageWidth, pageHeight],
          })
        })
        .catch((err) => {
          this.isExportingToPdf = false
          throw err
        })

      machinesImageDefinition = await Promise.resolve(machinesImageDefinitionPromise)
      docDefinition.content.push(machinesImageDefinition)
    }

    html2canvas(costEstimationEl, {
      width: costEstimationWidth,
      height: costEstimationHeight,
      scale: 2,
      allowTaint: false,
      useCORS: true,
      onclone(document) {
        // setting additional left padding to add empty space instead of tooltip icon
        document.querySelectorAll('.pdf-add-padding-25').forEach((el: HTMLElement) => (el.style.paddingLeft = '25px'))
        // setting additional left padding for pie chart legend
        document.querySelectorAll('.pdf-add-padding-20').forEach((el: HTMLElement) => (el.style.paddingLeft = '20px'))
      },
    })
      .then((canvas) => {
        const dataUrl = canvas.toDataURL('image/png')
        resultsImageDefinition = {
          image: dataUrl,
          fit: [pageWidth, pageHeight],
        }

        docDefinition.content.push(resultsImageDefinition)

        this.isExportingToPdf = false

        const pdfmake = import(/* webpackChunkName: "pdfMake" */ 'pdfmake/build/pdfmake')
        const vfs = import(/* webpackChunkName: "pdfMake" */ 'pdfmake/build/vfs_fonts')
        Promise.all([pdfmake, vfs]).then((modules) => {
          modules[0].vfs = modules[1].pdfMake.vfs

          if (this.isAdBlockDetected) {
            modules[0].createPdf(docDefinition).download(title)
          } else {
            modules[0].createPdf(docDefinition).open()
          }
        })
      })
      .catch((err) => {
        this.isExportingToPdf = false
        throw err
      })
  }

  onCostStudiesClick() {
    // @ts-ignore
    this.$router.safePush(RouterPaths.ManageCostEstimations)
  }

  async onPreviousClick() {
    if (this.costEstimationsList.length <= 1) {
      return
    }

    const list = this.costEstimationsList
    const currentId = +this.$route.params.costEstimationId
    const currentIndex = list.findIndex((item) => item.id === currentId)

    const { id: nexId } = currentIndex !== 0 ? list[currentIndex - 1] : list[list.length - 1]

    // @ts-ignore
    this.$router.safePush({ path: `${nexId}` })
    await this.getPageReady(nexId)
  }

  async onNextClick() {
    if (this.costEstimationsList.length <= 1) {
      return
    }

    const list = this.costEstimationsList
    const currentId = +this.$route.params.costEstimationId
    const currentIndex = list.findIndex((item) => item.id === currentId)

    const { id: nexId } = currentIndex !== list.length - 1 ? list[currentIndex + 1] : list[0]

    // @ts-ignore
    this.$router.safePush({ path: `${nexId}` })
    await this.getPageReady(nexId)
  }

  onEditClick() {
    const costEstimation = {
      name: RouterNames.EditCostEstimation,
      params: {
        costEstimationId: `${this.costEstimation.id}`,
      },
    }
    // @ts-ignore
    this.$router.safePush(costEstimation)
  }

  async onCopyClick() {
    this.cloneCostEstimation(this.costEstimation.id)
    // @ts-ignore
    this.$router.safePush(RouterPaths.ManageCostEstimations)
  }

  async onDeleteClick() {
    const modalTitle = this.$i18n.t('costEstimationDeleteModalTitle')
    const modalMessage = `<span class="bold-font">${this.costEstimation.projectName}</span> ${this.$i18n.t(
      'willBeDeleted',
    )}`

    const shouldDelete = await this.$refs.confirm.open(modalTitle, modalMessage)

    if (shouldDelete) {
      try {
        await this.deleteCostEstimation(this.costEstimation.id)
        this.onCostStudiesClick()
      } catch (error) {
        console.error(`Error while deleting Cost Estimation: ${error}`)
      }
    }
  }

  async onCreateNewClick() {
    try {
      const materials = this.fetchMaterials()
      const params = this.fetchParameterSets()
      const machineConfigs = this.fetchMachineConfigs()
      const regions = this.fetchRegions()
      await Promise.all([materials, params, machineConfigs, regions])
      const estimation: ICostEstimation = this.getDefaultCostEstimation

      const region = this.getDefaultRegion

      estimation.materialId = null
      estimation.manufacturingRegion = region
      estimation.unitsId = DEFAULT_UNITS_ID

      const newEstimation = await this.createCostEstimation(estimation)
      const routerObj = {
        name: RouterNames.EditCostEstimation,
        params: {
          costEstimationId: newEstimation.id,
        },
      }
      // @ts-ignore
      this.$router.safePush(routerObj)
    } catch (error) {
      console.error(`Error while creating Cost Estimation: ${error}`)
    }
  }

  get costStudiesButtonLabel() {
    if (!this.costEstimationsList) {
      return ''
    }

    const currentId = parseInt(this.$route.params.costEstimationId, 10)
    const currentIndex = this.costEstimationsList.findIndex((item) => item.id === currentId)
    const amount = this.costEstimationsList.length

    return `(${currentIndex + 1} ${this.$t('of')} ${amount})`
  }

  renderCurrencyRange(min: number = 0, max: number = 0): string {
    let str = ''
    const strMin = min ? this.numberToCurrency(round(min, 0)) : ''
    const strMax = max ? this.numberToCurrency(round(max, 0)) : ''

    if (strMin && strMax && strMin !== strMax) {
      str = `${strMin} - ${strMax}`
    } else {
      str = strMin || strMax || '-'
    }

    return str
  }

  numberToCurrency(numStr: string): string {
    return this.$options.filters.currency(numStr)
  }
}
