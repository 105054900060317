import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { IBuildPlansState } from './types'
import { IRootState } from '@/store/types'
import { ViewModes } from '@/constants'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { SelectionUnit, IMeshGeometryProperties } from '@/types/BuildPlans/IBuildPlan'
import { ContentViewModeTypes } from '@/visualization/types/ContentViewMode'
import { InsightGroups } from '@/types/BuildPlans/IBuildPlanInsight'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'

export const state: IBuildPlansState = {
  buildPlans: [],
  buildPlanVariants: [],
  buildPlan: null,
  selectedViewMode: ViewModes.Grid,
  selectedBuildPlanViewMode: ViewModeTypes.Layout,
  contentViewMode: ContentViewModeTypes.Layout,
  machineConfigs: [],
  materials: [],
  productionSets: [],
  printStrategyParameterSets: [],
  activePrintStrategies: [],
  printStrategies: [],
  buildPlanPrintStrategy: null,
  loadingParts: [],
  selectedItems: [],
  selectedPartsCollisions: [],
  selectedLabel: null,
  isLabelInstancing: false,
  isLoading: false,
  isLayersLoading: false,
  isReadOnly: false,
  selectedBuildPlanJobs: [],
  selectionMode: SelectionUnit.Part,
  printSites: [],
  buildPlates: [],
  buildPlateMaterials: [],
  buildPlateMachineConfigs: [],
  insights: [],
  pendingInsights: [],
  calcCostInProgress: false,
  addPartTool: {
    selectedParts: [],
    selectedPartProperties: {},
    selectedPartsScale: {},
    outgoingPartProperties: {},
    geometryProperties: null,
  },
  crossSectionMatrix: [],
  insightsSettings: {
    groupBy: InsightGroups.ByInsight,
    showWarnings: true,
  },
  insightTool: {
    activeComponent: null,
    insightCount: { warnings: 0, errors: 0 },
    insightSelectionEnabled: true,
  },
  duplicateTool: {
    instances: null,
    isDuplicateUnderBuildPlate: false,
  },
  isShownNoMaterialParamsTooltip: false,
  hoverBodyId: { bodyId: null, bpItemId: null },
  partImportJobs: [],
  parentFolder: undefined,
  previewPrintOrder: {
    navigatedFrom: null,
    labelSearchInput: '',
  },
  isVariantProcessing: false,
  isVariantCreating: false,
  isBuildPlanDisposing: false,
  buildPlanDisposePromise: null,
  buildPlanVariantJobs: [],
  displayToolbarStates: [],
  canCreateVariants: false,
  preferredSupportItems: [],
  prohibitedSupportItems: [],
  selectedOrientationIndex: null,
  selectedBuildPlanPrintStrategy: null,
  requiresLabelSetUpdates: false,
  lockBuildPlanIntervalId: null,
  machineConfigMaterialBinders: [],
  isBuildPlanUpdating: false,
  numberOfPendingPartElevationRequests: 0,
  isLabelToolPreparing: false,
  isLabelRestoreInProgress: false,
  geometryPropertiesCache: new Map<string, IMeshGeometryProperties>(),
  activeToolHasUnsavedData: false,
  editIbcPlan: {
    navigatedFrom: null,
  },
  ibcPlan: null,
  ibcDisplayToolbarStates: [],
  ibcPlanJobs: [],
  isToolMaskDisplaying: false,
  collaboratorsCount: 0,
  loadingItemsData: null,
  isSupportToolPreparing: false,
  isTransferToolBusy: false,
}

const namespaced: boolean = true

export const buildPlans: Module<IBuildPlansState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
