import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { ICommonState } from './types'
import { IRootState } from '@/store/types'

export const state: ICommonState = {
  locales: [],
  selectedLocale: undefined,
  connectedMachineConfigs: [],
  isDisconnected: false,
}

const namespaced: boolean = true

export const common: Module<ICommonState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
