
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { IProcessSummary, RiskType, IProcessItem } from '@/visualization/summary'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'
import { HighlightType } from '@/visualization/types/Common'
import { ISimulationStep, StepType } from '@/types/Simulation/SimulationSteps'
import { getUnitLabel, Unit } from '@/types/Simulation/Units'

const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component
export default class ProcessSummary extends Vue {
  @visualizationStore.Getter('getSimulationSteps') getSimulationSteps: ISimulationStep[]
  @visualizationStore.Getter('getLoadedSimulationSteps') getLoadedSimulationSteps: string[]
  @visualizationStore.Getter('getVisualizationLoading') getVisualizationLoading: boolean
  @visualizationStore.Getter legend: { rangePoints: number[]; colors: number[][] }

  @visualizationStore.Mutation('highlightDetailsInfo') highlightDetailsInfo: Function
  @visualizationStore.Mutation('setResultsPathData') setResultsPathData: Function
  @visualizationStore.Mutation('viewSimulationResults') viewSimulationResults: Function
  @visualizationStore.Mutation('setIsLoading') setIsLoading: Function

  @Prop() context: IProcessSummary

  risks = RiskType
  selectedItem: number = null
  private icon: string = 'mdi-check-circle-outline'
  private lowRiskItemsCount: number = 0
  private moderateRiskItemsCount: number = 0
  private highRiskItemsCount: number = 0
  private previousItem: IProcessItem = null
  private loadStepCallback

  constructor() {
    super()
    this.$root.$on('activeItemSet', this.handleActiveItemSetOutside)
  }

  @Watch('context') onContextChanged() {
    if (this.context.items.length > 0) {
      for (const item of this.context.items) {
        if (item.risk === RiskType.Low) this.lowRiskItemsCount += 1
        if (item.risk === RiskType.Moderate) this.moderateRiskItemsCount += 1
        if (item.risk === RiskType.High) this.highRiskItemsCount += 1
      }

      if (this.highRiskItemsCount !== 0) {
        this.context.conclusion = RiskType.High
      } else if (this.moderateRiskItemsCount !== 0) {
        this.context.conclusion = RiskType.Moderate
      } else if (this.lowRiskItemsCount !== 0) {
        this.context.conclusion = RiskType.Low
      }
    }
  }

  @Watch('selectedItem') onActiveItemChanged() {
    this.resetPreviousItem()

    if (this.selectedItem !== undefined) {
      const item = this.visibleItems[this.selectedItem]
      item.active = true
      this.previousItem = item
      this.$root.$emit('activeItemSet', this)
    } else {
      this.previousItem = null
    }
  }

  highlightDetails(item) {
    this.setResultsPathData({ simulationStep: this.targetStep })
    if (this.getLoadedSimulationSteps.indexOf(this.targetStep.value) !== -1) {
      this.highlightItem(item)
    } else {
      this.setIsLoading(true)
      this.viewSimulationResults()
      this.loadStepCallback = this.$watch('legend', () => {
        if (this.legend.rangePoints !== undefined && this.legend.rangePoints.length !== 0) {
          this.highlightItem(item)
          this.loadStepCallback()
        }
      })
    }
  }

  unitLabel(unit: Unit) {
    return getUnitLabel(unit)
  }

  private highlightItem(item) {
    this.highlightDetailsInfo({
      highlight: true,
      coords: item.location,
      type: this.riskToHighlightType(item.risk),
    })
  }

  get targetStep() {
    return (
      this.getSimulationSteps.find((s) => s.type === StepType.Compensation) ||
      this.getSimulationSteps.find((s) => s.type === StepType.BinderJet || s.type === StepType.DmlmBuild)
    )
  }

  get isVisible() {
    return this.context.items.length > 0
  }

  get itemDisabled() {
    return this.getVisualizationLoading || !this.targetStep
  }

  get conclusionRiskLow() {
    return this.context.conclusion || this.context.conclusion === RiskType.Low
  }

  get conclusionRiskModerate() {
    return !this.context.conclusion || this.context.conclusion === RiskType.Moderate
  }

  get conclusionRiskHigh() {
    return this.context.conclusion === RiskType.High
  }

  get conclusionLabel() {
    if (this.context.conclusion !== undefined && typeof this.context.conclusion === 'boolean') {
      return this.context.conclusion
        ? this.$t('summaryLabels.geometryInRange')
        : this.$t('summaryLabels.geometryOutOfRange')
    }

    if (this.highRiskItemsCount !== 0) {
      return this.$tc('summaryLabels.recoaterHighRisk', this.highRiskItemsCount)
    }

    if (this.moderateRiskItemsCount !== 0) {
      return this.$tc('summaryLabels.recoaterModerateRisk', this.moderateRiskItemsCount)
    }

    if (this.lowRiskItemsCount !== 0) {
      return this.$t('summaryLabels.recoaterNoRisk')
    }

    return ''
  }

  get iconContent() {
    if (this.conclusionRiskHigh) return this.riskToIcon(RiskType.High)
    if (this.conclusionRiskModerate) return this.riskToIcon(RiskType.Moderate)
    if (this.conclusionRiskLow) return this.riskToIcon(RiskType.Low)

    return ''
  }

  get visibleItems() {
    return this.context.items.filter((i) => i.risk !== RiskType.Low)
  }

  private handleActiveItemSetOutside(otherComponent) {
    if (otherComponent !== this) {
      this.selectedItem = undefined
    }
  }

  private resetPreviousItem() {
    if (this.previousItem) {
      this.previousItem.active = false
      this.highlightDetailsInfo({ highlight: false, coords: null, type: null })
    }
  }

  private riskToIcon(risk: RiskType) {
    switch (risk) {
      case RiskType.Low:
        return 'mdi-check-circle-outline'
      case RiskType.Moderate:
        return 'mdi-alert-outline'
      case RiskType.High:
        return 'mdi-alert-box-outline'
      default:
        return ''
    }
  }

  private riskToHighlightType(risk: RiskType) {
    switch (risk) {
      case RiskType.Moderate:
        return HighlightType.Warning
      case RiskType.High:
        return HighlightType.Error
      case RiskType.Low:
      default:
        return HighlightType.Info
    }
  }
}
