
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class EditingParameterModal extends Vue {
  @Prop({ required: true, type: Boolean }) value: boolean
  @Prop({ default: false, type: Boolean }) loading: boolean
  @Prop({ default: false, type: Boolean }) saveDisabled: boolean

  get header() {
    return this.$t('editingParameterModal.header')
  }

  get body() {
    return this.$t('editingParameterModal.body')
  }
}
