
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'
import { extend } from 'vee-validate'
import VSelect from 'vuetify/lib/components/VSelect'
import { Watch } from 'vue-property-decorator'

import SiteConstantUpdater from '@/components/layout/admin/sites/SiteConstantUpdater.vue'
import EditableLabel from '@/components/controls/Common/EditableLabel.vue'
import PrintStrategyStatusToggler from '@/components/layout/admin/printStrategy/PrintStrategyStatusToggler.vue'
import SimulationReadyTooltip from '@/components/layout/admin/printStrategy/SimulationReadyTooltip.vue'
import ProcessParametersModal from '@/components/layout/admin/printStrategy/ProcessParametersModal.vue'
import Menu from '@/components/controls/Common/Menu.vue'
import { PrintStrategyStatus } from '@/types/Sites/PrintStrategyStatus'
import {
  AdditionalPrintStrategyInfo,
  PrintStrategyInfo,
  Measurement,
  MachineMaterial,
  MachineTypeDto,
  DMLMPartParamAdditionalInfoDto,
  PrintStrategyParameterSetDto,
} from '@/types/Sites/Site'
import { debounce } from '@/utils/debounce'
import StoresNamespaces from '@/store/namespaces'

import {
  CheckPrintStrategyNameExistsPayload,
  UploadDmlmProductionSetPayload,
  UpdatePrintStrategyNamePayload,
} from '@/store/modules/sites/types'
import { convert } from '@/utils/converter/lengthConverter'
import { ClickOutsideMixin } from '@/components/layout/mixins/ClickOutsideMixin'
import { PrintingTypes } from '@/types/IMachineConfig'
import { RouterNames } from '@/router'
import PartParameterDuplicateErrorModal from './PartParameterDuplicateErrorModal.vue'
import DeletePrintStrategyModal from './DeletePrintStrategyModal.vue'
import {
  IDmlmParameterSetContent,
  InertGasType,
  IParameterSet,
  RecoaterMaterial,
  RecoaterType,
} from '@/types/BuildPlans/IBuildPlan'
import { DUMMY_PRINT_STRATEGY_PARAMETER_SET_ID } from '@/constants'
import GenericErrorModal from './GenericErrorModal.vue'
import {
  WrxSupportedMachines,
  WrxUploadSupportedPartParameterExtensions,
  WrxUploadSupportedProcessParameterExtensions,
} from '@/types/PrintStrategy/enum'

const sitesStore = namespace(StoresNamespaces.Sites)
const commonStore = namespace(StoresNamespaces.Common)

const DEBOUNCE_TIME = 600
const ZERO_THICKNESS = '0.000'

@Component({
  components: {
    DeletePrintStrategyModal,
    EditableLabel,
    Menu,
    PartParameterDuplicateErrorModal,
    PrintStrategyStatusToggler,
    ProcessParametersModal,
    SimulationReadyTooltip,
    SiteConstantUpdater,
    GenericErrorModal,
  },
})
export default class PrintStrategy extends ClickOutsideMixin {
  @sitesStore.Action fetchPrintStrategyById: (payload: {
    printStrategyId: number
    siteId: number
  }) => Promise<PrintStrategyInfo>
  @sitesStore.Action fetchPartParameterById: (payload: { partParameterId: number }) => Promise<IParameterSet>
  @sitesStore.Action fetchPrintStrategyAdditionalInfo: (printStrategyId: string) => Promise<AdditionalPrintStrategyInfo>
  @sitesStore.Action updatePrintStrategyStatus: (params: {
    printStrategyId: number
    siteId: number
    status: PrintStrategyStatus
  }) => Promise<PrintStrategyInfo>

  @sitesStore.Action updatePrintStrategyDefaults: (params: {
    printStrategyId: number
    siteId: number
    defaults: object
  }) => Promise<PrintStrategyInfo>
  @sitesStore.Action addPrintStrategyParameterSet: (params: {
    printStrategyId: number
    siteId: number
    parameterSetId: number
    layerThickness?: number
  }) => Promise<PrintStrategyInfo>
  @sitesStore.Action updatePrintStrategyParameterSetLayerThickness: (params: {
    printStrategyId: number
    siteId: number
    printStrategyParameterSetId: number
    layerThickness: number
  }) => Promise<PrintStrategyInfo>
  @sitesStore.Action updatePrintStrategyProcessParameterFile: (params: {
    printStrategyId: number
    siteId: number
    processParameterFileId: string
  }) => Promise<PrintStrategyInfo>

  @sitesStore.Action uploadProductionSet: (payload: UploadDmlmProductionSetPayload) => any

  @sitesStore.Action uploadParameterSet: (payload: { printStrategyId: number; file: File; siteId: number }) => any
  @sitesStore.Action updatePrintStrategyName: (payload: UpdatePrintStrategyNamePayload) => Promise<PrintStrategyInfo>
  @sitesStore.Action checkPrintStrategyNameExists: (payload: CheckPrintStrategyNameExistsPayload) => Promise<boolean>
  @sitesStore.Action isPrintStrategyUsedByBuildPlan: (printStrategyId: number) => Promise<boolean>
  @sitesStore.Action deletePrintStrategy: (printStrategyId: number) => Promise<number>
  @sitesStore.Action deletePrintStrategyParameterSet: (params: {
    printStrategyId: number
    siteId: number
    parameterSetId: string
  }) => Promise<PrintStrategyInfo>

  @sitesStore.Mutation setSelectedPrintStrategy: (printStrategy: PrintStrategyInfo) => void
  @sitesStore.Mutation clearPrintStrategyState: () => void
  @sitesStore.Mutation addDmlmParameterSetToPrintStrategy: (
    printStrategyParameterSet: PrintStrategyParameterSetDto,
  ) => void
  @sitesStore.Mutation removeDmlmParameterSetFromPrintStrategy: (printStrategyParameterSetId: string) => void

  @sitesStore.Getter getSelectedPrintStrategy: PrintStrategyInfo
  @sitesStore.Getter getSelectedPrintStrategyParameterSets: PrintStrategyParameterSetDto[]
  @sitesStore.Getter getPrintStrategyAdditionalInfo: AdditionalPrintStrategyInfo
  @sitesStore.Getter getSelectedMachineType: MachineTypeDto
  @sitesStore.Getter getSelectedMaterial: MachineMaterial

  @sitesStore.Getter isBreadcrumbsReady: boolean

  @commonStore.Getter tooltipOpenDelay: number

  currentEditThicknessParameterSetId: number = 0
  editableField: string = null
  formFieldProcessing = false
  isPartParameterDuplicateError = false
  isPartParameterUploading = false
  isPrintStrategyUnused: boolean = false
  isShowProcessParametersModal = false
  isProcessParameterFileUploading = false
  isReady = false
  layerThickness: string = ZERO_THICKNESS
  originalThickness: string = ZERO_THICKNESS
  minimalThickness: number = 0
  paramSetId: number = 0
  printStrategyId: number = null
  selectedPartParamFile: File = null
  selectedProcessParamFile: File = null
  selectedProcessParameterFileId = null
  siteId: number
  addedParameterHasThicknessError = false
  isShowGenericErrorModal: boolean = false
  genericErrorModalTitle: string = ''
  genericErrorModalMessage: string = ''
  clickRefElementAfterModalClose: any = null

  measurement = Measurement

  convert = convert

  $refs!: {
    newPartParam: VSelect
    processFile: VSelect
    partParamSelector: VSelect
    uploadPartParam: VSelect
    uploadProcessParam: VSelect
    deleteModal: InstanceType<typeof DeletePrintStrategyModal>
  }

  get isDMLMModality() {
    return this.getSelectedMachineType.modality === PrintingTypes.DMLM
  }

  get isBinderJetModality() {
    return this.getSelectedMachineType.modality === PrintingTypes.BinderJet
  }

  get acceptedParameterSetFileTypes(): string {
    return '.par,.xpar'
  }

  get printStrategyFieldName() {
    return this.$t('tenantAdminMessages.printStrategy.sections.printStrategyName')
  }

  getItemLayerThickness(item: PrintStrategyParameterSetDto) {
    const layerThickness = parseFloat(convert('m', 'mm', item.layerThickness).toPrecision(3))
    const layerThicknessFixed = layerThickness.toFixed(3)

    return layerThickness === +layerThicknessFixed ? layerThicknessFixed : layerThickness.toString()
  }

  @Watch('isBreadcrumbsReady')
  onBreadcrumbsReadyStateChange(isReady: boolean) {
    if (isReady) {
      this.prepareData()
    }
  }

  beforeMount() {
    this.extendValidationRules()
  }

  extendValidationRules() {
    extend('more_value', {
      validate: (value: number, { limit }: { limit: number }) => {
        return (
          value > limit ||
          (+this.originalThickness === 0 &&
            +this.layerThickness === 0 &&
            this.getSelectedPrintStrategyParameterSets.length > 0)
        )
      },
      params: ['limit'],
      message: this.$i18n.t('moreValidationMessage') as string,
    })
    extend('unique-strategy-name', {
      validate: debounce(DEBOUNCE_TIME, async (value: string) => {
        const trimmedValue = value.trim()
        if (this.formFieldProcessing || this.getSelectedPrintStrategy.name === trimmedValue) {
          return true
        }

        this.formFieldProcessing = true

        const isNameExists = await this.checkPrintStrategyNameExists({
          printStrategyId: this.printStrategyId,
          name: trimmedValue,
        })

        this.formFieldProcessing = false

        return !isNameExists
      }),
      message: 'Name must be unique',
    })
    extend('max', {
      validate(value, args) {
        return value.length <= args.length
      },
      params: ['length'],
    })
    extend('in_range_included', {
      validate: (
        value: number,
        { leftLimit, rightLimit, units }: { leftLimit: string; rightLimit: string; units: string },
      ) => {
        return (
          (value >= +leftLimit && value <= +rightLimit) ||
          (+this.originalThickness === 0 &&
            +this.layerThickness === 0 &&
            this.getSelectedPrintStrategyParameterSets.length > 1 &&
            !this.getSelectedPrintStrategyParameterSets.every((pspp) => pspp.layerThickness === 0))
        )
      },
      params: ['leftLimit', 'rightLimit', 'units'],
      message: this.$i18n.t('inRangeValidationMessage').toString(),
    })
    extend('is_multiple_of', {
      validate: (value: number) => {
        return (
          this.minimalThickness === 0 ||
          (this.isLowestValue(+value) && +value !== 0) ||
          (this.isUnique(+value) && this.isMultipleOf(+value))
        )
      },
      params: ['lowestThickness'],
      message: this.$i18n.t('layerThicknessMultipleOf').toString(),
    })
    extend('is_new_multiplier', {
      validate: (value: number) => {
        return (
          this.minimalThickness === 0 ||
          !this.isLowestValue(+value) ||
          (this.isUnique(+value) && this.isNewMultiplier(+value))
        )
      },
      params: ['layerThickness'],
      message: this.$i18n.t('layerThicknessNotANewMultiplier').toString(),
    })
  }

  async prepareData() {
    try {
      const { printStrategyId, siteId } = this.$route.params
      this.siteId = Number(siteId)
      this.printStrategyId = Number(printStrategyId)

      await this.fetchPrintStrategyAdditionalInfo(printStrategyId)
      const thicknessOfLayers = this.getSelectedPrintStrategyParameterSets
        .map((pp) => pp.layerThickness)
        .filter((ls) => !!+ls)
      if (thicknessOfLayers.length) {
        this.minimalThickness = Math.min(...thicknessOfLayers)
      }
      this.isPrintStrategyUnused = !(await this.isPrintStrategyUsedByBuildPlan(this.printStrategyId))
      this.isReady = !!this.getSelectedPrintStrategy && !!this.getPrintStrategyAdditionalInfo
    } catch (error) {
      this.isReady = false
    }
  }

  get processFileText() {
    if (this.getPrintStrategyAdditionalInfo && this.getPrintStrategyAdditionalInfo.availableProcessFiles) {
      const processFile = this.getPrintStrategyAdditionalInfo.availableProcessFiles.find(
        (pf) =>
          pf.value === this.getSelectedPrintStrategy.processParameterFileId ||
          pf.value === this.selectedProcessParameterFileId,
      )
      if (processFile) {
        return processFile.text
      }
    }
    return this.getSelectedPrintStrategy.processParameterFileId
  }

  get printStrategyStatuses() {
    return [
      { text: PrintStrategyStatus.Active, value: PrintStrategyStatus.Active, color: 'green' },
      { text: PrintStrategyStatus.Inactive, value: PrintStrategyStatus.Inactive, color: 'grey' },
    ]
  }

  get isActiveMenu() {
    if (!this.isReady) {
      return false
    }
    const isNewPartParamMenuActive =
      this.$refs.newPartParam &&
      this.$refs.newPartParam.$refs &&
      this.$refs.newPartParam.$refs.menu &&
      this.$refs.newPartParam.$refs.menu.isActive

    const isProcessFileMenuActive =
      this.$refs.processFile &&
      this.$refs.processFile.$refs &&
      this.$refs.processFile.$refs.menu &&
      this.$refs.processFile.$refs.menu.isActive

    const isPartParamSelectorMenuActive =
      this.$refs.partParamSelector &&
      this.$refs.partParamSelector.$refs &&
      this.$refs.partParamSelector.$refs.menu &&
      this.$refs.partParamSelector.$refs.menu.isActive

    return isNewPartParamMenuActive || isProcessFileMenuActive || isPartParamSelectorMenuActive
  }

  get isSelectedMachineTypeSupported() {
    const wrxSupportedMachines = Object.values(WrxSupportedMachines)
    const machine = wrxSupportedMachines.find(
      (machineName) =>
        machineName.toLocaleLowerCase().trim() === this.getSelectedMachineType.name.toLocaleLowerCase().trim(),
    )

    return !!machine
  }

  @Watch('isActiveMenu')
  onIsMenuActiveChanged() {
    this.setListenerForClickOutside(this.isActiveMenu, this.closeAllSelectorMenus)
  }

  closeAllSelectorMenus() {
    this.$refs.processFile.$refs.menu.isActive = false
    this.$refs.newPartParam.$refs.menu.isActive = false
  }

  preProcessParameterUpload(event: Event) {
    if (!this.isSelectedMachineTypeSupported) {
      event.stopImmediatePropagation()
      const title = this.$t(
        'tenantAdminMessages.printStrategy.dialogs.upload.processParameter.uploadError.title',
      ).toString()
      const message = this.$t('tenantAdminMessages.printStrategy.dialogs.upload.processParameter.uploadError.message', {
        machineName: this.getSelectedMachineType.name,
      }).toString()
      this.showGenericErrorModal(title, message)
    }
  }

  async onFileAdded(file: File) {
    if (file) {
      const extension = file.name.split('.').pop()
      const supportedExtensions = Object.values(WrxUploadSupportedProcessParameterExtensions) as string[]
      const isSupprtedExtension = supportedExtensions.find((supportedExtension) => supportedExtension === extension)
      let extensionString
      if (!isSupprtedExtension) {
        if (supportedExtensions.length === 1) {
          extensionString = `'.${supportedExtensions[0]}'`
        } else {
          extensionString = supportedExtensions.reduce((previousValue, currentValue, currentIndex) => {
            if (currentIndex === 1) {
              return `'.${previousValue}' or '.${currentValue}'`
            }
            return `${previousValue} or '.${currentValue}'`
          })
        }
        const title = this.$t(
          'tenantAdminMessages.printStrategy.dialogs.upload.processParameter.invalidFileType.title',
        ).toString()
        const message = this.$t(
          'tenantAdminMessages.printStrategy.dialogs.upload.processParameter.invalidFileType.message',
          { extension: extensionString },
        ).toString()
        this.clickRefElementAfterModalClose = this.$refs.uploadProcessParam.$refs.input
        this.showGenericErrorModal(title, message)
        return
      }

      this.selectedProcessParamFile = file
      this.isShowProcessParametersModal = true
    }
  }

  async uploadProcessParametersFile(parameters: {
    inertGasType: InertGasType
    file: File
    recoaterType: RecoaterType
    recoaterMaterial: RecoaterMaterial
  }) {
    try {
      this.isProcessParameterFileUploading = true

      await this.uploadProductionSet({
        file: parameters.file,
        inertGasType: parameters.inertGasType,
        machineConfigId: this.getSelectedPrintStrategy.machineConfigId,
        materialId: this.getSelectedPrintStrategy.materialId,
        modality: this.getSelectedMachineType.modality,
        printStrategyId: this.printStrategyId,
        recoaterMaterial: parameters.recoaterMaterial,
        recoaterType: parameters.recoaterType,
        siteId: this.siteId,
      })

      await this.fetchPrintStrategyAdditionalInfo(this.getSelectedPrintStrategy.id.toString())
      this.closeProcessParametersModal()
    } catch (error) {
      const title = this.$t(
        'tenantAdminMessages.printStrategy.dialogs.upload.processParameter.invalidFileType.title',
      ).toString()
      const message = `The file contains invalid content.`
      this.isProcessParameterFileUploading = false
      this.closeProcessParametersModal()
      this.clickRefElementAfterModalClose = this.$refs.uploadProcessParam.$refs.input
      this.showGenericErrorModal(title, message)
    } finally {
      this.isProcessParameterFileUploading = false
    }
  }

  prePartParameterUpload(event: Event) {
    if (!this.isSelectedMachineTypeSupported) {
      event.stopImmediatePropagation()
      const title = this.$t(
        'tenantAdminMessages.printStrategy.dialogs.upload.partParameter.uploadError.title',
      ).toString()
      const message = this.$t('tenantAdminMessages.printStrategy.dialogs.upload.partParameter.uploadError.message', {
        machineName: this.getSelectedMachineType.name,
      }).toString()
      this.showGenericErrorModal(title, message)
    }
  }

  async onParameterSetFileAdded(file: File) {
    if (!file) {
      return
    }

    const extension = file.name.split('.').pop()
    const supportedExtensions = Object.values(WrxUploadSupportedPartParameterExtensions) as string[]
    const isSupprtedExtension = supportedExtensions.find((supportedExtension) => supportedExtension === extension)

    const title = this.$t(
      'tenantAdminMessages.printStrategy.dialogs.upload.partParameter.invalidFileType.title',
    ).toString()
    let extensionString
    if (!isSupprtedExtension) {
      if (supportedExtensions.length === 1) {
        extensionString = `'.${supportedExtensions[0]}'`
      } else {
        extensionString = supportedExtensions.reduce((previousValue, currentValue, currentIndex) => {
          if (currentIndex === 1) {
            return `'.${previousValue}' or '.${currentValue}'`
          }
          return `${previousValue} or '.${currentValue}'`
        })
      }
      const message = this.$t(
        'tenantAdminMessages.printStrategy.dialogs.upload.partParameter.invalidFileType.message',
        { extension: extensionString },
      ).toString()

      this.clickRefElementAfterModalClose = this.$refs.uploadPartParam.$refs.input
      this.showGenericErrorModal(title, message)
      return
    }

    const fileName = file.name.replace(/\.[^/.]+$/, '')
    const nameSplitedByUnderscore = fileName.split('_')
    const layerThicknessValues = nameSplitedByUnderscore[2]

    // Parameter files that don't have underscores in the name are allowed
    // https://jira-ebm.additive.ge.com/browse/YPVJZ-16258
    if (layerThicknessValues && layerThicknessValues.length === 6) {
      const firstThickness = +layerThicknessValues.substring(0, 3)
      const secondThickness = +layerThicknessValues.substring(3)
      if (firstThickness === 0 && secondThickness === 0) {
        const message =
          `Invalid file name: both layer thicknesses (two 3-digits groups between the second` +
          ` and third underscore) can not be equal to zero(e.g. 000000)`
        this.showGenericErrorModal(title, message)
        this.$refs.uploadPartParam.$refs.input.value = null
        return
      }

      // Range: 010 - 250
      if (
        ((firstThickness < 10 || firstThickness > 250) && firstThickness !== 0) ||
        ((secondThickness < 10 || secondThickness > 250) && secondThickness !== 0)
      ) {
        const message =
          `Invalid file name: layer thicknesses (two 3-digits groups between the second` +
          ` and third underscore) has to be between 010 and 250 (e.g. 050100, 000060)`
        this.showGenericErrorModal(title, message)
        this.$refs.uploadPartParam.$refs.input.value = null
        return
      }
    }

    this.selectedPartParamFile = file
    await this.uploadParameterSetFile()
  }

  async uploadParameterSetFile() {
    if (this.selectedPartParamFile) {
      try {
        this.isPartParameterUploading = true
        await this.uploadParameterSet({
          file: this.selectedPartParamFile,
          printStrategyId: this.printStrategyId,
          siteId: this.siteId,
        })

        await this.fetchPrintStrategyAdditionalInfo(this.getSelectedPrintStrategy.id.toString())

        this.selectedPartParamFile = null
        this.checkThickness()
        this.$refs.uploadPartParam.$refs.input.value = null
      } catch (error) {
        if (error.status === 409) {
          this.isPartParameterDuplicateError = true
        } else {
          const title = this.$t(
            'tenantAdminMessages.printStrategy.dialogs.upload.partParameter.invalidFileType.title',
          ).toString()
          const message = `The file contains invalid content.`
          this.clickRefElementAfterModalClose = this.$refs.uploadPartParam.$refs.input
          this.showGenericErrorModal(title, message)
        }
      } finally {
        this.isPartParameterUploading = false
      }
    }
  }

  terminateUploadPartParamProcess() {
    this.selectedPartParamFile = null
    this.$refs.uploadPartParam.$refs.input.value = null
  }

  get partParametersTableHeaders() {
    return [
      {
        text: this.$t('tenantAdminMessages.printStrategy.headers.file'),
        value: 'name',
        sortable: false,
        width: '375px',
      },
      {
        text: this.$t('tenantAdminMessages.printStrategy.headers.layerThickness'),
        value: 'layerThickness',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('tenantAdminMessages.printStrategy.headers.simulationReady'),
        value: 'allowSimCom',
        sortable: false,
        align: 'center',
      },
      { text: '', value: 'actions', sortable: false, align: 'left' },
    ]
  }

  get defaultsTableHeaders() {
    return [
      {
        text: this.$t('tenantAdminMessages.printStrategy.headers.function'),
        value: 'function',
        sortable: false,
        width: '350px',
      },
      {
        text: this.$t('tenantAdminMessages.printStrategy.headers.partParameter'),
        value: 'partParameter',
        sortable: false,
        align: 'left',
      },
    ]
  }

  get defaults() {
    return [
      {
        function: 'Production',
        partParameter: this.getSelectedPrintStrategy.productionId,
        fieldName: 'productionId',
      },
      {
        function: 'Coupon',
        partParameter: this.getSelectedPrintStrategy.couponId,
        fieldName: 'couponId',
      },
      {
        function: 'Support - Imported Volume',
        partParameter: this.getSelectedPrintStrategy.supportImportedVolumeId,
        fieldName: 'supportImportedVolumeId',
      },
      {
        function: 'Support - Imported Sheet',
        partParameter: this.getSelectedPrintStrategy.supportImportedSheetId,
        fieldName: 'supportImportedSheetId',
      },
      {
        function: 'Support - Amp Solid',
        partParameter: this.getSelectedPrintStrategy.supportAmpSolidId,
        fieldName: 'supportAmpSolidId',
      },
      {
        function: 'Support - Amp Line',
        partParameter: this.getSelectedPrintStrategy.supportAmpLineId,
        fieldName: 'supportAmpLineId',
      },
    ]
  }

  get getProcessParameterLabel() {
    return this.selectedProcessParameterFileId
      ? this.$t('tenantAdminMessages.printStrategy.controls.processFile')
      : this.$t('tenantAdminMessages.printStrategy.controls.selectProcessFile')
  }

  get getSelectedPrintStrategyStatus() {
    return this.getSelectedPrintStrategy.status
  }

  get getChangeStatusAvailability() {
    return this.getSelectedPrintStrategyStatus !== PrintStrategyStatus.Incomplete
  }

  isLowestValue(testedThickness) {
    const thicknessOfLayers = this.getThicknessArrayWithoutEditedValue()
    return thicknessOfLayers.every((lt) => lt > testedThickness)
  }

  getTooltipTextForPartParameterRemoveBtn(parameter: PrintStrategyParameterSetDto): string {
    if (this.isDeletePartParameterDisabled(parameter)) {
      return this.$i18n.t('tenantAdminMessages.printStrategy.controls.notPossibleToRemovePartParameter').toString()
    }

    return ''
  }

  getThicknessArrayWithoutEditedValue() {
    const thicknessOfLayers = this.getSelectedPrintStrategyParameterSets.map((pp) => {
      return {
        layerThickness: parseFloat(convert('m', 'mm', pp.layerThickness).toPrecision(3)),
        id: pp.parameterSetId,
      }
    })
    const index = thicknessOfLayers.findIndex(
      (lt) => lt.layerThickness === +this.originalThickness && lt.id === this.paramSetId,
    )
    thicknessOfLayers.splice(index, 1)
    return thicknessOfLayers.map((lt) => lt.layerThickness).filter((ls) => !!+ls)
  }

  getLowestThickness() {
    const thicknessOfLayers = this.getThicknessArrayWithoutEditedValue()
    return +this.layerThickness ? Math.min(+this.layerThickness, ...thicknessOfLayers) : Math.min(...thicknessOfLayers)
  }

  isShownEditableField(fieldName: string): boolean {
    return this.isDMLMModality && this.editableField === fieldName
  }

  isDisabledEditableField(): boolean {
    return this.getSelectedMachineType.modality !== PrintingTypes.DMLM || this.addedParameterHasThicknessError
  }

  isDisabledStatusField(): boolean {
    return this.addedParameterHasThicknessError
  }

  updateDefault(e, fieldName) {
    const defaults = {}
    defaults[fieldName] = e
    this.updatePrintStrategyDefaults({ defaults, printStrategyId: this.printStrategyId, siteId: this.siteId })
  }

  updateProcessParameterFile(e) {
    this.selectedProcessParameterFileId = e
    this.updatePrintStrategyProcessParameterFile({
      processParameterFileId: e,
      printStrategyId: this.printStrategyId,
      siteId: this.siteId,
    })
  }

  thicknessValid(thickness: number, partParameterId?: number) {
    return (
      this.minimalThickness === 0 || (this.isUnique(+thickness, partParameterId) && this.isNewMultiplier(+thickness))
    )
  }

  async addNewPartParam(e) {
    const partParameterId = e
    const partParameter = await this.fetchPartParameterById({ partParameterId })
    const partParameterContent = partParameter.partParameters as IDmlmParameterSetContent
    const printStrategyParameterSetImitation = {
      name: partParameter.parameterSetName,
      id: DUMMY_PRINT_STRATEGY_PARAMETER_SET_ID,
      allowSimCom: partParameter.allowSimCom,
      layerThickness: partParameter.layerThickness,
      isUsed: false,
      parameterSetId: partParameter.id,
      parameterSetVersion: partParameter.version,
      dmlmData: {
        id: partParameter.id,
        laserPower: partParameterContent.laserPower,
        laserSpeed: partParameterContent.laserSpeed,
        hatchDistance: partParameterContent.hatchDistance,
        beamDiameter: partParameterContent.beamDiameter,
      } as DMLMPartParamAdditionalInfoDto,
    } as PrintStrategyParameterSetDto

    this.addDmlmParameterSetToPrintStrategy(printStrategyParameterSetImitation)
    this.$refs.newPartParam.reset()

    const partParamThickness = parseFloat(convert('m', 'mm', partParameter.layerThickness).toPrecision(3))
    if (this.thicknessValid(partParamThickness, partParameterId)) {
      await this.addPrintStrategyParameterSet({
        parameterSetId: partParameterId,
        printStrategyId: this.printStrategyId,
        siteId: this.siteId,
      })
      await this.fetchPrintStrategyAdditionalInfo(this.printStrategyId.toString())
    } else {
      this.addedParameterHasThicknessError = true
    }

    this.checkThickness()
  }

  checkThickness() {
    const partParameter = this.getSelectedPrintStrategyParameterSets.slice().reverse().shift()

    if (this.getSelectedPrintStrategyParameterSets.length > 0 && partParameter.layerThickness === 0) {
      this.setCurrentEditThicknessParameterSetId(partParameter)
      return
    }

    if (this.minimalThickness === 0) {
      const thicknessOfLayers = this.getSelectedPrintStrategyParameterSets
        .map((pp) => pp.layerThickness)
        .filter((ls) => !!+ls)
      if (thicknessOfLayers.length) {
        this.minimalThickness = Math.min(...thicknessOfLayers)
      }
      return
    }

    const minimalThickness = parseFloat(convert('m', 'mm', this.minimalThickness).toPrecision(3))
    const ppThickness = parseFloat(convert('m', 'mm', partParameter.layerThickness).toPrecision(3))
    if (
      this.minimalThickness !== 0 &&
      (!this.isMultipleOf(+ppThickness, +minimalThickness, partParameter.parameterSetId) ||
        !this.isUnique(+ppThickness, partParameter.parameterSetId))
    ) {
      this.setCurrentEditThicknessParameterSetId(partParameter)
    }
  }

  isMultipleOf(testedThickness, overrideMinimal = null, partParamId = null): boolean {
    const partParametersId = partParamId ? partParamId : this.paramSetId
    const thicknessOfLayers = this.getSelectedPrintStrategyParameterSets.map((pp) => {
      return {
        layerThickness: parseFloat(convert('m', 'mm', pp.layerThickness).toPrecision(3)),
        id: pp.parameterSetId,
      }
    })
    const index = thicknessOfLayers.findIndex(
      (lt) => lt.layerThickness === +this.originalThickness && lt.id === partParametersId,
    )
    thicknessOfLayers.splice(index, 1)
    const thicknessValues = thicknessOfLayers.map((lt) => lt.layerThickness).filter((ls) => !!+ls)
    const minimalThickness = overrideMinimal ? overrideMinimal : Math.min(testedThickness, ...thicknessValues)
    return Math.round(testedThickness / minimalThickness) / (1 / minimalThickness) === testedThickness
  }

  canEditLayerThickness(parameter: PrintStrategyParameterSetDto): boolean {
    return parameter.isUsed !== undefined ? !parameter.isUsed : true
  }

  isUnique(testedThickness, partParamId = null): boolean {
    const partParameterId = partParamId ? partParamId : this.paramSetId
    const thicknessOfLayers = this.getSelectedPrintStrategyParameterSets
      .filter((pp) => pp.parameterSetId === partParameterId)
      .map((pp) => {
        return {
          layerThickness: parseFloat(convert('m', 'mm', pp.layerThickness).toPrecision(3)),
          id: pp.parameterSetId,
        }
      })
    const index = thicknessOfLayers.findIndex(
      (lt) => lt.layerThickness === +this.originalThickness && lt.id === partParameterId,
    )
    thicknessOfLayers.splice(index, 1)
    const thicknessValues = thicknessOfLayers.map((lt) => lt.layerThickness).filter((ls) => !!+ls)
    return !thicknessValues.includes(testedThickness)
  }

  isNewMultiplier(testedThickness): boolean {
    const thicknessOfLayers = this.getSelectedPrintStrategyParameterSets.map((pp) => {
      return {
        layerThickness: parseFloat(convert('m', 'mm', pp.layerThickness).toPrecision(3)),
        id: pp.parameterSetId,
      }
    })
    const index = thicknessOfLayers.findIndex(
      (lt) => lt.layerThickness === +this.originalThickness && lt.id === this.paramSetId,
    )
    thicknessOfLayers.splice(index, 1)
    const thicknessValues = thicknessOfLayers.map((lt) => lt.layerThickness).filter((ls) => !!+ls)
    const minimalThickness = Math.min(testedThickness, ...thicknessValues)
    return thicknessValues.every((lt) => this.isMultipleOf(lt, minimalThickness))
  }

  setCurrentEditThicknessParameterSetId(item) {
    if (!this.isDMLMModality) {
      return
    }

    const layerThickness = parseFloat(convert('m', 'mm', item.layerThickness).toPrecision(3))
    const layerThicknessFixed = layerThickness.toFixed(3)

    this.currentEditThicknessParameterSetId = item.id
    this.paramSetId = item.parameterSetId
    this.layerThickness = layerThickness === +layerThicknessFixed ? layerThicknessFixed : layerThickness.toString()
    this.originalThickness = this.layerThickness
  }

  async updatePartParamThickness(e) {
    const layerThickness = parseFloat(convert('mm', 'm', e).toPrecision(3))
    const updatedParameterSetId = this.currentEditThicknessParameterSetId
    const paramId = this.paramSetId
    this.currentEditThicknessParameterSetId = 0
    this.paramSetId = 0
    if (this.addedParameterHasThicknessError) {
      await this.addPrintStrategyParameterSet({
        layerThickness,
        parameterSetId: paramId,
        printStrategyId: this.printStrategyId,
        siteId: this.siteId,
      })
      await this.fetchPrintStrategyAdditionalInfo(this.printStrategyId.toString())
    } else {
      await this.updatePrintStrategyParameterSetLayerThickness({
        layerThickness,
        printStrategyParameterSetId: updatedParameterSetId,
        printStrategyId: this.printStrategyId,
        siteId: this.siteId,
      })
    }

    this.layerThickness = ZERO_THICKNESS
    const thicknessOfLayers = this.getSelectedPrintStrategyParameterSets
      .map((pp) => pp.layerThickness)
      .filter((ls) => !!+ls)
    if (thicknessOfLayers.length) {
      this.minimalThickness = Math.min(...thicknessOfLayers)
    }
    this.addedParameterHasThicknessError = false
  }

  onCloseEditMode() {
    if (this.addedParameterHasThicknessError) {
      this.removeDmlmParameterSetFromPrintStrategy(DUMMY_PRINT_STRATEGY_PARAMETER_SET_ID)
      this.addedParameterHasThicknessError = false
    }
    this.currentEditThicknessParameterSetId = 0
    this.paramSetId = 0
    this.layerThickness = ZERO_THICKNESS
    this.editableField = null
  }

  get validationRules() {
    const lowestThickness = this.getLowestThickness()
    const lowestThicknessFixed = lowestThickness.toFixed(3)
    const layerThickness = +this.layerThickness
    const layerThicknessFixed = layerThickness.toFixed(3)

    return {
      rules: {
        required: true,
        more_value: 0,
        in_range_included: [(0.01).toFixed(3), (0.25).toFixed(3), 'mm'],
        is_multiple_of: [lowestThickness === +lowestThicknessFixed ? lowestThicknessFixed : lowestThickness.toString()],
        is_new_multiplier: [layerThickness === +layerThicknessFixed ? layerThicknessFixed : layerThickness.toString()],
      },
      customMessages: {
        required: this.$t('tenantAdminMessages.siteConstantValidationMessages.range').toString(),
      },
    }
  }

  get isDeletePrintStrategyDisabled() {
    return !this.isPrintStrategyUnused
  }

  get isProcessParameterUpdateDisabled() {
    return !this.isPrintStrategyUnused || this.addedParameterHasThicknessError
  }

  isDeletePartParameterDisabled(item) {
    return item.isUsed !== undefined ? item.isUsed : false
  }

  dropDownParameterSetName(item) {
    return `${item.name} - ${parseFloat(convert('m', 'mm', item.layerThickness).toPrecision(3))}mm`
  }

  async delPrintStrategy() {
    let confirm: boolean

    // Print strategy with 'Incomplete' status should be deleted without confirmation
    if (this.getSelectedPrintStrategy.status === PrintStrategyStatus.Incomplete) {
      await this.deletePrintStrategy(this.getSelectedPrintStrategy.id)
      confirm = true
    } else {
      confirm = await this.$refs.deleteModal.open(this.getSelectedPrintStrategy.id)
    }

    if (confirm) {
      const route = {
        name: RouterNames.EditMaterialAdmin,
        params: {
          siteId: this.$route.params.siteId,
          machineConfigId: this.$route.params.machineConfigId,
          materialId: this.$route.params.materialId,
        },
      }

      // @ts-ignore
      this.$router.safePush(route)
    }
  }

  showEditableField(fieldName: string): void {
    this.editableField = fieldName
  }

  async changeStatus(status: PrintStrategyStatus) {
    await this.updatePrintStrategyStatus({ status, printStrategyId: this.printStrategyId, siteId: this.siteId })
    this.editableField = null
  }

  async changePrintStrategyName(name: string) {
    if (this.formFieldProcessing) {
      return
    }
    if (this.getSelectedPrintStrategy.name === name) {
      this.editableField = null
      return
    }

    this.formFieldProcessing = true

    const strategy = await this.updatePrintStrategyName({
      name,
      printStrategyId: this.printStrategyId,
      siteId: this.siteId,
    })

    if (strategy) {
      this.editableField = null
    }

    this.formFieldProcessing = false
  }

  closePartParameterDuplicateErrorModal() {
    this.isPartParameterDuplicateError = false
    this.$refs.uploadPartParam.$refs.input.value = null
  }

  closeProcessParametersModal() {
    this.isShowProcessParametersModal = false
    this.selectedProcessParamFile = null
  }

  showGenericErrorModal(errorTitle: string, errorMessage: string) {
    this.genericErrorModalTitle = errorTitle ? errorTitle : ''
    this.genericErrorModalMessage = errorMessage ? errorMessage : ''
    this.isShowGenericErrorModal = true
  }

  closeGenericErrorModal() {
    this.isShowGenericErrorModal = false
    this.genericErrorModalTitle = ''
    this.genericErrorModalMessage = ''
    if (this.clickRefElementAfterModalClose) {
      // programatically clicking for file uploader
      this.clickRefElementAfterModalClose.click()
    }
    this.clickRefElementAfterModalClose = null
  }

  uploadDifferentFile() {
    this.closePartParameterDuplicateErrorModal()
    this.$refs.uploadPartParam.$refs.input.click()
  }

  async useExistingParameter() {
    if (!this.selectedPartParamFile) return

    // remove extention from the part parameter name
    const selectePartParameterName = this.selectedPartParamFile.name.split('.').slice(0, -1).join('.')
    const availablePartParameter = this.getPrintStrategyAdditionalInfo.availablePartParameters.find(
      (pp) => pp.text === selectePartParameterName,
    )

    this.$refs.newPartParam.reset()

    if (!availablePartParameter) {
      this.closePartParameterDuplicateErrorModal()
      return
    }

    await this.addPrintStrategyParameterSet({
      parameterSetId: availablePartParameter.value,
      printStrategyId: this.printStrategyId,
      siteId: this.siteId,
    })
    await this.fetchPrintStrategyAdditionalInfo(this.printStrategyId.toString())
    this.closePartParameterDuplicateErrorModal()
  }

  async deletePartParam(item) {
    if (this.addedParameterHasThicknessError) {
      this.removeDmlmParameterSetFromPrintStrategy(item.id)
      this.addedParameterHasThicknessError = false
    } else {
      await this.deletePrintStrategyParameterSet({
        parameterSetId: item.id,
        printStrategyId: this.printStrategyId,
        siteId: this.siteId,
      })
    }
  }

  beforeDestroy() {
    this.clearPrintStrategyState()
  }
}
