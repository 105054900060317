
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Selector from '@/components/controls/Common/Selector.vue'
import StoresNamespaces from '@/store/namespaces'
import { ItemPermissionsRole, PermissionCollaboratorViewModel } from '@/types/FileExplorer/Permission'
import { IUser } from '@/types/User/IUser'
import { getRoleId, getRoleLabel } from '@/utils/user'

const userStore = namespace(StoresNamespaces.User)

@Component({
  components: {
    Selector,
  },
})
export default class CollaboratorsListItem extends Vue {
  @userStore.Getter getUserDetails: IUser

  @Prop() collaborator: PermissionCollaboratorViewModel
  @Prop() index: number
  @Prop() canManageCollaboration: boolean
  @Prop() onChangeRole: Function
  @Prop() onRemoveCollaborator: Function
  @Prop() getAvailableRoles: Function

  selectedRole: ItemPermissionsRole = null

  get role() {
    return getRoleLabel(this.selectedRole)
  }

  set role(value) {
    this.selectedRole = getRoleId(value)
  }

  get canRemoveCollaboration() {
    const isCurrentUsersCollaboration = this.collaborator.grantedTo === this.getUserDetails.id
    return this.canManageCollaboration || isCurrentUsersCollaboration
  }

  mounted() {
    this.selectedRole = this.collaborator.role
  }

  async onChange(value) {
    await this.onChangeRole(this.collaborator, getRoleId(value))
  }
}
