
import { GeometryType, IUpdateBuildPlanItemParamsDto, PartProperty, Visibility } from '@/types/BuildPlans/IBuildPlan'
import { Component, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Selector from '@/components/controls/Common/Selector.vue'
import BodyDropdownMenu from '@/components/layout/buildPlans/addPart/BodyDropdownMenu.vue'
import Menu from '@/components/controls/Common/Menu.vue'
import StoresNamespaces from '@/store/namespaces'
import PartPropertyItemMixin from '@/components/layout/buildPlans/mixins/PartPropertyItemMixin'
import { PART_BODY_ID_DELIMITER } from '@/constants'
import { VersionablePk } from '@/types/Common/VersionablePk'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

const scrollTimeoutTime = 200

@Component({
  components: {
    BodyDropdownMenu,
    Selector,
    Menu,
  },
})
export default class PartPropertyListItem extends PartPropertyItemMixin {
  @buildPlansStore.Getter getHoverBodyId: { bpItemId: string; bodyId: string }
  @buildPlansStore.Getter isBuildPlanItemComponentVisible: (bpItemId: string, geometryId: string) => boolean
  @buildPlansStore.Action updateBuildPlanItem: (payload: {
    params: IUpdateBuildPlanItemParamsDto
    hideAPIErrorMessages?: boolean
  }) => Promise<void>

  @visualizationStore.Mutation setIsLoading: Function
  hoverBodyTimeout: NodeJS.Timeout

  isHighlightBody = false

  $refs!: {
    wrapperElement: Element
  }

  get canCopyBodyProperties(): boolean {
    if (!this.partProperties.length) {
      return false
    }
    return this.item.type !== null
  }

  get isGeometryVisible() {
    return this.isBuildPlanItemComponentVisible(this.bpItem.id, this.item.geometryId)
  }

  get geometryVisibilityTooltip() {
    return this.isGeometryVisible ? (this.$t('partVisibleTooltip') as string) : (this.$t('partHiddenTooltip') as string)
  }

  onApplyToInstancesClick() {
    this.updateSceneGeometriesOnTypeChange(this.item, { copyInstances: true })
    this.$emit('property-copy-instances', this.item)
  }

  onApplyToAllClick() {
    this.updateSceneGeometriesOnTypeChange(this.item, { copyAll: true })
    this.$emit('property-copy-all', this.item)
  }

  onBodyTypeChange(type: GeometryType) {
    const property: PartProperty = {
      ...this.item,
      type,
    }

    this.updateSceneGeometriesOnTypeChange(property)
    this.$emit('property-change', property)
    this.$emit('body-function-change', { before: this.item, after: property, bpItem: this.bpItem })
  }

  onBodyParameterSetChange(printStrategyParameterSetPk: VersionablePk) {
    const property: PartProperty = {
      ...this.item,
      printStrategyParameterSetId: printStrategyParameterSetPk.id,
      printStrategyParameterSetVersion: printStrategyParameterSetPk.version,
      type: this.item.type,
    }
    this.$emit('property-change', property)
  }

  onHoverItem(isHover: boolean) {
    this.$emit('property-hover', this.item, isHover)
  }

  /*
  toggleVisibility() {
    let hiddenBodies: string[] = this.bpItem.hiddenBodies
    // As if all bodies are hidden we store Visibility.Hidden for part and hiddenBodies = []
    // Hidden bodies for the case when part and all bodies hidden and we show one body
    if (this.bpItem.visibility === Visibility.Hidden && !this.bpItem.hiddenBodies.length) {
      hiddenBodies = this.bpItem.partProperties.map(component => component.geometryId)
    }
    
    ...

    this.setGeometryVisibility({ items, visibility: !isVisible, saveVisibility: true })
    hiddenBodies = this.updateHiddenBodies(hiddenBodies, makeVisible)
    })

    if (!this.isPrintOrder) {
      this.updateBuildPlanItem({
        params: {
          hiddenBodies,
          visibility: bpItemVisibility,
          buildPlanItemId: this.bpItem.id,
        },
      })
    }
  }
  */

  @Watch('getHoverBodyId')
  scrollToItem() {
    clearTimeout(this.hoverBodyTimeout)

    this.isHighlightBody = false

    if (
      (!this.bpItem || this.bpItem.id === this.getHoverBodyId.bpItemId) &&
      this.item.geometryId === this.getHoverBodyId.bodyId
    ) {
      this.isHighlightBody = true
      this.hoverBodyTimeout = setTimeout(() => {
        this.$refs.wrapperElement.scrollIntoView({ behavior: 'smooth' })
      }, scrollTimeoutTime)
    }
  }

  get hasSameInstances() {
    const sourceBodyId: string = this.item.geometryId.split(PART_BODY_ID_DELIMITER)[1]

    return this.partProperties.some((targetProperty) => {
      const targetBodyId: string = targetProperty.geometryId.split(PART_BODY_ID_DELIMITER)[1]

      return targetBodyId === sourceBodyId && this.item.id !== targetProperty.id
    })
  }

  get isDisabledApplyToAllInstances() {
    return !this.hasSameInstances || this.disabled
  }

  get isDisabledApplyToAllBodies() {
    return !(this.partProperties.length > 1) || this.disabled
  }

  /**
   * Add or remove current geometry id from hiddenBodies list.
   * @param hiddenBodies Hidden geometry ids.
   * @param isVisible Does current geometry become visible.
   * @returns Updated hiddenBodies.
   */
  private updateHiddenBodies(hiddenBodies: string[], isVisible: boolean) {
    if (isVisible) {
      const bodyIndex = hiddenBodies.findIndex((geometryId) => geometryId === this.item.geometryId)
      if (bodyIndex !== -1) {
        hiddenBodies.splice(bodyIndex, 1)
      }
    } else {
      hiddenBodies.push(this.item.geometryId)
    }

    return hiddenBodies
  }
}
