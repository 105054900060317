
import StoresNamespaces from '@/store/namespaces'
import { CollectorWithOptionalFilter, MultiItemCollector } from '@/types/OptionalMultiItemCollector/MultiItemCollector'
import { CollectorItem } from '@/visualization/rendering/Collector'
import { Color3 } from '@babylonjs/core/Maths'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { InputOutsideMixin } from '@/components/layout/mixins/InputOutsideMixin'

const commonStore = namespace(StoresNamespaces.Common)
const optionalMultiItemCollectorStore = namespace(StoresNamespaces.OptionalMultiItemCollector)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component
export default class OptionalMultiItemCollector extends Mixins(InputOutsideMixin) {
  @Prop() selectionColor: Color3
  @Prop() filter: (item: CollectorItem) => boolean
  @Prop() label: string
  @Prop() collector: MultiItemCollector
  @Prop({ default: false }) disabled: boolean

  @commonStore.Getter tooltipOpenDelay: number

  @optionalMultiItemCollectorStore.Action createCollector: (collector: CollectorWithOptionalFilter) => void

  @optionalMultiItemCollectorStore.Mutation setActiveCollector: (id: string) => void
  @optionalMultiItemCollectorStore.Mutation deselectAllCollectorItems: (id: string) => void
  @optionalMultiItemCollectorStore.Mutation disposeCollectors: () => void

  @optionalMultiItemCollectorStore.Getter isCollectorActive: (id: string) => boolean
  @optionalMultiItemCollectorStore.Getter getCollectorById: (id: string) => MultiItemCollector

  @visualizationStore.Getter isMouseOverCanvas: boolean

  beforeMount() {
    this.createCollector({ ...this.collector, filter: this.filter })
  }

  get numberOfSelectedItem() {
    return this.currentCollector ? this.currentCollector.items.length : 0
  }

  get numberOfSelectedLabel() {
    return `${this.numberOfSelectedItem} ${this.$t('optionalItemCollector.selected')}`
  }

  get currentCollector() {
    return this.collector ? this.getCollectorById(this.collector.id) : null
  }

  get isActive() {
    return this.collector ? this.isCollectorActive(this.collector.id) : false
  }

  onCollectorClick() {
    if (this.isActive) {
      this.deactivate()
    } else {
      this.setActiveCollector(this.collector.id)
    }
  }

  deactivate() {
    this.setActiveCollector(null)
  }

  @Watch('isActive')
  onActivation() {
    this.setListenerForInputOutside(this.isActive, 'multi-item-collector-wrapper', () => {
      if (!this.isMouseOverCanvas) {
        this.deactivate()
      }
    })
  }

  beforeDestroy() {
    if (this.isActive) {
      this.deactivate()
    }

    this.disposeCollectors()
  }
}
