/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera'
import { Mesh, MeshBuilder } from '@babylonjs/core/Meshes'
import { Scene } from '@babylonjs/core/scene'
import { ArcRotateCameraPointersInput } from '@babylonjs/core/Cameras/Inputs/arcRotateCameraPointersInput'
import { Epsilon, Frustum, Vector2, Vector3, Viewport } from '@babylonjs/core/Maths'
import { CameraView } from '@/visualization/models/CameraView'
import {
  DEFAULT_CAMERA_RADIUS,
  WORLD_EXTENDS_EXCLUDED_MESHES,
  MouseButtons,
  MOUSE_BUTTON,
  ZOOM_CAMERA_NAME,
  PointerType,
} from '@/constants'
import { PointerEventTypes, PointerInfo } from '@babylonjs/core/Events/pointerEvents'
import { MeshManager } from '../rendering/MeshManager'
import { Observer } from '@babylonjs/core/Misc/observable'
import { IRenderable } from '../types/IRenderable'

export class OrthoCamera extends ArcRotateCamera {
  defaultCameraView: CameraView
  minOrthoPlane: number
  maxOrthoPlane: number
  aspect: number
  panSpeed: number
  isViewLocked: boolean
  isRotationStarted: boolean
  defaultAlpha: number
  defaultBeta: number
  defaultRadius: number
  centerTarget: Vector3
  initCenterTargetScreen: Vector2
  worldExtendsMultiplier: number
  zoomTarget: Vector3
  private canvas: HTMLCanvasElement
  private scene: Scene
  private renderScene: IRenderable
  private meshManager: MeshManager
  private zoomCamera: ArcRotateCamera
  private changeTargetObserver: Observer<PointerInfo>

  constructor(
    name: string,
    viewport: Viewport,
    scene: Scene,
    canvas: HTMLCanvasElement,
    renderScene: IRenderable,
    alpha: number = Math.PI / 4,
    beta: number = Math.PI / 3,
    worldExtendsMultiplier: number = 1,
  ) {
    const worldExtends = scene.getWorldExtends((mesh) => {
      return mesh.visibility === 1 && !WORLD_EXTENDS_EXCLUDED_MESHES.includes(mesh.name)
    })
    const target = Mesh.Center(worldExtends)
    let radius = worldExtends.max.subtract(worldExtends.min).length() * worldExtendsMultiplier
    radius = Number.isFinite(radius) ? radius : DEFAULT_CAMERA_RADIUS
    const nearCoefficient = 10
    super(name, alpha, beta, radius, target, scene)
    this.defaultAlpha = Number.isFinite(alpha) ? alpha : Math.PI / 4
    this.defaultBeta = Number.isFinite(beta) ? beta : Math.PI / 3
    this.defaultRadius = radius
    this.centerTarget = target
    this.worldExtendsMultiplier = worldExtendsMultiplier
    // set area for the camera
    this.viewport = viewport
    this.canvas = canvas
    this.scene = scene
    this.renderScene = renderScene
    this.meshManager = renderScene.getMeshManager()
    this.initCenterTargetScreen = this.meshManager.project3DPointOntoScreen(this.centerTarget, this)

    this.mode = ArcRotateCamera.ORTHOGRAPHIC_CAMERA
    this.lowerBetaLimit = 0
    this.upperBetaLimit = Math.PI
    this.isViewLocked = false
    this.minZ = -radius * nearCoefficient
    this.maxZ = radius * nearCoefficient
    this.wheelPrecision = 100
    // Use engine rendering canvas size, because it contains width and height in double
    // instead of canvas width and height (in int). Or if you change it, also take care of
    // other places where aspect is uses for changing camera view
    const rect = this.getEngine().getRenderingCanvasClientRect()
    // For the orthographic camera mode we need to set the left, right, bottom and
    // top boundaries. Usually you'll want to account for the aspect ratio of the
    // renderer canvas.
    this.aspect = rect.height / rect.width
    this.lowerRadiusLimit = radius
    this.upperRadiusLimit = radius
    this.minOrthoPlane = radius / 1000
    this.maxOrthoPlane = 2 * radius
    this.orthoLeft = -radius
    this.orthoRight = radius
    this.orthoTop = radius * this.aspect
    this.orthoBottom = -radius * this.aspect
    this.panningSensibility = canvas.width / (4 * this.orthoTop)
    this.panningInertia = 0
    this.inertia = 0
    this.angularSensibilityX = 250
    this.angularSensibilityY = 250
    // also set pan/zoom speed here
    this.panSpeed = 0.1
    // update camera's up vector to simulate Z axis looking to the top instead of the default Z axis position in Babylon
    this.upVector = new Vector3(0, 0, 1)
    // save default camera orientation
    this.defaultCameraView = new CameraView(this)
    // attach the camera to the canvas
    this.attachControl(canvas, true)

    this.newTargetCallback = this.newTargetCallback.bind(this)
    this.repositionCallback = this.repositionCallback.bind(this)
    this.changeTargetCallback = this.changeTargetCallback.bind(this)

    // zoom-to-mouse target
    const engine = this.scene.getEngine()
    this.zoomTarget = target
    scene.onPointerObservable.add((evt) => {
      if (evt.type === PointerEventTypes.POINTERMOVE && (evt.event as PointerEvent).pointerType === PointerType.MOUSE) {
        this.zoomTarget = Vector3.Unproject(
          new Vector3(this.scene.pointerX, this.scene.pointerY, 0),
          engine.getRenderWidth(),
          engine.getRenderHeight(),
          this.getWorldMatrix(),
          this.getViewMatrix(),
          this.getProjectionMatrix(),
        )
      }
    })
  }

  turnOff() {
    this.minZ = 0
    this.maxZ = 0
  }

  reset() {
    if (this.isViewLocked) {
      return
    }
    // restore default camera orientation
    this.orthoLeft = this.defaultCameraView.left
    this.orthoRight = this.defaultCameraView.right
    this.orthoTop = this.defaultCameraView.top
    this.orthoBottom = this.defaultCameraView.bottom
    this.alpha = this.defaultCameraView.alpha
    this.beta = this.defaultCameraView.beta
    this.target = this.defaultCameraView.target.clone()
    this.panningSensibility = this.canvas.width / (4 * this.orthoTop)
  }

  pan(deltaY: number, shiftKey: boolean) {
    const totalY = Math.abs(this.orthoTop - this.orthoBottom)
    const panSpeed = shiftKey ? 0.5 * this.panSpeed : this.panSpeed
    const delta = deltaY > 0 ? -panSpeed : panSpeed
    const adjustedOrthoLeft = this.orthoLeft - (this.orthoLeft - this.zoomTarget.x) * delta
    const adjustedOrthoRight = this.orthoRight - (this.orthoRight - this.zoomTarget.x) * delta

    if (this.zoomTarget && adjustedOrthoRight - adjustedOrthoLeft > 0) {
      this.orthoLeft = adjustedOrthoLeft
      this.orthoRight = adjustedOrthoRight
      this.orthoTop -= (this.orthoTop - this.zoomTarget.y) * delta
      this.orthoBottom -= (this.orthoBottom - this.zoomTarget.y) * delta
      // decrease pan sensitivity the closer we "zoom"
      this.panningSensibility = this.canvas.width / (4 * totalY)

      if (this.zoomCamera) {
        this.zoomCamera.orthoLeft = this.orthoLeft
        this.zoomCamera.orthoRight = this.orthoRight
        this.zoomCamera.orthoTop = this.orthoTop
        this.zoomCamera.orthoBottom = this.orthoBottom
        this.initCenterTargetScreen = this.meshManager.project3DPointOntoScreen(this.centerTarget, this.zoomCamera)
      }
    }
  }

  repositionCamera(scene: Scene, updatePointer?: boolean) {
    if (this.isViewLocked) {
      return
    }

    this.adjustCamera(scene, this.repositionCallback, updatePointer)
  }

  setViewLocked(value: boolean) {
    this.isViewLocked = value
    if (this.isViewLocked) {
      this.lowerAlphaLimit = this.upperAlphaLimit = this.alpha
      this.lowerBetaLimit = this.upperBetaLimit = this.beta
    } else {
      this.lowerAlphaLimit = this.upperAlphaLimit = null
      this.lowerBetaLimit = 0
      this.upperBetaLimit = Math.PI
    }
  }

  setNewTarget(scene: Scene) {
    this.adjustCamera(scene, this.newTargetCallback)
  }

  togglePointerInput(input: MouseButtons, attach: boolean) {
    const pointers = this.inputs.attached.pointers as ArcRotateCameraPointersInput
    if (attach) {
      if (pointers.buttons.findIndex((val) => val === input) === -1) {
        this.detachControl()
        pointers.buttons.push(input)
        this.attachControl(this.canvas, true)
      }
    } else {
      const index = pointers.buttons.findIndex((val) => val === input)
      if (index !== -1) {
        this.detachControl()
        pointers.buttons.splice(index, 1)
        this.attachControl(this.canvas, true)
      }
    }
  }

  addChangeTargetObserver() {
    this.zoomCamera = new ArcRotateCamera(ZOOM_CAMERA_NAME, 0, 0, 0, Vector3.Zero(), this.scene)
    this.zoomCamera.mode = this.mode
    this.zoomCamera.upVector = this.upVector
    this.zoomCamera.target = this.target.clone()
    this.zoomCamera.position = this.position
    this.zoomCamera.orthoLeft = this.orthoLeft
    this.zoomCamera.orthoRight = this.orthoRight
    this.zoomCamera.orthoTop = this.orthoTop
    this.zoomCamera.orthoBottom = this.orthoBottom
    this.zoomCamera.alpha = this.alpha
    this.zoomCamera.beta = this.beta
    this.zoomCamera.detachControl()
    if (!this.changeTargetObserver) {
      // Prevent adding the same callback multiple times
      this.changeTargetObserver = this.scene.onPointerObservable.add((pointerInfo) =>
        this.changeTargetCallback(pointerInfo),
      )
    }
  }

  removeChangeTargetObserver() {
    if (this.changeTargetObserver) {
      this.scene.onPointerObservable.remove(this.changeTargetObserver)
      this.changeTargetObserver = undefined
    }

    if (this.zoomCamera) {
      this.zoomCamera.dispose()
    }
  }

  private adjustCamera(scene: Scene, adjustCallback: Function, updatePointer?: boolean) {
    const worldExtends = scene.getWorldExtends((mesh) => {
      return mesh.visibility === 1 && !WORLD_EXTENDS_EXCLUDED_MESHES.includes(mesh.name)
    })

    let radius = worldExtends.max.subtract(worldExtends.min).length() * this.worldExtendsMultiplier
    radius = this.defaultRadius = Number.isFinite(radius) ? radius : DEFAULT_CAMERA_RADIUS
    const nearCoefficient = 10

    this.lowerRadiusLimit = radius
    this.upperRadiusLimit = radius

    if (!this.isViewLocked) {
      this.lowerBetaLimit = 0
      this.upperBetaLimit = Math.PI
    }

    this.minZ = -radius * nearCoefficient
    this.maxZ = radius * nearCoefficient
    // Use engine rendering canvas size, because it contains width and height in double
    // instead of canvas width and height (in int). Or if you change it, also take care of
    // other places where aspect is uses for changing camera view
    const rect = this.getEngine().getRenderingCanvasClientRect()
    // For the orthographic camera mode we need to set the left, right, bottom and
    // top boundaries. Usually you'll want to account for the aspect ratio of the
    // renderer canvas.
    this.aspect = rect.height / rect.width

    if (isNaN(this.aspect)) {
      this.aspect = this.canvas.height / this.canvas.width
    }

    this.minOrthoPlane = radius / 1000
    this.maxOrthoPlane = 2 * radius
    this.orthoLeft = -radius
    this.orthoRight = radius
    this.orthoTop = radius * this.aspect
    this.orthoBottom = -radius * this.aspect

    const params = {
      radius,
      updatePointer,
      cameraTarget: Mesh.Center(worldExtends),
      orthoLeft: this.orthoLeft,
      orthoRight: this.orthoRight,
      orthoTop: this.orthoTop,
      orthoBottom: this.orthoBottom,
      alpha: this.alpha,
      beta: this.beta,
    }

    adjustCallback(params)
    this.panningSensibility = this.canvas.width / (4 * this.orthoTop)

    // save default camera orientation
    this.defaultCameraView = new CameraView(this)
    // attach the camera to the canvas
    this.attachControl(this.canvas, true)
  }

  private repositionCallback(params) {
    if (!params.updatePointer) {
      this.orthoTop -= this.orthoTop - params.orthoTop
      this.orthoBottom -= this.orthoBottom - params.orthoBottom
      this.orthoLeft -= this.orthoLeft - params.orthoLeft
      this.orthoRight -= this.orthoRight - params.orthoRight
    } else {
      this.target = params.cameraTarget
      this.centerTarget = params.cameraTarget.clone()
      this.setPosition(this.target.add(new Vector3(params.radius, -params.radius, params.radius)))
    }
    this.alpha = params.alpha
    this.beta = params.beta
    this.initCenterTargetScreen = this.meshManager.project3DPointOntoScreen(this.centerTarget, this)
  }

  private newTargetCallback(params) {
    const formerTarget = this.target
    const formerPosition = this.position
    this.target = params.cameraTarget
    this.centerTarget = params.cameraTarget.clone()
    this.targetScreenOffset.x = 0
    this.targetScreenOffset.y = 0

    if (Number.isNaN(formerPosition.length())) {
      this.setPosition(this.target.add(new Vector3(params.radius, -params.radius, params.radius)))
    } else {
      // If camera has a position already - set new position along the previous To-Target vector
      const position = formerPosition.subtract(formerTarget)
      position.normalize()
      this.setPosition(this.target.add(position.multiplyByFloats(params.radius, params.radius, params.radius)))
    }

    if (this.zoomCamera) {
      this.zoomCamera.target = this.target.clone()
      this.zoomCamera.position = this.position
      this.zoomCamera.orthoLeft = this.orthoLeft
      this.zoomCamera.orthoRight = this.orthoRight
      this.zoomCamera.orthoTop = this.orthoTop
      this.zoomCamera.orthoBottom = this.orthoBottom
    }

    this.alpha = params.alpha
    this.beta = params.beta

    this.initCenterTargetScreen = this.meshManager.project3DPointOntoScreen(this.centerTarget, this)
  }

  private changeTargetCallback(pointerInfo) {
    if (
      pointerInfo.type === PointerEventTypes.POINTERDOWN &&
      (pointerInfo.event as PointerEvent).pointerType === PointerType.MOUSE
    ) {
      if (pointerInfo.event.button !== MOUSE_BUTTON.Middle) {
        return
      }

      this.isRotationStarted = true
    }

    if (
      pointerInfo.type === PointerEventTypes.POINTERMOVE &&
      (pointerInfo.event as PointerEvent).pointerType === PointerType.MOUSE
    ) {
      if (!this.isRotationStarted) {
        return
      }

      this.isRotationStarted = false
      const isVisualizationCamera = this.scene !== this.renderScene.getScene()
      const pickInfo = this.scene.pick(
        this.scene.pointerX,
        this.scene.pointerY,
        !isVisualizationCamera
          ? (mesh) => this.meshManager.isSceneItem(mesh) && !this.meshManager.isOverhangMesh(mesh)
          : null,
      )

      let newTarget: Vector3
      let isHit: boolean = true
      if (pickInfo.hit) {
        newTarget = pickInfo.pickedPoint
      } else {
        const meshes = this.scene.meshes.filter(
          (m) => this.meshManager.isSceneItem(m) && !this.meshManager.isOverhangMesh(m),
        )
        const binfo = this.meshManager.getTotalBoundingInfo(meshes)

        const frustum = Frustum.GetPlanes(this.getTransformationMatrix())
        if (!binfo.boundingSphere.isCenterInFrustum(frustum)) {
          newTarget = this.renderScene.getGpuPicker().getNearestCameraTarget(this.scene.pointerX, this.scene.pointerY)
          if (!newTarget) {
            newTarget = this.centerTarget
            isHit = false
          }
        } else {
          newTarget = binfo.boundingSphere.centerWorld.clone()
        }
      }

      const scaleWidth = (this.orthoRight - this.orthoLeft) / this.canvas.width
      const scaleHeight = (this.orthoTop - this.orthoBottom) / this.canvas.height
      const newTargetScreen = this.meshManager.project3DPointOntoScreen(newTarget, this)
      const newCenterTargetScreen = this.meshManager.project3DPointOntoScreen(this.centerTarget, this)
      const newRadius = Vector3.Distance(newTarget, this.position)

      const alpha = this.alpha
      const beta = this.beta
      this.target = newTarget
      if (!isHit) {
        this.centerTarget = newTarget.clone()
      }

      this.radius = newRadius
      this.alpha = alpha
      this.beta = beta
      const xOffset = (newTargetScreen.x - newCenterTargetScreen.x) * scaleWidth
      const yOffset = (newCenterTargetScreen.y - newTargetScreen.y) * scaleHeight

      if (
        Math.abs(this.initCenterTargetScreen.x - newCenterTargetScreen.x) < Epsilon &&
        Math.abs(this.initCenterTargetScreen.y - newCenterTargetScreen.y) < Epsilon
      ) {
        this.targetScreenOffset.x = xOffset
        this.targetScreenOffset.y = yOffset
      } else {
        const xOffsetByPosition = (newCenterTargetScreen.x - this.initCenterTargetScreen.x) * scaleWidth
        const yOffsetByPosition = (this.initCenterTargetScreen.y - newCenterTargetScreen.y) * scaleHeight
        this.targetScreenOffset.x = xOffset + xOffsetByPosition
        this.targetScreenOffset.y = yOffset + yOffsetByPosition
      }
    }

    if (
      pointerInfo.type === PointerEventTypes.POINTERUP &&
      (pointerInfo.event as PointerEvent).pointerType === PointerType.MOUSE
    ) {
      if (pointerInfo.event.button !== MOUSE_BUTTON.Middle) {
        return
      }

      this.isRotationStarted = false
    }
  }
}
