
import Vue from 'vue'
import Component from 'vue-class-component'
import BJPrintStrategy from './BJPrintStrategy.vue'
import { namespace } from 'vuex-class'
import { MachineType } from '@/types/Sites/Site'
import StoresNamespaces from '@/store/namespaces'
import { PrintingTypes } from '@/types/IMachineConfig'
import PrintStrategy from './PrintStrategy.vue'
import messageService from '@/services/messageService'

const sitesStore = namespace(StoresNamespaces.Sites)
@Component
export default class PrintStrategyContainer extends Vue {
  @sitesStore.Getter getSelectedMachineType: MachineType

  get getPrintStrategyComponent() {
    if (this.getSelectedMachineType) {
      switch (this.getSelectedMachineType.modality) {
        case PrintingTypes.DMLM:
          return PrintStrategy
        case PrintingTypes.BinderJet:
          return BJPrintStrategy
        case PrintingTypes.EBM:
          messageService.showErrorMessage('EBM print strategies not supported yet')
          return
        default:
          messageService.showErrorMessage('Print strategy printing type is not DMLM, or BJ, or EBM')
          return
      }
    }
    return
  }
}
