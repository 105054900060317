import { IGPUInfo } from '@/types/IGPUInfo'

export function getGPUInfo() {
  const canvas = document.createElement('canvas')
  let gl = null
  const result: IGPUInfo = {
    isWebGLEnabled: false,
    vendor: '',
    renderer: '',
    isIntegratedGPU: true,
  }

  try {
    gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
  } catch (e) {
    console.log(e)
  }

  if (gl) {
    result.isWebGLEnabled = true
    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info')
    result.vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL)
    result.renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
    result.isIntegratedGPU = result.renderer.toLowerCase().indexOf('intel') >= 0
  }

  return result
}
