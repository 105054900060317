type tfactors = {
  [key: string]: number
}
const factors: tfactors = {
  m: 1,
  cm: 100,
  mm: 1000,
  km: 0.001,
  in: 39.37,
}

const NOT_AVAILABLE_UNIT = 'n/a'

export function convert(fromUnit: string, toUnit: string, value: number): number {
  if (toUnit === NOT_AVAILABLE_UNIT) {
    return value
  }

  const fromFactor = getUnitFactor(fromUnit)
  const toFactor = getUnitFactor(toUnit)

  if (toFactor === fromFactor) {
    return value
  }

  return (value * toFactor) / fromFactor
}

function getUnitFactor(unit: string) {
  const factor = factors[unit]
  if (!factor) {
    throw new Error(`"${unit}" not supported by the converter`)
  }
  return factor
}
