import { IAdapter } from '@/types/Common/IAdapter'
import { CostEvaluationResultViewModel } from '@/types/CostEstimations/ICostEstimation'
import average from '@/utils/arithmetic/average'

export class EBMResultAdapter implements IAdapter<CostEvaluationResultViewModel> {
  adapt(item: any): CostEvaluationResultViewModel {
    const result = new CostEvaluationResultViewModel()

    // TODO Need to fix min/max values
    const {
      buildMaterialCost,
      solidMaterialWaste,
      powderMaterialWaste,
      costOfPowderReuse,
      heliumGas,
      electricity,
      machineConsumables,
      msaAllocation,
    } = item.costOfOperating

    result.quantityOfMachinesRequired = Math.ceil(item.machineBuildData.quantityOfMachinesRequired)

    // Build Time
    result.machineBuildTime = item.machineBuildData.machineBuildTimeHours
    result.machineBuildTimeMin = item.machineBuildData.machineBuildTimeHours
    result.machineBuildTimeMax = item.machineBuildData.machineBuildTimeHours

    // TODO Need to add number of parts per build
    // result.partsPerBuild = ?

    result.complexMaterialCostMin = buildMaterialCost + solidMaterialWaste + powderMaterialWaste + costOfPowderReuse
    result.complexMaterialCostMax = buildMaterialCost + solidMaterialWaste + powderMaterialWaste + costOfPowderReuse
    result.complexMaterialCostAverage = average(result.complexMaterialCostMin, result.complexMaterialCostMax)

    // TODO should we add here msaAllocatio? EBM machine calls this Operating expenses and adds,
    // however DMLM machine cals this Consumables and don`t add it
    result.consumablesMin = machineConsumables + heliumGas + electricity
    result.consumablesMax = machineConsumables + heliumGas + electricity
    result.consumablesAverage = average(result.consumablesMin, result.consumablesMax)

    result.maintenance = msaAllocation || 0

    result.printerMin = item.straightLineDepreciation.depExpensePerPart
    result.printerMax = item.straightLineDepreciation.depExpensePerPart
    result.printerAverage = average(result.printerMin, result.printerMax)

    result.totalPrintProcessCostMin =
      result.complexMaterialCostMin + result.consumablesMin + result.maintenance + result.printerMin
    result.totalPrintProcessCostMax =
      result.complexMaterialCostMax + result.consumablesMax + result.maintenance + result.printerMax
    result.totalPrintProcessCostAverage = average(result.totalPrintProcessCostMin, result.totalPrintProcessCostMax)

    return result
  }
}
