import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { DetailsPanelViewMode } from '@/types/FileExplorer/ViewMode'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { Permission } from '@/types/FileExplorer/Permission'
import { ItemType } from '@/types/FileExplorer/ItemType'
import { ItemDetailsDto, ItemDetailsType } from '@/types/FileExplorer/ItemDetails'
import {
  DetailsBuildPlan,
  DetailsFolder,
  DetailsManageCollaborators,
  DetailsPart,
  DetailsPrintOrder,
  DetailsIbcPlan,
} from '@/components/layout/FileExplorer/Details'

import StoresNamespaces from '@/store/namespaces'
import { isOfType } from '@/utils/common'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { ItemDetailsPayload } from '@/store/modules/fileExplorer/types'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component
export default class DetailsPanelMixin extends Vue {
  @fileExplorerStore.Action fetchItemDetails: (payload: ItemDetailsPayload) => Promise<ItemDetailsDto>

  @fileExplorerStore.Getter('getItemDetails') itemDetails: ItemDetailsType
  @fileExplorerStore.Getter getSelectedItems: FileExplorerItem[]
  @fileExplorerStore.Getter permissionsByItemId: Record<string, Permission[]>
  @fileExplorerStore.Getter getRootItem: FileExplorerItem
  @fileExplorerStore.Getter getSelectedItem: FileExplorerItem
  @fileExplorerStore.Getter find: (itemId: string) => FileExplorerItem

  @fileExplorerStore.Mutation setDetailsPanelMode: (mode: DetailsPanelViewMode) => void
  @fileExplorerStore.Mutation setItemDetails: (itemDetails: ItemDetailsDto) => void

  @fileExplorerStore.State detailsPanelMode: DetailsPanelViewMode
  @fileExplorerStore.State isOpenDetailsPanel: boolean

  get permissions(): Permission[] {
    return this.getSelectedItems.length === 1 ? this.permissionsByItemId[this.getSelectedItems[0].id] : []
  }

  get detailsComponent() {
    switch (this.detailsPanelMode) {
      case DetailsPanelViewMode.Part:
        return DetailsPart
      case DetailsPanelViewMode.BuildPlan:
        return DetailsBuildPlan
      case DetailsPanelViewMode.IbcPlan:
        return DetailsIbcPlan
      case DetailsPanelViewMode.ManageCollaborators:
        return DetailsManageCollaborators
      case DetailsPanelViewMode.Folder:
        return DetailsFolder
      case DetailsPanelViewMode.PrintOrder:
        return DetailsPrintOrder
      default:
        return null
    }
  }

  get detailsProps() {
    switch (this.detailsPanelMode) {
      case DetailsPanelViewMode.Part:
        return {
          item: this.getSelectedItems[0],
          itemDetails: this.itemDetails,
        }
      case DetailsPanelViewMode.BuildPlan: {
        const item =
          this.getSelectedItems[0] && this.getSelectedItems[0].id === this.itemDetails.id
            ? this.getSelectedItems[0]
            : this.find(this.itemDetails.id)
        return {
          item: this.checkItemForTypeMatch(item, ItemType.BuildPlan),
          itemDetails: this.itemDetails,
          // TODO: Add print orders
          // orders: null,
        }
      }
      case DetailsPanelViewMode.IbcPlan: {
        const ibcItem = this.find(this.itemDetails.id)
        return {
          item: this.checkItemForTypeMatch(ibcItem, ItemType.IbcPlan),
          itemDetails: this.itemDetails,
        }
      }

      case DetailsPanelViewMode.Folder:
        return {
          item: this.getSelectedItems[0] || this.getRootItem,
          itemDetails: this.itemDetails,
        }
      case DetailsPanelViewMode.ManageCollaborators:
        return {
          item: this.getSelectedItems[0],
        }
      case DetailsPanelViewMode.PrintOrder:
        return {
          // TODO: Add Print Order
          order: null,
        }
      default:
        return null
    }
  }

  get detailsPanelTitle() {
    switch (this.detailsPanelMode) {
      case DetailsPanelViewMode.ManageCollaborators:
        return 'Manage Collaborators'
      default:
        return
    }
  }

  defineDetailsViewMode() {
    if (!this.getSelectedItem || this.getSelectedItems.length > 1) {
      this.setDetailsPanelMode(DetailsPanelViewMode.Default)
      return
    }

    const item = this.getSelectedItem

    if (item.itemType === ItemType.BuildPart) {
      this.setDetailsPanelMode(DetailsPanelViewMode.Part)
    }

    if (item.itemType === ItemType.BuildPlan) {
      this.setDetailsPanelMode(DetailsPanelViewMode.BuildPlan)
    }

    if (item.itemType === ItemType.IbcPlan) {
      this.setDetailsPanelMode(DetailsPanelViewMode.IbcPlan)
    }

    if (item.itemType === ItemType.Folder) {
      this.setDetailsPanelMode(DetailsPanelViewMode.Folder)
    }

    if (item.itemType === ItemType.PrintOrder || isOfType<PrintOrder>(item, 'site')) {
      this.setDetailsPanelMode(DetailsPanelViewMode.PrintOrder)
    }
  }

  async variantChanged(v) {
    const itemDetails = await this.fetchItemDetails({
      itemId: v.id,
      itemType: v.itemType,
      silent: true,
    })

    if (!itemDetails) {
      return
    }

    const { item, details } = itemDetails

    this.setItemDetails({ item, details })

    if (item.itemType === ItemType.BuildPlan) {
      this.setDetailsPanelMode(DetailsPanelViewMode.BuildPlan)
    } else {
      this.setDetailsPanelMode(DetailsPanelViewMode.IbcPlan)
    }
  }

  private checkItemForTypeMatch(item: FileExplorerItem, targetType: ItemType): FileExplorerItem | null {
    if (item && item.itemType === targetType) {
      return item
    }

    return null
  }
}
