const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) {
    return { value: 0, units: sizes[0] }
  }

  const factor = 1024
  const dm = decimals < 0 ? 0 : decimals

  const index = Math.floor(Math.log(bytes) / Math.log(factor))

  return {
    value: parseFloat((bytes / Math.pow(factor, index)).toFixed(dm)),
    units: sizes[index],
  }
}
