export type SortParam = {
  field: string
  order?: SortOrders
}

export type SortParamsMap = {
  [key: string]: SortOrders
}

export enum SortOrders {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export enum SortModes {
  ProjNameAscending = 'proj-name-ascending',
  ProjNameDescending = 'proj-name-descending',
  PartCostAscending = 'part-cost-ascending',
  PartCostDescending = 'part-cost-descending',
}
