import { Component, Vue } from 'vue-property-decorator'
import { extend } from 'vee-validate'
import i18n from '@/plugins/i18n'

const RESERVED_NAMES = [
  'CON',
  'PRN',
  'AUX',
  'NUL',
  'COM1',
  'COM2',
  'COM3',
  'COM4',
  'COM5',
  'COM6',
  'COM7',
  'COM8',
  'COM9',
  'LPT1',
  'LPT2',
  'LPT3',
  'LPT4',
  'LPT5',
  'LPT6',
  'LPT7',
  'LPT8',
  'LPT9',
]

@Component
export default class CreateAmpItemModalMixin extends Vue {
  addSpecialCharacterVerificationRule() {
    extend('noSpecials', {
      validate: (value: string) => {
        const regularExpression = new RegExp('[\\\\/:*?“”<>"|]')
        return !regularExpression.test(value)
      },
      message: i18n.t('specialCharactersError').toString(),
    })
  }

  addReservedNamesVerificationRule() {
    extend('noReservedNames', {
      validate: (value: string) => {
        return !RESERVED_NAMES.includes(value.toUpperCase())
      },
      params: ['name'],
      message: i18n.t('reservedNamesError').toString(),
    })
  }
}
