
import Component from 'vue-class-component'
import CadHelperRunner from '@/components/layout/CadHelperRunner.vue'
import { CadHelperProcess } from '@/constants'
import { EndpointsUrls } from '@/configs/config'
import { CadHelperLink, CadHelperLinkParameters } from '@/types/CadHelper/CadHelperLink'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { IUser } from '@/types/User/IUser'
import { IbcMeasurementDeleteDto, IIBCPlan, IMeasurementWithProperties } from '@/types/IBCPlans/IIBCPlan'
import { Mixins } from 'vue-property-decorator'
import CadHelperLinkUpdateMixin from '@/components/layout/FileExplorer/Table/mixins/CadHelperLinkUpdateMixin'
import Button from '@/components/controls/Common/Button.vue'
import { IIBCDisplayToolbarState, Visibility } from '@/types/BuildPlans/IBuildPlan'
import TextArea from '@/components/controls/Common/TextArea.vue'
import NumberField from '@/components/controls/Common/NumberField.vue'
import DownloadCadHelperModal from '@/components/layout/DownloadCadHelperModal.vue'
import { IJob, JobStatusCode } from '@/types/PartsLibrary/Job'
import { BrokerMessage } from '@/types/Common/BrokerMessage'
import { BrokerEvents } from '@/types/Common/BrokerEvents'

const cadHelperStore = namespace(StoresNamespaces.CadHelper)
const partsStore = namespace(StoresNamespaces.Parts)
const userStore = namespace(StoresNamespaces.User)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const commonStore = namespace(StoresNamespaces.Common)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component({
  components: {
    NumberField,
    TextArea,
    Button,
    CadHelperRunner,
    DownloadCadHelperModal,
  },
})
export default class IBCPlanInspectionTab extends Mixins(CadHelperLinkUpdateMixin) {
  @cadHelperStore.Getter('getDownloadLink') getDownloadLinkOfCadHelper: string
  @cadHelperStore.Getter('getVersion') getVersionOfCadHelper: string

  @partsStore.Action('getPartToken') getPartToken: any

  @userStore.Getter getUserDetails: IUser

  @buildPlansStore.Action deleteMeasurements: (payload: {
    measurements: IMeasurementWithProperties[]
    ibcPlanId: string
  }) => void
  @buildPlansStore.Action setIbcPlanItemMeasurement: (measurement: IMeasurementWithProperties) => void

  @buildPlansStore.Getter getIBCPlan: IIBCPlan
  @buildPlansStore.Getter getIbcPlanJobs: IJob[]
  @buildPlansStore.Getter ibcDisplayToolbarStateByVariantId: (ibcPlanId: string) => IIBCDisplayToolbarState
  @buildPlansStore.Getter isLockedForUser: boolean
  @buildPlansStore.Getter isLockedForViewer: boolean

  @commonStore.Getter tooltipOpenDelay: number

  @visualizationStore.Action toggleIBCMeasurment
  @visualizationStore.Action highlightIBCMeasurement

  $refs: {
    upload: HTMLElement
  }
  partToken: string = null
  updatingMeasurements = []
  downloadCadHelperDialogVisible: boolean = false

  async mounted() {
    const { token } = await this.getPartToken()
    this.partToken = token
    await this.fetchLinks()
  }

  get cadHelperLink() {
    const command = CadHelperProcess.UploadMeasurementData
    const apiUrl = window.env.VUE_APP_API_URL
    const signedUrl = `${apiUrl}/${EndpointsUrls.IBCPlans}/${this.getIBCPlan.id}/uploadMeasurements`

    const downloadUrl = this.getDownloadLinkOfCadHelper
    const token = this.partToken
    const cadHelperVersion = this.getVersionOfCadHelper
    const sessionId = this.getUserDetails.id
    const tenant = this.getUserDetails ? this.getUserDetails.tenant : null

    const parameters: CadHelperLinkParameters = {
      command,
      signedUrl,
      downloadUrl,
      token,
      cadHelperVersion,
      sessionId,
      tenant,
    }

    return new CadHelperLink(parameters)
  }

  get getIbcMeasurmentsVisibility() {
    const displayToolbarState = this.ibcDisplayToolbarStateByVariantId(this.getIBCPlan.id)
    return displayToolbarState.measurements
  }

  clickClose() {
    return
  }

  async toggleVisibility(measurement: IMeasurementWithProperties) {
    const visibility = this.isVisible(measurement) ? Visibility.Hidden : Visibility.Visible
    const mes = { ...measurement, visibility } as IMeasurementWithProperties
    await this.setMeasurements(mes)
    this.toggleIBCMeasurment({
      id: mes.measurementVisFileId,
      isVisible: this.getIbcMeasurmentsVisibility && mes.visibility === Visibility.Visible,
    })
  }

  getMeasurementName(measurement: IMeasurementWithProperties) {
    return measurement.name || measurement.id
  }

  async deleteMeasurement(measurement: IMeasurementWithProperties) {
    this.deleteMeasurements({ measurements: [measurement], ibcPlanId: this.getIBCPlan.id })
  }

  isMeasurementUpdating(id: string) {
    return this.updatingMeasurements.includes(id)
  }

  isVisible(measurement: IMeasurementWithProperties) {
    return measurement.visibility === Visibility.Visible
  }

  async changeMeasurementNote(notes, measurement: IMeasurementWithProperties) {
    if (measurement.notes === notes) {
      return
    }
    const mes = { ...measurement, notes } as IMeasurementWithProperties
    this.setMeasurements(mes)
  }

  async setMeasurements(measurement: IMeasurementWithProperties) {
    this.updatingMeasurements.push(measurement.ibcPlanItemMeasurementId)
    await this.setIbcPlanItemMeasurement(measurement)
    this.updatingMeasurements = this.updatingMeasurements.filter((id) => id !== measurement.ibcPlanItemMeasurementId)
  }

  onMeasurementHover(measurement: IMeasurementWithProperties, isHover: boolean) {
    this.highlightIBCMeasurement({ id: measurement.measurementVisFileId, showHighlight: isHover })
  }

  get isReadOnly() {
    if (this.isLockedForViewer || this.isLockedForUser) {
      return true
    }

    const jobs = this.getIbcPlanJobs.filter((job) => job.itemId === this.getIBCPlan.id)
    return jobs.some((job) => {
      return [
        JobStatusCode.RUNNING,
        JobStatusCode.PENDING,
        JobStatusCode.QUEUED,
        JobStatusCode.CREATED,
        JobStatusCode.COMPLETE,
        JobStatusCode.WARNING,
      ].includes(job.code)
    })
  }

  openDownloadCadHelperDialog() {
    this.downloadCadHelperDialogVisible = true
  }

  closeDownloadCadHelperDialog() {
    this.downloadCadHelperDialogVisible = false
  }

  onCadHelperStarted() {
    this.closeDownloadCadHelperDialog()
  }

  onCadHelperTimeOut() {
    this.openDownloadCadHelperDialog()
  }

  onSocketMessage(brokerMessage: BrokerMessage) {
    switch (brokerMessage.event) {
      case BrokerEvents.CadHelperStarted:
        this.onCadHelperStarted()
        break
    }
  }
}
