import messageService from '@/services/messageService'
import { Vector3 } from '@babylonjs/core/Maths'

const STEP_SIZE = 5
const TOTAL_ANGLES = 360 / STEP_SIZE + 1

export enum OverhangDesignation {
  downskin,
  upskin,
  unsupported,
}
export enum RecoatDesignation {
  lowRecoaterAngle,
  recoaterFacing,
  recoaterNotFacing,
}

export class OrientHelper {
  readonly fromPartUnitsToMicronsFactorExact: number = 1000
  readonly oppositeDirectionLimitcosine = -0.0185 // ~sin(1 deg)
  readonly radiansToDegrees = 180.0 / Math.PI
  readonly partToBuildPlateClearance = 5.0

  readonly xUnitVector: number[] = [1, 0, 0]
  readonly yUnitVector: number[] = [0, 1, 0]
  readonly zUnitVector: number[] = [0, 0, 1]

  public evaluateValidOrientations(
    sa: number,
    trianglesProhibitedSupport: Vector3[][][],
    trianglesPreferedSupport: Vector3[][][],
    lastSavedRotationAngles: number[],
  ) {
    const orientations: Orientation[] = []
    const start: number = 0
    const end: number = TOTAL_ANGLES * TOTAL_ANGLES
    const xDeg: number = null
    const yDeg: number = null
    const zDeg: number = 0
    const eachFacefacetsPreferedSupport: Facet[][] = []
    const eachFacefacetsProhibitedSupport: Facet[][] = []

    trianglesProhibitedSupport.forEach((selectedFace) => {
      const facefacetsShouldNotHaveSupport: Facet[] = []
      selectedFace.forEach((triangle) => {
        const facet = new Facet([triangle[0], triangle[1], triangle[2]])
        facefacetsShouldNotHaveSupport.push(facet)
      })
      eachFacefacetsProhibitedSupport.push(facefacetsShouldNotHaveSupport)
    })

    trianglesPreferedSupport.forEach((selectedFace) => {
      const facefacetsShouldHaveSupport: Facet[] = []
      selectedFace.forEach((triangle) => {
        const facet = new Facet([triangle[0], triangle[1], triangle[2]])
        facefacetsShouldHaveSupport.push(facet)
      })
      eachFacefacetsPreferedSupport.push(facefacetsShouldHaveSupport)
    })

    const faceProhibitedSupportClusterBins = this.collectFacets(eachFacefacetsProhibitedSupport)
    const facePreferedSupportClusterBins = this.collectFacets(eachFacefacetsPreferedSupport)

    let i = start

    do {
      const x = Number.isInteger(xDeg) ? xDeg : this.getAngleX(i)
      const y = Number.isInteger(yDeg) ? yDeg : this.getAngleY(i)
      const z = Number.isInteger(zDeg) ? zDeg : this.getAngleZ(i)

      const orientation = this.getValidOrientation(
        x,
        y,
        z,
        sa,
        i,
        faceProhibitedSupportClusterBins,
        facePreferedSupportClusterBins,
      )
      orientations.push(orientation)
      i = i + 1
    } while (i < end)

    // filtering orientations based on sorting first prohibited then prefered
    let filteredOrientations: Orientation[] = []
    orientations.sort((o1, o2) =>
      o1.prohibitedSupportSurfaceCoverage > o2.prohibitedSupportSurfaceCoverage
        ? -1
        : o1.prohibitedSupportSurfaceCoverage < o2.prohibitedSupportSurfaceCoverage
        ? 1
        : 0,
    )

    const filteredProhibitedOrientations = orientations.filter(
      (orientation) => orientation.prohibitedSupportSurfaceCoverage === 1,
    )
    if (filteredProhibitedOrientations.length > 12) {
      filteredProhibitedOrientations.sort((o1, o2) =>
        o1.preferedSupportSurfaceCoverage > o2.preferedSupportSurfaceCoverage
          ? -1
          : o1.preferedSupportSurfaceCoverage < o2.preferedSupportSurfaceCoverage
          ? 1
          : 0,
      )
      const filteredPreferedOrientations = filteredProhibitedOrientations.filter(
        (orientation) =>
          orientation.preferedSupportSurfaceCoverage ===
          filteredProhibitedOrientations[0].preferedSupportSurfaceCoverage,
      )
      if (filteredPreferedOrientations.length > 12) filteredOrientations = filteredPreferedOrientations
      else filteredOrientations = filteredProhibitedOrientations.slice(0, 12)
    } else if (filteredProhibitedOrientations.length > 0 && filteredProhibitedOrientations.length <= 12) {
      filteredOrientations = filteredProhibitedOrientations
    }

    orientations.forEach((orientation) => {
      if (
        filteredOrientations.length === 0 ||
        (filteredOrientations.findIndex((filteredOri) => filteredOri.id === orientation.id) === -1 &&
          orientation.id !== 0)
      ) {
        orientation.isValid = false
      } else {
        orientation.isValid = true
      }
    })
    orientations.sort((o1, o2) => (o1.id < o2.id ? -1 : o1.id > o2.id ? 1 : 0))

    const orientationLastSaved = this.getValidOrientation(
      lastSavedRotationAngles[0],
      lastSavedRotationAngles[1],
      lastSavedRotationAngles[2],
      sa,
      end,
      faceProhibitedSupportClusterBins,
      facePreferedSupportClusterBins,
    )
    orientations.push(orientationLastSaved)
    return orientations
  }

  public checkPreferedSupportSurfaceCoverage(
    faceShouldHaveSupportClusterBins: AlignedNormal[],
    alignedBuildDir: number[],
    sa: number,
  ) {
    if (faceShouldHaveSupportClusterBins.length === 0) return 1.0
    let totalUnsupportedAreaFracion = 0
    faceShouldHaveSupportClusterBins.forEach((faceShouldHaveSupportClusterBin) => {
      const overhangDesignation = this.getOverHangDesignation(
        faceShouldHaveSupportClusterBin.direction,
        alignedBuildDir,
        sa,
      )
      switch (overhangDesignation) {
        case OverhangDesignation.unsupported:
          totalUnsupportedAreaFracion = totalUnsupportedAreaFracion + faceShouldHaveSupportClusterBin.areaFraction
          break
      }
    })
    return Math.round(totalUnsupportedAreaFracion * 100) / 100
  }

  public checkProhibitedSupportSurfaceCoverage(
    faceShouldNotHaveSupportClusterBins: AlignedNormal[],
    alignedBuildDir: number[],
    sa: number,
  ) {
    if (faceShouldNotHaveSupportClusterBins.length === 0) return 1.0
    let totalDownAndUpskinAreaFracion = 0
    faceShouldNotHaveSupportClusterBins.forEach((faceShouldNotHaveSupportClusterBin) => {
      const overhangDesignation = this.getOverHangDesignation(
        faceShouldNotHaveSupportClusterBin.direction,
        alignedBuildDir,
        sa,
      )
      switch (overhangDesignation) {
        case OverhangDesignation.downskin:
          totalDownAndUpskinAreaFracion =
            totalDownAndUpskinAreaFracion + faceShouldNotHaveSupportClusterBin.areaFraction
          break
        case OverhangDesignation.upskin:
          totalDownAndUpskinAreaFracion =
            totalDownAndUpskinAreaFracion + faceShouldNotHaveSupportClusterBin.areaFraction
          break
      }
    })
    return Math.round(totalDownAndUpskinAreaFracion * 100) / 100
  }

  public getSingleRotationMatrix(x, y, z) {
    const xRadian = this.getRadian(x)
    const yRadian = this.getRadian(y)
    const zRadian = this.getRadian(z)

    return [
      Math.cos(zRadian) * Math.cos(yRadian),
      Math.cos(zRadian) * Math.sin(yRadian) * Math.sin(xRadian) - Math.sin(zRadian) * Math.cos(xRadian),
      Math.cos(zRadian) * Math.sin(yRadian) * Math.cos(xRadian) + Math.sin(zRadian) * Math.sin(xRadian),
      Math.sin(zRadian) * Math.cos(yRadian),
      Math.sin(zRadian) * Math.sin(yRadian) * Math.sin(xRadian) + Math.cos(zRadian) * Math.cos(xRadian),
      Math.sin(zRadian) * Math.sin(yRadian) * Math.cos(xRadian) - Math.cos(zRadian) * Math.sin(xRadian),
      -Math.sin(yRadian),
      Math.cos(yRadian) * Math.sin(xRadian),
      Math.cos(yRadian) * Math.cos(xRadian),
    ]
  }

  public invert(a: number[]) {
    return [a[0], a[3], a[6], a[1], a[4], a[7], a[2], a[5], a[8]]
  }

  public getRadian(angle) {
    return angle * (Math.PI / 180.0)
  }

  public getVectorMultiplication(a: number[], b: number[]) {
    return [
      a[0] * b[0] + a[1] * b[1] + a[2] * b[2],
      a[0] * b[3] + a[1] * b[4] + a[2] * b[5],
      a[0] * b[6] + a[1] * b[7] + a[2] * b[8],
    ]
  }

  public getOverHangDesignation(normaOfCluster: Vector3, buildDirection: number[], sa: number) {
    const rounding = 100000
    const overhangDotLimit = -Math.cos((sa * Math.PI) / 180.0)

    const dotProductWithBuildDirection = Vector3.Dot(
      normaOfCluster,
      new Vector3(buildDirection[0], buildDirection[1], buildDirection[2]),
    )
    if (
      Math.round((dotProductWithBuildDirection + Number.EPSILON) * rounding) / rounding <
      Math.round((overhangDotLimit + Number.EPSILON) * rounding) / rounding
    ) {
      return OverhangDesignation.unsupported
    }
    if (dotProductWithBuildDirection < this.oppositeDirectionLimitcosine) return OverhangDesignation.downskin

    return OverhangDesignation.upskin
  }

  public getAngleX(index: number) {
    const x = (index % 73) * 5
    return x
  }

  public getAngleY(index: number) {
    const y = (Math.floor(index / 73) % 73) * 5
    return y
  }

  public getAngleZ(index: number) {
    const z = (index % 73) * 5
    return z
  }

  public collectFacets(eachFacefacets: Facet[][]) {
    const uniqueFacetBins: AlignedNormal[] = []
    let totalArea = 0.0
    const rounding = 100
    eachFacefacets.forEach((eachFacefacet) => {
      const facetsByOrientation: Map<string, AlignedNormal> = new Map()

      eachFacefacet.forEach((facet) => {
        // const x = Math.trunc(rounding * facet.normal._x)
        // const y = Math.trunc(rounding * facet.normal._y)
        // const z = Math.trunc(rounding * facet.normal._z)

        const x = Math.round(rounding * facet.normal._x)
        const y = Math.round(rounding * facet.normal._y)
        const z = Math.round(rounding * facet.normal._z)

        let normalCollector: AlignedNormal = facetsByOrientation.get(x.toString() + y.toString() + z.toString())

        if (!normalCollector) {
          normalCollector = new AlignedNormal()
          const facets: Facet[] = []
          normalCollector.facets = facets
          facetsByOrientation.set(x.toString() + y.toString() + z.toString(), normalCollector)
        }

        normalCollector.facets.push(facet)
      })

      facetsByOrientation.forEach((value) => {
        value.setArea()
        value.setDirection()
        uniqueFacetBins.push(value)
        totalArea = totalArea + value.totalArea
      })
    })
    uniqueFacetBins.forEach((uniqueFacetBin) => {
      uniqueFacetBin.areaFraction = uniqueFacetBin.totalArea / totalArea
    })
    return uniqueFacetBins
  }

  private getValidOrientation(
    x: number,
    y: number,
    z: number,
    sa: number,
    index: number,
    faceProhibitedSupportClusterBins: AlignedNormal[],
    facePreferedSupportClusterBins: AlignedNormal[],
  ) {
    const rotMatrix: number[] = this.getSingleRotationMatrix(x, y, z)
    const invertedMatrix = this.invert(rotMatrix)
    const alignedBuildDir: number[] = this.getVectorMultiplication(this.zUnitVector, invertedMatrix)

    const prohibitedSuppfaceCoverage = this.checkProhibitedSupportSurfaceCoverage(
      faceProhibitedSupportClusterBins,
      alignedBuildDir,
      sa,
    )
    const preferedSuppSurfaceCoverage = this.checkPreferedSupportSurfaceCoverage(
      facePreferedSupportClusterBins,
      alignedBuildDir,
      sa,
    )

    return new Orientation(index, prohibitedSuppfaceCoverage, preferedSuppSurfaceCoverage)
  }
}

export class AlignedNormal {
  totalArea: number = 0
  areaFraction: number = 0
  direction: Vector3 = new Vector3()
  facets: Facet[] = []

  public setArea() {
    this.totalArea = 0.0
    this.facets.forEach((facet) => {
      this.totalArea = this.totalArea + facet.area
    })
  }

  public setDirection() {
    this.direction = this.facets[0].normal
  }
}

export class Facet {
  vertices: Vector3[] = []
  normal: Vector3 = new Vector3()
  area: number = 0

  constructor(vertices: Vector3[]) {
    this.vertices = vertices
    this.area = this.getArea()
    this.normal = this.setNormal()
  }

  public getArea() {
    if (
      this.vertices[2] === this.vertices[0] ||
      this.vertices[1] === this.vertices[0] ||
      this.vertices[2] === this.vertices[1]
    ) {
      return 0.0
    }

    const crossProduct: Vector3 = Vector3.Cross(
      this.vertices[1].subtract(this.vertices[0]),
      this.vertices[2].subtract(this.vertices[0]),
    )
    return 0.5 * crossProduct.length()
  }

  public setNormal() {
    const crossProduct: Vector3 = Vector3.Cross(
      this.vertices[1].subtract(this.vertices[0]),
      this.vertices[2].subtract(this.vertices[0]),
    )
    return crossProduct.normalize()
  }
}

export class Orientation {
  id: number = 0
  prohibitedSupportSurfaceCoverage: number = 0
  preferedSupportSurfaceCoverage: number = 0
  isValid: boolean = true

  constructor(id: number, prohibitedSupportSurfaceCoverage: number, preferedSupportSurfaceCoverage: number) {
    this.id = id
    this.prohibitedSupportSurfaceCoverage = prohibitedSupportSurfaceCoverage
    this.preferedSupportSurfaceCoverage = preferedSupportSurfaceCoverage
    this.isValid = true
  }
}
