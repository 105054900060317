/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { VertexData, Mesh, TransformNode } from '@babylonjs/core/Meshes'
import { Buffer, VertexBuffer } from '@babylonjs/core/Buffers'
import { Vector3, Matrix, Color3, Color4, Axis, Epsilon } from '@babylonjs/core/Maths'
import { IRenderable } from '@/visualization/types/IRenderable'
import earcut from 'earcut'
import {
  COLOR_FOR_BODY,
  COLOR_FOR_FACE,
  COLOR_FOR_PART,
  SUPPORT_EDGES_COLOR,
  SUPPORT_EDGES_EPSILON,
  FACE_ID_ATTRIBUTE,
  LINE_SUPPORT,
  MESH_RENDERING_GROUP_ID,
  SUPPORT,
  SUPPORT_COLOR,
  SUPPORT_INSIDE_MATERIAL,
  SUPPORT_INSIDE_MESH_NAME,
  SUPPORT_MATERIAL,
  SUPPORT_PARENT,
  SUPPORT_EDGES_WIDTH,
} from '@/constants'
import { createGuid } from '@/utils/common'
import { InsightsManager } from '@/visualization/rendering/InsightsManager'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import { BuildPlanItemSupport, IMeshGeometryProperties } from '@/types/BuildPlans/IBuildPlan'
import { IBuildPlanInsight, InsightErrorCodes } from '@/types/BuildPlans/IBuildPlanInsight'
import { IPartMetadata, ISupportMetadata, SceneItemType } from '@/visualization/types/SceneItemMetadata'
import { Material } from '@babylonjs/core/Materials'
import { v4 as uuid } from 'uuid'
import partsService from '@/api/parts'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

export class Sdata {
  constructor(
    readonly vertices: Vector3[],
    readonly polylines: IPolyline[],
    readonly connects: IConnect[],
    readonly solidSupportIndices: number[],
  ) {}
}

interface IConnect {
  type: number
  connect(polylines: IPolyline[], isClockwise: boolean): number[]
}

interface IPolyline {
  vertexIndices: number[]
}

class LineSupport {
  constructor(readonly connector: StrutLoft) {}

  public buildMesh(polylines: IPolyline[], vertices: Vector3[]): VertexData {
    const connectorPolylines = this.connector.polylinesIndices.map((index) => polylines[index])

    const backDirection = this.calcFirstCorrectPolylinesDirection(connectorPolylines, vertices).scale(-1)
    const backHole = this.closeHole(vertices, connectorPolylines[0].vertexIndices, backDirection)

    connectorPolylines.reverse()
    const frontDirection = this.calcFirstCorrectPolylinesDirection(connectorPolylines, vertices).scale(-1)
    const frontHole = this.closeHole(vertices, connectorPolylines[0].vertexIndices, frontDirection)
    connectorPolylines.reverse()

    const isClockwise = this.isClockwise(connectorPolylines, vertices)
    const indices = this.connector.connect(polylines, isClockwise).concat(backHole, frontHole)

    return this.createSubVertexData(indices, vertices, true)
  }

  private createSubVertexData(indices: number[], vertices: Vector3[], convertToFlatShaded: boolean): VertexData {
    const newIndices: number[] = []
    const newPositions: number[] = []
    const newNormals: number[] = []

    if (!convertToFlatShaded) {
      const mapIndices = new Map<number, number>()

      for (const index of indices) {
        const newIndex = mapIndices.get(index)

        if (newIndex !== undefined) {
          newIndices.push(newIndex)
        } else {
          mapIndices.set(index, newPositions.length / 3)
          newIndices.push(newPositions.length / 3)

          newPositions.push(vertices[index].x)
          newPositions.push(vertices[index].y)
          newPositions.push(vertices[index].z)
        }
      }

      VertexData.ComputeNormals(newPositions, newIndices, newNormals, { useRightHandedSystem: true })
    } else {
      const addVertex = (v: Vector3, n: Vector3) => {
        newIndices.push(newPositions.length / 3)
        newPositions.push(v.x, v.y, v.z)
        newNormals.push(n.x, n.y, n.z)
      }

      for (let i = 0; i < indices.length - 2; i += 3) {
        const v1 = vertices[indices[i]]
        const v2 = vertices[indices[i + 1]]
        const v3 = vertices[indices[i + 2]]
        const normal = v1.subtract(v2).cross(v2.subtract(v3)).normalize()
        addVertex(v1, normal)
        addVertex(v2, normal)
        addVertex(v3, normal)
      }
    }

    const vertexData = new VertexData()
    vertexData.indices = newIndices
    vertexData.positions = newPositions
    vertexData.normals = newNormals
    return vertexData
  }

  private closeHole(vertices: Vector3[], indicesHole: number[], plateNormal: Vector3): number[] {
    if (indicesHole.length < 3) {
      return []
    }

    const mapIndices = new Map<number, number>()

    const hole: Vector3[] = []
    indicesHole.forEach((index) => {
      mapIndices.set(hole.length, index)
      hole.push(vertices[index])
    })

    const polylineNormal: Vector3 = this.calcPolylineNormal(indicesHole, vertices)

    // Rotates the hole plane to XY plane for earcut.
    const toXOY: Matrix = Matrix.Identity()
    if (Vector3.Dot(Axis.Z, polylineNormal) < -1 + Epsilon) {
      // Fix RotationAlignToRef with opposite vectors. Ref: https://github.com/BabylonJS/Babylon.js/pull/10340/files
      // TODO: Remove after update BabylonJS to 5.0.0 or more.
      Matrix.RotationXToRef(Math.PI, toXOY)
    } else {
      Matrix.RotationAlignToRef(polylineNormal, Axis.Z, toXOY)
    }

    const holeFloat: number[] = []
    for (const vector of hole) {
      const temp = Vector3.TransformCoordinates(vector, toXOY)
      holeFloat.push(temp.x, temp.y, temp.z)
    }

    const indices: number[] = earcut(holeFloat, null, 3)

    // fix normals
    const invertNormals = Vector3.Dot(polylineNormal, plateNormal) < 0

    const result: number[] = []
    for (let i = 0; i < indices.length - 2; i += 3) {
      if (invertNormals) {
        result.push(mapIndices.get(indices[i + 2]))
        result.push(mapIndices.get(indices[i + 1]))
        result.push(mapIndices.get(indices[i]))
      } else {
        result.push(mapIndices.get(indices[i]))
        result.push(mapIndices.get(indices[i + 1]))
        result.push(mapIndices.get(indices[i + 2]))
      }
    }

    return result
  }

  private isClockwise(polylines: IPolyline[], vertices: Vector3[]) {
    const prevPolylineNormal = this.calcPolylineNormal(polylines[0].vertexIndices, vertices)
    const dir = this.calcFirstCorrectPolylinesDirection(polylines, vertices)

    return Vector3.Dot(prevPolylineNormal, dir) > 0
  }

  private calcFirstCorrectPolylinesDirection(polylines: IPolyline[], vertices: Vector3[]): Vector3 {
    const firstPolyline = polylines[0]
    for (let i = 1; i < polylines.length; i += 1) {
      const currentPolyline = polylines[i]
      const direction = this.calcPolylinesDirection(firstPolyline, currentPolyline, vertices)
      if (direction) {
        return direction
      }
    }

    throw new Error('Polylines have the same vertices')
  }

  private calcPolylinesDirection(from: IPolyline, to: IPolyline, vertices: Vector3[]): Vector3 {
    for (let i = 0; i < from.vertexIndices.length; i += 1) {
      const current = vertices[from.vertexIndices[i]]
      const next = vertices[to.vertexIndices[i]]

      if (!next.equalsWithEpsilon(current)) {
        return next.subtract(current).normalize()
      }
    }

    return undefined
  }

  private calcPolylineNormal(vertexIndices: number[], vertices: Vector3[]): Vector3 {
    // Polyline is concave polygon
    const normal = Vector3.Zero()
    const begin = vertices[vertexIndices[0]]
    for (let i = 1; i < vertexIndices.length - 1; i += 1) {
      const current = vertices[vertexIndices[i]]
      const next = vertices[vertexIndices[i + 1]]
      const edge1 = current.subtract(begin)
      const edge2 = next.subtract(begin)
      normal.addInPlace(edge1.cross(edge2))
    }

    return normal.normalize()
  }
}

class StrutLoft implements IConnect {
  readonly type = 4
  readonly polylinesIndices: number[]

  constructor(polylinesIndices: number[]) {
    this.polylinesIndices = polylinesIndices
  }

  public connect(polylines: IPolyline[], isClockwise: boolean): number[] {
    const indices: number[] = []

    for (let i = 0; i < this.polylinesIndices.length - 1; i += 1) {
      const current = polylines[this.polylinesIndices[i]]
      const next = polylines[this.polylinesIndices[i + 1]]

      if (next.vertexIndices.length !== current.vertexIndices.length) {
        throw new Error(`polyline size must be equal`)
      }

      for (let j = 0; j < current.vertexIndices.length - 1; j += 1) {
        const prevIndex1 = current.vertexIndices[j]
        const prevIndex2 = current.vertexIndices[j + 1]
        const nextIndex1 = next.vertexIndices[j]
        const nextIndex2 = next.vertexIndices[j + 1]

        if (isClockwise) {
          indices.push(prevIndex1, prevIndex2, nextIndex1)
          indices.push(prevIndex2, nextIndex2, nextIndex1)
        } else {
          indices.push(nextIndex1, prevIndex2, prevIndex1)
          indices.push(nextIndex1, nextIndex2, prevIndex2)
        }
      }
    }

    return indices
  }
}

export class SupportManager {
  readonly renderScene: IRenderable
  private readonly insightsManager: InsightsManager
  private readonly meshManager: MeshManager
  private readonly supportMaterial: Material
  private readonly supportInsideMaterial: Material
  private supportCache: Map<string, { mesh: TransformNode; geometryProperties?: IMeshGeometryProperties }> = new Map<
    string,
    { mesh: TransformNode; geometryProperties: IMeshGeometryProperties }
  >()

  constructor(renderScene: IRenderable) {
    this.renderScene = renderScene
    this.insightsManager = renderScene.getInsightsManager()
    this.meshManager = renderScene.getMeshManager()
    this.supportMaterial = this.renderScene.getScene().getMaterialByID(SUPPORT_MATERIAL)
    this.supportInsideMaterial = this.renderScene.getScene().getMaterialByID(SUPPORT_INSIDE_MATERIAL)
  }

  public createSupport(sdata: Sdata): TransformNode {
    // currently only supports StrutLofts
    const strutLofts = sdata.connects.filter(this.isStrutLoft)
    const lineSupports: LineSupport[] = strutLofts.map((strutLoft) => new LineSupport(strutLoft))

    const scene = this.renderScene.getScene()
    const support = new TransformNode(SUPPORT, scene)
    support.metadata = {
      itemType: SceneItemType.Support,
      sourceSupport: support,
    } as ISupportMetadata
    support.id = createGuid()

    try {
      lineSupports.forEach((lineSupport) => {
        const vertexData = lineSupport.buildMesh(sdata.polylines, sdata.vertices)
        const lineSupportMesh = new Mesh(LINE_SUPPORT, scene, support)
        vertexData.applyToMesh(lineSupportMesh)

        this.setupSupportMesh(lineSupportMesh)
      })

      if (sdata.solidSupportIndices.length > 2) {
        const solidSupport = this.buildSolidSupport(sdata.vertices, sdata.solidSupportIndices)
        const solidSupportMesh = new Mesh(LINE_SUPPORT, scene, support)
        solidSupport.applyToMesh(solidSupportMesh)

        this.setupSupportMesh(solidSupportMesh)
      }
    } catch (error) {
      support.getChildMeshes().forEach((mesh) => {
        if (mesh.metadata && mesh.metadata.sourceMeshInside) {
          mesh.metadata.sourceMeshInside.dispose()
        }
        mesh.dispose()
      })
      support.dispose()
      throw error
    }

    return support
  }

  public decodeSdata(sdataFile: ArrayBuffer): Sdata {
    const decoder = new TextDecoder('utf-8')
    const fileData = decoder.decode(sdataFile)

    return this.createSdataFromString(fileData)
  }

  createSdataFromString(fileData: string): Sdata {
    const lineData = fileData.split('\n')
    const vertices = new Array<Vector3>()
    const polylines = new Array<IPolyline>()
    const connects = new Array<IConnect>()
    const solidIndices = new Array<number>()

    // parse
    for (let line of lineData) {
      line = line.trim()
      switch (line[0]) {
        case 'v':
          vertices.push(this.parseVector(line))
          break
        case 'l':
          polylines.push(this.parsePolyline(line))
          break
        case 'c':
          connects.push(this.parseConnect(line))
          break
        case 't':
          solidIndices.push(...this.parseTriangle(line))
        case '#':
        default:
          break
      }
    }

    return new Sdata(vertices, polylines, connects, solidIndices)
  }

  getSupportInsights() {
    return this.insightsManager.buildInsights(Array.from(this.renderScene.getSceneMetadata().buildPlanItems.values()))
  }

  getCachedSupport(id: string) {
    return this.supportCache.get(id)
  }

  refreshSupportInsights() {
    const insights = this.getSupportInsights()
    this.insightsManager.reportInsights([{ insights, tool: ToolNames.SUPPORT }])
  }

  loadSupportInsights(insights: IBuildPlanInsight[]) {
    insights.forEach((insight) => {
      const partMesh = this.meshManager.getBuildPlanItemMeshById(insight.details.bpItemId)
      if (!partMesh) {
        return
      }

      const partMetadata = partMesh.metadata as IPartMetadata
      switch (insight.errorCode) {
        case InsightErrorCodes.SupportToolConstructionFailed:
          partMetadata.failedOverhangZones = insight.details.failedOverhangZones
          break
      }
    })
  }

  addFailedOverhangZone(bpItemId: string, overhangZoneName: string) {
    const partMesh = this.meshManager.getBuildPlanItemMeshById(bpItemId)
    if (!partMesh) {
      return
    }
    const partMetadata = partMesh.metadata as IPartMetadata
    partMetadata.failedOverhangZones.push(overhangZoneName)

    this.refreshSupportInsights()
  }

  async loadSupport(config: BuildPlanItemSupport, pColor: Color4): Promise<TransformNode> {
    let sourceSupportGroup: TransformNode
    if (this.supportCache.has(config.awsFileKey)) {
      sourceSupportGroup = this.supportCache.get(config.awsFileKey).mesh
    } else {
      const supportFile = await partsService.getTextFileFromAws(config.awsFileKey)
      const sdata = this.createSdataFromString(supportFile)
      sourceSupportGroup = this.createSupport(sdata)
      sourceSupportGroup.id = config.awsFileKey
      this.supportCache.set(config.awsFileKey, { mesh: sourceSupportGroup })
    }

    const supportGroup = this.createSupportInstance(sourceSupportGroup, pColor)
    supportGroup.metadata.belongsToOverhangElementName = config.overhangElementName
    return supportGroup
  }

  createSupportInstance(supportGroup: TransformNode, pColor: Color4): TransformNode {
    const cloneSupportGroup = supportGroup.clone(SUPPORT, undefined, true)
    cloneSupportGroup.metadata = {
      itemType: SceneItemType.Support,
      belongsToOverhangElementName: supportGroup.metadata.belongsToOverhangElementName,
      sourceSupport: supportGroup.metadata.sourceSupport,
    } as ISupportMetadata

    cloneSupportGroup.onDispose = () => {
      const sourceSupportGroup: TransformNode = cloneSupportGroup.metadata.sourceSupport
      const someHasInstances = sourceSupportGroup.getChildMeshes(true).some((child) => child.hasInstances)
      if (!someHasInstances) {
        sourceSupportGroup.getChildMeshes(true).forEach((child) => child.metadata.sourceMeshInside.dispose())
        sourceSupportGroup.dispose()
        this.supportCache.delete(sourceSupportGroup.id)
      }
    }

    const supports = supportGroup.getChildMeshes(true, (mesh) => mesh.name === LINE_SUPPORT) as Mesh[]
    for (const support of supports) {
      //  Edges rendering start
      support.enableEdgesRendering(SUPPORT_EDGES_EPSILON, true, {
        useAlternateEdgeFinder: true,
        applyTessellation: false,
        removeDegeneratedTriangles: true,
        useFastVertexMerger: true,
      })
      support.edgesWidth = SUPPORT_EDGES_WIDTH
      support.edgesColor = Color4.FromColor3(SUPPORT_EDGES_COLOR, 1)
      support.edgesRenderer.lineShader.options.useClipPlane = null
      support.edgesShareWithInstances = true
      // edges rendering end

      const supportInstance = support.createInstance(LINE_SUPPORT)
      supportInstance.id = createGuid()
      supportInstance.parent = cloneSupportGroup
      supportInstance.isVisible = true
      supportInstance.isPickable = false
      supportInstance.instancedBuffers.color = SUPPORT_COLOR
      supportInstance.instancedBuffers.pColor = pColor
      supportInstance.instancedBuffers.fColor = support.instancedBuffers.fColor
      supportInstance.instancedBuffers.bColor = support.instancedBuffers.bColor
      supportInstance.metadata = {
        itemType: SceneItemType.Support,
        faces: supportInstance.sourceMesh.metadata.faces,
        pickingColor: supportInstance.sourceMesh.metadata.pickingColor,
        componentId: supportInstance.sourceMesh.metadata.componentId,
        geometryId: supportInstance.sourceMesh.metadata.geometryId,
      }

      const insideSupport = supportInstance.sourceMesh.metadata.sourceMeshInside as Mesh
      const supportInsideInstance = insideSupport.createInstance(`${SUPPORT_INSIDE_MESH_NAME}_${LINE_SUPPORT}`)
      supportInsideInstance.parent = supportInstance
      supportInsideInstance.isVisible = false
      supportInsideInstance.isPickable = false

      this.meshManager.createTransparentClone(supportInstance, false)
    }

    return cloneSupportGroup
  }

  createSupportParent(part: TransformNode) {
    let supportParentMesh = part.getChildTransformNodes().find((mesh) => mesh.name === SUPPORT_PARENT)
    if (!supportParentMesh) {
      part.metadata.failedOverhangZones = part.metadata.failedOverhangZones || []
      supportParentMesh = new TransformNode(SUPPORT_PARENT, this.renderScene.getScene())
      supportParentMesh.id = createGuid()
      supportParentMesh.parent = part
      supportParentMesh.metadata = {
        buildPlanItemId: (part.metadata as IPartMetadata).buildPlanItemId,
        itemType: SceneItemType.Support,
      }
    }

    return supportParentMesh
  }

  duplicateSupports(sourcePart: TransformNode, targetPart: TransformNode) {
    const supportParent = this.createSupportParent(targetPart)

    const mainSupportParent = sourcePart.getChildTransformNodes().find((mesh) => mesh.name === SUPPORT_PARENT)
    if (mainSupportParent) {
      supportParent.metadata.bvh = mainSupportParent.metadata.bvh
      supportParent.metadata.hull = mainSupportParent.metadata.hull
    }

    const supportGroups = sourcePart.getChildTransformNodes(false, (mesh) => mesh.name === SUPPORT)
    const pickingColor = targetPart.metadata.pickingColor
    for (const supportGroup of supportGroups) {
      const cloneSupportGroup = this.createSupportInstance(supportGroup, pickingColor)
      cloneSupportGroup.parent = supportParent
    }

    targetPart.metadata.hullBInfo = this.meshManager.getHullBInfo(targetPart)
    targetPart.metadata.failedOverhangZones = [...sourcePart.metadata.failedOverhangZones]
  }

  updateSupports(buildPlanItemId: string, supportConfigs: BuildPlanItemSupport[]) {
    const part = this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)

    const supportGroups = part.getChildTransformNodes(false, (node) => node.name === SUPPORT)
    for (const config of supportConfigs) {
      if (config.awsFileKey) {
        const supportGroup = supportGroups.find((support) => {
          return support.metadata.belongsToOverhangElementName === config.overhangElementName
        })
        supportGroup.metadata.sourceSupport.id = config.awsFileKey
        this.supportCache.set(config.awsFileKey, { mesh: supportGroup.metadata.sourceSupport })
      }
    }
  }

  updateCachedGeometryProperties(id: string, geometryProperties: IMeshGeometryProperties) {
    const cached = this.supportCache.get(id)
    if (cached) {
      this.supportCache.set(id, { geometryProperties, mesh: cached.mesh })
    }
  }

  private parseVector(line: string): Vector3 {
    const vector = line.split(' ')
    const x = +vector[1]
    const y = +vector[2]
    const z = +vector[3]
    return new Vector3(x, y, z)
  }

  private parsePolyline(line: string): IPolyline {
    const indices = line.split(' ')

    const vertexIndices: number[] = []
    for (let i = 1; i < indices.length; i += 1) {
      const index = +indices[i]
      if (!Number.isInteger(index) || index < 0) {
        throw new Error(`element: ${indices[i]} is incorrect`)
      }
      vertexIndices.push(index)
    }

    return { vertexIndices }
  }

  private parseConnect(line: string): IConnect {
    const indices = line.split(' ')

    const list: number[] = []
    for (let i = 1; i < indices.length; i += 1) {
      const index = +indices[i]
      if (!Number.isInteger(index)) {
        throw new Error(`Element ${indices[i]} is incorrect`)
      }
      list.push(index)
    }

    if (list[0] === 4) {
      return new StrutLoft(list.slice(1))
    }

    throw new Error(`Сonnect type ${list[0]} not implemented`)
  }

  private parseTriangle(line: string): number[] {
    const triangle = line.split(' ')
    const idx1 = +triangle[1]
    const idx2 = +triangle[2]
    const idx3 = +triangle[3]
    return [idx1, idx2, idx3]
  }

  private buildSolidSupport(vertices: Vector3[], indices: number[]): VertexData {
    const newIndices: number[] = []
    const newPositions: number[] = []
    const newNormals: number[] = []

    const addVertex = (v: Vector3, n: Vector3) => {
      newIndices.push(newPositions.length / 3)
      newPositions.push(v.x, v.y, v.z)
      newNormals.push(n.x, n.y, n.z)
    }

    for (let i = 0; i < indices.length - 2; i += 3) {
      const v1 = vertices[indices[i]]
      const v2 = vertices[indices[i + 1]]
      const v3 = vertices[indices[i + 2]]
      const edge1 = v1.subtract(v2)
      const edge2 = v2.subtract(v3)
      const normal = edge1.cross(edge2).normalize()
      addVertex(v1, normal)
      addVertex(v2, normal)
      addVertex(v3, normal)
    }

    const vertexData = new VertexData()
    vertexData.indices = newIndices
    vertexData.positions = newPositions
    vertexData.normals = newNormals

    return vertexData
  }

  private setupMeshForGpuPicker(support: Mesh) {
    const buffer = new Buffer(
      this.renderScene.getScene().getEngine(),
      new Array(support.getTotalVertices()).fill(0),
      false,
    )
    const faceColor = Color3.FromInts(
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
    )
    const pickingColor = Color3.FromInts(
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
    )
    support.setVerticesBuffer(buffer.createVertexBuffer(FACE_ID_ATTRIBUTE, 0, 1))
    support.registerInstancedBuffer(COLOR_FOR_FACE, 3)
    support.instancedBuffers.fColor = faceColor
    support.registerInstancedBuffer(COLOR_FOR_PART, 3)
    support.instancedBuffers.pColor = Color3.White()
    support.registerInstancedBuffer(COLOR_FOR_BODY, 3)
    support.instancedBuffers.bColor = pickingColor
    support.metadata = {
      pickingColor,
      pickingShader: this.renderScene.getGpuPicker().pickingShader,
      faces: [{ color: faceColor }],
      originalMaterial: this.supportMaterial,
    }
  }

  private setupSupportMesh(support: Mesh) {
    const scena = this.renderScene.getScene()

    support.id = createGuid()
    support.registerInstancedBuffer(VertexBuffer.ColorKind, 4)
    support.instancedBuffers.color = SUPPORT_COLOR
    support.material = this.supportMaterial
    support.renderingGroupId = MESH_RENDERING_GROUP_ID
    support.isVisible = false
    support.isPickable = false
    support.useVertexColors = true
    this.setupMeshForGpuPicker(support)
    scena.removeMesh(support)

    const insideSupport = new Mesh(`${SUPPORT_INSIDE_MESH_NAME}_${LINE_SUPPORT}`, scena)
    const vd = new VertexData()
    vd.indices = support.getIndices()
    vd.positions = support.getVerticesData(VertexBuffer.PositionKind)
    vd.normals = support.getVerticesData(VertexBuffer.NormalKind)
    vd.applyToMesh(insideSupport)
    insideSupport.material = this.supportInsideMaterial
    insideSupport.renderingGroupId = MESH_RENDERING_GROUP_ID
    insideSupport.isPickable = false
    insideSupport.isVisible = false
    scena.removeMesh(insideSupport)

    const componentId = uuid()
    const geometryId = uuid()
    if (support.metadata) {
      support.metadata.sourceMeshInside = insideSupport
      support.metadata.componentId = componentId
      support.metadata.geometryId = geometryId
    } else {
      support.metadata = {
        componentId,
        geometryId,
        sourceMeshInside: insideSupport,
      }
    }
  }

  private isStrutLoft(connect: IConnect): connect is StrutLoft {
    return connect.type === 4
  }
}
