
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import DetailsPreview from '@/components/layout/FileExplorer/Details/DetailsPreview.vue'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { IbcPlanDetailsDto } from '@/types/FileExplorer/ItemDetails'
import messageService from '@/services/messageService'
import StoresNamespaces from '@/store/namespaces'
import { IBuildPlan, PartTypes } from '@/types/BuildPlans/IBuildPlan'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { IMachineConfig } from '@/types/IMachineConfig'
import { IMaterial } from '@/types/IMaterial'
import FallbackImage from '@/components/layout/FallbackImage.vue'
import ExpansionItemsList from '@/components/layout/FileExplorer/Details/ExpansionItemsList.vue'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { DetailsPanelExpansionItem } from '@/types/FileExplorer/DetailsPanelExpansionItem'
import { RouterNames } from '@/router'
import Menu from '@/components/controls/Common/Menu.vue'
import ExpansionPrintOrdersList from '@/components/layout/FileExplorer/Details/ExpansionPrintOrdersList.vue'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { PrintStrategyShortInfo } from '@/types/Sites/Site'
import { DEFAULT_PREVIEW_PATH } from '@/constants'
import { ActionDateByUserMixin } from '@/components/layout/mixins/ActionDateByUserMixin'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { PartListItemViewModel } from '../../buildPlans/addPart/types'
import VariantIcon from '@/components/icons/VariantIcon.vue'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const jobsStore = namespace(StoresNamespaces.Jobs)
const partsStore = namespace(StoresNamespaces.Parts)

@Component({
  components: { DetailsPreview, FallbackImage, ExpansionItemsList, Menu, ExpansionPrintOrdersList, VariantIcon },
})
export default class DetailsIbcPlan extends ActionDateByUserMixin {
  @Prop() item: FileExplorerItem
  @Prop() itemDetails: IbcPlanDetailsDto
  @Prop({ default: false }) hidePreview: boolean
  @Prop({ default: false }) disableHyperlinks: boolean

  @buildPlansStore.Action fetchMachineConfigs: Function
  @buildPlansStore.Action fetchMaterials: Function
  @buildPlansStore.Action fetchPrintStrategies: Function
  @buildPlansStore.Action fetchRelatedBuildPlanVariants: (variantId: string) => Promise<void>
  @buildPlansStore.Getter getMachineConfigByPk: (pk: VersionablePk) => IMachineConfig
  @buildPlansStore.Getter getMaterialByPk: (pk: VersionablePk) => IMaterial
  @buildPlansStore.Getter getPrintStrategyByPk: (pk: VersionablePk) => PrintStrategyShortInfo
  @buildPlansStore.Getter getBuildPlanVariants: IBuildPlan[]

  @fileExplorerStore.Getter hasRunningImportJob: (id: string) => boolean
  @fileExplorerStore.Mutation setClickedNameItem: (item: FileExplorerItem) => void

  @jobsStore.Action fetchPrintOrders: () => Promise<PrintOrder[]>

  @partsStore.Getter getAllSinterParts: IPartDto[]
  @partsStore.Getter getAllIbcParts: IPartDto[]
  @partsStore.Getter getIbcPlanParts: (ibcPlan: IIBCPlan) => PartListItemViewModel[]

  printOrdersJobs: PrintOrder[] = []

  get machineName() {
    const machineConfig = this.getMachineConfigByPk(
      new VersionablePk(this.itemDetails.machineConfigId, this.itemDetails.machineConfigVersion),
    )

    if (machineConfig && machineConfig.name) {
      return machineConfig.name
    }

    return `${this.$i18n.t('none')}`
  }

  get materialName() {
    const material = this.getMaterialByPk(
      new VersionablePk(this.itemDetails.materialId, this.itemDetails.materialVersion),
    )

    if (material && material.name) {
      return material.name
    }

    return `${this.$i18n.t('none')}`
  }

  get printStrategyName() {
    const printStrategy = this.getPrintStrategyByPk(
      new VersionablePk(this.itemDetails.printStrategyId, this.itemDetails.printStrategyVersion),
    )

    if (printStrategy && printStrategy.name) {
      return printStrategy.name
    }

    return `${this.$i18n.t('none')}`
  }

  get printOrder(): { id: string; name: string } {
    return this.itemDetails.ibcPlan.printOrder
  }

  get printOrderName(): string {
    return this.printOrder ? `${this.printOrder.name} (${this.$t('printOrder')})` : 'N/A'
  }

  get parts(): PartListItemViewModel[] {
    return this.getIbcPlanParts(this.itemDetails.ibcPlan)
  }

  get colaboratorsInfo(): string {
    const collaboratorsCount = this.itemDetails.collaborators.length
    return `${collaboratorsCount} ${
      collaboratorsCount !== 1 ? this.$i18n.t('collaborators') : this.$i18n.t('collaborator')
    }`
  }

  get previewImageUrl(): string {
    const time: number = new Date().getTime()
    const previewImageUrl = this.item.previewImageUrl ? this.item.previewImageUrl : this.getDefaultPreviewImageUrl()

    return `${previewImageUrl}?${time}`
  }

  @Watch('item', { deep: true })
  async onItemChanged(value: FileExplorerItem) {
    if (!value) {
      return
    }

    const printStrategyPk = new VersionablePk(this.itemDetails.printStrategyId, this.itemDetails.printStrategyVersion)
    const printStrategy = this.getPrintStrategyByPk(printStrategyPk)
    if (!printStrategy) {
      await this.fetchPrintStrategies(true)
    }

    await Promise.all([this.getBuildPlanPrintOrders(), this.fetchRelatedBuildPlanVariants(value.id)])
  }

  async beforeMount() {
    await Promise.all([
      this.fetchMachineConfigs(),
      this.fetchMaterials(),
      this.getBuildPlanPrintOrders(),
      this.fetchPrintStrategies(true),
      this.fetchRelatedBuildPlanVariants(this.itemDetails.ibcPlan.id),
    ])
  }

  share() {
    this.$emit('share')
  }

  async previewItem(item: FileExplorerItem) {
    if (await this.hasRunningImportJob(item.id)) {
      const errMsg = `${this.$i18n.t('importSnackbarMsg')}`
      messageService.showErrorMessage(errMsg)
      return
    }
    this.setClickedNameItem(item)
  }

  getDefaultPreviewImageUrl() {
    if (this.item.subType === ItemSubType.SinterPlan) return DEFAULT_PREVIEW_PATH.sinterPlan
    if (this.item.itemType === ItemType.BuildPlan) return DEFAULT_PREVIEW_PATH.buildPlan
    if (this.item.itemType === ItemType.IbcPlan) return DEFAULT_PREVIEW_PATH.sinterPlan

    return null
  }

  variantChanged(variant) {
    this.$emit('variantChanged', variant)
  }

  private async getBuildPlanPrintOrders() {
    this.printOrdersJobs = await this.fetchPrintOrders()
  }

  private openPrintOrder() {
    if (!this.printOrder) {
      return
    }

    const route = {
      name: RouterNames.PreviewPrintOrder,
      params: {
        id: this.printOrder.id,
      },
    }

    // @ts-ignore
    this.$router.safePush(route)
  }
}
