import Vue from 'vue'
import { MutationTree } from 'vuex'
import { IJobsState } from '@/store/modules/jobs/types'
import { IJob, JobStatusCode } from '@/types/PartsLibrary/Job'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { PrintOrderJobStatusCode } from '@/types/PrintOrder/PrintOrderJobStatusCode'

export const mutations: MutationTree<IJobsState> = {
  setJobs(state: IJobsState, jobs: IJob[]) {
    Vue.set(state, 'jobs', jobs)
  },

  addJobs(state: IJobsState, jobs: IJob[]) {
    state.jobs = [...jobs, ...state.jobs]
  },

  setJobStatus(state: IJobsState, jobId: string) {
    for (const idx in state.jobs) {
      if (state.jobs[idx].id === jobId) {
        state.jobs[idx].code = JobStatusCode.CANCELLING
      }
    }
  },

  setPrintOrder(state: IJobsState, printOrder: PrintOrder) {
    // For some reasons we update status value not via mutations
    // it is better to set it manually
    printOrder.code = printOrder.status
    printOrder.status = PrintOrderJobStatusCode[printOrder.status].toLowerCase()
    state.printOrder = printOrder
  },

  setPrintOrders(state, printOrders: PrintOrder[]) {
    // For some reasons we update status value not via mutations
    // it is better to set it manually
    printOrders.forEach((printOrder) => {
      printOrder.code = printOrder.status
      printOrder.status = PrintOrderJobStatusCode[printOrder.status].toLowerCase()
    })

    state.printOrders = [...printOrders]
  },

  setPrintOrdersLoading(state, printOrderLoading: boolean) {
    state.printOrdersLoading = printOrderLoading
  },
}
