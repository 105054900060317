import { get } from '@/api/http'
import { EndpointsUrls } from '@/configs/config'
import { handleAPIError } from '@/api/common'

export default {
  async getDownloadUrl() {
    try {
      return await get<string>(`${EndpointsUrls.CadHelper}/getDownloadUrl`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getLatestVersion() {
    try {
      return await get<string>(`${EndpointsUrls.CadHelper}/getLatestVersion`)
    } catch (error) {
      handleAPIError(error)
    }
  },
}
