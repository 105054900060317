
import Vue from 'vue'
import Component from 'vue-class-component'
@Component({
  components: {},
})
export default class Settings extends Vue {
  settingDetails: object[] = [
    {
      header: 'accountSettings',
      items: [
        {
          icon: 'mdi-account',
          title: 'personalDetailsTitle',
          subTitle: 'personalDetailsSubTitle',
          color: '#5DBCD2',
          route: '',
        },
        {
          icon: 'mdi-lock',
          title: 'securityTitle',
          subTitle: 'securitySubTitle',
          color: '',
          route: '',
        },
        {
          icon: 'mdi-map-marker',
          title: 'shipmentAddressTitle',
          subTitle: 'shipmentAddressSubTitle',
          color: '#FF9800',
          route: '',
        },
        {
          icon: 'mdi-credit-card-outline ',
          title: 'paymentOptionsTitle',
          subTitle: 'paymentOptionsSubTitle',
          color: '#0277BD',
          route: '',
        },
        {
          icon: 'mdi-cube-outline ',
          title: 'ordersTitle',
          subTitle: 'ordersSubTitle',
          color: '#FFE0B2',
          route: '',
        },
      ],
    },
    {
      header: 'factorySettings',
      items: [
        {
          icon: 'mdi-factory',
          title: 'factoryDetailsTitle',
          subTitle: 'factoryDetailsSubTitle',
          color: '',
          route: '',
        },
        {
          icon: 'mdi-coin-outline',
          title: 'costCalcTitle',
          subTitle: 'costCalcSubTitle',
          color: '',
          route: '',
        },
        {
          icon: 'mdi-account-supervisor-circle ',
          title: 'suppliersTitle',
          subTitle: 'suppliersSubTitle',
          color: '',
          route: '',
        },
      ],
    },
    {
      header: 'printers',
      items: [
        {
          icon: 'mdi-printer-3d',
          title: 'connectedPrintersTitle',
          subTitle: 'connectedPrintersSubTitle',
          color: '',
          route: '/settings/connected_printers',
        },
        {
          icon: 'mdi-image-filter-hdr',
          title: 'powderManagementTitle',
          subTitle: 'powderManagementSubTitle',
          color: '',
          route: '',
        },
        {
          icon: 'mdi-printer-check ',
          title: 'printManagementTitle',
          subTitle: 'printManagementSubTitle',
          color: 'black',
          route: '',
        },
      ],
    },
  ]
}
