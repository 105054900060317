/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Scene } from '@babylonjs/core/scene'
import {
  IDataDirectoryStructure,
  IResultsConnectionParams,
  IResultsPathData,
  ISimulationExportInfo,
  ResultsManagerEvent as RMEvent,
  CategoryKind,
} from '@/visualization/types/SimulationTypes'
import { VisualizationEvent } from '@/visualization/infrastructure/IVisualizationEvent'
import { OuterEvents } from '@/visualization/types/Common'
import { SimulationColoringView } from '@/types/Simulation/SimulationColoringView'
import { SimulationSlicerManager } from './SimulationSlicerManager'
import { Engine } from '@babylonjs/core/Engines/engine'
import { Socket, io } from 'socket.io-client'
import { OperationStatus } from '@/types/InteractiveService/IInteractiveServiceMessage'
import messageService from '@/services/messageService'
import i18n from '@/plugins/i18n'
import { createGuid } from '@/utils/common'
import { ISimulationStep } from '@/types/Simulation/SimulationSteps'
import { StreamHandler } from '@/visualization/rendering/StreamHandler'
import { IDataHandlerInfo, SimulationStepHandler } from '@/visualization/rendering/SimulationStepHandler'
import { SimCompVersion } from '@/visualization/rendering/SimCompVersion'

const PARAVIEWWEB_EXIT_CONTENT = 'paraviewweb.exit'
const SIMCOMP_VERSION_WARPING_SHRINKAGE_ON = '23.05.08.0'

// Web Socket commands for communication with ParaView Web Server
export enum WsCommands {
  Hello = 'wslink.hello',
  ImportSkinSimulation = 'ge.create.skin.reader',
  GetGeometry = 'ge.geometry.array.get',
  GetTimeStamps = 'ge.time.values',
  SetCurrentSkinTimeStamp = 'ge.get.datasetinfo.skin.time',
  GetChartsData = 'ge.get.charts',
  ExportCompensationFiles = 'ge.compensation.files.export',
  SizeCompensationFiles = 'ge.compensation.files.size',
  FullBrowseDirectory = 'ge.browse.directory',
  GetGeometryFile = 'ge.geometry.file',
  ImportVtkData = 'ge.create.vtk.reader',
}

export interface IVisualizationServiceCommand {
  id?: string
  name: WsCommands
  parameters?: unknown
}

export interface IVisualizationServiceMessage {
  id?: string
  status: string
  message: any
  content: any
}

export enum PWError {
  SocketError,
  ProcessExit,
  SocketClosed,
  ErrorBeforeConnection,
}

export abstract class ResultsManager {
  protected scene: Scene
  protected socket: Socket
  protected buildPlanId: string
  protected jobNumber: number
  protected simulationSlicerManager: SimulationSlicerManager
  protected commandMap: Map<string, Function>
  protected stepsList: ISimulationStep[]
  protected currentSimulationStep: ISimulationStep
  protected simulationStepsData: Map<string, SimulationStepHandler>
  protected currentSimCompVersion: SimCompVersion

  private isCurComponentRange: boolean
  private helloCommand: IVisualizationServiceCommand
  private sizeCompensationCommand: IVisualizationServiceCommand
  private readonly onResultsManagerEvent = new VisualizationEvent<{ event: RMEvent; args }>()
  private readonly onChangeIsLoading = new VisualizationEvent<{ isLoading: boolean }>()
  private readonly onRenderRequested = new VisualizationEvent<void>()
  private readonly streamHandler: StreamHandler
  private readonly simCompWarpingVersion = new SimCompVersion(SIMCOMP_VERSION_WARPING_SHRINKAGE_ON)
  private handersVisibility: Map<CategoryKind, boolean>

  constructor(scene: Scene, engine: Engine) {
    this.scene = scene
    this.simulationSlicerManager = new SimulationSlicerManager(this.scene, engine)
    this.commandMap = new Map<string, Function>()
    this.isCurComponentRange = false
    this.stepsList = []
    this.onSocketConnectError = this.onSocketConnectError.bind(this)
    this.onSocketDisconnect = this.onSocketDisconnect.bind(this)
    this.streamHandler = new StreamHandler(this)
    this.handersVisibility = new Map<CategoryKind, boolean>()
    this.simulationStepsData = new Map<string, SimulationStepHandler>()
  }

  abstract get isDMLM()

  abstract get analysisSettings()

  get simCompSupportsWarping(): boolean {
    return this.currentSimCompVersion.isGreaterOrEqual(this.simCompWarpingVersion)
  }

  get resultsEvent() {
    return this.onResultsManagerEvent.expose()
  }

  get changeIsLoading() {
    return this.onChangeIsLoading.expose()
  }

  get renderRequested() {
    return this.onRenderRequested.expose()
  }

  get getSocket(): Socket {
    return this.socket
  }

  get getScene(): Scene {
    return this.scene
  }

  get isCurrentComponentRange() {
    return this.isCurComponentRange
  }

  get sliceManager(): SimulationSlicerManager {
    return this.simulationSlicerManager
  }

  get isCurrentStepActive(): boolean {
    return !!this.getCurrentSimulationStep()
  }

  handleOuterEvent(eventName: OuterEvents, payload: object) {
    switch (eventName) {
      case OuterEvents.ColoringModeChanged:
        this.changeCurrentColorView(payload as SimulationColoringView)
        break

      case OuterEvents.ConnectToService:
        const params = payload as IResultsConnectionParams
        this.startCommunication(params)
        break

      case OuterEvents.SetXrayView:
        const dataRange = payload as { min: number; max: number }
        this.setXrayView(dataRange.min, dataRange.max)
        break

      case OuterEvents.SetSimulationExportInfo:
        this.streamHandler.setSimulationExportInfo(payload as ISimulationExportInfo)
        break

      case OuterEvents.ExportCompensationFiles:
        this.streamHandler.toggleCompensationExportProgress(true)
        this.sizeCompensationCommand.parameters = [this.buildPlanId, this.jobNumber, payload]
        this.socket.emit('command', this.sizeCompensationCommand)
        break

      case OuterEvents.SetAllStepsColorRange:
        this.isCurComponentRange = !payload
        this.adjustColorRange()
        break

      case OuterEvents.SetHandlerVisibility:
        const handlerParams = payload as IDataHandlerInfo
        this.handersVisibility.set(handlerParams.type, handlerParams.visible)
        if (this.getCurrentSimulationStep()) {
          this.getCurrentSimulationStep().setHandlerVisibility(handlerParams)
        }
        break

      default:
        return false
    }

    this.renderScene()
    return true
  }

  setResultsPathData(pathData: IResultsPathData) {
    this.buildPlanId = pathData.buildPlanId || this.buildPlanId
    this.jobNumber = pathData.jobNumber || this.jobNumber
    this.setSimulationStep(pathData.simulationStep)
  }

  startVisualization() {
    if (!this.currentSimulationStep) return false
    if (this.simulationStepsData.get(this.currentSimulationStep.value)) return false

    return true
  }

  clearResults() {
    this.jobNumber = undefined
    if (this.socket) {
      this.socket.off('disconnect', this.onSocketDisconnect)
      this.socket.disconnect()
    }

    this.stepsList.splice(0, this.stepsList.length)
    this.streamHandler.clear()
    this.handersVisibility.clear()
    this.simulationStepsData.forEach((value: SimulationStepHandler, key: string) => {
      value.clearData()
    })

    this.commandMap.clear()
    this.simulationStepsData.clear()
    this.initCommands()
  }

  renderScene() {
    // Sometimes one render call is not enough to refresh scene
    this.onRenderRequested.trigger()
    this.scene.render()
  }

  getHandlerVisibility(kind: CategoryKind): boolean {
    return this.handersVisibility.get(kind)
  }

  protected abstract helloCommandHandler()

  protected setSimulationStep(step: ISimulationStep) {
    if (!step || this.currentSimulationStep === step) return

    if (this.getCurrentSimulationStep()) {
      this.getCurrentSimulationStep().activateStep(false)
    }

    this.currentSimulationStep = undefined

    if (this.simulationStepsData.get(step.value)) {
      this.simulationStepsData.get(step.value).activateStep(true)
    }

    this.currentSimulationStep = step
  }

  protected getCurrentSimulationStep(): SimulationStepHandler {
    if (!this.currentSimulationStep) return
    return this.simulationStepsData.get(this.currentSimulationStep.value)
  }

  protected initCommands() {
    this.currentSimCompVersion = new SimCompVersion()
    this.handersVisibility.set(CategoryKind.BUILDPLATE, true)
    this.handersVisibility.set(CategoryKind.PARTS, true)
    this.handersVisibility.set(CategoryKind.COUPONS, true)
    this.handersVisibility.set(CategoryKind.SUPPORTS, true)
    this.isCurComponentRange = false
    this.helloCommand = { id: createGuid(), name: WsCommands.Hello }
    this.sizeCompensationCommand = { id: createGuid(), name: WsCommands.SizeCompensationFiles }

    this.commandMap.set(this.helloCommand.id, () => this.helloCommandHandler())
    this.commandMap.set(this.sizeCompensationCommand.id, (msg: IVisualizationServiceMessage) => {
      const result = msg.message.result
      if (!result.success) {
        messageService.showWarningMessage(i18n.t('exportCompensationError') as string)
        this.streamHandler.toggleCompensationExportProgress(false)
        return
      }

      this.streamHandler.targetSize = result.size
      this.streamHandler.getFileStream(this.buildPlanId, this.jobNumber)
    })
  }

  protected getFileNamesByPattern(result: IDataDirectoryStructure, criteria) {
    const path = result.path.slice(1).join('/')
    if (result.groups.length > 0) {
      const entry = result.groups.find(criteria)

      if (entry) {
        return entry.files.map((f) => `${path}/${f.label}`)
      }
    }

    if (result.files.length > 0) {
      const entry = result.files.find(criteria)

      if (entry) {
        return `${path}/${entry.label}`
      }
    }
  }

  protected triggerEvent(event: RMEvent, args) {
    this.resultsEvent.trigger({ args, event })
  }

  private changeCurrentColorView(view: SimulationColoringView) {
    if (!this.getCurrentSimulationStep()) return
    this.getCurrentSimulationStep().changeDatasetColorView(view)
  }

  private setXrayView(min: number, max: number) {
    if (!this.getCurrentSimulationStep()) return
    this.getCurrentSimulationStep().setXrayView(min, max)
  }

  private adjustColorRange() {
    if (!this.getCurrentSimulationStep()) return
    this.getCurrentSimulationStep().applyColorScheme()
  }

  /**
   * Starts Web Socket communication with Paraview Web Server
   */
  private startCommunication(params: IResultsConnectionParams) {
    const connectOptions = {
      rejectUnauthorized: false,
      query: {
        token: params.token,
        tenant: params.tenant,
        buildPlanId: this.buildPlanId,
      },
      agent: false,
      upgrade: false,
      transports: ['websocket', 'polling'],
      path: '/visualization',
      reconnection: false,
    }

    this.socket = io(params.url, connectOptions)
    this.socket.io.on('error', (error) => {
      if (!this.socket.connected) {
        this.triggerEvent(RMEvent.ParaviewError, { message: error.message, type: PWError.ErrorBeforeConnection })
      }
    })

    this.socket.on('connect', () => {
      this.socket.emit('command', this.helloCommand)
    })

    this.socket.on('message', (msg: IVisualizationServiceMessage) => {
      const sentCommand = this.commandMap.get(msg.id)
      if (sentCommand && msg.status === OperationStatus.Success) {
        sentCommand(msg)
      } else if (msg.status !== OperationStatus.Success) {
        this.changeIsLoading.trigger({ isLoading: false })
        console.warn(`Visualization server returned an error: ${JSON.stringify(msg.message)}`)
        if (msg.content === PARAVIEWWEB_EXIT_CONTENT) {
          this.triggerEvent(RMEvent.ParaviewError, { message: msg.message, type: PWError.ProcessExit })
        } else {
          messageService.showWarningMessage(i18n.t('visualizationServerError').toString())
        }
      }
    })

    this.socket.on('custom_connect_error', this.onSocketConnectError)
    this.socket.on('connect_error', this.onSocketConnectError)
    this.socket.on('disconnect', this.onSocketDisconnect)
  }

  private onSocketConnectError(error) {
    console.warn(`Socket connection to the visualization app failed ${error}`)
    this.triggerEvent(RMEvent.ParaviewError, { message: error.message, type: PWError.SocketError })
  }

  private onSocketDisconnect(reason) {
    console.warn(`Socket disconnected due to: ${reason}`)
    this.triggerEvent(RMEvent.ParaviewError, { message: reason, type: PWError.SocketClosed })
    this.commandMap.clear()
  }
}
