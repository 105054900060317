import { ObjectWithId } from '@/types/Label/ObjectWithId'
import { ManualPatch } from '@/types/Label/Patch'
import { createGuid } from '@/utils/common'
import { ILabelOrientation } from '@/types/Marking/ILabel'

export interface Placement extends ObjectWithId, ManualPatch {}

/**
 * Creates manual placement as a trackable object
 * @param {string} buildPlanItemId Build plan item id
 * @param {string} componentId Component id
 * @param {string} geometryId Geometry id
 * @param {ILabelOrientation} orientation Manual placement orientation
 * @param {string} id placement id based on patch id
 * @returns {Placement} Manual placement with transformation as a trackable object
 */
export const createPlacement = (
  buildPlanItemId: string,
  componentId: string,
  geometryId: string,
  orientation: ILabelOrientation,
  rotationAngle: number,
  id?: string,
) => {
  const placement: Placement = {
    buildPlanItemId,
    componentId,
    geometryId,
    orientation,
    rotationAngle,
    id: id || createGuid(),
  }

  return placement
}
