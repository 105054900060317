import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import Vue from 'vue'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import Component from 'vue-class-component'

const labelStore = namespace(StoresNamespaces.Labels)

@Component
export class LabelListMixin extends Vue {
  @labelStore.Getter activeLabelSet: InteractiveLabelSet

  @labelStore.Mutation setIsSilent: (isSilent: boolean) => void

  isActive(label: InteractiveLabelSet): boolean {
    return this.activeLabelSet && label.id === this.activeLabelSet.id
  }
}
