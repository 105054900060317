
import Vue from 'vue'
import Component from 'vue-class-component'
import Button from '@/components/controls/Common/Button.vue'
import i18n from '@/plugins/i18n'

@Component({
  components: {
    Button,
  },
})
export default class ConnectionErrorModal extends Vue {
  title = null
  isShow = false
  error = null

  open(dialogTitle: string, error) {
    this.title = i18n.t('connectionError.title', { title: dialogTitle })
    this.error = error
    this.isShow = true
  }

  close() {
    this.isShow = false
  }
}
