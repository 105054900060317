
import { extend } from 'vee-validate'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import VSelect from 'vuetify/lib/components/VSelect'
import { namespace } from 'vuex-class'

import EditableLabel from '@/components/controls/Common/EditableLabel.vue'
import Menu from '@/components/controls/Common/Menu.vue'
import { TenantAdminBindersMixin } from '@/components/layout/admin/mixins/TenantAdminBindersMixin'
import PrintStrategyStatusToggler from '@/components/layout/admin/printStrategy/PrintStrategyStatusToggler.vue'
import { ClickOutsideMixin } from '@/components/layout/mixins/ClickOutsideMixin'
import { RouterNames } from '@/router'
import { CheckPrintStrategyNameExistsPayload, UpdatePrintStrategyNamePayload } from '@/store/modules/sites/types'
import StoresNamespaces from '@/store/namespaces'
import { PrintingTypes } from '@/types/IMachineConfig'
import { PrintStrategyStatus } from '@/types/Sites/PrintStrategyStatus'
import {
  AdditionalPrintStrategyInfo,
  DropDownItem,
  EditableParameter,
  Measurement,
  ParameterSet,
  PrintStrategyInfo,
  PrintStrategyParameterSetDto,
  PrintStrategyParameterType,
  SiteMachineConfigMaterialBinderDto,
} from '@/types/Sites/Site'
import { convert } from '@/utils/converter/lengthConverter'
import { debounce } from '@/utils/debounce'
import DeletePrintStrategyModal from './DeletePrintStrategyModal.vue'
import ParameterEditor from './ParameterEditor.vue'
import PartParameterDuplicateErrorModal from './PartParameterDuplicateErrorModal.vue'

const sitesStore = namespace(StoresNamespaces.Sites)
const commonStore = namespace(StoresNamespaces.Common)

const DEBOUNCE_TIME = 600

@Component({
  components: {
    EditableLabel,
    PrintStrategyStatusToggler,
    Menu,
    PartParameterDuplicateErrorModal,
    ParameterEditor,
    DeletePrintStrategyModal,
  },
})
export default class BJPrintStrategy extends Mixins(ClickOutsideMixin, TenantAdminBindersMixin) {
  @sitesStore.Action fetchPrintStrategyById: (payload: {
    printStrategyId: number
    siteId: number
  }) => Promise<PrintStrategyInfo>
  @sitesStore.Action fetchPrintStrategyAdditionalInfo: (printStrategyId: string) => Promise<AdditionalPrintStrategyInfo>
  @sitesStore.Action updatePrintStrategyStatus: (params: {
    printStrategyId: number
    siteId: number
    status: PrintStrategyStatus
  }) => Promise<PrintStrategyInfo>
  @sitesStore.Action updatePrintStrategyProcessParameterFile: (params: {
    printStrategyId: number
    siteId: number
    processParameterFileId: string
  }) => Promise<PrintStrategyInfo>
  @sitesStore.Action updatePrintStrategyName: (payload: UpdatePrintStrategyNamePayload) => Promise<PrintStrategyInfo>
  @sitesStore.Action checkPrintStrategyNameExists: (payload: CheckPrintStrategyNameExistsPayload) => Promise<boolean>
  @sitesStore.Action isPrintStrategyUsedByBuildPlan: (printStrategyId: number) => Promise<boolean>
  @sitesStore.Action deletePrintStrategy: (printStrategyId: number) => Promise<number>
  @sitesStore.Action addPrintStrategyParameterSet: (params: {
    printStrategyId: number
    siteId: number
    parameterSetId: number
    layerThickness?: number
  }) => Promise<PrintStrategyInfo>
  @sitesStore.Action deletePrintStrategyParameterSet: (params: {
    printStrategyId: number
    siteId: number
    parameterSetId: string
  }) => Promise<PrintStrategyInfo>
  @sitesStore.Action updatePrintStrategyDefaults: (params: {
    printStrategyId: number
    siteId: number
    defaults: object
  }) => Promise<PrintStrategyInfo>
  @sitesStore.Action copyParameterSet: (payload: { parameterSetId: number; implicit: boolean }) => Promise<ParameterSet>
  @sitesStore.Action copyProductionSet: (productionSetId: number) => Promise<any>
  @sitesStore.Action setBinder: (payload: { printStrategyId; machineConfigMaterialBinderId; siteId }) => Promise<void>

  @sitesStore.Mutation clearPrintStrategyState: () => void
  @sitesStore.Mutation renameProcessParameter: (payload: DropDownItem) => void
  @sitesStore.Mutation renamePartParameter: (payload: { id: number; name: string }) => void
  @sitesStore.Mutation setLoadingStatus: (status: boolean) => void

  @sitesStore.Getter getSelectedPrintStrategy: PrintStrategyInfo
  @sitesStore.Getter getSelectedPrintStrategyParameterSets: PrintStrategyParameterSetDto[]
  @sitesStore.Getter getPrintStrategyAdditionalInfo: AdditionalPrintStrategyInfo
  @sitesStore.Getter getCopiedParameterSetId: number
  @sitesStore.Getter getCopiedProductionSetId: number
  @sitesStore.Getter getSiteMachineConfigMaterialBinders: SiteMachineConfigMaterialBinderDto[]
  @sitesStore.Getter isLoading: boolean

  @commonStore.Getter tooltipOpenDelay: number

  siteId: number
  printStrategyId: number
  editableField: string = null
  editableParameter: EditableParameter = null
  formFieldProcessing = false
  isMounted: boolean = false
  isPrintStrategyUsed = true
  selectedProcessParameterFileId = null

  measurement = Measurement

  convert = convert

  $refs!: {
    deleteModal: InstanceType<typeof DeletePrintStrategyModal>
    editor: ParameterEditor
    newPartParam: VSelect
    processFile: VSelect
    partParamSelector: VSelect
    uploadPartParam: VSelect
  }

  async updateBinder(machineConfigMaterialBinderId) {
    await this.setBinder({
      machineConfigMaterialBinderId,
      printStrategyId: this.getSelectedPrintStrategy.id,
      siteId: this.siteId,
    })
  }

  get binders() {
    return this.getSiteMachineConfigMaterialBinders.map((b) => {
      return { text: b.name, value: b.machineConfigMaterialBinderId }
    })
  }

  get isViewParameterButtonAvailable(): boolean {
    return this.selectedProcessParameterFileId !== null
  }

  get isProcessParameterLoadedInEditor(): boolean {
    if (this.selectedProcessParameterFileId === null) {
      return false
    }
    return this.isParameterLoadedInEditor(this.selectedProcessParameterFileId, PrintStrategyParameterType.Process)
  }

  get printStrategyFieldName() {
    return this.$t('tenantAdminMessages.printStrategy.sections.printStrategyName').toString()
  }

  async beforeMount() {
    this.extendValidationRules()
    this.clearPrintStrategyState()

    await this.loadBinders()
  }

  async copyProcessParameter() {
    if (this.isParameterEditorHasUnsavedChanges()) {
      return
    }

    const copiedProcessParameter = await this.copyProductionSet(this.selectedProcessParameterFileId)

    if (!copiedProcessParameter) return
    this.updateProcessParameterFile(copiedProcessParameter.id)
  }

  isPartParameterCopyInProgress(item) {
    return item.parameterSetId === this.getCopiedParameterSetId
  }

  async copyPartParameter(item: PrintStrategyParameterSetDto) {
    const copiedPartParam = await this.copyParameterSet({ parameterSetId: item.parameterSetId, implicit: false })

    if (!copiedPartParam) return
    await this.addNewPartParameter(copiedPartParam.id)
  }

  async deletePartParameter(item) {
    const isParameterActive = this.isParameterLoadedInEditor(item.parameterSetId, PrintStrategyParameterType.Part)

    if (isParameterActive && this.isParameterEditorHasUnsavedChanges()) {
      return
    }

    this.setLoadingStatus(true)
    try {
      const printStrategy = await this.deletePrintStrategyParameterSet({
        parameterSetId: item.id,
        printStrategyId: this.printStrategyId,
        siteId: this.siteId,
      })

      if (printStrategy && isParameterActive) {
        this.editableParameter = null
      }
      await this.updatePrintStrategyAdditionalInfo()
    } finally {
      this.setLoadingStatus(false)
    }
  }

  dropDownParameterSetName(item) {
    return `${item.name} - ${parseFloat(convert('m', 'mm', item.layerThickness).toPrecision(3))}mm`
  }

  updateDefault(e, fieldName) {
    if (!this.selectedProcessParameterFileId) {
      return
    }

    const defaults = {}
    defaults[fieldName] = e
    this.updatePrintStrategyDefaults({ defaults, printStrategyId: this.printStrategyId, siteId: this.siteId })
  }

  get defaultsTableHeaders() {
    return [
      {
        text: this.$t('tenantAdminMessages.printStrategy.headers.function'),
        value: 'function',
        sortable: false,
        width: 'auto',
      },
      {
        text: this.$t('tenantAdminMessages.printStrategy.headers.partParameter'),
        value: 'partParameter',
        sortable: false,
        align: 'left',
      },
    ]
  }

  get defaults() {
    return [
      {
        function: 'Production',
        partParameter: this.getSelectedPrintStrategy.productionId,
        fieldName: 'productionId',
      },
      {
        function: 'Coupon',
        partParameter: this.getSelectedPrintStrategy.couponId,
        fieldName: 'couponId',
      },
      {
        function: 'Support',
        partParameter: this.getSelectedPrintStrategy.supportImportedVolumeId,
        fieldName: 'supportImportedVolumeId',
      },
    ]
  }

  async addNewPartParameter(e) {
    if (!this.selectedProcessParameterFileId) {
      return
    }

    const partParameterId = e

    if (this.getSelectedPrintStrategyParameterSets.find((ps) => ps.id === partParameterId)) return

    await this.addPrintStrategyParameterSet({
      parameterSetId: partParameterId,
      printStrategyId: this.printStrategyId,
      siteId: this.siteId,
    })

    this.$refs.newPartParam.reset()
    await this.updatePrintStrategyAdditionalInfo()
  }

  get partParametersTableHeaders() {
    return [
      {
        text: this.$t('tenantAdminMessages.printStrategy.sections.partParameters'),
        value: 'name',
        sortable: false,
        width: '375px',
      },
      { text: '', value: 'actions', sortable: false, align: 'left' },
    ]
  }

  extendValidationRules() {
    extend('unique-strategy-name', {
      validate: debounce(DEBOUNCE_TIME, async (value: string) => {
        if (this.formFieldProcessing) {
          return true
        }

        this.formFieldProcessing = true

        const isNameExists = await this.checkPrintStrategyNameExists({
          printStrategyId: this.printStrategyId,
          name: value,
        })

        this.formFieldProcessing = false

        return !isNameExists
      }),
      message: 'Name must be unique',
    })
  }

  get selectedBinderText() {
    const binder = this.binders.find((b) => this.printStrategyMachineConfigMaterialBinderId === b.value)
    return binder && binder.text
  }

  get printStrategyMachineConfigMaterialBinderId() {
    return this.getSelectedPrintStrategy && this.getSelectedPrintStrategy.machineConfigMaterialBinderId
  }

  async mounted() {
    const { printStrategyId, siteId } = this.$route.params
    this.siteId = Number(siteId)
    this.printStrategyId = Number(printStrategyId)
    await this.fetchPrintStrategy()

    await this.fetchPrintStrategyAdditionalInfo(printStrategyId)

    this.initProcessParameter()

    this.extendValidationRules()
    this.isPrintStrategyUsed = await this.isPrintStrategyUsedByBuildPlan(this.printStrategyId)
    this.isMounted = true
  }

  initProcessParameter() {
    if (this.getSelectedPrintStrategy.processParameterFileId === null) {
      this.selectedProcessParameterFileId = null
    } else {
      const items: DropDownItem[] = this.getPrintStrategyAdditionalInfo.availableProcessFiles
      const selected = items.find((item) => item.value === this.getSelectedPrintStrategy.processParameterFileId)
      this.selectedProcessParameterFileId = selected ? selected.value : null
    }
  }

  onCloseEditMode() {
    this.editableField = null
  }

  get processFileText() {
    if (this.getPrintStrategyAdditionalInfo && this.getPrintStrategyAdditionalInfo.availableProcessFiles) {
      const processFile = this.getPrintStrategyAdditionalInfo.availableProcessFiles.find(
        (pf) =>
          pf.value === this.getSelectedPrintStrategy.processParameterFileId ||
          pf.value === this.selectedProcessParameterFileId,
      )
      if (processFile) {
        return processFile.text
      }
    }
    return this.getSelectedPrintStrategy.processParameterFileId.toString()
  }

  get isActiveMenu() {
    if (!this.isMounted) {
      return false
    }
    const isProcessFileMenuActive =
      this.$refs.processFile &&
      this.$refs.processFile.$refs &&
      this.$refs.processFile.$refs.menu &&
      this.$refs.processFile.$refs.menu.isActive

    return isProcessFileMenuActive
  }

  @Watch('isActiveMenu')
  onIsMenuActiveChanged() {
    this.setListenerForClickOutside(this.isActiveMenu, this.closeAllSelectorMenus)
  }

  closeAllSelectorMenus() {
    this.$refs.processFile.$refs.menu.isActive = false
    this.$refs.newPartParam.$refs.menu.isActive = false
  }

  get getProcessParameterLabel() {
    return this.selectedProcessParameterFileId
      ? this.$t('tenantAdminMessages.printStrategy.controls.processParameter')
      : this.$t('tenantAdminMessages.printStrategy.controls.selectProcessParameter')
  }

  get getSelectedPrintStrategyStatus() {
    return this.getSelectedPrintStrategy.status
  }

  get getChangeStatusAvailability() {
    return this.getSelectedPrintStrategyStatus !== PrintStrategyStatus.Incomplete
  }

  isShownEditableField(fieldName: string): boolean {
    return this.editableField === fieldName
  }

  isDisabledEditableField(): boolean {
    return (
      this.getSelectedMachineType.modality !== PrintingTypes.DMLM &&
      this.getSelectedMachineType.modality !== PrintingTypes.BinderJet
    )
  }

  isDeletePartParameterDisabled(item) {
    const isSinglePartParameter = this.getSelectedPrintStrategyParameterSets.length === 1
    const isUsedPartParameter = item.isUsed !== undefined ? item.isUsed : false
    return isUsedPartParameter || (isSinglePartParameter && this.isPrintStrategyUsed) || this.isLoading
  }

  updateProcessParameterFile(e) {
    this.selectedProcessParameterFileId = e
    this.updatePrintStrategyProcessParameterFile({
      processParameterFileId: e,
      printStrategyId: this.printStrategyId,
      siteId: this.siteId,
    })
  }

  get isDeletePrintStrategyDisabled() {
    return this.isPrintStrategyUsed
  }

  get isProcessParameterUpdateDisabled() {
    return this.isPrintStrategyUsed
  }

  async delPrintStrategy() {
    if (this.isParameterEditorHasUnsavedChanges()) {
      return
    }

    let confirm: boolean

    // Print strategy with 'Incomplete' status should be deleted without confirmation
    if (this.getSelectedPrintStrategy.status === PrintStrategyStatus.Incomplete) {
      await this.deletePrintStrategy(this.getSelectedPrintStrategy.id)
      confirm = true
    } else {
      confirm = await this.$refs.deleteModal.open(this.getSelectedPrintStrategy.id)
    }

    if (confirm) {
      const route = {
        name: RouterNames.EditMaterialAdmin,
        params: {
          siteId: this.$route.params.siteId,
          machineConfigId: this.$route.params.machineConfigId,
          materialId: this.$route.params.materialId,
        },
      }

      // @ts-ignore
      this.$router.safePush(route)
    }
  }

  showEditableField(fieldName: string): void {
    this.editableField = fieldName
  }

  async changeStatus(status: PrintStrategyStatus) {
    await this.updatePrintStrategyStatus({ status, printStrategyId: this.printStrategyId, siteId: this.siteId })
    this.editableField = null
  }

  async changePrintStrategyName(name: string) {
    if (this.formFieldProcessing) {
      return
    }

    this.formFieldProcessing = true

    const strategy = await this.updatePrintStrategyName({
      name,
      printStrategyId: this.printStrategyId,
      siteId: this.siteId,
    })

    if (strategy) {
      this.editableField = null
    }

    this.formFieldProcessing = false
  }

  onViewProcessParameterClick() {
    if (this.editableParameter) {
      if (this.isParameterEditorHasUnsavedChanges()) {
        return
      }

      const { id, type } = this.editableParameter
      const isSameParameterChecked =
        id === this.selectedProcessParameterFileId && type === PrintStrategyParameterType.Process
      if (isSameParameterChecked) {
        this.editableParameter = null
        return
      }
    }
    this.editableParameter = {
      id: this.selectedProcessParameterFileId,
      type: PrintStrategyParameterType.Process,
      printStrategyId: this.printStrategyId,
    }
  }

  onViewPartParameterClick(item: PrintStrategyParameterSetDto) {
    if (this.editableParameter) {
      if (this.isParameterEditorHasUnsavedChanges()) {
        return
      }

      const { id, type } = this.editableParameter
      const isSameParameterChecked = id === item.parameterSetId && type === PrintStrategyParameterType.Part
      if (isSameParameterChecked) {
        this.editableParameter = null
        return
      }
    }

    this.editableParameter = {
      id: item.parameterSetId,
      type: PrintStrategyParameterType.Part,
      printStrategyId: this.printStrategyId,
    }
  }

  onParameterRenamed(payload: EditableParameter) {
    if (this.editableParameter.type === PrintStrategyParameterType.Process) {
      this.renameProcessParameter({ value: payload.id, text: payload.name })
    } else {
      this.renamePartParameter({ id: payload.id, name: payload.name })
    }
  }

  onParameterEditorClose() {
    this.editableParameter = null
  }

  async onParameterEditorSave() {
    await this.fetchPrintStrategy()
  }

  isPartParameterLoadedInEditor(item: PrintStrategyParameterSetDto): boolean {
    return this.isParameterLoadedInEditor(item.parameterSetId, PrintStrategyParameterType.Part)
  }

  isParameterLoadedInEditor(parameterId: number, parameterType: PrintStrategyParameterType): boolean {
    if (!this.editableParameter) {
      return false
    }

    return this.editableParameter.id === parameterId && this.editableParameter.type === parameterType
  }

  async updatePrintStrategyAdditionalInfo() {
    await this.fetchPrintStrategyAdditionalInfo(this.getSelectedPrintStrategy.id.toString())
  }

  isParameterEditorHasUnsavedChanges(): boolean {
    if (this.$refs.editor.hasUnsavedChanges()) {
      this.$refs.editor.toggleEditingParameterModal(true)
      return true
    }

    this.$refs.editor.toggleParameterNameField(false)
    return false
  }

  async fetchPrintStrategy() {
    await this.fetchPrintStrategyById({
      printStrategyId: this.printStrategyId,
      siteId: this.siteId,
    })
  }
}
