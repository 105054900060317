import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { IOptionalMultiItemCollectorState } from './types'
import { IRootState } from '@/store/types'
import { SelectionUnit } from '@/types/BuildPlans/IBuildPlan'

export const state: IOptionalMultiItemCollectorState = {
  collectors: [],
  activeCollector: null,
}

const namespaced: boolean = true

export const optionalMultiItemCollector: Module<IOptionalMultiItemCollectorState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
