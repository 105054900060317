
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { GeometryDefect } from '@/visualization/models/DataModel'
import DefectWrapper from '@/components/layout/parts/insight/group/DefectWrapper.vue'
import DefectShape from '@/components/layout/parts/insight/group/DefectShape.vue'
import { DefectInsightMixin } from '@/components/layout/parts/insight/mixins/DefectInsightMixin'
import { isNumber } from '@/utils/number'

@Component({
  components: { DefectWrapper, DefectShape },
})
export default class PartDefectByInsight extends DefectInsightMixin {
  @Prop() defect: GeometryDefect
  @Prop() defectIndex: number

  isDefectHovered() {
    return (
      this.hoveredDefect !== null &&
      this.hoveredDefect.type === this.defect.type &&
      Boolean(
        this.hoveredDefect.indices.find((ind) => ind.defectIndex === this.defectIndex && !isNumber(ind.shapeIndex)),
      )
    )
  }

  isDefectSelected() {
    return (
      this.selectedDefect !== null &&
      this.selectedDefect.type === this.defect.type &&
      Boolean(this.selectedDefect.indices.find((ind) => ind.defectIndex === this.defectIndex))
    )
  }

  hoverIntoDefect() {
    const hoverableDefect = { defectType: this.defect.type, defectIndex: this.defectIndex }
    this.setHoveredDefect(hoverableDefect)
    this.toggleDefectsHighlight({ defect: this.hoveredDefect, showHighlight: true })
  }

  hoverOutOfDefect() {
    this.toggleDefectsHighlight({ defect: this.hoveredDefect, showHighlight: false })
    this.setHoveredDefect(null)
  }

  selectDefect() {
    const defect = { defectType: this.defect.type, defectIndex: this.defectIndex }
    if (this.isDefectSelected()) {
      this.removeSelectedDefect(defect)
      this.selectDefects({
        defect: { type: this.defect.type, indices: [{ defectIndex: this.defectIndex }] },
        deselectIfSelected: true,
      })
      this.hoverIntoDefect()
    } else {
      this.addSelectedDefect(defect)
      this.selectDefects({
        defect: { type: this.defect.type, indices: [{ defectIndex: this.defectIndex }] },
        deselectIfSelected: false,
      })
    }
  }
}
