
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ValidationObserver } from 'vee-validate'
import Button from '@/components/controls/Common/Button.vue'
import TextArea from '@/components/controls/Common/TextArea.vue'
import StoresNamespaces from '@/store/namespaces'
import { MAX_STRING_LENGTH } from '@/constants'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component({
  components: {
    Button,
    TextArea,
  },
})
export default class EditVariantDescriptionModal extends Vue {
  @Prop({ default: '' }) variantDescription: string
  @Prop() editDescription: (value: string) => Promise<void>
  @buildPlansStore.Getter isVariantProcessing: boolean

  $refs!: {
    textarea: TextArea
    form: InstanceType<typeof ValidationObserver>
  }

  isShownDialog: boolean = true
  description: string

  beforeMount() {
    this.description = this.variantDescription
  }

  mounted() {
    this.setFocus()
    this.$refs.textarea.selectAll()
  }

  async onOk() {
    const isValid = await this.$refs.form.validate()
    if (!isValid) {
      return
    }

    if (!this.editDescription) {
      return
    }

    await this.editDescription(this.description)
    this.onClose()
  }

  onClose() {
    this.$emit('onClose')
  }

  setFocus() {
    setTimeout(() => {
      if (this.$refs.textarea) {
        this.$refs.textarea.focus()
      }
    }, 0)
  }

  getDescripValidationRules() {
    return {
      max: MAX_STRING_LENGTH,
    }
  }
}
