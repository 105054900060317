import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { IFileExplorerState } from './types'
import { IRootState } from '@/store/types'
import { ViewMode, DetailsPanelViewMode } from '@/types/FileExplorer/ViewMode'
import { SortParamsKey } from '@/types/FileExplorer/SortParamsKey'
import { FilterParamsKey } from '@/types/FileExplorer/FilterParamsKey'
import localStorageService from '@/services/localStorageService'
import {
  DEFAULT_IS_ALLOWED_TO_BE_TRASHED_SELECTED_ITEMS,
  DEFAULT_PAGINATION_LIMIT,
  FILE_EXPLORER_TABLE_STATE_KEY,
} from '@/constants'

const storage = localStorageService.get(FILE_EXPLORER_TABLE_STATE_KEY)
export const state: IFileExplorerState = {
  dataState: {
    numberOfPendingRequests: 0,
    numberOfFailedRequests: 0,
    failedMessages: [],
  },
  viewMode: storage && storage.viewMode ? storage.viewMode : ViewMode.Folders,
  items: {
    byId: {},
    allIds: [],
    recentIds: [],
    sharedIds: [],
    favoriteIds: [],
  },
  folders: {
    byId: {},
    allIds: [],
  },
  selectedItems: [],
  clickedNameItem: null,
  lastAddedItem: null,
  root: null,
  sortParams:
    storage && storage.sort
      ? storage.sort
      : {
          [SortParamsKey.AllFiles]: null,
          [SortParamsKey.Favorites]: null,
          [SortParamsKey.Recent]: null,
          [SortParamsKey.Shared]: null,
          [SortParamsKey.Trash]: null,
          [SortParamsKey.DashboardJobs]: null,
          [SortParamsKey.PrintOrders]: null,
        },
  filterParams:
    storage && storage.filters
      ? storage.filters
      : {
          [FilterParamsKey.AllFiles]: null,
          [FilterParamsKey.Favorites]: null,
          [FilterParamsKey.Recent]: null,
          [FilterParamsKey.Shared]: null,
          [FilterParamsKey.Trash]: null,
          [FilterParamsKey.PrintOrdersStatus]: null,
          [FilterParamsKey.PrintOrdersMaterial]: null,
          [FilterParamsKey.PrintOrdersMachine]: null,
          [FilterParamsKey.PrintOrdersBuildPlate]: null,
          [FilterParamsKey.PrintOrdersParameter]: null,
          [FilterParamsKey.SearchPrintOrdersStatus]: null,
          [FilterParamsKey.SearchPrintOrdersMaterial]: null,
          [FilterParamsKey.SearchPrintOrdersMachine]: null,
          [FilterParamsKey.SearchPrintOrdersBuildPlate]: null,
          [FilterParamsKey.SearchPrintOrdersParameter]: null,
          [FilterParamsKey.JobTypes]: null,
        },
  permissions: {
    byId: {},
  },
  isOpenDetailsPanel: true,
  detailsPanelMode: DetailsPanelViewMode.Default,
  isMoveItems: false,
  favorites: {
    items: [],
    snapshot: [],
    pageRange: [0, DEFAULT_PAGINATION_LIMIT],
  },
  search: {
    parameters: '',
    items: [],
    snapshot: [],
    pageRange: [0, DEFAULT_PAGINATION_LIMIT],
  },
  jobs: {
    byItemId: {},
  },
  rootOfMovingItems: null,
  isOpenRenameDialog: false,
  isOpenMoveToTrashErrorDialog: false,
  isOpenMoveToTrashItemDialog: false,
  itemDetails: {
    id: null,
    createdAt: null,
    createdBy: null,
    folder: null,
    collaborators: [],
    numberOfItems: 0,
    numberOfHiddenItems: 0,
  },
  itemPermissionCollaborators: {
    byId: {},
    allIds: [],
  },
  itemDetailsOfSelectedItems: [],
  isForceUpdateDetails: false,
  collaborationData: {
    canManageCollaboration: null,
    collaborators: [],
    amountOfNestedItemsWithoutPermissionsToView: 0,
    grantedParentPermissions: [],
  },
  requestRaceConditionTokenObjects: {
    detailsRequest: {},
  },
  canBeTrashedSelectedItemsResult: DEFAULT_IS_ALLOWED_TO_BE_TRASHED_SELECTED_ITEMS,
  numberOfHiddenItemsInCurrentFolder: 0,
  numberOfHiddenItemsInFavorites: 0,
  numberOfHiddenItemsInRecent: 0,
  trashActionsInfo: {
    relatedItemsToDelete: [],
    hasDeletePermission: false,
    relatedItemsToRestore: [],
    hasRestorePermission: false,
  },
  isShownLockedBuildPlanModal: false,
  lockItemInfo: {
    editVariant: null,
    previewVariant: null,
  },
  paginationData: {
    currentPage: 0,
    totalItems: null,
    totalPages: null,
    size: DEFAULT_PAGINATION_LIMIT,
  },
  collaboratorsToRemoveList: [],
  isShownCreateBuildPlanDialog: false,
  isShownCreateSinterPlanDialog: false,
  isShownCreateFolderModal: false,
  itemsWithoutAccess: [],
  isPrintOrderDetailsLoading: false,
  isAllowedToEndCollaboration: false,
}

const namespaced: boolean = true

export const fileExplorer: Module<IFileExplorerState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
