
import Vue from 'vue'
import Component from 'vue-class-component'
import { extend, ValidationObserver } from 'vee-validate'
import { namespace } from 'vuex-class'
import { Prop } from 'vue-property-decorator'

import Button from '@/components/controls/Common/Button.vue'
import TextField from '@/components/controls/Common/TextField.vue'
import StoresNamespaces from '@/store/namespaces'
import { CheckItemUniqPayload } from '@/store/modules/fileExplorer/types'
import { debounce } from '@/utils/debounce'
import { ItemType } from '@/types/FileExplorer/ItemType'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const defaultFolderName = 'New Folder'
const DEBOUNCE_TIME = 600

@Component({
  components: {
    Button,
    TextField,
  },
})
export default class CreateFolderModal extends Vue {
  @fileExplorerStore.Action checkItemForUnique: (payload: CheckItemUniqPayload) => boolean
  @fileExplorerStore.Action generateUniqueName: (payload: { name: string; parentId: string }) => Promise<string>

  @Prop({ default: false }) loading: boolean

  $refs!: {
    form: InstanceType<typeof ValidationObserver>
    nameTextField: TextField
  }

  folderName: string = null
  lastValidFolderName: string = null
  root: string = null
  formProcessing: boolean = false
  isSubmitting: boolean = false

  async beforeMount() {
    this.root = this.$route.params.itemId === '0' ? null : this.$route.params.itemId
    await this.generateUniqueFolderName()
    this.extendValidationRules()
    this.$refs.nameTextField.selectAll()
  }

  extendValidationRules() {
    extend('unique', {
      validate: debounce(DEBOUNCE_TIME, async (value) => {
        if (this.loading || this.formProcessing || !this.folderName || this.folderName === this.lastValidFolderName) {
          return true
        }

        this.formProcessing = true

        const isNameExists = await this.checkItemForUnique({
          name: this.folderName,
          parentId: this.root,
          itemType: ItemType.Folder,
        })

        if (!isNameExists) {
          this.lastValidFolderName = value
        }

        this.formProcessing = false

        return !isNameExists
      }),
      message: 'Name must be unique',
    })

    // TODO: Create validation mixin and move most common validation rules into it
    // There is a data base column limitation that does not allow to use strings which are more than 255 symbols
    extend('compact', {
      validate: (value: string) => {
        if (this.formProcessing) {
          return true
        }

        return value.length <= 255
      },
      message: this.$i18n.t('invalidNameLength') as string,
    })
  }

  async generateUniqueFolderName() {
    try {
      this.formProcessing = true
      const name = await this.generateUniqueName({ name: defaultFolderName, parentId: this.root })
      this.folderName = name
      this.lastValidFolderName = name
    } finally {
      this.formProcessing = false
    }
  }

  async onCreateClick() {
    if (this.isSubmitting) {
      return
    }

    this.isSubmitting = true

    const isValid = await this.$refs.form.validate()

    if (isValid) {
      this.$emit('confirm', this.folderName)
    }

    this.isSubmitting = false
  }

  onCloseClick() {
    if (this.loading) {
      return
    }
    this.$emit('close')
  }

  getFolderNameRules() {
    return {
      required: true,
      unique: true,
      compact: true,
    }
  }
}
