import { BoundingBox } from '@/visualization/models/DataModel'
import { SelectedItem } from '../OptionalMultiItemCollector/SelectedItem'
export interface IBaseSimulateCompensation {
  buildPlanId: string
  runCompensation: boolean
  maxIterations: number
  convergenceValue: number
  computeType?: string
  meshOnly?: boolean
}

export interface IDmlmLaserPrameters {
  laserPower: number
  laserSpeed: number
  laserBeamDiameter: number
  hatchSpace: number
}

export interface IDmlmInherentStrainParameters {
  parameterSetId: number
  laserParameters?: IDmlmLaserPrameters
  inherentStrainsValues?: number[]
}

export interface IDmlmSimulateCompensation extends IBaseSimulateCompensation {
  ambientTemperatureInCelsius: number
  buildPlateTemperatureInCelsius: number
  buildPlateThicknessInMm: number
  minWallThicknessInMm: number
  speedVsAccuracy: SimSpeed
  processSteps?: IDmlmSimulateCompensateProcessStep[]
  inherentStrainParameters?: IDmlmInherentStrainParameters[]
  creepDataValidation: boolean
}

export interface II8nOption {
  i8n: string
  value: string
}
export interface IBinderJetSimulateCompensation extends IBaseSimulateCompensation {
  speedVsAccuracy: SimSpeed
  sinterPlateThicknessInMm: number
  shrinkageActivation: boolean
  selectedFrictionCoefficientPlate: string
  selectedFrictionCoefficientSupport: string
  frictionCoefficient: number[]
  binderjetScalingFactor: number[]
  autoMeshSizeActive: boolean
  meshSize: number
  meshSizeUnit: string
  contactDampingFactor: number
  materialModelOption: BinderJetMaterialModelOptions
  parallelMemoryOption: BinderJetParallelMemoryOptions
  solverType: BinderJetSolverTypeOptions
  remesh: boolean
  meshContact: BinderJetMeshContactOptions
  compensationConstraints: IBinderJetCompensationConstrainst[]
  transitionTol: number
  generalContact: boolean
}

export interface IDmlmSimulateCompensateProcessStep {
  processType: StepProcessType
  index: number
  itemsToRemove?: string[]
  ovenTemperatureProfile?: number[][]
  userDefined?: boolean
}

export interface IBinderJetCompensationConstrainst {
  constraintType: BinderJetCompensationConstraintTypes
  planePoint: number[]
  constraintTol: number
  surfaceItem: SelectedItem[]
}

export interface IPartHeights {
  partName: string
  bpItemId: string
  meshId: string
  height: number
}

export enum SimSpeed {
  STANDARD = 'standard',
  FAST = 'fast',
  ACCURATE = 'accurate',
}

export enum SimSpeedUI {
  LOW = 'LOW',
  STANDARD = 'STANDARD',
  HIGH = 'HIGH',
}

export enum SimProcessType {
  BuildSimulation = 'Printing',
  StressRelief = 'Stress Relief',
  MaterialRemovalBP = 'Plate Removal',
  MaterialRemovalSupport = 'Support Removal',
}

export enum StepProcessType {
  Thermal = 'thermal',
  Mechanical = 'mechanical',
  MaterialRemoval = 'material_removal',
  StressRelief = 'stress_relief',
}

export enum InfoStatusCode {
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export enum BinderJetAnalysisTypeOptions {
  GRAVITY = 'gravity',
  FRICTION = 'friction',
}

export enum BinderJetMaterialModelOptions {
  ASYMMETRIC = 'asymmetric',
  SYMMETRIC = 'symmetric',
}

export enum BinderJetParallelMemoryOptions {
  SHARED = 'shared',
  DISTRIBUTED = 'distributed',
}

export enum BinderJetSolverTypeOptions {
  DIRECT = 'direct',
  ITERATIVE = 'iterative',
}

export enum BinderJetMeshContactOptions {
  FRICTIONAL = 'frictional',
  BONDED = 'bonded',
}

export enum BinderJetCompensationConstraintTypes {
  Planar = 'planar',
  Parallel = 'parallel',
}

export type TemperatureProfileType = {
  index: number
  timeItem: number
  temperatureItem: number
}

export type FrictionCoefficientType = {
  surface: string
  plateFrictionCoefficient?: number
  supportFrictionCoefficient?: number
}

export function convertTemperatureProfileToArray(
  heatTreatment: TemperatureProfileType[],
  convertTimeToSeconds: boolean = false,
): number[][] {
  const heatTreatmentArray = []

  heatTreatment.forEach((element) => {
    const newTime = convertTimeToSeconds ? element.timeItem * 60 : element.timeItem
    const timeTemperature = [newTime, element.temperatureItem]
    heatTreatmentArray.push(timeTemperature)
  })

  return heatTreatmentArray
}

export function convertArrayToTemperatureProfile(heatTreatmentArray: number[][]): TemperatureProfileType[] {
  const heatTreatment = []
  let counter: number = 0

  heatTreatmentArray.forEach((element) => {
    const timeTemperatureProfile: TemperatureProfileType = {
      index: counter,
      timeItem: element[0],
      temperatureItem: element[1],
    }
    heatTreatment.push(timeTemperatureProfile)
    counter = counter + 1
  })

  return heatTreatment
}

export function copyTemperatureProfile(
  temperatureProfileToPaste: TemperatureProfileType[],
  temperatureProfileToCopy: TemperatureProfileType[],
) {
  const lastIndex = temperatureProfileToCopy.length
  temperatureProfileToPaste.splice(0, temperatureProfileToPaste.length)

  for (let i = 0; i < lastIndex; i += 1) {
    const timeTemp = temperatureProfileToCopy[i]
    if (temperatureProfileToPaste.length === i) {
      const newtimeTemp: TemperatureProfileType = {
        index: timeTemp.index,
        timeItem: timeTemp.timeItem,
        temperatureItem: timeTemp.temperatureItem,
      }
      temperatureProfileToPaste.push(newtimeTemp)
    }
  }
}

export function roundNumberByDigits(value: number, digits: number): number {
  const factor: number = Math.pow(10, digits)
  return Math.round(value * factor) / factor
}

export function isValidationsRuleSatisfied(parameter: number, rule): boolean {
  let isValid: boolean = true
  if (rule.min_value !== undefined) {
    isValid = isValid && parameter >= rule.min_value
  }
  if (rule.max_value !== undefined) {
    isValid = isValid && parameter <= rule.max_value
  }
  return isValid
}

export function calculateMacroLayers(
  wallThickness: number,
  speed: SimSpeed,
  layerThickness: number,
  maxZ: number,
): { totalMacroLayers: number; macroLayerThickness: number; minElementSize: number } {
  let minElementSize: number = 1
  let macroLayerExponent: number = 0

  switch (speed) {
    case SimSpeed.FAST:
      {
        minElementSize = wallThickness * 1
        macroLayerExponent = 3
      }
      break
    case SimSpeed.STANDARD:
      {
        minElementSize = wallThickness * 0.75
        macroLayerExponent = 2
      }
      break
    case SimSpeed.ACCURATE:
      {
        minElementSize = wallThickness * 0.5
        macroLayerExponent = 1
      }
      break
  }
  let macroLayerThickness = minElementSize * Math.pow(2, macroLayerExponent)

  if (macroLayerThickness < layerThickness) {
    macroLayerThickness = layerThickness
  }

  return {
    macroLayerThickness,
    minElementSize,
    totalMacroLayers: Math.ceil(Math.abs(maxZ) / macroLayerThickness),
  }
}

export function calculateMaxMinWallThickness(speed: SimSpeed, maxZ: number): number {
  let minElementSize: number = 1
  let macroLayerExponent: number = 0
  let maxWallThickness: number = 0
  switch (speed) {
    case SimSpeed.FAST:
      {
        minElementSize = 1
        macroLayerExponent = 3
      }
      break
    case SimSpeed.STANDARD:
      {
        minElementSize = 0.75
        macroLayerExponent = 2
      }
      break
    case SimSpeed.ACCURATE:
      {
        minElementSize = 0.5
        macroLayerExponent = 1
      }
      break
  }

  maxWallThickness = Math.abs(maxZ) / (minElementSize * Math.pow(2, macroLayerExponent))
  return roundNumberByDigits(maxWallThickness, 3)
}

export function calculateMeshSizeRange(boundingBox: BoundingBox): { min_value: number; max_value: number } {
  const volume: number =
    (boundingBox.maxX - boundingBox.minX) *
    (boundingBox.maxY - boundingBox.minY) *
    (boundingBox.maxZ - boundingBox.minZ)
  const exponent: number = 1 / 3
  const powThird: number = Math.pow(Math.abs(volume), exponent)
  const minimum: number = 0.01 * powThird
  const maximum: number = 0.1 * powThird

  return {
    min_value: roundNumberByDigits(minimum, 3),
    max_value: roundNumberByDigits(maximum, 3),
  }
}
