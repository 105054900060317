
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import Button from '@/components/controls/Common/Button.vue'
import StoresNamespaces from '@/store/namespaces'

const userStore = namespace(StoresNamespaces.User)

const MIGRATION_TIMEOUT = 5000

@Component({
  components: {
    Button,
  },
})
export default class SsoMigrationModal extends Vue {
  @userStore.Action ssoMigrateUser: Function

  isShow = false
  isMigrated = false
  title = null
  message = null
  confirmLabel = null
  resolve = null

  options = {
    force: false,
    width: 475,
    zIndex: 200,
  }

  async open(options?: any): Promise<boolean> {
    this.isShow = true
    this.title = this.$t('ssoUserMigration.title')
    this.message = this.$t('ssoUserMigration.progressMessage')
    this.options = Object.assign(this.options, options)

    setTimeout(async () => {
      await this.ssoMigrateUser(this.options.force)

      this.isMigrated = true
      this.confirmLabel = this.$t('ssoUserMigration.confirmButton')
      this.message = this.$t('ssoUserMigration.migratedMessage')
    }, MIGRATION_TIMEOUT)

    return new Promise((resolve) => {
      this.resolve = resolve
    })
  }

  confirm() {
    this.resolve(true)
    this.isShow = false
  }
}
