
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'

import { debounce } from '@/utils/debounce'
import { TOOLTIP_OPEN_DELAY } from '@/constants'

const LABEL_ID_TEMPLATE = 'label_'
const CONTENT_ID_TEMPLATE = 'content_'

@Component
export default class HoverableTooltip extends Vue {
  // Id of the element the cursor is hovering over
  @Prop({ default: null }) targetId: string
  @Prop({ default: false }) isTooltipDisabled: boolean

  show = false
  isMouseOnTooltip = false
  uuid = null

  toggleDebounced = debounce(TOOLTIP_OPEN_DELAY, this.toggle)

  get labelId() {
    return `${LABEL_ID_TEMPLATE}${this.uuid}`
  }

  get contentId() {
    return `${CONTENT_ID_TEMPLATE}${this.uuid}`
  }

  @Watch('show')
  onShowChanged() {
    if (!this.show) return

    const labelId = this.targetId || this.labelId
    const tooltipLabel: HTMLElement = document.getElementById(labelId)
    const tooltipLabelRect = tooltipLabel.getBoundingClientRect()
    const tooltipContent: HTMLElement = document.getElementById(this.contentId)
    const bottomPadding = 10

    tooltipContent.style.left = `${tooltipLabelRect.x + tooltipLabelRect.width / 2}px`
    tooltipContent.style.top = `${tooltipLabelRect.y - bottomPadding}px`
  }

  beforeMount() {
    this.uuid = uuidv4()
  }

  setMouseOnTooltip(isMouseOnTooltip: boolean) {
    this.isMouseOnTooltip = isMouseOnTooltip
    this.toggleDebounced()
  }

  toggle() {
    this.show = this.isTooltipDisabled ? false : this.isMouseOnTooltip
  }
}
