
import { TranslateResult } from 'vue-i18n'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/components/controls/Common/Button.vue'
import Menu from '@/components/controls/Common/Menu.vue'
import NumberField from '@/components/controls/Common/NumberField.vue'
import Selector from '@/components/controls/Common/Selector.vue'
import { PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import BuildPlanInsightsPanel from '@/components/layout/buildPlans/BuildPlanInsightsPanel.vue'
import CreateBuildPlanModal from '@/components/layout/buildPlans/CreateBuildPlanModal.vue'
import CreateSinterPlanModal from '@/components/layout/buildPlans/CreateSinterPlanModal.vue'
import BuildPlanDetailsPart from '@/components/layout/buildPlans/details/BuildPlanDetailsPart.vue'
import ProcessStateChangeModal from '@/components/layout/buildPlans/details/ProcessStateChangeModal.vue'
import RemovePartModal from '@/components/layout/buildPlans/details/RemovePartModal.vue'
import BuildPlanPartPropertiesParts from '@/components/layout/buildPlans/partProperties/BuildPlanPartPropertiesParts.vue'
import ShareItemModal from '@/components/layout/FileExplorer/ShareItemModal.vue'
import ModalsStateMixin from '@/components/layout/FileExplorer/Table/mixins/ModalsStateMixin'
import Splitter from '@/components/layout/Splitter.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import {
  DEFAULT_PRINT_STRATEGY_PARAMETER_SET_ID,
  DEFAULT_SUPPORT_VOLUME_PERCENT_THAT_IS_USED,
  DELETE_CODE,
  NOT_AVAILABLE_LABEL,
} from '@/constants'
import { BuildPlanCostItemDTO } from '@/pages/BuildPlans/dtos/BuildPlanCostItemDTO'
import { BuildPlanCost } from '@/pages/BuildPlans/models/BuildPlanCost'
import { RouterNames } from '@/router'
import StoresNamespaces from '@/store/namespaces'
import {
  GeometryType,
  IBuildChamberPart,
  IBuildPlan,
  IBuildPlanItem,
  IPrintStrategyParameterSet,
  IProductionSet,
  ISelectable,
  IUpdateBuildPlanItemParamsDto,
  SelectionUnit,
} from '@/types/BuildPlans/IBuildPlan'
import { IInsightSettings } from '@/types/BuildPlans/IBuildPlanInsight'
import { IBuildPlate } from '@/types/BuildPlates/IBuildPlate'
import { IInsightsCount } from '@/types/Common/Insights'
import { VersionableModel } from '@/types/Common/VersionableModel'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { Permission } from '@/types/FileExplorer/Permission'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import { IIBCPlan, IIBCPlanItem } from '@/types/IBCPlans/IIBCPlan'
import { IMachineConfig, PrintingTypes } from '@/types/IMachineConfig'
import { IMaterial } from '@/types/IMaterial'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { ManufacturingRegions } from '@/types/ManufacturingRegions'
import { IJob } from '@/types/PartsLibrary/Job'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { EditableParameter, PrintStrategyParameterType, PrintStrategyShortInfo } from '@/types/Sites/Site'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { EditBuildPlanCommand } from '@/types/UndoRedo/EditBuildPlanCommand'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { createGuid } from '@/utils/common'
import { getDefaultVariantIdFromVersionAndPath } from '@/utils/fileExplorerItem/fileExplorerItemUtils'
import { getDefaultBaseOnType } from '@/utils/parameterSet/parameterSetUtils'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import CustomPrintStrategyModal from './customStrategy/CustomPrintStrategyModal.vue'
import BuildPlanDetailsPartListItem from './details/BuildPlanDetailsPartListItem.vue'
import MachineMaterialSelectionMixin from './mixins/MachineMaterialSelection'

enum CustomPrintStrategyMode {
  Edit = 'Edit',
  View = 'View',
  NotCreated = 'NotCreated',
}

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const commonStore = namespace(StoresNamespaces.Common)
const partsStore = namespace(StoresNamespaces.Parts)
const commandManagerStore = namespace(StoresNamespaces.CommandManager)
const jobsStore = namespace(StoresNamespaces.Jobs)
const labelStore = namespace(StoresNamespaces.Labels)

@Component({
  components: {
    Selector,
    NumberField,
    Button,
    BuildPlanInsightsPanel,
    RemovePartModal,
    CreateBuildPlanModal,
    CreateSinterPlanModal,
    ConfirmModal,
    CustomPrintStrategyModal,
    Splitter,
    BuildPlanPartPropertiesParts,
    Menu,
    ProcessStateChangeModal,
    BuildPlanDetailsPartListItem,
    ShareItemModal,
  },
})
export default class BuildPlanDetails extends Mixins(MachineMaterialSelectionMixin, ModalsStateMixin) {
  @buildPlansStore.Getter getSelectedParts: ISelectable[]
  @buildPlansStore.Getter getBuildChamberParts: IBuildChamberPart[]
  @buildPlansStore.Getter getBuildPlanItemsByPartId: (partId: string) => IBuildPlanItem[]
  @buildPlansStore.Getter getIBCPlanItemsByPartId: (partId: string) => IIBCPlanItem[]
  @buildPlansStore.Getter getSelectedBuildPlanFinalizingJobs: IJob[]
  @buildPlansStore.Getter getSelectedItemsNames: string[]
  @buildPlansStore.Getter getSelectedPartsCollisions: number[]
  @buildPlansStore.Getter getActiveSelectionMode: SelectionUnit
  @buildPlansStore.Getter getSelectedBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getAllBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes
  @buildPlansStore.Getter getAllMachineConfigs: IMachineConfig[]
  @buildPlansStore.Getter getAllMaterials: IMaterial[]
  @buildPlansStore.Getter parameterSets: IPrintStrategyParameterSet[]
  @buildPlansStore.Getter getSelectedMachineConfigPk: VersionablePk | null
  @buildPlansStore.Getter getSelectedMaterialPk: VersionablePk | null
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getBuildPlanCost: BuildPlanCost
  @buildPlansStore.Getter getCalcCostInProgress: boolean
  @buildPlansStore.Getter getBuildPlanItemDTOs: BuildPlanCostItemDTO[]
  @buildPlansStore.Getter insightSettings: IInsightSettings
  @buildPlansStore.Getter insightsCount: IInsightsCount
  @buildPlansStore.Getter isShownNoMaterialParamsTooltip: boolean
  @buildPlansStore.Getter isSceneReadOnly: boolean
  @buildPlansStore.Getter getIsLoading: boolean
  @buildPlansStore.Getter getMaterialByPk: (pk: VersionablePk) => IMaterial
  @buildPlansStore.Getter getMachineConfigByPk: (pk: VersionablePk) => IMachineConfig
  @buildPlansStore.Getter getBuildPlanProductionSet: () => IProductionSet
  @buildPlansStore.Getter getBuildPlateByPk: (pk: VersionablePk) => IBuildPlate
  @buildPlansStore.Getter getPrintStrategyByPk: (pk: VersionablePk) => PrintStrategyShortInfo
  @buildPlansStore.Getter getBuildPlanPrintStrategy: BuildPlanPrintStrategyDto
  @buildPlansStore.Getter isReadOnly: boolean
  @buildPlansStore.Getter isVariantProcessing: boolean
  @buildPlansStore.Getter isBuildPlanDisposing: boolean
  @buildPlansStore.Getter('getAddPartToolSelectedParts') selectedParts: PartListItemViewModel[]
  @buildPlansStore.Getter getCommandType: CommandType
  @buildPlansStore.Getter getIBCPlan: IIBCPlan
  @buildPlansStore.Getter getCollaboratorsCount: number

  @commonStore.Getter tooltipOpenDelay: number

  @visualizationStore.Getter isLabelCreationMode: boolean
  @visualizationStore.Getter isCrossSectionViewMode: boolean
  @visualizationStore.Getter isClearanceToolViewMode: boolean
  @visualizationStore.Getter getVisualizationLoading: boolean
  @visualizationStore.Getter isInitialized: boolean

  @buildPlansStore.Action deleteSelectedParts: Function
  @buildPlansStore.Action fetchMachineConfigs: Function
  @buildPlansStore.Action fetchMaterials: Function
  @buildPlansStore.Action fetchBuildPlates: Function
  @buildPlansStore.Action fetchBuildPlateMaterials: Function
  @buildPlansStore.Action fetchBuildPlateMachineConfigs: Function
  @buildPlansStore.Action fetchProductionSets: Function
  @buildPlansStore.Action fetchParameterSets: Function
  @buildPlansStore.Action updateBuildPlanV1: (payload: {
    buildPlan: IBuildPlan
    hideAPIErrorMessages?: boolean
  }) => Promise<IBuildPlan>
  @buildPlansStore.Action fetchPrintStrategies: Function
  @buildPlansStore.Action hasCustomPrintStrategy: (buildPlanId: string) => Promise<boolean>
  @buildPlansStore.Action createCustomPrintStrategy: (payload: {
    buildPlanId: string
    printStrategyPk: VersionablePk
  }) => Promise<BuildPlanPrintStrategyDto>
  @buildPlansStore.Action replaceCustomPrintStrategy: (payload: {
    buildPlanId: string
    printStrategyPk: VersionablePk
  }) => Promise<BuildPlanPrintStrategyDto>
  @buildPlansStore.Action getBuildPlanById: (id: string) => Promise<IBuildPlan>
  @buildPlansStore.Action updateBuildPlanItems: (
    buildPlanItemsDtos: IUpdateBuildPlanItemParamsDto[],
  ) => Promise<IBuildPlanItem[]>
  @buildPlansStore.Action setBPItemGeometryProperties: Function
  @buildPlansStore.Action fetchCollaborators: Function
  @buildPlansStore.Action setVisibleWhenPublishedSinterPlan: Function
  @buildPlansStore.Action setVisibleWhenPublishedIbcPlan: Function

  @buildPlansStore.Mutation setInsightSettings: Function
  @buildPlansStore.Mutation setBuildPlan: (buildPlan: IBuildPlan) => void

  @visualizationStore.Mutation selectAndHighlightPart: (payload: {
    buildPlanItemId: string
    deselectIfSelected: boolean
    showGizmo: boolean
  }) => void
  @visualizationStore.Mutation selectAndHighlightParts: (payload: {
    buildPlanItemIds: string[]
    deselectIfSelected: boolean
    showGizmo: boolean
  }) => void
  @visualizationStore.Mutation toggleHighlight: (payload: { buildPlanItemId: string; highlight: boolean }) => void
  @visualizationStore.Mutation toggleMultiHighlight: (payload: {
    buildPlanItemIds: string[]
    highlight: boolean
  }) => void
  @fileExplorerStore.Action getItemPathNames: Function

  @partsStore.Getter getAllSinterParts: IPartDto[]
  @partsStore.Getter getAllIbcParts: IPartDto[]
  @partsStore.Getter partById: (partId: string) => IPartDto
  @partsStore.Getter partsByNominalPartId: (nominalPartId: string) => IPartDto[]

  @commandManagerStore.Mutation addCommand: (command: ICommand) => void

  @jobsStore.Getter getPrintOrder: PrintOrder

  @fileExplorerStore.Getter permissionsByItemId: Record<string, Permission[]>

  @fileExplorerStore.Mutation selectItem: (payload: {
    item: FileExplorerItem | PrintOrder
    selectionType: SelectionTypes
  }) => void

  @labelStore.Getter activeLabelSet: InteractiveLabelSet

  isDeletingPatch = false
  defaultSupportVolumePercent = DEFAULT_SUPPORT_VOLUME_PERCENT_THAT_IS_USED
  defaultManufacturingRegion = ManufacturingRegions.Americas
  insightMenuIsShown: boolean = false
  removePartModalOpen = false
  processStateChangeModal = false
  editBuildPlanModalOpen = false
  editSinterPlanModalOpen = false
  customPrintStrategyModalOpen = false
  buildPlanPath = []
  buildPlanAsItem: FileExplorerItem = null

  openedSections = {
    settings: true,
    selectedParts: true,
    parts: true, // parts in build chamber
    insights: false,
    details: true,
  }

  instancingInputs = {
    rows: null,
    cols: null,
  }

  processStateModalProps = {
    processStateName: '',
    sinterPartsCount: 0,
    partNames: [],
  }

  activeElement: Element = null

  get availableMaterialModality() {
    if (!this.hasPartsAdded) return

    return this.getBuildPlan.modality
  }

  delPressedListener: (ev: KeyboardEvent) => void

  $refs!: {
    customizePrintStrategyDialog: InstanceType<typeof ConfirmModal>
    customPrintStrategyModal: InstanceType<typeof CustomPrintStrategyModal>
  }

  get printStrategyName(): string {
    return this.getBuildPlanPrintStrategy ? this.getBuildPlanPrintStrategy.name : '-'
  }

  get allowUseSinterParts() {
    return this.isIbcPlan ? this.getIBCPlan.visibleWhenPublished : this.getBuildPlan.visibleWhenPublished
  }

  get isUsedStrategyImplicit(): boolean {
    const strategy = this.getBuildPlanPrintStrategy
    return strategy && strategy.implicit
  }

  get customizeButtonText(): TranslateResult {
    switch (this.customPrintStrategyMode) {
      case CustomPrintStrategyMode.View:
        return this.$i18n.t('view')
      case CustomPrintStrategyMode.Edit:
        return this.$i18n.t('edit')
      case CustomPrintStrategyMode.NotCreated:
        return this.$i18n.t('customize')
    }
  }

  get isCustomPrintStrategyInReadonlyMode() {
    return this.customPrintStrategyMode === CustomPrintStrategyMode.View
  }

  get customPrintStrategyMode() {
    if (this.isUsedStrategyImplicit) {
      if (this.isVariantLocked) {
        return CustomPrintStrategyMode.View
      }
      return CustomPrintStrategyMode.Edit
    }
    return CustomPrintStrategyMode.NotCreated
  }

  get isVariantLocked() {
    return this.isSceneReadOnly || this.hasFinalizingJobs
  }

  get hasFinalizingJobs() {
    return !!this.getSelectedBuildPlanFinalizingJobs.length
  }

  get isCustomizeStrategyButtonDisabled() {
    return (
      this.customPrintStrategyMode !== CustomPrintStrategyMode.View &&
      (this.areAnyToolsActive || this.isVariantLocked || this.isVariantProcessing || this.isBuildPlanDisposing)
    )
  }

  get areAnyToolsActive() {
    return this.getBuildPlanViewMode !== null || this.isCrossSectionViewMode || this.isClearanceToolViewMode
  }

  get isMarkingViewModeType() {
    return this.getBuildPlanViewMode === ViewModeTypes.Marking
  }

  get partList() {
    if (this.isIbcPlan) {
      const uniqueParts = new Map<string, IBuildChamberPart>()
      const nominalParts: IPartDto[] = []
      this.getIBCPlan.ibcPlanItems.forEach((item) => {
        const ibcPlanItemPart = this.partById(item.partId)
        if (!ibcPlanItemPart) return

        const nominalPart = this.partById(ibcPlanItemPart.nominalPartId)
        nominalParts.push(nominalPart)
      })
      nominalParts.forEach((nominalPart) => {
        if (!uniqueParts.has(nominalPart.id)) {
          uniqueParts.set(nominalPart.id, {
            id: nominalPart.id,
            count: 1,
            name: nominalPart.name,
          })
        } else {
          uniqueParts.get(nominalPart.id).count += 1
        }
      })
      return Array.from(uniqueParts.values())
    }

    return this.getBuildChamberParts
  }

  openBuildPlanFolder() {
    if (this.isMarkingViewModeType) {
      return
    }

    this.openItemFolder(this.getBuildPlan.id)
  }

  async toggleCustomPrintStrategyModal() {
    if (!this.isUsedStrategyImplicit) {
      const bp = JSON.parse(JSON.stringify(this.getBuildPlan))
      const bpHasCustomPrintStrategy = await this.hasCustomPrintStrategy(this.getBuildPlan.id)

      const title = this.$t('customizePrintStrategyDialog.customizeTitle')
      const itemType =
        this.getBuildPlan.subType === ItemSubType.SinterPlan
          ? this.$t('customizePrintStrategyDialog.sinterPlan')
          : this.$t('customizePrintStrategyDialog.buildPlan')
      const message = bpHasCustomPrintStrategy
        ? this.$t('customizePrintStrategyDialog.messageReplace', { itemType })
        : this.$t('customizePrintStrategyDialog.messageCustomize', { itemType })

      const confirmed = await this.$refs.customizePrintStrategyDialog.open(title, message)
      if (!confirmed) {
        return
      }

      const customPrintStrategy = bpHasCustomPrintStrategy
        ? await this.replaceCustomPrintStrategy({
            buildPlanId: this.getBuildPlan.id,
            printStrategyPk: new VersionablePk(
              this.getBuildPlan.printStrategyId,
              this.getBuildPlan.printStrategyVersion,
            ),
          })
        : await this.createCustomPrintStrategy({
            buildPlanId: this.getBuildPlan.id,
            printStrategyPk: new VersionablePk(
              this.getBuildPlan.printStrategyId,
              this.getBuildPlan.printStrategyVersion,
            ),
          })

      await this.fetchBuildPlanInfo()

      this.addCommand(
        new EditBuildPlanCommand(
          bp,
          this.getBuildPlan,
          bp.buildPlanItems,
          this.getAllBuildPlanItems,
          this.getCommandType,
          true,
          false,
          [],
          this.$store.dispatch,
          this.$store.commit,
        ),
      )

      this.customPrintStrategyModalOpen = !!customPrintStrategy
      return
    }

    this.customPrintStrategyModalOpen = !this.customPrintStrategyModalOpen
  }

  get binderName() {
    return (
      this.getBuildPlanPrintStrategy &&
      this.getBuildPlanPrintStrategy.machineConfigMaterialBinder &&
      this.getBuildPlanPrintStrategy.machineConfigMaterialBinder.binder.name
    )
  }

  get splitterGutterSize(): number {
    if (this.isIbcPlan) {
      return 0
    }
  }

  get splitterInitialSize(): number {
    if (this.isIbcPlan) {
      return 100
    }
  }

  async beforeMount() {
    await Promise.all([
      this.fetchMaterials(),
      this.fetchMachineConfigs(),
      this.fetchBuildPlates(),
      this.fetchBuildPlateMaterials(),
      this.fetchBuildPlateMachineConfigs(),
      this.fetchPrintStrategies(true),
    ])
  }

  async fetchBuildPlanAsItem(forceFetch = false) {
    const plan: IBuildPlan | IIBCPlan = this.getIBCPlan || this.getBuildPlan

    if (!plan) {
      return
    }

    const defaultVariantId = getDefaultVariantIdFromVersionAndPath(plan)
    this.buildPlanAsItem = this.find(defaultVariantId)

    if (!this.buildPlanAsItem || forceFetch) {
      this.buildPlanAsItem = await this.fetchItemById(plan.id)
    }

    this.selectItem({ item: this.buildPlanAsItem, selectionType: SelectionTypes.Single })
    this.fetchCollaborators(plan.id)
  }

  get collaboratorsInfo(): string {
    return `${this.getCollaboratorsCount} ${
      this.getCollaboratorsCount !== 1 ? this.$i18n.t('collaborators') : this.$i18n.t('collaborator')
    }`
  }

  created() {
    this.delPressedListener = (ev) => {
      const inputTagNames = ['input', 'textarea']
      const isInputFieldFocused = this.activeElement && inputTagNames.includes(this.activeElement.tagName.toLowerCase())
      if (ev.code === DELETE_CODE && !isInputFieldFocused && this.canRemoveParts) {
        this.toggleRemovePartModal()
      }
    }
    document.addEventListener('keydown', this.delPressedListener)
    document.addEventListener('focusin', this.onFocusChanged)
    document.addEventListener('focusout', this.onFocusChanged)
    this.onBuildPlanUpdated(this.getBuildPlan)
  }

  destroyed() {
    document.removeEventListener('keydown', this.delPressedListener)
  }

  @Watch('getBuildPlan')
  @Watch('getIBCPlan')
  async onBuildPlanUpdated(bp: IBuildPlan) {
    this.fetchBuildPlanAsItem()
    this.buildPlanPath = await this.getItemPathNames(bp)
  }

  onShareModalClose(params?: { isEndCollaboration?: boolean }) {
    if (params && params.isEndCollaboration) {
      this.$emit('request-close-plan', { forceUnlockVariant: true })
    } else {
      this.fetchBuildPlanAsItem(true)
      this.toggleShareItemModal(false)
    }
  }

  getIBCPlanItemsByNominalPartId(nominalPartId: string): IIBCPlanItem[] {
    const ibcPartIds = this.partsByNominalPartId(nominalPartId)
    return ibcPartIds.flatMap((part) => this.getIBCPlanItemsByPartId(part.id))
  }

  hoverIntoPartGroup(partId: string) {
    const items = this.isIbcPlan ? this.getIBCPlanItemsByNominalPartId(partId) : this.getBuildPlanItemsByPartId(partId)
    this.toggleMultiHighlight({
      buildPlanItemIds: items.map((bpItem) => bpItem.id),
      highlight: true,
    })
  }

  hoverOutOfPartGroup(partId: string) {
    const items = this.isIbcPlan ? this.getIBCPlanItemsByNominalPartId(partId) : this.getBuildPlanItemsByPartId(partId)
    this.toggleMultiHighlight({
      buildPlanItemIds: items.map((bpItem) => bpItem.id),
      highlight: false,
    })
  }

  clickOnPartGroup(partId: string) {
    if (!this.isPartInteractionAvailable) {
      return
    }

    if (this.isIbcPlan) {
      // @ts-ignore
      this.$router.safePush({ name: RouterNames.FE_EditIbcPlan })
      return
    }

    if (this.getBuildPlanViewMode !== ViewModeTypes.Marking) {
      // @ts-ignore
      this.$router.safePush({ name: RouterNames.EditBuildPlan })
    }

    this.selectAndHighlightParts({
      buildPlanItemIds: this.getBuildPlanItemsByPartId(partId).map((bpItem) => bpItem.id),
      deselectIfSelected: false,
      showGizmo: true,
    })

    const canvasEl = document.getElementById('visualization_canvas')
    canvasEl.focus()
  }

  insightShowWarningsChanged(v: boolean) {
    this.setInsightSettings({ ...this.insightSettings, ...{ showWarnings: !v } })
  }

  get hasPartsAdded() {
    return this.getBuildPlan && this.getBuildPlan.buildPlanItems && this.getBuildPlan.buildPlanItems.length
  }

  get otherDetailsComponent() {
    switch (this.getBuildPlanViewMode) {
      case ViewModeTypes.Part:
      case ViewModeTypes.Replace:
        if (this.selectedParts.length) {
          return BuildPlanDetailsPart
        }
      default:
        return null
    }
  }

  get selectedMaterial() {
    return this.getMaterialByPk(this.getSelectedMaterialPk)
  }

  get isBinderJetMachineSelected() {
    return this.selectedMachineConfig && this.selectedMachineConfig.printingType === PrintingTypes.BinderJet
  }

  get selectedMachineConfig() {
    return this.getMachineConfigByPk(this.getSelectedMachineConfigPk)
  }

  get selectedProductionSet() {
    return this.getBuildPlanProductionSet()
  }

  get selectedPrintStrategy() {
    return this.getPrintStrategyByPk(
      new VersionablePk(this.getBuildPlan.printStrategyId, this.getBuildPlan.printStrategyVersion),
    )
  }

  get selectedBuildPlate() {
    return this.getBuildPlateByPk(
      new VersionablePk(this.getBuildPlan.buildPlateId, this.getBuildPlan.buildPlateVersion),
    )
  }

  get renderBuildPlanCost(): string {
    if (this.isShownNoMaterialParamsTooltip) {
      return NOT_AVAILABLE_LABEL
    }

    const { min, max } = this.getBuildPlanCost
    return this.formatCurrencyRange(min, max)
  }

  get isPartInstancingEnabled() {
    return (
      (this.getBuildPlanViewMode === ViewModeTypes.Layout || this.getBuildPlanViewMode === null) &&
      this.getBuildPlan.subType !== ItemSubType.SinterPlan
    )
  }

  get shouldShowPartsProperties(): boolean {
    return this.getSelectedParts.length > 0 && !this.isClearanceToolViewMode && ([SelectionUnit.Part, SelectionUnit.PartAndSupport].includes(this.getActiveSelectionMode))
  }

  get headerTitle(): TranslateResult {
    const isSinterPlan: boolean = this.getBuildPlan.subType === ItemSubType.SinterPlan

    if (isSinterPlan || this.isIbcPlan) return this.$i18n.t('sinterPlanProperties')

    return this.$i18n.t('buildPlanProperties')
  }

  get isSinterPlan(): boolean {
    return this.getBuildPlan.subType === ItemSubType.SinterPlan
  }

  get isIbcPlan() {
    return !!this.getIBCPlan
  }

  get canRemoveParts(): boolean {
    return (
      this.getSelectedParts.length &&
      !this.hasFinalizingJobs &&
      !this.isLabelCreationMode &&
      !this.isSceneReadOnly &&
      (this.getBuildPlanViewMode === ViewModeTypes.Layout || this.getBuildPlanViewMode === null) &&
      !this.isClearanceToolViewMode
    )
  }

  get isPartInteractionAvailable(): boolean {
    return (
      !this.getVisualizationLoading &&
      !this.getIsLoading &&
      this.isInitialized &&
      !this.isCrossSectionViewMode &&
      (!this.areAnyToolsActive ||
        (this.areAnyToolsActive && this.getBuildPlanViewMode === ViewModeTypes.Marking && this.activeLabelSet === null))
    )
  }

  get isBinderJetModality(): boolean {
    return this.getBuildPlan && this.getBuildPlan.modality === PrintingTypes.BinderJet
  }

  isPartSelectionModeActive() {
    return this.getActiveSelectionMode === SelectionUnit.Part
  }

  toggleSection(name: string) {
    if (this.openedSections[name] !== undefined) {
      this.openedSections[name] = !this.openedSections[name]
    }
  }

  async toggleAllowUseSinterParts() {
    if (this.isReadOnly && !this.isIbcPlan) {
      return
    }

    if (this.isIbcPlan) {
      this.setVisibleWhenPublishedIbcPlan({
        ibcPlan: this.getIBCPlan,
        visibilityWhenPublished: !this.getIBCPlan.visibleWhenPublished,
      })
    } else {
      this.setVisibleWhenPublishedSinterPlan({
        sinterPlan: this.getBuildPlan,
        visibilityWhenPublished: !this.getBuildPlan.visibleWhenPublished,
      })
    }
  }

  async fetchBuildPlanInfo() {
    const buildPlan = await this.getBuildPlanById(this.getBuildPlan.id)
    this.setBuildPlan(buildPlan)
  }

  async onUpdateParameterSet(editableParameter: EditableParameter) {
    // Updates build plan print strategy.
    await this.fetchBuildPlanInfo()

    if (editableParameter.type === PrintStrategyParameterType.Process) {
      return
    }

    // Updates build plan items with updated parameter set to the latest version.
    const parameterSets = this.getBuildPlanPrintStrategy.printStrategyParameterSets.filter((item) => {
      return item.parameterSetId === editableParameter.id
    })
    const parameterSet = VersionableModel.getLatestVersion(parameterSets)
    const buildPlanItems: IBuildPlanItem[] = JSON.parse(JSON.stringify(this.getAllBuildPlanItems))
    const updatedBpItems = buildPlanItems.filter((item) => {
      return item.partProperties[0].printStrategyParameterSetId === parameterSet.id
    })

    const buildPlanItemDtos: IUpdateBuildPlanItemParamsDto[] = updatedBpItems.map((bpItem) => {
      const groupId = createGuid()
      const bpItemDto: IUpdateBuildPlanItemParamsDto = {
        buildPlanItemId: bpItem.id,
        partProperties: JSON.parse(JSON.stringify(bpItem.partProperties)),
      }
      bpItemDto.partProperties.forEach((partProperty) => {
        partProperty.printStrategyParameterSetId = parameterSet.id
        partProperty.printStrategyParameterSetVersion = parameterSet.version
        partProperty.groupId = groupId
      })

      return bpItemDto
    })

    // Updates build plan items with an updated default parameter set to the latest version.
    const printStrategyDefaults = this.getBuildPlanPrintStrategy.defaults
    const updatedDefaultParameters: GeometryType[] = []
    if (printStrategyDefaults.productionId === parameterSet.id) {
      updatedDefaultParameters.push(GeometryType.Production)
    }
    if (printStrategyDefaults.couponId === parameterSet.id) {
      updatedDefaultParameters.push(GeometryType.Coupon)
    }
    if (printStrategyDefaults.supportImportedVolumeId === parameterSet.id) {
      updatedDefaultParameters.push(GeometryType.Support)
    }
    for (const bpItem of buildPlanItems) {
      if (bpItem.partProperties[0].printStrategyParameterSetId !== DEFAULT_PRINT_STRATEGY_PARAMETER_SET_ID) {
        continue
      }

      const bpItemDto: IUpdateBuildPlanItemParamsDto = {
        buildPlanItemId: bpItem.id,
        partProperties: JSON.parse(JSON.stringify(bpItem.partProperties)),
      }

      let anyPartParameterWasUpdated = false
      bpItemDto.partProperties.forEach((partProperty) => {
        if (!updatedDefaultParameters.includes(partProperty.type)) {
          return
        }

        const versionablePk = getDefaultBaseOnType(printStrategyDefaults, partProperty.type, partProperty.bodyType)
        anyPartParameterWasUpdated = true
        partProperty.printStrategyParameterSetVersion = versionablePk.version
        return
      })

      if (anyPartParameterWasUpdated) {
        buildPlanItemDtos.push(bpItemDto)
      }
    }

    if (buildPlanItemDtos.length) {
      await this.updateBuildPlanItems(buildPlanItemDtos)
    }
  }

  async onChangeDefaultParameterSets() {
    // Updates build plan print strategy.
    await this.fetchBuildPlanInfo()

    const defaultPartParams = this.getBuildPlanPrintStrategy.defaults

    const updatedBpItems = this.getAllBuildPlanItems.filter((item) => {
      return item.partProperties[0].printStrategyParameterSetId === DEFAULT_PRINT_STRATEGY_PARAMETER_SET_ID
    })

    const partPropsUpdateDto: IUpdateBuildPlanItemParamsDto[] = updatedBpItems.map((bpItem) => {
      type PartPropGeometryKey = {
        type: number
        bodyType: string
        printStrategyParameterSetId: number
        printStrategyParameterSetVersion: number
      }
      const relationsGroupIdMap = new Map<PartPropGeometryKey, string>()
      bpItem.partProperties.forEach((p) => {
        relationsGroupIdMap.set(
          {
            type: p.type,
            bodyType: p.bodyType,
            printStrategyParameterSetId: p.printStrategyParameterSetId,
            printStrategyParameterSetVersion: p.printStrategyParameterSetVersion,
          },
          p.groupId,
        )
      })

      const bpItemDto: IUpdateBuildPlanItemParamsDto = {
        buildPlanItemId: bpItem.id,
        partProperties: JSON.parse(JSON.stringify(bpItem.partProperties)),
      }

      bpItemDto.partProperties.forEach((partProperty) => {
        const printStrategyParameterDefault = getDefaultBaseOnType(
          defaultPartParams,
          partProperty.type,
          partProperty.bodyType,
        )

        partProperty.printStrategyParameterSetId = DEFAULT_PRINT_STRATEGY_PARAMETER_SET_ID
        partProperty.printStrategyParameterSetVersion = printStrategyParameterDefault.version

        const key: PartPropGeometryKey = {
          type: partProperty.type,
          bodyType: partProperty.bodyType,
          printStrategyParameterSetId: partProperty.printStrategyParameterSetId,
          printStrategyParameterSetVersion: partProperty.printStrategyParameterSetVersion,
        }
        const groupId = relationsGroupIdMap.get(key)
        if (groupId) {
          partProperty.groupId = groupId
        } else {
          partProperty.groupId = createGuid()
          relationsGroupIdMap.set(key, partProperty.groupId)
        }
      })

      return bpItemDto
    })

    if (partPropsUpdateDto.length) {
      await this.updateBuildPlanItems(partPropsUpdateDto)
    }
  }

  numberToCurrency(numStr: string): string {
    return this.$options.filters.currency(numStr)
  }

  formatCurrencyRange(min: number = 0, max: number = 0, digits: number = 2): string {
    let str = ''
    const strMin = min ? this.numberToCurrency(min.toFixed(digits)) : ''
    const strMax = max ? this.numberToCurrency(max.toFixed(digits)) : ''

    if (strMin && strMax) {
      str = `${strMin} - ${strMax}`
    } else {
      str = strMin || strMax || '-'
    }

    return str
  }

  async toggleRemovePartModal(isVisible: boolean = true) {
    this.removePartModalOpen = isVisible
  }

  async toggleProcessStateChangeModal(
    isVisible: boolean = true,
    payload?: { processStateName: string; sinterPartsCount: number },
  ) {
    this.processStateChangeModal = isVisible
    if (payload) {
      this.processStateModalProps = JSON.parse(JSON.stringify(payload))
    } else {
      this.processStateModalProps = { sinterPartsCount: 0, processStateName: '', partNames: [] }
    }
  }

  async toggleEditBuildOrSinterPlanModal(isVisible: boolean = true) {
    if (this.getBuildPlan && this.getBuildPlan.subType === ItemSubType.SinterPlan) {
      this.editSinterPlanModalOpen = isVisible
      return
    }

    this.editBuildPlanModalOpen = isVisible
  }

  onFocusChanged() {
    this.activeElement = document.activeElement
  }

  beforeDestroy() {
    document.removeEventListener('focusin', this.onFocusChanged)
    document.removeEventListener('focusout', this.onFocusChanged)
  }

  openIBCPlanPrintOrder() {
    const route = {
      name: RouterNames.PreviewPrintOrder,
      params: {
        id: this.getPrintOrder.id,
      },
    }

    // @ts-ignore
    this.$router.safePush(route)
  }
}
