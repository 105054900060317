
import Vue from 'vue'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import StoresNamespaces from '@/store/namespaces'

const commonStore = namespace(StoresNamespaces.Common)

@Component
export default class ToggleButton extends Vue {
  @commonStore.Getter tooltipOpenDelay: number

  @Prop({ default: true }) visible: boolean
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: '' }) testAttribute: string
  @Prop({ default: false }) toggleState: boolean
  @Prop() click: Function
  @Prop({ default: undefined }) onStateIcon: string
  @Prop({ default: undefined }) offStateIcon: string
  @Prop({ default: undefined }) onStateTooltip: any
  @Prop({ default: undefined }) offStateTooltip: any
  @Prop({ default: false }) hasActiveState: boolean
  @Prop({ default: 20 }) size: number
  @Prop({ default: false }) hasBottomTriangle: boolean

  get offIcon() {
    // Handle case when only one icon provided
    return this.offStateIcon || this.onStateIcon || ''
  }

  get onIcon() {
    // Handle case when only one icon provided
    return this.onStateIcon || this.offStateIcon || ''
  }

  get offTooltip() {
    // Handle case when only one tooltip provided
    return this.offStateTooltip || this.onStateTooltip || ''
  }

  get onTooltip() {
    // Handle case when only one tooltip provided
    return this.onStateTooltip || this.offStateTooltip || ''
  }
}
