
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import Menu from '@/components/controls/Common/Menu.vue'
import BuildPlanPartMixin from '@/components/layout/buildPlans/mixins/BuildPlanPartMixin'

import { BodyOption } from './types'
import { BodyMenuType, ProcessState } from '@/types/BuildPlans/IBuildPlan'
import BodyMenuListItem from './BodyMenuListItem.vue'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { VersionablePk } from '@/types/Common/VersionablePk'

const commonStore = namespace(StoresNamespaces.Common)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

interface IMixinInterface extends Vue, BuildPlanPartMixin {}

@Component({
  components: {
    Menu,
    BodyMenuListItem,
  },
})
export default class BodyDropdownMenu extends Mixins<IMixinInterface>(Vue, BuildPlanPartMixin) {
  @commonStore.Getter tooltipOpenDelay: number
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes

  @Prop({ required: true }) readonly options: BodyOption[]
  @Prop({ required: true }) readonly initialValue: number | string | VersionablePk
  @Prop() readonly hintText?: string
  @Prop() readonly hintIcon?: string
  @Prop({ default: false }) readonly isReadOnly?: boolean
  @Prop({ default: false }) readonly disabled?: boolean
  @Prop({ default: null }) readonly menuType?: BodyMenuType

  truncatedName: string = null

  $refs!: {
    truncateNameEl: HTMLElement
  }

  get activeOption(): BodyOption {
    return this.options.find((option) => {
      return option.value instanceof VersionablePk && this.initialValue instanceof VersionablePk
        ? option.value.equals(this.initialValue)
        : option.value === this.initialValue
    })
  }

  get icon(): string {
    return this.activeOption ? this.activeOption.icon : this.hintIcon
  }

  get iconColor(): string {
    return this.activeOption ? this.activeOption.iconColor : null
  }

  get name(): string {
    return this.activeOption ? this.activeOption.name : this.hintText
  }

  get isPartTool() {
    return this.getBuildPlanViewMode === ViewModeTypes.Part || this.getBuildPlanViewMode === ViewModeTypes.Replace
  }

  get tooltipContentClass() {
    const margin = this.isPartTool ? 'ml-8' : 'ml-n5'
    return `${margin} tooltip-override--basic`
  }

  getTooltipText(optionName: string) {
    if (this.menuType === BodyMenuType.ProcessState) {
      return this.getProcessStateTooltipText(optionName)
    }

    return null
  }

  getProcessStateTooltipText(optionName: string) {
    const processState = optionName.toLowerCase()

    switch (processState) {
      case ProcessState.Nominal.toLowerCase():
        return this.$t('processStateNominalTooltip')
      case ProcessState.Green.toLowerCase():
        return this.$t('processStateGreenTooltip')
      default:
        return null
    }
  }

  mounted() {
    this.truncateName()
  }

  @Watch('name')
  onNameChanged() {
    this.truncatedName = null
    this.truncateName()
  }

  onValueChange(value: number | string | VersionablePk) {
    this.$emit('change', value || null)
  }

  onMouseOver() {
    this.$emit('hoverOver')
  }

  onMouseOut() {
    this.$emit('hoverOut')
  }

  private truncateName() {
    this.$nextTick(() => {
      if (this.name) {
        this.truncatedName = this.middleTruncate(
          this.name,
          this.$refs.truncateNameEl,
          this.$refs.truncateNameEl.parentElement.parentElement,
        )
      }
    })
  }
}
