import { ISimulationStep } from '@/types/Simulation/SimulationSteps'

export interface IResultsConnectionParams {
  url: string
  token: string
  tenant: string
}

export interface IResultsPathData {
  buildPlanId?: string
  jobNumber?: number
  simulationStep?: ISimulationStep
}

export interface ISimulationPathData extends IResultsPathData {
  meshJobNumber?: number
}

export interface ISimulationExportInfo {
  buildPlanName: string
  buildPlanVariant: string
  prefix: string
}

export interface IWarpingInfo {
  available: boolean
  loaded: boolean
  factor?: number
}

export interface IDataDirectoryStructure {
  dirs
  files: IFileEntry[]
  groups: IFolderEntry[]
  label: string
  path: string[]
}

export interface IAdditionalGeometryInfo {
  type: GeometryType
  loaded: boolean
  visible: boolean
  notAvailable?: boolean
}

export enum GeometryType {
  Bridging = 'bridging',
  Nominal = 'nominal',
  GreenNominal = 'greenNominal',
  BuildPlate = 'buildPlate',
  Meshing = 'meshing',
  Compensated = 'compensated',
  GreenCompensated = 'compensatedGreen',
}

export interface IFileEntry {
  label: string
  size: number
}

export interface IFolderEntry {
  label: string
  files: IFileEntry[]
}

export enum ResultsManagerEvent {
  AdditionalGeometryDetected = 'visualizationModule/additionalGeometryDetected',
  ChartsInitialized = 'visualizationModule/setCharts',
  ColoringInitialized = 'visualizationModule/initializeColoringModel',
  DataRangeChanged = 'visualizationModule/dataRangeChanged',
  HandlerTogglesAvailable = 'visualizationModule/setHandlersTogglesAvailable',
  NewTimestampLoaded = 'visualizationModule/setIsShownNewTimestampModal',
  LegendChanged = 'visualizationModule/changeSimulationLegend',
  ParaviewError = 'visualizationModule/setParaviewWebError',
  PrintingLayersInitilized = 'visualizationModule/initializePrintingLayers',
  ResultsAvailable = 'visualizationModule/setResultsAvailable',
  SimulationStepLoaded = 'visualizationModule/addLoadedSimulationStep',
  SimulationStepsInitialized = 'visualizationModule/initSimulationSteps',
  SlicerInitialized = 'visualizationModule/initSimulationSlicer',
  StepBounds = 'visualizationModule/initializeStepBounds',
  SummaryInitilized = 'visualizationModule/initializeSummary',
  TimestampsInitialized = 'visualizationModule/initSimulationTimestamps',
  WarpingAvailable = 'visualizationModule/setWarpingAvailability',
}

export enum ProcessingStage {
  FetchingResults,
}

export enum CategoryKind {
  SUPPORTS,
  PARTS,
  BUILDPLATE,
  COUPONS,
}
