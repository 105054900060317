import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { IconNames } from '@/components/icons/Icon.vue'

const commonStore = namespace(StoresNamespaces.Common)

@Component
export default class CanvasDisplayMenuMixin extends Vue {
  @commonStore.Getter tooltipOpenDelay: number

  getIconName(view: string) {
    let iconName
    switch (view) {
      case 'Y-':
        iconName = IconNames.FrontView
        break
      case 'Y+':
        iconName = IconNames.BackView
        break
      case 'X-':
        iconName = IconNames.LeftView
        break
      case 'X+':
        iconName = IconNames.RightView
        break
      case 'Z+':
        iconName = IconNames.TopView
        break
      case 'Z-':
        iconName = IconNames.BottomView
        break
      case 'XYZ':
        iconName = IconNames.IsometricView
        break
    }
    return iconName
  }
}
