import { CharacterLengthControl, MarkingContentElementType } from '@/types/Label/enums'

export interface TextElement {
  elementIDNumber: number
  isStaticValue: boolean
  lengthControl: CharacterLengthControl
  lengthMax: number
  lengthMin: number
  mandatory: boolean
  runTimeIndex: number
  title: string
  type: MarkingContentElementType
  _cachedSpecificsJSON: string
}

export interface GridLetterJSON {
  direction: GridLetterDirection
  location: GridLetterLocation
  offset: number
  spacing: number
}

export enum GridLetterDirection {
  XAxis,
  YAxis,
  ZAxis,
}

export enum GridLetterLocation {
  MinimumCorner,
  MaximumCorner,
  Center,
}

export interface CounterJSON {
  startNumber: number
  increment: number
  leadingZero: number
  zeroPadding: ZeroPaddingStyle
  ordering: BodyOrderingParameters
}

export interface BodyOrderingParameters {
  method: BodyOrderMethod
  sortCoordinateOrder: CounterSelectionOrder[]
}

export enum BodyOrderMethod {
  InOrderSelected,
  CartesianSort,
}

export enum CounterSelectionOrder {
  LeftToRight = 1,
  FrontToBack = 2,
  BotToTop = 3,
  RightToLeft = -1,
  BackToFront = -2,
  TopToBot = -3,
}

export enum ZeroPaddingStyle {
  Automatic,
  Specify,
  None,
}

export enum UserEntryType {
  AlphaNumeric,
  Numeric,
  RegularExpression,
}

export interface UserEntryJSON {
  entryType: UserEntryType
  selectedInputType: CharacterLengthControl
  minRange: number
  maxRange: number
  min: number
  max: number
  exactly: number
  promptText: string
  previewText: string
}

export interface ActiveDynamicElementDialogInfo {
  activeDialogType: MarkingContentElementType
  textElement: TextElement
  top: number
  left: number
}

export enum DynamicElementEvents {
  OnDialogClosed = 'onClose',
  OnDialogUpdated = 'onUpdate',
  OnElementAdded = 'onAdded',
}

export const defaultGridLetterSpacing = 20
