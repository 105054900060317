
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'

import TableRowMixin from '@/components/layout/FileExplorer/Table/mixins/TableRowMixin'
import ItemListRowBadges from '@/components/layout/FileExplorer/Table/ItemListRowBadges.vue'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { IJob, JobStatusCode } from '@/types/PartsLibrary/Job'
import Button from '@/components/controls/Common/Button.vue'
import { RouterNames } from '@/router'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import ItemContextMenu from '@/components/layout/FileExplorer/Table/ItemContexMenu.vue'
import { ViewMode } from '@/types/FileExplorer/ViewMode'
import { ROOT_FOLDER_ID } from '@/constants'
import { eventBus } from '@/services/EventBus'
import { FileExplorerEvent } from '@/types/FileExplorer/FileExplorerEvent'
import Menu from '@/components/controls/Common/Menu.vue'
import { getDownloadPrintOrder, getDownloadPrintOrderFileKey, getPrintOrderStatus } from '@/utils/download'
import AlertModal from '@/components/modals/AlertModal.vue'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const jobStore = namespace(StoresNamespaces.Jobs)

@Component({
  components: { ItemListRowBadges, Button, ItemContextMenu, Menu, AlertModal },
})
export default class PrintOrderListRow extends Mixins(TableRowMixin) {
  @buildPlansStore.Action getSignedUrlV1: (payload: { fileKey: string; newName?: string }) => Promise<string>

  @jobStore.Action fetchJob: (id: string) => Promise<IJob>

  @fileExplorerStore.Mutation setViewMode: (viewMode: ViewMode) => void

  @Prop() item: PrintOrder
  @Prop() parentItem
  @Prop() setupContextMenu: (item: FileExplorerItem | PrintOrder, x: number, y: number) => Promise<void>
  @Prop() contextMenu: { x: number; y: number; isShown: boolean; parentId: string }

  $refs: {
    [key: string]: Vue | Element | Vue[] | Element[]
    alert: InstanceType<typeof AlertModal>
  }

  job: IJob = null
  downloadFileUrl: string = null
  isShownActionButtons: boolean = false
  isShownContextMenu: boolean = false
  contextMenuItems = []

  get fullParentPathLabel(): string {
    return `${this.parentPathLabel}/${this.item.itemName}`
  }

  async beforeMount() {
    eventBus.$on(FileExplorerEvent.Scroll, this.onScroll)

    this.job = await this.fetchJob(this.item.id)
  }

  onMenuIconClick(event) {
    this.showContextMenu(event, true)
  }

  async showContextMenu(event, needToTranspose = false) {
    const iconHeight = 18
    const x = needToTranspose ? event.target.getBoundingClientRect().left : event.clientX
    const y = needToTranspose ? event.target.getBoundingClientRect().top + iconHeight : event.clientY

    await this.setupContextMenu(this.item, x, y)
    this.getContextMenuItems()

    this.isShownContextMenu = true
  }

  getContextMenuItems() {
    const newContextMenuItems = []

    newContextMenuItems.push(
      {
        title: this.$root.$i18n.t('open'),
        divide: true,
        clickHandler: () => {
          this.navigateToPreview()
        },
        testAttribute: 'open-button',
        condition: true,
      },
      {
        title: this.$root.$i18n.t('download'),
        divide: false,
        clickHandler: () => {
          this.downloadFile()
        },
        testAttribute: 'download-button',
        condition: true,
      },
    )

    this.contextMenuItems = newContextMenuItems
  }

  async downloadFile() {
    this.item.downloadFileKey = getDownloadPrintOrderFileKey(this.job)
    if (this.item.downloadFileKey) {
      this.downloadFileUrl = await this.getSignedUrlV1({
        fileKey: this.item.downloadFileKey,
        newName: getDownloadPrintOrder(this.job),
      })

      window.open(this.downloadFileUrl)
    } else {
      if (
        this.job.code === JobStatusCode.CREATED ||
        this.job.code === JobStatusCode.QUEUED ||
        this.job.code === JobStatusCode.RUNNING
      ) {
        await this.$refs.alert.open(this.$i18n.t('printOrderDownload'), this.$i18n.t('printOrderPrepUnderProcess'))
      } else {
        await this.$refs.alert.open(this.$i18n.t('printOrderDownload'), this.$i18n.t('printOrderPkgNotavailable'))
      }
    }
  }

  async onMenuItemClicked(item: FileExplorerItem, clickHandler: Function) {
    await this.$emit('menuItemClick', this.item)
    clickHandler(item)
  }

  get isOpenContextValue() {
    return this.contextMenu.isShown
  }

  @Watch('isSelected')
  menuChangedBySelection(isSelectedNow: boolean) {
    this.isShownActionButtons = isSelectedNow
  }

  get printOrderStatus() {
    if (!this.job) return ''

    this.item.status = getPrintOrderStatus(this.job)

    return getPrintOrderStatus(this.job)
  }

  navigateToPreview() {
    const route = {
      name: RouterNames.PreviewPrintOrder,
      params: {
        id: this.item.id,
      },
    }
    // @ts-ignore
    this.$router.safePush(route)
  }

  navigateToLocation() {
    const route = {
      name: RouterNames.EditBuildPlan,
      params: {
        id: this.item.parentId,
      },
    }

    // @ts-ignore
    this.$router.safePush(route)
  }

  getPrintOrderVersion() {
    const version = +this.item.parentVersion
    if (Number.isNaN(version)) {
      return this.item.parentVersion
    }

    return `${this.$t('variant')} ${version}`
  }

  onScroll() {
    if (this.isShownContextMenu) {
      this.isShownContextMenu = false
      ;((this.$refs.menuIcon as Vue).$el as HTMLElement).blur()
    }
  }

  beforeDestroy() {
    eventBus.$off(FileExplorerEvent.Scroll, this.onScroll)
  }
}
