
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ItemTypeSelector from '@/components/controls/FileExplorer/ItemTypeSelector.vue'
import ActionBar from '@/components/layout/FileExplorer/ActionBar.vue'
import DetailsPanel from '@/components/layout/FileExplorer/DetailsPanel.vue'
import InfinityScrollArea from '@/components/layout/FileExplorer/InfinityScrollArea.vue'
import MoveErrorModal from '@/components/layout/FileExplorer/MoveErrorModal.vue'
import MoveOrCopyModal from '@/components/layout/FileExplorer/MoveOrCopyModal.vue'
import MoveToTrashItemErrorModal from '@/components/layout/FileExplorer/MoveToTrashItemErrorModal.vue'
import MoveToTrashItemModal from '@/components/layout/FileExplorer/MoveToTrashItemModal.vue'
import RenameItemModal from '@/components/layout/FileExplorer/RenameItemModal.vue'
import ShareItemModal from '@/components/layout/FileExplorer/ShareItemModal.vue'
import AllFilesSearchViewMixin from '@/components/layout/FileExplorer/Table/mixins/AllFilesSearchViewMixin'
import FileExplorerRedirectionMixin from '@/components/layout/FileExplorer/Table/mixins/FileExplorerRedirectionMixin'
import RecentListRow from '@/components/layout/FileExplorer/Table/Recent/RecentListRow.vue'
import RecentTableHeader from '@/components/layout/FileExplorer/Table/Recent/RecentTableHeader.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import StoresNamespaces from '@/store/namespaces'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { FilterParamsKey, TableFilterParams } from '@/types/FileExplorer/FilterParamsKey'
import { TableSortParams } from '@/types/FileExplorer/SortParamsKey'
import { DetailsPanelViewMode } from '@/types/FileExplorer/ViewMode'
import { IPartDto } from '@/types/PartsLibrary/Parts'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const partsStore = namespace(StoresNamespaces.Parts)

@Component({
  components: {
    RecentTableHeader,
    RecentListRow,
    DetailsPanel,
    ActionBar,
    RenameItemModal,
    MoveErrorModal,
    MoveToTrashItemModal,
    ShareItemModal,
    ItemTypeSelector,
    MoveToTrashItemErrorModal,
    InfinityScrollArea,
    MoveOrCopyModal,
    ConfirmModal,
  },
})
export default class Recent extends Mixins(AllFilesSearchViewMixin, FileExplorerRedirectionMixin) {
  @fileExplorerStore.Action fetchRecentItems: Function
  @fileExplorerStore.Action getGetRunningAndFailedJobsByItemIds: Function
  @fileExplorerStore.Action clearPaginatedData: Function

  @partsStore.Action actualizeParts: (items?: FileExplorerItem[]) => Promise<void>
  @partsStore.Action fetchAllParts: Function
  @partsStore.Action fetchAllSinterParts: Function
  @partsStore.Action fetchAllIbcParts: () => Promise<IPartDto[]>

  @fileExplorerStore.Getter getRecentItems: FileExplorerItem[]
  @fileExplorerStore.Getter getClickedNameItem: FileExplorerItem
  @fileExplorerStore.Getter getSelectedItems: FileExplorerItem[]
  @fileExplorerStore.Getter getRenameDialogState: boolean
  @fileExplorerStore.Getter isLoading: boolean
  @fileExplorerStore.Getter getSortParams: () => TableSortParams
  @fileExplorerStore.Getter getFilterParams: () => TableFilterParams
  @fileExplorerStore.Getter getNumberOfHiddenItemsInRecent: () => number

  @fileExplorerStore.Mutation setDetailsPanelMode: (mode: DetailsPanelViewMode) => void
  @fileExplorerStore.Mutation clearRecentItems: Function

  isSelectedItems: boolean = true
  filterType = FilterParamsKey.Recent

  async beforeMount() {
    this.unselectAllItems()
    this.setRoot(null)
    await this.refreshItems()
  }

  // @ts-ignore
  get items(): FileExplorerItem[] {
    return this.getRecentItems
  }

  async onItemListRowClick(payload) {
    this.setSelectedItemsCanBeTrashed(null)

    const item: FileExplorerItem = payload.item

    payload.item = await this.refreshItemIfNeeded(item)

    await this.onItemClick(payload)
    this.defineDetailsViewMode()
  }

  beforeDestroy() {
    this.setDetailsPanelMode(DetailsPanelViewMode.Default)
  }

  onIntersection() {
    this.fetchItems()
  }

  @Watch('getFilterParams', { deep: true })
  @Watch('getSortParams', { deep: true })
  async refreshItems() {
    await this.clearPaginatedData()
    this.clearRecentItems()
    this.fetchItems()
  }

  async fetchItems() {
    const itemsToGetJobs = await this.fetchRecentItems()
    await Promise.all([
      this.fetchAllSinterParts(),
      this.fetchAllParts(),
      this.fetchAllIbcParts(),
      this.actualizeParts(itemsToGetJobs),
    ])
    const itemIds = itemsToGetJobs.map((item: FileExplorerItem) => item.id)
    this.getGetRunningAndFailedJobsByItemIds(itemIds)
  }
}
