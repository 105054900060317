import { SliceSettings } from '../../../../../types/BuildPlans/IBuildPlan'

export class PartParameterFormViewModel {
  sinterCycleName: string = null
  scaleFactorX: number = null
  scaleFactorY: number = null
  scaleFactorZ: number = null
  greenDensity: number = null
  sinteredDensity: number = null
  poissonRatio: number = null
  youngsModulus: number = null
  compressiveYoungsModulus: number = null
  tensileModulus: number = null
  calibrationName: string = null
  calibrationDate: string = null
  calibrationMachine: string = null
  powderLot: string = null
  binderLot: string = null
  sliceSettings: SliceSettings = null
}
