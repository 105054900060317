
import Component from 'vue-class-component'
import DefectGroupWrapper from '@/components/layout/parts/insight/group/DefectGroupWrapper.vue'
import { IPartInsight, PartDefect, messageMapByInsight } from '@/types/Parts/IPartInsight'
import { Prop } from 'vue-property-decorator'
import { InsightsSeverity } from '@/types/Common/Insights'
import PartDefectByInsight from '@/components/layout/parts/insight/defect/byInsight/PartDefectByInsight.vue'
import { DefectInsightMixin } from '@/components/layout/parts/insight/mixins/DefectInsightMixin'

@Component({
  components: { DefectGroupWrapper, PartDefectByInsight },
})
export default class DefectGroup extends DefectInsightMixin {
  @Prop({ default: {} }) defects: Partial<{ [key in PartDefect]: IPartInsight[] }>
  @Prop() severity: InsightsSeverity

  get sortedHeaders() {
    if (!this.defects) {
      return null
    }

    return Object.keys(this.defects).sort((k1: string, k2: string) => {
      const key1 = this.$t(this.message(k1)) as string
      const key2 = this.$t(this.message(k2)) as string
      return key1.localeCompare(key2)
    })
  }

  message(key: string) {
    return messageMapByInsight[key]
  }

  isDefectTypeHovered(type: string) {
    const defectType = Number.parseInt(type, 10)
    return this.hoveredDefect !== null && this.hoveredDefect.type === defectType && !this.hoveredDefect.indices.length
  }

  isDefectTypeSelected(type: string) {
    const defectType = Number.parseInt(type, 10)
    return this.selectedDefect !== null && this.selectedDefect.type === defectType
  }

  hoverIntoDefects(type: string) {
    const defectType = Number.parseInt(type, 10)
    this.setHoveredDefect({ defectType })
    this.toggleDefectsHighlight({ defect: this.hoveredDefect, showHighlight: true })
  }

  hoverOutOfDefects() {
    this.toggleDefectsHighlight({ defect: this.hoveredDefect, showHighlight: false })
    this.setHoveredDefect(null)
  }

  selectDefectType(type: string) {
    const defectType = Number.parseInt(type, 10)
    if (this.selectedDefect.type) {
      this.selectDefects({
        defect: { type: this.selectedDefect.type, indices: [] },
        deselectIfSelected: true,
      })
      this.hoverIntoDefects(type)
    }

    if (this.isDefectTypeSelected(type)) {
      this.setSelectedDefect(null)
    } else {
      this.setSelectedDefect({ defectType })
    }
  }
}
