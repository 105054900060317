
import Component, { mixins } from 'vue-class-component'
import { namespace } from 'vuex-class'

import StoresNamespaces from '@/store/namespaces'
import { SortOrders } from '@/types/SortModes'
import { FilterParamsKey, ItemTypeFilterParams } from '@/types/FileExplorer/FilterParamsKey'
import { IJob, JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import DetailsPanel from '@/components/layout/FileExplorer/DetailsPanel.vue'
import PrintOrderHeader from '@/components/layout/FileExplorer/Table/printOrder/PrintOrderHeader.vue'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import DetailsPrintOrder from '@/components/layout/FileExplorer/Details/DetailsPrintOrder.vue'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import PrintOrderListRow from '@/components/layout/FileExplorer/Table/printOrder/PrintOrderListRow.vue'
import ItemTypeSelector from '@/components/controls/FileExplorer/ItemTypeSelector.vue'
import { SortParamsKey } from '@/types/FileExplorer/SortParamsKey'
import { PrintingTypes } from '@/types/IMachineConfig'
import AllFilesSearchViewMixin from '@/components/layout/FileExplorer/Table/mixins/AllFilesSearchViewMixin'
import ActionBar from '@/components/layout/FileExplorer/ActionBar.vue'
import { IBuildPlate } from '@/types/BuildPlates/IBuildPlate'
import { isTabVisible } from '@/utils/common'
import { getDownloadPrintOrder, getDownloadPrintOrderFileKey, getPrintOrderStatus } from '@/utils/download'
import { PRINT_SITE_DOWNLOAD_DEFAULT_NAME, PRINT_SITE_DOWNLOAD_DISPLAY_NAME } from '@/constants'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { DetailsPanelExpansionItem } from '@/types/FileExplorer/DetailsPanelExpansionItem'
import AlertModal from '@/components/modals/AlertModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import PrintOrderMixin from '@/components/layout/FileExplorer/Table/printOrder/PrintOrderMixin'

const jobsStore = namespace(StoresNamespaces.Jobs)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const partsStore = namespace(StoresNamespaces.Parts)

const SORT_BY_DEFAULT = 'name'
const SORT_ORDER_DEFAULT = SortOrders.Ascending
const POLL_INTERVAL = 5 * 1000

@Component({
  components: {
    DetailsPanel,
    PrintOrderHeader,
    DetailsPrintOrder,
    PrintOrderListRow,
    ItemTypeSelector,
    ActionBar,
    AlertModal,
  },
})
export default class PrintOrders extends mixins(AllFilesSearchViewMixin, PrintOrderMixin) {
  // region jobsStore
  @jobsStore.Action fetchPrintOrders: () => Promise<PrintOrder[]>
  @jobsStore.Action fetchJob: (id: string) => Promise<IJob>
  @jobsStore.Getter getJobs: IJob[]
  // endregion jobsStore

  // region fileExplorer
  @fileExplorerStore.Action fetchItems: () => Promise<FileExplorerItem[]>

  @fileExplorerStore.Mutation unselectAllItems: () => void

  @fileExplorerStore.Getter('getSelectedItems') selectedItems: PrintOrder[]
  @fileExplorerStore.Getter('find') findItemById: (itemId: string) => FileExplorerItem
  // endregion fileExplorer

  //region buildPlan
  @buildPlansStore.Action getSignedUrlV1: (payload: { fileKey: string; newName?: string }) => Promise<string>
  @buildPlansStore.Action fetchBuildPlates: Function

  @buildPlansStore.Getter getBuildPlateByPk: (pk: VersionablePk) => IBuildPlate
  //endregion buildPlan

  @partsStore.Getter getAllParts: IPartDto[]
  @partsStore.Getter getAllSinterParts: IPartDto[]

  @partsStore.Action fetchAllParts: Function
  @partsStore.Action fetchAllSinterParts: Function
  @partsStore.Action fetchAllIbcParts: () => Promise<IPartDto[]>

  @fileExplorerStore.Mutation setIsPrintOrderDetailsLoading: (status: boolean) => void

  buildPlan: IBuildPlan = null
  showPrintOrderDetails: boolean = false

  isSelectedItems: boolean = true
  jobFetchTimeoutId: number = null
  printOrderItems: FileExplorerItem[] = []
  menuItems = []
  statusFilterType = FilterParamsKey.PrintOrdersStatus
  materialFilterType = FilterParamsKey.PrintOrdersMaterial
  machineFilterType = FilterParamsKey.PrintOrdersMachine
  parameterFilterType = FilterParamsKey.PrintOrdersParameter
  buildPlateFilterType = FilterParamsKey.PrintOrdersBuildPlate

  $refs!: {
    confirm: InstanceType<typeof ConfirmModal>
    alert: InstanceType<typeof AlertModal>
  }

  async beforeMount() {
    this.unselectAllItems()
    this.setRoot(null)
    this.setPrintOrders([])
    this.setPrintOrdersLoading(true)

    await this.fetchBuildPlates()
    await this.pollPrintJobs()
    await Promise.all([this.fetchAllSinterParts(), this.fetchAllParts(), this.fetchAllIbcParts()])
  }

  async pollPrintJobs() {
    if (isTabVisible()) {
      await this.getPrintJobs()
    }
    this.jobFetchTimeoutId = window.setTimeout(this.pollPrintJobs, POLL_INTERVAL)
  }

  async getPrintJobs() {
    await this.fetchPrintOrders()
    this.updatePrintOrdersStatuses()
    this.printOrders.map((item) => {
      if (item.site.trim().toLowerCase() === PRINT_SITE_DOWNLOAD_DEFAULT_NAME) {
        item.site = PRINT_SITE_DOWNLOAD_DISPLAY_NAME
      }
    })
  }

  get orders(): PrintOrder[] {
    let orders = this.printOrders

    orders.forEach((order) => {
      order.status = order.status.toLowerCase()
    })

    orders = this.getFilteredObjects(FilterParamsKey.PrintOrdersStatus, orders)
    orders = this.getFilteredObjects(FilterParamsKey.PrintOrdersMaterial, orders)
    orders = this.getFilteredObjects(FilterParamsKey.PrintOrdersMachine, orders)
    orders = this.getFilteredObjects(FilterParamsKey.PrintOrdersParameter, orders)
    orders = this.getFilteredObjects(FilterParamsKey.PrintOrdersBuildPlate, orders)
    orders = this.getOrderedItems(SortParamsKey.PrintOrders, orders, SORT_BY_DEFAULT, SORT_ORDER_DEFAULT)
    return orders
  }

  // @ts-ignore
  get items() {
    return this.orders
  }

  printOrderItemByJobId(id: string): FileExplorerItem {
    return this.printOrderItems.find((i: FileExplorerItem) => i.id === id)
  }

  itemUpdated() {
    this.getPrintJobs()
  }

  async onItemListRowClick(payload) {
    this.setIsPrintOrderDetailsLoading(true)
    this.showPrintOrderDetails = false
    await this.onItemClick(payload, false)
    this.parts = await this.getParts(payload.item.parentId)
    this.showPrintOrderDetails = true
    this.setIsPrintOrderDetailsLoading(false)
  }

  beforeDestroy() {
    clearTimeout(this.jobFetchTimeoutId)
    this.unselectAllItems()
  }

  getDownloadFileKey(parameters): string {
    if (parameters && parameters.modality) {
      return parameters.modality === PrintingTypes.BinderJet
        ? parameters && parameters.binderJet && parameters.binderJet.arsf && parameters.binderJet.arsf.s3FileName
        : parameters && parameters.dmlm
        ? parameters.dmlm.printPackageZip
          ? parameters.dmlm.printPackageZip.s3FileName
          : null
        : null
    }

    return null
  }

  async downloadSelected() {
    const jobs = await Promise.all(this.selectedItems.map((item) => this.fetchJob(item.id)))
    const downloadUrls: Array<{ printOrderName: string; url: string }> = await Promise.all(
      this.selectedItems.map(async (item: PrintOrder) => {
        const job = jobs.find((j) => j.id === item.id)
        item.downloadFileKey = getDownloadPrintOrderFileKey(job)
        if (item.downloadFileKey) {
          return {
            printOrderName: item.name,
            url: await this.getSignedUrlV1({
              fileKey: item.downloadFileKey,
              newName: getDownloadPrintOrder(job),
            }),
          }
        }
        if (
          !(
            job.code === JobStatusCode.CREATED ||
            job.code === JobStatusCode.QUEUED ||
            job.code === JobStatusCode.RUNNING
          )
        ) {
          return { printOrderName: item.name, url: 'printOrderPkgNotavailable' }
        }
        return { printOrderName: item.name, url: '' }
      }),
    )
    downloadUrls.forEach(async (downloadUrl) => {
      if (downloadUrl.url === 'printOrderPkgNotavailable') {
        await this.$refs.alert.open(this.$i18n.t('printOrderDownload'), this.$i18n.t('printOrderPkgNotavailable'))
      } else if (downloadUrl.url) {
        window.open(downloadUrl.url)
      } else {
        await this.$refs.alert.open(this.$i18n.t('printOrderDownload'), this.$i18n.t('printOrderPrepUnderProcess'))
      }
    })
  }

  get selectedPrintOrder(): PrintOrder {
    if (!this.selectedItems.length || this.selectedItems.length > 1) {
      return null
    }

    const selectedItem = this.selectedItems[0]

    return this.printOrders.find((p: PrintOrder) => p.id === selectedItem.id)
  }

  get selectedItem() {
    if (!this.selectedItems.length || this.selectedItems.length > 1) {
      return null
    }

    const selectedItem = this.selectedItems[0]

    return this.findItemById(selectedItem.parentId)
  }

  private updatePrintOrdersStatuses() {
    if (!this.getJobs.length) return

    for (const printOrder of this.printOrders) {
      const job = this.getJobs.find((p) => p.id === printOrder.id)
      if (job) printOrder.status = getPrintOrderStatus(job)
    }
  }
}
