export enum InteractiveServiceEvents {
  GenerateOverhangMeshDebounced = 'generateOverhangMeshDebounced',
  GenerateOverhanhgMeshByClick = 'generateOverhangMeshByClick',
  GetOverhangElements = 'getOverhangElements',
  LockOverhangsAndSupportsRendering = 'lockOverhangsAndSupportsRendering',
  SelectSupport = 'selectSupport',
  HoverSupport = 'hoverSupport',
  AbortLabelAfterLabelSetChange = 'abortLabelAfterLabelSetChange',
  OnPartElevate = 'onPartElevate',
  SelectRelatedBodies = 'SelectRelatedBodies',
  ScheduleExecuteCommand = 'ScheduleExecteCommand',
}
