
import { TranslateResult } from 'vue-i18n'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import VariantIsLockedModal from '@/components/layout/FileExplorer/VariantIsLockedModal.vue'
import { RouterNames } from '@/router'
import StoresNamespaces from '@/store/namespaces'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemType } from '@/types/FileExplorer/ItemType'
import { ItemPermissionsRole } from '@/types/FileExplorer/Permission'
import { IUser } from '@/types/User/IUser'
import { isItemLockedForUser } from '@/utils/fileExplorerItem/fileExplorerItemUtils'
import ActionBarMixin from './Table/mixins/ActionBarMixin'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const userStore = namespace(StoresNamespaces.User)
const commonStore = namespace(StoresNamespaces.Common)

@Component({
  components: {
    VariantIsLockedModal,
  },
})
export default class ActionBar extends Mixins(ActionBarMixin) {
  @fileExplorerStore.Action pasteMovedItems: () => void
  @fileExplorerStore.Action copyItems: (items: FileExplorerItem[]) => void

  @commonStore.Getter tooltipOpenDelay: number

  @fileExplorerStore.Getter getIsMoveItems: boolean
  @fileExplorerStore.Getter rootContainsSelectedItem: boolean
  @fileExplorerStore.Getter getRootRole: () => ItemPermissionsRole
  @fileExplorerStore.Getter getSelectedItems: FileExplorerItem[]
  @fileExplorerStore.Getter getSelectedItem: FileExplorerItem
  @fileExplorerStore.Getter getClickedNameItem: FileExplorerItem
  @fileExplorerStore.Getter getRootItem: FileExplorerItem

  @fileExplorerStore.Mutation resetIsMoveItems: () => void
  @fileExplorerStore.Mutation setIsMoveItems: (val: boolean) => void

  @userStore.Getter getUserById: (userId: string) => IUser

  @Prop({ default: () => null }) fileExplorerItems
  @Prop({ default: false }) ignoreOpen: boolean
  @Prop({ default: false }) canUnshare: boolean
  @Prop({ default: false }) canRename: boolean
  @Prop({ default: false }) canEndCollaboration: boolean
  @Prop({ default: false }) canTrash: boolean
  @Prop({ default: false }) canUnfavoriteAll: boolean
  @Prop({ default: true }) canWrap: boolean

  types = ItemType
  isProcessing: boolean = false

  get lockedByTooltipText() {
    const whitelist = this.getItem.lockWhitelist ? this.getItem.lockWhitelist : []
    const userId = whitelist.length === 0 ? this.getItem.createdBy : this.getItem.lockWhitelist[0].userId
    const user = this.getUserById(userId)
    const userName = user ? `${user.firstName} ${user.lastName}` : userId

    return `${this.$t('itemLockedBy')} ${userName}`
  }

  get getItems() {
    return this.fileExplorerItems
      ? this.fileExplorerItems
      : this.getSelectedItems.length
      ? this.getSelectedItems
      : this.getSelectedItem
      ? [this.getSelectedItem]
      : []
  }

  get getItem() {
    return this.fileExplorerItems
      ? this.fileExplorerItems[0]
      : this.getSelectedItem
      ? this.getSelectedItem
      : this.getRootItem
  }

  get isItemLocked() {
    return isItemLockedForUser(this.getItem, this.getUserDetails)
  }

  get isPrintOrder() {
    return this.getItem && this.getItem.site && this.getItem.status
  }

  get isShownMainActions() {
    const isRemoved: boolean = this.getItem && this.getItem.isRemoved
    return (this.getItems.length && !this.getIsMoveItems && !isRemoved) || this.canUnfavoriteAll
  }

  get canOpen(): boolean {
    if (!this.getItem || (this.getRootItem && this.getItem.id === this.getRootItem.id)) {
      return false
    }

    const hasPermissions: boolean = !!this.getItem.grantedPermissions
    const isRemoved: boolean = this.getItem.isRemoved
    return (!isRemoved && hasPermissions) || this.isPrintOrder
  }

  get canShare(): boolean {
    if (!this.getItem || this.getSelectedItems.length > 1) {
      return false
    }

    const isRemoved: boolean = this.getItem.isRemoved
    const canItemShare: boolean = this.getItem.grantedPermissions && this.getItem.grantedPermissions.itemShare

    return !isRemoved && canItemShare
  }

  get showMoveOrCopy(): boolean {
    let canMove = false

    if (this.getItem) {
      canMove = this.getItem.grantedPermissions && this.getItem.grantedPermissions.itemMove
    }

    if (this.getItems.length) {
      canMove = this.getItems.every((item) => item.grantedPermissions && item.grantedPermissions.itemMove)
    }

    switch (this.$route.name) {
      case RouterNames.FE_AllFiles:
      case RouterNames.FE_Search:
      case RouterNames.FE_Favorites:
      case RouterNames.FE_Recent:
      case RouterNames.FE_SharedByMe:
        return canMove

      default:
        return false
    }
  }

  get copyDisabled(): boolean {
    return this.getRootRole() === ItemPermissionsRole.Viewer
  }

  get moveDisabledMessage(): TranslateResult {
    const rootRole = this.getRootRole()
    if (rootRole === ItemPermissionsRole.Viewer) {
      return this.$i18n.t('notAllowedByPermissionsDestination', { role: ItemPermissionsRole[rootRole] })
    }
    return this.$i18n.t('moveFolderIntoItselfTooltip')
  }

  get copyDisabledMessage(): TranslateResult {
    const rootRole = this.getRootRole()
    return this.$i18n.t('notAllowedByPermissionsDestination', { role: ItemPermissionsRole[rootRole] })
  }

  get canDownload(): boolean {
    if (this.getItem && this.getItem.itemType === ItemType.PrintOrder) {
      return this.getItem
    }

    if (this.getItems && this.getItems.every((item) => item.itemType === ItemType.PrintOrder)) {
      return this.getItems.length
    }
  }

  share() {
    this.$emit('share')
  }

  unshare() {
    this.$emit('unshare')
  }

  download() {
    this.$emit('download')
  }

  moveOrCopySelectedItems() {
    this.setIsMoveItems(true)
    this.$emit('move')
  }

  rename() {
    this.$emit('rename')
  }

  endCollaboration() {
    this.$emit('endCollaboration')
  }

  trash() {
    this.$emit('trash')
  }

  unfavoriteAll() {
    this.$emit('unfavoriteAll')
  }
}
