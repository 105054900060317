/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Vector3, Matrix, Quaternion, TmpVectors } from '@babylonjs/core/Maths'
import { InstancedMesh, MeshBuilder, TransformNode } from '@babylonjs/core/Meshes'
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo'
import { BVHTreeNode } from '@/visualization/models/BVHTreeNode'
import {
  MIN,
  MAX,
  BVH_BOX,
  MESH_RENDERING_GROUP_ID,
  BUILD_VOLUME_LIMIT_ZONE,
  KEEP_OUT_ZONE,
  MAX_PH_INDEXING,
  PART_BODY_ID_DELIMITER,
  CLEARANCE_MIN_DISPLAY_DISTANCE,
} from '@/constants'
import DCPQuerry from './rendering/DCPQuery'
import { IRenderable } from './types/IRenderable'
import { ClearanceModes, MeshClearanceResult } from './types/ClearanceTypes'
import { RenderScene } from './render-scene'
import { ISystemReferenceClearanceMetadata } from './types/SceneItemMetadata'

export class OBBTree {
  public root
  public scene
  public debugBox
  private renderScene: IRenderable

  constructor(renderScene: IRenderable) {
    this.root = null
    this.renderScene = renderScene
    this.scene = renderScene.getScene()
    this.debugBox = MeshBuilder.CreateBox(BVH_BOX, { width: 0.01, height: 0.01, depth: 0.01 }, this.scene)
    this.debugBox.isPickable = false
    this.debugBox.renderingGroupId = MESH_RENDERING_GROUP_ID
    this.debugBox.isVisible = false
  }

  makeBoundingInfoFromObb(obb) {
    const minimum = new Vector3(-obb.size.x, -obb.size.y, -obb.size.z)
    const maximum = new Vector3(obb.size.x, obb.size.y, obb.size.z)
    // compute obb matrix before "parenting" to parentMesh
    const scale = new Vector3(1, 1, 1)
    const rotation = obb.rotation
    const r = new Vector3(rotation[0], rotation[3], rotation[6])
    const u = new Vector3(rotation[1], rotation[4], rotation[7])
    const f = Vector3.Cross(r, u)
    const localRotationQuaternion = Quaternion.RotationQuaternionFromAxis(r, u, f)
    const localTranslation = new Vector3(obb.center.x, obb.center.y, obb.center.z)
    const localMatrix = Matrix.Compose(scale, localRotationQuaternion, localTranslation)
    const bInfo = new BoundingInfo(minimum, maximum, localMatrix)
    return bInfo
  }

  deserializationHelper(bvhList, depth) {
    if (!bvhList.length) {
      return null
    }

    const obb = bvhList.shift()

    if (obb === null) {
      return null
    }

    const node = new BVHTreeNode(obb)
    const bInfo = this.makeBoundingInfoFromObb(obb)
    node.bInfo = bInfo
    node.depth = depth

    node.left = this.deserializationHelper(bvhList, depth + 1)
    node.right = this.deserializationHelper(bvhList, depth + 1)

    return node
  }

  deserializationHelperRss(
    bvhList,
    depth,
    worldR: Matrix,
    worldT: Vector3,
    bodies: Set<string>,
    parentMesh: TransformNode,
  ) {
    if (!bvhList.length) {
      return null
    }

    const rss = bvhList.shift()

    if (rss === null) {
      return null
    }

    const rotationMatrixFromObb = this.getRotationMatrixFromObb(rss)
    const RWC = worldR.multiply(rotationMatrixFromObb)
    const TPC = this.transformCoordinates(rss.center, worldR).add(worldT)

    const node = new BVHTreeNode(rss)
    const obb = this.getObbFromRss(rss, RWC, TPC)
    const bInfo = this.makeBoundingInfoFromObb(obb)
    node.bInfo = bInfo
    node.depth = depth

    if (rss.triangleIds && parentMesh) {
      rss.triangleIds.forEach((triangleId) => {
        const geometryId = triangleId.geometryId
        const componentId = triangleId.componentId
        const meshManager = this.renderScene.getMeshManager()
        const geometryMesh = parentMesh
          .getChildMeshes()
          .find(
            (mesh) =>
              meshManager.isComponentMesh(mesh) &&
              mesh.metadata.geometryId === geometryId &&
              mesh.metadata.componentId === componentId,
          )

        // For some legacy bvh.json files, geometryId and componentId are incorrect for brep parts.
        // To avoid issues with incorrect geometryId and componentId,
        // We can check if there is a component with same geometryId and componentId
        if (geometryMesh) {
          const bodyId = `${componentId}${PART_BODY_ID_DELIMITER}${geometryId}`
          node.facetsIndices.set(bodyId, triangleId.triangleIds)
          bodies.add(bodyId)
        }
      })
    }

    node.left = this.deserializationHelperRss(bvhList, depth + 1, RWC, TPC, bodies, parentMesh)
    node.right = this.deserializationHelperRss(bvhList, depth + 1, RWC, TPC, bodies, parentMesh)

    return node
  }

  deserializeBVH(bvh: [], parentMesh?: TransformNode): BVHTreeNode[] {
    const bvhList = bvh

    if (bvhList.length === 0) {
      return null
    }

    const worldR = Matrix.Identity()
    const worldT = Vector3.Zero()

    const bvhRoots: BVHTreeNode[] = []
    while (bvhList.length > 0) {
      const rootObb = bvhList.find((x) => x !== undefined)
      let bvhRoot
      const bodies = new Set<string>()
      if (this.isObbLegacy(rootObb)) {
        bvhRoot = this.deserializationHelper(bvhList, 0)
      } else {
        bvhRoot = this.deserializationHelperRss(bvhList, 0, worldR, worldT, bodies, parentMesh)
      }
      bvhRoot.bodies = bodies
      bvhRoots.push(bvhRoot)
    }

    return bvhRoots
  }

  /**
   * Check if BVH Tree contains data about triangle ids
   * @param originalRoots root node of tree
   * @returns true if tree does not contain triangle ids, otherwise returns false
   */
  isBVHTreeWithoutTriangleIds(originalRoots: BVHTreeNode | BVHTreeNode[]) {
    const roots = originalRoots instanceof BVHTreeNode ? [originalRoots] : originalRoots
    return roots.every((root) => root.bodies.size === 0)
  }

  isObbLegacy(obb) {
    if (obb.radius === undefined || obb.spCenter === undefined) {
      return true
    }
    return false
  }

  isItemLegacy(mesh) {
    if (mesh.metadata.bvh) {
      const root = mesh.metadata.bvh
      const roots = root instanceof BVHTreeNode ? [root] : root
      const firstRoot = roots.find((bvhroot) => bvhroot !== undefined)
      return this.isObbLegacy(firstRoot.obb)
    }
  }

  showObbTreeHelper(root, leafNodeVisibility, parentMesh, depth?) {
    if ((depth !== undefined && root.depth === depth) || (root.left === null && root.right === null)) {
      if (leafNodeVisibility) {
        if (!root.debugBox) {
          root.debugBox = this.debugBox.createInstance(BVH_BOX)
          root.debugBox.isVisible = true
          root.debugBox.doNotSyncBoundingInfo = true
        }
        const localMatrix = root.bInfo.boundingBox.getWorldMatrix().clone()
        const newMatrix = localMatrix.multiply(parentMesh.getWorldMatrix())
        const newBInfo = new BoundingInfo(root.bInfo.minimum, root.bInfo.maximum, newMatrix)
        root.debugBox.setBoundingInfo(newBInfo)
        root.debugBox.showBoundingBox = true
      } else if (root.debugBox) {
        this.scene.removeMesh(root.debugBox)
        root.debugBox.dispose()
        root.debugBox = null
      }

      return
    }

    this.showObbTreeHelper(root.left, leafNodeVisibility, parentMesh, depth)
    this.showObbTreeHelper(root.right, leafNodeVisibility, parentMesh, depth)
  }

  showObbTree(roots, leafNodeVisibility, parentMesh, depth?) {
    for (const root of roots) {
      this.showObbTreeHelper(root, leafNodeVisibility, parentMesh, depth)
    }
  }

  getObbSize(root) {
    return root.bInfo.diagonalLength
  }

  bvhIntersectionHelper(rootA, rootB, parentMeshA, parentMeshB) {
    let result: boolean = false
    if (!this.collideBox(rootA, rootB, parentMeshA, parentMeshB)) {
      return false
    }

    if (this.isLeaf(rootA)) {
      if (this.isLeaf(rootB)) {
        return true
      }
    }

    const obbSizeA = this.getObbSize(rootA)
    const obbSizeB = this.getObbSize(rootB)

    if (this.isLeaf(rootB) || (!this.isLeaf(rootA) && obbSizeA > obbSizeB)) {
      result = this.bvhIntersectionHelper(rootA.left, rootB, parentMeshA, parentMeshB)
      if (result) {
        return true
      }
      result = this.bvhIntersectionHelper(rootA.right, rootB, parentMeshA, parentMeshB)
    } else {
      result = this.bvhIntersectionHelper(rootA, rootB.left, parentMeshA, parentMeshB)
      if (result) {
        return true
      }
      result = this.bvhIntersectionHelper(rootA, rootB.right, parentMeshA, parentMeshB)
    }

    return result
  }

  bvhCollisionHelper(rootA, rootB, parentMeshA, parentMeshB) {
    let result: boolean = false
    if (!this.collideBox(rootA, rootB, parentMeshA, parentMeshB)) {
      return false
    }

    if (this.isLeaf(rootA)) {
      if (this.isLeaf(rootB)) {
        return true
      }
      result =
        this.bvhCollisionHelper(rootA, rootB.left, parentMeshA, parentMeshB) ||
        this.bvhCollisionHelper(rootA, rootB.right, parentMeshA, parentMeshB)
    } else {
      if (this.isLeaf(rootB)) {
        result =
          this.bvhCollisionHelper(rootA.left, rootB, parentMeshA, parentMeshB) ||
          this.bvhCollisionHelper(rootA.right, rootB, parentMeshA, parentMeshB)
      } else {
        result =
          this.bvhCollisionHelper(rootA.left, rootB.left, parentMeshA, parentMeshB) ||
          this.bvhCollisionHelper(rootA.left, rootB.right, parentMeshA, parentMeshB) ||
          this.bvhCollisionHelper(rootA.right, rootB.left, parentMeshA, parentMeshB) ||
          this.bvhCollisionHelper(rootA.right, rootB.right, parentMeshA, parentMeshB)
      }
    }

    return result
  }

  bvhCollision(originalRootA, originalRootB, parentMeshA, parentMeshB) {
    let result: boolean = false
    // for single geometry parts and keep-out zones root will be BVHTreeNode
    // for parts merged via CAD Helper root will be BVHTreeNode[]
    // convert each to BVHTreeNode[] here for consistency of further comparison
    const rootsA = originalRootA instanceof BVHTreeNode ? [originalRootA] : originalRootA
    const rootsB = originalRootB instanceof BVHTreeNode ? [originalRootB] : originalRootB

    for (const rootA of rootsA) {
      for (const rootB of rootsB) {
        result = result || this.bvhIntersectionHelper(rootA, rootB, parentMeshA, parentMeshB)
        // this.bvhCollisionHelper(rootA, rootB, parentMeshA, parentMeshB)
        if (result) {
          // if found a collision between a pair of trees - break early
          break
        }
      }
    }

    return result
  }

  collideBox(rootA, rootB, parentMeshA, parentMeshB) {
    let bInfoA
    if (![BUILD_VOLUME_LIMIT_ZONE, KEEP_OUT_ZONE].includes(parentMeshA.name)) {
      const localMatrix = rootA.bInfo.boundingBox.getWorldMatrix().clone()
      const newMatrix = localMatrix.multiply(parentMeshA.getWorldMatrix())
      bInfoA = new BoundingInfo(rootA.bInfo.minimum, rootA.bInfo.maximum, newMatrix)
    } else {
      bInfoA = rootA.bInfo
    }
    let bInfoB
    if (![BUILD_VOLUME_LIMIT_ZONE, KEEP_OUT_ZONE].includes(parentMeshB.name)) {
      const localMatrix = rootB.bInfo.boundingBox.getWorldMatrix().clone()
      const newMatrix = localMatrix.multiply(parentMeshB.getWorldMatrix())
      bInfoB = new BoundingInfo(rootB.bInfo.minimum, rootB.bInfo.maximum, newMatrix)
    } else {
      bInfoB = rootB.bInfo
    }
    return bInfoA.intersects(bInfoB, true)
  }

  transformCoordinates(vector: Vector3, rotation: Matrix) {
    const transformedVector = Vector3.Zero()
    transformedVector.x =
      vector.x * rotation.m[0] + vector.y * rotation.m[1] + vector.z * rotation.m[2] + 1.0 * rotation.m[3]
    transformedVector.y =
      vector.x * rotation.m[4] + vector.y * rotation.m[5] + vector.z * rotation.m[6] + 1.0 * rotation.m[7]
    transformedVector.z =
      vector.x * rotation.m[8] + vector.y * rotation.m[9] + vector.z * rotation.m[10] + 1.0 * rotation.m[11]
    return transformedVector
  }

  isNominal(parentMesh) {
    const translation: Vector3 = Vector3.Zero()
    const rotation: Quaternion = Quaternion.Identity()
    const transform: Matrix = Matrix.Identity()
    let invTransform = Matrix.Identity()
    const scale: Vector3 = Vector3.Zero()
    if (![BUILD_VOLUME_LIMIT_ZONE, KEEP_OUT_ZONE].includes(parentMesh.name)) {
      invTransform = parentMesh.getWorldMatrix()
      invTransform.transposeToRef(transform)
      invTransform.decompose(scale, rotation, translation)
    }
    if (
      Math.round((scale.x + Number.EPSILON) * 1000) / 1000 !== 1 ||
      Math.round((scale.y + Number.EPSILON) * 1000) / 1000 !== 1 ||
      Math.round((scale.z + Number.EPSILON) * 1000) / 1000 !== 1
    ) {
      return true
    }
    return false
  }

  rssCollision(originalRootA, originalRootB, parentMeshA, parentMeshB) {
    let result: boolean = false
    const rootsA = originalRootA instanceof BVHTreeNode ? [originalRootA] : originalRootA
    const rootsB = originalRootB instanceof BVHTreeNode ? [originalRootB] : originalRootB

    for (const rootA of rootsA) {
      for (const rootB of rootsB) {
        result = result || this.rssCollisionHelper(rootA, rootB, parentMeshA, parentMeshB)
        if (result) {
          break
        }
      }
    }

    return result
  }

  rssCollisionHelper(rootA, rootB, parentMeshA, parentMeshB) {
    const translationA: Vector3 = Vector3.Zero()
    const translationB: Vector3 = Vector3.Zero()
    const rotationA: Quaternion = Quaternion.Identity()
    const rotationB: Quaternion = Quaternion.Identity()
    const transformA: Matrix = Matrix.Identity()
    const transformB: Matrix = Matrix.Identity()
    let invTransformA = Matrix.Identity()
    let invTransformB = Matrix.Identity()
    const scaleA: Vector3 = Vector3.Zero()
    const scaleB: Vector3 = Vector3.Zero()
    if (![BUILD_VOLUME_LIMIT_ZONE, KEEP_OUT_ZONE].includes(parentMeshA.name)) {
      invTransformA = parentMeshA.getWorldMatrix()
      invTransformA.transposeToRef(transformA)
      invTransformA.decompose(scaleA, rotationA, translationA)
    }
    if (![BUILD_VOLUME_LIMIT_ZONE, KEEP_OUT_ZONE].includes(parentMeshB.name)) {
      invTransformB = parentMeshB.getWorldMatrix()
      invTransformB.transposeToRef(transformB)
      invTransformB.decompose(scaleB, rotationB, translationB)
    }

    if (this.svDistance(transformA, transformB, rootA.obb, rootB.obb) > 0) return false

    const result = {
      R: Matrix.Identity(),
      T: Vector3.Zero(),
      tolerance: 0,
      distance: -1,
      transformA: Matrix.Identity(),
      transformB: Matrix.Identity(),
      closerThanTolerance: 0,
    }

    // compute transform from cs1 to cs2
    let rotationMatrixA: Matrix = Matrix.Identity()
    let rotationMatrixB: Matrix = Matrix.Identity()
    const invRotationMatrixA: Matrix = Matrix.Identity()
    rotationA.toRotationMatrix(rotationMatrixA)
    rotationB.toRotationMatrix(rotationMatrixB)
    rotationMatrixA = rotationMatrixA.transpose()
    rotationMatrixB = rotationMatrixB.transpose()
    rotationMatrixA.invertToRef(invRotationMatrixA)

    let tTemp = translationB.subtract(translationA)
    result.T = this.transformCoordinates(tTemp, invRotationMatrixA)
    result.R = invRotationMatrixA.multiply(rotationMatrixB)
    result.transformA = transformA
    result.transformB = transformB

    // compute transform from rootA.obb to rootB.obb
    const obbARotation = this.getRotationMatrixFromObb(rootA.obb)
    const obbBRotation = this.getRotationMatrixFromObb(rootB.obb)
    const invObbARotation: Matrix = Matrix.Identity()
    obbARotation.invertToRef(invObbARotation)
    const rTemp = result.R.multiply(obbBRotation)
    const R = invObbARotation.multiply(rTemp)
    tTemp = this.transformCoordinates(rootB.obb.center, result.R)
    tTemp.addInPlace(result.T)
    tTemp.subtractInPlace(new Vector3(rootA.obb.center.x, rootA.obb.center.y, rootA.obb.center.z))
    const T = this.transformCoordinates(tTemp, invObbARotation)

    const scale = new Vector3(1, 1, 1)
    const rotationQuaternion = Quaternion.FromRotationMatrix(R)
    const transformation = Matrix.Compose(scale, rotationQuaternion, T)

    const d = this.bvDistance(transformation, rootA.obb, rootB.obb)

    if (d <= result.tolerance) {
      this.toleranceRecurse(result, d, transformation, rootA, rootB)
    }

    return result.closerThanTolerance === 1
  }

  toleranceRecurse(
    result: {
      R: Matrix
      T: Vector3
      tolerance: number
      distance: number
      transformA: Matrix
      transformB: Matrix
      closerThanTolerance: number
    },
    distance: number,
    transformation: Matrix,
    o1: BVHTreeNode,
    o2: BVHTreeNode,
  ) {
    const position: Vector3 = Vector3.Zero()
    const rotation: Quaternion = Quaternion.Identity()
    const scale: Vector3 = Vector3.Zero()
    transformation.decompose(scale, rotation, position)
    const T: Vector3 = position
    const R: Matrix = Matrix.Identity()
    rotation.toRotationMatrix(R)
    const svDistance = this.svDistance(result.transformA, result.transformB, o1.obb, o2.obb)

    if (svDistance <= result.tolerance) {
      const sz1 = this.getRssSize(o1.obb)
      const sz2 = this.getRssSize(o2.obb)

      if (this.isLeaf(o1) && this.isLeaf(o2)) {
        result.closerThanTolerance = 1
        result.distance = distance
        return
      }

      let a1: BVHTreeNode
      let a2: BVHTreeNode
      let c1: BVHTreeNode
      let c2: BVHTreeNode
      let R1: Matrix = Matrix.Identity()
      let R2: Matrix = Matrix.Identity()
      let T1: Vector3 = Vector3.Zero()
      let T2: Vector3 = Vector3.Zero()
      let tTemp: Vector3 = Vector3.Zero()

      if (this.isLeaf(o2) || (!this.isLeaf(o1) && sz1 > sz2)) {
        a1 = o1.left
        a2 = o2
        c1 = o1.right
        c2 = o2

        let obbInverseRotation = this.getObbRotationInverse(a1.obb)
        R1 = obbInverseRotation.multiply(R)
        tTemp = T.subtract(new Vector3(a1.obb.center.x, a1.obb.center.y, a1.obb.center.z))
        T1 = this.transformCoordinates(tTemp, obbInverseRotation)

        obbInverseRotation = this.getObbRotationInverse(c1.obb)
        R2 = obbInverseRotation.multiply(R)
        tTemp = T.subtract(new Vector3(c1.obb.center.x, c1.obb.center.y, c1.obb.center.z))
        T2 = this.transformCoordinates(tTemp, obbInverseRotation)
      } else {
        a1 = o1
        a2 = o2.left
        c1 = o1
        c2 = o2.right

        R1 = R.multiply(this.getRotationMatrixFromObb(a2.obb))
        T1 = this.transformCoordinates(a2.obb.center, R)
        T1.addInPlace(T)

        R2 = R.multiply(this.getRotationMatrixFromObb(c2.obb))
        T2 = this.transformCoordinates(c2.obb.center, R)
        T2.addInPlace(T)
      }

      const transform1 = this.getTransformation(R1, T1)
      const transform2 = this.getTransformation(R2, T2)
      const d1 = this.bvDistance(transform1, a1.obb, a2.obb)
      const d2 = this.bvDistance(transform2, c1.obb, c2.obb)

      if (d2 < d1) {
        if (d2 <= result.tolerance) {
          this.toleranceRecurse(result, d2, transform2, c1, c2)
        }
        if (result.closerThanTolerance === 1) return
        if (d1 <= result.tolerance) {
          this.toleranceRecurse(result, d1, transform1, a1, a2)
        }
      } else {
        if (d1 <= result.tolerance) {
          this.toleranceRecurse(result, d1, transform1, a1, a2)
        }
        if (result.closerThanTolerance === 1) return
        if (d2 <= result.tolerance) {
          this.toleranceRecurse(result, d2, transform2, c1, c2)
        }
      }
    }
  }

  segPoints(
    P: Vector3, // seg 1 origin
    A: Vector3, // seg 1 vector
    Q: Vector3, // seg 2 origin
    B: Vector3, // seg 2 vector
  ): {
    vec: Vector3
    X: Vector3
    Y: Vector3 // closest points
  } {
    let T: Vector3 = Q.subtract(P)
    const A_DOT_A = Vector3.Dot(A, A)
    const B_DOT_B = Vector3.Dot(B, B)
    const A_DOT_B = Vector3.Dot(A, B)
    const A_DOT_T = Vector3.Dot(A, T)
    const B_DOT_T = Vector3.Dot(B, T)

    // t parameterizes ray P,A
    // u parameterizes ray Q,B

    let t: number = 0
    let u: number = 0

    // compute t for the closest point on ray P,A to
    // ray Q,B
    const denom = A_DOT_A * B_DOT_B - A_DOT_B * A_DOT_B
    t = (A_DOT_T * B_DOT_B - B_DOT_T * A_DOT_B) / denom

    // clamp result so t is on the segment P,A
    if (t < 0 || Number.isNaN(t)) {
      t = 0
    } else if (t > 1) {
      t = 1
    }

    // find u for point on ray Q,B closest to point at t
    u = (t * A_DOT_B - B_DOT_T) / B_DOT_B

    // if u is on segment Q,B, t and u correspond to
    // closest points, otherwise, clamp u, recompute and
    // clamp t

    const result = { vec: Vector3.Zero(), X: Vector3.Zero(), Y: Vector3.Zero() }
    if (u <= 0 || Number.isNaN(u)) {
      result.Y = Q.clone()
      t = A_DOT_T / A_DOT_A

      if (t <= 0 || Number.isNaN(t)) {
        result.X = P.clone()
        result.vec = Q.subtract(P)
      } else if (t >= 1) {
        result.X = P.add(A)
        result.vec = Q.subtract(result.X)
      } else {
        result.X = P.add(A.scale(t))
        result.vec = Vector3.Cross(A, Vector3.Cross(T, A))
      }
    } else if (u >= 1) {
      result.Y = Q.add(B)
      t = (A_DOT_B + A_DOT_T) / A_DOT_A

      if (t <= 0 || Number.isNaN(t)) {
        result.X = P.clone()
        result.vec = result.Y.subtract(P)
      } else if (t >= 1) {
        result.X = P.add(A)
        result.vec = result.Y.subtract(result.X)
      } else {
        result.X = P.add(A.scale(t))
        T = result.Y.subtract(P)
        result.vec = Vector3.Cross(A, Vector3.Cross(T, A))
      }
    } else {
      result.Y = Q.add(B.scale(u))

      if (t <= 0 || Number.isNaN(t)) {
        result.X = P.clone()
        result.vec = Vector3.Cross(B, Vector3.Cross(T, B))
      } else if (t >= 1) {
        result.X = P.add(A)
        T = Q.subtract(result.X)
        result.vec = Vector3.Cross(B, Vector3.Cross(T, B))
      } else {
        result.X = P.add(A.scale(t))
        result.vec = Vector3.Cross(A, B)
        if (Vector3.Dot(result.vec, T) < 0) {
          result.vec.scaleInPlace(-1)
        }
      }
    }

    return result
  }

  /**
   * Function to get ray triangle intersection
   * More details here
   * https://iquilezles.org/articles/intersectors/
   * @param rayOrigin origin of ray
   * @param rayDirection direction of ray
   * @param v0 vertex0 of triangle
   * @param v1 vertex1 of triangle
   * @param v2 vertex2 of triangle
   * @returns distance from ray origin to triangle
   */
  rayTriangleIntersection(rayOrigin: Vector3, rayDirection: Vector3, v0: Vector3, v1: Vector3, v2: Vector3) {
    const v1v0 = v1.subtract(v0)
    const v2v0 = v2.subtract(v0)
    const rov0 = rayOrigin.subtract(v0)

    const n = Vector3.Cross(v1v0, v2v0)
    const q = Vector3.Cross(rov0, rayDirection)

    const d = 1.0 / Vector3.Dot(rayDirection, n)
    const u = d * Vector3.Dot(q.scale(-1.0), v2v0)
    const v = d * Vector3.Dot(q, v1v0)
    let t = d * Vector3.Dot(n.scale(-1.0), rov0)

    if (u < 0.0 || v < 0.0 || u + v > 1.0) {
      t = -1.0
    }

    return t
  }

  triangleDistance(
    S: Vector3[],
    T: Vector3[],
  ): {
    distance: number
    pointA: Vector3
    pointB: Vector3
  } {
    // Compute vectors along the 6 sides
    const sv: Vector3[] = [S[1].subtract(S[0]), S[2].subtract(S[1]), S[0].subtract(S[2])]

    const tv: Vector3[] = [T[1].subtract(T[0]), T[2].subtract(T[1]), T[0].subtract(T[2])]

    let vec: Vector3 = Vector3.Zero()
    let P: Vector3 = Vector3.Zero()
    let Q: Vector3 = Vector3.Zero()

    // For each edge pair, the vector connecting the closest points
    // of the edges defines a slab (parallel planes at head and tail
    // enclose the slab). If we can show that the off-edge vertex of
    // each triangle is outside of the slab, then the closest points
    // of the edges are the closest points for the triangles.
    // Even if these tests fail, it may be helpful to know the closest
    // points found, and whether the triangles were shown disjoint

    let V: Vector3 = Vector3.Zero()
    let Z: Vector3 = Vector3.Zero()
    let minP: Vector3 = Vector3.Zero()
    let minQ: Vector3 = Vector3.Zero()
    let mindd = Vector3.DistanceSquared(S[0], T[0]) + 1 // Set first minimum safely high
    let shownDisjoint = false

    for (let i = 0; i < 3; i += 1) {
      for (let j = 0; j < 3; j += 1) {
        const result = this.segPoints(S[i], sv[i], T[j], tv[j])
        vec = result.vec.clone()
        P = result.X.clone()
        Q = result.Y.clone()
        V = Q.subtract(P)
        const dd = Vector3.Dot(V, V)

        if (dd <= mindd) {
          minP = P.clone()
          minQ = Q.clone()
          mindd = dd

          Z = S[(i + 2) % 3].subtract(P)
          let a = Vector3.Dot(Z, vec)
          Z = T[(j + 2) % 3].subtract(Q)
          let b = Vector3.Dot(Z, vec)

          if (a <= 0 && b >= 0) {
            return {
              distance: Math.sqrt(dd),
              pointA: P,
              pointB: Q,
            }
          }

          const p = Vector3.Dot(V, vec)

          if (a < 0) {
            a = 0
          }

          if (b > 0) {
            b = 0
          }

          if (p - a + b > 0) {
            shownDisjoint = true
          }
        }
      }
    }

    // No edge pairs contained the closest points.
    // either:
    // 1. one of the closest points is a vertex, and the
    //    other point is interior to a face.
    // 2. the triangles are overlapping.
    // 3. an edge of one triangle is parallel to the other's face. If
    //    cases 1 and 2 are not true, then the closest points from the 9
    //    edge pairs checks above can be taken as closest points for the
    //    triangles.
    // 4. possibly, the triangles were degenerate.  When the
    //    triangle points are nearly colinear or coincident, one
    //    of above tests might fail even though the edges tested
    //    contain the closest points.

    // First check for case 1
    const sNormal: Vector3 = Vector3.Cross(sv[0], sv[1]) // Compute normal to S triangle
    const sNormalLength: number = Vector3.Dot(sNormal, sNormal) // Compute square of length of normal

    // If cross product is long enough,
    if (sNormalLength > 1e-15) {
      // Get projection lengths of T points

      const tp = new Array(3)

      V = S[0].subtract(T[0])
      tp[0] = Vector3.Dot(V, sNormal)

      V = S[0].subtract(T[1])
      tp[1] = Vector3.Dot(V, sNormal)

      V = S[0].subtract(T[2])
      tp[2] = Vector3.Dot(V, sNormal)

      // If Sn is a separating direction,
      // find point with smallest projection

      let point = -1
      if (tp[0] > 0 && tp[1] > 0 && tp[2] > 0) {
        if (tp[0] < tp[1]) {
          point = 0
        } else {
          point = 1
        }

        if (tp[2] < tp[point]) {
          point = 2
        }
      } else if (tp[0] < 0 && tp[1] < 0 && tp[2] < 0) {
        if (tp[0] > tp[1]) {
          point = 0
        } else {
          point = 1
        }

        if (tp[2] > tp[point]) {
          point = 2
        }
      }

      // If Sn is a separating direction,
      if (point >= 0) {
        shownDisjoint = true

        // Test whether the point found, when projected onto the
        // other triangle, lies within the face.

        V = T[point].subtract(S[0])
        Z = Vector3.Cross(sNormal, sv[0])
        if (Vector3.Dot(V, Z) > 0) {
          V = T[point].subtract(S[1])
          Z = Vector3.Cross(sNormal, sv[1])
          if (Vector3.Dot(V, Z) > 0) {
            V = T[point].subtract(S[2])
            Z = Vector3.Cross(sNormal, sv[2])
            if (Vector3.Dot(V, Z) > 0) {
              // T[point] passed the test - it's a closest point for
              // the T triangle; the other point is on the face of S

              const scale = tp[point] / sNormalLength
              P = T[point].add(sNormal.scale(scale))
              Q = T[point].clone()
              return {
                distance: Vector3.Distance(P, Q),
                pointA: P,
                pointB: Q,
              }
            }
          }
        }
      }
    }

    const tNormal: Vector3 = Vector3.Cross(tv[0], tv[1]) // Compute normal to T triangle
    const tNormalLength: number = Vector3.Dot(tNormal, tNormal) // Compute square of length of normal

    if (tNormalLength > 1e-15) {
      const sp = new Array(3)

      V = T[0].subtract(S[0])
      sp[0] = Vector3.Dot(V, tNormal)

      V = T[0].subtract(S[1])
      sp[1] = Vector3.Dot(V, tNormal)

      V = T[0].subtract(S[2])
      sp[2] = Vector3.Dot(V, tNormal)

      let point = -1
      if (sp[0] > 0 && sp[1] > 0 && sp[2] > 0) {
        if (sp[0] < sp[1]) {
          point = 0
        } else {
          point = 1
        }

        if (sp[2] < sp[point]) {
          point = 2
        }
      } else if (sp[0] < 0 && sp[1] < 0 && sp[2] < 0) {
        if (sp[0] > sp[1]) {
          point = 0
        } else {
          point = 1
        }

        if (sp[2] > sp[point]) {
          point = 2
        }
      }

      if (point >= 0) {
        shownDisjoint = true

        V = S[point].subtract(T[0])
        Z = Vector3.Cross(tNormal, tv[0])
        if (Vector3.Dot(V, Z) > 0) {
          V = S[point].subtract(T[1])
          Z = Vector3.Cross(tNormal, tv[1])
          if (Vector3.Dot(V, Z) > 0) {
            V = S[point].subtract(T[2])
            Z = Vector3.Cross(tNormal, tv[2])
            if (Vector3.Dot(V, Z) > 0) {
              const scale = sp[point] / tNormalLength
              P = S[point].clone()
              Q = S[point].add(tNormal.scale(scale))
              return {
                distance: Vector3.Distance(P, Q),
                pointA: P,
                pointB: Q,
              }
            }
          }
        }
      }
    }

    // Case 1 can't be shown.
    // If one of these tests showed the triangles disjoint,
    // we assume case 3 or 4, otherwise we conclude case 2,
    // that the triangles overlap.

    if (shownDisjoint) {
      return {
        distance: Math.sqrt(mindd),
        pointA: minP,
        pointB: minQ,
      }
    }

    let intersectionPoint: Vector3
    for (let i = 0; i < 3; i += 1) {
      let direction = sv[i].normalizeToNew()
      let t = this.rayTriangleIntersection(S[i], direction, T[0], T[1], T[2])
      if (t !== -1.0) {
        intersectionPoint = S[i].add(direction.scale(t))
        break
      }

      direction = tv[i].normalizeToNew()
      t = this.rayTriangleIntersection(T[i], direction, S[0], S[1], S[2])
      if (t !== -1.0) {
        intersectionPoint = T[i].add(direction.scale(t))
        break
      }
    }

    return {
      distance: 0,
      pointA: intersectionPoint ? intersectionPoint : P,
      pointB: intersectionPoint ? intersectionPoint : Q,
    }
  }

  /**
   * Calculates distance from part to system reference mesh for clearance tool
   * @param result object that contains info about measurement result
   * @param originalRoot roots of BVH tree of part
   * @param parentMesh part itself
   * @param wallMesh mesh that represents wall on scene
   */
  rssRectangleDistance(
    result: {
      distance: number
      referenceId: string
      pointA: Vector3
      pointB: Vector3
    },
    originalRoot: BVHTreeNode | BVHTreeNode[],
    parentMesh: TransformNode,
    wallMesh: InstancedMesh,
  ) {
    // for single geometry parts and keep-out zones root will be BVHTreeNode
    // for parts merged via CAD Helper root will be BVHTreeNode[]
    // convert each to BVHTreeNode[] here for consistency of further comparison
    const roots = originalRoot instanceof BVHTreeNode ? [originalRoot] : originalRoot
    for (const root of roots) {
      this.rssRectangleDistanceRecurse(result, root, parentMesh, wallMesh)
    }
  }

  /**
   * Calculates distance from part to system reference mesh for clearance tool
   * @param result object that contains info about measurement result
   * @param node node of BVH tree of part
   * @param parentMesh part itself
   * @param wallMesh mesh that represents wall on scene
   */
  rssRectangleDistanceRecurse(
    result: {
      distance: number
      referenceId: string
      pointA: Vector3
      pointB: Vector3
    },
    node: BVHTreeNode,
    parentMesh: TransformNode,
    wallMesh: InstancedMesh,
  ) {
    const modelManager = (this.renderScene as RenderScene).getModelManager()
    const metadata = wallMesh.metadata as ISystemReferenceClearanceMetadata
    if (this.isLeaf(node)) {
      for (const [bodyId, indices] of node.facetsIndices) {
        const { componentId, geometryId } = modelManager.getBodyIdsInfo(bodyId)
        const geometryMesh = parentMesh
          .getChildMeshes()
          .find(
            (mesh) =>
              this.renderScene.getMeshManager().isComponentMesh(mesh) &&
              mesh.metadata.geometryId === geometryId &&
              mesh.metadata.componentId === componentId,
          )

        const meshIndices = geometryMesh.getIndices()
        const meshPositions = geometryMesh.getPositionData()

        const meshTriangles = []
        for (const index of indices) {
          const meshTriangle = []
          for (let i = 0; i < 3; i += 1) {
            const trianglePosIndex = meshIndices[index * 3 + i]
            const trianglePosition = new Vector3(
              meshPositions[trianglePosIndex * 3],
              meshPositions[trianglePosIndex * 3 + 1],
              meshPositions[trianglePosIndex * 3 + 2],
            )
            meshTriangle.push(Vector3.TransformCoordinates(trianglePosition, geometryMesh.getWorldMatrix()))
          }
          meshTriangles.push(meshTriangle)
        }

        for (const wallTriangle of metadata.triangles) {
          for (const meshTriangle of meshTriangles) {
            const triDistance = this.triangleDistance(wallTriangle, meshTriangle)
            if (triDistance.distance < result.distance) {
              result.distance = triDistance.distance
              result.pointA = triDistance.pointA
              result.pointB = triDistance.pointB
            }

            if (Math.abs(triDistance.distance - result.distance) < CLEARANCE_MIN_DISPLAY_DISTANCE) {
              result.referenceId = metadata.referenceId
            }
          }
        }
      }
      return
    }

    const left = node.left
    const localMatrixLeft = left.bInfo.boundingBox.getWorldMatrix()
    localMatrixLeft.multiplyToRef(parentMesh.getWorldMatrix(), TmpVectors.Matrix[0])
    const leftBInfoWCS = new BoundingInfo(left.bInfo.minimum, left.bInfo.maximum, TmpVectors.Matrix[0])
    let d1 = Number.MAX_VALUE
    metadata.rectangles.forEach((rectangle) => {
      const { distance } = DCPQuerry.rectangleObbDistance(rectangle, leftBInfoWCS.boundingBox)
      if (distance < d1) {
        d1 = distance
      }
    })

    const right = node.right
    const localMatrixRight = right.bInfo.boundingBox.getWorldMatrix()
    localMatrixRight.multiplyToRef(parentMesh.getWorldMatrix(), TmpVectors.Matrix[1])
    const rightBInfoWCS = new BoundingInfo(right.bInfo.minimum, right.bInfo.maximum, TmpVectors.Matrix[1])
    let d2 = Number.MAX_VALUE
    metadata.rectangles.forEach((rectangle) => {
      const { distance } = DCPQuerry.rectangleObbDistance(rectangle, rightBInfoWCS.boundingBox)
      if (distance < d2) {
        d2 = distance
      }
    })

    if (d2 < d1) {
      if (d2 < result.distance) {
        this.rssRectangleDistanceRecurse(result, right, parentMesh, wallMesh)
      }

      if (d1 < result.distance) {
        this.rssRectangleDistanceRecurse(result, left, parentMesh, wallMesh)
      }
    } else {
      if (d1 < result.distance) {
        this.rssRectangleDistanceRecurse(result, left, parentMesh, wallMesh)
      }

      if (d2 < result.distance) {
        this.rssRectangleDistanceRecurse(result, right, parentMesh, wallMesh)
      }
    }
  }

  /**
   * Calculates shortest distance between triangles inside provided BVHTreeNodes
   * @param o1 leaf bvh node of first mesh
   * @param parentMeshA first mesh
   * @param o2 leaf bvh node of second mesh
   * @param parentMeshB second mesh
   */
  obbTriangleDistance(
    o1: BVHTreeNode,
    parentMeshA: TransformNode,
    o2: BVHTreeNode,
    parentMeshB: TransformNode,
    originalBodyIdA?: string,
    originalBodyIdB?: string,
  ) {
    const result = {
      distance: Number.MAX_VALUE,
      pointA: Vector3.Zero(),
      pointB: Vector3.Zero(),
      bpItemIdA: null as string,
      geometryIdA: null as string,
      componentIdA: null as string,
      bpItemIdB: null as string,
      geometryIdB: null as string,
      componentIdB: null as string,
    }

    const modelManager = (this.renderScene as RenderScene).getModelManager()
    const meshManager = this.renderScene.getMeshManager()
    const childMeshesA = parentMeshA.getChildMeshes()
    const childMeshesB = parentMeshB.getChildMeshes()

    for (const [bodyIdA, indicesA] of o1.facetsIndices) {
      if (originalBodyIdA && originalBodyIdA !== bodyIdA) {
        continue
      }

      const { componentId: componentIdA, geometryId: geometryIdA } = modelManager.getBodyIdsInfo(bodyIdA)
      const geometryMeshA = childMeshesA.find(
        (mesh) =>
          meshManager.isComponentMesh(mesh) &&
          mesh.metadata.geometryId === geometryIdA &&
          mesh.metadata.componentId === componentIdA,
      )

      const meshAIndices = geometryMeshA.getIndices()
      const meshAPositions = geometryMeshA.getPositionData()
      const meshATransformation = geometryMeshA.getWorldMatrix()

      for (const [bodyIdB, indicesB] of o2.facetsIndices) {
        if (originalBodyIdB && originalBodyIdB !== bodyIdB) {
          continue
        }

        const { componentId: componentIdB, geometryId: geometryIdB } = modelManager.getBodyIdsInfo(bodyIdB)
        const geometryMeshB = childMeshesB.find(
          (mesh) =>
            meshManager.isComponentMesh(mesh) &&
            mesh.metadata.geometryId === geometryIdB &&
            mesh.metadata.componentId === componentIdB,
        )

        const meshBIndices = geometryMeshB.getIndices()
        const meshBPositions = geometryMeshB.getPositionData()
        const meshBTransformation = geometryMeshB.getWorldMatrix()

        const trianglesA = []
        for (const indexA of indicesA) {
          const triangleA = []
          for (let i = 0; i < 3; i += 1) {
            const index = meshAIndices[indexA * 3 + i]
            const trianglePositionA = new Vector3(
              meshAPositions[index * 3],
              meshAPositions[index * 3 + 1],
              meshAPositions[index * 3 + 2],
            )
            triangleA.push(Vector3.TransformCoordinates(trianglePositionA, meshATransformation))
          }

          trianglesA.push(triangleA)
        }

        const trianglesB = []
        for (const indexB of indicesB) {
          const triangleB = []
          for (let i = 0; i < 3; i += 1) {
            const index = meshBIndices[indexB * 3 + i]
            const trianglePositionB = new Vector3(
              meshBPositions[index * 3],
              meshBPositions[index * 3 + 1],
              meshBPositions[index * 3 + 2],
            )
            triangleB.push(Vector3.TransformCoordinates(trianglePositionB, meshBTransformation))
          }

          trianglesB.push(triangleB)
        }

        for (const triangleA of trianglesA) {
          for (const triangleB of trianglesB) {
            const triDistance = this.triangleDistance(triangleA, triangleB)
            if (triDistance.distance < result.distance) {
              result.distance = triDistance.distance
              result.pointA = triDistance.pointA
              result.pointB = triDistance.pointB
              result.bpItemIdA = parentMeshA.metadata.buildPlanItemId
              result.geometryIdA = geometryIdA
              result.componentIdA = componentIdA
              result.bpItemIdB = parentMeshB.metadata.buildPlanItemId
              result.geometryIdB = geometryIdB
              result.componentIdB = componentIdB
            }
          }
        }
      }
    }

    return result
  }

  /**
   * Calculates distance between BVHs of given meshes. Use if both meshes have metadata.bvh.obb.
   * @param originalRootA BVH root/s of first mesh
   * @param originalRootB BVH root/s of second mesh
   * @param parentMeshA first mesh
   * @param parentMeshB second mesh
   * @param bodyIdA body id of component of parent meshA to measure distance from this component
   * @param bodyIdB body id of component of parent meshB to measure distance to this component
   * @param checkEveryLeafNode if true will check every triangle pair in leaf nodes.
   * Otherwise, will return distance between triangles inside closest bvh boxes
   * @returns object that contains distance and closest points between meshes
   */
  rssDistance(
    originalRootA: BVHTreeNode | BVHTreeNode[],
    originalRootB: BVHTreeNode | BVHTreeNode[],
    parentMeshA: TransformNode,
    parentMeshB: TransformNode,
    options?: {
      checkEveryLeafNode?: boolean
      bodyIdA: string
      bodyIdB: string
    },
  ): MeshClearanceResult {
    const { bodyIdA = null } = options || {}
    const { bodyIdB = null } = options || {}

    let result: MeshClearanceResult = {
      mode: ClearanceModes.Mesh,
      distance: Number.MAX_VALUE,
      pointA: null,
      bpItemIdA: null,
      geometryIdA: null,
      componentIdA: null,
      pointB: null,
      bpItemIdB: null,
      geometryIdB: null,
      componentIdB: null,
    }

    // for single geometry parts and keep-out zones root will be BVHTreeNode
    // for parts merged via CAD Helper root will be BVHTreeNode[]
    // convert each to BVHTreeNode[] here for consistency of further comparison
    const rootsA = originalRootA instanceof BVHTreeNode ? [originalRootA] : originalRootA
    const rootsB = originalRootB instanceof BVHTreeNode ? [originalRootB] : originalRootB

    for (const rootA of rootsA) {
      if (bodyIdA && !rootA.bodies.has(bodyIdA)) {
        continue
      }

      for (const rootB of rootsB) {
        if (bodyIdB && !rootB.bodies.has(bodyIdB)) {
          continue
        }

        const clearanceResult = this.rssDistanceHelper(rootA, rootB, parentMeshA, parentMeshB, options)
        if (clearanceResult.distance < result.distance) {
          result = clearanceResult
        }
      }
    }

    return result
  }

  /**
   * Calculates distance between BVH of given meshes. Use if both meshes have metadata.bvh.obb.
   * @param originalRootA BVH root of first mesh
   * @param originalRootB BVH root of second mesh
   * @param parentMeshA first mesh
   * @param parentMeshB second mesh
   * @param bodyIdA body id of component of parent meshA to measure distance from this component
   * @param bodyIdB body id of component of parent meshB to measure distance to this component
   * @param checkEveryLeafNode if true will check every triangle pair in leaf nodes.
   * Otherwise, will return distance between triangles inside closest bvh boxes
   * @returns object that contains distance and closest points between meshes
   */
  rssDistanceHelper(
    rootA: BVHTreeNode,
    rootB: BVHTreeNode,
    parentMeshA: TransformNode,
    parentMeshB: TransformNode,
    options?: {
      checkEveryLeafNode?: boolean
      bodyIdA: string
      bodyIdB: string
    },
  ): MeshClearanceResult {
    const { checkEveryLeafNode = false } = options || {}
    const { bodyIdA = null } = options || {}
    const { bodyIdB = null } = options || {}

    const translationA: Vector3 = Vector3.Zero()
    const translationB: Vector3 = Vector3.Zero()
    const rotationA: Quaternion = Quaternion.Identity()
    const rotationB: Quaternion = Quaternion.Identity()
    const transformA: Matrix = Matrix.Identity()
    const transformB: Matrix = Matrix.Identity()
    let invTransformA = Matrix.Identity()
    let invTransformB = Matrix.Identity()
    const scaleA: Vector3 = Vector3.Zero()
    const scaleB: Vector3 = Vector3.Zero()

    // If parentMeshA is buildVolumeLimitZone or keepOutZone do not take it transformation
    if (![BUILD_VOLUME_LIMIT_ZONE, KEEP_OUT_ZONE].includes(parentMeshA.name)) {
      invTransformA = parentMeshA.getWorldMatrix()
      invTransformA.transposeToRef(transformA)
      invTransformA.decompose(scaleA, rotationA, translationA)
    }

    // If parentMeshB is buildVolumeLimitZone or keepOutZone do not take it transformation
    if (![BUILD_VOLUME_LIMIT_ZONE, KEEP_OUT_ZONE].includes(parentMeshB.name)) {
      invTransformB = parentMeshB.getWorldMatrix()
      invTransformB.transposeToRef(transformB)
      invTransformB.decompose(scaleB, rotationB, translationB)
    }

    const result = {
      R: Matrix.Identity(),
      T: Vector3.Zero(),
      distance: Number.MAX_VALUE,
      pointA: Vector3.Zero(),
      o1: null as BVHTreeNode,
      pointB: Vector3.Zero(),
      o2: null as BVHTreeNode,
      transformA: Matrix.Identity(),
      transformB: Matrix.Identity(),

      bpItemIdA: null as string,
      geometryIdA: null as string,
      componentIdA: null as string,
      bpItemIdB: null as string,
      geometryIdB: null as string,
      componentIdB: null as string,
    }

    // compute transform from cs1 to cs2
    let rotationMatrixA: Matrix = Matrix.Identity()
    let rotationMatrixB: Matrix = Matrix.Identity()
    const invRotationMatrixA: Matrix = Matrix.Identity()
    rotationA.toRotationMatrix(rotationMatrixA)
    rotationB.toRotationMatrix(rotationMatrixB)
    rotationMatrixA = rotationMatrixA.transpose()
    rotationMatrixB = rotationMatrixB.transpose()
    rotationMatrixA.invertToRef(invRotationMatrixA)

    let tTemp = translationB.subtract(translationA)
    result.T = this.transformCoordinates(tTemp, invRotationMatrixA)
    result.R = invRotationMatrixA.multiply(rotationMatrixB)
    result.transformA = transformA
    result.transformB = transformB

    // compute transform from rootA.obb to rootB.obb
    const obbARotation = this.getRotationMatrixFromObb(rootA.obb)
    const obbBRotation = this.getRotationMatrixFromObb(rootB.obb)
    const invObbARotation: Matrix = Matrix.Identity()
    obbARotation.invertToRef(invObbARotation)
    const rTemp = result.R.multiply(obbBRotation)
    const R = invObbARotation.multiply(rTemp)
    tTemp = this.transformCoordinates(rootB.obb.center, result.R)
    tTemp.addInPlace(result.T)
    tTemp.subtractInPlace(new Vector3(rootA.obb.center.x, rootA.obb.center.y, rootA.obb.center.z))
    const T = this.transformCoordinates(tTemp, invObbARotation)

    const scale = new Vector3(1, 1, 1)
    const rotationQuaternion = Quaternion.FromRotationMatrix(R)
    const transformation = Matrix.Compose(scale, rotationQuaternion, T)

    this.distanceRecurse(result, transformation, rootA, parentMeshA, rootB, parentMeshB, options)

    if (result.o1 && result.o2 && !checkEveryLeafNode) {
      const preciseDistance = this.obbTriangleDistance(result.o1, parentMeshA, result.o2, parentMeshB, bodyIdA, bodyIdB)

      result.distance = preciseDistance.distance
      result.pointA = preciseDistance.pointA
      result.pointB = preciseDistance.pointB

      result.bpItemIdA = preciseDistance.bpItemIdA
      result.geometryIdA = preciseDistance.geometryIdA
      result.componentIdA = preciseDistance.componentIdA
      result.bpItemIdB = preciseDistance.bpItemIdB
      result.geometryIdB = preciseDistance.geometryIdB
      result.componentIdB = preciseDistance.componentIdB
    }

    return {
      mode: ClearanceModes.Mesh,
      distance: result.distance,
      pointA: result.pointA,
      bpItemIdA: result.bpItemIdA,
      geometryIdA: result.geometryIdA,
      componentIdA: result.componentIdA,
      pointB: result.pointB,
      bpItemIdB: result.bpItemIdB,
      geometryIdB: result.geometryIdB,
      componentIdB: result.componentIdB,
    }
  }

  /**
   * Recursively calculates distance between closest bvh nodes
   * @param result
   * object that contains updatable pointA,
   * pointB and distance between them,
   * transformation from rootA.obb to rootB.obb
   * @param transformation tranformation from o1 to o2 or from o2 to o1
   * @param o1 child BVH node of first mesh
   * @param parentMeshA given first mesh
   * @param o2 child BVH node of second mesh
   * @param parentMeshB given second mesh
   * @param bodyIdA body id of component of parent meshA to measure distance from this component
   * @param bodyIdB body id of component of parent meshB to measure distance to this component
   * @param checkEveryLeafNode if true will check every triangle pair in leaf nodes.
   * Otherwise, will return closest bvh boxes and distance between them
   */
  distanceRecurse(
    result: {
      R: Matrix
      T: Vector3
      distance: number
      pointA: Vector3
      o1: BVHTreeNode
      pointB: Vector3
      o2: BVHTreeNode
      transformA: Matrix
      transformB: Matrix
      bpItemIdA: string
      geometryIdA: string
      componentIdA: string
      bpItemIdB: string
      geometryIdB: string
      componentIdB: string
    },
    transformation: Matrix,
    o1: BVHTreeNode,
    parentMeshA: TransformNode,
    o2: BVHTreeNode,
    parentMeshB: TransformNode,
    options?: {
      checkEveryLeafNode?: boolean
      bodyIdA: string
      bodyIdB: string
    },
  ) {
    const { checkEveryLeafNode = false } = options || {}
    const { bodyIdA = null } = options || {}
    const { bodyIdB = null } = options || {}

    const position: Vector3 = Vector3.Zero()
    const rotation: Quaternion = Quaternion.Identity()
    const scale: Vector3 = Vector3.Zero()
    transformation.decompose(scale, rotation, position)
    const T: Vector3 = position
    const R: Matrix = Matrix.Identity()
    rotation.toRotationMatrix(R)
    // This check will increase perfomance for non legacy obb
    // Because there is no need to check precise distance between two obbs
    // when distance between spheres of these obbs is greater than result.distance
    if (!this.isObbLegacy(o1.obb) && !this.isObbLegacy(o2.obb)) {
      const svDistance = this.svDistance(result.transformA, result.transformB, o1.obb, o2.obb)

      if (svDistance > result.distance) {
        return
      }
    }

    const sz1 = this.getRssSize(o1.obb)
    const sz2 = this.getRssSize(o2.obb)

    // Both nodes are leaves. Calculate distance between them and return.
    if (this.isLeaf(o1) && this.isLeaf(o2)) {
      if (checkEveryLeafNode) {
        const triDistance = this.obbTriangleDistance(o1, parentMeshA, o2, parentMeshB, bodyIdA, bodyIdB)
        if (triDistance.distance < result.distance) {
          result.distance = triDistance.distance
          result.pointA = triDistance.pointA
          result.pointB = triDistance.pointB
          result.bpItemIdA = triDistance.bpItemIdA
          result.geometryIdA = triDistance.geometryIdA
          result.componentIdA = triDistance.componentIdA
          result.bpItemIdB = triDistance.bpItemIdB
          result.geometryIdB = triDistance.geometryIdB
          result.componentIdB = triDistance.componentIdB
        }
      } else {
        if ((bodyIdA && !o1.facetsIndices.has(bodyIdA)) || (bodyIdB && !o2.facetsIndices.has(bodyIdB))) {
          return
        }

        const localMatrixO1 = o1.bInfo.boundingBox.getWorldMatrix().clone()
        const newMatrixO1 = localMatrixO1.multiply(parentMeshA.getWorldMatrix())
        const newBInfoO1 = new BoundingInfo(o1.bInfo.minimum, o1.bInfo.maximum, newMatrixO1)
        const o1BB = newBInfoO1.boundingBox

        const localMatrixO2 = o2.bInfo.boundingBox.getWorldMatrix().clone()
        const newMatrixO2 = localMatrixO2.multiply(parentMeshB.getWorldMatrix())
        const newBInfoO2 = new BoundingInfo(o2.bInfo.minimum, o2.bInfo.maximum, newMatrixO2)
        const o2BB = newBInfoO2.boundingBox

        const obbDistance = DCPQuerry.obbDistance(o1BB, o2BB)
        if (obbDistance.distance < result.distance) {
          result.distance = obbDistance.distance
          result.o1 = o1
          result.pointA = obbDistance.pointA
          result.o2 = o2
          result.pointB = obbDistance.pointB
        }
      }

      return
    }

    let a1: BVHTreeNode
    let a2: BVHTreeNode
    let c1: BVHTreeNode
    let c2: BVHTreeNode
    let R1: Matrix = Matrix.Identity()
    let R2: Matrix = Matrix.Identity()
    let T1: Vector3 = Vector3.Zero()
    let T2: Vector3 = Vector3.Zero()
    let tTemp: Vector3 = Vector3.Zero()

    // First, perform distance tests on the children. Then traverse
    // them recursively, but test the closer pair first, the further
    // pair second.

    if (this.isLeaf(o2) || (!this.isLeaf(o1) && sz1 > sz2)) {
      a1 = o1.left
      a2 = o2
      c1 = o1.right
      c2 = o2

      let obbInverseRotation = this.getObbRotationInverse(a1.obb)
      R1 = obbInverseRotation.multiply(R)
      tTemp = T.subtract(new Vector3(a1.obb.center.x, a1.obb.center.y, a1.obb.center.z))
      T1 = this.transformCoordinates(tTemp, obbInverseRotation)

      obbInverseRotation = this.getObbRotationInverse(c1.obb)
      R2 = obbInverseRotation.multiply(R)
      tTemp = T.subtract(new Vector3(c1.obb.center.x, c1.obb.center.y, c1.obb.center.z))
      T2 = this.transformCoordinates(tTemp, obbInverseRotation)
    } else {
      a1 = o1
      a2 = o2.left
      c1 = o1
      c2 = o2.right

      R1 = R.multiply(this.getRotationMatrixFromObb(a2.obb))
      T1 = this.transformCoordinates(a2.obb.center, R)
      T1.addInPlace(T)

      R2 = R.multiply(this.getRotationMatrixFromObb(c2.obb))
      T2 = this.transformCoordinates(c2.obb.center, R)
      T2.addInPlace(T)
    }

    const transform1 = this.getTransformation(R1, T1)
    const transform2 = this.getTransformation(R2, T2)

    const localMatrixA1 = a1.bInfo.boundingBox.getWorldMatrix()
    localMatrixA1.multiplyToRef(parentMeshA.getWorldMatrix(), TmpVectors.Matrix[0])
    const newBInfoA1 = new BoundingInfo(a1.bInfo.minimum, a1.bInfo.maximum, TmpVectors.Matrix[0])
    const localMatrixA2 = a2.bInfo.boundingBox.getWorldMatrix()
    localMatrixA2.multiplyToRef(parentMeshB.getWorldMatrix(), TmpVectors.Matrix[1])
    const newBInfoA2 = new BoundingInfo(a2.bInfo.minimum, a2.bInfo.maximum, TmpVectors.Matrix[1])
    const { distance: d1 } = DCPQuerry.obbDistance(newBInfoA1.boundingBox, newBInfoA2.boundingBox)

    const localMatrixC1 = c1.bInfo.boundingBox.getWorldMatrix()
    localMatrixC1.multiplyToRef(parentMeshA.getWorldMatrix(), TmpVectors.Matrix[0])
    const newBInfoC1 = new BoundingInfo(c1.bInfo.minimum, c1.bInfo.maximum, TmpVectors.Matrix[0])
    const localMatrixC2 = c2.bInfo.boundingBox.getWorldMatrix()
    localMatrixC2.multiplyToRef(parentMeshB.getWorldMatrix(), TmpVectors.Matrix[1])
    const newBInfoC2 = new BoundingInfo(c2.bInfo.minimum, c2.bInfo.maximum, TmpVectors.Matrix[1])
    const { distance: d2 } = DCPQuerry.obbDistance(newBInfoC1.boundingBox, newBInfoC2.boundingBox)

    if (d2 < d1) {
      if (d2 < result.distance) {
        this.distanceRecurse(result, transform2, c1, parentMeshA, c2, parentMeshB, options)
      }

      if (d1 < result.distance) {
        this.distanceRecurse(result, transform1, a1, parentMeshA, a2, parentMeshB, options)
      }
    } else {
      if (d1 < result.distance) {
        this.distanceRecurse(result, transform1, a1, parentMeshA, a2, parentMeshB, options)
      }

      if (d2 < result.distance) {
        this.distanceRecurse(result, transform2, c1, parentMeshA, c2, parentMeshB, options)
      }
    }
  }

  getObbFromRss(rss, RWC: Matrix, TPC: Vector3) {
    const origin: Vector3 = TPC
    const extent = rss.size
    const r = new Vector3(RWC.m[0], RWC.m[4], RWC.m[8])
    const u = new Vector3(RWC.m[1], RWC.m[5], RWC.m[9])
    r.normalize()
    u.normalize()
    const f = Vector3.Cross(r, u)
    const rotation: number[] = [r.x, u.x, f.x, r.y, u.y, f.y, r.z, u.z, f.z]
    const cornerA = r.scale(extent.x).add(origin)
    const cornerB = u.scale(extent.y).add(origin)
    const center = cornerA.add(cornerB).scale(0.5)
    const size = new Vector3(extent.x / 2 + extent.z, extent.y / 2 + extent.z, extent.z)
    const obb = { size, center, rotation }
    return obb
  }

  getTransformation(R: Matrix, T: Vector3) {
    const scale = new Vector3(1, 1, 1)
    const rotationQuaternion = Quaternion.FromRotationMatrix(R)
    return Matrix.Compose(scale, rotationQuaternion, T)
  }

  getRotationMatrixFromObb(obb) {
    const rotation = obb.rotation
    const r = new Vector3(rotation[0], rotation[3], rotation[6])
    const u = new Vector3(rotation[1], rotation[4], rotation[7])
    const f = Vector3.Cross(r, u)
    const localRotationQuaternion = Quaternion.RotationQuaternionFromAxis(r, u, f)
    const localRotationMatrixInverse = Matrix.Identity()
    localRotationQuaternion.toRotationMatrix(localRotationMatrixInverse)
    const localRotationMatrix = Matrix.Identity()
    localRotationMatrixInverse.invertToRef(localRotationMatrix)
    return localRotationMatrix
  }

  getObbRotationInverse(obb) {
    const rotationMatrixFromObb = this.getRotationMatrixFromObb(obb)
    const obbInverseRotation: Matrix = Matrix.Identity()
    rotationMatrixFromObb.invertToRef(obbInverseRotation)
    return obbInverseRotation
  }

  isLeaf(root: BVHTreeNode) {
    return root.left === null && root.right === null
  }

  getRssSize(obb) {
    return Math.sqrt(obb.size.x * obb.size.x + obb.size.y * obb.size.y) + 2 * obb.size.z
  }

  svDistance(transformA: Matrix, transformB: Matrix, obbA, obbB) {
    const transformedSpCenterA = this.transformCoordinates(obbA.spCenter, transformA)
    const transformedSpCenterB = this.transformCoordinates(obbB.spCenter, transformB)
    return transformedSpCenterA.subtract(transformedSpCenterB).length() - (obbA.radius + obbB.radius)
  }

  bvDistance(transformation, obbA, obbB) {
    const dist = this.rectDist(transformation, obbA.size, obbB.size) - (obbA.size.z + obbB.size.z)
    return dist < 0.0 ? 0.0 : dist
  }

  clipToRange(value: number, a: number, b: number) {
    if (value < a) return a
    if (value > b) return b
    return value
  }

  segCoords(tu: { t: number; u: number }, a: number, b: number, A_DOT_B: number, A_DOT_T: number, B_DOT_T: number) {
    const denom = 1 - A_DOT_B * A_DOT_B

    if (denom === 0) tu.t = 0
    else {
      tu.t = (A_DOT_T - B_DOT_T * A_DOT_B) / denom
      tu.t = this.clipToRange(tu.t, 0, a)
    }

    tu.u = tu.t * A_DOT_B - B_DOT_T
    if (tu.u < 0) {
      tu.u = 0
      tu.t = A_DOT_T
      tu.t = this.clipToRange(tu.t, 0, a)
    } else if (tu.u > b) {
      tu.u = b
      tu.t = tu.u * A_DOT_B + A_DOT_T
      tu.t = this.clipToRange(tu.t, 0, a)
    }
  }

  inVoronoi(
    a: number,
    b: number,
    ANORM_DOT_B: number,
    ANORM_DOT_T: number,
    A_DOT_B: number,
    A_DOT_T: number,
    B_DOT_T: number,
  ) {
    const small = 1e-7
    if (Math.abs(ANORM_DOT_B) < small) return false

    let u = -ANORM_DOT_T / ANORM_DOT_B
    u = this.clipToRange(u, 0, b)

    let t = u * A_DOT_B + A_DOT_T
    t = this.clipToRange(t, 0, a)

    const v = t * A_DOT_B - B_DOT_T

    if (ANORM_DOT_B > 0) {
      if (v > u + small) return true
    } else {
      if (v < u - small) return true
    }
    return false
  }

  rectDist(transformation: Matrix, a: Vector3, b: Vector3) {
    const position: Vector3 = Vector3.Zero()
    const rotation: Quaternion = Quaternion.Identity()
    const scale: Vector3 = Vector3.Zero()
    transformation.decompose(scale, rotation, position)
    const TAB: Vector3 = position
    const RAB: Matrix = Matrix.Identity()
    rotation.toRotationMatrix(RAB)

    const A0_DOT_B0 = RAB.m[0]
    const A0_DOT_B1 = RAB.m[1]
    const A1_DOT_B0 = RAB.m[4]
    const A1_DOT_B1 = RAB.m[5]

    const AA0_DOT_B0 = a.x * A0_DOT_B0
    const AA0_DOT_B1 = a.x * A0_DOT_B1
    const AA1_DOT_B0 = a.y * A1_DOT_B0
    const AA1_DOT_B1 = a.y * A1_DOT_B1
    const BA0_DOT_B0 = b.x * A0_DOT_B0
    const BA1_DOT_B0 = b.x * A1_DOT_B0
    const BA0_DOT_B1 = b.y * A0_DOT_B1
    const BA1_DOT_B1 = b.y * A1_DOT_B1

    const rabInverse: Matrix = Matrix.Identity()
    RAB.invertToRef(rabInverse)
    const TBA: Vector3 = this.transformCoordinates(TAB, rabInverse)

    const S: Vector3 = Vector3.Zero()
    let t: number = 0
    let u: number = 0

    // determine if any edge pair contains the closest points

    let LA1_LX
    let LA1_UX
    let UA1_LX
    let UA1_UX
    let LB1_LX
    let LB1_UX
    let UB1_LX
    let UB1_UX

    const ALL_X = -TBA.x
    const ALU_X = ALL_X + AA1_DOT_B0
    const AUL_X = ALL_X + AA0_DOT_B0
    const AUU_X = ALU_X + AA0_DOT_B0

    if (ALL_X < ALU_X) {
      LA1_LX = ALL_X
      LA1_UX = ALU_X
      UA1_LX = AUL_X
      UA1_UX = AUU_X
    } else {
      LA1_LX = ALU_X
      LA1_UX = ALL_X
      UA1_LX = AUU_X
      UA1_UX = AUL_X
    }

    const BLL_X = TAB.x
    const BLU_X = BLL_X + BA0_DOT_B1
    const BUL_X = BLL_X + BA0_DOT_B0
    const BUU_X = BLU_X + BA0_DOT_B0

    if (BLL_X < BLU_X) {
      LB1_LX = BLL_X
      LB1_UX = BLU_X
      UB1_LX = BUL_X
      UB1_UX = BUU_X
    } else {
      LB1_LX = BLU_X
      LB1_UX = BLL_X
      UB1_LX = BUU_X
      UB1_UX = BUL_X
    }

    // UA1, UB1

    if (UA1_UX > b.x && UB1_UX > a.x) {
      if (
        (UA1_UX > b.x ||
          this.inVoronoi(
            b.y,
            a.y,
            A1_DOT_B0,
            AA0_DOT_B0 - b.x - TBA.x,
            A1_DOT_B1,
            AA0_DOT_B1 - TBA.y,
            -TAB.y - BA1_DOT_B0,
          )) &&
        (UB1_LX > a.x ||
          this.inVoronoi(
            a.y,
            b.y,
            A0_DOT_B1,
            TAB.x + BA0_DOT_B0 - a.x,
            A1_DOT_B1,
            TAB.y + BA1_DOT_B0,
            TBA.y - AA0_DOT_B1,
          ))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.y, b.y, A1_DOT_B1, TAB.y + BA1_DOT_B0, TBA.y - AA0_DOT_B1)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[0] * b.x + RAB.m[1] * u - a.x
        S.y = TAB.y + RAB.m[4] * b.x + RAB.m[5] * u - t
        S.z = TAB.z + RAB.m[8] * b.x + RAB.m[9] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    // UA1, LB1

    if (UA1_LX < 0 && LB1_UX > a.x) {
      if (
        (UA1_UX < 0 ||
          this.inVoronoi(b.y, a.y, -A1_DOT_B0, TBA.x - AA0_DOT_B0, A1_DOT_B1, AA0_DOT_B1 - TBA.y, -TAB.y)) &&
        (LB1_LX > a.x || this.inVoronoi(a.y, b.y, A0_DOT_B1, TAB.x - a.x, A1_DOT_B1, TAB.y, TBA.y - AA0_DOT_B1))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.y, b.y, A1_DOT_B1, TAB.y, TBA.y - AA0_DOT_B1)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[1] * u - a.x
        S.y = TAB.y + RAB.m[5] * u - t
        S.z = TAB.z + RAB.m[9] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    // LA1, UB1

    if (LA1_UX > b.x && UB1_LX < 0) {
      if (
        (LA1_LX > b.x || this.inVoronoi(b.y, a.y, A1_DOT_B0, -TBA.x - b.x, A1_DOT_B1, -TBA.y, -TAB.y - BA1_DOT_B0)) &&
        (UB1_UX < 0 || this.inVoronoi(a.y, b.y, -A0_DOT_B1, -TAB.x - BA0_DOT_B0, A1_DOT_B1, TAB.y + BA1_DOT_B0, TBA.y))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.y, b.y, A1_DOT_B1, TAB.y + BA1_DOT_B0, TBA.y)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[0] * b.x + RAB.m[1] * u
        S.y = TAB.y + RAB.m[4] * b.x + RAB.m[5] * u - t
        S.z = TAB.z + RAB.m[8] * b.x + RAB.m[9] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    // LA1, LB1

    if (LA1_LX < 0 && LB1_LX < 0) {
      if (
        (LA1_UX < 0 || this.inVoronoi(b.y, a.y, -A1_DOT_B0, TBA.x, A1_DOT_B1, -TBA.y, -TAB.y)) &&
        (LB1_UX < 0 || this.inVoronoi(a.y, b.y, -A0_DOT_B1, -TAB.x, A1_DOT_B1, TAB.y, TBA.y))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.y, b.y, A1_DOT_B1, TAB.y, TBA.y)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[1] * u
        S.y = TAB.y + RAB.m[5] * u - t
        S.z = TAB.z + RAB.m[9] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    const ALL_Y = -TBA.y
    const ALU_Y = ALL_Y + AA1_DOT_B1
    const AUL_Y = ALL_Y + AA0_DOT_B1
    const AUU_Y = ALU_Y + AA0_DOT_B1

    let LA1_LY
    let LA1_UY
    let UA1_LY
    let UA1_UY
    let LB0_LX
    let LB0_UX
    let UB0_LX
    let UB0_UX

    if (ALL_Y < ALU_Y) {
      LA1_LY = ALL_Y
      LA1_UY = ALU_Y
      UA1_LY = AUL_Y
      UA1_UY = AUU_Y
    } else {
      LA1_LY = ALU_Y
      LA1_UY = ALL_Y
      UA1_LY = AUU_Y
      UA1_UY = AUL_Y
    }

    if (BLL_X < BUL_X) {
      LB0_LX = BLL_X
      LB0_UX = BUL_X
      UB0_LX = BLU_X
      UB0_UX = BUU_X
    } else {
      LB0_LX = BUL_X
      LB0_UX = BLL_X
      UB0_LX = BUU_X
      UB0_UX = BLU_X
    }

    // UA1, UB0

    if (UA1_UY > b.y && UB0_UX > a.x) {
      if (
        (UA1_LY > b.y ||
          this.inVoronoi(
            b.x,
            a.y,
            A1_DOT_B1,
            AA0_DOT_B1 - TBA.y - b.y,
            A1_DOT_B0,
            AA0_DOT_B0 - TBA.x,
            -TAB.y - BA1_DOT_B1,
          )) &&
        (UB0_LX > a.x ||
          this.inVoronoi(
            a.y,
            b.x,
            A0_DOT_B0,
            TAB.x - a.x + BA0_DOT_B1,
            A1_DOT_B0,
            TAB.y + BA1_DOT_B1,
            TBA.x - AA0_DOT_B0,
          ))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.y, b.x, A1_DOT_B0, TAB.y + BA1_DOT_B1, TBA.x - AA0_DOT_B0)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[1] * b.y + RAB.m[0] * u - a.x
        S.y = TAB.y + RAB.m[5] * b.y + RAB.m[4] * u - t
        S.z = TAB.z + RAB.m[9] * b.y + RAB.m[8] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    // UA1, LB0

    if (UA1_LY < 0 && LB0_UX > a.x) {
      if (
        (UA1_UY < 0 ||
          this.inVoronoi(b.x, a.y, -A1_DOT_B1, TBA.y - AA0_DOT_B1, A1_DOT_B0, AA0_DOT_B0 - TBA.x, -TAB.y)) &&
        (LB0_LX > a.x || this.inVoronoi(a.y, b.x, A0_DOT_B0, TAB.x - a.x, A1_DOT_B0, TAB.y, TBA.x - AA0_DOT_B0))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.y, b.x, A1_DOT_B0, TAB.y, TBA.x - AA0_DOT_B0)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[0] * u - a.x
        S.y = TAB.y + RAB.m[4] * u - t
        S.z = TAB.z + RAB.m[8] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    // LA1, UB0

    if (LA1_UY > b.y && UB0_LX < 0) {
      if (
        (LA1_LY > b.y || this.inVoronoi(b.x, a.y, A1_DOT_B1, -TBA.y - b.y, A1_DOT_B0, -TBA.x, -TAB.y - BA1_DOT_B1)) &&
        (UB0_UX < 0 || this.inVoronoi(a.y, b.x, -A0_DOT_B0, -TAB.x - BA0_DOT_B1, A1_DOT_B0, TAB.y + BA1_DOT_B1, TBA.x))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.y, b.x, A1_DOT_B0, TAB.y + BA1_DOT_B1, TBA.x)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[1] * b.y + RAB.m[0] * u
        S.y = TAB.y + RAB.m[5] * b.y + RAB.m[4] * u - t
        S.z = TAB.z + RAB.m[9] * b.y + RAB.m[8] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    // LA1, LB0

    if (LA1_LY < 0 && LB0_LX < 0) {
      if (
        (LA1_UY < 0 || this.inVoronoi(b.x, a.y, -A1_DOT_B1, TBA.y, A1_DOT_B0, -TBA.x, -TAB.y)) &&
        (LB0_UX < 0 || this.inVoronoi(a.y, b.x, -A0_DOT_B0, -TAB.x, A1_DOT_B0, TAB.y, TBA.x))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.y, b.x, A1_DOT_B0, TAB.y, TBA.x)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[0] * u
        S.y = TAB.y + RAB.m[4] * u - t
        S.z = TAB.z + RAB.m[8] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    const BLL_Y = TAB.y
    const BLU_Y = BLL_Y + BA1_DOT_B1
    const BUL_Y = BLL_Y + BA1_DOT_B0
    const BUU_Y = BLU_Y + BA1_DOT_B0

    let LA0_LX
    let LA0_UX
    let UA0_LX
    let UA0_UX
    let LB1_LY
    let LB1_UY
    let UB1_LY
    let UB1_UY

    if (ALL_X < AUL_X) {
      LA0_LX = ALL_X
      LA0_UX = AUL_X
      UA0_LX = ALU_X
      UA0_UX = AUU_X
    } else {
      LA0_LX = AUL_X
      LA0_UX = ALL_X
      UA0_LX = AUU_X
      UA0_UX = ALU_X
    }

    if (BLL_Y < BLU_Y) {
      LB1_LY = BLL_Y
      LB1_UY = BLU_Y
      UB1_LY = BUL_Y
      UB1_UY = BUU_Y
    } else {
      LB1_LY = BLU_Y
      LB1_UY = BLL_Y
      UB1_LY = BUU_Y
      UB1_UY = BUL_Y
    }

    // UA0, UB1

    if (UA0_UX > b.x && UB1_UY > a.y) {
      if (
        (UA0_LX > b.x ||
          this.inVoronoi(
            b.y,
            a.x,
            A0_DOT_B0,
            AA1_DOT_B0 - TBA.x - b.x,
            A0_DOT_B1,
            AA1_DOT_B1 - TBA.y,
            -TAB.x - BA0_DOT_B0,
          )) &&
        (UB1_LY > a.y ||
          this.inVoronoi(
            a.x,
            b.y,
            A1_DOT_B1,
            TAB.y - a.y + BA1_DOT_B0,
            A0_DOT_B1,
            TAB.x + BA0_DOT_B0,
            TBA.y - AA1_DOT_B1,
          ))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.x, b.y, A0_DOT_B1, TAB.x + BA0_DOT_B0, TBA.y - AA1_DOT_B1)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[0] * b.x + RAB.m[1] * u - t
        S.y = TAB.y + RAB.m[4] * b.x + RAB.m[5] * u - a.y
        S.z = TAB.z + RAB.m[8] * b.x + RAB.m[9] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    // UA0, LB1

    if (UA0_LX < 0 && LB1_UY > a.y) {
      if (
        (UA0_UX < 0 ||
          this.inVoronoi(b.y, a.x, -A0_DOT_B0, TBA.x - AA1_DOT_B0, A0_DOT_B1, AA1_DOT_B1 - TBA.y, -TAB.x)) &&
        (LB1_LY > a.y || this.inVoronoi(a.x, b.y, A1_DOT_B1, TAB.y - a.y, A0_DOT_B1, TAB.x, TBA.y - AA1_DOT_B1))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.x, b.y, A0_DOT_B1, TAB.x, TBA.y - AA1_DOT_B1)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[1] * u - t
        S.y = TAB.y + RAB.m[5] * u - a.y
        S.z = TAB.z + RAB.m[9] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    // LA0, UB1

    if (LA0_UX > b.x && UB1_LY < 0) {
      if (
        (LA0_LX > b.x || this.inVoronoi(b.y, a.x, A0_DOT_B0, -b.x - TBA.x, A0_DOT_B1, -TBA.y, -BA0_DOT_B0 - TAB.x)) &&
        (UB1_UY < 0 || this.inVoronoi(a.x, b.y, -A1_DOT_B1, -TAB.y - BA1_DOT_B0, A0_DOT_B1, TAB.x + BA0_DOT_B0, TBA.y))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.x, b.y, A0_DOT_B1, TAB.x + BA0_DOT_B0, TBA.y)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[0] * b.x + RAB.m[1] * u - t
        S.y = TAB.y + RAB.m[4] * b.x + RAB.m[5] * u
        S.z = TAB.z + RAB.m[8] * b.x + RAB.m[9] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    // LA0, LB1

    if (LA0_LX < 0 && LB1_LY < 0) {
      if (
        (LA0_UX < 0 || this.inVoronoi(b.y, a.x, -A0_DOT_B0, TBA.x, A0_DOT_B1, -TBA.y, -TAB.x)) &&
        (LB1_UY < 0 || this.inVoronoi(a.x, b.y, -A1_DOT_B1, -TAB.y, A0_DOT_B1, TAB.x, TBA.y))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.x, b.y, A0_DOT_B1, TAB.x, TBA.y)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[1] * u - t
        S.y = TAB.y + RAB.m[5] * u
        S.z = TAB.z + RAB.m[9] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    let LA0_LY
    let LA0_UY
    let UA0_LY
    let UA0_UY
    let LB0_LY
    let LB0_UY
    let UB0_LY
    let UB0_UY

    if (ALL_Y < AUL_Y) {
      LA0_LY = ALL_Y
      LA0_UY = AUL_Y
      UA0_LY = ALU_Y
      UA0_UY = AUU_Y
    } else {
      LA0_LY = AUL_Y
      LA0_UY = ALL_Y
      UA0_LY = AUU_Y
      UA0_UY = ALU_Y
    }

    if (BLL_Y < BUL_Y) {
      LB0_LY = BLL_Y
      LB0_UY = BUL_Y
      UB0_LY = BLU_Y
      UB0_UY = BUU_Y
    } else {
      LB0_LY = BUL_Y
      LB0_UY = BLL_Y
      UB0_LY = BUU_Y
      UB0_UY = BLU_Y
    }

    // UA0, UB0

    if (UA0_UY > b.y && UB0_UY > a.y) {
      if (
        (UA0_LY > b.y ||
          this.inVoronoi(
            b.x,
            a.x,
            A0_DOT_B1,
            AA1_DOT_B1 - TBA.y - b.y,
            A0_DOT_B0,
            AA1_DOT_B0 - TBA.x,
            -TAB.x - BA0_DOT_B1,
          )) &&
        (UB0_LY > a.y ||
          this.inVoronoi(
            a.x,
            b.x,
            A1_DOT_B0,
            TAB.y - a.y + BA1_DOT_B1,
            A0_DOT_B0,
            TAB.x + BA0_DOT_B1,
            TBA.x - AA1_DOT_B0,
          ))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.x, b.x, A0_DOT_B0, TAB.x + BA0_DOT_B1, TBA.x - AA1_DOT_B0)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[1] * b.y + RAB.m[0] * u - t
        S.y = TAB.y + RAB.m[5] * b.y + RAB.m[4] * u - a.y
        S.z = TAB.z + RAB.m[9] * b.y + RAB.m[8] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    // UA0, LB0

    if (UA0_LY < 0 && LB0_UY > a.y) {
      if (
        (UA0_UY < 0 ||
          this.inVoronoi(b.x, a.x, -A0_DOT_B1, TBA.y - AA1_DOT_B1, A0_DOT_B0, AA1_DOT_B0 - TBA.x, -TAB.x)) &&
        (LB0_LY > a.y || this.inVoronoi(a.x, b.x, A1_DOT_B0, TAB.y - a.y, A0_DOT_B0, TAB.x, TBA.x - AA1_DOT_B0))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.x, b.x, A0_DOT_B0, TAB.x, TBA.x - AA1_DOT_B0)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[0] * u - t
        S.y = TAB.y + RAB.m[4] * u - a.y
        S.z = TAB.z + RAB.m[8] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    // LA0, UB0

    if (LA0_UY > b.y && UB0_LY < 0) {
      if (
        (LA0_LY > b.y || this.inVoronoi(b.x, a.x, A0_DOT_B1, -TBA.y - b.y, A0_DOT_B0, -TBA.x, -TAB.x - BA0_DOT_B1)) &&
        (UB0_UY < 0 || this.inVoronoi(a.x, b.x, -A1_DOT_B0, -TAB.y - BA1_DOT_B1, A0_DOT_B0, TAB.x + BA0_DOT_B1, TBA.x))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.x, b.x, A0_DOT_B0, TAB.x + BA0_DOT_B1, TBA.x)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[1] * b.y + RAB.m[0] * u - t
        S.y = TAB.y + RAB.m[5] * b.y + RAB.m[4] * u
        S.z = TAB.z + RAB.m[9] * b.y + RAB.m[8] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    // LA0, LB0

    if (LA0_LY < 0 && LB0_LY < 0) {
      if (
        (LA0_UY < 0 || this.inVoronoi(b.x, a.x, -A0_DOT_B1, TBA.y, A0_DOT_B0, -TBA.x, -TAB.x)) &&
        (LB0_UY < 0 || this.inVoronoi(a.x, b.x, -A1_DOT_B0, -TAB.y, A0_DOT_B0, TAB.x, TBA.x))
      ) {
        const tu = { t, u }
        this.segCoords(tu, a.x, b.x, A0_DOT_B0, TAB.x, TBA.x)
        t = tu.t
        u = tu.u

        S.x = TAB.x + RAB.m[0] * u - t
        S.y = TAB.y + RAB.m[4] * u
        S.z = TAB.z + RAB.m[8] * u
        return Math.sqrt(Vector3.Dot(S, S))
      }
    }

    // no edges passed, take max separation along face normals

    let sep1: number
    let sep2: number

    if (TAB.z > 0.0) {
      sep1 = TAB.z
      if (RAB.m[8] < 0.0) sep1 += b.x * RAB.m[8]
      if (RAB.m[9] < 0.0) sep1 += b.y * RAB.m[9]
    } else {
      sep1 = -TAB.z
      if (RAB.m[8] > 0.0) sep1 -= b.x * RAB.m[8]
      if (RAB.m[9] > 0.0) sep1 -= b.y * RAB.m[9]
    }

    if (TBA.z < 0) {
      sep2 = -TBA.z
      if (RAB.m[2] < 0.0) sep2 += a.x * RAB.m[2]
      if (RAB.m[6] < 0.0) sep2 += a.y * RAB.m[6]
    } else {
      sep2 = TBA.z
      if (RAB.m[2] > 0.0) sep2 -= a.x * RAB.m[2]
      if (RAB.m[6] > 0.0) sep2 -= a.y * RAB.m[6]
    }

    const sep = sep1 > sep2 ? sep1 : sep2
    return sep > 0 ? sep : 0
  }

  isPointBelowBuildHeight(buildPlateSizes, point: Vector3) {
    if (point.z > buildPlateSizes.buildVolumeHeight) {
      return false
    }
    return true
  }

  isPointInside(buildPlateSizes, point: Vector3, yShiftSize) {
    if (this.distanceFromPlateBoundary(buildPlateSizes, point, yShiftSize) < 0) {
      return false
    }
    return true
  }

  transformedPointsInside(buildPlateSizes, points: Vector3[], trf: Matrix, yShiftSize) {
    for (const point of points) {
      const pointInWorld = Vector3.TransformCoordinates(point, trf)
      if (!this.isPointInside(buildPlateSizes, pointInWorld, yShiftSize)) {
        return false
      }
    }
    return true
  }

  transformedPointsBelowBuildHeight(buildPlateSizes, points: Vector3[], trf: Matrix) {
    for (const point of points) {
      const pointInWorld = Vector3.TransformCoordinates(point, trf)
      if (!this.isPointBelowBuildHeight(buildPlateSizes, pointInWorld)) {
        return false
      }
    }
    return true
  }

  pointsInside(buildPlateSizes, points: Vector3[], yShiftSize) {
    for (const point of points) {
      if (!this.isPointInside(buildPlateSizes, point, yShiftSize)) {
        return false
      }
    }
    return true
  }

  pointsBelowBuildHeight(buildPlateSizes, points: Vector3[]) {
    for (const point of points) {
      if (!this.isPointBelowBuildHeight(buildPlateSizes, point)) {
        return false
      }
    }
    return true
  }

  distanceFromPlateBoundary(buildPlateSizes, point: Vector3, yShiftSize) {
    const angle = (Math.atan2(point.y, point.x) * 180) / Math.PI
    const normAngle = angle > 0 ? angle : 360 + angle
    const bpPoint = this.buildPlateBoundaryPoint(buildPlateSizes, normAngle, yShiftSize, true)
    const pointDistance = point.x * point.x + point.y * point.y
    const boundaryPointDistance = bpPoint.x * bpPoint.x + bpPoint.y * bpPoint.y
    return boundaryPointDistance - pointDistance
  }

  buildPlateBoundaryPoint(buildPlateMetadata, angle, yShiftSize, useBuildChamber) {
    // For Binder Jet if PH indexing value > 9mm, then the system shall reduce the size of
    // the buildable volume along Y-axis accordingly (2mm Y-reduction per 1mm indexing increase)
    let xMax
    let xMin
    let yMin
    let yMax
    let roundedCornerRad
    const PI = Math.PI

    const yAdjustment = yShiftSize > MAX_PH_INDEXING ? (yShiftSize - MAX_PH_INDEXING) * 2 : 0
    const bvX = buildPlateMetadata.buildVolumeX
    const bvY = buildPlateMetadata.buildVolumeY - yAdjustment

    if (useBuildChamber) {
      xMin = -bvX / 2
      xMax = bvX / 2
      yMin = -bvY / 2
      yMax = bvY / 2
      roundedCornerRad = buildPlateMetadata.roundedCornerRad - (buildPlateMetadata.xMax - xMax)
    } else {
      xMax = buildPlateMetadata.xMax
      yMax = buildPlateMetadata.yMax
      xMin = buildPlateMetadata.xMin
      yMin = buildPlateMetadata.yMin
      roundedCornerRad = buildPlateMetadata.roundedCornerRad
    }

    let x
    let y
    const deltaX = xMax - roundedCornerRad
    const deltaY = yMax - roundedCornerRad
    const theta = (Math.atan(deltaY / xMax) * 180) / PI
    const beta = (Math.atan(deltaX / yMax) * 180) / PI

    if (angle < theta || angle > 360 - theta) {
      x = xMax
      y = xMax * Math.tan((angle * PI) / 180)
    }
    if (angle >= theta && angle <= 90 - beta) {
      const phi = ((angle - theta) / (90 - theta - beta)) * 90
      x = deltaX + roundedCornerRad * Math.cos((phi * PI) / 180)
      y = deltaY + roundedCornerRad * Math.sin((phi * PI) / 180)
    }
    if (angle > 90 - beta && angle < 90 + beta) {
      x = yMax / Math.tan((angle * PI) / 180)
      y = yMax
    }
    if (angle >= 90 + beta && angle <= 180 - theta) {
      const phi = ((angle - 180 + theta) / (90 - theta - beta)) * 90
      x = -deltaX - roundedCornerRad * Math.cos((phi * PI) / 180)
      y = deltaY - roundedCornerRad * Math.sin((phi * PI) / 180)
    }
    if (angle > 180 - theta && angle < 180 + theta) {
      x = xMin
      y = xMin * Math.tan((angle * PI) / 180)
    }
    if (angle >= 180 + theta && angle <= 270 - beta) {
      const phi = ((angle - 180 - theta) / (90 - theta - beta)) * 90
      x = -deltaX - roundedCornerRad * Math.cos((phi * PI) / 180)
      y = -deltaY - roundedCornerRad * Math.sin((phi * PI) / 180)
    }
    if (angle > 270 - beta && angle < 270 + beta) {
      x = yMin / Math.tan(((angle - 180) * PI) / 180)
      y = yMin
    }
    if (angle >= 270 + beta && angle <= 360 - theta) {
      const phi = ((angle - 360 + theta) / (90 - theta - beta)) * 90
      x = deltaX + roundedCornerRad * Math.cos((phi * PI) / 180)
      y = -deltaY + roundedCornerRad * Math.sin((phi * PI) / 180)
    }
    return new Vector3(x, y, 0)
  }

  getOBBTreeBBox(root, recomputeWorldMatrix?, depth?) {
    let bBoxes = []
    const bvhRoots = root.metadata.bvh instanceof BVHTreeNode ? [root.metadata.bvh] : root.metadata.bvh
    for (const bvhRoot of bvhRoots) {
      bBoxes = bBoxes.concat(this.getBBoxes(bvhRoot, recomputeWorldMatrix, depth))
    }

    const min = new Vector3(MIN, MIN, MIN)
    const max = new Vector3(MAX, MAX, MAX)
    for (const bBox of bBoxes) {
      min.minimizeInPlace(bBox.minimumWorld)
      max.maximizeInPlace(bBox.maximumWorld)
    }

    return new BoundingInfo(min, max)
  }

  private getBBoxes(root, recomputeWorldMatrix?, depth?) {
    let bBoxes = []
    if ((depth !== undefined && root.depth === depth) || (root.left === null && root.right === null)) {
      return [root.bInfo.boundingBox]
    }

    bBoxes = bBoxes.concat(this.getBBoxes(root.left, recomputeWorldMatrix, depth))
    bBoxes = bBoxes.concat(this.getBBoxes(root.right, recomputeWorldMatrix, depth))

    return bBoxes
  }
}
