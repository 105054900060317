
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Drop } from 'vue-drag-drop'
import { Mixins, Watch } from 'vue-property-decorator'

import BuildPlanLayoutToolbar from '@/components/layout/buildPlans/BuildPlanLayoutToolbar.vue'
import BuildPlanDetails from '@/components/layout/buildPlans/BuildPlanDetails.vue'
import BuildPlanSidebar from '@/components/layout/buildPlans/BuildPlanSidebar.vue'
import BuildPlanSlider from '@/components/layout/buildPlans/BuildPlanSlider.vue'
import BuildPlanRasterViewer from '@/components/layout/buildPlans/BuildPlanRasterViewer.vue'
import ToggleDetailsPanelButton from '@/components/layout/FileExplorer/Details/ToggleDetailsPanelButton.vue'
import DetailsPanel from '@/components/layout/FileExplorer/DetailsPanel.vue'
import SinglePartCanvas from '@/components/layout/buildPlans/addPart/SinglePartCanvas.vue'
import XRayWidget from '@/components/layout/buildPlans/simulate/XRayWidget.vue'
import BuildPlanVariantsBar from '@/components/layout/buildPlans/BuildPlanVariantsBar.vue'
import VerticalSlider from '@/components/layout/buildPlans/simulate/VerticalSlider.vue'
import BuildPlanStickyComponent from '@/components/layout/buildPlans/stickyToPart/BuildPlanStickyComponent.vue'

import { RouterNames, RouterPaths } from '@/router'

import StoresNamespaces from '@/store/namespaces'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import VisualizationModeTypes from '@/visualization/types/VisualizationModeTypes'
import { getGPUInfo } from '@/utils/gpu'
import { IJob, JobStatusCode } from '@/types/PartsLibrary/Job'
import {
  IBuildPlan,
  ILoadingPart,
  IBuildPlanItem,
  IDisplayToolbarState,
  AddPartToolState,
  ISelectable,
  SelectionUnit,
} from '@/types/BuildPlans/IBuildPlan'
import { IGPUInfo } from '@/types/IGPUInfo'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import messageService from '@/services/messageService'
import Icon from '@/components/icons/Icon.vue'
import {
  DEFAULT_ITEM_VERSION,
  INCORRECT_UUID_MESSAGE_ERROR_2,
  ROOT_FOLDER_ID,
  BodyTypeIcons,
  PART_BODY_ID_DELIMITER,
} from '@/constants'
import { ContentViewModeTypes } from '@/visualization/types/ContentViewMode'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import BuildPlanSidebarTools from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import variables from '@/assets/styles/variables.scss'
import { PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import { CommandType } from '@/types/UndoRedo/CommandType'
import ModalsStateMixin from '@/components/layout/FileExplorer/Table/mixins/ModalsStateMixin'
import BuildPlanCostMixin from '@/components/layout/buildPlans/mixins/BuildPlanCostMixin'
import { ItemPermissionsRole, Permission } from '@/types/FileExplorer/Permission'
import { ItemAction } from '@/types/FileExplorer/ItemAction'
import { ViewMode } from '@/types/FileExplorer/ViewMode'
import { SceneMode } from '@/visualization/types/SceneTypes'
import { IMachineConfig, PrintingTypes } from '@/types/IMachineConfig'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { LoadBuildPlanOptions } from '@/visualization/types/LoadBuildPlanOptions'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { GeometryAvailability } from '@/visualization/types/Common'
import Menu from '@/components/controls/Common/Menu.vue'
import fileExplorer from '@/api/fileExplorer'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { CategoryKind } from '@/visualization/types/SimulationTypes'
import ToggleButton from '@/components/controls/Common/ToggleButton.vue'
import ClearanceToggleButton from '@/components/controls/ClearanceTool/ClearanceToggleButton.vue'
import ViewModesPanel from '@/components/controls/Common/ViewModesPanel.vue'
import ViewButton from '@/components/controls/Common/ViewButton.vue'
import { LabelSetDto } from '@/types/Label/LabelSetDto'
import {
  ActiveToolUnsavedChangesMixin,
  ExitToolAction,
} from '@/components/layout/buildPlans/mixins/ActiveToolUnsavedChangesMixin'
import ProgressModal from '@/components/modals/ProgressModal.vue'
import { DimensionBox, ClearanceTypes } from '@/visualization/types/ClearanceTypes'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { eventBus } from '@/services/EventBus'
import { BuildPlanEvents } from '@/types/Label/BuildPlanEvents'
import {
  getDefaultVariantIdFromVersionAndPath,
  isItemLockedForUser,
} from '@/utils/fileExplorerItem/fileExplorerItemUtils'

const TIMEOUT_FOR_UPDATE_GRID = 50

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const partsStore = namespace(StoresNamespaces.Parts)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const commandManager = namespace(StoresNamespaces.CommandManager)
const labelStore = namespace(StoresNamespaces.Labels)
const commonStore = namespace(StoresNamespaces.Common)

@Component({
  components: {
    BuildPlanLayoutToolbar,
    Drop,
    ToggleDetailsPanelButton,
    DetailsPanel,
    BuildPlanDetails,
    BuildPlanSidebar,
    Icon,
    BuildPlanSlider,
    BuildPlanRasterViewer,
    SinglePartCanvas,
    XRayWidget,
    BuildPlanVariantsBar,
    VerticalSlider,
    Menu,
    ToggleButton,
    ClearanceToggleButton,
    ViewModesPanel,
    ViewButton,
    BuildPlanStickyComponent,
    ProgressModal,
  },

  async beforeRouteUpdate(to, from, next) {
    const toolAction = await this.canExitActiveTool()
    if (toolAction === ExitToolAction.DoNotExit) {
      next(false)
    } else {
      setTimeout(() => {
        this.$refs.sidebar.onRouteChanged(to)
        next()
      }, 50)
    }
  },
  async beforeRouteLeave(to, from, next) {
    const toolAction = await this.canExitActiveTool()
    if (toolAction === ExitToolAction.DoNotExit) {
      next(false)
    } else {
      next()
    }
  },
})
export default class EditBuildPlan extends Mixins(ModalsStateMixin, BuildPlanCostMixin, ActiveToolUnsavedChangesMixin) {
  @commandManager.Action undoCommand: () => Promise<void>
  @commandManager.Action redoCommand: () => Promise<void>

  @commandManager.Getter canUndo: boolean
  @commandManager.Getter canRedo: boolean
  @commandManager.Getter getToolUndoStack: ICommand[]
  @commandManager.Getter getToolRedoStack: ICommand[]
  @commandManager.Getter getBuildPlanUndoStack: ICommand[]
  @commandManager.Getter getBuildPlanRedoStack: ICommand[]
  @commandManager.Getter isCommandManagerReady: boolean

  @commandManager.Mutation disposeBuildPlanStack: () => void
  @commandManager.Mutation disposeToolStack: () => void
  @commandManager.Mutation disable: () => void
  @commandManager.Mutation setToolModeActive: (isActive: boolean) => void

  @commandManager.State isToolModeActive: boolean

  @commonStore.Getter tooltipOpenDelay: number

  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes
  @buildPlansStore.Getter('getAllBuildPlanItems') buildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getLoadingParts: ILoadingPart[]
  @buildPlansStore.Getter getIsLoading: boolean
  @buildPlansStore.Getter getSelectedBuildPlanFinalizingJobs: IJob[]
  @buildPlansStore.Getter getContentViewMode: ContentViewModeTypes
  @buildPlansStore.Getter getSelectedBuildPlanJobs: IJob[]
  @buildPlansStore.Getter getMachineConfigByPk: (machineConfigPk: VersionablePk) => IMachineConfig
  @buildPlansStore.Getter getCommandType: CommandType
  @buildPlansStore.Getter isSceneReadOnly: boolean
  @buildPlansStore.Getter displayToolbarStateByVariantId: (buildPlanId: string) => IDisplayToolbarState
  @buildPlansStore.Getter isBuildPlanDisposing: boolean
  @buildPlansStore.Getter isToolMaskDisplaying: boolean
  @buildPlansStore.Getter getBuildPlanDisposePromise: { promise: Promise<void>; done: Function }
  @buildPlansStore.Getter getAddPartToolState: AddPartToolState
  @buildPlansStore.Getter('getBuildPlanPrintStrategy') printStrategy: BuildPlanPrintStrategyDto
  @buildPlansStore.Getter('getAddPartToolSelectedParts')
  selectedPartsToAdd: PartListItemViewModel[]
  @buildPlansStore.Getter('getSelectedParts') selectedParts: ISelectable[]

  @visualizationStore.Getter visualizationMode: VisualizationModeTypes
  @visualizationStore.Getter isViewLocked: boolean
  @visualizationStore.Getter isInitialized: boolean
  @visualizationStore.Getter getVisualizationLoading: boolean
  @visualizationStore.Getter isMouseOverCanvas: boolean
  @visualizationStore.Getter hoveredLabel: string
  @visualizationStore.Getter isLabelCreationMode: boolean
  @visualizationStore.Getter isLabelManualPlacement: boolean
  @visualizationStore.Getter isDownwardPlaneRotationInitialized: boolean
  @visualizationStore.Getter getNominalGeometryVisible: boolean
  @visualizationStore.Getter getMeshingAvailable: boolean
  @visualizationStore.Getter getMeshingLoaded: boolean
  @visualizationStore.Getter getCompensatedGeometryAvailable: boolean
  @visualizationStore.Getter getCompensatedGeometryVisible: boolean
  @visualizationStore.Getter getCompensatedGeometryLoaded: boolean
  @visualizationStore.Getter getNominalGeometryLoaded: boolean
  @visualizationStore.Getter getGreenCompensatedGeometryLoaded: boolean
  @visualizationStore.Getter getGreenCompensatedGeometryAvailable: boolean
  @visualizationStore.Getter getGreenCompensatedGeometryVisible: boolean
  @visualizationStore.Getter getNominalGeometryAvailable: GeometryAvailability
  @visualizationStore.Getter getBridgingElementsVisible: boolean
  @visualizationStore.Getter getGreenNominalGeometryVisible: boolean
  @visualizationStore.Getter getGreenNominalGeometryLoaded: boolean
  @visualizationStore.Getter getGreenNominalGeometryAvailable: boolean
  @visualizationStore.Getter getMeshingVisibility: boolean
  @visualizationStore.Getter getHandlerTogglesAvailable: boolean
  @visualizationStore.Getter getPartsVisible: boolean
  @visualizationStore.Getter getSupportsVisible: boolean
  @visualizationStore.Getter getCouponsVisible: boolean
  @visualizationStore.Getter getBuildPlateVisible: boolean
  @visualizationStore.Getter isCrossSectionModeEnabled: Function
  @visualizationStore.Getter isClearanceToolEnabled: Function
  @visualizationStore.Getter isClearanceFromEnabled: Function
  @visualizationStore.Getter isClearanceToEnabled: Function
  @visualizationStore.Getter isRubberBandShown: Function
  @visualizationStore.Getter highlightedClearanceIds: string[]
  @visualizationStore.Getter enabledClearanceFrom: ClearanceTypes
  @visualizationStore.Getter enabledClearanceTo: ClearanceTypes
  @visualizationStore.Getter getPreviewCreationPromise: { promise: Promise<void>; done: Function }
  @visualizationStore.Getter isShowHiddenPartsAsTransparentMode: boolean
  @visualizationStore.Getter isMeshToMeshClearance: Function
  @visualizationStore.Getter dimensionBoxes: DimensionBox[]
  @fileExplorerStore.Getter permissionsByItemId: Record<string, Permission[]>
  @fileExplorerStore.Getter getViewMode: ViewMode

  @partsStore.Getter getAllParts: IPartDto[]
  @partsStore.Getter getAllSinterParts: IPartDto[]
  @partsStore.Getter getAllIbcParts: IPartDto[]

  @buildPlansStore.Action loadBuildPlan: (payload: { buildPlan: IBuildPlan; options?: LoadBuildPlanOptions }) => void
  @buildPlansStore.Action getBuildPlanById: (id: string) => Promise<IBuildPlan>
  @buildPlansStore.Action fetchMaterials: Function
  @buildPlansStore.Action fetchMachineConfigs: Function
  @buildPlansStore.Action fetchBuildPlanJobs: Function
  @buildPlansStore.Action fetchRelatedBuildPlanVariants: (variantId: string) => Promise<void>
  @buildPlansStore.Action fetchInsightsByBuildPlanId: (payload: {
    buildPlanId: string
    changeState: boolean
  }) => Promise<IBuildPlanInsight[]>
  @buildPlansStore.Action fetchPrintSites: Function
  @buildPlansStore.Action changeRecoaterDirectionLabelVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action changeGasFlowDirectionVisibility: (isVisble: boolean) => void
  @buildPlansStore.Action changeBuildPlanVolumeVisibility: (isVisble: boolean) => void
  @buildPlansStore.Action changeBuildPlateVisibility: (isVisble: boolean) => void
  @buildPlansStore.Action changePrintHeadVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action changeSupportGeometryVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action changePrintHeadLanesVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action changeProductionGeometryVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action changeCouponGeometryVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action changeRecoaterDirectionShaderVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action changeOverhangAreasShaderVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action getBuildPlanPrintStrategy: (
    printStrategyPk: VersionablePk,
  ) => Promise<BuildPlanPrintStrategyDto>
  @buildPlansStore.Action checkAccessForCreateVariants: (id: string) => Promise<void>
  @buildPlansStore.Action changeLabeledBodiesVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action changeVisibilityByDisplayToolbarState: Function
  @buildPlansStore.Action showLoadingPart: (index: number) => void
  @buildPlansStore.Action hideLoadingPart: (index: number) => void
  @buildPlansStore.Action saveLoadingPart: Function
  @buildPlansStore.Action updateLoadingPartPosition: Function
  @buildPlansStore.Action changeSceneReadOnly: Function
  @buildPlansStore.Action setSelectionMode: Function

  @fileExplorerStore.Action getParentFolder: (itemId: string) => Promise<FileExplorerItem>
  @fileExplorerStore.Getter getDirectOrInheritedPermissionsByItemPath: (path: string) => Permission[]

  @partsStore.Action fetchAllParts: Function
  @partsStore.Action fetchAllSinterParts: Function
  @partsStore.Action fetchAllIbcParts: Function
  @partsStore.Action getPartIdsWithUsedDate: Function

  @visualizationStore.Action generatePreviewCreationPromise: () => { promise: Promise<void>; done: Function }

  @buildPlansStore.Mutation setBuildPlanViewMode: Function
  @buildPlansStore.Mutation deselectBuildPlan: Function
  @buildPlansStore.Mutation setParentFolder: (folder: FileExplorerItem) => void
  @buildPlansStore.Mutation setDisplayToolbarStates: Function
  @buildPlansStore.Mutation updateDisplayToolbarState: Function
  @buildPlansStore.Mutation setIsBuildPlanDisposing: (value: boolean) => void
  @buildPlansStore.Mutation setBuildPlanDisposePromise: (value: { promise: Promise<void>; done: Function }) => void
  @buildPlansStore.Mutation setRequiresLabelSetUpdates: (value: boolean) => void
  @buildPlansStore.Mutation('setIsLoading') setIsLoadingBuildPlan: (value: boolean) => void

  @visualizationStore.Mutation init: Function
  @visualizationStore.Mutation setViewLocked: Function
  @visualizationStore.Mutation dispose: Function
  @visualizationStore.Mutation updateItemPreview: Function
  @visualizationStore.Mutation changeViewMode: Function
  @visualizationStore.Mutation enableCrossSectionMode: Function
  @visualizationStore.Mutation enableClearanceTool: Function
  @visualizationStore.Mutation enableClearanceModeFrom: Function
  @visualizationStore.Mutation enableClearanceModeTo: Function
  @visualizationStore.Mutation measureDistanceToEnvironment: Function
  @visualizationStore.Mutation recenterCrossSection: Function
  @visualizationStore.Mutation axisAlignCrossSection: Function
  @visualizationStore.Mutation resizeCanvas: Function
  @visualizationStore.Mutation setMeshesVisibilityByName: Function
  @visualizationStore.Mutation showNominalGeometry: Function
  @visualizationStore.Mutation showMeshing: Function
  @visualizationStore.Mutation showCompensated: Function
  @visualizationStore.Mutation showGreenCompensated: Function
  @visualizationStore.Mutation showGreenNominal: Function
  @visualizationStore.Mutation setIsLoading: Function
  @visualizationStore.Mutation setHandlerVisibility: Function
  @visualizationStore.Mutation setIsMouseOverCanvas: Function
  @visualizationStore.Mutation('showMeshes') showMeshes: Function
  @visualizationStore.Mutation setShowHiddenPartsAsTransparent: Function
  @visualizationStore.Mutation showHiddenPartsTransparent: Function
  @visualizationStore.Mutation hideTransparentParts: Function
  @visualizationStore.Mutation hideGizmos: Function
  @visualizationStore.Mutation showRubberBand: Function
  @visualizationStore.Mutation changeClearanceSelectionObserver: Function
  @visualizationStore.Mutation deselect: () => void

  @labelStore.Action getLabelSetsByBuildPlanId: (payload: {
    buildPlanId: string
    dirtyStateAddIfNew?: boolean
  }) => Promise<LabelSetDto[]>

  @labelStore.Action setLabelSetsIDsForUpdate: (payload: { ids: string[]; doNotInvalidate?: boolean }) => void
  @labelStore.Action resetLabelSetsIDsForUpdate: (labelSetsIDs: string[]) => void

  @labelStore.Getter labelSets: InteractiveLabelSet[]

  @buildPlansStore.State buildPlan: IBuildPlan
  @buildPlansStore.State parentFolder: FileExplorerItem

  unlockBuildPlanReference = this.unlockBuildPlanHandler.bind(this)

  viewModes = ViewModeTypes
  contentViewModes = ContentViewModeTypes
  visualizationModes = VisualizationModeTypes
  clearanceTypes = ClearanceTypes
  gpuInfo: IGPUInfo = null

  isOpenDetails = true
  isCheckingAccess = false
  sliderDisabled = false
  showSlider = false
  showSliceToolbarButton = false
  crossSectionDisabled = false
  clearanceToolDisabled = false
  isBuildPlanReleased = false
  buildPlanNameTruncated = ''
  isFetching: boolean = true
  printHeadLanesAvailable: boolean = false
  oldIsViewLocked: boolean = false
  isBuildPlanCompletelyLoaded: boolean = false
  overflowMenuIsShown: boolean = false
  sliderPromise: { promise: Promise<void>; done: Function } = null
  undoTooltipText = ''
  redoTooltipText = ''
  forceUnlockVariant = false

  $refs!: {
    buildPlanNameField: Element
  }

  get modeToolbarComponent() {
    switch (this.getBuildPlanViewMode) {
      // disabling until we can use body and face selection in the future
      // case ViewModeTypes.Layout:
      //   return BuildPlanLayoutToolbar
      default:
        return null
    }
  }

  get productionBodyIcon() {
    return BodyTypeIcons.production
  }

  get supportBodyIcon() {
    return BodyTypeIcons.support
  }

  get couponBodyIcon() {
    return BodyTypeIcons.coupon
  }

  get showVisualizationModes() {
    const result = this.getBuildPlanViewMode === ViewModeTypes.Orientation
    const displayToolBarState = this.displayToolbarStateByVariantId(this.buildPlan.id)
    if (!result) {
      if (displayToolBarState.isShowingRecoaterDirectionShader) {
        this.changeRecoaterDirectionShaderVisibility(false)
      }
      if (displayToolBarState.isShowingOverhangAreasShader) {
        this.changeOverhangAreasShaderVisibility(false)
      }
    }
    return result
  }

  get isLabelToolEnabled() {
    return this.getBuildPlanViewMode === ViewModeTypes.Marking
  }

  get canShowNominalGeometry() {
    return this.isOnVisualizationMode && this.getNominalGeometryAvailable === GeometryAvailability.Available
  }

  get canShowGreenNominalGeometry() {
    return this.isOnVisualizationMode && this.getGreenNominalGeometryAvailable
  }

  get canShowMeshing() {
    return this.isOnVisualizationMode && this.getMeshingAvailable
  }

  get canShowCompensated() {
    return this.isOnVisualizationMode && this.getCompensatedGeometryAvailable
  }

  get canShowGreenCompensated() {
    return this.isOnVisualizationMode && this.getGreenCompensatedGeometryAvailable
  }

  get toggleProduction() {
    return this.isOnLayoutMode ? this.changeProductionGeometryVisibility : this.toggleVisParts
  }

  get toggleSupports() {
    return this.isOnLayoutMode ? this.changeSupportGeometryVisibility : this.toggleVisSupports
  }

  get toggleCoupons() {
    return this.isOnLayoutMode ? this.changeCouponGeometryVisibility : this.toggleVisCoupons
  }

  get toggleBuildPlate() {
    return this.isOnLayoutMode ? this.changeBuildPlateVisibility : this.toggleVisBuildPlate
  }

  get productionToggleState() {
    return this.isOnLayoutMode ? this.displayToolbarState.isShowingProductionGeometry : this.getPartsVisible
  }

  get supportToggleState() {
    return this.isOnLayoutMode ? this.displayToolbarState.isShowingSupportGeometry : this.getSupportsVisible
  }

  get couponToggleState() {
    return this.isOnLayoutMode ? this.displayToolbarState.isShowingCouponGeometry : this.getCouponsVisible
  }

  get buildPlateToggleState() {
    return this.isOnLayoutMode ? this.displayToolbarState.isShowingBuildPlate : this.getBuildPlateVisible
  }

  get buildPlanName() {
    return (this.getBuildPlan || {}).name || ''
  }

  get hasJobError() {
    return (
      (this.getBuildPlanViewMode === this.viewModes.Layout || this.getBuildPlanViewMode === null) &&
      !!this.getSelectedBuildPlanFinalizingJobs.length
    )
  }

  get addLabelPointer() {
    return (this.isLabelCreationMode || this.isLabelManualPlacement) && this.isMouseOverCanvas
  }

  get handleLabelPointer() {
    return (this.isLabelCreationMode || this.isLabelManualPlacement) && this.isMouseOverCanvas && this.hoveredLabel
  }

  get downwardPlaneRotationPointer() {
    return this.isDownwardPlaneRotationInitialized && this.isMouseOverCanvas
  }

  get viewModePointerClass(): { [key: string]: boolean } {
    let className: string

    switch (this.getBuildPlanViewMode) {
      case ViewModeTypes.TransferProps:
        className = 'view-mode-pointer--transfer-props'
        break
      default:
        className = ''
    }

    return {
      ...(className && { [className]: true }),
    }
  }

  get isBinderJetModality() {
    if (this.getBuildPlan && this.getBuildPlan.modality === PrintingTypes.BinderJet) {
      return true
    }

    return false
  }

  get isDMLMModality() {
    return this.getBuildPlan && this.getBuildPlan.modality === PrintingTypes.DMLM
  }

  get isSinterPlan() {
    return this.getBuildPlan && this.getBuildPlan.subType === ItemSubType.SinterPlan
  }

  get isOnLayoutMode() {
    return this.getContentViewMode === ContentViewModeTypes.Layout
  }

  get isOnVisualizationMode() {
    return this.getContentViewMode === ContentViewModeTypes.Visualization
  }

  get showCanvasContainer() {
    if (this.getBuildPlan && this.getBuildPlan.modality === PrintingTypes.BinderJet && this.showSlider) {
      return false
    }

    // As the Add Part tool (Part view mode) is activated the "main" canvas should be hidden
    // The canvas for single part should be displayed instead
    const isModeInclude = [ViewModeTypes.Part, ViewModeTypes.Replace].includes(this.getBuildPlanViewMode)
    if (isModeInclude && !!this.selectedPartsToAdd.length) {
      return false
    }

    return true
  }

  get gridColumns() {
    const dpWidth = variables.buildPlanDetailsPanelWidth
    const lsbWidth = variables.buildPlanLeftSidebarWidth

    const gridTemplateAreasValue = this.isOpenDetails
      ? '"header header header" "sidebar content details" "variants variants variants"'
      : '"header header" "sidebar content" "variants variants"'
    const gridTemplateColumnsValue = this.isOpenDetails ? `${lsbWidth} auto ${dpWidth}` : `${lsbWidth} auto`
    return `grid-template-areas: ${gridTemplateAreasValue}; grid-template-columns: ${gridTemplateColumnsValue};`
  }

  get detailsBtnXPosition() {
    const defaultRightPosition = variables.buildPlanDefDetailBtnPos
    const openedPanelRightPosition = variables.buildPlanOpenedDetailBtnPos
    const position = this.isOpenDetails ? openedPanelRightPosition : defaultRightPosition
    return `right: ${position};`
  }

  get shouldDisplayToolbar(): boolean {
    const isModeInclude = [ViewModeTypes.Part, ViewModeTypes.Replace].includes(this.getBuildPlanViewMode)
    return this.buildPlan && (!isModeInclude || !this.shouldDisplaySinglePartCanvas)
  }

  get shouldDisplaySinglePartCanvas(): boolean {
    const isModeInclude = [ViewModeTypes.Part, ViewModeTypes.Replace].includes(this.getBuildPlanViewMode)
    return isModeInclude && this.selectedPartsToAdd.length === 1
  }

  get partToDisplayInSinglePartCanvas(): { id: string; name: string } {
    if (this.shouldDisplaySinglePartCanvas) {
      const [selectedPart] = this.selectedPartsToAdd
      return {
        id: selectedPart.id,
        name: selectedPart.name,
      }
    }

    return null
  }

  get shouldDisplaySelectedPartsNumber(): boolean {
    const isModeInclude = [ViewModeTypes.Part, ViewModeTypes.Replace].includes(this.getBuildPlanViewMode)
    return isModeInclude && this.selectedPartsToAdd.length > 1
  }

  get isOverflowMenuActive(): boolean {
    return (
      (this.isRecoaterDirectionCheckboxShown && this.displayToolbarState.isShowingRecoaterDirection) ||
      (this.isGasFlowDirectionCheckboxShown && this.displayToolbarState.isShowingGasFlowDirection) ||
      (this.isPrintHeadCheckboxShown && this.displayToolbarState.isShowingPrintHead) ||
      (this.isPrintHeadLanesCheckboxShown && this.displayToolbarState.isShowingPrintHeadLanes) ||
      (this.isBuildPlanVolumeCheckboxShown && this.displayToolbarState.isShowingBuildPlanVolume) ||
      (this.isBuildPlateCheckboxShown && this.buildPlateToggleState)
    )
  }

  get isRecoaterDirectionCheckboxShown(): boolean {
    return !this.isSinterPlan && this.isOnLayoutMode
  }

  get isGasFlowDirectionCheckboxShown(): boolean {
    return this.isDMLMModality && !this.isOnVisualizationMode
  }

  get isPrintHeadCheckboxShown(): boolean {
    return this.isBinderJetModality && !this.isSinterPlan && this.isOnLayoutMode
  }

  get isPrintHeadLanesCheckboxShown(): boolean {
    return this.isOnLayoutMode && !this.isSinterPlan && this.printHeadLanesAvailable
  }

  get isBuildPlanVolumeCheckboxShown(): boolean {
    return this.isOnLayoutMode
  }

  get isBuildPlateCheckboxShown(): boolean {
    return this.isOnLayoutMode || this.getHandlerTogglesAvailable
  }

  get isOverflowMenuShown(): boolean {
    return (
      this.isRecoaterDirectionCheckboxShown ||
      this.isGasFlowDirectionCheckboxShown ||
      this.isPrintHeadCheckboxShown ||
      this.isPrintHeadLanesCheckboxShown ||
      this.isBuildPlanVolumeCheckboxShown ||
      this.isBuildPlateCheckboxShown
    )
  }

  get isShowHideBlockShown(): boolean {
    return this.isOverflowMenuShown || this.isOnLayoutMode
  }

  get isUndoDisabled(): boolean {
    return !this.isCommandManagerReady || !this.canUndo
  }

  get isRedoDisabled(): boolean {
    return !this.isCommandManagerReady || !this.canRedo
  }

  get undoStackLastCommand(): ICommand {
    const stack = this.isToolModeActive ? this.getToolUndoStack : this.getBuildPlanUndoStack
    return stack.slice(-1).pop()
  }

  get redoStackLastCommand(): ICommand {
    const stack = this.isToolModeActive ? this.getToolRedoStack : this.getBuildPlanRedoStack
    return stack.slice(-1).pop()
  }

  get buildPlateCheckboxName(): string {
    let name = this.$t('buildPlate')
    if (this.isSinterPlan) {
      name = this.$t('sinterSurface')
    }
    return name as string
  }

  get displayToolbarState(): IDisplayToolbarState {
    return this.displayToolbarStateByVariantId(this.getBuildPlan.id)
  }

  get isClearanceModeToDisabled(): boolean {
    return (
      !this.isClearanceFromEnabled(this.clearanceTypes.Bodies) &&
      !this.isClearanceFromEnabled(this.clearanceTypes.Parts)
    )
  }

  get isCouponGeometryToggleVisible(): boolean {
    return (this.isOnLayoutMode || this.getHandlerTogglesAvailable) && !this.isSinterPlan
  }

  get isSliderDisabled(): boolean {
    return this.sliderDisabled || this.getIsLoading
  }

  @Watch('isUndoDisabled')
  onUndoStackChange(isDisabled: boolean) {
    if (isDisabled) {
      return
    }

    this.undoTooltipText = this.getUndoRedoTooltipText(this.undoStackLastCommand, 'undo')
  }

  @Watch('isRedoDisabled')
  onRedoStackChange(isDisabled: boolean) {
    if (isDisabled) {
      return
    }
    this.redoTooltipText = this.getUndoRedoTooltipText(this.redoStackLastCommand, 'redo')
  }

  getUndoRedoTooltipText(command: ICommand, undoOrRedo: 'undo' | 'redo'): string {
    let text = this.$t(undoOrRedo).toString()

    if (command) {
      const toolName = this.$t(command.toolName).toString() || ''
      text = `${text} ${toolName}`.trim()
    }

    return text
  }

  getShowHideButtonTooltip(key: string, show: boolean) {
    if (key === 'nominal') {
      if (this.isBinderJetModality) {
        return show
          ? this.$t('showUnscaledNominalGeometryTooltip').toString()
          : this.$t('hideUnscaledNominalGeometryTooltip').toString()
      }
      return show ? this.$t('showNominalGeometryTooltip').toString() : this.$t('hideNominalGeometryTooltip').toString()
    }
    if (key === 'compensated') {
      if (this.isBinderJetModality) {
        return show
          ? this.$t('showUnscaledCompensatedGeometryTooltip').toString()
          : this.$t('hideUnscaledCompensatedGeometryTooltip').toString()
      }
      return show
        ? this.$t('showCompensatedGeometryTooltip').toString()
        : this.$t('hideCompensatedGeometryTooltip').toString()
    }
  }

  getParsedDimensionBoxText(text: string) {
    const splitText = text.split(' ')
    const units = splitText.pop()
    const value = splitText.join(' ')

    return { value, units }
  }

  isNoClearanceBox(dimensionBox: DimensionBox): boolean {
    return dimensionBox.text === this.$i18n.t('clearanceTool.noClearance').toString()
  }

  @Watch('buildPlanItems')
  onBuildPlanItemsChanged(buildPlanItems: IBuildPlanItem[]) {
    if (!this.isClearanceToolEnabled) {
      return
    }

    if (buildPlanItems.length === 0) {
      this.toggleClearanceTool(false)
    }
  }

  @Watch('selectedParts')
  onSelectionChanged(partsInSelection: ISelectable[], partsInPrevSelection: ISelectable[]) {
    if (!this.isClearanceToolEnabled || this.isMeshToMeshClearance || partsInPrevSelection.length !== 1) {
      return
    }

    const from = this.enabledClearanceFrom as ClearanceTypes
    const to = this.enabledClearanceTo as ClearanceTypes
    const selected = partsInPrevSelection[0]
    if (selected.type === SelectionUnit.Part) {
      const buildPlanItemId = selected.id
      this.measureDistanceToEnvironment({ from, to, buildPlanItemId })
    } else if (selected.type === SelectionUnit.Body) {
      const [buildPlanItemId, componentId, geometryId] = selected.id.split(PART_BODY_ID_DELIMITER)
      this.measureDistanceToEnvironment({ from, to, buildPlanItemId, componentId, geometryId })
    }
  }

  @Watch('getSelectedBuildPlanJobs')
  async onBuildPlanItemsOrJobsChange() {
    let showButton = false
    if (this.buildPlan) {
      const subType = this.buildPlan.subType ? this.buildPlan.subType : ItemSubType.None
      const sidebarItems = BuildPlanSidebarTools.getBuildPlanTools(subType, this.buildPlan.modality)
      const slicePublishIndex = sidebarItems.findIndex((sidebarItem) => sidebarItem.key === 'slicePublish')
      if (slicePublishIndex > 0) {
        const completedSlicePublishJobs = this.getSelectedBuildPlanJobs.filter((job) => {
          return (
            sidebarItems[slicePublishIndex].jobTypes.includes(job.jobType) &&
            [JobStatusCode.COMPLETE].includes(job.code)
          )
        })

        if (completedSlicePublishJobs.length > 0) {
          showButton = true
          for (const jobType of sidebarItems[slicePublishIndex].jobTypes) {
            const job = completedSlicePublishJobs.find((itemJob) => itemJob.jobType === jobType)
            if (!job) {
              showButton = false
              break
            }
          }
        }
      }
    }

    // Fix in case slicing results were opened and then user switches to variant without slicing results available
    if (this.showSliceToolbarButton && !showButton && this.showSlider) {
      this.closeSlicing()
    }

    this.showSliceToolbarButton = showButton
  }

  @Watch('getBuildPlan')
  async selectedBuildPlanChanged(buildPlan: IBuildPlan): Promise<void> {
    // check if a variant was switched and isInitialized
    if (!buildPlan || !this.isInitialized || this.$route.params.id === buildPlan.id) return

    this.setIsLoadingBuildPlan(true)

    await this.setLastAction({ itemId: buildPlan.id, action: ItemAction.Opened })
    await this.getBuildPlanPrintStrategy(new VersionablePk(buildPlan.printStrategyId, buildPlan.printStrategyVersion))

    // @ts-ignore
    this.$router.safePush({ params: { id: buildPlan.id } })
    this.gpuInfo = getGPUInfo()

    await this.disposeBuildPlan()

    // Prevent loading of bp if edit view was closed before disposal was finished
    if (this.isBuildPlanReleased) {
      this.setIsLoadingBuildPlan(false)
      return
    }

    if (this.isSinterPlan) {
      const displayToolbarState = this.displayToolbarStateByVariantId(this.buildPlan.id)
      displayToolbarState.isShowingRecoaterDirection = false
      displayToolbarState.isShowingPrintHead = false
      displayToolbarState.isShowingBuildPlanVolume = false
      this.updateDisplayToolbarState({ buildPlanId: this.buildPlan.id, state: displayToolbarState })
    }

    this.init({
      canvasId: 'visualization_canvas',
      sceneMode: SceneMode.BuildPlan,
      loadDefaultPlate: !buildPlan.buildPlateId,
    })
    await this.fetchInsightsByBuildPlanId({ buildPlanId: buildPlan.id, changeState: true })
    this.isBuildPlanCompletelyLoaded = false
    await this.getLabelSetsByBuildPlanId({ buildPlanId: buildPlan.id })
    this.setLabelSetsToUpdate(buildPlan)
    this.resetBuildCostConfiguration()
    this.loadBuildPlan({ buildPlan, options: { labelSets: this.labelSets } })
  }

  setLabelSetsToUpdate(buildPlan: IBuildPlan) {
    if (buildPlan.labelSetIDsToUpdate && buildPlan.labelSetIDsToUpdate.length) {
      this.setRequiresLabelSetUpdates(true)
      this.setLabelSetsIDsForUpdate({ ids: buildPlan.labelSetIDsToUpdate })
    } else {
      this.setRequiresLabelSetUpdates(false)
      this.setLabelSetsIDsForUpdate({ ids: [] })
    }
  }

  changeBuildPlanVolumeVisibilityHelper(isVisible: boolean) {
    if (this.isSinterPlan) {
      this.changeBuildPlanVolumeVisibility(isVisible)
      this.changeRecoaterDirectionLabelVisibility(isVisible)
      this.changePrintHeadVisibility(isVisible)
    } else {
      this.changeBuildPlanVolumeVisibility(isVisible)
    }
  }

  @Watch('isSceneReadOnly')
  onSceneReadOnlyChange() {
    this.changeSceneReadOnly()
  }

  @Watch('getSelectedMachineConfigPk')
  selectedMachineConfigChanged(machineConfigPk: VersionablePk) {
    // set the availability of print head lanes button
    const machineConfig = machineConfigPk && this.getMachineConfigByPk(machineConfigPk)
    this.printHeadLanesAvailable = machineConfig && machineConfig.numberOfPrintHeadLanes > 0
  }

  setMouseOverCanvas(isOver: boolean) {
    this.setIsMouseOverCanvas(isOver)
  }

  showMeshingElements(show: boolean) {
    if (!this.getMeshingLoaded) {
      this.setIsLoading(true)
    }

    this.showMeshing(show)
  }

  showNominal(show: boolean) {
    if (!this.getNominalGeometryLoaded) {
      this.setIsLoading(true)
    }

    this.showNominalGeometry(show)
  }

  showCompensatedMesh(show: boolean) {
    if (!this.getCompensatedGeometryLoaded) {
      this.setIsLoading(true)
    }

    this.showCompensated(show)
  }

  showGreenCompensatedMesh(show: boolean) {
    if (!this.getGreenCompensatedGeometryLoaded) {
      this.setIsLoading(true)
    }

    this.showGreenCompensated(show)
  }

  showGreenNominalGeometry(show: boolean) {
    if (!this.getGreenNominalGeometryLoaded) {
      this.setIsLoading(true)
    }

    this.showGreenNominal(show)
  }

  async beforeMount() {
    try {
      const initialPath = this.$route.path
      this.isCheckingAccess = true

      const buildPlanId = this.$route.params.id
      const buildPlanAsItem = await this.getBuildPlanAsItem(buildPlanId)
      const hasAccess = await this.checkAccess(buildPlanAsItem)

      if (!hasAccess) {
        // @ts-ignore
        this.$router.safePush(RouterPaths.Home)
        return
      }

      const [isLockedForUser, isViewer] = await Promise.all([
        this.checkVariantIsLockedForUser(buildPlanId),
        this.checkForViewAccess(buildPlanAsItem),
        this.checkAccessForCreateVariants(buildPlanId),
      ])

      this.isViewer = isViewer

      this.isCheckingAccess = false

      // always start in the Layout tab
      this.setBuildPlanViewMode(ViewModeTypes.Layout)

      const buildPlan = await this.getBuildPlanById(buildPlanId)

      if (buildPlan.modality === PrintingTypes.BinderJet) {
        if (this.$route.path.includes(RouterNames.EBP_Arrange)) {
          // @ts-ignore
          this.$router.safePush({ name: RouterNames.NotFound })
          return
        }
      }

      if (!buildPlan) {
        // Timeout is required so that the snackbar message is displayed before changing the route
        setTimeout(() => {
          // @ts-ignore
          this.$router.safePush(RouterPaths.DefaultFileExplorer)
        }, 2000)
        return
      }

      if (buildPlan.isRemoved) {
        messageService.showErrorMessage(
          buildPlan.subType === ItemSubType.SinterPlan
            ? `${this.$i18n.t('sinterPlanRemoved')}`
            : `${this.$i18n.t('buildPlanRemoved')}`,
        )

        // @ts-ignore
        await this.$router.safePush(RouterPaths.DefaultFileExplorer)
        return
      }
      const [parts] = await Promise.all([
        this.fetchAllParts(),
        this.fetchAllSinterParts(),
        this.fetchPrintSites(),
        this.fetchAllIbcParts(),
      ])

      // Get mapping of parts and sinter parts with `usedDate` property to apply it for the `Used Date` filter
      // in the Add Part Tool
      const partIds: string[] = [
        ...this.getAllParts.map((p) => p.id),
        ...this.getAllSinterParts.map((p) => p.id),
        ...this.getAllIbcParts.map((p) => p.id),
      ]
      await this.getPartIdsWithUsedDate(partIds)

      // TODO: worked around for the part value getting as part id until we clean up data
      buildPlan.buildPlanItems.forEach((bpItem) => {
        if (typeof bpItem.part === 'string' || bpItem.part instanceof String) {
          bpItem.part = parts.find((part) => part.id === bpItem.part)
        }
      })

      if (this.$route.path === initialPath) {
        this.setBuildPlan(buildPlan)
      }

      this.initParentFolder()

      if (this.isSinterPlan) {
        const displayToolbarState = this.displayToolbarStateByVariantId(this.buildPlan.id)
        displayToolbarState.isShowingRecoaterDirection = false
        displayToolbarState.isShowingPrintHead = false
        displayToolbarState.isShowingBuildPlanVolume = false
        this.updateDisplayToolbarState({ buildPlanId: this.buildPlan.id, state: displayToolbarState })
      }

      await this.fetchRelatedBuildPlanVariants(buildPlanId)
      this.setDisplayToolbarStates()
      this.isFetching = false

      // make current BP variant active
      if (this.buildPlan && !this.buildPlan.isActiveVersion) {
        const relatedVariants = this.getBuildPlanVariants
        const activeVariant = relatedVariants.find((x) => x.isActiveVersion)
        let activeVariantId = null
        if (activeVariant) {
          activeVariantId = activeVariant.id
        } else {
          const defaultVariant = relatedVariants.find((x) => x.version === DEFAULT_ITEM_VERSION)
          activeVariantId = defaultVariant ? defaultVariant.id : null
        }

        if (activeVariantId && buildPlan.id !== activeVariantId) {
          await this.switchBuildPlanActiveVersion({ newVersionId: buildPlan.id, oldVersionId: activeVariantId })
        }

        if (!activeVariantId && this.buildPlan.version !== DEFAULT_ITEM_VERSION) {
          await fileExplorer.setActiveVersion(buildPlan.id)
        }
      }

      const materials = this.fetchMaterials()
      const machineConfigs = this.fetchMachineConfigs()

      await Promise.all([materials, machineConfigs])

      if (this.getBuildPlanDisposePromise) {
        await this.getBuildPlanDisposePromise.promise
      }

      // There is no bp and we shouldn't initialize render scene
      if (!this.buildPlan) {
        return
      }

      this.subscribeToEventBus()
      this.gpuInfo = getGPUInfo()
      this.init({
        canvasId: 'visualization_canvas',
        sceneMode: SceneMode.BuildPlan,
        loadDefaultPlate: !buildPlan.buildPlateId,
      })

      this.isBuildPlanCompletelyLoaded = false
      await this.getLabelSetsByBuildPlanId({ buildPlanId: buildPlan.id })
      this.setLabelSetsToUpdate(buildPlan)
      this.loadBuildPlan({ buildPlan, options: { labelSets: this.labelSets } })
      await this.fetchBuildPlanJobs(buildPlan.id)

      if (!isViewer && !isLockedForUser) {
        await this.setLastAction({ itemId: buildPlan.id, action: ItemAction.Opened })
        // Lock BP variant by current user on edit
        if (!this.isBuildPlanReleased) {
          await this.lockBuildPlanVariant()
        }
      }

      this.changeSceneReadOnly()
      this.onHeaderResize()
    } catch (error) {
      console.error(`Error while receiving data: ${error}`)
      this.isCheckingAccess = false
    }
  }

  mounted() {
    window.addEventListener('beforeunload', this.unlockBuildPlanReference)
  }

  subscribeToEventBus() {
    eventBus.$on(BuildPlanEvents.HideGizmo, this.hideGizmos)
    eventBus.$on(BuildPlanEvents.LoadBuildPlanLoadingProgressStarts, this.onLoadBuildPlanLoadingProgressStarts)
    eventBus.$on(BuildPlanEvents.LoadBuildPlanLoadingProgressUpdates, this.onLoadBuildPlanLoadingProgressUpdates)
    eventBus.$on(BuildPlanEvents.LoadBuildPlanLoadingProgressEnds, this.onLoadBuildPlanLoadingProgressEnds)
  }

  unsubscribeFromEventBus() {
    eventBus.$off(BuildPlanEvents.HideGizmo, this.hideGizmos)
    eventBus.$off(BuildPlanEvents.LoadBuildPlanLoadingProgressStarts, this.onLoadBuildPlanLoadingProgressStarts)
    eventBus.$off(BuildPlanEvents.LoadBuildPlanLoadingProgressUpdates, this.onLoadBuildPlanLoadingProgressUpdates)
    eventBus.$off(BuildPlanEvents.LoadBuildPlanLoadingProgressEnds, this.onLoadBuildPlanLoadingProgressEnds)
  }

  async unlockBuildPlanHandler() {
    if (
      (!this.isViewer && !this.isLockedForUser) ||
      (this.forceUnlockVariant && !isItemLockedForUser(this.getBuildPlan, this.getUserDetails))
    ) {
      await this.unlockBuildPlanVariant()
    }
  }

  async getBuildPlanAsItem(buildPlanId: string): Promise<FileExplorerItem> {
    let item: FileExplorerItem = this.find(buildPlanId)

    if (!item) {
      item = await this.fetchItemById(buildPlanId)

      if (this.isError) {
        const errMsg =
          this.getErrorText === INCORRECT_UUID_MESSAGE_ERROR_2
            ? `${this.$i18n.t('incorrectBuildPlanId')}: ${buildPlanId}`
            : this.getErrorText
        messageService.showErrorMessage(errMsg)
      }
    }

    return item
  }

  async checkAccess(item: FileExplorerItem): Promise<boolean> {
    if (!item) {
      return false
    }

    await this.getItemPermissions(item.id)

    const rootItemId = item.path.split('/').slice(1)[0]
    const rootPermissions = this.permissionsByItemId[rootItemId]
    const rootOwnerPermissions = rootPermissions.filter(
      (p) => p.grantedTo === this.getUserDetails.id && p.role === ItemPermissionsRole.Owner,
    )
    const defaultVersionId = getDefaultVariantIdFromVersionAndPath(item)

    if (
      !this.getUserDetails ||
      (!this.permissionsByItemId[defaultVersionId] &&
        !this.permissionsByItemId[item.id] &&
        !rootOwnerPermissions.length)
    ) {
      return false
    }

    return true
  }

  async checkForViewAccess(item: FileExplorerItem): Promise<boolean> {
    await this.getItemPermissions(item.id)

    const permissions = this.getDirectOrInheritedPermissionsByItemPath(item.path)

    if (!permissions || !this.getUserDetails) {
      return false
    }

    const userPermission = permissions.find((permission) => permission.grantedTo === this.getUserDetails.id)

    return userPermission ? userPermission.role === ItemPermissionsRole.Viewer : false
  }

  onDragEnter(data) {
    if (!data || !data.selectedPartName) {
      return
    }
    const loadingPartIndex = this.getLoadingParts.length - 1
    this.showLoadingPart(loadingPartIndex)
  }

  onDragOver(data, nativeEvent) {
    if (!data || !data.selectedPartName) {
      return
    }

    const loadingPartIndex = this.getLoadingParts.length - 1
    this.updateLoadingPartPosition({
      loadingPartIndex,
      pointerX: nativeEvent.clientX,
      pointerY: nativeEvent.clientY,
    })
  }

  onDragLeave(data) {
    if (!data || !data.selectedPartName) {
      return
    }
    const loadingPartIndex = this.getLoadingParts.length - 1
    this.hideLoadingPart(loadingPartIndex)
  }

  async handlePartDrop(data, nativeEvent) {
    if (!data || !data.selectedPartName) {
      return
    }
    const loadingPartIndex = this.getLoadingParts.length - 1
    this.saveLoadingPart({
      loadingPartIndex,
      pointerX: nativeEvent.clientX,
      pointerY: nativeEvent.clientY,
    })
  }

  toggleDetails() {
    this.isOpenDetails = !this.isOpenDetails
    // This timeout is needed to render the canvas after resizing grid and canvas
    setTimeout(() => {
      this.resizeCanvas()
    }, TIMEOUT_FOR_UPDATE_GRID)
  }

  async toggleCrossSection() {
    const isCrossSectionModeActive = !this.isCrossSectionModeEnabled
    if (isCrossSectionModeActive && this.showSlider) {
      let done
      const promise = new Promise<void>((resolve) => (done = resolve))
      this.sliderPromise = { done, promise }
      this.toggleSlider(!this.showSlider)
      await promise
      this.sliderPromise = null
    }

    if (isCrossSectionModeActive && this.isClearanceToolEnabled) {
      this.enableClearanceTool({
        isEnabled: false,
        restoreSelectionManagerState: true,
      })
    }

    this.enableCrossSectionMode({
      isEnabled: isCrossSectionModeActive,
      crossSectionMatrix: this.buildPlan.crossSectionMatrix,
    })
    if (isCrossSectionModeActive) {
      this.changeViewMode(ViewModeTypes.CrossSection)
    } else {
      this.changeViewMode(ViewModeTypes.Layout)
    }
  }

  /**
   * Toggles clearance tool
   * @param isEnabled
   * if true activates clearance tool
   * Otherwise, deactivates clearance tool
   */
  async toggleClearanceTool(isEnabled: boolean) {
    if (isEnabled && this.showSlider) {
      let done
      const promise = new Promise<void>((resolve) => (done = resolve))
      this.sliderPromise = { done, promise }
      this.toggleSlider(false)
      await promise
      this.sliderPromise = null
    }

    if (isEnabled && this.isCrossSectionModeEnabled) {
      this.toggleCrossSection()
    }

    this.enableClearanceTool({
      isEnabled,
      restoreSelectionManagerState: true,
    })
  }

  @Watch('isToolMaskDisplaying')
  onToolMaskDisplayChanged(isDisplayed: boolean) {
    if (!isDisplayed && this.isClearanceToolEnabled) {
      this.toggleClearanceTool(false)
    }
  }

  toggleClearanceModeFrom(clearanceType: ClearanceTypes) {
    if (this.isClearanceFromEnabled(clearanceType)) {
      return
    }

    this.showRubberBand({ isShown: false })
    this.deselect()

    this.enableClearanceModeFrom({ clearanceType })
    if (this.isClearanceFromEnabled(ClearanceTypes.Bodies)) {
      this.setSelectionMode({ mode: SelectionUnit.Body })
    } else if (this.isClearanceFromEnabled(ClearanceTypes.Parts)) {
      this.setSelectionMode({ mode: SelectionUnit.Part })
    } else if (this.isClearanceToEnabled(ClearanceTypes.Bodies)) {
      this.setSelectionMode({ mode: SelectionUnit.Body })
    } else if (this.isClearanceToEnabled(ClearanceTypes.Parts)) {
      this.setSelectionMode({ mode: SelectionUnit.Part })
    }
  }

  toggleClearanceModeTo(clearanceType: ClearanceTypes) {
    if (this.isClearanceToEnabled(clearanceType)) {
      return
    }

    if (
      (clearanceType !== ClearanceTypes.Bodies && clearanceType !== ClearanceTypes.Parts) ||
      !this.isRubberBandShown
    ) {
      this.showRubberBand({ isShown: false })
      this.deselect()
    }

    this.enableClearanceModeTo({ clearanceType })
    if (this.isClearanceFromEnabled(ClearanceTypes.Bodies)) {
      this.setSelectionMode({ mode: SelectionUnit.Body })
    } else if (this.isClearanceFromEnabled(ClearanceTypes.Parts)) {
      this.setSelectionMode({ mode: SelectionUnit.Part })
    } else if (this.isClearanceToEnabled(ClearanceTypes.Bodies)) {
      this.setSelectionMode({ mode: SelectionUnit.Body })
    } else if (this.isClearanceToEnabled(ClearanceTypes.Parts)) {
      this.setSelectionMode({ mode: SelectionUnit.Part })
    }
  }

  @Watch('isMeshToMeshClearance')
  onMeshToMeshClearanceEnabledChanged(isEnabled: boolean) {
    if (this.isClearanceToolEnabled) {
      this.changeClearanceSelectionObserver({ isEnabled })
    }
  }

  toggleSlider(toggle: boolean) {
    this.showSlider = toggle
    if (this.showSlider && this.isCrossSectionModeEnabled) {
      this.toggleCrossSection()
    }

    if (this.showSlider && this.isClearanceToolEnabled) {
      this.enableClearanceTool({
        isEnabled: false,
        restoreSelectionManagerState: true,
      })
    }

    if (this.isBinderJetModality) {
      if (this.showSlider) {
        this.oldIsViewLocked = this.isViewLocked
      }

      this.setViewLocked(this.oldIsViewLocked || this.showSlider)
    }

    if (this.showSlider) {
      this.changeViewMode(ViewModeTypes.Slicing)
    } else {
      this.changeViewMode(ViewModeTypes.Layout)
      this.showMeshes()
      // set visibility of mesh based on toolbar selection
      this.changeVisibilityByDisplayToolbarState()
    }

    if (this.sliderPromise) {
      this.sliderPromise.done()
    }
  }

  closeSlicing() {
    this.showSlider = true
    this.toggleSlider(!this.showSlider)
  }

  @Watch('getBuildPlanViewMode')
  buildPlanViewModeChanged(viewMode: ViewModeTypes) {
    if (viewMode !== ViewModeTypes.Slicing && viewMode !== null) {
      this.showSlider = false
      if (this.isBinderJetModality) {
        this.setViewLocked(this.oldIsViewLocked || this.showSlider)
      }

      this.disableSliceToolbarButton(true)
      this.crossSectionDisabled = true
      const isCrossSectionModeActive = false
      this.enableCrossSectionMode(isCrossSectionModeActive)
    }

    if (viewMode !== ViewModeTypes.ClearanceTool && viewMode !== null) {
      this.showSlider = false
      if (this.isBinderJetModality) {
        this.setViewLocked(this.oldIsViewLocked || this.showSlider)
      }

      this.disableSliceToolbarButton(true)
      this.clearanceToolDisabled = ![ViewModeTypes.Duplicate, ViewModeTypes.Rotate, ViewModeTypes.Move].includes(
        viewMode,
      )

      if (this.isClearanceToolEnabled) {
        this.enableClearanceTool({
          isEnabled: false,
          restoreSelectionManagerState: this.clearanceToolDisabled,
        })
      }
    }

    if (viewMode === null) {
      this.disableSliceToolbarButton(false)
      this.crossSectionDisabled = false
      this.clearanceToolDisabled = false
    }

    this.changeVisibilityByDisplayToolbarState()
  }

  onRequestClosePlan(options: { forceUnlockVariant: boolean }) {
    this.forceUnlockVariant = options.forceUnlockVariant
    this.onCloseClick()
  }

  async onCloseClick() {
    // Generate promise to await loading outdated files
    this.generatePreviewCreationPromise()
    // Dispose build plan and tool undo/redo stack during unload.
    this.disposeBuildPlanStack()
    this.disposeToolStack()

    const parentItem = this.getRootItem ? this.getRootItem : this.parentFolder
    const hasAccessToParent = parentItem ? await fileExplorer.itemExistsForUser(parentItem.id) : false

    if (this.getRootItem && hasAccessToParent) {
      const route = {
        name: RouterNames.FE_AllFiles,
        params: { itemId: this.getRootItem.id },
      }
      // @ts-ignore
      await this.$router.safePush(route)
    } else {
      // If a user just came to a website and 'this.getRootItem' is not available, we should check parent of that item.
      // If a parent exists, we should navigate to it (only for Folders View mode)
      if (this.parentFolder && hasAccessToParent) {
        const itemId = this.getViewMode === ViewMode.Folders ? this.parentFolder.id : ROOT_FOLDER_ID
        const route = {
          name: RouterNames.FE_AllFiles,
          params: { itemId },
        }
        // @ts-ignore
        await this.$router.safePush(route)
        return
      }

      // @ts-ignore
      await this.$router.safePush(RouterPaths.DefaultFileExplorer)
    }
  }

  updatePreview() {
    if (this.buildPlan) {
      this.updateItemPreview({ itemId: this.buildPlan.id })
    } else {
      this.getPreviewCreationPromise.done()
    }
  }

  @Watch('getIsLoading')
  async addPreviewUpdateEvent(isLoading: boolean) {
    if (isLoading) {
      window.removeEventListener('beforeunload', this.updatePreview)
    } else {
      this.isBuildPlanCompletelyLoaded = true
      window.addEventListener('beforeunload', this.updatePreview)
    }
  }

  @Watch('getCommandType')
  onBuildPlanToolChanged(newValue: CommandType, oldValue: CommandType) {
    if (newValue === CommandType.BuildPlanCommand) {
      this.disposeToolStack()
      this.setToolModeActive(false)
    }

    if (newValue === CommandType.ToolCommand) {
      this.setToolModeActive(true)
    }
  }

  async initParentFolder() {
    if (this.getBuildPlan) {
      const folder: FileExplorerItem = await this.getParentFolder(this.getBuildPlan.id)
      this.setParentFolder(folder)
    }
  }

  async beforeDestroy() {
    this.enableClearanceTool({
      isEnabled: false,
      restoreSelectionManagerState: true,
    })
    // Unlock BP variant by current user to enable editing to other users only if user has rights to unlock
    this.isBuildPlanReleased = true
    this.resetLabelSetsIDsForUpdate([])
    if (!this.isReadOnly || (this.forceUnlockVariant && !isItemLockedForUser(this.getBuildPlan, this.getUserDetails))) {
      await this.unlockBuildPlanVariant()
    }

    if (!this.getVisualizationLoading && this.isBuildPlanCompletelyLoaded && this.isInitialized) {
      this.updatePreview()
    } else if (this.getPreviewCreationPromise) {
      this.getPreviewCreationPromise.done()
    }

    window.removeEventListener('beforeunload', this.updatePreview)
    window.removeEventListener('beforeunload', this.unlockBuildPlanReference)
    this.deselectBuildPlan()
    this.unsubscribeFromEventBus()

    if (!this.isBuildPlanDisposing) {
      await this.disposeBuildPlan()
    }

    this.setParentFolder(undefined)
  }

  @Watch('buildPlanName')
  onHeaderResize() {
    this.$nextTick(() => {
      this.buildPlanNameTruncated = this.middleTruncateTextForElement(this.buildPlanName, this.$refs.buildPlanNameField)
    })
  }

  @Watch('isReadOnly')
  onReadOnlyBuildPlan() {
    if (this.isReadOnly) {
      this.disposeBuildPlanStack()
      this.disposeToolStack()
      this.disable()
    }
  }

  disableSliceToolbarButton(toDisable: boolean) {
    this.sliderDisabled = toDisable
  }

  changeHiddenPartsVisibility(show: boolean) {
    this.setShowHiddenPartsAsTransparent(show)
    if (show) {
      this.showHiddenPartsTransparent()
    } else {
      this.hideTransparentParts()
    }
  }

  private toggleVisParts(toggle: boolean) {
    this.setHandlerVisibility({ type: CategoryKind.PARTS, visible: toggle })
  }

  private toggleVisSupports(toggle: boolean) {
    this.setHandlerVisibility({ type: CategoryKind.SUPPORTS, visible: toggle })
  }

  private toggleVisCoupons(toggle: boolean) {
    this.setHandlerVisibility({ type: CategoryKind.COUPONS, visible: toggle })
  }

  private toggleVisBuildPlate(toggle: boolean) {
    this.setHandlerVisibility({ type: CategoryKind.BUILDPLATE, visible: toggle })
  }

  private middleTruncateTextForElement(text: string, element: Element) {
    if (!element) {
      return text
    }

    const maxWidth = element.parentElement.offsetWidth
    const canvas = document.createElement('canvas')
    const elementStyle = window.getComputedStyle(element)
    const ctx = canvas.getContext('2d')
    ctx.font = elementStyle.font
      ? elementStyle.font
      : `${elementStyle.fontWeight} ${elementStyle.fontSize} / ${elementStyle.lineHeight} ${elementStyle.fontFamily}`

    if (ctx.measureText(text).width <= maxWidth) {
      return text
    }

    const delimiter = '...'
    const textLength = text.length
    const middle = Math.floor(textLength / 2)
    const tail = textLength % 2 === 0 ? null : text[textLength - 1]

    for (let i = middle; i > 0; i -= 1) {
      const truncatedText = tail
        ? `${text.substr(0, i)}${delimiter}${text.substr(textLength - i, i - 1)}${tail}`
        : `${text.substr(0, i)}${delimiter}${text.substr(textLength - i, i)}`

      if (ctx.measureText(truncatedText).width <= maxWidth) {
        return truncatedText
      }
    }

    return delimiter
  }

  private async disposeBuildPlan() {
    try {
      this.setIsBuildPlanDisposing(true)

      // Need to use promise to wait until dispose fully finished,
      // because visualization dispose mutation is asynchronous
      const disposePromise = this.createDisposePromise()
      this.setBuildPlanDisposePromise(disposePromise)
      this.dispose(disposePromise.done)

      await disposePromise.promise
    } finally {
      this.setIsBuildPlanDisposing(false)
    }
  }

  private createDisposePromise() {
    let done: Function
    const promise = new Promise<void>((resolve) => {
      done = resolve
    })

    return { done, promise }
  }
}
