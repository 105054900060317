
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import FallbackImage from '@/components/layout/FallbackImage.vue'
import { DEFAULT_PREVIEW_PATH } from '@/constants'

@Component({
  components: { FallbackImage },
})
export default class VariantPreview extends Vue {
  @Prop({ required: true }) item: IBuildPlan

  isLoaded = false

  get previewImageUrl(): string {
    const time: number = new Date().getTime()
    const previewImageUrl = this.item.previewImageUrl ? this.item.previewImageUrl : this.getDefaultPreviewImageUrl()

    return `${previewImageUrl}?${time}`
  }

  getDefaultPreviewImageUrl() {
    if (this.item.itemType === ItemType.BuildPlan) {
      return DEFAULT_PREVIEW_PATH.buildPlan
    }

    if (this.item.subType === ItemSubType.SinterPlan) {
      return DEFAULT_PREVIEW_PATH.sinterPlan
    }

    return null
  }

  onPreviewLoad() {
    this.isLoaded = true
  }
}
