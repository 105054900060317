
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import VTextField from 'vuetify/lib/components/VTextField'

@Component
export default class LabelToolTextField extends Vue {
  @Prop() suffix!: string
  @Prop({ default: false }) disabled: boolean
  @Prop() placeholder: string
  @Prop() label: string
  @Prop({ type: [Number, String, null] }) value: number | string | null
  @Prop({ default: 'text' }) type: string
  @Prop() min!: number
  @Prop() max!: number
  @Prop() step!: number
  @Prop({ type: [Object, String], default: '' }) readonly rules: object | string
  @Prop({ type: [Object, String], default: '' }) readonly customMessages: object | string
  @Prop() height: string
  @Prop() hint!: boolean

  $refs!: {
    textField: VTextField
  }

  numberOfCharacters: string = null

  model: number | string | null =
    // @ts-ignore
    typeof this.value === 'number' ? Number(this.value) : !this.value ? '' : String(this.value)
  input(e) {
    this.$emit('input', e)
  }

  focus() {
    this.$refs.textField.focus()
  }

  get isFocused() {
    return this.$refs.textField.isFocused
  }

  selectAll() {
    this.$refs.textField.$el.querySelector('input').select()
  }

  onInput() {
    return { on: ['input'] }
  }

  @Watch('value')
  onValueChanged(val) {
    this.model = this.type === 'text' ? String(val) : Number(val)

    if (this.hint) {
      // @ts-ignore
      const tip = this.model.length === 1 ? this.$t('character') : this.$t('characters')
      // @ts-ignore
      this.numberOfCharacters = `${this.model.length} ${tip}`
    }
  }
}
