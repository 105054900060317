import {
  MachineMaterial,
  SiteResponse,
  UpdateSiteDto,
  MachineTypeResponse,
  UpdateMachineTypeDto,
  PrintStrategy,
  PrintStrategyInfo,
  AdditionalPrintStrategyInfo,
  PrintStrategyShortInfo,
  ParameterSet,
  SiteMachineConfigMaterialBinderDto,
  MachineConfigMaterialBinder,
} from '@/types/Sites/Site'
import { del, get, post, put } from '@/api/http'
import { EndpointsUrls } from '@/configs/config'
import { handleAPIError } from '@/api/common'
import { UpdateAdminMaterialDTO } from '@/types/Sites/SiteAdminDTOs'
import { TenantAdminBuildPlatform, UpdateBuildPlatformDTO } from '@/types/Sites/TenantAdminBuildPlatform'
import { PrintStrategyStatus } from '@/types/Sites/PrintStrategyStatus'
import { InertGas, IParameterSet, IProductionSet } from '@/types/BuildPlans/IBuildPlan'
import { UploadDmlmProductionSetPayload, UploadProductionSetPayload } from '@/store/modules/sites/types'
import { PrintingTypes } from '@/types/IMachineConfig'
import { ExportBinderJetProcessParameterDto } from '@/types/Sites/ExportBinderJetProcessParameterDto'
import { ExportBinderJetPartParameterDto } from '@/types/Sites/ExportBinderJetPartParameterDto'

export default {
  async fetchAllSites() {
    return await get<SiteResponse[]>(EndpointsUrls.SitesAdmin)
  },

  async fetchSiteById(siteId: string) {
    return await get<SiteResponse>(`${EndpointsUrls.SitesAdmin}/${siteId}`)
  },

  async updateSite(siteId: number, updateSiteDto: UpdateSiteDto) {
    let updatedSite

    try {
      updatedSite = await put<SiteResponse>(`${EndpointsUrls.SitesAdmin}/${siteId}`, updateSiteDto)
    } catch (error) {
      handleAPIError(error)
    }

    return updatedSite
  },

  async updateMacineType(machineTypeId: number, updateMachineTypeDto: UpdateMachineTypeDto) {
    let updatedMachineType

    try {
      updatedMachineType = await put<MachineTypeResponse>(
        `${EndpointsUrls.MachineTypesAdmin}/${machineTypeId}`,
        updateMachineTypeDto,
      )
    } catch (error) {
      handleAPIError(error)
    }

    return updatedMachineType
  },

  async fetchMachineTypesBySiteId(siteId: number | string) {
    return await get<MachineTypeResponse[]>(`${EndpointsUrls.MachineTypesBySiteIdAdmin}/${siteId}`)
  },

  async fetchMachineTypeById(machineTypeId: string) {
    return await get<MachineTypeResponse>(`${EndpointsUrls.MachineTypesAdmin}/${machineTypeId}`)
  },

  async fetchMaterialById(materialId: string) {
    return get<MachineMaterial>(`${EndpointsUrls.MaterialsAdmin}/${materialId}`)
  },

  async fetchMaterialsByMachineConfigId(machineTypeId: string) {
    try {
      return await get<MachineMaterial[]>(`${EndpointsUrls.MaterialsAdmin}/byMachineConfig/${machineTypeId}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async updateMaterial(materialId: string, dto: UpdateAdminMaterialDTO) {
    try {
      return await put<MachineMaterial>(`${EndpointsUrls.MaterialsAdmin}/${materialId}`, dto)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchPrintStrategiesBySiteMachineMaterial(
    siteId: number,
    machineConfigId: number,
    materialId: number,
    params?: object,
  ): Promise<PrintStrategy[]> {
    try {
      return await get<PrintStrategy[]>(
        `${EndpointsUrls.PrintStrategiesAdmin}/${siteId}/${machineConfigId}/${materialId}`,
        { params },
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchBuildPlatesByMaterialAndMachine(machineConfigId: string, materialId: string) {
    try {
      return await get<TenantAdminBuildPlatform[]>(`${EndpointsUrls.BuildPlates}/${machineConfigId}/${materialId}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async updateBuildPlate(id: number, dto: UpdateBuildPlatformDTO) {
    try {
      return await put<TenantAdminBuildPlatform>(`${EndpointsUrls.BuildPlates}/${id}`, dto)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchPrintStrategyById(printStrategyId: number, siteId: number) {
    return get<PrintStrategyInfo>(`${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}`, {
      params: { siteId },
    })
  },

  async fetchPrintStrategyAdditionalInfo(printStrategyId: number) {
    try {
      return await get<AdditionalPrintStrategyInfo>(
        `${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}/additional`,
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async updatePrintStrategyStatus(printStrategyId: number, siteId: number, printStrategyStatus: PrintStrategyStatus) {
    try {
      return await put<PrintStrategyInfo>(
        `${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}/status`,
        { printStrategyStatus },
        { params: { siteId } },
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async updatePrintStrategyDefaults(printStrategyId: number, siteId: number = null, defaults) {
    try {
      return await put<PrintStrategyInfo>(
        `${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}/defaults`,
        defaults,
        { params: { siteId } },
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async addPrintStrategyParameterSet(
    printStrategyId: number,
    siteId: number,
    parameterSetId: number,
    layerThickness?: number,
  ) {
    try {
      return await post<PrintStrategyInfo>(
        `${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}/parameterSet/${parameterSetId}`,
        {},
        { params: { siteId, layerThickness } },
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async deletePrintStrategyParameterSet(printStrategyId: number, siteId: number, parameterSetId: number) {
    try {
      return await del<PrintStrategyInfo>(
        `${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}/parameterSet/${parameterSetId}`,
        { params: { siteId } },
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async updatePrintStrategyParameterSetLayerThickness(
    printStrategyId: number,
    siteId: number,
    printStrategyParameterSetId: number,
    layerThickness: number,
  ) {
    try {
      return await put<PrintStrategyInfo>(
        `${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}/parameterSet/${printStrategyParameterSetId}`,
        { layerThickness },
        { params: { siteId } },
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async uploadProductionSet(payload: UploadProductionSetPayload) {
    try {
      const formData = new FormData()
      const { file, machineConfigId, materialId, modality, printStrategyId, siteId, version = '1' } = payload

      formData.append('productionSetFile', file)
      formData.append('version', version)
      formData.append('modality', modality)
      formData.append('machineConfigId', String(machineConfigId))
      formData.append('materialId', String(materialId))

      if (payload.modality === PrintingTypes.DMLM) {
        const { inertGasType, recoaterType, recoaterMaterial } = payload as UploadDmlmProductionSetPayload
        formData.append('inertGasType', String(inertGasType))
        formData.append('recoaterType', String(recoaterType))
        formData.append('recoaterMaterial', String(recoaterMaterial))
      }

      return await post<PrintStrategyInfo>(
        `${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}/uploadProcessParameter`,
        formData,
        { params: { siteId } },
      )
    } catch (error) {
      throw error
    }
  },

  async uploadParameterSet(printStrategyId: number, file: File, siteId: number) {
    try {
      const formData = new FormData()
      formData.append('parameterSetFile', file)

      return await post<PrintStrategyInfo>(
        `${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}/uploadPartParameter`,
        formData,
        { params: { siteId } },
      )
    } catch (error) {
      // handling to be done at caller level since different modal needs to be shown
      throw error
    }
  },

  async updatePrintStrategyProcessParameterFile(
    printStrategyId: number,
    siteId: number,
    processParameterFileId: number,
  ) {
    try {
      return await put<PrintStrategyInfo>(
        `${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}/productionSet/${processParameterFileId}`,
        {},
        { params: { siteId } },
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async exportProcessParameter(id: number, dto: ExportBinderJetProcessParameterDto) {
    return post(`${EndpointsUrls.ProductionSets}/${id}/export`, dto)
  },

  async exportPartParameter(id: number, dto: ExportBinderJetPartParameterDto) {
    return post(`${EndpointsUrls.ParameterSets}/${id}/export`, dto)
  },

  async createPrintStrategy(machineConfigId: number, materialId: number): Promise<PrintStrategyInfo> {
    try {
      return await post<PrintStrategyInfo>(EndpointsUrls.PrintStrategiesAdmin, {
        machineConfigId,
        materialId,
      })
    } catch (error) {
      handleAPIError(error)
    }
  },

  async updatePrintStrategyName(printStrategyId: number, siteId: number, name: string): Promise<PrintStrategyInfo> {
    try {
      const strategy = await put<PrintStrategyInfo>(
        `${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}/updateName`,
        { name },
        { params: { siteId } },
      )
      return strategy
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchActivePrintStrategies(buildPlanId: string = null): Promise<PrintStrategyInfo[]> {
    try {
      const strategies = await get<PrintStrategyInfo[]>(`${EndpointsUrls.PrintStrategiesAdmin}/active`, {
        params: { buildPlanId },
      })
      return strategies
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchPrintStrategies(versions?: boolean): Promise<PrintStrategyShortInfo> {
    try {
      const strategies = await get<PrintStrategyShortInfo>(
        `${EndpointsUrls.PrintStrategiesAdmin}/shortInfo?versions=${!!versions}`,
      )
      return strategies
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchInertGases(): Promise<InertGas[]> {
    try {
      const inertGases = await get<{ count: number; items: InertGas[] }>(`${EndpointsUrls.InertGases}`)
      return inertGases.items
    } catch (error) {
      handleAPIError(error)
    }
  },

  async checkPrintStrategyNameExists(printStrategyId: number, name: string): Promise<boolean> {
    try {
      const isExists = get<boolean>(`${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}/nameExist`, {
        params: { name },
      })
      return isExists
    } catch (error) {
      handleAPIError(error)
    }
  },

  async isPrintStrategyUsedByBuildPlan(printStrategyId: number): Promise<boolean> {
    try {
      return get<boolean>(`${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}/isUsedByBuildPlan`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getBuildPlansRelatedToPrintStrategyCount(printStrategyId: number) {
    try {
      return get<number>(
        `${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}/getBuildPlansRelatedToPrintStrategy/count`,
      )
    } catch (error) {
      handleAPIError(error)
      return 0
    }
  },

  async deletePrintStrategy(printStrategyId: number): Promise<number> {
    try {
      return await del<number>(`${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchPartParameterById(id: number): Promise<IParameterSet> {
    try {
      return get<IParameterSet>(`${EndpointsUrls.ParameterSets}/${id}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async checkIfParameterSetIsInUse(id: number): Promise<boolean> {
    try {
      return await get<boolean>(`${EndpointsUrls.ParameterSets}/${id}/isInUse`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async checkIfProductionSetIsInUsedPrintStrategy(id: number): Promise<boolean> {
    try {
      return await get<boolean>(`${EndpointsUrls.ProductionSets}/${id}/isInUsedPrintStrategy`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async isPartParameterNameExists(name: string): Promise<boolean> {
    return get<boolean>(`${EndpointsUrls.ParameterSets}/exists?name=${name}`)
  },

  async isProcessParameterNameExists(name: string): Promise<boolean> {
    return get<boolean>(`${EndpointsUrls.ProductionSets}/exists?name=${name}`)
  },

  async copyPrintStrategy(printStrategyId: number): Promise<number> {
    try {
      return post<number>(`${EndpointsUrls.PrintStrategiesAdmin}/copy`, {
        printStrategyId,
      })
    } catch (error) {
      handleAPIError(error)
    }
  },

  async copyParameterSet(parameterSetId: number, implicit: boolean = false): Promise<ParameterSet> {
    try {
      return post<ParameterSet>(
        `${EndpointsUrls.ParameterSets}/copy`,
        {
          parameterSetId,
        },
        { params: { implicit } },
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async copyProductionSet(productionSetId: number): Promise<IProductionSet> {
    try {
      return post<IProductionSet>(`${EndpointsUrls.ProductionSets}/copy`, {
        productionSetId,
      })
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchBinders(materialId: number, machineConfigId: number, siteId: number) {
    try {
      return get<SiteMachineConfigMaterialBinderDto[]>(
        `${EndpointsUrls.Binder}/${siteId}/${machineConfigId}/${materialId}/binders`,
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async updateBinder(siteMachineConfigMaterialBinderId, updateBinderDto: any) {
    try {
      return put<SiteMachineConfigMaterialBinderDto>(
        `${EndpointsUrls.Binder}/${siteMachineConfigMaterialBinderId}`,
        updateBinderDto,
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async setBinder(printStrategyId, machineConfigMaterialBinderId, siteId?): Promise<PrintStrategyInfo> {
    try {
      return put<PrintStrategyInfo>(
        `${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}/binder/${machineConfigMaterialBinderId}`,
        {},
        { params: { siteId } },
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchPrintStrategyAvailableMachineConfigMaterialBinders(printStrategyId) {
    try {
      return get<MachineConfigMaterialBinder[]>(
        `${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyId}/availableBinders`,
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchProcessParameterSolidLevel(printStrategyPk) {
    try {
      return get<number>(`${EndpointsUrls.PrintStrategiesAdmin}/${printStrategyPk.id}/processParameterSolidLevel`, {
        params: { printStrategyVersion: printStrategyPk.version },
      })
    } catch (error) {
      handleAPIError(error)
    }
  },
}
