/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Scene } from '@babylonjs/core/scene'
import { AbstractMesh, InstancedMesh, Mesh, TransformNode } from '@babylonjs/core/Meshes'
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder'
import { CSG } from '@babylonjs/core/Meshes/csg'
import { Color3, Color4, Epsilon, Vector3 } from '@babylonjs/core/Maths'
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo'
import buildPlatesService from '@/api/buildPlates'
import { BuildPlateDisplaySettings, IBuildPlateSizes } from '@/visualization/types/BuildPlateTypes'
import {
  BUILD_CHAMBER_POLYLINES_NAME,
  BUILD_PLATE_MATERIAL_NAME,
  BUILD_PLATE_SIZES_TO_MM,
  BUILD_VOLUME_LIMIT_ZONE,
  DEFAULT_SINTER_PLATE_BOUNDING_INFO,
  FRONT_INDICATOR_MATERIAL_NAME,
  FRONT_INDICATOR_MESH_NAME,
  GAS_FLOW_ARROW_MATERIAL_NAME,
  GAS_FLOW_MESH_NAME,
  GROUND_BOX_NAME,
  KEEP_OUT_ZONE,
  MESH_RENDERING_GROUP_ID,
  PRINT_HEAD_ARROW_MATERIAL_NAME,
  PRINT_HEAD_DIRECTION_MESH_NAME,
  RECOATER_ARROW_MATERIAL_NAME,
  RECOATER_DIRECTION_MESH_NAME,
  SINTER_PLATE_NAME,
  SINTER_PLATE_MATERIAL_NAME,
  SINTER_PLATE_PADDING,
  BUILD_PLATE_GRID_RATIO,
  INDICATOR_ARROW_MESH_NAME,
  PART_PREVIEW_PLATE_MATERIAL_NAME,
  BuildVolumeLimitZoneType,
  DEFAULT_MACHINE_BUILD_CHAMBER_UNIT,
  MAX_PH_INDEXING,
  BUILD_CHAMBER_LINES_MIN_Z,
  REGULAR_ORANGE,
  PRINT_HEAD_LANES_MESH_NAME,
  BUILD_CHAMBER_BOTTOM_POLYLINES_NAME,
  BUILD_CHAMBER_TOP_POLYLINES_NAME,
  BUILD_CHAMBER_SIDE_POLYLINES_NAME,
  SPATIAL_LETTER_GRID_NAME,
  SPATIAL_LETTER_GRID_COLOR,
  SYMBOL_UPPER_A_CODE,
  LATIN_SYMBOLS_LENGTH,
  CHARACTER,
  X_CHAR,
  Y_CHAR,
  Z_CHAR,
} from '@/constants'
import { IBuildPlate } from '@/types/BuildPlates/IBuildPlate'
import { BVHTreeNode } from '@/visualization/models/BVHTreeNode'
import { OBBTree } from '@/visualization/OBBTree'
import { IRenderable } from '../types/IRenderable'
import messageService from '@/services/messageService'
import { VisualizationEvent } from '../infrastructure/IVisualizationEvent'
import { convert } from '@/utils/converter/lengthConverter'
import { IMachineProperties } from '@/visualization/types/MachineProperties'
import { IMachineConfig, PrintingTypes, Direction } from '@/types/IMachineConfig'
import { MeshManager } from './MeshManager'
import { IPartMetadata } from '../types/SceneItemMetadata'
import { BoundingBox } from '@babylonjs/core/Culling'
import { createGuid } from '@/utils/common'
import { RenderScene } from '@/visualization/render-scene'
import store from '@/store'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { GridLetterDirection, GridLetterJSON } from '@/types/Label/TextElement'
import { DynamicTexture } from '@babylonjs/core/Materials/Textures/dynamicTexture'
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { isNumber } from '@/utils/number'

export class BuildPlateManager {
  public onGetBPNameByBPId = new VisualizationEvent<{
    buildPlanId: string
    callback: Function
  }>()

  private onChangeBuildPlate = new VisualizationEvent<VersionablePk>()
  private renderScene: IRenderable
  private scene: Scene
  private meshManager: MeshManager
  private buildPlatePk: VersionablePk
  private buildPlateSizes: IBuildPlateSizes
  private machineProperties: IMachineProperties
  private ground: AbstractMesh
  private keepOutZones: AbstractMesh[]
  private buildPlateDisplaySettings: BuildPlateDisplaySettings = {
    isShowingBuildPlanVolume: true,
    isShowingGasFlowDirection: true,
    isShowingPrintHead: true,
    isShowingPrintHeadLanes: true,
    isShowingRecoaterDirection: true,
    isShowingBuildPlate: true,
  }

  constructor(renderScene: IRenderable) {
    this.renderScene = renderScene
    this.scene = renderScene.getScene()
    this.meshManager = renderScene.getMeshManager()
  }

  get groundBox() {
    return this.ground
  }

  get changeBuildPlate() {
    return this.onChangeBuildPlate.expose()
  }

  get getBPNameByBPId() {
    return this.onGetBPNameByBPId.expose()
  }

  async loadBuildPlateSizes(buildPlatePk?: VersionablePk, machineConfigPk?: VersionablePk) {
    if (this.buildPlatePk && this.buildPlatePk.equals(buildPlatePk)) {
      return
    }

    const buildPlate = buildPlatePk
      ? await buildPlatesService.getBuildPlateByPk(buildPlatePk, machineConfigPk)
      : await buildPlatesService.getDefaultBuildPlate(machineConfigPk)

    this.buildPlatePk = new VersionablePk(buildPlate.id, buildPlate.version)
    this.buildPlateSizes = this.extractBuildPlateSizes(buildPlate)
    this.machineProperties = this.extractMachineProperties(buildPlate.machineConfig)
  }

  getBuildPlatePk() {
    return this.buildPlatePk
  }

  getBuildPlateSizes() {
    return this.buildPlateSizes
  }

  getMachineProperties() {
    return this.machineProperties
  }

  setBuildPlateDisplaySettings(name: string, visibility: boolean) {
    switch (name) {
      case RECOATER_DIRECTION_MESH_NAME:
        this.buildPlateDisplaySettings.isShowingRecoaterDirection = visibility
        break
      case GAS_FLOW_MESH_NAME:
        this.buildPlateDisplaySettings.isShowingGasFlowDirection = visibility
        break
      case PRINT_HEAD_DIRECTION_MESH_NAME:
        this.buildPlateDisplaySettings.isShowingPrintHead = visibility
        break
      case PRINT_HEAD_LANES_MESH_NAME:
        this.buildPlateDisplaySettings.isShowingPrintHeadLanes = visibility
        break
      case BUILD_CHAMBER_POLYLINES_NAME:
        this.buildPlateDisplaySettings.isShowingBuildPlanVolume = visibility
        break
      case GROUND_BOX_NAME:
      case SINTER_PLATE_NAME:
        this.buildPlateDisplaySettings.isShowingBuildPlate = visibility
        break
      default:
        break
    }
  }

  getBuidPlateDisplaySettingsName(meshName: string) {
    switch (meshName) {
      case RECOATER_DIRECTION_MESH_NAME:
        return 'isShowingRecoaterDirection'
      case GAS_FLOW_MESH_NAME:
        return 'isShowingGasFlowDirection'
      case PRINT_HEAD_DIRECTION_MESH_NAME:
        return 'isShowingPrintHead'
      case PRINT_HEAD_LANES_MESH_NAME:
        return 'isShowingPrintHeadLanes'
      default:
        return ''
    }
  }

  extractBuildPlateSizes(buildPlateConfig: IBuildPlate) {
    const buildPlate = {
      xMin: Number.MAX_VALUE,
      xMax: Number.MIN_VALUE,
      yMin: Number.MAX_VALUE,
      yMax: Number.MIN_VALUE,
      roundedCornerRad: Number.MIN_VALUE,
      depth: null,
      boltHoleDia: Number.MIN_VALUE,
      buildVolumeX: Number.MIN_VALUE,
      buildVolumeY: Number.MIN_VALUE,
      buildVolumeHeight: Number.MIN_VALUE,
      xBolt: Number.MIN_VALUE,
      yBolt: Number.MIN_VALUE,
    }
    buildPlate.depth = convert(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, 'mm', buildPlateConfig.buildPlateDimensionZ)
    buildPlate.buildVolumeX = convert(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, 'mm', buildPlateConfig.buildableVolumeX)
    buildPlate.buildVolumeY = convert(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, 'mm', buildPlateConfig.buildableVolumeY)
    buildPlate.buildVolumeHeight = convert(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, 'mm', buildPlateConfig.buildableVolumeZ)

    const segments = buildPlateConfig.segments

    if (segments && segments.corners) {
      segments.corners.forEach((corner) => {
        if (corner.x > buildPlate.xMax) {
          buildPlate.xMax = corner.x
        }

        if (corner.x < buildPlate.xMin) {
          buildPlate.xMin = corner.x
        }

        if (corner.y > buildPlate.yMax) {
          buildPlate.yMax = corner.y
        }

        if (corner.y < buildPlate.yMin) {
          buildPlate.yMin = corner.y
        }

        if (corner.roundedRadius > buildPlate.roundedCornerRad) {
          buildPlate.roundedCornerRad = corner.roundedRadius
        }
      })
    }

    if (segments && segments.screwHoles) {
      segments.screwHoles.forEach((screwHole) => {
        if (screwHole.cx > buildPlate.xBolt) {
          buildPlate.xBolt = screwHole.cx
        }

        if (screwHole.cy > buildPlate.yBolt) {
          buildPlate.yBolt = screwHole.cy
        }

        if (screwHole.radius > buildPlate.boltHoleDia) {
          buildPlate.boltHoleDia = 2 * screwHole.radius
        }
      })
    }

    if (buildPlate.boltHoleDia === Number.MIN_VALUE) {
      buildPlate.xBolt = 0
      buildPlate.yBolt = 0
      buildPlate.boltHoleDia = 0
    }

    // Convert to mm
    buildPlate.roundedCornerRad *= BUILD_PLATE_SIZES_TO_MM
    buildPlate.xMax *= BUILD_PLATE_SIZES_TO_MM
    buildPlate.xMin *= BUILD_PLATE_SIZES_TO_MM
    buildPlate.yMax *= BUILD_PLATE_SIZES_TO_MM
    buildPlate.yMin *= BUILD_PLATE_SIZES_TO_MM
    buildPlate.xBolt *= BUILD_PLATE_SIZES_TO_MM
    buildPlate.yBolt *= BUILD_PLATE_SIZES_TO_MM
    buildPlate.boltHoleDia *= BUILD_PLATE_SIZES_TO_MM

    return buildPlate
  }

  extractMachineProperties(machineConfig: IMachineConfig) {
    const machineProperties: IMachineProperties = {
      gasFlow: new Vector3(1, 0, 0),
      recoaterDirection: new Vector3(1, 0, 0),
      printHeadDirection: new Vector3(1, 0, 0),
      printHeadDirectionString: Direction.X,
      numberOfPrintHeadLanes: 0,
      firstPrintHeadLaneSide: 0,
      printHeadLaneWidth: 0,
      printingType: PrintingTypes.DMLM,
    }
    if (machineConfig) {
      machineProperties.gasFlow = this.computeDirectionVector(machineConfig.gasFlow)
      machineProperties.recoaterDirection = this.computeDirectionVector(machineConfig.recoaterDirection)
      machineProperties.printingType = machineConfig.printingType
      machineProperties.printHeadDirection = this.computeDirectionVector(machineConfig.printHeadLaneDirection)
      machineProperties.numberOfPrintHeadLanes = machineConfig.numberOfPrintHeadLanes
      machineProperties.firstPrintHeadLaneSide = machineConfig.firstPrintHeadLaneSide * BUILD_PLATE_SIZES_TO_MM
      machineProperties.printHeadLaneWidth = machineConfig.printHeadLaneWidth * BUILD_PLATE_SIZES_TO_MM
    }
    return machineProperties
  }

  computeDirectionVector(direction: string) {
    let result
    switch (direction) {
      case Direction.NegativeX:
        result = new Vector3(1, 0, 0)
        break
      case Direction.X:
        result = new Vector3(-1, 0, 0)
        break
      case Direction.NegativeY:
        result = new Vector3(0, 1, 0)
        break
      case Direction.Y:
        result = new Vector3(0, -1, 0)
        break
      default:
        result = new Vector3(1, 0, 0)
        break
    }
    return result
  }

  addParametricGroundPlate(
    addHolesAndKeepOutZones = true,
    addIndicators = true,
    addBuildVolumeLines = true,
    addPrintHeadLanes = true,
  ) {
    const obbTree = new OBBTree(this.renderScene)

    const shape = []
    for (let angle = 0; angle <= 360; angle += 1) {
      shape.push(obbTree.buildPlateBoundaryPoint(this.buildPlateSizes, angle, 0, false))
    }

    shape.push(new Vector3(this.buildPlateSizes.xMax, 0, 0))
    const path = []
    path.push(new Vector3(0, 0, 0))
    path.push(new Vector3(0, 0, 1))
    const groundBox = MeshBuilder.ExtrudeShape(
      GROUND_BOX_NAME,
      {
        shape,
        path,
        scale: 1,
        cap: Mesh.CAP_ALL,
        updatable: true,
      },
      this.scene,
    )
    groundBox.computeWorldMatrix()

    groundBox.isVisible = this.buildPlateDisplaySettings.isShowingBuildPlate
    groundBox.isPickable = false
    groundBox.material = this.scene.getMaterialByID(BUILD_PLATE_MATERIAL_NAME)
    groundBox.position.z = -groundBox.getBoundingInfo().boundingBox.extendSizeWorld.z * 2
    this.ground = groundBox

    const yShiftSize = (this.renderScene as RenderScene).yShiftSize
    // For Binder Jet if PH indexing value > 9mm, then the system shall reduce the size of
    // the buildable volume along Y-axis accordingly (2mm Y-reduction per 1mm indexing increase)
    const yAdjustment = yShiftSize > MAX_PH_INDEXING ? (yShiftSize - MAX_PH_INDEXING) * 2 : 0
    const bvX = this.buildPlateSizes.buildVolumeX
    const bvY = this.buildPlateSizes.buildVolumeY - yAdjustment
    const xMin = -bvX / 2
    const xMax = bvX / 2
    const yMin = -bvY / 2
    const yMax = bvY / 2
    const buildVolumeHeight = this.buildPlateSizes.buildVolumeHeight
    const roundedCornerRad = this.buildPlateSizes.roundedCornerRad - (this.buildPlateSizes.xMax - xMax)

    // create bolt hole keep-out zones and build volume limits boxes
    if (addHolesAndKeepOutZones) {
      this.addKeepOutZonesAndBuildVolumeLimitBoxes(bvX, bvY, xMin, yMin, roundedCornerRad, groundBox)
    }

    // add print head, recoater and front indicators
    if (addIndicators) {
      this.addIndicators()
    }

    // add build chamber limit lines
    if (addBuildVolumeLines) {
      this.addBuildVolumeLines(xMin, xMax, yMin, yMax, roundedCornerRad, obbTree, buildVolumeHeight)
    }

    // add print lanes, if available for the machine
    if (addPrintHeadLanes) {
      this.addPrintHeadLanes()
    }
  }

  generateDirectionArrows(type: string, indicatorOffset: number, useGroundBox: boolean = false) {
    const direction = this.machineProperties[type]
    const indicatorExtends = indicatorOffset * 2
    let arrowOffset
    let directionMeshName
    let directionMeshMaterialName
    switch (type) {
      case 'recoaterDirection':
        arrowOffset = 2 * indicatorExtends
        directionMeshMaterialName = RECOATER_ARROW_MATERIAL_NAME
        directionMeshName = RECOATER_DIRECTION_MESH_NAME
        break
      case 'gasFlow':
        arrowOffset = -2 * indicatorExtends
        directionMeshMaterialName = GAS_FLOW_ARROW_MATERIAL_NAME
        directionMeshName = GAS_FLOW_MESH_NAME
        break
      case 'printHeadDirection':
        arrowOffset = -2 * indicatorExtends
        directionMeshMaterialName = PRINT_HEAD_ARROW_MATERIAL_NAME
        directionMeshName = PRINT_HEAD_DIRECTION_MESH_NAME
        break
    }
    const arrow1 = MeshBuilder.CreateDisc(
      INDICATOR_ARROW_MESH_NAME,
      {
        radius: indicatorExtends,
        arc: 0.5,
        tessellation: 3,
        sideOrientation: Mesh.DOUBLESIDE,
      },
      this.scene,
    )
    arrow1.enableEdgesRendering()
    arrow1.edgesColor = new Color4(0.8, 0.8, 0.8, 1)
    arrow1.edgesWidth = 5
    const arrow2 = arrow1.clone(INDICATOR_ARROW_MESH_NAME)
    arrow2.enableEdgesRendering()
    arrow2.edgesColor = new Color4(0.8, 0.8, 0.8, 1)
    arrow2.edgesWidth = 5
    const groundBBox = this.ground.getBoundingInfo().boundingBox
    if (direction.x !== 0) {
      // assuming 1 or -1
      arrow1.position.x =
        (useGroundBox ? groundBBox.minimum.x : this.buildPlateSizes.xMin) -
        indicatorExtends +
        indicatorOffset * direction.x
      arrow1.position.y = arrow2.position.y = arrowOffset
      arrow1.rotation.z = (Math.PI / 2) * direction.x
      if (type === 'printHeadDirection') {
        arrow2.position.x =
          (useGroundBox ? groundBBox.maximum.x : this.buildPlateSizes.xMax) +
          indicatorExtends +
          indicatorOffset * -direction.x
        arrow2.rotation.z = (Math.PI / 2) * -direction.x
      } else {
        arrow2.position.x =
          (useGroundBox ? groundBBox.maximum.x : this.buildPlateSizes.xMax) +
          indicatorExtends +
          indicatorOffset * direction.x
        arrow2.rotation.z = (Math.PI / 2) * direction.x
      }
    } else if (direction.y !== 0) {
      arrow1.position.x = arrow2.position.x = arrowOffset
      arrow1.position.y =
        (useGroundBox ? groundBBox.minimum.y : this.buildPlateSizes.yMin) -
        indicatorExtends +
        indicatorOffset * -direction.y
      arrow2.position.y =
        (useGroundBox ? groundBBox.maximum.y : this.buildPlateSizes.yMax) +
        indicatorExtends +
        indicatorOffset * -direction.y
      if (direction.y === -1) {
        arrow1.rotation.z = Math.PI
        arrow2.rotation.z = Math.PI
      }
    }

    arrow1.material = arrow2.material = this.scene.getMaterialByName(directionMeshMaterialName)
    const directionMesh = new Mesh(directionMeshName, this.scene)
    arrow1.parent = directionMesh
    arrow2.parent = directionMesh
    arrow1.isVisible = this.buildPlateDisplaySettings[this.getBuidPlateDisplaySettingsName(directionMeshName)]
    arrow2.isVisible = this.buildPlateDisplaySettings[this.getBuidPlateDisplaySettingsName(directionMeshName)]
    directionMesh.isVisible = this.buildPlateDisplaySettings[this.getBuidPlateDisplaySettingsName(directionMeshName)]
    directionMesh.parent = this.ground
  }

  async loadParametricBuildPlate(
    addHolesAndKeepOutZones = true,
    addIndicators = true,
    addBuildVolumeLines = true,
    addPrintHeadLanes = true,
    buildPlatePk?: VersionablePk,
    machineConfigPk?: VersionablePk,
  ) {
    await this.loadBuildPlateSizes(buildPlatePk, machineConfigPk)
    if (this.renderScene.hasCapabilities()) {
      this.addParametricGroundPlate(addHolesAndKeepOutZones, addIndicators, addBuildVolumeLines, addPrintHeadLanes)
    }
  }

  createKeepOutClone(cylinder, position) {
    const cloneMesh = cylinder.clone(KEEP_OUT_ZONE)
    cloneMesh.isPickable = false
    // cloneMesh.material = this.scene.getMaterialByID(BUILD_PLATE_MATERIAL_NAME)
    cloneMesh.visibility = 0
    cloneMesh.position = position
    const root = new BVHTreeNode(null)
    root.left = null
    root.right = null
    cloneMesh.metadata = { bvh: root }
    return cloneMesh
  }

  removeGroundPlane() {
    if (this.ground) {
      this.scene.removeMesh(this.ground)
      this.ground.dispose()
    }
    if (this.keepOutZones) {
      this.keepOutZones.forEach((keepOutZone) => {
        keepOutZone.dispose()
      })
    }
  }

  async checkBuildPlate(buildPlatePk: VersionablePk, machineConfigPk: VersionablePk) {
    const buildPlate = await buildPlatesService.getBuildPlateByPk(buildPlatePk, machineConfigPk)
    const buildPlateSizes = this.extractBuildPlateSizes(buildPlate)
    const isValid = store.getters['buildPlans/isBuildPlanUpdating'] || this.isBuildPlateValid(buildPlateSizes)

    if (!isValid) {
      return false
    }

    this.buildPlatePk = buildPlatePk
    this.buildPlateSizes = buildPlateSizes
    this.machineProperties = this.extractMachineProperties(buildPlate.machineConfig)
    return true
  }

  isBuildPlateValid(buildPlateSizes?: IBuildPlateSizes) {
    let errorMessage = ''
    Array.from(this.renderScene.getSceneMetadata().buildPlanItems.values()).map((m) => {
      const partMetadata = m.metadata as IPartMetadata
      const isPartFits = this.isPartFitsBuildPlate(m, buildPlateSizes)
      if (!isPartFits) {
        this.onGetBPNameByBPId.trigger({
          buildPlanId: partMetadata.buildPlanItemId,
          callback: (name) => {
            errorMessage += `Part ${name} exceeding the build plate size.`
          },
        })
      }
    })
    if (errorMessage.length > 0) {
      messageService.showErrorMessage(`Impossible to change build plate:
        ${errorMessage} Please choose another build plate or delete exceeding parts.`)
      return false
    }

    return true
  }

  isPartFitsBuildPlate(mesh: TransformNode, buildPlateSizes?: IBuildPlateSizes) {
    const obbTreeBBox = this.renderScene.getObbTree().getOBBTreeBBox(mesh).boundingBox
    const meshBoxXDimension = Math.abs(obbTreeBBox.maximum.x - obbTreeBBox.minimum.x)
    const meshBoxYDimension = Math.abs(obbTreeBBox.maximum.y - obbTreeBBox.minimum.y)

    const bpSizes = buildPlateSizes ? buildPlateSizes : this.buildPlateSizes
    const buildPlateBoxXDimension = Math.abs(bpSizes.xMax - bpSizes.xMin)
    const buildPlateBoxYDimension = Math.abs(bpSizes.yMax - bpSizes.yMin)

    return buildPlateBoxXDimension > meshBoxXDimension && buildPlateBoxYDimension > meshBoxYDimension
  }

  isPartHullFitsBuildPlate(hullBBox: BoundingBox, buildPlateSizes?: IBuildPlateSizes) {
    const bpSizes = buildPlateSizes ? buildPlateSizes : this.buildPlateSizes
    const buildPlateBoxXDimension = Math.abs(bpSizes.xMax - bpSizes.xMin)
    const buildPlateBoxYDimension = Math.abs(bpSizes.yMax - bpSizes.yMin)

    const hullBoxXDimension = Math.abs(hullBBox.maximum.x - hullBBox.minimum.x)
    const hullBoxYDimension = Math.abs(hullBBox.maximum.y - hullBBox.minimum.y)

    return buildPlateBoxXDimension > hullBoxXDimension && buildPlateBoxYDimension > hullBoxYDimension
  }

  createGroundSinterPlate(boundingInfo?: BoundingInfo) {
    this.createPlate(SINTER_PLATE_MATERIAL_NAME, boundingInfo)
    this.addIndicators(false)

    const obbTree = new OBBTree(this.renderScene)
    const yShiftSize = (this.renderScene as RenderScene).yShiftSize
    // For Binder Jet if PH indexing value > 9mm, then the system shall reduce the size of
    // the buildable volume along Y-axis accordingly (2mm Y-reduction per 1mm indexing increase)
    const yAdjustment = yShiftSize > MAX_PH_INDEXING ? (yShiftSize - MAX_PH_INDEXING) * 2 : 0
    const bvX = this.buildPlateSizes.buildVolumeX
    const bvY = this.buildPlateSizes.buildVolumeY - yAdjustment
    const xMin = -bvX / 2
    const xMax = bvX / 2
    const yMin = -bvY / 2
    const yMax = bvY / 2
    const buildVolumeHeight = this.buildPlateSizes.buildVolumeHeight
    const roundedCornerRad = this.buildPlateSizes.roundedCornerRad - (this.buildPlateSizes.xMax - xMax)
    this.addBuildVolumeLines(xMin, xMax, yMin, yMax, roundedCornerRad, obbTree, buildVolumeHeight, true)
  }

  createPlate(materialName: string, boundingInfo?: BoundingInfo) {
    const groundPlane = this.createGroundPlane(boundingInfo)
    groundPlane.material = this.scene.getMaterialByID(materialName)

    this.ground = groundPlane
    this.ground.computeWorldMatrix(true)
  }

  createGroundPartPreviewPlate(boundingInfo?: BoundingInfo) {
    this.createPlate(PART_PREVIEW_PLATE_MATERIAL_NAME, boundingInfo)

    if (this.renderScene.getActiveCamera()) {
      this.renderScene.getActiveCamera().repositionCamera(this.scene, true)
      this.renderScene.getActiveCamera().addChangeTargetObserver()
    }
  }

  displaySpatialLetterGrid(isVisible: boolean, settings?: GridLetterJSON) {
    const letterGridMeshes = this.scene.meshes.filter((m) => m.name === SPATIAL_LETTER_GRID_NAME)
    if (letterGridMeshes && letterGridMeshes.length) {
      letterGridMeshes.forEach((letterGridMesh) => {
        const sourceMeshes = letterGridMesh
          .getChildMeshes()
          .filter((c) => c instanceof InstancedMesh)
          .map((i: InstancedMesh) => i.sourceMesh)
        this.scene.removeMesh(letterGridMesh, true)
        letterGridMesh.dispose()
        sourceMeshes.forEach((source) => {
          this.scene.removeMesh(source)
          source.dispose(false, true)
        })
      })
    }

    if (!isVisible || !isNumber(settings.offset) || !isNumber(settings.spacing) || !settings.spacing) {
      return
    }

    let direction: string
    let orthogonalDirection: string
    switch (settings.direction) {
      case GridLetterDirection.XAxis:
        direction = X_CHAR
        orthogonalDirection = Y_CHAR
        break
      case GridLetterDirection.YAxis:
        direction = Y_CHAR
        orthogonalDirection = X_CHAR
        break
      case GridLetterDirection.ZAxis:
        direction = Z_CHAR
        orthogonalDirection = Y_CHAR
        this.addSpatialLetterGrid(direction, X_CHAR, settings.offset, settings.spacing)
        break
    }

    this.addSpatialLetterGrid(direction, orthogonalDirection, settings.offset, settings.spacing)
  }

  private addSpatialLetterGrid(direction: string, orthogonalDirection: string, offset: number, spacing: number) {
    const buildChamberPolylines = this.scene.getMeshByName(BUILD_CHAMBER_POLYLINES_NAME).getChildMeshes()
    const volume = this.scene.getWorldExtends(
      (m) => this.meshManager.isComponentMesh(m) || buildChamberPolylines.includes(m),
    )

    const letterPositionOffset = spacing >= 5 ? 10 : 5
    const letterFontSize = spacing >= 5 ? 120 : spacing < 2 ? 28 : 60
    const points: Vector3[][] = []
    const letterPositions: Vector3[] = []
    const originLineStart = Vector3.Zero()
    const originLineEnd = Vector3.Zero()
    originLineStart[direction] = originLineEnd[direction] = offset
    originLineStart[orthogonalDirection] = volume.min[orthogonalDirection]
    originLineEnd[orthogonalDirection] = volume.max[orthogonalDirection]
    points.push([originLineStart, originLineEnd])

    const negativeLineCenterStart = originLineStart.clone()
    const negativeLineCenterEnd = originLineEnd.clone()
    for (let lineCenter = offset - spacing; lineCenter > volume.min[direction] - spacing; lineCenter -= spacing) {
      negativeLineCenterStart[direction] = negativeLineCenterEnd[direction] = lineCenter
      negativeLineCenterStart[orthogonalDirection] = volume.min[orthogonalDirection]
      negativeLineCenterEnd[orthogonalDirection] = volume.max[orthogonalDirection]
      points.push([negativeLineCenterStart.clone(), negativeLineCenterEnd.clone()])

      const letterPositionStart = negativeLineCenterStart.clone()
      const letterPositionEnd = negativeLineCenterEnd.clone()
      letterPositionStart[direction] += spacing / 2
      letterPositionEnd[direction] += spacing / 2
      letterPositionStart[orthogonalDirection] -= letterPositionOffset
      letterPositionEnd[orthogonalDirection] += letterPositionOffset
      letterPositions.push(letterPositionStart, letterPositionEnd)
    }

    const positiveLineCenterStart = originLineStart.clone()
    const positiveLineCenterEnd = originLineEnd.clone()
    for (let lineCenter = offset + spacing; lineCenter < volume.max[direction] + spacing; lineCenter += spacing) {
      positiveLineCenterStart[direction] = positiveLineCenterEnd[direction] = lineCenter
      positiveLineCenterStart[orthogonalDirection] = volume.min[orthogonalDirection]
      positiveLineCenterEnd[orthogonalDirection] = volume.max[orthogonalDirection]
      points.push([positiveLineCenterStart.clone(), positiveLineCenterEnd.clone()])

      const letterPositionStart = positiveLineCenterStart.clone()
      const letterPositionEnd = positiveLineCenterEnd.clone()
      letterPositionStart[direction] -= spacing / 2
      letterPositionEnd[direction] -= spacing / 2
      letterPositionStart[orthogonalDirection] -= letterPositionOffset
      letterPositionEnd[orthogonalDirection] += letterPositionOffset
      letterPositions.push(letterPositionStart, letterPositionEnd)
    }

    // add connecting lines to form a grid
    points.push([negativeLineCenterStart, positiveLineCenterStart])
    points.push([negativeLineCenterEnd, positiveLineCenterEnd])

    // create lines grid mesh
    const spatialLetterGridMesh = MeshBuilder.CreateLineSystem(SPATIAL_LETTER_GRID_NAME, { lines: points }, this.scene)
    spatialLetterGridMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
    spatialLetterGridMesh.color = SPATIAL_LETTER_GRID_COLOR
    spatialLetterGridMesh.position.z = this.groundBox.getBoundingInfo().boundingBox.maximumWorld.z + Epsilon

    // TODO: decrease the number of render calls by reducing the number of letter meshes and materials
    for (let i = 0; i < letterPositions.length; i += 2) {
      const point = letterPositions[i]
      const letterCode =
        SYMBOL_UPPER_A_CODE +
        Math.min(Math.max(0, Math.trunc((point[direction] - offset) / spacing)), LATIN_SYMBOLS_LENGTH - 1)

      // create texture, material and mesh for each letter
      const characterMesh = MeshBuilder.CreatePlane(CHARACTER, { size: 10 }, this.scene)
      characterMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
      characterMesh.material = this.createCharacterMaterial(
        String.fromCharCode(letterCode).toUpperCase(),
        letterFontSize,
      )
      characterMesh.material.isReady(characterMesh, true)
      this.scene.removeMesh(characterMesh)

      const characterMeshInstanceMin = characterMesh.createInstance(CHARACTER)
      characterMeshInstanceMin.rotation.x =
        direction === Z_CHAR && orthogonalDirection === X_CHAR ? -Math.PI / 2 : Math.PI
      characterMeshInstanceMin.rotation.y = direction === Z_CHAR && orthogonalDirection === Y_CHAR ? -Math.PI / 2 : 0
      characterMeshInstanceMin.rotation.z =
        direction === Y_CHAR ? Math.PI / 2 : direction === Z_CHAR && orthogonalDirection === Y_CHAR ? Math.PI / 2 : 0
      characterMeshInstanceMin.position = point
      characterMeshInstanceMin.parent = spatialLetterGridMesh

      const characterMeshInstanceMax = characterMesh.createInstance(CHARACTER)
      characterMeshInstanceMax.rotation.x =
        direction === Z_CHAR && orthogonalDirection === X_CHAR ? -Math.PI / 2 : Math.PI
      characterMeshInstanceMax.rotation.y = direction === Z_CHAR && orthogonalDirection === Y_CHAR ? -Math.PI / 2 : 0
      characterMeshInstanceMax.rotation.z =
        direction === Y_CHAR
          ? -Math.PI / 2
          : direction === X_CHAR
            ? Math.PI
            : direction === Z_CHAR && orthogonalDirection === Y_CHAR
              ? Math.PI / 2
              : 0
      characterMeshInstanceMax.position = letterPositions[i + 1]
      characterMeshInstanceMax.parent = spatialLetterGridMesh
    }

    if (direction === Z_CHAR) {
      const thirdDirection = orthogonalDirection === X_CHAR ? Y_CHAR : X_CHAR
      spatialLetterGridMesh.position[thirdDirection] = volume.min[thirdDirection]
      const inst = spatialLetterGridMesh.clone(SPATIAL_LETTER_GRID_NAME, null, true)
      inst.position[thirdDirection] = volume.max[thirdDirection]
      spatialLetterGridMesh
        .getChildMeshes()
        .filter((child) => child instanceof InstancedMesh)
        .forEach((instance: InstancedMesh) => {
          const characterMesh = instance.createInstance(CHARACTER)
          characterMesh.position = instance.position.clone()
          characterMesh.rotation[orthogonalDirection] = orthogonalDirection === X_CHAR ? Math.PI / 2 : -Math.PI / 2
          characterMesh.rotation.z = orthogonalDirection === X_CHAR ? Math.PI : Math.PI / 2
          characterMesh.parent = inst
        })
    }
  }

  private createCharacterMaterial(character: string, fontSize: number) {
    const characterTexture = new DynamicTexture(
      CHARACTER,
      {
        width: 256,
        height: 256,
      },
      this.scene,
      false,
    )
    const font = `normal ${fontSize}px Arial`
    characterTexture.drawText(
      character,
      null,
      null,
      font,
      SPATIAL_LETTER_GRID_COLOR.toHexString(),
      'transparent',
      false,
      true,
    )
    const characterMaterial = new StandardMaterial(CHARACTER, this.scene)
    characterMaterial.diffuseTexture = characterTexture
    characterMaterial.diffuseTexture.hasAlpha = true
    characterMaterial.backFaceCulling = false
    characterMaterial.useAlphaFromDiffuseTexture = true
    characterMaterial.freeze()

    return characterMaterial
  }

  private addKeepOutZonesAndBuildVolumeLimitBoxes(bvX, bvY, xMin, yMin, roundedCornerRad, groundBox) {
    const hasBoltHoles = !!this.buildPlateSizes.boltHoleDia
    let bhCylinder1: Mesh
    let bhCylinder2: Mesh
    let bhCylinder3: Mesh
    let bhCylinder4: Mesh

    if (hasBoltHoles) {
      const bhPosX = this.buildPlateSizes.xBolt
      const bhPosY = this.buildPlateSizes.yBolt
      const diameter = this.buildPlateSizes.boltHoleDia
      const plateDepth = 1
      const height = this.buildPlateSizes.buildVolumeHeight + plateDepth * 1.1
      const zPosition = height / 2 - plateDepth * 1.1
      bhCylinder1 = MeshBuilder.CreateCylinder(KEEP_OUT_ZONE, { height, diameter }, this.scene)
      bhCylinder1.isPickable = false
      // bhCylinder1.material = this.scene.getMaterialByID(BUILD_PLATE_MATERIAL_NAME)
      bhCylinder1.visibility = 0
      bhCylinder1.rotation = new Vector3(1.57, 0, 0)
      bhCylinder1.position = new Vector3(-bhPosX, -bhPosY, zPosition)
      const root = new BVHTreeNode(null)
      root.left = null
      root.right = null
      bhCylinder1.metadata = { bvh: root }
      bhCylinder2 = this.createKeepOutClone(bhCylinder1, new Vector3(bhPosX, -bhPosY, zPosition))
      bhCylinder3 = this.createKeepOutClone(bhCylinder1, new Vector3(-bhPosX, bhPosY, zPosition))
      bhCylinder4 = this.createKeepOutClone(bhCylinder1, new Vector3(bhPosX, bhPosY, zPosition))
    }

    const bvHeight = this.buildPlateSizes.buildVolumeHeight
    const bvHeightScaled = bvHeight * 3
    const bvWidth = Math.max(bvX, bvY)
    const bvWidthScaled = bvWidth * 20
    const bvLimitZone1 = MeshBuilder.CreateBox(
      BUILD_VOLUME_LIMIT_ZONE,
      {
        height: bvWidthScaled,
        width: bvWidthScaled,
        depth: bvHeight,
      },
      this.scene,
    )
    bvLimitZone1.visibility = 0
    const bvLimitZone2 = bvLimitZone1.clone(BUILD_VOLUME_LIMIT_ZONE)
    const bvLimitZone3 = bvLimitZone1.clone(BUILD_VOLUME_LIMIT_ZONE)
    const bvLimitZone4 = bvLimitZone1.clone(BUILD_VOLUME_LIMIT_ZONE)
    const bvLimitZone5 = MeshBuilder.CreateBox(
      BUILD_VOLUME_LIMIT_ZONE,
      {
        height: bvWidthScaled,
        width: bvWidthScaled,
        depth: bvHeightScaled,
      },
      this.scene,
    )
    bvLimitZone5.visibility = 0
    bvLimitZone1.isPickable = false
    bvLimitZone1.position = new Vector3(-(bvWidthScaled + bvX) / 2, (bvWidthScaled - bvY) / 2, bvHeight / 2)
    bvLimitZone2.isPickable = false
    bvLimitZone2.position = new Vector3(-(bvWidthScaled - bvX) / 2, -(bvWidthScaled + bvY) / 2, bvHeight / 2)
    bvLimitZone3.isPickable = false
    bvLimitZone3.position = new Vector3((bvWidthScaled + bvX) / 2, -(bvWidthScaled - bvY) / 2, bvHeight / 2)
    bvLimitZone4.isPickable = false
    bvLimitZone4.position = new Vector3((bvWidthScaled - bvX) / 2, (bvWidthScaled + bvY) / 2, bvHeight / 2)
    bvLimitZone5.isPickable = false
    bvLimitZone5.position = new Vector3(0, 0, bvHeightScaled - bvHeight / 2)
    const bvLimitZone6 = MeshBuilder.CreateBox(
      BUILD_VOLUME_LIMIT_ZONE,
      {
        height: Math.sqrt(8) * roundedCornerRad - 2 * roundedCornerRad,
        width: Math.sqrt(8) * roundedCornerRad - 2 * roundedCornerRad,
        depth: bvHeight,
      },
      this.scene,
    )
    bvLimitZone6.rotation.z = Math.PI / 4
    bvLimitZone6.visibility = 0
    const bvLimitZone7 = bvLimitZone6.clone(BUILD_VOLUME_LIMIT_ZONE)
    const bvLimitZone8 = bvLimitZone6.clone(BUILD_VOLUME_LIMIT_ZONE)
    const bvLimitZone9 = bvLimitZone6.clone(BUILD_VOLUME_LIMIT_ZONE)
    bvLimitZone6.position = new Vector3(-xMin, -yMin, bvHeight / 2)
    bvLimitZone6.isPickable = false
    bvLimitZone7.position = new Vector3(xMin, -yMin, bvHeight / 2)
    bvLimitZone7.isPickable = false
    bvLimitZone8.position = new Vector3(-xMin, yMin, bvHeight / 2)
    bvLimitZone8.isPickable = false
    bvLimitZone9.position = new Vector3(xMin, yMin, bvHeight / 2)
    bvLimitZone9.isPickable = false

    this.createBVHFromLimitBox(bvLimitZone1, BuildVolumeLimitZoneType.Side)
    this.createBVHFromLimitBox(bvLimitZone2, BuildVolumeLimitZoneType.Side)
    this.createBVHFromLimitBox(bvLimitZone3, BuildVolumeLimitZoneType.Side)
    this.createBVHFromLimitBox(bvLimitZone4, BuildVolumeLimitZoneType.Side)
    this.createBVHFromLimitBox(bvLimitZone5, BuildVolumeLimitZoneType.Top)
    this.createBVHFromLimitBox(bvLimitZone6, BuildVolumeLimitZoneType.Side)
    this.createBVHFromLimitBox(bvLimitZone7, BuildVolumeLimitZoneType.Side)
    this.createBVHFromLimitBox(bvLimitZone8, BuildVolumeLimitZoneType.Side)
    this.createBVHFromLimitBox(bvLimitZone9, BuildVolumeLimitZoneType.Side)

    this.keepOutZones = [
      bvLimitZone1,
      bvLimitZone2,
      bvLimitZone3,
      bvLimitZone4,
      bvLimitZone5,
      bvLimitZone6,
      bvLimitZone7,
      bvLimitZone8,
      bvLimitZone9,
    ]

    if (hasBoltHoles) {
      this.keepOutZones = [...this.keepOutZones, bhCylinder1, bhCylinder2, bhCylinder3, bhCylinder4]
    }

    // need to make sure world matrices and bounding infos get updated/set in time after the above position updates
    // so that collision checks with keep-out zones that run later don't create false positives
    this.keepOutZones.map((zone) => {
      zone.computeWorldMatrix()
      zone.metadata.bvh.bInfo = zone.getBoundingInfo()
    })

    // boolean operations for bolt holes
    const buildPlateCSG = CSG.FromMesh(groundBox as Mesh)
    this.scene.removeMesh(groundBox)
    let subCSG = buildPlateCSG
    if (hasBoltHoles) {
      subCSG = buildPlateCSG
        .subtract(CSG.FromMesh(bhCylinder1))
        .subtract(CSG.FromMesh(bhCylinder2))
        .subtract(CSG.FromMesh(bhCylinder3))
        .subtract(CSG.FromMesh(bhCylinder4))
    }
    this.ground = subCSG.toMesh(GROUND_BOX_NAME, this.scene.getMaterialByID(BUILD_PLATE_MATERIAL_NAME), this.scene)
    this.ground.computeWorldMatrix()
    this.ground.isPickable = false
  }

  private createBVHFromLimitBox(bvLimitZone: Mesh, type) {
    const bvLimitZoneRoot = new BVHTreeNode(null)
    bvLimitZoneRoot.left = null
    bvLimitZoneRoot.right = null
    bvLimitZone.metadata = { type, bvh: bvLimitZoneRoot }
  }

  private addIndicators(addFrontIndicator: boolean = true) {
    const indicatorOffset = Math.min(this.buildPlateSizes.xMax, this.buildPlateSizes.yMax) / 10
    if (this.machineProperties.printingType !== PrintingTypes.BinderJet) {
      // for non-BJ printers add gas flow direction
      this.generateDirectionArrows('gasFlow', indicatorOffset)
    } else {
      // for BJ printers add print head direction
      this.generateDirectionArrows('printHeadDirection', indicatorOffset)
    }
    // add recoater direction arrows
    this.generateDirectionArrows('recoaterDirection', indicatorOffset)
    if (addFrontIndicator) {
      // add FRONT indicator plane
      const frontIndicator = MeshBuilder.CreatePlane(
        FRONT_INDICATOR_MESH_NAME,
        {
          width: indicatorOffset * 2,
          height: indicatorOffset,
        },
        this.scene,
      )
      frontIndicator.position = new Vector3(0, this.buildPlateSizes.yMin - indicatorOffset, 0)
      frontIndicator.rotation.x = Math.PI
      frontIndicator.material = this.scene.getMaterialByName(FRONT_INDICATOR_MATERIAL_NAME)
      frontIndicator.enableEdgesRendering()
      frontIndicator.edgesColor = new Color4(0.8, 0.8, 0.8, 1)
      frontIndicator.edgesWidth = 5
      frontIndicator.parent = this.ground
    }
  }

  private addBuildVolumeLines(
    xMin,
    xMax,
    yMin,
    yMax,
    roundedCornerRad,
    obbTree,
    buildVolumeHeight,
    isSinterPlan?: boolean,
  ) {
    const buildVolumeMetadata = {
      xMin,
      yMin,
      xMax,
      yMax,
      roundedCornerRad,
    }
    const bvBottomPoints = []
    const bvTopPoints = []
    for (let angle = 0; angle <= 360; angle += 1) {
      const v = obbTree.buildPlateBoundaryPoint(buildVolumeMetadata, angle, 0, false)
      const buildChamberLinesMinZ = isSinterPlan ? 0 : BUILD_CHAMBER_LINES_MIN_Z
      bvBottomPoints.push(new Vector3(v.x, v.y, buildChamberLinesMinZ))
      bvTopPoints.push(new Vector3(v.x, v.y, buildVolumeHeight + buildChamberLinesMinZ))
    }
    // drawing vertical build volume lines at each end of each rounded corner arc (8 total)
    const angle1 = Math.PI / 2 - Math.atan(xMax / (yMax - roundedCornerRad))
    const angle2 = Math.PI / 2 - Math.atan((xMax - roundedCornerRad) / yMax)
    const angle3 = Math.PI / 2 + Math.atan(xMax / (yMax - roundedCornerRad))
    const angle4 = Math.PI / 2 + Math.atan((xMax - roundedCornerRad) / yMax)
    const angle5 = angle1 + Math.PI
    const angle6 = angle2 + Math.PI
    const angle7 = angle3 + Math.PI
    const angle8 = angle4 + Math.PI
    const buildChamberSideLines = [
      [bvBottomPoints[this.radToRoundDeg(angle1)], bvTopPoints[this.radToRoundDeg(angle1)]],
      [bvBottomPoints[this.radToRoundDeg(angle2)], bvTopPoints[this.radToRoundDeg(angle2)]],
      [bvBottomPoints[this.radToRoundDeg(angle3)], bvTopPoints[this.radToRoundDeg(angle3)]],
      [bvBottomPoints[this.radToRoundDeg(angle4)], bvTopPoints[this.radToRoundDeg(angle4)]],
      [bvBottomPoints[this.radToRoundDeg(angle5)], bvTopPoints[this.radToRoundDeg(angle5)]],
      [bvBottomPoints[this.radToRoundDeg(angle6)], bvTopPoints[this.radToRoundDeg(angle6)]],
      [bvBottomPoints[this.radToRoundDeg(angle7)], bvTopPoints[this.radToRoundDeg(angle7)]],
      [bvBottomPoints[this.radToRoundDeg(angle8)], bvTopPoints[this.radToRoundDeg(angle8)]],
    ]
    const buildChamberBottomPolylines = MeshBuilder.CreateLines(
      BUILD_CHAMBER_BOTTOM_POLYLINES_NAME,
      { points: bvBottomPoints },
      this.scene,
    )
    buildChamberBottomPolylines.id = createGuid()
    const buildChamberTopPolylines = MeshBuilder.CreateLines(
      BUILD_CHAMBER_TOP_POLYLINES_NAME,
      { points: bvTopPoints },
      this.scene,
    )
    buildChamberTopPolylines.id = createGuid()
    const buildChamberSidePolylines = MeshBuilder.CreateLineSystem(
      BUILD_CHAMBER_SIDE_POLYLINES_NAME,
      { lines: buildChamberSideLines },
      this.scene,
    )
    const buildChamberPolylines = new Mesh(BUILD_CHAMBER_POLYLINES_NAME, this.scene)
    buildChamberPolylines.isVisible = this.buildPlateDisplaySettings.isShowingBuildPlanVolume
    buildChamberPolylines.parent = this.ground
    buildChamberBottomPolylines.parent = buildChamberPolylines
    buildChamberBottomPolylines.isVisible = this.buildPlateDisplaySettings.isShowingBuildPlanVolume
    buildChamberTopPolylines.parent = buildChamberPolylines
    buildChamberTopPolylines.isVisible = this.buildPlateDisplaySettings.isShowingBuildPlanVolume
    buildChamberSidePolylines.parent = buildChamberPolylines
    buildChamberSidePolylines.isVisible = this.buildPlateDisplaySettings.isShowingBuildPlanVolume
    buildChamberBottomPolylines.renderingGroupId = MESH_RENDERING_GROUP_ID
    buildChamberTopPolylines.renderingGroupId = MESH_RENDERING_GROUP_ID
    buildChamberSidePolylines.renderingGroupId = MESH_RENDERING_GROUP_ID
    buildChamberBottomPolylines.color =
      buildChamberTopPolylines.color =
      buildChamberSidePolylines.color =
      new Color3(0.7, 0.7, 0.7)
  }

  private addPrintHeadLanes() {
    if (this.machineProperties.numberOfPrintHeadLanes > 0) {
      const printHeadPolylines = []
      switch (this.machineProperties.printHeadDirectionString) {
        case Direction.X:
        case Direction.NegativeX:
          for (let i = 0; i <= this.machineProperties.numberOfPrintHeadLanes; i = i + 1) {
            const lanePositionY =
              this.machineProperties.firstPrintHeadLaneSide + i * this.machineProperties.printHeadLaneWidth
            const startPoint = new Vector3(this.buildPlateSizes.xMin, lanePositionY, 1.5)
            const endPoint = new Vector3(this.buildPlateSizes.xMax, lanePositionY, 1.5)
            printHeadPolylines.push([startPoint, endPoint])
            if (i < this.machineProperties.numberOfPrintHeadLanes) {
              // add connecting lines to form a grid
              const startPointConnection = startPoint.clone()
              const endPointConnection = endPoint.clone()
              startPointConnection.y = endPointConnection.y = lanePositionY + this.machineProperties.printHeadLaneWidth
              printHeadPolylines.push([startPoint, startPointConnection])
              printHeadPolylines.push([endPoint, endPointConnection])
            }
          }
          break
        case Direction.Y:
        case Direction.NegativeY:
          for (let i = 0; i <= this.machineProperties.numberOfPrintHeadLanes; i = i + 1) {
            const lanePositionX =
              this.machineProperties.firstPrintHeadLaneSide + i * this.machineProperties.printHeadLaneWidth
            const startPoint = new Vector3(lanePositionX, this.buildPlateSizes.yMin, 1.5)
            const endPoint = new Vector3(lanePositionX, this.buildPlateSizes.yMax, 1.5)
            printHeadPolylines.push([startPoint, endPoint])
            if (i < this.machineProperties.numberOfPrintHeadLanes) {
              // add connecting lines to form a grid
              const startPointConnection = startPoint.clone()
              const endPointConnection = endPoint.clone()
              startPointConnection.x = endPointConnection.x = lanePositionX + this.machineProperties.printHeadLaneWidth
              printHeadPolylines.push([startPoint, startPointConnection])
              printHeadPolylines.push([endPoint, endPointConnection])
            }
          }
          break
      }
      const printHeadPolylinesMesh = MeshBuilder.CreateLineSystem(
        PRINT_HEAD_LANES_MESH_NAME,
        { lines: printHeadPolylines },
        this.scene,
      )
      printHeadPolylinesMesh.color = REGULAR_ORANGE
      printHeadPolylinesMesh.parent = this.ground
    }
  }

  private createGroundPlane(boundingInfo?: BoundingInfo) {
    const box = boundingInfo ? boundingInfo.boundingBox : DEFAULT_SINTER_PLATE_BOUNDING_INFO.boundingBox
    this.removeGroundPlane()

    let groundBox: Mesh
    if (!boundingInfo) {
      groundBox = MeshBuilder.CreatePlane(
        SINTER_PLATE_NAME,
        {
          width: box.extendSizeWorld.x * 2,
          height: box.extendSizeWorld.y * 2,
        },
        this.scene,
      )
    } else {
      const diagonalDistance = Vector3.Distance(
        new Vector3(box.maximumWorld.x, box.maximumWorld.y, box.maximumWorld.z),
        new Vector3(box.minimumWorld.x, box.minimumWorld.y, box.minimumWorld.z),
      )

      const numberOfCells = Math.floor((diagonalDistance + SINTER_PLATE_PADDING) / BUILD_PLATE_GRID_RATIO)
      const evenNumberOfCells = numberOfCells % 2 ? numberOfCells + 1 : numberOfCells
      const size = BUILD_PLATE_GRID_RATIO * evenNumberOfCells
      groundBox = MeshBuilder.CreatePlane(
        SINTER_PLATE_NAME,
        {
          width: size,
          height: size,
        },
        this.scene,
      )
    }

    groundBox.isPickable = false
    groundBox.position.x = box.centerWorld.x
    groundBox.position.y = box.centerWorld.y
    groundBox.position.z = box.minimumWorld.z
    groundBox.computeWorldMatrix()
    return groundBox
  }

  private radToRoundDeg(radians: number) {
    return Math.round((radians * 180) / Math.PI)
  }
}
