import { ActionTree } from 'vuex'
import { ISitesState, UpdatePrintStrategyNamePayload, UploadProductionSetPayload } from '@/store/modules/sites/types'
import { IRootState } from '@/store/types'

import sitesApi from '@/api/sites'
import {
  PrintStrategyInfo,
  MachineTypeDto,
  SiteDto,
  UpdateMachineTypeDto,
  UpdateSiteDto,
  ParameterSet,
  PrintStrategy,
} from '@/types/Sites/Site'
import { UpdateAdminMaterialDTO } from '@/types/Sites/SiteAdminDTOs'
import { UpdateBuildPlatformDTO } from '@/types/Sites/TenantAdminBuildPlatform'
import messageService from '@/services/messageService'
import { PrintStrategyStatus } from '@/types/Sites/PrintStrategyStatus'

export const actions: ActionTree<ISitesState, IRootState> = {
  async updateSiteConstants({ commit }, payload) {
    // TODO: need to implement logic for the updating site constants
    return payload
  },

  async fetchProcessParameterSolidLevel({ commit }, printStrategyPk): Promise<number> {
    const solidLevel = await sitesApi.fetchProcessParameterSolidLevel(printStrategyPk)
    commit('setStrategyProcessParameterSolidLevel', solidLevel)
    return solidLevel
  },

  async fetchAllSites({ commit }) {
    try {
      const sites = await sitesApi.fetchAllSites()
      commit(
        'setSites',
        sites.map((site) => new SiteDto(site)),
      )
      return sites
    } catch (error) {
      messageService.showErrorMessage(error.message || error.data.message)
    }
  },

  async fetchSiteById({ commit }, siteId: string) {
    const site = await sitesApi.fetchSiteById(siteId)
    commit('setSelectedSite', new SiteDto(site))

    return site
  },

  async fetchMachineTypesBySiteId({ commit }, siteId: number | string) {
    const machineTypes = await sitesApi.fetchMachineTypesBySiteId(siteId)
    commit(
      'setMachineTypes',
      machineTypes.map((machineType) => new MachineTypeDto(machineType)),
    )

    return machineTypes
  },

  async updateSite({ commit }, params: { siteId: number; updateSiteDto: UpdateSiteDto }) {
    const site = await sitesApi.updateSite(params.siteId, params.updateSiteDto)
    return site
  },

  async updateMachineType({ commit }, params: { machineTypeId: number; updateMachineTypeDto: UpdateMachineTypeDto }) {
    const machineType = await sitesApi.updateMacineType(params.machineTypeId, params.updateMachineTypeDto)
    commit('setMachineType', new MachineTypeDto(machineType))

    return machineType
  },

  async fetchMachineTypeById({ commit }, machineTypeId: string) {
    const machineType = await sitesApi.fetchMachineTypeById(machineTypeId)
    commit('setSelectedMachineType', new MachineTypeDto(machineType))

    return machineType
  },

  async fetchMaterialById({ commit }, materialId: string) {
    return sitesApi.fetchMaterialById(materialId)
  },

  async fetchMaterialsByMachineConfigId({}, machineTypeId: string) {
    return await sitesApi.fetchMaterialsByMachineConfigId(machineTypeId)
  },

  async updateMaterial({ commit }, payload: { materialId: string; dto: UpdateAdminMaterialDTO }) {
    const material = await sitesApi.updateMaterial(payload.materialId, payload.dto)

    if (material) {
      commit('updateMachineMaterial', material)
    }

    return material
  },

  async fetchPrintStrategiesBySiteMachineMaterial(
    { commit },
    payload: { materialId: number; machineConfigId: number; siteId: number; params?: object },
  ) {
    let printStrategies: PrintStrategy[] = []

    try {
      commit('setLoadingStatus', true)

      printStrategies = await sitesApi.fetchPrintStrategiesBySiteMachineMaterial(
        payload.siteId,
        payload.machineConfigId,
        payload.materialId,
        payload.params,
      )
    } finally {
      commit('setLoadingStatus', false)
    }

    return printStrategies
  },

  async fetchBuildPlatesByMaterialAndMachine({}, payload: { machineConfigId: string; materialId: string }) {
    return await sitesApi.fetchBuildPlatesByMaterialAndMachine(payload.machineConfigId, payload.materialId)
  },

  async updateBuildPlate({}, payload: { id: number; dto: UpdateBuildPlatformDTO }) {
    return await sitesApi.updateBuildPlate(payload.id, payload.dto)
  },

  async fetchPrintStrategyById({ commit }, payload: { printStrategyId: number; siteId: number }) {
    const printStrategy = await sitesApi.fetchPrintStrategyById(payload.printStrategyId, payload.siteId)
    commit('setSelectedPrintStrategy', printStrategy)
    return printStrategy
  },

  async fetchPrintStrategyAdditionalInfo({ commit }, printStrategyId: number) {
    const additional = await sitesApi.fetchPrintStrategyAdditionalInfo(printStrategyId)
    commit('setPrintStrategyAdditionalInfo', additional)
    return additional
  },

  async fetchInertGases({ commit }) {
    const inertGases = await sitesApi.fetchInertGases()

    if (inertGases) {
      commit('setInertGases', inertGases)
    }
  },

  async updatePrintStrategyStatus(
    { commit },
    params: { printStrategyId: number; siteId: number; status: PrintStrategyStatus },
  ) {
    const printStrategy = await sitesApi.updatePrintStrategyStatus(params.printStrategyId, params.siteId, params.status)
    if (printStrategy) {
      commit('setSelectedPrintStrategy', printStrategy)
    }
  },

  async updatePrintStrategyDefaults({ commit }, params: { printStrategyId: number; siteId: number; defaults }) {
    const printStrategy = await sitesApi.updatePrintStrategyDefaults(
      params.printStrategyId,
      params.siteId,
      params.defaults,
    )
    if (printStrategy) {
      commit('setSelectedPrintStrategy', printStrategy)
    }
  },

  async addPrintStrategyParameterSet(
    { commit },
    params: { printStrategyId: number; siteId: number; parameterSetId: number; layerThickness?: number },
  ) {
    const printStrategy = await sitesApi.addPrintStrategyParameterSet(
      params.printStrategyId,
      params.siteId,
      params.parameterSetId,
      params.layerThickness,
    )

    if (printStrategy) {
      commit('setSelectedPrintStrategy', printStrategy)
    }
  },

  async deletePrintStrategyParameterSet(
    { commit },
    params: { printStrategyId: number; siteId: number; parameterSetId: number },
  ) {
    const printStrategy = await sitesApi.deletePrintStrategyParameterSet(
      params.printStrategyId,
      params.siteId,
      params.parameterSetId,
    )

    if (printStrategy) {
      commit('setSelectedPrintStrategy', printStrategy)
    }

    return printStrategy
  },

  async updatePrintStrategyParameterSetLayerThickness(
    { commit },
    params: { printStrategyId: number; siteId: number; printStrategyParameterSetId: number; layerThickness: number },
  ) {
    const printStrategy = await sitesApi.updatePrintStrategyParameterSetLayerThickness(
      params.printStrategyId,
      params.siteId,
      params.printStrategyParameterSetId,
      params.layerThickness,
    )

    if (printStrategy) {
      commit('setSelectedPrintStrategy', printStrategy)
    }
  },

  async updatePrintStrategyProcessParameterFile(
    { commit },
    params: { printStrategyId: number; siteId: number; processParameterFileId: number; layerThickness: number },
  ) {
    const printStrategy = await sitesApi.updatePrintStrategyProcessParameterFile(
      params.printStrategyId,
      params.siteId,
      params.processParameterFileId,
    )

    if (printStrategy) {
      commit('setSelectedPrintStrategy', printStrategy)
    }
  },

  async uploadProductionSet({ commit }, payload: UploadProductionSetPayload) {
    const printStrategy = await sitesApi.uploadProductionSet(payload)

    if (printStrategy) {
      commit('setSelectedPrintStrategy', printStrategy)
    }
  },

  async uploadParameterSet(
    { commit },
    payload: {
      printStrategyId: number
      file: File
      siteId: number
    },
  ) {
    const printStrategy = await sitesApi.uploadParameterSet(payload.printStrategyId, payload.file, payload.siteId)

    if (printStrategy) {
      commit('setSelectedPrintStrategy', printStrategy)
    }
  },

  async createPrintStrategy(
    {},
    payload: {
      machineConfigId: number
      materialId: number
    },
  ): Promise<PrintStrategyInfo> {
    return await sitesApi.createPrintStrategy(payload.machineConfigId, payload.materialId)
  },

  async updatePrintStrategyName({ commit }, payload: UpdatePrintStrategyNamePayload) {
    const printStrategy = await sitesApi.updatePrintStrategyName(payload.printStrategyId, payload.siteId, payload.name)

    if (!printStrategy) {
      return
    }

    commit('setSelectedPrintStrategy', printStrategy)

    return printStrategy
  },

  async checkPrintStrategyNameExists(_, payload: { printStrategyId: number; name: string }) {
    const isExists = await sitesApi.checkPrintStrategyNameExists(payload.printStrategyId, payload.name)

    if (isExists === undefined) {
      return
    }

    return isExists
  },

  async fetchPartParameterById({ commit }, payload: { partParameterId: number }) {
    const { partParameterId } = payload
    const data = await sitesApi.fetchPartParameterById(partParameterId)
    return data
  },

  async isPrintStrategyUsedByBuildPlan(_, printStrategyId: number) {
    return sitesApi.isPrintStrategyUsedByBuildPlan(printStrategyId)
  },

  async getBuildPlansRelatedToPrintStrategyCount(_, printStrategyId: number) {
    return sitesApi.getBuildPlansRelatedToPrintStrategyCount(printStrategyId)
  },

  async deletePrintStrategy(_, printStrategyId: number) {
    return sitesApi.deletePrintStrategy(printStrategyId)
  },

  async copyPrintStrategy(_, printStrategyId) {
    return sitesApi.copyPrintStrategy(printStrategyId)
  },

  async copyParameterSet({ commit }, payload: { parameterSetId: number; implicit: boolean }) {
    let newParameterSet: ParameterSet

    try {
      commit('setCopiedParameterSetId', payload.parameterSetId)
      const implicit = payload.implicit !== null && payload.implicit !== undefined ? payload.implicit : false
      newParameterSet = await sitesApi.copyParameterSet(payload.parameterSetId, implicit)
    } finally {
      commit('setCopiedParameterSetId', null)
    }

    commit('addAvailablePartParameter', newParameterSet)
    return newParameterSet
  },

  async copyProductionSet({ commit }, productionSetId: number) {
    let newProductionSet: any

    try {
      commit('setCopiedProductionSetId', productionSetId)
      newProductionSet = await sitesApi.copyProductionSet(productionSetId)
    } finally {
      commit('setCopiedProductionSetId', null)
    }

    commit('addAvailableProcessFile', newProductionSet)
    return newProductionSet
  },

  async fetchBinders({ commit }, payload: { machineConfigId: number; materialId: number; siteId: number }) {
    try {
      commit('setLoadingStatus', true)
      const binders = await sitesApi.fetchBinders(payload.materialId, payload.machineConfigId, payload.siteId)
      commit('setBinders', binders)
    } finally {
      commit('setLoadingStatus', false)
    }
  },

  async updateBinder({ commit }, payload: { siteMachineConfigMaterialBinderId: number; updateBinderDto: any }) {
    try {
      commit('setLoadingStatus', true)
      const binder = await sitesApi.updateBinder(payload.siteMachineConfigMaterialBinderId, payload.updateBinderDto)

      if (!binder) return
      commit('updateBinder', binder)
    } finally {
      commit('setLoadingStatus', false)
    }
  },

  async setBinder(
    { commit },
    payload: { printStrategyId: number; machineConfigMaterialBinderId: number; siteId?: number },
  ) {
    try {
      commit('setLoadingStatus', true)
      const printStrategy = await sitesApi.setBinder(
        payload.printStrategyId,
        payload.machineConfigMaterialBinderId,
        payload.siteId,
      )
      if (printStrategy) {
        commit('setSelectedPrintStrategy', printStrategy)
      }
    } finally {
      commit('setLoadingStatus', false)
    }
  },

  async fetchPrintStrategyAvailableMachineConfigMaterialBinders({ commit }, printStrategyId) {
    try {
      commit('setLoadingStatus', true)
      return await sitesApi.fetchPrintStrategyAvailableMachineConfigMaterialBinders(printStrategyId)
    } finally {
      commit('setLoadingStatus', false)
    }
  },
}
