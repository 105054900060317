var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"label-tool-container"},[_c('div',{staticClass:"created-label-tool-items"},[_c('v-expansion-panels',{staticClass:"wrapper-label--border",attrs:{"accordion":""},model:{value:(_vm.openedPanel),callback:function ($$v) {_vm.openedPanel=$$v},expression:"openedPanel"}},[_vm._l((_vm.buildPlanMarkingLabels),function(item,index){return _c('v-expansion-panel',{key:index,attrs:{"readonly":_vm.isLabelReadOnly}},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"","disabled":_vm.getVisualizationLoading},on:{"click":_vm.scrollToOpenedPanel,"mouseenter":function($event){return _vm.toggleHighlight(item, true)},"mouseleave":function($event){return _vm.toggleHighlight(item, false)}},scopedSlots:_vm._u([{key:"default",fn:function({ open }){return [_c('v-row',{staticClass:"px10",class:{
                'label-item-row': !open,
                'add-or-edit-label-border': open,
              },attrs:{"no-gutters":""}},[_c('div',{staticClass:"label-index"},[_vm._v(_vm._s(index + 1))]),(!open)?_c('v-tooltip',{attrs:{"open-delay":_vm.tooltipOpenDelay,"close-delay":"100","right":"","transition":"slide-y-transition","content-class":"label-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attr }){return [_c('v-col',_vm._g({staticClass:"label-name text-truncate",class:{
                      'add-or-edit-label': open,
                    },attrs:{"cols":"6"}},on),[_vm._v(" "+_vm._s(_vm._f("capitalizeFirstLetter")(item.style.text))+" "),(_vm.hasInsight(item.id))?_c('span',{staticStyle:{"position":"absolute","left":"220px"}},[_c('v-icon',{attrs:{"size":"16","color":"#D74E47"}},[_vm._v("mdi-close-circle")])],1):_vm._e()])]}}],null,true)},[_c('MarkingTooltipContent',{attrs:{"style-to-show":item.style}})],1):_c('v-col',{staticClass:"label-name text-truncate",class:{
                  'add-or-edit-label': open,
                },attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(_vm.$t('labelTool.editingLabel'))+" ")])],1)]}}],null,true)}),(_vm.isOpenLabelPanel(index))?_c('v-expansion-panel-content',[_c('UpsertMarkingLabel',{attrs:{"mode":_vm.labelEditMode,"label-to-update":item,"has-insight":_vm.hasInsight(item.id)}})],1):_vm._e()],1)}),(!_vm.isLabelReadOnly)?_c('v-expansion-panel',{class:{
          'new-label-expansion-panel': _vm.isAllPanelsCollapsed,
        }},[_c('v-expansion-panel-header',{class:{
            'new-label-panel-header': _vm.toggleNewLabelStyle,
          },attrs:{"expand-icon":"","disabled":_vm.getVisualizationLoading,"data-test":"new-label-button"},on:{"click":_vm.scrollToOpenedPanel},scopedSlots:_vm._u([{key:"default",fn:function({ open }){return [(!open)?_c('v-row',{staticClass:"justify-center px10 add-label-row",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(_vm.$t('labelTool.newLabel'))+" ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"append-button-icon",attrs:{"color":"#005eb8"}},[_vm._v("mdi-plus-box")])],1)],1):_c('v-row',{staticClass:"px10",class:{
                'label-item-row': !open,
                'add-or-edit-label-border': open,
              },attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"label-name",class:{
                  'add-or-edit-label': open,
                },attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$t('labelTool.addingLabel'))+" ")])],1)]}}],null,false,1846422497)}),(_vm.isOpenAddLabelPanel)?_c('v-expansion-panel-content',[_c('UpsertMarkingLabel',{attrs:{"mode":_vm.labelCreationMode}})],1):_vm._e()],1):_vm._e(),(_vm.isLabelReadOnly && !_vm.buildPlanMarkingLabels.length)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('noLabelsInBuildPlan'))+" ")]):_vm._e()],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }