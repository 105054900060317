
/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import Component from 'vue-class-component'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'
import VSelect from 'vuetify/lib/components/VSelect'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import { ISite } from '@/types/ISite'
import buildPlans from '@/api/buildPlans'
import { Watch } from 'vue-property-decorator'
import { JobStatusCode, JobType, FileTypes, IJob } from '@/types/PartsLibrary/Job'
import MarkingJobStatusModal from '@/components/layout/MarkingJobStatusModal.vue'
import ImageViewer from '@/components/controls/ImageViewer.vue'
import JobsViewer from '@/components/layout/JobsViewer.vue'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import { IMarkTemplate } from '../../../types/Marking/IMarkTemplate'
import { IMarkPatch } from '../../../types/Marking/IMarkPatch'
import { IMarkRecipe } from '../../../types/Marking/IMarkRecipe'
import { Vector3, Matrix } from '@babylonjs/core/Maths'
import messageService from '@/services/messageService'
import { PrintingTypes } from '@/types/IMachineConfig'
import { ILabel } from '@/types/Marking/ILabel'
import { isTabVisible } from '@/utils/common'
import { ClickOutsideMixin } from '@/components/layout/mixins/ClickOutsideMixin'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component({
  name: 'PrintTab',
  components: {
    ImageViewer,
    MarkingJobStatusModal,
    JobsViewer,
  },
})
export default class BuildPlanPrintTab extends ClickOutsideMixin implements IToolComponent {
  @buildPlansStore.Action('fetchPrintSites') fetchPrintSites: Function

  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter('getPrintSites') getPrintSites: ISite[]
  @buildPlansStore.Getter getBuildPlanProductionSetId: () => number
  @visualizationStore.Mutation('selectAndHighlightPart') selectAndHighlightPart: Function

  $refs!: {
    markingSelector: VSelect
    printSitesSelector: VSelect
  }

  currentExposureJob: any = null
  currentPrintJob: any = null
  latestCompletedSliceJob: any = null
  loadingData: boolean = false
  exposureJobStatus: string = ''
  printJobStatus: string = ''
  triggeringExposureJob: boolean = false
  triggeringMarkJob: boolean = false
  binderJetJob: any = null
  images: any[] = []
  loadingRaster: boolean = false
  rasterFilesCount: number = 0
  currentFileKey: string = ''
  fileList: [] = []
  triggeringPrintJob: boolean = false
  markFile: File = null
  markText: string = ''
  markJobStatusDialogVisible: boolean = false
  markJobs: any[] = []
  loadingBtn: boolean = false
  loadingStatusBtn: boolean = false
  markingFileList: any[] = []
  currentMarkingJob: any = null
  loadingMarkingRaster: boolean = false
  currentMarkingFileKey: string = ''
  markingImages: any[] = []
  selectedPrintSiteName: string = ''
  latestMarkJobCompleted: any = null
  printOptions: any[] = [
    { text: 'Print Part Only', value: 1 },
    { text: 'Print Part + Serial Number', value: 2 },
  ]
  printOption: number = 1
  printJobs: any = []
  markTemplates: IMarkTemplate[] = []
  markPatches: IMarkPatch[] = []
  headers = [
    { text: 'Part Name', value: 'partName' },
    { text: 'Serial', value: 'serial' },
  ]

  intervalId = null
  isMounted: boolean = false

  /**************************************
   * Generic tool method implementations
   **************************************/
  // need to mention these generic optional methods even if they are not implemented by the tool
  // due to TypeScript's weak type detection per https://stackoverflow.com/a/47930521
  clickOk?: () => void
  clickCancel?: () => void

  get isBinderJet() {
    return this.buildPlan.modality === PrintingTypes.BinderJet
  }

  get isReadyForPrint() {
    return (
      (this.isBinderJet
        ? this.currentExposureJob && this.currentExposureJob.code === JobStatusCode.COMPLETE
        : this.latestCompletedSliceJob) || this.currentPrintJob
    )
  }

  get isReadyForExposure() {
    if (!this.latestCompletedSliceJob) return true
    if (this.triggeringExposureJob) return true
    if (this.currentPrintJob) {
      if (!(this.currentPrintJob.code === 'COMPLETE' || this.currentPrintJob.code === 'ERROR')) {
        return true
      }
    }
    if (this.currentExposureJob) {
      if (!(this.currentExposureJob.code === 'COMPLETE' || this.currentExposureJob.code === 'ERROR')) {
        return true
      }
    }
    return false
  }

  get isMarkingDisabled() {
    if (this.isBinderJet) {
      if (!this.currentExposureJob) {
        return true
      }
      if (!(this.currentExposureJob.code === 'COMPLETE')) {
        return true
      }
    }
    if (this.triggeringMarkJob) return true
    if (this.currentMarkingJob) {
      if (!(this.currentMarkingJob.code === 'COMPLETE' || this.currentMarkingJob.code === 'ERROR')) {
        return true
      }
    }

    if (!(this.markPatches && this.markPatches.length > 0)) {
      return true
    }

    let isDisabled = false
    for (const markPatch of this.markPatches) {
      if (!(markPatch.runTimeContentText.length > 0)) {
        isDisabled = true
        break
      }
    }
    return isDisabled
  }

  get showMarkingTab() {
    if (this.markPatches && this.markPatches.length > 0) {
      return true
    }
    return false
  }

  get isPrintBtnDisabled() {
    return !this.isReadyForPrint || this.triggeringPrintJob || !this.selectedPrintSiteName.length
  }

  get exposureBtnText() {
    // TODO: since it is for sample UI not fallowing localization
    let expBtnText = 'Prepare for Print'
    if (this.currentExposureJob) {
      expBtnText = `Prepare for Print: ${this.currentExposureJob.code}`
    }
    return expBtnText
  }

  get markBtnText() {
    let markBtnText = 'Run Label'
    if (this.currentMarkingJob) {
      markBtnText = `Run Label: ${this.currentMarkingJob.code}`
    }
    return markBtnText
  }

  get printBtnText() {
    let printBtnText = 'Print'
    if (this.currentPrintJob) {
      printBtnText = `Print: ${this.currentPrintJob.code}`
    }
    return printBtnText
  }

  get isReadyForMarkRasterView() {
    return (
      this.loadingMarkingRaster ||
      this.markingFileList.length > 0 ||
      !this.currentMarkingJob ||
      [JobStatusCode.COMPLETE].indexOf(this.currentMarkingJob.code) === -1
    )
  }

  async triggerExposureJob() {
    try {
      this.triggeringExposureJob = true
      this.$emit('creatingJob')
      this.currentExposureJob = await buildPlans.createExposureJob(
        this.buildPlan.id,
        this.latestCompletedSliceJob.number,
      )
      this.currentExposureJob.code = this.currentExposureJob.status.code
      this.triggeringExposureJob = false
      this.$emit('jobCreated')
      this.triggerStatusCheck('currentExposureJob', this.currentExposureJob.number)
    } catch (error) {
      // error message reporting is handled at API call level now
      this.triggeringExposureJob = false
      this.$emit('jobCreated')
    }
  }

  async triggerPrintJob() {
    try {
      this.triggeringPrintJob = true
      let sliceOrExposureJobId
      let markJobId
      if (this.isBinderJet) {
        if (this.latestMarkJobCompleted && this.latestMarkJobCompleted.id) {
          sliceOrExposureJobId = this.latestMarkJobCompleted.id
        } else {
          sliceOrExposureJobId = this.currentExposureJob.id
        }
      } else {
        sliceOrExposureJobId = this.latestCompletedSliceJob.id
        if (this.latestMarkJobCompleted && this.latestMarkJobCompleted.id) {
          markJobId = this.latestMarkJobCompleted.id
        }
      }

      this.$emit('creatingJob')
      this.currentPrintJob = await buildPlans.createPrintJob({
        sliceOrExposureJobId,
        markJobId,
        buildPlanId: this.buildPlan.id,
        // hardcoding value unit production set feature enabled/new wireframes implemented
        productionSetId: this.getBuildPlanProductionSetId(),
        printSite: this.selectedPrintSiteName,
      })
      this.printJobs = [this.currentPrintJob]
      this.currentPrintJob.code = this.currentPrintJob.status.code
      this.triggerStatusCheck('currentPrintJob', this.currentPrintJob.number)
      this.triggeringPrintJob = false
      this.$emit('jobCreated')
    } catch (error) {
      // error message reporting is handled at API call level now
      this.triggeringPrintJob = false
      this.$emit('jobCreated')
    }
  }

  get isActiveMenu() {
    if (!this.isMounted) {
      return false
    }

    const isMarkingSelectorMenuActive =
      this.$refs.markingSelector.$refs &&
      this.$refs.markingSelector.$refs.menu &&
      this.$refs.markingSelector.$refs.menu.isActive

    const isPrintSitesSelectorMenuActive =
      this.$refs.printSitesSelector.$refs &&
      this.$refs.printSitesSelector.$refs.menu &&
      this.$refs.printSitesSelector.$refs.menu.isActive

    return isMarkingSelectorMenuActive || isPrintSitesSelectorMenuActive
  }

  @Watch('isActiveMenu')
  onIsMenuActiveChanged() {
    this.setListenerForClickOutside(this.isActiveMenu, this.closeAllPrintTabSelectorMenus)
  }

  closeAllPrintTabSelectorMenus() {
    this.$refs.markingSelector.$refs.menu.isActive = false
    this.$refs.printSitesSelector.$refs.menu.isActive = false
  }

  mounted() {
    this.loadData()
    // deep copy
    this.markTemplates = []
    this.initialisePatches()
    this.isMounted = true
  }

  destroyed() {
    clearInterval(this.intervalId)
  }

  initialisePatches() {
    this.markPatches = []
    this.buildPlan.buildPlanItems.forEach((item) => {
      const labels: ILabel[] = JSON.parse(JSON.stringify(item.labels)) // deep copy
      if (labels) {
        let iCounter = 0
        labels.forEach((label, index) => {
          if (label.isValid === undefined || label.isValid) {
            const patch: any = {}
            const markTemplate: any = {}

            patch.id = label.id
            patch.isValid = label.isValid
            patch.settingsIDForJson = markTemplate.uniqueID = iCounter
            patch.runTimeContentText = ''
            patch.orientation = label.orientation
            patch.textPointIn3D = label.orientation.origin

            markTemplate.fontName = label.style.fontFamily
            markTemplate.fontTargetSize = label.style.fontSize
            markTemplate.textContent = label.style.text
            markTemplate.textExtrusionAboveSurface = 0.1
            markTemplate.textExtrusionBelowSurface = 0.1

            if (this.buildPlan.modality === PrintingTypes.DMLM) {
              markTemplate.textExtrusionAboveSurface = label.style.textExtrusionHeight
              markTemplate.textBoolean = 0
            } else if (this.buildPlan.modality === PrintingTypes.BinderJet) {
              markTemplate.textExtrusionBelowSurface = label.style.textExtrusionHeight
              markTemplate.textBoolean = 1
            }

            // set default values
            markTemplate.allowFontSizeVariation = false
            markTemplate.fontMinSize = 1
            markTemplate.fontStyle = 0
            markTemplate.textHorizontalAlignment = 1
            markTemplate.textMarginSize = 0
            markTemplate.textVerticalAlignment = 1
            markTemplate.type = 1

            this.markPatches.push(patch as IMarkPatch)
            this.markTemplates.push(markTemplate as IMarkTemplate)
            iCounter = iCounter + 1
          }
        })
      }
    })
  }

  async loadRasterFile(jobNumber, context) {
    this.loadingRaster = true
    context.currentFileKey = await buildPlans.getJobOutputFiles(this.buildPlan.id, jobNumber)
    let fileList = await buildPlans.getS3ZipMetaInfo(context.currentFileKey)
    fileList = fileList.filter((fileName) => fileName.indexOf('.tif') > -1)

    fileList.sort((a, b) => {
      const aNum = parseInt(a.split('slice')[1].split('_')[0], 10)
      const bNum = parseInt(b.split('slice')[1].split('_')[0], 10)
      return aNum - bNum
    })
    context.fileList = fileList
    this.loadingRaster = false
  }

  async loadMarkingRasterFile(jobNumber, context) {
    this.loadingMarkingRaster = true
    const files = await buildPlans.getAllFiles(this.buildPlan.id)
    const fileKeys = await buildPlans.getJobOutputFiles(this.buildPlan.id, jobNumber)
    let arsfFileList = fileKeys.map((key) => {
      const arsfFile = files.find((file) => {
        if (file.name.startsWith('mark_') && file.key === key && file.fileType === FileTypes.ARSF) {
          return true
        }
      })
      if (arsfFile) {
        return arsfFile.key
      }
    })
    arsfFileList = arsfFileList.filter((element) => {
      return element !== undefined
    })
    context.currentMarkingFileKey = arsfFileList[0]
    let markingFileList = await buildPlans.getS3ZipMetaInfo(context.currentMarkingFileKey)
    markingFileList = markingFileList.filter((file: string) => {
      if (file && file.indexOf('.tif') > -1) {
        return true
      }
      return false
    })
    context.markingFileList = markingFileList
    this.loadingMarkingRaster = false
  }

  async updateImageData(fileName) {
    this.loadingRaster = true
    this.images = []
    const image = await buildPlans.getSpecificFileFromZipFile(this.currentFileKey, fileName)
    this.images.push({
      fileName,
      src: URL.createObjectURL(image),
      width: '200px',
      height: '200px',
    })
    this.loadingRaster = false
  }

  async updateMarkingImageData(fileName) {
    this.loadingMarkingRaster = true
    this.markingImages = []
    const image = await buildPlans.getSpecificFileFromZipFile(this.currentMarkingFileKey, fileName)
    this.markingImages.push({
      fileName,
      src: URL.createObjectURL(image),
      width: '200px',
      height: '200px',
    })
    this.loadingMarkingRaster = false
  }

  loadRaster() {
    if (this.currentExposureJob && this.currentExposureJob.code === JobStatusCode.COMPLETE) {
      this.loadRasterFile(this.currentExposureJob.number, this)
    }
  }
  loadMarkingRaster() {
    if (this.currentMarkingJob && this.currentMarkingJob.code === JobStatusCode.COMPLETE) {
      this.loadMarkingRasterFile(this.currentMarkingJob.number, this)
    }
  }

  async loadData() {
    this.loadingData = true
    const jobs = await buildPlans.getJobs(this.buildPlan.id)
    jobs.sort((a, b) => b.number - a.number)
    this.currentPrintJob = jobs.find((item) => item.jobType === JobType.PRINT)
    this.currentExposureJob = jobs.find((item) => item.jobType === JobType.EXPOSURE)
    this.currentMarkingJob = jobs.find((item) => item.jobType === JobType.MARK)
    this.latestMarkJobCompleted = jobs.find(
      (item) => item.jobType === JobType.MARK && item.code === JobStatusCode.COMPLETE,
    )
    this.printJobs = jobs.filter((item) => item.jobType === JobType.PRINT)

    this.latestCompletedSliceJob = jobs.find(
      (item) => item.jobType === JobType.SLICE && item.code === JobStatusCode.COMPLETE,
    )
    await this.fetchPrintSites()
    this.loadingData = false
    if (
      this.currentPrintJob &&
      [JobStatusCode.COMPLETE, JobStatusCode.ERROR].indexOf(this.currentPrintJob.code) === -1
    ) {
      this.triggerStatusCheck('currentPrintJob', this.currentPrintJob.number)
    }
    if (
      this.currentExposureJob &&
      [JobStatusCode.COMPLETE, JobStatusCode.ERROR].indexOf(this.currentExposureJob.code) === -1
    ) {
      this.triggerStatusCheck('currentExposureJob', this.currentExposureJob.number)
    }
    if (
      this.currentMarkingJob &&
      [JobStatusCode.COMPLETE, JobStatusCode.ERROR].indexOf(this.currentMarkingJob.code) === -1
    ) {
      this.triggerStatusCheck('currentMarkingJob', this.currentMarkingJob.number)
    }
  }

  async triggerStatusCheck(currentJob, jobNumber) {
    ;((context, bp) => {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }

      this.intervalId = setInterval(async () => {
        if (isTabVisible()) {
          const jobs = await bp.getJobs(context.buildPlan.id)
          context[currentJob] = jobs.find((item) => item.number === jobNumber)
          context.printJobs = jobs.filter((item) => item.jobType === JobType.PRINT)
          const job = context[currentJob]
          if (job && (job.code === JobStatusCode.COMPLETE || job.code === job.ERROR)) {
            clearInterval(this.intervalId)
          }
        }
      }, 3000)
    })(this, buildPlans)
  }

  async onFilePicked(markFile) {
    if (markFile) {
      this.markFile = markFile
      return
    }
  }

  getMaxCharacters(patch) {
    const template = this.markTemplates.find((item) => item.uniqueID === patch.uniqueID)
    return template.textContent.length
  }

  async runMarking() {
    this.loadingBtn = true
    this.markPatches.forEach((patch) => {
      const buildPlanItem = this.buildPlan.buildPlanItems.find((item) => item.id === patch.buildPlanItemId)
      const transformationMatrix = Matrix.FromArray(buildPlanItem.transformationMatrix).transpose()
      let normal = new Vector3(patch.orientation.normal.x, patch.orientation.normal.y, patch.orientation.normal.z)
      normal = Vector3.TransformNormal(normal, transformationMatrix)
      let origin = new Vector3(patch.orientation.origin.x, patch.orientation.origin.y, patch.orientation.origin.z)
      origin = Vector3.TransformCoordinates(origin, transformationMatrix)
      let xDirection = new Vector3(
        patch.orientation.xDirection.x,
        patch.orientation.xDirection.y,
        patch.orientation.xDirection.z,
      )
      xDirection = Vector3.TransformNormal(xDirection, transformationMatrix)
      let yDirection = new Vector3(
        patch.orientation.yDirection.x,
        patch.orientation.yDirection.y,
        patch.orientation.yDirection.z,
      )
      yDirection = Vector3.TransformNormal(yDirection, transformationMatrix)
      let textPointIn3D = new Vector3(patch.textPointIn3D.x, patch.textPointIn3D.y, patch.textPointIn3D.z)
      textPointIn3D = Vector3.TransformCoordinates(textPointIn3D, transformationMatrix)
      patch.orientation.normal = { x: normal.x, y: normal.y, z: normal.z }
      patch.orientation.xDirection = { x: xDirection.x, y: xDirection.y, z: xDirection.z }
      patch.orientation.yDirection = { x: yDirection.x, y: yDirection.y, z: yDirection.z }
      patch.orientation.origin = { x: origin.x, y: origin.y, z: origin.z }
      patch.textPointIn3D = { x: textPointIn3D.x, y: textPointIn3D.y, z: textPointIn3D.z }

      const uniqueID = patch.settingsIDForJson
      const template = this.markTemplates.find((item) => item.uniqueID === uniqueID)
      if (template) {
        if (template.type === 1) {
          const runTimeContentTextLength = patch.runTimeContentText.length
          const recipeContentLength = template.textContent.length
          if (runTimeContentTextLength > recipeContentLength) {
            patch.runTimeContentText = patch.runTimeContentText.substring(0, recipeContentLength)
          }
        } else {
          patch.runTimeContentText = template.textContent
        }
      }
    })
    const markRecipe: IMarkRecipe = {
      contentElements: [],
      listOfSettings: this.markTemplates,
      patches: this.markPatches,
    }
    try {
      this.$emit('creatingJob')
      this.triggeringMarkJob = true
      this.currentMarkingJob = await buildPlans.createMarkJob(this.buildPlan.id, markRecipe)
      this.triggeringMarkJob = false
      this.triggerStatusCheck('currentMarkingJob', this.currentMarkingJob.number)
    } catch (error) {
      this.triggeringMarkJob = false
      messageService.showErrorMessage(error.message)
    } finally {
      this.$emit('jobCreated')
      this.initialisePatches()
    }
    this.loadingBtn = false
  }

  closeMarkJobStatusDialog() {
    this.markJobStatusDialogVisible = false
  }

  showJobStatusModal() {
    this.loadingStatusBtn = true
    this.getMarkJobs()
      .then(() => {
        this.loadingStatusBtn = false
        this.markJobStatusDialogVisible = true
      })
      .catch((error) => {
        this.loadingStatusBtn = false
        this.markJobStatusDialogVisible = false
      })
  }

  async getMarkJobs() {
    await buildPlans.getJobs(this.buildPlan.id).then((jobs) => {
      if (jobs && jobs.length > 0) {
        const markJobs = jobs.filter((item) => item.jobType === JobType.MARK)
        this.markJobs = []
        for (const markJob of markJobs) {
          if (markJob.parameters) {
            this.markJobs.push(markJob)
          }
        }
        this.markJobs.sort((job1, job2) => {
          return job2.number - job1.number // descending order
        })
      }
    })
  }
}
