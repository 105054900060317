
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import { TableRow } from '@/types/Sites/TenantAdminContentTableRow'
import TenantAdminContentTableControls from '@/components/layout/admin/sites/TenantAdminContentTableControls.vue'
import { RawLocation } from 'vue-router'

@Component({
  components: { TenantAdminContentTableControls },
})
export default class TenantAdminRow extends Vue {
  hover: boolean = false

  @Prop({ default: () => null }) row: TableRow

  navigateTo(location: RawLocation) {
    // @ts-ignore
    this.$router.safePush(location)
  }
}
