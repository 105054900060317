
import Vue from 'vue'
import Component from 'vue-class-component'
import '@/assets/styles/home.scss'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { IUser } from '@/types/User/IUser'
import SearchField from '@/components/controls/Common/SearchField.vue'
import InviteUserModal from '@/components/layout/InviteUserModal.vue'
import AccountActivationModal from '@/components/layout/AccountActivationModal.vue'
import { Permission } from '@/constants'
import { isAuthorized } from '@/utils/user'
import messageService from '@/services/messageService'
import SortableHeader from '@/components/layout/SortableHeader.vue'
import { RouterNames, RouterPaths } from '@/router'

const userStore = namespace(StoresNamespaces.User)

const YES_VALUE = 'Yes'
const NO_VALUE = 'No'

@Component({
  components: {
    SearchField,
    SortableHeader,
    InviteUserModal,
    AccountActivationModal,
  },
})
export default class UserAdmin extends Vue {
  @userStore.Action fetchAllUsersForAdmin: () => Promise<IUser[]>
  @userStore.Action inviteUser: any
  @userStore.Action toggleUserAdmin: any

  @userStore.Getter getUserDetails: IUser
  @userStore.Getter getAllUsers: IUser[]

  isShownInviteUserModal: boolean = false
  isShownAccountActivationModal: boolean = false
  userIdToActivate: string = ''
  isAuthorizedToInviteUsers = false
  thisUserId = ''

  searchExpression = ''
  sortField = 'email'
  sortDirection = 1
  allUsers = []

  get adminDataAttributeName(): string {
    return 'data-is-admin'
  }

  async beforeMount() {
    await this.fetchAllUsersForAdmin()
    this.filterAndSort()
    const user = this.getUserDetails
    this.thisUserId = user.id
    this.isAuthorizedToInviteUsers = user && isAuthorized(user, [Permission.CreateUsers])
  }

  goToTenantAdmin() {
    // @ts-ignore
    this.$router.safePush(RouterPaths.TenantAdmin)
  }

  sortOutput(sortField?: string) {
    if (sortField) {
      if (this.sortField === sortField) {
        this.sortDirection = -this.sortDirection
      } else {
        this.sortField = sortField
      }
    }
    this.allUsers.sort((a, b) => {
      const aField = a[this.sortField] ? a[this.sortField].toString() : ''
      const bField = b[this.sortField] ? b[this.sortField].toString() : ''
      return aField.localeCompare(bField) * this.sortDirection
    })
  }

  filterOutput() {
    this.allUsers = this.allUsers.filter((user) => {
      return (
        (user.email && user.email.toLowerCase().includes(this.searchExpression.toLowerCase())) ||
        (user.firstName && user.firstName.toLowerCase().includes(this.searchExpression.toLowerCase())) ||
        (user.lastName && user.lastName.toLowerCase().includes(this.searchExpression.toLowerCase())) ||
        (user.company && user.company.toLowerCase().includes(this.searchExpression.toLowerCase())) ||
        (user.country && user.country.toLowerCase().includes(this.searchExpression.toLowerCase()))
      )
    })
  }

  filterAndSort() {
    this.allUsers = this.getAllUsers.filter((user) => !user.isBlocked)
    this.filterOutput()
    this.sortOutput()
  }

  onInviteUserClick() {
    this.isShownInviteUserModal = true
  }

  onExportUsersClick() {
    const csv = []
    // header row
    const headerCols = document.querySelectorAll('div.sortable-header')
    let currRow = []
    for (const col of headerCols) {
      currRow.push((col as any).innerText)
    }
    csv.push(currRow.join(','))
    // body rows
    const rows = document.querySelectorAll('ul.user-list li')
    for (const row of rows) {
      currRow = []
      const cols = row.querySelectorAll('div.col')

      for (const col of cols) {
        if (col.hasAttribute(this.adminDataAttributeName)) {
          const isAdminStr = col.getAttribute(this.adminDataAttributeName)
          currRow.push(isAdminStr === 'true' ? YES_VALUE : NO_VALUE)
        } else {
          currRow.push((col as any).innerText)
        }
      }

      csv.push(currRow.join(','))
    }

    const resultCSV = csv.join('\n')
    const csvFile = new Blob([resultCSV], { type: 'text/csv' })
    const downloadLink = document.createElement('a')
    downloadLink.download = 'user table.csv'
    downloadLink.href = window.URL.createObjectURL(csvFile)
    downloadLink.style.display = 'none'
    document.body.appendChild(downloadLink)
    downloadLink.click()
  }

  async sendUserInvite(email: string, inviteAsAdmin: boolean) {
    try {
      await this.inviteUser({ email, inviteAsAdmin })
      const message = this.$i18n.t('invitingUserSuccess') as string
      messageService.showSuccessMessage(message)
      this.closeInviteUserModal()
      await this.fetchAllUsersForAdmin()
      this.filterAndSort()
    } catch (error) {
      const message = `${this.$i18n.t('invitingUserError')} ${error.data}`
      messageService.showErrorMessage(message)
    }
  }

  showAccountActivationModal(user: IUser) {
    this.userIdToActivate = user.id
    this.isShownAccountActivationModal = true
  }

  setUserAdmin(email: string, setAsAdmin: boolean) {
    this.toggleUserAdmin({ email, setAsAdmin })
  }

  closeInviteUserModal() {
    this.isShownInviteUserModal = false
  }

  closeAccountActivationModal() {
    this.isShownAccountActivationModal = false
  }
}
