
import { Component, Vue, Watch } from 'vue-property-decorator'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'
import { IWarpingInfo } from '@/visualization/types/SimulationTypes'
import { debounce } from '@/utils/debounce'
import NumberField from '@/components/controls/Common/NumberField.vue'
import i18n from '@/plugins/i18n'

const visualizationStore = namespace(StoresNamespaces.Visualization)
const commonStore = namespace(StoresNamespaces.Common)

@Component({
  components: { NumberField },
})
export default class DistortionControl extends Vue {
  distortionOptions = [
    { value: 1, label: i18n.t('distortion.predicted') },
    { value: 0, label: i18n.t('distortion.nominal') },
    { value: -1, label: i18n.t('distortion.reversed') },
  ]
  setFactorDebounced = debounce(1000, this.setWarpingFactor)

  @visualizationStore.Getter('getWarpingInfo') getWarpingInfo: IWarpingInfo
  @visualizationStore.Getter('getVisualizationLoading') getVisualizationLoading: boolean
  @visualizationStore.Mutation('setWarpingParameters') setWarpingParameters: Function

  @commonStore.Getter tooltipOpenDelay: number

  setWarpingFactor(factor: number) {
    this.setWarpingParameters(factor)
  }

  setPredefined(factor: number) {
    this.setWarpingParameters(factor)
  }

  get warpingValidationMessage() {
    return {
      required: this.$t('distortion.validationMessage'),
    }
  }
}
