import { Component, Vue } from 'vue-property-decorator'
import { PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import { PartTypes } from '@/types/BuildPlans/IBuildPlan'

@Component
export default class BuildPlanPartMixin extends Vue {
  get formatUserRootLocation(): string {
    return `${this.$t('allFiles').toString().charAt(0).toUpperCase()}${this.$t('allFiles').toString().slice(1)}`
  }

  formatPartLocation(part: PartListItemViewModel): string {
    const parentPathLabel = part.parentFolderNames.length ? `/${part.parentFolderNames.join('/')}` : null

    if (part.partType === PartTypes.SinterPart) {
      return part.sinterPlanName
        ? `${this.formatUserRootLocation}${parentPathLabel ? parentPathLabel : ''}/${part.sinterPlanName}`
        : ''
    }

    if (part.partType === PartTypes.IbcPart) {
      return part.ibcPlanName
        ? `${this.formatUserRootLocation}${parentPathLabel ? parentPathLabel : ''}/${part.ibcPlanName}`
        : ''
    }

    return parentPathLabel ? `${this.formatUserRootLocation}${parentPathLabel}` : this.formatUserRootLocation.toString()
  }

  middleTruncate(originalName: string, nameElement: HTMLElement, parentElement: HTMLElement) {
    const parentWidth = Math.ceil(parentElement.getBoundingClientRect().width)
    const elWidth = Math.ceil(nameElement.getBoundingClientRect().width)

    if (elWidth === 0 || parentWidth === 0) {
      return ''
    }

    if (elWidth > parentWidth) {
      const str = originalName as string
      const chars = str.length
      const charSize = elWidth / chars
      const canFitChars = parentWidth / charSize
      const charsToDeleteFromEachSide = (chars - canFitChars + 5) / 2
      const endLeft = Math.floor(chars / 2 - charsToDeleteFromEachSide)
      const startRight = Math.ceil(chars / 2 + charsToDeleteFromEachSide)
      return `${str.substr(0, endLeft)}...${str.substr(startRight)}`
    }

    return originalName
  }
}
