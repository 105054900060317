import { Module } from 'vuex'
import { ISitesState } from '@/store/modules/sites/types'
import { IRootState } from '@/store/types'
import { actions } from '@/store/modules/sites/actions'
import { getters } from '@/store/modules/sites/getters'
import { mutations } from '@/store/modules/sites/mutations'

export const state: ISitesState = {
  breadcrumbs: null,
  isBreadcrumbsReady: false,
  inertGases: [],
  sites: [],
  selectedSite: null,
  machineTypes: [],
  selectedMachineType: null,
  machineMaterials: [],
  selectedMaterial: null,
  printStrategies: [],
  previousPath: null,
  printStrategy: null,
  printStrategyAdditionalInfo: {
    availablePartParameters: [],
    availableProcessFiles: [],
    partParametersInherentStrains: [],
  },
  printStrategyProcessParameterSolidLevel: null,
  partParameters: [],
  copiedPartParameterId: null,
  copiedProductionSetId: null,
  isLoading: false,
  siteMachineConfigMaterialBinders: [],
}

const namespaced: boolean = true

export const sites: Module<ISitesState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
