import { IAdapter } from '@/types/Common/IAdapter'
import { CostEvaluationResultViewModel } from '@/types/CostEstimations/ICostEstimation'
import average from '@/utils/arithmetic/average'
import { isNil } from '@/utils/common'
import { isNumber } from '@/utils/number'

export class BJResultAdapter implements IAdapter<CostEvaluationResultViewModel> {
  adapt(item: any): CostEvaluationResultViewModel {
    const result = new CostEvaluationResultViewModel()

    const {
      metalPowderCost,
      binderCost,
      cleanerCost,
      liquidWasteDisposalCost,
      energyCost,
      floorspaceCost,
      equipmentDepreciationCost,
    } = item.partCosts

    result.quantityOfMachinesRequired = Math.ceil(
      isNumber(item.productionFinancials.productionMachinesRequired)
        ? item.productionFinancials.productionMachinesRequired
        : item.productionFinancials.productionMachinesRequired._max,
    )

    // Build Time
    result.machineBuildTime = isNumber(item.buildOutputs.buildTime)
      ? item.buildOutputs.buildTime
      : item.buildOutputs.buildTime._value
    result.machineBuildTimeMin = isNumber(item.buildOutputs.buildTime)
      ? item.buildOutputs.buildTime
      : item.buildOutputs.buildTime._min
    result.machineBuildTimeMax = isNumber(item.buildOutputs.buildTime)
      ? item.buildOutputs.buildTime
      : item.buildOutputs.buildTime._max

    // TODO Need to add number of parts per build
    // result.partsPerBuild = ?

    result.complexMaterialCostMin =
      metalPowderCost._min + binderCost._min + cleanerCost._min + liquidWasteDisposalCost._min
    result.complexMaterialCostMax =
      metalPowderCost._max + binderCost._max + cleanerCost._max + liquidWasteDisposalCost._max
    result.complexMaterialCostAverage = result.complexMaterialCostMax

    const energyCostMin = isNumber(energyCost) ? energyCost : energyCost._min
    const energyCostMax = isNumber(energyCost) ? energyCost : energyCost._max
    const floorspaceCostMin = isNumber(floorspaceCost) ? floorspaceCost : floorspaceCost._min
    const floorspaceCostMax = isNumber(floorspaceCost) ? floorspaceCost : floorspaceCost._max

    result.consumablesMin = energyCostMin + floorspaceCostMin
    result.consumablesMax = energyCostMax + floorspaceCostMax
    result.consumablesAverage = average(result.consumablesMin, result.consumablesMax)

    const maintenanceCost = this.getMaintenanceCostProperty(item)

    if (isNil(maintenanceCost)) {
      throw new Error('Maintenance Cost not found in the calculation result')
    }

    result.maintenanceMin = isNumber(maintenanceCost) ? maintenanceCost : maintenanceCost._min
    result.maintenanceMax = isNumber(maintenanceCost) ? maintenanceCost : maintenanceCost._max
    result.maintenanceAverage = average(result.maintenanceMin, result.maintenanceMax)

    result.printerMin = isNumber(equipmentDepreciationCost) ? equipmentDepreciationCost : equipmentDepreciationCost._min
    result.printerMax = isNumber(equipmentDepreciationCost) ? equipmentDepreciationCost : equipmentDepreciationCost._max
    result.printerAverage = average(result.printerMin, result.printerMax)

    result.totalPrintProcessCostMin =
      result.complexMaterialCostMin + result.consumablesMin + result.maintenanceMin + result.printerMin
    result.totalPrintProcessCostMax =
      result.complexMaterialCostMax + result.consumablesMax + result.maintenanceMax + result.printerMax
    result.totalPrintProcessCostAverage = average(result.totalPrintProcessCostMin, result.totalPrintProcessCostMax)

    const { buildCosts } = item
    result.totalBuildCostMin = buildCosts.totalBoxBuildCost._min
    result.totalBuildCostMax = buildCosts.totalBoxBuildCost._max

    return result
  }

  private getMaintenanceCostProperty(item: Record<string, any>) {
    const { partCosts = {} } = item
    const { maintenanceCost, equipmentMaintenanceCost } = partCosts

    if (maintenanceCost !== undefined) {
      return maintenanceCost
    }

    if (equipmentMaintenanceCost !== undefined) {
      return equipmentMaintenanceCost
    }
  }
}
