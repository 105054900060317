import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { TableHeader } from '@/types/Sites/TenantAdminContentTableHeader'
import { TableRow } from '@/types/Sites/TenantAdminContentTableRow'
import { TableCell } from '@/types/Sites/TenantAdminContentTableCell'
import { SortOrders } from '@/types/SortModes'
import { MachineTypeDto } from '@/types/Sites/Site'
import StoresNamespaces from '@/store/namespaces'
import { compareProps } from '@/utils/array'
import { PrintingTypes } from '@/types/IMachineConfig'

const DEFAULT_SORT_ORDER = SortOrders.Ascending

const sitesStore = namespace(StoresNamespaces.Sites)

@Component
export class TenantAdminContentTableMixin extends Vue {
  @sitesStore.Getter getSelectedMachineType: MachineTypeDto

  headers: TableHeader[] = []
  rows: TableRow[] = []

  generateColumns(rowData): TableCell[] {
    return []
  }

  setupRows() {
    this.rows = []
  }

  setupHeaders() {
    this.headers = []
  }

  onSortChanged(header: TableHeader) {
    this.reassignHeaders(header)
    this.orderRows(header)
  }

  orderRows(header: TableHeader) {
    this.rows = this.rows.sort((a: TableRow, b: TableRow) => {
      const aProp = this.getCellByHeaderName(header.headerName, a)
      const bProp = this.getCellByHeaderName(header.headerName, b)
      return compareProps(aProp.value, bProp.value, 0, [header.sortOrder])
    })
  }

  reassignHeaders(header: TableHeader) {
    this.headers = this.headers.map((h: TableHeader) => {
      if (h.headerName === header.headerName) {
        if (h.isSortCriteria) {
          h.sortOrder = h.sortOrder === SortOrders.Ascending ? SortOrders.Descending : SortOrders.Ascending
        } else {
          h.isSortCriteria = true
          h.sortOrder = DEFAULT_SORT_ORDER
        }
        return h
      }
      h.isSortCriteria = false
      h.sortOrder = null
      return h
    })
  }

  generateRows(data: any[]): TableRow[] {
    return data.map((platform: any) => {
      return {
        cells: this.generateColumns(platform),
        canBeCopied: this.getSelectedMachineType && this.getSelectedMachineType.modality === PrintingTypes.DMLM,
        canBeDeleted: true,
        canBeEdited: false,
      }
    })
  }

  private getCellByHeaderName(headerName: string, row: TableRow): TableCell {
    return row.cells.find((c: TableCell) => c.cellName === headerName)
  }
}
