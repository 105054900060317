import { createGuid } from '@/utils/common'
import { ResultsManager, WsCommands } from '@/visualization/rendering/ResultsManager'
import { MeshHandler } from '@/visualization/rendering/MeshHandler'
import { IHandlerSettings } from '@/visualization/rendering/NominalGeometryManager'
import { Matrix, VertexData } from '@babylonjs/core'
import { PART_TYPE } from '@/constants'
import { ResultsManagerEvent } from '@/visualization/types/SimulationTypes'
import { DracoDecoder } from '@/visualization/components/DracoDecoder'

export class BatchGeometryHandler extends MeshHandler {
  protected readonly meshIds: string[]
  protected readonly partsMatricesMap: Map<string, { fileName; matrix }>
  protected readonly loadedMeshes: Map<string, { id; matrix: Matrix }>

  constructor(manager: ResultsManager, settings: IHandlerSettings) {
    super(manager, settings)
    this.partsMatricesMap = new Map<string, { fileName; matrix }>()
    this.loadedMeshes = new Map<string, { id; matrix: Matrix }>()
    if (settings.metadata) {
      this.processJobMetadata(settings.metadata)
    }
    this.meshIds = []
  }

  async loadMesh(makeVisible: boolean) {
    this.defaultVisibility = makeVisible

    const filesToLoad = Array.from(this.hanlderSettings.paths)

    while (filesToLoad.length !== 0) {
      const current = filesToLoad.shift() as string
      const metadata = this.partsMatricesMap.get(this.getMetadataKeyFromName(current))

      if (this.useLoadedGeometry(current, metadata)) {
        this.createInstanceFromLoadedMesh(metadata)
      } else {
        const fetchNominalFile = {
          id: createGuid(),
          name: WsCommands.GetGeometryFile,
          parameters: [current],
        }

        await this.streamData(fetchNominalFile).then((data) => {
          const decode = this.getDecodingFunction(current)
          decode(data).then((decompressed) => {
            this.createNewGeometry(decompressed, metadata)
          })
        })
      }
    }

    this.triggerEvent(ResultsManagerEvent.AdditionalGeometryDetected, {
      type: this.geometryType,
      loaded: true,
      visible: this.defaultVisibility,
    })

    this.refreshCameraView()
    this.manager.sliceManager.adjustSlicingPlane()
    if (this.onHandlerReady) {
      this.onHandlerReady()
      this.onHandlerReady = undefined
    }
  }

  clear() {
    this.meshIds.forEach((id) => {
      const mesh = this.manager.getScene.getMeshById(id)
      if (mesh) {
        this.manager.getScene.removeMesh(mesh)
        mesh.dispose()
      }
    })

    this.commandMap.clear()
    this.meshIds.splice(0, this.meshIds.length)
  }

  setMaterial(materialId: string) {
    if (!this.isReady) return
    const material = this.manager.getScene.getMaterialById(materialId)
    if (material) {
      this.meshIds.forEach((id) => {
        this.getMeshById(id).material = material
      })
    }
  }

  get visible() {
    if (!this.isReady) return false

    return this.getMeshById(this.meshIds[0]).visibility === 1
  }

  set visible(value: boolean) {
    if (!this.isReady) return

    this.meshIds.forEach((id) => {
      this.getMeshById(id).visibility = value ? 1 : 0
    })
  }

  get isReady() {
    if (!this.meshIds.length) return false

    this.meshIds.forEach((id) => {
      const mesh = this.getMeshById(id)
      if (!mesh || !mesh.isReady()) return false
    })

    return true
  }

  protected processJobMetadata(metadata) {
    metadata.forEach((itemMetadata) => {
      itemMetadata.geometryFileDataList.forEach((geometry) => {
        if (geometry.fileType === PART_TYPE) {
          const name = `i0_p${this.getCommonNamePart(geometry.meshDataFilename)}`
          const info = { fileName: geometry.originalFilename, matrix: itemMetadata.transformationMatrix }
          this.partsMatricesMap.set(name, info)
        }
      })
    })
  }

  protected getDecodingFunction(name: string) {
    return name.toLowerCase().endsWith('drc')
      ? DracoDecoder.Default.decodeMeshAsync.bind(DracoDecoder.Default)
      : DracoDecoder.Default.decodeMeshPreservingOrder.bind(DracoDecoder.Default)
  }

  protected useLoadedGeometry(currentPath: string, metadata: { fileName; matrix }) {
    return !currentPath.includes('ovhg_triangles') && metadata && this.loadedMeshes.get(metadata.fileName)
  }

  protected createInstanceFromLoadedMesh(metadata: { fileName; matrix }) {
    const loadedMeshData = this.loadedMeshes.get(metadata.fileName)
    const existingMesh = this.getMeshById(loadedMeshData.id)
    const instance = existingMesh.createInstance(createGuid())
    instance.isPickable = false

    const worldMatrix = loadedMeshData.matrix.multiply(Matrix.FromArray(metadata.matrix).transpose())
    instance.freezeWorldMatrix(worldMatrix)
  }

  protected createNewGeometry(vData: VertexData, metadata: { fileName; matrix }) {
    const mesh = this.createMesh(vData)
    mesh.id = createGuid()
    this.meshIds.push(mesh.id)

    if (metadata) {
      const invertedWorldMatrix = Matrix.FromArray(metadata.matrix).transpose().invert()
      this.loadedMeshes.set(metadata.fileName, { id: mesh.id, matrix: invertedWorldMatrix })
    }

    return mesh
  }

  protected getMetadataKeyFromName(name: string) {
    return name.substring(name.indexOf('i0_'), name.lastIndexOf('-'))
  }

  protected createdMeshReadyHandler(): void {
    this.renderHandlerFirstTime()
    this.refreshCameraView()
  }

  // The end of the nominal geometry file name and the corresponding entry in the process_parameters
  // is different so we need to exclude it from comparison
  private getCommonNamePart(input: string) {
    return input.substring(0, input.lastIndexOf('-'))
  }
}
