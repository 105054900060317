
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import StoresNamespaces from '@/store/namespaces'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemType } from '@/types/FileExplorer/ItemType'
import { IJob, JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import { isTabVisible } from '@/utils/common'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { PrintOrderJobStatusCode } from '@/types/PrintOrder/PrintOrderJobStatusCode'
import { jobTypeToNameForDashboard } from '@/utils/string'
import { debounce } from '@/utils/debounce'
import { TOOLTIP_OPEN_DELAY } from '@/constants'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const commonStore = namespace(StoresNamespaces.Common)
const partsStore = namespace(StoresNamespaces.Parts)

@Component
export default class ItemListRowBadges extends Vue {
  @fileExplorerStore.Action updateJobsDataByIds: (ids: string[]) => void
  @fileExplorerStore.Action toggleIsFavoriteItem: (id: string) => void

  @fileExplorerStore.Getter favoriteIdsSet: Set<string>
  @fileExplorerStore.Getter hasRunningJob: (id: string) => boolean
  @fileExplorerStore.Getter getRunningJobsByItemId: (id: string) => IJob[]
  @fileExplorerStore.Getter getFailedJobsByItemId: (id: string) => IJob[]

  @commonStore.Getter tooltipOpenDelay: number

  @partsStore.Getter partDefectsById: (id: string) => string[]
  @partsStore.Getter partById: (partId: string) => IPartDto

  @Prop({ required: true }) item: FileExplorerItem
  @Prop({ default: false }) hideFavorite: boolean

  jobStatuses = JobStatusCode
  favoriteIconHover: boolean = false
  intervalId = null
  showPartDefectsTooltip = false
  isMouseOverOnPartDefectsTooltip = false
  updatePartDefectsTooltipDebounced = debounce(TOOLTIP_OPEN_DELAY, this.updatePartDefectsTooltip)

  get isFavorite(): boolean {
    return this.favoriteIdsSet.has(this.item.id)
  }

  get favoriteIcon(): string {
    if (this.isFavorite) {
      return 'mdi-star'
    }
    return this.favoriteIconHover ? 'mdi-star' : 'mdi-star-outline'
  }

  get itemHasRunningJob(): boolean {
    return this.hasRunningJob(this.item.id)
  }

  get itemHasFailedJob(): boolean {
    return this.failedJobs.length > 0
  }

  get failedJobs(): IJob[] {
    const filteredJobs = this.getFailedJobsByItemId(this.item.id).filter(
      (job) => job.jobType !== JobType.PRINT && job.jobType !== JobType.MARK,
    )
    return filteredJobs
  }

  get runningJobs(): IJob[] {
    return this.getRunningJobsByItemId(this.item.id)
  }

  get partDefects(): string[] {
    return this.partDefectsById(this.item.id)
  }

  get isPartHasSheetBodies(): boolean {
    const part = this.partById(this.item.id)
    return part ? part.hasSheetBodies : null
  }

  mounted() {
    if (this.item.itemType === ItemType.Folder) {
      return
    }

    // add watcher if item type is not folder
    this.$watch('itemHasRunningJob', (value, old) => {
      if (!value) {
        return
      }
      // update if item has new jobs
      this.pollAllActiveJobs()
    })

    this.pollAllActiveJobs()
  }

  setMouseOverOnPartDefectsTooltip(isMouseOverOnPartDefectsTooltip: boolean) {
    this.isMouseOverOnPartDefectsTooltip = isMouseOverOnPartDefectsTooltip
    this.updatePartDefectsTooltipDebounced()
  }

  updatePartDefectsTooltip() {
    this.showPartDefectsTooltip = this.isMouseOverOnPartDefectsTooltip
  }

  beforeDestroy() {
    clearInterval(this.intervalId)
  }

  async pollAllActiveJobs() {
    if (this.runningJobs && this.runningJobs.length !== 0) {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }

      this.intervalId = setInterval(async () => {
        if (isTabVisible()) {
          const ids = this.runningJobs.map((job) => job.id)

          if (ids.length === 0) {
            clearInterval(this.intervalId)
            return
          }

          await this.updateJobsDataByIds(ids)
        }
      }, 7000)
      return
    }
  }

  jobType(job: IJob): string {
    return jobTypeToNameForDashboard(job.jobType)
  }

  status(job: IJob) {
    if (job.jobType !== JobType.PRINT) {
      return job.code
    }
    return PrintOrderJobStatusCode[job.code]
  }

  description(job: IJob) {
    const isFailedCompensateJob = job.jobType === JobType.ICOMPENSATE && job.code === JobStatusCode.ERROR
    if (isFailedCompensateJob) {
      const parsedDescription = this.parseJobDescription(job)
      if (!parsedDescription || !parsedDescription.message) {
        return job.description
      }
      return parsedDescription.message
    }
    return job.description
  }

  parseJobDescription(job: IJob) {
    const jsonMatch = job.description.match(/{[\s\S]+}/)

    if (jsonMatch) {
      try {
        const jsonObject = JSON.parse(jsonMatch[0])
        if (job.code === JobStatusCode.ERROR) {
          const { message, percentComplete } = jsonObject
          return {
            message,
            percentComplete,
          }
        }
      } catch (error) {
        console.error('Error parsing job description:', error)
        return null
      }
    }
    return null
  }
}
