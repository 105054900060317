/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/

import { Color3, InstancedMesh, Mesh, Quaternion, Vector3 } from '@babylonjs/core'
import { ClearanceManager } from '@/visualization/rendering/clearance/ClearanceManager'
import {
  CLEARANCE_ACTIVE_COLOR,
  CLEARANCE_INACTIVE_COLOR,
  CLEARANCE_LINE,
  CLEARANCE_MIN_DISPLAY_DISTANCE,
} from '@/constants'
import { v4 as uuid } from 'uuid'

export enum ClearanceModes {
  Mesh = 'Mesh',
  Environment = 'Environment',
  Duplicate = 'Duplicate',
}

export enum ClearanceTypes {
  Bodies = 'Bodies',
  Parts = 'Parts',
  Walls = 'Walls',
  PrintHeadLanes = 'PrintHeadLanes',
  Plate = 'Plate',
  Ceiling = 'Ceiling',
  DuplicateWrapper = 'DuplicateWrapper',
}

export interface ClearanceMetadata {
  type: ClearanceTypes
  bpItemId?: string
  geometryId?: string
  componentId?: string
  combinedId?: string
  referenceIds?: string[]
}

export interface DimensionBox {
  id: string
  text: string
  canvasOffset: {
    x: number
    y: number
  }
}

/**
 * Interface that contains info about clearance results
 */
export interface ClearanceResult {
  distance: number
  mode: ClearanceModes
  pointA: Vector3
  pointB: Vector3
}

export interface MeshClearanceResult extends ClearanceResult {
  bpItemIdA: string
  geometryIdA: string
  componentIdA: string
  bpItemIdB: string
  geometryIdB: string
  componentIdB: string
}

export interface EnvironmentClearanceResult extends ClearanceResult {
  bpItemId: string
  // geometryId: string
  // componentId: string
  referenceIds: string[]
}

export interface DuplicateWrapperClearanceResult extends ClearanceResult {
  tempCombinedId: string
  originalCombinedId: string
}

class DimensionLine {
  private readonly clearanceManager: ClearanceManager
  private start: InstancedMesh
  private end: InstancedMesh
  private tube: InstancedMesh

  constructor(
    clearance: Clearance,
    clearanceManager: ClearanceManager,
    sourceMeshes: { sourceSphereMesh: Mesh; sourceTubeMesh: Mesh },
    color: Color3,
  ) {
    this.clearanceManager = clearanceManager
    const { sourceSphereMesh, sourceTubeMesh } = sourceMeshes
    const name = sourceSphereMesh.name.substring(0, sourceSphereMesh.name.indexOf('_source'))
    this.tube = sourceTubeMesh.createInstance(name)
    this.tube.id = uuid()
    this.tube.isPickable = false
    this.tube.instancedBuffers.color = color
    this.tube.metadata = {
      clearance,
      itemType: sourceTubeMesh.metadata.itemType,
    }

    this.start = sourceSphereMesh.createInstance(name)
    this.start.id = uuid()
    this.start.isPickable = false
    this.start.instancedBuffers.color = color
    this.start.metadata = {
      clearance,
      itemType: sourceTubeMesh.metadata.itemType,
    }

    this.end = sourceSphereMesh.createInstance(name)
    this.end.id = uuid()
    this.end.isPickable = false
    this.end.instancedBuffers.color = color
    this.end.metadata = {
      clearance,
      itemType: sourceTubeMesh.metadata.itemType,
    }
  }

  set isVisible(isVisible: boolean) {
    this.tube.isVisible = isVisible
    this.start.isVisible = isVisible
    this.end.isVisible = isVisible
  }

  set color(color: Color3) {
    this.tube.instancedBuffers.color = color
    this.start.instancedBuffers.color = color
    this.end.instancedBuffers.color = color
  }

  set scale(scale: number) {
    this.start.scaling.setAll(scale)
    this.end.scaling.setAll(scale)
    this.tube.scaling.y = scale
    this.tube.scaling.z = scale
  }

  update(start: Vector3, end: Vector3) {
    this.start.position = start
    this.end.position = end
    this.tube.position = start

    this.tube.scaling.x = Vector3.Distance(start, end)
    const direction = end.subtract(start).normalize()
    const oldDirection = Vector3.RightReadOnly
    const rotationAxis = oldDirection.cross(direction)
    // Rotate tube instance place it between spheres
    if (rotationAxis.length() !== 0) {
      rotationAxis.normalize()
      const dotProd = Vector3.Dot(oldDirection, direction)
      const angle = Math.acos(dotProd / (oldDirection.length() * direction.length()))
      this.tube.rotationQuaternion = Quaternion.RotationAxis(rotationAxis, angle)
    } else {
      const isSameDirection = direction.equalsWithEpsilon(oldDirection)
      this.tube.rotationQuaternion = Quaternion.RotationAxis(
        Vector3.RightHandedForwardReadOnly,
        isSameDirection ? 0 : Math.PI,
      )
    }
  }

  setNewEnd(end: Vector3) {
    const start = this.start.position
    this.update(start, end)
  }

  addToPicker() {
    this.clearanceManager.setupForGpuPicker(this.tube)
    this.clearanceManager.setupForGpuPicker(this.start)
    this.clearanceManager.setupForGpuPicker(this.end)
    this.clearanceManager.getRenderScene.getGpuPicker().addPickingObjects([this.tube, this.start, this.end])
  }

  removeFromPicker() {
    this.clearanceManager.getRenderScene.getGpuPicker().removePickingObjects([this.tube, this.start, this.end])
  }

  dispose() {
    this.start.dispose()
    this.end.dispose()
    this.tube.dispose()
  }
}

export class DimensionLineContainer {
  private dimensionLine: DimensionLine
  private outlineDimensionLine: DimensionLine
  private sensitiveZone: DimensionLine

  constructor(
    clearance: Clearance,
    clearanceManager: ClearanceManager,
    mainLineName: string,
    outLineName: string,
    sensitiveLineName?: string,
  ) {
    this.dimensionLine = new DimensionLine(
      clearance,
      clearanceManager,
      clearanceManager.getSourceLineMeshes(mainLineName),
      CLEARANCE_INACTIVE_COLOR,
    )

    this.outlineDimensionLine = new DimensionLine(
      clearance,
      clearanceManager,
      clearanceManager.getSourceLineMeshes(outLineName),
      Color3.White(),
    )

    if (sensitiveLineName) {
      this.sensitiveZone = new DimensionLine(
        clearance,
        clearanceManager,
        clearanceManager.getSourceLineMeshes(sensitiveLineName),
        Color3.Red(),
      )
      this.sensitiveZone.addToPicker()
      this.sensitiveZone.isVisible = false
    }
  }

  public update(pointA: Vector3, pointB: Vector3) {
    this.dimensionLine.update(pointA, pointB)
    this.outlineDimensionLine.update(pointA, pointB)
    if (this.sensitiveZone) {
      this.sensitiveZone.update(pointA, pointB)
    }
  }

  public setNewEnd(end: Vector3) {
    this.dimensionLine.setNewEnd(end)
    this.outlineDimensionLine.setNewEnd(end)
    if (this.sensitiveZone) {
      this.sensitiveZone.setNewEnd(end)
    }
  }

  set isVisible(isVisible: boolean) {
    this.dimensionLine.isVisible = isVisible
    this.outlineDimensionLine.isVisible = isVisible
  }

  set color(color: Color3) {
    this.dimensionLine.color = color
  }

  set scale(scale: number) {
    this.dimensionLine.scale = scale
    this.outlineDimensionLine.scale = scale
    if (this.sensitiveZone) {
      this.sensitiveZone.scale = scale
    }
  }

  public dispose() {
    this.dimensionLine.dispose()
    this.outlineDimensionLine.dispose()
    if (this.sensitiveZone) {
      this.sensitiveZone.removeFromPicker()
      this.sensitiveZone.dispose()
    }
  }
}

/**
 * Class that contain clearance meshes and info
 */
export class Clearance {
  private readonly clearanceManager: ClearanceManager
  private readonly clearanceId: string

  private mainDimensionLine: DimensionLineContainer
  private secondaryDimensionLine: DimensionLineContainer
  private clearanceResult: ClearanceResult
  private isClearanceHidden: boolean

  private fromType: ClearanceTypes
  private toType: ClearanceTypes

  get result() {
    const { distance, pointA, pointB } = this.clearanceResult
    return {
      distance,
      pointA,
      pointB,
    }
  }

  get from(): ClearanceMetadata {
    switch (this.clearanceResult.mode) {
      case ClearanceModes.Mesh:
        const meshClearanceResult = this.clearanceResult as MeshClearanceResult
        return {
          type: this.fromType,
          bpItemId: meshClearanceResult.bpItemIdA,
          geometryId: meshClearanceResult.geometryIdA,
          componentId: meshClearanceResult.componentIdA,
        }
      case ClearanceModes.Environment:
        const environmentClearanceResult = this.clearanceResult as EnvironmentClearanceResult
        return {
          type: this.fromType,
          bpItemId: environmentClearanceResult.bpItemId,
          // geometryId: environmentClearanceResult.geometryId,
          // componentId: environmentClearanceResult.componentId,
        }
      case ClearanceModes.Duplicate:
        const duplicateWrapperClearanceResult = this.clearanceResult as DuplicateWrapperClearanceResult
        return {
          type: this.fromType,
          combinedId: duplicateWrapperClearanceResult.originalCombinedId,
        }
      default:
        return
    }
  }

  get to(): ClearanceMetadata {
    switch (this.clearanceResult.mode) {
      case ClearanceModes.Mesh:
        const meshClearanceResult = this.clearanceResult as MeshClearanceResult
        return {
          type: this.toType,
          bpItemId: meshClearanceResult.bpItemIdB,
          geometryId: meshClearanceResult.geometryIdB,
          componentId: meshClearanceResult.componentIdB,
        }
      case ClearanceModes.Environment:
        const environmentClearanceResult = this.clearanceResult as EnvironmentClearanceResult
        return {
          type: this.toType,
          referenceIds: environmentClearanceResult.referenceIds,
        }
      case ClearanceModes.Duplicate:
        const duplicateWrapperClearanceResult = this.clearanceResult as DuplicateWrapperClearanceResult
        return {
          type: this.toType,
          combinedId: duplicateWrapperClearanceResult.tempCombinedId,
        }
      default:
        return
    }
  }

  get id(): string {
    return this.clearanceId
  }

  get isNoClearance(): boolean {
    return this.clearanceResult.distance < CLEARANCE_MIN_DISPLAY_DISTANCE
  }

  get isHidden() {
    return this.isClearanceHidden
  }

  set isHidden(hide: boolean) {
    this.isClearanceHidden = hide
    if ((this.isNoClearance && !hide) || hide) {
      this.mainDimensionLine.isVisible = false
      if (this.secondaryDimensionLine) {
        this.secondaryDimensionLine.isVisible = false
      }
    } else {
      this.mainDimensionLine.isVisible = true
      if (this.secondaryDimensionLine) {
        this.secondaryDimensionLine.isVisible = true
      }
    }
  }

  constructor(
    clearanceManager: ClearanceManager,
    clearanceResult: ClearanceResult,
    from: ClearanceTypes,
    to: ClearanceTypes,
  ) {
    this.clearanceManager = clearanceManager
    this.clearanceId = uuid()
    this.mainDimensionLine = new DimensionLineContainer(
      this,
      clearanceManager,
      `${CLEARANCE_LINE}_source`,
      `${CLEARANCE_LINE}_outline_source`,
      `${CLEARANCE_LINE}_sensitive_source`,
    )
    this.updateClearance(clearanceResult, from, to)
    this.isClearanceHidden = false
  }

  public updateClearance(clearanceResult: ClearanceResult, from: ClearanceTypes, to: ClearanceTypes) {
    this.clearanceResult = clearanceResult
    this.fromType = from
    this.toType = to

    const { pointA, pointB } = clearanceResult
    this.mainDimensionLine.update(pointA, pointB)

    if (this.isNoClearance) {
      this.mainDimensionLine.isVisible = false
    } else {
      this.mainDimensionLine.isVisible = true
    }
  }

  public addSecondaryLine(pointA: Vector3, pointB: Vector3) {
    if (!this.secondaryDimensionLine) {
      this.secondaryDimensionLine = new DimensionLineContainer(
        this,
        this.clearanceManager,
        `${CLEARANCE_LINE}_leading_source`,
        `${CLEARANCE_LINE}_leading_outline_source`,
        `${CLEARANCE_LINE}_leading_sensitive_source`,
      )
    }

    this.updateSecondaryLine(pointA, pointB)
  }

  public updateSecondaryLine(pointA: Vector3, pointB: Vector3) {
    if (this.secondaryDimensionLine) {
      this.secondaryDimensionLine.update(pointA, pointB)
    }
  }

  public setInstancedBufferColor(showHighlight: boolean) {
    const color = showHighlight ? CLEARANCE_ACTIVE_COLOR : CLEARANCE_INACTIVE_COLOR
    this.mainDimensionLine.color = color
    if (this.secondaryDimensionLine) {
      this.secondaryDimensionLine.color = color
    }
  }

  public updateScaleFactor(scale: number) {
    this.mainDimensionLine.scale = scale
    if (this.secondaryDimensionLine) {
      this.secondaryDimensionLine.scale = scale
    }
  }

  dispose() {
    this.mainDimensionLine.dispose()
    if (this.secondaryDimensionLine) {
      this.secondaryDimensionLine.dispose()
    }
  }
}

/**
 * Interface to implement new clearance modes
 */
export interface IClearance {
  measureDistance(payload: object): void

  clearClearances(predicate?: (clearance: Clearance) => boolean): void

  hideClearances(hide: boolean, predicate?: (clearance: Clearance) => boolean): void

  dispose(): void

  getClearances(): Map<string, Clearance>
}
