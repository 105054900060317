export enum SimulationResultTypes {
  AppliedCompensation = 'resultTypeHelpMessages.appliedCompensation',
  Displacement = 'resultTypeHelpMessages.displacement',
  SurfaceDeviation = 'resultTypeHelpMessages.surfaceDeviation',
  Temperature = 'resultTypeHelpMessages.temperature',
  MaximumPrincipalStress = 'resultTypeHelpMessages.maximumPrincipalStress',
  MinimumPrincipalStress = 'resultTypeHelpMessages.minimumPrincipalStress',
  VonMisesStrain = 'resultTypeHelpMessages.vonMisesStrain',
  VonMisesStress = 'resultTypeHelpMessages.vonMisesStress',
  DisplacementPreviousIteration = 'resultTypeHelpMessages.displacementIteration',
  ResidualError = 'resultTypeHelpMessages.residualError',
  SelfIntersectingNodesOrig = 'resultTypeHelpMessages.selfIntersectingNodesOrig',
  SelfIntersectingNodesComp = 'resultTypeHelpMessages.selfIntersectingNodesComp',
  MaximumPrincipalStrain = 'resultTypeHelpMessages.maximumPrincipalStrain',
  MiddlePrincipalStrain = 'resultTypeHelpMessages.middlePrincipalStrain',
  MinimumPrincipalStrain = 'resultTypeHelpMessages.minimumPrincipalStrain',
  ElasticStrain = 'resultTypeHelpMessages.elasticStrain',
  IBCMagnitude = 'resultTypeHelpMessages.ibcMagnitude',
  IBCX = 'resultTypeHelpMessages.ibcX',
  IBCY = 'resultTypeHelpMessages.ibcY',
  IBCZ = 'resultTypeHelpMessages.ibcZ',
  IBCNormalSigned = 'resultTypeHelpMessages.ibcNormalSigned',
  IBCNormalUnsigned = 'resultTypeHelpMessages.ibcNormalUnsigned',
  Undefined = '',
}
