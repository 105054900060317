
import Component from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { RouterNames, RouterPaths } from '@/router'

import fileExplorer from '@/api/fileExplorer'
import partsService from '@/api/parts'
import variables from '@/assets/styles/variables.scss'
import Menu from '@/components/controls/Common/Menu.vue'
import ToggleButton from '@/components/controls/Common/ToggleButton.vue'
import ViewModesPanel from '@/components/controls/Common/ViewModesPanel.vue'
import BuildPlanDetails from '@/components/layout/buildPlans/BuildPlanDetails.vue'
import BuildPlanVariantsBar from '@/components/layout/buildPlans/BuildPlanVariantsBar.vue'
import ToggleDetailsPanelButton from '@/components/layout/FileExplorer/Details/ToggleDetailsPanelButton.vue'
import DetailsPanel from '@/components/layout/FileExplorer/DetailsPanel.vue'
import ModalsStateMixin from '@/components/layout/FileExplorer/Table/mixins/ModalsStateMixin'
import IBCPlanSidebar from '@/components/layout/IBCPlan/IBCPlanSidebar.vue'
import XRayWidget from '@/components/layout/buildPlans/simulate/XRayWidget.vue'
import { BodyTypeIcons, DEFAULT_ITEM_VERSION, ROOT_FOLDER_ID } from '@/constants'
import CommunicationService from '@/services/CommunicationService'
import { eventBus } from '@/services/EventBus'
import StoresNamespaces from '@/store/namespaces'
import { GeometryType, IBuildPlan, IIBCDisplayToolbarState, Visibility } from '@/types/BuildPlans/IBuildPlan'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { BrokerEvents } from '@/types/Common/BrokerEvents'
import { BrokerMessage } from '@/types/Common/BrokerMessage'
import { IFileDetails } from '@/types/Common/IFileDetails'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemPermissionsRole, Permission } from '@/types/FileExplorer/Permission'
import { ViewMode } from '@/types/FileExplorer/ViewMode'
import { IIBCPlan, IMeasurementWithProperties } from '@/types/IBCPlans/IIBCPlan'
import { IGPUInfo } from '@/types/IGPUInfo'
import { BuildPlanEvents } from '@/types/Label/BuildPlanEvents'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { IUser } from '@/types/User/IUser'
import { getGPUInfo } from '@/utils/gpu'
import { CategoryKind } from '@/visualization/types/SimulationTypes'
import { ContentViewModeTypes } from '@/visualization/types/ContentViewMode'
import { SceneMode } from '@/visualization/types/SceneTypes'
import ProgressModal from '@/components/modals/ProgressModal.vue'
import { ItemType } from '@/types/FileExplorer/ItemType'
import {
  getDefaultVariantIdFromVersionAndPath,
  isItemLockedForUser,
} from '@/utils/fileExplorerItem/fileExplorerItemUtils'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'

const TIMEOUT_FOR_UPDATE_GRID = 50
const REDIRECT_TIMEOUT_FOR_PERMISSIONS_ERROR = 2000

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const partsStore = namespace(StoresNamespaces.Parts)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const jobsStore = namespace(StoresNamespaces.Jobs)
const commonStore = namespace(StoresNamespaces.Common)
const userStore = namespace(StoresNamespaces.User)

@Component({
  components: {
    BuildPlanVariantsBar,
    IBCPlanSidebar,
    ToggleDetailsPanelButton,
    DetailsPanel,
    BuildPlanDetails,
    ViewModesPanel,
    XRayWidget,
    Menu,
    ToggleButton,
    ProgressModal,
  },
  beforeRouteUpdate(to, from, next) {
    setTimeout(() => {
      this.$refs.sidebar.onRouteChanged(to)
      next()
    }, 50)
  },
})
export default class EditIbcPlan extends Mixins(ModalsStateMixin) {
  @fileExplorerStore.Action fetchIBCPlan: (ibcPlanId: string) => Promise<IIBCPlan>
  @fileExplorerStore.Action getParentFolder: (itemId: string) => Promise<FileExplorerItem>

  @fileExplorerStore.Getter getViewMode: ViewMode
  @fileExplorerStore.Getter getDirectOrInheritedPermissionsByItemPath: (path: string) => Permission[]

  @fileExplorerStore.Mutation deleteItem: (itemId: string) => void

  @jobsStore.Action fetchPrintOrderById: (id: string) => Promise<PrintOrder>

  @partsStore.Action getPartById: (partId: string) => Promise<IPartDto>
  @partsStore.Action fetchAllParts: Function
  @partsStore.Action fetchAllSinterParts: Function
  @partsStore.Action fetchAllIbcParts: Function

  @commonStore.Getter tooltipOpenDelay: number

  @visualizationStore.Action generatePreviewCreationPromise: () => { promise: Promise<void>; done: Function }

  @visualizationStore.Getter isInitialized: boolean
  @visualizationStore.Getter getVisualizationLoading: boolean
  @visualizationStore.Getter getPreviewCreationPromise: { promise: Promise<void>; done: Function }
  @visualizationStore.Getter getBuildPlateVisible: boolean
  @visualizationStore.Getter getHandlerTogglesAvailable: boolean
  @visualizationStore.Getter getPartsVisible: boolean
  @visualizationStore.Getter getSupportsVisible: boolean

  @visualizationStore.Mutation dispose: Function
  @visualizationStore.Mutation updateItemPreview: Function
  @visualizationStore.Mutation resizeCanvas: Function
  @visualizationStore.Mutation init: Function
  @visualizationStore.Mutation hideGizmos: Function

  @visualizationStore.Action toggleIBCMeasurment: Function

  @buildPlansStore.Action getBuildPlanById: (id: string) => Promise<IBuildPlan>
  @buildPlansStore.Action fetchRelatedBuildPlanVariants: (variantId) => Promise<void>
  @buildPlansStore.Action changeIbcPartVisibility: (payload: {
    isPartVisible: boolean
    isPartAndSupportsVisible: boolean
  }) => void
  @buildPlansStore.Action changeIbcSupportsVisibility: (payload: {
    isSupportsVisible: boolean
    isPartAndSupportsVisible: boolean
  }) => void
  @buildPlansStore.Action changeIbcPartAndSupportsVisibility: (payload: {
    isPartVisible: boolean
    isSupportsVisible: boolean
    isPartAndSupportsVisible: boolean
  }) => void
  @buildPlansStore.Action changeIbcMeasurementsVisibility: (payload: {
    isInspTool: boolean
    isVisible: boolean
  }) => void
  @buildPlansStore.Action changeIbcBuildVolumeVisibility: (payload: { isVisible: boolean }) => void
  @buildPlansStore.Action changeIbcBuildPlateVisibility: (payload: { isVisible: boolean }) => void
  @buildPlansStore.Action loadMeasurementsForIBCPlan: Function
  @buildPlansStore.Action changeSceneReadOnly: Function
  @buildPlansStore.Action fetchInsightsByBuildPlanId: (payload: {
    buildPlanId: string
    changeState: boolean
  }) => Promise<IBuildPlanInsight[]>
  @buildPlansStore.Action unlockBuildPlanVariant: (id?: string) => Promise<void>
  @buildPlansStore.Action checkAccessForCreateVariants: (id: string) => Promise<void>

  @buildPlansStore.Getter ibcPlanNavigatedFrom: { name: RouterNames; params: { itemId?: string; id?: string } }
  @buildPlansStore.Getter getIsLoading: boolean
  @buildPlansStore.Getter isBuildPlanDisposing: boolean
  @buildPlansStore.Getter isLockedForUser: boolean
  @buildPlansStore.Getter isLockedForViewer: boolean
  @buildPlansStore.Getter getContentViewMode: ContentViewModeTypes
  @buildPlansStore.Getter('getIBCPlan') ibcPlan: IIBCPlan
  @buildPlansStore.Getter ibcDisplayToolbarStateByVariantId: (ibcPlanId: string) => IIBCDisplayToolbarState
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes

  @userStore.Getter('getUserDetails') getUserDetails: IUser

  @buildPlansStore.Mutation deselectIbcPlan: () => void
  @buildPlansStore.Mutation setIsBuildPlanDisposing: (value: boolean) => void
  @buildPlansStore.Mutation setBuildPlanDisposePromise: (value: { promise: Promise<void>; done: Function }) => void
  @buildPlansStore.Mutation loadIBCPlan: Function
  @buildPlansStore.Mutation setIBCPlanNavigatedFrom: (payload: {
    name: RouterNames
    params: {
      id?: string
      itemId?: string
    }
  }) => void
  @buildPlansStore.Mutation setIBCPlan: Function
  @buildPlansStore.Mutation setBuildPlan: Function
  @buildPlansStore.Mutation setIsReadOnly: Function
  @buildPlansStore.Mutation setIsLoading: (value: boolean) => void
  @buildPlansStore.Mutation setBuildPlanViewMode: Function
  @buildPlansStore.Mutation deselectBuildPlan: () => void
  @buildPlansStore.Mutation setDisplayToolbarStates: Function

  @visualizationStore.Mutation setHandlerVisibility: Function
  @visualizationStore.Mutation setShowHiddenPartsAsTransparent: Function
  @visualizationStore.Mutation showHiddenPartsTransparent: Function
  @visualizationStore.Mutation hideTransparentParts: Function

  ibcPlanMeasurementFile: IFileDetails = null

  isOpenDetails = true
  buildPlanNameTruncated = ''
  sliderPromise: { promise: Promise<void>; done: Function } = null
  overflowMenuIsShown: boolean = false
  undoTooltipText = ''
  redoTooltipText = ''
  connector: CommunicationService = null
  isFetching: boolean = true
  isIbcPlanReleased = false
  isIbcPlanCompletelyLoaded = false
  gpuInfo: IGPUInfo = null
  isViewer = false
  forceUnlockVariant = false

  unlockBuildPlanReference = this.unlockBuildPlanHandler.bind(this)

  $refs!: {
    ibcPlanNameField: Element
  }

  async beforeMount() {
    const ibcPlanId = this.$route.params.ibcPlanId

    const item = await this.fetchItemById(ibcPlanId)

    if (!item || item.itemType !== ItemType.IbcPlan) {
      setTimeout(() => {
        // @ts-ignore
        this.$router.safePush(RouterPaths.DefaultFileExplorer)
      }, REDIRECT_TIMEOUT_FOR_PERMISSIONS_ERROR)
      return
    }

    this.addItem(item)

    const [isLockedForUser, isLockedForViewer] = await Promise.all([
      this.checkVariantIsLockedForUser(ibcPlanId),
      this.checkForViewAccess(item),
      this.checkAccessForCreateVariants(ibcPlanId),
    ])

    this.isViewer = isLockedForViewer

    const ibcPlan = await this.getIBCPlan(ibcPlanId)
    if (!ibcPlan) {
      setTimeout(() => {
        // @ts-ignore
        this.$router.safePush(RouterPaths.DefaultFileExplorer)
      }, REDIRECT_TIMEOUT_FOR_PERMISSIONS_ERROR)
      return
    }

    const ibcPlanItem = this.getProductionIbcPlanItem(this.ibcPlan)

    const ibcPlanBuildPlan = await this.getBuildPlanById(ibcPlanItem.buildPlanId)

    if (!ibcPlanBuildPlan) {
      // Timeout is required so that the snackbar message is displayed before changing the route
      setTimeout(() => {
        // @ts-ignore
        this.$router.safePush(RouterPaths.DefaultFileExplorer)
      }, REDIRECT_TIMEOUT_FOR_PERMISSIONS_ERROR)
      return
    }

    this.setBuildPlan(ibcPlanBuildPlan)
    this.getIbcPlanMeasurementFile()
    await Promise.all([
      this.fetchPrintOrderById(ibcPlanItem.printOrderId),
      this.fetchRelatedBuildPlanVariants(ibcPlanId),
    ])

    this.isFetching = false

    // make current IBC variant active
    if (this.ibcPlan && !this.ibcPlan.isActiveVersion) {
      const relatedVariants = this.getBuildPlanVariants
      const activeVariant = relatedVariants.find((x) => x.isActiveVersion)
      let activeVariantId = null
      if (activeVariant) {
        activeVariantId = activeVariant.id
      } else {
        const defaultVariant = relatedVariants.find((x) => x.version === DEFAULT_ITEM_VERSION)
        activeVariantId = defaultVariant ? defaultVariant.id : null
      }

      if (activeVariantId && this.ibcPlan.id !== activeVariantId) {
        await this.switchBuildPlanActiveVersion({ newVersionId: this.ibcPlan.id, oldVersionId: activeVariantId })
      }

      if (!activeVariantId && this.ibcPlan.version !== DEFAULT_ITEM_VERSION) {
        await fileExplorer.setActiveVersion(this.ibcPlan.id)
      }
    }

    this.connector = CommunicationService.getConnector()
    this.connector.subscribe(BrokerEvents.MeasurementsSent, this.onSocketMessage)

    this.subscribeToEventBus()
    this.init({
      canvasId: 'visualization_canvas',
      sceneMode: SceneMode.BuildPlan,
      loadDefaultPlate: false,
    })

    this.isIbcPlanCompletelyLoaded = false

    if (!isLockedForViewer && !isLockedForUser) {
      if (!this.isIbcPlanReleased) {
        await this.lockBuildPlanVariant(ibcPlanId)
      }
    }

    if (!this.ibcPlanNavigatedFrom) {
      await this.initParentFolder()
    }

    this.setDisplayToolbarStates()

    // Should be checked on readOnly status is set
    this.setIsReadOnly({ value: true })
    this.changeSceneReadOnly()

    await Promise.all([this.fetchAllParts(), this.fetchAllSinterParts(), this.fetchAllIbcParts()])

    this.setBuildPlanViewMode(null)
    this.loadIBCPlan({ ibcPlan: this.ibcPlan })
  }

  mounted() {
    window.addEventListener('beforeunload', this.unlockBuildPlanReference)
  }

  async checkForViewAccess(item: FileExplorerItem): Promise<boolean> {
    await this.getItemPermissions(item.id)

    const permissions = this.getDirectOrInheritedPermissionsByItemPath(item.path)

    if (!permissions || !this.getUserDetails) {
      return false
    }

    const userPermission = permissions.find((permission) => permission.grantedTo === this.getUserDetails.id)

    return userPermission ? userPermission.role === ItemPermissionsRole.Viewer : false
  }

  async unlockBuildPlanHandler() {
    if (
      (!this.isLockedForViewer && !this.isLockedForUser) ||
      (this.forceUnlockVariant && !isItemLockedForUser(this.getBuildPlan, this.getUserDetails))
    ) {
      await this.unlockBuildPlanVariant(this.ibcPlan.id)
    }
  }

  subscribeToEventBus() {
    eventBus.$on(BuildPlanEvents.HideGizmo, this.hideGizmos)
    eventBus.$on(BuildPlanEvents.LoadBuildPlanLoadingProgressStarts, this.onLoadBuildPlanLoadingProgressStarts)
    eventBus.$on(BuildPlanEvents.LoadBuildPlanLoadingProgressUpdates, this.onLoadBuildPlanLoadingProgressUpdates)
    eventBus.$on(BuildPlanEvents.LoadBuildPlanLoadingProgressEnds, this.onLoadBuildPlanLoadingProgressEnds)
  }

  unsubscribeFromEventBus() {
    eventBus.$off(BuildPlanEvents.HideGizmo, this.hideGizmos)
    eventBus.$off(BuildPlanEvents.LoadBuildPlanLoadingProgressStarts, this.onLoadBuildPlanLoadingProgressStarts)
    eventBus.$off(BuildPlanEvents.LoadBuildPlanLoadingProgressUpdates, this.onLoadBuildPlanLoadingProgressUpdates)
    eventBus.$off(BuildPlanEvents.LoadBuildPlanLoadingProgressEnds, this.onLoadBuildPlanLoadingProgressEnds)
  }

  async initParentFolder() {
    let parentFolderId: string

    if (this.getRootItem) {
      parentFolderId = this.getRootItem.id
    } else {
      const parentFolder: FileExplorerItem = await this.getParentFolder(this.ibcPlan.id)
      parentFolderId = parentFolder ? parentFolder.id : null
    }

    this.setIBCPlanNavigatedFrom({
      name: RouterNames.FE_AllFiles,
      params: {
        itemId: parentFolderId || ROOT_FOLDER_ID,
      },
    })
  }

  async getIbcPlanMeasurementFile() {
    if (this.ibcPlan.measurements) {
      const files = await partsService.getFilesByItemId(this.ibcPlan.id)
      this.ibcPlanMeasurementFile = files.find((file) => file.key === this.ibcPlan.measurements[0].measurementVisFileId)
    } else {
      this.ibcPlanMeasurementFile = null
    }
  }

  async getIBCPlan(ibcPlanId: string): Promise<IIBCPlan> {
    const ibcPlan = await this.fetchIBCPlan(ibcPlanId)
    if (!ibcPlan) {
      return null
    }

    this.setIBCPlan(ibcPlan)
    return ibcPlan
  }

  async beforeDestroy() {
    this.isIbcPlanReleased = true

    this.setIBCPlanNavigatedFrom(null)

    if (this.connector) {
      this.connector.unsubscribe(BrokerEvents.MeasurementsSent, this.onSocketMessage)
    }

    if (
      (!this.isLockedForViewer && !this.isLockedForUser) ||
      (this.forceUnlockVariant && !isItemLockedForUser(this.getBuildPlan, this.getUserDetails))
    ) {
      await this.unlockBuildPlanVariant(this.ibcPlan.id)
    }

    if (!this.getVisualizationLoading && this.isIbcPlanCompletelyLoaded && this.isInitialized) {
      this.updatePreview()
    } else if (this.getPreviewCreationPromise) {
      this.getPreviewCreationPromise.done()
    }

    window.removeEventListener('beforeunload', this.updatePreview)
    window.removeEventListener('beforeunload', this.unlockBuildPlanReference)

    this.deleteItem(this.ibcPlan.id)
    this.deselectIbcPlan()
    this.deselectBuildPlan()
    this.unsubscribeFromEventBus()
  }

  @Watch('isInitialized')
  async onVisualizationInitializaed() {
    if (this.isInitialized) {
      this.changeBuildVolumeVisibility(this.ibcBuildVolumeVisibility)
    }
  }

  get toggleProduction() {
    return this.isOnLayoutMode ? this.changePartVisibility : this.toggleVisParts
  }

  get toggleSupports() {
    return this.isOnLayoutMode ? this.changeSupportsVisibility : this.toggleVisSupports
  }

  get gridColumns() {
    const dpWidth = variables.buildPlanDetailsPanelWidth
    const lsbWidth = variables.buildPlanLeftSidebarWidth

    const gridTemplateAreasValue = this.isOpenDetails
      ? '"header header header" "sidebar content details"'
      : '"header header" "sidebar content"'
    const gridTemplateColumnsValue = this.isOpenDetails ? `${lsbWidth} auto ${dpWidth}` : `${lsbWidth} auto`
    return `grid-template-areas: ${gridTemplateAreasValue}; grid-template-columns: ${gridTemplateColumnsValue};`
  }

  get detailsBtnXPosition() {
    const defaultRightPosition = variables.buildPlanDefDetailBtnPos
    const openedPanelRightPosition = variables.buildPlanOpenedDetailBtnPos
    const position = this.isOpenDetails ? openedPanelRightPosition : defaultRightPosition
    return `right: ${position};`
  }

  get ibcPlanName() {
    return (this.ibcPlan || {}).name || ''
  }

  get productionBodyIcon() {
    return BodyTypeIcons.production
  }

  get supportBodyIcon() {
    return BodyTypeIcons.support
  }

  get productionAndSupportBodyIcon() {
    return BodyTypeIcons.productionAndSupport
  }

  get measurementBodyIcon() {
    return BodyTypeIcons.measurement
  }

  get isOnLayoutMode() {
    return this.getContentViewMode === ContentViewModeTypes.Layout
  }

  get productionToggleState() {
    return this.isOnLayoutMode ? this.ibcPartVisibility : this.getPartsVisible
  }

  get supportToggleState() {
    return this.isOnLayoutMode ? this.ibcSupportsVisibility : this.getSupportsVisible
  }

  get isShowHideBlockShown(): boolean {
    return this.isOverflowMenuShown || this.isOnLayoutMode
  }

  get isBuildPlanVolumeCheckboxShown(): boolean {
    return this.isOnLayoutMode && !!this.ibcPlan
  }

  get isBuildPlateCheckboxShown(): boolean {
    return this.isOnLayoutMode && !!this.ibcPlan
  }

  get isOverflowMenuActive(): boolean {
    return (
      (this.isBuildPlanVolumeCheckboxShown && this.ibcBuildVolumeVisibility) ||
      (this.isBuildPlateCheckboxShown && this.ibcBuildPlateVisibility)
    )
  }

  get isOverflowMenuShown(): boolean {
    return this.isBuildPlanVolumeCheckboxShown || this.isBuildPlateCheckboxShown
  }

  get ibcDisplayToolbarState(): IIBCDisplayToolbarState {
    return this.ibcDisplayToolbarStateByVariantId(this.ibcPlan ? this.ibcPlan.id : null)
  }

  get ibcPartVisibility(): boolean {
    return this.ibcDisplayToolbarState.part
  }

  get ibcSupportsVisibility(): boolean {
    return this.ibcDisplayToolbarState.supports
  }

  get ibcPartAndSupportsVisibility(): boolean {
    return this.ibcDisplayToolbarState.partAndSupports
  }

  get isInspTool(): boolean {
    return this.getBuildPlanViewMode === ViewModeTypes.Inspections
  }

  get ibcMeasurmentsVisibility(): boolean {
    return this.isInspTool ? this.ibcDisplayToolbarState.inspToolMeasurements : this.ibcDisplayToolbarState.measurements
  }

  get ibcBuildVolumeVisibility(): boolean {
    return this.ibcDisplayToolbarState.buildVolume
  }

  get ibcBuildPlateVisibility(): boolean {
    return this.ibcDisplayToolbarState.buildPlate
  }

  toggleDetails() {
    this.isOpenDetails = !this.isOpenDetails
    // This timeout is needed to render the canvas after resizing grid and canvas
    setTimeout(() => {
      this.resizeCanvas()
    }, TIMEOUT_FOR_UPDATE_GRID)
  }

  onRequestClosePlan(options: { forceUnlockVariant: boolean }) {
    this.forceUnlockVariant = options.forceUnlockVariant
    this.onCloseClick()
  }

  async onCloseClick() {
    // Generate promise to await loading outdated files
    this.generatePreviewCreationPromise()

    if (!this.ibcPlanNavigatedFrom) {
      // @ts-ignore
      await this.$router.safePush(RouterPaths.DefaultFileExplorer)
      return
    }

    const { name, params } = this.ibcPlanNavigatedFrom

    // If the IBC was opened – back to its folder in Amp Navigation
    // If the IBC was created – back to the Print Order from which the IBC was created
    if (name === RouterNames.FE_AllFiles) {
      const parentFolderId = params.itemId
      const shouldCheckAccess = parentFolderId && parentFolderId !== ROOT_FOLDER_ID
      const hasAccess = shouldCheckAccess ? await fileExplorer.itemExistsForUser(parentFolderId) : true

      if (hasAccess && this.getViewMode === ViewMode.Folders) {
        // @ts-ignore
        await this.$router.safePush(this.ibcPlanNavigatedFrom)
      } else {
        // @ts-ignore
        await this.$router.safePush(this.RouterPaths.DefaultFileExplorer)
      }
    } else {
      // @ts-ignore
      await this.$router.safePush(this.ibcPlanNavigatedFrom)
    }

    this.setIBCPlanNavigatedFrom(null)
  }

  @Watch('ibcPlanName')
  onHeaderResize() {
    this.$nextTick(() => {
      this.buildPlanNameTruncated = this.middleTruncateTextForElement(this.ibcPlanName, this.$refs.ibcPlanNameField)
    })
  }

  @Watch('getBuildPlanViewMode')
  onChangeViewMode() {
    this.changeMeasurementsVisibility(this.ibcMeasurmentsVisibility)
  }

  onSocketMessage(brokerMessage: BrokerMessage) {
    switch (brokerMessage.event) {
      case BrokerEvents.MeasurementsSent:
        this.getIBCPlan(this.ibcPlan.id).then(() => {
          this.loadMeasurementsForIBCPlan(this.ibcPlan)
          this.getIbcPlanMeasurementFile()
        })
        break
    }
  }

  changePartVisibility(isVisible: boolean) {
    this.changeIbcPartVisibility({
      isPartVisible: isVisible,
      isPartAndSupportsVisible: this.ibcPartAndSupportsVisibility,
    })
  }

  changeSupportsVisibility(isVisible: boolean) {
    this.changeIbcSupportsVisibility({
      isSupportsVisible: isVisible,
      isPartAndSupportsVisible: this.ibcPartAndSupportsVisibility,
    })
  }

  changePartAndSupportsVisibility(isVisible: boolean) {
    this.changeIbcPartAndSupportsVisibility({
      isPartVisible: this.ibcPartVisibility,
      isSupportsVisible: this.ibcSupportsVisibility,
      isPartAndSupportsVisible: isVisible,
    })
  }

  isVisible(measurement: IMeasurementWithProperties) {
    return measurement.visibility === Visibility.Visible
  }

  changeMeasurementsVisibility(isVisible: boolean) {
    this.changeIbcMeasurementsVisibility({ isVisible, isInspTool: this.isInspTool })
  }

  changeBuildVolumeVisibility(isVisible: boolean) {
    this.changeIbcBuildVolumeVisibility({ isVisible })
  }

  changeBuildPlateVisibility(isVisible: boolean) {
    this.changeIbcBuildPlateVisibility({ isVisible })
  }

  @Watch('ibcPlan')
  async selectedBuildPlanChanged(ibcPlan: IIBCPlan): Promise<void> {
    // check if a variant was switched and isInitialized
    if (!ibcPlan || !this.isInitialized || this.$route.params.ibcPlanId === ibcPlan.id) return

    this.setIsLoading(true)

    // @ts-ignore
    this.$router.safePush({ params: { ibcPlanId: ibcPlan.id } })
    this.gpuInfo = getGPUInfo()

    await this.disposeIbcPlan()

    const ibcPlanItem = this.getProductionIbcPlanItem(ibcPlan)
    this.fetchPrintOrderById(ibcPlanItem.printOrderId)

    // Prevent loading of bp if edit view was closed before disposal was finished
    if (this.isIbcPlanReleased) {
      this.setIsLoading(false)
      return
    }

    this.init({
      canvasId: 'visualization_canvas',
      sceneMode: SceneMode.BuildPlan,
      loadDefaultPlate: false,
    })

    this.isIbcPlanCompletelyLoaded = false

    this.loadIBCPlan({ ibcPlan: this.ibcPlan })
  }

  @Watch('getIsLoading')
  async addPreviewUpdateEvent(isLoading: boolean) {
    if (isLoading) {
      window.removeEventListener('beforeunload', this.updatePreview)
    } else {
      this.isIbcPlanCompletelyLoaded = true
      window.addEventListener('beforeunload', this.updatePreview)
    }
  }

  private toggleVisParts(toggle: boolean) {
    this.setHandlerVisibility({ type: CategoryKind.PARTS, visible: toggle })
  }

  private toggleVisSupports(toggle: boolean) {
    this.setHandlerVisibility({ type: CategoryKind.SUPPORTS, visible: toggle })
  }

  private middleTruncateTextForElement(text: string, element: Element) {
    if (!element) {
      return text
    }

    const maxWidth = element.parentElement.offsetWidth
    const canvas = document.createElement('canvas')
    const elementStyle = window.getComputedStyle(element)
    const ctx = canvas.getContext('2d')
    ctx.font = elementStyle.font
      ? elementStyle.font
      : `${elementStyle.fontWeight} ${elementStyle.fontSize} / ${elementStyle.lineHeight} ${elementStyle.fontFamily}`

    if (ctx.measureText(text).width <= maxWidth) {
      return text
    }

    const delimiter = '...'
    const textLength = text.length
    const middle = Math.floor(textLength / 2)
    const tail = textLength % 2 === 0 ? null : text[textLength - 1]

    for (let i = middle; i > 0; i -= 1) {
      const truncatedText = tail
        ? `${text.substr(0, i)}${delimiter}${text.substr(textLength - i, i - 1)}${tail}`
        : `${text.substr(0, i)}${delimiter}${text.substr(textLength - i, i)}`

      if (ctx.measureText(truncatedText).width <= maxWidth) {
        return truncatedText
      }
    }

    return delimiter
  }

  private async disposeIbcPlan() {
    try {
      this.setIsBuildPlanDisposing(true)

      // Need to use promise to wait until dispose fully finished,
      // because visualization dispose mutation is asynchronous
      const disposePromise = this.createDisposePromise()
      this.setBuildPlanDisposePromise(disposePromise)
      this.dispose(disposePromise.done)

      await disposePromise.promise
    } finally {
      this.setIsBuildPlanDisposing(false)
    }
  }

  private createDisposePromise() {
    let done: Function
    const promise = new Promise<void>((resolve) => {
      done = resolve
    })

    return { done, promise }
  }

  private getProductionIbcPlanItem(ibcPlan: IIBCPlan) {
    return ibcPlan.ibcPlanItems.find((ibcPlanItem) => ibcPlanItem.geometryType === GeometryType.Production)
  }

  private updatePreview() {
    if (this.ibcPlan) {
      this.updateItemPreview({ itemId: this.ibcPlan.id })
    } else {
      this.getPreviewCreationPromise.done()
    }
  }
}
