import { Scene } from '@babylonjs/core/scene'

import { UPChecker } from '@/validators/UnconstrainedPart'
import { OZChecker } from './OverhangZones'

/**
 * Holds all checks (stability, overhang zones for now)
 */
export class Checker {
  upChecker: UPChecker
  ozChecker: OZChecker

  // Debugging flags
  debugStability: boolean

  constructor(scene: Scene, scale: number[]) {
    this.upChecker = new UPChecker(scene, scale)
    this.ozChecker = new OZChecker(scene)

    this.debugStability = this.upChecker.debugStability
  }

  /**
   * Runs all checks
   */
  public check() {
    let reports
    // Check items stability
    if (this.upChecker !== undefined) {
      reports = this.upChecker.checkStability()
    }

    // TODO: Tempoparily overhang zones are turned off
    // Check items overhang zones
    // if (this.ozChecker !== undefined) {
    //   this.ozChecker.checkOverhangZones()
    //   // TODO: Temporarily show overhang zones here
    //   // Later will be moved to a more suitable location
    //   this.ozChecker.showOverhangLoops()
    // }

    return reports
  }

  /**
   * Cleans up geometry data
   */
  public resetGeometryData() {
    // Reset items geometric data
    if (this.upChecker !== undefined) {
      this.upChecker.resetGeometryData()
    }
  }

  /**
   * Removes visualization of check results from the scene
   */
  public hideChecks() {
    if (this.upChecker !== undefined) {
      this.upChecker.clearFootprintAndCG()
    }

    if (this.ozChecker !== undefined) {
      this.ozChecker.clearOverhangZones()
    }
  }

  /**
   * Shows footprint and center of gravity
   * @param bpItemId Sinter plan item GUID
   */
  public showFootprintAndCG(bpItemId: string) {
    if (this.upChecker !== undefined) {
      this.upChecker.showFootprintAndCG(bpItemId)
    }
  }

  /**
   * Shows hulls of overhang zones
   */
  public showOverhangLoops() {
    if (this.ozChecker !== undefined) {
      this.ozChecker.showOverhangLoops()
    }
  }
}
