export enum ColorPalettes {
  BlueToRed,
  CoolToWarm,
}

const colorData = {
  [ColorPalettes.BlueToRed]: {
    clamping: true,
    colorSpace: 1,
    hSVWrap: false,
    allowDuplicateScalars: true,
    alpha: 1,
    vectorComponent: 0,
    vectorSize: -1,
    vectorMode: 0,
    indexedLookup: 0,
    nodes: [
      [0, 0, 0, 1, 0.5, 0],
      [1, 1, 0, 0, 0.5, 0],
    ],
  },
  [ColorPalettes.CoolToWarm]: {
    clamping: true,
    colorSpace: 3,
    hSVWrap: false,
    allowDuplicateScalars: true,
    alpha: 1,
    vectorComponent: 0,
    vectorSize: -1,
    vectorMode: 0,
    indexedLookup: 0,
    nodes: [
      [0, 0.23137254902, 0.298039215686, 0.752941176471, 0.5, 0],
      [0.5, 0.865, 0.865, 0.865, 0.5, 0],
      [1, 0.705882352941, 0.0156862745098, 0.149019607843, 0.5, 0],
    ],
  },
}

export { colorData }
