
import { get } from '@/api/http'
import { EndpointsUrls } from '@/configs/config'

import json from '../../build-info.json'

export default {
  data() {
    return {
      ampVersion: window.env.VUE_APP_AMP_VERSION,
      chartVersion: window.env.VUE_APP_CHART_VERSION,
      uiVersion: `${json.PRODUCT_VERSION}_${json.BUILD_ID} ${json.BUILD_DATE}`,
      apiVersion: null,
      apiDetailsVersion: null,
      migrationVersion: null,
    }
  },
  async created() {
    try {
      const apiJson = await get<any>(`${EndpointsUrls.Version}`)
      const detailsJson = await get<any>(`${EndpointsUrls.Version}/details`)
      this.apiVersion = `${apiJson.PRODUCT_VERSION}_${apiJson.BUILD_ID} ${apiJson.BUILD_DATE}`
      this.apiDetailsVersion = detailsJson
      const migrationVersion = await get<any>(`${EndpointsUrls.Version}/migrations`)
      this.migrationVersion = migrationVersion
    } catch (error) {
      throw error
    }
  },
}
