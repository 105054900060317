export enum OuterEvents {
  ConnectToService = 'startVisualization',
  FetchResults = 'refreshResults',
  ColoringModeChanged = 'coloringModeChanged',
  SimulationSlicerChanged = 'simulationSlicerChanged',
  SetResultsPathData = 'setResultsPathData',
  SetSimulationExportInfo = 'setSimulationExportInfo',
  SimulationTimestampChanged = 'simulationTimestampChanged',
  SetXrayView = 'setXrayView',
  SetActiveScene = 'setActiveScene',
  ShowBridgingElements = 'showBridgingElements',
  ShowNominalGeometry = 'showNominalGeometry',
  ExportCompensationFiles = 'exportCompensationFiles',
  ShowMeshing = 'showMeshing',
  StartDownwardRotation = 'startDownwardRotation',
  EndDownwardRotation = 'endDownwardRotation',
  ShowCompensatedGeometry = 'showCompensatedGeometry',
  ShowGreenCompensatedGeometry = 'showGreenCompensatedGeometry',
  ShowGreenNominalGeometry = 'showGreenNominalGeometry',
  LabelEnterManualPlacement = 'labelEnterManualPlacement',
  LabelExitManualPlacement = 'labelExitManualPlacement',
  LabelActivateManualPlacementUpdate = 'labelActivateManualPlacementUpdate',
  LabelDeactivateManualPlacementUpdate = 'labelDeactivateManualPlacementUpdate',
  LabelEnterBarPlacement = 'labelEnterBarPlacement',
  LabelExitBarPlacement = 'labelExitBarPlacement',
  LabelOrientationStartUpdate = 'labelOrientationStartUpdate',
  LabelOrientationEndUpdate = 'labelOrientationEndUpdate',
  LabelPlacementEnabled = 'labelPlacementEnabled',
  LabelPlacementDisabled = 'labelPlacementDisabled',
  HightlightDetailsInfo = 'hightlightDetailsInfo',
  SetAllStepsColorRange = 'changeColorRange',
  SetWarpingParameters = 'setWarpingParameters',
  SetHandlerVisibility = 'setHandlerVisibility',
  ClearanceChangeSelectionObserver = 'clearanceChangeSelectionObserver',
  SetDataJsonContent = 'setDataJsonContent',
}

export enum SceneType {
  Layout,
  Visualization,
}

export enum HighlightType {
  Info,
  Warning,
  Error,
}

export interface IRangeInfo {
  allSteps: {
    min: number
    max: number
  }
  currentStep: {
    min: number
    max: number
  }
  xray: {
    min: number
    max: number
  }
}

export enum GeometryAvailability {
  Available,
  NotAvailable,
  Undefined,
}
