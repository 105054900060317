import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { IRootState } from './types'

import { user } from './modules/user'
import { jobs } from './modules/jobs'
import { common } from './modules/common'
import { costEstimation } from './modules/costEstimation'
import { parts } from './modules/parts'
import { visualizationModule } from './modules/visualization'
import { buildPlans } from './modules/buildPlans'
import { fileExplorer } from './modules/fileExplorer'
import { notifications } from './modules/notifications'
import { announcements } from './modules/announcements'
import { commandManager } from './modules/commandManager'
import { cadHelper } from './modules/cadHelper'
import { sites } from '@/store/modules/sites'
import { label } from '@/store/modules/label'
import { optionalMultiItemCollector } from '@/store/modules/optionalMultiItemCollector'

import createVisualizationPlugin from '@/plugins/visualization'
import createSinglePartVisualizationPlugin from '@/plugins/singlePartVisualizationPlugin'
import actionsListener from '@/plugins/actionsListener'

Vue.use(Vuex)

const visualizationPlugin = createVisualizationPlugin()
const singlePartVisualizationPlugin = createSinglePartVisualizationPlugin()
const actionsListenerPlugin = actionsListener()

const store: StoreOptions<IRootState> = {
  plugins: [visualizationPlugin, singlePartVisualizationPlugin, actionsListenerPlugin],
  state: {
    version: '1.0.0',
  },

  modules: {
    user,
    jobs,
    common,
    costEstimation,
    parts,
    visualizationModule,
    buildPlans,
    fileExplorer,
    notifications,
    announcements,
    commandManager,
    cadHelper,
    sites,
    label,
    optionalMultiItemCollector,
  },
}

export default new Vuex.Store<IRootState>(store)
