
import Component from 'vue-class-component'
import { Mixins, Prop, Watch } from 'vue-property-decorator'
import { IBuildPlanItem, ISelectable } from '@/types/BuildPlans/IBuildPlan'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { ILayoutInsightDetails } from '@/types/Common/Insights'
import { SelectablePartInsightMixin } from '@/components/layout/buildPlans/mixins/SelectablePartInsightMixin'
import Vue from 'vue'
import { InsightMixin } from '@/components/layout/buildPlans/mixins/InsightMixin'
import { DisableableInsightMixin } from '@/components/layout/buildPlans/mixins/DisableableInsightMixin'
import { ClickOutsideMixin } from '@/components/layout/mixins/ClickOutsideMixin'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component({
  components: {},
})
export default class StabilityIssueByInsight extends Mixins(
  Vue,
  InsightMixin,
  DisableableInsightMixin,
  ClickOutsideMixin,
) {
  @Prop() insight: IBuildPlanInsight

  @visualizationStore.Getter isPartInstabilityShow: boolean
  @visualizationStore.Mutation showPartInstability: (bpItemId: string) => void
  @visualizationStore.Mutation hidePartInstability: () => void

  clickOnInsight(details: ILayoutInsightDetails, ev) {
    const canvasEl = document.getElementById('visualization_canvas')
    canvasEl.focus()
    this.showPartInstability(details.bpItemId)
  }

  customHandler(event: PointerEvent) {
    this.dialogOutsideClickHandler(
      event,
      'insights',
      'part-instability-insight',
      () => {
        /* update logic */
      },
      this.hidePartInstability,
    )
  }

  @Watch('isPartInstabilityShow')
  onPartStabilityShow() {
    if (!this.isPartInstabilityShow) {
      return
    }

    this.setListenerForClickOutside(this.isPartInstabilityShow, null, this.customHandler)
  }
}
