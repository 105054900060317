/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/

import { RenderScene } from '@/visualization/render-scene'
import {
  Color3,
  InstancedMesh,
  Mesh,
  MeshBuilder,
  Observer,
  PointerEventTypes,
  PointerInfo,
  Scene,
  StandardMaterial,
  TransformNode,
  UtilityLayerRenderer,
  Vector3,
  VertexBuffer,
} from '@babylonjs/core'
import {
  DimensionBox,
  IClearance,
  Clearance,
  ClearanceTypes,
  ClearanceModes,
  ClearanceMetadata,
} from '@/visualization/types/ClearanceTypes'
import { ClearanceMeshToMesh } from '@/visualization/rendering/clearance/ClearanceMeshToMesh'
import {
  COLOR_FOR_BODY,
  COLOR_FOR_FACE,
  COLOR_FOR_PART,
  CLEARANCE_LINE,
  CLEARANCE_LINE_MATERIAL,
  CLEARANCE_MIN_DISPLAY_DISTANCE,
  CLEARANCE_PLANE,
  CLEARANCE_PLANE_MATERIAL,
  CLEARANCE_SCALE_DIVIDER,
  MESH_RENDERING_GROUP_ID,
  MouseButtons,
  SELECTING_COLOR,
  SEMITRANSPARENCY_ALPHA,
  ITEM_ID_PREFIX_DELIMITER,
  HIGHLIGHTING_COLOR,
  PointerType,
} from '@/constants'
import store from '@/store'
import i18n from '@/plugins/i18n'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import { ClearanceEnvironment } from '@/visualization/rendering/clearance/ClearanceEnvironment'
import { IComponentMetadata, SceneItemType } from '@/visualization/types/SceneItemMetadata'
import { Face } from '@/visualization/components/DracoDecoder'
import { v4 as uuid } from 'uuid'
import { ISelectableNode, SelectionManager } from '@/visualization/rendering/SelectionManager'
import { ANNOUNCEMENT_HEIGHT } from '@/components/layout/buildPlans/marking/mixins/LabelTooltipMixin'
import { clamp } from '@/utils/number'
import { GeometryType, IDisplayToolbarState } from '@/types/BuildPlans/IBuildPlan'
import { ClearanceDuplicate } from '@/visualization/rendering/clearance/ClearanceDuplicate'

interface DimensionLineParams {
  name: string
  size: number
  renderingGroupId: number
  itemType: SceneItemType
}

export class ClearanceManager {
  private readonly lineSize = 1
  private readonly outlineSize = 2
  private readonly sensitiveZoneSize = 4
  private readonly leadingLineDelimiter = 1 / 3

  private readonly dimensionLinesParams: DimensionLineParams[] = [
    {
      name: `${CLEARANCE_LINE}_source`,
      size: this.lineSize,
      renderingGroupId: MESH_RENDERING_GROUP_ID + 1,
      itemType: SceneItemType.Clearance,
    },
    {
      name: `${CLEARANCE_LINE}_outline_source`,
      size: this.outlineSize,
      renderingGroupId: MESH_RENDERING_GROUP_ID,
      itemType: SceneItemType.Clearance,
    },
    {
      name: `${CLEARANCE_LINE}_sensitive_source`,
      size: this.sensitiveZoneSize,
      renderingGroupId: MESH_RENDERING_GROUP_ID + 1,
      itemType: SceneItemType.ClearanceSensitiveZone,
    },
    {
      name: `${CLEARANCE_LINE}_leading_source`,
      size: this.lineSize * this.leadingLineDelimiter,
      renderingGroupId: MESH_RENDERING_GROUP_ID + 1,
      itemType: SceneItemType.Clearance,
    },
    {
      name: `${CLEARANCE_LINE}_leading_outline_source`,
      size: this.outlineSize * this.leadingLineDelimiter,
      renderingGroupId: MESH_RENDERING_GROUP_ID,
      itemType: SceneItemType.Clearance,
    },
    {
      name: `${CLEARANCE_LINE}_leading_sensitive_source`,
      size: this.sensitiveZoneSize * this.leadingLineDelimiter,
      renderingGroupId: MESH_RENDERING_GROUP_ID + 1,
      itemType: SceneItemType.ClearanceSensitiveZone,
    },
  ]

  private renderScene: RenderScene
  private utilLayer: UtilityLayerRenderer
  private scene: Scene
  private utilityLayerScene: Scene

  private sourceLineMeshes: Map<string, { sourceSphereMesh: Mesh; sourceTubeMesh: Mesh }>

  private mPlaneMesh: Mesh
  private meshManager: MeshManager
  private selectionManager: SelectionManager
  private dimensionBoxObserver: Observer<PointerInfo>

  private allClearanceModes: Map<ClearanceModes, IClearance>

  get planeMesh(): Mesh {
    return this.mPlaneMesh
  }

  get utilScene(): Scene {
    return this.utilityLayerScene
  }

  get getRenderScene(): RenderScene {
    return this.renderScene
  }

  constructor(renderScene: RenderScene) {
    this.renderScene = renderScene
    this.scene = renderScene.getScene()
    this.utilLayer = new UtilityLayerRenderer(this.scene)
    this.utilityLayerScene = this.utilLayer.utilityLayerScene
    this.meshManager = this.renderScene.getMeshManager()
    this.selectionManager = this.renderScene.getSelectionManager()

    this.allClearanceModes = new Map<ClearanceModes, IClearance>()
    this.sourceLineMeshes = new Map<string, { sourceSphereMesh: Mesh; sourceTubeMesh: Mesh }>()

    this.mPlaneMesh = MeshBuilder.CreatePlane(
      CLEARANCE_PLANE,
      {
        sideOrientation: Mesh.DOUBLESIDE,
      },
      this.utilityLayerScene,
    )
    this.utilityLayerScene.removeMesh(this.mPlaneMesh)

    const planeMaterial = new StandardMaterial(CLEARANCE_PLANE_MATERIAL, this.utilityLayerScene)
    planeMaterial.emissiveColor = SELECTING_COLOR
    planeMaterial.alpha = SEMITRANSPARENCY_ALPHA
    this.mPlaneMesh.material = planeMaterial
    this.mPlaneMesh.isVisible = false

    const lineMaterial = new StandardMaterial(CLEARANCE_LINE_MATERIAL, this.utilityLayerScene)
    lineMaterial.emissiveColor = Color3.White()
    lineMaterial.disableLighting = true
    for (const dimensionLineParams of this.dimensionLinesParams) {
      const { name, size, renderingGroupId, itemType } = dimensionLineParams
      const pickingShader =
        itemType === SceneItemType.ClearanceSensitiveZone ? this.renderScene.getGpuPicker().pickingShader : undefined
      const sourceTubeMesh = MeshBuilder.CreateTube(
        name,
        {
          path: [Vector3.ZeroReadOnly, Vector3.RightReadOnly],
          radius: size / 2,
        },
        this.utilityLayerScene,
      )
      sourceTubeMesh.isVisible = false
      sourceTubeMesh.isPickable = false
      sourceTubeMesh.material = lineMaterial
      sourceTubeMesh.renderingGroupId = renderingGroupId
      sourceTubeMesh.registerInstancedBuffer(VertexBuffer.ColorKind, 3)
      sourceTubeMesh.metadata = {
        pickingShader,
        itemType,
        originalMaterial: lineMaterial,
      }
      this.utilityLayerScene.removeMesh(sourceTubeMesh)

      const sourceSphereMesh = MeshBuilder.CreateSphere(
        name,
        {
          diameter: size,
        },
        this.utilityLayerScene,
      )
      sourceSphereMesh.isVisible = false
      sourceSphereMesh.isPickable = false
      sourceSphereMesh.material = lineMaterial
      sourceSphereMesh.renderingGroupId = renderingGroupId
      sourceSphereMesh.registerInstancedBuffer(VertexBuffer.ColorKind, 3)
      sourceSphereMesh.metadata = {
        pickingShader,
        itemType,
        originalMaterial: lineMaterial,
      }
      this.utilityLayerScene.removeMesh(sourceSphereMesh)
      this.sourceLineMeshes.set(name, { sourceSphereMesh, sourceTubeMesh })
    }

    let cameraPos = this.renderScene.getActiveCamera().position.clone()
    this.dimensionBoxObserver = this.scene.onPointerObservable.add((evt) => {
      this.updateRubberBandScalingAndPlaneNormal()

      const dimensionBoxes = store.getters['visualizationModule/dimensionBoxes'] as DimensionBox[]
      if (
        dimensionBoxes.length > 0 &&
        (this.renderScene.cameraPositionChanged(cameraPos) || evt.type === PointerEventTypes.POINTERWHEEL)
      ) {
        // Update dimensions only when there is at least one dimension box
        // and camera position changed or user uses wheel
        cameraPos = this.renderScene.getActiveCamera().position.clone()
        setTimeout(() => this.updateDimensionBoxPosition(), 0)
      }
    })
  }

  public init() {
    const clearanceMeshToMesh = new ClearanceMeshToMesh(this.renderScene)
    this.allClearanceModes.set(ClearanceModes.Mesh, clearanceMeshToMesh)

    const clearanceEnvironment = new ClearanceEnvironment(this.renderScene)
    this.allClearanceModes.set(ClearanceModes.Environment, clearanceEnvironment)

    const clearanceDuplicate = new ClearanceDuplicate(this.renderScene)
    this.allClearanceModes.set(ClearanceModes.Duplicate, clearanceDuplicate)
  }

  public getSourceLineMeshes(name: string) {
    return this.sourceLineMeshes.get(name)
  }

  public getClearanceMode(clearanceMode: ClearanceModes) {
    return this.allClearanceModes.get(clearanceMode)
  }

  public hasClearanceMode(clearanceMode: ClearanceModes) {
    return this.allClearanceModes.has(clearanceMode)
  }

  /**
   * Updates rubberband line scaling and plane normal according to camera distance and position
   */
  public updateRubberBandScalingAndPlaneNormal() {
    if (this.hasClearanceMode(ClearanceModes.Mesh)) {
      const activeCamera = this.renderScene.getActiveCamera()
      const scaleRatio = (activeCamera.orthoTop - activeCamera.orthoBottom) / CLEARANCE_SCALE_DIVIDER

      const clearanceMeshToMesh = this.getClearanceMode(ClearanceModes.Mesh) as ClearanceMeshToMesh
      clearanceMeshToMesh.updateRubberBandScaleFactor(scaleRatio)

      const direction = this.renderScene.getActiveCamera().getForwardRay().direction
      clearanceMeshToMesh.updatePlaneNormal(direction)
    }
  }

  /**
   * Updates dimension box position and dimension line scaling according to camera distance
   */
  public updateDimensionBoxPosition() {
    const activeCamera = this.renderScene.getActiveCamera()
    const scaleRatio = (activeCamera.orthoTop - activeCamera.orthoBottom) / CLEARANCE_SCALE_DIVIDER

    const rect = this.scene.getEngine().getRenderingCanvasClientRect()
    // relates to extension-height property of v-app-bar for announcement
    const shiftY = store.getters['announcements/getAnnouncement'] ? ANNOUNCEMENT_HEIGHT : 0
    const dimensionBoxes: DimensionBox[] = []
    this.allClearanceModes.forEach((mode) => {
      const clearances = mode.getClearances()
      clearances.forEach((clearance) => {
        const distance = clearance.result.distance
        const center = Vector3.Center(clearance.result.pointA, clearance.result.pointB)
        const canvasOffset = this.meshManager.project3DPointOntoScreen(center, activeCamera)
        canvasOffset.x = clamp(canvasOffset.x, 0, rect.width)
        canvasOffset.y = clamp(canvasOffset.y, 0, rect.height)
        const dimensionBoxHeight = 34
        const dimensionBoxText =
          distance < CLEARANCE_MIN_DISPLAY_DISTANCE
            ? i18n.t('clearanceTool.noClearance').toString()
            : i18n.t('clearanceTool.dimensionBoxText', { distance: distance.toFixed(3) }).toString()

        if (!clearance.isHidden) {
          dimensionBoxes.push({
            id: clearance.id,
            text: dimensionBoxText,
            canvasOffset: {
              x: canvasOffset.x,
              y: canvasOffset.y - shiftY - dimensionBoxHeight,
            },
          })
        }

        clearance.updateScaleFactor(scaleRatio)
      })
    })

    store.commit('visualizationModule/changeDimensionBox', dimensionBoxes)
  }

  public highlight(items: ISelectableNode[], showHighlight: boolean) {
    const clearanceIds: string[] = []
    for (const item of items) {
      if (!item.body || !item.body.metadata || !item.body.metadata.clearance) {
        continue
      }

      const clearance: Clearance = item.body.metadata.clearance
      this.highlightPerType(clearance.from, showHighlight)
      this.highlightPerType(clearance.to, showHighlight)

      clearance.setInstancedBufferColor(showHighlight)
      if (showHighlight) {
        clearanceIds.push(clearance.id)
      }
    }

    store.commit('visualizationModule/setHighlightedClearanceIds', { clearanceIds })
  }

  public clearClearances(
    clearanceModePredicate?: (clearanceMode: ClearanceModes) => boolean,
    clearancePredicate?: (clearance: Clearance) => boolean,
  ) {
    if (!clearanceModePredicate) {
      this.allClearanceModes.forEach((item) => item.clearClearances(clearancePredicate))
      this.updateDimensionBoxPosition()
      this.renderScene.animate(true)

      return
    }

    for (const [clearanceModeType, clearanceMode] of this.allClearanceModes) {
      if (clearanceModePredicate(clearanceModeType)) {
        clearanceMode.clearClearances(clearancePredicate)
      }
    }

    this.updateDimensionBoxPosition()
    this.renderScene.animate(true)
  }

  public hideClearances(
    hide: boolean,
    clearanceModePredicate?: (clearanceMode: ClearanceModes) => boolean,
    clearancePredicate?: (clearance: Clearance) => boolean,
  ) {
    if (!clearanceModePredicate) {
      this.allClearanceModes.forEach((item) => item.hideClearances(hide, clearancePredicate))
      this.updateDimensionBoxPosition()
      this.renderScene.animate(true)

      return
    }

    for (const [clearanceModeType, clearanceMode] of this.allClearanceModes) {
      if (clearanceModePredicate(clearanceModeType)) {
        clearanceMode.hideClearances(hide, clearancePredicate)
      }
    }

    this.updateDimensionBoxPosition()
    this.renderScene.animate(true)
  }

  public setupForGpuPicker(mesh: InstancedMesh) {
    if (!mesh.sourceMesh.instancedBuffers.bColor) {
      mesh.sourceMesh.registerInstancedBuffer(COLOR_FOR_BODY, 3)
      mesh.sourceMesh.instancedBuffers.bColor = Color3.White()
    }

    if (!mesh.sourceMesh.instancedBuffers.pColor) {
      mesh.sourceMesh.registerInstancedBuffer(COLOR_FOR_PART, 3)
      mesh.sourceMesh.instancedBuffers.pColor = Color3.White()
    }

    if (!mesh.sourceMesh.instancedBuffers.fColor) {
      mesh.sourceMesh.registerInstancedBuffer(COLOR_FOR_FACE, 3)
      mesh.sourceMesh.instancedBuffers.fColor = Color3.White()
    }

    if (!mesh.sourceMesh.metadata) {
      mesh.sourceMesh.metadata = {}
    }

    const pickingColor = Color3.FromInts(
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
    )
    if (!mesh.metadata) {
      mesh.metadata = {}
    }

    mesh.metadata.pickingColor = pickingColor
    mesh.metadata.itemType = mesh.sourceMesh.metadata.itemType
    mesh.metadata.componentId = uuid()
    mesh.metadata.geometryId = uuid()
    mesh.metadata.faces = [
      new Face(
        0,
        '',
        Array.from(mesh.getIndices()),
        0,
        Color3.FromInts(Math.ceil(Math.random() * 255), Math.ceil(Math.random() * 255), Math.ceil(Math.random() * 255)),
      ),
    ]

    mesh.instancedBuffers.pColor = pickingColor
    mesh.instancedBuffers.bColor = pickingColor
    mesh.instancedBuffers.fColor =
      mesh.metadata.faces && mesh.metadata.faces.length
        ? mesh.metadata.faces[0].color
        : mesh.sourceMesh.metadata.faces[0].color
  }

  public dispose() {
    this.sourceLineMeshes.forEach((item) => {
      item.sourceSphereMesh.dispose(false, true)
      item.sourceTubeMesh.dispose(false, true)
    })
    this.scene.onPointerObservable.remove(this.dimensionBoxObserver)
    this.allClearanceModes.forEach((mode) => mode.dispose())
  }

  private highlightPerType(item: ClearanceMetadata, showHighlight: boolean) {
    const { type, bpItemId, geometryId, componentId, referenceIds, combinedId } = item
    switch (type) {
      case ClearanceTypes.Parts:
        {
          const part = this.meshManager.getBuildPlanItemMeshById(bpItemId)
          this.highlightPart(part, showHighlight, SELECTING_COLOR)
        }
        break
      case ClearanceTypes.Bodies:
        {
          const part = this.meshManager.getBuildPlanItemMeshById(bpItemId)
          const body = part
            .getChildMeshes()
            .find(
              (mesh) =>
                this.meshManager.isComponentMesh(mesh) &&
                mesh.metadata.componentId === componentId &&
                mesh.metadata.geometryId === geometryId,
            )

          if (body) {
            this.selectionManager.highlight([{ body }], showHighlight, undefined, SELECTING_COLOR)
          }
        }
        break
      case ClearanceTypes.Walls:
      case ClearanceTypes.PrintHeadLanes:
      case ClearanceTypes.Plate:
      case ClearanceTypes.Ceiling:
        const clearanceEnvironment = this.getClearanceMode(ClearanceModes.Environment) as ClearanceEnvironment
        clearanceEnvironment.highlightWalls(referenceIds, showHighlight)
        break
      case ClearanceTypes.DuplicateWrapper:
        {
          const splittedIds = combinedId.split(ITEM_ID_PREFIX_DELIMITER)
          for (const splittedId of splittedIds) {
            const part = this.meshManager.getBuildPlanItemMeshById(splittedId)
            this.highlightPart(part, showHighlight, HIGHLIGHTING_COLOR)
          }
        }
        break
      default:
        break
    }
  }

  private highlightPart(part: TransformNode, showHighlight: boolean, highlightColor: Color3) {
    const displaySettings = store.getters['buildPlans/displayToolbarStateByVariantId'](
      store.getters['buildPlans/getBuildPlan'].id,
    ) as IDisplayToolbarState

    part.getChildMeshes().forEach((mesh) => {
      const metadata = mesh.metadata as IComponentMetadata
      if (
        // Check if item is component
        this.meshManager.isComponentMesh(mesh) && // Check if item is production and production geometry is hidden
        ((metadata.bodyType === GeometryType.Production && !displaySettings.isShowingProductionGeometry) || // Or
          // Check if item is support and support geometry is hidden
          (metadata.bodyType === GeometryType.Support && !displaySettings.isShowingSupportGeometry) || // Or
          // Check if item is coupon and coupon geometry is hidden
          (metadata.bodyType === GeometryType.Coupon && !displaySettings.isShowingCouponGeometry))
      ) {
        this.meshManager.createTransparentClone(mesh as InstancedMesh, showHighlight)
      }
    })
    this.selectionManager.highlight([{ part }], showHighlight, undefined, highlightColor)
  }
}
