/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { AbstractMesh, Mesh, MeshBuilder, TransformNode, VertexData, InstancedMesh } from '@babylonjs/core/Meshes'
import { VertexBuffer } from '@babylonjs/core/Buffers'
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo'
import { Vector3, Matrix, Quaternion, Plane, Epsilon, Vector2 } from '@babylonjs/core/Maths'
import { FloatArray } from '@babylonjs/core/types'
import {
  MIN,
  MAX,
  GROUP_PARENT_MESH_NAME,
  INSIDE_MESH_NAME,
  BVH_BOX,
  ACCURACY,
  IDENTITY_MATRIX,
  KEEP_OUT_ZONE,
  BUILD_VOLUME_LIMIT_ZONE,
  LINE_SUPPORT,
  BUILD_CHAMBER_POLYLINES_NAME,
  MESH_HULL,
  WIREFRAME_MATERIAL_NAME,
  MESH_RENDERING_GROUP_ID,
  DEFAULT_COLOR,
  SEMI_TRANSPARENT_DEFAULT_MATERIAL_NAME,
  SEMI_TRANSPARENT_INSIDE_MATERIAL_NAME,
  DUPLICATE_WRAPPER,
  SEMI_TRANSPARENT_LABEL_MATERIAL_NAME,
  LABEL_INSIDE_MESH_NAME,
  SUPPORT_INSIDE_MESH_NAME,
  TRANSPARENT_MESH_ENDING,
  SEMI_TRANSPARENT_SUPPORT_INSIDE_MATERIAL,
  SEMI_TRANSPARENT_SUPPORT_MATERIAL,
  SUPPORT_COLOR,
  PRODUCTION_DEFAULT_COLOR,
  COUPON_DEFAULT_COLOR,
  IMPORTED_SUPPORT_DEFAULT_COLOR,
  HIGHLIGHTING_COLOR,
  SELECTING_COLOR,
  INSIDE_TRANSPARENT_MESH_ENDING,
  FAILED_DUPLCIATE_COLOR,
} from '@/constants'
import { IRenderable } from '../types/IRenderable'
import { Ray } from '@babylonjs/core/Culling/ray'
import { ConvexHull } from './ConvexHull'
import { Graph } from '../components/Graph'
import { BoundingBox2D, GeometryTypes } from '@/visualization/models/DataModel'
import {
  ISceneItemMetadata,
  SceneItemType,
  ITransparentCloneMetadata,
  IComponentMetadata,
} from '@/visualization/types/SceneItemMetadata'
import { PartDefect } from '@/types/Parts/IPartInsight'
import { PartTypes, GeometryType, IDisplayToolbarState, IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import { Camera } from '@babylonjs/core/Cameras'
import { RenderScene } from '../render-scene'
import { CrossSectionViewMode, SlicingViewMode } from '../infrastructure/ViewMode'
import { Face } from '../components/DracoDecoder'
import { eigs } from 'mathjs'
import { Color3 } from '@babylonjs/core/Maths/math.color'

import store from '@/store'
import { Material, Node } from '@babylonjs/core'
import { ItemType } from '@/types/FileExplorer/ItemType'

export class MeshManager {
  private renderScene: IRenderable

  constructor(renderScene: IRenderable) {
    this.renderScene = renderScene
  }

  isPartMesh(mesh: TransformNode) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.Part
  }

  isSamePartMesh(meshA: TransformNode, meshB: TransformNode) {
    if (!this.isPartMesh(meshA) || !this.isPartMesh(meshB)) {
      return false
    }

    return meshA.metadata.buildPlanItemId === meshB.metadata.buildPlanItemId
  }

  isSinterPartMesh(mesh: TransformNode) {
    const { partType } = (mesh.metadata as ISceneItemMetadata) || {}
    return partType ? partType === PartTypes.SinterPart : false
  }

  isIBCPartMesh(mesh: TransformNode) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).partType === PartTypes.IbcPart
  }

  isComponentMesh(mesh: TransformNode) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.Component
  }

  isSameComponentMesh(meshA: TransformNode, meshB: TransformNode) {
    if (!this.isComponentMesh(meshA) || !this.isComponentMesh(meshB)) {
      return false
    }

    const parentMeshA = meshA.parent as TransformNode
    const parentMeshB = meshB.parent as TransformNode

    if (!this.isSamePartMesh(parentMeshA, parentMeshB)) {
      return false
    }

    return (
      meshA.metadata.geometryId === meshB.metadata.geometryId &&
      meshA.metadata.componentId === meshB.metadata.componentId
    )
  }

  isComponentOfPart(component: TransformNode, parent: TransformNode): boolean {
    if (!this.isComponentMesh(component) || !this.isPartMesh(parent)) {
      return false
    }

    return component.parent === parent
  }

  isComponentsOfSamePart(componentA: TransformNode, componentB: TransformNode): boolean {
    if (!this.isComponentMesh(componentA) || !this.isComponentMesh(componentB)) {
      return false
    }

    return componentA.parent === componentB.parent
  }

  isComponentCloneMesh(mesh: TransformNode) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.ComponentClone
  }

  isSupportMesh(mesh: TransformNode) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.Support
  }

  isComponentSupportMesh(mesh: TransformNode) {
    return this.isComponentMesh(mesh) && (mesh.metadata as IComponentMetadata).bodyType === GeometryType.Support
  }

  isSupportCloneMesh(mesh: TransformNode) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.SupportSemitransparent
  }

  isOverhangSurface(mesh: TransformNode) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.OverhangSurface
  }

  isOverhangEdge(mesh: TransformNode) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.OverhangEdge
  }

  isOverhangVertex(mesh: TransformNode) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.OverhangVertex
  }

  isOverhangEdgeTube(mesh: TransformNode) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.OverhangEdgeTube
  }

  isOverhangMesh(mesh: TransformNode) {
    return this.isOverhangSurface(mesh) || this.isOverhangEdge(mesh) || this.isOverhangVertex(mesh)
  }

  isLabelMesh(mesh: AbstractMesh) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.Label
  }

  isLabelCloneMesh(mesh: AbstractMesh) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.LabelSemitransparent
  }

  isLabelCachedMesh(mesh: AbstractMesh) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.LabelCached
  }

  isLabelSensitiveZone(mesh: AbstractMesh) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.LabelSensitiveZone
  }

  isLabelOrigin(mesh: AbstractMesh) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.LabelOrigin
  }

  isDefectGroupNode(node: TransformNode) {
    return node.metadata && (node.metadata as ISceneItemMetadata).itemType === SceneItemType.DefectGroup
  }

  isDefectNode(node: TransformNode) {
    return node.metadata && (node.metadata as ISceneItemMetadata).itemType === SceneItemType.Defect
  }

  isPointDefect(mesh: AbstractMesh) {
    return mesh && mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.PointDefect
  }

  isLineDefect(mesh: AbstractMesh) {
    return mesh && mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.LineDefect
  }

  isLoopDefect(mesh: AbstractMesh) {
    return mesh && mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.LoopDefect
  }

  isFaceDefect(mesh: AbstractMesh) {
    return mesh && mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.FaceDefect
  }

  isMeshDefect(mesh: AbstractMesh) {
    return mesh && this.isComponentMesh(mesh) && mesh.metadata.hasDefects
  }

  isDefectMesh(mesh: AbstractMesh) {
    return this.isPointDefect(mesh) || this.isLineDefect(mesh) || this.isLoopDefect(mesh) || this.isFaceDefect(mesh)
  }

  isRawLabelMesh(mesh: AbstractMesh) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.RawLabel
  }

  isKeepOutZoneMesh(mesh: Node) {
    return mesh.name === KEEP_OUT_ZONE
  }

  isBuildVolumeLimitZoneMesh(mesh: Node) {
    return mesh.name === BUILD_VOLUME_LIMIT_ZONE
  }

  isSceneItem(mesh: AbstractMesh) {
    return mesh.metadata && mesh.metadata.itemType && mesh.metadata.itemType in SceneItemType
  }

  isSheetBody(mesh: AbstractMesh) {
    return mesh.metadata && mesh.metadata.geometryType === GeometryTypes.Sheet
  }

  isDuplicateWrapper(node: TransformNode) {
    return node.name === DUPLICATE_WRAPPER
  }

  isClearanceSensitiveZone(mesh: AbstractMesh) {
    return mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.ClearanceSensitiveZone
  }

  getMeshesWithBounds(meshes: TransformNode[]): AbstractMesh[] {
    const totalMeshes = []
    meshes.forEach((select) => {
      const children = select.getChildMeshes()
      if (children.length) {
        children.forEach((child) => {
          if (!child.getChildMeshes().length) {
            totalMeshes.push(child)
          }
        })
      } else {
        totalMeshes.push(select)
      }
    })
    return totalMeshes
  }

  getTotalBoundingInfo(
    totalMeshes: AbstractMesh[],
    recomputeWorldMatrix: boolean = true,
    world: boolean = false,
  ): BoundingInfo {
    let boundingInfo: BoundingInfo
    const meshes = totalMeshes.filter(
      (mesh) =>
        (mesh.metadata === null ||
          this.isComponentMesh(mesh) ||
          // this.isLabelMesh(mesh) ||
          mesh.name === LINE_SUPPORT ||
          mesh.name === BUILD_CHAMBER_POLYLINES_NAME) &&
        mesh.name !== BVH_BOX,
    )
    let min = new Vector3(MIN, MIN, MIN)
    let max = new Vector3(MAX, MAX, MAX)
    if (!world) {
      for (const mesh of meshes) {
        if (recomputeWorldMatrix) {
          mesh.computeWorldMatrix(true)
        }
        const meshBoundingInfo = mesh.getBoundingInfo()
        min = Vector3.Minimize(min, meshBoundingInfo.boundingBox.minimumWorld)
        max = Vector3.Maximize(max, meshBoundingInfo.boundingBox.maximumWorld)
      }
      boundingInfo = new BoundingInfo(min, max)
    } else {
      for (const mesh of meshes) {
        mesh.computeWorldMatrix(true)
        const m = mesh.getWorldMatrix()
        const vertices = mesh.getVerticesData('position')
        const v = new Vector3()

        if (!vertices) {
          continue
        }
        const end = vertices.length - 2
        for (let i = 0; i < end; i += 3) {
          v.copyFromFloats(vertices[i + 0], vertices[i + 1], vertices[i + 2])
          Vector3.TransformCoordinatesToRef(v, m, v)
          min.minimizeInPlace(v)
          max.maximizeInPlace(v)
        }
      }
      boundingInfo = new BoundingInfo(min, max)
    }
    return boundingInfo
  }

  mergeBoundingInfos(bInfos: BoundingInfo[]) {
    let result
    if (bInfos.length) {
      let minX = bInfos[0].minimum.x
      let minY = bInfos[0].minimum.y
      let minZ = bInfos[0].minimum.z
      let maxX = bInfos[0].maximum.x
      let maxY = bInfos[0].maximum.y
      let maxZ = bInfos[0].maximum.z
      for (const bInfo of bInfos) {
        if (bInfo.minimum.x < minX) minX = bInfo.minimum.x
        if (bInfo.minimum.y < minY) minY = bInfo.minimum.y
        if (bInfo.minimum.z < minZ) minZ = bInfo.minimum.z
        if (bInfo.maximum.x > maxX) maxX = bInfo.maximum.x
        if (bInfo.maximum.y > maxY) maxY = bInfo.maximum.y
        if (bInfo.maximum.z > maxZ) maxZ = bInfo.maximum.z
      }
      result = new BoundingInfo(new Vector3(minX, minY, minZ), new Vector3(maxX, maxY, maxZ))
    } else {
      result = new BoundingInfo(Vector3.Zero(), Vector3.Zero())
    }

    return result
  }

  getPartHullBInfo(node: TransformNode) {
    let min = new Vector3(MIN, MIN, MIN)
    let max = new Vector3(MAX, MAX, MAX)

    if (node.metadata && node.metadata.hull && node.metadata.hull.hullPoints && node.metadata.hull.hullPoints.length) {
      const hull = node.metadata.hull as ConvexHull
      const hullBBox = hull.computeBBox(node.getWorldMatrix())

      min = Vector3.Minimize(min, hullBBox.minimumWorld)
      max = Vector3.Maximize(max, hullBBox.maximumWorld)
    }

    const children = node.getChildTransformNodes(false, (m) => {
      if (this.isPartMesh(m as TransformNode)) {
        return m.metadata && m.metadata.hull && m.metadata.hull.hullPoints && m.metadata.hull.hullPoints.length
      }
    })
    for (const child of children) {
      const hull = child.metadata.hull as ConvexHull
      const hullBBox = hull.computeBBox(child.getWorldMatrix())
      min = Vector3.Minimize(min, hullBBox.minimumWorld)
      max = Vector3.Maximize(max, hullBBox.maximumWorld)
    }

    return new BoundingInfo(min, max)
  }

  getHullBInfo(node: TransformNode, itemType?: SceneItemType) {
    let min = new Vector3(MIN, MIN, MIN)
    let max = new Vector3(MAX, MAX, MAX)

    if (node.metadata && node.metadata.hull && node.metadata.hull.hullPoints && node.metadata.hull.hullPoints.length) {
      const hull = node.metadata.hull as ConvexHull
      const hullBBox = hull.computeBBox(node.getWorldMatrix())

      min = Vector3.Minimize(min, hullBBox.minimumWorld)
      max = Vector3.Maximize(max, hullBBox.maximumWorld)
    }

    const children = node.getChildTransformNodes(false, (m) => {
      return (
        m.getChildMeshes().length &&
        m.metadata &&
        m.metadata.hull &&
        m.metadata.hull.hullPoints &&
        m.metadata.hull.hullPoints.length &&
        (!itemType || (itemType && m.metadata.itemType === itemType))
      )
    })
    for (const child of children) {
      const hull = child.metadata.hull as ConvexHull
      const hullBBox = hull.computeBBox(child.getWorldMatrix())

      min = Vector3.Minimize(min, hullBBox.minimumWorld)
      max = Vector3.Maximize(max, hullBBox.maximumWorld)
    }

    return new BoundingInfo(min, max)
  }

  getBuildPlanItemMeshById(buildPlanItemId: string) {
    if (!buildPlanItemId) {
      return null
    }

    return this.renderScene.getSceneMetadata().buildPlanItems.get(buildPlanItemId)
  }

  getBuildPlanItemMeshByChild(childMesh: TransformNode) {
    let root = childMesh

    while (!this.isPartMesh(root) && root.parent != null && root.parent.name !== GROUP_PARENT_MESH_NAME) {
      root = root.parent as TransformNode
    }

    return root
  }

  getComponentMesh(componentId: string, geometryId: string, buildPlanItemId?: string) {
    let componentMesh
    if (buildPlanItemId) {
      const bpItemMesh = this.getBuildPlanItemMeshById(buildPlanItemId)
      componentMesh = bpItemMesh
        .getChildMeshes()
        .find(
          (mesh) =>
            this.isComponentMesh(mesh) &&
            mesh.metadata.componentId === componentId &&
            mesh.metadata.geometryId === geometryId,
        )
    } else {
      componentMesh = this.renderScene
        .getScene()
        .meshes.find(
          (mesh) =>
            this.isComponentMesh(mesh) &&
            mesh.metadata.componentId === componentId &&
            mesh.metadata.geometryId === geometryId,
        )
    }

    return componentMesh
  }

  getDefectGroupNodeByType(type: PartDefect) {
    return this.renderScene
      .getScene()
      .transformNodes.find((n) => this.isDefectGroupNode(n) && n.metadata.defectType === type)
  }

  getDefectNodeByTypeAndIndex(type: PartDefect, defectIndex: number) {
    let defectNode = null
    const groupNode = this.getDefectGroupNodeByType(type)
    if (groupNode) {
      defectNode = groupNode.getChildTransformNodes(true).find((n) => n.metadata.defectIndex === defectIndex)
    }

    return defectNode
  }

  getDefectMeshesByType(type: PartDefect) {
    return this.renderScene.getScene().meshes.filter((m) => this.isMeshDefect(m) && m.metadata.defectType === type)
  }

  getPartBodyByDefectShape(defectShape: AbstractMesh) {
    if (!this.isDefectMesh(defectShape)) {
      return null
    }

    return defectShape.parent ? (defectShape.parent.parent ? defectShape.parent.parent.parent : null) : null
  }

  getRawLabel() {
    return this.renderScene.getScene().meshes.find((m) => this.isRawLabelMesh(m))
  }

  getFacetsDataByFaceId(mesh: AbstractMesh, faceName: string) {
    if (!mesh || !this.isComponentMesh(mesh) || !mesh.metadata.faces) {
      return []
    }

    // changed to face name because I did not have face id in the store for selected faces.
    // Need to discuss with Bridge Team
    const face = mesh.metadata.faces.find((f: Face) => f.name === faceName)
    return this.getFacetsDataByFace(mesh, face)
  }

  getFacetsDataByFace(mesh: AbstractMesh, face: Face) {
    if (!mesh || !face) {
      return []
    }

    const positions = mesh.getVerticesData(VertexBuffer.PositionKind)
    const normals = mesh.getVerticesData(VertexBuffer.NormalKind)
    const facetsPositions = []
    const facetsNormals = []
    for (let i = 0; i < face.indices.length; i += 3) {
      const facetPositions = []
      const facetNormals = []
      for (let j = 0; j < 3; j += 1) {
        const index = face.indices[i + j]
        facetPositions.push(new Vector3(positions[index * 3], positions[index * 3 + 1], positions[index * 3 + 2]))
        facetNormals.push(new Vector3(normals[index * 3], normals[index * 3 + 1], normals[index * 3 + 2]))
      }

      facetsPositions.push(facetPositions)
      facetsNormals.push(facetNormals)
    }

    return { positions: facetsPositions, normals: facetsNormals }
  }

  findRoot(mesh: TransformNode) {
    let rootId = mesh.id
    let root = mesh

    while (root.parent != null && root.parent.name !== GROUP_PARENT_MESH_NAME) {
      rootId = root.parent.id
      root = root.parent as TransformNode
    }

    return root
  }

  updateTransformations(mesh: TransformNode, transformation: Matrix) {
    const translation = Vector3.Zero()
    const rotation = Quaternion.Identity()
    const scaling = Vector3.Zero()
    transformation.decompose(scaling, rotation, translation)

    if (mesh.rotationQuaternion) {
      mesh.rotationQuaternion.copyFrom(rotation)
    } else {
      rotation.toEulerAnglesToRef(mesh.rotation)
    }

    mesh.position = translation
    mesh.scaling = scaling
    mesh.computeWorldMatrix()
  }

  getRelativeTransformation(newTransformation: Matrix, initTransformation: Matrix) {
    const initTransformationInvert = initTransformation.clone().invert()
    const relativeTransformation = newTransformation.clone().multiply(initTransformationInvert)

    return relativeTransformation
  }

  getChildAbsoluteTransformation(mesh: AbstractMesh) {
    mesh.computeWorldMatrix()
    let absoluteTransformation = mesh.getWorldMatrix()
    let parent = mesh.parent

    while (parent) {
      const parentTransformation = parent.getWorldMatrix()
      absoluteTransformation = absoluteTransformation.multiply(parentTransformation)
      parent = parent.parent
    }

    return absoluteTransformation
  }

  convertTranslationToMillimeters(transformation: number[] | Matrix, unitFactor: number) {
    const transformationMatrix =
      transformation instanceof Matrix ? transformation.clone() : Matrix.FromArray(transformation)

    if (!unitFactor) {
      throw new Error('Cannot convert translation to millimeters')
    }
    if (unitFactor - 1 < ACCURACY) {
      return transformationMatrix
    }

    const translation = transformationMatrix.getTranslation().multiplyByFloats(unitFactor, unitFactor, unitFactor)
    transformationMatrix.setTranslation(translation)

    return transformationMatrix
  }

  convertTranslationToGeomUnits(transformation: number[] | Matrix, unitFactor: number, transponsed: boolean = true) {
    let transformationMatrix =
      transformation instanceof Matrix ? transformation.clone() : Matrix.FromArray(transformation)
    if (!unitFactor) {
      throw new Error('Cannot convert translation to geometry units')
    }
    if (unitFactor - 1 < ACCURACY) {
      return transformationMatrix
    }
    if (transponsed) {
      transformationMatrix = transformationMatrix.transpose()
    }

    const translation = transformationMatrix.getTranslation().divide(new Vector3(unitFactor, unitFactor, unitFactor))
    transformationMatrix.setTranslation(translation)

    return transponsed ? transformationMatrix.transpose() : transformationMatrix
  }

  transformMesh(
    mesh: TransformNode,
    transformation: number[] | Matrix,
    attach: boolean = false,
    skipTranslateAndRotate: boolean = false,
  ) {
    if (!mesh) {
      return
    }
    let transformationMatrix = transformation instanceof Matrix ? transformation : Matrix.FromArray(transformation)
    // need to account for Babylon expecting row-matrix, not column-matrix
    transformationMatrix = transformationMatrix.transpose()

    let newTransformation = transformationMatrix
    if (attach) {
      mesh.computeWorldMatrix()
      const initTransformation = mesh.getWorldMatrix().clone()
      newTransformation = initTransformation.multiply(transformationMatrix)
    }

    // Create temp node to etract scaling sign for result mesh
    const preserveScaleNode = new TransformNode('preserveScaleNode', this.renderScene.getScene())
    preserveScaleNode.scaling = new Vector3(transformation[0], transformation[5], transformation[10])

    const rotation: Quaternion = Quaternion.Identity()
    const scaling: Vector3 = Vector3.Zero()
    const translation: Vector3 = Vector3.Zero()
    newTransformation.decompose(scaling, rotation, translation, preserveScaleNode)
    mesh.scaling = scaling
    if (!skipTranslateAndRotate) {
      mesh.rotationQuaternion = rotation
      mesh.position = translation
    }

    mesh.computeWorldMatrix()
    preserveScaleNode.dispose()
  }

  calculateGeometryVolume(mesh: AbstractMesh, transformation = Matrix.Identity()): number {
    if (!mesh) {
      return 0
    }

    const positions = mesh.getVerticesData(VertexBuffer.PositionKind)
    const indices = mesh.getIndices()
    const v1 = new Vector3()
    const v2 = new Vector3()
    const v3 = new Vector3()
    let volume = 0

    const end = indices.length - 2
    for (let i = 0; i < end; i += 3) {
      const ind1 = indices[i] * 3
      const ind2 = indices[i + 1] * 3
      const ind3 = indices[i + 2] * 3

      v1.copyFromFloats(positions[ind1], positions[ind1 + 1], positions[ind1 + 2])
      Vector3.TransformCoordinatesToRef(v1, transformation, v1)
      v2.copyFromFloats(positions[ind2], positions[ind2 + 1], positions[ind2 + 2])
      Vector3.TransformCoordinatesToRef(v2, transformation, v2)
      v3.copyFromFloats(positions[ind3], positions[ind3 + 1], positions[ind3 + 2])
      Vector3.TransformCoordinatesToRef(v3, transformation, v3)

      volume += Vector3.Dot(v1, v2.cross(v3))
    }

    volume = Math.abs(volume) / 6.0

    return volume
  }

  calculateMeshSurfaceArea(mesh: AbstractMesh, transformation: Matrix) {
    if (!mesh) {
      return 0
    }

    const indices = mesh.getIndices()
    const nbFaces = indices.length / 3
    const positions = mesh.getVerticesData(VertexBuffer.PositionKind)
    let area = 0
    for (let i = 0; i < nbFaces; i += 1) {
      area = area + this.calculateMeshFacetArea(mesh, i, positions, transformation)
    }

    return area
  }

  calculateMeshFacetArea(mesh: AbstractMesh, faceId: number, positions: FloatArray, transformation: Matrix) {
    if (!mesh) {
      return 0
    }

    const indices = mesh.getIndices()
    const nbFaces = indices.length / 3
    if (faceId < 0 || faceId > nbFaces) {
      return 0
    }

    let v1x = 0.0
    let v1y = 0.0
    let v1z = 0.0
    let v2x = 0.0
    let v2y = 0.0
    let v2z = 0.0
    let crossx = 0.0
    let crossy = 0.0
    let crossz = 0.0
    let i1 = 0
    let i2 = 0
    let i3 = 0
    i1 = indices[faceId * 3]
    i2 = indices[faceId * 3 + 1]
    i3 = indices[faceId * 3 + 2]

    const pi1 = Vector3.Zero()
    const tpi1 = Vector3.Zero()
    const pi2 = Vector3.Zero()
    const tpi2 = Vector3.Zero()
    const pi3 = Vector3.Zero()
    const tpi3 = Vector3.Zero()
    pi1.copyFromFloats(positions[i1 * 3], positions[i1 * 3 + 1], positions[i1 * 3 + 2])
    Vector3.TransformCoordinatesToRef(pi1, transformation, tpi1)
    pi2.copyFromFloats(positions[i2 * 3], positions[i2 * 3 + 1], positions[i2 * 3 + 2])
    Vector3.TransformCoordinatesToRef(pi2, transformation, tpi2)
    pi3.copyFromFloats(positions[i3 * 3], positions[i3 * 3 + 1], positions[i3 * 3 + 2])
    Vector3.TransformCoordinatesToRef(pi3, transformation, tpi3)

    v1x = tpi1.x - tpi2.x
    v1y = tpi1.y - tpi2.y
    v1z = tpi1.z - tpi2.z
    v2x = tpi3.x - tpi2.x
    v2y = tpi3.y - tpi2.y
    v2z = tpi3.z - tpi2.z
    crossx = v1y * v2z - v1z * v2y
    crossy = v1z * v2x - v1x * v2z
    crossz = v1x * v2y - v1y * v2x

    return Math.sqrt(crossx * crossx + crossy * crossy + crossz * crossz) * 0.5
  }

  calculateFacetArea(v1: Vector3, v2: Vector3, v3: Vector3, worldMatrix: Matrix) {
    const tv1 = Vector3.TransformCoordinates(v1, worldMatrix)
    const tv2 = Vector3.TransformCoordinates(v2, worldMatrix)
    const tv3 = Vector3.TransformCoordinates(v3, worldMatrix)

    const v2v1 = tv1.subtract(tv2)
    const v2v3 = tv3.subtract(tv2)
    return v2v1.cross(v2v3).length() * 0.5
  }

  intersectRayPlane(ray: Ray, plane: Plane) {
    let intersectionPoint = null
    const dotProduct = Vector3.Dot(ray.direction, plane.normal)
    if (dotProduct !== 0.0) {
      const scaleFactor = -plane.signedDistanceTo(ray.origin) / dotProduct
      if (scaleFactor >= 0.0) {
        const direction = ray.direction.scale(scaleFactor)
        intersectionPoint = ray.origin.add(direction)
      }
    }
    return intersectionPoint
  }

  project3DPointOntoScreen(point: Vector3, camera?: Camera): Vector2 {
    const scene = this.renderScene.getScene()
    const engine = scene.getEngine()
    const sceneTransform = camera
      ? camera.getViewMatrix().multiply(camera.getProjectionMatrix())
      : scene.getTransformMatrix()
    const activeCamera = camera ? camera : this.renderScene.getActiveCamera()
    const viewport = activeCamera.viewport.toGlobal(engine.getRenderWidth(), engine.getRenderHeight())
    const screenCoord = Vector3.Project(point, IDENTITY_MATRIX, sceneTransform, viewport)

    return new Vector2(screenCoord.x, screenCoord.y)
  }

  getBoundingBox2D(meshes: AbstractMesh[]): BoundingBox2D {
    const boundingInfo: BoundingInfo = this.getTotalBoundingInfo(meshes)
    const screenPoints = boundingInfo.boundingBox.vectors.map((v: Vector3) => this.project3DPointOntoScreen(v))
    const xMin = Math.min.apply(
      Math,
      screenPoints.map((p: Vector2) => p.x),
    )
    const xMax = Math.max.apply(
      Math,
      screenPoints.map((p: Vector2) => p.x),
    )
    const yMin = Math.min.apply(
      Math,
      screenPoints.map((p: Vector2) => p.y),
    )
    const yMax = Math.max.apply(
      Math,
      screenPoints.map((p: Vector2) => p.y),
    )

    return { xMin, xMax, yMin, yMax }
  }

  removeNonAdjacentAndSmallFacets(mesh: Mesh, targetFacetId: number) {
    const indices = mesh.getIndices()
    const positions = mesh.getVerticesData(VertexBuffer.PositionKind)
    const normals = mesh.getVerticesData(VertexBuffer.NormalKind)
    const uvs = mesh.getVerticesData(VertexBuffer.UVKind)
    const meshGraph = new Graph()
    const vertexFacetsMap = {}
    const indicesChangeMap = {}

    // create mesh graph
    for (let i = 0; i < indices.length; i += 3) {
      meshGraph.addEdge(indices[i], indices[i + 1])
      meshGraph.addEdge(indices[i], indices[i + 2])
      meshGraph.addEdge(indices[i + 1], indices[i + 2])

      // store in which triangles a vertex is present
      for (let j = i; j < i + 3; j += 1) {
        const vIndex = indices[j]
        if (!vertexFacetsMap[vIndex]) {
          vertexFacetsMap[vIndex] = []
        }

        vertexFacetsMap[vIndex].push(i / 3)
      }
    }

    // search adjacency vertices to vertices that belongs to triangle that contains picked point
    const adjacencyVertices = meshGraph.bfs(indices[3 * targetFacetId])
    const knownFacets = []
    const filteredIndices = []
    let filteredPositions = []
    let filteredNormals = []
    let filteredUVs = []
    let lastIndex = 0

    for (const vIndexA of adjacencyVertices) {
      // get all triangles where vertex is present
      const vFacets = vertexFacetsMap[vIndexA]
      for (const fIndex of vFacets) {
        if (knownFacets[fIndex] !== undefined) {
          continue
        } else {
          // remove triangles with small facet area
          const area = this.calculateMeshFacetArea(mesh, fIndex, positions, Matrix.Identity())
          if (area < Epsilon) {
            continue
          }

          knownFacets[fIndex] = fIndex
          const fStartIndex = 3 * fIndex // first facet vertex index
          let newIndex: number
          for (let j = fStartIndex; j < fStartIndex + 3; j += 1) {
            const index = indices[j]

            if (indicesChangeMap[index] === undefined) {
              indicesChangeMap[index] = lastIndex
              newIndex = lastIndex
              lastIndex += 1

              let startIndex = 3 * index
              let endIndex = startIndex + 3
              filteredPositions = filteredPositions.concat(positions.slice(startIndex, endIndex))
              filteredNormals = filteredNormals.concat(normals.slice(startIndex, endIndex))
              startIndex = 2 * index
              endIndex = startIndex + 2
              filteredUVs = filteredUVs.concat(uvs.slice(startIndex, endIndex))
            } else {
              newIndex = indicesChangeMap[index]
            }

            filteredIndices.push(newIndex)
          }
        }
      }
    }

    // create new mark patch mesh from filtered triangles
    const vertexData = new VertexData()
    vertexData.indices = filteredIndices
    vertexData.positions = filteredPositions
    vertexData.normals = []
    VertexData.ComputeNormals(filteredPositions, filteredIndices, vertexData.normals, { useRightHandedSystem: true })
    vertexData.uvs = filteredUVs

    vertexData.applyToMesh(mesh)
  }

  // source: https://doc.babylonjs.com/toolsAndResources/utilities/Minimise_Vertices
  minimizeVertices(mesh: Mesh) {
    const decPlaces = Math.pow(10, 8)
    const pdata = mesh.getVerticesData(VertexBuffer.PositionKind)
    const ndata = mesh.getVerticesData(VertexBuffer.NormalKind)
    const uvdata = mesh.getVerticesData(VertexBuffer.UVKind)
    const cdata = mesh.getVerticesData(VertexBuffer.ColorKind)
    const idata = mesh.getIndices()

    const newPdata = [] // new positions array
    const newIdata = [] // new indices array
    const newNdata = [] // new normal array
    const newCData = [] // new color array
    const newUVData = [] // new uv array

    let mapPtr = 0 // new index
    const uniquePositions = {} // unique vertex positions
    for (let i = 0; i < idata.length; i += 3) {
      const facet = [idata[i], idata[i + 1], idata[i + 2]] // facet vertex indices
      const pstring = [] // lists facet vertex positions (x,y,z) as string "xyz""
      for (let j = 0; j < 3; j += 1) {
        //
        pstring[j] = ''
        for (let k = 0; k < 3; k += 1) {
          // small values make 0
          if (Math.abs(pdata[3 * facet[j] + k]) < ACCURACY) {
            pdata[3 * facet[j] + k] = 0
          }

          pstring[j] += `${Math.round(pdata[3 * facet[j] + k] * decPlaces) / decPlaces}|`
        }
      }
      // check facet vertices to see that none are repeated
      // do not process any facet that has a repeated vertex, ie is a line
      if (!(pstring[0] === pstring[1] || pstring[0] === pstring[2] || pstring[1] === pstring[2])) {
        // for each facet position check if already listed in uniquePositions
        // if not listed add to uniquePositions and set index pointer
        // if listed use its index in uniquePositions and new index pointer
        for (let j = 0; j < 3; j += 1) {
          let ptr = uniquePositions[pstring[j]]
          if (ptr === undefined) {
            uniquePositions[pstring[j]] = mapPtr
            ptr = mapPtr
            mapPtr += 1
            // not listed so add individual x, y, z coordinates to new positions array newPdata
            // and add matching normal data to new normals array newNdata
            for (let k = 0; k < 3; k += 1) {
              newPdata.push(pdata[3 * facet[j] + k])
            }

            if (ndata !== null && ndata !== void 0) {
              for (let k = 0; k < 3; k += 1) {
                newNdata.push(ndata[3 * facet[j] + k])
              }
            }

            if (cdata !== null && cdata !== void 0) {
              for (let k = 0; k < 4; k += 1) {
                newCData.push(cdata[4 * facet[j] + k])
              }
            }

            if (uvdata !== null && uvdata !== void 0) {
              for (let k = 0; k < 2; k += 1) {
                newUVData.push(uvdata[2 * facet[j] + k])
              }
            }
          }
          // add new index pointer to new indices array newIdata
          newIdata.push(ptr)
        }
      }
    }

    // create new vertex data object and update
    const vertexData = new VertexData()
    vertexData.positions = newPdata
    vertexData.indices = newIdata
    vertexData.normals = []
    VertexData.ComputeNormals(newPdata, newIdata, vertexData.normals, { useRightHandedSystem: true })
    vertexData.uvs = newUVData
    if (newCData && newCData.length !== 0) {
      vertexData.colors = newCData
    }

    vertexData.applyToMesh(mesh)
  }

  isBoundingBoxCompletelyInsideBoundingBox(inside: BoundingInfo, outside: BoundingInfo): boolean {
    if (
      (Math.abs(inside.boundingBox.minimumWorld.x - outside.boundingBox.minimumWorld.x) < Epsilon ||
        inside.boundingBox.minimumWorld.x > outside.boundingBox.minimumWorld.x) &&
      (Math.abs(inside.boundingBox.minimumWorld.y - outside.boundingBox.minimumWorld.y) < Epsilon ||
        inside.boundingBox.minimumWorld.y > outside.boundingBox.minimumWorld.y) &&
      (Math.abs(inside.boundingBox.minimumWorld.z - outside.boundingBox.minimumWorld.z) < Epsilon ||
        inside.boundingBox.minimumWorld.z > outside.boundingBox.minimumWorld.z) &&
      (Math.abs(inside.boundingBox.maximumWorld.x - outside.boundingBox.maximumWorld.x) < Epsilon ||
        inside.boundingBox.maximumWorld.x < outside.boundingBox.maximumWorld.x) &&
      (Math.abs(inside.boundingBox.maximumWorld.y - outside.boundingBox.maximumWorld.y) < Epsilon ||
        inside.boundingBox.maximumWorld.y < outside.boundingBox.maximumWorld.y) &&
      (Math.abs(inside.boundingBox.maximumWorld.z - outside.boundingBox.maximumWorld.z) < Epsilon ||
        inside.boundingBox.maximumWorld.z < outside.boundingBox.maximumWorld.z)
    ) {
      return true
    }

    return false
  }

  distanceFromPointToLine(point: Vector2, lineStart: Vector2, lineEnd: Vector2) {
    const a = lineStart.y - lineEnd.y
    const b = lineEnd.x - lineStart.x
    return (
      Math.abs(a * point.x + b * point.y + (lineStart.x * lineEnd.y - lineEnd.x * lineStart.y)) /
      Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2))
    )
  }

  isPointInsideTriangle(point: Vector2, v1: Vector2, v2: Vector2, v3: Vector2) {
    const a = (v1.x - point.x) * (v2.y - v1.y) - (v2.x - v1.x) * (v1.y - point.y)
    const b = (v2.x - point.x) * (v3.y - v2.y) - (v3.x - v2.x) * (v2.y - point.y)
    const c = (v3.x - point.x) * (v1.y - v3.y) - (v1.x - v3.x) * (v3.y - point.y)

    return (a >= 0 && b >= 0 && c >= 0) || (a <= 0 && b <= 0 && c <= 0)
  }

  projectionPointOnLine(point: Vector2, lineStart: Vector2, lineEnd: Vector2) {
    const abx = lineEnd.x - lineStart.x
    const aby = lineEnd.y - lineStart.y
    const dacab = (point.x - lineStart.x) * abx + (point.y - lineStart.y) * aby
    const dab = abx * abx + aby * aby
    const t = dacab / dab
    const x = lineStart.x + abx * t
    const y = lineStart.y + aby * t

    return new Vector3(x, y, 0)
  }

  showMeshHull(hull: ConvexHull, isVisible: boolean, parentMesh) {
    const scene = this.renderScene.getScene()
    if (isVisible) {
      if (!hull.meshHull) {
        hull.meshHull = MeshBuilder.CreateLines(
          MESH_HULL,
          {
            points: hull.hullPoints,
          },
          scene,
        )
        hull.meshHull.color = new Color3(0, 0, 0)
        hull.meshHull.renderingGroupId = MESH_RENDERING_GROUP_ID
        hull.meshHull.parent = parentMesh
        parentMesh.getChildTransformNodes(true).map((n) => {
          if (n.name !== MESH_HULL) {
            n.material.alpha = 0.3
          }
        })
      }
    } else {
      scene.removeMesh(hull.meshHull)
      hull.meshHull.dispose()
      hull.meshHull = null
      parentMesh.getChildTransformNodes(true).map((n) => {
        if (n.name !== WIREFRAME_MATERIAL_NAME) {
          n.material.alpha = 1.0
        }
      })
    }
  }

  /**
   * Computes optimal OBB.
   * Based on https://github.build.ge.com/additive/bounding-volume-hierarchy/blob/main/ASP-BVH/ASP-BVH/src/obb.cs
   * @param triangles Triangles of the mesh that need OBB.
   * @returns Array with 8 points of OBB.
   */
  computeOptimalObbFromTriangles(triangles: Vector3[]): Vector3[] {
    // Compute covariance matrix.
    let am = 0
    const mu = new Vector3(0, 0, 0)
    let cxx = 0
    let cxy = 0
    let cxz = 0
    let cyy = 0
    let cyz = 0
    let czz = 0

    for (let i = 0; i < triangles.length; i += 3) {
      const p = triangles[i]
      const q = triangles[i + 1]
      const r = triangles[i + 2]
      const centroid = p
        .add(q)
        .add(r)
        .divide(new Vector3(3, 3, 3))

      const area = q.subtract(p).cross(r.subtract(p)).length() / 2
      mu.addInPlace(centroid.multiplyByFloats(area, area, area))
      am += area

      cxx += (9 * centroid.x * centroid.x + p.x * p.x + q.x * q.x + r.x * r.x) * (area / 12)
      cxy += (9 * centroid.x * centroid.y + p.x * p.y + q.x * q.y + r.x * r.y) * (area / 12)
      cxz += (9 * centroid.x * centroid.z + p.x * p.z + q.x * q.z + r.x * r.z) * (area / 12)
      cyy += (9 * centroid.y * centroid.y + p.y * p.y + q.y * q.y + r.y * r.y) * (area / 12)
      cyz += (9 * centroid.y * centroid.z + p.y * p.z + q.y * q.z + r.y * r.z) * (area / 12)
      czz += (9 * centroid.z * centroid.z + p.z * p.z + q.z * q.z + r.z * r.z) * (area / 12)
    }
    mu.divideInPlace(new Vector3(am, am, am))
    cxx /= am
    cxy /= am
    cxz /= am
    cyy /= am
    cyz /= am
    czz /= am

    // Builds covariance matrix.
    const covarianceMatrix: number[][] = [[], [], []]
    covarianceMatrix[0][0] = cxx - mu.x * mu.x
    covarianceMatrix[0][1] = cxy - mu.x * mu.y
    covarianceMatrix[0][2] = cxz - mu.x * mu.z
    covarianceMatrix[1][1] = cyy - mu.y * mu.y
    covarianceMatrix[1][2] = cyz - mu.y * mu.z
    covarianceMatrix[2][2] = czz - mu.z * mu.z
    covarianceMatrix[1][0] = covarianceMatrix[0][1]
    covarianceMatrix[2][1] = covarianceMatrix[1][2]
    covarianceMatrix[2][0] = covarianceMatrix[0][2]

    return this.computeOptimalObb(covarianceMatrix, triangles)
  }

  /**
   * Computes optimal OBB.
   * Based on https://github.build.ge.com/additive/bounding-volume-hierarchy/blob/main/ASP-BVH/ASP-BVH/src/obb.cs
   * @param vertices Vertices that need OBB.
   * @returns Array with 8 points of OBB.
   */
  computeOptimalObbFromVertices(vertices: Vector3[]): Vector3[] {
    const covarianceMatrix: number[][] = [[], [], []]
    const mu = vertices.reduce((p, c) => p.addInPlace(c), Vector3.Zero())
    mu.divideInPlace(new Vector3(vertices.length, vertices.length, vertices.length))

    // Builds covariance matrix.
    covarianceMatrix[0][0] = vertices.reduce((p, c) => p + (c.x - mu.x) * (c.x - mu.x), 0) / (vertices.length - 1)
    covarianceMatrix[0][1] = vertices.reduce((p, c) => p + (c.x - mu.x) * (c.y - mu.y), 0) / (vertices.length - 1)
    covarianceMatrix[0][2] = vertices.reduce((p, c) => p + (c.x - mu.x) * (c.z - mu.z), 0) / (vertices.length - 1)
    covarianceMatrix[1][1] = vertices.reduce((p, c) => p + (c.y - mu.y) * (c.y - mu.y), 0) / (vertices.length - 1)
    covarianceMatrix[1][2] = vertices.reduce((p, c) => p + (c.y - mu.y) * (c.z - mu.z), 0) / (vertices.length - 1)
    covarianceMatrix[2][2] = vertices.reduce((p, c) => p + (c.z - mu.z) * (c.z - mu.z), 0) / (vertices.length - 1)
    covarianceMatrix[1][0] = covarianceMatrix[0][1]
    covarianceMatrix[2][0] = covarianceMatrix[0][2]
    covarianceMatrix[2][1] = covarianceMatrix[1][2]

    return this.computeOptimalObb(covarianceMatrix, vertices)
  }

  setIsHidden(mesh: InstancedMesh, makeHidden: boolean, showHiddenAsTransparent: boolean) {
    if (!this.isComponentMesh(mesh) && mesh.name !== LINE_SUPPORT) {
      return
    }
    mesh.metadata.isHidden = makeHidden

    const labels: InstancedMesh[] = this.isComponentMesh(mesh) ? this.getAllMeshLabels(mesh) : []
    labels.forEach((label) => (label.metadata.isHidden = makeHidden))

    if (!makeHidden) {
      this.showAsOpaque(mesh as InstancedMesh, labels)
    } else if (showHiddenAsTransparent) {
      this.showAsTransparent(mesh as InstancedMesh, labels)
    } else {
      this.totalHide(mesh as InstancedMesh, labels)
    }
  }

  /**
   * Translates the center bbox of the source part to the center bbox of target part in WCS.
   * Uses bbox from BabylonJS.
   */
  translateBBoxCenterToBBoxCenter(sourcePart: TransformNode, targetPart: TransformNode) {
    const targetMeshes = targetPart.getChildMeshes(false, this.isComponentMesh)
    const oldBBoxInfo = this.getTotalBoundingInfo(targetMeshes)
    this.translateBBoxCenterToPosition(sourcePart, oldBBoxInfo.boundingBox.centerWorld)
  }

  /**
   * Translates the center bbox of the part to new position in WCS.
   * Uses bbox from BabylonJS.
   */
  translateBBoxCenterToPosition(part: TransformNode, position: Vector3) {
    part.computeWorldMatrix(true)
    const meshes = part.getChildMeshes(false, this.isComponentMesh)
    const bbox = this.getTotalBoundingInfo(meshes)
    const delta = position.subtract(bbox.boundingBox.centerWorld)
    part.setAbsolutePosition(part.getAbsolutePosition().add(delta))
  }

  /**
   * Create clone mesh with transparent material. Also create transparent clone for corresponding insideMesh.
   * @param sourceMesh component's source mesh.
   * @param sourceCloneId The ID to set for source clone.
   * @param geometryType component's GeometryTypes (to find suitable material).
   * @returns Clone mesh with transparent material and cloned sourceMeshInside.
   */
  cloneSourceMesh(sourceMesh: Mesh, sourceCloneId: string, itemType: SceneItemType): Mesh {
    // Create source mesh clone
    const cloneMesh = sourceMesh.clone(sourceMesh.name + TRANSPARENT_MESH_ENDING, null, true, false)
    cloneMesh.setParent(sourceMesh)
    cloneMesh.id = sourceCloneId
    cloneMesh.material = this.getTransparentMaterial(itemType, true)
    cloneMesh.metadata = {
      geometryId: sourceMesh.metadata.geometryId,
      originalMaterial: cloneMesh.material,
    }

    // Create inside mesh clone
    const sourceMeshInside = sourceMesh.metadata.sourceMeshInside
    const cloneMeshInside = sourceMeshInside.clone(sourceMeshInside.name + TRANSPARENT_MESH_ENDING)
    cloneMeshInside.id = sourceMeshInside.id + TRANSPARENT_MESH_ENDING
    cloneMeshInside.parent = cloneMesh
    cloneMeshInside.material = this.getTransparentMaterial(itemType, false)
    cloneMeshInside.renderingGroupId = cloneMesh.renderingGroupId
    cloneMesh.metadata.sourceMeshInside = cloneMeshInside
    cloneMeshInside.metadata = null

    // Source meshes are invisible and not pickable. Prepare them for creating renderable instances.
    ;[cloneMesh, cloneMeshInside].forEach((mesh) => {
      mesh.isVisible = false
      mesh.isPickable = false

      // Clears the instanced buffer after cloning. It needs to correct the register buffer.
      mesh.instancedBuffers = null

      // Transparent instances are not rendered to GPU picker, so only color for canvas is needed.
      mesh.registerInstancedBuffer(VertexBuffer.ColorKind, 3)
      mesh.instancedBuffers.color = DEFAULT_COLOR

      this.renderScene.getScene().removeMesh(mesh)
    })

    return cloneMesh
  }

  /**
   * Create transparent clone for hidden component or label mesh.
   * @param instanceMesh opaque component mesh or label mesh with metadata.isHidden is true.
   * @param show To show transparent clone or make it hidden on canvas.
   * @returns transparent InstancedMesh with metadata.itemType is ComponentClone.
   */
  createTransparentClone(instanceMesh: InstancedMesh, show: boolean): InstancedMesh {
    if (instanceMesh.metadata.transparentCloneId) {
      // If transparent clone already exists and we need to show the clone's mesh on a scene - set it's visibility
      // to match 'show' variable state.
      if (show) {
        this.showTransparentClone(instanceMesh)
        const transparentClone = this.getTransparentClone(instanceMesh)
        transparentClone.instancedBuffers.color = instanceMesh.instancedBuffers.color
      } else {
        this.hideTransparentClone(instanceMesh)
      }
      return
    }
    const sourceMesh = instanceMesh.sourceMesh
    const sourceCloneId = sourceMesh.id + TRANSPARENT_MESH_ENDING

    // Transparent clones are structured the same as opaque meshes - there is 1 source mesh and 1 or more instances.
    // Mesh transparentCloneSource is a clone of component's source mesh.
    let transparentCloneSource: Mesh = null
    if (sourceMesh.cloneMeshMap) {
      // Try to find transparent source (when another instance of sourceMesh already has transparentCopy).
      for (const mesh of Object.values(sourceMesh.cloneMeshMap)) {
        if (mesh && mesh.id === sourceCloneId) {
          transparentCloneSource = mesh
          break
        }
      }
    }
    if (!transparentCloneSource) {
      // It's the first hidden instance of sourceMesh. Craete clone with transparent material.
      transparentCloneSource = this.cloneSourceMesh(sourceMesh, sourceCloneId, instanceMesh.metadata.itemType)
    }

    // Create instance mesh as transparent copy of component.
    const transparentInstance = transparentCloneSource.createInstance(instanceMesh.name + TRANSPARENT_MESH_ENDING)
    transparentInstance.id = instanceMesh.id + TRANSPARENT_MESH_ENDING
    transparentInstance.parent = instanceMesh
    transparentInstance.isVisible = show && this.isMeshGeometryTypeVisible(instanceMesh)
    transparentInstance.isPickable = false
    transparentInstance.instancedBuffers.color = instanceMesh.instancedBuffers.color
    transparentInstance.onDispose = () => {
      if (!transparentCloneSource.hasInstances) {
        transparentCloneSource.dispose()
      }
    }

    let insideMeshName = INSIDE_MESH_NAME
    let transparentCloneItemType = SceneItemType.ComponentClone
    if (this.isLabelMesh(instanceMesh)) {
      insideMeshName = LABEL_INSIDE_MESH_NAME
      transparentCloneItemType = SceneItemType.LabelSemitransparent
    } else if (this.isLabelCachedMesh(instanceMesh)) {
      insideMeshName = LABEL_INSIDE_MESH_NAME
      transparentCloneItemType = SceneItemType.LabelSemitransparentCached
    } else if (this.isSupportMesh(instanceMesh)) {
      insideMeshName = SUPPORT_INSIDE_MESH_NAME
      transparentCloneItemType = SceneItemType.SupportSemitransparent
    }

    // Create inside mesh instance.
    const transparentCloneMeshInside = transparentCloneSource.metadata.sourceMeshInside as Mesh
    const meshInsideInstance = transparentCloneMeshInside.createInstance(insideMeshName + TRANSPARENT_MESH_ENDING)
    meshInsideInstance.id = transparentInstance.id + INSIDE_TRANSPARENT_MESH_ENDING
    meshInsideInstance.parent = transparentInstance
    meshInsideInstance.isPickable = false
    meshInsideInstance.isVisible = false
    meshInsideInstance.onDispose = () => {
      if (!transparentCloneMeshInside.hasInstances) {
        transparentCloneMeshInside.dispose()
      }
    }

    instanceMesh.metadata.transparentCloneId = transparentInstance.id
    instanceMesh.metadata.showAsTransparent = transparentInstance.isVisible

    transparentInstance.metadata = {
      itemType: transparentCloneItemType,
      partType: instanceMesh.metadata.partType,
      copiedInstanceId: instanceMesh.id,
      insideMeshId: meshInsideInstance.id,
    } as ITransparentCloneMetadata

    if (this.isComponentMesh(instanceMesh)) {
      this.getAllMeshLabels(instanceMesh).forEach((label) => this.createTransparentClone(label, show))
    }

    return transparentInstance
  }

  /** Make transparent clone of mesh invisible. */
  hideTransparentClone(mesh: InstancedMesh, cachedLabels?: InstancedMesh[]) {
    mesh.metadata.showAsTransparent = false
    if (!mesh.metadata.transparentCloneId) {
      return
    }

    const clone = this.getTransparentClone(mesh)
    clone.isVisible = false
    clone.getChildMeshes().forEach((child) => (child.isVisible = false))

    if (this.isComponentMesh(mesh)) {
      const labels = cachedLabels ? cachedLabels : this.getAllMeshLabels(mesh)
      labels.forEach((label) => this.hideTransparentClone(label, []))
    }
  }

  /** Make transparent clone of mesh visible. */
  showTransparentClone(mesh: InstancedMesh, cachedLabels?: InstancedMesh[]) {
    if (!mesh.metadata.transparentCloneId) {
      return
    }

    mesh.metadata.showAsTransparent = true
    const clone = this.getTransparentClone(mesh)
    clone.isVisible = true
    clone.getChildMeshes().forEach((child) => {
      if (child.id === (clone.metadata as ITransparentCloneMetadata).insideMeshId) {
        child.isVisible = this.isShowingInsideMeshes
      } else {
        child.isVisible = true
      }
    })

    if (this.isComponentMesh(mesh)) {
      const labels = cachedLabels ? cachedLabels : this.getAllMeshLabels(mesh)
      labels.forEach((label) => this.showTransparentClone(label, []))
    }
  }

  /**
   * When component is hidden but should be highlighted (on hover or selection).
   * Keep transparentClone material up-to-date even if not shown.
   * Note: When transparent rendering mode is turned off this function has power to show/hide transparent clone.
   * @param mesh Component mesh.
   * @param showHighlight Mesh is highlighted if true or highlight is deactivated if false.
   * @param isSelected Mesh is selected.
   * @param allowHideSelected Allow to hide transparentClone when highlight is deactivated but mesh is selected.
   */
  highlightHiddenMesh(mesh: InstancedMesh, showHighlight: boolean, isSelected: boolean, allowHideSelected: boolean) {
    if (!(this.isComponentMesh(mesh) || this.isSupportMesh(mesh)) || !mesh.metadata.isHidden) {
      return
    }

    if (
      !this.isShowHiddenPartsAsTransparentMode && // When transparent rendering is turned off
      showHighlight !== mesh.metadata.showAsTransparent // showHighlight should be equal showAsTransparent.
    ) {
      if (showHighlight) {
        this.showAsTransparent(mesh)
      } else if (allowHideSelected || !isSelected) {
        this.totalHide(mesh)
      }
    }
  }

  /**
   * @param mesh - the parent of transparent clone mesh
   * @returns Transparent clone mesh
   */
  getTransparentClone(mesh: AbstractMesh) {
    return mesh.getChildMeshes(true).find((child) => child.id === mesh.metadata.transparentCloneId) as InstancedMesh
  }

  /**
   * @param mesh - the parent of transparent clone inside mesh
   * @returns Transparent clone inside mesh
   */
  getTransparentCloneInside(mesh: AbstractMesh) {
    return mesh.getChildMeshes(true).find((child) => child.id === mesh.metadata.insideMeshId) as InstancedMesh
  }

  showAsOpaque(mesh: InstancedMesh, cachedLabels?: InstancedMesh[]) {
    const visible = this.isMeshGeometryTypeVisible(mesh)
    if (this.isComponentMesh(mesh)) {
      const labels = cachedLabels ? cachedLabels : this.getAllMeshLabels(mesh)

      this.hideTransparentClone(mesh, labels)
      mesh.isVisible = visible
      labels.forEach((label) => (label.isVisible = visible))
      if (this.isShowingInsideMeshes) {
        mesh
          .getChildMeshes()
          .filter((c) => [INSIDE_MESH_NAME, LABEL_INSIDE_MESH_NAME].includes(c.name))
          .forEach((child) => (child.isVisible = visible))
      }
    } else if (mesh.name === LINE_SUPPORT) {
      this.hideTransparentClone(mesh, cachedLabels)
      mesh.isVisible = visible
    }
  }

  showAsTransparent(mesh: InstancedMesh, cachedLabels?: InstancedMesh[]) {
    const visible = this.isMeshGeometryTypeVisible(mesh)
    if (this.isComponentMesh(mesh)) {
      const labels = cachedLabels ? cachedLabels : this.getAllMeshLabels(mesh)
      if (visible) {
        this.showTransparentClone(mesh, labels)
      }
      mesh.isVisible = false
      labels.forEach((label) => (label.isVisible = false))
    } else if (mesh.name === LINE_SUPPORT) {
      if (visible) {
        this.showTransparentClone(mesh, cachedLabels)
      }
      mesh.isVisible = false
    }
  }

  totalHide(mesh: InstancedMesh, cachedLabels?: InstancedMesh[]) {
    if (this.isComponentMesh(mesh)) {
      const labels = cachedLabels ? cachedLabels : this.getAllMeshLabels(mesh)

      this.hideTransparentClone(mesh, labels)
      mesh.isVisible = false
      labels.forEach((label) => (label.isVisible = false))
    } else if (mesh.name === LINE_SUPPORT) {
      this.hideTransparentClone(mesh, cachedLabels)
      mesh.isVisible = false
    }
  }

  isMeshGeometryTypeVisible(mesh: InstancedMesh): boolean {
    const displaySettings: IDisplayToolbarState = store.getters['buildPlans/displayToolbarStateByVariantId'](
      store.getters['buildPlans/getBuildPlan'].id,
    )

    if (this.isComponentMesh(mesh)) {
      if (mesh.metadata.bodyType === GeometryType.Production) {
        return displaySettings.isShowingProductionGeometry
      }
      if (mesh.metadata.bodyType === GeometryType.Support) {
        return displaySettings.isShowingSupportGeometry
      }
      if (mesh.metadata.bodyType === GeometryType.Coupon) {
        return displaySettings.isShowingCouponGeometry
      }
    } else if (this.isSupportMesh(mesh)) {
      return displaySettings.isShowingSupportGeometry
    }

    return true
  }

  setInstancedBufferColor(mesh: InstancedMesh, highlighted: boolean, selected: boolean, highlightColor?: Color3) {
    if (!mesh.instancedBuffers || !mesh.instancedBuffers.color) {
      return
    }

    let color = DEFAULT_COLOR
    if (highlighted) {
      color = highlightColor ? highlightColor : HIGHLIGHTING_COLOR
    } else if (selected) {
      color = SELECTING_COLOR
    } else {
      color = this.getDefaultMeshColor(mesh)
    }

    mesh.instancedBuffers.color = color

    // Update inside mesh color
    mesh.getChildMeshes().forEach((child) => {
      if (child.name.includes(INSIDE_MESH_NAME) && !!child.instancedBuffers) {
        child.instancedBuffers.color = color
      }
    })

    // Update transparent clone
    const clone = this.getTransparentClone(mesh)
    if (clone && clone.instancedBuffers) {
      clone.instancedBuffers.color = color
      clone.getChildMeshes().forEach((child) => {
        if (child.name.includes(INSIDE_MESH_NAME) && !!child.instancedBuffers) {
          child.instancedBuffers.color = color
        }
      })
    }
  }

  get isShowHiddenPartsAsTransparentMode(): boolean {
    return store.getters['visualizationModule/isShowHiddenPartsAsTransparentMode']
  }

  get isIBCPlan(): boolean {
    const ibcBp: IBuildPlan = store.getters['buildPlans/getIBCPlan']
    return ibcBp && ibcBp.itemType === ItemType.IbcPlan
  }

  get isShowingInsideMeshes(): boolean {
    const sceneViewMode = (this.renderScene as RenderScene).getViewMode()
    return sceneViewMode instanceof SlicingViewMode || sceneViewMode instanceof CrossSectionViewMode
  }

  getInsideMesh(outsideMesh: InstancedMesh): InstancedMesh {
    return outsideMesh.getChildMeshes().find((child) => child.name.includes(INSIDE_MESH_NAME)) as InstancedMesh
  }

  private getTransparentMaterial(itemType: SceneItemType, outside: boolean): Material {
    let materialName = SEMI_TRANSPARENT_DEFAULT_MATERIAL_NAME
    if (itemType === SceneItemType.Component) {
      materialName = outside ? SEMI_TRANSPARENT_DEFAULT_MATERIAL_NAME : SEMI_TRANSPARENT_INSIDE_MATERIAL_NAME
    } else if (itemType === SceneItemType.Label || itemType === SceneItemType.LabelCached) {
      materialName = outside ? SEMI_TRANSPARENT_LABEL_MATERIAL_NAME : SEMI_TRANSPARENT_INSIDE_MATERIAL_NAME
    } else if (itemType === SceneItemType.Support) {
      materialName = outside ? SEMI_TRANSPARENT_SUPPORT_MATERIAL : SEMI_TRANSPARENT_SUPPORT_INSIDE_MATERIAL
    }

    return this.renderScene.getScene().getMaterialByName(materialName)
  }

  private getDefaultMeshColor(mesh: InstancedMesh) {
    let opaqueOutsideMesh = mesh
    if ([INSIDE_MESH_NAME, SUPPORT_INSIDE_MESH_NAME].some((name) => mesh.name.includes(name))) {
      opaqueOutsideMesh = mesh.parent as InstancedMesh
    }
    if (opaqueOutsideMesh.name.includes(TRANSPARENT_MESH_ENDING)) {
      const scene = this.renderScene.getScene()
      opaqueOutsideMesh = scene.getMeshById(opaqueOutsideMesh.metadata.copiedInstanceId) as InstancedMesh
    }

    if (this.isComponentMesh(opaqueOutsideMesh)) {
      if (opaqueOutsideMesh.metadata.isMarkedByDuplicateManager) {
        return FAILED_DUPLCIATE_COLOR
      }

      const bodyType = opaqueOutsideMesh.metadata.bodyType
      if (bodyType === GeometryType.Production && !this.isIBCPlan) {
        return PRODUCTION_DEFAULT_COLOR
      }
      if (bodyType === GeometryType.Coupon) {
        return COUPON_DEFAULT_COLOR
      }
      if (bodyType === GeometryType.Support && !this.isIBCPlan) {
        return IMPORTED_SUPPORT_DEFAULT_COLOR
      }
    } else if (this.isSupportMesh(opaqueOutsideMesh)) {
      return SUPPORT_COLOR
    }

    return DEFAULT_COLOR
  }

  private getAllMeshLabels(mesh: InstancedMesh): InstancedMesh[] {
    const bpItemMesh = this.getBuildPlanItemMeshByChild(mesh)

    // All labels are attached to build plan item node. Get direct descendants meshes to save time.
    const directDescendantsOnly = true
    const labels = bpItemMesh
      .getChildMeshes(directDescendantsOnly)
      .filter(
        (c) =>
          this.isLabelMesh(c) &&
          c.metadata.componentId === mesh.metadata.componentId &&
          c.metadata.geometryId === mesh.metadata.geometryId &&
          c.uniqueId !== mesh.uniqueId,
      ) as InstancedMesh[]

    return labels
  }

  private computeOptimalObb(covarianceMatrix: number[][], vertices: Vector3[]): Vector3[] {
    // Compute eigenvectors of covariance matrix.
    const eigenMatrix = eigs(covarianceMatrix).vectors

    const r = new Vector3(eigenMatrix[0][0], eigenMatrix[1][0], eigenMatrix[2][0])
    const u = new Vector3(eigenMatrix[0][1], eigenMatrix[1][1], eigenMatrix[2][1])
    const f = new Vector3(eigenMatrix[0][2], eigenMatrix[1][2], eigenMatrix[2][2])
    r.normalize()
    u.normalize()
    f.normalize()

    const rotatedVertices: Vector3[] = []
    for (const vertex of vertices) {
      const rotatedPoint = new Vector3(Vector3.Dot(r, vertex), Vector3.Dot(u, vertex), Vector3.Dot(f, vertex))
      rotatedVertices.push(rotatedPoint)
    }

    let vertexMin = rotatedVertices[0]
    let vertexMax = rotatedVertices[0]
    for (const vertex of rotatedVertices) {
      vertexMin = Vector3.Minimize(vertexMin, vertex)
      vertexMax = Vector3.Maximize(vertexMax, vertex)
    }

    const size = vertexMax.subtract(vertexMin).multiplyByFloats(0.5, 0.5, 0.5)
    const position = vertexMax.add(vertexMin).multiplyByFloats(0.5, 0.5, 0.5)

    const rotx = new Vector3(r.x, u.x, f.x)
    const roty = new Vector3(r.y, u.y, f.y)
    const rotz = new Vector3(r.z, u.z, f.z)
    const rotatedCenter = new Vector3(
      Vector3.Dot(rotx, position),
      Vector3.Dot(roty, position),
      Vector3.Dot(rotz, position),
    )

    const rx = r.multiplyByFloats(size.x, size.x, size.x)
    const uy = u.multiplyByFloats(size.y, size.y, size.y)
    const fz = f.multiplyByFloats(size.z, size.z, size.z)
    const obbPoints: Vector3[] = []
    obbPoints.push(rotatedCenter.subtract(rx).subtract(uy).subtract(fz))
    obbPoints.push(rotatedCenter.add(rx).subtract(uy).subtract(fz))
    obbPoints.push(rotatedCenter.add(rx).subtract(uy).add(fz))
    obbPoints.push(rotatedCenter.subtract(rx).subtract(uy).add(fz))
    obbPoints.push(rotatedCenter.subtract(rx).add(uy).add(fz))
    obbPoints.push(rotatedCenter.add(rx).add(uy).add(fz))
    obbPoints.push(rotatedCenter.add(rx).add(uy).subtract(fz))
    obbPoints.push(rotatedCenter.subtract(rx).add(uy).subtract(fz))

    return obbPoints
  }
}
