import { Setting } from '@/types/Label/Setting'
import { Patch } from '@/types/Label/Patch'
import { LabeledBody } from '@/types/Label/LabeledBody'
import { createPlacement, Placement } from '@/types/Label/Placement'
import {
  createLabeledBodyWithTransformation,
  LabeledBodyWIthTransformation,
} from '@/types/Label/LabeledBodyWIthTransformation'
import { LabelSetDto } from '@/types/Label/LabelSetDto'
import {
  AutomatedTrackableLabel,
  createAutomatedTrackableLabel,
  createManualTrackableLabel,
  ErrorCodes,
  ManualTrackableLabel,
  TrackableLabel,
} from '@/types/Label/TrackableLabel'
import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { getBuildPlanItemTransformationWithoutScale } from '@/utils/label/labelUtils'
import { LabelDirtyState, LabelSetMode, MarkingLocation } from '@/types/Label/enums'
import store from '@/store'

export class InteractiveLabelSet {
  static fromDto(
    labelSetDto: LabelSetDto,
    labelSetBpItems: IBuildPlanItem[],
    dirtyStateAddIfNew: boolean,
  ): InteractiveLabelSet {
    const labelSetFromStore: InteractiveLabelSet = store.getters['label/getLabelSetById'](labelSetDto.id)

    const relatedBodies: LabeledBodyWIthTransformation[] = labelSetDto.relatedBodies.map((relatedBody: LabeledBody) => {
      const bpItem: IBuildPlanItem = labelSetBpItems.find((item: IBuildPlanItem) => {
        return item.id === relatedBody.buildPlanItemId
      })
      const transformation: number[] = getBuildPlanItemTransformationWithoutScale(bpItem)
      const partId: string = bpItem.part.id
      const createdBody = createLabeledBodyWithTransformation(
        relatedBody.buildPlanItemId,
        relatedBody.componentId,
        relatedBody.geometryId,
        partId,
        transformation,
      )
      let existingBody = null
      if (labelSetFromStore) {
        existingBody = findExistingBody(labelSetFromStore.relatedBodies, createdBody)
      }

      return existingBody || createdBody
    })

    const selectedBodies: LabeledBodyWIthTransformation[] = labelSetDto.selectedBodies.map(
      (selectedBody: LabeledBody) => {
        const bpItem: IBuildPlanItem = labelSetBpItems.find((item: IBuildPlanItem) => {
          return item.id === selectedBody.buildPlanItemId
        })
        const transformation: number[] = getBuildPlanItemTransformationWithoutScale(bpItem)
        const partId: string = bpItem.part.id
        const createdBody = createLabeledBodyWithTransformation(
          selectedBody.buildPlanItemId,
          selectedBody.componentId,
          selectedBody.geometryId,
          partId,
          transformation,
        )
        let existingBody = null
        if (labelSetFromStore) {
          existingBody = findExistingBody(
            [...labelSetFromStore.selectedBodies, ...labelSetFromStore.relatedBodies],
            createdBody,
          )
        }

        return existingBody || createdBody
      },
    )

    let manualPlacements: Placement[] = []
    // Placement should not be created for automatic methods
    if (!labelSetDto.settings.placementMethodAutomatic) {
      manualPlacements = labelSetDto.patches.map((patch: Patch) => {
        const createdPlacement = createPlacement(
          patch.buildPlanItemId,
          patch.componentId,
          patch.geometryId,
          patch.orientation,
          patch.rotationAngle,
          patch.id,
        )
        let existingPlacement = null
        if (labelSetFromStore) {
          existingPlacement = findExistingPlacement(labelSetFromStore.manualPlacements, patch.id)
        }

        return existingPlacement || createdPlacement
      })
    }

    let labels: TrackableLabel[]
    if (labelSetDto.settings.placementMethodAutomatic) {
      labels = labelSetDto.patches.map((patch: Patch) => {
        const bodyId: string = [...relatedBodies, ...selectedBodies].find((body: LabeledBodyWIthTransformation) => {
          return (
            body.buildPlanItemId === patch.buildPlanItemId &&
            body.geometryId === patch.geometryId &&
            body.componentId === patch.componentId
          )
        }).id
        const createdAutomatedLabel = createAutomatedTrackableLabel(
          bodyId,
          patch.autoLocation,
          dirtyStateAddIfNew ? LabelDirtyState.Add : LabelDirtyState.None,
          patch.labelS3FileName && patch.patchS3FileName ? null : ErrorCodes.CommonError
        )
        let existingAutomatedLabel = null
        if (labelSetFromStore) {
          existingAutomatedLabel = findExistingAutomatedTrackableLabel(
            labelSetFromStore.labels as AutomatedTrackableLabel[],
            bodyId,
            patch.autoLocation,
          )
        }

        return existingAutomatedLabel || createdAutomatedLabel
      })
    } else {
      labels = labelSetDto.patches.map((patch: Patch) => {
        const placementId: string = patch.id
        const createdManualLabel = createManualTrackableLabel(
          placementId,
          dirtyStateAddIfNew ? LabelDirtyState.Add : LabelDirtyState.None,
          patch.labelS3FileName && patch.patchS3FileName ? null : ErrorCodes.CommonError
        )
        let existingManualLabel = null
        if (labelSetFromStore) {
          existingManualLabel = findExistingManualTrackableLabel(
            labelSetFromStore.labels as ManualTrackableLabel[],
            placementId,
          )
        }

        return existingManualLabel || createdManualLabel
      })
    }

    return {
      selectedBodies,
      relatedBodies,
      manualPlacements,
      labels,
      id: labelSetDto.id,
      settings: labelSetDto.settings,
      patches: labelSetDto.patches,
      orderIndex: labelSetDto.orderIndex,
      buildPlanId: labelSetDto.buildPlanId,
      mode: labelSetDto.mode,
    }
  }

  static toDto(interactiveLabelSet: InteractiveLabelSet): LabelSetDto {
    const relatedBodies: LabeledBody[] = interactiveLabelSet.relatedBodies.map(
      (relatedBody: LabeledBodyWIthTransformation) => {
        return {
          buildPlanItemId: relatedBody.buildPlanItemId,
          componentId: relatedBody.componentId,
          geometryId: relatedBody.geometryId,
          orientation: relatedBody.orientation,
          partId: relatedBody.partId,
          selectedAt: relatedBody.selectedAt,
        }
      },
    )

    const selectedBodies: LabeledBody[] = interactiveLabelSet.selectedBodies.map(
      (relatedBody: LabeledBodyWIthTransformation) => {
        return {
          buildPlanItemId: relatedBody.buildPlanItemId,
          componentId: relatedBody.componentId,
          geometryId: relatedBody.geometryId,
          orientation: relatedBody.orientation,
          partId: relatedBody.partId,
          selectedAt: relatedBody.selectedAt,
        }
      },
    )

    return {
      relatedBodies,
      selectedBodies,
      id: interactiveLabelSet.id,
      buildPlanId: interactiveLabelSet.buildPlanId,
      orderIndex: interactiveLabelSet.orderIndex,
      patches: interactiveLabelSet.patches,
      settings: interactiveLabelSet.settings,
      mode: interactiveLabelSet.mode,
    }
  }

  id: string
  settings: Setting
  patches: Patch[]
  orderIndex: number
  buildPlanId: string
  manualPlacements: Placement[]
  selectedBodies: LabeledBodyWIthTransformation[]
  relatedBodies: LabeledBodyWIthTransformation[]
  labels: TrackableLabel[]
  mode: LabelSetMode
}

/** Finds existing body by build plan item id, geometry id and component id.
 * @param {LabeledBody[]} bodies - list of bodies that may contain needed body
 * @param {LabeledBody} bodyToTest - body to be searched in a list
 * @returns {LabeledBody} - returns existing body if found or undefined if body does not exist yet
 */
function findExistingBody(bodies: LabeledBody[], bodyToTest: LabeledBody): LabeledBody {
  return bodies.find((body: LabeledBody) => {
    return (
      body.buildPlanItemId === bodyToTest.buildPlanItemId &&
      body.geometryId === bodyToTest.geometryId &&
      body.componentId === bodyToTest.componentId
    )
  })
}

/** Finds existing placement by patch id.
 * @param {Placement[]} placements - list of placements that may contain needed placement
 * @param {string} patchId - id of a patch that matches placement id
 * @returns {Placement} - returns existing placement if found or undefined if placement does not exist yet
 */
function findExistingPlacement(placements: Placement[], patchId: string): Placement {
  return placements.find((placement: Placement) => placement.id === patchId)
}

/** Finds existing placement by placement id.
 * @param {ManualTrackableLabel[]} labels - list of labels that may contain needed label
 * @param {string} placementId - id of a placement
 * @returns {ManualTrackableLabel} - returns existing manual trackable label if found or undefined if trackable
 * label does not exist yet
 */
function findExistingManualTrackableLabel(labels: ManualTrackableLabel[], placementId: string): ManualTrackableLabel {
  return labels.find((label: ManualTrackableLabel) => label.manualPlacementId === placementId)
}

/** Finds existing placement by body id and auto location.
 * @param {AutomatedTrackableLabel[]} labels - list of labels that may contain needed label
 * @param {string} bodyId - id of a body
 * @param {MarkingLocation} autoLocation - the location of a label
 * @returns {ManualTrackableLabel} - returns existing automated trackable label if found or undefined if trackable
 * label does not exist yet
 */
function findExistingAutomatedTrackableLabel(
  labels: AutomatedTrackableLabel[],
  bodyId: string,
  autoLocation: MarkingLocation,
): AutomatedTrackableLabel {
  return labels.find((label: AutomatedTrackableLabel) => label.bodyId === bodyId && label.autoLocation === autoLocation)
}
