
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { DeleteType } from '@/constants'
import Button from '@/components/controls/Common/Button.vue'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { getItemTypeName } from '@/utils/fileExplorerItem/fileExplorerItemUtils'
import StoresNamespaces from '@/store/namespaces'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: {
    Button,
  },
})
export default class PermanentlyDeleteItemModal extends Vue {
  @fileExplorerStore.Action fetchItemsWithoutAccess: () => Promise<void>
  @fileExplorerStore.Getter getItemsWithoutAccess: FileExplorerItem[]

  @Prop() label: string
  @Prop() onClose: () => void
  @Prop() onConfirm: () => Promise<void>
  @Prop() relatedItems: FileExplorerItem[]
  @Prop() isLoading: boolean
  @Prop({ default: DeleteType.Selected }) mode: DeleteType

  dialog: boolean = true
  isMounted = false

  get getSortedRelatedItemsLabels() {
    return this.relatedItems
      .sort((a, b) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
      .sort((a, b) => {
        return a.itemType - b.itemType
      })
      .map((item) => `${getItemTypeName(item)} ${item.name}`)
  }

  get isDeleteAllMode(): boolean {
    return this.mode === DeleteType.All
  }

  async beforeMount() {
    if (this.isDeleteAllMode) await this.fetchItemsWithoutAccess()
    this.isMounted = true
  }

  async onCancelClick() {
    this.dialog = false
    this.onClose()
  }

  async onDeleteClick() {
    await this.onConfirm()
    this.dialog = false
  }
}
