import StoresNamespaces from '@/store/namespaces'
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { TextElement } from '@/types/Label/TextElement'
import { MarkingContentElementType } from '@/types/Label/enums'

const labelStore = namespace(StoresNamespaces.Labels)

@Component
export class DynamicElementSettingsMixin extends Vue {
  @labelStore.Getter variantDynamicElements: TextElement[]

  getNextFreeId(additionalIds?: number[]): number {
    let usedIDs = this.variantDynamicElements.map((te: TextElement) => te.elementIDNumber)
    if (additionalIds) {
      usedIDs = usedIDs.concat(additionalIds)
    }
    let id = 0
    while (usedIDs.includes(id)) {
      id += 1
    }
    return id
  }

  getNextFreeNameIdForType(type: MarkingContentElementType) {
    const elements = this.variantDynamicElements.filter((te: TextElement) => te.type === type)
    const usedIDs = elements.map((te: TextElement) => +te.title.split(' ').pop())
    let id = 1
    while (usedIDs.includes(id)) {
      id += 1
    }
    return id
  }
}
