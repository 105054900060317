
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import Icon, { IconNames } from '@/components/icons/Icon.vue'
import NumberField from '@/components/controls/Common/NumberField.vue'
import { TranslateResult } from 'vue-i18n'
import { LabelDirtyState, MarkingLocation } from '@/types/Label/enums'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { IJob } from '@/types/PartsLibrary/Job'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import { AutomatedTrackableLabel, createAutomatedTrackableLabel, TrackableLabel } from '@/types/Label/TrackableLabel'
import { Visualization } from '@/visualization'

const labelStore = namespace(StoresNamespaces.Labels)

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component({
  components: { Icon, NumberField },
})
export default class PlacementRules extends Vue {
  @labelStore.Getter activeLabelSet: InteractiveLabelSet
  @labelStore.Getter isLabelSetHasLabelWithCommandId: (labelSetId: string) => boolean

  @labelStore.Mutation addTrackableLabels: (payload: TrackableLabel[]) => void
  @labelStore.Mutation setActiveLabelSetSettingsProp: (payload: { propName: string; value: any }) => void
  @labelStore.Mutation removeTrackableLabels: (ids: string[]) => void

  @labelStore.Action makeTrackableLabelsDirty: (
    payload: Array<{
      labelSetId: string
      id: string
      dirtyState: LabelDirtyState
    }>,
  ) => void
  @labelStore.Action scheduleExecuteCommandAbortable: (shouldAbort: boolean) => void
  @labelStore.Action('setActiveLabelSetSettingsProp') setActiveLabelSetSettingsPropAction: (payload: {
    propName: string
    value: any
  }) => void

  @labelStore.Action invalidateLabels: (
    payload: Array<{
      labelSetId: string
      id: string
      dirtyState: LabelDirtyState
      force?: boolean
    }>,
  ) => void

  @buildPlansStore.Getter isReadOnly: boolean
  @buildPlansStore.Getter getSelectedBuildPlanFinalizingJobs: IJob[]
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getCompleteAndRunningSlicingJobs: (id: string) => IJob[]
  @buildPlansStore.Getter isLabelReadOnly: boolean

  @visualizationStore.Getter visualization: Visualization

  placementSides: Array<{
    iconName: IconNames
    selected: boolean
    label: TranslateResult
    location: MarkingLocation
  }> = []
  hovered = null

  beforeMount() {
    this.setupPlacementSides()
  }

  setupPlacementSides() {
    const placementSides = [
      {
        iconName: IconNames.FrontView,
        selected: this.activeLabelSet.settings.placementAutoLocations.includes(MarkingLocation.Front),
        label: this.$t('labelTool.placement.placementSides.front'),
        location: MarkingLocation.Front,
      },
      {
        iconName: IconNames.BackView,
        selected: this.activeLabelSet.settings.placementAutoLocations.includes(MarkingLocation.Back),
        label: this.$i18n.t('labelTool.placement.placementSides.back'),
        location: MarkingLocation.Back,
      },
      {
        iconName: IconNames.LeftView,
        selected: this.activeLabelSet.settings.placementAutoLocations.includes(MarkingLocation.Left),
        label: this.$i18n.t('labelTool.placement.placementSides.left'),
        location: MarkingLocation.Left,
      },
      {
        iconName: IconNames.RightView,
        selected: this.activeLabelSet.settings.placementAutoLocations.includes(MarkingLocation.Right),
        label: this.$i18n.t('labelTool.placement.placementSides.right'),
        location: MarkingLocation.Right,
      },
      {
        iconName: IconNames.TopView,
        selected: this.activeLabelSet.settings.placementAutoLocations.includes(MarkingLocation.Top),
        label: this.$i18n.t('labelTool.placement.placementSides.top'),
        location: MarkingLocation.Top,
      },
      {
        iconName: IconNames.BottomView,
        selected: this.activeLabelSet.settings.placementAutoLocations.includes(MarkingLocation.Bottom),
        label: this.$i18n.t('labelTool.placement.placementSides.bottom'),
        location: MarkingLocation.Bottom,
      },
    ]

    const selected = placementSides.filter((side) => side.selected)
    const deselected = placementSides.filter((side) => !side.selected)
    this.placementSides = selected.concat(deselected)
  }

  onSideClicked(side: { iconName: IconNames; selected: boolean; label: TranslateResult; location: MarkingLocation }) {
    const shouldAbort = this.isLabelSetHasLabelWithCommandId(this.activeLabelSet.id)
    side.selected = !side.selected
    const selected = this.placementSides.filter((s) => s.selected)
    const deselected = this.placementSides.filter((s) => !s.selected)
    this.placementSides = selected.concat(deselected)
    const locationIds = this.placementSides.filter((s) => s.selected).map((s) => s.location)
    const bodies = [...this.activeLabelSet.selectedBodies, ...this.activeLabelSet.relatedBodies]
    const labels: AutomatedTrackableLabel[] = this.activeLabelSet.labels as AutomatedTrackableLabel[]

    // In this case we should create new trackable label for each selected/related body if there is no such label
    if (locationIds.includes(side.location)) {
      const toForceMarkAsAdded = []
      const toAdd = bodies
        .map((body) => {
          const existingLabel = labels.find((label) => label.autoLocation === side.location && label.bodyId === body.id)
          if (!existingLabel) {
            return createAutomatedTrackableLabel(body.id, side.location, LabelDirtyState.Add)
          }

          toForceMarkAsAdded.push({
            labelSetId: this.activeLabelSet.id,
            id: existingLabel.id,
            dirtyState: LabelDirtyState.Add,
            force: true,
          })
        })
        .filter((label) => label)
      this.addTrackableLabels(toAdd)
      this.makeTrackableLabelsDirty(toForceMarkAsAdded)
    } else {
      const toRemoveSilently = []
      const toRemove = []
      labels
        .filter((label) => label.autoLocation === side.location)
        .forEach((label) => {
          const labelMesh = this.visualization.getLabelMeshByTrackId(label.id)
          if (labelMesh) {
            toRemove.push({ labelSetId: this.activeLabelSet.id, id: label.id, dirtyState: LabelDirtyState.Remove })
          } else {
            toRemoveSilently.push(label.id)
          }
        })

      // Mark labels as dirty and should be removed
      this.makeTrackableLabelsDirty(toRemove)
      // Invalidate such labels on babylon side
      this.invalidateLabels(toRemove)
      // Remove trackableLabels without triggering execute command
      if (toRemoveSilently.length) {
        this.removeTrackableLabels(toRemoveSilently)
      }
    }

    this.setActiveLabelSetSettingsProp({ propName: 'placementAutoLocations', value: locationIds })
    if (locationIds.length !== this.activeLabelSet.settings.placementMaxCount) {
      this.setActiveLabelSetSettingsProp({ propName: 'placementMaxCount', value: locationIds.length })
    }

    this.$emit('change', { activeCount: this.amountOfSelectedSides })
    // Due to fact that we changed settings without triggering execute we should do it manually
    this.scheduleExecuteCommandAbortable(shouldAbort)
  }

  arrowLeftClick(i: number) {
    ;[this.placementSides[i - 1], this.placementSides[i]] = [this.placementSides[i], this.placementSides[i - 1]]
    this.$forceUpdate()
    this.hovered = this.placementSides[i].iconName
    const locationIds = this.placementSides.filter((side) => side.selected).map((side) => side.location)
    this.setActiveLabelSetSettingsProp({ propName: 'placementAutoLocations', value: locationIds })
  }

  arrowRightClick(i: number) {
    ;[this.placementSides[i + 1], this.placementSides[i]] = [this.placementSides[i], this.placementSides[i + 1]]
    this.$forceUpdate()
    this.hovered = this.placementSides[i].iconName
    const locationIds = this.placementSides.filter((side) => side.selected).map((side) => side.location)
    this.setActiveLabelSetSettingsProp({ propName: 'placementAutoLocations', value: locationIds })
  }

  get amountOfSelectedSides() {
    return this.placementSides.filter((side) => side.selected).length
  }
}
