
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import VSelect from 'vuetify/lib/components/VSelect'
import { getPropertyFromItem } from 'vuetify/lib/util/helpers'
import MachineSelectorTemplate from '@/components/controls/Common/MachineSelectorTemplate.vue'
import { ClickOutsideMixin } from '@/components/layout/mixins/ClickOutsideMixin'
import StoresNamespaces from '@/store/namespaces'

const commonStore = namespace(StoresNamespaces.Common)

@Component({
  components: {
    MachineSelectorTemplate,
  },
})
export default class Selector extends ClickOutsideMixin {
  @commonStore.Getter tooltipOpenDelay: number

  @Prop() placeholder!: string
  @Prop() label!: string
  @Prop() items: any[]
  @Prop() value: number
  @Prop() disabled: boolean
  @Prop({ type: [Object, String], default: '' }) readonly rules: object | string
  @Prop({ default: false }) hideDetails: boolean
  @Prop({ type: [String, Array, Function], default: 'id' }) itemValue: object | string
  @Prop({ type: [String, Array, Function], default: 'name' }) itemText: object | string
  @Prop({ type: [String, Array, Function], default: null }) itemTextMark: object | string
  @Prop({ type: [String, Array, Function], default: null }) itemTextMessage: object | string
  @Prop({ type: Boolean, default: false }) isMachineSelector: boolean
  @Prop({ default: 'mdi-chevron-down' }) appendIcon: string
  @Prop({ type: Boolean, default: false }) separator: boolean
  @Prop({ type: Boolean, default: false }) middleTruncate: boolean

  // Well... This input field is basically is a fix of YPVJZ-4118
  // At current vuetify version (2.1.10) there is an issue in v-menu that is a part of v-select
  // Left position of such menus is calculated based on scrollLeft of a parent element instead of offsetLeft
  // That leads to a behavior where menu is mispositioned in cases when it should be rendered based on an element
  // with absolute positioning but page is scrolled to the right
  // This variable MUST be used only in a problematic selectors 'cause it overrides some default vuetify settings
  @Prop({ default: false }) overrideLeft: boolean
  @Prop({ default: false }) overrideRight: boolean
  @Prop({ default: false }) isVisualizationTool: boolean
  @Prop({ default: false }) enumerateItems: boolean
  @Prop({ default: '' }) suffix: string
  @Prop({ default: false }) returnObject: boolean
  @Prop({ default: false }) isNewPrintOrderTool: boolean
  @Prop({ default: true }) attach: boolean

  $refs!: {
    selector: VSelect
  }

  isMounted: boolean = false

  get menuProps() {
    return {
      openOnClick: false,
      closeOnClick: false,
      closeOnContentClick: false,
      offsetY: true,
      offsetOverflow: true,
      transition: false,
      maxHeight: 300,
      contentClass: this.isVisualizationTool ? 'viz-dropdown' : undefined,
      nudgeTop: this.isVisualizationTool ? 1 : 4,
      rounded: this.isVisualizationTool ? 'lg' : undefined,
      minWidth: this.isVisualizationTool ? 120 : undefined,
    }
  }

  get isMenuOpened() {
    return (
      this.isMounted &&
      this.$refs.selector &&
      this.$refs.selector.$refs &&
      this.$refs.selector.$refs.menu &&
      this.$refs.selector.$refs.menu.isActive
    )
  }

  @Watch('isMenuOpened')
  onIsMenuActiveChanged() {
    this.setListenerForClickOutside(this.isMenuOpened, this.closeSelectorMenu)
    this.$emit('isOpenedChange', this.isMenuOpened)
  }

  getItemText(item) {
    return this.enumerateItems ? this.getIndexedItemName(item) : getPropertyFromItem(item, this.itemText, item)
  }

  getItemTextMark(item) {
    if (!this.itemTextMark) {
      return null
    }

    return getPropertyFromItem(item, this.itemTextMark, item)
  }

  getItemTextMessage(item) {
    if (!this.itemTextMessage) {
      return null
    }
    return this.itemTextMessage[item]
  }

  mounted() {
    this.isMounted = true
    if (this.overrideLeft) {
      // Check whether menu is already rendered
      if (this.$refs.selector.$refs && this.$refs.selector.$refs.menu) {
        this.$refs.selector.$refs.menu.calcLeft = this.calcMenuLeftPosition
      }
    }

    if (this.overrideRight) {
      if (this.$refs.selector.$refs && this.$refs.selector.$refs.menu) {
        this.$refs.selector.$refs.menu.calcLeft = this.calcMenuLeftForRightAllignment
      }
    }
  }

  calcMenuLeftPosition = () => {
    // Vuetify stores reference to an 'activator' html element for v-menu that is a part of v-select
    // Basically, if v-menu should be positioned under or above - it should be aligned by left side of activator
    if (this.$refs.selector.$refs.menu && this.$refs.selector.$refs.menu.activator) {
      return `${this.$refs.selector.$refs.menu.activator.getBoundingClientRect().x}px`
    }
  }

  calcMenuLeftForRightAllignment = (width: number) => {
    if (this.$refs.selector.$refs.menu && this.$refs.selector.$refs.menu.activator) {
      const rect = this.$refs.selector.$refs.menu.activator.getBoundingClientRect()
      return `${rect.width - width}px`
    }
  }

  input(e) {
    this.$emit('input', e)
  }

  toggleDropDownListState() {
    const { isActive } = this.$refs.selector.$refs.menu
    this.$refs.selector.$refs.menu.isActive = !isActive
  }

  closeSelectorMenu() {
    this.$refs.selector.$refs.menu.isActive = false
  }

  private getIndexedItemName(item) {
    const index = this.items.indexOf(item) + 1
    return `${index}. ${getPropertyFromItem(item, this.itemText, item)}`
  }
}
