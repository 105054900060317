import i18n from '@/plugins/i18n'
import axios, { AxiosRequestConfig } from 'axios'
import { ApiValidationError } from './common'
import store from '../store'
import { EndpointsUrls } from '@/configs/config'

const defaultContentType = 'application/json'

let requestsCounter: number = 0 // Variable to control pending requests
const byItemIdsUrl = `${EndpointsUrls.Jobs}/byItemIds`
const costUrl = '/cost'
const variantJobs = 'variantJobs'

export const enum HttpHeaders {
  Authorization = 'Authorization',
  ContentType = 'Content-Type',
  Tenant = 'X-Tenant-Id',
  Version = 'X-Amp-Version',
}

export async function initHttpModule(onAuthenticationFailed: () => void) {
  axios.defaults.baseURL = window.env.VUE_APP_API_URL
  axios.defaults.headers.common[HttpHeaders.ContentType] = defaultContentType
  axios.defaults.headers.common[HttpHeaders.Version] = window.env.VUE_APP_AMP_VERSION

  // Added interceptor before request for the disabling Undo/Redo command manager
  // As we need have control and Undo/Redo command manager over each HTTP request
  // we need use axios.interceptors.request for the disabling undo/redo buttons before HTTP request
  // and put the enabling undo/redo buttons in the axios.interceptors.response when HTTP request is complete
  axios.interceptors.request.use(
    (config) => {
      // Ignore the disabling Undo/Redo command manager for the
      // 'api/printModel/jobs/byItemIds', '/cost', '/variantJobs' requests
      const isIgnoredUrs = getIgnoredUrl(config)
      if (isIgnoredUrs) {
        return config
      }

      requestsCounter += 1

      store.commit('commandManager/disable')
      return config
    },
    (error) => {
      const { config } = error.request

      // Disable Undo/Redo command manager if error occurred
      const isIgnoredUrl = getIgnoredUrl(config)
      if (!isIgnoredUrl) {
        requestsCounter -= 1
        store.commit('commandManager/disable')
      }

      return Promise.reject(error)
    },
  )
  axios.interceptors.response.use(
    (response) => {
      // Ignore the enabling Undo/Redo command manager for the
      // 'api/printModel/jobs/byItemIds', '/cost', '/variantJobs' requests
      const isIgnoredUrl = getIgnoredUrl(response.config)
      if (isIgnoredUrl) {
        return response.data
      }

      requestsCounter -= 1

      // If no pending requests enable Undo/Redo command manager
      if (requestsCounter === 0) {
        store.commit('commandManager/enable')
      }

      return response.data
    },
    (error) => {
      const { config } = error.response

      // Disable Undo/Redo command manager if error occurred
      const isIgnoredUrl = getIgnoredUrl(config)
      if (!isIgnoredUrl) {
        requestsCounter -= 1
        store.commit('commandManager/disable')
      }

      let responseMessage = error.response.data.message || error.response.data.error
      if (responseMessage === undefined && typeof error.response.data === 'string') {
        responseMessage = error.response.data
      }
      // tslint:disable: no-string-literal
      error.response['message'] = responseMessage

      // Handle validation errors which are throwed by the class-validator
      if (error.response.status === 400) {
        const { message } = error.response
        if (Array.isArray(message)) {
          const messages = message
            .filter((object) => object['constraints'] !== undefined)
            .map((object: ApiValidationError) => {
              return Object.values(object['constraints']).map((text: string) => text)
            })
          if (messages.length) {
            error.response['message'] = i18n.t('validationError')
            error.response['validationMessages'] = messages
          }
        }
      }
      // tslint:enable: no-string-literal

      if (error.response.status === 401) {
        onAuthenticationFailed()
      }

      if (error.response.status === 409 && responseMessage === 'version-mismatch') {
        // 409 with version-mismatch text means current UI doesn't correspond to the server version
        // this can happen after redeployments and if user switches between different environments
        // automatic page reload fetches correct UI version
        location.reload()
      }

      return Promise.reject(error.response)
    },
  )
}

function getIgnoredUrl(config: AxiosRequestConfig) {
  return [byItemIdsUrl, costUrl, variantJobs].some((urlToIgnore) => config.url.includes(urlToIgnore))
}

export function setAuthorizationToken(authToken: string) {
  axios.defaults.headers.common[HttpHeaders.Authorization] = `bearer ${authToken}`
}

export function setTenant(tenant: string) {
  axios.defaults.headers.common[HttpHeaders.Tenant] = tenant
}

export function getHeaders(): any {
  return axios.defaults.headers ? axios.defaults.headers.common : null
}

export function get<T>(apiUrl: string, options: AxiosRequestConfig = {}) {
  return axios.get<T>(apiUrl, options)
}

export function post<T>(apiUrl: string, data: object, options: AxiosRequestConfig = {}) {
  return axios.post<T>(apiUrl, data, options)
}

export function put<T>(apiUrl: string, data: object, options: AxiosRequestConfig = {}) {
  return axios.put<T>(apiUrl, data, options)
}

export function del<T>(apiUrl: string, options: AxiosRequestConfig = {}) {
  return axios.delete<T>(apiUrl, options)
}
