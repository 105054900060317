
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Menu from '@/components/controls/Common/Menu.vue'
import BuildPlanInsightsPanel from '@/components/layout/buildPlans/BuildPlanInsightsPanel.vue'
import MachineMaterialSelectionMixin from '@/components/layout/buildPlans/mixins/MachineMaterialSelection'
import BuildPlanPartPropertiesParts from '@/components/layout/buildPlans/partProperties/BuildPlanPartPropertiesParts.vue'
import ExpansionItemsList from '@/components/layout/FileExplorer/Details/ExpansionItemsList.vue'
import { DateByUserMixin } from '@/components/layout/mixins/DateByUserMixin'
import Splitter from '@/components/layout/Splitter.vue'
import StoresNamespaces from '@/store/namespaces'
import { IBuildPlan, ISelectable } from '@/types/BuildPlans/IBuildPlan'
import { IInsightSettings } from '@/types/BuildPlans/IBuildPlanInsight'
import { IInsightsCount } from '@/types/Common/Insights'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { DetailsPanelExpansionItem } from '@/types/FileExplorer/DetailsPanelExpansionItem'
import { PrintingTypes } from '@/types/IMachineConfig'
import { IJob } from '@/types/PartsLibrary/Job'
import { formatBytes } from '@/utils/converter/sizeConvertor'
import { getPrintOrderStatus } from '@/utils/download'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const partsStore = namespace(StoresNamespaces.Parts)

@Component({
  components: {
    BuildPlanInsightsPanel,
    Splitter,
    Menu,
    ExpansionItemsList,
    BuildPlanPartPropertiesParts,
  },
})
export default class PrintOrderPreviewDetails extends Mixins(MachineMaterialSelectionMixin, DateByUserMixin) {
  @buildPlansStore.Getter getSelectedParts: ISelectable[]
  @buildPlansStore.Getter insightSettings: IInsightSettings
  @buildPlansStore.Getter insightsCount: IInsightsCount
  @buildPlansStore.Getter getSelectedMachineConfigPk: VersionablePk | null
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @partsStore.Getter getBuildPlanParts: (buildPlan: IBuildPlan) => DetailsPanelExpansionItem[]

  @buildPlansStore.Mutation setInsightSettings: Function

  @Prop() job: IJob
  @Prop() numberOfParts: number
  @Prop() buildPlanName: string
  @Prop() variant: string
  @Prop() fileSize: number

  insightMenuIsShown: boolean = false

  insightShowWarningsChanged(v: boolean) {
    this.setInsightSettings({ ...this.insightSettings, ...{ showWarnings: !v } })
  }

  get parts() {
    const buildPlan = this.getBuildPlan
    return this.getBuildPlanParts(buildPlan)
  }

  get selectedMachineConfig() {
    return this.getMachineConfigByPk(this.getSelectedMachineConfigPk)
  }

  get printOrderStatus() {
    return getPrintOrderStatus(this.job)
  }

  get machineName() {
    return this.selectedMachineConfig ? this.selectedMachineConfig.name : ''
  }

  get materialName() {
    const parameters = this.job.parameters && JSON.parse(this.job.parameters)
    return (parameters && parameters.materialName) || ''
  }

  get createdInfo(): string {
    return this.getFormatedActionInfo(this.job.createdDate, this.job.runByUser)
  }

  get fileName(): string {
    const parameters = this.job.parameters && JSON.parse(this.job.parameters)
    if (parameters) {
      return parameters && parameters.modality === PrintingTypes.BinderJet
        ? parameters.binderJet && parameters.binderJet.arsf && parameters.binderJet.arsf.name
        : parameters.dmlm
        ? parameters.dmlm.printPackageZip
          ? parameters.dmlm.printPackageZip.name
          : null
        : null
    }
    return null
  }

  get convertedFileSize(): string {
    if (!this.fileSize) {
      return null
    }

    const formated = formatBytes(this.fileSize)
    return `${formated.value} ${formated.units}`
  }

  get shouldShowPartsProperties(): boolean {
    return this.getSelectedParts && this.getSelectedParts.length > 0
  }
}
