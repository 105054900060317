export enum DataType {
  Text = 'TEXT',
  Number = 'NUMBER',
}

export interface ICostEstimationListHeader {
  text: string
  value: string
  headerKey?: string
  meta: {
    dataType: DataType
    sortable: boolean
    filterable: boolean
  }
  data: {
    filterOptions: Array<{ name: string; value: any }>
    filtered: string[] | number[]
  }
}

type PartCost = {
  min: number
  max: number
}

type CostEstimationResult = {
  machineName: string
  partCost: PartCost
}

export interface ICostEstimationListItemViewModel {
  id: number
  projectName: string
  materialName: string
  numberOfPartsPerYear: number
  manufacturingRegion: string
  imageUrl: string
  createdAt: string
  result: CostEstimationResult[]
  isDisabled: boolean
}
