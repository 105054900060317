function round(method: string, number: number, precision: number) {
  if (typeof number !== 'number') {
    throw new TypeError('Expected value to be a number')
  }

  if (precision === Infinity) {
    return number
  }

  if (!Number.isInteger(precision)) {
    throw new TypeError('Expected precision to be an integer')
  }

  let numberClone: number = number
  const isRoundingAndNegative = method === 'round' && numberClone < 0
  if (isRoundingAndNegative) {
    numberClone = Math.abs(numberClone)
  }

  let numStr: string
  let exponent: any
  ;[numStr, exponent] = `${numberClone}e`.split('e')
  let result = Math[method](`${numStr}e${Number(exponent) + precision}`)
  ;[numStr, exponent] = `${result}e`.split('e')
  result = Number(`${numStr}e${Number(exponent) - precision}`)

  if (isRoundingAndNegative) {
    result = -result
  }

  return result
}

const roundUp = round.bind(null, 'ceil')
const roundDown = round.bind(null, 'floor')

export { roundUp, roundDown }

export default round.bind(null, 'round')
