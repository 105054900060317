
import StoresNamespaces from '@/store/namespaces'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import { CharacterLengthControl } from '@/types/Label/enums'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component
export default class PrintLabelTooltipContent extends Vue {
  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan

  @Prop() userEntry: any

  get requiredCharactersText() {
    if (this.userEntry._cachedSpecificsJSON) {
      const userEntryJSON = JSON.parse(this.userEntry._cachedSpecificsJSON)
      const selectedInputType = userEntryJSON.selectedInputType
      switch (selectedInputType) {
        case CharacterLengthControl.Specific:
          return `${this.userEntry.lengthMin} Characters`
        case CharacterLengthControl.Maximum:
          return `Up to ${this.userEntry.lengthMax} Characters`
        case CharacterLengthControl.Range:
          return `${this.userEntry.lengthMin} - ${this.userEntry.lengthMax} Characters`
      }
    }
  }

  get userEntryPrompt() {
    let promptText = this.userEntry.title
    const json = this.userEntry._cachedSpecificsJSON ? JSON.parse(this.userEntry._cachedSpecificsJSON) : null
    if (json && json.promptText) {
      promptText = json.promptText
    }
    return promptText
  }
}
