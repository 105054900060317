import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Epsilon } from '@babylonjs/core/Maths'

import { BoundingBox } from '@/visualization/models/DataModel'
import { IBuildPlan, IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { ManufacturingRegions } from '@/types/ManufacturingRegions'
import { BuildPlanCost } from '@/pages/BuildPlans/models/BuildPlanCost'
import { BuildPlanCostItemDTO } from '@/pages/BuildPlans/dtos/BuildPlanCostItemDTO'
import { BuildPlanCostCreateDTO } from '@/pages/BuildPlans/dtos/BuildPlanCostCreateDTO'
import { DEFAULT_BUILD_PLAN_COST, DEFAULT_UNITS_ID } from '@/constants'
import { debounce } from '@/utils/debounce'
import StoresNamespaces from '@/store/namespaces'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { IMaterial } from '@/types/IMaterial'
import { IMachineConfig } from '@/types/IMachineConfig'
import { ItemType, ItemSubType } from '@/types/FileExplorer/ItemType'
import { isNil } from '@/utils/common'

const visualizationStore = namespace(StoresNamespaces.Visualization)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

const CALCULATE_BUILD_PLAN_COST_DEBOUNCE = 3000

const ALLOWED_TYPES_FOR_CALCULATION = {
  [ItemType.BuildPlan]: [ItemSubType.None],
}

@Component
export default class BuildPlanCostMixin extends Vue {
  @buildPlansStore.Getter getAllBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getBuildPlanItemDTOs: BuildPlanCostItemDTO[]
  @buildPlansStore.Getter getSelectedMachineConfigPk: VersionablePk | null
  @buildPlansStore.Getter getMachineConfigByPk: (pk: VersionablePk) => IMachineConfig
  @buildPlansStore.Getter getMaterialByPk: (pk: VersionablePk) => IMaterial
  @buildPlansStore.Getter getSelectedMaterialPk: VersionablePk | null
  @buildPlansStore.Getter getBuildPlanCost: BuildPlanCost
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter isLockedForUser: boolean
  @visualizationStore.Getter boundingBoxForPartsOnly: BoundingBox

  @buildPlansStore.Action updateBuildPlanV1: (payload: {
    buildPlan: IBuildPlan
    hideAPIErrorMessages?: boolean
  }) => Promise<IBuildPlan>
  @buildPlansStore.Action calcBuildPlanCost: (payload: {
    buildPlanId: string
    dto: BuildPlanCostCreateDTO
  }) => IBuildPlan

  lastCostConfiguration: BuildPlanCostCreateDTO & {
    materialId: number
    machineConfigId: number
    buildPlanId: string
  } = null
  defaultManufacturingRegion = ManufacturingRegions.Americas
  calcBuildPlanCostDebounced: (payload: { buildPlanId: string; dto: BuildPlanCostCreateDTO }) => void
  isViewer = false

  get selectedMachineConfigPk() {
    return this.getSelectedMachineConfigPk
  }

  get selectedMaterialPk() {
    return this.getSelectedMaterialPk
  }

  get shouldCalculate(): boolean {
    const { itemType, subType } = this.getBuildPlan
    const allowedSubTypes = ALLOWED_TYPES_FOR_CALCULATION[itemType]

    return !isNil(allowedSubTypes) && allowedSubTypes.includes(subType) && !this.isViewer && !this.isLockedForUser
  }

  beforeMount() {
    this.calcBuildPlanCostDebounced = debounce(CALCULATE_BUILD_PLAN_COST_DEBOUNCE, this.calcBuildPlanCost)
  }

  @Watch('boundingBoxForPartsOnly')
  onCostUpdated(bbox: BoundingBox) {
    if (!this.shouldCalculate) {
      return
    }

    if (!this.getAllBuildPlanItems.length) {
      this.lastCostConfiguration = null
    }

    const items: BuildPlanCostItemDTO[] = this.getBuildPlanItemDTOs

    if (!Array.isArray(items)) {
      return
    }

    // if cost is already calculated for this configuration - skip the calculation
    if (this.matchLatestConfiguration() && !this.isNewlyCreatedVariant()) {
      return
    }

    // Machine Config ID
    // Machine Config ID is required for cost calculation
    if (!this.selectedMachineConfigPk) {
      return
    }

    const machineConfig = this.getMachineConfigByPk(this.selectedMachineConfigPk)
    if (!machineConfig) {
      return
    }

    // Material ID
    // Material ID is required for cost calculation
    if (!this.selectedMaterialPk) {
      return
    }

    const material = this.getMaterialByPk(this.selectedMaterialPk)
    if (!material) {
      return
    }

    // Unit IDs
    // Unit IDs are required for cost calculation
    const unitsId = DEFAULT_UNITS_ID

    // Manufacturing region
    // Manufacturing region is required for cost calculation
    const manufacturingRegion = this.defaultManufacturingRegion

    const { minX, minY, minZ, maxX, maxY, maxZ } = bbox

    const buildLengthOverride = maxY - minY
    const buildWidthOverride = maxX - minX
    const buildHeightOverride = maxZ - minZ

    const parts: BuildPlanCostItemDTO[] = items.filter(Boolean).map((item) => {
      return {
        length: item.length,
        width: item.width,
        height: item.height,
        scaling: { x: 1, y: 1, z: 1 },
        surfaceArea: item.surfaceArea,
        volume: item.volume,
        itemId: item.itemId,
      }
    })

    const buildPlanCreateCostDTO: BuildPlanCostCreateDTO = {
      buildHeightOverride,
      buildLengthOverride,
      buildWidthOverride,
      manufacturingRegion,
      parts,
      unitsId,
      machineConfigId: machineConfig.id,
      machineConfigVersion: machineConfig.version,
      materialId: material.id,
      materialVersion: material.version,
    }

    try {
      const mightHaveCostCalculated = !this.lastCostConfiguration

      this.lastCostConfiguration = {
        buildHeightOverride,
        buildLengthOverride,
        buildWidthOverride,
        manufacturingRegion,
        parts,
        unitsId,
        buildPlanId: this.getBuildPlan.id,
        machineConfigId: this.selectedMachineConfigPk.id,
        machineConfigVersion: machineConfig.version,
        materialId: this.selectedMaterialPk.id,
        materialVersion: material.version,
      }

      if (mightHaveCostCalculated) {
        const isCostCalculated = JSON.stringify(this.getBuildPlanCost) !== JSON.stringify(DEFAULT_BUILD_PLAN_COST)
        if (items.length === 0 && isCostCalculated) {
          const updateBuildPlatDto = { cost: null }
          this.updateBuildPlanV1({ buildPlan: updateBuildPlatDto as IBuildPlan })
          return
        }
        if (items.length === 0 && !isCostCalculated) {
          return
        }
        if (isCostCalculated) {
          return
        }
      }

      this.calcBuildPlanCostDebounced({
        buildPlanId: this.getBuildPlan.id,
        dto: buildPlanCreateCostDTO,
      })
    } catch (error) {
      console.error(`Error while calculating build plan cost: ${error.message}`)
    }
  }

  public resetBuildCostConfiguration() {
    this.lastCostConfiguration = null
  }

  private isNewlyCreatedVariant(): boolean {
    return (
      this.lastCostConfiguration &&
      this.lastCostConfiguration.buildPlanId !== this.getBuildPlan.id &&
      !this.getBuildPlan.cost
    )
  }

  private matchLatestConfiguration(): boolean {
    if (!this.lastCostConfiguration) {
      return false
    }

    const isSameMachineConfig =
      this.selectedMachineConfigPk && this.selectedMachineConfigPk.id === this.lastCostConfiguration.machineConfigId
    const isSameMaterial =
      this.selectedMaterialPk && this.selectedMaterialPk.id === this.lastCostConfiguration.materialId

    return isSameMachineConfig && isSameMaterial && this.matchBuildBoxConfigurations()
  }

  private matchBuildBoxConfigurations(): boolean {
    const oldItems = this.lastCostConfiguration.parts
    const newItems = this.getBuildPlanItemDTOs

    if (!Array.isArray(oldItems) || !Array.isArray(newItems) || oldItems.length !== newItems.length) {
      return false
    }

    const { minX, minY, minZ, maxX, maxY, maxZ } = this.boundingBoxForPartsOnly
    const {
      buildLengthOverride: oldBuildLength,
      buildWidthOverride: oldBuildWidth,
      buildHeightOverride: oldBuildHeight,
    } = this.lastCostConfiguration

    const oldBuildSizes = {
      length: oldBuildLength,
      width: oldBuildWidth,
      height: oldBuildHeight,
    }

    const newBuildSizes = {
      length: maxY - minY,
      width: maxX - minX,
      height: maxZ - minZ,
    }

    const buildSizeKeysToCompare = ['length', 'width', 'height']
    const isBuildSizeMatched = this.compareNumericProps(oldBuildSizes, newBuildSizes, buildSizeKeysToCompare)

    if (!isBuildSizeMatched) {
      return false
    }

    const partPropertyKeysToCompare: Array<keyof BuildPlanCostItemDTO> = ['length', 'width', 'height', 'volume']

    for (let index = 0; index < oldItems.length; index += 1) {
      const oldItem = oldItems[index]
      const newItem = newItems[index]

      const isPartPropertiesMatched = this.compareNumericProps(oldItem, newItem, partPropertyKeysToCompare)

      if (isPartPropertiesMatched) {
        continue
      } else {
        return false
      }
    }

    return true
  }

  private compareNumericProps(obj1: object, obj2: object, keys: string[], epsilon = Epsilon): boolean {
    for (const key of keys) {
      const val1 = obj1[key]
      const val2 = obj2[key]

      if (typeof val1 !== 'number' || typeof val2 !== 'number') {
        continue
      }

      if (Math.abs(val1 - val2) >= epsilon) {
        return false
      }
    }

    return true
  }
}
