import { ItemPermissionsRole, ROLES_DETAILS } from '@/types/FileExplorer/Permission'
import { IUser } from '@/types/User/IUser'

export function isAuthorized(user: IUser, requiredPermissions: string[]) {
  return requiredPermissions.every((requiredPermission) => user.permissions.includes(requiredPermission))
}

export function getRoleId(label: string) {
  if (label === ROLES_DETAILS[ItemPermissionsRole.Owner].text) {
    return ROLES_DETAILS[ItemPermissionsRole.CoOwner].value
  }

  for (const [key, val] of Object.entries(ROLES_DETAILS)) {
    if (val.text === label) {
      return +key
    }
  }
  return null
}

export function getRoleLabel(roleId: ItemPermissionsRole) {
  return ROLES_DETAILS[roleId] ? ROLES_DETAILS[roleId].text : ''
}

export function getAvailableRolesForShare() {
  return Object.entries(ROLES_DETAILS).reduce((map, current) => {
    const [key, roleData] = current
    if (roleData.isAvailableForShare) {
      map[key] = roleData
    }
    return map
  }, {})
}

export function getFullName(user: IUser) {
  return user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email
}
