import { Component, Mixins, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import StoresNamespaces from '@/store/namespaces'

import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemType } from '@/types/FileExplorer/ItemType'
import { RouterNames } from '@/router'
import messageService from '@/services/messageService'
import { DetailsPanelViewMode } from '@/types/FileExplorer/ViewMode'
import { ItemAction } from '@/types/FileExplorer/ItemAction'
import { IJob, JobType } from '@/types/PartsLibrary/Job'
import ModalsStateMixin from './ModalsStateMixin'
import fileExplorer from '@/api/fileExplorer'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

interface IMixinInterface extends Vue, ModalsStateMixin {}

@Component
export default class ActionBarMixin extends Mixins<IMixinInterface>(Vue, ModalsStateMixin) {
  @fileExplorerStore.Getter hasRunningImportJob: (id: string) => boolean
  @fileExplorerStore.Getter hasFailedJobs: (id: string) => boolean
  @fileExplorerStore.Getter getFailedJobsByItemId: (id: string) => IJob[]

  @fileExplorerStore.Mutation setNumberOfHiddenItemsInCurrentFolder: (numberOfHiddenItems: number) => void

  async openFolder(item: FileExplorerItem) {
    const route = {
      name: RouterNames.FE_AllFiles,
      params: {
        itemId: item.id,
      },
    }
    // @ts-ignore
    this.$router.safePush(route)
  }

  // open part for preview
  async openBuildPart(item: FileExplorerItem) {
    const route = {
      name: RouterNames.PartPreview,
      params: {
        id: item.id,
      },
    }
    // @ts-ignore
    this.$router.safePush(route)
    this.setDetailsPanelMode(DetailsPanelViewMode.Part)
    await this.setLastAction({ itemId: item.id, action: ItemAction.Opened })
  }

  async openBuildPlan(itemId: string) {
    await this.checkVariantIsLockedAndEdit(itemId)
  }

  async openPrintOrder(id: string) {
    const route = {
      name: RouterNames.PreviewPrintOrder,
      params: {
        id,
      },
    }
    // @ts-ignore
    this.$router.safePush(route)
  }

  async openIbcPlan(itemId: string) {
    const route = {
      name: RouterNames.FE_EditIbcPlan,
      params: { ibcPlanId: itemId },
    }

    // @ts-ignore
    this.$router.safePush(route)
  }

  async openItem(item: FileExplorerItem) {
    const hasRunningImportJob = this.hasRunningImportJob(item.id)
    if (hasRunningImportJob) {
      const errMsg = `${this.$i18n.t('importSnackbarMsg')}`
      messageService.showErrorMessage(errMsg)
      return
    }

    switch (item.itemType) {
      case ItemType.Folder:
        return this.openFolder(item)
      case ItemType.BuildPart:
        const hasFailedImportJobs =
          this.hasFailedJobs(item.id) &&
          this.getFailedJobsByItemId(item.id).some((job) => job.jobType === JobType.IMPORT)
        if (hasFailedImportJobs) {
          return
        }
        return this.openBuildPart(item)
      case ItemType.BuildPlan:
        return this.openBuildPlan(item.id)
      case ItemType.PrintOrder:
        return this.openPrintOrder(item.id)
      case ItemType.IbcPlan:
        return this.openIbcPlan(item.id)
      default:
        return
    }
  }

  public async isNotificationItemTrashed(itemId: string): Promise<boolean> {
    const isItemTrashed = await fileExplorer.isItemTrashed(itemId)
    return !!isItemTrashed
  }

  public async goToTrash(): Promise<void> {
    const route = {
      name: RouterNames.FE_Trash,
    }
    // @ts-ignore
    this.$router.safePush(route)
  }
}
