import buildPlanItemsApi from '@/api/buildPlanItems'
import { Command } from '@/types/UndoRedo/Command'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { Commit, Dispatch } from 'vuex'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

export class ArrangeToolCommand extends Command implements ICommand {
  public readonly commandType: CommandType
  public readonly toolName = ToolNames.ARRANGE

  public readonly buildPlanItemsBeforeArrangeApplied: IBuildPlanItem[]
  public readonly buildPlanItemsAfterArrangeApplied: IBuildPlanItem[]

  constructor(
    buildPlanItemsBeforeArrangeApplied: IBuildPlanItem[],
    buildPlanItemsAfterArrangeApplied: IBuildPlanItem[],
    commandType: CommandType,
    protected dispatch: Dispatch,
    protected commit: Commit,
  ) {
    super()

    this.buildPlanItemsBeforeArrangeApplied = JSON.parse(JSON.stringify(buildPlanItemsBeforeArrangeApplied))
    this.buildPlanItemsAfterArrangeApplied = JSON.parse(JSON.stringify(buildPlanItemsAfterArrangeApplied))
    this.commandType = commandType
  }

  async undo(): Promise<void> {
    this.deselectItems()
    await this.applyTransformationMatrix(this.buildPlanItemsBeforeArrangeApplied)
  }

  async redo(): Promise<void> {
    this.deselectItems()
    await this.applyTransformationMatrix(this.buildPlanItemsAfterArrangeApplied)
  }

  private async applyTransformationMatrix(buildPlanItems: IBuildPlanItem[]): Promise<void> {
    // Apply transformation without updating state
    this.commit('visualizationModule/applyTransformationMatrixBatch', { buildPlanItems }, this.rootLevel)

    // Send request to the server for the batch build plan items update.
    // This is important for the large number of the parts, when page is reloading
    await buildPlanItemsApi.updateBuildPlanItems(buildPlanItems)
  }
}
