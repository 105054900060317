
import Vue from 'vue'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'
import StoresNamespaces from '@/store/namespaces'
import { SelectionUnit } from '@/types/BuildPlans/IBuildPlan'

import Selector from '@/components/controls/Common/Selector.vue'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component({
  components: {
    Selector,
  },
})
export default class BuildPlanLayoutToolbar extends Vue {
  @buildPlansStore.Action('setSelectionMode') setSelectionMode: Function

  @buildPlansStore.Getter('getSelectionModes') getSelectionModes: SelectionUnit[]
  @buildPlansStore.Getter('getActiveSelectionMode') getActiveSelectionMode: SelectionUnit

  get activeSelectionMode() {
    return this.getActiveSelectionMode
  }

  set activeSelectionMode(mode: SelectionUnit) {
    this.setSelectionMode({ mode })
  }
}
