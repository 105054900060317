
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { TenantAdminBindersMixin } from '@/components/layout/admin/mixins/TenantAdminBindersMixin'
import TenantAdminHeader from '@/components/layout/admin/sites/contentTable/TenantAdminHeader.vue'
import TenantAdminRow from '@/components/layout/admin/sites/contentTable/TenantAdminRow.vue'
import { TenantAdminContentTableMixin } from '@/components/layout/admin/sites/mixins/TenantAdminContentTableMixin'
import TableRowNumberUpdater from '@/components/layout/admin/sites/TableRowNumberUpdater.vue'
import StoresNamespaces from '@/store/namespaces'
import { Currency, SiteMachineConfigMaterialBinderDto } from '@/types/Sites/Site'
import { TableCell } from '@/types/Sites/TenantAdminContentTableCell'
import { TableHeader } from '@/types/Sites/TenantAdminContentTableHeader'
import { SortOrders } from '@/types/SortModes'
import { SiteAdminMixin } from './mixins/SiteAdminMixin'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component({
  components: { TenantAdminHeader, TenantAdminRow, TableRowNumberUpdater },
})
export default class TenantAdminBinder extends Mixins(
  TenantAdminContentTableMixin,
  SiteAdminMixin,
  TenantAdminBindersMixin,
) {
  @sitesStore.Getter isLoading: boolean
  @sitesStore.Getter getSiteMachineConfigMaterialBinders: SiteMachineConfigMaterialBinderDto[]

  @sitesStore.Action updateBinder: (payload: {
    siteMachineConfigMaterialBinderId
    updateBinderDto: any
  }) => Promise<void>

  siteId: string

  get formattedRows() {
    return this.setupRows()
  }

  async beforeMount() {
    this.setupHeaders()

    await this.loadBinders()
  }

  setupHeaders() {
    this.headers = [
      {
        headerName: this.$t('name').toString(),
        cols: 4,
        isSortCriteria: true,
        sortOrder: SortOrders.Ascending,
      },
      {
        headerName: '',
        cols: 4,
        isSortCriteria: false,
        sortOrder: null,
      },
      {
        headerName: '',
        cols: 4,
        isSortCriteria: false,
        sortOrder: null,
      },
    ]
  }

  @Watch('getSiteMachineConfigMaterialBinders', { immediate: true })
  onBindersUpdate() {
    this.setupRows()
  }

  setupRows() {
    this.rows = this.generateRows(this.getSiteMachineConfigMaterialBinders)
    return this.rows
  }

  async updateBinderPrice(value: number, id: number) {
    await this.updateBinder({ siteMachineConfigMaterialBinderId: id, updateBinderDto: { binderPrice: value } })
    this.setupRows()
  }

  async updateCleaningAgentPrice(value: number, id: number) {
    await this.updateBinder({ siteMachineConfigMaterialBinderId: id, updateBinderDto: { cleaningAgentPrice: value } })
    this.setupRows()
  }

  generateColumns(element): TableCell[] {
    return [
      {
        value: element.name,
        text: element.name,
        cols: 4,
        cellName: this.$t('name').toString(),
      },
      {
        value: element.binderPrice,
        cellItemId: element.siteMachineConfigMaterialBinderId,
        upperDescription: this.$t('tenantAdminContentTableHeaders.binderList.binderPrice').toString(),
        text: `${element.binderPrice.value} ${Currency.USD}`,
        bottomDescription: 'per liter',
        cols: 4,
        component: TableRowNumberUpdater,
        cellName: this.$t('tenantAdminContentTableHeaders.binderList.binderPrice').toString(),
        componentOptions: {
          name: this.$t('tenantAdminContentTableHeaders.binderList.binderPrice').toString(),
          rules: this.validationRules.rules,
          customMessages: this.validationRules.customMessages,
          suffix: Currency.USD,
          componentHeight: '14px',
          hideDetails: true,
        },
        callbacks: {
          onUpdateSiteConstants: this.updateBinderPrice,
        },
      },
      {
        value: element.cleaningAgentPrice,
        cellItemId: element.siteMachineConfigMaterialBinderId,
        upperDescription: this.$t('tenantAdminContentTableHeaders.binderList.cleaningAgentPrice').toString(),
        text: `${element.binderPrice.value} ${Currency.USD}`,
        bottomDescription: 'per liter',
        cols: 4,
        component: TableRowNumberUpdater,
        cellName: this.$t('tenantAdminContentTableHeaders.binderList.cleaningAgentPrice').toString(),
        componentOptions: {
          name: this.$t('tenantAdminContentTableHeaders.binderList.cleaningAgentPrice').toString(),
          rules: this.validationRules.rules,
          customMessages: this.validationRules.customMessages,
          suffix: Currency.USD,
          componentHeight: '14px',
          hideDetails: true,
        },
        callbacks: {
          onUpdateSiteConstants: this.updateCleaningAgentPrice,
        },
      },
    ]
  }

  sortChanged(header: TableHeader) {
    this.onSortChanged(header)
    this.$forceUpdate()
  }
}
