import { RouterNames } from '@/router'
import StoresNamespaces from '@/store/namespaces'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component
export class SelectablePartInsightMixin extends Vue {
  @visualizationStore.Getter getVisualizationLoading: boolean
  @visualizationStore.Getter isInitialized: boolean
  @visualizationStore.Getter viewMode: { name: ViewModeTypes; isSelectable: boolean }

  @buildPlansStore.Getter getIsLoading: boolean

  // As we show insights for parts that can not be saved to build plan,
  // disable part selection and gizmo interaction
  viewModesWithDisabledSelection = [
    ViewModeTypes.Part,
    ViewModeTypes.Replace,
    ViewModeTypes.Orientation,
    ViewModeTypes.Support,
    ViewModeTypes.Duplicate,
    ViewModeTypes.Nesting,
    ViewModeTypes.SimulationCompensation,
    ViewModeTypes.Marking,
    ViewModeTypes.CrossSection,
    ViewModeTypes.Slicing,
    ViewModeTypes.ClearanceTool,
  ]

  get isPartSelectionDisabled(): boolean {
    return (
      this.viewModesWithDisabledSelection.includes(this.viewMode.name) ||
      this.$route.name === RouterNames.PreviewPrintOrder
    )
  }
}
