const cadHelperProtocol = 'ge-additive-cadhelper'

export interface CadHelperLinkParameters {
  command: string
  signedUrl: string
  downloadUrl: string
  token: string
  cadHelperVersion: string
  sessionId: string
  tenant: string
  name?: string | void
  parentId?: string
}

export class CadHelperLink {
  public command: string
  public signedUrl: string
  public downloadUrl: string
  public token: string
  public cadHelperVersion: string
  public sessionId: string
  public name: string
  public parentId: string
  public tenant: string

  constructor(parameters: CadHelperLinkParameters) {
    this.command = parameters.command
    this.signedUrl = parameters.signedUrl
    this.downloadUrl = parameters.downloadUrl
    this.token = parameters.token
    this.cadHelperVersion = parameters.cadHelperVersion
    this.sessionId = parameters.sessionId
    this.tenant = parameters.tenant

    this.name = parameters.name ? ` name=${parameters.name}` : ''
    this.parentId = parameters.parentId ? ` parentId=${parameters.parentId}` : ''
  }

  public toString() {
    return (
      `${cadHelperProtocol}` +
      `:process=${this.command}` +
      ` signedUrl=${this.signedUrl}` +
      ` downloadUrl=${this.downloadUrl}` +
      ` token=${this.token}` +
      ` version=${this.cadHelperVersion}` +
      ` sessionId=${this.sessionId}` +
      `${this.name}` +
      `${this.parentId}` +
      ` tenant=${this.tenant}`
    )
  }
}
