/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { AbstractMesh, InstancedMesh, Mesh, SubMesh, TransformNode } from '@babylonjs/core/Meshes'
import { Color3, Matrix, Vector3 } from '@babylonjs/core/Maths'
import { Material } from '@babylonjs/core/Materials/material'
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { Scene } from '@babylonjs/core/scene'
import { IVisualizationEvent, VisualizationEvent } from '@/visualization/infrastructure/IVisualizationEvent'
import { Gizmo } from '@/visualization/rendering/Gizmo'
import { FaceColoringShader, HoverableFace } from '@/visualization/rendering/MeshShader'
import { IActiveToggle } from '@/visualization/infrastructure/IActiveToggle'
import { RenderScene } from '@/visualization/render-scene'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import {
  BVH_BOX,
  CROSS_SECTION_MESH_MATERIAL,
  CUTOUT_MATERIAL,
  DEFAULT_COLOR,
  DEFAULT_MATERIAL_NAME,
  DEFECTIVE_FACES_MATERIAL_NAME,
  GROUP_PARENT_MESH_NAME,
  HIGHLIGHTING_COLOR,
  SELECTING_COLOR,
  HIGHLIGHT_ERROR_MATERIAL_NAME,
  HIGHLIGHT_MATERIAL_NAME,
  INSIDE_MESH_NAME,
  LINE_SUPPORT,
  MESH_RENDERING_GROUP_ID,
  OVERHANG_EDGES_NAME,
  OVERHANG_VERTICES_NAME,
  PART_BODY_ID_DELIMITER,
  PRIMARY_CYAN,
  PRIMARY_SELECTION,
  SELECTION_ERROR_MATERIAL_NAME,
  SELECTION_MATERIAL_NAME,
  SHADER_HIGHLIGHT_MATERIAL_NAME,
  SHADER_SELECTION_MATERIAL_NAME,
  SUPPORT,
  SUPPORT_COLOR,
  SUPPORT_INSIDE_MESH_NAME,
} from '@/constants'
import { ISelectable, SelectionUnit } from '@/types/BuildPlans/IBuildPlan'
import { Face } from '@/visualization/components/DracoDecoder'
import { SelectionBox } from '@/visualization/components/SelectionBox'
import { BoundingBox2D, GeometryTypes } from '@/visualization/models/DataModel'
import {
  IComponentMetadata,
  IPartMetadata,
  ISupportMetadata,
  SceneItemType,
} from '@/visualization/types/SceneItemMetadata'
import { PartDefect } from '@/types/Parts/IPartInsight'
import { isNumber } from '@/utils/number'
import { MultiMaterial } from '@babylonjs/core/Materials'
import { BoundingInfo } from '@babylonjs/core/Culling'
import {
  createLabeledBodyWithTransformation,
  LabeledBodyWIthTransformation,
} from '@/types/Label/LabeledBodyWIthTransformation'
import { getBuildPlanItemTransformationWithoutScaleFromTransformation } from '@/utils/label/labelUtils'
import store from '@/store'
import { ClearanceToolViewMode } from '@/visualization/infrastructure/ViewMode'
import { ClearanceTypes } from '@/visualization/types/ClearanceTypes'

export interface ISelectableNode {
  part?: TransformNode
  body?: AbstractMesh
  face?: Face
}

interface SelectionItem {
  groupParent: any
  selected: any
  regularMaterial: StandardMaterial
  highlightMaterial: StandardMaterial
  selectionMaterial: StandardMaterial
  isGizmoEnabled: boolean

  highlight(items: ISelectableNode[], showHighlight: boolean, silent?: boolean, highlightColor?: Color3)

  select(items: ISelectableNode[], attach: boolean, silent?: boolean)

  deselect(items: ISelectableNode[], silent?: boolean)

  getSelected(singleMesh: boolean)

  isSelected(item: ISelectableNode)

  equals(item1: ISelectableNode, item2: ISelectableNode)

  dispose()

  getSelectionBoundingBox(): BoundingInfo
}

export class SelectionPart implements SelectionItem {
  groupParent: AbstractMesh
  selected: TransformNode[] = []
  regularMaterial: StandardMaterial
  highlightMaterial: StandardMaterial
  selectionMaterial: StandardMaterial
  highlightErrorMaterial: StandardMaterial
  selectionErrorMaterial: StandardMaterial
  sendBoundingAnchorPoint: boolean = false
  isGizmoEnabled: boolean = true

  private elementsSelected: VisualizationEvent<{ selectedItems: ISelectable[]; attach: boolean }>
  private selectionBoundingGeometryReady: VisualizationEvent<BoundingBox2D>
  private generateOverhangMeshByClickEvent: IVisualizationEvent<{
    buildPlanItemId: string
    transformation: number[]
  }>
  private scene: Scene
  private renderScene: RenderScene
  private meshManager: MeshManager

  constructor(
    renderScene: RenderScene,
    elementsSelected: VisualizationEvent<{ selectedItems: ISelectable[]; attach: boolean }>,
    selectionBoundingGeometryReady: VisualizationEvent<BoundingBox2D>,
    generateOverhangMeshByClickEvent: IVisualizationEvent<{
      buildPlanItemId: string
      transformation: number[]
    }>,
  ) {
    this.renderScene = renderScene
    this.scene = renderScene.getScene()
    this.regularMaterial = this.scene.getMaterialByName(DEFAULT_MATERIAL_NAME) as StandardMaterial
    this.highlightMaterial = this.scene.getMaterialByName(HIGHLIGHT_MATERIAL_NAME) as StandardMaterial
    this.selectionMaterial = this.scene.getMaterialByName(SELECTION_MATERIAL_NAME) as StandardMaterial
    this.highlightErrorMaterial = this.highlightMaterial.clone(HIGHLIGHT_ERROR_MATERIAL_NAME)
    this.selectionErrorMaterial = this.scene.getMaterialByName(SELECTION_ERROR_MATERIAL_NAME) as StandardMaterial
    this.meshManager = renderScene.getMeshManager()
    this.elementsSelected = elementsSelected
    this.selectionBoundingGeometryReady = selectionBoundingGeometryReady
    this.generateOverhangMeshByClickEvent = generateOverhangMeshByClickEvent
  }

  highlight(items: ISelectableNode[], showHighlight: boolean, silent?: boolean, highlightColor?: Color3) {
    const isClearanceToolEnabled = store.getters['visualizationModule/isClearanceToolEnabled']
    const isClearanceToEnabled = store.getters['visualizationModule/isClearanceToEnabled']
    const isRubberBandShown = store.getters['visualizationModule/isRubberBandShown']

    // Edge case for clearance tool
    if (isClearanceToolEnabled) {
      const item = items[0]
      const selected = this.selected[0]

      if (isRubberBandShown && item.part && item.body) {
        // Do not highlight part or bodies that belongs to selected part
        if (selected === item.part || selected === item.body.parent) {
          return
        }
        // If rubber band is active and user selects 'Part' from 'To' section
        // Highlight Part
        if (isClearanceToEnabled(ClearanceTypes.Parts)) {
          this.highlightPart(items, showHighlight, highlightColor)
        }
        // Otherwise, highlight body
        else if (isClearanceToEnabled(ClearanceTypes.Bodies)) {
          // Fix to dehighlight another bodies of same part
          // When user move pointer from one body to another body of this part
          this.highlightPart(items, false)
          this.highlightBody(items, showHighlight, highlightColor)
        }
      } else if (!item.body || (item.body && item.part)) {
        this.highlightPart(items, showHighlight, highlightColor)
      } else if (!item.part) {
        this.highlightBody(items, showHighlight, highlightColor)
      }
    } else {
      this.highlightPart(items, showHighlight, highlightColor)
    }
  }

  select(items: ISelectableNode[], attach: boolean) {
    this.updateParent()
    if (!attach) {
      this.deselect()
    }

    const selectedItems = []
    for (const item of items) {
      const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
      if (
        this.selected.includes(partMesh) ||
        (item.body &&
          item.body.metadata.itemType !== SceneItemType.Component &&
          item.body.metadata.itemType !== SceneItemType.Support &&
          item.body.metadata.itemType !== SceneItemType.OverhangEdge &&
          item.body.metadata.itemType !== SceneItemType.OverhangSurface &&
          item.body.metadata.itemType !== SceneItemType.OverhangVertex)
      ) {
        this.deselect([item])
      } else {
        this.selected.push(partMesh)
        const hullBBox = partMesh.metadata.hullBInfo.boundingBox
        const translation = new Vector3(hullBBox.centerWorld.x, hullBBox.centerWorld.y, hullBBox.minimumWorld.z)
        selectedItems.push({
          translation,
          id: partMesh.metadata.buildPlanItemId,
          type: SelectionUnit.Part,
        })
        if (this.sendBoundingAnchorPoint) {
          this.send2DBoundingPoints()
        }
        const transformation = []
        partMesh
          .getWorldMatrix()
          .transpose()
          .toArray()
          .forEach((i) => transformation.push(i))
        this.generateOverhangMeshByClickEvent.trigger({
          transformation,
          buildPlanItemId: partMesh.metadata.buildPlanItemId,
        })
        this.selectChildrenIfExist(partMesh, true)
      }
    }

    if (selectedItems.length) {
      this.elementsSelected.trigger({ selectedItems, attach })
    }
  }

  deselect(items: ISelectableNode[] = null, silent?: boolean) {
    if (items === null) {
      for (const selMesh of this.selected) {
        this.selectChildrenIfExist(selMesh, false)
        // if (this.renderScene.isDebugModeEnabled) {
        //   this.renderScene.getObbTree().showObbTree(selMesh.metadata.bvh, false, selMesh)
        // }
      }

      this.selected = []

      if (!silent) {
        this.elementsSelected.trigger({ selectedItems: null, attach: false })
      }
    } else {
      const selectedItems = []
      for (const item of items) {
        const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
        const index = this.selected.indexOf(partMesh)

        if (index !== -1) {
          this.selectChildrenIfExist(this.selected[index], false)
          // if (this.renderScene.isDebugModeEnabled) {
          //   this.renderScene.getObbTree().showObbTree(this.selected[index].metadata.bvh, false, this.selected[index])
          // }
          this.selected.splice(index, 1)
          selectedItems.push({ id: partMesh.metadata.buildPlanItemId, type: SelectionUnit.Part })
          if (this.sendBoundingAnchorPoint) {
            this.send2DBoundingPoints()
          }
        }
      }

      if (!silent) {
        this.elementsSelected.trigger({ selectedItems, attach: true })
      }
    }

    this.updateParent()
  }

  getSelected(singleMesh: boolean) {
    if (!singleMesh) {
      return this.selected
    }

    if (this.selected.length === 0) {
      return null
    }

    if (this.groupParent) {
      return this.groupParent
    }

    this.groupParent = new AbstractMesh(GROUP_PARENT_MESH_NAME, this.scene)
    const meshes = []
    this.selected.map((selected) => {
      selected.getChildMeshes().map((child) => {
        if (this.meshManager.isComponentMesh(child)) {
          meshes.push(child)
        }
      })
    })
    // const totalMeshes = this.meshManager.getMeshesWithBounds(this.selected)
    // const meshes = totalMeshes.filter(
    //   mesh => mesh.name !== SUPPORT && mesh.name !== OVERHANG_NAME && !mesh.name.includes(LINE_SUPPORT),
    // )
    const boundingInfo = this.meshManager.getTotalBoundingInfo(meshes)
    this.groupParent.position = boundingInfo.boundingBox.center

    for (const mesh of this.selected) {
      ;(mesh.parent as TransformNode).setParent(this.groupParent)
      mesh.computeWorldMatrix(true)
    }

    return this.groupParent
  }

  isSelected(item: ISelectableNode) {
    const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
    return this.selected.includes(partMesh) || this.getChildrenOfSelected().includes(partMesh)
  }

  equals(item1: ISelectableNode, item2: ISelectableNode) {
    return item1.part === item2.part
  }

  dispose() {
    this.updateParent()
  }

  /** Returns Bounding info of selected bodies and supports in world coordinates. */
  getSelectionBoundingBox(): BoundingInfo {
    const selectedMeshes = []
    const bInfos: BoundingInfo[] = []
    for (const selectedPart of this.selected) {
      selectedPart.getChildMeshes().forEach((mesh) => {
        if (this.meshManager.isComponentMesh(mesh)) {
          // || this.meshManager.isLabelMesh(mesh)
          selectedMeshes.push(mesh)
        }
      })

      const supportParent = selectedPart
        .getChildTransformNodes()
        .find((c) => this.meshManager.isSupportMesh(c) && c.metadata.buildPlanItemId)

      if (
        supportParent &&
        supportParent.metadata &&
        supportParent.metadata.hullBInfo &&
        supportParent.getChildTransformNodes().length
      ) {
        bInfos.push(supportParent.metadata.hullBInfo)
      } else {
        selectedPart.getChildMeshes().forEach((mesh) => {
          if (this.meshManager.isSupportMesh(mesh)) {
            selectedMeshes.push(mesh)
          }
        })
      }
    }

    if (selectedMeshes.length) {
      bInfos.push(this.meshManager.getTotalBoundingInfo(selectedMeshes, true, true))
    }

    return this.meshManager.mergeBoundingInfos(bInfos)
  }

  public send2DBoundingPoints() {
    if (!this.selected.length) {
      return
    }

    const children = []
    this.selected.map((selected) => {
      selected.getChildMeshes().map((child) => {
        if (this.meshManager.isComponentMesh(child)) {
          children.push(child)
        }
      })
    })
    const points: BoundingBox2D = this.meshManager.getBoundingBox2D(children as AbstractMesh[])
    this.selectionBoundingGeometryReady.trigger(points)
  }

  private updateParent() {
    if (!this.groupParent) return

    for (const child of this.groupParent.getChildTransformNodes(true)) {
      child.setParent(null)
    }

    this.scene.removeMesh(this.groupParent)
    this.groupParent.dispose()
    this.groupParent = null
  }

  private highlightPart(items: ISelectableNode[], showHighlight: boolean, highlightColor?: Color3) {
    for (const item of items) {
      const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
      if (partMesh.isDisposed()) {
        continue
      }

      const isSelected = this.isSelected(item)
      if (showHighlight) {
        if (
          !item.body ||
          !item.body.isDisposed() ||
          (item.body &&
            (item.body.metadata.itemType === SceneItemType.Component ||
              item.body.metadata.itemType === SceneItemType.Support ||
              item.body.metadata.itemType === SceneItemType.OverhangEdge ||
              item.body.metadata.itemType === SceneItemType.OverhangSurface ||
              item.body.metadata.itemType === SceneItemType.OverhangVertex))
        ) {
          partMesh.metadata.color = highlightColor ? highlightColor : PRIMARY_CYAN
        } else {
          this.highlight([item], false)
        }
      } else {
        partMesh.metadata.color = isSelected ? PRIMARY_SELECTION : DEFAULT_COLOR
      }

      const childMeshes = partMesh
        .getChildMeshes()
        .filter((child) => this.meshManager.isComponentMesh(child) || this.meshManager.isSupportMesh(child))

      for (const child of childMeshes) {
        this.meshManager.setInstancedBufferColor(child as InstancedMesh, showHighlight, isSelected, highlightColor)

        if (child.metadata && child.metadata.isHidden) {
          const allowHideSelected = false
          this.meshManager.highlightHiddenMesh(child as InstancedMesh, showHighlight, isSelected, allowHideSelected)
        }
      }
    }
  }

  private highlightBody(items: ISelectableNode[], showHighlight: boolean, highlightColor?: Color3) {
    for (const item of items) {
      const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
      let instances = []
      if (item.body && this.meshManager.isComponentMesh(item.body)) {
        instances = item.body.isDisposed() ? [] : [item.body as InstancedMesh]
      } else {
        instances = partMesh.getChildMeshes().filter((child) => this.meshManager.isComponentMesh(child))
      }

      const isSelected = this.isSelected(item)
      for (const instance of instances) {
        this.meshManager.setInstancedBufferColor(instance, showHighlight, isSelected, highlightColor)

        if (instance.metadata.isHidden) {
          const allowHideSelected = false
          this.meshManager.highlightHiddenMesh(instance, showHighlight, isSelected, allowHideSelected)
        }

        for (const child of instance.getChildMeshes(true)) {
          if (
            child.name !== BVH_BOX &&
            child.name !== INSIDE_MESH_NAME &&
            !child.name.startsWith(SUPPORT_INSIDE_MESH_NAME) &&
            child.material &&
            child.instancedBuffers &&
            child.instancedBuffers.color
          ) {
            child.instancedBuffers.color = instance.instancedBuffers.color
          }
        }
      }
    }
  }

  private selectChildrenIfExist(node: TransformNode, showSelection: boolean) {
    const childMeshes = node
      .getChildMeshes()
      .filter((child) => this.meshManager.isComponentMesh(child) || this.meshManager.isSupportMesh(child))
    if (showSelection) {
      node.metadata.color = PRIMARY_SELECTION
    } else {
      node.metadata.color = DEFAULT_COLOR
    }

    for (const child of childMeshes) {
      this.meshManager.setInstancedBufferColor(child as InstancedMesh, false, showSelection)

      if (child.metadata.isHidden) {
        const allowHideSelected = true
        this.meshManager.highlightHiddenMesh(child as InstancedMesh, showSelection, showSelection, allowHideSelected)
      }
    }
  }

  private getChildrenOfSelected() {
    const children = []
    this.selected.forEach((selMesh) => {
      selMesh.getChildMeshes().forEach((child) => children.push(child))
    })
    return children
  }
}

export class SelectionBody implements SelectionItem {
  groupParent: AbstractMesh
  selected: AbstractMesh[] = []
  regularMaterial: StandardMaterial
  highlightMaterial: StandardMaterial
  selectionMaterial: StandardMaterial
  selectionHighlightMaterial: StandardMaterial
  isGizmoEnabled: boolean = false

  private elementsSelected: VisualizationEvent<{ selectedItems: ISelectable[]; attach: boolean }>
  private addSelectedLabeledBodies: VisualizationEvent<LabeledBodyWIthTransformation[]>
  private removeSelectedLabeledBodies: VisualizationEvent<ISelectable[]>
  private scene: Scene
  private renderScene: RenderScene
  private meshManager: MeshManager

  constructor(renderScene: RenderScene, elementsSelected, addSelectedLabeledBodies, removeSelectedLabeledBodies) {
    this.renderScene = renderScene
    this.scene = renderScene.getScene()
    this.regularMaterial = this.scene.getMaterialByName(DEFAULT_MATERIAL_NAME) as StandardMaterial
    this.highlightMaterial = this.scene.getMaterialByName(HIGHLIGHT_MATERIAL_NAME) as StandardMaterial
    this.selectionMaterial = this.scene.getMaterialByName(SELECTION_MATERIAL_NAME) as StandardMaterial
    this.selectionHighlightMaterial = this.highlightMaterial
    this.meshManager = renderScene.getMeshManager()
    this.elementsSelected = elementsSelected
    this.addSelectedLabeledBodies = addSelectedLabeledBodies
    this.removeSelectedLabeledBodies = removeSelectedLabeledBodies
  }

  highlight(items: ISelectableNode[], showHighlight: boolean, silent?: boolean, highlightColor?: Color3) {
    const isClearanceToolEnabled = store.getters['visualizationModule/isClearanceToolEnabled']
    const isClearanceToEnabled = store.getters['visualizationModule/isClearanceToEnabled']
    const isRubberBandShown = store.getters['visualizationModule/isRubberBandShown']

    // Edge case for clearance tool
    if (isClearanceToolEnabled) {
      const item = items[0]
      const selected = this.selected[0]

      if (isRubberBandShown && item.part && item.body) {
        if (!item.body) {
          this.highlightPart(items, showHighlight, highlightColor)
          return
        }

        // Do not highlight part or bodies that belongs to selected body
        if (selected.parent === item.part || selected.parent === item.body.parent) {
          return
        }

        // If rubber band is active and user selects 'Part' from 'To' section
        // Highlight Part
        if (isClearanceToEnabled(ClearanceTypes.Parts)) {
          this.highlightPart(items, showHighlight, highlightColor)
        }
        // Otherwise, highlight body
        else if (isClearanceToEnabled(ClearanceTypes.Bodies)) {
          this.highlightBody(items, showHighlight, highlightColor)
        }
      } else if (!item.part || (item.body && item.part)) {
        this.highlightBody(items, showHighlight, highlightColor)
      } else if (!item.body) {
        this.highlightPart(items, showHighlight, highlightColor)
      }
    } else {
      this.highlightBody(items, showHighlight, highlightColor)
    }
  }

  select(items: ISelectableNode[], attach: boolean, silent?: boolean) {
    this.updateParent()
    if (!attach) {
      this.deselect()
    }

    const selectedItems = []
    const selectedTrackableBodies: LabeledBodyWIthTransformation[] = []
    for (const item of items) {
      if (this.selected.includes(item.body)) {
        this.deselect([item])
      } else {
        this.selected.push(item.body)
        const root = this.meshManager.getBuildPlanItemMeshByChild(item.body)
        const geometry = (item.body as InstancedMesh).sourceMesh.geometry as any
        selectedItems.push({
          id:
            root.metadata.buildPlanItemId +
            PART_BODY_ID_DELIMITER +
            item.body.metadata.componentId +
            PART_BODY_ID_DELIMITER +
            item.body.metadata.geometryId,
          name: geometry.name,
          type: SelectionUnit.Body,
        })
        this.selectPickedMesh(item.body, true)
        if (!(this.renderScene.getViewMode() instanceof ClearanceToolViewMode)) {
          selectedTrackableBodies.push(
            createLabeledBodyWithTransformation(
              root.metadata.buildPlanItemId,
              item.body.metadata.componentId,
              item.body.metadata.geometryId,
              root.metadata.partId,
              getBuildPlanItemTransformationWithoutScaleFromTransformation(this.getRelatedTransformation(root)),
            ),
          )
        }
      }
    }

    if (!silent && selectedItems.length) {
      this.elementsSelected.trigger({ selectedItems, attach })
      if (!(this.renderScene.getViewMode() instanceof ClearanceToolViewMode)) {
        // // This event is used in the label tool
        this.addSelectedLabeledBodies.trigger(selectedTrackableBodies)
      }
    }
  }

  deselect(items: ISelectableNode[] = null, silent?: boolean) {
    if (items === null) {
      const selectedItems = []
      for (const selMesh of this.selected) {
        this.selectPickedMesh(selMesh, false)
        const root = this.meshManager.getBuildPlanItemMeshByChild(selMesh)
        const geometry = (selMesh as InstancedMesh).sourceMesh.geometry as any
        selectedItems.push({
          id:
            root.metadata.buildPlanItemId +
            PART_BODY_ID_DELIMITER +
            selMesh.metadata.componentId +
            PART_BODY_ID_DELIMITER +
            selMesh.metadata.geometryId,
          name: geometry.name,
          type: SelectionUnit.Body,
        })
      }

      this.selected = []

      if (!silent) {
        this.elementsSelected.trigger({ selectedItems: null, attach: false })

        if (!(this.renderScene.getViewMode() instanceof ClearanceToolViewMode)) {
          // This event is used in the label tool
          store.dispatch('label/removeSelectedBodies', selectedItems)
          this.renderScene.animate()
        }
      }
    } else {
      const selectedItems = []
      for (const item of items) {
        const index = this.selected.indexOf(item.body)

        if (index !== -1) {
          this.selectPickedMesh(this.selected[index], false)
          this.selected.splice(index, 1)
          const root = this.meshManager.getBuildPlanItemMeshByChild(item.body)
          const geometry = (item.body as InstancedMesh).sourceMesh.geometry as any
          selectedItems.push({
            id:
              root.metadata.buildPlanItemId +
              PART_BODY_ID_DELIMITER +
              item.body.metadata.componentId +
              PART_BODY_ID_DELIMITER +
              item.body.metadata.geometryId,
            name: geometry.name,
            type: SelectionUnit.Body,
          })
        }
      }

      if (!silent) {
        this.elementsSelected.trigger({ selectedItems, attach: true })
        if (!(this.renderScene.getViewMode() instanceof ClearanceToolViewMode)) {
          // This event is used in the label tool
          store.dispatch('label/removeSelectedBodies', selectedItems)
        }
      }
    }

    this.updateParent()
    if (this.renderScene.getViewMode() instanceof ClearanceToolViewMode) {
      this.renderScene.animate()
    }
  }

  getSelected(singleMesh: boolean) {
    if (!singleMesh) {
      return this.selected
    }

    if (this.selected.length === 0) {
      return null
    }

    if (this.groupParent) {
      return this.groupParent
    }

    this.groupParent = new AbstractMesh(GROUP_PARENT_MESH_NAME, this.scene)
    const totalMeshes = this.meshManager.getMeshesWithBounds(this.selected)
    const boundingInfo = this.meshManager.getTotalBoundingInfo(totalMeshes)
    this.groupParent.position = boundingInfo.boundingBox.center

    for (const mesh of this.selected) {
      ;(mesh as any).nativaeParent = mesh.parent
      mesh.parent = this.groupParent
    }

    return this.groupParent
  }

  isSelected(item: ISelectableNode) {
    return this.selected.includes(item.body)
  }

  equals(item1: ISelectableNode, item2: ISelectableNode) {
    return item1.part === item2.part && item1.body === item2.body
  }

  dispose() {
    this.updateParent()
  }

  getSelectionBoundingBox(): BoundingInfo {
    // Implement needed logic
    return new BoundingInfo(Vector3.Zero(), Vector3.Zero())
  }

  private updateParent() {
    if (!this.groupParent) return

    for (const child of this.groupParent.getChildMeshes(true)) {
      const nativeParent = (child as any).nativeParent
      child.parent = nativeParent
    }

    this.scene.removeMesh(this.groupParent)
    this.groupParent.dispose()
    this.groupParent = null
  }

  private selectPickedMesh(mesh: any, showSelection: boolean, color: Color3 = Color3.Teal()) {
    this.meshManager.setInstancedBufferColor(mesh as InstancedMesh, false, showSelection)

    if (mesh.metadata.isHidden) {
      const allowHideSelected = false
      this.meshManager.highlightHiddenMesh(mesh as InstancedMesh, false, true, allowHideSelected)
    }
  }

  private highlightBody(items: ISelectableNode[], showHighlight: boolean, highlightColor?: Color3) {
    for (const item of items) {
      const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
      let instances = []
      if (item.body && this.meshManager.isComponentMesh(item.body)) {
        instances = item.body.isDisposed() ? [] : [item.body as InstancedMesh]
      } else if (item.body && this.meshManager.isLabelSensitiveZone(item.body)) {
        const label = this.scene.getMeshById(item.body.metadata.labelId)
        let body: AbstractMesh
        if (label) {
          body = this.meshManager.getComponentMesh(
            label.metadata.bodyComponentId,
            label.metadata.bodyGeometryId,
            partMesh.metadata.buildPlanItemId,
          )
        }

        instances = body ? [body] : []
      } else {
        instances = partMesh.getChildMeshes(false, this.meshManager.isComponentMesh)
      }

      for (const instance of instances) {
        const isSelected = this.selected.includes(instance)
        this.meshManager.setInstancedBufferColor(instance, showHighlight, isSelected, highlightColor)

        if (instance.metadata.isHidden) {
          const allowHideSelected = false
          this.meshManager.highlightHiddenMesh(instance, showHighlight, isSelected, allowHideSelected)
        }

        for (const child of instance.getChildMeshes(true)) {
          if (
            child.name !== BVH_BOX &&
            child.name !== INSIDE_MESH_NAME &&
            !child.name.startsWith(SUPPORT_INSIDE_MESH_NAME) &&
            child.material &&
            child.instancedBuffers &&
            child.instancedBuffers.color
          ) {
            child.instancedBuffers.color = instance.instancedBuffers.color
          }
        }
      }
    }
  }

  private highlightPart(items: ISelectableNode[], showHighlight: boolean, highlightColor?: Color3) {
    for (const item of items) {
      const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
      const isSelected = this.isSelected(item)
      if (showHighlight) {
        if (!item.body || (item.body && item.body.metadata.itemType === SceneItemType.Component)) {
          partMesh.metadata.color = highlightColor ? highlightColor : PRIMARY_CYAN
        } else {
          this.highlight([item], false)
        }
      } else {
        partMesh.metadata.color = isSelected ? PRIMARY_SELECTION : DEFAULT_COLOR
      }

      const childMeshes = partMesh.getChildMeshes(false, this.meshManager.isComponentMesh)

      for (const child of childMeshes) {
        if (!this.meshManager.isComponentMesh(child)) {
          continue
        }

        this.meshManager.setInstancedBufferColor(child as InstancedMesh, showHighlight, isSelected, highlightColor)
      }
    }
  }

  private getRelatedTransformation(mesh: TransformNode): number[] {
    const transformation = []

    if (mesh && mesh.metadata && mesh.metadata.initialTransformation) {
      const { initialTransformation } = mesh.metadata
      const partRelativeTransformation = this.meshManager.getRelativeTransformation(
        mesh.getWorldMatrix(),
        initialTransformation,
      )

      this.meshManager
        .convertTranslationToMillimeters(partRelativeTransformation, mesh.metadata.unitFactor)
        .transpose()
        .asArray()
        .forEach((item) => transformation.push(item))

      return transformation
    }
  }
}

export class SelectionPartAndSupport implements SelectionItem {
  groupParent: AbstractMesh
  selected: Map<TransformNode, string[]> = new Map<TransformNode, string[]>()
  regularMaterial: StandardMaterial
  highlightMaterial: StandardMaterial
  selectionMaterial: StandardMaterial
  isGizmoEnabled: boolean = true

  private initialState: Map<string, Matrix>
  private elementsSelected: VisualizationEvent<{ selectedItems: ISelectable[]; attach: boolean }>
  private generateOverhangMeshByClickEvent: IVisualizationEvent<{
    buildPlanItemId: string
    transformation: number[]
  }>
  private selectSupportEvent: IVisualizationEvent<{
    buildPlanItemId: string
    overhangZoneName: string
    attach: boolean
  }>
  private hoverSupportEvent: IVisualizationEvent<{
    buildPlanItemId: string
    overhangZoneName: string
  }>
  private renderScene: RenderScene
  private scene: Scene
  private meshManager: MeshManager
  private hoveredPart: TransformNode

  constructor(
    renderScene: RenderScene,
    elementsSelected: VisualizationEvent<{ selectedItems: ISelectable[]; attach: boolean }>,
    generateOverhangMeshByClickEvent: IVisualizationEvent<{
      buildPlanItemId: string
      transformation: number[]
    }>,
    selectSupportEvent: IVisualizationEvent<{
      buildPlanItemId: string
      overhangZoneName: string
      attach: boolean
    }>,
    hoverSupportEvent: IVisualizationEvent<{
      buildPlanItemId: string
      overhangZoneName: string
    }>,
  ) {
    this.elementsSelected = elementsSelected
    this.generateOverhangMeshByClickEvent = generateOverhangMeshByClickEvent
    this.selectSupportEvent = selectSupportEvent
    this.hoverSupportEvent = hoverSupportEvent
    this.renderScene = renderScene
    this.scene = renderScene.getScene()
    this.meshManager = renderScene.getMeshManager()
    this.regularMaterial = this.scene.getMaterialByName(DEFAULT_MATERIAL_NAME) as StandardMaterial
    this.highlightMaterial = this.scene.getMaterialByName(HIGHLIGHT_MATERIAL_NAME) as StandardMaterial
    this.selectionMaterial = this.scene.getMaterialByName(SELECTION_MATERIAL_NAME) as StandardMaterial
  }

  highlight(items: ISelectableNode[], showHighlight: boolean, silent?: boolean) {
    for (const item of items) {
      const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
      if (!item.body && !item.face) {
        this.updatePartMaterials(partMesh, showHighlight)
        this.updateSupportMaterials(partMesh, null)
      } else if (this.selected.has(partMesh)) {
        const overhangZoneName = showHighlight ? this.getOverhangZoneName(item.body, item.face) : null
        this.updateSupportMaterials(partMesh, overhangZoneName)
        if (!silent) {
          this.hoverSupportEvent.trigger({
            overhangZoneName,
            buildPlanItemId: partMesh.metadata.buildPlanItemId,
          })
        }
      }

      if (this.hoveredPart && this.hoveredPart !== partMesh) {
        this.updatePartMaterials(this.hoveredPart)
        this.updateSupportMaterials(this.hoveredPart)
        this.hoveredPart = null
      }

      if (showHighlight) {
        this.hoveredPart = partMesh
      }
    }
  }

  select(items: ISelectableNode[], attach: boolean, silent?: boolean) {
    for (const item of items) {
      const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
      const overhangZoneName = item.body && this.getOverhangZoneName(item.body, item.face)

      if (overhangZoneName && this.selected.has(partMesh)) {
        if (attach) {
          const overHangZoneIndex = this.selected.get(partMesh).indexOf(overhangZoneName)
          if (overHangZoneIndex >= 0) {
            this.selected.get(partMesh).splice(overHangZoneIndex, 1)
          } else {
            this.selected.get(partMesh).push(overhangZoneName)
          }
        } else {
          this.selected.set(partMesh, [overhangZoneName])
          this.selected.forEach((value, key) => {
            if (key !== partMesh) {
              this.deselect([{ body: key as AbstractMesh, part: partMesh }], false)
            }
          })
        }

        if (!silent) {
          this.selectSupportEvent.trigger({
            overhangZoneName,
            attach,
            buildPlanItemId: partMesh.metadata.buildPlanItemId,
          })
        }

        this.updateSupportMaterials(partMesh)
      } else {
        if (this.isSelected(item)) {
          if (!item.face) {
            this.selected.set(partMesh, [])
            this.updateSupportMaterials(partMesh)
            if (!silent) {
              this.selectSupportEvent.trigger({
                buildPlanItemId: partMesh.metadata.buildPlanItemId,
                overhangZoneName: null,
                attach: false,
              })
            }
          }
        } else if (!this.selected.size) {
          this.updateParent()
          this.selected.set(partMesh, [])

          if (!silent) {
            const transformation = []
            partMesh
              .getWorldMatrix()
              .transpose()
              .toArray()
              .forEach((i) => transformation.push(i))

            const hullBBox = partMesh.metadata.hullBInfo.boundingBox
            const translation = new Vector3(hullBBox.centerWorld.x, hullBBox.centerWorld.y, hullBBox.minimumWorld.z)
            this.elementsSelected.trigger({
              attach,
              selectedItems: [
                {
                  translation,
                  id: partMesh.metadata.buildPlanItemId,
                  type: SelectionUnit.Part,
                },
              ],
            })

            this.generateOverhangMeshByClickEvent.trigger({
              transformation,
              buildPlanItemId: partMesh.metadata.buildPlanItemId,
            })
          }
        }

        this.updatePartMaterials(partMesh, false)
      }
    }
  }

  deselect(items: ISelectableNode[] = null, silent?: boolean) {
    if (items === null) {
      this.selected.forEach((value, key) => this.deselect([{ body: key as AbstractMesh }], true))
      if (!silent) {
        this.elementsSelected.trigger({ selectedItems: null, attach: false })
      }
    } else {
      const selectedItems = []
      for (const item of items) {
        const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
        this.selected.delete(partMesh)
        this.updatePartMaterials(partMesh)
        this.updateSupportMaterials(partMesh)

        if (partMesh === this.hoveredPart) {
          this.hoveredPart = null
        }
        selectedItems.push({ id: partMesh.metadata.buildPlanItemId, type: SelectionUnit.Part })
      }

      if (!silent) {
        this.elementsSelected.trigger({ selectedItems, attach: true })
      }
    }

    this.updateParent()
    if (this.scene.metadata && !this.scene.metadata.updateGpuPicker) {
      this.scene.metadata.updateGpuPicker = true
    }
  }

  getSelected(singleMesh: boolean) {
    if (!singleMesh) {
      return [...this.selected.keys()]
    }

    if (this.selected.size === 0) {
      return null
    }

    if (this.groupParent) {
      return this.groupParent
    }

    this.groupParent = new AbstractMesh(GROUP_PARENT_MESH_NAME, this.scene)
    const meshes = []
    this.selected.forEach((value, key) => {
      key.getChildMeshes().map((mesh) => {
        if (this.meshManager.isComponentMesh(mesh)) {
          meshes.push(mesh)
        }
      })
    })

    const boundingInfo = this.meshManager.getTotalBoundingInfo(meshes)
    this.groupParent.position = boundingInfo.boundingBox.center

    for (const mesh of [...this.selected.keys()]) {
      ;(mesh.parent as TransformNode).setParent(this.groupParent)
    }

    return this.groupParent
  }

  isSelected(item: ISelectableNode) {
    const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
    return this.selected.has(partMesh)
  }

  equals(item1: ISelectableNode, item2: ISelectableNode) {
    return item1.part === item2.part && item1.body === item2.body && item1.face === item2.face
  }

  dispose() {
    this.updateParent()
  }

  /** Returns Bounding info of selected bodies and supports in world coordinates. */
  getSelectionBoundingBox(): BoundingInfo {
    const selectedMeshes = []
    const bInfos: BoundingInfo[] = []
    for (const selectedPart of this.selected.keys()) {
      selectedPart.getChildMeshes().forEach((mesh) => {
        if (this.meshManager.isComponentMesh(mesh)) {
          // || this.meshManager.isLabelMesh(mesh)
          selectedMeshes.push(mesh)
        }
      })

      const supportParent = selectedPart
        .getChildTransformNodes()
        .find((c) => this.meshManager.isSupportMesh(c) && c.metadata.buildPlanItemId)

      if (
        supportParent &&
        supportParent.metadata &&
        supportParent.metadata.hullBInfo &&
        supportParent.getChildTransformNodes().length
      ) {
        bInfos.push(supportParent.metadata.hullBInfo)
      } else {
        selectedPart.getChildMeshes().forEach((mesh) => {
          if (this.meshManager.isSupportMesh(mesh)) {
            selectedMeshes.push(mesh)
          }
        })
      }
    }

    if (selectedMeshes.length) {
      bInfos.push(this.meshManager.getTotalBoundingInfo(selectedMeshes, true, true))
    }

    return this.meshManager.mergeBoundingInfos(bInfos)
  }

  private updatePartMaterials(partMesh: TransformNode, showHighlight: boolean = false) {
    const partMetadata = partMesh.metadata as IPartMetadata
    const isSelected = this.selected.has(partMesh)
    if (showHighlight) {
      partMetadata.color = PRIMARY_CYAN
    } else {
      partMetadata.color = isSelected ? PRIMARY_SELECTION : DEFAULT_COLOR
    }

    const childMeshes = partMesh.getChildMeshes(false, this.meshManager.isComponentMesh)
    for (const child of childMeshes) {
      this.meshManager.setInstancedBufferColor(child as InstancedMesh, showHighlight, isSelected)
    }
  }

  private updateSupportMaterials(partMesh: TransformNode, hoveredOverhangZoneName?: string) {
    const bpItemId = partMesh.metadata.buildPlanItemId
    let selectedZones = this.selected.get(partMesh)
    if (!selectedZones) {
      selectedZones = []
    }

    this.renderScene.updateSupportsMaterial(bpItemId, selectedZones, hoveredOverhangZoneName)
  }

  private getOverhangZoneName(mesh: TransformNode, face: Face) {
    if (this.meshManager.isOverhangSurface(mesh)) {
      if (!face) {
        return null
      }
      return face.name
    }

    if (mesh.name.startsWith(OVERHANG_VERTICES_NAME) || mesh.name.startsWith(OVERHANG_EDGES_NAME)) {
      return mesh.name
    }

    if (mesh.name === LINE_SUPPORT) {
      let supportId = mesh.id
      let support = mesh

      while (support.name !== SUPPORT) {
        supportId = support.parent.id
        support = support.parent as TransformNode
      }

      return (support.metadata as ISupportMetadata).belongsToOverhangElementName
    }

    return null
  }

  private updateParent() {
    if (!this.groupParent) return

    for (const child of this.groupParent.getChildTransformNodes(true)) {
      child.setParent(null)
    }

    this.scene.removeMesh(this.groupParent)
    this.groupParent.dispose()
    this.groupParent = null
  }
}

export class SelectionFaceEdge implements SelectionItem {
  groupParent: Face
  selected: Map<AbstractMesh, Face[]> = new Map<AbstractMesh, Face[]>()
  regularMaterial: StandardMaterial
  highlightMaterial: StandardMaterial
  selectionMaterial: StandardMaterial
  isGizmoEnabled: boolean = false

  private elementsSelected: VisualizationEvent<{ selectedItems: ISelectable[]; attach: boolean }>
  private renderScene: RenderScene
  private scene: Scene
  private meshManager: MeshManager

  constructor(renderScene: RenderScene, elementsSelected) {
    this.renderScene = renderScene
    this.scene = renderScene.getScene()
    this.regularMaterial = this.scene.getMaterialByName(DEFAULT_MATERIAL_NAME) as StandardMaterial
    this.highlightMaterial = this.scene.getMaterialByName(SHADER_HIGHLIGHT_MATERIAL_NAME) as StandardMaterial
    this.selectionMaterial = this.scene.getMaterialByName(SHADER_SELECTION_MATERIAL_NAME) as StandardMaterial
    this.meshManager = renderScene.getMeshManager()
    this.elementsSelected = elementsSelected
  }

  highlight(items: ISelectableNode[], showHighlight: boolean) {
    for (const item of items) {
      const mesh = item.body as any

      if (item.body.isDisposed()) {
        return
      }

      if (!mesh.sourceMesh.faceMaterial || !(mesh.sourceMesh.faceMaterial instanceof FaceColoringShader)) {
        mesh.sourceMesh.faceMaterial = new FaceColoringShader(
          this.renderScene,
          this.selectionMaterial,
          this.highlightMaterial,
        )
        mesh.sourceMesh.faceMaterial.shaderMaterial.isReady()
      }

      if (item.face) {
        const faceId = showHighlight ? item.face.id : -1
        mesh.instancedBuffers.hoverFaceId = new HoverableFace(faceId)
        mesh.sourceMesh.material = mesh.sourceMesh.metadata.originalMaterial =
          mesh.sourceMesh.faceMaterial.shaderMaterial
      }

      mesh.sourceMesh.faceMaterial.showHover(showHighlight)
    }
  }

  select(items: ISelectableNode[], attach: boolean) {
    this.updateParent()
    if (!attach) {
      this.deselect()
    }

    const selectedItems = []
    for (const item of items) {
      const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
      const mesh = item.body as any
      const allFaces = this.getAllFaces(mesh)

      if (allFaces.includes(item.face)) {
        this.deselect([item])
      } else {
        let selectedFaces = this.selected.get(mesh)
        if (selectedFaces) {
          if (selectedFaces.length < (mesh.sourceMesh.faceMaterial as FaceColoringShader).maxSelectedFacesCount) {
            selectedFaces.push(item.face)
          } else {
            // TODO: notify user about reached limit for mesh selected faces count
          }
        } else {
          selectedFaces = [item.face]
          if (!mesh.sourceMesh.faceMaterial || !(mesh.sourceMesh.faceMaterial instanceof FaceColoringShader)) {
            mesh.sourceMesh.faceMaterial = new FaceColoringShader(
              this.renderScene,
              this.selectionMaterial,
              this.highlightMaterial,
            )
            mesh.instancedBuffers.hoverFaceId = new HoverableFace(item.face.id)
            mesh.sourceMesh.faceMaterial.showHover(true)
          }
        }

        this.selected.set(mesh, selectedFaces)
        selectedItems.push({
          id: partMesh.metadata.buildPlanItemId,
          name: item.face.name,
          type: SelectionUnit.FaceAndEdge,
        })
        this.selectPickedFaces(mesh)
      }
    }

    this.elementsSelected.trigger({ selectedItems, attach })
  }

  deselect(items: ISelectableNode[] = null) {
    if (items === null) {
      this.selectPickedFaces(null)
      this.elementsSelected.trigger({ selectedItems: null, attach: false })
      this.selected.clear()
    } else {
      const selectedItems = []
      for (const item of items) {
        const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
        const selFaces = this.selected.get(item.body)
        const index = selFaces.indexOf(item.face)
        if (index !== -1) {
          selFaces.splice(index, 1)
          selectedItems.push({
            id: partMesh.metadata.buildPlanItemId,
            name: item.face.name,
            type: SelectionUnit.FaceAndEdge,
          })
          if (selFaces.length !== 0) {
            this.selected.set(item.body, selFaces)
          } else {
            this.selected.delete(item.body)
          }

          this.selectPickedFaces(item.body as InstancedMesh)
        }
      }

      this.elementsSelected.trigger({ selectedItems, attach: true })
    }

    this.updateParent()
  }

  getSelected(singleMesh: boolean) {
    const allFaces = this.getAllFaces()
    if (!singleMesh) {
      return allFaces
    }

    if (this.selected.size === 0) {
      return null
    }

    if (this.groupParent) {
      return this.groupParent
    }

    this.groupParent = new Face(null, GROUP_PARENT_MESH_NAME)
    for (const face of allFaces) {
      this.groupParent.indices = this.groupParent.indices.concat(face.indices)
      this.groupParent.facetIndices = this.groupParent.indices.concat(face.facetIndices)
    }

    return this.groupParent
  }

  isSelected(item: ISelectableNode) {
    return Array.from(this.selected.keys()).includes(item.body)
  }

  equals(item1: ISelectableNode, item2: ISelectableNode) {
    return item1.part === item2.part && item1.body === item2.body && item1.face === item2.face
  }

  dispose() {
    this.updateParent()
  }

  getSelectionBoundingBox(): BoundingInfo {
    throw new Error('Not implemented.')
  }

  private getAllFaces(body?: AbstractMesh) {
    if (body) {
      const faces = this.selected.get(body)
      return faces ? faces : []
    }

    const allValues = Array.from(this.selected.values())
    let allFaces = []
    for (const faces of allValues) {
      allFaces = allFaces.concat(faces)
    }

    return allFaces
  }

  private updateParent() {
    if (!this.groupParent) {
      return
    }

    this.groupParent = null
  }

  private selectPickedFaces(mesh: InstancedMesh) {
    const defaultMat = this.scene.getMaterialByID(DEFAULT_MATERIAL_NAME)
    if (mesh === null) {
      Array.from(this.selected.keys()).forEach((key) => {
        const faceMaterial = ((key as InstancedMesh).sourceMesh as any).faceMaterial
        if (faceMaterial) {
          faceMaterial.setSelectedFacesId(null)
        }

        this.setSelectedFaces((key as InstancedMesh).sourceMesh, null, null)
        this.disposeFaceMaterial((key as InstancedMesh).sourceMesh)
        ;(key as InstancedMesh).sourceMesh.material = defaultMat
      })
      return
    }

    let facesId = []
    const faces = this.selected.get(mesh)
    if (faces) {
      for (const face of faces) {
        facesId = facesId.concat(face.id)
      }
    }

    if (facesId.length === 0) {
      this.disposeFaceMaterial((mesh as InstancedMesh).sourceMesh)
      mesh.sourceMesh.material = defaultMat
    } else {
      this.setSelectedFaces(
        mesh.sourceMesh,
        facesId,
        mesh.sourceMesh.instances.findIndex((m) => m === mesh),
      )
      ;(mesh as any).sourceMesh.faceMaterial.setSelectedFacesId(mesh.sourceMesh.metadata.selectedFacesIds)
      mesh.sourceMesh.material = (mesh as any).sourceMesh.faceMaterial.shaderMaterial
    }
  }

  private setSelectedFaces(source: Mesh, facesId: number[], instanceIndex: number) {
    if (!source) {
      return
    }

    if (!facesId || !facesId.length) {
      source.metadata.selectedFacesIds.clear()
    } else {
      source.metadata.selectedFacesIds.set(instanceIndex, facesId)
    }
  }

  private disposeFaceMaterial(mesh: any) {
    if (mesh.faceMaterial) {
      mesh.faceMaterial.dispose()
      mesh.faceMaterial = undefined
    }
  }
}

export class SelectionDefect implements SelectionItem {
  groupParent: AbstractMesh
  selected: AbstractMesh[] = []
  regularMaterial: StandardMaterial
  highlightMaterial: StandardMaterial
  selectionMaterial: StandardMaterial
  isGizmoEnabled: boolean = false

  private renderScene: RenderScene
  private meshManager: MeshManager
  private scene: Scene

  private hoverDefect: IVisualizationEvent<{ type: PartDefect }>
  private selectDefects: IVisualizationEvent<{ types: PartDefect[]; attach: boolean }>
  private selectedFaces: Face[] = []

  constructor(
    renderScene: RenderScene,
    hoverDefect: IVisualizationEvent<{ type: PartDefect }>,
    selectDefects: IVisualizationEvent<{ types: PartDefect[]; attach: boolean }>,
  ) {
    this.renderScene = renderScene
    this.scene = renderScene.getScene()
    this.meshManager = renderScene.getMeshManager()
    this.hoverDefect = hoverDefect
    this.selectDefects = selectDefects
  }

  highlight(items: ISelectableNode[], showHighlight: boolean, silent?: boolean) {
    const faces: Face[] = []
    let partBody: InstancedMesh
    for (const item of items) {
      if (!this.meshManager.isDefectMesh(item.body) && !this.meshManager.isMeshDefect(item.body)) {
        continue
      }

      partBody = this.meshManager.getPartBodyByDefectShape(item.body) as InstancedMesh
      if (isNumber((item.body as InstancedMesh).sourceMesh.metadata.entity)) {
        const face = partBody.metadata.faces.find(
          (f) => f.name === (item.body as InstancedMesh).sourceMesh.metadata.entity.toString(),
        )
        if (face) {
          faces.push(face)
        }
      }

      if (showHighlight) {
        item.body.instancedBuffers.color = PRIMARY_CYAN
        ;(item.body as InstancedMesh).sourceMesh.renderingGroupId = MESH_RENDERING_GROUP_ID + 1
      } else {
        if (this.isSelected(item)) {
          item.body.instancedBuffers.color = PRIMARY_SELECTION
          continue
        }

        item.body.instancedBuffers.color = Color3.Red()
        ;(item.body as InstancedMesh).sourceMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
      }
    }

    if (faces.length) {
      this.togglePartBodyFacesHighlight(partBody, faces, showHighlight)
    }

    if (!showHighlight) {
      this.hoverDefect.trigger({ type: null })
      return
    }
  }

  select(items: ISelectableNode[], attach: boolean, silent?: boolean) {
    this.updateParent()
    if (!attach) {
      this.deselect(null, silent)
    }

    let isSelected = false
    let isDeselected = false

    let partBody
    for (const item of items) {
      if (!this.meshManager.isDefectMesh(item.body) && !this.meshManager.isMeshDefect(item.body)) {
        continue
      }

      if (this.selected.includes(item.body)) {
        this.deselect([item], silent)
        isDeselected = true
      } else {
        this.selected.push(item.body)
        partBody = this.meshManager.getPartBodyByDefectShape(item.body) as InstancedMesh
        if (isNumber((item.body as InstancedMesh).sourceMesh.metadata.entity)) {
          const face = partBody.metadata.faces.find(
            (f) => f.name === (item.body as InstancedMesh).sourceMesh.metadata.entity.toString(),
          )
          if (face) {
            this.selectedFaces.push(face)
          }
        }

        item.body.instancedBuffers.color = PRIMARY_SELECTION
        ;(item.body as InstancedMesh).sourceMesh.renderingGroupId = MESH_RENDERING_GROUP_ID + 1
        isSelected = true
      }
    }

    if (this.selectedFaces.length) {
      this.showSelectedPartBodyFaces(partBody, this.selectedFaces)
    }
  }

  deselect(items: ISelectableNode[] = null, silent?: boolean) {
    let partBody
    if (items === null) {
      for (const selMesh of this.selected) {
        partBody = this.meshManager.getPartBodyByDefectShape(selMesh) as InstancedMesh
        selMesh.instancedBuffers.color = Color3.Red()
        ;(selMesh as InstancedMesh).sourceMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
      }

      this.selected = []
      this.selectedFaces = []
    } else {
      for (const item of items) {
        partBody = this.meshManager.getPartBodyByDefectShape(item.body) as InstancedMesh
        const index = this.selected.indexOf(item.body)

        if (index !== -1) {
          item.body.instancedBuffers.color = Color3.Red()
          ;(item.body as InstancedMesh).sourceMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
          this.selected.splice(index, 1)
        }

        if (isNumber((item.body as InstancedMesh).sourceMesh.metadata.entity)) {
          const face = partBody.metadata.faces.find(
            (f) => f.name === (item.body as InstancedMesh).sourceMesh.metadata.entity.toString(),
          )
          if (face) {
            const faceIndex = this.selectedFaces.findIndex((f) => f === face)
            if (faceIndex !== -1) {
              this.selectedFaces.splice(index, 1)
            }
          }
        }
      }
    }

    this.updateParent()
    if (this.selectedFaces.length) {
      this.showSelectedPartBodyFaces(partBody, this.selectedFaces)
    }
  }

  getSelected(singleMesh: boolean) {
    if (!singleMesh) {
      return this.selected
    }

    if (this.selected.length === 0) {
      return null
    }

    if (this.groupParent) {
      return this.groupParent
    }

    this.groupParent = new AbstractMesh(GROUP_PARENT_MESH_NAME, this.scene)
    const boundingInfo = this.meshManager.getTotalBoundingInfo(this.selected)
    this.groupParent.position = boundingInfo.boundingBox.center

    for (const mesh of this.selected) {
      ;(mesh as any).nativaeParent = mesh.parent
      mesh.parent = this.groupParent
    }

    return this.groupParent
  }

  isSelected(item: ISelectableNode) {
    return this.selected.includes(item.body)
  }

  equals(item1: ISelectableNode, item2: ISelectableNode) {
    return item1.body === item2.body
  }

  dispose() {
    this.updateParent()
  }

  getSelectionBoundingBox(): BoundingInfo {
    throw new Error('Not implemented.')
  }

  private updateParent() {
    if (!this.groupParent) return

    for (const child of this.groupParent.getChildMeshes(true)) {
      const nativeParent = (child as any).nativeParent
      child.parent = nativeParent
    }

    this.scene.removeMesh(this.groupParent)
    this.groupParent.dispose()
    this.groupParent = null
  }

  private togglePartBodyFacesHighlight(body: InstancedMesh, faces: Face[], showHighlight: boolean) {
    if (!body || !faces) {
      return
    }

    let secondaryMaterial: StandardMaterial
    let selectionMaterial: StandardMaterial
    const defaultMaterial = this.scene.getMaterialByName(DEFAULT_MATERIAL_NAME) as StandardMaterial
    const highlightMaterial = this.scene.getMaterialByName(HIGHLIGHT_MATERIAL_NAME) as StandardMaterial
    const isTransparentState = this.selectedFaces.length ? true : false
    if (isTransparentState) {
      secondaryMaterial = this.scene.getMaterialByName(CUTOUT_MATERIAL) as StandardMaterial
      selectionMaterial = defaultMaterial
    } else {
      secondaryMaterial = defaultMaterial
      selectionMaterial = this.scene.getMaterialByName(SELECTION_MATERIAL_NAME) as StandardMaterial
    }

    if (!showHighlight) {
      if (body.sourceMesh.material instanceof MultiMaterial) {
        ;(body.sourceMesh.material as MultiMaterial).subMaterials.forEach((m) => {
          ;(m as StandardMaterial).useLogarithmicDepth = false
        })
        body.sourceMesh.material.dispose()
      }

      body.sourceMesh.material = body.sourceMesh.metadata.originalMaterial = defaultMaterial
      const verticesCount = body.sourceMesh.getTotalVertices()
      const indicesCount = body.sourceMesh.getTotalIndices()
      body.sourceMesh.subMeshes = [new SubMesh(0, 0, verticesCount, 0, indicesCount, body.sourceMesh)]

      if (this.selectedFaces.length) {
        this.showSelectedPartBodyFaces(body, this.selectedFaces)
      }
    } else {
      const multiMaterial = new MultiMaterial(DEFECTIVE_FACES_MATERIAL_NAME, this.scene)
      multiMaterial.subMaterials.push(selectionMaterial)
      multiMaterial.subMaterials.push(highlightMaterial)
      multiMaterial.subMaterials.push(secondaryMaterial)

      const sortedIndices = []
      const knownHighlightedFaces = []
      const knownSelectedFaces = []
      let selectedIndices = 0
      let highlightedIndices = 0
      let validIndices = 0

      /** Split facets on selected, highlighted and other */
      for (const face of faces) {
        if (!knownHighlightedFaces.includes(face)) {
          sortedIndices.push(...face.indices)
          highlightedIndices += face.indices.length
          knownHighlightedFaces.push(face)
        }
      }
      for (const selectedFace of this.selectedFaces) {
        if (!knownHighlightedFaces.includes(selectedFace) && !knownSelectedFaces.includes(selectedFace)) {
          sortedIndices.unshift(...selectedFace.indices)
          selectedIndices += selectedFace.indices.length
          knownSelectedFaces.push(selectedFace)
        }
      }
      for (const face of body.metadata.faces) {
        if (!knownHighlightedFaces.includes(face) && !knownSelectedFaces.includes(face)) {
          sortedIndices.push(...face.indices)
          validIndices += face.indices.length
        }
      }

      /** Update vertices, submeshes, material */
      body.sourceMesh.updateIndices(sortedIndices)
      if (body.sourceMesh.material instanceof MultiMaterial) {
        body.sourceMesh.material.dispose()
      }

      body.sourceMesh.material = body.sourceMesh.metadata.originalMaterial = multiMaterial
      body.sourceMesh.subMeshes = []

      const verticesCount = body.sourceMesh.getTotalVertices()
      const s1 = new SubMesh(0, 0, verticesCount, 0, selectedIndices, body.sourceMesh)
      const s2 = new SubMesh(1, 0, verticesCount, selectedIndices, highlightedIndices, body.sourceMesh)
      const s3 = new SubMesh(2, 0, verticesCount, selectedIndices + highlightedIndices, validIndices, body.sourceMesh)
    }
  }

  private toggleValidBodiesHighlight(body: InstancedMesh, showHighlight: boolean) {
    const partMesh = this.meshManager.getBuildPlanItemMeshByChild(body)
    const highlightMaterial = this.scene.getMaterialByName(CUTOUT_MATERIAL) as StandardMaterial
    const defaultMaterial = this.scene.getMaterialByName(DEFAULT_MATERIAL_NAME) as StandardMaterial
    const material = showHighlight ? highlightMaterial : defaultMaterial
    partMesh.getChildMeshes().map((partBody) => {
      if (this.meshManager.isComponentMesh(partBody) && partBody !== body) {
        const pBody = partBody as InstancedMesh
        pBody.sourceMesh.material = pBody.sourceMesh.metadata.originalMaterial = material
      }
    })
  }

  private showSelectedPartBodyFaces(body: InstancedMesh, faces: Face[]) {
    if (!body || !faces) {
      return
    }

    const defaultMaterial = this.scene.getMaterialByName(DEFAULT_MATERIAL_NAME) as StandardMaterial
    const secondaryMaterial = this.scene.getMaterialByName(CUTOUT_MATERIAL) as StandardMaterial
    const selectionMaterial = defaultMaterial
    if (!faces.length) {
      if (body.sourceMesh.material instanceof MultiMaterial) {
        ;(body.sourceMesh.material as MultiMaterial).subMaterials.forEach((m) => {
          ;(m as StandardMaterial).useLogarithmicDepth = false
        })
        body.sourceMesh.material.dispose()
      }

      body.sourceMesh.material = body.sourceMesh.metadata.originalMaterial = defaultMaterial
      const verticesCount = body.sourceMesh.getTotalVertices()
      const indicesCount = body.sourceMesh.getTotalIndices()
      body.sourceMesh.subMeshes = [new SubMesh(0, 0, verticesCount, 0, indicesCount, body.sourceMesh)]

      const crossSectionMaterial = this.scene.getMaterialByName(CROSS_SECTION_MESH_MATERIAL)
      crossSectionMaterial.alpha = 1

      this.toggleValidBodiesHighlight(body, false)
    } else {
      selectionMaterial.useLogarithmicDepth = true
      const multiMaterial = new MultiMaterial(DEFECTIVE_FACES_MATERIAL_NAME, this.scene)
      multiMaterial.subMaterials.push(selectionMaterial)
      multiMaterial.subMaterials.push(secondaryMaterial)

      const sortedIndices = []
      const knownFaces = []
      let selectedIndices = 0
      let validIndices = 0

      /** Split facets on selected and other */
      for (const face of faces) {
        if (!knownFaces.includes(face)) {
          sortedIndices.push(...face.indices)
          selectedIndices += face.indices.length
          knownFaces.push(face)
        }
      }
      for (const face of body.metadata.faces) {
        if (!faces.includes(face)) {
          sortedIndices.push(...face.indices)
          validIndices += face.indices.length
        }
      }

      /** Update vertices, submeshes, material */
      body.sourceMesh.updateIndices(sortedIndices)
      if (body.sourceMesh.material instanceof MultiMaterial) {
        body.sourceMesh.material.dispose()
      }

      body.sourceMesh.material = body.sourceMesh.metadata.originalMaterial = multiMaterial
      body.sourceMesh.subMeshes = []

      const verticesCount = body.sourceMesh.getTotalVertices()
      const s1 = new SubMesh(0, 0, verticesCount, 0, selectedIndices, body.sourceMesh)
      const s2 = new SubMesh(1, 0, verticesCount, selectedIndices, validIndices, body.sourceMesh)

      const crossSectionMaterial = this.scene.getMaterialByName(CROSS_SECTION_MESH_MATERIAL)
      crossSectionMaterial.alpha = 0.2
      this.toggleValidBodiesHighlight(body, true)
    }
  }
}

export class SelectionManager {
  readonly highlightMaterial: Material
  readonly selectionBox: SelectionBox

  private onElementsSelected = new VisualizationEvent<{ selectedItems: ISelectable[]; attach: boolean }>()
  private onSelectionBoundingGeometryReady = new VisualizationEvent<BoundingBox2D>()
  private onGenerateOverhangMeshByClickEvent = new VisualizationEvent<{
    buildPlanItemId: string
    transformation: number[]
  }>()
  private onSelectSupportEvent = new VisualizationEvent<{
    buildPlanItemId: string
    overhangZoneName: string
    attach: boolean
  }>()
  private onHoverSupportEvent = new VisualizationEvent<{
    buildPlanItemId: string
    overhangZoneName: string
  }>()
  private onHoverDefect = new VisualizationEvent<{ type: PartDefect }>()
  private onSelectDefects = new VisualizationEvent<{ types: PartDefect[]; attach: boolean }>()
  private addSelectedLabeledBodiesEvent = new VisualizationEvent<LabeledBodyWIthTransformation[]>()
  private removeSelectedLabeledBodiesEvent = new VisualizationEvent<ISelectable[]>()
  private renderScene: RenderScene
  private scene: Scene
  private meshManager: MeshManager
  private selectionItem: SelectionItem
  private selectionMode: SelectionUnit
  private gizmo: Gizmo
  private selectionPart: SelectionPart
  private selectionBody: SelectionBody
  private selectionFaceEdge: SelectionFaceEdge
  private selectionPartAndSupport: SelectionPartAndSupport
  private selectionDefect: SelectionDefect

  private savedSelected: AbstractMesh[] = []
  private savedSelectionMode: SelectionUnit = SelectionUnit.Part

  constructor(
    renderScene: RenderScene,
    highlightMaterial: Material,
    collisionCallback: Function,
    dragListeners?: IActiveToggle[],
  ) {
    this.renderScene = renderScene
    this.scene = renderScene.getScene()
    this.meshManager = this.renderScene.getMeshManager()
    this.highlightMaterial = highlightMaterial
    this.selectionBox = new SelectionBox(this.scene, renderScene.getActiveCamera(), this, renderScene.getMeshManager())
    this.deactivateSelectionBox()
    const listeners = dragListeners ? dragListeners : []
    this.gizmo = new Gizmo(renderScene, this, listeners.concat(this.selectionBox), collisionCallback)

    // selection items
    this.selectionPart = new SelectionPart(
      renderScene,
      this.onElementsSelected,
      this.onSelectionBoundingGeometryReady,
      this.generateOverhangMeshByClickEvent,
    )
    this.selectionBody = new SelectionBody(
      this.renderScene,
      this.onElementsSelected,
      this.addSelectedLabeledBodies,
      this.removeSelectedLabeledBodies,
    )
    this.selectionFaceEdge = new SelectionFaceEdge(this.renderScene, this.onElementsSelected)
    this.selectionPartAndSupport = new SelectionPartAndSupport(
      this.renderScene,
      this.onElementsSelected,
      this.generateOverhangMeshByClickEvent,
      this.selectSupportEvent,
      this.hoverSupportEvent,
    )
    this.selectionDefect = new SelectionDefect(this.renderScene, this.hoverDefect, this.selectDefects)

    // default item
    this.selectionMode = SelectionUnit.Part
    this.selectionItem = this.selectionPart
    this.selectionBox.setSelectionMode(SelectionUnit.Part)
  }

  get elementsSelected() {
    return this.onElementsSelected.expose()
  }

  get transformationChange() {
    return this.gizmo.transformationChange
  }

  get transformationChangeBatch() {
    return this.gizmo.transformationChangeBatch
  }

  get deleteOverhangsAndSupportsEvent() {
    return this.gizmo.deleteOverhangsAndSupportsEvent
  }

  get onSelectionBoundingGeometryReadyEvent() {
    return this.onSelectionBoundingGeometryReady
  }

  get gizmos() {
    return this.gizmo
  }

  get generateOverhangMeshByClickEvent() {
    return this.onGenerateOverhangMeshByClickEvent.expose()
  }

  get generateOverhangMeshEventDebounced() {
    return this.gizmo.generateOverhangMeshEventDebounced
  }

  get selectSupportEvent() {
    return this.onSelectSupportEvent.expose()
  }

  get hoverSupportEvent() {
    return this.onHoverSupportEvent.expose()
  }

  get hoverDefect() {
    return this.onHoverDefect.expose()
  }

  get selectDefects() {
    return this.onSelectDefects.expose()
  }

  get addSelectedLabeledBodies() {
    return this.addSelectedLabeledBodiesEvent.expose()
  }

  get removeSelectedLabeledBodies() {
    return this.removeSelectedLabeledBodiesEvent.expose()
  }

  getSelectionMode() {
    return this.selectionMode
  }

  setSelectionMode(
    mode: SelectionUnit,
    options: { shouldAffectSelectionBox: boolean } = { shouldAffectSelectionBox: true },
  ) {
    this.selectionMode = mode
    this.selectionBox.setSelectionMode(mode)
    switch (mode) {
      case SelectionUnit.Part:
        this.selectionItem = this.selectionPart
        if (options.shouldAffectSelectionBox) {
          this.activateSelectionBox()
        }
        break
      case SelectionUnit.Body:
        this.selectionItem = this.selectionBody
        if (options.shouldAffectSelectionBox) {
          this.deactivateSelectionBox()
        }
        break
      case SelectionUnit.FaceAndEdge:
        this.selectionItem = this.selectionFaceEdge
        if (options.shouldAffectSelectionBox) {
          this.deactivateSelectionBox()
        }
        break
      case SelectionUnit.PartAndSupport:
        this.selectionItem = this.selectionPartAndSupport
        if (options.shouldAffectSelectionBox) {
          this.deactivateSelectionBox()
        }
        break
      case SelectionUnit.Defect:
        this.selectionItem = this.selectionDefect
        if (options.shouldAffectSelectionBox) {
          this.deactivateSelectionBox()
        }
        break
    }
  }

  updateGizmoScale() {
    this.gizmo.updateGizmoScale()
  }

  highlight(items: ISelectableNode[], showHighlight: boolean, silent?: boolean, highlightColor?: Color3) {
    this.selectionItem.highlight(items, showHighlight, silent, highlightColor)
    if (this.scene.metadata && !this.scene.metadata.updateGpuPicker) {
      this.scene.metadata.updateGpuPicker = false
    }
  }

  select(items: ISelectableNode[], attach: boolean, silent?: boolean) {
    this.gizmo.hide()
    this.selectionItem.select(items, attach, silent)
    if (this.renderScene.isDebugModeEnabled) {
      for (const item of items) {
        const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
        this.toggleObbTreeForMesh(partMesh, true)
      }
    }
    if (this.renderScene.isHullModeEnabled) {
      for (const item of items) {
        const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
        this.toggleHullForMesh(partMesh, true)
      }
    }

    if (this.scene.metadata && !this.scene.metadata.updateGpuPicker) {
      this.scene.metadata.updateGpuPicker = false
    }
  }

  deselect(items: ISelectableNode[] = null, silent?: boolean) {
    this.gizmo.hide()
    if (this.renderScene.isDebugModeEnabled) {
      if (items) {
        for (const item of items) {
          const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
          this.toggleObbTreeForMesh(partMesh, false)
        }
      } else {
        this.toggleObbTree(false)
      }
    }
    if (this.renderScene.isHullModeEnabled) {
      if (items) {
        for (const item of items) {
          const partMesh = item.part ? item.part : this.meshManager.getBuildPlanItemMeshByChild(item.body)
          this.toggleHullForMesh(partMesh, false)
        }
      } else {
        this.toggleHull(false)
      }
    }

    this.selectionItem.deselect(items, silent)
    if (this.selectionItem.selected.length && this.selectionItem.isGizmoEnabled) {
      this.showGizmos()
    }

    if (this.scene.metadata && !this.scene.metadata.updateGpuPicker) {
      this.scene.metadata.updateGpuPicker = false
    }
  }

  deselectNonBarGeometry() {
    const nonBarMeshes = this.getSelected().filter(
      (selected) => this.meshManager.isComponentMesh(selected) && !selected.metadata.isBar,
    )
    const nonBarItems = []
    nonBarMeshes.map((mesh) => nonBarItems.push({ body: mesh }))
    if (nonBarMeshes.length) {
      this.deselect(nonBarItems)
    }
  }

  toggleObbTree(isVisible: boolean) {
    let selectedMeshes = this.getSelected()
    if (this.selectionMode === SelectionUnit.Body) {
      selectedMeshes = selectedMeshes
        .map((selectedMesh) => this.meshManager.getBuildPlanItemMeshByChild(selectedMesh))
        .reduce((meshes, mesh) => {
          const alreadyIn = meshes.some((m) => m.id === mesh.id)
          if (!alreadyIn) {
            meshes.push(mesh)
          }
          return meshes
        }, [])
    }
    selectedMeshes.forEach((mesh) => {
      this.toggleObbTreeForMesh(mesh, isVisible)
    })
  }

  toggleObbTreeForMesh(mesh: TransformNode, isVisible: boolean) {
    this.renderScene.getObbTree().showObbTree(mesh.metadata.bvh, isVisible, mesh)
  }

  toggleHull(isVisible: boolean) {
    const selectedMeshes = this.getSelected()
    selectedMeshes.forEach((mesh) => {
      this.toggleHullForMesh(mesh, isVisible)
    })
  }

  toggleHullForMesh(mesh: TransformNode, isVisible: boolean) {
    this.meshManager.showMeshHull(mesh.metadata.hull, isVisible, mesh)
  }

  getSelectionBoundingBox() {
    return this.selectionItem.getSelectionBoundingBox()
  }

  getSelectedItems() {
    return this.selectionItem.selected.length
  }

  getSelected(singleMesh?: false): AbstractMesh[]
  getSelected(singleMesh: true): AbstractMesh
  getSelected(singleMesh = false): AbstractMesh[] | AbstractMesh {
    return this.selectionItem.getSelected(singleMesh)
  }

  isSelected(item: ISelectableNode) {
    return this.selectionItem.isSelected(item)
  }

  equals(item1: ISelectableNode, item2: ISelectableNode) {
    if (
      this.meshManager.isClearanceSensitiveZone(item1.body) &&
      this.meshManager.isClearanceSensitiveZone(item2.body)
    ) {
      return item1.body === item2.body
    }

    return this.selectionItem.equals(item1, item2)
  }

  setGizmoVisibility(isVisible: boolean) {
    this.gizmo.setGizmoVisibility(isVisible)
  }

  setSelectionModeAndReselect(mode: SelectionUnit) {
    const selected = this.getSelected()
    this.deselect()
    this.setSelectionMode(mode)
    selected.forEach((item) => {
      let selectableNode: ISelectableNode
      if (mode === SelectionUnit.Part || mode === SelectionUnit.PartAndSupport) {
        selectableNode = { part: item }
      } else {
        selectableNode = { body: item }
      }

      this.select([selectableNode], true)
    })
  }

  saveSelectionManagerState() {
    this.savedSelectionMode = this.selectionMode
    this.savedSelected = this.getSelected()
  }

  restoreSelectionManagerState() {
    this.deselect()
    this.setSelectionMode(this.savedSelectionMode)
    this.savedSelected.forEach((item) => {
      let selectableNode: ISelectableNode
      if (this.selectionMode === SelectionUnit.Part || this.selectionMode === SelectionUnit.PartAndSupport) {
        selectableNode = { part: item }
      } else {
        selectableNode = { body: item }
      }

      this.select([selectableNode], true)
    })

    this.savedSelectionMode = SelectionUnit.Part
    this.savedSelected = []
  }

  showGizmos() {
    this.gizmo.show(this.selectionItem.getSelected(true))
    this.gizmo.attachCollisionCheckToDragObservable()
  }

  enableGizmos() {
    this.gizmo.isEnabled = true
    this.showGizmos()
  }

  disableGizmos(silent: boolean) {
    this.gizmo.hide(silent)
    this.gizmo.isEnabled = false
  }

  toggleGizmosVisibility(isVisible: boolean) {
    this.gizmo.toggleVisibility(isVisible)
  }

  getSelectionMaterial() {
    return this.selectionItem.selectionMaterial
  }

  dispose() {
    this.selectionItem.dispose()
    this.selectionPart.dispose()
    this.selectionBody.dispose()
    this.selectionFaceEdge.dispose()
    this.selectionPartAndSupport.dispose()
    this.selectionDefect.dispose()
    this.gizmo.dispose()
  }

  setSendBoundingAnchorPoints(shouldSend: boolean) {
    ;(this.selectionItem as SelectionPart).sendBoundingAnchorPoint = shouldSend
  }

  sendBoundingAnchorPoint() {
    ;(this.selectionItem as SelectionPart).send2DBoundingPoints()
  }

  rebuildSupports() {
    this.gizmo.rebuildSupports()
  }

  activateSelectionBox() {
    this.selectionBox.activate()
  }

  deactivateSelectionBox() {
    this.selectionBox.deactivate()
  }
}
