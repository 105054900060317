import { PART_PARAMETER_DEFAULT_NAMES } from '../../constants'
import { GeometryType } from '../../types/BuildPlans/IBuildPlan'
import { SupportTypes } from '../../types/BuildPlans/IBuildPlanItemSettings'
import { PrintStrategyDefaultsDto } from '../../types/PrintStrategy/BuildPlanPrintStrategy'
import { GeometryTypes } from '../../visualization/models/DataModel'
import { VersionablePk } from '@/types/Common/VersionablePk'

export const getDefaultBaseOnType = (
  defaults: PrintStrategyDefaultsDto,
  type: GeometryType,
  bodyType: GeometryTypes,
) => {
  switch (type) {
    case GeometryType.Production:
      return new VersionablePk(defaults.productionId, defaults.productionVersion)
    case GeometryType.Coupon:
      return new VersionablePk(defaults.couponId, defaults.couponVersion)
    case GeometryType.Support:
      return bodyType === GeometryTypes.Sheet
        ? new VersionablePk(defaults.supportImportedSheetId, defaults.supportImportedSheetVersion)
        : new VersionablePk(defaults.supportImportedVolumeId, defaults.supportImportedVolumeVersion)
    default:
      return null
  }
}

export const getSupportDefaultBasedOnType = (defaults: PrintStrategyDefaultsDto, supportType: SupportTypes) => {
  switch (supportType) {
    case SupportTypes.Lines1:
      return new VersionablePk(defaults.supportAmpLineId, defaults.supportAmpLineVersion)
    case SupportTypes.Solid:
      return new VersionablePk(defaults.supportAmpSolidId, defaults.supportAmpSolidVersion)
    default:
      return null
  }
}

export const getDefaultNameBasedOnBodyType = (type: GeometryType, bodyType: GeometryTypes) => {
  switch (type) {
    case GeometryType.Production:
      return PART_PARAMETER_DEFAULT_NAMES.PRODUCTION
    case GeometryType.Coupon:
      return PART_PARAMETER_DEFAULT_NAMES.COUPON
    case GeometryType.Support:
      switch (bodyType) {
        case GeometryTypes.Sheet:
          return PART_PARAMETER_DEFAULT_NAMES.IMPORTED_SHEET
        case GeometryTypes.Solid:
          return PART_PARAMETER_DEFAULT_NAMES.IMPORTED_SUPPORT
        default:
          return PART_PARAMETER_DEFAULT_NAMES.SUPPORT
      }
    default:
      return 'Print Strategy Default'
  }
}

export const defaultNameBasedOnSupportType = (type: SupportTypes) => {
  switch (type) {
    case SupportTypes.Lines1:
      return PART_PARAMETER_DEFAULT_NAMES.AMP_GENERATED_LINE_TYPE
    case SupportTypes.Solid:
      return PART_PARAMETER_DEFAULT_NAMES.AMP_GENERATED_SOLID_TYPE
    default:
      return 'Print Strategy Default'
  }
}
