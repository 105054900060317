import { SortOrders } from '@/types/SortModes'

export const orderBy = (arr: any, props: any[], orders?: SortOrders[]) =>
  [...arr].sort((a, b) =>
    props.reduce((acc, prop, i) => {
      if (acc === 0) {
        return compareProps(a[prop], b[prop], i, orders)
      }
      return acc
    }, 0),
  )

export const compareProps = (aProp: any, bProp: any, index: number, orders?: SortOrders[]) => {
  const valA = typeof aProp === 'string' ? aProp.toUpperCase() : aProp
  const valB = typeof bProp === 'string' ? bProp.toUpperCase() : bProp
  const [p1, p2] = orders && orders[index] === SortOrders.Descending ? [valB, valA] : [valA, valB]
  return p1 > p2 ? 1 : p1 < p2 ? -1 : 0
}

export const areEqual = (a, b) => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
}

export const contentsAreEqual = (a, b) => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val) => b.includes(val))
}

export const sortArrayByAnotherArray = (array: any[], order: any[], key?: string) => {
  array.sort((a, b) => {
    const A = a[key] || a
    const B = b[key] || b

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1
    }
    return -1
  })

  return array
}

export const setNestedValue = (baseArray: any[], indices: number[], value?: any) => {
  let nested = baseArray
  indices.forEach((index, count) => {
    if (count === indices.length - 1) {
      nested[index] = value
    } else if (!nested[index]) {
      nested[index] = []
    }

    nested = nested[index]
  })
}
