// H3

export enum PixelLevelFeature {
  Solid = 'solid',
  Edge = 'edge',
  Void = 'void',
}

interface IPixelLevel {
  feature: PixelLevelFeature
  grayLevel: number
  jetting: null
}

interface IAttenuation {
  XYLengthUnits: string
  XYlength: number
  Xminus: false
  Xplus: false
  Yminus: false
  Yplus: false
  ZLengthUnits: string
  Zlength: number
  Zminus: true
  Zplus: false
  constrainThickness: false
  constrainThicknessPercentage: number
  option: string
}

interface IImageColoring {
  attenuation: IAttenuation
  exposurePattern: null
  exposurePatternLayerCount: number
  exposurePatternSizeInPixels: number
  pixelLevels: IPixelLevel[]
}

interface IImageShifting {
  RandomlyShiftInY: true
  YShiftSize: number
  YShiftSizeUnits: string
}

interface ISliceSettings {
  LayerThickness_um: number
  imageColoring: IImageColoring
  imageShifting: IImageShifting
}

interface IEnvironmental {
  Temperature_SetPoint_degC: number
  PurgeFlow_IRLamp_Printhead_SetPoint_lpm: number
  PurgeFlow_IRLamp_Recoat_SetPoint_lpm: number
  PurgeFlow_MotionAndPrintheadPressurization_SetPoint_lpm: number
  PurgeFlow_HMBCooling_SetPoint_lpm: number
}

interface IHeating {
  BuildPlateTemperature_SetPoint_degC: number
  PowderTemperature_SetPoint_degC: number
  RecoatIR_Intensity_pct: number
  PrintheadIR_Intensity_pct: number
}

interface IJetting {
  JetSpeed_mm_s: number
  WipeSpeed_mm_s: number
  MoveSpeed_mm_s: number
  PurgeTime_ms: number
  PurgePressure_mmH2O: number
}

interface IPowderSupply {
  Refill_DwellTime_ms: number
  Dosing_Factor_pct: number
  DosingSupply_AngularSpeed_rev_s: number
  DosingRefill_AngularSpeed_rev_s: number
  FirstDose_FinalPosition_rev: number
  DosingRefill_HopperVibratorEnabled: number
  DosingRefill_PistonVibratorEnabled: number
}

interface IRecoat {
  Delay_ms: number
  FoundationLayers_count: number
  MoveSpeed_Supply_Depart_mm_s: number
  MoveSpeed_Supply_Return_mm_s: number
  MoveSpeed_Build_Depart_mm_s: number
  MoveSpeed_Build_Return_mm_s: number
  RollerSpeed_Recoat_Depart_rpm: number
  RollerSpeed_Recoat_Return_rpm: number
  RollerSpeed_SupplyLeveling_Depart_rpm: number
  RollerSpeed_SupplyLeveling_Return_rpm: number
  LiftLayerSplit_perc: number
}

export interface IBinderJetH3ProcessParameter {
  FileFormatVersion: number
  Name: string
  MachineType: string
  PowderType: string
  BinderType: string
  SliceSettings: ISliceSettings
  Environmental: IEnvironmental
  Heating: IHeating
  Jetting: IJetting
  PowderSupply: IPowderSupply
  Recoat: IRecoat
}

// H2

export interface IBinderJetH2ProcessParameter {
  Name: string
  ID: string
  FileFormatVersion: number
  DateCreated: string
  LastUpdate: string
  FoundationLayers_count: number
  RollerSupply_rpm: number
  RollerBuild_rpm: number
  UseRoller2_bool: boolean
  RecoatReverseCompaction_bool: boolean
  BuildLayerReverse_mm: number
  SupplyLayerSpeed_mmQs: number
  BuildLayerSpeed_mmQs: number
  SupplyDosing_mm: number
  LayerThickness_mm: number
  IRTemp_degC: number
  UseDualIR_bool: boolean
  RecoatReverseIR_bool: boolean
  IRLampOpenLoop_bool: boolean
  IRLampOpenLoop_perc: number
  IRLampOpenLoop2_perc: number
  PreRecoatDelay_sec: number
  InkSupplyPurge_sec: number
  JettingSpeed_mmQs: number
  WiperSpeed_mmQs: number
  BuildPlateSetPoint_degC: number
  BuildBox1SetPoint_degC: number
  BuildBox2SetPoint_degC: number
  BuildBox3SetPoint_degC: number
  BuildBox4SetPoint_degC: number
  BuildBox5SetPoint_degC: number
  BuildBox6SetPoint_degC: number
  BuildBox7SetPoint_degC: number
  BuildBoxCureSetPoint_degC: number
  BuildBoxCureDuration_hrs: number
  CureAfterBuild_bool: boolean
  IRPreHeat_bool: boolean
  IRPreHeatTemp_degC: number
  IRPreHeatTimeout_min: number
  PixelLevel_Solid: number
  PixelLevel_Edge: number
  PixelLevel_Void: number
  AttenXY_mm: number
  AttenZ_mm: number
  AttenXminus: boolean
  AttenXplus: boolean
  AttenYminus: boolean
  AttenYplus: boolean
  AttenZminus: boolean
  AttenZplus: boolean
  ShiftY: boolean
  ShiftYSize_mm: number
  constrainThickness: boolean
  constrainThickness_perc: number
  PHindex_PartialPixel: boolean
  PHindex_PartialPixelBetPasses: boolean
  PHindex_PartialPixelStep: number
  PHindex_Speed: number
  gBool_usePowderPlow: boolean
  gStr_Binder: string
  gStr_Powder: string
}
