import { render, staticRenderFns } from "./IconBackView.vue?vue&type=template&id=6cbf9bea&scoped=true"
import script from "./IconBackView.vue?vue&type=script&lang=ts"
export * from "./IconBackView.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cbf9bea",
  null
  
)

export default component.exports