import { del, get, post, put } from '@/api/http'
import { EndpointsUrls } from '@/configs/config'
import {
  BuildPlanItemInstancesDto,
  IBuildPlanItem,
  IConstraintsBuildPlanItemsDto,
  IMoveBuildPlanItemsDto,
  TransferPropsResponseDto,
} from '@/types/BuildPlans/IBuildPlan'
import { handleAPIError } from '@/api/common'
import { ILabel } from '@/types/Marking/ILabel'
import axios from 'axios'

const CancelToken = axios.CancelToken
const source = CancelToken.source()

export default {
  async getBuildPlanItemsByBuildPlanIdAndPartId(buildPlanId: string, partId: string) {
    try {
      return get<IBuildPlanItem[]>(`${EndpointsUrls.BuildPlanItems}/byBuildPlanAndPart/${buildPlanId}/${partId}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getBuildPlanItemByIbcPartId(ibcPartId: string) {
    try {
      return get<IBuildPlanItem>(`${EndpointsUrls.IBCPlans}/buildPlanItemByIbcPart/${ibcPartId}`, {})
    } catch (error) {
      handleAPIError(error)
    }
  },

  async batchMoveBuildPlanItems(moveBuildPlanItemsQueryDto: IMoveBuildPlanItemsDto) {
    try {
      return await put<IBuildPlanItem[]>(`${EndpointsUrls.BuildPlanItems}/batch/move`, moveBuildPlanItemsQueryDto)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async batchUpdateBuildPlanItemsConstraints(constraintsBuildPlanItemsDto: IConstraintsBuildPlanItemsDto) {
    try {
      return await put<IBuildPlanItem[]>(
        `${EndpointsUrls.BuildPlanItems}/batch/constraints`,
        constraintsBuildPlanItemsDto,
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async updateBuildPlanItems(buildPlanItemsDtos: IBuildPlanItem[], restoreSupportFiles = false) {
    try {
      return put<IBuildPlanItem[]>(`${EndpointsUrls.BuildPlanItems}/batch`, buildPlanItemsDtos, {
        params: { restoreSupportFiles },
      })
    } catch (error) {
      handleAPIError(error)
    }
  },

  async updateBuildPlanItem(
    buildPlanItemDto: IBuildPlanItem,
    hideAPIErrorMessages: boolean = false,
    silent: boolean = false,
  ) {
    try {
      return await put<IBuildPlanItem>(`${EndpointsUrls.BuildPlanItems}/${buildPlanItemDto.id}`, buildPlanItemDto, {
        cancelToken: source.token,
        params: {
          silent,
        },
      })
    } catch (error) {
      handleAPIError(error, hideAPIErrorMessages)
      throw error
    }
  },

  async createBuildPlanItems(buildPlanItems: IBuildPlanItem[]) {
    try {
      return await post<IBuildPlanItem[]>(`${EndpointsUrls.BuildPlanItems}/batch`, buildPlanItems)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async createBuildPlanItem(buildPlanItemDto: IBuildPlanItem) {
    try {
      return await post<IBuildPlanItem>(`${EndpointsUrls.BuildPlanItems}`, buildPlanItemDto)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async removeSupports(buildPlanItemDto: Partial<IBuildPlanItem>, hideAPIErrorMessages: boolean = false) {
    try {
      return await put<IBuildPlanItem>(
        `${EndpointsUrls.BuildPlanItems}/${buildPlanItemDto.id}/removeSupports`,
        buildPlanItemDto,
      )
    } catch (error) {
      handleAPIError(error, hideAPIErrorMessages)
    }
  },

  async transferBuildPlanItemsProps(payload: {
    sourceId: string
    targets: Array<{ id: string; transformationMatrix: number[] }>
  }) {
    return put<TransferPropsResponseDto>(`${EndpointsUrls.BuildPlanItems}/transferProps`, payload)
  },

  async updateLabel(buildPlanItemId: string, label: ILabel, file: File) {
    const url = `${EndpointsUrls.BuildPlanItems}/${buildPlanItemId}/label`

    const headers = {
      'Content-Type': 'multipart/form-data',
    }

    const formData = new FormData()
    formData.append('label', JSON.stringify(label))
    formData.append('file', file)

    try {
      return await put<IBuildPlanItem>(url, formData, { headers })
    } catch (error) {
      handleAPIError(error)
    }
  },

  async copyLabel(buildPlanItemId: string, label: ILabel) {
    try {
      return await post<IBuildPlanItem>(`${EndpointsUrls.BuildPlanItems}/${buildPlanItemId}/copyLabel`, label)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async deleteLabel(buildPlanItemId: string, labelId: string) {
    try {
      return await del<IBuildPlanItem>(`${EndpointsUrls.BuildPlanItems}/${buildPlanItemId}/label/${labelId}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async removeLegacyLabels(buildPlanId: string) {
    try {
      return await del<IBuildPlanItem>(`${EndpointsUrls.BuildPlanItems}/legacyLabels/${buildPlanId}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async instantiateBuildPlanItems(bpiInstances: BuildPlanItemInstancesDto[], hideAPIErrorMessages: boolean = false) {
    try {
      return await post<IBuildPlanItem[]>(`${EndpointsUrls.BuildPlanItems}/instantiate`, bpiInstances)
    } catch (error) {
      handleAPIError(error, hideAPIErrorMessages)
    }
  },

  async replaceBuildPlanItems(
    configs: Array<{
      replacementBuildPlanItem: IBuildPlanItem
      targetBuildPlanItemId: string
      targetBuildPlanItemInsightIds: string[]
    }>,
    hideAPIErrorMessages: boolean = false,
  ) {
    try {
      return post<IBuildPlanItem[]>(`${EndpointsUrls.BuildPlanItems}/replace`, configs)
    } catch (error) {
      handleAPIError(error, hideAPIErrorMessages)
    }
  },
}
