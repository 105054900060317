import { Command } from '@/types/UndoRedo/Command'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { Commit, Dispatch } from 'vuex'
import { ILabel } from '@/types/Marking/ILabel'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

export enum LabelMode {
  AddLabel,
  RemoveLabel,
}

export class LabelCommand extends Command implements ICommand {
  readonly commandType: CommandType
  readonly toolName = ToolNames.LABEL

  private readonly buildPlanItemId: string
  private readonly label: ILabel
  private readonly labelMode: LabelMode
  private readonly file: File

  constructor(
    buildPlanItemId: string,
    label: ILabel,
    file: File,
    commandType: CommandType,
    labelMode: LabelMode,
    protected dispatch: Dispatch,
    protected commit: Commit,
  ) {
    super()

    this.commandType = commandType
    this.buildPlanItemId = buildPlanItemId
    this.labelMode = labelMode
    this.label = JSON.parse(JSON.stringify(label))
    this.file = file
  }

  async undo(): Promise<void> {
    // If labelMode = RemoveLabel need to call createLabel method, otherwise need to call removeLabel method
    if (this.labelMode === LabelMode.RemoveLabel) {
      await this.createLabel()
      return
    }

    await this.removeLabel()
  }

  async redo(): Promise<void> {
    // If labelMode = RemoveLabel need to call removeLabel method, otherwise need to call createLabel method
    if (this.labelMode === LabelMode.RemoveLabel) {
      await this.removeLabel()
      return
    }

    await this.createLabel()
  }

  private async createLabel(): Promise<void> {
    this.commit('visualizationModule/setIsLoading', true, this.rootLevel)
    this.setIsLabelInstancing(true)

    const payload = {
      buildPlanItemId: this.buildPlanItemId,
      label: this.label,
      needToLoadItemLabel: true,
      file: this.file,
      insights: [],
      doNotForceUndoRedoCommandCreation: true,
    }

    await this.dispatch('buildPlans/addBuildPlanItemLabel', payload, this.rootLevel)
    this.setIsLabelInstancing(false)
    this.commit('visualizationModule/setIsLoading', false, this.rootLevel)
  }

  private async removeLabel(): Promise<void> {
    this.commit('visualizationModule/setIsLoading', true, this.rootLevel)

    const payload = {
      buildPlanItemId: this.buildPlanItemId,
      label: this.label,
      file: this.file,
      doNotForceUndoRedoCommandCreation: true,
    }

    if (this.labelMode === LabelMode.AddLabel) {
      this.commit('visualizationModule/deactivateLabelInteraction', this.label.id, this.rootLevel)
    }

    await this.dispatch('buildPlans/deleteBuildPlanItemLabel', payload, this.rootLevel)
    this.commit('visualizationModule/setIsLoading', false, this.rootLevel)
  }

  private setIsLabelInstancing(payload: boolean) {
    if (this.labelMode === LabelMode.AddLabel) {
      return
    }

    this.commit('buildPlans/setIsLabelInstancing', payload, this.rootLevel)
  }
}
