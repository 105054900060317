
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import SiteConstantUpdater from '@/components/layout/admin/sites/SiteConstantUpdater.vue'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { RouterNames } from '@/router'
import { SiteAdminMixin } from './mixins/SiteAdminMixin'
import { Site, EditViewMode, SiteDto, UpdateSiteDto } from '@/types/Sites/Site'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component({
  components: { SiteConstantUpdater },
})
export default class SiteListRow extends SiteAdminMixin {
  @sitesStore.Action updateSite: (params: { siteId: string; updateSiteDto: UpdateSiteDto }) => Promise<SiteDto>

  @Prop() headers: string[]
  @Prop() item: Site

  viewModel = {
    // @ts-ignore
    electricityPriceValue: this.item.electricityPrice || 0,
    // @ts-ignore
    argonPriceValue: this.item.argonPrice || 0,
    // @ts-ignore
    nitrogenPriceValue: this.item.nitrogenPrice || 0,
  }

  get isActiveElectricityPriceEditMode(): boolean {
    return this.editMode === EditViewMode.ElectricityPrice
  }

  get isActiveArgonPriceEditMode(): boolean {
    return this.editMode === EditViewMode.ArgonPrice
  }

  get isActiveNitrogenPriceEditMode(): boolean {
    return this.editMode === EditViewMode.NitrogenPrice
  }

  navigateToDetails(siteId) {
    const route = { name: RouterNames.EditSiteAdmin, params: { siteId } }

    // @ts-ignore
    this.$router.safePush(route)
  }

  activateEditSiteRowItemMode(editMode: EditViewMode) {
    this.editMode = editMode
  }

  onCloseEditMode() {
    this.editMode = EditViewMode.None
  }

  async onUpdateSiteConstants(updateSiteDto: UpdateSiteDto) {
    await this.updateSite({ updateSiteDto, siteId: this.item.id })
    this.editMode = EditViewMode.None
    this.$emit('itemUpdated')
  }

  @Watch('editMode')
  onEditModeChanged(value: EditViewMode) {
    if (value === EditViewMode.None) {
      this.viewModel.electricityPriceValue = this.item.electricityPrice || 0
      this.viewModel.argonPriceValue = this.item.argonPrice || 0
      this.viewModel.nitrogenPriceValue = this.item.nitrogenPrice || 0
    }
  }
}
