
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop, Watch } from 'vue-property-decorator'
import Menu from '@/components/controls/Common/Menu.vue'

@Component({
  components: {
    Menu,
  },
})
export default class ConfirmableSelector extends Vue {
  @Prop({ default: () => null }) value
  @Prop({ default: () => [] }) options
  @Prop({ default: '' }) label: string
  @Prop({ default: true }) editable: boolean
  @Prop({ default: 'auto' }) zIndex: number | string

  menuValue = null
  initialValue = null
  inEditState = false

  @Watch('value')
  onValueUpdate(newValue) {
    this.menuValue = newValue
    this.initialValue = newValue
  }

  mounted() {
    this.menuValue = this.value
    this.initialValue = this.value
  }

  submit() {
    this.$emit('submit', this.menuValue)
    this.initialValue = this.menuValue
    this.inEditState = false
  }

  cancel() {
    this.$emit('cancel')
    this.menuValue = this.initialValue
    this.inEditState = false
  }

  onClickOutside(e) {
    if (e && e.srcElement && e.srcElement.id === 'menu_row') return
    this.cancel()
  }

  get valueText() {
    const option = this.options.find((o) => o.value === this.menuValue)
    return (option && option.text) || this.value
  }

  get valueColor() {
    const option = this.options.find((o) => o.value === this.menuValue)
    return option && option.color
  }

  get showControls(): boolean {
    return this.menuValue !== this.initialValue
  }

  select(option) {
    this.menuValue = option.value
  }

  edit() {
    this.inEditState = true
  }
}
