
import Vue from 'vue'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import SearchField from '@/components/controls/Common/SearchField.vue'
import Button from '@/components/controls/Common/Button.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'

import { CostEstimationListItem, CostEstimationListHeader, CostEstimationListEmptyState } from './list'
import { CostEvaluationResultAdapterFactory } from './adapters'

import { ICostEstimation } from '@/types/CostEstimations/ICostEstimation'
import { DataType, ICostEstimationListItemViewModel } from './types/DataTable'

import { DEFAULT_UNITS_ID } from '@/constants'
import StoresNamespaces from '@/store/namespaces'
import { RouterNames } from '@/router'
import { orderBy } from '@/utils/array'
import { debounce } from '@/utils/debounce'
import { IMaterial } from '@/types/IMaterial'
import { FilterParamsMap } from '@/types/Filter'
import { IMachineConfig } from '@/types/IMachineConfig'

const costEstimationStore = namespace(StoresNamespaces.CostEstimation)
const userStore = namespace(StoresNamespaces.User)

const DEFAULT_VISIBLE_PAGES_COUNT = 10
const DEBOUNCE_TIME = 600

@Component({
  components: {
    SearchField,
    CostEstimationListItem,
    CostEstimationListHeader,
    CostEstimationListEmptyState,
    Button,
    ConfirmModal,
  },
})
export default class ManageCostEstimations extends Vue {
  @costEstimationStore.Action fetchFilteredCostEstimations: any
  @costEstimationStore.Action fetchMaterials: any
  @costEstimationStore.Action fetchParameterSets: any
  @costEstimationStore.Action fetchMachineConfigs: any
  @costEstimationStore.Action fetchRegions: any
  @costEstimationStore.Action createCostEstimation: any
  @costEstimationStore.Action deleteCostEstimation: any
  @costEstimationStore.Action cloneCostEstimation: any
  @costEstimationStore.Action getMaterialById: any
  @costEstimationStore.Action setSortParams: any
  @costEstimationStore.Action setFilterParams: any

  @costEstimationStore.Getter isLoading: boolean
  @costEstimationStore.Getter('getFilteredCostEstimations') getFilteredCostEstimations: any
  @costEstimationStore.Getter('getFilteredCostEstimationsCount') getFilteredCostEstimationsCount: any
  @costEstimationStore.Getter('getPaginationLimit') getPaginationLimit: any
  @costEstimationStore.Getter('getDefaultCostEstimation') getDefaultCostEstimation: any
  @costEstimationStore.Getter('getMaterials') getMaterials: IMaterial[]
  @costEstimationStore.Getter('getDefaultRegion') getDefaultRegion: any
  @costEstimationStore.Getter('getRegions') getRegions: string[]
  @costEstimationStore.Getter getMachineConfigById: any
  @costEstimationStore.Getter getMachineConfigs: IMachineConfig[]
  @costEstimationStore.Getter('getPageByPaginationOffset') getPageByPaginationOffset: any
  @costEstimationStore.Getter('getHasActiveFilters') getHasActiveFilters: string
  @costEstimationStore.Getter('getFilterParams') getFilterParams: FilterParamsMap

  @costEstimationStore.Mutation('setSearchEstimationName') setSearchEstimationName: any
  @costEstimationStore.Mutation('setPaginationOffset') setPaginationOffset: any

  @costEstimationStore.State('filterParams') filterParams: any
  @costEstimationStore.State('sortParams') sortParams: any
  @costEstimationStore.State('searchEstimationName') searchEstimationName: any

  isShownErrorMsg: boolean = false
  isDataLoaded: boolean = false
  items = []

  currentPage: number = 1
  visiblePagesCount = DEFAULT_VISIBLE_PAGES_COUNT

  listHasScroll: boolean = null

  changeSearchEstimationNameDebounced = debounce(DEBOUNCE_TIME, this.changeSearchEstimationName)

  $refs!: {
    list: HTMLElement
    confirm: InstanceType<typeof ConfirmModal>
  }

  get headers() {
    const defaultHeader = {
      text: '',
      value: '',
      headerKey: '',
      meta: { dataType: DataType.Text, sortable: false, filterable: false },
      data: {
        filterOptions: [],
      },
    }

    const headers = [
      {
        text: 'Name',
        value: 'projectName',
        meta: { dataType: DataType.Text, sortable: true },
      },
      {
        text: 'Part Cost ($)',
        value: 'partCost',
        meta: { dataType: DataType.Number, sortable: false },
      },
      {
        text: 'Parts Per Year',
        value: 'partsPerYear',
        meta: { dataType: DataType.Number, sortable: false },
      },
      {
        text: 'Material',
        value: 'material',
        headerKey: 'materialId',
        meta: { dataType: DataType.Text, sortable: false, filterable: true },
        data: {
          filterOptions: orderBy(
            this.getMaterials.map((material) => ({ name: material.name, value: material.id })),
            ['name'],
          ),
        },
      },
      {
        text: 'Machine',
        value: 'machineName',
        headerKey: 'machineConfigId',
        meta: { dataType: DataType.Text, sortable: false, filterable: true },
        data: {
          filterOptions: orderBy(
            this.getMachineConfigs.map((machineConfig) => ({ name: machineConfig.name, value: machineConfig.id })),
            ['name'],
          ),
        },
      },
      {
        text: 'Region',
        value: 'manufacturingRegion',
        headerKey: 'manufacturingRegion',
        meta: {
          dataType: DataType.Text,
          sortable: true,
          filterable: true,
        },
        data: {
          filterOptions: orderBy(
            this.getRegions.map((region) => ({ name: region, value: region })),
            ['name'],
          ),
        },
      },
      {
        text: 'Date Created',
        value: 'createdAt',
        meta: { dataType: DataType.Text, sortable: true },
      },
    ]

    return headers.map((header) => ({ ...defaultHeader, ...header }))
  }

  get isShownPaginationBar(): boolean {
    return this.getFilteredCostEstimationsCount > this.getPaginationLimit
  }

  get pageCount(): number {
    return this.getFilteredCostEstimationsCount
      ? Math.ceil(this.getFilteredCostEstimationsCount / this.getPaginationLimit)
      : 0
  }

  get stylesToPreventListShake() {
    // Styles to prevent list contents from shifting when a vertical scrollbar appears
    let styles = {}
    const padding = 4 // space between list and scrollbar
    if (this.listHasScroll) {
      styles = {
        paddingRight: `${padding}px`,
        marginRight: `-${this.scrollbarWidth + padding}px`,
      }
    }
    return styles
  }

  get scrollbarWidth() {
    const div = document.createElement('div')

    div.style.width = '100px'
    div.style.height = '100px'
    div.style.overflow = 'scroll'
    div.style.position = 'absolute'
    div.style.top = '-9999px'

    document.body.appendChild(div)

    const scrollbarWidth = div.offsetWidth - div.clientWidth

    document.body.removeChild(div)

    return scrollbarWidth
  }

  async beforeMount() {
    try {
      this.setSearchEstimationName()
      this.currentPage = this.getPageByPaginationOffset

      const materials = this.fetchMaterials()
      const params = this.fetchParameterSets()
      const machineConfigs = this.fetchMachineConfigs()
      const regions = this.fetchRegions()
      await Promise.all([materials, params, machineConfigs, regions])

      await this.setItems()

      this.setDefaultFilters()

      this.isDataLoaded = true
    } catch (error) {
      console.error(`Error while receiving Cost Estimations data: ${error}`)
    }
  }

  updated() {
    this.listHasScroll = this.$refs.list.offsetWidth > this.$refs.list.clientWidth
  }

  @Watch('getFilteredCostEstimations')
  onFilteredCostEstimationChange() {
    if (this.isDataLoaded) {
      this.setItems()
    }
  }

  @Watch('currentPage')
  async onCurrentPageChange() {
    const offset = (this.currentPage - 1) * this.getPaginationLimit
    this.setPaginationOffset(offset)
    await this.fetchFilteredCostEstimations()
  }

  async setItems() {
    const isNoAppliedFilters: boolean = Object.values(this.getFilterParams).every((filter) => !filter.length)

    this.items = []
    if (!isNoAppliedFilters) {
      this.items = await Promise.all(this.getFilteredCostEstimations.map((estimation) => this.createItem(estimation)))
    }

    if (this.getFilteredCostEstimationsCount <= this.getPaginationLimit) {
      this.currentPage = 1
    }
  }

  async createItem(estimation: ICostEstimation): Promise<ICostEstimationListItemViewModel> {
    const {
      id,
      projectName,
      materialId,
      numberOfPartsPerYear,
      manufacturingRegion,
      createdAt,
      imageUrl,
      costEstimationSet,
    } = estimation
    const { name: materialName = '' } = materialId ? await this.getMaterialById(materialId) : {}
    const result = []
    let isDisabled = false

    if (costEstimationSet) {
      costEstimationSet
        .filter((est) => est.result)
        .forEach((est) => {
          const machineConfig: IMachineConfig = this.getMachineConfigById(est.machineConfigId)
          const resultAdapter = CostEvaluationResultAdapterFactory.getAdapter(machineConfig.printingType)
          let estimationResult = null

          try {
            estimationResult = resultAdapter.adapt(est.result)
          } catch (error) {
            isDisabled = true
          }

          const min = estimationResult ? estimationResult.totalPrintProcessCostMin : 0
          const max = estimationResult ? estimationResult.totalPrintProcessCostMax : 0

          result.push({
            machineName: machineConfig.name,
            partCost: {
              min,
              max,
            },
          })
        })
    }

    return {
      id,
      projectName,
      materialName,
      numberOfPartsPerYear,
      manufacturingRegion,
      createdAt,
      imageUrl,
      result,
      isDisabled,
    }
  }

  onCostEstimationSelect(itemId) {
    const costEstimation = {
      name: RouterNames.CostEstimation,
      params: {
        costEstimationId: itemId,
      },
    }
    // @ts-ignore
    this.$router.safePush(costEstimation)
  }

  async onCostEstimationDelete(costEstimation) {
    const modalTitle = this.$i18n.t('costEstimationDeleteModalTitle')
    const modalMessage = `<span class="bold-font">${costEstimation.projectName}</span> ${this.$i18n.t('willBeDeleted')}`

    const shouldDelete = await this.$refs.confirm.open(modalTitle, modalMessage)

    if (shouldDelete) {
      await this.deleteEstimation(costEstimation.id)
    }
  }

  async deleteEstimation(estimationId) {
    try {
      await this.deleteCostEstimation(estimationId)
      this.fetchFilteredCostEstimations()
      this.refreshCurrentPage()
    } catch (error) {
      console.error(`Error while deleting Cost Estimations: ${error}`)
    }
  }

  onCostEstimationEdit(itemId) {
    const costEstimation = {
      name: RouterNames.EditCostEstimation,
      params: {
        costEstimationId: itemId,
      },
    }
    // @ts-ignore
    this.$router.safePush(costEstimation)
  }

  async onCostEstimationCopy(itemId) {
    try {
      await this.cloneCostEstimation(itemId)
      this.refreshCurrentPage()
    } catch (error) {
      console.error(`Error while cloning Cost Estimations: ${error}`)
    }
  }

  onSortUpdate(payload) {
    this.setSortParams(payload)
  }

  onFilterUpdate(payload) {
    this.setFilterParams(payload)
  }

  onClearAllFilters() {
    this.setDefaultFilters()
  }

  refreshCurrentPage() {
    this.currentPage = this.getPageByPaginationOffset
  }

  async changeSearchEstimationName(value) {
    this.setSearchEstimationName(value)
    this.currentPage = 1
    await this.fetchFilteredCostEstimations()
  }

  async onCreateNewClick() {
    try {
      const estimation: ICostEstimation = this.getDefaultCostEstimation

      const region = this.getDefaultRegion

      // Default material id should be equal null for better ui represetnation reasons
      estimation.materialId = null
      estimation.manufacturingRegion = region
      estimation.unitsId = DEFAULT_UNITS_ID

      const newEstimation = await this.createCostEstimation(estimation)
      const routerObj = {
        name: RouterNames.EditCostEstimation,
        params: {
          costEstimationId: newEstimation.id,
        },
      }
      // @ts-ignore
      this.$router.safePush(routerObj)
    } catch (error) {
      this.isShownErrorMsg = true

      console.error(`Error while creating Cost Estimation: ${error}`)
    }
  }

  private setDefaultFilters() {
    const payloads = [
      {
        field: 'materialId',
        value: this.getMaterials.map((material) => material.id),
      },
      {
        field: 'machineConfigId',
        value: this.getMachineConfigs.map((machineConfig) => machineConfig.id),
      },
      {
        field: 'manufacturingRegion',
        value: this.getRegions,
      },
    ]

    payloads.forEach((payload) => this.onFilterUpdate(payload))
  }
}
