
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import cloneDeep from 'lodash/cloneDeep'
import StoresNamespaces from '@/store/namespaces'
import {
  GeometryType,
  IBinderJetParameterSetContent,
  IBuildPlan,
  IBuildPlanItem,
  IPrintStrategyParameterSet,
  ISelectable,
  TransferPropsResponseDto,
  Visibility,
  IDisplayToolbarState,
  ProcessState,
} from '@/types/BuildPlans/IBuildPlan'
import { Watch } from 'vue-property-decorator'
import BuildPlanStickyComponent from '@/components/layout/buildPlans/stickyToPart/BuildPlanStickyComponent.vue'
import { BoundingBox2D } from '@/visualization/models/DataModel'
import { IConstraints } from '@/types/BuildPlans/IConstraints'
import { InstanceLabel } from '@/visualization/types/InstanceLabel'
import { handleAPIError } from '@/api/common'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { ToolNames } from '../BuildPlanSidebarTools'
import { PrintingTypes } from '@/types/IMachineConfig'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { getDefaultBaseOnType } from '@/utils/parameterSet/parameterSetUtils'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { TransferPropertiesCommand } from '@/types/UndoRedo/TransferPropertiesCommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { ICommand } from '@/types/UndoRedo/ICommand'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const commandManagerStore = namespace(StoresNamespaces.CommandManager)
const labelsStore = namespace(StoresNamespaces.Labels)

@Component({
  components: {
    BuildPlanStickyComponent,
  },
})
export default class BuildPlanTransferProps extends Vue {
  @visualizationStore.Getter getBoundingBox2D: BoundingBox2D
  @visualizationStore.Getter isShowHiddenPartsAsTransparentMode: boolean

  @visualizationStore.Mutation setSendBoundingAnchorPoints: (shouldSend: boolean) => void
  @visualizationStore.Mutation deselect: () => void
  @visualizationStore.Mutation selectAndHighlightParts: (payload: {
    buildPlanItemIds: string[]
    deselectIfSelected?: boolean
    showGizmo?: boolean
  }) => void
  @visualizationStore.Mutation setPartsVisibility: (payload: { ids: string[]; visibility: boolean }) => void
  @visualizationStore.Mutation getItemsBoundingBox2D: (itemIds: string[]) => void
  @visualizationStore.Mutation showGizmos: () => void
  @visualizationStore.Mutation applyTransformationMatrix: (payload: {
    buildPlanItemId: string
    transformation: number[]
    options?: {
      skipPositionX?: boolean
      skipPositionY?: boolean
      skipPositionZ?: boolean
      parameterSetScaleFactor?: number[]
    }
  }) => void
  @visualizationStore.Mutation clearOverhangMesh: (bpItemId: string) => void
  @visualizationStore.Mutation clearSupports: (payload: {
    buildPlanItemId: string
    overhangElementsToClear?: string[]
    skipGeomProps?: boolean
  }) => void
  @visualizationStore.Mutation transferSupports: (payload: { sourceId: string; targetIds: string[] }) => void
  @visualizationStore.Mutation labelInstance: (payload: InstanceLabel) => void
  @visualizationStore.Mutation deleteRenderedLabel: (labelId: string) => void
  @visualizationStore.Mutation refreshInsights: () => void

  @visualizationStore.Action setBpItemVisibility: (payload: {
    bpItem: IBuildPlanItem
    makeVisible: boolean
    showAsTransparent: boolean
  }) => void
  @visualizationStore.Action updateGeometriesOnTypeChange: (payload: {
    items: Array<{ bodyIds: string[]; buildPlanItemId: string }>
    geometryType: GeometryType
    visibility: boolean
  }) => void

  @visualizationStore.State cameraPosition: { x: number; y: number; z: number }

  @buildPlansStore.Action refreshLabelInsightsOnScene: () => void
  @buildPlansStore.Action updateConstraints: (payload: { buildPlanItemId: string; constraints: IConstraints }) => void
  @buildPlansStore.Action transferBuildPlanItemsProps: (payload: {
    sourceId: string
    targets: Array<{ id: string; transformationMatrix: number[] }>
  }) => Promise<TransferPropsResponseDto>

  @buildPlansStore.Getter getSelectedParts: ISelectable[]
  @buildPlansStore.Getter getAllBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getSelectedBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter insights: IBuildPlanInsight[]
  @buildPlansStore.Getter isSinterPlan: boolean
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getBuildPlanPrintStrategy: BuildPlanPrintStrategyDto
  @buildPlansStore.Getter parameterSets: IPrintStrategyParameterSet[]
  @buildPlansStore.Getter buildPlanItemById: (id: string) => IBuildPlanItem
  @buildPlansStore.Getter getCommandType: CommandType
  @buildPlansStore.Getter displayToolbarStateByVariantId: (buildPlanId: string) => IDisplayToolbarState

  @buildPlansStore.Mutation removeInsights: (insightIds: string[]) => void
  @buildPlansStore.Mutation setIsTransferToolBusy: (isBusy: boolean) => void

  @commandManagerStore.Mutation addCommand: (command: ICommand) => void

  @labelsStore.Action updateRelatedLabelsOnTransferProperties: (payload: {
    source: IBuildPlanItem
    targets: IBuildPlanItem[]
  }) => Promise<void>

  optionsWidgetTitle = 'transferPropsTool.optionsWidgetTitle'
  canvasOffset = { x: 0, y: 0 }
  isVisible = false
  isClosedFromTransferToAllInstances: boolean = false

  source: IBuildPlanItem = null
  targets: IBuildPlanItem[] = []
  differentParts: IBuildPlanItem[] = []

  created() {
    this.source = this.getSelectedBuildPlanItems[0]
    this.differentParts = this.getAllBuildPlanItems.filter((item) => item.part.id !== this.source.part.id)
    this.differentParts.push(this.source)
  }

  mounted() {
    this.toggleDifferentPartsVisibility(false)
    this.setSendBoundingAnchorPoints(true)
    this.getItemsBoundingBox2D([this.source.id])
    this.deselect()
  }

  @Watch('getBoundingBox2D')
  onBoundingBox2DChange(points: BoundingBox2D) {
    const { xMin, xMax, yMin, yMax } = points
    this.canvasOffset = { x: xMax - (xMax - xMin) / 2, y: yMax - (yMax - yMin) / 2 } // center of the part
    this.isVisible = true
  }

  @Watch('cameraPosition')
  onCameraPositionChanged() {
    this.getItemsBoundingBox2D([this.source.id])
  }

  @Watch('getSelectedBuildPlanItems')
  async onSelectedBuildPlanItemsChanged(newItems: IBuildPlanItem[]) {
    if (newItems.length === 1) {
      this.targets = [newItems[0]]
      await this.transferProps()
      this.closeTool()
    }
  }

  onAllInstancesClick() {
    this.targets = this.getAllBuildPlanItems.filter((item) => item.part.id === this.source.part.id)
    this.isClosedFromTransferToAllInstances = true
    this.transferProps()
  }

  onCancelClick() {
    this.closeTool()
  }

  onExitRequested(event?: Event) {
    this.closeTool()
  }

  beforeDestroy() {
    this.setSendBoundingAnchorPoints(false)
    this.toggleDifferentPartsVisibility(true)

    let itemIdsToSelect: string[] = []

    if (!this.targets.length) {
      itemIdsToSelect.push(this.source.id)
    } else if (!this.isClosedFromTransferToAllInstances) {
      itemIdsToSelect.push(this.targets[this.targets.length - 1].id)
    } else {
      itemIdsToSelect = [this.source.id, ...this.targets.map((target) => target.id)]
    }

    this.selectAndHighlightParts({
      buildPlanItemIds: itemIdsToSelect,
      showGizmo: true,
    })
  }

  private toggleDifferentPartsVisibility(visibility: boolean) {
    this.setPartsVisibility({
      visibility,
      ids: this.differentParts.map((item) => item.id),
    })
  }

  private async transferProps() {
    this.setIsTransferToolBusy(true)
    this.deselect()

    this.targets = this.targets.filter((item) => item.id !== this.source.id)

    if (!this.targets.length) {
      return
    }

    const buildPlanItemsBeforeTransfer = cloneDeep(this.targets)

    // create a map of bodies with the same part properties in order to prevent multiple updates
    const partPropertiesMap: Map<GeometryType, string[]> = new Map()
    this.source.partProperties.forEach((partProperty) => {
      const ids = partPropertiesMap.get(partProperty.type) || []
      partPropertiesMap.set(partProperty.type, [...ids, partProperty.geometryId])
    })

    // get scale factor
    let parameterSetScaleFactor
    const partProps = this.source.partProperties[0]
    if (!this.isSinterPlan && this.getBuildPlan.modality === PrintingTypes.BinderJet) {
      let printStrategyParameterSetPk: VersionablePk
      if (partProps.printStrategyParameterSetId) {
        printStrategyParameterSetPk = new VersionablePk(
          partProps.printStrategyParameterSetId,
          partProps.printStrategyParameterSetVersion,
        )
      } else {
        const defaults = this.getBuildPlanPrintStrategy.defaults
        printStrategyParameterSetPk = getDefaultBaseOnType(defaults, partProps.type, partProps.bodyType)
      }
      const bjPartParameters = this.parameterSets.find((p) => {
        return p.id === printStrategyParameterSetPk.id && p.version === printStrategyParameterSetPk.version
      }).parameterSet.partParameters as IBinderJetParameterSetContent

      // Do not pass scale paramenters if the item is Green
      if (partProps.processState !== ProcessState.Green) {
        parameterSetScaleFactor = [
          bjPartParameters.ScaleFactors.ScaleFactorX,
          bjPartParameters.ScaleFactors.ScaleFactorY,
          bjPartParameters.ScaleFactors.ScaleFactorZ,
        ]
      }
    }

    // Copy and apply transformation
    this.targets.forEach((target) => {
      // Transformation
      this.applyTransformationMatrix({
        buildPlanItemId: target.id,
        transformation: this.source.transformationMatrix,
        options: {
          parameterSetScaleFactor,
          skipPositionX: true,
          skipPositionY: true,
          skipPositionZ: !this.source.supports,
        },
      })

      // Constraints
      this.updateConstraints({
        buildPlanItemId: target.id,
        constraints: {
          translation: this.source.constraints.translation,
          rotation: this.source.constraints.rotation,
        },
      })

      // Part properties
      partPropertiesMap.forEach((bodyIds, geometryType) => {
        const items = [{ bodyIds, buildPlanItemId: target.id }]
        const geomTypeIsVisible = this.isGeometryTypeVisible(geometryType)
        this.updateGeometriesOnTypeChange({ items, geometryType, visibility: geomTypeIsVisible })
      })

      // Visibility
      this.setBpItemVisibility({
        bpItem: target,
        makeVisible: this.source.visibility === Visibility.Visible,
        showAsTransparent: this.isShowHiddenPartsAsTransparentMode,
      })
    })

    // Supports
    this.targets.forEach((target) => {
      this.clearOverhangMesh(target.id)
      this.clearSupports({ buildPlanItemId: target.id, skipGeomProps: true })
    })

    if (this.source.supports && this.source.supports.length) {
      this.transferSupports({
        sourceId: this.source.id,
        targetIds: this.targets.map((target) => target.id),
      })
    }

    try {
      const result: TransferPropsResponseDto = await this.transferBuildPlanItemsProps({
        sourceId: this.source.id,
        targets: this.targets.map((target) => ({
          id: target.id,
          transformationMatrix: this.buildPlanItemById(target.id).transformationMatrix, // Gets matrix from the state.
        })),
      })

      /**
       * !!!TEMPORARY SOLUTION!!! Due to fact that update buildPlan endpoint do not updates build plan items
       * we should take into account order of update calls because it causes a skip of build plan transformation matrix changes
       */
      // Labels
      // get bpItems with correct transformations
      const targetsForLabelsUpdate = this.targets.map((target) => this.buildPlanItemById(target.id))
      await this.updateRelatedLabelsOnTransferProperties({ source: this.source, targets: targetsForLabelsUpdate })

      // Delete existing target labels
      const oldLabelIds = this.targets
        .filter((target) => target.labels && target.labels.length > 0)
        .flatMap((target) => target.labels.map((label) => label.id))
      oldLabelIds.forEach((labelId) => this.deleteRenderedLabel(labelId))

      // Delete existing target label insights
      const oldLabelInsightIds = this.insights
        .filter((insight) => insight.tool === ToolNames.LABEL && oldLabelIds.includes(insight.details.labelId))
        .map((insight) => insight.id)
      this.removeInsights(oldLabelInsightIds)

      // Display new labels and insights
      result.buildPlanItems
        .filter((updatedTarget) => updatedTarget.labels && updatedTarget.labels.length > 0)
        .forEach((updatedTarget) => {
          updatedTarget.labels.forEach((label) => {
            const insights = this.insights.filter(
              (insight) => insight.tool === ToolNames.LABEL && insight.details.labelId === label.id,
            )
            this.labelInstance({
              insights,
              label,
              itemId: updatedTarget.id,
              transformation: updatedTarget.transformationMatrix,
            })
          })
        })

      this.refreshLabelInsightsOnScene()
      this.refreshInsights()

      this.addCommand(
        new TransferPropertiesCommand(
          buildPlanItemsBeforeTransfer,
          result.buildPlanItems,
          this.getCommandType,
          parameterSetScaleFactor,
          this.isShowHiddenPartsAsTransparentMode,
          this.insights,
          this.$store.dispatch,
          this.$store.commit,
        ),
      )

      this.setIsTransferToolBusy(false)
      this.closeTool()
    } catch (error) {
      handleAPIError(error)
      this.setIsTransferToolBusy(false)
    }
  }

  private closeTool() {
    this.$emit('closeTool')
  }

  private isGeometryTypeVisible(geometryType: GeometryType) {
    switch (geometryType) {
      case GeometryType.Production:
        return this.displayToolbarStateByVariantId(this.getBuildPlan.id).isShowingProductionGeometry
      case GeometryType.Support:
        return this.displayToolbarStateByVariantId(this.getBuildPlan.id).isShowingSupportGeometry
      case GeometryType.Coupon:
        return this.displayToolbarStateByVariantId(this.getBuildPlan.id).isShowingCouponGeometry
    }
  }
}
