
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import Button from '@/components/controls/Common/Button.vue'
import StoresNamespaces from '@/store/namespaces'
import { centerTruncate } from '@/utils/string'
import { SortOrders } from '@/types/SortModes'
import { getLabelFromPower, numberToPowerOf10 } from '@/utils/number'
import { compareProps } from '@/utils/array'

const visualizationStore = namespace(StoresNamespaces.Visualization)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

const EXPORT_LABEL_STRING_LENGTH = 70
const TO_REPLACE = /[/\\?:*<>|\"]/g
const PDF_FILE_EXTENSION = '.pdf'
const ESCAPE_KEY_CODE = 27
const A_KEY_CODE = 65
enum SortKeys {
  Name = 'label',
  Size = 'size',
}

@Component({ components: { Button } })
export default class ExportFileSelectModal extends Vue {
  @visualizationStore.Action exportCompensationFiles: Function
  @visualizationStore.Getter getResultsAvailable

  isVisible: boolean = false
  exportFileList: Array<{ label: string; selected: boolean; id: number; size: number; isPdfFile: boolean }> = []
  sortStatus = {
    key: SortKeys.Name,
    order: SortOrders.Ascending,
  }
  sortKeys = SortKeys

  open(buildPlanName: string, variantName: string) {
    const newData = []
    const bpName = centerTruncate(buildPlanName, EXPORT_LABEL_STRING_LENGTH).replaceAll(TO_REPLACE, '_')
    const vName = centerTruncate(variantName, EXPORT_LABEL_STRING_LENGTH).replaceAll(TO_REPLACE, '_')

    Object.keys(this.getResultsAvailable.exportData).forEach((k) => {
      const label = this.adjustExportLabel(this.getResultsAvailable.exportData[k].name, bpName, vName)
      const extension = label.substring(label.lastIndexOf('.'))
      const isPdfFile = extension === PDF_FILE_EXTENSION
      newData.push({ label, id: k, selected: false, size: this.getResultsAvailable.exportData[k].size, isPdfFile })
    })
    this.exportFileList = newData
    this.sort()
    this.isVisible = true
  }

  get exportDisabled() {
    return this.exportFileList.every((v) => !v.selected)
  }

  get exportLabel() {
    const selectedFilesSize = this.exportFileList
      .filter((file) => file.selected)
      .reduce((accSize, file) => accSize + file.size, 0)
    const sizeInfo = numberToPowerOf10(selectedFilesSize)
    const label = getLabelFromPower(sizeInfo.baseOf10)
    return this.$t('fileExportDialog.exportSizeLabel', {
      fileSize: sizeInfo.number,
      sizeLabel: label,
    })
  }

  getFileSizeLabel(size: number) {
    const sizeInfo = numberToPowerOf10(size)
    const label = getLabelFromPower(sizeInfo.baseOf10)
    return `${sizeInfo.number} ${label}`
  }

  handleKeyDown(event) {
    // Escape shortcut
    if (event.keyCode === ESCAPE_KEY_CODE) {
      this.close()
    }
    const { ctrlKey, metaKey } = event
    // Ctrl or Meta + A shortcut
    if (event.keyCode === A_KEY_CODE && (ctrlKey || metaKey)) {
      this.selectAll()
    }
  }

  selectAll() {
    this.exportFileList.forEach((file) => (file.selected = true))
  }

  deselectAll() {
    this.exportFileList.forEach((file) => (file.selected = false))
  }

  get masterSelectorState() {
    return this.exportFileList.reduce((acc, file) => {
      return acc === file.selected ? acc : null
    }, this.exportFileList[0].selected)
  }

  get isIndeterminate() {
    return this.masterSelectorState === null
  }

  onMasterSelectorChange() {
    if (this.masterSelectorState) {
      this.deselectAll()
    } else {
      this.selectAll()
    }
  }

  toggleFileSelection(file) {
    const selectedFile = this.exportFileList.find((item) => item.id === file.id)
    selectedFile.selected = !selectedFile.selected
  }

  sort() {
    this.exportFileList = this.exportFileList.sort((a, b) => {
      const fileA = a[this.sortStatus.key]
      const fileB = b[this.sortStatus.key]
      return compareProps(fileA, fileB, 0, [this.sortStatus.order])
    })
  }

  sortIcon(sortOrder: SortOrders) {
    return sortOrder === SortOrders.Ascending ? 'mdi-arrow-up' : 'mdi-arrow-down'
  }

  changeSortStatus(key: SortKeys) {
    let order
    if (key !== this.sortStatus.key) {
      order = SortOrders.Ascending
    } else {
      order = this.sortStatus.order === SortOrders.Ascending ? SortOrders.Descending : SortOrders.Ascending
    }
    this.sortStatus = {
      key,
      order,
    }
    this.sort()
  }

  isActiveSort(key: SortKeys) {
    return this.sortStatus.key === key
  }

  exportSelected() {
    const selectedForExport = {}
    this.exportFileList.forEach((v) => {
      if (v.selected) {
        selectedForExport[v.id] = v.label
      }
    })
    this.exportCompensationFiles(selectedForExport)
    this.close()
  }

  close() {
    this.isVisible = false
  }

  private adjustExportLabel(label: string, bpName: string, vName: string) {
    const lastDot = label.lastIndexOf('.')
    const name = label.substring(0, lastDot)
    const extension = label.substring(lastDot)
    return `${name}_${bpName}_${vName}${extension}`
  }
}
