import { Socket, io } from 'socket.io-client'
import { BrokerEvents } from '@/types/Common/BrokerEvents'
import { BrokerMessage } from '@/types/Common/BrokerMessage'
import store from '@/store'

type ISocketMessageSubscriptionMap = {
  [key in BrokerEvents]: Array<(msg) => void>
}

const defaultSubscription = {
  [BrokerEvents.PermissionChanged]: [],
  [BrokerEvents.CadHelperStarted]: [],
  [BrokerEvents.CostEstimationSent]: [],
  [BrokerEvents.PartSent]: [],
  [BrokerEvents.JobStatusFinalized]: [],
  [BrokerEvents.JobStatusChanged]: [],
  [BrokerEvents.NotificationCreated]: [],
  [BrokerEvents.NotificationDeleted]: [],
  [BrokerEvents.ArrangeApplied]: [],
  [BrokerEvents.ExposureJobCreated]: [],
  [BrokerEvents.InsightsCreated]: [],
  [BrokerEvents.PrintJobCreated]: [],
  [BrokerEvents.PrintJobCreationFailed]: [],
  [BrokerEvents.MeasurementsSent]: [],
}

export default class CommunicationService {
  public static getConnector(): CommunicationService {
    if (!this.connector) {
      this.connector = new CommunicationService()
    }

    return this.connector
  }

  private static connector: CommunicationService

  public subscriptions: ISocketMessageSubscriptionMap = defaultSubscription
  public socket: Socket = null
  public isDisconected = false

  private userId: string
  private userTenant: string
  private connectOptions = {
    rejectUnauthorized: false,
    agent: false,
    upgrade: false,
    transports: ['websocket', 'polling'],
  }

  private constructor() {
    this.onSocketConnectError = this.onSocketConnectError.bind(this)
  }

  public connect(userId: string, tenant: string, token: string) {
    const query = {
      query: {
        userId,
        token,
        tenant,
      },
    }
    const mergeOptions = { ...this.connectOptions, ...query }
    this.userId = userId
    this.userTenant = tenant

    this.socket = io(window.env.VUE_APP_API_URL, mergeOptions)

    this.attachEvents()
  }

  public subscribe(event: BrokerEvents, handler: (msg) => void) {
    this.subscriptions[event].push(handler)
  }

  public unsubscribe(event: BrokerEvents, handler: (msg) => void) {
    this.subscriptions[event] = this.subscriptions[event].filter((h) => h !== handler)
  }

  private trigger(message: BrokerMessage) {
    this.subscriptions[message.event].forEach((h) => h(message))
  }

  private unloadHandler() {
    if (this.socket) {
      this.socket.disconnect()
    }
  }

  private disconnectHandler() {
    store.commit('common/setIsDisconnected', true)
  }

  private attachEvents() {
    if (!this.socket) {
      return
    }

    this.socket.on('connect', () => {
      store.commit('common/setIsDisconnected', false)
      window.addEventListener('unload', this.unloadHandler.bind(this))
    })

    this.socket.on('custom_connect_error', this.onSocketConnectError)
    this.socket.on('connect_error', this.onSocketConnectError)

    this.socket.on('disconnect', (err) => {
      console.log('socket disconnect')
      console.log(err)
      this.disconnectHandler()
    })

    this.socket.on('message', (data: string) => {
      const parsedData = JSON.parse(data)
      this.trigger(parsedData)
    })
  }

  private onSocketConnectError(error) {
    console.log('socket error')
  }
}
