
import Component from 'vue-class-component'
import EditingParameterModal from '@/components/layout/admin/printStrategy/EditingParameterModal.vue'

@Component({
  components: { EditingParameterModal },
})
export default class ConfirmToolExitModal extends EditingParameterModal {
  get header() {
    return this.$t('labelTool.unsavedChangesHeader')
  }

  get body() {
    return this.$t('labelTool.unsavedChangesBody')
  }
}
