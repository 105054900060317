import { ActionTree } from 'vuex'
import { ICommonState } from './types'
import { IRootState } from '@/store/types'
import ILocale from '@/types/Common/ILocale'

import common from '@/api/common'

const locales: ILocale[] = [
  { id: 'en', title: 'en' },
  { id: 'de', title: 'de' },
  { id: 'es', title: 'es' },
]

export const actions: ActionTree<ICommonState, IRootState> = {
  fetchLocales({ commit }) {
    commit('setLocales', locales)
    commit('setSelectedLocale', locales[0])
  },

  applySelectedLocale({ commit }, selectedLocale: ILocale) {
    commit('setSelectedLocale', selectedLocale)
  },

  async fetchConnectedMachineConfigs({ commit }) {
    const machineConfigs = await common.getConnectedMachineConfigs()
    commit('setConnectedMachineConfigs', machineConfigs)
  },
}
