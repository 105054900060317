export enum BrokerEvents {
  CostEstimationSent,
  CadHelperStarted,
  PartSent,
  PermissionChanged,
  JobStatusFinalized,
  JobStatusChanged,
  NotificationCreated,
  NotificationDeleted,
  ArrangeApplied,
  ExposureJobCreated,
  InsightsCreated,
  PrintJobCreated,
  PrintJobCreationFailed,
  MeasurementsSent,
}
