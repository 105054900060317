import { PartParameterFormViewModel } from './PartParameterFormViewModel'
import { AmpUnit } from '@/types/Common/AmpUnit'
import { PrintStrategyMode } from '@/types/Sites/ParameterEditor'

const SCALE_FACTOR_MIN = 1.005
const SCALE_FACTOR_MAX = 1.5
export const SCALE_FACTOR_DEFAULT = 1.15
const GREEN_DENSITY_KG_PER_M3_MIN = 0.0
const POISON_RATIO_MIN = -0.5
const POISON_RATIO_MAX = 0.5
const YOUNGS_MODULUS_PA_MIN = 0
const COMPRESSIVE_YOUNGS_MODULUS_PA_MIN = 0
const TENSILE_MODULUS_PA_MIN = 0

export type PartParameterValidationRules = {
  [Property in keyof PartParameterFormViewModel]: object
}

const defaultValidationRules: PartParameterValidationRules = {
  sinterCycleName: { required: true },
  scaleFactorX: { required: true, between: { min: SCALE_FACTOR_MIN, max: SCALE_FACTOR_MAX } },
  scaleFactorY: { required: true, between: { min: SCALE_FACTOR_MIN, max: SCALE_FACTOR_MAX } },
  scaleFactorZ: { required: true, between: { min: SCALE_FACTOR_MIN, max: SCALE_FACTOR_MAX } },
  greenDensity: {
    required: false,
    great_than: {
      minValue: GREEN_DENSITY_KG_PER_M3_MIN,
      unit: AmpUnit.KilogramPerCubicMeter,
    },
  },
  sinteredDensity: {
    required: false,
    great_than: {
      minValue: '@greenDensity',
      unit: AmpUnit.KilogramPerCubicMeter,
    },
  },
  poissonRatio: {
    required: false,
    between: { min: POISON_RATIO_MIN, max: POISON_RATIO_MAX },
  },
  youngsModulus: { required: false, great_than: { minValue: YOUNGS_MODULUS_PA_MIN, unit: AmpUnit.Pascal } },
  compressiveYoungsModulus: {
    required: false,
    great_than: { minValue: COMPRESSIVE_YOUNGS_MODULUS_PA_MIN, unit: AmpUnit.Pascal },
  },
  tensileModulus: { required: false, great_than: { minValue: TENSILE_MODULUS_PA_MIN, unit: AmpUnit.Pascal } },
  calibrationName: { required: false },
  calibrationDate: { required: true },
  calibrationMachine: { required: false },
  powderLot: { required: false },
  binderLot: { required: false },
  sliceSettings: { required: true },
}

const customPrintStrategyValidationRules: Partial<PartParameterValidationRules> = {
  greenDensity: { ...defaultValidationRules.greenDensity, required: true },
  sinteredDensity: { ...defaultValidationRules.sinteredDensity, required: true },
  poissonRatio: { ...defaultValidationRules.poissonRatio, required: true },
  youngsModulus: { ...defaultValidationRules.youngsModulus, required: true },
  compressiveYoungsModulus: { ...defaultValidationRules.compressiveYoungsModulus, required: true },
}

const mapValidationRulesByPrintStrategyMode = new Map()
mapValidationRulesByPrintStrategyMode.set(PrintStrategyMode.Default, defaultValidationRules)
mapValidationRulesByPrintStrategyMode.set(PrintStrategyMode.Custom, customPrintStrategyValidationRules)

export class PartParameterFormValidation {
  public static getValidationRules(mode: PrintStrategyMode): PartParameterValidationRules {
    const rules = mapValidationRulesByPrintStrategyMode.get(mode)

    if (!rules) {
      throw new Error(`Unsupported print strategy mode: ${mode}`)
    }

    return { ...defaultValidationRules, ...rules }
  }
}
