import { RESIDUAL_ERROR_FIELD_PATTERN, DISPLACEMENT_MM_FIELD_PATTERN } from '@/constants'
import { SimulationColoringView } from '@/types/Simulation/SimulationColoringView'
import _ from 'lodash'

export class SimulationColoringModel {
  views: SimulationColoringView[]
  selectedView: SimulationColoringView

  constructor(views: SimulationColoringView[]) {
    this.views = views
    this.selectedView = this.views.length !== 0 ? this.views[0] : undefined
  }

  containsView(view: SimulationColoringView): boolean {
    if (!view) return false

    return this.views.find((v) => _.isEqual(v.name, view.name) && _.isEqual(v.modes, view.modes)) ? true : false
  }

  containsAllFieldsKeys(keys: string[]): boolean {
    if (!keys || this.views.length !== keys.length) return false

    for (const key of keys) {
      if (this.views.findIndex((view) => view.name === key) === -1) return false
    }

    return true
  }

  getDisplacementView(): SimulationColoringView {
    return this.views.find((v) => DISPLACEMENT_MM_FIELD_PATTERN.test(v.name))
  }

  getResidualErrorView(): SimulationColoringView {
    return this.views.find((v) => RESIDUAL_ERROR_FIELD_PATTERN.test(v.name))
  }
}
