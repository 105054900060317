
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Button from '@/components/controls/Common/Button.vue'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import StoresNamespaces from '@/store/namespaces'
import { getItemTypeName } from '../../../../utils/fileExplorerItem/fileExplorerItemUtils'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component({
  components: {
    Button,
  },
  methods: {
    getItemTypeName,
  },
})
export default class DeleteVariantModal extends Vue {
  @buildPlansStore.Getter isVariantProcessing: boolean

  @Prop() variant: IBuildPlan
  @Prop({ default: [] }) relatedItems: FileExplorerItem[]
  @Prop() deleteVariant: (variant: IBuildPlan) => Promise<void>

  isShownDialog: boolean = true

  async onDelete() {
    if (!this.deleteVariant) {
      return
    }

    await this.deleteVariant(this.variant)
    this.onClose()
  }

  onClose() {
    this.$emit('onClose')
  }
}
