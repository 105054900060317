
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import StoresNamespaces from '@/store/namespaces'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { ContextMenuItem } from '@/types/FileExplorer/ContextMenuItem'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({})
export default class ItemContextMenu extends Vue {
  @Prop() item: FileExplorerItem | PrintOrder
  @Prop() menuItems: ContextMenuItem[]
  @Prop() onMenuItemClick: (item: FileExplorerItem | PrintOrder, handler: Function) => void
  @Prop({ default: false }) autoHandleClick: boolean

  menuClick(handler) {
    if (this.autoHandleClick) {
      handler(this.item)
      return
    }
    this.onMenuItemClick(this.item, handler)
  }
}
