
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Menu from '@/components/controls/Common/Menu.vue'
import StoresNamespaces from '@/store/namespaces'
import { ParameterSetInherentStrainAvailability, PrintStrategyParameterSetDto } from '@/types/Sites/Site'
import i18n from '@/plugins/i18n'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component({
  components: {
    Menu,
  },
})
export default class SimulationReadyTooltip extends Vue {
  @sitesStore.Getter getPartParameterInherentStrainAvailabilities: ParameterSetInherentStrainAvailability[]

  @Prop({ type: Object, required: true }) parameterSet: PrintStrategyParameterSetDto

  @Prop({ required: true }) printStrategyParameterSetId: string | number
  @Prop({ type: Number, required: true }) printStrategyId: number

  notDefined = i18n.t('unknown') as string

  get paramInfo() {
    return this.parameterSet.dmlmData
  }

  get iconName(): string {
    return this.parameterSet.allowSimCom ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'
  }

  get iconColor(): string {
    return this.parameterSet.allowSimCom ? 'green' : '#D74E47'
  }

  get simulationReadyText() {
    return this.parameterSet.allowSimCom
      ? this.$i18n.t('tenantAdminMessages.printStrategy.partParameters.simulationReadyOn')
      : this.$i18n.t('tenantAdminMessages.printStrategy.partParameters.simulationReadyOff')
  }

  get laserPower() {
    return this.paramInfo && this.paramInfo.laserPower
      ? `${this.paramInfo.laserPower} ${i18n.t('wattAbbr')}`
      : this.notDefined
  }

  get beamDiameter() {
    return this.paramInfo && this.paramInfo.beamDiameter
      ? `${this.paramInfo.beamDiameter} ${i18n.t('micronAbbr')}`
      : this.notDefined
  }

  get hatchDistance() {
    return this.paramInfo && this.paramInfo.hatchDistance
      ? `${this.paramInfo.hatchDistance} ${i18n.t('millimeterAbbr')}`
      : this.notDefined
  }

  get laserSpeed() {
    return this.paramInfo && this.paramInfo.laserSpeed
      ? `${this.paramInfo.laserSpeed} ${i18n.t('millimeterPerSecAbbr')}`
      : this.notDefined
  }

  get layerThicknessAvailable() {
    const parameterInherentStrainAvailability = this.getPartParameterInherentStrainAvailabilities.find(
      (x) => x.id === this.parameterSet.parameterSetId && x.version === this.parameterSet.parameterSetVersion,
    )
    return parameterInherentStrainAvailability && parameterInherentStrainAvailability.available
  }

  get layerThicknessText() {
    return this.parameterSet.allowSimCom ? this.$i18n.t('compatible') : this.$i18n.t('incompatible')
  }
}
