
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Icon from '@/components/icons/Icon.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'

import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import {
  ItemPermissionsRole,
  ModalLabelType,
  Permission,
  PermissionCollaborator,
  PermissionCollaboratorViewModel,
  SimplePermission,
} from '@/types/FileExplorer/Permission'
import { IUser } from '@/types/User/IUser'
import { ItemAction } from '@/types/FileExplorer/ItemAction'
import StoresNamespaces from '@/store/namespaces'
import { PermissionCollaboratorViewModelMap } from '@/api/mappers/FileExplorerItemPermissionMap'

import messageService from '@/services/messageService'
import fileExplorerApi from '@/api/fileExplorer'
import UnshareUserMixin from '@/components/layout/FileExplorer/Table/mixins/UnshareUserMixin'
import { ItemDetailsPayload } from '@/store/modules/fileExplorer/types'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import { DetailsPanelViewMode } from '@/types/FileExplorer/ViewMode'
import Menu from '@/components/controls/Common/Menu.vue'
import { RouterNames } from '@/router'
import { ROOT_FOLDER_ID, DEFAULT_USER_PERMISSION_ROLE_FOR_RELATED_ITEM, DEFAULT_ITEM_VERSION } from '@/constants'
import { ItemType } from '@/types/FileExplorer/ItemType'
import { getAvailableRolesForShare } from '@/utils/user'
import { isFirstRoleHigher } from '@/utils/permissions/permissionsUtils'
import { getDefaultVariantIdFromVersionAndPath } from '@/utils/fileExplorerItem/fileExplorerItemUtils'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const userStore = namespace(StoresNamespaces.User)

interface IRelatedItemData {
  itemId: string
  role: ItemPermissionsRole
  permissionId: string
}

@Component({
  components: { Icon, ConfirmModal, Menu },
  filters: {
    formatRole(role: ItemPermissionsRole): string {
      return ItemPermissionsRole[role]
    },
  },
})
export default class DetailsManageCollaborators extends UnshareUserMixin {
  @userStore.Getter('getActiveUsersOrderedByLastName') orderedUsers: IUser[]

  @fileExplorerStore.Getter canManageCollaboration: boolean
  @fileExplorerStore.Getter('getCollaborators') collaborators: PermissionCollaborator[]
  @fileExplorerStore.Getter getCollaboratorsToRemoveList: PermissionCollaboratorViewModel[]
  @fileExplorerStore.Getter getRootItem: FileExplorerItem
  @fileExplorerStore.Getter getAmountOfNestedItemsWithoutPermissionsToView: number
  @fileExplorerStore.Getter getCollaboratorsGrantedParentPermissions: SimplePermission[]
  @fileExplorerStore.Getter getSelectedItem: FileExplorerItem
  @fileExplorerStore.Getter getUserDirectOrParentPermissions: (
    userId: string,
    path: string,
    excludePathIds?: string[],
  ) => Permission[]
  @fileExplorerStore.Getter find: (id: string) => FileExplorerItem
  @fileExplorerStore.Mutation deleteItem: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation updateItem: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation setPermissions: (permissions: Permission[]) => void
  @fileExplorerStore.Mutation unsetCollaborationData: () => void
  @fileExplorerStore.Mutation addCollaboratorToRemoveList: (collaborator: PermissionCollaboratorViewModel) => void
  @fileExplorerStore.Mutation unselectItem: (payload: { item: FileExplorerItem; selectionType: SelectionTypes }) => void
  @fileExplorerStore.Mutation setDetailsPanelMode: (mode: DetailsPanelViewMode) => void

  @fileExplorerStore.Action getCollaborationData: (payload: {
    itemId: string
    includeParentPermissions?: boolean
  }) => Promise<void>
  @fileExplorerStore.Action deletePermission: (payload: {
    permissionId: string
    fullDeletion?: boolean
  }) => Promise<void>
  @fileExplorerStore.Action setLastAction: (payload: { itemId: string; action: ItemAction }) => Promise<void>
  @fileExplorerStore.Action fetchItemDetails: (payload: ItemDetailsPayload) => Promise<void>
  @fileExplorerStore.Action fetchAllItemsInFolder: (folderId: string) => Promise<FileExplorerItem[]>
  @fileExplorerStore.Action fetchItemWithNoStateUpdate: (itemId: string) => Promise<FileExplorerItem>

  @Prop({ required: true }) item: FileExplorerItem
  @Prop({ default: false }) onBuildPlanPage: FileExplorerItem

  collaboratorList: PermissionCollaboratorViewModel[] = []
  selectedUser: IUser = null
  originalItemOwnerId: string = null
  isLoading = true
  isEndCollaboration = false

  mapUserIdToInitialRole = new Map<string, ItemPermissionsRole>()
  modalLabelType = ModalLabelType.Delete

  mapUserIdToInaccessibleItemsData = new Map<string, IRelatedItemData[]>()

  // all related parts and sinter plans (for sinter parts)
  relatedItemsCollaborators = new Map<string, PermissionCollaborator[]>()
  relatedItems: FileExplorerItem[] = []
  relatedPartNames: string[] = []
  isRelatedItemsLoading: boolean = false
  unnecessaryWaterfallPermissionIds = []

  $refs!: {
    confirm: InstanceType<typeof ConfirmModal>
  }

  async beforeMount() {
    this.originalItemOwnerId = (await fileExplorerApi.getOwnerIdForItems([this.item.id], true))[0].ownerId
  }

  destroyed() {
    this.unsetCollaborationData()
  }

  get activeUsers() {
    const selectedUserIds = this.collaboratorList.map((collaborator) => collaborator.grantedTo)
    return this.orderedUsers.filter(
      (user) =>
        !selectedUserIds.includes(user.id) && user.id !== this.userDetails.id && user.id !== this.originalItemOwnerId,
    )
  }

  get amountOfNestedItemsWithoutPermissionsToView(): number {
    const amountOfNotAvailableItems = this.relatedItems.filter((item) => item.hasNotAvailableRelatedItems).length

    return amountOfNotAvailableItems + this.getAmountOfNestedItemsWithoutPermissionsToView
  }

  getCollaboratorsDisplayName(user: IUser) {
    if (!user || !user.id) {
      return ''
    }

    if (!user.email) {
      return user.id
    }

    let displayName = user.firstName ? user.firstName : ''
    displayName = user.lastName ? `${displayName} ${user.lastName}` : displayName

    return displayName ? `${displayName} (${user.email})` : user.email
  }

  @Watch('item', { immediate: true })
  async watchItem(item) {
    if (!item) {
      return
    }

    this.originalItemOwnerId = (await fileExplorerApi.getOwnerIdForItems(item.id, true))[0].ownerId
    await this.getCollaborationData({ itemId: this.item.id, includeParentPermissions: true })
    this.createCollaboratorList()
    this.initPermissionsMap()
  }

  createCollaboratorList() {
    this.collaboratorList = this.collaborators
      .map((permission) => {
        const user = this.users.byId[permission.grantedTo]

        if (!user || user.isBlocked) {
          return null
        }

        const collaborator: PermissionCollaboratorViewModel = {
          id: permission.id,
          grantedTo: permission.grantedTo,
          displayName: this.getCollaboratorsDisplayName(user),
          role: permission.role,
          itemId: this.item.id,
          isEditable: permission.isEditable,
          isInherited: permission.isInherited,
          inheritedFrom: permission.inheritedFrom,
          hasChanged: false,
        }

        return collaborator
      })
      .filter((collaborator) => collaborator !== null)
  }

  initPermissionsMap() {
    this.collaborators.forEach((collaborator: PermissionCollaborator) => {
      this.mapUserIdToInitialRole.set(collaborator.grantedTo, collaborator.role)
    })
  }

  async initInaccessibleItemsMap() {
    this.isRelatedItemsLoading = true
    await this.fetchRelatedItemsCollaborators()
    this.collaboratorList.forEach((collaborator) => this.loadUserInaccessibleItems(collaborator.grantedTo))
    this.isRelatedItemsLoading = false
  }

  async fetchRelatedItemsCollaborators(itemId = this.item.id) {
    const allRelatedItems = this.canManageCollaboration ? await fileExplorerApi.getRelatedItemsForShare(itemId) : []
    let item
    if (itemId === this.item.id) {
      item = this.item
    } else {
      const stateItem = this.find(itemId)
      item = stateItem || (await this.fetchItemWithNoStateUpdate(itemId))
    }
    const defaultItemId = item.version === DEFAULT_ITEM_VERSION ? item.id : getDefaultVariantIdFromVersionAndPath(item)

    this.relatedPartNames = allRelatedItems.filter((i) => i.id !== defaultItemId && i.id !== itemId).map((i) => i.name)
    this.relatedItems = allRelatedItems.filter((i) => i.id !== defaultItemId)

    return Promise.all(
      this.relatedItems.map(async (i) => {
        const partPermissions = await fileExplorerApi.getItemPermissions(i.id)
        this.relatedItemsCollaborators.set(i.id, partPermissions.collaborators)
      }),
    )
  }

  loadUserInaccessibleItems(userId: string) {
    const inaccessibleItemsData: IRelatedItemData[] = []
    this.relatedItemsCollaborators.forEach((itemCollaborators, itemId) => {
      // get role for item collaborator and write if the collaborator is not a co-owner
      const collaboratorData = itemCollaborators.find((c) => c.grantedTo === userId)

      if (!collaboratorData || collaboratorData.role !== ItemPermissionsRole.CoOwner) {
        const role = collaboratorData ? collaboratorData.role : null
        const permissionId = collaboratorData ? collaboratorData.id : null
        inaccessibleItemsData.push({ itemId, role, permissionId })
      }

      if (inaccessibleItemsData.length > 0) {
        this.mapUserIdToInaccessibleItemsData.set(userId, inaccessibleItemsData)
      }
    })
  }

  getAvailableRoles(collaborator: PermissionCollaboratorViewModel) {
    const allRoles = getAvailableRolesForShare()
    if (collaborator.grantedTo === this.userDetails.id) {
      return [allRoles[collaborator.role]]
    }
    return Object.values(allRoles)
  }

  filterUsers(user: IUser, queryText: string) {
    if (!queryText) {
      return true
    }

    const trimLowerCaseQueryText = queryText.toLocaleLowerCase().trim()
    const userDisplayName = this.getCollaboratorsDisplayName(user).toLocaleLowerCase()

    return userDisplayName.indexOf(trimLowerCaseQueryText) > -1
  }

  async onChangeRole(collaborator: PermissionCollaboratorViewModel, newRole: ItemPermissionsRole) {
    if (collaborator.role === newRole) {
      return
    }

    if (collaborator.isInherited) {
      const isNewRolerHigher = isFirstRoleHigher(newRole, collaborator.role)
      const parentPermissionItem: FileExplorerItem = await this.fetchItemByIdWithoutAddingToState(
        collaborator.inheritedFrom,
      )

      if (isNewRolerHigher && collaborator.isInherited && parentPermissionItem.itemType === ItemType.Folder) {
        // create new permission according to the waterfall permissions workflow
        collaborator.id = null
        collaborator.role = newRole
        collaborator.itemId = this.item.id
        collaborator.isInherited = false
        collaborator.inheritedFrom = null
        collaborator.hasChanged = false
        collaborator.onlyWaterfall = true
        return
      }

      this.modalLabelType = ModalLabelType.ChangeInherited
      const shouldChangeParentRole = await this.shouldChangeParentPermission(
        collaborator.displayName,
        parentPermissionItem,
        this.$refs.confirm,
      )

      if (!shouldChangeParentRole) {
        const previousRole = collaborator.role
        delete collaborator.role
        collaborator.role = previousRole
        return
      }
    }

    collaborator.role = newRole

    if (collaborator.id === null) {
      collaborator.hasChanged = false
    } else {
      const initialRole = this.mapUserIdToInitialRole.get(collaborator.grantedTo)
      collaborator.hasChanged = initialRole === undefined ? false : newRole !== initialRole
    }

    if (collaborator.hasChanged && collaborator.id) {
      const parentPathIds = this.item.path.split('/').slice(1, -1)
      // find parent permissions with the same data
      const parentPermissions = this.getCollaboratorsGrantedParentPermissions.filter(
        (p) =>
          parentPathIds.includes(p.itemId) && p.role === collaborator.role && p.grantedTo === collaborator.grantedTo,
      )

      if (parentPermissions.length) {
        this.unnecessaryWaterfallPermissionIds.push(collaborator.id)
      }
    }
  }

  async onRemoveCollaborator(collaborator: PermissionCollaboratorViewModel, index: number) {
    this.mapUserIdToInaccessibleItemsData.delete(collaborator.grantedTo)
    const isNewCollaborator = collaborator.id === null

    if (isNewCollaborator) {
      this.collaboratorList = this.collaboratorList.filter((item) => item.grantedTo !== collaborator.grantedTo)
      return
    }

    const isCurrentUser = collaborator.grantedTo === this.userDetails.id
    this.modalLabelType = ModalLabelType.Delete
    const additionalUserParentPermissions = this.getUserDirectOrParentPermissions(
      collaborator.grantedTo,
      this.getSelectedItem.path,
      [this.getSelectedItem.id],
    )
    const hasAnotherParentPermissions = additionalUserParentPermissions.length > 0
    const shouldDelete = await this.shouldDeletePermission(
      collaborator.displayName,
      isCurrentUser,
      this.$refs.confirm,
      hasAnotherParentPermissions,
    )

    if (shouldDelete) {
      this.addCollaboratorToRemoveList(collaborator)
      this.collaboratorList = this.collaboratorList.filter((item) => item.id !== collaborator.id)
    }
  }

  async removeCollaborator(collaborator: PermissionCollaboratorViewModel) {
    const isCurrentUser = collaborator.grantedTo === this.userDetails.id

    if (collaborator.isInherited) {
      this.$refs.confirm.cancel()
      this.modalLabelType = ModalLabelType.DeleteInherited
      const shouldDelete = await this.shouldDeleteInheritedPermission(
        collaborator.displayName,
        collaborator.inheritedFrom,
        this.$refs.confirm,
      )

      if (!shouldDelete) {
        this.$refs.confirm.cancel()
        return
      }

      // update related items for parent item
      await this.fetchRelatedItemsCollaborators(collaborator.inheritedFrom)
    }

    await Promise.all(
      Array.from(this.relatedItemsCollaborators.values()).map((collaborators) => {
        const currentCollaborator = collaborators.find((c) => c.grantedTo === collaborator.grantedTo)
        if (currentCollaborator) {
          this.deletePermission({ permissionId: currentCollaborator.id })
        }
      }),
    )

    await this.deletePermission({ permissionId: collaborator.id, fullDeletion: true })
    await this.setLastAction({ itemId: this.item.id, action: ItemAction.Unshared })
    this.mapUserIdToInitialRole.delete(collaborator.grantedTo)

    if (isCurrentUser) {
      // End collaboration
      this.isEndCollaboration = true
      this.relatedItems.forEach((item) => this.deleteItem(item))
      this.deleteItem(this.item)
      this.unselectItem({ item: this.item, selectionType: SelectionTypes.Single })
      this.setDetailsPanelMode(DetailsPanelViewMode.Default)
      // Close preview and share views if current user ended collaboration for himself
      this.$emit('onClose', { closePreview: true, isEndCollaboration: this.isEndCollaboration })

      const isRootFolderRemoved = this.getRootItem && this.getRootItem.id === this.item.id && isCurrentUser
      if (isRootFolderRemoved) {
        // @ts-ignore
        this.$router.safePush({ name: RouterNames.FE_AllFiles, params: { itemId: ROOT_FOLDER_ID } })
      }
    }

    if (this.collaboratorList.length === 0 && !isCurrentUser) {
      this.updateItem({ ...this.item, isShared: false })
    }
  }

  onAddCollaborator(role: ItemPermissionsRole) {
    if (!this.selectedUser) {
      return
    }

    const collaborator: PermissionCollaboratorViewModel = {
      role,
      id: null,
      displayName: this.getCollaboratorsDisplayName(this.selectedUser),
      grantedTo: this.selectedUser.id,
      itemId: this.item.id,
      isEditable: true,
      isInherited: false,
      inheritedFrom: null,
      hasChanged: false,
    }

    this.collaboratorList.push(collaborator)

    this.selectedUser = null

    this.loadUserInaccessibleItems(collaborator.grantedTo)
  }

  async onApplyPermissions(message?: string) {
    if (!this.collaboratorList.length) {
      return
    }

    const collaboratorsToCreate = this.collaboratorList.filter((collaborator) => collaborator.id === null)
    const collaboratorsToUpdate = this.collaboratorList.filter(
      (collaborator) => collaborator.hasChanged && !this.unnecessaryWaterfallPermissionIds.includes(collaborator.id),
    )

    const collaboratorsWithInaccessibleItems = this.collaboratorList.filter((collaborator) => {
      if (collaborator.onlyWaterfall) {
        return false
      }

      const relatedItemsData = this.mapUserIdToInaccessibleItemsData.get(collaborator.grantedTo)
      if (!relatedItemsData) {
        return false
      }

      return relatedItemsData.some((data) => {
        if (!data.role) {
          return true
        }

        if (collaborator.role === ItemPermissionsRole.CoOwner) {
          return (data.role as ItemPermissionsRole) !== ItemPermissionsRole.CoOwner
        }
        return false
      })
    })

    const requestsDelete = this.unnecessaryWaterfallPermissionIds.map((id) => fileExplorerApi.deletePermission(id))
    await Promise.all(requestsDelete)

    if (!this.mapUserIdToInaccessibleItemsData.size && !collaboratorsToCreate.length && !collaboratorsToUpdate.length) {
      return
    }

    const requestsCreate = collaboratorsToCreate.map((permission) =>
      fileExplorerApi.createPermission(PermissionCollaboratorViewModelMap.toGrantPermissionDto(permission, message)),
    )
    const requestsUpdate = collaboratorsToUpdate.map((permission) =>
      fileExplorerApi.updatePermission(
        permission.id,
        PermissionCollaboratorViewModelMap.toUpdatePermissionDto(permission),
      ),
    )
    const requestsRelated = collaboratorsWithInaccessibleItems.flatMap((collaborator) =>
      this.mapUserIdToInaccessibleItemsData.get(collaborator.grantedTo).map((data) => {
        const role =
          collaborator.role === ItemPermissionsRole.CoOwner
            ? ItemPermissionsRole.CoOwner
            : DEFAULT_USER_PERMISSION_ROLE_FOR_RELATED_ITEM
        if (!data.role || !data.permissionId) {
          return fileExplorerApi.createPermission({
            message,
            role,
            userId: this.userDetails.id,
            itemId: data.itemId,
            targetUserId: collaborator.grantedTo,
          })
        }
        return fileExplorerApi.updatePermission(data.permissionId, { role })
      }),
    )

    try {
      const permissions = await Promise.all([...requestsCreate, ...requestsUpdate])
      const relatedPermissions = await Promise.all(requestsRelated)

      this.collaboratorList = this.collaboratorList.map((collaborator) => {
        const permission = permissions.find((p) => p.grantedTo === collaborator.grantedTo)
        return permission
          ? { ...collaborator, id: permission.id, role: permission.role, hasChanged: false }
          : collaborator
      })
      const relatedItemIds = [...new Set(relatedPermissions.map((permission) => permission.itemId))]
      await Promise.all(relatedItemIds.map((itemId) => this.setLastAction({ itemId, action: ItemAction.Shared })))

      if (!this.item.isShared && this.collaboratorList.length > 0) {
        this.updateItem({ ...this.item, isShared: true })
      }

      permissions.forEach((permission: Permission) => {
        this.mapUserIdToInitialRole.set(permission.grantedTo, permission.role)
      })
      this.setPermissions(permissions)
      if (!this.onBuildPlanPage) {
        await this.fetchItemDetails({ itemId: this.item.id, itemType: this.item.itemType })
      }
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  }
}
