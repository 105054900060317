
import Vue from 'vue'
import Component from 'vue-class-component'
import LabelToolTextField from '@/components/controls/LabelToolControls/LabelToolTextField.vue'
import LabelToolSelect from '@/components/controls/LabelToolControls/LabelToolSelect.vue'
import { Prop, Watch } from 'vue-property-decorator'
import { LabelTabStyleViewMode, LATIN_CHARACTERS_PATTERN } from '@/constants'
import { ILabel, ILabelStyle } from '@/types/Marking/ILabel'
import { extend, ValidationObserver } from 'vee-validate'
import TextField from '@/components/controls/Common/TextField.vue'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { v4 as uuidv4 } from 'uuid'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { PrintingTypes } from '@/types/IMachineConfig'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import { LabelToolPropertyCommand, LabelToolPropertyValue } from '@/types/UndoRedo/LabelToolPropertyCommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { difference } from '@/utils/common'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const commandManager = namespace(StoresNamespaces.CommandManager)

// NOTICE: the following enum is strongly joined with refs that defined inside the following component.
enum FIELDS_NAMES {
  LABEL_TEXT = 'labelText',
  FONT_SIZE = 'fontSize',
  EMBOSSED_HEIGHT = 'embossedHeight',
  SELECTED_FONT = 'selectedFont',
}

@Component({
  components: { TextField, LabelToolTextField, LabelToolSelect, ConfirmModal },
})
export default class UpsertMarkingLabel extends Vue {
  @buildPlansStore.Getter getAllBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getAllBuildPlanMarkLabels: ILabel[]
  @buildPlansStore.Getter getBuildPlanItemByLabelId: Function
  @buildPlansStore.Getter insights: IBuildPlanInsight[]
  @buildPlansStore.Getter printingType: string
  @buildPlansStore.Getter getCommandType: CommandType

  @buildPlansStore.Mutation setIsLabelInstancing: Function
  @buildPlansStore.Mutation setSelectedLabel: Function

  @buildPlansStore.Action addBuildPlanItemLabel: Function
  @buildPlansStore.Action copyBuildPlanItemLabel: Function
  @buildPlansStore.Action updateBuildPlanItemLabel: Function
  @buildPlansStore.Action deleteBuildPlanItemLabel: Function

  @visualizationStore.Getter isLabelCreationMode: boolean
  @visualizationStore.Getter getVisualizationLoading: boolean
  @visualizationStore.Mutation setLabelStyle: Function
  @visualizationStore.Mutation updateLabelAppearance: Function
  @visualizationStore.Mutation setIsLoading: Function
  @visualizationStore.Mutation activateLabelCreation: Function
  @visualizationStore.Mutation deactivateLabelCreation: Function
  @visualizationStore.Mutation activateLabelInteraction: Function
  @visualizationStore.Mutation deactivateLabelInteraction: Function
  @visualizationStore.Mutation deleteRenderedLabel: Function
  @visualizationStore.Mutation toggleComponentHighlight: Function

  @commandManager.Mutation addCommand: (command: ICommand) => void
  @commandManager.Mutation bindLabelToolInstanceContextWithUndoableCommand: (payload: {
    context: UpsertMarkingLabel
  }) => void

  @Prop() mode: LabelTabStyleViewMode
  @Prop() labelToUpdate!: ILabel
  @Prop() hasInsight!: boolean

  $refs!: {
    form: InstanceType<typeof ValidationObserver>
    confirm: InstanceType<typeof ConfirmModal>
    labelText: InstanceType<typeof LabelToolTextField>
    fontSize: InstanceType<typeof LabelToolTextField>
    embossedHeight: InstanceType<typeof LabelToolTextField>
    selectedFont: InstanceType<typeof LabelToolSelect>
  }

  labelText: string = null
  fontSize: number = null
  embossedHeight: number = null
  selectedFont: string = null

  isSelectedPartSurface: boolean = false
  isLabelUpToDate: boolean = true

  fontNames: string[] = ['Varela Round', 'Linotte']

  initialLabel: ILabelStyle = null
  forceUndoableCommandCreation: boolean = true
  isMounted: boolean = false

  beforeMount() {
    this.extendValidationRules()

    if (this.isCreateMode) {
      this.labelText = `Label ${this.getAllBuildPlanMarkLabels.length + 1}`
      this.selectedFont = this.fontNames[0]
      this.fontSize = 4
      this.embossedHeight = 0.5
      this.setLabelStyle({
        text: this.labelText,
        fontFamily: this.selectedFont,
        fontSize: this.fontSize,
        textExtrusionHeight: this.embossedHeight,
      })
      this.setSelectedLabel({})
      this.activateLabelCreation()
    } else {
      this.labelText = this.labelToUpdate.style.text
      this.selectedFont = this.labelToUpdate.style.fontFamily
      this.fontSize = this.labelToUpdate.style.fontSize
      this.embossedHeight = this.labelToUpdate.style.textExtrusionHeight
      this.setSelectedLabel(this.labelToUpdate)
      this.activateLabelInteraction(this.labelToUpdate.id)
    }
  }

  mounted() {
    this.initialLabel = {
      text: this.labelText,
      fontFamily: this.selectedFont,
      fontSize: this.fontSize,
      textExtrusionHeight: this.embossedHeight,
    }

    this.isMounted = true

    if (this.isEditMode) {
      // Bind 'this' context with current mounted component
      // and Undo/Redo tool stack for the 'LabelToolPropertyCommand' type commands.
      this.bindLabelToolInstanceContextWithUndoableCommand({
        context: this,
      })
    }
  }

  @Watch('initialLabel', { deep: true })
  onInitialLabelChanged(newValue: ILabelStyle, oldValue: ILabelStyle) {
    if (!this.forceUndoableCommandCreation) {
      return
    }

    if (!oldValue) {
      return
    }

    const nextPropertyValue = difference(newValue, oldValue)
    const previousPropertyValue = difference(oldValue, newValue)
    const [changedPropertyName] = Object.getOwnPropertyNames(nextPropertyValue)

    this.addCommand(
      new LabelToolPropertyCommand(
        this.getCommandType,
        changedPropertyName,
        previousPropertyValue[changedPropertyName],
        nextPropertyValue[changedPropertyName],
        this,
        this.labelToUpdate,
      ),
    )
  }

  beforeDestroy() {
    if (this.isCreateMode) {
      if (!this.isLabelCreationMode) {
        return
      }

      this.deactivateLabelCreation()
    } else {
      const isExists = this.getAllBuildPlanMarkLabels.some((l) => l.id === this.labelToUpdate.id)
      if (isExists) {
        this.deactivateLabelInteraction(this.labelToUpdate.id)
      }

      this.setSelectedLabel(null)
    }

    this.isMounted = false
  }

  get labelDepthInput(): string {
    if (this.printingType === PrintingTypes.DMLM) {
      return this.$t('labelTool.labelDepthInput.DMLM').toString()
    }
    if (this.printingType === PrintingTypes.BinderJet) {
      return this.$t('labelTool.labelDepthInput.BinderJet').toString()
    }

    return this.$t('labelTool.labelDepthInput.default').toString()
  }

  get isCreateMode(): boolean {
    return this.mode === LabelTabStyleViewMode.Create
  }

  get isEditMode(): boolean {
    return this.mode === LabelTabStyleViewMode.Edit
  }

  get labelTextValidationRules() {
    return {
      nativeRules: {
        required: true,
        max: 50,
        onlyLatinCharacters: true,
      },
      customMessages: {
        required: this.$t('labelTool.validationMessages.requiredLabelName'),
      },
    }
  }

  get fontSizeValidationRules() {
    return {
      nativeRules: {
        required: true,
        min_value: 1.0,
        max_value: 50.0,
      },
      customMessages: {
        min_value: this.$t('labelTool.validationMessages.validMinMaxFontSize'),
        max_value: this.$t('labelTool.validationMessages.validMinMaxFontSize'),
      },
    }
  }

  get embossedHeightValidationRules() {
    return {
      nativeRules: {
        required: true,
        min_value: 0.25,
        max_value: 2.5,
      },
      customMessages: {
        min_value: this.$t('labelTool.validationMessages.validMinMaxEmbossedHeight'),
        max_value: this.$t('labelTool.validationMessages.validMinMaxEmbossedHeight'),
      },
    }
  }

  get isLabelAllInstancesActive() {
    if (this.isCreateMode || this.getVisualizationLoading || !this.isLabelUpToDate) {
      return false
    }

    const buildPlanItems = this.getAllBuildPlanItems
    const bpItemWithLabel = buildPlanItems.find(
      (bpItem) => bpItem.labels && bpItem.labels.find((l) => l.id === this.labelToUpdate.id),
    )

    return buildPlanItems.some(
      (bpItem) => bpItem.id !== bpItemWithLabel.id && bpItem.part.id === bpItemWithLabel.part.id,
    )
  }

  get isLabelSynchronized() {
    if (this.isCreateMode) {
      return false
    }

    return (
      this.labelToUpdate.style.text === this.labelText &&
      this.labelToUpdate.style.fontFamily === this.selectedFont &&
      this.labelToUpdate.style.fontSize === this.fontSize &&
      this.labelToUpdate.style.textExtrusionHeight === this.embossedHeight
    )
  }

  get isLabelTextFieldDisabled() {
    return !this.shouldDisableExcept(FIELDS_NAMES.LABEL_TEXT)
  }

  get isFontSizeFieldDisabled() {
    return !this.shouldDisableExcept(FIELDS_NAMES.FONT_SIZE)
  }

  get isEmbossedHeightFieldDisabled() {
    return !this.shouldDisableExcept(FIELDS_NAMES.EMBOSSED_HEIGHT)
  }

  get isSelectedFontFieldDisabled() {
    return !this.shouldDisableExcept(FIELDS_NAMES.SELECTED_FONT)
  }

  @Watch('labelText')
  @Watch('selectedFont')
  @Watch('fontSize')
  @Watch('embossedHeight')
  onLabelStyleChanged() {
    this.isLabelUpToDate = this.isLabelSynchronized
  }

  async readyForm() {
    return await this.$refs.form.validate()
  }

  async updateLabelOnCanvas() {
    if (this.isCreateMode) {
      const labelStyle = {
        text: this.labelText,
        fontFamily: this.selectedFont,
        fontSize: this.fontSize,
        textExtrusionHeight: this.embossedHeight,
      }
      this.setLabelStyle(labelStyle)
    } else {
      setTimeout(() => this.updateLabelStyle(), 0)
    }
  }

  async updateLabelStyle() {
    this.$nextTick(async () => {
      if (this.isLabelSynchronized) {
        return
      }

      const isValid = await this.readyForm()
      if (!isValid) {
        return
      }

      const labelStyle = {
        text: this.labelText,
        fontFamily: this.selectedFont,
        fontSize: this.fontSize,
        textExtrusionHeight: this.embossedHeight,
      }
      if (this.isCreateMode) {
        this.setLabelStyle(labelStyle)
        return
      }

      if (this.isEditMode && this.labelToUpdate) {
        this.updateLabelAppearance({
          id: this.labelToUpdate.id,
          style: labelStyle,
        })
      }

      if (this.forceUndoableCommandCreation) {
        this.initialLabel = labelStyle
      }

      this.forceUndoableCommandCreation = true
      this.isLabelUpToDate = true
    })
  }

  componentHighlight(showHighlight: boolean) {
    if (this.isCreateMode || !this.labelToUpdate) {
      return
    }

    const bpItem = this.getBuildPlanItemByLabelId(this.labelToUpdate.id)
    this.toggleComponentHighlight({
      showHighlight,
      componentId: this.labelToUpdate.componentId,
      excludedBPItems: [bpItem.id],
    })
  }

  async labelAllInstances() {
    this.setIsLoading(true)
    this.setIsLabelInstancing(true)
    const buildPlanItems = this.getAllBuildPlanItems
    const bpItemWithLabel = buildPlanItems.find(
      (bpItem) => bpItem.labels && bpItem.labels.find((l) => l.id === this.labelToUpdate.id),
    )
    for (const bpItem of buildPlanItems) {
      if (!(bpItem.id !== bpItemWithLabel.id && bpItem.part.id === bpItemWithLabel.part.id)) {
        continue
      }

      let labelConfig
      if (
        bpItem.labels &&
        bpItem.labels.length &&
        bpItem.labels.some((l) => l.componentId === this.labelToUpdate.componentId)
      ) {
        const label = bpItem.labels.find((l) => l.componentId === this.labelToUpdate.componentId)
        labelConfig = this.buildLabelConfig(
          bpItem.id,
          label.id,
          this.labelToUpdate.s3FileName,
          label.fileKey,
          label.createdAt,
        )
      } else {
        labelConfig = this.buildLabelConfig(bpItem.id, uuidv4(), this.labelToUpdate.s3FileName, undefined, new Date())
      }

      await this.copyBuildPlanItemLabel(labelConfig)
    }
    this.setIsLabelInstancing(false)
    this.componentHighlight(false)
    this.setIsLoading(false)
  }

  async deleteLabel() {
    // Collapse add panel
    if (this.isCreateMode) {
      this.setSelectedLabel(null)
      return
    }

    const deleteMessage = this.$t('labelTool.deleteDialog.message', { value: this.labelToUpdate.style.text })
    const confirmed = await this.$refs.confirm.open(this.$t('labelTool.deleteDialog.title'), deleteMessage)
    if (!confirmed) {
      return
    }

    this.setIsLoading(true)
    const buildPlanItemId = this.getBuildPlanItemByLabelId(this.labelToUpdate.id).id
    this.deactivateLabelInteraction(this.labelToUpdate.id)

    await this.deleteBuildPlanItemLabel({
      buildPlanItemId,
      label: this.labelToUpdate,
    })
    this.setIsLoading(false)
  }

  async onUpdateLabelStyleValueByPropertyName(
    propertyName: string,
    propertyValue: LabelToolPropertyValue,
    labelToUpdate: ILabel,
    forceUndoableCommandCreation: boolean = true,
  ): Promise<void> {
    this.forceUndoableCommandCreation = forceUndoableCommandCreation

    if (this.isMounted && this.labelToUpdate.id === labelToUpdate.id) {
      this.setStyleValueByPropertyName(propertyName, propertyValue)
      await this.updateLabelStyle()
      return
    }

    await this.updateLabelStyleWhenComponentIsUnmounted(labelToUpdate, propertyName, propertyValue)
  }

  extendValidationRules() {
    extend('onlyLatinCharacters', {
      validate: (value) => {
        return new RegExp(LATIN_CHARACTERS_PATTERN).test(value)
      },
      message: this.$t('latinCharactersMismatch').toString(),
    })
  }

  shouldDisableExcept(exceptedField: string): boolean {
    return this.isMounted && this.$refs[exceptedField].isFocused
  }

  private buildLabelConfig(bpItemId: string, id: string, s3FileName: string, fileKey: string, createdAt: Date) {
    // Create new label
    const newLabel = JSON.parse(JSON.stringify(this.labelToUpdate))
    newLabel.id = id
    newLabel.s3FileName = s3FileName
    newLabel.fileKey = fileKey
    newLabel.createdAt = createdAt
    newLabel.index = undefined
    newLabel.style.text = this.labelText
    newLabel.style.fontFamily = this.selectedFont
    newLabel.style.fontSize = this.fontSize
    newLabel.style.textExtrusionHeight = this.embossedHeight
    // Create new label insights
    const insights = this.insights.filter(
      (i) => i.tool === ToolNames.LABEL && i.details.labelId === this.labelToUpdate.id,
    )
    const newInsights = JSON.parse(JSON.stringify(insights))
    newInsights.map((insight) => (insight.details.labelId = id))

    return {
      buildPlanItemId: bpItemId,
      label: newLabel,
      needToLoadItemLabel: true,
      insights: newInsights,
    }
  }

  private async updateLabelStyleWhenComponentIsUnmounted(
    labelToUpdate: ILabel,
    propertyName: string,
    propertyValue: LabelToolPropertyValue,
  ): Promise<void> {
    const labelStyle = {
      text: labelToUpdate.style.text,
      fontFamily: labelToUpdate.style.fontFamily,
      fontSize: labelToUpdate.style.fontSize,
      textExtrusionHeight: labelToUpdate.style.textExtrusionHeight,
    }

    labelStyle[propertyName] = propertyValue

    this.updateLabelAppearance({
      id: labelToUpdate.id,
      style: labelStyle,
      shouldLabelBeSelected: false,
    })

    if (this.forceUndoableCommandCreation) {
      this.initialLabel = labelStyle
    }
    this.forceUndoableCommandCreation = true
    this.isLabelUpToDate = true
  }

  private setStyleValueByPropertyName(propertyName: string, propertyValue: LabelToolPropertyValue) {
    switch (propertyName) {
      case 'text':
        this.labelText = propertyValue as string
        break
      case 'fontFamily':
        this.selectedFont = propertyValue as string
        break
      case 'fontSize':
        this.fontSize = propertyValue as number
        break
      case 'textExtrusionHeight':
        this.embossedHeight = propertyValue as number
        break
      default:
        throw new Error(`Unknown property name ${propertyName} to assign value ${propertyValue}`)
    }
  }
}
