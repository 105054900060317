
import '@/assets/styles/home.scss'
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { RouterPaths } from '@/router'
import SsoMigrationModal from '@/components/modals/SsoMigrationModal.vue'
import StoresNamespaces from '@/store/namespaces'
import { IUser } from '@/types/User/IUser'

const userStore = namespace(StoresNamespaces.User)

@Component({
  components: {
    SsoMigrationModal,
  },
})
export default class SsoMigration extends Vue {
  @userStore.Getter getUserDetails: IUser

  $refs!: {
    ssoMigrationModal: InstanceType<typeof SsoMigrationModal>
  }

  async mounted() {
    const user = this.getUserDetails
    if (user && user.oldUserId) {
      // Open migration modal
      await this.$refs.ssoMigrationModal.open({ force: true })
    }

    // @ts-ignore
    this.$router.safePush(RouterPaths.DefaultFileExplorer)
  }
}
