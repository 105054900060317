
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'

import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import { ItemUsersRoles } from '@/types/FileExplorer/ItemUsersRoles'
import { UserRole } from '@/types/User/UserRole'
import StoresNamespaces from '@/store/namespaces'
import { getItemTypeName } from '@/utils/fileExplorerItem/fileExplorerItemUtils'
import { getRoleLabel } from '@/utils/user'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'

const userStore = namespace(StoresNamespaces.User)

@Component({
  components: {
    ConfirmModal,
  },
})
export default class ImplicitShareModal extends Vue {
  @userStore.Getter lookupFullNameById: (userId: string) => string

  $refs!: {
    confirm: InstanceType<typeof ConfirmModal>
  }

  open(itemUserRoles: ItemUsersRoles[], destinationFolder: FileExplorerItem, byMoveAction = false): Promise<boolean> {
    const messagesBody = itemUserRoles
      .map((x) => {
        let messagesAsHtml: string
        if (x.userRoles.length === 1) {
          const userRole: UserRole = x.userRoles.pop()
          const userName = this.lookupFullNameById(userRole.userId)
          messagesAsHtml = `${userName} ${userRole.role === 2 ? 'a' : 'an'} ${getRoleLabel(userRole.role)}`
        } else {
          messagesAsHtml = `<ul>${x.userRoles
            .map((z) => {
              const userName = this.lookupFullNameById(z.userId)
              const roleName = getRoleLabel(z.role)
              const startsWithVowelRegex = '^[aieouAIEOU].*'
              return `<li>${userName} ${roleName.match(startsWithVowelRegex) ? 'an' : 'a'} ${roleName}</li>`
            })
            .join('')}</ul>`
        }
        return `<div>${byMoveAction ? 'Moving' : 'Copying'} ${getItemTypeName(x.item)} "${x.item.name}" to "${
          destinationFolder.name
        }" will make
        ${messagesAsHtml}
        </div>`
      })
      .join('')
    const message = `<div class="text t-14">
      ${messagesBody}
    </div>
    <div class="text t-14">Are you sure you want to continue?</div>`
    return this.$refs.confirm.open('Share', message)
  }

  confirm() {
    this.$refs.confirm.confirm()
  }

  cancel() {
    this.$refs.confirm.cancel()
  }
}
