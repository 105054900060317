
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Button from '@/components/controls/Common/Button.vue'
import TextField from '@/components/controls/Common/TextField.vue'
import StoresNamespaces from '@/store/namespaces'
import { ValidationObserver, extend } from 'vee-validate'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const NAME_MAX_LENGTH = 255

@Component({
  components: {
    TextField,
    Button,
  },
})
export default class RenameVariantModal extends Vue {
  @Prop({ default: '' }) variantLabel: string
  @Prop() renameVariant: (value: string) => Promise<void>
  @buildPlansStore.Getter isVariantProcessing: boolean

  $refs!: {
    nameTextField: TextField
    form: InstanceType<typeof ValidationObserver>
  }

  isShownDialog: boolean = true
  label: string

  beforeMount() {
    this.label = this.variantLabel
    this.extendValidationRules()
  }

  mounted() {
    this.setFocus()
    this.$refs.nameTextField.selectAll()
  }

  async onRename() {
    if (!this.renameVariant) {
      return
    }

    const isValid = await this.$refs.form.validate()
    if (!isValid) {
      return
    }

    await this.renameVariant(this.label)
    this.onClose()
  }

  onClose() {
    this.$emit('onClose')
  }

  setFocus() {
    setTimeout(() => {
      if (this.$refs.nameTextField) {
        this.$refs.nameTextField.focus()
      }
    }, 0)
  }

  extendValidationRules() {
    extend('compact', {
      validate: (value: string) => {
        return value.length <= NAME_MAX_LENGTH
      },
      message: this.$i18n.t('invalidNameLength') as string,
    })
  }

  getNameRules() {
    return {
      required: true,
      compact: true,
    }
  }
}
