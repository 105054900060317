
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import ProfileMenu from './ProfileMenu.vue'
import Button from '@/components/controls/Common/Button.vue'
import Selector from '@/components/controls/Common/Selector.vue'
import NotificationsMenu from '@/components/layout/notifications/NotificationsMenu.vue'
import { isAuthorized } from '@/utils/user'
import { AMP_CT_COOKIE, Permission } from '@/constants'
import { Mixins, Watch } from 'vue-property-decorator'
import { IUser } from '@/types/User/IUser'
import VSelect from 'vuetify/lib/components/VSelect'
import { ClickOutsideMixin } from '@/components/layout/mixins/ClickOutsideMixin'
import { isClickedInside } from '@/utils/clickOutside/clickOutside'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import { IMaterial } from '@/types/IMaterial'
import { IMachineConfig } from '@/types/IMachineConfig'
import Vue from 'vue'
import { get } from '@/api/http'
import { EndpointsUrls } from '@/configs/config'

import json from '../../../build-info.json'
import { RouterNames } from '@/router'
import { VersionablePk } from '@/types/Common/VersionablePk'
import {
  ActiveToolUnsavedChangesMixin,
  ExitToolAction,
} from '@/components/layout/buildPlans/mixins/ActiveToolUnsavedChangesMixin'

const userStore = namespace(StoresNamespaces.User)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

// Literal values ​​match component parent styles
enum ActiveMenu {
  None = 'none',
  Tenant = 'tenant-selector',
  Notifications = 'notifications',
  NotificationsWithTypeSelector = 'notifications-with-opened-type-selector',
  Profile = 'profile',
}

enum ActiveMenuParentStyles {
  DefaultVMenu = 'v-menu__content', // default v-menu vuetify style
  NotificationsList = 'notifications-list',
  UserProfile = 'user-profile',
}

enum ActiveMenuStyles {
  NotificationsMenu = 'notifications-menu',
  UserProfileMenu = 'user-profile-menu',
}

@Component({
  components: {
    NotificationsMenu,
    ProfileMenu,
    Button,
    Selector,
  },
})
export default class Login extends Mixins(ClickOutsideMixin, ActiveToolUnsavedChangesMixin) {
  @userStore.Getter('getUserDetails') getUserDetails: IUser
  @userStore.Getter('isAuthenticating') isAuthenticating: any
  @userStore.Getter('getAuth0Client') auth0Client: any

  @buildPlansStore.State buildPlan: IBuildPlan
  @buildPlansStore.Getter getSelectedMachineConfigPk: VersionablePk | null
  @buildPlansStore.Getter getMachineConfigByPk: (pk: VersionablePk) => IMachineConfig
  @buildPlansStore.Getter getMaterialByPk: (pk: VersionablePk) => IMaterial

  $refs!: {
    notificationsMenu: InstanceType<typeof NotificationsMenu>
    profileMenu: InstanceType<typeof ProfileMenu>
    selector: VSelect
  }

  notificationsVisible = false
  isLoaded: boolean = false

  userbackToken = window.env.VUE_APP_USERBACK_TOKEN || ''

  ampVersion = window.env.VUE_APP_AMP_VERSION
  uiVersion = `${json.PRODUCT_VERSION}_${json.BUILD_ID} ${json.BUILD_DATE}`
  apiVersion = null
  currentTenant = null

  get activeMenu() {
    if (!this.isLoaded) {
      return ActiveMenu.None
    }

    if (this.$refs.selector && this.$refs.selector.$refs.menu && this.$refs.selector.$refs.menu.isActive) {
      return ActiveMenu.Tenant
    }

    if (this.$refs.notificationsMenu && this.$refs.notificationsMenu.dialog) {
      // check opened selector type in notificationsMenu modal
      if (this.$refs.notificationsMenu.isNotificationsSelectorOpened) {
        return ActiveMenu.NotificationsWithTypeSelector
      }
      return ActiveMenu.Notifications
    }

    if (this.$refs.profileMenu && this.$refs.profileMenu.dialog) {
      return ActiveMenu.Profile
    }

    return ActiveMenu.None
  }

  notificationsOpened() {
    this.$refs.profileMenu.dialog = false
  }

  profileOpened() {
    this.$refs.notificationsMenu.dialog = false
  }

  @Watch('getUserDetails')
  async userLoaded(user: IUser) {
    if (user) {
      this.notificationsVisible = isAuthorized(user, [Permission.AccessPrintModel])
      try {
        const apiJson = await get<any>(`${EndpointsUrls.Version}`)
        this.apiVersion = `${apiJson.PRODUCT_VERSION}_${apiJson.BUILD_ID} ${apiJson.BUILD_DATE}`
      } catch (error) {
        throw error
      }
    }

    if (this.getUserDetails) {
      this.currentTenant = this.getUserDetails.tenant
    }
  }

  @Watch('activeMenu')
  onMenuActiveChanged() {
    const isSomeMenuActive = this.activeMenu !== ActiveMenu.None
    this.setListenerForClickOutside(isSomeMenuActive, null, this.clickOutsideMenusHandler)
  }

  clickOutsideMenusHandler(event) {
    let style
    let menuStyle
    switch (this.activeMenu) {
      case ActiveMenu.Notifications:
        style = ActiveMenuParentStyles.NotificationsList
        menuStyle = ActiveMenuStyles.NotificationsMenu
        break
      case ActiveMenu.Profile:
        style = ActiveMenuParentStyles.UserProfile
        menuStyle = ActiveMenuStyles.UserProfileMenu
        break
      default:
        style = ActiveMenuParentStyles.DefaultVMenu
        break
    }

    let isInside = isClickedInside(event, style)
    if (menuStyle) {
      isInside = isInside || isClickedInside(event, menuStyle)
    }
    if (isInside) {
      return
    }

    switch (this.activeMenu) {
      case ActiveMenu.Tenant:
        this.$refs.selector.$refs.menu.isActive = false
        return
      case ActiveMenu.Notifications:
        if (this.$refs.notificationsMenu) {
          this.$refs.notificationsMenu.dialog = false
        }
        return
      case ActiveMenu.Profile:
        this.$refs.profileMenu.dialog = false
        return
      default:
        return
    }
  }

  login() {
    this.auth0Client.loginWithRedirect()
  }

  openHelpLibrary() {
    const routeData = this.$router.resolve({ name: RouterNames.HelpLibrary })
    window.open(routeData.href, '_blank')
  }

  provideFeedback() {
    // open Userback widget
    const fullName = `${this.getUserDetails.firstName} ${this.getUserDetails.lastName}`
    window.Userback.name = fullName
    window.Userback.email = this.getUserDetails.email
    const customData: any = {
      tenant: this.getUserDetails.tenant,
      customerName: fullName,
      customerEmail: this.getUserDetails.email,
      ampVersion: this.ampVersion,
      uiVersion: this.uiVersion,
      apiVersion: this.apiVersion,
    }
    if (this.buildPlan) {
      customData.buildPlanId = this.buildPlan.id
      customData.buildPlanName = this.buildPlan.name
      const machineConfig = this.getMachineConfigByPk(this.getSelectedMachineConfigPk)
      customData.machine = machineConfig.name
      const material = this.getMaterialByPk(
        new VersionablePk(this.buildPlan.materialId, this.buildPlan.materialVersion),
      )
      customData.material = material.name
    }
    window.Userback.setData(customData)
    window.Userback.addHeader('x-tenant-id', this.getUserDetails.tenant)
    window.Userback.open()
  }

  updated() {
    // This value should be set after all components have been rendered
    // and fetched the required data
    this.isLoaded = true
  }

  async changeUsersTenant(value: string, e) {
    const toolAction = await this.canExitActiveTool()
    if (toolAction === ExitToolAction.DoNotExit) {
      this.currentTenant = this.getUserDetails.tenant
      return
    }

    Vue.$cookies.set(AMP_CT_COOKIE, value)
    // reset the navigation, initial routing will
    // be calculated based on the tenant when the site loads
    window.location.href = window.location.origin
  }
}
