export enum ViewMode {
  Folders = 'folders',
  List = 'list',
}

export enum DetailsPanelViewMode {
  Default = 'default',
  ManageCollaborators = 'manage-collaborators',
  Part = 'part',
  BuildPlan = 'build-plan',
  IbcPlan = 'ibc-plan',
  Folder = 'folder',
  PrintOrder = 'print-order',
  ItemsNumber = 'items-number',
  ItemShared = 'item-shared',
}
