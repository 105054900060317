import { ICommand } from '@/types/UndoRedo/ICommand'
import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { Commit, Dispatch } from 'vuex'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { Command } from '@/types/UndoRedo/Command'
import { IUpdatableCommand } from '@/types/UndoRedo/IUpdatableCommand'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

const CREATE_INSIGHTS_ACTION_NAME = 'buildPlans/createInsightMultiple'
const REMOVE_INSIGHTS_ACTION_NAME = 'buildPlans/deleteInsightMultiple'

export class BuildPlanItemsCommand extends Command implements ICommand, IUpdatableCommand {
  public readonly commandType: CommandType
  public toolName: ToolNames

  private incomingBuildPlanItems: IBuildPlanItem[]
  private outgoingBuildPlanItems: IBuildPlanItem[]
  private buildPlanInsights: IBuildPlanInsight[]
  private oldBuildPlanItemIds: string[]
  private newBuildPlanItemIds: string[]

  /**
   * @param incomingBuildPlanItems Parts which will be removed/added after call undo/redo.
   * @param outgoingBuildPlanItems Parts which will be added/removed after call undo/redo.
   */
  constructor(
    incomingBuildPlanItems: IBuildPlanItem[],
    outgoingBuildPlanItems: IBuildPlanItem[],
    buildPlanInsights: IBuildPlanInsight[],
    commandType: CommandType,
    protected dispatch: Dispatch,
    protected commit: Commit,
  ) {
    super()

    this.incomingBuildPlanItems = JSON.parse(JSON.stringify(incomingBuildPlanItems))
    this.outgoingBuildPlanItems = JSON.parse(JSON.stringify(outgoingBuildPlanItems))
    this.buildPlanInsights = JSON.parse(JSON.stringify(buildPlanInsights))
    this.commandType = commandType
  }

  async undo(): Promise<void> {
    if (this.incomingBuildPlanItems.length) {
      await this.removeItems(this.incomingBuildPlanItems)
    }

    if (this.outgoingBuildPlanItems.length) {
      this.outgoingBuildPlanItems = await this.createItems(this.outgoingBuildPlanItems)
      this.selectAndHighlightPartsAfterRemove(this.outgoingBuildPlanItems)
    }
  }

  async redo(): Promise<void> {
    if (this.outgoingBuildPlanItems.length) {
      await this.removeItems(this.outgoingBuildPlanItems)
    }

    if (this.incomingBuildPlanItems.length) {
      this.incomingBuildPlanItems = await this.createItems(this.incomingBuildPlanItems)
      this.selectAndHighlightPartsAfterRemove(this.incomingBuildPlanItems)
    }
  }

  private async createItems(buildPlanItems: IBuildPlanItem[]): Promise<IBuildPlanItem[]> {
    const itemsToCreate: IBuildPlanItem[] = JSON.parse(JSON.stringify(buildPlanItems))
    this.oldBuildPlanItemIds = buildPlanItems.map((item) => item.id)

    const createdBuildPlanItems = await this.createBuildPlanItems(itemsToCreate)

    this.newBuildPlanItemIds = createdBuildPlanItems.map((item) => item.id)

    // Restore insufficient simulation accuracy insights
    if (this.buildPlanInsights.length) {
      this.buildPlanInsights = await this.dispatch(
        CREATE_INSIGHTS_ACTION_NAME,
        {
          insights: this.buildPlanInsights.map((insight) => ({
            ...insight,
            details: {
              ...insight.details,
              parts: insight.details.parts.map((part) => ({
                ...part,
                bpItemId: this.newBuildPlanItemIds[this.oldBuildPlanItemIds.findIndex((id) => id === part.bpItemId)],
              })),
            },
          })),
        },
        this.rootLevel,
      )
    }

    return createdBuildPlanItems
  }

  private async removeItems(buildPlanItems: IBuildPlanItem[]): Promise<void> {
    const itemsToDelete: IBuildPlanItem[] = JSON.parse(JSON.stringify(buildPlanItems))
    await this.deleteBuildPlanItems(itemsToDelete, true)

    // Remove insufficient simulation accuracy insights
    const buildPlanInsightIds = this.buildPlanInsights.map((insight) => insight.id)
    if (buildPlanInsightIds.length) {
      await this.dispatch(REMOVE_INSIGHTS_ACTION_NAME, { insightsIds: buildPlanInsightIds }, this.rootLevel)
    }

    await this.dispatch('label/clearLabelSetsOnUndoRedoAfterDuplicate', itemsToDelete, { root: true })
  }

  get idsToUpdate(): { oldBuildPlanItemIds: string[]; newBuildPlanItemIds: string[] } {
    return {
      oldBuildPlanItemIds: this.oldBuildPlanItemIds || [],
      newBuildPlanItemIds: this.newBuildPlanItemIds || [],
    }
  }

  get buildPlanItems(): IBuildPlanItem[] {
    return this.incomingBuildPlanItems.concat(this.outgoingBuildPlanItems)
  }
}
