
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Prop } from 'vue-property-decorator'
import VForm from 'vuetify/lib/components/VForm'

import Button from '@/components/controls/Common/Button.vue'
import StoresNamespaces from '@/store/namespaces'
import { InertGas, InertGasType, RecoaterMaterial, RecoaterType } from '@/types/BuildPlans/IBuildPlan'
import { getInertGasTypeFromFilename } from '@/utils/printStrategy/parametersHelper'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component({
  components: {
    Button,
  },
})
export default class ProcessParametersModal extends Vue {
  @sitesStore.Getter getInertGases: InertGas[]

  @Prop({ required: true, type: File }) file: File
  @Prop({ default: false }) loading: boolean

  form = {
    inertGasType: null,
    recoaterType: RecoaterType.Hard,
    recoaterMaterial: null,
  }
  isValid = false
  isLoading = false

  $refs: {
    form: VForm
  }

  get inertGasesTypeOptions() {
    return Object.entries(InertGasType).map(([, value]) => value)
  }

  get recoaterTypeOptions() {
    return Object.entries(RecoaterType).map(([, value]) => value)
  }

  get recoaterMaterialOptions() {
    return this.mapRecoaterTypeToMaterial[this.form.recoaterType] || []
  }

  get mapRecoaterTypeToMaterial() {
    return {
      [RecoaterType.Hard]: [RecoaterMaterial.Steel, RecoaterMaterial.StainlessSteel],
      [RecoaterType.Soft]: [RecoaterMaterial.Rubber, RecoaterMaterial.CarbonBrush],
    }
  }

  async beforeMount() {
    this.setInertGasType()
  }

  setInertGasType() {
    if (this.file) {
      this.form.inertGasType = getInertGasTypeFromFilename(this.file.name, this.getInertGases)
    }
  }

  async onCreateClick() {
    if (!this.$refs.form.validate() || this.loading) {
      return
    }
    this.$emit('confirm', { ...this.form, file: this.file })
  }

  onCloseClick() {
    if (this.loading) {
      return
    }
    this.$emit('close')
  }
}
