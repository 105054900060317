import { ICommand } from '@/types/UndoRedo/ICommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { Commit, Dispatch } from 'vuex'
import { Command } from '@/types/UndoRedo/Command'
import { RestoreSelectedPartsType } from '../BuildPlans/RestoreSelectedPartsType'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

export class RestoreSelectedPartsCommand extends Command implements ICommand {
  public readonly commandType: CommandType
  public toolName: ToolNames
  private readonly buildPlanItems: IBuildPlanItem[]

  constructor(
    buildPlanItems: IBuildPlanItem[],
    commandType: CommandType,
    private restoreType: RestoreSelectedPartsType,
    protected dispatch: Dispatch,
    protected commit: Commit,
  ) {
    super()

    this.buildPlanItems = JSON.parse(JSON.stringify(buildPlanItems))
    this.commandType = commandType
  }

  async redo(): Promise<void> {
    this.selectAndHighlightParts(this.buildPlanItems)
    this.dispatch('buildPlans/restoreSelectedParts', this.restoreType, this.rootLevel)
  }

  async undo(): Promise<void> {
    this.deselectItems()
    await this.applyTransformationMatrix()
    this.selectAndHighlightParts(this.buildPlanItems)
  }

  private applyTransformationMatrix(): void {
    for (const item of this.buildPlanItems) {
      const payload = {
        buildPlanItemId: item.id,
        transformation: item.transformationMatrix,
        options: { updateStateOnly: false },
      }

      this.commit('visualizationModule/applyTransformationMatrix', payload, this.rootLevel)
    }
  }
}
