/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Engine } from '@babylonjs/core/Engines/engine'
import { RenderTargetTexture } from '@babylonjs/core/Materials/Textures/renderTargetTexture'
import { AbstractMesh, InstancedMesh, LinesMesh } from '@babylonjs/core/Meshes'
import { Color3, Vector2 } from '@babylonjs/core/Maths'
import { Face } from '@/visualization/components/DracoDecoder'
import { RenderScene } from '@/visualization/render-scene'
import { IComponentMetadata, IPartMetadata, SceneItemType } from '@/visualization/types/SceneItemMetadata'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import { SceneMode } from '@/visualization/types/SceneTypes'
import { PickingShader } from '@/visualization/rendering/MeshShader'
import { IDENTITY_MATRIX } from '@/constants'
import { PickingInfo } from '@babylonjs/core/Collisions/pickingInfo'
import store from '@/store'
import { Clearance } from '@/visualization/types/ClearanceTypes'

export interface IPickingObject {
  type: SceneItemType
  faceIndex: number
}

export class PickingFace implements IPickingObject {
  type: SceneItemType
  faceIndex: number
  geometryId: string

  constructor(type: SceneItemType, geometryId: string, faceIndex: number) {
    this.type = type
    this.geometryId = geometryId
    this.faceIndex = faceIndex
  }
}

export class PickingSupport implements IPickingObject {
  type: SceneItemType
  faceIndex: number
  supportId: string

  constructor(supportId: string, faceIndex: number) {
    this.type = SceneItemType.Support
    this.supportId = supportId
    this.faceIndex = faceIndex
  }
}

export class GpuPicker {
  readonly pickingShader: PickingShader
  readonly pickingShaderWithZOffset: PickingShader
  readonly lightWidth: number = 128
  readonly lightHeight: number
  private engine: Engine
  private renderScene: RenderScene
  private meshManager: MeshManager
  private zoomThrottle: NodeJS.Timeout
  private resizeCanvasCallback: () => void

  // Use for picking faces - each face has unique color
  // Note: Part instances faces has the same color as root.
  // To identify specific build plan item use faces texture with part texture
  private pickingFacesTexture: RenderTargetTexture
  // Use for picking parts - each part has unique color
  private pickingPartsTexture: RenderTargetTexture
  private pickingPartsLightTexture: RenderTargetTexture
  // Use for picking bodies - each part has unique color
  private pickingBodiesTexture: RenderTargetTexture

  private facesTexturePixels: ArrayBufferView
  private partsTexturePixels: ArrayBufferView
  private partsLightTexturePixels: ArrayBufferView
  private bodiesTexturePixels: ArrayBufferView

  // Key = colorHex, Value = build plan item id
  private pickingFaces: Map<string, IPickingObject>
  // Key = colorHex, Value = face index
  private pickingParts: Map<string, string>
  private pickingPartsLight: Map<string, string>
  // Key = colorHex, Value = component id and
  private pickingBodies: Map<string, string>

  private knownPickingItems: Map<string, object>
  private renderLabels: boolean = false

  constructor(engine: Engine, renderScene: RenderScene) {
    this.engine = engine
    this.renderScene = renderScene
    this.meshManager = renderScene.getMeshManager()
    const scene = renderScene.getScene()
    this.pickingShader = new PickingShader(scene)
    this.pickingShaderWithZOffset = new PickingShader(scene)
    this.pickingShaderWithZOffset.shaderMaterial.zOffset = -1
    this.lightHeight = (this.engine.getRenderHeight() / this.engine.getRenderWidth()) * this.lightWidth

    this.pickingFaces = new Map<string, IPickingObject>()
    this.pickingFacesTexture = new RenderTargetTexture(
      'pickingFacesTexture',
      { width: this.engine.getRenderWidth(), height: this.engine.getRenderHeight() },
      scene,
    )

    this.pickingParts = new Map<string, string>()
    this.pickingPartsTexture = new RenderTargetTexture(
      'pickingPartsTexture',
      { width: this.engine.getRenderWidth(), height: this.engine.getRenderHeight() },
      scene,
    )

    this.pickingPartsLight = new Map<string, string>()
    this.pickingPartsLightTexture = new RenderTargetTexture(
      'pickingPartsLightTexture',
      { width: this.lightWidth, height: this.lightHeight },
      scene,
    )

    this.pickingBodies = new Map<string, string>()
    this.pickingBodiesTexture = new RenderTargetTexture(
      'pickingBodiesTexture',
      { width: this.engine.getRenderWidth(), height: this.engine.getRenderHeight() },
      scene,
    )

    this.knownPickingItems = new Map<string, object>()
    this.resizeCanvasCallback = () => {
      const renderHeight = this.engine.getRenderHeight()
      const renderWidth = this.engine.getRenderWidth()
      this.pickingFacesTexture.resize({ width: renderWidth, height: renderHeight })
      this.pickingPartsTexture.resize({ width: renderWidth, height: renderHeight })
      this.pickingBodiesTexture.resize({ width: renderWidth, height: renderHeight })
    }

    // Override onMouseWheel method with a copied function with a predefined context set to GpuPicker instance
    this.onMouseWheel = this.onMouseWheel.bind(this)

    this.renderScene.getScene().getEngine().getRenderingCanvas().addEventListener('wheel', this.onMouseWheel)

    this.engine.onResizeObservable.add(this.resizeCanvasCallback)
  }

  addPickingObjects(meshes: AbstractMesh[]) {
    if (!this.pickingFacesTexture.renderList && !this.pickingPartsTexture.renderList) {
      return
    }

    for (const mesh of meshes) {
      const isPresentInBodiesRenderList = this.pickingBodiesTexture.renderList.find(
        (renderListItem) => renderListItem.id === mesh.id,
      )
      if (
        isPresentInBodiesRenderList ||
        !(mesh instanceof AbstractMesh) ||
        (!this.meshManager.isComponentMesh(mesh) &&
          !this.meshManager.isSupportMesh(mesh) &&
          !this.meshManager.isOverhangMesh(mesh) &&
          !this.meshManager.isDefectMesh(mesh) &&
          !this.meshManager.isLabelSensitiveZone(mesh) &&
          !this.meshManager.isOverhangEdgeTube(mesh) &&
          !this.meshManager.isOverhangVertex(mesh) &&
          !this.meshManager.isOverhangSurface(mesh) &&
          !this.meshManager.isDefectMesh(mesh) &&
          !this.meshManager.isLabelOrigin(mesh) &&
          !this.meshManager.isClearanceSensitiveZone(mesh))
      ) {
        continue
      }

      // add parts for picking
      const partMesh = this.meshManager.getBuildPlanItemMeshByChild(mesh)
      const partMetadata = partMesh.metadata as IPartMetadata
      const partColorHex = partMetadata.pickingColor.toHexString()
      const pickingId =
        this.renderScene.getSceneMode() === SceneMode.BuildPlan && partMetadata.buildPlanItemId
          ? partMetadata.buildPlanItemId
          : partMesh.id
      this.pickingParts.set(partColorHex, pickingId)
      this.pickingPartsLight.set(partColorHex, pickingId)
      this.pickingPartsTexture.renderList.push(mesh)
      this.pickingPartsLightTexture.renderList.push(mesh)

      // add bodies for picking
      const componentMetadata = mesh.metadata as IComponentMetadata
      const bodyColorHex = componentMetadata.pickingColor.toHexString()
      if (!this.knownPickingItems.has(componentMetadata.componentId)) {
        this.pickingBodies.set(bodyColorHex, componentMetadata.componentId)
        this.knownPickingItems.set(componentMetadata.componentId, {})
      }

      this.pickingBodiesTexture.renderList.push(mesh)

      // add faces for picking
      if (!this.knownPickingItems.has(componentMetadata.geometryId) && componentMetadata.faces) {
        const faces = componentMetadata.faces
        // add the actual faces to a id->object dictionary
        for (let i = 0; i < faces.length; i += 1) {
          const pickingFace: Face = faces[i]
          const faceColorHex = pickingFace.color.toHexString()
          const pickingObject = new PickingFace(componentMetadata.itemType, componentMetadata.geometryId, i)
          this.pickingFaces.set(faceColorHex, pickingObject)
        }
        this.knownPickingItems.set(componentMetadata.geometryId, {})
      }

      this.pickingFacesTexture.renderList.push(mesh)

      const sourceMeshMetadata = (mesh as InstancedMesh).sourceMesh.metadata as IComponentMetadata
      sourceMeshMetadata.pickingShader.shaderMaterial.isReady((mesh as InstancedMesh).sourceMesh, true)
    }
  }

  removePickingObjects(meshes: AbstractMesh[]) {
    if (!this.pickingFacesTexture.renderList && !this.pickingPartsTexture.renderList) {
      return
    }

    for (const mesh of meshes) {
      if (
        !(mesh instanceof AbstractMesh) ||
        (!this.meshManager.isComponentMesh(mesh) &&
          !this.meshManager.isSupportMesh(mesh) &&
          !this.meshManager.isOverhangMesh(mesh) &&
          !this.meshManager.isDefectMesh(mesh) &&
          !this.meshManager.isLabelSensitiveZone(mesh) &&
          !this.meshManager.isOverhangEdgeTube(mesh) &&
          !this.meshManager.isOverhangVertex(mesh) &&
          !this.meshManager.isOverhangSurface(mesh) &&
          !this.meshManager.isDefectMesh(mesh) &&
          !this.meshManager.isLabelOrigin(mesh) &&
          !this.meshManager.isClearanceSensitiveZone(mesh))
      ) {
        continue
      }

      // delete parts for picking
      const partMetadata = this.meshManager.getBuildPlanItemMeshByChild(mesh).metadata as IPartMetadata
      const partColorHex = partMetadata.pickingColor.toHexString()
      const renderPartIndex = this.pickingPartsTexture.renderList.findIndex((r) => r.id === mesh.id)
      if (renderPartIndex !== -1) {
        this.pickingPartsTexture.renderList.splice(renderPartIndex, 1)
        this.pickingPartsLightTexture.renderList.splice(renderPartIndex, 1)
      }

      if (this.meshManager.isComponentMesh(mesh)) {
        this.pickingParts.delete(partColorHex)
        this.pickingPartsLight.delete(partColorHex)
      }

      // delete bodies for picking
      const componentMetadata = mesh.metadata as IComponentMetadata
      const renderBodyIndex = this.pickingBodiesTexture.renderList.findIndex((r) => r.id === mesh.id)
      if (renderBodyIndex !== -1) {
        this.pickingBodiesTexture.renderList.splice(renderBodyIndex, 1)
      }
      const isBodyExists = this.pickingBodiesTexture.renderList.some(
        (r) => r.metadata.componentId === mesh.metadata.componentId,
      )
      if (!isBodyExists) {
        const bodyColorHex = componentMetadata.pickingColor.toHexString()
        this.pickingBodies.delete(bodyColorHex)
        this.knownPickingItems.delete(componentMetadata.componentId)
        if (this.renderScene.getScene().metadata.componentPickingColor.has(componentMetadata.componentId)) {
          this.renderScene.getScene().metadata.componentPickingColor.delete(componentMetadata.componentId)
        }
      }

      // delete faces for picking
      const renderFaceIndex = this.pickingFacesTexture.renderList.findIndex((r) => r.id === mesh.id)
      if (renderFaceIndex !== -1) {
        this.pickingFacesTexture.renderList.splice(renderFaceIndex, 1)
      }
      const isFacesExist = this.pickingFacesTexture.renderList.some(
        (r) => r.metadata.geometryId === mesh.metadata.geometryId,
      )
      if (!isFacesExist) {
        const faces = componentMetadata.faces
        if (faces) {
          for (const face of faces) {
            const pickingFace: Face = face
            const faceColorHex = pickingFace.color.toHexString()
            this.pickingFaces.delete(faceColorHex)
          }
        }
        this.knownPickingItems.delete(componentMetadata.geometryId)
      }
    }
  }

  pick(pointerX: number, pointerY: number) {
    if (!this.facesTexturePixels && !this.partsTexturePixels && !this.bodiesTexturePixels) {
      return null
    }

    const renderHeight = this.engine.getRenderHeight()
    const renderWidth = this.engine.getRenderWidth()
    const x = Math.ceil(pointerX)
    const y = Math.ceil(renderHeight - pointerY)

    // pixel that is read from the lower left corner of a rectangular block of pixels
    const startIndex = y * 4 * renderWidth + x * 4
    const targetPixelForFaces = [
      this.facesTexturePixels[startIndex],
      this.facesTexturePixels[startIndex + 1],
      this.facesTexturePixels[startIndex + 2],
      this.facesTexturePixels[startIndex + 3],
    ]
    const targetPixelForParts = [
      this.partsTexturePixels[startIndex],
      this.partsTexturePixels[startIndex + 1],
      this.partsTexturePixels[startIndex + 2],
      this.partsTexturePixels[startIndex + 3],
    ]
    const targetPixelForBodies = [
      this.bodiesTexturePixels[startIndex],
      this.bodiesTexturePixels[startIndex + 1],
      this.bodiesTexturePixels[startIndex + 2],
      this.bodiesTexturePixels[startIndex + 3],
    ]

    // create object id from pixel color
    const colorHexForFaces = Color3.FromInts(
      targetPixelForFaces[0],
      targetPixelForFaces[1],
      targetPixelForFaces[2],
    ).toHexString()
    const colorHexForParts = Color3.FromInts(
      targetPixelForParts[0],
      targetPixelForParts[1],
      targetPixelForParts[2],
    ).toHexString()
    const colorHexForBodies = Color3.FromInts(
      targetPixelForBodies[0],
      targetPixelForBodies[1],
      targetPixelForBodies[2],
    ).toHexString()

    // get picked object by id-color
    const pickedPartId = this.pickingParts.get(colorHexForParts)
    const pickedFace = this.pickingFaces.get(colorHexForFaces)
    const pickedBodyId = this.pickingBodies.get(colorHexForBodies)
    if (!pickedPartId || !pickedBodyId || !pickedFace) {
      return null
    }

    const partMesh =
      this.renderScene.getSceneMode() === SceneMode.BuildPlan
        ? this.meshManager.getBuildPlanItemMeshById(pickedPartId)
        : this.renderScene.getScene().getTransformNodeByID(pickedPartId)
    let bodyMesh
    if (partMesh) {
      bodyMesh = partMesh
        .getChildMeshes()
        .find(
          (c) =>
            c.metadata &&
            c.metadata.componentId === pickedBodyId &&
            c.metadata.geometryId === (pickedFace as PickingFace).geometryId,
        )
    } else {
      bodyMesh = this.pickingBodiesTexture.renderList.find(
        (c) =>
          c.metadata &&
          c.metadata.componentId === pickedBodyId &&
          c.metadata.geometryId === (pickedFace as PickingFace).geometryId,
      )
    }

    const bodyMetadata = bodyMesh.metadata as IComponentMetadata

    if (this.meshManager.isOverhangEdgeTube(bodyMesh)) {
      if (!bodyMesh.metadata.renderingLines) {
        return null
      }
      const lines = bodyMesh.metadata.renderingLines.instances.at(0)
      return { body: lines, face: null, part: partMesh }
    }

    return { body: bodyMesh, face: bodyMetadata.faces[pickedFace.faceIndex], part: partMesh }
  }

  getNearestCameraTarget(x: number, y: number) {
    const delta = this.engine.getRenderWidth() / this.lightWidth
    const xLight = x / delta
    const yLight = y / delta
    const pickPointLight = new Vector2(xLight, yLight)
    const colored = []
    for (let i = 0; i < this.lightWidth * this.lightHeight; i += 1) {
      const startIndex = i * 4
      if (
        this.partsLightTexturePixels[startIndex] !== 255 ||
        this.partsLightTexturePixels[startIndex + 1] !== 255 ||
        this.partsLightTexturePixels[startIndex + 2] !== 255
      ) {
        colored.push(new Vector2(i % this.lightWidth, this.lightHeight - i / this.lightWidth))
      }
    }

    colored.sort((a, b) => Vector2.Distance(pickPointLight, a) - Vector2.Distance(pickPointLight, b))
    let pickInfo: PickingInfo
    for (const color of colored) {
      const pickingRay = this.renderScene
        .getScene()
        .createPickingRay(color.x * delta, color.y * delta, IDENTITY_MATRIX, this.renderScene.getActiveCamera())
      pickInfo = this.renderScene.getScene().pickWithRay(pickingRay, (mesh) => this.meshManager.isSceneItem(mesh))
      if (pickInfo.hit) {
        break
      }
    }

    return pickInfo.pickedPoint
  }

  enable(forceRender: boolean = false) {
    if (forceRender) {
      this.registerEvents()
      this.pickingFacesTexture.render()
      this.pickingPartsTexture.render()
      this.pickingPartsLightTexture.render()
      this.pickingBodiesTexture.render()

      return
    }

    this.renderScene.getScene().customRenderTargets = []
    this.renderScene.getScene().customRenderTargets.push(this.pickingFacesTexture)
    this.renderScene.getScene().customRenderTargets.push(this.pickingPartsTexture)
    this.renderScene.getScene().customRenderTargets.push(this.pickingPartsLightTexture)
    this.renderScene.getScene().customRenderTargets.push(this.pickingBodiesTexture)
    this.registerEvents()
  }

  async readAndSavePixels() {
    this.facesTexturePixels = await this.pickingFacesTexture.readPixels()
    this.partsTexturePixels = await this.pickingPartsTexture.readPixels()
    this.partsLightTexturePixels = await this.pickingPartsLightTexture.readPixels()
    this.bodiesTexturePixels = await this.pickingBodiesTexture.readPixels()
  }

  disable() {
    this.unregisterEvents()
    this.renderScene.getScene().customRenderTargets = []
  }

  enableLabels() {
    this.renderLabels = true
  }

  disableLabels() {
    this.renderLabels = false
  }

  dispose() {
    this.pickingFaces.clear()
    this.pickingFacesTexture.dispose()
    this.pickingPartsTexture.dispose()
    this.pickingPartsLightTexture.dispose()
    this.pickingBodiesTexture.dispose()
    this.pickingShader.dispose()
    this.pickingShaderWithZOffset.dispose()
    this.facesTexturePixels = null
    this.partsTexturePixels = null
    this.bodiesTexturePixels = null
    this.engine.onResizeObservable.removeCallback(this.resizeCanvasCallback)
    this.renderScene.getScene().getEngine().getRenderingCanvas().removeEventListener('wheel', this.onMouseWheel)
    if (this.zoomThrottle) {
      clearTimeout(this.zoomThrottle)
    }
  }

  private onMouseWheel() {
    if (this.zoomThrottle) {
      clearTimeout(this.zoomThrottle)
    }

    this.zoomThrottle = setTimeout(async () => {
      this.enable(true)
      await this.readAndSavePixels()
      this.disable()
    }, 500)
  }

  private adjustVisibility(mesh: InstancedMesh, renderForPicker: boolean) {
    if (this.meshManager.isOverhangEdgeTube(mesh)) {
      mesh.isVisible = renderForPicker
    } else if (this.meshManager.isOverhangEdge(mesh)) {
      mesh.isVisible = !renderForPicker
    }

    const metadata = mesh.metadata as IComponentMetadata
    if (metadata && metadata.isHidden && metadata.showAsTransparent && metadata.transparentCloneId) {
      // Mesh is hidden but pickable via its transparent clone.
      // Render opaque mesh fro GPU picker instead of transparent.
      const transparentClone = this.meshManager.getTransparentClone(mesh)
      transparentClone.isVisible = !renderForPicker
      mesh.isVisible = renderForPicker
    }
  }

  private registerEvents() {
    // tslint:disable-next-line:no-this-assignment
    const picker = this
    this.pickingFacesTexture.onBeforeRender = () => {
      for (const renderObject of this.pickingFacesTexture.renderList) {
        const instanced = renderObject as InstancedMesh
        instanced.sourceMesh.metadata.pickingShader.enableFacesColoring()
        instanced.sourceMesh.material = instanced.sourceMesh.metadata.pickingShader.shaderMaterial
        instanced.sourceMesh.metadata.isPickingMaterial = true
        this.adjustVisibility(instanced, true)
        if (picker.renderLabels && this.meshManager.isLabelSensitiveZone(instanced)) {
          const activeLabelSet = store.getters['label/activeLabelSet']
          if (activeLabelSet && instanced.metadata.labelSetId === activeLabelSet.id) {
            instanced.isVisible = true
          }
        }

        if (this.meshManager.isClearanceSensitiveZone(instanced)) {
          const clearance: Clearance = instanced.metadata.clearance
          if (!clearance.isNoClearance && !clearance.isHidden) {
            instanced.isVisible = true
          }
        }
      }
    }
    this.pickingFacesTexture.onAfterRender = () => {
      for (const renderObject of this.pickingFacesTexture.renderList) {
        const instanced = renderObject as InstancedMesh
        instanced.sourceMesh.material = instanced.sourceMesh.metadata.originalMaterial
        instanced.sourceMesh.metadata.isPickingMaterial = false
        if (picker.renderLabels && this.meshManager.isLabelSensitiveZone(instanced)) {
          instanced.isVisible = false
        }

        if (this.meshManager.isClearanceSensitiveZone(instanced)) {
          instanced.isVisible = false
        }
        this.adjustVisibility(instanced, false)
      }
    }

    this.pickingPartsTexture.onBeforeRender = this.pickingPartsLightTexture.onBeforeRender = () => {
      for (const renderObject of this.pickingPartsTexture.renderList) {
        const instanced = renderObject as InstancedMesh
        instanced.sourceMesh.metadata.pickingShader.enablePartsColoring()
        instanced.sourceMesh.material = instanced.sourceMesh.metadata.pickingShader.shaderMaterial
        instanced.sourceMesh.metadata.isPickingMaterial = true
        if (picker.renderLabels && this.meshManager.isLabelSensitiveZone(instanced)) {
          const activeLabelSet = store.getters['label/activeLabelSet']
          if (activeLabelSet && instanced.metadata.labelSetId === activeLabelSet.id) {
            instanced.isVisible = true
          }
        }

        if (this.meshManager.isClearanceSensitiveZone(instanced)) {
          const clearance: Clearance = instanced.metadata.clearance
          if (!clearance.isNoClearance && !clearance.isHidden) {
            instanced.isVisible = true
          }
        }
        this.adjustVisibility(instanced, true)
      }
    }
    this.pickingPartsTexture.onAfterRender = this.pickingPartsLightTexture.onAfterRender = () => {
      for (const renderObject of this.pickingPartsTexture.renderList) {
        const instanced = renderObject as InstancedMesh
        instanced.sourceMesh.material = instanced.sourceMesh.metadata.originalMaterial
        instanced.sourceMesh.metadata.isPickingMaterial = false
        if (picker.renderLabels && this.meshManager.isLabelSensitiveZone(instanced)) {
          instanced.isVisible = false
        }

        if (this.meshManager.isClearanceSensitiveZone(instanced)) {
          instanced.isVisible = false
        }
        this.adjustVisibility(instanced, false)
      }
    }

    this.pickingBodiesTexture.onBeforeRender = () => {
      for (const renderObject of this.pickingBodiesTexture.renderList) {
        const instanced = renderObject as InstancedMesh
        instanced.sourceMesh.metadata.pickingShader.enableBodiesColoring()
        instanced.sourceMesh.material = instanced.sourceMesh.metadata.pickingShader.shaderMaterial
        instanced.sourceMesh.metadata.isPickingMaterial = true
        if (picker.renderLabels && this.meshManager.isLabelSensitiveZone(instanced)) {
          const activeLabelSet = store.getters['label/activeLabelSet']
          if (activeLabelSet && instanced.metadata.labelSetId === activeLabelSet.id) {
            instanced.isVisible = true
          }
        }

        if (this.meshManager.isClearanceSensitiveZone(instanced)) {
          const clearance: Clearance = instanced.metadata.clearance
          if (!clearance.isNoClearance && !clearance.isHidden) {
            instanced.isVisible = true
          }
        }
        this.adjustVisibility(instanced, true)
      }
    }
    this.pickingBodiesTexture.onAfterRender = () => {
      for (const renderObject of this.pickingBodiesTexture.renderList) {
        const instanced = renderObject as InstancedMesh
        instanced.sourceMesh.material = instanced.sourceMesh.metadata.originalMaterial
        instanced.sourceMesh.metadata.isPickingMaterial = false
        if (picker.renderLabels && this.meshManager.isLabelSensitiveZone(instanced)) {
          instanced.isVisible = false
        }

        if (this.meshManager.isClearanceSensitiveZone(instanced)) {
          instanced.isVisible = false
        }
        this.adjustVisibility(instanced, false)
      }
    }
  }

  private unregisterEvents() {
    this.pickingFacesTexture.onBeforeRender = null
    this.pickingFacesTexture.onAfterRender = null
    this.pickingPartsTexture.onBeforeRender = null
    this.pickingPartsTexture.onAfterRender = null
    this.pickingPartsLightTexture.onBeforeRender = null
    this.pickingPartsLightTexture.onAfterRender = null
    this.pickingBodiesTexture.onBeforeRender = null
    this.pickingBodiesTexture.onAfterRender = null
  }
}
