import Vue from 'vue'

import SnackbarMessage from '@/utils/snackbar/SnackbarMessage'
import { SnackbarMessageType } from '@/types/messages/SnackbarMessageType'
import sentryService from '@/services/sentryService'

export default {
  showErrorMessage(message: string, timeout?: number) {
    sentryService.captureError(new Error(message))
    Vue.prototype.$snackbar.notify(new SnackbarMessage(message, SnackbarMessageType.Error, timeout))
  },

  showInfoMessage(message: string, timeout?: number) {
    Vue.prototype.$snackbar.notify(new SnackbarMessage(message, SnackbarMessageType.Info, timeout))
  },

  showWarningMessage(message: string, timeout?: number) {
    Vue.prototype.$snackbar.notify(new SnackbarMessage(message, SnackbarMessageType.Warning, timeout))
  },

  showSuccessMessage(message: string, timeout?: number) {
    Vue.prototype.$snackbar.notify(new SnackbarMessage(message, SnackbarMessageType.Success, timeout))
  },
}
