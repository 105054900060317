import { AnnouncementType } from '@/constants'
import { get, post } from '@/api/http'
import { EndpointsUrls } from '@/configs/config'
import { handleAPIError } from '@/api/common'
import { IAnnouncement } from '@/types/Announcement/IAnnouncement'

export default {
  async getLatestUnreadAnnouncementByType(announcementType: AnnouncementType) {
    try {
      return await get<IAnnouncement>(`${EndpointsUrls.Announcements}/${announcementType}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async readAnnouncement(announcementId: string) {
    try {
      return await post<string>(`${EndpointsUrls.Announcements}/readAnnouncement/${announcementId}`, {})
    } catch (error) {
      handleAPIError(error)
    }
  },
}
