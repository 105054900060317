import { Unit } from '@/types/Simulation/Units'

const SUMMARY = 'Summary'
const GEOMETRY_WITHIN_TOLERANCE = 'Geometry Within Tolerance'
const RECOATER_CLEARANCE_RISK = 'Recoater Clearance Risk'
const DEVIATION = 'Residual Deviation'
const LOCATION_UNIT = 'location unit'
const SCALAR_UNIT = 'scalar unit'
const MAXIMUM = 'maximum'
const AVERAGE = 'average'
const LOCATION = 'location'
const BUILD_STRESS = 'Build Stress Von Mises Stress'
const RESIDUAL_STRESS = 'Residual Stress Von Mises Stress'
const COMPENSATION = 'Compensation Applied'
const RECOATER = 'Recoater Clearance'
const STEPS = 'steps'
const RISK = 'risk'
const CLEARANCE = 'clearance'

export enum RiskType {
  Low = 'low',
  Moderate = 'moderate',
  High = 'high',
}

export interface ILocationEntry {
  location: number[]
  locationUnit: Unit
  maximum: number
  average: number
  scalarUnit: Unit
}

export interface IProcessItem {
  risk?: RiskType
  details: string
  label?: string
  value: number
  unit: Unit
  referenceLabel?: string
  active: boolean
  location: number[]
}

export interface IProcessSummary {
  conclusion?: boolean | RiskType
  items: IProcessItem[]
  label: string
}

export interface IRecoaterEvent {
  location: number[]
  risk: RiskType
  clearance: number
}

export interface IRecoaterInfo {
  scalarUnit: Unit
  locationUnit: Unit
  steps: IRecoaterEvent[]
}

export interface ISummaryDetails {
  withinTolerance?: boolean
  recoaterRisk?: RiskType
  deviation: ILocationEntry
  buildStress?: ILocationEntry
  residualStress?: ILocationEntry
  compensationApplied?: ILocationEntry
  recoater?: IRecoaterInfo
}

export function getSimulationSummary(rawData): ISummaryDetails {
  if (!rawData[SUMMARY]) return undefined
  const summaryData = rawData[SUMMARY]
  const tolerance = summaryData[GEOMETRY_WITHIN_TOLERANCE]
  const risk = summaryData[RECOATER_CLEARANCE_RISK]
  const compensationEntry = getLocationEntry(summaryData[COMPENSATION])

  return {
    withinTolerance: tolerance && compensationEntry ? tolerance === 'true' : undefined,
    deviation: getLocationEntry(summaryData[DEVIATION]),
    buildStress: getLocationEntry(summaryData[BUILD_STRESS]),
    residualStress: getLocationEntry(summaryData[RESIDUAL_STRESS]),
    recoaterRisk: risk ? (risk as RiskType) : undefined,
    compensationApplied: compensationEntry,
    recoater: getRecoaterInfo(summaryData[RECOATER]),
  }
}

function getLocationEntry(data): ILocationEntry {
  if (!data || Object.keys(data).length === 0) return undefined

  return {
    maximum: data[MAXIMUM],
    average: data[AVERAGE],
    scalarUnit: unitStringToEnum(data[SCALAR_UNIT]),
    locationUnit: unitStringToEnum(data[LOCATION_UNIT]),
    location: data[LOCATION],
  }
}

function getRecoaterInfo(data): IRecoaterInfo {
  if (!data || (Array.isArray(data[STEPS]) && data[STEPS].length === 0)) return undefined

  return {
    scalarUnit: unitStringToEnum(data[SCALAR_UNIT]),
    locationUnit: unitStringToEnum(data[LOCATION_UNIT]),
    steps: data[STEPS].map((d) => ({
      location: d[LOCATION],
      risk: d[RISK] as RiskType,
      clearance: d[CLEARANCE],
    })),
  }
}

function unitStringToEnum(value: string): Unit {
  if (value === 'mm') return Unit.Millimeter
  if (value === 'MPa') return Unit.MegaPascal
  if (value === 'percent') return Unit.Percent

  return Unit.Undefined
}
