
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IJob, JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { PrintOrderJobStatusCode } from '@/types/PrintOrder/PrintOrderJobStatusCode'
import { convertNewlineCharacters, jobTypeToNameForDashboard } from '@/utils/string'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'

const commonStore = namespace(StoresNamespaces.Common)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component
export default class JobStatusIcon extends Vue {
  @commonStore.Getter tooltipOpenDelay: number
  @buildPlansStore.Getter isSinterPlan: boolean
  @buildPlansStore.Getter getIBCPlan: IIBCPlan

  @Prop({ required: true }) isSuccessIcon: boolean
  @Prop({ required: true }) job: IJob
  @Prop({ default: false }) addReadOnlyStatus: boolean
  @Prop({ required: true }) isWarningIcon: boolean

  get iconColor() {
    if (this.isSuccessIcon) {
      return '#00BF6F'
    }

    if (this.isWarningIcon) {
      return '#FF9800'
    }

    return '#D74E47'
  }

  get icon() {
    if (this.isSuccessIcon || this.isWarningIcon) {
      return 'mdi-check-circle'
    }

    return 'mdi-close-circle'
  }

  get jobType() {
    return jobTypeToNameForDashboard(this.job.jobType)
  }

  get jobStatus() {
    if (this.job.jobType !== JobType.PRINT && this.job.code !== JobStatusCode.WARNING) {
      return this.job.code
    }
    return PrintOrderJobStatusCode[this.job.code]
  }

  get description() {
    if (this.isSuccessIcon) {
      return this.$t('jobCompletedSuccessfully')
    }

    const isFailedCompensateJob = this.job.jobType === JobType.ICOMPENSATE && this.job.code === JobStatusCode.ERROR
    if (isFailedCompensateJob) {
      const parsedDescription = this.parseJobDescription()
      if (!parsedDescription || !parsedDescription.message) {
        return convertNewlineCharacters(this.job.description)
      }
      return parsedDescription.message
    }

    // Warning description treatment
    if (this.job.code === JobStatusCode.WARNING) {
      const parsedDescription = this.parseJobDescription()
      if (!parsedDescription || !parsedDescription.message) {
        return convertNewlineCharacters(this.job.description)
      }
      let warningDescription: string = `${parsedDescription.message}`
      if (parsedDescription.warnings && parsedDescription.warnings.length) {
        if (parsedDescription.warnings.length === 1) {
          warningDescription = `${warningDescription}<br>${this.replaceNewline(parsedDescription.warnings[0])}`
        } else {
          warningDescription = `${warningDescription}<ul>`
          parsedDescription.warnings.forEach((warn) => {
            warningDescription = `${warningDescription}<li>${this.replaceNewline(warn)}</li>`
          })
          warningDescription = `${warningDescription}</ul>`
        }
      }
      return warningDescription
    }

    return convertNewlineCharacters(this.job.description)
  }

  get modalityReadonly() {
    if (this.isSinterPlan || this.getIBCPlan) {
      return this.$t('toolInformationSinterPlanReadOnly')
    }

    return this.$t('toolInformationBuildPlanReadOnly')
  }

  replaceNewline(description: string) {
    return description.replace(/\n/g, '<br>')
  }

  parseJobDescription() {
    const jsonMatch = this.job.description.match(/{[\s\S]+}/)

    if (jsonMatch) {
      try {
        const jsonObject = JSON.parse(jsonMatch[0])
        if (this.job.code === JobStatusCode.ERROR) {
          const { message, percentComplete } = jsonObject
          return {
            message,
            percentComplete,
          }
        }
        if (this.job.code === JobStatusCode.WARNING) {
          const { message, percentComplete, warnings } = jsonObject
          return {
            message,
            percentComplete,
            warnings,
          }
        }
      } catch (error) {
        console.error('Error parsing job description:', error)
        return null
      }
    }
    return null
  }
}
