
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import Selector from '@/components/controls/Common/Selector.vue'

@Component({
  components: {
    Selector,
  },
  inheritAttrs: false,
})
export default class LabeledSelector extends Vue {
  @Prop({ type: String, required: true }) label: string
  @Prop({ default: false }) hideDetails: boolean

  isActive = false

  onSelectorOpen(status: boolean) {
    this.isActive = status
  }
}
