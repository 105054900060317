import { ScaleRange } from '@/visualization/scales'
import { CategoryDataHandler } from '@/visualization/rendering/CategoryDataHandler'
import { int } from '@babylonjs/core/types'
import { SimulationColoringView } from '@/types/Simulation/SimulationColoringView'
import { SimulationColoringModel } from '@/types/Simulation/SimulationColoringModel'
import { createGuid } from '@/utils/common'
import { equalWithTolerance } from '@/utils/number'
import { IRangeInfo } from '@/visualization/types/Common'
import { getUnitTolerance } from '@/types/Simulation/Units'
import { DIFFERENCE_SIGNED, ELASTIC_STRAIN_FIELD_PATTERN, SHRINKAGE_ON } from '@/constants'
import { Vector3 } from '@babylonjs/core/Maths'
import { ResultsManagerEvent as RMEvent, CategoryKind } from '@/visualization/types/SimulationTypes'
import { ResultsManager } from '@/visualization/rendering/ResultsManager'
import { OrthoCamera } from '@/visualization/components/OrthoCamera'

const DEFAULT_LEGEND_POINTS_LENGTH = 5

export interface IDataHandlerInfo {
  type: CategoryKind
  visible: boolean
}

enum Feedback {
  LoadedMesh = 'updateMesh',
  WarpingApplied = 'warpingApplied',
  TimeStampsInitialized = 'timeStampsInitialized',
  FieldDataReceived = 'coloringInfoReady',
  ColorSchemeApplied = 'colorSchemeApplied',
}

export class SimulationStepHandler {
  private manager: ResultsManager
  private dataHandlers: Map<CategoryKind, CategoryDataHandler>
  private stepId: string
  private readyMarkers: Map<Feedback, number>
  private currentTimeStamp: int
  private timeStamps: number[]
  private coloringModel: SimulationColoringModel
  private colorView: SimulationColoringView
  private warpingFactor: number
  private allStepsScales: Map<string, ScaleRange>
  private currentStepScales: Map<string, ScaleRange>
  private xrayInfo: { min: number; max: number }
  private isWarpingApplied: boolean
  private stepBounds: { min: Vector3; max: Vector3 }
  private fieldDataCache: any[]
  private handlersToTrack: number

  constructor(
    manager: ResultsManager,
    scalesInfo: Map<string, ScaleRange>,
    pathInfo: {
      parts: string | string[]
      supports: string | string[]
      buildPlate: string | string[]
      coupons: string | string[]
    },
  ) {
    this.manager = manager
    this.stepId = createGuid()
    this.warpingFactor = 1
    this.dataHandlers = new Map<CategoryKind, CategoryDataHandler>()
    this.readyMarkers = new Map<Feedback, number>()
    this.allStepsScales = scalesInfo ? scalesInfo : new Map<string, ScaleRange>()
    this.currentStepScales = new Map<string, ScaleRange>()
    this.isWarpingApplied = true
    this.stepBounds = { max: undefined, min: undefined }
    this.fieldDataCache = []
    this.timeStamps = []

    Object.values(Feedback).forEach((v) => this.readyMarkers.set(v, 0))
    Object.keys(pathInfo)
      .filter((k) => pathInfo[k] !== undefined)
      .forEach((k) => {
        const key = CategoryKind[k.toUpperCase() as keyof typeof CategoryKind]
        const handler = new CategoryDataHandler(
          this.manager,
          this.allStepsScales,
          this.currentStepScales,
          pathInfo[k],
          this.stepId,
          key,
        )
        this.dataHandlers.set(key, handler)
        this.subscribeHandlersEvents(handler, key)
      })

    this.handlersToTrack = this.dataHandlers.size
  }

  private get warpingFieldView() {
    return this.coloringModel.getDisplacementView()
  }

  private get warpingDataAvailable() {
    const shrinkage = this.manager.analysisSettings && this.manager.analysisSettings[SHRINKAGE_ON]
    if (shrinkage && !this.manager.simCompSupportsWarping) return false

    return this.warpingFieldView && this.warpingFieldView.modes.length === 4
  }

  openDataSet() {
    this.manager.changeIsLoading.trigger({ isLoading: true })
    this.dataHandlers.forEach((value, key) => {
      value.openDataSet(this.manager.getHandlerVisibility(key))
    })
  }

  changeDatasetColorView(view: SimulationColoringView) {
    if (!view) return

    this.manager.changeIsLoading.trigger({ isLoading: true })
    this.xrayInfo = undefined
    this.colorView = Object.create(view)
    this.dataHandlers.forEach((value, key) => {
      value.changeDatasetColorView(this.colorView)
    })
  }

  changeTimeStamp(timeStamp: int) {
    const newTimeStamp = Number(timeStamp)
    if (newTimeStamp === this.currentTimeStamp) return
    this.currentTimeStamp = newTimeStamp

    this.manager.changeIsLoading.trigger({ isLoading: true })
    this.currentStepScales.clear()

    let availableHandlers = 0
    this.dataHandlers.forEach((value, key) => {
      if (value.availableInTimestamp(newTimeStamp, this.timeStamps.length)) {
        availableHandlers += 1
      }
    })

    this.handlersToTrack = availableHandlers
    this.dataHandlers.forEach((value, key) => {
      value.changeTimeStamp(newTimeStamp, this.timeStamps.length)
    })
  }

  clearData() {
    this.dataHandlers.forEach((value, key) => {
      value.clearData()
    })
  }

  activateStep(isVisible: boolean) {
    if (!isVisible) {
      this.triggerEvent(RMEvent.WarpingAvailable, { available: false, loaded: false })
      this.triggerEvent(RMEvent.LegendChanged, { rangePoints: [], colors: [] })
      this.triggerEvent(RMEvent.SlicerInitialized, { max: 0, min: 0 })
      this.triggerEvent(RMEvent.TimestampsInitialized, { stamps: [], current: undefined })

      if (this.coloringModel) {
        this.triggerEvent(RMEvent.ColoringInitialized, { current: undefined, model: new SimulationColoringModel([]) })
      }

      this.triggerEvent(RMEvent.StepBounds, { min: undefined, max: undefined })
      this.dataHandlers.forEach((value, key) => {
        value.activate(false)
      })
    } else {
      const legendInfo = this.getLegendInfo(this.targetDataRange, this.firstDataHandler.transferFunction)
      this.triggerEvent(RMEvent.LegendChanged, legendInfo)
      this.triggerEvent(RMEvent.DataRangeChanged, this.rangeInfo)
      this.triggerEvent(RMEvent.TimestampsInitialized, { stamps: this.timeStamps, current: this.currentTimeStamp })

      if (this.colorView) {
        this.triggerEvent(RMEvent.ColoringInitialized, { current: this.colorView, model: this.coloringModel })

        if (this.warpingDataAvailable) {
          const loaded = this.firstDataHandler.isWarpingDataInitialized
          this.triggerEvent(RMEvent.WarpingAvailable, { loaded, available: true, factor: this.warpingFactor })
        }
      }

      this.triggerEvent(RMEvent.StepBounds, this.stepBounds)
      this.dataHandlers.forEach((value, key) => {
        value.activate(this.manager.getHandlerVisibility(key))
      })

      this.manager.sliceManager.adjustSlicingPlane()
    }

    this.manager.getScene.render()
  }

  setXrayView(minBound: number, maxBound: number) {
    this.xrayInfo = { min: minBound, max: maxBound }
    this.dataHandlers.forEach((value, key) => {
      value.setXrayView(minBound, maxBound)
    })
  }

  resetXrayView() {
    this.xrayInfo = undefined
    this.dataHandlers.forEach((value, key) => {
      value.resetXrayView()
    })
  }

  applyColorScheme() {
    this.dataHandlers.forEach((value, key) => {
      value.applyColorScheme()
    })
  }

  setWarpingParameters(factor: number) {
    this.warpingFactor = factor
    this.manager.changeIsLoading.trigger({ isLoading: true })
    this.dataHandlers.forEach((value, key) => {
      value.setWarpingParameters(factor, this.warpingFieldView.internalName)
    })
  }

  setHandlerVisibility(handlerInfo: IDataHandlerInfo) {
    const handler = this.dataHandlers.get(handlerInfo.type)
    if (handler) {
      handler.hide(!handlerInfo.visible)
      if (handler.isReady) {
        this.manager.sliceManager.adjustSlicingPlane()
      }
    }
  }

  private adjustCamera() {
    const camera = this.manager.getScene.activeCamera as OrthoCamera
    camera.setNewTarget(this.manager.getScene)
  }

  private subscribeHandlersEvents(handler: CategoryDataHandler, type: CategoryKind) {
    handler.onLoaded.on(() => {
      this.incrementReadyMarker(Feedback.LoadedMesh)
      if (!this.allHandlersUpdated(Feedback.LoadedMesh)) return
      this.stepBounds = this.manager.getScene.getWorldExtends(
        (mesh) => mesh.name.includes(this.stepId) && mesh.visibility === 1,
      )
      this.triggerEvent(RMEvent.StepBounds, this.stepBounds)

      if (!this.isWarpingApplied) {
        this.manager.sliceManager.adjustSlicingPlane()
        this.manager.renderScene()
      } else {
        this.setWarpingParameters(this.warpingFactor)
      }
    })

    handler.onWarpingApplied.on((initialLoad) => {
      this.incrementReadyMarker(Feedback.WarpingApplied)
      if (!this.allHandlersUpdated(Feedback.WarpingApplied)) return

      if (initialLoad) {
        this.triggerEvent(RMEvent.WarpingAvailable, { available: true, loaded: true })
      }

      this.manager.sliceManager.adjustSlicingPlane()
      this.manager.changeIsLoading.trigger({ isLoading: false })
      this.manager.renderScene()
    })

    handler.onTimeStampsInitialized.on((args) => {
      this.incrementReadyMarker(Feedback.TimeStampsInitialized)
      if (args.timeStamps && args.timeStamps.length > this.timeStamps.length) {
        this.timeStamps = args.timeStamps
      }

      if (!this.allHandlersUpdated(Feedback.TimeStampsInitialized)) return

      if (args.update) {
        this.triggerEvent(RMEvent.NewTimestampLoaded, true)
      } else {
        this.currentTimeStamp = this.timeStamps.length - 1
      }

      this.triggerEvent(RMEvent.TimestampsInitialized, { stamps: this.timeStamps, current: this.currentTimeStamp })
    })

    handler.onFieldDataReceived.on((fieldsData) => {
      this.incrementReadyMarker(Feedback.FieldDataReceived)
      if (this.firstHandlerUpdated(Feedback.FieldDataReceived)) {
        this.fieldDataCache = []
      }

      if (fieldsData) {
        this.updateScalesInfoFromActualRanges(fieldsData)
        this.fieldDataCache.push(fieldsData)
      }

      if (!this.allHandlersUpdated(Feedback.FieldDataReceived)) return

      // Sometimes datasets have different number of fields available (usually supports have extra fields)
      // so we need to be sure that we are requesting field data which is common for all datahandlers
      const commonFieldData = this.fieldDataCache.reduce((acc, current) => {
        Object.keys(acc).forEach((k) => {
          if (!current.hasOwnProperty(k)) {
            delete acc[k]
          }
        })
        return acc
      })

      if (!this.coloringModel || !this.coloringModel.containsAllFieldsKeys(Object.keys(commonFieldData))) {
        this.initializeColoringModel(commonFieldData)
      }

      this.dataHandlers.forEach((v) => v.setColoringInfo(this.coloringModel, this.colorView))

      if (this.warpingDataAvailable) {
        this.triggerEvent(RMEvent.WarpingAvailable, { available: true, loaded: false, factor: this.warpingFactor })
      } else {
        this.isWarpingApplied = false
      }
    })

    handler.onColorSchemeApplied.on(() => {
      this.incrementReadyMarker(Feedback.ColorSchemeApplied)
      if (!this.allHandlersUpdated(Feedback.ColorSchemeApplied)) return

      this.triggerEvent(RMEvent.LegendChanged, this.getLegendInfo(this.targetDataRange, handler.transferFunction))
      this.handleXrayOnRangeChange(this.targetDataRange)
      if (this.xrayInfo) {
        this.setXrayView(this.xrayInfo.min, this.xrayInfo.max)
      }

      this.triggerEvent(RMEvent.DataRangeChanged, this.rangeInfo)
      this.adjustCamera()
      this.manager.getScene.render()
      this.manager.changeIsLoading.trigger({ isLoading: false })
    })
  }

  private get firstDataHandler(): CategoryDataHandler {
    return this.dataHandlers.values().next().value
  }

  private incrementReadyMarker(type: Feedback) {
    this.readyMarkers.set(type, this.readyMarkers.get(type) + 1)
  }

  private firstHandlerUpdated(type: Feedback) {
    return this.readyMarkers.get(type) === 1
  }

  private allHandlersUpdated(type: Feedback) {
    if (this.readyMarkers.get(type) === this.handlersToTrack) {
      this.readyMarkers.set(type, 0)
      return true
    }

    return false
  }

  private initializeColoringModel(fieldsData) {
    const colorViews = []
    Object.keys(fieldsData)
      .sort((a, b) => {
        // Strain field should be the last
        if (ELASTIC_STRAIN_FIELD_PATTERN.test(a)) return 1
        if (ELASTIC_STRAIN_FIELD_PATTERN.test(b)) return -1

        return 0
      })
      .forEach((key) => {
        const numberOfComponents: number = fieldsData[key].numberOfComponents
        colorViews.push(new SimulationColoringView(key, numberOfComponents))
      })

    const normalSignedView = colorViews.find((v) => v.isNameMatches(DIFFERENCE_SIGNED))
    if (normalSignedView) {
      this.colorView = Object.create(normalSignedView)
    }

    this.coloringModel = new SimulationColoringModel(colorViews)
    if (!this.coloringModel.containsView(this.colorView)) {
      this.colorView = Object.create(this.coloringModel.views[0])
    } else {
      this.coloringModel.selectedView = this.colorView
    }

    this.triggerEvent(RMEvent.ColoringInitialized, { current: null, model: this.coloringModel })
  }

  private updateScalesInfoFromActualRanges(fieldsData) {
    Object.keys(fieldsData).forEach((fieldName, index) => {
      const field = fieldsData[fieldName]
      if (!this.allStepsScales.has(fieldName)) {
        this.allStepsScales.set(fieldName, new ScaleRange())
      }

      if (!this.currentStepScales.has(fieldName)) {
        this.currentStepScales.set(fieldName, new ScaleRange())
      }

      const numberOfComponents: number = field.numberOfComponents
      for (let i = 0; i < numberOfComponents; i += 1) {
        const componentName = i.toString()
        const componentData = field[componentName]
        const dataRange = componentData.range

        const allStepsRange = this.allStepsScales.get(fieldName)
        allStepsRange.setMinValue(componentName, dataRange.min)
        allStepsRange.setMaxValue(componentName, dataRange.max)

        const currentStepRange = this.currentStepScales.get(fieldName)
        currentStepRange.setMinValue(componentName, dataRange.min)
        currentStepRange.setMaxValue(componentName, dataRange.max)
      }
    })
  }

  private handleXrayOnRangeChange(range: { min: number; max: number }) {
    if (!this.xrayInfo) return

    const minXrayOutOfRange =
      range.min > this.xrayInfo.min || equalWithTolerance(range.min, this.xrayInfo.min, Number.EPSILON)

    const maxXrayOutOfRange =
      range.max < this.xrayInfo.max || equalWithTolerance(range.max, this.xrayInfo.max, Number.EPSILON)

    if (minXrayOutOfRange && maxXrayOutOfRange) {
      this.xrayInfo = undefined
      return
    }

    if (minXrayOutOfRange) {
      this.xrayInfo.min = range.min
    }

    if (maxXrayOutOfRange) {
      this.xrayInfo.max = range.max
    }
  }

  /**
   * Returns points and colors for Legend visualization
   */
  private getLegendInfo(dataRange: { min: number; max: number }, colorTransferFunction) {
    if (!dataRange) return
    const unit = this.coloringModel.selectedView.unit
    const rangeToletanceRatio = Math.abs(dataRange.max - dataRange.min) / Math.pow(10, getUnitTolerance(unit))
    const nodePoints = colorTransferFunction.get().nodes.map((node) => node.x)

    const start = nodePoints[0]
    const end = nodePoints[nodePoints.length - 1]

    const rangePoints = []
    const colors = []
    if (rangeToletanceRatio < 1) {
      rangePoints.push(dataRange.max, dataRange.min)
      rangePoints.forEach((p) => colors.push(colorTransferFunction.mapValue(0.5)))
    } else {
      const scalePointsLength =
        rangeToletanceRatio > DEFAULT_LEGEND_POINTS_LENGTH
          ? DEFAULT_LEGEND_POINTS_LENGTH
          : Math.floor(rangeToletanceRatio)

      for (let i = 0; i <= scalePointsLength; i += 1) {
        rangePoints.push(start + (i / scalePointsLength) * (end - start))
      }

      colorTransferFunction.setUseAboveRangeColor(false)
      colorTransferFunction.setUseBelowRangeColor(false)
      for (let i = 0; i <= DEFAULT_LEGEND_POINTS_LENGTH; i += 1) {
        const rangeValue = start + (i / DEFAULT_LEGEND_POINTS_LENGTH) * (end - start)
        colors.push(colorTransferFunction.mapValue(rangeValue))
      }

      colorTransferFunction.setUseAboveRangeColor(true)
      colorTransferFunction.setUseBelowRangeColor(true)
    }

    rangePoints.reverse()
    colors.reverse()

    return { rangePoints, colors }
  }

  private get rangeInfo(): IRangeInfo {
    const rangeName = this.colorView.getSelectedModeMetadataId()
    const fieldName = this.colorView.internalName
    const currentStepFieldScale = this.currentStepScales.get(fieldName)
    const allStepsFieldScale = this.allStepsScales.get(fieldName)

    return {
      allSteps: { min: allStepsFieldScale.min.get(rangeName), max: allStepsFieldScale.max.get(rangeName) },
      currentStep: { min: currentStepFieldScale.min.get(rangeName), max: currentStepFieldScale.max.get(rangeName) },
      xray: this.xrayInfo,
    }
  }

  private get targetDataRange(): { min: number; max: number } {
    return this.manager.isCurrentComponentRange ? this.rangeInfo.currentStep : this.rangeInfo.allSteps
  }

  private triggerEvent(event: RMEvent, args) {
    this.manager.resultsEvent.trigger({ args, event })
  }
}
