import { Scene } from '@babylonjs/core/scene'
import { Color3, Color4, Matrix, Vector2, Vector3 } from '@babylonjs/core/Maths'
import { StandardMaterial } from '@babylonjs/core/Materials'

import { OVERHANG } from '../constants'

import { ItemsGeometry } from './ItemsGeometry'

type Edge = { a: number; b: number }
type OFacet = { a: number; b: number; c: number; e1?: Edge; e2?: Edge; e3?: Edge; included?: boolean }
type Loop = { loop: Vector3[]; hull?: Vector3[]; xyOBB?: Vector3[] }
type Diameter = { value: number; beginPoint: Vector3; finalPoint: Vector3 }
type OverhangZone = { facets: OFacet[]; vertices: Vector3[]; boundaries: Loop[]; diameter?: Diameter }

/**
 * Performs build plan items overhang zones  checking
 */
export class OZChecker extends ItemsGeometry {
  overhangTolerance: number = 2 /* mm */
  maxAngle: number = 45 /* 45 degrees */
  zTolerance: number = -Math.cos((Math.PI * this.maxAngle) / 180)
  overhangDimension: number = 30 /* mm */

  overhangVertices: Map<string, Vector3[]>
  overhangFacets: Map<string, OFacet[]>
  overhangZones: Map<string, OverhangZone[]>

  constructor(scene: Scene) {
    super(scene)

    this.overhangVertices = new Map<string, Vector3[]>()
    this.overhangFacets = new Map<string, OFacet[]>()
    this.overhangZones = new Map<string, OverhangZone[]>()
  }

  /**
   * Runs overhang zones check
   */
  public checkOverhangZones() {
    this.resetData()

    // Collect items geometries
    const success = this.collectGeometry()
    if (!success) {
      return
    }

    // Set overhang zones of all items
    this.setOverhangFacets()
    this.setOverhangZones()
    this.setOverhangLoops()
  }

  /**
   * Shows loops of the overhang zones
   */
  public showOverhangLoops() {
    // Diameter to show any 3D points
    const d: number = 0.004 * this.diagonal

    this.clearOverhangZones()

    for (const itemId of this.overhangZones.keys()) {
      const itemPositions = ItemsGeometry.overhangGeometry.get(itemId).vertices
      const ohZones = this.overhangZones.get(itemId)

      for (const zone of ohZones) {
        if (
          zone.vertices !== undefined &&
          zone.vertices.length === 0 &&
          zone.boundaries !== undefined &&
          zone.boundaries.length === 0
        ) {
          continue
        }

        // Random color for loops, its xyOBB and overhang vertices
        let randomColor = Color3.Random()
        // Material to show any 3D points
        const pointMaterial = new StandardMaterial('overhangVertices', this.scene)
        pointMaterial.diffuseColor = randomColor

        // Show all vertices of zone
        // this.showPoints(zone.vertices, d, pointMaterial)

        // Show all overhang triangles
        // this.showFacets(zone.facets, itemPositions, randomColor)

        for (const b of zone.boundaries) {
          if (
            (b.loop !== undefined && b.loop.length === 0) ||
            (b.hull !== undefined && b.hull.length === 0) ||
            (b.xyOBB !== undefined && b.xyOBB.length !== 4)
          ) {
            continue
          }

          // Calc xyOBB sizes
          // const size1 = b.xyOBB[0].subtract(b.xyOBB[3]).length()
          // const size2 = b.xyOBB[0].subtract(b.xyOBB[1]).length()
          // if (size1 <= this.overhangDimension && size2 <= this.overhangDimension) {
          //   continue
          // }

          // Show boundary vertices
          // this.showPoints(b.loop, d, pointMaterial)

          // Show overhang loop
          this.showLoop(b.loop, randomColor)
          randomColor = Color3.Random()

          // Show hull of overhang zone
          this.showLoop(b.hull, randomColor)

          // Show overhang xyOBB
          // this.showLoop(b.xyOBB, randomColor)

          // Show diameter
          // const diameter: Vector3[] = []
          // diameter.push(zone.diameter.beginPoint)
          // diameter.push(zone.diameter.finalPoint)
          // const dMesh = MeshBuilder.CreateLines(FOOTPRINT, { points: diameter }, this.scene)
          // dMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
          // dMesh.enableEdgesRendering()
          // dMesh.edgesWidth = 10
          // dMesh.edgesColor = Color4.FromColor3(Color3.Black(), 1)
        }
      }
    }
  }

  /**
   * Removes visualization of the overhang zones from the scene
   */
  public clearOverhangZones() {
    // Clear visualized points
    const meshes = this.scene.getMeshesByID(OVERHANG)
    for (const mesh of meshes) {
      this.scene.removeMesh(mesh)
      mesh.dispose()
    }
  }

  /**
   * Cleans up the inner data
   */
  private resetData() {
    // Clean up data
    this.overhangFacets.clear()
    this.overhangVertices.clear()
    this.overhangZones.clear()
  }

  /**
   * Makes reindexing overhang facets
   * @param destVertices Overhang vertices
   * @param sourcePositions Source mesh vertices
   * @param destFacet Overhang facet
   * @param sourceFacet Source mesh facet
   * @param idx Index of current vertex
   * @returns New value of the current index
   */
  private reindexFacet(
    // Collection of destination vertices, collection of sources vertices
    destVertices: Vector3[],
    sourcePositions: Vector3[],
    destFacet: OFacet,
    sourceFacet: OFacet,
    idx: number,
  ): number {
    // Destination facet, source facet, current vertices index
    // Copy current index value as it can't be changed
    let newIdx = idx
    // Check if source vertex has already been stored in the destination collection
    // The tolerance should be used to compare floating point values
    const idxA = destVertices.findIndex((vertex) => {
      return (
        Math.abs(vertex.x - sourcePositions[sourceFacet.a].x) < this.coordTolerance &&
        Math.abs(vertex.y - sourcePositions[sourceFacet.a].y) < this.coordTolerance &&
        Math.abs(vertex.z - sourcePositions[sourceFacet.a].z) < this.coordTolerance
      )
    })
    const idxB = destVertices.findIndex((vertex) => {
      return (
        Math.abs(vertex.x - sourcePositions[sourceFacet.b].x) < this.coordTolerance &&
        Math.abs(vertex.y - sourcePositions[sourceFacet.b].y) < this.coordTolerance &&
        Math.abs(vertex.z - sourcePositions[sourceFacet.b].z) < this.coordTolerance
      )
    })
    const idxC = destVertices.findIndex((vertex) => {
      return (
        Math.abs(vertex.x - sourcePositions[sourceFacet.c].x) < this.coordTolerance &&
        Math.abs(vertex.y - sourcePositions[sourceFacet.c].y) < this.coordTolerance &&
        Math.abs(vertex.z - sourcePositions[sourceFacet.c].z) < this.coordTolerance
      )
    })

    if (idxA === -1) {
      destVertices.push(sourcePositions[sourceFacet.a])
      destFacet.a = newIdx
      newIdx += 1
    } else {
      destFacet.a = idxA
    }

    if (idxB === -1) {
      destVertices.push(sourcePositions[sourceFacet.b])
      destFacet.b = newIdx
      newIdx += 1
    } else {
      destFacet.b = idxB
    }

    if (idxC === -1) {
      destVertices.push(sourcePositions[sourceFacet.c])
      destFacet.c = newIdx
      newIdx += 1
    } else {
      destFacet.c = idxC
    }

    destFacet.e1 = { a: destFacet.a, b: destFacet.b }
    destFacet.e2 = { a: destFacet.b, b: destFacet.c }
    destFacet.e3 = { a: destFacet.c, b: destFacet.a }
    // Return new value of the current index
    return newIdx
  }

  /**
   * Checks if edges are adjucent
   * @param e1 First edge
   * @param e2 Seconf edge
   * @returns 0 - if edges aren't adjucent, 1 - if they are adjecent, -1 - if they are co-edges
   */
  private compareEdges(e1: Edge, e2: Edge): number {
    // Compare edges directoin
    if (e1.a === e2.a && e1.b === e2.b) {
      // e1 and e2 are adjacent but not coedges
      return 1
    }

    if (e1.a === e2.b && e1.b === e2.a) {
      // e1 and e2 are coedges
      return -1
    }

    // e1 and e2 aren't adjacent
    return 0
  }

  /**
   * Chacks if facets are adjucent by edge
   * @param facet1
   * @param facet2
   * @returns True if edges are adjucent
   */
  private isAdjacentByEdge(facet1: OFacet, facet2: OFacet): boolean {
    // Check if facets are adjacent
    if (
      this.compareEdges(facet1.e1, facet2.e1) === -1 ||
      this.compareEdges(facet1.e1, facet2.e2) === -1 ||
      this.compareEdges(facet1.e1, facet2.e3) === -1 ||
      this.compareEdges(facet1.e2, facet2.e1) === -1 ||
      this.compareEdges(facet1.e2, facet2.e2) === -1 ||
      this.compareEdges(facet1.e2, facet2.e3) === -1 ||
      this.compareEdges(facet1.e3, facet2.e1) === -1 ||
      this.compareEdges(facet1.e3, facet2.e2) === -1 ||
      this.compareEdges(facet1.e3, facet2.e3) === -1
    ) {
      return true
    }

    return false
  }

  /**
   * Chacks if facets are adjucent by vertex
   * @param facet1
   * @param facet2
   * @returns True if facets are adjucent
   */
  private isAdjacentByVertex(facet1: OFacet, facet2: OFacet): boolean {
    if (facet1.a === facet2.a || facet1.a === facet2.b || facet1.a === facet2.c) {
      return true
    }

    if (facet1.b === facet2.a || facet1.b === facet2.b || facet1.b === facet2.c) {
      return true
    }

    if (facet1.c === facet2.a || facet1.c === facet2.b || facet1.c === facet2.c) {
      return true
    }

    return false
  }

  /**
   * Searches adjucent facets to group them into one overhang zone
   * @param facet First facet
   * @param ohFacets Collection of overhang facets
   * @param startIdx First facet index
   * @param ohZone Overhang zone to be filled
   * @returns True if factes are adjacent
   */
  private findAdjacents(facet: OFacet, ohFacets: OFacet[], startIdx: number, ohZone: OverhangZone): void {
    // Find all facets adjacent to 'facet'
    for (let n = startIdx; n < ohFacets.length; n += 1) {
      if (ohFacets[n].included) {
        continue
      }

      if (this.isAdjacentByEdge(facet, ohFacets[n])) {
        ohFacets[n].included = true
        ohZone.facets.push(ohFacets[n])
      }
    }
  }

  /**
   * Checks if edge has laminar co-edge in facet
   * @param e Edge
   * @param ohFacets Facet
   * @returns True if the edge has co-edge
   */
  private isLaminar(e: Edge, ohFacets: OFacet[]): boolean {
    // Check if edge is laminar
    for (const ohFacet of ohFacets) {
      if (e === ohFacet.e1 || e === ohFacet.e2 || e === ohFacet.e3) {
        continue
      }

      if (
        this.compareEdges(e, ohFacet.e1) !== 0 ||
        this.compareEdges(e, ohFacet.e2) !== 0 ||
        this.compareEdges(e, ohFacet.e3) !== 0
      ) {
        return false
      }
    }

    return true
  }

  /**
   * Calculates geometric center of lopps
   * @param loop points of loop
   * @returns Array of Vector3
   */
  private calcLoopCenter(loop: Vector3[]): Vector3 {
    const center = Vector3.Zero()
    for (const pos of loop) {
      center.addInPlace(pos)
    }

    center.scaleInPlace(1 / loop.length)
    return center
  }

  /**
   * Transforms source loop to convex one
   * @param loop
   * @returns Array of Vector3
   */
  private buildConvexLoop(loop: Vector3[]): Vector3[] {
    const cross = (a: Vector3, b: Vector3, c: Vector3): number => {
      const u = a.subtract(b)
      const v = c.subtract(b)
      return u.x * v.y - u.y * v.x
    }

    let copyLoop: Vector3[] = []
    copyLoop = copyLoop.concat(loop)

    const loopCenter = this.calcLoopCenter(copyLoop)
    const direction = Math.sign(cross(copyLoop[0], loopCenter, copyLoop[copyLoop.length - 1]))

    for (let i = 0; i < copyLoop.length; i += 1) {
      const first = i % copyLoop.length
      const second = (i + 1) % copyLoop.length
      const third = (i + 2) % copyLoop.length
      const sign = cross(copyLoop[first], copyLoop[second], copyLoop[third]) * direction
      if (sign < 0) {
        copyLoop.splice(second, 1)
      }
    }

    return copyLoop
  }

  /**
   * Selects overhang facets
   */
  private setOverhangFacets() {
    const uniqueOnly = (value, idx, self): boolean => {
      return self.indexOf(value) === idx
    }

    let ohVertices: Vector3[] = []
    let ohFacets: OFacet[] = []

    for (const itemId of ItemsGeometry.overhangGeometry.keys()) {
      ohVertices = []
      ohFacets = []
      const itemNormals = ItemsGeometry.overhangGeometry.get(itemId).normals
      const itemVertices = ItemsGeometry.overhangGeometry.get(itemId).vertices
      const itemFacets = ItemsGeometry.overhangGeometry.get(itemId).facets

      for (let i = 0; i < itemFacets.length; i += 1) {
        const facet = itemFacets[i]
        const normal = itemNormals[i]
        // All normals of facet are directed to -Z (+/- maxAngle)
        if (normal.z < this.zTolerance) {
          // All vertex of facet is upper then bottom surface
          if (
            Math.abs(itemVertices[facet.a].z - this.globalBottomZ) > this.overhangTolerance &&
            Math.abs(itemVertices[facet.b].z - this.globalBottomZ) > this.overhangTolerance &&
            Math.abs(itemVertices[facet.c].z - this.globalBottomZ) > this.overhangTolerance
          ) {
            const ohFacet: OFacet = { a: facet.a, b: facet.b, c: facet.c, included: false }
            ohFacet.e1 = { a: facet.a, b: facet.b }
            ohFacet.e2 = { a: facet.b, b: facet.c }
            ohFacet.e3 = { a: facet.c, b: facet.a }
            ohFacets.push(ohFacet)
            ohVertices.push(itemVertices[facet.a])
            ohVertices.push(itemVertices[facet.b])
            ohVertices.push(itemVertices[facet.c])
          }
        }
      }

      this.overhangVertices.set(itemId, ohVertices)
      this.overhangFacets.set(itemId, ohFacets)
    }
  }

  /**
   * Splits overhang facets to separate zones
   */
  private setOverhangZones() {
    // Split overhang facets onto clusters with connected facets
    for (const itemId of this.overhangFacets.keys()) {
      const itemVertices = ItemsGeometry.overhangGeometry.get(itemId).vertices
      const itemOverhangFacets = this.overhangFacets.get(itemId)
      let facet: OFacet
      const ohZones: OverhangZone[] = []

      for (let i = 0; i < itemOverhangFacets.length; i += 1) {
        const ohZone: OverhangZone = { facets: [], vertices: [], boundaries: [] }

        facet = itemOverhangFacets[i]

        if (!facet.included) {
          facet.included = true
          ohZone.facets.push(facet)
          for (const f of ohZone.facets) {
            this.findAdjacents(f, itemOverhangFacets, i + 1, ohZone)
          }

          // Collect overhang zone vertices
          for (const f of ohZone.facets) {
            ohZone.vertices.push(itemVertices[f.a])
            ohZone.vertices.push(itemVertices[f.b])
            ohZone.vertices.push(itemVertices[f.c])
          }

          ohZones.push(ohZone)
        }
      }

      if (ohZones.length > 0) {
        this.overhangZones.set(itemId, ohZones)
      }
    }
  }

  /**
   * Selects contour points of overhang zones
   */
  private setOverhangLoops() {
    // Find border edges of overhang zones
    for (const itemId of this.overhangZones.keys()) {
      const itemVertices = ItemsGeometry.overhangGeometry.get(itemId).vertices
      const itemOverhangZones = this.overhangZones.get(itemId)

      for (const ohZone of itemOverhangZones) {
        // Hold all laminar edges as an unordered sequence
        // based on vertex indices as key
        const laminarEdges: Map<number, Edge> = new Map<number, Edge>()
        const ohFacets = ohZone.facets
        for (const ohFacet of ohFacets) {
          if (this.isLaminar(ohFacet.e1, ohFacets)) {
            laminarEdges.set(ohFacet.e1.a, ohFacet.e1)
          }

          if (this.isLaminar(ohFacet.e2, ohFacets)) {
            laminarEdges.set(ohFacet.e2.a, ohFacet.e2)
          }

          if (this.isLaminar(ohFacet.e3, ohFacets)) {
            laminarEdges.set(ohFacet.e3.a, ohFacet.e3)
          }
        }

        // Traverce laminar edges
        interrupt: do {
          // Hold boundary vertices
          const boundary: Loop = { loop: [] }
          const [firstVertexIdx] = laminarEdges.keys()
          let lEdge = laminarEdges.get(firstVertexIdx)
          boundary.loop.push(itemVertices[lEdge.a])
          laminarEdges.delete(firstVertexIdx)
          do {
            lEdge = laminarEdges.get(lEdge.b)
            if (lEdge === undefined) {
              // This is a temporary stub to avoid exception
              // Should be clarify why one of the edges has two next ones
              if (laminarEdges.size > 0) {
                const vIndices = laminarEdges.keys()
                for (const idx of vIndices) {
                  boundary.loop.push(itemVertices[idx])
                }
              }

              if (boundary.loop.length > 0) {
                ohZone.boundaries.push(boundary)
              }

              break interrupt
            }

            boundary.loop.push(itemVertices[lEdge.a])
            laminarEdges.delete(lEdge.a)
          } while (lEdge.b !== firstVertexIdx)

          ohZone.boundaries.push(boundary)
        } while (laminarEdges.size > 0)
      }
    }

    // Find overhang zones hull and xyOBB
    for (const itemId of this.overhangZones.keys()) {
      const itemOverhangZones = this.overhangZones.get(itemId)

      for (const ovZone of itemOverhangZones) {
        for (const boundary of ovZone.boundaries) {
          // Build convex hull of boundary
          // boundary.hull = this.buildConvexHull(boundary.loop)

          // Build XY projection of boundary convex hull
          // const hull = this.buildConvexHull(boundary.loop)
          // boundary.hull = []
          // for (const point of hull) {
          //   boundary.hull.push(new Vector3(point.x, point.y, this.globalBottomZ))
          // }

          // Build convex loop - remove vertices that are the center point of straight angles
          // TODO: This is currently the best visual representation of the overhang boundary.
          // It can be improved with getting rid from 'hide' edges.
          // To do this, the non-convex vertex shouldn't be removed,
          // but replaced with another vertex from the same facet.
          boundary.hull = this.buildConvexLoop(boundary.loop)

          // Build xyOBB of boundary
          const bbXY = this.getOBB(boundary.hull)
          boundary.xyOBB = []
          for (const point of bbXY) {
            boundary.xyOBB.push(new Vector3(point.x, point.y, this.globalBottomZ))
          }
        }
      }
    }

    this.calcLoopsDiameter()
  }

  /**
   * Calculates diameter of overhang loops
   */
  private calcLoopsDiameter() {
    const itemsId = this.overhangZones.keys()
    for (const key of itemsId) {
      const ohZones = this.overhangZones.get(key)
      for (const zone of ohZones) {
        if (zone.boundaries.length === 0) {
          continue
        }

        const diameter: Diameter = { value: -1, beginPoint: Vector3.Zero(), finalPoint: Vector3.Zero() }
        for (const boundary of zone.boundaries) {
          let loop: Vector3[] = []
          loop = loop.concat(boundary.loop)

          while (loop.length > 0) {
            const point = loop.pop()
            for (const p of loop) {
              const dist = point.subtract(p).length()
              if (dist > diameter.value) {
                diameter.value = dist
                diameter.beginPoint = point
                diameter.finalPoint = p
              }
            }
          }

          zone.diameter = diameter
        }
      }
    }
  }
}
