import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import StoresNamespaces from '@/store/namespaces'

import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import { IUser } from '@/types/User/IUser'
import { ItemType } from '@/types/FileExplorer/ItemType'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const userStore = namespace(StoresNamespaces.User)

@Component
export default class UnshareUserMixin extends Vue {
  @userStore.Getter('getUserDetails') userDetails: IUser

  @fileExplorerStore.Action fetchItemByIdWithoutAddingToState: (itemId: string) => Promise<FileExplorerItem>

  @userStore.State('users') users: any

  async shouldDeletePermission(
    name: string,
    isCurrentUser: boolean = false,
    dialog: InstanceType<typeof ConfirmModal>,
    hasAnotherParentPermissions: boolean = false,
  ) {
    const modalTitle = this.$root.$t('removeFromCollaborationDialog.title')
    let modalMessage

    if (isCurrentUser) {
      modalMessage = `<p class="text t-14">${this.$root.$t('removeFromCollaborationDialog.removeYourselfLabel')}</p>`
    } else {
      modalMessage = hasAnotherParentPermissions
        ? `<p class="text t-14">${this.$root.$t('removeFromCollaborationDialog.removeWithParent', {
            collaboratorName: name,
          })}</p>`
        : `<p class="text t-14">${this.$root.$t('removeFromCollaborationDialog.label', {
            collaboratorName: name,
          })}</p>`
    }

    return await dialog.open(modalTitle, modalMessage)
  }

  async shouldDeleteInheritedPermission(
    name: string,
    inheritedFrom: string,
    dialog: InstanceType<typeof ConfirmModal>,
  ): Promise<boolean> {
    const parentItem: FileExplorerItem = await this.fetchItemByIdWithoutAddingToState(inheritedFrom)
    // if permissions inherited from 'Sinter plan/Build Plan' then we are trying to remove
    // permissions for Sinter Part or some of the plan variant, and there is no need to warn user about it
    if (parentItem.itemType === ItemType.BuildPlan) {
      return true
    }
    const modalTitle = this.$root.$t('removeFromCollaborationDialog.fromParentTitle')
    const modalMessage = `<p class="text t-14">${this.$root.$t('removeFromCollaborationDialog.fromParentLabel', {
      collaboratorName: name,
      parentFolderName: parentItem.name,
    })}</p>`

    return await dialog.open(modalTitle, modalMessage)
  }

  async shouldChangeParentPermission(
    collaboratorName: string,
    parentPermissionItem: FileExplorerItem,
    dialog: InstanceType<typeof ConfirmModal>,
  ): Promise<boolean> {
    // if permissions inherited from 'Sinter plan/Build Plan' then we are trying to change
    // permissions for Sinter Part or some of the plan variant, and there is no need to warn user about it
    if (parentPermissionItem.itemType === ItemType.BuildPlan) {
      return true
    }

    const modalTitle = this.$root.$t('changeFromCollaborationDialog.fromParentTitle')
    const modalMessage = `<p class="text t-14">${this.$root.$t('changeFromCollaborationDialog.fromParentLabel', {
      collaboratorName,
      parentFolderName: parentPermissionItem.name,
    })}</p>`

    return await dialog.open(modalTitle, modalMessage)
  }
}
