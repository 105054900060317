import Vue, { VNode } from 'vue'
import { DirectiveBinding } from 'vue/types/options'

const CHARS_FOR_DELIMITER = 5
const DOM_UPDATE_DELAY = 25

Vue.directive('middle-truncate', {
  inserted: (el, bindings, vnode) => truncate(el, bindings, vnode),
  update: (el, bindings, vnode) => truncate(el, bindings, vnode),
})

function truncate(el: HTMLElement, bindings: DirectiveBinding, vnode: VNode) {
  vnode.context.$nextTick(async () => {
    const { value, oldValue } = bindings

    if (value !== oldValue) {
      el.textContent = value
      // Wait for dom update in order to get actual width of elements
      // Next tick doesn't work
      let done
      const domUpdatePromise = new Promise((resolve) => (done = resolve))
      setTimeout(() => done(), DOM_UPDATE_DELAY)
      await domUpdatePromise
    }

    const parentEl = el.parentElement
    const parentWidth = Math.ceil(parentEl.getBoundingClientRect().width)
    const elWidth = Math.ceil(el.getBoundingClientRect().width)

    if (elWidth === 0 || parentWidth === 0) {
      return
    }

    if (elWidth > parentWidth) {
      const str = value as string
      const chars = str.length
      const charSize = elWidth / chars
      const canFitChars = parentWidth / charSize
      const charsToDeleteFromEachSide = (chars - canFitChars + CHARS_FOR_DELIMITER) / 2
      const endLeft = Math.floor(chars / 2 - charsToDeleteFromEachSide)
      const startRight = Math.ceil(chars / 2 + charsToDeleteFromEachSide)
      el.textContent = `${str.substr(0, endLeft)}...${str.substr(startRight)}`
    }
  })
}
