
/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import * as msgpack from '@ygoe/msgpack'
import cloneDeep from 'lodash/cloneDeep'

import buildPlans from '@/api/buildPlans'
import NumberField from '@/components/controls/Common/NumberField.vue'
import TextField from '@/components/controls/Common/TextField.vue'
import IconButton from '@/components/controls/Common/IconButton.vue'
import OrientDataAnalysisMap from '@/components/layout/buildPlans/orientation/OrientDataAnalysisMap.vue'
import messageService from '@/services/messageService'
import StoresNamespaces from '@/store/namespaces'
import {
  IBuildPlan,
  IBuildPlanItem,
  ISelectable,
  IOrientCriteria,
  GeometryType,
  IDisplayToolbarState,
  SelectionUnit,
} from '@/types/BuildPlans/IBuildPlan'
import { IConstraints } from '@/types/BuildPlans/IConstraints'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import { IJob } from '@/types/PartsLibrary/Job'
import {
  DEFAULT_SELF_SUPPORTING_ANGLE,
  KEYBOARD_KEY_CODES,
  ORIENT_PREFERRED_SURFACE_COLOR,
  ORIENT_PROHIBITED_SURFACE_COLOR,
} from '@/constants'
import OrientResult from '@/types/orientation/OrientResult'
import {
  ChangePartOrientationCommand,
  ChangePartOrientationCommandPayload,
} from '@/types/UndoRedo/ChangePartOrientationCommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { Vector3 } from '@babylonjs/core/Maths'
import { OrientHelper, Orientation } from '@/components/layout/buildPlans/orientation/orientHelper'
import { formatDecimal, roundUptoDecimalPlaces } from '@/utils/number'
import { v4 as uuidv4 } from 'uuid'
import { MultiItemCollector } from '@/types/OptionalMultiItemCollector/MultiItemCollector'
import OptionalMultiItemCollector from '@/components/layout/buildPlans/OptionalMultiItemCollector.vue'
import { SelectedItem } from '@/types/OptionalMultiItemCollector/SelectedItem'
import { CollectorItem } from '@/visualization/rendering/Collector'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const commandManager = namespace(StoresNamespaces.CommandManager)
const optionalMultiItemCollectorStore = namespace(StoresNamespaces.OptionalMultiItemCollector)
const commonStore = namespace(StoresNamespaces.Common)

@Component({
  components: { NumberField, OrientDataAnalysisMap, TextField, IconButton, OptionalMultiItemCollector },
})
export default class BuildPlanOrientationTab extends Vue implements IToolComponent {
  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter('getIsLoading') isGeometryLoading: boolean
  @buildPlansStore.Getter('getSelectedParts') selectedItems: ISelectable[]
  @buildPlansStore.Getter('getSelectedOrientIndex') selectedOrientIndex: number
  @buildPlansStore.Getter('isOrientReadOnly') isOrientReadOnly: boolean
  @buildPlansStore.Getter displayToolbarStateByVariantId: (buildPlanId: string) => IDisplayToolbarState
  @buildPlansStore.Getter getCommandType: CommandType
  @buildPlansStore.Getter('getSelectedBuildPlanFinalizingJobs') getSelectedBuildPlanFinalizingJobs: IJob[]
  @buildPlansStore.Action('setSelectionMode') setSelectionMode: Function
  @buildPlansStore.Mutation('setSelectedOrientationIndex') setSelectedOrientationIndex: Function
  @buildPlansStore.Action('updateBuildPlanItem') updateBuildPlanItem: Function
  @buildPlansStore.Action('removeSupports') removeSupports: Function

  @visualizationStore.Getter('getFacetDataByFaceId') getFacetDataByFaceId: Function
  @visualizationStore.Mutation('savePartOrientation') savePartOrientation: Function
  @visualizationStore.Mutation('rotatePart') rotatePart: Function
  @visualizationStore.Mutation('setGeometriesVisibility') setGeometriesVisibility: Function
  @visualizationStore.Mutation('clearSupports') clearSupports: Function
  @visualizationStore.Mutation('clearOverhangMesh') clearOverhangMesh: Function
  @visualizationStore.Mutation('clearSelection') clearSelection: Function
  @visualizationStore.Mutation('setOverhangAreasAngle') setOverhangAreasAngle: Function
  @visualizationStore.Getter('getIsSelectedPartBrep') getIsSelectedPartBrep: Function
  @visualizationStore.Getter('getPartZTranslation') getPartZTranslation: Function
  @visualizationStore.Getter('getTransformationMatrixByBuildPlanItemId') getTransformationMatrixByBuildPlanItemId: (
    buildPlanItemId: string,
  ) => number[]

  @optionalMultiItemCollectorStore.Getter getCollectorById: (id: string) => MultiItemCollector
  @optionalMultiItemCollectorStore.Mutation deleteCollector: (id: string) => void
  @optionalMultiItemCollectorStore.Mutation enableColoringForCollector: (id: string) => void
  @optionalMultiItemCollectorStore.Mutation disableColoringForCollector: (id: string) => void

  @commandManager.Mutation addCommand: (command: ICommand) => void
  @commonStore.Getter tooltipOpenDelay: number

  openedSections = {
    overhang: true,
    orientations: true,
    analysisResults: true,
  }

  isToolMounted: boolean = false
  orientations: OrientResult[] = []
  showProgressBar = true
  overhangAngle: string = DEFAULT_SELF_SUPPORTING_ANGLE.toString()
  currentAppliedOrientationIndex: number = null
  selectedBuildPlanItem: IBuildPlanItem
  hasErrors: boolean = false
  errorString: string = ''
  importedOrientationIndex: number = null
  lastSavedOrientationIndex: number = null
  initialState: ChangePartOrientationCommandPayload
  zTranslation: number = null
  isPreferredSelected: boolean = false
  isProhibitedSelected: boolean = false
  trianglesProhibitedSupport: Vector3[][][] = []
  trianglesPreferredSupport: Vector3[][][] = []
  selectedPreferredFaces: string[] = []
  selectedProhibitedFaces: string[] = []
  debounceTimer: NodeJS.Timeout = null
  orientationWithSurfacePercentage: Orientation[] = []
  isHaveOrientationGraph: boolean = true
  isPageMounted: boolean = false
  lastSavedRotationAngles: number[] = [32, 88, 50]
  currentRequestId: string
  preferredSurfacesMultiItemCollector: MultiItemCollector = null
  prohibitedSurfacesMultiItemCollector: MultiItemCollector = null
  scrollId: string = ''
  itemFilter = null
  isCancelOrOkClicked = false

  get supportAngleCustomMessages() {
    return {
      min_value: this.$t('orientSupportAngleValidationMessage'),
      max_value: this.$t('orientSupportAngleValidationMessage'),
    }
  }

  get preferredSurfacesCollector() {
    if (this.preferredSurfacesMultiItemCollector) {
      return this.getCollectorById(this.preferredSurfacesMultiItemCollector.id)
    }
    return null
  }

  get prohibitedSurfacesCollector() {
    if (this.prohibitedSurfacesMultiItemCollector) {
      return this.getCollectorById(this.prohibitedSurfacesMultiItemCollector.id)
    }
    return null
  }

  get isSurfaceCollectorReadOnly() {
    if (this.isOrientReadOnly) {
      return true
    }

    if (this.selectedBuildPlanItem && this.selectedBuildPlanItem.part != null) {
      if (this.getIsSelectedPartBrep()) {
        return false
      }
    }

    return true
  }

  get orientdisplayToolbarState() {
    const recoaterDirectionShader: boolean = this.displayToolbarStateByVariantId(
      this.buildPlan.id,
    ).isShowingRecoaterDirectionShader
    const overhangAreaShader: boolean = this.displayToolbarStateByVariantId(
      this.buildPlan.id,
    ).isShowingOverhangAreasShader
    return recoaterDirectionShader || overhangAreaShader
  }

  getOkName() {
    return 'Save'
  }

  getSupportLimitAngleRules() {
    return {
      required: true,
      min_value: 0,
      max_value: 90,
    }
  }

  getRecoaterLimitAngleRules() {
    return {
      required: true,
      min_value: 0,
      max_value: 90,
    }
  }

  toggleSection(name: string) {
    if (this.openedSections[name] !== undefined) {
      this.openedSections[name] = !this.openedSections[name]
    }
  }

  @Watch('preferredSurfacesCollector', { deep: true })
  preferredSurfacesChanged(newVal, oldVal) {
    if (this.isToolMounted) {
      this.trianglesPreferredSupport = []
      if (
        this.preferredSurfacesCollector &&
        this.preferredSurfacesCollector.items &&
        this.preferredSurfacesCollector.items.length
      ) {
        this.selectedPreferredFaces = []
        this.preferredSurfacesCollector.items.forEach((preferredItem) => {
          this.selectedPreferredFaces.push(preferredItem.faceName)
          const facetData = this.getFacetDataByFaceId(
            preferredItem.componentId,
            preferredItem.geometryId,
            this.selectedBuildPlanItem.id,
            preferredItem.faceName,
          )
          if (facetData && facetData.positions) {
            this.trianglesPreferredSupport.push(facetData.positions)
          }
        })
      }
      this.debounceInput()
    }
  }

  @Watch('prohibitedSurfacesCollector', { deep: true })
  prohibitedSurfacesChanged(newVal, oldVal) {
    if (this.isToolMounted) {
      this.trianglesProhibitedSupport = []
      if (
        this.prohibitedSurfacesCollector &&
        this.prohibitedSurfacesCollector.items &&
        this.prohibitedSurfacesCollector.items.length
      ) {
        this.selectedProhibitedFaces = []
        this.prohibitedSurfacesCollector.items.forEach((prohibitedItem) => {
          this.selectedProhibitedFaces.push(prohibitedItem.faceName)
          const facetData = this.getFacetDataByFaceId(
            prohibitedItem.componentId,
            prohibitedItem.geometryId,
            this.selectedBuildPlanItem.id,
            prohibitedItem.faceName,
          )
          if (facetData && facetData.positions) {
            this.trianglesProhibitedSupport.push(facetData.positions)
          }
        })
      }
      this.debounceInput()
    }
  }

  @Watch('orientdisplayToolbarState')
  orientdisplayToolbarStateChanged(newVal, oldVal) {
    if (this.isToolMounted) {
      if (!this.orientdisplayToolbarState) {
        // TODO: Need to figure out a better way to solve the timeout
        setTimeout(() => {
          this.enableColoringForCollector(this.preferredSurfacesCollector.id)
          this.enableColoringForCollector(this.prohibitedSurfacesCollector.id)
        }, 200)
      }
    }
  }

  isOrientationChanged(): boolean {
    if (!this.orientationsExists(this.selectedBuildPlanItem) && this.currentAppliedOrientationIndex == null) {
      return false
    }

    if (this.currentAppliedOrientationIndex == null) {
      return false
    }

    if (this.orientationsExists(this.selectedBuildPlanItem)) {
      if (this.currentAppliedOrientationIndex === this.lastSavedOrientationIndex) {
        return false
      }

      if (this.isHaveSameAngle(this.lastSavedOrientationIndex, this.importedOrientationIndex)) {
        if (this.currentAppliedOrientationIndex === this.importedOrientationIndex) {
          return false
        }
      }
    }

    return true
  }

  debounceInput() {
    if (!this.overhangAngle) {
      return
    }

    const overhangAngle = Number.parseFloat(this.overhangAngle)
    if (isNaN(overhangAngle) || !this.isValidSupportLimitAngle(overhangAngle)) {
      this.$emit('setOkDisabled', true)
      return
    }

    this.setOverhangAreasAngle(overhangAngle)
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer)
    }

    this.debounceTimer = setTimeout(() => {
      if (this.isPageMounted) {
        this.$emit('setOkDisabled', true)
        this.loadOrient(overhangAngle)
      }
      this.isPageMounted = true
    }, 1000)
  }

  isHaveOrientationSolution(orientation: Orientation[]): boolean {
    if (orientation.length <= 0) {
      return true
    }
    const haveValidPercentageRecord = orientation.filter((item) => item.prohibitedSupportSurfaceCoverage === 1)
    return haveValidPercentageRecord.length > 0 ? true : false
  }

  beforeMount() {
    this.itemFilter = (item: CollectorItem) => {
      return item.itemMetadata.buildPlanItemId === this.selectedItems[0].id
    }
    const preferredSurfaceCollectorId = uuidv4()
    const prohibitedSurfaceCollectorId = uuidv4()

    let preferredItems: SelectedItem[] = []
    let prohibitedItems: SelectedItem[] = []

    if (this.selectedItems.length) {
      this.selectedBuildPlanItem = this.buildPlan.buildPlanItems.find((item) => item.id === this.selectedItems[0].id)
    }
    if (this.orientationsExists(this.selectedBuildPlanItem)) {
      preferredItems = this.selectedBuildPlanItem.orientCriteria.preferredItems
        ? this.selectedBuildPlanItem.orientCriteria.preferredItems
        : []

      preferredItems.forEach((preferredItem) => {
        preferredItem.buildPlanItemId = this.selectedBuildPlanItem.id
      })

      prohibitedItems = this.selectedBuildPlanItem.orientCriteria.prohibitedItems
        ? this.selectedBuildPlanItem.orientCriteria.prohibitedItems
        : []

      prohibitedItems.forEach((prohibitedItem) => {
        prohibitedItem.buildPlanItemId = this.selectedBuildPlanItem.id
      })
    }

    this.preferredSurfacesMultiItemCollector = {
      id: preferredSurfaceCollectorId,
      type: SelectionUnit.FaceAndEdge,
      selectionColor: ORIENT_PREFERRED_SURFACE_COLOR,
      items: preferredItems,
    }

    this.prohibitedSurfacesMultiItemCollector = {
      id: prohibitedSurfaceCollectorId,
      type: SelectionUnit.FaceAndEdge,
      selectionColor: ORIENT_PROHIBITED_SURFACE_COLOR,
      items: prohibitedItems,
    }

    // triangles information for preferred pre selected surfaces
    this.trianglesPreferredSupport = []
    this.selectedPreferredFaces = []
    preferredItems.forEach((preferredItem) => {
      this.selectedPreferredFaces.push(preferredItem.faceName)
      const facetData = this.getFacetDataByFaceId(
        preferredItem.componentId,
        preferredItem.geometryId,
        this.selectedBuildPlanItem.id,
        preferredItem.faceName,
      )
      if (facetData && facetData.positions) {
        this.trianglesPreferredSupport.push(facetData.positions)
      }
    })

    // triangles information for prohibited pre selected surfaces
    this.trianglesProhibitedSupport = []
    this.selectedProhibitedFaces = []
    prohibitedItems.forEach((prohibitedItem) => {
      this.selectedProhibitedFaces.push(prohibitedItem.faceName)
      const facetData = this.getFacetDataByFaceId(
        prohibitedItem.componentId,
        prohibitedItem.geometryId,
        this.selectedBuildPlanItem.id,
        prohibitedItem.faceName,
      )
      if (facetData && facetData.positions) {
        this.trianglesProhibitedSupport.push(facetData.positions)
      }
    })
  }

  beforeDestroy() {
    if (this.preferredSurfacesCollector) this.deleteCollector(this.preferredSurfacesCollector.id)
    if (this.prohibitedSurfacesCollector) this.deleteCollector(this.prohibitedSurfacesCollector.id)
    if (!this.isCancelOrOkClicked && !this.isOrientReadOnly) {
      this.clickCancel()
    }
  }

  async mounted() {
    this.$emit('mounted')
    if (this.selectedItems.length) {
      this.selectedBuildPlanItem = this.buildPlan.buildPlanItems.find((item) => item.id === this.selectedItems[0].id)
      this.overhangAngle = this.selectedBuildPlanItem.overhangAngle
        ? this.selectedBuildPlanItem.overhangAngle.toString()
        : this.overhangAngle
    }

    this.getLastSavedRotationAngles()
    this.saveInitialState()
    this.updateOkStatus()

    let selectedItemInPlan: IBuildPlanItem = null
    const translations: Vector3[] = []

    this.selectedItems.forEach((part) => {
      selectedItemInPlan = this.buildPlan.buildPlanItems.find((item) => item.id === part.id)
      if (!selectedItemInPlan) {
        return
      }
      translations.push(part.translation)
    })

    this.zTranslation = this.getPartZTranslation(this.selectedBuildPlanItem.id)
    this.isToolMounted = true

    // do not await
    this.loadOrient(Number.parseFloat(this.overhangAngle), true)
  }

  getLastSavedRotationAngles() {
    const transformationMatrix = this.selectedBuildPlanItem.transformationMatrix
    // calculate rotation angles from this transformation matrix
    // asin function accepts values in range of -1 and 1, else it returns NaN
    // So one way is to round the number to 3 decimal places and then proceed.
    const yPoint: number =
      transformationMatrix[8] < -1 || transformationMatrix[8] > 1
        ? Math.round(transformationMatrix[8] * 1000) / 1000
        : transformationMatrix[8]

    // Note: yPoint value should be in between -1 and 1. If not, then the transformation matrix has incorrect data
    // yRot will be NaN in such cases
    const yRot = Math.asin(yPoint)
    const xRot = Math.atan2(-transformationMatrix[9] / Math.cos(yRot), transformationMatrix[10] / Math.cos(yRot))
    const zRot = Math.atan2(-transformationMatrix[4] / Math.cos(yRot), transformationMatrix[0] / Math.cos(yRot))

    this.lastSavedRotationAngles[0] = -formatDecimal((xRot * 180) / Math.PI).toLocaleString()
    this.lastSavedRotationAngles[1] = -formatDecimal((yRot * 180) / Math.PI).toLocaleString()
    this.lastSavedRotationAngles[2] = -formatDecimal((zRot * 180) / Math.PI).toLocaleString()

    this.lastSavedRotationAngles[0] =
      this.lastSavedRotationAngles[0] < 0 ? this.lastSavedRotationAngles[0] + 360 : this.lastSavedRotationAngles[0]
    this.lastSavedRotationAngles[1] =
      this.lastSavedRotationAngles[1] < 0 ? this.lastSavedRotationAngles[1] + 360 : this.lastSavedRotationAngles[1]
    this.lastSavedRotationAngles[2] =
      this.lastSavedRotationAngles[2] < 0 ? this.lastSavedRotationAngles[2] + 360 : this.lastSavedRotationAngles[2]
  }

  async loadOrient(overhangAngle?: number, isOrientLoaded = false) {
    if (overhangAngle && !this.isValidSupportLimitAngle(overhangAngle)) {
      return
    }

    if (this.selectedBuildPlanItem) {
      this.showProgressBar = true
      let fetchOrientResults = true

      if (this.orientationsExists(this.selectedBuildPlanItem)) {
        if (isOrientLoaded) {
          this.orientations = [...this.selectedBuildPlanItem.orientCriteria.orientations]

          this.lastSavedOrientationIndex = this.orientations.length - 1
          this.importedOrientationIndex = this.orientations.length - 2

          this.selectedPreferredFaces = this.selectedBuildPlanItem.orientCriteria.selectedPreferredFaces
            ? this.selectedBuildPlanItem.orientCriteria.selectedPreferredFaces
            : []
          this.selectedProhibitedFaces = this.selectedBuildPlanItem.orientCriteria.selectedProhibitedFaces
            ? this.selectedBuildPlanItem.orientCriteria.selectedProhibitedFaces
            : []
          this.overhangAngle = this.selectedBuildPlanItem.orientCriteria.supportAngle.toString()

          if (this.overhangAngle === DEFAULT_SELF_SUPPORTING_ANGLE.toString()) {
            this.isPageMounted = true
          }

          const orientationLastSaved = this.orientations[this.lastSavedOrientationIndex]
          if (orientationLastSaved != null) {
            const degreeToRadianMultiplier = Math.PI / 180
            const decimalPlaces = 4
            const lastSavedCriteriaX = roundUptoDecimalPlaces(
              Math.tan(orientationLastSaved.x * degreeToRadianMultiplier),
              decimalPlaces,
            )
            const lastSavedCriteriaY = roundUptoDecimalPlaces(
              Math.tan(orientationLastSaved.y * degreeToRadianMultiplier),
              decimalPlaces,
            )
            const lastSavedCriteriaZ = roundUptoDecimalPlaces(
              Math.tan(orientationLastSaved.z * degreeToRadianMultiplier),
              decimalPlaces,
            )

            const lastSavedX = roundUptoDecimalPlaces(
              Math.tan(this.lastSavedRotationAngles[0] * degreeToRadianMultiplier),
              decimalPlaces,
            )
            const lastSavedY = roundUptoDecimalPlaces(
              -Math.tan(this.lastSavedRotationAngles[1] * degreeToRadianMultiplier),
              decimalPlaces,
            )
            const lastSavedZ = roundUptoDecimalPlaces(
              Math.tan(this.lastSavedRotationAngles[2] * degreeToRadianMultiplier),
              decimalPlaces,
            )

            if (
              (this.lastSavedRotationAngles[0] === orientationLastSaved.x &&
                this.lastSavedRotationAngles[1] === orientationLastSaved.y &&
                this.lastSavedRotationAngles[2] === orientationLastSaved.z) ||
              (lastSavedCriteriaX === lastSavedX &&
                lastSavedCriteriaY === lastSavedY &&
                lastSavedCriteriaZ === lastSavedZ)
            ) {
              this.lastSavedRotationAngles[0] = orientationLastSaved.x
              this.lastSavedRotationAngles[1] = orientationLastSaved.y
              this.lastSavedRotationAngles[2] = orientationLastSaved.z
              fetchOrientResults = false
            } else {
              fetchOrientResults = true
            }
          }
        } else {
          if (!overhangAngle) {
            fetchOrientResults = false
          }
        }
      } else {
        this.isPageMounted = true
      }

      if (fetchOrientResults) {
        const orientOverhangAngle = isOrientLoaded ? Number.parseFloat(this.overhangAngle) : overhangAngle
        if (this.trianglesProhibitedSupport.length !== 0 || this.trianglesPreferredSupport.length !== 0) {
          const orientHelper = new OrientHelper()
          this.orientationWithSurfacePercentage = orientHelper.evaluateValidOrientations(
            orientOverhangAngle,
            this.trianglesProhibitedSupport,
            this.trianglesPreferredSupport,
            this.lastSavedRotationAngles,
          )
        } else {
          this.orientationWithSurfacePercentage = []
        }

        this.currentRequestId = uuidv4()
        let orientBuffer
        try {
          orientBuffer = await buildPlans.orient(
            this.buildPlan.id,
            orientOverhangAngle,
            this.selectedBuildPlanItem.id,
            this.orientationWithSurfacePercentage.map((a) => a.isValid),
            this.lastSavedRotationAngles,
            this.currentRequestId,
          )
        } catch (e) {
          messageService.showErrorMessage(
            `${this.$i18n.t('orientFileIssuesError', [this.selectedBuildPlanItem.part.name])}`,
          )
          this.$emit('closeTool')
          return
        }
        if (typeof orientBuffer === 'string') {
          this.errorString = orientBuffer
          this.hasErrors = true
          this.showProgressBar = false
          return
        }
        if (orientBuffer.requestId === this.currentRequestId) {
          const orientResult = msgpack.deserialize(orientBuffer.stream) as OrientResult[]
          this.orientations = this.addProhibitedAndPreferredPercentage(orientResult)
          this.lastSavedOrientationIndex = this.orientations.length - 1
          this.importedOrientationIndex = this.orientations.length - 2
          if (this.orientations.length === 2) {
            this.isHaveOrientationGraph = false
          } else {
            this.isHaveOrientationGraph = true
          }
          this.showProgressBar = false
        }
      } else {
        this.showProgressBar = false
      }
      this.updateOkStatus()
    }
  }

  saveInitialState(): void {
    this.initialState = cloneDeep({
      orientCriteria: {
        orientations: this.orientations,
        appliedOrientationIndex: this.lastSavedOrientationIndex
          ? this.lastSavedOrientationIndex
          : this.importedOrientationIndex,
        supportAngle: Number.parseFloat(this.overhangAngle),
        selectedPreferredFaces: this.selectedPreferredFaces,
        selectedProhibitedFaces: this.selectedProhibitedFaces,
        prohibitedItems: this.prohibitedSurfacesCollector ? this.prohibitedSurfacesCollector.items : null,
        preferredItems: this.preferredSurfacesCollector ? this.preferredSurfacesCollector.items : null,
      },
      constraints: this.selectedBuildPlanItem.constraints,
      partItemIndex: this.selectedBuildPlanItem.id,
      transformation: this.selectedBuildPlanItem.transformationMatrix,
    })
  }

  orientPart(orientationIndex: number, peek: boolean, transformation?: number[]) {
    if (!peek) {
      this.currentAppliedOrientationIndex = orientationIndex
    }

    if (this.displayToolbarStateByVariantId(this.buildPlan.id).isShowingSupportGeometry && !this.isOrientReadOnly) {
      this.setGeometriesVisibility({
        items: [{ buildPlanItemId: this.selectedBuildPlanItem.id }],
        geometryType: GeometryType.Support,
        visibility: false,
      })
    }

    const x = this.orientations[orientationIndex].x
    const y = this.orientations[orientationIndex].y
    const z = this.orientations[orientationIndex].z

    if (transformation) {
      this.rotatePart({
        x,
        y,
        z,
        transformation,
        partItemIndex: this.selectedBuildPlanItem.id,
        zTranslation: this.zTranslation,
      })
    } else {
      this.rotatePart({ x, y, z, partItemIndex: this.selectedBuildPlanItem.id, zTranslation: this.zTranslation })
    }
  }

  /**************************************
   * Generic tool method implementations
   **************************************/

  async clickOk() {
    this.isCancelOrOkClicked = true
    if (this.showProgressBar) {
      return
    }

    if (!this.hasErrors) {
      this.$emit('creatingJob') // to have spinner
      let appliedOrientIndex = 0
      if (!this.currentAppliedOrientationIndex) {
        appliedOrientIndex = this.lastSavedOrientationIndex
      } else {
        appliedOrientIndex = this.currentAppliedOrientationIndex
      }
      try {
        this.orientations[this.lastSavedOrientationIndex] = this.orientations[appliedOrientIndex]
        const orientData: IOrientCriteria = {
          orientations: this.orientations,
          appliedOrientationIndex: appliedOrientIndex,
          supportAngle: Number.parseFloat(this.overhangAngle),
          selectedPreferredFaces: this.selectedPreferredFaces,
          selectedProhibitedFaces: this.selectedProhibitedFaces,
          prohibitedItems: this.prohibitedSurfacesCollector ? this.prohibitedSurfacesCollector.items : null,
          preferredItems: this.preferredSurfacesCollector ? this.preferredSurfacesCollector.items : null,
        }
        const buildPlanItemConstraints: IConstraints = this.selectedBuildPlanItem.constraints
        buildPlanItemConstraints.rotation.x = true
        buildPlanItemConstraints.rotation.y = true
        buildPlanItemConstraints.rotation.z = true
        const newTransformationMatrix = this.getTransformationMatrixByBuildPlanItemId(this.selectedBuildPlanItem.id)
        const payload: ChangePartOrientationCommandPayload = cloneDeep({
          orientCriteria: orientData,
          constraints: buildPlanItemConstraints,
          partItemIndex: this.selectedBuildPlanItem.id,
          transformation: newTransformationMatrix,
          zTranslation: this.zTranslation,
        })
        const orientCommand = new ChangePartOrientationCommand(
          this.getCommandType,
          this.selectedBuildPlanItem.id,
          this.initialState,
          payload,
          this.$store.dispatch,
          this.$store.commit,
        )

        await this.removeSupports({
          params: { buildPlanItemId: this.selectedBuildPlanItem.id },
          hideAPIErrorMessages: true,
        })

        this.clearSupports({ buildPlanItemId: this.selectedBuildPlanItem.id, skipGeomProps: true })
        this.clearOverhangMesh(this.selectedBuildPlanItem.id)

        // only update state in store
        this.updateBuildPlanItem({
          params: {
            orientCriteria: orientData,
            buildPlanItemId: this.selectedBuildPlanItem.id,
            constraints: buildPlanItemConstraints,
            updateStateOnly: true,
          },
        })

        // this method will trigger api call to update the item
        await this.savePartOrientation({
          partItemIndex: this.selectedBuildPlanItem.id,
        })

        this.addCommand(orientCommand)
        this.setSelectedOrientationIndex(this.currentAppliedOrientationIndex)
        this.$emit('jobCreated') // to remove spinner
      } catch (error) {
        messageService.showErrorMessage(error.message)
        this.$emit('jobCreated') // to remove spinner
      }
    }
  }

  clickCancel() {
    this.isCancelOrOkClicked = true
    if (this.showProgressBar) {
      return
    }

    if (!this.hasErrors) {
      this.$emit('creatingJob') // to have spinner
      try {
        this.setSelectionMode({ mode: SelectionUnit.Part })
        const orntIndex = this.lastSavedOrientationIndex
          ? this.lastSavedOrientationIndex
          : this.importedOrientationIndex
        this.orientPart(orntIndex, false, this.selectedBuildPlanItem.transformationMatrix)
        if (this.displayToolbarStateByVariantId(this.buildPlan.id).isShowingSupportGeometry) {
          this.setGeometriesVisibility({
            items: [{ buildPlanItemId: this.selectedBuildPlanItem.id }],
            geometryType: GeometryType.Support,
            visibility: true,
          })
        }

        this.$emit('jobCreated') // to remove spinner
      } catch (error) {
        messageService.showErrorMessage(error.message)
        this.$emit('jobCreated') // to remove spinner
      }
    }
  }

  addProhibitedAndPreferredPercentage(orientResult: any) {
    if (orientResult.length > 0) {
      orientResult.forEach((element) => {
        const result = this.getProhibitAndPreferredCoveragePercentage(this.getIndex(element.x, element.y))
        element.prohibitedPercentage = result.prohibitPercentageValue
        element.preferredPercentage = result.preferredPercentageValue
      })
      return orientResult
    }
  }

  getProhibitAndPreferredCoveragePercentage(id: number) {
    if (this.orientationWithSurfacePercentage.length > 0) {
      const orientationwithPercentage = this.orientationWithSurfacePercentage.find((item) => item.id === id)
      const prohibitPercentageValue = orientationwithPercentage.prohibitedSupportSurfaceCoverage * 100
      const preferredPercentageValue = orientationwithPercentage.preferedSupportSurfaceCoverage * 100
      return { prohibitPercentageValue, preferredPercentageValue }
    }

    return { prohibitPercentageValue: 100, preferredPercentageValue: 100 }
  }

  getIndex(x: number, y: number) {
    const xAxis = Math.floor(x / 5)
    const yAxis = Math.floor(y / 5)
    return xAxis + yAxis * 73
  }

  isHaveSameAngle(orientIndex: number, orientationIndex2: number) {
    const importedOrient = this.orientations[orientIndex]
    const lastSavedOrient = this.orientations[orientationIndex2]
    if (
      importedOrient.x === lastSavedOrient.x &&
      importedOrient.y === lastSavedOrient.y &&
      importedOrient.y === lastSavedOrient.y
    ) {
      return true
    }
    return false
  }

  isValidSupportLimitAngle(overhangAngle: number) {
    const rules = this.getSupportLimitAngleRules()
    if (overhangAngle < rules.min_value || overhangAngle > rules.max_value) {
      return false
    }
    return true
  }

  debounceScroll() {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer)
    }

    this.debounceTimer = setTimeout(() => {
      this.scrollId = uuidv4()
    }, 100)
  }

  @Watch('getSelectedBuildPlanFinalizingJobs')
  updateOkStatus() {
    const result =
      this.isGeometryLoading ||
      (this.getSelectedBuildPlanFinalizingJobs && this.getSelectedBuildPlanFinalizingJobs.length > 0) ||
      this.orientations.length === 0
    this.$emit('setOkDisabled', !this.isOrientationChanged() || result)
  }

  @Watch('currentAppliedOrientationIndex')
  disableOrientButton() {
    this.$emit('setOkDisabled', !this.isOrientationChanged())
  }

  private orientationsExists(buildplanItem: IBuildPlanItem): boolean {
    if (
      buildplanItem.orientCriteria &&
      buildplanItem.orientCriteria.orientations &&
      buildplanItem.orientCriteria.orientations.length > 0 &&
      buildplanItem.orientCriteria.appliedOrientationIndex &&
      buildplanItem.orientCriteria.supportAngle
    ) {
      return true
    }
    return false
  }
}
