
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'

import Button from '@/components/controls/Common/Button.vue'
import { PrintStrategyInfo } from '@/types/Sites/Site'
import StoresNamespaces from '@/store/namespaces'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component({
  components: {
    Button,
  },
})
export default class DeletePrintStrategyModal extends Vue {
  @sitesStore.Action deletePrintStrategy: (printStrategyId: number) => Promise<number>
  @sitesStore.Action getBuildPlansRelatedToPrintStrategyCount: (printStrategyId: number) => Promise<number>
  @sitesStore.Action fetchPrintStrategyById: (payload: {
    printStrategyId: number
    siteId: number
  }) => Promise<PrintStrategyInfo>

  isDeleteAvailable?: boolean = false
  relatedBuildPlansCount = 0
  printStrategy: PrintStrategyInfo = null
  isLoading = false

  isShow = false
  resolve = null

  async open(printStrategyId: number): Promise<boolean> {
    this.isShow = true
    this.isLoading = true

    const { siteId } = this.$route.params
    this.printStrategy = await this.fetchPrintStrategyById({ printStrategyId, siteId: Number(siteId) })

    if (this.printStrategy) {
      this.relatedBuildPlansCount = await this.getBuildPlansRelatedToPrintStrategyCount(this.printStrategy.id)
      this.isDeleteAvailable = this.relatedBuildPlansCount === 0
    }

    this.isLoading = false
    return new Promise((resolve) => {
      this.resolve = resolve
    })
  }

  async remove() {
    if (this.isDeleteAvailable) {
      const result = await this.deletePrintStrategy(this.printStrategy.id)
      this.resolve(result)
      this.isShow = false
    } else {
      this.cancel()
    }
  }

  cancel() {
    this.resolve(false)
    this.isShow = false
  }
}
