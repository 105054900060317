import { render, staticRenderFns } from "./TenantAdmin.vue?vue&type=template&id=d79d90f8&scoped=true"
import script from "./TenantAdmin.vue?vue&type=script&lang=ts"
export * from "./TenantAdmin.vue?vue&type=script&lang=ts"
import style0 from "./TenantAdmin.vue?vue&type=style&index=0&id=d79d90f8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d79d90f8",
  null
  
)

export default component.exports