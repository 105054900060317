export class VersionablePk {
  constructor(
    readonly id: number,
    readonly version?: number,
  ) {}

  equals(other: VersionablePk) {
    return this.id === other.id && this.version === other.version
  }
}
