import { GetterTree } from 'vuex'
import { IPartsState } from './types'
import { IRootState } from '@/store/types'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { IInsightsCount } from '@/types/Common/Insights'
import { IHighlightableDefect, IPartInsight } from '@/types/Parts/IPartInsight'
import { IBuildPlan, PartTypes } from '@/types/BuildPlans/IBuildPlan'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'

export const getters: GetterTree<IPartsState, IRootState> = {
  getAllParts(state) {
    return state.parts.sort(sortPartsByNamePredicate)
  },

  getAllSinterParts(state) {
    return state.sinterParts.sort(sortPartsByNamePredicate)
  },

  getPartIdsWithUsedDate(state): Array<{ partId: string; usedDate: Date }> {
    return state.partIdsWithUsedDate
  },

  getPreviewedPartGeometryProps(state) {
    return state.partPreview.geometryProperties
  },

  configFile(state) {
    return state.partPreview.configFile
  },

  insightsCount(state): IInsightsCount {
    return state.partPreview.insightTool.insightsCount
  },

  insights(state): IPartInsight[] {
    return state.partPreview.insightTool.insights
  },

  activeInsightDefects(state): number[] {
    return state.partPreview.insightTool.activeInsightDefects
  },

  activeInsightDefectGroup(state): number {
    return state.partPreview.insightTool.activeInsightDefectGroup
  },

  selectedDefect(state): IHighlightableDefect {
    return state.partPreview.selectedDefect
  },

  hoveredDefect(state): IHighlightableDefect {
    return state.partPreview.hoveredDefect
  },

  partDefectsById:
    (state) =>
    (partId: string): string[] => {
      const part = state.parts.find((partItem) => partItem.id === partId)
      if (part && part.hasErrors) {
        return (part.metadata as { error: string }).error.split('\n')
      }

      return []
    },

  partById:
    (state) =>
    (partId: string): IPartDto => {
      return state.parts.find((partItem) => partItem.id === partId)
    },

  partsByNominalPartId:
    (state) =>
    (nominalPartId: string): IPartDto[] => {
      return state.parts.filter((part) => part.nominalPartId === nominalPartId)
    },

  getIbcPlanParts: (state) => (ibcPlan: IIBCPlan) => {
    const sinterParts = state.sinterParts.sort(sortPartsByNamePredicate)
    const ibcParts = state.ibcParts.sort(sortPartsByNamePredicate)

    if (ibcPlan && ibcPlan.ibcPlanItems) {
      const partMap = new Map<string, IPartDto>()

      ibcPlan.ibcPlanItems.forEach((ibcPlanItem) => {
        const foundSinterPart = sinterParts.find((sinterPart) => sinterPart.id === ibcPlanItem.partId)
        if (foundSinterPart) {
          partMap.set(foundSinterPart.id, foundSinterPart)
        } else {
          const foundIbcPart = ibcParts.find((ibcPart) => ibcPart.id === ibcPlanItem.partId)
          if (foundIbcPart) {
            partMap.set(foundIbcPart.id, foundIbcPart)
          }
        }
      })

      return Array.from(partMap, ([, part]) => {
        const vm = {
          ...part,
          partType: part.subType === ItemSubType.SinterPart ? PartTypes.SinterPart : PartTypes.IbcPart,
          disabled: false,
          disabledDescription: '',
          previewImageUrl: part.previewImageUrl,
        }
        return vm
      }).sort((a, b) => a.name.localeCompare(b.name))
    }
    return []
  },

  getBuildPlanParts: (state) => (buildPlan: IBuildPlan) => {
    const parts = state.parts.sort(sortPartsByNamePredicate)
    const sinterParts = state.sinterParts.sort(sortPartsByNamePredicate)
    const ibcParts = state.ibcParts.sort(sortPartsByNamePredicate)

    if (buildPlan && buildPlan.buildPlanItems.length > 0) {
      return buildPlan.buildPlanItems.reduce((unique, item) => {
        const { id, subType } = item.part
        let partType
        switch (subType) {
          case ItemSubType.None:
            partType = PartTypes.BuildPlanPart
            break
          case ItemSubType.SinterPart:
            partType = PartTypes.SinterPart
            break
          case ItemSubType.IbcPart:
            partType = PartTypes.IbcPart
            break
        }
        const itemIndex = unique && unique.findIndex((u) => u.id === id)
        if (itemIndex < 0) {
          let part
          switch (partType) {
            case PartTypes.BuildPlanPart:
              part = parts.find((p) => p.id === id)
              break
            case PartTypes.SinterPart:
              part = sinterParts.find((p) => p.id === id)
              break
            case PartTypes.IbcPart:
              part = ibcParts.find((p) => p.id === id)
              break
          }
          if (part) {
            unique.push({ ...part, partType })
          }
        } else {
          unique[itemIndex].amountOfInstances += 1
        }
        return unique
      }, [])
    }
    return []
  },

  getAllIbcParts(state) {
    return state.ibcParts.sort(sortPartsByNamePredicate)
  },
}

function sortPartsByNamePredicate(part1: IPartDto, part2: IPartDto) {
  const name1 = part1.name.toLowerCase()
  const name2 = part2.name.toLowerCase()

  if (name1 < name2) return -1
  if (name1 > name2) return 1
  return 0
}
