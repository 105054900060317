
import Component from 'vue-class-component'
import { Watch, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ShareItemModal from '@/components/layout/FileExplorer/ShareItemModal.vue'
import { ItemDetailsDto, PartDetailsDto, ItemDetailsTypes, ItemDetailsType } from '@/types/FileExplorer/ItemDetails'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { IBuildPlansState } from '@/store/modules/buildPlans/types'
import { PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import StoresNamespaces from '@/store/namespaces'
import ModalsStateMixin from '@/components/layout/FileExplorer/Table/mixins/ModalsStateMixin'
import DetailsUsedByBuildOrSinterPlansList from '@/components/layout/FileExplorer/Details/DetailsUsedByBuildOrSinterPlansList.vue'
import { DateByUserMixin } from '@/components/layout/mixins/DateByUserMixin'
import { ItemType } from '@/types/FileExplorer/ItemType'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

interface IMixinInterface extends ModalsStateMixin, DateByUserMixin {}

@Component({
  components: {
    ShareItemModal,
    DetailsUsedByBuildOrSinterPlansList,
  },
})
export default class BuildPlanDetailsPart extends Mixins<IMixinInterface>(ModalsStateMixin, DateByUserMixin) {
  @buildPlansStore.State((state: IBuildPlansState) => state.addPartTool.selectedParts)
  selectedParts: PartListItemViewModel[]

  @fileExplorerStore.Getter getItemDetails: ItemDetailsType
  @fileExplorerStore.Getter getSelectedItem: FileExplorerItem

  @fileExplorerStore.Mutation selectItem: (payload: { item: FileExplorerItem; selectionType: SelectionTypes }) => void
  @fileExplorerStore.Mutation unselectAllItems: () => void

  isLoadingNow = false
  isShownShareItemModal = false

  get isReady(): boolean {
    if (this.isLoadingNow) {
      return false
    }
    return this.partDetails !== null
  }

  get colaboratorsInfo(): string {
    const collaboratorsCount = this.partDetails.collaborators.length
    return `${collaboratorsCount} ${
      collaboratorsCount !== 1 ? this.$i18n.t('collaborators') : this.$i18n.t('collaborator')
    }`
  }

  async beforeMount() {
    if (this.selectedParts.length === 1) {
      await this.fetchPartDetails()
    }
  }

  get createdInfo(): string {
    return this.getFormatedActionInfo(this.partDetails.createdAt, this.partDetails.createdBy)
  }

  get partDetails(): PartDetailsDto {
    return this.getItemDetails as PartDetailsDto
  }

  @Watch('selectedParts')
  async onSelectedPartChanged() {
    if (this.selectedParts.length !== 1) {
      this.unselectAllItems()
      return
    }

    await this.fetchPartDetails()
  }

  async shareItemModalClose() {
    this.toggleShareItemModal(false)
    await this.fetchPartDetails()
  }

  async fetchPartDetails() {
    if (this.selectedParts.length !== 1) return

    const [selectedPart] = this.selectedParts
    try {
      this.isLoadingNow = true

      const dto: ItemDetailsDto = await this.fetchItemDetails({ itemId: selectedPart.id, itemType: ItemType.BuildPart })
      const details = dto.type === ItemDetailsTypes.Part && (dto as any)

      if (selectedPart && selectedPart.id === details.id) {
        const partItem = {
          id: this.partDetails.id,
          createdBy: this.partDetails.createdBy,
          itemType: ItemType.BuildPart,
          path: selectedPart.path,
          parentId: selectedPart.parentId,
          version: selectedPart.version,
        } as FileExplorerItem
        this.selectItem({ item: partItem, selectionType: SelectionTypes.Single })
      }
    } finally {
      this.isLoadingNow = false
    }
  }
}
