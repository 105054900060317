export function debounce<T>(time: number, func: Function): (...funcArgs: any[]) => Promise<T> {
  let timeout

  return (...args): Promise<T> =>
    new Promise((resolve) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        resolve(func(...args))
      }, time)
    })
}
