
import Vue from 'vue'
import Component from 'vue-class-component'
import Button from '@/components/controls/Common/Button.vue'

const THE_TIME_IS_NOW_LINK = 'https://go.additive.ge.com/rs/706-JIU-273/images/GE_JourneyAM_WP_FINAL.pdf'
const THE_PATH_TO_PRODUCTION_LINK =
  'https://go.additive.ge.com/rs/706-JIU-273/images/GE%20Additive_Path%20to%20Production_White%20paper_Final.pdf'

@Component({
  components: {
    Button,
  },
})
export default class CostEstimationSidePanel extends Vue {
  theTimeIsNowLink = THE_TIME_IS_NOW_LINK
  thePathToProductionLink = THE_PATH_TO_PRODUCTION_LINK
}
