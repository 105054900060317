import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { INotificationState } from './types'
import { IRootState } from '@/store/types'
import { DEFAULT_PAGINATION_LIMIT } from '@/constants'
import i18n from '@/plugins/i18n'

export const state: INotificationState = {
  isLoading: false,
  notifications: [],
  selectedNotificationType: {
    text: i18n.t('allNotificationTypes').toString(),
    value: ['ALL'],
  },
  paginationData: {
    currentPage: 0,
    totalItems: null,
    totalPages: 1,
    size: DEFAULT_PAGINATION_LIMIT,
  },
  lastNotificationTime: null,
}

const namespaced: boolean = true

export const notifications: Module<INotificationState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
