
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import SliceSettingsRow from './SliceSettingsRow.vue'
import { BULK_AREA_SLICE_SETTINGS_BAND_ID, HatchType } from '@/constants'
import { BandModel, SliceSettings } from '@/types/BuildPlans/IBuildPlan'
import { ValidationObserver } from 'vee-validate'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { VCardText } from 'vuetify/lib/components/VCard'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { PrintStrategyMode } from '@/types/Sites/ParameterEditor'
import { isNil } from '@/utils/common'
import HoverableTooltip from '@/components/modals/HoverableTooltip.vue'
import ContourPixelLevelInput from './ContourPixelLevelInput.vue'

const BANDS_LIMIT = 5
const INPUT_FIELD_ERROR_STATE_CSS_CLASSNAME = 'error-flat-form'

const sitesStore = namespace(StoresNamespaces.Sites)
const buildPlanStore = namespace(StoresNamespaces.BuildPlans)

@Component({
  components: { SliceSettingsRow, draggable, HoverableTooltip, ContourPixelLevelInput },
})
export default class SliceSettingsList extends Vue {
  @Prop({ required: true }) value: SliceSettings
  @Prop() disabled: boolean
  @Prop({ default: PrintStrategyMode.Default }) printStrategyMode: PrintStrategyMode

  @sitesStore.Action fetchProcessParameterSolidLevel: (printStrategyPk) => Promise<number>

  @sitesStore.Getter getSelectedPrintStrategyPk: VersionablePk
  @buildPlanStore.Getter getBuildPlanPrintStrategyPk: VersionablePk

  $refs!: {
    sliceSettingsForm: InstanceType<typeof ValidationObserver>
    listContent: VCardText
  }
  originalOrderedBands: BandModel[]
  activeBandId: number = null
  bandsHasBeenEdited: boolean = false
  contourPixelLevelHasBeenEdited = false
  originContourPixelLevel: number

  get isValid() {
    const { failed } = this.$refs.sliceSettingsForm.flags
    return !failed
  }

  get bulkAreaId() {
    return BULK_AREA_SLICE_SETTINGS_BAND_ID
  }

  get bandsLimit() {
    return BANDS_LIMIT
  }

  get numberOfBands() {
    return this.value.bands ? this.value.bands.length : 0
  }

  get bulk() {
    return this.value.bulk
  }

  async mounted() {
    const pk =
      this.printStrategyMode === PrintStrategyMode.Default
        ? this.getSelectedPrintStrategyPk
        : this.getBuildPlanPrintStrategyPk
    await this.fetchProcessParameterSolidLevel(pk)

    this.originContourPixelLevel = this.value.contourPixelLevel
  }

  move(a) {
    const draggedItem = a.draggedContext.element
    const dropingOnElement = a.relatedContext.element
    return !(draggedItem.hatchType !== HatchType.Solid && dropingOnElement.id === 0)
  }

  bandsReordered(event) {
    if (!isNil(this.activeBandId)) {
      if (this.activeBandId < event.oldDraggableIndex && event.newDraggableIndex <= this.activeBandId) {
        this.activeBandId += 1
      } else if (this.activeBandId > event.oldDraggableIndex && event.newDraggableIndex >= this.activeBandId) {
        this.activeBandId -= 1
      } else if (this.activeBandId === event.oldDraggableIndex) {
        this.activeBandId = event.newDraggableIndex
      }
    }

    this.bandsHasBeenEdited = true
    this.reAssignBandIds()
  }

  bandsNonFormFieldHasBeenEdited() {
    this.bandsHasBeenEdited = true
  }

  reset() {
    this.bandsHasBeenEdited = false
    this.originContourPixelLevel = this.value.contourPixelLevel
    this.contourPixelLevelHasBeenEdited = false
  }

  onDragStart() {
    this.originalOrderedBands = this.value.bands
  }

  addNewBand() {
    if (this.numberOfBands >= 5 || this.disabled || !this.isValid) {
      return
    }
    this.bandsHasBeenEdited = true
    this.value.bands.push(new BandModel())
    this.reAssignBandIds()
    this.triggerBandExpand(this.numberOfBands - 1)
  }

  get isAddBandDissabled() {
    return this.numberOfBands >= this.bandsLimit || this.disabled || !this.isValid
  }

  removeBand(id: number) {
    if (id < 0 || this.disabled) {
      return
    }
    this.bandsHasBeenEdited = true
    // id of bands starts from 0, so we can use it as array index
    this.value.bands.splice(id, 1)
    this.reAssignBandIds()
    if (!this.numberOfBands) {
      this.bulk.hatchType = HatchType.Solid
    }
  }

  reAssignBandIds() {
    for (let i = 0; i < this.numberOfBands; i = i + 1) {
      this.value.bands[i].id = i
    }
  }

  scrollToFormErrors() {
    const elements = this.$refs.sliceSettingsForm.$el.getElementsByClassName(INPUT_FIELD_ERROR_STATE_CSS_CLASSNAME)
    if (elements.length > 0) {
      ;(elements[0] as HTMLElement).scrollIntoView({ behavior: 'smooth' })
    }
  }

  triggerBandExpand(id: number) {
    if (!this.isValid) {
      this.scrollToFormErrors()
      return
    }
    this.activeBandId = this.activeBandId === id ? null : id
  }

  onContourPixelLevelInput(value) {
    this.value.contourPixelLevel = value
  }
}
