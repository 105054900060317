
import { Component, Vue, Watch } from 'vue-property-decorator'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'

const visualizationStore = namespace(StoresNamespaces.Visualization)
const HUNDRED = 100
const SLIDER_MARGIN = 10
const THUMB_HEIGHT = 30

@Component
export default class VerticalSlider extends Vue {
  @visualizationStore.Getter('getSimulationSlicer') getSimulationSlicer: { max: number; min: number; current: number }
  @visualizationStore.Getter('getVisualizationLoading') getVisualizationLoading: boolean

  @visualizationStore.Mutation('changeSimulationSlicer') changeSimulationSlicer: Function

  slice: number = 0

  @Watch('getSimulationSlicer') onSlicerChanged() {
    this.slice = this.getSimulationSlicer.current
    this.$nextTick(() => this.updateThumbPosition())
  }

  mounted() {
    this.onSlicerChanged()
  }

  get sliderMin() {
    return Math.floor(this.getSimulationSlicer.min)
  }

  get sliderMax() {
    return Math.ceil(this.getSimulationSlicer.max)
  }

  get sliceSliderVisible() {
    return (
      this.getSimulationSlicer.current !== undefined &&
      !Number.isNaN(this.getSimulationSlicer.current) &&
      this.sliderMax - this.sliderMin !== 0 &&
      Number.isFinite(this.sliderMin)
    )
  }

  updateThumbPosition() {
    const thumb: HTMLDivElement = document.getElementById('verticalThumb') as HTMLDivElement
    if (thumb) {
      const topValue = HUNDRED - this.getBoundRelative()

      thumb.style.top = `calc(${topValue}% - ${THUMB_HEIGHT / 2}px - ${2 * SLIDER_MARGIN}px * ${topValue / HUNDRED})`
    }
  }

  updateThumb(event) {
    const value = event.target.value
    this.setSliderValue(value)
  }

  incrementSlider() {
    this.setSliderValue(this.slice + 1)
  }

  decrementSlider() {
    this.setSliderValue(this.slice - 1)
  }

  private setSliderValue(newValue: number) {
    if (newValue < this.sliderMin) {
      this.slice = this.sliderMin
    } else if (newValue > this.sliderMax) {
      this.slice = this.sliderMax
    } else {
      this.slice = Number(newValue)
    }

    this.$forceUpdate()
    this.changeSimulationSlicer(this.slice)
    this.updateThumbPosition()
  }

  private getBoundRelative(): number {
    const referenceRange = this.sliderMax - this.sliderMin
    const minimum = this.slice - this.sliderMin
    return Math.abs(Math.round((minimum / referenceRange) * HUNDRED))
  }
}
