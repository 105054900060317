
import Component, { mixins } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Watch } from 'vue-property-decorator'

import SearchListRow from '@/components/layout/FileExplorer/Table/Search/SearchListRow.vue'
import DetailsPanel from '@/components/layout/FileExplorer/DetailsPanel.vue'
import ActionBar from '@/components/layout/FileExplorer/ActionBar.vue'
import ItemTypeSelector from '@/components/controls/FileExplorer/ItemTypeSelector.vue'
import InfinityScrollArea from '@/components/layout/FileExplorer/InfinityScrollArea.vue'
import MoveToTrashItemModal from '@/components/layout/FileExplorer/MoveToTrashItemModal.vue'
import MoveToTrashItemErrorModal from '@/components/layout/FileExplorer/MoveToTrashItemErrorModal.vue'
import MoveErrorModal from '@/components/layout/FileExplorer/MoveErrorModal.vue'
import ShareItemModal from '@/components/layout/FileExplorer/ShareItemModal.vue'
import RenameItemModal from '@/components/layout/FileExplorer/RenameItemModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import AlertModal from '@/components/modals/AlertModal.vue'
import AllFilesSearchViewMixin from '@/components/layout/FileExplorer/Table/mixins/AllFilesSearchViewMixin'
import { ToggleDetailsPanelButton } from '@/components/layout/FileExplorer/Details'
import DetailsPrintOrder from '@/components/layout/FileExplorer/Details/DetailsPrintOrder.vue'

import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ISearchParamsInterface } from '@/types/ISearchParamsInterface'
import { SearchResultItem } from '@/types/FileExplorer/SearchResultItem'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import {
  FilterBy,
  FilterParamsKey,
  ItemTypeFilterParams,
  TableFilterParams,
} from '@/types/FileExplorer/FilterParamsKey'
import { TableSortParams } from '@/types/FileExplorer/SortParamsKey'
import { ItemFieldType, ItemType } from '@/types/FileExplorer/ItemType'
import { isOfType } from '@/utils/common'
import { IFileExplorerState } from '@/store/modules/fileExplorer/types'
import StoresNamespaces from '@/store/namespaces'
import { RouterNames } from '@/router'
import PrintOrderMixin from '@/components/layout/FileExplorer/Table/printOrder/PrintOrderMixin'
import FileExplorerRedirectionMixin from '@/components/layout/FileExplorer/Table/mixins/FileExplorerRedirectionMixin'
import { SearchResultSnapshot } from '@/types/FileExplorer/SearchResultSnapshot'
import MoveOrCopyModal from '@/components/layout/FileExplorer/MoveOrCopyModal.vue'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { PrintOrderJobStatusCode } from '@/types/PrintOrder/PrintOrderJobStatusCode'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const partsStore = namespace(StoresNamespaces.Parts)
const jobsStore = namespace(StoresNamespaces.Jobs)

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate'])

@Component({
  components: {
    SearchListRow,
    DetailsPanel,
    ActionBar,
    ItemTypeSelector,
    InfinityScrollArea,
    ShareItemModal,
    MoveErrorModal,
    MoveToTrashItemModal,
    MoveToTrashItemErrorModal,
    RenameItemModal,
    ConfirmModal,
    AlertModal,
    ToggleDetailsPanelButton,
    DetailsPrintOrder,
    MoveOrCopyModal,
  },
})
export default class Search extends mixins(AllFilesSearchViewMixin, PrintOrderMixin, FileExplorerRedirectionMixin) {
  @buildPlansStore.Action fetchBuildPlates: Function

  @partsStore.Action actualizeParts: (items?: FileExplorerItem[]) => Promise<void>
  @partsStore.Action fetchAllParts: Function
  @partsStore.Action fetchAllSinterParts: Function
  @partsStore.Action fetchAllIbcParts: () => Promise<IPartDto[]>

  @fileExplorerStore.Action updateFilter: (filterParams: { key: string; filterBy: FilterBy }) => void
  @fileExplorerStore.Action fetchSearchResultSnapshot: (payload: ISearchParamsInterface) => Promise<void>
  @fileExplorerStore.Action fetchSearchResultItems: (searchQuery: string) => Promise<SearchResultItem[]>
  @fileExplorerStore.Action getGetRunningAndFailedJobsByItemIds: Function

  @jobsStore.Action fetchPrintOrders: () => Promise<PrintOrder[]>

  @fileExplorerStore.Getter isLoading: boolean
  @fileExplorerStore.Getter('find') findItemById: (itemId: string) => FileExplorerItem
  @fileExplorerStore.Getter('getSelectedItems') selectedItems: FileExplorerItem[]
  @fileExplorerStore.Getter getSearchResultItems: SearchResultItem[]
  @fileExplorerStore.Getter getSearchResultSnapshot: SearchResultSnapshot[]

  @fileExplorerStore.Mutation toggleDetailsPanel: () => void
  @fileExplorerStore.Mutation clearSearchResult: () => void
  @fileExplorerStore.Mutation clearPaginationData: () => void
  @fileExplorerStore.Mutation removeFromSearchResult: (itemId: string) => void

  @fileExplorerStore.State isOpenDetailsPanel: boolean
  @fileExplorerStore.State((s: IFileExplorerState) => s.sortParams.search) sortParams: TableSortParams
  @fileExplorerStore.State((s: IFileExplorerState) => s.filterParams.searchFilter) filterParams: TableFilterParams
  @fileExplorerStore.State((s: IFileExplorerState) => s.search.pageRange) pageRange: [number, number]

  filterType = FilterParamsKey.Search
  statusFilterType = FilterParamsKey.SearchPrintOrdersStatus
  materialFilterType = FilterParamsKey.SearchPrintOrdersMaterial
  machineFilterType = FilterParamsKey.SearchPrintOrdersMachine
  parameterFilterType = FilterParamsKey.SearchPrintOrdersParameter
  buildPlateFilterType = FilterParamsKey.SearchPrintOrdersBuildPlate

  // @ts-ignore
  get items(): SearchResultItem[] {
    return this.getSearchResultItems
  }

  get hasMoreItems(): boolean {
    const [startIndex] = this.pageRange
    return startIndex < this.getSearchResultSnapshot.length
  }

  get selectedPrintOrder() {
    if (!this.selectedItems.length || this.selectedItems.length > 1) {
      return null
    }

    const selectedItem = this.selectedItems[0]

    return this.getSearchResultItems
      .filter((i) => isOfType<PrintOrder>(i, 'site'))
      .find((p: PrintOrder) => p.id === selectedItem.id) as PrintOrder
  }

  get selectedItem() {
    if (!this.selectedItems.length || this.selectedItems.length > 1) {
      return null
    }

    const selectedItem = this.selectedItems[0]

    if (selectedItem.itemType === ItemType.PrintOrder) {
      return this.printOrders.find((p: PrintOrder) => p.id === selectedItem.id)
    }

    return this.findItemById(selectedItem.id)
  }

  get printOrderStatusSelectorItems(): ItemTypeFilterParams[] {
    const mapCodeToStatus = new Map<string, string>()
    this.printOrders.forEach((p: PrintOrder) => mapCodeToStatus.set(p.code, p.status))
    const items = [...mapCodeToStatus.entries()].map((entry) => {
      const [code, status] = entry
      return {
        name: status,
        value: code,
        testClass: '',
      }
    })
    return items
  }

  @Watch('sortParams')
  @Watch('filterParams')
  async refreshItems() {
    await this.fetchSearchResults(this.$route.params)
  }

  beforeRouteEnter(to, from, next) {
    next((vm: Search) => {
      vm.getPrintJobs()
      vm.fetchSearchResults(to.params)
    })
  }

  async beforeRouteUpdate(to, from, next) {
    this.fetchSearchResults(to.params)
    next()
  }

  beforeMount() {
    this.unselectAllItems()
    this.setRoot(null)
  }

  async fetchSearchResults(params) {
    this.clearSearchResult()
    await this.retrieveSearchResultsSnapshotByParams(params)
    await this.fetchItems(params.query)
  }

  async retrieveSearchResultsSnapshotByParams(params) {
    const { query } = params

    const searchQuery = query ? query.trim() : null
    if (!searchQuery) {
      return
    }
    const searchParams = { searchQuery }

    await this.fetchSearchResultSnapshot(searchParams)
    await Promise.all([
      this.fetchAllSinterParts(),
      this.fetchAllParts(),
      this.fetchAllIbcParts(),
      this.actualizeParts(this.items as FileExplorerItem[]),
    ])
  }

  async fetchItems(searchQuery: string) {
    const itemsToGetJobs = await this.fetchSearchResultItems(searchQuery)
    if (itemsToGetJobs.length > 0) {
      const itemIds = itemsToGetJobs.map((item: FileExplorerItem) => item.id)
      this.getGetRunningAndFailedJobsByItemIds(itemIds)
    }
  }

  async onSearchItemClick(payload) {
    if (payload.item.itemType === ItemType.PrintOrder) {
      this.parts = await this.getParts(payload.item.parentId)
    }

    this.onItemListRowClick(payload)
  }

  onIntersection() {
    if (this.hasMoreItems) {
      this.fetchItems(this.$route.params.query)
    }
  }

  onItemTrash(itemIds: string[]) {
    itemIds.forEach((id) => {
      this.removeFromSearchResult(id)
    })
  }

  downloadPrintOrder() {
    if (this.selectedPrintOrder) {
      this.downloadFile(this.selectedPrintOrder)
    }
  }

  async getPrintJobs() {
    this.setPrintOrdersLoading(true)

    await this.fetchBuildPlates()
    await this.fetchPrintOrders()
  }

  // Because we show bp variants separately in search
  // after rename or trash should refresh search to show updated variants
  // (or remove all variants)
  async renameSearchItem(name: string) {
    await this.renameItem(name)
    this.refreshItems()
  }

  async confirmTrashSearchItems(items) {
    await this.confirmTrashModal(items)
    this.refreshItems()
  }

  beforeDestroy() {
    if (this.$route.name === RouterNames.FE_Search) {
      return
    }
    this.updateFilter({
      key: FilterParamsKey.AllFiles,
      filterBy: { field: ItemFieldType.ItemType, value: [] },
    })

    this.unselectAllItems()
    this.clearPaginationData()
    this.clearSearchResult()
  }
}
