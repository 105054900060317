import { SimulationManager } from './SimulationManager'
import { IHandlerSettings } from './NominalGeometryManager'
import { Matrix } from '@babylonjs/core/Maths'
import { GeometryType } from '@/visualization/types/SimulationTypes'
import { createGuid } from '@/utils/common'
import { BatchGeometryHandler } from '@/visualization/rendering/BatchGeometryHandler'
import { PART_TYPE, SUPPORT_TYPE } from '@/constants'
import { VertexData } from '@babylonjs/core'

const SUPPORT_NAME_PREFIX = 'COMPS'
const PART_NAME_PREFIX = 'COMP'
const GROUP_ID_LENGTH = 36

export class CompensatedMeshHandler extends BatchGeometryHandler {
  private isSinter: boolean

  constructor(manager: SimulationManager, settings: IHandlerSettings, isSinter: boolean) {
    super(manager, settings)
    this.isSinter = isSinter
  }

  protected getMetadataKeyFromName(name: string): string {
    const fileName = name.substring(name.indexOf(PART_NAME_PREFIX), name.lastIndexOf('_joblog'))
    if (fileName.startsWith(SUPPORT_NAME_PREFIX)) {
      // Return only groupId
      return fileName.substring(fileName.length - GROUP_ID_LENGTH)
    }

    return fileName
  }

  protected createNewGeometry(vData: VertexData, metadata: { fileName: any; matrix: any }) {
    const mesh = super.createNewGeometry(vData, metadata)

    if (!this.isSinter && metadata) {
      const newMatrix = Matrix.FromArray(metadata.matrix).transpose()
      mesh.freezeWorldMatrix(newMatrix)
    }

    return mesh
  }

  protected createInstanceFromLoadedMesh(metadata: { fileName: any; matrix: any }): void {
    const loadedMeshData = this.loadedMeshes.get(metadata.fileName)
    const existingMesh = this.getMeshById(loadedMeshData.id)
    const instance = existingMesh.createInstance(createGuid())
    instance.isPickable = false

    if (!this.isSinter) {
      instance.freezeWorldMatrix(Matrix.FromArray(metadata.matrix).transpose())
    }
  }

  protected processJobMetadata(metadata: any) {
    metadata.forEach((itemMetadata) => {
      itemMetadata.geometryFileDataList.forEach((geometry) => {
        if (geometry.fileType === PART_TYPE) {
          const name = `${PART_NAME_PREFIX}-${this.getFileNameWithoutExtension(geometry.meshDataFilename)}`
          const info = { fileName: geometry.originalFilename, matrix: itemMetadata.transformationMatrix }
          this.partsMatricesMap.set(name, info)
        } else if (geometry.fileType === SUPPORT_TYPE) {
          const name = `${geometry.groupId}`
          const info = { fileName: name, matrix: itemMetadata.transformationMatrix }
          this.partsMatricesMap.set(name, info)
        }
      })
    })
  }

  private getFileNameWithoutExtension(str: string) {
    const result = str.replace('_', '-')
    const extensionIndex = result.lastIndexOf('.')
    return extensionIndex !== -1 ? result.substring(0, extensionIndex) : result
  }
}
