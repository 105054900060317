export interface IInteractiveServiceMessage {
  id?: string
  status: OperationStatus
  message: string
  content?: unknown
  item?: string
  errors?: ISupportError[]
  category?: string
  code?: number
}

export interface ISupportError {
  category: string
  code: number
  message: string
}

export enum OperationStatus {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Error = 'ERROR',
  Done = 'DONE',
}
