
import { Component, Mixins } from 'vue-property-decorator'

import { ToggleDetailsPanelButton } from '@/components/layout/FileExplorer/Details'
import TableHeaderMixin from '@/components/layout/FileExplorer/Table/mixins/TableHeaderMixin'
import TableHeaderCell from '@/components/layout/FileExplorer/Table/TableHeaderCell.vue'
import { DataTableHeaderCell } from '@/types/Common/DataTable'
import { SortParamsKey } from '@/types/FileExplorer/SortParamsKey'

const SORT_PARAMS_KEY = SortParamsKey.Shared

@Component({
  components: { ToggleDetailsPanelButton, TableHeaderCell },
})
export default class SharedByMeItemHeader extends Mixins(TableHeaderMixin) {
  get headers(): { [key: string]: DataTableHeaderCell } {
    const headers: DataTableHeaderCell[] = [
      {
        text: 'Name',
        key: 'name',
        sortBy: 'name',
      },
      {
        text: 'Shared',
        key: 'sharedDate',
        sortBy: 'sharedDate',
      },
      {
        text: 'Type',
        key: 'itemType',
        sortBy: 'itemType',
      },
    ]

    return this.headersToMap(headers)
  }

  sortStatusKey = SORT_PARAMS_KEY
}
