
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Button from '@/components/controls/Common/Button.vue'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import StoresNamespaces from '@/store/namespaces'
import VariantIcon from '@/components/icons/VariantIcon.vue'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component({
  components: {
    Button,
    VariantIcon,
  },
})
export default class ActivateVariantModal extends Vue {
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getIsVariantLockedForUserByVariantId: (id: string) => boolean
  @buildPlansStore.Getter isVariantProcessing: boolean

  @Prop() variants: IBuildPlan[]
  @Prop() activateVariant: (variant: IBuildPlan) => Promise<void>

  isShownDialog: boolean = true
  items: IBuildPlan[] = []
  selectedVariant: IBuildPlan = null

  beforeMount() {
    this.items = this.variants.filter((variant) => {
      if (variant.version === this.getBuildPlan.version) {
        this.selectedVariant = variant
      }

      return !this.getIsVariantLockedForUserByVariantId(variant.id)
    })

    // fix for compatibility with old build plans (without default variant labels)
    this.items.forEach((bp, index) => {
      if (!bp.versionLabel || bp.versionLabel === '') {
        bp.versionLabel = `Variant ${index + 1}`
      }
    })
  }

  selectVariant(variant: IBuildPlan) {
    if (variant.version !== this.selectedVariant.version) {
      this.selectedVariant = variant
    }
  }

  async onActivate() {
    if (!this.activateVariant) {
      return
    }

    if (this.selectedVariant.version !== this.getBuildPlan.version) {
      await this.activateVariant(this.selectedVariant)
    }

    this.onClose()
  }

  onClose() {
    this.$emit('onClose')
  }
}
