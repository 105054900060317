
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/components/controls/Common/Button.vue'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import StoresNamespaces from '@/store/namespaces'
import { getItemTypeName } from '../../../utils/fileExplorerItem/fileExplorerItemUtils'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: {
    Button,
  },
  methods: {
    getItemTypeName,
  },
})
export default class MoveToTrashItemModal extends Vue {
  @fileExplorerStore.Getter isLoading: boolean

  @Prop({ default: () => [] }) trashItems: FileExplorerItem[]
  @Prop({ default: () => [] }) relatedItems: FileExplorerItem[]
  @Prop() onConfirm: () => Promise<void>

  dialog: boolean = true

  beforeMount() {
    this.sortItems(this.relatedItems)
  }

  async onCancelClick() {
    this.dialog = false
    this.onClose()
  }

  async onTrashClick() {
    await this.onConfirm()
    this.dialog = false
  }

  onClose() {
    this.$emit('onClose')
  }

  get description() {
    return this.trashItems && this.trashItems.length
      ? this.trashItems.length > 1
        ? `${this.trashItems.length} ${this.$i18n.t('selectedItems')}`
        : `${getItemTypeName(this.trashItems[0])} ${this.trashItems[0].name}`
      : null
  }

  private sortItems(items: FileExplorerItem[]) {
    if (!items) {
      return
    }

    items
      .sort((a, b) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()

        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        return 0
      })
      .sort((a, b) => {
        return a.itemType - b.itemType
      })
  }
}
