import { SimulationBase } from '@/visualization/rendering/SimulationBase'
import { Mesh, VertexData } from '@babylonjs/core/Meshes'
import { IHandlerSettings } from '@/visualization/rendering/NominalGeometryManager'
import { GeometryType, ResultsManagerEvent as RMEvent } from '@/visualization/types/SimulationTypes'
import { NOMINAL_GEOMETRY_SUFFIX } from '@/constants'
import { createGuid } from '@/utils/common'
import {
  IVisualizationServiceCommand,
  IVisualizationServiceMessage,
  ResultsManager,
  WsCommands,
} from '@/visualization/rendering/ResultsManager'
import { DracoDecoder } from '@/visualization/components/DracoDecoder'

export class MeshHandler extends SimulationBase {
  onHandlerReady: () => void
  protected materialId: string
  protected defaultVisibility: boolean
  protected meshId: string
  protected readonly geometryType: GeometryType
  protected openDatasetCommand: IVisualizationServiceCommand
  protected hanlderSettings: IHandlerSettings

  constructor(manager: ResultsManager, settings: IHandlerSettings) {
    super(manager)
    this.meshId = this.getMeshId()
    this.materialId = settings.materialId
    this.geometryType = settings.type
    this.hanlderSettings = settings
    this.initCommands()
    this.createdMeshReadyHandler = this.createdMeshReadyHandler.bind(this)
  }

  loadMesh(makeVisible: boolean) {
    this.defaultVisibility = makeVisible
    this.manager.getSocket.on('message', this.handleSocketCommunication)
    this.manager.getSocket.on('disconnect', this.stopSocketCommunication)
    this.openDatasetCommand.parameters = [this.hanlderSettings.paths]
    this.manager.getSocket.emit('command', this.openDatasetCommand)
  }

  setMaterial(materialId: string) {
    if (!this.isReady) return
    const material = this.manager.getScene.getMaterialById(materialId)
    if (material) {
      this.getMeshById(this.meshId).material = material
    }
  }

  changeDefaultMaterial(materialId: string) {
    if (this.manager.getScene.getMaterialById(materialId)) {
      this.materialId = materialId
    }
  }

  clear() {
    const mesh = this.getMeshById(this.meshId)
    if (mesh) {
      this.manager.getScene.removeMesh(mesh)
      mesh.dispose()
    }

    this.commandMap.clear()
    this.initCommands()
  }

  get visible() {
    if (!this.isReady) return false
    return this.getMeshById(this.meshId).visibility === 1
  }

  set visible(value: boolean) {
    if (!this.isReady) return
    this.getMeshById(this.meshId).visibility = value ? 1 : 0
  }

  get isReady() {
    const mesh = this.getMeshById(this.meshId)
    return mesh !== null && mesh.isReady()
  }

  protected getMeshId(): string {
    return `${NOMINAL_GEOMETRY_SUFFIX}${Math.random().toString(36).substr(2, 9)}`
  }

  protected createMesh(vertexData: VertexData) {
    const mesh = new Mesh(this.meshId, this.manager.getScene)
    mesh.isPickable = false
    mesh.visibility = this.defaultVisibility ? 1 : 0
    mesh.material = this.manager.getScene.getMaterialByName(this.materialId)
    vertexData.applyToMesh(mesh, true)
    mesh.onMeshReadyObservable.addOnce(this.createdMeshReadyHandler)
    return mesh
  }

  protected handleLoadDatasetMessage(result) {
    this.getBinaryBuffer(result.points.hash).then((data) => {
      DracoDecoder.Default.decodeMeshPreservingOrder(data).then((decompressed) => {
        this.createMesh(decompressed)
        this.stopSocketCommunication()
        this.triggerEvent(RMEvent.AdditionalGeometryDetected, {
          type: this.geometryType,
          loaded: true,
          visible: this.defaultVisibility,
        })
      })
    })
  }

  protected initCommands() {
    this.openDatasetCommand = { id: createGuid(), name: WsCommands.ImportVtkData }

    this.commandMap.set(this.openDatasetCommand.id, (msg: IVisualizationServiceMessage) => {
      this.handleLoadDatasetMessage(msg.message.result)
    })
  }

  protected renderHandlerFirstTime() {
    // It's strange but in order to render newly created mesh we need to trigger animate (which calls render())
    // and render() function of the scene
    this.manager.renderScene()
    this.manager.getScene.render()
  }

  protected refreshCameraView() {
    if (!this.manager.isCurrentStepActive) {
      this.adjustCamera()
    }
  }

  protected createdMeshReadyHandler() {
    if (this.onHandlerReady) {
      this.onHandlerReady()
      this.onHandlerReady = undefined
    }

    this.refreshCameraView()
    this.renderHandlerFirstTime()
    this.manager.sliceManager.adjustSlicingPlane()
  }

  protected triggerEvent(event: RMEvent, args) {
    this.manager.resultsEvent.trigger({ args, event })
  }
}
