
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/components/controls/Common/Button.vue'
import SearchField from '@/components/controls/Common/SearchField.vue'
import ImplicitShareModal from '@/components/modals/ImplicitShareModal.vue'
import NotEnoughRoleModal from '@/components/modals/NotEnoughRoleModal.vue'

import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemPermissionsRole, Permission } from '@/types/FileExplorer/Permission'

import fileExplorerApi from '@/api/fileExplorer'
import messageService from '@/services/messageService'
import StoresNamespaces from '@/store/namespaces'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'

import buildPlans from '@/api/buildPlans'
import { ROOT_FOLDER_ID } from '@/constants'
import { RouterNames } from '@/router'
import { ItemTypeFilterName } from '@/types/FileExplorer/FilterParamsKey'
import { ItemUsersRoles } from '@/types/FileExplorer/ItemUsersRoles'
import { ViewMode } from '@/types/FileExplorer/ViewMode'
import { ISearchParamsInterface } from '@/types/ISearchParamsInterface'
import { IJob, JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import { SortOrders } from '@/types/SortModes'
import { IUser } from '@/types/User/IUser'
import { UserRole } from '@/types/User/UserRole'
import { orderBy } from '@/utils/array'
import { debounce } from '@/utils/debounce'

const commonStore = namespace(StoresNamespaces.Common)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const userStore = namespace(StoresNamespaces.User)

const SORT_BY_NAME = 'name'
const SORT_ORDER_ASCENDING = SortOrders.Ascending
const ROLE_TO_CHECK_EQUALITY_OF_COLLABORATION_ROLES = ItemPermissionsRole.Editor

interface ICopyItemsData {
  items: FileExplorerItem[]
  rootFolder: FileExplorerItem
}

@Component({
  components: {
    Button,
    SearchField,
    ImplicitShareModal,
    NotEnoughRoleModal,
  },
})
export default class MoveOrCopyModal extends Vue {
  @commonStore.Getter tooltipOpenDelay: number

  @fileExplorerStore.Getter('find') findItemById: (itemId: string) => FileExplorerItem
  @fileExplorerStore.Getter('getRootItem') rootItem: FileExplorerItem
  @fileExplorerStore.Getter getSelectedItems: FileExplorerItem[]
  @fileExplorerStore.Getter getFolderItems: FileExplorerItem[]
  @fileExplorerStore.Getter permissionsByItemId: Permission[]
  @fileExplorerStore.Getter getRunningJobsByItemId: (id: string) => IJob[]
  @fileExplorerStore.Getter getDirectOrInheritedPermissionsByItemPath: (path: string) => Permission[]
  @fileExplorerStore.Getter getViewMode: ViewMode

  @fileExplorerStore.Action pasteMovedItems: (item: FileExplorerItem) => Promise<void>
  @fileExplorerStore.Action copyItems: (value: ICopyItemsData) => Promise<FileExplorerItem[]>
  @fileExplorerStore.Action fetchItemWithNoStateUpdate: (itemId: string) => Promise<FileExplorerItem>
  @fileExplorerStore.Action getBreadcrumbsForItem: (rootItem: FileExplorerItem) => []
  @fileExplorerStore.Action getItemPermissions: (itemId: string) => Promise<void>
  @fileExplorerStore.Action fetchItemsPermissions: (items: FileExplorerItem[]) => Promise<void>
  @fileExplorerStore.Action moveRootItem: (newParentFolder: FileExplorerItem) => Promise<void>
  @fileExplorerStore.Action fetchItemById: (itemId: string) => Promise<FileExplorerItem>

  @fileExplorerStore.Mutation resetIsMoveItems: () => void
  @fileExplorerStore.Mutation setIsMoveItems: (val: boolean) => void
  @fileExplorerStore.Mutation setRoot: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation unselectAllItems: () => void

  @userStore.Getter getUserDetails: IUser

  @Prop({ type: Array }) items: FileExplorerItem[]
  @Prop({ required: true }) item: FileExplorerItem

  $refs!: {
    confirm: InstanceType<typeof ImplicitShareModal>
    notEnoughRole: InstanceType<typeof NotEnoughRoleModal>
  }

  fetchSearchResultsByValueDebounced = debounce(1000, this.searchInput)

  dialog: boolean = true
  folderItems: FileExplorerItem[] = []
  rootFolder: FileExplorerItem = null
  selectedFolder: FileExplorerItem = null
  isMultipleInitialParentFolders: boolean = false
  searchQuery: string = ''
  breadcrumbItems = []
  isProcessingMoveOrCopy: boolean = false
  searchItems: FileExplorerItem[] = []
  isSearchPanel: boolean = false
  containsIbcVariants = false

  get moveOrCopyModalTitle() {
    return this.items && this.items.length > 1 ? `${this.items.length} selected items` : this.item.name
  }

  get itemsToMoveOrCopy() {
    return this.items && this.items.length ? this.items : [this.item]
  }

  async beforeMount() {
    this.setIsMoveItems(true)

    await this.checkForIbcVariants()

    const isFolderViewMode = this.getViewMode === ViewMode.Folders

    const isAllFiles = this.$route.name === RouterNames.FE_AllFiles
    if (isFolderViewMode && isAllFiles) {
      this.rootFolder = this.rootItem
      await this.selectDestination(this.rootFolder)
      return
    }

    const directFolderIds = new Set(this.itemsToMoveOrCopy.map((x) => x.parentFolderId))

    if (directFolderIds.size === 1) {
      const [folderId] = directFolderIds
      if (!folderId) {
        await this.selectDestination(null)
        return
      }
      const folder = this.findItemById(folderId) || (await this.fetchItemById(folderId))
      this.rootFolder = folder
      await this.selectDestination(folder)
      return
    }

    this.isMultipleInitialParentFolders = true
    await this.selectDestination(null)
  }

  async move() {
    if (this.isProcessingMoveOrCopy) {
      return
    }

    try {
      this.isProcessingMoveOrCopy = true
      if (this.selectedFolder) {
        // get needed permissions
        await this.fetchItemsPermissions(this.itemsToMoveOrCopy.concat(this.selectedFolder))
        const idsForOwnerRequest = this.itemsToMoveOrCopy.map((i) => i.id)
        idsForOwnerRequest.push(this.selectedFolder.id)
        const originalOwners = await fileExplorerApi.getOwnerIdForItems(idsForOwnerRequest, true)

        if (!originalOwners || !originalOwners.length) {
          throw new Error(this.$t('incorrectItemOwnerId') as string)
        }

        const targetFolderRawPermissions: Permission[] = this.permissionsByItemId[this.selectedFolder.id] || []
        const targetFolderUserIdToRolesMap = {} as { [userId: string]: ItemPermissionsRole }
        if (targetFolderRawPermissions) {
          targetFolderRawPermissions.forEach((p) => {
            targetFolderUserIdToRolesMap[p.grantedTo] = p.role
          })
        }

        const selectedFolderOriginalOwnerId = originalOwners.find((o) => o.itemId === this.selectedFolder.id).ownerId
        targetFolderUserIdToRolesMap[selectedFolderOriginalOwnerId] = ItemPermissionsRole.Owner
        const itemsRoles: ItemUsersRoles[] = []
        const currUserItemsWithLowRoleNames: string[] = []
        const currentUserId = this.getUserDetails.id
        for (const item of this.itemsToMoveOrCopy) {
          const itemRoles: Permission[] = this.getDirectOrInheritedPermissionsByItemPath(item.path)
          const currentUserPermission = itemRoles.find((p) => p.grantedTo === currentUserId)

          if (currentUserPermission.role === ROLE_TO_CHECK_EQUALITY_OF_COLLABORATION_ROLES) {
            // editor
            const itemOriginalOwnerId = originalOwners.find((o) => o.itemId === item.id).ownerId
            const itemRawPermissions = this.permissionsByItemId[item.id] || []
            const itemUserPermissions: UserRole[] = itemRawPermissions.map((x: Permission) => ({
              userId: x.grantedTo,
              role: x.role,
            }))
            const hasDifferentRoles = itemUserPermissions.some((itemUserPermission) => {
              const hasPermissions = Object.hasOwn(targetFolderUserIdToRolesMap, itemUserPermission.userId)
              return (
                !hasPermissions ||
                (hasPermissions && targetFolderUserIdToRolesMap[itemUserPermission.userId] !== itemUserPermission.role)
              )
            })

            if (selectedFolderOriginalOwnerId !== itemOriginalOwnerId || hasDifferentRoles) {
              currUserItemsWithLowRoleNames.push(item.name)
            }
          } else {
            const usersToUpgradeOrDowngradeRoles = []
            for (const [userId, role] of Object.entries(targetFolderUserIdToRolesMap)) {
              const hasDiffRole = itemRoles.some(
                (itemRole: Permission) =>
                  // role < itemRole.role means that role on the movable item has lower permissions level then on the target folder
                  // this was true up until Owner role was added with index 3
                  // now its like this CoOwner(0) < Editor(1) < Viewer(2) < Owner(3)
                  // so target folder with role Editor has higher permission level then item role Owner by this logic (Editor < Owner)
                  // and its not true. has to exclude Owner role from this comparison
                  userId === itemRole.grantedTo && itemRole.role !== ItemPermissionsRole.Owner && role < itemRole.role,
              )
              if (role !== ItemPermissionsRole.Owner && hasDiffRole) {
                usersToUpgradeOrDowngradeRoles.push({ userId, role })
              }
            }

            if (usersToUpgradeOrDowngradeRoles.length > 0) {
              itemsRoles.push({ item, userRoles: usersToUpgradeOrDowngradeRoles })
            }
          }

          if (currUserItemsWithLowRoleNames.length > 0) {
            this.isProcessingMoveOrCopy = false
            await this.$refs.notEnoughRole.open(currUserItemsWithLowRoleNames)
            return
          }
        }

        if (itemsRoles.length !== 0) {
          const openForMoveAction = true
          const confirmed = await this.$refs.confirm.open(itemsRoles, this.selectedFolder, openForMoveAction)
          if (!confirmed) {
            this.isProcessingMoveOrCopy = false
            return
          }
        }
      }

      if (this.items && this.items.length) {
        await this.pasteMovedItems(this.selectedFolder)
      } else {
        await this.moveRootItem(this.selectedFolder)
      }

      this.resetIsMoveItems()
      this.unselectAllItems()
      this.$emit('onMoveOrCopy')
      this.close()
    } catch (error) {
      messageService.showErrorMessage(error.message)
    } finally {
      this.isProcessingMoveOrCopy = false
    }
  }

  async copy() {
    if (this.isProcessingMoveOrCopy) {
      return
    }

    try {
      this.isProcessingMoveOrCopy = true
      if (this.selectedFolder) {
        // get folders permissions
        await this.getItemPermissions(this.selectedFolder.id)
        const originalOwners = await fileExplorerApi.getOwnerIdForItems([this.selectedFolder.id], true)

        if (!originalOwners || !originalOwners.length) {
          throw new Error(this.$t('incorrectItemOwnerId') as string)
        }

        const folderRawPermissions = this.permissionsByItemId[this.selectedFolder.id] || []
        const folderRoles: UserRole[] = []
        if (folderRawPermissions) {
          folderRoles.push(
            ...folderRawPermissions.map((x: Permission) => ({ userId: x.grantedTo, role: x.role }) as UserRole),
          )
        }

        folderRoles.push({ userId: originalOwners[0].ownerId, role: ItemPermissionsRole.CoOwner })

        const currentUserId = this.getUserDetails.id
        const itemsRoles: ItemUsersRoles[] = []

        for (const item of this.itemsToMoveOrCopy) {
          // find folder users that will gain access to the file(s) after copy
          const usersToUpgradeRoles = folderRoles.filter(
            (folderRole) =>
              folderRole.userId !== currentUserId &&
              folderRole.role !== ItemPermissionsRole.Owner &&
              folderRole.role !== ItemPermissionsRole.CoOwner,
          )

          if (usersToUpgradeRoles.length > 0) {
            itemsRoles.push({ item, userRoles: usersToUpgradeRoles })
          }
        }

        if (itemsRoles.length !== 0) {
          const confirmed = await this.$refs.confirm.open(itemsRoles, this.selectedFolder)
          if (!confirmed) {
            this.isProcessingMoveOrCopy = false
            return
          }
        }
      }

      const copiedItems = await this.copyItems({ items: this.itemsToMoveOrCopy, rootFolder: this.selectedFolder })
      this.resetIsMoveItems()

      const firstCopiedItemId = copiedItems.length > 0 ? copiedItems[0].id : null
      this.$emit('onCopy', firstCopiedItemId)
      this.$emit('onMoveOrCopy')
      this.close()
    } catch (error) {
      messageService.showErrorMessage(error.message)
    } finally {
      this.isProcessingMoveOrCopy = false
    }
  }

  close() {
    this.setIsMoveItems(false)
    this.$emit('onClose')
  }

  async selectDestination(item: FileExplorerItem) {
    const itemId = item && item.id ? item.id : ROOT_FOLDER_ID
    this.selectedFolder = item && item.id ? item : null
    this.isProcessingMoveOrCopy = true
    const { data: items } = await fileExplorerApi.getItemsInFolder(itemId)
    this.isProcessingMoveOrCopy = false
    this.folderItems = items.filter((folderItem) => folderItem.itemType === ItemType.Folder && !folderItem.isRemoved)
    this.breadcrumbItems = await this.getBreadcrumbsForItem(this.selectedFolder)
    this.hideSearchPanel()
  }

  async searchInput(value) {
    const trimmedValue: string | null | undefined = value.trim()
    this.searchQuery = trimmedValue

    if (!this.searchQuery) {
      this.searchItems = []
      return
    }

    const searchParams: ISearchParamsInterface = {
      searchQuery: this.searchQuery,
    }

    searchParams.itemType = [ItemTypeFilterName.Folder]

    this.isProcessingMoveOrCopy = true
    const searchUrlParams = new URLSearchParams(Object.entries(searchParams))
    this.searchItems = await fileExplorerApi.getItemsBySearchParams(searchUrlParams.toString())
    this.isProcessingMoveOrCopy = false
    this.showSearchPanel()
  }

  async navigationBack() {
    const rootItem = this.breadcrumbItems[this.breadcrumbItems.length - 2]
    this.selectDestination(rootItem)
  }

  get moveDisabled(): boolean {
    if (this.isProcessingMoveOrCopy) {
      return true
    }

    let permission
    let rootIsSubfolderOfSomeSelectedItems

    if (this.selectedFolder && this.selectedFolder.id) {
      permission = this.permissionsByItemId[this.selectedFolder.id]
      const moveItems = this.items.length > 0 ? this.items : [this.item]
      rootIsSubfolderOfSomeSelectedItems = moveItems.some((item: FileExplorerItem) => {
        return this.selectedFolder.path.includes(item.path)
      })
    } else {
      permission = ItemPermissionsRole.Owner
      rootIsSubfolderOfSomeSelectedItems = false
    }

    const rootFolderId = this.rootFolder ? this.rootFolder.id : null
    const selectedFolderId = this.selectedFolder ? this.selectedFolder.id : null

    return (
      (rootFolderId === selectedFolderId && !this.isMultipleInitialParentFolders) ||
      rootIsSubfolderOfSomeSelectedItems ||
      permission === ItemPermissionsRole.Viewer
    )
  }

  get copyDisabled(): boolean {
    if (this.isProcessingMoveOrCopy) {
      return true
    }

    const jobs = this.items.map((item: FileExplorerItem) => this.getRunningJobsByItemId(item.id))
    const hasActiveImportJob = jobs
      .flat(2)
      .some(
        (job: IJob) =>
          job.jobType === JobType.IMPORT && (job.code === JobStatusCode.QUEUED || job.code === JobStatusCode.RUNNING),
      )
    if (hasActiveImportJob) {
      return true
    }

    if (this.selectedFolder && this.selectedFolder.id) {
      const permission = this.permissionsByItemId[this.selectedFolder.id]

      return permission === ItemPermissionsRole.Viewer
    }

    return false
  }

  get orderedFolderItems() {
    return orderBy(this.folderItems, [SORT_BY_NAME], [SORT_ORDER_ASCENDING])
  }

  hideSearchPanel() {
    this.isSearchPanel = false
  }

  showSearchPanel() {
    this.isSearchPanel = true
  }

  private async checkForIbcVariants(): Promise<void> {
    const sinterPlans: FileExplorerItem[] = []
    const ibcPlans: FileExplorerItem[] = []

    this.itemsToMoveOrCopy.forEach((item) => {
      if (item.itemType === ItemType.IbcPlan) {
        ibcPlans.push(item)
      } else if (item.subType === ItemSubType.SinterPlan) {
        sinterPlans.push(item)
      }
    })

    if (ibcPlans.length > 0) {
      this.containsIbcVariants = true
      return
    }

    if (sinterPlans.length > 0) {
      const variantsPromises = sinterPlans.map((item) => buildPlans.getRelatedBuildPlanVariants(item.id))
      const variants = await Promise.all(variantsPromises)
      this.containsIbcVariants = variants.flat().some((variant) => variant.itemType === ItemType.IbcPlan)
    }
  }
}
