import { ActionTree } from 'vuex'
import { ICadHelperState } from './types'
import { IRootState } from '@/store/types'
import cadHelper from '@/api/cadHelper'

export const actions: ActionTree<ICadHelperState, IRootState> = {
  async fetchDownloadLink({ commit }) {
    const link = await cadHelper.getDownloadUrl()
    commit('setDownloadLink', link)

    return link
  },

  async fetchLatestVersion({ commit }) {
    const version = await cadHelper.getLatestVersion()
    commit('setVersion', version)

    return version
  },

  async fetchActualVersion({ commit }) {
    const cadHelperVersion = window.env.VUE_APP_CAD_HELPER_VERSION
    const version =
      !cadHelperVersion || cadHelperVersion === 'latest' ? await cadHelper.getLatestVersion() : cadHelperVersion
    commit('setVersion', version)

    return version
  },
}
