
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

enum ButtonColors {
  Blue = 'blue white--text',
  Grey = 'blue-grey lighten-5 main--text',
}

@Component
export default class Button extends Vue {
  @Prop() id: string
  @Prop() color: string
  @Prop() href: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) loading: boolean
  @Prop({ default: false }) block: boolean
  @Prop() click: Function
  @Prop({ default: 'button' }) type: string
  @Prop() buttonStyle: string

  get buttonStyles(): string {
    const colors = this.buttonColor
    return `text-capitalize text t-14 ${colors}`
  }

  get buttonColor(): string {
    // if color is missing (return default blue color) or color is blue
    if (!this.color || this.color === 'blue') {
      return ButtonColors.Blue
    }

    switch (this.color) {
      case 'grey':
        return ButtonColors.Grey

      default:
        return this.color
    }
  }
}
