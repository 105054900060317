import { PartTypes } from '@/types/BuildPlans/IBuildPlan'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { IPartDto } from '@/types/PartsLibrary/Parts'

export enum PartListSortField {
  Name,
  ModifiedDate,
  CreationDate,
  UsedDate,
}

export type FilterOptions = {
  displayFolderContentOnly: boolean
  displayPartsFromSinterPlans: boolean
  displaySinteredPartsOnly: boolean
  displayIbcPartsOnly: boolean
}

export type PartListItemViewModel = IPartDto & {
  partType: PartTypes
  disabled: boolean
  disabledDescription: string
  previewImageUrl: string
}

export type PartListItemWithPartPathNames = PartListItemViewModel & {
  partPathNames: string[]
}

export type PartListTemViewModelWithUsedDate = PartListItemViewModel & {
  usedDate: Date
}

export type BodyOption = {
  value: number | string | VersionablePk
  name: string
  icon?: string
  iconColor?: string
}
