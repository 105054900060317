import { VersionablePk } from './VersionablePk'

export abstract class VersionableModel {
  public static getPk<T extends VersionableModel>(model: T) {
    return new VersionablePk(model.id, model.version)
  }

  public static getLatestVersion<T extends VersionableModel>(models: T[]): T {
    return models.reduce((prev, curr) => (prev && prev.version > curr.version ? prev : curr), null)
  }

  public static getLatestVersions<M extends VersionableModel>(models: M[]): M[] {
    return models.reduce((prev, curr) => {
      const idx = prev.findIndex((el) => el.id === curr.id)
      if (idx < 0) {
        prev.push(curr)
      } else {
        if (prev[idx].version < curr.version) {
          prev[idx] = curr
        }
      }

      return prev
    }, [] as M[])
  }

  id: number
  version: number
  implicitVersion: boolean
}
