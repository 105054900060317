
import StoresNamespaces from '@/store/namespaces'
import {
  IBinderJetParameterSetContent,
  IBuildPlan,
  IBuildPlanItem,
  IGeometryProperties,
  IPrintStrategyParameterSet,
  ISelectable,
  ProcessState,
} from '@/types/BuildPlans/IBuildPlan'
import { Epsilon, Matrix, Quaternion, Vector3 } from '@babylonjs/core/Maths'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { formatDecimal } from '@/utils/number'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { PrintingTypes } from '@/types/IMachineConfig'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { getDefaultBaseOnType } from '@/utils/parameterSet/parameterSetUtils'
import { ScalingStatus } from '@/constants'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { Visualization } from '@/visualization'
import Icon, { getIconName } from '@/components/icons/Icon.vue'
import i18n from '@/plugins/i18n'
import { IJob } from '@/types/PartsLibrary/Job'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const singlePartVisualizationStore = namespace(StoresNamespaces.SinglePartVisualization)

@Component({
  components: {
    Icon,
  },
})
export default class BuildPlanPartFooter extends Vue {
  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter('getAllBuildPlanItems') getAllBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter parameterSets: IPrintStrategyParameterSet[]
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes
  @buildPlansStore.Getter getBuildPlanPrintStrategy: BuildPlanPrintStrategyDto
  @buildPlansStore.Getter('getSelectedParts') getSelectedItems: ISelectable[]
  @buildPlansStore.Getter getSelectedBuildPlanFinalizingJobs: IJob[]

  @visualizationStore.Getter getSelectionBoundingBox: () => object
  @visualizationStore.Getter getSelectedParts: () => number
  @singlePartVisualizationStore.State visualization: Visualization

  @Prop() selectedParts: ISelectable[]
  @Prop({ default: false }) showEmptyFooter: boolean

  scalingStatus: string = ''
  scalingStatusIcon: string = ''
  scalingStatusTooltip: string = ''
  partSize: string = ''
  partRotationAngles: string = ''
  partTranslation: string = ''
  partSurfaceArea: string = ''
  partVolume: string = ''
  isExtended: boolean = false
  showPartRotationAngles: boolean = true
  showPartTranslation: boolean = true
  isScaled = false

  mounted() {
    this.init()
  }

  async init() {
    let selectedItem: IBuildPlanItem = null
    const geometriesProperties: IGeometryProperties[] = []
    const translations: Vector3[] = []
    const transformationMatrices: number[][] = []

    if (this.showEmptyFooter) {
      this.setEmptyFooterValues()
      return
    }

    const isReplaceOrAddViewMode = [ViewModeTypes.Part, ViewModeTypes.Replace].includes(this.getBuildPlanViewMode)
    if (isReplaceOrAddViewMode && this.selectedParts[0].geometryProperties) {
      geometriesProperties.push(this.selectedParts[0].geometryProperties)
      this.showPartRotationAngles = false
      this.showPartTranslation = false
    } else {
      this.showPartRotationAngles = true
      this.showPartTranslation = true
      this.selectedParts.forEach((part) => {
        selectedItem = this.buildPlan.buildPlanItems.find((item) => item.id === part.id)
        if (!selectedItem) {
          return
        }

        geometriesProperties.push(selectedItem.geometryProperties)
        translations.push(part.translation)
        const partProps = selectedItem.partProperties[0]
        if (
          this.buildPlan.subType !== ItemSubType.SinterPlan &&
          this.buildPlan.modality === PrintingTypes.BinderJet &&
          partProps.processState === ProcessState.Nominal
        ) {
          const transformMatrix = []
          selectedItem.transformationMatrix.forEach((value) => transformMatrix.push(value))
          let printStrategyParameterSetPk: VersionablePk
          if (partProps.printStrategyParameterSetId) {
            printStrategyParameterSetPk = new VersionablePk(
              partProps.printStrategyParameterSetId,
              partProps.printStrategyParameterSetVersion,
            )
          } else {
            const defaults = this.getBuildPlanPrintStrategy.defaults
            printStrategyParameterSetPk = getDefaultBaseOnType(defaults, partProps.type, partProps.bodyType)
          }
          const result = this.removeParameterSetScale(
            transformMatrix,
            printStrategyParameterSetPk.id,
            printStrategyParameterSetPk.version,
          )
          transformationMatrices.push(result)
        } else {
          transformationMatrices.push(selectedItem.transformationMatrix)
        }
      })
    }

    if (this.buildPlan.modality === PrintingTypes.BinderJet) {
      this.setScalingStatus(this.selectedParts)
    }

    // consider here the case when we are just adding a part to the build plan
    if (isReplaceOrAddViewMode && this.selectedParts.length === 1) {
      const partsBoundingBox = await this.visualization.getPartsBoundingBox()
      if (partsBoundingBox) {
        this.setSelectionDimensionsFromBBox(partsBoundingBox)
      }
    } else {
      const selectedPartsBoundingBox = this.getSelectionBoundingBox()
      this.setSelectionDimensionsFromBBox(selectedPartsBoundingBox)
    }

    this.setPartSurfaceArea(geometriesProperties)
    this.setPartVolume(geometriesProperties)
    this.setPartTranslation(translations)
    this.setPartRotationAngles(transformationMatrices)
  }

  getIconName(view: string) {
    return getIconName(view)
  }

  setScalingStatus(selectedParts: ISelectable[]) {
    this.isScaled = false

    if (this.buildPlan.subType === ItemSubType.SinterPlan) {
      const isSimulated = this.getSelectedBuildPlanFinalizingJobs.length

      if (isSimulated) return

      this.scalingStatus = 'nominal'
      this.scalingStatusIcon = 'mdi-resize'
      this.scalingStatusTooltip = 'partPropsToolbar.ampScaledTooltip'

      return
    }

    const partScalingStatuses = []
    let scalingStatus = ''
    selectedParts.forEach((part) => {
      if (!part.id) {
        return
      }
      const selectedItem = this.buildPlan.buildPlanItems.find((item) => item.id === part.id)
      if (!selectedItem) {
        return
      }

      if ([ItemSubType.SinterPart, ItemSubType.IbcPart].includes(selectedItem.part.subType)) {
        if (selectedItem.part.isPublishedAsScaled) {
          scalingStatus = ScalingStatus.CompensatedAndSimulationScaled
        } else if (selectedItem.partProperties.every((prop) => prop.processState === ProcessState.Nominal)) {
          scalingStatus = ScalingStatus.CompensatedAndScaled
        } else if (selectedItem.partProperties.every((prop) => prop.processState === ProcessState.Green)) {
          scalingStatus = ScalingStatus.CompensatedAndNotScaled
        }
      } else {
        if (selectedItem.partProperties.every((prop) => prop.processState === ProcessState.Nominal)) {
          scalingStatus = ScalingStatus.Scaled
        } else if (selectedItem.partProperties.every((prop) => prop.processState === ProcessState.Green)) {
          scalingStatus = ScalingStatus.Unscaled
        }
      }
      if (!partScalingStatuses.includes(scalingStatus)) {
        partScalingStatuses.push(scalingStatus)
      }
    })

    // only show scaling status if all selected parts share the same one
    if (partScalingStatuses.length === 1) {
      this.scalingStatus = partScalingStatuses[0]
      switch (this.scalingStatus) {
        case ScalingStatus.Scaled:
          this.scalingStatus = 'scaled'
          this.scalingStatusIcon = 'mdi-resize'
          this.scalingStatusTooltip = 'buildPlanFooter.part.scaled'
          break
        case ScalingStatus.Unscaled:
          this.scalingStatus = 'unscaled'
          this.scalingStatusIcon = 'mdi-rectangle-outline'
          this.scalingStatusTooltip = 'buildPlanFooter.part.unscaled'
          break
        case ScalingStatus.CompensatedAndScaled:
          this.scalingStatus = 'compensatedAndScaled'
          this.scalingStatusTooltip = 'buildPlanFooter.part.compensatedAndScaled'
          this.isScaled = true
          break
        case ScalingStatus.CompensatedAndNotScaled:
          this.scalingStatus = 'compensatedAndNotScaled'
          this.scalingStatusIcon = 'mdi-car-windshield-outline'
          this.scalingStatusTooltip = 'buildPlanFooter.part.compensatedAndNotScaled'
          break
        case ScalingStatus.CompensatedAndSimulationScaled:
          this.scalingStatus = 'ampCompensatedAndSimulationScaled'
          this.scalingStatusTooltip = 'buildPlanFooter.part.CompensatedAndSimulationScaled'
          this.isScaled = true
          break
      }
    } else {
      this.scalingStatus = ''
      this.scalingStatusIcon = ''
      this.scalingStatusTooltip = ''
    }
  }

  setSelectionDimensionsFromBBox(selectedPartsBoundingBox) {
    if (selectedPartsBoundingBox.boundingBox.minimum && selectedPartsBoundingBox.boundingBox.maximum) {
      const minimum = selectedPartsBoundingBox.boundingBox.minimum
      const maximum = selectedPartsBoundingBox.boundingBox.maximum
      const xDimensionString = formatDecimal(Math.abs(maximum.x - minimum.x), 3).toLocaleString()
      const yDimensionString = formatDecimal(Math.abs(maximum.y - minimum.y), 3).toLocaleString()
      const zDimensionString = formatDecimal(Math.abs(maximum.z - minimum.z), 3).toLocaleString()
      this.partSize = `${xDimensionString} X ${yDimensionString} X ${zDimensionString} ${i18n.t('millimeterAbbr')}`
    } else {
      this.partSize = `N/A`
    }
  }

  setPartRotationAngles(transformationMatrices: number[][]) {
    if (this.hasIdenticalTransformations(transformationMatrices)) {
      const transformationMatrix: number[] = transformationMatrices[0]
      const matrix = Matrix.FromArray(transformationMatrix).transpose().getRotationMatrix()

      // make it negative just because we used left-handed matrices
      const r02 = Math.abs(matrix.getRow(0).z) < Epsilon ? 0 : -matrix.getRow(0).z
      const r12 = Math.abs(matrix.getRow(1).z) < Epsilon ? 0 : -matrix.getRow(1).z
      const r22 = Math.abs(matrix.getRow(2).z) < Epsilon ? 0 : matrix.getRow(2).z
      const r01 = Math.abs(matrix.getRow(0).y) < Epsilon ? 0 : -matrix.getRow(0).y
      const r00 = Math.abs(matrix.getRow(0).x) < Epsilon ? 0 : matrix.getRow(0).x
      const r10 = Math.abs(matrix.getRow(1).x) < Epsilon ? 0 : matrix.getRow(1).x
      const r11 = Math.abs(matrix.getRow(1).y) < Epsilon ? 0 : matrix.getRow(1).y

      let thetaY
      let thetaX
      let thetaZ

      if (r02 < 1) {
        if (r02 > -1) {
          thetaY = Math.asin(r02)
          thetaX = Math.atan2(-r12, r22)
          thetaZ = Math.atan2(-r01, r00)
        } else {
          // r02 === -1
          // Not unique solution? thetaZ - thetaX = Math.atan2(r10, r11)
          thetaY = -Math.PI / 2
          thetaX = -Math.atan2(r10, r11)
          thetaZ = 0
        }
      } else {
        // r01 === 1
        // Not unique solution? thetaZ + thetaX = Math.atan2(r10, r11)
        thetaY = Math.PI / 2
        thetaX = Math.atan2(r10, r11)
        thetaZ = 0
      }

      const xRot = formatDecimal((thetaX * 180) / Math.PI, 3).toLocaleString()
      const yRot = formatDecimal((thetaY * 180) / Math.PI, 3).toLocaleString()
      const zRot = formatDecimal((thetaZ * 180) / Math.PI, 3).toLocaleString()

      this.partRotationAngles = `${xRot},  ${yRot},  ${zRot} ${i18n.t('degreeAbbr')}`
    } else {
      this.partRotationAngles = `-`
    }
  }

  setPartTranslation(translations: Vector3[]) {
    if (this.hasIdenticalTranslations(translations)) {
      const translation: Vector3 = translations[0]
      const xTranslation = this.negativeZeroToPositive(formatDecimal(translation.x, 3)).toLocaleString()
      const yTranslation = this.negativeZeroToPositive(formatDecimal(translation.y, 3)).toLocaleString()
      const zTranslation = this.negativeZeroToPositive(formatDecimal(translation.z, 3)).toLocaleString()
      this.partTranslation = `${xTranslation},  ${yTranslation},  ${zTranslation} ${i18n.t('millimeterAbbr')}`
    } else {
      this.partTranslation = `-`
    }
  }

  negativeZeroToPositive(number: string) {
    return number.replace(/^-([.0]*)$/, '$1')
  }

  setPartSurfaceArea(geometriesProperties: IGeometryProperties[]) {
    let totalSurfaceArea = 0
    geometriesProperties.forEach((geometryProperties) => {
      if (geometryProperties && geometryProperties.surfaceArea) {
        totalSurfaceArea += geometryProperties.surfaceArea
        if (geometryProperties.supportSurfaceArea) {
          totalSurfaceArea += geometryProperties.supportSurfaceArea
        }
      }
    })
    if (totalSurfaceArea > 0) {
      this.partSurfaceArea = `${formatDecimal(totalSurfaceArea, 0).toLocaleString()} ${i18n.t('millimeterSquaredAbbr')}`
    } else {
      this.partSurfaceArea = '-'
    }
  }

  setPartVolume(geometriesProperties: IGeometryProperties[]) {
    let totalVolume = 0
    geometriesProperties.forEach((geometryProperties) => {
      if (geometryProperties && geometryProperties.volume) {
        totalVolume += geometryProperties.volume
        if (geometryProperties.supportVolume) {
          totalVolume += geometryProperties.supportVolume
        }
      }
    })
    if (totalVolume > 0) {
      this.partVolume = `${formatDecimal(totalVolume, 0).toLocaleString()} ${i18n.t('millimeterCubedAbbr')}`
    } else {
      this.partVolume = '-'
    }
  }

  @Watch('getAllBuildPlanItems', { deep: true })
  @Watch('getSelectedItems', { deep: true })
  onBuildPlanItemsChange() {
    this.init()
  }

  @Watch('selectedParts')
  inputChanged() {
    this.init()
  }

  private hasIdenticalTransformations(transformationMatrices: number[][]) {
    let hasIndentical = true
    if (transformationMatrices) {
      if (transformationMatrices.length === 0) {
        return false
      }
      if (transformationMatrices.length > 1) {
        const baseTransfomation: number[] = transformationMatrices[0]
        transformationMatrices.every((transformationMatrix, index) => {
          if (
            !(
              this.isEqualWithEpsilon(baseTransfomation[0], transformationMatrix[0]) &&
              this.isEqualWithEpsilon(baseTransfomation[4], transformationMatrix[4]) &&
              this.isEqualWithEpsilon(baseTransfomation[8], transformationMatrix[8]) &&
              this.isEqualWithEpsilon(baseTransfomation[9], transformationMatrix[9]) &&
              this.isEqualWithEpsilon(baseTransfomation[10], transformationMatrix[10])
            )
          ) {
            hasIndentical = false
            return false
          }
          return true
        })
        return hasIndentical
      }
    }
    return hasIndentical
  }

  private isEqualWithEpsilon(number1: number, number2: number, epsilon: number = 1e-6) {
    return Math.abs(number1 - number2) < epsilon
  }

  private hasIdenticalTranslations(translations: Vector3[]) {
    let hasIndentical = true
    if (translations) {
      if (translations.length === 0) {
        return false
      }
      if (translations.length > 1) {
        const translationStr: string = JSON.stringify(translations[0])
        translations.every((translation) => {
          if (translationStr !== JSON.stringify(translation)) {
            hasIndentical = false
            return false
          }
          return true
        })
        return hasIndentical
      }
    }
    return hasIndentical
  }

  private removeParameterSetScale(
    matrix: number[],
    printStrategyParameterSetId: number,
    printStrategyParameterSetVersion: number,
  ) {
    const bjPartParameters = this.parameterSets.find(
      (p) => p.id === printStrategyParameterSetId && p.version === printStrategyParameterSetVersion,
    ).parameterSet.partParameters as IBinderJetParameterSetContent

    // R * Sp * Sp^(-1)
    // R - rotate component
    // Sp - parameter set scale component

    const transformationMatrix = Matrix.FromArray(matrix)
    const translation = transformationMatrix.getTranslation()
    transformationMatrix.setTranslation(Vector3.Zero())

    const scaleMatrix = Matrix.Scaling(
      1 / bjPartParameters.ScaleFactors.ScaleFactorX,
      1 / bjPartParameters.ScaleFactors.ScaleFactorY,
      1 / bjPartParameters.ScaleFactors.ScaleFactorZ,
    )
    const unscaledMatrix = scaleMatrix.multiply(transformationMatrix)
    unscaledMatrix.setTranslation(translation)

    const result = []

    unscaledMatrix.asArray().forEach((item) => result.push(item))
    return result
  }

  private setEmptyFooterValues() {
    this.showPartRotationAngles = false
    this.showPartTranslation = false
    this.partSize = ''
    this.partVolume = ''
    this.partSurfaceArea = ''
    this.scalingStatus = ''
    this.scalingStatusIcon = ''
    this.scalingStatusTooltip = ''
  }
}
