import { IJobsState } from '@/store/modules/jobs/types'
import { Module } from 'vuex'
import { IRootState } from '@/store/types'
import { getters } from '@/store/modules/jobs/getters'
import { actions } from '@/store/modules/jobs/actions'
import { mutations } from '@/store/modules/jobs/mutations'

export const state: IJobsState = {
  jobs: [],
  printOrder: null,
  printOrders: [],
  printOrdersLoading: false,
}

const namespaced: boolean = true

export const jobs: Module<IJobsState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
