import { GetterTree } from 'vuex'
import { ICostEstimationState } from './types'
import { IRootState } from '@/store/types'
import { EstimationCostInputType, ICostEstimation } from '@/types/CostEstimations/ICostEstimation'
import { IMaterial } from '@/types/IMaterial'
import { IMachineConfig } from '@/types/IMachineConfig'
import { ViewModes } from '@/constants'
import { IParameterSet } from '@/types/BuildPlans/IBuildPlan'
import { FilterParamsMap } from '@/types/Filter'

const DEFAULT_REGION_INDEX = 1 // 0 - Europe, 1 - Americas

export const getters: GetterTree<ICostEstimationState, IRootState> = {
  isLoading(state) {
    const { numberOfPendingRequests } = state.dataState
    return numberOfPendingRequests > 0
  },

  isError(state) {
    const { numberOfFailedRequests } = state.dataState
    return numberOfFailedRequests > 0
  },

  getErrorText(state) {
    return state.dataState.numberOfFailedRequests > 0 && state.dataState.failedMessages[0]
  },

  getSelectedCostEstimation(state) {
    return state.selectedCostEstimation
  },

  getSelectedMaterial(state): number {
    return state.selectedCostEstimation && state.selectedCostEstimation.materialId
  },

  getSelectedMachineConfigs(state): number[] {
    return !state.selectedCostEstimation
      ? []
      : state.selectedCostEstimation.costEstimationSet
          .filter((set) => set.machineConfigId)
          .map((set) => set.machineConfigId)
  },

  getEnabledMachineConfigs(state, allGetters): number[] {
    if (!state.selectedCostEstimation) {
      return
    }

    return allGetters
      .getMachineConfigsByMaterialId(state.selectedCostEstimation.materialId)
      .map((machineConfig) => machineConfig.id)
  },

  getMaterials(state): IMaterial[] {
    if (!state) {
      return
    }

    return state.materials
      .filter((material) => state.parameterSets.some((parameterSet) => parameterSet.materialId === material.id))
      .sort((a, b) => a.name.localeCompare(b.name))
  },

  getParameterSets(state): IParameterSet[] {
    return state && state.parameterSets
  },

  getMaterialById:
    (state) =>
    (id: number): IMaterial | undefined => {
      return state.materials.find((material) => material.id === id)
    },

  getRegions(state): string[] {
    return state && state.regions
  },

  getDefaultRegion(state): string | undefined {
    return state && state.regions[DEFAULT_REGION_INDEX]
  },

  getMachineConfigs(state): IMachineConfig[] {
    return state && state.machineConfigs
  },

  getParameterSetsByMaterialId: (state) => (id) => {
    return state.parameterSets.filter((set) => set.materialId === id)
  },

  getMachineConfigById:
    (state) =>
    (id: number): IMachineConfig => {
      return state && state.machineConfigs.find((machine) => machine.id === id)
    },

  // get all cost estimations without pagination and filters
  getFilteredCostEstimations(state): ICostEstimation[] {
    return state && state.filteredCostEstimations
  },

  // get cost estimations with pagination
  getAllCostEstimations(state): ICostEstimation[] {
    return state && state.allCostEstimations
  },

  getAllCostEstimationsCount(state): number {
    return state && state.allCostEstimationsCount
  },

  getFilteredCostEstimationsCount(state): number {
    return state && state.filteredCostEstimationsCount
  },

  getMachineConfigsByMaterialId:
    (state, allGetters) =>
    (id: number): IMachineConfig[] => {
      if (!state || !state.machineConfigs) {
        return
      }

      const paramSets: IParameterSet[] = allGetters.getParameterSetsByMaterialId(id)
      return state.machineConfigs.filter((machineConfig) =>
        paramSets.some((set) => machineConfig.machineName === set.machineName),
      )
    },

  getDefaultCostEstimation(): Omit<ICostEstimation, 'costEstimationSet'> {
    return {
      projectName: 'Cost Study',
      modelName: '',
      length: 0,
      width: 0,
      height: 0,
      volume: 0,
      materialId: 0,
      manufacturingRegion: '',
      numberOfPartsPerYear: 0,
      totalVolumeOnFirstLaser: 0,
      supportStructureRatio: 0,
      trappedPowderWaste: 0,
      userId: '',
      unitsId: 0,
      surfaceArea: 0,
      imageUrl: '',
      inputType: EstimationCostInputType.Upload,
    }
  },

  getCostEstimationById: (state) => (id: number | string) => {
    return state.allCostEstimations.find((o) => o.id === id)
  },

  getPaginationLimit(state): number {
    return state && state.paginationLimit
  },

  getPaginationOffset(state): number {
    return state && state.paginationOffset
  },

  getPaginationCurrentPage(state): number {
    return state.paginationOffset ? state.paginationOffset / state.paginationLimit + 1 : 1
  },

  getSelectedViewMode(state): ViewModes {
    return state && state.selectedViewMode
  },

  getPageByPaginationOffset(state): number {
    if (!state) {
      return
    }

    return state.paginationOffset ? state.paginationOffset / state.paginationLimit + 1 : 1
  },

  getSearchEstimationName(state): string {
    return state && state.searchEstimationName
  },

  getSelectedFiltersIds(state): number[] {
    return state && state.selectedFiltersIds
  },

  getSelectedSortModeId(state): string {
    return state && state.selectedSortModeId
  },

  getHasActiveFilters(state) {
    return Object.values(state.filterParams).some((values) => values.length)
  },

  getFilterParams(state): FilterParamsMap {
    return state.filterParams
  },
}
