import { render, staticRenderFns } from "./TenantAdminContentTableControls.vue?vue&type=template&id=79b69a34&scoped=true"
import script from "./TenantAdminContentTableControls.vue?vue&type=script&lang=ts"
export * from "./TenantAdminContentTableControls.vue?vue&type=script&lang=ts"
import style0 from "./TenantAdminContentTableControls.vue?vue&type=style&index=0&id=79b69a34&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79b69a34",
  null
  
)

export default component.exports