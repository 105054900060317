import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import EndCollaborationMixin from '@/components/layout/FileExplorer/Table/mixins/EndCollaborationMixin'
import ModalsStateMixin from '@/components/layout/FileExplorer/Table/mixins/ModalsStateMixin'
import { RowSelection } from '@/components/layout/FileExplorer/Table/mixins/RowSelection'
import TableMixin from '@/components/layout/FileExplorer/Table/mixins/TableMixin'
import UnshareUserMixin from '@/components/layout/FileExplorer/Table/mixins/UnshareUserMixin'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'

import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { FilterParamsKey } from '@/types/FileExplorer/FilterParamsKey'
import { ItemDetailsType } from '@/types/FileExplorer/ItemDetails'
import { ItemType } from '@/types/FileExplorer/ItemType'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import { DetailsPanelViewMode, ViewMode } from '@/types/FileExplorer/ViewMode'

import { DEFAULT_IS_ALLOWED_TO_BE_TRASHED_SELECTED_ITEMS } from '@/constants'
import { eventBus } from '@/services/EventBus'
import StoresNamespaces from '@/store/namespaces'
import { FileExplorerEvent } from '@/types/FileExplorer/FileExplorerEvent'
import { ItemCollaborationDto } from '@/types/FileExplorer/Permission'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { isOfType } from '@/utils/common'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

const ITEMS_TABLE_ID = 'items_list_table'
const TABLE_ITEM_ID_PATTERN = 'table_item_'

@Component
export default class AllFilesSearchViewMixin extends Mixins(
  TableMixin,
  RowSelection,
  ModalsStateMixin,
  UnshareUserMixin,
  EndCollaborationMixin,
) {
  @fileExplorerStore.Action deletePermission: (payload: {
    permissionId: string
    fullDeletion?: boolean
  }) => Promise<void>
  @fileExplorerStore.Action fetchItemsPermissionsByIds: (ids: string[]) => Promise<ItemCollaborationDto[]>

  @fileExplorerStore.Getter getClickedNameItem: FileExplorerItem
  @fileExplorerStore.Getter getItemsFromRoot: FileExplorerItem[]
  @fileExplorerStore.Getter getRenameDialogState: boolean
  @fileExplorerStore.Getter getItemDetails: ItemDetailsType
  @fileExplorerStore.Getter isAllowedToEndCollaboration: boolean

  @fileExplorerStore.Mutation deleteItem: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation setIsAllowedToEndCollaboration: (value: boolean) => void

  $refs!: {
    confirm: InstanceType<typeof ConfirmModal>
  }

  tableViewMode = ViewMode
  filterType = FilterParamsKey.AllFiles
  tableItemIdPattern = TABLE_ITEM_ID_PATTERN
  itemsTableId = ITEMS_TABLE_ID
  existingNames: string[] = []

  isShownContextMenu: boolean = false
  contextMenu: { x: number; y: number; isShown: boolean; parentId: string } = {
    isShown: false,
    x: null,
    y: null,
    parentId: null,
  }

  get canRename() {
    const isSingleItemSelected = this.getSelectedItems.length === 1
    const noItemsSelected = this.getSelectedItem && this.getSelectedItems.length === 0
    return (isSingleItemSelected || noItemsSelected) && this.canRenameItem
  }

  @Watch('lastSelectedItem')
  async onSelectedItemsChanged() {
    const isAllowedToEndCollaboration = await this.checkAccessToEndCollaboration()
    this.setIsAllowedToEndCollaboration(isAllowedToEndCollaboration)
  }

  async checkAccessToEndCollaboration(itemIds?: string[]) {
    const selectedItemIds = itemIds ? itemIds : this.getSelectedItems.map((item) => item.id)

    if (selectedItemIds.length === 0) {
      return false
    }

    await this.fetchItemsPermissionsByIds(selectedItemIds)
    const currentUserId = this.getUserDetails.id

    for (const id of selectedItemIds) {
      if (!this.getItemPermissionCollaboratorsById(id)) continue

      const element = this.getItemPermissionCollaboratorsById(id).find((c) => c.grantedTo === currentUserId)

      if (!element) return false
    }

    return true
  }

  async setupContextMenu(item: FileExplorerItem | PrintOrder, x, y) {
    // when context menu called - only its item has to be selected
    if (!this.getSelectedItem || this.getSelectedItem.id !== item.id) {
      const payload = { item, ctrlKey: false, shiftKey: false }
      await this.onItemListRowClick(payload)
    }

    this.contextMenu = {
      x,
      y,
      isShown: true,
      parentId: item.id,
    }
  }

  async openItem(item: FileExplorerItem) {
    if (item.itemType === ItemType.Folder) {
      this.setDetailsPanelMode(DetailsPanelViewMode.Default)
    }
  }

  async onItemListRowClick(payload) {
    this.setSelectedItemsCanBeTrashed(DEFAULT_IS_ALLOWED_TO_BE_TRASHED_SELECTED_ITEMS)

    const item: FileExplorerItem = payload.item

    payload.item = await this.refreshItemIfNeeded(item)

    await this.onItemClick(payload, !isOfType<PrintOrder>(payload.item, 'site'))
    this.defineDetailsViewMode()
  }

  onManageCollaborators(item: FileExplorerItem) {
    this.setDetailsPanelMode(DetailsPanelViewMode.ManageCollaborators)
  }

  onRowMenuItemClick(item: FileExplorerItem) {
    this.selectItem({
      item,
      selectionType: SelectionTypes.Single,
    })
    this.defineDetailsViewMode()
  }

  async endCollaborationOnSelectedItem(items?: FileExplorerItem[]) {
    await this.onRemoveFromCollaborationClick(items || this.getSelectedItems, this.$refs.confirm)
  }

  onScroll() {
    eventBus.$emit(FileExplorerEvent.Scroll)
  }
}
