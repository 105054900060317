
import { namespace } from 'vuex-class'
import { BodyMenuType, GeometryType, PartProperty, ProcessState, IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import { Component } from 'vue-property-decorator'
import { debounce } from '@/utils/debounce'
import { BodyOption, PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import Selector from '@/components/controls/Common/Selector.vue'
import BodyDropdownMenu from '@/components/layout/buildPlans/addPart/BodyDropdownMenu.vue'
import PartPropertyItemMixin from '@/components/layout/buildPlans/mixins/PartPropertyItemMixin'
import { VersionablePk } from '@/types/Common/VersionablePk'
import StoresNamespaces from '@/store/namespaces'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component({
  components: {
    BodyDropdownMenu,
    Selector,
  },
})
export default class SinglePartPropertyItem extends PartPropertyItemMixin {
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter('getAddPartToolSelectedParts') selectedParts: PartListItemViewModel[]

  isProcessStateHovered = false
  hoverProcessStateDebounced = debounce(this.tooltipOpenDelay, this.hoverProcessState)

  get processStateOptions(): BodyOption[] {
    const options = [
      {
        value: ProcessState.Nominal,
        name: this.$t('applyScaling').toString(),
        icon: 'mdi-resize',
      },
      {
        value: ProcessState.Green,
        name: this.isPartLegacy ? this.$t('simulationScaled').toString() : this.$t('doNotScale').toString(),
        icon: this.isPartLegacy ? 'mdi-resize' : 'mdi-rectangle-outline',
      },
    ]

    return options.map((option) => {
      const name: string = option.name.toString()
      option.name = name.charAt(0).toUpperCase() + name.substring(1)
      return option
    })
  }

  get isProcessStateDisabled() {
    return this.disabled || this.isSinterPlan || this.isPartLegacy
  }

  get isPartLegacy() {
    const selectedPart = this.selectedParts.length && this.selectedParts[0]

    if (selectedPart) return selectedPart.isPublishedAsScaled

    return this.bpItem && this.bpItem.part.isPublishedAsScaled
  }

  get processStateName() {
    if (this.item.processState === ProcessState.Nominal) {
      return this.$t('applyScaling')
    }

    if (this.item.processState === ProcessState.Green) {
      return this.isPartLegacy ? this.$t('simulationScaled').toString() : this.$t('doNotScale').toString()
    }

    return '-'
  }

  get processStateMenuType() {
    return BodyMenuType.ProcessState
  }

  onBodyProcessStateChange(processState: ProcessState) {
    const property: PartProperty = {
      ...this.item,
      processState,
    }
    this.$emit('property-copy-all', property)
  }

  onBodyTypeChange(type: GeometryType) {
    const property: PartProperty = {
      ...this.item,
      type,
    }

    this.updateSceneGeometriesOnTypeChange(property, { copyAll: true })
    this.$emit('property-copy-all', property)
    this.$emit('body-function-change', { before: this.item, after: property, bpItem: this.bpItem })
  }

  onBodyParameterSetChange(printStrategyParameterSetPk: VersionablePk) {
    const property: PartProperty = {
      ...this.item,
      printStrategyParameterSetId: printStrategyParameterSetPk.id,
      printStrategyParameterSetVersion: printStrategyParameterSetPk.version,
      type: this.item.type,
    }
    this.$emit('property-copy-all', property)
  }

  hoverProcessState(value: boolean) {
    this.isProcessStateHovered = value
  }
}
