import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { IPartsState } from './types'
import { IRootState } from '@/store/types'
import { partPreviewDefaultState } from '@/types/Parts/IPartPreview'

export const state: IPartsState = {
  parts: [],
  sinterParts: [],
  partIdsWithUsedDate: [],
  partPreview: { ...{}, ...partPreviewDefaultState },
  ibcParts: [],
}

const namespaced: boolean = true

export const parts: Module<IPartsState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
