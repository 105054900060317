
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'

const userStore = namespace(StoresNamespaces.User)

@Component
export default class AccountActivationModal extends Vue {
  @Prop() visible: boolean
  @Prop() userId: string
  @Prop() onOk: (string, boolean) => void

  @userStore.Action('getAccountActivationLink') getAccountActivationLink: any

  accountActivationLink: string = ''

  @Watch('visible')
  async onVisibleChanged(val) {
    if (val) {
      this.accountActivationLink = await this.getAccountActivationLink(this.userId)
    } else {
      this.accountActivationLink = ''
    }
  }

  copyLinkToClipboard() {
    const activationLinkEl: any = document.querySelector('#activation-link')
    activationLinkEl.setAttribute('type', 'text')
    activationLinkEl.select()
    document.execCommand('copy')
  }
}
