
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { LabelTooltipMixin } from '@/components/layout/buildPlans/marking/mixins/LabelTooltipMixin'
import StoresNamespaces from '@/store/namespaces'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { TextElement } from '@/types/Label/TextElement'

const labelStore = namespace(StoresNamespaces.Labels)

@Component
export default class PrintOrderTooltip extends Mixins(LabelTooltipMixin) {
  @labelStore.Getter labelSets: InteractiveLabelSet[]

  @Prop() tooltipElement: TextElement
}
