import { ActionTree } from 'vuex'
import { IRootState } from '@/store/types'
import { IUndoableState } from '@/store/modules/commandManager/types'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { SaveSupportsCommand } from '@/types/UndoRedo/SaveSupportsCommand'

const GET_COMMAND_TYPE = 'buildPlans/getCommandType'

export const actions: ActionTree<IUndoableState, IRootState> = {
  async undoCommand({ state, rootGetters, commit }): Promise<void> {
    try {
      commit('buildPlans/setIsLoading', true, { root: true })

      const commandType: CommandType = rootGetters[GET_COMMAND_TYPE]
      const command: ICommand =
        commandType === CommandType.BuildPlanCommand ? state.buildPlanUndoStack.pop() : state.toolUndoStack.pop()

      await command.undo()

      command.commandType === CommandType.BuildPlanCommand || command instanceof SaveSupportsCommand
        ? state.buildPlanRedoStack.push(command)
        : state.toolRedoStack.push(command)
    } finally {
      commit('visualizationModule/refreshInsights', null, { root: true })
      commit('buildPlans/setIsLoading', false, { root: true })
    }
  },

  async redoCommand({ state, rootGetters, commit }): Promise<void> {
    try {
      commit('buildPlans/setIsLoading', true, { root: true })

      const commandType: CommandType = rootGetters[GET_COMMAND_TYPE]
      const command: ICommand =
        commandType === CommandType.BuildPlanCommand ? state.buildPlanRedoStack.pop() : state.toolRedoStack.pop()

      await command.redo()

      command.commandType === CommandType.BuildPlanCommand || command instanceof SaveSupportsCommand
        ? state.buildPlanUndoStack.push(command)
        : state.toolUndoStack.push(command)
    } finally {
      commit('visualizationModule/refreshInsights', null, { root: true })
      commit('buildPlans/setIsLoading', false, { root: true })
    }
  },
}
