
import Vue from 'vue'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import StoresNamespaces from '@/store/namespaces'
import Icon, { IconNames } from '@/components/icons/Icon.vue'

const commonStore = namespace(StoresNamespaces.Common)

@Component({ components: { Icon } })
export default class ViewButton extends Vue {
  @commonStore.Getter tooltipOpenDelay: number

  @Prop({ default: false }) disabled: boolean
  @Prop({ default: '' }) testAttribute: string
  @Prop() click: Function
  @Prop({ default: '' }) icon: string
  @Prop({ default: '' }) tooltip: any

  get iconNameFitsSvgCollection() {
    return Object.values<string>(IconNames).includes(this.icon)
  }
}
