
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { DocumentModel } from '@/visualization/models/DataModel'

const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component({
  name: 'ComponentTreeItem',
  components: {},
})
export default class ComponentTreeItem extends Vue {
  @Prop() treeItem: DocumentModel
  @visualizationStore.Getter('selectedComponentsIds') selectedComponentsIds: string[]
  @visualizationStore.Getter('isInitialized') isInitialized: boolean
  @visualizationStore.Getter('isShowingGizmos') isShowingGizmos: boolean
  @visualizationStore.Getter('viewMode') viewMode: { name: string; isSelectable: boolean }
  @visualizationStore.Mutation('selectComponent') selectComponent: Function
  @visualizationStore.Mutation('hideGizmos') hideGizmos: Function

  select(id: string, attach: boolean) {
    if (this.isInitialized && this.viewMode.isSelectable) {
      if (this.isShowingGizmos) {
        this.hideGizmos()
      }

      this.selectComponent({ attach, componentId: id })
    }
  }
}
