import { LabeledBody } from '@/types/Label/LabeledBody'
import { ObjectWithId } from '@/types/Label/ObjectWithId'
import { createGuid } from '@/utils/common'
import { Placement } from './Placement'
import { getBuildPlanItemTransformationWithoutScale } from '@/utils/label/labelUtils'
import store from '@/store'
import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'

export interface LabeledBodyWIthTransformation extends LabeledBody, ObjectWithId {
  // TODO: Need to remove optional sign for this property
  transformation?: number[]
}

/**
 * Creates labeled body with Transformation
 * @param {string} buildPlanItemId Build plan item id
 * @param {string} componentId Component id
 * @param {string} geometryId Geometry id
 * @param {string} partId Part id
 * @param {Array<number>} transformation Transformation without scale
 * @returns {LabeledBodyWIthTransformation} Labeled body with transformation
 */
export const createLabeledBodyWithTransformation = (
  buildPlanItemId: string,
  componentId: string,
  geometryId: string,
  partId: string,
  transformation: number[],
) => {
  const labeledBodyWIthTransformation: LabeledBodyWIthTransformation = {
    buildPlanItemId,
    componentId,
    geometryId,
    partId,
    transformation,
    selectedAt: new Date(),
    id: createGuid(),
  }

  return labeledBodyWIthTransformation
}

/**
 * Converts passed placement to labeled body with transformation
 * @param {Placement} placement - Manual placement to convert from
 * @returns {LabeledBodyWIthTransformation} Converted Labeled body from passed placemet
 */
export const createLabeledBodyWithTransformationFromPlacement = (placement: Placement) => {
  // In order to create a labeled body we have to have a transformatiob without scale
  const bpItem: IBuildPlanItem = store.getters['buildPlans/buildPlanItemById'](placement.buildPlanItemId)
  const transformation = getBuildPlanItemTransformationWithoutScale(
    store.getters['buildPlans/buildPlanItemById'](placement.buildPlanItemId),
  )

  return createLabeledBodyWithTransformation(
    placement.buildPlanItemId,
    placement.componentId,
    placement.geometryId,
    bpItem.part.id,
    transformation,
  )
}
