import { MutationTree } from 'vuex'
import { IUndoableState } from '@/store/modules/commandManager/types'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import UpsertMarkingLabel from '@/components/layout/buildPlans/marking/UpsertMarkingLabel.vue'
import { LabelToolPropertyCommand } from '@/types/UndoRedo/LabelToolPropertyCommand'
import { SaveSupportsCommand } from '@/types/UndoRedo/SaveSupportsCommand'

export const mutations: MutationTree<IUndoableState> = {
  addCommand(state: IUndoableState, command: ICommand) {
    if (command.commandType === CommandType.BuildPlanCommand) {
      state.buildPlanUndoStack.push(command)
      return
    }

    if (command.commandType === CommandType.ToolCommand && command instanceof SaveSupportsCommand) {
      state.buildPlanUndoStack.push(command)
      return
    }

    state.toolUndoStack.push(command)
  },

  enable(state: IUndoableState) {
    state.isCommandManagerReady = true
  },

  disable(state: IUndoableState) {
    state.isCommandManagerReady = false
  },

  setToolModeActive(state: IUndoableState, isActive: boolean) {
    state.isToolModeActive = isActive
  },

  disposeBuildPlanStack(state: IUndoableState) {
    state.buildPlanUndoStack = []
    state.buildPlanRedoStack = []
  },

  disposeToolStack(state: IUndoableState) {
    state.toolUndoStack = []
    state.toolRedoStack = []
  },

  // Bind 'this' context for the 'LabelToolPropertyCommand' instances,
  // for the correct executing Undo/Redo actions inside the following command.
  bindLabelToolInstanceContextWithUndoableCommand(
    state: IUndoableState,
    payload: {
      context: UpsertMarkingLabel
    },
  ): void {
    const { toolUndoStack, toolRedoStack } = state

    bindLabelToolInstanceContext(toolUndoStack, payload.context)
    bindLabelToolInstanceContext(toolRedoStack, payload.context)
  },
}

function bindLabelToolInstanceContext(stackToBindContext: ICommand[], contextToBind: UpsertMarkingLabel) {
  stackToBindContext.forEach((command) => {
    if (command instanceof LabelToolPropertyCommand) {
      command.context = contextToBind
    }
  })
}
