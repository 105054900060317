
import Vue from 'vue'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import StoresNamespaces from '@/store/namespaces'
import { ClearanceTypes } from '@/visualization/types/ClearanceTypes'
import Icon, { IconNames } from '@/components/icons/Icon.vue'

const commonStore = namespace(StoresNamespaces.Common)

@Component({ components: { Icon } })
export default class ClearanceToggleButton extends Vue {
  @commonStore.Getter tooltipOpenDelay: number

  @Prop({ default: ClearanceTypes.Parts }) clearanceType: ClearanceTypes
  @Prop({ default: true }) visible: boolean
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) isStart: boolean
  @Prop({ default: false }) isEnd: boolean
  @Prop({ default: '' }) testAttribute: string
  @Prop({ default: false }) toggleState: boolean
  @Prop() click: Function
  @Prop({ default: undefined }) icon: string
  @Prop({ default: undefined }) onStateTooltip: any
  @Prop({ default: undefined }) offStateTooltip: any
  @Prop({ default: false }) hasActiveState: boolean
  @Prop({ default: 20 }) size: number

  get iconNameFitsSvgCollection() {
    return Object.values<string>(IconNames).includes(this.icon)
  }

  get offTooltip() {
    // Handle case when only one tooltip provided
    return this.offStateTooltip || this.onStateTooltip || ''
  }

  get onTooltip() {
    // Handle case when only one tooltip provided
    return this.onStateTooltip || this.offStateTooltip || ''
  }

  get customIconViewBox() {
    switch (this.icon) {
      case IconNames.Walls:
        return '0 0 24 18'
      case IconNames.PrintLanes:
        return '0 0 18 18'
      default:
        return
    }
  }

  get customIconSize() {
    switch (this.icon) {
      case IconNames.Walls:
        return 24
      case IconNames.PrintLanes:
        return 18
      default:
        return this.size
    }
  }
}
