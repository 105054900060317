import { IProductionSet, IPrintStrategyParameterSet } from '@/types/BuildPlans/IBuildPlan'
import { MachineConfigMaterialBinder } from '../Sites/Site'

export interface PrintStrategyDefaultsDto {
  productionId: number
  productionVersion: number
  couponId: number
  couponVersion: number
  supportImportedVolumeId: number
  supportImportedVolumeVersion: number
  supportImportedSheetId: number
  supportImportedSheetVersion: number
  supportAmpSolidId: number
  supportAmpSolidVersion: number
  supportAmpLineId: number
  supportAmpLineVersion: number
}
export class BuildPlanPrintStrategyDto {
  defaults: PrintStrategyDefaultsDto
  name: string
  printStrategyId: number
  printStrategyVersion: number
  printStrategyParameterSets: IPrintStrategyParameterSet[]
  productionSet: IProductionSet
  machineConfigMaterialBinder?: MachineConfigMaterialBinder
  machineConfigMaterialBinderId?: number
  machineConfigMaterialBinderVersion?: number
  implicit: boolean

  public constructor(
    printStrategyId: number,
    printStrategyVersion: number,
    printStrategyParameterSets: IPrintStrategyParameterSet[],
    productionSet: IProductionSet,
    machineConfigMaterialBinder?: MachineConfigMaterialBinder,
  ) {
    this.printStrategyId = printStrategyId
    this.printStrategyVersion = printStrategyVersion
    this.printStrategyParameterSets = printStrategyParameterSets
    this.productionSet = productionSet
    this.machineConfigMaterialBinder = machineConfigMaterialBinder
  }
}

export enum ValidationStatus {
  Success,
  Failure,
}

export enum ValidationError {
  MissingParameter,
  InvalidValue,
  WrongSection,
  InvalidJson = 100,
  InvalidFileType = 101,
}

export interface ParameterError {
  error: ValidationError
  parameter: string
  description: string
}

export interface ParametersValidationReport {
  status: ValidationStatus
  parameterErrors?: ParameterError[]
}
