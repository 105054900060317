import { GetterTree } from 'vuex'
import { IRootState } from '@/store/types'
import { ILabelState } from '@/store/modules/label/types'
import { ContentElement } from '@/types/Label/ContentElement'
import { difference } from '@/utils/common'
import { isEmpty } from 'lodash'
import { ActiveDynamicElementDialogInfo, BodyOrderMethod, TextElement } from '@/types/Label/TextElement'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { Setting } from '@/types/Label/Setting'
import { LabelDirtyState, LabelSetMode, MarkingContentElementType, MarkingLocation } from '@/types/Label/enums'
import { ManualPatch, Patch } from '@/types/Label/Patch'
import { Matrix, Quaternion, Vector3 } from '@babylonjs/core/Maths/math'
import { LabeledBody } from '@/types/Label/LabeledBody'
import {
  CounterBodies,
  LabelExecuteCommandBody,
  LabelExecuteCommandParameters,
  LabelExecuteCommandSettings,
} from '@/types/InteractiveService/LabelExecuteCommandParameter'
import { BuildPlanItemInstancesDto, IBuildPlan, IBuildPlanItem, ISelectable } from '@/types/BuildPlans/IBuildPlan'
import { AutomaticPlacementInfo } from '@/types/Label/AutomaticPlacementInfo'
import { getTextElementByType, isDirty } from '@/utils/label/labelUtils'
import { Placement } from '@/types/Label/Placement'
import { AutomatedTrackableLabel, ManualTrackableLabel, TrackableLabel } from '@/types/Label/TrackableLabel'
import { PART_BODY_ID_DELIMITER } from '@/constants'
import {
  createLabeledBodyWithTransformationFromPlacement,
  LabeledBodyWIthTransformation,
} from '@/types/Label/LabeledBodyWIthTransformation'
import { IBuildPlanInsight, InsightErrorCodes, messageMapByInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { InteractiveLabelSetNamedList } from '@/types/Label/InteractiveLabelSetNamedList'
import { PrintingTypes } from '@/types/IMachineConfig'
import { LabelInsightRelatedItem } from '@/types/InteractiveService/LabelMessageContent'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { InsightsSeverity } from '@/types/Common/Insights'
import i18n from '@/plugins/i18n'

export const getters: GetterTree<ILabelState, IRootState> = {
  contentElements(state): ContentElement[] {
    return state.contentElements
  },

  labelSets(state): InteractiveLabelSet[] {
    return state.labelSets
  },

  manualPlacementsForLabelSet:
    (state) =>
      (labelSetId: string): Placement[] => {
        return labelSetId ? state.labelSets.find((ls: InteractiveLabelSet) => ls.id === labelSetId).manualPlacements : []
      },

  getAutomaticPlacements:
    (state) =>
      (labelSetId: string): AutomaticPlacementInfo[] => {
        return labelSetId ? state.automaticPlacements[labelSetId] : []
      },

  activeLabelSet(state): InteractiveLabelSet {
    return state.activeLabelSet
  },

  variantDynamicElements(state): TextElement[] {
    return state.listOfTextElements
  },

  labelSetsSnapshot(state): InteractiveLabelSet[] {
    return state.labelSetsSnapshot
  },

  getLabelSetsToSave(state: ILabelState): {
    labelSetsToCreate: InteractiveLabelSet[]
    labelSetsToUpdate: InteractiveLabelSet[]
    labelSetsToRemove: InteractiveLabelSet[]
  } {
    const labelSetsToCreate: InteractiveLabelSet[] = []
    const labelSetsToUpdate: InteractiveLabelSet[] = []
    const labelSetsToRemove: InteractiveLabelSet[] = []
    if (!state.labelSetsSnapshot) {
      return { labelSetsToCreate, labelSetsToUpdate, labelSetsToRemove }
    }

    state.labelSets.forEach((labelSet) => {
      const existingLabelSetSnapshot = state.labelSetsSnapshot.find(
        (labelSetSnapshot) => labelSet.id === labelSetSnapshot.id,
      )

      if (!existingLabelSetSnapshot) {
        labelSetsToCreate.push(labelSet)
      } else {
        const diff = difference(labelSet, existingLabelSetSnapshot)
        if (!isEmpty(diff)) {
          labelSetsToUpdate.push(labelSet)
        }
      }
    })

    state.labelSetsSnapshot.forEach((labelSetSnapshot) => {
      // Should be changed to labelSetId after we finialized with data model
      const existingLabelSet = state.labelSets.find((labelSet) => labelSet.id === labelSetSnapshot.id)
      if (!existingLabelSet) {
        labelSetsToRemove.push(labelSetSnapshot)
      }
    })

    return { labelSetsToCreate, labelSetsToUpdate, labelSetsToRemove }
  },

  isLabelSetsChanged(state: ILabelState, localGetters): boolean {
    const { labelSetsToCreate, labelSetsToUpdate, labelSetsToRemove } = localGetters.getLabelSetsToSave
    return Boolean(labelSetsToCreate.length || labelSetsToUpdate.length || labelSetsToRemove.length)
  },

  activeLabelSetSettings(state: ILabelState): Setting {
    return state.activeLabelSet && state.activeLabelSet.settings
  },

  isLabelSetOpened(state: ILabelState): boolean {
    return state.isLabelSetOpened
  },

  isPatchedBodiesSelected(state: ILabelState): boolean {
    return state.isPatchedBodiesSelected
  },

  namedList:
    (state: ILabelState) =>
      (additionalLabelSets?: InteractiveLabelSet[]): InteractiveLabelSetNamedList[] => {
        const uniqueIdToTextDictionary: any = {}
        const list = additionalLabelSets ? [...state.labelSets, ...additionalLabelSets] : state.labelSets

        return list
          .sort((a, b) => a.orderIndex - b.orderIndex)
          .map((ls: InteractiveLabelSet, index: number) => {
            const settingType = ls.mode
            // Filter out label sets that match current label set mode
            const existingItems = list.filter((labelSet: InteractiveLabelSet) => labelSet.mode === settingType)
            const newIndex = existingItems && existingItems.length ? existingItems.length : 1

            // Get type related text based on mode and index
            let typeRelatedText = uniqueIdToTextDictionary[ls.id] ? uniqueIdToTextDictionary[ls.id] : null
            if (!typeRelatedText) {
              switch (settingType) {
                case LabelSetMode.Views:
                  typeRelatedText = `Views ${newIndex}`
                  break
                case LabelSetMode.Bars:
                  typeRelatedText = `Bars ${newIndex}`
                  break
                case LabelSetMode.Manual:
                  typeRelatedText = `Manual ${newIndex}`
                  break
                default:
                  typeRelatedText = 'Adding new labels...'
              }

              uniqueIdToTextDictionary[ls.id] = typeRelatedText
            }

            return {
              ...ls,
              index,
              typeRelatedText,
            }
          })
      },

  isLabelToolOpened(state: ILabelState): boolean {
    return state.isLabelSetOpened
  },

  getLabelSets(state: ILabelState) {
    return state.labelSets.sort((a: InteractiveLabelSet, b: InteractiveLabelSet) => a.orderIndex - b.orderIndex)
  },

  getLabelSetById:
    (state: ILabelState) =>
      (labelSetId: string): InteractiveLabelSet => {
        return state.labelSets && state.labelSets.find((labelSet) => labelSet.id === labelSetId)
      },

  isActiveLabelSetHasDynamicField(state: ILabelState, localGetters) {
    const activeLabelSet = localGetters.activeLabelSet as InteractiveLabelSet
    if (!activeLabelSet) {
      return false
    }

    const dynamicFields = [
      MarkingContentElementType.Sequential_Integer,
      MarkingContentElementType.User_Entry,
      MarkingContentElementType.Grid_Letter,
    ]

    return activeLabelSet.settings.textElements.some((textElement) => dynamicFields.includes(textElement.type))
  },

  shouldActiveLabelSetRegenerateLabelsDueToDynamicField(state: ILabelState, localGetters) {
    const activeLabelSet = localGetters.activeLabelSet as InteractiveLabelSet
    if (!activeLabelSet) {
      return false
    }

    const dynamicFields = [MarkingContentElementType.Sequential_Integer]

    return activeLabelSet.settings.textElements.some((textElement) => dynamicFields.includes(textElement.type))
  },

  getActiveWithRelatedLabelSets:
    (state: ILabelState, localGetters) =>
      (relatedByAllDynamicElementsTypes: boolean): InteractiveLabelSet[] => {
        const activeLabelSet = localGetters.activeLabelSet as InteractiveLabelSet
        if (!activeLabelSet) {
          return []
        }

        let idsToSearch = activeLabelSet.settings.textElements.map((textElement) => textElement.elementIDNumber)
        if (state.lastDeletedElementIDs.length) {
          idsToSearch = [...new Set(idsToSearch.concat(state.lastDeletedElementIDs))]
        }

        idsToSearch = [...idsToSearch, ...localGetters.getForceRecalculateRelatedForIds]
        const labelSets = localGetters.labelSets as InteractiveLabelSet[]

        const dynamicElementsTypesOfRelated = relatedByAllDynamicElementsTypes
          ? [
            MarkingContentElementType.Sequential_Integer,
            MarkingContentElementType.User_Entry,
            MarkingContentElementType.Grid_Letter,
          ]
          : [MarkingContentElementType.Sequential_Integer]

        const activeWithRelatedLabelSets = [activeLabelSet].concat(
          ...labelSets.filter(
            (labelSet) =>
              labelSet.id !== activeLabelSet.id &&
              labelSet.settings.textElements.some(
                (textElement) =>
                  idsToSearch.includes(textElement.elementIDNumber) &&
                  dynamicElementsTypesOfRelated.includes(textElement.type),
              ),
          ),
        )

        /**
         * temporary make active labelSet first in the array
         * it will be changed when exe be able to work with arrays of label sets
         */
        const indexOfActiveLabelSet = activeWithRelatedLabelSets.findIndex(
          (labelSet) => labelSet.id === activeLabelSet.id,
        )
        if (indexOfActiveLabelSet !== -1) {
          const tmp = activeWithRelatedLabelSets[0]
          activeWithRelatedLabelSets[0] = activeWithRelatedLabelSets[indexOfActiveLabelSet]
          activeWithRelatedLabelSets[indexOfActiveLabelSet] = tmp
        }

        // should be sorted by orderIndex in future
        return activeWithRelatedLabelSets
      },

  lastDeletedElementIDs(state) {
    return state.lastDeletedElementIDs
  },

  /** For label set id returns that label set and all related to it label sets. */
  getLabelSetWithRelatedLabelSets:
    (state: ILabelState, localGetters) =>
      (labelSetId: string, relatedByAllDynamicElementsTypes: boolean = true): InteractiveLabelSet[] => {
        const labelSet = localGetters.getLabelSetById(labelSetId) as InteractiveLabelSet
        if (!labelSet) {
          return []
        }

        let idsToSearch = labelSet.settings.textElements.map((textElement) => textElement.elementIDNumber)
        if (state.lastDeletedElementIDs.length) {
          idsToSearch = [...new Set(idsToSearch.concat(state.lastDeletedElementIDs))]
        }
        const labelSets = localGetters.labelSets as InteractiveLabelSet[]
        const dynamicElementsTypesOfRelated = relatedByAllDynamicElementsTypes
          ? [
            MarkingContentElementType.Sequential_Integer,
            MarkingContentElementType.User_Entry,
            MarkingContentElementType.Grid_Letter,
          ]
          : [MarkingContentElementType.Sequential_Integer]

        const labelSetWithRelatedLabelSets = [labelSet].concat(
          ...labelSets.filter(
            (ls) =>
              labelSetId !== ls.id &&
              ls.settings.textElements.some(
                (textElement) =>
                  idsToSearch.includes(textElement.elementIDNumber) &&
                  dynamicElementsTypesOfRelated.includes(textElement.type),
              ),
          ),
        )

        const indexOfActiveLabelSet = labelSetWithRelatedLabelSets.findIndex((ls) => ls.id === ls.id)
        if (indexOfActiveLabelSet !== -1) {
          const tmp = labelSetWithRelatedLabelSets[0]
          labelSetWithRelatedLabelSets[0] = labelSetWithRelatedLabelSets[indexOfActiveLabelSet]
          labelSetWithRelatedLabelSets[indexOfActiveLabelSet] = tmp
        }

        // should be sorted by orderIndex in future
        return labelSetWithRelatedLabelSets
      },

  getActiveLabelCommandParameters:
    (state: ILabelState, localGetters, __, rootGetters) =>
      (labelIndices: number[], clearUsedPatches: boolean): LabelExecuteCommandSettings[] => {
        // Method will be changed when we change selection logic
        if (!localGetters.activeLabelSet) {
          return []
        }

        const cachedLabelIndices = localGetters.getLabelIndicesForExecute(localGetters.activeLabelSet.id) || []

        const labledBodies: LabeledBody[] =
          localGetters.shouldActiveLabelSetRegenerateLabelsDueToDynamicField ||
            !localGetters.getDetectedDiff ||
            !localGetters.getDetectedDiff.shouldUseDiff ||
            labelIndices ||
            cachedLabelIndices.length
            ? [...localGetters.getSelectedBodiesFromActiveLabelSet, ...localGetters.getRelatedBodiesFromActiveLabelSet]
            : localGetters.getDetectedDiff.toAdd

        const bodies: LabelExecuteCommandBody[] = labledBodies.map((labledBody) => {
          const { buildPlanItemId: bpItemId, componentId, geometryId } = labledBody
          const bpItem = rootGetters['buildPlans/buildPlanItemById'](bpItemId)
          const bpItemTransformation = JSON.parse(JSON.stringify(bpItem.transformationMatrix)) as number[]
          const scaling = new Vector3(1, 1, 1)
          const translation = Vector3.Zero()
          const rotation = new Quaternion()
          Matrix.FromArray(bpItemTransformation).transpose().decompose(scaling, rotation, translation)
          const withoutScale = Matrix.Compose(new Vector3(1, 1, 1), rotation, translation).transpose()

          return {
            bpItemId,
            componentId,
            geometryId,
            partId: bpItem.part.id,
            transformation: Array.from(withoutScale.m),
          }
        })

        // This is fix for label set settings validation and debounced command execute synchronization
        // In one moment of time you can pass validation and trigger debounced execute,
        // but then rapidly change some settings so validation will failed (getLabelToolIsValid)
        const manualPlacements: ManualPatch[] = localGetters.manualPlacementsForLabelSet(localGetters.activeLabelSet.id)
        let patches = []
        if (!localGetters.activeLabelSet.settings.placementMethodAutomatic) {
          if (labelIndices && labelIndices.length) {
            for (const labelIndex of labelIndices) {
              patches.push(manualPlacements[labelIndex])
            }
          } else if (
            !localGetters.shouldActiveLabelSetRegenerateLabelsDueToDynamicField &&
            localGetters.getDetectedDiff
          ) {
            patches = manualPlacements.filter((manualPatch) => {
              return bodies.find(
                (body) =>
                  body.bpItemId === manualPatch.buildPlanItemId &&
                  body.componentId === manualPatch.componentId &&
                  body.geometryId === manualPatch.geometryId,
              )
            })
          } else {
            patches = manualPlacements
          }
        }

        const parameters = localGetters.getActiveLabelIsValid
          ? [
            {
              bodies,
              patches,
              labelSetId: localGetters.activeLabelSet.id,
              settings: localGetters.activeLabelSet.settings,
              orderIndex: localGetters.activeLabelSet.orderIndex,
            },
          ]
          : []

        const [, ...relatedLabelSets] = localGetters.getActiveWithRelatedLabelSets(false) as InteractiveLabelSet[]

        relatedLabelSets.forEach((relatedLabelSet) => {
          const selectedAndRelatedBodies = [...relatedLabelSet.selectedBodies, ...relatedLabelSet.relatedBodies]
          const relatedBodies = [
            ...new Set(
              selectedAndRelatedBodies.map((body) => {
                const relatedBuildPlanItem = rootGetters['buildPlans/buildPlanItemById'](body.buildPlanItemId)
                const relatedBuildPlanItemTransformation = JSON.parse(
                  JSON.stringify(relatedBuildPlanItem.transformationMatrix),
                ) as number[]
                const scaling = new Vector3(1, 1, 1)
                const translation = Vector3.Zero()
                const rotation = new Quaternion()
                Matrix.FromArray(relatedBuildPlanItemTransformation).transpose().decompose(scaling, rotation, translation)
                const relatedWithoutScale = Matrix.Compose(new Vector3(1, 1, 1), rotation, translation).transpose()

                return JSON.stringify({
                  bpItemId: body.buildPlanItemId,
                  geometryId: body.geometryId,
                  componentId: body.componentId,
                  partId: relatedBuildPlanItem.part.id,
                  transformation: Array.from(relatedWithoutScale.m),
                })
              }),
            ),
          ].map((body) => JSON.parse(body))

          const parameter = {
            bodies: relatedBodies,
            labelSetId: relatedLabelSet.id,
            settings: relatedLabelSet.settings,
            patches: relatedLabelSet.settings.placementMethodAutomatic
              ? []
              : localGetters.manualPlacementsForLabelSet(relatedLabelSet.id),
            orderIndex: relatedLabelSet.orderIndex,
          }

          parameters.push(parameter)
        })

        return parameters
      },

  getDirtyLabelSetsToExecute(state: ILabelState, localGetters, __, rootGetters) {
    const labelSets: InteractiveLabelSet[] = []
    state.labelSets.forEach((labelSet: InteractiveLabelSet) => {
      // Filter dirty label sets
      const dirtyLabels = labelSet.labels.filter(isDirty)

      if (dirtyLabels.length) {
        labelSets.push(labelSet)
      }
    })

    return labelSets
  },

  getRelatedLabelSets(state: ILabelState, localGetters) {
    if (
      (!state.lastDeletedElementIDs || !state.lastDeletedElementIDs.length) &&
      (!state.dynamicElementsIDsToRefresh || !state.dynamicElementsIDsToRefresh.length)
    ) {
      return []
    }

    const idsToSearch = state.lastDeletedElementIDs.length
      ? state.lastDeletedElementIDs
      : state.dynamicElementsIDsToRefresh
    const labelSets = localGetters.labelSets as InteractiveLabelSet[]

    return labelSets.filter((labelSet) =>
      labelSet.settings.textElements.some(
        (textElement) =>
          idsToSearch.includes(textElement.elementIDNumber) &&
          textElement.type === MarkingContentElementType.Sequential_Integer,
      ),
    )
  },

  getCommandParametersWithNoActiveLabel:
    (state: ILabelState, localGetters, __, rootGetters) => (): LabelExecuteCommandSettings[] => {
      const parameters = []

      const relatedLabelSets = localGetters.getRelatedLabelSets as InteractiveLabelSet[]

      // Temporary logic until we move to new data model
      relatedLabelSets.forEach((relatedLabelSet) => {
        const selectedWithRelatedBodies = [...relatedLabelSet.selectedBodies, ...relatedLabelSet.relatedBodies]
        const relatedBodies: LabeledBody[] = [
          ...new Set(
            selectedWithRelatedBodies.map((patch) => {
              const relatedBuildPlanItem = rootGetters['buildPlans/buildPlanItemById'](patch.buildPlanItemId)
              const relatedBuildPlanItemTransformation = JSON.parse(
                JSON.stringify(relatedBuildPlanItem.transformationMatrix),
              ) as number[]
              const scaling = new Vector3(1, 1, 1)
              const translation = Vector3.Zero()
              const rotation = new Quaternion()
              Matrix.FromArray(relatedBuildPlanItemTransformation).transpose().decompose(scaling, rotation, translation)
              const relatedWithoutScale = Matrix.Compose(new Vector3(1, 1, 1), rotation, translation).transpose()

              return JSON.stringify({
                bpItemId: patch.buildPlanItemId,
                geometryId: patch.geometryId,
                componentId: patch.componentId,
                partId: relatedBuildPlanItem.part.id,
                transformation: Array.from(relatedWithoutScale.m),
              })
            }),
          ),
        ].map((body) => JSON.parse(body))

        const parameter = {
          bodies: relatedBodies,
          labelSetId: relatedLabelSet.id,
          settings: relatedLabelSet.settings,
          patches: relatedLabelSet.settings.placementMethodAutomatic
            ? []
            : localGetters.manualPlacementsForLabelSet(relatedLabelSet.id),
          orderIndex: relatedLabelSet.orderIndex,
        }

        parameters.push(parameter)
      })

      return parameters
    },

  getCommandParametersByLabelSetsIDs:
    (state: ILabelState, localGetters, __, rootGetters) =>
      (labelSetIDs: string[]): LabelExecuteCommandSettings[] => {
        const parameters = []

        const labelSets = state.labelSets.filter((labelSet: InteractiveLabelSet) => labelSetIDs.includes(labelSet.id))

        // Temporary logic until we move to new data model
        labelSets.forEach((labelSet: InteractiveLabelSet) => {
          const selectedWithRelatedBodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]
          const relatedBodies: LabeledBody[] = [
            ...new Set(
              selectedWithRelatedBodies.map((patch) => {
                const relatedBuildPlanItem = rootGetters['buildPlans/buildPlanItemById'](patch.buildPlanItemId)
                const relatedBuildPlanItemTransformation = JSON.parse(
                  JSON.stringify(relatedBuildPlanItem.transformationMatrix),
                ) as number[]
                const scaling = new Vector3(1, 1, 1)
                const translation = Vector3.Zero()
                const rotation = new Quaternion()
                Matrix.FromArray(relatedBuildPlanItemTransformation).transpose().decompose(scaling, rotation, translation)
                const relatedWithoutScale = Matrix.Compose(new Vector3(1, 1, 1), rotation, translation).transpose()

                return JSON.stringify({
                  bpItemId: patch.buildPlanItemId,
                  geometryId: patch.geometryId,
                  componentId: patch.componentId,
                  partId: relatedBuildPlanItem.part.id,
                  transformation: Array.from(relatedWithoutScale.m),
                })
              }),
            ),
          ].map((body) => JSON.parse(body))

          const patches = labelSet.settings.placementMethodAutomatic
            ? []
            : localGetters.manualPlacementsForLabelSet(labelSet.id)

          const parameter = {
            patches,
            bodies: relatedBodies,
            labelSetId: labelSet.id,
            settings: labelSet.settings,
            orderIndex: labelSet.orderIndex,
          }

          parameters.push(parameter)
        })

        return parameters
      },

  getIsRunning(state: ILabelState) {
    return state.isRunning
  },

  getCachedLabelInsights(state: ILabelState) {
    return state.cachedLabelInsights
  },

  getExecuteTimeout(state: ILabelState) {
    return state.executeTimeout
  },

  getIsSilent(state: ILabelState) {
    return state.isSilent
  },

  getActiveSetLastSuccessfulManualPlacements(state: ILabelState) {
    return state.activeSetLastSuccessfulManualPlacements
  },

  getLabelToolIsValid(state: ILabelState) {
    return state.labelToolIsValid
  },

  getActiveLabelIsValid(state: ILabelState) {
    return state.activeLabelSetIsValid
  },

  getHighlightedLabelSetId(state: ILabelState) {
    return state.highlightedLabelSetId
  },

  getCachedPatches(state: ILabelState) {
    return state.cachedPatches
  },

  getSaveComplete(state: ILabelState) {
    return state.saveComplete
  },

  getIsSaving(state: ILabelState) {
    return state.isSaving
  },

  getIsLabelExecuteTriggered(state: ILabelState) {
    return state.isLabelExecuteTriggered
  },

  getLabelSetsIDsToDelete:
    (state: ILabelState, localGetters, __, rootGetters) =>
      (
        bodyIds?: Array<{
          buildPlanItemId: string
          geometryId: string
          componentId: string
        }>,
      ) => {
        const bpItems = bodyIds
          ? bodyIds.map((bodyInfo) => rootGetters['buildPlans/buildPlanItemById'](bodyInfo.buildPlanItemId))
          : rootGetters['buildPlans/getSelectedBuildPlanItems']
        const bpItemsIDs = bpItems.map((bpItem: IBuildPlanItem) => bpItem.id)
        const labelSets = state.labelSets

        return labelSets
          .filter((labelSet: InteractiveLabelSet) =>
            labelSet.patches.every((patch: Patch) => {
              return bpItemsIDs.includes(patch.buildPlanItemId)
            }),
          )
          .map((labelSet: InteractiveLabelSet) => labelSet.id)
      },

  getLabelSetsToUpdateOnRemove:
    (state: ILabelState, localGetters, __, rootGetters) =>
      (
        bodiesIds?: Array<{
          buildPlanItemId: string
          geometryId: string
          componentId: string
        }>,
      ) => {
        const bpItems = bodiesIds
          ? bodiesIds.map((bodyInfo) => rootGetters['buildPlans/buildPlanItemById'](bodyInfo.buildPlanItemId))
          : rootGetters['buildPlans/getSelectedBuildPlanItems']
        const bpItemsIDs = bpItems.map((bpItem: IBuildPlanItem) => bpItem.id)
        const labelSets = state.labelSets
        const labelsToUpdate: InteractiveLabelSet[] = []

        const existingLabelSets = labelSets.filter(
          (labelSet: InteractiveLabelSet) =>
            !labelSet.patches.every((patch: Patch) => {
              return bpItemsIDs.includes(patch.buildPlanItemId)
            }),
        )

        const updatedLabelSets = existingLabelSets.filter((labelSet: InteractiveLabelSet) =>
          [...labelSet.relatedBodies, ...labelSet.selectedBodies].some((labeledBody: LabeledBody) => {
            return bpItemsIDs.includes(labeledBody.buildPlanItemId)
          }),
        )

        existingLabelSets
          .sort((a: InteractiveLabelSet, b: InteractiveLabelSet) => a.orderIndex - b.orderIndex)
          .forEach((labelSet: InteractiveLabelSet, index: number) => {
            const updatedLabelSetsIds = updatedLabelSets.map((ls: InteractiveLabelSet) => ls.id)
            if (updatedLabelSetsIds.includes(labelSet.id) || labelSet.orderIndex !== index) {
              const relatedBodies = labelSet.relatedBodies.filter(
                (labeledBody: LabeledBody) => !bpItemsIDs.includes(labeledBody.buildPlanItemId),
              )
              const selectedBodies = labelSet.selectedBodies.filter(
                (labeledBody: LabeledBody) => !bpItemsIDs.includes(labeledBody.buildPlanItemId),
              )

              if (selectedBodies.length === 0 && relatedBodies.length > 0) {
                selectedBodies.push(relatedBodies.shift())
              }

              const allBodies = [...relatedBodies, ...selectedBodies]
              const uniqueParts = [...new Set(allBodies.map((labeledBody: LabeledBody) => labeledBody.partId))]
              const nonSelectedParts = uniqueParts.filter((partId: string) => {
                const selectedParts = selectedBodies.map((labeledBody: LabeledBody) => labeledBody.partId)
                return !selectedParts.includes(partId)
              })
              if (nonSelectedParts.length) {
                nonSelectedParts.forEach((partId: string) => {
                  const firstIndex = relatedBodies.findIndex((labeledBody: LabeledBody) => labeledBody.partId === partId)
                  const partToSelect = relatedBodies.splice(firstIndex, 1).shift()
                  selectedBodies.push(partToSelect)
                })
              }

              const patches = labelSet.patches.filter((patch: Patch) => !bpItemsIDs.includes(patch.buildPlanItemId))
              labelsToUpdate.push({ ...labelSet, relatedBodies, selectedBodies, patches, orderIndex: index })
            }
          })

        return labelsToUpdate
      },

  /** Determines which label sets should be updated on duplicate part process.
   * accordingly to GEAMPREQ-1198:
   * - source items with automatically placed labels should transfer labels to new build plan items and add bodies
   *   into the collector (add to selectedBodies) to the source label set.
   * - source items with manually placed labels should transfer labels to new build plan items and add bodies into
   *   the collector (add to selectedBodies) to the source label set.
   */
  getLabelSetsToUpdateOnDuplicate: (state) => (createInstancesResults: BuildPlanItemInstancesDto[]) => {
    return state.labelSets
      .filter((labelSet: InteractiveLabelSet) => {
        const labelSetBodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]
        const labelSetBodiesBpItemsIds = [
          ...new Set(labelSetBodies.map((labeledBody: LabeledBody) => labeledBody.buildPlanItemId)),
        ]
        return createInstancesResults.some((createInstancesResult: BuildPlanItemInstancesDto) => {
          return labelSetBodiesBpItemsIds.includes(createInstancesResult.srcBuildPlanItem.id)
        })
      })
      .map((labelSet: InteractiveLabelSet) => labelSet.id)
  },

  /** Determines which label sets should be updated on part being moved
   * accordingly to GEAMPREQ-1198:
   * - label set should be updated if moved parts were a part of a label set AND label set contains 'Counter' dynamic
   *   element with sequence by 'Position' turned on in it's text string.
   * - label set should be updated if moved parts were a part of a label set AND label set contains 'Grid Letter'
   *   dynamic element in it's text string.
   */
  getLabelSetsToUpdateOnMove: (state) => (moveBpItemsIds: string[]) => {
    const labelSets = state.labelSets
    return labelSets
      .filter((labelSet: InteractiveLabelSet) => {
        const allBodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]
        const bodyIsMoved = allBodies.some((body: LabeledBody) => moveBpItemsIds.includes(body.buildPlanItemId))
        if (bodyIsMoved) {
          const bpItemsUsed = []
          const selectedAndRelatedBodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]
          selectedAndRelatedBodies.forEach((labeledBody: LabeledBody) => {
            if (!bpItemsUsed.includes(labeledBody.buildPlanItemId)) {
              bpItemsUsed.push(labeledBody.buildPlanItemId)
            }
          })
          const isMultipleBPItemsUsed = bpItemsUsed.length > 1
          return (
            labelSet.settings.textElements &&
            labelSet.settings.textElements.some((textElement: TextElement) => {
              const textElementSettings = JSON.parse(textElement._cachedSpecificsJSON)
              return (
                textElement.type === MarkingContentElementType.Grid_Letter ||
                (textElement.type === MarkingContentElementType.Sequential_Integer &&
                  textElementSettings.ordering &&
                  isMultipleBPItemsUsed &&
                  textElementSettings.ordering.method === BodyOrderMethod.CartesianSort)
              )
            })
          )
        }

        return false
      })
      .map((labelSet: InteractiveLabelSet) => labelSet.id)
  },

  /** Determines which label sets should be updated on part being rotated
   * accordingly to GEAMPREQ-1198:
   * - label set should be updated if rotated parts were a part of a label set AND label set was using 'Views' mode
   *   in it.
   * - label set should be updated if rotated parts were a part of a label set AND label set contains 'Counter' dynamic
   *   element with sequence by 'Position' turned on in it's text string.
   * - label set should be updated if rotated parts were a part of a label set AND label set contains 'Grid Letter'
   *   dynamic element in it's text string.
   */
  getLabelSetsToUpdateOnRotate: (state) => (rotatedBpItemsIds: string[]) => {
    const labelSets = state.labelSets
    return labelSets
      .filter((labelSet: InteractiveLabelSet) => {
        const allBodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]
        const bodyIsRotated = allBodies.some((body: LabeledBody) => rotatedBpItemsIds.includes(body.buildPlanItemId))
        if (bodyIsRotated) {
          const isViewsMode =
            labelSet.settings.placementAutoLocations.length &&
            !labelSet.settings.placementAutoLocations.includes(MarkingLocation.FarBarEnd) &&
            !labelSet.settings.placementAutoLocations.includes(MarkingLocation.NearBarEnd) &&
            !labelSet.settings.placementAutoLocations.includes(MarkingLocation.NearestToPoint)
          const bpItemsUsed = []
          const selectedAndRelatedBodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]
          selectedAndRelatedBodies.forEach((labeledBody: LabeledBody) => {
            if (!bpItemsUsed.includes(labeledBody.buildPlanItemId)) {
              bpItemsUsed.push(labeledBody.buildPlanItemId)
            }
          })
          const isMultipleBPItemsUsed = bpItemsUsed.length > 1
          return (
            isViewsMode ||
            (labelSet.settings.textElements &&
              labelSet.settings.textElements.some((textElement: TextElement) => {
                const textElementSettings = JSON.parse(textElement._cachedSpecificsJSON)
                return (
                  textElement.type === MarkingContentElementType.Grid_Letter ||
                  (textElement.type === MarkingContentElementType.Sequential_Integer &&
                    textElementSettings.ordering &&
                    isMultipleBPItemsUsed &&
                    textElementSettings.ordering.method === BodyOrderMethod.CartesianSort)
                )
              }))
          )
        }

        return false
      })
      .map((labelSet: InteractiveLabelSet) => labelSet.id)
  },

  getSelectedBodiesFromActiveLabelSet(state: ILabelState) {
    return state.activeLabelSet.selectedBodies
  },

  getRelatedBodiesFromActiveLabelSet(state: ILabelState) {
    return state.activeLabelSet.relatedBodies
  },

  getSelectedAndRelatedBodiesByLabelSetId(state: ILabelState, lsId: string) {
    const labelSet = this.state.labelSets.find((ls) => ls.id === lsId)
    if (labelSet) {
      return { selectedBodies: labelSet.selectedBodies, relatedBodies: labelSet.relatedBodies }
    }

    return null
  },

  getLabelUpdateInProgress(state: ILabelState) {
    return state.labelUpdateInProgress
  },

  getLabelSetsIDsForUpdate(state: ILabelState): string[] {
    return state.labelSetsIDsToUpdate
  },

  getWatchToolReady(state: ILabelState): boolean {
    return state.watchToolReady
  },

  getSettingsAreValid(state: ILabelState): boolean {
    return state.settingsAreValid
  },

  getUserSettingsAreValid(state: ILabelState): boolean {
    return state.userSettingsAreValid
  },

  getDetectedDiff(state: ILabelState) {
    return state.detectedDiff
  },

  getIsOkDisabled(state: ILabelState) {
    return state.isOkDisabled
  },

  getLastUpdatedLabelSetId(state: ILabelState) {
    return state.lastUpdatedLabelSetId
  },

  getLastUpdatedDynamicTextElementId:
    (state) =>
      (elementType: MarkingContentElementType): number => {
        const textElement = getTextElementByType(elementType)
        return state.lastUpdatedDynamicTextElements[textElement]
      },

  getTextElementById:
    (state) =>
      (elementId: number): TextElement => {
        return state.listOfTextElements.find((te: TextElement) => te.elementIDNumber === elementId)
      },

  getLabelIndicesForExecute:
    (state) =>
      (labelSetId: string): number[] => {
        return state.labelIndicesForExecute ? state.labelIndicesForExecute[labelSetId] : []
      },

  isExitingFromTool(state: ILabelState) {
    return state.isExitingFromTool
  },

  isInDirtyState(state: ILabelState) {
    return state.isInDirtyState
  },

  getCachedInsights(state: ILabelState) {
    return state.cachedInsightsWhileExecution
  },

  getSelectedBodiesFromSelectables: (state) => (toSearch: ISelectable[]) => {
    if (
      !state.activeLabelSet ||
      !toSearch ||
      !state.activeLabelSet.selectedBodies ||
      !state.activeLabelSet.selectedBodies.length
    ) {
      return []
    }

    return state.activeLabelSet.selectedBodies.filter((selectedBody) =>
      toSearch.some((selectable) => {
        const [buildPlanItemId, componentId, geometryId] = selectable.id.split(PART_BODY_ID_DELIMITER)
        return (
          selectedBody.buildPlanItemId === buildPlanItemId &&
          selectedBody.componentId === componentId &&
          selectedBody.geometryId === geometryId
        )
      }),
    )
  },

  getRelatedBodiesFromSelectables: (state) => (toSearch: ISelectable[]) => {
    if (!state.activeLabelSet) {
      return []
    }

    return state.activeLabelSet.relatedBodies.filter((relatedBody) =>
      toSearch.some((selectable) => {
        const [buildPlanItemId, componentId, geometryId] = selectable.id.split(PART_BODY_ID_DELIMITER)
        return (
          relatedBody.buildPlanItemId === buildPlanItemId &&
          relatedBody.componentId === componentId &&
          relatedBody.geometryId === geometryId
        )
      }),
    )
  },

  getTrackableLabel:
    (state) =>
      (id: string, labelSetId: string): TrackableLabel => {
        const labelSet = state.labelSets.find((ls) => ls.id === labelSetId)
        if (!labelSet) {
          return
        }

        return labelSet.labels.find((label) => label.id === id)
      },

  getUniqueBodies:
    (state) =>
      (bodies: LabeledBodyWIthTransformation[], bodyId: string): LabeledBodyWIthTransformation[] => {
        const uniqueBodies: Map<string, LabeledBodyWIthTransformation> = new Map<string, LabeledBodyWIthTransformation>()
        // Create combined trackable body ID so the needed body will not
        const trackableLabelBody = bodies.find((body: LabeledBodyWIthTransformation) => body.id === bodyId)
        if (!trackableLabelBody) {
          return []
        }

        const trackableLabelBodyCombinedId =
          `${trackableLabelBody.buildPlanItemId}${PART_BODY_ID_DELIMITER}` +
          `${trackableLabelBody.componentId}${PART_BODY_ID_DELIMITER}${trackableLabelBody.geometryId}`
        // Other unique bodies should be added into a map by their combined IDs
        bodies.forEach((body: LabeledBodyWIthTransformation) => {
          const combinedId =
            `${body.buildPlanItemId}${PART_BODY_ID_DELIMITER}${body.componentId}` +
            `${PART_BODY_ID_DELIMITER}${body.geometryId}`
          // We should replace bodies with the same combined id but with another body id to make sure that body from a
          // trackable label always gets passed to a label core to save ordering of bodies in array
          // (for counter with selection body sequence)
          let uniqueBody = body
          if (trackableLabelBodyCombinedId === combinedId) {
            if (body.id !== bodyId) {
              uniqueBody = bodies.find((b) => b.id === bodyId)
            }
          }

          if (!uniqueBodies.has(combinedId)) {
            uniqueBodies.set(combinedId, uniqueBody)
          }
        })
        return Array.from(uniqueBodies.values()) as LabeledBodyWIthTransformation[]
      },

  isLabelSetHasDirtyLabels:
    (state) =>
      (labelSetId: string): boolean => {
        const labelSet = state.labelSets.find((set) => set.id === labelSetId)
        if (!labelSet) {
          return false
        }

        return labelSet.labels.some((label) => label.isDirty)
      },

  getCurrentCommand(state: ILabelState) {
    return state.currentCommand
  },

  isLabelSetsHasDirtyLabel(state: ILabelState, localGetters): boolean {
    return state.labelSets.some((labelSet: InteractiveLabelSet) => {
      return localGetters.isLabelSetHasDirtyLabels(labelSet.id)
    })
  },

  isLabelHasCommandId:
    (state) =>
      (labelSetId: string, id: string): boolean => {
        const labelSet = state.labelSets.find((ls) => ls.id === labelSetId)
        if (labelSet) {
          return labelSet.labels.some((label) => label.id === id && label.commandId)
        }

        return false
      },

  isLabelsHasCommandId:
    (state: ILabelState, localGetters) =>
      (
        payload: Array<{
          labelSetId: string
          id: string
        }>,
      ): boolean => {
        return payload.some((labelData) => localGetters.isLabelHasCommandId(labelData.labelSetId, labelData.id))
      },

  isLabelSetHasLabelWithCommandId:
    (state) =>
      (labelSetId: string): boolean => {
        const labelSet = state.labelSets.find((ls) => ls.id === labelSetId)
        if (labelSet) {
          return labelSet.labels.some((label) => label.commandId)
        }

        return false
      },

  getExecuteCommandParamsForLabelSet:
    (state: ILabelState, localGetters, _, rootGetters) =>
      (labelSet: InteractiveLabelSet, firstDirtyLabel: TrackableLabel): LabelExecuteCommandParameters => {
        const manualPlacement = labelSet.manualPlacements.find(
          (placement) => placement.id === (firstDirtyLabel as ManualTrackableLabel).manualPlacementId,
        )

        let uniqueBodies = []
        const trackableLabel: TrackableLabel = localGetters.getTrackableLabel(firstDirtyLabel.id, labelSet.id)
        if (trackableLabel.dirtyState === LabelDirtyState.Remove) {
          uniqueBodies = []
        } else {
          // Get all active with all related by counter label sets
          const bodies: LabeledBodyWIthTransformation[] = []
          const orderedLabelSets = localGetters
            .getLabelSetWithRelatedLabelSets(labelSet.id, false)
            .sort((a: InteractiveLabelSet, b: InteractiveLabelSet) => a.orderIndex - b.orderIndex)

          // This means that we have counter-related label sets
          if (orderedLabelSets.length > 1) {
            orderedLabelSets.forEach((orderedLabelSet: InteractiveLabelSet) => {
              const uniqueBodiesFromPlacements = []
              // Get unique bodies from manual placements for current label set
              orderedLabelSet.manualPlacements.forEach((placement) => {
                const labeledBody = createLabeledBodyWithTransformationFromPlacement(placement)
                const found = uniqueBodiesFromPlacements.find(
                  (body) =>
                    body.buildPlanItemId === labeledBody.buildPlanItemId &&
                    body.componentId === labeledBody.componentId &&
                    body.geometryId === labeledBody.geometryId,
                )
                if (!found) {
                  uniqueBodiesFromPlacements.push(labeledBody)
                }
              })

              // Add unique bodies to bodies list
              bodies.push(...uniqueBodiesFromPlacements)
              // Add selected bodies
              bodies.push(...orderedLabelSet.selectedBodies)
              // Add related bodies
              bodies.push(...orderedLabelSet.relatedBodies)
            })
          } else {
            // In this case we have no related labelSets
            // If we have manual placement we have to create body from it
            if (manualPlacement) {
              if (localGetters.isLabelSetContainsCounter(labelSet.id)) {
                for (const placement of labelSet.manualPlacements) {
                  bodies.push(createLabeledBodyWithTransformationFromPlacement(placement))
                }
              } else {
                bodies.push(createLabeledBodyWithTransformationFromPlacement(manualPlacement))
              }
            } else {
              // Automated placement case
              if (localGetters.isLabelSetContainsCounter(labelSet.id)) {
                bodies.push(...[...labelSet.selectedBodies, ...labelSet.relatedBodies])
              } else {
                const firstDirtyLabelBody = [...labelSet.selectedBodies, ...labelSet.relatedBodies].find(
                  (body) => body.id === (firstDirtyLabel as AutomatedTrackableLabel).bodyId,
                )
                // In case if we are deselecting bodies and the previous body of a trackable label is not
                // in selector anymore but it still needs to be passed to a label core with remove flag
                if (firstDirtyLabelBody) {
                  bodies.push(firstDirtyLabelBody)
                }
              }
            }
          }

          // Find body id for manual trackable label
          let bodyId = (trackableLabel as AutomatedTrackableLabel).bodyId
          if (!bodyId) {
            const body = bodies.find(
              (b) =>
                b.buildPlanItemId === manualPlacement.buildPlanItemId &&
                b.componentId === manualPlacement.componentId &&
                b.geometryId === manualPlacement.geometryId,
            )
            bodyId = body ? body.id : null
          }

          uniqueBodies = localGetters.getUniqueBodies(bodies, bodyId)
        }

        const buildPlan: IBuildPlan = rootGetters['buildPlans/getBuildPlan']
        // Trim text context
        labelSet.settings.textContent = labelSet.settings.textContent.trim()

        return {
          manualPlacement,
          trackableLabel,
          bodies: uniqueBodies,
          labelSetId: labelSet.id,
          settings: JSON.parse(JSON.stringify(labelSet.settings)),
          ignoreInsightCodes:
            buildPlan.modality === PrintingTypes.BinderJet ? [InsightErrorCodes.LabelToolDownFacingArea] : [],
          countersBodies: localGetters.isLabelSetContainsCounter(labelSet.id)
            ? localGetters.getExecuteCommandCountersBodies(labelSet, uniqueBodies)
            : null,
        } as LabelExecuteCommandParameters
      },

  getIgnoreChangesByDirtyState(state: ILabelState) {
    return state.ignoreChangesByDirtyStates
  },

  isLabelSetContainsCounter:
    (state: ILabelState) =>
      (labelSetId: string): boolean => {
        const labelSet = state.labelSets.find((ls: InteractiveLabelSet) => ls.id === labelSetId)
        return labelSet.settings.textElements.some(
          (textElement: TextElement) => textElement.type === MarkingContentElementType.Sequential_Integer,
        )
      },

  getForceRecalculateRelatedForIds(state: ILabelState) {
    return state.forceRecalculateRelatedForIds
  },

  isLabelAllInstancesEnabled(state: ILabelState) {
    return state.isLabelAllInstancesEnabled
  },

  isApplyRotationToInstancesEnabled(state: ILabelState) {
    return state.isApplyRotationToInstancesEnabled
  },

  getAutomatedInsightsToUpdate:
    (state, localGetters, _, rootGetters) =>
      (
        labelSet: InteractiveLabelSet,
        trackableLabel: TrackableLabel,
      ): { insights: IBuildPlanInsight[]; insightsIdsToUpdate: string[] } => {
        const insights = rootGetters['buildPlans/labelInsights'].filter((insight: IBuildPlanInsight) => insight.id)
        const insightsIdsToUpdate = []
        // Find placement body by body id and use it to find build plan item, component and geometry ids
        const placementBody: LabeledBodyWIthTransformation = [...labelSet.selectedBodies, ...labelSet.relatedBodies].find(
          (body: LabeledBodyWIthTransformation) => {
            return body.id === (trackableLabel as AutomatedTrackableLabel).bodyId
          },
        )

        const updatedInsights = insights.map((insight: IBuildPlanInsight) => {
          const relatedItems = insight.details.relatedItems
          if (!relatedItems) {
            return insight
          }

          // If there is a related item with all properties matched but with another label id - we should update
          // trackable label id to match it on a scene
          const trackableLabelIndex = relatedItems.findIndex((relatedItem: LabelInsightRelatedItem) => {
            return (
              relatedItem.componentId === placementBody.componentId &&
              relatedItem.geometryId === placementBody.geometryId &&
              relatedItem.buildPlanItemId === placementBody.buildPlanItemId &&
              relatedItem.autoLocation === (trackableLabel as AutomatedTrackableLabel).autoLocation &&
              relatedItem.labelId !== trackableLabel.id
            )
          })
          if (trackableLabelIndex < 0) {
            return insight
          }

          insight.details.relatedItems[trackableLabelIndex].labelId = trackableLabel.id
          insightsIdsToUpdate.push(insight.id)
          return insight
        })

        return { insightsIdsToUpdate, insights: updatedInsights }
      },

  getManualInsightsToUpdate:
    (state, localGetters, _, rootGetters) =>
      (
        labelSet: InteractiveLabelSet,
        trackableLabel: TrackableLabel,
      ): { insights: IBuildPlanInsight[]; insightsIdsToUpdate: string[] } => {
        const insights = rootGetters['buildPlans/labelInsights'].filter((insight: IBuildPlanInsight) => insight.id)
        const insightsIdsToUpdate = []
        // Find manual placement by manual placement id and use it to find build plan item, component and geometry ids
        const manualPlacement: Placement = labelSet.manualPlacements.find((p: Placement) => {
          return p.id === (trackableLabel as ManualTrackableLabel).manualPlacementId
        })
        const updatedInsights = insights.map((insight: IBuildPlanInsight) => {
          const relatedItems = insight.details.relatedItems
          if (!relatedItems) {
            return insight
          }

          // If there is a related item with all properties matched but with another label id - we should update
          // trackable label id to match it on a scene
          const trackableLabelIndex = relatedItems.findIndex((relatedItem: LabelInsightRelatedItem) => {
            return (
              relatedItem.componentId === manualPlacement.componentId &&
              relatedItem.geometryId === manualPlacement.geometryId &&
              relatedItem.buildPlanItemId === manualPlacement.buildPlanItemId &&
              relatedItem.manualPlacementId === (trackableLabel as ManualTrackableLabel).manualPlacementId &&
              relatedItem.labelId !== trackableLabel.id
            )
          })
          if (trackableLabelIndex < 0) {
            return insight
          }

          insight.details.relatedItems[trackableLabelIndex].labelId = trackableLabel.id
          insightsIdsToUpdate.push(insight.id)
          return insight
        })

        return { insightsIdsToUpdate, insights: updatedInsights }
      },

  getActiveDynamicElementDialogInfo(state: ILabelState): ActiveDynamicElementDialogInfo {
    return state.activeDynamicElementDialogInfo
  },

  getFontStyleHelpBalloonInfo(state: ILabelState) {
    return state.fontStyleHelpBalloonInfo
  },

  getLabelAddedPromise(state: ILabelState) {
    return state.labelAddedPromise
  },

  getPatches(state: ILabelState) {
    return state.printOrder.patchList
  },

  getExecuteCommandCountersBodies:
    (state: ILabelState) =>
      (labelSet: InteractiveLabelSet, bodies: LabeledBodyWIthTransformation[]): CounterBodies[] => {
        const countersBodies: CounterBodies[] = []

        labelSet.settings.textElements
          .filter((textElement) => textElement.type === MarkingContentElementType.Sequential_Integer)
          .forEach((counter) => {
            // find all label sets with specified counter
            const labelSetsWithCounter = [
              labelSet,
              ...state.labelSets.filter(
                (set) =>
                  set.id !== labelSet.id &&
                  set.settings.textElements.findIndex(
                    (element) => element.elementIDNumber === counter.elementIDNumber,
                  ) !== -1,
              ),
            ]

            // find body that corresponds label set used items
            const counterBodies = new Set<LabeledBodyWIthTransformation>()
            labelSetsWithCounter.forEach((lsWithCounter) => {
              let labeledBody: LabeledBodyWIthTransformation
              if (lsWithCounter.settings.placementMethodAutomatic) {
                const labelSetBodies = [...lsWithCounter.selectedBodies, ...lsWithCounter.relatedBodies]
                labelSetBodies.forEach((labelSetBody) => {
                  labeledBody = bodies.find(
                    (body) =>
                      body.buildPlanItemId === labelSetBody.buildPlanItemId &&
                      body.componentId === labelSetBody.componentId &&
                      body.geometryId === labelSetBody.geometryId,
                  )
                  if (labeledBody) {
                    counterBodies.add(labeledBody)
                  }
                })
              } else {
                lsWithCounter.manualPlacements.forEach((placement) => {
                  labeledBody = bodies.find(
                    (body) =>
                      body.buildPlanItemId === placement.buildPlanItemId &&
                      body.componentId === placement.componentId &&
                      body.geometryId === placement.geometryId,
                  )
                  if (labeledBody) {
                    counterBodies.add(labeledBody)
                  }
                })
              }
            })

            countersBodies.push({
              elementIdNumber: counter.elementIDNumber,
              bodyIds: Array.from(counterBodies.values()).map((body) => body.id),
            })
          })

        return countersBodies
      },

  labelErrorMessage:
    () =>
      (key: InsightErrorCodes | string): string => {
        return messageMapByInsight[key]
      },

  sortedHeaders(state: ILabelState, localGetters, __, rootGetters) {
    if (!localGetters.labelIssues) {
      return null
    }

    return Object.keys(localGetters.labelIssues).sort((k1: string, k2: string) => {
      const key1 = i18n.t(localGetters.labelErrorMessage(k1)).toString()
      const key2 = i18n.t(localGetters.labelErrorMessage(k2)).toString()
      return key1.localeCompare(key2)
    })
  },

  labelIssues(state: ILabelState, localGetters, __, rootGetters) {
    const insights = rootGetters['buildPlans/insights'].filter(
      (insight: IBuildPlanInsight) => insight.tool === ToolNames.LABEL && insight.severity === InsightsSeverity.Error,
    )
    let issuesMap: Partial<{ [key in InsightErrorCodes]: IBuildPlanInsight[] }> = null

    insights.forEach((i: IBuildPlanInsight) => {
      if (!issuesMap) {
        issuesMap = { [i.errorCode]: [i] }
        return
      }
      if (!issuesMap[i.errorCode]) {
        issuesMap[i.errorCode] = [i]
        return
      }
      if (i.errorCode === InsightErrorCodes.LayoutToolPartIntersectsPart) {
        const hasIntersectError = issuesMap[i.errorCode].some(
          (insight: IBuildPlanInsight) => insight.details.bpItemId === i.details.bpItemId,
        )
        if (hasIntersectError) {
          return
        }
      }
      issuesMap[i.errorCode].push(i)
    })
    return issuesMap
  },
}
