import { Color3, Color4, Matrix, Vector2, Vector3 } from '@babylonjs/core/Maths'
import { MeshBuilder, TransformNode } from '@babylonjs/core/Meshes'
import { VertexBuffer } from '@babylonjs/core/Buffers'
import { Scene } from '@babylonjs/core/scene'
import { ISceneItemMetadata, SceneItemType } from '../visualization/types/SceneItemMetadata'
import { GeometryType } from '@/types/BuildPlans/IBuildPlan'
import { StandardMaterial } from '@babylonjs/core/Materials'
import { MESH_RENDERING_GROUP_ID, OVERHANG } from '@/constants'
import { IndicesArray } from '@babylonjs/core'

export type Facet = { a: number; b: number; c: number }

type ItemGeometry = {
  productORsupport: boolean
  bottomZ: number
  positions: Map<string, Vector3[]>
  extraPositions: Map<string, Vector3[]>
  normals: Vector3[]
  facets: Map<string, Facet[]>
  xyOBB: Map<string, Vector2[]>
}

type OverhangGeometry = {
  facets: Facet[]
  vertices: Vector3[]
  normals: Vector3[]
}

export class ItemsGeometry {
  // Use static variables to avoid holding several instances of the same data
  static itemsGeometry: Map<string, ItemGeometry>
  static overhangGeometry: Map<string, OverhangGeometry>

  scene: Scene

  maxVal: number = 1e10
  minVal: number = -1e10
  coordTolerance: number = 1e-10 /* mm */
  globalBottomZ: number = this.maxVal
  diagonal: number = 1

  lineWidth: number = 10

  constructor(scene: Scene) {
    this.scene = scene
    ItemsGeometry.itemsGeometry = new Map<string, ItemGeometry>()
    ItemsGeometry.overhangGeometry = new Map<string, OverhangGeometry>()
  }

  /**
   * Collect parts, supports and coupons geometric data
   * @param scale Compensated scale factors
   */
  public collectGeometry(scale?: number[]): boolean {
    const isUnique = (value, idx, self): boolean => {
      return self.indexOf(value) === idx
    }

    if (ItemsGeometry.itemsGeometry.size && ItemsGeometry.overhangGeometry.size) {
      // Geometry data has been updated
      return true
    }

    // TODO: This is unused value, do we need it?
    // Set scale matrix
    const scaleMatrix = scale ? Matrix.Scaling(scale[0], scale[1], scale[2]) : Matrix.Identity()

    // Get all components on the scene
    const items: TransformNode[] = Array.from(this.scene.metadata.buildPlanItems.values())
    if (items.length === 0) {
      return false
    }

    // The lowerest Z coordinate
    this.globalBottomZ = this.maxVal
    let meshWorldMathrix = Matrix.Identity()
    // Min and Max points of bounding boxes
    let minPoint = new Vector3(this.maxVal, this.maxVal, this.maxVal)
    let maxPoint = new Vector3(this.minVal, this.minVal, this.minVal)

    for (const item of items) {
      // Collect vertices of all meshes tramsformed to world coordinates
      // Calc item meshes centers
      // Calc Z direction lowerest point
      // Get mesh bounding box
      const productPositions: Map<string, Vector3[]> = new Map<string, Vector3[]>()
      const supportPositions: Map<string, Vector3[]> = new Map<string, Vector3[]>()
      const couponPositions: Map<string, Vector3[]> = new Map<string, Vector3[]>()
      let itemNormals: Vector3[] = []
      const meshFacets: Map<string, Facet[]> = new Map<string, Facet[]>()
      const itemXYOBB: Map<string, Vector2[]> = new Map<string, Vector2[]>()
      let itemLowerestZ: number = this.maxVal
      // Overhang data
      const itemFacets: Facet[] = []
      const itemVertices: Vector3[] = []
      const itemFacetNormals: Vector3[] = []

      // Get all meshes of item
      const meshes = item.getChildMeshes().filter((mesh) => {
        return (
          // Mesh is a production or support (not Amp) or coupon
          mesh.metadata && (mesh.metadata as ISceneItemMetadata).itemType === SceneItemType.Component
        )
      })

      for (const mesh of meshes) {
        const meshPositions: Vector3[] = []
        const meshNormals: Vector3[] = []
        // Determine mesh geometry type
        const isProduct = mesh.metadata.bodyType === GeometryType.Production
        const isSupport = mesh.metadata.bodyType === GeometryType.Support
        const isCoupon = mesh.metadata.bodyType === GeometryType.Coupon

        // Get word matrix of mesh and apply scaling
        meshWorldMathrix = mesh.getWorldMatrix() // Decide if this is
        // the appropriate place to .multiply(scaleMatrix)
        const normalRotateMathrix = mesh.getWorldMatrix().clone().getRotationMatrix()

        // Collect mesh positions and normals
        const vCoords = mesh.getVerticesData(VertexBuffer.PositionKind)
        const nCoords = mesh.getVerticesData(VertexBuffer.NormalKind)

        for (let idx: number = 0; idx < vCoords.length; idx += 3) {
          // Collect vertices and normals
          const localCoord = new Vector3(vCoords[idx], vCoords[idx + 1], vCoords[idx + 2])
          const worldCoord = Vector3.TransformCoordinates(localCoord, meshWorldMathrix)
          const localNormal = new Vector3(nCoords[idx], nCoords[idx + 1], nCoords[idx + 2])
          const worldNormal = Vector3.TransformCoordinates(localNormal, normalRotateMathrix)

          meshPositions.push(worldCoord)
          meshNormals.push(worldNormal)

          // Calc lowerest point of mesh
          itemLowerestZ = Math.min(itemLowerestZ, worldCoord.z)
        }

        // Collect mesh facets to extract overhang zones
        const vertIndices = mesh.getIndices()
        const facets: Facet[] = []
        for (let idx: number = 0; idx < vertIndices.length; idx += 3) {
          // Store facet
          const facet = { a: vertIndices[idx], b: vertIndices[idx + 1], c: vertIndices[idx + 2] }
          facets.push(facet)

          // TODO: Temporarily overhang zones are turned off
          // // Mapping mesh positions
          // itemFacets.push(
          //   this.newFacet(itemVertices, meshPositions, facet, vertIndices, idx)
          // )
          // // Store facet normal as the average of all vertices normals
          // const normal = meshNormals[facet.a]
          //   .add(meshNormals[facet.b])
          //   .add(meshNormals[facet.c])
          //   .normalize()
          // itemFacetNormals.push(normal)
        }

        // Store positions, vertices, normals and facets
        itemNormals = itemNormals.concat(meshNormals)
        meshFacets.set(mesh.id, facets)
        if (isProduct) {
          productPositions.set(mesh.id, meshPositions)
        } else if (isSupport) {
          supportPositions.set(mesh.id, meshPositions)
        } else if (isCoupon) {
          couponPositions.set(mesh.id, meshPositions)
        }

        // Get mesh XY bounding box
        let meshOBBVertices: Vector3[] = []
        meshOBBVertices = meshOBBVertices.concat(mesh.getBoundingInfo().boundingBox.vectorsWorld)
        meshOBBVertices.sort((a, b) => {
          // Sort by Z then by X
          return a.z !== b.z ? a.z - b.z : a.x - b.x
        })

        // Update min and max points of total bounding box
        minPoint = Vector3.Minimize(minPoint, mesh.getBoundingInfo().boundingBox.minimumWorld)
        maxPoint = Vector3.Maximize(maxPoint, mesh.getBoundingInfo().boundingBox.maximumWorld)

        const meshXYOBB: Vector2[] = []
        for (let idx = 0; idx < 4; idx += 1) {
          // The first four vertices are the low part of the mesh OBB
          meshXYOBB.push(new Vector2(meshOBBVertices[idx].x, meshOBBVertices[idx].y))
        }

        // Arrange vertices by clockwise
        const base = meshXYOBB[0]
        meshXYOBB.sort((a, b) => {
          return this.cross2D(a.subtract(base), b.subtract(base))
        })

        // Set item's mesh XY bounding box
        itemXYOBB.set(mesh.id, meshXYOBB)
      }

      // Create new record in items map or add to existing record
      if (productPositions.size && supportPositions.size) {
        // Item has part and support
        ItemsGeometry.itemsGeometry.set(item.metadata.buildPlanItemId, {
          productORsupport: true,
          bottomZ: itemLowerestZ,
          positions: productPositions,
          extraPositions: supportPositions,
          normals: itemNormals,
          facets: meshFacets,
          xyOBB: itemXYOBB,
        })
      } else if (productPositions.size || supportPositions.size) {
        // Item has either a product or a support.
        // If the item has the product, the support have to be found and vice versa.
        // If neither the support nor the product is found, a new record have to be created.
        let productORsupportKey = null
        if (productPositions.size) {
          // Search for product or support item with extra positions (support positions)
          for (const key of ItemsGeometry.itemsGeometry.keys()) {
            const itm = ItemsGeometry.itemsGeometry.get(key)
            if (itm.productORsupport && itm.extraPositions.size > 0) {
              productORsupportKey = key
              break
            }
          }
        } else {
          // Search for product or support item with position (part positons)
          for (const key of ItemsGeometry.itemsGeometry.keys()) {
            const itm = ItemsGeometry.itemsGeometry.get(key)
            if (itm.productORsupport && (itm.positions.size > 0 || itm.extraPositions.size > 0)) {
              productORsupportKey = key
              break
            }
          }
        }

        if (productORsupportKey) {
          // Appropriate item has been found
          if (productPositions.size) {
            // Item with the support has been found
            const itm = ItemsGeometry.itemsGeometry.get(productORsupportKey)
            for (const mId of productPositions.keys()) {
              itm.positions.set(mId, productPositions.get(mId))
              itm.facets.set(mId, meshFacets.get(mId))
            }
            if (itemLowerestZ < itm.bottomZ) {
              itm.bottomZ = itemLowerestZ
            }
          } else {
            // Item with the product has been found
            const itm = ItemsGeometry.itemsGeometry.get(productORsupportKey)
            for (const mId of supportPositions.keys()) {
              itm.extraPositions.set(mId, supportPositions.get(mId))
              itm.facets.set(mId, meshFacets.get(mId))
            }
            if (itemLowerestZ < itm.bottomZ) {
              itm.bottomZ = itemLowerestZ
            }
          }
        } else {
          // Create new record
          ItemsGeometry.itemsGeometry.set(item.metadata.buildPlanItemId, {
            productORsupport: true,
            bottomZ: itemLowerestZ,
            positions: productPositions,
            extraPositions: supportPositions,
            normals: itemNormals,
            facets: meshFacets,
            xyOBB: itemXYOBB,
          })
        }
      } else if (couponPositions.size) {
        ItemsGeometry.itemsGeometry.set(item.metadata.buildPlanItemId, {
          productORsupport: false,
          bottomZ: itemLowerestZ,
          positions: couponPositions,
          extraPositions: new Map<string, Vector3[]>(),
          normals: itemNormals,
          facets: meshFacets,
          xyOBB: itemXYOBB,
        })
      }

      // Calc lowerest point of all items (part + its supports)
      this.globalBottomZ = Math.min(this.globalBottomZ, itemLowerestZ)

      // Set Overhang geometry
      ItemsGeometry.overhangGeometry.set(item.metadata.buildPlanItemId, {
        facets: itemFacets,
        vertices: itemVertices,
        normals: itemFacetNormals,
      })
    }

    // Calculate common diagonal
    const xDist = maxPoint.x - minPoint.x
    const yDist = maxPoint.y - minPoint.y
    const zDist = maxPoint.z - minPoint.z
    this.diagonal = Math.sqrt(xDist * xDist + yDist * yDist + zDist * zDist)

    return true
  }

  /**
   * Calculates XY projection of 3D points OBB
   * @param hull Array of 3D points
   * @returns XY projection of the OBB
   */
  public getOBB(hull: Vector3[]): Vector2[] {
    // Create the smallest area OBB with rotating calipers
    enum Location {
      left,
      right,
      up,
      low,
    }

    let minOBBArea = this.maxVal
    let minOBBVertices: Vector2[] = []

    const polygon: Vector2[] = []
    for (const pos of hull) {
      polygon.push(new Vector2(pos.x, pos.y))
    }

    if (polygon.length <= 3) {
      return polygon
    }

    const intersectLines = (pa: Vector2, paDirection: Vector2, pb: Vector2, pbDirection: Vector2) => {
      const relatedDirection = paDirection.x * pbDirection.y - paDirection.y * pbDirection.x
      if (relatedDirection === 0) {
        // relatedDirection=0 means lines are parallel
        // TODO: should be handled in caller
        return pa
      }

      const u = ((pb.x - pa.x) * pbDirection.y - (pb.y - pa.y) * pbDirection.x) / relatedDirection
      return new Vector2(pa.x + u * paDirection.x, pa.y + u * paDirection.y)
    }

    const updateOBB = (
      leftPoint: Vector2,
      leftDir: Vector2,
      rightPoint: Vector2,
      rightDir: Vector2,
      upPoint: Vector2,
      upDir: Vector2,
      lowPoint: Vector2,
      lowDir: Vector2,
    ) => {
      const leftUpPoint = intersectLines(leftPoint, leftDir, upPoint, upDir)
      const leftLowPoint = intersectLines(lowPoint, lowDir, leftPoint, leftDir)
      const rightLowPoint = intersectLines(lowPoint, lowDir, rightPoint, rightDir)
      const rightUpPoint = intersectLines(rightPoint, rightDir, upPoint, upDir)
      const distLeftRight = leftUpPoint.subtract(rightUpPoint).length()
      const distUpLow = leftUpPoint.subtract(leftLowPoint).length()
      const obbArea = distLeftRight * distUpLow

      if (obbArea < minOBBArea) {
        minOBBVertices = [leftUpPoint, leftLowPoint, rightLowPoint, rightUpPoint]
        minOBBArea = obbArea
      }
    }

    const dot2D = (a: Vector2, b: Vector2) => {
      return a.x * b.x + a.y * b.y
    }

    const orthoCW2D = (a: Vector2) => {
      return new Vector2(a.y, -a.x)
    }

    // compute directions of convex hull edges
    const edgesDirection: Vector2[] = []
    for (let i = 0; i < polygon.length; i += 1) {
      edgesDirection.push(polygon[(i + 1) % polygon.length].subtract(polygon[i]))
      edgesDirection[i].normalize()
    }

    // Get up, left, low, right points
    const minPoint = new Vector2(this.maxVal, this.maxVal)
    const maxPoint = new Vector2(this.minVal, this.minVal)
    let leftPointIdx: number
    let rightPointIdx: number
    let upPointIdx: number
    let lowPointIdx: number
    for (let i = 0; i < polygon.length; i += 1) {
      const point = polygon[i]
      if (point.x < minPoint.x) {
        minPoint.x = point.x
        leftPointIdx = i
      }

      if (point.y < minPoint.y) {
        minPoint.y = point.y
        lowPointIdx = i
      }

      if (point.x > maxPoint.x) {
        maxPoint.x = point.x
        rightPointIdx = i
      }

      if (point.y > maxPoint.y) {
        maxPoint.y = point.y
        upPointIdx = i
      }
    }

    // First calipers directions
    //               up
    //     <----------A
    //   l |          |
    //   e |          |r
    //   f |          |i
    //   t |          |g
    //     |          |h
    //     |          |t
    //     V---------->
    //      low
    let leftDirection = new Vector2(0, -1)
    let rightDirection = new Vector2(0, 1)
    let upDirection = new Vector2(-1, 0)
    let lowDirection = new Vector2(1, 0)

    for (const point of polygon) {
      const angles = [
        Math.acos(dot2D(leftDirection, edgesDirection[leftPointIdx])),
        Math.acos(dot2D(rightDirection, edgesDirection[rightPointIdx])),
        Math.acos(dot2D(upDirection, edgesDirection[upPointIdx])),
        Math.acos(dot2D(lowDirection, edgesDirection[lowPointIdx])),
      ]

      const minAngle = Math.min(
        angles[Location.left],
        angles[Location.right],
        angles[Location.up],
        angles[Location.low],
      )
      const minAngleIdx = angles.indexOf(minAngle)

      if (minAngleIdx === Location.left) {
        leftDirection = edgesDirection[leftPointIdx].clone()
        rightDirection = leftDirection.clone().negate()
        upDirection = orthoCW2D(leftDirection)
        lowDirection = upDirection.clone().negate()
        leftPointIdx = (leftPointIdx + 1) % polygon.length
      } else if (minAngleIdx === Location.right) {
        rightDirection = edgesDirection[rightPointIdx].clone()
        leftDirection = rightDirection.clone().negate()
        upDirection = orthoCW2D(leftDirection)
        lowDirection = upDirection.clone().negate()
        rightPointIdx = (rightPointIdx + 1) % polygon.length
      } else if (minAngleIdx === Location.up) {
        upDirection = edgesDirection[upPointIdx].clone()
        lowDirection = upDirection.clone().negate()
        leftDirection = orthoCW2D(lowDirection)
        rightDirection = leftDirection.clone().negate()
        upPointIdx = (upPointIdx + 1) % polygon.length
      } else if (minAngleIdx === Location.low) {
        lowDirection = edgesDirection[lowPointIdx].clone()
        upDirection = lowDirection.clone().negate()
        leftDirection = orthoCW2D(lowDirection)
        rightDirection = leftDirection.clone().negate()
        lowPointIdx = (lowPointIdx + 1) % polygon.length
      }

      updateOBB(
        polygon[leftPointIdx],
        leftDirection,
        polygon[rightPointIdx],
        rightDirection,
        polygon[upPointIdx],
        upDirection,
        polygon[lowPointIdx],
        lowDirection,
      )
    }

    return minOBBVertices
  }

  /**
   * Shows loop of 3D points
   * @param loop Array of 3D points
   * @param color Color
   */
  public showLoop(loop: Vector3[], color: Color3) {
    loop.push(loop[0])
    const loopMesh = MeshBuilder.CreateLines(OVERHANG, { points: loop }, this.scene)
    loopMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
    loopMesh.enableEdgesRendering()
    loopMesh.edgesWidth = this.lineWidth
    loopMesh.edgesColor = Color4.FromColor3(color, 1)
  }

  /**
   * Shows 3D points
   * @param points Array of 3D points
   * @param d Points diameter
   * @param material Material of points
   */
  public showPoints(points: Vector3[], d: number, material: StandardMaterial) {
    const pointMesh = MeshBuilder.CreateSphere(OVERHANG, { diameter: d }, this.scene)
    pointMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
    pointMesh.isVisible = false
    pointMesh.material = material
    for (const point of points) {
      const p = pointMesh.createInstance(OVERHANG)
      p.setAbsolutePosition(point)
    }
  }

  /**
   * Shows facets
   * @param facets Indexes of vertices
   * @param itemPositions Vertices
   * @param color Color
   */
  public showFacets(facets: Facet[], itemPositions: Vector3[], color: Color3) {
    for (const facet of facets) {
      const edges: Vector3[] = []
      edges.push(itemPositions[facet.a])
      edges.push(itemPositions[facet.b])
      edges.push(itemPositions[facet.c])
      edges.push(itemPositions[facet.a])

      const facetMesh = MeshBuilder.CreateLines(OVERHANG, { points: edges }, this.scene)
      facetMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
      facetMesh.enableEdgesRendering()
      facetMesh.edgesWidth = this.lineWidth
      facetMesh.edgesColor = Color4.FromColor3(color, 1)
    }
  }

  /**
   * Calculates 2D cross of vectors
   * @param a First vector
   * @param b Second vector
   */
  public cross2D(a: Vector2, b: Vector2): number {
    return a.x * b.y - a.y * b.x
  }

  /**
   * Cleans up items geometric data
   */
  public resetGeometryData() {
    ItemsGeometry.itemsGeometry.clear()
    ItemsGeometry.overhangGeometry.clear()
  }

  /**
   * Makes reindexing overhang facets
   * @param itemVertices Reindexed vertices
   * @param meshPositions Source mesh vertices
   * @param facet Source facet
   * @param vertIndices Indexes of source vertices
   * @param idx Index of current vertex
   * @returns Reindexed facet
   */
  private newFacet(
    itemVertices: Vector3[],
    meshPositions: Vector3[],
    facet: Facet,
    vertIndices: IndicesArray,
    idx: number,
  ): Facet {
    // Mapping mesh positions
    let idxA = itemVertices.findIndex(
      (vertex: Vector3) =>
        Math.abs(vertex.x - meshPositions[facet.a].x) < this.coordTolerance &&
        Math.abs(vertex.y - meshPositions[facet.a].y) < this.coordTolerance &&
        Math.abs(vertex.z - meshPositions[facet.a].z) < this.coordTolerance,
    )
    let idxB = itemVertices.findIndex(
      (vertex: Vector3) =>
        Math.abs(vertex.x - meshPositions[facet.b].x) < this.coordTolerance &&
        Math.abs(vertex.y - meshPositions[facet.b].y) < this.coordTolerance &&
        Math.abs(vertex.z - meshPositions[facet.b].z) < this.coordTolerance,
    )
    let idxC = itemVertices.findIndex(
      (vertex: Vector3) =>
        Math.abs(vertex.x - meshPositions[facet.c].x) < this.coordTolerance &&
        Math.abs(vertex.y - meshPositions[facet.c].y) < this.coordTolerance &&
        Math.abs(vertex.z - meshPositions[facet.c].z) < this.coordTolerance,
    )
    // Reindexing mesh positions
    if (idxA === -1) {
      itemVertices.push(meshPositions[vertIndices[idx]])
      idxA = itemVertices.length - 1
    }
    if (idxB === -1) {
      itemVertices.push(meshPositions[vertIndices[idx + 1]])
      idxB = itemVertices.length - 1
    }
    if (idxC === -1) {
      itemVertices.push(meshPositions[vertIndices[idx + 2]])
      idxC = itemVertices.length - 1
    }

    return { a: idxA, b: idxB, c: idxC }
  }
}
