import Vue from 'vue'
import VueCurrencyFilter from 'vue-currency-filter'

import { IUser } from '@/types/User/IUser'
import { getFullName } from '@/utils/user'

const DEFAULT_CURRENCY_SYMBOL = '$'

Vue.use(VueCurrencyFilter, {
  symbol: DEFAULT_CURRENCY_SYMBOL,
  thousandsSeparator: ',',
  fractionCount: 0,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true,
})

Vue.filter('userFullName', (user: IUser) => {
  if (!user) return ''

  return getFullName(user)
})

Vue.filter('capitalizeFirstLetter', (textContent: string): string => {
  return textContent.charAt(0).toUpperCase() + textContent.slice(1)
})
