import lodash from 'lodash'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import StoresNamespaces from '@/store/namespaces'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { FilterBy, FilterParamsKey, TableFilterParams } from '@/types/FileExplorer/FilterParamsKey'
import { IItemTypeFilterValue, ItemFieldType, ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { SearchResultItem } from '@/types/FileExplorer/SearchResultItem'
import { SortParams, SortParamsKey, TableSortParams } from '@/types/FileExplorer/SortParamsKey'
import { ViewMode } from '@/types/FileExplorer/ViewMode'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { SortOrders } from '@/types/SortModes'
import { orderBy } from '@/utils/array'
import { isOfType } from '@/utils/common'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const userStore = namespace(StoresNamespaces.User)

const CREATED_BY_FIELD = 'createdBy'
const UPDATED_BY_FIELD = 'updatedBy'
const CREATED_DATE = 'createdDate'
const CREATED_AT_FIELD = 'createdAt'
const UPDATED_AT_FIELD = 'updatedAt'
const LAST_UPDATED_AT_FIELD = 'lastUpdatedAt'
const LAST_UPDATED_BY_NAME_FIELD = 'updatedByName'

@Component
export default class TableMixin extends Vue {
  @fileExplorerStore.Action updateSort: (sortParams: { key: string; sort: SortParams }) => void
  @fileExplorerStore.Action updateFilter: (filterParams: { key: string; filterBy: FilterBy }) => void

  @userStore.Getter lookupFullNameById: (userId: string) => string

  @fileExplorerStore.Getter getTotalPages: () => number
  @fileExplorerStore.Getter getCurrentPageIndex: () => number

  @fileExplorerStore.State sortParams: TableSortParams
  @fileExplorerStore.State filterParams: TableFilterParams

  @fileExplorerStore.Getter getViewMode: ViewMode

  get hasMoreItems(): boolean {
    return this.getTotalPages > this.getCurrentPageIndex
  }

  getOrderedItems<T extends Partial<FileExplorerItem>>(
    key: SortParamsKey,
    items: T[],
    defaultSortBy: string,
    defaultSortOrder: SortOrders,
  ): T[] {
    const sort: SortParams | {} = this.sortParams[key] || {}
    const { sortBy = defaultSortBy, sortOrder = defaultSortOrder } = sort as SortParams

    if (!sortBy || !sortOrder) {
      throw new Error('Invalid sort parameters')
    }

    // Sort by username (createdBy or updatedBy)
    // Since the username is specified by an ID, it is necessary to replace the ID with a name
    // Or add a field to the server response
    if (sortBy === CREATED_BY_FIELD || sortBy === UPDATED_BY_FIELD) {
      const idToItemMap = items.reduce((map, item) => {
        map[item.id] = item
        return map
      }, {})

      const unordered = items.map((item) => {
        return {
          id: item.id,
          [LAST_UPDATED_BY_NAME_FIELD]: this.lookupFullNameById(item.createdBy),
        }
      })

      const ordered = orderBy(unordered, [LAST_UPDATED_BY_NAME_FIELD], [sortOrder])

      return ordered.map((item) => idToItemMap[item.id])
    }

    if (sortBy === CREATED_AT_FIELD || sortBy === CREATED_DATE || sortBy === UPDATED_AT_FIELD) {
      const unordered = items.map((item) => {
        return { ...item, [LAST_UPDATED_AT_FIELD]: item.updatedAt ? item.updatedAt : item.createdAt }
      })

      const ordered = orderBy(unordered, [LAST_UPDATED_AT_FIELD], [sortOrder])
      return ordered.map((orderedItem) => {
        return items.find((item) => orderedItem.id === item.id)
      })
    }

    if (sortBy === 'itemType') {
      const getItemTypeOrderKey = (item: FileExplorerItem) => {
        if (!item.subType) {
          switch (item.itemType) {
            case ItemType.Folder:
              return sortOrder === SortOrders.Ascending ? 0 : 6
            case ItemType.BuildPlan:
              return 1
            case ItemType.BuildPart:
              return 2
            case ItemType.PrintOrder:
              return 3
            default:
              throw new Error(`Unknown item type: ${item.itemType}`)
          }
        }

        switch (item.subType) {
          case ItemSubType.SinterPlan:
            return 4
          case ItemSubType.SinterPart:
            return 5

          default:
            throw new Error(`Unknown item subtype: ${item.subType}`)
        }
      }

      const currentSortOrder = sortOrder.toLowerCase()
      const descSortOrder = SortOrders.Descending.toLowerCase()

      return sortOrder === SortOrders.Ascending
        ? lodash.orderBy(items, [getItemTypeOrderKey, 'name'], [currentSortOrder, currentSortOrder])
        : lodash.orderBy(items, [getItemTypeOrderKey, 'name'], [descSortOrder, currentSortOrder])
    }

    if (sortBy === 'name') {
      const folders = orderBy(
        items.filter((item) => item.itemType === 2),
        [sortBy],
        [sortOrder],
      )
      const others = orderBy(
        items.filter((item) => item.itemType !== 2),
        [sortBy],
        [sortOrder],
      )
      return sortOrder === SortOrders.Ascending ? [...folders, ...others] : [...others, ...folders]
    }

    return orderBy(items, [sortBy], [sortOrder])
  }

  getFilteredItems(key: FilterParamsKey, items: FileExplorerItem[]): FileExplorerItem[] {
    const filter = this.getFilterByKey(key)
    const field = this.getFieldFromFilter(filter)
    let typeFilterValues = this.getTypeFilterValues(filter)

    typeFilterValues = this.getTypeFilterValuesWhenViewModeIsList(typeFilterValues)

    if (!field || !typeFilterValues || !typeFilterValues.length) {
      return items
    }

    return items.filter((item: FileExplorerItem) => {
      const filterValueIndex = typeFilterValues.findIndex((filterValue: IItemTypeFilterValue) => {
        switch (filterValue.fieldType) {
          case ItemFieldType.ItemSubType:
            return filterValue.typeValue === item[filterValue.fieldType] && item.subType !== ItemSubType.None

          case ItemFieldType.ItemType:
            return filterValue.typeValue === item[filterValue.fieldType] && item.subType === ItemSubType.None
        }
      })

      return filterValueIndex >= 0
    })
  }

  getFilteredSearchResultItems(
    key: FilterParamsKey,
    items: FileExplorerItem[] | SearchResultItem[],
  ): FileExplorerItem[] | SearchResultItem[] {
    const filter = this.getFilterByKey(key)
    const field = this.getFieldFromFilter(filter)
    let typeFilterValues = this.getTypeFilterValues(filter)

    typeFilterValues = this.getTypeFilterValuesWhenViewModeIsList(typeFilterValues)

    if (!field || !typeFilterValues || !typeFilterValues.length) {
      return items
    }

    return items.filter((item: FileExplorerItem | PrintOrder) => {
      const filterValueIndex = typeFilterValues.findIndex((filterValue: IItemTypeFilterValue) => {
        switch (filterValue.fieldType) {
          case ItemFieldType.ItemSubType:
            return isOfType<PrintOrder>(item, 'site')
              ? filterValue.typeValue === item[filterValue.fieldType]
              : filterValue.typeValue === item[filterValue.fieldType] && item.subType !== ItemSubType.None

          case ItemFieldType.ItemType:
            return isOfType<PrintOrder>(item, 'site')
              ? filterValue.typeValue === item[filterValue.fieldType]
              : filterValue.typeValue === item[filterValue.fieldType] && item.subType === ItemSubType.None
        }
      })

      return filterValueIndex >= 0
    })
  }

  getFilteredObjects<T>(key: FilterParamsKey, items: T[]): T[] {
    const filter: FilterBy | {} = (this.filterParams && this.filterParams[key]) || {}
    const { field } = filter as FilterBy
    const values: string[] | number[] = (filter as FilterBy).value

    if (!field || !values || !values.length) {
      return items
    }

    return items.filter((item: T) => {
      const filterValueIndex = values.findIndex((filterValue: string | number) => {
        return item[field] === filterValue
      })
      return filterValueIndex >= 0
    })
  }

  private getTypeFilterValues(filter: FilterBy | {}) {
    const typeFilterValues: IItemTypeFilterValue[] = (filter as FilterBy).value
    return typeFilterValues
  }

  private getFieldFromFilter(filter: FilterBy | {}) {
    const { field } = filter as FilterBy
    return field
  }

  private getTypeFilterValuesWhenViewModeIsList(typeFilterValues: IItemTypeFilterValue[]) {
    if (this.getViewMode === ViewMode.List && typeFilterValues && typeFilterValues.length) {
      return typeFilterValues.filter(
        (f: IItemTypeFilterValue) => !(f.fieldType === ItemFieldType.ItemType && f.typeValue === ItemType.Folder),
      )
    }

    return typeFilterValues
  }

  private getFilterByKey(key: FilterParamsKey) {
    const filter: FilterBy | {} = (this.filterParams && this.filterParams[key]) || {}
    return filter
  }
}
