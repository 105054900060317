import { MutationTree } from 'vuex'
import { ICommonState } from './types'
import i18n from '@/plugins/i18n'
import ILocale from '@/types/Common/ILocale'
import { IMachineConfig } from '@/types/IMachineConfig'

export const mutations: MutationTree<ICommonState> = {
  setLocales(state: ICommonState, locales: ILocale[]) {
    state.locales = locales
  },

  setSelectedLocale(state: ICommonState, selectedLocale: ILocale) {
    state.selectedLocale = selectedLocale
    i18n.locale = selectedLocale.id
  },

  setConnectedMachineConfigs(state, machineConfigs: IMachineConfig[]) {
    state.connectedMachineConfigs = machineConfigs
  },

  setIsDisconnected(state: ICommonState, isDisconnected: boolean) {
    state.isDisconnected = isDisconnected
  },
}
