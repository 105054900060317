import { VisualizationEvent } from '@/visualization/infrastructure/IVisualizationEvent'
import {
  BUILD_CHAMBER_SIDE_POLYLINES_NAME,
  BUILD_VOLUME_LIMIT_ZONE,
  BuildVolumeLimitZoneType,
  KEEP_OUT_ZONE,
  LABEL,
  LABEL_MESH,
} from '@/constants'
import { OBBTree } from '@/visualization/OBBTree'
import { RenderScene } from '@/visualization/render-scene'
import { IRenderable } from '@/visualization/types/IRenderable'
import { IPartMetadata, ISceneItemMetadata, SceneItemType } from '@/visualization/types/SceneItemMetadata'
import { IBuildPlanInsight, InsightErrorCodes, IPendingInsights } from '@/types/BuildPlans/IBuildPlanInsight'
import { MeshManager } from './MeshManager'
import { InsightsSeverity } from '@/types/Common/Insights'
import { BuildPlateManager } from './BuildPlateManager'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { BoundingBox } from '@babylonjs/core/Culling'
import { LabelInsightRelatedItem } from '@/types/InteractiveService/LabelMessageContent'
import store from '@/store'

export class InsightsManager {
  get reportInsightIssues() {
    return this.onReportInsightIssues
  }

  private renderScene: RenderScene | IRenderable = null
  private meshManager: MeshManager
  private buildPlateManager: BuildPlateManager
  private readonly onReportInsightIssues = new VisualizationEvent<IPendingInsights[]>()

  constructor(renderScene: RenderScene | IRenderable) {
    this.renderScene = renderScene
    this.meshManager = renderScene.getMeshManager()
    this.buildPlateManager = renderScene.getBuildPlateManager()
  }

  buildInsights(meshes): IBuildPlanInsight[] {
    // In case we have no meshes to test - there are no Insights
    if (!meshes.length) {
      return []
    }

    const insights: IBuildPlanInsight[] = []
    meshes.forEach((mesh) => {
      if (mesh.name === LABEL || mesh.name === LABEL_MESH) {
        return
      }

      const metadata = mesh.metadata as ISceneItemMetadata

      if (metadata && metadata.itemType === SceneItemType.Part) {
        const partMetadata = metadata as IPartMetadata
        if (partMetadata.failedOverhangZones && partMetadata.failedOverhangZones.length > 0) {
          this.addInsight(
            insights,
            this.buildSupportConstructionFailed(partMetadata.buildPlanItemId, partMetadata.failedOverhangZones),
          )
        }

        if (partMetadata.isNonDefaultScaleFactor) {
          this.addInsight(insights, this.buildPartHasNonDefaultScale(partMetadata.buildPlanItemId))
        }

        return
      }

      let mainSubject = mesh.mesh

      // Test for floating parts (DMLM only)
      if (mesh.floating) {
        this.addInsight(insights, this.buildPartFloatsAboveBuildPlate(mainSubject))
      }

      // Test for parts above safe dosing height (H2 machine only, build plans)
      if (mesh.aboveSafeDosingHeight) {
        this.addInsight(insights, this.buildPartAboveSafeDosingHeight(mainSubject))
      }

      // Test if part fits the build plate
      const meshMetadata = mainSubject.metadata
      const isPartFitsBuildPlate = meshMetadata.hullBInfo
        ? this.buildPlateManager.isPartHullFitsBuildPlate(meshMetadata.hullBInfo.boundingBox)
        : this.buildPlateManager.isPartFitsBuildPlate(mainSubject)
      if (meshMetadata && meshMetadata.itemType === SceneItemType.Part && !isPartFitsBuildPlate) {
        this.addInsight(insights, this.buildPartBiggerThanBuildVolume(mainSubject))
      }

      // Test collisions between meshes

      // Find number of instances of side build volume limit zones
      const buildVolumeLimits = mesh.collidingWith.filter(
        (m) => m.name === BUILD_VOLUME_LIMIT_ZONE && m.metadata && m.metadata.type === BuildVolumeLimitZoneType.Side,
      )

      // If there are multiple instances of side build volume limit zones intersections - leave only one of them
      if (buildVolumeLimits.length > 1) {
        const buildVolumeLimitZoneMesh = buildVolumeLimits.shift()
        mesh.collidingWith = mesh.collidingWith.filter(
          (m) =>
            !(m.name === BUILD_VOLUME_LIMIT_ZONE && m.metadata && m.metadata.type === BuildVolumeLimitZoneType.Side),
        )
        mesh.collidingWith.push(buildVolumeLimitZoneMesh)
      }

      // fimd instances of  keep out limit zone
      const keepOutZoneLimits = mesh.collidingWith.filter((m) => m.name === KEEP_OUT_ZONE)

      // If there are multiple instances of keep out limit zones intersection - leave only one of them
      if (keepOutZoneLimits.length > 1) {
        const [keepOutZoneMesh] = keepOutZoneLimits
        const filtered = mesh.collidingWith.filter((m) => m.name !== KEEP_OUT_ZONE)
        mesh.collidingWith = filtered
        mesh.collidingWith.push(keepOutZoneMesh)
      }

      mesh.collidingWith.forEach((collidingWithMesh) => {
        // Do not test meshes that are not parts or supports
        if (!this.meshManager.isPartMesh(mainSubject) && !this.meshManager.isSupportMesh(mainSubject)) {
          return
        }

        // treat support collisions as collisions of the parent part
        if (this.meshManager.isSupportMesh(mainSubject)) {
          mainSubject = mainSubject.parent
        }

        // If main subject collides with a mesh that is a part or support - indicate an intersection between two parts
        if (this.meshManager.isPartMesh(collidingWithMesh)) {
          this.addInsight(insights, this.buildTwoPartsCollidesInsight(mainSubject, collidingWithMesh))
        } else if (this.meshManager.isSupportMesh(collidingWithMesh)) {
          this.addInsight(insights, this.buildTwoPartsCollidesInsight(mainSubject, collidingWithMesh.parent))
        }
        // If main subject collides with a mesh but that mesh is not a part or a support
        else if (collidingWithMesh) {
          if (
            !(
              this.meshManager.isKeepOutZoneMesh(collidingWithMesh) ||
              this.meshManager.isBuildVolumeLimitZoneMesh(collidingWithMesh) ||
              this.meshManager.isSupportMesh(collidingWithMesh)
            )
          ) {
            // Skip testing of bvh, supports, overhangs, etc.
            return
          }

          // Mesh might collide with outer space, build volume ceiling or holes of a build plate
          const buildVolumeMesh = this.getBuildVolumeMesh()
          if (
            BoundingBox.Intersects(
              buildVolumeMesh.getBoundingInfo().boundingBox,
              mainSubject.metadata.hullBInfo.boundingBox,
            )
          ) {
            if (
              collidingWithMesh.name === BUILD_VOLUME_LIMIT_ZONE &&
              collidingWithMesh.metadata &&
              collidingWithMesh.metadata.type === BuildVolumeLimitZoneType.Top
            ) {
              // If tested mesh intersects both with ceiling outer space and build volume it means that
              // tested mesh touches ceiling
              this.addInsight(insights, this.buildIntersectsCeiling(mainSubject))
            } else if (collidingWithMesh.name === KEEP_OUT_ZONE) {
              this.addInsight(insights, this.buildIntersectsKeepOutZone(mainSubject))
            } else if (collidingWithMesh.name === BUILD_VOLUME_LIMIT_ZONE) {
              // If tested mesh intersects with both any outside mesh and build volume mesh it means that
              // tested mesh is lying outside of build volume partially, but we no longer need
              // to report it at this level of detail, so just
              this.addInsight(insights, this.buildOutsideOfBuildVolume(mainSubject))
            }
          } else {
            // If tested mesh intersects only with any outside mesh it means that tested mesh is lying
            // outside of build volume fully
            const outsideOfBuildVolume = this.buildOutsideOfBuildVolume(mainSubject)
            if (
              !insights.find(
                (insight) =>
                  insight.errorCode === outsideOfBuildVolume.errorCode &&
                  insight.itemId === outsideOfBuildVolume.itemId &&
                  insight.details.bpItemId === outsideOfBuildVolume.details.bpItemId &&
                  insight.details.meshId === outsideOfBuildVolume.details.meshId &&
                  insight.details.partName === outsideOfBuildVolume.details.partName,
              )
            ) {
              this.addInsight(insights, outsideOfBuildVolume)
            }
          }
        }
      })
    })

    return insights
  }

  addInsight(insights: IBuildPlanInsight[], newInsight: IBuildPlanInsight) {
    const insightAlreadyReported = insights.some(
      (insight: IBuildPlanInsight) =>
        insight.details &&
        newInsight.details &&
        insight.errorCode === newInsight.errorCode &&
        insight.details.bpItemId === newInsight.details.bpItemId,
    )
    if (!insightAlreadyReported) {
      insights.push(newInsight)
    }
  }

  getBuildVolumeMesh() {
    const scene = this.renderScene.getScene()
    return scene.getMeshByName(BUILD_CHAMBER_SIDE_POLYLINES_NAME)
  }

  getPartChildMesh(mesh) {
    const root = this.meshManager.getBuildPlanItemMeshByChild(mesh)
    return root.getChildMeshes().find((childMesh) => this.meshManager.isComponentMesh(childMesh))
  }

  reportInsights(insights: IPendingInsights[]) {
    this.onReportInsightIssues.trigger(insights)
  }

  buildPartBiggerThanBuildVolume(mainMesh) {
    return {
      errorCode: InsightErrorCodes.LayoutToolPartBiggerThanBuildVolume,
      accepted: false,
      itemId: (this.renderScene as RenderScene).buildPlanId,
      details: {
        bpItemId: mainMesh.metadata.buildPlanItemId,
        meshId: mainMesh.id,
        partName: mainMesh.metadata.partName,
      },
      severity: InsightsSeverity.Warning,
      tool: ToolNames.LAYOUT,
    }
  }

  buildPartFloatsAboveBuildPlate(mainMesh) {
    return {
      errorCode: InsightErrorCodes.LayoutToolPartFloatsAboveBuildPlate,
      accepted: false,
      itemId: (this.renderScene as RenderScene).buildPlanId,
      details: {
        bpItemId: mainMesh.metadata.buildPlanItemId,
        meshId: mainMesh.id,
        partName: mainMesh.metadata.partName,
      },
      severity: InsightsSeverity.Error,
      tool: ToolNames.LAYOUT,
    }
  }

  buildTwoPartsCollidesInsight(mainMesh, additionalMesh) {
    return {
      errorCode: InsightErrorCodes.LayoutToolPartIntersectsPart,
      accepted: false,
      itemId: (this.renderScene as RenderScene).buildPlanId,
      details: {
        bpItemId: mainMesh.metadata.buildPlanItemId,
        meshId: mainMesh.id,
        partName: mainMesh.metadata.partName,
        collidedWithBuildPlanItemId: additionalMesh.metadata.buildPlanItemId,
        collideWithMeshId: additionalMesh.id,
        collideWithPartName: additionalMesh.metadata.partName,
      },
      severity: InsightsSeverity.Warning,
      tool: ToolNames.LAYOUT,
    }
  }

  buildIntersectsKeepOutZone(mesh) {
    return {
      errorCode: InsightErrorCodes.LayoutToolPartIntersectsKeepOutZone,
      accepted: false,
      itemId: (this.renderScene as RenderScene).buildPlanId,
      details: {
        bpItemId: mesh.metadata.buildPlanItemId,
        meshId: mesh.id,
        partName: mesh.metadata.partName,
      },
      severity: InsightsSeverity.Error,
      tool: ToolNames.LAYOUT,
    }
  }

  buildPartiallyOutsideOfBuildVolume(mesh) {
    return {
      errorCode: InsightErrorCodes.LayoutToolPartIsPartiallyOutOfPlate,
      accepted: false,
      itemId: (this.renderScene as RenderScene).buildPlanId,
      details: {
        bpItemId: mesh.metadata.buildPlanItemId,
        meshId: mesh.id,
        partName: mesh.metadata.partName,
      },
      severity: InsightsSeverity.Error,
      tool: ToolNames.LAYOUT,
    }
  }

  buildOutsideOfBuildVolume(mesh) {
    return {
      errorCode: InsightErrorCodes.LayoutToolPartIsOutOfPlate,
      accepted: false,
      itemId: (this.renderScene as RenderScene).buildPlanId,
      details: {
        bpItemId: mesh.metadata.buildPlanItemId,
        meshId: mesh.id,
        partName: mesh.metadata.partName,
      },
      severity: InsightsSeverity.Error,
      tool: ToolNames.LAYOUT,
    }
  }

  buildIntersectsCeiling(mesh) {
    return {
      errorCode: InsightErrorCodes.LayoutToolPartIsTouchingCeiling,
      accepted: false,
      itemId: (this.renderScene as RenderScene).buildPlanId,
      details: {
        bpItemId: mesh.metadata.buildPlanItemId,
        meshId: mesh.id,
        partName: mesh.metadata.partName,
      },
      severity: InsightsSeverity.Error,
      tool: ToolNames.LAYOUT,
    }
  }

  buildPartTallerThanSafeDosingHeight(bpItemId: string) {
    return {
      errorCode: InsightErrorCodes.LayoutToolPartTallerThanSafeDosingHeight,
      accepted: false,
      itemId: (this.renderScene as RenderScene).buildPlanId,
      details: {
        bpItemId,
      },
      severity: InsightsSeverity.Warning,
      tool: ToolNames.LAYOUT,
    }
  }

  buildPartAboveSafeDosingHeight(mainMesh) {
    return {
      errorCode: InsightErrorCodes.LayoutToolPartAboveSafeDosingHeight,
      accepted: false,
      itemId: (this.renderScene as RenderScene).buildPlanId,
      details: {
        bpItemId: mainMesh.metadata.buildPlanItemId,
        meshId: mainMesh.id,
        partName: mainMesh.metadata.partName,
      },
      severity: InsightsSeverity.Warning,
      tool: ToolNames.LAYOUT,
    }
  }

  buildSupportConstructionFailed(bpItemId: string, failedOverhangZones: string[]) {
    return {
      errorCode: InsightErrorCodes.SupportToolConstructionFailed,
      accepted: false,
      itemId: (this.renderScene as RenderScene).buildPlanId,
      details: {
        bpItemId,
        failedOverhangZones,
      },
      severity: InsightsSeverity.Error,
      tool: ToolNames.SUPPORT,
    }
  }

  buildPartHasNonDefaultScale(bpItemId: string) {
    return {
      errorCode: InsightErrorCodes.PartPropertiesNonDefaultScale,
      accepted: false,
      itemId: (this.renderScene as RenderScene).buildPlanId,
      details: {
        bpItemId,
      },
      severity: InsightsSeverity.Warning,
      tool: ToolNames.PART_PROPERTIES,
    }
  }

  intersectsBvh(mesh1, mesh2) {
    const mesh1Bvh = mesh1 && mesh1.metadata && mesh1.metadata.bvh
    const mesh2Bvh = mesh2 && mesh2.metadata && mesh2.metadata.bvh
    if (mesh1Bvh && mesh2Bvh) {
      const obbTree: OBBTree = this.renderScene.getObbTree()
      return obbTree.bvhCollision(mesh1Bvh, mesh2Bvh, mesh1, mesh2)
    }
    return false
  }

  buildLabelToolMinimumCountInsight(relatedItems: LabelInsightRelatedItem[], buildPlanId: string): IBuildPlanInsight {
    return {
      details: { relatedItems },
      tool: ToolNames.LABEL,
      errorCode: InsightErrorCodes.LabelToolMinimumCount,
      itemId: buildPlanId,
      severity: InsightsSeverity.Error,
      accepted: false,
    }
  }

  /**
   * Deletes collision insights related to build plan items.
   */
  deleteCollisionInsights(buildPlanItemIds: string[], stateOnly: boolean) {
    const oldInsights = store.getters['buildPlans/insights'] as IBuildPlanInsight[]

    const insights = oldInsights.filter((insight) => {
      if (
        [
          InsightErrorCodes.LayoutToolPartFloatsAboveBuildPlate,
          InsightErrorCodes.LayoutToolPartAboveSafeDosingHeight,
          InsightErrorCodes.LayoutToolPartBiggerThanBuildVolume,
          InsightErrorCodes.LayoutToolPartIsTouchingCeiling,
          InsightErrorCodes.LayoutToolPartIntersectsKeepOutZone,
          InsightErrorCodes.LayoutToolPartIsOutOfPlate,
        ].includes(insight.errorCode) &&
        buildPlanItemIds.includes(insight.details.bpItemId)
      ) {
        return true
      }

      if (
        insight.errorCode === InsightErrorCodes.LayoutToolPartIntersectsPart &&
        (buildPlanItemIds.includes(insight.details.bpItemId) ||
          buildPlanItemIds.includes(insight.details.collidedWithBuildPlanItemId))
      ) {
        return true
      }

      return false
    })

    store.dispatch('buildPlans/deleteInsightMultiple', {
      stateOnly,
      insights,
      insightsIds: insights.map((insight) => insight.id),
    })
  }
}
