
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { isDate, format } from 'date-fns'
import { ValidationObserver } from 'vee-validate'

import TextField from '@/components/controls/Common/TextField.vue'
import NumberField from '@/components/controls/Common/NumberField.vue'
import { PartParameterFormViewModel } from '@/components/layout/admin/printStrategy/parameterEditor/PartParameterFormViewModel'
import {
  PartParameterFormValidation,
  SCALE_FACTOR_DEFAULT,
  PartParameterValidationRules,
} from '@/components/layout/admin/printStrategy/parameterEditor/partParameterValidation'
import {
  IBinderJetParameterSetContent,
  IConstitutiveLaw,
  IParameterSet,
  IPhysicalProperty,
} from '@/types/BuildPlans/IBuildPlan'
import { IParameterFormComponent } from '@/types/Sites/IParameterFormComponent'
import { PrintStrategyMode } from '@/types/Sites/ParameterEditor'
import { isNil, isObject } from '@/utils/common'
import { ConstitutiveLawType, DEFAULT_CONTOUR_PIXEL_LEVEL, MaterialState, PhysicalPropertyType } from '@/constants'
import SliceSettingsList from './partParameterComponents/SliceSettingsList.vue'

@Component({
  components: { NumberField, TextField, SliceSettingsList },
})
export default class PartParameterForm extends Vue implements IParameterFormComponent {
  @Prop({ required: true, type: Object }) parameter: IParameterSet
  @Prop({ required: true, type: Boolean }) readonly: boolean
  @Prop({ required: true }) printStrategyMode: PrintStrategyMode

  $refs!: {
    form: InstanceType<typeof ValidationObserver>
    sliceSettingsList: InstanceType<typeof SliceSettingsList>
  }

  vm: PartParameterFormViewModel = null
  validationRules: PartParameterValidationRules = null
  originalVm: PartParameterFormViewModel = null

  datepicker = false

  get isValid() {
    const { failed } = this.$refs.form.flags
    return !failed
  }

  @Watch('parameter')
  onParameterChanged() {
    this.initForm({ shouldSaveOriginalState: false })
  }

  created() {
    this.vm = new PartParameterFormViewModel()
    this.validationRules = PartParameterFormValidation.getValidationRules(this.printStrategyMode)
  }

  beforeMount() {
    this.initForm()
  }

  mounted() {
    this.$watch('isValid', this.onFormValidationStateChanged)
  }

  initForm(options: { shouldSaveOriginalState: boolean } = { shouldSaveOriginalState: true }) {
    const fileContent = this.parameter.partParameters as IBinderJetParameterSetContent

    this.setSinterCycle(fileContent)
    this.setScaleFactors(fileContent)
    this.setPhysicalProperties(fileContent)
    this.setCalibrationBuildInfo(fileContent)
    this.setSliceSettingsInfo(fileContent)

    // In cases where it is necessary to save the original view model, and not imported from JSON file.
    if (options.shouldSaveOriginalState) {
      this.originalVm = structuredClone(this.vm)
    }
  }

  setSinterCycle(fileContent: IBinderJetParameterSetContent) {
    const { SinterCycle } = fileContent

    if (isObject(SinterCycle)) {
      this.vm.sinterCycleName = SinterCycle.SinterCycleName
    }
  }

  setScaleFactors(fileContent: IBinderJetParameterSetContent) {
    const { ScaleFactors } = fileContent

    let scaleX: number
    let scaleY: number
    let scaleZ: number

    if (isObject(ScaleFactors)) {
      scaleX = ScaleFactors.ScaleFactorX
      scaleY = ScaleFactors.ScaleFactorY
      scaleZ = ScaleFactors.ScaleFactorZ
    }

    this.vm.scaleFactorX = scaleX || SCALE_FACTOR_DEFAULT
    this.vm.scaleFactorY = scaleY || SCALE_FACTOR_DEFAULT
    this.vm.scaleFactorZ = scaleZ || SCALE_FACTOR_DEFAULT
  }

  setPhysicalProperties(fileContent: IBinderJetParameterSetContent) {
    const { materials } = fileContent

    if (Array.isArray(materials)) {
      const material = materials[0]

      if (isObject(material)) {
        const { constitutiveLaws = [] } = material

        // Density
        this.vm.greenDensity = this.getValueFromPhysicalProperty(
          this.getPhysicalProperty(
            constitutiveLaws,
            ConstitutiveLawType.Density,
            MaterialState.BinderJetGreen,
            PhysicalPropertyType.Density,
          ),
        )
        this.vm.sinteredDensity = this.getValueFromPhysicalProperty(
          this.getPhysicalProperty(
            constitutiveLaws,
            ConstitutiveLawType.Density,
            MaterialState.BinderJetSintered,
            PhysicalPropertyType.Density,
          ),
        )

        // Poisson's Ratio
        // Same value used for green and sintered
        this.vm.poissonRatio = this.getValueFromPhysicalProperty(
          this.getPhysicalProperty(
            constitutiveLaws,
            ConstitutiveLawType.Elasticity,
            MaterialState.BinderJetGreen,
            PhysicalPropertyType.PoissonRatio,
          ),
        )

        // Young's Modulus
        // Same value used for green and sintered
        this.vm.youngsModulus = this.getValueFromPhysicalProperty(
          this.getPhysicalProperty(
            constitutiveLaws,
            ConstitutiveLawType.Elasticity,
            MaterialState.BinderJetGreen,
            PhysicalPropertyType.YoungsModulus,
          ),
        )

        // Young's Modulus (Compressive)
        // Same value used for green and sintered
        this.vm.compressiveYoungsModulus = this.getValueFromPhysicalProperty(
          this.getPhysicalProperty(
            constitutiveLaws,
            ConstitutiveLawType.BinderJetTensileAndCompressiveModulus,
            MaterialState.BinderJetGreen,
            PhysicalPropertyType.YoungsModulus,
          ),
        )

        // Tensile Modulus
        // Same value used for green and sintered
        this.vm.tensileModulus = this.getValueFromPhysicalProperty(
          this.getPhysicalProperty(
            constitutiveLaws,
            ConstitutiveLawType.BinderJetTensileAndCompressiveModulus,
            MaterialState.BinderJetGreen,
            PhysicalPropertyType.TensileModulus,
          ),
        )
      }
    }
  }

  setCalibrationBuildInfo(fileContent: IBinderJetParameterSetContent) {
    const { Optional } = fileContent

    if (isObject(Optional)) {
      const { calibrationName, calibrationDate, calibrationMachine, binderLot, powderLot } = Optional
      this.vm.calibrationName = calibrationName
      this.vm.calibrationDate = isFinite(new Date(calibrationDate).getMilliseconds())
        ? this.formatDate(calibrationDate)
        : this.formatDate(new Date().toString())
      this.vm.calibrationMachine = calibrationMachine
      this.vm.binderLot = binderLot
      this.vm.powderLot = powderLot
    } else {
      this.vm.calibrationDate = this.formatDate(new Date().toString())
    }
  }

  setSliceSettingsInfo(fileContent: IBinderJetParameterSetContent) {
    const { sliceSettings } = fileContent
    const { contourPixelLevel, bands, bulk } = sliceSettings
    this.vm.sliceSettings = {
      bulk,
      bands: bands || [],
      contourPixelLevel: isNil(contourPixelLevel) ? DEFAULT_CONTOUR_PIXEL_LEVEL : contourPixelLevel,
    }
  }

  getPhysicalProperty(
    constitutiveLaws: IConstitutiveLaw[],
    constitutiveLawType: ConstitutiveLawType,
    materialState: MaterialState,
    propertyType: PhysicalPropertyType,
  ) {
    const laws = constitutiveLaws.filter((law) => law.constitutiveLawType === constitutiveLawType)
    const foundLaw = laws.find((law) => law.materialState === materialState)

    if (foundLaw) {
      const property = foundLaw.physicalProperties.find((prop) => prop.propertyType === propertyType)
      return property
    }
  }

  getValueFromPhysicalProperty(property: IPhysicalProperty) {
    const { propertyValue } = property || {}

    if (propertyValue && Array.isArray(propertyValue)) {
      return propertyValue[0]
    }
  }

  formatDate(date: string) {
    return format(new Date(date), 'yyyy-MM-dd')
  }

  onFormValidationStateChanged(isValid: boolean) {
    this.$emit('valid', isValid)
  }

  isFormValid(options: { silent: boolean } = { silent: false }) {
    return this.$refs.form.validate({ silent: options.silent })
  }

  isFormChanged(): boolean {
    return (
      this.$refs.form.flags.changed ||
      this.$refs.sliceSettingsList.bandsHasBeenEdited ||
      this.$refs.sliceSettingsList.contourPixelLevelHasBeenEdited
    )
  }

  getFormData() {
    return { ...this.vm, calibrationDate: new Date(this.vm.calibrationDate).toISOString() }
  }

  reset() {
    this.vm = structuredClone(this.originalVm)
    this.$nextTick(() => {
      this.$refs.form.reset()
      this.$refs.sliceSettingsList.reset()
    })
  }

  save() {
    this.originalVm = structuredClone(this.vm)
    this.$nextTick(() => {
      this.$refs.form.reset()
      this.$refs.sliceSettingsList.reset()
    })
  }
}
