var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-row"},[_c('ValidationProvider',{ref:"provider",staticClass:"d-contents",attrs:{"name":_vm.$attrs.name,"rules":_vm.rules,"custom-messages":_vm.customMessages,"mode":_vm.validationMode,"immediate":_vm.validateImmediately,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [(_vm.showValidationErrorsInTooltip)?_c('div',{attrs:{"title":_vm.errorTooltipMessage ? _vm.errorTooltipMessage : errors[0]}},[_c('v-text-field',{ref:"field",class:{
          'flat-form': valid && !_vm.activeSmallField,
          'number-field': true,
          'error-flat-form': !!errors[0] && !_vm.activeSmallField,
          'spin-buttons-input': !valid && !_vm.activeSmallField,
          'small-field': _vm.activeSmallField,
          'small-field-error': !!errors[0] && _vm.activeSmallField,
        },attrs:{"outlined":!_vm.activeSmallField,"single-line":!_vm.label,"background-color":"#ffffff","dense":"","suffix":_vm.suffix,"success":valid,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"min":_vm.min,"max":_vm.max,"step":_vm.step,"label":_vm.label},on:{"input":_vm.onInput,"blur":_vm.onBlur,"change":_vm.onChange,"wheel":function($event){return $event.target.blur()}},nativeOn:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.onKeyUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.onKeyDown.apply(null, arguments)}]},scopedSlots:_vm._u([(_vm.label)?{key:"label",fn:function(){return [_c('span',{staticClass:"number-field-label text-capitalize"},[_vm._v(_vm._s(_vm.label))])]},proxy:true}:null,(_vm.showSpinButtons)?{key:"append",fn:function(){return [_c('div',{class:{ 'flat-form spinbox': true, disabled: _vm.disabled }},[_c('div',{staticClass:"spin-button spin-up",on:{"click":_vm.upSpinClick}},[_c('v-icon',[_vm._v("mdi-menu-up")])],1),_c('div',{staticClass:"spin-button spin-down",on:{"click":_vm.downSpinClick}},[_c('v-icon',[_vm._v("mdi-menu-down")])],1)])]},proxy:true}:null],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1):_c('v-text-field',{ref:"field",class:{
        'flat-form': !errors[0] && !_vm.activeSmallField,
        'number-field': true,
        'error-flat-form': !!errors[0] && !_vm.activeSmallField,
        'spin-buttons-input': _vm.showSpinButtons && !_vm.activeSmallField,
        'small-field': _vm.activeSmallField,
        'small-field-error': !!errors[0] && _vm.activeSmallField,
      },attrs:{"outlined":!_vm.activeSmallField,"single-line":!_vm.label,"background-color":"#ffffff","dense":"","suffix":_vm.suffix,"success":valid,"error-messages":_vm.activeSmallField ? null : errors[0],"placeholder":_vm.placeholder,"disabled":_vm.disabled,"min":_vm.min,"max":_vm.max,"step":"any","label":_vm.label},on:{"input":_vm.onInput,"blur":_vm.onBlur,"change":_vm.onChange,"wheel":function($event){return $event.target.blur()}},nativeOn:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.onKeyUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.onKeyDown.apply(null, arguments)}]},scopedSlots:_vm._u([(_vm.label)?{key:"label",fn:function(){return [_c('span',{staticClass:"number-field-label text-capitalize"},[_vm._v(_vm._s(_vm.label))])]},proxy:true}:null,(_vm.showSpinButtons)?{key:"append",fn:function(){return [_c('div',{class:{ 'flat-form spinbox': true, disabled: _vm.disabled }},[_c('div',{staticClass:"spin-button spin-up",on:{"click":_vm.upSpinClick}},[_c('v-icon',[_vm._v("mdi-menu-up")])],1),_c('div',{staticClass:"spin-button spin-down",on:{"click":_vm.downSpinClick}},[_c('v-icon',[_vm._v("mdi-menu-down")])],1)])]},proxy:true}:null],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }