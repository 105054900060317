export const landingPage = 'cost-estimations'

export enum EndpointsUrls {
  Login = 'api/auth/login',
  CostEstimations = 'api/costEstimations',
  CostEstimationsToken = 'api/costEstimations/token',
  Materials = 'api/materials',
  MaterialsAdmin = 'api/admin/materials',
  PrintStrategiesAdmin = 'api/admin/printStrategy',
  MachineTypesAdmin = 'api/admin/machineTypes',
  MachineTypesBySiteIdAdmin = 'api/admin/machineTypes/bySiteId',
  Regions = 'api/regions',
  MachineConfigs = 'api/machineConfigs',
  Users = 'api/users',
  UserPassword = 'api/auth/changePassword',
  Folders = 'api/printModel/folders',
  PartsV1 = 'api/printModel/parts-v1',
  SinterParts = 'api/printModel/parts-v1/sinterParts',
  IbcParts = 'api/printModel/parts-v1/ibcParts',
  Items = 'api/printModel/items',
  Permissions = 'api/printModel/permissions',
  PartToken = 'api/printModel/token',
  BuildPlans = 'api/printModel/buildPlans',
  BuildPlansV1 = 'api/printModel/buildPlans-v1',
  BuildPlanItems = 'api/printModel/buildPlanItems',
  SignedUrl = 'api/printModel/getSignedUrl',
  SignedUrlV1 = 'api/printModel/getSignedUrlV1',
  Simulations = 'api/printModel/simulations',
  BuildPlates = 'api/printModel/buildPlates',
  BuildPlateMaterials = 'api/printModel/buildPlateMaterials',
  BuildPlateMachineConfigs = 'api/printModel/buildPlateMachineConfigs',
  Exposure = 'api/printModel/buildPlans/exposure',
  ProductionSets = 'api/printModel/production-sets',
  ParameterSets = 'api/printModel/parameterSets',
  Notifications = 'api/notifications',
  Announcements = 'api/announcements',
  S3Zip = 'api/zip',
  Version = 'api/version',
  Jobs = 'api/printModel/jobs',
  Files = 'api/printModel/files',
  Sites = 'api/printModel/sites',
  SitesAdmin = 'api/admin/sites',
  CadHelper = 'api/cadHelper',
  DracoDecoderWasmUrl = '3rdparty/draco_wasm_wrapper_gltf.js',
  DracoDecoderWasmBinaryUrl = '3rdparty/draco_decoder_gltf.wasm',
  DracoDecoderFallbackUrl = '3rdparty/draco_decoder_gltf.js',
  DracoEncoderWasmUrl = '3rdparty/draco_encoder_wrapper.js',
  DracoEncoderWasmBinaryUrl = '3rdparty/draco_encoder.wasm',
  DracoEncoderFallbackUrl = '3rdparty/draco_encoder.js',
  HeatTreatments = 'api/ht',
  Insights = 'api/printModel/insights',
  InertGases = 'api/inertGasCost',
  SinteringSurfaces = 'api/sinteringSurfaces',
  AdminAllSites = 'api/sites',
  LabelSet = 'api/labelSet',
  Binder = 'api/admin/binder',
  IBCPlans = 'api/printModel/ibcPlans',
}
