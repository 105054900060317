import { Command } from '@/types/UndoRedo/Command'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { Commit, Dispatch } from 'vuex'
import { OnDragTransformation } from '@/types/UndoRedo/OnDragTransformation'
import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

export class DragCommand extends Command implements ICommand {
  public commandType: CommandType
  public toolName: ToolNames
  private readonly buildPlanItems: IBuildPlanItem[]
  private readonly dragTransformation: {
    beforeDragStartTransformation: OnDragTransformation[]
    afterDragEndTransformation: OnDragTransformation[]
  }

  constructor(
    buildPlanItems: IBuildPlanItem[],
    dragTransformation: {
      beforeDragStartTransformation: OnDragTransformation[]
      afterDragEndTransformation: OnDragTransformation[]
    },
    commandType: CommandType,
    private forceSupportsUpdate: boolean,
    protected dispatch: Dispatch,
    protected commit: Commit,
  ) {
    super()

    this.commandType = commandType
    this.dragTransformation = JSON.parse(JSON.stringify(dragTransformation))
    this.buildPlanItems = JSON.parse(JSON.stringify(buildPlanItems))
  }

  async undo(): Promise<void> {
    this.deselectItems()
    this.applyTransformationMatrix(this.dragTransformation.beforeDragStartTransformation)
    this.selectAndHighlightParts(this.buildPlanItems)
    this.rebuildSupports()
  }

  async redo(): Promise<void> {
    this.deselectItems()
    this.applyTransformationMatrix(this.dragTransformation.afterDragEndTransformation)
    this.selectAndHighlightParts(this.buildPlanItems)
    this.rebuildSupports()
  }

  private applyTransformationMatrix(targetDragTransformation: OnDragTransformation[]): void {
    targetDragTransformation.forEach((item) => {
      const payload = {
        buildPlanItemId: item.buildPlanItemId,
        transformation: item.transformationMatrix,
        options: { updateStateOnly: false, skipScaleCompensation: true },
      }

      this.commit('visualizationModule/applyTransformationMatrix', payload, this.rootLevel)
    })
  }

  private rebuildSupports() {
    if (this.forceSupportsUpdate) {
      this.commit('visualizationModule/rebuildSupports', null, this.rootLevel)
    }
  }
}
