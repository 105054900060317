
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import VTextField from 'vuetify/lib/components/VTextField'
import { ValidationProvider } from 'vee-validate'
import AutoNumeric from 'autonumeric'

import SpinButtons from '@/components/controls/Common/SpinButtons.vue'
import { DEFAULT_VALIDATION_MODE } from '@/constants'
import { roundNumberByDigits } from '@/types/Simulation/SimulationCompensation'

@Component({
  components: {
    SpinButtons,
  },
})
export default class DecimalNumberField extends Vue {
  @Prop({ default: false }) disabled: boolean
  @Prop() suffix!: string
  @Prop() min!: number
  @Prop() max!: number
  @Prop() placeholder!: string
  @Prop({ type: [Number, String, null] }) value: number | string | null
  @Prop({ type: [Object, String], default: '' }) readonly rules: object | string
  @Prop({ type: [Object, null], default: null, required: false }) readonly customMessages: object | null
  @Prop({ type: Number, default: 0 }) floatLimit!: number
  @Prop() step!: number | string
  @Prop({ default: false }) keepModelAsNumber: boolean
  @Prop({ default: false }) showValidationErrorsInTooltip: boolean
  @Prop({ default: false }) showSpinButtons: boolean
  @Prop({ default: false }) setNullForEmptyFiled: boolean
  @Prop({ default: DEFAULT_VALIDATION_MODE }) validationMode: string
  @Prop({ default: false }) validateImmediately: boolean
  @Prop({ default: false }) activeSmallField: boolean
  @Prop({ default: null }) errorTooltipMessage: string
  @Prop({ default: false }) onlyPositive: boolean
  @Prop({ default: () => null }) label: string

  numberValue: number
  model: string = ''
  numeric: AutoNumeric
  options: AutoNumeric.Options = {
    digitGroupSeparator: '',
    decimalCharacter: '.',
    decimalPlaces: this.floatLimit,
    allowDecimalPadding: false,
  }

  $refs: {
    field: VTextField
    provider: InstanceType<typeof ValidationProvider>
  }

  @Watch('numberValue')
  onNumberValueChanged(val) {
    this.$emit('input', val)
  }

  @Watch('value')
  onValueChanged(val) {
    if (val === null || isNaN(val)) {
      // This is the fix to set an empty field
      // (Need for Rotate and Move tools)
      this.reset()
      return
    }

    if (val === Number(this.model)) {
      return
    }
    this.numeric.set(this.value, this.options)
    this.model = AutoNumeric.format(val, this.options)

    if (!this.$refs.field.isFocused) {
      this.updateNumberValue()
    }
  }

  created() {
    this.numberValue = Number(this.value)
    this.updateNumberValue()
  }

  mounted() {
    this.numeric = new AutoNumeric(this.$refs.field.$refs.input)
    if (this.onlyPositive) {
      this.options.minimumValue = '0'
    }
    this.numeric.set(this.value, this.options)
    this.model = AutoNumeric.format(this.value, this.options)
    this.$refs.provider.value = this.value as undefined
    this.validate()
  }

  onInput(e, val) {
    if (this.keepModelAsNumber) {
      this.$nextTick(() => (this.model = (+this.model).toString()))
    }
    this.updateNumberValue()
    this.onChange()
  }

  updateNumberValue(): void {
    if (this.model === null) {
      this.$emit('input', null)
      return
    }

    this.$nextTick(() => {
      this.numberValue =
        +(this.setNullForEmptyFiled && this.model === '') || isNaN(Number(this.model)) ? null : Number(this.model)
    })
  }

  onBlur() {
    this.formatModelForEmit()
    this.$emit('blur', this.model)
  }

  onChange() {
    this.formatModelForEmit()
    this.$emit('change', this.model)
  }

  formatModelForEmit() {
    if (this.model === null) {
      this.reset()
      return
    }

    const checkFuncName = this.isFloat(this.model) ? 'isCorrectFloat' : 'isCorrectInteger'
    if (!this[checkFuncName](String(this.model))) {
      this.reset()
    } else {
      this.$emit('input', this.numeric.getNumber())
    }
  }

  reset() {
    if (this.model !== '-') {
      this.resetNumeric()
      this.model = null
    }
    this.numberValue = null
    this.$emit('input', null)
  }

  resetToZero() {
    this.model = '0'
    this.updateNumberValue()
  }

  resetNumeric() {
    this.numeric.remove()
    this.numeric = new AutoNumeric(this.$refs.field.$refs.input)
    this.numeric.set(this.value, this.options)
  }

  isFloat(str: string): boolean {
    return !isNaN(Number(str)) && str.indexOf('.') !== -1
  }

  upSpinClick() {
    if (this.step && this.max !== undefined) {
      const resultOfOperation = +Number(this.model) + +this.step
      const value = roundNumberByDigits(resultOfOperation, 3)
      if (value <= this.max) {
        this.model = value.toString()
        this.numeric.set(this.model, this.options)
        this.updateNumberValue()
      }
    }
  }

  downSpinClick() {
    if (this.step && this.min !== undefined) {
      const resultOfOperation = +Number(this.model) - +this.step
      const value = roundNumberByDigits(resultOfOperation, 3)
      if (value >= this.min) {
        this.model = value.toString()
        this.numeric.set(this.model, this.options)
        this.updateNumberValue()
      }
    }
  }

  isString(val: any): boolean {
    return typeof val === 'string'
  }

  isCorrectFloat(n: string): boolean {
    const reFloat = new RegExp(`^[+-]?\\d+(\\.\\d{0,${this.floatLimit ? this.floatLimit : ''}})?$`, 'g')
    const matches = n.match(reFloat)
    if (matches) {
      return matches[0] === n
    }
    return false
  }

  isCorrectInteger(n: string): boolean {
    const reInteger = new RegExp(`^[+-]?(\\d+)$`, 'g')
    const matches = n.match(reInteger)
    if (matches) {
      return matches[0] === n
    }
    return false
  }

  validate(options: { silent: boolean } = { silent: false }): Promise<{ valid: boolean }> {
    if (options.silent) {
      return this.$refs.provider.validateSilent()
    }
    return this.$refs.provider.validate()
  }

  onKeyUp(e) {
    if (!this.showSpinButtons) {
      return
    }

    e.preventDefault()
    this.upSpinClick()
  }

  onKeyDown(e) {
    if (!this.showSpinButtons) {
      return
    }

    e.preventDefault()
    this.downSpinClick()
  }
}
