import { render, staticRenderFns } from "./PartPropertiesToolbarBJT.vue?vue&type=template&id=4556d242&scoped=true"
import script from "./PartPropertiesToolbarBJT.vue?vue&type=script&lang=ts"
export * from "./PartPropertiesToolbarBJT.vue?vue&type=script&lang=ts"
import style0 from "./PartPropertiesToolbarBJT.vue?vue&type=style&index=0&id=4556d242&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4556d242",
  null
  
)

export default component.exports