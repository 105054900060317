
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import variables from '@/assets/styles/variables.scss'
import PartPreview from '@/components/layout/buildPlans/addPart/PartPreview.vue'
import { PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import BuildPlanPartMixin from '@/components/layout/buildPlans/mixins/BuildPlanPartMixin'
import FallbackImage from '@/components/layout/FallbackImage.vue'
import { ActionDateByUserMixin } from '@/components/layout/mixins/ActionDateByUserMixin'
import StoresNamespaces from '@/store/namespaces'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const MAX_FOLDER_LENGTH = 50
const TRUNCATED_FOLDER_NAME = 47
const ELLIPSIS_TRUNCATE_SYMBOL = '...'
const FOLDER_DIVIDER = '/'
const MIN_TOOLTIP_HEIGHT = 400
const NUMBER_OF_PARENTS_TO_TRUNCATE_ITEM_PATH = 5
const NUMBER_OF_LAST_PARENTS_TO_SHOW = 2

@Component({ components: { FallbackImage, PartPreview } })
export default class PartTooltip extends Mixins(BuildPlanPartMixin, ActionDateByUserMixin) {
  @fileExplorerStore.Getter find: (id: string | number) => FileExplorerItem
  @fileExplorerStore.Action fetchItemByIdWithoutAddingToState: (id: string | number) => Promise<FileExplorerItem>
  @fileExplorerStore.Mutation addItem: (item: FileExplorerItem) => void

  @Prop({ default: 0 }) top: number
  @Prop({ default: () => null }) item: PartListItemViewModel
  @Prop({ default: false }) showFullPath: boolean

  partLocation: string = ELLIPSIS_TRUNCATE_SYMBOL

  get topOffset() {
    const top = this.top - parseInt(variables.applicationHeaderHeight, 0)
    const windowHeight = window.innerHeight
    if (MIN_TOOLTIP_HEIGHT + top >= windowHeight) {
      return windowHeight - MIN_TOOLTIP_HEIGHT
    }
    return top
  }

  @Watch('item')
  async onItemChange(newItem: PartListItemViewModel) {
    if (!newItem) {
      return
    }
    this.partLocation = await this.getPartLocation(newItem)
  }

  async getPartLocation(item: PartListItemViewModel) {
    const location = this.formatPartLocation(item)

    if (this.showFullPath) {
      return location
    }

    const parentNames = location.split(FOLDER_DIVIDER)
    let resultLocationPieces = parentNames

    const parentIds = item.path.split(FOLDER_DIVIDER)
    parentIds.pop()

    if (parentIds.length >= NUMBER_OF_PARENTS_TO_TRUNCATE_ITEM_PATH) {
      if (parentIds.length !== parentNames.length) {
        // some of folder names contain "/"
        const lastParentNames = await this.getItemNamesByIds(
          parentIds.slice(parentIds.length - NUMBER_OF_LAST_PARENTS_TO_SHOW),
        )

        parentNames.splice(
          parentNames.length - NUMBER_OF_LAST_PARENTS_TO_SHOW,
          NUMBER_OF_LAST_PARENTS_TO_SHOW,
          ...lastParentNames,
        )
      }

      resultLocationPieces = [
        parentNames[0],
        ELLIPSIS_TRUNCATE_SYMBOL,
        ...parentNames.slice(parentNames.length - NUMBER_OF_LAST_PARENTS_TO_SHOW),
      ]
    }

    return resultLocationPieces
      .map((piece) => {
        if (piece.length > MAX_FOLDER_LENGTH) {
          return piece.slice(0, TRUNCATED_FOLDER_NAME) + ELLIPSIS_TRUNCATE_SYMBOL
        }
        return piece
      })
      .join(FOLDER_DIVIDER)
  }

  async getItemNamesByIds(ids: string[]) {
    const result: string[] = []

    for (const id of ids) {
      let item = this.find(id)
      if (!item) {
        item = await this.fetchItemByIdWithoutAddingToState(id)
        this.addItem(item)
      }

      result.push(item.name)
    }

    return result
  }
}
