import { Matrix, Vector3 } from '@babylonjs/core/Maths'
import { GIZMO_ROTATION_TUBE_RADIUS } from '@/constants'
import store from '@/store'
import { RenderScene } from '@/visualization/render-scene'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import { ModelManager } from '@/visualization/rendering/ModelManager'
import { SelectionManager } from '@/visualization/rendering/SelectionManager'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { LabelSetDto } from '@/types/Label/LabelSetDto'
import label from '@/api/label'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemPermissionsRole } from '@/types/FileExplorer/Permission'
import { isItemLockedForUser } from '@/utils/fileExplorerItem/fileExplorerItemUtils'
import { InstancedMesh } from '@babylonjs/core'
import { STLExport } from '@/visualization/components/STLExporter'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'

enum GizmoMeshTypes {
  xAxis = 'xAxis',
  yAxis = 'yAxis',
  zAxis = 'zAxis',
  xRotation = 'xRotation',
  yRotation = 'yRotation',
  zRotation = 'zRotation',
  xyPlane = 'xyPlane',
  xzPlane = 'xzPlane',
  yzPlane = 'yzPlane',
}

export class VisualizationApi {
  private readonly renderScene: RenderScene
  private readonly modelManager: ModelManager
  private readonly meshManager: MeshManager
  private readonly selectionManager: SelectionManager

  constructor(renderScene: RenderScene) {
    this.renderScene = renderScene
    this.modelManager = renderScene.getModelManager()
    this.meshManager = renderScene.getMeshManager()
    this.selectionManager = renderScene.getSelectionManager()
  }

  get gizmoMeshTypes() {
    return GizmoMeshTypes
  }

  getBuildPlanItemsList() {
    return store.getters['buildPlans/getAllBuildPlanItems']
  }

  selectBuildPlanItem(buildPlanItemId: string, attach: boolean = false) {
    if (buildPlanItemId) {
      const mesh = this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)
      this.selectionManager.select([{ part: mesh }], attach)

      if (this.selectionManager.getSelected().length > 0) {
        this.renderScene.showGizmos()
      }
    } else if (!attach) {
      this.selectionManager.deselect()
    }
  }

  selectBody(buildPlanItemId: string, componentId: string, geometryId: string, attach: boolean = false) {
    this.renderScene.selectBodies([{ buildPlanItemId, componentId, geometryId }], attach)
  }

  getSurfaceArea(buildPlanItemId: string) {
    const props = this.modelManager.getSinglePartGeometryProps(buildPlanItemId)
    return props.surfaceArea
  }

  getVolume(buildPlanItemId: string) {
    const props = this.modelManager.getSinglePartGeometryProps(buildPlanItemId)
    return props.volume
  }

  getBuildPlanItemWorldPosition(buildPlanItemId: string) {
    const mesh = this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)
    const hullBBox = mesh.metadata.hullBInfo.boundingBox
    return { x: hullBBox.centerWorld.x, y: hullBBox.centerWorld.y, z: hullBBox.minimumWorld.z }
  }

  getBuildPlanItemCanvasPosition(buildPlanItemId: string) {
    const mesh = this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)
    return this.unprojectPoint(mesh.metadata.hullBInfo.boundingBox.centerWorld)
  }

  getGizmoMeshCanvasPosition(gizmoMeshType: GizmoMeshTypes) {
    switch (gizmoMeshType) {
      case GizmoMeshTypes.xAxis:
      case GizmoMeshTypes.yAxis:
      case GizmoMeshTypes.zAxis:
        return this.unprojectPoint(
          this.selectionManager.gizmos.getGizmoMeshByName(`${gizmoMeshType}SphereMesh`).getAbsolutePosition(),
        )
      case GizmoMeshTypes.xRotation:
        return this.unprojectPoint(this.calcRotationTubeCenter('xAxis'))
      case GizmoMeshTypes.yRotation:
        return this.unprojectPoint(this.calcRotationTubeCenter('yAxis'))
      case GizmoMeshTypes.zRotation:
        return this.unprojectPoint(this.calcRotationTubeCenter('zAxis'))
      case GizmoMeshTypes.xyPlane:
        return this.unprojectPoint(this.calcDragPlaneCenter('zAxis'))
      case GizmoMeshTypes.xzPlane:
        return this.unprojectPoint(this.calcDragPlaneCenter('yAxis'))
      case GizmoMeshTypes.yzPlane:
        return this.unprojectPoint(this.calcDragPlaneCenter('xAxis'))
    }
  }

  /**
   * Exports scene with provided params to binary STL file format
   * @param options
   * object that contains labels and hiddenMeshes boolean flags
   * 
   * * if labels is true, the exporter will add labels to the file,
   *   otherwise the file will contain no labels
   * 
   * * if hiddenMeshes is true, the exporter will add hidden meshes to the file,
   *   otherwise the file will contain no hiddem meshes
   */
  exportToStl(
    options: {
      labels: boolean
      hiddenMeshes: boolean
    }
  ) {
    const { labels = false, hiddenMeshes = false } = options || {}
    const scene = this.renderScene.getScene()
    const meshes = scene.meshes.filter((mesh) => {
      return (mesh.isVisible || hiddenMeshes) &&
        (
          this.meshManager.isComponentMesh(mesh) ||
          (labels && this.meshManager.isLabelMesh(mesh))
        )
    }) as InstancedMesh[]

    const buildPlan = store.getters['buildPlans/getBuildPlan'] as IBuildPlan
    STLExport.CreateSTL(meshes, `${buildPlan.name} ${buildPlan.versionLabel}`, true)
  }

  /**
   * Removes all labelSets for broken build plans.
   * This method won't fix state issues and should be used only as a fix for broken buildPlans
   */
  async removeAllLabelSets() {
    const buildPlan = store.getters['buildPlans/getBuildPlan']
    const item = await store.dispatch('fileExplorer/fetchItemById', buildPlan.id)
    const hasAccess = await this.checkAccessForRemoveLabelSets(item)
    if (!hasAccess) {
      return
    }

    // get label sets from the database
    const labelSets: LabelSetDto[] = await label.getLabelSetsByBuildPlanId(buildPlan.id)
    if (!labelSets.length) {
      return
    }

    // remove all label-relted insights
    const savedInsights = (
      await store.dispatch('buildPlans/fetchInsightsByBuildPlanId', {
        buildPlanId: store.getters['buildPlans/getBuildPlan'].id,
        changeState: false,
      })
    )
      .filter((insight: IBuildPlanInsight) => insight.tool === ToolNames.LABEL)
      .map((insight) => insight.id)

    store.commit('buildPlans/removeInsightsByTool', ToolNames.LABEL)
    if (savedInsights.length) {
      await store.dispatch(
        'buildPlans/deleteInsightMultiple',
        {
          insightsIds: savedInsights,
          stateOnly: false,
        },
        { root: true },
      )
    }

    const ids = labelSets.map((labelSet) => labelSet.id)
    await store.dispatch('label/deleteLabelSets', ids)

    buildPlan.labelSetIDsToUpdate = []
    store.commit('buildPlans/setRequiresLabelSetUpdates', false, { root: true })

    store.dispatch('label/setLabelSetsIDsForUpdate', { ids: [] })
    await store.dispatch('buildPlans/updateBuildPlanV1', { buildPlan }, { root: true })

    location.reload()
  }

  private calcRotationTubeCenter(axisName: string) {
    return this.calcGizmoRotationPoint(axisName, 1)
  }

  private calcDragPlaneCenter(axisName: string) {
    return this.calcGizmoRotationPoint(axisName, 0.5)
  }

  private calcGizmoRotationPoint(axisName: string, scale = 1.0) {
    const gizmos = this.selectionManager.gizmos

    const gizmoOrigin = gizmos.getGizmoMeshByName(`${axisName}DragMesh`).getAbsolutePosition()
    const arrowA = gizmos.getGizmoMeshByName(`${axisName}PlaneRotationHelperMesh1`).getAbsolutePosition()
    const arrowB = gizmos.getGizmoMeshByName(`${axisName}PlaneRotationHelperMesh2`).getAbsolutePosition()

    const vecA = arrowA.subtract(gizmoOrigin)
    const vecB = arrowB.subtract(gizmoOrigin)

    const middleVector = vecA
      .add(vecB)
      .normalize()
      .scale(GIZMO_ROTATION_TUBE_RADIUS * gizmos.rotationGizmoScaleRatio * scale)

    return gizmoOrigin.add(middleVector)
  }

  private unprojectPoint(point: Vector3) {
    const canvas = this.renderScene.visualizationCanvas
    const unprojectedPoint = Vector3.Project(
      point,
      Matrix.IdentityReadOnly,
      this.renderScene.getScene().getTransformMatrix(),
      this.renderScene.getActiveCamera().viewport.toGlobal(canvas.width, canvas.height),
    )

    return { x: unprojectedPoint.x, y: unprojectedPoint.y }
  }

  /**
   * Checks if current user has access for remove all label sets 
   * @param {FileExplorerItem} item build plan as a FileExplorerItem 
   * @returns {boolean} hasAccess
   */
  private async checkAccessForRemoveLabelSets(item: FileExplorerItem) {
    const ALLOWED_ROLES = [ItemPermissionsRole.CoOwner, ItemPermissionsRole.Owner, ItemPermissionsRole.Editor]
    const userDetails = store.getters['user/getUserDetails']
    const bpLockInfo = await store.dispatch('buildPlans/getBuildPlanLockInfo', item.id)
    const isLockedForUser = isItemLockedForUser(bpLockInfo, userDetails)

    if (isLockedForUser) {
      return false
    }

    await store.dispatch('fileExplorer/getItemPermissions', item.id)

    const permissions = store.getters['fileExplorer/getDirectOrInheritedPermissionsByItemPath'](item.path)

    if (!permissions || !userDetails) {
      return false
    }

    const userPermission = permissions.find((permission) => permission.grantedTo === userDetails.id)

    return userPermission && ALLOWED_ROLES.includes(userPermission.role)
  }
}
