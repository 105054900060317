
import { Component, Mixins, Prop } from 'vue-property-decorator'

import { ToggleDetailsPanelButton } from '@/components/layout/FileExplorer/Details'
import TableHeaderMixin from '@/components/layout/FileExplorer/Table/mixins/TableHeaderMixin'
import TableHeaderCell from '@/components/layout/FileExplorer/Table/TableHeaderCell.vue'
import { DataTableHeaderCell } from '@/types/Common/DataTable'
import { SortParamsKey } from '@/types/FileExplorer/SortParamsKey'

const SORT_PARAMS_KEY = SortParamsKey.DashboardJobs

@Component({
  components: { ToggleDetailsPanelButton, TableHeaderCell },
})
export default class DashboardTableHeader extends Mixins(TableHeaderMixin) {
  @Prop({ default: 0 }) scrollbarWidth: number

  sortStatusKey = SORT_PARAMS_KEY

  get headers(): { [key: string]: DataTableHeaderCell } {
    const headers: DataTableHeaderCell[] = [
      {
        text: this.$i18n.t('item').toString(),
        key: 'itemName',
        sortBy: 'itemName',
      },
      {
        text: this.$i18n.t('status').toString(),
        key: 'status',
        sortBy: '',
      },
      {
        text: this.$i18n.t('task').toString(),
        key: 'jobType',
        sortBy: 'jobType',
      },
      {
        text: this.$i18n.t('startedOn').toString(),
        key: 'createdDate',
        sortBy: 'createdDate',
      },
    ]

    return this.headersToMap(headers)
  }

  get headerStyle(): Partial<CSSStyleDeclaration> {
    return {
      paddingRight: this.scrollbarWidth > 0 ? `${this.scrollbarWidth}px` : 'unset',
    }
  }

  get toogleDetailsPanelHeaderCellStyle(): Partial<CSSStyleDeclaration> {
    return {
      marginLeft: this.scrollbarWidth > 0 ? `${this.scrollbarWidth}px` : 'unset',
    }
  }
}
