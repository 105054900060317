import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { MachineTypeDto, MachineType, MachineMaterial, MachineTypeResponse } from '@/types/Sites/Site'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component
export class TenantAdminBindersMixin extends Vue {
  @sitesStore.Getter getSelectedMachineType: MachineTypeDto
  @sitesStore.Getter getSelectedMaterial: MachineMaterial

  @sitesStore.Action fetchMachineTypeById: (typeId: string) => Promise<MachineTypeResponse>
  @sitesStore.Action fetchMaterialById: (materialId: string) => Promise<MachineMaterial>
  @sitesStore.Action fetchBinders: (payload: {
    materialId: number
    machineConfigId: number
    siteId: number
  }) => Promise<void>

  async loadBinders() {
    const { siteId, machineTypeId, materialId } = this.$route.params

    let selectedMachineType = this.getSelectedMachineType
    let selectedMaterial = this.getSelectedMaterial

    if (!selectedMachineType) {
      selectedMachineType = new MachineTypeDto(await this.fetchMachineTypeById(machineTypeId)) as MachineType
    }

    if (!selectedMaterial) {
      selectedMaterial = await this.fetchMaterialById(materialId)
    }

    await this.fetchBinders({
      siteId: +siteId,
      machineConfigId: selectedMachineType.machineConfigId,
      materialId: selectedMaterial.materialId,
    })
  }
}
