
import Vue from 'vue'
import { Prop, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { IStickyComponentListItem } from '@/types/BuildPlans/IStickyComponentListItem'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { ISelectable } from '@/types/BuildPlans/IBuildPlan'
import { contentsAreEqual } from '@/utils/array'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { Visualization } from '@/visualization'
import { ESCAPE_CODE } from '@/constants'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
enum focusOptions {
  IN,
  OUT,
}

@Component
export default class BuildPlanStickyComponent extends Vue {
  @buildPlansStore.Getter getSelectedParts: ISelectable[]

  @visualizationStore.Getter visualization: Visualization

  @visualizationStore.Mutation toggleClearanceHighlight: Function

  @Prop({ default: false }) watchSelection: boolean
  @Prop({ default: () => [] }) items?: IStickyComponentListItem[]
  @Prop({ default: null }) header: string
  @Prop({ default: false }) isVisible: boolean
  @Prop({ default: true }) isMenuVisible: boolean
  @Prop({ default: { x: 0, y: 0 } }) canvasOffset: { x: number; y: number }
  @Prop({ default: 34 }) height: number
  @Prop({ default: 60 }) width: number
  @Prop({ default: 3 }) marginTop: number
  @Prop({ default: null }) hoverEvent: Function
  @Prop({ default: false }) isDimensionBox: boolean
  @Prop({ default: false }) isNoClearanceBox: boolean
  @Prop({ default: false }) isHovered: boolean
  @Prop({ default: null }) clearanceId: string

  focusState: focusOptions = focusOptions.OUT
  listIsVisible: boolean = false
  lastPosition: { x: number; y: number } = { x: 0, y: 0 }
  keypressReference = this.onKeyPressed.bind(this)
  clickReference = this.onClick.bind(this)

  get iconPosition() {
    const sidebarWidth = parseInt(
      window.getComputedStyle(document.documentElement).getPropertyValue('--left-sidebar-width'),
      10,
    )
    const xOffset = this.canvasOffset.x + sidebarWidth - this.width / 2

    const headerHeight = parseInt(
      window.getComputedStyle(document.documentElement).getPropertyValue('--application-header-height'),
      10,
    )
    const yOffset = this.canvasOffset.y + headerHeight - this.height / 2

    if (this.positionChanged(xOffset, yOffset)) {
      this.listIsVisible = false
    }
    this.lastPosition = { x: xOffset, y: yOffset }

    return { top: `${yOffset}px`, left: `${xOffset}px`, height: `${this.height}px`, width: `${this.width}px` }
  }

  get menuPosition() {
    const menuPosition = this.iconPosition

    const iconTop = parseInt(menuPosition.top, 10)
    const iconLeft = parseInt(menuPosition.left, 10)

    const menuTop = iconTop + this.height
    return { top: `${menuTop}px`, left: `${iconLeft}px`, 'margin-top': `${this.marginTop}px` }
  }
  @buildPlansStore.Mutation setBuildPlanViewMode: (mode: ViewModeTypes | null) => void

  mounted() {
    window.addEventListener('keyup', this.keypressReference)
    window.addEventListener('click', this.clickReference)
  }

  onScroll(event: WheelEvent) {
    const renderScene = this.visualization.getRenderScene()
    const canvas = renderScene.visualizationCanvas
    const rect = renderScene.getScene().getEngine().getRenderingCanvasClientRect()
    const clientX = event.clientX - rect.x
    const clientY = event.clientY - rect.y

    if (!this.isDimensionBox || 0 > clientX || clientX > rect.width || 0 > clientY || clientY > rect.height) {
      return
    }

    const canvasEvent = new WheelEvent(event.type, event)
    canvas.dispatchEvent(canvasEvent)
  }

  @Watch('getSelectedParts')
  selectedPartsChanged(partsInSelection, partsInPrevSelection) {
    const selectionIds = partsInSelection.map((item) => item.id)
    const prevSelectionIds = partsInPrevSelection.map((item) => item.id)
    if (this.watchSelection && !contentsAreEqual(selectionIds, prevSelectionIds)) {
      this.exitTool()
    }
  }

  onKeyPressed(event) {
    if (event.key === ESCAPE_CODE) {
      if (this.listIsVisible) {
        this.listIsVisible = false
      } else {
        this.exitTool(event)
      }
    }
  }

  onClick(event) {
    if (this.focusState === focusOptions.OUT && this.isVisible && !event.altKey && !event.ctrlKey) {
      if (this.listIsVisible) {
        this.listIsVisible = false
      } else {
        this.exitTool(event)
      }
    }
  }

  itemClicked(item) {
    this.$emit('onItemClicked', item)
    this.exitTool()
  }

  onFocusout() {
    this.focusState = focusOptions.OUT

    if (this.isDimensionBox && this.clearanceId) {
      this.toggleClearanceHighlight({ clearanceId: this.clearanceId, showHighlight: false })
    }
  }

  onFocusin() {
    this.focusState = focusOptions.IN
  }

  onHover() {
    if (this.hoverEvent) {
      this.hoverEvent()
    }

    if (this.isDimensionBox && this.clearanceId) {
      this.toggleClearanceHighlight({ clearanceId: this.clearanceId, showHighlight: true })
    }
  }

  beforeDestroy() {
    window.removeEventListener('keyup', this.keypressReference)
    window.removeEventListener('click', this.clickReference)
  }

  private exitTool(event?: Event) {
    this.listIsVisible = false
    this.$emit('onExitRequested', event)
  }

  private positionChanged(x, y) {
    return !(x === this.lastPosition.x && y === this.lastPosition.y)
  }
}
