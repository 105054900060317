
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'
import { ISummaryDetails, IProcessSummary, IProcessItem, ILocationEntry } from '@/visualization/summary'
import ProcessSummary from './ProcessSummary.vue'
import { Unit, getUnitTolerance, getUnitLabel } from '@/types/Simulation/Units'
import { ItemSubType } from '@/types/FileExplorer/ItemType'

const visualizationStore = namespace(StoresNamespaces.Visualization)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component({ components: { ProcessSummary } })
export default class SummaryPanel extends Vue {
  @visualizationStore.Getter('getVisualizationLoading') getVisualizationLoading: boolean
  @visualizationStore.Getter('getSummaryData') getSummaryData: ISummaryDetails
  @visualizationStore.Getter('getMeshingVisibility') getMeshingVisibility: boolean
  @visualizationStore.Getter('getBridgingElementsVisible') getBridgingElementsVisible: boolean

  @buildPlansStore.Getter('getBuildPlan') buildPlan

  printingSummary: IProcessSummary = {
    items: [],
    label: '',
  }

  recoatingSummary: IProcessSummary = {
    items: [],
    label: '',
  }

  @Prop({ default: false }) expanded: boolean

  @Watch('expanded') onTabExpanded() {
    if (!this.expanded) {
      this.resetActiveItem()
    }
  }

  @Watch('getBridgingElementsVisible')
  @Watch('getMeshingVisibility')
  onAdditionalGeometryVisibilityChanged() {
    if (this.expanded && (this.getMeshingVisibility || this.getBridgingElementsVisible)) {
      this.resetActiveItem()
    }
  }

  @Watch('getSummaryData') onContextChanged() {
    if (this.getSummaryData) {
      this.preparePrintingData()

      if (!this.isSinterPlan && this.getSummaryData.recoater) {
        this.prepareRecoaterData()
      }
    }
  }

  mounted() {
    this.onContextChanged()
  }

  get printingContext() {
    return this.printingSummary
  }

  get recoatingContext() {
    return this.recoatingSummary
  }

  get isSinterPlan() {
    return this.buildPlan.subType === ItemSubType.SinterPlan
  }

  private preparePrintingData() {
    const printingItems: IProcessItem[] = []
    const deviationLabel =
      this.getSummaryData.withinTolerance !== undefined
        ? this.$t('summaryLabels.residualDeviation').toString()
        : this.$t('summaryLabels.printingDeviation').toString()

    let value = this.getSummaryData.deviation.average
    let unit = this.getSummaryData.deviation.scalarUnit
    let referenceLabel =
      this.getSummaryData.withinTolerance !== undefined
        ? this.$t('summaryLabels.targetLabel', {
            unit: getUnitLabel(unit),
            value: this.getRoundedValue(value, unit),
          }).toString()
        : undefined

    printingItems.push(this.entryToItem(this.getSummaryData.deviation, deviationLabel, referenceLabel))
    if (this.getSummaryData.compensationApplied) {
      const label = this.$t('summaryLabels.compensation').toString()
      value = this.getSummaryData.compensationApplied.average
      unit = this.getSummaryData.compensationApplied.scalarUnit
      referenceLabel = this.$t('summaryLabels.averageLabel', {
        unit: getUnitLabel(unit),
        value: this.getRoundedValue(value, unit),
      }).toString()
      printingItems.push(this.entryToItem(this.getSummaryData.compensationApplied, label, referenceLabel))
    }

    if (this.getSummaryData.residualStress) {
      printingItems.push(
        this.entryToItem(this.getSummaryData.residualStress, this.$t('summaryLabels.residualStress').toString()),
      )
    }

    if (this.getSummaryData.buildStress) {
      printingItems.push(
        this.entryToItem(this.getSummaryData.buildStress, this.$t('summaryLabels.buildStress').toString()),
      )
    }

    this.printingSummary = {
      conclusion: this.getSummaryData.withinTolerance,
      items: printingItems,
      label: this.getPrintingLabel(),
    }
  }

  private resetActiveItem() {
    this.$root.$emit('activeItemSet', this)
  }

  private entryToItem(entry: ILocationEntry, label: string, referenceLabel?: string): IProcessItem {
    return {
      label,
      referenceLabel,
      details: this.itemDetails(entry.location, entry.locationUnit),
      value: this.getRoundedValue(entry.maximum, entry.scalarUnit),
      unit: entry.scalarUnit,
      active: false,
      location: entry.location,
    }
  }

  private prepareRecoaterData() {
    this.recoatingSummary = {
      items: this.getSummaryData.recoater.steps
        .sort((a, b) => {
          return a.clearance - b.clearance
        })
        .map((s) => ({
          details: this.itemDetails(s.location, this.getSummaryData.recoater.locationUnit),
          value: this.getRoundedValue(s.clearance, this.getSummaryData.recoater.scalarUnit),
          unit: this.getSummaryData.recoater.scalarUnit,
          active: false,
          risk: s.risk,
          location: s.location,
        })),
      conclusion: this.getSummaryData.recoaterRisk,
      label: this.$t('summaryLabels.recoaterClearance').toString(),
    }
  }

  private itemDetails(values: number[], unit: Unit) {
    const coords = values.map((d) => {
      return this.getRoundedValue(d, unit)
    })

    return `${coords.join(', ')} (${getUnitLabel(unit)})`
  }

  private getRoundedValue(value: number, unit: Unit): number {
    const tolerance = Math.abs(getUnitTolerance(unit))
    return this.getFlooredFixed(value, tolerance)
  }

  private getFlooredFixed(v: number, d: number) {
    return +(Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d)
  }

  private getPrintingLabel(): string {
    if (this.getSummaryData.compensationApplied && this.getSummaryData.residualStress) {
      return this.$t('summaryLabels.compensatedPrintingPost').toString()
    }

    if (this.getSummaryData.compensationApplied) {
      return this.isSinterPlan
        ? this.$t('summaryLabels.compensatedSintering').toString()
        : this.$t('summaryLabels.compensatedPrinting').toString()
    }

    if (this.getSummaryData.residualStress) {
      return this.$t('summaryLabels.printingPost').toString()
    }

    return this.isSinterPlan
      ? this.$t('summaryLabels.sintering').toString()
      : this.$t('summaryLabels.printing').toString()
  }
}
