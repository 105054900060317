
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class SpinButtons extends Vue {
  @Prop() onUpSpinClick: Function
  @Prop() onDownSpinClick: Function
  @Prop({ type: Boolean, default: false }) disabled

  upSpinClick() {
    if (this.onUpSpinClick && !this.disabled) this.onUpSpinClick()
  }

  downSpinClick() {
    if (this.onDownSpinClick && !this.disabled) this.onDownSpinClick()
  }
}
