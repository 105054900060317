import { BUILD_PLATE_SIZES_TO_MM } from '@/constants'
import { GeometryType } from '@/types/BuildPlans/IBuildPlan'
import { SimulationReadyStatuses } from '@/types/Sites/SimulationReady'
import { VersionableModel } from '@/types/Common/VersionableModel'

export enum EditViewMode {
  None,
  ElectricityPrice,
  ArgonPrice,
  NitrogenPrice,
  FilterPrice,
  MaterialPrice,
  RecoaterPrice,
  RecoaterLife,
  FilterLife,
}

export enum Measurement {
  KWH = 'kWh',
  Liter = 'liter',
  Millimeter = 'mm',
  Days = 'Days',
  Builds = 'Builds',
}

export enum Currency {
  USCent = 'US¢',
  USD = 'USD',
}

export enum LifeLabel {
  Days = 'Day(s)',
  Builds = 'Build(s)',
}

export interface LifeResult {
  value: number
  lifeLabel?: LifeLabel
}

export interface Price {
  value: number
  currency?: Currency
  measurement?: string
}

export enum PrintStrategyStatus {
  Incomplete = 'Incomplete',
  Inactive = 'Inactive',
  Active = 'Active',
}

export interface SiteResponse {
  id: string
  name: string
  electricityPrice: number
  argonPrice: number
  nitrogenPrice: number
  machineTypes: string[]
  siteMachineConfigs: any
}

export interface MachineType {
  id: string
  siteId: string
  name: string
  modality: string
  machines: string[]
  buildVolume: BuildVolume
  filterPrice: Price
  materials: string[]
  machineConfigId: number
}

export interface MethodPrice extends Price {
  measurement: Measurement
}

export interface BuildVolume {
  height: number
  width: number
  length: number
  measurement: Measurement
}

export interface Site {
  id: string
  name: string
  electricityPrice: MethodPrice
  argonPrice: MethodPrice
  nitrogenPrice: MethodPrice
  machineTypes: string[]
  siteMachineConfigs: any
}

export class SiteDto {
  id: string
  name: string
  electricityPrice: MethodPrice
  argonPrice: MethodPrice
  nitrogenPrice: MethodPrice
  machineTypes: string[]

  constructor(site: SiteResponse) {
    this.id = site.id
    this.name = site.name
    this.electricityPrice = {
      value: site.electricityPrice,
      currency: Currency.USCent,
      measurement: Measurement.KWH,
    }
    this.argonPrice = {
      value: site.argonPrice,
      currency: Currency.USD,
      measurement: Measurement.Liter,
    }
    this.nitrogenPrice = {
      value: site.nitrogenPrice,
      currency: Currency.USD,
      measurement: Measurement.Liter,
    }
    this.machineTypes = site.machineTypes ? site.machineTypes : []
  }
}

export interface MachineTypeResponse {
  id: number
  name: string
  machineConfigId: number
  buildVolumeX: number
  buildVolumeY: number
  buildVolumeZ: number
  siteMachineConfigId: number
  machineLicenses: string[]
  siteMachineConfigMaterials: string[]
  filterPrice: number
  modality: string
}

export class MachineTypeDto {
  id: number | string
  name: string
  modality: string
  machines: string[]
  buildVolume: BuildVolume
  filterPrice: Price
  materials: string[]
  machineConfigId: number

  constructor(machineType: MachineTypeResponse) {
    this.id = machineType.id
    this.name = machineType.name
    this.modality = machineType.modality
    this.machines = machineType.machineLicenses
    this.buildVolume = {
      height: Math.round(machineType.buildVolumeX * BUILD_PLATE_SIZES_TO_MM),
      width: Math.round(machineType.buildVolumeY * BUILD_PLATE_SIZES_TO_MM),
      length: Math.round(machineType.buildVolumeZ * BUILD_PLATE_SIZES_TO_MM),
      measurement: Measurement.Millimeter,
    }
    this.filterPrice = { value: machineType.filterPrice, currency: Currency.USD }
    this.materials = machineType.siteMachineConfigMaterials
    this.machineConfigId = machineType.machineConfigId
  }
}

export class UpdateSiteDto {
  name: string
  electricityPrice: number
  argonPrice: number
  nitrogenPrice: number
}

export class UpdateMachineTypeDto {
  filterPrice: number
}

export interface MachineMaterial {
  id: string
  materialId: number
  machineTypeId: string
  name: string
  materialPrice: Price
  recoaterPrice: Price
  recoaterLife: LifeResult
  filterLife: LifeResult
}

export interface MachineConfigMaterialBinder {
  id: number
  version: number
  machineConfigId: number
  machineConfigVersion: number
  materialId: number
  materialVersion: number
  binderId: number
  binderVersion: number
  binder: Binder
}

export interface MachineConfigMaterialBinderInfo extends MachineConfigMaterialBinder {
  machineConfigId: number
  machineConfigVersion: number
}

export interface Binder extends VersionableModel {
  name: string
}

export interface SiteMachineConfigMaterialBinderDto {
  binderId: number
  binderVersion: number
  machineConfigId: number
  machineConfigVersion: number
  machineConfigMaterialBinderId: number
  machineConfigMaterialBinderVersion: number
  name: string
  binderPrice: number
  cleaningAgentPrice: number
  materialId: number
  materialVersion: number
  siteMachineConfigMaterialBinderId: number
  siteMachineConfigMaterialBinderVersion: number
  siteId: number
  siteVersion: number
  status: BinderStatus
  version: number
}

export enum BinderStatus {
  Inactive = 'Inactive',
  Active = 'Active',
}

export interface PrintStrategy {
  name: string
  status: PrintStrategyStatus
  printStrategyId: number
  processParameterName: string
  partParameters: ParameterSet[]
  simulationReadyStatus: SimulationReadyStatuses
}

export interface ParameterSet {
  id: number
  layerThickness: number
  geDefault: boolean
  parameterSetName: string
  s3FileName: string
  version: number
  visibility: boolean
  allowSimCom: boolean
  machineConfigId: number
  materialId: number
  isDefaultForCost: boolean
  worksWith: GeometryType[]
}

export class PrintStrategyParameterSetDto {
  name: string
  id: string
  allowSimCom: boolean
  layerThickness: number
  isUsed?: boolean
  parameterSetId?: number
  parameterSetVersion?: number
  dmlmData?: DMLMPartParamAdditionalInfoDto
}

export class PrintStrategyShortInfo extends VersionableModel {
  name: string
  productionSetId: number
  productionSetVersion: number
  productionSetName: string
}

export class PrintStrategyInfo extends VersionableModel {
  name: string
  status: PrintStrategyStatus
  processParameterFileId: number
  partParameters: PrintStrategyParameterSetDto[]
  materialId: number
  materialVersion: number
  machineConfigId: number
  machineConfigVersion: number
  productionId: number
  productionVersion: number
  couponId: number
  couponVersion: number
  supportImportedVolumeId: number
  supportImportedVolumeVersion: number
  supportImportedSheetId: number
  supportImportedSheetVersion: number
  supportAmpSolidId: number
  supportAmpSolidVersion: number
  supportAmpLineId: number
  supportAmpLineVersion: number
  associatedBJPartParameter: string
  machineConfigMaterialBinder: MachineConfigMaterialBinder
  machineConfigMaterialBinderId: number
  machineConfigMaterialBinderVersion: number
}

export class ParameterSetsWithAdditionalInfoDTO {
  partParameters: PrintStrategyParameterSetDto[]
}

export class DropDownItem {
  text: string
  value: number
}

export class AdditionalPrintStrategyInfo {
  availableProcessFiles: DropDownItem[]
  availablePartParameters: DropDownItem[]
  partParametersInherentStrains: ParameterSetInherentStrainAvailability[]
}

export class ParameterSetInherentStrainAvailability {
  id: number
  version: number
  available: boolean
}

export interface DMLMPartParamAdditionalInfoDto {
  id?: number
  laserPower: number
  beamDiameter: number
  hatchDistance: number
  laserSpeed: number
}

export enum PrintStrategyParameterType {
  Process = 'Process',
  Part = 'Part',
}

export interface EditableParameter {
  id: number
  type: PrintStrategyParameterType
  printStrategyId: number
  name?: string
}
