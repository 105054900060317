
import { Prop, Mixins, Component, Vue, Watch } from 'vue-property-decorator'
import { InsightMixin } from '@/components/layout/buildPlans/mixins/InsightMixin'
import { IBuildPlanInsight, InsightErrorCodes } from '@/types/BuildPlans/IBuildPlanInsight'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { IBuildPlanItem, ISelectable } from '@/types/BuildPlans/IBuildPlan'
import { SelectablePartInsightMixin } from '@/components/layout/buildPlans/mixins/SelectablePartInsightMixin'
import { DisableableInsightMixin } from '@/components/layout/buildPlans/mixins/DisableableInsightMixin'
import { IPartHeights } from '@/types/Simulation/SimulationCompensation'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component
export default class SimulateIssueByInsight extends Mixins(
  Vue,
  InsightMixin,
  SelectablePartInsightMixin,
  DisableableInsightMixin,
) {
  @Prop() insight: IBuildPlanInsight
  @buildPlansStore.Getter buildPlanItemById: (id: string) => IBuildPlanItem
  @buildPlansStore.Getter insightSelectionEnabled: boolean
  @buildPlansStore.Getter('getSelectedParts') selectedParts: ISelectable[]
  @visualizationStore.Mutation selectAndHighlightPart: (payload: {
    buildPlanItemId: string
    meshId: string
    deselectIfSelected: boolean
    showGizmo: boolean
  }) => void
  @visualizationStore.Mutation toggleHighlight: (payload: { buildPlanItemId: string; highlight: boolean }) => void
  @visualizationStore.Mutation deselect: () => void

  getpartNames(item: IPartHeights) {
    if (this.insight.errorCode !== InsightErrorCodes.SimCompToolMacroLayersWarn) {
      return null
    }
    return item.partName
  }

  get parts() {
    if (this.insight.errorCode !== InsightErrorCodes.SimCompToolMacroLayersWarn) {
      return null
    }

    return this.insight.details.parts as IPartHeights[]
  }

  get simulationInsight(): string {
    switch (this.insight.errorCode) {
      case InsightErrorCodes.SimCompToolCustomScaleFactor: {
        return this.$t('insightsLabels.simCompTool.byInsight.SimCompToolCustomScaleFactorValues', {
          XX: this.insight.details.customScalingFactor,
          YY: this.insight.details.defaultScalingFactor,
        }).toString()
      }
      case InsightErrorCodes.SimCompToolMacroLayersWarn:
    }
  }

  hoverIntoPart(item: IPartHeights) {
    const buildPlanItemId: string = item.bpItemId
    this.toggleHighlight({ buildPlanItemId, highlight: true })
  }

  hoverOutOfPart(item: IPartHeights) {
    const buildPlanItemId: string = item.bpItemId
    this.toggleHighlight({ buildPlanItemId, highlight: false })
  }

  clickOnPart(item: IPartHeights) {
    if (!this.insightSelectionEnabled || this.isPartSelectionDisabled) {
      return
    }

    const buildPlanItemId: string = item.bpItemId
    const isPartSelected = this.selectedParts.find((part) => part.id === buildPlanItemId)
    if (!isPartSelected || this.selectedParts.length > 1) {
      this.deselect()
    }
    this.selectAndHighlightPart({ buildPlanItemId, deselectIfSelected: true, meshId: item.meshId, showGizmo: true })
  }
}
