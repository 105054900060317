
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { ToggleDetailsPanelButton } from '@/components/layout/FileExplorer/Details'
import StoresNamespaces from '@/store/namespaces'
import { SceneMode } from '@/visualization/types/SceneTypes'
import CanvasDisplayMenuMixin from '@/components/layout/buildPlans/mixins/CanvasDisplayMenuMixin'
import Icon from '@/components/icons/Icon.vue'
import { IPartRenderable } from '@/types/Parts/IPartRenderable'
import { eventBus } from '@/services/EventBus'
import { BuildPlanEvents } from '@/types/Label/BuildPlanEvents'

const visualizationStore = namespace(StoresNamespaces.SinglePartVisualization)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const commonStore = namespace(StoresNamespaces.Common)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component({
  components: {
    ToggleDetailsPanelButton,
    Icon,
  },
})
export default class SinglePartCanvas extends CanvasDisplayMenuMixin {
  @visualizationStore.Getter isInitialized: boolean
  @commonStore.Getter tooltipOpenDelay: number

  @visualizationStore.Mutation init: (payload: {
    canvasId: string
    sceneMode: SceneMode
    viewMode?: ViewModeTypes
    loadDefaultPlate?: boolean
  }) => void
  @visualizationStore.Mutation dispose: Function
  @visualizationStore.Mutation delayDisposing: Function
  @visualizationStore.Mutation loadPartConfig: (payload: IPartRenderable) => void
  @visualizationStore.Mutation changeView: Function
  @visualizationStore.Mutation zoomToFitCamera: Function
  @visualizationStore.Mutation hideGizmos: Function

  @fileExplorerStore.State isOpenDetailsPanel: boolean

  @buildPlansStore.Getter getIsLoading: boolean

  @Prop() part: { id: string; name: string }

  lastLoadedPartId: string = null

  @Watch('part.id')
  async onPartChanged(part: { id: string; name: string }) {
    if (part !== null && !this.getIsLoading) {
      this.displayPart()
    }
  }

  @Watch('getIsLoading')
  onIsLoadingChanged(isPartLoading: boolean) {
    if (!isPartLoading && this.lastLoadedPartId !== this.part.id) {
      this.displayPart()
    }
  }

  mounted() {
    this.subscribeToEventBus()
    this.init({
      canvasId: 'single_part_canvas',
      sceneMode: SceneMode.SinglePart,
      viewMode: ViewModeTypes.PartPreview,
      loadDefaultPlate: false,
    })
    this.displayPart()
  }

  subscribeToEventBus() {
    eventBus.$on(BuildPlanEvents.HideGizmo, this.hideGizmos)
  }

  unsubscribeFromEventBus() {
    eventBus.$off(BuildPlanEvents.HideGizmo, this.hideGizmos)
  }

  displayPart() {
    try {
      this.loadPartConfig({ partId: this.part.id, partName: this.part.name })
      this.lastLoadedPartId = this.part.id
      this.zoomToFitCamera()
    } catch (error) {
      console.error(`Error while receiving data: ${error}`)
    }
  }

  async disposeScene() {
    // Need to delay disposing in this case because initializing is asynchronous
    // Destroying component and disposing scene before model is fully loaded will cause errors
    if (this.isInitialized) {
      // Need to use promise to wait until dispose fully finished,
      // because visualization dispose mutation is asynchronous
      const disposePromise = this.createDisposePromise()
      this.dispose(disposePromise.done)
      await disposePromise.promise
    } else {
      this.delayDisposing()
    }
  }

  beforeDestroy() {
    this.disposeScene()
    this.unsubscribeFromEventBus()
  }

  private createDisposePromise() {
    let done: Function
    const promise = new Promise<void>((resolve) => {
      done = resolve
    })

    return { done, promise }
  }
}
