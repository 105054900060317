import { ActionTree } from 'vuex'
import { IRootState } from '@/store/types'
import { ILabelState } from '@/store/modules/label/types'
import label from '@/api/label'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { eventBus } from '@/services/EventBus'
import { InteractiveServiceEvents } from '@/types/InteractiveService/InteractiveServiceEvents'
import { ManualPatch, Patch } from '@/types/Label/Patch'
import { TextElement } from '@/types/Label/TextElement'
import { BooleanType, LabelDirtyState, MarkingContentElementType, MarkingLocation } from '@/types/Label/enums'
import { LabelInsightRelatedItem } from '@/types/InteractiveService/LabelMessageContent'
import { LabelSetDto } from '@/types/Label/LabelSetDto'
import { GeometryType, IBuildPlanItem, ISelectable, PartProperty } from '@/types/BuildPlans/IBuildPlan'
import { createPlacement, Placement } from '@/types/Label/Placement'
import {
  createLabeledBodyWithTransformation,
  LabeledBodyWIthTransformation,
} from '@/types/Label/LabeledBodyWIthTransformation'
import {
  AutomatedTrackableLabel,
  createAutomatedTrackableLabel,
  createManualTrackableLabel,
  ManualTrackableLabel,
  TrackableLabel,
} from '@/types/Label/TrackableLabel'
import { getBuildPlanItemTransformationWithoutScale, getTextElementByType } from '@/utils/label/labelUtils'
import { PART_BODY_ID_DELIMITER } from '@/constants'
import { CachedLabelInsight, IBuildPlanInsight, InsightErrorCodes } from '@/types/BuildPlans/IBuildPlanInsight'
import { ILabelOrientation } from '@/types/Marking/ILabel'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { v4 as uuidv4 } from 'uuid'

export const actions: ActionTree<ILabelState, IRootState> = {
  async getLabelSetsByBuildPlanId(
    { state, commit, rootGetters, dispatch, getters },
    payload: { buildPlanId: string; dirtyStateAddIfNew?: boolean },
  ) {
    const labelSets: LabelSetDto[] = await label.getLabelSetsByBuildPlanId(payload.buildPlanId)
    const bpItems: IBuildPlanItem[] = rootGetters['buildPlans/getAllBuildPlanItems']
    const interactiveLabelSets: InteractiveLabelSet[] = labelSets.map((labelSetDto: LabelSetDto) => {
      return InteractiveLabelSet.fromDto(labelSetDto, bpItems, !!payload.dirtyStateAddIfNew)
    })
    commit(
      'setLabelSets',
      interactiveLabelSets.sort((a: InteractiveLabelSet, b: InteractiveLabelSet) => a.orderIndex - b.orderIndex),
    )
    const existingLabelSetsIds = state.labelSets.map((labelSet: InteractiveLabelSet) => labelSet.id)
    const labelSetsIDsForUpdate = state.labelSetsIDsToUpdate
      ? state.labelSetsIDsToUpdate.filter((id: string) => existingLabelSetsIds.includes(id))
      : []
    if (state.labelSetsIDsToUpdate && state.labelSetsIDsToUpdate.length !== labelSetsIDsForUpdate.length) {
      // Update insights
      const buildPlan = rootGetters['buildPlans/getBuildPlan']
      buildPlan.labelSetIDsToUpdate = labelSetsIDsForUpdate
      if (!labelSetsIDsForUpdate.length) {
        commit('buildPlans/setRequiresLabelSetUpdates', false, { root: true })
      }
      dispatch('setLabelSetsIDsForUpdate', { ids: labelSetsIDsForUpdate })
      await dispatch('buildPlans/updateBuildPlanV1', { buildPlan }, { root: true })
    }

    if (interactiveLabelSets.length) {
      // Updates of label set insights should only take place if there are label sets
      dispatch('updateLabelIdsForLabelInsights', interactiveLabelSets)
    }

    commit('setDynamicElements')
    return labelSets
  },

  async updateLabelIdsForLabelInsights({ rootGetters, dispatch, getters }, interactiveLabelSets) {
    // Due to the re-generation of label ids we should update insights related items that are made for a label tool
    // so we have a scene-to-insight connection
    let insights = rootGetters['buildPlans/labelInsights']
    const bpItems: IBuildPlanItem[] = rootGetters['buildPlans/getAllBuildPlanItems']
    const bpItemsIds: string[] = bpItems.map((bpItem: IBuildPlanItem) => bpItem.id)
    const labelSetIds: string = interactiveLabelSets.map((labelSet: InteractiveLabelSet) => labelSet.id)
    let insightsIdsToUpdate = []
    interactiveLabelSets.forEach((labelSet: InteractiveLabelSet) => {
      labelSet.labels.forEach((trackableLabel: TrackableLabel) => {
        // There are two different ways to find updated build plan insights based on a placement method of a label set
        if (labelSet.settings.placementMethodAutomatic) {
          const automatedInsightsToUpdate = getters.getAutomatedInsightsToUpdate(labelSet, trackableLabel)
          insights = automatedInsightsToUpdate.insights
          insightsIdsToUpdate = automatedInsightsToUpdate.insightsIdsToUpdate
        } else {
          const manualInsightsToUpdate = getters.getManualInsightsToUpdate(labelSet, trackableLabel)
          insights = manualInsightsToUpdate.insights
          insightsIdsToUpdate = manualInsightsToUpdate.insightsIdsToUpdate
        }
      })
    })
    // Remove insights' related items which refer to non-existing build plan items with adding a console warn with
    // non-existing item description
    insights.map((insight: IBuildPlanInsight) => {
      const insightDetails = insight.details
      const relatedItems = insightDetails && insight.details.relatedItems
      const insightLabelSetId = relatedItems && relatedItems.length && relatedItems[0].labelSetId
      // Skip insight if it has no details, related items, does not belong to any label set or does not have id (was
      // not saved yet)
      if (!insightDetails || !relatedItems || !labelSetIds.includes(insightLabelSetId) || !insight.id) {
        return insight
      }

      insight.details.relatedItems = insight.details.relatedItems.filter((relatedItem: LabelInsightRelatedItem) => {
        const refersExistingBpItem = bpItemsIds.includes(relatedItem.buildPlanItemId)
        // If insight related item refers to non-existing build plan item - filter out such record with a console
        // warn added with record info
        if (!refersExistingBpItem) {
          console.warn(
            `Insight with id`,
            insight.id,
            'had a record that referred to a non-existing build plan item id =',
            relatedItem.buildPlanItemId,
            'component id =',
            relatedItem.componentId,
            'geometry id =',
            relatedItem.geometryId,
            ', which was removed from insight.',
          )
          if (!insightsIdsToUpdate.includes(insight.id)) {
            insightsIdsToUpdate.push(insight.id)
          }
        }

        return refersExistingBpItem
      })
      return insight
    })
    // If there are insights to update we should send them to a server
    if (insightsIdsToUpdate.length) {
      const insightsToUpdate = insights.filter((i: IBuildPlanInsight) => insightsIdsToUpdate.includes(i.id))
      dispatch(
        'buildPlans/updateInsightMultiple',
        {
          insights: insightsToUpdate,
          stateOnly: false,
        },
        { root: true },
      )
    }
  },

  async createLabelSets({ state, commit }, labelSets: InteractiveLabelSet[]) {
    return await label.createLabelSets(labelSets)
  },

  async deleteLabelSet({ state, commit }, labelSetId: string) {
    return await label.deleteLabelSet(labelSetId)
  },

  async deleteLabelSets({ state, commit }, labelSetIds: string[]) {
    return await label.deleteLabelSets(labelSetIds)
  },

  async updateLabelSet({ state, commit }, labelSet: InteractiveLabelSet) {
    return await label.updateLabelSet(labelSet.id, labelSet)
  },

  async updateLabelSets({ state, commit }, labelSets: InteractiveLabelSet[]) {
    return await label.updateLabelSets(labelSets)
  },

  async clearLabelSetsOnUndoRedoAfterDuplicate(
    { state, commit, dispatch, rootGetters },
    removedBuildPlanItems: IBuildPlanItem[],
  ) {
    const buildPlanId = rootGetters['buildPlans/getBuildPlan'].id
    const buildPlanItems = rootGetters['buildPlans/getAllBuildPlanItems']
    const allBuildPlanItems = buildPlanItems.concat(removedBuildPlanItems)
    const removedBuildPlanItemIds = removedBuildPlanItems.map((i) => i.id)
    const labelSetDtos = await label.getLabelSetsByBuildPlanId(buildPlanId)
    const labelSets = labelSetDtos.map((dto) => InteractiveLabelSet.fromDto(dto, allBuildPlanItems, false))
    labelSets.forEach(
      (ls) =>
        (ls.selectedBodies = ls.selectedBodies.filter((sb) => !removedBuildPlanItemIds.includes(sb.buildPlanItemId))),
    )

    await dispatch('updateLabelSets', labelSets)
    await dispatch('getLabelSetsByBuildPlanId', { buildPlanId })
  },

  async deleteLabelSetPatch({ state, commit }, { labelSetId, patchId }) {
    return await label.deleteLabelSetPatch(labelSetId, patchId)
  },

  async createLabelSetPatch({ state, commit }, { labelSetId, patches }) {
    return await label.createLabelSetPatch(labelSetId, patches)
  },

  async saveLabelSets({ state, dispatch, getters, rootGetters }, ignoreLoading: boolean = false) {
    const { labelSetsToCreate, labelSetsToUpdate, labelSetsToRemove } = getters.getLabelSetsToSave

    const labelSetsPromises = []
    if (labelSetsToCreate.length) {
      const labelSetsDtosToCreate = labelSetsToCreate.map((labelSetToCreate: InteractiveLabelSet) => {
        return InteractiveLabelSet.toDto(labelSetToCreate)
      })
      labelSetsPromises.push(dispatch('createLabelSets', labelSetsDtosToCreate))
    }

    if (labelSetsToUpdate.length) {
      const labelSetsDtosToUpdate = labelSetsToUpdate.map((labelSetToUpdate: InteractiveLabelSet) => {
        return InteractiveLabelSet.toDto(labelSetToUpdate)
      })
      labelSetsPromises.push(dispatch('updateLabelSets', labelSetsDtosToUpdate))
    }

    if (labelSetsToRemove.length) {
      labelSetsPromises.push(
        dispatch(
          'deleteLabelSets',
          labelSetsToRemove.map((labelSet) => labelSet.id),
        ),
      )
    }

    await Promise.all(labelSetsPromises)
    if (!ignoreLoading) {
      await dispatch('getLabelSetsByBuildPlanId', { buildPlanId: rootGetters['buildPlans/getBuildPlan'].id })
    }
  },

  async updateRelatedLabelsOnRemove(
    { dispatch, commit, getters, rootGetters },
    payload?: {
      bodiesIds: Array<{
        buildPlanItemId: string
        geometryId: string
        componentId: string
      }>
      automaticOnly: boolean
      doNotSetRequiresLabelSetUpdates?: boolean
    },
  ) {
    let labelSetsIDsToDelete: string[] = getters.getLabelSetsIDsToDelete(payload && payload.bodiesIds)
    let labelSetsToUpdate: InteractiveLabelSet[] = getters.getLabelSetsToUpdateOnRemove(payload && payload.bodiesIds)
    if (payload && payload.automaticOnly) {
      labelSetsIDsToDelete = labelSetsIDsToDelete.filter((labelSetId: string) => {
        const labelSet = getters.getLabelSetById(labelSetId)
        return labelSet.settings.placementMethodAutomatic
      })
      labelSetsToUpdate = labelSetsToUpdate.filter((labelSet: InteractiveLabelSet) => {
        return labelSet.settings.placementMethodAutomatic
      })
    }
    const textElementsForManualUpdate = [
      MarkingContentElementType.Grid_Letter,
      MarkingContentElementType.Sequential_Integer,
    ]
    const promises = []
    const buildPlan = rootGetters['buildPlans/getBuildPlan']
    const stateLabelSetsIDsManualUpdate = rootGetters['label/getLabelSetsIDsForUpdate'] || []
    const additionalLabelSetsIDsManualUpdate: string[] = []

    if (labelSetsIDsToDelete.length) {
      promises.push(dispatch('deleteLabelSets', labelSetsIDsToDelete))
    }

    if (labelSetsToUpdate.length || labelSetsIDsToDelete.length) {
      labelSetsToUpdate.forEach((labelSet: InteractiveLabelSet) => {
        labelSet.settings.textElements.forEach((textElement: TextElement) => {
          if (textElementsForManualUpdate.includes(textElement.type)) {
            additionalLabelSetsIDsManualUpdate.push(labelSet.id)
          }
        })
      })
      // If bodies ids are specified - we need to remove labels from those bodies
      if (payload && payload.bodiesIds) {
        const labelsToRemove = []
        const labelSetsToRemove = getters.labelSets.filter((labelSet: InteractiveLabelSet) => {
          return labelSetsIDsToDelete.includes(labelSet.id)
        })
        const labelSetsWithLabelsToRemove = [...labelSetsToUpdate, ...labelSetsToRemove]
        // For each label set that have to be updated/removed - clear labels from a scene of corresponding bodies
        labelSetsWithLabelsToRemove.forEach((ls: InteractiveLabelSet) => {
          const labelSet = getters.labelSets.find((set) => set.id === ls.id)
          const labelSetBodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]
          if (labelSetBodies.length) {
            labelSetBodies.forEach((body) => {
              const includesBody = payload.bodiesIds.some((b) => {
                return (
                  b.buildPlanItemId === body.buildPlanItemId &&
                  b.componentId === body.componentId &&
                  b.geometryId === body.geometryId
                )
              })
              if (includesBody) {
                labelsToRemove.push({
                  labelSetId: labelSet.id,
                  buildPlanItemId: body.buildPlanItemId,
                  componentId: body.componentId,
                  geometryId: body.geometryId,
                })
              }
            })
          }
        })
        dispatch('removeLabels', { labelsInfo: labelsToRemove })
      }

      const labelSetsIDsManualUpdate: string[] = [
        ...new Set([...stateLabelSetsIDsManualUpdate, ...additionalLabelSetsIDsManualUpdate]),
      ]
      if (labelSetsIDsManualUpdate.length) {
        buildPlan.labelSetIDsToUpdate = labelSetsIDsManualUpdate
        if (!payload || !payload.doNotSetRequiresLabelSetUpdates) {
          commit('buildPlans/setRequiresLabelSetUpdates', true, { root: true })
        }

        dispatch('setLabelSetsIDsForUpdate', { ids: labelSetsIDsManualUpdate })
        promises.push(dispatch('buildPlans/updateBuildPlanV1', { buildPlan }, { root: true }))
      }
      promises.push(dispatch('updateLabelSets', labelSetsToUpdate))
    }

    await Promise.all(promises)
    await dispatch('getLabelSetsByBuildPlanId', { buildPlanId: buildPlan.id })

    return promises.length
  },

  async updateRelatedLabelsOnBodyFunctionChange(
    { dispatch, commit, getters, rootGetters },
    payload: {
      before: PartProperty
      after: PartProperty
      bpItem: IBuildPlanItem
    },
  ) {
    commit('buildPlans/setRequiresLabelSetUpdates', false, { root: true })
    const labelSets = getters.labelSets
    // If there are no label sets - do not update anything
    if (!labelSets.length) {
      return []
    }

    // Find if build plan item with changed body function on it contains any labels before operation
    const bpItemHasLabels = labelSets.some((labelSet: InteractiveLabelSet) => {
      const bodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]
      return bodies.some((body: LabeledBodyWIthTransformation) => {
        return body.buildPlanItemId === payload.bpItem.id
      })
    })

    // Determine if body function was changed from a coupon
    const changedFromCoupon: boolean =
      payload.before.type === GeometryType.Coupon && payload.after.type !== GeometryType.Coupon
    // Determine if body changed was changed to a coupon
    const changedToCoupon: boolean =
      payload.before.type !== GeometryType.Coupon && payload.after.type === GeometryType.Coupon

    const buildPlanItemId = payload.bpItem.id
    const componentId = payload.after.geometryId.split(PART_BODY_ID_DELIMITER).shift()
    const geometryId = payload.after.geometryId.split(PART_BODY_ID_DELIMITER).pop()

    if (bpItemHasLabels && changedFromCoupon) {
      // Remove all automatic (views, bars) labels from a body if body function was changed from coupon
      await dispatch('updateRelatedLabelsOnRemove', {
        bodiesIds: [{ buildPlanItemId, geometryId, componentId }],
        automaticOnly: true,
      })
    } else if (changedToCoupon) {
      // If body function to a coupon, if similar bodies are involved into label sets that have "label all instances"
      // option turned on - add this body to those label sets as a selected body
      const automatedLabelSetsWithLabelAllInstances = getters.labelSets.filter((labelSet: InteractiveLabelSet) => {
        return (
          labelSet.settings.placementMethodAutomatic &&
          labelSet.settings.hasLabeledInstances &&
          [...labelSet.selectedBodies, ...labelSet.relatedBodies].some(
            (b) => b.geometryId === geometryId && b.componentId === componentId,
          )
        )
      })
      const labelSetsIdsToUpdate = automatedLabelSetsWithLabelAllInstances.map(
        (labelSet: InteractiveLabelSet) => labelSet.id,
      )
      labelSets
        .filter((labelSet: InteractiveLabelSet) => labelSetsIdsToUpdate.includes(labelSet.id))
        .forEach((labelSet: InteractiveLabelSet) => {
          // For each label set create new body and empty patch, so the system will be able to update such label
          // Take build plan item id for the first selected body as the all are identical due to the being of the same
          // instance
          const selectedBodyBpItemId = labelSet.selectedBodies[0].buildPlanItemId
          labelSet.patches
            .filter((patch: Patch) => patch.buildPlanItemId === selectedBodyBpItemId)
            .forEach((patch: Patch) => {
              dispatch('createBodyAndEmptyPatch', {
                patch,
                componentId,
                geometryId,
                labelSet,
                buildPlanItem: payload.bpItem,
              })
            })
        })
      // Save changes and re-fetch label sets data
      if (labelSetsIdsToUpdate.length) {
        await dispatch('setLabelSetsToUpdatedAndFetch', { labelSetsIdsToUpdate })
      }
    }

    // if there is at least one id we should set requiresLabelSetUpdates to true
    if (getters.getLabelSetsIDsForUpdate.length) {
      commit('buildPlans/setRequiresLabelSetUpdates', true, { root: true })
    }
  },

  async updateRelatedLabelsOnBodyFunctionChangeBatch(
    { dispatch, commit, getters, rootGetters },
    payload: Array<{
      before: PartProperty
      after: PartProperty
      bpItem: IBuildPlanItem
    }>,
  ) {
    commit('buildPlans/setRequiresLabelSetUpdates', false, { root: true })
    const labelSets = getters.labelSets
    // If there are no label sets - do not update anything
    if (!labelSets.length) {
      return []
    }

    const changedFromCoupon = []
    const labelSetIdsToUpdateAndFetch = []
    payload.forEach((bodyProperties) => {
      // Find if build plan item with changed body function on it contains any labels before operation
      const bpItemHasLabels = labelSets.some((labelSet: InteractiveLabelSet) => {
        const bodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]
        return bodies.some((body: LabeledBodyWIthTransformation) => {
          return body.buildPlanItemId === bodyProperties.bpItem.id
        })
      })

      // Determine if body function was changed from a coupon
      const isChangedFromCoupon: boolean =
        bodyProperties.before.type === GeometryType.Coupon && bodyProperties.after.type !== GeometryType.Coupon
      // Determine if body changed was changed to a coupon
      const isChangedToCoupon: boolean =
        bodyProperties.before.type !== GeometryType.Coupon && bodyProperties.after.type === GeometryType.Coupon

      const buildPlanItemId = bodyProperties.bpItem.id
      const [componentId, geometryId] = bodyProperties.after.geometryId.split(PART_BODY_ID_DELIMITER)

      if (bpItemHasLabels && isChangedFromCoupon) {
        changedFromCoupon.push({ buildPlanItemId, geometryId, componentId })
      } else if (isChangedToCoupon) {
        // If body function to a coupon, if similar bodies are involved into label sets that have "label all instances"
        // option turned on - add this body to those label sets as a selected body
        const automatedLabelSetsWithLabelAllInstances = getters.labelSets.filter((labelSet: InteractiveLabelSet) => {
          return (
            labelSet.settings.placementMethodAutomatic &&
            labelSet.settings.hasLabeledInstances &&
            [...labelSet.selectedBodies, ...labelSet.relatedBodies].some(
              (b) => b.geometryId === geometryId && b.componentId === componentId,
            )
          )
        })

        const labelSetsIdsToUpdate = automatedLabelSetsWithLabelAllInstances.map(
          (labelSet: InteractiveLabelSet) => labelSet.id,
        )

        labelSets
          .filter((labelSet: InteractiveLabelSet) => labelSetsIdsToUpdate.includes(labelSet.id))
          .forEach((labelSet: InteractiveLabelSet) => {
            // For each label set create new body and empty patch, so the system will be able to update such label
            // Take build plan item id for the first selected body as the all are identical due to the being of the same
            // instance
            const selectedBodyBpItemId = labelSet.selectedBodies[0].buildPlanItemId
            labelSet.patches
              .filter((patch: Patch) => patch.buildPlanItemId === selectedBodyBpItemId)
              .forEach((patch: Patch) => {
                dispatch('createBodyAndEmptyPatch', {
                  patch,
                  componentId,
                  geometryId,
                  labelSet,
                  buildPlanItem: bodyProperties.bpItem,
                })
              })
          })

        if (labelSetsIdsToUpdate.length) {
          labelSetIdsToUpdateAndFetch.push(...labelSetsIdsToUpdate)
        }
      }
    })

    // Remove all automatic (views, bars) labels from a body if body function was changed from coupon
    if (changedFromCoupon.length)
      await dispatch('updateRelatedLabelsOnRemove', {
        bodiesIds: changedFromCoupon,
        automaticOnly: true,
        doNotSetRequiresLabelSetUpdates: true,
      })

    // Save changes and re-fetch label sets data
    if (labelSetIdsToUpdateAndFetch.length) {
      await dispatch('setLabelSetsToUpdatedAndFetch', {
        labelSetsIdsToUpdate: [...new Set(labelSetIdsToUpdateAndFetch)],
        doNotSetRequiresLabelSetUpdates: true,
      })
    }

    // if there is at least one id we should set requiresLabelSetUpdates to true
    if (getters.getLabelSetsIDsForUpdate.length) {
      commit('buildPlans/setRequiresLabelSetUpdates', true, { root: true })
    }
  },

  async updateRelatedLabelsOnTransferProperties(
    { dispatch, commit, getters, rootGetters },
    payload: {
      source: IBuildPlanItem
      targets: IBuildPlanItem[]
    },
  ) {
    commit('buildPlans/setRequiresLabelSetUpdates', false, { root: true })
    // Determine if source build plan item is included into label sets
    const sourceLabelSets: InteractiveLabelSet[] = getters.labelSets.filter((labelSet: InteractiveLabelSet) => {
      return [...labelSet.selectedBodies, ...labelSet.relatedBodies].some((body: LabeledBodyWIthTransformation) => {
        return body.buildPlanItemId === payload.source.id
      })
    })
    const targetsBpItemsIds = payload.targets.map((bpItem: IBuildPlanItem) => bpItem.id)
    // Determine if target build plan items are included into label sets
    const targetsContainsLabels: boolean = getters.labelSets.some((labelSet: InteractiveLabelSet) => {
      return [...labelSet.selectedBodies, ...labelSet.relatedBodies].some((body: LabeledBodyWIthTransformation) => {
        return targetsBpItemsIds.includes(body.buildPlanItemId)
      })
    })
    // If neither source nor targets are involved into label sets - do not do anything
    if (!sourceLabelSets.length && !targetsContainsLabels) {
      return
    }

    if (targetsContainsLabels) {
      // If targets contain label sets - remove all labels and bodies of targets from selected/related bodies
      const bodiesIds = payload.targets.flatMap((bpItem: IBuildPlanItem) => {
        return bpItem.partProperties.map((partProperty: PartProperty) => {
          const buildPlanItemId = bpItem.id
          const componentId = partProperty.geometryId.split(PART_BODY_ID_DELIMITER).shift()
          const geometryId = partProperty.geometryId.split(PART_BODY_ID_DELIMITER).pop()
          return { buildPlanItemId, geometryId, componentId }
        })
      })

      if (bodiesIds.length) {
        // In order to prevent data inergiry issue we should trigger
        // "setRequiresLabelSetUpdates"  only once instead of seting it several times
        await dispatch('updateRelatedLabelsOnRemove', { bodiesIds, doNotSetRequiresLabelSetUpdates: true })
      }
    }

    if (sourceLabelSets.length) {
      // If source build plan item has labels on it - and targets to corresponding label sets
      sourceLabelSets.forEach((labelSet: InteractiveLabelSet) => {
        labelSet.patches
          .filter((patch: Patch) => patch.buildPlanItemId === payload.source.id)
          .forEach((patch: Patch) => {
            // For each patch on source create a patch and a body for each target
            payload.targets.forEach((target: IBuildPlanItem) => {
              dispatch('createBodyAndEmptyPatch', {
                labelSet,
                patch,
                buildPlanItem: target,
                componentId: patch.componentId,
                geometryId: patch.geometryId,
              })
            })
          })
      })
      const labelSetsIdsToUpdate = sourceLabelSets.map((labelSet: InteractiveLabelSet) => labelSet.id)
      // Save changes and re-fetch label sets data
      if (labelSetsIdsToUpdate.length) {
        await dispatch('setLabelSetsToUpdatedAndFetch', { labelSetsIdsToUpdate, doNotSetRequiresLabelSetUpdates: true })
      }
    }

    // if there is at least one id we should set requiresLabelSetUpdates to true
    if (getters.getLabelSetsIDsForUpdate.length) {
      commit('buildPlans/setRequiresLabelSetUpdates', true, { root: true })
    }
  },

  createBodyAndEmptyPatch(
    { commit },
    payload: {
      buildPlanItem: IBuildPlanItem
      componentId: string
      geometryId: string
      labelSet: InteractiveLabelSet
      patch: Patch
    },
  ) {
    // Create new body
    const bodyToAdd = createLabeledBodyWithTransformation(
      payload.buildPlanItem.id,
      payload.componentId,
      payload.geometryId,
      payload.buildPlanItem.part.id,
      getBuildPlanItemTransformationWithoutScale(payload.buildPlanItem),
    )
    // Add new body into the label set
    commit('addSelectedBodiesIntoLabelSet', { labelSetId: payload.labelSet.id, toAdd: [bodyToAdd] })
    // Create new empty patch
    const patchToAdd = JSON.parse(JSON.stringify(payload.patch))
    patchToAdd.id = uuidv4()
    patchToAdd.buildPlanItemId = payload.buildPlanItem.id
    patchToAdd.labelS3FileName = null
    patchToAdd.labelFileKey = null
    patchToAdd.patchS3FileName = null
    patchToAdd.patchFileKey = null
    // Add new patch into the label set
    commit('addPatchesIntoLabelSet', { labelSetId: payload.labelSet.id, toAdd: [patchToAdd] })
  },

  async setLabelSetsToUpdatedAndFetch(
    { getters, dispatch, commit, rootGetters },
    payload: { labelSetsIdsToUpdate: string[]; doNotsetRequiresLabelSetUpdates?: boolean },
  ) {
    const buildPlan = rootGetters['buildPlans/getBuildPlan']
    const { labelSetsIdsToUpdate, doNotsetRequiresLabelSetUpdates } = payload
    const labelSetsToUpdate = getters.labelSets.filter((labelSet: InteractiveLabelSet) => {
      return labelSetsIdsToUpdate.includes(labelSet.id)
    })

    await dispatch('updateLabelSets', labelSetsToUpdate)
    buildPlan.labelSetIDsToUpdate = buildPlan.labelSetIDsToUpdate
      ? [...new Set([...buildPlan.labelSetIDsToUpdate, ...labelSetsIdsToUpdate])]
      : labelSetsIdsToUpdate
    dispatch('setLabelSetsIDsForUpdate', { ids: buildPlan.labelSetIDsToUpdate })
    await dispatch('buildPlans/updateBuildPlanV1', { buildPlan }, { root: true })
    await dispatch('getLabelSetsByBuildPlanId', { buildPlanId: buildPlan.id, dirtyStateAddIfNew: true })
    if (!doNotsetRequiresLabelSetUpdates) {
      commit('buildPlans/setRequiresLabelSetUpdates', true, { root: true })
    }
  },

  setActiveLabelSet({ state, dispatch, commit, getters, rootGetters }, labelSet: InteractiveLabelSet) {
    // Changes in selection should be ignored while the setting of the active label set.
    // Removing bodies of a previous label set should not trigger the execute command without bodies selected
    // Adding bodies of a new label set should not trigger the execute command with already calculated labels
    commit('setIgnoreChangesByDirtyStates', true)
    commit('buildPlans/selectItems', { selectedItems: null, attach: false }, { root: true })
    commit('setIsLabelSetOpened', false)
    commit('setIsPatchedBodiesSelected', false)
    let bodyIds = []
    if (labelSet) {
      bodyIds = [
        ...new Set(
          labelSet.selectedBodies.map((body) =>
            JSON.stringify({
              buildPlanItemId: body.buildPlanItemId,
              componentId: body.componentId,
              geometryId: body.geometryId,
            }),
          ),
        ),
      ].map((ids) => JSON.parse(ids))

      const manualPlacements: ManualPatch[] = getters.manualPlacementsForLabelSet(labelSet.id)
      // Placement origins should not be shown for automatic placements, manual only
      if (!labelSet.settings.placementMethodAutomatic) {
        commit(
          'visualizationModule/showManuallyPlacedLabelOrigins',
          { patches: manualPlacements, labelSetId: labelSet.id },
          { root: true },
        )
      }
    }

    commit('setActiveLabelSet', labelSet)
    commit('visualizationModule/selectBodies', { bodyIds, attach: false }, { root: true })

    commit('setIgnoreChangesByDirtyStates', false)
  },

  removeLabelSet({ commit, dispatch, getters }, id: string) {
    const [, ...counterRelatedLabelSets] = getters.getLabelSetWithRelatedLabelSets(id, false)
    commit('removeLabelSet', id)
    dispatch('removeLabels', { labelsInfo: [{ labelSetId: id }], stateOnly: true })
    if (counterRelatedLabelSets.length) {
      counterRelatedLabelSets.forEach((labelSet: InteractiveLabelSet) => {
        dispatch('makeAllTrackableLabelsDirtyByLabelSetId', { id: labelSet.id, dirtyState: LabelDirtyState.Update })
      })
    }

    const isActiveLabelSetToRemove = getters.activeLabelSet && getters.activeLabelSet.id === id
    if (isActiveLabelSetToRemove) {
      dispatch('setActiveLabelSet', null)
    }
  },

  async removeLabels(
    { commit, rootGetters, dispatch, getters },
    payload: {
      labelsInfo: Array<{ labelSetId: string; buildPlanItemId?: string; componentId?: string; geometryId?: string }>
      stateOnly: boolean
    },
  ) {
    if (!payload.labelsInfo.length) {
      return
    }

    await dispatch('removeLabelsFromCanvas', payload.labelsInfo)
    const labelSetIds = payload.labelsInfo.map((ids) => ids.labelSetId)
    const insightsToUpdate = []
    const idsToRemove = []
    const insightsToCreate = []
    const insightsToRemove = []
    const labelSetInsights = rootGetters['buildPlans/labelInsights'].filter((insight) => {
      const relatedItems = insight.details.relatedItems
      return relatedItems && relatedItems.length && labelSetIds.includes(relatedItems[0].labelSetId)
    })
    const labelSets = getters.labelSets

    for (const insight of labelSetInsights) {
      for (const relatedItem of insight.details.relatedItems) {
        const pIndex = payload.labelsInfo.findIndex(
          (p) =>
            !p.buildPlanItemId ||
            (p.buildPlanItemId === relatedItem.buildPlanItemId &&
              ((!p.componentId && !p.geometryId) ||
                (p.componentId === relatedItem.componentId && p.geometryId === relatedItem.geometryId))),
        )
        if (pIndex !== -1) {
          insight.details.relatedItems = insight.details.relatedItems.filter((item: LabelInsightRelatedItem) => {
            const relatesToRemovedITem =
              item.buildPlanItemId === payload.labelsInfo[pIndex].buildPlanItemId &&
              item.componentId === payload.labelsInfo[pIndex].componentId &&
              item.geometryId === payload.labelsInfo[pIndex].geometryId
            return !relatesToRemovedITem
          })
        }
      }
      const allSetsExist = insight.details.relatedItems
        .map((ri: LabelInsightRelatedItem) => ri.labelSetId)
        .every((lsId) => {
          return labelSets.map((ls: InteractiveLabelSet) => ls.id).includes(lsId)
        })
      if (insight.id && insight.details.relatedItems.length && allSetsExist) {
        insightsToUpdate.push(insight)
      } else {
        insightsToRemove.push(insight)
        if (insight.id) {
          idsToRemove.push(insight.id)
        }
      }
      if (!insight.id && allSetsExist) {
        insightsToCreate.push(insight)
      }
    }

    if (insightsToUpdate.length) {
      await dispatch(
        'buildPlans/updateInsightMultiple',
        { insights: insightsToUpdate, stateOnly: payload.stateOnly },
        { root: true },
      )
    }

    if (idsToRemove.length || insightsToRemove.length) {
      await dispatch(
        'buildPlans/deleteInsightMultiple',
        {
          insightsIds: idsToRemove,
          insights: insightsToRemove,
          stateOnly: payload.stateOnly,
        },
        { root: true },
      )
    }

    if (insightsToCreate.length) {
      await dispatch(
        'buildPlans/createInsightMultiple',
        {
          insights: insightsToCreate,
          stateOnly: payload.stateOnly,
        },
        { root: true },
      )
    }
  },

  async removeLabelsFromCanvas(
    { commit, rootGetters },
    payload?: Array<{
      labelSetId?: string
      buildPlanItemId?: string
      componentId?: string
      geometryId?: string
      labelId?: string
      trackId?: string
    }>,
  ) {
    const labelInfos = await rootGetters['visualizationModule/visualization'].removeLabels(payload)
    labelInfos.forEach((labelSetsInfo) => {
      commit('removeAutoPlacements', labelSetsInfo)
    })
  },

  async removeManuallyPlacedLabel(
    { commit, rootGetters, dispatch, getters },
    payload: { labelSetId: string; labelId: string },
  ) {
    if (!payload.labelSetId || !payload.labelId) {
      return
    }

    // Store old placemt in order to have body information for future check
    const manualPlacement = (getters.activeLabelSet.manualPlacements as Placement[]).find(
      (placement) => placement.id === payload.labelId,
    )
    const labelSet: InteractiveLabelSet = getters.labelSets.find((ls) => ls.id === payload.labelSetId)
    const manualTrackabelLabel = (labelSet.labels as ManualTrackableLabel[]).find(
      (l) => l.manualPlacementId === payload.labelId,
    )

    commit('removeManualPlacementsForLabelSet', { labelSetId: payload.labelSetId, labelId: payload.labelId })

    const idsToRemove = []
    const insightsToUpdate = []
    const insightsToRemove = []
    const labelSetInsights = rootGetters['buildPlans/labelInsights'].filter(
      (insight) =>
        insight.details.relatedItems &&
        insight.details.relatedItems.length &&
        payload.labelSetId === insight.details.relatedItems[0].labelSetId,
    )
    for (const insight of labelSetInsights) {
      for (const relatedItem of insight.details.relatedItems) {
        if (relatedItem.labelId && relatedItem.labelId === manualTrackabelLabel.id) {
          const index = insight.details.relatedItems.findIndex((item) => item.labelId === manualTrackabelLabel.id)
          insight.details.relatedItems.splice(index, 1)
          if (insight.details.relatedItems.length && insight.id) {
            insightsToUpdate.push(insight)
          } else {
            insightsToRemove.push(insight)
            if (insight.id) {
              idsToRemove.push(insight.id)
            }
          }
        }
      }
    }

    if (insightsToUpdate.length) {
      await dispatch(
        'buildPlans/updateInsightMultiple',
        { insights: insightsToUpdate, stateOnly: false },
        { root: true },
      )
    }

    if (idsToRemove.length || insightsToRemove.length) {
      await dispatch('buildPlans/deleteInsightMultiple', { insightsIds: idsToRemove, insights: insightsToRemove, stateOnly: false }, { root: true })
    }

    if (manualTrackabelLabel.errorCode) {
      // If label with error it means that it is not generated and we don't need to send command for remove
      await dispatch('removeLabelOrigins', [manualTrackabelLabel.manualPlacementId])
      commit('removeTrackableLabels', [manualTrackabelLabel.id])
    } else {
      // Update trackable label, set dirtyState = Remove
      dispatch('makeTrackableLabelsDirty', [
        {
          labelSetId: payload.labelSetId,
          id: manualTrackabelLabel.id,
          dirtyState: LabelDirtyState.Remove,
        },
      ])
    }

    // check for related by counter labelSets and mark it as one that need to be updated
    const patchWithLabledBodyExist = (getters.activeLabelSet.manualPlacements as Placement[]).some(
      (placement) =>
        placement.id !== manualTrackabelLabel.id &&
        placement.buildPlanItemId === manualPlacement.buildPlanItemId &&
        placement.componentId === manualPlacement.componentId &&
        placement.geometryId === manualPlacement.geometryId,
    )
    if (getters.isLabelSetContainsCounter(getters.activeLabelSet.id) && !patchWithLabledBodyExist) {
      getters
        .getActiveWithRelatedLabelSets()
        .forEach((labelSet) =>
          dispatch('makeAllTrackableLabelsDirtyByLabelSetId', { id: labelSet.id, dirtyState: LabelDirtyState.Update }),
        )
    }
  },

  async removeLabelOrigins({ rootGetters }, labelIds: string[]) {
    rootGetters['visualizationModule/visualization'].removeLabelOrigins(labelIds)
  },

  async restoreLabelSetsFromCache({ commit, getters, rootGetters, dispatch }, labelSetIds: string[]) {
    commit('restoreLabelSetsFromCache', labelSetIds)
    const idsToRemove = rootGetters['buildPlans/labelInsights']
      .filter((insight) => labelSetIds.includes(insight.details.relatedItems[0].labelSetId))
      .map((insight) => insight.id)

    if (idsToRemove.length) {
      await dispatch('buildPlans/deleteInsightMultiple', { insightsIds: idsToRemove, stateOnly: false }, { root: true })
    }

    const insightsToRestore = getters.getCachedLabelInsights.filter((insight) =>
      labelSetIds.includes(insight.details.relatedItems[0].labelSetId),
    )

    if (insightsToRestore.length) {
      await dispatch(
        'buildPlans/createInsightMultiple',
        { insights: insightsToRestore, stateOnly: false },
        { root: true },
      )
    }
  },

  async activateReadOnly({ commit, getters, rootGetters, dispatch }) {
    commit('visualizationModule/deactivateLabelManualPlacement', null, { root: true })
    commit('buildPlans/selectItems', { selectedItems: null, attach: false }, { root: true })
    dispatch(
      'restoreLabelSetsFromCache',
      getters.getActiveWithRelatedLabelSets(true).map((ls) => ls.id),
    )

    if (getters.activeLabelSet) {
      const labelSet = getters.labelSets.find((ls) => ls.id === getters.activeLabelSet.id)
      const bodyIds = [
        ...new Set<string>(
          labelSet.selectBodies.map((patch) =>
            JSON.stringify({
              buildPlanItemId: patch.buildPlanItemId,
              componentId: patch.componentId,
              geometryId: patch.geometryId,
            }),
          ),
        ),
      ].map((ids) => JSON.parse(ids))

      commit('visualizationModule/selectBodies', { bodyIds, attach: false }, { root: true })
    }
  },

  scheduleExecuteCommand() {
    eventBus.$emit(InteractiveServiceEvents.ScheduleExecuteCommand)
  },

  addManualPlacements(
    { commit, getters, dispatch },
    payload: {
      labelSetId: string
      patches: ManualPatch[]
    },
  ) {
    const labelsToAdd = []
    const shouldAbort = getters.isLabelSetHasLabelWithCommandId(getters.activeLabelSet.id)
    const placementsWithTransformation: Placement[] = payload.patches.map((patch: ManualPatch) => {
      labelsToAdd.push(createManualTrackableLabel(patch.id, LabelDirtyState.Add))
      return createPlacement(
        patch.buildPlanItemId,
        patch.componentId,
        patch.geometryId,
        patch.orientation,
        patch.rotationAngle,
        patch.id,
      )
    })
    commit('addManualPlacements', { placementsWithTransformation, labelSetId: payload.labelSetId })

    // check for related by counter labelSets and mark it as one that need to be updated
    if (getters.isLabelSetContainsCounter(getters.activeLabelSet.id)) {
      getters
        .getActiveWithRelatedLabelSets()
        .forEach((labelSet: InteractiveLabelSet) =>
          dispatch('makeAllTrackableLabelsDirtyByLabelSetId', { id: labelSet.id, dirtyState: LabelDirtyState.Update }),
        )
    }

    // add new trackable labels
    commit('addTrackableLabels', labelsToAdd)
    dispatch('scheduleExecuteCommandAbortable', shouldAbort)
  },

  setLabelManualPlacements({ commit, rootGetters }, labelSets: InteractiveLabelSet[]) {
    labelSets.forEach((ls: InteractiveLabelSet) => {
      if (!ls.settings.placementMethodAutomatic) {
        const manualPlacements: Placement[] = ls.patches.map((patch: Patch) => {
          return createPlacement(
            patch.buildPlanItemId,
            patch.componentId,
            patch.geometryId,
            patch.orientation,
            patch.rotationAngle,
            patch.id,
          )
        })
        commit('setLabelManualPlacements', { manualPlacements, labelSet: ls })
      }
    })
  },

  updateManualPlacements(
    { commit, dispatch, rootGetters, getters },
    payload: {
      labelSetId: string
      patches: ManualPatch[]
      singleLabelUpdate?: boolean
    },
  ) {
    // Mark labels to update
    const labelsToMarkAsDirty = []
    const bpItems = rootGetters['buildPlans/getAllBuildPlanItems']
    const labelSet: InteractiveLabelSet = JSON.parse(
      JSON.stringify(getters.labelSets.find((ls) => ls.id === payload.labelSetId)),
    )
    payload.patches.forEach((patch: ManualPatch) => {
      const placement: Placement = labelSet.manualPlacements.find((p: Placement) => p.id === patch.id)
      const placementIndex: number = labelSet.manualPlacements.findIndex((p: Placement) => p.id === patch.id)

      const updatedPlacement: Placement = {
        ...placement,
        orientation: patch.orientation,
        buildPlanItemId: patch.buildPlanItemId,
        componentId: patch.componentId,
        geometryId: patch.geometryId,
        rotationAngle: patch.rotationAngle,
        index: patch.index,
      }
      labelSet.manualPlacements.splice(placementIndex, 1, updatedPlacement)
      const manualTrackabelLabel = (getters.activeLabelSet.labels as ManualTrackableLabel[]).find(
        (l) => l.manualPlacementId === updatedPlacement.id,
      )
      if (manualTrackabelLabel) {
        labelsToMarkAsDirty.push({
          labelSetId: getters.activeLabelSet.id,
          id: manualTrackabelLabel.id,
          dirtyState: LabelDirtyState.Update,
        })
      }
    })
    commit('setLabelManualPlacements', { labelSet, manualPlacements: labelSet.manualPlacements })
    // Update trackable label, set dirtyState = Update
    dispatch('makeTrackableLabelsDirty', labelsToMarkAsDirty)
    if (getters.isLabelSetContainsCounter(labelSet.id) && !payload.singleLabelUpdate) {
      getters
        .getActiveWithRelatedLabelSets()
        .forEach((ls) =>
          dispatch('makeAllTrackableLabelsDirtyByLabelSetId', { id: ls.id, dirtyState: LabelDirtyState.Update }),
        )
    }
  },

  setActiveLabelSetSettingsProp({ state, dispatch, commit, getters }, payload: { propName: string; value: any }) {
    const activeLabelSet: InteractiveLabelSet = getters.activeLabelSet
    const dirtyState = payload.propName === 'placementAutoLocations' ? LabelDirtyState.Remove : LabelDirtyState.Update

    commit('setActiveLabelSetSettingsProp', payload)
    dispatch('makeAllTrackableLabelsDirtyByLabelSetId', { dirtyState, id: activeLabelSet.id })
    if (dirtyState === LabelDirtyState.Remove) {
      commit('clearActiveLabelSetPatches')
    }
  },

  addNewTextElement({ dispatch, commit, getters }, element: TextElement) {
    commit('addNewTextElement', element)
    const activeWithRelatedLabelSets = getters.getActiveWithRelatedLabelSets()
    const activeLabelSet = getters.activeLabelSet
    const activeLabelSetHasAtLeastOneBody = activeLabelSet
      ? activeLabelSet.selectedBodies.length || activeLabelSet.relatedBodies.lenght
      : false
    if (activeLabelSetHasAtLeastOneBody) {
      activeWithRelatedLabelSets.forEach((labelSet) =>
        dispatch('makeAllTrackableLabelsDirtyByLabelSetId', { id: labelSet.id, dirtyState: LabelDirtyState.Update }),
      )
    }
  },

  removeDynamicElement({ dispatch, commit, getters }, id: number | string) {
    commit('removeDynamicElement', id)
    const activeWithRelatedLabelSets = getters.getActiveWithRelatedLabelSets()
    const activeLabelSet = getters.activeLabelSet
    if (!activeLabelSet) {
      return
    }

    const activeLabelSetHasAtLeastOneBody = activeLabelSet.selectedBodies.length || activeLabelSet.relatedBodies.length
    if (activeLabelSetHasAtLeastOneBody) {
      activeWithRelatedLabelSets.forEach((labelSet) =>
        dispatch('makeAllTrackableLabelsDirtyByLabelSetId', { id: labelSet.id, dirtyState: LabelDirtyState.Update }),
      )
    }
  },

  updateDynamicElement({ dispatch, commit, getters, state }, element: TextElement) {
    // Get a list of all dynamic elements with updated element that replaced it's older version
    const list = state.listOfTextElements.map((el: TextElement) => {
      if (el.elementIDNumber === element.elementIDNumber) {
        return element
      }

      return el
    })
    // Get a list of dynamic elements within the settings if a label set with updated element that replaced
    // it's older version
    const settingElementsList = state.activeLabelSet.settings.textElements.map((el: TextElement) => {
      if (el.elementIDNumber === element.elementIDNumber) {
        return element
      }

      return el
    })
    const listOfDependentSets = []
    // Get a list of dependent label sets indices and updated list of dynamic elements that are affected by a
    // dynamic element change
    state.labelSets.forEach((labelSet: InteractiveLabelSet, index) => {
      if (labelSet.id === state.activeLabelSet.id) {
        return
      }

      const hasElement = labelSet.settings.textElements.some(
        (te: TextElement) => te.elementIDNumber === element.elementIDNumber,
      )
      if (hasElement) {
        const updatedElements = labelSet.settings.textElements.map((te: TextElement) => {
          if (te.elementIDNumber === element.elementIDNumber) {
            return element
          }

          return te
        })
        listOfDependentSets.push({ index, updatedElements })
      }
    })

    const shouldUpdateDependentSets = !!listOfDependentSets.length
    const shouldUpdateListOfTextElements = JSON.stringify(state.listOfTextElements) !== JSON.stringify(list)
    const shouldUpdateActiveLabelSetSettings =
      JSON.stringify(state.activeLabelSet.settings.textElements) !== JSON.stringify(settingElementsList)

    // Update dependent labelSets if needed
    if (shouldUpdateDependentSets) {
      commit('updateDependentLabelSetsDynamicFields', listOfDependentSets)
    }

    commit('setActiveTextElement', null)

    // Update full list of text elements if needed
    if (shouldUpdateListOfTextElements) {
      commit('updateListOfTextElements', list)
    }

    // Update active label set settings with a new dynamic element if needed
    // If update of active label set is needed it means that corresponding label set in label list should be
    // updated as well
    if (shouldUpdateActiveLabelSetSettings) {
      commit('updateActiveLabelSetTextElements', settingElementsList)
      const index = state.labelSets.findIndex((ls: InteractiveLabelSet) => ls.id === state.activeLabelSet.id)
      commit('setLabelSetByIndex', { index, labelSet: state.activeLabelSet })
    }

    commit('setLastUpdatedLabelSetId', state.activeLabelSet.id)
    const textElement = getTextElementByType(element.type)
    // Update the map of last updated dynamic text element
    if (textElement) {
      commit('setLastUpdatedDynamicTextElement', { textElement, id: element.elementIDNumber })
    }

    // If there were updates to active label set or dependent label sets - mark label as dirty
    if (shouldUpdateActiveLabelSetSettings) {
      if (shouldUpdateDependentSets) {
        getters.getActiveWithRelatedLabelSets(true).forEach((labelSet) =>
          dispatch('makeAllTrackableLabelsDirtyByLabelSetId', {
            id: labelSet.id,
            dirtyState: LabelDirtyState.Update,
          }),
        )
      } else {
        dispatch('makeAllTrackableLabelsDirtyByLabelSetId', {
          id: state.activeLabelSet.id,
          dirtyState: LabelDirtyState.Update,
        })
      }
    }
  },

  updateCounterDynamicElement({ dispatch, commit, getters }, element: TextElement) {
    dispatch('updateDynamicElement', element)
    getters
      .getActiveWithRelatedLabelSets()
      .forEach((labelSet) =>
        dispatch('makeAllTrackableLabelsDirtyByLabelSetId', { id: labelSet.id, dirtyState: LabelDirtyState.Update }),
      )
  },

  updateLabelText(
    { dispatch, commit, getters },
    payload: {
      labelSetId: string
      text: string
      elementToAdd?: TextElement
      elementToRemove?: TextElement
    },
  ) {
    commit('updateLabelText', payload)
    const activeLabelSet: InteractiveLabelSet = getters.activeLabelSet
    if (
      (getters.isLabelSetContainsCounter(activeLabelSet.id) || getters.getForceRecalculateRelatedForIds.length) &&
      (activeLabelSet.manualPlacements.length > 0 || activeLabelSet.selectedBodies.length > 0)
    ) {
      // If active label set contains counter dynamic element we should search through all related label sets and
      // mark their trackable labels as dirtyState = update on each text string update, which can include changes to
      // counter dynamic element
      getters.getActiveWithRelatedLabelSets().forEach((labelSet) => {
        let dirtyState = LabelDirtyState.Update
        if (labelSet.id === activeLabelSet.id && payload.text.length === 0) {
          dirtyState = LabelDirtyState.Remove
          if (!activeLabelSet.settings.placementMethodAutomatic && activeLabelSet.manualPlacements.length) {
            // invalidate labels on the scene 
            if (labelSet) {
              const labelsToInvalidate = labelSet.labels.map(label => ({ labelSetId: labelSet.id, id: label.id, dirtyState: LabelDirtyState.None }))
              dispatch('invalidateLabels', labelsToInvalidate)
            }

            return
          }
        }

        dispatch('makeAllTrackableLabelsDirtyByLabelSetId', { dirtyState, id: labelSet.id })
      })
    } else {

      if (!activeLabelSet.settings.placementMethodAutomatic &&
        activeLabelSet.manualPlacements.length
        && !payload.text.length) {
        // invalidate labels on the scene 
        const labelsToInvalidate =
          activeLabelSet.labels.map(label =>
            ({ labelSetId: activeLabelSet.id, id: label.id, dirtyState: LabelDirtyState.None })
          )
        dispatch('invalidateLabels', labelsToInvalidate)


        return
      }

      // Otherwise just update trackable labels of an active label set
      dispatch('makeAllTrackableLabelsDirtyByLabelSetId', {
        id: activeLabelSet.id,
        dirtyState: payload.text.length ? LabelDirtyState.Update : LabelDirtyState.Remove,
      })
    }
  },

  addSelectedBodies({ dispatch, commit, getters }, payload: LabeledBodyWIthTransformation[]) {
    const shouldAbort = getters.isLabelSetHasLabelWithCommandId(getters.activeLabelSet.id)
    // If there are no active label set or there is an operations that does not require setting labels into a dirty
    // state - we should skip setting them
    if (!getters.activeLabelSet || getters.getIgnoreChangesByDirtyState) {
      return
    }

    const candidatesToAdd = [...payload]
    const activeLabelSet: InteractiveLabelSet = getters.activeLabelSet
    // If labelAllInstances is enabled we should add items to related bodies too
    if (activeLabelSet.settings.hasLabeledInstances) {
      const selectedBodiesGeometryIds = [...new Set(payload.map((b) => b.geometryId))]
      const selectedBodiesComponentIds = [...new Set(payload.map((b) => b.componentId))]
      const containsRelatedToNewBodies = activeLabelSet.relatedBodies.some((b) => {
        return selectedBodiesGeometryIds.includes(b.geometryId) && selectedBodiesComponentIds.includes(b.componentId)
      })
      const shouldLabelAllInstances =
        activeLabelSet.settings.placementMethodAutomatic &&
        (!activeLabelSet.relatedBodies.length || (activeLabelSet.relatedBodies.length && !containsRelatedToNewBodies))
      // If label set label all instances setting is set to true and there are no related bodies, it means that
      // bodies were not collected and set into a related bodies array. Label all instances process should be launched
      // manually and related bodies should be set based on a list of currently selected bodies.
      // In other case label all instances should be used if there is a list of related bodies but those bodies
      // are not the instances of a newly selected body
      if (shouldLabelAllInstances) {
        const ignoreBodies = activeLabelSet.relatedBodies.map((b: LabeledBodyWIthTransformation) => {
          return {
            buildPlanItemId: b.buildPlanItemId,
            geometryId: b.geometryId,
            componentId: b.componentId,
            id: b.id,
          }
        })
        dispatch('launchLabelAllInstances', ignoreBodies)
      }

      // Filter geometry ids in case if such geometry was added earlier
      const geometryIdsToAdd = []

      payload.forEach((labeledBody) => {
        getters.getRelatedBodiesFromActiveLabelSet.forEach((relatedBody) => {
          if (
            relatedBody.buildPlanItemId === labeledBody.buildPlanItemId &&
            relatedBody.geometryId === labeledBody.geometryId &&
            relatedBody.componentId === labeledBody.componentId
          ) {
            // If body to select is present in related bodies it means
            // that we shouldn't add trackableLabels to trackable labels list
            // and should remove such body from related list
            const index = candidatesToAdd.findIndex(
              (candidate) =>
                candidate.buildPlanItemId === labeledBody.buildPlanItemId &&
                candidate.componentId === labeledBody.componentId &&
                candidate.geometryId === labeledBody.geometryId,
            )
            candidatesToAdd.splice(index, 1)
            commit('removeRelatedBodies', [relatedBody])
            activeLabelSet.labels.forEach((l: AutomatedTrackableLabel) => {
              if (l.bodyId === relatedBody.id) {
                commit('updateAutoTrackableLabelBodyId', { trackId: l.id, bodyId: labeledBody.id })
              }
            })
          } else if (relatedBody.geometryId !== labeledBody.geometryId) {
            // If there is no such geometry in relatedBodies
            // it means that we should add such geometry to the search array
            geometryIdsToAdd.push(labeledBody.geometryId)
          }
        })
      })
    }

    // Add passed bodies to selectedBodies collector
    commit('addSelectedBodies', payload)
    // Create and add trackable labels to the labels list
    const labelsToAdd = []
    candidatesToAdd.forEach((body) => {
      labelsToAdd.push(
        ...activeLabelSet.settings.placementAutoLocations.map((location) =>
          createAutomatedTrackableLabel(body.id, location, LabelDirtyState.Add),
        ),
      )
    })

    if (getters.activeLabelSet.settings.placementMethodAutomatic) {
      // Check for related by counter labelSets and mark it as one that need to be updated in case if counter is present
      // in active label set
      if (getters.isLabelSetContainsCounter(getters.activeLabelSet.id)) {
        getters.getActiveWithRelatedLabelSets().forEach((labelSet) =>
          dispatch('makeAllTrackableLabelsDirtyByLabelSetId', {
            id: labelSet.id,
            dirtyState: LabelDirtyState.Update,
          }),
        )
      }

      // add new trackable labels
      commit('addTrackableLabels', labelsToAdd)
      dispatch('scheduleExecuteCommandAbortable', shouldAbort)
    }
  },

  removeSelectedBodies({ dispatch, commit, getters, rootGetters }, payload: ISelectable[]) {
    // If there are no active label set or there is an operations that does not require setting labels into a dirty
    // state - we should skip setting them
    if (!getters.activeLabelSet || getters.getIgnoreChangesByDirtyState) {
      return
    }

    let relatedBodies = []
    const selectedBodiesToRemove: LabeledBodyWIthTransformation[] = getters.getSelectedBodiesFromSelectables(payload)
    // Remove passed bodies from array of selected bodies
    commit('removeSelectedBodies', payload)

    // In case of active labelAllInstances toggle button we have to take into account related geometry
    if (getters.activeLabelSet.settings.hasLabeledInstances) {
      // Related bodies without main selected body should be removed from the list
      relatedBodies = getters.getRelatedBodiesFromActiveLabelSet.filter((relatedBody) =>
        getters.getSelectedBodiesFromActiveLabelSet.every(
          (selectedBody) => selectedBody.geometryId !== relatedBody.geometryId,
        ),
      )

      commit('removeRelatedBodies', relatedBodies)
    }

    if (getters.activeLabelSet.settings.placementMethodAutomatic) {
      // Mark labels as removed or remove trackable label if it does not exists on the scene
      const labelsToMarkAsDirty = []
      const labelsToRemove = []
      const activeLabelSet: InteractiveLabelSet = getters.activeLabelSet
        ; (activeLabelSet.labels as AutomatedTrackableLabel[]).forEach((automatedLabel) => {
          const labelMesh = rootGetters['visualizationModule/visualization'].getLabelMeshByTrackId(automatedLabel.id)
          // If we don't have labelMesh and this mesh has no command id we can silently remove it from the state
          // Also we should not remove labels that are queued for an execution. Only lalels with DirtyState = None
          // should be removed way.
          if (!labelMesh && !automatedLabel.commandId && automatedLabel.dirtyState === LabelDirtyState.None) {
            labelsToRemove.push(automatedLabel.id)
          } else {
            if (
              selectedBodiesToRemove.find((body) => body.id === automatedLabel.bodyId) ||
              relatedBodies.find((body) => body.id === automatedLabel.bodyId)
            ) {
              labelsToMarkAsDirty.push({
                labelSetId: activeLabelSet.id,
                id: automatedLabel.id,
                dirtyState: LabelDirtyState.Remove,
              })
            }
          }
        })

      if (labelsToMarkAsDirty.length) {
        dispatch('makeTrackableLabelsDirty', labelsToMarkAsDirty)
      }

      if (labelsToRemove.length) {
        commit('removeTrackableLabels', labelsToRemove)
      }

      // If we are removing selected bodies and label all instances is enabled - we should check removed bodies
      // if they are instances of selected ones and should be instanced with labels
      if (activeLabelSet.selectedBodies.length > 0 && activeLabelSet.settings.hasLabeledInstances) {
        // Ignored labeled bodies should be bodies that are instances of selected bodies. Other bodies
        // that corresponds to the removed selected bodies should be removed as well
        const selectedBodiesGeometryIds = [...new Set(activeLabelSet.selectedBodies.map((b) => b.geometryId))]
        const selectedBodiesComponentIds = [...new Set(activeLabelSet.selectedBodies.map((b) => b.componentId))]
        const ignoreBodies = activeLabelSet.relatedBodies
          .filter((b: LabeledBodyWIthTransformation) => {
            return (
              selectedBodiesGeometryIds.includes(b.geometryId) && selectedBodiesComponentIds.includes(b.componentId)
            )
          })
          .map((b: LabeledBodyWIthTransformation) => {
            return {
              buildPlanItemId: b.buildPlanItemId,
              geometryId: b.geometryId,
              componentId: b.componentId,
              id: b.id,
            }
          })
        dispatch('launchLabelAllInstances', ignoreBodies)
      }

      // check for related by counter labelSets and mark it as one that need to be updated
      if (getters.isLabelSetContainsCounter(getters.activeLabelSet.id)) {
        getters.getActiveWithRelatedLabelSets().forEach((labelSet) =>
          dispatch('makeAllTrackableLabelsDirtyByLabelSetId', {
            id: labelSet.id,
            dirtyState: LabelDirtyState.Update,
          }),
        )
      }
    }
  },

  makeTrackableLabelsDirty(
    { dispatch, commit, getters, rootGetters },
    payload: Array<{
      labelSetId: string
      id: string
      dirtyState: LabelDirtyState
      force?: boolean
    }>,
  ) {
    const shouldAbort = getters.isLabelsHasCommandId(
      payload.map((labelData) => ({ labelSetId: labelData.labelSetId, id: labelData.id })),
    )
    commit('makeTrackableLabelsDirty', payload)
    dispatch('invalidateLabels', payload)
    dispatch('scheduleExecuteCommandAbortable', shouldAbort)
  },

  makeTrackableLabelDirty(
    { dispatch, commit, getters, rootGetters },
    payload: {
      labelSetId: string
      id: string
      dirtyState: LabelDirtyState
    },
  ) {
    const shouldAbort = getters.isLabelHasCommandId(payload.labelSetId, payload.id)
    commit('makeTrackableLabelDirty', payload)
    dispatch('invalidateLabels', [payload])
    dispatch('scheduleExecuteCommandAbortable', shouldAbort)
  },

  makeAllTrackableLabelsDirtyByLabelSetId(
    { state, dispatch, commit, getters, rootGetters },
    payload: { id: string; dirtyState: LabelDirtyState; doNotInvalidate?: boolean },
  ) {
    const shouldAbort = getters.isLabelSetHasLabelWithCommandId(payload.id)
    commit('makeAllTrackableLabelsDirtyByLabelSetId', payload)

    const labelsToInvalidate = []
    const labelSet = state.labelSets.find((ls) => ls.id === payload.id)
    if (labelSet) {
      for (const labelSetLabel of labelSet.labels) {
        labelsToInvalidate.push({ labelSetId: labelSet.id, id: labelSetLabel.id, dirtyState: labelSetLabel.dirtyState })
      }

      if (!payload.doNotInvalidate) {
        dispatch('invalidateLabels', labelsToInvalidate)
      }
    }

    dispatch('scheduleExecuteCommandAbortable', shouldAbort)
  },

  triggerAbort() {
    eventBus.$emit(InteractiveServiceEvents.AbortLabelAfterLabelSetChange)
  },

  scheduleExecuteCommandAbortable({ dispatch, commit, getters, rootGetters }, shouldAbort: boolean) {
    if (shouldAbort) {
      dispatch('triggerAbort')
    } else {
      dispatch('scheduleExecuteCommand')
    }
  },

  setLabelSetsIDsForUpdate(
    { dispatch, commit, getters, rootGetters },
    payload: { ids: string[]; doNotInvalidate?: boolean },
  ) {
    const allSetsToUpdate = getters.getLabelSetsIDsForUpdate
      ? [...getters.getLabelSetsIDsForUpdate, ...payload.ids]
      : payload.ids
    commit('resetLabelSetsIDsForUpdate', allSetsToUpdate)
    if (allSetsToUpdate) {
      allSetsToUpdate.forEach((labelSetId: string) => {
        dispatch('makeAllTrackableLabelsDirtyByLabelSetId', {
          id: labelSetId,
          dirtyState: LabelDirtyState.Update,
          doNotInvalidate: payload.doNotInvalidate,
        })
      })
    }
  },

  resetLabelSetsIDsForUpdate({ dispatch, commit, getters, rootGetters }, value: string[]) {
    commit('resetLabelSetsIDsForUpdate', value)
    if (value) {
      value.forEach((labelSetId: string) => {
        dispatch('makeAllTrackableLabelsDirtyByLabelSetId', { id: labelSetId, dirtyState: LabelDirtyState.Update })
      })
    }
  },

  updateLabelSetBodiesTransformation({ dispatch, commit, getters, rootGetters }, labelSetIds: string[]) {
    getters.labelSets.forEach((labelSet: InteractiveLabelSet, index) => {
      if (labelSetIds.includes(labelSet.id)) {
        labelSet.selectedBodies = labelSet.selectedBodies.map((body: LabeledBodyWIthTransformation) => {
          const bpItem: IBuildPlanItem = rootGetters['buildPlans/buildPlanItemById'](body.buildPlanItemId)
          const transformation: number[] = getBuildPlanItemTransformationWithoutScale(bpItem)
          return { ...body, transformation }
        })

        labelSet.relatedBodies = labelSet.relatedBodies.map((body: LabeledBodyWIthTransformation) => {
          const bpItem: IBuildPlanItem = rootGetters['buildPlans/buildPlanItemById'](body.buildPlanItemId)
          const transformation: number[] = getBuildPlanItemTransformationWithoutScale(bpItem)
          return { ...body, transformation }
        })
      }

      commit('setLabelSetByIndex', { labelSet, index })
    })
  },

  setRelatedBodies(
    { dispatch, commit, getters, rootGetters },
    payload: { bodies: LabeledBodyWIthTransformation[]; add?: boolean; ignored?: LabeledBodyWIthTransformation[] },
  ) {
    const activeLabelSet: InteractiveLabelSet = getters.activeLabelSet
    const bodies = payload.bodies
    // If there are bodies that should be removed - we should set dirty labels of such bodies into a remove state
    // This should be done only to automatically placed labels
    if (!bodies.length && activeLabelSet.relatedBodies.length && activeLabelSet.settings.placementMethodAutomatic) {
      const p: Array<{
        labelSetId: string
        id: string
        dirtyState: LabelDirtyState
      }> = []
      // Find trackable labels by placement view and body id in case if placement method is automatic
      const ignoredBodiesIds = payload.add ? payload.ignored.map((b) => b.id) : []
      activeLabelSet.relatedBodies
        .filter((body: LabeledBodyWIthTransformation) => {
          // If there are ignored labeled bodies, that were already labeled earlier and was not taking part
          // in a latest updated - we should exclude such bodies and leave them on a scene
          return !payload.add || (payload.add && !ignoredBodiesIds.includes(body.id))
        })
        .forEach((body: LabeledBodyWIthTransformation) => {
          activeLabelSet.settings.placementAutoLocations.forEach((placement: MarkingLocation) => {
            const trackableLabel: AutomatedTrackableLabel = (activeLabelSet.labels as AutomatedTrackableLabel[]).find(
              (l: AutomatedTrackableLabel) => {
                return l.bodyId === body.id && l.autoLocation === placement
              },
            )
            if (trackableLabel) {
              p.push({
                id: trackableLabel.id,
                labelSetId: activeLabelSet.id,
                dirtyState: LabelDirtyState.Remove,
              })
            }
          })
        })
      commit('makeTrackableLabelsDirty', p)
    }

    commit('setRelatedBodies', payload)
  },

  launchLabelAllInstances(
    { dispatch, commit, getters, rootGetters },
    ignoreBodies: Array<{ buildPlanItemId: string; geometryId: string; componentId: string }>,
  ) {
    const geometryIds = []
    const hoveredLabel = rootGetters['visualizationModule/hoveredLabel']
    const isManual = !getters.activeLabelSetSettings.placementMethodAutomatic && hoveredLabel
    const manualPlacements = getters.manualPlacementsForLabelSet(getters.activeLabelSet.id)
    const manualLabel = manualPlacements ? manualPlacements.find((mp) => mp.id === hoveredLabel) : null
    rootGetters['buildPlans/getSelectedParts'].forEach((selected) => {
      const [, , geometryId] = selected.id.split(PART_BODY_ID_DELIMITER)
      // in case of manual labeling we shouldn't take into account not hovered geometries
      if (isManual && manualLabel && manualLabel.geometryId !== geometryId) {
        return
      }

      if (!geometryIds.includes(geometryId)) {
        geometryIds.push(geometryId)
      }
    })

    const params =
      !getters.activeLabelSetSettings.placementMethodAutomatic && hoveredLabel
        ? {
          geometryIds,
          labelId: hoveredLabel,
          isAutomatic: getters.activeLabelSetSettings.placementMethodAutomatic,
        }
        : { geometryIds, ignoreBodies, isAutomatic: getters.activeLabelSetSettings.placementMethodAutomatic }
    commit('visualizationModule/selectAllInstances', params, { root: true })
  },

  changeBooleanType({ dispatch, commit, getters, rootGetters }, booleanType: BooleanType) {
    const activeLabelSet: InteractiveLabelSet = getters.activeLabelSet
    commit('changeBooleanType', booleanType)
    dispatch('makeAllTrackableLabelsDirtyByLabelSetId', { dirtyState: LabelDirtyState.Update, id: activeLabelSet.id })
  },

  clearLabelInsightsByErrorCodes(
    { commit, state },
    payload: { labelSetId: string; errorCodes: InsightErrorCodes[]; labelId?: string },
  ) {
    const cachedInsights: CachedLabelInsight[] = JSON.parse(JSON.stringify(state.cachedInsightsWhileExecution))
    const updatedInsights: CachedLabelInsight[] = []
    cachedInsights.forEach((insight) => {
      if (
        insight.labelSetId === payload.labelSetId &&
        insight.content.some((code) => payload.errorCodes.includes(+code))
      ) {
        return
      }

      updatedInsights.push(insight)
    })

    commit('setCachedInsight', updatedInsights)
  },

  async setUpdateLabelSetsOnTransformationChange(
    { dispatch, commit, getters, rootGetters },
    payload: { isMovement: boolean; affectedBpItemsIDs: string[] },
  ) {
    const labelSetIdsToUpdate = payload.isMovement
      ? getters.getLabelSetsToUpdateOnMove(payload.affectedBpItemsIDs)
      : getters.getLabelSetsToUpdateOnRotate(payload.affectedBpItemsIDs)

    // On every build plan item transformation change update selected/related bodies transformation for labels
    const labelSetIdsToUpdateTransformation = getters.labelSets
      .filter((ls: InteractiveLabelSet) => {
        const bodies = [...ls.selectedBodies, ...ls.relatedBodies]
        return bodies.some((body) => payload.affectedBpItemsIDs.includes(body.buildPlanItemId))
      })
      .map((ls: InteractiveLabelSet) => ls.id)
    dispatch('updateLabelSetBodiesTransformation', labelSetIdsToUpdateTransformation)

    if (labelSetIdsToUpdate.length) {
      const buildPlan = rootGetters['buildPlans/getBuildPlan']
      buildPlan.labelSetIDsToUpdate = labelSetIdsToUpdate
      dispatch('setLabelSetsIDsForUpdate', { ids: labelSetIdsToUpdate })
      commit('buildPlans/setRequiresLabelSetUpdates', true, { root: true })
      await dispatch('buildPlans/updateBuildPlanV1', { buildPlan }, { root: true })
    }
  },

  async addLabelOnScene(
    { rootGetters, commit },
    payload: {
      drc: ArrayBuffer
      buildPlanItemId: string
      componentId: string
      geometryId: string
      id: string
      labelSetId: string
      isFailed: boolean
      orientation: ILabelOrientation
      rotationAngle: number
      trackId: string
    },
  ) {
    const result = await rootGetters['visualizationModule/visualization'].addLabelOnScene(
      payload.id,
      payload.buildPlanItemId,
      payload.componentId,
      payload.geometryId,
      payload.labelSetId,
      payload.drc,
      payload.isFailed,
      payload.orientation,
      payload.rotationAngle,
      payload.trackId,
    )
    if (!payload.orientation && result && result.metadata) {
      // automatic placement
      const info = {
        buildPlanItemId: result.metadata.buildPlanItemId,
        componentId: result.metadata.componentId,
        geometryId: result.metadata.geometryId,
      }
      commit('addAutoPlacements', {
        labelSetId: payload.labelSetId,
        manualPlacements: [info],
      })
    }
  },

  highlightLabels(
    { rootGetters },
    payload?: Array<{
      labelSetId?: string
      parentId?: string
      componentId?: string
      geometryId?: string
      patchId?: string
      isPrintOrderPreviewLabel: boolean
    }>,
  ) {
    rootGetters['visualizationModule/visualization'].highlightLabels(payload)
  },

  deHighlightLabels(
    { rootGetters },
    payload?: Array<{
      labelSetId?: string
      parentId?: string
      componentId?: string
      geometryId?: string
      patchId?: string
      isPrintOrderPreviewLabel: boolean
    }>,
  ) {
    rootGetters['visualizationModule/visualization'].deHighlightLabels(payload)
  },

  applyRotationToAllOtherLabels({ rootGetters }, payload: { sourceLabel: ManualPatch; targetLabels: ManualPatch[] }) {
    rootGetters['visualizationModule/visualization'].applyRotationToAllOtherLabels(
      payload.sourceLabel,
      payload.targetLabels,
    )
  },

  invalidateLabels({ rootGetters }, payload: Array<{ labelSetId: string; id: string; dirtyState: LabelDirtyState }>) {
    rootGetters['visualizationModule/visualization'].invalidateLabels(payload)
  },

  markNotCreatedManualLabel({ rootGetters }, payload: string) {
    rootGetters['visualizationModule/visualization'].markNotCreatedManualLabel(payload)
  },

  onDisplayManualLabelSettings(
    { commit },
    payload: {
      isVisible: boolean
      settingsLocation: { x: number; y: number }
      disableLabelAllInstances?: boolean
      disableApplyRotationToInstances?: boolean
    },
  ) {
    commit('visualizationModule/manualLabelSettingsLocation', payload.settingsLocation, { root: true })
    commit('allowLabelAllInstances', !payload.disableLabelAllInstances)
    commit('allowApplyRotationToInstances', !payload.disableApplyRotationToInstances)
    commit('visualizationModule/displayManualLabelSettings', payload.isVisible, { root: true })
  },

  async updateLabelRelatedInsightsOnPartsRemoval({ state, commit, rootGetters, dispatch, getters }) {
    const insightsToUpdate: IBuildPlanInsight[] = []
    const insightsToRemove: string[] = []
    const bpItemsIdsToRemove = rootGetters['buildPlans/getSelectedParts'].map((item) => item.id)
    const insights = rootGetters['buildPlans/insights']

    insights
      .filter((insight: IBuildPlanInsight) => insight.tool === ToolNames.LABEL)
      .forEach((insight: IBuildPlanInsight) => {
        // filter out items that were related to removed build plan items
        const relatedItems = insight.details.relatedItems.filter((ri: LabelInsightRelatedItem) => {
          return !bpItemsIdsToRemove.includes(ri.buildPlanItemId)
        })
        // if there are no items left - remove the insight
        if (!relatedItems.length) {
          insightsToRemove.push(insight.id)
          return
        }

        // if the length of the resulting array is not equal to the starting length - update the insight
        if (insight.details.relatedItems.length !== relatedItems.length) {
          insight.details.relatedItems = relatedItems
          insightsToUpdate.push(insight)
        }
      })

    if (insightsToUpdate.length) {
      await dispatch(
        'buildPlans/updateInsightMultiple',
        { insights: insightsToUpdate, stateOnly: false },
        { root: true },
      )
    }

    if (insightsToRemove.length) {
      await dispatch(
        'buildPlans/deleteInsightMultiple',
        {
          insightsIds: insightsToRemove,
          stateOnly: false,
        },
        { root: true },
      )
    }
  },
}
