export interface ICostEstimation {
  id?: number
  projectName: string
  modelName: string
  length: number
  width: number
  height: number
  volume: number
  materialId: number
  manufacturingRegion: string
  numberOfPartsPerYear: number
  totalVolumeOnFirstLaser: number
  supportStructureRatio: number
  trappedPowderWaste: number
  userId: string
  unitsId: number
  surfaceArea: number
  imageUrl: string
  createdAt?: string
  updatedAt?: string
  costEstimationSet: ICostEstimationSet[]
  inputType: EstimationCostInputType
}

export interface ICostEstimationSet {
  machineConfigId: number
  result: CostEvaluationResults
}

export interface IResult {
  machineName: string
  machineImageUrl: string
  result: CostEvaluationResultViewModel
}

export class CostEvaluationResults {
  public machineBuildData: MachineBuildData
  public buildCostOfOperating: CostOfOperating
  public partCostOfOperating: CostOfOperating
  public perPartCosts: PerPartCosts
  public costOfOwning: CostOfOwning
  public straightLineDepreciation: StraightLineDepreciation
  public totalPartCost: NumericRange

  constructor() {
    this.machineBuildData = new MachineBuildData()
    this.buildCostOfOperating = new CostOfOperating()
    this.partCostOfOperating = new CostOfOperating()
    this.perPartCosts = new PerPartCosts()
    this.costOfOwning = new CostOfOwning()
    this.straightLineDepreciation = new StraightLineDepreciation()
  }
}

export class NumericRange {
  // tslint:disable-next-line: variable-name
  public _value = 0
  // tslint:disable-next-line: variable-name
  public _min = 0
  // tslint:disable-next-line: variable-name
  public _max = 0
}

// Machine build time related data
export class MachineBuildData {
  public machineType = ''
  public numberOfLasers = 0
  public materialUsed = ''
  public materialCost = 0
  public materialStatus = ''
  public inertGas = ''
  public preferredRecoaterMaterial = ''
  public machineOutputBuildsYear = 0
  public machineOutputUsablePartsYear = 0
  public quantityOfMachinesRequired = new NumericRange()
  public machineBuildTimeHours = 0
  public machineBuildTimeOverride = 0
  public machineBuildTimeDays = 0
  public footprintOfPrinters = 0
  public partsPerBuild = 0
  public partsHIPOtherOven = 0
  public totalLeadTimeHours = 0
  public totalLeadTimeDays = 0
  public totalBuildRate = 0
  public complexityRatio = 0
}

export class CostOfOperating {
  public buildMaterialCost = 0
  public solidMaterialWaste = 0
  public powderMaterialWaste = new NumericRange()
  public costOfPowderReuse = new NumericRange()
  public platform = new NumericRange()
  public recoater = 0
  public filters = new NumericRange()
  public inertGas = 0
  public electricity = 0
  public laserMaintenance = new NumericRange()
  public msaAllocation = new NumericRange()
  public rawCostOfOperating = new NumericRange()
  public stressRelief = 0
  public totalCostOfOperating = new NumericRange()
}

// Per-Part (only) Cost Estimates
export class PerPartCosts {
  public hotIsostaticPressurization = 0
  public otherHeatTreatments = 0
  public otherFinishingCosts = 0
  public finalCostOfOperatingPerPart = new NumericRange()
}

export class OperatingLaborHours {
  public buildSetupChangeover = 0
  public powderRemoval = 0
  public platformRemoval = 0
  public supportRemoval = 0
  public inspection = 0
  public adminPackagingShipping = 0
  public stressRelief = 0
  public hotIsostaticPressurization = 0
  public otherHeatTreatments = 0
}

export class CostOfOwning {
  public costOfDMLMMachines = 0
  public peripheries = 0
  public shippingCustomsInsurance = 0
  public totalCostOfOwning = 0
}

export class StraightLineDepreciation {
  public salvageValue = 0
  public annualDepreciation = 0
  public depExpensePerPart = new NumericRange()
}

export class CostEvaluationResultViewModel {
  // Production Information
  quantityOfMachinesRequired = 0
  machineBuildTime = 0
  machineBuildTimeMin = 0
  machineBuildTimeMax = 0
  partsPerBuild = 0 // now only DMLM

  // Print Process Cost
  complexMaterialCostMin = 0
  complexMaterialCostMax = 0
  complexMaterialCostAverage = 0
  consumablesMin = 0
  consumablesMax = 0
  consumablesAverage = 0
  maintenance = 0
  maintenanceMin = 0
  maintenanceMax = 0
  maintenanceAverage = 0
  printerMin = 0
  printerMax = 0
  printerAverage = 0
  totalPrintProcessCostMin = 0
  totalPrintProcessCostMax = 0
  totalPrintProcessCostAverage = 0

  // Build Costs
  totalBuildCostMin = 0
  totalBuildCostMax = 0
}

export enum EstimationCostInputType {
  Manual = 'manual',
  Upload = 'upload',
}
