
import Vue from 'vue'
import Component from 'vue-class-component'
import { IBuildPlan, IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import Button from '@/components/controls/Common/Button.vue'
import { ILabel } from '@/types/Marking/ILabel'
import { DynamicElementSettingsMixin } from '@/components/layout/buildPlans/marking/mixins/DynamicElementSettingsMixin'
import { Mixins } from 'vue-property-decorator'
import { TextElement, UserEntryType } from '@/types/Label/TextElement'
import {
  CharacterLengthControl,
  LabelDirtyState,
  LabelSetMode,
  MarkingContentElementType,
  MarkingLocation,
  TextAlign,
  TextAlignVertical,
} from '@/types/Label/enums'
import { DEFAULT_PROMPT_TEXT } from '@/components/layout/buildPlans/marking/elementSettings/UserEntrySettings.vue'
import { v4 as uuidv4 } from 'uuid'
import { LabelExecuteCommandSettings } from '@/types/InteractiveService/LabelExecuteCommandParameter'
import { LabelToLabelSet } from '@/types/Label/LabelToLabelSet'
import {
  createExecuteProcessComplete,
  createSaveProcessComplete,
  executeProcessComplete,
  isSaved,
  LabelServiceMixin,
} from '@/components/layout/buildPlans/marking/mixins/LabelServiceMixin'
import { ManualPatch, Patch } from '@/types/Label/Patch'
import { DEFAULT_LABEL_SET_SETTING } from '@/constants'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { InteractiveLabelSetNamedList } from '@/types/Label/InteractiveLabelSetNamedList'
import { createManualTrackableLabel } from '@/types/Label/TrackableLabel'
import { createPlacement } from '@/types/Label/Placement'
import {
  createLabeledBodyWithTransformation,
  LabeledBodyWIthTransformation,
} from '@/types/Label/LabeledBodyWIthTransformation'
import { getBuildPlanItemTransformationWithoutScale } from '@/utils/label/labelUtils'

const labelStore = namespace(StoresNamespaces.Labels)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

interface IMixinInterface extends Vue, DynamicElementSettingsMixin, LabelServiceMixin {}

@Component({ components: { Button } })
export default class LabelConversionDialog extends Mixins<IMixinInterface>(
  Vue,
  DynamicElementSettingsMixin,
  LabelServiceMixin,
) {
  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter getAllBuildPlanItems: IBuildPlanItem[]

  @buildPlansStore.Action refreshLabelInsightsOnScene: () => void
  @buildPlansStore.Action removeLegacyLabels: (buildPlanId: string) => void

  @buildPlansStore.Mutation updateBuildPlanItems: (
    buildPlanItemsDtos: IBuildPlanItem[],
    restoreSupportFiles?: boolean,
  ) => void

  @labelStore.Mutation addLabelSet: (labelSet: InteractiveLabelSet) => void
  @labelStore.Mutation clearLabelSetsSnapshot: (force?: boolean) => void
  @labelStore.Mutation setLabelSetPatches: (payload: { labelSetId: string; patches: Patch[] }) => void

  @labelStore.Action addManualPlacements: (payload: { labelSetId: string; patches: ManualPatch[] }) => void

  @labelStore.Action addNewTextElement: (element: TextElement) => void

  @visualizationStore.Mutation deactivateLabelInteraction: (labelId: string) => void
  @visualizationStore.Mutation deleteRenderedLabel: (labelId: string) => void

  @labelStore.Getter namedList: (additionalLabelSets?: InteractiveLabelSet[]) => InteractiveLabelSetNamedList[]
  @labelStore.Getter getSaveComplete: boolean

  visible: boolean = false
  hasLegacyData: boolean = false
  params: LabelExecuteCommandSettings[] = []

  mounted() {
    this.visible = this.buildPlan.buildPlanItems.some((bpItem: IBuildPlanItem) => bpItem.labels && bpItem.labels.length)
    this.hasLegacyData = this.visible
    if (this.hasLegacyData) {
      this.convertFromLegacy()
    }
  }

  async convertFromLegacy() {
    const labelsToDelete: LabelToLabelSet[] = []

    // Find all build plan items with legacy labels on them
    const bpItemsWithLabels: IBuildPlanItem[] = this.buildPlan.buildPlanItems.filter(
      (bpItem: IBuildPlanItem) => bpItem.labels && bpItem.labels.length,
    )
    if (bpItemsWithLabels.length) {
      this.clearLabelSetsSnapshot(true)
      // Iterate through each build plan item and each legacy label on it
      bpItemsWithLabels.forEach((bpItem: IBuildPlanItem) => {
        bpItem.labels.forEach((label: ILabel) => {
          const textElements = [this.createUserEntry(label)]
          const font = label.style.fontFamily
          const size = label.style.fontSize
          const margin = 0.5
          const textContent = '{1}'
          const { componentId, geometryId } = label
          const transformation = getBuildPlanItemTransformationWithoutScale(bpItem)
          const patch = this.generatePatch(bpItem.id, label)
          const labels = [createManualTrackableLabel(patch.id, LabelDirtyState.Add)]
          const manualPlacements = [
            createPlacement(
              patch.buildPlanItemId,
              patch.componentId,
              patch.geometryId,
              patch.orientation,
              patch.rotationAngle,
              patch.id,
            ),
          ]
          const selectedBodies: LabeledBodyWIthTransformation[] = [
            createLabeledBodyWithTransformation(bpItem.id, componentId, geometryId, bpItem.part.id, transformation),
          ]

          // Create interactive label set object
          const labelSet: InteractiveLabelSet = {
            labels,
            manualPlacements,
            selectedBodies,
            id: uuidv4(),
            buildPlanId: this.buildPlan.id,
            settings: {
              ...JSON.parse(JSON.stringify(DEFAULT_LABEL_SET_SETTING)),
              textContent,
              textElements,
              index: this.namedList().length,
              uniqueID: 0,
              placementMethodAutomatic: false,
              allowFontSizeVariation: false,
              placementAutoLocations: [MarkingLocation.NearestToPoint],
              fontName: font,
              fontTargetSize: size,
              fontMinSize: size,
              textMarginSize: margin,
              textHorizontalAlignment: TextAlign.Center,
              textVerticalAlignment: TextAlignVertical.Center,
              placementMinCount: 1,
              textExtrusionBelowSurface: this.defaultAttachmentDepthByParameterId,
              labelSetName: `Manual ${this.namedList().length + 1}`,
            },
            patches: [patch],
            relatedBodies: [],
            orderIndex: this.namedList().length,
            mode: LabelSetMode.Manual,
          }
          this.deactivateLabelInteraction(label.id)
          labelsToDelete.push({
            label,
            labelSet,
            buildPlanItemId: bpItem.id,
          })
          this.addLabelSet(labelSet)
        })
      })

      // Delete legacy labels from data base and from scene
      if (labelsToDelete) {
        await this.removeLegacyLabels(this.buildPlan.id)

        labelsToDelete.forEach((labelData) => {
          this.deleteRenderedLabel(labelData.label.id)
        })

        this.refreshLabelInsightsOnScene()
        this.setEmptyLabelSetsSnapshot()
      }
    }

    // Start the execute queue with new label sets
    this.execute()

    // Create execute and save promises and await them to be resolved
    createSaveProcessComplete()
    createExecuteProcessComplete()
    await executeProcessComplete
    this.getJsonPatches()
    await isSaved

    await this.saveLabelSets(true)
    this.createLabelSetsSnapshot()
    this.cacheLabelInsights([...this.labelInsights])
    this.hasLegacyData = false
  }

  createUserEntry(label: ILabel): TextElement {
    const id = this.getNextFreeId()
    const nameId = this.getNextFreeNameIdForType(MarkingContentElementType.User_Entry)
    const element = {
      title: `User Entry ${nameId}`,
      elementIDNumber: id,
      type: MarkingContentElementType.User_Entry,
      isStaticValue: true,
      lengthControl: CharacterLengthControl.Maximum,
      mandatory: true,
      lengthMin: 0,
      lengthMax: label.style.text.length,
      runTimeIndex: null,
      _cachedSpecificsJSON: JSON.stringify(this.generateSpecificJson(label)),
    }
    this.addNewTextElement(element)
    return element
  }

  generateSpecificJson(label: ILabel) {
    return {
      entryType: UserEntryType.AlphaNumeric,
      selectedInputType: CharacterLengthControl.Maximum,
      minRange: 0,
      maxRange: label.style.text.length,
      min: 0,
      max: label.style.text.length,
      exactly: label.style.text.length,
      promptText: DEFAULT_PROMPT_TEXT,
    }
  }

  onCloseClick() {
    this.visible = false
  }

  generatePatch(buildPlanItemId: string, label: ILabel): Patch {
    const bpItem = this.buildPlanItemById(buildPlanItemId)
    const { componentId, geometryId, orientation } = label

    return {
      orientation,
      componentId,
      geometryId,
      buildPlanItemId: bpItem.id,
      id: uuidv4(),
    } as Patch
  }
}
