
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import TenantAdminPrintStrategyList from '@/components/layout/admin/sites/TenantAdminPrintStrategyList.vue'
import TenantAdminBuildPlatformList from '@/components/layout/admin/sites/TenantAdminBuildPlatformList.vue'
import TenantAdminStressReliefProfile from '@/components/layout/admin/sites/TenantAdminStressReliefProfile.vue'
import {
  Currency,
  EditViewMode,
  MachineMaterial,
  MachineTypeDto,
  MachineTypeResponse,
  Measurement,
  PrintStrategy,
} from '@/types/Sites/Site'
import { SiteAdminMixin } from '@/components/layout/admin/sites/mixins/SiteAdminMixin'
import SiteConstantUpdater from '@/components/layout/admin/sites/SiteConstantUpdater.vue'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { UpdateAdminMaterialDTO } from '@/types/Sites/SiteAdminDTOs'
import { TenantAdminBuildPlatform } from '@/types/Sites/TenantAdminBuildPlatform'
import { extend } from 'vee-validate'
import TenantAdminBinder from '@/components/layout/admin/sites/TenantAdminBinder.vue'

const DEFAULT_TAB = 'printStrategies'
const sitesStore = namespace(StoresNamespaces.Sites)

@Component({
  components: {
    TenantAdminPrintStrategyList,
    TenantAdminBuildPlatformList,
    TenantAdminStressReliefProfile,
    SiteConstantUpdater,
    TenantAdminBinder,
  },
})
export default class TenantAdminEditMaterial extends SiteAdminMixin {
  @sitesStore.Action updateMaterial: (payload: {
    materialId: string
    dto: UpdateAdminMaterialDTO
  }) => Promise<MachineMaterial>
  @sitesStore.Action fetchPrintStrategiesBySiteMachineMaterial: (payload: {
    siteId: string
    machineConfigId: string
    materialId: string
  }) => Promise<PrintStrategy[]>
  @sitesStore.Action fetchBuildPlatesByMaterialAndMachine: (payload: {
    machineConfigId: number
    materialId: number
  }) => Promise<TenantAdminBuildPlatform[]>
  @sitesStore.Action fetchMachineTypeById: (machineTypeId: string) => Promise<MachineTypeResponse>

  @sitesStore.Mutation setSelectedMaterial: (material: MachineMaterial) => void
  @sitesStore.Mutation setPrintStrategies: (printStrategies: PrintStrategy[]) => void
  @sitesStore.Mutation clearSelectedMaterial: () => void

  @sitesStore.Getter getSelectedMaterial: MachineMaterial
  @sitesStore.Getter getSelectedMachineType: MachineTypeDto
  @sitesStore.Getter isBreadcrumbsReady: boolean

  activeTab: string = DEFAULT_TAB
  currencyUnits = Currency
  isReady = false
  measurementUnits = Measurement
  platformList: TenantAdminBuildPlatform[] = []

  @Watch('isBreadcrumbsReady')
  onBreadcrumbsReadyStateChange(isReady: boolean) {
    if (isReady) {
      this.prepareData()
    }
  }

  get isBinderJetModality() {
    return this.getSelectedMachineType.modality === 'Binder Jet'
  }

  async prepareData() {
    try {
      this.platformList = await this.fetchBuildPlatesByMaterialAndMachine({
        materialId: this.getSelectedMaterial.materialId,
        machineConfigId: this.getSelectedMachineType.machineConfigId,
      })

      this.extendValidationRules()

      this.isReady = true
    } catch (error) {
      this.isReady = false
    }
  }

  async updateActiveMaterial(updateDTO: UpdateAdminMaterialDTO) {
    const { materialId } = this.$route.params
    const updatedMaterial = await this.updateMaterial({ materialId, dto: updateDTO })
    this.setSelectedMaterial(updatedMaterial)
  }

  get activeMaterial(): MachineMaterial {
    return this.getSelectedMaterial
  }

  onCloseEditMode() {
    this.editMode = EditViewMode.None
  }

  onUpdateSiteConstants(dto: UpdateAdminMaterialDTO) {
    this.editMode = EditViewMode.None
    this.updateActiveMaterial(dto)
  }

  updateList(index, item) {
    this.platformList.splice(index, 1, item)
  }

  extendValidationRules() {
    extend('more_value', {
      validate: (value: number, { limit }: { limit: number }) => {
        return value > limit
      },
      params: ['limit'],
      message: this.$i18n.t('moreValidationMessage') as string,
    })
  }
}
