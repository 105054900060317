
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Splitter extends Vue {
  @Prop({ default: 10, type: Number }) gutterSize: number
  @Prop({ default: '#ffffff', type: String }) gutterColor: string
  @Prop({ default: 50, type: Number }) initialSize: number
  @Prop({ type: Number }) minSize: number
  @Prop({ type: Number }) maxSize: number

  isMoved = false
  startY: number = null
  startSplit: number = null
  currentSplit: number = null

  get topHeight(): string {
    if (this.maxSize && this.currentSplit > this.maxSize) {
      this.currentSplit = this.maxSize
    }

    if (this.minSize && this.currentSplit < this.minSize) {
      this.currentSplit = this.minSize
    }

    const gutterHeight: number = (this.currentSplit * this.gutterSize) / 100

    return `calc(${this.currentSplit}% - ${gutterHeight}px)`
  }

  get bottomHeight(): string {
    const gutterHeight: number = ((100 - this.currentSplit) * this.gutterSize) / 100
    return `calc(${100 - this.currentSplit}% - ${gutterHeight}px)`
  }

  get gutterStyle(): object {
    return {
      height: `${this.gutterSize}px`,
      backgroundColor: this.gutterColor,
    }
  }

  getPaneStyle(): object {
    return {
      height: this.currentSplit,
    }
  }

  mounted() {
    this.currentSplit = this.initialSize
  }

  onMouseDown(event: MouseEvent) {
    this.isMoved = true
    this.startY = event.pageY
    this.startSplit = this.currentSplit
  }

  onMouseMove(event: MouseEvent) {
    if (this.isMoved) {
      const deltaY = event.pageY - this.startY
      const totalHeight = (this.$el as HTMLElement).offsetHeight
      this.currentSplit = this.startSplit + Math.trunc((deltaY / totalHeight) * 100)
    }
  }

  onMouseUp() {
    this.isMoved = false
  }

  onMouseLeave() {
    this.isMoved = false
  }

  reset() {
    if (!this.isMoved) {
      this.currentSplit = this.initialSize
    }
  }
}
