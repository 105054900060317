import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import StoresNamespaces from '@/store/namespaces'
import { DataTableHeaderCell } from '@/types/Common/DataTable'
import { RecursivePartial } from '@/types/Common/UtilityTypes'

import { merge } from '@/utils/common'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component
export default class TableHeaderMixin extends Vue {
  @fileExplorerStore.Mutation toggleDetailsPanel: () => void
  @fileExplorerStore.State isOpenDetailsPanel: boolean
  @fileExplorerStore.Getter isLoading: boolean

  getDefaultHeader(): DataTableHeaderCell {
    return {
      text: '',
      key: '',
      sortBy: '',
    }
  }

  headersToMap(headers: Array<RecursivePartial<DataTableHeaderCell>>) {
    return headers.reduce<Record<string, DataTableHeaderCell>>((map, header) => {
      map[header.key] = merge(this.getDefaultHeader(), header) as DataTableHeaderCell
      return map
    }, {})
  }
}
