/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Vector3 } from '@babylonjs/core/Maths'
import { BoundingBox } from '@babylonjs/core/Culling'
import { CanonicalBox, Line, Plane, Rectangle, Segment } from '../types/DCPTypes'

export default class DCPQuerry {
  /**
   * Algorithm based on Real-Time Collision Detection book
   *
   * Calculates closest point from given to given obb
   * @param point given point
   * @param boundingBox given bounding box
   * @returns closest point. If given point is inside boundingBox will return same point
   */
  public static closestPointToOBB(point: Vector3, boundingBox: BoundingBox): Vector3 {
    const dir = point.subtract(boundingBox.centerWorld)
    // Start result at center of box
    const q = boundingBox.centerWorld.clone()
    const extend = [boundingBox.extendSize.x, boundingBox.extendSize.y, boundingBox.extendSize.z]

    // For each OBB axis...
    for (let i = 0; i < 3; i += 1) {
      // ... project d onto that axis to get the distance
      // along the axis of d from the box center
      const normalizedAxis = boundingBox.directions[i].clone().normalize()
      let dist = Vector3.Dot(dir, normalizedAxis)

      // If distance farther than the box extends, clamp to the box
      if (dist > extend[i]) {
        dist = extend[i]
      }
      if (dist < -extend[i]) {
        dist = -extend[i]
      }

      // Step that distance along the axis to get world coordinate
      q.addInPlace(normalizedAxis.scale(dist))
    }

    return q
  }

  /**
   * Calculates closest distance between two bounding boxes
   * @param boundingBoxA given first bounding box
   * @param boundingBoxB given second bounding box
   * @returns object that contains distance and closest points between boxes
   */
  public static obbDistance(box0: BoundingBox, box1: BoundingBox) {
    const result = {
      distance: -1,
      pointA: Vector3.Zero(),
      pointB: Vector3.Zero(),
    }
    const box0Axis = box0.directions
    const box0Extent = box0.extendSize.asArray()
    const box1Axis = box1.directions
    const box1Extent = box1.extendSize.asArray()
    const rectangle = new Rectangle()

    let rectObbResult: {
      distance: number
      sqrDistance: number
      cartesian: number[]
      closest: Vector3[]
    }
    // Compare faces of box0 to box1.
    for (let i0 = 2, i1 = 0, i2 = 1; i1 < 3; i2 = i0, i0 = i1, i1 += 1) {
      rectangle.axis[0] = box0Axis[i0].clone().normalize()
      rectangle.axis[1] = box0Axis[i1].clone().normalize()
      rectangle.extent.x = box0Extent[i0]
      rectangle.extent.y = box0Extent[i1]

      const scaledAxis = box0Axis[i2].scale(box0Extent[i2])
      rectangle.center = box0.centerWorld.add(scaledAxis)
      rectObbResult = DCPQuerry.rectangleObbDistance(rectangle, box1)
      if (result.distance === -1 || rectObbResult.distance < result.distance) {
        result.distance = rectObbResult.distance
        result.pointA = rectObbResult.closest[0]
        result.pointB = rectObbResult.closest[1]
      }

      rectangle.center = box0.centerWorld.subtract(scaledAxis)
      rectObbResult = DCPQuerry.rectangleObbDistance(rectangle, box1)
      if (result.distance === -1 || rectObbResult.distance < result.distance) {
        result.distance = rectObbResult.distance
        result.pointA = rectObbResult.closest[0]
        result.pointB = rectObbResult.closest[1]
      }
    }

    // Compare faces of box1 to box0.
    for (let i0 = 2, i1 = 0, i2 = 1; i1 < 3; i2 = i0, i0 = i1, i1 += 1) {
      rectangle.axis[0] = box1Axis[i0].clone().normalize()
      rectangle.axis[1] = box1Axis[i1].clone().normalize()
      rectangle.extent.x = box1Extent[i0]
      rectangle.extent.y = box1Extent[i1]

      const scaledAxis = box1Axis[i2].scale(box1Extent[i2])
      rectangle.center = box1.centerWorld.add(scaledAxis)
      rectObbResult = DCPQuerry.rectangleObbDistance(rectangle, box0)
      if (result.distance === -1 || rectObbResult.distance < result.distance) {
        result.distance = rectObbResult.distance
        result.pointA = rectObbResult.closest[0]
        result.pointB = rectObbResult.closest[1]
      }

      rectangle.center = box1.centerWorld.subtract(scaledAxis)
      rectObbResult = DCPQuerry.rectangleObbDistance(rectangle, box0)
      if (result.distance === -1 || rectObbResult.distance < result.distance) {
        result.distance = rectObbResult.distance
        result.pointA = rectObbResult.closest[0]
        result.pointB = rectObbResult.closest[1]
      }
    }
    return result
  }

  public static rectangleObbDistance(
    rectangle: Rectangle,
    box: BoundingBox,
  ): {
    distance: number
    sqrDistance: number
    cartesian: number[]
    closest: Vector3[]
  } {
    let result = {
      distance: 0,
      sqrDistance: 0,
      cartesian: [0, 0],
      closest: [Vector3.Zero(), Vector3.Zero()],
    }

    // Rotate and translate the rectangle and box so that the box is
    // aligned and has center at the origin.
    const cbox: CanonicalBox = new CanonicalBox(box.extendSize)
    const delta = rectangle.center.subtract(box.centerWorld)
    const boxAxis = box.directions.map((item) => item.clone().normalize())
    const xfrmCenter = [0, 0, 0]
    const xfrmAxis = [
      [0, 0, 0],
      [0, 0, 0],
    ]
    for (let i = 0; i < 3; i += 1) {
      xfrmCenter[i] = Vector3.Dot(boxAxis[i], delta)
      for (let j = 0; j < 2; j += 1) {
        xfrmAxis[j][i] = Vector3.Dot(boxAxis[i], rectangle.axis[j])
      }
    }

    // The query computes 'output' relative to the box with center
    // at the origin.
    const xfrmRectangle = new Rectangle(
      Vector3.FromArray(xfrmCenter),
      [Vector3.FromArray(xfrmAxis[0]), Vector3.FromArray(xfrmAxis[1])],
      rectangle.extent,
    )
    result = DCPQuerry.rectangleCanonicalBoxDistance(xfrmRectangle, cbox)
    const resultClosest = [result.closest[0].asArray(), result.closest[1].asArray()]
    // Rotate and translate the closest points to the original
    // coordinates.
    const closest = [box.centerWorld.clone(), box.centerWorld.clone()]
    for (let i = 0; i < 2; i += 1) {
      for (let j = 0; j < 3; j += 1) {
        closest[i].addInPlace(boxAxis[j].scale(resultClosest[i][j]))
      }
    }

    result.closest = closest

    return result
  }

  public static rectangleCanonicalBoxDistance(
    rectangle: Rectangle,
    box: CanonicalBox,
  ): {
    distance: number
    sqrDistance: number
    cartesian: number[]
    closest: Vector3[]
  } {
    const result = {
      distance: 0,
      sqrDistance: 0,
      cartesian: [0, 0],
      closest: [Vector3.Zero(), Vector3.Zero()],
    }

    const normal = Vector3.Cross(rectangle.axis[0], rectangle.axis[1])
    const plane = new Plane(normal, rectangle.center)
    const pbOutput = DCPQuerry.planeCanonicalBoxDistance(plane, box)
    const delta = pbOutput.closest[0].subtract(rectangle.center)
    result.cartesian[0] = Vector3.Dot(rectangle.axis[0], delta)
    result.cartesian[1] = Vector3.Dot(rectangle.axis[1], delta)
    const rectangleExtent = rectangle.extent.asArray()

    if (Math.abs(result.cartesian[0]) <= rectangleExtent[0] && Math.abs(result.cartesian[1]) <= rectangleExtent[1]) {
      result.distance = pbOutput.distance
      result.sqrDistance = pbOutput.distance
      result.closest = pbOutput.closest
    } else {
      // The closest plane point is outside the rectangle, although
      // it is possible there are points inside the rectangle that
      // also are closest points to the box. Regardless, locate a
      // point on an edge of the rectangle that is closest to the
      // box.
      let sbOuput: {
        distance: number
        sqrDistance: number
        parameter: number
        closest: Vector3[]
      }
      const segment = new Segment()

      result.distance = -1
      result.sqrDistance = -1
      const sign = [-1, 1, -1, 1]
      const j0 = [0, 0, 1, 1]
      const j1 = [1, 1, 0, 0]
      const edges = [
        [0, 1],
        [2, 3], // horizontal edges
        [0, 2],
        [1, 3], // vertical edges
      ]

      const vertices = rectangle.getVertices()

      for (let i = 0; i < 4; i += 1) {
        const edge = edges[i]
        segment.p[0] = vertices[edge[0]].clone()
        segment.p[1] = vertices[edge[1]].clone()

        sbOuput = DCPQuerry.segmentCanonicalBoxDistance(segment, box)
        if (result.sqrDistance === -1 || sbOuput.sqrDistance < result.sqrDistance) {
          result.distance = sbOuput.distance
          result.sqrDistance = sbOuput.sqrDistance
          result.closest = sbOuput.closest

          const scale = 2 * sbOuput.parameter - 1
          result.cartesian[j0[i]] = scale * rectangleExtent[j0[i]]
          result.cartesian[j1[i]] = sign[i] * rectangleExtent[j1[i]]
        }
      }
    }
    return result
  }

  public static planeCanonicalBoxDistance(
    plane: Plane,
    box: CanonicalBox,
  ): {
    distance: number
    sqrDistance: number
    closest: Vector3[]
  } {
    const result = {
      distance: 0,
      sqrDistance: 0,
      closest: [Vector3.Zero(), Vector3.Zero()],
    }

    // Copies are made so that we can transform the plane normal to
    // the first octant (nonnegative components) using reflections.
    const origin = plane.normal.scale(plane.constant).asArray()
    const normal = plane.normal.asArray()
    const reflect = [false, false, false]
    for (let i = 0; i < 3; i += 1) {
      if (normal[i] < 0) {
        origin[i] = -origin[i]
        normal[i] = -normal[i]
        reflect[i] = true
      }
    }

    // Compute the plane-box closest points.
    if (normal[0] > 0) {
      if (normal[1] > 0) {
        if (normal[2] > 0) {
          // The normal signs are (+,+,+).
          Plane.doQuery3D(Vector3.FromArray(origin), Vector3.FromArray(normal), box.extent, result)
        } else {
          // The normal signs are (+,+,0).
          Plane.doQuery2D(0, 1, 2, origin, normal, box.extent.asArray(), result)
        }
      } else {
        if (normal[2] > 0) {
          // The normal signs are (+,0,+).
          Plane.doQuery2D(0, 2, 1, origin, normal, box.extent.asArray(), result)
        } else {
          // The normal signs are (+,0,0). The closest box point
          // is (x0,e1,e2) where x0 = clamp(p0,[-e0,e0]). The
          // closest plane point is (p0,e1,e2).
          Plane.doQuery1D(0, 1, 2, origin, box.extent.asArray(), result)
        }
      }
    } else {
      if (normal[1] > 0) {
        if (normal[2] > 0) {
          // The normal signs are (0,+,+).
          Plane.doQuery2D(1, 2, 0, origin, normal, box.extent.asArray(), result)
        } else {
          // The normal signs are (0,+,0). The closest box point
          // is (e0,x1,e2) where x1 = clamp(p1,[-e1,e1]). The
          // closest plane point is (e0,p1,e2).
          Plane.doQuery1D(1, 2, 0, origin, box.extent.asArray(), result)
        }
      } else {
        if (normal[2] > 0) {
          // The normal signs are (0,0,+) The closest box point
          // is (e0,e1,x2) where x2 = clamp(p2,[-e2,e2]). The
          // closest plane point is (e0,e1,p2).
          Plane.doQuery1D(2, 0, 1, origin, box.extent.asArray(), result)
        } else {
          // The normal signs are (0,0,0). Execute the DCP
          // query for the plane origin and the canonical box.
          // This is a low-probability event.
          Plane.doQuery0D(plane.origin, box.extent, result)
        }
      }
    }

    // Undo the reflections. The origin and normal are not
    // consumed, so these do not need to be reflected. However, the
    // closest points are consumed.
    for (let i = 0; i < 3; i += 1) {
      if (reflect[i]) {
        for (let j = 0; j < 2; j += 1) {
          const closest = result.closest[j].asArray()
          closest[i] = -closest[i]
          result.closest[j] = Vector3.FromArray(closest)
        }
      }
    }

    result.sqrDistance = Vector3.DistanceSquared(result.closest[0], result.closest[1])
    result.distance = Math.sqrt(result.sqrDistance)

    return result
  }

  public static segmentCanonicalBoxDistance(
    segment: Segment,
    box: CanonicalBox,
  ): {
    distance: number
    sqrDistance: number
    parameter: number
    closest: Vector3[]
  } {
    let result = {
      distance: 0,
      sqrDistance: 0,
      parameter: 0,
      closest: [Vector3.Zero(), Vector3.Zero()],
    }
    const segDirection = segment.p[1].subtract(segment.p[0])
    const line = new Line(segment.p[0], segDirection)
    const lbOutput = DCPQuerry.lineCanonicalBoxDistance(line, box)

    if (lbOutput.parameter >= 0) {
      if (lbOutput.parameter <= 1) {
        result = lbOutput
      } else {
        const pbOutput = DCPQuerry.pointCanonicalBoxDistance(segment.p[1], box)
        result.sqrDistance = pbOutput.sqrDistance
        result.distance = pbOutput.distance
        result.parameter = 1
        result.closest[0] = segment.p[1].clone()
        result.closest[1] = pbOutput.closest[1].clone()
      }
    } else {
      const pbOutput = DCPQuerry.pointCanonicalBoxDistance(segment.p[0], box)
      result.sqrDistance = pbOutput.sqrDistance
      result.distance = pbOutput.distance
      result.parameter = 0
      result.closest[0] = segment.p[0].clone()
      result.closest[1] = pbOutput.closest[1].clone()
    }
    return result
  }

  public static lineCanonicalBoxDistance(
    line: Line,
    box: CanonicalBox,
  ): {
    distance: number
    sqrDistance: number
    parameter: number
    closest: Vector3[]
  } {
    const result = {
      distance: 0,
      sqrDistance: 0,
      parameter: 0,
      closest: [Vector3.Zero(), Vector3.Zero()],
    }

    const origin = line.origin.asArray()
    const direction = line.direction.asArray()
    const reflect = [false, false, false]
    for (let i = 0; i < 3; i += 1) {
      if (direction[i] < 0) {
        origin[i] = -origin[i]
        direction[i] = -direction[i]
        reflect[i] = true
      }
    }

    // Compute the line-box distance and closest points. The DoQueryND
    // calls compute result.parameter and update result.sqrDistance.
    // The result.distance is computed after the specialized queries.
    // The result.closest[] points are computed afterwards.
    const boxExtent = box.extent.asArray()
    if (direction[0] > 0) {
      if (direction[1] > 0) {
        if (direction[2] > 0) {
          // (+, +, +)
          Line.doQuery3D(origin, direction, boxExtent, result)
        } else {
          // (+, +, 0)
          Line.doQuery2D(0, 1, 2, origin, direction, boxExtent, result)
        }
      } else {
        if (direction[2] > 0) {
          // (+, 0, +)
          Line.doQuery2D(0, 2, 1, origin, direction, boxExtent, result)
        } else {
          // (+, 0, 0)
          Line.doQuery1D(0, 1, 2, origin, direction, boxExtent, result)
        }
      }
    } else {
      if (direction[1] > 0) {
        if (direction[2] > 0) {
          // (0, +, +)
          Line.doQuery2D(1, 2, 0, origin, direction, boxExtent, result)
        } else {
          // (0, +, 0)
          Line.doQuery1D(1, 0, 2, origin, direction, boxExtent, result)
        }
      } else {
        if (direction[2] > 0) {
          // (0, 0, +)
          Line.doQuery1D(2, 0, 1, origin, direction, boxExtent, result)
        } else {
          // (0, 0, 0)
          Line.doQuery0D(origin, boxExtent, result)
        }
      }
    }

    // Undo the reflections applied previously.
    for (let i = 0; i < 3; i += 1) {
      if (reflect[i]) {
        origin[i] = -origin[i]
      }
    }

    result.distance = Math.sqrt(result.sqrDistance)
    // Compute the closest point on the line.
    result.closest[0] = line.origin.add(line.direction.scale(result.parameter))

    // Compute the closest point on the box. The original 'origin' is
    // modified by the DoQueryND functions to compute the closest
    // point.
    result.closest[1] = Vector3.FromArray(origin)
    return result
  }

  public static pointCanonicalBoxDistance(
    point: Vector3,
    box: CanonicalBox,
  ): {
    distance: number
    sqrDistance: number
    closest: Vector3[]
  } {
    const result = {
      distance: 0,
      sqrDistance: 0,
      closest: [point.clone(), point.clone()],
    }
    const pointArray = point.asArray()
    const closestPoint = point.asArray()
    const boxExtent = box.extent.asArray()
    for (let i = 0; i < 3; i += 1) {
      if (pointArray[i] < -boxExtent[i]) {
        const delta = closestPoint[i] + boxExtent[i]
        result.sqrDistance += delta * delta
        closestPoint[i] = -boxExtent[i]
        result.closest[1] = Vector3.FromArray(closestPoint)
      } else if (pointArray[i] > boxExtent[i]) {
        const delta = closestPoint[i] - boxExtent[i]
        result.sqrDistance += delta * delta
        closestPoint[i] = boxExtent[i]
        result.closest[1] = Vector3.FromArray(closestPoint)
      }
    }

    result.distance = Math.sqrt(result.sqrDistance)

    return result
  }
}
