
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import FallbackImage from '@/components/layout/FallbackImage.vue'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import ExpansionItemsList from '@/components/layout/FileExplorer/Details/ExpansionItemsList.vue'
import { PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import { ActionDateByUserMixin } from '@/components/layout/mixins/ActionDateByUserMixin'

@Component({
  components: { FallbackImage, ExpansionItemsList },
})
export default class DetailsPrintOrder extends ActionDateByUserMixin {
  @Prop() printOrder: PrintOrder
  @Prop() parts: PartListItemViewModel[]

  get previewImageUrl(): string {
    const time: number = new Date(this.printOrder.parentUpdatedAt).getTime()
    return this.printOrder.parentPreviewImageUrl ? `${this.printOrder.parentPreviewImageUrl}?${time}` : null
  }
}
