
import Vue from 'vue'
import Component from 'vue-class-component'
import Button from '@/components/controls/Common/Button.vue'
import { Mixins, Prop } from 'vue-property-decorator'

@Component({ components: { Button } })
export default class SocketDisconnectModal extends Mixins<Vue>(Vue) {
  @Prop({ required: true }) visible: boolean
  @Prop() disconnectMessage?: string
  @Prop() confirm: Function
}
