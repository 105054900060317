
import Vue from 'vue'
import Component from 'vue-class-component'
import Button from '@/components/controls/Common/Button.vue'
import { RouterPaths, ROUTER_PERMISSIONS } from '@/router'

import '@/assets/styles/home.scss'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { IUser } from '@/types/User/IUser'
import { isAuthorized } from '@/utils/user'

const userStore = namespace(StoresNamespaces.User)

@Component({
  components: {
    Button,
  },
})
export default class SiteMap extends Vue {
  @userStore.Getter('getUserDetails') getUserDetails: IUser

  isAuthorizedToSeeCostEstimator = false
  isAuthorizedToSeePrintModel = false

  async beforeMount() {
    const user = this.getUserDetails
    if (user) {
      this.isAuthorizedToSeeCostEstimator = isAuthorized(user, ROUTER_PERMISSIONS.CostEstimator)
      this.isAuthorizedToSeePrintModel = isAuthorized(user, ROUTER_PERMISSIONS.PrintModel)
    }
  }

  goToCostEstimation() {
    // @ts-ignore
    this.$router.safePush(RouterPaths.ManageCostEstimations)
  }

  goToFileExplorer() {
    // @ts-ignore
    this.$router.safePush(RouterPaths.DefaultFileExplorer)
  }
}
