
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { LabelTooltipMixin } from '@/components/layout/buildPlans/marking/mixins/LabelTooltipMixin'
import StoresNamespaces from '@/store/namespaces'
import { CharacterLengthControl } from '@/types/Label/enums'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { TextElement } from '@/types/Label/TextElement'

const labelStore = namespace(StoresNamespaces.Labels)

@Component
export default class UserEntryTooltip extends Mixins(LabelTooltipMixin) {
  @labelStore.Getter labelSets: InteractiveLabelSet[]

  @Prop() tooltipElement: TextElement

  get prompt(): string {
    return this.tooltipElement && JSON.parse(this.tooltipElement._cachedSpecificsJSON).promptText
  }

  get preview(): string {
    return this.tooltipElement && JSON.parse(this.tooltipElement._cachedSpecificsJSON).previewText
  }

  get requiredCharacters(): string {
    if (this.tooltipElement) {
      const specificJson = JSON.parse(this.tooltipElement._cachedSpecificsJSON)
      switch (specificJson.selectedInputType) {
        case CharacterLengthControl.Range:
          return `${specificJson.minRange} - ${specificJson.maxRange}`
        case CharacterLengthControl.Maximum:
          return `Less Than ${specificJson.max}`
        case CharacterLengthControl.Specific:
          return specificJson.exactly
      }
    }
    return ''
  }
}
