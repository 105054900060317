import { BoundingInfo, Mesh } from '@babylonjs/core'

export class BVHTreeNode {
  public obb
  public bInfo: BoundingInfo
  public debugBox: Mesh
  public left: BVHTreeNode
  public right: BVHTreeNode
  public depth: number
  public bodies: Set<string> = new Set<string>()
  public facetsIndices: Map<string, Set<number>> = new Map<string, Set<number>>()

  constructor(obb) {
    this.obb = obb
    this.left = null
    this.right = null
  }
}
