
import Vue from 'vue'
import Component from 'vue-class-component'
import Button from '@/components/controls/Common/Button.vue'
import clearAuth0Cookies from '@/utils/cookies/auth0'
import { LOGOUT_EVENT } from '@/constants'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'

const userStore = namespace(StoresNamespaces.User)

@Component({
  components: {
    Button,
  },
})
export default class ErrorModal extends Vue {
  errorDialog: boolean = true
  message: string = ''

  @userStore.Getter('getAuth0Client') auth0Client: any

  mounted() {
    if (this.$route.query && this.$route.query.message) {
      this.message = this.$route.query.message as string
    } else {
      this.message = this.$t('loginError') as string
    }
  }

  closeErrorModal() {
    // Clean up the cookies generated by Auth0 client first
    // because of https://github.com/auth0/auth0-spa-js/issues/217
    clearAuth0Cookies()
    // dispatch logout event to other browser tabs, if any
    localStorage.setItem(`${LOGOUT_EVENT}-${window.location.host}`, `${new Date().getTime()}`)
    // then log the user out and land them on the home page
    this.auth0Client.logout({ returnTo: window.location.origin })
  }
}
