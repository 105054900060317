
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import Selector from '@/components/controls/Common/Selector.vue'
import StoresNamespaces from '@/store/namespaces'
import { SimulationColoringModel } from '@/types/Simulation/SimulationColoringModel'

const visualizationStore = namespace(StoresNamespaces.Visualization)
const commonStore = namespace(StoresNamespaces.Common)

@Component({
  name: 'SimulationColoringModeComponent',
  components: { Selector },
})
export default class SimulationColoringModeComponent extends Vue {
  @visualizationStore.Getter('coloringModelSelectedViewModesNames') coloringModelSelectedViewModesNames: string[]
  @visualizationStore.Getter('coloringModel') coloringModel: SimulationColoringModel
  @visualizationStore.Getter('getVisualizationLoading') getVisualizationLoading: boolean
  @visualizationStore.Mutation changeSelectedColoringMode: (payload: { name: string }) => void

  @commonStore.Getter tooltipOpenDelay: number

  private hideSelectorToolTip: boolean = true

  get selectedModeName() {
    return this.coloringModel.selectedView.selectedMode.name
  }

  set selectedModeName(newModeName: string) {
    this.changeSelectedColoringMode({ name: newModeName })
  }

  get tooltipMessage(): string {
    return this.$t(this.coloringModel.selectedView.selectedMode.helpMessage) as string
  }

  get tooltips() {
    const tooltips = []
    this.coloringModel.selectedView.modes.forEach((element) => {
      const helpMessage = this.$t(element.helpMessage) as string
      tooltips[element.name] = helpMessage
    })
    return tooltips
  }

  get tooltipClass(): string {
    if (this.tooltipMessage.length > 0 && this.hideSelectorToolTip) {
      return 'tooltip-override--basic-job-tooltip'
    }
    return 'tooltip_empty'
  }

  changedSelectorOpenStatus(status: boolean) {
    this.hideSelectorToolTip = !status
  }
}
