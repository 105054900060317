
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Mixins, Watch } from 'vue-property-decorator'
import StoresNamespaces from '@/store/namespaces'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'

import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import AlertModal from '@/components/modals/AlertModal.vue'
import ConnectionErrorModal from '@/components/modals/ConnectionErrorModal.vue'
import { RouterNames } from '@/router'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import Selector from '@/components/controls/Common/Selector.vue'
import Menu from '@/components/controls/Common/Menu.vue'
import IBCPlanSidebarTools, { IBCPlanTool, IBCPlanToolNames } from '@/components/layout/IBCPlan/IBCPlanSidebarTools'
import IBCPlanInspectionTab from '@/components/layout/IBCPlan/IBCPlanInspectionTab.vue'
import IBCPlanDeviationCompensateTab from '@/components/layout/IBCPlan/IBCPlanDeviationCompensateTab.vue'
import BuildPlanSidebarBadges from '@/components/layout/buildPlans/BuildPlanSidebarBadges.vue'
import BuildPlanToolHeaderBadges from '@/components/layout/buildPlans/BuildPlanToolHeaderBadges.vue'
import { TOOL_HTML_TYPE, WAYS_TO_ENABLE_TOOLS } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { isTabVisible } from '@/utils/common'
import { IJob, JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'
import buildPlans from '@/api/buildPlans'
import messageService from '@/services/messageService'
import { ICOMPENSATE_COMPUTE_TYPE, SOCKET_TOOL_ACTIVATION_TIMEOUT } from '@/constants'
import { SidebarMixin } from '@/components/layout/buildPlans/mixins/SidebarMixin'
import SocketDisconnectModal from '@/components/layout/buildPlans/modals/SocketDisconnectModal.vue'
import SessionModal from '@/components/modals/SessionModal.vue'
import { StartJobError } from '@/errors/startJobError'
import { IVariantItem } from '@/types/BuildPlans/IBuildPlan'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { IPartDto } from '@/types/PartsLibrary/Parts'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const commonStore = namespace(StoresNamespaces.Common)
const partsStore = namespace(StoresNamespaces.Parts)

const DEFAULT_BUTTON_STYLE = 'button'
const JOBS_POLLING_TIMEOUT = 5000

@Component({
  components: {
    IBCPlanInspectionTab,
    IBCPlanDeviationCompensateTab,
    ConfirmModal,
    AlertModal,
    ConnectionErrorModal,
    Selector,
    Menu,
    BuildPlanSidebarBadges,
    BuildPlanToolHeaderBadges,
    SocketDisconnectModal,
    SessionModal,
  },
})
export default class IBCPlanSidebar extends Mixins(SidebarMixin) {
  @commonStore.Getter tooltipOpenDelay: number

  @buildPlansStore.Action fetchIbcPlanJobs: (payload: {
    ibcPlanId: string
    fetchForRelatedVariants?: number
  }) => Promise<void>
  @buildPlansStore.Action fetchVariantJobsByBuildPlanId: (id: string) => Promise<void>

  @buildPlansStore.Getter getIsLoading: boolean
  @buildPlansStore.Getter getIBCPlan: IIBCPlan
  @buildPlansStore.Getter getIbcPlanJobs: IJob[]
  @buildPlansStore.Getter isLockedForUser: boolean
  @buildPlansStore.Getter isLockedForViewer: boolean
  @buildPlansStore.Getter('getBuildPlanVariants') allVariants: IVariantItem[]
  @buildPlansStore.Getter getAllVariantsJobs: IJob[]

  @buildPlansStore.Mutation setBuildPlanViewMode: (mode: ViewModeTypes | null) => void

  @visualizationStore.Mutation changeViewMode: Function

  $refs!: {
    toolComponent: any
    confirm: InstanceType<typeof ConfirmModal>
    session: InstanceType<typeof SessionModal>
    alert2: InstanceType<typeof AlertModal>
    connectionError: InstanceType<typeof ConnectionErrorModal>
  }

  // generic tool action button flags
  actionButtonsLoading = false

  items: IBCPlanTool[] = IBCPlanSidebarTools.getIBCPlanTools()

  isBusy: boolean = false

  okName: string = null
  selectorItems: string[] = []
  menuItems: any[] = []
  runningJobsTimeout = null
  isDestroyed = false
  runningJobs = []
  completedJobs = []
  failedJobs = []
  warningJobs = []
  isJobsFetching = true
  TOOL_HTML_TYPE = TOOL_HTML_TYPE
  compensateThrottle: NodeJS.Timeout
  navigationReady = false

  get toolComponent() {
    let tool = null
    switch (this.getBuildPlanViewMode) {
      case ViewModeTypes.Inspections:
        tool = IBCPlanInspectionTab
        break
      case ViewModeTypes.DeviationCompensate:
        tool = IBCPlanDeviationCompensateTab
        break
    }

    if (tool && tool.extendOptions.methods) {
      this.cancelAvailable = tool.options.methods.clickCancel !== undefined
      this.okAvailable = tool.options.methods.clickOk !== undefined
      this.okName = tool.options.methods.getOkName ? tool.options.methods.getOkName() : null
      this.cancelName = tool.options.methods.getCancelName ? tool.options.methods.getCancelName() : null
    } else {
      this.cancelAvailable = false
      this.okAvailable = false
      this.okName = null
      this.cancelName = null
    }

    return tool
  }

  get activeTool() {
    return this.items.find((item) => item.viewMode === this.getBuildPlanViewMode)
  }

  get currentVariantJobs(): IJob[] {
    return this.getIbcPlanJobs.filter((job) => {
      // For the PUBLISH job the 'itemId' property contains the ID of IBC Part
      if (job.jobType === JobType.IBC_PUBLISH) {
        return job.relatedPlanId === this.$route.params.ibcPlanId
      }
      return job.itemId === this.$route.params.ibcPlanId
    })
  }

  get allIbcVariantsRunningJobs(): IJob[] {
    return this.getIbcPlanJobs.filter((job) => {
      return [JobStatusCode.RUNNING, JobStatusCode.PENDING, JobStatusCode.QUEUED, JobStatusCode.CREATED].includes(
        job.code,
      )
    })
  }

  get allSbcVariantsRunningJobs(): IJob[] {
    return this.getAllVariantsJobs.filter((job) => {
      if (job.jobType === JobType.PRINT) {
        return [JobStatusCode.RUNNING, JobStatusCode.QUEUED, JobStatusCode.CREATED].includes(job.code)
      }
      return [JobStatusCode.RUNNING, JobStatusCode.PENDING, JobStatusCode.QUEUED, JobStatusCode.CREATED].includes(
        job.code,
      )
    })
  }

  get isActiveToolReadOnly(): boolean {
    if (!this.activeTool) {
      return false
    }

    const { viewMode } = this.activeTool

    if (viewMode === ViewModeTypes.DeviationCompensate) {
      const hasCompletedCompensateJob = this.completedJobs.some((job) => {
        return job.jobType === JobType.ICOMPENSATE && job.computeType.trim().toLowerCase()
      })
      const hasWarningCompensateJob = this.warningJobs.some((job) => {
        return job.jobType === JobType.ICOMPENSATE && job.computeType.trim().toLowerCase()
      })
      return hasCompletedCompensateJob || hasWarningCompensateJob
    }

    if (viewMode === ViewModeTypes.Inspections) {
      const hasSomeCompensateJob = this.currentVariantJobs.some((job) => {
        return job.jobType === JobType.ICOMPENSATE && job.computeType.trim().toLowerCase() === ICOMPENSATE_COMPUTE_TYPE
      })
      return hasSomeCompensateJob
    }

    return false
  }

  private get completedCompensationJobs() {
    return this.currentVariantJobs.filter(
      (job) =>
        job.jobType === JobType.ICOMPENSATE &&
        (job.code === JobStatusCode.COMPLETE || job.code === JobStatusCode.WARNING),
    )
  }

  private get isCompensateActiveTool() {
    return this.activeTool && this.activeTool.key === IBCPlanToolNames.DEVIATION_COMPENSATE
  }

  sideBarItemText(item) {
    return this.$t(item.key)
  }

  jobCreated() {
    this.pollActiveJobs(true)
  }

  async closeTool() {
    if (this.isCompensateActiveTool && this.isCompensationFileInProgress) {
      const confirm = await this.$refs.confirm.open(
        this.$t('exportInProgressTitle'),
        this.$t('exportIbcInProgressMessage'),
      )
      if (confirm) return
    }

    if ((this.$refs.toolComponent as any).clickClose) {
      ;(this.$refs.toolComponent as any).clickClose()
    }

    if (this.activeTool && !this.activeTool.routerName) {
      this.setBuildPlanViewMode(null)
      this.changeViewMode(ViewModeTypes.IBCPlan)
      return
    }

    const onIBCPlanPage = this.$route.matched.some((route) => route.name === RouterNames.FE_EditIbcPlan)
    if (onIBCPlanPage) {
      // @ts-ignore
      this.$router.safePush({ name: RouterNames.FE_EditIbcPlan })
    }
  }

  clickCancel() {
    if ((this.$refs.toolComponent as any).clickCancel) {
      ;(this.$refs.toolComponent as any).clickCancel()
    }
    this.closeTool()
  }

  clickExport() {
    if ((this.$refs.toolComponent as any).clickExport) {
      ;(this.$refs.toolComponent as any).clickExport()
    }
  }

  async clickOk() {
    try {
      const clickHandler = (this.$refs.toolComponent as any).clickOk
      if (clickHandler) {
        this.isBusy = true
        await clickHandler()
        this.isBusy = false
      }
      this.closeTool()
    } catch (error) {
      if (!(error instanceof StartJobError)) {
        this.$refs.connectionError.open(this.sideBarItemText(this.activeTool).toString(), error)
      }
    } finally {
      this.isBusy = false
    }
  }

  async clickSecondaryAction() {
    const clickHandler = (this.$refs.toolComponent as IToolComponent).clickSecondaryAction
    if (clickHandler) {
      try {
        this.isBusy = true
        await clickHandler()
      } finally {
        this.isBusy = false
      }
    }
  }

  async beforeMount() {
    this.setBuildPlanViewMode(ViewModeTypes.IBCPlan)
    await this.checkJobs()
  }

  async checkJobs() {
    const shoudlFetchForRelatedVariants = true
    await this.fetchAllJobs(shoudlFetchForRelatedVariants)

    const sbcVariants = this.allVariants.filter((v) => v.subType === ItemSubType.SinterPlan)
    if (sbcVariants.length > 0) {
      await this.fetchVariantJobsByBuildPlanId(sbcVariants[0].id)
    }

    if (this.runningJobs.length > 0 || this.allSbcVariantsRunningJobs.length > 0) {
      await this.pollActiveJobs()
    }
  }

  destroyed() {
    clearTimeout(this.runningJobsTimeout)
    this.isDestroyed = true
  }

  onRouteChanged(to) {
    this.$refs.session.clearSession()
    const itemByRouteName = this.items.find(
      (item) => item.routerName && item.routerName.toLowerCase() === to.name.toLowerCase(),
    )
    if (itemByRouteName) {
      this.setBuildPlanViewMode(itemByRouteName.viewMode)
      this.changeViewMode(itemByRouteName.viewMode)
      if (itemByRouteName.viewMode === ViewModeTypes.DeviationCompensate && this.completedCompensationJobs.length) {
        this.$refs.session.prolongSession()
      }
    } else {
      // throttling logic moved here to disable re-entering socket based tools
      // for 10 seconds after they're quit and make sure it would be applied either tool is closed
      // or user clicks Back button in the browser
      this.setThrottlingForSocketBasedTools()
      this.setBuildPlanViewMode(null)
      this.changeViewMode(ViewModeTypes.IBCPlan)
    }
  }

  async clickOnMenuItem(item: IBCPlanTool) {
    if (item.waysToEnable.length || !this.isToolAvailable(item)) {
      return
    }

    if (item.key === IBCPlanToolNames.PUBLISH) {
      try {
        const runningPublishJobs = this.runningJobs.filter((job) => job.jobType === JobType.IBC_PUBLISH)
        if (!(runningPublishJobs.length || this.isJobsFetching)) {
          this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_INFO, this.getIBCPlan.name, this.$t('publishing'))
          // Should be considered which result should be showed
          await buildPlans.publishIBCPlan(this.getIBCPlan.id, 0)
          this.pollActiveJobs(true)
        }
      } catch (error) {
        this.removeWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_INFO, this.getIBCPlan.name, this.$t('publishing'))
        const msg = this.$i18n.t('publishTaskFailed') as string
        messageService.showErrorMessage(`${msg} ${error.message}`)
      }
    }

    if (item.routerName) {
      // @ts-ignore
      this.$router.safePush({ name: item.routerName })
    } else {
      if (item.setViewMode) {
        this.setBuildPlanViewMode(item.viewMode)
        this.changeViewMode(item.viewMode)
      }
    }
  }

  isToolAvailable(tool: IBCPlanTool): boolean {
    if (!this.navigationReady) {
      return false
    }

    if (this.activeTool && this.activeTool.sidebarStyling && this.activeTool.sidebarStyling.keepList) {
      return this.activeTool.key === tool.key
    }

    if (tool.waysToEnable.length > 0) {
      return false
    }

    return true
  }

  getButtonStyle(item: IBCPlanTool) {
    if (!item.button) {
      return
    }

    switch (item.key) {
      default:
        return DEFAULT_BUTTON_STYLE
    }
  }

  async pollActiveJobs(forceFetchJobs = false) {
    clearTimeout(this.runningJobsTimeout)

    const hasToCheckJobs =
      this.runningJobs.length > 0 ||
      this.allSbcVariantsRunningJobs.length > 0 ||
      this.allIbcVariantsRunningJobs.length > 0 ||
      forceFetchJobs
    if (!hasToCheckJobs || this.isDestroyed) {
      return
    }

    this.runningJobsTimeout = setTimeout(
      async () => {
        try {
          if (isTabVisible() || forceFetchJobs) {
            let fetchForRelatedVariants = false

            const runningJobIbcVariants = this.getRunningJobIbcVariants()

            if (runningJobIbcVariants.length > 1) {
              fetchForRelatedVariants = true
            } else if (runningJobIbcVariants.length === 1) {
              fetchForRelatedVariants = runningJobIbcVariants.some((v) => v.id !== this.$route.params.ibcPlanId)
            }

            await this.fetchAllJobs(fetchForRelatedVariants)
          }
        } finally {
          this.pollActiveJobs()
        }
      },
      // make poll immediately in case it was forced
      forceFetchJobs ? 0 : JOBS_POLLING_TIMEOUT,
    )
  }

  async fetchAllJobs(fetchForRelatedVariants = false) {
    await this.fetchIbcPlanJobs({
      ibcPlanId: this.$route.params.ibcPlanId,
      fetchForRelatedVariants: Number(fetchForRelatedVariants),
    })

    if (this.allSbcVariantsRunningJobs.length > 0) {
      const { relatedPlanId, itemId } = this.allSbcVariantsRunningJobs[0]
      const sbcVariantId = relatedPlanId || itemId
      await this.fetchVariantJobsByBuildPlanId(sbcVariantId)
    }

    this.initJobs()
    this.isJobsFetching = false
    this.onIBCPlanOrJobsChange()
  }

  getRunningJobIbcVariants(): IVariantItem[] {
    const variantIds = this.allIbcVariantsRunningJobs.map((job) => job.relatedPlanId || job.itemId)
    const uniqueVariantIdsSet = new Set(variantIds)

    return this.allVariants.filter((v) => v.itemType === ItemType.IbcPlan && uniqueVariantIdsSet.has(v.id))
  }

  initJobs() {
    this.runningJobs = this.currentVariantJobs
      .filter((job) => {
        return [JobStatusCode.RUNNING, JobStatusCode.PENDING, JobStatusCode.QUEUED, JobStatusCode.CREATED].includes(
          job.code,
        )
      })
      .sort((a, b) => b.number - a.number)

    this.failedJobs = this.currentVariantJobs
      .filter(
        (job) =>
          job.code === JobStatusCode.ERROR ||
          job.code === JobStatusCode.CANCELLED ||
          job.code === JobStatusCode.CANCELLING,
      )
      .sort((a, b) => b.number - a.number)

    this.completedJobs = this.currentVariantJobs
      .filter((job) => job.code === JobStatusCode.COMPLETE)
      .sort((a, b) => b.number - a.number)

    this.warningJobs = this.currentVariantJobs
      .filter((job) => job.code === JobStatusCode.WARNING)
      .sort((a, b) => b.number - a.number)
  }

  onIBCPlanOrJobsChange() {
    if (!this.getIBCPlan) {
      return
    }

    for (const item of this.items) {
      item.waysToEnable = []

      switch (item.key) {
        case IBCPlanToolNames.INSPECTIONS:
          const hasRunningCompensateOrPublishJobs =
            this.runningJobs.filter((job) => job.jobType === JobType.ICOMPENSATE || job.jobType === JobType.IBC_PUBLISH)
              .length > 0
          const completedPublishJobs = this.completedJobs.filter((job) => job.jobType === JobType.IBC_PUBLISH)
          const publishedIbcPlanItemIds = []
          for (const job of completedPublishJobs) {
            if (job.jobName) {
              const jobNameJSON = JSON.parse(job.jobName)
              publishedIbcPlanItemIds.push(jobNameJSON.ibcPlanItemId)
            }
          }
          const allIbcPlanItemIds = this.getIBCPlan.ibcPlanItems.map((ibcPlanItem) => ibcPlanItem.id)
          const areAllIbcPlanItemsPublished = allIbcPlanItemIds.every((i) => publishedIbcPlanItemIds.includes(i))
          item.shouldShowStatusIcon = hasRunningCompensateOrPublishJobs || areAllIbcPlanItemsPublished
          break
        case IBCPlanToolNames.DEVIATION_COMPENSATE:
          if (!(this.getIBCPlan.measurements && this.getIBCPlan.measurements.length > 0)) {
            this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.NO_MEASUREMENT_FILE)
          }
          if (this.isJobsFetching) {
            this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_UNAVAILABLE)
          }
          break
        case IBCPlanToolNames.PUBLISH:
          this.checkPublishTool(item)
          break
        case IBCPlanToolNames.SELECT_PUBLISHED_PARTS:
          this.setMenuItems(item)
          if (this.menuItems.length > 0) {
            item.hidden = false
          } else {
            item.hidden = true
          }
          break
      }
    }
  }

  getRunningJobsForBadgesByJobTypes(jobTypes: JobType[]) {
    return this.filterJobsByJobTypes(this.runningJobs, jobTypes)
  }

  getCompletedJobsForBadgesByJobTypes(jobTypes: JobType[]) {
    return this.filterJobsByJobTypes(this.completedJobs, jobTypes)
  }

  getFailedJobsForBadgesByJobTypes(jobTypes: JobType[]) {
    return this.filterJobsByJobTypes(this.failedJobs, jobTypes)
  }

  getWarningJobsForBadgesByJobTypes(jobTypes: JobType[]) {
    return this.filterJobsByJobTypes(this.warningJobs, jobTypes)
  }

  filterJobsByJobTypes(jobs: IJob[], jobTypes: JobType[]) {
    const filteredJobs = jobs.filter((job) => {
      if (jobTypes.includes(job.jobType)) {
        return true
      }
    })
    return filteredJobs
  }

  @Watch('getIBCPlan')
  @Watch('getIBCPlan.measurements')
  onIBCPlanChange() {
    this.onIBCPlanOrJobsChange()
  }

  @Watch('getIsLoading')
  onIBCPlanLoaded(value) {
    if (value) {
      return
    }

    this.navigationReady = true
    const itemByRouteName = this.items.find(
      (item) => item.routerName && item.routerName.toLowerCase() === this.$router.currentRoute.name.toLowerCase(),
    )
    if (itemByRouteName) {
      if (itemByRouteName.waysToEnable.length === 0 || itemByRouteName.routerName === RouterNames.IBC_Inspections) {
        this.setBuildPlanViewMode(itemByRouteName.viewMode)
        this.changeViewMode(itemByRouteName.viewMode)
      } else {
        // @ts-ignore
        this.$router.safePush({ name: RouterNames.FE_EditIbcPlan })
      }
    }
  }

  @Watch('$route.params.ibcPlanId')
  async onChangeVariant() {
    this.pollActiveJobs(true)
  }

  // If export of compensation files has been finished before the user confirmed tab should be closed
  // dismiss the dialog
  @Watch('isCompensationFileInProgress')
  compensationInProgressChanged() {
    if (!this.isCompensationFileInProgress && this.$refs.confirm.isShow) {
      this.$refs.confirm.confirm()
    }
  }

  get isReadOnly() {
    return this.isJobsFetching || !!this.runningJobs.length || !!this.completedJobs.length || !!this.warningJobs.length
  }

  setMenuItems(item: IBCPlanTool) {
    this.menuItems = []

    const completedPublishJobs = this.completedJobs.filter((job) => job.jobType === JobType.IBC_PUBLISH)
    const publishedIbcPlanItemIds = []
    for (const job of completedPublishJobs) {
      if (job.jobName) {
        const jobNameJSON = JSON.parse(job.jobName)
        publishedIbcPlanItemIds.push(jobNameJSON.ibcPlanItemId)
      }
    }
    const failedPublishJobs = this.failedJobs.filter((job) => job.jobType === JobType.IBC_PUBLISH)
    const failedAndCompletedPublishJobs = [...failedPublishJobs, ...completedPublishJobs]
    failedAndCompletedPublishJobs.map((job) => {
      let ibcPartName = job.itemId
      let publishedIbcPlanItemId
      if (job.jobName) {
        const jobNameJSON = JSON.parse(job.jobName)
        ibcPartName = jobNameJSON.name
        publishedIbcPlanItemId = jobNameJSON.ibcPlanItemId
        if (
          job.code !== JobStatusCode.COMPLETE &&
          job.code !== JobStatusCode.WARNING &&
          publishedIbcPlanItemIds.includes(publishedIbcPlanItemId)
        ) {
          return
        }
      }
      const ibcItemId = job.itemId
      this.menuItems.push({
        name: ibcPartName,
        value: ibcItemId,
        code: job.code,
        description: job.description,
        buildPlanItemId: publishedIbcPlanItemId,
      })
    })
    if (completedPublishJobs.length === 1) {
      item.placeholder = 'onePublishedPart'
    } else if (completedPublishJobs.length > 1) {
      item.placeholder = 'severalPublishedParts'
    }
  }

  checkPublishTool(item: IBCPlanTool) {
    if (this.isLockedForViewer) {
      this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.SINTER_PLAN_LOCKED_FOR_VIEWER)
    }

    if (this.isLockedForUser) {
      this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.SINTER_PLAN_READ_ONLY)
    }

    if (!this.completedCompensationJobs.length) {
      this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.NO_COMP_RESULTS_TO_PUBLISH)
    }
    const runningPublishJobs = this.runningJobs.filter((job) => job.jobType === JobType.IBC_PUBLISH)
    if (runningPublishJobs.length) {
      this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_INFO, this.$t('publish'), this.$t('preparing'))
    }

    const completedPublishJobs = this.completedJobs.filter((job) => item.jobTypes.includes(job.jobType))
    // check if all ibc plan items got published successfully
    if (completedPublishJobs.length >= this.getIBCPlan.ibcPlanItems.length) {
      item.hidden = true
    } else {
      item.hidden = false
    }
  }

  isJobComplete(code) {
    return code === JobStatusCode.COMPLETE || code === JobStatusCode.WARNING
  }

  getIbcPartsLabel(item: IBCPlanTool) {
    const completedMenuItems = this.menuItems.filter(
      (i) => i.code === JobStatusCode.COMPLETE || i.code === JobStatusCode.WARNING,
    )
    return this.$t(item.placeholder, { number: completedMenuItems.length })
  }

  confirmSocketDisconnectModal() {
    this.closeTool()
    this.socketDisconnectModalVisibility = false
  }

  private setThrottlingForSocketBasedTools() {
    if (this.isCompensateActiveTool) {
      const item = this.activeTool
      if (this.compensateThrottle) {
        clearTimeout(this.compensateThrottle)
      }

      if (this.completedCompensationJobs.length) {
        item.isPreparing = true
        this.addUniqueWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_RECONNECT)
        this.compensateThrottle = setTimeout(() => {
          item.isPreparing = false
          this.removeWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_RECONNECT)
        }, SOCKET_TOOL_ACTIVATION_TIMEOUT)
      }
    }
  }
}
