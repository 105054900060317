
import Vue from 'vue'
import { extend } from 'vee-validate'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { required } from 'vee-validate/dist/rules'
import { isNil } from '@/utils/common'

@Component({})
export default class SliderWithValidation extends Vue {
  @Prop({ required: true }) label: string
  @Prop({ required: true }) name: string
  @Prop({ required: true }) min: number
  @Prop({ required: true }) max: number
  @Prop() value: number
  @Prop() readonly: number
  @Prop() tickLabels: string[]

  sliderValue: number = null
  innerModel: number = null
  validColor = '#1867c0'
  erroredColor = 'transparent'

  get sliderColor() {
    return this.isValid ? this.validColor : this.erroredColor
  }

  get isValid() {
    return this.validate(this.value)
  }

  get providerRules() {
    return {
      required_in_range: {
        leftLimit: this.min,
        rightLimit: this.max,
        units: this.$i18n.t('pixelsAbbr'),
      },
    }
  }

  beforeMount() {
    this.extendValidationRules()
  }

  mounted() {
    this.sliderValue = this.value
    this.innerModel = this.value
  }

  onSliderValueChanged(value) {
    this.setSliderValue(value)
  }

  extendValidationRules() {
    extend('required_in_range', {
      ...required,
      params: ['leftLimit', 'rightLimit', 'units'],
      message: isNil(this.value)
        ? this.$i18n.t('requiredValidationMessage').toString()
        : this.$i18n.t('inRangeValidationMessage').toString(),
      validate: (value) => {
        return this.validate(value)
      },
    })
  }

  validate(value: number) {
    return value <= this.max && value >= this.min
  }

  setSliderValue(value: number) {
    this.sliderValue = this.isValid ? value : null
    this.$emit('input', value)
  }

  @Watch('value')
  onValueChanged() {
    this.innerModel = this.value
    this.$emit('input', this.value)
  }

  @Watch('innerModel')
  onInnerModelChanged(value: number) {
    this.setSliderValue(value)
  }
}
