
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import { TableHeader } from '@/types/Sites/TenantAdminContentTableHeader'
import { SortOrders } from '@/types/SortModes'

@Component({
  components: {},
})
export default class TenantAdminHeader extends Vue {
  @Prop({ default: () => [] }) headers: TableHeader[]

  sortIcon(header: TableHeader) {
    return header.sortOrder === SortOrders.Ascending ? 'mdi-arrow-up' : 'mdi-arrow-down'
  }

  changeSort(header: TableHeader) {
    this.$emit('sort-changed', header)
  }
}
