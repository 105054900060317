/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { RenderScene } from '@/visualization/render-scene'
import { Scene } from '@babylonjs/core/scene'
import { Engine } from '@babylonjs/core/Engines/engine'
import { SelectionManager } from '@/visualization/rendering/SelectionManager'
import { SimulationManager } from '@/visualization/rendering/SimulationManager'
import { OrthoCamera } from '@/visualization/components/OrthoCamera'
import { OuterEvents, SceneType } from '@/visualization/types/Common'
import { OBBTree } from '@/visualization/OBBTree'
import { BuildPlateManager } from '@/visualization/rendering/BuildPlateManager'
import { InputController } from '@/visualization/rendering/InputController'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import store from '@/store'
import { GeometryType, IBuildPlan, SelectionUnit } from '@/types/BuildPlans/IBuildPlan'
import { KeyboardInfo } from '@babylonjs/core/Events'
import { ResultsManager } from '@/visualization/rendering/ResultsManager'
import { DeviationManager } from '@/visualization/rendering/DeviationManager'
import { BACKQUOTE_CODE } from '@/constants'

export interface IViewMode {
  readonly viewModeScene: Scene
  isViewModeReadOnly: boolean
  setup(): void
  clean(): void
  onOuterEvent(eventName: OuterEvents, payload: object): void
  setIsReadOnly(value: boolean): void
}

abstract class ViewMode implements IViewMode {
  protected scene: Scene
  protected renderScene: RenderScene
  protected inputController: InputController
  protected isReadOnly: boolean

  constructor(scene: Scene, renderScene: RenderScene) {
    this.scene = scene
    this.renderScene = renderScene
    this.inputController = renderScene.getInputController()
    this.isReadOnly = false
  }

  get viewModeScene() {
    return this.scene
  }

  get isViewModeReadOnly() {
    return this.isReadOnly
  }

  set isViewModeReadOnly(value: boolean) {
    this.isReadOnly = value
  }

  abstract setup(): void

  abstract clean(): void

  abstract onOuterEvent(eventName: OuterEvents, payload: object): void

  abstract setIsReadOnly(value: boolean): void
}

export class VariantsViewMode extends ViewMode {
  private selectionManager: SelectionManager

  constructor(scene: Scene, renderScene: RenderScene, selectionManager: SelectionManager) {
    super(scene, renderScene)
    this.selectionManager = selectionManager
  }

  setup() {
    this.selectionManager.deselect()

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
  }

  clean() {
    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeGpuPickerObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }
}

export class MoveViewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
    this.inputController.addCameraPositionChangedObserver()
    this.inputController.addHoverObjectsObserver()
    this.inputController.addSelectedObjectObserver({ ignoreBlankSpace: true })
    this.inputController.addSelectionBoxObserver()
  }

  clean() {
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
    this.inputController.removeCameraPositionChangedObserver()
    this.inputController.removeHoverObjectsObserver()
    this.inputController.removeSelectedObjectObserver()
    this.inputController.removeSelectionBoxObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }
}

export class RotateViewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    const bp: IBuildPlan = store.getters['buildPlans/getBuildPlan']
    if (bp.subType === ItemSubType.SinterPlan && !this.isReadOnly) {
      this.renderScene.enableGizmos()
    }

    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()

    if (bp.subType !== ItemSubType.SinterPlan) {
      this.inputController.addHoverObjectsObserver()
      this.inputController.addSelectedObjectObserver({ ignoreBlankSpace: true })
      this.inputController.removeSelectionBoxObserver()
      this.inputController.addSelectionBoxObserver()
    }
  }

  clean() {
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()

    this.inputController.removeSelectionBoxObserver()
    const bp: IBuildPlan = store.getters['buildPlans/getBuildPlan']
    if (bp.subType === ItemSubType.SinterPlan) {
      this.renderScene.disableGizmos(true)
    } else {
      this.inputController.removeHoverObjectsObserver()
      this.inputController.removeSelectedObjectObserver()
    }
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    const bp: IBuildPlan = store.getters['buildPlans/getBuildPlan']
    switch (eventName) {
      case OuterEvents.StartDownwardRotation:
        this.inputController.removeLeftPointerEventForCameraObserver()
        if (bp.subType !== ItemSubType.SinterPlan) {
          this.inputController.removeHoverObjectsObserver()
          this.inputController.removeSelectedObjectObserver()
          this.inputController.removeSelectionBoxObserver()
        }
        break
      case OuterEvents.EndDownwardRotation:
        if (bp.subType !== ItemSubType.SinterPlan) {
          this.inputController.removeHoverObjectsObserver()
          this.inputController.addHoverObjectsObserver()
          this.inputController.removeSelectedObjectObserver()
          this.inputController.addSelectedObjectObserver()
          this.inputController.removeSelectionBoxObserver()
          this.inputController.addSelectionBoxObserver()
        }

        this.inputController.addLeftPointerEventForCameraObserver()
        break
    }
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }
}

export class ScaleViewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
  }

  clean() {
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }
}

export class DuplicateViewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)
    if (this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    }

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addCameraPositionChangedObserver()
    this.inputController.addHoverObjectsObserver()
    this.inputController.addCollectorHoverObjectsObserver()
    this.inputController.addCollectorSelectedObjectObserver()
  }

  clean() {
    if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }

    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeCameraPositionChangedObserver()
    this.inputController.removeHoverObjectsObserver()
    this.inputController.removeCollectorHoverObjectObserver()
    this.inputController.removeCollectorSelectedObjectObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    }
  }
}

export class TransferPropsViewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
    this.inputController.addCameraPositionChangedObserver()
    this.inputController.addHoverObjectsObserver()
    this.inputController.addSelectedObjectObserver({
      shouldHandleCtrlKey: false,
    })
  }

  clean() {
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
    this.inputController.removeCameraPositionChangedObserver()
    this.inputController.removeHoverObjectsObserver()
    this.inputController.removeSelectedObjectObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    }
  }
}

export class ConstrainViewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
    this.inputController.addHoverObjectsObserver()
    this.inputController.addSelectedObjectObserver({ ignoreBlankSpace: true })
    this.inputController.addSelectionBoxObserver()
  }

  clean() {
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
    this.inputController.removeHoverObjectsObserver()
    this.inputController.removeSelectedObjectObserver()
    this.inputController.removeSelectionBoxObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }
}

export class LayoutViewMode extends ViewMode {
  private selectionManager: SelectionManager
  private obbTree: OBBTree
  private buildPlateManager: BuildPlateManager

  constructor(scene: Scene, renderScene: RenderScene, selectionManager: SelectionManager) {
    super(scene, renderScene)
    this.selectionManager = selectionManager
    this.obbTree = renderScene.getObbTree()
    this.buildPlateManager = renderScene.getBuildPlateManager()
  }

  setup() {
    const bp: IBuildPlan = store.getters['buildPlans/getBuildPlan']
    const ibcBp: IBuildPlan = store.getters['buildPlans/getIBCPlan']
    if ((bp && bp.subType === ItemSubType.SinterPlan) || (ibcBp && ibcBp.itemType === ItemType.IbcPlan)) {
      this.renderScene.disableGizmos(true)
    }

    this.inputController.addRepositionCameraObserver()
    this.inputController.addHoverObjectsObserver()
    this.inputController.addSelectedObjectObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addSelectionBoxObserver()
    this.inputController.addGpuPickerObserver()
    this.inputController.addCollectorHoverObjectsObserver()
    this.inputController.addCollectorSelectedObjectObserver()

    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)
  }

  clean() {
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeHoverObjectsObserver()
    this.inputController.removeSelectedObjectObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeSelectionBoxObserver()
    this.inputController.removeGpuPickerObserver()
    this.inputController.removeCollectorHoverObjectObserver()
    this.inputController.removeCollectorSelectedObjectObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    const bp: IBuildPlan = store.getters['buildPlans/getBuildPlan']
    const ibcBp: IBuildPlan = store.getters['buildPlans/getIBCPlan']
    const isIBCPlan = ibcBp && ibcBp.itemType === ItemType.IbcPlan
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (!this.isReadOnly && bp.subType !== ItemSubType.SinterPlan && !isIBCPlan) {
      this.renderScene.enableGizmos()
    }
  }
}

export class OrientationViewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)
    if (this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    }

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
    this.inputController.addCollectorHoverObjectsObserver()
    this.inputController.addCollectorSelectedObjectObserver()
  }

  clean() {
    if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }

    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
    this.inputController.removeCollectorHoverObjectObserver()
    this.inputController.removeCollectorSelectedObjectObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    }
  }
}

export class SupportViewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addHoverObjectsObserver()
    this.inputController.addSelectedObjectObserver({ ignoreRmb: true, allowSupports: true })
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
    this.renderScene.disableGizmos(true)
  }

  clean() {
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeHoverObjectsObserver()
    this.inputController.removeSelectedObjectObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
    if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }
}

export class NestingViewMode extends ViewMode {
  private selectionManager: SelectionManager

  constructor(scene: Scene, renderScene: RenderScene, selectionManager: SelectionManager) {
    super(scene, renderScene)
    this.selectionManager = selectionManager
  }

  setup() {
    this.selectionManager.deselect()
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
  }

  clean() {
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }
}

export class ResultsViewMode extends ViewMode {
  protected resultsManager: ResultsManager
  protected visualizationScene: Scene

  private engine: Engine
  private selectionManager: SelectionManager
  private activeScene: SceneType

  constructor(renderScene: RenderScene, engine: Engine, selectionManager: SelectionManager) {
    super(renderScene.getScene(), renderScene)
    this.engine = engine
    this.visualizationScene = this.renderScene.constructScene()
    this.selectionManager = selectionManager
    this.activeScene = SceneType.Layout
    this.onRenderRequestedHandler = this.onRenderRequestedHandler.bind(this)
  }

  get viewModeScene(): Scene {
    return this.activeScene === SceneType.Layout ? this.scene : this.visualizationScene
  }

  get layoutCamera(): OrthoCamera {
    return this.scene.activeCamera as OrthoCamera
  }

  getResultsManager() {
    return this.resultsManager
  }

  setup() {
    this.selectionManager.deselect()
    this.renderScene.registerCanvasEvents(this.viewModeScene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
    this.inputController.addCollectorHoverObjectsObserver()
    this.inputController.addCollectorSelectedObjectObserver()

    if (this.activeScene === SceneType.Visualization) {
      this.renderScene.getActiveCamera().addChangeTargetObserver()
      // If user locked view in Layout Mode we want to keep it in Visualization Mode
      this.renderScene.setViewLocked(this.layoutCamera.isViewLocked)
      this.layoutCamera.removeChangeTargetObserver()
    }

    this.resultsManager.renderRequested.on(this.onRenderRequestedHandler)
  }

  clean() {
    // Cleanup logic goes here, close socket connection, cleanup canvas

    this.resultsManager.renderRequested.off(this.onRenderRequestedHandler)
    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
    this.inputController.removeCollectorHoverObjectObserver()
    this.inputController.removeCollectorSelectedObjectObserver()

    if (this.activeScene === SceneType.Visualization) {
      this.renderScene.getActiveCamera().removeChangeTargetObserver()
      this.layoutCamera.addChangeTargetObserver()
    }

    // If user locked view in Visualization mode we want to keep it in Layout Mode
    const keepViewLocked = (this.viewModeScene.activeCamera as OrthoCamera).isViewLocked

    // make sure regular scene isn't distorted when leaving Results View mode (Visualize)
    this.activeScene = SceneType.Layout
    this.renderScene.setViewLocked(keepViewLocked)
    setTimeout(() => this.renderScene.resizeCanvas(), 0)
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    if (eventName === OuterEvents.SetActiveScene) {
      const { active } = payload as { active: SceneType }
      if (this.activeScene !== active) {
        this.clean()
        this.activeScene = active
        this.setup()
      }
    } else {
      this.resultsManager.handleOuterEvent(eventName, payload)
    }
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }

  private onRenderRequestedHandler() {
    this.renderScene.animate()
  }
}

export class SimulateViewMode extends ResultsViewMode {
  constructor(renderScene: RenderScene, engine: Engine, selectionManager: SelectionManager) {
    super(renderScene, engine, selectionManager)
    this.resultsManager = new SimulationManager(this.visualizationScene, engine)
  }

  getSimulationManager() {
    return this.resultsManager as SimulationManager
  }
}

export class DeviationViewMode extends ResultsViewMode {
  constructor(renderScene: RenderScene, engine: Engine, selectionManager: SelectionManager) {
    super(renderScene, engine, selectionManager)
    this.resultsManager = new DeviationManager(this.visualizationScene, engine)
  }

  getDeviationManager() {
    return this.resultsManager as DeviationManager
  }
}

export class CrossSectionViewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
  }

  clean() {
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }
}

export class SlicingViewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
  }

  clean() {
    this.renderScene.setSlicer(false)
    this.renderScene.removePolylines()
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }
}

export class MarkingViewMode extends ViewMode {
  private selectionManager: SelectionManager
  private ctrlPressedCallback: (evt: KeyboardInfo) => void
  private ctrlPressedCallbackForBars: (evt: KeyboardInfo) => void

  constructor(scene: Scene, renderScene: RenderScene, selectionManager: SelectionManager) {
    super(scene, renderScene)
    this.selectionManager = selectionManager
    const ctrlHanlder = (evt: KeyboardInfo, onlyBars: boolean = false) => {
      if (
        evt.event.key.toLocaleLowerCase() !== 'control' ||
        this.selectionManager.getSelectionMode() !== SelectionUnit.Body
      ) {
        return
      }

      if (evt.event.type === 'keyup') {
        this.inputController.removeSelectedObjectObserver()
        this.inputController.addSelectedObjectObserver(
          {
            cacheSelected: true,
            ignoreBlankSpace: true,
            allowSupports: false,
            ignoreRmb: true,
          },
          { onlyBars, bodyTypes: [GeometryType.Coupon] },
        )
      }

      if (evt.event.type === 'keydown') {
        this.inputController.removeSelectedObjectObserver()
        this.inputController.addSelectedObjectObserver(
          { allowSupports: false, ignoreRmb: true },
          { onlyBars, bodyTypes: [GeometryType.Coupon] },
        )
      }
    }

    this.ctrlPressedCallback = (evt: KeyboardInfo) => ctrlHanlder(evt)
    this.ctrlPressedCallbackForBars = (evt: KeyboardInfo) => ctrlHanlder(evt, true)
  }

  setup() {
    this.selectionManager.deselect()
    this.selectionManager.setSelectionMode(SelectionUnit.Part)

    this.renderScene.disableGizmos(true)
    this.renderScene.getModelManager().initializeLabelMode()
    this.renderScene.getModelManager().changeLabelColors(true)
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addHoverObjectsObserver()
    this.inputController.addSelectedObjectObserver()
    this.inputController.addSelectionBoxObserver()
    this.inputController.addGpuPickerObserver()

    this.scene.onKeyboardObservable.add(this.ctrlPressedCallback)
  }

  clean() {
    this.selectionManager.deselect()
    this.selectionManager.setSelectionMode(SelectionUnit.Part)
    this.scene.onKeyboardObservable.removeCallback(this.ctrlPressedCallback)
    this.scene.onKeyboardObservable.removeCallback(this.ctrlPressedCallbackForBars)
    this.renderScene.getModelManager().deactivateLabelCreation()
    this.renderScene.getModelManager().terminateLabelMode()
    this.renderScene.getModelManager().changeLabelColors(false)
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeHoverObjectsObserver()
    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeSelectedObjectObserver()
    this.inputController.removeSelectionBoxObserver()
    this.inputController.removeGpuPickerObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    if (eventName === OuterEvents.LabelEnterManualPlacement) {
      if (!this.isViewModeReadOnly) {
        const options = {
          selectWithAttach: true,
          cacheSelected: true,
          allowWithButtonPressed: true,
          allowSupports: false,
          ignoreRmb: true,
        }
        const filters = { bodyTypes: [GeometryType.Coupon, GeometryType.Production, GeometryType.Support] }
        this.inputController.removeHoverObjectsObserver()
        this.inputController.addHoverObjectsObserver({ allowWithButtonPressed: true, allowSupports: false })
        this.inputController.removeSelectedObjectObserver()
        this.inputController.addSelectedObjectObserver(options, filters)
        this.inputController.removeSelectionBoxObserver()
      }

      this.scene.onKeyboardObservable.removeCallback(this.ctrlPressedCallback)
      this.scene.onKeyboardObservable.removeCallback(this.ctrlPressedCallbackForBars)
      this.renderScene.getGpuPicker().enableLabels()
      this.renderScene.getScene().metadata.updateGpuPicker = true
      this.renderScene.animate()
    }

    if (eventName === OuterEvents.LabelExitManualPlacement || eventName === OuterEvents.LabelExitBarPlacement) {
      if (!this.isViewModeReadOnly) {
        const options = {
          cacheSelected: true,
          ignoreBlankSpace: true,
          allowSupports: false,
          ignoreRmb: true,
        }
        const filters = { bodyTypes: [GeometryType.Coupon] }
        this.inputController.removeHoverObjectsObserver()
        this.inputController.addHoverObjectsObserver({ allowSupports: false }, filters)
        this.inputController.removeSelectedObjectObserver()
        this.inputController.addSelectedObjectObserver(options, filters)
        this.inputController.addSelectionBoxObserver(options, filters)
      }

      this.scene.onKeyboardObservable.add(this.ctrlPressedCallback)
      this.scene.onKeyboardObservable.removeCallback(this.ctrlPressedCallbackForBars)
      this.renderScene.getGpuPicker().disableLabels()
      this.renderScene.getScene().metadata.updateGpuPicker = true
      this.renderScene.animate()
    }

    if (eventName === OuterEvents.LabelEnterBarPlacement) {
      if (!this.isViewModeReadOnly) {
        const options = {
          cacheSelected: true,
          ignoreBlankSpace: true,
          allowSupports: false,
          ignoreRmb: true,
        }

        const filters = { bodyTypes: [GeometryType.Coupon], onlyBars: true }
        this.inputController.removeHoverObjectsObserver()
        this.inputController.addHoverObjectsObserver({ allowSupports: false }, filters)
        this.inputController.removeSelectedObjectObserver()
        this.inputController.addSelectedObjectObserver(options, filters)
        this.inputController.removeSelectionBoxObserver()
        this.inputController.addSelectionBoxObserver(options, filters)
        this.scene.onKeyboardObservable.removeCallback(this.ctrlPressedCallback)
        this.scene.onKeyboardObservable.add(this.ctrlPressedCallbackForBars)
      }
    }

    if (eventName === OuterEvents.LabelOrientationStartUpdate) {
      this.inputController.removeHoverObjectsObserver()
    }

    if (eventName === OuterEvents.LabelOrientationEndUpdate) {
      this.inputController.addHoverObjectsObserver({ allowWithButtonPressed: true, allowSupports: false })
    }

    if (eventName === OuterEvents.LabelPlacementEnabled) {
      this.selectionManager.activateSelectionBox()

      this.inputController.removeHoverObjectsObserver()
      this.inputController.removeSelectedObjectObserver()
      this.inputController.removeSelectionBoxObserver()

      const isLabelReadOnly: boolean = store.getters['buildPlans/isLabelReadOnly']
      if (this.isViewModeReadOnly || isLabelReadOnly) {
        this.inputController.removeGpuPickerObserver()
      }

      if (!this.isViewModeReadOnly) {
        const options = {
          cacheSelected: true,
          ignoreBlankSpace: true,
          allowSupports: false,
          ignoreRmb: true,
        }
        const filters = { bodyTypes: [GeometryType.Coupon] }
        this.inputController.addHoverObjectsObserver({ allowSupports: false }, filters)
        this.inputController.addSelectedObjectObserver(options, filters)
        this.inputController.addSelectionBoxObserver(options, filters)
      }
    }

    if (eventName === OuterEvents.LabelPlacementDisabled) {
      if (!this.isViewModeReadOnly) {
        this.inputController.removeHoverObjectsObserver()
        this.inputController.removeSelectedObjectObserver()
        this.inputController.removeSelectionBoxObserver()
      }

      this.inputController.addHoverObjectsObserver()
      this.inputController.addSelectedObjectObserver()
      this.inputController.addSelectionBoxObserver()

      const isLabelReadOnly: boolean = store.getters['buildPlans/isLabelReadOnly']
      if (!this.isViewModeReadOnly || !isLabelReadOnly) {
        this.inputController.addGpuPickerObserver()
      }
    }

    if (eventName === OuterEvents.LabelActivateManualPlacementUpdate) {
      if (!this.isViewModeReadOnly) {
        const options = {
          selectWithAttach: true,
          cacheSelected: true,
          allowWithButtonPressed: true,
          allowSupports: false,
          ignoreRmb: true,
          ignoreMoveThreshold: true,
        }
        const filters = { bodyTypes: [GeometryType.Coupon, GeometryType.Production, GeometryType.Support] }
        this.inputController.removeSelectedObjectObserver()
        this.inputController.addSelectedObjectObserver(options, filters)
      }
    }

    if (eventName === OuterEvents.LabelDeactivateManualPlacementUpdate) {
      const options = {
        selectWithAttach: true,
        cacheSelected: true,
        allowWithButtonPressed: true,
        allowSupports: false,
        ignoreRmb: true,
      }
      const filters = { bodyTypes: [GeometryType.Coupon, GeometryType.Production, GeometryType.Support] }
      this.inputController.removeSelectedObjectObserver()
      this.inputController.addSelectedObjectObserver(options, filters)
    }
  }

  setIsReadOnly(value: boolean) {
    if (!value && value !== this.isReadOnly) {
      const options = {
        cacheSelected: true,
        ignoreBlankSpace: true,
        allowSupports: false,
        ignoreRmb: true,
      }
      const filters = { bodyTypes: [GeometryType.Coupon] }
      this.inputController.addSelectedObjectObserver(options, filters)

      this.inputController.addHoverObjectsObserver({ allowSupports: false }, filters)
      this.inputController.addGpuPickerObserver()
      this.inputController.addSelectionBoxObserver(options, filters)
    }
    if (value) {
      this.inputController.removeSelectedObjectObserver()
      this.inputController.removeHoverObjectsObserver()
      this.inputController.removeGpuPickerObserver()
      this.inputController.removeSelectionBoxObserver()
    }

    this.isReadOnly = value
  }
}

export class PrintViewMode extends ViewMode {
  private selectionManager: SelectionManager
  constructor(scene: Scene, renderScene: RenderScene, selectionManager: SelectionManager) {
    super(scene, renderScene)
    this.selectionManager = selectionManager
  }

  setup() {
    this.selectionManager.deselect()
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)
    if (this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    }

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
  }

  clean() {
    if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }

    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    }
  }
}

export class PublishViewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
  }

  clean() {
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }
}

export class PartViewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
    this.renderScene.disableGizmos(true)
  }

  clean() {
    // TODO: Remove stuff from the canvas
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
    this.renderScene.enableGizmos()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }
}

export class PreviewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
    this.inputController.addHoverObjectsObserver()
  }

  clean() {
    // TODO: Remove stuff from the canvas
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
    this.inputController.removeHoverObjectsObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }
}

export class PartLayoutMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
  }

  clean() {
    // TODO: Remove stuff from the canvas
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }
}

export class PrintOrderPreviewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)
    if (this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    }

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
    this.inputController.addHoverObjectsObserver()
  }

  clean() {
    if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }

    // TODO: Remove stuff from the canvas
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
    this.inputController.removeHoverObjectsObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }
}

export class ReplaceViewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
    this.renderScene.disableGizmos(true)
  }

  clean() {
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
    this.renderScene.enableGizmos()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    }
  }
}

export class ClearanceToolViewMode extends ViewMode {
  private rubberBandObserver: boolean = true

  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup(): void {
    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)
    this.rubberBandObserver = true

    this.inputController.addRepositionCameraObserver()
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()
    this.inputController.addHoverObjectsObserver({ allowWithButtonPressed: true })
    this.inputController.addClearanceRubberBandObserver()
  }

  clean(): void {
    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
    this.inputController.removeHoverObjectsObserver()
    if (this.rubberBandObserver) {
      this.inputController.removeClearanceRubberBandObserver()
    } else {
      this.inputController.removeSelectedObjectObserver()
    }
  }

  onOuterEvent(eventName: OuterEvents, payload: object): void {
    if (eventName !== OuterEvents.ClearanceChangeSelectionObserver) {
      return
    }

    const { isEnabled } = payload as { isEnabled: boolean }
    if (isEnabled === this.rubberBandObserver) {
      return
    }

    if (this.rubberBandObserver) {
      this.inputController.removeClearanceRubberBandObserver()
      this.inputController.addSelectedObjectObserver({ shouldHandleCtrlKey: false })
    } else {
      this.inputController.removeSelectedObjectObserver()
      this.inputController.addClearanceRubberBandObserver()
    }

    this.rubberBandObserver = isEnabled
  }

  setIsReadOnly(value: boolean): void {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    } else if (!this.isReadOnly) {
      this.renderScene.enableGizmos()
    }
  }
}

export class IBCPlanViewMode extends ViewMode {
  constructor(scene: Scene, renderScene: RenderScene) {
    super(scene, renderScene)
  }

  setup() {
    this.inputController.addRepositionCameraObserver()
    this.inputController.addHoverObjectsObserver({ allowWithButtonPressed: true })
    this.inputController.addLeftPointerEventForCameraObserver()
    this.inputController.addCameraZoomWithTouch()
    this.inputController.addGpuPickerObserver()

    this.renderScene.registerCanvasEvents(this.scene.activeCamera as OrthoCamera)
  }

  clean() {
    this.renderScene.unregisterCanvasEvents()

    this.inputController.removeRepositionCameraObserver()
    this.inputController.removeHoverObjectsObserver()
    this.inputController.removeLeftPointerEventForCameraObserver()
    this.inputController.removeCameraZoomWithTouch()
    this.inputController.removeGpuPickerObserver()
  }

  onOuterEvent(eventName: OuterEvents, payload: object) {
    // Handle Vue events here
  }

  setIsReadOnly(value: boolean) {
    this.isReadOnly = value
    if (this.isReadOnly && this.renderScene.isGizmoEnabled()) {
      this.renderScene.disableGizmos(true)
    }
  }
}
