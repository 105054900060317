import { GetterTree } from 'vuex'
import { IJobsState } from '@/store/modules/jobs/types'
import { IRootState } from '@/store/types'
import { IJob } from '@/types/PartsLibrary/Job'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'

export const getters: GetterTree<IJobsState, IRootState> = {
  getJobs(state): IJob[] {
    return state && state.jobs
  },

  getPrintOrder(state): PrintOrder {
    return state.printOrder
  },

  getPrintOrders(state) {
    return state.printOrders
  },

  isPrintOrdersLoading(state) {
    return state.printOrdersLoading
  },
}
