import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import {
  GeometryType,
  IBuildPlan,
  IBuildPlanItem,
  IDisplayToolbarState,
  IPrintStrategyParameterSet,
  PartProperty,
} from '@/types/BuildPlans/IBuildPlan'
import { BodyOption } from '@/components/layout/buildPlans/addPart/types'
import { BodyTypeIcons, PART_BODY_ID_DELIMITER } from '@/constants'
import { convert } from '@/utils/converter/lengthConverter'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { getDefaultBaseOnType, getDefaultNameBasedOnBodyType } from '@/utils/parameterSet/parameterSetUtils'
import { VersionableModel } from '@/types/Common/VersionableModel'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { isBuildPlanItemBodyFunctionLocked } from '@/utils/buildPlan/buildPlanItemUtil'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const commonStore = namespace(StoresNamespaces.Common)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component
export default class PartPropertyItemMixin extends Vue {
  @Prop() readonly item: PartProperty
  @Prop({ required: true }) readonly parameters: IPrintStrategyParameterSet[]
  @Prop({ default: false }) readonly isReadOnly: boolean
  @Prop() readonly bpItem?: IBuildPlanItem
  @Prop() readonly partProperties?: PartProperty[]
  @Prop({ default: false }) readonly disabled?: boolean
  @Prop({ default: false }) hideActionItems: boolean
  @Prop({ default: false }) readonly isPrintOrder: boolean
  @Prop({ default: false }) readonly isBodyTypeOptionsDisabled: boolean

  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter isSelectedSinterPart: boolean
  @buildPlansStore.Getter isSelectedIbcPart: boolean
  @buildPlansStore.Getter isSinterPlan: boolean
  @buildPlansStore.Getter getBuildPlanPrintStrategy: BuildPlanPrintStrategyDto
  @buildPlansStore.Getter displayToolbarStateByVariantId: (buildPlanId: string) => IDisplayToolbarState
  @buildPlansStore.Getter defaultPartParamOptionAvailable: boolean
  @commonStore.Getter tooltipOpenDelay: number

  @visualizationStore.Action updateGeometriesOnTypeChange: Function
  @visualizationStore.Getter isPrintOrderPreviewViewMode: boolean

  get parameterSetInitialValue() {
    let bodyOption = this.bodyParameterOptions.find((option) => {
      return option.value instanceof VersionablePk
        ? option.value.equals(
            new VersionablePk(this.item.printStrategyParameterSetId, this.item.printStrategyParameterSetVersion),
          )
        : null
    })
    if (!bodyOption) {
      bodyOption = this.bodyParameterOptions.find((x) => (x.value as VersionablePk).id === null)
    }
    return bodyOption && bodyOption.value
  }

  get bodyParameterOptions(): BodyOption[] {
    const defaults = this.getBuildPlanPrintStrategy.defaults
    const printStrategyParameterSetPk = getDefaultBaseOnType(defaults, this.item.type, this.item.bodyType)
    const params = [...this.parameters]

    if (printStrategyParameterSetPk) {
      const defaultParam: IPrintStrategyParameterSet = this.parameters.find(
        (parameter) =>
          parameter.id === printStrategyParameterSetPk.id && parameter.version === printStrategyParameterSetPk.version,
      )

      if (this.defaultPartParamOptionAvailable) {
        const defaultOption = {
          name: getDefaultNameBasedOnBodyType(this.item.type, this.item.bodyType),
          id: null,
          parameterSetId: defaultParam.parameterSetId,
          allowSimCom: defaultParam.allowSimCom,
          layerThickness: defaultParam.layerThickness,
          parameterSet: defaultParam.parameterSet,
          parameterSetVersion: defaultParam.parameterSetVersion,
          version: 1,
          implicitVersion: defaultParam.implicitVersion,
        }
        params.unshift(defaultOption)
      }
    }

    return params.map((parameter) => {
      const layerThickness = parseFloat(convert('m', 'mm', parameter.layerThickness).toPrecision(3))
      const thicknessText = parameter.id ? ` - ${layerThickness}mm` : ''
      const name = parameter.name + thicknessText
      return {
        name,
        value: VersionableModel.getPk(parameter),
      }
    })
  }

  get bodyParameterName(): string {
    const partProp = this.item
    let printStrategyParameterSetPk: VersionablePk
    if (partProp.printStrategyParameterSetId) {
      printStrategyParameterSetPk = new VersionablePk(
        partProp.printStrategyParameterSetId,
        partProp.printStrategyParameterSetVersion,
      )
    } else {
      const defaults = this.getBuildPlanPrintStrategy.defaults
      printStrategyParameterSetPk = getDefaultBaseOnType(defaults, partProp.type, partProp.bodyType)
    }
    let bodyParameter: IPrintStrategyParameterSet
    if (printStrategyParameterSetPk) {
      bodyParameter = this.parameters.find(
        (parameter) =>
          parameter.id === printStrategyParameterSetPk.id && parameter.version === printStrategyParameterSetPk.version,
      )
    }
    return bodyParameter ? bodyParameter.name : '-'
  }

  get bodyTypeOptions(): BodyOption[] {
    const options = [
      {
        value: GeometryType.Production,
        name: this.$i18n.t('production').toString(),
        icon: BodyTypeIcons.production,
      },
      {
        value: GeometryType.Support,
        name: this.$i18n.t('support').toString(),
        icon: BodyTypeIcons.support,
      },
      {
        value: GeometryType.Coupon,
        name: this.$i18n.t('coupon').toString(),
        icon: BodyTypeIcons.coupon,
      },
    ]

    return options.map((option) => {
      const name: string = option.name.toString()
      option.name = name.charAt(0).toUpperCase() + name.substring(1)
      return option
    })
  }

  get bodyTypeName(): string {
    const bodyType = this.bodyTypeOptions.find((type) => type.value === this.item.type)
    return bodyType ? bodyType.name : '-'
  }

  get bodyName(): string {
    return this.item.name || '-'
  }

  get isBuildPlanPartProperty() {
    return !!this.bpItem
  }

  get isBodyTypeOptionsReadOnly() {
    return this.isReadOnly || (this.bpItem && isBuildPlanItemBodyFunctionLocked(this.bpItem))
  }

  updateSceneGeometriesOnTypeChange(
    updatedProp: PartProperty,
    options?: { copyAll?: boolean; copyInstances?: boolean },
  ) {
    const items = []
    const updatedGeometryTypeVisibility = this.isGeometryTypeVisible(updatedProp.type)
    let bodyIds: string[]

    if (!options || (!options.copyAll && !options.copyInstances)) {
      bodyIds = [updatedProp.geometryId]
    } else if (options && options.copyInstances) {
      const sourceBodyId: string = updatedProp.geometryId.split(PART_BODY_ID_DELIMITER)[1]
      bodyIds = this.partProperties
        .filter((targetProperty) => {
          const targetBodyId: string = targetProperty.geometryId.split(PART_BODY_ID_DELIMITER)[1]
          if (targetBodyId === sourceBodyId && updatedProp.id !== targetProperty.id) {
            return targetProperty
          }
        })
        .map((partProperty) => partProperty.geometryId)
    }

    if (this.bpItem) {
      items.push({ bodyIds, buildPlanItemId: this.bpItem.id })
      this.updateGeometriesOnTypeChange({
        items,
        geometryType: updatedProp.type,
        visibility: updatedGeometryTypeVisibility,
      })
    } else {
      if (!updatedGeometryTypeVisibility) {
        this.$emit('hide-bodies', bodyIds)
      }
    }
  }

  private isGeometryTypeVisible(geometryType: GeometryType) {
    switch (geometryType) {
      case GeometryType.Production:
        return this.displayToolbarStateByVariantId(this.getBuildPlan.id).isShowingProductionGeometry
      case GeometryType.Support:
        return this.displayToolbarStateByVariantId(this.getBuildPlan.id).isShowingSupportGeometry
      case GeometryType.Coupon:
        return this.displayToolbarStateByVariantId(this.getBuildPlan.id).isShowingCouponGeometry
    }
  }
}
