
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Icon from '@/components/icons/Icon.vue'
import PartTooltip from '@/components/layout/buildPlans/addPart/PartTooltip.vue'
import BuildPlanPartMixin from '@/components/layout/buildPlans/mixins/BuildPlanPartMixin'
import StoresNamespaces from '@/store/namespaces'
import { PartTypes } from '@/types/BuildPlans/IBuildPlan'
import { JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import { PartListItemViewModel } from './types'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'

const commonStore = namespace(StoresNamespaces.Common)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const TIMEOUT = 800

@Component({ components: { Icon, PartTooltip } })
export default class PartListItem extends Mixins(BuildPlanPartMixin) {
  @commonStore.Getter tooltipOpenDelay: number
  @visualizationStore.Mutation dispose: Function
  @fileExplorerStore.Action openItemFolder: (itemId: string) => Promise<void>

  @Prop({ required: true }) readonly item: PartListItemViewModel
  @Prop({ default: false }) readonly showTooltip: boolean
  @Prop({ default: true }) truncateImmediately: boolean
  @Prop({ default: true }) isHidden: boolean
  @Prop({ default: true }) allowLocationOpening: boolean

  timeout = null
  itemName: string = null

  $refs!: {
    itemName: HTMLElement
  }

  get isCustomPartIcon(): boolean {
    return [PartTypes.SinterPart, PartTypes.IbcPart].includes(this.item.partType)
  }

  get customIconViewBox() {
    switch (this.item.partType) {
      case PartTypes.SinterPart:
        return '0 0 24 24.4'
      case PartTypes.IbcPart:
        return '0 0 24 20'
      default:
        return
    }
  }

  get partIcon(): string {
    switch (this.item.partType) {
      case PartTypes.BuildPlanPart:
        return 'mdi-cube-outline'
      case PartTypes.SinterPart:
        return 'simulation-based-compensation'
      case PartTypes.IbcPart:
        return 'inspection-based-compensation'
      default:
        return 'mdi-cube-off-outline'
    }
  }

  get partIconColor() {
    switch (this.item.partType) {
      case PartTypes.BuildPlanPart:
        return '#63666A'
      case PartTypes.SinterPart:
        return '#E86B5F'
      case PartTypes.IbcPart:
        return '#1d9a00'
      default:
        return '#63666A'
    }
  }

  get isImporting(): boolean {
    return (
      (this.item.status === JobStatusCode.RUNNING || this.item.status === JobStatusCode.QUEUED) &&
      this.item.jobType === JobType.IMPORT
    )
  }

  get hasError(): boolean {
    return (
      this.item.status === JobStatusCode.ERROR ||
      this.item.status === JobStatusCode.CANCELLED ||
      !!this.item.disabledDescription
    )
  }

  get versionLabel() {
    if (this.item.partType === PartTypes.SinterPart) {
      return this.item.sinterPlanVersionLabel
    }
    if (this.item.partType === PartTypes.IbcPart) {
      return this.item.ibcPlanVersionLabel
    }
  }

  mouseEnter(event) {
    this.timeout = setTimeout(() => {
      const { y } = event.target.getBoundingClientRect()
      this.$emit('hovered', { top: y, item: this.item })
    }, TIMEOUT)
  }

  mouseLeave() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.$emit('hovered', { top: 0, item: null })
  }

  mounted() {
    if (this.truncateImmediately) {
      this.forceTruncate()
    }
  }

  forceTruncate() {
    this.itemName = this.middleTruncate(this.item.name, this.$refs.itemName, this.$refs.itemName.parentElement)
  }

  openLocationFolder(e: MouseEvent) {
    if (!this.allowLocationOpening) return

    e.stopPropagation()

    if (this.item.itemType === ItemType.BuildPart && this.item.subType === ItemSubType.SinterPart) {
      this.openItemFolder(this.item.originalPlanId)
      return
    }

    this.openItemFolder(this.item.id)
  }

  @Watch('isHidden', { immediate: true })
  async truncateIfVisible() {
    if (!this.isHidden && !this.itemName) {
      await this.$nextTick()
      this.forceTruncate()
    }
  }
}
