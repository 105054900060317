export interface IVisualizationEvent<T> {
  on(handler: (data?: T) => void): void
  off(handler: (data?: T) => void): void
  trigger(data: T): void
  dispose(): void
}

export class VisualizationEvent<T> implements IVisualizationEvent<T> {
  private handlers: Array<(data?: T) => void> = []

  on(handler: (data?: T) => void): void {
    this.handlers.push(handler)
  }

  off(handler: (data?: T) => void): void {
    this.handlers = this.handlers.filter((h) => h !== handler)
  }

  trigger(data: T): void {
    this.handlers.slice(0).forEach((handler) => handler(data))
  }

  expose(): IVisualizationEvent<T> {
    return this
  }

  dispose(): void {
    this.handlers.splice(0, this.handlers.length)
  }
}
