import store from '@/store'
import {
  PermissionCollaboratorViewModel,
  GrantPermissionDto,
  UpdatePermissionDto,
} from '@/types/FileExplorer/Permission'
import { IUser } from '@/types/User/IUser'

export class PermissionCollaboratorViewModelMap {
  public static toGrantPermissionDto(item: PermissionCollaboratorViewModel, message: string): GrantPermissionDto {
    const user: IUser = store.getters['user/getUserDetails']
    return {
      message,
      userId: user.id,
      itemId: item.itemId,
      targetUserId: item.grantedTo,
      role: item.role,
    }
  }

  public static toUpdatePermissionDto(item: PermissionCollaboratorViewModel): UpdatePermissionDto {
    return {
      role: item.role,
    }
  }
}
