import { secondsToMilliseconds } from 'date-fns'

import {
  DOSING_SPEED_DECIMAL_PLACES,
  H2_MACHINE_CONFIG_NAME,
  H3_ALPHA_MACHINE_CONFIG_NAME,
  H3_BETA_MACHINE_CONFIG_NAME,
  LENGTH_MM_DECIMAL_PLACES,
  PERCENTAGE_DECIMAL_PLACES,
  TEMPERATURE_CELSIUS_DEGREE_DECIMAL_PLACES,
} from '@/constants'
import { ProcessParameterFormViewModel } from './ProcessParameterFormViewModel'
import { isObject, isNil } from '@/utils/common'
import { AmpUnit } from '@/types/Common/AmpUnit'

const H2_LAYER_THICKNESS_MM_MIN = 0.05
const H2_LAYER_THICKNESS_MM_MAX = 0.2
const H2_Y_SHIFT_SIZE_MM_MIN = 0.001
const H2_Y_SHIFT_SIZE_MM_MAX = 5.0
const H2_BUILT_PLATE_TEMPERATURE_SET_POINT_C_MIN = 25
const H2_BUILT_PLATE_TEMPERATURE_SET_POINT_C_MAX = 90
const H2_JET_SPEED_MM_S_MIN = 50
const H2_JET_SPEED_MM_S_MAX = 200
const H2_WIPE_SPEED_MM_S_MIN = 100
const H2_WIPE_SPEED_MM_S_MAX = 1000
const H2_PURGE_TIME_S_MIN = 1
const H2_PURGE_TIME_S_MAX = 4
const H2_PURGE_PRESSURE_MM_H2O_MIN = 500
const H2_PURGE_PRESSURE_MM_H2O_MAX = 4000
const H2_DOSING_FACTOR_MM_MIN = 0
const H2_DOSING_FACTOR_MM_MAX = 0.5
const H2_MOVE_SPEED_SUPPLY_DEPART_MM_S_MIN = 5
const H2_MOVE_SPEED_SUPPLY_DEPART_MM_S_MAX = 200
const H2_MOVE_SPEED_BUILD_DEPART_MM_S_MIN = 5
const H2_MOVE_SPEED_BUILD_DEPART_MM_S_MAX = 200
const H2_ROLLER_SPEED_SUPPLY_LEVELING_DEPART_RPM_MIN = 100
const H2_ROLLER_SPEED_SUPPLY_LEVELING_DEPART_RPM_MAX = 3500
const H2_ROLLER_SPEED_RECOATER_LEVELING_DEPART_RPM_MIN = 100
const H2_ROLLER_SPEED_RECOATER_LEVELING_DEPART_RPM_MAX = 3500

const H3_LAYER_THICKNESS_MM_MIN = 0.001
const H3_LAYER_THICKNESS_MM_MAX = 50
const H3_Y_SHIFT_SIZE_MM_MIN = 0.001
const H3_Y_SHIFT_SIZE_MM_MAX = 9.0
const H3_TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MIN = 30
const H3_TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MAX = 40
const H3_BUILT_PLATE_TEMPERATURE_SET_POINT_C_MIN = 20
const H3_BUILT_PLATE_TEMPERATURE_SET_POINT_C_MAX = 100
const H3_POWDER_TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MIN = 20
const H3_POWDER_TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MAX = 100
const H3_JET_SPEED_MM_S_MIN = 50
const H3_JET_SPEED_MM_S_MAX = 300
const H3_WIPE_SPEED_MM_S_MIN = 100
const H3_WIPE_SPEED_MM_S_MAX = 1000
const H3_MOVE_SPEED_MM_S_MIN = 100
const H3_MOVE_SPEED_MM_S_MAX = 1000
const H3_PURGE_TIME_MS_MIN = 0
const H3_PURGE_TIME_MS_MAX = 10 * 1000
const H3_PURGE_PRESSURE_MM_H2O_MIN = 500
const H3_PURGE_PRESSURE_MM_H2O_MAX = 4000
const H3_DOSING_FACTOR_PCT_MIN = 100
const H3_DOSING_FACTOR_PCT_MAX = 500
const H3_DOSING_SUPPLY_ANGULAR_SPEED_RAD_S_MIN = 0.01
const H3_DOSING_SUPPLY_ANGULAR_SPEED_RAD_S_MAX = 0.2
const H3_DOSING_REFILL_ANGULAR_SPEED_RAD_S_MIN = 0.01
const H3_DOSING_REFILL_ANGULAR_SPEED_RAD_S_MAX = 0.2
const H3_FIRST_DOSE_FINAL_POSITION_MIN = -0.298
const H3_FIRST_DOSE_FINAL_POSITION_MAX = -0.012
const H3_MOVE_SPEED_SUPPLY_DEPART_MM_S_MIN = 10
const H3_MOVE_SPEED_SUPPLY_DEPART_MM_S_MAX = 1000
const H3_MOVE_SPEED_SUPPLY_RETURN_MM_S_MIN = 10
const H3_MOVE_SPEED_SUPPLY_RETURN_MM_S_MAX = 1000
const H3_MOVE_SPEED_BUILD_DEPART_MM_S_MIN = 10
const H3_MOVE_SPEED_BUILD_DEPART_MM_S_MAX = 1000
const H3_MOVE_SPEED_BUILD_RETURN_MM_S_MIN = 10
const H3_MOVE_SPEED_BUILD_RETURN_MM_S_MAX = 1000
const H3_ROLLER_SPEED_SUPPLY_LEVELING_DEPART_RPM_MIN = 0
const H3_ROLLER_SPEED_SUPPLY_LEVELING_DEPART_RPM_MAX = 4000
const H3_ROLLER_SPEED_SUPPLY_LEVELING_RETURN_RPM_MIN = 0
const H3_ROLLER_SPEED_SUPPLY_LEVELING_RETURN_RPM_MAX = 4000
const H3_ROLLER_SPEED_RECOATER_LEVELING_DEPART_RPM_MIN = 0
const H3_ROLLER_SPEED_RECOATER_LEVELING_DEPART_RPM_MAX = 4000
const H3_ROLLER_SPEED_RECOATER_LEVELING_RETURN_RPM_MIN = 0
const H3_ROLLER_SPEED_RECOATER_LEVELING_RETURN_RPM_MAX = 4000
const H3_LIFT_LAYER_SPLIT_PCT_MIN = -75
const H3_LIFT_LAYER_SPLIT_PCT_MAX = 100

const XY_LENGTH_MM_MIN = 0
const XY_LENGTH_MM_MAX = 500
const Z_LENGTH_MM_MIN = 0
const Z_LENGTH_MM_MAX = 500
const CONSTRAIN_THICKNESS_PCT_MIN = 0
const CONSTRAIN_THICKNESS_PCT_MAX = 100
const PIXEL_SOLID_PCT_MIN = 1
const PIXEL_SOLID_PCT_MAX = 3
const PIXEL_EDGE_PCT_MIN = 0
const PIXEL_EDGE_PCT_MAX = 3
const PIXEL_VOID_PCT_MIN = 0
const PIXEL_VOID_PCT_MAX = 3
const TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MIN = -20
const TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MAX = 50
const PURGE_FLOW_PRINTHEAD_SET_POINT_LITER_PER_MINUTE_MIN = 0
const PURGE_FLOW_PRINTHEAD_SET_POINT_LITER_PER_MINUTE_MAX = 500
const PURGE_FLOW_RECOAT_SET_POINT_LITER_PER_MINUTE_MIN = 0
const PURGE_FLOW_RECOAT_SET_POINT_LITER_PER_MINUTE_MAX = 500
const PURGE_FLOW_MOTION_AND_PRINTHEAD_PRESSURIZATION_SET_POINT_LITER_PER_MINUTE_MIN = 0
const PURGE_FLOW_MOTION_AND_PRINTHEAD_PRESSURIZATION_SET_POINT_LITER_PER_MINUTE_MAX = 500
const PURGE_FLOW_HMB_COOLING_SET_POINT_LITER_PER_MINUTE_MIN = 0
const PURGE_FLOW_HMB_COOLING_SET_POINT_LITER_PER_MINUTE_MAX = 500
const POWDER_TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MIN = 0
const POWDER_TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MAX = 100
const RECOAT_IR_INTENSITY_PCT_MIN = 0
const RECOAT_IR_INTENSITY_PCT_MAX = 100
const PRINT_IR_INTENSITY_PCT_MIN = 0
const PRINT_IR_INTENSITY_PCT_MAX = 100
const MOVE_SPEED_MM_S_MIN = 0
const MOVE_SPEED_MM_S_MAX = 1000
const REFILL_DWELL_TIME_MS_MIN = 0
const REFILL_DWELL_TIME_MS_MAX = 10 * 1000
const DOSING_SUPPLY_ANGULAR_SPEED_REV_S_MIN = 0
const DOSING_SUPPLY_ANGULAR_SPEED_REV_S_MAX = 0.2
const DOSING_REFILL_ANGULAR_SPEED_REV_S_MIN = 0
const DOSING_REFILL_ANGULAR_SPEED_REV_S_MAX = 0.2
const FIRST_DOSE_FINAL_POSITION_REV_MIN = -0.298
const FIRST_DOSE_FINAL_POSITION_REV_MAX = -0.0121
const RECOAT_DELAY_MS_MIN = 0
const RECOAT_DELAY_MS_MAX = 30 * 1000
const FOUNDATION_LAYERS_MIN = 1
const FOUNDATION_LAYERS_MAX = 100
const MOVE_SPEED_SUPPLY_RETURN_MM_S_MIN = 0
const MOVE_SPEED_SUPPLY_RETURN_MM_S_MAX = 1000
const MOVE_SPEED_BUILD_RETURN_MM_S_MIN = 0
const MOVE_SPEED_BUILD_RETURN_MM_S_MAX = 1000
const ROLLER_SPEED_SUPPLY_LEVELING_RETURN_RPM_MIN = 0
const ROLLER_SPEED_SUPPLY_LEVELING_RETURN_RPM_MAX = 4000
const ROLLER_SPEED_RECOATER_LEVELING_RETURN_RPM_MIN = 0
const ROLLER_SPEED_RECOATER_LEVELING_RETURN_RPM_MAX = 4000
const LIFT_LAYER_SPLIT_PCT_MIN = 0
const LIFT_LAYER_SPLIT_PCT_MAX = 100

type ValidationExcludedKeys =
  | 'constrainThickness'
  | 'dosingFactorUnit'
  | 'angularSpeedUnit'
  | 'dosingRefillHopperVibrator'
  | 'dosingRefillPistonVibrator'
type ValidationKeys = Omit<ProcessParameterFormViewModel, ValidationExcludedKeys>

export type ProcessParameterValidationRules = {
  [Property in keyof ValidationKeys]: object
}

const defaultValidationRules: ProcessParameterValidationRules = {
  attenXYLength: {
    required: true,
    between: {
      min: XY_LENGTH_MM_MIN,
      max: XY_LENGTH_MM_MAX,
      digits: LENGTH_MM_DECIMAL_PLACES,
      unit: AmpUnit.Millimeter,
    },
  },
  attenXPlus: { required: true },
  attenXMinus: { required: true },
  attenYPlus: { required: true },
  attenYMinus: { required: true },
  attenZPlus: { required: true },
  attenZMinus: { required: true },
  attenZLength: {
    required: true,
    between: { min: Z_LENGTH_MM_MIN, max: Z_LENGTH_MM_MAX, digits: LENGTH_MM_DECIMAL_PLACES, unit: AmpUnit.Millimeter },
  },
  constrainThicknessPerc: {
    between: {
      min: CONSTRAIN_THICKNESS_PCT_MIN,
      max: CONSTRAIN_THICKNESS_PCT_MAX,
      digits: LENGTH_MM_DECIMAL_PLACES,
      unit: AmpUnit.Percent,
    },
  },

  pixelLevelSolid: { required: true, between: { min: PIXEL_SOLID_PCT_MIN, max: PIXEL_SOLID_PCT_MAX } },
  pixelLevelEdge: { required: true, between: { min: PIXEL_EDGE_PCT_MIN, max: PIXEL_EDGE_PCT_MAX } },
  // https://ge.ent.box.com/file/1185005998341 GEAMPREQ-1413 - PixelLevel_void
  // https://jira-ebm.additive.ge.com/browse/YPVJZ-24565 comments - PixelLevel_void should be 0
  pixelLevelVoid: { required: true, between: { min: PIXEL_VOID_PCT_MIN, max: PIXEL_VOID_PCT_MIN } },

  shiftY: { required: true },
  shiftYSize: { required: true },

  temperatureSetPoint: {
    required: true,
    between: {
      min: TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MIN,
      max: TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MAX,
      digits: TEMPERATURE_CELSIUS_DEGREE_DECIMAL_PLACES,
      unit: AmpUnit.CelsiusDegree,
    },
  },
  purgeFlowPrintheadSetPoint: {
    required: true,
    between: {
      min: PURGE_FLOW_PRINTHEAD_SET_POINT_LITER_PER_MINUTE_MIN,
      max: PURGE_FLOW_PRINTHEAD_SET_POINT_LITER_PER_MINUTE_MAX,
      unit: AmpUnit.LiterPerMinute,
    },
  },
  purgeFlowRecoatSetPoint: {
    required: true,
    between: {
      min: PURGE_FLOW_RECOAT_SET_POINT_LITER_PER_MINUTE_MIN,
      max: PURGE_FLOW_RECOAT_SET_POINT_LITER_PER_MINUTE_MAX,
      unit: AmpUnit.LiterPerMinute,
    },
  },
  purgeFlowMotionAndPrintheadPressurizationSetPoint: {
    required: true,
    between: {
      min: PURGE_FLOW_MOTION_AND_PRINTHEAD_PRESSURIZATION_SET_POINT_LITER_PER_MINUTE_MIN,
      max: PURGE_FLOW_MOTION_AND_PRINTHEAD_PRESSURIZATION_SET_POINT_LITER_PER_MINUTE_MAX,
      unit: AmpUnit.LiterPerMinute,
    },
  },
  purgeFlowHMBCoolingSetPoint: {
    required: true,
    between: {
      min: PURGE_FLOW_HMB_COOLING_SET_POINT_LITER_PER_MINUTE_MIN,
      max: PURGE_FLOW_HMB_COOLING_SET_POINT_LITER_PER_MINUTE_MAX,
      unit: AmpUnit.LiterPerMinute,
    },
  },

  buildPlateTemperatureSetPoint: { required: true },
  powderTemperatureSetPoint: {
    required: true,
    between: {
      min: POWDER_TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MIN,
      max: POWDER_TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MAX,
      digits: TEMPERATURE_CELSIUS_DEGREE_DECIMAL_PLACES,
      unit: AmpUnit.CelsiusDegree,
    },
  },
  recoatIRIntensity: {
    required: true,
    between: {
      min: RECOAT_IR_INTENSITY_PCT_MIN,
      max: RECOAT_IR_INTENSITY_PCT_MAX,
      digits: PERCENTAGE_DECIMAL_PLACES,
      unit: AmpUnit.Percent,
    },
  },
  printIRIntensity: {
    required: true,
    between: {
      min: PRINT_IR_INTENSITY_PCT_MIN,
      max: PRINT_IR_INTENSITY_PCT_MAX,
      digits: PERCENTAGE_DECIMAL_PLACES,
      unit: AmpUnit.Percent,
    },
  },

  jetSpeed: { required: true },
  wipeSpeed: { required: true },
  moveSpeed: {
    required: true,
    between: { min: MOVE_SPEED_MM_S_MIN, max: MOVE_SPEED_MM_S_MAX, unit: AmpUnit.MillimeterPerSecond },
  },
  purgeTime: { required: true },
  purgePressure: { required: true },

  refillDwellTime: {
    required: true,
    between: { min: REFILL_DWELL_TIME_MS_MIN, max: REFILL_DWELL_TIME_MS_MAX, unit: AmpUnit.Millisecond },
  },
  dosingFactor: { required: true },
  dosingSupplyAngularSpeed: {
    required: true,
    between: {
      min: DOSING_SUPPLY_ANGULAR_SPEED_REV_S_MIN,
      max: DOSING_SUPPLY_ANGULAR_SPEED_REV_S_MAX,
      unit: AmpUnit.RevolutionsPerSecond,
    },
  },
  dosingRefillAngularSpeed: {
    required: true,
    between: {
      min: DOSING_REFILL_ANGULAR_SPEED_REV_S_MIN,
      max: DOSING_REFILL_ANGULAR_SPEED_REV_S_MAX,
      unit: AmpUnit.RevolutionsPerSecond,
    },
  },
  firstDoseFinalPosition: {
    required: true,
    between: {
      min: FIRST_DOSE_FINAL_POSITION_REV_MIN,
      max: FIRST_DOSE_FINAL_POSITION_REV_MAX,
      unit: AmpUnit.Revolutions,
    },
  },

  recoatDelay: {
    required: true,
    between: { min: RECOAT_DELAY_MS_MIN, max: RECOAT_DELAY_MS_MAX, unit: AmpUnit.Millisecond },
  },
  foundationLayers: { required: true, between: { min: FOUNDATION_LAYERS_MIN, max: FOUNDATION_LAYERS_MAX, digits: 0 } },
  moveSpeedSupplyDepart: { required: true },
  moveSpeedSupplyReturn: {
    required: true,
    between: {
      min: MOVE_SPEED_SUPPLY_RETURN_MM_S_MIN,
      max: MOVE_SPEED_SUPPLY_RETURN_MM_S_MAX,
      unit: AmpUnit.MillimeterPerSecond,
    },
  },
  moveSpeedBuildDepart: { required: true },
  moveSpeedBuildReturn: {
    required: true,
    between: {
      min: MOVE_SPEED_BUILD_RETURN_MM_S_MIN,
      max: MOVE_SPEED_BUILD_RETURN_MM_S_MAX,
      unit: AmpUnit.MillimeterPerSecond,
    },
  },
  rollerSpeedSupplyLevelingDepart: { required: true },
  rollerSpeedSupplyLevelingReturn: {
    required: true,
    between: {
      min: ROLLER_SPEED_SUPPLY_LEVELING_RETURN_RPM_MIN,
      max: ROLLER_SPEED_SUPPLY_LEVELING_RETURN_RPM_MAX,
      unit: AmpUnit.RevolutionsPerMinute,
    },
  },
  rollerSpeedRecoatLevelingDepart: { required: true },
  rollerSpeedRecoatLevelingReturn: {
    required: true,
    between: {
      min: ROLLER_SPEED_RECOATER_LEVELING_RETURN_RPM_MIN,
      max: ROLLER_SPEED_RECOATER_LEVELING_RETURN_RPM_MAX,
      unit: AmpUnit.RevolutionsPerMinute,
    },
  },
  liftLayerSplit: {
    required: true,
    between: {
      min: LIFT_LAYER_SPLIT_PCT_MIN,
      max: LIFT_LAYER_SPLIT_PCT_MAX,
      digits: PERCENTAGE_DECIMAL_PLACES,
      unit: AmpUnit.Percent,
    },
  },

  layerThickness: { required: true },
}

/**
 * Fields for which there are no corresponding keys in the JSON file (H2, H3) are optional
 */

const h2ValidationRules: Partial<ProcessParameterValidationRules> = {
  layerThickness: {
    required: true,
    between: {
      min: H2_LAYER_THICKNESS_MM_MIN,
      max: H2_LAYER_THICKNESS_MM_MAX,
      unit: AmpUnit.Millimeter,
      digits: LENGTH_MM_DECIMAL_PLACES,
    },
  },
  shiftYSize: {
    required: true,
    between: {
      min: H2_Y_SHIFT_SIZE_MM_MIN,
      max: H2_Y_SHIFT_SIZE_MM_MAX,
      digits: LENGTH_MM_DECIMAL_PLACES,
      unit: AmpUnit.Millimeter,
    },
  },
  temperatureSetPoint: {
    ...defaultValidationRules.temperatureSetPoint,
    required: false,
  },
  purgeFlowPrintheadSetPoint: { ...defaultValidationRules.purgeFlowPrintheadSetPoint, required: false },
  purgeFlowRecoatSetPoint: { ...defaultValidationRules.purgeFlowRecoatSetPoint, required: false },
  purgeFlowMotionAndPrintheadPressurizationSetPoint: {
    ...defaultValidationRules.purgeFlowMotionAndPrintheadPressurizationSetPoint,
    required: false,
  },
  purgeFlowHMBCoolingSetPoint: { ...defaultValidationRules.purgeFlowHMBCoolingSetPoint, required: false },
  buildPlateTemperatureSetPoint: {
    required: true,
    between: {
      min: H2_BUILT_PLATE_TEMPERATURE_SET_POINT_C_MIN,
      max: H2_BUILT_PLATE_TEMPERATURE_SET_POINT_C_MAX,
      digits: TEMPERATURE_CELSIUS_DEGREE_DECIMAL_PLACES,
      unit: AmpUnit.CelsiusDegree,
    },
  },
  jetSpeed: {
    required: true,
    between: { min: H2_JET_SPEED_MM_S_MIN, max: H2_JET_SPEED_MM_S_MAX, unit: AmpUnit.MillimeterPerSecond },
  },
  wipeSpeed: {
    required: true,
    between: { min: H2_WIPE_SPEED_MM_S_MIN, max: H2_WIPE_SPEED_MM_S_MAX, unit: AmpUnit.MillimeterPerSecond },
  },
  purgeTime: {
    required: true,
    between: { min: H2_PURGE_TIME_S_MIN, max: H2_PURGE_TIME_S_MAX, unit: AmpUnit.Second },
  },
  purgePressure: {
    required: false,
    between: { min: H2_PURGE_PRESSURE_MM_H2O_MIN, max: H2_PURGE_PRESSURE_MM_H2O_MAX, unit: AmpUnit.MillimeterOfWater },
  },
  refillDwellTime: { ...defaultValidationRules.refillDwellTime, required: false },
  dosingFactor: {
    required: true,
    between: {
      min: H2_DOSING_FACTOR_MM_MIN,
      max: H2_DOSING_FACTOR_MM_MAX,
      digits: LENGTH_MM_DECIMAL_PLACES,
      unit: AmpUnit.Millimeter,
    },
  },
  dosingSupplyAngularSpeed: { ...defaultValidationRules.dosingSupplyAngularSpeed, required: false },
  dosingRefillAngularSpeed: { ...defaultValidationRules.dosingRefillAngularSpeed, required: false },
  firstDoseFinalPosition: { ...defaultValidationRules.firstDoseFinalPosition, required: false },
  moveSpeedSupplyDepart: {
    required: false,
    between: {
      min: H2_MOVE_SPEED_SUPPLY_DEPART_MM_S_MIN,
      max: H2_MOVE_SPEED_SUPPLY_DEPART_MM_S_MAX,
      unit: AmpUnit.MillimeterPerSecond,
    },
  },
  moveSpeedSupplyReturn: { ...defaultValidationRules.moveSpeedSupplyReturn, required: false },
  moveSpeedBuildDepart: {
    required: false,
    between: {
      min: H2_MOVE_SPEED_BUILD_DEPART_MM_S_MIN,
      max: H2_MOVE_SPEED_BUILD_DEPART_MM_S_MAX,
      unit: AmpUnit.MillimeterPerSecond,
    },
  },
  moveSpeedBuildReturn: { ...defaultValidationRules.moveSpeedBuildReturn, required: false },
  rollerSpeedSupplyLevelingDepart: {
    required: true,
    between: {
      min: H2_ROLLER_SPEED_SUPPLY_LEVELING_DEPART_RPM_MIN,
      max: H2_ROLLER_SPEED_SUPPLY_LEVELING_DEPART_RPM_MAX,
      unit: AmpUnit.RevolutionsPerMinute,
    },
  },
  rollerSpeedSupplyLevelingReturn: { ...defaultValidationRules.rollerSpeedSupplyLevelingReturn, required: false },
  rollerSpeedRecoatLevelingDepart: {
    required: true,
    between: {
      min: H2_ROLLER_SPEED_RECOATER_LEVELING_DEPART_RPM_MIN,
      max: H2_ROLLER_SPEED_RECOATER_LEVELING_DEPART_RPM_MAX,
      unit: AmpUnit.RevolutionsPerMinute,
    },
  },
  rollerSpeedRecoatLevelingReturn: { ...defaultValidationRules.rollerSpeedRecoatLevelingReturn, required: false },
  liftLayerSplit: { ...defaultValidationRules.liftLayerSplit, required: false },
}
const h3ValidationRules: Partial<ProcessParameterValidationRules> = {
  layerThickness: { required: true, between: { min: H3_LAYER_THICKNESS_MM_MIN, max: H3_LAYER_THICKNESS_MM_MAX } },
  shiftYSize: { required: true, between: { min: H3_Y_SHIFT_SIZE_MM_MIN, max: H3_Y_SHIFT_SIZE_MM_MAX } },
  temperatureSetPoint: {
    required: true,
    between: {
      min: H3_TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MIN,
      max: H3_TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MAX,
      digits: 2,
      unit: AmpUnit.CelsiusDegree,
    },
  },
  purgeFlowPrintheadSetPoint: {
    required: true,
    between: {
      min: PURGE_FLOW_PRINTHEAD_SET_POINT_LITER_PER_MINUTE_MIN,
      max: PURGE_FLOW_PRINTHEAD_SET_POINT_LITER_PER_MINUTE_MAX,
      digits: 0,
      unit: AmpUnit.LiterPerMinute,
    },
  },
  purgeFlowRecoatSetPoint: {
    required: true,
    between: {
      min: PURGE_FLOW_RECOAT_SET_POINT_LITER_PER_MINUTE_MIN,
      max: PURGE_FLOW_RECOAT_SET_POINT_LITER_PER_MINUTE_MAX,
      digits: 0,
      unit: AmpUnit.LiterPerMinute,
    },
  },
  purgeFlowMotionAndPrintheadPressurizationSetPoint: {
    required: true,
    between: {
      min: PURGE_FLOW_MOTION_AND_PRINTHEAD_PRESSURIZATION_SET_POINT_LITER_PER_MINUTE_MIN,
      max: PURGE_FLOW_MOTION_AND_PRINTHEAD_PRESSURIZATION_SET_POINT_LITER_PER_MINUTE_MAX,
      digits: 0,
      unit: AmpUnit.LiterPerMinute,
    },
  },
  purgeFlowHMBCoolingSetPoint: {
    required: true,
    between: {
      min: PURGE_FLOW_HMB_COOLING_SET_POINT_LITER_PER_MINUTE_MIN,
      max: PURGE_FLOW_HMB_COOLING_SET_POINT_LITER_PER_MINUTE_MAX,
      digits: 0,
      unit: AmpUnit.LiterPerMinute,
    },
  },
  buildPlateTemperatureSetPoint: {
    required: true,
    between: {
      min: H3_BUILT_PLATE_TEMPERATURE_SET_POINT_C_MIN,
      max: H3_BUILT_PLATE_TEMPERATURE_SET_POINT_C_MAX,
      digits: 2,
      unit: AmpUnit.CelsiusDegree,
    },
  },
  powderTemperatureSetPoint: {
    required: true,
    between: {
      min: H3_POWDER_TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MIN,
      max: H3_POWDER_TEMPERATURE_SET_POINT_CELSIUS_DEGREE_MAX,
      digits: 2,
      unit: AmpUnit.CelsiusDegree,
    },
  },
  recoatIRIntensity: {
    required: true,
    between: {
      min: RECOAT_IR_INTENSITY_PCT_MIN,
      max: RECOAT_IR_INTENSITY_PCT_MAX,
      digits: 0,
      unit: AmpUnit.Percent,
    },
  },
  printIRIntensity: {
    required: true,
    between: {
      min: PRINT_IR_INTENSITY_PCT_MIN,
      max: PRINT_IR_INTENSITY_PCT_MAX,
      digits: 0,
      unit: AmpUnit.Percent,
    },
  },
  jetSpeed: {
    required: true,
    between: { min: H3_JET_SPEED_MM_S_MIN, max: H3_JET_SPEED_MM_S_MAX, digits: 0, unit: AmpUnit.MillimeterPerSecond },
  },
  wipeSpeed: {
    required: true,
    between: { min: H3_WIPE_SPEED_MM_S_MIN, max: H3_WIPE_SPEED_MM_S_MAX, digits: 0, unit: AmpUnit.MillimeterPerSecond },
  },
  moveSpeed: {
    required: true,
    between: { min: H3_MOVE_SPEED_MM_S_MIN, max: H3_MOVE_SPEED_MM_S_MAX, digits: 0, unit: AmpUnit.MillimeterPerSecond },
  },
  purgeTime: {
    required: true,
    between: { min: H3_PURGE_TIME_MS_MIN, max: H3_PURGE_TIME_MS_MAX, digits: 0, unit: AmpUnit.Millisecond },
  },
  purgePressure: {
    required: true,
    between: {
      min: H3_PURGE_PRESSURE_MM_H2O_MIN,
      max: H3_PURGE_PRESSURE_MM_H2O_MAX,
      digits: 0,
      unit: AmpUnit.MillimeterOfWater,
    },
  },
  refillDwellTime: {
    required: true,
    between: { min: REFILL_DWELL_TIME_MS_MIN, max: REFILL_DWELL_TIME_MS_MAX, digits: 0, unit: AmpUnit.Millisecond },
  },
  dosingFactor: {
    required: true,
    between: { min: H3_DOSING_FACTOR_PCT_MIN, max: H3_DOSING_FACTOR_PCT_MAX, digits: 0, unit: AmpUnit.Percent },
  },
  dosingSupplyAngularSpeed: {
    required: true,
    between: {
      min: H3_DOSING_SUPPLY_ANGULAR_SPEED_RAD_S_MIN,
      max: H3_DOSING_SUPPLY_ANGULAR_SPEED_RAD_S_MAX,
      digits: DOSING_SPEED_DECIMAL_PLACES,
      unit: AmpUnit.RadiansPerSecond,
    },
  },
  dosingRefillAngularSpeed: {
    required: true,
    between: {
      min: H3_DOSING_REFILL_ANGULAR_SPEED_RAD_S_MIN,
      max: H3_DOSING_REFILL_ANGULAR_SPEED_RAD_S_MAX,
      digits: DOSING_SPEED_DECIMAL_PLACES,
      unit: AmpUnit.RadiansPerSecond,
    },
  },
  firstDoseFinalPosition: {
    required: true,
    between: {
      min: H3_FIRST_DOSE_FINAL_POSITION_MIN,
      digits: 3,
      max: H3_FIRST_DOSE_FINAL_POSITION_MAX,
    },
  },
  recoatDelay: {
    required: true,
    between: { min: RECOAT_DELAY_MS_MIN, max: RECOAT_DELAY_MS_MAX, digits: 0, unit: AmpUnit.Millisecond },
  },
  moveSpeedSupplyDepart: {
    required: true,
    between: {
      min: H3_MOVE_SPEED_SUPPLY_DEPART_MM_S_MIN,
      max: H3_MOVE_SPEED_SUPPLY_DEPART_MM_S_MAX,
      digits: 0,
      unit: AmpUnit.MillimeterPerSecond,
    },
  },
  moveSpeedSupplyReturn: {
    required: true,
    between: {
      min: H3_MOVE_SPEED_SUPPLY_RETURN_MM_S_MIN,
      max: H3_MOVE_SPEED_SUPPLY_RETURN_MM_S_MAX,
      digits: 0,
      unit: AmpUnit.MillimeterPerSecond,
    },
  },
  moveSpeedBuildDepart: {
    required: true,
    between: {
      min: H3_MOVE_SPEED_BUILD_DEPART_MM_S_MIN,
      max: H3_MOVE_SPEED_BUILD_DEPART_MM_S_MAX,
      digits: 0,
      unit: AmpUnit.MillimeterPerSecond,
    },
  },
  moveSpeedBuildReturn: {
    required: true,
    between: {
      min: H3_MOVE_SPEED_BUILD_RETURN_MM_S_MIN,
      max: H3_MOVE_SPEED_BUILD_RETURN_MM_S_MAX,
      digits: 0,
      unit: AmpUnit.MillimeterPerSecond,
    },
  },
  rollerSpeedSupplyLevelingDepart: {
    required: true,
    between: {
      min: H3_ROLLER_SPEED_SUPPLY_LEVELING_DEPART_RPM_MIN,
      max: H3_ROLLER_SPEED_SUPPLY_LEVELING_DEPART_RPM_MAX,
      digits: 0,
      unit: AmpUnit.RevolutionsPerMinute,
    },
  },
  rollerSpeedSupplyLevelingReturn: {
    required: true,
    between: {
      min: H3_ROLLER_SPEED_SUPPLY_LEVELING_RETURN_RPM_MIN,
      max: H3_ROLLER_SPEED_SUPPLY_LEVELING_RETURN_RPM_MAX,
      digits: 0,
      unit: AmpUnit.RevolutionsPerMinute,
    },
  },
  rollerSpeedRecoatLevelingDepart: {
    required: true,
    between: {
      min: H3_ROLLER_SPEED_RECOATER_LEVELING_DEPART_RPM_MIN,
      max: H3_ROLLER_SPEED_RECOATER_LEVELING_DEPART_RPM_MAX,
      digits: 0,
      unit: AmpUnit.RevolutionsPerMinute,
    },
  },
  rollerSpeedRecoatLevelingReturn: {
    required: true,
    between: {
      min: H3_ROLLER_SPEED_RECOATER_LEVELING_RETURN_RPM_MIN,
      max: H3_ROLLER_SPEED_RECOATER_LEVELING_RETURN_RPM_MAX,
      digits: 0,
      unit: AmpUnit.RevolutionsPerMinute,
    },
  },
  liftLayerSplit: {
    required: true,
    between: {
      min: H3_LIFT_LAYER_SPLIT_PCT_MIN,
      max: H3_LIFT_LAYER_SPLIT_PCT_MAX,
      digits: 0,
      unit: AmpUnit.Percent,
    },
  },
}

const mapValidationRulesByMachineConfigName = new Map()
mapValidationRulesByMachineConfigName.set(H2_MACHINE_CONFIG_NAME, h2ValidationRules)
mapValidationRulesByMachineConfigName.set(H3_ALPHA_MACHINE_CONFIG_NAME, h3ValidationRules)
mapValidationRulesByMachineConfigName.set(H3_BETA_MACHINE_CONFIG_NAME, h3ValidationRules)

export class ProcessParameterFormValidation {
  public static getValidationRules(machineConfigName: string): ProcessParameterValidationRules {
    const rules = mapValidationRulesByMachineConfigName.get(machineConfigName)

    if (!rules) {
      throw new Error(`Unsupported machine config name: ${machineConfigName}`)
    }

    return { ...defaultValidationRules, ...rules }
  }

  public static getRangeByField(field) {
    if (isObject(field)) {
      const { between: betweenRule } = field

      if (!betweenRule) {
        return null
      }

      const { min, max } = betweenRule

      if (isNil(min) || isNil(max)) {
        return null
      }

      return { min, max }
    }

    return null
  }
}

export const edgePixelLevelLimits = {
  min: PIXEL_EDGE_PCT_MIN,
  max: PIXEL_EDGE_PCT_MAX,
}

export const solidPixelLevelLimits = {
  min: PIXEL_SOLID_PCT_MIN,
  max: PIXEL_SOLID_PCT_MAX,
}
