import Vue from 'vue'
import { extend, ValidationObserver, ValidationProvider, localize, setInteractionMode } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'

import { DEFAULT_VALIDATION_MODE } from '@/constants'

localize('en', en)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

setInteractionMode(DEFAULT_VALIDATION_MODE)

extend('great_than', {
  validate: (value: number, args: { minValue: number; digits?: number }) => {
    return +value > +args.minValue
  },
  params: ['minValue', 'unit', 'digits'],
  message: (_, placeholders: { minValue: number; _minValue_?: string; unit?: string; digits?: number }) => {
    const { minValue, _minValue_, unit, digits } = placeholders
    let minValueAsNumber = minValue
    let minValueAsString: string

    // For cross field validation
    if (_minValue_) {
      minValueAsNumber = Number(_minValue_)
    }

    if (digits === undefined) {
      minValueAsString = minValueAsNumber.toString()
    } else {
      minValueAsString =
        digits && (!Number.isInteger(minValueAsNumber) || minValueAsNumber === 0)
          ? minValueAsNumber.toFixed(digits)
          : minValueAsNumber.toFixed(0)
    }

    const unitStr = unit ? ` ${unit}` : ''
    return `Enter value larger than ${minValueAsString}${unitStr}`
  },
})
