import { IGeometryProperties } from '@/types/BuildPlans/IBuildPlan'
import { Part } from '@/visualization/models/DataModel'
import { IInsightsCount } from '@/types/Common/Insights'
import { IHighlightableDefect, IPartInsight } from '@/types/Parts/IPartInsight'

export interface IPartPreview {
  geometryProperties?: IGeometryProperties
  configFile?: { parts: Part[] }
  insightTool?: {
    insights: IPartInsight[]
    insightsCount: IInsightsCount
    activeInsightDefectGroup: number
    activeInsightDefects: number[]
  }
  hoveredDefect?: IHighlightableDefect
  selectedDefect?: IHighlightableDefect
}

export const partPreviewDefaultState: IPartPreview = {
  geometryProperties: null,
  configFile: null,
  insightTool: {
    insights: [],
    insightsCount: { errors: 0, warnings: 0 },
    activeInsightDefectGroup: null,
    activeInsightDefects: [],
  },
  selectedDefect: {
    type: null,
    indices: [],
  },
  hoveredDefect: {
    type: null,
    indices: [],
  },
}
