
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { RouterNames } from '@/router'
import { TableHeader } from '@/types/Sites/TenantAdminContentTableHeader'
import { TenantAdminContentTableMixin } from '@/components/layout/admin/sites/mixins/TenantAdminContentTableMixin'
import TenantAdminHeader from '@/components/layout/admin/sites/contentTable/TenantAdminHeader.vue'
import TenantAdminRow from '@/components/layout/admin/sites/contentTable/TenantAdminRow.vue'
import { SortOrders } from '@/types/SortModes'
import { TenantAdminPrintStrategy } from '@/types/Sites/TenantAdminPrintStrategy'
import { PrintStrategyStatus } from '@/types/Sites/PrintStrategyStatus'
import { TableCell } from '@/types/Sites/TenantAdminContentTableCell'
import SimulationReady from '@/components/layout/admin/sites/SimulationReady.vue'
import ConfirmableSelector from '@/components/layout/admin/sites/ConfirmableSelector.vue'
import StoresNamespaces from '@/store/namespaces'
import {
  PrintStrategyInfo,
  MachineMaterial,
  MachineType,
  PrintStrategy,
  MachineTypeResponse,
  MachineTypeDto,
} from '@/types/Sites/Site'
import { handleAPIError } from '@/api/common'
import DeletePrintStrategyModal from '@/components/layout/admin/printStrategy/DeletePrintStrategyModal.vue'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component({
  components: { DeletePrintStrategyModal, TenantAdminHeader, TenantAdminRow },
})
export default class TenantAdminPrintStrategyList extends TenantAdminContentTableMixin {
  @sitesStore.Mutation setPrintStrategies: (printStrategies: PrintStrategy[]) => void
  @sitesStore.Mutation removePrintStrategy: (printStrategyId: number) => void
  @sitesStore.Getter getSelectedMaterial: MachineMaterial

  @sitesStore.Action updatePrintStrategyStatus: (payload: {
    printStrategyId: number
    siteId: string
    status: PrintStrategyStatus
  }) => Promise<void>
  @sitesStore.Action fetchPrintStrategiesBySiteMachineMaterial: (payload: {
    siteId: number
    machineConfigId: number
    materialId: number
    params?: object
  }) => Promise<PrintStrategy[]>
  @sitesStore.Action createPrintStrategy: (payload: {
    machineConfigId: number
    materialId: number
  }) => Promise<PrintStrategyInfo>
  @sitesStore.Action fetchMachineTypeById: (typeId: string) => Promise<MachineTypeResponse>
  @sitesStore.Action fetchMaterialById: (materialId: string) => Promise<MachineMaterial>
  @sitesStore.Action copyPrintStrategy: (printStrategyId: number) => Promise<PrintStrategyInfo>
  @sitesStore.Action deletePrintStrategy: (printStrategyId: number) => Promise<number>
  @sitesStore.Action isPrintStrategyUsedByBuildPlan: (printStrategyId: number) => Promise<boolean>

  @sitesStore.Getter getPrintStrategies: PrintStrategy[]
  @sitesStore.Getter getSelectedMachineType: MachineType
  @sitesStore.Getter isLoading: boolean

  $refs!: {
    deleteModal: InstanceType<typeof DeletePrintStrategyModal>
  }
  siteId: string

  async mounted() {
    await this.fetchPrintStrategies()
  }

  setupHeaders() {
    this.headers = [
      {
        headerName: this.$root.$t('tenantAdminContentTableHeaders.printStrategyList.name').toString(),
        cols: 3,
        isSortCriteria: true,
        sortOrder: SortOrders.Ascending,
      },
      {
        headerName: this.$root.$t('tenantAdminContentTableHeaders.printStrategyList.processParameter').toString(),
        cols: 3,
        isSortCriteria: false,
        sortOrder: null,
      },
      {
        headerName: this.$root.$t('tenantAdminContentTableHeaders.printStrategyList.simulationReady').toString(),
        cols: 2,
        isSortCriteria: false,
        sortOrder: null,
      },
      {
        headerName: this.$root.$t('tenantAdminContentTableHeaders.printStrategyList.status').toString(),
        cols: 3,
        isSortCriteria: false,
        sortOrder: null,
      },
    ]
  }

  setupRows() {
    this.rows = this.generateRows(this.printStrategiesToTenantAdminPrintStrategies())

    const sortHeader = this.headers.find((h) => h.isSortCriteria)
    this.orderRows(sortHeader || this.headers[0])
  }

  generateColumns(strategy: TenantAdminPrintStrategy): TableCell[] {
    const { siteId, machineTypeId, materialId } = this.$route.params

    return [
      {
        // Name
        value: strategy.name,
        text: strategy.name,
        textLink: {
          name: RouterNames.PrintStrategy,
          params: {
            machineTypeId,
            materialId,
            siteId,
            printStrategyId: strategy.printStrategyId.toString(),
          },
        },
        textIcon: 'mdi-download',
        cols: 3,
        cellName: this.$root.$t('tenantAdminContentTableHeaders.printStrategyList.name').toString(),
      },
      {
        // Process parameter
        value: strategy.processParameterName,
        bottomDescription: this.$root.$t('tenantAdminContentTableHeaders.printStrategyList.partParamList', {
          amount: strategy.partParametersCount,
        }),
        textLink: {
          name: RouterNames.PrintStrategy,
          params: {
            machineTypeId,
            materialId,
            siteId,
            printStrategyId: strategy.printStrategyId.toString(),
          },
        },
        bottomDescriptionIcon: 'mdi-open-in-new',
        text: strategy.processParameterName,
        cols: 3,
        cellName: this.$root.$t('tenantAdminContentTableHeaders.printStrategyList.processParameter').toString(),
      },
      {
        // Simulation-Ready
        value: strategy.simulationReady,
        component: SimulationReady,
        componentOptions: {},
        cols: 2,
        cellName: this.$root.$t('tenantAdminContentTableHeaders.printStrategyList.simulationReady').toString(),
      },
      {
        // Status
        value: strategy.status,
        cellItemId: strategy.printStrategyId,
        componentOptions: {
          options: [
            { text: 'Active', value: PrintStrategyStatus.Active, color: 'green' },
            { text: 'Inactive', value: PrintStrategyStatus.Inactive, color: 'grey' },
          ],
          zIndex: 9,
        },
        component: ConfirmableSelector,
        cellName: this.$root.$t('tenantAdminContentTableHeaders.printStrategyList.status').toString(),
        cols: 2,
        editable: strategy.status !== PrintStrategyStatus.Incomplete,
        callbacks: {
          submit: this.updateStatus,
        },
      },
      {
        // Controls
        value: null,
        cellItemId: strategy.printStrategyId,
        isControlsCell: true,
        cols: 1,
        callbacks: {
          copy: this.onCopyPrintStrategy,
          remove: this.onRemovePrintStrategy,
        },
      },
    ]
  }

  sortChanged(header: TableHeader) {
    this.onSortChanged(header)
  }

  async addPrintStrategy() {
    const printStrategyToEdit = await this.createPrintStrategy({
      machineConfigId: this.getSelectedMachineType.machineConfigId,
      materialId: this.getSelectedMaterial.materialId,
    })

    if (printStrategyToEdit) {
      const route = {
        name: RouterNames.PrintStrategy,
        params: {
          printStrategyId: printStrategyToEdit.id,
        },
      }

      // @ts-ignore
      return this.$router.safePush(route)
    }

    handleAPIError(`Failed to edit print strategy`)
  }

  async updateStatus(status: PrintStrategyStatus, printStrategyId: number) {
    await this.updatePrintStrategyStatus({
      printStrategyId,
      status,
      siteId: this.siteId,
    })
  }

  async onCopyPrintStrategy(printStrategyId: number) {
    const printStrategyToEdit = await this.copyPrintStrategy(printStrategyId)

    if (printStrategyToEdit) {
      const route = {
        name: RouterNames.PrintStrategy,
        params: {
          printStrategyId: printStrategyToEdit.id,
        },
      }

      // @ts-ignore
      return this.$router.safePush(route)
    }
  }

  async onRemovePrintStrategy(printStrategyId: number) {
    const foundPrintStrategy = this.getPrintStrategies.find((ps) => ps.printStrategyId === printStrategyId)

    if (foundPrintStrategy) {
      let confirm: boolean

      // Print strategy with 'Incomplete' status should be deleted without confirmation
      if (foundPrintStrategy.status === PrintStrategyStatus.Incomplete) {
        await this.deletePrintStrategy(printStrategyId)
        confirm = true
      } else {
        confirm = await this.$refs.deleteModal.open(printStrategyId)
      }

      if (confirm) {
        this.removePrintStrategy(printStrategyId)
        this.setupRows()
      }
    }
  }

  async fetchPrintStrategies() {
    const { siteId, machineTypeId, materialId } = this.$route.params
    this.siteId = siteId
    let selectedMachineType = this.getSelectedMachineType
    let selectedMaterial = this.getSelectedMaterial
    if (!selectedMachineType) {
      selectedMachineType = new MachineTypeDto(await this.fetchMachineTypeById(machineTypeId)) as MachineType
    }
    if (!selectedMaterial) {
      selectedMaterial = await this.fetchMaterialById(materialId)
    }

    const printStrategies = await this.fetchPrintStrategiesBySiteMachineMaterial({
      siteId: +siteId,
      machineConfigId: selectedMachineType.machineConfigId,
      materialId: selectedMaterial.materialId,
    })

    this.setPrintStrategies(printStrategies)

    if (!printStrategies.length) return

    this.setupHeaders()
    this.setupRows()
  }

  private printStrategiesToTenantAdminPrintStrategies(): TenantAdminPrintStrategy[] {
    if (this.getPrintStrategies) {
      return this.getPrintStrategies.map((printStrategy) => {
        return {
          id: printStrategy.printStrategyId,
          name: printStrategy.name,
          downloadLink: printStrategy.processParameterName,
          processParameter: printStrategy.processParameterName,
          partParametersCount: printStrategy.partParameters.length,
          simulationReady: printStrategy.simulationReadyStatus,
          status: printStrategy.status,

          printStrategyId: printStrategy.printStrategyId,
          processParameterName: printStrategy.processParameterName,
          partParameters: printStrategy.partParameters,
        }
      })
    }
    return []
  }
}
