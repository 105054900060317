/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Engine } from '@babylonjs/core/Engines/engine'
import { BoundingBoxRenderer } from '@babylonjs/core/Rendering/boundingBoxRenderer'
// tslint:disable-next-line:no-duplicate-imports
import '@babylonjs/core/Rendering/boundingBoxRenderer' // side-effect for Scene
import '@babylonjs/core/Rendering/edgesRenderer' // side-effect for Slice polylines
import { IDisposable, Scene, ScenePerformancePriority } from '@babylonjs/core/scene'
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight'
import { PointLight } from '@babylonjs/core/Lights/pointLight'
import { Axis, Color3, Color4, Epsilon, Matrix, Quaternion, Space, Vector3, Viewport } from '@babylonjs/core/Maths'
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { CustomMaterial } from '@babylonjs/materials/custom'
import { AbstractMesh, InstancedMesh, Mesh, TransformNode } from '@babylonjs/core/Meshes'
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder'
import { GridMaterial } from '@babylonjs/materials/grid/gridMaterial'
import { Animation } from '@babylonjs/core/Animations/animation'
import '@babylonjs/core/Animations/animatable'
import '@babylonjs/core/Misc/screenshotTools'
import { Tools } from '@babylonjs/core/Misc/tools'
import { BoundingBox, BoundingBox2D, Part } from '@/visualization/models/DataModel'
import { ISelectableNode, SelectionManager } from '@/visualization/rendering/SelectionManager'
import { ModelManager } from '@/visualization/rendering/ModelManager'
import { CommandManager } from '@/visualization/rendering/CommandManager'
import { CrossSectionManager } from '@/visualization/rendering/CrossSectionManager'
import { SlicerManager } from '@/visualization/rendering/SlicerManager'
import { OrthoCamera } from '@/visualization/components/OrthoCamera'
import { AdvancedAxesViewer } from '@/visualization/components/AdvancedAxesViewer'
import {
  ConstrainViewMode,
  CrossSectionViewMode,
  DeviationViewMode,
  DuplicateViewMode,
  IViewMode,
  LayoutViewMode,
  MarkingViewMode,
  ClearanceToolViewMode,
  MoveViewMode,
  NestingViewMode,
  OrientationViewMode,
  PartLayoutMode,
  PartViewMode,
  PreviewMode,
  PrintOrderPreviewMode,
  PrintViewMode,
  PublishViewMode,
  ReplaceViewMode,
  ResultsViewMode,
  RotateViewMode,
  ScaleViewMode,
  SimulateViewMode,
  SlicingViewMode,
  SupportViewMode,
  TransferPropsViewMode,
  IBCPlanViewMode,
} from '@/visualization/infrastructure/ViewMode'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { OuterEvents } from '@/visualization/types/Common'
import {
  BuildPlanItemOverhang,
  BuildPlanItemSupport,
  GeometryType,
  IBuildPlan,
  IBuildPlanItem,
  IIBCDisplayToolbarState,
  SelectionUnit,
  Visibility,
} from '@/types/BuildPlans/IBuildPlan'
import { BuildPlateManager } from '@/visualization/rendering/BuildPlateManager'
import { OBBTree } from '@/visualization/OBBTree'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import {
  ACCURACY,
  BACKQUOTE_CODE,
  BOX_MATERIAL_NAME,
  BRIDGING_MATERIAL,
  BRIDGING_TRANSPARENT,
  BUILD_PLATE_GRID_RATIO,
  BUILD_PLATE_MATERIAL_NAME,
  BVH_BOX,
  CENTER_OF_GRAVITY_MATERIAL_NAME,
  COMPENSATED_MATERIAL,
  CROSS_SECTION_MESH_MATERIAL,
  CROSS_SECTION_PLANE_MATERIAL,
  CROSS_SECTION_SEMI_TRANSPARENT_MESH_MATERIAL,
  CUTOUT_MATERIAL,
  DEFAULT_COLOR,
  DEFAULT_MATERIAL_NAME,
  DEFAULT_Y_SHIFT_SIZE,
  DEFECT_INSIDE_MATERIAL,
  DEFECT_MATERIAL,
  ERROR_BOX_MATERIAL_NAME,
  FAILED_DUPLCIATE_MATERIAL,
  FOOTPRINT_MATERIAL_NAME,
  FRONT_INDICATOR_MATERIAL_NAME,
  GAS_FLOW_ARROW_MATERIAL_NAME,
  GREEN_COMPENSATED_MATERIAL,
  GREEN_NOMINAL_MATERIAL,
  GROUND_BOX_NAME,
  HIGHLIGHT_ERROR_MATERIAL,
  HIGHLIGHT_INFO_MATERIAL,
  HIGHLIGHT_MATERIAL_NAME,
  HIGHLIGHT_WARNING_MATERIAL,
  INSIDE_MESH_NAME,
  KEYBOARD_KEYS,
  LABEL_INSIDE_MATERIAL,
  LABEL_MATERIAL_NAME,
  LABEL_SENSITIVE_ZONE,
  LABEL_SENSITIVE_ZONE_CACHED,
  LINE_SUPPORT,
  MAIN_CAMERA_NAME,
  MAX,
  MESH_INSIDE_MATERIAL_NAME,
  MESH_RENDERING_GROUP_ID,
  MESHING_MATERIAL,
  MIN,
  MIN_CANVAS_SIZE,
  MouseButtons,
  NO_SPECULAR_MATERIAL_NAME,
  NOMINAL_GEOMETRY_MATERIAL,
  NUMPAD_ADD_CODE,
  NUMPAD_SUBTRACT_CODE,
  OVERHANG_COLOR,
  OVERHANG_ERROR_MATERIAL,
  OVERHANG_HOVER_MATERIAL,
  OVERHANG_INSIDE_MATERIAL,
  OVERHANG_INSIDE_MESH_NAME,
  OVERHANG_MATERIAL,
  OVERHANG_NAME,
  OVERHANG_SELECTION_COLOR,
  OVERHANG_SELECTION_MATERIAL,
  OVERHANG_SHORT_NAME,
  PARAMETER_SET_SCALE_NAME,
  PART_BODY_ID_DELIMITER,
  PART_PREVIEW_PLATE_MATERIAL_NAME,
  POLYLINES_MARKER_ERROR_MATERIAL,
  POLYLINES_MARKER_WARNING_MATERIAL,
  PRIMARY_CYAN,
  PRIMARY_SELECTION,
  DEFAULT_GREY,
  DEFAULT_LIGHT_GREY,
  SEMITRANSPARENCY_ALPHA,
  PRINT_HEAD_ARROW_MATERIAL_NAME,
  RECOATER_ARROW_MATERIAL_NAME,
  REGULAR_GREEN,
  REGULAR_ORANGE,
  REGULAR_YELLOW_MATERIAL,
  SECONDARY_INDIGO,
  SELECTION_MATERIAL_NAME,
  SEMI_TRANSPARENT_DEFAULT_MATERIAL_NAME,
  SEMI_TRANSPARENT_INSIDE_MATERIAL_NAME,
  SEMI_TRANSPARENT_SUPPORT_INSIDE_MATERIAL,
  SEMI_TRANSPARENT_SUPPORT_MATERIAL,
  SEMI_TRANSPARENT_SUPPORT_SELECTION_MATERIAL,
  SHADER_CYAN,
  SHADER_DEFAULT_COLOR,
  SHADER_DEFAULT_MATERIAL_NAME,
  SHADER_HIGHLIGHT_MATERIAL_NAME,
  SHADER_SELECTION,
  SHADER_SELECTION_MATERIAL_NAME,
  SINTER_PLATE_MATERIAL_NAME,
  SINTER_PLATE_NAME,
  SLICE_POLYLINES_MARKER_NAME,
  SLICE_POLYLINES_SYSTEM_NAME,
  SLICING_MESH_MATERIAL,
  SOLID_MATERIAL,
  SUPPORT,
  SUPPORT_COLOR,
  SUPPORT_INSIDE_MATERIAL,
  SUPPORT_INSIDE_MESH_NAME,
  SUPPORT_MATERIAL,
  SUPPORT_PARENT,
  SUPPORT_SELECTION_MATERIAL,
  WIREFRAME_MATERIAL_NAME,
  WORLD_EXTENDS_EXCLUDED_MESHES,
  SEMI_TRANSPARENT_LABEL_MATERIAL_NAME,
  LABEL_INSIDE_MESH_NAME,
  INSPECTION_MATERIAL_NAME,
  REGULAR_MAGENTA,
  INSPECTION_HIGHLIGHT_MATERIAL_NAME,
  ESCAPE_CODE,
  LABEL,
} from '@/constants'
import { CollisionManager } from '@/visualization/rendering/CollisionManager'
import { SceneMode } from '@/visualization/types/SceneTypes'
import VisualizationModeTypes from '@/visualization/types/VisualizationModeTypes'
import { GpuPicker } from '@/visualization/components/GpuPicker'
import { MeshShader } from '@/visualization/rendering/MeshShader'
import { VisualizationModeManager } from '@/visualization/rendering/VisualizationModeManager'
import { IRenderable } from '@/visualization/types/IRenderable'
import messageService from '@/services/messageService'
import { IOverhangConfig } from '@/visualization/rendering/OverhangManager'
import { ConvexHull } from '@/visualization/rendering/ConvexHull'
import { DynamicTexture } from '@babylonjs/core/Materials/Textures/dynamicTexture'
import i18n from '@/plugins/i18n'
import { VisualizationEvent } from '@/visualization/infrastructure/IVisualizationEvent'
import { IConstraints } from '@/types/BuildPlans/IConstraints'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import partsService from '@/api/parts'
import { ArcRotateCameraPointersInput } from '@babylonjs/core/Cameras/Inputs/arcRotateCameraPointersInput'
import { PointerEventTypes } from '@babylonjs/core/Events/pointerEvents'
import { InputController } from '@/visualization/rendering/InputController'
import { InsightsManager } from '@/visualization/rendering/InsightsManager'
import { DuplicateMode, DuplicatePayload } from '@/types/Duplicate/Duplicate'
import {
  IComponentMetadata,
  IPartMetadata,
  ISupportMetadata,
  SceneItemType,
} from '@/visualization/types/SceneItemMetadata'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { ISceneMetadata } from '@/visualization/types/SceneMetadata'
import { PrintingTypes } from '@/types/IMachineConfig'
import store from '@/store'
import { IPartRenderable } from '@/types/Parts/IPartRenderable'
import { MultiMaterial, ShaderMaterial } from '@babylonjs/core/Materials'
import { LoadBuildPlanOptions } from '@/visualization/types/LoadBuildPlanOptions'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { CollectorManager } from '@/visualization/rendering/CollectorManager'
import { LabeledBody } from '@/types/Label/LabeledBody'
import { BoundingInfo } from '@babylonjs/core/Culling'
import { occasionalSleeper } from '@/utils/common'
import {
  createLabeledBodyWithTransformation,
  LabeledBodyWIthTransformation,
} from '@/types/Label/LabeledBodyWIthTransformation'
import { createPlacement, Placement } from '@/types/Label/Placement'
import { getBuildPlanItemTransformationWithoutScale } from '@/utils/label/labelUtils'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'
import buildPlanService from '@/api/buildPlans'
import { ClearanceManager } from '@/visualization/rendering/clearance/ClearanceManager'
import { ClearanceModes, ClearanceTypes, DimensionBox } from '@/visualization/types/ClearanceTypes'
import { ClearanceEnvironment } from '@/visualization/rendering/clearance/ClearanceEnvironment'

export class RenderScene implements IRenderable, IDisposable {
  readonly onHoverLabel = new VisualizationEvent<string>()
  readonly onSelectRelatedBodies = new VisualizationEvent<{
    bodies: LabeledBodyWIthTransformation[]
    add: boolean
    ignored: LabeledBodyWIthTransformation[]
  }>()

  private canvas: HTMLCanvasElement
  private fpsTracker: HTMLDivElement
  private engine: Engine
  private scene: Scene
  private sceneMode: SceneMode
  private obbTree: OBBTree
  private camera: OrthoCamera
  private cameraLight: PointLight
  private ambientLight: HemisphericLight
  private picked: ISelectableNode
  private modelManager: ModelManager
  private selectionManager: SelectionManager
  private commandMananger: CommandManager
  private crossSectionManager: CrossSectionManager
  private slicerManager: SlicerManager
  private buildPlateManager: BuildPlateManager
  private meshManager: MeshManager
  private collisionManager: CollisionManager
  private visualizationModeManager: VisualizationModeManager
  private viewMode: IViewMode
  private axesViewer: AdvancedAxesViewer
  private boundingBox: BoundingBox
  private allViewModes: Map<ViewModeTypes, IViewMode> = new Map<ViewModeTypes, IViewMode>()
  private isDebugMode: boolean
  private isHullMode: boolean
  private isWireframeMode: boolean
  private isShiftKeyDown: boolean
  private isMouseWheelDown: boolean
  private gpuPicker: GpuPicker
  private buildPlanSubType: ItemSubType = ItemSubType.None
  private modalityType: PrintingTypes = PrintingTypes.DMLM
  private machineConfigName: string
  private inputController: InputController
  private insightManager: InsightsManager
  private collectorManager: CollectorManager
  private clearanceManager: ClearanceManager

  // render loop section
  private renderLoop: () => void
  private meshesToRender = new Map<number, boolean>()
  private isRenderLoopStopped = true
  private isAllNewMeshesRendered: boolean
  private isContinuousRender: boolean
  private needRender: boolean
  private isSceneCheckDisabled: boolean = false

  private notifyInitialized: Function
  private waitModuleInitialized = (() => {
    const initPromise = new Promise((resolve) => {
      this.notifyInitialized = resolve
    })
    return () => initPromise
  })()
  private notifyBuildPlanLoaded: Function
  private waitBuildPlanLoaded = (() => {
    const initPromise = new Promise((resolve) => {
      this.notifyBuildPlanLoaded = resolve
    })
    return () => initPromise
  })()
  private readonly onPreviewCreate = new VisualizationEvent<{
    itemId: string
    file: File
  }>()
  private readonly onCameraPositionChangedEvent = new VisualizationEvent<Vector3>()
  private activeBuildPlanId: string
  private activeBuildPlanMoveIncrement: number
  private activeBuildPlanRotateIncrement: number
  private activeYShiftSize: number
  private activeAspectRatio: number
  private boundResizeCanvas: () => void
  private onHoverBody = new VisualizationEvent<{ bpItemId: string; bodyId: string }>()
  private onConfigFileReady = new VisualizationEvent<{ parts: Part[] }>()

  private isDisposed: boolean

  constructor(canvasElement: string) {
    // Create canvas and engine
    this.canvas = document.getElementById(canvasElement) as HTMLCanvasElement
    this.fpsTracker = document.getElementById('fps') as HTMLDivElement
    this.engine = new Engine(this.canvas, true, { stencil: true, preserveDrawingBuffer: true })
    this.isDebugMode = false
    this.isHullMode = false
    this.isWireframeMode = false
    this.isShiftKeyDown = false
    this.isMouseWheelDown = false
    this.boundResizeCanvas = this.resizeCanvas.bind(this)
  }

  get configFileReady() {
    return this.onConfigFileReady.expose()
  }

  get visualizationCanvas() {
    return this.canvas
  }

  get isDebugModeEnabled() {
    return this.isDebugMode
  }

  get isHullModeEnabled() {
    return this.isHullMode
  }

  get isWireframeModeEnabled() {
    return this.isWireframeMode
  }

  get shiftKey() {
    return this.isShiftKeyDown
  }

  set shiftKey(value: boolean) {
    this.isShiftKeyDown = value
  }

  get transformationChange() {
    return this.selectionManager ? this.selectionManager.transformationChange : null
  }

  get transformationChangeBatch() {
    return this.selectionManager ? this.selectionManager.transformationChangeBatch : null
  }

  get elementsSelected() {
    return this.selectionManager ? this.selectionManager.elementsSelected : null
  }

  get hoverBody() {
    return this.onHoverBody.expose()
  }

  get hoverLabel() {
    return this.onHoverLabel.expose()
  }

  get selectRelatedBodies() {
    return this.onSelectRelatedBodies.expose()
  }

  get selectedElementsCollisions() {
    return this.collisionManager ? this.collisionManager.selectedElementsCollisions : null
  }

  get crossSectionChange() {
    return this.crossSectionManager ? this.crossSectionManager.crossSectionChange : null
  }

  get initializeSlicer() {
    return this.slicerManager ? this.slicerManager.initializeSlicer : null
  }

  get configLoadedEvent() {
    return this.modelManager ? this.modelManager.configLoadedEvent : null
  }

  get configsLoadedEvent() {
    return this.modelManager ? this.modelManager.configsLoadedEvent : null
  }

  get resultsManagerEvent() {
    return [this.getSimulateViewModeProperty('resultsEvent'), this.getDeviationViewModeProperty('resultsEvent')]
  }

  get visualizationModeChanged() {
    return this.visualizationModeManager ? this.visualizationModeManager.visualizationModeChanged : null
  }

  get changeIsLoading() {
    return [this.getSimulateViewModeProperty('changeIsLoading'), this.getDeviationViewModeProperty('changeIsLoading')]
  }

  get addGeometryProperties() {
    return this.modelManager ? this.modelManager.addGeometryProperties : null
  }

  get onGetBPNameByBPId() {
    return this.buildPlateManager.onGetBPNameByBPId
  }

  get changeBuildPlate() {
    return this.buildPlateManager.changeBuildPlate
  }

  get generateOverhangMeshByClickEvent() {
    return this.selectionManager && this.selectionManager.generateOverhangMeshByClickEvent
  }

  get generateOverhangMeshEventDebounced() {
    return this.selectionManager && this.selectionManager.generateOverhangMeshEventDebounced
  }

  get updateItemPreviewEvent() {
    return this.onPreviewCreate.expose()
  }

  get overhangsElementsEvent() {
    return this.modelManager && this.modelManager.overhangMgr
      ? this.modelManager.overhangMgr.overhangsElementsEvent
      : null
  }

  get buildPlanType() {
    return this.buildPlanSubType
  }

  get modality() {
    return this.modalityType
  }

  get machineConfig() {
    return this.machineConfigName
  }

  get deletePartsInState() {
    return this.modelManager ? this.modelManager.deletePartsInState : null
  }

  get downwardPlaneRotationStarted() {
    return this.modelManager ? this.modelManager.downwardPlaneRotationStarted : null
  }

  get downwardPlaneRotationEnded() {
    return this.modelManager ? this.modelManager.downwardPlaneRotationEnded : null
  }

  get setSendBoundingAnchorPoints() {
    return this.selectionManager.setSendBoundingAnchorPoints.bind(this.selectionManager)
  }

  get cameraPositionChangedEvent() {
    return this.onCameraPositionChangedEvent
  }

  get buildPlanId() {
    return this.activeBuildPlanId
  }

  get buildPlanMoveIncrement() {
    return this.activeBuildPlanMoveIncrement
  }

  get buildPlanRotateIncrement() {
    return this.activeBuildPlanRotateIncrement
  }

  get yShiftSize() {
    return this.activeYShiftSize
  }

  get selectSupportEvent() {
    return this.selectionManager && this.selectionManager.selectSupportEvent
  }

  get hoverSupportEvent() {
    return this.selectionManager && this.selectionManager.hoverSupportEvent
  }

  get hoverDefect() {
    return this.selectionManager && this.selectionManager.hoverDefect
  }

  get selectDefects() {
    return this.selectionManager && this.selectionManager.selectDefects
  }

  get isSinglePartPropertyMode(): boolean {
    return store.getters['buildPlans/isSinglePartPropertyMode']
  }

  set sceneCheckDisabled(isDisabled: boolean) {
    this.isSceneCheckDisabled = isDisabled
  }

  async createScene(sceneMode: SceneMode, viewMode?: ViewModeTypes, loadDefaultPlate: boolean = true) {
    // create a basic BJS Scene object
    this.scene = new Scene(this.engine)
    this.scene.performancePriority = ScenePerformancePriority.Intermediate
    this.scene.useRightHandedSystem = true
    this.scene.clearColor = new Color4(1, 1, 1, 1)
    this.scene.autoClear = true // needed to create a screenshot
    this.scene.autoClearDepthAndStencil = false
    this.scene.blockMaterialDirtyMechanism = true
    this.sceneMode = sceneMode
    this.scene.metadata = {
      componentPickingColor: new Map<string, Color3>(),
    }
    if (this.sceneMode === SceneMode.BuildPlan || this.sceneMode === SceneMode.PreviewPrintOrder) {
      this.scene.metadata.buildPlanItems = new Map<string, TransformNode>()
    }

    Mesh.INSTANCEDMESH_SORT_TRANSPARENT = true

    const bboxRenderer: BoundingBoxRenderer = this.scene.getBoundingBoxRenderer()
    bboxRenderer.frontColor = Color3.Black()
    bboxRenderer.backColor = Color3.White()

    this.meshManager = new MeshManager(this)

    this.obbTree = new OBBTree(this)

    this.buildPlateManager = new BuildPlateManager(this)
    if (loadDefaultPlate) {
      await this.buildPlateManager.loadParametricBuildPlate()
    }

    this.insightManager = new InsightsManager(this)
    this.collectorManager = new CollectorManager(this)

    this.createMaterialList()
    this.setMaterial(DEFAULT_MATERIAL_NAME)

    if (!this.hasCapabilities()) {
      this.notifyInitialized()
      return
    }

    // Create axes viewer
    this.axesViewer = new AdvancedAxesViewer(this)
    // load model from json file
    this.modelManager = new ModelManager(this, this.canvas)

    // Material gets applied if the mesh is selected.
    const highlightMaterial = this.scene.getMaterialByID(HIGHLIGHT_MATERIAL_NAME)

    // create a ArcRotateCamera
    this.camera = new OrthoCamera(MAIN_CAMERA_NAME, new Viewport(0, 0, 1, 1), this.scene, this.canvas, this)
    this.registerCanvasEvents(this.camera)

    this.camera.inputs.attached.keyboard.detachControl()
    const pointersInput = this.camera.inputs.attached.pointers as ArcRotateCameraPointersInput
    pointersInput.buttons = [MouseButtons.WheelButton, MouseButtons.RightButton]

    this.inputController = new InputController(this)

    if (!viewMode || viewMode !== ViewModeTypes.BuildPlanPreview) {
      // Collision manager Initialization
      this.collisionManager = new CollisionManager()

      // Selection manager Initialization
      this.selectionManager = new SelectionManager(this, highlightMaterial, this.collisionManager.markCollidingItems)

      if (viewMode === ViewModeTypes.PartLayout || viewMode === ViewModeTypes.PartPreview) {
        if (this.sceneMode === SceneMode.PreviewPart) {
          this.selectionManager.setSelectionMode(SelectionUnit.Defect)
        } else {
          this.selectionManager.setSelectionMode(SelectionUnit.Body)
        }
      }
    }

    this.commandMananger = new CommandManager()

    this.crossSectionManager = new CrossSectionManager(this)
    this.slicerManager = new SlicerManager(this)

    this.initViewModes(this.inputController)

    if (viewMode) {
      this.setViewMode(viewMode)
    }

    this.modelManager.init()

    // Create gpu picker for selecting objects
    this.gpuPicker = new GpuPicker(this.engine, this)

    this.clearanceManager = new ClearanceManager(this)

    this.ambientLight = new HemisphericLight('light1', new Vector3(0, 0, this.camera.defaultRadius), this.scene)

    // create a camera light
    this.cameraLight = new PointLight('camera1Light', new Vector3(0, 0, 1), this.scene)
    this.scene.onAfterRenderObservable.add((scene) => {
      this.cameraLight.position = this.camera.position
      if (this.camera.position.z > 0) {
        this.ambientLight.intensity = Math.max(
          Math.min(
            1,
            (Math.abs(this.camera.position.x) + Math.abs(this.camera.position.y)) / this.camera.defaultRadius,
          ),
          0.4,
        )
      }
    })

    this.visualizationModeManager = new VisualizationModeManager(this)
    this.visualizationModeManager.init()
    this.viewMode.setup()

    this.registerRenderLoop()
    this.registerRenderEvents()
    this.notifyInitialized()
  }

  constructScene(): Scene {
    const scene: Scene = new Scene(this.engine)
    scene.useRightHandedSystem = true
    scene.clearColor = new Color4(1, 1, 1, 1)
    const bboxRenderer: BoundingBoxRenderer = this.scene.getBoundingBoxRenderer()
    bboxRenderer.frontColor = Color3.Black()
    bboxRenderer.backColor = Color3.White()

    // create a ArcRotateCamera
    const camera = new OrthoCamera('camera2', new Viewport(0, 0, 1, 1), scene, this.canvas, this)
    camera.inputs.attached.keyboard.detachControl()
    camera.togglePointerInput(MouseButtons.WheelButton, true)
    camera.togglePointerInput(MouseButtons.RightButton, true)
    camera.upVector = new Vector3(0, 0, 1)

    this.registerCanvasEvents(camera)

    // create a basic ambient light
    const ambientLight = new HemisphericLight('light1', new Vector3(0, 0, 1), scene)
    ambientLight.intensity = 0.3

    const cameraLight = new HemisphericLight('light2', new Vector3(0, 0, 1), scene)
    cameraLight.intensity = 0.8

    scene.onBeforeRenderObservable.add(() => {
      cameraLight.setDirectionToTarget(camera.getFrontPosition(1).subtract(camera.position))
    })

    scene.onNewMeshAddedObservable.add((mesh) => {
      this.meshesToRender.set(mesh.uniqueId, false)
    })

    scene.onMeshRemovedObservable.add((mesh) => {
      if (this.meshesToRender.has(mesh.uniqueId)) {
        this.meshesToRender.delete(mesh.uniqueId)
      }
    })

    const defaultMaterial = new StandardMaterial(MESHING_MATERIAL, scene)
    defaultMaterial.diffuseColor = new Color3(0.8, 0.8, 0.8)
    defaultMaterial.specularColor = Color3.Black()
    defaultMaterial.sideOrientation = Mesh.DOUBLESIDE
    defaultMaterial.backFaceCulling = false
    defaultMaterial.zOffset = 1

    const noSpecularMaterial = new StandardMaterial(NO_SPECULAR_MATERIAL_NAME, scene)
    noSpecularMaterial.backFaceCulling = true
    noSpecularMaterial.specularColor = Color3.Black()

    const cutoutMaterial = new StandardMaterial(CUTOUT_MATERIAL, scene)
    cutoutMaterial.backFaceCulling = false
    cutoutMaterial.diffuseColor = Color3.Gray()
    cutoutMaterial.alpha = 0.4
    cutoutMaterial.specularColor = Color3.Black()

    const bridgingTransparentMaterial = cutoutMaterial.clone(BRIDGING_TRANSPARENT)
    bridgingTransparentMaterial.backFaceCulling = true

    const nominalGeometryMaterial = bridgingTransparentMaterial.clone(NOMINAL_GEOMETRY_MATERIAL)
    nominalGeometryMaterial.diffuseColor = Color3.Red()
    nominalGeometryMaterial.sideOrientation = Mesh.DOUBLESIDE

    const greenNominalGeometryMaterial = nominalGeometryMaterial.clone(GREEN_NOMINAL_MATERIAL)
    greenNominalGeometryMaterial.diffuseColor = new Color3(1, 0.58, 0.58)

    const compensatedMaterial = nominalGeometryMaterial.clone(COMPENSATED_MATERIAL)
    compensatedMaterial.diffuseColor = Color3.Green()

    const greenCompensatedMaterial = nominalGeometryMaterial.clone(GREEN_COMPENSATED_MATERIAL)
    greenCompensatedMaterial.diffuseColor = new Color3(0.58, 1, 0.58)

    const bridgingMaterial = new StandardMaterial(BRIDGING_MATERIAL, scene)
    bridgingMaterial.backFaceCulling = true
    bridgingMaterial.diffuseColor = Color3.Red()
    bridgingMaterial.specularColor = Color3.Black()

    const solidMaterial = new StandardMaterial(SOLID_MATERIAL, scene)
    solidMaterial.backFaceCulling = false
    solidMaterial.specularColor = Color3.Black()

    const meshInsideMaterial = new CustomMaterial(MESH_INSIDE_MATERIAL_NAME, scene)
    meshInsideMaterial.backFaceCulling = false
    meshInsideMaterial.alpha = 0
    meshInsideMaterial.Fragment_Before_FragColor('if(gl_FrontFacing) discard;')

    const slicingMeshMaterial = new StandardMaterial(SLICING_MESH_MATERIAL, scene)
    slicingMeshMaterial.diffuseColor = new Color3(0, 0, 0)
    slicingMeshMaterial.specularColor = new Color3(0, 0, 0)
    slicingMeshMaterial.emissiveColor = new Color3(0.3, 0.3, 0.3)
    slicingMeshMaterial.ambientColor = new Color3(0, 0, 0)

    const highlightInfoMaterial = new StandardMaterial(HIGHLIGHT_INFO_MATERIAL, scene)
    highlightInfoMaterial.backFaceCulling = false
    highlightInfoMaterial.diffuseColor = Color3.Green()
    highlightInfoMaterial.alpha = 0.4

    const highlightWarningMaterial = highlightInfoMaterial.clone(HIGHLIGHT_WARNING_MATERIAL)
    highlightWarningMaterial.emissiveColor = Color3.Yellow()
    const highlightErrorMaterial = highlightInfoMaterial.clone(HIGHLIGHT_ERROR_MATERIAL)
    highlightErrorMaterial.emissiveColor = Color3.Red()

    return scene
  }

  getScene() {
    return this.scene
  }

  getSceneMode() {
    return this.sceneMode
  }

  getSceneMetadata() {
    return this.scene.metadata as ISceneMetadata
  }

  handleOuterEvent(eventName: OuterEvents, payload: object) {
    this.viewMode.onOuterEvent(eventName, payload)
  }

  getActiveCamera() {
    return this.viewMode ? (this.viewMode.viewModeScene.activeCamera as OrthoCamera) : this.camera
  }

  getAmbientLight() {
    return this.ambientLight
  }

  getDefaultMaterial() {
    return this.scene.getMaterialByName(DEFAULT_MATERIAL_NAME)
  }

  getPointLight() {
    return this.cameraLight
  }

  getObbTree() {
    return this.obbTree
  }

  getModelManager() {
    return this.modelManager
  }

  getBuildPlateManager() {
    return this.buildPlateManager
  }

  getCommandManager() {
    return this.commandMananger
  }

  getSelectionManager() {
    return this.selectionManager
  }

  getMeshManager() {
    return this.meshManager
  }

  getCollisionManager() {
    return this.collisionManager
  }

  getInsightsManager() {
    return this.insightManager
  }

  getVisuzalizationModeManager() {
    return this.visualizationModeManager
  }

  getCrossSectionManager() {
    return this.crossSectionManager
  }

  getGpuPicker() {
    return this.gpuPicker
  }

  getInputController() {
    return this.inputController
  }

  getViewModes() {
    return this.allViewModes
  }

  getCollectorManager() {
    return this.collectorManager
  }

  getClearanceManager() {
    return this.clearanceManager
  }

  changeView(view: string) {
    switch (view) {
      case 'X+':
        this.animateActiveCamera(0, Math.PI / 2)
        return
      case 'X-':
        this.animateActiveCamera(Math.PI, Math.PI / 2)
        return
      case 'Y+':
        this.animateActiveCamera(-Math.PI / 2, Math.PI / 2)
        return
      case 'Y-':
        this.animateActiveCamera(Math.PI / 2, Math.PI / 2)
        return
      case 'Z+':
        this.animateActiveCamera(Math.PI / 2, 0)
        return
      case 'Z-':
        this.animateActiveCamera(Math.PI / 2, Math.PI)
        return
      case 'XYZ':
        this.animateActiveCamera(Math.PI / 4, Math.PI / 3)
        return
    }
  }

  setViewLocked(value: boolean) {
    this.getActiveCamera().setViewLocked(value)
    this.axesViewer.camera.setViewLocked(value)
  }

  async loadPartConfig(part: IPartRenderable) {
    await this.waitModuleInitialized()
    if (this.sceneMode === SceneMode.SinglePart) {
      const partNodes = this.scene.transformNodes.filter((mesh) => this.meshManager.isPartMesh(mesh))
      partNodes.forEach((mesh) => this.gpuPicker.removePickingObjects(mesh.getChildMeshes()))
      this.scene.transformNodes.forEach((mesh) => mesh.dispose())
      this.scene.render()
    }
    await this.modelManager.loadPartConfig(part)
    if (this.sceneMode === SceneMode.PreviewPart) {
      const loadedDocuments = this.modelManager.getLoadedDocuments()
      const loadedDocument = loadedDocuments && loadedDocuments.length && loadedDocuments[0]
      const parts = loadedDocument.document.parts
      this.onConfigFileReady.trigger({ parts })
    }
  }

  showLoadingPart(loadingPartIndex: number) {
    this.modelManager.showLoadingPart(loadingPartIndex)
  }

  hideLoadingPart(loadingPartIndex: number) {
    this.modelManager.hideLoadingPart(loadingPartIndex)
  }

  updateLoadingPartPosition(loadingPartIndex: number, pointerX: number, pointerY: number) {
    this.modelManager.updateLoadingPartPosition(loadingPartIndex, pointerX, pointerY)
  }

  async saveLoadingPart(loadingPartIndex: number, pointerX?: number, pointerY?: number) {
    const isSaved = await this.modelManager.saveLoadingPart(loadingPartIndex, pointerX, pointerY)
    if (!isSaved) {
      return
    }

    this.scene.freeActiveMeshes()
  }

  async addOverhangMesh(bpItemId: string, meshId: string, config: IOverhangConfig) {
    await this.modelManager.overhangMgr.addOverhangMesh(bpItemId, meshId, config)
    this.modelManager.overhangMgr.addOverhangZonesToGpuPicker(bpItemId)
    if (config.isInvisible) {
      this.setOverhangMeshVisibility([{ buildPlanItemId: bpItemId }], false)
    }

    setTimeout(() => this.animate(), 0)
  }

  updateOverhangMesh(bpItemId: string, meshId: string, overhang: BuildPlanItemOverhang) {
    this.modelManager.overhangMgr.updateOverhangMesh(bpItemId, meshId, overhang)
  }

  clearOverhangMesh(bpItemId: string) {
    this.modelManager.overhangMgr.clearOverhangMesh(bpItemId)
  }

  setGeometriesVisibility(items: Array<{ buildPlanItemId: string }>, geometryType: GeometryType, visibility: boolean) {
    switch (geometryType) {
      case GeometryType.Production:
      case GeometryType.Coupon:
        this.setGeometryVisibility(items, visibility)
        break
      case GeometryType.Support:
        this.setSupportsVisibility(items, visibility)
        break
    }
  }

  setOverhangMeshVisibility(items: Array<{ buildPlanItemId: string }>, visibility: boolean) {
    this.modelManager.overhangMgr.setOverhangMeshVisibility(items, visibility)
    this.animate(true)
  }

  async highlightErrorOverhangZone(bpItemId: string, overhangZoneName?: string) {
    await this.modelManager.overhangMgr.highlightErrorOverhangZone(bpItemId, overhangZoneName)
  }

  setDefaultOverhangMaterial(bpItemId: string, overhangElementsToClear?: string[]) {
    this.modelManager.overhangMgr.setDefaultOverhangMaterial(bpItemId, overhangElementsToClear)
  }

  addSupportMesh(bpItemId: string, sdata: ArrayBuffer | string, belongsToOverhangElementName: string) {
    let decoded
    if (typeof sdata === 'string') {
      decoded = this.modelManager.supportMgr.createSdataFromString(sdata)
    } else {
      decoded = this.modelManager.decodeSdata(sdata)
    }

    const sourceSupportGroup = this.modelManager.createSupportMesh(decoded)
    sourceSupportGroup.metadata.belongsToOverhangElementName = belongsToOverhangElementName

    const parentMesh = this.meshManager.getBuildPlanItemMeshById(bpItemId)
    const pickingColor = parentMesh.metadata.pickingColor
    const supportGroup = this.modelManager.supportMgr.createSupportInstance(sourceSupportGroup, pickingColor)
    supportGroup.parent = this.modelManager.supportMgr.createSupportParent(parentMesh)

    this.addSupportsToGpuPicker(parentMesh.metadata.buildPlanItemId, belongsToOverhangElementName)
    if (this.scene.metadata && !this.scene.metadata.updateGpuPicker) {
      this.scene.metadata.updateGpuPicker = true
    }

    parentMesh.metadata.hullBInfo = this.meshManager.getHullBInfo(parentMesh)
    const updateStateOnly = this.viewMode instanceof SupportViewMode ? true : false
    this.modelManager.updateGeometryProperties(parentMesh, parentMesh.metadata.documentModelId, true, updateStateOnly)
  }

  async addSupportBvhAndHull(bpItemId: string, supportsBvhFileKey: string, supportsHullFileKey: string) {
    const parentMesh = this.meshManager.getBuildPlanItemMeshById(bpItemId)
    const supportParentMesh = parentMesh.getChildTransformNodes().find((mesh) => mesh.name === SUPPORT_PARENT)

    if (supportsBvhFileKey) {
      const supportsBvhData = await partsService.getBvhFileById(supportsBvhFileKey)
      const root = this.getObbTree().deserializeBVH(supportsBvhData)
      supportParentMesh.metadata.bvh = root
    }
    if (supportsHullFileKey) {
      const supportsHullData = await partsService.getFileDataById(supportsHullFileKey)
      const supportsHull = new ConvexHull(supportsHullData)
      supportParentMesh.metadata.hull = supportsHull
      parentMesh.metadata.hullBInfo = this.meshManager.getHullBInfo(parentMesh)
    }

    this.checkCollision([supportParentMesh])
  }

  loadSupports(
    bpItemId: string,
    supports: BuildPlanItemSupport[],
    supportsBvhFileKey: string,
    supportsHullFileKey: string,
    visibility: Visibility,
  ) {
    this.modelManager.loadSupports(bpItemId, supports, supportsBvhFileKey, supportsHullFileKey, visibility)
  }

  clearSupports(bpItemId: string, overhangElementsToClear?: string[], skipGeomProps?: boolean) {
    this.removeSupportsFromGpuPicker(bpItemId, overhangElementsToClear)

    const parentMesh = this.meshManager.getBuildPlanItemMeshById(bpItemId)
    parentMesh.getChildTransformNodes().map((mesh) => {
      if (mesh.name === SUPPORT) {
        if (overhangElementsToClear) {
          if (overhangElementsToClear.includes((mesh.metadata as ISupportMetadata).belongsToOverhangElementName)) {
            this.scene.removeTransformNode(mesh)
            mesh.dispose()
          }
        } else {
          this.scene.removeTransformNode(mesh)
          mesh.dispose()
        }
      }

      if (mesh.name === SUPPORT_PARENT && !overhangElementsToClear) {
        mesh.dispose(true)
      }
    })

    parentMesh.metadata.hullBInfo = this.meshManager.getHullBInfo(parentMesh)
    const updateStateOnly = this.viewMode instanceof SupportViewMode ? true : false
    const partMetadata = parentMesh.metadata as IPartMetadata
    partMetadata.failedOverhangZones = []
    this.modelManager.refreshSupportInsights()

    if (!skipGeomProps) {
      this.modelManager.updateGeometryProperties(parentMesh, parentMesh.metadata.documentModelId, true, updateStateOnly)
    }
  }

  setSupportsVisibility(items: Array<{ buildPlanItemId: string; bodyIds?: string[] }>, visibility: boolean) {
    const doNotChangeInsideVisibility = !(
      this.viewMode instanceof SlicingViewMode || this.viewMode instanceof CrossSectionViewMode
    )

    this.updateItemListForDuplicateTool(items)
    for (const item of items) {
      const parentMesh = this.meshManager.getBuildPlanItemMeshById(item.buildPlanItemId)
      if (!parentMesh) {
        continue
      }

      if (item.bodyIds) {
        const supportBodyIdsInfo = item.bodyIds.map((id) => this.modelManager.getBodyIdsInfo(id))

        const supportBodies = parentMesh.getChildMeshes().filter((child) => {
          const metadata = child.metadata as IComponentMetadata
          return (
            metadata &&
            supportBodyIdsInfo.some(
              (info) => metadata.componentId === info.componentId && metadata.geometryId === info.geometryId,
            )
          )
        })

        supportBodies.forEach((body) =>
          this.setMeshVisibilityRec(body, visibility, false, doNotChangeInsideVisibility, true, false, false, false),
        )
      } else {
        const supportFilter = this.meshManager.isIBCPlan
          ? (m) => this.meshManager.isComponentSupportMesh(m)
          : (m) => this.meshManager.isSupportMesh(m)
        const supportBodies = parentMesh.getChildMeshes().filter(supportFilter)

        supportBodies.forEach((body) =>
          this.setMeshVisibilityRec(body, visibility, false, doNotChangeInsideVisibility, true, false, false, false),
        )
      }

      parentMesh.getChildTransformNodes().forEach((mesh) => {
        if (mesh.name === LINE_SUPPORT) {
          this.setMeshVisibilityRec(mesh, visibility, false, doNotChangeInsideVisibility, true, false, false, true)
        }
      })
    }

    this.animate(true)
  }

  setBodiesVisibility(ids: string[], isVisible: boolean) {
    const meshes = this.scene.meshes
    const componentMeshes = meshes.filter(
      (mesh: AbstractMesh) =>
        mesh.metadata && mesh.metadata.itemType === SceneItemType.Component && ids.includes(mesh.metadata.geometryId),
    )
    componentMeshes.forEach((mesh: AbstractMesh) => {
      this.setMeshVisibilityRec(mesh, isVisible)
    })
  }

  setMeshesVisibilityByName(name: string, visibility: boolean) {
    const meshes = this.scene.meshes.filter((mesh) => mesh.name === name)
    meshes.forEach((mesh) => {
      this.setMeshVisibilityRec(mesh, visibility)
    })

    this.buildPlateManager.setBuildPlateDisplaySettings(name, visibility)
    this.animate(true)
  }

  setBuildPlateMeshVisibility(visibility: boolean, isSinterPlan: boolean) {
    const name: string = isSinterPlan ? SINTER_PLATE_NAME : GROUND_BOX_NAME
    const meshes = this.scene.meshes.filter((mesh) => mesh.name === name)
    meshes.forEach((mesh) => {
      const doNotChangeChild = true
      this.setMeshVisibilityRec(mesh, visibility, false, false, false, false, false, doNotChangeChild)
    })

    this.buildPlateManager.setBuildPlateDisplaySettings(name, visibility)
    this.animate(true)
  }

  /**
   * Set visibility for items
   * Covers both production and coupon geometry visibility
   * @param items Build plan items and, if specified, bodyIds to be affected.
   * @param visibility Make visible if true.
   */
  setGeometryVisibility(items: Array<{ buildPlanItemId: string; bodyIds?: string[] }>, visibility: boolean) {
    const doNotChangeInsideVisibility = !(
      this.viewMode instanceof SlicingViewMode || this.viewMode instanceof CrossSectionViewMode
    )

    this.updateItemListForDuplicateTool(items)
    for (const item of items) {
      const parentMesh = this.meshManager.getBuildPlanItemMeshById(item.buildPlanItemId)
      if (!parentMesh) {
        continue
      }

      if (item.bodyIds && item.bodyIds.length) {
        const bodyIdsInfo = item.bodyIds.map((id) => this.modelManager.getBodyIdsInfo(id))

        const bodies = parentMesh.getChildMeshes().filter((child) => {
          const metadata = child.metadata as IComponentMetadata
          return (
            (this.meshManager.isComponentMesh(child) || this.meshManager.isLabelMesh(child)) &&
            bodyIdsInfo.some(
              (info) => metadata.componentId === info.componentId && metadata.geometryId === info.geometryId,
            )
          )
        })

        bodies.forEach((body) => {
          this.setMeshVisibilityRec(body, visibility, true, doNotChangeInsideVisibility, false, true)
        })
      } else {
        this.setMeshVisibilityRec(parentMesh, visibility, true, doNotChangeInsideVisibility, false, true)
      }

      // need for fix z-fighting when camera is directed along -z axis
      const overhang = parentMesh.getChildMeshes().find((child) => this.meshManager.isOverhangSurface(child))
      if (overhang) {
        if (visibility) {
          overhang.position.z -= Epsilon
        } else {
          overhang.position.z += Epsilon
        }
      }

      // legacy label meshes should have same visibility as components meshes
      const legacyLabelMeshes = parentMesh
        .getChildMeshes()
        .filter((mesh) => this.meshManager.isLabelMesh(mesh) && mesh.name === LABEL)
      const isComponentMeshesVisible = parentMesh
        .getChildMeshes(false, this.meshManager.isComponentMesh)
        .every((component) => component.isVisible)
      legacyLabelMeshes.forEach((label) => label.isVisible = isComponentMeshesVisible)
    }

    this.animate(true)
  }

  setSelectionModeAndReselect(mode: SelectionUnit) {
    this.selectionManager.setSelectionModeAndReselect(mode)
    if (this.selectionManager.getSelected().length) {
      this.showGizmos()
    }
  }

  updateGeometryProperties(bpItemId: string) {
    const bpItemMesh = this.getSceneMetadata().buildPlanItems.get(bpItemId)
    this.modelManager.updateGeometryProperties(bpItemMesh, bpItemMesh.metadata.documentModelId, true, false, true, true)
  }

  updateSupports(buildPlanItemId: string, supports: BuildPlanItemSupport[]) {
    const bpItemMesh = this.getSceneMetadata().buildPlanItems.get(buildPlanItemId)
    this.modelManager.supportMgr.updateSupports(buildPlanItemId, supports)
    this.modelManager.updateGeometryProperties(bpItemMesh, bpItemMesh.metadata.documentModelId, true, false)
  }

  updateSupportsMaterial(bpItemId: string, selectedOverhangElementNames: string[], hoverOverhangZoneName?: string) {
    this.modelManager.overhangMgr.highlightOverhangZones(bpItemId, selectedOverhangElementNames, hoverOverhangZoneName)
    const bpItem = this.meshManager.getBuildPlanItemMeshById(bpItemId)
    const supportMeshes = bpItem.getChildTransformNodes(false, (mesh) => mesh.name === SUPPORT)

    supportMeshes.forEach((support) => {
      const lineSupports = support.getChildMeshes().filter((child) => child.name === LINE_SUPPORT)
      if (selectedOverhangElementNames.includes(support.metadata.belongsToOverhangElementName)) {
        if (lineSupports.some((lineSupport) => lineSupport.instancedBuffers.color !== REGULAR_ORANGE)) {
          lineSupports.forEach((lineSupport) => (lineSupport.instancedBuffers.color = REGULAR_ORANGE))
        }
      } else {
        if (lineSupports.some((lineSupport) => lineSupport.instancedBuffers.color !== SUPPORT_COLOR)) {
          lineSupports.forEach((lineSupport) => (lineSupport.instancedBuffers.color = SUPPORT_COLOR))
        }
      }

      if (hoverOverhangZoneName && support.metadata.belongsToOverhangElementName === hoverOverhangZoneName) {
        lineSupports.forEach((lineSupport) => (lineSupport.instancedBuffers.color = PRIMARY_CYAN))
      }
    })
  }

  highlightSupports(bpItemId: string, overhangElementNames: string[]) {
    const bpItem = this.meshManager.getBuildPlanItemMeshById(bpItemId)
    const overhangMesh = bpItem.getChildMeshes().find((child) => this.meshManager.isOverhangSurface(child))
    this.selectionManager.select([{ part: bpItem }], false, true)

    overhangElementNames.forEach((element) => {
      const overhangFace = (overhangMesh.metadata as IComponentMetadata).faces.find((face) => face.name === element)
      if (overhangFace) {
        this.selectionManager.select([{ body: overhangMesh, face: overhangFace }], true, true)
      } else {
        const childOverhangMesh = overhangMesh.getChildMeshes(true).find((child) => child.name === element)
        this.selectionManager.select(
          [
            {
              body: childOverhangMesh,
              face: (childOverhangMesh.metadata as IComponentMetadata).faces[0],
            },
          ],
          true,
          true,
        )
      }
    })

    this.showGizmos()
  }

  hoverSupport(bpItemId: string, overhangElementName: string) {
    const bpItem = this.meshManager.getBuildPlanItemMeshById(bpItemId)
    if (!overhangElementName) {
      this.selectionManager.highlight([{ part: bpItem }], false, true)
      return
    }

    const overhangMesh = bpItem.getChildMeshes().find((child) => this.meshManager.isOverhangSurface(child))
    const overhangFace = (overhangMesh.metadata as IComponentMetadata).faces.find(
      (face) => face.name === overhangElementName,
    )

    if (overhangFace) {
      this.selectionManager.highlight([{ body: overhangMesh, face: overhangFace }], true, true)
    } else {
      const childOverhangMesh = overhangMesh.getChildMeshes(true).find((child) => child.name === overhangElementName)
      this.selectionManager.highlight(
        [
          {
            body: childOverhangMesh,
            face: (childOverhangMesh.metadata as IComponentMetadata).faces[0],
          },
        ],
        true,
        true,
      )
    }
  }

  addSupportsToGpuPicker(bpItemId: string, belongsToOverhangElementName?: string) {
    const bpItem = this.meshManager.getBuildPlanItemMeshById(bpItemId)
    let supportMeshes = bpItem.getChildTransformNodes(false, (mesh) => mesh.name === SUPPORT)
    if (belongsToOverhangElementName) {
      supportMeshes = supportMeshes.filter(
        (mesh) => mesh.metadata.belongsToOverhangElementName === belongsToOverhangElementName,
      )
    }

    let pickableSupports = []
    supportMeshes.forEach((support) => {
      const lineSupports = support.getChildMeshes().filter((child) => child.name === LINE_SUPPORT)
      pickableSupports = pickableSupports.concat(lineSupports)
    })

    this.getGpuPicker().addPickingObjects(pickableSupports)
  }

  removeSupportsFromGpuPicker(bpItemId: string, belongsToOverhangElementNames?: string[]) {
    const bpItem = this.meshManager.getBuildPlanItemMeshById(bpItemId)

    let supportMeshes = bpItem.getChildTransformNodes(false, (mesh) => mesh.name === SUPPORT)
    if (belongsToOverhangElementNames) {
      supportMeshes = supportMeshes.filter((mesh) => {
        const metadata = mesh.metadata as ISupportMetadata
        return belongsToOverhangElementNames.includes(metadata.belongsToOverhangElementName)
      })
    }

    let children = []
    for (const supportMesh of supportMeshes) {
      children = children.concat(supportMesh.getChildMeshes(false, (child) => child.name === LINE_SUPPORT))
    }

    this.getGpuPicker().removePickingObjects(children)
  }

  transferSupports(sourceItemId: string, targetItemIds: string[]) {
    const sourcePartMesh = this.meshManager.getBuildPlanItemMeshById(sourceItemId)
    targetItemIds.forEach((targetItemId) => {
      this.selectionManager.deselect()

      const targetPartMesh = this.meshManager.getBuildPlanItemMeshById(targetItemId)
      const sourcePartOverhangMesh = (sourcePartMesh.getChildMeshes() as InstancedMesh[]).find((mesh) =>
        this.meshManager.isOverhangSurface(mesh),
      )

      if (sourcePartOverhangMesh) {
        this.modelManager.overhangMgr.duplicateOverhangMesh(sourcePartOverhangMesh, targetPartMesh)
      }

      this.modelManager.supportMgr.duplicateSupports(sourcePartMesh, targetPartMesh)
    })
  }

  setPartsVisibility(ids: string[], visibility: boolean) {
    const meshes = []
    const bpItems = this.getSceneMetadata().buildPlanItems
    for (const id of ids) {
      if (bpItems.has(id)) {
        meshes.push(bpItems.get(id))
      }
    }

    meshes.forEach((mesh) => {
      mesh.getChildMeshes().forEach((childMesh) => {
        this.setMeshVisibilityRec(childMesh, visibility, false, true, true)
      })
    })

    this.animate(true)
  }

  setMeshVisibilityRec(
    mesh: TransformNode,
    visibility: boolean,
    doNotHideSupports: boolean = false,
    doNotChangeInsideVisibility: boolean = false,
    doNotChangeOverhangVisibility: boolean = false,
    doNotChangeGpuPicker: boolean = false,
    saveVisibility: boolean = false,
    doNotChangeChild: boolean = false,
  ) {
    if (
      !mesh ||
      this.meshManager.isComponentCloneMesh(mesh) ||
      this.meshManager.isLabelCloneMesh(mesh as AbstractMesh) ||
      this.meshManager.isSupportCloneMesh(mesh) ||
      (doNotHideSupports && (mesh.name === SUPPORT || mesh.name === SUPPORT_PARENT)) ||
      (doNotChangeOverhangVisibility && this.meshManager.isOverhangMesh(mesh)) ||
      (doNotChangeInsideVisibility &&
        (mesh.name.includes(INSIDE_MESH_NAME) ||
          mesh.name.includes(SUPPORT_INSIDE_MESH_NAME) ||
          mesh.name.includes(OVERHANG_INSIDE_MESH_NAME) ||
          mesh.name.includes(LABEL_INSIDE_MESH_NAME)))
    ) {
      return
    }

    // Label Sensetive zones should be skipped
    if (
      this.meshManager.isLabelSensitiveZone(mesh as AbstractMesh) ||
      (this.meshManager.isLabelMesh(mesh as AbstractMesh) && mesh.metadata.isHidden && mesh.metadata.transparentCloneId)
    ) {
      return
    }

    if (
      !saveVisibility &&
      (this.meshManager.isComponentMesh(mesh) || mesh.name === LINE_SUPPORT) &&
      mesh.metadata.isHidden
    ) {
      // The condition above is true when toggle show/hide production/support/coupon geometry or open duplicate tool
      const isSelected = this.selectionManager.isSelected({ body: mesh as AbstractMesh })
      visibility && (isSelected || this.meshManager.isShowHiddenPartsAsTransparentMode)
        ? this.meshManager.showAsTransparent(mesh as InstancedMesh)
        : this.meshManager.totalHide(mesh as InstancedMesh)
      return
    }

    ; (mesh as Mesh).isVisible = visibility
    if (!doNotChangeGpuPicker) {
      if (!visibility) {
        this.gpuPicker.removePickingObjects([mesh as Mesh])
      } else {
        this.gpuPicker.addPickingObjects([mesh as Mesh])
      }
    }

    if (doNotChangeChild) return

    const children = mesh.getChildren()
    for (const child of children) {
      this.setMeshVisibilityRec(
        child as AbstractMesh,
        visibility,
        doNotHideSupports,
        doNotChangeInsideVisibility,
        doNotChangeOverhangVisibility,
        doNotChangeGpuPicker,
        saveVisibility,
        doNotChangeChild,
      )
    }
  }

  async savePartByClick(part: IPartRenderable) {
    await this.loadPartConfig(part)
    await this.saveLoadingPart(part.loadingPartIndex)
    await this.disposeLoadingPart(part.loadingPartIndex)

    const renderedPart = this.scene.transformNodes
      .filter((m) => this.meshManager.isPartMesh(m))
      .find((p) => p.metadata.partId === part.partId)

    if (!renderedPart) {
      return
    }

    this.selectionManager.select([{ part: renderedPart }], true)
    this.resizeCanvas()
    this.showGizmos()
  }

  setLoadingPartIndex(loadingPartIndex: number, buildPlanItemId: string) {
    this.modelManager.setLoadingPartIndex(loadingPartIndex, buildPlanItemId)

    const loadedPart = this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)
    this.checkCollision([loadedPart])
    this.modelManager.refreshPartPropertiesInsights()
  }

  setLoadingPartIndices(loadingPartIndices: Array<{ loadingPartIndex: number; buildPlanItemId: string }>) {
    this.modelManager.setLoadingPartIndices(loadingPartIndices)

    const loadedParts = loadingPartIndices.map((loadingPartIndex) => {
      return this.meshManager.getBuildPlanItemMeshById(loadingPartIndex.buildPlanItemId)
    })
    this.checkCollision(loadedParts)
    this.modelManager.refreshPartPropertiesInsights()
  }

  async disposeLoadingPart(loadingPartIndex: number) {
    await this.modelManager.disposeLoadingPart(loadingPartIndex)
  }

  async loadBuildPlan(buildPlan: IBuildPlan, options: LoadBuildPlanOptions = {}) {
    this.activeBuildPlanId = buildPlan.id
    this.activeBuildPlanMoveIncrement = buildPlan.settings.move.increment
    this.activeBuildPlanRotateIncrement = buildPlan.settings.rotate.increment
    await this.waitModuleInitialized()

    if (this.modelManager === null || !this.camera) {
      return false
    }

    const buildPlatePk = new VersionablePk(buildPlan.buildPlateId, buildPlan.buildPlateVersion)
    const machineConfigPk = new VersionablePk(buildPlan.machineConfigId, buildPlan.machineConfigVersion)
    const machineConfig = store.getters['buildPlans/getMachineConfigByPk'](machineConfigPk)

    this.modalityType = buildPlan.modality as PrintingTypes
    this.buildPlanSubType = buildPlan.subType
    this.machineConfigName = machineConfig && machineConfig.name

    // retrieve yShiftSize from production set if
    // rasterSettings.imageShifting.randomlyShiftInY === true
    this.activeYShiftSize = DEFAULT_Y_SHIFT_SIZE
    if (this.modalityType === PrintingTypes.BinderJet) {
      const buildPlanPrintStrategy = store.getters['buildPlans/getBuildPlanPrintStrategy']
      const { productionSet } = buildPlanPrintStrategy

      if (productionSet.rasterSettings) {
        const rasterSettings =
          typeof productionSet.rasterSettings === 'string'
            ? JSON.parse(productionSet.rasterSettings)
            : productionSet.rasterSettings
        if (
          rasterSettings.imageShifting &&
          rasterSettings.imageShifting.RandomlyShiftInY &&
          rasterSettings.imageShifting.YShiftSize
        ) {
          this.activeYShiftSize = rasterSettings.imageShifting.YShiftSize
        }
      }
    }

    this.camera.turnOff()
    if (this.buildPlanSubType) {
      await this.loadSinterPlate(buildPlatePk, machineConfigPk)
    } else {
      await this.loadBuildPlate(buildPlatePk, machineConfigPk, this.modalityType)
    }

    await this.modelManager.loadBuildPlan(buildPlan, options)

    if (this.isDisposed) {
      return false
    }

    this.clearanceManager.init()
    this.selectionManager.activateSelectionBox()
    this.camera.repositionCamera(this.scene, true)
    this.camera.addChangeTargetObserver()
    this.axesViewer.camera.reset()
    this.modelManager.refreshPartPropertiesInsights()
    this.notifyBuildPlanLoaded()
  }

  async getBuildPlan(buildPlanId: string) {
    return await buildPlanService.getBuildPlanById(buildPlanId)
  }

  async loadIBCPlan(ibcPlan: IIBCPlan, options: LoadBuildPlanOptions = {}) {
    this.activeBuildPlanId = ibcPlan.id
    await this.waitModuleInitialized()

    if (this.modelManager === null || !this.camera) {
      return false
    }

    const buildPlan = await buildPlanService.getBuildPlanById(ibcPlan.ibcPlanItems[0].buildPlanId)
    this.buildPlanSubType = ibcPlan.subType
    this.modalityType = buildPlan.modality as PrintingTypes
    this.activeYShiftSize = DEFAULT_Y_SHIFT_SIZE
    if (this.modalityType === PrintingTypes.BinderJet) {
      const buildPlanPrintStrategy = store.getters['buildPlans/getBuildPlanPrintStrategy']
      const { productionSet } = buildPlanPrintStrategy

      if (productionSet.rasterSettings) {
        const rasterSettings =
          typeof productionSet.rasterSettings === 'string'
            ? JSON.parse(productionSet.rasterSettings)
            : productionSet.rasterSettings
        if (
          rasterSettings.imageShifting &&
          rasterSettings.imageShifting.RandomlyShiftInY &&
          rasterSettings.imageShifting.YShiftSize
        ) {
          this.activeYShiftSize = rasterSettings.imageShifting.YShiftSize
        }
      }
    }

    const buildPlatePk = new VersionablePk(buildPlan.buildPlateId, buildPlan.buildPlateVersion)
    const machineConfigPk = new VersionablePk(buildPlan.machineConfigId, buildPlan.machineConfigVersion)

    this.camera.turnOff()
    await this.loadSinterPlate(buildPlatePk, machineConfigPk)
    await this.modelManager.loadIBCPlan(ibcPlan, options)

    if (this.isDisposed) {
      return false
    }

    this.camera.repositionCamera(this.scene, true)
    this.camera.addChangeTargetObserver()
    this.axesViewer.camera.reset()
    this.modelManager.refreshPartPropertiesInsights()
    this.notifyBuildPlanLoaded()
  }

  async loadMeasurementsForIBCPlan(ibcPlan: IIBCPlan) {
    await this.waitModuleInitialized()

    if (this.modelManager === null || !this.camera) {
      return false
    }

    const displayToolbarState: IIBCDisplayToolbarState = store.getters['buildPlans/ibcDisplayToolbarStateByVariantId'](
      ibcPlan.id,
    )

    const sinterPlanItemTransform: number[] = await this.modelManager.getSinterPlanItemTransform(
      ibcPlan.ibcPlanItems[0].partId,
    )

    const loadPromises = []
    ibcPlan.measurements.forEach((m) => {
      if (!this.scene.getMeshById(m.measurementVisFileId)) {
        const isVisible = displayToolbarState.measurements && m.visibility === Visibility.Visible
        loadPromises.push(
          this.modelManager.loadMeasurementsModel(m.measurementVisFileId, isVisible, sinterPlanItemTransform),
        )
      }
    })

    await Promise.all(loadPromises)
  }

  changeIbcMeasurementsVisibility(measurementVisFileId: string, isVisible: boolean) {
    if (measurementVisFileId) {
      const measurements = this.scene.getMeshById(measurementVisFileId)
      if (measurements) {
        measurements.isVisible = isVisible
        this.animate(true)
      }
    }
  }

  deleteMeasurements(measurementVisFileIds: string[]) {
    for (const measurementVisFileId of measurementVisFileIds) {
      const measurement = this.scene.getMeshById(measurementVisFileId)
      if (!measurement) {
        continue
      }
      measurement.dispose()
    }
  }

  highlightIBCMeasurement(measurementVisFileId: string, showHighlight: boolean) {
    if (!measurementVisFileId) {
      return
    }

    const measurements = this.scene.getMeshById(measurementVisFileId)
    if (!measurements) {
      return
    }

    const materialName = showHighlight ? INSPECTION_HIGHLIGHT_MATERIAL_NAME : INSPECTION_MATERIAL_NAME
    measurements.material = this.scene.getMaterialByName(materialName)
    this.render()
  }

  async updateBuildPlan(buildPlan: IBuildPlan) {
    const machineConfigPk = new VersionablePk(buildPlan.machineConfigId, buildPlan.machineConfigVersion)
    const machineConfig = store.getters['buildPlans/getMachineConfigByPk'](machineConfigPk)

    this.modalityType = buildPlan.modality as PrintingTypes
    this.buildPlanSubType = buildPlan.subType
    this.machineConfigName = machineConfig && machineConfig.name
    if (this.modalityType === PrintingTypes.BinderJet && this.buildPlanSubType === ItemSubType.None) {
      const clearanceEnv = this.clearanceManager.getClearanceMode(ClearanceModes.Environment) as ClearanceEnvironment
      if (clearanceEnv) {
        clearanceEnv.recreateHighlightedWalls()
      }
    }
    this.modelManager.triggerSafeDosingHeightCheck()
  }

  async loadBuildPlate(
    buildPlatePk: VersionablePk,
    machineConfigPk: VersionablePk,
    modality: PrintingTypes,
    resetCamera?: boolean,
  ) {
    if (!buildPlatePk) {
      return false
    }

    const isValid = await this.buildPlateManager.checkBuildPlate(buildPlatePk, machineConfigPk)
    if (!isValid) {
      this.changeBuildPlate.trigger(this.buildPlateManager.getBuildPlatePk())
      return false
    }

    this.buildPlateManager.removeGroundPlane()
    const addPrintHeadLanes = modality === PrintingTypes.BinderJet
    await this.buildPlateManager.loadParametricBuildPlate(
      true,
      true,
      true,
      addPrintHeadLanes,
      buildPlatePk,
      machineConfigPk,
    )
    if (resetCamera) {
      this.camera.repositionCamera(this.scene, true)
      this.axesViewer.camera.reset()
    }

    return true
  }

  async loadInsights(insights: IBuildPlanInsight[]) {
    await this.waitBuildPlanLoaded()
    if (this.modelManager) {
      this.modelManager.loadInsights(insights)
    }
  }

  async loadSinterPlate(buildPlatePk: VersionablePk, machineConfigPk: VersionablePk) {
    if (!buildPlatePk) {
      return false
    }

    await this.buildPlateManager.loadBuildPlateSizes(buildPlatePk, machineConfigPk)

    return true
  }

  deleteParts(partsIds: string[]) {
    this.modelManager.deleteParts(partsIds)
    this.insightManager.deleteCollisionInsights(partsIds, false)
    this.modelManager.refreshPartPropertiesInsights()
    this.modelManager.triggerStabilityCheck()
    this.modelManager.triggerSafeDosingHeightCheck()
  }

  async clearUnfinishedInstances() {
    await this.modelManager.clearUnfinishedInstances()
  }

  async createVolumeGrid(
    duplicatePayload: DuplicatePayload[],
    duplicatePartsIndependently: boolean = true,
    duplicateMode: DuplicateMode = DuplicateMode.BoundingBoxes,
  ) {
    await this.modelManager.createVolumeGrid(duplicatePayload, duplicatePartsIndependently, duplicateMode)
  }

  finishInstancingProcess(payload: { items: IBuildPlanItem[]; singleSelection: boolean }) {
    this.modelManager.finishInstancingProcess(payload)
    const buildPlanItems = payload.items.map((item) => this.meshManager.getBuildPlanItemMeshById(item.id))
    this.checkCollision(buildPlanItems)
    this.modelManager.refreshPartPropertiesInsights()
  }

  cancelDuplicateProcess() {
    this.modelManager.cancelDuplicateProcess()
  }

  undo() {
    this.commandMananger.undo()
  }

  setCrossSection(payload: { isEnabled: boolean; crossSectionMatrix: number[] }) {
    if (payload.isEnabled && this.selectionManager) {
      if (this.picked) {
        this.selectionManager.highlight([this.picked], false)
      }

      if (!(this.viewMode instanceof PartLayoutMode)) {
        this.selectionManager.deselect()
      }
    }

    if (this.crossSectionManager) {
      this.crossSectionManager.setCrossSection(payload)
      setTimeout(() => this.animate(true), 0)
    }
  }

  recenterCrossSection() {
    this.crossSectionManager.recenterCrossSection()
  }

  axisAlignCrossSection() {
    this.crossSectionManager.axisAlignCrossSection()
  }

  setSlicer(isEnabled: boolean) {
    if (this.selectionManager) {
      if (this.picked) {
        this.selectionManager.highlight([this.picked], false)
      }
      this.selectionManager.deselect()
    }

    this.slicerManager.setSlicer(isEnabled)
  }

  changeCurrentSlicer(current: number) {
    if (this.slicerManager) {
      this.slicerManager.changeCurrentSlicer(current)
    }
  }

  viewSimulationResults() {
    const simulateView = this.viewMode as ResultsViewMode
    if (simulateView) {
      simulateView.getResultsManager().startVisualization()
    }
  }

  clearResults() {
    const simulateView = this.allViewModes.get(ViewModeTypes.SimulationCompensation) as SimulateViewMode
    if (simulateView && simulateView.getSimulationManager()) {
      simulateView.getSimulationManager().clearResults()
    }
    const deviationView = this.allViewModes.get(ViewModeTypes.DeviationCompensate) as DeviationViewMode
    if (deviationView && deviationView.getDeviationManager()) {
      deviationView.getDeviationManager().clearResults()
    }
  }

  async setGizmoVisibility(isVisible: boolean) {
    await this.waitModuleInitialized()
    if (!this.selectionManager) {
      return
    }
    this.selectionManager.setGizmoVisibility(isVisible)
  }

  showGizmos() {
    if (!this.selectionManager.getSelected(true)) {
      messageService.showErrorMessage('Select a Mesh')
      return
    }

    this.selectionManager.showGizmos()
  }

  enableGizmos() {
    this.selectionManager.enableGizmos()
  }

  disableGizmos(silent: boolean) {
    this.selectionManager.disableGizmos(silent)
  }

  async setSelectionMode(mode: SelectionUnit, options?: { shouldAffectSelectionBox: boolean }) {
    await this.waitModuleInitialized()
    this.selectionManager.setSelectionMode(mode, options)
  }

  getSelectionBoundingBox() {
    return this.selectionManager.getSelectionBoundingBox()
  }

  getIsSelectedPartBrep() {
    const selectedBodies = []
    let brep = false
    this.selectionManager
      .getSelected()
      .forEach((s) => selectedBodies.push(...s.getChildMeshes().filter((c) => this.meshManager.isComponentMesh(c))))
    selectedBodies.forEach((body) => {
      if (body.sourceMesh.metadata.faces.length > 1) {
        brep = true
        return
      }
    })

    return brep
  }

  getPartZTranslation(buildPlanItemId: string) {
    let selected = this.selectMeshByBuildPlanItemId(buildPlanItemId, null)
    if (!selected || selected == null) {
      selected = this.selectMeshByBuildPlanItemId(buildPlanItemId, null, true)
    }
    if (selected) {
      const minimumWorldZ = this.meshManager.getPartHullBInfo(selected).boundingBox.minimumWorld.z
      return minimumWorldZ
    }
    return null
  }

  getSelectedParts() {
    return this.selectionManager.getSelectedItems()
  }

  setMaterial(materialName: string) {
    const material = this.scene.getMaterialByName(materialName)

    if (material) {
      this.scene.defaultMaterial = material
      const meshInsideMaterial = this.scene.getMaterialByName(MESH_INSIDE_MATERIAL_NAME)
      const meshesInside = this.scene.meshes.filter((mesh) => mesh.name.includes(INSIDE_MESH_NAME))
      meshesInside.forEach((mesh) => {
        mesh.material = meshInsideMaterial
      })

      meshesInside
        .map((mesh) => mesh.parent)
        .forEach((mesh) => {
          this.scene.getMeshByID(mesh.id).material = material
        })
    }
  }

  addPolylines(polylines: any[], type?: string, polylineMeshName?: string) {
    if (!polylines.length) return
    let meshName = SLICE_POLYLINES_SYSTEM_NAME
    if (polylineMeshName) {
      meshName = polylineMeshName
    }
    let polylinesSystemMeshInstance
    if (polylines[0] instanceof Vector3) {
      polylinesSystemMeshInstance = MeshBuilder.CreateLineSystem(
        meshName,
        { lines: [polylines], updatable: true },
        this.scene,
      )
    } else {
      polylinesSystemMeshInstance = MeshBuilder.CreateLineSystem(
        meshName,
        { lines: polylines, updatable: true },
        this.scene,
      )
    }

    polylinesSystemMeshInstance.isVisible = true
    polylinesSystemMeshInstance.renderingGroupId = MESH_RENDERING_GROUP_ID + 1
    polylinesSystemMeshInstance.enableEdgesRendering()
    polylinesSystemMeshInstance.edgesWidth = 7

    const bboxDetails = this.getBoundingBoxDetails()
    const markerRadius = Math.max(bboxDetails.maxX - bboxDetails.minX, bboxDetails.maxY - bboxDetails.minY) / 45
    const polylinesMarker = MeshBuilder.CreateDisc(meshName, { radius: markerRadius }, this.scene)
    polylinesMarker.renderingGroupId = MESH_RENDERING_GROUP_ID + 1
    this.scene.removeMesh(polylinesMarker)
    if (polylineMeshName && type) {
      meshName = `${polylineMeshName}${type}`
    } else {
      meshName = SLICE_POLYLINES_MARKER_NAME
    }
    switch (type) {
      case 'WARNING':
        polylinesSystemMeshInstance.color = new Color3(1, 0.4, 0)
        polylinesSystemMeshInstance.edgesColor = new Color4(1, 0.4, 0, 1)
        polylinesMarker.material = this.scene.getMaterialByName(POLYLINES_MARKER_WARNING_MATERIAL)
        this.drawPolylineMarkers(polylines, polylinesMarker, meshName, markerRadius)
        break
      case 'ERROR':
        polylinesSystemMeshInstance.color = new Color3(1, 0, 0)
        polylinesSystemMeshInstance.edgesColor = new Color4(1, 0, 0, 1)
        polylinesMarker.material = this.scene.getMaterialByName(POLYLINES_MARKER_ERROR_MATERIAL)
        this.drawPolylineMarkers(polylines, polylinesMarker, meshName, markerRadius)
        break
      default:
        polylinesSystemMeshInstance.color = new Color3(0, 1, 1)
        polylinesSystemMeshInstance.edgesColor = new Color4(0, 1, 1, 1)
        break
    }

    this.animate()
  }

  removePolylines(type?: string, polylineMeshName?: string) {
    let meshes
    if (polylineMeshName && type) {
      meshes = this.scene.meshes.filter(
        (mesh) => mesh.name === polylineMeshName || mesh.name === `${polylineMeshName}${type}`,
      )
    } else {
      meshes = this.scene.meshes.filter(
        (mesh) => mesh.name === SLICE_POLYLINES_SYSTEM_NAME || mesh.name === SLICE_POLYLINES_MARKER_NAME,
      )
    }
    for (const mesh of meshes) {
      mesh.dispose()
      this.scene.removeMesh(mesh)
    }
  }

  async dispose() {
    if (this.isDisposed) {
      return
    }

    this.isDisposed = true

    this.unregisterCanvasEvents()
    window.removeEventListener('resize', this.boundResizeCanvas)

    if (this.modelManager) {
      await this.modelManager.dispose()
      this.modelManager = null
    }

    if (this.selectionManager) {
      this.selectionManager.dispose()
      this.selectionManager = null
    }

    if (this.clearanceManager) {
      this.clearanceManager.dispose()
      this.clearanceManager = null
    }

    if (this.crossSectionManager) {
      this.crossSectionManager.dispose()
    }

    if (this.axesViewer) {
      this.axesViewer.dispose()
    }

    if (this.camera) {
      this.camera.dispose()
    }

    if (this.cameraLight) {
      this.cameraLight.dispose()
    }

    if (this.ambientLight) {
      this.ambientLight.dispose()
    }

    if (this.canvas && this.scene) {
      // scene.dispose() call doesn't free up geometries
      while (this.scene.geometries.length) {
        const geometry = this.scene.geometries[0]
        geometry.dispose()
      }

      // It's important to set scene metadata to null, otherwise memory isn't freed up on dispose
      this.scene.metadata = null
      this.scene.dispose()
    }

    if (this.gpuPicker) {
      this.gpuPicker.dispose()
    }

    this.engine.stopRenderLoop()
    if (this.canvas && this.engine) {
      this.engine.dispose()
    }
  }

  /**
   * @param suppressGpuPicker set suppressGpuPicker to true if there is no need to update GPU picker
   * @param isContinuously
   * if run with isContinuously = true you must call stopAnimate() after animation end.
   * if run with isContinuously = false animation stops automatically.
   */
  animate(suppressGpuPicker: boolean = false, isContinuously: boolean = false) {
    if (isContinuously) {
      this.isContinuousRender = true
      if (!this.isRenderLoopStopped) {
        this.scene.getEngine().stopRenderLoop()
        this.isRenderLoopStopped = true
      }

      this.runRenderLoop(suppressGpuPicker, true)
    }

    if (this.isContinuousRender) {
      return
    }

    if (this.isRenderLoopStopped) {
      this.runRenderLoop(suppressGpuPicker, false)
    }
    this.needRender = true
  }

  stopAnimate() {
    this.isContinuousRender = false
    this.needRender = false
    this.scene.getEngine().stopRenderLoop()
    // need timeout to finish camera animation without freezing UI during reading pixels in GPU picker
    setTimeout(() => {
      if (this.scene.metadata) {
        this.scene.metadata.updateGpuPicker = true
      }

      this.animate()
    }, 200)
  }

  checkCollision(selectedMeshes?: TransformNode[], silent: boolean = false, enableSleeper?: boolean): void {
    if (this.collisionManager) {
      this.collisionManager.markCollidingItems(this, selectedMeshes, silent, null, enableSleeper)
    }
  }

  dehighlightClearances() {
    if (this.picked && this.meshManager.isClearanceSensitiveZone(this.picked.body)) {
      this.clearanceManager.highlight([this.picked], false)
      this.picked = null
    }
  }

  measureDistanceToEnvironment(payload: {
    from: ClearanceTypes
    to: ClearanceTypes
    buildPlanItemId: string
    componentId?: string
    geometryId?: string
  }) {
    const clearanceEnvironment = this.clearanceManager.getClearanceMode(ClearanceModes.Environment)
    if (clearanceEnvironment) {
      clearanceEnvironment.measureDistance(payload)
    }
  }

  hoverPickedObject(pickedObject?: ISelectableNode) {
    if (
      !this.selectionManager ||
      (this.isSinglePartPropertyMode &&
        this.selectionManager.getSelectionMode() === SelectionUnit.Body &&
        !(this.viewMode instanceof MarkingViewMode || this.viewMode instanceof ClearanceToolViewMode))
    ) {
      return
    }

    const isPartAndSupportMode = this.selectionManager.getSelectionMode() === SelectionUnit.PartAndSupport

    if (pickedObject) {
      const metadata = pickedObject.body.metadata as IComponentMetadata
      if (!this.meshManager.isClearanceSensitiveZone(pickedObject.body)) {
        const partMetadata = pickedObject.part.metadata as IPartMetadata
        const bodyId = `${metadata.componentId}${PART_BODY_ID_DELIMITER}${metadata.geometryId}`
        this.onHoverBody.trigger({ bodyId, bpItemId: partMetadata.buildPlanItemId })
        if (
          pickedObject.body &&
          pickedObject.body.metadata &&
          (pickedObject.body.metadata.itemType === SceneItemType.LabelSensitiveZone ||
            pickedObject.body.metadata.itemType === SceneItemType.LabelOrigin)
        ) {
          const activeLabelSetId = store.getters['label/activeLabelSet'].id
          if (activeLabelSetId === pickedObject.body.metadata.labelSetId) {
            this.onHoverLabel.trigger(pickedObject.body.metadata.labelId)
            this.modelManager.labelMgr.showManualLabelHandle(pickedObject.body)
          }
        } else {
          // Give time to put pointer over rotation handle
          this.modelManager.labelMgr.hideManualLabelHandleWithTimeout()
        }
      }

      const isFaceAndEdgeMode = this.selectionManager.getSelectionMode() === SelectionUnit.FaceAndEdge
      if (!this.visualizationModeManager.isDefaultMode()) {
        return
      }

      if (
        (isPartAndSupportMode || !this.selectionManager.isSelected(pickedObject) || isFaceAndEdgeMode) &&
        !this.selectionManager.gizmos.isDragging
      ) {
        if (this.meshManager.isClearanceSensitiveZone(pickedObject.body)) {
          this.clearanceManager.highlight([pickedObject], true)
        } else {
          this.selectionManager.highlight([pickedObject], true)
        }
      }

      if (this.picked && !this.selectionManager.equals(this.picked, pickedObject)) {
        this.onHoverBody.trigger({ bpItemId: null, bodyId: null })
        if (this.meshManager.isClearanceSensitiveZone(this.picked.body)) {
          this.clearanceManager.highlight([this.picked], false)
        } else {
          this.selectionManager.highlight([this.picked], false)
        }
      } else if (this.picked && this.selectionManager.equals(this.picked, pickedObject)) {
        if (this.meshManager.isClearanceSensitiveZone(this.picked.body)) {
          this.clearanceManager.highlight([this.picked], true)
        } else {
          this.selectionManager.highlight([this.picked], true)
        }
      }

      this.picked = pickedObject
    } else {
      this.onHoverBody.trigger({ bpItemId: null, bodyId: null })
      if (this.picked && this.meshManager.isClearanceSensitiveZone(this.picked.body)) {
        this.clearanceManager.highlight([this.picked], false)
      } else if (this.picked && !this.picked.part.isDisposed() && !this.picked.body.isDisposed()) {
        this.selectionManager.highlight([this.picked], false)
      }

      this.picked = null
    }
  }

  selectPickedObject(
    attach: boolean,
    cacheSelected: boolean,
    ignoreBlankSpace: boolean,
    pickedObject?: ISelectableNode,
  ) {
    if (
      this.selectionManager.getSelected().length > 0 &&
      !attach &&
      !pickedObject &&
      !ignoreBlankSpace &&
      !cacheSelected &&
      this.selectionManager.getSelectionMode() !== SelectionUnit.PartAndSupport
    ) {
      this.selectionManager.deselect()
    }

    if (pickedObject) {
      if (
        cacheSelected &&
        this.selectionManager.isSelected(pickedObject) &&
        (attach || (!attach && this.selectionManager.getSelected().length === 1))
      ) {
        return
      }

      if (!this.visualizationModeManager.isDefaultMode()) {
        this.visualizationModeChanged.trigger(VisualizationModeTypes.Default)
      }

      this.selectionManager.select([pickedObject], attach, false)

      if (!this.selectionManager.isSelected(pickedObject)) {
        this.selectionManager.highlight([pickedObject], true, false)
      }

      if (
        this.selectionManager.getSelected().length > 0 &&
        (this.selectionManager.getSelectionMode() === SelectionUnit.Part ||
          this.selectionManager.getSelectionMode() === SelectionUnit.PartAndSupport) &&
        this.viewMode.constructor.name !== 'OrientationViewMode'
      ) {
        this.showGizmos()
      }
    }
  }

  pointerPositionChanged(evt: MouseEvent) {
    return evt.movementX > ACCURACY || evt.movementY > ACCURACY
  }

  triggerCameraPositionChangedEvent(position: Vector3) {
    this.onCameraPositionChangedEvent.trigger(position)
  }

  cameraPositionChanged(initPosition: Vector3) {
    return initPosition
      ? Math.abs(initPosition.x - this.getActiveCamera().position.x) > ACCURACY ||
      Math.abs(initPosition.y - this.getActiveCamera().position.y) > ACCURACY ||
      Math.abs(initPosition.z - this.getActiveCamera().position.z) > ACCURACY
      : false
  }

  isGizmoEnabled() {
    return this.selectionManager.gizmos.isEnabled
  }

  toggleDebugMode() {
    // toggle debug mode
    this.isDebugMode = !this.isDebugMode
    this.selectionManager.toggleObbTree(this.isDebugMode)
    if (this.fpsTracker) {
      this.fpsTracker.style.visibility = this.isDebugMode ? 'visible' : 'hidden'
    }

    this.animate(true)
  }

  toggleHullMode() {
    // toggle hull visibility mode
    this.isHullMode = !this.isHullMode
    this.selectionManager.toggleHull(this.isHullMode)

    this.animate(true)
  }

  toggleWireframeMode() {
    // toggle wireframe mode
    this.isWireframeMode = !this.isWireframeMode
    this.scene.getMaterialByName(DEFAULT_MATERIAL_NAME).wireframe = this.isWireframeMode
    this.scene.getMaterialByName(SELECTION_MATERIAL_NAME).wireframe = this.isWireframeMode
    this.animate(true)
  }

  registerCanvasEvents(camera: OrthoCamera) {
    const rotateCamera = (alpha: number, beta: number) => {
      if (alpha) {
        camera.alpha += alpha
      }

      if (beta) {
        camera.beta += beta
      }

      this.animate()
    }

    this.canvas.onwheel = (ev) => {
      if (this.isMouseWheelDown) {
        // don't zoom if mouse wheel is currently pressed (to rotate the camera)
        return
      }
      this.panCamera(camera, ev.deltaY, ev.shiftKey)
      ev.preventDefault()
      ev.stopPropagation()
    }

    this.canvas.onpointerdown = (ev) => {
      if (ev.button === 1) {
        // MMB / mouse wheel down
        this.isMouseWheelDown = true
      }
    }

    // double-click handling
    // onclick and ondblclick only work for left mouse button (on PC)
    // so have to get creative here
    this.canvas.onpointerup = (ev) => {
      if (ev.button === 1) {
        // MMB / mouse wheel up
        this.isMouseWheelDown = false
      }
    }

    this.canvas.onkeydown = (ev) => {
      ev.preventDefault()
      const DELTA_ANGLE = Math.PI / 180
      const ctrlDown = ev.ctrlKey || ev.metaKey // Mac support
      const altDown = ev.altKey

      this.isShiftKeyDown = ev.shiftKey

      switch (ev.code) {
        case BACKQUOTE_CODE:
          if (ctrlDown) {
            this.toggleWireframeMode()
          } else if (altDown) {
            this.toggleHullMode()
          } else {
            this.toggleDebugMode()
          }
          break
        case NUMPAD_ADD_CODE:
          this.panCamera(camera, -1, ev.shiftKey)
          break
        case NUMPAD_SUBTRACT_CODE:
          this.panCamera(camera, 1, ev.shiftKey)
          break
        case KEYBOARD_KEYS.ArrowUp:
          rotateCamera(0, -DELTA_ANGLE)
          break
        case KEYBOARD_KEYS.ArrowDown:
          rotateCamera(0, DELTA_ANGLE)
          break
        case KEYBOARD_KEYS.ArrowLeft:
          rotateCamera(DELTA_ANGLE, 0)
          break
        case KEYBOARD_KEYS.ArrowRight:
          rotateCamera(-DELTA_ANGLE, 0)
          break
        case ESCAPE_CODE:
          const isClearanceToolEnabled = store.getters['visualizationModule/isClearanceToolEnabled']
          const isRubberBandShown = store.getters['visualizationModule/isRubberBandShown']
          if (isClearanceToolEnabled && isRubberBandShown) {
            store.commit('visualizationModule/showRubberBand', { isShown: false })
            this.selectionManager.deselect()
          }
          break
      }
    }

    this.canvas.onkeyup = (ev) => {
      this.isShiftKeyDown = ev.shiftKey
    }
  }

  unregisterCanvasEvents() {
    if (this.canvas) {
      this.canvas.onwheel = null
      this.canvas.onpointerup = null
      this.canvas.onkeydown = null
      this.canvas.onkeyup = null
      this.canvas.onpointerdown = null
    }
  }

  panCamera(camera: OrthoCamera, deltaY: number, shiftKey: boolean) {
    camera.pan(deltaY, shiftKey)

    if (this.selectionManager) {
      this.selectionManager.updateGizmoScale()
    }

    if (this.crossSectionManager) {
      this.crossSectionManager.updateGizmoScale()
    }

    if (this.modelManager) {
      this.modelManager.labelMgr.updateLabelGizmoScale()
    }

    this.animate(true)
  }

  initViewModes(input: InputController) {
    this.allViewModes.set(ViewModeTypes.Move, new MoveViewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.Rotate, new RotateViewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.Scale, new ScaleViewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.Duplicate, new DuplicateViewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.TransferProps, new TransferPropsViewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.Constrain, new ConstrainViewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.Orientation, new OrientationViewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.Layout, new LayoutViewMode(this.scene, this, this.selectionManager))
    this.allViewModes.set(ViewModeTypes.Orientation, new OrientationViewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.Support, new SupportViewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.Nesting, new NestingViewMode(this.scene, this, this.selectionManager))
    this.allViewModes.set(ViewModeTypes.Replace, new ReplaceViewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.ClearanceTool, new ClearanceToolViewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.IBCPlan, new IBCPlanViewMode(this.scene, this))

    const simulateViewMode = new SimulateViewMode(this, this.engine, this.selectionManager)
    this.allViewModes.set(ViewModeTypes.SimulationCompensation, simulateViewMode)
    this.allViewModes.set(ViewModeTypes.CrossSection, new CrossSectionViewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.Slicing, new SlicingViewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.Marking, new MarkingViewMode(this.scene, this, this.selectionManager))
    this.allViewModes.set(ViewModeTypes.Print, new PrintViewMode(this.scene, this, this.selectionManager))
    this.allViewModes.set(ViewModeTypes.Publish, new PublishViewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.Part, new PartViewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.PrintOrderPreview, new PrintOrderPreviewMode(this.scene, this))
    const deviationViewMode = new DeviationViewMode(this, this.engine, this.selectionManager)
    this.allViewModes.set(ViewModeTypes.DeviationCompensate, deviationViewMode)
    this.allViewModes.set(ViewModeTypes.PartPreview, new PreviewMode(this.scene, this))
    this.allViewModes.set(ViewModeTypes.PartLayout, new PartLayoutMode(this.scene, this))

    this.viewMode = this.meshManager.isIBCPlan
      ? this.allViewModes.get(ViewModeTypes.IBCPlan)
      : this.allViewModes.get(ViewModeTypes.Layout)
  }

  setViewMode(mode: ViewModeTypes) {
    this.viewMode = this.allViewModes.get(mode)
  }

  getViewMode() {
    return this.viewMode
  }

  changeViewMode(mode: ViewModeTypes) {
    if (this.isDisposed) {
      return
    }

    this.viewMode.clean()
    this.viewMode = null

    switch (mode) {
      case ViewModeTypes.Layout:
      case ViewModeTypes.Orientation:
      case ViewModeTypes.Support:
      case ViewModeTypes.Nesting:
      case ViewModeTypes.SimulationCompensation:
      case ViewModeTypes.CrossSection:
      case ViewModeTypes.Slicing:
      case ViewModeTypes.Marking:
      case ViewModeTypes.Print:
      case ViewModeTypes.Publish:
      case ViewModeTypes.Part:
      case ViewModeTypes.PartPreview:
      case ViewModeTypes.BuildPlanPreview:
      case ViewModeTypes.Move:
      case ViewModeTypes.Rotate:
      case ViewModeTypes.Scale:
      case ViewModeTypes.Duplicate:
      case ViewModeTypes.Constrain:
      case ViewModeTypes.TransferProps:
      case ViewModeTypes.PartLayout:
      case ViewModeTypes.Deviation:
      case ViewModeTypes.Replace:
      case ViewModeTypes.DeviationCompensate:
      case ViewModeTypes.ClearanceTool:
        this.viewMode = this.allViewModes.get(mode)
        this.viewMode.setup()
        break
      // On the Babylon side, IBCPlan and Inspections types use the same view mode.
      // Inspections view mode type is used on UI side.
      case ViewModeTypes.IBCPlan:
      case ViewModeTypes.Inspections:
        this.viewMode = this.allViewModes.get(ViewModeTypes.IBCPlan)
        this.viewMode.setup()
        break
      default:
        this.viewMode = this.allViewModes.get(ViewModeTypes.Layout)
        this.viewMode.setup()
        break
    }
  }

  setIsReadOnly(value: boolean) {
    this.allViewModes.forEach((viewMode) => (viewMode.isViewModeReadOnly = value))
    if (this.viewMode) {
      this.viewMode.setIsReadOnly(value)
    }
  }

  hideMeshes() {
    for (const mesh of this.scene.meshes) {
      if (SLICE_POLYLINES_SYSTEM_NAME !== mesh.name && SLICE_POLYLINES_MARKER_NAME !== mesh.name) {
        mesh.isVisible = false
      }
    }
  }

  showMeshes() {
    for (const mesh of this.scene.meshes) {
      if (
        [
          SLICE_POLYLINES_SYSTEM_NAME,
          SLICE_POLYLINES_MARKER_NAME,
          LABEL_SENSITIVE_ZONE,
          LABEL_SENSITIVE_ZONE_CACHED,
          OVERHANG_NAME,
          OVERHANG_SHORT_NAME,
        ].includes(mesh.name) ||
        (mesh.metadata && mesh.metadata.isHidden) ||
        ([INSIDE_MESH_NAME, LABEL_INSIDE_MESH_NAME, SUPPORT_INSIDE_MESH_NAME].some((name) =>
          mesh.name.includes(name),
        ) &&
          mesh.parent.metadata &&
          mesh.parent.metadata.isHidden)
      ) {
        continue
      }

      mesh.isVisible = true
    }
  }

  async getBuildBoundingBox() {
    await this.waitBuildPlanLoaded()
    const buildMeshes: AbstractMesh[] = []
    const bInfos: BoundingInfo[] = []
    this.scene.transformNodes.forEach((node) => {
      node.getChildMeshes().forEach((mesh) => {
        if (this.meshManager.isComponentMesh(mesh)) {
          // || this.meshManager.isLabelMesh(mesh)
          buildMeshes.push(mesh)
        }
      })
      const supportParent = node
        .getChildTransformNodes()
        .find((c) => this.meshManager.isSupportMesh(c) && c.metadata.buildPlanItemId)

      if (
        supportParent &&
        supportParent.metadata &&
        supportParent.metadata.hullBInfo &&
        supportParent.getChildTransformNodes().length
      ) {
        bInfos.push(supportParent.metadata.hullBInfo)
      } else {
        node.getChildMeshes().forEach((mesh) => {
          if (this.meshManager.isSupportMesh(mesh)) {
            buildMeshes.push(mesh)
          }
        })
      }
    })

    if (buildMeshes.length) {
      // use getHierarchyBoundingVectors instead of meshManager.getTotalBoundingInfo to optimize
      // because second iterate each vertex every time
      for (const buildMesh of buildMeshes) {
        const boundingVectors = buildMesh.getHierarchyBoundingVectors()
        bInfos.push(new BoundingInfo(boundingVectors.min, boundingVectors.max))
      }
    }

    return this.meshManager.mergeBoundingInfos(bInfos).boundingBox
  }

  getBoundingBoxDetailsForCostCalculation() {
    const filterMeshFn = (mesh: AbstractMesh) => {
      if (!mesh.visibility) {
        return false
      }
      return this.meshManager.isComponentMesh(mesh) || this.meshManager.isSupportMesh(mesh)
    }

    return this.getBoundingBoxDetails(null, filterMeshFn)
  }

  getBoundingBoxDetails(
    excludedMeshNames = WORLD_EXTENDS_EXCLUDED_MESHES,
    filterMeshPredicate?: (mesh: AbstractMesh) => boolean,
  ) {
    this.boundingBox = new BoundingBox()

    let sceneBoundingBox: {
      min: Vector3
      max: Vector3
    }

    if (typeof filterMeshPredicate === 'function') {
      sceneBoundingBox = this.scene.getWorldExtends(filterMeshPredicate)
    } else {
      sceneBoundingBox = this.scene.getWorldExtends((mesh) => {
        return mesh.visibility === 1 && !excludedMeshNames.includes(mesh.name)
      })
    }

    this.boundingBox.minX = sceneBoundingBox.min.x
    this.boundingBox.maxX = sceneBoundingBox.max.x
    this.boundingBox.minY = sceneBoundingBox.min.y
    this.boundingBox.maxY = sceneBoundingBox.max.y
    // calculate scene bbox along z axis using mesh hulls, not mesh bboxes (which are not aabb in world coordinates)
    this.boundingBox.minZ = MIN
    this.boundingBox.maxZ = MAX
    this.scene.transformNodes.forEach((node) => {
      if (node.metadata && node.metadata.hull) {
        // for part meshes need to get min and max from their hulls
        if (!node.metadata.hullBInfo) {
          // calling this updates node.metadata.hull as well
          node.metadata.hullBInfo = this.meshManager.getHullBInfo(node)
        }
        const hullBBox = node.metadata.hullBInfo.boundingBox
        this.boundingBox.minZ = Math.min(this.boundingBox.minZ, hullBBox.minimumWorld.z)
        this.boundingBox.maxZ = Math.max(this.boundingBox.maxZ, hullBBox.maximumWorld.z)
      } else if (node.name === LINE_SUPPORT) {
        // for support meshes just need min and max of their bbox
        const supportsBoundingBox = node.metadata.hullBInfo.boundingBox
        this.boundingBox.minZ = Math.min(this.boundingBox.minZ, supportsBoundingBox.minimumWorld.z)
        this.boundingBox.maxZ = Math.max(this.boundingBox.maxZ, supportsBoundingBox.maximumWorld.z)
      }
    })
    return this.boundingBox
  }

  getItemsBoundingBox2D(itemIds: string[]): BoundingBox2D {
    const bpItems: TransformNode[] = []
    const bpItemMap = this.getSceneMetadata().buildPlanItems
    for (const itemId of itemIds) {
      const bpItem = bpItemMap.get(itemId)
      if (bpItem) {
        bpItems.push(bpItem)
      }
    }
    const meshes = bpItems.flatMap((mesh) => mesh.getChildMeshes())

    return this.meshManager.getBoundingBox2D(meshes)
  }

  /**
   * Roatate the part by x, y and z degrees using rotationQuaternion
   *
   * @param buildPlanItemId build plan id of the part ( assigned while uploading into the system )
   * @param x x's rotation angle in degrees
   * @param y y's rotation angle in degrees
   * @param z z's rotation angle in degrees
   * @param zTranslation minimum z value to be maintained for orient feautre
   */
  rotatePart(
    buildPlanItemId: string,
    x: number,
    y: number,
    z: number,
    transformation?: number[],
    zTranslation?: number,
  ) {
    let selected = this.selectMeshByBuildPlanItemId(buildPlanItemId, null)
    if (!selected || selected == null) {
      selected = this.selectMeshByBuildPlanItemId(buildPlanItemId, null, true)
    }
    if (selected) {
      const bpItemMesh = this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)
      if (bpItemMesh) {
        const parts = selected.getChildTransformNodes(false, this.meshManager.isPartMesh)
        parts.forEach((child) => {
          this.detachScaleNode(child, selected, parts.length === 1 ? selected.position : null)
        })
        if (transformation) {
          const transformationMatrix = Matrix.FromArray(transformation).transpose()
          const rotation: Quaternion = Quaternion.Identity()
          const scaling: Vector3 = Vector3.Zero()
          const position: Vector3 = Vector3.Zero()
          transformationMatrix.decompose(scaling, rotation, position)
          selected.rotationQuaternion = Quaternion.Identity()
          bpItemMesh.rotationQuaternion = rotation
          bpItemMesh.computeWorldMatrix(true)
          selected.computeWorldMatrix(true)
        } else {
          const eulerAngle = bpItemMesh.absoluteRotationQuaternion.toEulerAngles()
          // to bring back part to original rotation
          selected.rotate(Axis.Y, eulerAngle.y * -1, Space.WORLD)
          selected.rotate(Axis.X, eulerAngle.x * -1, Space.WORLD)
          selected.rotate(Axis.Z, eulerAngle.z * -1, Space.WORLD)
          const xRad = (x * Math.PI) / 180
          const yRad = (y * Math.PI) / 180
          const zRad = (z * Math.PI) / 180
          selected.rotate(Axis.X, xRad, Space.WORLD)
          selected.rotate(Axis.Y, yRad, Space.WORLD)
          selected.rotate(Axis.Z, zRad, Space.WORLD)
          selected.computeWorldMatrix(true)
        }
        // before attaching scale nodes we need to compute world matrix
        selected.getChildTransformNodes().forEach((transformNode) => transformNode.computeWorldMatrix(true))
        parts.forEach((child) => {
          this.attachScaleNode(child, selected, parts.length === 1 ? selected.position : null)
        })
        selected.computeWorldMatrix(true)
        selected.getChildTransformNodes().forEach((childNode) => childNode.computeWorldMatrix(true))

        // seting the distance from the item to the build plate as it was before orient
        // get minimum z coordinate after rotation from hull bounding info
        const hullBBox = this.meshManager.getPartHullBInfo(selected).boundingBox
        const zDifference = zTranslation - hullBBox.minimum.z
        // compute the centerpoint z coordinate to adjust the item for maintaining part height
        const updatedZPosition = selected.position.z + zDifference
        selected.position.z = updatedZPosition
        selected.computeWorldMatrix(true)
        selected.getChildTransformNodes().forEach((childNode) => childNode.computeWorldMatrix(true))
      }

      this.checkCollision([bpItemMesh], true)
    }
  }

  async applyTransformationMatrixBatch(buildPlanItems: any[]) {
    for (const bpItem of buildPlanItems) {
      await occasionalSleeper()
      await this.applyTransformationMatrix(bpItem.id, bpItem.transformationMatrix)
    }

    this.checkCollision()
  }

  // TODO Consider the separate method for TransferPropertiesTool transformations
  // as sometimes they can be very different from usual mesh transformations
  async applyTransformationMatrix(
    buildPlanItemId: string,
    transformation: number[],
    options?: {
      skipPositionX?: boolean
      skipPositionY?: boolean
      skipPositionZ?: boolean
      updateStateOnly?: boolean
      parameterSetScaleFactor?: number[]
      skipScaleCompensation?: boolean
    },
  ) {
    const {
      skipPositionX = false,
      skipPositionY = false,
      skipPositionZ = false,
      updateStateOnly = true,
      parameterSetScaleFactor = null,
      skipScaleCompensation = false,
    } = options || {}

    const bpItemMesh = this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)
    if (bpItemMesh) {
      const initTransformation = bpItemMesh.computeWorldMatrix(true).clone()
      this.selectionManager.gizmos.translateLabelsOrientation(bpItemMesh.getChildMeshes(), false)

      const parameterSetScale = bpItemMesh.metadata.parameterSetScaleNode as TransformNode
      const scale = parameterSetScaleFactor ? Vector3.FromArray(parameterSetScaleFactor) : parameterSetScale.scaling
      let partTransform = Matrix.FromArray(transformation).transpose()

      const translateVector = partTransform.getTranslation().clone()
      const defaultPosition = initTransformation.getTranslation()
      if (skipPositionX) translateVector.x = defaultPosition.x
      if (skipPositionY) translateVector.y = defaultPosition.y
      if (skipPositionZ) translateVector.z = defaultPosition.z

      if (skipPositionX && skipPositionY && skipPositionZ) {
        // To rotate the item correctly we need to rollback the current rotation, then apply needed
        const rotationMatrix = initTransformation
          .getRotationMatrix()
          .clone()
          .invert()
          .multiply(partTransform.getRotationMatrix())

        const parent = bpItemMesh.parent
        bpItemMesh.setParent(null)
        // This is logic from rotateAround method of TransformNode adjusted for the rotation matrix usage
        const centerBBox = (bpItemMesh.metadata as IPartMetadata).hullBInfo.boundingBox.centerWorld
        const positionDetla = centerBBox.subtract(bpItemMesh.position)
        const translation = Matrix.Translation(positionDetla.x, positionDetla.y, positionDetla.z)
        const translationInv = translation.clone().invert()
        const finalTransform = translationInv.multiply(rotationMatrix).multiply(translation)
        const finalRotation = Quaternion.Identity()
        const finalTranslation = Vector3.Zero()
        finalTransform.decompose(null, finalRotation, finalTranslation)
        bpItemMesh.position.addInPlace(finalTranslation)
        finalRotation.multiplyToRef(bpItemMesh.rotationQuaternion, bpItemMesh.rotationQuaternion)
        bpItemMesh.setParent(parent)
      }

      partTransform.setTranslation(translateVector)

      const translateMatrix = Matrix.Translation(translateVector.x, translateVector.y, translateVector.z)
      const invertTranslateMatrix = translateMatrix.clone().invert()
      const scaleMatrix = Matrix.Scaling(scale.x, scale.y, scale.z)
      const parameterSetTransform = invertTranslateMatrix.multiply(scaleMatrix).multiply(translateMatrix)
      if (!skipScaleCompensation) {
        partTransform = partTransform.multiply(parameterSetTransform.clone().invert())
      }

      this.meshManager.transformMesh(
        bpItemMesh,
        partTransform.transpose(),
        false,
        skipPositionX && skipPositionY && skipPositionZ,
      )
      this.meshManager.transformMesh(
        parameterSetScale,
        parameterSetTransform.transpose(),
        false,
        skipPositionX && skipPositionY && skipPositionZ,
      )

      bpItemMesh.computeWorldMatrix()

      this.placeAboveGround(bpItemMesh, false)

      // make sure we manually update cached hull info for the mesh
      bpItemMesh.metadata.hullBInfo = this.meshManager.getHullBInfo(bpItemMesh)

      this.selectionManager.gizmos.saveTransformation(bpItemMesh, updateStateOnly)
    }

    this.getModelManager().triggerStabilityCheck()
    this.getModelManager().triggerSafeDosingHeightCheck()
  }

  async updateScaleForSinglePart(selectedPartId: string, parameterSetScaleFactor: number[]) {
    const mesh = this.scene.transformNodes.find(
      (m) => this.meshManager.isPartMesh(m) && m.metadata.partId === selectedPartId,
    )
    if (!mesh) {
      return
    }

    const scale = Vector3.FromArray(parameterSetScaleFactor)
    const scaleTransformNode = mesh.metadata.parameterSetScaleNode as TransformNode
    if (scaleTransformNode) {
      if (scaleTransformNode.scaling === scale) {
        return
      }
      scaleTransformNode.scaling = scale
      scaleTransformNode.computeWorldMatrix(true)
    } else {
      mesh.scaling = scale
    }
    mesh.computeWorldMatrix(true)
    this.placeAboveGround(mesh, false)

    // make sure we manually update cached hull info for the mesh
    mesh.metadata.hullBInfo = this.meshManager.getHullBInfo(mesh)

    this.selectionManager.gizmos.saveTransformation(mesh)
    this.getModelManager().triggerStabilityCheck()
    this.getModelManager().triggerSafeDosingHeightCheck()
  }

  /**
   * Save the part's latest transformation matrix
   *
   * @param buildPlanItemId build plan id of the part ( assigned while uploading into the system )
   */
  savePartOrientation(buildPlanItemId: string) {
    this.selectMeshByBuildPlanItemId(buildPlanItemId, null)
    const bpItemMesh = this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)
    if (bpItemMesh) {
      this.selectionManager.gizmos.translateLabelsOrientation(bpItemMesh.getChildMeshes(), false)
      this.selectionManager.gizmos.placeAboveGround(bpItemMesh)
      if (bpItemMesh.metadata && bpItemMesh.metadata.initialTransformation) {
        this.selectionManager.gizmos.saveTransformation(bpItemMesh, false)
      }
    }
    this.checkCollision([bpItemMesh])
  }

  async getBPItemGeometryPropertiesFromCache(payload) {
    await this.waitModuleInitialized()
    return this.modelManager.getBPItemGeometryPropertiesFromCache(payload)
  }

  async getSinglePartGeometryProps(buildPlanItemId?: string, includeSupports?: boolean, ignoreCache?: boolean) {
    if (!buildPlanItemId) {
      await this.waitModuleInitialized()
    }
    return (
      this.modelManager && this.modelManager.getSinglePartGeometryProps(buildPlanItemId, includeSupports, ignoreCache)
    )
  }

  async getPartsBoundingBox(buildPlanItemId?: string, includeSupports?: boolean) {
    await this.waitModuleInitialized()
    return this.modelManager && this.modelManager.getPartsBoundingBox(buildPlanItemId, includeSupports)
  }

  getBpItemDimensions(buildPlanItemId: string, includeSupports?: boolean) {
    const partsBInfo = this.modelManager.getPartsBoundingBox(buildPlanItemId, includeSupports)
    return this.getDimensions(partsBInfo)
  }

  getMeshDimensions(mesh: TransformNode) {
    const meshBInfo = this.modelManager.getMeshBoundingBox(mesh)
    return this.getDimensions(meshBInfo)
  }

  getDimensions(boundingInfo: BoundingInfo) {
    const minimum = boundingInfo.boundingBox.minimum
    const maximum = boundingInfo.boundingBox.maximum

    return {
      xDimension: Math.abs(maximum.x - minimum.x),
      yDimension: Math.abs(maximum.y - minimum.y),
      zDimension: Math.abs(maximum.z - minimum.z),
    }
  }

  selectMeshByBuildPlanItemId(
    buildPlanItemId: string,
    meshId: string,
    select: boolean = false,
    attach: boolean = false,
    deselectIfSelected: boolean = false,
    showGizmo: boolean = false,
  ) {
    if (deselectIfSelected) {
      const selectedMeshes = this.selectionManager.getSelected(false)
      const selectedMesh = selectedMeshes.shift()
      if (selectedMesh) {
        if (buildPlanItemId) {
          const selectedMeshMetadata = selectedMesh.metadata as IPartMetadata
          if (buildPlanItemId === selectedMeshMetadata.buildPlanItemId) {
            this.selectionManager.deselect()
            return
          }
        } else {
          if (meshId === selectedMesh.id) {
            this.selectionManager.deselect()
            return
          }
        }
      }
    }
    if (this.modelManager) {
      const mesh = buildPlanItemId
        ? this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)
        : this.scene.getTransformNodeByID(meshId)
      if (select) {
        this.selectionManager.select([{ part: mesh }], attach)
      }
      if (showGizmo) {
        this.showGizmos()
      }
      return this.selectionManager.getSelected(true)
    }
  }

  selectMeshesByBuildPlanItemIds(
    buildPlanItemIds: string[],
    select: boolean = false,
    attach: boolean = false,
    deselectIfSelected: boolean = false,
    showGizmo: boolean = false,
  ) {
    if (deselectIfSelected) {
      const selectedMeshes = this.selectionManager.getSelected(false)
      if (selectedMeshes.length) {
        const shouldDeselect = selectedMeshes.some((selectedMesh) => {
          const selectedMeshMetadata = selectedMesh.metadata as IPartMetadata
          return buildPlanItemIds.includes(selectedMeshMetadata.buildPlanItemId)
        })

        if (shouldDeselect) {
          this.selectionManager.deselect()
          return
        }
      }
    }

    if (this.modelManager) {
      const items = buildPlanItemIds
        .map((id) => {
          const part = this.meshManager.getBuildPlanItemMeshById(id)
          if (part) {
            return { part }
          }
        })
        .filter((item) => item)

      if (select) {
        this.selectionManager.select(items, attach)
      }

      if (showGizmo) {
        this.showGizmos()
      }

      return this.selectionManager.getSelected(true)
    }
  }

  selectAndHighlightPartsAfterRemove(buildPlanItemId: string, attach: boolean) {
    this.scene.onAfterRenderObservable.addOnce(() => {
      const mesh = this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)

      if (mesh) {
        this.selectionManager.select([{ part: mesh }], attach)
        this.showGizmos()
        this.selectionManager.getSelected(true)
      }
    })
  }

  toggleHighlight(buildPlanItemId: string, meshId: string, highlight: boolean) {
    const mesh = buildPlanItemId
      ? this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)
      : this.scene.getTransformNodeByID(meshId)
    if (mesh) {
      this.selectionManager.highlight([{ part: mesh }], highlight)
      this.animate(true)
    }
  }

  toggleMultiHighlight(buildPlanItemIds: string[], highlight: boolean) {
    const items = buildPlanItemIds
      .map((id) => {
        const part = this.meshManager.getBuildPlanItemMeshById(id)
        if (part) {
          return { part }
        }
      })
      .filter((item) => item)
    if (items.length) {
      this.selectionManager.highlight(items, highlight)
      this.animate(true)
    }
  }

  updateItemPreview(itemId: string) {
    if (this.isDebugMode) {
      this.toggleDebugMode()
    }

    if (!this.selectionManager && !this.modelManager) {
      return
    }

    this.selectionManager.deselect()
    this.clearanceManager.clearClearances()
    store.commit('visualizationModule/showRubberBand', { isShown: false })
    this.modelManager.labelMgr.deactivateLabelInteraction()
    this.hoverPickedObject()
    this.scene.meshes.forEach((mesh) => {
      // off error bvh box
      if (mesh.name === 'KeepOutBox') {
        mesh.isVisible = false
      }
    })
    this.setCrossSection({ isEnabled: false, crossSectionMatrix: [] })
    this.changeViewMode(ViewModeTypes.Layout)

    // per Igal - don't reposition the camera, save last viewed orientation as a thumbnail
    // this.camera.repositionCamera(this.scene, true)
    this.animate(true)
    Tools.CreateScreenshot(this.scene.getEngine(), this.camera, { precision: 1 }, (data: string) => {
      const newdata = Tools.DecodeBase64(data)
      const file = new File([newdata], `${itemId}_preview.png`)
      this.onPreviewCreate.trigger({ itemId, file })
    })
  }

  updateConstraints(buildPlanItemId: string, constraints: IConstraints) {
    const part = this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)
    if (!part) return

    const partMetadata = part.metadata as IPartMetadata
    partMetadata.constraints = constraints
    this.selectionManager.gizmos.recalculateConstraints()
  }

  /**
   * Checks whether engine capabilities are available depending on existing engine and scene
   */
  public hasCapabilities(): boolean {
    const engine = this.scene && this.scene.getEngine()
    const capabilities = engine && engine.getCaps()
    return !!capabilities
  }

  setScreenAspectRatio() {
    const rect = this.engine.getRenderingCanvasClientRect()
    const aspect = rect.height / rect.width
    this.setAspectRatio(aspect)
  }

  repositionCameras() {
    this.getActiveCamera().repositionCamera(this.viewMode.viewModeScene, true)
    this.axesViewer.camera.reset()
    this.setScreenAspectRatio()

    if (this.selectionManager) {
      this.selectionManager.updateGizmoScale()
    }

    if (this.crossSectionManager) {
      this.crossSectionManager.updateGizmoScale()
    }

    if (this.modelManager) {
      this.modelManager.labelMgr.updateLabelGizmoScale()
    }

    if (this.clearanceManager) {
      this.clearanceManager.updateDimensionBoxPosition()
      this.clearanceManager.updateRubberBandScalingAndPlaneNormal()
    }
  }

  zoomToFitCamera() {
    this.getActiveCamera().setNewTarget(this.viewMode.viewModeScene)
    this.setScreenAspectRatio()

    if (this.selectionManager) {
      this.selectionManager.updateGizmoScale()
    }

    if (this.crossSectionManager) {
      this.crossSectionManager.updateGizmoScale()
    }

    if (this.modelManager) {
      this.modelManager.labelMgr.updateLabelGizmoScale()
    }

    if (this.clearanceManager) {
      this.clearanceManager.updateDimensionBoxPosition()
      this.clearanceManager.updateRubberBandScalingAndPlaneNormal()
    }
  }

  setLeftPointerCameraInput(attach: boolean) {
    this.getActiveCamera().togglePointerInput(MouseButtons.LeftButton, attach)
  }

  async loadBuildPlanItemsByConfig(bpItems: IBuildPlanItem[]): Promise<void> {
    const partsConfiguration = await Promise.all(
      bpItems.map(async (bpItem) => ({
        documentModel: await partsService.getPartConfigFile(bpItem.part.id),
        partConfig: await partsService.getPartById(bpItem.part.id),
        buildPlanItemId: bpItem.id,
        partId: bpItem.part.id,
        partName: bpItem.part.name,
        transformation: bpItem.transformationMatrix,
        labels: bpItem.labels,
        overhangs: bpItem.overhangs,
        supports: bpItem.supports,
        constraints: bpItem.constraints,
        supportsBvhFileKey: bpItem.supportsBvhFileKey,
        supportsHullFileKey: bpItem.supportsHullFileKey,
      })),
    )

    await Promise.all(
      partsConfiguration.map(
        async (part) =>
          await this.modelManager.loadModel({
            model: part.documentModel,
            part: part.partConfig,
            buildPlanItemId: part.buildPlanItemId,
            partId: part.partId,
            partName: part.partName,
            transformation: part.transformation,
            labels: part.labels,
            overhangs: part.overhangs,
            supports: part.supports,
            constraints: part.constraints,
            supportsBvhFileKey: part.supportsBvhFileKey,
            supportsHullFileKey: part.supportsHullFileKey,
          }),
      ),
    )
    this.getModelManager().refreshInsights()
    const parts = bpItems.map((bpItem) => this.meshManager.getBuildPlanItemMeshById(bpItem.id))
    this.checkCollision(parts)
  }

  resizeCanvas() {
    if (this.isDisposed) {
      return
    }

    const rect = this.engine.getRenderingCanvasClientRect()
    if (!rect) return

    if (rect.height > MIN_CANVAS_SIZE && rect.width > MIN_CANVAS_SIZE) {
      const alpha = this.getActiveCamera().alpha
      const beta = this.getActiveCamera().beta
      this.engine.setSize(rect.width, rect.height)
      this.zoomToFitCamera()
      this.getActiveCamera().alpha = alpha
      this.getActiveCamera().beta = beta
    }

    this.animate()
  }

  highlightBody(id: string, showHighlight: boolean, bpItemId?: string) {
    const { componentId, geometryId } = this.modelManager.getBodyIdsInfo(id)

    let meshes: AbstractMesh[]
    if (bpItemId) {
      const bpItem = this.meshManager.getBuildPlanItemMeshById(bpItemId)
      meshes = bpItem.getChildMeshes().filter(this.meshManager.isComponentMesh)
    } else {
      meshes = this.scene.meshes.filter(this.meshManager.isComponentMesh)
    }

    const body = meshes.find((mesh) => {
      const metadata = mesh.metadata as IComponentMetadata
      return metadata.componentId === componentId && metadata.geometryId === geometryId
    })

    if (!body) {
      throw new Error(`Body with id: ${id} not found`)
    }

    const tempSelectionMode = this.selectionManager.getSelectionMode()
    const part = this.meshManager.getBuildPlanItemMeshByChild(body) as TransformNode
    const partSelected = this.selectionManager.isSelected({ part })
    this.selectionManager.setSelectionMode(SelectionUnit.Body)
    if (partSelected && !this.selectionManager.isSelected({ body })) {
      this.selectionManager.select([{ body }], true, true)
    }

    this.selectionManager.highlight([{ body }], showHighlight)
    this.selectionManager.setSelectionMode(tempSelectionMode)

    this.animate(true)
  }

  highlightSupport(bpItemId: string, overhangZoneName: string, showHighlight: boolean) {
    const bpItem = this.meshManager.getBuildPlanItemMeshById(bpItemId)
    const [support] = bpItem.getChildTransformNodes(
      false,
      (tn) => tn.name === SUPPORT && tn.metadata.belongsToOverhangElementName === overhangZoneName,
    )

    if (support) {
      support.getChildMeshes(false, this.meshManager.isSupportMesh).forEach((child) => {
        const isSelected = this.selectionManager.isSelected({ part: bpItem }) // bpItem.metadata.color !== DEFAULT_COLOR
        this.meshManager.setInstancedBufferColor(child as InstancedMesh, showHighlight, isSelected)
      })
    }

    this.animate(true)
  }

  selectBodies(
    bodyIds: Array<{ buildPlanItemId: string; componentId: string; geometryId: string }>,
    attach: boolean = false,
  ) {
    const bpItems = [...new Set(bodyIds.map((body) => body.buildPlanItemId))].map((buildPlanItemId) =>
      this.meshManager.getBuildPlanItemMeshById(buildPlanItemId),
    )
    const bodies = []
    bpItems.forEach((bpItem) => {
      const bodyIdsOfBuildPlanItem = bodyIds.filter((ids) => ids.buildPlanItemId === bpItem.metadata.buildPlanItemId)
      const meshes = bpItem.getChildMeshes().filter(this.meshManager.isComponentMesh)
      meshes.forEach((body) => {
        const metadata = body.metadata as IComponentMetadata
        const isFound = bodyIdsOfBuildPlanItem.find(
          (ids) => metadata.componentId === ids.componentId && metadata.geometryId === ids.geometryId,
        )

        if (isFound) {
          bodies.push({ body })
        }
      })
    })

    this.selectionManager.select(bodies, attach)
    setTimeout(() => {
      this.animate()
    }, 0)
  }

  // labelId for select all instances of manually placed label
  selectAllInstances(params: {
    geometryIds: string[]
    labelId: string
    attach?: boolean
    silent?: boolean
    isAutomatic?: boolean
    ignoreBodies?: Array<{ buildPlanItemId: string; geometryId: string; componentId: string; id: string }>
  }): LabeledBody[] {
    let bodies = []
    const allowedBodyTypes = params.isAutomatic
      ? [GeometryType.Coupon]
      : [GeometryType.Coupon, GeometryType.Production, GeometryType.Support]
    if (!params.labelId) {
      bodies = this.scene.meshes
        .filter((mesh) => {
          if (
            !(
              this.meshManager.isComponentMesh(mesh) &&
              params.geometryIds.includes(mesh.metadata.geometryId) &&
              allowedBodyTypes.includes(mesh.metadata.bodyType)
            )
          ) {
            return
          }
          const isBj = this.modalityType === PrintingTypes.BinderJet
          const selectedBodies = this.selectionManager.getSelected()
          const originalBody = selectedBodies.find(
            (selected) =>
              selected.metadata.buildPlanItemId ===
              this.meshManager.getBuildPlanItemMeshByChild(mesh).metadata.buildPlanItemId &&
              selected.metadata.componentId === mesh.metadata.componentId &&
              selected.metadata.geometryId === mesh.metadata.geometryId,
          )
          const originalBodyOnOtherItems = selectedBodies.find(
            (selected) =>
              selected.metadata.buildPlanItemId !==
              this.meshManager.getBuildPlanItemMeshByChild(mesh).metadata.buildPlanItemId &&
              selected.metadata.componentId === mesh.metadata.componentId &&
              selected.metadata.geometryId === mesh.metadata.geometryId,
          )

          return !originalBody && (!isBj || (isBj && originalBodyOnOtherItems))
        })
        .map((body) => ({ body }))
        .filter((selectableNode) => !this.selectionManager.isSelected(selectableNode))
        .filter((selectableNode) => {
          // If there are no bodies to ignore - no need to filter
          if (!params.ignoreBodies) {
            return true
          }

          // Check whether the body should be ignored because it is already selected by label all instances
          const bpItemId = this.meshManager.getBuildPlanItemMeshByChild(selectableNode.body).metadata.buildPlanItemId
          const index = params.ignoreBodies.findIndex(
            (b) =>
              b.buildPlanItemId === bpItemId &&
              b.geometryId === selectableNode.body.metadata.geometryId &&
              b.componentId === selectableNode.body.metadata.componentId,
          )
          return index < 0
        })
        .map((selectableNode) => {
          const bpItem = this.meshManager.getBuildPlanItemMeshByChild(selectableNode.body)
          const transformation = getBuildPlanItemTransformationWithoutScale(
            store.getters['buildPlans/buildPlanItemById'](bpItem.metadata.buildPlanItemId),
          )
          const labeledBody: LabeledBodyWIthTransformation = createLabeledBodyWithTransformation(
            bpItem.metadata.buildPlanItemId,
            selectableNode.body.metadata.componentId,
            selectableNode.body.metadata.geometryId,
            bpItem.metadata.partId,
            transformation,
          )

          return labeledBody
        })
    } else {
      const labelMesh = this.scene.meshes.find((m) => m.id === params.labelId && this.meshManager.isLabelMesh(m))
      const targetBodies = this.scene.meshes.filter((mesh) => {
        if (
          !(
            this.meshManager.isComponentMesh(mesh) &&
            params.geometryIds.includes(mesh.metadata.geometryId) &&
            allowedBodyTypes.includes(mesh.metadata.bodyType)
          )
        ) {
          return
        }
        const isBj = this.modalityType === PrintingTypes.BinderJet
        const originalBody =
          labelMesh.metadata.buildPlanItemId ===
          this.meshManager.getBuildPlanItemMeshByChild(mesh).metadata.buildPlanItemId &&
          labelMesh.metadata.componentId === mesh.metadata.componentId &&
          labelMesh.metadata.geometryId === mesh.metadata.geometryId
        const originalBodyOnOtherItems =
          labelMesh.metadata.buildPlanItemId !==
          this.meshManager.getBuildPlanItemMeshByChild(mesh).metadata.buildPlanItemId &&
          labelMesh.metadata.componentId === mesh.metadata.componentId &&
          labelMesh.metadata.geometryId === mesh.metadata.geometryId

        return !originalBody && (!isBj || (isBj && originalBodyOnOtherItems))
      })
      targetBodies.forEach((body) => {
        const bpItem = this.meshManager.getBuildPlanItemMeshByChild(body)
        const bodyTransformation = this.modelManager.labelMgr.getLabelParentTransformation(body)
        const transformation = this.modelManager.labelMgr.getLabelParentTransformation(labelMesh)
        let newOrientation = this.modelManager.labelMgr.applyTransformationForOrientation(
          labelMesh.metadata.orientation,
          transformation.clone().invert().multiply(bodyTransformation),
          true,
        )

        let relativeTransformation = Matrix.Identity()
        if (labelMesh.metadata.geometryId === body.metadata.geometryId) {
          const rootBody =
            bpItem.metadata.buildPlanItemId === labelMesh.metadata.buildPlanItemId
              ? body
              : (this.meshManager.getComponentMesh(
                body.metadata.componentId,
                body.metadata.geometryId,
                labelMesh.metadata.buildPlanItemId,
              ) as AbstractMesh)
          const originBody = this.meshManager.getComponentMesh(
            labelMesh.metadata.componentId,
            labelMesh.metadata.geometryId,
            labelMesh.metadata.buildPlanItemId,
          )
          relativeTransformation = this.meshManager.getRelativeTransformation(
            rootBody.getWorldMatrix(),
            originBody.getWorldMatrix(),
          )
          newOrientation = this.modelManager.labelMgr.applyTransformationForOrientation(
            newOrientation,
            relativeTransformation,
          )
        }

        const allOrientations = bpItem
          .getChildMeshes()
          .filter(
            (c) =>
              this.meshManager.isLabelMesh(c) &&
              c.metadata.componentId === body.metadata.componentId &&
              c.metadata.geometryId === body.metadata.geometryId &&
              c.metadata.orientation,
          )
          .map((l) => JSON.stringify(this.modelManager.labelMgr.roundOrientation(l.metadata.orientation, 3)))
        const orientationStr = JSON.stringify(this.modelManager.labelMgr.roundOrientation(newOrientation, 3))
        if (allOrientations.includes(orientationStr)) {
          return
        }

        let orientation = this.modelManager.labelMgr.applyTransformationForOrientation(
          labelMesh.metadata.orientation,
          transformation.clone().invert(),
          true,
        )
        if (!relativeTransformation.isIdentity()) {
          orientation = this.modelManager.labelMgr.applyTransformationForOrientation(
            orientation,
            relativeTransformation,
          )
        }

        const placement: Placement = createPlacement(
          bpItem.metadata.buildPlanItemId,
          body.metadata.componentId,
          body.metadata.geometryId,
          this.modelManager.labelMgr.convertToOrientationVertex(orientation),
          labelMesh.metadata.rotationAngle,
        )

        bodies.push(placement)
      })

      const bodiesToSelect = targetBodies
        .map((body) => ({ body }))
        .filter((selectableNode) => !this.selectionManager.isSelected(selectableNode))

      this.selectionManager.select(bodiesToSelect, true)
      setTimeout(() => {
        this.animate()
      }, 0)
    }

    if (!params.silent) {
      this.selectRelatedBodies.trigger({
        bodies,
        add: !!params.ignoreBodies,
        ignored: params.ignoreBodies as LabeledBodyWIthTransformation[],
      })
    }

    return bodies
  }

  rebuildSupports() {
    this.selectionManager.rebuildSupports()
  }

  updateMoveIncrement(increment: number) {
    this.activeBuildPlanMoveIncrement = increment
  }

  updateRotateIncrement(increment: number) {
    this.activeBuildPlanRotateIncrement = increment
  }

  setRotatePartsIndependentlyMode(areRotatePartsIndependentlyMode: boolean) {
    this.selectionManager.gizmos.rotatePartsIndependentlyMode = areRotatePartsIndependentlyMode
    if (areRotatePartsIndependentlyMode) {
      this.disableGizmos(false)
    } else {
      this.enableGizmos()
    }
  }

  /** For all hidden meshes show it as transparent on canvas to make hidden components partly visible */
  showHiddenPartsTransparent() {
    this.getAllHiddenMeshes()
      .filter((mesh) => !mesh.metadata.showAsTransparent)
      .forEach((mesh) => this.meshManager.showAsTransparent(mesh))

    this.animate()
  }

  /** For all hidden meshes that shown as transparent make it completely invisible */
  hideTransparentParts() {
    this.getAllHiddenMeshes()
      .filter((mesh) => mesh.metadata.showAsTransparent && !this.selectionManager.isSelected({ body: mesh }))
      .forEach((mesh) => this.meshManager.totalHide(mesh))

    this.animate()
  }

  /**
   * Set visibility for build plan item. Change compoment metadata "isHidden" flag.
   * @param buildPlanItemId Build plan item id.
   * @param makeHidden Make visible if false.
   */
  setIsHiddenForBuildPlanItemMesh(buildPlanItemId: string, makeHidden: boolean, showHiddenAsTransparent: boolean) {
    const items = [{ buildPlanItemId, bodyIds: [] }]
    this.updateItemListForDuplicateTool(items)

    for (const item of items) {
      const buildPlanItemMesh = this.meshManager.getBuildPlanItemMeshById(item.buildPlanItemId)
      const children = buildPlanItemMesh.getChildMeshes()
      for (const mesh of children) {
        if (this.meshManager.isComponentMesh(mesh) || mesh.name === LINE_SUPPORT) {
          if (mesh.metadata.isHidden === makeHidden) {
            continue
          }

          this.meshManager.setIsHidden(mesh as InstancedMesh, makeHidden, showHiddenAsTransparent)

          !makeHidden || showHiddenAsTransparent
            ? this.gpuPicker.addPickingObjects([mesh as Mesh])
            : this.gpuPicker.removePickingObjects([mesh as Mesh])
        } else if (this.meshManager.isLabelMesh(mesh as AbstractMesh)) {
          mesh.metadata.isHidden = makeHidden
        } else if (this.meshManager.isOverhangSurface(mesh)) {
          ; (mesh as AbstractMesh).isVisible = !makeHidden
          // need for fix z-fighting when camera is directed along -z axis
          makeHidden ? (mesh.position.z += Epsilon) : (mesh.position.z -= Epsilon)
        }
      }
    }

    this.animate(true)
  }

  /** Get all hidden (component or support) meshes (if mesh.metadata.isHidden) */
  private getAllHiddenMeshes(): InstancedMesh[] {
    const hiddenMeshes: InstancedMesh[] = []
    Array.from(this.getSceneMetadata().buildPlanItems.values()).forEach((bpItem) => {
      bpItem.getChildMeshes(false).forEach((mesh) => {
        if (
          (this.meshManager.isComponentMesh(mesh) || mesh.name === LINE_SUPPORT) &&
          mesh.metadata &&
          mesh.metadata.isHidden &&
          mesh instanceof InstancedMesh
        ) {
          hiddenMeshes.push(mesh as InstancedMesh)
        }
      })
    })

    return hiddenMeshes
  }

  private drawPolylineMarkers(polylines: any[], polylinesMarker: Mesh, meshName: string, markerRadius: number) {
    for (const polyline of polylines) {
      const instancedMarker = polylinesMarker.createInstance(meshName)
      if (polyline.length === 1) {
        instancedMarker.position = polyline[0]
      } else {
        instancedMarker.position = polyline[0].add(polyline[1]).divide(new Vector3(2, 2, 2))
      }
      instancedMarker.sourceMesh.onBeforeRenderObservable.add(() => {
        instancedMarker.scaling.x = instancedMarker.scaling.y = Math.min(this.scene.activeCamera.orthoRight / 100, 1)
      })
    }
  }

  private placeAboveGround(mesh: TransformNode, ignoreMinZCheck?: boolean) {
    this.selectionManager.gizmos.placeAboveGround(mesh, ignoreMinZCheck)
  }

  private registerRenderEvents(): void {
    this.scene.onNewMeshAddedObservable.add((mesh) => {
      if (mesh.name !== BVH_BOX) {
        mesh.onAfterWorldMatrixUpdateObservable.add(() => {
          this.animate()
        })

        this.meshesToRender.set(mesh.uniqueId, false)
        this.animate()
      }
    })

    this.scene.onMeshRemovedObservable.add((mesh) => {
      if (mesh.name !== BVH_BOX) {
        if (this.meshesToRender.has(mesh.uniqueId)) {
          this.meshesToRender.delete(mesh.uniqueId)
        }

        this.animate()
      }
    })

    let cameraPosition
    this.scene.onPointerObservable.add((eventData) => {
      if (eventData.type === PointerEventTypes.POINTERDOWN) {
        cameraPosition = this.getActiveCamera().position.clone()
      }

      if (eventData.type === PointerEventTypes.POINTERUP) {
        const isCameraPositionChanged = this.cameraPositionChanged(cameraPosition)
        if (this.scene.metadata && isCameraPositionChanged) {
          this.scene.metadata.updateGpuPicker = true
        }

        setTimeout(() => this.animate(), 0)
      }

      if (eventData.type === PointerEventTypes.POINTERWHEEL || eventData.type === PointerEventTypes.POINTERMOVE) {
        this.animate(true)
      }
    })

    // the canvas/window resize event handler
    window.addEventListener('resize', this.boundResizeCanvas)
  }

  private registerRenderLoop() {
    this.renderLoop = () => {
      this.isRenderLoopStopped = false
      let notReadyMeshesNb: number
      let readyNotRenderedMeshesNb: number

      if (this.meshesToRender.size !== 0) {
        notReadyMeshesNb = 0
        readyNotRenderedMeshesNb = 0

        for (const [meshUniqueId, isRendered] of this.meshesToRender) {
          const mesh = this.viewMode.viewModeScene.getMeshByUniqueID(meshUniqueId)
          if (!mesh || isRendered) {
            continue
          }

          if (mesh.isReady(true)) {
            this.meshesToRender.set(meshUniqueId, true)
            readyNotRenderedMeshesNb += 1
          } else {
            notReadyMeshesNb += 1
          }
        }
      }

      if (!(readyNotRenderedMeshesNb && notReadyMeshesNb)) {
        this.meshesToRender.clear()
      }

      if (this.scene.metadata) {
        if (this.scene.metadata.updateGpuPicker !== false) {
          this.scene.metadata.updateGpuPicker = true
        }
      }

      if (!this.isSceneCheckDisabled) {
        const subMeshesNotReady = this.scene.meshes.some((m) => {
          if (
            m instanceof InstancedMesh &&
            m.sourceMesh.material instanceof MultiMaterial &&
            m.sourceMesh.subMeshes.length > 1
          ) {
            return m.sourceMesh.subMeshes.some(
              (subMesh) =>
                !(m.sourceMesh.material as MultiMaterial).subMaterials[subMesh.materialIndex].isReadyForSubMesh(
                  m.sourceMesh,
                  subMesh,
                  true,
                ),
            )
          }
        })
        if (subMeshesNotReady) {
          return
        }

        const materialNotReady = this.scene.meshes.some((m) => {
          if (
            m instanceof InstancedMesh &&
            m.sourceMesh.material &&
            (m.sourceMesh.material instanceof ShaderMaterial || m.sourceMesh.material instanceof StandardMaterial)
          ) {
            return !m.sourceMesh.material.isReady(m, true)
          }
        })
        if (materialNotReady) {
          return
        }
      }

      this.render()

      if (
        notReadyMeshesNb ||
        this.getActiveCamera().inertialAlphaOffset !== 0 ||
        this.getActiveCamera().inertialBetaOffset !== 0
      ) {
        this.needRender = true
      } else {
        this.needRender = false
      }

      if (!this.needRender) {
        this.isRenderLoopStopped = true
        this.scene.getEngine().stopRenderLoop(this.renderLoop)
      }
    }
  }

  private runRenderLoop(suppressGpuPicker: boolean, isContinuously: boolean) {
    if (suppressGpuPicker) {
      this.suppressGpuPicker()
    }

    if (isContinuously) {
      this.scene.getEngine().runRenderLoop(() => {
        this.render()
      })

      return
    }

    this.scene.getEngine().runRenderLoop(this.renderLoop)
  }

  private render() {
    if (this.fpsTracker && this.isDebugMode) {
      this.fpsTracker.innerText = `${this.engine.getFps().toFixed()} fps`
    }

    if (this.clearanceManager && this.isContinuousRender) {
      this.clearanceManager.updateRubberBandScalingAndPlaneNormal()
      const dimensionBoxes = store.getters['visualizationModule/dimensionBoxes'] as DimensionBox[]
      if (dimensionBoxes && dimensionBoxes.length > 0) {
        setTimeout(() => this.clearanceManager.updateDimensionBoxPosition(), 0)
      }
    }
    this.viewMode.viewModeScene.render()
    this.axesViewer.camera.alpha = this.getActiveCamera().alpha
    this.axesViewer.camera.beta = this.getActiveCamera().beta
    this.axesViewer.getScene().render()
  }

  private setAspectRatio(aspect: number) {
    const delta = Math.abs(
      (this.getActiveCamera().orthoRight - this.getActiveCamera().orthoLeft) * aspect -
      (this.getActiveCamera().orthoTop - this.getActiveCamera().orthoBottom),
    )

    if (this.activeAspectRatio) {
      this.getActiveCamera().orthoTop =
        aspect <= this.activeAspectRatio
          ? this.getActiveCamera().orthoTop - delta / 2
          : this.getActiveCamera().orthoTop + delta / 2
      this.getActiveCamera().orthoBottom =
        aspect <= this.activeAspectRatio
          ? this.getActiveCamera().orthoBottom + delta / 2
          : this.getActiveCamera().orthoBottom - delta / 2
    }

    this.axesViewer.camera.orthoTop = this.axesViewer.camera.orthoRight * aspect
    this.axesViewer.camera.orthoBottom = this.axesViewer.camera.orthoLeft * aspect
    this.activeAspectRatio = aspect
  }

  private createMaterialList(): void {
    const blueMaterial = new StandardMaterial('blueMat', this.scene)
    blueMaterial.diffuseColor = Color3.Blue()
    blueMaterial.backFaceCulling = false
    blueMaterial.freeze()

    const yellowMaterial = new StandardMaterial(REGULAR_YELLOW_MATERIAL, this.scene)
    yellowMaterial.diffuseColor = Color3.Yellow()
    yellowMaterial.specularColor = new Color3(0.3, 0.3, 0.3)
    yellowMaterial.backFaceCulling = false
    yellowMaterial.freeze()

    const redMaterial = new StandardMaterial('redMat', this.scene)
    redMaterial.diffuseColor = Color3.Red()
    redMaterial.backFaceCulling = false
    redMaterial.freeze()

    const boxMaterial = new StandardMaterial(BOX_MATERIAL_NAME, this.scene)
    boxMaterial.diffuseColor = Color3.Yellow()
    boxMaterial.specularColor = Color3.Black()
    boxMaterial.alpha = 0.3
    boxMaterial.backFaceCulling = false
    boxMaterial.freeze()

    const errorBoxMaterial = new StandardMaterial(ERROR_BOX_MATERIAL_NAME, this.scene)
    errorBoxMaterial.diffuseColor = Color3.Red()
    errorBoxMaterial.specularColor = Color3.Black()
    errorBoxMaterial.alpha = 0.3
    errorBoxMaterial.backFaceCulling = false
    errorBoxMaterial.freeze()

    const wireframeMaterial = new StandardMaterial(WIREFRAME_MATERIAL_NAME, this.scene)
    wireframeMaterial.wireframe = true
    wireframeMaterial.disableLighting = true
    wireframeMaterial.freeze()

    ////////////////////////////////////////
    // mesh outside materials start
    ////////////////////////////////////////

    const meshOutsideMaterial = new StandardMaterial(DEFAULT_MATERIAL_NAME, this.scene)
    meshOutsideMaterial.diffuseColor = new Color3(0.5, 0.5, 0.5)
    meshOutsideMaterial.ambientColor = new Color3(0.5, 0.5, 0.5)
    meshOutsideMaterial.specularColor = new Color3(0.3, 0.3, 0.3)
    meshOutsideMaterial.sideOrientation = Mesh.DOUBLESIDE
    meshOutsideMaterial.backFaceCulling = false
    meshOutsideMaterial.twoSidedLighting = true

    const measurementMaterial = new StandardMaterial(INSPECTION_MATERIAL_NAME, this.scene)
    measurementMaterial.diffuseColor = REGULAR_MAGENTA
    measurementMaterial.ambientColor = REGULAR_MAGENTA
    measurementMaterial.specularColor = new Color3(0.3, 0.3, 0.3)
    measurementMaterial.sideOrientation = Mesh.DOUBLESIDE
    measurementMaterial.alpha = SEMITRANSPARENCY_ALPHA
    measurementMaterial.backFaceCulling = false
    measurementMaterial.twoSidedLighting = true

    const measurementHighligtMaterial = measurementMaterial.clone(INSPECTION_HIGHLIGHT_MATERIAL_NAME)
    measurementHighligtMaterial.diffuseColor = PRIMARY_CYAN

    const highlightMaterial = meshOutsideMaterial.clone(HIGHLIGHT_MATERIAL_NAME)
    highlightMaterial.diffuseColor = PRIMARY_CYAN

    const selectionMaterial = meshOutsideMaterial.clone(SELECTION_MATERIAL_NAME)
    selectionMaterial.diffuseColor = PRIMARY_SELECTION

    const meshOutsideSemiTransparentMaterial = meshOutsideMaterial.clone(SEMI_TRANSPARENT_DEFAULT_MATERIAL_NAME)
    meshOutsideSemiTransparentMaterial.alpha = SEMITRANSPARENCY_ALPHA
    meshOutsideSemiTransparentMaterial.transparencyMode = StandardMaterial.MATERIAL_ALPHABLEND

    const meshInsideSemiTransparentMaterial = new StandardMaterial(SEMI_TRANSPARENT_INSIDE_MATERIAL_NAME, this.scene)
    meshInsideSemiTransparentMaterial.diffuseColor = new Color3(0, 0, 0)
    meshInsideSemiTransparentMaterial.specularColor = new Color3(0, 0, 0)
    meshInsideSemiTransparentMaterial.emissiveColor = new Color3(0.5, 0.5, 0.5)
    meshInsideSemiTransparentMaterial.ambientColor = new Color3(0, 0, 0)
    meshInsideSemiTransparentMaterial.disableLighting = true
    meshInsideSemiTransparentMaterial.backFaceCulling = true
    meshInsideSemiTransparentMaterial.alpha = SEMITRANSPARENCY_ALPHA
    meshInsideSemiTransparentMaterial.sideOrientation = Mesh.FRONTSIDE
    meshInsideSemiTransparentMaterial.transparencyMode = StandardMaterial.MATERIAL_ALPHABLEND
    meshInsideSemiTransparentMaterial.zOffset = -1 // to prevent z-fighting with touching meshes / supports

    const shaderRegularMaterial = meshOutsideMaterial.clone(SHADER_DEFAULT_MATERIAL_NAME)
    shaderRegularMaterial.diffuseColor = SHADER_DEFAULT_COLOR

    const shaderHighlightMaterial = meshOutsideMaterial.clone(SHADER_HIGHLIGHT_MATERIAL_NAME)
    shaderHighlightMaterial.diffuseColor = SHADER_CYAN

    const shaderSelectionMaterial = meshOutsideMaterial.clone(SHADER_SELECTION_MATERIAL_NAME)
    shaderSelectionMaterial.diffuseColor = SHADER_SELECTION

    ////////////////////////////////////////
    // mesh outside materials end
    ////////////////////////////////////////

    const meshInsideMaterial = new StandardMaterial(MESH_INSIDE_MATERIAL_NAME, this.scene)
    meshInsideMaterial.diffuseColor = new Color3(0, 0, 0)
    meshInsideMaterial.specularColor = new Color3(0, 0, 0)
    meshInsideMaterial.emissiveColor = new Color3(0.5, 0.5, 0.5)
    meshInsideMaterial.ambientColor = new Color3(0, 0, 0)
    meshInsideMaterial.disableLighting = true
    meshInsideMaterial.backFaceCulling = true
    meshInsideMaterial.zOffset = -1 // to prevent z-fighting with touching meshes / supports

    const crossSectionPlaneMaterial = new StandardMaterial(CROSS_SECTION_PLANE_MATERIAL, this.scene)
    crossSectionPlaneMaterial.diffuseColor = new Color3(0, 0, 0)
    crossSectionPlaneMaterial.emissiveColor = new Color3(0, 0.37, 0.72)
    crossSectionPlaneMaterial.specularColor = new Color3(0, 0, 0)
    crossSectionPlaneMaterial.ambientColor = new Color3(0, 0, 0)
    crossSectionPlaneMaterial.alpha = 0.1

    const slicingMeshMaterial = new StandardMaterial(SLICING_MESH_MATERIAL, this.scene)
    slicingMeshMaterial.diffuseColor = new Color3(0, 0, 0)
    slicingMeshMaterial.specularColor = new Color3(0, 0, 0)
    slicingMeshMaterial.emissiveColor = new Color3(0.3, 0.3, 0.3)
    slicingMeshMaterial.ambientColor = new Color3(0, 0, 0)
    slicingMeshMaterial.backFaceCulling = true
    if (this.hasCapabilities()) {
      slicingMeshMaterial.useLogarithmicDepth = true
    }
    slicingMeshMaterial.disableLighting = true
    slicingMeshMaterial.freeze()

    const crossSectionMeshMaterial = new StandardMaterial(CROSS_SECTION_MESH_MATERIAL, this.scene)
    crossSectionMeshMaterial.diffuseColor = new Color3(0, 0, 0)
    crossSectionMeshMaterial.specularColor = new Color3(0, 0, 0)
    crossSectionMeshMaterial.emissiveColor = new Color3(0, 0.37, 0.72)
    crossSectionMeshMaterial.ambientColor = new Color3(0, 0, 0)
    crossSectionMeshMaterial.disableLighting = true
    crossSectionMeshMaterial.backFaceCulling = true
    crossSectionMeshMaterial.zOffset = -1 // to prevent z-fighting with touching meshes / supports

    const crossSectionSemiTransparentMeshMaterial = crossSectionMeshMaterial.clone(
      CROSS_SECTION_SEMI_TRANSPARENT_MESH_MATERIAL,
    )
    crossSectionSemiTransparentMeshMaterial.sideOrientation = Mesh.FRONTSIDE
    crossSectionSemiTransparentMeshMaterial.alpha = SEMITRANSPARENCY_ALPHA
    crossSectionSemiTransparentMeshMaterial.transparencyMode = StandardMaterial.MATERIAL_ALPHABLEND

    const buildPlateMaterial = new GridMaterial(BUILD_PLATE_MATERIAL_NAME, this.scene)
    buildPlateMaterial.mainColor = new Color3(0.95, 0.95, 0.95)
    buildPlateMaterial.lineColor = new Color3(0.8, 0.8, 0.8)
    buildPlateMaterial.gridRatio = BUILD_PLATE_GRID_RATIO
    buildPlateMaterial.majorUnitFrequency = 1

    if (this.canvas) {
      const recoaterArrowTexture = new DynamicTexture(
        'recoaterArrowTexture',
        {
          width: 256,
          height: 256,
        },
        this.scene,
        false,
      )
      const font = 'normal 35px Arial'
      recoaterArrowTexture.drawText(
        (i18n.t('recoater') as string).toUpperCase(),
        32,
        122,
        font,
        'black',
        'whitesmoke',
        false,
        true,
      )
      const recoaterArrowMaterial = new StandardMaterial(RECOATER_ARROW_MATERIAL_NAME, this.scene)
      recoaterArrowMaterial.diffuseTexture = recoaterArrowTexture
      recoaterArrowMaterial.ambientColor = new Color3(0.3, 0.3, 0.3)
      recoaterArrowMaterial.specularColor = new Color3(0, 0, 0)
      recoaterArrowMaterial.backFaceCulling = false
      recoaterArrowMaterial.freeze()

      const gasFlowArrowTexture = new DynamicTexture(
        'gasFlowArrowTexture',
        {
          width: 256,
          height: 256,
        },
        this.scene,
        false,
      )
      gasFlowArrowTexture.drawText(
        (i18n.t('gasFlow') as string).toUpperCase(),
        32,
        122,
        font,
        'black',
        'whitesmoke',
        false,
        true,
      )
      const gasFlowArrowMaterial = new StandardMaterial(GAS_FLOW_ARROW_MATERIAL_NAME, this.scene)
      gasFlowArrowMaterial.diffuseTexture = gasFlowArrowTexture
      gasFlowArrowMaterial.ambientColor = new Color3(0.3, 0.3, 0.3)
      gasFlowArrowMaterial.specularColor = new Color3(0, 0, 0)
      gasFlowArrowMaterial.backFaceCulling = false

      const font2 = 'normal 32px Arial'
      const printHeadArrowTexture = new DynamicTexture(
        'printHeadArrowTexture',
        {
          width: 256,
          height: 256,
        },
        this.scene,
        false,
      )
      printHeadArrowTexture.drawText(
        (i18n.t('printHead') as string).toUpperCase(),
        32,
        122,
        font2,
        'black',
        'whitesmoke',
        false,
        true,
      )
      const printHeadArrowMaterial = new StandardMaterial(PRINT_HEAD_ARROW_MATERIAL_NAME, this.scene)
      printHeadArrowMaterial.diffuseTexture = printHeadArrowTexture
      printHeadArrowMaterial.ambientColor = new Color3(0.3, 0.3, 0.3)
      printHeadArrowMaterial.specularColor = new Color3(0, 0, 0)
      printHeadArrowMaterial.backFaceCulling = false

      const font3 = 'normal 65px Arial'
      const frontIndicatorTexture = new DynamicTexture(
        'frontIndicatorTexture',
        {
          width: 256,
          height: 128,
        },
        this.scene,
        false,
      )
      frontIndicatorTexture.drawText(
        (i18n.t('front') as string).toUpperCase(),
        16,
        100,
        font3,
        'black',
        'whitesmoke',
        false,
        true,
      )
      const frontIndicatorMaterial = new StandardMaterial(FRONT_INDICATOR_MATERIAL_NAME, this.scene)
      frontIndicatorMaterial.diffuseTexture = frontIndicatorTexture
      frontIndicatorMaterial.ambientColor = new Color3(0.3, 0.3, 0.3)
      frontIndicatorMaterial.specularColor = new Color3(0, 0, 0)
      frontIndicatorMaterial.backFaceCulling = false
    }

    const meshOutsideDisabled = new StandardMaterial('meshOutsideDisabled', this.scene)
    meshOutsideDisabled.diffuseColor = new Color3(0.3, 0.3, 0.3)
    meshOutsideDisabled.specularColor = new Color3(0, 0, 0)
    meshOutsideDisabled.sideOrientation = Mesh.BACKSIDE
    meshOutsideDisabled.alpha = 0.7
    meshOutsideDisabled.backFaceCulling = true

    const meshInsideDisabled = new StandardMaterial('meshInsideDisabled', this.scene)
    meshInsideDisabled.diffuseColor = new Color3(0.3, 0.3, 0.3)
    meshInsideDisabled.specularColor = new Color3(0, 0, 0)
    meshInsideDisabled.sideOrientation = Mesh.BACKSIDE
    meshInsideDisabled.alpha = 0.7
    meshInsideDisabled.backFaceCulling = true

    const tempMeshMaterial = new StandardMaterial('tempMeshMaterial', this.scene)
    tempMeshMaterial.diffuseColor = new Color3(0.3, 0.3, 0.3)
    tempMeshMaterial.specularColor = new Color3(0, 0, 0)
    tempMeshMaterial.backFaceCulling = false

    const overhangMaterial = new StandardMaterial(OVERHANG_MATERIAL, this.scene)
    overhangMaterial.diffuseColor = OVERHANG_COLOR
    overhangMaterial.ambientColor = OVERHANG_COLOR
    overhangMaterial.specularColor = new Color3(0, 0, 0)
    overhangMaterial.sideOrientation = Mesh.BACKSIDE
    overhangMaterial.backFaceCulling = true
    overhangMaterial.maxSimultaneousLights = 1
    overhangMaterial.zOffset = -1

    const overhangMaterialInside = new StandardMaterial(OVERHANG_INSIDE_MATERIAL, this.scene)
    overhangMaterialInside.emissiveColor = OVERHANG_COLOR
    overhangMaterialInside.disableLighting = true
    overhangMaterialInside.sideOrientation = Mesh.FRONTSIDE
    overhangMaterialInside.backFaceCulling = true
    overhangMaterialInside.zOffset = -1

    const overhangErrorMaterial = new StandardMaterial(OVERHANG_ERROR_MATERIAL, this.scene)
    overhangErrorMaterial.diffuseColor = Color3.Red()
    overhangErrorMaterial.ambientColor = Color3.Red()
    overhangErrorMaterial.specularColor = new Color3(0, 0, 0)
    overhangErrorMaterial.sideOrientation = Mesh.BACKSIDE
    overhangErrorMaterial.backFaceCulling = true
    overhangErrorMaterial.maxSimultaneousLights = 1
    overhangErrorMaterial.zOffset = -1

    const overhangHoverMaterial = new StandardMaterial(OVERHANG_HOVER_MATERIAL, this.scene)
    overhangHoverMaterial.diffuseColor = PRIMARY_CYAN
    overhangHoverMaterial.ambientColor = PRIMARY_CYAN
    overhangHoverMaterial.specularColor = new Color3(0, 0, 0)
    overhangHoverMaterial.sideOrientation = Mesh.BACKSIDE
    overhangHoverMaterial.backFaceCulling = true
    overhangHoverMaterial.maxSimultaneousLights = 1
    overhangHoverMaterial.zOffset = -1

    const overhangSelectionMaterial = new StandardMaterial(OVERHANG_SELECTION_MATERIAL, this.scene)
    overhangSelectionMaterial.diffuseColor = OVERHANG_SELECTION_COLOR
    overhangSelectionMaterial.ambientColor = OVERHANG_SELECTION_COLOR
    overhangSelectionMaterial.specularColor = Color3.Black()
    overhangSelectionMaterial.sideOrientation = Mesh.BACKSIDE
    overhangSelectionMaterial.backFaceCulling = false
    overhangSelectionMaterial.maxSimultaneousLights = 1
    overhangSelectionMaterial.zOffset = -1

    const supportMaterial = new StandardMaterial(SUPPORT_MATERIAL, this.scene)
    supportMaterial.diffuseColor = new Color3(0.5, 0.5, 0.5)
    supportMaterial.ambientColor = new Color3(0.5, 0.5, 0.5)
    supportMaterial.specularColor = new Color3(0.3, 0.3, 0.3)
    supportMaterial.sideOrientation = Mesh.BACKSIDE
    supportMaterial.backFaceCulling = true

    const supportInsideMaterial = new StandardMaterial(SUPPORT_INSIDE_MATERIAL, this.scene)
    supportInsideMaterial.emissiveColor = SECONDARY_INDIGO.scale(2.0)
    supportInsideMaterial.disableLighting = true
    supportInsideMaterial.sideOrientation = Mesh.FRONTSIDE
    supportInsideMaterial.backFaceCulling = true

    const supportSelectionMaterial = new StandardMaterial(SUPPORT_SELECTION_MATERIAL, this.scene)
    supportSelectionMaterial.diffuseColor = REGULAR_ORANGE
    supportSelectionMaterial.ambientColor = REGULAR_ORANGE
    supportSelectionMaterial.specularColor = new Color3(0, 0, 0)
    supportSelectionMaterial.sideOrientation = Mesh.BACKSIDE
    supportSelectionMaterial.backFaceCulling = true

    const supportSemiTransparentMaterial = supportMaterial.clone(SEMI_TRANSPARENT_SUPPORT_MATERIAL)
    supportSemiTransparentMaterial.alpha = SEMITRANSPARENCY_ALPHA
    supportSemiTransparentMaterial.transparencyMode = StandardMaterial.MATERIAL_ALPHABLEND

    const supportSemiTransparentInsideMaterial = supportInsideMaterial.clone(SEMI_TRANSPARENT_SUPPORT_INSIDE_MATERIAL)
    supportSemiTransparentInsideMaterial.alpha = SEMITRANSPARENCY_ALPHA
    supportSemiTransparentInsideMaterial.transparencyMode = StandardMaterial.MATERIAL_ALPHABLEND

    const supportSemiTransparentSelectionMaterial = supportSelectionMaterial.clone(
      SEMI_TRANSPARENT_SUPPORT_SELECTION_MATERIAL,
    )
    supportSemiTransparentSelectionMaterial.alpha = SEMITRANSPARENCY_ALPHA
    supportSemiTransparentSelectionMaterial.transparencyMode = StandardMaterial.MATERIAL_ALPHABLEND

    const labelMaterial = new StandardMaterial(LABEL_MATERIAL_NAME, this.scene)
    labelMaterial.diffuseColor = DEFAULT_GREY
    labelMaterial.ambientColor = DEFAULT_GREY
    labelMaterial.specularColor = DEFAULT_LIGHT_GREY
    labelMaterial.sideOrientation = Mesh.FRONTSIDE
    labelMaterial.backFaceCulling = false
    labelMaterial.zOffset = -1
    labelMaterial.twoSidedLighting = true

    const labelSemiTransparentMaterial = labelMaterial.clone(SEMI_TRANSPARENT_LABEL_MATERIAL_NAME)
    labelSemiTransparentMaterial.alpha = SEMITRANSPARENCY_ALPHA
    labelSemiTransparentMaterial.transparencyMode = StandardMaterial.MATERIAL_ALPHABLEND

    const labelMaterialInside = new StandardMaterial(LABEL_INSIDE_MATERIAL, this.scene)
    labelMaterialInside.emissiveColor = Color3.White()
    labelMaterialInside.sideOrientation = Mesh.FRONTSIDE
    meshInsideMaterial.disableLighting = true
    meshInsideMaterial.backFaceCulling = true
    labelMaterialInside.zOffset = -1

    const polylinesMarkerWarningMaterial = new StandardMaterial(POLYLINES_MARKER_WARNING_MATERIAL, this.scene)
    polylinesMarkerWarningMaterial.disableLighting = true
    polylinesMarkerWarningMaterial.emissiveColor = new Color3(1, 0.6, 0)
    polylinesMarkerWarningMaterial.backFaceCulling = false
    polylinesMarkerWarningMaterial.alpha = 0.5

    const polylinesMarkerErrorMaterial = new StandardMaterial(POLYLINES_MARKER_ERROR_MATERIAL, this.scene)
    polylinesMarkerErrorMaterial.disableLighting = true
    polylinesMarkerErrorMaterial.emissiveColor = new Color3(1, 0, 0)
    polylinesMarkerErrorMaterial.backFaceCulling = false
    polylinesMarkerErrorMaterial.alpha = 0.5

    const sinterPlateMaterial = new GridMaterial(SINTER_PLATE_MATERIAL_NAME, this.scene)
    sinterPlateMaterial.mainColor = new Color3(0.95, 0.95, 0.95)
    sinterPlateMaterial.lineColor = new Color3(0.99, 0.31, 0)
    sinterPlateMaterial.gridRatio = BUILD_PLATE_GRID_RATIO
    sinterPlateMaterial.majorUnitFrequency = 1
    sinterPlateMaterial.sideOrientation = Mesh.DOUBLESIDE
    sinterPlateMaterial.backFaceCulling = false
    sinterPlateMaterial.zOffset = -1
    sinterPlateMaterial.opacity = 0.8

    const partPreviewPlateMaterial = new GridMaterial(PART_PREVIEW_PLATE_MATERIAL_NAME, this.scene)
    partPreviewPlateMaterial.mainColor = new Color3(0.95, 0.95, 0.95)
    partPreviewPlateMaterial.lineColor = new Color3(0.8, 0.8, 0.8)
    partPreviewPlateMaterial.gridRatio = BUILD_PLATE_GRID_RATIO
    partPreviewPlateMaterial.majorUnitFrequency = 1
    partPreviewPlateMaterial.sideOrientation = Mesh.DOUBLESIDE
    partPreviewPlateMaterial.backFaceCulling = false
    partPreviewPlateMaterial.zOffset = -1
    partPreviewPlateMaterial.opacity = 0.8

    const failedDuplicateMaterial = new StandardMaterial(FAILED_DUPLCIATE_MATERIAL, this.scene)
    failedDuplicateMaterial.diffuseColor = new Color3(0.6, 0, 0)
    failedDuplicateMaterial.ambientColor = new Color3(0.3, 0.3, 0.3)
    failedDuplicateMaterial.specularColor = new Color3(0, 0, 0)
    failedDuplicateMaterial.sideOrientation = Mesh.BACKSIDE
    failedDuplicateMaterial.backFaceCulling = true

    const defectMaterial = new StandardMaterial(DEFECT_MATERIAL, this.scene)
    defectMaterial.emissiveColor = Color3.White()
    defectMaterial.disableLighting = true
    defectMaterial.sideOrientation = Mesh.BACKSIDE
    defectMaterial.backFaceCulling = true
    defectMaterial.zOffset = -1

    const defectInsideMaterial = new StandardMaterial(DEFECT_INSIDE_MATERIAL, this.scene)
    defectInsideMaterial.emissiveColor = Color3.White()
    defectInsideMaterial.disableLighting = true
    defectInsideMaterial.sideOrientation = Mesh.FRONTSIDE
    defectInsideMaterial.backFaceCulling = true
    defectInsideMaterial.zOffset = -1

    const cutoutMaterial = new StandardMaterial(CUTOUT_MATERIAL, this.scene)
    cutoutMaterial.backFaceCulling = false
    cutoutMaterial.diffuseColor = Color3.Gray()
    cutoutMaterial.alpha = 0.2
    cutoutMaterial.specularColor = Color3.Black()

    const footPrintMaterial = new StandardMaterial(CENTER_OF_GRAVITY_MATERIAL_NAME, this.scene)
    footPrintMaterial.diffuseColor = REGULAR_GREEN
    footPrintMaterial.emissiveColor = Color3.Black()
    footPrintMaterial.specularColor = Color3.Black()
    footPrintMaterial.ambientColor = Color3.Black()
    footPrintMaterial.backFaceCulling = false
    footPrintMaterial.transparencyMode = 0.5

    const cgMaterial = new StandardMaterial(FOOTPRINT_MATERIAL_NAME, this.scene)
    cgMaterial.diffuseColor = REGULAR_GREEN
    cgMaterial.emissiveColor = Color3.Black()
    cgMaterial.specularColor = Color3.Black()
    cgMaterial.ambientColor = Color3.Black()
    cgMaterial.backFaceCulling = false

    MeshShader.initializeShaderIncludes()
  }

  private findClosestNewAngle(angle: number) {
    const newAngleFloor = Math.floor(angle / (2 * Math.PI)) * 2 * Math.PI
    const newAngleCeil = Math.ceil(angle / (2 * Math.PI)) * 2 * Math.PI
    let newAngle
    if (Math.abs(angle - newAngleFloor) <= Math.abs(angle - newAngleCeil)) {
      newAngle = newAngleFloor
    } else {
      newAngle = newAngleCeil
    }
    return newAngle
  }

  private animateActiveCamera(alpha: number, beta: number) {
    const speed = 75
    const frameCount = 50

    this.animate(true, true)

    const activeCamera = this.getActiveCamera()
    const currentAlpha = activeCamera.alpha
    const newAlpha = this.findClosestNewAngle(currentAlpha) + alpha
    const currentBeta = activeCamera.beta
    const newBeta = this.findClosestNewAngle(currentBeta) + beta

    // render scene camera animation
    Animation.CreateAndStartAnimation(
      'camAlpha',
      this.getActiveCamera(),
      'alpha',
      speed,
      frameCount,
      currentAlpha,
      newAlpha,
      0,
      null,
      null,
      this.scene, // 5.0.0
    )
    Animation.CreateAndStartAnimation(
      'camBeta',
      this.getActiveCamera(),
      'beta',
      speed,
      frameCount,
      currentBeta,
      newBeta,
      0,
      null,
      () => this.stopAnimate(),
      this.scene, // 5.0.0
    )
  }

  private attachScaleNode(bpItem: TransformNode, selectedMesh: AbstractMesh, bboxPositionCenter?: Vector3) {
    if (bpItem.metadata.parameterSetScaleNode) {
      const node = bpItem.metadata.parameterSetScaleNode as TransformNode
      bpItem.setParent(null)

      const scaling = node.scaling
      const translation = bpItem.position
      const translationMatrix = Matrix.Translation(translation.x, translation.y, translation.z)
      const invTranslationMatrix = translationMatrix.clone().invert()
      const scalingMatrix = Matrix.Scaling(scaling.x, scaling.y, scaling.z)
      const transform = invTranslationMatrix.multiply(scalingMatrix).multiply(translationMatrix)
      transform.decompose(node.scaling, node.rotationQuaternion, node.position)

      bpItem.parent = node
      if (bboxPositionCenter) {
        this.meshManager.translateBBoxCenterToPosition(bpItem, bboxPositionCenter)
      }

      node.setParent(selectedMesh)
    }
  }

  private detachScaleNode(bpItem: TransformNode, selectedMesh: AbstractMesh, bboxPositionCenter?: Vector3) {
    if (bpItem.parent.name === PARAMETER_SET_SCALE_NAME) {
      ; (bpItem.parent as TransformNode).setParent(null)
      bpItem.parent = null
      bpItem.setParent(selectedMesh)
      if (bboxPositionCenter) {
        this.meshManager.translateBBoxCenterToPosition(bpItem, bboxPositionCenter)
      }
    }
  }

  private suppressGpuPicker() {
    if (this.scene.metadata && !this.scene.metadata.updateGpuPicker) {
      this.scene.metadata.updateGpuPicker = false
    }
  }

  private getSimulateViewModeProperty(name: string) {
    const simulationMode = this.allViewModes.get(ViewModeTypes.SimulationCompensation) as SimulateViewMode
    return simulationMode ? simulationMode.getSimulationManager()[name] : null
  }

  private getDeviationViewModeProperty(name: string) {
    const simulationMode = this.allViewModes.get(ViewModeTypes.DeviationCompensate) as DeviationViewMode
    return simulationMode ? simulationMode.getResultsManager()[name] : null
  }

  private updateItemListForDuplicateTool(items: Array<{ buildPlanItemId: string; bodyIds?: string[] }>) {
    const isOnDupliateTool = this.viewMode instanceof DuplicateViewMode
    if (isOnDupliateTool) {
      const itemsToAdd = []
      Array.from(this.getSceneMetadata().buildPlanItems.values()).forEach((bpItem) => {
        const foundItem =
          bpItem.metadata && items.find((item) => item.buildPlanItemId === bpItem.metadata.originBpItemId)
        if (foundItem) {
          itemsToAdd.push({ buildPlanItemId: bpItem.metadata.buildPlanItemId, bodyIds: foundItem.bodyIds })
        }
      })

      items.push(...itemsToAdd)
    }
  }
}
