import { IBuildPlan, IPart } from '../BuildPlans/IBuildPlan'
import { Permission } from './Permission'
import { FileExplorerItem } from './FileExplorerItem'
import { IFolder } from '../Folders/Folders'
import { IIBCPlan } from '../IBCPlans/IIBCPlan'
import { ItemSubType, ItemType } from './ItemType'

interface BaseDetailsDto {
  id: string
  createdAt: string
  createdBy: string
}

export interface BuildPlanDetailsDto extends BaseDetailsDto {
  buildPlan: IBuildPlan
  buildPlateName: string
  collaborators: Permission[]
  versionsCount: number
}

export interface IbcPlanDetailsDto extends BaseDetailsDto {
  ibcPlan: IIBCPlan
  collaborators: Permission[]
  versionsCount: number
  materialId: number
  materialVersion: number
  machineConfigId: number
  machineConfigVersion: number
  printStrategyId: number
  printStrategyVersion: number
}

export interface FolderDetailsDto extends BaseDetailsDto {
  folder: IFolder
  collaborators: Permission[]
  numberOfItems: number
  numberOfHiddenItems: number
}

export interface PartDetailsDto extends BaseDetailsDto {
  name: string
  previewImageUrl: string
  originalName: string[]
  collaborators: Permission[]
  usedInAvailableBuildPlansDetails: SimpleBuildPlanDetailsDto[]
  usedInNotAvailableBuildPlansCount: number
  usedInNotAvailableSinterPlansCount: number
}

export class SimpleBuildPlanDetailsDto {
  id: string
  name: string
  hasSeveralVersions: boolean
  versionLabel: string
  parentPathLabel: string
  itemType: ItemType
  subType: ItemSubType
}

export type ItemDetailsType = PartDetailsDto | BuildPlanDetailsDto | IbcPlanDetailsDto | FolderDetailsDto

export type ItemDetailsDto = {
  details: ItemDetailsType
  item?: FileExplorerItem // TODO: update logic to use simple dto data for all details
  type?: any // TODO: update logic to use simple dto data for all details
}

export enum ItemDetailsTypes {
  Part = 'part',
  BuildPlan = 'build-plan',
  Folder = 'folder',
}
