export interface IInteractiveServiceCommand {
  id?: string
  name: InteractiveServiceCommandNames
  parameters?: unknown
}

export enum InteractiveServiceCommandNames {
  Setup = 'setup',
  Overhang = 'overhang',
  LinearSupports = 'linear_supports',
  TreeSupports = 'tree_supports',
  NoSupports = 'no_supports',
  Exit = 'exit',
  Save = 'save',
  Abort = 'abort',
  Execute = 'execute',
  Remove = 'remove',
  GetJsonPatches = 'get_json_patches',
  ClearLabelSetFiles = 'clear_label_set_files',
}
