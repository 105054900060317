import {
  Clearance,
  ClearanceModes,
  ClearanceTypes,
  DuplicateWrapperClearanceResult,
  IClearance,
} from '@/visualization/types/ClearanceTypes'
import { RenderScene } from '@/visualization/render-scene'
import { ClearanceManager } from '@/visualization/rendering/clearance/ClearanceManager'
import { AbstractMesh, BoundingBox, Ray, TransformNode, Vector3, VertexBuffer } from '@babylonjs/core'
import { DuplicateAxes, DuplicateMode } from '@/types/Duplicate/Duplicate'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import { OBBTree } from '@/visualization/OBBTree'
import { equalWithTolerance } from '@/utils/number'

export class ClearanceDuplicate implements IClearance {
  private renderScene: RenderScene
  private clearanceManager: ClearanceManager
  private meshManager: MeshManager
  private obbTree: OBBTree

  private clearances: Map<string, Clearance> = new Map<string, Clearance>()

  constructor(renderScene: RenderScene) {
    this.renderScene = renderScene
    this.clearanceManager = renderScene.getClearanceManager()
    this.meshManager = renderScene.getMeshManager()
    this.obbTree = renderScene.getObbTree()
  }

  public measureDistance(payload: {
    duplicateMode: DuplicateMode
    wrapper: TransformNode
    cloneWrapper: TransformNode
    shiftedPosition: Vector3
    axisName: DuplicateAxes
  }): void {
    const { duplicateMode } = payload
    switch (duplicateMode) {
      case DuplicateMode.BoundingBoxes:
        this.measureDistanceBetweenAABB(payload)
        break
      case DuplicateMode.Geometry:
        break
    }
  }

  public clearClearances(predicate?: (clearance: Clearance) => boolean): void {
    for (const [clearanceId, clearance] of this.clearances) {
      if (!predicate || predicate(clearance)) {
        clearance.dispose()
        this.clearances.delete(clearanceId)
      }
    }
  }

  public hideClearances(hide: boolean, predicate?: (clearance: Clearance) => boolean) {
    this.clearances.forEach((clearance) => {
      if (!predicate || predicate(clearance)) {
        clearance.isHidden = hide
      }
    })
  }

  public dispose(): void {
    this.clearClearances()
  }

  public getClearances(): Map<string, Clearance> {
    return this.clearances
  }

  /**
   * Measures distance between AABBs of duplicate wrappers
   * @param payload object that contains
   *  duplicate wrapper,
   *  duplicate clone wrapper,
   *  shifted position,
   *  duplicate axis name
   */
  private measureDistanceBetweenAABB(payload: {
    wrapper: TransformNode
    cloneWrapper: TransformNode
    shiftedPosition: Vector3
    axisName: DuplicateAxes
  }) {
    const { wrapper, cloneWrapper, shiftedPosition, axisName } = payload
    const parts = wrapper.getChildTransformNodes(false, this.meshManager.isPartMesh)
    const wrapperExtremumHull = this.getExtremumPointsFromParts(parts)

    const wrapperBoundingBox = new BoundingBox(
      new Vector3(wrapperExtremumHull.xMin.x, wrapperExtremumHull.yMin.y, wrapperExtremumHull.zMin.z),
      new Vector3(wrapperExtremumHull.xMax.x, wrapperExtremumHull.yMax.y, wrapperExtremumHull.zMax.z),
    )
    const wrapperMin = wrapperBoundingBox.minimumWorld
    const wrapperMax = wrapperBoundingBox.maximumWorld

    const clonedParts = cloneWrapper.getChildTransformNodes(false, this.meshManager.isPartMesh)
    const cloneWrapperBoundingBox = new BoundingBox(
      new Vector3(
        wrapperExtremumHull.xMin.x + shiftedPosition.x,
        wrapperExtremumHull.yMin.y + shiftedPosition.y,
        wrapperExtremumHull.zMin.z + shiftedPosition.z,
      ),
      new Vector3(
        wrapperExtremumHull.xMax.x + shiftedPosition.x,
        wrapperExtremumHull.yMax.y + shiftedPosition.y,
        wrapperExtremumHull.zMax.z + shiftedPosition.z,
      ),
    )
    const cloneWrapperMin = cloneWrapperBoundingBox.minimumWorld
    const cloneWrapperMax = cloneWrapperBoundingBox.maximumWorld

    let distance: number = null
    let mainLinePointA: Vector3 = null
    let mainLinePointB: Vector3 = null
    let secondaryLinePointA: Vector3 = null
    let secondaryLinePointB: Vector3 = null
    if (wrapperMax[axisName] < cloneWrapperMin[axisName]) {
      distance = cloneWrapperMin[axisName] - wrapperMax[axisName]
      switch (axisName) {
        case DuplicateAxes.X:
          mainLinePointA = wrapperExtremumHull.xMax.clone()
          mainLinePointB = wrapperExtremumHull.xMax.clone()
          secondaryLinePointA = wrapperExtremumHull.xMax.clone()
          secondaryLinePointB = wrapperExtremumHull.xMin.add(shiftedPosition)
          break
        case DuplicateAxes.Y:
          mainLinePointA = wrapperExtremumHull.yMax.clone()
          mainLinePointB = wrapperExtremumHull.yMax.clone()
          secondaryLinePointA = wrapperExtremumHull.yMax.clone()
          secondaryLinePointB = wrapperExtremumHull.yMin.add(shiftedPosition)
          break
        case DuplicateAxes.Z:
          mainLinePointA = wrapperExtremumHull.zMax.clone()
          mainLinePointB = wrapperExtremumHull.zMax.clone()
          secondaryLinePointA = wrapperExtremumHull.zMax.clone()
          secondaryLinePointB = wrapperExtremumHull.zMin.add(shiftedPosition)
          break
      }
      mainLinePointB[axisName] = cloneWrapperMin[axisName]
      secondaryLinePointA[axisName] = cloneWrapperMin[axisName]
    } else if (wrapperMin[axisName] > cloneWrapperMax[axisName]) {
      distance = wrapperMin[axisName] - cloneWrapperMax[axisName]
      switch (axisName) {
        case DuplicateAxes.X:
          mainLinePointA = wrapperExtremumHull.xMin.clone()
          mainLinePointB = wrapperExtremumHull.xMin.clone()
          secondaryLinePointA = wrapperExtremumHull.xMin.clone()
          secondaryLinePointB = wrapperExtremumHull.xMax.add(shiftedPosition)
          break
        case DuplicateAxes.Y:
          mainLinePointA = wrapperExtremumHull.yMin.clone()
          mainLinePointB = wrapperExtremumHull.yMin.clone()
          secondaryLinePointA = wrapperExtremumHull.yMin.clone()
          secondaryLinePointB = wrapperExtremumHull.yMax.add(shiftedPosition)
          break
        case DuplicateAxes.Z:
          mainLinePointA = wrapperExtremumHull.zMin.clone()
          mainLinePointB = wrapperExtremumHull.zMin.clone()
          secondaryLinePointA = wrapperExtremumHull.zMin.clone()
          secondaryLinePointB = wrapperExtremumHull.zMax.add(shiftedPosition)
          break
      }
      mainLinePointB[axisName] = cloneWrapperMax[axisName]
      secondaryLinePointA[axisName] = cloneWrapperMax[axisName]
    }

    if (!mainLinePointA || !mainLinePointB || !secondaryLinePointA || !secondaryLinePointB) {
      return // In this case something is wrong with input
    }

    const attachedComponent = this.adjustSecondaryLineEnd(
      clonedParts,
      axisName,
      secondaryLinePointA,
      secondaryLinePointB,
    )
    const clearanceResult = {
      distance,
      pointA: mainLinePointA,
      pointB: mainLinePointB,
      originalCombinedId: wrapper.metadata.combinedId,
      tempCombinedId: cloneWrapper.metadata.combinedId,
      mode: ClearanceModes.Duplicate,
    } as DuplicateWrapperClearanceResult

    const clearance = new Clearance(
      this.clearanceManager,
      clearanceResult,
      ClearanceTypes.DuplicateWrapper,
      ClearanceTypes.DuplicateWrapper,
    )

    if (attachedComponent && this.isSecondaryLinePresent(attachedComponent, mainLinePointA, mainLinePointB)) {
      clearance.addSecondaryLine(secondaryLinePointA, secondaryLinePointB)
    }

    this.clearances.set(clearance.id, clearance)
    this.clearanceManager.getRenderScene.animate()
    this.clearanceManager.updateDimensionBoxPosition()
  }

  /**
   * Calculates extremum points for group of parts
   * @param parts parts that is used to calculate extremum points
   * @returns object that contains the six furthest points in each direction - +X, -X, +Y, -Y, +Z, -Z
   */
  private getExtremumPointsFromParts(parts: TransformNode[]) {
    const extremePoints = {
      xMin: new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE),
      xMax: new Vector3(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE),
      yMin: new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE),
      yMax: new Vector3(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE),
      zMin: new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE),
      zMax: new Vector3(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE),
    }

    const transformedPoint = new Vector3()
    for (const part of parts) {
      const components = part.getChildMeshes(false, this.meshManager.isComponentMesh)
      for (const component of components) {
        component.computeWorldMatrix(true)
        const worldMatrix = component.getWorldMatrix()
        const vertices = component.getVerticesData(VertexBuffer.PositionKind)

        if (!vertices) {
          continue
        }
        const end = vertices.length - 2
        for (let i = 0; i < end; i += 3) {
          transformedPoint.copyFromFloats(vertices[i + 0], vertices[i + 1], vertices[i + 2])
          Vector3.TransformCoordinatesToRef(transformedPoint, worldMatrix, transformedPoint)
          if (transformedPoint.x < extremePoints.xMin.x) {
            extremePoints.xMin.copyFrom(transformedPoint)
          }

          if (transformedPoint.x > extremePoints.xMax.x) {
            extremePoints.xMax.copyFrom(transformedPoint)
          }

          if (transformedPoint.y < extremePoints.yMin.y) {
            extremePoints.yMin.copyFrom(transformedPoint)
          }

          if (transformedPoint.y > extremePoints.yMax.y) {
            extremePoints.yMax.copyFrom(transformedPoint)
          }

          if (transformedPoint.z < extremePoints.zMin.z) {
            extremePoints.zMin.copyFrom(transformedPoint)
          }

          if (transformedPoint.z > extremePoints.zMax.z) {
            extremePoints.zMax.copyFrom(transformedPoint)
          }
        }
      }
    }

    return extremePoints
  }

  /**
   * Adjusts end of secondary line to make it shorter
   * @param clonedParts cloned instances of parts
   * @param axisName name of axis that is used for duplication
   * @param secondaryLineStart start of secondary line
   * @param secondaryLineEnd end of secondary line
   * @returns component to which the secondary line will be attached
   */
  private adjustSecondaryLineEnd(
    clonedParts: TransformNode[],
    axisName: DuplicateAxes,
    secondaryLineStart: Vector3,
    secondaryLineEnd: Vector3,
  ) {
    const transformedPoint = new Vector3()
    let attachedComponent: AbstractMesh = null

    for (const part of clonedParts) {
      const components = part.getChildMeshes(false, this.meshManager.isComponentMesh)
      for (const component of components) {
        component.computeWorldMatrix(true)
        const worldMatrix = component.getWorldMatrix()
        const vertices = component.getVerticesData(VertexBuffer.PositionKind)

        if (!vertices) {
          continue
        }
        const end = vertices.length - 2
        for (let i = 0; i < end; i += 3) {
          transformedPoint.copyFromFloats(vertices[i + 0], vertices[i + 1], vertices[i + 2])
          Vector3.TransformCoordinatesToRef(transformedPoint, worldMatrix, transformedPoint)

          const secondaryLineDistance = Vector3.Distance(secondaryLineStart, secondaryLineEnd)
          const newSecondaryLineDistance = Vector3.Distance(secondaryLineStart, transformedPoint)
          if (
            equalWithTolerance(secondaryLineEnd[axisName], transformedPoint[axisName], 1e-3) &&
            (newSecondaryLineDistance < secondaryLineDistance ||
              equalWithTolerance(secondaryLineDistance, newSecondaryLineDistance, 1e-3))
          ) {
            attachedComponent = component
            secondaryLineEnd.copyFrom(transformedPoint)
          }
        }
      }
    }

    return attachedComponent
  }

  /**
   * Checks if there is a need for additional line from main dimension to attached component
   * @param attachedComponent component to which the secondary line will be attached
   * @param mainLineStart start of main line
   * @param mainLineEnd end of main line
   * @returns true if main line is not attached to provided component, otherwise false
   */
  private isSecondaryLinePresent(attachedComponent: AbstractMesh, mainLineStart: Vector3, mainLineEnd: Vector3) {
    const ray = new Ray(mainLineStart, mainLineEnd.subtract(mainLineStart))
    const { pickedPoint } = ray.intersectsMesh(attachedComponent)
    if (pickedPoint && pickedPoint.equalsWithEpsilon(mainLineEnd, 1e-3)) {
      return false
    }

    return true
  }
}
