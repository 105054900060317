
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { Watch } from 'vue-property-decorator'
const userStore = namespace(StoresNamespaces.User)

@Component({
  components: {},
})
export default class LoginInitiation extends Vue {
  @userStore.Getter('isAuthenticating') isAuthenticating: any
  @userStore.Getter('getAuth0Client') auth0Client: any

  @Watch('isAuthenticating')
  initiateLogin() {
    this.auth0Client.loginWithRedirect()
  }
}
