import { GetterTree } from 'vuex'
import { ISitesState } from '@/store/modules/sites/types'
import { IRootState } from '@/store/types'
import {
  AdditionalPrintStrategyInfo,
  PrintStrategyInfo,
  MachineMaterial,
  MachineTypeDto,
  PrintStrategy,
  SiteDto,
  PrintStrategyParameterSetDto,
  ParameterSetInherentStrainAvailability,
} from '@/types/Sites/Site'
import { orderBy } from '@/utils/array'
import { SortOrders } from '@/types/SortModes'
import { InertGas } from '@/types/BuildPlans/IBuildPlan'
import { VersionableModel } from '@/types/Common/VersionableModel'
import { VersionablePk } from '@/types/Common/VersionablePk'

export const getters: GetterTree<ISitesState, IRootState> = {
  getAllSites(state): SiteDto[] {
    return state.sites
  },

  getSelectedSite(state): SiteDto {
    return state.selectedSite
  },

  getMachineTypesOfActiveState(state): MachineTypeDto[] {
    return state.machineTypes ? orderBy(state.machineTypes, ['name'], [SortOrders.Ascending]) : []
  },

  getSelectedMachineType(state): MachineTypeDto {
    return state.selectedMachineType
  },

  getMachineMaterials(state): MachineMaterial[] {
    return state.machineMaterials ? orderBy(state.machineMaterials, ['name'], [SortOrders.Ascending]) : []
  },

  getSelectedMaterial(state): MachineMaterial {
    return state.selectedMaterial
  },

  getPrintStrategies(state): PrintStrategy[] {
    return state.printStrategies
  },

  previousPath(state): string {
    return state.previousPath
  },

  getSelectedPrintStrategy(state): PrintStrategyInfo {
    return state.printStrategy
  },

  getSelectedPrintStrategyPk(state): VersionablePk {
    return VersionableModel.getPk(state.printStrategy)
  },

  getSelectedPrintStrategyParameterSets(state): PrintStrategyParameterSetDto[] {
    return state.printStrategy.partParameters
  },

  getPrintStrategyAdditionalInfo(state): AdditionalPrintStrategyInfo {
    return state.printStrategyAdditionalInfo
  },

  getPartParameterInherentStrainAvailabilities(state): ParameterSetInherentStrainAvailability[] {
    return state.printStrategyAdditionalInfo.partParametersInherentStrains
  },

  isBreadcrumbsReady(state): boolean {
    return state.isBreadcrumbsReady
  },

  getInertGases(state): InertGas[] {
    return state.inertGases
  },

  getCopiedParameterSetId(state) {
    return state.copiedPartParameterId
  },

  getCopiedProductionSetId(state) {
    return state.copiedProductionSetId
  },

  isLoading(state) {
    return state.isLoading
  },

  getSiteMachineConfigMaterialBinders(state) {
    return state.siteMachineConfigMaterialBinders
  },

  getStrategyProcessParameterSolidLevel(state) {
    return state.printStrategyProcessParameterSolidLevel
  },
}
