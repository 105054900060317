<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :viewBox="viewbox"
    preserveAspectRatio="xMidYMid meet"
    :aria-labelledby="iconName"
    role="presentation"
    class="icon"
    :style="{ boxSizing: 'content-box' }"
  >
    <g fill="currentColor">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'edit',
    },
    size: {
      type: Number,
      default: 16,
    },
    viewbox: {
      type: String,
      default: '0 0 16 16',
    },
  },
}
</script>

<style type="text/css">
.st0 {
  fill: #595959;
}
.st1 {
  fill: none;
}
.st2 {
  fill: #ffffff;
}
.st3 {
  fill: #005eb8;
}
.st4 {
  fill: #e1e1e1;
}
.st5 {
  fill: #f3f3f3;
}
.st6 {
  fill: #bfbfbf;
}

/* Problematic 2 color theme */
.fill-1 {
  color: #44474a; /* Same HUE range as the GE Grey */
}
.fill-2 {
  fill: #00b5e2; /* Basically the GE Teal */
}

/* This seems really hacky. Combines the v-btn--disabled and our own overide */
.v-btn--disabled .fill-1,
.v-btn--disabled .fill-2 {
  fill: rgba(0, 0, 0, 0.15);
}
</style>
