/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { AbstractMesh, VertexData, Mesh, TransformNode, InstancedMesh } from '@babylonjs/core/Meshes'
import { VertexBuffer } from '@babylonjs/core/Buffers'
import { IRenderable } from '../types/IRenderable'
import { IActiveToggle } from '../infrastructure/IActiveToggle'
import { Matrix, Vector3, Plane, Color3, Axis, Space, Angle, Epsilon, Viewport, Vector2 } from '@babylonjs/core/Maths'
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder'
import { PickingInfo } from '@babylonjs/core/Collisions/pickingInfo'
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { Scene } from '@babylonjs/core/scene'
import {
  LABEL,
  LABEL_MATERIAL_NAME,
  MAX_LABEL_ANGLE_BETWEEN_NORMALS,
  TEXTURE_TEXT_SHARPNESS,
  REGULAR_ORANGE,
  IDENTITY_MATRIX,
  MESH_RENDERING_GROUP_ID,
  GROUP_PARENT_MESH_NAME,
  SEED_SURFACE,
  PRIMARY_CYAN,
  TEMP,
  LABEL_MESH,
  MAX_LABEL_NORMALS_TOLERANCE,
  FAILED_LABEL_COLOR,
  LABEL_CACHED,
  LABEL_ORIGIN,
  REGULAR_YELLOW_MATERIAL,
  LABEL_SENSITIVE_ZONE,
  LABEL_SENSITIVE_ZONE_SCALE,
  COLOR_FOR_BODY,
  COLOR_FOR_PART,
  COLOR_FOR_FACE,
  LABEL_SENSITIVE_ZONE_CACHED,
  ROTATION_GIZMO_ARROW_NAME,
  INACTIVE_LABEL_COLOR,
  LABEL_COLOR,
  INACTIVE_LABEL_FAILED_COLOR,
  ACTIVE_LABEL_FAILED_COLOR,
  LABEL_INSIDE_MESH_NAME,
  LABEL_INSIDE_MATERIAL,
  MouseButtons,
  PLANAR_GIZMO_NAME,
  THRESHOLD_RATIO,
  LABEL_ID,
  LABEL_ORIGIN_REGULAR_COLOR,
  LABEL_ORIGIN_ERROR_COLOR,
  GET_LABEL_ADDED_PROMISE,
  CLEAR_LABEL_PROMISE,
} from '@/constants'
import { VisualizationEvent } from '@/visualization/infrastructure/IVisualizationEvent'
import { ILabel, ILabelOrientation, ILabelStyle, IVertex } from '@/types/Marking/ILabel'
import { v4 as uuid } from 'uuid'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import { RenderScene } from '@/visualization/render-scene'
import { DynamicTexture, Material } from '@babylonjs/core/Materials'
import { OrthoCamera } from '@/visualization/components/OrthoCamera'
import { LabelGizmo } from '@/visualization/rendering/LabelGizmo'
import { DracoEncoder } from '@/visualization/components/DracoEncoder'
import { DracoDecoder, Face } from '@/visualization/components/DracoDecoder'
import { convertMillimeterToPixel, convertPixelToMillimeter } from '@/utils/number'
import { InsightsManager } from '@/visualization/rendering/InsightsManager'
import {
  IComponentMetadata,
  ILabelMeshMetadata,
  ILabelMetadata,
  IPartMetadata,
  SceneItemType,
} from '@/visualization/types/SceneItemMetadata'
import _ from 'lodash'
import { GeometryTypes } from '@/visualization/models/DataModel'
import { PointerEventTypes, PointerInfo } from '@babylonjs/core/Events'
import { ManualPatch, Patch } from '@/types/Label/Patch'
import { ModelManager } from './ModelManager'
import { Observer } from '@babylonjs/core/Misc/observable'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { MarkingViewMode } from '../infrastructure/ViewMode'
import store from '@/store'
import { isNil } from '@/utils/common'
import { LabelInsightRelatedItem } from '@/types/InteractiveService/LabelMessageContent'
import { LabelDirtyState } from '@/types/Label/enums'
import { AutomatedTrackableLabel, ManualTrackableLabel, TrackableLabel } from '@/types/Label/TrackableLabel'
import { Placement } from '@/types/Label/Placement'
import { isManualLabel } from '@/utils/label/labelUtils'
import { IDisplayToolbarState } from '@/types/BuildPlans/IBuildPlan'
import { PrintOrderPreviewPatch } from '@/types/PrintOrder/PrintOrderPatch'
import { eventBus } from '@/services/EventBus'
import { BuildPlanEvents } from '@/types/Label/BuildPlanEvents'
import { ANNOUNCEMENT_HEIGHT } from '@/components/layout/buildPlans/marking/mixins/LabelTooltipMixin'

interface ILabelRenderable {
  contentWidth: number
  contentHeight: number
  textContent: string
  fontFamily: string
  fontSize: number
  regularMaterial: StandardMaterial
  previewMaterial: StandardMaterial
  semitransparentMaterial: StandardMaterial
}

export interface IOrientation {
  normal: Vector3
  origin: Vector3
  xDirection: Vector3
  yDirection: Vector3
}

export class LabelManager {
  private readonly onLabelAdded = new VisualizationEvent<{
    buildPlanItemId: string
    label: ILabel
    file: File
  }>()
  private readonly onLabelUpdated = new VisualizationEvent<{
    buildPlanItemId: string
    label: ILabel
    file: File
  }>()
  private readonly onLabelPlaced = new VisualizationEvent<void>()
  private onLabelOrientationSelected = new VisualizationEvent<ManualPatch>()
  private onLabelOrientationChanged = new VisualizationEvent<{
    manualPatches: ManualPatch[]
    singleLabelUpdate?: boolean
  }>()

  private renderScene: IRenderable
  private modelManager: ModelManager
  private scene: Scene
  private camera: OrthoCamera
  private meshManager: MeshManager
  private insightsManager: InsightsManager
  private labelGizmo: LabelGizmo
  private groupParent: AbstractMesh

  // store material for each mark style
  private dracoEncoder: DracoEncoder
  private labelConfigs: ILabelRenderable[]
  private labelsCache: Array<{ label: InstancedMesh; sensitiveZone: InstancedMesh }> = []
  private originTemplateMesh: Mesh

  private pickPredicate: (mesh: AbstractMesh) => boolean
  private labelInteractionsVisibility: (eventData: PointerInfo) => void
  private manualLabelUpdateObserver: Observer<PointerInfo>
  private manualLabelCreateObserver: Observer<PointerInfo>
  private storePointerPosition: (evt: PointerEvent) => void
  private hideLabelHandleThrottle: NodeJS.Timeout

  private labelStyle: ILabelStyle
  private canAddLabel = false
  private readonly spacing = 2
  private pointerX: number
  private pointerY: number
  private readonly hideLabelHandleDebounce: number = 600

  private notifyCacheRestored: Function
  private waitCacheRestored: () => Promise<unknown>

  constructor(renderScene: IRenderable, modelManager: ModelManager, drawListeners?: IActiveToggle[]) {
    this.renderScene = renderScene
    this.modelManager = modelManager
    this.scene = renderScene.getScene()
    this.meshManager = renderScene.getMeshManager()
    this.insightsManager = (renderScene as RenderScene).getInsightsManager()
    this.labelConfigs = []
    this.labelGizmo = new LabelGizmo(renderScene as RenderScene, this, drawListeners)
    this.dracoEncoder = new DracoEncoder()
    this.pickPredicate = (mesh: AbstractMesh) => this.meshManager.isComponentMesh(mesh)
    this.storePointerPosition = (evt: PointerEvent) => {
      this.pointerX = evt.offsetX
      this.pointerY = evt.offsetY
    }
  }

  get isEnabled() {
    return this.canAddLabel
  }

  get labelAdded() {
    return this.onLabelAdded.expose()
  }

  get labelUpdated() {
    return this.onLabelUpdated.expose()
  }

  get labelPlaced() {
    return this.onLabelPlaced.expose()
  }

  get labelOrientationSelected() {
    return this.onLabelOrientationSelected.expose()
  }

  get labelOrientationChanged() {
    return this.onLabelOrientationChanged.expose()
  }

  initializeLabelMode() {
    const bBoxRenderer = this.scene.getBoundingBoxRenderer()
    bBoxRenderer.onBeforeBoxRenderingObservable.add((bBox) => {
      this.scene.meshes.map((m) => {
        const labelMetadata = m.metadata as ILabelMetadata
        if (
          !labelMetadata ||
          (labelMetadata.itemType !== SceneItemType.Label && labelMetadata.itemType !== SceneItemType.RawLabel) ||
          m.getBoundingInfo().boundingBox !== bBox
        ) {
          return
        }

        bBoxRenderer.frontColor = labelMetadata.isValid ? Color3.White() : Color3.Red()
        bBoxRenderer.backColor = labelMetadata.isValid ? Color3.White() : Color3.Red()
      })
    })
  }

  terminateLabelMode() {
    const bBoxRenderer = this.scene.getBoundingBoxRenderer()
    const bBoxObservable = bBoxRenderer.onBeforeBoxRenderingObservable
    bBoxObservable.remove(bBoxObservable.observers[bBoxObservable.observers.length - 1])
  }

  changeLabelColors(isLabelMode: boolean) {
    this.scene.meshes
      .filter((m) => this.meshManager.isLabelMesh(m) || this.meshManager.isLabelCloneMesh(m))
      .map((label) => {
        const color = label.metadata.isFailed
          ? isLabelMode
            ? ACTIVE_LABEL_FAILED_COLOR
            : INACTIVE_LABEL_FAILED_COLOR
          : isLabelMode
            ? LABEL_COLOR
            : INACTIVE_LABEL_COLOR
        const insideMesh = label.getChildMeshes().find((c) => c.name.includes(LABEL_INSIDE_MESH_NAME))
        if (insideMesh) {
          label.instancedBuffers.color = color
          insideMesh.instancedBuffers.color = color
        }
      })
  }

  async loadItemLabels(
    itemId: string,
    labels: ILabel[],
    transformation: number[],
    dracoData: Map<string, ArrayBuffer>,
  ) {
    this.camera = this.renderScene.getActiveCamera()
    const bpItem = this.meshManager.getBuildPlanItemMeshById(itemId)
    for (const label of labels) {
      const buffer = dracoData.get(label.s3FileName)
      if (!buffer) {
        continue
      }

      const labelMesh = await this.createLabelMeshFromDraco(buffer)
      this.setupLabelConfig(label.style.fontSize, label.style.fontFamily, label.style.text)
      this.meshManager.transformMesh(labelMesh, transformation)
      const material = this.getLabelConfig(
        label.style.fontSize,
        label.style.fontFamily,
        label.style.text,
      ).regularMaterial
      const worldTransformation = label.worldTransformation
        ? Matrix.FromArray(label.worldTransformation)
        : IDENTITY_MATRIX
      const orientation = {
        normal: new Vector3(label.orientation.normal.x, label.orientation.normal.y, label.orientation.normal.z),
        origin: new Vector3(label.orientation.origin.x, label.orientation.origin.y, label.orientation.origin.z),
        xDirection: new Vector3(
          label.orientation.xDirection.x,
          label.orientation.xDirection.y,
          label.orientation.xDirection.z,
        ),
        yDirection: new Vector3(
          label.orientation.yDirection.x,
          label.orientation.yDirection.y,
          label.orientation.yDirection.z,
        ),
      }

      this.setupLabelMesh(
        labelMesh,
        label.id,
        label.componentId,
        label.geometryId,
        label.style,
        bpItem,
        material,
        worldTransformation,
        orientation,
        label.isValid,
      )
      this.translateToWorldCS(labelMesh, orientation, bpItem)
      // need for fix z-fighting when camera is directed along -z axis
      labelMesh.position = labelMesh.position.add(orientation.normal.scale(Epsilon))

      this.createLabelInstance(labelMesh)
    }
  }

  async loadPrintOrderLabels(printOrderId: string, dracoData: ArrayBuffer) {
    if (!dracoData) {
      return
    }

    await this.createLabelsFromDraco(dracoData, printOrderId)
  }

  async createLabelsFromDraco(dracoData: ArrayBuffer, meshId?: string) {
    const color =
      (this.renderScene as RenderScene).getViewMode() instanceof MarkingViewMode ? LABEL_COLOR : INACTIVE_LABEL_COLOR

    const patchList: PrintOrderPreviewPatch[] = store.getters['label/getPatches']
    const labelInstances: InstancedMesh[] = []
    const labelMeshes = await this.modelManager.createLabelMeshes(uuid(), dracoData)
    for (const labelMesh of labelMeshes) {
      const patch = patchList.find((item) => item.id === labelMesh.id)
      if (patch) {
        const bpItem = this.meshManager.getBuildPlanItemMeshById(patch.buildPlanItemId)

        // Use setParent method to avoid accepting parent transformation
        // labelMeshes geometry already in WCS
        labelMesh.setParent(bpItem)
      }

      labelMesh.id = meshId || labelMesh.id
      labelMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
      labelMesh.material = this.scene.getMaterialByName(LABEL_MATERIAL_NAME)
      labelMesh.isVisible = false
      labelMesh.isPickable = false
      labelMesh.registerInstancedBuffer(VertexBuffer.ColorKind, 3)
      labelMesh.instancedBuffers.color = color

      const labelMeshInstance = labelMesh.createInstance(LABEL_MESH)
      labelMeshInstance.id = labelMesh.id
      labelMeshInstance.isPickable = false
      labelMeshInstance.parent = labelMesh.parent
      labelMeshInstance.instancedBuffers.color = color

      labelMesh.id = `${labelMesh.id}_source`
      labelMesh.name = `${labelMesh.name}_source`
      this.scene.removeMesh(labelMesh)

      // create inside Mesh
      this.createLabelMeshInside(labelMeshInstance, color)

      if (patch) {
        labelMeshInstance.metadata = {
          buildPlanItemId: patch.buildPlanItemId,
          componentId: patch.componentId,
          geometryId: patch.geometryId,
          patchId: patch.id,
          itemType: SceneItemType.Label,
        } as ILabelMeshMetadata

        // All meshes should have transparent clone.
        this.meshManager.createTransparentClone(labelMeshInstance, false)
        labelInstances.push(labelMeshInstance)
      }
    }

    return labelInstances
  }

  async addLabelOnScene(
    id: string,
    buildPlanItemId: string,
    componentId: string,
    geometryId: string,
    labelSetId: string,
    drc: ArrayBuffer,
    isFailed?: boolean,
    orientation?: ILabelOrientation,
    rotationAngle?: number,
    trackId?: string,
  ) {
    if (!drc) {
      return
    }

    this.camera = this.renderScene.getActiveCamera()
    this.disposeLabelOrigins({ labelId: id })
    const labelMesh = await this.createLabelFromDraco(drc, { labelSetId, buildPlanItemId, isFailed, meshId: id })
    labelMesh.metadata = {
      buildPlanItemId,
      componentId,
      geometryId,
      labelSetId,
      isFailed,
      rotationAngle,
      trackId,
      itemType: SceneItemType.Label,
    } as ILabelMeshMetadata

    if (orientation) {
      const sensitiveZoneMesh = this.buildSensitiveZone(labelMesh, orientation)
      labelMesh.metadata.sensitiveZoneId = sensitiveZoneMesh.id
      this.setupForGpuPicker(sensitiveZoneMesh, SceneItemType.LabelSensitiveZone)
      this.renderScene.getGpuPicker().addPickingObjects([sensitiveZoneMesh])

      // store orientation in world CS
      const labelOrientation = this.convertToOrientationVector3(orientation)
      const transformation = this.getLabelParentTransformation(labelMesh)
      labelMesh.metadata.orientation = {
        normal: Vector3.TransformNormal(labelOrientation.normal, transformation),
        origin: Vector3.TransformCoordinates(labelOrientation.origin, transformation),
        xDirection: Vector3.TransformNormal(labelOrientation.xDirection, transformation),
        yDirection: Vector3.TransformNormal(labelOrientation.yDirection, transformation),
      }

      const renderScene = this.renderScene as RenderScene
      setTimeout(() => {
        this.scene.metadata.updateGpuPicker = true
        renderScene.animate()
        const picked = renderScene.getGpuPicker().pick(this.pointerX, this.pointerY)
        if (picked && picked.body && this.meshManager.isLabelSensitiveZone(picked.body)) {
          renderScene.hoverPickedObject(picked)
        }
      }, 0)
    }

    // All meshes should have transparent clone.
    if (!labelMesh.metadata.transparentCloneId) {
      await this.meshManager.createTransparentClone(labelMesh, false)
    }

    const componentMesh = this.getLabelComponentMesh(labelMesh)
    labelMesh.isVisible = !componentMesh.metadata.isHidden
    if (componentMesh.metadata.isHidden) {
      labelMesh.metadata.isHidden = true
      // Show transparent clone if ShowHiddenPartsAsTransparentMode enabled.
      if (this.meshManager.isShowHiddenPartsAsTransparentMode) {
        this.meshManager.showTransparentClone(labelMesh)
      }
    }

    // Assign visibility for the case when create label, toggle 'Display Labeled Bodies' and label all instances
    ; (this.renderScene as RenderScene).setMeshVisibilityRec(
      componentMesh,
      this.canShowComponent(componentMesh),
      false,
      true,
    )

    const labelPromise: { done: Function } = store.getters[GET_LABEL_ADDED_PROMISE]
    if (labelPromise) {
      labelPromise.done()
    }

    store.commit(CLEAR_LABEL_PROMISE)
    return labelMesh
  }

  async createLabelFromDraco(
    dracoData: ArrayBuffer,
    options?: { labelSetId?: string; buildPlanItemId?: string; meshId?: string; isFailed?: boolean },
  ) {
    let color
    const activeLabelSet = store.getters['label/activeLabelSet']
    const highlightedLabelSetId = store.getters['label/getHighlightedLabelSetId']
    if (highlightedLabelSetId === options.labelSetId) {
      color = PRIMARY_CYAN
    } else {
      if (activeLabelSet && activeLabelSet.id === options.labelSetId) {
        color = options.isFailed ? INACTIVE_LABEL_FAILED_COLOR : INACTIVE_LABEL_COLOR
      } else {
        color =
          (this.renderScene as RenderScene).getViewMode() instanceof MarkingViewMode
            ? options.isFailed
              ? ACTIVE_LABEL_FAILED_COLOR
              : LABEL_COLOR
            : options.isFailed
              ? INACTIVE_LABEL_FAILED_COLOR
              : INACTIVE_LABEL_COLOR
      }
    }

    const labelMesh = await this.modelManager.createLabelMesh(uuid(), dracoData)
    if (options.buildPlanItemId) {
      const bpItem = this.meshManager.getBuildPlanItemMeshById(options.buildPlanItemId)
      labelMesh.parent = bpItem
    }

    labelMesh.id = options.meshId || labelMesh.id
    labelMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
    labelMesh.material = this.scene.getMaterialByName(LABEL_MATERIAL_NAME)
    labelMesh.isVisible = false
    labelMesh.isPickable = false
    labelMesh.registerInstancedBuffer(VertexBuffer.ColorKind, 3)
    labelMesh.instancedBuffers.color = color

    const labelMeshInstance = labelMesh.createInstance(LABEL_MESH)
    labelMeshInstance.id = labelMesh.id
    labelMeshInstance.isPickable = false
    labelMeshInstance.parent = labelMesh.parent
    labelMeshInstance.instancedBuffers.color = color

    labelMesh.id = `${labelMesh.id}_source`
    labelMesh.name = `${labelMesh.name}_source`
    this.scene.removeMesh(labelMesh)

    // create inside Mesh
    this.createLabelMeshInside(labelMeshInstance, color)

    return labelMeshInstance
  }

  // NOTE: on the scene we use label geometry in world coordinate system,
  // but when we save geometry we use part local coordinate system
  setLabelStyle(style: ILabelStyle) {
    this.labelStyle = style
    this.setupLabelConfig(style.fontSize, style.fontFamily, style.text)
    const tempLabelMesh = this.meshManager.getRawLabel()
    if (tempLabelMesh) {
      this.removeLabelMesh(tempLabelMesh)
    }

    if (!style.text || !style.fontFamily || !style.fontSize || !style.textExtrusionHeight) {
      this.canAddLabel = false
    } else {
      this.canAddLabel = true
      this.createRawLabel(this.scene.pointerX, this.scene.pointerY)
    }
  }

  deleteLabel(labelId: string) {
    const labelMesh = this.scene.meshes.find(
      (mesh: AbstractMesh) => mesh.id === labelId && this.meshManager.isLabelMesh(mesh),
    )
    if (!labelMesh) {
      return
    }

    this.removeLabelMesh(labelMesh)
  }

  toggleLabelHighlight(labelId: string, highlight: boolean) {
    const labelMesh = this.scene.getMeshByID(labelId) as InstancedMesh
    if (!labelMesh || !this.meshManager.isLabelMesh(labelMesh)) {
      return
    }

    if (!labelMesh.instancedBuffers) {
      return
    }

    const labelMetadata = labelMesh.metadata as ILabelMeshMetadata

    labelMesh.instancedBuffers.color = highlight ? PRIMARY_CYAN : labelMetadata ? FAILED_LABEL_COLOR : REGULAR_ORANGE

    const component = this.scene.getMeshByID(labelMetadata.componentId) as InstancedMesh
    if (component && component.metadata && component.metadata.isHidden && component.metadata.transparentCloneId) {
      const transparentClone = this.meshManager.getTransparentClone(component)
      transparentClone.instancedBuffers.color = labelMesh.instancedBuffers.color
    }

    ; (this.renderScene as RenderScene).animate(true)
  }

  activateLabelCreation() {
    this.camera = this.renderScene.getActiveCamera()
    this.canAddLabel = true
    this.registerLabelCreationEvents()
  }

  deactivateLabelCreation() {
    this.canAddLabel = false
    this.unregisterLabelCreationEvents()
    this.toggleValidBodiesHighlight(false)
  }

  activateLabelInteraction(labelId: string) {
    if (!labelId || this.groupParent) {
      return
    }

    const labelMesh = this.scene.getMeshByID(labelId)
    if (!labelMesh) {
      return
    }

    this.toggleLabelHighlight(labelId, false)
    this.toggleSelectedBodyHighlight(true, labelMesh)
    this.createGroupParent((labelMesh.metadata as ILabelMetadata).orientation)
    const worldMatrix = labelMesh.computeWorldMatrix().clone()
    labelMesh.parent = this.groupParent
    labelMesh.freezeWorldMatrix(worldMatrix)
    labelMesh.showBoundingBox = true

    const pickPredicate = (mesh: AbstractMesh) => mesh.isPickable || this.meshManager.isLabelMesh(mesh)
    let screenOrigin = this.meshManager.project3DPointOntoScreen(labelMesh.metadata.orientation.origin)
    let pickInfo = this.scene.pick(screenOrigin.x, screenOrigin.y, pickPredicate)
    let isInteractionsActive = false
    if (pickInfo.pickedMesh.id === labelMesh.id) {
      // show interactions for label
      this.labelGizmo.show(this.groupParent)
      isInteractionsActive = true
    }

    this.labelInteractionsVisibility = (eventData: PointerInfo) => {
      if (eventData.type !== PointerEventTypes.POINTERDOWN && eventData.type !== PointerEventTypes.POINTERUP) {
        return
      }

      screenOrigin = this.meshManager.project3DPointOntoScreen(labelMesh.metadata.orientation.origin)
      pickInfo = this.scene.pick(screenOrigin.x, screenOrigin.y, pickPredicate)
      if (pickInfo.pickedMesh.id === labelMesh.id) {
        if (isInteractionsActive) {
          return
        }

        this.labelGizmo.show(this.groupParent)
        isInteractionsActive = true
          ; (this.renderScene as RenderScene).animate(true)
      } else {
        if (!isInteractionsActive) {
          return
        }

        this.labelGizmo.hide()
        isInteractionsActive = false
          ; (this.renderScene as RenderScene).animate(true)
      }
    }

    this.scene.onPointerObservable.add(this.labelInteractionsVisibility)
  }

  deactivateLabelInteraction(labelId?: string) {
    try {
      if (!labelId) {
        this.scene.meshes.map((m) => {
          if (m.name !== LABEL) {
            return
          }

          this.toggleSelectedBodyHighlight(false, m)
          this.setNativeParent(m)
          m.showBoundingBox = false
        })
      } else {
        const labelMesh = this.scene.getMeshByID(labelId)
        this.toggleSelectedBodyHighlight(false, labelMesh)
        this.setNativeParent(labelMesh)
        labelMesh.showBoundingBox = false
      }
    } catch (error) {
      console.error(error)
    } finally {
      if (this.groupParent) {
        this.scene.removeMesh(this.groupParent)
        this.groupParent.dispose()
        this.groupParent = null
      }

      this.labelGizmo.hide()
      this.scene.onPointerObservable.removeCallback(this.labelInteractionsVisibility)
    }
  }

  /**
   * Show handle for moving, rotating label and display label menu
   * @param labelDraggable - label sensitive zone if label mesh created or label origin if not
   */
  showManualLabelHandle(labelDraggable: AbstractMesh) {
    if (!labelDraggable || this.labelGizmo.isDragging) return
    if (this.groupParent && this.groupParent.metadata) {
      if (this.groupParent.metadata.sensitiveZoneId !== labelDraggable.id) {
        this.labelGizmo.hide()
        this.scene.removeMesh(this.groupParent)
        this.groupParent.dispose()
        this.groupParent = null
      } else return
    }

    let labelMesh = null
    if (this.meshManager.isLabelSensitiveZone(labelDraggable)) {
      labelMesh = this.scene.meshes.find(
        (m) => m.id === labelDraggable.metadata.labelId && m.metadata.itemType === SceneItemType.Label,
      )
    }

    this.createGroupParent(labelMesh ? labelMesh.metadata.orientation : labelDraggable.metadata.orientation)
    this.groupParent.metadata = {
      labelId: labelMesh ? labelMesh.id : labelDraggable.metadata.labelId,
      sensitiveZoneId: labelDraggable.id,
    }
    this.labelGizmo.show(this.groupParent, !labelDraggable.metadata.patchNotCreated, true, true)
    eventBus.$emit(BuildPlanEvents.DisplayManualLabelSettings, {
      isVisible: true,
      settingsLocation: this.calculateManualLabelSettingsLocation(labelDraggable),
      disableLabelAllInstances: labelDraggable.metadata.patchNotCreated,
      disableApplyRotationToInstances: labelDraggable.metadata.patchNotCreated,
    })
  }

  hideManualLabelHandle(force?: boolean) {
    if (!force) {
      const pickedObject = this.renderScene.getGpuPicker().pick(this.scene.pointerX, this.scene.pointerY)
      if (pickedObject && this.groupParent && pickedObject.body.id === this.groupParent.metadata.sensitiveZoneId) {
        return
      }

      const pickInfo = this.labelGizmo.pick(this.scene.pointerX, this.scene.pointerY)
      if (!(pickInfo.hit && pickInfo.pickedMesh.name === ROTATION_GIZMO_ARROW_NAME)) {
        eventBus.$emit(BuildPlanEvents.DisplayManualLabelSettings, {
          isVisible: false,
          settingsLocation: { x: 0, y: 0 },
        })
      }

      if ((pickInfo.hit && pickInfo.pickedMesh.name === ROTATION_GIZMO_ARROW_NAME) || this.labelGizmo.isDragging) {
        ; (this.renderScene as RenderScene).onHoverLabel.trigger(this.groupParent.metadata.labelId)
        return
      }
    }

    if (this.groupParent) {
      this.labelGizmo.hide()
      this.scene.removeMesh(this.groupParent)
      this.groupParent.dispose()
      this.groupParent = null
    }
  }

  hoverManualLabelSettings() {
    if (this.hideLabelHandleThrottle) {
      clearTimeout(this.hideLabelHandleThrottle)
    }
  }

  hideManualLabelHandleWithTimeout() {
    if (this.hideLabelHandleThrottle) {
      clearTimeout(this.hideLabelHandleThrottle)
    }

    this.hideLabelHandleThrottle = setTimeout(() => {
      this.modelManager.labelMgr.hideManualLabelHandle()
        ; (this.renderScene as RenderScene).onHoverLabel.trigger(null)
    }, this.hideLabelHandleDebounce)
  }

  async updateLabelAppearance(
    id: string,
    newStyle: ILabelStyle,
    shouldLabelBeSelected: boolean = true,
    updateStore: boolean = true,
  ) {
    if (!newStyle) {
      throw new Error('New label style is not defined')
    }

    this.setupLabelConfig(newStyle.fontSize, newStyle.fontFamily, newStyle.text)
    const labelMesh = this.scene.getMeshByID(id)
    if (!labelMesh) {
      throw new Error('Label on canvas does not exist')
    }

    if (!newStyle.text || !newStyle.text.length) {
      labelMesh.isVisible = false
        ; (this.renderScene as RenderScene).animate()
      return
    }

    const labelMetadata = labelMesh.metadata as ILabelMetadata
    const newOriginScreen = this.meshManager.project3DPointOntoScreen(labelMetadata.orientation.origin)
    const pickingRay = this.scene.createPickingRay(newOriginScreen.x, newOriginScreen.y, IDENTITY_MATRIX, this.camera)
    const pickInfo = this.scene.pickWithRay(
      pickingRay,
      (mesh: AbstractMesh) => this.meshManager.getBuildPlanItemMeshByChild(mesh).id === labelMesh.metadata.parentId,
    )
    if (!pickInfo.hit) {
      return false
    }

    if (updateStore) {
      this.labelPlaced.trigger()
    }

    labelMesh.isPickable = false
    const newTextDirection = Vector3.Cross(labelMetadata.orientation.yDirection, labelMetadata.orientation.normal)
    const newLabelMesh = this.generateLabelMesh(labelMesh.id, newStyle, pickInfo, newTextDirection, true)
    const orientation = this.translateToPartCS(newLabelMesh, newTextDirection, pickInfo)
    const material = this.getLabelConfig(newStyle.fontSize, newStyle.fontFamily, newStyle.text).regularMaterial
    const pickedMeshPart = this.meshManager.getBuildPlanItemMeshByChild(pickInfo.pickedMesh)

    if (updateStore) {
      const originFacetId = this.scene.pickWithRay(pickingRay, (mesh) => mesh.isPickable && mesh.id === id).faceId
      const dracoFile = await this.createDracoFromLabelMesh(newLabelMesh, id, originFacetId)
      const updatedLabel = this.createNewLabel(
        id,
        labelMetadata.componentId,
        labelMetadata.geometryId,
        orientation,
        labelMetadata.relativeTransformation,
        newStyle,
        labelMetadata.isValid,
        null,
      )

      this.onLabelUpdated.trigger({
        label: updatedLabel,
        buildPlanItemId: pickedMeshPart.metadata.buildPlanItemId,
        file: dracoFile,
      })
    }

    this.setupLabelMesh(
      newLabelMesh,
      id,
      labelMetadata.componentId,
      labelMetadata.geometryId,
      newStyle,
      pickedMeshPart,
      material,
      labelMetadata.relativeTransformation,
      orientation,
      labelMetadata.isValid,
      labelMetadata.placementInvalid,
      labelMetadata.placedNotCorrectly,
    )
    this.translateToWorldCS(newLabelMesh, orientation, pickedMeshPart)
    this.deactivateLabelInteraction()
    this.removeLabelMesh(labelMesh)

    // need for fix z-fighting when camera is directed along -z axis
    newLabelMesh.position = newLabelMesh.position.add(labelMetadata.orientation.normal.scale(Epsilon))

    this.createLabelInstance(newLabelMesh)
    if (shouldLabelBeSelected) {
      this.activateLabelInteraction(id)
    }

    return true
  }

  updateManualPlacedLabelPosition(
    labelId: string,
    orientation: IOrientation,
    rotationAngle: number,
    dragStartPoint: Vector2,
  ) {
    const gpuPickInfo = this.renderScene.getGpuPicker().pick(this.scene.pointerX, this.scene.pointerY)
    const pickPredicate = (m: AbstractMesh) =>
      this.meshManager.isComponentMesh(m) &&
      gpuPickInfo &&
      this.meshManager.getBuildPlanItemMeshByChild(m).id === gpuPickInfo.part.id
    const pickInfo = this.getOriginPickInfoAfterDrag(orientation.origin, dragStartPoint, pickPredicate)
    const labelMesh = this.scene.meshes.find(
      (m) => m.id === labelId && (this.meshManager.isLabelMesh(m) || this.meshManager.isLabelOrigin(m)),
    )
    const isLabelOrigin = this.meshManager.isLabelOrigin(labelMesh)
    const componentId = isLabelOrigin ? labelMesh.metadata.bodyComponentId : labelMesh.metadata.componentId
    const geometryId = isLabelOrigin ? labelMesh.metadata.bodyGeometryId : labelMesh.metadata.geometryId
    if (!pickInfo.hit) {
      const insideMesh = isLabelOrigin
        ? null
        : labelMesh.getChildMeshes().find((l) => l.name === LABEL_INSIDE_MESH_NAME)
      if (!labelMesh.metadata.isHidden) {
        labelMesh.isVisible = true
        if (insideMesh) {
          insideMesh.isVisible = true
        }
      } else {
        this.meshManager.showTransparentClone(labelMesh as InstancedMesh)
      }
    } else {
      const root = this.meshManager.getBuildPlanItemMeshByChild(pickInfo.pickedMesh)

      // In Babylon 4.2.1 pointer up is not triggered on original scene after gizmo is released
      this.scene.simulatePointerUp(this.scene.pick(this.scene.pointerX, this.scene.pointerY))
      const bpItemMesh = this.meshManager.getBuildPlanItemMeshByChild(pickInfo.pickedMesh)
      const otherLabelsForOldBody = labelMesh.parent
        .getChildMeshes()
        .filter(
          (c) =>
            c.id !== labelId &&
            c.metadata &&
            (c.metadata.itemType === SceneItemType.Label || c.metadata.itemType === SceneItemType.LabelOrigin) &&
            c.metadata.labelSetId === labelMesh.metadata.labelSetId,
        )

      const activeLabelSet: InteractiveLabelSet = store.getters['label/activeLabelSet']
      const placementsOnBodyIds = activeLabelSet.manualPlacements
        .filter(
          (mp) =>
            mp.buildPlanItemId === labelMesh.metadata.buildPlanItemId &&
            mp.componentId === componentId &&
            mp.geometryId === geometryId,
        )
        .map((placementOnBody) => placementOnBody.id)
      const labelsToAddOrUpdate = activeLabelSet.labels.filter(
        (l: ManualTrackableLabel) =>
          placementsOnBodyIds.includes(l.manualPlacementId) &&
          (l.dirtyState === LabelDirtyState.Add || l.dirtyState === LabelDirtyState.Update),
      )

      // Last label from body moved to another body
      if (
        !otherLabelsForOldBody.length &&
        !labelsToAddOrUpdate.length &&
        (componentId !== pickInfo.pickedMesh.metadata.componentId ||
          labelMesh.metadata.buildPlanItemId !== bpItemMesh.metadata.buildPlanItemId)
      ) {
        this.renderScene.getSelectionManager().deselect([
          {
            part: labelMesh.parent as TransformNode,
            body: this.meshManager.getComponentMesh(componentId, geometryId, labelMesh.metadata.buildPlanItemId),
          },
        ])
      }

      const transformation = this.getLabelParentTransformation(pickInfo.pickedMesh)
      const invTransformation = transformation.clone().invert()

      const normal = pickInfo.getNormal(true)
      const origin = pickInfo.pickedPoint
      const textDirection = this.getTextDirectionAfterDrag(pickInfo, orientation)
      const directions = this.calculateDirections(normal, textDirection)
      if (isLabelOrigin) {
        this.disposeLabelOrigins({ labelId })
      } else {
        this.removeLabelMesh(this.scene.meshes.find((m) => m.id === labelId && this.meshManager.isLabelMesh(m)))
      }

      const originMesh = this.createOriginInstance(
        labelId,
        activeLabelSet.id,
        root.metadata.buildPlanItemId,
        pickInfo.pickedMesh.metadata.componentId,
        pickInfo.pickedMesh.metadata.geometryId,
        pickInfo.pickedPoint.clone(),
        {
          normal,
          origin,
          xDirection: directions.xDirection,
          yDirection: directions.yDirection,
        },
        LABEL_ORIGIN_REGULAR_COLOR,
        bpItemMesh,
        false,
      )
      this.setupForGpuPicker(originMesh, SceneItemType.LabelOrigin)

      this.labelOrientationChanged.trigger({
        manualPatches: [
          {
            rotationAngle: rotationAngle ? rotationAngle : 0,
            id: labelId,
            buildPlanItemId: root.metadata.buildPlanItemId,
            componentId: pickInfo.pickedMesh.metadata.componentId,
            geometryId: pickInfo.pickedMesh.metadata.geometryId,
            orientation: {
              normal: this.convertToVertex(Vector3.TransformNormal(normal, invTransformation).normalize()),
              origin: this.convertToVertex(Vector3.TransformCoordinates(origin, invTransformation)),
              xDirection: this.convertToVertex(
                Vector3.TransformNormal(directions.xDirection, invTransformation).normalize(),
              ),
              yDirection: this.convertToVertex(
                Vector3.TransformNormal(directions.yDirection, invTransformation).normalize(),
              ),
            },
          },
        ],
      })
    }

    this.labelGizmo.hide()
    if (this.groupParent) {
      this.scene.removeMesh(this.groupParent)
      this.groupParent.dispose()
      this.groupParent = null
    }
  }

  updateManualPlacedLabelRotation(labelId: string, initTransformations: Matrix, yDirection: Vector3) {
    const labelMesh = this.scene.meshes.find((m) => m.id === labelId && this.meshManager.isLabelMesh(m))
    let newYDirection = Vector3.TransformNormal(yDirection, initTransformations.clone().invert())
    newYDirection = Vector3.TransformNormal(newYDirection, this.groupParent.getWorldMatrix())
    const textDirection = Vector3.Cross(newYDirection, labelMesh.metadata.orientation.normal)
    const directions = this.calculateDirections(labelMesh.metadata.orientation.normal, textDirection)
    const originMesh = this.createOriginInstance(
      labelId,
      labelMesh.metadata.labelSetId,
      labelMesh.metadata.buildPlanItemId,
      labelMesh.metadata.componentId,
      labelMesh.metadata.geometryId,
      labelMesh.metadata.orientation.origin.clone(),
      {
        normal: labelMesh.metadata.orientation.normal,
        origin: labelMesh.metadata.orientation.origin,
        xDirection: directions.xDirection,
        yDirection: directions.yDirection,
      },
      LABEL_ORIGIN_REGULAR_COLOR,
      labelMesh.parent as TransformNode,
      false,
    )
    this.setupForGpuPicker(originMesh, SceneItemType.LabelOrigin)
    const root = this.meshManager.getBuildPlanItemMeshByChild(labelMesh)
    const transformation = this.getLabelParentTransformation(labelMesh)
    const invTransformation = transformation.clone().invert()

    this.labelOrientationChanged.trigger({
      manualPatches: [
        {
          id: labelId,
          buildPlanItemId: root.metadata.buildPlanItemId,
          componentId: labelMesh.metadata.componentId,
          geometryId: labelMesh.metadata.geometryId,
          rotationAngle: this.calculateRotationAngle(textDirection, labelMesh.metadata.orientation.normal),
          orientation: {
            normal: this.convertToVertex(
              Vector3.TransformNormal(labelMesh.metadata.orientation.normal, invTransformation).normalize(),
            ),
            origin: this.convertToVertex(
              Vector3.TransformCoordinates(labelMesh.metadata.orientation.origin, invTransformation),
            ),
            xDirection: this.convertToVertex(
              Vector3.TransformNormal(directions.xDirection, invTransformation).normalize(),
            ),
            yDirection: this.convertToVertex(
              Vector3.TransformNormal(directions.yDirection, invTransformation).normalize(),
            ),
          },
        },
      ],
      singleLabelUpdate: true,
    })

    this.labelGizmo.hide()
    this.removeLabelMesh(this.scene.meshes.find((m) => m.id === labelId && this.meshManager.isLabelMesh(m)))
    if (this.groupParent) {
      this.scene.removeMesh(this.groupParent)
      this.groupParent.dispose()
      this.groupParent = null
    }
  }

  async updateLabelPosition(labelId: string, dragStartPoint: Vector2) {
    const labelMesh = this.scene.getMeshByID(labelId)
    const labelMetadata = labelMesh.metadata as ILabelMetadata
    const tempLabelMesh = this.scene.getMeshByID(`${labelId}${TEMP}`)
    if (tempLabelMesh) {
      this.removeLabelMesh(tempLabelMesh)
    }

    const pickInfo = this.getOriginPickInfoAfterDrag(labelMetadata.orientation.origin, dragStartPoint)
    if (!pickInfo.hit) {
      this.deactivateLabelInteraction()
      labelMesh.isVisible = true
      this.activateLabelInteraction(labelId)
      return
    }

    this.labelPlaced.trigger()
    const textDirection = this.getTextDirectionAfterDrag(pickInfo, labelMetadata.orientation)
    const newLabelMesh = this.generateLabelMesh(labelId, labelMetadata.style, pickInfo, textDirection, true)
    const validationResult = this.validateLabelMesh(newLabelMesh)
    const bpItem = this.meshManager.getBuildPlanItemMeshByChild(pickInfo.pickedMesh)
    const relativeMatrix = newLabelMesh.getWorldMatrix().multiply(newLabelMesh.parent.getWorldMatrix().clone().invert())
    const orientation = this.translateToPartCS(newLabelMesh, textDirection, pickInfo)
    const originFacetId = this.scene.pickWithRay(pickInfo.ray, (mesh) => mesh.id === newLabelMesh.id).faceId

    const componentMetadata = this.meshManager.isComponentMesh(pickInfo.pickedMesh)
      ? (pickInfo.pickedMesh.metadata as IComponentMetadata)
      : null
    const componentId = componentMetadata ? componentMetadata.componentId : null
    const geometryId = componentMetadata ? componentMetadata.geometryId : null
    const dracoFile = await this.createDracoFromLabelMesh(newLabelMesh, newLabelMesh.id, originFacetId)
    const label = this.createNewLabel(
      newLabelMesh.id,
      componentId,
      geometryId,
      orientation,
      relativeMatrix,
      labelMetadata.style,
      validationResult.isValid,
      new Date(),
    )
    this.onLabelUpdated.trigger({
      label,
      buildPlanItemId: bpItem.metadata.buildPlanItemId,
      file: dracoFile,
    })

    const material = this.getLabelConfig(
      labelMetadata.style.fontSize,
      labelMetadata.style.fontFamily,
      labelMetadata.style.text,
    ).regularMaterial
    this.setupLabelMesh(
      newLabelMesh,
      label.id,
      componentId,
      geometryId,
      label.style,
      bpItem,
      material,
      relativeMatrix,
      orientation,
      validationResult.isValid,
      validationResult.isDownskin,
      validationResult.isSharp,
    )
    this.translateToWorldCS(newLabelMesh, orientation, bpItem)
    this.deactivateLabelInteraction()
    this.removeLabelMesh(labelMesh)

    // need for fix z-fighting when camera is directed along -z axis
    newLabelMesh.position = newLabelMesh.position.add(orientation.normal.scale(Epsilon))

    this.createLabelInstance(newLabelMesh)
    this.activateLabelInteraction(labelId)
  }

  async updateLabelRotation(labelId: string, initTransformations: Matrix, yDirection: Vector3) {
    const labelMesh = this.scene.getMeshByID(labelId) as Mesh
    const labelMetadata = labelMesh.metadata as ILabelMetadata
    let newYDirection = Vector3.TransformNormal(yDirection, initTransformations.clone().invert())
    newYDirection = Vector3.TransformNormal(newYDirection, labelMesh.parent.getWorldMatrix())
    const newOriginScreen = this.meshManager.project3DPointOntoScreen(labelMetadata.orientation.origin)
    const pickingRay = this.scene.createPickingRay(newOriginScreen.x, newOriginScreen.y, IDENTITY_MATRIX, this.camera)
    const pickInfo = this.scene.pickWithRay(pickingRay, this.pickPredicate)
    this.labelPlaced.trigger()
    this.removeLabelMesh(labelMesh)

    const textDirection = Vector3.Cross(newYDirection, labelMetadata.orientation.normal)
    const newLabelMesh = this.generateLabelMesh(labelId, labelMetadata.style, pickInfo, textDirection, true)
    const bpItem = this.meshManager.getBuildPlanItemMeshByChild(pickInfo.pickedMesh)
    const relativeMatrix = newLabelMesh.getWorldMatrix().multiply(newLabelMesh.parent.getWorldMatrix().clone().invert())
    const validationResult = this.validateLabelMesh(newLabelMesh)
    const orientation = this.translateToPartCS(newLabelMesh, textDirection, pickInfo)
    const originFacetId = this.scene.pickWithRay(pickingRay, (mesh) => mesh.id === labelId).faceId

    const componentMetadata = this.meshManager.isComponentMesh(pickInfo.pickedMesh)
      ? (pickInfo.pickedMesh.metadata as IComponentMetadata)
      : null
    const componentId = componentMetadata ? componentMetadata.componentId : null
    const geometryId = componentMetadata ? componentMetadata.geometryId : null
    const dracoFile = await this.createDracoFromLabelMesh(newLabelMesh, labelId, originFacetId)
    const label = this.createNewLabel(
      labelId,
      componentId,
      geometryId,
      orientation,
      relativeMatrix,
      labelMetadata.style,
      validationResult.isValid,
      new Date(),
    )
    this.onLabelUpdated.trigger({
      label,
      buildPlanItemId: bpItem.metadata.buildPlanItemId,
      file: dracoFile,
    })

    const material = this.getLabelConfig(
      labelMetadata.style.fontSize,
      labelMetadata.style.fontFamily,
      labelMetadata.style.text,
    ).regularMaterial
    this.setupLabelMesh(
      newLabelMesh,
      labelId,
      componentId,
      geometryId,
      label.style,
      bpItem,
      material,
      relativeMatrix,
      orientation,
      validationResult.isValid,
      validationResult.isDownskin,
      validationResult.isSharp,
    )
    this.translateToWorldCS(newLabelMesh, orientation, bpItem)
    this.deactivateLabelInteraction()

    // need for fix z-fighting when camera is directed along -z axis
    newLabelMesh.position = newLabelMesh.position.add(orientation.normal.scale(Epsilon))

    this.createLabelInstance(newLabelMesh)
    this.activateLabelInteraction(labelId)
  }

  updateLabelGizmoScale() {
    this.labelGizmo.updateGizmoScale()
  }

  rotateLabel(labelId: string, initTransformations: Matrix, yDirection: Vector3) {
    const labelMesh = this.scene.getMeshByID(labelId)
    const labelMetadata = labelMesh.metadata as ILabelMetadata
    let newYDirection = Vector3.TransformNormal(yDirection, initTransformations.clone().invert())
    newYDirection = Vector3.TransformNormal(newYDirection, labelMesh.parent.getWorldMatrix())
    labelMetadata.orientation.yDirection = newYDirection
    const parent = labelMesh.parent
    const newOriginScreen = this.meshManager.project3DPointOntoScreen(labelMetadata.orientation.origin)
    const pickingRay = this.scene.createPickingRay(newOriginScreen.x, newOriginScreen.y, IDENTITY_MATRIX, this.camera)
    const pickInfo = this.scene.pickWithRay(pickingRay, this.pickPredicate)
    this.removeLabelMesh(labelMesh)

    const newTextDirection = Vector3.Cross(newYDirection, labelMetadata.orientation.normal)
    const newLabelMesh = this.generateLabelMesh(labelId, labelMetadata.style, pickInfo, newTextDirection, false)
    const material = this.getLabelConfig(
      labelMetadata.style.fontSize,
      labelMetadata.style.fontFamily,
      labelMetadata.style.text,
    ).previewMaterial
    const componentMetadata = this.meshManager.isComponentMesh(pickInfo.pickedMesh)
      ? (pickInfo.pickedMesh.metadata as IComponentMetadata)
      : null
    const componentId = componentMetadata ? componentMetadata.componentId : null
    const geometryId = componentMetadata ? componentMetadata.geometryId : null
    const validationResult = this.validateLabelMesh(newLabelMesh)
    this.setupLabelMesh(
      newLabelMesh,
      labelId,
      componentId,
      geometryId,
      labelMetadata.style,
      null,
      material,
      newLabelMesh.getWorldMatrix(),
      labelMetadata.orientation,
      validationResult.isValid,
      validationResult.isDownskin,
      validationResult.isSharp,
    )

    // need for fix z-fighting when camera is directed along -z axis
    newLabelMesh.position = newLabelMesh.position.add(labelMetadata.orientation.normal.scale(Epsilon))

    newLabelMesh.setParent(parent)
    newLabelMesh.showBoundingBox = true
  }

  translateLabelOrientation(labelMesh: AbstractMesh, toWorld: boolean) {
    let partMesh = this.scene.getTransformNodeByID(labelMesh.parent.id)
    if (!partMesh) {
      partMesh = this.scene.getMeshByID(labelMesh.parent.id)
    }
    const labelMetadata = labelMesh.metadata as ILabelMetadata
    let transformation =
      partMesh.name === GROUP_PARENT_MESH_NAME
        ? partMesh.getWorldMatrix().clone()
        : this.getLabelParentTransformation(partMesh)
    transformation = toWorld ? transformation : transformation.invert()
    labelMetadata.orientation.origin = Vector3.TransformCoordinates(labelMetadata.orientation.origin, transformation)
    labelMetadata.orientation.normal = Vector3.TransformNormal(labelMetadata.orientation.normal, transformation)
    labelMetadata.orientation.xDirection = Vector3.TransformNormal(labelMetadata.orientation.xDirection, transformation)
    labelMetadata.orientation.yDirection = Vector3.TransformNormal(labelMetadata.orientation.yDirection, transformation)
  }

  getLabelInsights() {
    return this.insightsManager.buildInsights(this.scene.meshes.filter((m) => m.name === LABEL))
  }

  refreshLabelInsights() {
    // Empty for now. It is necessary for easier finding entry point for downstream operation
  }

  copyLabelMesh(source: Mesh, id: string, name: string, parent?: TransformNode) {
    const copy = source.createInstance(name)
    copy.parent = source.parent
    copy.id = id
    copy.isPickable = false
    copy.metadata = _.cloneDeep(source.metadata)
    copy.instancedBuffers.color = REGULAR_ORANGE
    if (parent) {
      copy.parent = parent
      if (copy.metadata) {
        copy.metadata.parentId = parent.id
      }
    }

    return copy
  }

  validateLabels(part: TransformNode) {
    if (!part) {
      return
    }

    for (const child of part.getChildMeshes()) {
      if (!this.meshManager.isLabelMesh(child)) {
        continue
      }

      const validationResult = this.validateLabelMesh(child as Mesh)
      child.metadata.isValid = validationResult.isValid
      child.metadata.placementInvalid = validationResult.isDownskin
      child.metadata.placedNotCorrectly = validationResult.isSharp
    }
  }

  validateLabel(label) {
    if (!label) {
      return
    }

    const validationResult = this.validateLabelMesh(label as Mesh)
    label.metadata.isValid = validationResult.isValid
    label.metadata.placementInvalid = validationResult.isDownskin
    label.metadata.placedNotCorrectly = validationResult.isSharp
  }

  toggleValidBodiesHighlight(showHighlight: boolean) {
    const items = []
    const parts = Array.from(this.renderScene.getSceneMetadata().buildPlanItems.values())
    for (const part of parts) {
      const partBodies = part.getChildMeshes(false, this.meshManager.isComponentMesh)
      for (const body of partBodies) {
        items.push({ body })
      }
    }
    this.renderScene.getSelectionManager().highlight(items, showHighlight, true)
  }

  toggleSelectedBodyHighlight(showHighlight: boolean, labelMesh: AbstractMesh) {
    const body = this.getLabelComponentMesh(labelMesh)
    const item = { body }
    if (showHighlight) {
      if (this.renderScene.getSelectionManager().isSelected(item)) {
        this.renderScene.getSelectionManager().deselect([item], true)
      }
      this.renderScene.getSelectionManager().select([item], true, true)
    } else {
      this.renderScene.getSelectionManager().highlight([item], showHighlight, true)
    }
  }

  registerLabelCreationEvents() {
    let tempLabelMesh = null
    this.scene.onPointerMove = (evt) => {
      if (!this.canAddLabel) {
        return
      }

      tempLabelMesh = this.meshManager.getRawLabel()
      if (tempLabelMesh) {
        this.removeLabelMesh(tempLabelMesh)
      }

      this.createRawLabel(evt.offsetX, evt.offsetY)
    }

    let pointerXStart = null
    let pointerYStart = null
    const canvas = this.scene.getEngine().getRenderingCanvas()
    const pointerXMoveThreshold = canvas.clientWidth * THRESHOLD_RATIO
    const pointerYMoveThreshold = canvas.clientHeight * THRESHOLD_RATIO

    this.scene.onPointerDown = (evt) => {
      pointerXStart = evt.clientX
      pointerYStart = evt.clientY
    }

    this.scene.onPointerUp = async (evt) => {
      if (
        Math.abs(pointerXStart - evt.clientX) > pointerXMoveThreshold ||
        Math.abs(pointerYStart - evt.clientY) > pointerYMoveThreshold
      ) {
        return
      }

      if (!this.canAddLabel) {
        return
      }

      const pickingRay = this.scene.createPickingRay(evt.offsetX, evt.offsetY, IDENTITY_MATRIX, this.camera)
      const pickInfo = this.scene.pickWithRay(pickingRay, this.pickPredicate)
      if (!pickInfo.hit) {
        return
      }

      tempLabelMesh = this.meshManager.getRawLabel()
      if (tempLabelMesh) {
        this.removeLabelMesh(tempLabelMesh)
      }

      this.labelPlaced.trigger()
      const textDirection = this.getTextDirection(pickInfo)
      const labelMesh = this.generateLabelMesh(uuid(), this.labelStyle, pickInfo, textDirection, true)
      const validationResult = this.validateLabelMesh(labelMesh)
      const bpItem = this.meshManager.getBuildPlanItemMeshByChild(pickInfo.pickedMesh)
      const relativeTransformation = labelMesh.getWorldMatrix().multiply(bpItem.getWorldMatrix().clone().invert())
      const orientation = this.translateToPartCS(labelMesh, textDirection, pickInfo)
      const originFacetId = this.scene.pickWithRay(pickingRay, (mesh) => mesh.id === labelMesh.id).faceId

      const componentMetadata = this.meshManager.isComponentMesh(pickInfo.pickedMesh)
        ? (pickInfo.pickedMesh.metadata as IComponentMetadata)
        : null
      const componentId = componentMetadata ? componentMetadata.componentId : null
      const geometryId = componentMetadata ? componentMetadata.geometryId : null
      const dracoFile = await this.createDracoFromLabelMesh(labelMesh, labelMesh.id, originFacetId)
      const label = this.createNewLabel(
        labelMesh.id,
        componentId,
        geometryId,
        orientation,
        relativeTransformation,
        this.labelStyle,
        validationResult.isValid,
        new Date(),
      )
      this.onLabelAdded.trigger({
        label,
        buildPlanItemId: bpItem.metadata.buildPlanItemId,
        file: dracoFile,
      })

      const material = this.getLabelConfig(
        this.labelStyle.fontSize,
        this.labelStyle.fontFamily,
        this.labelStyle.text,
      ).regularMaterial
      this.setupLabelMesh(
        labelMesh,
        label.id,
        componentId,
        geometryId,
        label.style,
        bpItem,
        material,
        relativeTransformation,
        orientation,
        validationResult.isValid,
        validationResult.isDownskin,
        validationResult.isSharp,
      )
      this.translateToWorldCS(labelMesh, orientation, bpItem)
      // need for fix z-fighting when camera is directed along -z axis
      labelMesh.position = labelMesh.position.add(orientation.normal.scale(Epsilon))

      this.createLabelInstance(labelMesh)
    }

    canvas.onpointerleave = () => {
      if (tempLabelMesh) {
        this.removeLabelMesh(tempLabelMesh)
      }
    }
  }

  unregisterLabelCreationEvents() {
    const canvas = this.scene.getEngine().getRenderingCanvas()
    this.scene.onPointerMove = null
    this.scene.onPointerUp = null
    this.scene.onPointerDown = null
    canvas.onpointerleave = null
  }

  registerManualLabelUpdatingEvents(mesh: Mesh, orientation: IOrientation, dragStartPoint: Vector2) {
    let previousNormal = orientation.normal.clone()
    const axisZTransformation = this.alignToNormal(mesh, orientation.origin, Axis.Z, previousNormal)
    let previousTextDirection = Vector3.Cross(orientation.yDirection, orientation.normal)
    this.alignToNormal(
      mesh,
      orientation.origin,
      Vector3.TransformNormal(Axis.X, axisZTransformation),
      previousTextDirection,
    )
    this.manualLabelUpdateObserver = this.scene.onPointerObservable.add((eventData) => {
      if (eventData.type === PointerEventTypes.POINTERMOVE) {
        const gpuPickInfo = this.renderScene.getGpuPicker().pick(this.scene.pointerX, this.scene.pointerY)
        const pickPredicate = (m: AbstractMesh) =>
          this.meshManager.isComponentMesh(m) &&
          gpuPickInfo &&
          this.meshManager.getBuildPlanItemMeshByChild(m).id === gpuPickInfo.part.id
        const originPickInfo = this.getOriginPickInfoAfterDrag(orientation.origin, dragStartPoint, pickPredicate)
        if (!originPickInfo.hit || !originPickInfo.getNormal(true)) {
          mesh.isVisible = false
          return
        }

        const textDirection = this.getTextDirectionAfterDrag(originPickInfo, orientation)
        const pickNormal = originPickInfo.getNormal(true)
        mesh.position = originPickInfo.pickedPoint
        mesh.isVisible = true
        if (
          Math.abs(previousNormal.x - pickNormal.x) > Epsilon ||
          Math.abs(previousNormal.y - pickNormal.y) > Epsilon ||
          Math.abs(previousNormal.z - pickNormal.z) > Epsilon
        ) {
          const normalTransformation = this.alignToNormal(mesh, orientation.origin, previousNormal, pickNormal)
          const previousTransformed = Vector3.TransformNormal(previousTextDirection, normalTransformation)
          previousNormal = pickNormal.clone()

          if (
            Math.abs(previousTransformed.x - textDirection.x) > Epsilon ||
            Math.abs(previousTransformed.y - textDirection.y) > Epsilon ||
            Math.abs(previousTransformed.z - textDirection.z) > Epsilon
          ) {
            this.alignToNormal(mesh, orientation.origin, previousTransformed, textDirection)
          }

          previousTextDirection = textDirection.clone()
        }
      }
    })

    const canvas = this.scene.getEngine().getRenderingCanvas()
    canvas.onpointerleave = () => {
      mesh.isVisible = false
    }
  }

  unRegisterManualLabelUpdatingEvents() {
    const canvas = this.scene.getEngine().getRenderingCanvas()
    this.scene.onPointerObservable.remove(this.manualLabelUpdateObserver)
    canvas.onpointerleave = null
  }

  registerLabelUpdatingEvents(labelMesh: AbstractMesh, dragStartPoint: Vector2) {
    const updatingLabelBody = this.getLabelComponentMesh(labelMesh)
    const labelMetadata = labelMesh.metadata as ILabelMetadata
    const labelStyle = labelMetadata.style
    let highlighted: AbstractMesh = null
    let tempLabelMesh = this.meshManager.getRawLabel()
    this.scene.onPointerMove = (evt) => {
      if (labelMesh.parent.id !== labelMetadata.parentId) {
        this.setNativeParent(labelMesh)
      }

      const pickingRay = this.scene.createPickingRay(evt.offsetX, evt.offsetY, IDENTITY_MATRIX, this.camera)
      const pickInfo = this.scene.pickWithRay(pickingRay, this.pickPredicate)

      if (highlighted) {
        this.renderScene.getSelectionManager().highlight([{ body: highlighted }], false, true)
      }

      if (!pickInfo.hit || !this.meshManager.isComponentMesh(pickInfo.pickedMesh)) {
        highlighted = null
      } else if (pickInfo.pickedMesh.id !== updatingLabelBody.id) {
        highlighted = pickInfo.pickedMesh
        this.renderScene.getSelectionManager().highlight([{ body: highlighted }], true, true)
      }

      if (tempLabelMesh) {
        this.removeLabelMesh(tempLabelMesh)
      }

      if (labelMesh) {
        labelMesh.isVisible = false
      }

      const originPickInfo = this.getOriginPickInfoAfterDrag(labelMetadata.orientation.origin, dragStartPoint)
      const normal = originPickInfo.getNormal(true)
      if (!originPickInfo.hit || !normal) {
        return
      }

      const textDirection = this.getTextDirectionAfterDrag(originPickInfo, labelMetadata.orientation)
      const material = this.getLabelConfig(labelStyle.fontSize, labelStyle.fontFamily, labelStyle.text).previewMaterial

      tempLabelMesh = this.generateLabelMesh(null, labelStyle, originPickInfo, textDirection, false)
      tempLabelMesh.id = `${labelMesh.id}${TEMP}`
      tempLabelMesh.material = material
      tempLabelMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
      tempLabelMesh.showBoundingBox = true
      // need for fix z-fighting when camera is directed along -z axis
      tempLabelMesh.position = tempLabelMesh.position.add(normal.scale(Epsilon))
      tempLabelMesh.isVisible = true
      const validationResult = this.validateLabelMesh(tempLabelMesh as Mesh)
      tempLabelMesh.metadata = {
        itemType: SceneItemType.RawLabel,
        isValid: validationResult.isValid,
      }
    }

    const canvas = this.scene.getEngine().getRenderingCanvas()
    canvas.onpointerleave = () => {
      if (tempLabelMesh) {
        this.removeLabelMesh(tempLabelMesh)
      }
    }
  }

  unRegisterLabelUpdatingEvents() {
    const canvas = this.scene.getEngine().getRenderingCanvas()
    this.scene.onPointerMove = null
    canvas.onpointerleave = null
  }

  removeLabels(
    options?: Array<{
      labelSetId?: string
      buildPlanItemId?: string
      componentId?: string
      geometryId?: string
      labelId?: string
      trackId?: string
    }>,
  ) {
    const labelsToRemove = this.scene.meshes.filter((mesh) => {
      return (
        this.meshManager.isLabelMesh(mesh) &&
        (options
          ? options.some((option) => {
            return Object.entries(option).every(([key, value]) => {
              if (option.trackId) {
                return option.trackId === mesh.metadata.trackId
              }

              if (value && mesh.metadata && mesh.metadata[key]) {
                return mesh.metadata[key] === value
              }

              if (value && key === LABEL_ID) {
                return mesh.id === value
              }

              return true
            })
          })
          : true)
      )
    })

    const labelsToRemoveInfos = labelsToRemove.map((labelToRemove) => {
      return {
        buildPlanItemId: labelToRemove.metadata.buildPlanItemId,
        componentId: labelToRemove.metadata.componentId,
        geometryId: labelToRemove.metadata.geometryId,
        labelSetId: labelToRemove.metadata.labelSetId,
      }
    })

    labelsToRemove.forEach((labelToRemove) => this.removeLabelMesh(labelToRemove))

    return labelsToRemoveInfos
  }

  /**
   * Remove from the scene label origins (dots)
   * @param labelIds - List of label ID
   * @returns
   */
  removeLabelOrigins(labelIds: string[]) {
    if (!labelIds || !labelIds.length) {
      return
    }

    labelIds.forEach((labelId) => this.disposeLabelOrigins({ labelId }))
  }

  /**
   * Hides manual label handle, remove mesh from picking
   * @param labelId - manual label id
   */
  invalidateManuallyPlacedLabel(labelMesh: InstancedMesh) {
    this.hideManualLabelHandle()
    const sensitiveZoneMesh = this.scene.meshes.find(
      (m) => this.meshManager.isLabelSensitiveZone(m) && m.metadata.labelId === labelMesh.id,
    )
    this.renderScene.getGpuPicker().removePickingObjects([sensitiveZoneMesh])
  }

  applyRotationToAllOtherLabels(sourceLabel: ManualPatch, targetLabels: ManualPatch[]) {
    const sourceLabelMesh = this.scene.meshes.find(m => m.id === sourceLabel.id && this.meshManager.isLabelMesh(m))
    const updatedPatches: ManualPatch[] = []
    const transformation = this.getLabelParentTransformation(sourceLabelMesh)
    const invTransformation = transformation.clone().invert()
    for (const manualLabel of targetLabels) {
      const manualLabelMesh = this.scene.meshes.find(m => m.id === manualLabel.id && this.meshManager.isLabelMesh(m))
      const manualTransformation = this.getLabelParentTransformation(manualLabelMesh)
      const manualInvTransformation = manualTransformation.clone().invert()
      const rotationAngle = sourceLabel.rotationAngle - manualLabel.rotationAngle
      if (Math.abs(rotationAngle) < Epsilon) {
        continue
      }

      let orientation = this.modelManager.labelMgr.applyTransformationForOrientation(
        sourceLabelMesh.metadata.orientation,
        invTransformation,
        true,
      )
      orientation = this.modelManager.labelMgr.applyTransformationForOrientation(orientation, manualTransformation)
      const rotationMatrix = Matrix.RotationAxis(
        manualLabelMesh.metadata.orientation.normal,
        Angle.FromDegrees(rotationAngle).radians(),
      )
      Vector3.TransformNormalToRef(
        manualLabelMesh.metadata.orientation.yDirection,
        rotationMatrix,
        manualLabelMesh.metadata.orientation.yDirection,
      )

      orientation = this.modelManager.labelMgr.applyTransformationForOrientation(
        manualLabelMesh.metadata.orientation,
        manualInvTransformation,
      )
      this.createOriginInstance(
        manualLabel.id,
        manualLabelMesh.metadata.labelSetId,
        manualLabelMesh.metadata.buildPlanItemId,
        manualLabelMesh.metadata.componentId,
        manualLabelMesh.metadata.geometryId,
        new Vector3(
          manualLabel.orientation.origin.x,
          manualLabel.orientation.origin.y,
          manualLabel.orientation.origin.z,
        ),
        orientation,
        LABEL_ORIGIN_REGULAR_COLOR,
        this.renderScene.getSceneMetadata().buildPlanItems.get(manualLabel.buildPlanItemId),
        true,
      )
      updatedPatches.push({
        id: manualLabel.id,
        buildPlanItemId: manualLabel.buildPlanItemId,
        componentId: manualLabel.componentId,
        geometryId: manualLabel.geometryId,
        rotationAngle: sourceLabel.rotationAngle,
        orientation: this.modelManager.labelMgr.convertToOrientationVertex(orientation),
      })
    }

    if (updatedPatches.length) {
      this.labelOrientationChanged.trigger({ manualPatches: updatedPatches })
    }
  }

  applyTransformationForOrientation(orientation: IOrientation, transformation: Matrix, createNew: boolean = false) {
    const normal = createNew ? orientation.normal.clone() : orientation.normal
    const origin = createNew ? orientation.origin.clone() : orientation.origin
    const xDirection = createNew ? orientation.xDirection.clone() : orientation.xDirection
    const yDirection = createNew ? orientation.yDirection.clone() : orientation.yDirection

    return {
      normal: Vector3.TransformNormal(normal, transformation).normalize(),
      origin: Vector3.TransformCoordinates(origin, transformation),
      xDirection: Vector3.TransformNormal(xDirection, transformation).normalize(),
      yDirection: Vector3.TransformNormal(yDirection, transformation).normalize(),
    }
  }

  highlightLabels(
    options?: Array<{
      labelSetId?: string
      parentId?: string
      componentId?: string
      geometryId?: string
      patchId?: string
      isPrintOrderPreviewLabel: boolean
    }>,
  ) {
    let labelMeshes = []

    const hasPrintOrderPreviewLabels = options.some(
      (option) => option.isPrintOrderPreviewLabel && option.isPrintOrderPreviewLabel === true,
    )

    if (hasPrintOrderPreviewLabels) {
      labelMeshes = this.scene.meshes.filter((mesh) => {
        return options
          ? options.some(
            (option) =>
              mesh.metadata &&
              mesh.metadata.componentId &&
              mesh.metadata.geometryId &&
              mesh.metadata.componentId === option.componentId &&
              mesh.metadata.geometryId === option.geometryId &&
              mesh.metadata.patchId === option.patchId &&
              mesh.parent &&
              mesh.parent.id &&
              mesh.parent.id === option.parentId,
          )
          : false
      })
    } else {
      labelMeshes = this.scene.meshes.filter((mesh) => {
        return (
          this.meshManager.isLabelMesh(mesh) &&
          (options
            ? options.some((option) => {
              return Object.entries(option).every(([key, value]) => {
                if (value && mesh.metadata && mesh.metadata[key]) {
                  return mesh.metadata[key] === value
                }
                return true
              })
            })
            : true)
        )
      })
    }

    labelMeshes.forEach((labelMesh) => {
      if (labelMesh.instancedBuffers) {
        labelMesh.instancedBuffers.color = PRIMARY_CYAN

        const component = this.getLabelComponentMesh(labelMesh) as InstancedMesh
        if (
          component &&
          component.metadata &&
          component.metadata.isHidden &&
          component.metadata.transparentCloneId &&
          labelMesh.metadata &&
          labelMesh.metadata.transparentCloneId
        ) {
          const transparentClone = this.meshManager.getTransparentClone(labelMesh)
          transparentClone.instancedBuffers.color = labelMesh.instancedBuffers.color
        }

        ; (this.renderScene as RenderScene).animate(true)
      }
    })
  }

  deHighlightLabels(
    options?: Array<{
      labelSetId?: string
      parentId?: string
      componentId?: string
      geometryId?: string
      patchId?: string
      labelSetHighlight?: boolean
      isPrintOrderPreviewLabel: boolean
    }>,
  ) {
    let labelMeshes = []

    const hasPrintOrderPreviewLabels = options.some(
      (option) => option.isPrintOrderPreviewLabel && option.isPrintOrderPreviewLabel === true,
    )

    if (hasPrintOrderPreviewLabels) {
      labelMeshes = this.scene.meshes.filter((mesh) => {
        return options
          ? options.some(
            (option) =>
              mesh.metadata &&
              mesh.metadata.componentId &&
              mesh.metadata.geometryId &&
              mesh.metadata.componentId === option.componentId &&
              mesh.metadata.geometryId === option.geometryId &&
              mesh.metadata.patchId === option.patchId &&
              mesh.parent &&
              mesh.parent.id &&
              mesh.parent.id === option.parentId,
          )
          : false
      })
    } else {
      labelMeshes = this.scene.meshes.filter((mesh) => {
        return (
          this.meshManager.isLabelMesh(mesh) &&
          (options
            ? options.some((option) => {
              return Object.entries(option).every(([key, value]) => {
                if (value && mesh.metadata && mesh.metadata[key]) {
                  return mesh.metadata[key] === value
                }
                return true
              })
            })
            : true)
        )
      })
    }

    const activeLabelSet = store.getters['label/activeLabelSet']
    labelMeshes.forEach((labelMesh) => {
      if (labelMesh.instancedBuffers) {
        if (!activeLabelSet || activeLabelSet.id !== labelMesh.metadata.labelSetId) {
          labelMesh.instancedBuffers.color = labelMesh.metadata.isFailed
            ? ACTIVE_LABEL_FAILED_COLOR
            : (this.renderScene as RenderScene).getViewMode() instanceof MarkingViewMode
              ? LABEL_COLOR
              : INACTIVE_LABEL_COLOR
        } else {
          labelMesh.instancedBuffers.color = labelMesh.metadata.isFailed
            ? INACTIVE_LABEL_FAILED_COLOR
            : INACTIVE_LABEL_COLOR
        }

        if (labelMesh.metadata && labelMesh.metadata.transparentCloneId) {
          const transparentLabelClone = this.meshManager.getTransparentClone(labelMesh)
          transparentLabelClone.instancedBuffers.color = labelMesh.instancedBuffers.color
        }

        ; (this.renderScene as RenderScene).animate(true)
      }
    })
  }

  async cacheLabelMeshes() {
    const labelsToCache = this.scene.meshes.filter((mesh) => this.meshManager.isLabelMesh(mesh)) as Mesh[]
    if (this.labelsCache.length) {
      await this.clearLabelsCache()
    }

    this.labelsCache.push(
      ...(await Promise.all(
        labelsToCache.map(async (label) => {
          const cached = this.copyLabelMesh(label, label.id, LABEL_CACHED, label.parent as TransformNode)
          cached.isVisible = false
          cached.instancedBuffers.color = label.instancedBuffers.color

          const labelMetadata = cached.metadata as ILabelMetadata
          labelMetadata.itemType = SceneItemType.LabelCached
          labelMetadata.transparentCloneId = null

          const sensitiveZoneMesh = this.scene.meshes.find(
            (mesh) =>
              mesh.id === label.metadata.sensitiveZoneId && mesh.metadata.itemType === SceneItemType.LabelSensitiveZone,
          )
          let cachedSensitiveZone = null
          if (sensitiveZoneMesh) {
            cachedSensitiveZone = this.copyLabelMesh(
              sensitiveZoneMesh as any,
              sensitiveZoneMesh.id,
              LABEL_SENSITIVE_ZONE_CACHED,
              sensitiveZoneMesh.parent as TransformNode,
            )
            cachedSensitiveZone.metadata.itemType = SceneItemType.LabelSensitiveZoneCached
            cachedSensitiveZone.isVisible = false
            cachedSensitiveZone.position = sensitiveZoneMesh.position.clone()

            // without this cached labels after restore will not be pickable
            cachedSensitiveZone.instancedBuffers.pColor = sensitiveZoneMesh.instancedBuffers.pColor
            cachedSensitiveZone.instancedBuffers.bColor = sensitiveZoneMesh.instancedBuffers.bColor
            cachedSensitiveZone.instancedBuffers.fColor = sensitiveZoneMesh.instancedBuffers.fColor
          }

          return { label: cached, sensitiveZone: cachedSensitiveZone }
        }),
      )),
    )
  }

  async clearLabelsCache(force = false) {
    if (!force && !isNil(this.waitCacheRestored)) {
      await this.waitCacheRestored()
    }
    this.labelsCache.forEach((cache) => this.removeLabelMesh(cache.label))
    this.labelsCache = []
  }

  restoreLabelsFromCache(labelSetIds?: string[]) {
    if (!this.labelsCache.length) return

    this.initCacheRestoreNotifier()
    this.removeLabels()
    const labelsCache =
      labelSetIds && labelSetIds.length
        ? this.labelsCache.filter((lc) => labelSetIds.includes(lc.label.metadata.labelSetId))
        : this.labelsCache
    labelsCache.forEach((cache) => {
      const { label, sensitiveZone } = cache
      const labelMetadata = label.metadata as ILabelMetadata
      if (!label || !labelMetadata) {
        return
      }

      label.name = LABEL_MESH
      labelMetadata.itemType = SceneItemType.Label

      // create inside Mesh
      this.createLabelMeshInside(label, label.instancedBuffers.color)

      // create transparent clone
      if (!labelMetadata.transparentCloneId) {
        this.meshManager.createTransparentClone(label, false)
      }

      const componentMesh = this.getLabelComponentMesh(label)
      label.isVisible = !componentMesh.metadata.isHidden
      if (componentMesh.metadata.isHidden) {
        label.metadata.isHidden = true
        // Show transparent clone if ShowHiddenPartsAsTransparentMode enabled.
        if (this.meshManager.isShowHiddenPartsAsTransparentMode) {
          this.meshManager.showTransparentClone(label)
        }
      }

      if (sensitiveZone) {
        sensitiveZone.name = LABEL_SENSITIVE_ZONE
        sensitiveZone.metadata.itemType = SceneItemType.LabelSensitiveZone
        sensitiveZone.isVisible = false
        this.renderScene.getGpuPicker().addPickingObjects([sensitiveZone])
      }
    })

    this.labelsCache = []
    this.notifyCacheRestored()
  }

  restoreLabelSetsFromCache(labelSetIds: string[]) {
    this.disposeLabelOrigins()

    this.removeLabels(labelSetIds.map((labelSetId) => ({ labelSetId })))
    const labelsToRestore = this.labelsCache.filter((cached) => {
      const labelMetadata = cached.label.metadata as ILabelMeshMetadata
      return labelSetIds.includes(labelMetadata.labelSetId)
    })

    labelsToRestore.forEach(async (labelToRestore) => {
      const { buildPlanItemId, componentId, geometryId, labelSetId, isFailed } = labelToRestore.label
        .metadata as ILabelMeshMetadata
      const restoredLabel = this.copyLabelMesh(
        labelToRestore.label as unknown as Mesh,
        labelToRestore.label.id,
        LABEL,
        labelToRestore.label.parent as TransformNode,
      )
      const insideMesh = labelToRestore.label.getChildMeshes().find((c) => c.name === LABEL_INSIDE_MESH_NAME) as Mesh
      if (insideMesh) {
        const restoredInside = this.copyLabelMesh(insideMesh, insideMesh.id, LABEL_INSIDE_MESH_NAME, restoredLabel)
        restoredInside.instancedBuffers.color = isFailed ? ACTIVE_LABEL_FAILED_COLOR : LABEL_COLOR
      }

      const restoredClone = await this.meshManager.createTransparentClone(restoredLabel as InstancedMesh, false)
      if (restoredLabel.metadata.isHidden) {
        restoredClone.instancedBuffers.color = isFailed ? ACTIVE_LABEL_FAILED_COLOR : LABEL_COLOR
      }

      if (labelToRestore.sensitiveZone) {
        const restoredSensitiveZone = this.copyLabelMesh(
          labelToRestore.sensitiveZone as any,
          labelToRestore.sensitiveZone.id,
          LABEL_SENSITIVE_ZONE,
          labelToRestore.sensitiveZone.parent as TransformNode,
        )

        restoredSensitiveZone.isVisible = false
        restoredLabel.instancedBuffers.color = isFailed ? ACTIVE_LABEL_FAILED_COLOR : LABEL_COLOR
        restoredLabel.metadata = {
          buildPlanItemId,
          componentId,
          geometryId,
          labelSetId,
          isFailed,
          itemType: SceneItemType.Label,
        } as ILabelMeshMetadata
        this.renderScene.getGpuPicker().addPickingObjects([restoredSensitiveZone])
      }
    })
  }

  activateLabelManualPlacement() {
    if (!this.originTemplateMesh) {
      this.createOriginTemplateMesh()
    }

    let pointerXStart = null
    let pointerYStart = null
    const canvas = this.scene.getEngine().getRenderingCanvas()
    const pointerXMoveThreshold = canvas.clientWidth * THRESHOLD_RATIO
    const pointerYMoveThreshold = canvas.clientHeight * THRESHOLD_RATIO
    let isWheelDown = false
    let isMovedByWheel = false

    this.scene.onPointerDown = (evt: PointerEvent) => {
      if (evt.button === MouseButtons.LeftButton) {
        pointerXStart = evt.clientX
        pointerYStart = evt.clientY
      }

      if (evt.button === MouseButtons.WheelButton) {
        isWheelDown = true
      }
    }

    this.scene.onPointerMove = (evt: PointerEvent) => {
      if (isWheelDown) {
        isMovedByWheel = true
        setTimeout(() => {
          this.renderScene.getGpuPicker().disableLabels()
            ; (this.renderScene as RenderScene).animate()
        }, 0)
      }
    }

    this.scene.onPointerUp = (evt: PointerEvent) => {
      if (isMovedByWheel) {
        isWheelDown = isMovedByWheel = false
        setTimeout(() => {
          this.renderScene.getGpuPicker().enableLabels()
            ; (this.renderScene as RenderScene).animate()
        }, 0)
      }

      if (
        evt.button !== MouseButtons.LeftButton ||
        Math.abs(pointerXStart - evt.clientX) > pointerXMoveThreshold ||
        Math.abs(pointerYStart - evt.clientY) > pointerYMoveThreshold
      ) {
        return
      }

      const pickingRay = this.scene.createPickingRay(
        this.scene.pointerX,
        this.scene.pointerY,
        IDENTITY_MATRIX,
        this.camera,
      )
      const gpuPickInfo = this.renderScene.getGpuPicker().pick(this.scene.pointerX, this.scene.pointerY)
      const pickPredicate = (m: AbstractMesh) =>
        this.meshManager.isComponentMesh(m) &&
        gpuPickInfo &&
        this.meshManager.getBuildPlanItemMeshByChild(m).id === gpuPickInfo.part.id
      const pickInfo = this.scene.pickWithRay(pickingRay, pickPredicate)
      if (!pickInfo.hit) {
        return
      }
      const id = uuid()
      const normal = pickInfo.getNormal(true)
      const origin = pickInfo.pickedPoint
      const textDirection = this.getTextDirection(pickInfo, false)
      const directions = this.calculateDirections(normal, textDirection)
      const activeLabelSet: InteractiveLabelSet = store.getters['label/activeLabelSet']
      const originMesh = this.createOriginInstance(
        id,
        activeLabelSet.id,
        this.meshManager.getBuildPlanItemMeshByChild(pickInfo.pickedMesh).metadata.buildPlanItemId,
        pickInfo.pickedMesh.metadata.componentId,
        pickInfo.pickedMesh.metadata.geometryId,
        pickInfo.pickedPoint.clone(),
        {
          normal,
          origin: pickInfo.pickedPoint,
          xDirection: directions.xDirection,
          yDirection: directions.yDirection,
        },
        LABEL_ORIGIN_REGULAR_COLOR,
        gpuPickInfo.part,
        false,
      )
      this.setupForGpuPicker(originMesh, SceneItemType.LabelOrigin)

      const root = this.meshManager.getBuildPlanItemMeshByChild(pickInfo.pickedMesh)
      const transformation = this.getLabelParentTransformation(pickInfo.pickedMesh)
      const invTransformation = transformation.clone().invert()
      this.onLabelOrientationSelected.trigger({
        id,
        buildPlanItemId: root.metadata.buildPlanItemId,
        componentId: pickInfo.pickedMesh.metadata.componentId,
        geometryId: pickInfo.pickedMesh.metadata.geometryId,
        rotationAngle: 0,
        orientation: {
          normal: this.convertToVertex(Vector3.TransformNormal(normal, invTransformation).normalize()),
          origin: this.convertToVertex(Vector3.TransformCoordinates(origin, invTransformation)),
          xDirection: this.convertToVertex(
            Vector3.TransformNormal(directions.xDirection, invTransformation).normalize(),
          ),
          yDirection: this.convertToVertex(
            Vector3.TransformNormal(directions.yDirection, invTransformation).normalize(),
          ),
        },
      })
    }

    this.renderScene
      .getScene()
      .getEngine()
      .getRenderingCanvas()
      .addEventListener('wheel', () => {
        const isDisplayManualLabelSettings = store.getters['visualizationModule/isDisplayManualLabelSettings']
        if (this.groupParent && isDisplayManualLabelSettings) {
          const sensitiveZoneMesh = this.scene.meshes.find(
            (mesh) =>
              mesh.id === this.groupParent.metadata.sensitiveZoneId &&
              mesh.metadata.itemType === SceneItemType.LabelSensitiveZone,
          )
          const originMesh = this.scene.meshes.find(
            (mesh) =>
              mesh.id === this.groupParent.metadata.labelId && mesh.metadata.itemType === SceneItemType.LabelOrigin,
          )
          setTimeout(
            () =>
              eventBus.$emit(BuildPlanEvents.DisplayManualLabelSettings, {
                isVisible: isDisplayManualLabelSettings,
                settingsLocation: this.calculateManualLabelSettingsLocation(
                  sensitiveZoneMesh ? sensitiveZoneMesh : originMesh,
                ),
                disableLabelAllInstances: originMesh ? originMesh.metadata.patchNotCreated : false,
                disableApplyRotationToInstances: originMesh ? originMesh.metadata.patchNotCreated : false,
              }),
            50,
          )
        }
      })

    this.scene.getEngine().getRenderingCanvas().addEventListener('pointerenter', this.storePointerPosition)
    this.scene.getEngine().getRenderingCanvas().addEventListener('pointermove', this.storePointerPosition)
  }

  deactivateLabelManualPlacement(labelSetId?: string, removeOrigin: boolean = true) {
    this.scene.onPointerDown = null
    this.scene.onPointerMove = null
    this.scene.onPointerUp = null
    this.scene.getEngine().getRenderingCanvas().removeEventListener('pointerenter', this.storePointerPosition)
    this.scene.getEngine().getRenderingCanvas().removeEventListener('pointermove', this.storePointerPosition)

    if (removeOrigin) {
      this.disposeLabelOrigins({ labelSetId })
    }
  }

  showManuallyPlacedLabelOrigins(patches: Placement[], labelSetId?: string) {
    if (!patches || !patches.length) {
      return
    }

    if (!this.originTemplateMesh) {
      this.createOriginTemplateMesh()
    }

    const activeLabelSet: InteractiveLabelSet =
      labelSetId ? store.getters['label/getLabelSetById'](labelSetId) : store.getters['label/activeLabelSet']

    const labelMeshesIds = this.scene.meshes.filter((m) => this.meshManager.isLabelMesh(m)).map((l) => l.id)
    for (const patch of patches) {
      const existingOrigin = this.scene.meshes.find((m) => this.meshManager.isLabelOrigin(m) && patch.id === m.id)
      if (labelMeshesIds.includes(patch.id) || existingOrigin) {
        continue
      }

      const trackableLabel = activeLabelSet.labels.find(
        (l) => (l as ManualTrackableLabel).manualPlacementId === patch.id,
      )
      const labelOrientation = this.convertToOrientationVector3(patch.orientation)
      const body = this.meshManager.getComponentMesh(patch.componentId, patch.geometryId, patch.buildPlanItemId)
      const transformation = this.getLabelParentTransformation(body)
      const originMesh = this.createOriginInstance(
        patch.id,
        activeLabelSet.id,
        patch.buildPlanItemId,
        patch.componentId,
        patch.geometryId,
        new Vector3(patch.orientation.origin.x, patch.orientation.origin.y, patch.orientation.origin.z),
        {
          normal: Vector3.TransformNormal(labelOrientation.normal, transformation),
          origin: Vector3.TransformCoordinates(labelOrientation.origin, transformation),
          xDirection: Vector3.TransformNormal(labelOrientation.xDirection, transformation),
          yDirection: Vector3.TransformNormal(labelOrientation.yDirection, transformation),
        },
        trackableLabel && trackableLabel.errorCode ? LABEL_ORIGIN_ERROR_COLOR : LABEL_ORIGIN_REGULAR_COLOR,
        this.renderScene.getSceneMetadata().buildPlanItems.get(patch.buildPlanItemId),
        true,
      )
      this.setupForGpuPicker(originMesh, SceneItemType.LabelOrigin)

      if (trackableLabel && trackableLabel.errorCode) {
        originMesh.metadata.patchNotCreated = true
        originMesh.metadata.trackId = trackableLabel.id
        this.renderScene.getGpuPicker().addPickingObjects([originMesh])
      }
    }
  }

  /**
   * Updates visibility of labels and other meshes that linked to labels.
   */
  setLabeledBodiesVisibility(activeLabelSetId: string, visibility: boolean) {
    if (activeLabelSetId) {
      this.setNotActiveLabledBodiesVisibility(visibility, activeLabelSetId)
    } else {
      this.setAllLabledBodiesVisibility(visibility)
    }

    ; (this.renderScene as RenderScene).animate()
  }

  changeActiveLabelSet(labelSet: InteractiveLabelSet) {
    if (labelSet) {
      this.scene.meshes
        .filter((m) => this.meshManager.isLabelMesh(m) || this.meshManager.isLabelCloneMesh(m))
        .map((label) => {
          const regularColor = labelSet.id === label.metadata.labelSetId ? INACTIVE_LABEL_COLOR : LABEL_COLOR
          const failedColor =
            labelSet.id === label.metadata.labelSetId ? INACTIVE_LABEL_FAILED_COLOR : ACTIVE_LABEL_FAILED_COLOR
          label.instancedBuffers.color = label.metadata.isFailed ? failedColor : regularColor
          const insideMesh = label.getChildMeshes().find((c) => c.name.includes(LABEL_INSIDE_MESH_NAME))
          insideMesh.instancedBuffers.color = label.metadata.isFailed ? failedColor : regularColor
        })
    } else {
      this.scene.meshes
        .filter((m) => this.meshManager.isLabelMesh(m) || this.meshManager.isLabelCloneMesh(m))
        .map((label) => {
          const color =
            (this.renderScene as RenderScene).getViewMode() instanceof MarkingViewMode
              ? label.metadata.isFailed
                ? ACTIVE_LABEL_FAILED_COLOR
                : LABEL_COLOR
              : label.metadata.isFailed
                ? INACTIVE_LABEL_FAILED_COLOR
                : INACTIVE_LABEL_COLOR
          label.instancedBuffers.color = color
          const insideMesh = label.getChildMeshes().find((c) => c.name.includes(LABEL_INSIDE_MESH_NAME))
          if (insideMesh) {
            insideMesh.instancedBuffers.color = color
          }
        })
    }
  }

  convertToOrientationVertex(orientation: IOrientation) {
    return {
      normal: this.convertToVertex(orientation.normal),
      origin: this.convertToVertex(orientation.origin),
      xDirection: this.convertToVertex(orientation.xDirection),
      yDirection: this.convertToVertex(orientation.yDirection),
    }
  }

  roundOrientation(orientation: IOrientation, fractionDigits: number) {
    return {
      normal: this.roundVectorToFractionDigits(orientation.normal, fractionDigits),
      origin: this.roundVectorToFractionDigits(orientation.origin, fractionDigits),
      xDirection: this.roundVectorToFractionDigits(orientation.xDirection, fractionDigits),
      yDirection: this.roundVectorToFractionDigits(orientation.yDirection, fractionDigits),
    }
  }

  roundVectorToFractionDigits(vector: Vector3, fractionDigits: number) {
    return new Vector3(
      Number.parseFloat(vector.x.toFixed(fractionDigits)),
      Number.parseFloat(vector.y.toFixed(fractionDigits)),
      Number.parseFloat(vector.z.toFixed(fractionDigits)),
    )
  }

  getLabelParentTransformation(picked: TransformNode) {
    let transformation = IDENTITY_MATRIX
    const mesh = this.meshManager.getBuildPlanItemMeshByChild(picked)
    if (mesh && mesh.metadata.initialTransformation) {
      const initialTransformation = mesh.metadata.initialTransformation
      const relativeTransformation = this.meshManager.getRelativeTransformation(
        mesh.getWorldMatrix(),
        initialTransformation,
      )

      transformation = this.meshManager.convertTranslationToMillimeters(
        relativeTransformation,
        mesh.metadata.unitFactor,
      )
    }

    return transformation
  }

  /**
   * Find trackable label by patch information
   * @param labelSet - label set to process
   * @param patch - patch that is stored in DB
   * @returns TrackableLabel
   */
  getTrackableLabelByPatch(labelSet: InteractiveLabelSet, patch: Patch): TrackableLabel {
    let labeledBody = labelSet.selectedBodies.find(
      (body) =>
        body.buildPlanItemId === patch.buildPlanItemId &&
        body.componentId === patch.componentId &&
        body.geometryId === patch.geometryId,
    )
    if (!labeledBody) {
      labeledBody = labelSet.relatedBodies.find(
        (body) =>
          body.buildPlanItemId === patch.buildPlanItemId &&
          body.componentId === patch.componentId &&
          body.geometryId === patch.geometryId,
      )
    }

    return labelSet.labels.find((label) => {
      if (isManualLabel(label)) {
        return label.manualPlacementId === patch.id
      }

      if ((label as AutomatedTrackableLabel).autoLocation !== patch.autoLocation) {
        return false
      }

      return (label as AutomatedTrackableLabel).bodyId === labeledBody.id
    })
  }

  /**
   * Find label mesh by trackable label id
   * @param trackId - trackable label id
   * @returns
   */
  getLabelMeshByTrackId(trackId: string): AbstractMesh {
    return this.scene.meshes.find((m) => this.meshManager.isLabelMesh(m) && m.metadata.trackId === trackId)
  }

  /**
   * Change appearance of dirty labels on the scene
   * @param labelSetId - label set id
   * @param buildPlanItemId - build plan item id
   * @param componentId - component id
   * @param geometryId - geometry id
   * @param labelId - label id (optional - only for manually placed labels)
   */
  invalidateLabels(
    payload: Array<{
      labelSetId: string
      id: string
      dirtyState: LabelDirtyState
    }>,
  ) {
    payload.forEach((dirtyLabel) => {
      const labelMesh = this.getLabelMeshByTrackId(dirtyLabel.id)
      if (labelMesh) {
        const trackableLabel = store.getters['label/getTrackableLabel'](dirtyLabel.id, dirtyLabel.labelSetId)
        if (isManualLabel(trackableLabel)) {
          this.invalidateManuallyPlacedLabel(labelMesh as InstancedMesh)
        }

        // Temporary logic
        // In real implementation don't forget about disable sensitive zone and
        // transparent clone appearance if label is shown as semitransparent
        this.removeLabelMesh(labelMesh)

        // showManuallyPlacedLabelOrigins must be called after label mesh is removed
        if (isManualLabel(trackableLabel) && trackableLabel.dirtyState !== LabelDirtyState.Remove) {
          const activeLabelSet: InteractiveLabelSet = store.getters['label/activeLabelSet']
          if (activeLabelSet) {
            const placement = activeLabelSet.manualPlacements.find((mp) => mp.id === trackableLabel.manualPlacementId)
            if (placement) {
              this.showManuallyPlacedLabelOrigins([placement])
            }
          }
        }
      }
    })
  }

  /**
   * If label mesh is not received from interactive service
   * Redraw label origin in appropriate color and allow move or remove manual placement
   * @param labelId - label id (corresponds with manual placement id)
   */
  markNotCreatedManualLabel(labelId: string, trackId: string) {
    const labelOrigin = this.scene.meshes.find((m) => this.meshManager.isLabelOrigin(m) && m.id === labelId)
    if (!labelOrigin || labelOrigin.isDisposed()) {
      return
    }

    labelOrigin.instancedBuffers.color = LABEL_ORIGIN_ERROR_COLOR
    labelOrigin.metadata.patchNotCreated = true
    labelOrigin.metadata.trackId = trackId
    this.renderScene.getGpuPicker().addPickingObjects([labelOrigin])
      ; (this.renderScene as RenderScene).animate()
  }

  /**
   * Dispose label origin mesh and if it was origin of not created label remove from GPU picker
   * @param labelId - ID of label
   */
  disposeLabelOrigins(payload?: { labelId?: string, labelSetId?: string }) {
    this.scene.meshes
      .filter((m) => this.meshManager.isLabelOrigin(m) && (!payload || (!payload.labelId || m.id === payload.labelId) && (!payload.labelSetId || m.metadata.labelSetId === payload.labelSetId)))
      .map((origin) => {
        if (origin.metadata.patchNotCreated) {
          this.renderScene.getGpuPicker().removePickingObjects([origin])
        }

        // Deselect body if the last label is removed from this body
        const activeLabelSet: InteractiveLabelSet = store.getters['label/activeLabelSet']
        if (activeLabelSet) {
          const label = activeLabelSet.labels.find((l) => (l as ManualTrackableLabel).manualPlacementId === origin.id)
          if (label) {
            this.checkExistingLabelsOnBody(
              origin as InstancedMesh,
              label.id,
              origin.metadata.buildPlanItemId,
              origin.metadata.bodyComponentId,
              origin.metadata.bodyGeometryId,
            )
          }
        }
        origin.dispose()
      })
  }

  dispose() {
    this.labelGizmo.dispose()
  }

  private getLabelConfig(fontSize: number, fontFamily: string, text: string) {
    return this.labelConfigs.find(
      (c) => c.fontSize === fontSize && c.fontFamily === fontFamily && c.textContent === text,
    )
  }

  private createMaterial(text: string, fontFamily: string, fontSize: number, color: Color3, hasAlpha: boolean = false) {
    const fontSizePx = convertMillimeterToPixel(fontSize)
    const fontStyle = `${fontSize}mm ${fontFamily}`
    const temp = new DynamicTexture('TempLabelTexture', 64, this.scene, false)
    const tmpctx = temp.getContext()
    tmpctx.font = fontStyle
    const tmpWidth = tmpctx.measureText(text).width
    temp.dispose()

    // the number to multiply by defines the sharpness of the text, low numbers produce blurred text
    const textureWidth = tmpWidth * TEXTURE_TEXT_SHARPNESS
    const textureHeight = fontSizePx * TEXTURE_TEXT_SHARPNESS
    const ratio = tmpWidth / fontSizePx
    const textureFontSize = Math.round(textureWidth / ratio)
    const textureFontStyle = `${textureFontSize}px ${fontFamily}`

    const dynamicTexture = new DynamicTexture(
      'LabelTexture',
      { width: textureWidth, height: textureHeight },
      this.scene,
      false,
    )
    // Babylon 5.0 breaking change
    // Property ‘textBaseline’ does not exist on type ‘ICanvasRenderingContext’
    const context = dynamicTexture.getContext() as any
    context.font = textureFontStyle
    context.textBaseline = 'hanging'
    // Babylon 5.0 breaking change
    // Properties ‘actualBoundingBoxAscent’ and ‘actualBoundingBoxDescent’ do not exist on type ‘ITextMetrics’
    const textMetrics = dynamicTexture.getContext().measureText(text) as any
    const textHeight = Math.abs(textMetrics.actualBoundingBoxAscent) + Math.abs(textMetrics.actualBoundingBoxDescent)
    const delta = (textureFontSize - textHeight) / 2
    dynamicTexture.drawText(text, null, delta, textureFontStyle, color.toHexString(), 'transparent', false)

    // create material
    const material = new StandardMaterial(LABEL_MATERIAL_NAME, this.scene)
    material.diffuseTexture = dynamicTexture
    material.emissiveTexture = dynamicTexture
    material.diffuseTexture.hasAlpha = true
    material.specularColor = Color3.Black()
    material.backFaceCulling = false
    material.zOffset = -2

    if (hasAlpha) {
      material.useAlphaFromDiffuseTexture = true
      material.alpha = 0.3
    }

    return { material, width: convertPixelToMillimeter(tmpWidth), heigth: fontSize }
  }

  private createRawLabel(x: number, y: number) {
    const pickingRay = this.scene.createPickingRay(x, y, IDENTITY_MATRIX, this.camera)
    const pickInfo = this.scene.pickWithRay(pickingRay, this.pickPredicate)
    const normal = pickInfo.getNormal(true)
    if (!pickInfo.hit || !normal) {
      return
    }

    const textDirection = this.getTextDirection(pickInfo)
    const material = this.getLabelConfig(
      this.labelStyle.fontSize,
      this.labelStyle.fontFamily,
      this.labelStyle.text,
    ).previewMaterial

    const tempLabelMesh: any = this.generateLabelMesh(null, this.labelStyle, pickInfo, textDirection, false)
    tempLabelMesh.isVisible = true
    tempLabelMesh.material = material
    tempLabelMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
    tempLabelMesh.showBoundingBox = true

    // need for fix z-fighting when camera is directed along -z axis
    tempLabelMesh.position = tempLabelMesh.position.add(normal.scale(Epsilon))

    const validationResult = this.validateLabelMesh(tempLabelMesh)
    tempLabelMesh.metadata = {
      itemType: SceneItemType.RawLabel,
      isValid: validationResult.isValid,
    }
  }

  private getTextDirection(pickInfo: PickingInfo, isDependOnCameraPosition = true) {
    const camera = this.renderScene.getActiveCamera()
    const normal = pickInfo.getNormal(true)
    const faceCamera = new OrthoCamera(
      'faceCamera',
      new Viewport(0, 0, 1, 1),
      this.scene,
      this.scene.getEngine().getRenderingCanvas(),
      this.renderScene,
    )
    faceCamera.position = normal.scale(camera.radius)
    const plane = Plane.FromPositionAndNormal(pickInfo.pickedPoint, normal)

    // fc - face camera
    // sc - scene camera
    const fcRay1 = this.scene.createPickingRay(500, 500, IDENTITY_MATRIX, faceCamera)
    const fcRay2 = this.scene.createPickingRay(600, 500, IDENTITY_MATRIX, faceCamera)
    const fcStart = this.meshManager.intersectRayPlane(fcRay1, plane)
    const fcEnd = this.meshManager.intersectRayPlane(fcRay2, plane)
    const fcTextDirection = new Vector3(fcEnd.x - fcStart.x, fcEnd.y - fcStart.y, fcEnd.z - fcStart.z).normalize()
    if (!isDependOnCameraPosition) {
      return fcTextDirection
    }

    const scRay1 = this.scene.createPickingRay(500, 500, IDENTITY_MATRIX, camera)
    const scRay2 = this.scene.createPickingRay(600, 500, IDENTITY_MATRIX, camera)
    const scStart = this.meshManager.intersectRayPlane(scRay1, plane)
    const scEnd = this.meshManager.intersectRayPlane(scRay2, plane)
    const scTextDirection = new Vector3(scEnd.x - scStart.x, scEnd.y - scStart.y, scEnd.z - scStart.z).normalize()

    const cross = Vector3.Cross(fcTextDirection, scTextDirection)
    const angle = Vector3.GetAngleBetweenVectors(fcTextDirection, scTextDirection, cross)
    let rotationAngle: number
    if (angle < Math.PI / 4) {
      rotationAngle = 0
    } else if (angle > (3 * Math.PI) / 4) {
      rotationAngle = Math.PI
    } else {
      rotationAngle = Math.PI / 2
      if (Math.sign(cross.z) !== Math.sign(normal.z)) {
        rotationAngle += Math.PI
      }
    }

    const textDirection = Vector3.TransformNormal(fcTextDirection, Matrix.RotationAxis(normal, rotationAngle))
    faceCamera.dispose()

    return textDirection
  }

  private getTextDirectionAfterDrag(pickInfo: PickingInfo, orientation: IOrientation) {
    const camera = this.renderScene.getActiveCamera()
    const normal = pickInfo.getNormal(true)
    const textDirection = Vector3.Cross(orientation.yDirection, orientation.normal)
    const faceCamera = new OrthoCamera(
      'faceCamera',
      new Viewport(0, 0, 1, 1),
      this.scene,
      this.scene.getEngine().getRenderingCanvas(),
      this.renderScene,
    )
    faceCamera.position = orientation.origin.add(orientation.normal.scale(camera.radius))
    faceCamera.target = orientation.origin

    const screenStartPoint = this.meshManager.project3DPointOntoScreen(textDirection.scale(-20), faceCamera)
    const screenEndPoint = this.meshManager.project3DPointOntoScreen(textDirection.scale(20), faceCamera)

    faceCamera.position = pickInfo.pickedPoint.add(normal.scale(camera.radius))
    faceCamera.target = pickInfo.pickedPoint
    const plane = Plane.FromPositionAndNormal(pickInfo.pickedPoint, normal)
    const fcRay1 = this.scene.createPickingRay(screenStartPoint.x, screenStartPoint.y, IDENTITY_MATRIX, faceCamera)
    const fcRay2 = this.scene.createPickingRay(screenEndPoint.x, screenEndPoint.y, IDENTITY_MATRIX, faceCamera)
    const fcStart = this.meshManager.intersectRayPlane(fcRay1, plane)
    const fcEnd = this.meshManager.intersectRayPlane(fcRay2, plane)
    const fcTextDirection = new Vector3(fcEnd.x - fcStart.x, fcEnd.y - fcStart.y, fcEnd.z - fcStart.z).normalize()
    faceCamera.dispose()

    return fcTextDirection
  }

  private getOriginPickInfoAfterDrag(origin: Vector3, dragStartPoint: Vector2, pickPredicate = this.pickPredicate) {
    const screenOrigin = this.meshManager.project3DPointOntoScreen(origin)
    const dragEndPoint = new Vector2(this.scene.pointerX, this.scene.pointerY)
    const direction = new Vector2(dragEndPoint.x - dragStartPoint.x, dragEndPoint.y - dragStartPoint.y).normalize()
    const distance = Vector2.Distance(dragEndPoint, dragStartPoint)
    const newScreenOrigin = new Vector2(screenOrigin.x, screenOrigin.y).add(direction.scale(distance))
    const pickingRay = this.scene.createPickingRay(
      newScreenOrigin.x,
      newScreenOrigin.y,
      IDENTITY_MATRIX,
      this.renderScene.getActiveCamera(),
    )
    const pickInfo = this.scene.pickWithRay(pickingRay, pickPredicate)

    return pickInfo
  }

  private generateLabelMesh(
    id: string,
    style: ILabelStyle,
    pickInfo: PickingInfo,
    textDirection: Vector3,
    makeGreenSize: boolean,
  ) {
    const labelConfig = this.getLabelConfig(style.fontSize, style.fontFamily, style.text)
    const bpItem = this.meshManager.getBuildPlanItemMeshByChild(pickInfo.pickedMesh)
    const normal = pickInfo.getNormal(true)
    const angle = this.calculateDecalRollAngle(normal, textDirection)

    let pickedPoint = pickInfo.pickedPoint
    if (makeGreenSize) {
      const parameterSetScaleMatrix = bpItem.parent.getWorldMatrix()
      bpItem.parent = null
      bpItem.computeWorldMatrix(true)
      pickInfo.pickedMesh.computeWorldMatrix(true)
      pickedPoint = Vector3.TransformCoordinates(pickInfo.pickedPoint, parameterSetScaleMatrix.clone().invert())
    }
    const labelSize = new Vector3(labelConfig.contentWidth, labelConfig.contentHeight, labelConfig.contentWidth)
    const labelMesh = MeshBuilder.CreateDecal(LABEL, pickInfo.pickedMesh, {
      normal,
      angle,
      position: pickedPoint,
      size: labelSize,
    })
    labelMesh.isVisible = false
    labelMesh.id = id

    if (makeGreenSize) {
      labelMesh.setParent(bpItem)
      bpItem.parent = (bpItem.metadata as IPartMetadata).parameterSetScaleNode
      bpItem.computeWorldMatrix(true)
      pickInfo.pickedMesh.computeWorldMatrix(true)
    }

    this.meshManager.minimizeVertices(labelMesh)
    const originFacetId = this.scene.pickWithRay(
      pickInfo.ray,
      (mesh) => mesh.isPickable && mesh.id === labelMesh.id,
    ).faceId
    this.meshManager.removeNonAdjacentAndSmallFacets(labelMesh, originFacetId)

    return labelMesh
  }

  // Need create each time when generating new label mesh
  // Duplicate tool using label instances instead of clones to improve rendering performance
  // So all label meshes on the scene is InstancedMesh
  private createLabelInstance(labelMesh: Mesh) {
    labelMesh.registerInstancedBuffer(VertexBuffer.ColorKind, 3)
    labelMesh.instancedBuffers.color = REGULAR_ORANGE
    const instance = labelMesh.createInstance(LABEL)
    instance.id = labelMesh.id
    instance.isPickable = false
    instance.parent = labelMesh.parent
    instance.metadata = _.cloneDeep(labelMesh.metadata)
    instance.instancedBuffers.color = REGULAR_ORANGE
    const existingLabel = this.scene.getMeshById(labelMesh.id)
    if (existingLabel) {
      labelMesh.id = `${labelMesh.id}_source`
      labelMesh.name = `${labelMesh.name}_source`
      labelMesh.isVisible = false
      this.translateLabelOrientation(labelMesh, false)
      labelMesh.parent = null
      this.scene.removeMesh(labelMesh)
    }
  }

  private removeLabelMesh(labelMesh: AbstractMesh) {
    if (!labelMesh || labelMesh.isDisposed()) {
      return
    }

    let source
    if (labelMesh instanceof InstancedMesh) {
      source = labelMesh.sourceMesh
    }

    const bpItem = labelMesh.metadata.parentId
      ? this.scene.getTransformNodeById(labelMesh.metadata.parentId)
      : this.meshManager.getBuildPlanItemMeshById(labelMesh.metadata.buildPlanItemId)
    const componentMesh = this.getComponentByItsLabel(labelMesh, bpItem.metadata.buildPlanItemId)

    // Deselect body if the last label is removed from this body
    this.checkExistingLabelsOnBody(
      labelMesh as InstancedMesh,
      labelMesh.metadata.trackId,
      labelMesh.metadata.buildPlanItemId,
      labelMesh.metadata.componentId,
      labelMesh.metadata.geometryId,
    )

    if (labelMesh.metadata && labelMesh.metadata.sensitiveZoneId) {
      const sensitiveZoneMeshType =
        labelMesh.metadata.itemType === SceneItemType.LabelCached
          ? SceneItemType.LabelSensitiveZoneCached
          : SceneItemType.LabelSensitiveZone
      const sensitiveZoneMesh = this.scene.meshes.find(
        (mesh) => mesh.id === labelMesh.metadata.sensitiveZoneId && mesh.metadata.itemType === sensitiveZoneMeshType,
      )
      if (sensitiveZoneMesh && sensitiveZoneMesh instanceof InstancedMesh) {
        const sourceMesh = sensitiveZoneMesh.sourceMesh
        this.renderScene.getGpuPicker().removePickingObjects([sensitiveZoneMesh])
        sensitiveZoneMesh.dispose()

        if (sourceMesh && (!sourceMesh.instances || !sourceMesh.instances.length)) {
          sourceMesh.dispose()
        }
      }
    }

    labelMesh.dispose()
    if (source && (!source.instances || !source.instances.length)) {
      source.dispose()
    }

    // Hide body if 'Display Labeled Bodies' is active and there are no other labels on this body
    const displaySettings: IDisplayToolbarState = store.getters['buildPlans/displayToolbarStateByVariantId'](
      store.getters['buildPlans/getBuildPlan'].id,
    )
    const activeLabelSet = store.getters['label/activeLabelSet']
    const activeLabelSetId = activeLabelSet ? activeLabelSet.id : null
    const allLabelsForLabelSet = bpItem
      .getChildMeshes(false, this.meshManager.isLabelMesh)
      .filter((l) => !l.isDisposed() && l.metadata.labelSetId === activeLabelSetId)
      ; (this.renderScene as RenderScene).setMeshVisibilityRec(
        componentMesh,
        this.canShowComponent(componentMesh) &&
        (displaySettings.isShowingAllLabledBodies ||
          (!displaySettings.isShowingAllLabledBodies && allLabelsForLabelSet.length > 0)),
        false,
        true,
      )
  }

  /**
   * Check labels on body except mesh passed in parameter
   * @param labelMesh - operated label
   * @param trackId - trackable label ID
   * @param buildPlanItemId - build plan item ID
   * @param componentId - component ID
   * @param geometryId - geometry ID
   */
  private checkExistingLabelsOnBody(
    labelMesh: InstancedMesh,
    trackId: string,
    buildPlanItemId: string,
    componentId: string,
    geometryId: string,
  ) {
    const metadata = labelMesh.metadata
    const trackableLabel = store.getters['label/getTrackableLabel'](trackId, metadata.labelSetId)
    if (trackableLabel && isManualLabel(trackableLabel)) {
      const labelSets = store.getters['label/labelSets']
      const labelSet = labelSets.find((ls) => ls.labels.some((l) => l.id === trackableLabel.id))
      let labelsToAddOrUpdate = []
      // Active label set can not exist in case if all label sets are collapsed while tool is opened.
      // In this case we are not able to search through manual placements of a active label set to get it's placements
      // from there
      if (labelSet) {
        if (!labelSet.labels || !labelSet.labels.length) {
          return
        }

        const placementsOnBodyIds = labelSet.manualPlacements
          .filter(
            (mp) =>
              mp.buildPlanItemId === buildPlanItemId && mp.componentId === componentId && mp.geometryId === geometryId,
          )
          .map((placementOnBody) => placementOnBody.id)
        labelsToAddOrUpdate = labelSet.labels.filter(
          (l: ManualTrackableLabel) =>
            placementsOnBodyIds.includes(l.manualPlacementId) &&
            (!l.dirtyState || l.dirtyState === LabelDirtyState.Add || l.dirtyState === LabelDirtyState.Update),
        )
      }

      const bpItemMesh = labelMesh.parent
      const labelsOnBody = bpItemMesh
        .getChildMeshes()
        .filter(
          (c) =>
            (this.meshManager.isLabelMesh(c) || this.meshManager.isLabelOrigin(c)) &&
            c.metadata.buildPlanItemId === buildPlanItemId &&
            (this.meshManager.isLabelOrigin(c) ? c.metadata.bodyComponentId : c.metadata.componentId === componentId) &&
            (this.meshManager.isLabelOrigin(c) ? c.metadata.bodyGeometryId : c.metadata.geometryId === geometryId) &&
            c.metadata.labelSetId === metadata.labelSetId &&
            c !== labelMesh,
        )
      const componentMesh = this.meshManager.getComponentMesh(componentId, geometryId, buildPlanItemId)
      if (
        (this.renderScene as RenderScene).getViewMode() instanceof MarkingViewMode &&
        !labelsOnBody.length &&
        !labelsToAddOrUpdate.length &&
        this.renderScene.getSelectionManager().isSelected({ part: bpItemMesh as TransformNode, body: componentMesh })
      ) {
        this.renderScene.getSelectionManager().deselect([{ body: componentMesh }], false)
        const picked = this.renderScene.getGpuPicker().pick(this.scene.pointerX, this.scene.pointerY)
        if (picked && picked.body) {
          ; (this.renderScene as RenderScene).hoverPickedObject(picked)
        }
        setTimeout(() => {
          ; (this.renderScene as RenderScene).animate()
        }, 0)
      }
    }
  }

  private validateLabelMesh(labelMesh: Mesh) {
    const geometry = labelMesh instanceof InstancedMesh ? labelMesh.sourceMesh.geometry : labelMesh.geometry
    const normals = geometry.getVerticesData(VertexBuffer.NormalKind)
    return this.isSharpOrDownskin(Array.from(normals), labelMesh.getWorldMatrix())
  }

  private translateToPartCS(labelMesh: Mesh, textDirection: Vector3, pickInfo: PickingInfo) {
    const transformation = this.getLabelParentTransformation(pickInfo.pickedMesh)
    const invTransformation = transformation.clone().invert()

    const relativeMatrix = labelMesh.getWorldMatrix().multiply(invTransformation)
    labelMesh.freezeWorldMatrix(relativeMatrix)

    const m = labelMesh.getWorldMatrix().clone().m
    labelMesh.bakeCurrentTransformIntoVertices()
    // bakeCurrentTransformIntoVertices flip faces automatically if m[0] * m[5] * m[10] < 0
    if (m[0] * m[5] * m[10] < 0) {
      labelMesh.flipFaces()
    }
    labelMesh.freezeWorldMatrix(transformation)

    const centerNormal = Vector3.TransformNormal(pickInfo.getNormal(true), invTransformation)
    const centerPoint = Vector3.TransformCoordinates(pickInfo.pickedPoint, invTransformation)
    const directions = this.calculateDirections(centerNormal, textDirection, invTransformation)

    return {
      normal: centerNormal,
      origin: centerPoint,
      xDirection: directions.xDirection,
      yDirection: directions.yDirection,
    }
  }

  private translateToWorldCS(labelMesh: Mesh, orientation: IOrientation, part: TransformNode) {
    const labelMetadata = labelMesh.metadata as ILabelMetadata
    const invLocalMatrix = labelMetadata.relativeTransformation.clone().invert()
    const partTransformation = this.getLabelParentTransformation(part) // relative matrix to initialization part matrix
    const invWorldPartTransformation = part.getWorldMatrix().clone().invert()

    const worldMatrix = partTransformation.multiply(invWorldPartTransformation).multiply(invLocalMatrix)
    labelMesh.freezeWorldMatrix(worldMatrix)

    const m = labelMesh.getWorldMatrix().clone().m
    labelMesh.bakeCurrentTransformIntoVertices()
    // bakeCurrentTransformIntoVertices flip faces automatically if m[0] * m[5] * m[10] < 0
    if (m[0] * m[5] * m[10] < 0) {
      labelMesh.flipFaces()
    }
    labelMesh.unfreezeWorldMatrix()

    const localMatrix = labelMetadata.relativeTransformation.clone()
    this.meshManager.transformMesh(labelMesh, localMatrix.transpose())
    labelMesh.parent = part
    labelMesh.computeWorldMatrix(true)

    const worldNormal = Vector3.TransformNormal(orientation.normal, partTransformation)
    const worldOrigin = Vector3.TransformCoordinates(orientation.origin, partTransformation)
    const worldYDirection = Vector3.TransformNormal(orientation.yDirection, partTransformation)
    labelMetadata.orientation = {
      normal: worldNormal,
      origin: worldOrigin,
      xDirection: Vector3.Zero(),
      yDirection: worldYDirection,
    }
  }

  private createNewLabel(
    id: string,
    componentId: string,
    geometryId: string,
    orientation: IOrientation,
    relativeTransformation: Matrix,
    style: ILabelStyle,
    isValid: boolean,
    createdAt: Date,
  ): ILabel {
    return {
      id,
      componentId,
      geometryId,
      style,
      isValid,
      createdAt,
      orientation: {
        normal: {
          x: orientation.normal.x,
          y: orientation.normal.y,
          z: orientation.normal.z,
        },
        origin: {
          x: orientation.origin.x,
          y: orientation.origin.y,
          z: orientation.origin.z,
        },
        xDirection: {
          x: orientation.xDirection.x,
          y: orientation.xDirection.y,
          z: orientation.xDirection.z,
        },
        yDirection: {
          x: orientation.yDirection.x,
          y: orientation.yDirection.y,
          z: orientation.yDirection.z,
        },
      },
      // TODO: Rename worldTransformation to relativeTransformation
      worldTransformation: Array.from(relativeTransformation.m),
    }
  }

  private setNativeParent(labelMesh: AbstractMesh) {
    const labelMetadata = labelMesh.metadata as ILabelMetadata
    const nativeParent = this.scene.getTransformNodeByID(labelMetadata.parentId)
    labelMesh.parent = nativeParent
    labelMesh.unfreezeWorldMatrix()
  }

  private setupLabelMesh(
    labelMesh: AbstractMesh,
    id: string,
    componentId: string,
    geometryId: string,
    style: ILabelStyle,
    parent: TransformNode,
    material: Material,
    relativeTransformation: Matrix,
    orientation: IOrientation,
    isValid: boolean,
    placementInvalid?: boolean,
    placedNotCorrectly?: boolean,
  ) {
    labelMesh.id = id
    labelMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
    labelMesh.material = material
    labelMesh.metadata = {} as ILabelMetadata
    labelMesh.metadata.itemType = SceneItemType.Label
    labelMesh.metadata.parentId = parent ? parent.id : null
    labelMesh.metadata.componentId = componentId
    labelMesh.metadata.geometryId = geometryId
    labelMesh.metadata.style = style
    labelMesh.metadata.orientation = orientation
    labelMesh.metadata.isValid = isValid
    labelMesh.metadata.placementInvalid = placementInvalid
    labelMesh.metadata.placedNotCorrectly = placedNotCorrectly
    labelMesh.metadata.relativeTransformation = relativeTransformation
    labelMesh.metadata.clone = () => _.cloneDeep(labelMesh.metadata)
    labelMesh.setParent(parent)
    labelMesh.isVisible = true
    labelMesh.isPickable = false
  }

  private setupLabelConfig(fontSize: number, fontFamily: string, text: string) {
    if (!text || !fontFamily || !fontSize) {
      return
    }

    const labelConfig = this.getLabelConfig(fontSize, fontFamily, text)
    if (labelConfig) {
      return
    }

    const result = this.createMaterial(text, fontFamily, fontSize, Color3.White())

    // save style material to reuse in future for new labels with this style
    this.labelConfigs.push({
      fontSize,
      fontFamily,
      contentWidth: result.width,
      contentHeight: result.heigth,
      textContent: text,
      regularMaterial: result.material,
      previewMaterial: this.createMaterial(text, fontFamily, fontSize, REGULAR_ORANGE, true).material,
      semitransparentMaterial: this.createMaterial(text, fontFamily, fontSize, Color3.White(), true).material,
    })
  }

  private async createLabelMeshFromDraco(dracoSurface: ArrayBuffer) {
    const vertexData = await DracoDecoder.Default.decodeMesh(dracoSurface)

    if (!vertexData.normals) {
      vertexData.normals = []
      VertexData.ComputeNormals(vertexData.positions, vertexData.indices, vertexData.normals, {
        useRightHandedSystem: true,
      })
    }

    const labelMesh = new Mesh(LABEL, this.renderScene.getScene())
    vertexData.applyToMesh(labelMesh)

    return labelMesh
  }

  private async createDracoFromLabelMesh(labelMesh: Mesh, labelId: string, originFacetId: number) {
    const indices = Array.from(labelMesh.getIndices())
    const positions = Array.from(labelMesh.geometry.getVerticesData(VertexBuffer.PositionKind))
    const uvs = Array.from(labelMesh.geometry.getVerticesData(VertexBuffer.UVKind))

    for (let i = 0; i < 3; i += 1) {
      indices.push(positions.length / 3)
      const v = indices[3 * originFacetId + i]

      positions.push(positions[v * 3])
      positions.push(positions[v * 3 + 1])
      positions.push(positions[v * 3 + 2])

      uvs.push(uvs[v * 2])
      uvs.push(uvs[v * 2 + 1])
    }
    indices.splice(3 * originFacetId, 3)

    const tempMesh = new Mesh(TEMP, this.scene)
    tempMesh.setVerticesData(VertexBuffer.PositionKind, positions)
    tempMesh.setVerticesData(VertexBuffer.UVKind, uvs)
    tempMesh.setIndices(indices)

    const indicesLength = indices.length
    const firstFace = new Face(0, SEED_SURFACE, indices.slice(indicesLength - 3, indicesLength))
    const other = new Face(1, 'Face_0_1', indices.slice(0, indicesLength - 3))
    const faces = [firstFace, other]

    const dracoLabel = await this.dracoEncoder.encodeMesh(tempMesh, faces, { exportUvs: true, exportNormals: false })
    const dracoFile = new File([dracoLabel], `${labelId}.drc`)
    tempMesh.dispose()

    return dracoFile
  }

  private createGroupParent(labelOrientation: IOrientation) {
    this.groupParent = new AbstractMesh(GROUP_PARENT_MESH_NAME, this.scene)
    if (!labelOrientation) {
      return
    }

    const textDirection = Vector3.Cross(labelOrientation.yDirection, labelOrientation.normal)

    // rotation angle of Z to match surface normal
    const crossNormalZ =
      labelOrientation.normal.z + Axis.Z.z < Epsilon ? Axis.X : Vector3.Cross(Axis.Z, labelOrientation.normal)
    const angleZ = Vector3.GetAngleBetweenVectors(Axis.Z, labelOrientation.normal, crossNormalZ)

    // find new Y after Z rotation
    const matrix = Matrix.RotationAxis(crossNormalZ, angleZ)
    const newY = Vector3.TransformNormal(Axis.Y, matrix)
    const angleY = Vector3.GetAngleBetweenVectors(newY, textDirection, labelOrientation.normal)

    // transform mesh
    this.groupParent.position = labelOrientation.origin.clone()
    this.groupParent.rotate(crossNormalZ, angleZ, Space.WORLD)
    this.groupParent.rotate(labelOrientation.normal, angleY, Space.WORLD)
    this.groupParent.rotate(textDirection, Math.PI, Space.WORLD)
  }

  private getLabelComponentMesh(labelMesh: AbstractMesh) {
    if (!this.meshManager.isLabelMesh(labelMesh)) {
      return
    }

    const labelMetadata = labelMesh.metadata as ILabelMetadata
    const bpItem = labelMetadata.parentId
      ? this.scene.getTransformNodeById(labelMetadata.parentId)
      : this.meshManager.getBuildPlanItemMeshById(labelMetadata.buildPlanItemId)
    const body = bpItem
      .getChildMeshes()
      .find(
        (m) =>
          this.meshManager.isComponentMesh(m) &&
          (m.metadata as IComponentMetadata).componentId === labelMetadata.componentId &&
          (!m.metadata.geometryId || (m.metadata as IComponentMetadata).geometryId === labelMetadata.geometryId),
      )

    return body
  }

  private calculateDecalRollAngle(normal: Vector3, textDirection: Vector3) {
    // basic yaw and pitch for decal in babylon.js source code
    const yaw = -Math.atan2(normal.z, normal.x) - Math.PI / 2
    const len = Math.sqrt(normal.x * normal.x + normal.z * normal.z)
    const pitch = Math.atan2(normal.y, len)
    const matrix = Matrix.RotationYawPitchRoll(yaw, pitch, 0)

    // calculate rotation angle for label to face camera plane
    const textDirectionXY = Vector3.TransformNormal(textDirection, matrix.invert())
    const angle = Vector3.GetAngleBetweenVectors(Axis.X, textDirectionXY, Axis.Z)

    return angle
  }

  private calculateDirections(centerNormal: Vector3, textDirection: Vector3, transformation?: Matrix) {
    const xDirection = Vector3.Zero()
    const textDirectionVector = transformation ? Vector3.TransformNormal(textDirection, transformation) : textDirection
    const yDirection = centerNormal.cross(textDirectionVector).normalize()

    return { xDirection, yDirection }
  }

  private calculateRotationAngle(textDirection: Vector3, normal: Vector3) {
    const camera = this.renderScene.getActiveCamera()
    const faceCamera = new OrthoCamera(
      'faceCamera',
      new Viewport(0, 0, 1, 1),
      this.scene,
      this.scene.getEngine().getRenderingCanvas(),
      this.renderScene,
    )
    faceCamera.position = normal.scale(camera.radius)
    const startPoint = this.meshManager.project3DPointOntoScreen(textDirection.scale(-10), faceCamera)
    const endPoint = this.meshManager.project3DPointOntoScreen(textDirection.scale(10), faceCamera)
    const textDirection2D = new Vector2(endPoint.x - startPoint.x, endPoint.y - startPoint.y).normalize()
    const rotationAngle =
      Angle.FromRadians(2 * Math.PI).degrees() -
      Angle.FromRadians(Math.atan2(textDirection2D.y, textDirection2D.x)).degrees() // adjust for required sign/offset

    faceCamera.dispose()

    return rotationAngle
  }

  private isBodyLabeled(body: AbstractMesh) {
    const bpItem = this.meshManager.getBuildPlanItemMeshByChild(body)
    const isLabeled = bpItem
      .getChildMeshes()
      .some(
        (c) =>
          this.meshManager.isLabelMesh(c) &&
          (c.metadata as ILabelMetadata).componentId === (body.metadata as IComponentMetadata).componentId &&
          (!body.metadata.geometryId || (body.metadata as IComponentMetadata).geometryId === c.metadata.geometryId),
      )

    return isLabeled
  }

  private isSharpOrDownskin(normals: number[], transformations: Matrix) {
    // check if label mesh has sharp edges
    // calculate angle between neighboor facet normals
    const negativeZ = Axis.Z.negate()
    let normal = new Vector3(normals[0], normals[1], normals[2])
    normal = Vector3.TransformNormal(normal, transformations)
    for (let i = 0; i < normals.length - 3; i += 3) {
      // get normals of neighboor facets
      let normal1 = new Vector3(normals[i], normals[i + 1], normals[i + 2])
      normal1 = Vector3.TransformNormal(normal1, transformations)
      let normal2 = new Vector3(normals[i + 3], normals[i + 4], normals[i + 5])
      normal2 = Vector3.TransformNormal(normal2, transformations)

      const angleBetweenFacetNormals = Math.abs(
        Vector3.GetAngleBetweenVectors(normal1, normal2, Vector3.Cross(normal1, normal2)),
      )
      const angleWithNegativeZ = Math.abs(
        Vector3.GetAngleBetweenVectors(normal1, negativeZ, Vector3.Cross(normal1, negativeZ)),
      )

      if (Number.isNaN(angleWithNegativeZ)) {
        continue
      }
      if (Angle.FromRadians(angleWithNegativeZ).degrees() < MAX_LABEL_ANGLE_BETWEEN_NORMALS) {
        return { isValid: false, isSharp: false, isDownskin: true }
      }

      if (Number.isNaN(angleBetweenFacetNormals)) {
        continue
      }
      if (Angle.FromRadians(angleBetweenFacetNormals).degrees() > MAX_LABEL_ANGLE_BETWEEN_NORMALS) {
        if (Angle.FromRadians(Math.PI - angleBetweenFacetNormals).degrees() > MAX_LABEL_ANGLE_BETWEEN_NORMALS) {
          return { isValid: false, isSharp: true, isDownskin: false }
        }
      }

      const angleBetweenFirstFacetNormals = Math.abs(
        Vector3.GetAngleBetweenVectors(normal, normal1, Vector3.Cross(normal, normal1)),
      )
      if (Number.isNaN(angleBetweenFirstFacetNormals)) {
        continue
      }
      if (Angle.FromRadians(angleBetweenFirstFacetNormals).degrees() > MAX_LABEL_NORMALS_TOLERANCE) {
        return { isValid: false, isSharp: true, isDownskin: false }
      }
    }

    return { isValid: true, isSharp: false, isDownskin: false }
  }

  private buildSensitiveZone(labelMesh: InstancedMesh, orientation: ILabelOrientation) {
    const origin = new Vector3(orientation.origin.x, orientation.origin.y, orientation.origin.z)
    const normal = new Vector3(orientation.normal.x, orientation.normal.y, orientation.normal.z)
    const yDirection = new Vector3(orientation.yDirection.x, orientation.yDirection.y, orientation.yDirection.z)
    const textDirection = Vector3.Cross(yDirection, normal)

    const labelClone = new Mesh('tempLabel', this.scene)
    const vd = new VertexData()
    vd.indices = labelMesh.sourceMesh.getIndices().slice()
    vd.positions = labelMesh.sourceMesh.getVerticesData(VertexBuffer.PositionKind).slice()
    vd.applyToMesh(labelClone)
    const normalTransformation = this.alignToNormal(labelClone, origin, normal, Axis.Z)
    this.alignToNormal(labelClone, origin, Vector3.TransformNormal(textDirection, normalTransformation), Axis.X)
    labelClone.bakeCurrentTransformIntoVertices()
    labelClone.refreshBoundingInfo()

    const bInfo = labelClone.getBoundingInfo()
    const bbox = bInfo.boundingBox
    const zoneOffset = bInfo.diagonalLength * LABEL_SENSITIVE_ZONE_SCALE - bInfo.diagonalLength
    const zoneWidth = bbox.maximumWorld.x - bbox.minimumWorld.x + zoneOffset
    const zoneHeigth = bbox.maximumWorld.y - bbox.minimumWorld.y + zoneOffset
    const zoneDepth = bbox.maximumWorld.z - bbox.minimumWorld.z + zoneOffset
    const sensitiveZone = MeshBuilder.CreateBox(
      LABEL_SENSITIVE_ZONE,
      { width: zoneWidth, height: zoneHeigth, depth: zoneDepth },
      this.scene,
    )
    sensitiveZone.id = uuid()
    sensitiveZone.renderingGroupId = MESH_RENDERING_GROUP_ID
    sensitiveZone.isPickable = false
    sensitiveZone.isVisible = false
    sensitiveZone.registerInstancedBuffer(VertexBuffer.ColorKind, 3)
    sensitiveZone.instancedBuffers.color = REGULAR_ORANGE
    sensitiveZone.position = bbox.centerWorld
    const axisZTransformation = this.alignToNormal(sensitiveZone, origin, Axis.Z, normal)
    this.alignToNormal(sensitiveZone, origin, Vector3.TransformNormal(Axis.X, axisZTransformation), textDirection)
    sensitiveZone.parent = labelMesh.parent

    sensitiveZone.metadata = {
      itemType: SceneItemType.LabelSensitiveZone,
      faces: labelMesh.sourceMesh.metadata.faces,
    }

    const sensitiveZoneInstance = sensitiveZone.createInstance(LABEL_SENSITIVE_ZONE)
    sensitiveZoneInstance.id = uuid()
    sensitiveZoneInstance.position = bbox.centerWorld
    sensitiveZoneInstance.parent = labelMesh.parent
    sensitiveZoneInstance.isVisible = false
    sensitiveZoneInstance.metadata = {
      itemType: SceneItemType.LabelSensitiveZone,
      faces: sensitiveZone.metadata,
      labelId: labelMesh.id,
      labelSetId: labelMesh.metadata.labelSetId,
    }
    this.scene.removeMesh(sensitiveZone)
    this.scene.removeMesh(labelClone)
    labelClone.dispose()

    return sensitiveZoneInstance
  }

  private setupForGpuPicker(mesh: InstancedMesh, itemType: SceneItemType) {
    if (!mesh.sourceMesh.instancedBuffers.bColor) {
      mesh.sourceMesh.registerInstancedBuffer(COLOR_FOR_BODY, 3)
      mesh.sourceMesh.instancedBuffers.bColor = Color3.White()
    }

    if (!mesh.sourceMesh.instancedBuffers.pColor) {
      mesh.sourceMesh.registerInstancedBuffer(COLOR_FOR_PART, 3)
      mesh.sourceMesh.instancedBuffers.pColor = Color3.White()
    }

    if (!mesh.sourceMesh.instancedBuffers.fColor) {
      mesh.sourceMesh.registerInstancedBuffer(COLOR_FOR_FACE, 3)
      mesh.sourceMesh.instancedBuffers.fColor = Color3.White()
    }

    if (!mesh.sourceMesh.metadata) {
      mesh.sourceMesh.metadata = {}
    }

    if (!mesh.sourceMesh.metadata.pickingShader) {
      mesh.sourceMesh.metadata.pickingShader = this.renderScene.getGpuPicker().pickingShader
    }

    const pickingColor = Color3.FromInts(
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
    )
    mesh.instancedBuffers.pColor = mesh.parent.metadata.pickingColor
    mesh.instancedBuffers.bColor = pickingColor
    mesh.instancedBuffers.fColor =
      mesh.metadata.faces && mesh.metadata.faces.length
        ? mesh.metadata.faces[0].color
        : mesh.sourceMesh.metadata.faces[0].color
    if (!mesh.metadata) {
      mesh.metadata = {}
    }

    mesh.metadata.pickingColor = pickingColor
    mesh.metadata.itemType = itemType
    mesh.metadata.componentId = uuid()
    mesh.metadata.geometryId = uuid()
    if (itemType !== SceneItemType.LabelOrigin) {
      mesh.metadata.faces = mesh.sourceMesh.metadata.faces
    }
  }

  private alignToNormal(mesh: Mesh, origin: Vector3, sourceNormal: Vector3, targetNormal: Vector3) {
    let rotationAxis: Vector3
    if (Math.abs(sourceNormal.x - targetNormal.x) < Epsilon && Math.abs(sourceNormal.z + targetNormal.z) < Epsilon) {
      rotationAxis = Axis.X
    } else if (
      Math.abs(sourceNormal.y - targetNormal.y) < Epsilon &&
      Math.abs(sourceNormal.z + targetNormal.z) < Epsilon
    ) {
      rotationAxis = Axis.Y
    } else {
      rotationAxis = Vector3.Cross(sourceNormal, targetNormal).normalize()
    }

    const angle = Vector3.GetAngleBetweenVectors(sourceNormal, targetNormal, rotationAxis)
    mesh.rotateAround(origin, rotationAxis, angle)

    return Matrix.RotationAxis(rotationAxis, angle)
  }

  private createOriginTemplateMesh() {
    const material = this.scene.getMaterialById(REGULAR_YELLOW_MATERIAL)
    this.originTemplateMesh = MeshBuilder.CreateSphere(LABEL_ORIGIN, { diameter: 1 }, this.scene)
    this.originTemplateMesh.material = material
    this.originTemplateMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
    this.originTemplateMesh.registerInstancedBuffer(VertexBuffer.ColorKind, 3)
    this.originTemplateMesh.instancedBuffers.color = Color3.White()
    this.originTemplateMesh.metadata = {
      originalMaterial: material,
    }
    this.scene.removeMesh(this.originTemplateMesh)
  }

  /**
   * Creates instance of label origin
   * @param id - label ID
   * @param labelSetId - label set ID
   * @param buildPlanItemId - build plan item ID
   * @param componentId - component ID
   * @param geometryId - geometry ID
   * @param position - label mesh position
   * @param orientation - label orientation in world coordinate system
   * @param color - label color
   * @param parent - label parent mesh
   * @param applyParentTransformation - should parent mesh transformation be applied
   */
  private createOriginInstance(
    id: string,
    labelSetId: string,
    buildPlanItemId: string,
    componentId: string,
    geometryId: string,
    position: Vector3,
    orientation: IOrientation,
    color: Color3,
    parent: TransformNode,
    applyParentTransformation: boolean,
  ) {
    const originMesh = this.originTemplateMesh.createInstance(LABEL_ORIGIN)
    originMesh.position = position
    originMesh.instancedBuffers.color = color
    originMesh.id = id
    if (!applyParentTransformation) {
      originMesh.setParent(parent)
    } else {
      originMesh.parent = parent
    }

    originMesh.metadata = {
      orientation,
      labelSetId,
      buildPlanItemId,
      itemType: SceneItemType.LabelOrigin,
      labelId: id,
      faces: [
        new Face(
          0,
          '',
          Array.from(this.originTemplateMesh.getIndices()),
          0,
          Color3.FromInts(
            Math.ceil(Math.random() * 255),
            Math.ceil(Math.random() * 255),
            Math.ceil(Math.random() * 255),
          ),
        ),
      ],
      // componentId and geometryId from metadata is used in GPU picker to define picked object
      // as we already have body with these ids in GPU picker need another properties to store body ids
      // TODO: in GPU picker assign to each object like 'pickingId' and use it everywhere
      bodyComponentId: componentId,
      bodyGeometryId: geometryId,
    }

    return originMesh
  }

  private calculateManualLabelSettingsLocation(draggableLabel: AbstractMesh) {
    let orientation = draggableLabel.metadata.orientation
    if (this.meshManager.isLabelSensitiveZone(draggableLabel)) {
      orientation = this.scene.meshes.find((m) => {
        return this.meshManager.isLabelMesh(m) && m.metadata && m.metadata.sensitiveZoneId === draggableLabel.id
      }).metadata.orientation
    }

    const camera = this.renderScene.getActiveCamera()
    const faceCamera = new OrthoCamera(
      'labelSurfaceCamera',
      new Viewport(0, 0, 1, 1),
      this.scene,
      this.scene.getEngine().getRenderingCanvas(),
      this.renderScene,
    )
    faceCamera.position = orientation.origin.add(orientation.normal.scale(camera.radius))
    faceCamera.target = orientation.origin
    faceCamera.orthoTop = camera.orthoTop
    faceCamera.orthoBottom = camera.orthoBottom
    faceCamera.orthoLeft = camera.orthoLeft
    faceCamera.orthoRight = camera.orthoRight
    faceCamera.alpha = camera.alpha

    const bbox = this.labelGizmo.getGizmoMeshByName(PLANAR_GIZMO_NAME).getBoundingInfo().boundingBox
    const bbox2DPoints: Vector2[] = []
    for (const vectorWorld of bbox.vectorsWorld) {
      bbox2DPoints.push(this.meshManager.project3DPointOntoScreen(vectorWorld, faceCamera))
    }

    faceCamera.dispose()
    const sortedDescendingByX = Array.from(bbox2DPoints).sort((a, b) => (a.x > b.x ? -1 : 1))
    // take right side of label bounding box: find the bigest y for the biggest x from first 4 elements in array,
    // because coordinates of bounding box is the same (camera looking along the label normal)
    let yMaxIndex = 0
    let sortedYMax = sortedDescendingByX[yMaxIndex]
    for (let i = 0; i < 4; i += 1) {
      if (sortedDescendingByX[i] > sortedYMax) {
        sortedYMax = sortedDescendingByX[i]
        yMaxIndex = i
      }
    }

    // find index of point in unsorted array
    const index = bbox2DPoints.findIndex(
      (p) =>
        Math.abs(p.x - sortedDescendingByX[yMaxIndex].x) < Epsilon &&
        Math.abs(p.y - sortedDescendingByX[yMaxIndex].y) < Epsilon,
    )

    // project found bounding box corner on screen using main scene camera
    const position2D = this.meshManager.project3DPointOntoScreen(bbox.vectorsWorld[index])
    const arrowIconWidth = 60 // from styles
    const arrowIconHeight = 24 // from styles

    // relates to extension-height property of v-app-bar for announcement
    const shiftY = store.getters['announcements/getAnnouncement'] ? ANNOUNCEMENT_HEIGHT : 0

    return { x: position2D.x + arrowIconWidth / 2, y: position2D.y - shiftY + arrowIconHeight / 2 }
  }

  private convertToVertex(vector: Vector3) {
    return {
      x: vector.x,
      y: vector.y,
      z: vector.z,
    }
  }

  private convertToVector3(vertex: IVertex) {
    return new Vector3(vertex.x, vertex.y, vertex.z)
  }

  private convertToOrientationVector3(orientation: ILabelOrientation) {
    return {
      origin: this.convertToVector3(orientation.origin),
      normal: this.convertToVector3(orientation.normal),
      xDirection: this.convertToVector3(orientation.xDirection),
      yDirection: this.convertToVector3(orientation.yDirection),
    }
  }

  private initCacheRestoreNotifier() {
    this.waitCacheRestored = (() => {
      const initPromise = new Promise((resolve) => {
        this.notifyCacheRestored = resolve
      })
      return () => initPromise
    })()
  }

  private getComponentByItsLabel(label: AbstractMesh, bpItemId: string): InstancedMesh {
    return this.meshManager.getComponentMesh(
      label.metadata.componentId,
      label.metadata.geometryId,
      bpItemId,
    ) as InstancedMesh
  }

  private canShowSupport(): boolean {
    const displaySettings = store.getters['buildPlans/displayToolbarStateByVariantId'](
      store.getters['buildPlans/getBuildPlan'].id,
    )
    return displaySettings.isShowingSupportGeometry
  }

  private canShowLabel(label: AbstractMesh, bpItemId: string) {
    const componentMesh = this.getComponentByItsLabel(label, bpItemId)

    return this.canShowComponent(componentMesh)
  }

  private canShowComponent(mesh: AbstractMesh) {
    // 'Display labeled bodies' button can not override settings for Hidden/Production/Support/Coupon Geometry
    return this.meshManager.isMeshGeometryTypeVisible(mesh as InstancedMesh)
  }

  /**
   * Updates the display of bodies that are not being referenced by any label that belongs to the current active set.
   * Also, updates the visibility of other meshes that linked to labels.
   */
  private setNotActiveLabledBodiesVisibility(visibility: boolean, activeLabelSetId: string) {
    const buildPlanItems = (this.renderScene as RenderScene).getSceneMetadata().buildPlanItems.values()
    for (const bpItem of buildPlanItems) {
      const allLabels = bpItem.getChildMeshes(false, this.meshManager.isLabelMesh)

      const bodiesWithActiveLabels = new Set<AbstractMesh>()
      allLabels
        .filter((label) => label.metadata.labelSetId === activeLabelSetId)
        .forEach((label) =>
          bodiesWithActiveLabels.add(this.getComponentByItsLabel(label, bpItem.metadata.buildPlanItemId)),
        )
      const bodiesWithoutActiveLabels: AbstractMesh[] = bpItem
        .getChildMeshes(false, this.meshManager.isComponentMesh)
        .filter((mesh) => !bodiesWithActiveLabels.has(mesh))

      allLabels.forEach((label) => {
        const canBeVisible = this.canShowLabel(label, bpItem.metadata.buildPlanItemId)
        if (label.metadata.labelSetId !== activeLabelSetId) {
          // Displays/Hides non-active labels.
          ; (this.renderScene as RenderScene).setMeshVisibilityRec(label, visibility && canBeVisible, false, true)
        } else {
          // Displays active labels.
          ; (this.renderScene as RenderScene).setMeshVisibilityRec(label, canBeVisible, false, true)
        }
      })

      // Displays bodies with active labels.
      bodiesWithActiveLabels.forEach((body) => {
        ; (this.renderScene as RenderScene).setMeshVisibilityRec(body, this.canShowComponent(body), false, true)
      })

      // Displays/Hides bodies without active labels.
      bodiesWithoutActiveLabels.forEach((body) => {
        ; (this.renderScene as RenderScene).setMeshVisibilityRec(
          body,
          visibility && this.canShowComponent(body),
          false,
          true,
        )
      })

      // Hides all supports if one body is hidden.
      const bpItemWithSupportsIds = [{ buildPlanItemId: bpItem.metadata.buildPlanItemId }]
      const supportVisibility = this.canShowSupport() && (bodiesWithoutActiveLabels.length ? visibility : true)
        ; (this.renderScene as RenderScene).setSupportsVisibility(bpItemWithSupportsIds, supportVisibility)
    }
  }

  /**
   * Updates the display of bodies that are referenced by labels.
   * Also, updates the visibility of other meshes that linked to labels.
   */
  private setAllLabledBodiesVisibility(visibility: boolean) {
    const buildPlanItems = (this.renderScene as RenderScene).getSceneMetadata().buildPlanItems.values()
    for (const bpItem of buildPlanItems) {
      const allLabels = bpItem.getChildMeshes(false, this.meshManager.isLabelMesh)

      const labeledBodies = new Set<AbstractMesh>()
      allLabels.forEach((label) =>
        labeledBodies.add(this.getComponentByItsLabel(label, bpItem.metadata.buildPlanItemId)),
      )
      const nonLabeledBodies: AbstractMesh[] = bpItem
        .getChildMeshes(false, this.meshManager.isComponentMesh)
        .filter((mesh) => !labeledBodies.has(mesh))

      // Displays/Hides all labels.
      allLabels.forEach((label) => {
        ; (this.renderScene as RenderScene).setMeshVisibilityRec(
          label,
          visibility && this.canShowLabel(label, bpItem.metadata.buildPlanItemId),
          false,
          true,
        )
      })

      // Displays/Hides all labeled bodies.
      labeledBodies.forEach((body) => {
        ; (this.renderScene as RenderScene).setMeshVisibilityRec(
          body,
          visibility && this.canShowComponent(body),
          false,
          true,
        )
      })

      // Displays non labeled bodies.
      nonLabeledBodies.forEach((body) => {
        ; (this.renderScene as RenderScene).setMeshVisibilityRec(body, this.canShowComponent(body), false, true)
      })

      // Hides all supports if one body is hidden.
      const bpItemWithSupportsIds = [{ buildPlanItemId: bpItem.metadata.buildPlanItemId }]
      const supportVisibility = this.canShowSupport() && (labeledBodies.size ? visibility : true)
        ; (this.renderScene as RenderScene).setSupportsVisibility(bpItemWithSupportsIds, supportVisibility)
    }
  }

  /**
   * Creates a label inside mesh for provided label mesh instance and color
   * @param labelMeshInstance label mesh instance
   * @param color color that will be applied to inside mesh
   * @returns instance of created label mesh inside
   */
  private createLabelMeshInside(labelMeshInstance: InstancedMesh, color: Color3) {
    const labelMesh = labelMeshInstance.sourceMesh
    const meshInside = new Mesh(LABEL_INSIDE_MESH_NAME, this.scene)
    meshInside.id = `${labelMesh.id}_inside`
    meshInside.parent = labelMesh
    meshInside.material = this.scene.getMaterialByName(LABEL_INSIDE_MATERIAL)
    meshInside.renderingGroupId = MESH_RENDERING_GROUP_ID
    meshInside.isPickable = false
    meshInside.isVisible = false
    meshInside.registerInstancedBuffer(VertexBuffer.ColorKind, 3)
    meshInside.instancedBuffers.color = color
    labelMesh.metadata.sourceMeshInside = meshInside

    const vd = new VertexData()
    vd.indices = labelMesh.getIndices()
    vd.positions = labelMesh.getVerticesData(VertexBuffer.PositionKind)
    vd.normals = labelMesh.getVerticesData(VertexBuffer.NormalKind)
    vd.applyToMesh(meshInside)

    const meshInsideInstance = meshInside.createInstance(LABEL_INSIDE_MESH_NAME)
    meshInsideInstance.id = meshInside.id
    meshInsideInstance.isPickable = false
    meshInsideInstance.isVisible = false
    meshInsideInstance.parent = labelMeshInstance
    meshInsideInstance.instancedBuffers.color = color

    meshInside.id = `${meshInside.id}_source`
    meshInside.name = `${meshInside.name}_source`
    this.scene.removeMesh(meshInside)

    return meshInsideInstance
  }
}
