
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Watch, Mixins } from 'vue-property-decorator'
import i18n from '@/plugins/i18n'

import StoresNamespaces from '@/store/namespaces'
import BuildPlanPartFooter from './BuildPlanPartFooter.vue'
import BuildTimeLabel from '@/components/layout/buildPlans/footer/BuildTimeLabel.vue'
import BuildGeometryLabel from '@/components/layout/buildPlans/footer/BuildGeometryLabel.vue'
import {
  BuildPlanCost,
  ISelectable,
  AddPartToolState,
  SelectionUnit,
  IBuildPlanItem,
  IGeometryProperties,
  IBuildPlan,
  IPrintStrategyParameterSet,
  SliceAlignment,
} from '@/types/BuildPlans/IBuildPlan'
import { ManufacturingRegions } from '@/types/ManufacturingRegions'
import MachineMaterialSelectionMixin from '@/components/layout/buildPlans/mixins/MachineMaterialSelection'
import Icon, { getIconName } from '@/components/icons/Icon.vue'

import {
  BUILD_COST_LIMIT,
  DEFAULT_SUPPORT_VOLUME_PERCENT_THAT_IS_USED,
  M_TO_MM,
  NOT_AVAILABLE_LABEL,
} from '@/constants'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { formatDecimal } from '@/utils/number'
import { IJob, JobType } from '@/types/PartsLibrary/Job'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import store from '@/store'
import { getDefaultBaseOnType } from '@/utils/parameterSet/parameterSetUtils'
import { ILabel } from '@/types/Marking/ILabel'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { PrintingTypes } from '@/types/IMachineConfig'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const labelStore = namespace(StoresNamespaces.Labels)
const commonStore = namespace(StoresNamespaces.Common)
const visualizationStore = namespace(StoresNamespaces.Visualization)

interface IMixinInterface extends Vue, MachineMaterialSelectionMixin {}

@Component({
  components: {
    BuildPlanPartFooter,
    BuildTimeLabel,
    BuildGeometryLabel,
    Icon,
  },
})
export default class BuildPlanFooter extends Mixins<IMixinInterface>(Vue, MachineMaterialSelectionMixin) {
  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter getIBCPlan: IIBCPlan
  @buildPlansStore.Getter getAllBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getAllBuildPlanMarkLabels: ILabel[]
  @buildPlansStore.Getter getAddPartToolState: AddPartToolState
  @buildPlansStore.Getter getBuildPlanCost: BuildPlanCost
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes
  @buildPlansStore.Getter getCalcCostInProgress: boolean
  @buildPlansStore.Getter getSelectedMachineConfigPk: VersionablePk | null
  @buildPlansStore.Getter isBuildPlan: boolean
  @buildPlansStore.Getter isSinterPlan: boolean
  @buildPlansStore.Getter printingType: string
  @buildPlansStore.Getter isShownNoMaterialParamsTooltip: boolean
  @buildPlansStore.Getter('getSelectedParts') getSelectedItems: ISelectable[]
  @buildPlansStore.Getter getSelectedBuildPlanJobs: IJob[]
  @buildPlansStore.Getter getIsLoading: boolean
  @buildPlansStore.Getter getSelectedBuildPlanCompletedJobs: IJob[]
  @buildPlansStore.Getter getSelectedBuildPlanFinalizingJobs: IJob[]

  @labelStore.Getter labelSets: InteractiveLabelSet[]

  @visualizationStore.Getter getBuildBoundingBox: () => object

  @commonStore.Getter tooltipOpenDelay: number

  buildHeightMm: string = '-'
  buildHeightSlices: string = '-'

  totalXY: string = '-'
  minX: string = '-'
  maxX: string = '-'
  minY: string = '-'
  maxY: string = '-'

  totalSurfaceArea: string = '-'
  totalVolume: string = '-'

  defaultSupportVolumePercent = DEFAULT_SUPPORT_VOLUME_PERCENT_THAT_IS_USED
  defaultManufacturingRegion = ManufacturingRegions.Americas
  selectedItems: ISelectable[] = []

  get renderBuildPlanCost(): string {
    if (this.isShownNoMaterialParamsTooltip) {
      return NOT_AVAILABLE_LABEL
    }

    if (!this.isBuildCostValid) {
      return '-'
    }

    const { min, max } = this.getBuildPlanCost
    return this.formatCurrencyRange(min, max)
  }

  get isBuildCostValid(): boolean {
    const { min, max } = this.getBuildPlanCost

    return min <= max && max < BUILD_COST_LIMIT
  }

  get isShownCostDescription(): boolean {
    if (!this.isBuildCostValid) {
      return false
    }

    const { min, max } = this.getBuildPlanCost
    return min !== 0 && max !== 0
  }

  get isShownCostInfoIcon(): boolean {
    return this.isShownCostDescription || this.isShownNoMaterialParamsTooltip || !this.isBuildCostValid
  }

  get isShownNumberOfLasers() {
    return this.printingType === PrintingTypes.DMLM
  }

  get isShownBuildPartFooter() {
    if (this.showEmptyPartFooter) {
      return true
    }

    // Shall be similar to SBC variant when no part is selected
    if (this.getIBCPlan) {
      return false
    }

    if (this.selectedItems && this.selectedItems.length && !this.getIsLoading) {
      return this.selectedItems.every((item) => item.type === SelectionUnit.Part)
    }

    return false
  }

  get shouldShowBuildCostAndTime(): boolean {
    return this.isBuildPlan && !this.getIBCPlan
  }

  // GEAMPREQ-764: As long as multiple parts are selected, the footer shall be displayed as empty.
  get showEmptyPartFooter() {
    return this.getBuildPlanViewMode === ViewModeTypes.Part && this.getAddPartToolState.selectedParts.length > 1
  }

  get showAlignmentStatus() {
    return (
      this.buildPlan &&
      this.printingType === PrintingTypes.BinderJet &&
      this.getSelectedBuildPlanCompletedJobs.some((job) => job.jobType === JobType.EXPOSURE)
    )
  }

  get buildPlanAlignmentUsed() {
    return this.getAllBuildPlanItems.some(
      (item) => item.sliceAlignment === SliceAlignment.Top || item.sliceAlignment === SliceAlignment.Bottom,
    )
  }

  get sliceAlignmentText() {
    return this.showAlignmentStatus && this.buildPlanAlignmentUsed ? this.$t('slicedLayerAligned') : this.$t('sliced')
  }

  get showSinterPlanScaledIcon() {
    if (!this.isSinterPlan || !this.getAllBuildPlanItems.length) return false
    const isSimulated = this.getSelectedBuildPlanFinalizingJobs.length

    if (isSimulated) return false
    return this.isSinterPlan
  }

  getIconName(view: string) {
    return getIconName(view)
  }

  resetFooterValues() {
    this.buildHeightMm = '-'
    this.buildHeightSlices = '-'
    this.totalXY = '-'
    this.minX = '-'
    this.maxX = '-'
    this.minY = '-'
    this.maxY = '-'
    this.totalSurfaceArea = '-'
    this.totalVolume = '-'
  }

  @Watch('buildPlan')
  @Watch('getAllBuildPlanItems')
  // @Watch('getAllBuildPlanMarkLabels')
  // @Watch('labelSets')
  onBuildPlanItemsOrLabelsChange() {
    this.init()
  }

  async init() {
    if (!this.getAllBuildPlanItems || this.getAllBuildPlanItems.length === 0 || !this.buildPlan) {
      this.resetFooterValues()
      return
    }

    const buildBoundingBox = await this.getBuildBoundingBox()
    this.setTotalBuildHeightFromBBox(buildBoundingBox)
    this.setTotalXYSizeFromBBox(buildBoundingBox)

    const geometriesProperties: IGeometryProperties[] = []
    for (const bpItem of this.buildPlan.buildPlanItems) {
      geometriesProperties.push(bpItem.geometryProperties)
    }
    this.setTotalSurfaceArea(geometriesProperties)
    this.setTotalVolume(geometriesProperties)
  }

  setTotalBuildHeightFromBBox(bbox) {
    if (bbox.maximumWorld.z > 0) {
      if (this.isBuildPlan) {
        // compute and show build height and number of slice layers
        // for build plan take part offset from the build plate into account for plan height
        this.buildHeightMm = `${formatDecimal(bbox.maximumWorld.z, 3)} ${i18n.t('millimeterAbbr')}`.toLocaleString()
        // height in slices
        const bpItemsLayerThicknesses = []
        for (const bpItem of this.getAllBuildPlanItems) {
          const bpItemLayerThicknesses = bpItem.partProperties.map((pp) => {
            let printStrategyParameterSetPk: VersionablePk
            if (pp.printStrategyParameterSetId) {
              printStrategyParameterSetPk = new VersionablePk(
                pp.printStrategyParameterSetId,
                pp.printStrategyParameterSetVersion,
              )
            } else {
              const printStrategy: BuildPlanPrintStrategyDto = store.getters['buildPlans/getBuildPlanPrintStrategy']
              printStrategyParameterSetPk = getDefaultBaseOnType(printStrategy.defaults, pp.type, pp.bodyType)
            }
            const printStrategyPartParameter: IPrintStrategyParameterSet =
              store.getters['buildPlans/getPrintStrategyParameterSetByPk'](printStrategyParameterSetPk)
            return printStrategyPartParameter && printStrategyPartParameter.layerThickness * M_TO_MM
          })
          bpItemsLayerThicknesses.push(...bpItemLayerThicknesses)
        }
        const minLayerThickness = Math.min(...bpItemsLayerThicknesses)
        const layers = formatDecimal(bbox.maximumWorld.z / minLayerThickness, 0)
        if (!Number.isFinite(+layers)) {
          return
        }
        this.buildHeightSlices = `${layers} ${i18n.t('buildPlanFooter.plan.layers')}`
      } else {
        // means this is a sinter plan
        // for sinter plan don't take part offset from the plate into account for plan height
        const buildHeight = bbox.maximumWorld.z - bbox.minimumWorld.z
        this.buildHeightMm = `${formatDecimal(buildHeight, 3)} ${i18n.t('millimeterAbbr')}`.toLocaleString()
      }
    } else {
      // only applicable to Sinter plans, as z should never be below 0 for Build plans
      const buildHeight = Math.abs(bbox.maximumWorld.z - bbox.minimumWorld.z)
      this.buildHeightMm = `${formatDecimal(buildHeight, 3)} ${i18n.t('millimeterAbbr')}`.toLocaleString()
      // no slices info needs to be displayed in Sinter plans
      this.buildHeightSlices = `-`
    }
  }

  setTotalXYSizeFromBBox(bbox) {
    const xLength = formatDecimal(bbox.maximumWorld.x - bbox.minimumWorld.x, 3).toLocaleString()
    const yLength = formatDecimal(bbox.maximumWorld.y - bbox.minimumWorld.y, 3).toLocaleString()
    this.totalXY = `${xLength} X ${yLength} ${i18n.t('millimeterAbbr')}`
    this.minX = `${formatDecimal(bbox.minimumWorld.x, 3).toLocaleString()} ${i18n.t('millimeterAbbr')}`
    this.maxX = `${formatDecimal(bbox.maximumWorld.x, 3).toLocaleString()} ${i18n.t('millimeterAbbr')}`
    this.minY = `${formatDecimal(bbox.minimumWorld.y, 3).toLocaleString()} ${i18n.t('millimeterAbbr')}`
    this.maxY = `${formatDecimal(bbox.maximumWorld.y, 3).toLocaleString()} ${i18n.t('millimeterAbbr')}`
  }

  setTotalSurfaceArea(geometriesProperties: IGeometryProperties[]) {
    let totalSurfaceArea = 0
    geometriesProperties.forEach((geometryProperties) => {
      if (geometryProperties && geometryProperties.surfaceArea) {
        totalSurfaceArea += geometryProperties.surfaceArea
        if (geometryProperties.supportSurfaceArea) {
          totalSurfaceArea += geometryProperties.supportSurfaceArea
        }
      }
    })
    if (totalSurfaceArea > 0) {
      this.totalSurfaceArea = `${formatDecimal(totalSurfaceArea, 0).toLocaleString()} ${i18n.t(
        'millimeterSquaredAbbr',
      )}`
    } else {
      this.totalSurfaceArea = '-'
    }
  }

  setTotalVolume(geometriesProperties: IGeometryProperties[]) {
    let totalVolume = 0
    geometriesProperties.forEach((geometryProperties) => {
      if (geometryProperties && geometryProperties.volume) {
        totalVolume += geometryProperties.volume
        if (geometryProperties.supportVolume) {
          totalVolume += geometryProperties.supportVolume
        }
      }
    })
    if (totalVolume > 0) {
      this.totalVolume = `${formatDecimal(totalVolume, 0).toLocaleString()} ${i18n.t('millimeterCubedAbbr')}`
    } else {
      this.totalVolume = '-'
    }
  }

  @Watch('getBuildPlanViewMode')
  @Watch('getAddPartToolState')
  @Watch('getAddPartToolState.geometryProperties')
  @Watch('getAddPartToolState.selectedParts')
  onViewModeChange() {
    this.selectedItems = []
    switch (this.getBuildPlanViewMode) {
      case ViewModeTypes.Part:
        if (this.getAddPartToolState.selectedParts.length === 1 && this.getAddPartToolState.geometryProperties) {
          const selectablePart: ISelectable = {
            id: '',
            type: SelectionUnit.Part,
            geometryProperties: this.getAddPartToolState.geometryProperties,
          }

          this.selectedItems.push(selectablePart)
        }
        break

      case ViewModeTypes.Replace:
        if (this.getAddPartToolState.selectedParts.length === 1 && this.getAddPartToolState.geometryProperties) {
          const selectablePart: ISelectable = {
            id: '',
            type: SelectionUnit.Part,
            geometryProperties: this.getAddPartToolState.geometryProperties,
          }

          this.selectedItems.push(selectablePart)
        } else {
          this.selectedItems = this.getSelectedItems
        }
        break

      default:
        this.selectedItems = this.getSelectedItems
        break
    }
  }

  @Watch('getSelectedItems')
  onSelectedItemsChange() {
    const incomingPartSelected = this.getAddPartToolState.selectedParts.length
    const isReplaceViewModeUpdateFreeze = this.getBuildPlanViewMode === ViewModeTypes.Replace && incomingPartSelected

    if (!(this.getBuildPlanViewMode === ViewModeTypes.Part || isReplaceViewModeUpdateFreeze)) {
      this.selectedItems = this.getSelectedItems
    }
  }

  numberToCurrency(numStr: string): string {
    return this.$options.filters.currency(numStr)
  }

  formatCurrencyRange(min: number = 0, max: number = 0, digits: number = 2): string {
    let str = ''
    let strMin = min ? this.numberToCurrency(min.toFixed(digits)) : ''
    let strMax = max ? this.numberToCurrency(max.toFixed(digits)) : ''

    if (strMin.length > 2) {
      strMin = strMin.substr(2)
    }
    if (strMax.length > 2) {
      strMax = strMax.substr(2)
    }

    if (strMin && strMax && strMin !== strMax) {
      str = `${strMin} - ${strMax}`
    } else {
      str = strMin || strMax || '-'
    }

    return str
  }
}
