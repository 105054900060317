/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Scene } from '@babylonjs/core/scene'
import { Engine } from '@babylonjs/core/Engines/engine'
import { InstancedMesh, Mesh, TransformNode } from '@babylonjs/core/Meshes'
import { Plane } from '@babylonjs/core/Maths'
import { VisualizationEvent } from '@/visualization/infrastructure/IVisualizationEvent'
import {
  INSIDE_MESH_NAME,
  LABEL_INSIDE_MESH_NAME,
  SUPPORT_INSIDE_MESH_NAME,
  OVERHANG_INSIDE_MESH_NAME,
  MESH_INSIDE_MATERIAL_NAME,
  SUPPORT_INSIDE_MATERIAL,
  SEMI_TRANSPARENT_INSIDE_MATERIAL_NAME,
  SEMI_TRANSPARENT_SUPPORT_INSIDE_MATERIAL,
} from '@/constants'
import { RenderScene } from '../render-scene'

export class SlicerManager {
  private readonly onInitializeSlices = new VisualizationEvent<{ max: number; min: number }>()
  private renderScene: RenderScene
  private engine: Engine
  private scene: Scene
  private isEnabledSlicerMode: boolean = false
  private current: number

  constructor(renderScene: RenderScene) {
    this.renderScene = renderScene
    this.scene = renderScene.getScene()
    this.engine = renderScene.getScene().getEngine()
  }

  get initializeSlicer() {
    return this.onInitializeSlices.expose()
  }

  get isEnabled() {
    return this.isEnabledSlicerMode
  }

  setSlicer(isEnabled: boolean) {
    this.isEnabledSlicerMode = isEnabled

    if (!isEnabled) {
      this.clearSlicer()
      return
    }

    const boundingBox = this.scene.getWorldExtends((mesh) => mesh.visibility === 1)
    const width = boundingBox.max.x - boundingBox.min.x
    const height = boundingBox.max.y - boundingBox.min.y
    const minZ = (boundingBox.max.z - boundingBox.min.z) * 0.01 + boundingBox.min.z

    this.onInitializeSlices.trigger({ max: boundingBox.max.z, min: minZ })

    const meshesInside = this.scene.meshes.filter((mesh) => {
      return mesh.name.includes(INSIDE_MESH_NAME) && !mesh.name.includes(SUPPORT_INSIDE_MESH_NAME)
    }) as InstancedMesh[]
    const meshesOutside = meshesInside.map((mesh) => mesh.parent) as InstancedMesh[]

    meshesInside.forEach((mesh) => {
      mesh.isVisible = (mesh.parent as Mesh).isVisible

      mesh.sourceMesh.onBeforeRenderObservable.add(() => {
        this.scene.clipPlane = new Plane(0, 0, 1, -this.current)
      })
      mesh.sourceMesh.onAfterRenderObservable.add(() => {
        this.scene.clipPlane = null
      })
    })

    meshesOutside.forEach((mesh) => {
      mesh.sourceMesh.onBeforeRenderObservable.add(() => {
        this.scene.clipPlane = new Plane(0, 0, 1, -this.current)
      })
      mesh.sourceMesh.onAfterRenderObservable.add(() => {
        this.scene.clipPlane = null
      })
    })

    const supportMeshesInside = this.scene.meshes.filter(
      (mesh) => mesh.name.includes(SUPPORT_INSIDE_MESH_NAME) && mesh instanceof InstancedMesh,
    ) as InstancedMesh[]
    const supportMeshesOutside = supportMeshesInside.map((mesh) => mesh.parent) as InstancedMesh[]
    supportMeshesInside.forEach((mesh) => {
      mesh.isVisible = (mesh.parent as Mesh).isVisible

      mesh.sourceMesh.onBeforeRenderObservable.add(() => {
        this.scene.clipPlane = new Plane(0, 0, 1, -this.current)
      })
      mesh.sourceMesh.onAfterRenderObservable.add(() => {
        this.scene.clipPlane = null
      })
    })

    supportMeshesOutside.forEach((mesh) => {
      if (mesh.sourceMesh.edgesRenderer) {
        const saveRender = mesh.sourceMesh.edgesRenderer.render.bind(mesh.sourceMesh.edgesRenderer)
        mesh.sourceMesh.edgesRenderer.render = () => {
          if (this.isEnabledSlicerMode) {
            this.scene.clipPlane = new Plane(0, 0, 1, -this.current)
            saveRender()
            this.scene.clipPlane = null
          } else {
            saveRender()
          }
        }
      }
      mesh.sourceMesh.onBeforeRenderObservable.add(() => {
        this.scene.clipPlane = new Plane(0, 0, 1, -this.current)
      })
      mesh.sourceMesh.onAfterRenderObservable.add(() => {
        this.scene.clipPlane = null
      })
    })

    const labelMeshesInside = this.scene.meshes.filter((mesh) =>
      mesh.name.includes(LABEL_INSIDE_MESH_NAME),
    ) as InstancedMesh[]
    const labelMeshesOutside = labelMeshesInside.map((mesh) => mesh.parent) as InstancedMesh[]

    labelMeshesInside.forEach((mesh) => {
      if (!(mesh.parent.metadata && mesh.parent.metadata.isHidden)) {
        mesh.isVisible = true
      }

      mesh.sourceMesh.onBeforeRenderObservable.add(() => {
        this.scene.clipPlane = new Plane(0, 0, 1, -this.current)
      })
      mesh.sourceMesh.onAfterRenderObservable.add(() => {
        this.scene.clipPlane = null
      })
    })

    labelMeshesOutside.forEach((mesh) => {
      mesh.sourceMesh.onBeforeRenderObservable.add(() => {
        this.scene.clipPlane = new Plane(0, 0, 1, -this.current)
      })
      mesh.sourceMesh.onAfterRenderObservable.add(() => {
        this.scene.clipPlane = null
      })
    })

    const overhangMeshesInside = this.scene.meshes.filter((mesh) =>
      mesh.name.includes(OVERHANG_INSIDE_MESH_NAME),
    ) as InstancedMesh[]
    const overhangMeshesOutside = overhangMeshesInside.map((mesh) => mesh.parent) as InstancedMesh[]

    overhangMeshesInside.forEach((mesh) => {
      mesh.isVisible = false
      mesh.sourceMesh.onBeforeRenderObservable.add(() => {
        this.scene.clipPlane = new Plane(0, 0, 1, -this.current)
      })
      mesh.sourceMesh.onAfterRenderObservable.add(() => {
        this.scene.clipPlane = null
      })
    })

    overhangMeshesOutside.forEach((mesh) => {
      mesh.isVisible = false
      mesh.sourceMesh.onBeforeRenderObservable.add(() => {
        this.scene.clipPlane = new Plane(0, 0, 1, -this.current)
      })
      mesh.sourceMesh.onAfterRenderObservable.add(() => {
        this.scene.clipPlane = null
      })
    })
  }

  changeCurrentSlicer(current: number) {
    this.current = current
    this.renderScene.animate()
  }

  clearSlicer() {
    const meshesInside = this.scene.meshes.filter((mesh) => mesh.name.includes(INSIDE_MESH_NAME)) as InstancedMesh[]
    const meshesOutside = meshesInside.map((mesh) => mesh.parent) as InstancedMesh[]

    meshesInside.forEach((mesh) => {
      mesh.isVisible = false
      mesh.sourceMesh.onBeforeRenderObservable.clear()
      mesh.sourceMesh.onAfterRenderObservable.clear()
      this.scene.clipPlane = null
    })

    meshesOutside.forEach((mesh) => {
      mesh.sourceMesh.onBeforeRenderObservable.clear()
      mesh.sourceMesh.onAfterRenderObservable.clear()
      this.scene.clipPlane = null
    })

    const supportMeshesInside = this.scene.meshes.filter((mesh) =>
      mesh.name.includes(SUPPORT_INSIDE_MESH_NAME),
    ) as InstancedMesh[]
    const supportMeshesOutside = supportMeshesInside.map((mesh) => mesh.parent) as InstancedMesh[]

    supportMeshesInside.forEach((mesh) => {
      mesh.isVisible = false
      mesh.sourceMesh.onBeforeRenderObservable.clear()
      mesh.sourceMesh.onAfterRenderObservable.clear()
      this.scene.clipPlane = null
    })

    supportMeshesOutside.forEach((mesh) => {
      mesh.sourceMesh.onBeforeRenderObservable.clear()
      mesh.sourceMesh.onAfterRenderObservable.clear()
      this.scene.clipPlane = null
    })

    const labelMeshesInside = this.scene.meshes.filter((mesh) =>
      mesh.name.includes(LABEL_INSIDE_MESH_NAME),
    ) as InstancedMesh[]
    const labelMeshesOutside = labelMeshesInside.map((mesh) => mesh.parent) as InstancedMesh[]

    labelMeshesInside.forEach((mesh) => {
      if (!(mesh.parent.metadata && mesh.parent.metadata.isHidden)) {
        mesh.isVisible = false
      }

      mesh.sourceMesh.onBeforeRenderObservable.clear()
      mesh.sourceMesh.onAfterRenderObservable.clear()
      this.scene.clipPlane = null
    })

    labelMeshesOutside.forEach((mesh) => {
      mesh.sourceMesh.onBeforeRenderObservable.clear()
      mesh.sourceMesh.onAfterRenderObservable.clear()
      this.scene.clipPlane = null
    })

    const overhangMeshesInside = this.scene.meshes.filter((mesh) =>
      mesh.name.includes(OVERHANG_INSIDE_MESH_NAME),
    ) as InstancedMesh[]
    const overhangMeshesOutside = overhangMeshesInside.map((mesh) => mesh.parent) as InstancedMesh[]

    overhangMeshesInside.forEach((mesh) => {
      mesh.isVisible = false
      mesh.sourceMesh.onBeforeRenderObservable.clear()
      mesh.sourceMesh.onAfterRenderObservable.clear()
      this.scene.clipPlane = null
    })

    overhangMeshesOutside.forEach((mesh) => {
      mesh.isVisible = false
      mesh.sourceMesh.onBeforeRenderObservable.clear()
      mesh.sourceMesh.onAfterRenderObservable.clear()
      this.scene.clipPlane = null
    })

    this.renderScene.animate()
  }
}
