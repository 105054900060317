import { JOB_TYPE_NAME_MAP, JOB_TYPE_NAME_MAP_FOR_DASHBOARD, NOTIFICATION_TYPE_NAME_MAP } from '@/constants'
import i18n from '@/plugins/i18n'

export const toCamelCase = (str: string) => {
  const s =
    str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
      .join('')
  return s.slice(0, 1).toLowerCase() + s.slice(1)
}

export const toQueryString = (obj: any) => {
  return Object.keys(obj)
    .map((key) => `${key}=${obj[key].toString()}`)
    .join('&')
}

export const jobTypeToName = (jobType: string) => {
  // converts job names to internationalized strings
  const jobName = i18n.t(JOB_TYPE_NAME_MAP[jobType]).toString()
  return jobName
}

export const jobTypeToNameForDashboard = (jobType: string) => {
  // converts job names to internationalized strings
  const jobName = i18n.t(JOB_TYPE_NAME_MAP_FOR_DASHBOARD[jobType]).toString()
  return jobName
}

export const notificationTypeToName = (jobType: string) => {
  // converts notification types to internationalized strings
  const jobName = i18n.t(NOTIFICATION_TYPE_NAME_MAP[jobType]).toString()
  return jobName
}

export const getLastPathItems = (path: string, n: number, delimiter: string = '/') => {
  return path.split(delimiter).slice(-n).join(delimiter)
}

export const capitalizeFirstLetter = (statement: string) => {
  return statement.charAt(0).toUpperCase() + statement.slice(1)
}

// Center truncates input string. If the length of input string is greater then limit
// all extra characters are substituted by ellipsis so that total length of output is equal to the limit
export const centerTruncate = (source: string, limit: number) => {
  if (source.length <= limit) return source

  const ellipsis = '...'
  const extraLength = source.length - limit + ellipsis.length
  const halfExtra = Math.floor(extraLength / 2)
  const remainder = extraLength % 2
  const halfLength = Math.floor(source.length / 2)
  return `${source.substring(0, halfLength - halfExtra)}...${source.substring(halfLength + halfExtra + remainder)}`
}

export function encodeHtmlCharacters(unsafe: string) {
  return unsafe
    .replaceAll('&', '&amp;')
    .replaceAll('<', '&lt;')
    .replaceAll('>', '&gt;')
    .replaceAll('"', '&quot;')
    .replaceAll("'", '&#039;')
}

export function convertNewlineCharacters(message: string) {
  return message.replaceAll('\n', '<br>')
}
