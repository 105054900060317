import { IConstraints } from '@/types/BuildPlans/IConstraints'
import { ILabelStyle } from '@/types/Marking/ILabel'
import { BoundingInfo } from '@babylonjs/core/Culling'
import { Material } from '@babylonjs/core/Materials'
import { Color3, Matrix, Vector3 } from '@babylonjs/core/Maths/math'
import { Face } from '@/visualization/components/DracoDecoder'
import { BVHTreeNode } from '@/visualization/models/BVHTreeNode'
import { GeometryTypes } from '@/visualization/models/DataModel'
import { ConvexHull } from '@/visualization/rendering/ConvexHull'
import { IOrientation } from '@/visualization/rendering/LabelManager'
import { OverhangShader, PickingShader } from '@/visualization/rendering/MeshShader'
import { TransformNode } from '@babylonjs/core/Meshes'
import { PartDefect } from '@/types/Parts/IPartInsight'
import { GeometryType, PartTypes } from '@/types/BuildPlans/IBuildPlan'
import { ClearanceTypes } from '@/visualization/types/ClearanceTypes'
import { Rectangle } from '@/visualization/types/DCPTypes'

export enum SceneItemType {
  Part,
  Component,
  ComponentClone,
  OverhangSurface,
  OverhangEdge,
  OverhangVertex,
  Support,
  SupportSemitransparent,
  Label,
  RawLabel,
  LabelSemitransparent,
  LabelSemitransparentCached,
  LabelSensitiveZone,
  LabelSensitiveZoneCached,
  DefectGroup,
  Defect,
  PointDefect,
  LineDefect,
  LoopDefect,
  FaceDefect,
  OverhangEdgeTube,
  LabelCached,
  LabelOrigin,
  ClearanceSystemReference,
  Clearance,
  ClearanceSensitiveZone,
}

export interface ISceneItemMetadata {
  itemType: SceneItemType
  partType?: PartTypes
}

export interface IPartMetadata extends ISceneItemMetadata {
  partId: string
  partName: string
  buildPlanItemId: string
  documentModelId: string
  constraints: IConstraints
  initialTransformation: Matrix
  unitFactor: number
  isInstancingInProgress: boolean
  bvh: BVHTreeNode
  hull: ConvexHull
  hullBInfo: BoundingInfo
  pickingColor: Color3
  color: Color3
  failedOverhangZones: string[]
  parameterSetScaleNode: TransformNode
  isNonDefaultScaleFactor: boolean
  hasDefects: boolean
  defectType: PartDefect
}

export interface IComponentMetadata extends ISceneItemMetadata {
  // unique data for each instance of a part
  componentId: string
  originalMaterial: Material
  pickingShader: PickingShader
  pickingColor: Color3
  nonErrorMaterial: Material
  isPickingMaterial: boolean
  bodyType: GeometryType
  isHidden: boolean
  showAsTransparent: boolean
  transparentCloneId?: string

  // common data for each instance of a part
  geometryId: string
  geometryType: GeometryTypes
  faces: Face[]
  initialTransformation: Matrix
}

export interface ITransparentCloneMetadata extends ISceneItemMetadata {
  copiedInstanceId: string
  insideMeshId: string
}

export interface ILabelMetadata extends ISceneItemMetadata {
  parentId: string
  componentId: string
  geometryId: string
  buildPlanItemId: string
  orientation: IOrientation
  style: ILabelStyle
  relativeTransformation: Matrix
  isValid: boolean
  placementInvalid: boolean
  placedNotCorrectly: boolean
  sensitiveZoneId: string
  transparentCloneId: string
  isHidden: boolean
}

export interface ILabelMeshMetadata extends ISceneItemMetadata {
  patchId?: string
  componentId: string
  geometryId: string
  labelSetId: string
  buildPlanItemId: string
  isFailed: boolean
  trackId: string
}

export interface ISupportMetadata extends ISceneItemMetadata {
  belongsToOverhangElementName: string
  color: Color3
  sourceSupport: TransformNode
  isHidden: boolean
  showAsTransparent: boolean
}

export interface IDefectGroupMetadata extends ISceneItemMetadata {
  defectType: PartDefect
}

export interface IDefectMetadata extends IDefectGroupMetadata {
  defectIndex: number
}

export interface IOverhangMetadata extends ISceneItemMetadata {
  fileKey: string
  componentId: string
  geometryId: string
  faceMaterial: OverhangShader
  failedZones: string[]
  faces: Face[]
  pickingColor: Color3
  pickingShader: PickingShader
}

export interface ISystemReferenceClearanceMetadata extends ISceneItemMetadata {
  referenceType: ClearanceTypes
  referenceId: string
  rectangles?: Rectangle[]
  triangles?: Vector3[][]
}
