
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ParameterError, ValidationError } from '@/types/PrintStrategy/BuildPlanPrintStrategy'

@Component({
  components: {},
})
export default class ParameterErrorModal extends Vue {
  @Prop({ required: true, type: Boolean }) value: boolean
  @Prop({ default: () => [] }) errors: ParameterError[]

  getErrorTitleByCode(code: number): string {
    switch (code) {
      case ValidationError.MissingParameter:
        return 'Missing parameter in'
      case ValidationError.InvalidJson:
        return 'Invalid JSON format'
      case ValidationError.InvalidValue:
        return 'Invalid value found in parameter'
      case ValidationError.WrongSection:
        return 'Wrong section settings'
      case ValidationError.InvalidFileType:
        return 'Invalid file type'
      default:
        return 'Unknown error in'
    }
  }

  getErrorTitle(paramError: ParameterError): string {
    if (paramError.parameter === null || paramError.error === ValidationError.WrongSection) {
      return `${this.getErrorTitleByCode(paramError.error)}`
    }
    return `${this.getErrorTitleByCode(paramError.error)} "${paramError.parameter}"`
  }
}
