
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import VSelect from 'vuetify/lib/components/VSelect'
import { PrintStrategyStatus } from '@/types/Sites/Site'
import { ClickOutsideMixin } from '@/components/layout/mixins/ClickOutsideMixin'

const ACTIVE_STATUS_COLOR = 'green'
const INACTIVE_STATUS_COLOR = 'grey'
const DEFAULT_STATUS_COLOR = 'grey'

@Component
export default class PrintStrategyStatusToggler extends ClickOutsideMixin {
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) editable: boolean
  @Prop() placeholder: string
  @Prop() label: string
  @Prop({ type: String }) value: string
  @Prop({ type: [Object, String], default: '' }) readonly rules: object | string
  @Prop({ type: String }) prependIcon: string
  @Prop({ default: () => [] }) options: any[]

  $refs!: {
    selectField: VSelect
  }

  model: string = null
  isMounted: boolean = false

  get statuses() {
    return [
      { text: PrintStrategyStatus.Active, value: PrintStrategyStatus.Active, color: ACTIVE_STATUS_COLOR },
      { text: PrintStrategyStatus.Inactive, value: PrintStrategyStatus.Inactive, color: INACTIVE_STATUS_COLOR },
    ]
  }

  get statusColor(): string {
    const value = this.editable ? this.model : this.value
    const foundColor = this.statuses.find((status) => status.value === value)
    return foundColor ? foundColor.color : DEFAULT_STATUS_COLOR
  }

  get disableEditStatus() {
    return this.value === PrintStrategyStatus.Incomplete || this.disabled
  }

  get isOpenedMenu() {
    return (
      this.isMounted &&
      this.$refs.selectField &&
      this.$refs.selectField.$refs &&
      this.$refs.selectField.$refs.menu &&
      this.$refs.selectField.$refs.menu.isActive
    )
  }

  @Watch('isOpenedMenu')
  onIsMenuActiveChanged() {
    this.setListenerForClickOutside(this.isOpenedMenu, this.closeSelectFieldMenu)
  }

  closeSelectFieldMenu() {
    this.$refs.selectField.$refs.menu.isActive = false
  }

  beforeMount() {
    this.model = this.value ? String(this.value) : ''
  }

  mounted() {
    this.isMounted = true
  }

  updated() {
    if (!this.editable) {
      this.model = this.value
    }
  }

  edit() {
    this.$emit('edit', this.$attrs.name)
    this.focus()
  }

  confirm() {
    this.$emit('confirm', this.model)
  }

  cancel() {
    this.model = this.value
    this.$emit('cancel')
  }

  focus() {
    setTimeout(() => {
      this.$refs.selectField.focus()
    }, 0)
  }
}
