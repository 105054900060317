
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import fileExplorer from '@/api/fileExplorer'
import CadHelperRunner from '@/components/layout/CadHelperRunner.vue'
import DownloadCadHelperModal from '@/components/layout/DownloadCadHelperModal.vue'
import CadHelperLinkUpdateMixin from '@/components/layout/FileExplorer/Table/mixins/CadHelperLinkUpdateMixin'
import { EndpointsUrls } from '@/configs/config'
import { CadHelperProcess, ROOT_FOLDER_ID } from '@/constants'
import { RouterNames } from '@/router'
import CommunicationService from '@/services/CommunicationService'
import StoresNamespaces from '@/store/namespaces'
import { CadHelperLink, CadHelperLinkParameters } from '@/types/CadHelper/CadHelperLink'
import { BrokerEvents } from '@/types/Common/BrokerEvents'
import { BrokerMessage } from '@/types/Common/BrokerMessage'
import { ISocketError } from '@/types/Common/ISocketError'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { IUser } from '@/types/User/IUser'

const partsStore = namespace(StoresNamespaces.Parts)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const userStore = namespace(StoresNamespaces.User)
const cadHelperStore = namespace(StoresNamespaces.CadHelper)

@Component({
  components: {
    CadHelperRunner,
    DownloadCadHelperModal,
  },
})
export default class UploadPart extends Mixins(CadHelperLinkUpdateMixin) {
  @partsStore.Action getPartToken: () => Promise<any>
  @partsStore.Action actualizeParts: (items?: FileExplorerItem[]) => Promise<void>

  @fileExplorerStore.Getter getRootItem: FileExplorerItem
  @fileExplorerStore.Action getGetRunningAndFailedJobsByItemIds: (itemIds: string[]) => Promise<any>
  @fileExplorerStore.Mutation addNewItem: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation setLastAddedItem: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation unselectAllItems: () => void

  @userStore.Getter('getUserDetails') getUser: IUser

  @cadHelperStore.Getter('getVersion') getVersionOfCadHelper: string
  @cadHelperStore.Getter('getDownloadLink') getDownloadLinkOfCadHelper: string

  @Prop() onClose: () => void
  @Prop() onMessage: (brokerMessage: BrokerMessage) => void
  @Prop() onError: (error: ISocketError) => void
  @Prop() onConnected: () => void

  downloadCadHelperDialogVisible: boolean = false
  partToken: string = null
  sessionId: string = null
  root: string = null
  connector: CommunicationService = null

  get cadHelperLink() {
    const command = CadHelperProcess.UploadModels
    const apiUrl = window.env.VUE_APP_API_URL
    const signedUrl = `${apiUrl}/${EndpointsUrls.PartsV1}/importToProject`
    const downloadUrl = this.getDownloadLinkOfCadHelper
    const token = this.partToken
    const cadHelperVersion = this.getVersionOfCadHelper
    const sessionId = this.sessionId
    const folder = this.getRootItem
    const parentId = folder ? folder.id : null
    const tenant = this.getUser ? this.getUser.tenant : null

    // @ts-ignore
    const parameters: CadHelperLinkParameters = {
      command,
      signedUrl,
      downloadUrl,
      token,
      cadHelperVersion,
      sessionId,
      name,
      parentId,
      tenant,
    }

    return new CadHelperLink(parameters)
  }

  get isUploadPartEnabled() {
    return !!this.partToken
  }

  async onSocketMessage(brokerMessage: BrokerMessage) {
    switch (brokerMessage.event) {
      case BrokerEvents.PartSent:
        const { parentId, parentFolderId } = brokerMessage.message as FileExplorerItem
        const isFolderAvailable = [parentId, parentFolderId].includes(this.root)
        const hasAccess = isFolderAvailable ? true : await this.checkFolderAccess(this.root)
        const isSameFolder = this.getRootItem && this.getRootItem.id === this.root

        if (isSameFolder && !hasAccess) {
          this.unselectAllItems()
          const route = {
            name: RouterNames.FE_AllFiles,
            params: {
              itemId: ROOT_FOLDER_ID,
            },
          }
          // @ts-ignore
          await this.$router.safePush(route)
        }

        this.addNewItem(brokerMessage.message)
        this.setLastAddedItem(brokerMessage.message)
        this.getGetRunningAndFailedJobsByItemIds([brokerMessage.message.id])
        this.actualizeParts([brokerMessage.message])
        break
      case BrokerEvents.CadHelperStarted:
        this.onCadHelperStarted()
        break
    }

    if (this.onMessage) {
      this.onMessage(brokerMessage)
    }
  }

  onSocketError(socketError: ISocketError) {
    if (this.onError) {
      this.onError(socketError)
    }
  }

  onCadHelperStarted() {
    this.closeDownloadCadHelperDialog()
  }

  onCadHelperTimeOut() {
    this.openDownloadCadHelperDialog()
  }

  openDownloadCadHelperDialog() {
    this.downloadCadHelperDialogVisible = true
  }

  closeDownloadCadHelperDialog() {
    this.downloadCadHelperDialogVisible = false
  }

  async beforeMount() {
    const { token } = await this.getPartToken()

    this.connector = CommunicationService.getConnector()
    this.connector.subscribe(BrokerEvents.PartSent, this.onSocketMessage)

    this.sessionId = this.getUser.id
    this.partToken = token
    this.root = this.$route.params.itemId === '0' ? null : this.$route.params.itemId
    this.fetchLinks()
  }

  async checkFolderAccess(folderId: string): Promise<boolean> {
    try {
      const item = await fileExplorer.getItemById(folderId)
      return Boolean(item)
    } catch (error) {
      return false
    }
  }

  beforeDestroy() {
    if (this.connector) {
      this.connector.unsubscribe(BrokerEvents.PartSent, this.onSocketMessage)
    }
  }
}
