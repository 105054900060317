
import StoresNamespaces from '@/store/namespaces'
import { TranslateResult } from 'vue-i18n'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { LabelTooltipMixin } from '@/components/layout/buildPlans/marking/mixins/LabelTooltipMixin'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { GridLetterDirection, GridLetterLocation, TextElement } from '@/types/Label/TextElement'

const labelStore = namespace(StoresNamespaces.Labels)

@Component
export default class GridLetterTooltip extends Mixins(LabelTooltipMixin) {
  @labelStore.Getter labelSets: InteractiveLabelSet[]

  @Prop() tooltipElement: TextElement

  get direction(): string {
    return (
      this.tooltipElement &&
      this.getDirection(JSON.parse(this.tooltipElement._cachedSpecificsJSON).direction) &&
      this.getDirection(JSON.parse(this.tooltipElement._cachedSpecificsJSON).direction).toString()
    )
  }

  get boundingBox(): string {
    return (
      this.tooltipElement &&
      this.getLocation(JSON.parse(this.tooltipElement._cachedSpecificsJSON).location) &&
      this.getLocation(JSON.parse(this.tooltipElement._cachedSpecificsJSON).location).toString()
    )
  }

  get offset(): string {
    return this.tooltipElement && JSON.parse(this.tooltipElement._cachedSpecificsJSON).offset
  }

  get spacing(): string {
    return this.tooltipElement && JSON.parse(this.tooltipElement._cachedSpecificsJSON).spacing
  }

  getLocation(location: GridLetterLocation): TranslateResult {
    switch (location) {
      case GridLetterLocation.Center:
        return this.$t('labelTool.gridLetterSettings.center')
      case GridLetterLocation.MaximumCorner:
        return this.$t('labelTool.gridLetterSettings.maximumCorner')
      case GridLetterLocation.MinimumCorner:
        return this.$t('labelTool.gridLetterSettings.minimumCorner')
    }
  }

  getDirection(direction: GridLetterDirection): TranslateResult {
    switch (direction) {
      case GridLetterDirection.XAxis:
        return this.$t('labelTool.gridLetterSettings.xAxis')
      case GridLetterDirection.YAxis:
        return this.$t('labelTool.gridLetterSettings.yAxis')
      case GridLetterDirection.ZAxis:
        return this.$t('labelTool.gridLetterSettings.zAxis')
    }
  }
}
