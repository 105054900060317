import { EndpointsUrls } from '@/configs/config'
import { get } from '@/api/http'
import { IBuildPlate, IBuildPlateMachineConfig, IBuildPlateMaterial } from '@/types/BuildPlates/IBuildPlate'
import { handleAPIError } from '@/api/common'
import { VersionablePk } from '@/types/Common/VersionablePk'

export default {
  async getBuildPlates() {
    try {
      return await get<IBuildPlate[]>(`${EndpointsUrls.BuildPlates}`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getDefaultBuildPlate(machineConfigPk?: VersionablePk) {
    try {
      return await get<IBuildPlate>(`${EndpointsUrls.BuildPlates}/default`, {
        params: {
          machineConfigId: machineConfigPk && machineConfigPk.id,
          machineConfigVersion: machineConfigPk && machineConfigPk.version,
        },
      })
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getBuildPlateByPk(buildPlatePk: VersionablePk, machineConfigPk?: VersionablePk) {
    try {
      return await get<IBuildPlate>(`${EndpointsUrls.BuildPlates}/${buildPlatePk.id}`, {
        params: {
          buildPlateVersion: buildPlatePk.version,
          machineConfigId: machineConfigPk && machineConfigPk.id,
          machineConfigVersion: machineConfigPk && machineConfigPk.version,
        },
      })
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getBuildPlateMaterials() {
    try {
      return await get<IBuildPlateMaterial[]>(`${EndpointsUrls.BuildPlateMaterials}`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getBuildPlateMachineConfigs() {
    try {
      return await get<IBuildPlateMachineConfig[]>(`${EndpointsUrls.BuildPlateMachineConfigs}`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },
}
