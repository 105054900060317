
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'

import { ProcessParameterFormViewModel } from '@/components/layout/admin/printStrategy/parameterEditor/ProcessParameterFormViewModel'
import {
  H2ProcessParameterFormViewModel,
  H3ProcessParameterFormViewModel,
  ProcessParameterViewModelTemplate,
} from '@/components/layout/admin/printStrategy/parameterEditor/ProcessParameterViewModelTemplate'
import {
  edgePixelLevelLimits,
  ProcessParameterFormValidation,
  ProcessParameterValidationRules,
  solidPixelLevelLimits,
} from '@/components/layout/admin/printStrategy/parameterEditor/processParameterValidation'
import TextField from '@/components/controls/Common/TextField.vue'
import NumberField from '@/components/controls/Common/NumberField.vue'
import { IParameterFormComponent } from '@/types/Sites/IParameterFormComponent'
import { IProductionSet } from '@/types/BuildPlans/IBuildPlan'
import { IBinderJetH2ProcessParameter, IBinderJetH3ProcessParameter } from '@/types/Sites/BinderJetProcessParameter'
import {
  H2_MACHINE_CONFIG_NAME,
  H3_ALPHA_MACHINE_CONFIG_NAME,
  H3_BETA_MACHINE_CONFIG_NAME,
  LENGTH_MM_DECIMAL_PLACES,
  PERCENTAGE_DECIMAL_PLACES,
  TEMPERATURE_CELSIUS_DEGREE_DECIMAL_PLACES,
} from '@/constants'
import { AmpUnit } from '@/types/Common/AmpUnit'
import SliderWithValidation from './parameterEditor/partParameterComponents/SliderWithValidation.vue'
import { isNil } from '@/utils/common'

@Component({
  components: {
    NumberField,
    TextField,
    SliderWithValidation,
  },
})
export default class ProcessParameterForm extends Vue implements IParameterFormComponent {
  @Prop({ required: true, type: Object }) parameter: IProductionSet
  @Prop({ required: true }) machineConfigName: string
  @Prop({ required: true, type: Boolean }) readonly: boolean

  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  vm: ProcessParameterFormViewModel = null
  validationRules: ProcessParameterValidationRules = null
  originalVm: ProcessParameterFormViewModel = null

  LENGTH_MM_DECIMAL_PLACES: number
  PERCENTAGE_DECIMAL_PLACES: number
  TEMPERATURE_CELSIUS_DEGREE_DECIMAL_PLACES: number
  MILLIMETER_UNIT = AmpUnit.Millimeter

  solidPixelLevelTickLabels = ['1', '2', '3']
  edgePixelLevelTickLabels = ['0', '1', '2', '3']

  get edgePixelLevelLimits() {
    return edgePixelLevelLimits
  }

  get solidPixelLevelLimits() {
    return solidPixelLevelLimits
  }

  get isValid() {
    const { failed } = this.$refs.form.flags
    return !failed
  }

  get isXyLengthAvailable(): boolean {
    const { attenXPlus, attenXMinus, attenYPlus, attenYMinus } = this.vm
    return attenXPlus || attenXMinus || attenYPlus || attenYMinus
  }

  get xYLengthValidationRule(): object {
    return {
      ...this.validationRules.attenXYLength,
      required: this.isXyLengthAvailable,
    }
  }

  get isZLengthAvailable(): boolean {
    const { attenZPlus, attenZMinus } = this.vm
    return attenZPlus || attenZMinus
  }

  get zLengthValidationRule(): object {
    return {
      ...this.validationRules.attenZLength,
      required: this.isZLengthAvailable,
    }
  }

  get isConstrainThicknessPercentageAvailable(): boolean {
    return this.vm.constrainThickness
  }

  get constrainThicknessPercentageValidationRule(): object {
    return {
      ...this.validationRules.constrainThicknessPerc,
      required: this.isConstrainThicknessPercentageAvailable,
    }
  }

  get isShiftYSizeAvailable(): boolean {
    return this.vm.shiftY
  }

  get shiftYSizeValidationRule(): object {
    return {
      ...this.validationRules.shiftYSize,
      required: this.isShiftYSizeAvailable,
    }
  }

  get isH3Machine(): boolean {
    return this.machineConfigName !== H2_MACHINE_CONFIG_NAME
  }

  get purgeFlowPrintheadSetPointLabel(): string {
    return this.isH3Machine
      ? this.$t('printHeadIRLampPurgeSetPoint').toString()
      : this.$t('purgeFlowSetPoint').toString()
  }

  get purgeFlowRecoatSetPointLabel(): string {
    return this.isH3Machine
      ? this.$t('recoatIRLampPurgeSetPoint').toString()
      : this.$t('purgeFlowRecoatSetPoint').toString()
  }

  get purgeFlowMotionAndPrintheadPressurizationSetPointLabel(): string {
    return this.isH3Machine
      ? this.$t('motionPrintHeadPurgeSetPoint').toString()
      : this.$t('purgeFlowMotionAndPrintheadPressurizationSetPoint').toString()
  }

  get purgeFlowHMBCoolingSetPointLabel(): string {
    return this.isH3Machine
      ? this.$t('hmbCoolingFlowSetPoint').toString()
      : this.$t('purgeFlowHMBCoolingSetPoint').toString()
  }

  get rollerSpeedRecoatLevelingDepartLabel(): string {
    return this.isH3Machine
      ? this.$t('rollerSpeedRecoatDepart').toString()
      : this.$t('rollerSpeedRecoaterLevelingDepart').toString()
  }

  get rollerSpeedRecoatLevelingReturnLabel(): string {
    return this.isH3Machine
      ? this.$t('rollerSpeedRecoatReturn').toString()
      : this.$t('rollerSpeedRecoaterLevelingReturn').toString()
  }

  @Watch('parameter')
  onParameterChanged() {
    this.initForm({ shouldSaveOriginalState: false })
  }

  created() {
    this.setConstants()
    this.initForm()
    this.validationRules = ProcessParameterFormValidation.getValidationRules(this.machineConfigName)
  }

  setConstants() {
    this.LENGTH_MM_DECIMAL_PLACES = LENGTH_MM_DECIMAL_PLACES
    this.PERCENTAGE_DECIMAL_PLACES = PERCENTAGE_DECIMAL_PLACES
    this.TEMPERATURE_CELSIUS_DEGREE_DECIMAL_PLACES = TEMPERATURE_CELSIUS_DEGREE_DECIMAL_PLACES
  }

  initForm(options: { shouldSaveOriginalState: boolean } = { shouldSaveOriginalState: true }) {
    const formVmTemplate: ProcessParameterViewModelTemplate = this.getFormViewModelTemplate(this.machineConfigName)

    if (!formVmTemplate) {
      throw new Error(`The form is not compatible with the selected machine: ${this.machineConfigName}`)
    }

    this.vm = formVmTemplate.init()

    // In cases where it is necessary to save the original view model, and not imported from JSON file.
    if (options.shouldSaveOriginalState) {
      this.originalVm = { ...this.vm }
    }
  }

  getFormViewModelTemplate(machineConfigName: string) {
    const { processParameters } = this.parameter
    let template: ProcessParameterViewModelTemplate

    switch (machineConfigName) {
      case H2_MACHINE_CONFIG_NAME:
        template = new H2ProcessParameterFormViewModel(processParameters as IBinderJetH2ProcessParameter)
        break
      case H3_ALPHA_MACHINE_CONFIG_NAME:
      case H3_BETA_MACHINE_CONFIG_NAME:
        template = new H3ProcessParameterFormViewModel(processParameters as IBinderJetH3ProcessParameter)
        break
    }

    return template
  }

  getPurgeTimeSuffix() {
    let suffix = ''

    switch (this.machineConfigName) {
      case H2_MACHINE_CONFIG_NAME:
        suffix = 's'
        break
      case H3_ALPHA_MACHINE_CONFIG_NAME:
      case H3_BETA_MACHINE_CONFIG_NAME:
        suffix = 'ms'
        break
    }

    return suffix
  }

  mounted() {
    this.$watch('isValid', this.onFormValidationStateChanged)
  }

  onFormValidationStateChanged(isValid: boolean) {
    this.$emit('valid', isValid)
  }

  isFormValid(options: { silent: boolean } = { silent: false }) {
    return this.$refs.form.validate({ silent: options.silent })
  }

  isFormChanged(): boolean {
    // TODO && !this.readonly after "The parameter contains unsaved changes." modal issue is fixed
    // the issue is related to newly created SliderWithValidation component
    // which updates form.flags.changed even without user input
    return this.$refs.form.flags.changed && !this.readonly
  }

  getFormData() {
    return { ...this.vm }
  }

  reset() {
    this.vm = { ...this.originalVm }
    this.$nextTick(() => {
      this.$refs.form.reset()
    })
  }

  save() {
    this.originalVm = { ...this.vm }
    this.$nextTick(() => {
      this.$refs.form.reset()
    })
  }

  getNumberAttributes(validationRule: object) {
    return {
      isInteger: this.isInteger(validationRule),
      floatLimit: this.getFloatLimit(validationRule),
      setNullForEmptyFiled: true,
      sanitizeInput: true,
    }
  }

  getFloatLimit(validationRule: { between?: { digits?: number } }): number {
    const { between } = validationRule
    if (isNil(between)) {
      return undefined
    }

    const { digits } = between
    if (isNil(digits)) {
      return undefined
    }

    return digits
  }

  isInteger(validationRule: object) {
    return this.getFloatLimit(validationRule) === 0
  }
}
