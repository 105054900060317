/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { AbstractMesh, Geometry, InstancedMesh, Mesh, TransformNode, VertexData } from '@babylonjs/core/Meshes'
import { Buffer, VertexBuffer } from '@babylonjs/core/Buffers'
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder'
import { Axis, Color3, Epsilon, Matrix, Plane, Quaternion, Space, Vector2, Vector3 } from '@babylonjs/core/Maths'
import { IDisposable, Scene } from '@babylonjs/core/scene'
import { Camera } from '@babylonjs/core/Cameras/camera'
import {
  AssemblyComponent,
  DocumentModel,
  GeometryTypes,
  IComponent,
  PartComponent,
} from '@/visualization/models/DataModel'
import { ModelItem } from '@/visualization/models/BabylonData'
import { VisualizationEvent } from '@/visualization/infrastructure/IVisualizationEvent'
import {
  BuildPlanItemOverhang,
  BuildPlanItemSupport,
  GeometryType,
  IBuildPlan,
  IBuildPlanItem,
  IGeometryProperties,
  IMeshGeometryProperties,
  IPart,
  IPrintStrategyParameterSet,
  IUpdateBuildPlanItemParamsDto,
  PartTypes,
  ProcessState,
  Visibility,
} from '@/types/BuildPlans/IBuildPlan'
import { SceneMode } from '@/visualization/types/SceneTypes'
import partsService from '@/api/parts'
import jobsService from '@/api/jobs'
import { BuildPlateManager } from '@/visualization/rendering/BuildPlateManager'
import { SelectionManager } from '@/visualization/rendering/SelectionManager'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import {
  BUILD_CHAMBER_POLYLINES_NAME,
  COLLECTOR_INSTANCE_ID,
  COLOR_FOR_BODY,
  COLOR_FOR_FACE,
  COLOR_FOR_PART,
  DEFAULT_COLOR,
  DEFAULT_MATERIAL_NAME,
  DEFAULT_MAX_DISTANCE_TO_BUILD_PLATE,
  DEFAULT_TRANSFORMATION_MATRIX,
  FACE_ID_ATTRIBUTE,
  H2_MACHINE_CONFIG_NAME,
  HOVER_FACE_ID,
  IDENTITY_MATRIX,
  IGNORED_MESH_NAMES,
  INSIDE_MESH_NAME,
  INSPECTION_MATERIAL_NAME,
  LABEL_MESH,
  LINE_SUPPORT,
  M_TO_MM,
  MAX_CONCURRENT_REQUESTS,
  MAX_SAFE_DOSING_HEIGHT_MM,
  MESH_INSIDE_MATERIAL_NAME,
  MESH_RENDERING_GROUP_ID,
  MIN,
  MOCK_PART_MESH_NAME,
  MouseButtons,
  OVERHANG_CONTOUR_NAME,
  OVERHANG_EDGES_NAME,
  OVERHANG_NAME,
  OVERHANG_TRIANGLE_NAME,
  OVERHANG_VERTICES_NAME,
  PARAMETER_SET_SCALE_NAME,
  PART_BODY_ID_DELIMITER,
  PART_CONFIG_LOADING_MUTATION_NAME,
  PART_SUPPORT_BODY_CONSTRAINTS,
  SHADER_HIGHLIGHT_MATERIAL_NAME,
  SHADER_SELECTION_MATERIAL_NAME,
  SINTER_PART_CONSTRAINTS,
  SUPPORT_INSIDE_MESH_NAME,
  BVH_BOX,
  FETCH_PART_BY_ID_ACTION_NAME,
  REMOVE_PART_BY_ID_MUTATION_NAME,
} from '@/constants'
import store from '@/store'
import { DracoDecoder, Face, FaceAttribute, FacePositionAttributes } from '@/visualization/components/DracoDecoder'
import { IRenderable } from '@/visualization/types/IRenderable'
import messageService from '../../services/messageService'
import { ILabel, ILabelStyle } from '@/types/Marking/ILabel'
import { Sdata, SupportManager } from '@/visualization/rendering/SupportsManager'
import { LabelManager } from '@/visualization/rendering/LabelManager'
import { IActiveToggle } from '@/visualization/infrastructure/IActiveToggle'
import { createGuid, promiseAllLimit } from '@/utils/common'
import { ConvexHull } from '@/visualization/rendering/ConvexHull'
import { IConstraints } from '@/types/BuildPlans/IConstraints'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { InstanceLabel } from '@/visualization/types/InstanceLabel'
import { RenderScene } from '@/visualization/render-scene'
import { DuplicateManager } from '@/visualization/rendering/DuplicateManager'
import { DuplicateMode, DuplicatePayload } from '@/types/Duplicate/Duplicate'
import { IComponentMetadata, IPartMetadata, SceneItemType } from '@/visualization/types/SceneItemMetadata'
import { IBuildPlanInsight, InsightErrorCodes, IPendingInsights } from '@/types/BuildPlans/IBuildPlanInsight'
import { InsightsSeverity } from '@/types/Common/Insights'
import { BVHTreeNode } from '@/visualization/models/BVHTreeNode'
import i18n from '@/plugins/i18n'
import { v4 as uuid } from 'uuid'
import { OverhangManager } from '@/visualization/rendering/OverhangManager'
import { FaceColoringShader, FacetColoringShader, HoverableFace } from './MeshShader'
import { DefectShapeType, IHighlightDefectPayload, ISelectableDefectPayload } from '@/types/Parts/IPartInsight'
import { PrintingTypes } from '@/types/IMachineConfig'
import { IPartRenderable } from '@/types/Parts/IPartRenderable'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { InsightsManager } from './InsightsManager'
import { debounce } from '@/utils/debounce'
import { DefectsManager } from './DefectsManager'
import { PointerEventTypes, PointerInfo } from '@babylonjs/core/Events'
import { Observer } from '@babylonjs/core/Misc/observable'
import { Animation } from '@babylonjs/core/Animations/animation'
import '@babylonjs/core/Animations/animatable'
import { Material, StandardMaterial } from '@babylonjs/core/Materials'
import { convertPixelToMillimeter, equalWithTolerance } from '@/utils/number'
import { OuterEvents } from '../types/Common'
import { LoadBuildPlanOptions } from '@/visualization/types/LoadBuildPlanOptions'
import { BuildPlanPrintStrategyDto } from '../../types/PrintStrategy/BuildPlanPrintStrategy'
import { getDefaultBaseOnType } from '../../utils/parameterSet/parameterSetUtils'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { BoundingBox, BoundingInfo } from '@babylonjs/core/Culling'
import { Checker } from '@/validators/Checker'
import { SnackbarMessageType } from '@/types/messages/SnackbarMessageType'
import { Placement } from '@/types/Label/Placement'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'
import cloneDeep from 'lodash/cloneDeep'
import buildPlanItems from '@/api/buildPlanItems'
import { eventBus } from '@/services/EventBus'
import { BuildPlanEvents } from '@/types/Label/BuildPlanEvents'
import { LabeledBodyWIthTransformation } from '@/types/Label/LabeledBodyWIthTransformation'

export interface IVertexInfo {
  origin: Vector3
  name: string
  id: number
  color: Color3
}

export interface IEdgeInfo {
  name: string
  id: number
  color: Color3
  path: Vector3[]
}

interface IBaseModelConfig {
  model: DocumentModel
  part?: IPart | IPartDto
  processState?: ProcessState
  hiddenBodies?: string[]
  buildPlanItemId?: string
  partId: string
  partName: string
  partPreviewId?: string
  transformation?: number[]
  loadingPartIndex?: number
  labels?: ILabel[]
  overhangs?: BuildPlanItemOverhang
  supports?: BuildPlanItemSupport[]
  supportsBvhFileKey?: string
  supportsHullFileKey?: string
  constraints?: IConstraints
  shouldAdjustCamera?: boolean
  maxDistanceFromPlate?: number
  parameterSetScaleFactor?: number[]
  partType?: PartTypes
  visibility?: Visibility
}

interface IModelConfig extends IBaseModelConfig {
  geometryTypes?: Map<string, GeometryType>
}

interface IIBCModelConfig extends IBaseModelConfig {
  geometryType?: GeometryType
}

interface IRenderConfig {
  component: IComponent
  parts: ModelItem[]
  partId: string
  partName: string
  buildPlanItemId?: string
  parentMesh?: TransformNode
  hiddenBodies?: string[]
  visibility?: Visibility
}

class LoadingPart {
  partId: string
  partName: string
  loadingPartIndex: number
  dragDrop: boolean
  isConfigCorrect: boolean
  isPartRemoved: boolean
  isPartFitsBuildPlate: boolean
  isAddedToBuildPlan: boolean
  isAddEventTriggered: boolean
  isSaved: boolean
  mesh: TransformNode
  initPosition: Vector3
  config: DocumentModel

  notifyConfigLoaded: Function
  waitConfigLoaded = (() => {
    const initPromise = new Promise((resolve) => {
      this.notifyConfigLoaded = resolve
    })
    return () => initPromise
  })()

  notifyPartManagedByScene: Function
  waitPartManagedByScene = (() => {
    const initPromise = new Promise((resolve) => {
      this.notifyPartManagedByScene = resolve
    })
    return () => initPromise
  })()

  notifyAddedToBuildPlan: Function
  waitAddedToBuildPlan = (() => {
    const initPromise = new Promise((resolve) => {
      this.notifyAddedToBuildPlan = resolve
    })
    return () => initPromise
  })()

  constructor(
    partId: string,
    partName: string,
    loadingPartIndex: number,
    dragDrop: boolean,
    mesh: AbstractMesh,
    initPosition: Vector3,
  ) {
    this.partId = partId
    this.partName = partName
    this.loadingPartIndex = loadingPartIndex
    this.dragDrop = dragDrop
    this.isConfigCorrect = false
    this.isPartRemoved = false
    this.isPartFitsBuildPlate = false
    this.isSaved = false
    this.isAddEventTriggered = false
    this.isAddedToBuildPlan = false
    this.mesh = mesh
    this.initPosition = initPosition
  }
}

interface IDocument {
  model: DocumentModel
  parts: ModelItem[]
}

export interface ILoadedDocument {
  document: DocumentModel
  partId: string
}

interface IModelCache {
  document: DocumentModel
  geometryProperties: IMeshGeometryProperties
}

export class ModelManager implements IDisposable {
  private readonly onConfigLoaded = new VisualizationEvent<{
    partId: string
    partName: string
    loadingPartIndex: number
    transformation: number[]
    isSuccess: boolean
    constraints: IConstraints
  }>()

  private readonly onConfigsLoaded = new VisualizationEvent<
    Array<{
      partConfig: {
        partId: string
        partName: string
        loadingPartIndex: number
        transformation: number[]
        isSuccess: boolean
        constraints: IConstraints
        visibility: boolean
      }
      targetBuildPlanItemId: string
    }>
  >()

  private readonly onAddGeometryProperties = new VisualizationEvent<{
    buildPlanItemId: string
    geometryProperties: IGeometryProperties
    updateStateOnly?: boolean
    takeIntoAccountRequestsQueue?: boolean
  }>()
  private readonly onDeletePartsInState = new VisualizationEvent<string[]>()
  private readonly onDownwardPlaneRotationStarted = new VisualizationEvent<void>()
  private readonly onDownwardPlaneRotationEnded = new VisualizationEvent<{
    isSheetBody: boolean
    flipArrowLocation: { x: number; y: number }
  }>()
  private pathDelimiter: string = '>'
  private delimiter: string = '-'
  private renderScene: IRenderable
  private scene: Scene
  private documents: Map<string, IDocument> = new Map<string, IDocument>()
  private canvas: HTMLCanvasElement
  private camera: Camera
  private selectionManager: SelectionManager
  private buildPlateManager: BuildPlateManager
  private overhangManager: OverhangManager
  private supportManager: SupportManager
  private labelManager: LabelManager
  private defectsManager: DefectsManager
  private meshManager: MeshManager
  private duplicateManager: DuplicateManager
  private insightsManager: InsightsManager
  private loadingParts: LoadingPart[] = []
  private loadedDocuments: ILoadedDocument[] = []
  private subType: ItemSubType = ItemSubType.None

  private lastRequestTokenObj: object = {}

  private loadingPartPointerX: number = MIN
  private loadingPartPointerY: number = MIN

  private bvhCache: Map<string, BVHTreeNode[]> = new Map<string, BVHTreeNode[]>()
  private meshIdCache: Map<string, string> = new Map<string, string>()
  private hullCache: Map<string, ConvexHull> = new Map<string, ConvexHull>()

  private isDisposed: boolean
  private downwardPlaneRotationPointerEvents: Observer<PointerInfo>
  private onAfterDownwardRotation: (isSheetBody: boolean) => void
  private checker: Checker

  constructor(renderScene: IRenderable, canvas: HTMLCanvasElement, dragListeners?: IActiveToggle[]) {
    this.renderScene = renderScene
    this.canvas = canvas
    this.scene = renderScene.getScene()
    this.meshManager = renderScene.getMeshManager()
    this.supportManager = new SupportManager(renderScene)
    this.labelManager = new LabelManager(renderScene, this, dragListeners)
    this.defectsManager = new DefectsManager(renderScene)
    this.duplicateManager = new DuplicateManager(renderScene, this)
    this.insightsManager = renderScene.getInsightsManager()

    this.scene.onNewMeshAddedObservable.add((mesh: AbstractMesh) => {
      this.meshIdCache.set(mesh.id, mesh.id)
    })

    this.scene.onMeshRemovedObservable.add((mesh: AbstractMesh) => {
      this.meshIdCache.delete(mesh.id)
    })
  }

  get configLoadedEvent() {
    return this.onConfigLoaded.expose()
  }

  get configsLoadedEvent() {
    return this.onConfigsLoaded.expose()
  }

  get addGeometryProperties() {
    return this.onAddGeometryProperties.expose()
  }

  get labelAdded() {
    return this.labelManager ? this.labelManager.labelAdded : null
  }

  get labelUpdated() {
    return this.labelManager ? this.labelManager.labelUpdated : null
  }

  get labelPlaced() {
    return this.labelManager ? this.labelManager.labelPlaced : null
  }

  get labelOrientationSelected() {
    return this.labelManager ? this.labelManager.labelOrientationSelected : null
  }

  get labelOrientationChanged() {
    return this.labelManager ? this.labelManager.labelOrientationChanged : null
  }

  get deletePartsInState() {
    return this.onDeletePartsInState.expose()
  }

  get downwardPlaneRotationStarted() {
    return this.onDownwardPlaneRotationStarted.expose()
  }

  get downwardPlaneRotationEnded() {
    return this.onDownwardPlaneRotationEnded.expose()
  }

  set setScene(newScene: Scene) {
    this.scene = newScene
  }

  get setInstancingIsRunning() {
    return this.duplicateManager.setInstancingIsRunning
  }

  get labelMgr() {
    return this.labelManager
  }

  get overhangMgr() {
    return this.overhangManager
  }

  get supportMgr() {
    return this.supportManager
  }

  get defectsMgr() {
    return this.defectsManager
  }

  get duplicateMgr() {
    return this.duplicateManager
  }

  getLabelManager() {
    return this.labelManager
  }

  getLoadedDocuments() {
    return this.loadedDocuments
  }

  getDocuments() {
    return this.documents
  }

  getBvhCache() {
    return this.bvhCache
  }

  getHullCache() {
    return this.hullCache
  }

  /** Divide componentId and geometryId from bodyId through PART_BODY_ID_DELIMITER. */
  getBodyIdsInfo(bodyId: string): { componentId: string; geometryId: string } {
    const separatorIndex = bodyId.indexOf(PART_BODY_ID_DELIMITER)
    const componentId: string = bodyId.slice(0, separatorIndex)
    const geometryId: string = bodyId.slice(separatorIndex + 1)

    return { componentId, geometryId }
  }

  init() {
    this.camera = this.renderScene.getActiveCamera()
    this.buildPlateManager = this.renderScene.getBuildPlateManager()
    this.selectionManager = this.renderScene.getSelectionManager()
    if (
      this.renderScene.getSceneMode() === SceneMode.BuildPlan ||
      this.renderScene.getSceneMode() === SceneMode.PreviewPrintOrder
    ) {
      this.overhangManager = new OverhangManager(this.renderScene as RenderScene)
    }
  }

  async loadPartConfig(partRenderable: IPartRenderable) {
    store.commit(PART_CONFIG_LOADING_MUTATION_NAME, true, { root: true })

    // Create and show mock part mesh while config is not loaded and rendered
    let currentRequestTokenObj = null
    let index = null
    if (partRenderable.loadingPartIndex !== undefined) {
      const mesh = MeshBuilder.CreateSphere(MOCK_PART_MESH_NAME, { diameter: 10 }, this.scene)
      const material = this.scene.getMaterialByID('tempMeshMaterial')
      mesh.material = material
      mesh.isVisible = false
      const meshBBox = mesh.getBoundingInfo().boundingBox
      const partOffset: Vector3 = Vector3.Zero().subtract(
        new Vector3(
          (meshBBox.maximumWorld.x + meshBBox.minimumWorld.x) / 2,
          (meshBBox.maximumWorld.y + meshBBox.minimumWorld.y) / 2,
          meshBBox.minimumWorld.z,
        ),
      )
      mesh.setAbsolutePosition(mesh.absolutePosition.add(partOffset))

      const loadingPart = new LoadingPart(
        partRenderable.partId,
        partRenderable.partName,
        partRenderable.loadingPartIndex,
        partRenderable.dragDrop,
        mesh,
        mesh.position.clone(),
      )
      this.loadingParts.push(loadingPart)
      index = this.loadingParts.indexOf(loadingPart)
    } else {
      currentRequestTokenObj = {}
      this.lastRequestTokenObj = currentRequestTokenObj
    }

    try {
      // Temporal decision until ibc parts permissions will be enabled
      const part = await store.dispatch(FETCH_PART_BY_ID_ACTION_NAME, { id: partRenderable.partId })

      if (part.isRemoved) {
        store.commit(REMOVE_PART_BY_ID_MUTATION_NAME, part.id, { root: true })

        if (index !== null) {
          this.loadingParts[index].isPartRemoved = true
          this.loadingParts[index].config = null
        }

        throw new Error('Part no longer exists')
      }

      const model = await partsService.getPartConfigFile(partRenderable.partId)

      // if there is newer requst do not show current model
      if (currentRequestTokenObj && currentRequestTokenObj !== this.lastRequestTokenObj) {
        return
      }

      if (index !== null) {
        this.loadingParts[index].config = model
      }

      if (model === null) {
        throw new Error('Config is null')
      }

      const config: IModelConfig = {
        model,
        part,
        hiddenBodies: partRenderable.hiddenBodies,
        partId: partRenderable.partId,
        partName: partRenderable.partName,
        partType: partRenderable.partType,
        geometryTypes: partRenderable.geometryTypes,
        loadingPartIndex: index !== null ? this.loadingParts[index].loadingPartIndex : undefined,
      }

      if (this.subType === ItemSubType.SinterPlan) {
        config.transformation = DEFAULT_TRANSFORMATION_MATRIX
        config.shouldAdjustCamera = true
      }

      if (partRenderable.partType === PartTypes.SinterPart || partRenderable.partType === PartTypes.IbcPart) {
        config.constraints = SINTER_PART_CONSTRAINTS
      }

      if (partRenderable.hasSupportBodies) {
        config.constraints = PART_SUPPORT_BODY_CONSTRAINTS
      }

      if (partRenderable.parameterSetScaleFactor) {
        config.parameterSetScaleFactor = partRenderable.parameterSetScaleFactor
      }

      if (partRenderable.processState) {
        config.processState = partRenderable.processState
      }

      if (index !== null) {
        this.loadingParts[index].isConfigCorrect = true
      }

      const isLoaded = this.loadedDocuments.find((d) => d.partId === partRenderable.partId)
      if (!isLoaded) {
        this.loadedDocuments.push({ partId: partRenderable.partId, document: config.model })
      }

      await this.loadModel(config, partRenderable.hideGeometries, partRenderable.excludeGeometries)
    } catch (error) {
      console.error(`Error loading model: ${error}`)
      if (index !== null) {
        this.loadingParts[index].isConfigCorrect = false
      }
    }

    if (index !== null) {
      this.loadingParts[index].notifyConfigLoaded()
    }

    store.commit(PART_CONFIG_LOADING_MUTATION_NAME, false, { root: true })
  }

  async loadPartConfigs(partsRenderable: IPartRenderable[]) {
    store.commit(PART_CONFIG_LOADING_MUTATION_NAME, true, { root: true })

    const filesMap = new Map<string, { model: any; part: IPartDto }>()
    for (const partRenderable of partsRenderable) {
      if (!filesMap.has(partRenderable.partId)) {
        const model = await partsService.getPartConfigFile(partRenderable.partId)
        const part = await partsService.getPartById(partRenderable.partId)
        filesMap.set(partRenderable.partId, { model, part })
      }
    }

    for (const partRenderable of partsRenderable) {
      const mesh = MeshBuilder.CreateSphere(MOCK_PART_MESH_NAME, { diameter: 10 }, this.scene)
      const material = this.scene.getMaterialByID('tempMeshMaterial')
      mesh.material = material
      mesh.isVisible = false
      const meshBBox = mesh.getBoundingInfo().boundingBox
      const partOffset: Vector3 = Vector3.Zero().subtract(
        new Vector3(
          (meshBBox.maximumWorld.x + meshBBox.minimumWorld.x) / 2,
          (meshBBox.maximumWorld.y + meshBBox.minimumWorld.y) / 2,
          meshBBox.minimumWorld.z,
        ),
      )
      const loadingPart = new LoadingPart(
        partRenderable.partId,
        partRenderable.partName,
        partRenderable.loadingPartIndex,
        partRenderable.dragDrop,
        mesh,
        mesh.position.clone(),
      )
      this.loadingParts.push(loadingPart)
      mesh.setAbsolutePosition(mesh.absolutePosition.add(partOffset))
      const index = this.loadingParts.indexOf(loadingPart)

      try {
        const { model, part } = filesMap.get(partRenderable.partId)

        if (index !== null) {
          this.loadingParts[index].config = model
        }

        if (model === null) {
          throw new Error('Config is null')
        }

        const config: IModelConfig = {
          model,
          part,
          hiddenBodies: partRenderable.hiddenBodies,
          partId: partRenderable.partId,
          partName: partRenderable.partName,
          partType: partRenderable.partType,
          geometryTypes: partRenderable.geometryTypes,
          loadingPartIndex: index !== null ? this.loadingParts[index].loadingPartIndex : undefined,
        }

        if (this.subType === ItemSubType.SinterPlan) {
          config.transformation = DEFAULT_TRANSFORMATION_MATRIX
          config.shouldAdjustCamera = true
        }

        if (partRenderable.partType === PartTypes.SinterPart || partRenderable.partType === PartTypes.IbcPart) {
          config.constraints = SINTER_PART_CONSTRAINTS
        }

        if (partRenderable.hasSupportBodies) {
          config.constraints = PART_SUPPORT_BODY_CONSTRAINTS
        }

        if (partRenderable.parameterSetScaleFactor) {
          config.parameterSetScaleFactor = partRenderable.parameterSetScaleFactor
        }

        if (partRenderable.processState) {
          config.processState = partRenderable.processState
        }

        if (index !== null) {
          this.loadingParts[index].isConfigCorrect = true
        }

        const isLoaded = this.loadedDocuments.find((d) => d.partId === partRenderable.partId)
        if (!isLoaded) {
          this.loadedDocuments.push({ partId: partRenderable.partId, document: config.model })
        }

        await this.loadModel(config, partRenderable.hideGeometries, partRenderable.excludeGeometries)
      } catch (error) {
        console.error(`Error loading model: ${error}`)

        if (index !== null) {
          this.loadingParts[index].isConfigCorrect = false
        }
      }

      if (index !== null) {
        this.loadingParts[index].notifyConfigLoaded()
      }
    }

    store.commit(PART_CONFIG_LOADING_MUTATION_NAME, false, { root: true })
  }

  showLoadingPart(loadingPartIndex: number) {
    const ind = this.loadingParts.findIndex((part) => part.loadingPartIndex === loadingPartIndex)
    for (const child of this.loadingParts[ind].mesh.getChildMeshes()) {
      child.isVisible = true
    }

    ; (this.renderScene as RenderScene).animate()
  }

  hideLoadingPart(loadingPartIndex: number) {
    const ind = this.loadingParts.findIndex((part) => part.loadingPartIndex === loadingPartIndex)
    for (const child of this.loadingParts[ind].mesh.getChildMeshes()) {
      child.isVisible = false
    }

    ; (this.renderScene as RenderScene).animate()
  }

  updateLoadingPartPosition(loadingPartIndex: number, pointerX: number, pointerY: number, pointerPosition?: Vector3) {
    let needUpdate = true
    if (
      Math.abs(this.loadingPartPointerX - pointerX) < Epsilon ||
      Math.abs(this.loadingPartPointerY - pointerY) < Epsilon
    ) {
      needUpdate = false
    }

    this.loadingPartPointerX = pointerX
    this.loadingPartPointerY = pointerY
    if (!needUpdate) {
      return
    }

    const ind = this.loadingParts.findIndex((part) => part.loadingPartIndex === loadingPartIndex)
    this.showLoadingPart(loadingPartIndex)

    if (pointerPosition) {
      this.setLoadingPartPosition(ind, pointerPosition)
    } else {
      const positionFromPointer = this.getMeshPositionByPointer(this.loadingParts[ind].mesh, pointerX, pointerY)
      if (positionFromPointer) {
        this.setLoadingPartPosition(ind, positionFromPointer)
      }
    }

    ; (this.renderScene as RenderScene).animate()
  }

  async saveLoadingPart(loadingPartIndex: number, pointerX: number, pointerY: number) {
    const ind = this.loadingParts.findIndex((part) => part.loadingPartIndex === loadingPartIndex)
    const loadingPart = this.loadingParts[ind]
    const pointerPosition = pointerX && pointerY && this.getMeshPositionByPointer(loadingPart.mesh, pointerX, pointerY)

    const triggerConfigFailed = () =>
      this.configLoadedEvent.trigger({
        partId: loadingPart.partId,
        partName: loadingPart.partName,
        loadingPartIndex: loadingPart.loadingPartIndex,
        transformation: null,
        isSuccess: false,
        constraints: null,
      })

    await loadingPart.waitConfigLoaded()
    if (!loadingPart.isConfigCorrect) {
      triggerConfigFailed()
      loadingPart.notifyPartManagedByScene()

      if (loadingPart.isPartRemoved) {
        messageService.showWarningMessage(i18n.t('PartNoLongerExist', { partName: loadingPart.partName }).toString())
      } else {
        messageService.showErrorMessage(`Impossible to add a ${loadingPart.partName}: invalid part configuration`)
      }

      return false
    }

    loadingPart.isPartFitsBuildPlate = true

    // abagayev - turning off the below 2 checks, user should be able to add a part to the build plate
    // regardless of whether the part fits onto the build plate in its original orientation or not

    // if (loadingPart.config.hullJson) {
    //   const partHullBBox = new ConvexHull(loadingPart.config.hullJson).computeBBox()
    //
    //   if (this.subType !== ItemSubType.SinterPlan &&
    //       !this.buildPlateManager.isPartHullFitsBuildPlate(partHullBBox)) {
    //     if (!this.buildPlateManager.isPartFitsBuildPlate(loadingPart.mesh)) {
    //       loadingPart.isPartFitsBuildPlate = false
    //       triggerConfigFailed()
    //       loadingPart.notifyPartManagedByScene()
    //
    //       messageService.showErrorMessage(
    //         `Impossible to add a ${loadingPart.partName}: part dimensions is bigger than build platform`,
    //       )
    //
    //       return false
    //     }
    //   }
    // }

    // if (this.buildPlateManager.isPartFitsBuildPlate(this.loadingParts[ind].mesh)) {
    //   this.loadingParts[ind].isPartFitsBuildPlate = true
    // } else {
    //   this.loadingParts[ind].isPartFitsBuildPlate = false
    //   this.loadingParts[ind].notifyPartManagedByScene()
    //   messageService.showErrorMessage(
    //     `Impossible to add a ${this.loadingParts[ind].partName}: part dimensions is bigger than build platform`,
    //   )
    //   return false
    // }

    loadingPart.isSaved = true
    if (pointerPosition) {
      this.updateLoadingPartPosition(loadingPart.loadingPartIndex, null, null, pointerPosition)
    }

    // make sure that all parts that has been triggered to add
    // to BP is really added to BP and build plan items is in actual state
    for (const part of this.loadingParts) {
      if (part === loadingPart || !part.isAddEventTriggered) {
        continue
      }

      await part.waitAddedToBuildPlan()
    }

    loadingPart.mesh.computeWorldMatrix(true)
    const loadingPartMesh = loadingPart.mesh
    const metadata = loadingPartMesh.metadata as IPartMetadata
    const partTransformation = []
    const initialTransformation = metadata.initialTransformation
    const relativeTransformation = this.meshManager.getRelativeTransformation(
      loadingPart.mesh.getWorldMatrix(),
      initialTransformation,
    )
    this.meshManager
      .convertTranslationToMillimeters(relativeTransformation, metadata.unitFactor)
      .transpose()
      .asArray()
      .forEach((item) => partTransformation.push(item))
    this.configLoadedEvent.trigger({
      partId: loadingPart.partId,
      partName: loadingPart.partName,
      loadingPartIndex: loadingPart.loadingPartIndex,
      transformation: partTransformation,
      isSuccess: loadingPart.isConfigCorrect,
      constraints: metadata.constraints,
    })
    loadingPart.isAddEventTriggered = true
    loadingPart.notifyPartManagedByScene()

    return true
  }

  /**
   * Saves parts in state.
   * @param loadingPartIndices indices of parts which need to save.
   */
  async saveLoadingParts(configs: Array<{ partConfig: IPartRenderable; targetBuildPlanItemId: string }>) {
    const loadingPartIndices = configs.map((config) => config.partConfig.loadingPartIndex)
    const loadingParts = this.loadingParts.filter((part) => loadingPartIndices.includes(part.loadingPartIndex))

    const loadingPartConfigs: Array<{
      partId: string
      partName: string
      loadingPartIndex: number
      transformation: number[]
      isSuccess: boolean
      constraints: IConstraints
      visibility: boolean
    }> = []

    for (const loadingPart of loadingParts) {
      await loadingPart.waitConfigLoaded()
      loadingPart.isPartFitsBuildPlate = true
      loadingPart.isSaved = true

      loadingPart.mesh.computeWorldMatrix(true)
      const loadingPartMesh = loadingPart.mesh
      const metadata = loadingPartMesh.metadata as IPartMetadata
      const firstBody = loadingPartMesh.getChildMeshes(false, this.meshManager.isComponentMesh)[0]
      const isHidden = (firstBody.metadata as IComponentMetadata).isHidden
      const partTransformation = []
      const initialTransformation = metadata.initialTransformation
      const relativeTransformation = this.meshManager.getRelativeTransformation(
        loadingPart.mesh.getWorldMatrix(),
        initialTransformation,
      )
      this.meshManager
        .convertTranslationToMillimeters(relativeTransformation, metadata.unitFactor)
        .transpose()
        .asArray()
        .forEach((item) => partTransformation.push(item))

      loadingPartConfigs.push({
        partId: loadingPart.partId,
        partName: loadingPart.partName,
        loadingPartIndex: loadingPart.loadingPartIndex,
        transformation: partTransformation,
        isSuccess: loadingPart.isConfigCorrect,
        constraints: metadata.constraints,
        visibility: !isHidden,
      })

      loadingPart.isAddEventTriggered = true
      loadingPart.notifyPartManagedByScene()
    }

    const replacementPartConfigs = configs.map((config) => ({
      partConfig: loadingPartConfigs.find(
        (loadingPartConfig) => loadingPartConfig.loadingPartIndex === config.partConfig.loadingPartIndex,
      ),
      targetBuildPlanItemId: config.targetBuildPlanItemId,
    }))

    this.configsLoadedEvent.trigger(replacementPartConfigs)
  }

  // set part buildPlanItemId after part is added to BP
  setLoadingPartIndex(loadingPartIndex: number, buildPlanItemId: string) {
    const ind = this.loadingParts.findIndex((part) => part.loadingPartIndex === loadingPartIndex)
    const partMesh = this.loadingParts[ind].mesh
    if (partMesh.metadata) {
      partMesh.metadata.buildPlanItemId = buildPlanItemId
    } else {
      partMesh.metadata = { buildPlanItemId }
    }

    this.scene.metadata.buildPlanItems.set(buildPlanItemId, partMesh)

    const bpItem = store.getters['buildPlans/buildPlanItemById'](buildPlanItemId)
    partMesh.metadata.maxDistanceFromPlate = this.getMaxDistanceFromPlateInMM(bpItem)

    if (!bpItem.geometryProperties) {
      const geometryProperties = this.getGeometryProperties(partMesh, this.loadingParts[ind].config, true)

      this.addGeometryProperties.trigger({
        geometryProperties,
        buildPlanItemId,
        updateStateOnly: false,
      })
    }

    if (this.renderScene.getGpuPicker()) {
      this.renderScene.getGpuPicker().addPickingObjects(partMesh.getChildMeshes())
    }

    this.loadingParts[ind].isAddedToBuildPlan = true
    this.loadingParts[ind].notifyAddedToBuildPlan()

    setTimeout(() => this.triggerStabilityCheck(), 0)
    setTimeout(() => this.triggerSafeDosingHeightCheck(), 50)
  }

  /**
   * Sets buildPlanItemIds after parts are added to BP. Updates geometry properties.
   */
  setLoadingPartIndices(loadingPartIndices: Array<{ loadingPartIndex: number; buildPlanItemId: string }>) {
    const updateGeometryProperties: IUpdateBuildPlanItemParamsDto[] = []

    for (const { buildPlanItemId, loadingPartIndex } of loadingPartIndices) {
      const ind = this.loadingParts.findIndex((part) => part.loadingPartIndex === loadingPartIndex)
      const partMesh = this.loadingParts[ind].mesh
      if (partMesh.metadata) {
        partMesh.metadata.buildPlanItemId = buildPlanItemId
      } else {
        partMesh.metadata = { buildPlanItemId }
      }

      this.scene.metadata.buildPlanItems.set(buildPlanItemId, partMesh)

      const bpItem = store.getters['buildPlans/buildPlanItemById'](buildPlanItemId) as IBuildPlanItem
      partMesh.metadata.maxDistanceFromPlate = this.getMaxDistanceFromPlateInMM(bpItem)

      if (!bpItem.geometryProperties) {
        const geometryProperties = this.getGeometryProperties(partMesh, this.loadingParts[ind].config, true)
        const bpItemDto: IUpdateBuildPlanItemParamsDto = {
          buildPlanItemId,
          geometryProperties,
        }
        updateGeometryProperties.push(bpItemDto)
      }

      if (this.renderScene.getGpuPicker()) {
        this.renderScene.getGpuPicker().addPickingObjects(partMesh.getChildMeshes())
      }

      this.loadingParts[ind].isAddedToBuildPlan = true
      this.loadingParts[ind].notifyAddedToBuildPlan()
    }

    store.dispatch('buildPlans/updateBuildPlanItems', updateGeometryProperties)

    setTimeout(() => this.triggerStabilityCheck(), 0)
    setTimeout(() => this.triggerSafeDosingHeightCheck(), 50)
  }

  async disposeLoadingPart(loadingPartIndex: number) {
    const ind = this.loadingParts.findIndex((part) => part.loadingPartIndex === loadingPartIndex)
    // wait while part is added to BP and part receives partIndex
    await this.loadingParts[ind].waitPartManagedByScene()
    if (this.loadingParts[ind].isSaved) {
      await this.loadingParts[ind].waitAddedToBuildPlan()
    }

    // remove mock mesh from the scene if part is not added to bp
    // (e.g. part drag started but dropped not on the canvas)
    if (!(this.loadingParts[ind].isPartFitsBuildPlate || this.loadingParts[ind].isAddedToBuildPlan)) {
      await this.loadingParts[ind].waitConfigLoaded()
      this.scene.removeTransformNode(this.loadingParts[ind].mesh)
      this.loadingParts[ind].mesh.dispose()
    }
  }

  async loadBuildPlan(buildPlan: IBuildPlan, options: LoadBuildPlanOptions = {}) {
    this.subType = buildPlan.subType
    if (this.isDisposed || !buildPlan.buildPlanItems.length) {
      return
    }

    const labelsFileName =
      options.printOrderId && (await jobsService.fetchLabelsFileNameByPrintOrderId(options.printOrderId))

    const isPrintOrder = options.printOrderId && options.printOrderId.length

    eventBus.$emit(BuildPlanEvents.LoadBuildPlanLoadingProgressStarts, 'openBuildPlanProgressModalInfo.status')
    eventBus.$emit(BuildPlanEvents.LoadBuildPlanLoadingProgressUpdates, {
      current: 0,
      total: buildPlan.buildPlanItems.length,
    })
    for (const [index, bpItem] of buildPlan.buildPlanItems.entries()) {
      if (this.isDisposed) {
        return
      }

      eventBus.$emit(BuildPlanEvents.LoadBuildPlanLoadingProgressUpdates, {
        current: index,
        total: buildPlan.buildPlanItems.length,
      })

      // need this "sleeper", otherwise the DOM freezes until the build plan is fully loaded
      if (index % 4 === 0) {
        await new Promise((r) => setTimeout(r, 1))
      }
      const loadedDocument = this.loadedDocuments.find((doc) => doc.partId === bpItem.part.id)
      const maxDistanceFromPlate = this.getMaxDistanceFromPlateInMM(bpItem)

      let parameterSetScaleFactor: number[]
      if (this.subType !== ItemSubType.SinterPlan && buildPlan.modality === PrintingTypes.BinderJet) {
        const partProperties = bpItem.partProperties[0]
        let printStrategyParameterSetPk: VersionablePk
        if (partProperties.printStrategyParameterSetId) {
          printStrategyParameterSetPk = new VersionablePk(
            partProperties.printStrategyParameterSetId,
            partProperties.printStrategyParameterSetVersion,
          )
        } else {
          const printStrategy: BuildPlanPrintStrategyDto = store.getters['buildPlans/getBuildPlanPrintStrategy']
          printStrategyParameterSetPk = getDefaultBaseOnType(
            printStrategy.defaults,
            partProperties.type,
            partProperties.bodyType,
          )
        }

        const bjPartParameters =
          store.getters['buildPlans/getPrintStrategyParameterSetByPk'](printStrategyParameterSetPk).parameterSet
            .partParameters
        parameterSetScaleFactor = [
          bjPartParameters.ScaleFactors.ScaleFactorX,
          bjPartParameters.ScaleFactors.ScaleFactorY,
          bjPartParameters.ScaleFactors.ScaleFactorZ,
        ]
      }

      let partType: PartTypes
      switch (bpItem.part.subType) {
        case ItemSubType.SinterPart:
          partType = PartTypes.SinterPart
          break
        case ItemSubType.IbcPart:
          partType = PartTypes.IbcPart
          break
        default:
          partType = PartTypes.BuildPlanPart
          break
      }
      const geometryTypes = new Map<string, GeometryType>()
      bpItem.partProperties.forEach((property) => geometryTypes.set(property.geometryId, property.type))

      if (this.isDisposed) {
        return
      }

      const part = loadedDocument ? loadedDocument.document : await partsService.getPartConfigFile(bpItem.part.id)
      if (!loadedDocument) {
        this.loadedDocuments.push({ document: part, partId: bpItem.part.id })
      }

      if (isPrintOrder) {
        bpItem.visibility = Visibility.Visible
        bpItem.hiddenBodies = []
      }

      await this.loadModel({
        maxDistanceFromPlate,
        parameterSetScaleFactor,
        partType,
        geometryTypes,
        processState: bpItem.partProperties[0] ? bpItem.partProperties[0].processState : ProcessState.Nominal,
        model: part,
        part: bpItem.part,
        buildPlanItemId: bpItem.id,
        partId: bpItem.part.id,
        partName: bpItem.part.name,
        transformation: bpItem.transformationMatrix,
        labels: labelsFileName ? null : bpItem.labels,
        overhangs: bpItem.overhangs,
        supports: bpItem.supports,
        constraints: bpItem.constraints,
        supportsBvhFileKey: bpItem.supportsBvhFileKey,
        supportsHullFileKey: bpItem.supportsHullFileKey,
        hiddenBodies: bpItem.hiddenBodies,
        visibility: bpItem.visibility,
      })
    }

    if (this.isDisposed) {
      return
    }

    if (labelsFileName) {
      const labelsFileData = await partsService.getLabelDracoFiles([labelsFileName])
      await this.labelManager.loadPrintOrderLabels(options.printOrderId, labelsFileData.get(labelsFileName))
    }

    eventBus.$emit(BuildPlanEvents.LoadBuildPlanLoadingProgressEnds)
    if (options.labelSets) {
      eventBus.$emit(BuildPlanEvents.LoadBuildPlanLoadingProgressStarts, 'labelTool.loadProgressModalText')
      let totalPatches = 0
      options.labelSets.forEach((labelSet) => {
        labelSet.patches.forEach((patch) => {
          if (patch.labelS3FileName) {
            totalPatches += 1
          }
        })
      })
      eventBus.$emit(BuildPlanEvents.LoadBuildPlanLoadingProgressUpdates, {
        current: 0,
        total: totalPatches,
      })

      const progressWatcher = (current: number, total: number) => {
        eventBus.$emit(BuildPlanEvents.LoadBuildPlanLoadingProgressUpdates, {
          current,
          total,
        })
      }

      const loadingLabels: Array<() => Promise<void>> = []
      options.labelSets.forEach((labelSet) => {
        return labelSet.patches.forEach((patch) => {
          if (!patch.labelS3FileName) return

          loadingLabels.push(async () => {
            const trackableLabel = this.labelManager.getTrackableLabelByPatch(labelSet, patch)
            const drc = await partsService.getLabelDracoFile(patch.labelS3FileName)
            await this.labelManager.addLabelOnScene(
              patch.id,
              patch.buildPlanItemId,
              patch.componentId,
              patch.geometryId,
              labelSet.id,
              drc,
              !patch.isValid,
              labelSet.settings.placementMethodAutomatic ? null : patch.orientation,
              patch.rotationAngle,
              trackableLabel ? trackableLabel.id : null,
            )
          })
        })
      })
      await promiseAllLimit(MAX_CONCURRENT_REQUESTS, loadingLabels, progressWatcher)
      eventBus.$emit(BuildPlanEvents.LoadBuildPlanLoadingProgressEnds)
    }

    this.triggerSafeDosingHeightCheck()
    setTimeout(() => this.triggerStabilityCheck(), 0)
  }

  async getSinterPlanItemTransform(partId: string) {
    let sinterPlanItemTransform: number[] = DEFAULT_TRANSFORMATION_MATRIX
    const ibcPlanItemPart = await partsService.getPartById(partId)
    if (ibcPlanItemPart.originalPlanId && ibcPlanItemPart.nominalPartId) {
      const sinterPlanItem = await buildPlanItems.getBuildPlanItemsByBuildPlanIdAndPartId(
        ibcPlanItemPart.originalPlanId,
        ibcPlanItemPart.nominalPartId,
      )
      if (sinterPlanItem) {
        sinterPlanItemTransform = sinterPlanItem[0].transformationMatrix
      }
    }
    return sinterPlanItemTransform
  }

  async loadIBCPlan(ibcPlan: IIBCPlan, options: LoadBuildPlanOptions = {}) {
    this.subType = ibcPlan.subType
    if (this.isDisposed) {
      return
    }

    const sinterPlanItemTransform: number[] = await this.getSinterPlanItemTransform(ibcPlan.ibcPlanItems[0].partId)

    const itemsToLoad: any[] = ibcPlan.ibcPlanItems.map((pi) => ({ ibcItem: pi, isMeasurement: false }))
    if (ibcPlan.measurements && ibcPlan.measurements.length > 0) {
      itemsToLoad.push(
        ...ibcPlan.measurements.map((m) => ({
          id: m.measurementVisFileId,
          isMeasurement: true,
          isVisible: m.visibility === Visibility.Visible,
        })),
      )
    }

    eventBus.$emit(BuildPlanEvents.LoadBuildPlanLoadingProgressStarts, 'openBuildPlanProgressModalInfo.status')
    for (const [index, item] of itemsToLoad.entries()) {
      if (this.isDisposed) {
        return
      }

      eventBus.$emit(BuildPlanEvents.LoadBuildPlanLoadingProgressUpdates, {
        current: index,
        total: itemsToLoad.length,
      })

      // need this "sleeper", otherwise the DOM freezes until the build plan is fully loaded
      if (index % 4 === 0) {
        await new Promise((r) => setTimeout(r, 1))
      }

      if (this.isDisposed) {
        return
      }

      if (item.isMeasurement) {
        await this.loadMeasurementsModel(item.id, item.isVisible, sinterPlanItemTransform)
      } else {
        const ibcItem = item.ibcItem
        const loadedDocument = this.loadedDocuments.find((doc) => doc.partId === ibcItem.partId)
        if (loadedDocument) {
          await this.loadIbcModel({
            model: loadedDocument.document,
            buildPlanItemId: ibcItem.id,
            partId: ibcItem.partId,
            partName: ibcItem.partName,
            geometryType: ibcItem.geometryType,
          })
        } else {
          try {
            // Temporal decision until ibc parts permissions will be enabled
            const partDto = await partsService.getPartById(ibcItem.partId)

            const partId = partDto.nominalPartId
            const part = await partsService.getPartConfigFile(partId)
            this.loadedDocuments.push({ partId, document: part })

            await this.loadIbcModel({
              partId,
              buildPlanItemId: ibcItem.id,
              partName: ibcItem.partName,
              transformation: sinterPlanItemTransform,
              model: part,
              geometryType: ibcItem.geometryType,
            })
          } catch (error) {
            messageService.showErrorMessage(error)
            eventBus.$emit(BuildPlanEvents.LoadBuildPlanLoadingProgressEnds)
            return
          }
        }
      }

      this.scene.render()
    }

    if (this.isDisposed) {
      return
    }

    eventBus.$emit(BuildPlanEvents.LoadBuildPlanLoadingProgressEnds)
    this.triggerStabilityCheck()
    this.triggerSafeDosingHeightCheck()
  }

  getGeometryProperties(
    mesh: TransformNode,
    document: DocumentModel | string,
    includeSupports?: boolean,
    ignoreCache?: boolean,
  ): IGeometryProperties {
    // build plan item geometry properties: get from cache or calculate from scratch
    const id = document
      ? document instanceof DocumentModel
        ? (document as DocumentModel).components.id
        : document
      : mesh.metadata
        ? mesh.metadata.documentModelId
        : null
    let geometryProperties: IMeshGeometryProperties = null
    if (id && !ignoreCache) {
      geometryProperties = store.getters['buildPlans/getModelGeometryPropertiesFromCache'](id)
    }
    if (!geometryProperties) {
      geometryProperties = this.calculateGeometryProperties(mesh)
      if (id) {
        store.commit('buildPlans/addGeometryPropertiesToCache', { geometryProperties, documentComponentsID: id })
      }
    }

    let supportSurfaceArea = 0
    let supportVolume = 0
    if (includeSupports) {
      const supportParent = mesh
        .getChildTransformNodes()
        .find((c) => this.meshManager.isSupportMesh(c) && c.metadata.buildPlanItemId)
      if (supportParent) {
        const supportNode = supportParent
          .getChildTransformNodes()
          .find((c) => this.meshManager.isSupportMesh(c) && c.metadata.sourceSupport)
        if (supportNode) {
          let supportGeometryProperties: IMeshGeometryProperties
          let cachedSupport = null
          if (!ignoreCache) {
            cachedSupport = this.supportManager.getCachedSupport(supportNode.metadata.sourceSupport.id)
          }
          if (cachedSupport && cachedSupport.geometryProperties) {
            supportGeometryProperties = cachedSupport.geometryProperties
          } else {
            supportGeometryProperties = this.calculateSupportGeometryProperties(supportParent, mesh.getWorldMatrix())
            this.supportManager.updateCachedGeometryProperties(
              supportNode.metadata.sourceSupport.id,
              supportGeometryProperties,
            )
          }

          supportSurfaceArea = supportGeometryProperties.surfaceArea
          supportVolume = supportGeometryProperties.volume
        }
      }
    }

    return {
      supportSurfaceArea,
      supportVolume,
      surfaceArea: geometryProperties.surfaceArea,
      volume: geometryProperties.volume,
    }
  }

  updateGeometryProperties(
    mesh: TransformNode,
    document: DocumentModel | string,
    includeSupports?: boolean,
    updateStateOnly?: boolean,
    ignoreCache?: boolean,
    takeIntoAccountRequestsQueue: boolean = false,
  ) {
    const geometryProperties = this.getGeometryProperties(mesh, document, includeSupports, ignoreCache)

    if (mesh.metadata.buildPlanItemId) {
      // do not update (including put request to the server) BPI geometry properties in case when they are the same
      const bpItem: IBuildPlanItem = store.getters['buildPlans/buildPlanItemById'](mesh.metadata.buildPlanItemId)
      if (bpItem && this.isEqualGeometryProperties(bpItem.geometryProperties, geometryProperties)) {
        return
      }
    }

    this.addGeometryProperties.trigger({
      geometryProperties,
      updateStateOnly,
      takeIntoAccountRequestsQueue,
      buildPlanItemId: mesh.metadata.buildPlanItemId,
    })
  }

  getComponentPath(mesh: AbstractMesh) {
    let current: any = mesh
    let fullPath: string = current.id
    while (current.parent != null) {
      fullPath = `${current.parent.id}${this.pathDelimiter}${fullPath}`
      current = current.parent
    }

    return fullPath
  }

  async clearUnfinishedInstances() {
    return this.duplicateManager.clearUnfinishedInstances()
  }

  async createVolumeGrid(
    duplicatePayload: DuplicatePayload[],
    duplicatePartsIndependently: boolean = true,
    duplicateMode: DuplicateMode = DuplicateMode.BoundingBoxes,
  ) {
    return this.duplicateManager.createVolumeGrid(duplicatePayload, duplicatePartsIndependently, duplicateMode)
  }

  finishInstancingProcess(payload: { items: IBuildPlanItem[]; singleSelection: boolean }) {
    this.duplicateManager.finishInstancingProcess(payload)
  }

  cancelDuplicateProcess() {
    this.duplicateManager.cancelDuplicateProcess()
  }

  deleteParts(partsIds: string[]) {
    const partsToDispose: TransformNode[] = []
    const bpItems = this.renderScene.getSceneMetadata().buildPlanItems
    for (const partId of partsIds) {
      if (bpItems.has(partId)) {
        const part = bpItems.get(partId)

        // probably should be removed from each collector where it is used

        this.selectionManager.deselect([{ part }])
        this.renderScene
          .getGpuPicker()
          .removePickingObjects(part.getChildMeshes().filter((c) => c instanceof InstancedMesh))

        partsToDispose.push(part)
        bpItems.delete(partId)

        let isCacheUsed = false
        for(const bpItem of bpItems.values()) {
          if (bpItem.metadata.documentModelId === part.metadata.documentModelId) {
            isCacheUsed = true
            break
          }
        }

        if (!isCacheUsed) {
          this.documents.delete((part.metadata as IPartMetadata).documentModelId)
          this.bvhCache.delete((part.metadata as IPartMetadata).documentModelId)
          this.hullCache.delete((part.metadata as IPartMetadata).documentModelId)
        }
      }
    }

    partsToDispose.forEach((part) => {
      ;(part.metadata as IPartMetadata).parameterSetScaleNode.dispose()
      part.dispose()
    })
    if (this.subType === ItemSubType.SinterPlan) {
      const parts = Array.from(this.renderScene.getSceneMetadata().buildPlanItems.values())
      this.updateSinterPlate(parts)
      this.renderScene.getActiveCamera().setNewTarget(this.scene)
    }

    setTimeout(() => this.refreshInsights(), 0)
    setTimeout(() => (this.renderScene as RenderScene).animate(), 0)
    this.onDeletePartsInState.trigger(partsIds)
  }

  async createOverhangMesh(id: string, drc: ArrayBuffer, transform: Matrix) {
    return await this.decompressOverhangMesh(id, OVERHANG_NAME, drc, transform)
  }

  async createLabelMesh(id: string, drc: ArrayBuffer) {
    return await this.decompressMesh(id, LABEL_MESH, null, drc, true)
  }

  async createLabelMeshes(id: string, drc: ArrayBuffer) {
    return await this.decompressLabelMeshes(id, LABEL_MESH, null, drc)
  }

  createSupportMesh(sdata: Sdata) {
    return this.supportManager.createSupport(sdata)
  }

  initializeLabelMode() {
    this.labelManager.initializeLabelMode()
  }

  terminateLabelMode() {
    this.labelManager.terminateLabelMode()
  }

  changeLabelColors(isLabelMode: boolean) {
    this.labelManager.changeLabelColors(isLabelMode)
  }

  setLabelStyle(style: ILabelStyle) {
    this.labelManager.setLabelStyle(style)
  }

  async updateLabelAppearance(
    labelId: string,
    newStyle: ILabelStyle,
    shouldLabelBeSelected: boolean = true,
    updateStore: boolean = true,
  ) {
    await this.labelManager.updateLabelAppearance(labelId, newStyle, shouldLabelBeSelected, updateStore)
  }

  deleteLabel(labelId: string) {
    this.labelManager.deleteLabel(labelId)
  }

  toggleComponentHighlight(componentId: string, showHighlight: boolean, excludedBPItems: string[] = null) {
    this.scene.meshes.map((mesh) => {
      if (!this.meshManager.isComponentMesh(mesh)) {
        return
      }

      const metadata = mesh.metadata as IComponentMetadata
      if (metadata.componentId !== componentId) {
        return
      }

      if (!excludedBPItems || !excludedBPItems.length) {
        this.selectionManager.highlight([{ body: mesh }], showHighlight)
        return
      }

      const part = this.meshManager.getBuildPlanItemMeshByChild(mesh)
      if (!part || excludedBPItems.includes(part.metadata.buildPlanItemId)) {
        return
      }

      this.selectionManager.highlight([{ body: mesh }], showHighlight)
    })
      ; (this.renderScene as RenderScene).animate(true)
  }

  toggleLabelHighlight(labelId: string, highlight: boolean) {
    this.labelManager.toggleLabelHighlight(labelId, highlight)
  }

  toggleDefectsHighlight(payload: IHighlightDefectPayload) {
    this.defectsManager.toggleDefectsHighlight(payload)
    setTimeout(() => (this.renderScene as RenderScene).animate(true), 0)
  }

  selectDefects(payload: ISelectableDefectPayload) {
    this.defectsManager.selectDefects(payload)
    setTimeout(() => (this.renderScene as RenderScene).animate(true), 0)
  }

  activateLabelManualPlacement() {
    this.labelManager.activateLabelManualPlacement()
  }

  deactivateLabelManualPlacement(labelSetId?: string, removeOrigin: boolean = true) {
    this.labelManager.deactivateLabelManualPlacement(labelSetId, removeOrigin)
  }

  showManuallyPlacedLabelOrigins(patches: Placement[], labelSetId?: string) {
    this.labelManager.showManuallyPlacedLabelOrigins(patches, labelSetId)
  }

  activateLabelCreation() {
    this.labelManager.activateLabelCreation()
      ; (this.renderScene as RenderScene).animate(true)
  }

  deactivateLabelCreation() {
    this.labelManager.deactivateLabelCreation()
      ; (this.renderScene as RenderScene).animate(true)
  }

  activateLabelInteraction(labelId: string) {
    this.labelManager.activateLabelInteraction(labelId)
  }

  deactivateLabelInteraction(labelId: string = null) {
    this.labelManager.deactivateLabelInteraction(labelId)
  }

  async labelInstance(payload: InstanceLabel) {
    if (payload.label) {
      const data = await partsService.getLabelDracoFiles([payload.label.s3FileName])
      await this.labelManager.loadItemLabels(payload.itemId, [payload.label], payload.transformation, data)
      this.loadInsights(payload.insights)
      this.refreshLabelInsights()
    }
  }

  updateLabelGizmoScale() {
    this.labelManager.updateLabelGizmoScale()
  }

  refreshInsights() {
    const supportInsights = this.supportManager.getSupportInsights()
    const insights: IPendingInsights[] = []
    insights.push({ insights: supportInsights, tool: ToolNames.SUPPORT })
    this.renderScene.getInsightsManager().reportInsights(insights)
  }

  refreshLabelInsights() {
    this.labelManager.refreshLabelInsights()
  }

  refreshSupportInsights() {
    this.supportManager.refreshSupportInsights()
  }

  refreshPartPropertiesInsights() {
    const insights = this.insightsManager.buildInsights(
      Array.from(this.renderScene.getSceneMetadata().buildPlanItems.values()),
    )
    this.insightsManager.reportInsights([{ insights, tool: ToolNames.PART_PROPERTIES }])
  }

  decodeSdata(data: ArrayBuffer) {
    return this.supportManager.decodeSdata(data)
  }

  getBPItemGeometryPropertiesFromCache(payload) {
    const loadedDocument = this.loadedDocuments.find((doc) => doc.partId === payload.partId)
    if (!loadedDocument) {
      return null
    }
    const id = (loadedDocument.document as DocumentModel).components.id

    const cachedModel = id && store.getters['buildPlans/getModelGeometryPropertiesFromCache'](id)
    if (!cachedModel) {
      return null
    }
    return cachedModel.geometryProperties
  }

  getSinglePartGeometryProps(buildPlanItemId?: string, includeSupports?: boolean, ignoreCache?: boolean) {
    let mesh
    if (buildPlanItemId) {
      mesh = this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)
    } else {
      mesh = this.scene.transformNodes.find((m) => this.meshManager.isPartMesh(m))
    }
    return this.getGeometryProperties(mesh, null, includeSupports, ignoreCache)
  }

  getPartsBoundingBox(buildPlanItemId?: string, includeSupports?: boolean) {
    let mesh: TransformNode
    if (buildPlanItemId) {
      mesh = this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)
    } else {
      mesh = this.scene.transformNodes.find((m) => this.meshManager.isPartMesh(m))
    }

    return mesh && this.getMeshBoundingBox(mesh)
  }

  getMeshBoundingBox(mesh: TransformNode) {
    const vectors = mesh.getHierarchyBoundingVectors()
    return new BoundingInfo(vectors.min, vectors.max)
  }

  async dispose() {
    if (this.isDisposed) {
      return
    }

    this.isDisposed = true

    // Cleanup stability check-related cache
    // Use private property instead of a getter as getter tries to create a new instance of a checker
    if (this.checker) {
      this.checker.resetGeometryData()
    }

    // Wait for cancellation of loadModel if any is in progress
    await debounce(200, () => {
      if (this.labelManager) {
        this.labelManager.dispose()
      }

      DracoDecoder.Default.dispose()
    })()
  }

  public async loadModel(config: IModelConfig, hideGeometries: string[] = [], excludeGeometries: string[] = []) {
    let loadedDocument = this.documents.get(config.model.components.id)
    if (!loadedDocument) {
      loadedDocument = { model: config.model, parts: [] }

      await Promise.all(
        config.model.parts.map(async (part) => {
          const meshes: Array<{ mesh: Mesh; type: GeometryTypes }> = []

          if (this.isDisposed) {
            return
          }

          await promiseAllLimit(
            MAX_CONCURRENT_REQUESTS,
            part.geometries
              .filter((geom) => !excludeGeometries || !excludeGeometries.includes(geom.id))
              .map((geom) => {
                return async () => {
                  const defaultMaterial = this.scene.getMaterialByName(DEFAULT_MATERIAL_NAME)
                  const insideMaterial = this.scene.getMaterialByName(MESH_INSIDE_MATERIAL_NAME)

                  const mesh = await this.decompressMesh(geom.id, geom.name, geom.path)
                  if (this.isDisposed) {
                    return
                  }
                  this.meshManager.transformMesh(mesh, geom.transformation)
                  mesh.registerInstancedBuffer(VertexBuffer.ColorKind, 4)
                  mesh.material = defaultMaterial
                  mesh.instancedBuffers.color = DEFAULT_COLOR
                  mesh.renderingGroupId = MESH_RENDERING_GROUP_ID
                  mesh.isVisible = !hideGeometries || !hideGeometries.includes(geom.id)
                  this.scene.removeMesh(mesh)

                  // create inside Mesh
                  const meshInside = new Mesh(INSIDE_MESH_NAME, this.scene)
                  meshInside.material = insideMaterial
                  meshInside.renderingGroupId = MESH_RENDERING_GROUP_ID
                  meshInside.metadata = null
                  const vd = new VertexData()
                  vd.indices = mesh.getIndices()
                  vd.positions = mesh.getVerticesData(VertexBuffer.PositionKind)
                  vd.normals = mesh.getVerticesData(VertexBuffer.NormalKind)
                  vd.applyToMesh(meshInside)
                  this.scene.removeMesh(meshInside)

                  mesh.metadata.sourceMeshInside = meshInside
                  mesh.metadata.defects = geom.defects
                  mesh.metadata.isBar = geom.bar
                  meshes.push({ mesh, type: geom.type || GeometryTypes.Solid })
                }
              }),
          )

          loadedDocument.parts.push(new ModelItem(part.id, meshes))
        }),
      )

      if (this.isDisposed) {
        return
      }

      this.documents.set(config.model.components.id, loadedDocument)
    }
    this.render({
      component: config.model.components,
      parts: loadedDocument.parts,
      buildPlanItemId: config.buildPlanItemId,
      partId: config.partId,
      partName: config.partName,
      hiddenBodies: config.hiddenBodies,
      visibility: config.visibility,
    })

    if (this.renderScene.getSceneMode() === SceneMode.PreviewDetails) return

    const bpItemMesh = config.buildPlanItemId
      ? this.meshManager.getBuildPlanItemMeshById(config.buildPlanItemId)
      : this.scene.transformNodes.filter((m) => this.meshManager.isPartMesh(m)).pop()
    bpItemMesh.metadata.constraints = config.constraints
    bpItemMesh.metadata.initialTransformation = bpItemMesh.getWorldMatrix().clone()
    bpItemMesh.metadata.maxDistanceFromPlate = config.maxDistanceFromPlate
    bpItemMesh.metadata.partType = config.partType
    bpItemMesh.metadata.failedOverhangZones = []
    bpItemMesh.getChildMeshes().forEach((child) => {
      const metadata = child.metadata as IComponentMetadata
      if (metadata && config.geometryTypes) {
        const bodyType = config.geometryTypes.get(
          `${metadata.componentId}${PART_BODY_ID_DELIMITER}${metadata.geometryId}`,
        )
        metadata.bodyType = bodyType
        if (metadata.bodyType !== undefined) {
          this.meshManager.setInstancedBufferColor(child as InstancedMesh, false, false)
        }
      }

      if (this.meshManager.isComponentMesh(child)) {
        metadata.initialTransformation = child.getWorldMatrix().clone()
      }
    })

    if (
      config.parameterSetScaleFactor &&
      config.processState &&
      config.processState === ProcessState.Green &&
      config.part.scaleX !== 1 &&
      config.part.scaleY !== 1 &&
      config.part.scaleZ !== 1 &&
      (config.parameterSetScaleFactor[0] !== config.part.scaleX ||
        config.parameterSetScaleFactor[1] !== config.part.scaleY ||
        config.parameterSetScaleFactor[2] !== config.part.scaleZ)
    ) {
      bpItemMesh.metadata.isNonDefaultScaleFactor = true
    }

    if (this.isDisposed) {
      return
    }

    if (config.overhangs) {
      try {
        await this.overhangManager.addOverhangMesh(
          config.buildPlanItemId,
          createGuid(),
          { overhang: config.overhangs },
          true,
        )
          ; (this.renderScene as RenderScene).setOverhangMeshVisibility(
            [{ buildPlanItemId: config.buildPlanItemId }],
            false,
          )
      } catch (error) {
        messageService.showErrorMessage(error)
      }
    }

    if (this.isDisposed) {
      return
    }

    if (config.model.hullJson) {
      if (!this.hullCache.has(config.model.components.id)) {
        this.hullCache.set(config.model.components.id, new ConvexHull(config.model.hullJson))
      }

      bpItemMesh.metadata.hull = this.hullCache.get(config.model.components.id)
      // cache hull bounding box info after the mesh has been transformed
      // calling this updates parentMesh.metadata.hull as well
      bpItemMesh.computeWorldMatrix(true)
      bpItemMesh.metadata.hullBInfo = this.meshManager.getHullBInfo(bpItemMesh)
    }

    if (!config.transformation) {
      // Split transform into bpItem and parameterSet
      // This is necessary for non-uniform scaling after rotation which can create a shift component in matrix
      // BABYLON.js doesn't support shift component
      const parameterSetScale = new TransformNode(PARAMETER_SET_SCALE_NAME, this.scene)
      parameterSetScale.scaling =
        config.processState === ProcessState.Nominal && config.parameterSetScaleFactor
          ? Vector3.FromArray(config.parameterSetScaleFactor)
          : new Vector3(1, 1, 1)
      bpItemMesh.metadata.parameterSetScaleNode = parameterSetScale
      bpItemMesh.parent = parameterSetScale
      bpItemMesh.computeWorldMatrix()

      if (config.processState === ProcessState.Nominal) {
        // we need to recompute the cached hullBInfo after scaling the Nominal part
        bpItemMesh.metadata.hullBInfo = this.meshManager.getTotalBoundingInfo(bpItemMesh.getChildMeshes(), true, true)
      }
      const newPartBounds = bpItemMesh.metadata.hullBInfo
      const [buildChamberLines] = this.scene.meshes.filter((mesh) => mesh.name === BUILD_CHAMBER_POLYLINES_NAME)
      const buildChamberBoundingInfo = buildChamberLines
        ? this.meshManager.getTotalBoundingInfo(buildChamberLines.getChildMeshes())
        : null

      if (
        !buildChamberBoundingInfo ||
        !this.meshManager.isBoundingBoxCompletelyInsideBoundingBox(newPartBounds, buildChamberBoundingInfo)
      ) {
        const partOffset: Vector3 = Vector3.Zero().subtract(
          new Vector3(
            (newPartBounds.boundingBox.maximumWorld.x + newPartBounds.boundingBox.minimumWorld.x) / 2,
            (newPartBounds.boundingBox.maximumWorld.y + newPartBounds.boundingBox.minimumWorld.y) / 2,
            newPartBounds.boundingBox.minimumWorld.z,
          ),
        )

        bpItemMesh.setAbsolutePosition(bpItemMesh.absolutePosition.add(partOffset))
        // re-compute parentMesh's world matrix after updating the absolute position
        bpItemMesh.computeWorldMatrix(true)
        // also re-compute the cached hull info after updating the absolute position
        bpItemMesh.metadata.hullBInfo = this.meshManager.getHullBInfo(bpItemMesh)
      }
    } else {
      const parameterSetScale = new TransformNode(PARAMETER_SET_SCALE_NAME, this.scene)
      const scale =
        config.processState === ProcessState.Nominal && config.parameterSetScaleFactor
          ? Vector3.FromArray(config.parameterSetScaleFactor)
          : new Vector3(1, 1, 1)

      const initMatrix = bpItemMesh.getWorldMatrix().clone()
      const relativeMatrix = Matrix.FromArray(config.transformation).transpose()
      let partTransform = initMatrix.multiply(relativeMatrix)

      // Split transform into bpItem and parameterSet
      // This is necessary for non-uniform scaling after rotation which can create a shift component in matrix
      // BABYLON.js doesn't support shift component
      const translateVector = partTransform.getTranslation()
      const translateMatrix = Matrix.Translation(translateVector.x, translateVector.y, translateVector.z)
      const invertTranslateMatrix = translateMatrix.clone().invert()
      const scaleMatrix = Matrix.Scaling(scale.x, scale.y, scale.z)
      const parameterSetTransform = invertTranslateMatrix.multiply(scaleMatrix).multiply(translateMatrix)
      partTransform = partTransform.multiply(parameterSetTransform.clone().invert())

      partTransform.decompose(bpItemMesh.scaling, bpItemMesh.rotationQuaternion, bpItemMesh.position)
      parameterSetTransform.decompose(
        parameterSetScale.scaling,
        parameterSetScale.rotationQuaternion,
        parameterSetScale.position,
      )

      bpItemMesh.metadata.parameterSetScaleNode = parameterSetScale
      bpItemMesh.parent = parameterSetScale
      bpItemMesh.computeWorldMatrix(true)
      bpItemMesh.metadata.hullBInfo = this.meshManager.getHullBInfo(bpItemMesh)
    }

    if (this.isDisposed) {
      return
    }

    if (config.supports) {
      await this.loadSupports(
        config.buildPlanItemId,
        config.supports,
        config.supportsBvhFileKey,
        config.supportsHullFileKey,
        config.visibility,
        bpItemMesh,
      )
    }

    if (this.isDisposed) {
      return
    }

    if (
      config.model.hullJson &&
      ![SceneMode.SinglePart, SceneMode.PreviewPart].includes(this.renderScene.getSceneMode())
    ) {
      const bpItem: IBuildPlanItem = store.getters['buildPlans/buildPlanItemById'](bpItemMesh.metadata.buildPlanItemId)
      if (!bpItem || !bpItem.geometryProperties) {
        const geometryProperties = this.getGeometryProperties(bpItemMesh, config.model, true)
        this.addGeometryProperties.trigger({
          geometryProperties,
          updateStateOnly: false,
          buildPlanItemId: bpItemMesh.metadata.buildPlanItemId,
        })
      }
    }

    if (this.isDisposed) {
      return
    }

    for (const mesh of bpItemMesh.getChildMeshes(false, this.meshManager.isComponentMesh)) {
      this.meshManager.createTransparentClone(mesh as InstancedMesh, false)
    }

    if (config.labels) {
      const data = await partsService.getLabelDracoFiles(config.labels.map((val) => val.s3FileName))

      if (this.isDisposed) {
        return
      }

      await this.labelManager.loadItemLabels(
        config.buildPlanItemId,
        JSON.parse(JSON.stringify(config.labels)),
        config.transformation,
        data,
      )
    }

    if (config.model.bvhJson) {
      if (!this.bvhCache.has(config.model.components.id)) {
        this.bvhCache.set(
          config.model.components.id,
          this.renderScene.getObbTree().deserializeBVH(cloneDeep(config.model.bvhJson), bpItemMesh),
        )
      }
      const roots = this.bvhCache.get(config.model.components.id)
      bpItemMesh.metadata.bvh = roots
    }

    if (config.loadingPartIndex !== null && config.loadingPartIndex !== undefined) {
      this.updateLoadingPartMesh(config.loadingPartIndex, bpItemMesh)
    }

    if (config.loadingPartIndex === void 0 && this.renderScene.getGpuPicker()) {
      this.renderScene.getGpuPicker().addPickingObjects(bpItemMesh.getChildMeshes())
    }

    if (this.subType === ItemSubType.SinterPlan) {
      const parts = this.scene.transformNodes.filter((tn) => this.meshManager.isPartMesh(tn))
      this.updateSinterPlate(parts)
      if (config.shouldAdjustCamera) {
        this.renderScene.getActiveCamera().setNewTarget(this.scene)
      }

      if (this.selectionManager) {
        this.selectionManager.deselect()
      }
    }

    const mode = this.renderScene.getSceneMode()
    if (mode === SceneMode.SinglePart || mode === SceneMode.PreviewPart) {
      this.updatePartPreviewPlate(bpItemMesh)
      this.defectsManager.updateDefectsScaling([DefectShapeType.Point])
      this.scene
        .getEngine()
        .getRenderingCanvas()
        .addEventListener('wheel', () => this.defectsManager.updateDefectsScaling([DefectShapeType.Point]))
    }
  }

  async loadSupports(
    bpItemId: string,
    supports: BuildPlanItemSupport[],
    supportsBvhFileKey: string,
    supportsHullFileKey: string,
    visibility: Visibility,
    bpItemMesh?: TransformNode,
  ) {
    const bpItemNode = bpItemMesh ? bpItemMesh : this.meshManager.getBuildPlanItemMeshById(bpItemId)
    const supportParentMesh = this.supportMgr.createSupportParent(bpItemNode)

    await Promise.all(
      supports.map(async (support) => {
        try {
          if (this.isDisposed) {
            return
          }

          if (support.fileKey) {
            const supportGroup = await this.supportManager.loadSupport(support, bpItemNode.metadata.pickingColor)
            supportGroup.parent = supportParentMesh
            const supportMeshes = supportGroup.getChildMeshes(false, (mesh) => mesh.name === LINE_SUPPORT)

            for (const mesh of supportMeshes) {
              if (visibility === Visibility.Hidden) {
                this.meshManager.setIsHidden(
                  mesh as InstancedMesh,
                  true,
                  this.meshManager.isShowHiddenPartsAsTransparentMode,
                )
              }
            }
          } else {
            await (this.renderScene as RenderScene).highlightErrorOverhangZone(bpItemId, support.overhangElementName)
          }
        } catch (error) {
          messageService.showErrorMessage(error)
        }
      }),
    )

    const suportBvhHullKeys = [supportsBvhFileKey, supportsHullFileKey].filter((key) => key)
    const awsFileKeysMap = suportBvhHullKeys.length && (await partsService.getAwsFileKeysMap(suportBvhHullKeys))

    if (this.isDisposed) {
      return
    }

    if (supportsBvhFileKey) {
      if (!this.bvhCache.has(awsFileKeysMap[supportsBvhFileKey])) {
        const supportsBvhData = await partsService.getBvhFileFromAws(awsFileKeysMap[supportsBvhFileKey])
        this.bvhCache.set(
          awsFileKeysMap[supportsBvhFileKey],
          this.renderScene.getObbTree().deserializeBVH(supportsBvhData),
        )
      }

      const roots = this.bvhCache.get(awsFileKeysMap[supportsBvhFileKey])
      supportParentMesh.metadata.bvh = roots
    }

    if (this.isDisposed) {
      return
    }

    if (supportsHullFileKey) {
      if (!this.hullCache.has(awsFileKeysMap[supportsHullFileKey])) {
        const supportsHullData = await partsService.getJsonFileFromAws(awsFileKeysMap[supportsHullFileKey])
        this.hullCache.set(awsFileKeysMap[supportsHullFileKey], new ConvexHull(supportsHullData))
      }

      bpItemNode.computeWorldMatrix(true)
      supportParentMesh.computeWorldMatrix(true)
      supportParentMesh.metadata.hull = this.hullCache.get(awsFileKeysMap[supportsHullFileKey])

      // In order to display correct value of part translation in the page footer after build plan is loaded
      // We need to update buildPlanItem's hull bounding info
      bpItemNode.metadata.hullBInfo = this.meshManager.getHullBInfo(bpItemNode)
      supportParentMesh.metadata.hullBInfo = this.meshManager.getHullBInfo(supportParentMesh)
    }
  }

  public async loadIbcModel(config: IIBCModelConfig, hideGeometries: string[] = [], excludeGeometries: string[] = []) {
    let loadedDocument = this.documents.get(config.model.components.id)
    if (!loadedDocument) {
      loadedDocument = { model: config.model, parts: [] }

      await Promise.all(
        config.model.parts.map(async (part) => {
          const meshes: Array<{ mesh: Mesh; type: GeometryTypes }> = []

          if (this.isDisposed) {
            return
          }

          await Promise.all(
            part.geometries
              .filter((geom) => !excludeGeometries || !excludeGeometries.includes(geom.id))
              .map(async (geom) => {
                const defaultMaterial = this.scene.getMaterialByName(DEFAULT_MATERIAL_NAME)
                const insideMaterial = this.scene.getMaterialByName(MESH_INSIDE_MATERIAL_NAME)

                const mesh = await this.decompressMesh(geom.id, geom.name, geom.path)
                if (this.isDisposed) {
                  return
                }

                this.meshManager.transformMesh(mesh, geom.transformation)
                mesh.registerInstancedBuffer(VertexBuffer.ColorKind, 4)
                mesh.material = defaultMaterial
                mesh.instancedBuffers.color = DEFAULT_COLOR
                mesh.renderingGroupId = MESH_RENDERING_GROUP_ID
                mesh.isVisible = !hideGeometries || !hideGeometries.includes(geom.id)
                this.scene.removeMesh(mesh)

                // create inside Mesh
                const meshInside = new Mesh(INSIDE_MESH_NAME, this.scene)
                meshInside.material = insideMaterial
                meshInside.renderingGroupId = MESH_RENDERING_GROUP_ID
                meshInside.metadata = null
                const vd = new VertexData()
                vd.indices = mesh.getIndices()
                vd.positions = mesh.getVerticesData(VertexBuffer.PositionKind)
                vd.normals = mesh.getVerticesData(VertexBuffer.NormalKind)
                vd.applyToMesh(meshInside)
                this.scene.removeMesh(meshInside)

                mesh.metadata.sourceMeshInside = meshInside
                mesh.metadata.defects = geom.defects
                meshes.push({ mesh, type: geom.type || GeometryTypes.Solid })
              }),
          )

          loadedDocument.parts.push(new ModelItem(part.id, meshes))
        }),
      )

      if (this.isDisposed) {
        return
      }

      this.documents.set(config.model.components.id, loadedDocument)
    }
    this.render({
      component: config.model.components,
      parts: loadedDocument.parts,
      buildPlanItemId: config.buildPlanItemId,
      partId: config.partId,
      partName: config.partName,
      hiddenBodies: config.hiddenBodies,
    })

    const bpItemMesh = config.buildPlanItemId
      ? this.meshManager.getBuildPlanItemMeshById(config.buildPlanItemId)
      : this.scene.transformNodes.filter((m) => this.meshManager.isPartMesh(m)).pop()
    bpItemMesh.metadata.constraints = config.constraints
    bpItemMesh.metadata.initialTransformation = bpItemMesh.getWorldMatrix().clone()
    bpItemMesh.metadata.maxDistanceFromPlate = config.maxDistanceFromPlate
    bpItemMesh.metadata.partType = config.partType
    bpItemMesh.metadata.failedOverhangZones = []
    if (config.geometryType) {
      bpItemMesh.getChildMeshes().forEach((child) => {
        const metadata = child.metadata as IComponentMetadata
        if (metadata) {
          metadata.bodyType = config.geometryType
        }
      })
    }

    if (this.isDisposed) {
      return
    }

    if (config.model.hullJson) {
      if (!this.hullCache.has(config.model.components.id)) {
        this.hullCache.set(config.model.components.id, new ConvexHull(config.model.hullJson))
      }

      bpItemMesh.metadata.hull = this.hullCache.get(config.model.components.id)
      // cache hull bounding box info after the mesh has been transformed
      // calling this updates parentMesh.metadata.hull as well
      bpItemMesh.computeWorldMatrix(true)
      bpItemMesh.metadata.hullBInfo = this.meshManager.getHullBInfo(bpItemMesh)
    }

    if (!config.transformation) {
      // Split transform into bpItem and parameterSet
      // This is necessary for non-uniform scaling after rotation which can create a shift component in matrix
      // BABYLON.js doesn't support shift component
      const parameterSetScale = new TransformNode(PARAMETER_SET_SCALE_NAME, this.scene)
      parameterSetScale.scaling =
        config.processState === ProcessState.Nominal && config.parameterSetScaleFactor
          ? Vector3.FromArray(config.parameterSetScaleFactor)
          : new Vector3(1, 1, 1)
      bpItemMesh.metadata.parameterSetScaleNode = parameterSetScale
      bpItemMesh.parent = parameterSetScale
      bpItemMesh.computeWorldMatrix()

      if (config.processState === ProcessState.Nominal) {
        // we need to recompute the cached hullBInfo after scaling the Nominal part
        bpItemMesh.metadata.hullBInfo = this.meshManager.getTotalBoundingInfo(bpItemMesh.getChildMeshes(), true, true)
      }
      const newPartBounds = bpItemMesh.metadata.hullBInfo
      const [buildChamberLines] = this.scene.meshes.filter((mesh) => mesh.name === BUILD_CHAMBER_POLYLINES_NAME)
      const buildChamberBoundingInfo = buildChamberLines
        ? this.meshManager.getTotalBoundingInfo(buildChamberLines.getChildMeshes())
        : null

      if (
        !buildChamberBoundingInfo ||
        !this.meshManager.isBoundingBoxCompletelyInsideBoundingBox(newPartBounds, buildChamberBoundingInfo)
      ) {
        const partOffset: Vector3 = Vector3.Zero().subtract(
          new Vector3(
            (newPartBounds.boundingBox.maximumWorld.x + newPartBounds.boundingBox.minimumWorld.x) / 2,
            (newPartBounds.boundingBox.maximumWorld.y + newPartBounds.boundingBox.minimumWorld.y) / 2,
            newPartBounds.boundingBox.minimumWorld.z,
          ),
        )

        bpItemMesh.setAbsolutePosition(bpItemMesh.absolutePosition.add(partOffset))
        // re-compute parentMesh's world matrix after updating the absolute position
        bpItemMesh.computeWorldMatrix(true)
        // also re-compute the cached hull info after updating the absolute position
        bpItemMesh.metadata.hullBInfo = this.meshManager.getHullBInfo(bpItemMesh)
      }
    } else {
      const parameterSetScale = new TransformNode(PARAMETER_SET_SCALE_NAME, this.scene)
      const scale =
        config.processState === ProcessState.Nominal && config.parameterSetScaleFactor
          ? Vector3.FromArray(config.parameterSetScaleFactor)
          : new Vector3(1, 1, 1)

      const initMatrix = bpItemMesh.getWorldMatrix().clone()
      const relativeMatrix = Matrix.FromArray(config.transformation).transpose()
      let partTransform = initMatrix.multiply(relativeMatrix)

      // Split transform into bpItem and parameterSet
      // This is necessary for non-uniform scaling after rotation which can create a shift component in matrix
      // BABYLON.js doesn't support shift component
      const translateVector = partTransform.getTranslation()
      const translateMatrix = Matrix.Translation(translateVector.x, translateVector.y, translateVector.z)
      const invertTranslateMatrix = translateMatrix.clone().invert()
      const scaleMatrix = Matrix.Scaling(scale.x, scale.y, scale.z)
      const parameterSetTransform = invertTranslateMatrix.multiply(scaleMatrix).multiply(translateMatrix)
      partTransform = partTransform.multiply(parameterSetTransform.clone().invert())

      partTransform.decompose(bpItemMesh.scaling, bpItemMesh.rotationQuaternion, bpItemMesh.position)
      parameterSetTransform.decompose(
        parameterSetScale.scaling,
        parameterSetScale.rotationQuaternion,
        parameterSetScale.position,
      )

      bpItemMesh.metadata.parameterSetScaleNode = parameterSetScale
      bpItemMesh.parent = parameterSetScale
    }

    if (this.isDisposed) {
      return
    }

    if (config.loadingPartIndex !== null && config.loadingPartIndex !== undefined) {
      this.updateLoadingPartMesh(config.loadingPartIndex, bpItemMesh)
    }

    if (config.loadingPartIndex === void 0 && this.renderScene.getGpuPicker()) {
      this.renderScene.getGpuPicker().addPickingObjects(bpItemMesh.getChildMeshes())
    }

    const parts = this.scene.transformNodes.filter((tn) => this.meshManager.isPartMesh(tn))
    this.updateSinterPlate(parts)
    if (config.shouldAdjustCamera) {
      this.renderScene.getActiveCamera().setNewTarget(this.scene)
    }

    if (this.selectionManager) {
      this.selectionManager.deselect()
    }

    if (
      this.renderScene.getSceneMode() === SceneMode.PreviewPart ||
      this.renderScene.getSceneMode() === SceneMode.SinglePart
    ) {
      this.updatePartPreviewPlate(bpItemMesh)
      this.defectsManager.updateDefectsScaling([DefectShapeType.Point])
      this.scene
        .getEngine()
        .getRenderingCanvas()
        .addEventListener('wheel', () => this.defectsManager.updateDefectsScaling([DefectShapeType.Point]))
    }
  }

  render(config: IRenderConfig) {
    if (this.isPartComponent(config.component)) {
      this.renderPartComponent(config)
    } else {
      this.renderAssemblyComponent(config)
    }
  }

  loadInsights(insights: IBuildPlanInsight[]) {
    const supportInsights = insights.filter((insight) => insight.tool === ToolNames.SUPPORT)
    this.supportManager.loadSupportInsights(supportInsights)
  }

  toggleDownwardPlaneRotation(isDownwardPlaneRotation: boolean) {
    const renderScene = this.renderScene as RenderScene
    const selectedBodies = []
    this.selectionManager
      .getSelected()
      .forEach((s) => selectedBodies.push(...s.getChildMeshes().filter((c) => this.meshManager.isComponentMesh(c))))

    if (isDownwardPlaneRotation) {
      renderScene.handleOuterEvent(OuterEvents.StartDownwardRotation, null)
      this.selectionManager.toggleGizmosVisibility(false)
      const materials = []
      selectedBodies.forEach((body) => {
        if (!body.sourceMesh || !body.sourceMesh.metadata) {
          return
        }

        if (body.sourceMesh.metadata.faces && body.sourceMesh.metadata.faces.length > 1) {
          if (
            !body.sourceMesh.metadata.faceMaterial ||
            !(body.sourceMesh.metadata.faceMaterial instanceof FaceColoringShader)
          ) {
            body.sourceMesh.metadata.faceMaterial = body.sourceMesh.faceMaterial = new FaceColoringShader(
              renderScene,
              this.scene.getMaterialByName(SHADER_SELECTION_MATERIAL_NAME) as StandardMaterial,
              this.scene.getMaterialByName(SHADER_HIGHLIGHT_MATERIAL_NAME) as StandardMaterial,
            )
          }

          materials.push(body.sourceMesh.metadata.faceMaterial.getMaterial())
        } else {
          if (!body.sourceMesh.metadata.facetPainter) {
            body.sourceMesh.metadata.facetPainter = new FacetColoringShader(
              renderScene,
              this.scene.getMaterialByName(SHADER_HIGHLIGHT_MATERIAL_NAME) as StandardMaterial,
            )
          }

          materials.push(body.sourceMesh.metadata.facetPainter.getMaterial())
        }
      })
      this.changeSelectedPartsMaterial(materials)

      this.downwardPlaneRotationPointerEvents = this.scene.onPointerObservable.add((evt) => {
        if (evt.type === PointerEventTypes.POINTERMOVE) {
          const pickInfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY, (m) => selectedBodies.includes(m))
          if (pickInfo.hit) {
            const pickedMesh = pickInfo.pickedMesh as InstancedMesh
            const facetData = pickedMesh.sourceMesh.getIndices()

            selectedBodies.forEach((body) => {
              if (body !== pickedMesh) {
                if (body.sourceMesh.metadata.faces && body.sourceMesh.metadata.faces.length > 1) {
                  body.instancedBuffers.hoverFaceId = new HoverableFace(-1)
                  body.sourceMesh.metadata.faceMaterial.showHover(false)
                } else {
                  body.sourceMesh.metadata.facetPainter.showHover(null, null)
                }
              }
            })

            if (pickedMesh.sourceMesh.metadata.faces && pickedMesh.sourceMesh.metadata.faces.length > 1) {
              const gpuPickInfo = renderScene.getGpuPicker().pick(this.scene.pointerX, this.scene.pointerY)
              if (gpuPickInfo && gpuPickInfo.face) {
                pickedMesh.instancedBuffers.hoverFaceId = new HoverableFace(gpuPickInfo.face.id)
                pickedMesh.sourceMesh.metadata.faceMaterial.showHover(true)
              }
            } else {
              pickedMesh.sourceMesh.metadata.facetPainter.showHover(
                pickedMesh.sourceMesh.instances.findIndex((i) => i === pickedMesh),
                new Vector3(
                  facetData[pickInfo.faceId * 3],
                  facetData[pickInfo.faceId * 3 + 1],
                  facetData[pickInfo.faceId * 3 + 2],
                ),
              )
            }
          } else {
            selectedBodies.forEach((body) => {
              if (body.sourceMesh.metadata.faces && body.sourceMesh.metadata.faces.length > 1) {
                body.instancedBuffers.hoverFaceId = new HoverableFace(-1)
                body.sourceMesh.metadata.faceMaterial.showHover(false)
              } else {
                body.sourceMesh.metadata.facetPainter.showHover(null, null)
              }
            })
          }
        }

        if (evt.type === PointerEventTypes.POINTERUP && evt.event.button === MouseButtons.LeftButton) {
          const pickInfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY, (m) => selectedBodies.includes(m))
          if (pickInfo.hit) {
            this.onDownwardPlaneRotationStarted.trigger()
            this.scene.onPointerObservable.remove(this.downwardPlaneRotationPointerEvents)
            const flipArrowLocationMesh = MeshBuilder.CreateSphere('flipArrowLocation', { diameter: 0.1 }, this.scene)
            flipArrowLocationMesh.isVisible = false
            flipArrowLocationMesh.position = pickInfo.pickedPoint
            flipArrowLocationMesh.setParent(pickInfo.pickedMesh)
            this.rotateDownward(pickInfo.getNormal(true, true), true, pickInfo.pickedMesh as InstancedMesh)
          }
        }
      })
    } else {
      selectedBodies.forEach((body) => {
        this.disableDownwardPlaneRotation(body.sourceMesh.metadata.facetPainter)
        body.sourceMesh.metadata.facetPainter = null
      })
    }
  }

  flipSelectedParts() {
    this.rotateDownward(Axis.Z, false)
  }

  // Param materials - the list of materials for selected parts according to part index
  // or put common material at the first place in materials array
  changeSelectedPartsMaterial(materials: Material[]) {
    if (!materials || !materials.length) {
      return
    }

    const selectedBodies = []
    this.selectionManager
      .getSelected()
      .forEach((s) => selectedBodies.push(...s.getChildMeshes().filter((c) => this.meshManager.isComponentMesh(c))))
    for (let i = 0; i < selectedBodies.length; i += 1) {
      const material = materials.length > i ? materials[i] : materials[0]
      selectedBodies[i].sourceMesh.material = selectedBodies[i].sourceMesh.metadata.originalMaterial = material
    }
    ; (this.renderScene as RenderScene).animate(true)
  }

  updateGeometriesType(items: Array<{ buildPlanItemId: string; bodyIds?: string[] }>, type: GeometryType) {
    for (const item of items) {
      const bpItemMesh = this.meshManager.getBuildPlanItemMeshById(item.buildPlanItemId)
      let bodyIdsInfo = null
      if (item.bodyIds) {
        bodyIdsInfo = item.bodyIds.map((id) => this.getBodyIdsInfo(id))
      }

      bpItemMesh.getChildMeshes().forEach((body) => {
        const metadata = body.metadata as IComponentMetadata
        if (
          metadata &&
          this.meshManager.isComponentMesh(body) &&
          (!bodyIdsInfo ||
            bodyIdsInfo.some(
              (info) => metadata.componentId === info.componentId && metadata.geometryId === info.geometryId,
            ))
        ) {
          body.metadata.bodyType = type
        }
      })
    }

    // Check components stability
    this.triggerStabilityCheck()
  }

  triggerStabilityCheck() {
    if (this.subType !== ItemSubType.SinterPlan) {
      return
    }

    // Clear geometry data to collect new one
    this.getCheckerInstance().resetGeometryData()

    const reports = this.getCheckerInstance().check()
    // report insight example need to find out what should be passed
    const insights = []
    for (const report of reports) {
      if (
        report.type === SnackbarMessageType.Success &&
        // Debug
        !this.getCheckerInstance().debugStability
      ) {
        continue
      }

      insights.push({
        errorCode: InsightErrorCodes.UnbalancedPart,
        accepted: false,
        itemId: (this.renderScene as RenderScene).buildPlanId,
        details: {
          bpItemId: report.id,
        },
        severity: InsightsSeverity.Error,
        tool: ToolNames.STABILITY, // To avoid mutual influence of insights,
        // the separate tool is used to mark unstable insight
      } as IBuildPlanInsight)
    }

    this.insightsManager.reportInsights([{ insights, tool: ToolNames.STABILITY }])
  }

  triggerSafeDosingHeightCheck() {
    const renderScene = this.renderScene as RenderScene
    // Test for parts taller than the safe dosing height (H2 machine only, sinter plans)
    if (renderScene.buildPlanType !== ItemSubType.SinterPlan || renderScene.machineConfig !== H2_MACHINE_CONFIG_NAME) {
      return
    }

    const insights = []

    const bpItemMeshes: TransformNode[] = Array.from(this.scene.metadata.buildPlanItems.values())
    for (const bpItemMesh of bpItemMeshes) {
      const bpItemId = bpItemMesh.metadata.buildPlanItemId
      const bpItemDimensions = renderScene.getBpItemDimensions(bpItemId)

      if (bpItemDimensions.zDimension >= MAX_SAFE_DOSING_HEIGHT_MM) {
        insights.push(this.insightsManager.buildPartTallerThanSafeDosingHeight(bpItemId))
      }
    }

    const pendingInsights = [...store.getters['buildPlans/pendingInsights']]
    const pendingLayoutInsights = pendingInsights.find((pi) => pi.tool === ToolNames.LAYOUT)

    if (pendingLayoutInsights) {
      pendingLayoutInsights.insights = [
        ...pendingLayoutInsights.insights.filter(
          (i) => i.errorCode !== InsightErrorCodes.LayoutToolPartTallerThanSafeDosingHeight,
        ),
        ...insights,
      ]
    } else {
      pendingInsights.push({
        insights,
        tool: ToolNames.LAYOUT,
      })
    }

    this.insightsManager.reportInsights(pendingInsights)
  }

  showPartFootprintAndCG(bpItemId: string) {
    this.getCheckerInstance().showFootprintAndCG(bpItemId)
  }

  hidePartFootprintAndGC() {
    this.getCheckerInstance().hideChecks()
  }

  /**
   * Replaces build plan items. Saves position center of bounding box and orientation.
   * @param configs The array of configs.
   * Each element is part config for new build plan item and the ID of the item which need to replace.
   */
  async replaceBuildPlanItems(configs: Array<{ partConfig: IPartRenderable; targetBuildPlanItemId: string }>) {
    this.selectionManager.deselect(null, false)

    // Saves orientation and remove target parts.
    type BodySavedProperties = {
      showAsTransparent: boolean
      isHidden: boolean
    }
    type PartProperty = {
      centerOfBBox: Vector3
      rotationQuaternion: Quaternion
      constraints: IConstraints
      bodyProperties: BodySavedProperties
    }
    const savedProperties: PartProperty[] = []

    const targetParts = configs.map((config) => this.meshManager.getBuildPlanItemMeshById(config.targetBuildPlanItemId))
    for (const targetPart of targetParts) {
      const targetMeshes = targetPart.getChildMeshes(false, this.meshManager.isComponentMesh)
      const oldBBoxInfo = this.meshManager.getTotalBoundingInfo(targetMeshes)

      // Saves bodies visibilyty property. All bodies of part have the same visibility property on BJ build plan.
      const firstBody = targetPart.getChildMeshes(false, this.meshManager.isComponentMesh)[0]
      const bodyProperties: BodySavedProperties = {
        showAsTransparent: firstBody.metadata.showAsTransparent,
        isHidden: firstBody.metadata.isHidden,
      }

      savedProperties.push({
        bodyProperties,
        centerOfBBox: oldBBoxInfo.boundingBox.centerWorld,
        rotationQuaternion: targetPart.rotationQuaternion.clone(),
        constraints: targetPart.metadata.constraints,
      })
    }

    const buildPlanItemIdsToDelete = configs.map((config) => config.targetBuildPlanItemId)

    // Saves outgoing items for undo/redo command.
    let allBPItems = (await store.getters['buildPlans/getAllBuildPlanItems']) as IBuildPlanItem[]
    const tempOutgoingBuildPlanItems = allBPItems.filter((item) => buildPlanItemIdsToDelete.includes(item.id))
    const outgoingBuildPlanItems = JSON.parse(JSON.stringify(tempOutgoingBuildPlanItems))

    const bpItems: TransformNode[] = []
    await this.loadPartConfigs(configs.map((config) => config.partConfig))
    for (let index = 0; index < configs.length; index += 1) {
      // Starts load new part.
      const config = configs[index]
      const buildPlanItem = this.loadingParts.find((loadingPart) => {
        return loadingPart.loadingPartIndex === config.partConfig.loadingPartIndex
      }).mesh

      // Hides new part.
      buildPlanItem.getChildMeshes(false, this.meshManager.isComponentMesh).forEach(async (body) => {
        this.meshManager.setIsHidden(body as InstancedMesh, true, false)
      })

      // Places part instance of target part.
      const partProperty = savedProperties[index]
      if (this.meshManager.isSinterPartMesh(buildPlanItem) || this.meshManager.isIBCPartMesh(buildPlanItem)) {
        const eulerAngles = partProperty.rotationQuaternion.toEulerAngles()
        eulerAngles.x = eulerAngles.x % Math.PI
        eulerAngles.y = eulerAngles.y % Math.PI
        eulerAngles.z = eulerAngles.z % Math.PI
        const isValidSinterPartOrientation = eulerAngles.equalsWithEpsilon(Vector3.Zero(), Epsilon)
        if (isValidSinterPartOrientation) {
          buildPlanItem.rotationQuaternion = partProperty.rotationQuaternion.clone()
        }
      } else {
        buildPlanItem.rotationQuaternion = partProperty.rotationQuaternion.clone()
      }
      this.meshManager.translateBBoxCenterToPosition(buildPlanItem, partProperty.centerOfBBox)
      this.selectionManager.gizmos.placeAboveGround(buildPlanItem, false)
      buildPlanItem.metadata.hullBInfo = this.meshManager.getHullBInfo(buildPlanItem)
      buildPlanItem.metadata.constraints = partProperty.constraints

      bpItems.push(buildPlanItem)
    }

    // Copies visibility from old build plan items.
    for (let index = 0; index < bpItems.length; index += 1) {
      const buildPlanItem = bpItems[index]
      const savedProperty = savedProperties[index]

      buildPlanItem.getChildMeshes(false, this.meshManager.isComponentMesh).forEach(async (body) => {
        this.meshManager.setIsHidden(
          body as InstancedMesh,
          savedProperty.bodyProperties.isHidden,
          savedProperty.bodyProperties.showAsTransparent,
        )
      })
    }

    // Saves new build plan items.
    await this.saveLoadingParts(configs)
    await Promise.all(configs.map((config) => this.disposeLoadingPart(config.partConfig.loadingPartIndex)))

    const selectableNodes = bpItems.map((buildPlanItem) => {
      return { part: buildPlanItem }
    })
    this.selectionManager.select(selectableNodes, false, false)
    this.selectionManager.showGizmos()

    // Updates all build plan items after replace operation.
    allBPItems = (await store.getters['buildPlans/getAllBuildPlanItems']) as IBuildPlanItem[]

    // Saves incoming items for undo/redo command.
    const tempIncomingBuildPlanItems = allBPItems.filter((item) => {
      return bpItems.some((tn) => (tn.metadata as IPartMetadata).buildPlanItemId === item.id)
    })
    const incomingBuildPlanItems = JSON.parse(JSON.stringify(tempIncomingBuildPlanItems))

    this.renderScene.getInsightsManager().deleteCollisionInsights(buildPlanItemIdsToDelete, false)

    await store.dispatch('buildPlans/addReplaceUndoRedoCommand', { incomingBuildPlanItems, outgoingBuildPlanItems })
  }

  updateSinterPlate(parts: TransformNode[]) {
    this.buildPlateManager.removeGroundPlane()
    if (parts.length > 0) {
      // use getHierarchyBoundingVectors instead of meshManager.getTotalBoundingInfo to optimize
      // because second iterate each vertex every time
      const partBInfos = []
      parts.forEach((part) => {
        const boundingVectors = part.getHierarchyBoundingVectors(true, (mesh) => mesh.name !== BVH_BOX)
        partBInfos.push(new BoundingInfo(boundingVectors.min, boundingVectors.max))
      })
      const totalBBox = this.meshManager.mergeBoundingInfos(partBInfos)
      this.buildPlateManager.createGroundSinterPlate(totalBBox)
    } else {
      this.buildPlateManager.createGroundSinterPlate()
    }
  }

  async loadMeasurementsModel(id: string, isVisible: boolean, transformation: number[]) {
    const mesh = await partsService
      .getFileById(id)
      .then((data) => this.decompressMesh(id, 'Measurements', undefined, data))

    mesh.isVisible = isVisible
    const defaultMaterial = this.scene.getMaterialByName(INSPECTION_MATERIAL_NAME)
    mesh.material = defaultMaterial
    mesh.renderingGroupId = MESH_RENDERING_GROUP_ID

    const meshTransform = Matrix.FromArray(transformation).transpose()
    const translateVector = meshTransform.getTranslation().clone()
    meshTransform.setTranslation(translateVector)
    this.meshManager.transformMesh(mesh, meshTransform.transpose(), false, false)
    mesh.computeWorldMatrix()
  }

  private rotateDownward(normal: Vector3, fromPick: boolean, body?: InstancedMesh) {
    const renderScene = this.renderScene as RenderScene
    const scene = this.scene

    // TODO: try to make 1 universal method to rotate part
    // renderScene.rotatePart(), gizmo.transformSelectedParts(), modelManager.rotatePartToNormal()
    const itemToRotate = this.selectionManager.getSelected(true)
    this.selectionManager.gizmos.translateLabelsOrientation(itemToRotate.getChildMeshes(), false)
    const hasSupports =
      itemToRotate.getChildTransformNodes().findIndex((m) => this.meshManager.isSupportMesh(m)) !== -1 ? true : false
    if (hasSupports) {
      this.selectionManager.gizmos.deleteOverhangsAndSupports(this.selectionManager.getSelected(true), true)
    }

    // Remove parameterSet scale
    const parts = itemToRotate.getChildTransformNodes(false, this.meshManager.isPartMesh)
    this.selectionManager.gizmos.detachScaleNodes(parts)
    itemToRotate.computeWorldMatrix(true)

    this.onAfterDownwardRotation = (isSheetBody: boolean) => {
      ; (renderScene as RenderScene).stopAnimate()
      if (fromPick) {
        const selectedBodies = []
        this.selectionManager
          .getSelected()
          .forEach((s) => selectedBodies.push(...s.getChildMeshes().filter((c) => this.meshManager.isComponentMesh(c))))
        selectedBodies.forEach((selectedBody) => {
          this.disableDownwardPlaneRotation(selectedBody.sourceMesh.metadata.facetPainter)
          selectedBody.sourceMesh.metadata.facetPainter = null
        })

        const flipArrowLocation = this.calculateFlipArrowLocation()
        this.scene.getMeshByName('flipArrowLocation').dispose()
        this.onDownwardPlaneRotationEnded.trigger({ isSheetBody, flipArrowLocation })
      }

      itemToRotate.computeWorldMatrix(true)
      this.selectionManager.gizmos.attachScaleNodes(parts)

      const ignoreMinZCheck = renderScene.modality === PrintingTypes.BinderJet ? true : false
      if (this.selectionManager.gizmos.rotatePartsIndependentlyMode) {
        parts.forEach((part) => this.selectionManager.gizmos.placeAboveGround(part, ignoreMinZCheck))
      } else {
        this.selectionManager.gizmos.placeAboveGround(itemToRotate, ignoreMinZCheck)
      }
      renderScene.checkCollision(parts)

      itemToRotate.computeWorldMatrix(true)
      const children = itemToRotate.getChildTransformNodes(false, this.meshManager.isPartMesh)
      children.forEach(async (child) => {
        child.computeWorldMatrix()
      })

      this.selectionManager.gizmos.setAfterActionTransformation(children)
      this.selectionManager.gizmos.saveTransformationBatch(children, false)

      const selectedParts = this.selectionManager.getSelected()
      this.selectionManager.deselect()
      this.selectionManager.select(
        selectedParts.map((part) => ({ part })),
        true,
      )
      this.selectionManager.showGizmos()
      this.triggerStabilityCheck()
      this.triggerSafeDosingHeightCheck()
    }

    // For sheet bodies calculate which normal of the selected plane is closer
    // to pointing in the build plan -Z direction
    const negativeZ = new Vector3(0, 0, -1)
    const isSheet = fromPick ? this.meshManager.isSheetBody(body) : null
    let sourceNormal = normal
    if (isSheet) {
      const oppositeNormal = normal.clone().negate()
      const angle1 = Vector3.GetAngleBetweenVectors(normal, negativeZ, Vector3.Cross(normal, negativeZ))
      const angle2 = Vector3.GetAngleBetweenVectors(oppositeNormal, negativeZ, Vector3.Cross(oppositeNormal, negativeZ))
      if (Math.abs(angle1 - angle2) > Epsilon && angle2 < angle1) {
        sourceNormal = oppositeNormal
      }
    }

    this.selectionManager.gizmos.setBeforeActionTransformation(parts)

    // Rotate part from picked facet normal to -Z
    if (this.selectionManager.gizmos.rotatePartsIndependentlyMode) {
      this.rotatePartsToNormal(parts, sourceNormal, negativeZ, isSheet, this.onAfterDownwardRotation)
    } else {
      this.rotatePartToNormal(itemToRotate, sourceNormal, negativeZ, isSheet, this.onAfterDownwardRotation)
    }
  }

  private rotatePartToNormal(
    part: TransformNode,
    sourceNormal: Vector3,
    targetNormal: Vector3,
    hasSheetBodies?: boolean,
    onRotatedCallback?: (isSheetBody: boolean) => void,
  ) {
    let rotationAxis: Vector3
    if (
      Math.abs(sourceNormal.x - targetNormal.x) < Epsilon &&
      Math.abs(sourceNormal.y - targetNormal.y) < Epsilon &&
      Math.abs(sourceNormal.z + targetNormal.z) < Epsilon
    ) {
      rotationAxis = Axis.Y
    } else {
      rotationAxis = Vector3.Cross(sourceNormal, targetNormal).normalize()
    }

    const angle = Vector3.GetAngleBetweenVectors(sourceNormal, targetNormal, rotationAxis)

    part.rotate(rotationAxis, angle, Space.WORLD)
    const targetRotation = part.rotationQuaternion.clone()
    part.rotate(rotationAxis, -angle, Space.WORLD)

    const speed = 75
    const frameCount = 50
      ; (this.renderScene as RenderScene).animate(true, true)

    // render scene camera animation
    Animation.CreateAndStartAnimation(
      'rotationToNormal',
      part,
      'rotationQuaternion',
      speed,
      frameCount,
      part.rotationQuaternion.clone(),
      targetRotation,
      0,
      null,
      () => setTimeout(() => onRotatedCallback(hasSheetBodies), 0),
    )
  }

  private rotatePartsToNormal(
    parts: TransformNode[],
    sourceNormal: Vector3,
    targetNormal: Vector3,
    hasSheetBodies?: boolean,
    onRotatedCallback?: (isSheetBody: boolean) => void,
  ) {
    let rotationAxis: Vector3
    if (
      Math.abs(sourceNormal.x - targetNormal.x) < Epsilon &&
      Math.abs(sourceNormal.y - targetNormal.y) < Epsilon &&
      Math.abs(sourceNormal.z + targetNormal.z) < Epsilon
    ) {
      rotationAxis = Axis.Y
    } else {
      rotationAxis = Vector3.Cross(sourceNormal, targetNormal).normalize()
    }

    const angle = Vector3.GetAngleBetweenVectors(sourceNormal, targetNormal, rotationAxis)

    const speed = 75
    const frameCount = 50
      ; (this.renderScene as RenderScene).animate(true, true)

    // render scene camera animation
    const animations = parts.map((part) => {
      const parent = part.parent
      const transformNode = new TransformNode('tempAnimationRotate', this.scene)
      transformNode.position = (part.metadata as IPartMetadata).hullBInfo.boundingBox.centerWorld
      part.setParent(transformNode)

      transformNode.rotate(rotationAxis, angle, Space.WORLD)
      const targetRotation = transformNode.rotationQuaternion.clone()
      transformNode.rotate(rotationAxis, -angle, Space.WORLD)

      // render scene camera animation
      return Animation.CreateAndStartAnimation(
        'rotationToNormal',
        transformNode,
        'rotationQuaternion',
        speed,
        frameCount,
        transformNode.rotationQuaternion.clone(),
        targetRotation,
        0,
        null,
        () => {
          part.setParent(parent)
          transformNode.dispose()
        },
      )
    })

    Promise.all(animations.map((animation) => animation.waitAsync())).then(() =>
      setTimeout(() => onRotatedCallback(hasSheetBodies), 0),
    )
  }

  private disableDownwardPlaneRotation(facetPainter?: FacetColoringShader) {
    ; (this.renderScene as RenderScene).handleOuterEvent(OuterEvents.EndDownwardRotation, null)
    this.selectionManager.toggleGizmosVisibility(true)
    this.scene.onPointerObservable.remove(this.downwardPlaneRotationPointerEvents)
    this.changeSelectedPartsMaterial([this.scene.getMaterialByName(DEFAULT_MATERIAL_NAME)])
    if (facetPainter) {
      facetPainter.dispose()
    }
  }

  private getMaxDistanceFromPlateInMM(bpItem: IBuildPlanItem) {
    // layer thickness is stored in meters, convert it to millimeters here
    const bpItemLayerThicknessesInMM = bpItem.partProperties.map((pp) => {
      let printStrategyParameterSetPk: VersionablePk
      if (pp.printStrategyParameterSetId) {
        printStrategyParameterSetPk = new VersionablePk(
          pp.printStrategyParameterSetId,
          pp.printStrategyParameterSetVersion,
        )
      } else {
        const printStrategy: BuildPlanPrintStrategyDto = store.getters['buildPlans/getBuildPlanPrintStrategy']
        printStrategyParameterSetPk = getDefaultBaseOnType(printStrategy.defaults, pp.type, pp.bodyType)
      }
      const printStrategyPartParameter: IPrintStrategyParameterSet =
        store.getters['buildPlans/getPrintStrategyParameterSetByPk'](printStrategyParameterSetPk)
      return printStrategyPartParameter.layerThickness * M_TO_MM
    })
    // setting the max distance from build plate to 0.5 * min layer thickness
    // to ensure that there is a slice at the first powder layer
    // and also taking into account the default max allowed distance, dictated by the Barracuda
    const halfMinLayerThickness = Math.min(...bpItemLayerThicknessesInMM) * 0.5
    return Math.min(halfMinLayerThickness, DEFAULT_MAX_DISTANCE_TO_BUILD_PLATE)
  }

  private calculateGeometryProperties(mesh: TransformNode): IMeshGeometryProperties {
    // calculate mesh surface area and volume
    const children = mesh.getChildMeshes(false, this.meshManager.isComponentMesh)
    let surfaceArea = 0
    let volume = 0

    for (const child of children) {
      const childMatrix = child.computeWorldMatrix(true)
      surfaceArea += this.meshManager.calculateMeshSurfaceArea(child, childMatrix)
      volume += this.meshManager.calculateGeometryVolume(child, childMatrix)
    }

    return { surfaceArea, volume }
  }

  private calculateSupportGeometryProperties(
    supportNode: TransformNode,
    transformation: Matrix,
  ): IMeshGeometryProperties {
    let surfaceArea = 0
    let volume = 0
    for (const support of supportNode.getChildMeshes().filter((c) => !c.name.includes(SUPPORT_INSIDE_MESH_NAME))) {
      surfaceArea += this.meshManager.calculateMeshSurfaceArea(support, transformation)
      volume += this.meshManager.calculateGeometryVolume(support, support.getWorldMatrix())
    }

    return { surfaceArea, volume }
  }

  private calculateFlipArrowLocation() {
    const arrowIconWidth = 20 // from styles
    const arrowIconHeight = 20 // from styles
    const offset = convertPixelToMillimeter(this.selectionManager.gizmos.sphereDiameter / 2)
    const longerSide = arrowIconWidth
    const flipArrowLocationMesh = this.scene.getMeshByName('flipArrowLocation')
    const arrowLocation3D = flipArrowLocationMesh ? flipArrowLocationMesh.absolutePosition : Vector3.Zero()
    const arrowLoc = this.meshManager.project3DPointOntoScreen(arrowLocation3D)

    const gizmoSphereMeshes = this.selectionManager.gizmos.getSphereMeshes()
    const gizmoVertices = []
    for (const mesh of gizmoSphereMeshes) {
      const v1 = mesh.absolutePosition.clone()
      gizmoVertices.push(v1)
    }

    const screenPoints = gizmoVertices.map((v: Vector3) => this.meshManager.project3DPointOntoScreen(v))
    const screenX = screenPoints[0]
    const screenY = screenPoints[1]
    const screenZ = screenPoints[2]

    // Check if flip arrow position is inside or outside gizmo triangle
    const isInside = this.meshManager.isPointInsideTriangle(arrowLoc, screenX, screenY, screenZ)

    // Calculate distance from flip arrow position to each triangle side
    const dXY = this.meshManager.distanceFromPointToLine(arrowLoc, screenX, screenY)
    const dXZ = this.meshManager.distanceFromPointToLine(arrowLoc, screenX, screenZ)
    const dYZ = this.meshManager.distanceFromPointToLine(arrowLoc, screenY, screenZ)

    let finalArrowLoc: { x: number; y: number } = arrowLoc
    if (dXY < dXZ && dXY < dYZ) {
      if (isInside || (!isInside && dXY < longerSide)) {
        const proj = this.meshManager.projectionPointOnLine(arrowLoc, screenX, screenY)
        const av1 = new Vector2(proj.x - (arrowIconWidth + offset), proj.y)
        const av2 = new Vector2(proj.x, proj.y + (arrowIconHeight + offset))
        finalArrowLoc = new Vector2((av1.x + av2.x) / 2, (av1.y + av2.y) / 2)
      }
    } else if (dXZ < dXY && dXZ < dYZ) {
      if (isInside || (!isInside && dXZ < longerSide)) {
        const proj = this.meshManager.projectionPointOnLine(arrowLoc, screenX, screenZ)
        const av1 =
          screenX.x > screenY.x
            ? new Vector2(proj.x + (2 * arrowIconWidth + offset), proj.y)
            : new Vector2(proj.x - (2 * arrowIconWidth + offset), proj.y)
        const av2 = new Vector2(proj.x, proj.y - (2 * arrowIconHeight + offset))
        finalArrowLoc = new Vector2((av1.x + av2.x) / 2, (av1.y + av2.y) / 2)
      }
    } else {
      if (isInside || (!isInside && dYZ < longerSide)) {
        const proj = this.meshManager.projectionPointOnLine(arrowLoc, screenY, screenZ)
        const av1 =
          screenX.x > screenY.x
            ? new Vector2(proj.x - (2 * arrowIconWidth + offset), proj.y)
            : new Vector2(proj.x + (2 * arrowIconWidth + offset), proj.y)
        const av2 = new Vector2(proj.x, proj.y - (2 * arrowIconHeight + offset))
        finalArrowLoc = new Vector2((av1.x + av2.x) / 2, (av1.y + av2.y) / 2)
      }
    }

    return finalArrowLoc
  }

  private isPartComponent(component: IComponent): component is PartComponent {
    if ((component as PartComponent).partID) {
      return true
    }

    return false
  }

  private async loadFileAsync(url: string) {
    return new Promise((resolve, reject) => {
      fetch(url, { credentials: 'include' })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Could not load file')
          }
          return response.arrayBuffer()
        })
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
          console.error(error)
        })
    })
  }

  private async decompressOverhangMesh(geometryId: string, geometryName: string, drc: ArrayBuffer, transform: Matrix) {
    if (this.isDisposed) {
      return
    }

    const attributes = await DracoDecoder.Default.getFaceAndPositionAttributes(drc as ArrayBuffer)
    return {
      surface: this.extractOverhangMesh(attributes, geometryId, geometryName),
      contours: this.extractOverhangTriangleContour(attributes, transform),
      edges: this.extractOverhangEdges(attributes, transform),
      vertices: this.extractOverhangVertices(attributes, transform),
    }
  }

  private extractOverhangMesh(attributes: FacePositionAttributes, geometryId: string, geometryName: string) {
    const overhangFaces = attributes.faces.filter((f) => f.name.startsWith(OVERHANG_TRIANGLE_NAME))
    const indicesMap = new Map<number, number>()
    const vertices: number[] = []
    const indices: number[] = []
    const verticesFaceIds: number[] = []
    const verticesColors: number[] = []
    let currentIndex = 0

    overhangFaces.forEach((face) => {
      for (let i = 0; i < face.indices.length; i += 1) {
        const v = face.indices[i]
        if (!indicesMap.has(v)) {
          indicesMap.set(v, currentIndex)
          currentIndex += 1
          vertices.push(attributes.positions[3 * v], attributes.positions[3 * v + 1], attributes.positions[3 * v + 2])
          verticesFaceIds.push(face.id)
          verticesColors.push(face.color.r, face.color.g, face.color.b)
        }

        face.indices[i] = indicesMap.get(v)
        indices.push(face.indices[i])
      }
    })

    const vertexData = new VertexData()
    vertexData.indices = indices
    vertexData.positions = vertices
    const geometry = new Geometry(geometryId, this.scene, vertexData) as any
    geometry.name = geometryName

    // create mesh
    const mesh = new Mesh(geometryName, this.scene)
    geometry.applyToMesh(mesh, true)
    mesh.id = geometryId
    mesh.name = geometryName
    mesh.isVisible = false
    mesh.metadata = {
      geometryId,
      faces: attributes.faces,
      selectedFacesIds: new Map<number, number[]>(),
      collectorFacesIds: new Map<number, Array<{ faceId: number; selectionColor: Color3 }>>(),
      collectorHighlightedFacesIds: new Map<number, number[]>(),
    }

    const meshOutsideMaterial = this.scene.getMaterialByName(DEFAULT_MATERIAL_NAME)
    mesh.material = mesh.metadata.originalMaterial = meshOutsideMaterial
    if (this.renderScene.getGpuPicker()) {
      mesh.metadata.pickingShader = this.renderScene.getGpuPicker().pickingShader
    }

    // initialize buffer with faceId for each vertex
    const faceIdBuffer = new Buffer(this.scene.getEngine(), verticesFaceIds, false)
    mesh.setVerticesBuffer(faceIdBuffer.createVertexBuffer(FACE_ID_ATTRIBUTE, 0, 1))
    // initialize buffer with unique color for each overhang surface
    const buffer = new Buffer(this.scene.getEngine(), verticesColors, false)
    mesh.setVerticesBuffer(buffer.createVertexBuffer(COLOR_FOR_FACE, 0, 3))
    // initialize buffer with unique color for each part
    mesh.registerInstancedBuffer(COLOR_FOR_PART, 3)
    mesh.instancedBuffers.pColor = Color3.White()
    // initialize buffer with unique color for each body
    mesh.registerInstancedBuffer(COLOR_FOR_BODY, 3)
    mesh.instancedBuffers.bColor = Color3.White()
    mesh.registerInstancedBuffer(HOVER_FACE_ID, 1)
    mesh.instancedBuffers.hoverFaceId = new HoverableFace(-1)
    mesh.registerInstancedBuffer(COLLECTOR_INSTANCE_ID, 1)
    mesh.instancedBuffers.collectorInstanceId = new HoverableFace(-1)
    return mesh
  }

  private extractOverhangTriangleContour(attributes: FacePositionAttributes, transform: Matrix) {
    const ovhgVertices = attributes.faces.filter((face) => face.name.startsWith(OVERHANG_CONTOUR_NAME))

    const overhangContourData = {
      vertices: new Map<string, Vector3[]>(),
      box: new Map<string, Vector3[]>(),
    }

    for (const vertex of ovhgVertices) {
      const size = vertex.indices.length - 2
      const contourVertices = []
      for (let i = 0; i < size; i += 3) {
        const index1 = vertex.indices[i] * 3
        const index2 = vertex.indices[i + 1] * 3
        const index3 = vertex.indices[i + 2] * 3

        const v1 = Vector3.FromArray(attributes.positions, index1)
        const w1 = Vector3.TransformCoordinates(v1, transform)
        let currentMaxZ = w1.z
        contourVertices.push(v1)

        const v2 = Vector3.FromArray(attributes.positions, index2)
        const w2 = Vector3.TransformCoordinates(v2, transform)
        if (w2.z > currentMaxZ) {
          currentMaxZ = w2.z
          contourVertices[contourVertices.length - 1] = v2
        }

        const v3 = Vector3.FromArray(attributes.positions, index3)
        const w3 = Vector3.TransformCoordinates(v3, transform)
        if (w3.z > currentMaxZ) {
          contourVertices[contourVertices.length - 1] = v3
        }
      }

      overhangContourData.vertices.set(vertex.name, contourVertices)
    }

    const ovhgSurfaces = attributes.faces.filter((face) => face.name.startsWith(OVERHANG_TRIANGLE_NAME))

    for (const surface of ovhgSurfaces) {
      const size = surface.indices.length - 2
      const max = new Vector3(Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER)
      const min = new Vector3(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER)
      const lBoundingBox = new BoundingInfo(min, max, transform)

      for (let i = 0; i < size; i += 3) {
        const index1 = surface.indices[i] * 3
        const index2 = surface.indices[i + 1] * 3
        const index3 = surface.indices[i + 2] * 3
        lBoundingBox.encapsulate(Vector3.FromArray(attributes.positions, index1))
        lBoundingBox.encapsulate(Vector3.FromArray(attributes.positions, index2))
        lBoundingBox.encapsulate(Vector3.FromArray(attributes.positions, index3))
      }

      // Save bounding box data for corresponding contour
      overhangContourData.box.set(surface.name.replace(OVERHANG_TRIANGLE_NAME, OVERHANG_CONTOUR_NAME), [
        lBoundingBox.minimum,
        lBoundingBox.maximum,
      ])
    }

    return overhangContourData
  }

  private extractOverhangEdges(attributes: FacePositionAttributes, transform: Matrix) {
    const lines: IEdgeInfo[] = []
    const ovhgEdges = attributes.faces.filter((face) => face.name.startsWith(OVERHANG_EDGES_NAME))

    for (const ovhgEdge of ovhgEdges) {
      const edgeInfo: IEdgeInfo = {
        name: ovhgEdge.name,
        id: ovhgEdge.id,
        color: ovhgEdge.color,
        path: undefined,
      }

      const edges: Vector3[][] = []
      const size = ovhgEdge.indices.length - 2
      for (let i = 0; i < size; i += 3) {
        const index1 = ovhgEdge.indices[i] * 3
        const index2 = ovhgEdge.indices[i + 1] * 3
        const index3 = ovhgEdge.indices[i + 2] * 3

        const localPoints: Vector3[] = []
        const worldPoints: Vector3[] = []

        const v1 = Vector3.FromArray(attributes.positions, index1)
        localPoints.push(v1)
        worldPoints.push(Vector3.TransformCoordinates(v1, transform))

        const v2 = Vector3.FromArray(attributes.positions, index2)
        localPoints.push(v2)
        worldPoints.push(Vector3.TransformCoordinates(v2, transform))

        const v3 = Vector3.FromArray(attributes.positions, index3)
        localPoints.push(v3)
        worldPoints.push(Vector3.TransformCoordinates(v3, transform))

        // remove point with minimum z
        let minZIndex = 0
        worldPoints.forEach((vec, idx) => {
          minZIndex = worldPoints[minZIndex].z < vec.z ? minZIndex : idx
        })
        localPoints.splice(minZIndex, 1)

        edges.push([localPoints[0], localPoints[1]])
      }

      edgeInfo.path = this.buildPath(edges)
        ; (ovhgEdge as any).obb = this.meshManager.computeOptimalObbFromVertices(edgeInfo.path)
      lines.push(edgeInfo)
    }

    return lines
  }

  private buildPath(edges: Vector3[][]) {
    const line: Vector3[] = edges.shift()
    let n = 0

    while (edges.length > 0 && n < edges.length) {
      if (line[0].equalsWithEpsilon(edges[n][0])) {
        line.unshift(edges[n][1])
        edges.splice(n, 1)
        n = 0
      } else if (line[0].equalsWithEpsilon(edges[n][1])) {
        line.unshift(edges[n][0])
        edges.splice(n, 1)
        n = 0
      } else if (line[line.length - 1].equalsWithEpsilon(edges[n][0])) {
        line.push(edges[n][1])
        edges.splice(n, 1)
        n = 0
      } else if (line[line.length - 1].equalsWithEpsilon(edges[n][1])) {
        line.push(edges[n][0])
        edges.splice(n, 1)
        n = 0
      } else {
        n += 1
      }
    }

    return line
  }

  private extractOverhangVertices(attributes: FacePositionAttributes, transform: Matrix) {
    const verticesInfo: IVertexInfo[] = []
    const ovhgVertices = attributes.faces.filter((face) => face.name.startsWith(OVERHANG_VERTICES_NAME))

    for (const vertex of ovhgVertices) {
      const size = vertex.indices.length - 2
      for (let i = 0; i < size; i += 3) {
        const index1 = vertex.indices[i] * 3
        const index2 = vertex.indices[i + 1] * 3
        const index3 = vertex.indices[i + 2] * 3

        const v1 = Vector3.FromArray(attributes.positions, index1)
        const w1 = Vector3.TransformCoordinates(v1, transform)
        let origin = v1
        let currentMaxZ = w1.z

        const v2 = Vector3.FromArray(attributes.positions, index2)
        const w2 = Vector3.TransformCoordinates(v2, transform)
        if (w2.z > currentMaxZ) {
          origin = v2
          currentMaxZ = w2.z
        }

        const v3 = Vector3.FromArray(attributes.positions, index3)
        const w3 = Vector3.TransformCoordinates(v3, transform)
        if (w3.z > currentMaxZ) {
          origin = v2
        }

        verticesInfo.push({
          origin,
          name: vertex.name,
          color: vertex.color,
          id: vertex.id,
        })
      }
    }

    return verticesInfo
  }

  private async decompressLabelMeshes(
    geometryId: string,
    geometryName: string,
    url?: string,
    drc?: ArrayBuffer,
  ) {
    const data = drc || (await this.loadFileAsync(url))
    if (this.isDisposed) {
      return
    }
    // extract united vertex data for all labels from draco file
    const vertexDataUnited = await DracoDecoder.Default.decodeMesh(data as ArrayBuffer)
    // extract geometry faces from draco file
    const faceAttr: FaceAttribute = await DracoDecoder.Default.getFaceAttribute(data as ArrayBuffer)

    const labelMeshes: Mesh[] = []
    for (const face of faceAttr.faces) {
      const positionData = []
      const indices = []
      const newFace = new Face(0, face.name, indices, undefined, face.color)

      for (let i = 0; i < face.indices.length; i += 3) {
        for (let j = 0; j < 3; j += 1) {
          const index = face.indices[i + j]
          positionData.push(vertexDataUnited.positions[index * 3 + 0])
          positionData.push(vertexDataUnited.positions[index * 3 + 1])
          positionData.push(vertexDataUnited.positions[index * 3 + 2])
          indices.push(i + j)
        }
        newFace.facetIndices.push(i / 3)
      }

      const vertexData = new VertexData()
      vertexData.positions = positionData
      vertexData.indices = indices
      const geometry = new Geometry(geometryId, this.scene, vertexData) as any
      geometry.name = geometryName

      const mesh = new Mesh(LABEL_MESH, this.scene)
      geometry.applyToMesh(mesh, true)
      mesh.id = face.name
      mesh.isVisible = false
      mesh.metadata = {
        geometryId,
        faces: [newFace],
        selectedFacesIds: new Map<number, number[]>(),
        collectorFacesIds: new Map<number, Array<{ faceId: number; selectionColor: Color3 }>>(),
        collectorHighlightedFacesIds: new Map<number, number[]>(),
      }

      const meshOutsideMaterial = this.scene.getMaterialByName(DEFAULT_MATERIAL_NAME)
      mesh.material = mesh.metadata.originalMaterial = meshOutsideMaterial

      labelMeshes.push(mesh)
    }

    return labelMeshes
  }

  private async decompressMesh(
    geometryId: string,
    geometryName: string,
    url?: string,
    drc?: ArrayBuffer,
    flatShaded?: boolean,
  ) {
    const data = drc || (await this.loadFileAsync(url))
    if (this.isDisposed) {
      return
    }

    // extract vertex data from draco file
    const vertexData = await DracoDecoder.Default.decodeMesh(data as ArrayBuffer)

    // extract geometry faces from draco file
    const faceAttr: FaceAttribute = await DracoDecoder.Default.getFaceAttribute(data as ArrayBuffer)

    // create geometry
    const geometry = new Geometry(geometryId, this.scene, vertexData) as any
    geometry.name = geometryName

    // create mesh
    const mesh = new Mesh(geometryName, this.scene)
    geometry.applyToMesh(mesh, true)
    mesh.id = geometryId
    mesh.name = geometryName
    mesh.isVisible = false
    mesh.metadata = {
      geometryId,
      faces: faceAttr.faces,
      selectedFacesIds: new Map<number, number[]>(),
      collectorFacesIds: new Map<number, Array<{ faceId: number; selectionColor: Color3 }>>(),
      collectorHighlightedFacesIds: new Map<number, number[]>(),
    }

    const meshOutsideMaterial = this.scene.getMaterialByName(DEFAULT_MATERIAL_NAME)
    mesh.material = mesh.metadata.originalMaterial = meshOutsideMaterial

    if (geometryName !== LABEL_MESH) {
      if (!vertexData.normals) {
        mesh.createNormals(true)
      }

      if (flatShaded) {
        mesh.convertToFlatShadedMesh()
      }

      if (this.renderScene.getGpuPicker()) {
        mesh.metadata.pickingShader = this.renderScene.getGpuPicker().pickingShader
      }

      // initialize buffer with faceId for each vertex
      const faceIdBuffer = new Buffer(this.scene.getEngine(), faceAttr.verticesFaceId, false)
      mesh.setVerticesBuffer(faceIdBuffer.createVertexBuffer(FACE_ID_ATTRIBUTE, 0, 1))
      // initialize buffer with unique color for each face
      const buffer = new Buffer(this.scene.getEngine(), faceAttr.verticesColors, false)
      mesh.setVerticesBuffer(buffer.createVertexBuffer(COLOR_FOR_FACE, 0, 3))
      // initialize buffer with unique color for each part
      mesh.registerInstancedBuffer(COLOR_FOR_PART, 3)
      mesh.instancedBuffers.pColor = Color3.White()
      // initialize buffer with unique color for each body
      mesh.registerInstancedBuffer(COLOR_FOR_BODY, 3)
      mesh.instancedBuffers.bColor = Color3.White()
      mesh.registerInstancedBuffer(HOVER_FACE_ID, 1)
      mesh.instancedBuffers.hoverFaceId = new HoverableFace(-1)
      mesh.registerInstancedBuffer(COLLECTOR_INSTANCE_ID, 1)
      mesh.instancedBuffers.collectorInstanceId = new HoverableFace(-1)
    }

    return mesh
  }

  private updateLoadingPartMesh(loadingPartIndex: number, newMesh: TransformNode) {
    const ind = this.loadingParts.findIndex((part) => part.loadingPartIndex === loadingPartIndex)
    this.scene.removeTransformNode(this.loadingParts[ind].mesh)
    this.loadingParts[ind].mesh = newMesh
    this.loadingParts[ind].initPosition = newMesh.position.clone()
    if (this.loadingParts[ind].dragDrop) {
      this.hideLoadingPart(this.loadingParts[ind].loadingPartIndex)
    }
  }

  private getMeshPositionByPointer(mesh: TransformNode, pointerX: number, pointerY: number) {
    const meshes = mesh.getChildMeshes()
    const meshBBox = this.meshManager.getTotalBoundingInfo(meshes, false).boundingBox
    const groundBBox = this.buildPlateManager.groundBox.getBoundingInfo().boundingBox
    const pickPlane = Plane.FromPositionAndNormal(new Vector3(0, 0, groundBBox.maximumWorld.z), Axis.Z)
    const canvasRect = this.canvas.getBoundingClientRect()
    const x = pointerX - canvasRect.left
    const y = pointerY - canvasRect.top
    const ray = this.scene.createPickingRay(x, y, IDENTITY_MATRIX, this.camera)
    const intersection = this.meshManager.intersectRayPlane(ray, pickPlane)
    if (!intersection) {
      return
    }

    if (intersection.x + meshBBox.extendSizeWorld.x > groundBBox.maximumWorld.x) {
      intersection.x = groundBBox.maximumWorld.x - meshBBox.extendSizeWorld.x
    }
    if (intersection.x - meshBBox.extendSizeWorld.x < groundBBox.minimumWorld.x) {
      intersection.x = groundBBox.minimumWorld.x + meshBBox.extendSizeWorld.x
    }
    if (intersection.y + meshBBox.extendSizeWorld.y > groundBBox.maximumWorld.y) {
      intersection.y = groundBBox.maximumWorld.y - meshBBox.extendSizeWorld.y
    }
    if (intersection.y - meshBBox.extendSizeWorld.y < groundBBox.minimumWorld.y) {
      intersection.y = groundBBox.minimumWorld.y + meshBBox.extendSizeWorld.y
    }

    return intersection
  }

  private renderPartComponent(config: IRenderConfig) {
    const part = config.parts.find((fPart) => {
      return fPart.id === (config.component as PartComponent).partID
    })
    if (!part || !part.geometryMeshes) {
      return
    }

    let pickingColor: Color3
    if (this.scene.metadata.componentPickingColor.has(config.component.id)) {
      pickingColor = this.scene.metadata.componentPickingColor.get(config.component.id)
    } else {
      pickingColor = Color3.FromInts(
        Math.ceil(Math.random() * 255),
        Math.ceil(Math.random() * 255),
        Math.ceil(Math.random() * 255),
      )
      this.scene.metadata.componentPickingColor.set(config.component.id, pickingColor)
    }

    for (const geometryMesh of part.geometryMeshes) {
      if (!geometryMesh || !geometryMesh.mesh) {
        continue
      }

      //  Edges rendering start
      // geometryMesh.mesh.enableEdgesRendering(EDGES_EPSILON, false)
      // geometryMesh.mesh.edgesWidth = EDGES_WIDTH
      // // geometryMesh.mesh.edgesRenderer.edgesWidthScalerForOrthographic = 10000
      // geometryMesh.mesh.edgesColor = Color4.FromColor3(EDGES_COLOR, 1)
      // geometryMesh.mesh.edgesRenderer.lineShader.options.useClipPlane = null
      // geometryMesh.mesh.edgesShareWithInstances = true
      // edges rendering end

      const instancedMesh = geometryMesh.mesh.createInstance(config.component.name)
      if (!instancedMesh.metadata) {
        instancedMesh.metadata = {} as IComponentMetadata
      }
      instancedMesh.metadata.geometryId = geometryMesh.mesh.metadata.geometryId
      instancedMesh.metadata.faces = geometryMesh.mesh.metadata.faces
      instancedMesh.metadata.geometryType = geometryMesh.type
      instancedMesh.metadata.isBar = geometryMesh.mesh.metadata.isBar
      instancedMesh.metadata.itemType = SceneItemType.Component
      instancedMesh.metadata.componentId = config.component.id
      instancedMesh.metadata.pickingColor = pickingColor
      instancedMesh.isVisible = geometryMesh.mesh.isVisible
      instancedMesh.onDispose = () => {
        if (!instancedMesh.sourceMesh.hasInstances) {
          instancedMesh.sourceMesh.dispose()
        }
      }

      instancedMesh.parent = config.parentMesh
      instancedMesh.id = uuid()
      const bpItemMesh = this.meshManager.getBuildPlanItemMeshByChild(instancedMesh)
      const bpItemMetadata = bpItemMesh.metadata as IPartMetadata
      if (bpItemMetadata && bpItemMetadata.pickingColor) {
        instancedMesh.instancedBuffers.pColor = bpItemMetadata.pickingColor
      }
      instancedMesh.instancedBuffers.bColor = pickingColor
      if (instancedMesh.metadata.bodyType) {
        this.meshManager.setInstancedBufferColor(instancedMesh, false, false)
      }
      instancedMesh.instancedBuffers.hoverFaceId = new HoverableFace(-1)
      instancedMesh.instancedBuffers.collectorInstanceId = new HoverableFace(
        instancedMesh.sourceMesh.instances.findIndex((m) => m === instancedMesh),
      )
      this.meshManager.transformMesh(instancedMesh, geometryMesh.mesh.getWorldMatrix().transpose())
      this.meshManager.transformMesh(instancedMesh, config.component.transformation, true)

      if (this.renderScene.getSceneMode() === SceneMode.PreviewPart && geometryMesh.mesh.metadata.defects) {
        const cadHelperDefectsKey = Object.keys(geometryMesh.mesh.metadata.defects).find((key) => key === 'cadHelper')
        const cadHelperDefects = geometryMesh.mesh.metadata.defects[cadHelperDefectsKey]
        if (cadHelperDefects) {
          this.defectsManager.addDefectsToMesh(instancedMesh, cadHelperDefects)
        }
      }

      const instancedMeshInside: InstancedMesh = geometryMesh.mesh.metadata.sourceMeshInside.createInstance(INSIDE_MESH_NAME)
      instancedMeshInside.parent = instancedMesh
      instancedMeshInside.isVisible = false
      instancedMeshInside.isPickable = false
      instancedMeshInside.onDispose = () => {
        if (!instancedMeshInside.sourceMesh.hasInstances) {
          instancedMeshInside.sourceMesh.dispose()
        }
      }

      const bodyIsHidden =
        config.visibility !== Visibility.Hidden &&
        config.hiddenBodies &&
        config.hiddenBodies
          .map((id) => this.getBodyIdsInfo(id))
          .some(
            (info) => config.component.id === info.componentId && instancedMesh.metadata.geometryId === info.geometryId,
          )
      if (config.visibility === Visibility.Hidden || bodyIsHidden) {
        this.meshManager.setIsHidden(instancedMesh, true, this.meshManager.isShowHiddenPartsAsTransparentMode)
      }
    }
  }

  private renderAssemblyComponent(config: IRenderConfig) {
    const assemblyMesh = new TransformNode(config.component.name, this.scene)
    assemblyMesh.parent = config.parentMesh
    assemblyMesh.id = config.buildPlanItemId ? config.buildPlanItemId : uuid()

    if (!config.parentMesh) {
      // if root component has scale it means that original geometry has units different from millimeters
      // all services use transformations in millimeters, visualization - in original units
      // for now store root component scale factor as unit factor to get all data in millimeters
      const transformationMatrix = Matrix.FromArray(config.component.transformation)
      const scaling = Vector3.Zero()
      transformationMatrix.decompose(scaling, null, null)

      const bpItemMetadata = {} as IPartMetadata
      bpItemMetadata.itemType = SceneItemType.Part
      bpItemMetadata.buildPlanItemId = config.buildPlanItemId
      bpItemMetadata.partId = config.partId
      bpItemMetadata.partName = config.partName
      bpItemMetadata.documentModelId = config.component.id
      bpItemMetadata.color = DEFAULT_COLOR
      bpItemMetadata.pickingColor = Color3.FromInts(
        Math.ceil(Math.random() * 255),
        Math.ceil(Math.random() * 255),
        Math.ceil(Math.random() * 255),
      )
      bpItemMetadata.unitFactor = +scaling.x.toFixed(1)
      assemblyMesh.metadata = bpItemMetadata
      if (
        (this.renderScene.getSceneMode() === SceneMode.BuildPlan ||
          this.renderScene.getSceneMode() === SceneMode.PreviewPrintOrder) &&
        config.buildPlanItemId
      ) {
        this.scene.metadata.buildPlanItems.set(config.buildPlanItemId, assemblyMesh)
      }
    }

    for (const child of (config.component as AssemblyComponent).children) {
      this.render({
        component: child,
        parts: config.parts,
        partId: config.partId,
        partName: config.partName,
        parentMesh: assemblyMesh,
        hiddenBodies: config.hiddenBodies,
        visibility: config.visibility,
      })
    }

    this.meshManager.transformMesh(assemblyMesh, config.component.transformation)
  }

  private setLoadingPartPosition(index: number, pointerPosition: Vector3) {
    const initPos = this.loadingParts[index].initPosition
    const newPos = new Vector3(initPos.x + pointerPosition.x, initPos.y + pointerPosition.y, initPos.z)
    this.loadingParts[index].mesh.setAbsolutePosition(newPos)
  }

  private updatePartPreviewPlate(part: TransformNode) {
    part.getChildTransformNodes().forEach((node) => node.computeWorldMatrix(true))
    const meshes = part.getChildMeshes()
    const totalBBox = this.meshManager.getTotalBoundingInfo(meshes, true, true)
    this.buildPlateManager.createGroundPartPreviewPlate(totalBBox)
  }

  private getCheckerInstance(): Checker {
    if (this.checker) {
      return this.checker
    }

    const bpItem = store.getters['buildPlans/getBuildPlan'].buildPlanItems[0]
    if (this.subType === ItemSubType.SinterPlan && bpItem) {
      const partProperties = bpItem.partProperties[0]
      let printStrategyParameterSetPk: VersionablePk
      if (partProperties.printStrategyParameterSetId) {
        printStrategyParameterSetPk = new VersionablePk(
          partProperties.printStrategyParameterSetId,
          partProperties.printStrategyParameterSetVersion,
        )
      } else {
        const printStrategy: BuildPlanPrintStrategyDto = store.getters['buildPlans/getBuildPlanPrintStrategy']
        printStrategyParameterSetPk = getDefaultBaseOnType(
          printStrategy.defaults,
          partProperties.type,
          partProperties.bodyType,
        )
      }

      const bjPartParameters =
        store.getters['buildPlans/getPrintStrategyParameterSetByPk'](printStrategyParameterSetPk).parameterSet
          .partParameters
      const scale = [
        bjPartParameters.ScaleFactors.ScaleFactorX,
        bjPartParameters.ScaleFactors.ScaleFactorY,
        bjPartParameters.ScaleFactors.ScaleFactorZ,
      ]

      this.checker = new Checker(this.scene, scale)
      return this.checker
    }
  }

  private isEqualGeometryProperties(originalObj: IGeometryProperties, newObj: IGeometryProperties): boolean {
    if (originalObj === null && newObj === null) {
      return true
    }
    if (originalObj === null || newObj === null) {
      return false
    }

    return (
      equalWithTolerance(originalObj.supportSurfaceArea, newObj.supportSurfaceArea, Number.EPSILON) &&
      equalWithTolerance(originalObj.supportVolume, newObj.supportVolume, Number.EPSILON) &&
      equalWithTolerance(originalObj.surfaceArea, newObj.surfaceArea, Number.EPSILON) &&
      equalWithTolerance(originalObj.volume, newObj.volume, Number.EPSILON)
    )
  }
}
