
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

enum Variant {
  Text = 'text',
  Image = 'image',
}

@Component
export default class FallbackImage extends Vue {
  @Prop() src: string
  @Prop() border: boolean
  @Prop() maxHeight: number
  @Prop() maxWidth: number
  @Prop({ type: String, default: Variant.Text }) variant: Variant

  hasErrors: boolean = false

  setErrorState() {
    this.hasErrors = true
  }

  @Watch('src') onImageChanged() {
    this.hasErrors = false
    this.$emit('load')
  }

  onImageLoad() {
    this.$emit('load')
  }
}
