
import Vue from 'vue'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { format } from 'date-fns'
import ILocale from '@/types/Common/ILocale'
import StoresNamespaces from '@/store/namespaces'
import { ICommonState } from '@/store/modules/common/types'
import router, { RouterPaths } from '@/router'
import Login from '@/components/layout/Login.vue'
import { IAnnouncement } from '@/types/Announcement/IAnnouncement'
import { AnnouncementType } from '@/constants'
import { IUser } from '@/types/User/IUser'

const commonStore = namespace(StoresNamespaces.Common)
const userStore = namespace(StoresNamespaces.User)
const announcementsStore = namespace(StoresNamespaces.Announcements)

@Component({
  components: {
    Login,
  },
})
export default class AppHeader extends Vue {
  @commonStore.State('common') common: ICommonState
  @commonStore.Action('fetchLocales') fetchLocales: any
  @commonStore.Action('applySelectedLocale') applySelectedLocale: any
  @commonStore.Getter('selectedLocaleTitle') selectedLocaleTitle: string

  @userStore.Getter('getUserDetails') getUserDetails: IUser

  @announcementsStore.Action fetchLatestUnreadAnnouncementByType: (type: string) => void
  @announcementsStore.Action readAnnouncement: (id: string) => void
  @announcementsStore.Getter('getAnnouncement') announcement: IAnnouncement

  isVisible: boolean = true
  // for progressive registration need to hide some elements
  isSimplified: boolean = false
  isExtended: boolean = false

  ampVersion: string = window.env.VUE_APP_AMP_VERSION
  releaseNotesLink: string = `${window.env.VUE_APP_RELEASE_NOTES_URL}?timestamp=${this.ampVersion}`

  get announcementPrompt() {
    let prompt = ''
    switch (this.announcement.announcementType) {
      case AnnouncementType.NewAmpVersion:
        prompt = this.$t(this.announcement.template, {
          version: this.ampVersion,
          time: this.formatTime(this.announcement.createdAt),
          date: this.formatDate(this.announcement.createdAt),
        }) as string
        break
    }
    return prompt
  }

  get locales(): ILocale[] {
    return this.common.locales
  }

  formatTime(dateStr: string) {
    const date = new Date(dateStr)
    const time = format(date, this.$t('timeFormat') as string)
    const timezone = date.toTimeString().split(' ')[1]
    return `${time} (${timezone})`
  }

  formatDate(dateStr: string) {
    return format(new Date(dateStr), this.$t('dateFormat') as string)
  }

  async refreshNewVersionBanner() {
    await this.fetchLatestUnreadAnnouncementByType(AnnouncementType.NewAmpVersion)
    if (this.announcement) {
      document.documentElement.style.setProperty('--application-header-height', '86px')
    } else {
      document.documentElement.style.setProperty('--application-header-height', '56px')
    }
  }

  async dismissNewVersionBanner() {
    await this.readAnnouncement(this.announcement.id)
    await this.refreshNewVersionBanner()
  }

  @Watch('$route')
  onRouteChange() {
    this.updateAppearance()
  }

  beforeMount() {
    this.fetchLocales()
    this.updateAppearance()
  }

  @Watch('getUserDetails')
  async userLoaded(user: IUser) {
    if (user) {
      await this.refreshNewVersionBanner()
    }
  }

  goToHome() {
    if (!this.isSimplified) {
      // @ts-ignore
      router.safePush(router.defaultRoute)
    }
  }

  updateAppearance() {
    this.isVisible = this.$route.path !== RouterPaths.Login && this.$route.path !== RouterPaths.Register
    this.isSimplified = this.$route.path === RouterPaths.ProgressiveRegistration
    this.isExtended = this.$route.path !== RouterPaths.Home
  }
}
