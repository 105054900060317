/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import {
  EnvironmentClearanceResult,
  IClearance,
  Clearance,
  ClearanceTypes,
  ClearanceModes,
} from '@/visualization/types/ClearanceTypes'
import { InstancedMesh, Mesh, MeshBuilder, TransformNode, Vector2, Vector3 } from '@babylonjs/core'
import { ClearanceManager } from '@/visualization/rendering/clearance/ClearanceManager'
import { RenderScene } from '@/visualization/render-scene'
import { BuildPlateManager } from '@/visualization/rendering/BuildPlateManager'
import { Direction } from '@/types/IMachineConfig'
import {
  MAX_PH_INDEXING,
  CLEARANCE_BUILDPLATE_ID,
  CLEARANCE_BUILD_VOLUME_WALL_ID,
  CLEARANCE_CEILING_ID,
  CLEARANCE_MIN_DISPLAY_DISTANCE,
  CLEARANCE_PLANE,
  CLEARANCE_PLANE_MATERIAL,
  CLEARANCE_PRINT_HEAD_LANES_ID,
  CLEARANCE_INVISIBLE_WALL_SIZE,
  CLEARANCE_LEGACY_PART_WARNING_TIMEOUT,
} from '@/constants'
import {
  IPartMetadata,
  ISystemReferenceClearanceMetadata,
  SceneItemType,
} from '@/visualization/types/SceneItemMetadata'
import { IMachineProperties } from '@/visualization/types/MachineProperties'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import { Rectangle } from '@/visualization/types/DCPTypes'
import cloneDeep from 'lodash/cloneDeep'
import messageService from '@/services/messageService'
import i18n from '@/plugins/i18n'

interface ExtremumPoints {
  xMin: Vector3
  xMax: Vector3
  yMin: Vector3
  yMax: Vector3
  zMin: Vector3
  zMax: Vector3
}

export class ClearanceEnvironment implements IClearance {
  private renderScene: RenderScene
  private meshManager: MeshManager
  private buildPlateManager: BuildPlateManager
  private clearanceManager: ClearanceManager

  private clearances: Map<string, Clearance> = new Map<string, Clearance>()
  private highlightedPlanes: Map<string, InstancedMesh> = new Map<string, InstancedMesh>()

  private machineProperties: IMachineProperties
  private buildVolumeSizes: {
    xMin: number
    xMax: number
    yMin: number
    yMax: number
    preRoundedXmin: number
    preRoundedXmax: number
    preRoundedYmin: number
    preRoundedYmax: number
    roundedCornerRad: number
    buildVolumeHeight: number
  }

  constructor(renderScene: RenderScene) {
    this.renderScene = renderScene
    this.meshManager = this.renderScene.getMeshManager()
    this.buildPlateManager = this.renderScene.getBuildPlateManager()
    this.clearanceManager = this.renderScene.getClearanceManager()

    this.createHighlightedWallsAndCeiling()
    this.createHighlightedPrintHeadLanes()
  }

  public getClearances(): Map<string, Clearance> {
    return this.clearances
  }

  public recreateHighlightedWalls() {
    this.dispose()
    this.createHighlightedWallsAndCeiling()
    this.createHighlightedPrintHeadLanes()
  }

  public highlightWalls(referenceIds: string[], showHighlight: boolean) {
    referenceIds.forEach((referenceId) => {
      const reference = this.highlightedPlanes.get(referenceId)
      if (reference) {
        reference.isVisible = showHighlight
      }
    })

    this.renderScene.animate(true)
  }

  public measureDistance(payload: {
    from: ClearanceTypes
    to: ClearanceTypes
    buildPlanItemId: string
    componentId?: string
    geometryId?: string
  }) {
    const { from, to, buildPlanItemId, componentId, geometryId } = payload
    if (from === ClearanceTypes.Bodies || to === ClearanceTypes.Bodies) {
      const mesh = this.meshManager.getComponentMesh(componentId, geometryId, buildPlanItemId)
      const referenceType = from === ClearanceTypes.Bodies ? to : from
      switch (referenceType) {
        case ClearanceTypes.Walls:
          break
        case ClearanceTypes.PrintHeadLanes:
          break
        case ClearanceTypes.Ceiling:
          break
        case ClearanceTypes.Plate:
          break
        default:
          break
      }
    } else if (from === ClearanceTypes.Parts || to === ClearanceTypes.Parts) {
      const parentMesh = this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)
      const { bvh } = parentMesh.metadata as IPartMetadata
      const obbTree = this.renderScene.getObbTree()
      if (obbTree.isBVHTreeWithoutTriangleIds(bvh)) {
        messageService.showWarningMessage(
          i18n.t('clearanceTool.legacyPartWarning').toString(),
          CLEARANCE_LEGACY_PART_WARNING_TIMEOUT,
        )
        return
      }

      const extremumHull = this.getExtremumPointsFromPart(parentMesh)
      const referenceType = from === ClearanceTypes.Parts ? to : from

      let clearance: Clearance = null
      for (const item of this.clearances.values()) {
        if (item.from.bpItemId === buildPlanItemId && item.to.type === referenceType) {
          clearance = item
          break
        }
      }

      if (clearance) {
        return
      }

      let clearanceResult: EnvironmentClearanceResult
      switch (referenceType) {
        case ClearanceTypes.Walls:
          clearanceResult = this.measureDistanceBetweenPartAndWalls(buildPlanItemId)
          break
        case ClearanceTypes.PrintHeadLanes:
          clearanceResult = this.measureDistanceBetweenPartAndPrintHeadLanes(buildPlanItemId, extremumHull)
          break
        case ClearanceTypes.Ceiling:
          clearanceResult = this.measureDistanceBetweenPartAndCeiling(buildPlanItemId, extremumHull)
          break
        case ClearanceTypes.Plate:
          clearanceResult = this.measureDistanceBetweenPartAndPlate(buildPlanItemId, extremumHull)
          break
        default:
          return
      }

      if (!clearanceResult) {
        return
      }

      clearance = new Clearance(this.clearanceManager, clearanceResult, ClearanceTypes.Parts, referenceType)
      this.clearances.set(clearance.id, clearance)

      this.clearanceManager.getRenderScene.animate()
      this.clearanceManager.updateDimensionBoxPosition()
    }
    this.renderScene.getSelectionManager().deselect()
  }

  public clearClearances(predicate?: (clearance: Clearance) => boolean) {
    this.highlightedPlanes.forEach((item) => (item.isVisible = false))
    for (const [clearanceId, clearance] of this.clearances) {
      if (!predicate || predicate(clearance)) {
        clearance.dispose()
        this.clearances.delete(clearanceId)
      }
    }
  }

  public hideClearances(hide: boolean, predicate?: (clearance: Clearance) => boolean) {
    this.clearances.forEach((clearance) => {
      if (!predicate || predicate(clearance)) {
        clearance.isHidden = hide
      }
    })
  }

  public dispose() {
    this.highlightedPlanes.forEach((item) => item.dispose())
    this.highlightedPlanes.clear()
    this.clearClearances()
  }

  // Functions to compute distance
  private measureDistanceBetweenPartAndWalls(bpItemId: string): EnvironmentClearanceResult {
    const obbTree = this.renderScene.getObbTree()
    const parentMesh = this.meshManager.getBuildPlanItemMeshById(bpItemId)

    const clearanceCandidates = []
    const result = {
      distance: Number.MAX_VALUE,
      referenceId: null,
      pointA: null,
      pointB: null,
    }
    this.highlightedPlanes.forEach((item) => {
      const metadata = item.metadata as ISystemReferenceClearanceMetadata
      if (metadata.referenceType === ClearanceTypes.Walls) {
        obbTree.rssRectangleDistance(result, parentMesh.metadata.bvh, parentMesh, item)
        clearanceCandidates.push(cloneDeep(result))
      }
    })

    clearanceCandidates.sort((itemA, itemB) => itemA.distance - itemB.distance)
    const shortestClearance = clearanceCandidates[0]
    const referenceIds = clearanceCandidates
      .filter((item) => Math.abs(item.distance - shortestClearance.distance) <= CLEARANCE_MIN_DISPLAY_DISTANCE)
      .map((item) => item.referenceId)

    return {
      bpItemId,
      referenceIds,
      mode: ClearanceModes.Environment,
      distance: shortestClearance.distance,
      pointA: shortestClearance.pointA,
      pointB: shortestClearance.pointB,
    }
  }

  private measureDistanceBetweenPartAndPrintHeadLanes(
    bpItemId: string,
    extremumHull: ExtremumPoints,
  ): EnvironmentClearanceResult {
    const { xMin, xMax, yMin, yMax } = extremumHull
    const clearanceCandidates = []

    const { numberOfPrintHeadLanes, printHeadDirectionString, firstPrintHeadLaneSide, printHeadLaneWidth } =
      this.machineProperties
    if (numberOfPrintHeadLanes > 0) {
      switch (printHeadDirectionString) {
        case Direction.X:
        case Direction.NegativeX:
          // Get number of closest print head lane to part in -Y direction
          const minClosestLaneNumberY = Math.floor((yMin.y - firstPrintHeadLaneSide) / printHeadLaneWidth)
          // Get number of closest print head lane to part in +Y direction
          const maxClosestLaneNumberY = Math.ceil((yMax.y - firstPrintHeadLaneSide) / printHeadLaneWidth)

          // Get position of closest print head lane to part in -Y direction
          const minClosestLanePositionY = firstPrintHeadLaneSide + minClosestLaneNumberY * printHeadLaneWidth
          // Get position of closest print head lane to part in +Y direction
          const maxClosestLanePositionY = firstPrintHeadLaneSide + maxClosestLaneNumberY * printHeadLaneWidth

          // Add new clearance, if corresponding point is inside any print head lanes
          if (minClosestLaneNumberY >= 0 && minClosestLaneNumberY < numberOfPrintHeadLanes) {
            clearanceCandidates.push({
              distance: Math.abs(minClosestLanePositionY - yMin.y),
              pointOnMesh: yMin,
              pointOnPrintHeadLane: new Vector3(yMin.x, minClosestLanePositionY, yMin.z),
              referenceId: `${CLEARANCE_PRINT_HEAD_LANES_ID}${minClosestLaneNumberY}`,
            })
          }

          if (maxClosestLaneNumberY > 0 && maxClosestLaneNumberY <= numberOfPrintHeadLanes) {
            clearanceCandidates.push({
              distance: Math.abs(maxClosestLanePositionY - yMax.y),
              pointOnMesh: yMax,
              pointOnPrintHeadLane: new Vector3(yMax.x, maxClosestLanePositionY, yMax.z),
              referenceId: `${CLEARANCE_PRINT_HEAD_LANES_ID}${maxClosestLaneNumberY}`,
            })
          }

          // Edge case when entire part is in front of first print head lane
          if (minClosestLaneNumberY <= 0 && maxClosestLaneNumberY <= 0) {
            clearanceCandidates.push({
              distance: Math.abs(firstPrintHeadLaneSide - yMax.y),
              pointOnMesh: yMax,
              pointOnPrintHeadLane: new Vector3(yMax.x, firstPrintHeadLaneSide, yMax.z),
              referenceId: `${CLEARANCE_PRINT_HEAD_LANES_ID}${0}`,
            })
          }

          // Edge case when entire part is behind of last print head lane
          if (minClosestLaneNumberY >= numberOfPrintHeadLanes && maxClosestLaneNumberY >= numberOfPrintHeadLanes) {
            const lastPrintHeadLanePositionY = firstPrintHeadLaneSide + numberOfPrintHeadLanes * printHeadLaneWidth
            clearanceCandidates.push({
              distance: Math.abs(lastPrintHeadLanePositionY - yMin.y),
              pointOnMesh: yMin,
              pointOnPrintHeadLane: new Vector3(yMin.x, lastPrintHeadLanePositionY, yMin.z),
              referenceId: `${CLEARANCE_PRINT_HEAD_LANES_ID}${numberOfPrintHeadLanes}`,
            })
          }

          if (clearanceCandidates.length === 0) {
            const pointOnFirstPrintHeadLane = new Vector3(yMin.x, firstPrintHeadLaneSide, yMin.z)
            clearanceCandidates.push({
              distance: 0,
              pointOnMesh: pointOnFirstPrintHeadLane,
              pointOnPrintHeadLane: pointOnFirstPrintHeadLane,
              referenceId: `${CLEARANCE_PRINT_HEAD_LANES_ID}${0}`,
            })

            const lastPrintHeadLanePositionX = firstPrintHeadLaneSide + numberOfPrintHeadLanes * printHeadLaneWidth
            const pointOnLastPrintHeadLane = new Vector3(yMax.x, lastPrintHeadLanePositionX, yMax.z)
            clearanceCandidates.push({
              distance: 0,
              pointOnMesh: pointOnLastPrintHeadLane,
              pointOnPrintHeadLane: pointOnLastPrintHeadLane,
              referenceId: `${CLEARANCE_PRINT_HEAD_LANES_ID}${numberOfPrintHeadLanes}`,
            })
          }
          break
        case Direction.Y:
        case Direction.NegativeY:
          // Get number of closest print head lane to part in -X direction
          const minClosestLaneNumberX = Math.floor((xMin.x - firstPrintHeadLaneSide) / printHeadLaneWidth)
          // Get number of closest print head lane to part in +X direction
          const maxClosestLaneNumberX = Math.ceil((xMax.x - firstPrintHeadLaneSide) / printHeadLaneWidth)

          // Get position of closest print head lane to part in -X direction
          const minClosestLanePositionX = firstPrintHeadLaneSide + minClosestLaneNumberX * printHeadLaneWidth
          // Get position of closest print head lane to part in -X direction
          const maxClosestLanePositionX = firstPrintHeadLaneSide + maxClosestLaneNumberX * printHeadLaneWidth

          // Add new clearance, if corresponding point is inside any print head lanes
          if (minClosestLaneNumberX >= 0 && minClosestLaneNumberX < numberOfPrintHeadLanes) {
            clearanceCandidates.push({
              distance: Math.abs(minClosestLanePositionX - xMin.x),
              pointOnMesh: xMin,
              pointOnPrintHeadLane: new Vector3(minClosestLanePositionX, xMin.y, xMin.z),
              referenceId: `${CLEARANCE_PRINT_HEAD_LANES_ID}${minClosestLaneNumberX}`,
            })
          }

          if (maxClosestLaneNumberX > 0 && maxClosestLaneNumberX <= numberOfPrintHeadLanes) {
            clearanceCandidates.push({
              distance: Math.abs(maxClosestLanePositionX - xMax.x),
              pointOnMesh: xMax,
              pointOnPrintHeadLane: new Vector3(maxClosestLanePositionX, xMax.y, xMax.z),
              referenceId: `${CLEARANCE_PRINT_HEAD_LANES_ID}${maxClosestLaneNumberX}`,
            })
          }

          // Edge case when entire part is in front of first print head lane
          if (minClosestLaneNumberX <= 0 && maxClosestLaneNumberX <= 0) {
            clearanceCandidates.push({
              distance: Math.abs(firstPrintHeadLaneSide - xMax.x),
              pointOnMesh: xMax,
              pointOnPrintHeadLane: new Vector3(firstPrintHeadLaneSide, xMax.y, xMax.z),
              referenceId: `${CLEARANCE_PRINT_HEAD_LANES_ID}${0}`,
            })
          }

          // Edge case when entire part is behind of last print head lane
          if (minClosestLaneNumberX >= numberOfPrintHeadLanes && maxClosestLaneNumberX >= numberOfPrintHeadLanes) {
            const lastPrintHeadLanePositionX = firstPrintHeadLaneSide + numberOfPrintHeadLanes * printHeadLaneWidth
            clearanceCandidates.push({
              distance: Math.abs(lastPrintHeadLanePositionX - xMin.x),
              pointOnMesh: xMin,
              pointOnPrintHeadLane: new Vector3(lastPrintHeadLanePositionX, xMin.y, xMin.z),
              referenceId: `${CLEARANCE_PRINT_HEAD_LANES_ID}${numberOfPrintHeadLanes}`,
            })
          }

          if (clearanceCandidates.length === 0) {
            const pointOnFirstPrintHeadLane = new Vector3(firstPrintHeadLaneSide, xMin.y, xMin.z)
            clearanceCandidates.push({
              distance: 0,
              pointOnMesh: pointOnFirstPrintHeadLane,
              pointOnPrintHeadLane: pointOnFirstPrintHeadLane,
              referenceId: `${CLEARANCE_PRINT_HEAD_LANES_ID}${0}`,
            })

            const lastPrintHeadLanePositionX = firstPrintHeadLaneSide + numberOfPrintHeadLanes * printHeadLaneWidth
            const pointOnLastPrintHeadLane = new Vector3(lastPrintHeadLanePositionX, xMax.y, xMax.z)
            clearanceCandidates.push({
              distance: 0,
              pointOnMesh: pointOnLastPrintHeadLane,
              pointOnPrintHeadLane: pointOnLastPrintHeadLane,
              referenceId: `${CLEARANCE_PRINT_HEAD_LANES_ID}${numberOfPrintHeadLanes}`,
            })
          }
          break
      }

      clearanceCandidates.sort((itemA, itemB) => itemA.distance - itemB.distance)
      const shortestClearance = clearanceCandidates[0]
      const referenceIds = clearanceCandidates
        .filter((item) => Math.abs(item.distance - shortestClearance.distance) <= CLEARANCE_MIN_DISPLAY_DISTANCE)
        .map((item) => item.referenceId)

      return {
        bpItemId,
        referenceIds,
        mode: ClearanceModes.Environment,
        distance: shortestClearance.distance,
        pointA: shortestClearance.pointOnMesh,
        pointB: shortestClearance.pointOnPrintHeadLane,
      }
    }
  }

  private measureDistanceBetweenPartAndCeiling(bpItemId: string, extremumHull: ExtremumPoints) {
    const { zMax, zMin } = extremumHull
    const { buildVolumeHeight } = this.buildVolumeSizes
    const pointOnCeiliing = new Vector3(zMax.x, zMax.y, buildVolumeHeight)

    if (zMax.z < buildVolumeHeight) {
      return {
        bpItemId,
        mode: ClearanceModes.Environment,
        distance: buildVolumeHeight - zMax.z,
        pointA: zMax,
        pointB: pointOnCeiliing,
        referenceIds: [CLEARANCE_CEILING_ID],
      }
    }

    if (zMin.z > buildVolumeHeight) {
      pointOnCeiliing.x = zMin.x
      pointOnCeiliing.y = zMin.y

      return {
        bpItemId,
        mode: ClearanceModes.Environment,
        distance: zMin.z - buildVolumeHeight,
        pointA: zMin,
        pointB: pointOnCeiliing,
        referenceIds: [CLEARANCE_CEILING_ID],
      }
    }

    return {
      bpItemId,
      mode: ClearanceModes.Environment,
      distance: 0,
      pointA: pointOnCeiliing,
      pointB: pointOnCeiliing,
      referenceIds: [CLEARANCE_CEILING_ID],
    } as EnvironmentClearanceResult
  }

  private measureDistanceBetweenPartAndPlate(
    bpItemId: string,
    extremumHull: ExtremumPoints,
  ): EnvironmentClearanceResult {
    const { zMin } = extremumHull

    return {
      bpItemId,
      mode: ClearanceModes.Environment,
      distance: Math.abs(zMin.z),
      pointA: zMin,
      pointB: new Vector3(zMin.x, zMin.y, 0),
      referenceIds: [CLEARANCE_BUILDPLATE_ID],
    }
  }

  // Functions to create highlighted references
  private createHighlightedWallsAndCeiling() {
    const obbTree = this.renderScene.getObbTree()
    const buildPlateSizes = this.buildPlateManager.getBuildPlateSizes()

    const yShiftSize = (this.renderScene as RenderScene).yShiftSize
    // For Binder Jet if PH indexing value > 9mm, then the system shall reduce the size of
    // the buildable volume along Y-axis accordingly (2mm Y-reduction per 1mm indexing increase)
    const yAdjustment = yShiftSize > MAX_PH_INDEXING ? (yShiftSize - MAX_PH_INDEXING) * 2 : 0
    const bvX = buildPlateSizes.buildVolumeX
    const bvY = buildPlateSizes.buildVolumeY - yAdjustment
    const xMin = -bvX / 2
    const xMax = bvX / 2
    const yMin = -bvY / 2
    const yMax = bvY / 2
    const buildVolumeHeight = buildPlateSizes.buildVolumeHeight
    const roundedCornerRad = buildPlateSizes.roundedCornerRad - (buildPlateSizes.xMax - xMax)

    const buildVolumeMetadata = {
      xMin,
      yMin,
      xMax,
      yMax,
      roundedCornerRad,
      buildVolumeHeight,
    }

    const wallShape: Vector3[] = []
    for (let angle = 0; angle <= 360; angle += 1) {
      wallShape.push(obbTree.buildPlateBoundaryPoint(buildVolumeMetadata, angle, 0, false))
    }
    this.createHighlightedCeiling(wallShape, buildVolumeHeight)
    this.createHighlightedStraightWalls(wallShape, buildVolumeMetadata)
    this.createHighlightedRoundedWalls(wallShape, buildVolumeMetadata)

    const groundShape: Vector3[] = []
    for (let angle = 0; angle <= 360; angle += 1) {
      groundShape.push(obbTree.buildPlateBoundaryPoint(buildPlateSizes, angle, 0, false))
    }
    this.createHighlightedPlate(groundShape)
  }

  private createHighlightedCeiling(shape: Vector3[], buildVolumeHeight: number) {
    const scene = this.clearanceManager.utilScene
    const ceilingMesh = MeshBuilder.ExtrudeShape(
      'ceilingMesh',
      {
        shape,
        path: [new Vector3(0, 0, 0), new Vector3(0, 0, 0.1)],
        cap: Mesh.CAP_ALL,
      },
      scene,
    )
    ceilingMesh.position = new Vector3(0, 0, buildVolumeHeight)
    ceilingMesh.material = scene.getMaterialByName(CLEARANCE_PLANE_MATERIAL)
    ceilingMesh.isPickable = false

    const ceilingInstance = ceilingMesh.createInstance(CLEARANCE_PLANE)
    ceilingInstance.isPickable = false
    ceilingInstance.isVisible = false
    ceilingInstance.metadata = {
      itemType: SceneItemType.ClearanceSystemReference,
      referenceType: ClearanceTypes.Ceiling,
      referenceId: CLEARANCE_CEILING_ID,
    } as ISystemReferenceClearanceMetadata
    this.highlightedPlanes.set(CLEARANCE_CEILING_ID, ceilingInstance)
    scene.removeMesh(ceilingMesh)
  }

  private createHighlightedPlate(shape: Vector3[]) {
    const scene = this.clearanceManager.utilScene
    const plateMesh = MeshBuilder.ExtrudeShape(
      'plateMesh',
      {
        shape,
        path: [new Vector3(0, 0, 0), new Vector3(0, 0, 0.1)],
        cap: Mesh.CAP_ALL,
      },
      scene,
    )
    plateMesh.material = scene.getMaterialByName(CLEARANCE_PLANE_MATERIAL)
    plateMesh.isPickable = false

    const plateInstance = plateMesh.createInstance(CLEARANCE_PLANE)
    plateInstance.isPickable = false
    plateInstance.isVisible = false
    plateInstance.metadata = {
      itemType: SceneItemType.ClearanceSystemReference,
      referenceType: ClearanceTypes.Plate,
      referenceId: CLEARANCE_BUILDPLATE_ID,
    } as ISystemReferenceClearanceMetadata
    this.highlightedPlanes.set(CLEARANCE_BUILDPLATE_ID, plateInstance)
    scene.removeMesh(plateMesh)
  }

  private createHighlightedStraightWalls(
    shape: Vector3[],
    buildVolumeMetadata: {
      xMin: number
      yMin: number
      xMax: number
      yMax: number
      roundedCornerRad: number
      buildVolumeHeight: number
    },
  ) {
    const { xMin, yMin, xMax, yMax, roundedCornerRad, buildVolumeHeight } = buildVolumeMetadata
    const angle1 = Math.PI / 2 - Math.atan(xMax / (yMax - roundedCornerRad))
    const angle2 = Math.PI / 2 + Math.atan(xMax / (yMax - roundedCornerRad)) + Math.PI
    const angle3 = Math.PI / 2 - Math.atan((xMax - roundedCornerRad) / yMax)
    const angle4 = Math.PI / 2 + Math.atan((xMax - roundedCornerRad) / yMax)

    let start = shape[this.radToRoundDeg(angle1)]
    let end = shape[this.radToRoundDeg(angle2)]
    const preRoundedYmax = start.y
    const preRoundedYmin = end.y
    const wallPlaneXId = `${CLEARANCE_BUILD_VOLUME_WALL_ID}+X`
    const wallPlaneX = this.clearanceManager.planeMesh.createInstance(CLEARANCE_PLANE)
    wallPlaneX.position = new Vector3(xMax, 0, buildVolumeHeight / 2)
    wallPlaneX.rotation.y = Math.PI / 2
    wallPlaneX.rotation.z = Math.PI / 2
    wallPlaneX.scaling = new Vector3(start.y - end.y, buildVolumeHeight, 1)
    wallPlaneX.isPickable = false
    wallPlaneX.isVisible = false
    wallPlaneX.metadata = {
      itemType: SceneItemType.ClearanceSystemReference,
      referenceType: ClearanceTypes.Walls,
      referenceId: wallPlaneXId,
      ...this.createRectanglesAndTrianglesForWall([start, end]),
    } as ISystemReferenceClearanceMetadata
    this.highlightedPlanes.set(wallPlaneXId, wallPlaneX)

    start = new Vector3(xMin, start.y, start.z)
    end = new Vector3(xMin, end.y, end.z)
    const wallPlaneNegativeXId = `${CLEARANCE_BUILD_VOLUME_WALL_ID}-X`
    const wallPlaneNegativeX = this.clearanceManager.planeMesh.createInstance(CLEARANCE_PLANE)
    wallPlaneNegativeX.position = new Vector3(xMin, 0, buildVolumeHeight / 2)
    wallPlaneNegativeX.rotation.y = Math.PI / 2
    wallPlaneNegativeX.rotation.z = Math.PI / 2
    wallPlaneNegativeX.scaling = new Vector3(start.y - end.y, buildVolumeHeight, 1)
    wallPlaneNegativeX.isPickable = false
    wallPlaneNegativeX.isVisible = false
    wallPlaneNegativeX.metadata = {
      itemType: SceneItemType.ClearanceSystemReference,
      referenceType: ClearanceTypes.Walls,
      referenceId: wallPlaneNegativeXId,
      ...this.createRectanglesAndTrianglesForWall([start, end]),
    } as ISystemReferenceClearanceMetadata
    this.highlightedPlanes.set(wallPlaneNegativeXId, wallPlaneNegativeX)

    start = shape[this.radToRoundDeg(angle3)]
    end = shape[this.radToRoundDeg(angle4)]
    const preRoundedXmax = start.x
    const preRoundedXmin = end.x
    const wallPlaneYId = `${CLEARANCE_BUILD_VOLUME_WALL_ID}+Y`
    const wallPlaneY = this.clearanceManager.planeMesh.createInstance(CLEARANCE_PLANE)
    wallPlaneY.position = new Vector3(0, yMax, buildVolumeHeight / 2)
    wallPlaneY.rotation.x = Math.PI / 2
    wallPlaneY.isPickable = false
    wallPlaneY.isVisible = false
    wallPlaneY.scaling = new Vector3(start.x - end.x, buildVolumeHeight, 1)
    wallPlaneY.metadata = {
      itemType: SceneItemType.ClearanceSystemReference,
      referenceType: ClearanceTypes.Walls,
      referenceId: wallPlaneYId,
      ...this.createRectanglesAndTrianglesForWall([start, end]),
    } as ISystemReferenceClearanceMetadata
    this.highlightedPlanes.set(wallPlaneYId, wallPlaneY)

    start = new Vector3(start.x, yMin, start.z)
    end = new Vector3(end.x, yMin, end.z)
    const wallPlaneNegativeYId = `${CLEARANCE_BUILD_VOLUME_WALL_ID}-Y`
    const wallPlaneNegativeY = this.clearanceManager.planeMesh.createInstance(CLEARANCE_PLANE)
    wallPlaneNegativeY.position = new Vector3(0, yMin, buildVolumeHeight / 2)
    wallPlaneNegativeY.rotation.x = Math.PI / 2
    wallPlaneNegativeY.isPickable = false
    wallPlaneNegativeY.isVisible = false
    wallPlaneNegativeY.scaling = new Vector3(start.x - end.x, buildVolumeHeight, 1)
    wallPlaneNegativeY.metadata = {
      itemType: SceneItemType.ClearanceSystemReference,
      referenceType: ClearanceTypes.Walls,
      referenceId: wallPlaneNegativeYId,
      ...this.createRectanglesAndTrianglesForWall([start, end]),
    } as ISystemReferenceClearanceMetadata
    this.highlightedPlanes.set(wallPlaneNegativeYId, wallPlaneNegativeY)

    this.buildVolumeSizes = {
      xMin,
      xMax,
      yMin,
      yMax,
      preRoundedXmin,
      preRoundedXmax,
      preRoundedYmin,
      preRoundedYmax,
      buildVolumeHeight,
      roundedCornerRad,
    }
  }

  private createHighlightedRoundedWalls(
    shape: Vector3[],
    buildVolumeMetadata: {
      xMin: number
      yMin: number
      xMax: number
      yMax: number
      roundedCornerRad: number
      buildVolumeHeight: number
    },
  ) {
    const { xMax, yMax, roundedCornerRad, buildVolumeHeight } = buildVolumeMetadata
    const angle1 = Math.PI / 2 - Math.atan(xMax / (yMax - roundedCornerRad))
    const angle2 = Math.PI / 2 - Math.atan((xMax - roundedCornerRad) / yMax)
    const angle3 = Math.PI / 2 + Math.atan(xMax / (yMax - roundedCornerRad))
    const angle4 = Math.PI / 2 + Math.atan((xMax - roundedCornerRad) / yMax)
    const angle5 = angle1 + Math.PI
    const angle6 = angle2 + Math.PI
    const angle7 = angle3 + Math.PI
    const angle8 = angle4 + Math.PI

    const scene = this.clearanceManager.utilScene
    const trimmedShapeXY = shape.slice(this.radToRoundDeg(angle1), this.radToRoundDeg(angle2) + 1)
    const roundedWallXY = MeshBuilder.ExtrudeShape(
      'roundedWallXY',
      {
        shape: trimmedShapeXY,
        path: [new Vector3(0, 0, 0), new Vector3(0, 0, buildVolumeHeight)],
        sideOrientation: Mesh.DOUBLESIDE,
      },
      scene,
    )
    roundedWallXY.material = scene.getMaterialByName(CLEARANCE_PLANE_MATERIAL)
    roundedWallXY.isPickable = false

    const roundedWallXYId = `${CLEARANCE_BUILD_VOLUME_WALL_ID}+X+Y`
    const roundedWallXYInstance = roundedWallXY.createInstance(CLEARANCE_PLANE)
    roundedWallXYInstance.isPickable = false
    roundedWallXYInstance.isVisible = false
    roundedWallXYInstance.metadata = {
      itemType: SceneItemType.ClearanceSystemReference,
      referenceType: ClearanceTypes.Walls,
      referenceId: roundedWallXYId,
      ...this.createRectanglesAndTrianglesForWall(trimmedShapeXY),
    } as ISystemReferenceClearanceMetadata
    this.highlightedPlanes.set(roundedWallXYId, roundedWallXYInstance)
    scene.removeMesh(roundedWallXY)

    const trimmedShapeNegativeXY = shape.slice(this.radToRoundDeg(angle4), this.radToRoundDeg(angle3) + 1)
    const roundedWallNegativeXY = MeshBuilder.ExtrudeShape(
      'roundedWallNegativeXY',
      {
        shape: trimmedShapeNegativeXY,
        path: [new Vector3(0, 0, 0), new Vector3(0, 0, buildVolumeHeight)],
        sideOrientation: Mesh.DOUBLESIDE,
      },
      scene,
    )
    roundedWallNegativeXY.material = scene.getMaterialByName(CLEARANCE_PLANE_MATERIAL)
    roundedWallNegativeXY.isPickable = false

    const roundedWallNegativeXYId = `${CLEARANCE_BUILD_VOLUME_WALL_ID}-X+Y`
    const roundedWallNegativeXYInstance = roundedWallNegativeXY.createInstance(CLEARANCE_PLANE)
    roundedWallNegativeXYInstance.isPickable = false
    roundedWallNegativeXYInstance.isVisible = false
    roundedWallNegativeXYInstance.metadata = {
      itemType: SceneItemType.ClearanceSystemReference,
      referenceType: ClearanceTypes.Walls,
      referenceId: roundedWallNegativeXYId,
      ...this.createRectanglesAndTrianglesForWall(trimmedShapeNegativeXY),
    } as ISystemReferenceClearanceMetadata
    this.highlightedPlanes.set(roundedWallNegativeXYId, roundedWallNegativeXYInstance)
    scene.removeMesh(roundedWallNegativeXY)

    const trimmedShapeNegativeXNegativeY = shape.slice(this.radToRoundDeg(angle5), this.radToRoundDeg(angle6) + 1)
    const roundedWallNegativeXNegativeY = MeshBuilder.ExtrudeShape(
      'roundedWallNegativeXNegativeY',
      {
        shape: trimmedShapeNegativeXNegativeY,
        path: [new Vector3(0, 0, 0), new Vector3(0, 0, buildVolumeHeight)],
        sideOrientation: Mesh.DOUBLESIDE,
      },
      scene,
    )
    roundedWallNegativeXNegativeY.material = scene.getMaterialByName(CLEARANCE_PLANE_MATERIAL)
    roundedWallNegativeXNegativeY.isPickable = false

    const roundedWallNegativeXNegativeYId = `${CLEARANCE_BUILD_VOLUME_WALL_ID}-X-Y`
    const roundedWallNegativeXNegativeYInstance = roundedWallNegativeXNegativeY.createInstance(CLEARANCE_PLANE)
    roundedWallNegativeXNegativeYInstance.isPickable = false
    roundedWallNegativeXNegativeYInstance.isVisible = false
    roundedWallNegativeXNegativeYInstance.metadata = {
      itemType: SceneItemType.ClearanceSystemReference,
      referenceType: ClearanceTypes.Walls,
      referenceId: roundedWallNegativeXNegativeYId,
      ...this.createRectanglesAndTrianglesForWall(trimmedShapeNegativeXNegativeY),
    } as ISystemReferenceClearanceMetadata
    this.highlightedPlanes.set(roundedWallNegativeXNegativeYId, roundedWallNegativeXNegativeYInstance)
    scene.removeMesh(roundedWallNegativeXNegativeY)

    const trimmedShapeXNegativeY = shape.slice(this.radToRoundDeg(angle8), this.radToRoundDeg(angle7) + 1)
    const roundedWallXNegativeY = MeshBuilder.ExtrudeShape(
      'roundedWallXNegativeY',
      {
        shape: trimmedShapeXNegativeY,
        path: [new Vector3(0, 0, 0), new Vector3(0, 0, buildVolumeHeight)],
        sideOrientation: Mesh.DOUBLESIDE,
      },
      scene,
    )
    roundedWallXNegativeY.material = scene.getMaterialByName(CLEARANCE_PLANE_MATERIAL)
    roundedWallXNegativeY.isPickable = false

    const roundedWallXNegativeYId = `${CLEARANCE_BUILD_VOLUME_WALL_ID}+X-Y`
    const roundedWallXNegativeYInstance = roundedWallXNegativeY.createInstance(CLEARANCE_PLANE)
    roundedWallXNegativeYInstance.isPickable = false
    roundedWallXNegativeYInstance.isVisible = false
    roundedWallXNegativeYInstance.metadata = {
      itemType: SceneItemType.ClearanceSystemReference,
      referenceType: ClearanceTypes.Walls,
      referenceId: roundedWallXNegativeYId,
      ...this.createRectanglesAndTrianglesForWall(trimmedShapeXNegativeY),
    } as ISystemReferenceClearanceMetadata
    this.highlightedPlanes.set(roundedWallXNegativeYId, roundedWallXNegativeYInstance)
    scene.removeMesh(roundedWallXNegativeY)
  }

  private createHighlightedPrintHeadLanes() {
    const buildPlateSizes = this.buildPlateManager.getBuildPlateSizes()
    this.machineProperties = this.buildPlateManager.getMachineProperties()
    if (this.machineProperties.numberOfPrintHeadLanes > 0) {
      switch (this.machineProperties.printHeadDirectionString) {
        case Direction.X:
        case Direction.NegativeX:
          for (let i = 0; i <= this.machineProperties.numberOfPrintHeadLanes; i = i + 1) {
            const lanePositionY =
              this.machineProperties.firstPrintHeadLaneSide + i * this.machineProperties.printHeadLaneWidth
            const width = buildPlateSizes.xMax - buildPlateSizes.xMin
            const height = buildPlateSizes.buildVolumeHeight
            const position = new Vector3(0, lanePositionY, height / 2)
            const planeInstance = this.clearanceManager.planeMesh.createInstance(CLEARANCE_PLANE)
            planeInstance.isPickable = false
            planeInstance.isVisible = false
            planeInstance.rotation.x = Math.PI / 2
            planeInstance.position = position
            planeInstance.scaling = new Vector3(width, height, 1)
            planeInstance.metadata = {
              itemType: SceneItemType.ClearanceSystemReference,
              referenceType: ClearanceTypes.PrintHeadLanes,
              referenceId: CLEARANCE_PRINT_HEAD_LANES_ID + i,
            } as ISystemReferenceClearanceMetadata
            this.highlightedPlanes.set(CLEARANCE_PRINT_HEAD_LANES_ID + i, planeInstance)
          }
          break
        case Direction.Y:
        case Direction.NegativeY:
          for (let i = 0; i <= this.machineProperties.numberOfPrintHeadLanes; i = i + 1) {
            const lanePositionX =
              this.machineProperties.firstPrintHeadLaneSide + i * this.machineProperties.printHeadLaneWidth
            const width = buildPlateSizes.yMax - buildPlateSizes.yMin
            const height = buildPlateSizes.buildVolumeHeight
            const position = new Vector3(lanePositionX, 0, height / 2)
            const planeInstance = this.clearanceManager.planeMesh.createInstance(CLEARANCE_PLANE)
            planeInstance.isPickable = false
            planeInstance.isVisible = false
            planeInstance.rotation.y = Math.PI / 2
            planeInstance.position = position
            planeInstance.scaling = new Vector3(width, height, 1)
            planeInstance.metadata = {
              itemType: SceneItemType.ClearanceSystemReference,
              referenceType: ClearanceTypes.PrintHeadLanes,
              referenceId: CLEARANCE_PRINT_HEAD_LANES_ID + i,
            } as ISystemReferenceClearanceMetadata
            this.highlightedPlanes.set(CLEARANCE_PRINT_HEAD_LANES_ID + i, planeInstance)
          }
          break
      }
    }
  }

  // Utils
  private radToRoundDeg(radians: number) {
    return Math.round((radians * 180) / Math.PI)
  }

  /**
   * Computes extremum points of hull
   * @param hullPoints hull itself
   */
  private computeHullExtremumPoints(hullPoints: Vector3[]): ExtremumPoints {
    const extremePoints = {
      xMin: new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE),
      xMax: new Vector3(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE),
      yMin: new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE),
      yMax: new Vector3(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE),
      zMin: new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE),
      zMax: new Vector3(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE),
    }

    for (const hullPoint of hullPoints) {
      if (hullPoint.x < extremePoints.xMin.x) {
        extremePoints.xMin = hullPoint
      }

      if (hullPoint.x > extremePoints.xMax.x) {
        extremePoints.xMax = hullPoint
      }

      if (hullPoint.y < extremePoints.yMin.y) {
        extremePoints.yMin = hullPoint
      }

      if (hullPoint.y > extremePoints.yMax.y) {
        extremePoints.yMax = hullPoint
      }

      if (hullPoint.z < extremePoints.zMin.z) {
        extremePoints.zMin = hullPoint
      }

      if (hullPoint.z > extremePoints.zMax.z) {
        extremePoints.zMax = hullPoint
      }
    }

    return extremePoints
  }

  private createRectanglesAndTrianglesForWall(shape: Vector3[]) {
    const rectangles: Rectangle[] = []

    for (let i = 0, j = 1; j < shape.length; i += 1, j += 1) {
      const xDirection = shape[j].subtract(shape[i])
      const yDirection = Vector3.LeftHandedForwardReadOnly

      const extent = new Vector2(xDirection.length() / 2, CLEARANCE_INVISIBLE_WALL_SIZE / 2)
      xDirection.normalize()

      const center = Vector3.Center(shape[i], shape[j])
      center.z = CLEARANCE_INVISIBLE_WALL_SIZE / 2

      rectangles.push(new Rectangle(center, [xDirection, yDirection], extent))
    }

    const triangles: Vector3[][] = []
    for (const rectangle of rectangles) {
      const vertices = rectangle.getVertices()
      triangles.push([vertices[0], vertices[1], vertices[2]])
      triangles.push([vertices[1], vertices[2], vertices[3]])
    }

    return { rectangles, triangles }
  }

  private getExtremumPointsFromPart(part: TransformNode) {
    const extremePoints = {
      xMin: new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE),
      xMax: new Vector3(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE),
      yMin: new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE),
      yMax: new Vector3(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE),
      zMin: new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE),
      zMax: new Vector3(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE),
    }

    const transformedHullPoint = new Vector3()
    const worldMatrix = part.getWorldMatrix()
    const hullPoints = (part.metadata as IPartMetadata).hull.hullPoints
    for (const hullPoint of hullPoints) {
      Vector3.TransformCoordinatesToRef(hullPoint, worldMatrix, transformedHullPoint)
      if (transformedHullPoint.x < extremePoints.xMin.x) {
        extremePoints.xMin.copyFrom(transformedHullPoint)
      }

      if (transformedHullPoint.x > extremePoints.xMax.x) {
        extremePoints.xMax.copyFrom(transformedHullPoint)
      }

      if (transformedHullPoint.y < extremePoints.yMin.y) {
        extremePoints.yMin.copyFrom(transformedHullPoint)
      }

      if (transformedHullPoint.y > extremePoints.yMax.y) {
        extremePoints.yMax.copyFrom(transformedHullPoint)
      }

      if (transformedHullPoint.z < extremePoints.zMin.z) {
        extremePoints.zMin.copyFrom(transformedHullPoint)
      }

      if (transformedHullPoint.z > extremePoints.zMax.z) {
        extremePoints.zMax.copyFrom(transformedHullPoint)
      }
    }

    return extremePoints
  }
}
