import Vue from 'vue'
import Component from 'vue-class-component'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'
import { IBuildPlan, IPrintStrategyParameterSet } from '@/types/BuildPlans/IBuildPlan'
import round from '@/utils/arithmetic/round'
import { convert as convertLength } from '@/utils/converter/lengthConverter'
import { DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, DEFAULT_DECIMAL_PRECISION } from '@/constants'
import { BuildPlanPrintStrategyDto } from '../../../types/PrintStrategy/BuildPlanPrintStrategy'
import { getDefaultBaseOnType, getSupportDefaultBasedOnType } from '../../../utils/parameterSet/parameterSetUtils'
import { SupportTypes } from '../../../types/BuildPlans/IBuildPlanItemSettings'
import { VersionablePk } from '@/types/Common/VersionablePk'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component
export class CalculateLayerThicknessMixin extends Vue {
  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter getBuildPlanPrintStrategy: BuildPlanPrintStrategyDto
  @buildPlansStore.Getter getPrintStrategyParameterSetByPk: (pk: VersionablePk) => IPrintStrategyParameterSet

  // Layer Thickness calculation
  getLayerThicknessFromParameterSet(parameterSetPk: VersionablePk, currentMinValue): number {
    const printStrategyParameterSet = this.getPrintStrategyParameterSetByPk(parameterSetPk)
    return Math.min(currentMinValue, printStrategyParameterSet.layerThickness)
  }

  calculateMinLayerThickness(lengthUnit: string): number {
    let minLayerThickness = Number.MAX_VALUE
    if (this.buildPlan.buildPlanItems) {
      const defaults = this.getBuildPlanPrintStrategy.defaults
      for (const buildPlanItem of this.buildPlan.buildPlanItems) {
        for (const partProperty of buildPlanItem.partProperties) {
          let printStrategyParameterSetPk: VersionablePk
          if (partProperty.printStrategyParameterSetId) {
            printStrategyParameterSetPk = new VersionablePk(
              partProperty.printStrategyParameterSetId,
              partProperty.printStrategyParameterSetVersion,
            )
          } else {
            printStrategyParameterSetPk = getDefaultBaseOnType(defaults, partProperty.type, partProperty.bodyType)
          }

          minLayerThickness = this.getLayerThicknessFromParameterSet(printStrategyParameterSetPk, minLayerThickness)
        }
        if (buildPlanItem.supports) {
          for (const support of buildPlanItem.supports) {
            const settings = support.settings
            if (settings.strategy !== SupportTypes.NoSupports) {
              let printStrategyParameterSetPk: VersionablePk
              if (settings.printStrategyParameterSetId) {
                printStrategyParameterSetPk = new VersionablePk(
                  settings.printStrategyParameterSetId,
                  settings.printStrategyParameterSetVersion,
                )
              } else {
                printStrategyParameterSetPk = getSupportDefaultBasedOnType(defaults, settings.strategy as SupportTypes)
              }
              minLayerThickness = this.getLayerThicknessFromParameterSet(printStrategyParameterSetPk, minLayerThickness)
            }
          }
        }
      }

      return round(
        convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, lengthUnit, minLayerThickness),
        DEFAULT_DECIMAL_PRECISION,
      )
    }
  }
}
