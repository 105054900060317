
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { IMarkTemplate } from '@/types/Marking/IMarkTemplate'
import MarkingAppearenceTab from '@/components/layout/buildPlans/marking/MarkingAppearanceTab.vue'
import MarkingContentTab from '@/components/layout/buildPlans/marking/MarkingContentTab.vue'
import MarkingLabels from './marking/MarkingLabels.vue'
import CommonBuildPlanToolsMixin from './mixins/CommonBuildPlanToolsMixin'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import LabelsList from '@/components/layout/buildPlans/marking/LabelsList.vue'
import LabelToolLoading from '@/components/layout/buildPlans/marking/LabelToolLoading.vue'
import { LabelServiceMixin } from '@/components/layout/buildPlans/marking/mixins/LabelServiceMixin'
import { Mixins, Watch } from 'vue-property-decorator'
import LabelConversionDialog from '@/components/layout/buildPlans/marking/LabelConversionDialog.vue'
import { CancellationToken } from '@/utils/common'
import { MarkingContentElementType } from '@/types/Label/enums'
import { SelectionUnit } from '@/types/BuildPlans/IBuildPlan'

const labelStore = namespace(StoresNamespaces.Labels)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

interface IMixinInterface extends CommonBuildPlanToolsMixin, LabelServiceMixin {}

@Component({
  components: {
    MarkingLabels,
    MarkingAppearenceTab,
    MarkingContentTab,
    LabelsList,
    LabelToolLoading,
    LabelConversionDialog,
  },
})
export default class BuildPlanMarkingTab
  extends Mixins<IMixinInterface>(CommonBuildPlanToolsMixin, LabelServiceMixin)
  implements IToolComponent
{
  @buildPlansStore.Getter isReadOnly: boolean

  @labelStore.Getter getLabelToolIsValid: boolean
  @labelStore.Getter isLabelToolOpened: boolean
  @labelStore.Getter getIsOkDisabled: boolean
  @labelStore.Getter isInDirtyState: boolean

  @labelStore.Mutation resetLastUpdatedLabelSetId: () => void
  @labelStore.Mutation resetLastUpdatedDynamicTextElements: (elementType?: MarkingContentElementType) => void

  @buildPlansStore.Action changeSceneReadOnly: Function
  @buildPlansStore.Action changeLabeledBodiesVisibility: (isVisible: boolean) => void

  templates: IMarkTemplate[] = []
  currentTemplate: IMarkTemplate = null
  settingTabs: any[] = [
    { id: 0, name: 'Content' },
    { id: 1, name: 'Appearance' },
  ]
  currentTab: number = this.settingTabs[0].id
  cancellationToken: CancellationToken

  beforeMount() {
    this.interactiveCommunicationService.isDisconnectTriggeredByUser = false
    this.cancellationToken = new CancellationToken()
    if (!this.getLabelUpdateInProgress) {
      this.establishConnection(this.cancellationToken)
    }
  }

  mounted() {
    this.addUnloadHandler()
    this.setCancelName()
    this.$emit('mounted')
  }

  destroyed() {
    this.removeUnloadHandler()
  }

  async beforeDestroy() {
    if (this.cancellationToken) {
      this.cancellationToken.cancel()
    }

    if (this.interactiveCommunicationService) {
      this.interactiveCommunicationService.isDisconnectTriggeredByUser = true
    }

    this.changeLabeledBodiesVisibility(true)
    this.clearCachedInsights()
    this.resetLabelSetData()
    this.changeSceneReadOnly()
    this.setLabelToolIsValid(true)
    this.deactivateLabelManualPlacement()
    this.setActiveToolHasUnsavedData(false)
    this.setIsSaving(false)
    await this.setSelectionMode({ mode: SelectionUnit.Part, options: { shouldAffectSelectionBox: false } })
  }

  get isLabelToolNotOpenedWithUpdate() {
    return !this.isLabelToolOpened || (this.isLabelToolOpened && this.getRequiresLabelSetUpdates)
  }

  @Watch('hasDataToSave')
  @Watch('allSetsDefined')
  @Watch('getIsOkDisabled', { immediate: true })
  @Watch('getLabelToolIsValid')
  @Watch('isInDirtyState')
  @Watch('getLabelSetsToSave')
  @Watch('isLabelToolOpened')
  updateOkButtonStatus() {
    if (!this.hasDataToSave || !this.allSetsDefined) {
      // The condition when we don't have data to save should be with the highest priority in disabling Save button
      // All other conditions should be considered only in the case if it's false
      this.$emit('setOkDisabled', true)
    } else {
      this.$emit('setOkDisabled', this.getIsOkDisabled || !this.getLabelToolIsValid || this.isInDirtyState)
    }
  }

  @Watch('hasDataToSave')
  @Watch('atLeastOneSetDefined')
  setCancelName() {
    const hasDataToSave = this.hasDataToSave && this.atLeastOneSetDefined
    this.setActiveToolHasUnsavedData(hasDataToSave)
    this.$emit('setCancelName', hasDataToSave ? this.$t('cancel') : this.$t('close'))
  }

  @Watch('isLabelReadOnly')
  onReadOnlyStateChanged(value, oldValue) {
    // if there was a readonly state when we opened the tool and then readonly state was removed - we should connect
    // label ui to interactive service
    if (!value && oldValue) {
      this.establishConnection(this.cancellationToken)
    }
  }

  async clickOk() {
    this.interactiveCommunicationService.isDisconnectTriggeredByUser = true
    if (this.activeLabelSet && !this.activeLabelSet.settings.placementMethodAutomatic) {
      this.hideManualLabelHandle(true)
      this.deactivateLabelManualPlacement()
    }
    // Pass all data to server
    await this.saveLabels()
    this.resetLastUpdatedLabelSetId()
    this.resetLastUpdatedDynamicTextElements()
  }

  async clickSecondaryAction() {
    await this.saveLabels()

    this.createLabelSetsSnapshot()
    this.cacheLabelInsights([...this.labelInsights])
    this.clearLabelAutomaticPlacements()
    this.setAutomaticPlacementsBasedOnPatches()
    this.setIgnoreChangesByDirtyStates(false)
  }

  async clickCancel() {
    this.interactiveCommunicationService.isDisconnectTriggeredByUser = true
    if (this.activeLabelSet && !this.activeLabelSet.settings.placementMethodAutomatic) {
      this.hideManualLabelHandle(true)
      this.deactivateLabelManualPlacement()
    }

    // Cancel all changes or get all rows from server
    this.setIgnoreChangesByDirtyStates(true)
    await this.closeLabelTool()
    this.resetLastUpdatedLabelSetId()
    this.resetLastUpdatedDynamicTextElements()
    this.setActiveToolHasUnsavedData(false)
  }

  async clickClose() {
    this.interactiveCommunicationService.isDisconnectTriggeredByUser = true
    this.setIgnoreChangesByDirtyStates(true)
  }

  getOkName() {
    return 'ok'
  }
}
