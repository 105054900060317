
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { FontStyleHelpBalloonInfo, FontStyleItem } from '@/types/Label/FontStyleHelpBalloonInfo'

const labelStore = namespace(StoresNamespaces.Labels)
const RIGHT_MARGIN = 5 // Margin in pixels from row element

@Component
export default class LabelFontStyleSelectorRow extends Vue {
  @Prop() item: FontStyleItem

  @labelStore.Mutation setFontStyleHelpBalloonInfo: (info: FontStyleHelpBalloonInfo) => void

  onMouseEnter(event: MouseEvent) {
    const { top, right } = (event.target as HTMLElement).parentElement.getBoundingClientRect()
    const show = true
    const left = right + RIGHT_MARGIN
    const src = this.item.src
    this.setFontStyleHelpBalloonInfo({ show, left, top, src })
  }

  onMouseLeave() {
    const show = false
    const left = 0
    const top = 0
    const src = null
    this.setFontStyleHelpBalloonInfo({ show, left, top, src })
  }
}
