
import '@/assets/styles/home.scss'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { MachineTypeDto, EditViewMode, UpdateSiteDto, SiteDto } from '@/types/Sites/Site'
import SiteConstantUpdater from '@/components/layout/admin/sites/SiteConstantUpdater.vue'
import MachineTypeListRow from '@/components/layout/admin/sites/MachineTypeListRow.vue'
import { SiteAdminMixin } from './mixins/SiteAdminMixin'
import { Watch } from 'vue-property-decorator'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component({
  components: {
    SiteConstantUpdater,
    MachineTypeListRow,
  },
})
export default class SiteDetailsAdmin extends SiteAdminMixin {
  @sitesStore.Action fetchSiteById: (siteId: string) => Promise<SiteDto>
  @sitesStore.Action updateSite: (params: { siteId: string; updateSiteDto: UpdateSiteDto }) => Promise<SiteDto>
  @sitesStore.Action fetchMachineTypesBySiteId: (siteId: number | string) => Promise<MachineTypeDto[]>

  @sitesStore.Getter getSelectedSite: SiteDto
  @sitesStore.Getter getMachineTypesOfActiveState: MachineTypeDto[]
  @sitesStore.Getter isBreadcrumbsReady: boolean

  isReady = false

  @Watch('isBreadcrumbsReady')
  onBreadcrumbsReadyStateChange(isReady: boolean) {
    if (isReady) {
      this.prepareData()
    }
  }

  async prepareData() {
    try {
      await this.fetchMachineTypesBySiteId(this.getSelectedSite.id)
      this.isReady = true
    } catch (error) {
      this.isReady = false
    }
  }

  onCloseEditMode() {
    this.editMode = EditViewMode.None
  }

  async onUpdateSiteConstants(updateSiteDto: UpdateSiteDto) {
    this.editMode = EditViewMode.None
    const siteId = this.$route.params.siteId

    await this.updateSite({ siteId, updateSiteDto })
  }
}
