
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IResult } from '@/types/CostEstimations/ICostEstimation'
import FallbackImage from '@/components/layout/FallbackImage.vue'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'

const commonStore = namespace(StoresNamespaces.Common)

@Component({
  name: 'TwoMachinesBar',
  components: {
    FallbackImage,
  },
})
export default class TwoMachinesBar extends Vue {
  @commonStore.Getter tooltipOpenDelay: number

  @Prop() results: IResult[]
}
