
import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator'
import { ISimulationStep, StepType } from '@/types/Simulation/SimulationSteps'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'
import { Unit, getUnitTolerance, getUnitLabel } from '@/types/Simulation/Units'
import {
  IDmlmSimulateCompensation,
  StepProcessType,
  TemperatureProfileType,
  convertArrayToTemperatureProfile,
} from '@/types/Simulation/SimulationCompensation'
import { BoundingBox } from '@/visualization/models/DataModel'
import { CalculateLayerThicknessMixin } from '@/components/layout/mixins/CalculateLayerThicknessMixin'
import { Vector3 } from '@babylonjs/core/Maths'

const visualizationStore = namespace(StoresNamespaces.Visualization)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const AFTER_COOLING_PATTERN = /After_Cooling/
const AFTER_RELAXATION_PATTERN = /After_Relaxation/
const PLASTIC_STRAIN_ESTIMATION = /PlasticStrainEstimation/
const SECONDS_IN_MINUTE = 60

@Component
export default class VisualizationSlider extends Mixins(Vue, CalculateLayerThicknessMixin) {
  get sliderVisible() {
    return (
      this.getSimulationTimestamps.current !== undefined &&
      this.getSimulationTimestamps.stamps.length > 1 &&
      this.getSelectedSimulationStep.type !== StepType.BinderJet &&
      this.getSelectedSimulationStep.type !== StepType.DmlmMaterialRemoval
    )
  }

  get maxSliderValue() {
    return this.getSimulationTimestamps ? this.getSimulationTimestamps.stamps.length - 1 : 0
  }

  get sliderLabel() {
    if (this.isPrintingStep) {
      return this.$t('simulationSlider.buildLabel')
    }

    if (this.isStressReliefStep) {
      return this.$t('simulationSlider.reliefLabel')
    }

    return this.$t('simulationSlider.compensationLabel')
  }

  get isStressReliefStep() {
    return this.getSelectedSimulationStep.type === StepType.DmlmStressRelief
  }

  get isPrintingStep() {
    return this.getSelectedSimulationStep.type === StepType.DmlmBuild
  }

  get isCompensationStep() {
    return this.getSelectedSimulationStep.type === StepType.Compensation
  }

  get sliderClass() {
    return !this.isCompensationStep ? 'two-ticks-slider' : 'all-ticks-slider'
  }

  @visualizationStore.Getter('getStepBounds') getStepBounds: { max: Vector3; min: Vector3 }
  @visualizationStore.Getter('getSelectedSimulationStep') getSelectedSimulationStep: ISimulationStep
  @visualizationStore.Getter('getSimulationTimestamps') getSimulationTimestamps: {
    stamps: number[]
    current: number
  }
  @visualizationStore.Getter('getPrintingLayers') getPrintingLayers: string[]
  @visualizationStore.Getter('boundingBox') boundingBox: BoundingBox
  @visualizationStore.Getter('getWarpingInfo') getWarpingInfo: {
    enabled: boolean
    factor: number
    available: boolean
    loaded: boolean
  }

  @visualizationStore.Mutation('changeSimulationTimestamp') changeSimulationTimestamp: Function
  @buildPlansStore.Getter getSimulationParmeters

  @Prop({ default: false }) disabled: boolean

  sliderUpperValue: any = ''
  sliderLowerValue: any = ''
  currentSelection: any = ''
  currentValue: string = ''
  private temperatureProfile: TemperatureProfileType[]
  private maxBuildHeight: number = 0
  private layerThickness: number = 0
  private slicerMax: number = 0

  @Watch('getSimulationTimestamps') onTimestampsChanged() {
    if (this.isStressReliefStep) {
      const simulationParameters = this.dmlmSimulationParameters
      if (simulationParameters) {
        const reliefStep = simulationParameters.processSteps.find((s) => s.processType === StepProcessType.StressRelief)
        this.temperatureProfile = convertArrayToTemperatureProfile(reliefStep.ovenTemperatureProfile)
      }

      this.sliderUpperValue = this.$t('simulationSlider.time', { value: this.getCurrentTimeValue(this.maxSliderValue) })
      this.sliderLowerValue = this.$t('simulationSlider.time', { value: 0 })
    } else if (this.isCompensationStep) {
      this.sliderUpperValue = this.$t('simulationSlider.compensated', { value: this.maxSliderValue })
      this.sliderLowerValue = this.$t('simulationSlider.compensationNominal')
    } else if (this.isPrintingStep) {
      this.sliderUpperValue = `${this.maxBuildHeight} ${getUnitLabel(Unit.Millimeter)}`
      this.sliderLowerValue = `0 ${getUnitLabel(Unit.Millimeter)}`
      this.layerThickness = this.calculateMinLayerThickness('mm')
    }

    this.setCurrentSelectionValue()
  }

  @Watch('getStepBounds') onBoundsChanged() {
    if (!this.getStepBounds.max) return

    this.slicerMax = this.getStepBounds.max.z

    if (this.isPrintingStep) {
      if (this.isLastTimestamp) {
        this.maxBuildHeight = this.currentHeight
        this.sliderUpperValue = `${this.currentHeight} ${getUnitLabel(Unit.Millimeter)}`
      }

      this.currentValue = `(${this.currentHeight} ${getUnitLabel(Unit.Millimeter)})`
      this.setCurrentLayer(this.getSimulationTimestamps.current)
    }
  }

  getCurrentTimeValue(index: number) {
    return Math.floor(this.getSimulationTimestamps.stamps[index] / SECONDS_IN_MINUTE)
  }

  increment() {
    const newValue = this.getSimulationTimestamps.current + 1
    if (newValue <= this.maxSliderValue) {
      this.updateSimulationTimeline(newValue)
    }
  }

  decrement() {
    const newValue = this.getSimulationTimestamps.current - 1
    if (newValue >= 0) {
      this.updateSimulationTimeline(newValue)
    }
  }

  updateSimulationTimeline(current) {
    this.changeSimulationTimestamp(current)
    this.setCurrentSelectionValue()
  }

  private get dmlmSimulationParameters(): IDmlmSimulateCompensation {
    return this.getSimulationParmeters as IDmlmSimulateCompensation
  }

  private get isLastTimestamp(): boolean {
    return this.getSimulationTimestamps.current === this.getSimulationTimestamps.stamps.length - 1
  }

  private get currentHeight(): number {
    const tolerance = Math.abs(getUnitTolerance(Unit.Millimeter))
    return +this.slicerMax.toFixed(tolerance)
  }

  private setCurrentSelectionValue() {
    const current = this.getSimulationTimestamps.current
    if (this.isCompensationStep) {
      this.currentSelection =
        current === 0
          ? this.$t('simulationSlider.compensationNominal')
          : this.$t('simulationSlider.compensated', { value: current })
    } else if (this.isStressReliefStep) {
      this.currentSelection = this.$t('simulationSlider.time', { value: this.getCurrentTimeValue(current) })
      this.currentValue = this.setCurrentTemperature()
    } else if (this.isPrintingStep && this.slicerMax !== undefined) {
      const tolerance = Math.abs(getUnitTolerance(Unit.Millimeter))
      const height = +this.slicerMax.toFixed(tolerance)
      this.currentValue = `(${height} ${getUnitLabel(Unit.Millimeter)})`
      this.setCurrentLayer(current)
    }
  }

  private setCurrentLayer(stepNumber: number) {
    if (stepNumber < this.getPrintingLayers.length) {
      const layer = this.getPrintingLayers[stepNumber]
      if (AFTER_COOLING_PATTERN.test(layer)) {
        this.currentSelection = this.$t('simulationSlider.afterCooling')
        return
      }

      if (AFTER_RELAXATION_PATTERN.test(layer)) {
        this.currentSelection = this.$t('simulationSlider.afterRelaxation')
        return
      }

      if (PLASTIC_STRAIN_ESTIMATION.test(layer)) {
        this.currentSelection = this.$t('simulationSlider.plasticStrain')
        return
      }
    }

    this.currentSelection = this.$t('simulationSlider.layer', {
      value: Math.ceil(Math.abs(this.currentHeight) / this.layerThickness),
    })
  }

  private setCurrentTemperature() {
    let temperature = 0
    const time = this.getCurrentTimeValue(this.getSimulationTimestamps.current)
    for (let i = 0; i < this.temperatureProfile.length; i += 1) {
      const profileItem = this.temperatureProfile[i]
      if (profileItem.timeItem === time) {
        temperature = profileItem.temperatureItem
        break
      }

      if (profileItem.timeItem > time) {
        const previousItem = this.temperatureProfile[i - 1]
        const tempDiff = profileItem.temperatureItem - previousItem.temperatureItem
        const timeDiff = previousItem.timeItem - profileItem.timeItem
        temperature = previousItem.temperatureItem + (tempDiff * (previousItem.timeItem - time)) / timeDiff
        const tolerance = Math.abs(getUnitTolerance(Unit.CelsiusDegree))
        temperature = +temperature.toFixed(tolerance)
        const newEntry = { index: 0, timeItem: time, temperatureItem: temperature }
        this.temperatureProfile.splice(i, 0, newEntry)
        break
      }
    }

    return `(${temperature} ${getUnitLabel(Unit.CelsiusDegree)})`
  }
}
