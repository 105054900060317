import { IAdapter } from '@/types/Common/IAdapter'
import { CostEvaluationResultViewModel } from '@/types/CostEstimations/ICostEstimation'
import average from '@/utils/arithmetic/average'

export class DMLMResultAdapter implements IAdapter<CostEvaluationResultViewModel> {
  adapt(item: any): CostEvaluationResultViewModel {
    const result = new CostEvaluationResultViewModel()

    result.quantityOfMachinesRequired = Math.ceil(item.machineBuildData.quantityOfMachinesRequired._max)

    // Build Time
    result.machineBuildTime = item.machineBuildData.machineBuildTimeHours._value
    result.machineBuildTimeMin = item.machineBuildData.machineBuildTimeHours._min
    result.machineBuildTimeMax = item.machineBuildData.machineBuildTimeHours._max

    result.partsPerBuild = item.machineBuildData.partsPerBuild

    const { buildMaterialCost, solidMaterialWaste, powderMaterialWaste, costOfPowderReuse } = item.partCostOfOperating
    result.complexMaterialCostMin =
      buildMaterialCost._min + solidMaterialWaste._min + powderMaterialWaste._min + costOfPowderReuse._min
    result.complexMaterialCostMax =
      buildMaterialCost._max + solidMaterialWaste._max + powderMaterialWaste._max + costOfPowderReuse._max
    result.complexMaterialCostAverage = average(result.complexMaterialCostMin, result.complexMaterialCostMax)

    const { platform, recoater, filters, inertGas, electricity, laserMaintenance } = item.partCostOfOperating
    result.consumablesMin =
      platform + recoater + filters._min + inertGas._min + electricity._min + laserMaintenance._min
    result.consumablesMax =
      platform + recoater + filters._max + inertGas._max + electricity._max + laserMaintenance._max
    result.consumablesAverage = average(result.consumablesMin, result.consumablesMax)

    result.maintenanceMin = item.partCostOfOperating.msaAllocation._min
    result.maintenanceMax = item.partCostOfOperating.msaAllocation._max
    result.maintenanceAverage = average(
      item.partCostOfOperating.msaAllocation._min,
      item.partCostOfOperating.msaAllocation._max,
    )

    result.printerMin = item.straightLineDepreciation.depExpensePerPart._min
    result.printerMax = item.straightLineDepreciation.depExpensePerPart._max
    result.printerAverage = average(result.printerMin, result.printerMax)

    const totalPrintProcessCostMin =
      result.complexMaterialCostMin + result.consumablesMin + result.maintenanceMin + result.printerMin
    result.totalPrintProcessCostMin = totalPrintProcessCostMin

    const totalPrintProcessCostMax =
      result.complexMaterialCostMax + result.consumablesMax + result.maintenanceMax + result.printerMax
    result.totalPrintProcessCostMax = totalPrintProcessCostMax

    result.totalPrintProcessCostAverage = average(result.totalPrintProcessCostMin, result.totalPrintProcessCostMax)

    const { buildCostOfOperating } = item

    result.totalBuildCostMin = buildCostOfOperating.totalCostOfOperating._min
    result.totalBuildCostMax = buildCostOfOperating.totalCostOfOperating._max

    return result
  }
}
