
import Vue from 'vue'
import Component from 'vue-class-component'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component
export default class InfinityScrollArea extends Vue {
  @fileExplorerStore.Getter('isLoading') isLoading: () => boolean

  async intersection(entries) {
    if (this.isLoading) {
      return
    }

    if (entries[0].intersectionRatio >= 0.5) {
      this.$emit('intersection')
    }
  }
}
