
import Vue from 'vue'
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { format } from 'date-fns'
import StoresNamespaces from '@/store/namespaces'
import { INotification } from '@/types/Notification/INotification'
import { IUser } from '@/types/User/IUser'
import { jobTypeToName, notificationTypeToName } from '@/utils/string'
import messageService from '@/services/messageService'
import { NOTIFICATION_TIMEOUT, NOTIFICATION_TYPE_NAME_MAP } from '@/constants'
import CommunicationService from '@/services/CommunicationService'
import { BrokerEvents } from '@/types/Common/BrokerEvents'
import { BrokerMessage } from '@/types/Common/BrokerMessage'
import Selector from '@/components/controls/Common/Selector.vue'
import { isTabVisible } from '@/utils/common'
import { getRoleLabel } from '@/utils/user'
import ActionBarMixin from '@/components/layout/FileExplorer/Table/mixins/ActionBarMixin'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import NotificationsInfinityScrollArea from './NotificationsInfinityScrollArea.vue'
import { JobType } from '@/types/PartsLibrary/Job'
import { validate } from 'uuid'
import { RouterNames, RouterPaths } from '@/router'
import fileExplorer from '@/api/fileExplorer'
import {
  ActiveToolUnsavedChangesMixin,
  ExitToolAction,
} from '@/components/layout/buildPlans/mixins/ActiveToolUnsavedChangesMixin'

const notificationsStore = namespace(StoresNamespaces.Notifications)
const userStore = namespace(StoresNamespaces.User)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: {
    Selector,
    NotificationsInfinityScrollArea,
  },
})
export default class NotificationsMenu extends Mixins(ActionBarMixin, ActiveToolUnsavedChangesMixin) {
  @notificationsStore.Action('fetchAllNotifications') fetchAllNotifications: () => Promise<INotification[]>
  @notificationsStore.Action('readNotification') readNotification: (notificationId: string) => Promise<void>
  @notificationsStore.Action('updateLastNotificationTime') updateLastNotificationTime: (time: string) => void

  @notificationsStore.Getter('getAllNotifications') getAllNotifications: INotification[]
  @notificationsStore.Getter('getSelectedNotificationType') selectedNotificationType: { text: string; value: string[] }
  @notificationsStore.Getter('getLastNotificationTime') lastNotificationTime: string
  @notificationsStore.Getter('isLoading') isLoadingNotifications: () => boolean

  @notificationsStore.Mutation addNotifications: (n: INotification[]) => void
  @notificationsStore.Mutation removeNotifications: (n: string[]) => void
  @notificationsStore.Mutation setSelectedNotificationType: (type: { text: string; value: string[] }) => void
  @notificationsStore.Mutation setLastNotificationTime: (lastNotificationTime: string) => void
  @notificationsStore.Mutation clearPaginationData: Function
  @notificationsStore.Mutation clearNotifications: Function

  @userStore.Action('fetchAllUsers') fetchAllUsers: () => Promise<IUser[]>
  @userStore.Getter('getUserDetails') userDetails: IUser
  @userStore.Getter('lookupFullNameById') lookupFullNameById: (userId: string, allowYou: boolean) => string

  @fileExplorerStore.Action getGetRunningAndFailedJobsByItemIds: Function

  dialog: boolean = false
  numNewNotifications: number = 0
  checkNotificationsTimeout: number
  notificationTypes = []
  connector: CommunicationService = null
  isNotificationsSelectorOpened: boolean = false

  get notificationType(): { text: string; value: string[] } {
    return this.selectedNotificationType
  }

  set notificationType(type: { text: string; value: string[] }) {
    this.setSelectedNotificationType(type)
  }

  get allNotifications() {
    return this.getAllNotifications
  }

  // this logic is needed for click-outside logic
  changeMenuOpenedStatus(status: boolean) {
    this.isNotificationsSelectorOpened = status
  }

  async beforeMount() {
    for (const key of Object.keys(NOTIFICATION_TYPE_NAME_MAP)) {
      const notificationTypeName = notificationTypeToName(key)
      const existingNotificationType = this.notificationTypes.find((type) => type.text === notificationTypeName)
      if (existingNotificationType) {
        existingNotificationType.value.push(key)
      } else {
        this.notificationTypes.push({
          text: notificationTypeToName(key),
          value: [key],
        })
      }
    }
    this.notificationTypes.sort((a, b) => {
      return a.text.localeCompare(b.text)
    })

    await this.fetchAllUsers()
    await this.checkNotifications()

    this.connector = CommunicationService.getConnector()
    this.connector.subscribe(BrokerEvents.PermissionChanged, this.onNewNotification)
    this.connector.subscribe(BrokerEvents.NotificationCreated, this.onNewNotification)
    this.connector.subscribe(BrokerEvents.NotificationDeleted, this.onNotificationDeleted)
  }

  beforeDestroy() {
    this.connector.unsubscribe(BrokerEvents.PermissionChanged, this.onNewNotification)
    this.connector.unsubscribe(BrokerEvents.NotificationCreated, this.onNewNotification)
    this.connector.unsubscribe(BrokerEvents.NotificationDeleted, this.onNotificationDeleted)
    window.clearTimeout(this.checkNotificationsTimeout)
  }

  async intersection() {
    await this.checkNotifications()
  }

  onNewNotification(message: BrokerMessage) {
    const notification = message.message
    this.addNotifications([notification])
    this.setLastNotificationTime(notification.createdAt)
    this.numNewNotifications += 1
  }

  onNotificationDeleted(message: BrokerMessage) {
    const notificationId = message.message
    this.removeNotifications([notificationId])
  }

  checkNotificationsDelayed() {
    this.checkNotificationsTimeout = window.setTimeout(async () => {
      if (isTabVisible()) {
        await this.checkNotifications()
      }
      this.checkNotificationsDelayed()
    }, NOTIFICATION_TIMEOUT)
  }

  async checkNotifications() {
    await this.fetchAllNotifications()
    this.numNewNotifications = 0
    if (this.allNotifications && this.allNotifications.length > 0) {
      // check if we have any new notifications
      if (this.lastNotificationTime === null) {
        this.numNewNotifications = this.allNotifications.length
      } else {
        const lastNotificationTime = Date.parse(this.lastNotificationTime)
        let index = 0
        while (
          index < this.allNotifications.length &&
          Date.parse(this.allNotifications[index].createdAt) > lastNotificationTime
        ) {
          this.numNewNotifications = this.numNewNotifications + 1
          index = index + 1
        }
      }
    }
  }

  async openNotifications() {
    this.dialog = !this.dialog
    if (this.dialog) {
      this.$emit('notificationsOpened')
    } else {
      this.clearPaginationDataAndNotifications()
    }
    // if we opened notifications popup - set number of "unseen" notifications to 0
    // and remember the last seen notification time on the server
    if (this.allNotifications.length > 0 && this.dialog) {
      const updatedLastNotificationTime = this.allNotifications[0].createdAt
      await this.updateLastNotificationTime(updatedLastNotificationTime)
      this.numNewNotifications = 0
    }
  }

  async changeSelectedNotificationType(newValue) {
    const notificationType = this.notificationTypes.find((type) => type.value === newValue)
    this.setSelectedNotificationType(notificationType)
    this.clearPaginationDataAndNotifications()
    await this.fetchAllNotifications()
    this.numNewNotifications = 0
  }

  clearPaginationDataAndNotifications() {
    this.clearPaginationData()
    this.clearNotifications()
  }

  async readCurrentNotification(notification: INotification) {
    const toolAction = await this.canExitActiveTool()
    if (toolAction === ExitToolAction.DoNotExit) return

    // mark notification as read if needed
    if (!notification.read) {
      await this.readNotification(notification.id)
    }

    if (!notification.pathname) {
      return
    }

    const currentRoute = this.$router.currentRoute

    if (currentRoute.fullPath !== notification.pathname) {
      const pathname = notification.pathname
      const itemId = pathname.split('/').find((i) => validate(i))

      const isItemAvailible = await this.isItemAvailible(itemId)
      if (!isItemAvailible) {
        this.showItemUnavailableMessage(notification)
        return
      }

      const notificationItem = this.find(itemId)

      // open the item without reloading page if file explorer is open
      if (notificationItem && currentRoute.fullPath.includes(RouterPaths.DefaultFileExplorer.slice(0, -1))) {
        await this.openItem(notificationItem)
        return
      }

      const matchedRoute = (this.$router as any).match(notification.pathname)
      if (matchedRoute) {
        const routeData = this.$router.resolve(notification.pathname)
        const isPrintOrder = routeData.route.name === RouterNames.PreviewPrintOrder

        if (!isPrintOrder) {
          const isItemTrashed = await this.isNotificationItemTrashed(itemId)
          if (isItemTrashed) {
            this.dialog = !this.dialog
            await this.goToTrash()
            return
          }

          await this.getGetRunningAndFailedJobsByItemIds([itemId])

          if (
            this.hasFailedJobs(itemId) &&
            this.getFailedJobsByItemId(itemId).some((job) => job.jobType === JobType.IMPORT)
          ) {
            const item = await this.fetchItemById(itemId)
            const parentFolder = await this.fetchItemById(item.parentId)

            await this.openFolder(parentFolder)

            return
          }
        }

        // force page reload to bypass Vue's flakiness
        // related to conditionally refreshing the page
        // only in certain conditions
        location.href = notification.pathname
      }
    }

    this.dialog = !this.dialog
  }

  private showItemUnavailableMessage(notification: INotification) {
    let message: string

    const isPrintOrder = notification.jobType === JobType.PRINT && notification.message
    const isVariantJob = notification.jobType && notification.itemVersionLabel

    if (isPrintOrder) {
      message = this.$t('notificationPrintOrderDeleted', {
        itemName: Vue.prototype.$sanitize(notification.itemName),
        itemType: this.getItemType(notification.itemType, notification.itemSubType),
        jobName: notification.message,
        versionLabel: Vue.prototype.$sanitize(notification.itemVersionLabel),
      }).toString()
    } else if (isVariantJob) {
      message = this.$t('notificationVariantWasDeleted', {
        itemName: Vue.prototype.$sanitize(notification.itemName),
        itemType: this.getItemType(notification.itemType, notification.itemSubType),
        versionLabel: Vue.prototype.$sanitize(notification.itemVersionLabel),
      }).toString()
    } else {
      message = this.$t('notificationItemWasDeleted', {
        itemName: Vue.prototype.$sanitize(notification.itemName),
      }).toString()
    }

    messageService.showInfoMessage(message)
  }

  async isItemAvailible(itemId: string) {
    const itemExists = await fileExplorer.itemExistsForUser(itemId, true)
    return itemExists
  }

  generateNotification(notification: INotification) {
    const actorUserName = this.lookupFullNameById(notification.actorUserId, false)
    const recipientUserName = this.lookupFullNameById(notification.recipientUserId, true)
    let jobName
    if (notification.jobType) {
      jobName = jobTypeToName(notification.jobType)
    }

    return this.getLocaleMessages(notification, jobName, actorUserName, recipientUserName)
  }

  isNotificationRelatedToVariant(notification: INotification): boolean {
    return !!notification.itemVersionLabel
  }

  formatDate(dateStr: string) {
    return format(new Date(dateStr), this.$t('dateAndTimeFormat') as string)
  }

  showNotificationMessage(notification: INotification): boolean {
    let toShowMsg = false
    if (notification.message && !(notification.jobType === JobType.PRINT)) {
      toShowMsg = true
    }
    return toShowMsg
  }

  private getLocaleMessages(notification: INotification, jobName, actorUserName: string, recipientUserName: string) {
    let modifiedJobName = jobName
    if (notification.jobType === JobType.PRINT && notification.message) {
      // notification.message for print job contains print order number
      modifiedJobName = `${notification.message}`
      if (notification.template === 'jobNotification') {
        notification.template = 'jobNotificationPrintOrder'
      }
    }

    if (this.isNotificationRelatedToVariant(notification)) {
      return this.$t(`${notification.template}Variant`, {
        jobName: modifiedJobName,
        actorUser: actorUserName,
        recipientUser: recipientUserName,
        itemName: Vue.prototype.$sanitize(notification.itemName),
        itemVersion: Vue.prototype.$sanitize(notification.itemVersionLabel),
        itemType: this.getItemType(notification.itemType, notification.itemSubType),
        role: getRoleLabel(notification.role),
      })
    }

    return this.$t(notification.template, {
      jobName: modifiedJobName,
      actorUser: actorUserName,
      recipientUser: recipientUserName,
      itemName: Vue.prototype.$sanitize(notification.itemName),
      itemVersion: notification.itemVersion,
      itemType: this.getItemType(notification.itemType, notification.itemSubType),
      role: getRoleLabel(notification.role),
    })
  }

  private getItemType(itemType: number, itemSubType: number) {
    let result: string = ''
    if (itemSubType === ItemSubType.SinterPlan || itemType === ItemType.IbcPlan) {
      result = this.$i18n.t('sinterPlan').toString().toLowerCase()
    } else {
      switch (itemType) {
        case ItemType.BuildPart:
          result = this.$i18n.t('part').toString().toLowerCase()
          break
        case ItemType.BuildPlan:
          result = this.$i18n.t('buildPlan').toString().toLowerCase()
          break
        case ItemType.Folder:
          result = this.$i18n.t('folder').toString().toLowerCase()
          break
      }
    }

    return result
  }
}
