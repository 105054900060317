export interface DuplicatePayload {
  axisData: DuplicateData[]
  buildPlanItemId: string
  partId: string
  copySupports: boolean
}

export interface DuplicateData {
  amount: number
  spacing: number
  isMain: boolean
  axisName: DuplicateAxes
}

export enum DuplicateAxes {
  X = 'x',
  Y = 'y',
  Z = 'z',
}

export enum DuplicateMode {
  BoundingBoxes,
  Geometry,
}
