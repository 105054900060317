import Vue from 'vue'
import Component from 'vue-class-component'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'
import { formatDateWithDefaultFormat } from '@/utils/common'

const userStore = namespace(StoresNamespaces.User)

@Component
export class DateByUserMixin extends Vue {
  @userStore.Getter lookupFullNameById: (userId: string) => string

  /**
   * Provides string that contains date and user name in format 'MMM d, YYYY by USERNAME'
   * @param date as Date obj or string representation
   * @param userId user id, based on which user name will be retrived from store
   * @returns formated string, example 'Jun 6, 2022 by John Doe'
   */
  getFormatedActionInfo(date: Date | string, userId: string) {
    return `${formatDateWithDefaultFormat(date)} ${this.$i18n.t('by')} ${this.lookupFullNameById(userId)}`
  }
}
