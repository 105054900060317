import { GetterTree } from 'vuex'
import { IUndoableState } from '@/store/modules/commandManager/types'
import { IRootState } from '@/store/types'
import { LIST_OF_DISABLED_COMMAND_VIEW_MODE_TYPES } from '@/constants'
import { ICommand } from '@/types/UndoRedo/ICommand'

const BUILD_PLAN_VIEW_MODE_GETTER_NAME = 'buildPlans/getBuildPlanViewMode'

export const getters: GetterTree<IUndoableState, IRootState> = {
  isCommandManagerReady(state: IUndoableState, localGetters, _, rootGetters): boolean {
    // Disable Undo-Redo manager if at least variant contains running or queued jobs.
    if (rootGetters['buildPlans/getAllCurrentVariantRunningJobs'].length) {
      return false
    }

    return state.isCommandManagerReady
  },

  canUndo(state: IUndoableState, localGetters, _, rootGetters): boolean {
    if (!isAllowedViewModeType(rootGetters[BUILD_PLAN_VIEW_MODE_GETTER_NAME])) return false

    if (state.isToolModeActive) {
      return state.toolUndoStack.length > 0
    }

    return state.buildPlanUndoStack.length > 0
  },

  canRedo(state: IUndoableState, localGetters, _, rootGetters): boolean {
    if (!isAllowedViewModeType(rootGetters[BUILD_PLAN_VIEW_MODE_GETTER_NAME])) return false

    if (state.isToolModeActive) {
      return state.toolRedoStack.length > 0
    }

    return state.buildPlanRedoStack.length > 0
  },

  getToolUndoStack(state: IUndoableState): ICommand[] {
    return state.toolUndoStack
  },

  getBuildPlanUndoStack(state: IUndoableState): ICommand[] {
    return state.buildPlanUndoStack
  },

  getToolRedoStack(state: IUndoableState): ICommand[] {
    return state.toolRedoStack
  },

  getBuildPlanRedoStack(state: IUndoableState): ICommand[] {
    return state.buildPlanRedoStack
  },
}

function isAllowedViewModeType(selectedBuildPlanViewMode: string) {
  return !LIST_OF_DISABLED_COMMAND_VIEW_MODE_TYPES.find((mode) => mode === selectedBuildPlanViewMode)
}
