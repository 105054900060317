
/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Vector3 } from '@babylonjs/core/Maths'
import ComponentTreeItem from '@/components/layout/ComponentTreeItem.vue'
import Button from '@/components/controls/Common/Button.vue'
import { IBuildPlan, IPrintStrategyParameterSet } from '@/types/BuildPlans/IBuildPlan'
import { IJob, JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import { BoundingBox } from '@/visualization/models/DataModel'
import messageService from '@/services/messageService'
import StoresNamespaces from '@/store/namespaces'
import buildPlans from '@/api/buildPlans'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import NumberField from '@/components/controls/Common/NumberField.vue'
import { Watch, Prop } from 'vue-property-decorator'
import { PrintingTypes } from '@/types/IMachineConfig'
import {
  SLICE_SLIDER_DEFAULT_HEIGHT,
  SLICE_SLIDER_DEFAULT_MARGIN_BOTTOM,
  SLICE_MICRON_OFFSET_MULTIPLIER,
  SLICE_SLIDER_FLOATING_Z_HEIGHT,
} from '@/constants'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import _ from 'lodash'
import { isTabVisible } from '@/utils/common'
import { InsightsSeverity } from '@/types/Common/Insights'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { getDefaultBaseOnType, getSupportDefaultBasedOnType } from '@/utils/parameterSet/parameterSetUtils'
import { SupportTypes } from '@/types/BuildPlans/IBuildPlanItemSettings'
import { VersionablePk } from '@/types/Common/VersionablePk'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
@Component({
  name: 'BuildPlanSlider',
  components: { ComponentTreeItem, Button, NumberField },
})
export default class BuildPlanSlider extends Vue implements IToolComponent {
  @buildPlansStore.Getter('getBuildPlan') getBuildPlan: IBuildPlan
  @buildPlansStore.Getter insights: IBuildPlanInsight[]
  @buildPlansStore.Getter('getSelectedBuildPlanJobs') getSelectedBuildPlanJobs: IJob[]
  @buildPlansStore.Getter getBuildPlanPrintStrategy: BuildPlanPrintStrategyDto
  @buildPlansStore.Getter getPrintStrategyParameterSetByPk: (pk: VersionablePk) => IPrintStrategyParameterSet
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes

  @buildPlansStore.Action changeVisibilityByDisplayToolbarState: Function
  @buildPlansStore.Action fetchSliceLayers: (payload: { buildPlanId: string; printJobId?: string }) => Promise<number[]>

  @visualizationStore.Getter('isInitialized') isInitialized: boolean
  @visualizationStore.Getter('slicerMode') slicerMode: { isEnable: boolean; max: number; min: number; current: number }
  @visualizationStore.Getter('boundingBox') boundingBox: BoundingBox
  @visualizationStore.Getter('getSliderLayerNumber') getSliderLayerNumber: number

  @visualizationStore.Mutation('enableSlicerMode') enableSlicerMode: Function
  @visualizationStore.Mutation('changeCurrentSlicerValue') changeCurrentSlicerValue: Function
  @visualizationStore.Mutation('enableViewModeSelection') enableViewModeSelection: Function
  @visualizationStore.Mutation('hideMeshes') hideMeshes: Function
  @visualizationStore.Mutation('showMeshes') showMeshes: Function
  @visualizationStore.Mutation('showSlicePolylines') showSlicePolylines: Function
  @visualizationStore.Mutation('hideSlicePolylines') hideSlicePolylines: Function
  @visualizationStore.Mutation('setIsSliderActive') setIsSliderActive: Function
  @visualizationStore.Mutation('setSliderLayerNumber') setSliderLayerNumber: Function
  @visualizationStore.Mutation('setIsLoading') setIsLoading: Function

  @Prop() showPolylines: boolean = false
  @Prop() printJobId: string
  @Prop() disableSliceToolbarButton: Function

  isSlicerModeEnable: boolean = true
  currentSlicer: number = 0
  sliceJob: any = null
  sliceJobStatusComplete = JobStatusCode.COMPLETE
  checkingForSlice: boolean = false
  layerHeightInMillimetres: number = 0.05 // 50 microns
  layerErrors = new Map<number, Map<string, number[][]>>()
  totalErrorsLiteral = '__totalErrors'
  errorsLiteral = '__errors'
  warningsLiteral = '__warnings'
  sliderRules: any[] = [
    (layer) => {
      if (Object.prototype.hasOwnProperty.call(this.layerErrors, layer)) {
        return false
      }
      return true
    },
  ]
  sliceLayers = []
  isCompensatedRadioDisabled: boolean = true
  geometryJob: string = 'IMPORT'
  showProgressCircle: boolean = true
  intervalId = null
  okIntervalId = null
  unitsToMillimeterConversionMultiplier: number = 0.001
  sliceChangeThrottleId = null
  showZheight: boolean = false
  settingsPanelActive: boolean = false
  incrementStep: number = 1
  dataLoaded: boolean = false
  sliceChangeThrottleTimeout: number = 300
  sliceJobStatusTimeout: number = 3000
  metresToMmConversionMultiplier: number = 1000
  sliderErrorThumbMouseupTimeout: number = 100
  loadedSliceDataJobId: string = null
  isDataLoaded = false

  /**************************************
   * Generic tool method implementations
   **************************************/
  // need to mention these generic optional methods even if they are not implemented by the tool
  // due to TypeScript's weak type detection per https://stackoverflow.com/a/47930521
  clickCancel: () => void

  @Watch('showPolylines')
  async onPolylinesVisibilityChange() {
    if (!this.showPolylines) {
      this.enableSlicerMode(false)
      this.hideSlicePolylines()
      this.setIsSliderActive(false)
      this.cleanup()
    } else {
      this.enableSlicerMode(true)
      this.onSlicerChange(true)
      this.setIsSliderActive(true)
      this.createSliceErrors()
    }
  }

  @Watch('getSelectedBuildPlanJobs')
  async onBuildPlanItemsOrJobsChange() {
    const completedSliceJob = this.getSelectedBuildPlanJobs.find((job) => {
      return [JobType.SLICE].includes(job.jobType) && [JobStatusCode.COMPLETE].includes(job.code)
    })
    const completedMarkJob = this.getSelectedBuildPlanJobs.find((job) => {
      return [JobType.MARK].includes(job.jobType) && [JobStatusCode.COMPLETE].includes(job.code)
    })
    const completedJob = completedSliceJob ? (completedMarkJob ? completedMarkJob : completedSliceJob) : null
    if (
      this.getBuildPlan &&
      completedJob &&
      (!this.loadedSliceDataJobId || completedJob.id !== this.loadedSliceDataJobId)
    ) {
      this.currentSlicer = this.maxLayersCount
      this.loadedSliceDataJobId = completedJob.id
      await this.loadData()
    }
  }

  @Watch('isDataLoaded')
  @Watch('getSliderLayerNumber')
  async moveSliderPopup() {
    if (!this.isDataLoaded) return

    this.currentSlicer = this.maxLayersCount
    this.moveSlicerPopup()
  }

  @Watch('settingsPanelActive')
  async moveSlideronShowPopup(val) {
    if (val) {
      this.moveSlicerPopup()
    }
  }

  @Watch('showPolylines')
  async showMesh(val) {
    if (val) {
      if (this.isSlicerModeEnable) {
        this.showMeshes()
        this.changeVisibilityByDisplayToolbarState()
      } else {
        this.hideMeshes()
      }
    }
  }

  @Watch('isDataLoaded')
  onCanShow(val: boolean) {
    if (!val) return

    this.onSlicerChange(true)
  }

  async loadData() {
    this.isDataLoaded = false
    this.disableSliceToolbarButton(true)
    this.setIsLoading(true)
    try {
      this.enableViewModeSelection(false)
      await this.loadSliceData()
      await this.calculateLayerHeightInMillimetres()
      if (this.sliceJob) {
        this.sliceLayers = await this.getAllLayers()
      }
      this.currentSlicer = this.maxLayersCount
      this.showProgressCircle = false
    } finally {
      this.setIsLoading(false)
      if (this.getBuildPlanViewMode !== ViewModeTypes.Slicing && this.getBuildPlanViewMode !== null) {
        this.disableSliceToolbarButton(true)
      } else {
        this.disableSliceToolbarButton(false)
      }

      this.isDataLoaded = true
    }
  }

  destroyed() {
    this.cleanup()
  }

  cleanup() {
    clearInterval(this.intervalId)
    clearInterval(this.okIntervalId)
    if (this.sliceChangeThrottleId) {
      clearTimeout(this.sliceChangeThrottleId)
      this.sliceChangeThrottleId = null
    }
  }

  get maxLayersCount() {
    let layersOfBoundingBox: number = 0

    if (this.sliceLayers) {
      const maxSliceLayer = Math.max(...this.sliceLayers)
      layersOfBoundingBox =
        Math.round(maxSliceLayer / (SLICE_MICRON_OFFSET_MULTIPLIER * this.layerHeightInMillimetres)) + 1
    }

    return layersOfBoundingBox
  }

  get settingBtnClass() {
    if (this.settingsPanelActive) {
      return 'settings-btn-active'
    }
    return ''
  }

  get settingsPanelClass() {
    if (this.settingsPanelActive) {
      return 'slider-settings'
    }
    return 'slider-settings-hidden'
  }

  incrementSlider() {
    if (this.currentSlicer + this.incrementStep < this.maxLayersCount) {
      this.currentSlicer += this.incrementStep
    } else {
      this.currentSlicer = this.maxLayersCount
    }
    this.onSlicerChange(true)
  }

  decrementSlider() {
    if (this.currentSlicer - this.incrementStep > 1) {
      this.currentSlicer -= this.incrementStep
    } else {
      this.currentSlicer = 1
    }
    this.onSlicerChange(true)
  }

  incrementStepCounter() {
    if (this.maxLayersCount > 1000) {
      if (this.incrementStep < 1000) {
        this.incrementStep += 1
      } else {
        this.incrementStep = 1000
      }
      return
    }

    if (this.incrementStep < this.maxLayersCount - 1) {
      this.incrementStep += 1
    } else {
      if (this.maxLayersCount !== 1) {
        this.incrementStep = this.maxLayersCount - 1
      } else {
        this.incrementStep = 1
      }
    }
  }

  decrementStepCounter() {
    if (this.incrementStep > 1) {
      this.incrementStep -= 1
    } else {
      this.incrementStep = 1
    }
  }

  onIncrementStepChange() {
    if (this.maxLayersCount > 1000) {
      if (this.incrementStep > 1000) {
        this.incrementStep = 1000
      }
      return
    }
    if (this.incrementStep > this.maxLayersCount - 1) {
      this.incrementStep = this.maxLayersCount - 1
    }
    if (this.incrementStep < 1) {
      this.incrementStep = 1
    }
  }

  @Watch('insights')
  createSliceErrors() {
    if (this.boundingBox && this.boundingBox.maxZ) {
      this.layerErrors = new Map<number, Map<string, number[][]>>()
      const insights = _.cloneDeep(this.insights) // deep copy
      for (const insight of insights) {
        const severity = insight.severity
        if (insight.tool === ToolNames.SLICE && insight.details && insight.details.length > 0) {
          for (const insightDetail of insight.details) {
            const sliceLayerNumber = Math.abs(Math.round(insightDetail.layerHeight / this.layerHeightInMillimetres)) + 1
            const points = insightDetail.points
            const lines = insightDetail.lines
            if (this.layerErrors.has(sliceLayerNumber)) {
              if (this.layerErrors.get(sliceLayerNumber).has(severity)) {
                let ponitsAndLines = this.layerErrors.get(sliceLayerNumber).get(severity)
                ponitsAndLines = [...ponitsAndLines, ...lines, ...points]
                this.layerErrors.set(sliceLayerNumber, new Map().set(severity, ponitsAndLines))
              } else {
                this.layerErrors.set(sliceLayerNumber, new Map().set(severity, [...lines, ...points]))
              }
            } else {
              this.layerErrors.set(sliceLayerNumber, new Map().set(severity, [...lines, ...points]))
            }
          }
        }
      }

      this.clearAllErrorPointsOnSlider()
      for (const [sliceLayerNumer, sliceLayerDetail] of this.layerErrors) {
        this.createErrorPointOnSlider(sliceLayerNumer, sliceLayerDetail.keys().next().value === InsightsSeverity.Error)
      }
    }
  }

  async calculateLayerHeightInMillimetres() {
    const sliceHeights: number[] = []
    if (this.getBuildPlan && this.getBuildPlan.buildPlanItems) {
      for (const buildPlanItem of this.getBuildPlan.buildPlanItems) {
        for (const partProperty of buildPlanItem.partProperties) {
          let printStrategyParameterSetPk: VersionablePk
          if (partProperty.printStrategyParameterSetId) {
            printStrategyParameterSetPk = new VersionablePk(
              partProperty.printStrategyParameterSetId,
              partProperty.printStrategyParameterSetVersion,
            )
          } else {
            const defaults = this.getBuildPlanPrintStrategy.defaults
            printStrategyParameterSetPk = getDefaultBaseOnType(defaults, partProperty.type, partProperty.bodyType)
          }

          if (printStrategyParameterSetPk) {
            const printStrategyParameterSet = this.getPrintStrategyParameterSetByPk(printStrategyParameterSetPk)
            sliceHeights.push(printStrategyParameterSet.layerThickness)
          }
        }
        if (buildPlanItem.supports) {
          for (const support of buildPlanItem.supports) {
            const settings = support.settings
            let printStrategyParameterSetPk: VersionablePk
            if (settings.printStrategyParameterSetId) {
              printStrategyParameterSetPk = new VersionablePk(
                settings.printStrategyParameterSetId,
                settings.printStrategyParameterSetVersion,
              )
            } else {
              const defaults = this.getBuildPlanPrintStrategy.defaults
              printStrategyParameterSetPk = getSupportDefaultBasedOnType(defaults, settings.strategy as SupportTypes)
            }

            if (printStrategyParameterSetPk) {
              const printStrategyParameterSet = this.getPrintStrategyParameterSetByPk(printStrategyParameterSetPk)
              sliceHeights.push(printStrategyParameterSet.layerThickness)
            }
          }
        }
      }

      const sliceHeightInMetres = Math.min(...sliceHeights)
      this.layerHeightInMillimetres = sliceHeightInMetres * this.metresToMmConversionMultiplier
    }
  }

  createErrorPointOnSlider(errorLayerNumber: number, hasError: boolean) {
    const errorThumbContainer = document.createElement('div')
    errorThumbContainer.className += 'v-slider__thumb-container'
    const errorThumb = document.createElement('div')
    if (hasError) {
      errorThumb.className += 'v-slider__thumb error'
    } else {
      errorThumb.className += 'v-slider__thumb warning'
    }
    errorThumbContainer.appendChild(errorThumb)
    const totalLayersOnSlider = this.maxLayersCount
    const top = ((totalLayersOnSlider - errorLayerNumber) / totalLayersOnSlider) * 100 // percentage
    if (top >= 0) {
      errorThumbContainer.style.top = `${top}%`
      try {
        const trackContainer = document.getElementsByClassName('v-slider__track-container')[0]
        if (trackContainer) {
          const verticalSliderNode = trackContainer.parentNode
          if (verticalSliderNode) {
            verticalSliderNode.appendChild(errorThumbContainer)
            errorThumb.onmousedown = (e) => {
              e.stopImmediatePropagation()
              e.preventDefault()
            }
            errorThumb.onmouseup = (e) => {
              e.stopImmediatePropagation()
              e.preventDefault()
              setTimeout(() => {
                this.currentSlicer = errorLayerNumber
                this.onSlicerChange(true)
              }, this.sliderErrorThumbMouseupTimeout)
            }
          }
        }
      } catch (error) {
        const message = this.$i18n.t('unableToPlotErrorOnSlider') as string
        messageService.showErrorMessage(message)
      }
    }
  }

  clearAllErrorPointsOnSlider() {
    const containers: HTMLCollectionOf<Element> = document.getElementsByClassName('v-slider__thumb-container')
    for (const container of containers) {
      const role = container.getAttribute('role')
      if (!(role && role === 'slider')) {
        container.remove()
      }
    }
  }

  onSlicerModeChange() {
    if (this.isInitialized) {
      this.enableSlicerMode(this.isSlicerModeEnable)
      if (!this.isSlicerModeEnable) {
        this.hideMeshes()
      } else {
        this.showMeshes()
        this.changeVisibilityByDisplayToolbarState()
      }
      this.onSlicerChange(false)
    }
  }

  async loadSliceData() {
    this.checkingForSlice = true
    const jobs = this.getSelectedBuildPlanJobs
    jobs.sort((a, b) => b.number - a.number)
    if (jobs.length) {
      this.sliceJob = jobs.find((item) => item.jobType === JobType.SLICE)
      const compensateJobs = jobs.filter(
        (item) => item.jobType === JobType.COMPENSATE && item.code === JobStatusCode.COMPLETE,
      )
      if (compensateJobs && compensateJobs.length > 0) {
        this.isCompensatedRadioDisabled = false
      }
    }
    this.checkingForSlice = false
    if (
      this.sliceJob &&
      (this.sliceJob.code === JobStatusCode.COMPLETE || this.sliceJob.code === JobStatusCode.ERROR)
    ) {
      this.triggerStatusCheck()
    }
  }

  async getAllLayers() {
    return this.fetchSliceLayers({ buildPlanId: this.getBuildPlan.id, printJobId: this.printJobId })
  }

  @Watch('getSliderLayerNumber')
  onSliderLayerNumberChange(sliceLayerNumber: number) {
    if (this.currentSlicer !== sliceLayerNumber) {
      this.currentSlicer = sliceLayerNumber
      this.onSlicerChange(true)
    }
  }

  @Watch('showZheight')
  setFloatingSliderAttributes() {
    // calculate for floating div
    // plain js faster than vue js refs in this case
    const sliderThumbFloatPanelDiv: HTMLDivElement = document.getElementById('sliderThumbFloatPanel') as HTMLDivElement
    const totalLayersOnSlider = this.maxLayersCount
    let bottom =
      (1 - (totalLayersOnSlider - this.currentSlicer) / totalLayersOnSlider) * SLICE_SLIDER_DEFAULT_HEIGHT +
      SLICE_SLIDER_DEFAULT_MARGIN_BOTTOM
    if (!this.showZheight) {
      bottom += SLICE_SLIDER_FLOATING_Z_HEIGHT
    }
    if (sliderThumbFloatPanelDiv) {
      sliderThumbFloatPanelDiv.style.bottom = `${bottom}px`
    }
    this.moveSlicerPopup()
  }

  onSlicerChange(dragEnd) {
    if (this.currentSlicer > this.maxLayersCount || this.currentSlicer <= 0 || !this.isDataLoaded) {
      return
    }

    if (dragEnd) {
      this.hideSlicePolylines()
    }
    if (this.boundingBox && this.boundingBox.maxZ) {
      this.changeCurrentSlicerValue((this.currentSlicer - 1) * this.layerHeightInMillimetres)
      this.setSliderLayerNumber(this.currentSlicer)
      this.setFloatingSliderAttributes()
      if (dragEnd && !(this.getBuildPlan.modality === PrintingTypes.BinderJet)) {
        if (this.sliceChangeThrottleId) {
          clearTimeout(this.sliceChangeThrottleId)
          this.sliceChangeThrottleId = null
        }
        this.sliceChangeThrottleId = setTimeout(async () => {
          if (this.isInitialized) {
            const micronOffset = Math.floor(
              (this.currentSlicer - 1) * this.layerHeightInMillimetres * SLICE_MICRON_OFFSET_MULTIPLIER,
            )
            if (this.sliceJob && this.showPolylines && micronOffset >= 0) {
              try {
                const lastThrottleId = this.sliceChangeThrottleId
                const layers = await buildPlans.getBuildPlanSlice(this.getBuildPlan.id, micronOffset, this.printJobId)
                // check to make sure we are still processing the latest request
                if (lastThrottleId === this.sliceChangeThrottleId) {
                  let unitsToMillimeterConversion = this.unitsToMillimeterConversionMultiplier
                  for (const layer of layers) {
                    unitsToMillimeterConversion = layer.unitsToMillimeterConversion
                    const polylines = this.parseSliceLayer(layer.slice, layer.unitsToMillimeterConversion)
                    this.showSlicePolylines({ polylines })
                  }
                  if (this.layerErrors.has(this.currentSlicer)) {
                    if (this.layerErrors.get(this.currentSlicer).get(InsightsSeverity.Warning)) {
                      const warnPolylines = this.getPolylinesFromLines(
                        this.layerErrors.get(this.currentSlicer).get(InsightsSeverity.Warning),
                        unitsToMillimeterConversion,
                      )
                      this.showSlicePolylines({
                        polylines: warnPolylines,
                        type: 'WARNING',
                      })
                    }
                    if (this.layerErrors.get(this.currentSlicer).get(InsightsSeverity.Error)) {
                      const errPolylines = this.getPolylinesFromLines(
                        this.layerErrors.get(this.currentSlicer).get(InsightsSeverity.Error),
                        unitsToMillimeterConversion,
                      )
                      this.showSlicePolylines({
                        polylines: errPolylines,
                        type: 'ERROR',
                      })
                    }
                  }
                }
              } catch (error) {
                messageService.showErrorMessage(error)
              }
            }
          }
        }, this.sliceChangeThrottleTimeout)
      }
    }
  }

  async triggerStatusCheck() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }

    this.intervalId = setInterval(async () => {
      if (isTabVisible()) {
        this.sliceJob = await buildPlans.getSliceJobById(this.getBuildPlan.id, this.sliceJob.id)
        if (
          this.sliceJob &&
          (this.sliceJob.code === JobStatusCode.COMPLETE || this.sliceJob.code === JobStatusCode.ERROR)
        ) {
          clearInterval(this.intervalId)
          this.onSlicerChange(true)
        }
      }
    }, this.sliceJobStatusTimeout)
  }

  moveSlicerPopup() {
    const slider = document.getElementById('slider')

    if (slider && this.showZheight) {
      const percentage = (this.getSliderLayerNumber / this.maxLayersCount) * 100
      if (percentage < 15) {
        slider.style.setProperty('margin-top', '18%')
      } else {
        slider.style.removeProperty('margin-top')
      }
    }

    if (slider && !this.showZheight) {
      slider.style.removeProperty('margin-top')
    }
  }

  private parseSliceLayer(layerString: string, unitsToMillimtersConversion: number) {
    const LAYER = '$$LAYER/'
    const POLYLINE = '$$POLYLINE/'
    const lines = layerString.split('\n')
    let zHeight
    const polylines = []
    let polylineIndex = 0
    for (const line of lines) {
      if (line.startsWith(LAYER)) {
        zHeight = parseFloat(line.substring(LAYER.length))
      } else if (line.startsWith(POLYLINE)) {
        let polylineData = line.split(',')
        polylineData = polylineData.slice(3)
        polylines[polylineIndex] = []
        const z = parseFloat(((this.currentSlicer - 1) * this.layerHeightInMillimetres).toString())
        for (let index = 0; index < polylineData.length; index += 1) {
          const x = parseFloat(polylineData[index]) * unitsToMillimtersConversion
          index += 1
          const y = parseFloat(polylineData[index]) * unitsToMillimtersConversion
          polylines[polylineIndex].push(new Vector3(x, y, z))
        }
        polylineIndex += 1
      }
    }
    return polylines
  }

  private getPolylinesFromLines(lines: number[][], unitsToMillimtersConversion: number) {
    const polylines = []
    let polylineIndex = 0
    const z = (this.currentSlicer - 1) * this.layerHeightInMillimetres
    for (const line of lines) {
      polylines[polylineIndex] = []
      for (let index = 0; index < line.length; index += 1) {
        const x = line[index] * unitsToMillimtersConversion
        index += 1
        const y = line[index] * unitsToMillimtersConversion
        polylines[polylineIndex].push(new Vector3(x, y, z))
      }
      polylineIndex += 1
    }
    return polylines
  }
}
