import Vue from 'vue'
import { MutationTree } from 'vuex'
import { IPartsState } from './types'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { IGeometryProperties } from '@/types/BuildPlans/IBuildPlan'
import { Geometry, GeometryDefect, Part } from '@/visualization/models/DataModel'
import { InsightsSeverity } from '@/types/Common/Insights'
import { PartDefect, IPartInsight } from '@/types/Parts/IPartInsight'
import { partPreviewDefaultState } from '@/types/Parts/IPartPreview'
import { isNumber } from '@/utils/number'
import { MAX_GEOMETRY_INSIGHT_COUNT_PER_TYPE } from '@/constants'

export const mutations: MutationTree<IPartsState> = {
  updatePartComponents(state, part: IPartDto) {
    const index = state.parts.findIndex((p) => p.id === part.id)

    if (index >= 0) {
      Vue.set(state.parts[index], 'components', part.components)
    }
  },

  setParts(state, parts: IPartDto[]) {
    state.parts = parts
  },

  setSinterParts(state, sinterParts: IPartDto[]) {
    state.sinterParts = sinterParts
  },

  setSinterPart(state, sinterPart: IPartDto) {
    const index = state.sinterParts.findIndex((part) => part.id === sinterPart.id)
    if (index >= 0) {
      state.sinterParts.splice(index, 1, sinterPart)
    } else {
      state.sinterParts.push(sinterPart)
    }
  },

  updateSinterPartVisibility(state:IPartsState, sinterPart: IPartDto) {
    const part = state.sinterParts.find((sp) => sp.id === sinterPart.id)
    if (part) {
      part.visibility = sinterPart.visibility
    }
  },

  updateIbcPartVisibility(state: IPartsState, ibcPart: IPartDto) {
    const part = state.ibcParts.find((sp) => sp.id === ibcPart.id)
    if (part) {
      part.visibility = ibcPart.visibility
    }
  },

  addPart(state, part: IPartDto) {
    state.parts.push(part)
  },

  removePartById(state, id: string) {
    state.parts = state.parts.filter((p) => p.id !== id)
  },

  updatePart(state, updatedPart: IPartDto) {
    const index = state.parts.findIndex((part) => part.id === updatedPart.id)

    if (index !== -1) {
      state.parts.splice(index, 1, updatedPart)
    }
  },

  setPreviewGeometryProps(state, props: IGeometryProperties) {
    Vue.set(state.partPreview, 'geometryProperties', props)
  },

  clearPreviewInfo(state) {
    Vue.set(state, 'partPreview', { ...{}, ...partPreviewDefaultState })
  },

  configFile(state, parts: { parts: Part[] }) {
    Vue.set(state.partPreview, 'configFile', parts)
  },

  setInsights(state, parts: Part[]) {
    let partsInsights = []
    const insightsTypes = []
    for (const part of parts) {
      const geometries = part.geometries
      const geometriesInsights: IPartInsight[] = []
      geometries
        .filter((geometry: Geometry) => geometry.defects)
        .map((geometry: Geometry) => {
          const defectIndexByType: Map<PartDefect, number> = new Map<PartDefect, number>()
          Object.keys(geometry.defects).forEach((key) => {
            const geometryDefects = geometry.defects[key] as GeometryDefect[]
            for (const geometryDefect of geometryDefects) {
              if (!geometryDefect.type) {
                continue
              }

              const defectType = geometryDefect.type in PartDefect ? geometryDefect.type : PartDefect.Other
              let defectIndex = 0
              if (defectIndexByType.has(defectType)) {
                defectIndex = defectIndexByType.get(defectType)
                defectIndex += 1
              }
              defectIndexByType.set(defectType, defectIndex)

              if (defectIndex >= MAX_GEOMETRY_INSIGHT_COUNT_PER_TYPE) {
                continue
              }

              const defectSeverity =
                defectType === PartDefect.Disjoint || defectType === PartDefect.FaceDisjointMesh
                  ? InsightsSeverity.Warning
                  : InsightsSeverity.Error
              geometriesInsights.push({
                geometryId: geometry.id,
                severity: defectSeverity,
                tag: key,
                type: defectType,
                shapes: geometryDefect.shapes,
              })
              if (!insightsTypes.includes(defectType)) {
                insightsTypes.push(defectType)
              }
            }
          })
        })

      partsInsights = partsInsights.concat(geometriesInsights)
    }

    Vue.set(state.partPreview, 'insightTool', {
      insights: partsInsights,
      insightsCount: { errors: insightsTypes.length, warnings: 0 },
      activeInsightDefects: state.partPreview.insightTool.activeInsightDefects,
      activeInsightDefectGroup: state.partPreview.insightTool.activeInsightDefectGroup,
    })
  },

  addActiveInsightDefect(state, cId: number) {
    state.partPreview.insightTool.activeInsightDefects.push(cId)
  },

  removeActiveInsightDefect(state, cId: number) {
    const index = state.partPreview.insightTool.activeInsightDefects.findIndex((d) => d === cId)
    if (index !== -1) {
      state.partPreview.insightTool.activeInsightDefects.splice(index, 1)
    }
  },

  setActiveInsightDefectGroup(state, cId: number) {
    Vue.set(state.partPreview.insightTool, 'activeInsightDefectGroup', cId)
  },

  setSelectedDefect(state, payload: { defectType; defectIndex?; shapeIndex? }) {
    if (!payload) {
      Vue.set(state.partPreview.selectedDefect, 'type', null)
    } else {
      Vue.set(state.partPreview.selectedDefect, 'type', payload.defectType)
    }

    Vue.set(state.partPreview.selectedDefect, 'indices', [])
  },

  setHoveredDefect(state, payload: { defectType; defectIndex?; shapeIndex? }) {
    if (!payload) {
      Vue.set(state.partPreview.hoveredDefect, 'type', null)
      Vue.set(state.partPreview.hoveredDefect, 'indices', [])
      return
    }

    Vue.set(state.partPreview.hoveredDefect, 'type', payload.defectType)
    if (!isNumber(payload.defectIndex) && !isNumber(payload.shapeIndex)) {
      Vue.set(state.partPreview.hoveredDefect, 'indices', [])
    } else {
      Vue.set(state.partPreview.hoveredDefect, 'indices', [
        { defectIndex: payload.defectIndex, shapeIndex: payload.shapeIndex },
      ])
    }
  },

  addSelectedDefect(state, payload: { defectType; defectIndex?; shapeIndex? }) {
    if (!payload && !payload.defectType && !isNumber(payload.defectIndex)) {
      return
    }

    if (!state.partPreview.selectedDefect.type) {
      Vue.set(state.partPreview.selectedDefect, 'type', payload.defectType)
    }

    if (isNumber(payload.shapeIndex)) {
      const existingIndex = state.partPreview.selectedDefect.indices.findIndex(
        (ind) => ind.defectIndex === payload.defectIndex,
      )
      const existing = state.partPreview.selectedDefect.indices[existingIndex]
      if (existing && existing.shapeIndex !== payload.shapeIndex) {
        state.partPreview.selectedDefect.indices[existingIndex].shapeIndex = payload.shapeIndex
      }

      return
    }

    state.partPreview.selectedDefect.indices.push({ defectIndex: payload.defectIndex, shapeIndex: payload.shapeIndex })
  },

  removeSelectedDefect(state, payload: { defectType; defectIndex?; shapeIndex? }) {
    if (!payload && !payload.defectType && !isNumber(payload.defectIndex)) {
      return
    }

    let index: number
    if (!isNumber(payload.shapeIndex)) {
      index = state.partPreview.selectedDefect.indices.findIndex((ind) => ind.defectIndex === payload.defectIndex)
      if (index !== -1) {
        state.partPreview.selectedDefect.indices.splice(index, 1)
      }
    } else {
      index = state.partPreview.selectedDefect.indices.findIndex(
        (ind) => ind.defectIndex === payload.defectIndex && ind.shapeIndex === payload.shapeIndex,
      )
      if (index !== -1) {
        state.partPreview.selectedDefect.indices[index].shapeIndex = null
      }
    }

    if (!state.partPreview.selectedDefect.indices.length) {
      Vue.set(state.partPreview.selectedDefect, 'type', null)
    }
  },

  setPartIdsWithUsedDate(state, payload: Array<{ partId: string; usedDate: Date }>) {
    state.partIdsWithUsedDate = payload
  },

  setIbcParts(state, parts: IPartDto[]) {
    state.ibcParts = parts
  },
}
