import { ITEM_ID_PREFIX_DELIMITER } from '@/constants'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'
import { IBuildPlan, IBuildPlanLockDto, IVariantItem } from '../../types/BuildPlans/IBuildPlan'
import { FileExplorerItem } from '../../types/FileExplorer/FileExplorerItem'
import { ItemSubType, ItemType } from '../../types/FileExplorer/ItemType'
import { IUser } from '../../types/User/IUser'

export function getItemTypeName(item: FileExplorerItem) {
  if (item.subType) {
    switch (item.subType) {
      case ItemSubType.SinterPlan:
        return 'Sinter Plan'

      case ItemSubType.SinterPart:
        return 'Sinter Part'

      default:
        return '-'
    }
  }

  switch (item.itemType) {
    case ItemType.Folder:
      return 'Folder'

    case ItemType.BuildPlan:
      return 'Build Plan'

    case ItemType.BuildPart:
      return 'Part'

    // IBC should be treated as SBC in file explorer
    case ItemType.IbcPlan:
      return 'Sinter Plan'

    default:
      return '-'
  }
}

export function isItemLockedForUser(
  item: FileExplorerItem | IBuildPlan | IIBCPlan | IBuildPlanLockDto | IVariantItem,
  user: IUser,
) {
  return item.isLocked && item.lockWhitelist && !item.lockWhitelist.some((elem) => elem.userId === user.id)
}

export function transformPrintOrderIdToItemId(orderId: string): string {
  const idParts = orderId.split(ITEM_ID_PREFIX_DELIMITER)
  // {id: "p_{uuid}"}
  if (idParts.length === 2) {
    return idParts[1]
  }
  // {id: {uuid}}
  return idParts[0]
}

/**
 * This method returns default variant id (first variant) for all plans.
 * It has the same logic as server side
 * @param item item with path and version
 * @returns id of default version
 */
export function getDefaultVariantIdFromVersionAndPath(
  item: Pick<FileExplorerItem | IBuildPlan | IIBCPlan, 'path' | 'version'>,
): string {
  const depth = item.version.split('.').slice(1).length
  const pathIds = item.path.split('/').slice(1)

  return pathIds.reverse()[depth]
}
