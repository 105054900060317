
import Vue from 'vue'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import StoresNamespaces from '@/store/namespaces'
import ViewButton from '@/components/controls/Common/ViewButton.vue'
import ToggleButton from '@/components/controls/Common/ToggleButton.vue'

const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component({ components: { ViewButton, ToggleButton } })
export default class ViewModesPanel extends Vue {
  @visualizationStore.Getter isViewLocked: boolean

  @visualizationStore.Mutation changeView: Function
  @visualizationStore.Mutation setViewLocked: Function
  @visualizationStore.Mutation zoomToFitCamera: Function

  @Prop({ default: false }) isShowHideBlockShown: boolean
}
