/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Camera } from '@babylonjs/core/Cameras/camera'
import { Scene } from '@babylonjs/core/scene'
import { Epsilon, Matrix, Plane, Vector2, Vector3 } from '@babylonjs/core/Maths'
import { AbstractMesh, Mesh, TransformNode } from '@babylonjs/core/Meshes'
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo'
import { ISelectableNode, SelectionManager } from '@/visualization/rendering/SelectionManager'
import { IActiveToggle } from '@/visualization/infrastructure/IActiveToggle'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import { SelectionRectangle } from '@/visualization/components/SelectionRectangle'
import { GeometryType, SelectionUnit } from '@/types/BuildPlans/IBuildPlan'
import { SceneItemType } from '../types/SceneItemMetadata'
import { LINE_SUPPORT } from '@/constants'

export class SelectionBox implements IActiveToggle {
  private scene: Scene
  private camera: Camera
  private selectionManager: SelectionManager
  private meshManager: MeshManager
  private selectionMode: SelectionUnit

  private selectionRectangle: SelectionRectangle
  private startPosition: Vector2 = Vector2.Zero()
  private endPosition: Vector2 = Vector2.Zero()
  private frustum: Plane[]
  private isDown = false
  private attach: boolean = true
  private isActivate: boolean = true
  private clearSelected: boolean

  constructor(scene: Scene, camera: Camera, selectionManager: SelectionManager, meshManager: MeshManager) {
    this.selectionRectangle = new SelectionRectangle(scene)
    this.scene = scene
    this.camera = camera
    this.selectionManager = selectionManager
    this.meshManager = meshManager
  }

  deactivate() {
    this.isActivate = false
    this.selectionRectangle.hide()
  }

  activate() {
    this.isActivate = true
    if (this.isDown) {
      this.selectionRectangle.show(this.startPosition, this.endPosition)
    }
  }

  pointerDown(position: Vector2, attach: boolean) {
    if (!this.isActivate) {
      return
    }

    this.attach = attach
    this.isDown = true
    this.startPosition = position.clone()
    this.endPosition.x = position.x + Epsilon
    this.endPosition.y = position.y + Epsilon
    this.selectionRectangle.show(this.startPosition, this.endPosition)
  }

  pointerUp(clearSelected: boolean = false) {
    if (this.isDown && this.isActivate) {
      this.selectionRectangle.hide()
      this.computeFrustum()
    }

    this.isDown = false
    this.clearSelected = clearSelected
  }

  pointerMove(position: Vector2) {
    if (!this.isDown || !this.isActivate) {
      return
    }

    if (this.startPosition.equalsWithEpsilon(position)) {
      this.endPosition.x = position.x + Epsilon
      this.endPosition.y = position.y + Epsilon
    } else {
      this.endPosition.x = position.x
      this.endPosition.y = position.y
    }

    this.computeFrustum()
    this.selectionRectangle.update(this.endPosition)
  }

  highlightItemsInFrustum(options: { allowSupports: boolean }, filters?: { bodyTypes?: GeometryType[] }) {
    switch (this.selectionMode) {
      case SelectionUnit.Part:
        this.highlightPartsInFrustum()
        break
      case SelectionUnit.Body:
        this.highlightBodiesInFrustum(options, filters)
        break
    }
  }

  selectItemsInFrustum(options: { allowSupports: boolean }, filters?: { bodyTypes?: GeometryType[] }) {
    switch (this.selectionMode) {
      case SelectionUnit.Part:
        this.selectPartsInFrustum()
        break
      case SelectionUnit.Body:
        this.selectBodiesInFrustum(options, filters)
        break
    }
  }

  setSelectionMode(mode: SelectionUnit) {
    this.selectionMode = mode
  }

  private highlightPartsInFrustum() {
    if (!this.frustum) return

    const parts = this.getPartsFromScene()

    for (const part of parts) {
      const partIsTotalHidden = part
        .getChildMeshes()
        .filter((mesh) => this.meshManager.isComponentMesh(mesh) || mesh.name === LINE_SUPPORT)
        .every((mesh) => !mesh.isVisible && !mesh.metadata.showAsTransparent)

      if (partIsTotalHidden) continue

      const bboxInfo = part.metadata.hullBInfo
      const supportMesh = part.getChildTransformNodes(true).find((m) => this.meshManager.isSupportMesh(m))
      if (supportMesh) {
        supportMesh.metadata.hullBInfo = this.meshManager.getHullBInfo(supportMesh)
      }

      if (this.isBboxInfoInFrustum(bboxInfo, this.frustum) && this.isPartInFrustum(part, this.frustum)) {
        const partIsPartiallyVisible = part
          .getChildMeshes()
          .filter((mesh) => this.meshManager.isComponentMesh(mesh) || mesh.name === LINE_SUPPORT)
          .some((mesh) => mesh.metadata && (!mesh.metadata.isHidden || mesh.metadata.showAsTransparent))
        if (partIsPartiallyVisible) {
          this.selectionManager.highlight([{ part }], true)
        }
      } else if (supportMesh && this.isBboxInfoInFrustum(supportMesh.metadata.hullBInfo, this.frustum)) {
        this.selectionManager.highlight([{ part }], true)
      } else {
        this.selectionManager.highlight([{ part }], false)
      }
    }
  }

  private selectPartsInFrustum() {
    if (!this.frustum) return

    const parts = this.getPartsFromScene()
    const partsToSelect: ISelectableNode[] = []
    const partsToDeselect: ISelectableNode[] = []
    for (const part of parts) {
      const partIsTotalHidden = part
        .getChildMeshes()
        .filter((mesh) => this.meshManager.isComponentMesh(mesh) || mesh.name === LINE_SUPPORT)
        .every((mesh) => !mesh.isVisible && !mesh.metadata.showAsTransparent)
      if (partIsTotalHidden) continue

      const bboxInfo = part.metadata.hullBInfo
      const supportMesh = part.getChildTransformNodes(true).find((m) => this.meshManager.isSupportMesh(m))
      if (supportMesh) {
        supportMesh.metadata.hullBInfo = this.meshManager.getHullBInfo(supportMesh)
      }

      if (this.isBboxInfoInFrustum(bboxInfo, this.frustum) && this.isPartInFrustum(part, this.frustum)) {
        const partIsPartiallyVisible = part
          .getChildMeshes()
          .filter((mesh) => this.meshManager.isComponentMesh(mesh) || mesh.name === LINE_SUPPORT)
          .some((mesh) => mesh.metadata && (!mesh.metadata.isHidden || mesh.metadata.showAsTransparent))
        if (partIsPartiallyVisible) {
          if (this.attach) {
            partsToSelect.push({ part })
          } else {
            partsToDeselect.push({ part })
          }
        }
      } else if (supportMesh && this.isBboxInfoInFrustum(supportMesh.metadata.hullBInfo, this.frustum)) {
        if (this.attach) {
          partsToSelect.push({ part })
        } else {
          partsToDeselect.push({ part })
        }
      }
    }

    if (partsToSelect.length) {
      this.selectionManager.select(partsToSelect, true)
    }

    if (partsToDeselect.length) {
      this.selectionManager.deselect(partsToDeselect)
    }

    this.selectionManager.showGizmos()
  }

  private highlightBodiesInFrustum(
    options: { allowSupports: boolean },
    filters?: { bodyTypes?: GeometryType[]; onlyBars?: boolean },
  ) {
    if (!this.frustum) return

    const bodies = this.getBodiesFromScene()
    for (const body of bodies) {
      const bboxInfo = body.getBoundingInfo()

      if (
        (!filters ||
          (filters.bodyTypes &&
            filters.bodyTypes.includes(body.metadata.bodyType) &&
            (!filters.onlyBars || (filters.onlyBars && body.metadata.isBar)))) &&
        (!options ||
          (!options.allowSupports &&
            body.metadata.itemType !== SceneItemType.Support &&
            (!body.metadata.isHidden || body.metadata.showAsTransparent))) &&
        body.isVisible
      ) {
        if (this.isBboxInfoInFrustum(bboxInfo, this.frustum)) {
          this.selectionManager.highlight([{ body }], true)
        } else {
          this.selectionManager.highlight([{ body }], false)
        }
      }
    }
  }

  private selectBodiesInFrustum(
    options: { allowSupports: boolean },
    filters?: { bodyTypes?: GeometryType[]; onlyBars?: boolean },
  ) {
    if (!this.frustum) return

    const bodies = this.getBodiesFromScene()
    const itemsToSelect: ISelectableNode[] = []
    const itemsToDeselect: ISelectableNode[] = []
    const selected = this.selectionManager.getSelected()
    for (const body of bodies) {
      const bboxInfo = body.getBoundingInfo()

      if (
        (!filters ||
          (filters.bodyTypes &&
            filters.bodyTypes.includes(body.metadata.bodyType) &&
            (!filters.onlyBars || (filters.onlyBars && body.metadata.isBar)))) &&
        (!options || (!options.allowSupports && body.metadata.itemType !== SceneItemType.Support)) &&
        (!body.metadata.isHidden || body.metadata.showAsTransparent) &&
        body.isVisible
      ) {
        if (this.isBboxInfoInFrustum(bboxInfo, this.frustum)) {
          const selectableNode = { body }
          if (this.attach) {
            itemsToSelect.push(selectableNode)
          } else {
            itemsToDeselect.push(selectableNode)
          }
        }
      }
    }

    if (itemsToSelect.length && this.clearSelected) {
      for (const body of selected) {
        const bboxInfo = body.getBoundingInfo()
        if (!this.isBboxInfoInFrustum(bboxInfo, this.frustum)) {
          itemsToDeselect.push({ body })
        }
      }
    }

    if (itemsToSelect.length) {
      this.selectionManager.select(itemsToSelect, true)
    }
    if (itemsToDeselect.length) {
      this.selectionManager.deselect(itemsToDeselect)
    }
  }

  private getPartsFromScene(): TransformNode[] {
    const bpItems: TransformNode[] = Array.from(this.scene.metadata.buildPlanItems.values())
    return bpItems.filter((mesh) => {
      if (this.attach) {
        return !this.selectionManager.isSelected({ part: mesh })
      }

      return this.selectionManager.isSelected({ part: mesh })
    })
  }

  /**
   * @returns {AbstractMesh[]} List of selected bodies for deselect operation or unselected bodies for select
   */
  private getBodiesFromScene(): AbstractMesh[] {
    const bodies: AbstractMesh[] = []
    const bpItems: TransformNode[] = Array.from(this.scene.metadata.buildPlanItems.values())
    bpItems.forEach((mesh) => {
      const children = mesh.getChildMeshes().filter((child) => this.meshManager.isComponentMesh(child))
      for (const body of children) {
        if (this.attach) {
          if (!this.selectionManager.isSelected({ body })) {
            bodies.push(body)
          }
        } else {
          if (this.selectionManager.isSelected({ body })) {
            bodies.push(body)
          }
        }
      }
    })

    return bodies
  }

  private isPartInFrustum(part: TransformNode, frustum: Plane[]) {
    const tempVector = Vector3.Zero()
    const m = part.getWorldMatrix()
    for (const hullPoint of part.metadata.hull.hullPoints) {
      tempVector.copyFromFloats(hullPoint.x, hullPoint.y, hullPoint.z)
      Vector3.TransformCoordinatesToRef(tempVector, m, tempVector)
      if (this.isVectorInFrustum(tempVector, frustum)) {
        return true
      }
    }

    return false
  }

  private unproject(point: Vector3) {
    const width = this.camera.getEngine().getRenderingCanvas().width
    const height = this.camera.getEngine().getRenderingCanvas().height
    const viewMatrix = this.camera.getViewMatrix()
    const projMatrix = this.camera.getProjectionMatrix()

    return Vector3.Unproject(point, width, height, Matrix.Identity(), viewMatrix, projMatrix)
  }

  private computeFrustum() {
    const minX = Math.min(this.startPosition.x, this.endPosition.x)
    const minY = Math.max(this.startPosition.y, this.endPosition.y)
    const maxX = Math.max(this.startPosition.x, this.endPosition.x)
    const maxY = Math.min(this.startPosition.y, this.endPosition.y)

    const rightTopNear = this.unproject(new Vector3(maxX, maxY, -1))
    const rightBotNear = this.unproject(new Vector3(maxX, minY, -1))
    const leftBotNear = this.unproject(new Vector3(minX, minY, -1))
    const leftTopNear = this.unproject(new Vector3(minX, maxY, -1))

    const rightTopFar = this.unproject(new Vector3(maxX, maxY, 1))
    const rightBotFar = this.unproject(new Vector3(maxX, minY, 1))
    const leftBotFar = this.unproject(new Vector3(minX, minY, 1))
    const leftTopFar = this.unproject(new Vector3(minX, maxY, 1))

    const rightPlane = Plane.FromPoints(rightBotNear, rightTopNear, rightBotFar)
    const botPlane = Plane.FromPoints(leftBotNear, rightBotNear, rightBotFar)
    const leftPlane = Plane.FromPoints(leftTopNear, leftBotNear, leftTopFar)
    const topPlane = Plane.FromPoints(rightTopNear, leftTopNear, rightTopFar)
    const nearPlane = Plane.FromPoints(leftBotNear, leftTopNear, rightBotNear)
    const farPlane = Plane.FromPoints(rightBotFar, rightTopFar, leftBotFar)

    this.frustum = [rightPlane, botPlane, leftPlane, topPlane, nearPlane, farPlane]
  }

  private isBboxInfoInFrustum(bboxInfo: BoundingInfo, frustum: Plane[]) {
    if (this.startPosition.x - this.endPosition.x > Epsilon) {
      return bboxInfo.isInFrustum(frustum)
    }

    return bboxInfo.isCompletelyInFrustum(frustum)
  }

  private isVectorInFrustum(vector: Vector3, frustum: Plane[]) {
    for (let i = 0; i < 6; i += 1) {
      if (frustum[i].dotCoordinate(vector) < 0) {
        return false
      }
    }

    return true
  }
}
