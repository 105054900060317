/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import {
  HIGHLIGHT_INSTANCE_ID,
  OVERHANG_ANGLE,
  OVERHANG_AREAS,
  OVERHANG_MAX_ANGLE_VALUE,
  RECOATER_DIRECTION,
} from '@/constants'
import { Material } from '@babylonjs/core/Materials/material'
import { ShaderMaterial } from '@babylonjs/core/Materials/shaderMaterial'
import { Vector3 } from '@babylonjs/core/Maths'
import { AbstractMesh, InstancedMesh } from '@babylonjs/core/Meshes'
import { RenderScene } from '../render-scene'
import { OverhangAreasShader, RecoaterDirectionShader } from '../rendering/MeshShader'

export interface IVisualizationMode {
  readonly visualizationModeMaterial: Material
  render(): void
}

abstract class VisualizationMode implements IVisualizationMode {
  protected material: Material
  protected renderScene: RenderScene

  constructor(renderScene: RenderScene) {
    this.renderScene = renderScene
  }

  get visualizationModeMaterial() {
    return this.material
  }

  render() {
    this.createOrUpdateMaterial()
    const selectedMeshes: AbstractMesh[] = this.renderScene.getSelectionManager().getSelected()
    for (const selectedMesh of selectedMeshes) {
      const bpItemMesh = this.renderScene
        .getMeshManager()
        .getBuildPlanItemMeshById(selectedMesh.metadata.buildPlanItemId)
      const componentMeshes: InstancedMesh[] = bpItemMesh
        .getChildMeshes()
        .filter((mesh) => this.renderScene.getMeshManager().isComponentMesh(mesh)) as InstancedMesh[]
      if (Array.isArray(componentMeshes)) {
        for (const componentMesh of componentMeshes) {
          componentMesh.sourceMesh.material = componentMesh.sourceMesh.metadata.originalMaterial = this.material
          if ([OVERHANG_AREAS, RECOATER_DIRECTION].includes(this.material.name)) {
            const index = componentMesh.sourceMesh.instances.indexOf(componentMesh)
            ;(this.material as ShaderMaterial).setInt(HIGHLIGHT_INSTANCE_ID, index)
          }
        }
      }
    }
  }

  removeMaterial() {
    if (this.material) {
      this.material.dispose()
    }
  }

  protected abstract createOrUpdateMaterial(): void
}

export class DefaultVisualizationMode extends VisualizationMode {
  constructor(renderScene: RenderScene) {
    super(renderScene)
  }

  protected createOrUpdateMaterial(): void {
    this.removeMaterial()
    this.material = this.renderScene.getDefaultMaterial().clone('defaultMode')
  }
}

export class OverhangAreasVisualizationMode extends VisualizationMode {
  private overhangAngle: number

  constructor(renderScene: RenderScene) {
    super(renderScene)
    this.overhangAngle = OVERHANG_MAX_ANGLE_VALUE
  }

  setOverhangAngle(angle: number) {
    this.overhangAngle = angle
    const shaderMaterial = this.material as ShaderMaterial
    if (shaderMaterial) {
      shaderMaterial.setFloat(OVERHANG_ANGLE, angle)
    }
  }

  protected createOrUpdateMaterial() {
    const allCollectors = this.renderScene.getCollectorManager().allCollectors
    allCollectors.forEach((collector) => {
      collector.disableColoring()
    })
    this.removeMaterial()
    // TODO: get rid of creating object, because we don't use it anywhere except of getting internal shader material
    this.material = new OverhangAreasShader(this.renderScene, this.overhangAngle).getMaterial()
  }
}

export class RecoaterDirectionVisualizationMode extends VisualizationMode {
  private recoaterDirection: Vector3

  constructor(renderScene: RenderScene) {
    super(renderScene)
    this.recoaterDirection = renderScene.getVisuzalizationModeManager().getRecoaterDirection()
  }

  setRecoaterDirection(direction: Vector3) {
    this.recoaterDirection = direction
    const shaderMaterial = this.material as ShaderMaterial
    shaderMaterial.setVector3(RECOATER_DIRECTION, direction)
  }

  protected createOrUpdateMaterial() {
    this.removeMaterial()
    // TODO: get rid of creating object, because we don't use it anywhere except of getting internal shader material
    this.material = new RecoaterDirectionShader(this.renderScene, this.recoaterDirection).getMaterial()
  }
}
