/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Engine } from '@babylonjs/core/Engines/engine'
import { Scene } from '@babylonjs/core/scene'
import { Color4, Viewport, Vector3, Color3 } from '@babylonjs/core/Maths'
import { PointLight } from '@babylonjs/core/Lights/pointLight'
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight'
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { Mesh, MeshBuilder } from '@babylonjs/core/Meshes'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import { ModelManager } from '@/visualization/rendering/ModelManager'
import { IRenderable } from '@/visualization/types/IRenderable'
import { OrthoCamera } from '@/visualization/components/OrthoCamera'
import { OBBTree } from '@/visualization/OBBTree'
import { BuildPlateManager } from '@/visualization/rendering/BuildPlateManager'
import { SelectionManager } from '@/visualization/rendering/SelectionManager'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import {
  BUILD_PLATE_GRID_RATIO,
  BUILD_PLATE_MATERIAL_NAME,
  MOCK_PART_MESH_NAME,
  SINTER_PLATE_PADDING,
} from '@/constants'
import { GridMaterial } from '@babylonjs/materials/grid/gridMaterial'
import { GpuPicker } from '@/visualization/components/GpuPicker'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { IViewMode } from '@/visualization/infrastructure/ViewMode'
import { InsightsManager } from '@/visualization/rendering/InsightsManager'
import { SceneMode } from '@/visualization/types/SceneTypes'
import { ISceneMetadata } from '@/visualization/types/SceneMetadata'
import { IPartRenderable } from '@/types/Parts/IPartRenderable'

export class RenderDetailsPreview implements IRenderable {
  private canvas: HTMLCanvasElement
  private engine: Engine
  private scene: Scene
  private sceneMode: SceneMode
  private meshManager: MeshManager
  private modelManager: ModelManager
  private camera: OrthoCamera
  private ambientLight: HemisphericLight
  private cameraLight: PointLight
  private obbTree: OBBTree
  private buildPlateManager: BuildPlateManager
  private selectionManager: SelectionManager
  private insightManager: InsightsManager
  private gpuPicker: GpuPicker
  private ground: Mesh

  private isDisposed: boolean = false
  private notifyInitialized: Function
  private waitModuleInitialized = (() => {
    const initPromise = new Promise((resolve) => {
      this.notifyInitialized = resolve
    })
    return () => initPromise
  })()
  private shouldTerminatePartLoading: boolean = false

  constructor(canvasElement: string, sceneMode: SceneMode = SceneMode.PreviewDetails) {
    // Create canvas and engine
    this.canvas = document.getElementById(canvasElement) as HTMLCanvasElement
    this.engine = new Engine(this.canvas, true, { stencil: true, preserveDrawingBuffer: false })
    this.sceneMode = sceneMode
  }

  async createScene() {
    this.scene = new Scene(this.engine)
    this.scene.useRightHandedSystem = true
    this.scene.clearColor = new Color4(1, 1, 1, 1)
    this.scene.autoClear = true
    this.scene.autoClearDepthAndStencil = false
    this.scene.blockMaterialDirtyMechanism = true
    this.scene.metadata = {
      componentPickingColor: new Map<string, Color3>(),
    }

    this.meshManager = new MeshManager(this)
    this.obbTree = new OBBTree(this)
    this.insightManager = new InsightsManager(this)
    // load model from json file
    this.modelManager = new ModelManager(this, this.canvas, null)

    this.createMaterialList()
    this.setMaterial('default')

    this.buildPlateManager = new BuildPlateManager(this)

    this.camera = new OrthoCamera('camera1', new Viewport(0, 0, 1, 1), this.scene, this.canvas, this)
    this.modelManager.init()

    this.cameraLight = new PointLight('camera1Light', new Vector3(0, 0, 1), this.scene)
    this.scene.onBeforeRenderObservable.add(() => {
      this.cameraLight.position = this.camera.position
    })

    // create a basic ambient light
    this.ambientLight = new HemisphericLight('light1', new Vector3(0, 0, 1000), this.scene)

    // the canvas/window resize event handler
    window.onresize = () => {
      this.engine.resize()
      const rect = this.engine.getRenderingCanvasClientRect()
      if (rect) {
        const aspect = rect.height / rect.width
        this.camera.orthoTop = this.camera.orthoRight * aspect
        this.camera.orthoBottom = this.camera.orthoLeft * aspect
      }
    }
    this.notifyInitialized()
  }

  async dispose() {
    if (this.isDisposed) {
      return
    }

    this.isDisposed = true

    if (this.modelManager) {
      await this.modelManager.dispose()
      this.modelManager = null
    }

    if (this.selectionManager) {
      this.selectionManager.dispose()
      this.selectionManager = null
    }

    if (this.camera) {
      this.camera.dispose()
    }

    if (this.cameraLight) {
      this.cameraLight.dispose()
    }

    if (this.ambientLight) {
      this.ambientLight.dispose()
    }

    if (this.canvas && this.scene) {
      // scene.dispose() call doesn't free up geometries
      while (this.scene.geometries.length) {
        const geometry = this.scene.geometries[0]
        geometry.dispose()
      }

      // It's important to set scene metadata to null, otherwise memory isn't freed up on dispose
      this.scene.metadata = null
      this.scene.dispose()
    }

    if (this.gpuPicker) {
      this.gpuPicker.dispose()
    }

    this.engine.stopRenderLoop()
    if (this.canvas && this.engine) {
      this.engine.dispose()
    }
  }

  async loadBuildPlan(buildPlan: IBuildPlan) {
    await this.waitModuleInitialized()
    this.scene.unfreezeActiveMeshes()
    await this.modelManager.loadBuildPlan(buildPlan)
    this.scene.freezeActiveMeshes()
    this.addGroundPlane()
    this.camera.repositionCamera(this.scene, true)
  }

  async loadPartConfig(part: IPartRenderable) {
    await this.waitModuleInitialized()

    if (part.allowLoadPartConfigTermination && this.shouldTerminatePartLoading) {
      this.shouldTerminatePartLoading = false
      return
    }

    await this.modelManager.loadPartConfig(part)
    if (!this.isDisposed) {
      this.addGroundPlane()
      this.camera.repositionCamera(this.scene, true)
    }
  }

  terminatePartLoading() {
    this.shouldTerminatePartLoading = true
    this.notifyInitialized()
  }

  addGroundPlane() {
    if (this.scene.meshes.length === 1 && this.scene.meshes[0].name === MOCK_PART_MESH_NAME) {
      return
    }

    this.scene.transformNodes.forEach((node) => node.computeWorldMatrix(true))

    const boundingInfo = this.meshManager.getTotalBoundingInfo(this.scene.meshes, true, true)
    const boundingBox = boundingInfo.boundingBox
    const diagonalDistance = Vector3.Distance(
      new Vector3(boundingBox.maximumWorld.x, boundingBox.maximumWorld.y, 0),
      new Vector3(boundingBox.minimumWorld.x, boundingBox.minimumWorld.y, 0),
    )
    const numberOfCells = Math.floor((diagonalDistance + SINTER_PLATE_PADDING) / BUILD_PLATE_GRID_RATIO)
    const evenNumberOfCells = numberOfCells % 2 ? numberOfCells + 1 : numberOfCells
    const size = BUILD_PLATE_GRID_RATIO * evenNumberOfCells

    const groundPosition = new Vector3(
      (boundingBox.maximumWorld.x + boundingBox.minimumWorld.x) / 2,
      (boundingBox.maximumWorld.y + boundingBox.minimumWorld.y) / 2,
      boundingBox.minimumWorld.z,
    )

    this.ground = MeshBuilder.CreateGround('ground', { width: size, height: size }, this.scene)
    this.ground.position = groundPosition
    this.ground.rotation = new Vector3(Math.PI / 2, 0, 0)
    this.ground.isPickable = false

    this.ground.material = this.scene.getMaterialByID(BUILD_PLATE_MATERIAL_NAME)
  }

  clearScene() {
    const meshes = [...this.scene.meshes]
    meshes.forEach((mesh) => mesh.dispose())

    this.scene.render()
  }

  getScene() {
    return this.scene
  }

  getSceneMode() {
    return this.sceneMode
  }

  getSceneMetadata() {
    return this.scene.metadata as ISceneMetadata
  }

  getMeshManager() {
    return this.meshManager
  }

  getActiveCamera() {
    return this.camera
  }

  getAmbientLight() {
    return this.ambientLight
  }

  getPointLight() {
    return this.cameraLight
  }

  getObbTree() {
    return this.obbTree
  }

  getBuildPlateManager() {
    return this.buildPlateManager
  }

  getSelectionManager() {
    return this.selectionManager
  }

  getGpuPicker() {
    return this.gpuPicker
  }

  getVisuzalizationModeManager() {
    return null
  }

  getViewModes() {
    return new Map<ViewModeTypes, IViewMode>()
  }

  getInsightsManager() {
    return this.insightManager
  }

  getModelManager() {
    return this.modelManager
  }

  setMaterial(materialName: string) {
    const material = this.scene.getMaterialByID(materialName)

    if (material) {
      this.scene.defaultMaterial = material
      const meshInsideMaterial = this.scene.getMaterialByID('meshInsideMaterial')
      const meshesInside = this.scene.meshes.filter((mesh) => mesh.name.includes('meshInside'))
      meshesInside.forEach((mesh) => {
        mesh.material = meshInsideMaterial
      })

      meshesInside
        .map((mesh) => mesh.parent)
        .forEach((mesh) => {
          this.scene.getMeshByID(mesh.id).material = material
        })
    }
  }

  /**
   * Checks wheather engine capabilities are available depending on existing engine and scene
   */
  public hasCapabilities(): boolean {
    const engine = this.scene && this.scene.getEngine()
    const capabilities = engine && engine.getCaps()
    return !!capabilities
  }

  public isCanvasExists(): boolean {
    return !!this.canvas
  }

  private createMaterialList(): void {
    const meshOutsideMaterial = new StandardMaterial('default', this.scene)
    meshOutsideMaterial.diffuseColor = new Color3(0.3, 0.3, 0.3)
    meshOutsideMaterial.specularColor = new Color3(0.7, 0.7, 0.7)
    meshOutsideMaterial.sideOrientation = Mesh.BACKSIDE
    meshOutsideMaterial.backFaceCulling = true
    meshOutsideMaterial.freeze()

    const meshInsideMaterial = new StandardMaterial('meshInsideMaterial', this.scene)
    meshInsideMaterial.diffuseColor = new Color3(0.3, 0.3, 0.3)
    meshInsideMaterial.specularColor = new Color3(0.7, 0.7, 0.7)
    meshInsideMaterial.sideOrientation = Mesh.FRONTSIDE
    meshInsideMaterial.backFaceCulling = true
    meshInsideMaterial.freeze()

    const buildPlateMaterial = new GridMaterial(BUILD_PLATE_MATERIAL_NAME, this.scene)
    buildPlateMaterial.mainColor = Color3.White()
    buildPlateMaterial.lineColor = new Color3(0.6, 0.6, 0.6)
    buildPlateMaterial.gridRatio = BUILD_PLATE_GRID_RATIO
    buildPlateMaterial.freeze()
  }
}
