/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { PointerEventTypes, PointerInfo, PointerInfoPre } from '@babylonjs/core/Events/pointerEvents'
import { Observer } from '@babylonjs/core/Misc/observable'
import { Scene } from '@babylonjs/core/scene'
import { Vector2 } from '@babylonjs/core/Maths'
import { AbstractMesh } from '@babylonjs/core/Meshes'

import { RenderScene } from '@/visualization/render-scene'
import { MouseButtons, PointerType, THRESHOLD_RATIO } from '@/constants'
import { GeometryType } from '@/types/BuildPlans/IBuildPlan'
import { IComponentMetadata, IPartMetadata, SceneItemType } from '@/visualization/types/SceneItemMetadata'
import { ArcRotateCameraPointersInput } from '@babylonjs/core/Cameras'
import store from '@/store'
import { ClearanceModes, ClearanceTypes } from '@/visualization/types/ClearanceTypes'
import { ClearanceMeshToMesh } from '@/visualization/rendering/clearance/ClearanceMeshToMesh'
import { DuplicateViewMode } from '@/visualization/infrastructure/ViewMode'

export class InputController {
  private renderScene: RenderScene
  private scene: Scene
  private repositonCameraObserver: Observer<PointerInfo>
  private howerObjectsObserver: Observer<PointerInfo>
  private selectObjectsObserver: Observer<PointerInfo>
  private leftPointerEventForCamera: Observer<PointerInfo>
  private selectionBoxObserver: Observer<PointerInfo>
  private gpuPickerSceneBeforeRenderObservable: Observer<Scene>
  private gpuPickerSceneAfterRenderObserver: Observer<Scene>
  private cameraChangedPositionObserver: Observer<PointerInfo>
  private collectorHoverObjectsObserver: Observer<PointerInfo>
  private collectorSelectObjectsObserver: Observer<PointerInfo>
  private cameraZoomWithTouchObserver: Observer<PointerInfo>
  private cameraTouchPrepare: Observer<PointerInfoPre>
  private clearanceRubberBandObserver: Observer<PointerInfo>

  constructor(renderScene: RenderScene) {
    this.renderScene = renderScene
    this.scene = this.renderScene.getScene()

    // remove default raycasting for picking meshes
    this.scene.pointerDownPredicate = (mesh: AbstractMesh): boolean => {
      return false
    }
    this.scene.pointerUpPredicate = (mesh: AbstractMesh): boolean => {
      return false
    }
    this.scene.pointerMovePredicate = (mesh: AbstractMesh): boolean => {
      return false
    }
    this.scene.constantlyUpdateMeshUnderPointer = false
  }

  addRepositionCameraObserver() {
    this.repositonCameraObserver = this.scene.onPointerObservable.add((evt) => {
      if (evt.event.button === MouseButtons.RightButton && evt.type === PointerEventTypes.POINTERDOUBLETAP) {
        this.renderScene.zoomToFitCamera()
      }
    })
  }

  removeRepositionCameraObserver() {
    this.scene.onPointerObservable.remove(this.repositonCameraObserver)
  }

  addHoverObjectsObserver(
    options?: { allowWithButtonPressed?: boolean; allowSupports?: boolean },
    filters?: { bodyTypes?: GeometryType[]; onlyBars?: boolean },
  ) {
    const meshManager = this.renderScene.getMeshManager()
    this.howerObjectsObserver = this.scene.onPointerObservable.add((evt) => {
      if (this.renderScene.getCollectorManager().getActiveCollector) {
        return
      }

      if (
        ((!options || !options.allowWithButtonPressed) &&
          evt.event.buttons === 0 &&
          evt.type !== PointerEventTypes.POINTERUP) ||
        (options && options.allowWithButtonPressed)
      ) {
        const canvasRect = this.renderScene.getActiveCamera().getEngine().getRenderingCanvasClientRect()
        let pickedObject = this.renderScene
          .getGpuPicker()
          .pick(evt.event.clientX - canvasRect.left, evt.event.clientY - canvasRect.top)

        if (
          pickedObject &&
          filters &&
          ((filters.bodyTypes && !filters.bodyTypes.includes(pickedObject.body.metadata.bodyType)) ||
            (filters.onlyBars && pickedObject.body && pickedObject.body.metadata && !pickedObject.body.metadata.isBar))
        ) {
          pickedObject = null
        }

        if (
          options &&
          !options.allowSupports &&
          pickedObject &&
          pickedObject.body.metadata &&
          pickedObject.body.metadata.itemType === SceneItemType.Support
        ) {
          pickedObject = null
        }

        if (
          this.renderScene.getViewMode() instanceof DuplicateViewMode &&
          pickedObject &&
          pickedObject.body &&
          !meshManager.isClearanceSensitiveZone(pickedObject.body)
        ) {
          pickedObject = null
        }

        this.renderScene.hoverPickedObject(pickedObject)
      } else {
        this.renderScene.hoverPickedObject()
      }
    })
  }

  removeHoverObjectsObserver() {
    this.renderScene.hoverPickedObject()
    this.scene.onPointerObservable.remove(this.howerObjectsObserver)
  }

  addCollectorHoverObjectsObserver() {
    this.collectorHoverObjectsObserver = this.scene.onPointerObservable.add((evt) => {
      if (!this.renderScene.getCollectorManager().getActiveCollector) {
        return
      }

      if (evt.event.buttons === 0) {
        const canvasRect = this.renderScene.getActiveCamera().getEngine().getRenderingCanvasClientRect()
        const pickedObject = this.renderScene
          .getGpuPicker()
          .pick(evt.event.clientX - canvasRect.left, evt.event.clientY - canvasRect.top)

        this.renderScene.getCollectorManager().hover(pickedObject)
      } else {
        this.renderScene.getCollectorManager().hover()
      }
    })
  }

  removeCollectorHoverObjectObserver() {
    this.scene.onPointerObservable.remove(this.collectorHoverObjectsObserver)
  }

  addSelectedObjectObserver(
    options?: {
      shouldHandleCtrlKey?: boolean
      selectWithAttach?: boolean
      cacheSelected?: boolean
      ignoreBlankSpace?: boolean
      allowWithButtonPressed?: boolean
      allowSupports?: boolean
      ignoreRmb?: boolean
      ignoreMoveThreshold?: boolean
    },
    filters?: {
      bodyTypes?: GeometryType[]
      onlyBars?: boolean
    },
  ) {
    const { shouldHandleCtrlKey = true } = options || {}
    const { selectWithAttach = false } = options || {}
    const { cacheSelected = false } = options || {}
    const { ignoreBlankSpace = false } = options || {}
    const { ignoreRmb = false } = options || {}
    const { ignoreMoveThreshold = false } = options || {}
    const canvas = this.scene.getEngine().getRenderingCanvas()
    const meshManager = this.renderScene.getMeshManager()
    const pointerXMoveThreshold = canvas.clientWidth * THRESHOLD_RATIO
    const pointerYMoveThreshold = canvas.clientHeight * THRESHOLD_RATIO
    let pointerXStart = null
    let pointerYStart = null

    this.selectObjectsObserver = this.scene.onPointerObservable.add((evt) => {
      /*
        evt.event.buttons:
          0: No button or un-initialized
          1: Primary button (usually the left button)
          2: Secondary button (usually the right button)
          4: Auxiliary button (usually the mouse wheel button or middle button)
        evt.event.button:
          0: Main button pressed, usually the left button or the un-initialized state
          1: Auxiliary button pressed, usually the wheel button or the middle button (if present)
          2: Secondary button pressed, usually the right button
       */
      if (
        ((!options || !options.allowWithButtonPressed) &&
          !(
            [MouseButtons.LeftButton, MouseButtons.RightButton].includes(evt.event.button) &&
            evt.type === PointerEventTypes.POINTERTAP
          )) ||
        (options &&
          options.allowWithButtonPressed &&
          (![PointerEventTypes.POINTERDOWN, PointerEventTypes.POINTERUP].includes(evt.type) ||
            (evt.event.buttons !== 1 && evt.type === PointerEventTypes.POINTERDOWN) ||
            (evt.event.button !== MouseButtons.LeftButton && evt.type === PointerEventTypes.POINTERUP))) ||
        this.renderScene.getCollectorManager().getActiveCollector ||
        (ignoreRmb && evt.event.button === MouseButtons.RightButton)
      ) {
        return
      }

      if (
        evt.type === PointerEventTypes.POINTERDOWN &&
        (evt.event.buttons === 1 || evt.event.button === MouseButtons.LeftButton)
      ) {
        pointerXStart = evt.event.clientX
        pointerYStart = evt.event.clientY

        return
      }

      if (
        !ignoreMoveThreshold &&
        evt.type === PointerEventTypes.POINTERUP &&
        (Math.abs(pointerXStart - evt.event.clientX) > pointerXMoveThreshold ||
          Math.abs(pointerYStart - evt.event.clientY) > pointerYMoveThreshold)
      ) {
        return
      }

      const canvasRect = this.renderScene.getActiveCamera().getEngine().getRenderingCanvasClientRect()
      const pointerX = evt.event.clientX ? evt.event.clientX - canvasRect.left : this.scene.pointerX
      const pointerY = evt.event.clientY ? evt.event.clientY - canvasRect.top : this.scene.pointerY
      let pickedObject = this.renderScene.getGpuPicker().pick(pointerX, pointerY)

      if (
        pickedObject &&
        filters &&
        ((filters.bodyTypes && !filters.bodyTypes.includes(pickedObject.body.metadata.bodyType)) ||
          (filters.onlyBars && pickedObject.body && pickedObject.body.metadata && !pickedObject.body.metadata.isBar))
      ) {
        pickedObject = null
      }

      if (
        options &&
        !options.allowSupports &&
        pickedObject &&
        pickedObject.body.metadata &&
        pickedObject.body.metadata.itemType === SceneItemType.Support
      ) {
        pickedObject = null
      }

      const isClearanceToolEnabled = store.getters['visualizationModule/isClearanceToolEnabled']
      if (!isClearanceToolEnabled) {
        this.renderScene.selectPickedObject(
          (shouldHandleCtrlKey && evt.event.ctrlKey) || selectWithAttach,
          cacheSelected,
          ignoreBlankSpace,
          pickedObject,
        )
      } else if (pickedObject) {
        const { part, body } = pickedObject
        const from = store.getters['visualizationModule/enabledClearanceFrom'] as ClearanceTypes
        const to = store.getters['visualizationModule/enabledClearanceTo'] as ClearanceTypes
        if (part && meshManager.isPartMesh(part) && (from === ClearanceTypes.Parts || to === ClearanceTypes.Parts)) {
          const { buildPlanItemId } = part.metadata as IPartMetadata
          this.renderScene.measureDistanceToEnvironment({ from, to, buildPlanItemId })
        } else if (
          part &&
          meshManager.isPartMesh(part) &&
          body &&
          meshManager.isComponentMesh(body) &&
          (from === ClearanceTypes.Bodies || to === ClearanceTypes.Bodies)
        ) {
          const { buildPlanItemId } = part.metadata as IPartMetadata
          const { componentId, geometryId } = body.metadata as IComponentMetadata
          this.renderScene.measureDistanceToEnvironment({ from, to, buildPlanItemId, componentId, geometryId })
        }
      }
    })
  }

  removeSelectedObjectObserver() {
    this.scene.onPointerObservable.remove(this.selectObjectsObserver)
  }

  addCollectorSelectedObjectObserver() {
    this.collectorSelectObjectsObserver = this.scene.onPointerObservable.add((evt) => {
      if (
        !(
          [MouseButtons.LeftButton, MouseButtons.RightButton].includes(evt.event.button) &&
          evt.type === PointerEventTypes.POINTERTAP
        ) ||
        !this.renderScene.getCollectorManager().getActiveCollector
      ) {
        return
      }

      const canvasRect = this.renderScene.getActiveCamera().getEngine().getRenderingCanvasClientRect()
      const pickedObject = this.renderScene
        .getGpuPicker()
        .pick(evt.event.clientX - canvasRect.left, evt.event.clientY - canvasRect.top)

      this.renderScene.getCollectorManager().pick(pickedObject, evt.event.ctrlKey)
    })
  }

  removeCollectorSelectedObjectObserver() {
    this.scene.onPointerObservable.remove(this.collectorSelectObjectsObserver)
  }

  addLeftPointerEventForCameraObserver() {
    this.leftPointerEventForCamera = this.scene.onPointerObservable.add(
      (evt) => {
        if (evt.type === PointerEventTypes.POINTERDOWN && evt.event.button === MouseButtons.LeftButton) {
          this.renderScene.setLeftPointerCameraInput(
            evt.event.altKey || (evt.event as PointerEvent).pointerType !== PointerType.MOUSE,
          )
        }
      },
      undefined,
      true,
    )
  }

  removeLeftPointerEventForCameraObserver() {
    this.renderScene.setLeftPointerCameraInput(false)
    this.scene.onPointerObservable.remove(this.leftPointerEventForCamera)
  }

  addSelectionBoxObserver(
    options?: {
      shouldHandleCtrlKey?: boolean
      selectWithAttach?: boolean
      cacheSelected?: boolean
      ignoreBlankSpace?: boolean
      allowWithButtonPressed?: boolean
      allowSupports?: boolean
      ignoreRmb?: boolean
    },
    filters?: { bodyTypes?: GeometryType[]; onlyBars?: boolean },
  ) {
    const { ignoreBlankSpace = false } = options || {}
    const canvas = this.scene.getEngine().getRenderingCanvas()
    const pointerXMoveThreshold = canvas.clientWidth * THRESHOLD_RATIO
    const pointerYMoveThreshold = canvas.clientHeight * THRESHOLD_RATIO
    let pointerXStart = null
    let pointerYStart = null
    let altKeyStartModificator = false
    let touchModificator = false
    let isDown = false
    let shouldDeselectAfterMove = false
    let isCtrlDown = false

    if (this.selectionBoxObserver) {
      // Do not rewrite this.selectionBoxObserver because it will be impossible to remove it from scene:
      // https://doc.babylonjs.com/features/featuresDeepDive/events/observables#add-an-observer .
      // To avoid duplicated selectionBoxObservers remove current before rewrite with new.
      this.removeSelectionBoxObserver()
    }

    this.selectionBoxObserver = this.scene.onPointerObservable.add((evt) => {
      if (this.renderScene.getCollectorManager().getActiveCollector) {
        return
      }

      if (evt.type === PointerEventTypes.POINTERDOWN && evt.event.button === MouseButtons.LeftButton) {
        pointerXStart = evt.event.clientX
        pointerYStart = evt.event.clientY
        altKeyStartModificator = evt.event.altKey
        touchModificator = evt.event.altKey || (evt.event as PointerEvent).pointerType !== PointerType.MOUSE
        if (altKeyStartModificator || touchModificator) {
          return
        }

        isDown = true
        const canvasRect = this.renderScene.getActiveCamera().getEngine().getRenderingCanvasClientRect()
        const position = new Vector2(evt.event.clientX - canvasRect.left, evt.event.clientY - canvasRect.top)

        let type
        if (evt.event.ctrlKey) {
          type = true
          isCtrlDown = true
        } else if (evt.event.shiftKey) {
          type = false
        } else {
          type = true
          shouldDeselectAfterMove = !ignoreBlankSpace
        }

        this.renderScene.getSelectionManager().selectionBox.pointerDown(position, type)
      } else if (evt.type === PointerEventTypes.POINTERMOVE && !altKeyStartModificator && isDown) {
        if (shouldDeselectAfterMove) {
          this.renderScene.getSelectionManager().deselect()
          shouldDeselectAfterMove = false
        }

        const canvasRect = this.renderScene.getActiveCamera().getEngine().getRenderingCanvasClientRect()
        const position = new Vector2(evt.event.clientX - canvasRect.left, evt.event.clientY - canvasRect.top)
        this.renderScene.getSelectionManager().selectionBox.pointerMove(position)
        this.renderScene
          .getSelectionManager()
          .selectionBox.highlightItemsInFrustum(options ? { allowSupports: options.allowSupports } : null, filters)
      } else if (evt.type === PointerEventTypes.POINTERUP && !altKeyStartModificator && isDown) {
        isDown = false
        shouldDeselectAfterMove = false
        this.renderScene.getSelectionManager().selectionBox.pointerUp(!isCtrlDown && ignoreBlankSpace)
        if (
          Math.abs(pointerXStart - evt.event.clientX) > pointerXMoveThreshold ||
          Math.abs(pointerYStart - evt.event.clientY) > pointerYMoveThreshold
        ) {
          this.renderScene
            .getSelectionManager()
            .selectionBox.selectItemsInFrustum(options ? { allowSupports: options.allowSupports } : null, filters)
        }

        isCtrlDown = false
      }
    })
  }

  removeSelectionBoxObserver() {
    this.scene.onPointerObservable.remove(this.selectionBoxObserver)
    this.selectionBoxObserver = null
  }

  addGpuPickerObserver() {
    const scene = this.scene
    this.gpuPickerSceneBeforeRenderObservable = this.scene.onBeforeRenderObservable.add(() => {
      if (scene.metadata && scene.metadata.updateGpuPicker) {
        this.renderScene.getGpuPicker().enable()
      }
    })

    this.gpuPickerSceneAfterRenderObserver = this.scene.onAfterRenderObservable.add(async () => {
      if (scene.metadata) {
        if (scene.metadata.updateGpuPicker) {
          await this.renderScene.getGpuPicker().readAndSavePixels()
          this.renderScene.getGpuPicker().disable()
        }

        scene.metadata.updateGpuPicker = undefined
      }
    })
  }

  removeGpuPickerObserver() {
    this.scene.onAfterRenderObservable.remove(this.gpuPickerSceneBeforeRenderObservable)
    this.scene.onAfterRenderObservable.remove(this.gpuPickerSceneAfterRenderObserver)
  }

  addCameraPositionChangedObserver() {
    let cameraPos = this.renderScene.getActiveCamera().position.clone()

    this.cameraChangedPositionObserver = this.scene.onPointerObservable.add((evt) => {
      if (!this.renderScene.cameraPositionChanged(cameraPos)) {
        return
      }

      this.renderScene.getSelectionManager().sendBoundingAnchorPoint()

      cameraPos = this.renderScene.getActiveCamera().position.clone()

      this.renderScene.triggerCameraPositionChangedEvent(cameraPos)
    })
  }

  removeCameraPositionChangedObserver() {
    this.scene.onPointerObservable.remove(this.cameraChangedPositionObserver)
  }

  addCameraZoomWithTouch() {
    const renderScene = this.renderScene
    const canvas = this.renderScene.getScene().getEngine().getRenderingCanvas()
    const camera = this.renderScene.getActiveCamera()
    const pointersInput = camera.inputs.attached.pointers as ArcRotateCameraPointersInput
    const eventCache = []
    const panningSensibilityForTouch = 100
    let prevDiff = -1
    let afterPan = false

    this.cameraTouchPrepare = this.scene.onPrePointerObservable.add((eventData) => {
      camera.togglePointerInput(
        0,
        (eventData.event as PointerEvent).pointerType !== PointerType.MOUSE || eventData.event.altKey,
      )
    })

    this.cameraZoomWithTouchObserver = this.scene.onPointerObservable.add((eventData) => {
      if (
        eventData.type === PointerEventTypes.POINTERDOWN &&
        (eventData.event as PointerEvent).pointerType !== PointerType.MOUSE
      ) {
        camera.panningSensibility = panningSensibilityForTouch
        // The pointerdown event signals the start of a touch interaction.
        // This event is cached to support 2-finger gestures
        eventCache.push(eventData.event)
        afterPan = false
      }

      if (
        eventData.type === PointerEventTypes.POINTERMOVE &&
        (eventData.event as PointerEvent).pointerType !== PointerType.MOUSE
      ) {
        // Find this event in the cache and update its record with this event
        const index = eventCache.findIndex(
          (cachedEvent) => cachedEvent.pointerId === (eventData.event as PointerEvent).pointerId,
        )
        eventCache[index] = eventData.event

        // If two pointers are down, check for pinch gestures
        if (eventCache.length === 2) {
          // Calculate the distance between the two pointers
          const curDiff = Math.abs(eventCache[0].clientX - eventCache[1].clientX)

          if (prevDiff > 0) {
            const diff = prevDiff - curDiff
            if (diff) {
              renderScene.panCamera(camera, diff, true)
            }
          }

          // Cache the distance for the next move event
          prevDiff = curDiff
        }

        if (eventCache.length <= 2) {
          pointersInput.multiTouchPanning = false
          pointersInput.multiTouchPanAndZoom = false
        } else {
          if (!afterPan) {
            pointersInput.multiTouchPanning = true
            pointersInput.multiTouchPanAndZoom = true
          }
        }
      }

      if (
        eventData.type === PointerEventTypes.POINTERUP &&
        (eventData.event as PointerEvent).pointerType !== PointerType.MOUSE
      ) {
        camera.panningSensibility = canvas.width / (4 * camera.orthoTop)

        // Remove this pointer from the cache
        const index = eventCache.findIndex(
          (cachedEvent) => cachedEvent.pointerId === (eventData.event as PointerEvent).pointerId,
        )
        eventCache.splice(index, 1)

        // If the number of pointers down is less than two then reset diff tracker
        if (eventCache.length < 2) {
          prevDiff = -1
        }

        pointersInput.multiTouchPanning = false
        pointersInput.multiTouchPanAndZoom = false
        if (eventCache.length > 2) {
          afterPan = true
        }
      }
    })
  }

  removeCameraZoomWithTouch() {
    const canvas = this.renderScene.getScene().getEngine().getRenderingCanvas()
    const camera = this.renderScene.getActiveCamera()
    const pointersInput = camera.inputs.attached.pointers as ArcRotateCameraPointersInput
    const totalY = Math.abs(camera.orthoTop - camera.orthoBottom)
    camera.panningSensibility = canvas.width / (4 * totalY)
    pointersInput.multiTouchPanAndZoom = true
    this.scene.onPointerObservable.remove(this.cameraZoomWithTouchObserver)
    this.scene.onPrePointerObservable.remove(this.cameraTouchPrepare)
  }

  addClearanceRubberBandObserver() {
    const canvas = this.scene.getEngine().getRenderingCanvas()
    const pointerXMoveThreshold = canvas.clientWidth * THRESHOLD_RATIO
    const pointerYMoveThreshold = canvas.clientHeight * THRESHOLD_RATIO
    let pointerXStart = null
    let pointerYStart = null

    if (this.clearanceRubberBandObserver) {
      // Do not rewrite this.clearanceRubberBandObserver because it will be impossible to remove it from scene:
      // https://doc.babylonjs.com/features/featuresDeepDive/events/observables#add-an-observer .
      // To avoid duplicated clearanceRubberBandObserver remove current before rewrite with new.
      this.removeClearanceRubberBandObserver()
    }

    this.clearanceRubberBandObserver = this.scene.onPointerObservable.add((evt) => {
      /*
        evt.event.buttons:
          0: No button or un-initialized
          1: Primary button (usually the left button)
          2: Secondary button (usually the right button)
          4: Auxiliary button (usually the mouse wheel button or middle button)
        evt.event.button:
          0: Main button pressed, usually the left button or the un-initialized state
          1: Auxiliary button pressed, usually the wheel button or the middle button (if present)
          2: Secondary button pressed, usually the right button
      */
      if (
        evt.type !== PointerEventTypes.POINTERMOVE &&
        (![PointerEventTypes.POINTERDOWN, PointerEventTypes.POINTERUP].includes(evt.type) ||
          (evt.event.buttons !== 1 && evt.type === PointerEventTypes.POINTERDOWN) ||
          (evt.event.button !== MouseButtons.LeftButton && evt.type === PointerEventTypes.POINTERUP))
      ) {
        return
      }

      if (
        evt.type === PointerEventTypes.POINTERDOWN &&
        (evt.event.buttons === 1 || evt.event.button === MouseButtons.LeftButton)
      ) {
        pointerXStart = evt.event.clientX
        pointerYStart = evt.event.clientY

        return
      }

      if (
        evt.type === PointerEventTypes.POINTERDOWN &&
        (Math.abs(pointerXStart - evt.event.clientX) > pointerXMoveThreshold ||
          Math.abs(pointerYStart - evt.event.clientY) > pointerYMoveThreshold)
      ) {
        return
      }

      const clearanceManager = this.renderScene.getClearanceManager()
      if (!clearanceManager || !clearanceManager.hasClearanceMode(ClearanceModes.Mesh)) {
        return
      }

      const clearanceMeshToMesh = clearanceManager.getClearanceMode(ClearanceModes.Mesh) as ClearanceMeshToMesh
      const canvasRect = this.renderScene.getActiveCamera().getEngine().getRenderingCanvasClientRect()
      const pointerX = evt.event.clientX ? evt.event.clientX - canvasRect.left : this.scene.pointerX
      const pointerY = evt.event.clientY ? evt.event.clientY - canvasRect.top : this.scene.pointerY
      const isRubberBandShown = store.getters['visualizationModule/isRubberBandShown']

      if (evt.type === PointerEventTypes.POINTERMOVE && isRubberBandShown) {
        clearanceMeshToMesh.updateRubberBand(pointerX, pointerY)
      } else if (evt.type !== PointerEventTypes.POINTERMOVE && !isRubberBandShown) {
        clearanceMeshToMesh.createRubberBand(pointerX, pointerY)
      } else if (evt.type !== PointerEventTypes.POINTERMOVE && isRubberBandShown) {
        clearanceMeshToMesh.replaceRubberBand(pointerX, pointerY)
      }
    })
  }

  removeClearanceRubberBandObserver() {
    this.scene.onPointerObservable.remove(this.clearanceRubberBandObserver)
    this.clearanceRubberBandObserver = null
  }
}
