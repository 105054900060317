/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Vector3, Matrix } from '@babylonjs/core/Maths'
import { BoundingBox } from '@babylonjs/core/Culling/boundingBox'
import { IDENTITY_MATRIX, MAX, MIN } from '@/constants'
import { LinesMesh } from '@babylonjs/core/Meshes'

export class ConvexHull {
  readonly hullPoints: Vector3[]
  meshHull: LinesMesh

  constructor(hull: any) {
    this.hullPoints = this.parseHull(hull)
  }

  public computeBBox(trf: Matrix = IDENTITY_MATRIX): BoundingBox {
    const minMax = this.findMinMax(trf)
    return new BoundingBox(minMax.min, minMax.max)
  }

  private findMinMax(trf: Matrix) {
    let min = new Vector3(MIN, MIN, MIN)
    let max = new Vector3(MAX, MAX, MAX)
    this.hullPoints.forEach((point) => {
      const pointInWorld = Vector3.TransformCoordinates(point, trf)
      min = Vector3.Minimize(pointInWorld, min)
      max = Vector3.Maximize(pointInWorld, max)
    })

    return { min, max }
  }

  private parseHull(hull: any[]) {
    if (!hull || !hull.length) {
      return null
    }
    const hullVectors: Vector3[] = []
    hull.forEach((point) => {
      hullVectors.push(new Vector3(point.x, point.y, point.z))
    })
    return hullVectors
  }
}
