import { Scene } from '@babylonjs/core/scene'
import { Mesh, MeshBuilder } from '@babylonjs/core/Meshes'
import { SimulationSlicerManager } from '@/visualization/rendering/SimulationSlicerManager'
import { HighlightType } from '@/visualization/types/Common'
import {
  SIMULATION_DATA_SUFFIX,
  HIGHLIGHT_ERROR_MATERIAL,
  HIGHLIGHT_INFO_MATERIAL,
  HIGHLIGHT_WARNING_MATERIAL,
  MESH_RENDERING_GROUP_ID,
} from '@/constants'
import { Vector3 } from '@babylonjs/core/Maths'

const DETAILS_HIGHLIGHT = 'detailsHightlight'

export class SummarySelectionManager {
  private scene: Scene
  private sliceManager: SimulationSlicerManager

  constructor(scene: Scene, slicer: SimulationSlicerManager) {
    this.scene = scene
    this.sliceManager = slicer
  }

  hightlight(coords, type: HighlightType) {
    let highlighter = this.highlighter
    if (!highlighter) {
      highlighter = this.createHighligher()
    }

    const position = Vector3.FromArray(coords)
    highlighter.visibility = 1
    highlighter.position = position
    const materialName = this.getMaterialFromHighlightType(type)
    highlighter.material = this.scene.getMaterialByName(materialName)
    this.sliceManager.changeCurrentSimulationSlicer(position.z)
  }

  hide() {
    const highlighter = this.highlighter
    if (highlighter) {
      highlighter.visibility = 0
    }

    this.resetSlicer()
  }

  clear() {
    const highlighter = this.highlighter
    if (highlighter) {
      this.scene.removeMesh(highlighter)
      highlighter.dispose()
    }

    this.resetSlicer()
  }

  private getMaterialFromHighlightType(type: HighlightType) {
    switch (type) {
      case HighlightType.Error:
        return HIGHLIGHT_ERROR_MATERIAL
      case HighlightType.Warning:
        return HIGHLIGHT_WARNING_MATERIAL
      case HighlightType.Info:
      default:
        return HIGHLIGHT_INFO_MATERIAL
    }
  }

  private resetSlicer() {
    const boundingBox = this.scene.getWorldExtends((mesh) => mesh.name.includes(SIMULATION_DATA_SUFFIX))
    this.sliceManager.changeCurrentSimulationSlicer(boundingBox.max.z)
  }

  private get highlighter(): Mesh {
    return this.scene.getMeshByID(DETAILS_HIGHLIGHT) as Mesh
  }

  private createHighligher(): Mesh {
    const sphere = MeshBuilder.CreateSphere(DETAILS_HIGHLIGHT, { diameter: 1 }, this.scene)
    sphere.renderingGroupId = MESH_RENDERING_GROUP_ID + 1
    sphere.onBeforeRenderObservable.add(() => {
      const camera = this.scene.activeCamera
      const scale = (camera.orthoRight - camera.orthoLeft) / 50
      sphere.scaling = new Vector3(scale, scale, scale)
    })

    return sphere
  }
}
