
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VTextArea from 'vuetify/lib/components/VTextarea'
import VBtn from 'vuetify/lib/components/VBtn'
import { VCardText } from 'vuetify/lib/components/VCard'
import { extend } from 'vee-validate'
import { between } from 'vee-validate/dist/rules'
import { secondsToMilliseconds } from 'date-fns'

import buildPlans from '@/api/buildPlans'
import sites from '@/api/sites'
import messageService from '@/services/messageService'
import EditableLabel from '@/components/controls/Common/EditableLabel.vue'
import EditingParameterModal from '@/components/layout/admin/printStrategy/EditingParameterModal.vue'
import ParameterErrorModal from '@/components/layout/admin/printStrategy/ParameterErrorModal.vue'
import PartParameterForm from '@/components/layout/admin/printStrategy/parameterEditor/PartParameterForm.vue'
import ProcessParameterForm from '@/components/layout/admin/printStrategy/ProcessParameterForm.vue'
import { EditableParameter, PrintStrategyShortInfo, PrintStrategyParameterType, MachineType } from '@/types/Sites/Site'
import { IParameterSet, IProductionSet } from '@/types/BuildPlans/IBuildPlan'
import {
  ParameterError,
  ParametersValidationReport,
  ValidationError,
} from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { UpdateBinderJetPartParameterDto } from '@/types/Sites/UpdateBinderJetPartParameterDto'
import { UpdateBinderJetProcessParameterDto } from '@/types/Sites/UpdateBinderJetProcessParameterDto'
import { ExportBinderJetProcessParameterDto } from '@/types/Sites/ExportBinderJetProcessParameterDto'
import { ExportBinderJetPartParameterDto } from '@/types/Sites/ExportBinderJetPartParameterDto'
import { PrintStrategyMode } from '@/types/Sites/ParameterEditor'
import { debounce } from '@/utils/debounce'
import { PartParameterFormViewModel } from './parameterEditor/PartParameterFormViewModel'
import { ProcessParameterFormViewModel } from './parameterEditor/ProcessParameterFormViewModel'
import { DEFAULT_CONTOUR_PIXEL_LEVEL, H2_MACHINE_CONFIG_NAME, HatchType, JSON_CONTENT_TYPE } from '@/constants'
import { ApiValidationError } from '@/api/common'
import { ProcessParameterFormValidation } from './parameterEditor/processParameterValidation'
import { PartParameterFormValidation } from './parameterEditor/partParameterValidation'
import { isNil, isOfType } from '@/utils/common'
import { downloadFile } from '@/utils/download'

enum EditorMode {
  Default = 'Default',
  Edit = 'Edit',
  View = 'View',
  Loading = 'Loading',
}

const INPUT_FIELD_ERROR_STATE_CSS_CLASSNAME = 'error-flat-form'
const SLIDER_FIELD_ERROR_STATE_CSS_CLASSNAME = 'error--text'
const INPUT_FIELD_ERROR_STATE_SCROLL_OFFSET = 170
const JSON_TEXT_SPACE = 2

const mapEditorErrorByValidationConstraint = new Map()
mapEditorErrorByValidationConstraint.set('min', ValidationError.InvalidValue)
mapEditorErrorByValidationConstraint.set('max', ValidationError.InvalidValue)
mapEditorErrorByValidationConstraint.set('isNumber', ValidationError.InvalidValue)
mapEditorErrorByValidationConstraint.set('isNotEmpty', ValidationError.MissingParameter)

const RANGE_CONSTRAINT_NAMES = ['min', 'max', 'isNumber']
const MISSING_PARAMETER_CONSTRAINT_NAME = 'isNotEmpty'

const JSON_PROCESS_PARAMETER_KEY = 'processParameters'
const JSON_PART_PARAMETER_KEY = 'partParameters'

@Component({
  components: {
    EditableLabel,
    EditingParameterModal,
    ParameterErrorModal,
    PartParameterForm,
    ProcessParameterForm,
  },
})
export default class ParameterEditor extends Vue {
  @Prop() parameter: EditableParameter
  @Prop({ required: false }) machine: MachineType
  @Prop({ default: PrintStrategyMode.Default }) printStrategyMode: PrintStrategyMode
  @Prop({ required: false }) readonly: boolean

  EditorMode = EditorMode
  mode = EditorMode.Default
  editableParameter: IProductionSet | IParameterSet = null
  editableContent: string = null
  originalContent: string = null
  editableParameterName = 'None'
  editingParameterModal = false
  parameterErrorModal = false
  parameterErrors: ParameterError[] = []
  parameterNameLabel = 'Showing'
  isParameterNameInEditMode = false
  isParameterContentEditable = false
  isLoadingParameter = false
  isNameFieldProcessing = false
  isParameterFormValid = true
  isSaving = false
  machineConfigName: string = null

  $refs: {
    contentField: VTextArea
    saveBtn: VBtn
    parameterForm: PartParameterForm | ProcessParameterForm
    parameterName: EditableLabel
    content: VCardText
    uploader: HTMLInputElement
  }

  get isProcessParameter(): boolean {
    return this.parameter.type === PrintStrategyParameterType.Process
  }

  get isAnyDialogDisplayed(): boolean {
    return this.parameterErrorModal || this.editingParameterModal
  }

  get isEditParameterNameDisabled() {
    return (
      this.mode === EditorMode.Default ||
      this.mode === EditorMode.Edit ||
      !this.isParameterContentEditable ||
      this.readonly
    )
  }

  @Watch('parameter')
  async onParameterChange(newValue: EditableParameter) {
    if (this.parameter) {
      this.isParameterFormValid = true
      await this.loadParameter(newValue)
    } else {
      this.changeMode(EditorMode.Default)
      this.resetState()
    }
  }

  @Watch('mode')
  onModeChange(newMode: EditorMode) {
    switch (newMode) {
      case EditorMode.Default:
        this.handleDefaultMode()
        break
      case EditorMode.View:
        this.handleViewMode()
        break
      case EditorMode.Edit:
        this.handleEditMode()
        break
      default:
        this.handleDefaultMode()
    }
  }

  created() {
    this.extendValidationRules()
    this.changeMode(EditorMode.Default)
  }

  extendValidationRules() {
    extend('unique', {
      validate: debounce(500, async (value: string) => {
        if (this.isNameFieldProcessing || !this.editableParameterName) {
          return true
        }

        if (this.editableParameterName === value.trim()) {
          const isValid = !this.isNameChanged()
          return isValid
        }

        this.isNameFieldProcessing = true

        const isNameExists = await this.isParameterNameExists(value.trim())

        this.isNameFieldProcessing = false

        return !isNameExists
      }),
      message: 'Name must be unique',
    })

    extend('between', {
      ...between,
      params: ['min', 'max', 'digits', 'unit'],
      message: (_, placeholders: { min: number; max: number; unit?: string; digits?: number }) => {
        const { min, max, unit, digits } = placeholders

        let minStr: string
        let maxStr: string

        if (digits === undefined) {
          minStr = min.toString()
          maxStr = max.toString()
        } else if (digits) {
          // Remove end zeroes if field has more allowed digits then limit(e.g. 0.100 will be turned to 0.1)
          minStr = parseFloat(min.toFixed(digits)).toString()
          maxStr = parseFloat(max.toFixed(digits)).toString()
        } else {
          minStr = min.toFixed(0)
          maxStr = max.toFixed(0)
        }

        const unitStr = unit ? ` ${unit}` : ''
        return `Enter value ${minStr} - ${maxStr}${unitStr}`
      },
    })
  }

  isParameterNameExists(name: string): Promise<boolean> {
    if (this.parameter.type === PrintStrategyParameterType.Process) {
      return sites.isProcessParameterNameExists(name)
    }
    return sites.isPartParameterNameExists(name)
  }

  changeMode(mode: EditorMode) {
    this.mode = mode
  }

  handleDefaultMode() {
    this.isParameterContentEditable = false
    this.resetParameterNameField()
    this.resetState()
    this.$emit('close')
  }

  async handleViewMode() {
    this.isParameterContentEditable = false
    this.editableParameterName = this.getParameterName()
    if (!this.isParameterNameInEditMode) {
      this.parameterNameLabel = 'Showing'
    }
    this.isParameterContentEditable = await this.canEditParameterContent()
  }

  handleEditMode() {
    if (!this.isParameterNameInEditMode) {
      this.parameterNameLabel = 'Editing'
    }
  }

  async canEditParameterContent(): Promise<boolean> {
    if (this.printStrategyMode === PrintStrategyMode.Custom) {
      return true
    }

    let canEditParameter: boolean

    if (this.parameter.type === PrintStrategyParameterType.Process) {
      canEditParameter = !(await sites.checkIfProductionSetIsInUsedPrintStrategy(this.parameter.id))
    } else {
      canEditParameter = !(await sites.checkIfParameterSetIsInUse(this.parameter.id))
    }

    return canEditParameter
  }

  async loadParameter(parameter: EditableParameter) {
    try {
      this.isLoadingParameter = true

      let loadedParameter: IProductionSet | IParameterSet

      if (parameter.type === PrintStrategyParameterType.Process) {
        loadedParameter = (await buildPlans.getProductionSetById(parameter.id)) as IProductionSet
        if (this.machine) {
          this.machineConfigName = this.machine.name
        } else {
          const machineConfig = await buildPlans.getMachineConfigById(loadedParameter.machineConfigId)
          this.machineConfigName = machineConfig.name
        }
        this.setProcessParameterContent(loadedParameter)
      } else if (parameter.type === PrintStrategyParameterType.Part) {
        loadedParameter = (await buildPlans.getParameterSetById(parameter.id)) as IParameterSet
        this.setPartParameterContent(loadedParameter)
      } else {
        throw new Error(`Unknown parameter type ${parameter.type}`)
      }

      this.editableParameter = loadedParameter

      if (this.mode === EditorMode.View) {
        this.handleViewMode()
      } else {
        this.changeMode(EditorMode.View)
      }
    } catch (error) {
      messageService.showErrorMessage(error)
    } finally {
      this.isLoadingParameter = false
    }
  }

  setProcessParameterContent(parameter: IProductionSet) {
    const { processParameters } = parameter
    const str = JSON.stringify(processParameters, null, 2)
    this.editableContent = str
    this.originalContent = str
  }

  setPartParameterContent(parameter: IParameterSet) {
    const { partParameters } = parameter
    const str = JSON.stringify(partParameters, null, 2)
    this.editableContent = str
    this.originalContent = str
  }

  getParameterName() {
    if (!this.editableParameter) {
      return null
    }

    return (
      (this.editableParameter as IProductionSet).productionSetName ||
      (this.editableParameter as IParameterSet).parameterSetName
    )
  }

  getParameterNameLabel(): string {
    if (this.isParameterNameInEditMode) {
      return this.parameter.type === PrintStrategyParameterType.Process
        ? 'Process Parameter Name'
        : 'Part Parameter Name'
    }

    if (this.mode === EditorMode.Edit) {
      return 'Editing'
    }

    return 'Showing'
  }

  onParameterNameEditClick() {
    this.toggleParameterNameField(true)
  }

  async onParameterNameEditConfirm(name: string) {
    await this.renameParameter(name)
  }

  onParameterNameEditCancel() {
    this.toggleParameterNameField(false)
  }

  async renameParameter(name: string) {
    if (this.isNameFieldProcessing) {
      return
    }

    if (this.editableParameterName === name) {
      this.isParameterNameInEditMode = false
      return
    }

    try {
      this.isNameFieldProcessing = true

      if (this.parameter.type === PrintStrategyParameterType.Process) {
        await buildPlans.renameProductionSet(this.editableParameter.id, name)
        ;(this.editableParameter as IProductionSet).productionSetName = name
      } else {
        await buildPlans.renameParameterSet(this.editableParameter.id, name)
        ;(this.editableParameter as IParameterSet).parameterSetName = name
      }
      this.editableParameterName = name

      const payload: EditableParameter = {
        name,
        id: this.parameter.id,
        type: this.parameter.type,
        printStrategyId: this.parameter.printStrategyId,
      }
      this.$emit('rename', payload)
      this.isParameterNameInEditMode = false
    } catch (error) {
      console.error(`Error while renaming the parameter: ${error.message}`)
    } finally {
      this.isNameFieldProcessing = false
    }
  }

  toggleParameterNameField(isEditMode: boolean) {
    this.isParameterNameInEditMode = isEditMode
    this.parameterNameLabel = this.getParameterNameLabel()
  }

  isParameterNameChanged(): boolean {
    return this.$refs.parameterName.isChanged()
  }

  async onEditClick() {
    this.changeMode(EditorMode.Edit)

    const isValid = await this.isFormValid()

    if (!isValid) {
      this.scrollToFormErrors()
      return
    }
  }

  onCloseClick() {
    if (this.isParameterNameChanged()) {
      this.toggleEditingParameterModal(true)
      return
    }
    this.changeMode(EditorMode.Default)
  }

  async onSaveClick() {
    const isValid = await this.isFormValid()

    if (!isValid) {
      this.scrollToFormErrors()
      return
    }

    const content = this.getFormData()

    await this.saveContent(content)
    this.saveParameterFormData()
    this.changeMode(EditorMode.View)
    this.$emit('save')
  }

  onCancelClick() {
    if (this.isSaving) {
      return
    }

    if (this.mode === EditorMode.Edit) {
      if (this.hasUnsavedChanges()) {
        this.editingParameterModal = true
        return
      }
      this.resetParameterForm()
    }

    this.changeMode(EditorMode.View)
  }

  async saveContent(content: unknown) {
    try {
      if (this.isSaving) {
        return
      }

      this.isSaving = true
      let response: ParametersValidationReport

      if (this.isProcessParameter) {
        response = await this.updateProcessParameterContent(
          this.editableParameter.id,
          content as ProcessParameterFormViewModel,
        )
      } else {
        const parameterSetContent = content as PartParameterFormViewModel
        response = await this.updatePartParameterContent(
          this.editableParameter.id,
          this.cleanUpSliceSettingsFields(parameterSetContent),
        )
      }

      this.originalContent = this.editableContent

      return response
    } catch (error) {
      if (error instanceof SyntaxError) {
        this.handleIncorrectContentFormat(error.message)
        return
      }

      if (error.validationMessages) {
        this.handleValidationError(error, content)
        return
      }

      messageService.showErrorMessage(error.message)
    } finally {
      this.isSaving = false
    }
  }

  async updateProcessParameterContent(parameterId: number, formData: ProcessParameterFormViewModel) {
    const dto = this.convertProcessParameterFormDataToDTO(formData)
    return buildPlans.updateProductionSetContent(parameterId, dto)
  }

  async updatePartParameterContent(parameterId: number, formData: PartParameterFormViewModel) {
    const dto = this.convertPartParameterFormDataToDTO(formData)
    return buildPlans.updateParameterSetContent(parameterId, dto)
  }

  handleIncorrectContentFormat(message: string) {
    this.parameterErrors = [{ error: ValidationError.InvalidJson, parameter: null, description: message }]
    this.parameterErrorModal = true
  }

  handleUnsupportedFileTypeForImport(message: string) {
    this.parameterErrors = [{ error: ValidationError.InvalidFileType, parameter: null, description: message }]
    this.parameterErrorModal = true
  }

  handleValidationError(error, content) {
    const messages = error.data.message as ApiValidationError[]
    this.parameterErrors = messages.map((message) => {
      const { constraints } = message
      const constraintNames = Object.keys(constraints)
      const foundConstraint = constraintNames.find((constraint) => mapEditorErrorByValidationConstraint.has(constraint))
      const parameterError = foundConstraint
        ? mapEditorErrorByValidationConstraint.get(foundConstraint)
        : ValidationError.MissingParameter

      return {
        error: parameterError,
        parameter: message.property,
        description: this.getErrorDescription(foundConstraint, message.property, content[message.property]),
      }
    })
    this.parameterErrorModal = true
  }

  getErrorDescription(constraint: string, fieldName: string, value: unknown) {
    const validationRules = this.isProcessParameter
      ? ProcessParameterFormValidation.getValidationRules(this.machineConfigName)
      : PartParameterFormValidation.getValidationRules(this.printStrategyMode)

    if (RANGE_CONSTRAINT_NAMES.includes(constraint)) {
      if (isNil(value)) {
        return 'The value is invalid.'
      }

      const description = `The value of "${value}" is invalid.`
      const fieldValidation = validationRules[fieldName]
      const range = ProcessParameterFormValidation.getRangeByField(fieldValidation)

      if (!range) {
        return description
      }

      const { min, max } = range

      return `${description} (Range: ${min} - ${max})`
    }

    if (constraint === MISSING_PARAMETER_CONSTRAINT_NAME) {
      return 'Specific parameter cannot be found in the file'
    }

    return 'Unknown error'
  }

  resetParameterNameField() {
    this.parameterNameLabel = 'Showing'
    this.editableParameterName = 'None'
    this.isParameterNameInEditMode = false
  }

  resetParameterForm() {
    this.$refs.parameterForm.reset()
  }

  cancelParameterNameEdit() {
    this.$refs.parameterName.cancel()
  }

  resetState() {
    this.editableParameter = null
    this.editableContent = null
  }

  async onEditingParameterModalSave() {
    try {
      if (this.isSaving) {
        return
      }

      if (this.isFormChanged()) {
        const isValid = await this.isFormValid()

        if (!isValid) {
          this.editingParameterModal = false
          this.scrollToFormErrors()
          return
        }

        const content = this.getFormData()

        await this.saveContent(content)

        this.saveParameterFormData()
      }

      if (this.isNameChanged()) {
        this.isSaving = true
        await this.renameParameter(this.$refs.parameterName.getValue())
      }

      this.changeMode(EditorMode.View)

      this.editingParameterModal = false
    } finally {
      this.isSaving = false
    }
  }

  onEditingParameterModalDiscard() {
    this.resetParameterForm()
    this.cancelParameterNameEdit()
    this.editingParameterModal = false
    this.changeMode(EditorMode.View)
  }

  onEditingParameterModalContinue() {
    this.editingParameterModal = false
  }

  onParameterErrorModalClose() {
    this.parameterErrorModal = false
  }

  async onParameterFormChanged(isValid: boolean) {
    this.isParameterFormValid = isValid
  }

  onImportClick() {
    this.$refs.uploader.click()
  }

  onImportFileChanged(e: Event) {
    const file = (e.target as HTMLInputElement).files[0]

    if (!file) {
      return
    }

    if (file.type !== JSON_CONTENT_TYPE) {
      this.handleUnsupportedFileTypeForImport(
        `The selected file ${file.name} cannot be imported. Only JSON type file is allowed.`,
      )
      return
    }

    const reader = new FileReader()

    reader.onload = () => this.handleImportFile(reader.result as string)

    reader.readAsText(file)
  }

  handleImportFile(result: string) {
    try {
      const json = JSON.parse(result)

      if (isOfType<IProductionSet>(this.editableParameter, JSON_PROCESS_PARAMETER_KEY)) {
        this.editableParameter = Object.assign({}, this.editableParameter, { processParameters: json })
      }

      if (isOfType<IParameterSet>(this.editableParameter, JSON_PART_PARAMETER_KEY)) {
        const partParametersContent = json as PartParameterFormViewModel

        this.validatePartParameterContent(partParametersContent)
        this.editableParameter = Object.assign({}, this.editableParameter, { partParameters: partParametersContent })
      }
    } catch (error) {
      if (error instanceof SyntaxError) {
        this.handleIncorrectContentFormat(error.message)
        return
      }
      messageService.showErrorMessage(error.message)
    } finally {
      this.$refs.uploader.value = null
    }
  }

  async onExportClick() {
    const formData = this.getFormData()

    if (this.isProcessParameter) {
      this.exportProcessParameter(this.editableParameter.id, formData as ProcessParameterFormViewModel)
    } else {
      this.exportPartParameter(this.editableParameter.id, formData as PartParameterFormViewModel)
    }
  }

  async exportProcessParameter(id: number, formData: ProcessParameterFormViewModel) {
    try {
      const updateDto = this.convertProcessParameterFormDataToDTO(formData)
      const exportDto: ExportBinderJetProcessParameterDto = {
        content: updateDto,
        printStrategyId: this.parameter.printStrategyId,
      }
      const parameter = await sites.exportProcessParameter(id, exportDto)
      const fileName = this.getParameterName()

      downloadFile(fileName, JSON.stringify(parameter, null, JSON_TEXT_SPACE), JSON_CONTENT_TYPE)
    } catch (error) {
      messageService.showErrorMessage(`An error occurred while exporting the parameter: ${error.message}`)
    }
  }

  async exportPartParameter(id: number, formData: PartParameterFormViewModel) {
    try {
      const updateDto = this.convertPartParameterFormDataToDTO(formData)
      const exportDto: ExportBinderJetPartParameterDto = {
        content: updateDto,
      }
      const parameter = await sites.exportPartParameter(id, exportDto)
      const fileName = this.getParameterName()

      downloadFile(fileName, JSON.stringify(parameter, null, JSON_TEXT_SPACE), JSON_CONTENT_TYPE)
    } catch (error) {
      messageService.showErrorMessage(`An error occurred while exporting the parameter: ${error.message}`)
    }
  }

  async isFormValid(options: { silent: boolean } = { silent: false }) {
    return this.$refs.parameterForm.isFormValid({ silent: options.silent })
  }

  getFormData() {
    return this.$refs.parameterForm.getFormData()
  }

  scrollToFormErrors() {
    const elements = this.$refs.parameterForm.$el.getElementsByClassName(INPUT_FIELD_ERROR_STATE_CSS_CLASSNAME)
    const sliderRelatedElements = this.$refs.parameterForm.$el.getElementsByClassName(
      SLIDER_FIELD_ERROR_STATE_CSS_CLASSNAME,
    )
    if (elements.length > 0) {
      const position = (elements[0] as HTMLElement).offsetTop
      this.$refs.content.scrollTop = position - INPUT_FIELD_ERROR_STATE_SCROLL_OFFSET
      return
    }
    if (sliderRelatedElements.length > 0) {
      const position = (sliderRelatedElements[0] as HTMLElement).offsetTop
      this.$refs.content.scrollTop = position - INPUT_FIELD_ERROR_STATE_SCROLL_OFFSET
      return
    }
  }

  saveParameterFormData() {
    this.$refs.parameterForm.save()
  }

  hasUnsavedChanges(): boolean {
    return this.isFormChanged() || this.isNameChanged()
  }

  isFormChanged(): boolean {
    return this.$refs.parameterForm && this.$refs.parameterForm.isFormChanged()
  }

  isNameChanged(): boolean {
    return this.$refs.parameterName && this.$refs.parameterName.isChanged()
  }

  toggleEditingParameterModal(isShow: boolean) {
    this.editingParameterModal = isShow
  }

  convertPartParameterFormDataToDTO(formData: PartParameterFormViewModel): UpdateBinderJetPartParameterDto {
    return { ...formData }
  }

  convertProcessParameterFormDataToDTO(formData: ProcessParameterFormViewModel): UpdateBinderJetProcessParameterDto {
    const dto: UpdateBinderJetProcessParameterDto = { ...formData }

    this.prepareBinderJetProcessParameterDto(dto)

    const excludedKeys = this.getExcludedKeysFromDtoByMachineConfigName(this.machineConfigName)
    ;(Object.keys(dto) as Array<keyof ProcessParameterFormViewModel>)
      .filter((key) => excludedKeys.includes(key))
      .forEach((key) => {
        delete dto[key]
      })

    return dto
  }

  getExcludedKeysFromDtoByMachineConfigName(name: string) {
    const excludedKeys: Array<keyof ProcessParameterFormViewModel> = ['dosingFactorUnit', 'angularSpeedUnit']
    if (name === H2_MACHINE_CONFIG_NAME) {
      const h2Keys: Array<keyof ProcessParameterFormViewModel> = [
        'temperatureSetPoint',
        'purgeFlowPrintheadSetPoint',
        'purgeFlowRecoatSetPoint',
        'purgeFlowMotionAndPrintheadPressurizationSetPoint',
        'purgeFlowHMBCoolingSetPoint',
        'purgePressure',
        'refillDwellTime',
        'dosingSupplyAngularSpeed',
        'dosingRefillAngularSpeed',
        'firstDoseFinalPosition',
        'dosingRefillHopperVibrator',
        'dosingRefillPistonVibrator',
        'moveSpeedSupplyDepart',
        'moveSpeedSupplyReturn',
        'moveSpeedBuildDepart',
        'moveSpeedBuildReturn',
        'liftLayerSplit',
        'rollerSpeedSupplyLevelingReturn',
        'rollerSpeedRecoatLevelingReturn',
      ]

      excludedKeys.push(...h2Keys)
    }

    return excludedKeys
  }

  prepareBinderJetProcessParameterDto(dto: UpdateBinderJetProcessParameterDto) {
    if (this.machineConfigName === H2_MACHINE_CONFIG_NAME) {
      dto.purgeTime = secondsToMilliseconds(dto.purgeTime)
    }
  }

  cleanUpSliceSettingsFields(context: PartParameterFormViewModel) {
    if (!context) {
      return context
    }

    const { sliceSettings } = context
    if (!sliceSettings) {
      return context
    }

    const { bands, bulk } = sliceSettings
    const clearHatchFieldsObj = {
      gridSpacingInPixels: undefined,
      gridSpacingGrayScale: undefined,
      gridThicknessInPixels: undefined,
      gridRotationAngleInDegrees: undefined,
    }
    if (bands && bands.length) {
      context.sliceSettings.bands = bands.map((x) =>
        x.hatchType === HatchType.Solid
          ? {
              ...x,
              ...clearHatchFieldsObj,
            }
          : x,
      )
    }

    context.sliceSettings.bulk =
      bulk.hatchType === HatchType.Solid
        ? {
            ...bulk,
            ...clearHatchFieldsObj,
          }
        : bulk

    return context
  }

  /**
   * Validates the content of a PartParameterFormViewModel, ensuring it meets specified criteria and sets default values.
   *
   * @param {PartParameterFormViewModel} content - The content to be validated
   * @throws {Error} If the content fails validation, specific error messages are thrown.
   */
  validatePartParameterContent(content: PartParameterFormViewModel) {
    if (!content.sliceSettings) throw new Error('Missed required field "sliceSettings"')

    if (!content.sliceSettings.contourPixelLevel) {
      content.sliceSettings.contourPixelLevel = DEFAULT_CONTOUR_PIXEL_LEVEL
    }
  }
}
