
import Component from 'vue-class-component'
import { Mixins, Prop, Watch } from 'vue-property-decorator'
import PartListItem from '@/components/layout/buildPlans/addPart/PartListItem.vue'
import { PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import { RouterNames } from '@/router'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import ActionBarMixin from '../Table/mixins/ActionBarMixin'

@Component({
  components: {
    PartListItem,
  },
})
export default class ExpansionItemsList extends Mixins(ActionBarMixin) {
  @Prop() titleToDisplay: string
  @Prop({ default: () => [] }) items: PartListItemViewModel[]
  @Prop() icon: string
  @Prop({ default: false }) disableHyperlinks: boolean

  model = null

  get isClosed() {
    return this.model === undefined ? true : false
  }

  @Watch('items')
  onItemsChange() {
    if (!this.items.length) {
      this.model = undefined
    }
  }

  onPartClick(item: PartListItemViewModel) {
    if (item.itemType !== ItemType.BuildPart) return

    switch (item.subType) {
      case ItemSubType.SinterPart:
        this.openBuildPlan(item.parentId)
        break
      case ItemSubType.IbcPart:
        this.openIbcPlan(item.parentId)
        break
      default:
        const route = {
          name: RouterNames.PartPreview,
          params: {
            id: item.id,
          },
        }

        // @ts-expect-error Custom router
        this.$router.safePush(route)
        break
    }
  }
}
