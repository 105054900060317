/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { SimulationManager } from '@/visualization/rendering/SimulationManager'
import {
  BRIDGING_MATERIAL,
  BRIDGING_TRANSPARENT,
  NOMINAL_GEOMETRY_MATERIAL,
  BIN_DIR,
  ADW_DIR,
  STL,
  COMPENSATED_MATERIAL,
  GREEN_COMPENSATED_MATERIAL,
  GREEN_NOMINAL_MATERIAL,
  MESHING_FILE,
  ANALYSIS_SETTINGS,
  SHRINKAGE_ON,
  BUILD_PLAN_METADATA,
  PART_JOB_METADATA,
} from '@/constants'
import { MeshHandler } from '@/visualization/rendering/MeshHandler'
import { MeshingHandler } from '@/visualization/rendering/MeshingHandler'
import { CompensatedMeshHandler } from '@/visualization/rendering/CompensatedMeshHandler'
import { BatchGeometryHandler } from '@/visualization/rendering/BatchGeometryHandler'
import i18n from '@/plugins/i18n'
import messageService from '@/services/messageService'
import {
  GeometryType,
  IDataDirectoryStructure,
  ResultsManagerEvent as RMEvent,
} from '@/visualization/types/SimulationTypes'

const DMLM_GEOMETRY_PATTERN = /^(?!.*(buildplate)).*^i0[^]*drc$/
const SINTER_GEOMETRY_PATTERN = /^(?!.*(sinter_plate)).*^i0[^]*sinter.drc$/
const GREEN_GEOMETRY_PATTERN = /^(?!.*(sinter_plate|sinter|mesh)).*^i0[^]*drc$/
const BUILD_PLATE_PATTERN = /^i0[^].*(buildplate)[^]*.drc$/

// Need to check for STLs for backwards compatibility
const STL_DMLM_GEOMETRY_PATTERN = /^(?!.*(buildplate)).*^i0[^]*stl$/
const STL_SINTER_GEOMETRY_PATTERN = /^(?!.*(sinter_plate)).*^i0[^]*sinter.stl$/
const STL_GREEN_GEOMETRY_PATTERN = /^(?!.*(sinter_plate|sinter|mesh)).*^i0[^]*stl$/
const STL_BUILD_PLATE_PATTERN = /^i0[^].*(buildplate)[^]*.stl$/

const DRC = 'drc'

const EXTRA_FILES_DIR = 'Extra_Files'
const BRIDGING_ELEMENTS_FILE = 'bridging_elements.vtk'
const COMPENSATED_DIR = 'Compensated_Files'
const SINTER_SIZE = 'sintersize'
const GREEN_SIZE = 'greensize'

export interface IHandlerSettings {
  materialId: string
  type: GeometryType
  metadata?: any[]
  paths: string | string[]
}

export class NominalGeometryManager {
  private manager: SimulationManager
  private geometryHandlers: Map<GeometryType, MeshHandler>

  constructor(manager: SimulationManager) {
    this.manager = manager
    this.geometryHandlers = new Map<GeometryType, MeshHandler>()
  }

  initPaths(dirContent: IDataDirectoryStructure, isSinter: boolean, processParameters) {
    const jobMetadata =
      (processParameters &&
        processParameters[BUILD_PLAN_METADATA] &&
        processParameters[BUILD_PLAN_METADATA][PART_JOB_METADATA]) ||
      undefined
    const shrinkangeOn =
      (processParameters &&
        processParameters[ANALYSIS_SETTINGS] &&
        processParameters[ANALYSIS_SETTINGS][SHRINKAGE_ON]) ||
      false

    const extraDirContent = dirContent.dirs[EXTRA_FILES_DIR]
    this.setNominalGeometryPath(extraDirContent, jobMetadata, isSinter, shrinkangeOn)

    const adwDir = dirContent.dirs[ADW_DIR]
    if (adwDir && adwDir.dirs[BIN_DIR]) {
      this.checkBridgingElements(adwDir.dirs[BIN_DIR])
    }

    try {
      if (adwDir && adwDir.dirs[COMPENSATED_DIR] && Array.isArray(jobMetadata)) {
        this.checkCompensatedGeometry(adwDir.dirs[COMPENSATED_DIR], jobMetadata, isSinter, shrinkangeOn)
      }
    } catch {
      console.warn('Error occurred during collecting compensated geometry information')
    }
  }

  toggleNominalGeometry(makeVisible: boolean) {
    this.showHideHandler(GeometryType.Nominal, makeVisible)
    const nominal = this.geometryHandlers.get(GeometryType.Nominal)
    if (!nominal.isReady) return

    const bridging = this.geometryHandlers.get(GeometryType.Bridging)
    const buildPlate = this.geometryHandlers.get(GeometryType.BuildPlate)
    if (bridging && bridging.isReady && bridging.visible) {
      bridging.visible = !makeVisible
      buildPlate.visible = !makeVisible
    }

    if (makeVisible) {
      nominal.setMaterial(NOMINAL_GEOMETRY_MATERIAL)
    }

    nominal.visible = makeVisible
    this.triggerVisibilityChange(GeometryType.Nominal, makeVisible)
    this.manager.renderScene()
  }

  toggleBridgingElements(makeVisible: boolean) {
    const nominal = this.geometryHandlers.get(GeometryType.Nominal)
    if (!nominal) {
      messageService.showWarningMessage(i18n.t('bridgingCantBeShown').toString())
      return
    }

    // In case if nominal geometry is not loaded at the moment user requested bridging
    // we need to load it with bridging material applied. After nominal geometry loading we should set
    // its visibility state to false while actually keeping it visible to set toggle buttons in correct state
    if (!nominal.isReady) {
      const handler = this.geometryHandlers.get(GeometryType.Nominal)
      handler.changeDefaultMaterial(BRIDGING_TRANSPARENT)
      handler.onHandlerReady = () => {
        this.triggerVisibilityChange(GeometryType.Nominal, false)
      }
    } else if (makeVisible) {
      nominal.setMaterial(BRIDGING_TRANSPARENT)
    }

    this.showHideHandler(GeometryType.Nominal, makeVisible)
    this.showHideHandler(GeometryType.BuildPlate, makeVisible)
    this.showHideHandler(GeometryType.Bridging, makeVisible)

    if (makeVisible) {
      this.showHideHandler(GeometryType.Meshing, false)
      this.showHideHandler(GeometryType.Compensated, false)
      this.showHideHandler(GeometryType.GreenCompensated, false)
      this.showHideHandler(GeometryType.GreenNominal, false)
      this.triggerVisibilityChange(GeometryType.Nominal, false)
      this.triggerVisibilityChange(GeometryType.Meshing, false)
      this.triggerVisibilityChange(GeometryType.Compensated, false)
      this.triggerVisibilityChange(GeometryType.GreenCompensated, false)
      this.triggerVisibilityChange(GeometryType.GreenNominal, false)
    }

    this.triggerVisibilityChange(GeometryType.Bridging, makeVisible)
    this.manager.renderScene()
  }

  toggleMeshing(makeVisible: boolean) {
    this.toggleHandler(GeometryType.Meshing, makeVisible)
  }

  toggleCompensated(makeVisible: boolean) {
    this.toggleHandler(GeometryType.Compensated, makeVisible)
  }

  toggleGreenCompensated(makeVisible: boolean) {
    this.toggleHandler(GeometryType.GreenCompensated, makeVisible)
  }

  toggleGreenNominal(makeVisible: boolean) {
    this.toggleHandler(GeometryType.GreenNominal, makeVisible)
  }

  hideAll() {
    this.geometryHandlers.forEach((value: MeshHandler, key: GeometryType) => {
      if (value.visible) {
        value.visible = false
        this.triggerVisibilityChange(key, false)
      }
    })
  }

  clear() {
    this.geometryHandlers.forEach((value: MeshHandler, key: GeometryType) => {
      value.clear()
    })

    this.geometryHandlers.clear()
  }

  private toggleHandler(type: GeometryType, visible: boolean) {
    const handler = this.geometryHandlers.get(type)
    if (!handler) return

    const bridging = this.geometryHandlers.get(GeometryType.Bridging)
    this.showHideHandler(type, visible)
    if (visible && bridging && bridging.visible) {
      this.toggleBridgingElements(false)
    }

    this.triggerVisibilityChange(type, visible)
    this.manager.renderScene()
  }

  private showHideHandler(type: GeometryType, show: boolean) {
    const handler = this.geometryHandlers.get(type)
    if (!handler) return

    if (!handler.isReady && show) {
      handler.loadMesh(true)
    } else {
      handler.visible = show
      this.manager.sliceManager.adjustSlicingPlane()
    }
  }

  private handlerExists(type: GeometryType) {
    return this.geometryHandlers.has(type)
  }

  private setNominalGeometryPath(dir: IDataDirectoryStructure, metadata, isSinter: boolean, shrinkangeOn: boolean) {
    if (!dir) return

    const paths = this.getPathsForNominalGeometry(dir, isSinter)

    if (!this.handlerExists(GeometryType.Nominal)) {
      if (paths.nominal.length) {
        const settings = {
          metadata,
          type: GeometryType.Nominal,
          materialId: NOMINAL_GEOMETRY_MATERIAL,
          paths: paths.nominal,
        }
        const nominalHandler = new BatchGeometryHandler(this.manager, settings)
        nominalHandler.onHandlerReady = () => {
          this.manager.changeIsLoading.trigger({ isLoading: false })
        }

        this.geometryHandlers.set(GeometryType.Nominal, nominalHandler)
        this.triggerVisibilityChange(GeometryType.Nominal, false)
      } else {
        const args = { type: GeometryType.Nominal, visible: false, loaded: false, notAvailable: true }
        this.triggerEvent(RMEvent.AdditionalGeometryDetected, args)
      }
    }

    if (paths.buildPlate && !this.handlerExists(GeometryType.BuildPlate)) {
      const settings = { type: GeometryType.BuildPlate, materialId: BRIDGING_TRANSPARENT, paths: paths.buildPlate }
      const buildPlateGeometry = new MeshHandler(this.manager, settings)
      this.geometryHandlers.set(GeometryType.BuildPlate, buildPlateGeometry)
    }

    if (paths.greenNominal.length && shrinkangeOn && !this.handlerExists(GeometryType.GreenNominal)) {
      const settings = {
        metadata,
        type: GeometryType.GreenNominal,
        materialId: GREEN_NOMINAL_MATERIAL,
        paths: paths.greenNominal,
      }

      const greenNominal = new BatchGeometryHandler(this.manager, settings)
      this.geometryHandlers.set(GeometryType.GreenNominal, greenNominal)
      this.triggerVisibilityChange(GeometryType.GreenNominal, false)
    }
  }

  private checkBridgingElements(dir: IDataDirectoryStructure) {
    const bridgingFile = dir.files.find((f) => f.label.toLowerCase() === BRIDGING_ELEMENTS_FILE)
    const meshingFile = dir.files.find((f) => f.label.toLowerCase() === MESHING_FILE)
    const path = dir.path.slice(1).join('/')
    if (bridgingFile && !this.handlerExists(GeometryType.Bridging)) {
      const bridgingPath = `${path}/${bridgingFile.label}`
      const settings = { type: GeometryType.Bridging, materialId: BRIDGING_MATERIAL, paths: bridgingPath }
      const bridgingGeometry = new MeshHandler(this.manager, settings)
      this.geometryHandlers.set(GeometryType.Bridging, bridgingGeometry)
      this.triggerVisibilityChange(GeometryType.Bridging, false)
    }

    if (meshingFile && !this.handlerExists(GeometryType.Meshing)) {
      const meshingPath = `${path}/${meshingFile.label}`
      const meshingGeometry = new MeshingHandler(this.manager, meshingPath)
      this.geometryHandlers.set(GeometryType.Meshing, meshingGeometry)
      this.triggerVisibilityChange(GeometryType.Meshing, false)
    }
  }

  private checkCompensatedGeometry(dir: IDataDirectoryStructure, metadata, isSinter: boolean, shrinkangeOn: boolean) {
    const paths = this.getPathsForCompensatedGeometry(dir, isSinter)

    if (paths.compensated.length && !this.handlerExists(GeometryType.Compensated)) {
      const settings = {
        metadata,
        type: GeometryType.Compensated,
        materialId: COMPENSATED_MATERIAL,
        paths: paths.compensated,
      }

      const compensatedGeometry = new CompensatedMeshHandler(this.manager, settings, isSinter)
      this.geometryHandlers.set(GeometryType.Compensated, compensatedGeometry)
      this.triggerVisibilityChange(GeometryType.Compensated, false)
    }

    if (paths.compensatedGreen.length && shrinkangeOn && !this.handlerExists(GeometryType.GreenCompensated)) {
      const settings = {
        type: GeometryType.GreenCompensated,
        materialId: GREEN_COMPENSATED_MATERIAL,
        paths: paths.compensatedGreen,
      }

      const green = new CompensatedMeshHandler(this.manager, settings, true)
      this.geometryHandlers.set(GeometryType.GreenCompensated, green)
      this.triggerVisibilityChange(GeometryType.GreenCompensated, false)
    }
  }

  private triggerVisibilityChange(type: GeometryType, visible: boolean) {
    const geometry = this.geometryHandlers.get(type)
    if (!geometry) return

    this.triggerEvent(RMEvent.AdditionalGeometryDetected, { type, visible, loaded: geometry.isReady })
  }

  private getPathsForCompensatedGeometry(dir: IDataDirectoryStructure, isSinter: boolean) {
    const compensatedCallback = (extension) => {
      return (g) => {
        const lower = g.label.toLowerCase()
        return isSinter ? lower.endsWith(extension) && lower.indexOf(SINTER_SIZE) !== -1 : lower.endsWith(extension)
      }
    }

    let compensated = this.getFileNamesByPatternForCompensation(dir, compensatedCallback(DRC))
    if (!compensated.length) {
      compensated = this.getFileNamesByPatternForCompensation(dir, compensatedCallback(STL))
    }

    let compensatedGreen = []
    if (isSinter) {
      const greenCallback = (extension) => {
        return (g) => g.label.toLowerCase().endsWith(extension) && g.label.toLowerCase().indexOf(GREEN_SIZE) !== -1
      }

      compensatedGreen = this.getFileNamesByPatternForCompensation(dir, greenCallback(DRC))
      if (!compensatedGreen.length) {
        compensatedGreen = this.getFileNamesByPatternForCompensation(dir, greenCallback(STL))
      }
    }

    return { compensated, compensatedGreen }
  }

  private getPathsForNominalGeometry(dir: IDataDirectoryStructure, isSinter) {
    let greenNominal = []
    const nominalPattern = isSinter ? SINTER_GEOMETRY_PATTERN : DMLM_GEOMETRY_PATTERN
    const stlNominalPattern = isSinter ? STL_SINTER_GEOMETRY_PATTERN : STL_DMLM_GEOMETRY_PATTERN

    let nominal = this.getFileNamesByPattern(dir, (e) => nominalPattern.test(e.label.toLowerCase()))
    if (!nominal.length) {
      nominal = this.getFileNamesByPattern(dir, (e) => stlNominalPattern.test(e.label.toLowerCase()))
    }

    if (isSinter) {
      greenNominal = this.getFileNamesByPattern(dir, (e) => GREEN_GEOMETRY_PATTERN.test(e.label.toLowerCase()))
      if (!greenNominal.length) {
        greenNominal = this.getFileNamesByPattern(dir, (e) => STL_GREEN_GEOMETRY_PATTERN.test(e.label.toLowerCase()))
      }
    }

    let buildPlate = this.getFileNamesByPattern(dir, (e) => BUILD_PLATE_PATTERN.test(e.label.toLowerCase())).pop()
    if (!buildPlate) {
      buildPlate = this.getFileNamesByPattern(dir, (e) => STL_BUILD_PLATE_PATTERN.test(e.label.toLowerCase())).pop()
    }

    return { nominal, greenNominal, buildPlate }
  }

  private getFileNamesByPattern(dir: IDataDirectoryStructure, criteria) {
    const path = dir.path.slice(1).join('/')
    const foundFiles = []
    if (dir.groups.length > 0) {
      dir.groups.filter(criteria).forEach((g) => {
        g.files.forEach((f) => foundFiles.push(`${path}/${f.label}`))
      })
    }

    if (dir.files.length > 0) {
      const files = dir.files.filter(criteria)
      files.forEach((f) => foundFiles.push(`${path}/${f.label}`))
    }

    return foundFiles
  }

  private getFileNamesByPatternForCompensation(dir: IDataDirectoryStructure, criteria) {
    const path = dir.path.slice(1).join('/')
    const foundFiles = []
    if (dir.groups.length > 0) {
      dir.groups.filter(criteria).forEach((g) => {
        const names = g.files.map((f) => f.label)
        names.sort()
        foundFiles.push(`${path}/${names[names.length - 1]}`)
      })
    }

    if (dir.files.length > 0) {
      const files = dir.files.filter(criteria)
      files.forEach((f) => foundFiles.push(`${path}/${f.label}`))
    }

    return foundFiles
  }

  private triggerEvent(event: RMEvent, args) {
    this.manager.resultsEvent.trigger({ args, event })
  }
}
