export enum InsightsSeverity {
  Warning = 'warning',
  Error = 'error',
}

export interface IInsightsCount {
  errors: number
  warnings: number
}

export interface ILayoutInsightDetails {
  bpItemId: string
  meshId: string
  partName: string
}

export interface ILayoutIntersectingPartsDetails extends ILayoutInsightDetails {
  collidedWithBuildPlanItemId: string
  collideWithMeshId: string
  collideWithPartName: string
}
