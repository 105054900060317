import { SupportedFontStyles } from '@/types/Label/enums'

export interface FontStyleHelpBalloonInfo {
  top: number
  left: number
  show: boolean
  src: string
}

export interface FontStyleItem {
  fontName: FontNames
  src: string
  visibleName: FontVisibleNames
  fontStyle: SupportedFontStyles
}

export enum FontVisibleNames {
  LinotteSemiBold = 'Linotte – SemiBold',
  NotoSansSemiBold = 'Noto Sans – SemiBold',
  NotoSansBold = 'Noto Sans – Bold',
  NotoSansExtraBold = 'Noto Sans – ExtraBold',
  NotoSansBlack = 'Noto Sans – Black',
  VarelaRoundRegular = 'Varela Round – Regular',
}

export enum FontNames {
  NotoSans = 'Noto Sans',
  Linotte = 'Linotte',
  VarelaRound = 'Varela Round',
}
