import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import i18n from '@/plugins/i18n'
import StoresNamespaces from '@/store/namespaces'
import messageService from '@/services/messageService'
import {
  IBuildPlan,
  IBuildPlanItem,
  Visibility,
  IUpdateBuildPlanItemParamsDto,
  IPrintStrategyParameterSet,
  IDisplayToolbarState,
} from '@/types/BuildPlans/IBuildPlan'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'

const commonStore = namespace(StoresNamespaces.Common)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component
export default class PartPropertiesToolbarMixin extends Vue {
  @Prop({ default: false }) readonly isPrintOrder: boolean

  @commonStore.Getter tooltipOpenDelay: number

  @buildPlansStore.Getter getSelectedBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter getBuildPlanPrintStrategy: BuildPlanPrintStrategyDto
  @buildPlansStore.Getter parameterSets: IPrintStrategyParameterSet[]
  @buildPlansStore.Getter displayToolbarStateByVariantId: (buildPlanId: string) => IDisplayToolbarState
  @buildPlansStore.Getter isLockedForViewer: boolean
  @buildPlansStore.Action updateBuildPlanItem: (payload: {
    params: IUpdateBuildPlanItemParamsDto
    hideAPIErrorMessages?: boolean
  }) => Promise<void>
  @buildPlansStore.Action updateBuildPlanItems: (
    buildPlanItemsDtos: IUpdateBuildPlanItemParamsDto[],
  ) => Promise<IBuildPlanItem[]>

  @visualizationStore.Getter getVisualizationLoading: boolean
  @visualizationStore.Mutation setIsLoading: Function
  @visualizationStore.Action setBpItemVisibility: (payload: {
    bpItem: IBuildPlanItem
    makeVisible: boolean
    showAsTransparent: boolean
  }) => void

  /**
   * Change visibility of build plan items
   * @param makeVisible Set Visibility.Visible if true or Visibility.Hidden if false.
   * @param bpItems BpItems to change, if not specified - set visibility to all selected bpItems.
   */
  toggleVisibility(makeVisible: boolean, bpItems?: IBuildPlanItem[]) {
    if (this.getVisualizationLoading) return

    const selectedItems = bpItems ? bpItems : this.bpItemsToChangeVisibility(makeVisible)
    if (selectedItems.length === 0) return

    // Update build plan items on stage and change visualization (show/hide/show-as-transparent).
    const showAsTransparent = !makeVisible // Show part as transparent if hidden because it's selected.
    const updateStateOnly = this.isPrintOrder || this.isLockedForViewer
    if (updateStateOnly) {
      selectedItems.forEach((bpItem) => {
        this.setBpItemVisibility({ bpItem, makeVisible, showAsTransparent })
        bpItem.visibility = makeVisible ? Visibility.Visible : Visibility.Hidden
      })
    } else {
      const bpItemsDto: IUpdateBuildPlanItemParamsDto[] = selectedItems.map((bpItem) => {
        this.setBpItemVisibility({ bpItem, makeVisible, showAsTransparent })

        const buildPlanItemDto: IUpdateBuildPlanItemParamsDto = {
          buildPlanItemId: bpItem.id,
          visibility: makeVisible ? Visibility.Visible : Visibility.Hidden,
        }
        return buildPlanItemDto
      })

      // Update build plan items on server.
      this.updateBuildPlanItemsWithLoading(bpItemsDto)
    }
  }

  private async updateBuildPlanItemsWithLoading(bpItemsDto: IUpdateBuildPlanItemParamsDto[]) {
    const wasLoading = this.getVisualizationLoading
    this.setIsLoading(true)
    try {
      await this.updateBuildPlanItems(bpItemsDto)
    } catch (error) {
      const msg = i18n.t('partPropsToolbar.updatePartPropertiesFailed') as string
      messageService.showErrorMessage(`${msg} ${error.message}`)
    } finally {
      this.setIsLoading(wasLoading)
    }
  }

  /**
   * Returns selected bpItems that has visibility setting that should be changed.
   * @param makeVisible Visibility setting to apply for all selected parts.
   */
  private bpItemsToChangeVisibility(makeVisible: boolean): IBuildPlanItem[] {
    const selectedItems = this.getSelectedBuildPlanItems.filter((bpItem) => {
      // Filter items that should be changed:
      return (
        (bpItem.visibility === Visibility.Visible && !makeVisible) || // totally visible but make hidden,
        (bpItem.visibility === Visibility.Hidden && makeVisible) || // totally hidden but make visible,
        (bpItem.hiddenBodies && bpItem.hiddenBodies.length) // partially visible.
      )
    })

    return selectedItems
  }
}
