/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { IClearance, Clearance, ClearanceTypes, DimensionLineContainer } from '@/visualization/types/ClearanceTypes'
import { AbstractMesh, Plane, Scene, TransformNode, Vector3 } from '@babylonjs/core'
import { RenderScene } from '@/visualization/render-scene'
import {
  IDENTITY_MATRIX,
  CLEARANCE_RUBBERBAND_COLOR,
  CLEARANCE_SCALE_DIVIDER,
  PART_BODY_ID_DELIMITER,
  CLEARANCE_LEGACY_PART_WARNING_TIMEOUT,
  CLEARANCE_LINE,
} from '@/constants'
import { ClearanceManager } from '@/visualization/rendering/clearance/ClearanceManager'
import store from '@/store'
import { BVHTreeNode } from '@/visualization/models/BVHTreeNode'
import { SelectionManager } from '@/visualization/rendering/SelectionManager'
import { IPartMetadata } from '@/visualization/types/SceneItemMetadata'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import i18n from '@/plugins/i18n'
import messageService from '@/services/messageService'

export class ClearanceMeshToMesh implements IClearance {
  private renderScene: RenderScene
  private clearanceManager: ClearanceManager
  private meshManager: MeshManager
  private selectionManager: SelectionManager
  private scene: Scene
  private plane: Plane

  private rubberBand: DimensionLineContainer
  private firstPickedObject: TransformNode
  private clearances: Map<string, Clearance> = new Map<string, Clearance>()

  constructor(renderScene: RenderScene) {
    this.renderScene = renderScene
    this.scene = this.renderScene.getScene()

    this.clearanceManager = renderScene.getClearanceManager()
    this.selectionManager = renderScene.getSelectionManager()
    this.meshManager = renderScene.getMeshManager()

    this.rubberBand = new DimensionLineContainer(
      null,
      this.clearanceManager,
      `${CLEARANCE_LINE}_source`,
      `${CLEARANCE_LINE}_outline_source`,
    )
    this.rubberBand.color = CLEARANCE_RUBBERBAND_COLOR
    this.changeRubberBandVisibility(false)
  }

  public getClearances(): Map<string, Clearance> {
    return this.clearances
  }

  public measureDistance(payload: { meshA: TransformNode; meshB: TransformNode }) {
    const { meshA, meshB } = payload
    const obbTree = this.renderScene.getObbTree()
    const meshManager = this.renderScene.getMeshManager()

    let from: ClearanceTypes
    let parentMeshA: TransformNode
    let rootsA: BVHTreeNode[]
    let bodyIdA: string

    let to: ClearanceTypes
    let parentMeshB: TransformNode
    let rootsB: BVHTreeNode[]
    let bodyIdB: string

    // Check if involved meshes ara not:
    // * Same part meshes
    // * Same body meshes
    // * MeshA is not component of part MeshB
    // * MeshB is not component of part MeshA
    // * MeshA and MeshB is not components of same parent mesh
    if (
      meshManager.isSamePartMesh(meshA, meshB) ||
      meshManager.isSameComponentMesh(meshA, meshB) ||
      meshManager.isComponentOfPart(meshA, meshB) ||
      meshManager.isComponentOfPart(meshB, meshA) ||
      meshManager.isComponentsOfSamePart(meshA, meshB)
    ) {
      return
    }

    if (meshManager.isPartMesh(meshA)) {
      parentMeshA = meshA
      const bvh = parentMeshA.metadata.bvh
      rootsA = bvh instanceof BVHTreeNode ? [bvh] : bvh
      from = ClearanceTypes.Parts
    } else if (meshManager.isComponentMesh(meshA)) {
      parentMeshA = meshA.parent as TransformNode
      const bvh = parentMeshA.metadata.bvh
      rootsA = bvh instanceof BVHTreeNode ? [bvh] : bvh
      const componentIdA = meshA.metadata.componentId
      const geometryIdA = meshA.metadata.geometryId
      bodyIdA = `${componentIdA}${PART_BODY_ID_DELIMITER}${geometryIdA}`
      from = ClearanceTypes.Bodies
    } else {
      return
    }

    if (meshManager.isPartMesh(meshB)) {
      parentMeshB = meshB
      const bvh = parentMeshB.metadata.bvh
      rootsB = bvh instanceof BVHTreeNode ? [bvh] : bvh
      to = ClearanceTypes.Parts
    } else if (meshManager.isComponentMesh(meshB)) {
      parentMeshB = meshB.parent as TransformNode
      const bvh = parentMeshB.metadata.bvh
      rootsB = bvh instanceof BVHTreeNode ? [bvh] : bvh
      const componentIdB = meshB.metadata.componentId
      const geometryIdB = meshB.metadata.geometryId
      bodyIdB = `${componentIdB}${PART_BODY_ID_DELIMITER}${geometryIdB}`
      to = ClearanceTypes.Bodies
    } else {
      return
    }

    const clearanceResult = obbTree.rssDistance(rootsA, rootsB, parentMeshA, parentMeshB, {
      bodyIdA,
      bodyIdB,
      checkEveryLeafNode: true,
    })

    let clearance: Clearance = null
    for (const item of this.clearances.values()) {
      const isFromMeshAtoMeshBClearance =
        item.from.bpItemId === clearanceResult.bpItemIdA &&
        item.from.geometryId === clearanceResult.geometryIdA &&
        item.from.componentId === clearanceResult.componentIdA &&
        item.to.bpItemId === clearanceResult.bpItemIdB &&
        item.to.geometryId === clearanceResult.geometryIdB &&
        item.to.componentId === clearanceResult.componentIdB

      const isFromMeshBtoMeshAClearance =
        item.from.bpItemId === clearanceResult.bpItemIdB &&
        item.from.geometryId === clearanceResult.geometryIdB &&
        item.from.componentId === clearanceResult.componentIdB &&
        item.to.bpItemId === clearanceResult.bpItemIdA &&
        item.to.geometryId === clearanceResult.geometryIdA &&
        item.to.componentId === clearanceResult.componentIdA

      if (isFromMeshAtoMeshBClearance || isFromMeshBtoMeshAClearance) {
        clearance = item
        break
      }
    }

    if (!clearance) {
      clearance = new Clearance(this.clearanceManager, clearanceResult, from, to)
      this.clearances.set(clearance.id, clearance)
    } else {
      clearance.updateClearance(clearanceResult, from, to)
    }

    this.clearanceManager.getRenderScene.animate()
    this.clearanceManager.updateDimensionBoxPosition()
  }

  public hideClearances(hide: boolean, predicate?: (clearance: Clearance) => boolean) {
    this.clearances.forEach((clearance) => {
      if (!predicate || predicate(clearance)) {
        clearance.isHidden = hide
      }
    })
  }

  public clearClearances(predicate?: (clearance: Clearance) => boolean) {
    for (const [clearanceId, clearance] of this.clearances) {
      if (!predicate || predicate(clearance)) {
        clearance.dispose()
        this.clearances.delete(clearanceId)
      }
    }
  }

  public dispose() {
    this.rubberBand.dispose()
    this.clearClearances()
  }

  public updatePlaneNormal(normal: Vector3) {
    if (this.plane) {
      this.plane.normal = normal.clone()
    }
  }

  public changeRubberBandVisibility(isVisible: boolean) {
    this.rubberBand.isVisible = isVisible
    this.renderScene.animate(true)
  }

  public createRubberBand(pointerX: number, pointerY: number) {
    const pickedInfo = this.pickMesh(pointerX, pointerY)
    if (!pickedInfo) {
      store.commit('visualizationModule/showRubberBand', { isShown: false })
      return
    }

    const direction = pickedInfo.pickingRayDirection
    const rubberBandOrigin = pickedInfo.pickedPoint
    this.plane = Plane.FromPositionAndNormal(rubberBandOrigin, direction)

    const activeCamera = this.renderScene.getActiveCamera()
    const scaleRatio = (activeCamera.orthoTop - activeCamera.orthoBottom) / CLEARANCE_SCALE_DIVIDER
    this.updateRubberBandScaleFactor(scaleRatio)
    this.rubberBand.update(rubberBandOrigin, rubberBandOrigin)

    const { bvh } = pickedInfo.part.metadata as IPartMetadata
    const obbTree = this.renderScene.getObbTree()
    if (obbTree.isBVHTreeWithoutTriangleIds(bvh)) {
      messageService.showWarningMessage(
        i18n.t('clearanceTool.legacyPartWarning').toString(),
        CLEARANCE_LEGACY_PART_WARNING_TIMEOUT,
      )
      store.commit('visualizationModule/showRubberBand', { isShown: false })
      return
    }

    const isClearanceFromEnabled = store.getters['visualizationModule/isClearanceFromEnabled']
    if (isClearanceFromEnabled(ClearanceTypes.Parts)) {
      this.firstPickedObject = pickedInfo.part
    } else if (isClearanceFromEnabled(ClearanceTypes.Bodies)) {
      this.firstPickedObject = pickedInfo.body
    }

    this.selectionManager.select([pickedInfo], false, true)
    store.commit('visualizationModule/showRubberBand', { isShown: true })
  }

  public updateRubberBand(pointerX: number, pointerY: number) {
    if (!this.firstPickedObject) {
      return
    }

    const pickingRay = this.scene.createPickingRay(
      pointerX,
      pointerY,
      IDENTITY_MATRIX,
      this.renderScene.getActiveCamera(),
    )

    const distance = pickingRay.intersectsPlane(this.plane)
    const intersectionPoint = pickingRay.origin.add(pickingRay.direction.scale(distance))
    this.rubberBand.setNewEnd(intersectionPoint)
  }

  public replaceRubberBand(pointerX: number, pointerY: number) {
    const pickedInfo = this.pickMesh(pointerX, pointerY)
    if (!pickedInfo) {
      return
    }

    this.rubberBand.setNewEnd(pickedInfo.pickedPoint)

    let secondPickedObject: TransformNode = null
    const { buildPlanItemId, bvh } = pickedInfo.part.metadata as IPartMetadata
    const obbTree = this.renderScene.getObbTree()
    if (obbTree.isBVHTreeWithoutTriangleIds(bvh)) {
      messageService.showWarningMessage(
        i18n.t('clearanceTool.legacyPartWarning').toString(),
        CLEARANCE_LEGACY_PART_WARNING_TIMEOUT,
      )
      return
    }

    const isClearanceToEnabled = store.getters['visualizationModule/isClearanceToEnabled']
    if (isClearanceToEnabled(ClearanceTypes.Parts)) {
      secondPickedObject = pickedInfo.part
    } else if (isClearanceToEnabled(ClearanceTypes.Bodies)) {
      secondPickedObject = pickedInfo.body
    }

    this.measureDistance({ meshA: this.firstPickedObject, meshB: secondPickedObject })
    this.renderScene.getSelectionManager().deselect()
    store.commit('visualizationModule/toggleHighlight', { buildPlanItemId, highlight: false })
    store.commit('visualizationModule/showRubberBand', { isShown: false })
  }

  public updateRubberBandScaleFactor(scale: number) {
    this.rubberBand.scale = scale
  }

  /**
   * Returns picking metadata, if there was a hit
   * @param pointerX X coordinate of pointer
   * @param pointerY Y coordinate of pointer
   * @returns Object that contains picked body, face, part, point and picking ray direction if there was hit
   *          Otherwise returns null
   */
  private pickMesh(pointerX: number, pointerY: number) {
    const pickingRay = this.scene.createPickingRay(
      pointerX,
      pointerY,
      IDENTITY_MATRIX,
      this.renderScene.getActiveCamera(),
    )
    const gpuPickInfo = this.renderScene.getGpuPicker().pick(pointerX, pointerY)
    const pickPredicate = (m: AbstractMesh) =>
      this.meshManager.isComponentMesh(m) &&
      gpuPickInfo &&
      gpuPickInfo.part &&
      this.meshManager.getBuildPlanItemMeshByChild(m).id === gpuPickInfo.part.id

    const pickInfo = this.scene.pickWithRay(pickingRay, pickPredicate)
    if (!pickInfo.hit) {
      return null
    }

    return {
      ...gpuPickInfo,
      pickedPoint: pickInfo.pickedPoint.clone(),
      pickingRayDirection: pickingRay.direction.clone(),
    }
  }
}
