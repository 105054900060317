
import '@/assets/styles/home.scss'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { EditViewMode, MachineTypeDto, UpdateMachineTypeDto } from '@/types/Sites/Site'
import SiteConstantUpdater from '@/components/layout/admin/sites/SiteConstantUpdater.vue'
import { Prop } from 'vue-property-decorator'
import { SiteAdminMixin } from '@/components/layout/admin/sites/mixins/SiteAdminMixin'
import { RouterNames } from '@/router'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component({
  components: {
    SiteConstantUpdater,
  },
})
export default class MachineTypeListRow extends SiteAdminMixin {
  @sitesStore.Action updateMachineType: (params: {
    machineTypeId: number
    updateMachineTypeDto: UpdateMachineTypeDto
  }) => Promise<MachineTypeDto>

  @Prop({ default: null }) readonly item: MachineTypeDto

  onCloseEditMode() {
    this.editMode = EditViewMode.None
  }

  async onUpdateSiteConstants(id, updateMachineTypeDto: UpdateMachineTypeDto) {
    this.editMode = EditViewMode.None
    await this.updateMachineType({ updateMachineTypeDto, machineTypeId: id })
  }

  navigateToMachineType(machineTypeId: string) {
    const route = { name: RouterNames.EditMachineTypeAdmin, params: { machineTypeId } }

    // @ts-ignore
    this.$router.safePush(route)
  }

  getItemBuildVolume(item: MachineTypeDto) {
    const { height, width, length, measurement } = item.buildVolume
    return `${height}x${width}x${length} ${measurement}`
  }
}
