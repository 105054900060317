
import Vue from 'vue'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import SearchField from '@/components/controls/Common/SearchField.vue'
import NavigationPanel from '@/components/layout/FileExplorer/NavigationPanel.vue'

import StoresNamespaces from '@/store/namespaces'
import { RouterNames } from '@/router'

import { debounce } from '@/utils/debounce'
import { IUser } from '@/types/User/IUser'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ROOT_FOLDER_ID } from '@/constants'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const userStore = namespace(StoresNamespaces.User)

@Component({
  components: {
    SearchField,
    NavigationPanel,
  },
})
export default class FileExplorer extends Vue {
  @fileExplorerStore.Action fetchFavoriteItemsIds: () => Promise<void>
  @fileExplorerStore.Action getTableState: Function
  @fileExplorerStore.Action setDefaultTableState: Function

  @fileExplorerStore.Mutation setSearchParameters: (searchParameters: string) => void
  @fileExplorerStore.Mutation setRoot: (item: FileExplorerItem) => void

  @fileExplorerStore.Getter('isLoading') isLoading: () => boolean
  @fileExplorerStore.Getter('isPrintOrderDetailsLoading') isPrintOrderDetailsLoading: () => boolean

  @userStore.Action fetchAllUsers: () => Promise<IUser[]>

  fetchSearchResultsByValueDebounced = debounce(1000, this.fetchSearchResultsByValue)

  $refs!: {
    searchField: SearchField
  }

  get searchQuery() {
    if (this.$route.name !== RouterNames.FE_Search) {
      return ''
    }

    return this.$route.params.query
  }

  async beforeMount() {
    await Promise.all([this.fetchFavoriteItemsIds(), this.fetchAllUsers()])

    if (!(await this.getTableState())) {
      this.setDefaultTableState()
    }
  }

  @Watch('$route')
  onRouteChanged(val) {
    if (val && val.name !== RouterNames.FE_Search) this.$refs.searchField.resetSearchValue()
  }

  fetchSearchResultsByValue(value) {
    const trimmedValue: string | null | undefined = value.trim()

    if (!trimmedValue) {
      this.setRoot(null)
      // @ts-ignore
      this.$router.safePush({ name: RouterNames.FE_AllFiles, params: { itemId: ROOT_FOLDER_ID } })
      return
    }

    // @ts-ignore
    this.$router.safePush({ name: RouterNames.FE_Search, params: { query: trimmedValue } })

    this.setSearchParameters(trimmedValue)
  }
}
