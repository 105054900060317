import { CustomGizmo } from './CustomGizmo'
import { RenderScene } from '../render-scene'
import { IActiveToggle } from '../infrastructure/IActiveToggle'
import { AbstractMesh } from '@babylonjs/core/Meshes'
import { BoundingBox } from '@babylonjs/core/Culling/boundingBox'
import { Quaternion } from '@babylonjs/core/Maths'
import { CrossSectionManager } from './CrossSectionManager'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { GROUND_BOX_NAME, SINTER_PLATE_NAME } from '@/constants'
import { SceneMode } from '@/visualization/types/SceneTypes'
import { RotationGizmo } from '@babylonjs/core/Gizmos/rotationGizmo'
import { PositionGizmo } from '@babylonjs/core/Gizmos/positionGizmo'

export class CrossSectionGizmo extends CustomGizmo {
  private crossSectionManager: CrossSectionManager
  private limits: BoundingBox
  private SNAP_ANGLE = Math.PI / 4

  constructor(renderScene: RenderScene, crossSectionManager: CrossSectionManager, dragListeners?: IActiveToggle[]) {
    super(renderScene, dragListeners)
    this.meshManager = renderScene.getMeshManager()
    this.crossSectionManager = crossSectionManager
  }

  show(selected: AbstractMesh) {
    if (!this.isVisible || !selected) {
      return
    }

    const activeMesh = selected
    selected.computeWorldMatrix()

    this.gizmoManager.usePointerToAttachGizmos = false
    this.gizmoManager.rotationGizmoEnabled = true
    this.gizmoManager.positionGizmoEnabled = true
    const rotationGizmo = this.gizmoManager.gizmos.rotationGizmo as RotationGizmo
    rotationGizmo.scaleRatio = this.computeGizmoScaleRatio()
    const positionGizmo = this.gizmoManager.gizmos.positionGizmo as PositionGizmo
    positionGizmo.planarGizmoEnabled = false
    positionGizmo.xGizmo.isEnabled = false
    positionGizmo.yGizmo.isEnabled = false
    positionGizmo.scaleRatio = this.computeGizmoScaleRatio()

    this.setCustomRotationGizmo(rotationGizmo)
    this.setCustomDragGizmo(positionGizmo)
    this.gizmoManager.attachToMesh(selected)
    this.updateGizmoScale()

    // drag start events
    let lastPointerUp
    this.gizmoManager.gizmos.positionGizmo.onDragStartObservable.add(() => {
      // double-click handling
      const thisPointerUp = new Date().getTime()
      if (lastPointerUp && thisPointerUp - lastPointerUp < 500) {
        const attachedMesh = this.gizmoManager.gizmos.positionGizmo.zGizmo.attachedMesh
        const euler = attachedMesh.rotationQuaternion.toEulerAngles()
        const newEulerX = euler.x + Math.PI
        const newPlaneQuaternion = Quaternion.FromEulerAngles(newEulerX, euler.y, euler.z)
        attachedMesh.rotationQuaternion = newPlaneQuaternion
        attachedMesh.computeWorldMatrix()
      }
      lastPointerUp = thisPointerUp
      this.isInDraggingState = true
      this.setMaterialOpacity(this.movementOpacity)
      this.dragListeners.map((listener) => listener.deactivate())
    })
    this.gizmoManager.gizmos.rotationGizmo.onDragStartObservable.add(() => {
      this.isInDraggingState = true
      this.setMaterialOpacity(this.movementOpacity)
      this.dragListeners.map((listener) => listener.deactivate())
    })

    // drag end events
    this.gizmoManager.gizmos.positionGizmo.onDragEndObservable.add(() => {
      this.dragEnd()
    })
    this.gizmoManager.gizmos.rotationGizmo.onDragEndObservable.add(() => {
      this.dragEnd()
    })

    // limits
    this.gizmoManager.gizmos.positionGizmo.zGizmo.dragBehavior.onDragObservable.add((eventData) => {
      const axisPositionGizmo = this.gizmoManager.gizmos.positionGizmo.zGizmo
      const attachedMesh = axisPositionGizmo.attachedMesh
      // keep cross section always above the build plate
      const isSinterPlan = this.renderScene.buildPlanType === ItemSubType.SinterPlan
      const isPreview = this.renderScene.getSceneMode() === SceneMode.PreviewPart
      const plateMeshName = isSinterPlan || isPreview ? SINTER_PLATE_NAME : GROUND_BOX_NAME
      const plateMesh = this.scene.getMeshByName(plateMeshName)
      if (attachedMesh && attachedMesh.position.z < plateMesh.position.z) {
        attachedMesh.position.z = plateMesh.position.z
      }
      if (this.renderScene.shiftKey && axisPositionGizmo.snapDistance === 0) {
        axisPositionGizmo.attachedMesh = null
        const buildBBox = this.renderScene.getBoundingBoxDetails()
        const halfBuildHeight = buildBBox.maxZ / 2
        const newZPosition = this.computeNewZPosition(attachedMesh.position.z, halfBuildHeight)
        attachedMesh.position.z = newZPosition - 1
        attachedMesh.computeWorldMatrix()
        axisPositionGizmo.snapDistance = halfBuildHeight
        axisPositionGizmo.attachedMesh = attachedMesh
      } else if (!this.renderScene.shiftKey && axisPositionGizmo.snapDistance !== 0) {
        axisPositionGizmo.snapDistance = 0
      }
    })
    this.gizmoManager.gizmos.rotationGizmo.xGizmo.dragBehavior.onDragObservable.add((eventData) => {
      const planeRotationGizmo = this.gizmoManager.gizmos.rotationGizmo.xGizmo
      const attachedMesh = planeRotationGizmo.attachedMesh
      if (this.renderScene.shiftKey && planeRotationGizmo.snapDistance === 0) {
        planeRotationGizmo.isEnabled = false
        const euler = attachedMesh.rotationQuaternion.toEulerAngles()
        const newEulerX = this.computeNewEulerAngle(euler.x)
        const newPlaneQuaternion = Quaternion.FromEulerAngles(newEulerX, euler.y, euler.z)
        attachedMesh.rotationQuaternion = newPlaneQuaternion
        attachedMesh.computeWorldMatrix()
        planeRotationGizmo.snapDistance = this.SNAP_ANGLE
        planeRotationGizmo.isEnabled = true
      } else if (!this.renderScene.shiftKey && planeRotationGizmo.snapDistance !== 0) {
        planeRotationGizmo.snapDistance = 0
      }
    })
    this.gizmoManager.gizmos.rotationGizmo.yGizmo.dragBehavior.onDragObservable.add((eventData) => {
      const planeRotationGizmo = this.gizmoManager.gizmos.rotationGizmo.yGizmo
      const attachedMesh = planeRotationGizmo.attachedMesh
      if (this.renderScene.shiftKey && planeRotationGizmo.snapDistance === 0) {
        planeRotationGizmo.isEnabled = false
        const euler = attachedMesh.rotationQuaternion.toEulerAngles()
        const newEulerY = this.computeNewEulerAngle(euler.y)
        const newPlaneQuaternion = Quaternion.FromEulerAngles(euler.x, newEulerY, euler.z)
        attachedMesh.rotationQuaternion = newPlaneQuaternion
        attachedMesh.computeWorldMatrix()
        planeRotationGizmo.snapDistance = this.SNAP_ANGLE
        planeRotationGizmo.isEnabled = true
      } else if (!this.renderScene.shiftKey && planeRotationGizmo.snapDistance !== 0) {
        planeRotationGizmo.snapDistance = 0
      }
    })
    this.gizmoManager.gizmos.rotationGizmo.zGizmo.dragBehavior.onDragObservable.add((eventData) => {
      const planeRotationGizmo = this.gizmoManager.gizmos.rotationGizmo.zGizmo
      const attachedMesh = planeRotationGizmo.attachedMesh
      if (this.renderScene.shiftKey && planeRotationGizmo.snapDistance === 0) {
        planeRotationGizmo.isEnabled = false
        const euler = attachedMesh.rotationQuaternion.toEulerAngles()
        const newEulerZ = this.computeNewEulerAngle(euler.z)
        const newPlaneQuaternion = Quaternion.FromEulerAngles(euler.x, euler.y, newEulerZ)
        attachedMesh.rotationQuaternion = newPlaneQuaternion
        attachedMesh.computeWorldMatrix()
        planeRotationGizmo.snapDistance = this.SNAP_ANGLE
        planeRotationGizmo.isEnabled = true
      } else if (!this.renderScene.shiftKey && planeRotationGizmo.snapDistance !== 0) {
        planeRotationGizmo.snapDistance = 0
      }
    })
  }

  hide(silent?: boolean) {
    this.clearRotationGizmosEvents()
    this.clearPositionGizmosEvents()
    this.disableRotationGizmos()
    this.disableTranslationGizmos()
  }

  setGizmoLimits(boundingBox: BoundingBox) {
    this.limits = boundingBox
  }

  dispose() {
    super.dispose()
  }

  protected computeGizmoScaleRatio() {
    const camera = this.renderScene.getActiveCamera()
    return (camera.orthoRight - camera.orthoLeft) / 800
  }

  private computeNewZPosition(currentZPosition, snapValue) {
    const remainder = Math.abs(currentZPosition) % snapValue
    let result
    if (remainder <= snapValue) {
      result = Math.floor(Math.abs(currentZPosition) / snapValue) * snapValue * Math.sign(currentZPosition)
    } else {
      result = Math.ceil(Math.abs(currentZPosition) / snapValue) * snapValue * Math.sign(currentZPosition)
    }
    return result
  }

  private computeNewEulerAngle(euler) {
    const remainder = Math.abs(euler) % this.SNAP_ANGLE
    let result
    if (remainder <= this.SNAP_ANGLE / 2) {
      result = Math.floor(Math.abs(euler) / this.SNAP_ANGLE) * this.SNAP_ANGLE * Math.sign(euler)
    } else {
      result = Math.ceil(Math.abs(euler) / this.SNAP_ANGLE) * this.SNAP_ANGLE * Math.sign(euler)
    }
    return result
  }

  private async dragEnd() {
    this.isInDraggingState = false
    this.setMaterialOpacity(this.regularOpacity)

    this.crossSectionManager.saveCrossSection()

    this.dragListeners.map((listener) => listener.activate())
  }
}
