import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import buildPlans from '@/api/buildPlans'
import fileExplorerApi from '@/api/fileExplorer'
import DetailsPanelMixin from '@/components/layout/FileExplorer/Details/DetailsPanelMixin'
import { DEFAULT_IS_ALLOWED_TO_BE_TRASHED_SELECTED_ITEMS, ROOT_FOLDER_ID } from '@/constants'
import { RouterNames } from '@/router'
import messageService from '@/services/messageService'
import { ItemDetailsPayload } from '@/store/modules/fileExplorer/types'
import StoresNamespaces from '@/store/namespaces'
import { IBuildPlan, IBuildPlanLockDto, ILoadingItemsData, IVariantItem } from '@/types/BuildPlans/IBuildPlan'
import { CanBeTrashedResult } from '@/types/FileExplorer/CanBeTrashed'
import { FileExplorerItem, RenameItemPayload } from '@/types/FileExplorer/FileExplorerItem'
import { ItemAction } from '@/types/FileExplorer/ItemAction'
import { ItemDetailsDto } from '@/types/FileExplorer/ItemDetails'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import { DetailsPanelViewMode, ViewMode } from '@/types/FileExplorer/ViewMode'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { IUser } from '@/types/User/IUser'
import { isItemLockedForUser } from '@/utils/fileExplorerItem/fileExplorerItemUtils'
import { extractNumberFromString } from '@/utils/number'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const userStore = namespace(StoresNamespaces.User)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

const PROGRESS_MODAL_OVERLAY_DEFAULT_TOP_OFFSET = '103px'

@Component
export default class ModalsStateMixin extends Mixins(DetailsPanelMixin) {
  get isShownMoveToTrashErrorModal(): boolean {
    return this.getMoveToTrashErrorDialogState
  }

  get isShownMoveToTrashItemModal(): boolean {
    return this.getMoveToTrashItemDialogState
  }

  get getMoveToTrashItems() {
    return this.getSelectedItems.length ? this.getSelectedItems : this.getRootItem ? [this.getRootItem] : []
  }

  get getMoveToTrashRelatedItems() {
    return this.getSelectedItemsCanBeTrashed.relatedItems
  }

  get previewVariantForVariantIsLockedModal(): IBuildPlanLockDto {
    return this.previewVariant
  }

  get editVariantForVariantIsLockedModal(): IBuildPlanLockDto {
    return this.editVariant
  }

  get isTrashSelectedItemsEnabled() {
    return this.getSelectedItems.some((item) => item.grantedPermissions && item.grantedPermissions.itemTrash)
  }

  get isBuildPlanPage() {
    return this.$router.currentRoute.matched.some((route) => route.name === RouterNames.EditBuildPlan)
  }

  get isIbcPlanPage() {
    return this.$router.currentRoute.matched.some((route) => route.name === RouterNames.FE_EditIbcPlan)
  }
  @fileExplorerStore.Action updateItem: (item: Partial<FileExplorerItem>) => Promise<FileExplorerItem>
  @fileExplorerStore.Action setIsRemovedSelected: Function
  @fileExplorerStore.Action setIsRemovedItem: Function
  @fileExplorerStore.Action openItemFolder: (itemId: string) => Promise<void>
  @fileExplorerStore.Action switchBuildPlanActiveVersion: (payload: {
    newVersionId: string
    oldVersionId: string
  }) => Promise<void>
  @fileExplorerStore.Action fetchItemsInFolder: (payload: {
    folderId: string
    abortIfRootChanged: boolean
  }) => Promise<FileExplorerItem[]>
  @fileExplorerStore.Action fetchItemById: (itemId: string) => Promise<FileExplorerItem>
  @fileExplorerStore.Action fetchItemDetails: (payload: ItemDetailsPayload) => Promise<ItemDetailsDto>
  @fileExplorerStore.Action setLastAction: (payload: { itemId: string; action: ItemAction }) => Promise<void>
  @fileExplorerStore.Action getItemPermissions: (itemId: string) => Promise<void>
  @fileExplorerStore.Action fetchIBCPlan: (ibcPlanId: string) => Promise<IIBCPlan>

  @fileExplorerStore.Getter isError: boolean
  @fileExplorerStore.Getter isLoading: boolean

  @fileExplorerStore.Getter getErrorText: string
  @fileExplorerStore.Getter getSelectedItems: FileExplorerItem[]
  @fileExplorerStore.Getter getSelectedItem: FileExplorerItem
  @fileExplorerStore.Getter getRootItem: FileExplorerItem
  @fileExplorerStore.Getter getSelectedItemsCanBeTrashed: CanBeTrashedResult
  @fileExplorerStore.Getter('list') getFileExplorerItems: FileExplorerItem[]
  @fileExplorerStore.Getter find: (itemId: string) => FileExplorerItem
  @fileExplorerStore.Getter isCanBeTrashedStatusSuccess: () => boolean
  @fileExplorerStore.Getter getMoveToTrashErrorDialogState: boolean
  @fileExplorerStore.Getter getMoveToTrashItemDialogState: boolean

  @fileExplorerStore.Mutation addItem: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation unselectItem: (payload: {
    item: FileExplorerItem | PrintOrder
    selectionType: SelectionTypes
  }) => void
  @fileExplorerStore.Mutation setRenameDialogState: (value: boolean) => void
  @fileExplorerStore.Mutation setMoveToTrashErrorDialogState: (value: boolean) => void
  @fileExplorerStore.Mutation setMoveToTrashItemDialogState: (value: boolean) => void
  @fileExplorerStore.Mutation setSelectedItemsCanBeTrashed: (value: CanBeTrashedResult) => void
  @fileExplorerStore.Mutation setClickedNameItem: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation setViewMode: (mode: ViewMode) => void
  @fileExplorerStore.Mutation setRoot: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation setDetailsPanelMode: (mode: DetailsPanelViewMode) => void

  @buildPlansStore.Action lockBuildPlanVariant: (id?: string) => Promise<void>
  @buildPlansStore.Action unlockBuildPlanVariant: (id?: string) => Promise<void>
  @buildPlansStore.Action getBuildPlanLockInfo: (id: string) => Promise<IBuildPlanLockDto>
  @buildPlansStore.Action updateVariantsLockInfo: () => Promise<void>
  @buildPlansStore.Action getBuildPlanById: (id: string) => Promise<IBuildPlan>

  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getBuildPlanVariants: IVariantItem[]
  @buildPlansStore.Getter isReadOnly: boolean
  @buildPlansStore.Getter('getIBCPlan') ibcPlan: IIBCPlan
  @buildPlansStore.Getter getLoadingItemsData: ILoadingItemsData

  @buildPlansStore.Mutation setBuildPlan: Function
  @buildPlansStore.Mutation setIBCPlan: Function
  @buildPlansStore.Mutation setLoadingItemsData: Function

  @userStore.Getter getUserDetails: IUser

  isShownVariantIsLockedModal: boolean = false
  previewVariant: IBuildPlanLockDto = null
  editVariant: IBuildPlanLockDto = null
  isShownMoveErrorModal: boolean = false
  isShownShareItemModal: boolean = false
  isShownMoveOrCopyModal: boolean = false
  isShowProgressModal: boolean = false
  progressModalTitle: string = ''
  progressModalTemplate: string = ''

  isDestroyed: boolean = false

  get progressModalOverlayTopOffset(): string {
    const computedStyle = getComputedStyle(document.documentElement)
    const appHeaderHeight = extractNumberFromString(computedStyle.getPropertyValue('--application-header-height'))
    const bpHeaderHeight = extractNumberFromString(computedStyle.getPropertyValue('--bp-header-height'))

    if (appHeaderHeight !== null && bpHeaderHeight !== null) {
      return `${appHeaderHeight + bpHeaderHeight}px`
    }

    return PROGRESS_MODAL_OVERLAY_DEFAULT_TOP_OFFSET
  }

  beforeDestroy() {
    this.isDestroyed = true
  }

  toggleVariantIsLockedModal(isVisible: boolean = true) {
    this.isShownVariantIsLockedModal = isVisible
  }

  toggleShareItemModal(isVisible: boolean = true) {
    this.isShownShareItemModal = isVisible
  }

  toggleRenameItemModal(isVisible: boolean = true) {
    if (!this.isLoading) {
      this.setRenameDialogState(isVisible)
    }
  }

  toggleMoveToTrashModal(isVisible: boolean = true) {
    this.setMoveToTrashItemDialogState(isVisible)
  }

  toggleMoveErrorModal(isVisible: boolean = true) {
    this.isShownMoveErrorModal = isVisible
  }

  /**
   * Set Move to trash error modal open/close
   * also open Move to trash modal when closing Error modal and still having selected items
   * (happens when initally selecting both items that can and cannot be trashed)
   * @param isVisible - visibilty of error modal (default true)
   * @param canOpenTrashModal - is opening trash modal on error modal close allowed (default true)
   */
  toggleMoveToTrashErrorModal(isVisible: boolean = true, canOpenTrashModal = true) {
    this.setMoveToTrashErrorDialogState(isVisible)

    if (!isVisible && this.getSelectedItems.length && canOpenTrashModal) {
      this.setMoveToTrashItemDialogState(true)
    }
  }

  toggleMoveOrCopyModal(isVisible: boolean = true) {
    this.isShownMoveOrCopyModal = isVisible
  }

  onMenuItemClick(props) {
    props.clickHandler()
  }

  setLoadingItemsTitle() {
    const isSinterPlan = this.getBuildPlan.subType === ItemSubType.SinterPlan || this.ibcPlan
    if (isSinterPlan) {
      this.progressModalTitle = this.$t('openBuildPlanProgressModalInfo.sinterPlanTitle').toString()
      return
    }

    this.progressModalTitle = this.$t('openBuildPlanProgressModalInfo.buildPlanTitle').toString()
  }

  async previewVariantIsLockedModal(bpVariant: IBuildPlanLockDto) {
    await this.openItemFolder(bpVariant.id)

    const item = this.getFileExplorerItems.find((fei) => fei.id === bpVariant.id)
    this.setClickedNameItem(item || (await fileExplorerApi.getItemById(bpVariant.id)))

    this.toggleVariantIsLockedModal(false)
  }

  async editVariantIsLockedModal(variantId: string, variant?: IBuildPlan | IIBCPlan) {
    const plan = this.getBuildPlanVariants.find((bp) => bp.id === variantId)

    if (this.isBuildPlanPage || this.isIbcPlanPage) {
      // Check if edit came from Edit Build Plan page
      if (this.isBuildPlanPage) {
        // Check if switch between variants come from Build Plan to Ibc Plan
        if (plan && plan.itemType === ItemType.IbcPlan) {
          // @ts-ignore
          this.$router.safePush({
            name: RouterNames.FE_EditIbcPlan,
            params: { ibcPlanId: variantId },
          })
          return
        }

        const wasReadOnly = this.isReadOnly
        await this.switchBuildPlanActiveVersion({ newVersionId: variantId, oldVersionId: this.getBuildPlan.id })

        if (!this.isError) {
          if (!wasReadOnly) {
            await this.unlockBuildPlanVariant()
          }
          await this.fetchItemById(variantId)
          const buildPlan = variant ? variant : await this.getBuildPlanById(variantId)
          this.setBuildPlan(buildPlan)
          await this.checkAndLockVariant(variantId)
        }
      }
      // Check if edit came from Ibc Plan page
      if (this.isIbcPlanPage) {
        // Check if switch between variants come from Ibc Plan to Build Plan
        if (plan && plan.itemType === ItemType.BuildPlan) {
          // @ts-ignore
          this.$router.safePush({
            name: RouterNames.EditBuildPlan,
            params: { id: variantId },
          })
          return
        }

        const wasReadOnly = this.isReadOnly
        await this.switchBuildPlanActiveVersion({ newVersionId: variantId, oldVersionId: this.ibcPlan.id })

        if (!this.isError) {
          if (!wasReadOnly) {
            await this.unlockBuildPlanVariant(this.ibcPlan.id)
          }
          await this.fetchItemById(variantId)
          const ibcPlan = variant ? variant : await this.fetchIBCPlan(variantId)
          this.setIBCPlan(ibcPlan)

          await this.checkAndLockVariant(variantId)
        }
      }
    } else {
      let buildPlan: object = {
        name: RouterNames.EditBuildPlan,
        params: { id: variantId },
      }

      if (plan && plan.itemType === ItemType.IbcPlan) {
        buildPlan = {
          name: RouterNames.FE_EditIbcPlan,
          params: { ibcPlanId: variantId },
        }
      }

      // @ts-ignore
      this.$router.safePush(buildPlan)
    }

    this.toggleVariantIsLockedModal(false)
  }

  async checkAndLockVariant(variantId: string) {
    await this.getItemPermissions(variantId)

    // Fetch variant and add to state if it isn't here yet to check if it is read-only accessible only
    if (!this.find(variantId)) {
      this.addItem(await this.fetchItemById(variantId))
    }

    if (!this.isReadOnly) {
      await this.lockBuildPlanVariant(variantId)
    }
  }

  async checkVariantIsLockedAndEdit(variantId: string, variant?: IBuildPlan | IIBCPlan) {
    if (!this.getUserDetails) {
      return
    }

    await this.updateVariantsLockInfo()
    await this.editVariantIsLockedModal(variantId, variant)

    // Temporary commented in order to store this logic for future
    // if (!bpLockInfo.isLocked || bpLockInfo.lockWhitelist.includes(currUserId)) {
    //   await this.editVariantIsLockedModal(bpLockInfo)
    //   return
    // }

    // const bpVariantsLockInfos = await buildPlans.getBuildPlanVariantsLockInfos(bpVariantId)
    // const firstUnlockedVariant = bpVariantsLockInfos
    //   .sort(this.sortByVariantVersionPredicate)
    //   .find(lockInfo => !lockInfo.isLocked || (lockInfo.isLocked && lockInfo.lockWhitelist.includes(currUserId)))

    // this.previewVariant = bpLockInfo
    // this.editVariant = firstUnlockedVariant

    // this.toggleVariantIsLockedModal(true)
  }

  async renameItem(newName: string, renamedItem?: FileExplorerItem) {
    const item = renamedItem ? renamedItem : this.getSelectedItem
    if (!item) {
      return
    }

    const prevName = item.name
    item.name = newName
    if (prevName !== newName) {
      const payload: RenameItemPayload = {
        id: item.id,
        name: newName,
      }

      await this.updateItem(payload)

      if (this.isError) {
        item.name = prevName
      } else {
        await this.setLastAction({ itemId: item.id, action: ItemAction.Renamed })
      }
    }

    this.toggleRenameItemModal(false)
  }

  onMoveToTrashError() {
    this.toggleMoveToTrashErrorModal()
    this.unselectCannotBeTrashedItems()

    if (this.getSelectedItems.length === 1) {
      const selectedItem = this.getSelectedItems[0]
      this.fetchItemDetails({ itemId: selectedItem.id, itemType: selectedItem.itemType })
    }

    this.defineDetailsViewMode()
  }

  async trashSelectedItems() {
    const selected = this.getSelectedItems.length ? this.getSelectedItems : [this.getRootItem]
    await this.isAllowedToBeTrashed(selected)

    if (this.isCanBeTrashedStatusSuccess) {
      this.toggleMoveToTrashModal()
      return
    }

    this.onMoveToTrashError()
  }

  unselectCannotBeTrashedItems() {
    if (!this.getSelectedItems.length) return

    let itemsToUnselect = []

    const canBeTrashedResult = this.getSelectedItemsCanBeTrashed
    itemsToUnselect.push(...canBeTrashedResult.permissionsResult.selectedUnavailableItems)
    itemsToUnselect.push(...canBeTrashedResult.permissionsResult.relatedUnavailableItems)

    for (const reason of canBeTrashedResult.relateToPrintOrdersResult.reasons) {
      if (reason.trashItem) itemsToUnselect.push(reason.trashItem)

      const { items = [], trashItemVariants = [] } = reason

      if (!items.length && !trashItemVariants.length) continue

      for (const item of items.concat(trashItemVariants)) {
        itemsToUnselect.push(item)
      }

      const unavailableItemsToTrash = canBeTrashedResult.relateToPrintOrdersResult.unavailableItemsToTrash
      if (unavailableItemsToTrash && unavailableItemsToTrash.length) {
        itemsToUnselect = itemsToUnselect.concat(unavailableItemsToTrash)
      }
    }

    const moveToTrashFolders = this.getMoveToTrashItems.filter((item) => item.itemType === ItemType.Folder)
    const foldersToUnselect = moveToTrashFolders.filter((folder) =>
      itemsToUnselect.find((item) => item.path.includes(folder.id)),
    )

    itemsToUnselect = itemsToUnselect.concat(foldersToUnselect)

    for (const item of itemsToUnselect) {
      this.unselectItem({ item, selectionType: SelectionTypes.Multiple })
    }
  }

  async confirmTrashModal(items?) {
    const shouldChangeLocation = items ? false : this.getSelectedItems.length ? false : true

    await this.setIsRemovedSelected(items ? items : null)
    this.toggleMoveToTrashModal(false)

    if (shouldChangeLocation && this.getRootItem) {
      if (this.getRootItem.parentId) {
        const parentItem = this.find(this.getRootItem.parentId) || (await this.fetchItemById(this.getRootItem.parentId))
        this.changeRoot(parentItem)
      }

      this.changeRoot(null)
    }

    if (this.getSelectedItem && !this.getSelectedItems.length) {
      await this.fetchItemDetails({ itemId: this.getSelectedItem.id, itemType: this.getSelectedItem.itemType })
    }

    if (this.isError) {
      messageService.showErrorMessage(this.getErrorText)
      return
    }
  }

  async isAllowedToBeTrashed(items: FileExplorerItem[]): Promise<CanBeTrashedResult> {
    if (this.isShownMoveToTrashErrorModal) {
      return this.getSelectedItemsCanBeTrashed
    }

    const ids = items.map((item) => item.id)
    const results = ids.length
      ? await fileExplorerApi.canTrashItems(ids)
      : DEFAULT_IS_ALLOWED_TO_BE_TRASHED_SELECTED_ITEMS

    this.setSelectedItemsCanBeTrashed(results)

    return results
  }

  async checkVariantIsLockedForUser(bpVariantId: string) {
    if (!this.getUserDetails) {
      return true
    }

    const userId = this.getUserDetails.id
    const bpLockInfo = await this.getBuildPlanLockInfo(bpVariantId)
    const isLockedForUser = isItemLockedForUser(bpLockInfo, this.getUserDetails)

    if (isLockedForUser) {
      const bpVariantsLockInfos = await buildPlans.getBuildPlanVariantsLockInfos(bpVariantId)
      const firstUnlockedVariant = bpVariantsLockInfos
        .sort(this.sortByVariantVersionPredicate)
        .find(
          (lockInfo) =>
            !lockInfo.isLocked || (lockInfo.isLocked && lockInfo.lockWhitelist.some((elem) => elem.userId === userId)),
        )
      this.previewVariant = bpLockInfo
      this.editVariant = firstUnlockedVariant ? firstUnlockedVariant : null
    }

    return isLockedForUser
  }

  onLoadBuildPlanLoadingProgressStarts(template: string) {
    this.isShowProgressModal = true
    this.progressModalTemplate = template
    this.setLoadingItemsTitle()
  }

  onLoadBuildPlanLoadingProgressUpdates(data: ILoadingItemsData) {
    this.setLoadingItemsData(data)
  }

  onLoadBuildPlanLoadingProgressEnds() {
    this.isShowProgressModal = false
  }

  private sortByVariantVersionPredicate(first: IBuildPlanLockDto, second: IBuildPlanLockDto): number {
    const firstVersionArr = first.version.split('.')
    const secondVersionArr = second.version.split('.')

    const shortestVersionArrLength = Math.min(firstVersionArr.length, secondVersionArr.length)

    for (let i = 0; i < shortestVersionArrLength; i += 1) {
      const firstVersion = parseInt(firstVersionArr[i], 10)
      const secondVersion = parseInt(secondVersionArr[i], 10)

      if (firstVersion === secondVersion) {
        continue
      }

      return firstVersion - secondVersion
    }

    return firstVersionArr.length - secondVersionArr.length
  }

  private async changeRoot(item: FileExplorerItem) {
    const itemId = item && item.id ? item.id : ROOT_FOLDER_ID
    const route = {
      name: RouterNames.FE_AllFiles,
      params: {
        itemId,
      },
    }
    // @ts-ignore
    this.$router.safePush(route)
  }
}
