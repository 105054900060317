import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { RouterNames } from '@/router'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { IJob, JobStatusCode } from '@/types/PartsLibrary/Job'
import { getDownloadPrintOrder, getDownloadPrintOrderFileKey } from '@/utils/download'
import StoresNamespaces from '@/store/namespaces'
import AlertModal from '@/components/modals/AlertModal.vue'
import { ItemTypeFilterParams } from '@/types/FileExplorer/FilterParamsKey'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { PrintOrderJobStatusCode } from '@/types/PrintOrder/PrintOrderJobStatusCode'
import { IBuildPlate } from '@/types/BuildPlates/IBuildPlate'
import { DetailsPanelExpansionItem } from '@/types/FileExplorer/DetailsPanelExpansionItem'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const jobStore = namespace(StoresNamespaces.Jobs)
const partsStore = namespace(StoresNamespaces.Parts)

@Component
export default class PrintOrderMixin extends Vue {
  @buildPlansStore.Action getSignedUrlV1: (payload: { fileKey: string; newName?: string }) => Promise<string>

  @buildPlansStore.Getter getBuildPlateByPk: (pk: VersionablePk) => IBuildPlate
  @buildPlansStore.Action getBuildPlanById: (id: string) => Promise<IBuildPlan>

  @jobStore.Action fetchJob: (id: string) => Promise<IJob>

  @jobStore.Getter isPrintOrdersLoading: boolean
  @jobStore.Getter('getPrintOrders') printOrders: PrintOrder[]

  @jobStore.Mutation setPrintOrdersLoading: (printOrderLoading: boolean) => void
  @jobStore.Mutation setPrintOrders: (printOrders: PrintOrder[]) => void

  @partsStore.Getter getBuildPlanParts: (buildPlan: IBuildPlan) => DetailsPanelExpansionItem[]

  $refs: {
    alert: InstanceType<typeof AlertModal>
  }

  parts: DetailsPanelExpansionItem[]

  canBeDownloaded(item: PrintOrder): boolean {
    return item.downloadFileKey !== null
  }

  getPrintOrderContextMenu(item: PrintOrder) {
    return [
      {
        title: this.$root.$i18n.t('open'),
        divide: true,
        clickHandler: () => {
          this.navigateToPrintOrderPreview(item)
        },
        testAttribute: 'open-button',
        condition: true,
      },
      {
        title: this.$root.$i18n.t('download'),
        divide: false,
        clickHandler: () => {
          this.downloadFile(item)
        },
        testAttribute: 'download-button',
        condition: true,
      },
    ]
  }

  navigateToPrintOrderPreview(item: PrintOrder) {
    const route = {
      name: RouterNames.PreviewPrintOrder,
      params: {
        id: item.id,
      },
    }
    // @ts-ignore
    this.$router.safePush(route)
  }

  async downloadFile(item: PrintOrder) {
    const job = await this.fetchJob(item.id)
    item.downloadFileKey = getDownloadPrintOrderFileKey(job)
    if (item.downloadFileKey) {
      const downloadFileUrl = await this.getSignedUrlV1({
        fileKey: item.downloadFileKey,
        newName: getDownloadPrintOrder(job),
      })

      window.open(downloadFileUrl)
    } else {
      if (
        job.code === JobStatusCode.CREATED ||
        job.code === JobStatusCode.QUEUED ||
        job.code === JobStatusCode.RUNNING
      ) {
        await this.$refs.alert.open(this.$i18n.t('printOrderDownload'), this.$i18n.t('printOrderPrepUnderProcess'))
      } else {
        await this.$refs.alert.open(this.$i18n.t('printOrderDownload'), this.$i18n.t('printOrderPkgNotavailable'))
      }
    }
  }

  async getParts(id: string) {
    const buildPlan = await this.getBuildPlanById(id)
    return this.getBuildPlanParts(buildPlan)
  }

  get materialsSelectorItems(): ItemTypeFilterParams[] {
    const availableMaterials = [
      ...new Set(this.printOrders.filter((p: PrintOrder) => p.materialName).map((p: PrintOrder) => p.materialName)),
    ]
    return availableMaterials.map((material) => {
      return {
        name: material,
        value: material,
        testClass: '',
      }
    })
  }

  get machinesSelectorItems(): ItemTypeFilterParams[] {
    const availableMachines = [
      ...new Set(this.printOrders.filter((p: PrintOrder) => p.machineName).map((p: PrintOrder) => p.machineName)),
    ]
    return availableMachines.map((machine) => {
      return {
        name: machine,
        value: machine,
        testClass: '',
      }
    })
  }

  get parametersSelectorItems(): ItemTypeFilterParams[] {
    return []
  }

  get buildPlatesSelectorItems(): ItemTypeFilterParams[] {
    const availableBuildPlates = this.printOrders
      .filter((p: PrintOrder) => p.buildPlateId)
      .filter(
        (p: PrintOrder, index: number, self: PrintOrder[]) =>
          index === self.findIndex((po: PrintOrder) => po.buildPlateId === p.buildPlateId),
      )
      .map((p: PrintOrder) => new VersionablePk(p.buildPlateId, p.buildPlateVersion))

    return availableBuildPlates.map((buildPlatePk) => {
      const buildPlate = this.getBuildPlateByPk(buildPlatePk)
      const buildPlateName =
        buildPlate && buildPlate.buildPlateName ? buildPlate.buildPlateName : buildPlatePk.id.toString()
      return {
        name: buildPlateName,
        value: buildPlatePk.id,
        testClass: '',
      }
    })
  }

  get statusSelectorItems(): ItemTypeFilterParams[] {
    const availableStatuses = [...new Set(this.printOrders.map((p: PrintOrder) => p.status))]
    const items = availableStatuses.map((status) => {
      return {
        name: status.toLowerCase(),
        value: status,
        testClass: '',
      }
    })
    return items
  }
}
