import Vue from 'vue'
import { MutationTree } from 'vuex'
import { INotificationState } from './types'
import { INotification } from '@/types/Notification/INotification'
import { DEFAULT_PAGINATION_LIMIT } from '@/constants'

export const mutations: MutationTree<INotificationState> = {
  setNotifications(state, notifications: INotification[]) {
    state.notifications = notifications
  },

  setSelectedNotificationType(state, notificationType: { text: string; value: string[] }) {
    state.selectedNotificationType = notificationType
  },

  addNotifications(state: INotificationState, notifications: INotification[]) {
    state.notifications = [...notifications, ...state.notifications]
  },

  appendNotifications(state: INotificationState, notifications: INotification[]) {
    state.notifications = [...state.notifications, ...notifications]
  },

  removeNotifications(state: INotificationState, notificationIds: string[]) {
    state.notifications = state.notifications.filter((notification) => !notificationIds.includes(notification.id))
  },

  readNotification(state, notificationId: string) {
    for (const idx in state.notifications) {
      if (state.notifications[idx].id === notificationId) {
        state.notifications[idx].read = true
      }
    }
  },

  setItemVersionLabel(state, payload: { itemId: string; versionLabel: string }) {
    const notifications = state.notifications.filter((notification) => notification.itemId === payload.itemId)

    notifications.forEach((notification) => {
      notification.itemVersionLabel = payload.versionLabel
    })
  },

  setLastNotificationTime(state, lastNotificationTime: string) {
    state.lastNotificationTime = lastNotificationTime
  },

  setIsLoading(state, isLoading: boolean) {
    state.isLoading = isLoading
  },

  changeCurrentPage(state, currentPage) {
    state.paginationData.currentPage = currentPage
  },

  updateTotalPages(state, totalPages) {
    state.paginationData.totalPages = totalPages
  },

  clearNotifications(state) {
    state.notifications = []
    state.lastNotificationTime = null
  },

  clearPaginationData(state) {
    state.paginationData = {
      currentPage: 0,
      totalItems: null,
      totalPages: 1,
      size: DEFAULT_PAGINATION_LIMIT,
    }
  },
}
