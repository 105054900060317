import ColorTransferFunction from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction'
import { colorData, ColorPalettes } from '@/types/Simulation/SimulationColorFunctions'
import { SIMULATION_DATA_SUFFIX } from '@/constants'
import { OrthoCamera } from '@/visualization/components/OrthoCamera'
import { Mesh } from '@babylonjs/core/Meshes'
import { OperationStatus } from '@/types/InteractiveService/IInteractiveServiceMessage'
import { IVisualizationServiceMessage, ResultsManager, WsCommands } from '@/visualization/rendering/ResultsManager'
import { createGuid } from '@/utils/common'
import * as ss from 'socket.io-stream'
import * as concat from 'concat-stream'

const arrays = {
  Float32Array,
  Float64Array,
  Uint32Array,
  Int32Array,
  Uint16Array,
  Int16Array,
  Uint8Array,
  Int8Array,
}

export interface ISimulationSettings {
  computePrincipals?: boolean
  computeDerivatives?: boolean
}

export abstract class SimulationBase {
  protected colorTransferFunction
  protected settings: ISimulationSettings
  protected datasetInfo: any[]
  protected manager: ResultsManager
  protected commandMap: Map<string, Function>

  constructor(manager: ResultsManager) {
    this.settings = {
      computePrincipals: false,
      computeDerivatives: false,
    }

    this.manager = manager
    this.colorTransferFunction = ColorTransferFunction.newInstance()
    this.colorTransferFunction.setUseAboveRangeColor(true)
    this.colorTransferFunction.setUseBelowRangeColor(true)
    this.colorTransferFunction.setAboveRangeColor([1.0, 1.0, 1.0, 1.0])
    this.colorTransferFunction.setBelowRangeColor([1.0, 1.0, 1.0, 1.0])
    this.commandMap = new Map<string, Function>()
    this.handleSocketCommunication = this.handleSocketCommunication.bind(this)
    this.stopSocketCommunication = this.stopSocketCommunication.bind(this)
  }

  protected handleSocketCommunication(msg: IVisualizationServiceMessage) {
    const sentCommand = this.commandMap.get(msg.id)
    if (sentCommand && msg.status === OperationStatus.Success) {
      sentCommand(msg)
    }
  }

  protected getArrayFromBinaryData(data: ArrayBuffer, type: string) {
    return new arrays[type](data)
  }

  /**
   * Creates color transfer function
   * @param result Paraview Web Server response result
   */
  protected setColorPalette(palette: ColorPalettes) {
    const adjustedNodes = colorData[palette].nodes.map((e) => ({
      x: e[0],
      r: e[1],
      g: e[2],
      b: e[3],
      midpoint: e[4],
      sharpness: e[5],
    }))

    this.colorTransferFunction.set({ ...colorData[palette], ...{ nodes: adjustedNodes } }, true)
    this.colorTransferFunction.sortAndUpdateRange()
    this.colorTransferFunction.modified()
  }

  protected getMeshId(): string {
    return `${SIMULATION_DATA_SUFFIX}${Math.random().toString(36).substr(2, 9)}`
  }

  protected adjustCamera() {
    const camera = this.manager.getScene.activeCamera as OrthoCamera
    camera.setNewTarget(this.manager.getScene)
  }

  protected removeMeshById(meshId: string) {
    const mesh = this.manager.getScene.getMeshById(meshId)
    if (mesh) {
      this.manager.getScene.removeMesh(mesh)
      mesh.dispose()
    }
  }

  protected getMeshById(id: string): Mesh {
    return this.manager.getScene.getMeshById(id) as Mesh
  }

  protected stopSocketCommunication(): void {
    this.manager.getSocket.off('message', this.handleSocketCommunication)
    this.commandMap.clear()
  }

  protected getBinaryBuffer(hash: string): Promise<ArrayBuffer | ArrayBufferView> {
    const command = {
      id: createGuid(),
      name: WsCommands.GetGeometry,
      parameters: [hash, true],
    }

    return this.streamData(command)
  }

  protected streamData(command): Promise<ArrayBuffer | ArrayBufferView> {
    return new Promise((resolve, reject) => {
      const write = concat((data) => {
        resolve(data.buffer || data)
      })

      const readStream = ss.createStream()

      readStream.on('data', (data) => {
        write.write(data)
      })

      readStream.on('error', (reason) => {
        reject(reason)
      })

      readStream.on('end', () => {
        write.end()
      })

      ss(this.manager.getSocket).emit('stream', readStream, command)
    })
  }
}
