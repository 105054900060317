/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/

import { clamp } from '@/utils/number'
import { Vector2, Vector3 } from '@babylonjs/core/Maths'

export class Rectangle {
  public center: Vector3
  public axis: Vector3[]
  public extent: Vector2

  constructor(
    center: Vector3 = Vector3.Zero(),
    axis: Vector3[] = [new Vector3(1, 0, 0), new Vector3(0, 1, 0)],
    extent: Vector2 = Vector2.One(),
  ) {
    this.center = center
    this.axis = axis
    this.extent = extent
  }

  public getVertices(): Vector3[] {
    const vertices = new Array<Vector3>(4)
    const product0 = this.axis[0].scale(this.extent.x)
    const product1 = this.axis[1].scale(this.extent.y)
    const sum = product0.add(product1)
    const dif = product0.subtract(product1)

    vertices[0] = this.center.subtract(sum)
    vertices[1] = this.center.add(dif)
    vertices[2] = this.center.subtract(dif)
    vertices[3] = this.center.add(sum)

    return vertices
  }
}

export class Plane {
  public static doQuery3D(
    origin: Vector3,
    normal: Vector3,
    extent: Vector3,
    result: {
      distance: number
      sqrDistance: number
      closest: Vector3[]
    },
  ) {
    const dmin = -Vector3.Dot(normal, extent.add(origin))
    if (dmin >= 0) {
      // result.closest[0] = -extent - dmin * normal
      result.closest[0] = extent.scale(-1).subtract(normal.scale(dmin))
      // result.closest[1] = -extent
      result.closest[1] = extent.scale(-1)
    } else {
      const dmax = Vector3.Dot(normal, extent.subtract(origin))
      if (dmax <= 0) {
        // result.closest[0] = extent - dmax * normal
        result.closest[0] = extent.subtract(normal.scale(dmax))
        // result.closest[1] = extent
        result.closest[1] = extent.clone()
      } else {
        const s = (2 * dmin) / (dmin - dmax) - 1
        // result.closest[0] = s * extent
        result.closest[0] = extent.scale(s)
        result.closest[1] = extent.scale(s)
      }
    }
  }

  public static doQuery2D(
    i0: number,
    i1: number,
    i2: number,
    origin: number[],
    normal: number[],
    extent: number[],
    result: {
      distance: number
      sqrDistance: number
      closest: Vector3[]
    },
  ) {
    const dmin = -(normal[i0] * (extent[i0] + origin[i0]) + normal[i1] * (extent[i1] + origin[i1]))

    if (dmin >= 0) {
      const closest0 = [0, 0, 0]
      const closest1 = [0, 0, 0]

      closest0[i0] = -extent[i0] - dmin * normal[i0]
      closest0[i1] = -extent[i1] - dmin * normal[i1]
      closest0[i2] = extent[i2]
      closest1[i0] = -extent[i0]
      closest1[i1] = -extent[i1]
      closest1[i2] = extent[i2]

      result.closest[0] = Vector3.FromArray(closest0)
      result.closest[1] = Vector3.FromArray(closest1)
    } else {
      const dmax = normal[i0] * (extent[i0] - origin[i0]) + normal[i1] * (extent[i1] - origin[i1])

      if (dmax <= 0) {
        const closest0 = [0, 0, 0]
        const closest1 = [0, 0, 0]

        closest0[i0] = extent[i0] - dmax * normal[i0]
        closest0[i1] = extent[i1] - dmax * normal[i1]
        closest0[i2] = extent[i2]
        closest1[i0] = extent[i0]
        closest1[i1] = extent[i1]
        closest1[i2] = extent[i2]

        result.closest[0] = Vector3.FromArray(closest0)
        result.closest[1] = Vector3.FromArray(closest1)
      } else {
        const s = (2 * dmin) / (dmin - dmax) - 1
        const closest0 = [0, 0, 0]
        closest0[i0] = s * extent[i0]
        closest0[i1] = s * extent[i1]
        closest0[i2] = extent[i2]

        result.closest[0] = Vector3.FromArray(closest0)
        result.closest[1] = Vector3.FromArray(closest0)
      }
    }
  }

  public static doQuery1D(
    i0: number,
    i1: number,
    i2: number,
    origin: number[],
    extent: number[],
    result: {
      distance: number
      sqrDistance: number
      closest: Vector3[]
    },
  ) {
    const closest0 = result.closest[0].asArray()
    const closest1 = result.closest[1].asArray()

    closest0[i0] = origin[i0]
    closest0[i1] = extent[i1]
    closest0[i2] = extent[i2]
    result.closest[0] = Vector3.FromArray(closest0)
    closest1[i0] = clamp(origin[i0], -extent[i0], extent[i0])
    closest1[i1] = extent[i1]
    closest1[i2] = extent[i2]
    result.closest[1] = Vector3.FromArray(closest1)
  }

  public static doQuery0D(
    origin: Vector3,
    extent: Vector3,
    result: {
      distance: number
      sqrDistance: number
      closest: Vector3[]
    },
  ) {
    result.closest[0] = origin.clone()
    const closest1 = [
      clamp(origin.x, -extent.x, extent.x),
      clamp(origin.y, -extent.y, extent.y),
      clamp(origin.z, -extent.z, extent.z),
    ]
    result.closest[1] = Vector3.FromArray(closest1)
  }

  public normal: Vector3
  public origin: Vector3
  public constant: number

  constructor(normal: Vector3 = new Vector3(0, 0, 1), origin: Vector3 = Vector3.Zero()) {
    this.normal = normal
    this.origin = origin
    this.constant = Vector3.Dot(normal, origin)
  }
}

export class CanonicalBox {
  public extent: Vector3

  constructor(extent: Vector3 = Vector3.Zero()) {
    this.extent = extent
  }

  public getVertices(): Vector3[] {
    const vertices = new Array<Vector3>(8)
    const extent = this.extent.asArray()

    for (let i = 0; i < 8; i += 1) {
      vertices[i] = Vector3.Zero()
      // tslint:disable-next-line:no-bitwise
      for (let d = 0, mask = 1; d < 3; d += 1, mask = mask << 1) {
        // tslint:disable-next-line:no-bitwise
        if ((i & mask) > 0) {
          const vertex = vertices[i].asArray()
          vertex[d] += extent[d]
          vertices[i] = Vector3.FromArray(vertex)
        } else {
          const vertex = vertices[i].asArray()
          vertex[d] -= extent[d]
          vertices[i] = Vector3.FromArray(vertex)
        }
      }
    }

    return vertices
  }
}

export class Segment {
  public p: Vector3[] = []

  constructor() {
    this.p.push(new Vector3(-1, 0, 0))
    this.p.push(new Vector3(1, 0, 0))
  }
}

export class Line {
  public static face(
    i0: number,
    i1: number,
    i2: number,
    origin: number[],
    direction: number[],
    pMinusE: number[],
    extent: number[],
    result: {
      distance: number
      sqrDistance: number
      parameter: number
      closest: Vector3[]
    },
  ) {
    // PpE
    const pPlusE = [origin[0] + extent[0], origin[1] + extent[1], origin[2] + extent[2]]

    if (direction[i0] * pPlusE[i1] >= direction[i1] * pMinusE[i0]) {
      if (direction[i0] * pPlusE[i2] >= direction[i2] * pMinusE[i0]) {
        // v[i1] >= -e[i1], v[i2] >= -e[i2] (distance = 0)
        origin[i0] = extent[i0]
        origin[i1] -= (direction[i1] * pMinusE[i0]) / direction[i0]
        origin[i2] -= (direction[i2] * pMinusE[i0]) / direction[i0]
        result.parameter = -pMinusE[i0] / direction[i0]
      } else {
        // v[i1] >= -e[i1], v[i2] < -e[i2]
        let lenSqr = direction[i0] * direction[i0] + direction[i2] * direction[i2]
        let tmp = lenSqr * pPlusE[i1] - direction[i1] * (direction[i0] * pMinusE[i0] + direction[i2] * pPlusE[i2])
        if (tmp <= 2 * lenSqr * extent[i1]) {
          const t = tmp / lenSqr
          lenSqr += direction[i1] * direction[i1]
          tmp = pPlusE[i1] - t
          const delta = direction[i0] * pMinusE[i0] + direction[i1] * tmp + direction[i2] * pPlusE[i2]
          result.parameter = -delta / lenSqr
          result.sqrDistance +=
            pMinusE[i0] * pMinusE[i0] + tmp * tmp + pPlusE[i2] * pPlusE[i2] + delta * result.parameter

          origin[i0] = extent[i0]
          origin[i1] = t - extent[i1]
          origin[i2] = -extent[i2]
        } else {
          lenSqr += direction[i1] * direction[i1]
          const delta = direction[i0] * pMinusE[i0] + direction[i1] * pMinusE[i1] + direction[i2] * pPlusE[i2]
          result.parameter = -delta / lenSqr
          result.sqrDistance +=
            pMinusE[i0] * pMinusE[i0] + pMinusE[i1] * pMinusE[i1] + pPlusE[i2] * pPlusE[i2] + delta * result.parameter

          origin[i0] = extent[i0]
          origin[i1] = extent[i1]
          origin[i2] = -extent[i2]
        }
      }
    } else {
      if (direction[i0] * pPlusE[i2] >= direction[i2] * pMinusE[i0]) {
        // v[i1] < -e[i1], v[i2] >= -e[i2]
        let lenSqr = direction[i0] * direction[i0] + direction[i1] * direction[i1]
        let tmp = lenSqr * pPlusE[i2] - direction[i2] * (direction[i0] * pMinusE[i0] + direction[i1] * pPlusE[i1])
        if (tmp <= 2 * lenSqr * extent[i2]) {
          const t = tmp / lenSqr
          lenSqr += direction[i2] * direction[i2]
          tmp = pPlusE[i2] - t
          const delta = direction[i0] * pMinusE[i0] + direction[i1] * pPlusE[i1] + direction[i2] * tmp
          result.parameter = -delta / lenSqr
          result.sqrDistance +=
            pMinusE[i0] * pMinusE[i0] + pPlusE[i1] * pPlusE[i1] + tmp * tmp + delta * result.parameter

          origin[i0] = extent[i0]
          origin[i1] = -extent[i1]
          origin[i2] = t - extent[i2]
        } else {
          lenSqr += direction[i2] * direction[i2]
          const delta = direction[i0] * pMinusE[i0] + direction[i1] * pPlusE[i1] + direction[i2] * pMinusE[i2]
          result.parameter = -delta / lenSqr
          result.sqrDistance +=
            pMinusE[i0] * pMinusE[i0] + pPlusE[i1] * pPlusE[i1] + pMinusE[i2] * pMinusE[i2] + delta * result.parameter

          origin[i0] = extent[i0]
          origin[i1] = -extent[i1]
          origin[i2] = extent[i2]
        }
      } else {
        // v[i1] < -e[i1], v[i2] < -e[i2]
        let lenSqr = direction[i0] * direction[i0] + direction[i2] * direction[i2]
        let tmp = lenSqr * pPlusE[i1] - direction[i1] * (direction[i0] * pMinusE[i0] + direction[i2] * pPlusE[i2])
        if (tmp >= 0) {
          // v[i1]-edge is closest
          if (tmp <= 2 * lenSqr * extent[i1]) {
            const t = tmp / lenSqr
            lenSqr += direction[i1] * direction[i1]
            tmp = pPlusE[i1] - t
            const delta = direction[i0] * pMinusE[i0] + direction[i1] * tmp + direction[i2] * pPlusE[i2]
            result.parameter = -delta / lenSqr
            result.sqrDistance +=
              pMinusE[i0] * pMinusE[i0] + tmp * tmp + pPlusE[i2] * pPlusE[i2] + delta * result.parameter

            origin[i0] = extent[i0]
            origin[i1] = t - extent[i1]
            origin[i2] = -extent[i2]
          } else {
            lenSqr += direction[i1] * direction[i1]
            const delta = direction[i0] * pMinusE[i0] + direction[i1] * pMinusE[i1] + direction[i2] * pPlusE[i2]
            result.parameter = -delta / lenSqr
            result.sqrDistance +=
              pMinusE[i0] * pMinusE[i0] + pMinusE[i1] * pMinusE[i1] + pPlusE[i2] * pPlusE[i2] + delta * result.parameter

            origin[i0] = extent[i0]
            origin[i1] = extent[i1]
            origin[i2] = -extent[i2]
          }
          return
        }

        lenSqr = direction[i0] * direction[i0] + direction[i1] * direction[i1]
        tmp = lenSqr * pPlusE[i2] - direction[i2] * (direction[i0] * pMinusE[i0] + direction[i1] * pPlusE[i1])
        if (tmp >= 0) {
          // v[i2]-edge is closest
          if (tmp <= 2 * lenSqr * extent[i2]) {
            const t = tmp / lenSqr
            lenSqr += direction[i2] * direction[i2]
            tmp = pPlusE[i2] - t
            const delta = direction[i0] * pMinusE[i0] + direction[i1] * pPlusE[i1] + direction[i2] * tmp
            result.parameter = -delta / lenSqr
            result.sqrDistance +=
              pMinusE[i0] * pMinusE[i0] + pPlusE[i1] * pPlusE[i1] + tmp * tmp + delta * result.parameter

            origin[i0] = extent[i0]
            origin[i1] = -extent[i1]
            origin[i2] = t - extent[i2]
          } else {
            lenSqr += direction[i2] * direction[i2]
            const delta = direction[i0] * pMinusE[i0] + direction[i1] * pPlusE[i1] + direction[i2] * pMinusE[i2]
            result.parameter = -delta / lenSqr
            result.sqrDistance +=
              pMinusE[i0] * pMinusE[i0] + pPlusE[i1] * pPlusE[i1] + pMinusE[i2] * pMinusE[i2] + delta * result.parameter

            origin[i0] = extent[i0]
            origin[i1] = -extent[i1]
            origin[i2] = extent[i2]
          }
          return
        }

        // (v[i1],v[i2])-corner is closest
        lenSqr += direction[i2] * direction[i2]
        // delta
        const newDelta = direction[i0] * pMinusE[i0] + direction[i1] * pPlusE[i1] + direction[i2] * pPlusE[i2]
        result.parameter = -newDelta / lenSqr
        result.sqrDistance +=
          pMinusE[i0] * pMinusE[i0] + pPlusE[i1] * pPlusE[i1] + pPlusE[i2] * pPlusE[i2] + newDelta * result.parameter

        origin[i0] = extent[i0]
        origin[i1] = -extent[i1]
        origin[i2] = -extent[i2]
      }
    }
  }

  public static doQuery3D(
    origin: number[],
    direction: number[],
    extent: number[],
    result: {
      distance: number
      sqrDistance: number
      parameter: number
      closest: Vector3[]
    },
  ) {
    // PmE
    const pMinusE = [origin[0] - extent[0], origin[1] - extent[1], origin[2] - extent[2]]
    const prodDxPy = direction[0] * pMinusE[1]
    const prodDyPx = direction[1] * pMinusE[0]

    if (prodDyPx >= prodDxPy) {
      const prodDzPx = direction[2] * pMinusE[0]
      const prodDxPz = direction[0] * pMinusE[2]

      if (prodDzPx >= prodDxPz) {
        // line intersects x = e0
        Line.face(0, 1, 2, origin, direction, pMinusE, extent, result)
      } else {
        // line intersects z = e2
        Line.face(2, 0, 1, origin, direction, pMinusE, extent, result)
      }
    } else {
      const prodDzPy = direction[2] * pMinusE[1]
      const prodDyPz = direction[1] * pMinusE[2]

      if (prodDzPy >= prodDyPz) {
        // line intersects y = e1
        Line.face(1, 2, 0, origin, direction, pMinusE, extent, result)
      } else {
        // line intersects z = e2
        Line.face(2, 0, 1, origin, direction, pMinusE, extent, result)
      }
    }
  }

  public static doQuery2D(
    i0: number,
    i1: number,
    i2: number,
    origin: number[],
    direction: number[],
    extent: number[],
    result: {
      distance: number
      sqrDistance: number
      parameter: number
      closest: Vector3[]
    },
  ) {
    // PmE0
    const pMinusE0 = origin[i0] - extent[i0]
    // PmE1
    const pMinusE1 = origin[i1] - extent[i1]
    const prod0 = direction[i1] * pMinusE0
    const prod1 = direction[i0] * pMinusE1

    if (prod0 >= prod1) {
      // line intersects P[i0] = e[i0]
      origin[i0] = extent[i0]

      // PpE1
      const pPlusE1 = origin[i1] + extent[i1]
      const delta = prod0 - direction[i0] * pPlusE1
      if (delta >= 0) {
        const lenSqr = direction[i0] * direction[i0] + direction[i1] * direction[i1]
        result.sqrDistance += (delta * delta) / lenSqr
        origin[i1] = -extent[i1]
        result.parameter = -(direction[i0] * pMinusE0 + direction[i1] * pPlusE1) / lenSqr
      } else {
        origin[i1] -= prod0 / direction[i0]
        result.parameter = -pMinusE0 / direction[i0]
      }
    } else {
      // line intersects P[i1] = e[i1]
      origin[i1] = extent[i1]

      // PpE0
      const pPlusE0 = origin[i0] + extent[i0]
      const delta = prod1 - direction[i1] * pPlusE0
      if (delta >= 0) {
        const lenSqr = direction[i0] * direction[i0] + direction[i1] * direction[i1]
        result.sqrDistance += (delta * delta) / lenSqr
        origin[i0] = -extent[i0]
        result.parameter = -(direction[i0] * pPlusE0 + direction[i1] * pMinusE1) / lenSqr
      } else {
        origin[i0] -= prod1 / direction[i1]
        result.parameter = -pMinusE1 / direction[i1]
      }
    }

    if (origin[i2] < -extent[i2]) {
      const delta = origin[i2] + extent[i2]
      result.sqrDistance += delta * delta
      origin[i2] = -extent[i2]
    } else if (origin[i2] > extent[i2]) {
      const delta = origin[i2] - extent[i2]
      result.sqrDistance += delta * delta
      origin[i2] = extent[i2]
    }
  }

  public static doQuery1D(
    i0: number,
    i1: number,
    i2: number,
    origin: number[],
    direction: number[],
    extent: number[],
    result: {
      distance: number
      sqrDistance: number
      parameter: number
      closest: Vector3[]
    },
  ) {
    result.parameter = (extent[i0] - origin[i0]) / direction[i0]

    origin[i0] = extent[i0]
    for (const i of [i1, i2]) {
      if (origin[i] < -extent[i]) {
        const delta = origin[i] + extent[i]
        result.sqrDistance += delta * delta
        origin[i] = -extent[i]
      } else if (origin[i] > extent[i]) {
        const delta = origin[i] - extent[i]
        result.sqrDistance += delta * delta
        origin[i] = extent[i]
      }
    }
  }

  public static doQuery0D(
    origin: number[],
    extent: number[],
    result: {
      distance: number
      sqrDistance: number
      parameter: number
      closest: Vector3[]
    },
  ) {
    for (let i = 0; i < 3; i += 1) {
      if (origin[i] < -extent[i]) {
        const delta = origin[i] + extent[i]
        result.sqrDistance += delta * delta
        origin[i] = -extent[i]
      } else {
        const delta = origin[i] - extent[i]
        result.sqrDistance += delta * delta
        origin[i] = extent[i]
      }
    }
  }

  public origin: Vector3
  public direction: Vector3

  constructor(origin: Vector3 = Vector3.Zero(), directon: Vector3 = new Vector3(1, 0, 0)) {
    this.origin = origin
    this.direction = directon
  }
}
