
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import buildPlans from '@/api/buildPlans'
import LocalComputeServiceResults from '@/api/localComputeServiceResults'
import Button from '@/components/controls/Common/Button.vue'
import IbcPlanCompensateResults from '@/components/layout/IBCPlan/IbcPlanCompensateResults.vue'
import IbcPlanCompensateSettings from '@/components/layout/IBCPlan/IbcPlanCompensateSettings.vue'
import AlertModal from '@/components/modals/AlertModal.vue'
import { ICOMPENSATE_COMPUTE_TYPE, MAX_EXPORT_STRING_LENGTH } from '@/constants'
import { StartJobError } from '@/errors/startJobError'
import StoresNamespaces from '@/store/namespaces'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import { IbcJobConfiguration, IIBCPlan, IMeasurement } from '@/types/IBCPlans/IIBCPlan'
import { IJob, JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import { IUser } from '@/types/User/IUser'
import { ConnectionState } from '@/utils/socketConnection'
import { centerTruncate } from '@/utils/string'
import { PWError } from '@/visualization/rendering/ResultsManager'
import { SceneType } from '@/visualization/types/Common'
import { ContentViewModeTypes } from '@/visualization/types/ContentViewMode'
import { ISimulationExportInfo } from '@/visualization/types/SimulationTypes'
import CommonBuildPlanToolsMixin from '../buildPlans/mixins/CommonBuildPlanToolsMixin'
import ExportFileSelectModal from '@/components/layout/buildPlans/modals/ExportFileSelectModal.vue'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const userStore = namespace(StoresNamespaces.User)
const commonStore = namespace(StoresNamespaces.Common)

const FALLBACK_SINTER_PLAN_NAME = 'Sinter Plan'

const IBC_COMPENSATE_JOB_DATA = {
  analysisSettings: {
    sinterScalingFactor: [1, 1, 1],
  },
  compensationList: [{ resolution: 4, morphingScaleFactor: -1.0 }],
}

enum TabSections {
  NoSection = -1,
  Settings = 0,
  Results = 1,
}

@Component({
  components: { Button, IbcPlanCompensateSettings, IbcPlanCompensateResults, AlertModal, ExportFileSelectModal },
})
export default class IBCPlanDeviationCompensateTab extends Mixins(CommonBuildPlanToolsMixin) implements IToolComponent {
  @buildPlansStore.Action getBuildPlanById: (id: string) => Promise<IBuildPlan>

  @buildPlansStore.Getter getIBCPlan: IIBCPlan
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getIbcPlanJobs: IJob[]
  @buildPlansStore.Getter isLockedForUser: boolean
  @buildPlansStore.Getter isLockedForViewer: boolean
  @buildPlansStore.Mutation setContentViewMode: Function

  @commonStore.Getter tooltipOpenDelay: number

  @userStore.Getter getUserDetails: IUser

  @visualizationStore.Action exportCompensationFiles: Function
  @visualizationStore.Action setSimulationExportInfo: (exportInfo: ISimulationExportInfo) => void
  @visualizationStore.Action setVisualizationConnectionParams: Function
  @visualizationStore.Mutation setResultsPathData: Function
  @visualizationStore.Mutation changeViewMode: Function
  @visualizationStore.Mutation('resetSimulationState') resetSimulationState: Function
  @visualizationStore.Mutation('setActiveScene') setActiveScene: Function
  @visualizationStore.Mutation('setIsLoading') setIsLoading: Function

  @visualizationStore.Getter('getResultsAvailable') getResultsAvailable
  @visualizationStore.Getter('getParaviewWebError') getParaviewWebError
  @visualizationStore.Getter getVisualizationLoading: boolean

  runningJobsTimeout = null
  hasRunningPublishJobs = false
  hasCompletedPublishJobs = false
  runningPublishJobs: IJob[] = []
  completedPublishJobs: IJob[] = []
  isDestroyed = false
  isJobsFetching = false
  isAccurate = true
  checkingResults = false
  selectedSection: TabSections = TabSections.NoSection

  clickOk?: () => void
  clickExport?: () => void

  $refs!: {
    alert: InstanceType<typeof AlertModal>
    selectExport: InstanceType<typeof ExportFileSelectModal>
  }

  get compensationJobs(): IJob[] {
    return this.getIbcPlanJobs.filter((job) => {
      return (
        job.itemId === this.getIBCPlan.id &&
        job.jobType === JobType.ICOMPENSATE &&
        job.computeType.trim().toLowerCase() === ICOMPENSATE_COMPUTE_TYPE
      )
    })
  }

  get completedCompensationJobs(): IJob[] {
    return this.compensationJobs
      .filter((job) => job.code === JobStatusCode.COMPLETE || job.code === JobStatusCode.WARNING)
      .sort((a, b) => b.number - a.number)
  }

  get runningCompensationJobs(): IJob[] {
    return this.compensationJobs
      .filter((job) => {
        return [JobStatusCode.RUNNING, JobStatusCode.PENDING, JobStatusCode.QUEUED, JobStatusCode.CREATED].includes(
          job.code,
        )
      })
      .sort((a, b) => b.number - a.number)
  }

  get failedCompensationJobs(): IJob[] {
    return this.compensationJobs
      .filter((job) => {
        return [JobStatusCode.ERROR, JobStatusCode.CANCELLED, JobStatusCode.CANCELLING].includes(job.code)
      })
      .sort((a, b) => b.number - a.number)
  }

  get measurements(): IMeasurement[] {
    return this.getIBCPlan.measurements || []
  }

  get flatHorizontalSurfaceCount(): number {
    return this.getIBCPlan.flatHorizontalSurfaceCount || 0
  }

  get resultsAvailable() {
    return this.getResultsAvailable && this.getResultsAvailable.available
  }

  @Watch('getResultsAvailable') onResultsAvailable() {
    this.finishResultsCheck(this.getResultsAvailable)
  }

  @Watch('failedCompensationJobs')
  onFailedCompensationJobs(jobs: IJob[]) {
    if (!this.completedCompensationJobs.length && !this.runningCompensationJobs.length) {
      const [lastFailedCompensationJob] = jobs
      if (lastFailedCompensationJob) {
        const isReadOnly = this.isLockedForViewer || this.isLockedForUser
        this.$emit('setCancelDisabled', false)
        this.$emit('setOkDisabled', isReadOnly)
      }
    }
  }

  async beforeMount() {
    if (!this.completedCompensationJobs.length) {
      this.finishResultsCheck(null)

      if (!this.runningCompensationJobs.length) {
        const isReadOnly = this.isLockedForViewer || this.isLockedForUser
        this.$emit('setCancelDisabled', false)
        this.$emit('setOkDisabled', isReadOnly)
      }
      return
    }

    this.checkingResults = true

    const [lastIbcJob] = this.completedCompensationJobs
    this.setResultsPathData({ jobNumber: lastIbcJob.number, buildPlanId: this.getIBCPlan.id })

    const lcsResults = await LocalComputeServiceResults.establishConnection()
    if (lcsResults) {
      this.setVisualizationConnectionParams({
        url: lcsResults.sessionURL,
        token: await Vue.prototype.$auth.getTokenSilently(),
        tenant: this.getUserDetails && this.getUserDetails.tenant,
      })
      return
    }

    this.finishResultsCheck(null)
  }

  mounted() {
    this.$emit('mounted')
  }

  async startIBCJob() {
    try {
      this.$emit('setCancelDisabled', true)
      this.$emit('setOkDisabled', true)

      const ibcJobConfiguration: IbcJobConfiguration = {
        ibcPlanId: this.getIBCPlan.id,
        isAccurate: this.isAccurate,
        ...IBC_COMPENSATE_JOB_DATA,
      }
      await buildPlans.createIBCompensationJob(this.getIBCPlan.id, ibcJobConfiguration)
      this.$emit('jobCreated')
    } catch (error) {
      this.$emit('setCancelDisabled', false)
      this.$emit('setOkDisabled', false)
      throw new StartJobError(error.message)
    }
  }

  clickClose() {
    return
  }

  getOkName(): string {
    return 'start'
  }

  beforeDestroy() {
    this.setContentViewMode(ContentViewModeTypes.Layout)
    this.resetSimulationState()
    clearTimeout(this.runningJobsTimeout)
    this.isDestroyed = true
  }

  @Watch('getParaviewWebError') async onParaviewWebError() {
    if (this.getParaviewWebError) {
      switch (this.getParaviewWebError.type) {
        case PWError.SocketError:
          await this.$refs.alert.open(this.$t('simulationToolLabel'), this.$t('simulationToolSocketError'))
          this.$emit('closeTool')
          break
        case PWError.ProcessExit:
          await this.$refs.alert.open(this.$t('simulationToolLabel'), this.$t('paraviewwebProcessClosed'))
          this.$emit('closeTool')
          break
        case PWError.SocketClosed:
          this.$emit('triggerSocketDisconnectModal', ConnectionState.SocketClosed)
          break
        case PWError.ErrorBeforeConnection:
          this.$emit('triggerSocketDisconnectModal', ConnectionState.FailedToConnect)
          break
        default:
          break
      }
    }
  }

  private finishResultsCheck(check: { available: boolean; exportData }) {
    this.checkingResults = false
    const resultsAvailable = check && check.available

    this.selectedSection = resultsAvailable ? TabSections.Results : TabSections.Settings
    if (resultsAvailable) {
      this.setContentViewMode(ContentViewModeTypes.Visualization)
      this.setActiveScene(SceneType.Visualization)
    } else {
      this.setContentViewMode(ContentViewModeTypes.Layout)
      this.setActiveScene(SceneType.Layout)
      this.clickOk = this.startIBCJob
    }

    if (check && check.exportData && Object.keys(check.exportData).length) {
      this.clickExport = () => this.onExportClick()
    }

    this.$emit('isOkAvailable', this)
  }

  private onExportClick() {
    const buildPlanName = this.getIBCPlan.name
      ? centerTruncate(this.getIBCPlan.name, MAX_EXPORT_STRING_LENGTH)
      : FALLBACK_SINTER_PLAN_NAME
    const buildPlanVariant = this.getIBCPlan.versionLabel
      ? centerTruncate(this.getIBCPlan.versionLabel, MAX_EXPORT_STRING_LENGTH)
      : `variant_${this.getIBCPlan.version}`

    this.setSimulationExportInfo({
      buildPlanName,
      buildPlanVariant,
      prefix: this.$t('exportArchivePrefix.ibc').toString(),
    })

    this.$refs.selectExport.open(buildPlanName, buildPlanVariant)
  }
}
