import Vue from 'vue'
import '@/window'
import VueRouter from '@/router/VueRouter'
import IRouter from '@/types/IRouter'
import Home from '@/pages/Home.vue'
import Settings from '@/pages/Settings.vue'
import Version from '@/pages/Version.vue'
import HelpLibrary from '@/pages/HelpLibrary.vue'
import ConnectedPrinters from '@/pages/ConnectedPrinters.vue'
import EditCostEstimation from '@/pages/CostEstimations/EditCostEstimation.vue'
import CostEstimation from '@/pages/CostEstimations/CostEstimation.vue'
import ManageCostEstimations from '@/pages/CostEstimations/ManageCostEstimations.vue'
import store from '@/store'
import SiteMap from '@/pages/SiteMap.vue'
import TermsAndConditions from '@/pages/CostEstimations/TermsAndConditions.vue'
import ErrorModal from '@/components/layout/ErrorModal.vue'
import DashboardTopBar from '@/components/layout/FileExplorer/TopBar/DashboardTopBar.vue'
import AllFilesTopBar from '@/components/layout/FileExplorer/TopBar/AllFilesTopBar.vue'
import FileExplorer from '@/pages/FileExplorer/FileExplorer.vue'
import Dashboard from '@/pages/FileExplorer/Dashboard.vue'
import AllFiles from '@/pages/FileExplorer/AllFiles.vue'
import Favorites from '@/pages/FileExplorer/Favorites.vue'
import Recent from '@/pages/FileExplorer/Recent.vue'
import SharedByMe from '@/pages/FileExplorer/SharedByMe.vue'
import Trash from '@/pages/FileExplorer/Trash.vue'
import Search from '@/pages/FileExplorer/Search.vue'
import EditBuildPlan from '@/pages/BuildPlans/EditBuildPlan.vue'
import LoginInitiation from '@/components/layout/LoginInitiation.vue'
import RegistrationInitiation from '@/components/layout/RegistrationInitiation.vue'
import UserAdmin from '@/components/layout/admin/users/UserAdmin.vue'
import SiteDetailsAdmin from '@/components/layout/admin/sites/SiteDetailsAdmin.vue'
import JobsDashboard from '@/components/layout/admin/process/JobsDashboard.vue'
import TenantAdmin from '@/components/layout/admin/TenantAdmin.vue'
import ProgressiveRegistration from '@/pages/Admin/ProgressiveRegistration.vue'
import { Permission } from '@/constants'
import { isAuthorized } from '@/utils/user'
import { IUser } from '@/types/User/IUser'
import messageService from '@/services/messageService'
import i18n from '@/plugins/i18n'
import PartPreview from '@/components/layout/parts/PartPreview.vue'
import PrintOrdersTopBar from '@/components/layout/FileExplorer/TopBar/PrintOrdersTopBar.vue'
import PrintOrders from '@/pages/FileExplorer/PrintOrders.vue'
import PreviewPrintOrder from '@/pages/PrintOrder/PreviewPrintOrder.vue'
import SitesHolder from '@/components/layout/admin/sites/SitesHolder.vue'
import TenantAdminEditMaterial from '@/components/layout/admin/sites/TenantAdminEditMaterial.vue'
import MachineDetailsAdmin from '@/components/layout/admin/sites/MachineDetailsAdmin.vue'
import PrintStrategy from '@/components/layout/admin/printStrategy/PrintStrategy.vue'
import PrintStrategyContainer from '@/components/layout/admin/printStrategy/PrintStrategyContainer.vue'
import NotFound from '@/pages/NotFound.vue'
import SsoMigrate from '@/pages/SsoMigrate.vue'
import EditIbcPlan from '@/pages/IbcPlan/EditIbcPlan.vue'

export enum RouterPaths {
  Home = '/',
  Error = '/error',
  Login = '/login',
  Register = '/register',
  SsoMigrate = '/sso-migrate',
  SiteMap = '/site-map',
  Settings = '/settings',
  Version = '/version',
  HelpLibrary = '/help-library',
  ConnectedPrinters = '/settings/connected-printers',
  ManageCostEstimations = '/cost-estimations',
  CostEstimation = '/cost-estimations/:costEstimationId',
  EditCostEstimation = '/cost-estimations/:costEstimationId/edit',
  TermsAndConditions = '/terms-and-conditions',
  DefaultFileExplorer = '/file-explorer/all-files/0',
  EditBuildPlan = '/services/build-plans/:id',
  PartPreview = '/services/parts/preview/:id',
  UserAdmin = '/admin/users',
  JobsDashboard = '/admin/jobs',
  Sites = '/admin/sites',
  EditSite = '/admin/sites/:siteId',
  EditMachineType = '/admin/sites/:siteId/:machineTypeId',
  EditMaterial = '/admin/sites/:siteId/:machineTypeId/:materialId',
  PrintStrategy = '/admin/sites/:siteId/:machineTypeId/:materialId/:printStrategyId',
  EditPrintStrategy = '/admin/sites/:siteId/:machineTypeId/:materialId/print-strategy/edit',
  TenantAdmin = '/admin',
  ProgressiveRegistration = '/progressive-registration',
  PreviewPrintOrder = '/services/print-orders/:id/preview',
  PrintOrdersList = '/file-explorer/print-orders',
  EditIbcPlan = '/services/ibc-plans/:ibcPlanId',
  IBCPlansList = '/file-explorer/ibc-plans',
  NotFound = '*',
}

export enum RouterNames {
  Home = 'home',
  Error = 'error',
  Login = 'login',
  Register = 'register',
  SsoMigrate = 'sso-migrate',
  SiteMap = 'site-map',
  Settings = 'settings',
  Version = 'version',
  HelpLibrary = 'help-library',
  ConnectedPrinters = 'connected-printers',
  ManageCostEstimations = 'cost-estimations',
  CostEstimation = 'cost-estimation',
  EditCostEstimation = 'cost-estimation-edit',
  TermsAndConditions = 'terms-and-conditions',
  FE_Dashboard = 'fe-dashboard',
  FE_AllFiles = 'fe-all-files',
  FE_Favorites = 'fe-favorites',
  FE_Recent = 'fe-recent',
  FE_SharedByMe = 'fe-shared-by-me',
  FE_Trash = 'fe-trash',
  FE_Search = 'fe-search',
  FE_PrintOrders = 'fe-print-orders',
  PreviewPrintOrder = 'fe-preview-print-order',
  FE_EditIbcPlan = 'fe-preview-ibc-plan',
  EditBuildPlan = 'edit-build-plan',
  EBP_AddPart = 'add-part',
  EBP_Orient = 'orient',
  EBP_Support = 'support',
  EBP_Arrange = 'arrange',
  EBP_SimulateCompensate = 'simulate-compensate',
  EBP_Slice = 'slice',
  EBP_Label = 'label',
  EBP_Print = 'print',
  EBP_Publish = 'publish',
  EBP_Move = 'move',
  EBP_Rotate = 'rotate',
  EBP_Scale = 'scale',
  EBP_Constrain = 'constrain',
  EBP_Duplicate = 'duplicate',
  EBP_Replace = 'replace',
  UserAdmin = 'users',
  JobsDashboard = 'jobs',
  TenantAdmin = 'tenant',
  SitesAdmin = 'sites',
  EditSiteAdmin = 'edit-site',
  EditMachineTypeAdmin = 'edit-machine-type',
  EditMaterialAdmin = 'edit-material',
  PrintStrategy = 'print-strategy',
  EditPrintStrategy = 'edit-print-strategy',
  ProgressiveRegistration = 'progressive-registration',
  PartPreview = 'part-preview',
  IBC_DeviationCompensate = 'deviation-compensate',
  IBC_Inspections = 'inspections',
  NotFound = 'not-found',
}

export const ROUTER_PERMISSIONS = {
  UserAdmin: [Permission.ReadUsers],
  JobsAdmin: [Permission.ReadJobs],
  CostEstimator: [Permission.AccessCostEstimator],
  PrintModel: [Permission.AccessPrintModel],
}

Vue.use(VueRouter)

const router: IRouter = new VueRouter({
  routes: [
    {
      path: RouterPaths.Home,
      name: RouterNames.Home,
      component: Home,
      meta: {},
      children: [
        {
          path: RouterPaths.Error,
          component: ErrorModal,
          name: RouterNames.Error,
          meta: {},
        },
      ],
    },
    {
      path: RouterPaths.Login,
      component: LoginInitiation,
      name: RouterNames.Login,
    },
    {
      path: RouterPaths.Register,
      component: RegistrationInitiation,
      name: RouterNames.Register,
    },
    {
      path: RouterPaths.SsoMigrate,
      name: RouterNames.SsoMigrate,
      component: SsoMigrate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: RouterPaths.SiteMap,
      name: RouterNames.SiteMap,
      component: SiteMap,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: RouterPaths.Settings,
      name: RouterNames.Settings,
      component: Settings,
      meta: {},
    },
    {
      path: RouterPaths.Version,
      name: RouterNames.Version,
      component: Version,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: RouterPaths.HelpLibrary,
      name: RouterNames.HelpLibrary,
      component: HelpLibrary,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: RouterPaths.ConnectedPrinters,
      name: RouterNames.ConnectedPrinters,
      component: ConnectedPrinters,
      meta: {},
    },
    {
      path: RouterPaths.ManageCostEstimations,
      name: RouterNames.ManageCostEstimations,
      component: ManageCostEstimations,
      meta: {
        requiresAuth: true,
        requiredPermissions: ROUTER_PERMISSIONS.CostEstimator,
      },
    },
    {
      path: RouterPaths.CostEstimation,
      name: RouterNames.CostEstimation,
      component: CostEstimation,
      meta: {
        requiresAuth: true,
        requiredPermissions: ROUTER_PERMISSIONS.CostEstimator,
      },
    },
    {
      path: RouterPaths.EditCostEstimation,
      name: RouterNames.EditCostEstimation,
      component: EditCostEstimation,
      meta: {
        requiresAuth: true,
        requiredPermissions: ROUTER_PERMISSIONS.CostEstimator,
      },
    },
    {
      path: RouterPaths.TermsAndConditions,
      name: RouterNames.TermsAndConditions,
      component: TermsAndConditions,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/file-explorer',
      component: FileExplorer,
      children: [
        {
          path: 'dashboard',
          name: RouterNames.FE_Dashboard,
          components: {
            topbar: DashboardTopBar,
            content: Dashboard,
          },
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'all-files/:itemId',
          name: RouterNames.FE_AllFiles,
          components: {
            topbar: AllFilesTopBar,
            content: AllFiles,
          },
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
          beforeEnter: (to, from, next) => {
            if (to.params.itemId) {
              next()
            } else {
              next(RouterPaths.DefaultFileExplorer)
            }
          },
        },
        {
          path: 'favorites',
          name: RouterNames.FE_Favorites,
          components: {
            topbar: AllFilesTopBar,
            content: Favorites,
          },
          props: {
            topbar: {
              customTitle: true,
              pageTitle: 'favorites',
            },
          },
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'recent',
          name: RouterNames.FE_Recent,
          components: {
            topbar: AllFilesTopBar,
            content: Recent,
          },
          props: {
            topbar: {
              customTitle: true,
              pageTitle: 'recent',
            },
          },
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'shared',
          name: RouterNames.FE_SharedByMe,
          components: {
            topbar: AllFilesTopBar,
            content: SharedByMe,
          },
          props: {
            topbar: {
              customTitle: true,
              pageTitle: 'sharedByMe',
            },
          },
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'trash',
          name: RouterNames.FE_Trash,
          components: {
            topbar: AllFilesTopBar,
            content: Trash,
          },
          props: {
            topbar: {
              customTitle: true,
              pageTitle: 'trash',
            },
          },
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'all-files/search/:query',
          name: RouterNames.FE_Search,
          components: {
            topbar: AllFilesTopBar,
            content: Search,
          },
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'print-orders',
          name: RouterNames.FE_PrintOrders,
          components: {
            topbar: PrintOrdersTopBar,
            content: PrintOrders,
          },
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
      ],
    },
    {
      path: RouterPaths.EditBuildPlan,
      name: RouterNames.EditBuildPlan,
      component: EditBuildPlan,
      children: [
        {
          path: 'add-part',
          name: RouterNames.EBP_AddPart,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'orient',
          name: RouterNames.EBP_Orient,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'support',
          name: RouterNames.EBP_Support,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'arrange',
          name: RouterNames.EBP_Arrange,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'simulate-compensate',
          name: RouterNames.EBP_SimulateCompensate,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'slice',
          name: RouterNames.EBP_Slice,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'label',
          name: RouterNames.EBP_Label,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'print',
          name: RouterNames.EBP_Print,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'publish',
          name: RouterNames.EBP_Publish,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'move',
          name: RouterNames.EBP_Move,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'rotate',
          name: RouterNames.EBP_Rotate,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'constrain',
          name: RouterNames.EBP_Constrain,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'duplicate',
          name: RouterNames.EBP_Duplicate,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
        {
          path: 'replace',
          name: RouterNames.EBP_Replace,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
          },
        },
      ],
      meta: {
        requiresAuth: true,
        requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
      },
    },
    {
      path: RouterPaths.PreviewPrintOrder,
      name: RouterNames.PreviewPrintOrder,
      component: PreviewPrintOrder,
      meta: {
        requiresAuth: true,
        requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
      },
    },
    {
      path: RouterPaths.EditIbcPlan,
      name: RouterNames.FE_EditIbcPlan,
      component: EditIbcPlan,
      children: [
        {
          path: 'inspections',
          name: RouterNames.IBC_Inspections,
        },
        {
          path: 'deviation-compensate',
          name: RouterNames.IBC_DeviationCompensate,
        },
      ],
      meta: {
        requiresAuth: true,
        requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
      },
    },
    {
      path: RouterPaths.PartPreview,
      name: RouterNames.PartPreview,
      component: PartPreview,
      meta: {
        requiresAuth: true,
        requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
      },
    },
    {
      path: RouterPaths.TenantAdmin,
      name: RouterNames.TenantAdmin,
      component: TenantAdmin,
      redirect: { name: RouterNames.UserAdmin },
      children: [
        {
          path: RouterPaths.UserAdmin,
          name: RouterNames.UserAdmin,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.UserAdmin,
          },
          components: {
            content: UserAdmin,
          },
        },
        {
          path: RouterPaths.JobsDashboard,
          name: RouterNames.JobsDashboard,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.JobsAdmin,
          },
          components: {
            content: JobsDashboard,
          },
        },
        {
          path: RouterPaths.Sites,
          name: RouterNames.SitesAdmin,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
            routerName: RouterNames.SitesAdmin,
          },
          components: {
            content: SitesHolder,
          },
        },
        {
          path: RouterPaths.EditSite,
          name: RouterNames.EditSiteAdmin,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
            routerName: RouterNames.SitesAdmin,
          },
          components: {
            content: SiteDetailsAdmin,
          },
        },
        {
          path: RouterPaths.EditMachineType,
          name: RouterNames.EditMachineTypeAdmin,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
            routerName: RouterNames.SitesAdmin,
          },
          components: {
            content: MachineDetailsAdmin,
          },
        },
        {
          path: RouterPaths.EditMaterial,
          name: RouterNames.EditMaterialAdmin,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
            routerName: RouterNames.SitesAdmin,
          },
          components: {
            content: TenantAdminEditMaterial,
          },
        },
        {
          path: RouterPaths.PrintStrategy,
          name: RouterNames.PrintStrategy,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
            routerName: RouterNames.SitesAdmin,
          },
          components: {
            content: PrintStrategyContainer,
          },
        },
        {
          path: RouterPaths.EditPrintStrategy,
          name: RouterNames.EditPrintStrategy,
          meta: {
            requiresAuth: true,
            requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
            routerName: RouterNames.SitesAdmin,
          },
          components: {
            content: PrintStrategy,
          },
        },
      ],
    },
    {
      path: RouterPaths.ProgressiveRegistration,
      name: RouterNames.ProgressiveRegistration,
      component: ProgressiveRegistration,
      meta: {
        requiresAuth: true,
        requiredPermissions: ROUTER_PERMISSIONS.PrintModel,
      },
    },
    {
      path: RouterPaths.NotFound,
      name: RouterNames.NotFound,
      component: NotFound,
    },
  ],
  mode: 'history',
})

router.beforeEach(async (to, from, next) => {
  if (to.path !== RouterPaths.Home && to.path.endsWith('/')) {
    next(to.path.slice(0, -1)) // Remove trailing slash
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      let user: IUser = null
      let promiseResult: boolean = false
      const authPrms = store.getters['user/getAuthPromise']
      promiseResult = await authPrms
      if (!promiseResult && !window.location.search.includes('message=')) {
        // user must login to access this route
        messageService.showWarningMessage(i18n.t('loginPrompt') as string)
        return
      }
      // here we know for sure the user is logged in and we can
      // do all the necessary checks prior to navigating to the route
      user = store.getters['user/getUserDetails']
      if (!user) {
        return
      }

      // first check profile completeness (for invited user)
      if (!user.consentGiven) {
        if (to.name !== RouterNames.ProgressiveRegistration) {
          next(RouterPaths.ProgressiveRegistration)
        } else {
          next()
        }
      } else {
        // now check if the user has all the necessary permissions to access the route
        if (to.meta.requiredPermissions && !isAuthorized(user, to.meta.requiredPermissions)) {
          // user doesn't have the necessary permissions to access the route - abort navigation
          next(router.defaultRoute)
        } else {
          next()
        }
      }
    } else {
      next()
    }
  }
})

router.defaultRoute = RouterPaths.SiteMap

export default router
