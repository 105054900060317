var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"px-2"},[_c('DefectWrapper',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{class:{
          selectable: true,
          capitalize: true,
          'full-width': true,
          'hovered-list-item': _vm.isDefectHovered(),
          'selected-list-item': _vm.isDefectSelected(),
        },on:{"click":_vm.selectDefect,"mouseover":_vm.hoverIntoDefect,"mouseleave":_vm.hoverOutOfDefect}},[_c('div',{staticClass:"defect-name"},[_vm._v(_vm._s(_vm.$t('defect'))+" "+_vm._s(_vm.defectIndex + 1))])])]},proxy:true},{key:"shapes",fn:function(){return [(_vm.defect.shapes)?_c('div',_vm._l((_vm.defect.shapes),function(shape,index){return _c('div',{key:index},[_c('DefectShape',{staticClass:"ml16",attrs:{"defect-type":_vm.defect.type,"defect-index":_vm.defectIndex,"shape":shape,"shape-index":index}})],1)}),0):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }