
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import NumberField from '@/components/controls/Common/NumberField.vue'
import TextField from '@/components/controls/Common/TextField.vue'
import LabelToolSelect from '@/components/controls/LabelToolControls/LabelToolSelect.vue'
import { Mixins, Watch } from 'vue-property-decorator'
import { TextElement, UserEntryJSON, UserEntryType } from '@/types/Label/TextElement'
import { CharacterLengthControl } from '@/types/Label/enums'
import { DynamicElementSettingsMixin } from '@/components/layout/buildPlans/marking/mixins/DynamicElementSettingsMixin'
import { LabelTooltipMixin } from '@/components/layout/buildPlans/marking/mixins/LabelTooltipMixin'
import { extend, ValidationObserver } from 'vee-validate'
import { ClickOutsideMixin } from '@/components/layout/mixins/ClickOutsideMixin'
import { LabelServiceMixin } from '@/components/layout/buildPlans/marking/mixins/LabelServiceMixin'
import { LATIN_CHARACTERS_PATTERN } from '@/constants'

const labelStore = namespace(StoresNamespaces.Labels)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const commonStore = namespace(StoresNamespaces.Common)

export const DEFAULT_PROMPT_TEXT = 'Enter label text'
export const DEFAULT_PREVIEW_PLACEHOLDER_TEXT = ''
export const PREVIEW_PLACEHOLDER_CHARACTER = '[-]'

interface IMixinInterface
  extends Vue,
    DynamicElementSettingsMixin,
    LabelTooltipMixin,
    ClickOutsideMixin,
    LabelServiceMixin {}

const SPECIFIC_DEFAULT_VALUE = 8
const MAXIMUM_DEFAULT_MIN_VALUE = 1
const MAXIMUM_DEFAULT_MAX_VALUE = 8
const RANGE_DEFAULT_MIN_VALUE = 1
const RANGE_DEFAULT_MAX_VALUE = 8

@Component({ components: { NumberField, TextField, LabelToolSelect } })
export default class UserEntrySettings extends Mixins<IMixinInterface>(
  Vue,
  DynamicElementSettingsMixin,
  LabelTooltipMixin,
  ClickOutsideMixin,
  LabelServiceMixin,
) {
  @buildPlansStore.Getter isLabelReadOnly: boolean
  @commonStore.Getter tooltipOpenDelay: number
  @labelStore.Action updateDynamicElement: (element: TextElement) => void

  requiredCharactersEnum = CharacterLengthControl
  usedByIsOpen: boolean = false
  sets = []
  promptText: string = DEFAULT_PROMPT_TEXT
  previewText: string = ""
  previewPlaceholderText: string = DEFAULT_PREVIEW_PLACEHOLDER_TEXT
  title: string = null
  selectedInputType = CharacterLengthControl.Specific
  minRange: number = RANGE_DEFAULT_MIN_VALUE
  maxRange: number = RANGE_DEFAULT_MAX_VALUE
  min: number = MAXIMUM_DEFAULT_MIN_VALUE
  max: number = MAXIMUM_DEFAULT_MAX_VALUE
  exactly: number = SPECIFIC_DEFAULT_VALUE
  id: number = 0
  topOffset: number = 0
  leftOffset: number = 0
  triangleLeft: number = 22
  triangleTop: number = 15
  nameId: number = 0

  numberFieldMinValue: number = 1
  rangeMinMaxValue: number = 35
  rangeMaxMinValue: number = 2
  numberFieldMaxValue: number = 36
  numberFieldStep: number = 1
  maxLength: number = 40

  $refs!: {
    dialog: Element,
    userEntrySettings: InstanceType<typeof ValidationObserver>
  }

  get requiredCharactersList() {
    return [
      {
        id: CharacterLengthControl.Specific,
        name: 'Exactly',
      },
      {
        id: CharacterLengthControl.Maximum,
        name: 'Less Than',
      },
      {
        id: CharacterLengthControl.Range,
        name: 'Range',
      },
    ]
  }

  beforeMount() {
    this.extendValidationRules()
  }


  mounted() {
    // Set values from dynamic element settings in a store to the local component variables
    this.setListenerForClickOutside(true, null, this.customHandler)
    const specificJSON: UserEntryJSON = JSON.parse(
      this.getActiveDynamicElementDialogInfo.textElement._cachedSpecificsJSON,
    )
    this.usedByIsOpen = false
    this.promptText = specificJSON.promptText ? specificJSON.promptText : DEFAULT_PROMPT_TEXT
    this.selectedInputType =
      specificJSON.selectedInputType >= 0 ? specificJSON.selectedInputType : CharacterLengthControl.Specific
    this.minRange = specificJSON.minRange ? specificJSON.minRange : 0
    this.maxRange = specificJSON.maxRange ? specificJSON.maxRange : this.numberFieldMinValue
    this.min = specificJSON.min ? specificJSON.min : 0
    this.max = specificJSON.max ? specificJSON.max : 0
    this.exactly = specificJSON.exactly >= 0 ? specificJSON.exactly : this.numberFieldMinValue
    this.title = this.getActiveDynamicElementDialogInfo.textElement.title
    this.id = this.getActiveDynamicElementDialogInfo.textElement.elementIDNumber
    this.nameId = +this.title.split(' ').pop()
    this.$forceUpdate()
    this.previewPlaceholderText = this.getPreviewPlaceholderText();
    this.previewText = specificJSON.previewText ? specificJSON.previewText : "";
    // On html content is loaded set the position of a dialog
    this.$nextTick(() => {
      this.$refs.userEntrySettings.validate()
      const { leftOffset, triangleLeft } = this.setLeft(this.getActiveDynamicElementDialogInfo.left, this.$refs.dialog)
      const { topOffset, triangleTop } = this.setTop(this.getActiveDynamicElementDialogInfo.top, this.$refs.dialog)
      this.leftOffset = leftOffset
      this.topOffset = topOffset
      this.triangleLeft = triangleLeft
      this.triangleTop = triangleTop
    })
  }

  beforeDestroy() {
    this.setListenerForClickOutside(false, null, this.customHandler)
  }

  get exactlyValidation() {
    return {
      rules: {
        required: true,
        in_range_included: [this.numberFieldMinValue, this.numberFieldMaxValue],
      },
      customMessages: {
        required: this.$t('labelTool.enterValue', {
          leftLimit: this.numberFieldMinValue,
          rightLimit: this.numberFieldMaxValue,
        }).toString(),
      },
    }
  }

  get maximumValidation() {
    return {
      rules: {
        required: true,
        in_range_included: [this.numberFieldMinValue, this.numberFieldMaxValue],
      },
      customMessages: {
        required: this.$t('labelTool.enterValue', {
          leftLimit: this.numberFieldMinValue,
          rightLimit: this.numberFieldMaxValue,
        }).toString(),
      },
    }
  }

  get rangeMinimumValidation() {
    return {
      nativeRules: {
        required: true,
        min_value: this.numberFieldMinValue,
        max_value: this.rangeMinMaxValue,
      },
      customMessages: {
        required: this.$t('labelTool.userEntrySettings.enterValue', {
          leftLimit: this.numberFieldMinValue,
          rightLimit: this.rangeMinMaxValue,
        }).toString(),
        min_value: this.$t('labelTool.validationMessages.validationMinMaxRange', [
          this.numberFieldMinValue,
          this.rangeMinMaxValue,
        ]),
        max_value: this.$t('labelTool.validationMessages.validationMinMaxRange', [
          this.numberFieldMinValue,
          this.rangeMinMaxValue,
        ]),
      },
    }
  }

  get promptTextValidation() {
    return {
      nativeRules: {
        compact: true,
      },
      customMessages: {
        compact: this.$t('labelTool.validationMessages.promptTextInvalidLength').toString(),
      },
    }
  }

  get rangeMaximumValidation() {
    return {
      nativeRules: {
        required: true,
        min_value: this.rangeMaxMinValue,
        max_value: this.numberFieldMaxValue,
      },
      customMessages: {
        required: this.$t('labelTool.userEntrySettings.enterValue', {
          leftLimit: this.rangeMaxMinValue,
          rightLimit: this.numberFieldMaxValue,
        }).toString(),
        min_value: this.$t('labelTool.validationMessages.validationMinMaxRange', [
          this.rangeMaxMinValue,
          this.numberFieldMaxValue,
        ]),
        max_value: this.$t('labelTool.validationMessages.validationMinMaxRange', [
          this.rangeMaxMinValue,
          this.numberFieldMaxValue,
        ]),
      },
    }
  }

  get previewTextValidation() {
    return {
      rules: {
        onlyLatinCharacters:true,
        preview:[this.selectedInputType,this.getLengthMax(),this.getLengthMin()]
      }
    }
  }

  extendValidationRules() {
    extend('in_range_included', {
      validate: (value: number, { leftLimit, rightLimit }: { leftLimit: string; rightLimit: string }) => {
        return value >= +leftLimit && value <= +rightLimit
      },
      params: ['leftLimit', 'rightLimit'],
      message: this.$i18n.t('labelTool.userEntrySettings.enterValue').toString(),
    })

    extend('compact', {
      validate: (value: string) => {
        return value.length <= this.maxLength
      },
      message: this.$i18n.t('labelTool.validationMessages.promptTextInvalidLength').toString(),
    })

    extend('preview', {
      validate: (value: string) => {
        switch (this.selectedInputType) {
          case CharacterLengthControl.Specific:
            return value.trim().length == this.getLengthMax()
          case CharacterLengthControl.Maximum:
            return value.trim().length <= this.getLengthMax()
          case CharacterLengthControl.Range:
            return value.trim().length >= this.getLengthMin() && value.trim().length <= this.getLengthMax()
        }
      },
      params: ['selectedInputType','requiredNoOfCharactersMax','requiredNoOfCharactersMin'],
      message: (_, placeholders: { requiredNoOfCharactersMax: number; requiredNoOfCharactersMin: number; selectedInputType: number }) => {
        const { requiredNoOfCharactersMax, selectedInputType, requiredNoOfCharactersMin } = placeholders
        let msg = `Enter Exactly ${requiredNoOfCharactersMax} characters`
        switch (selectedInputType) {
          case CharacterLengthControl.Specific:
            msg = `Enter Exactly ${requiredNoOfCharactersMax} characters`
            break
          case CharacterLengthControl.Maximum:
            msg =  `Enter up to ${requiredNoOfCharactersMax} characters`
            break
          case CharacterLengthControl.Range:
            msg =  `Enter ${requiredNoOfCharactersMin} - ${requiredNoOfCharactersMax} characters`
            break
        }
        return msg
      }
    })

    extend('onlyLatinCharacters', {
      validate: (value) => {
        return new RegExp(LATIN_CHARACTERS_PATTERN).test(value)
      },
      message: this.$t('latinCharactersMismatch').toString(),
    })
  }

  getPreviewPlaceholderText(){
    let previewPlaceholderText = '';
    for (let i = 0; i < this.getLengthMax(); i += 1) {
      previewPlaceholderText += PREVIEW_PLACEHOLDER_CHARACTER;
    }
    return previewPlaceholderText;
  }

  close() {
    this.$emit('close')
  }

  usedInArray() {
    if (this.getActiveDynamicElementDialogInfo.textElement) {
      const usedInLine = this.usedIn(this.getActiveDynamicElementDialogInfo.textElement, true)
      if (usedInLine.length) {
        return usedInLine.split(',')
      }
    }
    return []
  }

  save() {
    this.updateElement()
    this.$emit('close')
  }

  generateSpecificJson(): UserEntryJSON {
    return {
      entryType: UserEntryType.AlphaNumeric,
      selectedInputType: this.selectedInputType,
      minRange: this.selectedInputType === CharacterLengthControl.Specific ? this.exactly : this.minRange,
      maxRange: this.selectedInputType === CharacterLengthControl.Specific ? this.exactly : this.maxRange,
      min: this.selectedInputType === CharacterLengthControl.Specific ? this.exactly : this.min,
      max: this.selectedInputType === CharacterLengthControl.Specific ? this.exactly : this.max,
      exactly: this.exactly,
      promptText: this.promptText,
      previewText: this.previewText
    }
  }

  @Watch('selectedInputType')
  @Watch('exactly')
  @Watch('minRange')
  @Watch('max')
  @Watch('min')
  @Watch('maxRange')
  @Watch('promptText')
  @Watch('previewPlaceholderText')
  @Watch('previewText')
  onValuesChanged() {
    this.updateElement()
  }

  @Watch('exactly')
  @Watch('max')
  @Watch('maxRange')
  @Watch('selectedInputType')
  onRequiredNoOfCharactersChangeed() {
    this.previewPlaceholderText = this.getPreviewPlaceholderText()
  }
  
  updateElement() {
    const element = JSON.parse(JSON.stringify(this.getActiveDynamicElementDialogInfo.textElement))
    const updatedElement = {
      ...element,
      _cachedSpecificsJSON: JSON.stringify(this.generateSpecificJson()),
      lengthControl: this.selectedInputType,
      mandatory: true,
      lengthMin: this.getLengthMin(),
      lengthMax: this.getLengthMax(),
    }
    this.updateDynamicElement(updatedElement)
  }

  getLengthMin(): number {
    switch (this.selectedInputType) {
      case CharacterLengthControl.Specific:
        return this.exactly
      case CharacterLengthControl.Maximum:
        return 0
      case CharacterLengthControl.Range:
        return this.minRange
    }
  }

  getLengthMax(): number {
    switch (this.selectedInputType) {
      case CharacterLengthControl.Specific:
        return this.exactly
      case CharacterLengthControl.Maximum:
        return this.max
      case CharacterLengthControl.Range:
        return this.maxRange
    }
  }

  minRangeChange() {
    if (this.minRange >= this.maxRange && this.minRange < this.numberFieldMaxValue) {
      this.maxRange = this.minRange + 1
    }
  }

  maxRangeChange() {
    if (this.maxRange <= this.minRange && this.maxRange > this.numberFieldMinValue) {
      this.minRange = this.maxRange - 1
    }
  }

  customHandler(event: PointerEvent) {
    this.dialogOutsideClickHandler(event, 'grid-settings', `element-chip-${this.id}`, this.updateElement, this.close)
  }

  updated() {
    if (this.getActiveDynamicElementDialogInfo.textElement) {
      this.checkElementChanged(
        this.getActiveDynamicElementDialogInfo.textElement._cachedSpecificsJSON,
        JSON.stringify(this.generateSpecificJson()),
      )
    }
  }

  onInputTypeChange(value: CharacterLengthControl) {
    switch (value) {
      case CharacterLengthControl.Specific:
        this.exactly = SPECIFIC_DEFAULT_VALUE
        break
      case CharacterLengthControl.Maximum:
        this.min = MAXIMUM_DEFAULT_MIN_VALUE
        this.max = MAXIMUM_DEFAULT_MAX_VALUE
        break
      case CharacterLengthControl.Range:
        this.minRange = RANGE_DEFAULT_MIN_VALUE
        this.maxRange = RANGE_DEFAULT_MAX_VALUE
        break
    }
  }
}
