import StoresNamespaces from '@/store/namespaces'
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component
export class DisableableInsightMixin extends Vue {
  @visualizationStore.Getter getVisualizationLoading: boolean
  @visualizationStore.Getter isInitialized: boolean

  @buildPlansStore.Getter getIsLoading: boolean

  get isDisabled(): boolean {
    return this.getVisualizationLoading || this.getIsLoading || !this.isInitialized
  }
}
