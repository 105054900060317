
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Emit, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import TableRowMixin from '@/components/layout/FileExplorer/Table/mixins/TableRowMixin'
import ItemListRowBadges from '@/components/layout/FileExplorer/Table/ItemListRowBadges.vue'
import VariantIsLockedModal from '@/components/layout/FileExplorer/VariantIsLockedModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import MenuSection from '@/components/controls/FileExplorer/MenuSection.vue'
import ShareItemModal from '@/components/layout/FileExplorer/ShareItemModal.vue'
import ItemContextMenu from '@/components/layout/FileExplorer/Table/ItemContexMenu.vue'
import ActionBarMixin from '@/components/layout/FileExplorer/Table/mixins/ActionBarMixin'
import { RowSelection } from '@/components/layout/FileExplorer/Table/mixins/RowSelection'
import Menu from '@/components/controls/Common/Menu.vue'
import MoveOrCopyModal from '@/components/layout/FileExplorer/MoveOrCopyModal.vue'

import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import { ItemAction } from '@/types/FileExplorer/ItemAction'
import { Permission } from '@/types/FileExplorer/Permission'
import { SearchResultItem } from '@/types/FileExplorer/SearchResultItem'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { FileExplorerEvent } from '@/types/FileExplorer/FileExplorerEvent'
import { ItemDetailsType } from '@/types/FileExplorer/ItemDetails'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'

import StoresNamespaces from '@/store/namespaces'
import { RouterNames } from '@/router'
import { isOfType } from '@/utils/common'
import RowItemMenu from '../RowItemMenu.vue'
import messageService from '@/services/messageService'
import { eventBus } from '@/services/EventBus'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: {
    MenuSection,
    ItemListRowBadges,
    ShareItemModal,
    ItemContextMenu,
    VariantIsLockedModal,
    ConfirmModal,
    Menu,
    RowItemMenu,
    MoveOrCopyModal,
  },
})
export default class FavoritesListRow extends Mixins(TableRowMixin, ActionBarMixin, RowSelection) {
  @fileExplorerStore.Action deletePermission: (payload: {
    permissionId: string
    fullDeletion?: boolean
  }) => Promise<void>

  @fileExplorerStore.Getter getItemDetails: ItemDetailsType
  @fileExplorerStore.Getter permissionsByItemId: Record<string, Permission[]>

  @fileExplorerStore.Mutation deleteItem: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation setIsMoveItems: (val: boolean) => void

  @Prop() item: SearchResultItem

  $refs!: {
    confirm: InstanceType<typeof ConfirmModal>
    menuIcon: Vue
    rowItemMenu: RowItemMenu
  }

  isShownContextMenu: boolean = false
  isShownActionButtons: boolean = false
  canManageCollaboration: boolean = false
  contextMenuItems = []

  beforeMount() {
    eventBus.$on(FileExplorerEvent.Scroll, this.onScroll)
  }

  async onMenuItemClicked(item: FileExplorerItem, clickHandler: Function) {
    await this.$emit('menuItemClick', this.item)
    clickHandler(item)
  }

  @Emit('manageCollaborators')
  onManageCollaborators() {
    return this.item
  }

  @Watch('isSelected')
  menuChangedBySelection(isSelectedNow: boolean) {
    this.isShownActionButtons = isSelectedNow
  }

  @Watch('getItemDetails')
  onItemDetailsChanged(details) {
    const id = this.getDetailsItemId(details)
    if (id === this.item.id) {
      this.getContextMenuItems()
    }
  }

  getDetailsItemId(details) {
    return (
      (details.part && details.part.id) ||
      (details.folder && details.folder.id) ||
      (details.buildPlan && details.buildPlan.id)
    )
  }

  showContextMenu(event) {
    this.$refs.rowItemMenu.showContextMenu(event, false)
  }

  async fetchPermissions() {
    if (isOfType<PrintOrder>(this.item, 'site')) {
      return
    }

    if (this.item.isShared) {
      try {
        await this.fetchItemById(this.item.id)
        // const collaboration: ItemCollaborationDto = await fileExplorerApi.getItemPermissions(this.item.id)
        // this.canManageCollaboration = collaboration.canManageCollaboration
      } catch (error) {
        messageService.showErrorMessage(error.message || 'Something went wrong')
      }
    }
  }

  async getContextMenuItems(onMoveOrCopy?: Function) {
    await this.fetchPermissions()
    if (isOfType<PrintOrder>(this.item, 'site')) {
      this.contextMenuItems = []
      return
    }

    const canBeTrashedResult = await this.isAllowedToBeTrashed(this.getSelectedItems)
    const newContextMenuItems = []

    newContextMenuItems.push(
      {
        title: this.$i18n.t('open'),
        divide: false,
        clickHandler: (item: FileExplorerItem) => {
          this.onItemNameClick()
        },
        testAttribute: 'open-button',
        condition: this.item.grantedPermissions,
        disabled: !this.getSelectedItem,
      },
      {
        title: this.$i18n.t('share'),
        divide: false,
        clickHandler: this.toggleShareItemModal,
        testAttribute: 'share-button',
        condition: this.item.grantedPermissions.itemShare,
      },
      {
        title: this.$i18n.t('moveOrCopy'),
        divide: false,
        clickHandler: (item: FileExplorerItem) => {
          onMoveOrCopy ? onMoveOrCopy() : this.toggleMoveOrCopyModal(true)
        },
        testAttribute: 'copy-button',
        condition: this.item.grantedPermissions.itemMove,
      },
    )

    newContextMenuItems.push(
      {
        title: this.$i18n.t('rename'),
        divide: this.item.grantedPermissions.itemTrash && !this.isAllowedToEndCollaboration(),
        clickHandler: this.toggleRenameItemModal,
        testAttribute: 'rename-button',
        condition: this.item.grantedPermissions.itemRename,
        disabled: !this.getSelectedItem,
      },
      {
        title: this.$t('endCollaboration'),
        divide: this.item.grantedPermissions.itemTrash && this.isAllowedToEndCollaboration(),
        clickHandler: async () => {
          if (isOfType<PrintOrder>(this.item, 'site') || !this.getUserDetails) {
            return
          }

          await this.fetchItemDetails({ itemId: this.item.id, itemType: this.item.itemType })
          await this.getItemPermissions(this.item.id)
          const permissions = this.permissionsByItemId
          const collaborators =
            permissions && Object.keys(permissions).includes(this.item.id) ? permissions[this.item.id] : null

          const collaborator = collaborators.find((item) => item.grantedTo === this.getUserDetails.id)
          if (collaborator) {
            const modalTitle = this.$root.$t('removeFromCollaborationDialog.title')
            const modalMessage = `<p class="text t-14">${this.$root.$t(
              'removeFromCollaborationDialog.removeYourselfLabel',
            )}</p>`
            let shouldDelete = await this.$refs.confirm.open(modalTitle, modalMessage)

            if (shouldDelete) {
              await this.setLastAction({ itemId: this.item.id, action: ItemAction.Unshared })
              let itemToDelete = this.item
              const isInherited = collaborator.isInherited
              if (isInherited) {
                this.$refs.confirm.cancel()
                const parentItem: FileExplorerItem = await this.fetchItemById(collaborator.inheritedFrom)
                const user = this.getUserDetails
                const displayName = `${user.firstName} ${user.lastName}`
                const title = this.$root.$t('removeFromCollaborationDialog.fromParentTitle')
                const message = `<p class="text t-14">${this.$root.$t('removeFromCollaborationDialog.fromParentLabel', {
                  collaboratorName: displayName,
                  parentFolderName: parentItem.name,
                })}</p>`
                shouldDelete = await this.$refs.confirm.open(title, message)
                if (!shouldDelete) {
                  this.$refs.confirm.cancel()
                  return
                }
                itemToDelete = parentItem
              }
              await this.deletePermission({ permissionId: collaborator.id, fullDeletion: true })
              this.deleteItem(itemToDelete)
              this.unselectItem({ item: this.item, selectionType: SelectionTypes.Single })

              if (isInherited) {
                // @ts-ignore
                this.$router.safePush({ name: RouterNames.FE_AllFiles, params: { itemId: '0' } })
              }
            }
          }
        },
        testAttribute: 'end-collaboration-button',
        condition: this.isAllowedToEndCollaboration(),
      },
      {
        title: this.$i18n.t('trash'),
        divide: false,
        clickHandler: canBeTrashedResult
          ? this.isCanBeTrashedStatusSuccess
            ? this.toggleMoveToTrashModal
            : this.onMoveToTrashError
          : () => null,
        testAttribute: 'delete-button',
        condition: this.item.grantedPermissions.itemTrash,
      },
    )
    this.contextMenuItems = newContextMenuItems
  }

  onScroll() {
    if (this.isShownContextMenu) {
      this.isShownContextMenu = false
      ;(this.$refs.menuIcon.$el as HTMLElement).blur()
    }
  }

  beforeDestroy() {
    eventBus.$off(FileExplorerEvent.Scroll, this.onScroll)
  }

  private isAllowedToEndCollaboration(): boolean {
    const itemDetails = this.getItemDetails

    if (!itemDetails || !this.getUserDetails) {
      return false
    }

    if (itemDetails.collaborators.length > 0) {
      const currentUserId = this.getUserDetails.id

      const collaborator = itemDetails.collaborators.find((c) => c.grantedTo === currentUserId)
      return !!collaborator
    }

    return false
  }
}
