
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import VSelect from 'vuetify/lib/components/VSelect'
import { IMarkTemplate } from '@/types/Marking/IMarkTemplate'
import { ClickOutsideMixin } from '@/components/layout/mixins/ClickOutsideMixin'
import { KEYBOARD_KEY_CODES } from '@/constants'

@Component
export default class MarkingContentTab extends ClickOutsideMixin {
  @Prop() template: IMarkTemplate
  @Prop() updateRecipe: (recipe: IMarkTemplate) => void

  $refs!: {
    selector: VSelect
  }

  templateTypes: any[] = [
    { text: 'Fixed', value: 0 },
    { text: 'Serial Number', value: 1 },
  ]
  content: string = ''
  isMounted: boolean = false

  get isTypeSelectorMenuActive() {
    return (
      this.isMounted &&
      this.$refs.selector.$refs &&
      this.$refs.selector.$refs.menu &&
      this.$refs.selector.$refs.menu.isActive
    )
  }

  get currentTemplate() {
    return this.template
  }

  @Watch('isTypeSelectorMenuActive')
  onIsMenuActiveChanged() {
    this.setListenerForClickOutside(this.isTypeSelectorMenuActive, this.closeTypeSelectorMenu)
  }

  closeTypeSelectorMenu() {
    this.$refs.selector.$refs.menu.isActive = false
  }

  mounted() {
    this.isMounted = true
  }

  isAcceptableCharacter(evt, template) {
    if (template.type === 1) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (
        !(
          charCode === KEYBOARD_KEY_CODES.Backspace ||
          charCode === KEYBOARD_KEY_CODES.Delete ||
          charCode === KEYBOARD_KEY_CODES.W
        )
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    } else {
      return true
    }
  }
}
