var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"}],staticClass:"mb-6 process-summary-container"},[_c('span',{staticClass:"process-summary-label text t-14 px-1",domProps:{"innerHTML":_vm._s(_vm.context.label)}}),_c('div',{staticClass:"summary-content-container",class:{ 'summary-bottom-padding': _vm.visibleItems.length !== 0 }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.context.conclusion !== undefined),expression:"context.conclusion !== undefined"}],staticClass:"conclusion-section",class:{
        'conclusion-section-bottom': _vm.visibleItems.length === 0,
        'low-risk-item': _vm.conclusionRiskLow,
        'moderate-risk-item': _vm.conclusionRiskModerate,
        'high-risk-item': _vm.conclusionRiskHigh,
      }},[_c('span',{staticClass:"conclusion-content"},[_vm._v(_vm._s(_vm.conclusionLabel))]),_c('div',{staticClass:"icon-container",class:{
          'low-risk-item-active': _vm.conclusionRiskLow,
          'moderate-risk-item-active': _vm.conclusionRiskModerate,
          'high-risk-item-active': _vm.conclusionRiskHigh,
        }},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.iconContent))])],1)]),_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.visibleItems),function(item,i){return _c('v-list-item',{key:i,attrs:{"disabled":_vm.itemDisabled}},[_c('div',{staticClass:"summary-item-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.risk !== undefined),expression:"item.risk !== undefined"}],staticClass:"risk-icon",class:{
                'low-risk-item': item.risk == _vm.risks.Low,
                'low-risk-item-active': item.risk == _vm.risks.Low && item.active,
                'moderate-risk-item': item.risk == _vm.risks.Moderate,
                'moderate-risk-item-active': item.risk == _vm.risks.Moderate && item.active,
                'high-risk-item': item.risk == _vm.risks.High,
                'high-risk-item-active': item.risk == _vm.risks.High && item.active,
              }},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.riskToIcon(item.risk)))])],1),_c('div',{staticClass:"summary-item-columns"},[_c('span',{staticClass:"summary-item-label"},[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"summary-item-bottom"},[_c('span',{staticClass:"summary-item-value"},[_vm._v(_vm._s(item.value))]),_c('span',{staticClass:"summary-item-value-unit"},[_vm._v(_vm._s(_vm.unitLabel(item.unit)))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.referenceLabel !== undefined),expression:"item.referenceLabel !== undefined"}],staticClass:"summary-item-value-unit"},[_vm._v(" "+_vm._s(item.referenceLabel)+" ")])]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(item.active),expression:"item.active"}],staticClass:"summary-item-details",on:{"click":function($event){$event.stopPropagation();return _vm.highlightDetails(item)}}},[_vm._v(_vm._s(item.details)+" ")])]),_c('div',{staticClass:"expand-icon"},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)])])}),1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }