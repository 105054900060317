
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class GenericErrorModal extends Vue {
  @Prop({ required: true, type: String, default: '' }) title: string
  @Prop({ required: true, type: String, default: '' }) message: string
}
