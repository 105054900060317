import { ItemsGeometry, Facet } from './ItemsGeometry'
import { Color4, Vector2, Vector3 } from '@babylonjs/core/Maths'
import { MeshBuilder } from '@babylonjs/core/Meshes'
import { Scene } from '@babylonjs/core/scene'
import {
  CENTER_OF_GRAVITY,
  CENTER_OF_GRAVITY_MATERIAL_NAME,
  FOOTPRINT_MATERIAL_NAME,
  FOOTPRINT,
  MESH_RENDERING_GROUP_ID,
  REGULAR_GREEN,
} from '../constants'
import { SnackbarMessageType } from '../types/messages/SnackbarMessageType'

type Footprint = { meshId: string; positions: Vector3[]; xyOBB: Vector2[]; unstable: boolean }
type FootprintZone = {
  centers: Vector3[]
  footprints: Footprint[]
}

type PartStabilityInfo = {
  id: string
  type: SnackbarMessageType
  areaCriteria?: number
  tangentCriteria?: number
}

enum bbIndices {
  minX,
  maxX,
  minY,
  maxY,
}

/**
 * Performs build plan items stability checking
 */
export class UPChecker extends ItemsGeometry {
  tolerance: number = 0.2 /* mm */
  centerOfGravitySize: number = 4
  footprintZones: Map<string, FootprintZone>

  scale: number[]
  // Debugging flag
  debugStability = false

  constructor(scene: Scene, scale: number[]) {
    super(scene)
    this.footprintZones = new Map<
      string,
      {
        centers: Vector3[]
        footprints: Footprint[]
      }
    >()

    this.scale = scale
  }

  /**
   * Runs stability check
   * @returns Stability reports
   */
  public checkStability(): PartStabilityInfo[] {
    const reports: PartStabilityInfo[] = []

    this.resetData()

    // Collect items geometries
    const success = this.collectGeometry(this.scale)
    if (!success) {
      return reports
    }

    // Set footprint of the part + its supports and coupons
    this.setFootprintZones(this.globalBottomZ)

    // Check stability
    for (const id of this.footprintZones.keys()) {
      const centers = this.footprintZones.get(id).centers
      const footprints = this.footprintZones.get(id).footprints
      const bottomZ: number = ItemsGeometry.itemsGeometry.get(id).bottomZ

      const meshNumber = centers.length < footprints.length ? centers.length : footprints.length
      for (let n = 0; n < meshNumber; n += 1) {
        const footprintLoop = footprints[n].positions
        const center = centers[n]
        footprints[n].unstable = false

        let isStable: boolean = true
        const size = footprintLoop.length
        if (size < 3) {
          // Footprint consists on one point or one edge
          // Item is unstable if at least one its mesh is unstable
          footprints[n].unstable = true
          reports.push({ id, type: SnackbarMessageType.Error })
        }

        // Are the points of one segment on opposite sides of the other segment? (Do segments crossed?)
        // One of these segments consists of adjucent support area points.
        // Another segment consists of the part's center (x,y) and far point outside the support area.
        const farPoint: Vector3 = new Vector3(-1e4, center.y, center.z)
        let crossNumber: number = 0
        for (let i: number = 0; i < size; i += 1) {
          const p0 = footprintLoop[i]
          const p1 = footprintLoop[(i + 1) % size]

          // Use farPoint-partCenter as a base segment
          if (
            !this.checkSegment(center, farPoint, p0, p1) ||
            // Use p1-p0 as a base segment
            !this.checkSegment(p1, p0, farPoint, center)
          ) {
            continue
          }

          crossNumber += 1
        }

        isStable = crossNumber % 2 === 1 ? true : false

        if (!isStable) {
          // Item is unstable if at least one its mesh is unstable
          footprints[n].unstable = true
          reports.push({ id, type: SnackbarMessageType.Error })
          continue
        }

        // It's necessary to check whether this is the result of the tessellation side effect
        // 1. Get the item bounding box (BB)
        // 2. Get the projection area of BB onto the plan (BBP area)
        // 3. Calculate the part's footprint area
        // 4. Criterion #1. Calc the the ratio of part's footprint area to BBP area.
        //    If this ratio is less than 'areaFactor', this may be a side effect of tessellation
        //    and the part is unstable. (The footprint area is suspiciously small)
        // 5. Get the footprint area bounding box and its minimum size (along x or y axis)
        // 6. Criterion #2. Calc the ratio of the minimum footprint area size to the CG z-coordinate.
        //    If this ratio is less than 'tangentFactor', the part is unstable.
        //    (The angle that the part must be tilted to get the CG out of the footprint area is too small)
        const areaFactor: number = 0.05 // 5% area
        const tangentFactor: number = 0.035 // ~2 degree

        // Create overall item xyOBB
        const xyOBBMap = ItemsGeometry.itemsGeometry.get(id).xyOBB
        let xyOBB: Vector2[] = []
        for (const obb of xyOBBMap.keys()) {
          const meshXYOBB = xyOBBMap.get(obb)

          if (xyOBB.length === 0) {
            xyOBB = xyOBB.concat(meshXYOBB)
            continue
          }

          if (meshXYOBB[bbIndices.minX].x < xyOBB[bbIndices.minX].x) {
            xyOBB[bbIndices.minX] = meshXYOBB[bbIndices.minX]
          } else if (meshXYOBB[bbIndices.minY].y < xyOBB[bbIndices.minY].y) {
            xyOBB[bbIndices.minY] = meshXYOBB[bbIndices.minY]
          } else if (meshXYOBB[bbIndices.maxX].x > xyOBB[bbIndices.maxX].x) {
            xyOBB[bbIndices.maxX] = meshXYOBB[bbIndices.maxX]
          } else if (meshXYOBB[bbIndices.maxY].y > xyOBB[bbIndices.maxY].y) {
            xyOBB[bbIndices.maxY] = meshXYOBB[bbIndices.maxY]
          }
        }

        // Calc XY bounding box area
        const bbArea = Math.abs(
          this.cross2D(
            xyOBB[bbIndices.minY].subtract(xyOBB[bbIndices.minX]),
            xyOBB[bbIndices.maxX].subtract(xyOBB[bbIndices.minX]),
          ),
        )

        // Calc the footprint area
        let sArea: number = 0
        for (let i: number = 0; i < size; i += 1) {
          sArea += Math.abs(
            this.cross2D(
              new Vector2(footprintLoop[i].x - center.x, footprintLoop[i].y - center.y),
              new Vector2(footprintLoop[(i + 1) % size].x - center.x, footprintLoop[(i + 1) % size].y - center.y),
            ),
          )
        }

        sArea /= 2

        // Calc the footprint area BB minimum size
        const footOBB = footprints[n].xyOBB
        const sizeXminYmin = this.distanceSquare2D(footOBB[0], footOBB[1])
        const sizeXmaxYmin = this.distanceSquare2D(footOBB[1], footOBB[2])
        let minSize: number = 0
        if (sizeXminYmin < sizeXmaxYmin) {
          minSize = Math.sqrt(sizeXminYmin) / 2
        } else {
          minSize = Math.sqrt(sizeXmaxYmin) / 2
        }

        // Check additional criteria
        const areaCriteria = sArea / bbArea
        const tangentCriteria = minSize / (center.z - bottomZ)
        if (Math.abs(areaCriteria) < areaFactor || Math.abs(tangentCriteria) < tangentFactor) {
          footprints[n].unstable = true
          reports.push({ id, areaCriteria, tangentCriteria, type: SnackbarMessageType.Warning })
        } else {
          reports.push({ id, type: SnackbarMessageType.Success })
        }
      }
    }

    return this.getReports(reports)
  }

  /**
   * Returns results of items stability checking
   * @returns Footprints and CG of sinter plan items
   */
  public getResult(): Map<string, { centers: Vector3[]; footprints: Footprint[] }> {
    return this.footprintZones
  }

  /**
   * Shows footprint and center of gravity
   * @param bpItemId Sinter plan item GUID
   */
  public showFootprintAndCG(bpItemId: string) {
    // Collect all unstable meshes
    const footprints: Map<string, { positions: Vector3[]; center: Vector3 }> = new Map<
      string,
      { positions: Vector3[]; center: Vector3 }
    >()

    let footprintZone: FootprintZone

    this.clearFootprintAndCG()

    for (const itemId of this.footprintZones.keys()) {
      // Only one item should be shown?
      // if (itemId !== bpItemId) {
      //   continue
      // }

      footprintZone = this.footprintZones.get(itemId)
      // if (!footprintZone) {
      //   this.collectGeometry(this.scene, this.scale)
      //   footprintZone = this.footprintZones.get(itemId)
      // }

      const meshNumber =
        footprintZone.centers.length < footprintZone.footprints.length
          ? footprintZone.centers.length
          : footprintZone.footprints.length
      for (let n = 0; n < meshNumber; n += 1) {
        // Find unstable footprint
        if (footprintZone.footprints[n].unstable) {
          footprints.set(footprintZone.footprints[n].meshId, {
            positions: footprintZone.footprints[n].positions,
            center: footprintZone.centers[n],
          })
        }
      }
    }

    // Debug
    if (this.debugStability && footprints.size === 0) {
      footprints.set(footprintZone.footprints[0].meshId, {
        positions: footprintZone.footprints[0].positions,
        center: footprintZone.centers[0],
      })
    }

    if (footprints.size === 0) {
      // Unstable elements weren't found
      return
    }

    for (const mId of footprints.keys()) {
      let footprintPoints: Vector3[] = []
      footprintPoints = footprintPoints.concat(footprints.get(mId).positions)
      const center = footprints.get(mId).center.clone()

      if (footprintPoints.length === 1) {
        const footPrintPoint = MeshBuilder.CreateSphere(FOOTPRINT, { diameter: this.centerOfGravitySize }, this.scene)
        footPrintPoint.renderingGroupId = MESH_RENDERING_GROUP_ID
        footPrintPoint.setAbsolutePosition(footprintPoints[0])
        footPrintPoint.material = this.scene.getMaterialByName(FOOTPRINT_MATERIAL_NAME)
      } else {
        // To close footprint curve
        footprintPoints.push(footprintPoints[0])
        // Contour of the footprint
        const footprintMesh = MeshBuilder.CreateLines(FOOTPRINT, { points: footprintPoints }, this.scene)
        footprintMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
        footprintMesh.enableEdgesRendering()
        footprintMesh.edgesWidth = this.lineWidth
        footprintMesh.edgesColor = Color4.FromColor3(REGULAR_GREEN, 1)
      }

      // Center of gravity
      center.z = footprintPoints[0].z
      const centerOfGravity = MeshBuilder.CreateSphere(
        CENTER_OF_GRAVITY,
        { diameter: this.centerOfGravitySize },
        this.scene,
      )
      centerOfGravity.renderingGroupId = MESH_RENDERING_GROUP_ID + 1
      centerOfGravity.setAbsolutePosition(center)
      centerOfGravity.material = this.scene.getMaterialByName(CENTER_OF_GRAVITY_MATERIAL_NAME)
    }
  }

  /**
   * Removes footprint and CG visualization from the scene
   */
  public clearFootprintAndCG() {
    const meshes = this.scene.meshes.filter((m) => m.name === CENTER_OF_GRAVITY || m.name === FOOTPRINT)
    for (const mesh of meshes) {
      mesh.dispose()
    }
  }

  /**
   * Returns reports for unstable parts and supports only
   * @param reports
   * @returns Array of reports
   */
  private getReports(reports: PartStabilityInfo[]): PartStabilityInfo[] {
    const insightRecords: PartStabilityInfo[] = []

    // Debug
    if (this.debugStability) {
      return reports
    }

    if (reports.length === 1) {
      return reports
    }

    for (const rep of reports) {
      const isPartORsupport = ItemsGeometry.itemsGeometry.get(rep.id).productORsupport
      const isError = rep.type === SnackbarMessageType.Error || rep.type === SnackbarMessageType.Warning

      if (isPartORsupport && isError) {
        insightRecords.push(rep)
        break
      }
    }

    return insightRecords
  }

  /**
   * Checks if the segments pa-pb and pc-pd intersect
   * @param pa First point of pa-pb segmrnt
   * @param pb Second point of pa-pb segmrnt
   * @param pc First point of pc-pd segmrnt
   * @param pd Second point of pc-pd segmrnt
   * @returns True if segments intersect
   */
  private checkSegment(pa: Vector3, pb: Vector3, pc: Vector3, pd: Vector3) {
    // pa, pb - base segments (pa - start point, pb - end point)
    // pc, pd - checking segments
    const s = pa.subtract(pb) // Base segment
    const a = pc.subtract(pb)
    const b = pd.subtract(pb)

    const crossSA = s.x * a.y - s.y * a.x
    const crossSB = s.x * b.y - s.y * b.x
    if ((crossSA > 0 && crossSB > 0) || (crossSA < 0 && crossSB < 0)) {
      // Cross productions have the same sign, so
      // the segments s and (a-b) can't be intersected
      return false
    }

    return true
  }

  /**
   * Cleans up the inner data
   */
  private resetData() {
    // Clean up data
    this.footprintZones.clear()
  }

  /**
   * Builds convex hull of 3D loop
   * @param pos 3D loop points
   * @returns Array of 3D points
   */
  private buildConvexHull(pos: Vector3[]): Vector3[] {
    if (pos.length <= 3) {
      return pos
    }

    const cross = (a: Vector3, b: Vector3, c: Vector3): number => {
      const u = b.subtract(a)
      const v = c.subtract(b)
      return u.x * v.y - u.y * v.x
    }

    let positions: Vector3[] = []
    positions = positions.concat(pos)

    // Sort positions array by y then x
    // to put left bottom element at the top of array
    positions.sort((a, b) => {
      return a.y === b.y ? a.x - b.x : a.y - b.y
    })

    const convHull: Vector3[] = []
    // Remove left bottom position
    // and put it into convex hull array
    convHull.push(positions.splice(0, 1)[0])

    // Sort positions in ascending polar angle
    // relative to the left bottom point
    positions.sort((a, b) => {
      const u = a.subtract(convHull[0])
      const v = b.subtract(convHull[0])
      return -(u.x * v.y - u.y * v.x)
    })

    convHull.push(positions[0])
    for (let i = 1; i < positions.length; i += 1) {
      // Leave in the hull array only those positions
      // that have a left turn, moving from the prelast hull point
      // through the last one to the next candidate point
      while (
        convHull.length >= 2 &&
        cross(convHull[convHull.length - 2], convHull[convHull.length - 1], positions[i]) <= 0
      ) {
        convHull.pop()
      }

      convHull.push(positions[i])
    }

    return convHull
  }

  /**
   * Calculates the gravity center of part
   * @param positions Vertices of the part
   * @param facets Facets of the part
   * @returns Coordinates of gravity center
   */
  private calcGravityCenter(positions: Vector3[], facets: Facet[]): Vector3 {
    // Calc geometric center
    const t: Vector3 = Vector3.Zero()
    for (const pos of positions) {
      t.addInPlace(pos)
    }

    t.scaleInPlace(1 / positions.length)

    // Calc volume center
    let volume = 0
    const center: Vector3 = Vector3.Zero()
    const volumes: number[] = []
    const centers: Vector3[] = []
    for (const f of facets) {
      const at = positions[f.a].subtract(t)
      const bt = positions[f.b].subtract(t)
      const ct = positions[f.c].subtract(t)

      const pv =
        (at.x * bt.y * ct.z +
          at.y * bt.z * ct.x +
          at.z * bt.x * ct.y -
          (at.z * bt.y * ct.x + at.x * bt.z * ct.y + at.y * bt.x * ct.z)) /
        6
      let pc = positions[f.a].add(positions[f.b])
      pc = pc.add(positions[f.c])
      pc = pc.add(t)
      pc = pc.scaleInPlace(1 / 4)

      const x = pc.x * pv
      const y = pc.y * pv
      const z = pc.z * pv

      center.addInPlace(new Vector3(x, y, z))
      volume += pv
    }

    center.scaleInPlace(1 / volume)

    return center
  }

  /**
   * Calculates contour of part/support footprint zone
   * @param lowerestZ The lowerest bottom point of the part or support
   */
  private setFootprintZones(lowerestZ: number) {
    const reIndex = (facets: Facet[], startIdx: number): Facet[] => {
      for (const f of facets) {
        f.a += startIdx
        f.b += startIdx
        f.c += startIdx
      }

      return facets
    }

    for (const itemId of ItemsGeometry.itemsGeometry.keys()) {
      const meshCenters: Vector3[] = []
      const meshFootprints: Footprint[] = []
      const itm = ItemsGeometry.itemsGeometry.get(itemId)

      let isAssembly = false
      for (const mId of itm.positions.keys()) {
        let pos: Vector3[] = []
        let fac: Facet[] = []

        if (itm.positions.size > 0 && itm.extraPositions.size > 0) {
          isAssembly = true
          // Item has production(s) and support(s)
          // Merge positions to obtain a common footprint
          for (const pId of itm.positions.keys()) {
            const facets = reIndex(itm.facets.get(pId), pos.length)
            fac = fac.concat(facets)
            pos = pos.concat(itm.positions.get(pId))
          }

          for (const eId of itm.extraPositions.keys()) {
            const facets = reIndex(itm.facets.get(eId), pos.length)
            fac = fac.concat(facets)
            pos = pos.concat(itm.extraPositions.get(eId))
          }
        } else {
          // Item has production(s) or coupon(s) only
          // Create footprint for each set of positions
          pos = itm.positions.get(mId)
          fac = itm.facets.get(mId)
        }

        const footPrintHull = this.makeFootprintHull(pos, lowerestZ)
        if (footPrintHull.length === 0) {
          // Mesh doesn't touch to sinterplate
          continue
          // Extract 'flying' footprint hull for unstable mesh
          // let positions: Position[] = []
          // positions = positions.concat(pos)
          // // Sort by Z coordinates and take the first element as the lowerest
          // positions.sort((a, b) => {
          //   return a.z !== b.z ? a.z - b.z : a.x - b.x
          // })
          //
          // footPrintHull = this.makeFootprintHull(pos, positions[0].z)
        }

        const xyobb = this.getOBB(footPrintHull)
        meshFootprints.push({ meshId: mId, positions: footPrintHull, xyOBB: xyobb, unstable: false })
        meshCenters.push(this.calcGravityCenter(pos, fac))

        if (isAssembly) {
          break
        }
      }

      this.footprintZones.set(itemId, {
        centers: meshCenters,
        footprints: meshFootprints,
      })
    }
  }

  /**
   * Builds convex hull of part/support footprint zone
   * @param positions
   * @param lowerestZ
   * @returns Collection of footprint contour 3D points
   */
  private makeFootprintHull(positions: Vector3[], lowerestZ: number): Vector3[] {
    const lowermostPositions: Vector3[] = []
    let pos: Vector3[] = []
    pos = pos.concat(positions)

    // Collect lowermost vertices
    pos.sort((a, b) => {
      return a.z !== b.z ? a.z - b.z : a.x - b.x
    })

    for (const p of pos) {
      if (Math.abs(p.z - lowerestZ) > this.tolerance) {
        // Positions were sorted by Z coordinates.
        // The search is interrupted when a position upper than the lowerestZ is found
        break
      }
      // Use the lowest Z-coordinate to make the footprint as a flat surface
      p.z = lowerestZ
      lowermostPositions.push(p)
    }

    // Arrange lowermost vertices by x, then y
    lowermostPositions.sort((a, b) => {
      return a.x !== b.x ? a.x - b.x : a.y - b.y
    })

    // Get convex hull of the lowermost vertices
    return this.buildConvexHull(lowermostPositions)
  }

  /**
   * Calculates cross of 3D vectors
   * @param a First vector
   * @param b Second vector
   * @returns 3D vector
   */
  private cross3D(a: Vector3, b: Vector3): Vector3 {
    const c: Vector3 = Vector3.Zero()
    c.x = a.y * b.z - a.z * b.y
    c.y = a.z * b.x - a.x * b.z
    c.z = a.x * b.y - a.y * b.x
    return c
  }

  /**
   * Calculates normal of facet
   * @param vertices Vertices of facet
   * @returns Normal of facet
   */
  private calcFacetNormal(vertices: Vector3[]) {
    const ab = vertices[1].subtract(vertices[0])
    const ac = vertices[2].subtract(vertices[0])
    const n = ab.cross(ac).normalize()
    return n
  }

  /**
   * Calculates distance between two 2D points
   * @param pointA First point
   * @param pointB Second point
   * @returns Distance between points
   */
  private distanceSquare2D(pointA: Vector2, pointB: Vector2): number {
    return (pointA.x - pointB.x) * (pointA.x - pointB.x) + (pointA.y - pointB.y) * (pointA.y - pointB.y)
  }
}
