
import Vue from 'vue'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'

import StoresNamespaces from '@/store/namespaces'
import { Prop, Watch } from 'vue-property-decorator'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemType } from '@/types/FileExplorer/ItemType'
import { SceneMode } from '@/visualization/types/SceneTypes'

const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component
export default class PartPreview extends Vue {
  @visualizationStore.Getter getAddPartPreviewLoading: boolean

  @visualizationStore.Action loadPartConfig: Function

  @visualizationStore.Mutation disposePreview: Function
  @visualizationStore.Mutation clearDetailsPreview: Function
  @visualizationStore.Mutation initDetailsPreview: Function

  @Prop() item: FileExplorerItem
  @Prop({ default: false }) show: boolean

  async processItem(item: FileExplorerItem) {
    if (item.itemType !== ItemType.BuildPart) {
      return
    }

    // update preview only part item
    try {
      this.loadPartConfig({
        partId: item.id,
        partName: item.name,
        skipGlobalLoading: true,
        customLoader: 'setAddPartPreviewLoading',
      })
    } catch (error) {
      console.error(`Error while receiving data: ${error}`)
    }
  }

  @Watch('item')
  async onItemChange() {
    if (this.item) {
      this.initDetailsPreview({
        canvasId: `visualization_canvas_preview`,
        sceneMode: SceneMode.PreviewDetails,
      })
      await this.processItem(this.item)
    } else {
      this.clearDetailsPreview()
      this.disposePreview()
    }
  }
}
