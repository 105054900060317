enum ViewModeTypes {
  Layout = 'BuildPlanLayoutTab',
  Orientation = 'BuildPlanOrientationTab',
  Support = 'BuildPlanSupportTab',
  Nesting = 'BuildPlanNestingTab',
  SimulationCompensation = 'BuildPlanSimulationCompensationTab',
  CrossSection = 'BuildPlanCrossSectionMode',
  Slicing = 'BuildPlanSlicingMode',
  Part = 'PartTab',
  Print = 'BuildPlanPrintOrderTab',
  Publish = 'BuildPlanPublishTab',
  PartPreview = 'PartPreviewTab',
  PartLayout = 'PartLayoutTab',
  BuildPlanPreview = 'BuildPlanPreview',
  Marking = 'BuildPlanMarkingTab',
  Move = 'BuildPlanMoveTab',
  Rotate = 'BuildPlanRotateTab',
  Scale = 'BuildPlanScaleTab',
  Duplicate = 'BuildPlanDuplicateTab',
  Constrain = 'BuildPlanConstrainTab',
  TransferProps = 'BuildPlanTransferProps',
  PrintOrderPreview = 'PrintOrderPreviewPage',
  Deviation = 'PrintOrderPreviewDeviation',
  Replace = 'BuildPlanReplaceTab',
  Inspections = 'Inspections',
  DeviationCompensate = 'IBCPlanDeviationCompensateTab',
  ClearanceTool = 'BuildPlanClearanceTool',
  IBCPlan = 'IBCPlan',
}

export default ViewModeTypes
