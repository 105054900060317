import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'

// 60 minutes
const linkExpireTimeout = 60 * 60 * 1000

const cadHelperStore = namespace(StoresNamespaces.CadHelper)

@Component
export default class CadHelperLinkUpdateMixin extends Vue {
  @cadHelperStore.Action('fetchLatestVersion') fetchLatestVersionOfCadHelper: () => Promise<string>
  @cadHelperStore.Action('fetchDownloadLink') fetchDownloadLinkOfCadHelper: () => Promise<string>
  @cadHelperStore.Action('fetchActualVersion') fetchActualVersionOfCadHelper: () => Promise<string>

  fetchLinksTimeoutId

  destroy() {
    clearInterval(this.fetchLinksTimeoutId)
  }

  async fetchLinks() {
    const cadHelperVersion = this.fetchActualVersionOfCadHelper()
    const cadHelperDownloadLink = this.fetchDownloadLinkOfCadHelper()

    await Promise.all([cadHelperVersion, cadHelperDownloadLink])

    this.fetchLinksTimeoutId = setTimeout(async () => {
      await this.fetchLinks()
    }, linkExpireTimeout)
  }
}
