
import Component from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { extend, ValidationObserver, validate } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import cloneDeep from 'lodash/cloneDeep'

import IToolComponent from '@/types/BuildPlans/IToolComponent'
import CommonBuildPlanToolsMixin from '@/components/layout/buildPlans/mixins/CommonBuildPlanToolsMixin'
import { InputOutsideMixin } from '@/components/layout/mixins/InputOutsideMixin'
import NumberField from '@/components/controls/Common/NumberField.vue'
import SpinButtons from '@/components/controls/Common/SpinButtons.vue'
import StoresNamespaces from '@/store/namespaces'
import {
  IBuildPlan,
  IBuildPlanItem,
  IDisplayToolbarState,
  ISelectable,
  Visibility,
} from '@/types/BuildPlans/IBuildPlan'
import ITransformationDelta from '@/types/BuildPlans/ITransformationDelta'
import { ToolTypes } from '@/types/BuildPlans/ToolTypes'
import { IConstraintsOptions } from '@/types/BuildPlans/IConstraints'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { DragCommand } from '@/types/UndoRedo/DragCommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import BuildPlanStickyComponent from '@/components/layout/buildPlans/stickyToPart/BuildPlanStickyComponent.vue'
import { ToolNames } from './BuildPlanSidebarTools'

const commonStore = namespace(StoresNamespaces.Common)
const commandManager = namespace(StoresNamespaces.CommandManager)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

const SINTER_PART_ROTATION_VALUE = 180
const SINTER_PLAN_ROTATION_VALUE = 90
const DEFAULT_ROTATION_INCREMENT = 5
const defaultRotateModel: ITransformationDelta = { x: null, y: null, z: null }
const sinterPlanRotateConstraints: IConstraintsOptions = {
  x: true,
  y: true,
  z: false,
  variability: false,
}

@Component({
  components: {
    NumberField,
    SpinButtons,
    ValidationObserver,
    BuildPlanStickyComponent,
  },
})
export default class BuildPlanRotateTab
  extends Mixins(CommonBuildPlanToolsMixin, InputOutsideMixin)
  implements IToolComponent
{
  @commonStore.Getter tooltipOpenDelay: number

  @commandManager.Getter getToolUndoStack: ICommand[]
  @commandManager.Mutation addCommand: (command: ICommand) => void

  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter('getSelectedBuildPlanItems') buildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter isSinterPlan: boolean
  @buildPlansStore.Getter displayToolbarStateByVariantId: (buildPlanId: string) => IDisplayToolbarState
  @buildPlansStore.Getter getSelectedParts: ISelectable[]

  @buildPlansStore.Action('restoreImportedRotation') restoreImportedRotation: () => void
  @buildPlansStore.Action changeBuildPlanVolumeVisibility: (isVisble: boolean) => void
  @buildPlansStore.Action changeRecoaterDirectionLabelVisibility: (isVisible: boolean) => void
  @buildPlansStore.Action changePrintHeadVisibility: (isVisible: boolean) => void

  @visualizationStore.Getter isMouseOverCanvas: boolean
  @visualizationStore.Getter isDownwardPlaneRotationInitialized: boolean
  @visualizationStore.Getter isDownwardPlaneRotationInProgress: boolean
  @visualizationStore.Getter isDisplayFlipArrow: boolean
  @visualizationStore.Getter flipArrowLocation: { x: number; y: number }
  @visualizationStore.Mutation('updateRotateIncrement') updateRotateIncrement: Function
  @visualizationStore.Mutation selectAndHighlightPartWithAttach: Function
  @visualizationStore.Mutation toggleDownwardPlaneRotationInitialization: Function
  @visualizationStore.Mutation flipSelectedParts: Function
  @visualizationStore.Mutation displayFlipArrow: Function
  @visualizationStore.Mutation getItemsBoundingBox2D: (itemIds: string[]) => void
  @visualizationStore.Mutation setRotatePartsIndependentlyMode: Function
  @visualizationStore.Action setBpItemVisibility: (payload: {
    bpItem: IBuildPlanItem
    makeVisible: boolean
    showAsTransparent: boolean
  }) => void

  /**************************************
   * Generic tool method implementations
   **************************************/
  // need to mention these generic optional methods even if they are not implemented by the tool
  // due to TypeScript's weak type detection per https://stackoverflow.com/a/47930521
  clickOk?: () => void
  clickCancel?: () => void

  $refs!: {
    incrementField: NumberField
  }

  rotateIncrementValue: number = null
  rotateModel = { ...defaultRotateModel }

  rotateAboutAxisRules = {
    in_one_of_intervals: [
      { min: -359.999, max: -0.001 },
      { min: 0.001, max: 359.999 },
    ],
  }

  oldIsShowingBuildPlanVolume: boolean

  canvasOffset = { x: 0, y: 0 }
  isTakenByDownwardRotation: boolean = false

  areRotatePartsIndependently: boolean = false

  get rotateIncrementRules() {
    return {
      in_range_included: {
        leftLimit: 0.001,
        rightLimit: 359.999,
        units: this.$i18n.t('degreeAbbr'),
      },

      required_in_range: {
        leftLimit: 0.001,
        rightLimit: 359.999,
        units: this.$i18n.t('degreeAbbr'),
      },
    }
  }

  beforeMount() {
    this.extendValidationRules()
    if (this.isSinterPlan) {
      this.oldIsShowingBuildPlanVolume = this.displayToolbarStateByVariantId(this.buildPlan.id).isShowingBuildPlanVolume
      this.selectAndHighlightPartWithAttach({
        buildPlanItemIds: this.buildPlan.buildPlanItems.map((bpItem) => bpItem.id),
      })

      this.changeBuildPlanVolumeVisibility(true)
    }
  }

  mounted() {
    this.initRotationIncrementValue()
  }

  async initRotationIncrementValue() {
    let value: number

    if (this.isSinterPartSelected) {
      value = SINTER_PART_ROTATION_VALUE
    } else if (this.isSinterPlan) {
      value = SINTER_PLAN_ROTATION_VALUE
    } else {
      let savedValue = this.buildPlan.settings.rotate.increment
      if (savedValue === null || savedValue === undefined) {
        savedValue = DEFAULT_ROTATION_INCREMENT
      }
      const { valid } = await validate(savedValue, this.rotateIncrementRules)
      value = valid ? savedValue : DEFAULT_ROTATION_INCREMENT
    }

    this.rotateIncrementValue = value
  }

  @Watch('flipArrowLocation')
  onFlipArrowLocationChanged() {
    this.canvasOffset = this.flipArrowLocation
  }

  @Watch('isDisplayFlipArrow')
  onFlipArrowDisplayChanged() {
    if (this.isTakenByDownwardRotation) {
      return
    }

    this.setListenerForInputOutside(this.isDisplayFlipArrow, 'flip-arrow-wrapper', () => this.displayFlipArrow(false))
  }

  @Watch('isDownwardPlaneRotationInitialized')
  onDownwardPlaneRotationInitialized(value: boolean) {
    this.isTakenByDownwardRotation = value
    this.setListenerForInputOutside(this.isDownwardPlaneRotationInitialized, 'downward-plane-wrapper', () => {
      if (!this.isMouseOverCanvas) {
        this.toggleDownwardPlaneRotationInitialization({ isDownwardPlaneRotationInitialized: false })
      }
    })
    this.buildPlanItems.forEach((bpItem) => {
      if (bpItem.visibility === Visibility.Hidden) {
        if (this.isDownwardPlaneRotationInitialized) {
          // Display the selected part as opaque but do not change Visibility settings ob bpItem.
          this.setBpItemVisibility({ bpItem, makeVisible: true, showAsTransparent: false })
        } else {
          // Turn back initial display settings of hidden part. Show part as transparent because it's selected.
          this.setBpItemVisibility({ bpItem, makeVisible: false, showAsTransparent: true })
        }
      }
    })
  }

  @Watch('isSinterPartSelected')
  async onIsSinterPartSelectedChange(value) {
    if (value) {
      this.rotateIncrementValue = SINTER_PART_ROTATION_VALUE
      await this.updateBuildPlanIncrementValue()
    }
  }

  flipParts() {
    this.flipSelectedParts()
    this.displayFlipArrow(false)
  }

  shouldShowConstraintsLock(axis: string) {
    if (this.buildPlanItems.length === 0) {
      return false
    }

    let result: boolean
    if (this.buildPlan.subType === ItemSubType.SinterPlan) {
      result = false
    } else {
      result = this.isSinterPlan
        ? sinterPlanRotateConstraints[axis]
        : this.getToolConstraints(ToolTypes.RotateTool, this.buildPlanItems[0])[axis]
    }
    return result
  }

  onUpSpinClickX() {
    this.performTransformationDelta(ToolTypes.RotateTool, {
      ...defaultRotateModel,
      x: this.rotateIncrementValue,
    })
  }

  onDownSpinClickX() {
    this.performTransformationDelta(ToolTypes.RotateTool, {
      ...defaultRotateModel,
      x: this.rotateIncrementValue * -1,
    })
  }

  onUpSpinClickY() {
    this.performTransformationDelta(ToolTypes.RotateTool, {
      ...defaultRotateModel,
      y: this.rotateIncrementValue,
    })
  }

  onDownSpinClickY() {
    this.performTransformationDelta(ToolTypes.RotateTool, {
      ...defaultRotateModel,
      y: this.rotateIncrementValue * -1,
    })
  }

  onUpSpinClickZ() {
    this.performTransformationDelta(ToolTypes.RotateTool, {
      ...defaultRotateModel,
      z: this.rotateIncrementValue,
    })
  }

  onDownSpinClickZ() {
    this.performTransformationDelta(ToolTypes.RotateTool, {
      ...defaultRotateModel,
      z: this.rotateIncrementValue * -1,
    })
  }

  onExitRequested(event?: Event) {
    this.displayFlipArrow(false)
  }

  async updateBuildPlanIncrementValue() {
    const { valid } = await this.$refs.incrementField.validate({ silent: true })

    if (valid) {
      this.updateRotateIncrement(this.rotateIncrementValue)

      const isSavedValueChanged = this.buildPlan.settings.rotate.increment !== this.rotateIncrementValue
      if (isSavedValueChanged) {
        const clonedBuildPlan: IBuildPlan = cloneDeep(this.buildPlan)
        clonedBuildPlan.settings.rotate.increment = this.rotateIncrementValue

        await this.updateBuildPlanIncrement(clonedBuildPlan)
      }
    }
  }

  async onCalculateRotation(axis: string) {
    if (this.rotateModel[axis] === 0) {
      return
    }

    const rotate = { ...defaultRotateModel }
    rotate[axis] = this.rotateModel[axis]

    await this.performTransformationDelta(ToolTypes.RotateTool, rotate)
    this.rotateModel[axis] = null
  }

  async onUpdateAxis(propertyName: string, propertyValue: false) {
    const constraintOptions = this.getToolConstraints(ToolTypes.RotateTool, this.buildPlanItems[0])
    constraintOptions[propertyName] = propertyValue
    await this.updateSelectedConstraint(ToolTypes.RotateTool, this.buildPlanItems[0], constraintOptions)
  }

  beforeDestroy() {
    if (this.isDownwardPlaneRotationInitialized) {
      this.toggleDownwardPlaneRotationInitialization({ isDownwardPlaneRotationInitialized: false })
    }

    if (!this.isSinterPlan) {
      this.setRotatePartsIndependentlyMode(false)
    } else {
      this.changeBuildPlanVolumeVisibility(this.oldIsShowingBuildPlanVolume)
    }

    this.displayFlipArrow(false)
  }

  extendValidationRules() {
    extend('in_one_of_intervals', {
      validate: (value: number, { intervals }: { intervals: Array<{ min: number; max: number }> }) => {
        return intervals.some(({ min, max }) => value <= max && value >= min)
      },
      params: ['intervals'],
    })

    extend('required_in_range', {
      ...required,
      params: ['leftLimit', 'rightLimit', 'units'],
      message: this.$i18n.t('inRangeValidationMessage').toString(),
    })

    extend('in_range_included', {
      validate: (
        value: number,
        { leftLimit, rightLimit, units }: { leftLimit: string; rightLimit: string; units: string },
      ) => {
        return value >= +leftLimit && value <= +rightLimit
      },
      params: ['leftLimit', 'rightLimit', 'units'],
      message: this.$i18n.t('inRangeValidationMessage').toString(),
    })
  }

  get isSinterPartSelected() {
    return this.buildPlanItems.some(
      (selectedBpItem) =>
        selectedBpItem.part &&
        (selectedBpItem.part.subType === ItemSubType.SinterPart || selectedBpItem.part.subType === ItemSubType.IbcPart),
    )
  }

  get rotateIncrementCustomMessages() {
    return {
      min_value: this.$t('rotateIncrementValidationMessage'),
      max_value: this.$t('rotateIncrementValidationMessage'),
    }
  }

  get rotateAboutAxisCustomMessages() {
    return {
      in_one_of_intervals: this.$t('rotateAboutAxisValidationMessage'),
    }
  }

  get isDownwardPlaneEnabled() {
    return this.isDownwardPlaneRotationInitialized
  }

  set isDownwardPlaneEnabled(isEnabled: boolean) {
    this.toggleDownwardPlaneRotationInitialization({ isDownwardPlaneRotationInitialized: isEnabled })
  }

  clickClose() {
    this.saveUndoData()
  }

  isInvalid(errors: { [key: string]: [] }, fieldName: string): boolean {
    return errors[fieldName] && !!errors[fieldName].length
  }

  toggleRotatePartsIndependentlyMode() {
    this.setRotatePartsIndependentlyMode(this.areRotatePartsIndependently)
  }

  private saveUndoData() {
    /**
     * GEAMPREQ-141
     * If the tool was completed by clicking the “Close” and a change was done – the stored build plan stack
     * will be used again, and the tool undo stack will be added to it
     */
    const undoCommands = this.getToolUndoStack.filter((command) => command instanceof DragCommand) as DragCommand[]
    undoCommands.forEach((command) => {
      command.commandType = CommandType.BuildPlanCommand
      command.toolName = ToolNames.ROTATE
      this.addCommand(command)
    })
  }
}
