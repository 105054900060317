
import Menu from '@/components/controls/Common/Menu.vue'
import { eventBus } from '@/services/EventBus'
import { ContextMenuItem } from '@/types/FileExplorer/ContextMenuItem'
import { FileExplorerEvent } from '@/types/FileExplorer/FileExplorerEvent'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import ItemContextMenu from './ItemContexMenu.vue'
import AllFilesSearchViewMixin from './mixins/AllFilesSearchViewMixin'

const ICON_HEIGHT = 18

@Component({
  components: { Menu, ItemContextMenu },
})
export default class RowItemMenu extends mixins(AllFilesSearchViewMixin) {
  @Prop() item: FileExplorerItem
  @Prop() isMenuIconShown: boolean
  @Prop() contextMenuItems: ContextMenuItem[]
  @Prop() beforeContextMenuOpen: () => Promise<void>

  async showContextMenu(event, needToTranspose = false) {
    if (this.isShownContextMenu) {
      this.isShownContextMenu = false
      return
    }

    const x = needToTranspose ? event.target.getBoundingClientRect().left : event.clientX
    const y = needToTranspose ? event.target.getBoundingClientRect().top + ICON_HEIGHT : event.clientY
    await this.setupContextMenu(this.item, x, y)

    if (this.beforeContextMenuOpen) {
      await this.beforeContextMenuOpen()
    }

    if (this.getSelectedItem.id === this.item.id) {
      this.isShownContextMenu = true
    }
  }

  beforeMount() {
    eventBus.$on(FileExplorerEvent.Scroll, this.clearContextMenu)
  }

  clearContextMenu() {
    this.isShownContextMenu = false
  }

  beforeDestroy() {
    eventBus.$off(FileExplorerEvent.Scroll, this.clearContextMenu)
  }

  menuIconClicked(event) {
    this.$emit('click', { item: this.item })
    this.showContextMenu(event, true)
  }
}
