
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import FallbackImage from '@/components/layout/FallbackImage.vue'

@Component({
  components: {
    FallbackImage,
  },
})
export default class MachineSelectorTemplate extends Vue {
  @Prop() item: any

  get buildVolumeParamsTitle() {
    return this.item.isCircleEnvelopeShapedMachine
      ? this.$t('buildVolumeForCircleEnvelopeShapeParams')
      : this.$t('buildVolumeWithParams')
  }

  get buildVoulume() {
    return this.item.isCircleEnvelopeShapedMachine
      ? `${this.roundToInteger(this.item.buildChamberX)} x ${this.roundToInteger(this.item.buildChamberZ)} mm`
      : `${this.roundToInteger(this.item.buildChamberX)} x ${this.roundToInteger(
          this.item.buildChamberY,
        )} x ${this.roundToInteger(this.item.buildChamberZ)} mm`
  }

  roundToInteger(num: number) {
    return +num.toFixed(0)
  }
}
