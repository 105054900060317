import { del, get, post, put } from '@/api/http'
import { EndpointsUrls } from '@/configs/config'
import {
  IBuildPlan,
  IBuildPlanDto,
  ICreateBuildPlanDto,
  IParameterSet,
  IProductionSet,
  IBuildPlanLockDto,
  SliceAlignment,
  IVariantItem,
} from '@/types/BuildPlans/IBuildPlan'
import { ISlice } from '@/types/BuildPlans/ISlice'
import { ISliceError } from '@/types/BuildPlans/ISliceError'
import { IDmlmSimulateCompensation, IBinderJetSimulateCompensation } from '@/types/Simulation/SimulationCompensation'
import { BuildPlanCostCreateDTO } from '@/pages/BuildPlans/dtos/BuildPlanCostCreateDTO'
import { IMarkRecipe } from '@/types/Marking/IMarkRecipe'
import { IMachineConfig, PrintingTypes } from '@/types/IMachineConfig'
import { handleAPIError } from './common'
import * as msgpack from '@ygoe/msgpack'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { ISinteringSurface } from '@/types/ISinteringSurface'
import { BuildPlanPrintStrategyDto, ParametersValidationReport } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { INCORRECT_UUID_MESSAGE_ERROR_2 } from '@/constants'
import messageService from '@/services/messageService'
import i18n from '@/plugins/i18n'
import { IPhysicalProperty } from '@/types/IMaterial'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { MachineConfigMaterialBinder } from '../types/Sites/Site'
import {
  IbcJobConfiguration,
  IbcMeasurementDeleteDto,
  IIBCPlan,
  IMeasurementWithProperties,
} from '@/types/IBCPlans/IIBCPlan'
import { IJob } from '@/types/PartsLibrary/Job'
import { IPartDto } from '@/types/PartsLibrary/Parts'

export default {
  /**
   * Converts build plan object into an entity that is accepted by API services
   * @param buildPlan - build plan that needs to be transferred to the server
   */
  createDto(buildPlan: IBuildPlan): IBuildPlanDto {
    return {
      buildPlanItems: buildPlan.buildPlanItems.map((bpItem) => ({
        partName: bpItem.part.name,
        transformationMatrix: bpItem.transformationMatrix,
        orientCriteria: bpItem.orientCriteria,
      })),
      buildPlanName: buildPlan.name,
      buildPlateId: buildPlan.buildPlateId,
      buildPlateVersion: buildPlan.buildPlateVersion,
      machineConfigId: buildPlan.machineConfigId,
      machineConfigVersion: buildPlan.machineConfigVersion,
      materialId: buildPlan.materialId,
      materialVersion: buildPlan.materialVersion,
      printStrategyId: buildPlan.printStrategyId,
      printStrategyVersion: buildPlan.printStrategyVersion,
      modality: buildPlan.modality,
      absolutePath: buildPlan.path ? buildPlan.path : `/${buildPlan.name}`,
      parentId: buildPlan.parentId,
      recoaterAngle: buildPlan.recoaterAngle,
    }
  },

  async createBuildPlanVariant(buildPlanId: string) {
    try {
      return await post(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/variant`, {})
    } catch (error) {
      handleAPIError(error)
    }
  },

  async lockBuildPlanVariant(itemId: string) {
    try {
      return await put<IBuildPlanLockDto>(`${EndpointsUrls.Items}/${itemId}/setIsLocked`, {})
    } catch (error) {
      handleAPIError(error)
    }
  },

  async unlockBuildPlanVariant(itemId: string) {
    try {
      return await put(`${EndpointsUrls.Items}/${itemId}/unsetIsLocked`, {})
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getBuildPlanLockInfo(itemId: string) {
    try {
      return await get<IBuildPlanLockDto>(`${EndpointsUrls.Items}/${itemId}/basicInfo`, {})
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getBuildPlanVariantsLockInfos(itemId: string) {
    try {
      return await get<IBuildPlanLockDto[]>(`${EndpointsUrls.Items}/${itemId}/variants`, {})
    } catch (error) {
      handleAPIError(error)
    }
  },

  async setVariantLabel(itemId: string, label: string) {
    try {
      return await put(`${EndpointsUrls.Items}/${itemId}/setVersionLabel`, { label })
    } catch (error) {
      handleAPIError(error)
      return false
    }
  },

  // This method creates an build plan using new api
  async createBuildPlan(bpDto: ICreateBuildPlanDto) {
    try {
      return await post(`${EndpointsUrls.BuildPlansV1}`, bpDto)
    } catch (error) {
      handleAPIError(error)
      return error.data
    }
  },

  async createSliceJob(buildPlanId: string, alignOptionUsed: SliceAlignment) {
    try {
      const route = `${EndpointsUrls.BuildPlansV1}/${buildPlanId}/slice?computeType=DMLM&alignment=${alignOptionUsed}`
      return await post(route, {})
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getJobs(buildPlanId: string, hideAPIErrorMessages = false) {
    if (!buildPlanId) {
      handleAPIError('Incorrect build plan id!', hideAPIErrorMessages)
      return []
    }

    try {
      return await get<any>(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/jobs`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getFiles(name: string, config?: any) {
    try {
      return await get<any>(`${EndpointsUrls.BuildPlansV1}/file/${name}`, config)
    } catch (error) {
      return []
    }
  },

  async getSliceLayers(buildPlanId: string, printJobId?: string) {
    try {
      let sliceLayersEndpoint = `${EndpointsUrls.BuildPlansV1}/${buildPlanId}/slice/layers`
      if (printJobId) {
        sliceLayersEndpoint = sliceLayersEndpoint.concat(`?printJobId=${printJobId}`)
      }
      return await get<number[]>(sliceLayersEndpoint)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getSliceJobById(buildPlanId: string, jobId: any) {
    try {
      const jobs = await this.getJobs(buildPlanId)
      if (jobs.length) {
        const filteredJobs = jobs.filter((job) => job.id === jobId)[0]
        return filteredJobs
      }
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getRelatedBuildPlanVariants(buildPlanId: string): Promise<IVariantItem[]> {
    try {
      return await get<IVariantItem[]>(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/related-variants`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getBuildPlanById(id: string, hideAPIErrorMessages = false): Promise<IBuildPlan> {
    try {
      return await get<IBuildPlan>(`${EndpointsUrls.BuildPlansV1}/${id}`)
    } catch (error) {
      handleAPIError(error, hideAPIErrorMessages)
      throw error
    }
  },

  async updateBuildPlanV1(bpDto: IBuildPlan, bpId: string, hideAPIErrorMessages = false) {
    try {
      return await put<IBuildPlan>(`${EndpointsUrls.BuildPlansV1}/${bpId}`, bpDto)
    } catch (error) {
      handleAPIError(error, hideAPIErrorMessages)
    }
  },

  async deleteBuildPlanItems(bpItemIds: string[]) {
    if (!bpItemIds.length) {
      return Promise.resolve(0)
    }

    try {
      const url = `${EndpointsUrls.BuildPlanItems}/delete`
      return await post<number>(url, bpItemIds)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getBuildPlanSlice(buildPlanId: string, micronOffset: number, printJobId?: string) {
    try {
      let buildPlanSliceUrl = `${EndpointsUrls.BuildPlansV1}/${buildPlanId}/slice?micronOffset=${micronOffset}`
      if (printJobId) {
        buildPlanSliceUrl = buildPlanSliceUrl.concat(`&printJobId=${printJobId}`)
      }
      return await get<ISlice[]>(buildPlanSliceUrl)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getBuildPlanSliceErrors(buildPlanId: string) {
    try {
      const buildPlanSliceErrorUrl = `${EndpointsUrls.BuildPlansV1}/${buildPlanId}/slice/errors`
      return await get<ISliceError[]>(buildPlanSliceErrorUrl)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getMachineConfigs() {
    try {
      return await get<IMachineConfig[]>(EndpointsUrls.MachineConfigs)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getMachineConfigById(id: number) {
    try {
      return await get<IMachineConfig>(`${EndpointsUrls.MachineConfigs}/${id}`)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getMaterials() {
    try {
      return await get<string[]>(EndpointsUrls.Materials)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getProductionSets() {
    try {
      return await get<IProductionSet[]>(`${EndpointsUrls.ProductionSets}`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getProductionSetById(id: number) {
    return get<IProductionSet>(`${EndpointsUrls.ProductionSets}/${id}`)
  },

  async renameProductionSet(id: number, name: string) {
    return put(`${EndpointsUrls.ProductionSets}/${id}/rename`, { name })
  },

  async updateProductionSetContent(id: number, content: object) {
    return put<ParametersValidationReport>(`${EndpointsUrls.ProductionSets}/${id}/save`, content)
  },

  async getParameterSets() {
    try {
      const parameterSet = `${EndpointsUrls.ParameterSets}`
      return await get<IParameterSet[]>(parameterSet)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getParameterSetById(id: number) {
    return get<IParameterSet>(`${EndpointsUrls.ParameterSets}/${id}`)
  },

  async renameParameterSet(id: number, name: string) {
    return put(`${EndpointsUrls.ParameterSets}/${id}/rename`, { name })
  },

  async updateParameterSetContent(id: number, content: object) {
    return put<ParametersValidationReport>(`${EndpointsUrls.ParameterSets}/${id}/save`, content)
  },

  async createOrientJob(buildPlanId: string, data: any) {
    try {
      return await post(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/orient`, data)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async createMarkJob(buildPlanId: string, data: IMarkRecipe) {
    try {
      return await post<FormData>(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/mark`, data)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async createPrintJob(data: any) {
    try {
      return await post(`${EndpointsUrls.BuildPlansV1}/${data.buildPlanId}/print`, data)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async createMarkPrintJob(buildPlanId: string, data: any) {
    try {
      return await post(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/mark-print`, data)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async createExposureJob(buildPlanId: string, sliceJobId: string) {
    try {
      return await post(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/exposure?sliceJobNumber=${sliceJobId}`, {})
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getJobOutputFiles(buildPlanId: string, jobNumber: number) {
    try {
      return await get<any>(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/jobs/${jobNumber}/outputFiles`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getAllFiles(buildPlanId: string) {
    try {
      return await get<any>(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/files`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getSignedUrl(filekey: string) {
    try {
      return await post<any>(`${EndpointsUrls.Files}/${filekey}/signedUrl`, {})
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getHeadObject(s3filename: string) {
    try {
      return await get<any>(`${EndpointsUrls.Files}/headObject/?s3FileName=${s3filename}`)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getOrientOuput(buildPlanId: string, jobNumber: number) {
    try {
      return await get<any>(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/orient?jobNumber=${jobNumber}`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },
  async getS3ZipMetaInfo(fileKey: string) {
    try {
      return await get<any>(`${EndpointsUrls.S3Zip}/${fileKey}/meta-info`)
    } catch (error) {
      return []
    }
  },
  async getSpecificFileFromZipFile(fileKey: string, fileName: string) {
    try {
      return await get<any>(`${EndpointsUrls.S3Zip}/${fileKey}/file/${fileName}?tifftopng=true`, {
        responseType: 'blob',
      })
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async createDmlmSimulationCompensation(
    buildPlanId: string,
    data: IDmlmSimulateCompensation,
    hideAPIErrorMessages: boolean = false,
  ) {
    try {
      return await post(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/dmlm/simulate`, data)
    } catch (error) {
      handleAPIError(error, hideAPIErrorMessages)
    }
  },

  async createBinderJetSimulationCompensation(
    buildPlanId: string,
    data: IBinderJetSimulateCompensation,
    hideAPIErrorMessages: boolean = false,
  ) {
    try {
      return await post(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/binderjet/simulate`, data)
    } catch (error) {
      handleAPIError(error, hideAPIErrorMessages)
    }
  },

  async getJsonFileContent(fileKey: string) {
    try {
      return await get<any>(`${EndpointsUrls.BuildPlansV1}/${fileKey}/content`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async createNestingJob(buildPlanId: string, hideAPIErrorMessages: boolean = false) {
    try {
      return await post(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/nest`, {})
    } catch (error) {
      handleAPIError(error, hideAPIErrorMessages)
      return error
    }
  },

  async getPrintSites() {
    try {
      const printSitesUrl = `${EndpointsUrls.Sites}`
      return await get<any>(printSitesUrl)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getMachineConfigMaterialBinders() {
    try {
      return await get<MachineConfigMaterialBinder>(`${EndpointsUrls.Binder}/machineConfigMaterialBinders`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async calcBuildPlanCost(buildPlanId: string, data: BuildPlanCostCreateDTO): Promise<IBuildPlan> {
    try {
      return await post<IBuildPlan>(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/cost`, data)
    } catch (error) {
      throw error
    }
  },

  async orient(
    buildPlanId: string,
    overhangAngle: number,
    buildPlanItemId: string,
    data: boolean[],
    lastSavedAngles: number[],
    requestId: string,
  ): Promise<any> {
    try {
      const sa = overhangAngle
      const itemId = buildPlanItemId
      const payload = { lastSavedAngles, requestId, validOrientation: data }

      const res = await post<any>(
        `${EndpointsUrls.BuildPlansV1}/${buildPlanId}/orientations/${itemId}/${sa}`,
        payload,
        {},
      )

      return { requestId: res.requestId, stream: Buffer.from(res.stream) }
    } catch (error) {
      if (error.message) {
        handleAPIError(error.message)
        return error.message
      }
      if (error.data) {
        try {
          const errorObject = msgpack.deserialize(Buffer.from(error.data))
          handleAPIError(errorObject)
          return errorObject.message
        } catch (error) {
          return 'Some unexpected error occured'
        }
      }
      return 'Some unexpected error occured'
    }
  },

  async publishBuildPlan(buildPlanId: string) {
    return await post(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/publish`, {})
  },

  async getHtFurnanceProfile(materialId: number, materialVersion: number) {
    try {
      return await get<number[]>(`${EndpointsUrls.HeatTreatments}/${materialId}/furnanceProfile`, {
        params: { materialVersion },
      })
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getMaterialCreepDataValidation(materialId: number, materialVersion: number) {
    try {
      return await get<boolean>(`${EndpointsUrls.BuildPlansV1}/${materialId}/creepDataValidation`, {
        params: { materialVersion },
      })
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async fetchInsightsByBuildPlanId(buildPlanId: string) {
    try {
      return await get<IBuildPlanInsight[]>(`${EndpointsUrls.Insights}/${buildPlanId}/insights`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async fetchInsightById(insightId: string) {
    try {
      return await get<IBuildPlanInsight>(`${EndpointsUrls.Insights}/${insightId}`)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async createInsight(insight: IBuildPlanInsight) {
    try {
      return await post<IBuildPlanInsight[]>(`${EndpointsUrls.Insights}`, insight)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async createInsightMultiple(insights: IBuildPlanInsight[]) {
    try {
      return await post<IBuildPlanInsight[]>(`${EndpointsUrls.Insights}/create`, insights)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async deleteInsightMultiple(insightsIds: string[]) {
    try {
      return await post<IBuildPlanInsight[]>(`${EndpointsUrls.Insights}/delete`, insightsIds)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async updateInsightMultiple(insights: IBuildPlanInsight[]) {
    try {
      return await post<IBuildPlanInsight[]>(`${EndpointsUrls.Insights}/batch/update`, insights)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async updateInsight(insight: IBuildPlanInsight) {
    try {
      return await put<IBuildPlanInsight>(`${EndpointsUrls.Insights}`, insight)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async deleteInsight(insightId: string) {
    try {
      return await del(`${EndpointsUrls.Insights}/${insightId}`)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getSinteringSurface(materialId: number, materialVersion: number) {
    try {
      return await get<ISinteringSurface[]>(`${EndpointsUrls.SinteringSurfaces}/${materialId}/sinteringSurfaces`, {
        params: { materialVersion },
      })
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getSignedUrlV1(fileKey: string, newName?: string) {
    try {
      const queryString = newName
        ? `${EndpointsUrls.SignedUrlV1}?s3FileName=${fileKey}&name=${newName}`
        : `${EndpointsUrls.SignedUrlV1}?s3FileName=${fileKey}`
      return await get<any>(queryString)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async setVariantDescription(itemId: string, description: string) {
    try {
      return await put(`${EndpointsUrls.Items}/${itemId}/setVersionDescription`, { description })
    } catch (error) {
      handleAPIError(error)
      return false
    }
  },

  async getBuildPlanVariantJobs(buildPlanId: string) {
    try {
      return await get<any>(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/variantJobs`)
    } catch (error) {
      if (error.message === INCORRECT_UUID_MESSAGE_ERROR_2) {
        const errorMessage = `${i18n.t('incorrectBuildPlanId')}: ${buildPlanId}`
        messageService.showErrorMessage(errorMessage)
      } else {
        handleAPIError(error)
      }
    }
  },

  async setVariantOrder(itemId: string, order: number) {
    try {
      return await put(`${EndpointsUrls.Items}/${itemId}/setVersionOrder`, { order })
    } catch (error) {
      handleAPIError(error)
    }
  },

  async createProductionSet(payload: {
    productionSetFile: File
    geDefault: boolean
    modality: PrintingTypes
    machineConfigId: number
    rasterSettingsFile: File
    machineType: string
  }) {
    try {
      const headers = {
        'Content-Type': 'multipart/form-data',
      }

      const formData = new FormData()
      formData.append('productionSetFile', payload.productionSetFile)
      formData.append('geDefault', String(payload.geDefault))
      formData.append('version', '1')
      formData.append('modality', payload.modality)
      formData.append('machineConfigId', String(payload.machineConfigId))
      formData.append('rasterSettingsFile', payload.rasterSettingsFile)
      formData.append('machineType', payload.machineType)

      return await post<FormData>(`${EndpointsUrls.ProductionSets}`, formData, { headers })
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async deleteBuildPlanVariant(variantId: string) {
    return post(`${EndpointsUrls.Items}/${variantId}/deleteBuildPlanVariant`, {})
  },

  async getBuildPlanPrintStrategy(printStrategyPk: VersionablePk): Promise<BuildPlanPrintStrategyDto> {
    try {
      return await get<BuildPlanPrintStrategyDto>(
        `${EndpointsUrls.PrintStrategiesAdmin}/buildPlanPrintStrategy/${printStrategyPk.id}`,
        {
          params: { printStrategyVersion: printStrategyPk.version },
        },
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getCustomPrintStrategy(buildPlanId: string): Promise<BuildPlanPrintStrategyDto> {
    try {
      return get<BuildPlanPrintStrategyDto>(`${EndpointsUrls.BuildPlansV1}/${buildPlanId}/customPrintStrategy`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async createCustomPrintStrategy(
    buildPlanId: string,
    printStrategyPk: VersionablePk,
  ): Promise<BuildPlanPrintStrategyDto> {
    try {
      return post<BuildPlanPrintStrategyDto>(
        `${EndpointsUrls.BuildPlansV1}/${buildPlanId}/createCustomPrintStrategy`,
        null,
        {
          params: { printStrategyId: printStrategyPk.id, printStrategyVersion: printStrategyPk.version },
        },
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async replaceCustomPrintStrategy(
    buildPlanId: string,
    printStrategyPk: VersionablePk,
  ): Promise<BuildPlanPrintStrategyDto> {
    try {
      return post<BuildPlanPrintStrategyDto>(
        `${EndpointsUrls.BuildPlansV1}/${buildPlanId}/replaceCustomPrintStrategy`,
        null,
        {
          params: { newPrintStrategyId: printStrategyPk.id, newPrintStrategyVersion: printStrategyPk.version },
        },
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getMaterialPhysicalProperties(materialPk: VersionablePk) {
    try {
      return await get<IPhysicalProperty[]>(`${EndpointsUrls.Materials}/${materialPk.id}/physicalProperties`, {
        params: { materialVersion: materialPk.version },
      })
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async checkAccessForEditVariants(itemId: string) {
    try {
      return await get<boolean>(`${EndpointsUrls.Items}/${itemId}/canEdit`)
    } catch (error) {
      handleAPIError(error)
      return false
    }
  },

  async createIBCompensationJob(ibcPlanId: string, ibcJobConfiguration: IbcJobConfiguration) {
    try {
      return await post<IJob>(`${EndpointsUrls.IBCPlans}/${ibcPlanId}/compensate`, ibcJobConfiguration)
    } catch (error) {
      handleAPIError(error)
      throw error
    }
  },

  async fetchIBCompensationJobs(ibcPlanId: string) {
    try {
      return await get<IJob[]>(`${EndpointsUrls.IBCPlans}/${ibcPlanId}/ibcJobs`)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getIBCVariantJobs(ibcPlanId: string, fetchForRelatedVariants: number = 1) {
    try {
      return await get<IJob[]>(
        `${EndpointsUrls.IBCPlans}/${ibcPlanId}/ibcVariantJobs?fetchForRelatedVariants=${fetchForRelatedVariants}`,
      )
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async publishIBCPlan(ibcPlanId: string, resultIndex: number) {
    try {
      return await post(`${EndpointsUrls.IBCPlans}/${ibcPlanId}/publish?resultIndex=${resultIndex}`, {})
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getRelatedIbcPlanVariants(ibcPlanId: string) {
    try {
      return await get<IBuildPlan[]>(`${EndpointsUrls.IBCPlans}/${ibcPlanId}/related-variants`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async createIbcPlanVariant(ibcPlanId: string) {
    try {
      return await post(`${EndpointsUrls.IBCPlans}/${ibcPlanId}/variant`, {})
    } catch (error) {
      handleAPIError(error)
    }
  },

  async deleteMeasurements(measurements: IbcMeasurementDeleteDto[], ibcPlanId: string) {
    try {
      return await post(`${EndpointsUrls.IBCPlans}/${ibcPlanId}/deleteMeasurements`, measurements)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async setIbcPlanItemMeasurement(measurement: IMeasurementWithProperties) {
    try {
      return await put(`${EndpointsUrls.IBCPlans}/setMeasurementsProperties`, [measurement])
    } catch (error) {
      handleAPIError(error)
    }
  },

  async setVisibleWhenPublishedSinterPlan(sinterPlanId: string, visibleWhenPublished: boolean) {
    try {
      return await put<IPartDto[]>(`${EndpointsUrls.BuildPlansV1}/${sinterPlanId}/setVisibleWhenPublished`, { visibleWhenPublished })
    } catch(error) {
      handleAPIError(error)
    }
  },

  async setVisibleWhenPublishedIbcPlan(ibcPlanId: string, visibleWhenPublished: boolean) {
    try {
      return await put<IPartDto[]>(`${EndpointsUrls.IBCPlans}/${ibcPlanId}/setVisibleWhenPublished`, { visibleWhenPublished })
    } catch(error) {
      handleAPIError(error)
    }
  },
}
