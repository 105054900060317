export class ProcessParameterFormViewModel {
  attenXYLength: number = null
  attenXPlus = false
  attenXMinus = false
  attenYPlus = false
  attenYMinus = false
  attenZPlus = false
  attenZMinus = false
  attenZLength: number = null
  constrainThickness = false
  constrainThicknessPerc: number = null

  pixelLevelSolid: number = null
  pixelLevelEdge: number = null
  pixelLevelVoid: number = null

  shiftY = false
  shiftYSize: number = null

  temperatureSetPoint: number = null
  purgeFlowPrintheadSetPoint: number = null
  purgeFlowRecoatSetPoint: number = null
  purgeFlowMotionAndPrintheadPressurizationSetPoint: number = null
  purgeFlowHMBCoolingSetPoint: number = null

  buildPlateTemperatureSetPoint: number = null
  powderTemperatureSetPoint: number = null
  recoatIRIntensity: number = null
  printIRIntensity: number = null

  jetSpeed: number = null
  wipeSpeed: number = null
  moveSpeed: number = null
  purgeTime: number = null
  purgePressure: number = null

  refillDwellTime: number = null
  dosingFactor: number = null
  dosingFactorUnit: string = null
  dosingSupplyAngularSpeed: number = null
  dosingRefillAngularSpeed: number = null
  angularSpeedUnit: string = null
  firstDoseFinalPosition: number = null
  dosingRefillHopperVibrator = false
  dosingRefillPistonVibrator = false

  recoatDelay: number = null
  foundationLayers: number = null
  moveSpeedSupplyDepart: number = null
  moveSpeedSupplyReturn: number = null
  moveSpeedBuildDepart: number = null
  moveSpeedBuildReturn: number = null
  rollerSpeedSupplyLevelingDepart: number = null
  rollerSpeedSupplyLevelingReturn: number = null
  rollerSpeedRecoatLevelingDepart: number = null
  rollerSpeedRecoatLevelingReturn: number = null
  liftLayerSplit: number = null

  layerThickness: number = null
}
