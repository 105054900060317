/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera'
import { Vector3 } from '@babylonjs/core/Maths'

export class CameraView {
  left: number
  right: number
  top: number
  bottom: number
  alpha: number
  beta: number
  target: Vector3

  constructor(camera: ArcRotateCamera) {
    this.left = camera.orthoLeft
    this.right = camera.orthoRight
    this.top = camera.orthoTop
    this.bottom = camera.orthoBottom
    this.alpha = camera.alpha
    this.beta = camera.beta
    this.target = camera.target.clone()
  }
}
