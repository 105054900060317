
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import { TenantAdminStressReliefProfileData } from '@/types/Sites/TenantAdminStressRelief'

@Component({
  components: {},
})
export default class StressReliefProfileData extends Vue {
  @Prop({ default: () => [] }) value: TenantAdminStressReliefProfileData[]

  get timeValues() {
    return this.value.map((v: TenantAdminStressReliefProfileData) => v.time)
  }

  get tempValues() {
    return this.value.map((v: TenantAdminStressReliefProfileData) => v.temperature)
  }
}
