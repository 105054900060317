
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import VTextField from 'vuetify/lib/components/VTextField'
import { ValidationProvider } from 'vee-validate'

import SpinButtons from '@/components/controls/Common/SpinButtons.vue'
import { DEFAULT_VALIDATION_MODE, KEYBOARD_KEY_CODES } from '@/constants'

export enum LabelPlace {
  Top = 'top',
  Inside = 'inside',
}

@Component({
  components: {
    SpinButtons,
  },
})
export default class NumberField extends Vue {
  @Prop({ default: false }) disabled: boolean
  @Prop() suffix!: string
  @Prop() min!: number
  @Prop() max!: number
  @Prop() placeholder!: string
  @Prop({ type: [Number, String, null] }) value: number | string | null
  @Prop({ type: [Object, String], default: '' }) readonly rules: object | string
  @Prop({ type: [Object, null], default: null, required: false }) readonly customMessages: object | null
  @Prop({ type: Number, default: 0 }) floatLimit!: number
  @Prop({ type: Number, default: 100 }) intLimit: number
  @Prop() step!: number | string
  @Prop({ default: false }) keepModelAsNumber: boolean
  @Prop({ default: false }) showValidationErrorsInTooltip: boolean
  @Prop({ default: false }) showSpinButtons: boolean
  @Prop({ default: false }) setNullForEmptyFiled: boolean
  @Prop({ default: false }) sanitizeInput: boolean
  @Prop({ default: false }) isInteger: boolean
  @Prop({ default: false }) isPositive: boolean
  @Prop({ default: DEFAULT_VALIDATION_MODE }) validationMode: string
  @Prop({ default: false }) validateImmediately: boolean
  @Prop({ default: false }) hideDetails: boolean
  @Prop({ default: () => null }) label: string
  @Prop({ default: true }) labelShrink: boolean
  @Prop({ default: LabelPlace.Inside }) labelPlace: LabelPlace
  @Prop({ default: false }) cycling: boolean // requires to have max and min values
  @Prop({ default: false }) toFixed: boolean // requires to have floatLimit value
  @Prop({ default: true }) truncateLabel: boolean

  // @ts-ignore
  numberValue: number = Number(this.value)
  // @ts-ignore
  model: string = this.value !== undefined && this.value !== null ? String(this.value) : ''
  $refs: {
    field: VTextField
    provider: InstanceType<typeof ValidationProvider>
  }

  @Watch('numberValue')
  onNumberValueChanged(val) {
    this.$emit('input', val)
  }

  @Watch('value')
  onValueChanged(val) {
    const allowNegativeSignFirst = !this.isPositive && this.model === '-'
    const isEmptyValue = val === null || isNaN(val)

    if (isEmptyValue && !allowNegativeSignFirst) {
      // This is the fix to set an empty field
      // (Need for Rotate and Move tools)
      this.reset()
      return
    }

    if (val === Number(this.model)) {
      return
    }

    this.model = allowNegativeSignFirst ? '-' : String(val)

    if (!this.$refs.field.isFocused) {
      this.updateNumberValue()
    }
  }

  created() {
    this.updateNumberValue()
  }

  onInput(e, val) {
    if (this.keepModelAsNumber) {
      this.$nextTick(() => (this.model = (+this.model).toString()))
    }
    this.updateNumberValue()
  }

  updateNumberValue(): void {
    if (this.model === null) {
      this.$emit('input', null)
      return
    }

    const numberParts = this.splitFloatNumber(this.model)

    this.limitNumberParts(numberParts)

    if (this.sanitizeInput) {
      this.sanitizeNumberParts(numberParts)
    }

    this.$nextTick(() => {
      this.model =
        numberParts.floatPart !== undefined ? `${numberParts.intPart}.${numberParts.floatPart}` : numberParts.intPart
    })

    this.$nextTick(() => {
      this.numberValue =
        +(this.setNullForEmptyFiled && this.model === '') || isNaN(Number(this.model)) ? null : Number(this.model)
    })
  }

  limitNumberParts(numberParts: { intPart: string; floatPart: string }) {
    const minusSignShift = numberParts.intPart.startsWith('-') ? 1 : 0
    if (
      numberParts.intPart &&
      this.intLimit &&
      this.isFloatPartMaxLengthExceeded(numberParts.intPart, this.intLimit + minusSignShift)
    ) {
      numberParts.intPart = numberParts.intPart.slice(0, this.intLimit + minusSignShift)
    }

    if (
      numberParts.floatPart &&
      this.floatLimit &&
      this.isFloatPartMaxLengthExceeded(numberParts.floatPart, this.floatLimit)
    ) {
      numberParts.floatPart = numberParts.floatPart.slice(0, this.floatLimit)
    }
  }

  sanitizeNumberParts(numberParts: { intPart: string; floatPart: string }) {
    numberParts.intPart = numberParts.intPart.replace(/[^\d-]/g, '')

    // let's sanitize 0 in case of int part has more than 1 character
    if (numberParts.intPart.length > 1) {
      numberParts.intPart = numberParts.intPart.replace(/^0/g, '')
    }

    if (this.isInteger) {
      numberParts.floatPart = undefined
    } else if (numberParts.floatPart) {
      numberParts.floatPart = numberParts.floatPart.replace(/[^\d]/g, '')
    }
    if (this.isPositive) {
      numberParts.intPart = numberParts.intPart.replace('-', '')
    } else {
      numberParts.intPart = numberParts.intPart.replace(/(?<!^)-/, '')
    }
  }

  splitFloatNumber(val: string): { intPart: string; floatPart: string } {
    return {
      intPart: val.split('.')[0],
      floatPart: val.indexOf('.') !== -1 ? val.substr(val.indexOf('.') + 1) : undefined,
    }
  }

  isFloatPartMaxLengthExceeded(floatPart: string, floatPartMaxLen: number) {
    return floatPart.length > floatPartMaxLen
  }

  onBlur() {
    this.formatModelForEmit()
    this.$emit('blur', this.model)
  }

  onChange() {
    this.formatModelForEmit()
    this.$emit('change', this.model)
  }

  formatModelForEmit() {
    if (this.model === null || this.model === '') {
      this.reset()
      return
    }

    const checkFuncName = this.isFloat(this.model) ? 'isCorrectFloat' : 'isCorrectInteger'
    if (!this[checkFuncName](String(this.model))) {
      this.reset()
    }
  }

  reset() {
    this.model = ''
    this.numberValue = null
  }

  isFloat(str: string): boolean {
    return !isNaN(Number(str)) && str.indexOf('.') !== -1
  }

  upSpinClick() {
    if (this.step) {
      if (this.max !== undefined) {
        const resultOfOperation = +this.model + +this.step
        if (this.cycling && resultOfOperation > this.max) {
          this.model = this.toFixed && this.floatLimit ? this.min.toFixed(this.floatLimit) : this.min.toString()
          this.updateNumberValue()
          this.onChange()
          return
        }
        if (resultOfOperation <= this.max) {
          this.model =
            this.toFixed && this.floatLimit ? resultOfOperation.toFixed(this.floatLimit) : resultOfOperation.toString()
          this.model = (
            Math.round((+this.model + Number.EPSILON) * 10 ** this.floatLimit) /
            10 ** this.floatLimit
          ).toString()
          this.updateNumberValue()
          this.onChange()
        }
      } else {
        const resultOfOperation = +this.model + +this.step
        this.model =
          this.toFixed && this.floatLimit ? resultOfOperation.toFixed(this.floatLimit) : resultOfOperation.toString()
        this.model = (
          Math.round((+this.model + Number.EPSILON) * 10 ** this.floatLimit) /
          10 ** this.floatLimit
        ).toString()
        this.updateNumberValue()
        this.onChange()
      }
    }
  }

  downSpinClick() {
    if (this.step) {
      const resultOfOperation = +this.model - +this.step
      if (this.min !== undefined && resultOfOperation < this.min) {
        if (this.cycling) {
          this.model = this.max.toString()
          this.updateNumberValue()
        }

        return
      }

      this.model =
        this.toFixed && this.floatLimit
          ? (+this.model - +this.step).toFixed(this.floatLimit)
          : (+this.model - +this.step).toString()

      this.model = (
        Math.round((+this.model + Number.EPSILON) * 10 ** this.floatLimit) /
        10 ** this.floatLimit
      ).toString()
      this.updateNumberValue()
      this.onChange()
    }
  }

  isString(val: any): boolean {
    return typeof val === 'string'
  }

  isCorrectFloat(n: string): boolean {
    const reFloat = new RegExp(`^[+-]?\\d*?(\\.\\d{0,${this.floatLimit ? this.floatLimit : ''}})?$`, 'g')
    const matches = n.match(reFloat)
    if (matches) {
      return matches[0] === n
    }
    return false
  }

  isCorrectInteger(n: string): boolean {
    const reInteger = new RegExp(`^[+-]?(\\d+)$`, 'g')
    const matches = n.match(reInteger)
    if (matches) {
      return matches[0] === n
    }
    return false
  }

  validate(options: { silent: boolean } = { silent: false }): Promise<{ valid: boolean }> {
    if (options.silent) {
      return this.$refs.provider.validateSilent()
    }
    return this.$refs.provider.validate()
  }

  onKeyUp(e) {
    if (this.sanitizeInput || !this.showSpinButtons) {
      return
    }

    e.preventDefault()
    this.upSpinClick()
  }

  onKeyDown(e) {
    if (!this.showSpinButtons) {
      return
    }

    // Disable exponent number input
    if (this.sanitizeInput) {
      return e.keyCode !== KEYBOARD_KEY_CODES.E
    }

    e.preventDefault()
    this.downSpinClick()
  }

  onFocusIn() {
    this.$emit('focusin')
  }

  onFocusOut() {
    this.$emit('focusout')
  }
}
