import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { ICostEstimationState } from './types'
import { IRootState } from '@/store/types'
import { ViewModes } from '@/constants'

export const state: ICostEstimationState = {
  materials: [],
  parameterSets: [],
  regions: [],
  machineConfigs: [],
  sortParams: {},
  filterParams: {},
  allCostEstimations: [],
  allCostEstimationsCount: 0,
  filteredCostEstimations: [],
  filteredCostEstimationsCount: 0,
  paginationLimit: 10,
  paginationOffset: 0,
  searchEstimationName: '',
  selectedFiltersIds: [],
  selectedSortModeId: null,
  selectedViewMode: ViewModes.List,
  needToUpdateCostEstimations: true,
  selectedCostEstimation: null,
  dataState: {
    numberOfPendingRequests: 0,
    numberOfFailedRequests: 0,
    failedMessages: [],
  },
}

const namespaced: boolean = true

export const costEstimation: Module<ICostEstimationState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
