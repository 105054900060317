/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Scene } from '@babylonjs/core/scene'
import { Plane, Vector3, Axis } from '@babylonjs/core/Maths'
import { Engine } from '@babylonjs/core/Engines/engine'
import { CreatePlaneVertexData, Mesh, MeshBuilder } from '@babylonjs/core/Meshes'
import { MESH_RENDERING_GROUP_ID, SLICING_MESH_MATERIAL, STENCIL_PLANE_NAME } from '@/constants'
import { VisualizationEvent } from '@/visualization/infrastructure/IVisualizationEvent'

export class SimulationSlicerManager {
  private readonly onSlicerAdjusted: VisualizationEvent<{ min: Vector3; max: Vector3 }>
  private scene: Scene
  private current: number
  private engine: Engine

  constructor(scene: Scene, engine: Engine) {
    this.scene = scene
    this.engine = engine
    this.scene.setRenderingAutoClearDepthStencil(0, false)
    this.scene.setRenderingAutoClearDepthStencil(1, false)
    this.scene.setRenderingAutoClearDepthStencil(2, false)
    this.setupSceneForSlicing()
    this.onSlicerAdjusted = new VisualizationEvent<{ min: Vector3; max: Vector3 }>()
  }

  get slicePlane(): Mesh {
    return this.scene.getMeshById(STENCIL_PLANE_NAME) as Mesh
  }

  get slicerAdjusted() {
    return this.onSlicerAdjusted.expose()
  }

  adjustSlicingPlane() {
    const boundingBox = this.scene.getWorldExtends((mesh) => mesh.visibility === 1 && mesh.id !== STENCIL_PLANE_NAME)
    const width = boundingBox.max.x - boundingBox.min.x
    const height = boundingBox.max.y - boundingBox.min.y

    const stencilPlanePosition = new Vector3(
      (boundingBox.max.x + boundingBox.min.x) / 2,
      (boundingBox.max.y + boundingBox.min.y) / 2,
      boundingBox.max.z,
    )
    const vertexData = CreatePlaneVertexData({ width, height })
    const stencilPlane = this.slicePlane
    stencilPlane.position = stencilPlanePosition
    vertexData.applyToMesh(stencilPlane)
    this.changeCurrentSimulationSlicer(boundingBox.max.z)
    this.onSlicerAdjusted.trigger(boundingBox)
  }

  changeCurrentSimulationSlicer(current: number) {
    this.current = current
  }

  private setupSceneForSlicing() {
    const stencilPlane = MeshBuilder.CreatePlane(STENCIL_PLANE_NAME, { updatable: true }, this.scene)
    stencilPlane.material = this.scene.getMaterialById(SLICING_MESH_MATERIAL)
    stencilPlane.isPickable = false
    stencilPlane.renderingGroupId = MESH_RENDERING_GROUP_ID
    stencilPlane.rotate(Axis.Y, Math.PI)
    const previousStencilMask = this.engine.getStencilMask()
    const previousStencilFunction = this.engine.getStencilFunction()

    stencilPlane.onBeforeRenderObservable.add(() => {
      this.engine.setStencilMask(0x00)
      this.engine.setStencilBuffer(true)
      this.engine.setStencilFunction(Engine.EQUAL)
    })

    stencilPlane.onAfterRenderObservable.add(() => {
      this.engine.setStencilBuffer(false)
      this.engine.setStencilMask(previousStencilMask)
      this.engine.setStencilFunction(previousStencilFunction)
    })

    this.scene.onBeforeRenderingGroupObservable.add((groupInfo) => {
      this.scene.clipPlane = new Plane(0, 0, 1, -this.current)
      if (groupInfo.renderingGroupId === 1) {
        stencilPlane.position.z = this.current
        this.engine.setStencilBuffer(true)
      }
    })

    this.scene.onAfterRenderingGroupObservable.add((groupInfo) => {
      if (groupInfo.renderingGroupId === 1) {
        this.engine.setStencilBuffer(false)
      }

      this.scene.clipPlane = null
    })
  }
}
