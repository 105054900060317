export enum ItemType {
  BuildPart,
  BuildPlan,
  Folder,
  PrintOrder,
  IbcPlan,
}

export enum ItemSubType {
  None,
  SinterPlan,
  SinterPart,
  IbcPart,
}

export enum ItemFieldType {
  ItemType = 'itemType',
  ItemSubType = 'subType',
}

export interface IItemTypeFilterValue {
  fieldType?: ItemFieldType
  typeValue: ItemType | ItemSubType
}
