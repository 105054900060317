
import Vue from 'vue'
import Component from 'vue-class-component'

import Button from '@/components/controls/Common/Button.vue'
import AlertModal from '@/components/modals/AlertModal.vue'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'

// 15 minutes is the default timeout for supports, label, sim-comp tabs
// User should confirm he still needs tool tab to be open after 15 minutes of idle
const SESSION_TIMEOUT = 15 * 60 * 1000
// 15 seconds is given to the user to confirm he is still active
const AUTO_SHUTDOWN_TIMEOUT = 15 * 1000

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component({
  components: {
    Button,
    AlertModal,
  },
})
export default class SessionModal extends Vue {
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes

  @visualizationStore.Getter isCompensationFileInProgress: boolean

  $refs!: {
    alert: InstanceType<typeof AlertModal>
  }

  private sessionTimeout: number = null
  private autoShutdownTimeout: number = null

  destroyed() {
    this.clearSession()
  }

  prolongSession() {
    clearTimeout(this.sessionTimeout)

    // Start shutdown timer
    this.sessionTimeout = window.setTimeout(() => {
      // Show message to the user. If user will not respond to that message in 15 seconds close supports tab
      this.startAutoShutdownCountdown()
    }, SESSION_TIMEOUT)

    this.addProlongSessionEvents()
  }

  clearSession() {
    this.removeProlongSessionEvents()
    clearTimeout(this.sessionTimeout)
    clearTimeout(this.autoShutdownTimeout)
  }

  private async startAutoShutdownCountdown() {
    this.removeProlongSessionEvents()

    this.autoShutdownTimeout = window.setTimeout(() => {
      this.$refs.alert.cancel()
      this.$emit('cancelTool')
    }, AUTO_SHUTDOWN_TIMEOUT)

    let title
    switch (this.getBuildPlanViewMode) {
      case ViewModeTypes.SimulationCompensation:
        title = this.$t('simulateSessionExpireTitle')
        break
      case ViewModeTypes.DeviationCompensate:
        title = this.$t('compensateSessionExpireTitle')
        break
      case ViewModeTypes.Marking:
        title = this.$t('labelSessionExpireTitle')
        break

      default:
        title = this.$t('supportsSessionExpireTitle')
    }

    // If compensation files export is in progress do not show session expiration alert
    // and prolong session automatically
    if (
      (this.isSimulateCompensationTab && this.isCompensationFileInProgress) ||
      (await this.$refs.alert.open(title, this.$t('supportsSessionExpireMessage')))
    ) {
      this.cancelAutoShutdownCountdown()
      this.prolongSession()
    }
  }

  private addProlongSessionEvents() {
    window.addEventListener('mousemove', this.prolongSession)
    window.addEventListener('scroll', this.prolongSession)
    window.addEventListener('keydown', this.prolongSession)
    window.addEventListener('resize', this.prolongSession)
  }

  private removeProlongSessionEvents() {
    window.removeEventListener('mousemove', this.prolongSession)
    window.removeEventListener('scroll', this.prolongSession)
    window.removeEventListener('keydown', this.prolongSession)
    window.removeEventListener('resize', this.prolongSession)
  }

  private cancelAutoShutdownCountdown() {
    clearTimeout(this.autoShutdownTimeout)
  }

  private get isSimulateCompensationTab() {
    return (
      this.getBuildPlanViewMode === ViewModeTypes.SimulationCompensation ||
      this.getBuildPlanViewMode === ViewModeTypes.DeviationCompensate
    )
  }
}
