import { MutationTree } from 'vuex'
import { ICostEstimationState } from './types'
import { ICostEstimation } from '@/types/CostEstimations/ICostEstimation'
import { IMaterial } from '@/types/IMaterial'
import { SortParam } from '@/types/SortModes'
import { FilterParam } from '@/types/Filter'
import { ViewModes, MAX_COST_ESTIMATION_SETS } from '@/constants'
import { IParameterSet } from '../../../types/BuildPlans/IBuildPlan'
import { DataState } from '@/types/Common/DataState'
import { IMachineConfig } from '@/types/IMachineConfig'

export const mutations: MutationTree<ICostEstimationState> = {
  setSelectedCostEstimation(state, costEstimation: ICostEstimation) {
    state.selectedCostEstimation = costEstimation
  },

  toggleMachineConfigSelection(state, machineConfigId: number) {
    if (!state.selectedCostEstimation) {
      return
    }

    const sets = state.selectedCostEstimation.costEstimationSet
    // If there is a set with such machine Id, remove that set
    // Otherwise add a new set with that machine Id
    const index = sets.findIndex((set) => set.machineConfigId === machineConfigId)
    if (index !== -1) {
      sets.splice(index, 1)
    } else if (sets.length < MAX_COST_ESTIMATION_SETS) {
      sets.push({ machineConfigId, result: null })
    }
  },

  selectMaterial(state, materialId: number) {
    // Disable printer cards for the printers that can't work with the selected material
    const parameterSets: IParameterSet[] = state.parameterSets.filter((set) => set.materialId === materialId)
    const machineConfigs: IMachineConfig[] = state.machineConfigs.filter((machineConfig) =>
      parameterSets.some((set) => machineConfig.machineName === set.machineName),
    )

    // Remove cost etimation set if it doesn't work with the selected material
    const sets = state.selectedCostEstimation.costEstimationSet
    for (let i = sets.length - 1; i >= 0; i -= 1) {
      if (machineConfigs.some((machineConfig) => machineConfig.id === sets[i].machineConfigId)) {
        continue
      }

      sets.splice(i, 1)
    }

    state.selectedCostEstimation.materialId = materialId
  },

  setMaterials(state, materials: IMaterial[]) {
    state.materials = materials
  },

  setParameterSets(state, parameterSets: IParameterSet[]) {
    state.parameterSets = parameterSets
  },

  setMachineConfigs(state, machineConfigs: IMachineConfig[]) {
    state.machineConfigs = machineConfigs
  },

  setRegions(state, regions: string[]) {
    state.regions = regions
  },

  setFilteredCostEstimations(state, estimations: ICostEstimation[]) {
    state.filteredCostEstimations = estimations
  },

  setAllCostEstimations(state, estimations: ICostEstimation[]) {
    state.allCostEstimations = estimations
  },

  deleteCostEstimation(state, id: number) {
    state.filteredCostEstimations = state.filteredCostEstimations.filter((estimation) => estimation.id !== id)
    if (!state.filteredCostEstimations.length) {
      state.paginationOffset = 0
    }
  },

  updateCostEstimation(state, newEstimation) {
    const index = state.filteredCostEstimations.findIndex((estimation) => estimation.id === newEstimation.id)
    if (index) {
      state.filteredCostEstimations.splice(index, 1, newEstimation)
    }
  },

  setSelectedFiltersIds(state, filtersIds: number[]) {
    state.selectedFiltersIds = filtersIds
    state.needToUpdateCostEstimations = true
  },

  setSearchEstimationName(state, name: string) {
    state.searchEstimationName = name
    state.needToUpdateCostEstimations = true
  },

  setSortParams(state, { field, order = null }: SortParam) {
    state.sortParams = { [field]: order }
  },

  setFilterParams(state, { field, value }: FilterParam) {
    state.filterParams = { ...state.filterParams, ...{ [field]: value } }
  },

  setSelectedViewMode(state, mode: ViewModes) {
    state.selectedViewMode = mode
  },

  setPaginationLimit(state, limit: number) {
    state.paginationLimit = limit
    state.needToUpdateCostEstimations = true
  },

  setPaginationOffset(state, offset: number) {
    state.paginationOffset = offset
    state.needToUpdateCostEstimations = true
  },

  setFilteredCostEstimationsCount(state, count: number) {
    state.filteredCostEstimationsCount = count
  },

  setAllCostEstimationsCount(state, count: number) {
    state.allCostEstimationsCount = count
  },

  setNeedToUpdateCostEstimations(state, value: boolean) {
    state.needToUpdateCostEstimations = value
  },

  setDataState(state, payload: { state: DataState; errorText?: string }) {
    const { state: dataState, errorText = '' } = payload
    switch (dataState) {
      case DataState.Loading:
        const { numberOfPendingRequests } = state.dataState
        // remove previous failed requests
        if (numberOfPendingRequests === 0) {
          state.dataState.numberOfFailedRequests = 0
          state.dataState.failedMessages = []
        }

        state.dataState.numberOfPendingRequests += 1
        return

      case DataState.Loaded:
        state.dataState.numberOfPendingRequests -= 1
        return

      case DataState.Failed:
        state.dataState.numberOfPendingRequests -= 1
        state.dataState.numberOfFailedRequests += 1
        state.dataState.failedMessages.push(errorText)
        return
    }
  },
}
