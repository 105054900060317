import { Engine } from '@babylonjs/core/Engines/engine'
import { Scene } from '@babylonjs/core/scene'
import { Color3, Color4, Vector3, Viewport } from '@babylonjs/core/Maths'
import { SceneMode } from '@/visualization/types/SceneTypes'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import { BuildPlateManager } from '@/visualization/rendering/BuildPlateManager'
import { OrthoCamera } from '@/visualization/components/OrthoCamera'
import { PointLight } from '@babylonjs/core/Lights/pointLight'
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight'
import { IRenderable } from '@/visualization/types/IRenderable'
import { ISceneMetadata } from '@/visualization/types/SceneMetadata'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { IViewMode } from '@/visualization/infrastructure/ViewMode'
import {
  BUILD_PLATE_GRID_RATIO,
  BUILD_PLATE_MATERIAL_NAME,
  BVH_BOX,
  FRONT_INDICATOR_MATERIAL_NAME,
  GAS_FLOW_ARROW_MATERIAL_NAME,
  PRINT_HEAD_ARROW_MATERIAL_NAME,
  RECOATER_ARROW_MATERIAL_NAME,
} from '@/constants'
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { GridMaterial } from '@babylonjs/materials/grid/gridMaterial'
import { DynamicTexture } from '@babylonjs/core/Materials/Textures/dynamicTexture'
import i18n from '@/plugins/i18n'
import { MeshShader } from '@/visualization/rendering/MeshShader'
import { IComponentMetadata } from '@/visualization/types/SceneItemMetadata'
import { PointerEventTypes } from '@babylonjs/core/Events/pointerEvents'
import { VersionablePk } from '@/types/Common/VersionablePk'

export class BuildPlatformScene implements IRenderable {
  private readonly canvas: HTMLCanvasElement
  private readonly engine: Engine

  private scene: Scene
  private meshManager: MeshManager
  private sceneMode: SceneMode
  private buildPlateManager: BuildPlateManager
  private camera: OrthoCamera
  private cameraLight: PointLight
  private ambientLight: HemisphericLight

  // Render scene
  private renderLoop: () => void
  private meshesToRender = new Map<number, boolean>()
  private isRenderLoopStopped = true
  private isContinuousRender: boolean
  private needRender: boolean

  constructor(canvasElement: string) {
    this.canvas = document.getElementById(canvasElement) as HTMLCanvasElement
    this.engine = new Engine(this.canvas, true, { stencil: true, preserveDrawingBuffer: true })
  }

  getScene() {
    return this.scene
  }

  getSceneMode() {
    return this.sceneMode
  }

  getSceneMetadata() {
    return this.scene.metadata as ISceneMetadata
  }

  getMeshManager() {
    return this.meshManager
  }

  getActiveCamera() {
    return this.camera
  }

  getAmbientLight() {
    return this.ambientLight
  }

  getPointLight() {
    return this.cameraLight
  }

  getObbTree() {
    return null
  }

  getBuildPlateManager() {
    return this.buildPlateManager
  }

  getSelectionManager() {
    return null
  }

  getGpuPicker() {
    return null
  }

  getVisuzalizationModeManager() {
    return null
  }

  getViewModes() {
    return new Map<ViewModeTypes, IViewMode>()
  }

  getInsightsManager() {
    return null
  }

  hasCapabilities(): boolean {
    const engine = this.scene && this.scene.getEngine()
    const capabilities = engine && engine.getCaps()
    return !!capabilities
  }

  render() {
    this.scene.render()
  }

  async createScene(buildPlateId: number) {
    this.scene = new Scene(this.engine)
    this.scene.useRightHandedSystem = true
    this.scene.clearColor = new Color4(1, 1, 1, 1)
    this.scene.autoClear = true
    this.scene.autoClearDepthAndStencil = false
    this.scene.blockMaterialDirtyMechanism = true
    this.sceneMode = SceneMode.SinglePart
    this.meshManager = new MeshManager(this)
    this.buildPlateManager = new BuildPlateManager(this)
    this.camera = new OrthoCamera(
      'camera1',
      new Viewport(0, 0, 1, 1),
      this.scene,
      this.canvas,
      this,
      Math.PI / 2,
      0,
      0.5,
    )
    this.cameraLight = new PointLight('camera1Light', new Vector3(0, 0, 1), this.scene)
    this.ambientLight = new HemisphericLight('light1', new Vector3(0, 0, 1000), this.scene)
    this.scene.onBeforeRenderObservable.add(() => {
      this.cameraLight.position = this.camera.position
    })
    this.scene.onNewMeshAddedObservable.add((mesh) => {
      this.meshesToRender.set(mesh.uniqueId, false)
      // this.animate()
    })

    this.registerRenderLoop()
    this.registerRenderEvents()

    this.createMaterialList()
    await this.loadBuildPlate(buildPlateId)

    this.engine.resize()
    this.animate()
  }

  // if run with isContinuously = true you must call stopAnimate() after animation end
  // if run with isContinuously = false animation stops automatically
  animate(isContinuously: boolean = false) {
    if (isContinuously) {
      this.isContinuousRender = true
      if (!this.isRenderLoopStopped) {
        this.scene.getEngine().stopRenderLoop()
        this.isRenderLoopStopped = true
      }

      this.runRenderLoop(true)
    }

    if (this.isContinuousRender) {
      return
    }

    if (this.isRenderLoopStopped) {
      this.runRenderLoop(false)
    }
    this.needRender = true
  }

  async loadBuildPlate(buildPlateId: number) {
    if (!buildPlateId) {
      return false
    }

    this.buildPlateManager.removeGroundPlane()
    await this.buildPlateManager.loadParametricBuildPlate(false, true, false, false, new VersionablePk(buildPlateId))
    this.camera.repositionCamera(this.scene, true)

    return true
  }

  private createMaterialList(): void {
    const buildPlateMaterial = new GridMaterial(BUILD_PLATE_MATERIAL_NAME, this.scene)
    buildPlateMaterial.mainColor = new Color3(0.95, 0.95, 0.95)
    buildPlateMaterial.lineColor = new Color3(0.8, 0.8, 0.8)
    buildPlateMaterial.gridRatio = BUILD_PLATE_GRID_RATIO
    buildPlateMaterial.majorUnitFrequency = 1

    if (this.canvas) {
      const recoaterArrowTexture = new DynamicTexture(
        'recoaterArrowTexture',
        {
          width: 256,
          height: 256,
        },
        this.scene,
        false,
      )
      const font = 'normal 35px Arial'
      recoaterArrowTexture.drawText(
        (i18n.t('recoater') as string).toUpperCase(),
        32,
        122,
        font,
        'black',
        'whitesmoke',
        false,
        true,
      )
      const recoaterArrowMaterial = new StandardMaterial(RECOATER_ARROW_MATERIAL_NAME, this.scene)
      recoaterArrowMaterial.diffuseTexture = recoaterArrowTexture
      recoaterArrowMaterial.ambientColor = new Color3(0.3, 0.3, 0.3)
      recoaterArrowMaterial.specularColor = new Color3(0, 0, 0)
      recoaterArrowMaterial.backFaceCulling = false
      recoaterArrowMaterial.freeze()

      const gasFlowArrowTexture = new DynamicTexture(
        'gasFlowArrowTexture',
        {
          width: 256,
          height: 256,
        },
        this.scene,
        false,
      )
      gasFlowArrowTexture.drawText(
        (i18n.t('gasFlow') as string).toUpperCase(),
        32,
        122,
        font,
        'black',
        'whitesmoke',
        false,
        true,
      )
      const gasFlowArrowMaterial = new StandardMaterial(GAS_FLOW_ARROW_MATERIAL_NAME, this.scene)
      gasFlowArrowMaterial.diffuseTexture = gasFlowArrowTexture
      gasFlowArrowMaterial.ambientColor = new Color3(0.3, 0.3, 0.3)
      gasFlowArrowMaterial.specularColor = new Color3(0, 0, 0)
      gasFlowArrowMaterial.backFaceCulling = false

      const font2 = 'normal 32px Arial'
      const printHeadArrowTexture = new DynamicTexture(
        'printHeadArrowTexture',
        {
          width: 256,
          height: 256,
        },
        this.scene,
        false,
      )
      printHeadArrowTexture.drawText(
        (i18n.t('printHead') as string).toUpperCase(),
        32,
        122,
        font2,
        'black',
        'whitesmoke',
        false,
        true,
      )
      const printHeadArrowMaterial = new StandardMaterial(PRINT_HEAD_ARROW_MATERIAL_NAME, this.scene)
      printHeadArrowMaterial.diffuseTexture = printHeadArrowTexture
      printHeadArrowMaterial.ambientColor = new Color3(0.3, 0.3, 0.3)
      printHeadArrowMaterial.specularColor = new Color3(0, 0, 0)
      printHeadArrowMaterial.backFaceCulling = false

      const font3 = 'normal 65px Arial'
      const frontIndicatorTexture = new DynamicTexture(
        'frontIndicatorTexture',
        {
          width: 256,
          height: 128,
        },
        this.scene,
        false,
      )
      frontIndicatorTexture.drawText(
        (i18n.t('front') as string).toUpperCase(),
        16,
        100,
        font3,
        'black',
        'whitesmoke',
        false,
        true,
      )
      const frontIndicatorMaterial = new StandardMaterial(FRONT_INDICATOR_MATERIAL_NAME, this.scene)
      frontIndicatorMaterial.diffuseTexture = frontIndicatorTexture
      frontIndicatorMaterial.ambientColor = new Color3(0.3, 0.3, 0.3)
      frontIndicatorMaterial.specularColor = new Color3(0, 0, 0)
      frontIndicatorMaterial.backFaceCulling = false
    }

    MeshShader.initializeShaderIncludes()
  }

  private runRenderLoop(isContinuously: boolean) {
    if (isContinuously) {
      this.scene.getEngine().runRenderLoop(() => {
        this.render()
      })

      return
    }

    this.scene.getEngine().runRenderLoop(this.renderLoop)
  }

  private registerRenderLoop() {
    this.renderLoop = () => {
      this.isRenderLoopStopped = false
      let notReadyMeshesNb: number
      let readyNotRenderedMeshesNb: number

      if (this.meshesToRender.size !== 0) {
        notReadyMeshesNb = 0
        readyNotRenderedMeshesNb = 0

        for (const [meshUniqueId, isRendered] of this.meshesToRender) {
          const mesh = this.scene.getMeshByUniqueID(meshUniqueId)
          if (!mesh || isRendered) {
            continue
          }

          if (mesh.isReady(true)) {
            this.meshesToRender.set(meshUniqueId, true)
            readyNotRenderedMeshesNb += 1
          } else {
            notReadyMeshesNb += 1
          }
        }
      }

      if (!(readyNotRenderedMeshesNb && notReadyMeshesNb)) {
        this.meshesToRender.clear()
      }

      this.render()

      if (notReadyMeshesNb || this.camera.inertialAlphaOffset !== 0 || this.camera.inertialBetaOffset !== 0) {
        this.needRender = true
      } else {
        this.needRender = false
      }

      if (!this.needRender) {
        this.isRenderLoopStopped = true
        this.scene.getEngine().stopRenderLoop(this.renderLoop)
      }
    }
  }

  private registerRenderEvents(): void {
    this.scene.onNewMeshAddedObservable.add((mesh) => {
      if (mesh.name !== BVH_BOX) {
        mesh.onAfterWorldMatrixUpdateObservable.add(() => {
          this.animate()
        })
        mesh.onMaterialChangedObservable.add((m) => {
          const isComponentMesh = this.meshManager.isComponentMesh(m)
          if (!isComponentMesh || (isComponentMesh && !(m.metadata as IComponentMetadata).isPickingMaterial)) {
            this.animate()
          }
        })

        this.meshesToRender.set(mesh.uniqueId, false)
        this.animate()
      }
    })

    this.scene.onMeshRemovedObservable.add((mesh) => {
      if (mesh.name !== BVH_BOX) {
        if (this.meshesToRender.has(mesh.uniqueId)) {
          this.meshesToRender.delete(mesh.uniqueId)
        }

        this.animate()
      }
    })

    this.scene.onPointerObservable.add((eventData) => {
      if (
        eventData.type === PointerEventTypes.POINTERWHEEL ||
        eventData.type === PointerEventTypes.POINTERMOVE ||
        eventData.type === PointerEventTypes.POINTERUP
      ) {
        this.animate()
      }
    })
  }
}
