
import Vue from 'vue'
import Component from 'vue-class-component'
import { GChart } from 'vue-google-charts'
import { Prop } from 'vue-property-decorator'
import { IResult } from '@/types/CostEstimations/ICostEstimation'
import FallbackImage from '@/components/layout/FallbackImage.vue'
import HorizontalLine from '@/components/controls/Common/HorizontalLine.vue'
import round from '@/utils/arithmetic/round'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'

const commonStore = namespace(StoresNamespaces.Common)

@Component({
  name: 'SingleMachineResult',
  components: {
    GChart,
    FallbackImage,
    HorizontalLine,
  },
})
export default class SingleMachineResult extends Vue {
  @commonStore.Getter tooltipOpenDelay: number

  @Prop() results: IResult
  @Prop() manufacturingRegion: string
  @Prop() materialName: string
  @Prop() numberOfPartsPerYear: number
  @Prop() chartData: any
  @Prop() chartOptions: any
  @Prop() chartColorScheme: any
  @Prop() renderCurrencyRange: (minValue: number, maxValue: number) => string

  round = round

  get result() {
    return this.results && this.results[0]
  }

  get maintenance() {
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })

    if (this.materialName.includes(this.$t('binderJetMaterial').toString())) {
      return currencyFormatter
        .format(round(this.result.result.maintenance, 0))
        .replace('$', '$ ')
        .replace(/\D00(?=\D*$)/, '')
    }

    return this.renderCurrencyRange(this.result.result.maintenanceMin, this.result.result.maintenanceMax)
  }
}
