import { Component, Vue } from 'vue-property-decorator'
import { IBuildPlan, IBuildPlanItem, IUpdateBuildPlanItemParamsDto } from '@/types/BuildPlans/IBuildPlan'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { ToolTypes } from '@/types/BuildPlans/ToolTypes'
import ITransformationDelta from '@/types/BuildPlans/ITransformationDelta'
import { IConstraints, IConstraintsOptions } from '@/types/BuildPlans/IConstraints'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component
export default class CommonBuildPlanToolsMixin extends Vue {
  @buildPlansStore.Action('updateBuildPlanV1') updateBuildPlan: (payload: {
    buildPlan: IBuildPlan
    hideAPIErrorMessages?: boolean
  }) => Promise<IBuildPlan>
  @buildPlansStore.Action('rotateSelectedItem') rotateSelectedItem: (rotateValues: ITransformationDelta) => void
  @buildPlansStore.Action('moveSelectedItemsToDelta') moveSelectedItemsToDelta: (delta: ITransformationDelta) => void
  @buildPlansStore.Action updateBuildPlanItem: (payload: {
    params: IUpdateBuildPlanItemParamsDto
    hideAPIErrorMessages?: boolean
  }) => Promise<void>

  openedSections = {
    recipes: true,
    labels: true,
    constraints: true,
    rotationSettings: true,
    rotation: true,
    settings: true,
    movement: true,
    allAvailableParts: true,
    allAvailableSinterParts: true,
    printLocation: true,
    printLabels: true,
  }

  toggleSection(name: string) {
    if (this.openedSections[name] !== undefined) {
      this.openedSections[name] = !this.openedSections[name]
    }
  }

  async updateBuildPlanIncrement(targetBuildPlan: IBuildPlan) {
    await this.updateBuildPlan({ buildPlan: targetBuildPlan })
  }

  async performTransformationDelta(toolType: ToolTypes, transformationDelta: ITransformationDelta): Promise<void> {
    switch (toolType) {
      case ToolTypes.MoveTool:
        await this.moveSelectedItemsToDelta(transformationDelta)
        break
      case ToolTypes.RotateTool:
        await this.rotateSelectedItem(transformationDelta)
        break
      default:
        throw new Error(`Unknown tool type was passed: ${toolType}`)
    }
  }

  getToolConstraints(toolType: ToolTypes, selectedBuildPlanItem: IBuildPlanItem): IConstraintsOptions {
    switch (toolType) {
      case ToolTypes.MoveTool:
        return selectedBuildPlanItem.constraints.translation
      case ToolTypes.RotateTool:
        return selectedBuildPlanItem.constraints.rotation
      default:
        throw new Error(`Unknown tool type was passed: ${toolType}`)
    }
  }

  async updateSelectedConstraint(
    toolType: ToolTypes,
    selectedBuildPlanItem: IBuildPlanItem,
    constraintsOptions: IConstraintsOptions,
  ): Promise<void> {
    const buildPlanItemConstraints: IConstraints = {
      ...selectedBuildPlanItem.constraints,
    }

    switch (toolType) {
      case ToolTypes.MoveTool:
        buildPlanItemConstraints.translation = constraintsOptions

        await this.updateBuildPlanItem({
          params: {
            buildPlanItemId: selectedBuildPlanItem.id,
            constraints: buildPlanItemConstraints,
          },
        })
        break
      case ToolTypes.RotateTool:
        buildPlanItemConstraints.rotation = constraintsOptions

        await this.updateBuildPlanItem({
          params: {
            buildPlanItemId: selectedBuildPlanItem.id,
            constraints: buildPlanItemConstraints,
          },
        })
        break
      default:
        throw new Error(`Unknown tool type was passed: ${toolType}`)
    }
  }
}
