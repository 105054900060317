import { secondsToMilliseconds } from 'date-fns'

import {
  IBinderJetH2ProcessParameter,
  IBinderJetH3ProcessParameter,
  PixelLevelFeature,
} from '@/types/Sites/BinderJetProcessParameter'
import { ProcessParameterFormViewModel } from './ProcessParameterFormViewModel'
import { isNil } from '@/utils/common'
import { micronToMillimeter } from '@/utils/number'

export const CONSTRAIN_THICKNESS_PCT_DEFAULT = 0
export const PIXEL_SOLID_PCT_DEFAULT = 3
export const PIXEL_EDGE_PCT_DEFAULT = 1
export const PIXEL_VOID_PCT_DEFAUL = 0
export const LAYER_THICKNESS_MM_DEFAULT = 0.1
export const H2_Y_SHIFT_SIZE_MM_DEFAULT = 10
export const H2_LAYER_THICKNESS_MM_DEFAULT = 0.1
export const H3_Y_SHIFT_SIZE_MM_DEFAULT = 9
export const H3_LAYER_THICKNESS_MM_DEFAULT = 0.1
export const ATTEN_XY_DEFAULT = 0.5
export const ATTEN_Z_MINUS_DEFAULT = true
export const ATTEN_Z_LENGTH_MM_DEFAULT = 0.5
export const SHIFT_Y_DEFAULT = false

export abstract class ProcessParameterViewModelTemplate {
  protected vm: ProcessParameterFormViewModel

  constructor() {
    this.vm = new ProcessParameterFormViewModel()
  }

  init() {
    this.setSlicing()
    this.setEnvironmental()
    this.setHeating()
    this.setJetting()
    this.setPowderSupply()
    this.setRecoating()

    return this.vm
  }

  protected abstract setSlicing(): void
  protected abstract setHeating(): void
  protected abstract setJetting(): void
  protected abstract setPowderSupply(): void
  protected abstract setEnvironmental(): void
  protected abstract setRecoating(): void
}

// TODO: Add error handling if some data is missing

export class H3ProcessParameterFormViewModel extends ProcessParameterViewModelTemplate {
  private readonly parameter: IBinderJetH3ProcessParameter

  constructor(parameter: IBinderJetH3ProcessParameter) {
    super()
    this.parameter = parameter
    this.vm.dosingFactorUnit = '%'
    this.vm.angularSpeedUnit = '1/s'
  }

  protected setSlicing(): void {
    const { SliceSettings } = this.parameter
    const { imageColoring, imageShifting } = SliceSettings
    const { attenuation } = imageColoring

    this.vm.layerThickness = !isNil(SliceSettings.LayerThickness_um)
      ? micronToMillimeter(SliceSettings.LayerThickness_um)
      : LAYER_THICKNESS_MM_DEFAULT
    this.vm.attenXPlus = attenuation.Xplus
    this.vm.attenXMinus = attenuation.Xminus
    this.vm.attenYPlus = attenuation.Yplus
    this.vm.attenYMinus = attenuation.Yminus
    this.vm.attenXYLength = !isNil(attenuation.XYlength) ? attenuation.XYlength : ATTEN_XY_DEFAULT
    this.vm.attenZPlus = attenuation.Zplus
    this.vm.attenZMinus = !isNil(attenuation.Zminus) ? attenuation.Zminus : ATTEN_Z_MINUS_DEFAULT
    this.vm.attenZLength = !isNil(attenuation.Zlength) ? attenuation.Zlength : ATTEN_Z_LENGTH_MM_DEFAULT
    this.vm.constrainThickness = attenuation.constrainThickness
    this.vm.constrainThicknessPerc = attenuation.constrainThicknessPercentage || CONSTRAIN_THICKNESS_PCT_DEFAULT

    this.setPixelLevels()

    this.vm.shiftY = imageShifting.RandomlyShiftInY
    this.vm.shiftYSize = !isNil(imageShifting.YShiftSize) ? imageShifting.YShiftSize : H3_Y_SHIFT_SIZE_MM_DEFAULT
  }

  protected setEnvironmental(): void {
    const { Environmental } = this.parameter

    this.vm.temperatureSetPoint = Environmental.Temperature_SetPoint_degC
    this.vm.purgeFlowPrintheadSetPoint = Environmental.PurgeFlow_IRLamp_Printhead_SetPoint_lpm
    this.vm.purgeFlowRecoatSetPoint = Environmental.PurgeFlow_IRLamp_Recoat_SetPoint_lpm
    this.vm.purgeFlowMotionAndPrintheadPressurizationSetPoint =
      Environmental.PurgeFlow_MotionAndPrintheadPressurization_SetPoint_lpm
    this.vm.purgeFlowHMBCoolingSetPoint = Environmental.PurgeFlow_HMBCooling_SetPoint_lpm
  }

  protected setHeating(): void {
    const { Heating } = this.parameter

    this.vm.buildPlateTemperatureSetPoint = Heating.BuildPlateTemperature_SetPoint_degC
    this.vm.powderTemperatureSetPoint = Heating.PowderTemperature_SetPoint_degC
    this.vm.recoatIRIntensity = Heating.RecoatIR_Intensity_pct
    this.vm.printIRIntensity = Heating.PrintheadIR_Intensity_pct
  }

  protected setJetting(): void {
    const { Jetting } = this.parameter

    this.vm.jetSpeed = Jetting.JetSpeed_mm_s
    this.vm.wipeSpeed = Jetting.WipeSpeed_mm_s
    this.vm.moveSpeed = Jetting.MoveSpeed_mm_s
    this.vm.purgeTime = Jetting.PurgeTime_ms
    this.vm.purgePressure = Jetting.PurgePressure_mmH2O
  }

  protected setPowderSupply(): void {
    const { PowderSupply } = this.parameter

    this.vm.refillDwellTime = PowderSupply.Refill_DwellTime_ms
    this.vm.dosingFactor = PowderSupply.Dosing_Factor_pct
    this.vm.dosingSupplyAngularSpeed = PowderSupply.DosingSupply_AngularSpeed_rev_s
    this.vm.dosingRefillAngularSpeed = PowderSupply.DosingRefill_AngularSpeed_rev_s
    this.vm.firstDoseFinalPosition = getValueOrNull(PowderSupply.FirstDose_FinalPosition_rev)
    this.vm.dosingRefillHopperVibrator = Boolean(PowderSupply.DosingRefill_HopperVibratorEnabled)
    this.vm.dosingRefillPistonVibrator = Boolean(PowderSupply.DosingRefill_PistonVibratorEnabled)
  }

  protected setRecoating(): void {
    const { Recoat } = this.parameter

    this.vm.recoatDelay = Recoat.Delay_ms
    this.vm.foundationLayers = Recoat.FoundationLayers_count
    this.vm.moveSpeedSupplyDepart = Recoat.MoveSpeed_Supply_Depart_mm_s
    this.vm.moveSpeedSupplyReturn = Recoat.MoveSpeed_Supply_Return_mm_s
    this.vm.moveSpeedBuildDepart = Recoat.MoveSpeed_Build_Depart_mm_s
    this.vm.moveSpeedBuildReturn = Recoat.MoveSpeed_Build_Return_mm_s
    this.vm.rollerSpeedSupplyLevelingDepart = Recoat.RollerSpeed_SupplyLeveling_Depart_rpm
    this.vm.rollerSpeedSupplyLevelingReturn = Recoat.RollerSpeed_SupplyLeveling_Return_rpm
    this.vm.rollerSpeedRecoatLevelingDepart = Recoat.RollerSpeed_Recoat_Depart_rpm
    this.vm.rollerSpeedRecoatLevelingReturn = Recoat.RollerSpeed_Recoat_Return_rpm
    this.vm.liftLayerSplit = Recoat.LiftLayerSplit_perc
  }

  private setPixelLevels() {
    const { SliceSettings } = this.parameter
    const { imageColoring } = SliceSettings
    const { pixelLevels } = imageColoring

    if (Array.isArray(pixelLevels)) {
      pixelLevels.forEach((pixelLevel) => {
        if (pixelLevel.feature === PixelLevelFeature.Solid) {
          this.vm.pixelLevelSolid = !isNil(pixelLevel.grayLevel) ? pixelLevel.grayLevel : PIXEL_SOLID_PCT_DEFAULT
        } else if (pixelLevel.feature === PixelLevelFeature.Edge) {
          this.vm.pixelLevelEdge = !isNil(pixelLevel.grayLevel) ? pixelLevel.grayLevel : PIXEL_EDGE_PCT_DEFAULT
        } else if (pixelLevel.feature === PixelLevelFeature.Void) {
          // https://ge.ent.box.com/file/1185005998341 GEAMPREQ-1413
          // PixelLevel_void removed from parameter form, should be 0
          this.vm.pixelLevelVoid = PIXEL_VOID_PCT_DEFAUL
        }
      })
    }
  }
}

export class H2ProcessParameterFormViewModel extends ProcessParameterViewModelTemplate {
  private readonly parameter: IBinderJetH2ProcessParameter

  constructor(parameter: IBinderJetH2ProcessParameter) {
    super()
    this.parameter = parameter
    this.vm.dosingFactorUnit = 'mm'
    this.vm.angularSpeedUnit = 'rev/s'
  }

  protected setSlicing(): void {
    this.vm.layerThickness = !isNil(this.parameter.LayerThickness_mm)
      ? this.parameter.LayerThickness_mm
      : LAYER_THICKNESS_MM_DEFAULT
    this.vm.attenXPlus = this.parameter.AttenXplus
    this.vm.attenXMinus = this.parameter.AttenXminus
    this.vm.attenYPlus = this.parameter.AttenYplus
    this.vm.attenYMinus = this.parameter.AttenYminus
    this.vm.attenXYLength = !isNil(this.parameter.AttenXY_mm) ? this.parameter.AttenXY_mm : ATTEN_XY_DEFAULT
    this.vm.attenZPlus = this.parameter.AttenZplus
    this.vm.attenZMinus = !isNil(this.parameter.AttenZminus) ? this.parameter.AttenZminus : ATTEN_Z_MINUS_DEFAULT
    this.vm.attenZLength = !isNil(this.parameter.AttenZ_mm) ? this.parameter.AttenZ_mm : ATTEN_Z_LENGTH_MM_DEFAULT
    this.vm.constrainThickness = this.parameter.constrainThickness
    this.vm.constrainThicknessPerc = this.parameter.constrainThickness_perc || CONSTRAIN_THICKNESS_PCT_DEFAULT

    this.vm.pixelLevelSolid = !isNil(this.parameter.PixelLevel_Solid)
      ? this.parameter.PixelLevel_Solid
      : PIXEL_SOLID_PCT_DEFAULT
    this.vm.pixelLevelEdge = !isNil(this.parameter.PixelLevel_Edge)
      ? this.parameter.PixelLevel_Edge
      : PIXEL_EDGE_PCT_DEFAULT
    // https://ge.ent.box.com/file/1185005998341 GEAMPREQ-1413
    // PixelLevel_void removed from parameter form, should be 0
    this.vm.pixelLevelVoid = PIXEL_VOID_PCT_DEFAUL

    this.vm.shiftY = !isNil(this.parameter.ShiftY) ? this.parameter.ShiftY : SHIFT_Y_DEFAULT
    this.vm.shiftYSize = !isNil(this.parameter.ShiftYSize_mm)
      ? this.parameter.ShiftYSize_mm
      : H2_Y_SHIFT_SIZE_MM_DEFAULT
  }

  protected setEnvironmental(): void {
    return undefined
  }

  protected setHeating(): void {
    this.vm.buildPlateTemperatureSetPoint = this.parameter.BuildPlateSetPoint_degC
    this.vm.powderTemperatureSetPoint = this.parameter.BuildBox1SetPoint_degC
    this.vm.recoatIRIntensity = this.parameter.IRLampOpenLoop_perc
    this.vm.printIRIntensity = this.parameter.IRLampOpenLoop2_perc
  }

  protected setJetting(): void {
    this.vm.jetSpeed = this.parameter.JettingSpeed_mmQs
    this.vm.wipeSpeed = this.parameter.WiperSpeed_mmQs

    /**
     * Form field name <-- H2 key <-- H3 key
     * -------------------------------------
     * Move Speed <-- SupplyLayerSpeed_mmQs <-- Jetting.MoveSpeed_mm_s
     * Move Speed <-- BuildLayerSpeed_mmQs <-- Jetting.MoveSpeed_mm_s
     */
    this.vm.moveSpeed = this.parameter.SupplyLayerSpeed_mmQs

    this.vm.purgeTime = this.parameter.InkSupplyPurge_sec
  }

  protected setPowderSupply(): void {
    this.vm.dosingFactor = this.parameter.SupplyDosing_mm
  }

  protected setRecoating(): void {
    this.vm.recoatDelay = secondsToMilliseconds(this.parameter.PreRecoatDelay_sec)
    this.vm.foundationLayers = this.parameter.FoundationLayers_count
    this.vm.rollerSpeedSupplyLevelingDepart = this.parameter.RollerSupply_rpm
    this.vm.rollerSpeedRecoatLevelingDepart = this.parameter.RollerBuild_rpm
  }
}

function getValueOrNull(value: number) {
  return isNil(value) ? null : value
}
