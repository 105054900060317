
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { SortOrders } from '@/types/SortModes'
import { PrintingTypes } from '@/types/IMachineConfig'
import { FilterOptions, PartListSortField } from '@/components/layout/buildPlans/addPart/types'
import Menu from '@/components/controls/Common/Menu.vue'

const MENU_ICON_ACTIVE_COLOR_NAME = 'blue'
const MENU_ICON_DEFAULT_COLOR_NAME = 'default'

@Component({
  components: {
    Menu,
  },
})
export default class ViewOptionsMenu extends Vue {
  @Prop({ required: true }) printingType: PrintingTypes
  @Prop({ default: PartListSortField.Name }) sortField: PartListSortField
  @Prop({ default: SortOrders.Ascending }) sortOrder: SortOrders
  @Prop({
    default: () => ({
      displayFolderContentOnly: false,
      displayPartsFromSinterPlans: false,
      displaySinteredPartsOnly: false,
      displayIbcPartsOnly: false,
    }),
  })
  filter: FilterOptions
  @Prop({ default: false }) isSinterPlan: boolean
  @Prop() contentFolderName: string

  sortFieldEnum = PartListSortField
  sortOrderEnum = SortOrders

  menu = false
  displayFolderContentOnly = false
  displayPartsFromSinterPlans = false
  displaySinteredPartsOnly = false
  displayIbcPartsOnly = false

  get isBinderJet() {
    return this.printingType === PrintingTypes.BinderJet
  }

  get menuIconColorName(): string {
    let hasActiveFilters: boolean

    if (this.isSinterPlan) {
      hasActiveFilters = this.displayFolderContentOnly
    } else if (this.isBinderJet) {
      hasActiveFilters = [this.displaySinteredPartsOnly, this.displayFolderContentOnly, this.displayIbcPartsOnly].some(
        Boolean,
      )
    } else {
      hasActiveFilters = [this.displayFolderContentOnly].some(Boolean)
    }

    return hasActiveFilters ? MENU_ICON_ACTIVE_COLOR_NAME : MENU_ICON_DEFAULT_COLOR_NAME
  }

  created() {
    this.displayFolderContentOnly = this.filter.displayFolderContentOnly
    this.displayPartsFromSinterPlans = this.filter.displayPartsFromSinterPlans
    this.displaySinteredPartsOnly = this.filter.displaySinteredPartsOnly
    this.displayIbcPartsOnly = this.filter.displayIbcPartsOnly
  }

  onDisplayPartsFromSinterPlanChange() {
    this.displaySinteredPartsOnly = this.displayPartsFromSinterPlans
    this.displayIbcPartsOnly = this.displayPartsFromSinterPlans
    this.onFilterChange()
  }

  onDisplaySinteredPartsOnlyChange() {
    if (!this.displaySinteredPartsOnly) {
      this.displayIbcPartsOnly = true
    }
    this.onFilterChange()
  }

  onDisplayIbcPartsOnlyChange() {
    if (!this.displayIbcPartsOnly) {
      this.displaySinteredPartsOnly = true
    }
    this.onFilterChange()
  }

  onSortByChange(sortField: PartListSortField) {
    this.$emit('sort-change', {
      field: sortField,
      order: this.sortOrder,
    })
  }

  onSortOrderChange(sortOrder: SortOrders) {
    this.$emit('sort-change', {
      field: this.sortField,
      order: sortOrder,
    })
  }

  onFilterChange() {
    const payload: FilterOptions = {
      displayFolderContentOnly: this.displayFolderContentOnly,
      displayPartsFromSinterPlans: this.displayPartsFromSinterPlans,
      displaySinteredPartsOnly: this.displaySinteredPartsOnly,
      displayIbcPartsOnly: this.displayIbcPartsOnly,
    }
    this.$emit('filter-change', payload)
  }
}
