
import Vue from 'vue'
import Component from 'vue-class-component'
import { SiteItemType } from '@/types/Sites/SiteItemType'
import { ISiteBreadcrumb } from '@/types/Sites/SiteBreadcrumb'
import { Watch } from 'vue-property-decorator'
import { SiteBreadcrumbItem } from '@/types/Sites/SiteBreadcrumbItem'
import { RouterNames, RouterPaths } from '@/router'
import { SiteBreadcrumbsParams } from '@/types/Sites/SiteBreadcrumbsParams'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { PrintStrategyInfo, MachineMaterial, MachineTypeDto, SiteDto } from '@/types/Sites/Site'
import messageService from '@/services/messageService'

const routerPathDelimiter = '/'
const sitesStore = namespace(StoresNamespaces.Sites)

@Component
export default class TenantAdminSitesBreadcrumbs extends Vue {
  @sitesStore.Action fetchSiteById: (siteId: string) => Promise<SiteDto>
  @sitesStore.Action fetchMachineTypeById: (machineTypeId: string) => Promise<SiteDto>
  @sitesStore.Action fetchMaterialById: (materialId: string) => Promise<MachineMaterial>
  @sitesStore.Action fetchPrintStrategyById: (payload: {
    printStrategyId: string
    siteId: string
  }) => Promise<PrintStrategyInfo>
  @sitesStore.Action fetchInertGases: () => Promise<void>

  @sitesStore.Getter getSelectedSite: SiteDto
  @sitesStore.Getter getSelectedMachineType: MachineTypeDto
  @sitesStore.Getter getSelectedMaterial: MachineMaterial
  @sitesStore.Getter getSelectedPrintStrategy: PrintStrategyInfo

  @sitesStore.Mutation setSelectedMaterial: (material: MachineMaterial) => void
  @sitesStore.Mutation setBreadcrumbsReady: (isReady: boolean) => void

  items: ISiteBreadcrumb[] = []
  breadcrumbsItems: SiteBreadcrumbItem[] = []

  get printStrategyName(): string {
    return this.getSelectedPrintStrategy && this.getSelectedPrintStrategy.name
  }

  @Watch('printStrategyName')
  onPrintStrategyNameChange(newName: string, oldName: string) {
    if (oldName && newName !== oldName) {
      const foundItem = this.items.find((item) => item.typeName === SiteItemType.PrintStrategy)
      if (foundItem) {
        foundItem.name = newName
      }
    }
  }

  @Watch('$route')
  async routeChanged() {
    await this.prepareData()
  }

  async beforeMount() {
    this.fetchInertGases()
    await this.prepareData()
  }

  async prepareData() {
    try {
      this.setBreadcrumbsReady(false)
      this.items = await this.getItems(this.$route.path)
      this.items = this.setDisabledLastBreadcrumbElement(this.items)
      this.setBreadcrumbsReady(true)
    } catch (error) {
      this.setBreadcrumbsReady(false)
      // @ts-ignore
      this.$router.safePush(RouterPaths.Sites)
      setTimeout(() => messageService.showErrorMessage(error.message || error), 500)
    }
  }

  async getItems(path): Promise<ISiteBreadcrumb[]> {
    const pathItemMap = [
      '',
      SiteItemType.Site,
      SiteItemType.MachineType,
      SiteItemType.Material,
      SiteItemType.PrintStrategy,
    ]
    const pathFragments: string[] = path.split(routerPathDelimiter)
    pathFragments.splice(0, 2)

    this.breadcrumbsItems = await Promise.all(
      pathFragments.map((fragment: string, index: number) => {
        if (index === 0) {
          return { id: null, name: null }
        }
        const typeName = pathItemMap[index]
        return this.getItemByType(typeName, fragment)
      }),
    )

    return pathFragments.reduce((arr: ISiteBreadcrumb[], fragment: string, index: number) => {
      const typeName = pathItemMap[index]
      const item = this.breadcrumbsItems.find((i) => {
        return (
          (typeName === SiteItemType.Site && i.siteId === fragment) ||
          (typeName === SiteItemType.MachineType && i.machineTypeId === fragment) ||
          (typeName === SiteItemType.Material && i.materialId === fragment) ||
          (typeName === SiteItemType.PrintStrategy && i.printStrategyId === fragment)
        )
      })

      let name
      let siteId
      let machineTypeId
      let materialId

      if (index === 0) {
        name = this.$root.$t('tenantAdminMessages.allSites').toString()
      } else if (!item) {
        return arr
      } else {
        name = item.name
        siteId = item.siteId
        machineTypeId = item.machineTypeId
        materialId = item.materialId
      }

      arr.push({
        typeName,
        name,
        siteId,
        machineTypeId,
        materialId,
        disabled: false,
      })
      return arr
    }, [])
  }

  async getItemByType(type: string, id: string): Promise<SiteBreadcrumbItem> {
    let name: string
    let siteId: string
    let machineTypeId: string
    let materialId: string
    let printStrategyId: string

    switch (type) {
      case SiteItemType.Site:
        // get site by id
        siteId = this.$route.params.siteId.toString()
        await this.fetchSiteById(siteId)
        name = this.getSelectedSite.name

        return { siteId, name }
      case SiteItemType.MachineType:
        // get machine by id
        siteId = this.$route.params.siteId.toString()
        machineTypeId = this.$route.params.machineTypeId.toString()
        await this.fetchMachineTypeById(machineTypeId)
        name = this.getSelectedMachineType.name

        return { siteId, machineTypeId, name }
      case SiteItemType.Material:
        // get material by id
        siteId = this.$route.params.siteId.toString()
        machineTypeId = this.$route.params.machineTypeId.toString()
        materialId = this.$route.params.materialId.toString()
        const material = await this.fetchMaterialById(materialId)
        this.setSelectedMaterial(material)
        name = material.name

        return { siteId, machineTypeId, materialId, name }
      case SiteItemType.PrintStrategy:
        siteId = this.$route.params.siteId.toString()
        machineTypeId = this.$route.params.machineTypeId.toString()
        materialId = this.$route.params.materialId.toString()
        printStrategyId = this.$route.params.printStrategyId.toString()

        const printStrategy = await this.fetchPrintStrategyById({ siteId, printStrategyId })

        name = printStrategy.name

        return { siteId, machineTypeId, materialId, printStrategyId, name }
    }
  }

  navigateToItem(item: ISiteBreadcrumb) {
    const newRoute = { name: null, params: {} as SiteBreadcrumbsParams }

    switch (item.typeName) {
      case '':
        newRoute.name = RouterNames.SitesAdmin
        break
      case SiteItemType.Site:
        newRoute.name = RouterNames.EditSiteAdmin
        newRoute.params.siteId = item.siteId
        break
      case SiteItemType.MachineType:
        newRoute.name = RouterNames.EditMachineTypeAdmin
        newRoute.params.siteId = item.siteId
        newRoute.params.machineTypeId = item.machineTypeId
        break
      case SiteItemType.Material:
        newRoute.name = RouterNames.EditMaterialAdmin
        newRoute.params.siteId = item.siteId
        newRoute.params.machineTypeId = item.machineTypeId
        newRoute.params.materialId = item.materialId
        break
      case SiteItemType.PrintStrategy:
        break
    }

    // Delete items located after the item that the user navigates to
    const nextItemIndex = this.items.findIndex((i) => i.typeName === item.typeName)
    if (nextItemIndex >= 0 && nextItemIndex < this.items.length) {
      this.items.splice(nextItemIndex + 1)
    }

    // @ts-ignore
    this.$router.safePush(newRoute)
  }

  // TODO: Need to create getter instead of the following method which will compute breadcrumb item disable property
  private setDisabledLastBreadcrumbElement(items: ISiteBreadcrumb[]): ISiteBreadcrumb[] {
    const lastElement = items[items.length - 1]
    lastElement.disabled = true

    items.splice(items.length - 1, 1, lastElement)
    return items
  }
}
