
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component
export default class SortableHeader extends Vue {
  @Prop() fieldLabel?: string
  @Prop() fieldName: string
  @Prop() sortField: string
  @Prop() sortDirection: number

  generateLabel() {
    const valueToUse = this.fieldLabel ? this.fieldLabel : this.fieldName
    return this.$t(valueToUse)
  }

  click() {
    this.$emit('input', this.fieldName)
  }
}
