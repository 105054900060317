
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { RouterNames } from '@/router'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { DetailsPanelViewMode } from '@/types/FileExplorer/ViewMode'
import { ROOT_FOLDER_ID } from '@/constants'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component
export default class NavigationPanel extends Vue {
  @fileExplorerStore.Getter getRootItem: FileExplorerItem
  @fileExplorerStore.Mutation unselectAllItems: () => void
  @fileExplorerStore.Mutation resetIsMoveItems: () => void
  @fileExplorerStore.Mutation setClickedNameItem: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation setDetailsPanelMode: (mode: DetailsPanelViewMode) => void
  @fileExplorerStore.Mutation setRoot: (item: FileExplorerItem) => void

  // dashboard = RouterNames.FE_Dashboard
  allFiles = RouterNames.FE_AllFiles
  navigationItems = [
    {
      name: RouterNames.FE_Dashboard,
      label: 'Dashboard',
      mdi: 'mdi-view-dashboard-outline',
      hasMarginBottom: false,
      testAttribute: 'dashboard-tab',
    },
    {
      name: RouterNames.FE_AllFiles,
      label: 'All Files',
      mdi: 'mdi-folder-outline',
      hasMarginBottom: false,
      testAttribute: 'all-files-tab',
    },
    {
      name: RouterNames.FE_Favorites,
      label: 'Favorites',
      mdi: 'mdi-star-outline',
      hasMarginBottom: false,
      testAttribute: 'favorites-tab',
    },
    {
      name: RouterNames.FE_Recent,
      label: 'Recent',
      mdi: 'mdi-history',
      hasMarginBottom: false,
      testAttribute: 'recent-tab',
    },
    {
      name: RouterNames.FE_SharedByMe,
      label: 'Shared by Me',
      mdi: 'mdi-account-multiple-outline',
      hasMarginBottom: false,
      testAttribute: 'shared-by-me-tab',
    },
    {
      name: RouterNames.FE_Trash,
      label: 'Trash',
      mdi: 'mdi-trash-can-outline',
      hasMarginBottom: false,
      testAttribute: 'trash-tab',
      divide: true,
    },
    {
      name: RouterNames.FE_PrintOrders,
      label: 'Print Orders',
      mdi: 'mdi-printer-3d',
      hasMarginBottom: false,
      testAttribute: 'print-orders-tab',
    },
  ]

  get activePageName() {
    return this.$route.name
  }

  async route(routeName) {
    if (
      (this.activePageName === routeName && this.activePageName !== RouterNames.FE_AllFiles) ||
      (this.activePageName === RouterNames.FE_AllFiles &&
        routeName === RouterNames.FE_AllFiles &&
        this.$route.params.itemId === ROOT_FOLDER_ID)
    ) {
      return
    }

    const routerObj: any = {
      name: routeName,
    }

    if (routeName === RouterNames.FE_AllFiles) {
      routerObj.params = {
        itemId: ROOT_FOLDER_ID,
      }
    }

    this.unselectAllItems()
    this.resetIsMoveItems()
    this.setClickedNameItem(null)
    this.setRoot(null)
    this.setDetailsPanelMode(DetailsPanelViewMode.Default)
    // @ts-ignore
    this.$router.safePush(routerObj)
  }
}
