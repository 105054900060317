import buildPlanItemsApi from '@/api/buildPlanItems'
import { Command } from '@/types/UndoRedo/Command'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { GeometryType, IBuildPlanItem, Visibility, IDisplayToolbarState } from '@/types/BuildPlans/IBuildPlan'
import { Commit, Dispatch } from 'vuex'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { IBuildPlanInsight } from '../BuildPlans/IBuildPlanInsight'

export class TransferPropertiesCommand extends Command implements ICommand {
  public readonly commandType: CommandType
  public readonly parameterSetScaleFactor: number[]
  public readonly isShowHiddenPartsAsTransparentMode: boolean
  public readonly insights: IBuildPlanInsight[]
  public readonly toolName = ToolNames.TRANSFER_PROPS

  public readonly buildPlanItemsBeforeTransferApplied: IBuildPlanItem[]
  public readonly buildPlanItemsAfterTransferApplied: IBuildPlanItem[]

  constructor(
    buildPlanItemsBeforeTransferApplied: IBuildPlanItem[],
    buildPlanItemsAfterTransferApplied: IBuildPlanItem[],
    commandType: CommandType,
    parameterSetScaleFactor: number[],
    isShowHiddenPartsAsTransparentMode: boolean,
    insights: IBuildPlanInsight[],
    protected dispatch: Dispatch,
    protected commit: Commit,
  ) {
    super()

    this.buildPlanItemsBeforeTransferApplied = JSON.parse(JSON.stringify(buildPlanItemsBeforeTransferApplied))
    this.buildPlanItemsAfterTransferApplied = JSON.parse(JSON.stringify(buildPlanItemsAfterTransferApplied))
    this.commandType = commandType
    this.parameterSetScaleFactor = parameterSetScaleFactor
    this.isShowHiddenPartsAsTransparentMode = isShowHiddenPartsAsTransparentMode
    this.insights = insights
  }

  async undo(): Promise<void> {
    const bpItems = this.buildPlanItemsBeforeTransferApplied
    const skipPositionXY = true
    return this.undoRedoHandler(bpItems, skipPositionXY)
  }

  async redo(): Promise<void> {
    const bpItems = this.buildPlanItemsAfterTransferApplied
    return this.undoRedoHandler(bpItems)
  }

  private async undoRedoHandler(buildPlanItems: IBuildPlanItem[], skipPositionXY = false) {
    this.deselectItems()

    await buildPlanItemsApi.updateBuildPlanItems(buildPlanItems, true)

    buildPlanItems.forEach((bpItem) => {
      // Transformation
      this.commit(
        'visualizationModule/applyTransformationMatrix',
        {
          buildPlanItemId: bpItem.id,
          transformation: bpItem.transformationMatrix,
          options: {
            parameterSetScaleFactor: this.parameterSetScaleFactor,
            skipPositionX: skipPositionXY,
            skipPositionY: skipPositionXY,
            skipPositionZ: !bpItem.supports,
            updateStateOnly: true,
          },
        },
        this.rootLevel,
      )

      // Constraints
      this.dispatch(
        'buildPlans/updateConstraints',
        { buildPlanItemId: bpItem.id, constraints: bpItem.constraints },
        this.rootLevel,
      )

      // Part properties
      // Create a map of bodies with the same part properties in order to prevent multiple updates
      const partPropertiesMap: Map<GeometryType, string[]> = new Map()
      bpItem.partProperties.forEach((partProperty) => {
        const ids = partPropertiesMap.get(partProperty.type) || []
        partPropertiesMap.set(partProperty.type, [...ids, partProperty.geometryId])
      })

      partPropertiesMap.forEach((bodyIds, geometryType) => {
        const items = [{ bodyIds, buildPlanItemId: bpItem.id }]
        this.commit('visualizationModule/updateGeometriesTypeOnScene', { items, geometryType }, this.rootLevel)
      })

      // Visibility
      const geometryItems = [
        { buildPlanItemId: bpItem.id, bodyIds: bpItem.partProperties.map((item) => item.geometryId) },
      ]
      this.commit(
        'visualizationModule/setBpItemVisibility',
        {
          bpItem,
          makeVisible: bpItem.visibility === Visibility.Visible,
          showAsTransparent: this.isShowHiddenPartsAsTransparentMode,
        },
        this.rootLevel,
      )
      this.commit(
        'visualizationModule/setGeometryVisibility',
        {
          items: geometryItems,
          visibility: bpItem.visibility === Visibility.Visible,
          saveVisibility: true,
        },
        this.rootLevel,
      )

      // Supports
      this.commit('visualizationModule/clearOverhangMesh', bpItem.id, this.rootLevel)
      this.commit(
        'visualizationModule/clearSupports',
        { buildPlanItemId: bpItem.id, overhangElementsToClear: null, skipGeomProps: true },
        this.rootLevel,
      )

      if (bpItem.supports) {
        this.commit(
          'visualizationModule/loadSupports',
          {
            buildPlanItemId: bpItem.id,
            supports: bpItem.supports,
            supportsBvhFileKey: bpItem.supportsBvhFileKey,
            supportsHullFileKey: bpItem.supportsHullFileKey,
            visibility: bpItem.visibility,
          },
          this.rootLevel,
        )
      }
    })

    // TODO: implement undo/redo for labels when it's available for transfer properties tool
  }
}
