/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { ItemType, ItemSubType } from '../FileExplorer/ItemType'
import { PrintingTypes } from '../IMachineConfig'
import { ILabel } from '../Marking/ILabel'
import { IArrangeConstraints, IConstraints, ISettings } from './IConstraints'
import { IBaseSimulateCompensation } from '@/types/Simulation/SimulationCompensation'
import { PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import { Vector3 } from '@babylonjs/core/Maths'
import { CadFileTypes } from '@/types/PartsLibrary/Parts'
import { JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import OrientResult from '../orientation/OrientResult'
import { GeometryTypes } from '@/visualization/models/DataModel'
import { IBuildPlanInsight } from './IBuildPlanInsight'
import { VersionableModel } from '@/types/Common/VersionableModel'
import { VersionablePk } from '../Common/VersionablePk'
import { SelectedItem } from '../OptionalMultiItemCollector/SelectedItem'
import { ISupportError } from '../InteractiveService/IInteractiveServiceMessage'
import { IBinderJetH2ProcessParameter, IBinderJetH3ProcessParameter } from '../Sites/BinderJetProcessParameter'
import { LockWhitelistElementDto } from '../FileExplorer/FileExplorerItem'
import { HatchType } from '../../constants'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'

export interface IBuildPlan {
  id: string
  name: string
  createdBy: string
  createdDate: string
  buildPlateId: number
  buildPlateVersion: number
  machineConfigId: number
  machineConfigVersion: number
  materialId: number
  materialVersion: number
  printStrategyId: number
  printStrategyVersion: number
  modality: string
  buildPlanItems: IBuildPlanItem[]
  isRemoved: boolean
  path: string
  parentId?: string
  jobCount: number
  itemType: ItemType
  version: string
  versionLabel: string
  versionDescription?: string
  isActiveVersion: boolean
  isLocked: boolean
  lockWhitelist?: LockWhitelistElementDto[]
  constraints: IArrangeConstraints
  settings: ISettings
  subType: ItemSubType
  simulationParameters?: IBaseSimulateCompensation
  crossSectionMatrix: number[]
  recoaterAngle: number
  versionOrder: number
  cost?: object
  parentPathLabel?: string
  previewImageUrl?: string
  labelSetIDsToUpdate: string[]
  visibleWhenPublished: boolean
}

export interface IMoveBuildPlanItemsDto {
  buildPlanId: string
  moveBuildPlanItemDtos: IMoveBuildPlanItemDto[]
}

export interface IMoveBuildPlanItemDto {
  id: string
  transformationMatrix: number[]
}

export interface IConstraintsBuildPlanItemsDto {
  buildPlanId: string
  constraintsBuildPlanItemDtos: IConstraintsBuildPlanItemDto[]
}

export interface IConstraintsBuildPlanItemDto {
  id: string
  constraints: IConstraints
}

export interface ICreateBuildPlanLastUsedValues {
  materialId: number
  machineConfigId: number
  printStrategyId: number
  buildPlateId: number
  binderId: number
}

export interface ICreateSinterPlanLastUsedValues {
  materialId: number
  machineConfigId: number
  printStrategyId: number
  binderId: number
}

export interface IVariantTree {
  buildPlan: IBuildPlan
  children: IVariantTree[]
}

export interface IBuildPlanItem {
  id: string
  part: IPart
  transformationMatrix: number[]
  buildPlanId: string
  orientCriteria?: IOrientCriteria
  geometryProperties: IGeometryProperties
  labels: ILabel[]
  supports: BuildPlanItemSupport[]
  overhangs: BuildPlanItemOverhang
  supportsBvhFileKey: string
  supportsHullFileKey: string
  constraints: IConstraints
  settings: IBuildPlanItemSettings
  updatedAt: string
  cost: object
  partProperties?: PartProperty[]
  overhangAngle: number
  visibility: Visibility
  hiddenBodies: string[]
  sliceAlignment?: SliceAlignment
}

export interface IOrientCriteria {
  orientations: OrientResult[]
  appliedOrientationIndex: number
  supportAngle: number
  selectedPreferredFaces: string[]
  selectedProhibitedFaces: string[]
  prohibitedItems: SelectedItem[]
  preferredItems: SelectedItem[]
}

export interface IPart {
  id: string
  scaleX: number
  scaleY: number
  scaleZ: number
  createdDate: string
  cadFileType: CadFileTypes
  name: string
  createdBy: string
  createdAt: Date
  updatedAt: Date
  jobCount: number
  jobType: JobType
  status: JobStatusCode
  path: string
  parentId: string
  isRemoved: boolean
  itemType: ItemType
  subType: ItemSubType
  hasSheetBodies: boolean
  originalName: string[]
  isPublishedAsScaled: boolean

  printStrategyParameterSetId?: number
  printStrategyParameterSetVersion?: number
  parentPathLabel?: string
  sinterPlanName?: string
  nominalPartId?: string
  bodyFunction?: GeometryType
}

export interface ILoadingPart {
  id: number
  name: string
  partId?: string
  isSuccess?: boolean
}

export interface IBuildPlanDto {
  buildPlanItems: Array<{ partName: string; transformationMatrix: number[] }>
  buildPlanName: string
  buildPlateId: number
  buildPlateVersion: number
  machineConfigId: number
  machineConfigVersion: number
  materialId: number
  materialVersion: number
  printStrategyId: number
  printStrategyVersion: number
  modality: string
  absolutePath: string
  parentId?: string
  recoaterAngle: number
}

export interface IBuildPlanLockDto {
  id: string
  name: string
  version: string
  versionLabel: string
  isLocked: boolean
  lockWhitelist: LockWhitelistElementDto[]
}

export interface ISelectable {
  id: string
  name?: string
  type: SelectionUnit
  translation?: Vector3
  geometryProperties?: IGeometryProperties
}

export enum SelectionUnit {
  Part = 'Part',
  Body = 'Body',
  FaceAndEdge = 'Face',
  PartAndSupport = 'PartAndSupport',
  Defect = 'Defect',
}

export interface ICreateBuildPlanDto {
  name: string
  parentId?: string
  machineConfigId: number
  machineConfigVersion: number
  materialId: number
  materialVersion: number
  buildPlateId: number
  buildPlateVersion: number
  printStrategyId: number
  printStrategyVersion: number
  modality: string
  subType: ItemSubType
}

export interface InertGas {
  alias: string
  cost: number
  costStandbyUse: number
  id: number
  inertGasType: InertGasType
  version: number
}

export enum InertGasType {
  Argon = 'Argon',
  Nitrogen = 'Nitrogen',
  Helium = 'Helium',
  ArgonNitrogen = 'Argon/Nitrogen',
}

export enum RecoaterMaterial {
  Rubber = 'Rubber',
  CarbonBrush = 'Carbon Brush',
  Steel = 'Steel',
  StainlessSteel = 'Stainless Steel',
}

export enum BuildPlatecode {
  FE = 'FE',
  AL = 'AL',
  TI = 'TI',
  NI = 'NI',
}

export enum RecoaterType {
  Hard = 'Hard',
  Soft = 'Soft',
}

export interface IProductionSet extends VersionableModel {
  createdAt: Date
  updatedAt: Date
  geDefault: boolean
  productionSetName: string
  s3FileName: string
  visibility: boolean
  modality: PrintingTypes
  recoaterAngle: number
  overhangAngle: number
  machineConfigId: number
  machineConfigVersion: number
  processParameters: IDmlmProductionSetContent | IBinderJetH3ProcessParameter | IBinderJetH2ProcessParameter
}

export interface IDmlmProductionSetContent {
  inertGasType: InertGasType
  volumeGasFlow: string
  gasVelocity: number
  recoaterMaterial: RecoaterMaterial
  platformCode: BuildPlatecode
  machineProcessParameterFilename: string
  numberOfLasers: number
  recoatingTime: number
  securityDistance: number
  movementSpeed: number
  overPartSpeed: number
  emptyRunSpeed: number
  pause: number
  recoaterType: RecoaterType
  buildPlateTemperature: number
}

export interface IScaleFactors {
  ScaleFactorX: number
  ScaleFactorY: number
  ScaleFactorZ: number
}

export interface IParameter {
  ScaleFactors: IScaleFactors
}

export interface IParameterSet extends VersionableModel {
  createdAt: Date
  updatedAt: Date
  layerThickness: number
  geDefault: boolean
  parameterSetName: string
  s3FileName: string
  visibility: boolean
  allowSimCom: boolean
  machineName: string
  materialId: number
  materialVersion: number
  partParameters: IBinderJetParameterSetContent | IDmlmParameterSetContent
}

export interface IPrintStrategyParameterSet extends VersionableModel {
  name: string
  parameterSetId: number
  parameterSetVersion: number
  allowSimCom: boolean
  layerThickness: number
  parameterSet: IParameterSet
}

interface ISinterProfileValue {
  Time_min: number
  Temp_degC: number
}

interface ISinterCycle {
  SinterCycleName: string
  SinterProfile: ISinterProfileValue[]
}

export interface IPhysicalProperty {
  propertyType: string
  propertyValue: number[]
}

export interface IConstitutiveLaw {
  constitutiveLawType: string
  materialState: string
  physicalProperties: IPhysicalProperty[]
}

interface IBinderJetMaterial {
  constitutiveLaws: IConstitutiveLaw[]
}

interface ICalibrationBuild {
  calibrationName?: string
  calibrationDate?: string
  calibrationMachine?: string
  powderLot?: string
  binderLot?: string
}

export class SliceSettings {
  contourPixelLevel: number
  bulk: BulkAreaModel
  bands?: BandModel[]
}

export class BulkAreaModel {
  hatchType: HatchType = HatchType.Solid
  gridSpacingInPixels?: number = 16
  gridSpacingGrayScale?: number = 0
  gridThicknessInPixels?: number = 16
  gridRotationAngleInDegrees?: number = 67
}

export class BandModel extends BulkAreaModel {
  id: number = 0
  bandThicknessInPixels: number = 47
  bandGrayScale: number = 3
}

export interface IBinderJetParameterSetContent {
  ScaleFactors: IScaleFactors
  SinterCycle: ISinterCycle
  materials: IBinderJetMaterial[]
  Optional: ICalibrationBuild
  sliceSettings: SliceSettings
}

export interface IDmlmParameterSetContent {
  hatchCoeff: number
  contourCoeff: number
  laserSpeed: number
  hatchDistance: number
  generalizedHatchSpeed: number
  contourOneSpeed: number
  contourTwoSpeed: number
  contourThreeSpeed: number
  speedRatio: number
  generalizedContourSpeed: number
  coreSpeed: number
  coreTraceSpacing: number
  generalizedCoreHatchSpeed: number
  vsSpeed: number
  vsTraceSpacing: number
  generalizedVsHatchSpeed: number
  parameterFileName: string
  skinCoreParameter: boolean
  layerThicknessSupportCore: number
  laserPower: number
  beamDiameter: number
  offsetToCadContour: number
}

export interface IGeometryProperties {
  surfaceArea?: number
  volume?: number
  supportSurfaceArea?: number
  supportVolume?: number
}

export interface IMeshGeometryProperties {
  surfaceArea: number
  volume: number
}

export interface BuildPlanCost {
  min: number
  max: number
}

export interface BuildPlanTime {
  min: number
  max: number
}

export interface BuildDuration {
  days: number
  hours: number
  minutes: number
}

export enum SupportTypes {
  Lines = 'Lines',
  Column = 'Column',
  NoSupports = 'No Supports',
}

export enum FillTypes {
  Solid = 'Solid',
  Lattice = 'Lattice',
  LatticeAndSolid = 'Lattice & Solid',
}

export enum ContourTypes {
  InsideOutNoWrap = 'insideOutNoWrap',
  InsideOutWrap = 'insideOutWrap',
  PerimeterIn = 'perimeterIn',
  PerimeterAndHolesIn = 'perimeterAndHolesIn',
}

export enum LineShapeTypes {
  Parallel = 'parallel',
  OffsetAllBoundaries = 'offsetAllBoundaries',
  OffsetOuterBoundary = 'offsetOuterBoundary',
  OffsetFromCenter = 'offsetFromCenter',
  Multiple = 'multiple',
}

export interface IBuildPlanItemSettings {
  overhangAngleDeg: number
  minRequiredTrianglesArea: number
  minRequiredConnectedEdgesLength: number
  edgeLaminarAngleDeg: number
  elementsCanBeCoincident: boolean
  recoaterDirection: number[]
  strategy: string
  selectedOverhangElements: string[]
  overhangSampleDirectionDeg: number
  overhangAngleLimit: number
  overhangDistance: number
  thickness: number
  draftAngle: number
  fillType: FillTypes
  baseHeight: number
  blend: boolean
  addNeck: boolean
  neckWidth: number
  neckHeight: number
  addPerforations: boolean
  perforationMinHeight: number
  perforationWidth: number
  perforationAngleDeg: number
  contourType: LineShapeTypes
  addBlend: boolean
  blendRadius: number
  attachmentDepth: number
  parameterLayerThickness: number
  addOverhangBoundaries: boolean
  maxConnectionSpacing: number
  maxSegmentLength: number
  minSegmentBreak: number
  minSupportSpacing: number
  partElevation: number
}

export enum SupportedElementTypes {
  Area = 'Area',
  Face = 'Face',
  Edge = 'Edge',
  Vertex = 'Vertex',
}

export interface BuildPlanItemSupportSettings {
  addBlend: boolean
  addNeck: boolean
  addPerforations: boolean
  blendRadius: number
  contourType: LineShapeTypes
  neckHeight: number
  neckWidth: number
  overhangSampleDirectionDeg: number
  perforationAngleDeg: number
  perforationMinHeight: number
  perforationWidth: number
  printStrategyParameterSetId: number
  printStrategyParameterSetVersion: number
  recoaterDirection: number[]
  selectedOverhangElements: string[]
  strategy: string
  thickness: number
  attachmentDepth: number
  parameterLayerThickness: number
  addOverhangBoundaries: boolean
  maxConnectionSpacing: number
  maxSegmentLength: number
  minSegmentBreak: number
  minSupportSpacing: number
  hasSupportError: boolean
  hasSupportWarnings: boolean
  supportErrors: ISupportError[]
  isLoading: boolean
}

export interface BuildPlanItemSupport {
  settings: BuildPlanItemSupportSettings
  fileKey: string
  overhangElementType: SupportedElementTypes
  overhangElementName: string
  awsFileKey: string
  groupId: string
  overhangArea: number
  /**
   * OBB is 8 points that store as an array of x, y, z positions of each vertex [...., x, y, z, .....]
   */
  overhangObb: number[]
}

export enum PartTypes {
  SinterPart = 'SinterPart',
  BuildPlanPart = 'BuildPlanPart',
  IbcPart = 'IbcPart',
}

export interface BuildPlanItemOverhang {
  fileKey: string
  awsFileKey: string
}

export interface AddPartToolState {
  selectedParts: PartListItemViewModel[]
  selectedPartProperties: {
    [id: string]: PartProperty[]
  }
  selectedPartsScale: {
    [id: string]: number[]
  }
  outgoingPartProperties: {
    [loadIndex: number]: PartProperty[]
  }
  geometryProperties: IGeometryProperties
}

export interface DuplicateToolState {
  instances: Array<{
    buildPlanItemId: string
    meshInfo: Array<{
      instancesTransformations: number[]
      id: string
    }>
  }>
  isDuplicateUnderBuildPlate: boolean
}

export enum GeometryType {
  Support = 1,
  Production = 2,
  Coupon = 4,
}

export enum ProcessState {
  Nominal = 'nominal',
  Green = 'green',
}

export enum ModelGeometryTypeLabel {
  Nominal = 'Nominal',
  Compensated = 'Compensated',
  Warped = 'Warped',
}

export enum BodyMenuType {
  GeometryType = 'GeometryType',
  ProcessState = 'ProcessState',
}

export enum Visibility {
  Visible = 'Visible',
  Hidden = 'Hidden',
}

/** Body property (component/geometry) */
export interface PartProperty {
  id: number
  type: GeometryType
  name: string
  printStrategyParameterSetId: number
  printStrategyParameterSetVersion: number
  geometryId: string
  groupId: string
  bodyType: GeometryTypes
  processState?: ProcessState
  printStrategyParameterSetPk?: VersionablePk
}

export interface PartInSinglePartCanvasProperty {
  id: string
  name: string
  hiddenBodies: string[]
  scale: number[]
  processState: ProcessState
}

export interface BuildPlanItemInstancesDto {
  srcBuildPlanItem: IBuildPlanItem
  positions: number[][]
}

export interface IDisplayToolbarState {
  // Common
  isShowingProductionGeometry: boolean
  isShowingSupportGeometry: boolean
  isShowingCouponGeometry: boolean
  isShowingRecoaterDirection: boolean
  isShowingBuildPlanVolume: boolean
  isShowingBuildPlate: boolean
  // BJ
  isShowingPrintHead: boolean
  isShowingPrintHeadLanes: boolean
  // DMLM
  isShowingGasFlowDirection: boolean
  // Orient
  isShowingRecoaterDirectionShader: boolean
  isShowingOverhangAreasShader: boolean
  // Label
  isShowingAllLabledBodies: boolean
}

export interface IIBCDisplayToolbarState {
  part: boolean
  supports: boolean
  partAndSupports: boolean
  measurements: boolean
  inspToolMeasurements: boolean
  buildVolume: boolean
  buildPlate: boolean
}

export const defaultDisplayToolbarState: IDisplayToolbarState = {
  isShowingProductionGeometry: true,
  isShowingSupportGeometry: true,
  isShowingCouponGeometry: true,
  isShowingRecoaterDirection: true,
  isShowingBuildPlanVolume: true,
  isShowingBuildPlate: true,
  isShowingPrintHead: true,
  isShowingPrintHeadLanes: true,
  isShowingGasFlowDirection: true,
  isShowingRecoaterDirectionShader: false,
  isShowingOverhangAreasShader: false,
  isShowingAllLabledBodies: true,
}

export const defaultIBCDisplayToolbarState: IIBCDisplayToolbarState = {
  part: true,
  supports: true,
  partAndSupports: true,
  measurements: true,
  inspToolMeasurements: true,
  buildVolume: false,
  buildPlate: true,
}

export interface IUpdateBuildPlanItemParamsDto {
  buildPlanItemId: string
  transformationMatrix?: number[]
  updateStateOnly?: boolean
  orientCriteria?: IOrientCriteria
  geometryProperties?: IGeometryProperties
  constraints?: IConstraints
  supports?: BuildPlanItemSupport[]
  overhangs?: BuildPlanItemOverhang
  supportsBvhFileKey?: string
  supportsHullFileKey?: string
  settings?: IBuildPlanItemSettings
  cost?: object
  silent?: boolean
  partProperties?: PartProperty[]
  visibility?: Visibility
  hiddenBodies?: string[]
  takeIntoAccountRequestsQueue?: boolean
}

export interface TransferPropsResponseDto {
  buildPlanItems: IBuildPlanItem[]
  insights: IBuildPlanInsight[]
}

export interface IBuildChamberPart {
  id: string
  name: string
  count: number
}

export enum SliceAlignment {
  NotSet = 'NotSet',
  None = 'None',
  Bottom = 'Bottom',
  Top = 'Top',
}

export interface IIBCPlanVariantTree {
  buildPlan: IIBCPlan
  children: IIBCPlanVariantTree[]
}

export interface IVariantItem {
  id?: string
  path: string
  parentId: string
  version: string
  versionLabel: string
  versionDescription: string
  createdBy: string
  updatedBy: string
  createdAt: string
  updatedAt: string
  jobCount: number
  name: string
  isRemoved: boolean
  isLocked: boolean
  previewImageUrl: string
  lockWhitelist: LockWhitelistElementDto[]
  itemType: ItemType
  subType: ItemSubType
  versionOrder: number
  isActiveVersion: boolean
}

export interface ILoadingItemsData {
  current: number
  total: number
}
