
import Vue from 'vue'
import Component from 'vue-class-component'
import IssuesContainers from '@/components/layout/buildPlans/insight/group/IssuesContainers.vue'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import IssueWrapper from '@/components/layout/buildPlans/insight/group/IssueWrapper.vue'
import {
  IBuildPlanInsight,
  InsightErrorCodes,
  InsightGroups,
  messageMapByInsight,
} from '@/types/BuildPlans/IBuildPlanInsight'
import { Prop, Watch } from 'vue-property-decorator'
import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import LabelIssueByInsight from '@/components/layout/buildPlans/insight/issue/byInsight/LabelIssueByInsight.vue'
import LayoutIssueByInsight from '@/components/layout/buildPlans/insight/issue/byInsight/LayoutIssueByInsight.vue'
import SliceIssueByInsight from '@/components/layout/buildPlans/insight/issue/byInsight/SliceIssueByInsight.vue'
import SupportIssueByInsight from '@/components/layout/buildPlans/insight/issue/byInsight/SupportIssueByInsight.vue'
import BridgingIssueByInsight from '@/components/layout/buildPlans/insight/issue/byInsight/BridgingIssueByInsight.vue'
import SimulateIssueByInsight from '@/components/layout/buildPlans/insight/issue/byInsight/SimulateIssueByInsight.vue'
import StabilityIssueByInsight from '@/components/layout/buildPlans/insight/issue/byInsight/StabilityIssueByInsight.vue'
import { IInsightsCount, InsightsSeverity } from '@/types/Common/Insights'
import { RouterNames } from '@/router'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component({
  components: { IssuesContainers, IssueWrapper, LayoutIssueByInsight },
})
export default class IssuesGroup extends Vue {
  @buildPlansStore.Getter buildPlanItemById: (id: string) => IBuildPlanItem
  @buildPlansStore.Getter insightsCount: IInsightsCount
  @buildPlansStore.Getter getSelectedBuildPlanItems: IBuildPlanItem[]

  @buildPlansStore.Mutation setInsightsCount: (count: IInsightsCount) => void

  @Prop({ default: {} }) issues: Partial<{ [key in InsightErrorCodes]: IBuildPlanInsight[] }>
  @Prop() severity: InsightsSeverity
  @Prop({ default: InsightGroups.ByInsight }) byGroup: InsightGroups
  @Prop({ default: true }) showContent: boolean

  insightGroups = InsightGroups
  filteredIssues: Partial<{ [key in InsightErrorCodes]: IBuildPlanInsight[] }> = {}
  sortedHeaders: string[] = []

  beforeMount() {
    this.onFilteredIssuesChanged()
  }

  @Watch('filteredIssues', { immediate: true })
  onIssuesChanged() {
    if (this.filteredIssues) {
      const count = Object.keys(this.filteredIssues).length
      if (this.severity === InsightsSeverity.Warning) {
        this.setInsightsCount({ warnings: count, errors: this.insightsCount.errors })
      } else {
        this.setInsightsCount({ errors: count, warnings: this.insightsCount.warnings })
      }
    } else {
      if (this.severity === InsightsSeverity.Warning) {
        this.setInsightsCount({ warnings: 0, errors: this.insightsCount.errors })
      } else {
        this.setInsightsCount({ errors: 0, warnings: this.insightsCount.warnings })
      }
    }
  }

  @Watch('$route')
  @Watch('issues')
  @Watch('getSelectedBuildPlanItems')
  onFilteredIssuesChanged() {
    if (!this.issues) {
      this.filteredIssues = this.issues
      this.updateSortedHeaders()
      return
    }

    const filteredIssues = JSON.parse(JSON.stringify(this.issues))
    const routerName = this.$route && this.$route.name ? this.$route.name.toLowerCase() : ''
    const constructionFailed = InsightErrorCodes.SupportToolConstructionFailed
    if (
      routerName === RouterNames.EBP_Support && // If support tool is open.
      filteredIssues[constructionFailed] &&
      this.getSelectedBuildPlanItems
    ) {
      filteredIssues[constructionFailed] = filteredIssues[constructionFailed].filter((issue) => {
        return !this.getSelectedBuildPlanItems.find((item) => item.id === issue.details.bpItemId)
      })

      if (!filteredIssues[constructionFailed].length) {
        delete filteredIssues[constructionFailed]
      }
    }

    this.filteredIssues = filteredIssues
    this.updateSortedHeaders()
  }

  updateSortedHeaders() {
    if (!this.filteredIssues) {
      this.sortedHeaders = []
      return
    }

    this.sortedHeaders = Object.keys(this.filteredIssues).sort((k1: string, k2: string) => {
      const key1 = this.$t(this.message(k1)) as string
      const key2 = this.$t(this.message(k2)) as string
      return key1.localeCompare(key2)
    })
  }

  getIssuesCount(header) {
    let issuesCount = 0
    switch (Number(header)) {
      case InsightErrorCodes.SliceToolThinContour:
      case InsightErrorCodes.SliceToolSmallVoidBetweenContours:
      case InsightErrorCodes.SliceToolOverlappingContours:
      case InsightErrorCodes.SliceToolSelfIntersectingContours:
        this.filteredIssues[header].forEach((issue) => {
          if (Array.isArray(issue.details)) {
            issuesCount += issue.details.length
          }
        })
        break
      case InsightErrorCodes.SimCompToolMacroLayersWarn:
        this.filteredIssues[header].forEach((issue) => {
          issuesCount += issue.details.XX
        })
        break
      default:
        issuesCount = this.filteredIssues[header].length
        break
    }
    return issuesCount
  }

  message(key: InsightErrorCodes | string) {
    return messageMapByInsight[key]
  }

  getComponent(insight: IBuildPlanInsight) {
    switch (insight.errorCode) {
      case InsightErrorCodes.LayoutToolPartIntersectsPart:
      case InsightErrorCodes.LayoutToolPartIsOutOfPlate:
      case InsightErrorCodes.LayoutToolPartIsPartiallyOutOfPlate:
      case InsightErrorCodes.LayoutToolPartIsTouchingCeiling:
      case InsightErrorCodes.LayoutToolPartIntersectsKeepOutZone:
      case InsightErrorCodes.LayoutToolPartFloatsAboveBuildPlate:
      case InsightErrorCodes.LayoutToolPartBiggerThanBuildVolume:
      case InsightErrorCodes.LayoutToolPartAboveSafeDosingHeight:
      case InsightErrorCodes.LayoutToolPartTallerThanSafeDosingHeight:
      // For now the same behaviour as for Layout Tool (code deduplication)
      // If needed create separate component for displaying part properties insights
      case InsightErrorCodes.PartPropertiesNonDefaultScale:
        return LayoutIssueByInsight
      case InsightErrorCodes.SupportToolConstructionFailed:
        return SupportIssueByInsight
      case InsightErrorCodes.LabelToolHighlyCurvedArea:
      case InsightErrorCodes.LabelToolDownFacingArea:
      case InsightErrorCodes.LabelToolDoNotFit:
      case InsightErrorCodes.LabelIntersectingWithBody:
      case InsightErrorCodes.LabelCloseToBody:
      case InsightErrorCodes.LabelIsSelfIntersecting:
      case InsightErrorCodes.LabelToolMinimumCount:
      case InsightErrorCodes.LabelToolNotCreated:
        return LabelIssueByInsight
      case InsightErrorCodes.SliceToolThinContour:
      case InsightErrorCodes.SliceToolSmallVoidBetweenContours:
      case InsightErrorCodes.SliceToolOverlappingContours:
      case InsightErrorCodes.SliceToolSelfIntersectingContours:
        return SliceIssueByInsight
      case InsightErrorCodes.SimCompToolMeshBridgingDetected:
        return BridgingIssueByInsight
      case InsightErrorCodes.SimCompToolCustomScaleFactor:
      case InsightErrorCodes.SimCompToolMacroLayersWarn:
        return SimulateIssueByInsight
      case InsightErrorCodes.UnbalancedPart:
        return StabilityIssueByInsight
    }
  }
}
