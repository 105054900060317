import { ActionTree } from 'vuex'
import { IUsersState } from './types'
import { IRootState } from '@/store/types'
import { IUser } from '@/types/User/IUser'

import userService from '@/api/users'

export const actions: ActionTree<IUsersState, IRootState> = {
  setIsAuthenticated({ commit }, isLoggedIn) {
    commit('setIsAuthenticatedUser', isLoggedIn)
  },

  setIsAuthenticating({ commit }, isAuthenticating) {
    commit('setIsAuthenticating', isAuthenticating)
  },

  setAuth0Client({ commit }, auth0Client) {
    commit('setAuth0Client', auth0Client)
  },

  setUserDetails({ commit }, authUser: IUser) {
    if (authUser) {
      commit('setUser', authUser)
    } else commit('setUser', null)
  },

  setAuthPromise({ commit }, authPromise) {
    commit('setAuthPromise', authPromise)
  },

  async fetchAllUsers({ commit, state }) {
    if (Object.values(state.users.byId).length) return

    let users
    if (state.isLoadingUsers) {
      users = state.users
    } else {
      commit('setIsLoadingUsers', true)
      users = await userService.getAllUsers()
      commit('setUsers', users)
      commit('setIsLoadingUsers', false)
    }

    return users
  },

  async fetchAllUsersForAdmin({ commit }) {
    const users = await userService.getAllUsersForAdmin()
    commit('setUsers', users)
  },

  fetchAllCountries({ commit }) {
    const countries = userService.getAllCountries()
    commit('setCountries', countries)
  },

  async getUserPermissions() {
    const permissions = await userService.getUserPermissions()
    return permissions
  },

  async authorizeResources() {
    const authorizeResources = await userService.authorizeResources()
    return authorizeResources
  },

  inviteUser({ commit }, payload: { email: string; inviteAsAdmin: boolean }) {
    return userService.inviteUser(payload.email, payload.inviteAsAdmin)
  },

  toggleUserAdmin({ commit }, payload: { email: string; setAsAdmin: boolean }) {
    return userService.toggleUserAdmin(payload.email, payload.setAsAdmin)
  },

  updateUser({ commit }, user: IUser) {
    return userService.updateUser(user)
  },

  async ssoMigrateUser({ commit, rootGetters }, force: boolean) {
    const user: IUser = rootGetters['user/getUserDetails']

    await userService.ssoMigrateUser(force)

    if (force) {
      return
    }

    if (!user.ssoMigratedTenants) {
      user.ssoMigratedTenants = []
    }

    user.ssoMigratedTenants.push(user.tenant)

    commit('setUser', user)
  },

  async getAccountActivationLink({ commit }, userId: string) {
    const response = await userService.getAccountActivationLink(userId)
    return response.ticket
  },

  async prepareSupports() {
    const prepareSupports = await userService.prepareSupports()
    return prepareSupports
  },

  async prepareLabels() {
    const prepareLabels = await userService.prepareLabels()
    return prepareLabels
  },
}
