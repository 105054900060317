
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component
export default class MarkingJobStatusModal extends Vue {
  @Prop() onCancel: () => void
  @Prop() getModal: () => void
  @Prop() visible
  @Prop() markJobs: any

  onCancelClick() {
    this.onCancel()
  }
}
