import { GetterTree } from 'vuex'
import { IUsersState } from './types'
import { IRootState } from '@/store/types'
import i18n from '@/plugins/i18n'
import { IUser } from '@/types/User/IUser'
import { getFullName } from '@/utils/user'

export const getters: GetterTree<IUsersState, IRootState> = {
  getUserDetails(state) {
    return state.user
  },

  isAuthenticated(state) {
    return state.isLoggedIn
  },

  isAuthenticating(state) {
    return state.isAuthenticating
  },

  getAuth0Client(state) {
    return state.auth0Client
  },

  getAuthPromise(state) {
    return state.authPromise
  },

  getFullName(state) {
    return state.user && getFullName(state.user)
  },

  getAllUsers(state) {
    return Object.values(state.users.byId)
  },

  getUserById: (state) => (userId: string) => {
    return state.users.byId[userId] || null
  },

  lookupFullNameById(state) {
    return (userId: string, allowYou: boolean = false) => {
      let result = ''
      if (allowYou && state.user.id === userId) {
        result = i18n.t('you') as string
      } else if (state.users) {
        const user = state.users.byId[userId]
        if (user) {
          result = getFullName(user)
        } else {
          result = userId
        }
      }
      return result
    }
  },

  getAllCountries(state) {
    return state.countries
  },

  getActiveUsersOrderedByLastName(state): IUser[] {
    return Object.values(state.users.byId)
      .filter((user) => !user.isBlocked)
      .sort((a: IUser, b: IUser) => {
        const firstUserToCompare = a.lastName ? a.lastName : a.email
        if (!firstUserToCompare) {
          return 1
        }

        const secondUserToCompare = b.lastName ? b.lastName : b.email
        if (!secondUserToCompare) {
          return -1
        }

        return firstUserToCompare.toLowerCase().localeCompare(secondUserToCompare.toLowerCase())
      })
  },
}
