import StoresNamespaces from '@/store/namespaces'
import {
  IBuildPlan,
  IBuildPlanItem,
  IPrintStrategyParameterSet,
  ISelectable,
  SelectionUnit,
} from '@/types/BuildPlans/IBuildPlan'
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import partsService from '@/api/parts'
import { FileTypes, IJob } from '@/types/PartsLibrary/Job'
import { IFileTags } from '@/types/Common/IFileDetails'
import { interactiveCommunicationService, sequenceEvent, sequenceTarget } from '@/services/LabelInteractiveService'
import { IUser } from '@/types/User/IUser'
import {
  IInteractiveServiceCommand,
  InteractiveServiceCommandNames,
} from '@/types/InteractiveService/IInteractiveServiceCommand'
import { CancellationToken, createGuid } from '@/utils/common'
import { IInteractiveServiceMessage, OperationStatus } from '@/types/InteractiveService/IInteractiveServiceMessage'
import { Setting } from '@/types/Label/Setting'
import messageService from '@/services/messageService'
import { Watch } from 'vue-property-decorator'
import {
  InteractiveLabelGenerationResponse,
  LabelFailureMessage,
  LabelInsightRelatedItem,
  LabelJsonPatchContent,
  LabelMessageContent,
} from '@/types/InteractiveService/LabelMessageContent'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { Patch } from '@/types/Label/Patch'
import { ISaveFileDto } from '@/types/InteractiveService/ISaveFileDto'
import {
  CachedLabelInsight,
  IBuildPlanInsight,
  InsightErrorCodes,
  IPendingInsights,
} from '@/types/BuildPlans/IBuildPlanInsight'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { PrintingTypes } from '@/types/IMachineConfig'
import {
  LabelExecuteCommandBody,
  LabelExecuteCommandParameters,
  LabelExecuteCommandSettings,
} from '@/types/InteractiveService/LabelExecuteCommandParameter'
import { eventBus } from '@/services/EventBus'
import { InteractiveServiceEvents } from '@/types/InteractiveService/InteractiveServiceEvents'
import { MULTI_LINE_LATIN_CHARACTERS_PATTERN, ONLY_WHITESPACES_PATTERN, PART_BODY_ID_DELIMITER } from '@/constants'
import { TextElement } from '@/types/Label/TextElement'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { convert } from '@/utils/converter/lengthConverter'
import { LabeledBody } from '@/types/Label/LabeledBody'
import { AutomaticPlacementInfo } from '@/types/Label/AutomaticPlacementInfo'
import { ConnectionState } from '@/utils/socketConnection'
import { LabelDirtyState, MarkingLocation, CharacterLengthControl, MarkingContentElementType } from '@/types/Label/enums'
import { LabelSetDto } from '@/types/Label/LabelSetDto'
import { Placement } from '@/types/Label/Placement'
import {
  AutomatedTrackableLabel,
  createAutomatedTrackableLabel,
  createManualTrackableLabel,
  ErrorCodes,
  ManualTrackableLabel,
  TrackableLabel,
} from '@/types/Label/TrackableLabel'
import { InteractiveLabelSetNamedList } from '@/types/Label/InteractiveLabelSetNamedList'
import { LabeledBodyWIthTransformation } from '@/types/Label/LabeledBodyWIthTransformation'
import { isManualLabel } from '@/utils/label/labelUtils'
import { InsightsSeverity } from '@/types/Common/Insights'
import { ISelectableNode } from '@/visualization/rendering/SelectionManager'
import { ILabelOrientation } from '@/types/Marking/ILabel'
import { v4 as uuidv4 } from 'uuid'
import { UserEntry } from '@/types/PrintOrder/UserEntry'
import _ from 'lodash'

const OUTPUT_FOLDER_NAME = 'output'
const GUID_REGEX_PATTERN = '[0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12}'
const LABEL_REGEX_PATTERN = 'label'
const PATCH_REGEX_PATTERN = 'patch'
const DEBOUNCE_TIME = 700
const DEFAULT_ATTACHMENT_DEPTH_MULTIPLIER = 4

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const labelStore = namespace(StoresNamespaces.Labels)
const userStore = namespace(StoresNamespaces.User)
const partsStore = namespace(StoresNamespaces.Parts)
const visualizationStore = namespace(StoresNamespaces.Visualization)

// execute process readiness promise
export function createExecuteProcessComplete() {
  executeProcessComplete = new Promise((resolve, reject) => {
    resolveExecuteProcessCompletePromise = resolve
    rejectExecuteProcessCompletePromise = reject
  })
}

let resolveExecuteProcessCompletePromise
let rejectExecuteProcessCompletePromise
export let executeProcessComplete: Promise<any>

// save process readiness promise
export function createSaveProcessComplete() {
  isSaved = new Promise((resolve, reject) => {
    resolveIsSavedPromise = resolve
    rejectIsSavedPromise = reject
  })
}

let resolveIsSavedPromise
let rejectIsSavedPromise
export let isSaved: Promise<any> = new Promise((resolve, reject) => {
  resolveIsSavedPromise = resolve
  rejectIsSavedPromise = reject
})

// update process readiness promise
export function createUpdateProcessComplete() {
  isUpdated = new Promise((resolve, reject) => {
    resolveIsUpdatedPromise = resolve
    rejectIsUpdatedPromise = reject
  })
}

export let resolveIsUpdatedPromise
let rejectIsUpdatedPromise
export let isUpdated: Promise<any> = new Promise((resolve, reject) => {
  resolveIsUpdatedPromise = resolve
  rejectIsUpdatedPromise = reject
})

export const labelInsightWarningCodes: InsightErrorCodes[] = [
  InsightErrorCodes.LabelToolDoNotFit,
  InsightErrorCodes.LabelToolDownFacingArea,
  InsightErrorCodes.LabelCloseToBody,
  InsightErrorCodes.LabelIsSelfIntersecting,
  InsightErrorCodes.LabelToolHighlyCurvedArea,
]

@Component
export class LabelServiceMixin extends Vue {
  get defaultAttachmentDepthByParameterId() {
    const parameterSets = this.parameterSetsLatestVersions || []
    const ids = parameterSets.map((ps) => ps.id)
    const parameterId = ids.shift()
    const { layerThickness } = this.parameterSetsLatestVersions.find((ps) => ps.id === parameterId)
    const layerThicknessToMm = parseFloat(convert('m', 'mm', layerThickness).toPrecision(3))
    return layerThicknessToMm * DEFAULT_ATTACHMENT_DEPTH_MULTIPLIER
  }

  get isDMLM() {
    return this.buildPlan.modality === PrintingTypes.DMLM
  }

  get allSetsDefined() {
    return this.labelSets.every((ls: InteractiveLabelSet) => ls.selectedBodies.length && ls.labels.length)
  }

  get atLeastOneSetDefined() {
    return this.labelSets.some((ls: InteractiveLabelSet) => ls.selectedBodies.length && ls.labels.length)
  }

  get validateAllLabelSets() {
    return this.labelSets.every((ls: InteractiveLabelSet) => this.validateLabelSet(ls))
  }

  get dirtyLabelSetsAreValid() {
    return this.getDirtyLabelSetsToExecute.every((ls: InteractiveLabelSet) => this.validateLabelSet(ls))
  }

  @partsStore.Action getPartConfigFile: Function
  @labelStore.Action saveLabelSets: (ignoreLoading?: boolean) => Promise<void>
  @labelStore.Action getLabelSetsByBuildPlanId: (payload: {
    buildPlanId: string
    dirtyStateAddIfNew?: boolean
  }) => Promise<LabelSetDto[]>
  @labelStore.Action setActiveLabelSet: (labelSet: InteractiveLabelSet) => void
  @labelStore.Action removeLabels: (payload: {
    labelsInfo: Array<{
      labelSetId: string
      buildPlanItemId?: string
      componentId?: string
      geometryId?: string
      labelId?: string
    }>
    stateOnly: boolean
  }) => Promise<void>
  @labelStore.Action setActiveLabelSetSettingsProp: (payload: { propName: string; value: any }) => void
  @labelStore.Action removeDynamicElement: (id: string | number) => void
  @buildPlansStore.Action deleteInsightsWithNoStateChanges: (insightsIds: string[]) => Promise<void>
  @buildPlansStore.Action deleteInsightMultiple: (payload: {
    insightsIds: string[]
    stateOnly: boolean
  }) => Promise<void>
  @buildPlansStore.Action updateInsightMultiple: (payload: {
    insights: IBuildPlanInsight[]
    stateOnly: boolean
  }) => Promise<void>
  @buildPlansStore.Action createInsightMultiple: (payload: {
    insights: IBuildPlanInsight[]
    stateOnly: boolean
  }) => Promise<void>
  @labelStore.Action makeTrackableLabelsDirty: (
    payload: Array<{
      labelSetId: string
      id: string
      dirtyState: LabelDirtyState
    }>,
  ) => void

  @buildPlansStore.Action storeLabelInsights: () => void
  @buildPlansStore.Action fetchInsightsByBuildPlanId: (payload: {
    buildPlanId: string
    changeState: boolean
  }) => Promise<IBuildPlanInsight[]>
  @buildPlansStore.Action('updateBuildPlanV1') updateBuildPlan: (payload: {
    buildPlan: IBuildPlan
    hideAPIErrorMessages?: boolean
  }) => Promise<IBuildPlan>
  @buildPlansStore.Action setSelectionMode: (payload: {
    mode: SelectionUnit
    options?: { shouldAffectSelectionBox: boolean }
  }) => Promise<void>
  @labelStore.Action addLabelOnScene: (payload: {
    drc: ArrayBuffer
    buildPlanItemId: string
    componentId: string
    geometryId: string
    id: string
    labelSetId: string
    isFailed: boolean
    orientation: ILabelOrientation
    rotationAngle: number
    trackId: string
  }) => void
  @labelStore.Action removeLabelsFromCanvas: (
    payload?: Array<{
      labelSetId?: string
      buildPlanItemId?: string
      componentId?: string
      geometryId?: string
      labelId?: string
      trackId?: string
    }>,
  ) => void
  @labelStore.Action restoreLabelSetsFromCache: (labelSetIds: string[]) => void
  @labelStore.Action markNotCreatedManualLabel: (payload: { labelId: string; trackId: string }) => void

  @userStore.Action prepareLabels: () => Promise<void>

  @buildPlansStore.Mutation reportInsightIssues: (issues: IPendingInsights[]) => Promise<IPendingInsights[]>
  @buildPlansStore.Mutation loadInsights: (insights: IBuildPlanInsight[]) => void
  @buildPlansStore.Mutation setRequiresLabelSetUpdates: (value: boolean) => void
  @buildPlansStore.Mutation removeInsightsByInsights: (insightsToRemove: IBuildPlanInsight[]) => void

  @labelStore.Getter activeLabelSet: InteractiveLabelSet
  @labelStore.Getter labelSetsSnapshot: InteractiveLabelSet[]
  @labelStore.Getter isLabelSetsChanged: boolean
  @labelStore.Getter getLabelSetsToSave: {
    labelSetsToCreate: InteractiveLabelSet[]
    labelSetsToUpdate: InteractiveLabelSet[]
    labelSetsToRemove: InteractiveLabelSet[]
  }
  @labelStore.Getter labelSets: InteractiveLabelSet[]
  @labelStore.Getter activeLabelSetSettings: Setting
  @labelStore.Getter isLabelSetActivated: boolean
  @labelStore.Getter isLabelSetOpened: boolean
  @labelStore.Getter isPatchedBodiesSelected: boolean
  @labelStore.Getter getActiveLabelCommandParameters: () => LabelExecuteCommandSettings[]
  @labelStore.Getter getCommandParametersWithNoActiveLabel: () => LabelExecuteCommandSettings[]
  @labelStore.Getter getCommandParametersByLabelSetsIDs: (ids: string[]) => LabelExecuteCommandSettings[]
  @labelStore.Getter getActiveWithRelatedLabelSets: (relatedByAllDynamicElementsTypes: boolean) => InteractiveLabelSet[]
  @labelStore.Getter getLabelSetWithRelatedLabelSets: (labelSetId: string) => InteractiveLabelSet[]
  @labelStore.Getter getRelatedLabelSets: InteractiveLabelSet[]
  @labelStore.Getter getCachedLabelInsights: IBuildPlanInsight[]
  @labelStore.Getter manualPlacementsForLabelSet: (labelSetId: string) => Placement[]
  @labelStore.Getter getExecuteTimeout: number
  @labelStore.Getter getIsSilent: boolean
  @labelStore.Getter lastDeletedElementIDs: number[]
  @labelStore.Getter getLabelToolIsValid: boolean
  @labelStore.Getter getActiveLabelIsValid: boolean
  @labelStore.Getter getCachedPatches: { [key: string]: Patch[] }
  @labelStore.Getter getLabelSetById: (labelSet: string) => InteractiveLabelSet
  @labelStore.Getter getLabelSets: InteractiveLabelSet[]
  @labelStore.Getter getIsLabelExecuteTriggered: boolean
  @labelStore.Getter getLabelUpdateInProgress: boolean
  @labelStore.Getter getLabelSetsIDsForUpdate: string[]
  @labelStore.Getter('getWatchToolReady') watchToolReady: boolean
  @labelStore.Getter getSettingsAreValid: boolean
  @labelStore.Getter getUserSettingsAreValid: boolean
  @labelStore.Getter getDetectedDiff: { toAdd: LabeledBody[]; toRemove: LabeledBody[]; shouldUseDiff: boolean }
  @labelStore.Getter isActiveLabelSetHasDynamicField: boolean
  @labelStore.Getter namedList: (additionalLabelSets?: InteractiveLabelSet[]) => InteractiveLabelSetNamedList[]
  @labelStore.Getter shouldActiveLabelSetRegenerateLabelsDueToDynamicField: boolean
  @labelStore.Getter getLabelIndicesForExecute: (labelSetId: string) => number[]
  @labelStore.Getter isExitingFromTool: boolean
  @labelStore.Getter getDirtyLabelSetsToExecute: InteractiveLabelSet[]
  @labelStore.Getter getCachedInsights: CachedLabelInsight[]
  @labelStore.Getter isLabelSetHasDirtyLabels: (labelSetId: string) => boolean
  @labelStore.Getter getCurrentCommand: IInteractiveServiceCommand
  @labelStore.Getter isLabelSetsHasDirtyLabel: boolean
  @labelStore.Getter variantDynamicElements: TextElement[]
  @labelStore.Getter getExecuteCommandParamsForLabelSet: (
    labelSet: InteractiveLabelSet,
    firstDirtyLabel: TrackableLabel,
  ) => LabelExecuteCommandParameters
  @labelStore.Getter getTrackableLabel: (trackId: string, labelSetId: string) => TrackableLabel
  @labelStore.Getter isLabelSetContainsCounter: (labelSetId: string) => boolean
  @labelStore.Getter getLabelAddedPromise: { promise: Promise<void>; done: Function }

  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getCompleteAndRunningSlicingJobs: (id: string) => IJob[]
  @buildPlansStore.Getter getSelectedParts: ISelectable[]
  @buildPlansStore.Getter buildPlanItemById: (id: string) => IBuildPlanItem
  @buildPlansStore.Getter labelInsights: IBuildPlanInsight[]
  @buildPlansStore.Getter isReadOnly: boolean
  @buildPlansStore.Getter getSelectedBuildPlanFinalizingJobs: IJob[]
  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter getRequiresLabelSetUpdates: boolean
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes
  @buildPlansStore.Getter parameterSetsLatestVersions: IPrintStrategyParameterSet[]
  @buildPlansStore.Getter getRunningJobsByVariantId: (id: string) => IJob[]
  @buildPlansStore.Getter insights: IBuildPlanInsight[]
  @buildPlansStore.Getter isLabelReadOnly: boolean
  @buildPlansStore.Getter isLabelRestoreInProgress: boolean

  @userStore.Getter getUserDetails: IUser

  @labelStore.Mutation createLabelSetsSnapshot: () => void
  @labelStore.Mutation setEmptyLabelSetsSnapshot: () => void
  @labelStore.Mutation restoreLabelSetsFromSnapshot: (labelSetIds?: string[]) => void
  @labelStore.Mutation clearLabelSetsSnapshot: (force?: boolean) => void
  @labelStore.Mutation clearLabelAutomaticPlacements: () => void
  @labelStore.Mutation setLabelSetPatches: (payload: { labelSetId: string; patches: Patch[] }) => void
  @labelStore.Mutation addLabelSetPatches: (payload: { labelSetId: string; patches: Patch[] }) => void
  @labelStore.Mutation setLabelSetPatch: (payload: { labelSetId: string; patch: Patch }) => void
  @labelStore.Mutation setIsLabelSetOpened: (payload: boolean) => void
  @labelStore.Mutation setIsPatchedBodiesSelected: (payload: boolean) => void
  @labelStore.Mutation setIsLabelToolOpened: (isOpened: boolean) => void
  @labelStore.Mutation resetTextElements: () => void
  @labelStore.Mutation setIsRunning: (isRunning: boolean) => void
  @labelStore.Mutation cacheLabelInsights: (labelInsights: IBuildPlanInsight[]) => void
  @labelStore.Mutation clearExecuteTimeout: () => void
  @labelStore.Mutation setExecuteTimeout: (timeout: NodeJS.Timeout) => void
  @labelStore.Mutation clearDeletedElements: () => void
  @labelStore.Mutation setLabelToolIsValid: (isValid: boolean) => void
  @labelStore.Mutation setActiveLabelIsValid: (isValid: boolean) => void
  @labelStore.Mutation addCachedPatch: (params: { labelSetId: string; patches: Patch[] }) => void
  @labelStore.Mutation clearCachedPatches: () => void
  @labelStore.Mutation clearLabelIndices: () => void
  @labelStore.Mutation setIgnoreChangesByDirtyStates: (value: boolean) => void
  @labelStore.Mutation setLabelIndicesForExecute: (payload: { labelSetId: string; labelIndices: number[] }) => void
  @labelStore.Mutation clearLabelSetLabelIndices: (labelSetId: string) => void
  @labelStore.Mutation setSaveComplete: (value: boolean) => void
  @labelStore.Mutation setIsLabelExecuteTriggered: (value: boolean) => void
  @labelStore.Mutation setLastDeletedElementIDs: (ids: string[]) => void
  @labelStore.Mutation setDynamicElementsIDsToRefresh: (ids: number[]) => void
  @labelStore.Mutation setLabelUpdateInProgress: (value: boolean) => void
  @labelStore.Mutation setWatchToolReady: (value: boolean) => void
  @labelStore.Mutation setOkIsDisabled: (value: boolean) => void
  @labelStore.Mutation setUserSettingsAreValid: (value: boolean) => void
  @labelStore.Mutation setDetectedDiff: (payload: {
    toAdd: LabeledBody[]
    toRemove: LabeledBody[]
    shouldUseDiff: boolean
  }) => void
  @labelStore.Mutation addAutoPlacements: (payload: {
    labelSetId: string
    manualPlacements: AutomaticPlacementInfo[]
  }) => void
  @labelStore.Mutation addDetectedDiffToRemove: (toRemove: LabeledBody[]) => void
  @labelStore.Mutation setIsExitingFromTool: (payload: boolean) => void
  @labelStore.Mutation setIsSaving: (isSaving: boolean) => void
  @labelStore.Mutation setIsInDirtyState: (payload: boolean) => void
  @labelStore.Mutation setTrackableLabelCommandId: (payload: {
    labelSetId: string
    trackableLabelId: string
    commandId: string
  }) => void
  @labelStore.Mutation addCachedInsight: (payload: CachedLabelInsight) => void
  @labelStore.Mutation clearCachedInsights: () => void
  @labelStore.Mutation removeCachedInsightByIndex: (index: number) => void
  @labelStore.Mutation resetLabelDirtyState: (payload: { trackId: string; labelSetId: string }) => void
  @labelStore.Mutation setLabelErrorCode: (payload: {
    trackId: string
    labelSetId: string
    errorCode: ErrorCodes
  }) => void
  @labelStore.Mutation resetLabelErrorCode: (payload: { trackId: string; labelSetId: string }) => void
  @labelStore.Mutation setCurrentCommand: (command: IInteractiveServiceCommand) => void
  @labelStore.Mutation removeTrackableLabels: (ids: string[]) => void
  @labelStore.Mutation removeTrackableLabelsByLabelSetId: (payload: { labelSetId: string, ids: string[] }) => void
  @labelStore.Mutation setForceRecalculateRelatedForIds: (id: string) => void
  @labelStore.Mutation resetForceRecalculateRelatedForIds: () => void
  @labelStore.Mutation removePatchById: (patchId: string) => void
  @labelStore.Mutation createLabelPromise: () => void
  @labelStore.Mutation addTrackableLabels: (payload: TrackableLabel[]) => void

  @visualizationStore.Mutation hideManualLabelHandle: (force?: boolean) => void
  @visualizationStore.Mutation deselect: (payload?: { items?: ISelectableNode[]; isSilent?: boolean }) => void
  @visualizationStore.Mutation deselectNonBarGeometry: () => void
  @visualizationStore.Mutation activateLabelBarPlacement: () => void
  @visualizationStore.Mutation deactivateLabelBarPlacement: () => void
  @visualizationStore.Mutation deactivateLabelManualPlacement: (payload?: { labelSetId?: string, isSwitchedToAutomatic?: boolean }) => void
  @visualizationStore.Mutation showManuallyPlacedLabelOrigins: (payload?: {
    patches: Placement[]
    labelSetId?: string
  }) => void

  @visualizationStore.Getter getMinPlacementCountInsight: (
    buildPlanId: string,
    relatedItems: LabelInsightRelatedItem[],
  ) => IBuildPlanInsight

  @buildPlansStore.Mutation setIsReadOnly: (payload: { value: boolean; global?: boolean }) => void
  @buildPlansStore.Mutation setActiveToolHasUnsavedData: (hasData: boolean) => void
  @buildPlansStore.Mutation removeInsightsByTool: (toolName: ToolNames) => void
  @buildPlansStore.Mutation setLabelRestoreInProgress: (value: boolean) => void

  token: string
  partFiles: Map<string, { partId: string; files: Array<{ s3filename: string; name: string; bodyIds?: string[] }> }> =
    new Map()
  interactiveCommunicationService = interactiveCommunicationService
  resolveSaveProcessCompletePromise: Function
  rejectSaveProcessCompletePromise: Function
  resolveExecuteProcessCompletePromise: Function
  rejectExecuteProcessCompletePromise: Function
  patchMap: Map<string, Patch[]> = new Map()
  isToolCorrectlyClosed: boolean = false
  isLabelStateChanged: boolean = true
  sequenceEvent: Event = sequenceEvent
  sequenceTarget: EventTarget = sequenceTarget
  temporarySavedContent: Map<string, LabelMessageContent[]> = new Map<string, LabelMessageContent[]>()
  activeSettingChanged: boolean = false
  restoreLabelsPromise: Promise<void> = null

  get isInteractiveServiceStartingOrServesUpdate() {
    return (
      !this.interactiveCommunicationService ||
      this.interactiveCommunicationService.isSetupCommandRunning ||
      this.getRequiresLabelSetUpdates
    )
  }

  async establishConnection(cancellationToken?: CancellationToken) {
    if (this.isLabelReadOnly) {
      return
    }

    this.cacheLabelInsights(this.labelInsights)
    this.token = await Vue.prototype.$auth.getTokenSilently()
    await this.getPartFiles()
    await this.getLabelSetsByBuildPlanId({ buildPlanId: this.getBuildPlan.id })
    await this.prepareLabels()
    this.setIgnoreChangesByDirtyStates(false)
    this.createLabelSetsSnapshot()
    this.clearLabelAutomaticPlacements()
    this.setAutomaticPlacementsBasedOnPatches()
    this.setIsReadOnly({ value: this.isLabelReadOnly })

    this.interactiveCommunicationService.connectionHandler = this.onConnect
    this.interactiveCommunicationService.connectErrorHandler = this.onConnectError
    this.interactiveCommunicationService.disconnectHandler = this.disconnectHandler
    this.interactiveCommunicationService.addCommandHandler(
      InteractiveServiceCommandNames.Setup,
      OperationStatus.Success,
      this.setupSuccessHandler,
    )

    this.interactiveCommunicationService.addCommandHandler(
      InteractiveServiceCommandNames.Setup,
      OperationStatus.Failure,
      this.setupFailureHandler,
    )

    this.interactiveCommunicationService.addCommandHandler(
      InteractiveServiceCommandNames.Execute,
      OperationStatus.Success,
      this.executeSuccessHandler,
    )

    this.interactiveCommunicationService.addCommandHandler(
      InteractiveServiceCommandNames.Execute,
      OperationStatus.Failure,
      this.executeFailureHandler,
    )

    this.interactiveCommunicationService.addCommandHandler(
      InteractiveServiceCommandNames.GetJsonPatches,
      OperationStatus.Success,
      this.getJsonPatchesSuccessHandler,
    )

    this.interactiveCommunicationService.addCommandHandler(
      InteractiveServiceCommandNames.GetJsonPatches,
      OperationStatus.Failure,
      this.getJsonPatchesFailureHandler,
    )

    this.interactiveCommunicationService.addCommandHandler(
      InteractiveServiceCommandNames.Save,
      OperationStatus.Success,
      this.saveSuccessHandler,
    )

    this.interactiveCommunicationService.addCommandHandler(
      InteractiveServiceCommandNames.Save,
      OperationStatus.Failure,
      this.saveFailureHandler,
    )

    this.interactiveCommunicationService.addCommandHandler(
      InteractiveServiceCommandNames.ClearLabelSetFiles,
      OperationStatus.Done,
      this.clearLabelSetFilesDoneHandler,
    )

    this.interactiveCommunicationService.addCommandHandler(
      InteractiveServiceCommandNames.ClearLabelSetFiles,
      OperationStatus.Failure,
      this.clearLabelSetFilesFailureHandler,
    )

    this.interactiveCommunicationService.addCommandHandler(
      InteractiveServiceCommandNames.Remove,
      OperationStatus.Done,
      this.removeLabelFilesDoneHandler,
    )

    this.interactiveCommunicationService.addCommandHandler(
      InteractiveServiceCommandNames.Remove,
      OperationStatus.Failure,
      this.removeLabelFilesFailureHandler,
    )

    this.interactiveCommunicationService.addCommandHandler(
      InteractiveServiceCommandNames.Abort,
      OperationStatus.Success,
      this.abortHandler,
    )

    if (cancellationToken && cancellationToken.cancellationRequested) {
      return
    }

    if (this.getRequiresLabelSetUpdates && !this.isReadOnly) {
      this.setWatchToolReady(true)
      this.setLabelUpdateInProgress(true)
    }

    if (!this.isLabelReadOnly) {
      this.interactiveCommunicationService.connect(
        window.env.VUE_APP_LABEL_SVC_URL,
        'labels',
        this.getUserDetails && this.getUserDetails.tenant,
        this.token,
        { buildPlanId: this.getBuildPlan.id },
      )

      this.interactiveCommunicationService.connectionSocket.io.on('error', () => {
        if (!this.interactiveCommunicationService.connectionSocket.connected) {
          this.$emit('triggerSocketDisconnectModal', ConnectionState.FailedToConnect)
          this.closeLabelTool()
        }
      })
    }

    eventBus.$on(InteractiveServiceEvents.AbortLabelAfterLabelSetChange, this.abortLabelAfterLabelSetChange)
    eventBus.$on(InteractiveServiceEvents.ScheduleExecuteCommand, this.executeDebounced)
  }

  addUnloadHandler() {
    window.addEventListener('beforeunload', this.restoreLabelsIfIncorrectlyClosed)
  }

  removeUnloadHandler() {
    window.removeEventListener('beforeunload', this.restoreLabelsIfIncorrectlyClosed)
  }

  resetLabelSetData(force = false) {
    if (this.interactiveCommunicationService) {
      this.interactiveCommunicationService.closeSocket()
    }

    this.setActiveLabelSet(null)
    this.clearLabelSetsSnapshot(force)
    this.deselect()
    eventBus.$off(InteractiveServiceEvents.AbortLabelAfterLabelSetChange, this.abortLabelAfterLabelSetChange)
    eventBus.$off(InteractiveServiceEvents.ScheduleExecuteCommand, this.executeDebounced)
  }

  execute() {
    this.temporarySavedContent.clear()
    this.clearExecuteTimeout()

    // If there is an already running command we should not generate a new one and should not send it to a server
    if (this.getCurrentCommand) {
      return
    }

    const cmd = this.generateExecuteCommand()
    if (!cmd || !cmd.parameters || !(cmd.parameters as LabelExecuteCommandParameters).trackableLabel) {
      this.setOkIsDisabled(false)
      return
    }

    this.fixCurlyBraces(cmd.parameters as LabelExecuteCommandParameters)
    this.setOkIsDisabled(true)
    this.interactiveCommunicationService.sendCommand(cmd)

    const relatedSets = this.activeLabelSet ? this.getActiveWithRelatedLabelSets(true) : this.getRelatedLabelSets
    relatedSets
      .map((labelSet) => labelSet.id)
      .forEach((labelSetId) => {
        if (this.isLabelStateChanged) {
          this.addCachedPatch({ labelSetId, patches: this.getLabelSetById(labelSetId).patches })
        }
      })
  }

  executeDebounced() {
    this.setOkIsDisabled(true)
    this.setIsLabelExecuteTriggered(true)
    this.clearCachedInsights()
    this.clearExecuteTimeout()
    // TODO: Add correct logic for abort command
    // TODO: Add correct logic for scene clear

    this.setExecuteTimeout(setTimeout(this.execute, DEBOUNCE_TIME))
  }

  getJsonPatches() {
    const cmd = this.generateGetJsonPatchesCommand()
    this.interactiveCommunicationService.sendCommand(cmd)
  }

  async saveLabels() {
    if (
      !this.interactiveCommunicationService.connectionSocket ||
      !this.interactiveCommunicationService.connectionSocket.connected
    ) {
      // TODO: Move to resources
      return messageService.showInfoMessage(this.$t('socketIsNotConnected').toString())
    }

    const saveProcessComplete = new Promise((resolve, reject) => {
      this.resolveSaveProcessCompletePromise = resolve
      this.rejectSaveProcessCompletePromise = reject
    })

    this.setIsSaving(true)

    if (this.activeSettingChanged) {
      this.sequenceTarget.addEventListener('sequenceReady', this.performSave)
    } else {
      await this.performSave()
    }

    await saveProcessComplete
    this.setIsSaving(false)
    this.setActiveToolHasUnsavedData(false)
  }

  async performSave() {
    try {
      this.setIsExitingFromTool(true)
      this.setIgnoreChangesByDirtyStates(true)
      createSaveProcessComplete()
      this.getJsonPatches()
      // Before save validation should be performed after getJsonPatches response
      await isSaved
      // In case of file paths validation we have to validate it after save command
      // in order to have paths in patches
      if (this.isLabelSetsChanged) {
        await this.saveLabelSets()
      }

      await this.saveLabelRelatedInsights()
      this.clearLabelIndices()
      this.clearLabelAutomaticPlacements()
      this.setIsExitingFromTool(false)
    } catch (error) {
      messageService.showErrorMessage(error)
    }

    this.sequenceTarget.removeEventListener('sequenceReady', this.performSave)
    this.isToolCorrectlyClosed = true
    this.resolveSaveProcessCompletePromise()
  }

  async saveLabelRelatedInsights() {
    const savedInsights = await this.fetchInsightsByBuildPlanId({ buildPlanId: this.buildPlan.id, changeState: false })
    const savedLabelInsights = savedInsights.filter((insight: IBuildPlanInsight) => insight.tool === ToolNames.LABEL)
    const idsToRemove = savedLabelInsights.map((insight: IBuildPlanInsight) => insight.id)
    if (idsToRemove.length) {
      await this.deleteInsightsWithNoStateChanges(idsToRemove)
    }

    const labelInsightsToCreate = this.labelInsights
    this.removeInsightsByTool(ToolNames.LABEL)
    await this.createInsightMultiple({ insights: labelInsightsToCreate, stateOnly: false })
  }

  clearLabelSetFiles(isPartialClear: boolean = false) {
    const cmd = this.generateClearLabelSetFilesCommand(isPartialClear)
    this.interactiveCommunicationService.sendCommand(cmd)
  }

  removeLabelFiles(labelId: string) {
    const cmd = this.generateRemoveLabelFilesCommand(labelId)
    this.interactiveCommunicationService.sendCommand(cmd)
  }

  async closeLabelTool() {
    this.setIsExitingFromTool(true)
    this.isToolCorrectlyClosed = true
    this.resetTextElements()
    await this.restoreLabels()
    this.setIsExitingFromTool(false)
  }

  abortLabelOnLabelSetChange() {
    eventBus.$emit(InteractiveServiceEvents.AbortLabelAfterLabelSetChange)
  }

  @Watch('interactiveCommunicationService.isCommandRunning')
  onInteractiveCommandRunningChange() {
    this.setIsRunning(this.interactiveCommunicationService.isCommandRunning)
  }

  onLabelSetSettingChange(property: string, newValue: number) {
    const saved = this.activeLabelSet.settings[property]
    if (saved !== newValue) {
      this.setIsInDirtyState(true)
    } else {
      this.setIsInDirtyState(false)
    }
  }

  validateActiveLabelSet() {
    const isValid =
      this.fontSizeIsValid(this.activeLabelSet) &&
      this.textFieldIsValid(this.activeLabelSet) &&
      !this.textFieldContainsOnlyWhitespaces(this.activeLabelSet) &&
      this.getSettingsAreValid && this.getUserSettingsAreValid
    this.setActiveLabelIsValid(isValid)

    return isValid
  }

  fontSizeIsValid(labelSet: InteractiveLabelSet): boolean {
    return (
      (!labelSet.settings.allowFontSizeVariation && !!labelSet.settings.fontTargetSize) ||
      (labelSet.settings.allowFontSizeVariation && labelSet.settings.fontTargetSize >= labelSet.settings.fontMinSize)
    )
  }

  validateLabelSet(labelSet: InteractiveLabelSet) {
    return this.fontSizeIsValid(labelSet) && this.textFieldIsValid(labelSet) && !this.textFieldContainsOnlyWhitespaces(labelSet)
  }

  textFieldIsValid(labelSet: InteractiveLabelSet): boolean {
    return new RegExp(MULTI_LINE_LATIN_CHARACTERS_PATTERN).test(labelSet.settings.textContent)
  }

  textFieldContainsOnlyWhitespaces(labelSet: InteractiveLabelSet): boolean {
    return new RegExp(ONLY_WHITESPACES_PATTERN).test(labelSet.settings.textContent)
  }

  removeDynamicElementIfNotUsed(id: number) {
    const usesDynamicElement = this.labelSets.some((ls: InteractiveLabelSet) => {
      const index = ls.settings.textElements.findIndex((te: TextElement) => te.elementIDNumber === id)
      return index >= 0
    })
    if (!usesDynamicElement) {
      this.removeDynamicElement(id)
    }
  }

  checkElementChanged(savedElementSettings: string, newElementSettings: string) {
    this.activeSettingChanged = savedElementSettings !== newElementSettings
    this.validateTool()
  }

  getLabelSetName(labelId: string, additionalLabelSets?: InteractiveLabelSet[]) {
    if (this.labelSets || additionalLabelSets) {
      const namedLabelSet = this.namedList(additionalLabelSets).find((l) => l.id === labelId)
      if (namedLabelSet) {
        return namedLabelSet.typeRelatedText
      }
    }
    return ''
  }

  validateTool() {
    const toolIsValid =
      !this.isLabelReadOnly &&
      this.allSetsDefined &&
      this.validateAllLabelSets &&
      !this.activeSettingChanged &&
      !this.getCurrentCommand &&
      (this.getBuildPlanViewMode !== ViewModeTypes.Marking ||
        (this.getBuildPlanViewMode === ViewModeTypes.Marking && this.getSettingsAreValid && this.getUserSettingsAreValid))
    this.setLabelToolIsValid(toolIsValid)
    if (this.activeLabelSet) {
      this.validateActiveLabelSet()
      this.validateUserEntries()
    }

    return toolIsValid
  }

  validateUserEntries() {
    const userEntryList = this.variantDynamicElements.filter((te: TextElement) => te.type === MarkingContentElementType.User_Entry)
    const isValid = userEntryList.every((userEntry: UserEntry) => this.validateUserEntry(userEntry))
    this.setUserSettingsAreValid(isValid)
  }

  validateUserEntry(userEntry: UserEntry) {
    const min = userEntry.lengthMin
    const max = userEntry.lengthMax
    const value = this.getUserEntryPreview(userEntry)
    if (value.length === 0)
      return true
    switch (userEntry.lengthControl) {
      case CharacterLengthControl.Specific:
        return value.length == max
      case CharacterLengthControl.Maximum:
        return value.length <= max
      case CharacterLengthControl.Range:
        return value.length >= min && value.length <= max
      default:
        return true
    }
  }


  getUserEntryPreview(userEntry: TextElement) {
    let previewText = ""
    const json = userEntry._cachedSpecificsJSON ? JSON.parse(userEntry._cachedSpecificsJSON) : null
    if (json && json.previewText) {
      previewText = json.previewText
    }
    return previewText
  }

  validateDirtyLabelSets() {
    // Dirty label sets are valid for the execution in case:
    // - Label tool is not in readonly state
    // - All label sets that contain dirty labels are defined and valid
    // - Active label set was not changed during current execution chain
    // - There is no current command available
    // - Settings of a current label set are valid, if such exists and we are inside the label tool
    const dirtySetsAreValid =
      !this.isLabelReadOnly &&
      this.dirtyLabelSetsAreValid &&
      !this.activeSettingChanged &&
      !this.getCurrentCommand &&
      (this.getBuildPlanViewMode !== ViewModeTypes.Marking ||
        (this.getBuildPlanViewMode === ViewModeTypes.Marking && this.getSettingsAreValid && this.getUserSettingsAreValid))
    if (this.activeLabelSet) {
      this.validateActiveLabelSet()
      this.validateUserEntries()
    }
    return dirtySetsAreValid
  }

  setAutomaticPlacementsBasedOnPatches(labelSets?: InteractiveLabelSet[]) {
    const labelSetsToSetPatches = labelSets ? labelSets : this.labelSets
    if (labelSetsToSetPatches) {
      labelSetsToSetPatches.forEach((ls: InteractiveLabelSet) => {
        this.addAutoPlacements({
          labelSetId: ls.id,
          manualPlacements: ls.patches
            ? ls.patches.map((patch: Patch) => {
              return {
                buildPlanItemId: patch.buildPlanItemId,
                componentId: patch.componentId,
                geometryId: patch.geometryId,
              }
            })
            : [],
        })
      })
    }
  }

  /** Calculates insights for minimal required labels placed on a body based on label sets settings and labels
   * placed on selected and related bodies
   * @param {string[]} labelSetsIds - list of label sets ids to check minimal placement
   * @returns {IBuildPlanInsight[]} - minimal placement build plan insights
   */
  checkMinPlacementCount(labelSetsIds: string[]): IBuildPlanInsight[] {
    const failedLabels: LabelInsightRelatedItem[] = []
    const succedLabels: LabelInsightRelatedItem[] = []
    this.labelSets
      .filter((labelSet) => labelSetsIds.includes(labelSet.id) && labelSet.settings.placementMethodAutomatic)
      .forEach((ls) => {
        const bodies = [...ls.selectedBodies, ...ls.relatedBodies]
        ls.labels.forEach((label) => {
          let buildPlanItemId: string
          let componentId: string
          let geometryId: string
          if (ls.settings.placementMethodAutomatic) {
            const automated = label as AutomatedTrackableLabel
            const body = bodies.find((body) => body.id === automated.bodyId)
            buildPlanItemId = body.buildPlanItemId
            componentId = body.componentId
            geometryId = body.geometryId
          } else {
            const manual = label as ManualTrackableLabel
            const placement = ls.manualPlacements.find((placement) => placement.id === manual.manualPlacementId)
            buildPlanItemId = placement.buildPlanItemId
            componentId = placement.componentId
            geometryId = placement.geometryId
          }

          const labelRelatedItem = {
            buildPlanItemId,
            componentId,
            geometryId,
            labelSetId: ls.id,
          }

          label.errorCode === null ? succedLabels.push(labelRelatedItem) : failedLabels.push(labelRelatedItem)
        })
      })

    const relatedItems: LabelInsightRelatedItem[] = []
    const insights: IBuildPlanInsight[] = []

    if (succedLabels.length) {
      labelSetsIds.forEach((labelSetId: string) => {
        const labelSet: InteractiveLabelSet = this.getLabelSetById(labelSetId)
        const minPlacementCount: number = labelSet.settings.placementMinCount
        const uniqueBodiesOfBpItem = [
          ...new Set(
            succedLabels
              .filter((label) => label.labelSetId === labelSetId)
              .map(
                (label) =>
                  `${label.buildPlanItemId}${PART_BODY_ID_DELIMITER}${label.componentId}` +
                  `${PART_BODY_ID_DELIMITER}${label.geometryId}`,
              ),
          ),
        ]

        uniqueBodiesOfBpItem.forEach((uniqueBody: string) => {
          const [buildPlanItemId, componentId, geometryId] = uniqueBody.split(PART_BODY_ID_DELIMITER)
          const labelsPlacedOntoBody: number = succedLabels.filter((label) => {
            return (
              label.buildPlanItemId === buildPlanItemId &&
              label.componentId === componentId &&
              label.geometryId === geometryId
            )
          }).length
          if (labelsPlacedOntoBody < minPlacementCount) {
            relatedItems.push({
              componentId,
              buildPlanItemId,
              geometryId,
              labelSetId,
            })
          }
        })

        if (relatedItems.length) {
          insights.push(this.getMinPlacementCountInsight(this.buildPlan.id, relatedItems))
        }
      })
    } else {
      labelSetsIds.forEach((labelSetId: string) => {
        const labelSet: InteractiveLabelSet = this.getLabelSetById(labelSetId)
        const minPlacementCount: number = labelSet.settings.placementMinCount
        const uniqueBodiesOfBpItem = [
          ...new Set(
            failedLabels
              .filter((label) => label.labelSetId === labelSetId)
              .map(
                (label) =>
                  `${label.buildPlanItemId}${PART_BODY_ID_DELIMITER}${label.componentId}` +
                  `${PART_BODY_ID_DELIMITER}${label.geometryId}`,
              ),
          ),
        ]

        uniqueBodiesOfBpItem.forEach((uniqueBody: string) => {
          const [buildPlanItemId, componentId, geometryId] = uniqueBody.split(PART_BODY_ID_DELIMITER)
          relatedItems.push({
            componentId,
            buildPlanItemId,
            geometryId,
            labelSetId,
          })
        })

        if (minPlacementCount > 0 && relatedItems.length) {
          insights.push(this.getMinPlacementCountInsight(this.buildPlan.id, relatedItems))
        }
      })
    }

    return insights
  }

  cacheInsight(payload: CachedLabelInsight) {
    if (payload.content) {
      this.addCachedInsight(payload)
    } else {
      const cachedInsights = this.getCachedInsights
      const index = cachedInsights.findIndex((ci: CachedLabelInsight) => {
        return (
          ci.itemId.geometryId === payload.itemId.geometryId &&
          ci.itemId.componentId === payload.itemId.componentId &&
          ci.itemId.bpItemId === payload.itemId.bpItemId &&
          (!payload.itemId.labelId || (payload.itemId.labelId && ci.itemId.labelId === payload.itemId.labelId))
        )
      })
      if (index >= 0) {
        this.removeCachedInsightByIndex(index)
      }
    }
  }

  generateExecuteCommandParams(): LabelExecuteCommandParameters {
    // Take active label set if it is present and has 'dirty' label, otherwise take first label set with 'dirty' labels
    const labelSet =
      this.activeLabelSet && this.isLabelSetHasDirtyLabels(this.activeLabelSet.id)
        ? this.activeLabelSet
        : this.labelSets.find((ls: InteractiveLabelSet) => this.isLabelSetHasDirtyLabels(ls.id))
    if (!labelSet) {
      return null
    }

    // Take first dirty label and add command id to that trackable label
    const firstDirtyLabel = labelSet.labels.find((labelCandidate: TrackableLabel) => labelCandidate.isDirty)

    // Execute command should be scheduled for changes only in case if the whole tool is valid
    // Run execute if label set is valid and fully defined and not in read only state
    const activeLabelSetId = this.activeLabelSet ? this.activeLabelSet.id : null
    const isAbleToExecuteDirtyLabel =
      labelSet.id !== activeLabelSetId &&
      labelSet.selectedBodies.length &&
      this.validateLabelSet(labelSet) &&
      !this.isLabelReadOnly
    if (
      !this.validateDirtyLabelSets() &&
      firstDirtyLabel.dirtyState !== LabelDirtyState.Remove &&
      !isAbleToExecuteDirtyLabel
    ) {
      return null
    }

    this.setTrackableLabelCommandId({
      commandId: createGuid(),
      labelSetId: labelSet.id,
      trackableLabelId: firstDirtyLabel.id,
    })
    // Get execute command params based on label set and given dirty label
    return this.getExecuteCommandParamsForLabelSet(labelSet, firstDirtyLabel)
  }

  async addLabelSetInsights(labelInsights: CachedLabelInsight[], additionalInsights?: IBuildPlanInsight[]) {
    const labelIdToInsightCodesMap = new Map<string, string[]>()
    const cachedInsights = [...labelInsights]
    const uniqueLabelSetIds = [...new Set(labelInsights.map((labelInsight) => labelInsight.labelSetId))]
    cachedInsights.forEach((cachedInsight: CachedLabelInsight) => {
      const labelInsightCodes = labelIdToInsightCodesMap.get(cachedInsight.itemId.labelId)
      // If there already is a record of a label id - add new insight code
      if (!labelInsightCodes) {
        labelIdToInsightCodesMap.set(cachedInsight.itemId.labelId, [...cachedInsight.content])
      } else {
        // If there are no label id record - create a new array with insight code
        labelIdToInsightCodesMap.set(cachedInsight.itemId.labelId, [
          ...new Set([...labelInsightCodes, ...cachedInsight.content]),
        ])
      }
    })
    // Process non-label resolved insights
    let insights = this.insights.map((insight: IBuildPlanInsight) => {
      // Skip non-label tool insights and label tool insights with no related items in them
      if (insight.tool !== ToolNames.LABEL || !insight.details || !insight.details.relatedItems) {
        return insight
      }

      insight.details.relatedItems = insight.details.relatedItems.filter((relatedItem: LabelInsightRelatedItem) => {
        // If insight about minimum count and if label set did not take part in execute operations - left related item
        if (insight.errorCode === +InsightErrorCodes.LabelToolMinimumCount) {
          return !uniqueLabelSetIds.includes(relatedItem.labelSetId)
        }

        const labelId = relatedItem.labelId
        const hasInsightCodes = labelIdToInsightCodesMap.has(labelId)
        // If map has no records about the label - it did not take part in execute operations
        if (!hasInsightCodes) {
          return true
        }

        const labelInsightCodes = labelIdToInsightCodesMap.get(labelId)
        // If map has records of label but does not contain the insight code -
        // the insight is resolved, delete the item
        return labelInsightCodes.includes(insight.errorCode.toString())
      })

      return insight
    })
    // Process newly created insights
    cachedInsights.forEach((cachedInsight: CachedLabelInsight) => {
      // Skip resolved insights because they were already taken care of
      if (!cachedInsight.content.length) {
        return
      }
      const labelSetId = cachedInsight.labelSetId
      const labelId = cachedInsight.itemId.labelId
      const cachedRelatedItem: LabelInsightRelatedItem = {
        labelSetId,
        labelId,
        buildPlanItemId: cachedInsight.itemId.bpItemId,
        componentId: cachedInsight.itemId.componentId,
        geometryId: cachedInsight.itemId.geometryId,
        autoLocation: cachedInsight.itemId.autoLocation,
        manualPlacementId: cachedInsight.itemId.manualPlacementId,
      }

      cachedInsight.content.forEach((errorCode: string) => {
        // Find the existing insight index by tool, code and label set id
        const existingInsightIndex = insights.findIndex((insight: IBuildPlanInsight) => {
          const isLabelInsight = insight.tool === ToolNames.LABEL
          const matchesErrorCode = insight.errorCode.toString() === errorCode
          const matchesLabelSetId = !!(
            insight.details &&
            insight.details.relatedItems &&
            insight.details.relatedItems.find(
              (relatedItem: LabelInsightRelatedItem) => relatedItem.labelSetId === labelSetId,
            )
          )
          return isLabelInsight && matchesErrorCode && matchesLabelSetId
        })

        // If insight does not exist - create a new insight record with given related items
        if (existingInsightIndex < 0) {
          insights.push({
            errorCode: +errorCode,
            itemId: this.getBuildPlan.id,
            accepted: false,
            severity: labelInsightWarningCodes.includes(+errorCode) ? InsightsSeverity.Warning : InsightsSeverity.Error,
            tool: ToolNames.LABEL,
            details: {
              relatedItems: [cachedRelatedItem],
            },
          })
          return
        }

        const existingRelatedItem = insights[existingInsightIndex].details.relatedItems.find(
          (relatedItem: LabelInsightRelatedItem) => relatedItem.labelId === labelId,
        )
        // If insight exists but there are not related items that matches - add it into an insight
        if (!existingRelatedItem) {
          insights[existingInsightIndex].details.relatedItems.push(cachedRelatedItem)
        }
      })
    })

    const additionalInsightsLabelSetIds = additionalInsights.flatMap((insight) =>
      insight.details.relatedItems.forEach((relatedItem) => relatedItem.labelSetId),
    )
    additionalInsights.forEach((additionalInsight: IBuildPlanInsight) => {
      if (additionalInsight.errorCode !== +InsightErrorCodes.LabelToolMinimumCount) {
        return
      }

      // Find the existing insight index by tool, code and label set id
      const existingInsightIndex = insights.findIndex((insight: IBuildPlanInsight) => {
        const isLabelInsight = insight.tool === ToolNames.LABEL
        const matchesErrorCode = insight.errorCode === +InsightErrorCodes.LabelToolMinimumCount
        const matchesLabelSetId = !!(
          insight.details &&
          insight.details.relatedItems &&
          insight.details.relatedItems.find((relatedItem: LabelInsightRelatedItem) =>
            additionalInsightsLabelSetIds.includes(relatedItem.labelSetId),
          )
        )
        return isLabelInsight && matchesErrorCode && matchesLabelSetId
      })

      // If insight does not exist - create a new insight record with given related items
      if (existingInsightIndex < 0) {
        insights.push(additionalInsight)
        return
      }

      const existingRelatedItem = insights[existingInsightIndex].details.relatedItems.find(
        (relatedItem: LabelInsightRelatedItem) => additionalInsightsLabelSetIds.includes(relatedItem.labelSetId),
      )
      // If insight exists but there are not related items that matches - add it into an insight
      if (!existingRelatedItem) {
        insights[existingInsightIndex].details.relatedItems.push(...additionalInsight.details.relatedItems)
      }
    })

    // Remove related items for labels that does not exist anymore (were remove or never created and got aborted)
    insights = insights.map((insight: IBuildPlanInsight) => {
      const hasRelatedItems = insight.details && insight.details.relatedItems && insight.details.relatedItems.length
      // Skip those label sets that does not have any related items
      if (hasRelatedItems) {
        if (insight.errorCode === +InsightErrorCodes.LabelToolMinimumCount) {
          insight.details.relatedItems = insight.details.relatedItems.filter((relatedItem: LabelInsightRelatedItem) => {
            const labelSet = this.getLabelSetById(relatedItem.labelSetId)
            return labelSet
          })
          return insight
        }

        // Filter out related items that does not relate to any label
        insight.details.relatedItems = insight.details.relatedItems.filter((relatedItem: LabelInsightRelatedItem) => {
          const labelSet = this.getLabelSetById(relatedItem.labelSetId)
          return (
            labelSet &&
            labelSet.labels.some((trackableLabel: TrackableLabel) => trackableLabel.id === relatedItem.labelId)
          )
        })
      }
      return insight
    })

    // Remove label insights that after all changes does not have related items in them. All items of such insights
    // are fully resolved
    insights = insights.filter((insight: IBuildPlanInsight) => {
      const isLabelInsight = insight.tool === ToolNames.LABEL
      const hasRelatedItems = insight.details && insight.details.relatedItems && insight.details.relatedItems.length
      return !isLabelInsight || (isLabelInsight && hasRelatedItems)
    })

    this.loadInsights(insights)
  }

  recreateTrackableLabels() {
    const bodies = [...this.activeLabelSet.selectedBodies, ...this.activeLabelSet.relatedBodies]
    if (this.activeLabelSet.settings.placementMethodAutomatic) {
      const automatedTrackableLabels = bodies.flatMap((body: LabeledBodyWIthTransformation) => {
        return this.activeLabelSet.settings.placementAutoLocations.map((placement: MarkingLocation) => {
          return createAutomatedTrackableLabel(body.id, placement, LabelDirtyState.Add)
        })
      })
      this.addTrackableLabels(automatedTrackableLabels)
    } else if (this.activeLabelSet.manualPlacements.length) {
      const manualTrackableLabels = bodies.flatMap((body: LabeledBodyWIthTransformation) => {
        const placements = this.activeLabelSet.manualPlacements.filter((placement: Placement) => {
          return (
            placement.buildPlanItemId === body.buildPlanItemId &&
            placement.componentId === body.componentId &&
            placement.geometryId === body.geometryId
          )
        })
        return placements.map((placement: Placement) => {
          return createManualTrackableLabel(placement.id, LabelDirtyState.Add)
        })
      })
      this.addTrackableLabels(manualTrackableLabels)
    }
  }

  get hasDataToSave() {
    return this.isLabelSetsChanged && this.validateAllLabelSets
  }

  private async getPartFiles() {
    // Get unique parts on a build plan
    const parts = this.getBuildPlan.buildPlanItems.map((bpItem) => bpItem.part.id)
    const uniqueParts = [...new Set(parts)]
    // Create a map of bodies in each part
    const partsBodies = new Map<string, string[]>()
    this.getBuildPlan.buildPlanItems.forEach((bpItem) => {
      if (partsBodies.has(bpItem.part.id)) return

      partsBodies.set(
        bpItem.part.id,
        bpItem.partProperties.map((prop) => prop.geometryId),
      )
    })
    // Load list of files for each part
    const partFileRequests = uniqueParts.map((partId) => partsService.getFilesByItemId(partId))
    const filesForParts = (await Promise.all(partFileRequests)).map((files, partIndex) =>
      files
        .filter(
          (file) =>
            (file.fileType === FileTypes.DRACO && file.tag === IFileTags.Slice) ||
            file.fileType === FileTypes.DRACO_CONFIG,
        )
        .map((file) => {
          if (file.fileType === FileTypes.DRACO_CONFIG) {
            return {
              s3filename: file.s3filename,
              name: file.name,
              bodyIds: partsBodies.get(uniqueParts[partIndex]),
            }
          }

          return {
            s3filename: file.s3filename,
            name: file.name,
          }
        }),
    )

    // Set a corresponding files list for each part
    // Initialize this.partFiles structure
    uniqueParts.forEach((partId, partIndex) => this.partFiles.set(partId, { partId, files: filesForParts[partIndex] }))
  }

  private onConnect() {
    const parameters = []
    this.partFiles.forEach((file) => parameters.push(file))
    this.setIsLabelToolOpened(false)
    const setupCommand: IInteractiveServiceCommand = {
      parameters,
      id: createGuid(),
      name: InteractiveServiceCommandNames.Setup,
    }

    this.interactiveCommunicationService.isSetupCommandRunning = true
    this.interactiveCommunicationService.sendCommand(setupCommand)
  }

  private async onConnectError(msg?: IInteractiveServiceMessage) {
    await this.restoreLabels()
    if (this.activeLabelSet && !this.activeLabelSet.settings.placementMethodAutomatic) {
      this.deactivateLabelManualPlacement()
    }

    this.interactiveCommunicationService.closeSocket()
    this.$emit('closeTool')

    if (msg) {
      messageService.showErrorMessage(msg.message)
    }
  }

  private setupSuccessHandler() {
    this.setIsLabelToolOpened(true)
    this.interactiveCommunicationService.isSetupCommandRunning = false
  }

  private setupFailureHandler(msg: IInteractiveServiceMessage) {
    this.interactiveCommunicationService.closeSocket()
    messageService.showErrorMessage(msg.message)
    this.$emit('closeTool')
  }

  private async executeFailureHandler(msg: LabelFailureMessage) {
    // Skip labels which is not present in label list or have different command id
    // This means that it is outdated response
    const label = this.getTrackableLabel(msg.content.trackId, msg.content.labelSetId)
    if (!label || label.commandId !== msg.id) {
      return
    }

    // Find label set id by trackable label id from message
    let labelSetId: string = null
    if (this.activeLabelSet) {
      // Check active label set first if it is available
      labelSetId = this.activeLabelSet.labels.some((trackableLabel: TrackableLabel) => {
        return trackableLabel.id === msg.content.trackId
      })
        ? this.activeLabelSet.id
        : null
    }

    if (!labelSetId) {
      labelSetId = this.labelSets.find((ls: InteractiveLabelSet) => {
        if (this.activeLabelSet && ls.id === this.activeLabelSet.id) {
          // Skip active label set because we have already checked it earlier
          return false
        }

        return ls.labels.some((trackableLabel: TrackableLabel) => {
          return trackableLabel.id === msg.content.trackId
        })
      }).id
    }

    const labelSet: InteractiveLabelSet = this.getLabelSetById(labelSetId)
    if (isManualLabel(label)) {
      const manualPlacement = this.getLabelSetById(labelSetId).manualPlacements.find(
        (mp) => mp.id === label.manualPlacementId,
      )
      this.markNotCreatedManualLabel({ labelId: label.manualPlacementId, trackId: label.id })
      this.cacheInsight({
        labelSetId,
        content: [InsightErrorCodes.LabelToolNotCreated.toString()],
        itemId: {
          bpItemId: manualPlacement.buildPlanItemId,
          componentId: manualPlacement.componentId,
          geometryId: manualPlacement.geometryId,
          labelId: label.id,
          manualPlacementId: (label as ManualTrackableLabel).manualPlacementId,
        },
      })
    } else {
      const body = [...labelSet.selectedBodies, ...labelSet.relatedBodies].find(
        (b) => b.id === (label as AutomatedTrackableLabel).bodyId,
      )
      this.cacheInsight({
        labelSetId,
        content: [],
        itemId: {
          bpItemId: body.buildPlanItemId,
          componentId: body.componentId,
          geometryId: body.geometryId,
          labelId: label.id,
          autoLocation: (label as AutomatedTrackableLabel).autoLocation,
        },
      })
    }

    if (label.dirtyState === LabelDirtyState.Update) {
      this.removeLabelsFromCanvas([{ trackId: label.id }])
      if (labelSet.settings.placementMethodAutomatic) {
        // If placement method is views or bars - patch should be found by using the combination of placement auto
        // location, geometry id, build plan id and component id
        const autoTrackableLabel: AutomatedTrackableLabel = label as AutomatedTrackableLabel
        const bodies = [...labelSet.relatedBodies, ...labelSet.selectedBodies]
        const patchToRemove: Patch = labelSet.patches.find((p: Patch) => {
          // Body is not in selecting already, so we need remove patches that match auto location property and
          // also patches that contain bodies that are not present in selected/related arrays
          const body: LabeledBodyWIthTransformation = bodies.find((b: LabeledBodyWIthTransformation) => {
            return (
              b.buildPlanItemId === p.buildPlanItemId &&
              b.geometryId === p.geometryId &&
              b.componentId === p.componentId
            )
          })

          const bodyByTrackableLabelBodyId: LabeledBodyWIthTransformation = bodies.find(
            (b) => b.id === autoTrackableLabel.bodyId,
          )

          return (
            (p.autoLocation === autoTrackableLabel.autoLocation &&
              body &&
              (autoTrackableLabel.bodyId === body.id || bodyByTrackableLabelBodyId)) ||
            !body
          )
        })
        if (patchToRemove) {
          this.removePatchById(patchToRemove.id)
        }
      }
    }

    this.setIsInDirtyState(false)
    this.resetLabelDirtyState({ labelSetId, trackId: msg.content.trackId })
    this.setLabelErrorCode({ labelSetId, trackId: msg.content.trackId, errorCode: ErrorCodes.CommonError })
    this.setCurrentCommand(null)
    this.resetForceRecalculateRelatedForIds()

    // If there is label with isDirty=true left in state we should start execute process
    if (this.isLabelSetsHasDirtyLabel) {
      this.execute()
    } else {
      this.setOkIsDisabled(false)

      const minPlacementCountInsights: IBuildPlanInsight[] = this.checkMinPlacementCount([
        ...new Set(this.getCachedInsights.map((ci: CachedLabelInsight) => ci.labelSetId)),
      ])
      await this.addLabelSetInsights(this.getCachedInsights, minPlacementCountInsights)

      this.clearCachedInsights()
      if (executeProcessComplete) {
        resolveExecuteProcessCompletePromise()
      }

      this.validateTool()
    }
  }

  private async executeSuccessHandler(msg: IInteractiveServiceMessage) {
    const response: InteractiveLabelGenerationResponse = msg as InteractiveLabelGenerationResponse
    // TODO: We have to have only content in the response messages
    // It is temporary solution for fixing handling of cases when we don't have files in the response e.g. remove
    const content = response.content || (response as any).item

    const trackableLabel = this.getTrackableLabel(content.trackId, content.labelSetId)
    const labelSet = this.getLabelSetById(content.labelSetId)
    const insights = content.insights
    // Skip labels which is not present in label list or have different command id
    // This means that it is outdated response
    if (!trackableLabel || trackableLabel.commandId !== response.id || this.getCurrentCommand.id !== msg.id) {
      return
    }

    this.cacheInsight({
      content: insights || [],
      labelSetId: content.labelSetId,
      itemId: {
        bpItemId: content.buildPlanItemId,
        componentId: content.componentId,
        geometryId: content.geometryId,
        labelId: trackableLabel.id,
        autoLocation: (trackableLabel as AutomatedTrackableLabel).autoLocation,
        manualPlacementId: (trackableLabel as ManualTrackableLabel).manualPlacementId,
      },
    })
    if (trackableLabel.dirtyState !== LabelDirtyState.Remove) {
      // Remove old label mesh from the scene
      if (trackableLabel.dirtyState === LabelDirtyState.Update) {
        this.removeLabelsFromCanvas([{ trackId: trackableLabel.id }])
        if (labelSet.settings.placementMethodAutomatic) {
          // If placement method is views or bars - patch should be found by using the combination of placement auto
          // location, geometry id, build plan id and component id
          const autoTrackableLabel: AutomatedTrackableLabel = trackableLabel as AutomatedTrackableLabel
          const bodies = [...labelSet.relatedBodies, ...labelSet.selectedBodies]
          const patchToRemove: Patch = labelSet.patches.find((p: Patch) => {
            // Body is not in selecting already, so we need remove patches that match auto location property and
            // also patches that contain bodies that are not present in selected/related arrays
            const body: LabeledBodyWIthTransformation = bodies.find((b: LabeledBodyWIthTransformation) => {
              return (
                b.buildPlanItemId === p.buildPlanItemId &&
                b.geometryId === p.geometryId &&
                b.componentId === p.componentId
              )
            })

            const bodyByTrackableLabelBodyId: LabeledBodyWIthTransformation = bodies.find(
              (b) => b.id === autoTrackableLabel.bodyId,
            )

            return (
              (p.autoLocation === autoTrackableLabel.autoLocation &&
                body &&
                (autoTrackableLabel.bodyId === body.id || bodyByTrackableLabelBodyId)) ||
              !body
            )
          })
          if (patchToRemove) {
            this.removePatchById(patchToRemove.id)
          }
        }
      }

      const labelInfo = {
        id: content.id,
        index: content.index,
        buildPlanItemId: content.buildPlanItemId,
        componentId: content.componentId,
        geometryId: content.geometryId,
        labelSetId: content.labelSetId,
        drc: content.drc,
        isFailed: !content.isValid,
      }

      const manualPlacements = this.manualPlacementsForLabelSet(content.labelSetId)
      this.addLabelOnScene({
        ...labelInfo,
        trackId: trackableLabel.id,
        orientation:
          manualPlacements &&
          manualPlacements.length &&
          manualPlacements.find((mp) => mp.id === content.id) &&
          manualPlacements.find((mp) => mp.id === content.id).orientation,
        rotationAngle:
          manualPlacements &&
          manualPlacements.length &&
          manualPlacements.find((mp) => mp.id === content.id) &&
          manualPlacements.find((mp) => mp.id === content.id).rotationAngle,
      })

      this.createLabelPromise()
      const contents = this.temporarySavedContent.get(msg.id)
      if (!contents) {
        this.temporarySavedContent.set(msg.id, [content])
      } else {
        this.temporarySavedContent.set(msg.id, [...this.temporarySavedContent.get(msg.id), content])
      }
    } else {
      // Remove label from scene
      this.removeLabelsFromCanvas([{ trackId: trackableLabel.id }])
      // Remove trackable label
      this.removeTrackableLabelsByLabelSetId({ labelSetId: labelSet.id, ids: [trackableLabel.id] })
      // On each success operation we should remove a patch of a trackable label if dirty state of a trackable label
      // before the operation was DirtyState.Remove
      if (labelSet.settings.placementMethodAutomatic) {
        // If placement method is views or bars - patch should be found by using the combination of placement auto
        // location, geometry id, build plan id and component id
        const autoTrackableLabel: AutomatedTrackableLabel = trackableLabel as AutomatedTrackableLabel
        const bodies = [...labelSet.relatedBodies, ...labelSet.selectedBodies]
        const patchToRemove: Patch = labelSet.patches.find((p: Patch) => {
          // Body is not in the selection already, so we need to remove patches that match auto location property and
          // also patches that contain bodies that are not present in selected/related arrays
          const body: LabeledBodyWIthTransformation = bodies.find((b: LabeledBodyWIthTransformation) => {
            return (
              b.buildPlanItemId === p.buildPlanItemId &&
              b.geometryId === p.geometryId &&
              b.componentId === p.componentId
            )
          })

          const bodyByTrackableLabelBodyId: LabeledBodyWIthTransformation = bodies.find(
            (b) => b.id === autoTrackableLabel.bodyId,
          )

          // Find trackable label for patch body, because patch can be not for label that is actually removing
          const labelOnBodyForPatch = body
            ? labelSet.labels.find(
              (l) =>
                (l as AutomatedTrackableLabel).bodyId === body.id &&
                (l as AutomatedTrackableLabel).autoLocation === p.autoLocation,
            )
            : null

          return (
            (p.autoLocation === autoTrackableLabel.autoLocation &&
              body &&
              (autoTrackableLabel.bodyId === body.id ||
                (!bodyByTrackableLabelBodyId &&
                  (!labelOnBodyForPatch ||
                    (labelOnBodyForPatch && labelOnBodyForPatch.dirtyState !== LabelDirtyState.None))))) ||
            !body
          )
        })
        if (patchToRemove) {
          this.removePatchById(patchToRemove.id)
        }
      } else {
        // If placement method is manual - patch should be found by manual placement id (which is patch id)
        const manualTrackableLabel: ManualTrackableLabel = trackableLabel as ManualTrackableLabel
        this.removePatchById(manualTrackableLabel.manualPlacementId)
      }
    }

    this.setIsLabelExecuteTriggered(false)
    this.setLastDeletedElementIDs([])
    this.setDynamicElementsIDsToRefresh([])
    this.setDetectedDiff(null)
    this.setIsInDirtyState(false)
    this.clearCachedPatches()
    this.resetLabelDirtyState({ labelSetId: content.labelSetId, trackId: content.trackId })
    this.setCurrentCommand(null)
    this.resetForceRecalculateRelatedForIds()
    if (trackableLabel.errorCode) {
      this.resetLabelErrorCode({ labelSetId: content.labelSetId, trackId: content.trackId })
    }

    if (this.activeLabelSet) {
      this.clearLabelSetLabelIndices(this.activeLabelSet.id)
    } else {
      this.clearLabelIndices()
    }

    // If there is label with isDirty=true left in state we should start execute process
    if (this.isLabelSetsHasDirtyLabel) {
      this.execute()
    } else {
      // in case if there is no items in execution queue we should do actions that are require the end of
      // requests process
      this.setOkIsDisabled(false)
      // We have to wait until last part was added to the scene before actual check
      if (this.getLabelAddedPromise) {
        await this.getLabelAddedPromise.promise
      }

      const minPlacementCountInsights: IBuildPlanInsight[] = this.checkMinPlacementCount([
        ...new Set(this.getCachedInsights.map((ci: CachedLabelInsight) => ci.labelSetId)),
      ])
      await this.addLabelSetInsights(this.getCachedInsights, minPlacementCountInsights)
      this.activeSettingChanged = false
      this.sequenceTarget.dispatchEvent(this.sequenceEvent)
      this.clearCachedInsights()
      if (executeProcessComplete) {
        resolveExecuteProcessCompletePromise()
      }

      this.validateTool()
    }
  }

  private saveSuccessHandler(msg: IInteractiveServiceMessage) {
    const savedFiles = msg.content as ISaveFileDto[]

    const { labelSetsToCreate, labelSetsToUpdate } = this.getLabelSetsToSave
    const changedLabelSets = [...labelSetsToCreate, ...labelSetsToUpdate]

    changedLabelSets.forEach((labelSet) => {
      const filesRelatedToLabelSet = savedFiles
        .filter((savedFile) => {
          const [, labelSetId] = this.extractDataFromPath(savedFile.localFilename)
          return labelSet.id === labelSetId
        })
        .map((file) => {
          file.localFilename = `./${file.localFilename.replace(/\\/g, '/').replace(/^(\W*)/g, '')}`
          return file
        })

      let emptyFiles = ''
      labelSet.patches.forEach((patch) => {
        const { labelFile, patchFile } = filesRelatedToLabelSet.reduce(
          (acc, file) => {
            if (file.localFilename === patch.labelS3FileName) {
              acc.labelFile = file
            }

            if (file.localFilename === patch.patchS3FileName) {
              acc.patchFile = file
            }

            return acc
          },
          { labelFile: null, patchFile: null },
        )

        if (labelFile || patchFile) {
          if (labelFile) {
            patch.labelFileKey = labelFile.fileKey
            patch.labelS3FileName = labelFile.s3filename

            if (!labelFile.fileKey || !labelFile.s3filename) {
              emptyFiles = `${emptyFiles}\npatchId: ${patch.id}, labelFile: ${JSON.stringify(labelFile)}}`
            }
          }

          if (patchFile) {
            patch.patchFileKey = patchFile.fileKey
            patch.patchS3FileName = patchFile.s3filename

            if (!patchFile.fileKey || !patchFile.s3filename) {
              emptyFiles = `${emptyFiles}\npatchId: ${patch.id}, patchFile: ${JSON.stringify(patchFile)}}`
            }
          }

          this.setLabelSetPatch({ patch, labelSetId: labelSet.id })
        }
      })

      if (emptyFiles.length) {
        console.warn(`${this.$t('labelTool.emptyFilePath').toString()}\n${emptyFiles}`)
      }
    })

    resolveIsSavedPromise()
    this.setSaveComplete(true)
    this.setCurrentCommand(null)
  }

  private saveFailureHandler(msg: IInteractiveServiceMessage) {
    rejectIsSavedPromise(msg.message)
  }

  private getJsonPatchesSuccessHandler(msg: IInteractiveServiceMessage) {
    const content = msg.content as LabelJsonPatchContent[]
    if (content) {
      this.patchMap.clear()
      content.forEach((jsonPatch) => {
        const { labelSetId, patch } = this.createPatchFromJsonPatchContent(jsonPatch)
        const patches = this.patchMap.get(labelSetId)
        if (patches) {
          patches.push(patch)
        } else {
          this.patchMap.set(labelSetId, [patch])
        }
      })

      let cachedPatches: Map<string, Patch[]> = new Map()
      this.patchMap.forEach((patches, labelSetId) => {
        this.addLabelSetPatches({ labelSetId, patches })
        const labelSet = this.getLabelSetById(labelSetId)
        if (labelSet) {
          cachedPatches.set(labelSetId, _.cloneDeep(labelSet.patches))
        }
      })

      // Add patches for failed trackable labels without real geometry of patch and label
      // to have an ability to pass data integrity check and restore failed trackable labels after reload
      const { labelSetsToCreate, labelSetsToUpdate } = this.getLabelSetsToSave
      const changedLabelSets = [...labelSetsToCreate, ...labelSetsToUpdate]
      const labelSetIds = changedLabelSets.map(ls => ls.id)
      if (!cachedPatches.size) {
        changedLabelSets.forEach(ls => cachedPatches.set(ls.id, _.cloneDeep(ls.patches)))
      }

      this.addMockPatchesForFailedLabels(labelSetIds.filter(labelSetId => this.getLabelSetById(labelSetId)))

      // Before save validation
      if (!this.validateLabelDataBeforeSave()) {
        // In case of data integrity issues we should stop save process
        messageService.showErrorMessage(this.$t('labelTool.dataIntegrityIssue').toString())
        this.setIsExitingFromTool(false)
        this.setIsSaving(false)

        // Remove mock patches for failed labels if validation is not passed
        cachedPatches.forEach((patches, labelSetId) => {
          this.setLabelSetPatches({ labelSetId, patches })
        })
        cachedPatches.clear()

        if (this.getLabelUpdateInProgress) {
          // When we are getting the fail case scenario while update of labels - we trigger the finish
          // process of it by imitating of the end of the save mechanism
          resolveIsSavedPromise()
        }
        this.setLabelUpdateInProgress(false)
        return
      }

      // Before saving check that all patches with isValid = true has files on interactive service
      let patchInfo = ''
      changedLabelSets.forEach(labelSet => {
        const patchesWithoutFiles = labelSet.patches.filter(patch => patch.isValid && (!patch.patchS3FileName || !patch.labelS3FileName))
        if (patchesWithoutFiles.length) {
          patchInfo = `${patchInfo}\nlabelSetId: ${labelSet.id}\npatches: ${patchesWithoutFiles.map(patch => JSON.stringify(patch))}`
        }
      })
      if (patchInfo.length) {
        console.warn(`${this.$t('labelTool.labelPatchesWithoutGeometryFiles').toString()}\n${patchInfo}`)
      }

      const cmd = this.generateSaveCommand()
      this.setSaveComplete(false)
      this.interactiveCommunicationService.sendCommand(cmd)
    } else {
      resolveIsSavedPromise()
    }
  }

  private getJsonPatchesFailureHandler() {
    // Get JSON patches failure handler
  }

  private clearLabelSetFilesDoneHandler() {
    // Clear label set files done handler
  }

  /** Encodes curly braces to pass them into a label core without errors. */
  private fixCurlyBraces(parameters: LabelExecuteCommandParameters) {
    const openBrace = /\{(?!([0-9]+}))/g
    const closeBrace = /(?<!(\{\d))}/g
    parameters.settings.textContent = parameters.settings.textContent.replace(openBrace, '{{').replace(closeBrace, '}}')
  }

  private clearLabelSetFilesFailureHandler() {
    // Clear label set files failure handler
  }

  private removeLabelFilesDoneHandler() {
    setTimeout(() => this.validateTool(), 0)
  }

  private removeLabelFilesFailureHandler() {
    setTimeout(() => this.validateTool(), 0)
  }

  private abortHandler(msg: IInteractiveServiceMessage) {
    const content = (msg as InteractiveLabelGenerationResponse).content
    const trackableLabel = this.getTrackableLabel(content.trackId, content.labelSetId)

    // Skip labels which is not present in label list or have different command id
    // This means that it is outdated response
    if (!trackableLabel || trackableLabel.commandId !== msg.id) {
      this.setCurrentCommand(null)
      if (this.isLabelSetsHasDirtyLabel) {
        this.execute()
      } else {
        // If there is nothing to generate we should revalidate tool
        this.setOkIsDisabled(false)
        this.validateTool()
      }

      return
    }

    if (trackableLabel && trackableLabel.dirtyState === LabelDirtyState.Remove) {
      this.removeTrackableLabels([trackableLabel.id])
    } else if (trackableLabel) {
      this.setTrackableLabelCommandId({
        labelSetId: content.labelSetId,
        trackableLabelId: content.trackId,
        commandId: null,
      })
    }

    this.setCurrentCommand(null)
    if (this.isLabelSetsHasDirtyLabel) {
      this.execute()
    }
  }

  private generateAbortCommand(): IInteractiveServiceCommand {
    return {
      id: createGuid(),
      name: InteractiveServiceCommandNames.Abort,
    }
  }

  private generateExecuteCommand() {
    const parameters: LabelExecuteCommandParameters = this.generateExecuteCommandParams()
    // In case if we were not able to build parameters after a certain change, we need to not launch the execute process
    // TODO: Remove this condition and find places where execute is called but should not be called and fix them.
    if (!parameters) {
      return null
    }

    const executeCommand: IInteractiveServiceCommand = {
      parameters,
      id: parameters.trackableLabel.commandId,
      name: InteractiveServiceCommandNames.Execute,
    }

    return executeCommand
  }

  private generateGetJsonPatchesCommand() {
    const getJsonPatchesCommand: IInteractiveServiceCommand = {
      id: createGuid(),
      name: InteractiveServiceCommandNames.GetJsonPatches,
    }

    return getJsonPatchesCommand
  }

  private generateSaveCommand() {
    const saveCommand: IInteractiveServiceCommand = {
      id: createGuid(),
      parameters: { itemId: this.getBuildPlan.id },
      name: InteractiveServiceCommandNames.Save,
    }

    return saveCommand
  }

  private generateClearLabelSetFilesCommand(isPartialClear: boolean = false) {
    // Until we create correct getter
    const listOfSettings: Array<Partial<{ labelSetId: string; bodies: Array<Partial<LabelExecuteCommandBody>> }>> = [
      { labelSetId: this.activeLabelSet.id },
    ]
    if (isPartialClear) {
      listOfSettings[0].bodies = this.getDetectedDiff.toRemove.map((bodyToRemove) => ({
        bpItemId: bodyToRemove.buildPlanItemId,
        componentId: bodyToRemove.componentId,
        geometryId: bodyToRemove.geometryId,
        partId: bodyToRemove.partId,
      }))
    }

    const parameters = { listOfSettings, isPartialClear }

    const cmd: IInteractiveServiceCommand = {
      parameters,
      id: createGuid(),
      name: InteractiveServiceCommandNames.ClearLabelSetFiles,
    }

    return cmd
  }

  private generateRemoveLabelFilesCommand(labelId: string) {
    const parameters = { labelId, labelSetId: this.activeLabelSet.id }
    const cmd: IInteractiveServiceCommand = {
      parameters,
      id: createGuid(),
      name: InteractiveServiceCommandNames.Remove,
    }

    return cmd
  }

  private createPatchFromJsonPatchContent(jsonPatch: LabelJsonPatchContent) {
    const { labelFileName, patchFileName, autoLocation, ...content } = jsonPatch

    const formattedLabelPath = `./${labelFileName.replace(/\\/g, '/').replace(/^(\W*)/g, '')}`
    const formattedPatchPath = `./${patchFileName.replace(/\\/g, '/').replace(/^(\W*)/g, '')}`

    const [, labelSetId, buildPlanItemId, componentId, geometryId, , labelId] =
      this.extractDataFromPath(formattedLabelPath)

    const patch: Patch = {
      ...content,
      autoLocation,
      buildPlanItemId,
      componentId,
      geometryId,
      id: labelId,
      labelFileKey: null,
      // on this stage store interactive dir path in this field until we get real
      labelS3FileName: formattedLabelPath,
      patchFileKey: null,
      // on this stage store interactive dir path in this field until we get real
      patchS3FileName: formattedPatchPath,
    }

    return { labelSetId, patch }
  }

  private extractDataFromPath(path: string) {
    const regExp = new RegExp(
      `${OUTPUT_FOLDER_NAME}/` +
      `(${GUID_REGEX_PATTERN})/` +
      `(${GUID_REGEX_PATTERN})/` +
      `(${GUID_REGEX_PATTERN})_` +
      `(${GUID_REGEX_PATTERN})_` +
      `(${LABEL_REGEX_PATTERN}|${PATCH_REGEX_PATTERN})_` +
      `(${GUID_REGEX_PATTERN})`,
      '',
    )

    return path.match(regExp)
  }

  /**
   * Starts label restoring process.
   * While the restore process is in progress,
   * all other async calls will only await until the progress will end.
   * @returns label restore promise
   */
  private async restoreLabels() {
    if (!this.isLabelRestoreInProgress) {
      this.setLabelRestoreInProgress(true)
      this.restoreLabelsPromise = new Promise<void>(async (resolve) => {
        try {
          if (this.labelSetsSnapshot && this.labelSetsSnapshot.length) {
            this.restoreLabelSetsFromSnapshot()
          } else if (!this.isInteractiveServiceStartingOrServesUpdate) {
            this.removeLabelsFromCanvas()
          }

          this.setLastDeletedElementIDs([])
          this.clearLabelAutomaticPlacements()
          this.clearLabelIndices()
          const idsToRemove = this.labelInsights.map((insight) => insight.id).filter((id) => id)
          // Insights without id are related to the unsaved labels and not saved and should be removed
          const unsavedInsightsToRemove = this.labelInsights.filter((insight) => !insight.id)
          this.removeInsightsByInsights(unsavedInsightsToRemove)

          if (idsToRemove.length) {
            await this.deleteInsightMultiple({ insightsIds: idsToRemove, stateOnly: false })
          }

          if (this.getCachedLabelInsights.length) {
            await this.createInsightMultiple({ insights: this.getCachedLabelInsights, stateOnly: false })
          }
          resolve()
        } finally {
          this.restoreLabelsPromise = null
          this.setLabelRestoreInProgress(false)
        }
      })
    }

    return this.restoreLabelsPromise
  }

  private async restoreLabelsIfIncorrectlyClosed() {
    if (!this.isToolCorrectlyClosed) {
      await this.restoreLabels()
    }
  }

  private abortLabelAfterLabelSetChange() {
    if (this.activeLabelSet) {
      this.clearExecuteTimeout()
    }

    // Skip next steps if current command is Abort
    if (!this.getCurrentCommand || this.getCurrentCommand.name === InteractiveServiceCommandNames.Abort) {
      return
    }

    const cmd = this.generateAbortCommand()
    this.interactiveCommunicationService.sendCommand(cmd)
  }

  private async disconnectHandler() {
    // Even if we have unsaved data we are not able to save them so don't show the confirmation dialog
    this.setActiveToolHasUnsavedData(false)
    this.setLabelUpdateInProgress(false)
    if (!this.interactiveCommunicationService.isDisconnectTriggeredByUser) {
      this.$emit('triggerSocketDisconnectModal')
      await this.closeLabelTool()
    }
  }

  /**
   * Validates label data integrity before save
   */
  private validateLabelDataBeforeSave() {
    return (
      this.validatePatchesAndLabelsLength() &&
      this.isEachLabelHasCorrespondingBody() &&
      this.isEachPatchHasCorrespondingBody()
    )
  }

  /**
   * Checks if the length of valid patches equals to the length of valid labels
   * and the length of invalid patches equals to the length of failed labels
   * @returns {boolean} - validation result
   */
  private validatePatchesAndLabelsLength(): boolean {
    return this.labelSets.every((labelSet) => {
      const validPatches = labelSet.patches.filter(patch => patch.labelS3FileName && patch.patchS3FileName)
      const validLabels = labelSet.labels.filter(label => !label.errorCode)
      const invalidPatchesLength = labelSet.patches.length - validPatches.length
      const invalidLabelsLength = labelSet.labels.length - validLabels.length

      return validPatches.length === validLabels.length && invalidPatchesLength === invalidLabelsLength
    })
  }

  /**
   * Checks if each label has a corresponding body
   * @returns  {boolean} - validation results
   */
  private isEachLabelHasCorrespondingBody(): boolean {
    return this.labelSets.every((labelSet) => {
      const bodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]
      // Automated placement should be checked by bodyId
      if (labelSet.settings.placementMethodAutomatic) {
        const automatedLabels = labelSet.labels as AutomatedTrackableLabel[]
        return automatedLabels.every((automatedLabel) => bodies.find((body) => body.id === automatedLabel.bodyId))
      }

      // Manual labels should be checked by manualPlacement id
      const manualLabels = labelSet.labels as ManualTrackableLabel[]
      return manualLabels.every((manualLabel) => {
        const placement = labelSet.manualPlacements.find(
          (manualPlacement) => manualLabel.manualPlacementId === manualPlacement.id,
        )

        return (
          placement &&
          bodies.find(
            (body) =>
              body.buildPlanItemId === placement.buildPlanItemId &&
              body.componentId === placement.componentId &&
              body.geometryId === placement.geometryId,
          )
        )
      })
    })
  }

  /**
   * Checks if each patch has a corresponding body
   * @returns  {boolean} - validation results
   */
  private isEachPatchHasCorrespondingBody(): boolean {
    return this.labelSets.every((labelSet) => {
      const bodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]

      return labelSet.patches.every((patch) =>
        bodies.find(
          (body) =>
            body.buildPlanItemId === patch.buildPlanItemId &&
            body.componentId === patch.componentId &&
            body.geometryId === patch.geometryId,
        ),
      )
    })
  }

  private addMockPatchesForFailedLabels(labelSetIds: string[]) {
    labelSetIds.forEach(labelSetId => {
      const patches = []
      const labelSet = this.getLabelSetById(labelSetId)
      const failedLabels = labelSet.labels.filter(label => !label.isDirty && label.errorCode)
      const bodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]
      failedLabels.forEach(failedLabel => {
        let placement: Placement
        let buildPlanItemId: string
        let componentId: string
        let geometryId: string
        if (labelSet.settings.placementMethodAutomatic) {
          const automated = failedLabel as AutomatedTrackableLabel
          const body = bodies.find((body) => body.id === automated.bodyId)
          buildPlanItemId = body.buildPlanItemId
          componentId = body.componentId
          geometryId = body.geometryId
        } else {
          const manual = failedLabel as ManualTrackableLabel
          placement = labelSet.manualPlacements.find((placement) => placement.id === manual.manualPlacementId)
          buildPlanItemId = placement.buildPlanItemId
          componentId = placement.componentId
          geometryId = placement.geometryId
        }

        const patch: Patch = {
          buildPlanItemId,
          componentId,
          geometryId,
          id: labelSet.settings.placementMethodAutomatic ? uuidv4() : (failedLabel as ManualTrackableLabel).manualPlacementId,
          labelFileKey: null,
          patchS3FileName: null,
          patchFileKey: null,
          orientation: labelSet.settings.placementMethodAutomatic ? null : placement.orientation,
          textContent: [],
          runTimeTextContent: "",
          settingsIDForJson: 0,
          autoLocation: labelSet.settings.placementMethodAutomatic ? (failedLabel as AutomatedTrackableLabel).autoLocation : MarkingLocation.NearestToPoint,
          shellID: 0,
          staticSurface: null,
          textPointIn3D: null,
          transformation: [],
          labelS3FileName: null,
          rotationAngle: labelSet.settings.placementMethodAutomatic ? 0 : placement.rotationAngle,
          isValid: false
        }

        patches.push(patch)
      })

      if (patches.length) {
        this.addLabelSetPatches({ labelSetId, patches })
      }
    })
  }
}
