import { get, post, del, put } from '@/api/http'
import { EndpointsUrls } from '@/configs/config'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { FileTypes, IJob } from '@/types/PartsLibrary/Job'
import { IFileDetails } from '@/types/Common/IFileDetails'
import { DocumentModel } from '@/visualization/models/DataModel'
import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { handleAPIError } from '@/api/common'
import * as msgpack from '@ygoe/msgpack'

const VIS_FILE_TAG = 'vis'

export default {
  async getAllPartsV1() {
    try {
      return await get<IPartDto[]>(`${EndpointsUrls.PartsV1}`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  // Temporal decision until ibc parts permissions will be enabled
  async getPartById(id: string): Promise<IPartDto> {
    try {
      return get<IPartDto>(`${EndpointsUrls.PartsV1}/${id}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getJobsByPartId(partId: string) {
    try {
      return await get<IJob[]>(`${EndpointsUrls.PartsV1}/${partId}/jobs`)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getFilesByItemId(itemId: string) {
    try {
      return await get<IFileDetails[]>(`${EndpointsUrls.Files}?itemId=${itemId}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getPartToken() {
    try {
      return await get(EndpointsUrls.PartToken)
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getPartConfigFile(id: string) {
    const s3BaseUrl = window.env.VUE_APP_S3_BASE_URL
    try {
      const files = await this.getFilesByItemId(id)
      const configMetadata = files.find((file: IFileDetails) => file.fileType === FileTypes.DRACO_CONFIG)
      const dracoConfigUrl = `${s3BaseUrl}${configMetadata.s3filename}`
      const response = Object.assign(
        new DocumentModel(),
        await (await fetch(dracoConfigUrl, { credentials: 'include' })).json(),
      )

      const drcFilesMetadata = files.filter(
        (file: IFileDetails) => file.fileType === FileTypes.DRACO && file.tag === VIS_FILE_TAG,
      )

      for (const part of response.parts) {
        for (const geometry of part.geometries) {
          const geometryFile = drcFilesMetadata.find((drc: IFileDetails) => drc.name === geometry.path)
          if (geometryFile) {
            geometry.path = `${s3BaseUrl}${geometryFile.s3filename}`
          }
        }
      }

      const bvhFileMetadata = files.find((file: IFileDetails) => file.fileType === FileTypes.BVH)
      if (bvhFileMetadata) {
        const bvhUrl = `${s3BaseUrl}${bvhFileMetadata.s3filename}`
        try {
          const bvhJson: object = await (await fetch(bvhUrl, { credentials: 'include' })).json()
          response.bvhJson = bvhJson
        } catch (e) {
          const bvhbson: object = msgpack.deserialize(
            await (await fetch(bvhUrl, { credentials: 'include' })).arrayBuffer(),
          )
          response.bvhJson = bvhbson
        }
      }

      const hullFilesMetadata = files.find((file: IFileDetails) => file.fileType === FileTypes.HULL)
      if (hullFilesMetadata) {
        const hullUrl = `${s3BaseUrl}${hullFilesMetadata.s3filename}`
        const hullJson: object = await (await fetch(hullUrl, { credentials: 'include' })).json()
        response.hullJson = hullJson
      }

      return response
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },

  async getBuildPlanItemsByPartId(id: string, queryString?: string) {
    try {
      let url = `${EndpointsUrls.PartsV1}/${id}/buildPlanItems`

      if (queryString) {
        url += `?${queryString}`
      }

      return await get<IBuildPlanItem[]>(url)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getFileById(id: string) {
    try {
      const file = await get<ArrayBuffer>(`${EndpointsUrls.Files}/${id}/content`, {
        responseType: 'arraybuffer',
      })

      return file
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getFileDataById(id: string) {
    try {
      const file = await get(`${EndpointsUrls.Files}/${id}/content`, {})
      return file
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getBvhFileById(id: string) {
    let file
    try {
      file = await get(`${EndpointsUrls.Files}/${id}/content`, {})
      if (typeof file !== 'object') {
        file = msgpack.deserialize(
          await get<ArrayBuffer>(`${EndpointsUrls.Files}/${id}/content`, {
            responseType: 'arraybuffer',
          }),
        )
      }
    } catch (error) {
      handleAPIError(error)
    }

    return file
  },

  async getBvhFileFromAws(awsFileKey: string) {
    let file

    try {
      file = msgpack.deserialize(await this.getBinaryFileFromAws(awsFileKey))
    } catch (error) {
      handleAPIError(error)
    }

    return file
  },

  async getBinaryFileFromAws(awsFileKey: string): Promise<ArrayBuffer> {
    const s3BaseUrl = window.env.VUE_APP_S3_BASE_URL
    try {
      const file = await (await fetch(`${s3BaseUrl}${awsFileKey}`, { credentials: 'include' })).arrayBuffer()
      return file
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getTextFileFromAws(awsFileKey: string): Promise<string> {
    const s3BaseUrl = window.env.VUE_APP_S3_BASE_URL
    try {
      const file = await (await fetch(`${s3BaseUrl}${awsFileKey}`, { credentials: 'include' })).text()
      return file
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getJsonFileFromAws(awsFileKey: string): Promise<JSON> {
    const s3BaseUrl = window.env.VUE_APP_S3_BASE_URL
    try {
      const file = await (await fetch(`${s3BaseUrl}${awsFileKey}`, { credentials: 'include' })).json()
      return file
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getAllSinterParts() {
    try {
      return await get<IPartDto[]>(`${EndpointsUrls.SinterParts}`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async getLabelDracoFiles(s3filenames: string[]) {
    const result = new Map<string, ArrayBuffer>()
    const s3BaseUrl = window.env.VUE_APP_S3_BASE_URL

    for (const s3filename of s3filenames) {
      if (!s3filename) {
        continue
      }

      try {
        const patchUrl = `${s3BaseUrl}${s3filename}`
        const data = await (await fetch(patchUrl, { credentials: 'include' })).arrayBuffer()
        result.set(s3filename, data)
      } catch (error) {
        handleAPIError(error)
        continue
      }
    }

    return result
  },

  async getLabelDracoFile(s3filename: string) {
    const s3BaseUrl = window.env.VUE_APP_S3_BASE_URL
    const patchUrl = `${s3BaseUrl}${s3filename}`
    return this.fetchBinaryFile(patchUrl)
  },

  async fetchBinaryFile(fileUrl) {
    const response = await fetch(fileUrl, { credentials: 'include' })
    return response.arrayBuffer()
  },

  async updatePart(id: string, hiddenBodies: string[] | null) {
    try {
      return await put<any>(`${EndpointsUrls.PartsV1}/${id}`, { hiddenBodies })
    } catch (error) {
      handleAPIError(error)
    }
  },

  async updatePartComponents(id: string, components: string[] | null) {
    try {
      return await put<IPartDto>(`${EndpointsUrls.PartsV1}/${id}`, { components })
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getAwsFileKeysMap(fileKeys: string[]) {
    try {
      return await post<any>(`${EndpointsUrls.Files}/awsFileKeysMap`, fileKeys)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getPartIdsWithUsedDate(ids: string[]) {
    try {
      return post<Array<{ partId: string; usedDate: Date }>>(`${EndpointsUrls.PartsV1}/usedDate`, ids)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getAllIbcParts() {
    try {
      return await get<IPartDto[]>(`${EndpointsUrls.IbcParts}`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },
}
