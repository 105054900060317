/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_draco_mesh_compression' // side-effect for DracoCompression
import { DracoCompression, IDracoCompressionConfiguration, VertexData } from '@babylonjs/core/Meshes'
import { Color3 } from '@babylonjs/core/Maths'
import { EndpointsUrls } from '@/configs/config'

export interface FaceAttribute {
  faces: Face[]
  verticesFaceId: number[]
  verticesColors: number[]
}

export interface FacePositionAttributes {
  faces: Face[]
  positions: number[]
}

export class Face {
  id: number
  name: string
  indices: number[] = []
  facetIndices: number[] = []
  color: Color3

  constructor(id: number, name: string, indices?: number[], facetIndex?: number, color?: Color3) {
    this.id = id
    this.name = name
    this.color = color
    this.indices = indices ? indices : []
    if (facetIndex !== undefined) {
      this.facetIndices.push(facetIndex)
    }
  }

  clone(updateColor: boolean = false): Face {
    const cloneFace = new Face(this.id, this.name)
    cloneFace.indices = [...this.indices]
    cloneFace.facetIndices = [...this.facetIndices]
    if (updateColor) {
      cloneFace.color = Color3.FromInts(
        Math.ceil(Math.random() * 255),
        Math.ceil(Math.random() * 255),
        Math.ceil(Math.random() * 255),
      )
    } else {
      cloneFace.color = this.color
    }

    return cloneFace
  }
}

export class DracoDecoder extends DracoCompression {
  private static dracoDecoder: DracoDecoder
  private clearDracoEncoderThrottle: NodeJS.Timeout
  private taskCounter: number = 0

  private constructor() {
    DracoCompression.Configuration = {
      decoder: {
        wasmUrl: new URL(EndpointsUrls.DracoDecoderWasmUrl, origin).toString(),
        wasmBinaryUrl: new URL(EndpointsUrls.DracoDecoderWasmBinaryUrl, origin).toString(),
        fallbackUrl: new URL(EndpointsUrls.DracoDecoderFallbackUrl, origin).toString(),
      },
    } as IDracoCompressionConfiguration

    super(0)
  }

  static get Default() {
    if (!DracoDecoder.dracoDecoder) {
      DracoDecoder.dracoDecoder = new DracoDecoder()
    }
    return DracoDecoder.dracoDecoder
  }

  async decodeMesh(data: ArrayBuffer | ArrayBufferView, attributes?: { [kind: string]: number }) {
    this.onAddTask()
    return this.decodeMeshAsync(data, attributes).finally(() => this.onFinishTask())
  }

  getFaceAttribute(data: ArrayBuffer | ArrayBufferView) {
    const dracoCompression = this as any
    let faceAttribute: FaceAttribute
    if (dracoCompression._decoderModulePromise) {
      this.onAddTask()
      return dracoCompression._decoderModulePromise.then((decoder) => {
        const dataView = data instanceof ArrayBuffer ? new Uint8Array(data) : data
        faceAttribute = extractFaces(decoder.module, dataView)
        return faceAttribute
      }).finally(() => dracoCompression.onFinishTask())
    }
  }

  getFaceAndPositionAttributes(data: ArrayBuffer | ArrayBufferView): FacePositionAttributes {
    const dracoCompression = this as any
    if (dracoCompression._decoderModulePromise) {
      dracoCompression.onAddTask()

      return dracoCompression._decoderModulePromise.then((decoder) => {
        const dataView = data instanceof ArrayBuffer ? new Uint8Array(data) : data
        return extractFacesAndPositions(decoder.module, dataView)
      }).finally(() => dracoCompression.onFinishTask())
    }
  }

  decodeMeshPreservingOrder(data: ArrayBuffer | ArrayBufferView) {
    const dracoCompression = this as any
    if (dracoCompression._decoderModulePromise) {
      dracoCompression.onAddTask()

      return dracoCompression._decoderModulePromise.then((compression) => {
        const dataView = data instanceof ArrayBuffer ? new Uint8Array(data) : data
        const decoderModule = compression.module
        const buffer = new decoderModule.DecoderBuffer()
        buffer.Init(dataView, dataView.byteLength)
        const decoder = new decoderModule.Decoder()
        const order = new decoderModule.DracoUInt32Array()
        const face = new decoderModule.DracoInt32Array()
        const points = new decoderModule.DracoFloat32Array()

        let geometry
        let status
        let vertices
        let indices

        try {
          const type = decoder.GetEncodedGeometryType(buffer)
          switch (type) {
            case decoderModule.TRIANGULAR_MESH:
              geometry = new decoderModule.Mesh()
              status = decoder.DecodeBufferToMesh(buffer, geometry)
              break
            case decoderModule.POINT_CLOUD:
            default:
              throw new Error(`Invalid geometry type ${type}`)
          }
          if (!status.ok() || !geometry.ptr) {
            throw new Error(status.error_msg())
          }

          const orderAttributeId = decoder.GetAttributeId(geometry, decoderModule.GENERIC)
          const orderAttribute = decoder.GetAttribute(geometry, orderAttributeId)
          decoder.GetAttributeIntForAllPoints(geometry, orderAttribute, order)
          const pointAttributeId = decoder.GetAttributeId(geometry, decoderModule.POSITION)
          const pointAttribute = decoder.GetAttribute(geometry, pointAttributeId)
          decoder.GetAttributeFloatForAllPoints(geometry, pointAttribute, points)

          const orderInfo = new Uint32Array(geometry.num_points())
          for (let i = 0; i < geometry.num_points(); i += 1) {
            orderInfo[i] = order.GetValue(i)
          }

          vertices = new Float32Array(geometry.num_points() * 3)
          for (let i = 0; i < geometry.num_points() * 3; i += 3) {
            vertices[3 * orderInfo[i / 3]] = points.GetValue(i)
            vertices[3 * orderInfo[i / 3] + 1] = points.GetValue(i + 1)
            vertices[3 * orderInfo[i / 3] + 2] = points.GetValue(i + 2)
          }

          indices = new Uint32Array(geometry.num_faces() * 3)
          for (let i = 0; i < geometry.num_faces(); i += 1) {
            decoder.GetFaceFromMesh(geometry, i, face)
            const index1 = face.GetValue(0)
            const index2 = face.GetValue(1)
            const index3 = face.GetValue(2)

            indices[3 * i] = orderInfo[index1]
            indices[3 * i + 1] = orderInfo[index2]
            indices[3 * i + 2] = orderInfo[index3]
          }
        } finally {
          if (geometry) {
            // free unmanaged memory
            decoderModule.destroy(geometry)
          }

          decoderModule.destroy(points)
          decoderModule.destroy(face)
          decoderModule.destroy(order)
          decoderModule.destroy(decoder)
          decoderModule.destroy(buffer)
        }

        const vertexData = new VertexData()
        vertexData.indices = indices
        vertexData.positions = vertices

        return vertexData
      }).finally(() => dracoCompression.onFinishTask())
    }
  }

  dispose() {
    super.dispose()
    DracoDecoder.dracoDecoder = undefined
  }

  private onAddTask() {
    clearTimeout(this.clearDracoEncoderThrottle)
    this.taskCounter += 1
  }

  private onFinishTask() {
    this.taskCounter -= 1
    if (!this.taskCounter) {
      this.scheduleDispose()
    }
  }

  private scheduleDispose() {
    clearTimeout(this.clearDracoEncoderThrottle)
    this.clearDracoEncoderThrottle = setTimeout(() => {
      this.dispose()
    }, 20000)
  }
}

function extractFaces(decoderModule, dataView) {
  // initial setup
  const faceNamesMetaName = 'name'
  const buffer = new decoderModule.DecoderBuffer()
  buffer.Init(dataView, dataView.byteLength)
  const decoder = new decoderModule.Decoder()
  const faceAttributeData = new decoderModule.DracoFloat32Array()
  const facetIndices = new decoderModule.DracoInt32Array()
  const metadataQ = new decoderModule.MetadataQuerier()
  const faces: Face[] = []
  const verticesFaceId = []
  const verticesColors = []
  let geometry
  let status

  try {
    const type = decoder.GetEncodedGeometryType(buffer)
    switch (type) {
      case decoderModule.TRIANGULAR_MESH:
        geometry = new decoderModule.Mesh()
        status = decoder.DecodeBufferToMesh(buffer, geometry)
        break
      case decoderModule.POINT_CLOUD:
        geometry = new decoderModule.PointCloud()
        status = decoder.DecodeBufferToPointCloud(buffer, geometry)
        break
      default:
        throw new Error(`Invalid geometry type ${type}`)
    }
    if (!status.ok() || !geometry.ptr) {
      throw new Error(status.error_msg())
    }

    // get face attribute ID
    const faceAttributeId = decoder.GetAttributeId(geometry, decoderModule.GENERIC)
    // get face attribute
    const faceAttribute = decoder.GetAttribute(geometry, faceAttributeId)
    // faceAttributeData array will store a face attribute id for every point of the mesh
    decoder.GetAttributeFloatForAllPoints(geometry, faceAttribute, faceAttributeData)

    // query the attributes metadata
    const faceAttributeMetadata = decoder.GetAttributeMetadata(geometry, faceAttributeId)

    const faceNamesCount = metadataQ.NumEntries(faceAttributeMetadata)
    const faceNames = new Array(faceNamesCount - 1)
    for (let n = 0; n < faceNamesCount; n += 1) {
      const faceName = metadataQ.GetEntryName(faceAttributeMetadata, n)
      if (faceName === faceNamesMetaName) {
        continue
      }

      const intEntry = metadataQ.GetIntEntry(faceAttributeMetadata, faceName)
      faceNames[intEntry] = faceName
    }

    for (let i = 0; i < geometry.num_faces(); i += 1) {
      // get facet indices
      decoder.GetFaceFromMesh(geometry, i, facetIndices)
      // get facet face attribute
      const facetAttrDataIndex = faceAttributeData.GetValue(facetIndices.GetValue(0))
      // get face name from attribute metadata
      const faceName = faceNames[facetAttrDataIndex]
      // get first facet index
      const facetIndex0 = facetIndices.GetValue(0)
      // get second facet index
      const facetIndex1 = facetIndices.GetValue(1)
      // get third facet index
      const facetIndex2 = facetIndices.GetValue(2)
      // create or update Face with facet index and its vertex indices
      let faceColor
      if (!faces[facetAttrDataIndex]) {
        faceColor = Color3.FromInts(
          Math.ceil(Math.random() * 255),
          Math.ceil(Math.random() * 255),
          Math.ceil(Math.random() * 255),
        )

        const indices = [facetIndex0, facetIndex1, facetIndex2]
        faces[facetAttrDataIndex] = new Face(facetAttrDataIndex, faceName, indices, i, faceColor)
      } else {
        faces[facetAttrDataIndex].indices.push(facetIndex0)
        faces[facetAttrDataIndex].indices.push(facetIndex1)
        faces[facetAttrDataIndex].indices.push(facetIndex2)
        faces[facetAttrDataIndex].facetIndices.push(i)
        faceColor = faces[facetAttrDataIndex].color
      }

      // set to which face the facet indices belongs
      verticesFaceId[facetIndex0] = facetAttrDataIndex
      verticesFaceId[facetIndex1] = facetAttrDataIndex
      verticesFaceId[facetIndex2] = facetAttrDataIndex

      // set facet vertices colors
      const startIndex0 = facetIndex0 * 3
      verticesColors[startIndex0] = faceColor.r
      verticesColors[startIndex0 + 1] = faceColor.g
      verticesColors[startIndex0 + 2] = faceColor.b
      const startIndex1 = facetIndex1 * 3
      verticesColors[startIndex1] = faceColor.r
      verticesColors[startIndex1 + 1] = faceColor.g
      verticesColors[startIndex1 + 2] = faceColor.b
      const startIndex2 = facetIndex2 * 3
      verticesColors[startIndex2] = faceColor.r
      verticesColors[startIndex2 + 1] = faceColor.g
      verticesColors[startIndex2 + 2] = faceColor.b
    }
  } finally {
    if (geometry) {
      // free unmanaged memory
      decoderModule.destroy(geometry)
    }

    // free unmanaged memory
    decoderModule.destroy(faceAttributeData)
    decoderModule.destroy(facetIndices)
    decoderModule.destroy(metadataQ)
    decoderModule.destroy(decoder)
    decoderModule.destroy(buffer)
  }

  return { faces, verticesFaceId, verticesColors }
}

function extractFacesAndPositions(decoderModule, dataView) {
  // initial setup
  const faceNamesMetaName = 'name'
  const buffer = new decoderModule.DecoderBuffer()
  buffer.Init(dataView, dataView.byteLength)
  const decoder = new decoderModule.Decoder()
  const faceAttributeData = new decoderModule.DracoFloat32Array()
  const facetIndices = new decoderModule.DracoInt32Array()
  const metadataQ = new decoderModule.MetadataQuerier()
  const points = new decoderModule.DracoFloat32Array()
  const faces: Face[] = []
  let geometry
  let status
  let positions

  try {
    const type = decoder.GetEncodedGeometryType(buffer)
    switch (type) {
      case decoderModule.TRIANGULAR_MESH:
        geometry = new decoderModule.Mesh()
        status = decoder.DecodeBufferToMesh(buffer, geometry)
        break
      case decoderModule.POINT_CLOUD:
        geometry = new decoderModule.PointCloud()
        status = decoder.DecodeBufferToPointCloud(buffer, geometry)
        break
      default:
        throw new Error(`Invalid geometry type ${type}`)
    }
    if (!status.ok() || !geometry.ptr) {
      throw new Error(status.error_msg())
    }

    // get face attribute ID
    const faceAttributeId = decoder.GetAttributeId(geometry, decoderModule.GENERIC)
    // get face attribute
    const faceAttribute = decoder.GetAttribute(geometry, faceAttributeId)
    // faceAttributeData array will store a face attribute id for every point of the mesh
    decoder.GetAttributeFloatForAllPoints(geometry, faceAttribute, faceAttributeData)

    // query the attributes metadata
    const faceAttributeMetadata = decoder.GetAttributeMetadata(geometry, faceAttributeId)

    const faceNamesCount = metadataQ.NumEntries(faceAttributeMetadata)
    const faceNames = new Array(faceNamesCount - 1)
    for (let n = 0; n < faceNamesCount; n += 1) {
      const faceName = metadataQ.GetEntryName(faceAttributeMetadata, n)
      if (faceName === faceNamesMetaName) {
        continue
      }

      const intEntry = metadataQ.GetIntEntry(faceAttributeMetadata, faceName)
      faceNames[intEntry] = faceName
    }

    for (let i = 0; i < geometry.num_faces(); i += 1) {
      // get facet indices
      decoder.GetFaceFromMesh(geometry, i, facetIndices)
      // get facet face attribute
      const facetAttrDataIndex = faceAttributeData.GetValue(facetIndices.GetValue(0))
      // get face name from attribute metadata
      const faceName = faceNames[facetAttrDataIndex]
      // get first facet index
      const facetIndex0 = facetIndices.GetValue(0)
      // get second facet index
      const facetIndex1 = facetIndices.GetValue(1)
      // get third facet index
      const facetIndex2 = facetIndices.GetValue(2)
      // create or update Face with facet index and its vertex indices
      let faceColor
      if (!faces[facetAttrDataIndex]) {
        faceColor = Color3.FromInts(
          Math.ceil(Math.random() * 255),
          Math.ceil(Math.random() * 255),
          Math.ceil(Math.random() * 255),
        )

        const indices = [facetIndex0, facetIndex1, facetIndex2]
        faces[facetAttrDataIndex] = new Face(facetAttrDataIndex, faceName, indices, i, faceColor)
      } else {
        faces[facetAttrDataIndex].indices.push(facetIndex0)
        faces[facetAttrDataIndex].indices.push(facetIndex1)
        faces[facetAttrDataIndex].indices.push(facetIndex2)
        faces[facetAttrDataIndex].facetIndices.push(i)
        faceColor = faces[facetAttrDataIndex].color
      }
    }

    const pointAttributeId = decoder.GetAttributeId(geometry, decoderModule.POSITION)
    const pointAttribute = decoder.GetAttribute(geometry, pointAttributeId)
    decoder.GetAttributeFloatForAllPoints(geometry, pointAttribute, points)

    positions = new Float32Array(geometry.num_points() * 3)
    for (let i = 0; i < geometry.num_points() * 3; i += 3) {
      positions[i] = points.GetValue(i)
      positions[i + 1] = points.GetValue(i + 1)
      positions[i + 2] = points.GetValue(i + 2)
    }
  } finally {
    if (geometry) {
      // free unmanaged memory
      decoderModule.destroy(geometry)
    }

    // free unmanaged memory
    decoderModule.destroy(points)
    decoderModule.destroy(faceAttributeData)
    decoderModule.destroy(facetIndices)
    decoderModule.destroy(metadataQ)
    decoderModule.destroy(decoder)
    decoderModule.destroy(buffer)
  }

  return { faces, positions }
}
