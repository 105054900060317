const CHECK_ON_ADBLOCK_TIMEOUT = 600

export async function isAdBlockDetected(): Promise<boolean> {
  const banner = document.createElement('div')

  banner.classList.add('ad-banner')
  document.body.appendChild(banner)

  const adElement = document.getElementsByClassName('ad-banner')[0]
  const adElementStyle = getComputedStyle(adElement, null)

  // delay for adBlock to disable adBanner
  await new Promise((resolve) => setTimeout(resolve, CHECK_ON_ADBLOCK_TIMEOUT))

  return adElementStyle.display === 'none'
}
