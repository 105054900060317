
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { isOfType } from '@/utils/common'
import { BULK_AREA_SLICE_SETTINGS_BAND_ID, HatchType } from '@/constants'
import NumberField from '@/components/controls/Common/NumberField.vue'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { BandModel, BulkAreaModel } from '@/types/BuildPlans/IBuildPlan'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component({
  components: { NumberField },
})
export default class SliceSettingsList extends Vue {
  @Prop({ required: true }) sliceSettingsRow: BulkAreaModel | BandModel
  @Prop({ required: false }) isActiveRow: boolean
  @Prop() allBands: BandModel[]
  @Prop({ required: false }) bulkArea: BulkAreaModel
  @Prop() removeBand: Function
  @Prop() makeBandActive: Function
  @Prop() disabled: boolean
  @Prop() changedNonFormEditor: Function

  @sitesStore.Getter getStrategyProcessParameterSolidLevel: number

  hatchType = HatchType
  solidLevelLabels = ['1', '2', '3']
  gridLevelLabels = ['0', '1', '2', '3']
  spacingLevelLabels = ['0', '1', '2', '3']
  bandThicknessFieldSettings = {
    step: 1,
    min: 1,
    max: 400,
    default: 47,
  }
  gridThicknessFieldSettings = {
    step: 1,
    min: 1,
    max: 400,
    default: 16,
  }
  gridSpacingFieldSettings = {
    step: 1,
    min: 1,
    max: 400,
    default: 16,
  }
  spacingLevelFieldSettings = {
    min: 0,
    max: 3,
    default: 0,
  }
  solidLevelFieldSettings = {
    min: 1,
    max: 3,
    default: 3,
  }
  gridLevelFieldSettings = {
    min: 0,
    max: 3,
    default: 3,
  }
  rotationIncFieldSettings = {
    step: 1,
    min: 0,
    max: 360,
    default: 67,
  }

  get isBulkArea() {
    return !isOfType(this.sliceSettingsRow, 'id')
  }

  get isSolidHatchType() {
    return this.sliceSettingsRow.hatchType === HatchType.Solid
  }

  get numberOfBands() {
    return this.allBands.length
  }

  get isFirstBand() {
    return !this.isBulkArea && (this.sliceSettingsRow as BandModel).id === 0
  }

  get isSecondRow() {
    return !this.isBulkArea && (this.sliceSettingsRow as BandModel).id === 1
  }

  get isDraggableIconVisible() {
    return (
      !this.disabled &&
      this.numberOfBands > 1 &&
      !this.isBulkArea &&
      ((!this.isFirstBand && (!this.isSecondRow || this.numberOfBands > 2)) ||
        this.allBands[1].hatchType === HatchType.Solid)
    )
  }

  get isRemoveVisible() {
    return (
      !this.isBulkArea &&
      (!this.isFirstBand ||
        (this.numberOfBands === 1 && this.bulkArea.hatchType === HatchType.Solid) ||
        (this.numberOfBands > 1 && this.allBands[1].hatchType === HatchType.Solid))
    )
  }

  get hasNoBands() {
    return !this.numberOfBands
  }

  get hatchTypeText() {
    return this.isSolidHatchType ? this.$i18n.t('sliceSettings.solid') : this.$i18n.t('sliceSettings.grid')
  }

  get bandThickness() {
    return this.isBulkArea ? undefined : (this.sliceSettingsRow as BandModel).bandThicknessInPixels
  }

  get solidGridLevelLabelText() {
    return this.isSolidHatchType ? this.$i18n.t('sliceSettings.solidLevel') : this.$i18n.t('sliceSettings.gridLevel')
  }

  bandClicked() {
    const bandId = this.isBulkArea ? BULK_AREA_SLICE_SETTINGS_BAND_ID : (this.sliceSettingsRow as BandModel).id
    this.$emit('makeBandActive', bandId)
  }

  removeCurrentBand(event) {
    event.stopPropagation()
    if (this.isBulkArea || this.disabled) {
      return
    }
    const id = (this.sliceSettingsRow as BandModel).id
    this.$emit('removeBand', id)
  }

  changeHatchType(hatchType: HatchType) {
    if (this.hasNoBands || this.disabled) {
      return
    }

    this.sliceSettingsRow.hatchType = hatchType
    this.provideDefaultsIfNeeded()
    this.nonFormEditorUpdated()
  }

  nonFormEditorUpdated() {
    this.$emit('changedNonFormEditor')
  }

  provideDefaultsIfNeeded() {
    this.sliceSettingsRow.gridRotationAngleInDegrees =
      this.sliceSettingsRow.gridRotationAngleInDegrees || this.rotationIncFieldSettings.default
    this.sliceSettingsRow.gridSpacingGrayScale =
      this.sliceSettingsRow.gridSpacingGrayScale || this.spacingLevelFieldSettings.default
    this.sliceSettingsRow.gridSpacingInPixels =
      this.sliceSettingsRow.gridSpacingInPixels || this.gridSpacingFieldSettings.default
    this.sliceSettingsRow.gridThicknessInPixels =
      this.sliceSettingsRow.gridThicknessInPixels || this.gridThicknessFieldSettings.default
    if (!this.isBulkArea) {
      const band = this.sliceSettingsRow as BandModel
      band.bandGrayScale =
        band.bandGrayScale || this.isSolidHatchType
          ? this.solidLevelFieldSettings.default
          : this.gridLevelFieldSettings.default
      band.bandThicknessInPixels = band.bandThicknessInPixels || this.bandThicknessFieldSettings.default
    }
  }

  get validationRules() {
    return {
      bandThicknessInPixels: {
        required: true,
        between: {
          min: this.bandThicknessFieldSettings.min,
          max: this.bandThicknessFieldSettings.max,
          unit: this.$i18n.t('pixelsAbbr'),
        },
      },
      gridThicknessInPixels: {
        required: true,
        between: {
          min: this.gridThicknessFieldSettings.min,
          max: this.gridThicknessFieldSettings.max,
          unit: this.$i18n.t('pixelsAbbr'),
        },
      },
      gridSpacingInPixels: {
        required: true,
        between: {
          min: this.gridSpacingFieldSettings.min,
          max: this.gridSpacingFieldSettings.max,
          unit: this.$i18n.t('pixelsAbbr'),
        },
      },
      gridRotationAngleInDegrees: {
        required: true,
        between: {
          min: this.rotationIncFieldSettings.min,
          max: this.rotationIncFieldSettings.max,
          unit: this.$i18n.t('degreeAbbr'),
        },
      },
    }
  }
}
