
import { Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import jobApi from '@/api/jobs'
import ItemTypeSelector from '@/components/controls/FileExplorer/ItemTypeSelector.vue'
import DetailsPanel from '@/components/layout/FileExplorer/DetailsPanel.vue'
import ShareItemModal from '@/components/layout/FileExplorer/ShareItemModal.vue'
import DashboardTableHeader from '@/components/layout/FileExplorer/Table/Dashboard/DashboardTableHeader.vue'
import JobCancelModal from '@/components/layout/FileExplorer/Table/Dashboard/JobCancelModal.vue'
import UserJobTableRow from '@/components/layout/FileExplorer/Table/Dashboard/UserJobTableRow.vue'
import AllFilesSearchViewMixin from '@/components/layout/FileExplorer/Table/mixins/AllFilesSearchViewMixin'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import { FilterParamsKey, ItemTypeFilterParams } from '@/types/FileExplorer/FilterParamsKey'
import { ItemDetailsDto, ItemDetailsType } from '@/types/FileExplorer/ItemDetails'
import { ItemType } from '@/types/FileExplorer/ItemType'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import { SortParams, SortParamsKey } from '@/types/FileExplorer/SortParamsKey'
import { DetailsPanelViewMode } from '@/types/FileExplorer/ViewMode'
import { JobDto, JobType } from '@/types/PartsLibrary/Job'
import { SortOrders } from '@/types/SortModes'
import { orderBy } from '@/utils/array'
import { isOfType, isTabVisible } from '@/utils/common'
import { jobTypeToNameForDashboard } from '@/utils/string'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import StoresNamespaces from '@/store/namespaces'

const partsStore = namespace(StoresNamespaces.Parts)

const SORT_BY_DEFAULT = 'createdDate'
const SORT_ORDER_DEFAULT = SortOrders.Ascending
const POLL_INTERVAL = 5 * 1000
const IMPORT_JOBTYPES: JobType[] = [JobType.IMPORT, JobType.PUBLISH]

@Component({
  components: {
    DashboardTableHeader,
    DetailsPanel,
    JobCancelModal,
    ItemTypeSelector,
    ShareItemModal,
    UserJobTableRow,
  },
})
export default class Dashboard extends AllFilesSearchViewMixin {
  @partsStore.Action actualizeParts: (items?: FileExplorerItem[]) => Promise<void>
  @partsStore.Action fetchAllParts: () => Promise<IPartDto[]>
  @partsStore.Action fetchAllSinterParts: () => Promise<IPartDto[]>
  @partsStore.Action fetchAllIbcParts: () => Promise<IPartDto[]>

  jobs: JobDto[] = []
  selectedJob: JobDto = null
  jobToCancel: JobDto = null
  listHasScroll = false
  isFetchingJobs = false
  isFetchingDetails = false
  isShowJobCancelModal = false
  jobFetchIntervalId: NodeJS.Timeout = null
  jobIdsInCancellingState: string[] = []
  jobFilterType = FilterParamsKey.JobTypes

  $refs!: {
    confirm: InstanceType<typeof ConfirmModal>
    listContent: HTMLElement
  }

  get scrollbarWidth(): number {
    if (!this.listHasScroll) {
      return 0
    }

    const div = document.createElement('div')

    div.style.width = '100px'
    div.style.height = '100px'
    div.style.overflow = 'scroll'
    div.style.position = 'absolute'
    div.style.top = '-9999px'

    document.body.appendChild(div)

    const scrollbarWidth = div.offsetWidth - div.clientWidth

    document.body.removeChild(div)

    return scrollbarWidth
  }

  get filteredJobs(): JobDto[] {
    const jobTypeFilterParams = this.filterParams[this.jobFilterType] ? this.filterParams[this.jobFilterType].value : []
    let filteredJobs: JobDto[] = []

    if (jobTypeFilterParams.length > 0) {
      filteredJobs = this.jobs.filter((job) => jobTypeFilterParams.includes(job.jobType))
    } else {
      filteredJobs = [...this.jobs]
    }

    return orderBy(filteredJobs, [this.currentSort.sortBy], [this.currentSort.sortOrder])
  }

  get jobTypes(): ItemTypeFilterParams[] {
    return [...new Set(this.jobs.map((job) => job.jobType))].map((jobType) => ({
      name: jobTypeToNameForDashboard(jobType),
      value: jobType,
      testClass: '',
    }))
  }

  get currentSort(): SortParams {
    const sort: SortParamsKey | object = this.sortParams[SortParamsKey.DashboardJobs] || {}
    const { sortBy = SORT_BY_DEFAULT, sortOrder = SORT_ORDER_DEFAULT } = sort as SortParams
    return { sortBy, sortOrder }
  }

  get selectedJobIsStillActive(): boolean {
    return this.selectedJob ? this.jobs.some((job) => job.id === this.selectedJob.id) : false
  }

  async beforeMount() {
    await Promise.all([
      this.fetchAllSinterParts(),
      this.fetchAllParts(),
      this.fetchAllIbcParts(),
      this.actualizeParts(),
    ])
  }

  async mounted() {
    await this.fetchJobs()
    this.checkIfThereIsScrollbar()
    this.jobFetchIntervalId = setInterval(this.pollJobs, POLL_INTERVAL)
  }

  updated() {
    this.checkIfThereIsScrollbar()
  }

  async fetchJobs() {
    try {
      this.isFetchingJobs = true
      const jobs = await jobApi.getRunnningJobsForCurrentUser()
      if (jobs) {
        this.jobs = jobs
      }
    } finally {
      this.isFetchingJobs = false
    }
  }

  checkIfThereIsScrollbar() {
    this.listHasScroll = this.$refs.listContent.offsetWidth > this.$refs.listContent.clientWidth
  }

  async pollJobs() {
    if (!isTabVisible()) {
      return
    }

    const jobs = await jobApi.getRunnningJobsForCurrentUser()
    if (jobs) {
      this.jobs = jobs.filter((job) => !this.jobIdsInCancellingState.includes(job.id))
    }

    if (!this.selectedJobIsStillActive) {
      this.selectedJob = null
      this.setJobItemDetails()
    }

    // in case if we have the cancel job modal open and the job to cancel is no longer running
    // we need to explicitly close the cancel job modal, so user can't try to cancel the job
    if (this.isShowJobCancelModal && this.jobToCancel) {
      const jobToCancelIndex = this.jobs.findIndex((job) => job.id === this.jobToCancel.id)
      if (jobToCancelIndex === -1) {
        this.toggleJobCancelModal(false)
      }
    }
  }

  checkSelectedJob() {
    if (this.selectedJob) {
      const isListHasJob: boolean = this.jobs.some((job) => job.id === this.selectedJob.id)
      if (!isListHasJob) {
        this.selectedJob = null
        this.setJobItemDetails()
      }
    }
  }

  toggleJobCancelModal(isShow: boolean) {
    this.isShowJobCancelModal = isShow

    if (!isShow) {
      this.jobToCancel = null
    }
  }

  async onUserJobTableRowClick(job: JobDto) {
    if (!this.selectedJob) {
      this.selectedJob = job
    } else if (this.selectedJob.id === job.id) {
      this.selectedJob = null
    } else {
      this.selectedJob = job
    }

    this.setJobItemDetails()
  }

  async fetchJobItemDetails(job: JobDto): Promise<ItemDetailsDto> {
    this.isFetchingDetails = true

    const itemId: string = job.itemId
    let itemType: ItemType
    if (job.jobType === JobType.ICOMPENSATE) {
      itemType = ItemType.IbcPlan
    } else {
      itemType = IMPORT_JOBTYPES.includes(job.jobType) ? ItemType.BuildPart : ItemType.BuildPlan
    }
    const details = await this.fetchItemDetails({ itemId, itemType })

    this.isFetchingDetails = false

    return details
  }

  onJobCancel(job: JobDto) {
    this.jobToCancel = job
    this.toggleJobCancelModal(true)
  }

  async onJobCancelSuccess() {
    this.jobIdsInCancellingState.push(this.jobToCancel.id)
    const jobIndex = this.jobs.findIndex((job) => job.id === this.jobToCancel.id)
    if (jobIndex !== -1) {
      this.jobs.splice(jobIndex, 1)
    }
    this.toggleJobCancelModal(false)
  }

  async setJobItemDetails() {
    if (!this.selectedJob) {
      this.setDetailsPanelMode(DetailsPanelViewMode.Default)
      return
    }

    const details: ItemDetailsDto = await this.fetchJobItemDetails(this.selectedJob)

    if (!this.isDetailsOfSelectedJob(details)) {
      this.setDetailsPanelMode(DetailsPanelViewMode.Default)
      return
    }

    if (isOfType<ItemDetailsType>(details, 'id')) {
      const item = {
        id: details.id,
        createdAt: details.createdAt,
        createdBy: details.createdBy,
      } as FileExplorerItem
      this.selectItem({ item, selectionType: SelectionTypes.Single })
    } else {
      this.selectItem({ item: details.item, selectionType: SelectionTypes.Single })
    }

    if (IMPORT_JOBTYPES.includes(this.selectedJob.jobType)) {
      this.setDetailsPanelMode(DetailsPanelViewMode.Part)
    } else {
      if (this.selectedJob.jobType === JobType.ICOMPENSATE) {
        this.setDetailsPanelMode(DetailsPanelViewMode.IbcPlan)
      } else {
        this.setDetailsPanelMode(DetailsPanelViewMode.BuildPlan)
      }
    }
  }

  isDetailsOfSelectedJob(details: ItemDetailsDto): boolean {
    if (!details || !this.selectedJob) {
      return false
    }

    let itemId: string

    if (isOfType<ItemDetailsType>(details, 'id')) {
      itemId = details.id
    } else {
      itemId = details.item.id
    }

    return itemId === this.selectedJob.itemId
  }

  beforeDestroy() {
    clearInterval(this.jobFetchIntervalId)
    this.setDetailsPanelMode(DetailsPanelViewMode.Default)
  }
}
