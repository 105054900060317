
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import VTextField from 'vuetify/lib/components/VTextField'

@Component
export default class SiteConstantUpdater extends Vue {
  @Prop() suffix: string
  @Prop({ default: false }) disabled: boolean
  @Prop() placeholder: string
  @Prop() label: string
  @Prop({ type: [Number, String, null] }) value: number | string | null
  @Prop() min!: number
  @Prop() max!: number
  @Prop() step!: number
  @Prop({ type: [Object, String], default: '' }) readonly rules: object | string
  @Prop({ type: [Object, String], default: '' }) readonly customMessages: object | string
  @Prop({ default: false }) readonly alignConstantUpdaterActions: boolean
  @Prop({ default: false }) hideDetails: boolean
  @Prop({ default: false }) validateImmediately: boolean
  @Prop({ default: false }) minimized: boolean
  @Prop({ default: undefined }) backgroundColor: string

  $refs!: {
    textField: VTextField
  }

  model: number | string | null =
    // @ts-ignore
    typeof this.value === 'number' ? Number(this.value) : !this.value ? '' : String(this.value)

  initialValue: number | string = this.model
  isUpdated: boolean = false

  input(e) {
    this.$emit('input', e)
  }

  onCloseEditMode() {
    this.$emit('input', this.initialValue)
    this.$emit('onCloseEditMode')
  }

  onUpdateSiteConstants() {
    this.isUpdated = true
    this.$emit('onUpdateSiteConstants', this.value)
  }

  beforeDestroy() {
    if (!this.isUpdated) {
      this.$emit('input', this.initialValue)
    }
  }
}
