
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import LabelSettings from '@/components/layout/buildPlans/marking/LabelSettings.vue'
import draggable from 'vuedraggable'
import { Setting } from '@/types/Label/Setting'
import { LabelDirtyState, LabelSetMode, MarkingContentElementType, MarkingLocation } from '@/types/Label/enums'
import { v4 as uuidv4 } from 'uuid'
import { Mixins, Prop, Watch } from 'vue-property-decorator'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { IBuildPlanItem, IDisplayToolbarState, SelectionUnit } from '@/types/BuildPlans/IBuildPlan'
import { LabelServiceMixin } from '@/components/layout/buildPlans/marking/mixins/LabelServiceMixin'
import InteractiveCommunicationService from '@/services/InteractiveCommunicationService'
import { IJob } from '@/types/PartsLibrary/Job'
import Button from '@/components/controls/Common/Button.vue'
import { ManualPatch } from '@/types/Label/Patch'
import {
  ActiveDynamicElementDialogInfo,
  BodyOrderMethod,
  CounterJSON,
  DynamicElementEvents,
  TextElement,
} from '@/types/Label/TextElement'
import {
  DEFAULT_ADDITIONAL_INTERACTIVE_LABEL_SET_DATA,
  DEFAULT_LABEL_SET_SETTING,
  DEFAULT_PARTIAL_LABEL_SET_SETTING_WITHOUT_COUPONS,
  MAX_CHORD_HEIGHT,
} from '@/constants'
import { Placement } from '@/types/Label/Placement'
import { TrackableLabel } from '@/types/Label/TrackableLabel'
import { LabeledBodyWIthTransformation } from '@/types/Label/LabeledBodyWIthTransformation'
import CounterSettings from '@/components/layout/buildPlans/marking/elementSettings/CounterSettings.vue'
import GridSettings from '@/components/layout/buildPlans/marking/elementSettings/GridSettings.vue'
import UserEntrySettings from '@/components/layout/buildPlans/marking/elementSettings/UserEntrySettings.vue'
import { eventBus } from '@/services/EventBus'
import { LabelListMixin } from '@/components/layout/buildPlans/marking/mixins/LabelListMixin'
import LabelSetListRow from '@/components/layout/buildPlans/marking/LabelSetListRow.vue'
import { ILabelOrientation } from '@/types/Marking/ILabel'

const labelStore = namespace(StoresNamespaces.Labels)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

interface IMixinInterface extends Vue, LabelServiceMixin, LabelListMixin {}

@Component({
  components: { LabelSetListRow, LabelSettings, draggable, Button },
})
export default class LabelsList extends Mixins<IMixinInterface>(Vue, LabelServiceMixin, LabelListMixin) {
  @Prop() interactiveCommunicationService: InteractiveCommunicationService

  @labelStore.Getter labelSets: InteractiveLabelSet[]
  @labelStore.Getter getActiveSetLastSuccessfulManualPlacements: ManualPatch[]
  @labelStore.Getter getLabelToolIsValid: boolean
  @labelStore.Getter getLabelSetById: (id: string) => InteractiveLabelSet
  @labelStore.Getter manualPlacementsForLabelSet: (id: string) => Placement[]
  @labelStore.Getter getAutomaticPlacements: (id: string) => ManualPatch[]
  @labelStore.Getter getLastUpdatedLabelSetId: string
  @labelStore.Getter getActiveDynamicElementDialogInfo: ActiveDynamicElementDialogInfo
  @labelStore.Getter isLabelSetHasLabelWithCommandId: (id: string) => boolean

  @buildPlansStore.Getter getCompleteVariantSlicingJobs: (id: string) => IJob[]
  @buildPlansStore.Getter isReadOnly: boolean
  @buildPlansStore.Getter getSelectedBuildPlanFinalizingJobs: IJob[]
  @buildPlansStore.Getter isBuildPlanContainsCouponBody: boolean
  @buildPlansStore.Getter getActiveSelectionMode: SelectionUnit
  @buildPlansStore.Getter displayToolbarStateByVariantId: (buildPlanId: string) => IDisplayToolbarState

  @labelStore.Action removeLabelSet: (id: number | string) => void
  @labelStore.Action activateReadOnly: () => void
  @labelStore.Action addLabelOnScene: (payload: {
    drc: ArrayBuffer
    buildPlanItemId: string
    componentId: string
    geometryId: string
    id: string
    labelSetId: string
    isFailed: boolean
    orientation: ILabelOrientation
    rotationAngle: number
    trackId: string
  }) => void

  @labelStore.Mutation addLabelSet: (labelSet: InteractiveLabelSet) => void
  @labelStore.Mutation clearActiveSetLastSuccessfulManualPlacements: () => void
  @labelStore.Mutation setLastDeletedElementIDs: (ids: string[]) => void
  @labelStore.Mutation setLabelSets: (labelSets: InteractiveLabelSet[]) => void
  @labelStore.Mutation setRelatedBodies: (payload: { bodies: LabeledBodyWIthTransformation[]; add?: boolean }) => void

  @labelStore.Action triggerAbort: () => void

  @visualizationStore.Mutation activateLabelManualPlacement: () => void
  @visualizationStore.Mutation activateLabelPlacement: () => void
  @visualizationStore.Mutation deactivateLabelPlacement: () => void
    @visualizationStore.Mutation setLabeledBodiesVisibility: (payload: {
    activeLabelSetId: string
    visibility: boolean
  }) => void

  labelList = []
  labelSetToDelete: string = null

  async mounted() {
    this.labelList = this.labelSets
    const hasLegacyLabels =
      this.getBuildPlan.buildPlanItems &&
      this.getBuildPlan.buildPlanItems.some((bpItem: IBuildPlanItem) => bpItem.labels && bpItem.labels.length)
    if (!this.labelList.length && !hasLegacyLabels && !this.isLabelReadOnly) {
      await this.createLabelSetDummy()
    }
  }

  @Watch('labelSets', { deep: true })
  async onLabelSetsChanged() {
    if (this.labelSets && !this.labelSets.length) {
      this.setLabelToolIsValid(true)
    } else {
      this.validateTool()
    }
  }

  @Watch('activeLabelSet')
  onActiveLabelSetChanged(newSet: InteractiveLabelSet, oldSet: InteractiveLabelSet) {
    if (oldSet && (!newSet || newSet.id !== oldSet.id)) {
      if (!oldSet.settings.placementMethodAutomatic) {
        this.deactivateLabelManualPlacement({ labelSetId: oldSet.id})
      }

      if (
        oldSet.settings.placementMethodAutomatic &&
        (oldSet.settings.placementAutoLocations.includes(MarkingLocation.FarBarEnd) ||
          oldSet.settings.placementAutoLocations.includes(MarkingLocation.NearBarEnd))
      ) {
        this.deactivateLabelBarPlacement()
      }
    }

    if (!newSet) {
      this.deactivateLabelPlacement()
    }

    if (!oldSet) {
      this.activateLabelPlacement()
    }

    if (this.isLabelReadOnly) {
      return
    }

    if (newSet && (!oldSet || newSet.id !== oldSet.id)) {
      if (!newSet.settings.placementMethodAutomatic) {
        this.activateLabelManualPlacement()
      }

      if (
        newSet.settings.placementMethodAutomatic &&
        (newSet.settings.placementAutoLocations.includes(MarkingLocation.FarBarEnd) ||
          newSet.settings.placementAutoLocations.includes(MarkingLocation.NearBarEnd))
      ) {
        this.activateLabelBarPlacement()
      }
    }
  }

  @Watch('isLabelReadOnly')
  onReadonlyStateChange(newValue: boolean, oldValue: boolean) {
    if (newValue === oldValue) return
    const isManual = this.activeLabelSet ? !this.activeLabelSet.settings.placementMethodAutomatic : false
    if (newValue) {
      this.activateReadOnly()
    } else if (!newValue && isManual) {
      this.activateLabelManualPlacement()
    }
  }

  async createLabelSetDummy() {
    const uuid = uuidv4()
    const dummyLabelSet = this.setNewLabelSetData(uuid)
    this.addLabelSet(dummyLabelSet)
    this.labelList = this.labelSets
    this.setActiveLabelSet(dummyLabelSet)
    this.setIsLabelSetOpened(true)

    // Have to deselect all selected bodies or parts
    if (this.getActiveSelectionMode === SelectionUnit.Part) {
      this.deselect()
    } else {
      // Body selection case should be silent in order to prevent removing of selected bodies
      this.deselect({ items: null, isSilent: true })
    }

    await this.setSelectionMode({ mode: SelectionUnit.Body, options: { shouldAffectSelectionBox: false } })
    this.setLabeledBodiesVisibility({
      activeLabelSetId: this.activeLabelSet ? this.activeLabelSet.id : null,
      visibility: this.displayToolbarStateByVariantId(this.getBuildPlan.id).isShowingAllLabledBodies,
    })
    this.$nextTick(() => {
      if (this.activeLabelSet) {
        this.setIsSilent(false)
      }
    })
  }

  setNewLabelSetData(id: string) {
    let newLabelSet: InteractiveLabelSet
    const lastLabelSet = this.getLabelSetById(this.getLastUpdatedLabelSetId)
    const additionalInteractiveLsData = JSON.parse(JSON.stringify(DEFAULT_ADDITIONAL_INTERACTIVE_LABEL_SET_DATA))

    if (!this.getLastUpdatedLabelSetId || !lastLabelSet) {
      // If there were no last updated label sets - we should create new label set based on a default values
      newLabelSet = {
        id,
        buildPlanId: this.getBuildPlan.id,
        settings: {
          index: this.labelList.length,
          typeRelatedText: 'Adding new labels...',
          ...JSON.parse(JSON.stringify(DEFAULT_LABEL_SET_SETTING)),
          textExtrusionBelowSurface: this.isDMLM
            ? this.defaultAttachmentDepthByParameterId
            : this.defaultTextExtrusionBelowSurface,
          textExtrusionAboveSurface: this.isDMLM
            ? DEFAULT_LABEL_SET_SETTING.textExtrusionAboveSurface
            : MAX_CHORD_HEIGHT * 2,
          uniqueID: 0,
        } as Setting,
        orderIndex: this.labelList.length,
        mode: LabelSetMode.Views,
        ...additionalInteractiveLsData,
      }

      if (!this.isBuildPlanContainsCouponBody) {
        // If there are no coupon bodies on a build plan we should set manual mode as a default one
        newLabelSet.settings = {
          ...newLabelSet.settings,
          ...JSON.parse(JSON.stringify(DEFAULT_PARTIAL_LABEL_SET_SETTING_WITHOUT_COUPONS)),
        }
        newLabelSet.mode = LabelSetMode.Manual
      }
    } else {
      // if there was a last updated label set we should create a new one based on last updated label set's settings
      newLabelSet = {
        id,
        buildPlanId: this.getBuildPlan.id,
        settings: {
          index: this.labelList.length,
          typeRelatedText: 'Adding new labels...',
          ...JSON.parse(JSON.stringify(lastLabelSet.settings)),
          uniqueID: 0,
        } as Setting,
        orderIndex: this.labelList.length,
        mode: lastLabelSet.mode,
        ...additionalInteractiveLsData,
      }
    }

    newLabelSet.settings.labelSetName = this.getLabelSetName(id, [newLabelSet])

    if (newLabelSet.mode === LabelSetMode.Manual && newLabelSet.settings.hasLabeledInstances) {
      // If a new label set was created based on a last updated label set, and it's mode is manual and it's labels were
      // set to all instances - we should uncheck "label all instances" setting, so we do not get all bodies selected
      // after the first placement
      newLabelSet.settings.hasLabeledInstances = false
    }

    return newLabelSet
  }

  emitExecute(settings?) {
    this.$emit('execute', settings)
  }

  confirmRemoveSet(labelSet: InteractiveLabelSet) {
    this.labelSetToDelete = labelSet.id
  }

  textContent(labelSet: InteractiveLabelSet) {
    const linePieces = labelSet.settings.textContent.split(/(?={[0-9]+})|(?<={[0-9]+})/g)
    return linePieces
      .map((piece: string) => {
        if (/{[0-9]+}/g.test(piece)) {
          const elementId = piece.replace('{', '').replace('}', '')
          const elementData = labelSet.settings.textElements[+elementId - 1]
          return `[${elementData.title}]`
        }
        return piece
      })
      .join('')
  }

  async removeLabelSetFromList(labelSetToDelete: string) {
    if (!labelSetToDelete) return
    const labelSet = this.labelSets.find((ls) => ls.id === labelSetToDelete)
    const textElementIds = labelSet.settings.textElements.map((textElement) => textElement.elementIDNumber)
    const shouldAbort = this.isLabelSetHasLabelWithCommandId(labelSetToDelete)
    if (shouldAbort) {
      this.triggerAbort()
    }

    this.removeLabelSet(labelSetToDelete)
    this.labelList = this.labelSets
    this.labelSetToDelete = null
    textElementIds.forEach((textElementId) => {
      this.removeDynamicElementIfNotUsed(textElementId)
    })

    await this.setSelectionMode({ mode: SelectionUnit.Part, options: { shouldAffectSelectionBox: false } })
  }

  onDragEnd(event) {
    const { newIndex } = event
    const updatedLabelSets = this.labelList.map((listItem, index) => {
      const labelSet = this.labelSets.find((ls) => ls.id === listItem.id)
      labelSet.orderIndex = index
      return labelSet
    })
    this.setLabelSets(updatedLabelSets)

    const draggedLabelSet = this.labelSets[newIndex]
    // Find ids of dynamic elements that should be updated on drag end
    // Sequential integer should be taken into an account only
    const refreshableElementsIds = draggedLabelSet.settings.textElements
      .filter((textElement: TextElement) => {
        const isCounter = textElement.type === MarkingContentElementType.Sequential_Integer
        // If dragged label set contains counter dynamic element and counter's ordering method is "in order selected" -
        // we should update such label set and all related to it label sets
        if (isCounter) {
          const counterSettings: CounterJSON = JSON.parse(textElement._cachedSpecificsJSON)
          return counterSettings.ordering.method === BodyOrderMethod.InOrderSelected
        }
        return false
      })
      .map((textElement: TextElement) => textElement.elementIDNumber)

    if (refreshableElementsIds.length) {
      this.setDynamicElementsIDsToRefresh(refreshableElementsIds)
    }

    // Find a list of label sets that should be update due to a drag end. Label sets are filtered by having
    // counters, which are present in dragged label set
    const labelSetsToUpdate: InteractiveLabelSet[] = this.labelSets.filter((ls: InteractiveLabelSet) => {
      return ls.settings.textElements.some((textElement: TextElement) => {
        return refreshableElementsIds.includes(textElement.elementIDNumber)
      })
    })
    // There are no point in further updates of label sets if there are no related label sets are found
    if (!labelSetsToUpdate.length) {
      return
    }

    const payload: Array<{ labelSetId: string; id: string; dirtyState: LabelDirtyState }> = []
    labelSetsToUpdate.forEach((ls: InteractiveLabelSet) => {
      ls.labels.forEach((tl: TrackableLabel) => {
        payload.push({ labelSetId: ls.id, id: tl.id, dirtyState: LabelDirtyState.Update })
      })
    })
    this.makeTrackableLabelsDirty(payload)
  }

  getConfirmationMessage(id: string) {
    const labelSet = this.getLabelSetById(id)
    if (labelSet) {
      return `${labelSet.settings.labelSetName}. ${this.textContent(labelSet)}`.trim()
    }

    return ''
  }

  /** Fires event on dynamic element dialog close. */
  onDialogClose() {
    eventBus.$emit(DynamicElementEvents.OnDialogClosed)
  }

  /** Fires event on a new dynamic element added and proceeds data from it further into a system. */
  onDynamicElementAdded(data: { name: string; id: string | number }) {
    eventBus.$emit(DynamicElementEvents.OnElementAdded, data)
  }

  /** Fires event on an existing dynamic element updated and proceeds data of it further into a system. */
  onDialogUpdated(data: TextElement) {
    eventBus.$emit(DynamicElementEvents.OnDialogUpdated, data)
  }

  get defaultTextExtrusionBelowSurface() {
    return JSON.parse(JSON.stringify(DEFAULT_LABEL_SET_SETTING)).textExtrusionBelowSurface
  }

  /** Decides what component should be loaded based on active dyalog type property. */
  get dynamicElementComponent() {
    switch (this.getActiveDynamicElementDialogInfo.activeDialogType) {
      case MarkingContentElementType.Sequential_Integer:
        return CounterSettings
      case MarkingContentElementType.Grid_Letter:
        return GridSettings
      case MarkingContentElementType.User_Entry:
        return UserEntrySettings
      default:
        return null
    }
  }
}
