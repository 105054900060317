
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Emit } from 'vue-property-decorator'
import { format } from 'date-fns'
import FallbackImage from '@/components/layout/FallbackImage.vue'
import { ICostEstimationListItemViewModel } from '../types/DataTable'
import Icon from '@/components/icons/Icon.vue'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'

const commonStore = namespace(StoresNamespaces.Common)

@Component({
  components: {
    FallbackImage,
    Icon,
  },
  filters: {
    formatDate(date: string) {
      return format(new Date(date), 'yyyy/MM/dd')
    },
  },
})
export default class CostEstimationListItem extends Vue {
  @commonStore.Getter tooltipOpenDelay: number

  @Prop() item: ICostEstimationListItemViewModel

  onSelect() {
    if (!this.item.result.length || this.item.isDisabled) {
      return
    }
    this.$emit('select', this.item.id)
  }

  @Emit('edit')
  onEdit() {
    return this.item.id
  }

  @Emit('copy')
  onCopy() {
    return this.item.id
  }

  @Emit('delete')
  onDelete() {
    return this.item
  }

  get partCost() {
    if (!this.item.result.length) {
      return null
    }

    const values = this.item.result.flatMap(({ partCost }) => [partCost.min, partCost.max]).filter((val) => val > 0)
    // small fix for NaN values
    const min = values.length ? Math.min(...values) : 0
    const max = values.length ? Math.max(...values) : 0

    const toCurrency = this.$options.filters.currency
    return {
      min: toCurrency(min),
      max: toCurrency(max),
      isEqual: min === max,
    }
  }

  get machines() {
    return this.item.result.map(({ machineName }) => machineName)
  }

  upperFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.substring(1)
  }
}
