
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import fileExplorer from '@/api/fileExplorer'
import SearchField from '@/components/controls/Common/SearchField.vue'
import PartListItem from '@/components/layout/buildPlans/addPart/PartListItem.vue'
import PartPropertyListItem from '@/components/layout/buildPlans/addPart/PartPropertyListItem.vue'
import PartTooltip from '@/components/layout/buildPlans/addPart/PartTooltip.vue'
import SinglePartPropertyItem from '@/components/layout/buildPlans/addPart/SinglePartPropertyItem.vue'
import { PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import ViewOptionsMenu from '@/components/layout/buildPlans/addPart/ViewOptionsMenu.vue'
import CommonBuildPlanToolsMixin from '@/components/layout/buildPlans/mixins/CommonBuildPlanToolsMixin'
import ReplaceLabeledPartModal from '@/components/layout/buildPlans/modals/ReplaceLabeledPartModal.vue'
import Splitter from '@/components/layout/Splitter.vue'
import { FILE_EXPLORER_PATH_DELIMITER, ITEM_VERSION_DELIMETER, SINGLE_PART_VISUALIZATION_NAMESPACE } from '@/constants'
import i18n from '@/plugins/i18n'
import { visualizationModule } from '@/store/modules/visualization'
import StoresNamespaces from '@/store/namespaces'
import {
  AddPartToolState,
  GeometryType,
  IBinderJetParameterSetContent,
  IBuildPlanItem,
  ILoadingPart,
  IPrintStrategyParameterSet,
  PartProperty,
  PartTypes,
  ProcessState,
} from '@/types/BuildPlans/IBuildPlan'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import { PrintingTypes } from '@/types/IMachineConfig'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { LabelSetDto } from '@/types/Label/LabelSetDto'
import { IPartRenderable } from '@/types/Parts/IPartRenderable'
import { IJob, JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { createGuid, isTabVisible } from '@/utils/common'
import { getDefaultBaseOnType } from '@/utils/parameterSet/parameterSetUtils'
import { Visualization } from '@/visualization'
import {
  AssemblyComponent,
  DocumentModel,
  Geometry,
  GeometryTypes,
  Part,
  PartComponent,
} from '@/visualization/models/DataModel'
import PartsSearchMixin from './mixins/PartsSearchMixin'

const partsStore = namespace(StoresNamespaces.Parts)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const singlePartVisualizationStore = namespace(StoresNamespaces.SinglePartVisualization)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const labelStore = namespace(StoresNamespaces.Labels)

@Component({
  components: {
    SearchField,
    ViewOptionsMenu,
    PartListItem,
    PartPropertyListItem,
    Splitter,
    SinglePartPropertyItem,
    PartTooltip,
    ReplaceLabeledPartModal,
  },
})
export default class BuildPlanReplaceTab
  extends Mixins(CommonBuildPlanToolsMixin, PartsSearchMixin)
  implements IToolComponent
{
  @buildPlansStore.Action replaceBuildPlanItems: (
    payload: Array<{
      partConfig: IPartRenderable
      targetBuildPlanItemId: string
    }>,
  ) => void

  @buildPlansStore.Mutation addToLoadingParts: Function
  @buildPlansStore.Mutation setAddPartToolState: (state: Partial<AddPartToolState>) => void
  @buildPlansStore.Mutation updatePartImportJobs: (jobs: IJob[]) => void
  @buildPlansStore.Mutation selectPart: (payload: {
    item: PartListItemViewModel
    selectionType: SelectionTypes
  }) => void
  @buildPlansStore.Mutation unselectPart: (payload: {
    item: PartListItemViewModel
    selectionType: SelectionTypes
  }) => void

  @singlePartVisualizationStore.Mutation calcGeometryPropsForSinglePart: Function

  @singlePartVisualizationStore.State visualization: Visualization

  @buildPlansStore.Getter getLoadingParts: ILoadingPart[]
  @buildPlansStore.Getter getSelectedBuildPlanFinalizingJobs: IJob[]
  @buildPlansStore.Getter getAllBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getSelectedBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getIsLoading: boolean
  @buildPlansStore.Getter parameterSets: IPrintStrategyParameterSet[]
  @buildPlansStore.Getter getPartImportJobDescriptionByItemId: (id: string) => string
  @buildPlansStore.Getter getBuildPlanPrintStrategy: BuildPlanPrintStrategyDto
  @buildPlansStore.Getter('getAddPartToolSelectedParts') incomingSelectedParts: PartListItemViewModel[]

  @buildPlansStore.State parentFolder: FileExplorerItem

  @partsStore.Action getPartConfigFile: Function
  @partsStore.Action updatePartComponents: (payload: { id: string; components: string[] | null }) => Promise<any>

  @partsStore.Mutation updatePart: (updatedPart: IPartDto) => void

  @partsStore.Getter getAllParts: IPartDto[]
  @partsStore.Getter getAllSinterParts: IPartDto[]
  @partsStore.Getter getAllIbcParts: IPartDto[]

  @labelStore.Action updateRelatedLabelsOnRemove: () => Promise<number>
  @labelStore.Action getLabelSetsByBuildPlanId: (payload: {
    buildPlanId: string
    dirtyStateAddIfNew?: boolean
  }) => Promise<LabelSetDto[]>

  @labelStore.Getter labelSets: InteractiveLabelSet[]

  @visualizationStore.Mutation selectAndHighlightParts: (payload: {
    buildPlanItemIds: string[]
    deselectIfSelected: boolean
    showGizmo: boolean
  }) => void
  @visualizationStore.Mutation deselect: () => void

  partStatusIntervalId: number
  isToolClosing: boolean = false

  hoveredItem = { item: null, top: 0 }
  configsAreLoading = true
  configs = {}
  replaceAllInstances = false
  replaceLabeledPartModalOpen = false
  replaceLabeledPartModalWasOpenedOnce = false

  /** Selected buildPlanItems when tool was activate. */
  initBuildPlanItems: IBuildPlanItem[] = []

  /** Instance of selected buildPlanItems when tool was activate. */
  instancesOfInitBuildPlanItems: IBuildPlanItem[] = []

  $refs!: {
    searchField: SearchField
  }

  /**************************************
   * Generic tool method implementations
   **************************************/
  // need to mention these generic optional methods even if they are not implemented by the tool
  // due to TypeScript's weak type detection per https://stackoverflow.com/a/47930521
  clickCancel() {
    // Select build plan items that ware selected before the tool was opened.
    this.replaceAllInstances = false
  }

  async clickOk() {
    if (!this.selectedPart || this.getIsLoading) {
      return
    }
    this.isToolClosing = true

    await this.replaceBuildPlanItemsOnScene()
    await this.$nextTick()
    this.$forceUpdate()
  }

  get selectedPart() {
    if (this.incomingSelectedParts.length > 1) {
      throw Error('Multi part select unavailable for replace tool.')
    } else if (this.incomingSelectedParts.length === 1) {
      return this.incomingSelectedParts[0]
    } else {
      return null
    }
  }

  get partsList(): PartListItemViewModel[] {
    let partDtos: IPartDto[] = []

    if (this.filter.displayFolderContentOnly) {
      partDtos = this.getBuildPartsFromParentFolder()
    } else {
      partDtos = this.getAllParts
    }

    const hasFinalizingJobs = !!this.getSelectedBuildPlanFinalizingJobs.length

    // Only parts without errors should be present in the list
    let allParts: PartListItemViewModel[] = partDtos
      .map((part) => {
        const disabled =
          hasFinalizingJobs ||
          part.status !== JobStatusCode.COMPLETE ||
          this.isPartLoading(part.id) ||
          this.isPartDisabledDueToErrors(part) ||
          this.isPartWithSheetBodiesDisabled(part)
        const disabledDescription = disabled ? this.getDisabledDescription(part) : null
        return {
          disabled,
          disabledDescription,
          ...part,
          partType: PartTypes.BuildPlanPart,
          previewImageUrl: part.previewImageUrl,
        }
      })
      .filter((part) => !part.disabled && part.subType === ItemSubType.None && !part.isRemoved)

    // DMLM - display all the parts, excluding parts that were published from sinter plan.
    // BinderJet - display all the parts, including parts that were published from sinter plan.
    if (this.printingType === PrintingTypes.BinderJet) {
      allParts = allParts.concat(this.sinterPartsList)
      allParts = allParts.concat(this.ibcPartsList)
    }

    const filteredList = this.filterAndSortPartsList(allParts, this.initBuildPlanItems)

    return filteredList
  }

  get sinterPartsList(): PartListItemViewModel[] {
    let partDtos: IPartDto[] = []

    if (this.filter.displayFolderContentOnly) {
      partDtos = this.getSinterPartsFromParentFolder()
    } else {
      partDtos = this.getAllSinterParts
    }

    const hasFinalizingJobs = !!this.getSelectedBuildPlanFinalizingJobs.length
    return partDtos
      .map((part) => {
        const disabled = hasFinalizingJobs || part.status !== JobStatusCode.COMPLETE || this.isPartLoading(part.id)
        const disabledDescription = disabled ? this.getDisabledDescription(part) : null
        return {
          disabled,
          disabledDescription,
          ...part,
          partType: PartTypes.SinterPart,
          previewImageUrl: part.previewImageUrl,
        }
      })
      .filter((part) => !part.disabled && part.visibility)
  }

  get ibcPartsList(): PartListItemViewModel[] {
    let partDtos: IPartDto[] = []

    if (!this.filter.displayFolderContentOnly) {
      partDtos = this.getAllIbcParts
    } else {
      partDtos = this.getIbcPartsFromParentFolder()
    }

    const hasFinalizingJobs = !!this.getSelectedBuildPlanFinalizingJobs.length
    return partDtos
      .map((part) => {
        const disabled = hasFinalizingJobs || part.status !== JobStatusCode.COMPLETE || this.isPartLoading(part.id)
        const disabledDescription = disabled ? this.getDisabledDescription(part) : null
        return {
          disabled,
          disabledDescription,
          ...part,
          partType: PartTypes.IbcPart,
          previewImageUrl: null,
        }
      })
      .filter((part) => !part.disabled && part.visibility)
  }

  get isSelectedPartsLoaded(): PartListItemViewModel {
    return this.selectedPart && !this.getIsLoading ? this.selectedPart : null
  }

  get contentFolderName(): string {
    if (typeof this.parentFolder === 'undefined') {
      return ''
    }
    return this.parentFolder ? this.parentFolder.name : this.$i18n.t('allFiles').toString()
  }

  get selectedOutgoingPartsHasInstance(): boolean {
    return this.instancesOfInitBuildPlanItems.length > 0
  }

  isPartWithSheetBodiesDisabled(part: IPartDto) {
    return part.hasSheetBodies && this.printingType === PrintingTypes.BinderJet
  }

  @Watch('isSelectedPartsLoaded')
  onSelectedPartLoaded() {
    // The following check is needed because this event can be triggered during component disposal
    // and we don't want to handle such event
    if (this.isToolClosing) {
      return
    }

    if (this.incomingSelectedParts && this.incomingSelectedParts.length && !this.getIsLoading) {
      this.calcGeometryPropsForSinglePart()
    }
  }

  beforeCreate() {
    // Single part needs its own canvas element, instances of the Vuex visualization module and Visualization class
    this.$store.registerModule(SINGLE_PART_VISUALIZATION_NAMESPACE, visualizationModule)
  }

  mounted() {
    this.setFocus()
    this.disableOkButton()
    this.triggerPartJobStatusCheck()
    this.getDefectedPartsConfigs()
    this.initializeBuildPlanItems()
    this.showReplaceLabeledPartModal()
  }

  setFocus() {
    setTimeout(() => {
      if (this.$refs.searchField) {
        this.$refs.searchField.focus()
      }
    }, 0)
  }

  @Watch('isSelectedPartsLoaded')
  disableOkButton() {
    this.$emit('setOkDisabled', !this.isSelectedPartsLoaded)
  }

  async getDefectedPartsConfigs() {
    this.configsAreLoading = true
    const partsWithIgnoredBodies = this.getAllParts.filter(
      (p: IPartDto) => p.hasErrors && p.hiddenBodies && p.hiddenBodies.length,
    )

    const configs = await Promise.all(partsWithIgnoredBodies.map((p: IPartDto) => this.getPartConfigFile(p.id)))
    partsWithIgnoredBodies.forEach((p: IPartDto, index) => {
      this.configs = { ...this.configs, ...{ [p.id]: configs[index] } }
    })
    this.configsAreLoading = false
  }

  async triggerPartJobStatusCheck() {
    const mapIdToPart = this.getAllParts
      .filter((part) => part.jobType === JobType.IMPORT && part.status !== JobStatusCode.COMPLETE)
      .reduce<Record<string, IPartDto>>((map, part) => {
        map[part.id] = part
        return map
      }, {})

    const itemIds: string[] = Object.keys(mapIdToPart)

    if (itemIds.length > 0) {
      await this.checkPartJobStatus(itemIds, mapIdToPart)

      if (this.partStatusIntervalId) {
        window.clearInterval(this.partStatusIntervalId)
      }

      this.partStatusIntervalId = window.setInterval(() => {
        this.checkPartJobStatus(itemIds, mapIdToPart)
      }, 5000)
    }
  }

  onPartClick(item: PartListItemViewModel) {
    if (item.disabled) {
      return
    }

    if (this.selectedPart && this.selectedPart.id === item.id) {
      this.unselectPart({ item, selectionType: SelectionTypes.Single })
    } else {
      this.selectPart({ item, selectionType: SelectionTypes.Single })
    }
  }

  async replaceBuildPlanItemsOnScene() {
    const outgoingItems: IBuildPlanItem[] = this.getSelectedBuildPlanItems

    const partId: string = this.selectedPart.id
    const partName: string = this.selectedPart.name
    const partType: PartTypes = this.selectedPart.partType
    const excludeGeometries: string[] = this.selectedPart.hiddenBodies

    const replaceConfigs: Array<{
      partConfig: IPartRenderable
      targetBuildPlanItemId: string
    }> = []
    const outgoingPartProperties: {
      [loadingPartIndex: number]: PartProperty[]
    } = {}
    const loadingPartLength = this.getLoadingParts.length
    for (let i = 0; i < outgoingItems.length; i += 1) {
      const bpItem = outgoingItems[i]

      const loadingPartIndex = loadingPartLength + i
      const partProperties = await this.createsIncomingPartProperties(this.selectedPart, bpItem)
      outgoingPartProperties[loadingPartIndex] = partProperties
      const partProps = partProperties[0]

      // If incoming part is legacy: Apply "Green".
      if (this.selectedPart.isPublishedAsScaled) {
        partProperties.forEach((property) => (property.processState = ProcessState.Green))
      }

      const parameterSetScaleFactor = this.getPartScaleFactor(partProperties, true)
      const hasSupportBodies = partProperties.some((p) => p.type === GeometryType.Support)

      const geometryTypes = new Map<string, GeometryType>()
      partProperties.forEach((property) => geometryTypes.set(property.geometryId, property.type))

      this.addToLoadingParts({ partId, name: partName, id: loadingPartIndex })

      const partConfig: IPartRenderable = {
        partId,
        partName,
        loadingPartIndex,
        partType,
        hasSupportBodies,
        excludeGeometries,
        parameterSetScaleFactor,
        geometryTypes,
        dragDrop: false,
        hiddenBodies: [],
        processState: partProps.processState,
      }
      replaceConfigs.push({
        partConfig,
        targetBuildPlanItemId: bpItem.id,
      })
    }

    if (this.hasSomeLabels(outgoingItems)) {
      const changed = await this.updateRelatedLabelsOnRemove()
      if (changed) {
        await this.getLabelSetsByBuildPlanId({ buildPlanId: this.getBuildPlan.id })
      }
    }

    this.setAddPartToolState({ outgoingPartProperties })
    this.replaceBuildPlanItems(replaceConfigs)
  }

  isPartSelected(part: PartListItemViewModel): boolean {
    return this.selectedPart && this.selectedPart.id === part.id
  }

  isPartLoading(partId: string): boolean {
    return this.getLoadingParts.some((part) => part.partId === partId && part.isSuccess === null)
  }

  isPartDisabledDueToErrors(part: IPartDto): boolean {
    let allBodiesAreIgnored = part.hasErrors
    if (!this.configsAreLoading && part.hasErrors && part.hiddenBodies && part.hiddenBodies.length) {
      const geometries =
        this.configs[part.id] &&
        this.configs[part.id].parts.reduce((arr: Geometry[], p: Part) => {
          arr.push(...p.geometries)
          return arr
        }, [])
      allBodiesAreIgnored = part.hiddenBodies.length === geometries.length
    }
    return (part.hasErrors && part.hiddenBodies === null) || allBodiesAreIgnored
  }

  getOkName(): string {
    return 'replace'
  }

  getDisabledDescription(part: IPartDto): string {
    if (this.isPartWithSheetBodiesDisabled(part)) {
      return i18n.t('disabledPartWithSheetBodiesTooltip') as string
    }

    const hasFinalizingJobs = !!this.getSelectedBuildPlanFinalizingJobs.length
    if (hasFinalizingJobs) {
      return i18n.t('finalizingTasksPropt') as string
    }

    const isImporting = part.status === JobStatusCode.RUNNING || part.status === JobStatusCode.QUEUED
    if (isImporting) {
      return i18n.t('importSnackbarMsg') as string
    }

    // Import errors
    const isImportError = part.status !== JobStatusCode.COMPLETE
    if (isImportError) {
      const msg = this.getPartImportJobDescriptionByItemId(part.id)
      return i18n.t('partImportError', { errorMessage: msg }) as string
    }

    if (part.hasErrors) {
      return i18n.t('partHasErrorsMsg') as string
    }

    return null
  }

  beforeDestroy() {
    clearInterval(this.partStatusIntervalId)
    this.$store.unregisterModule(SINGLE_PART_VISUALIZATION_NAMESPACE)
    this.setAddPartToolState({
      selectedParts: [],
      geometryProperties: null,
    })
  }

  partHovered(event: { top: number; item: PartListItemViewModel }) {
    this.hoveredItem = event
  }

  /** Selects and deselects instance of initialize items. */
  @Watch('replaceAllInstances')
  onReplaceAllInstances() {
    this.deselect()

    let buildPlanItemIds = this.initBuildPlanItems.map((item) => item.id)
    if (this.replaceAllInstances) {
      buildPlanItemIds = buildPlanItemIds.concat(this.instancesOfInitBuildPlanItems.map((item) => item.id))
    }

    this.selectAndHighlightParts({
      buildPlanItemIds,
      deselectIfSelected: false,
      showGizmo: false,
    })
    this.showReplaceLabeledPartModal()
  }

  closeReplaceLabeledPartModal() {
    this.replaceLabeledPartModalOpen = false
  }

  /** Shows ReplaceLabeledPartModal if selected items have labels and modal box has not been previously opened. */
  showReplaceLabeledPartModal() {
    if (this.replaceLabeledPartModalWasOpenedOnce || !this.hasSomeLabels(this.getSelectedBuildPlanItems)) {
      return
    }

    this.replaceLabeledPartModalOpen = true
    this.replaceLabeledPartModalWasOpenedOnce = true
  }

  /** Creates part properties for incoming build plan item based on incoming part and outgoing build plan item. */
  async createsIncomingPartProperties(incomingPart: PartListItemViewModel, outgoingBuildPlanItem: IBuildPlanItem) {
    const model = this.visualization.loadedDocuments.find((doc) => doc.partId === incomingPart.id).document
    const mapIdToPart = model.parts.reduce<Record<string, { geometries: Geometry[]; name: string }>>((map, part) => {
      map[part.id] = {
        geometries: incomingPart.hiddenBodies
          ? part.geometries.filter((g) => !incomingPart.hiddenBodies.includes(g.id))
          : part.geometries,
        name: part.name,
      }
      return map
    }, {})

    // Traverse the component tree and get all the part components
    const partComponents = this.getPartComponents(model)
    let id = 0
    const outgoingPartProperty = outgoingBuildPlanItem.partProperties[0]
    const partProperties: PartProperty[] = []
    for (const component of partComponents) {
      const part = mapIdToPart[component.partID]

      if (!part || !part.geometries.length) {
        continue
      }

      const geometries = part.geometries.filter((g) => {
        return !incomingPart.hiddenBodies || !incomingPart.hiddenBodies.includes(g.id)
      })
      geometries.forEach((geometry) => {
        id += 1

        const names: string[] = [component.name, part.name, geometry.name]
        const name: string = names
          .filter(Boolean)
          .map((item) => item.trim())
          .join('-')

        const processState = incomingPart.isPublishedAsScaled ? ProcessState.Green : outgoingPartProperty.processState
        // if part is sinter part - set correct body function
        const bodyFunction = incomingPart.bodyFunction ? incomingPart.bodyFunction : outgoingPartProperty.type
        const property: PartProperty = {
          id,
          processState,
          type: bodyFunction,
          printStrategyParameterSetId: outgoingPartProperty.printStrategyParameterSetId,
          printStrategyParameterSetVersion: outgoingPartProperty.printStrategyParameterSetVersion,
          geometryId: `${component.id}_${geometry.id}`,
          name: name || 'N/A',
          groupId: '',
          bodyType: geometry.type || GeometryTypes.Solid,
        }

        partProperties.push(property)
      })
    }

    const partComponentsValues = partProperties.map((p) => p.geometryId)
    if (partComponentsValues.length) {
      await this.updatePartComponents({ id: incomingPart.id, components: partComponentsValues })
    }

    this.setPartPropertiesGroupId(partProperties)

    return partProperties
  }

  getPartComponents(model: DocumentModel): PartComponent[] {
    const queue = [model.components as AssemblyComponent | PartComponent]
    const partComponents: PartComponent[] = []

    const isPartComponent = (arg: any): arg is PartComponent => arg.partID !== undefined

    while (queue.length) {
      const component = queue.shift()
      if (isPartComponent(component)) {
        partComponents.push(component)
      } else {
        if (component.children) {
          component.children.forEach((child) => queue.push(child as AssemblyComponent | PartComponent))
        }
      }
    }

    return partComponents
  }

  setPartPropertiesGroupId(partProperties: PartProperty[]): PartProperty[] {
    const mapKeyToGroupId = new Map<string, string>()

    partProperties.forEach((prop) => {
      const key = `${prop.type}_${prop.printStrategyParameterSetId}_${prop.bodyType}`
      let groupId: string = mapKeyToGroupId.get(key)

      if (!groupId) {
        groupId = createGuid()
        mapKeyToGroupId.set(key, groupId)
      }

      prop.groupId = groupId
    })

    return partProperties
  }

  hasSomeLabels(bpItems: IBuildPlanItem[]): boolean {
    return this.labelSets.some((labelSet) =>
      labelSet.patches.some((patch) =>
        bpItems.some((bpItem) => {
          return bpItem.id === patch.buildPlanItemId
        }),
      ),
    )
  }

  private async checkPartJobStatus(itemIds: string[], mapIdToPart: Record<string, IPartDto>) {
    if (!itemIds.length) {
      clearInterval(this.partStatusIntervalId)
      return
    }

    if (isTabVisible()) {
      const jobs = await fileExplorer.getGetRunningAndFailedJobsByItemIds(itemIds)
      const importJobs = jobs.filter((job) => job.jobType === JobType.IMPORT)
      this.updatePartImportJobs(importJobs)
      importJobs.forEach((job) => {
        if ([JobStatusCode.COMPLETE, JobStatusCode.ERROR].includes(job.code)) {
          const part = mapIdToPart[job.itemId]
          if (part) {
            this.updatePart({ ...part, status: job.code })
            const index = itemIds.indexOf(job.itemId)
            if (index !== -1) {
              itemIds.splice(index, 1)
            }
          }
        }
      })
    }
  }

  private getBuildPartsFromParentFolder(): IPartDto[] {
    const folderId: string = this.parentFolder ? this.parentFolder.id : null
    return this.getAllParts.filter((part) => part.parentId === folderId)
  }

  private getSinterPartsFromParentFolder(): IPartDto[] {
    const folderId: string = this.parentFolder ? this.parentFolder.id : null

    return this.getAllSinterParts.filter((part) => {
      const ancestorIds = part.path.split(FILE_EXPLORER_PATH_DELIMITER).slice(1, -1)
      const depth = part.sinterPlanVersion.split(ITEM_VERSION_DELIMETER).length - 1
      const rootSinterPlanIdIndex = ancestorIds.length - depth - 1
      const rootSinterPlanParentId = ancestorIds[rootSinterPlanIdIndex - 1] || null

      return rootSinterPlanParentId === folderId
    })
  }

  private getPartScaleFactor(partPropertyList: PartProperty[], ignoreProcessState = false): number[] {
    if (!partPropertyList) {
      return null
    }

    const partProps = partPropertyList[0]
    let parameterSetScaleFactor: number[] = [1, 1, 1]
    if (
      !this.isSinterPlan &&
      this.printingType === PrintingTypes.BinderJet &&
      (ignoreProcessState || partProps.processState === ProcessState.Nominal)
    ) {
      let printStrategyParameterSetPk: VersionablePk
      if (partProps.printStrategyParameterSetId) {
        printStrategyParameterSetPk = new VersionablePk(
          partProps.printStrategyParameterSetId,
          partProps.printStrategyParameterSetVersion,
        )
      } else {
        const defaults = this.getBuildPlanPrintStrategy.defaults
        printStrategyParameterSetPk = getDefaultBaseOnType(defaults, partProps.type, partProps.bodyType)
      }
      const bjPartParameters = this.parameterSets.find((p) => {
        return p.id === printStrategyParameterSetPk.id && p.version === printStrategyParameterSetPk.version
      }).parameterSet.partParameters as IBinderJetParameterSetContent

      parameterSetScaleFactor = [
        bjPartParameters.ScaleFactors.ScaleFactorX,
        bjPartParameters.ScaleFactors.ScaleFactorY,
        bjPartParameters.ScaleFactors.ScaleFactorZ,
      ]
    }
    return parameterSetScaleFactor
  }

  private getIbcPartsFromParentFolder(): IPartDto[] {
    const folderId: string = this.parentFolder ? this.parentFolder.id : null

    return this.getAllIbcParts.filter((part) => {
      const ancestorIds = part.path.split(FILE_EXPLORER_PATH_DELIMITER).slice(1, -1)
      const depth = part.ibcPlanVersion.split(ITEM_VERSION_DELIMETER).length - 1
      const rootIbcPlanIdIndex = ancestorIds.length - depth - 1
      const rootIbcPlanParentId = ancestorIds[rootIbcPlanIdIndex - 1] || null

      return rootIbcPlanParentId === folderId
    })
  }

  /** Saves selected build plan items and their instances. */
  private initializeBuildPlanItems() {
    this.initBuildPlanItems = this.getSelectedBuildPlanItems

    const groupedByPartId: Map<string, IBuildPlanItem[]> = new Map()
    this.initBuildPlanItems.forEach((buildPlanItem) => {
      const partId = buildPlanItem.part.id
      const group = groupedByPartId.get(partId)
      if (group) {
        group.push(buildPlanItem)
      } else {
        groupedByPartId.set(partId, [buildPlanItem])
      }
    })

    this.instancesOfInitBuildPlanItems = this.getAllBuildPlanItems.filter((buildPlanItem) => {
      const selectedInstances = groupedByPartId.get(buildPlanItem.part.id)
      return selectedInstances && !selectedInstances.find((item) => item.id === buildPlanItem.id)
    })
  }
}
