import { SortOrders } from '../SortModes'

export enum SortParamsKey {
  AllFiles = 'allFiles',
  Favorites = 'favorites',
  Recent = 'recent',
  Shared = 'shared',
  Trash = 'trash',
  PrintOrders = 'printOrders',
  DashboardJobs = 'dashboardJobs',
  Search = 'search',
  IBCPlans = 'ibcPlans',
}

export type SortParams = {
  sortBy: string
  sortOrder: SortOrders
}

export type TableSortParams = {
  [key in SortParamsKey]: SortParams
}
