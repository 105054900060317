
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'

const partsStore = namespace(StoresNamespaces.Parts)

@Component({
  components: {},
})
export default class DefectWrapper extends Vue {
  @partsStore.Getter activeInsightDefects: number[]
  @partsStore.Mutation addActiveInsightDefect: (cId: number) => void
  @partsStore.Mutation removeActiveInsightDefect: (cId: number) => void

  get cId() {
    return (this as any)._uid
  }

  activate() {
    const isActivated = this.activeInsightDefects.find((d) => d === this.cId)
    if (isActivated) {
      this.removeActiveInsightDefect(this.cId)
    } else {
      this.addActiveInsightDefect(this.cId)
    }
  }
}
