
import '@/assets/styles/home.scss'
import Component from 'vue-class-component'
import { Currency, EditViewMode, Measurement } from '@/types/Sites/Site'
import SiteConstantUpdater from '@/components/layout/admin/sites/SiteConstantUpdater.vue'
import { Prop } from 'vue-property-decorator'
import { SiteAdminMixin } from '@/components/layout/admin/sites/mixins/SiteAdminMixin'
import { RouterNames } from '@/router'
import { UpdateAdminMaterialDTO } from '@/types/Sites/SiteAdminDTOs'

@Component({
  components: {
    SiteConstantUpdater,
  },
})
export default class MaterialListRow extends SiteAdminMixin {
  @Prop({ default: null }) readonly item

  currencyUnits = Currency
  measurementUnits = Measurement

  onCloseEditMode() {
    this.editMode = EditViewMode.None
  }

  onUpdateSiteConstants(dto: UpdateAdminMaterialDTO) {
    this.editMode = EditViewMode.None
    this.$emit('update', dto)
  }

  navigateToMaterial(materialId: string) {
    const siteId = this.$route.params.siteId
    const machineConfigId = this.$route.params.machineConfigId
    const route = {
      name: RouterNames.EditMaterialAdmin,
      params: {
        siteId,
        machineConfigId,
        materialId,
      },
    }
    // @ts-ignore
    this.$router.safePush(route)
  }
}
