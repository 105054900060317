/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { CustomGizmo } from './CustomGizmo'
import { RenderScene } from '../render-scene'
import { IActiveToggle } from '../infrastructure/IActiveToggle'
import { AbstractMesh, CSG, InstancedMesh, Mesh, MeshBuilder, TransformNode } from '@babylonjs/core/Meshes'
import { Matrix, Axis, Color3, Epsilon, Vector3, Vector2 } from '@babylonjs/core/Maths'
import { LabelManager } from './LabelManager'
import { StandardMaterial } from '@babylonjs/core/Materials'
import { ILabelMetadata, SceneItemType } from '../types/SceneItemMetadata'
import {
  LABEL,
  LABEL_HANDLE_BORDER,
  LABEL_INSIDE_MESH_NAME,
  MouseButtons,
  PLANAR_GIZMO_NAME,
  ROTATION_GIZMO_ARROW_NAME,
} from '@/constants'
import { OuterEvents } from '../types/Common'
import { PointerEventTypes, PointerInfoPre } from '@babylonjs/core/Events'
import { Observer } from '@babylonjs/core/Misc/observable'

export class LabelGizmo extends CustomGizmo {
  protected movementOpacity = 0

  private labelManager: LabelManager
  private highlightLabelPlaneMaterial: StandardMaterial
  private regularLabelMaterial: StandardMaterial
  private cacheRotationArrow: Mesh = null
  private labelBBoxExtendSize: Vector3
  private rotateLabelObserver
  private pointerDown: Observer<PointerInfoPre>

  constructor(renderScene: RenderScene, labelManager: LabelManager, dragListeners?: IActiveToggle[]) {
    super(renderScene, dragListeners)
    this.labelManager = labelManager

    this.regularLabelMaterial = new StandardMaterial(
      'regularLabelMaterial',
      this.gizmoManager.utilityLayer.utilityLayerScene,
    )
    this.regularLabelMaterial.diffuseColor = new Color3(0.79, 0.8, 0.8)
    this.regularLabelMaterial.alpha = this.regularOpacity / 1.7
    this.highlightLabelPlaneMaterial = this.highlightGizmoMaterial.clone('highlightLabelPlaneMaterial')
    this.highlightLabelPlaneMaterial.alpha = this.regularOpacity / 1.7
  }

  get isDragging() {
    return this.isInDraggingState
  }

  show(
    selected: AbstractMesh,
    allowRotation: boolean = true,
    is2DHandle: boolean = false,
    isNewVersion: boolean = false,
  ) {
    if (!this.isVisible || !selected) {
      return
    }

    const markPatches = isNewVersion
      ? [
        this.scene.meshes.find(
          (m) =>
            m.id === selected.metadata.sensitiveZoneId &&
            (m.metadata.itemType === SceneItemType.LabelSensitiveZone ||
              m.metadata.itemType === SceneItemType.LabelOrigin),
        ),
      ]
      : selected.getChildMeshes().filter((m) => m.name === LABEL)
    if (markPatches.length !== 1) {
      return
    }
    const markPatchMesh = markPatches[0] as Mesh
    markPatchMesh.computeWorldMatrix()
    selected.computeWorldMatrix()
    let initTransformations = selected.getWorldMatrix().clone()

    let isDisabled = false
    this.gizmoManager.usePointerToAttachGizmos = false
    this.gizmoManager.rotationGizmoEnabled = allowRotation
    this.gizmoManager.positionGizmoEnabled = true
    const rotationGizmo = this.gizmoManager.gizmos.rotationGizmo
    const positionGizmo = this.gizmoManager.gizmos.positionGizmo
    positionGizmo.planarGizmoEnabled = true
    positionGizmo.xGizmo.isEnabled = false
    positionGizmo.yGizmo.isEnabled = false
    positionGizmo.zGizmo.isEnabled = false
    positionGizmo.xPlaneGizmo.isEnabled = false
    positionGizmo.yPlaneGizmo.isEnabled = false
    if (allowRotation) {
      rotationGizmo.xGizmo.isEnabled = false
      rotationGizmo.yGizmo.isEnabled = false
    }

    this.pointerDown = this.gizmoManager.utilityLayer.utilityLayerScene.onPrePointerObservable.add((pointerInfo) => {
      if (pointerInfo.type === PointerEventTypes.POINTERDOWN) {
        if (pointerInfo.event.button !== MouseButtons.LeftButton) {
          this.hide()
          isDisabled = true
        }
      }
    })

    this.setCustomGizmo(markPatchMesh, selected, is2DHandle)
    this.gizmoManager.attachToMesh(selected)
    if (allowRotation) {
      this.updateGizmoScale()
    }

    // drag start events
    const dragStartPoint = new Vector2()
    if (!isNewVersion) {
      this.gizmoManager.gizmos.positionGizmo.onDragStartObservable.add(() => {
        dragStartPoint.x = this.scene.pointerX
        dragStartPoint.y = this.scene.pointerY
        initTransformations = selected.getWorldMatrix().clone()
        this.renderScene.getModelManager().labelMgr.registerLabelUpdatingEvents(
          selected.getChildMeshes().find((l) => l.name === LABEL),
          dragStartPoint,
        )
        this.isInDraggingState = true
        this.setMaterialOpacity(this.movementOpacity)
        this.dragListeners.map((listener) => listener.deactivate())
      })
      if (allowRotation) {
        this.gizmoManager.gizmos.rotationGizmo.onDragStartObservable.add(() => {
          initTransformations = selected.getWorldMatrix().clone()
          this.isInDraggingState = true
          this.setMaterialOpacity(this.movementOpacity)
          this.dragListeners.map((listener) => listener.deactivate())
        })
      }
    } else {
      this.gizmoManager.gizmos.positionGizmo.onDragStartObservable.add(() => {
        if (!isDisabled) {
          dragStartPoint.x = this.scene.pointerX
          dragStartPoint.y = this.scene.pointerY

          const labelMesh = this.scene.meshes.find(
            (m) =>
              m.id === selected.metadata.labelId &&
              (m.metadata.itemType === SceneItemType.Label || m.metadata.itemType === SceneItemType.LabelOrigin),
          )
          labelMesh.isVisible = false
          const insideMesh = this.meshManager.isLabelOrigin(labelMesh)
            ? null
            : labelMesh.getChildMeshes().find((l) => l.name === LABEL_INSIDE_MESH_NAME)
          if (insideMesh) {
            insideMesh.isVisible = false
          }

          this.meshManager.hideTransparentClone(labelMesh as InstancedMesh)
          const planeDragBox = positionGizmo.zPlaneGizmo._rootMesh
            .getChildMeshes()
            .find((c) => c.name === PLANAR_GIZMO_NAME) as Mesh
          planeDragBox.showBoundingBox = false
          const handleBorder = this.createLabelPlaneDragGizmo(this.labelBBoxExtendSize, is2DHandle)
          handleBorder.name = LABEL_HANDLE_BORDER
          handleBorder.position = labelMesh.metadata.orientation.origin
          handleBorder.showBoundingBox = true

          this.renderScene.getModelManager().labelMgr.deactivateLabelManualPlacement(null, false)
          this.renderScene
            .getModelManager()
            .labelMgr.registerManualLabelUpdatingEvents(handleBorder, labelMesh.metadata.orientation, dragStartPoint)
          this.renderScene.getViewMode().onOuterEvent(OuterEvents.LabelActivateManualPlacementUpdate, null)
          this.isInDraggingState = true
          this.setMaterialOpacity(this.movementOpacity)
          this.dragListeners.map((listener) => listener.deactivate())

          this.renderScene.getGpuPicker().disableLabels()
          setTimeout(() => this.renderScene.animate(), 0)
        }
      })
      if (allowRotation) {
        this.gizmoManager.gizmos.rotationGizmo.onDragStartObservable.add(() => {
          if (!isDisabled) {
            const labelMesh = this.scene.meshes.find(
              (m) => m.id === selected.metadata.labelId && m.metadata.itemType === SceneItemType.Label,
            )
            const insideMesh = labelMesh.getChildMeshes().find((l) => l.name === LABEL_INSIDE_MESH_NAME)
            labelMesh.isVisible = insideMesh.isVisible = false
            this.meshManager.hideTransparentClone(labelMesh as InstancedMesh)

            initTransformations = selected.getWorldMatrix().clone()
            this.isInDraggingState = true
            this.setMaterialOpacity(this.movementOpacity)
            this.dragListeners.map((listener) => listener.deactivate())
            this.renderScene.getViewMode().onOuterEvent(OuterEvents.LabelOrientationStartUpdate, null)
          }
        })
      }
    }

    // drag end events
    let wasRotate = false
    if (!isNewVersion) {
      const labelId = selected.getChildMeshes().find((l) => l.name === LABEL).id
      this.gizmoManager.gizmos.positionGizmo.onDragEndObservable.add(async () => {
        this.renderScene.getModelManager().labelMgr.unRegisterLabelUpdatingEvents()
        this.isInDraggingState = false
        this.setMaterialOpacity(this.regularOpacity)
        if (dragStartPoint.x !== this.scene.pointerX || dragStartPoint.y !== this.scene.pointerY) {
          await this.labelManager.updateLabelPosition(labelId, dragStartPoint)
        }

        this.dragListeners.map((listener) => listener.activate())
        initTransformations = selected.getWorldMatrix().clone()
      })
      const labelMesh = selected.getChildMeshes().find((l) => l.name === LABEL)
      const labelMetadata = labelMesh.metadata as ILabelMetadata
      const yDirection = labelMetadata.orientation.yDirection.clone()
      if (allowRotation) {
        this.gizmoManager.gizmos.rotationGizmo.onDragEndObservable.add(async () => {
          this.isInDraggingState = false
          this.setMaterialOpacity(this.regularOpacity)
          this.dragListeners.map((listener) => listener.activate())
          if (wasRotate) {
            await this.labelManager.updateLabelRotation(labelId, initTransformations, yDirection)
          }
        })
        this.rotateLabelObserver = this.gizmoManager.gizmos.rotationGizmo.zGizmo.dragBehavior.onDragObservable.add(
          () => {
            wasRotate = true
            this.renderScene.getModelManager().labelMgr.rotateLabel(labelMesh.id, initTransformations, yDirection)
          },
        )
      }
    } else {
      this.gizmoManager.gizmos.positionGizmo.onDragEndObservable.add(async () => {
        if (!isDisabled) {
          const labelMesh = this.scene.meshes.find(
            (m) =>
              m.id === selected.metadata.labelId &&
              (m.metadata.itemType === SceneItemType.Label || m.metadata.itemType === SceneItemType.LabelOrigin),
          )
          this.renderScene.getModelManager().labelMgr.unRegisterManualLabelUpdatingEvents()
          this.renderScene.getViewMode().onOuterEvent(OuterEvents.LabelDeactivateManualPlacementUpdate, null)
          this.isInDraggingState = false
          this.setMaterialOpacity(this.regularOpacity)
          const handleBorder = this.gizmoManager.utilityLayer.utilityLayerScene.getMeshByName(LABEL_HANDLE_BORDER)
          this.gizmoManager.utilityLayer.utilityLayerScene.removeMesh(handleBorder)
          handleBorder.dispose()

          if (dragStartPoint.x !== this.scene.pointerX || dragStartPoint.y !== this.scene.pointerY) {
            this.labelManager.updateManualPlacedLabelPosition(
              labelMesh.id,
              labelMesh.metadata.orientation,
              labelMesh.metadata.rotationAngle,
              dragStartPoint,
            )
          } else {
            const insideMesh = this.meshManager.isLabelOrigin(labelMesh)
              ? null
              : labelMesh.getChildMeshes().find((l) => l.name === LABEL_INSIDE_MESH_NAME)
            if (!labelMesh.metadata.isHidden) {
              labelMesh.isVisible = true
              if (insideMesh) {
                insideMesh.isVisible = true
              }
            } else {
              this.meshManager.showTransparentClone(labelMesh as InstancedMesh)
            }

            const planeDragBox = positionGizmo.zPlaneGizmo._rootMesh
              .getChildMeshes()
              .find((c) => c.name === PLANAR_GIZMO_NAME) as Mesh
            planeDragBox.showBoundingBox = true
          }

          this.dragListeners.map((listener) => listener.activate())
          this.renderScene.getGpuPicker().enableLabels()
          setTimeout(() => {
            this.renderScene.getModelManager().labelMgr.activateLabelManualPlacement()
            this.renderScene.animate()
            const picked = this.renderScene.getGpuPicker().pick(this.scene.pointerX, this.scene.pointerY)
            if (picked && picked.body && this.meshManager.isLabelSensitiveZone(picked.body)) {
              this.renderScene.hoverPickedObject(picked)
            }
          }, 0)
        }
      })
      if (allowRotation) {
        this.gizmoManager.gizmos.rotationGizmo.onDragEndObservable.add(async () => {
          if (!isDisabled) {
            this.isInDraggingState = false
            this.setMaterialOpacity(this.regularOpacity)
            this.dragListeners.map((listener) => listener.activate())
            this.renderScene.getViewMode().onOuterEvent(OuterEvents.LabelOrientationEndUpdate, null)
            const labelMesh = this.scene.meshes.find(
              (m) => m.id === selected.metadata.labelId && m.metadata.itemType === SceneItemType.Label,
            )
            if (wasRotate) {
              this.labelManager.updateManualPlacedLabelRotation(
                labelMesh.id,
                initTransformations,
                labelMesh.metadata.orientation.yDirection.clone(),
              )
            } else {
              const insideMesh = labelMesh.getChildMeshes().find((l) => l.name === LABEL_INSIDE_MESH_NAME)
              if (!labelMesh.metadata.isHidden) {
                labelMesh.isVisible = insideMesh.isVisible = true
              } else {
                this.meshManager.showTransparentClone(labelMesh as InstancedMesh)
              }
            }
          }
        })
        this.rotateLabelObserver = this.gizmoManager.gizmos.rotationGizmo.zGizmo.dragBehavior.onDragObservable.add(
          () => {
            wasRotate = true
          },
        )
      }
    }

    this.gizmoManager.utilityLayer.utilityLayerScene.render()
  }

  hide(silent?: boolean) {
    this.clearRotationGizmosEvents()
    this.clearPositionGizmosEvents()
    this.disableRotationGizmos()
    this.disableTranslationGizmos()
    this.gizmoManager.utilityLayer.utilityLayerScene.onPrePointerObservable.remove(this.pointerDown)
  }

  updateGizmoScale() {
    const arrow = this.gizmoManager.utilityLayer.utilityLayerScene.meshes.find(
      (mesh) => mesh.name === ROTATION_GIZMO_ARROW_NAME,
    )
    if (this.gizmoManager.gizmos.rotationGizmo && arrow) {
      const scaleRatio = this.computeGizmoScaleRatio()
      arrow.position.x = this.labelBBoxExtendSize.y + scaleRatio
      arrow.scaling = new Vector3(scaleRatio, scaleRatio, scaleRatio)
      arrow.computeWorldMatrix()
    }
  }

  pick(x: number, y: number) {
    return this.gizmoManager.utilityLayer.utilityLayerScene.pick(x, y)
  }

  dispose() {
    if (this.highlightLabelPlaneMaterial) {
      this.highlightLabelPlaneMaterial.dispose(true, true)
    }

    if (this.regularLabelMaterial) {
      this.regularLabelMaterial.dispose(true, true)
    }

    if (this.cacheRotationArrow) {
      this.cacheRotationArrow.dispose(false, true)
    }

    super.dispose()
  }

  protected computeGizmoScaleRatio() {
    const camera = this.renderScene.getActiveCamera()
    return (camera.orthoRight - camera.orthoLeft) / 35
  }

  protected setMaterialOpacity(alpha: number) {
    this.regularLabelMaterial.alpha = alpha / 1.7
    this.regularPlaneMaterial.alpha = alpha / 1.7
    this.highlightLabelPlaneMaterial.alpha = alpha / 1.7
    this.highlightGizmoMaterial.alpha = alpha
  }

  protected clearRotationGizmosEvents() {
    if (this.gizmoManager.gizmos.rotationGizmo) {
      this.gizmoManager.gizmos.rotationGizmo.onDragEndObservable.clear()
      this.gizmoManager.gizmos.rotationGizmo.onDragStartObservable.clear()
      this.gizmoManager.gizmos.rotationGizmo.zGizmo.dragBehavior.onDragObservable.remove(this.rotateLabelObserver)
    }
  }

  private setCustomGizmo(markPatchMesh: Mesh, selected: TransformNode, is2DHandle: boolean) {
    const positionGizmo = this.gizmoManager.gizmos.positionGizmo
    const rotationGizmo = this.gizmoManager.gizmos.rotationGizmo
    const meshBBox = markPatchMesh.getBoundingInfo().boundingBox
    this.labelBBoxExtendSize = meshBBox.extendSize.clone()
    const position = meshBBox.center.clone()

    const box = this.createLabelPlaneDragGizmo(this.labelBBoxExtendSize, is2DHandle)
    let arrow
    if (rotationGizmo) {
      arrow = this.createLabelRotationGizmo()
    }

    const toCenterBbox = Matrix.Identity()
    const invPartTrf = selected.computeWorldMatrix().clone().invert()
    const markPatchTrf = markPatchMesh.computeWorldMatrix().clone().multiply(invPartTrf)
    toCenterBbox.setTranslation(position)
    toCenterBbox.multiplyToRef(markPatchTrf, toCenterBbox)
    box.bakeTransformIntoVertices(toCenterBbox)

    positionGizmo.zPlaneGizmo.setCustomMesh(box)
    positionGizmo.zPlaneGizmo.updateScale = this.updateScale
    if (rotationGizmo) {
      rotationGizmo.zGizmo.setCustomMesh(arrow)
      rotationGizmo.zGizmo.updateScale = this.updateScale
    }
  }

  private createLabelPlaneDragGizmo(size: Vector3, is2DHandle: boolean = false): Mesh {
    const utilityLayerScene = this.gizmoManager.utilityLayer.utilityLayerScene
    const width = size.x * 2
    const height = size.y * 2
    const depth = size.z < Epsilon ? Epsilon : size.z * 2
    const box = is2DHandle
      ? MeshBuilder.CreatePlane(
        PLANAR_GIZMO_NAME,
        { width, height, sideOrientation: Mesh.DOUBLESIDE },
        utilityLayerScene,
      )
      : MeshBuilder.CreateBox(PLANAR_GIZMO_NAME, { width, height, depth }, utilityLayerScene)
    box.showBoundingBox = is2DHandle ? true : false

    box.material = this.regularPlaneMaterial

    utilityLayerScene.onPointerObservable.add((pointerInfo) => {
      if (!box.isPickable || this.isInDraggingState) return
      const isHovered =
        pointerInfo.pickInfo && pointerInfo.pickInfo.pickedMesh && pointerInfo.pickInfo.pickedMesh.name === box.name
      box.material = isHovered ? this.highlightLabelPlaneMaterial : this.regularPlaneMaterial
    })

    const gizmoLight = this.gizmoManager.utilityLayer._getSharedGizmoLight()
    gizmoLight.includedOnlyMeshes.push(box)

    return box
  }

  private createLabelRotationGizmo(): Mesh {
    const utilityLayerScene = this.gizmoManager.utilityLayer.utilityLayerScene
    const arrow = this.cacheRotationArrow
      ? this.cacheRotationArrow.clone(ROTATION_GIZMO_ARROW_NAME)
      : this.createCacheRotationArrow().clone(ROTATION_GIZMO_ARROW_NAME)

    arrow.isVisible = true
    arrow.isPickable = true
    arrow.material = this.regularLabelMaterial

    arrow.rotate(Axis.X, -Math.PI / 2)

    const disc = MeshBuilder.CreateDisc('rotationArrowHelperMesh', { radius: 2 / 3 }, utilityLayerScene)
    disc.rotate(Axis.X, Math.PI / 2)
    disc.visibility = 0
    disc.parent = arrow

    utilityLayerScene.onPointerObservable.add((pointerInfo) => {
      if (!arrow.isPickable || this.isInDraggingState) return
      const isHovered =
        pointerInfo.pickInfo &&
        pointerInfo.pickInfo.pickedMesh &&
        [arrow.name, disc.name].indexOf(pointerInfo.pickInfo.pickedMesh.name) > -1
      arrow.material = isHovered ? this.highlightGizmoMaterial : this.regularLabelMaterial
    })

    const gizmoLight = this.gizmoManager.utilityLayer._getSharedGizmoLight()
    gizmoLight.includedOnlyMeshes.push(arrow)

    return arrow
  }

  private createCacheRotationArrow(): Mesh {
    const diameter = 1
    const radiusTube = diameter / 3
    const heightCone = diameter / 2
    const diameterCone = radiusTube * 2
    const tessellation = 40
    const utilityLayerScene = this.gizmoManager.utilityLayer.utilityLayerScene

    const torus = MeshBuilder.CreateTorus('', { tessellation, diameter, thickness: radiusTube }, utilityLayerScene)
    const cone = MeshBuilder.CreateCylinder(
      '',
      {
        tessellation,
        height: heightCone,
        diameterTop: 0,
        diameterBottom: diameterCone,
      },
      utilityLayerScene,
    )
    const box = MeshBuilder.CreateBox('', { size: diameter }, utilityLayerScene)

    box.position.x = diameter / 2
    box.position.z = diameter / 2

    cone.rotate(Axis.X, Math.PI / 2)
    cone.position.x = diameter / 2
    cone.position.z = diameter / 4

    const torusCSG = CSG.FromMesh(torus)
    const coneCSG = CSG.FromMesh(cone)
    const boxCSG = CSG.FromMesh(box)

    torus.dispose()
    cone.dispose()
    box.dispose()

    const arrowCSG = torusCSG.subtract(boxCSG).union(coneCSG)
    const arrow = arrowCSG.toMesh('cacheRotationArrow', this.regularLabelMaterial, utilityLayerScene)

    arrow.isVisible = false
    arrow.isPickable = false

    this.cacheRotationArrow = arrow
    return arrow
  }
}
