import { get, post } from '@/api/http'
import { EndpointsUrls } from '@/configs/config'
import { handleAPIError } from '@/api/common'
import { JobDto, IJob } from '@/types/PartsLibrary/Job'
import { PrintOrder } from '../types/PrintOrder/PrintOrderFE'

export default {
  async getRunningJobsForEveryone() {
    try {
      return await get<IJob[]>(`${EndpointsUrls.Jobs}/runningForEveryone`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getRunnningJobsForCurrentUser() {
    try {
      return await get<JobDto[]>(`${EndpointsUrls.Jobs}/running`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async cancelJob(jobId: string, reason: string = 'Cancelled from Jobs Dashboard UI') {
    try {
      return await post(`${EndpointsUrls.Jobs}/cancel/${jobId}`, { reason })
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchPrintOrders() {
    try {
      return await get<PrintOrder[]>(`${EndpointsUrls.Jobs}/printOrders`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchPrintOrderById(id: string) {
    try {
      return await get<PrintOrder>(`${EndpointsUrls.Jobs}/printOrders/${id}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchLabelsFileNameByPrintOrderId(id: string) {
    try {
      return await get<string>(`${EndpointsUrls.Jobs}/printOrders/${id}/labelsFileName`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchJobById(id: string) {
    try {
      return await get<IJob>(`${EndpointsUrls.Jobs}/${id}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchJobsByItemIds(itemIds: string[]): Promise<IJob[]> {
    try {
      return post<IJob[]>(`${EndpointsUrls.Jobs}/byItemIds`, { itemIds })
    } catch (error) {
      handleAPIError(error)
    }
  },
}
