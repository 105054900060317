import { SimulationColoringMode } from '@/types/Simulation/SimulationColoringMode'
import {
  TEMPERATURE_FIELD_PATTERN,
  STRESS_FIELD_PATTERN,
  FIELD_MM_PATTERN,
  COMPENSATION_MM_FIELD_PATTERN,
  DISPLACEMENT_MM_FIELD_PATTERN,
  SURFACE_DEVIATION_MM_FIELD_PATTERN,
  MAXIMUM_STRESS_FIELD_PATTERN,
  MINIMUM_STRESS_FIELD_PATTERN,
  VON_MISES_STRESS_FIELD_PATTERN,
  VON_MISES_STRAIN_FIELD_PATTERN,
  DISPLACEMENT_ITERATION__FIELD_PATTERN,
  RESIDUAL_ERROR_FIELD_PATTERN,
  SELF_INTERSECTING_NODES_ORIG_PATTERN,
  SELF_INTERSECTING_NODES_COMP_PATTERN,
  ELASTIC_STRAIN_FIELD_PATTERN,
  DIFFERENCE_SIGNED,
} from '@/constants'
import { Unit } from '@/types/Simulation/Units'
import { SimulationResultTypes } from '@/types/Simulation/SimulationResultTypes'
import i18n from '@/plugins/i18n'

const MAGNITUDE = 'Magnitude'
const UNDEFINED_LABEL = 'undefined'

const displacementModeNames = ['X', 'Y', 'Z', MAGNITUDE]
const strainModeNames = ['Von Mises', 'Maximum Principal', 'Middle Principal', 'Minimum Principal']

const DIFFERENCE_MAGNITUDE = /difference_magnitude/i
const DIFFERENCE_X = /difference_x/i
const DIFFERENCE_Y = /difference_y/i
const DIFFERENCE_Z = /difference_z/i
const DIFFERENCE_UNSIGNED = /difference_along_normal_unsigned/i

export enum Components {
  X = '0',
  Y = '1',
  Z = '2',
  Magnitude = '3',
}

export class SimulationColoringView {
  modes: SimulationColoringMode[]
  selectedMode: SimulationColoringMode
  name: string
  internalName: string

  constructor(name: string, componentsCount: number) {
    this.name = this.adjustViewName(name)
    this.internalName = name
    this.modes = []
    this.initViewModes(componentsCount)
    this.selectedMode = this.modes[0]
  }

  getSelectedModeMetadataId(): string {
    return this.selectedMode.metadataId
  }

  isNameMatches(pattern: RegExp) {
    return pattern.test(this.internalName)
  }

  get unit(): Unit {
    if (this.isNameMatches(FIELD_MM_PATTERN)) {
      return Unit.Millimeter
    }

    if (this.isNameMatches(STRESS_FIELD_PATTERN)) {
      return Unit.MegaPascal
    }

    if (this.isNameMatches(TEMPERATURE_FIELD_PATTERN)) {
      return Unit.CelsiusDegree
    }

    if (this.isNameMatches(ELASTIC_STRAIN_FIELD_PATTERN)) {
      return Unit.Strain
    }

    return Unit.Undefined
  }

  get helpMessage(): string {
    if (this.isNameMatches(COMPENSATION_MM_FIELD_PATTERN)) {
      return SimulationResultTypes.AppliedCompensation
    }
    if (this.isNameMatches(DISPLACEMENT_MM_FIELD_PATTERN)) {
      return SimulationResultTypes.Displacement
    }
    if (this.isNameMatches(SURFACE_DEVIATION_MM_FIELD_PATTERN)) {
      return SimulationResultTypes.SurfaceDeviation
    }
    if (this.isNameMatches(TEMPERATURE_FIELD_PATTERN)) {
      return SimulationResultTypes.Temperature
    }
    if (this.isNameMatches(MAXIMUM_STRESS_FIELD_PATTERN)) {
      return SimulationResultTypes.MaximumPrincipalStress
    }
    if (this.isNameMatches(MINIMUM_STRESS_FIELD_PATTERN)) {
      return SimulationResultTypes.MinimumPrincipalStress
    }
    if (this.isNameMatches(VON_MISES_STRESS_FIELD_PATTERN)) {
      return SimulationResultTypes.VonMisesStress
    }
    if (this.isNameMatches(VON_MISES_STRAIN_FIELD_PATTERN)) {
      return SimulationResultTypes.VonMisesStrain
    }
    if (this.isNameMatches(DISPLACEMENT_ITERATION__FIELD_PATTERN)) {
      return SimulationResultTypes.DisplacementPreviousIteration
    }
    if (this.isNameMatches(RESIDUAL_ERROR_FIELD_PATTERN)) {
      return SimulationResultTypes.ResidualError
    }
    if (this.isNameMatches(SELF_INTERSECTING_NODES_ORIG_PATTERN)) {
      return SimulationResultTypes.SelfIntersectingNodesOrig
    }
    if (this.isNameMatches(SELF_INTERSECTING_NODES_COMP_PATTERN)) {
      return SimulationResultTypes.SelfIntersectingNodesComp
    }
    if (this.isNameMatches(ELASTIC_STRAIN_FIELD_PATTERN)) {
      return SimulationResultTypes.ElasticStrain
    }
    if (this.isNameMatches(DIFFERENCE_MAGNITUDE)) {
      return SimulationResultTypes.IBCMagnitude
    }
    if (this.isNameMatches(DIFFERENCE_X)) {
      return SimulationResultTypes.IBCX
    }
    if (this.isNameMatches(DIFFERENCE_Y)) {
      return SimulationResultTypes.IBCY
    }
    if (this.isNameMatches(DIFFERENCE_Z)) {
      return SimulationResultTypes.IBCZ
    }
    if (this.isNameMatches(DIFFERENCE_SIGNED)) {
      return SimulationResultTypes.IBCNormalSigned
    }
    if (this.isNameMatches(DIFFERENCE_UNSIGNED)) {
      return SimulationResultTypes.IBCNormalUnsigned
    }
    return SimulationResultTypes.Undefined
  }

  private initViewModes(count: number) {
    for (let i = 0; i < count; i += 1) {
      const label = this.getLabel(i)
      const mode = new SimulationColoringMode(label, i.toString(), this)
      if (label === MAGNITUDE) {
        this.modes.unshift(mode)
      } else {
        this.modes.push(mode)
      }
    }
  }

  private getLabel(index: number) {
    if (this.isNameMatches(ELASTIC_STRAIN_FIELD_PATTERN) && index < strainModeNames.length) {
      return strainModeNames[index]
    }

    return index < displacementModeNames.length ? displacementModeNames[index] : UNDEFINED_LABEL
  }

  private adjustViewName(name: string) {
    if (DIFFERENCE_MAGNITUDE.test(name)) {
      return displacementModeNames[3]
    }

    if (DIFFERENCE_X.test(name)) {
      return displacementModeNames[0]
    }

    if (DIFFERENCE_Y.test(name)) {
      return displacementModeNames[1]
    }

    if (DIFFERENCE_Z.test(name)) {
      return displacementModeNames[2]
    }

    if (DIFFERENCE_SIGNED.test(name)) {
      return i18n.t('ibcNormalToSurfaceSigned').toString()
    }

    if (DIFFERENCE_UNSIGNED.test(name)) {
      return i18n.t('ibcNormalToSurfaceUnsigned').toString()
    }

    return name
  }
}
