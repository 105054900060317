import jobService from '@/api/jobs'
import { ActionTree } from 'vuex'
import { IRootState } from '@/store/types'
import { IJobsState } from '@/store/modules/jobs/types'
import messageService from '@/services/messageService'
import i18n from '@/plugins/i18n'
import { PRINT_JOB_EXCLUDE_STATUS_SET } from '@/constants'

export const actions: ActionTree<IJobsState, IRootState> = {
  async fetchJobs({ commit }) {
    try {
      const myJobs = await jobService.getRunningJobsForEveryone()
      commit('setJobs', myJobs)
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  },

  async cancelJob({ commit }, jobId: string) {
    try {
      await jobService.cancelJob(jobId)
      commit('setJobStatus', jobId)
      const message = i18n.t('taskCancelRequestSuccess') as string
      messageService.showSuccessMessage(message)
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  },

  async fetchPrintOrders({ commit }) {
    try {
      let printOrders = await jobService.fetchPrintOrders()
      if (Array.isArray(printOrders) && printOrders.length > 0) {
        printOrders = printOrders.filter(
          (printOrder) => printOrder.status && !PRINT_JOB_EXCLUDE_STATUS_SET.has(printOrder.status),
        )
      }

      commit('setPrintOrders', printOrders)
      commit('setPrintOrdersLoading', false)
      return printOrders
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  },

  async fetchPrintOrderById({ commit }, id: string) {
    try {
      const printOrder = await jobService.fetchPrintOrderById(id)
      commit('setPrintOrder', printOrder)

      return printOrder
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  },

  async fetchJob({ commit }, id: string) {
    try {
      const job = await jobService.fetchJobById(id)
      commit('addJobs', [job])

      return job
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  },
}
