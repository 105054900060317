var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    selectable: true,
    capitalize: true,
    'full-width': true,
    'hovered-list-item': _vm.isDefectShapeHovered(),
    'selected-list-item': _vm.isDefectShapeSelected(),
  },on:{"click":_vm.selectDefectShape,"mouseover":_vm.hoverIntoDefectShape,"mouseleave":_vm.hoverOutOfDefectShape}},[_vm._v(" "+_vm._s(_vm.shapeName)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }