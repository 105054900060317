import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { ICadHelperState } from './types'
import { IRootState } from '@/store/types'

export const state: ICadHelperState = {
  downloadUrl: null,
  version: null,
}

const namespaced: boolean = true

export const cadHelper: Module<ICadHelperState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
