/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { InstancedMesh, Mesh, MeshBuilder, TransformNode, AbstractMesh, VertexData } from '@babylonjs/core/Meshes'
import { Buffer, VertexBuffer } from '@babylonjs/core/Buffers'
import { DefectShape, GeometryDefect } from '@/visualization/models/DataModel'
import { IRenderable } from '@/visualization/types/IRenderable'
import {
  DefectShapeType,
  IHighlightableDefectIndices,
  IHighlightDefectPayload,
  ISelectableDefectPayload,
  PartDefect,
} from '@/types/Parts/IPartInsight'
import {
  COLOR_FOR_BODY,
  COLOR_FOR_FACE,
  COLOR_FOR_PART,
  DEFECT,
  FACE_ID_ATTRIBUTE,
  POINT_DEFECT,
  DEFECT_MATERIAL,
  MESH_RENDERING_GROUP_ID,
  DEFECT_POINT_DIAMETER,
  DEFECT_TUBE_DIAMETER,
  LINE_DEFECT,
  LOOP_DEFECT,
  MESH_DEFECT,
  DEFECT_INSIDE_MESH_NAME,
  DEFECT_INSIDE_MATERIAL,
  FACE_DEFECT,
  DEFECT_GROUP,
  MAX_GEOMETRY_INSIGHT_COUNT_PER_TYPE,
} from '@/constants'
import {
  IDefectGroupMetadata,
  IDefectMetadata,
  IPartMetadata,
  SceneItemType,
} from '@/visualization/types/SceneItemMetadata'
import { Scene } from '@babylonjs/core/scene'
import { Color3, Vector3 } from '@babylonjs/core/Maths'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import { v4 as uuid } from 'uuid'
import { RenderScene } from '../render-scene'
import { ISelectableNode } from './SelectionManager'
import { isNumber } from '@/utils/number'

export class DefectsManager {
  private renderScene: IRenderable
  private scene: Scene
  private meshManager: MeshManager

  private defects: Map<TransformNode, Mesh> = new Map<TransformNode, Mesh>()
  private defectIndexByType: Map<PartDefect, number> = new Map<PartDefect, number>()
  private updateScalingThrottle: NodeJS.Timeout

  constructor(renderScene: IRenderable) {
    this.renderScene = renderScene
    this.scene = renderScene.getScene()
    this.meshManager = renderScene.getMeshManager()
  }

  addDefectsToMesh(mesh: InstancedMesh, defects: GeometryDefect[]) {
    try {
      for (const defect of defects) {
        const defectIndex = this.getDefectIndexByType(defect.type)
        if ((!defect.positions && !defect.shapes) || defectIndex >= MAX_GEOMETRY_INSIGHT_COUNT_PER_TYPE) {
          continue
        }

        if (defect.shapes && defect.shapes.length) {
          this.addDefectsFromConfigV2(defectIndex, defect.type, defect.shapes, mesh)
          continue
        }

        if (defect.positions && defect.positions.length) {
          this.addDefectsFromConfigV1(defectIndex, defect.type, defect.positions, mesh)
        }
      }
    } catch {
      throw new Error('Error is occured during part defects rendering')
    } finally {
      this.defectIndexByType.clear()
    }
  }

  toggleDefectsHighlight(payload: IHighlightDefectPayload) {
    const defectMeshes = this.getDefectMeshes(payload.defect.type, payload.defect.indices)
    this.renderScene.getSelectionManager().highlight(defectMeshes, payload.showHighlight, true)
  }

  selectDefects(payload: ISelectableDefectPayload) {
    const defectMeshes = this.getDefectMeshes(payload.defect.type, payload.defect.indices)
    if (payload.deselectIfSelected) {
      this.renderScene.getSelectionManager().deselect(defectMeshes, true)
      if (payload.defect.indices.length === 1 && isNumber(payload.defect.indices[0].shapeIndex)) {
        const defectShapes: ISelectableNode[] = []
        const defectNode = this.meshManager.getDefectNodeByTypeAndIndex(
          payload.defect.type,
          payload.defect.indices[0].defectIndex,
        )
        if (defectNode) {
          defectNode.getChildMeshes(true).map((c) => defectShapes.push({ body: c }))
        }

        this.renderScene.getSelectionManager().select(defectShapes, true, true)
      }
      return
    }

    if (
      !payload.defect.indices.length ||
      (payload.defect.indices.length && !isNumber(payload.defect.indices[0].shapeIndex))
    ) {
      this.renderScene.getSelectionManager().select(defectMeshes, true, true)
      return
    }

    const selected = this.renderScene
      .getSelectionManager()
      .getSelected()
      .filter((m) => m.parent.metadata.defectIndex !== payload.defect.indices[0].defectIndex)
    for (const selectedDefect of selected) {
      defectMeshes.push({ body: selectedDefect })
    }
    this.renderScene.getSelectionManager().select(defectMeshes, false, true)
  }

  updateDefectsScaling(shapeTypes: DefectShapeType[]) {
    if (this.updateScalingThrottle) {
      clearTimeout(this.updateScalingThrottle)
    }

    const modelManager = (this.renderScene as RenderScene).getModelManager()
    if (modelManager) {
      const defectsMgr = modelManager.defectsMgr
      this.updateScalingThrottle = setTimeout(defectsMgr.updateDefectsRadius, 50, {
        shapeTypes,
        renderScene: this.renderScene,
      })
    }
  }

  private addDefectsFromConfigV1(index: number, type: PartDefect, defectPositions: number[][], mesh: AbstractMesh) {
    const groupNode = this.addDefectGroupNode(type, mesh)
    const defectNode = this.addDefectNode(type, index, groupNode)
    for (const position of defectPositions) {
      this.addPointDefect(Vector3.FromArray(position), defectNode, groupNode)
    }
  }

  private addDefectsFromConfigV2(index: number, type: PartDefect, defectShapes: DefectShape[], mesh: AbstractMesh) {
    for (const shape of defectShapes) {
      this.addDefectShape(index, type, shape, mesh)
    }
  }

  private addDefectGroupNode(type: PartDefect, mesh: AbstractMesh) {
    const defectType = type in PartDefect ? type : PartDefect.Other
    let defectGroupNode = this.meshManager.getDefectGroupNodeByType(defectType)
    if (!defectGroupNode) {
      defectGroupNode = new TransformNode(DEFECT_GROUP, this.scene)
      defectGroupNode.parent = mesh
      defectGroupNode.metadata = {
        defectType,
        itemType: SceneItemType.DefectGroup,
      } as IDefectGroupMetadata
    }

    return defectGroupNode
  }

  private addDefectNode(type: PartDefect, defectIndex: number, defectGroupNode: TransformNode) {
    let defectNode = this.meshManager.getDefectNodeByTypeAndIndex(type, defectIndex)
    if (!defectNode) {
      defectNode = new TransformNode(DEFECT, this.scene)
      defectNode.parent = defectGroupNode
      defectNode.metadata = {
        defectIndex,
        itemType: SceneItemType.Defect,
      } as IDefectMetadata
    }

    return defectNode
  }

  private addDefectShape(defectIndex: number, type: PartDefect, defectShape: DefectShape, mesh: AbstractMesh) {
    let groupNode: TransformNode
    let defectNode: TransformNode
    switch (defectShape.type) {
      case DefectShapeType.Point:
        groupNode = this.addDefectGroupNode(type, mesh)
        defectNode = this.addDefectNode(type, defectIndex, groupNode)
        this.addPointDefect(Vector3.FromArray(defectShape.positions[0]), defectNode, groupNode)
        break
      case DefectShapeType.Line:
      case DefectShapeType.Loop:
      case DefectShapeType.Face:
        groupNode = this.addDefectGroupNode(type, mesh)
        defectNode = this.addDefectNode(type, defectIndex, groupNode)
        const tubeDiameter = DEFECT_TUBE_DIAMETER
        if (defectShape.type === DefectShapeType.Face) {
          const segments =
            defectShape.segments && defectShape.segments.length ? defectShape.segments : [defectShape.positions]
          this.addFaceDefect(segments, defectNode, groupNode, defectShape.entity, tubeDiameter)
        } else {
          this.addLineOrLoopDefect(defectShape.type, defectShape.positions, defectNode, groupNode, tubeDiameter)
        }
        break
      case DefectShapeType.Mesh:
        this.addMeshDefect(type, mesh)
        break
      default:
        break
    }
  }

  private addPointDefect(position: Vector3, defectNode: TransformNode, groupNode: TransformNode) {
    let sourcePointDefect: Mesh
    if (!this.defects.has(groupNode)) {
      sourcePointDefect = this.createPointDefectSource(groupNode)
      this.defects.set(groupNode, sourcePointDefect)
    } else {
      sourcePointDefect = this.defects.get(groupNode)
    }

    const defectMesh = sourcePointDefect.createInstance(sourcePointDefect.name)
    this.setupDefectInstanceMesh(defectMesh, defectNode, position)
  }

  private addLineOrLoopDefect(
    shapeType: DefectShapeType,
    positions: number[][],
    defectNode: TransformNode,
    groupNode: TransformNode,
    tubeDiameter: number,
    isFaceSegment?: boolean,
  ) {
    if (!positions.length) {
      return
    }

    const sourceLineOrLoopDefect = this.createLineOrLoopDefectSource(
      shapeType,
      positions,
      groupNode,
      tubeDiameter,
      isFaceSegment,
    )
    if (isFaceSegment) {
      sourceLineOrLoopDefect.parent = groupNode
      return
    }

    const defectMesh = sourceLineOrLoopDefect.createInstance(sourceLineOrLoopDefect.name)
    this.setupDefectInstanceMesh(defectMesh, defectNode)
  }

  private addFaceDefect(
    segments: number[][][],
    defectNode: TransformNode,
    groupNode: TransformNode,
    entity: number,
    tubeDiameter: number,
  ) {
    const sourceFaceDefect = this.createFaceDefectSource(segments, groupNode, entity, tubeDiameter)
    const defectMesh = sourceFaceDefect.createInstance(sourceFaceDefect.name)
    this.setupDefectInstanceMesh(defectMesh, defectNode)
  }

  private addMeshDefect(type: PartDefect, mesh: AbstractMesh) {
    mesh.instancedBuffers.color = Color3.Red()
    const metadata = mesh.metadata as IPartMetadata
    metadata.hasDefects = true
    metadata.defectType = type
  }

  private createPointDefectSource(groupNode: TransformNode) {
    const defectMesh = MeshBuilder.CreateSphere(
      this.getDefectShapeMeshName(DefectShapeType.Point),
      { segments: 16, diameter: DEFECT_POINT_DIAMETER },
      this.scene,
    )
    this.setupDefectSourceMesh(defectMesh, groupNode.parent as AbstractMesh, SceneItemType.PointDefect)
    const defectInsideMesh = this.createInsideMesh(defectMesh)
    defectMesh.metadata.meshInside = defectInsideMesh
    this.scene.removeMesh(defectInsideMesh)

    return defectMesh
  }

  private createLineOrLoopDefectSource(
    shapeType: DefectShapeType,
    positions: number[][],
    groupNode: TransformNode,
    tubeDiameter: number,
    isFaceSegment: boolean,
  ) {
    const path: Vector3[] = []
    for (const position of positions) {
      const pathPoint = Vector3.FromArray(position)
      path.push(pathPoint)
    }

    const defectMeshName = this.getDefectShapeMeshName(shapeType)
    let itemType = SceneItemType.LineDefect
    let defectMesh = MeshBuilder.CreateTube(
      defectMeshName,
      { path, cap: Mesh.CAP_ALL, radius: tubeDiameter / 2, sideOrientation: Mesh.BACKSIDE },
      this.scene,
    )

    if (shapeType === DefectShapeType.Loop) {
      itemType = SceneItemType.LoopDefect
      const loopPath = [path[0], path[path.length - 1]]
      const loopMesh = MeshBuilder.CreateTube(
        defectMeshName,
        { path: loopPath, cap: Mesh.CAP_ALL, radius: tubeDiameter / 2, sideOrientation: Mesh.BACKSIDE },
        this.scene,
      )
      const totalVertices = defectMesh.getTotalVertices() + loopMesh.getTotalVertices()
      const allow32BitsIndices = totalVertices >= 65536 ? true : false
      defectMesh = Mesh.MergeMeshes([defectMesh, loopMesh], true, allow32BitsIndices)
      defectMesh.name = defectMeshName
    }

    if (isFaceSegment) {
      return defectMesh
    }

    this.setupDefectSourceMesh(defectMesh, groupNode.parent as AbstractMesh, itemType)
    defectMesh.metadata.positions = positions
    const defectInsideMesh = this.createInsideMesh(defectMesh)
    defectMesh.metadata.meshInside = defectInsideMesh
    this.scene.removeMesh(defectInsideMesh)

    return defectMesh
  }

  private createFaceDefectSource(
    segments: number[][][],
    groupNode: TransformNode,
    entity: number,
    tubeDiameter: number,
  ) {
    const faceGroupNode = new TransformNode(FACE_DEFECT, this.scene)
    let totalVertices = 0
    for (const segment of segments) {
      this.addLineOrLoopDefect(DefectShapeType.Line, segment, null, faceGroupNode, tubeDiameter, true)
      totalVertices += faceGroupNode.getChildMeshes().pop().getTotalVertices()
    }

    const allow32BitsIndices = totalVertices >= 65536 ? true : false
    const defectMesh = Mesh.MergeMeshes(faceGroupNode.getChildMeshes() as Mesh[], true, allow32BitsIndices)
    defectMesh.name = FACE_DEFECT
    this.setupDefectSourceMesh(defectMesh, groupNode.parent as AbstractMesh, SceneItemType.FaceDefect)
    defectMesh.metadata.segments = segments
    defectMesh.metadata.entity = entity
    const defectInsideMesh = this.createInsideMesh(defectMesh)
    defectMesh.metadata.meshInside = defectInsideMesh
    this.scene.removeMesh(defectInsideMesh)
    faceGroupNode.dispose()

    return defectMesh
  }

  private createInsideMesh(mesh: Mesh) {
    const defectInsideMesh = new Mesh(DEFECT_INSIDE_MESH_NAME, this.scene)
    defectInsideMesh.material = this.scene.getMaterialByName(DEFECT_INSIDE_MATERIAL)
    defectInsideMesh.parent = mesh
    defectInsideMesh.isPickable = false
    defectInsideMesh.isVisible = false
    const vd = new VertexData()
    vd.indices = mesh.getIndices()
    vd.positions = mesh.getVerticesData(VertexBuffer.PositionKind)
    vd.normals = mesh.getVerticesData(VertexBuffer.NormalKind)
    vd.applyToMesh(defectInsideMesh)

    return defectInsideMesh
  }

  private setupDefectSourceMesh(defectMesh: Mesh, mesh: AbstractMesh, itemType: SceneItemType) {
    const part = this.meshManager.getBuildPlanItemMeshByChild(mesh)
    defectMesh.material = this.scene.getMaterialByName(DEFECT_MATERIAL)
    defectMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
    defectMesh.isVisible = false
    defectMesh.registerInstancedBuffer(VertexBuffer.ColorKind, 4)

    // setup source mesh for GpuPicker
    const buffer = new Buffer(this.scene.getEngine(), new Array(defectMesh.getTotalVertices()).fill(0), false)
    const faceColor = Color3.FromInts(
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
    )
    const pickingColor = Color3.FromInts(
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
    )
    defectMesh.setVerticesBuffer(buffer.createVertexBuffer(FACE_ID_ATTRIBUTE, 0, 1))
    defectMesh.registerInstancedBuffer(COLOR_FOR_FACE, 3)
    defectMesh.instancedBuffers.fColor = faceColor
    defectMesh.registerInstancedBuffer(COLOR_FOR_PART, 3)
    defectMesh.instancedBuffers.pColor = (part.metadata as IPartMetadata).pickingColor
    defectMesh.registerInstancedBuffer(COLOR_FOR_BODY, 3)
    defectMesh.instancedBuffers.bColor = pickingColor

    const componentId = uuid()
    const geometryId = uuid()

    defectMesh.metadata = {
      componentId,
      geometryId,
      pickingColor,
      itemType,
      originalMaterial: defectMesh.material,
      pickingShader: this.renderScene.getGpuPicker().pickingShader,
      faces: [{ color: faceColor }],
    }

    this.scene.removeMesh(defectMesh)
  }

  private setupDefectInstanceMesh(defectMesh: InstancedMesh, defectNode: TransformNode, position?: Vector3) {
    defectMesh.id = uuid()
    defectMesh.parent = defectNode
    if (position) {
      defectMesh.position = position
    }

    const componentId = uuid()
    let pickingColor: Color3
    if (this.scene.metadata.componentPickingColor.has(componentId)) {
      pickingColor = this.scene.metadata.componentPickingColor.get(componentId)
    } else {
      pickingColor = Color3.FromInts(
        Math.ceil(Math.random() * 255),
        Math.ceil(Math.random() * 255),
        Math.ceil(Math.random() * 255),
      )
      this.scene.metadata.componentPickingColor.set(componentId, pickingColor)
    }

    defectMesh.instancedBuffers.color = Color3.Red()
    defectMesh.instancedBuffers.pColor = defectMesh.sourceMesh.instancedBuffers.pColor
    defectMesh.instancedBuffers.bColor = pickingColor
    defectMesh.instancedBuffers.fColor = defectMesh.sourceMesh.instancedBuffers.fColor

    defectMesh.metadata = {
      pickingColor,
      componentId,
      itemType: defectMesh.sourceMesh.metadata.itemType,
      faces: defectMesh.sourceMesh.metadata.faces,
      geometryId: defectMesh.sourceMesh.metadata.geometryId,
    }

    defectMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
    defectMesh.onDisposeObservable.addOnce(() => {
      if (!defectMesh.sourceMesh.hasInstances) {
        this.renderScene.getGpuPicker().removePickingObjects([defectMesh])
        defectMesh.sourceMesh.dispose()
      }
    })

    const insideMesh = (defectMesh.sourceMesh.metadata.meshInside as Mesh).createInstance(DEFECT_INSIDE_MESH_NAME)
    insideMesh.parent = defectMesh
  }

  private getDefectShapeMeshName(shapeType: DefectShapeType) {
    let name = DEFECT
    switch (shapeType) {
      case DefectShapeType.Point:
        name = POINT_DEFECT
        break
      case DefectShapeType.Line:
        name = LINE_DEFECT
        break
      case DefectShapeType.Loop:
        name = LOOP_DEFECT
        break
      case DefectShapeType.Face:
        name = FACE_DEFECT
        break
      case DefectShapeType.Mesh:
        name = MESH_DEFECT
        break
      default:
        break
    }

    return name
  }

  private getDefectShapeTypeByItemType(itemType: SceneItemType) {
    let shapeType: DefectShapeType
    switch (itemType) {
      case SceneItemType.PointDefect:
        shapeType = DefectShapeType.Point
        break
      case SceneItemType.LineDefect:
        shapeType = DefectShapeType.Line
        break
      case SceneItemType.LoopDefect:
        shapeType = DefectShapeType.Loop
        break
      case SceneItemType.FaceDefect:
        shapeType = DefectShapeType.Face
        break
      default:
        break
    }

    return shapeType
  }

  private getDefectItemTypeByShapeType(shapeType: DefectShapeType) {
    let itemType: SceneItemType
    switch (shapeType) {
      case DefectShapeType.Point:
        itemType = SceneItemType.PointDefect
        break
      case DefectShapeType.Line:
        itemType = SceneItemType.LineDefect
        break
      case DefectShapeType.Loop:
        itemType = SceneItemType.LoopDefect
        break
      case DefectShapeType.Face:
        itemType = SceneItemType.FaceDefect
        break
      default:
        break
    }

    return itemType
  }

  private getScaleRatio() {
    const activeCamera = this.renderScene.getActiveCamera()
    return (activeCamera.orthoRight - activeCamera.orthoLeft) / 100
  }

  private getDefectMeshes(defectType: PartDefect, indices: IHighlightableDefectIndices[]) {
    const selectedItems: ISelectableNode[] = []
    if (!indices.length) {
      const defectGroupNode = this.meshManager.getDefectGroupNodeByType(defectType)
      if (!defectGroupNode) {
        return selectedItems
      }

      defectGroupNode.getChildTransformNodes(true).map((node) => {
        node.getChildMeshes(true).map((shape) => selectedItems.push({ body: shape }))
      })
    } else {
      indices.forEach((ind) => {
        const defectNode = this.meshManager.getDefectNodeByTypeAndIndex(defectType, ind.defectIndex)
        if (!defectNode) {
          return selectedItems
        }

        const shapes = defectNode.getChildMeshes(true)
        if (isNumber(ind.shapeIndex)) {
          selectedItems.push({ body: shapes[ind.shapeIndex] })
        } else {
          defectNode.getChildMeshes(true).map((shape) => selectedItems.push({ body: shape }))
        }
      })
    }

    return selectedItems
  }

  private getDefectIndexByType(type: PartDefect) {
    let index = 0
    if (this.defectIndexByType.has(type)) {
      index = this.defectIndexByType.get(type)
      index += 1
    }

    this.defectIndexByType.set(type, index)

    return index
  }

  private updateDefectsRadius(payload: { renderScene: RenderScene; shapeTypes: DefectShapeType[] }) {
    const defectsMgr = payload.renderScene.getModelManager().defectsMgr
    const meshMgr = payload.renderScene.getMeshManager()
    const scaleRatio = defectsMgr.getScaleRatio()
    const tubeDiameter = DEFECT_TUBE_DIAMETER * scaleRatio
    const itemTypes: SceneItemType[] = []
    for (const shapeType of payload.shapeTypes) {
      itemTypes.push(defectsMgr.getDefectItemTypeByShapeType(shapeType))
    }

    payload.renderScene
      .getScene()
      .meshes.filter((m) => meshMgr.isDefectMesh(m))
      .map((defectMesh: InstancedMesh) => {
        if (meshMgr.isPointDefect(defectMesh) && itemTypes.includes(defectMesh.metadata.itemType)) {
          defectMesh.scaling.setAll(scaleRatio)
        }
        if (
          (meshMgr.isLineDefect(defectMesh) || meshMgr.isLoopDefect(defectMesh) || meshMgr.isFaceDefect(defectMesh)) &&
          itemTypes.includes(defectMesh.metadata.itemType)
        ) {
          const shapeType = defectsMgr.getDefectShapeTypeByItemType(defectMesh.metadata.itemType)
          if (shapeType === DefectShapeType.Face) {
            defectsMgr.addFaceDefect(
              defectMesh.sourceMesh.metadata.segments,
              defectMesh.parent as TransformNode,
              defectMesh.parent.parent as TransformNode,
              defectMesh.sourceMesh.metadata.entity,
              tubeDiameter,
            )
          } else {
            defectsMgr.addLineOrLoopDefect(
              shapeType,
              defectMesh.sourceMesh.metadata.positions,
              defectMesh.parent as TransformNode,
              defectMesh.parent.parent as TransformNode,
              tubeDiameter,
            )
          }
          const selectionManager = payload.renderScene.getSelectionManager()
          const newDefectMesh = defectMesh.parent.getChildMeshes(true).pop()
          selectionManager.highlight([{ body: defectMesh }], false, true)
          if (selectionManager.isSelected({ body: defectMesh })) {
            selectionManager.deselect(null, true)
            selectionManager.select([{ body: newDefectMesh }], false, true)
          }

          payload.renderScene.getGpuPicker().addPickingObjects([newDefectMesh])
          defectMesh.dispose()
        }
      })

    payload.renderScene.animate(true)
  }
}
