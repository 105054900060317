import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import LocalComputeServiceResults from '@/api/localComputeServiceResults'
import { IUser } from '@/types/User/IUser'
import { Watch } from 'vue-property-decorator'
import { SOCKET_TOOL_ACTIVATION_TIMEOUT } from '@/constants'
import { IJob } from '@/types/PartsLibrary/Job'
import { ProcessingStage } from '@/visualization/types/SimulationTypes'

const userStore = namespace(StoresNamespaces.User)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

export enum ConnectionStatus {
  Connected,
  NotConnected,
  CannotBeActivated,
}

@Component
export class VisualizationServiceMixin extends Vue {
  @userStore.Getter getUserDetails: IUser

  @visualizationStore.Action setVisualizationConnectionParams: Function
  @visualizationStore.Action fetchResults: Function

  @visualizationStore.Getter getResultsAvailable
  @visualizationStore.Getter getParaviewWebError
  @visualizationStore.Getter getResultsFetching

  @visualizationStore.Mutation setCurrentProcessing: Function
  @visualizationStore.Mutation setResultsPathData: Function
  @visualizationStore.Mutation setVisualizationServiceStarted: Function

  @buildPlansStore.Getter getSelectedBuildPlanSimulationJob: IJob
  @buildPlansStore.Getter getSelectedBuildPlanMeshJob: IJob

  private isConnected: ConnectionStatus = ConnectionStatus.NotConnected
  private resolveIsConnected: Function
  private isNotActive: boolean = false

  get serviceIsInactive() {
    return this.isNotActive
  }

  @Watch('getResultsAvailable', { immediate: true }) onResultsAvailabilityChanged(value, oldValue) {
    if (value && !oldValue) {
      this.isConnected = ConnectionStatus.Connected
    }

    if (this.resolveIsConnected) {
      this.resolveIsConnected(this.isConnected)
    }
  }

  @Watch('getParaviewWebError') async onParaviewWebError() {
    if (this.getParaviewWebError) {
      this.isNotActive = true
      setTimeout(() => {
        this.isNotActive = false
      }, SOCKET_TOOL_ACTIVATION_TIMEOUT)

      if (this.resolveIsConnected) {
        this.resolveIsConnected(ConnectionStatus.NotConnected)
      }
    }
  }

  async connect(reCheck: boolean = false) {
    if (this.isNotActive) return ConnectionStatus.CannotBeActivated

    let lcsResults
    if (this.isConnected !== ConnectionStatus.Connected) {
      this.setVisualizationServiceStarted(true)
      this.setCurrentProcessing({ stage: ProcessingStage.FetchingResults, enabled: true })
      lcsResults = await LocalComputeServiceResults.establishConnection()
    }

    const buildPlanId = this.$route.params.id
    const latestJob = this.getSelectedBuildPlanSimulationJob
    const simulationJobNumber = latestJob ? latestJob.number : undefined
    const meshJobNumber = this.getSelectedBuildPlanMeshJob ? this.getSelectedBuildPlanMeshJob.number : undefined
    this.setResultsPathData({ buildPlanId, meshJobNumber, jobNumber: simulationJobNumber })

    if (lcsResults) {
      const isConnected = new Promise<ConnectionStatus>((resolve, reject) => {
        this.resolveIsConnected = resolve
      })

      this.setVisualizationConnectionParams({
        url: lcsResults.sessionURL,
        token: await Vue.prototype.$auth.getTokenSilently(),
        tenant: this.getUserDetails && this.getUserDetails.tenant,
      })

      return isConnected
    }
    this.setCurrentProcessing({ stage: ProcessingStage.FetchingResults, enabled: false })

    if (this.isConnected === ConnectionStatus.Connected && reCheck) {
      const isConnected = new Promise<ConnectionStatus>((resolve, reject) => {
        this.resolveIsConnected = resolve
      })

      this.fetchResults()
      return isConnected
    }

    return this.isConnected
  }
}
