/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { RenderScene } from '@/visualization/render-scene'
import { IActiveToggle } from '@/visualization/infrastructure/IActiveToggle'
import { GizmoManager } from '@babylonjs/core/Gizmos/gizmoManager'
import { Scene } from '@babylonjs/core/scene'
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { Color3, Vector3 } from '@babylonjs/core/Maths'
import { GIZMO_ROTATION_TUBE_RADIUS, REGULAR_CYAN, REGULAR_ORANGE } from '@/constants'
import { MeshBuilder, Mesh, AbstractMesh } from '@babylonjs/core/Meshes'
import { RotationGizmo } from '@babylonjs/core/Gizmos/rotationGizmo'
import { PositionGizmo } from '@babylonjs/core/Gizmos/positionGizmo'
import { UtilityLayerRenderer } from '@babylonjs/core/Rendering/utilityLayerRenderer'
import { Observer } from '@babylonjs/core/Misc/observable'
import { Sprite, SpriteManager } from '@babylonjs/core/Sprites'
import { MeshManager } from './MeshManager'

export abstract class CustomGizmo {
  readonly sphereDiameter = 9

  protected gizmoManager: GizmoManager
  protected spriteManager: SpriteManager
  protected renderScene: RenderScene
  protected scene: Scene
  protected meshManager: MeshManager
  protected dragListeners: IActiveToggle[]
  protected utilitySceneObservers: Array<Observer<any>>

  protected regularOpacity = 1.0
  protected movementOpacity = 0.2
  protected spriteSize = 10
  protected updateScale = false
  protected isInDraggingState = false
  protected isVisible: boolean

  protected regularMaterial: StandardMaterial
  protected regularPlaneMaterial: StandardMaterial
  protected regularGizmoMaterial: StandardMaterial
  protected highlightGizmoMaterial: StandardMaterial
  protected disablePlaneMaterial: StandardMaterial
  protected disableGizmoMaterial: StandardMaterial

  protected meshNames = {
    xAxis: 'xAxis',
    yAxis: 'yAxis',
    zAxis: 'zAxis',
  }

  constructor(renderScene: RenderScene, dragListeners?: IActiveToggle[]) {
    this.renderScene = renderScene
    this.scene = renderScene.getScene()
    this.meshManager = renderScene.getMeshManager()
    this.dragListeners = dragListeners ? dragListeners : []
    // by default used UtilityLayerRenderer.DefaultUtilityLayer that takes last created scene
    this.gizmoManager = new GizmoManager(this.scene, 1, new UtilityLayerRenderer(this.scene))
    this.spriteManager = new SpriteManager(
      'spriteManager',
      '/mdi-lock-icon.png',
      6,
      { width: 48, height: 48 },
      this.gizmoManager.utilityLayer.utilityLayerScene,
    )
    this.isVisible = true
    this.utilitySceneObservers = []

    this.regularMaterial = new StandardMaterial('regularMaterial', this.gizmoManager.utilityLayer.utilityLayerScene)
    this.regularMaterial.diffuseColor = new Color3(0, 0.37, 0.72)
    this.regularMaterial.specularColor = new Color3(0.2, 0.2, 0.2)
    this.regularMaterial.alpha = this.regularOpacity
    this.regularPlaneMaterial = new StandardMaterial(
      'regularPlaneMaterial',
      this.gizmoManager.utilityLayer.utilityLayerScene,
    )
    this.regularPlaneMaterial.diffuseColor = new Color3(0.69, 0.7, 0.7)
    this.regularPlaneMaterial.alpha = this.regularOpacity / 1.7
    this.regularGizmoMaterial = new StandardMaterial(
      'regularGizmoMaterial',
      this.gizmoManager.utilityLayer.utilityLayerScene,
    )
    this.regularGizmoMaterial.diffuseColor = REGULAR_ORANGE
    this.regularGizmoMaterial.specularColor = new Color3(0.3, 0.3, 0.3)
    this.highlightGizmoMaterial = new StandardMaterial(
      'highlightGizmoMaterial',
      this.gizmoManager.utilityLayer.utilityLayerScene,
    )
    this.highlightGizmoMaterial.diffuseColor = REGULAR_CYAN
    this.highlightGizmoMaterial.specularColor = new Color3(0.3, 0.3, 0.3)
    this.disableGizmoMaterial = this.regularGizmoMaterial.clone('disableGizmoMaterial')
    this.disableGizmoMaterial.alpha = this.movementOpacity
    this.disablePlaneMaterial = this.regularPlaneMaterial.clone('disablePlaneMaterial')
    this.disablePlaneMaterial.alpha = this.movementOpacity / 1.7
    this.gizmoManager.utilityLayer._getSharedGizmoLight().intensity = 1.5
    this.gizmoManager.utilityLayer._getSharedGizmoLight().direction = new Vector3(0, 0, 1)

    this.gizmoManager.utilityLayer.utilityLayerScene.onNewMeshAddedObservable.add((mesh) => {
      if (mesh.name === 'rotationCircle') {
        mesh.isVisible = false
      }
    })
  }

  abstract show(selected: AbstractMesh)
  abstract hide(silent?: boolean)

  get rotationGizmoScaleRatio() {
    return this.gizmoManager.gizmos.rotationGizmo.scaleRatio
  }

  getGizmoMeshByName(name: string) {
    return this.gizmoManager.utilityLayer.utilityLayerScene.getMeshByName(name)
  }

  getSphereMeshes() {
    return this.gizmoManager.utilityLayer.utilityLayerScene.meshes.filter((m) => m.name.includes('SphereMesh'))
  }

  setGizmoVisibility(isVisible: boolean) {
    this.isVisible = isVisible
    this.spriteManager.sprites.map((sprite) => (sprite.isVisible = isVisible))
  }

  updateGizmoScale() {
    if (this.gizmoManager.gizmos.positionGizmo) {
      this.gizmoManager.gizmos.positionGizmo.scaleRatio = this.computeGizmoScaleRatio()
    }
    if (this.gizmoManager.gizmos.rotationGizmo) {
      this.gizmoManager.gizmos.rotationGizmo.scaleRatio = this.computeGizmoScaleRatio()
    }
    this.gizmoManager.utilityLayer.utilityLayerScene.render()
  }

  dispose() {
    this.clearPositionGizmosEvents()
    this.clearRotationGizmosEvents()
    this.clearUtilitySceneObservers()
    this.gizmoManager.dispose()
    this.spriteManager.dispose()
    this.regularMaterial.dispose()
    this.regularPlaneMaterial.dispose()
    this.regularGizmoMaterial.dispose()
    this.highlightGizmoMaterial.dispose()
    this.disablePlaneMaterial.dispose()
    this.disableGizmoMaterial.dispose()
  }

  protected abstract computeGizmoScaleRatio()

  protected createCustomAxisDragGizmo(axisName: string) {
    const utilityLayerScene = this.gizmoManager.utilityLayer.utilityLayerScene
    const axisMesh = MeshBuilder.CreateCylinder(`${axisName}Mesh`, { diameter: 2.5, height: 46 }, utilityLayerScene)
    axisMesh.material = this.regularGizmoMaterial
    const sphereMesh = MeshBuilder.CreateSphere(
      `${axisName}SphereMesh`,
      { diameter: this.sphereDiameter },
      utilityLayerScene,
    )
    sphereMesh.material = this.regularGizmoMaterial
    switch (axisName) {
      case this.meshNames.xAxis:
        axisMesh.position.x = 23
        sphereMesh.position.x = 50
        axisMesh.rotation.z = -Math.PI / 2
        break
      case this.meshNames.yAxis:
        axisMesh.position.y = 23
        sphereMesh.position.y = 50
        break
      case this.meshNames.zAxis:
        axisMesh.position.z = 23
        sphereMesh.position.z = 50
        axisMesh.rotation.x = Math.PI / 2
        break
    }

    const customGizmo = new Mesh(`${axisName}DragMesh`, utilityLayerScene)
    axisMesh.parent = customGizmo
    sphereMesh.parent = customGizmo
    const lockSprite = new Sprite(`${axisName}LockSprite`, this.spriteManager)
    lockSprite.width = lockSprite.height = this.spriteSize * this.gizmoManager.gizmos.positionGizmo.scaleRatio
    lockSprite.position = Vector3.TransformCoordinates(sphereMesh.position.clone(), sphereMesh.getWorldMatrix())
    lockSprite.isPickable = false
    lockSprite.isVisible = false
    this.utilitySceneObservers.push(
      utilityLayerScene.onPointerObservable.add((pointerInfo) => {
        // don't update hover states and materials when a gizmo is being dragged
        if (!customGizmo.isPickable || this.isInDraggingState) return
        const isHovered =
          pointerInfo.pickInfo &&
          pointerInfo.pickInfo.pickedMesh &&
          pointerInfo.pickInfo.pickedMesh.parent &&
          pointerInfo.pickInfo.pickedMesh.parent.name === `${axisName}DragMesh`
        const material = isHovered ? this.highlightGizmoMaterial : this.regularGizmoMaterial
        customGizmo.getChildMeshes().forEach((m) => {
          m.material = material
        })
      }),
      utilityLayerScene.onBeforeRenderObservable.add(() => {
        lockSprite.width = lockSprite.height = this.spriteSize * this.gizmoManager.gizmos.positionGizmo.scaleRatio
        lockSprite.position = Vector3.TransformCoordinates(
          sphereMesh.position.clone().scale(1.4),
          customGizmo.getWorldMatrix(),
        )
      }),
    )

    return customGizmo
  }

  protected createCustomAxisRotationGizmo(axisName: string) {
    const utilityLayerScene = this.gizmoManager.utilityLayer.utilityLayerScene
    const arcPath = []
    const helperArcPath = []
    const numSegments = 10
    const arrowMesh1 = MeshBuilder.CreateCylinder(
      `${axisName}PlaneRotationHelperMesh1`,
      { diameterBottom: 9, diameterTop: 0, height: 9 },
      utilityLayerScene,
    )
    const arrowMesh2 = MeshBuilder.CreateCylinder(
      `${axisName}PlaneRotationHelperMesh2`,
      { diameterBottom: 9, diameterTop: 0, height: 9 },
      utilityLayerScene,
    )
    switch (axisName) {
      case this.meshNames.xAxis:
        for (let i = 0; i <= numSegments; i = i + 1) {
          const angle = (Math.PI / 2 / numSegments) * i
          arcPath.push(
            new Vector3(0, Math.cos(angle) * GIZMO_ROTATION_TUBE_RADIUS, Math.sin(angle) * GIZMO_ROTATION_TUBE_RADIUS),
          )
          if (i > 1 && i < numSegments - 1) {
            helperArcPath.push(
              new Vector3(
                0,
                Math.cos(angle) * GIZMO_ROTATION_TUBE_RADIUS,
                Math.sin(angle) * GIZMO_ROTATION_TUBE_RADIUS,
              ),
            )
          }
        }
        arrowMesh1.position = new Vector3(0, 31, 5)
        arrowMesh1.rotation.x = -Math.PI / 2
        arrowMesh2.position = new Vector3(0, 5, 31)
        arrowMesh2.rotation.x = Math.PI
        break
      case this.meshNames.yAxis:
        for (let i = 0; i <= numSegments; i = i + 1) {
          const angle = (Math.PI / 2 / numSegments) * i
          arcPath.push(
            new Vector3(Math.cos(angle) * GIZMO_ROTATION_TUBE_RADIUS, 0, Math.sin(angle) * GIZMO_ROTATION_TUBE_RADIUS),
          )
          if (i > 1 && i < numSegments - 1) {
            helperArcPath.push(
              new Vector3(
                Math.cos(angle) * GIZMO_ROTATION_TUBE_RADIUS,
                0,
                Math.sin(angle) * GIZMO_ROTATION_TUBE_RADIUS,
              ),
            )
          }
        }
        arrowMesh1.position = new Vector3(31, 0, 5)
        arrowMesh1.rotation.x = -Math.PI / 2
        arrowMesh2.position = new Vector3(5, 0, 31)
        arrowMesh2.rotation.z = Math.PI / 2
        break
      case this.meshNames.zAxis:
        for (let i = 0; i <= numSegments; i = i + 1) {
          const angle = (Math.PI / 2 / numSegments) * i
          arcPath.push(
            new Vector3(Math.cos(angle) * GIZMO_ROTATION_TUBE_RADIUS, Math.sin(angle) * GIZMO_ROTATION_TUBE_RADIUS, 0),
          )
          if (i > 1 && i < numSegments - 1) {
            helperArcPath.push(
              new Vector3(
                Math.cos(angle) * GIZMO_ROTATION_TUBE_RADIUS,
                Math.sin(angle) * GIZMO_ROTATION_TUBE_RADIUS,
                0,
              ),
            )
          }
        }
        arrowMesh1.position = new Vector3(5, 31, 0)
        arrowMesh1.rotation.z = Math.PI / 2
        arrowMesh2.position = new Vector3(31, 5, 0)
        arrowMesh2.rotation.z = Math.PI
        break
    }
    const arcMesh = MeshBuilder.CreateTube(
      `${axisName}PlaneRotationMesh`,
      { radius: 1.2, path: arcPath },
      utilityLayerScene,
    )
    arcMesh.material = this.regularGizmoMaterial
    const helperArcMesh = MeshBuilder.CreateTube(
      `${axisName}PlaneRotationHelperMesh3`,
      { radius: 1.2, path: helperArcPath },
      utilityLayerScene,
    )
    helperArcMesh.material = this.highlightGizmoMaterial
    arrowMesh1.material = this.highlightGizmoMaterial
    arrowMesh2.material = this.highlightGizmoMaterial
    helperArcMesh.parent = arrowMesh1.parent = arrowMesh2.parent = arcMesh
    helperArcMesh.visibility = arrowMesh1.visibility = arrowMesh2.visibility = 0
    const lockSprite = new Sprite(`${axisName}RotationLockSprite`, this.spriteManager)
    lockSprite.width = lockSprite.height = this.spriteSize * this.gizmoManager.gizmos.positionGizmo.scaleRatio
    lockSprite.position = Vector3.TransformCoordinates(arcMesh.position.clone(), arcMesh.getWorldMatrix())
    lockSprite.isPickable = false
    lockSprite.isVisible = false
    this.utilitySceneObservers.push(
      utilityLayerScene.onPointerObservable.add((pointerInfo) => {
        // don't update hover states and materials when a gizmo is being dragged
        if (!arcMesh.isPickable || this.isInDraggingState) return
        const isHovered =
          pointerInfo.pickInfo &&
          pointerInfo.pickInfo.pickedMesh &&
          [
            `${axisName}PlaneRotationMesh`,
            `${axisName}PlaneRotationHelperMesh1`,
            `${axisName}PlaneRotationHelperMesh2`,
            `${axisName}PlaneRotationHelperMesh3`,
          ].indexOf(pointerInfo.pickInfo.pickedMesh.name) > -1
        arcMesh.visibility = isHovered ? 0 : 1
        // visibilize helper meshes if dragging and hide original mesh
        helperArcMesh.visibility = arrowMesh1.visibility = arrowMesh2.visibility = isHovered ? 1 : 0
      }),
      utilityLayerScene.onBeforeRenderObservable.add(() => {
        const localSpritePosition = arrowMesh1.position.clone().add(arrowMesh2.position.clone())
        lockSprite.width = lockSprite.height = this.spriteSize * this.gizmoManager.gizmos.positionGizmo.scaleRatio
        lockSprite.position = Vector3.TransformCoordinates(localSpritePosition, arcMesh.getWorldMatrix())
      }),
    )

    return arcMesh
  }

  protected createCustomPlaneDragGizmo(axisName: string) {
    const utilityLayerScene = this.gizmoManager.utilityLayer.utilityLayerScene
    const discMesh = MeshBuilder.CreateDisc(
      `${axisName}PlaneDragMesh`,
      { radius: 32, tessellation: 64, arc: 0.25, sideOrientation: Mesh.DOUBLESIDE },
      utilityLayerScene,
    )
    discMesh.material = this.regularPlaneMaterial
    discMesh.position = new Vector3(0, 0, 0)
    switch (axisName) {
      case this.meshNames.xAxis:
        discMesh.rotation.y = -Math.PI / 2
        break
      case this.meshNames.yAxis:
        discMesh.rotation.x = Math.PI / 2
        break
    }
    this.utilitySceneObservers.push(
      utilityLayerScene.onPointerObservable.add((pointerInfo) => {
        // don't update hover states and materials when a gizmo is being dragged
        if (!discMesh.isPickable || this.isInDraggingState) return
        const isHovered =
          pointerInfo.pickInfo &&
          pointerInfo.pickInfo.pickedMesh &&
          pointerInfo.pickInfo.pickedMesh.name === `${axisName}PlaneDragMesh`
        const material = isHovered ? this.highlightGizmoMaterial : this.regularPlaneMaterial
        discMesh.material = material
        if (isHovered) {
          const complementaryAxisDragMeshes = Object.values(this.meshNames).filter((value) => value !== axisName)
          complementaryAxisDragMeshes.map((name) => {
            utilityLayerScene
              .getMeshByName(`${name}DragMesh`)
              .getChildMeshes()
              .forEach((m) => {
                m.material = this.highlightGizmoMaterial
              })
          })
        }
      }),
    )

    return discMesh
  }

  protected setCustomRotationGizmo(rotationGizmo: RotationGizmo) {
    // create custom gizmo meshes
    rotationGizmo.xGizmo.setCustomMesh(this.createCustomAxisRotationGizmo(this.meshNames.xAxis))
    rotationGizmo.yGizmo.setCustomMesh(this.createCustomAxisRotationGizmo(this.meshNames.yAxis))
    rotationGizmo.zGizmo.setCustomMesh(this.createCustomAxisRotationGizmo(this.meshNames.zAxis))

    rotationGizmo.xGizmo.updateScale = this.updateScale
    rotationGizmo.yGizmo.updateScale = this.updateScale
    rotationGizmo.zGizmo.updateScale = this.updateScale
  }

  protected setCustomDragGizmo(positionGizmo: PositionGizmo) {
    // create custom gizmo meshes
    positionGizmo.xGizmo.setCustomMesh(this.createCustomAxisDragGizmo(this.meshNames.xAxis))
    positionGizmo.yGizmo.setCustomMesh(this.createCustomAxisDragGizmo(this.meshNames.yAxis))
    positionGizmo.zGizmo.setCustomMesh(this.createCustomAxisDragGizmo(this.meshNames.zAxis))
    positionGizmo.xPlaneGizmo.setCustomMesh(this.createCustomPlaneDragGizmo(this.meshNames.xAxis))
    positionGizmo.yPlaneGizmo.setCustomMesh(this.createCustomPlaneDragGizmo(this.meshNames.yAxis))
    positionGizmo.zPlaneGizmo.setCustomMesh(this.createCustomPlaneDragGizmo(this.meshNames.zAxis))

    positionGizmo.xGizmo.updateScale = this.updateScale
    positionGizmo.yGizmo.updateScale = this.updateScale
    positionGizmo.zGizmo.updateScale = this.updateScale
    positionGizmo.xPlaneGizmo.updateScale = this.updateScale
    positionGizmo.yPlaneGizmo.updateScale = this.updateScale
    positionGizmo.zPlaneGizmo.updateScale = this.updateScale
  }

  protected setMaterialOpacity(alpha: number) {
    this.regularGizmoMaterial.alpha = alpha
    this.regularPlaneMaterial.alpha = alpha / 1.7
  }

  protected disableTranslationGizmos() {
    if (this.gizmoManager.positionGizmoEnabled) {
      this.gizmoManager.positionGizmoEnabled = false
    }
  }

  protected disableRotationGizmos() {
    if (this.gizmoManager.rotationGizmoEnabled) {
      this.gizmoManager.rotationGizmoEnabled = false
    }
  }

  protected clearRotationGizmosEvents() {
    if (this.gizmoManager.gizmos.rotationGizmo) {
      this.gizmoManager.gizmos.rotationGizmo.onDragEndObservable.clear()
      this.gizmoManager.gizmos.rotationGizmo.onDragStartObservable.clear()
    }
  }

  protected clearPositionGizmosEvents() {
    if (this.gizmoManager.gizmos.positionGizmo) {
      this.gizmoManager.gizmos.positionGizmo.onDragEndObservable.clear()
      this.gizmoManager.gizmos.positionGizmo.onDragStartObservable.clear()
    }
  }

  protected clearUtilitySceneObservers() {
    this.utilitySceneObservers.forEach((observer) =>
      this.gizmoManager.utilityLayer.utilityLayerScene.onPointerObservable.remove(observer),
    )

    this.utilitySceneObservers = []
  }
}
