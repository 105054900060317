import StoresNamespaces from '@/store/namespaces'
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { ActiveDynamicElementDialogInfo, TextElement } from '@/types/Label/TextElement'
import { InteractiveLabelSetNamedList } from '@/types/Label/InteractiveLabelSetNamedList'
import { IAnnouncement } from '@/types/Announcement/IAnnouncement'

const labelStore = namespace(StoresNamespaces.Labels)
const announcementsStore = namespace(StoresNamespaces.Announcements)

export const HEADER_HEIGHT = 54 // px
export const ANNOUNCEMENT_HEIGHT = 30 // px
const TRIANGLE_OFFSET_FROM_TOP = 15 // px
const TOOLTIP_OFFSET_FROM_BOTTOM = 40 // px

@Component
export class LabelTooltipMixin extends Vue {
  @labelStore.Getter labelSets: InteractiveLabelSet[]
  @labelStore.Getter namedList: (additionalLabelSets?: InteractiveLabelSet[]) => InteractiveLabelSetNamedList[]
  @labelStore.Getter activeLabelSet: InteractiveLabelSet
  @labelStore.Getter getActiveDynamicElementDialogInfo: ActiveDynamicElementDialogInfo

  @announcementsStore.Getter('getAnnouncement') announcement: IAnnouncement

  usedIn(element: TextElement, excludeCurrent: boolean = false): string {
    const usedIn: string[] = []
    if (element) {
      const labelSets = excludeCurrent
        ? this.labelSets.filter((ls: InteractiveLabelSet) => ls.id !== this.activeLabelSet.id)
        : this.labelSets
      labelSets.forEach((ls: InteractiveLabelSet) => {
        const index = ls.settings.textElements.findIndex(
          (el: TextElement) => el.elementIDNumber === element.elementIDNumber,
        )
        if (index >= 0) {
          const neededLabelSet = this.labelSets.find((namedSet) => namedSet.id === ls.id)
          if (neededLabelSet && neededLabelSet.settings) {
            usedIn.push(neededLabelSet.settings.labelSetName)
          }
        }
      })
    }
    return usedIn.join(', ')
  }

  /** Sets left value for dynamic element dialog based on a given value or dialog size.
   * @param {number} left - already calculated left position of a dialog
   * @param {Element} dialog - dialog HTML element
   */
  setLeft(left: number, dialog: Element) {
    const triangleLeft = 0
    let leftOffset: number
    if (this.getActiveDynamicElementDialogInfo.textElement) {
      leftOffset = left
      return { leftOffset, triangleLeft }
    }
    const dialogWidth = dialog && dialog.getBoundingClientRect().width
    const { innerWidth } = window
    leftOffset = innerWidth / 2 - dialogWidth / 2
    return { leftOffset, triangleLeft }
  }

  /** Sets left value for dynamic element dialog based on a given value or dialog size.
   * @param {number} top - already calculated left position of a dialog
   * @param {Element} dialog - dialog HTML element
   */
  setTop(top: number, dialog: Element) {
    let topOffset: number
    let triangleTop: number
    const { height } = dialog && dialog.getBoundingClientRect()
    const announcementHeight = this.announcement ? ANNOUNCEMENT_HEIGHT : 0
    if (this.getActiveDynamicElementDialogInfo.textElement) {
      if (height + top + HEADER_HEIGHT > window.innerHeight) {
        topOffset = window.innerHeight - height - TOOLTIP_OFFSET_FROM_BOTTOM - HEADER_HEIGHT
        triangleTop = top - topOffset + TRIANGLE_OFFSET_FROM_TOP - announcementHeight
      } else {
        triangleTop = TRIANGLE_OFFSET_FROM_TOP
        topOffset = top - announcementHeight
      }
    } else {
      const { innerHeight } = window
      triangleTop = 0
      topOffset = innerHeight / 2 - height / 2
    }
    return { topOffset, triangleTop }
  }
}
