
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import VTextField from 'vuetify/lib/components/VTextField'

@Component
export default class TextField extends Vue {
  @Prop() suffix!: string
  @Prop({ default: false }) disabled: boolean
  @Prop() placeholder!: string
  @Prop() value: string
  @Prop({ type: [Object, String], default: '' }) readonly rules: object | string
  @Prop() height: string
  @Prop() label: string
  @Prop({
    default: () => {
      return () => ({ on: ['input'] })
    },
  })
  readonly validationMode: any

  $refs!: {
    textField: VTextField
  }

  // @ts-ignore
  model: string = this.value ? String(this.value) : ''

  input(e) {
    this.$emit('input', e)
  }

  focus() {
    this.$refs.textField.focus()
  }

  selectAll() {
    this.$refs.textField.$el.querySelector('input').select()
  }

  @Watch('value')
  onValueChanged(val) {
    this.model = val ? String(val) : ''
  }
}
