
import { Component, Vue, Watch } from 'vue-property-decorator'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'
import { debounce } from '@/utils/debounce'
import { Unit, getUnitTolerance, getUnitLabel } from '@/types/Simulation/Units'
import { SimulationColoringModel } from '@/types/Simulation/SimulationColoringModel'
import { equalWithTolerance } from '@/utils/number'
import Icon from '@/components/icons/Icon.vue'
import { IRangeInfo } from '@/visualization/types/Common'

const visualizationStore = namespace(StoresNamespaces.Visualization)
const commonStore = namespace(StoresNamespaces.Common)

@Component({ components: { Icon } })
export default class XRayWidget extends Vue {
  @commonStore.Getter tooltipOpenDelay: number

  @visualizationStore.Getter legend: { rangePoints: number[]; colors: number[][] }
  @visualizationStore.Getter('getDataRange') getDataRange: IRangeInfo
  @visualizationStore.Getter('getVisualizationLoading') getVisualizationLoading: boolean
  @visualizationStore.Getter('coloringModel') coloringModel: SimulationColoringModel

  @visualizationStore.Mutation('setAllStepsColorRange') setAllStepsColorRange: Function
  @visualizationStore.Mutation('setXrayView') setXrayView: Function

  range = []
  updateMinDebounced = debounce(1000, this.updateMin)
  updateMaxDebounced = debounce(1000, this.updateMax)
  sliderTolerance: number = 0
  minInput: number = 0
  maxInput: number = 0
  allStepsRange: boolean = true
  private minFixed: number = 0
  private maxFloored: number = 0

  resetMin() {
    this.$set(this.range, 0, this.rangeMin)
    this.updateRange()
  }

  resetMax() {
    this.$set(this.range, 1, this.rangeMax)
    this.updateRange()
  }

  @Watch('getDataRange') onGetDataRange() {
    this.sliderTolerance = Math.pow(10, getUnitTolerance(this.unit))
    if (this.getDataRange.xray) {
      this.range = [this.getDataRange.xray.min, this.getDataRange.xray.max]
      this.minFixed = this.getFixedValue(this.rangeMin)
      this.maxFloored = this.getFlooredValue(this.rangeMax)
    } else {
      this.range = [this.rangeMin, this.rangeMax]
      this.minFixed = this.minCurrent
      this.maxFloored = this.maxCurrent
    }

    this.updateThumbsPosition()
    this.$nextTick(() => this.onLegendChanged())
  }

  @Watch('range') onRange() {
    this.minInput = this.minCurrent
    this.maxInput = this.maxCurrent
  }

  mounted() {
    this.onGetDataRange()
  }

  onLegendChanged() {
    if (!this.legend.colors) return

    const slider = document.getElementById('slider-container')
    if (!slider) return

    const collection = slider.getElementsByClassName('v-slider__track-fill') as HTMLCollection
    if (collection.length === 0) return

    const fill = collection[0] as HTMLDivElement
    if (fill) {
      fill.style.background = this.generateGradient()
    }
  }

  getLabelValue(point: number) {
    if (Math.abs(this.rangeMin - point) < Math.pow(10, getUnitTolerance(this.unit))) {
      return this.minFixed
    }

    if (Math.abs(this.rangeMax - point) < Math.pow(10, getUnitTolerance(this.unit))) {
      return this.maxFloored
    }

    return +point.toFixed(Math.abs(getUnitTolerance(this.unit)))
  }

  get unitLabel() {
    return getUnitLabel(this.unit)
  }

  get unit(): Unit {
    return this.coloringModel.selectedView ? this.coloringModel.selectedView.unit : Unit.Undefined
  }

  get xRayEnabled() {
    return this.range.length !== 0 && Math.abs(this.rangeMin - this.rangeMax) > this.sliderTolerance
  }

  get resetMinVisible() {
    return this.xRayEnabled ? this.resetVisible(Math.abs(this.minCurrent - this.minFixed)) : false
  }

  get resetMaxVisible() {
    return this.xRayEnabled ? this.resetVisible(Math.abs(this.maxCurrent - this.maxFloored)) : false
  }

  get minCurrent() {
    return this.getFixedValue(this.range[0])
  }

  get maxCurrent() {
    return this.getFlooredValue(this.range[1])
  }

  get allStepsActive() {
    return this.allStepsRange
  }

  setCurrentStepRange(allSteps: boolean) {
    if (this.allStepsRange === allSteps) return

    this.allStepsRange = allSteps
    this.setAllStepsColorRange(this.allStepsRange)
  }

  updateRange() {
    let min = this.range[0]
    let max = this.range[1]

    if (equalWithTolerance(this.range[0], this.minFixed, Number.EPSILON)) {
      min = this.rangeMin
    }

    if (equalWithTolerance(this.range[1], this.maxFloored, Number.EPSILON)) {
      max = this.rangeMax
    }

    this.setXrayView({ min, max })
    this.updateThumbsPosition()
  }

  updateMin(args: number) {
    if (args < this.minFixed || args > this.maxCurrent || equalWithTolerance(args, this.maxCurrent, Number.EPSILON)) {
      this.$set(this.range, 0, this.range[0])
      return
    }

    this.$set(this.range, 0, Number(args))
    this.updateRange()
  }

  updateMax(args: number) {
    if (args > this.maxFloored || args < this.minCurrent || equalWithTolerance(args, this.minCurrent, Number.EPSILON)) {
      this.$set(this.range, 1, this.range[1])
      return
    }

    this.$set(this.range, 1, Number(args))
    this.updateRange()
  }

  updateThumbsPosition() {
    const minThumb: HTMLDivElement = document.getElementById('minThumb') as HTMLDivElement
    if (minThumb) {
      minThumb.style.top = `${100 - this.getBoundRelative(0)}%`
    }

    const maxThumb: HTMLDivElement = document.getElementById('maxThumb') as HTMLDivElement
    if (maxThumb) {
      maxThumb.style.top = `calc(${100 - this.getBoundRelative(1)}% - 50px)`
    }
  }

  get rangeMax() {
    return this.rangeProperty('max') || 0
  }

  get rangeMin() {
    return this.rangeProperty('min') || 0
  }

  private getFlooredValue(value: number) {
    const tolerance = Math.abs(getUnitTolerance(this.unit))
    return this.getFlooredFixed(value, tolerance)
  }

  private getFixedValue(value: number) {
    const tolerance = Math.abs(getUnitTolerance(this.unit))
    return +value.toFixed(tolerance)
  }

  private rangeProperty(prop: string) {
    return this.allStepsActive
      ? (this.getDataRange.allSteps && this.getDataRange.allSteps[prop]) || undefined
      : (this.getDataRange.currentStep && this.getDataRange.currentStep[prop]) || undefined
  }

  private resetVisible(diff: number) {
    const tolerance = Math.pow(10, getUnitTolerance(this.unit))
    return diff > tolerance || equalWithTolerance(diff, tolerance, Number.EPSILON)
  }

  private getFlooredFixed(v, d) {
    return +(Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d)
  }

  private getBoundRelative(index: number): number {
    const referenceRange = this.maxFloored - this.minFixed
    return Math.abs(Math.round(((this.range[index] - this.minFixed) / referenceRange) * 100))
  }

  private generateGradient() {
    const colors = []
    this.legend.colors.forEach((color) => {
      colors.push(`rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`)
    })
    return `linear-gradient(${colors.join(',')})`
  }
}
