/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Scene } from '@babylonjs/core/scene'
import { Vector2 } from '@babylonjs/core/Maths'

export class SelectionRectangle {
  private boxElement: HTMLDivElement
  private startPosition = Vector2.Zero()
  private topLeftPosition = Vector2.Zero()
  private bottomRightPosition = Vector2.Zero()
  private ltrProperties = {
    backgroundColor: '#55aaff7f',
    borderStyle: 'solid',
    borderColor: '#06a0ff',
  }
  private rtlProperties = {
    backgroundColor: '#ff652470',
    borderStyle: 'dashed',
    borderColor: '#ff6524',
  }

  constructor(private scene: Scene) {
    this.boxElement = document.createElement('div')
    this.boxElement.className = 'selectionBox'

    this.boxElement.style.position = 'absolute'
    this.boxElement.style.top = '0px'
    this.boxElement.style.left = '0px'
    this.boxElement.style.height = '0px'
    this.boxElement.style.width = '0px'
    this.boxElement.style.borderWidth = '1px'
    this.boxElement.style.backgroundColor = this.ltrProperties.backgroundColor
    this.boxElement.style.borderStyle = this.ltrProperties.borderStyle
    this.boxElement.style.borderColor = this.ltrProperties.borderColor
  }

  public show(startPosition: Vector2, endPosition: Vector2) {
    this.scene.getEngine().getRenderingCanvas().parentElement.appendChild(this.boxElement)

    this.startPosition = startPosition.clone()
    this.update(endPosition)
  }

  public update(endPosition: Vector2) {
    this.bottomRightPosition.x = Math.max(this.startPosition.x, endPosition.x)
    this.bottomRightPosition.y = Math.max(this.startPosition.y, endPosition.y)
    this.topLeftPosition.x = Math.min(this.startPosition.x, endPosition.x)
    this.topLeftPosition.y = Math.min(this.startPosition.y, endPosition.y)

    const propertiesObject = this.startPosition.x < endPosition.x ? this.ltrProperties : this.rtlProperties
    this.boxElement.style.backgroundColor = propertiesObject.backgroundColor
    this.boxElement.style.borderStyle = propertiesObject.borderStyle
    this.boxElement.style.borderColor = propertiesObject.borderColor
    this.boxElement.style.left = `${this.topLeftPosition.x}px`
    this.boxElement.style.top = `${this.topLeftPosition.y}px`

    this.boxElement.style.width = `${this.bottomRightPosition.x - this.topLeftPosition.x}px`
    this.boxElement.style.height = `${this.bottomRightPosition.y - this.topLeftPosition.y}px`
  }

  public hide() {
    if (this.boxElement.parentElement) {
      this.boxElement.parentElement.removeChild(this.boxElement)
    }
  }
}
