import { GetterTree } from 'vuex'
import { ICommonState } from './types'
import { IRootState } from '@/store/types'
import { IMachineConfig } from '@/types/IMachineConfig'
import { TOOLTIP_OPEN_DELAY } from '@/constants'

export const getters: GetterTree<ICommonState, IRootState> = {
  selectedLocaleTitle(state): string {
    return state.selectedLocale && state.selectedLocale.title
  },

  getConnectedMachineConfigs(state): IMachineConfig[] {
    return state && state.connectedMachineConfigs
  },

  tooltipOpenDelay(state: ICommonState): number {
    return TOOLTIP_OPEN_DELAY
  },

  isDisconnected(state: ICommonState): boolean {
    return state.isDisconnected
  },
}
