
import { Prop, Mixins, Component, Vue, Watch } from 'vue-property-decorator'
import { InsightMixin } from '@/components/layout/buildPlans/mixins/InsightMixin'
import { IBuildPlanInsight, InsightErrorCodes } from '@/types/BuildPlans/IBuildPlanInsight'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { ContentViewModeTypes } from '@/visualization/types/ContentViewMode'
import { RouterNames } from '@/router'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { DisableableInsightMixin } from '@/components/layout/buildPlans/mixins/DisableableInsightMixin'
import { GeometryAvailability } from '@/visualization/types/Common'
import i18n from '@/plugins/i18n'
import messageService from '@/services/messageService'

const visualizationStore = namespace(StoresNamespaces.Visualization)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component
export default class BridgingIssueByInsight extends Mixins(Vue, InsightMixin, DisableableInsightMixin) {
  @visualizationStore.Getter('getBridgingElementsLoaded') getBridgingElementsLoaded: boolean
  @visualizationStore.Getter('getNominalGeometryAvailable') getNominalGeometryAvailable: GeometryAvailability
  @visualizationStore.Getter('viewMode') viewMode

  @visualizationStore.Mutation('setIsLoading') setIsLoading: Function
  @visualizationStore.Mutation('showBridgingElements') showBridgingElements: Function

  @buildPlansStore.Getter('getIsLoading') buildPlanLoading: boolean
  @buildPlansStore.Mutation setContentViewMode: Function

  @Prop() insight: IBuildPlanInsight

  private openingHandler
  private itemIsLoading: boolean = false

  openSimulationTool() {
    if (this.itemIsLoading || this.getVisualizationLoading || this.buildPlanLoading || this.isSimulationToolDisabled) {
      return
    }

    this.$watch('getBridgingElementsLoaded', () => {
      if (this.getBridgingElementsLoaded) {
        this.itemIsLoading = false
      }
    })

    if (this.viewMode.name !== ViewModeTypes.SimulationCompensation) {
      this.setIsLoading(true)
      this.itemIsLoading = true
      // @ts-ignore
      this.$router.safePush({ name: RouterNames.EBP_SimulateCompensate })
      this.openingHandler = this.$watch('getNominalGeometryAvailable', () => {
        if (this.getNominalGeometryAvailable === GeometryAvailability.Available) {
          this.setContentViewMode(ContentViewModeTypes.Visualization)
          this.$nextTick(this.showBridgingElements())
        } else {
          this.setIsLoading(false)
          messageService.showWarningMessage(i18n.t('bridgingCantBeShown').toString())
        }

        this.openingHandler()
      })
    } else {
      this.setContentViewMode(ContentViewModeTypes.Visualization)
      this.$nextTick(this.showBridgingElements())
    }
  }

  get isSimulationToolDisabled() {
    return this.$route.name === RouterNames.PreviewPrintOrder
  }
}
