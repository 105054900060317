
import Vue from 'vue'
import Component from 'vue-class-component'
import { Action, namespace } from 'vuex-class'
import AppHeader from '@/components/layout/AppHeader.vue'
import Snackbar from '@/components/layout/Snackbar.vue'
import AppFooter from '@/components/layout/AppFooter.vue'
import LostConnectionToast from '@/components/layout/LostConnectionToast.vue'
import StoresNamespaces from '@/store/namespaces'
import { ROUTER_PERMISSIONS, RouterPaths } from '@/router'
import { initHttpModule } from '@/api/http'
import { isAuthorized } from '@/utils/user'
import { IUser } from '@/types/User/IUser'
import { Watch } from 'vue-property-decorator'
import CommunicationService from '@/services/CommunicationService'

const userStore = namespace(StoresNamespaces.User)
const costEstimationStore = namespace(StoresNamespaces.CostEstimation)

const FILE_EXPLORER_PATH = '/file-explorer'

@Component({
  components: {
    AppHeader,
    Snackbar,
    AppFooter,
    LostConnectionToast,
  },
})
export default class App extends Vue {
  @userStore.Getter('getUserDetails') getUserDetails: IUser
  @userStore.Action('setUserDetails') setUserDetails: any
  @userStore.Action('setIsAuthenticated') setIsAuthenticated: any

  // Inverted flag to change the header to make transperant background for nav-bar
  isHeaderInverted: boolean = false
  $router: any
  isAuthorizedToSeeUserAdmin = false
  isNavigationMode = true

  @Watch('$route', { immediate: true, deep: true })
  setfooterComponent() {
    // do not display footer if AMP is in navigation mode
    this.isNavigationMode =
      this.$router.currentRoute.path.includes(FILE_EXPLORER_PATH) ||
      this.$router.currentRoute.path.includes(RouterPaths.HelpLibrary)
  }

  created() {
    initHttpModule(() => {
      this.setUserDetails(null)
      this.setIsAuthenticated(false)

      const routeName = this.$router.history.current.name
      if (routeName !== 'login') {
        // @ts-ignore
        this.$router.safePush(RouterPaths.Login)
      }
    })
  }

  @Watch('getUserDetails')
  checkAdminVisibility(user: IUser) {
    if (user) {
      this.isAuthorizedToSeeUserAdmin = isAuthorized(user, ROUTER_PERMISSIONS.UserAdmin)
      this.establishConnection()
    }
  }

  async establishConnection() {
    const userId = this.getUserDetails.id
    const userTenant = this.getUserDetails.tenant
    const token = await Vue.prototype.$auth.getTokenSilently()

    const connector = CommunicationService.getConnector()
    if (!connector.socket || !connector.socket.connected) {
      connector.connect(userId, userTenant, token)
    }
  }
}
