
import { extend, ValidationObserver } from 'vee-validate'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/components/controls/Common/Button.vue'
import TextField from '@/components/controls/Common/TextField.vue'
import CreateAmpItemModalMixin from '@/components/layout/mixins/CreateAmpItemModalMixin'
import { DEBOUNCE_TIME } from '@/constants'
import { CheckItemUniqPayload } from '@/store/modules/fileExplorer/types'
import StoresNamespaces from '@/store/namespaces'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { debounce } from '@/utils/debounce'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: {
    Button,
    TextField,
  },
})
export default class RenameItemModal extends Mixins(CreateAmpItemModalMixin) {
  @Prop() item: FileExplorerItem
  @Prop() onRename: (name: string) => Promise<void>

  @fileExplorerStore.Action('checkItemForUnique') checkItemForUnique: (payload: CheckItemUniqPayload) => boolean
  @fileExplorerStore.Action getParentFolder: (itemId: string) => Promise<FileExplorerItem>

  @fileExplorerStore.Getter find: (itemId: string) => FileExplorerItem
  @fileExplorerStore.Getter getRootItem: FileExplorerItem
  @fileExplorerStore.Getter getSelectedItem: FileExplorerItem

  $refs!: {
    form: InstanceType<typeof ValidationObserver>
    nameTextField: TextField
  }

  isShownRenameItemDialog: boolean = true
  name: string = ''
  reservedName = ''
  root: string = null
  isSubmitting: boolean = false
  isValid = true
  isValidating = false

  validateDebounce = debounce<boolean>(DEBOUNCE_TIME, this.validate) as () => Promise<boolean>

  async beforeMount() {
    this.root = this.item.parentId
    this.name = this.item.name
    this.extendValidationRules()
  }

  mounted() {
    this.setFocus()
    this.$refs.nameTextField.selectAll()
  }

  extendValidationRules() {
    extend('unique', {
      validate: async (value) => await this.validateItemUnique(value),
      message: 'Name must be unique',
    })

    // TODO: Create validation mixin and move most common validation rules into it
    // There is a data base column limitation that does not allow to use strings which are more than 255 symbols
    extend('compact', {
      validate: (value: string) => {
        return value.length <= 255
      },
      message: this.$i18n.t('invalidNameLength') as string,
    })

    if (this.item.itemType === ItemType.BuildPlan || this.item.subType === ItemSubType.SinterPlan) {
      this.addSpecialCharacterVerificationRule()
      this.addReservedNamesVerificationRule()
    }
  }

  /**
   * Returns if new item name is unique
   * Disables rename action while request is pending
   * @param newName
   */
  async validateItemUnique(newName: string) {
    let parentId = this.root

    if (!parentId && this.getSelectedItem) {
      const parent = await this.getParentFolder(this.getSelectedItem.id)
      parentId = parent && parent.id
    }

    const isNameExists = await this.checkItemForUnique({
      parentId,
      name: newName,
      excludeItemId: this.item.id,
      itemType: this.item.itemType,
      itemSubType: this.item.subType,
    })

    if (newName !== this.name) {
      return this.isValid
    }
    return !isNameExists
  }

  async onRenameClick() {
    if (this.isSubmitting || this.isValidating) {
      return
    }

    this.isSubmitting = true

    const isValid = await this.$refs.form.validate()
    if (isValid) {
      await this.onRename(this.name)
    }

    this.isSubmitting = false
  }

  async validate() {
    this.reservedName = this.name
    this.isValid = await this.$refs.form.validate()
  }

  setFocus() {
    setTimeout(() => {
      if (this.$refs.nameTextField) {
        this.$refs.nameTextField.focus()
      }
    }, 0)
  }

  getNameRules() {
    const isBuildPlan = this.item.itemType === ItemType.BuildPlan || this.item.subType === ItemSubType.SinterPlan

    return {
      required: true,
      compact: true,
      noSpecials: isBuildPlan,
      noReservedNames: isBuildPlan ? [this.reservedName] : false,
      unique: true,
    }
  }

  onClose() {
    this.$emit('onClose')
  }

  /**
   * Manually trigger validate when name changes.
   * Automatic vee-validation is triggered too often (e.g. when field loses/gains focus)
   */
  @Watch('name')
  async onNameChange() {
    this.isValidating = true
    await this.validateDebounce()
    this.isValidating = false
  }
}
