import { MutationTree } from 'vuex'
import { IOptionalMultiItemCollectorState } from '@/store/modules/optionalMultiItemCollector/types'
import { SelectedItem } from '@/types/OptionalMultiItemCollector/SelectedItem'
import { MultiItemCollector } from '@/types/OptionalMultiItemCollector/MultiItemCollector'

export const mutations: MutationTree<IOptionalMultiItemCollectorState> = {
  setCollectorItems(state: IOptionalMultiItemCollectorState, payload: { items: SelectedItem[] }) {
    state.activeCollector.items = payload.items
  },

  setActiveCollector(state: IOptionalMultiItemCollectorState, id: string) {
    state.activeCollector = state.collectors.find((collector) => collector.id === id)
  },

  createCollector(state: IOptionalMultiItemCollectorState, collector: MultiItemCollector) {
    state.collectors.push(collector)
  },

  deselectLastCollectorItem(state: IOptionalMultiItemCollectorState, id: string) {
    // This mutation passes payload to Babylon side

    const foundCollector = state.collectors.find((collector) => collector.id === id)
    if (foundCollector) {
      foundCollector.items = foundCollector.items.slice(0, foundCollector.items.length - 1)
    }
  },

  deselectAllCollectorItems(state: IOptionalMultiItemCollectorState, id: string) {
    // This mutation passes payload to Babylon side

    const foundCollector = state.collectors.find((collector) => collector.id === id)
    if (foundCollector) {
      foundCollector.items = []
    }
  },

  disableColoringForCollector(state: IOptionalMultiItemCollectorState, id: string) {
    // This mutation passes payload to Babylon side
  },

  enableColoringForCollector(state: IOptionalMultiItemCollectorState, id: string) {
    // This mutation passes payload to Babylon side
  },

  disposeCollectors(state: IOptionalMultiItemCollectorState) {
    // clear collectors on the state
    state.collectors = []

    // This mutation passes payload to Babylon
    // which will clear collectors on its side
  },

  deleteCollector(state: IOptionalMultiItemCollectorState, id: string) {
    state.collectors = state.collectors.filter((collector) => collector.id !== id)
  },
}
