import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { CreateIBCPlanDto, IIBCPlan } from '@/types/IBCPlans/IIBCPlan'
import { RouterNames } from '@/router'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import StoresNamespaces from '@/store/namespaces'
import { GeometryType, IBuildPlanItem, ISelectable } from '@/types/BuildPlans/IBuildPlan'
import fileExplorer from '@/api/fileExplorer'
import { isOfType } from '@/utils/common'
import { ItemPermissionsRole, Permission } from '@/types/FileExplorer/Permission'
import { IUser } from '@/types/User/IUser'

const IBC_PLAN_CREATION_ALLOWED_SUBTYPES = [ItemSubType.SinterPart, ItemSubType.IbcPart]

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const userStore = namespace(StoresNamespaces.User)

@Component
export class CreateIbcMixin extends Vue {
  @fileExplorerStore.Action createIBCPlan: (createIbcPlanDto: CreateIBCPlanDto) => Promise<IIBCPlan>
  @fileExplorerStore.Action fetchItemByIdWithoutAddingToState: (itemId: string) => Promise<FileExplorerItem>
  @fileExplorerStore.Action getItemPermissions: (itemId: string) => Promise<void>

  @fileExplorerStore.Getter getDirectOrInheritedPermissionsByItemPath: (path: string) => Permission[]

  @buildPlansStore.Mutation setIBCPlanNavigatedFrom: (from: {
    name: RouterNames
    params: {
      id?: string
      itemId?: string
    }
  }) => void

  @buildPlansStore.Getter getAllBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getSelectedParts: ISelectable[]

  @userStore.Getter getUserDetails: IUser

  @Prop({ default: false }) readonly isPrintOrder: boolean

  itemMap: Record<string, FileExplorerItem> = {}
  isNewIbcButtonEnabled = false
  isCreatingIbcPlan = false
  userRoleOnOriginalPlan: ItemPermissionsRole = null

  get bpItems() {
    const bpItems = this.getAllBuildPlanItems
    const selectedPartsIds = this.getSelectedParts.map((p) => p.id)
    return bpItems.filter((bpItem: IBuildPlanItem) => selectedPartsIds.includes(bpItem.id))
  }

  get isNewIbcButtonVisible() {
    if (!this.isPrintOrder) {
      return false
    }

    return this.bpItems.every((bpItem) => {
      const hasProductionType = bpItem.partProperties.some((pp) => pp.type === GeometryType.Production)

      return (
        hasProductionType &&
        IBC_PLAN_CREATION_ALLOWED_SUBTYPES.includes(bpItem.part.subType) &&
        !bpItem.part.isPublishedAsScaled
      )
    })
  }

  get showViewerTooltipTextForCompensateButton(): boolean {
    return this.userRoleOnOriginalPlan === ItemPermissionsRole.Viewer
  }

  @Watch('isNewIbcButtonVisible', { immediate: true })
  async onNewIbcButtonVisible(isVisible: boolean) {
    if (isVisible) {
      this.isNewIbcButtonEnabled = false

      if (this.bpItems.length > 1) {
        return
      }

      const bpItem = this.bpItems[0]

      const isAllowedItemSubType = IBC_PLAN_CREATION_ALLOWED_SUBTYPES.includes(bpItem.part.subType)
      const isPublishedAsScaled = bpItem.part.isPublishedAsScaled
      const hasProductionGeometryType = bpItem.partProperties.some((pp) => pp.type === GeometryType.Production)

      const isSatisfiedCondition =
        this.isPrintOrder && isAllowedItemSubType && hasProductionGeometryType && !isPublishedAsScaled

      if (!isSatisfiedCondition) {
        return
      }

      // Сheck if the user has permissions because the original plan may be shared
      // with a role that doesn't allow creating IBC variant
      const selectedPart = bpItem.part
      const originalPlanId = isOfType<IPartDto>(selectedPart, 'originalPlanId')
        ? selectedPart.originalPlanId
        : selectedPart.parentId

      if (!originalPlanId) {
        return
      }

      const originalPlanAsItem = await this.getItemById(originalPlanId)

      if (!originalPlanAsItem) {
        return
      }

      const canEditOriginalPlan =
        originalPlanAsItem.grantedPermissions && originalPlanAsItem.grantedPermissions.itemEdit

      this.isNewIbcButtonEnabled = canEditOriginalPlan
      this.userRoleOnOriginalPlan = await this.getRoleForCurrentUserOnItem(originalPlanAsItem)
    }
  }

  async getItemById(itemId: string): Promise<FileExplorerItem> {
    let item = this.itemMap[itemId]

    if (!item) {
      item = await fileExplorer.getItemById(itemId)
      if (item) {
        this.$set(this.itemMap, itemId, item)
      }
    }

    return item
  }

  async getRoleForCurrentUserOnItem(item: FileExplorerItem) {
    await this.getItemPermissions(item.id)
    const permissions = this.getDirectOrInheritedPermissionsByItemPath(item.path)

    if (!permissions || !this.getUserDetails) {
      return null
    }

    const userPermission = permissions.find((permission) => permission.grantedTo === this.getUserDetails.id)

    return userPermission ? userPermission.role : null
  }

  async newIBCPlan() {
    if (this.isCreatingIbcPlan || this.bpItems.length !== 1) {
      return
    }

    this.isCreatingIbcPlan = true

    const selectedBuildPlanItem = this.bpItems[0]
    const buildPlanItemId = selectedBuildPlanItem.id
    const printOrderId = this.$route.params.id
    const selectedPart = selectedBuildPlanItem.part
    const parentItem: FileExplorerItem = await this.getItemById(selectedPart.parentId)

    if (!parentItem) {
      this.isCreatingIbcPlan = false
      return
    }

    const createIBCPlanDto: CreateIBCPlanDto = {
      buildPlanItemId,
      printOrderId,
      name: parentItem.name,
      parentId: selectedPart.parentId,
    }

    const ibcPlan = await this.createIBCPlan(createIBCPlanDto)

    if (!ibcPlan) {
      this.isCreatingIbcPlan = false
      return
    }

    const routeTo = {
      name: RouterNames.IBC_Inspections,
      params: {
        ibcPlanId: ibcPlan.id,
      },
    }
    const routeFrom = {
      name: RouterNames.PreviewPrintOrder,
      params: {
        id: printOrderId,
      },
    }

    this.setIBCPlanNavigatedFrom(routeFrom)
    this.isCreatingIbcPlan = false

    // @ts-expect-error Custom router
    this.$router.safePush(routeTo)
  }
}
