import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import StoresNamespaces from '@/store/namespaces'
import { IMachineConfig, EnvelopeShape, PrintingTypes } from '@/types/IMachineConfig'
import { IMaterial } from '@/types/IMaterial'
import { IBuildPlate, IBuildPlateMachineConfig, IBuildPlateMaterial } from '@/types/BuildPlates/IBuildPlate'
import {
  DEFAULT_MACHINE_BUILD_CHAMBER_UNIT,
  ENVELOPE_X_Y_MODIFIER_FOR_LIKELY_AND_OPTIMISTIC,
  PRECISIONS,
} from '@/constants'
import round from '@/utils/arithmetic/round'
import { convert } from '@/utils/converter/lengthConverter'
import { PrintStrategyInfo } from '@/types/Sites/Site'
import { VersionableModel } from '@/types/Common/VersionableModel'
import { VersionablePk } from '@/types/Common/VersionablePk'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component
export default class MachineMaterialSelectionMixin extends Vue {
  @buildPlansStore.Getter getAllMachineConfigs: IMachineConfig[]
  @buildPlansStore.Getter getAllMaterials: IMaterial[]
  @buildPlansStore.Getter getAllBuildPlates: IBuildPlate[]
  @buildPlansStore.Getter getAllBuildPlateMaterials: IBuildPlateMaterial[]
  @buildPlansStore.Getter getAllBuildPlateMachineConfigs: IBuildPlateMachineConfig[]
  @buildPlansStore.Getter getAllActivePrintStrategies: PrintStrategyInfo[]
  @buildPlansStore.Getter getMachineConfigByPk: (pk: VersionablePk) => IMachineConfig
  @buildPlansStore.Getter getMaterialByPk: (pk: VersionablePk) => IMaterial

  getAvailableBuildPlatesByMachineConfigAndMaterial(
    machineConfigPk: VersionablePk,
    materialPk: VersionablePk,
  ): IBuildPlate[] {
    if (!machineConfigPk || !materialPk) {
      return []
    }

    const bpMaterialPks = this.getAllBuildPlateMaterials
      .filter(
        (bpMaterial) => bpMaterial.materialId === materialPk.id && bpMaterial.materialVersion === materialPk.version,
      )
      .map((bpMaterial) => new VersionablePk(bpMaterial.buildPlateMaterialId, bpMaterial.buildPlateMaterialVersion))

    const bpPks = this.getAllBuildPlateMachineConfigs
      .filter(
        (bpMachineConfig) =>
          bpMachineConfig.machineConfigId === machineConfigPk.id &&
          bpMachineConfig.machineConfigVersion === machineConfigPk.version,
      )
      .map((bpMachineConfig) => new VersionablePk(bpMachineConfig.buildPlateId, bpMachineConfig.buildPlateVersion))

    const buildPlates = this.getAllBuildPlates.filter(
      (plate) =>
        bpPks.some((pk) => pk.id === plate.id && pk.version === plate.version) &&
        bpMaterialPks.some((pk) => pk.id === plate.materialId && pk.version === plate.materialVersion),
    )

    return VersionableModel.getLatestVersions(buildPlates)
      .filter((buildPlate) => buildPlate.visibility)
      .sort((a, b) => a.buildPlateName.localeCompare(b.buildPlateName))
  }

  getPrintStrategiesByMachineConfigAndMaterial(machineConfigPk: VersionablePk, materialPk: VersionablePk) {
    if (!materialPk) {
      return []
    }

    return this.getAllActivePrintStrategies.filter(
      (x) =>
        x.materialId === materialPk.id &&
        x.materialVersion === materialPk.version &&
        (!machineConfigPk ||
          (x.machineConfigId === machineConfigPk.id && x.machineConfigVersion === machineConfigPk.version)),
    )
  }

  getAvailableMaterialsByPrintingType(printingType?: PrintingTypes) {
    const materialPks = this.getAllActivePrintStrategies.map((x) => new VersionablePk(x.materialId, x.materialVersion))
    let materials = this.getAllMaterials.filter(
      (mat) => mat.visibility && materialPks.some((m) => m.id === mat.id && m.version === mat.version),
    )

    if (printingType) {
      materials = materials.filter((material) => material.modality === printingType)
    }

    return materials.sort((a, b) => a.name.localeCompare(b.name))
  }

  getAvailableMachineConfigsByMaterial(materialPk: VersionablePk) {
    if (!materialPk) {
      return []
    }

    const machineConfigPks = this.getAllActivePrintStrategies
      .filter((x) => x.materialId === materialPk.id && x.materialVersion === materialPk.version)
      .map((x) => new VersionablePk(x.machineConfigId, x.machineConfigVersion))

    const machineConfigs = this.getAllMachineConfigs
      .filter((config) => machineConfigPks.some((pk) => pk.id === config.id && pk.version === config.version))
      .sort((a, b) => a.name.localeCompare(b.name))

    return machineConfigs.map((config) => {
      return {
        ...config,
        isCircleEnvelopeShapedMachine: config.envelopeShape === EnvelopeShape.Circle,
        buildChamberX: this.getBuildChamberMm(
          this.getAdjustedBuildChamberByEnvelopeShape(config.envelopeShape, config.buildChamberX),
        ),
        buildChamberY: this.getBuildChamberMm(
          this.getAdjustedBuildChamberByEnvelopeShape(config.envelopeShape, config.buildChamberY),
        ),
        buildChamberZ: this.getBuildChamberMm(config.buildChamberZ),
      }
    })
  }

  getAdjustedBuildChamberByEnvelopeShape(envelopeShape: EnvelopeShape, buildChamberXOrY: number) {
    return envelopeShape === EnvelopeShape.Square
      ? round(
          buildChamberXOrY / ENVELOPE_X_Y_MODIFIER_FOR_LIKELY_AND_OPTIMISTIC,
          PRECISIONS[DEFAULT_MACHINE_BUILD_CHAMBER_UNIT],
        )
      : buildChamberXOrY
  }

  getBuildChamberMm(buildChamber: number) {
    return convert(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, 'mm', buildChamber)
  }

  getDefaultPk(lastUsedId: number, allItems: VersionableModel[]) {
    if (lastUsedId) {
      const lastUsedItem = allItems.find((item) => item.id === lastUsedId)
      if (lastUsedItem) {
        return VersionableModel.getPk(lastUsedItem)
      }
    }

    const defaultItem = allItems[0]
    if (defaultItem) {
      return VersionableModel.getPk(defaultItem)
    }
  }

  getNumberOfLasersByMachineConfigPk(machineConfigPk: VersionablePk) {
    const machineConfig = this.getMachineConfigByPk(machineConfigPk)
    if (!machineConfig) {
      return 0
    }
    return machineConfig.numberOfLasers
  }
}
