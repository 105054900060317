import { MutationTree } from 'vuex'

import { IVisualizationState } from './types'
import {
  IComponent,
  AssemblyComponent,
  PartComponent,
  BoundingBox,
  BoundingBox2D,
} from '@/visualization/models/DataModel'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import {
  IResultsPathData,
  ISimulationPathData,
  IWarpingInfo,
  IAdditionalGeometryInfo,
  GeometryType as AdditionalGeometryType,
  ProcessingStage,
  CategoryKind,
} from '@/visualization/types/SimulationTypes'
import VisualizationModeTypes from '@/visualization/types/VisualizationModeTypes'
import {
  BuildPlanItemOverhang,
  BuildPlanItemSupport,
  GeometryType,
  IBuildPlan,
  SelectionUnit,
  Visibility,
} from '@/types/BuildPlans/IBuildPlan'
import { ISimulationStep } from '@/types/Simulation/SimulationSteps'
import { SimulationColoringModel } from '@/types/Simulation/SimulationColoringModel'
import { InstanceLabel } from '@/visualization/types/InstanceLabel'
import { ILabelStyle } from '@/types/Marking/ILabel'
import { SceneType, HighlightType, IRangeInfo } from '@/visualization/types/Common'
import { IOverhangConfig } from '@/visualization/rendering/OverhangManager'
import { SceneMode } from '@/visualization/types/SceneTypes'
import { IHighlightDefectPayload, ISelectableDefectPayload } from '@/types/Parts/IPartInsight'
import { ManualPatch } from '@/types/Label/Patch'
import { PWError } from '@/visualization/rendering/ResultsManager'
import { GridLetterJSON } from '@/types/Label/TextElement'
import { IDataHandlerInfo } from '@/visualization/rendering/SimulationStepHandler'
import { Vector3 } from '@babylonjs/core/Maths'
import { Placement } from '@/types/Label/Placement'
import { DimensionBox, ClearanceTypes } from '@/visualization/types/ClearanceTypes'
import { ISelectableNode } from '@/visualization/rendering/SelectionManager'

/// TODO: fix mutations according to new model config
export const mutations: MutationTree<IVisualizationState> = {
  selectComponent(state: IVisualizationState, payload: { componentId: string; attach: boolean; silent?: boolean }) {
    // if (!payload.componentId) {
    //   state.selectedComponentsIds = []
    //   return
    // }
    // if (payload.attach) {
    //   const includeIndex = state.selectedComponentsIds.indexOf(payload.componentId)
    //   if (includeIndex !== -1) {
    //     state.selectedComponentsIds.splice(includeIndex, 1)
    //   } else {
    //     const candidatChildrenIds = getChildrenIds(
    //       findComponentById(payload.componentId, state.documentModel.components),
    //     )
    //     candidatChildrenIds.forEach(childId => {
    //       const indexOfChildId = state.selectedComponentsIds.indexOf(childId)
    //       if (indexOfChildId !== -1) {
    //         state.selectedComponentsIds.splice(indexOfChildId, 1)
    //       }
    //     })
    //     const parentIds = getParentsIds(payload.componentId, state.documentModel.components)
    //     const isParentSelected = parentIds.some(parent => state.selectedComponentsIds.includes(parent))
    //     if (!isParentSelected) {
    //       state.selectedComponentsIds.push(payload.componentId)
    //     }
    //   }
    // } else {
    //   state.selectedComponentsIds = [payload.componentId]
    // }
  },

  deselectComponent(state: IVisualizationState, payload: { componentId?: string }) {
    if (!payload.componentId) {
      state.selectedComponentsIds = []
    } else {
      const includeIndex = state.selectedComponentsIds.indexOf(payload.componentId)

      if (includeIndex !== -1) {
        state.selectedComponentsIds.splice(includeIndex, 1)
      }
    }
  },

  init(
    state: IVisualizationState,
    payload: { canvasId: string; sceneMode: SceneMode; viewMode?: ViewModeTypes; loadDefaultPlate?: boolean },
  ) {
    state.canvasId = payload.canvasId
    state.isInitialized = false
  },

  setUninitialized(state: IVisualizationState) {
    state.isInitialized = false
  },

  initDetailsPreview(state: IVisualizationState, payload: { canvasId: string; loadDefaultPlate?: boolean }) {
    // This mutation passes payload to Babylon side
  },

  loadPartConfig(state: IVisualizationState, payload: { partName: string; allowLoadPartConfigTermination?: boolean }) {
    // This mutation passes payload to Babylon side
  },

  initialized(state: IVisualizationState) {
    state.isInitialized = true
  },

  delayDisposing(state: IVisualizationState) {
    state.needToDispose = true
  },

  dispose(state: IVisualizationState) {
    state.canvasId = ''
    state.selectedComponentsIds = []
    state.isShowingGizmos = false
    state.needToDispose = false
    state.isInitialized = false
  },

  disposePreview(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  clearDetailsPreview(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  showGizmos(state: IVisualizationState) {
    state.isShowingGizmos = true
  },

  hideGizmos(state: IVisualizationState) {
    state.isShowingGizmos = false
  },

  deselect(state: IVisualizationState, payload?: { items?: ISelectableNode[]; isSilent?: boolean }) {
    // This mutation passes payload to Babylon side
  },

  deselectNonBarGeometry(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  undo(state: IVisualizationState) {
    return
  },

  checkCollision(state: IVisualizationState, payload: { componentId: string }) {
    if (!payload) {
      return
    }
  },

  clearSelection() {
    // This mutation passes on to Babylon side
  },

  enableCrossSectionMode(state: IVisualizationState, payload: { isEnabled: boolean; crossSectionMatrix: number[] }) {
    state.crossSectionMode.isEnabled = payload.isEnabled
  },

  enableClearanceTool(
    state: IVisualizationState,
    payload: {
      isEnabled: boolean
      restoreSelectionManagerState?: boolean
    },
  ) {
    state.clearanceMode.isEnabled = payload.isEnabled

    if (!payload.isEnabled) {
      state.clearanceMode.enabledFrom = ClearanceTypes.Parts
      state.clearanceMode.isEnabledFrom.bodies = false
      state.clearanceMode.isEnabledFrom.parts = true
      state.clearanceMode.isEnabledFrom.walls = false
      state.clearanceMode.isEnabledFrom.printHeadLanes = false
      state.clearanceMode.isEnabledFrom.plate = false
      state.clearanceMode.isEnabledFrom.ceiling = false

      state.clearanceMode.enabledTo = ClearanceTypes.Parts
      state.clearanceMode.isEnabledTo.bodies = false
      state.clearanceMode.isEnabledTo.parts = true
      state.clearanceMode.isEnabledTo.walls = false
      state.clearanceMode.isEnabledTo.printHeadLanes = false
      state.clearanceMode.isEnabledTo.plate = false
      state.clearanceMode.isEnabledTo.ceiling = false
    }
  },

  enableClearanceModeFrom(state: IVisualizationState, payload: { clearanceType: ClearanceTypes }) {
    const { clearanceType } = payload

    state.clearanceMode.isEnabledFrom.bodies = false
    state.clearanceMode.isEnabledFrom.parts = false
    state.clearanceMode.isEnabledFrom.walls = false
    state.clearanceMode.isEnabledFrom.printHeadLanes = false
    state.clearanceMode.isEnabledFrom.plate = false
    state.clearanceMode.isEnabledFrom.ceiling = false

    switch (clearanceType) {
      case ClearanceTypes.Bodies:
        state.clearanceMode.isEnabledFrom.bodies = true
        break
      case ClearanceTypes.Parts:
        state.clearanceMode.isEnabledFrom.parts = true
        break
      case ClearanceTypes.Walls:
        state.clearanceMode.isEnabledFrom.walls = true
        break
      case ClearanceTypes.PrintHeadLanes:
        state.clearanceMode.isEnabledFrom.printHeadLanes = true
        break
      case ClearanceTypes.Plate:
        state.clearanceMode.isEnabledFrom.plate = true
        break
      case ClearanceTypes.Ceiling:
        state.clearanceMode.isEnabledFrom.ceiling = true
        break
    }

    state.clearanceMode.enabledFrom = clearanceType

    if (
      [ClearanceTypes.Walls, ClearanceTypes.PrintHeadLanes, ClearanceTypes.Plate, ClearanceTypes.Ceiling].includes(
        clearanceType,
      )
    ) {
      state.clearanceMode.enabledTo = ClearanceTypes.Parts
      state.clearanceMode.isEnabledTo.bodies = false
      state.clearanceMode.isEnabledTo.parts = true
      state.clearanceMode.isEnabledTo.walls = false
      state.clearanceMode.isEnabledTo.printHeadLanes = false
      state.clearanceMode.isEnabledTo.plate = false
      state.clearanceMode.isEnabledTo.ceiling = false
    }
  },

  enableClearanceModeTo(state: IVisualizationState, payload: { clearanceType: ClearanceTypes }) {
    const { clearanceType } = payload

    state.clearanceMode.isEnabledTo.bodies = false
    state.clearanceMode.isEnabledTo.parts = false
    state.clearanceMode.isEnabledTo.walls = false
    state.clearanceMode.isEnabledTo.printHeadLanes = false
    state.clearanceMode.isEnabledTo.plate = false
    state.clearanceMode.isEnabledTo.ceiling = false

    switch (clearanceType) {
      case ClearanceTypes.Bodies:
        state.clearanceMode.isEnabledTo.bodies = true
        break
      case ClearanceTypes.Parts:
        state.clearanceMode.isEnabledTo.parts = true
        break
      case ClearanceTypes.Walls:
        state.clearanceMode.isEnabledTo.walls = true
        break
      case ClearanceTypes.PrintHeadLanes:
        state.clearanceMode.isEnabledTo.printHeadLanes = true
        break
      case ClearanceTypes.Plate:
        state.clearanceMode.isEnabledTo.plate = true
        break
      case ClearanceTypes.Ceiling:
        state.clearanceMode.isEnabledTo.ceiling = true
        break
    }

    state.clearanceMode.enabledTo = clearanceType
  },

  measureDistanceToEnvironment(
    state: IVisualizationState,
    payload: {
      from: ClearanceTypes
      to: ClearanceTypes
      buildPlanItemId: string
      componentId?: string
      geometryId?: string
    },
  ) {
    // This mutation passes on to Babylon side
  },

  toggleClearanceHighlight(
    state: IVisualizationState,
    payload: {
      clearanceId: string
      showHighlight: boolean
    },
  ) {
    // This mutation passes on to Babylon side
  },

  setHighlightedClearanceIds(state: IVisualizationState, payload: { clearanceIds: string[] }) {
    state.clearanceMode.highlightedClearanceIds = payload.clearanceIds
  },

  showRubberBand(state: IVisualizationState, payload: { isShown: boolean }) {
    state.clearanceMode.showRubberband = payload.isShown
  },

  saveSelectionManagerState(state: IVisualizationState) {
    // This mutation passes on to Babylon side
  },

  restoreSelectionManagerState(state: IVisualizationState) {
    // This mutation passes on to Babylon side
  },

  changeClearanceSelectionObserver(state: IVisualizationState) {
    // This mutation passes on to Babylon side
  },

  changeDimensionBox(state: IVisualizationState, dimensionBoxes: DimensionBox[]) {
    state.clearanceMode.dimensionBoxes = dimensionBoxes
  },

  saveActiveCollectorId(state: IVisualizationState, payload: string) {
    state.clearanceMode.savedActiveCollectorId = payload
  },

  recenterCrossSection() {
    // This mutation passes on to Babylon side
  },

  axisAlignCrossSection() {
    // This mutation passes on to Babylon side
  },

  enableSlicerMode(state: IVisualizationState, payload: boolean) {
    state.slicerMode.isEnable = payload
  },

  initSlicer(state: IVisualizationState, payload: { max: number; min: number }) {
    state.slicerMode.max = payload.max
    state.slicerMode.min = payload.min
    state.slicerMode.current = payload.max
  },

  changeCurrentSlicerValue(state: IVisualizationState, payload: number) {
    state.slicerMode.current = payload
  },

  initSimulationSlicer(state: IVisualizationState, payload: { max: number; min: number }) {
    state.simulation.slicer = {
      max: payload.max,
      min: payload.min,
      current: Math.ceil(payload.max),
    }
  },

  initializeStepBounds(state: IVisualizationState, payload: { max: Vector3; min: Vector3 }) {
    state.simulation.boundingBox = {
      max: payload.max,
      min: payload.min,
    }
  },

  initSimulationTimestamps(state: IVisualizationState, payload: { stamps: number[]; current: number }) {
    state.simulation.timestamps = payload
  },

  initSimulationSteps(state: IVisualizationState, payload: { steps: ISimulationStep[] }) {
    state.simulation.simulationSteps = payload.steps
    if (payload.steps.length > 0) {
      state.simulation.selectedSimulationStep = payload.steps[0]
    }
  },

  changeSimulationSlicer(state: IVisualizationState, payload: number) {
    state.simulation.slicer.current = payload
  },

  changeSimulationTimestamp(state: IVisualizationState, payload: number) {
    state.simulation.timestamps.current = payload
  },

  setIsShownNewTimestampModal(state: IVisualizationState, payload: boolean) {
    state.simulation.isShownNewTimestampModal = payload
  },

  changeViewMode(state: IVisualizationState, payload: ViewModeTypes) {
    state.viewMode.name = payload
  },

  changeVisualizationMode(state: IVisualizationState, payload: VisualizationModeTypes) {
    state.visualizationMode = payload
  },

  changeView(state: IVisualizationState, payload: string) {
    return
  },

  setXrayView(state: IVisualizationState, payload: { min: number; max: number }) {
    return
  },

  setViewLocked(state: IVisualizationState, payload: boolean) {
    state.isViewLocked = payload
  },

  enableViewModeSelection(state: IVisualizationState, payload: boolean) {
    state.viewMode.isSelectable = payload
  },

  setLabelStyle(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  refreshInsights(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  activateLabelManualPlacement(state: IVisualizationState) {
    state.isLabelManualPlacement = true
    // This mutation passes payload to Babylon side
  },

  deactivateLabelManualPlacement(state: IVisualizationState, payload?: { labelSetId?: string, isSwitchedToAutomatic: boolean }) {
    state.isLabelManualPlacement = false
    // This mutation passes payload to Babylon side
  },

  activateLabelBarPlacement(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  deactivateLabelBarPlacement(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  activateLabelPlacement(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  deactivateLabelPlacement(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  showManuallyPlacedLabelOrigins(state: IVisualizationState, payload: { patches: Placement[]; labelSetId?: string }) {
    // This mutation passes payload to Babylon side
  },

  activateLabelCreation(state: IVisualizationState) {
    state.isLabelCreationMode = true
    // This mutation passes payload to Babylon side
  },

  deactivateLabelCreation(state: IVisualizationState) {
    state.isLabelCreationMode = false
    // This mutation passes payload to Babylon side
  },

  activateLabelInteraction(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  deactivateLabelInteraction(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  updateLabelAppearance(
    state: IVisualizationState,
    payload: {
      id: string
      style: ILabelStyle
      shouldLabelBeSelected: boolean
      updateStore: boolean
    },
  ) {
    // This mutation passes payload to Babylon side
  },

  toggleComponentHighlight(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  labelInstance(state: IVisualizationState, payload: InstanceLabel) {
    // This mutation passes payload to Babylon side
  },

  deleteRenderedLabel(state: IVisualizationState, labelId: string) {
    // This mutation passes payload to Babylon side
  },

  toggleLabelHighlight() {
    // This mutation passes payload to Babylon side
  },

  toggleDownwardPlaneRotationInitialization(
    state: IVisualizationState,
    payload: { isDownwardPlaneRotationInitialized: boolean; silent?: boolean },
  ) {
    state.isDownwardPlaneRotationInitialized = payload.isDownwardPlaneRotationInitialized
  },

  toggleDownwardPlaneRotationProgress(
    state: IVisualizationState,
    payload: { isDownwardPlaneRotationInProgress: boolean; silent?: boolean },
  ) {
    state.isDownwardPlaneRotationInProgress = payload.isDownwardPlaneRotationInProgress
  },

  flipSelectedParts(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  displayFlipArrow(state: IVisualizationState, isDisplay: boolean) {
    state.isDisplayFlipArrow = isDisplay
  },

  setFlipArrowLocation(state: IVisualizationState, location: { x: number; y: number }) {
    state.flipArrowLocation = location
  },

  hoverManualLabelSettings(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  displayManualLabelSettings(state: IVisualizationState, isDisplay: boolean) {
    state.isDisplayManualLabelSettings = isDisplay
  },

  manualLabelSettingsLocation(state: IVisualizationState, location: { x: number; y: number }) {
    state.manualLabelSettingsLocation = location
  },

  hideManualLabelHandle(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  setBoundingBox(state: IVisualizationState, payload: { boundingBox: BoundingBox }) {
    state.boundingBox = payload.boundingBox
  },

  setBoundingBoxForPartsOnly(state: IVisualizationState, payload: { boundingBox: BoundingBox }) {
    state.boundingBoxForPartsOnly = payload.boundingBox
  },

  showMeshes(state: IVisualizationState) {
    return
  },

  hideMeshes(state: IVisualizationState) {
    return
  },

  showSlicePolylines(
    state: IVisualizationState,
    payload: { polylines: number[][]; type?: string; polylineMeshName?: string },
  ) {
    return
  },

  rotatePart(
    state: IVisualizationState,
    payload: {
      partItemIndex: string
      x: number
      y: number
      z: number
      transformation?: number[]
      zTranslation?: number
    },
  ) {
    return
  },

  selectAndHighlightPart(
    state: IVisualizationState,
    payload: { buildPlanItemId: string; meshId: string; deselectIfSelected?: boolean; showGizmo?: boolean },
  ) {
    return
  },

  selectAndHighlightParts(
    state: IVisualizationState,
    payload: { buildPlanItemIds: string[]; deselectIfSelected?: boolean; showGizmo?: boolean },
  ) {
    return
  },

  selectDefects(state: IVisualizationState, payload: ISelectableDefectPayload) {
    // This mutation passes payload to Babylon side
  },

  toggleHighlight(
    state: IVisualizationState,
    payload: { buildPlanItemId: string; meshId: string; highlight: boolean },
  ) {
    return
  },

  toggleMultiHighlight(state: IVisualizationState, payload: { buildPlanItemIds: string[]; highlight: boolean }) {
    return
  },

  toggleDefectsHighlight(state: IVisualizationState, payload: IHighlightDefectPayload) {
    // This mutation passes payload to Babylon side
  },

  selectAndHighlightPartWithAttach(state: IVisualizationState, payload: { buildPlanItemIds: string | string[] }) {
    // This mutation passes payload to Babylon side
    return
  },

  // The following mutation was created for the Undo-Redo feature
  // and it calls when build plan items are restoring by Undo or Redo buttons
  selectAndHighlightPartsAfterRemove(state: IVisualizationState, payload: { buildPlanItemIds: string | string[] }) {
    // This mutation passes payload to Babylon side
    return
  },

  savePartOrientation(state: IVisualizationState, payload: { partItemIndex: string }) {
    return
  },

  hideSlicePolylines(state: IVisualizationState, payload: { type?: string; polylineMeshName?: string }) {
    return
  },

  changeSimulationLegend(state: IVisualizationState, payload: { rangePoints: number[]; colors: number[][] }) {
    state.simulation.legend = payload
  },

  changeSelectedColoringView(state: IVisualizationState, viewName: string) {
    const selected = state.simulation.coloringModel.views.find((view) => view.name === viewName)
    if (!selected) {
      return
    }

    state.simulation.coloringModel.selectedView = selected
  },

  changeSelectedColoringMode(state: IVisualizationState, payload: { name: string }) {
    const selectedView = state.simulation.coloringModel.selectedView
    if (!selectedView) {
      return
    }

    const selectedMode = selectedView.modes.find((mode) => mode.name === payload.name)
    if (!selectedMode) {
      return
    }

    selectedView.selectedMode = selectedMode
  },

  initializeColoringModel(state: IVisualizationState, model: SimulationColoringModel) {
    state.simulation.coloringModel = model
  },

  setResultsPathData(state: IVisualizationState, payload: IResultsPathData) {
    if (payload.hasOwnProperty('simulationStep')) {
      state.simulation.selectedSimulationStep = (payload as ISimulationPathData).simulationStep
    }
  },

  setActiveScene(state: IVisualizationState, payload: SceneType) {
    // This mutation passes payload to Babylon side
  },

  applyTransformationMatrix(
    state: IVisualizationState,
    payload: {
      buildPlanItemId: string
      transformation: number[]
      options?: {
        skipPositionX?: boolean
        skipPositionY?: boolean
        skipPositionZ?: boolean
        updateStateOnly?: boolean
        parameterSetScaleFactor?: number[]
        skipScaleCompensation?: boolean
      }
    },
  ) {
    return
  },

  applyTransformationMatrixBatch(
    state: IVisualizationState,
    payload: {
      buildPlanItems: any[]
    },
  ) {
    return
  },

  setIsLoading(state: IVisualizationState, value: boolean) {
    state.isLoading = value
  },

  setAddPartPreviewLoading(state: IVisualizationState, value: boolean) {
    state.addPart.previewIsLoading = value
  },

  setIsMouseOverCanvas(state: IVisualizationState, value: boolean) {
    state.isMouseOverCanvas = value
  },

  viewSimulationResults(state: IVisualizationState) {
    // This mutation starts simulation results rendering
  },

  highlightDetailsInfo(
    state: IVisualizationState,
    payload: {
      highlight: boolean
      coords: number[]
      type: HighlightType
    },
  ) {
    // This mutation starts simulation results rendering
  },

  setAllStepsColorRange(state: IVisualizationState, payload: boolean) {
    // This mutation starts simulation results rendering
  },

  setHandlerVisibility(state: IVisualizationState, payload: IDataHandlerInfo) {
    // This mutation changes visibility of the loaded data handler
    state.simulation.handlersLoaded[payload.type].visible = payload.visible
  },

  resetSimulationState(state: IVisualizationState) {
    state.isLoading = false
    state.simulation.selectedSimulationStep = undefined
    state.simulation.simulationSteps = []
    state.simulation.loadedSimulationSteps = []
    state.simulation.slicer = { min: undefined, max: undefined, current: undefined }
    state.simulation.timestamps = { stamps: [], current: undefined }
    state.simulation.legend = { rangePoints: [], colors: [] }
    state.simulation.coloringModel = new SimulationColoringModel([])
    state.simulation.charts = []
    state.simulation.activeChart = undefined
    state.simulation.dataRange = { allSteps: undefined, currentStep: undefined, xray: undefined }
    state.simulation.additionalGeometry = {}
    state.simulation.summary = undefined
    state.simulation.printingLayers = []
    state.simulation.resultsAvailable = undefined
    state.simulation.paraviewWebError = undefined
    state.simulation.warping = { factor: 1, available: false, loaded: false }
    state.simulation.handlersLoaded = {}
    state.simulation.boundingBox = { min: undefined, max: undefined }
    state.simulation.processing = {}
  },

  setParaviewWebError(state: IVisualizationState, payload: { message: string; type: PWError }) {
    state.simulation.paraviewWebError = payload
    state.simulation.processing = {
      ...state.simulation.processing,
      [ProcessingStage.FetchingResults]: false,
    }
  },

  setWarpingParameters(state: IVisualizationState, payload: number) {
    state.simulation.warping.factor = payload
  },

  setWarpingAvailability(state: IVisualizationState, payload: IWarpingInfo) {
    state.simulation.warping.available = payload.available
    state.simulation.warping.loaded = payload.loaded

    if (!(payload.factor === undefined || payload.factor === null)) {
      state.simulation.warping.factor = payload.factor
    }
  },

  setHandlersTogglesAvailable(state: IVisualizationState) {
    state.simulation.handlersLoaded = {
      [CategoryKind.PARTS]: { visible: true },
      [CategoryKind.COUPONS]: { visible: true },
      [CategoryKind.SUPPORTS]: { visible: true },
      [CategoryKind.BUILDPLATE]: { visible: true },
    }
  },

  addLoadedSimulationStep(state: IVisualizationState, value: string) {
    state.simulation.loadedSimulationSteps.push(value)
  },

  dataRangeChanged(state: IVisualizationState, payload: IRangeInfo) {
    state.simulation.dataRange = payload
  },

  showNominalGeometry(state: IVisualizationState, payload: boolean) {
    return
  },

  showMeshing(state: IVisualizationState, payload: boolean) {
    return
  },

  showCompensated(state: IVisualizationState, payload: boolean) {
    return
  },

  showGreenCompensated(state: IVisualizationState, payload: boolean) {
    return
  },

  showGreenNominal(state: IVisualizationState, payload: boolean) {
    return
  },

  additionalGeometryDetected(state: IVisualizationState, payload: IAdditionalGeometryInfo) {
    state.simulation.additionalGeometry = {
      ...state.simulation.additionalGeometry,
      [payload.type]: {
        loaded: payload.loaded,
        visible: payload.visible,
        notAvailable: payload.notAvailable || false,
      },
    }

    if (
      (payload.type === AdditionalGeometryType.Bridging ||
        payload.type === AdditionalGeometryType.Meshing ||
        payload.type === AdditionalGeometryType.Compensated ||
        payload.type === AdditionalGeometryType.GreenCompensated ||
        payload.type === AdditionalGeometryType.GreenNominal) &&
      payload.loaded
    ) {
      state.isLoading = false
    }
  },

  initializeSummary(state: IVisualizationState, payload) {
    state.simulation.summary = payload
  },

  initializePrintingLayers(state: IVisualizationState, payload: { layers: string[] }) {
    state.simulation.printingLayers = payload.layers
  },

  setResultsAvailable(state: IVisualizationState, payload) {
    state.simulation.resultsAvailable = payload
    state.simulation.processing = {
      ...state.simulation.processing,
      [ProcessingStage.FetchingResults]: false,
    }
  },

  showBridgingElements(state: IVisualizationState) {
    return
  },

  setCurrentProcessing(state: IVisualizationState, payload: { stage: ProcessingStage; enabled: boolean }) {
    state.simulation.processing = {
      ...state.simulation.processing,
      [payload.stage]: payload.enabled,
    }
  },

  setVisualizationServiceStarted(state: IVisualizationState, payload: boolean) {
    state.simulation.serviceStarted = payload
  },

  addOverhangMesh(
    state: IVisualizationState,
    payload: { buildPlanItemId: string; id: string; config: IOverhangConfig },
  ) {
    // This mutation passes payload to Babylon side
  },

  updateOverhangMesh(
    state: IVisualizationState,
    payload: { buildPlanItemId: string; id: string; overhang: BuildPlanItemOverhang },
  ) {
    // This mutation passes payload to Babylon side
  },

  clearOverhangMesh(state: IVisualizationState, buildPlanItemId: string) {
    // This mutation passes payload to Babylon side
  },

  addSupportMesh(
    state: IVisualizationState,
    payload: { buildPlanItemId: string; sdata: ArrayBuffer; belongsToOverhangElementName: string },
  ) {
    // This mutation passes payload to Babylon side
  },

  addSupportBvhAndHull(
    state: IVisualizationState,
    payload: { buildPlanItemId: string; supportsBvhFileKey: string; supportsHullFileKey: string },
  ) {
    // This mutation passes payload to Babylon side
  },

  loadSupports(
    state: IVisualizationState,
    payload: {
      buildPlanItemId: string
      supports: BuildPlanItemSupport[]
      supportsBvhFileKey: string
      supportsHullFileKey: string
      visibility: Visibility
    },
  ) {
    // This mutation passes payload to Babylon side
  },

  clearSupports(
    state: IVisualizationState,
    payload: { buildPlanItemId: string; overhangElementsToClear?: string[]; skipGeomProps?: boolean },
  ) {
    // This mutation passes payload to Babylon side
  },

  setGeometriesVisibility(
    state: IVisualizationState,
    payload: {
      items: Array<{ buildPlanItemId: string; supportBodyIds?: string[] }>
      geometryType: GeometryType
      visibility: boolean
    },
  ) {
    // This mutation passes payload to Babylon side
  },

  setGeometryVisibility(
    state: IVisualizationState,
    payload: {
      items: Array<{ buildPlanItemId: string; bodyIds?: string[] }>
      visibility: boolean
    },
  ) {
    // This mutation passes payload to Babylon side
  },

  setIsHiddenForBuildPlanItemMesh(
    state: IVisualizationState,
    payload: {
      buildPlanItemId: string
      makeHidden: boolean
      showHiddenAsTransparent: boolean
    },
  ) {
    // This mutation passes payload to Babylon side
  },

  setOverhangMeshVisibility(
    state: IVisualizationState,
    payload: { items: Array<{ buildPlanItemId: string }>; visibility: boolean },
  ) {
    // This mutation passes payload to Babylon side
  },

  highlightErrorOverhangZone(
    state: IVisualizationState,
    payload: { buildPlanItemId: string; overhangZoneName: string },
  ) {
    // This mutation passes payload to Babylon side
  },

  updateItemPreview(state: IVisualizationState, payload: { itemId: string }) {
    // This mutation passes payload to Babylon side
  },

  setDefaultOverhangMaterial(
    state: IVisualizationState,
    payload: { buildPlanItemId: string; overhangElementsToClear?: string[] },
  ) {
    // This mutation passes payload to Babylon side
  },

  setSelectionModeAndReselect(state: IVisualizationState, payload: { mode: SelectionUnit }) {
    // This mutation passes payload to Babylon side
  },

  highlightSupports(state: IVisualizationState, payload: { buildPlanItemId: string; overhangElementNames: string[] }) {
    // This mutation passes payload to Babylon side
  },

  hoverSupport(state: IVisualizationState, payload: { buildPlanItemId: string; overhangElementName: string }) {
    // This mutation passes payload to Babylon side
  },

  transferSupports(state: IVisualizationState, payload: { sourceId: string; targetIds: string[] }) {
    // This mutation passes payload to Babylon side
  },

  setSendBoundingAnchorPoints(state: IVisualizationState, shouldSend: boolean) {
    // This mutation passes payload to Babylon side
  },

  getItemsBoundingBox2D(state: IVisualizationState, itemIds: string[]) {
    // This mutation passes payload to Babylon side
  },

  setBoundingBox2D(state: IVisualizationState, points: BoundingBox2D) {
    state.boundingBox2D = points
  },

  setCharts(state: IVisualizationState, payload: any[]) {
    state.simulation.charts = payload
    state.simulation.activeChart = state.simulation.charts[0]
  },

  setActiveChart(state: IVisualizationState, chartName: string) {
    const chart = state.simulation.charts.find((c) => c.name === chartName)
    state.simulation.activeChart = chart
  },

  setInstancingIsRunning(state: IVisualizationState, isRunning: boolean) {
    state.instancingIsRunning = isRunning
  },

  setPartsVisibility(state: IVisualizationState, payload) {
    // This mutation passes payload to Babylon side
  },

  setCameraPostion(state: IVisualizationState, position: { x: number; y: number; z: number }) {
    state.cameraPosition = position
  },

  setIsSliderActive(state: IVisualizationState, payload: boolean) {
    state.slider.isActive = payload
  },

  setSliderLayerNumber(state: IVisualizationState, payload: number) {
    state.slider.currentLayer = payload
  },

  resizeCanvas() {
    // This mutation passes payload to Babylon side
  },

  setMeshesVisibilityByName(state: IVisualizationState, payload: { name: string; visibility: boolean }) {
    // This mutation passes payload to Babylon side
  },

  setBuildPlateMeshVisibility(state: IVisualizationState, payload: { visibility: boolean; isSinterPlan: boolean }) {
    // This mutation passes payload to Babylon side
  },

  zoomToFitCamera(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  highlightBody(state: IVisualizationState, payload: { id: string; showHighlight: boolean; bpItemId?: string }) {
    // This mutation passes payload to Babylon side
  },

  highlightSupport(
    state: IVisualizationState,
    payload: { bpItemId: string; overhangZoneName: string; showHighlight: boolean },
  ) {
    // This mutation passes payload to Babylon side
  },
  calcGeometryPropsForSinglePart(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  updateScaleForSinglePart(
    state: IVisualizationState,
    payload: { selectedPartId: string; parameterSetScaleFactor: number[] },
  ) {
    // This mutation passes payload to Babylon side
  },

  rebuildSupports(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  updateMoveIncrement(state: IVisualizationState, increment: number) {
    // This mutation passes payload to Babylon side
  },

  updateRotateIncrement(state: IVisualizationState, increment: number) {
    // This mutation passes payload to Babylon side
  },

  updateSupports(state: IVisualizationState, payload: { buildPlanItemId: string; supports: BuildPlanItemSupport[] }) {
    // This mutation passes payload to Babylon side
  },

  setBodiesVisibility(state: IVisualizationState, payload: { ids: string[]; isVisible: boolean }) {
    // This mutation passes payload to Babylon side
  },

  setLabeledBodiesVisibility(state: IVisualizationState, payload: { activeLabelSetId: string; isVisible: boolean }) {
    // This mutation passes payload to Babylon side
  },

  initBuildPlatformCanvas(state, payload: { canvasId: string; buildPlateId: string }) {
    // This mutation passes payload to Babylon side
  },

  setPreviewCreationPromise(state: IVisualizationState, value: { promise: Promise<void>; done: Function }) {
    state.previewCreationPromise = value
  },

  selectBodies(
    state: IVisualizationState,
    payload: { bodyIds: Array<{ buildPlanItemId: string; componentId: string; geometryId: string }>; attach: boolean },
  ) {
    // This mutation passes payload to Babylon side
  },

  selectAllInstances(state: IVisualizationState, payload: { geometryIds: string; attach: boolean }) {
    // This mutation passes payload to Babylon side
  },

  toggleCompensationFileProgress(state: IVisualizationState, status: boolean) {
    state.isCompensationFileInProgress = status
  },

  updateGeometriesTypeOnScene(
    state: IVisualizationState,
    payload: { items: Array<{ bodyIds: string[]; buildPlanItemId: string }>; geometryType: GeometryType },
  ) {
    // This mutation passes payload to Babylon side
  },

  hoverLabel(state: IVisualizationState, payload: string) {
    state.hoveredLabelId = payload
  },

  setOverhangAreasAngle(state: IVisualizationState, payload: number) {
    // This mutation passes payload to Babylon side
  },

  addFailedOverhangZone(state: IVisualizationState, payload: { buildPlanItemId: string; overhangZoneName: string }) {
    // This mutation passes payload to Babylon side
  },

  displaySpatialLetterGrid(
    state: IVisualizationState,
    payload: {
      isVisible: boolean
      settings: GridLetterJSON
    },
  ) {
    // This mutation passes payload to Babylon side
  },

  showPartInstability(state: IVisualizationState, payload: string) {
    state.isPartInstabilityShow = true
  },

  hidePartInstability(state: IVisualizationState) {
    state.isPartInstabilityShow = false
  },

  setRotatePartsIndependentlyMode(state: IVisualizationState, payload: boolean) {
    // This mutation passes payload to Babylon side
  },

  setShowHiddenPartsAsTransparent(state: IVisualizationState, payload) {
    state.showHiddenPartsAsTransparentMode = payload
  },

  showHiddenPartsTransparent(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  hideTransparentParts(state: IVisualizationState) {
    // This mutation passes payload to Babylon side
  },

  setPartConfigLoading(state: IVisualizationState, isLoading: boolean) {
    state.isPartConfigLoading = isLoading
  },
}

const findComponentById = (id: string, components: IComponent[]) => {
  const findById = (componentId: string, component: AssemblyComponent) => {
    if (component.id === componentId) {
      return component
    }

    if (component.children) {
      for (const child of component.children) {
        const result = findById(componentId, child as AssemblyComponent)
        if (result) {
          return result
        }
      }
    }

    return null
  }

  for (const component of components) {
    const result = findById(id, component as AssemblyComponent)
    if (result) {
      return result
    }
  }

  return null
}

const getChildrenIds = (parent: AssemblyComponent) => {
  const getIds = (component: AssemblyComponent, acc: string[] = []) => {
    if (component.children) {
      component.children.forEach((child) => {
        acc.push(child.id)
        return getIds(child as AssemblyComponent, acc)
      })
    }

    return acc
  }

  return getIds(parent, [])
}

const getParentsIds = (id: string, components: IComponent[]) => {
  const getIds = (componentId: string, component: AssemblyComponent, indices: string[]) => {
    if (component.id === id) {
      return indices
    }

    if (component.children) {
      for (const child of component.children) {
        const result = getIds(componentId, child as AssemblyComponent, [...indices, component.id])
        if (result.length) {
          return result
        }
      }
    }

    return []
  }

  for (const component of components) {
    const result = getIds(id, component as AssemblyComponent, [])
    if (result.length) {
      return result
    }
  }

  return []
}

const isPartComponent = (component: IComponent) => {
  return (component as PartComponent).partID
}

const createNewComponentId = (originId: string, components: IComponent[]) => {
  const delimiter = '-'
  let newId: string
  for (let i = 1; ; i += 1) {
    newId = `${originId}${delimiter}${i}`
    if (!findComponentById(newId, components)) {
      return newId
    }
  }
}

const addToModelTree = (originComponent: IComponent, newComponent: IComponent, components: IComponent[]) => {
  const ids = getParentsIds(originComponent.id, components)
  if (!ids.length) {
    components.push(newComponent)
    return
  }

  const parentId = ids[ids.length - 1]
  const parentComponent = findComponentById(parentId, components)
  parentComponent.children.push(newComponent)
}
