import { ItemPermissionsRole } from '@/types/FileExplorer/Permission'

type RoleLevels = { [key in ItemPermissionsRole]: number }

const PERMISSIONS_ROLE_LEVELS: RoleLevels = {
  [ItemPermissionsRole.Owner]: 0,
  [ItemPermissionsRole.CoOwner]: 1,
  [ItemPermissionsRole.Editor]: 2,
  [ItemPermissionsRole.Viewer]: 3,
}

export function isFirstRoleHigher(firstRole: ItemPermissionsRole, secondRole: ItemPermissionsRole) {
  const firstRoleLevel = PERMISSIONS_ROLE_LEVELS[firstRole]
  const secondRoleLevel = PERMISSIONS_ROLE_LEVELS[secondRole]

  return firstRoleLevel < secondRoleLevel
}
