import Vue from 'vue'
import { MutationTree } from 'vuex'
import { IFileExplorerState } from './types'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { DataState } from '@/types/Common/DataState'
import { ViewMode, DetailsPanelViewMode } from '@/types/FileExplorer/ViewMode'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import {
  ItemCollaborationDto,
  Permission,
  PermissionCollaborator,
  PermissionCollaboratorViewModel,
} from '@/types/FileExplorer/Permission'
import { IJob } from '@/types/PartsLibrary/Job'
import { SortParams } from '@/types/FileExplorer/SortParamsKey'
import { FilterBy } from '@/types/FileExplorer/FilterParamsKey'
import { ItemDetailsDto } from '@/types/FileExplorer/ItemDetails'
import localStorageService from '@/services/localStorageService'
import { DEFAULT_PAGINATION_LIMIT, FILE_EXPLORER_TABLE_STATE_KEY } from '@/constants'
import { FileExplorerTableState } from '@/types/FileExplorer/FileExplorerTableState'
import { CanBeTrashedResult } from '@/types/FileExplorer/CanBeTrashed'
import { RelatedItems } from '../../../types/FileExplorer/RelatedItems'
import { ItemType } from '../../../types/FileExplorer/ItemType'
import { IBuildPlanLockDto } from '@/types/BuildPlans/IBuildPlan'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { SearchResultSnapshot } from '@/types/FileExplorer/SearchResultSnapshot'

export const mutations: MutationTree<IFileExplorerState> = {
  setNumberOfHiddenItemsInCurrentFolder(state, numberOfHiddenItems: number) {
    state.numberOfHiddenItemsInCurrentFolder = numberOfHiddenItems
  },

  addItem(state, item: FileExplorerItem) {
    Vue.set(state.items.byId, item.id, item)

    if (state.items.allIds.includes(item.id)) {
      return
    }

    state.items.allIds.push(item.id)
  },

  addNewItem(state, item: FileExplorerItem) {
    Vue.set(state.items.byId, item.id, item)

    if (state.items.allIds.includes(item.id)) {
      return
    }

    state.items.allIds.unshift(item.id)
  },

  addFolderItem(state, item: FileExplorerItem) {
    Vue.set(state.folders.byId, item.id, item)

    if (state.folders.allIds.includes(item.id)) {
      return
    }

    state.folders.allIds.push(item.id)
  },

  updateItem(state, item: FileExplorerItem) {
    Vue.set(state.items.byId, item.id, item)

    // Since folder items are getting stored independently from all of the items, folder items should be updated
    // If an item was changed
    if (item.itemType === ItemType.Folder) {
      state.folders.byId[item.id] = item
    }

    if (state.selectedItems.length) {
      const foundIndex = state.selectedItems.findIndex((selectedItem) => selectedItem.id === item.id)
      if (foundIndex >= 0) {
        Vue.set(state.selectedItems, foundIndex, item)
      }
    }

    const favoriteItemIndex = state.favorites.items.findIndex((i) => i.id === item.id)

    if (favoriteItemIndex !== -1) {
      state.favorites.items[favoriteItemIndex] = item
    }
  },

  updateItemLockInfo(state, itemLockInfo: IBuildPlanLockDto) {
    const item = state.items.byId[itemLockInfo.id]
    if (!item) {
      return
    }

    item.isLocked = itemLockInfo.isLocked
    item.lockWhitelist = itemLockInfo.lockWhitelist
  },

  setLastAddedItem(state, item: FileExplorerItem) {
    state.lastAddedItem = item
  },

  deleteItem(state, item: FileExplorerItem) {
    Vue.delete(state.items.byId, item.id)

    state.items.allIds = state.items.allIds.filter((id) => id !== item.id)
    state.items.recentIds = state.items.recentIds.filter((id) => id !== item.id)
    state.items.sharedIds = state.items.sharedIds.filter((id) => id !== item.id)

    state.favorites.items = state.favorites.items.filter((favItem) => favItem.id !== item.id)
  },

  deleteItemById(state, itemId: string) {
    Vue.delete(state.items.byId, itemId)

    state.items.allIds = state.items.allIds.filter((id) => id !== itemId)
    state.items.recentIds = state.items.recentIds.filter((id) => id !== itemId)
    state.items.sharedIds = state.items.sharedIds.filter((id) => id !== itemId)
  },

  setRecentItem(state, item) {
    Vue.set(state.items.byId, item.id, item)

    state.items.recentIds = [...new Set([...state.items.recentIds, item.id])]
  },

  setSharedItem(state, item) {
    Vue.set(state.items.byId, item.id, item)

    state.items.sharedIds = [...new Set([...state.items.sharedIds, item.id])]
  },

  unsetSharedItem(state, item) {
    Vue.set(state.items.byId, item.id, item)

    state.items.sharedIds = state.items.sharedIds.filter((id) => id !== item.id)
  },

  setRoot(state, item: FileExplorerItem) {
    if (item && item.id) {
      state.root = item
    } else {
      state.root = null
    }

    if (state.isMoveItems) {
      return
    }

    // Clear selection when root changes
    state.selectedItems = []
  },

  clearItems(state) {
    state.items.allIds = []
    state.items.byId = {}
    state.items.sharedIds = []
  },

  clearFolders(state) {
    state.folders.allIds = []
    state.folders.byId = {}
  },

  clearRecentItems(state) {
    state.items.recentIds = []
  },

  clearSharedItems(state) {
    state.items.sharedIds = []
  },

  clearFavorites(state) {
    state.favorites.snapshot = []
    state.favorites.items = []
    state.favorites.pageRange = [0, DEFAULT_PAGINATION_LIMIT]
  },

  clearSearchResult(state) {
    state.search.snapshot = []
    state.search.items = []
    state.search.pageRange = [0, DEFAULT_PAGINATION_LIMIT]
  },

  setDataState(state, payload: { state: DataState; errorText?: string }) {
    const { state: dataState, errorText = '' } = payload
    switch (dataState) {
      case DataState.Loading:
        const { numberOfPendingRequests } = state.dataState
        // remove previous failed requests
        if (numberOfPendingRequests === 0) {
          state.dataState.numberOfFailedRequests = 0
          state.dataState.failedMessages = []
        }

        state.dataState.numberOfPendingRequests += 1
        return

      case DataState.Loaded:
        if (state.dataState.numberOfPendingRequests !== 0) {
          state.dataState.numberOfPendingRequests -= 1
        }
        return

      case DataState.Failed:
        if (state.dataState.numberOfPendingRequests !== 0) {
          state.dataState.numberOfPendingRequests -= 1
        }
        state.dataState.numberOfFailedRequests += 1
        state.dataState.failedMessages.push(errorText)
        return
    }
  },

  fetchFavoritesSnapshotDone(state, favorites: Array<{ id: string; name: string }>) {
    favorites.forEach((item) => {
      state.favorites.snapshot.push(item)
    })
  },

  fetchFavoritesByIdsDone(state, favorites: FileExplorerItem[]) {
    favorites.forEach((item) => {
      const index = state.favorites.items.findIndex((i) => i.id === item.id)
      if (index >= 0) {
        state.favorites.items.splice(index, 1, item)
      } else {
        state.favorites.items.push(item)
      }
    })
  },

  setFavoritePageRange(state, range: [number, number]) {
    state.favorites.pageRange = range
  },

  setFavoriteItemsIds(state, ids: string[]) {
    state.items.favoriteIds = ids
  },

  addToFavorites(state, itemId) {
    state.items.favoriteIds.push(itemId)
  },

  removeFromFavorites(state, itemId) {
    const itemIdIndex = state.items.favoriteIds.findIndex((id) => id === itemId)
    if (itemIdIndex !== -1) {
      state.items.favoriteIds.splice(itemIdIndex, 1)
    }

    state.favorites.snapshot = state.favorites.snapshot.filter((id) => id !== itemId)
    state.favorites.items = state.favorites.items.filter((item) => item.id !== itemId)
  },

  setPermissions(state, permissions: Permission[]) {
    // TODO: Do it in a bulk
    permissions.forEach((permission) => {
      Vue.set(state.permissions.byId, permission.id, permission)
    })
  },

  updateItemPermissions(state, permissions: Permission[]) {
    permissions.forEach((permission) => {
      Vue.set(state.permissions.byId, permission.id, permission)
    })
  },

  deletePermissions(state, ids: string[]) {
    ids.forEach((id) => {
      Vue.delete(state.permissions.byId, id)
    })
  },

  selectItem(state, payload: { item: FileExplorerItem | PrintOrder; selectionType: SelectionTypes }) {
    if (payload.item.id) {
      switch (payload.selectionType) {
        case SelectionTypes.Single:
          state.selectedItems = [payload.item]
          break

        case SelectionTypes.Multiple:
          state.selectedItems.push(payload.item)
          break
      }
    }
  },

  unselectItem(state, payload: { item: FileExplorerItem | PrintOrder; selectionType: SelectionTypes }) {
    if (payload.item.id) {
      switch (payload.selectionType) {
        case SelectionTypes.Single:
          state.selectedItems = []
          break

        case SelectionTypes.Multiple:
          state.selectedItems = state.selectedItems.filter((o) => o.id !== payload.item.id)
          break
      }
    }
  },

  unselectAllItems(state) {
    if (state.isMoveItems) return

    state.selectedItems = []
  },

  setClickedNameItem(state, item: FileExplorerItem) {
    state.clickedNameItem = item
  },

  setViewMode(state, viewMode: ViewMode) {
    state.viewMode = viewMode

    const storage = localStorageService.get(FILE_EXPLORER_TABLE_STATE_KEY)
    localStorageService.set(FILE_EXPLORER_TABLE_STATE_KEY, { ...storage, viewMode })
  },

  setSortParams(state, { key, sort }: { key: string; sort: SortParams }) {
    state.sortParams = { ...state.sortParams, ...{ [key]: sort } }

    const storage = localStorageService.get(FILE_EXPLORER_TABLE_STATE_KEY)
    localStorageService.set(FILE_EXPLORER_TABLE_STATE_KEY, { ...storage, sort: state.sortParams })
  },

  setFilterParams(state, { key, filterBy }: { key: string; filterBy: FilterBy }) {
    state.filterParams = { ...state.filterParams, ...{ [key]: filterBy } }

    const storage = localStorageService.get(FILE_EXPLORER_TABLE_STATE_KEY)
    localStorageService.set(FILE_EXPLORER_TABLE_STATE_KEY, { ...storage, filters: state.filterParams })
  },

  toggleDetailsPanel(state) {
    state.isOpenDetailsPanel = !state.isOpenDetailsPanel
  },

  setDetailsPanelMode(state, mode: DetailsPanelViewMode) {
    state.detailsPanelMode = mode
  },

  setDetailsRequestRaceConditionObj(state, obj: object) {
    state.requestRaceConditionTokenObjects.detailsRequest = obj
  },

  setIsMoveItems(state, val: boolean) {
    state.rootOfMovingItems = state.root
    state.isMoveItems = val
  },

  resetIsMoveItems(state) {
    state.isMoveItems = false
  },

  setJobs(state, jobs: IJob[]) {
    jobs.forEach((job) => {
      const itemJobs = state.jobs.byItemId[job.itemId]
      const newItemJobs = itemJobs ? JSON.parse(JSON.stringify(itemJobs)) : []
      const jobIndex = newItemJobs ? newItemJobs.findIndex((j) => j.id === job.id) : -1

      if (newItemJobs.length) {
        if (jobIndex !== -1) {
          newItemJobs[jobIndex] = job
        } else {
          newItemJobs.push(job)
        }
      } else {
        newItemJobs.push(job)
      }

      Vue.set(state.jobs.byItemId, job.itemId, newItemJobs)
    })
  },

  setRenameDialogState(state, value: boolean) {
    state.isOpenRenameDialog = value
  },

  setMoveToTrashErrorDialogState(state, value: boolean) {
    state.isOpenMoveToTrashErrorDialog = value
  },

  setMoveToTrashItemDialogState(state, value: boolean) {
    state.isOpenMoveToTrashItemDialog = value
  },

  addItemPermissionCollaborators(state, payload: { id: string; collaborators: PermissionCollaborator[] }) {
    Vue.set(state.itemPermissionCollaborators.byId, payload.id, payload.collaborators)
    state.itemPermissionCollaborators.allIds.push(payload.id)
  },

  setItemDetails(state, itemDetails: ItemDetailsDto) {
    // TODO: UPDATE LOGIC TO WORK WITH SIMPLE DETAILS DATA (next optimization)
    if (itemDetails.details) {
      const { details, item } = itemDetails

      Vue.set(state.items.byId, item.id, item)

      state.itemDetails = details
    } else {
      state.itemDetails = itemDetails as any
    }
  },

  setForceUpdateDetails(state, value: boolean) {
    state.isForceUpdateDetails = value
  },

  unsetCollaborationData(state) {
    state.collaborationData = {
      canManageCollaboration: null,
      collaborators: [],
      amountOfNestedItemsWithoutPermissionsToView: 0,
      grantedParentPermissions: [],
    }
  },

  setCollaborationData(
    state,
    {
      canManageCollaboration,
      collaborators,
      amountOfNestedItemsWithoutPermissionsToView,
      grantedParentPermissions,
    }: ItemCollaborationDto,
  ) {
    state.collaborationData = {
      canManageCollaboration,
      collaborators,
      amountOfNestedItemsWithoutPermissionsToView,
      grantedParentPermissions,
    }
  },

  setSelectedItemsCanBeTrashed(state, results: CanBeTrashedResult) {
    state.canBeTrashedSelectedItemsResult = results
  },

  setRelatedItemsInfo(state, values: RelatedItems) {
    state.trashActionsInfo.hasDeletePermission = values.hasDeletePermission
    state.trashActionsInfo.relatedItemsToDelete = [...values.itemsRelatedToDelete]

    state.trashActionsInfo.hasRestorePermission = values.hasRestorePermission
    state.trashActionsInfo.relatedItemsToRestore = [...values.itemsRelatedToRestore]
  },

  clearRelatedItemsInfo(state) {
    state.trashActionsInfo.hasDeletePermission = false
    state.trashActionsInfo.relatedItemsToDelete = []
    state.trashActionsInfo.hasRestorePermission = false
    state.trashActionsInfo.relatedItemsToRestore = []
  },

  setIsShownLockedBuildPlanModal(state, value: boolean) {
    state.isShownLockedBuildPlanModal = value
  },

  setLockItemInfo(state, payload: { editVariant: IBuildPlanLockDto; previewVariant: IBuildPlanLockDto }) {
    state.lockItemInfo = { editVariant: payload.editVariant, previewVariant: payload.previewVariant }
  },

  setSearchParameters(state, searchParameters) {
    state.search.parameters = searchParameters
  },

  fetchSearchResultSnapshotDone(state, result: SearchResultSnapshot[]) {
    state.search.snapshot = [...result]
  },

  fetchSearchResultByIdsDone(state, result: FileExplorerItem[]) {
    const uniqItemSet = new Set(state.search.items.map((item) => item.id))

    const filteredResult = result.filter((item) => {
      const isPresentInSet = uniqItemSet.has(item.id)
      uniqItemSet.add(item.id)
      return !isPresentInSet
    })

    filteredResult.forEach((item) => {
      state.search.items.push(item)
    })
  },

  setSearchResultPageRange(state, range: [number, number]) {
    state.search.pageRange = range
  },

  addSearchResultItem(state, item) {
    state.search.items.push(item)
  },

  removeFromSearchResult(state, itemId) {
    state.search.snapshot = state.search.snapshot.filter((snapshotDto) => snapshotDto.id !== itemId)
    state.search.items = state.search.items.filter((item) => item.id !== itemId)
  },

  changeCurrentPage(state, currentPage) {
    state.paginationData.currentPage = currentPage
  },

  updateTotalPages(state, totalPages) {
    state.paginationData.totalPages = totalPages
  },

  clearPaginationData(state) {
    state.paginationData = {
      currentPage: 0,
      totalItems: null,
      totalPages: null,
      size: DEFAULT_PAGINATION_LIMIT,
    }
  },

  addCollaboratorToRemoveList(state, collaborator: PermissionCollaboratorViewModel) {
    state.collaboratorsToRemoveList.push(collaborator)
  },

  clearCollaboratorsToRemoveList(state, collaborator: PermissionCollaboratorViewModel) {
    state.collaboratorsToRemoveList = []
  },

  setIsShownCreateBuildPlanDialog(state, value: boolean) {
    state.isShownCreateBuildPlanDialog = value
  },

  setIsShownCreateSinterPlanDialog(state, value: boolean) {
    state.isShownCreateSinterPlanDialog = value
  },

  setIsShownCreateFolderModal(state, value: boolean) {
    state.isShownCreateFolderModal = value
  },

  setNumberOfHiddenItemsInFavorites(state, numberOfHiddenItemsInFavorites: number) {
    state.numberOfHiddenItemsInFavorites = numberOfHiddenItemsInFavorites
  },

  setNumberOfHiddenItemsInRecent(state, numberOfHiddenItemsInRecent: number) {
    state.numberOfHiddenItemsInRecent = numberOfHiddenItemsInRecent
  },

  setItemsWithoutAccess(state, items: FileExplorerItem[]) {
    state.itemsWithoutAccess = items
  },

  setIsPrintOrderDetailsLoading(state, status: boolean) {
    state.isPrintOrderDetailsLoading = status
  },

  setIsAllowedToEndCollaboration(state, value: boolean) {
    state.isAllowedToEndCollaboration = value
  },
}
