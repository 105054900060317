
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop, Mixins, Watch } from 'vue-property-decorator'
import { InsightMixin } from '@/components/layout/buildPlans/mixins/InsightMixin'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { ILabel } from '@/types/Marking/ILabel'
import { RouterNames } from '@/router'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { DisableableInsightMixin } from '@/components/layout/buildPlans/mixins/DisableableInsightMixin'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { InteractiveLabelSetNamedList } from '@/types/Label/InteractiveLabelSetNamedList'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const labelStore = namespace(StoresNamespaces.Labels)

@Component({
  components: {},
})
export default class LabelIssueByInsight extends Mixins(Vue, InsightMixin, DisableableInsightMixin) {
  @buildPlansStore.Getter getAllBuildPlanMarkLabels: ILabel[]
  @buildPlansStore.Getter selectedLabel: ILabel
  @buildPlansStore.Getter insightSelectionEnabled: boolean
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes
  @buildPlansStore.Getter isLabelReadOnly: boolean
  @buildPlansStore.Getter isLabelToolPreparing: boolean
  @visualizationStore.Getter viewMode: { name: ViewModeTypes; isSelectable: boolean }
  @visualizationStore.Getter isPrintOrderPreviewViewMode: boolean
  @labelStore.Getter isLabelToolOpened: boolean
  @labelStore.Getter activeLabelSet: InteractiveLabelSet
  @labelStore.Getter getLabelSetById: (labelSet: string) => InteractiveLabelSet
  @labelStore.Getter namedList: (additionalLabelSets?: InteractiveLabelSet[]) => InteractiveLabelSetNamedList[]

  @buildPlansStore.Mutation setSelectedLabel: Function
  @visualizationStore.Mutation toggleLabelHighlight: Function
  @labelStore.Mutation setHighlightedLabelSetId: (labelSetId: string) => void

  @labelStore.Action deHighlightLabels: Function
  @labelStore.Action highlightLabels: Function
  @labelStore.Action setActiveLabelSet: (labelSet: InteractiveLabelSet) => void

  @Prop() insight: IBuildPlanInsight

  private isOpening: boolean = false

  get labelSetName(): string {
    const { details = {} } = this.insight
    const { relatedItems } = details
    const hasRelatedItems = relatedItems && Array.isArray(relatedItems) && relatedItems.length > 0

    if (hasRelatedItems) {
      const labelSetId = details.relatedItems[0].labelSetId
      const labelSet = this.getLabelSetById(labelSetId)
      return labelSet ? labelSet.settings.labelSetName : `LabelSet`
    }
    return 'Label'
  }

  get isLabelSelectionDisabled(): boolean {
    return (
      this.getBuildPlanViewMode &&
      this.getBuildPlanViewMode !== ViewModeTypes.Layout &&
      this.getBuildPlanViewMode !== ViewModeTypes.Marking
    )
  }

  get isLabelViewMode(): boolean {
    return this.viewMode.name === ViewModeTypes.Marking
  }

  get isLabelSetOpened(): boolean {
    return this.activeLabelSet && this.insight.details.relatedItems[0].labelSetId === this.activeLabelSet.id
  }

  async clickOnLabelSet() {
    if (!this.insightSelectionEnabled || this.isLabelSelectionDisabled) {
      return
    }

    if (!this.isLabelViewMode) {
      this.isOpening = true
      // @ts-ignore
      await this.$router.safePush({ name: RouterNames.EBP_Label })
      if (this.isLabelReadOnly) {
        this.openLabelSetFromInsight()
      }
    } else {
      this.openLabelSetFromInsight()
    }
  }

  highlightLabelSet() {
    if (!this.insight.details || !this.insight.details.relatedItems) {
      return
    }

    const labelSetId = this.insight.details.relatedItems[0].labelSetId
    const labelSet = this.getLabelSetById(labelSetId)
    const options = []
    const bodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]
    if (this.isPrintOrderPreviewViewMode) {
      labelSet.patches.forEach((patch) => {
        const option = {
          parentId: patch.buildPlanItemId,
          componentId: patch.componentId,
          geometryId: patch.geometryId,
          patchId: patch.id,
          isPrintOrderPreviewLabel: true,
        }

        options.push(option)
      })
    } else {
      bodies.forEach((patch) => {
        const option = {
          labelSetId,
          parentId: patch.buildPlanItemId,
          componentId: patch.componentId,
          geometryId: patch.geometryId,
        }

        options.push(option)
      })
    }

    this.setHighlightedLabelSetId(labelSet.id)
    this.highlightLabels(options)
  }

  removeHighlightFromLabelSet() {
    if (!this.insight.details || !this.insight.details.relatedItems) {
      return
    }
    const labelSetId = this.insight.details.relatedItems[0].labelSetId
    const labelSet = this.getLabelSetById(labelSetId)
    const options = []
    const bodies = [...labelSet.selectedBodies, ...labelSet.relatedBodies]
    if (this.isPrintOrderPreviewViewMode) {
      labelSet.patches.forEach((patch) => {
        const option = {
          parentId: patch.buildPlanItemId,
          componentId: patch.componentId,
          geometryId: patch.geometryId,
          patchId: patch.id,
          isPrintOrderPreviewLabel: true,
        }

        options.push(option)
      })
    } else {
      bodies.forEach((patch) => {
        const option = {
          labelSetId,
          parentId: patch.buildPlanItemId,
          componentId: patch.componentId,
          geometryId: patch.geometryId,
        }

        options.push(option)
      })
    }

    this.setHighlightedLabelSetId(null)
    this.deHighlightLabels(options)
  }

  @Watch('isLabelToolOpened')
  private labelSetActivation() {
    if (this.isOpening && this.isLabelToolOpened) {
      this.isOpening = false
      this.openLabelSetFromInsight()
    }
  }

  private openLabelSetFromInsight() {
    if (this.insight.details && this.insight.details.relatedItems) {
      const labelSetId = this.insight.details.relatedItems[0].labelSetId
      const labelSet = this.getLabelSetById(labelSetId)
      this.setActiveLabelSet(labelSet)
    }
  }
}
