import { render, staticRenderFns } from "./SharedByMeItemHeader.vue?vue&type=template&id=60071b76&scoped=true"
import script from "./SharedByMeItemHeader.vue?vue&type=script&lang=ts"
export * from "./SharedByMeItemHeader.vue?vue&type=script&lang=ts"
import style0 from "./SharedByMeItemHeader.vue?vue&type=style&index=0&id=60071b76&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60071b76",
  null
  
)

export default component.exports