
import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { IBuildPlan, IBuildPlanItem, ISelectable } from '@/types/BuildPlans/IBuildPlan'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { ILayoutInsightDetails } from '@/types/Common/Insights'
import { SelectablePartInsightMixin } from '@/components/layout/buildPlans/mixins/SelectablePartInsightMixin'
import Vue from 'vue'
import { InsightMixin } from '@/components/layout/buildPlans/mixins/InsightMixin'
import { DisableableInsightMixin } from '@/components/layout/buildPlans/mixins/DisableableInsightMixin'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { ItemSubType } from '@/types/FileExplorer/ItemType'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component({
  components: {},
})
export default class LayoutIssueByInsight extends Mixins(
  Vue,
  InsightMixin,
  SelectablePartInsightMixin,
  DisableableInsightMixin,
) {
  //region Build Plan store
  @buildPlansStore.Getter buildPlanItemById: (id: string) => IBuildPlanItem
  @buildPlansStore.Getter insightSelectionEnabled: boolean
  @buildPlansStore.Getter('getSelectedParts') selectedParts: ISelectable[]
  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes
  //endregion Build Plan store

  // region Visualization store
  @visualizationStore.Mutation selectAndHighlightPart: (payload: {
    buildPlanItemId: string
    meshId: string
    deselectIfSelected: boolean
    showGizmo: boolean
  }) => void
  @visualizationStore.Mutation selectAndHighlightParts: (payload: {
    buildPlanItemIds: string[]
    deselectIfSelected: boolean
    showGizmo: boolean
  }) => void
  @visualizationStore.Mutation selectAndHighlightPartWithAttach: Function
  @visualizationStore.Mutation toggleHighlight: (payload: {
    buildPlanItemId: string
    meshId: string
    highlight: boolean
  }) => void
  @visualizationStore.Mutation deselect: () => void
  //endregion Visualization store

  @Prop() insight: IBuildPlanInsight

  get partName(): string {
    let name = this.insight.details.partName
    if (this.insight.details.bpItemId) {
      const bpItem = this.buildPlanItemById(this.insight.details.bpItemId)
      if (bpItem) {
        name = bpItem.part.name
      }
    }

    return name
  }

  hoverIntoPart(details: ILayoutInsightDetails) {
    this.toggleHighlight({ buildPlanItemId: details.bpItemId, meshId: details.meshId, highlight: true })
  }

  hoverOutOfPart(details: ILayoutInsightDetails) {
    this.toggleHighlight({ buildPlanItemId: details.bpItemId, meshId: details.meshId, highlight: false })
  }

  clickOnPart(details: ILayoutInsightDetails, ev) {
    if (!this.insightSelectionEnabled || this.isPartSelectionDisabled) {
      return
    }

    const isPartSelected = this.selectedParts.find((part) => part.id === details.bpItemId)
    if ((!isPartSelected || this.selectedParts.length > 1) && !(ev.ctrlKey || ev.metaKey)) {
      this.deselect()
    }

    if (this.getBuildPlanViewMode === ViewModeTypes.Rotate && this.buildPlan.subType === ItemSubType.SinterPlan) {
      this.selectAndHighlightPartWithAttach({
        buildPlanItemIds: this.buildPlan.buildPlanItems.map((bpItem) => bpItem.id),
      })
    } else if (ev.ctrlKey || ev.metaKey) {
      this.selectAndHighlightParts({
        buildPlanItemIds: [details.bpItemId],
        deselectIfSelected: true,
        showGizmo: true,
      })
    } else {
      this.selectAndHighlightPart({
        buildPlanItemId: details.bpItemId,
        meshId: details.meshId,
        deselectIfSelected: true,
        showGizmo: true,
      })
    }

    const canvasEl = document.getElementById('visualization_canvas')
    canvasEl.focus()
  }
}
