
import Vue from 'vue'
import Component from 'vue-class-component'
import { extend, ValidationObserver } from 'vee-validate'
import { namespace } from 'vuex-class'
import { required, email } from 'vee-validate/dist/rules'
import Selector from '@/components/controls/Common/Selector.vue'
import TextField from '@/components/controls/Common/TextField.vue'
import { IUser } from '@/types/User/IUser'
import munchkin from '@/utils/marketo/munchkin'
import messageService from '@/services/messageService'
import StoresNamespaces from '@/store/namespaces'
import router from '@/router'

const userStore = namespace(StoresNamespaces.User)

extend('required', { ...required, message: 'This field is required' })
extend('email', { ...email, message: 'Please enter a valid email' })

@Component({
  components: {
    TextField,
    Selector,
  },
})
export default class ProgressiveRegistration extends Vue {
  @userStore.Action setUserDetails: any
  @userStore.Action updateUser: any
  @userStore.Getter getUserDetails: IUser

  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  user: IUser
  consentGiven: boolean = false
  serviceAgreementLink = window.env.VUE_APP_SERVICE_AGREEMENT_URL

  beforeMount() {
    this.user = this.getUserDetails
  }

  async onOkClick() {
    this.updateUser(this.user)
      .then((response) => {
        const message = this.$i18n.t('updatingUserProfileSuccess') as string
        messageService.showSuccessMessage(message)
        this.user.consentGiven = true
        this.setUserDetails(this.user)
        munchkin.associateLead(this.user)
        // @ts-ignore
        router.safePush(router.defaultRoute)
      })
      .catch((error) => {
        const message = `${this.$i18n.t('updatingUserProfileError')} ${error.data}`
        messageService.showErrorMessage(message)
      })
  }
}
