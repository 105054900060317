
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import i18n from '@/plugins/i18n'
import Menu from '@/components/controls/Common/Menu.vue'
import { convert } from '@/utils/converter/lengthConverter'
import { createGuid } from '@/utils/common'

import {
  GeometryType,
  IBuildPlan,
  IBuildPlanItem,
  IDisplayToolbarState,
  IPrintStrategyParameterSet,
  ISelectable,
  IUpdateBuildPlanItemParamsDto,
  PartProperty,
  ProcessState,
} from '@/types/BuildPlans/IBuildPlan'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { VersionableModel } from '@/types/Common/VersionableModel'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { IJob } from '@/types/PartsLibrary/Job'
import PartPropertiesToolbarMixin from '@/components/layout/buildPlans/mixins/PartPropertiesToolbarMixin'

import StoresNamespaces from '@/store/namespaces'
import messageService from '@/services/messageService'
import {
  BodyTypeIcons,
  DEFAULT_PRINT_STRATEGY_PARAMETER_SET_ID,
  DEFAULT_PRINT_STRATEGY_PARAMETER_SET_VERSION,
} from '@/constants'
import { Prop } from 'vue-property-decorator'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'
import LockedBodyFunctionModal from '../modals/LockedBodyFunctionModal.vue'
import { isBuildPlanItemBodyFunctionLocked } from '@/utils/buildPlan/buildPlanItemUtil'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const commonStore = namespace(StoresNamespaces.Common)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const labelsStore = namespace(StoresNamespaces.Labels)

interface PartPropGeometryKey {
  type: number
  bodyType: string
  printStrategyParameterSetId: number
  printStrategyParameterSetVersion: number
}

@Component({
  components: {
    Menu,
    LockedBodyFunctionModal,
  },
})
export default class PartPropertiesToolbarBJT extends PartPropertiesToolbarMixin {
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter isLockedForViewer: boolean
  @buildPlansStore.Getter getIsVariantLockedForUserByVariantId: (id: string) => boolean
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes
  @buildPlansStore.Getter getSelectedBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter isSinterPartSelected: boolean
  @buildPlansStore.Getter getBuildPlanPrintStrategy: BuildPlanPrintStrategyDto
  @buildPlansStore.Getter parameterSetsLatestVersions: IPrintStrategyParameterSet[]
  @buildPlansStore.Getter displayToolbarStateByVariantId: (buildPlanId: string) => IDisplayToolbarState
  @buildPlansStore.Getter defaultPartParamOptionAvailable: boolean
  @buildPlansStore.Getter getIsLoading: boolean
  @buildPlansStore.Getter getRunningJobsByVariantId: (id: string) => IJob[]
  @buildPlansStore.Getter getCompleteVariantSlicingJobs: (id: string) => IJob[]
  @buildPlansStore.Getter getSelectedBuildPlanExistingSimCompJobs: IJob[]
  @buildPlansStore.Getter getIBCPlan: IIBCPlan

  @visualizationStore.Mutation setIsLoading: Function

  @visualizationStore.Action updateGeometriesOnTypeChange: Function

  @labelsStore.Action updateRelatedLabelsOnBodyFunctionChangeBatch: (
    d: Array<{
      before: PartProperty
      after: PartProperty
      bpItem: IBuildPlanItem
    }>,
  ) => Promise<void>

  @commonStore.Getter tooltipOpenDelay: number

  @Prop({ default: false }) hideActionItems: boolean

  isShownBodyFunctionMenu: boolean = false
  isShownProcessStateMenu: boolean = false
  isShownPartParamsMenu: boolean = false
  isShownLockedBodyFunctionModal: boolean = false
  processStateEnum = ProcessState

  bodyFunctionTooltip = false
  processStateTooltip = false
  partParameterTooltip = false

  get isDisabled() {
    if (this.getIBCPlan) return true

    const hasRunningJobs = this.getRunningJobsByVariantId(this.getBuildPlan.id).length > 0
    const hasCompletedSlicing = this.getCompleteVariantSlicingJobs(this.getBuildPlan.id).length > 0
    const hasSimCompResults = this.getSelectedBuildPlanExistingSimCompJobs.length > 0
    const isEditedBySomeone =
      this.getBuildPlan.isLocked && this.getIsVariantLockedForUserByVariantId(this.getBuildPlan.id)
    const isSomeViewModeActive = !!this.getBuildPlanViewMode

    return (
      hasRunningJobs ||
      hasSimCompResults ||
      hasCompletedSlicing ||
      isEditedBySomeone ||
      isSomeViewModeActive ||
      this.isLockedForViewer
    )
  }

  get isPreScaledDisabled() {
    return !(
      (this.getSelectedBuildPlanItems.length === 1 && !this.isSinterPartSelected) ||
      this.getSelectedBuildPlanItems.length > 1
    )
  }

  get bodyTypes() {
    return [
      {
        value: GeometryType.Production,
        name: this.$i18n.t('production').toString(),
        icon: BodyTypeIcons.production,
      },
      {
        value: GeometryType.Support,
        name: this.$i18n.t('support').toString(),
        icon: BodyTypeIcons.support,
      },
      {
        value: GeometryType.Coupon,
        name: this.$i18n.t('coupon').toString(),
        icon: BodyTypeIcons.coupon,
      },
    ]
  }

  get processStateOptions() {
    return [
      {
        value: ProcessState.Nominal,
        name: this.$t('applyScaling').toString(),
        icon: 'mdi-resize',
        tooltip: this.$t('partPropsToolbar.ampScaledTooltip'),
      },
      {
        value: ProcessState.Green,
        name: this.$t('doNotScale').toString(),
        icon: 'mdi-rectangle-outline',
        tooltip: this.$t('partPropsToolbar.preScaledTooltip'),
      },
    ]
  }
  get isProcessStateChangeOptionsEnabled() {
    return !this.getSelectedBuildPlanItems.some(
      (item) => item.part.itemType === ItemType.BuildPart && item.part.subType === ItemSubType.None,
    )
  }

  get partParams() {
    const allParams = this.parameterSetsLatestVersions.map((parameter) => {
      const layerThickness = parseFloat(convert('m', 'mm', parameter.layerThickness).toPrecision(3))
      const thicknessText = parameter.id ? ` - ${layerThickness}mm` : ''
      const name = parameter.name + thicknessText
      return {
        name,
        value: VersionableModel.getPk(parameter),
      }
    })

    if (this.defaultPartParamOptionAvailable) {
      const paramSet = {
        name: i18n.t('partPropsToolbar.defaultPartParameter') as string,
        value: null,
      }
      allParams.unshift(paramSet)
      return allParams
    }
  }

  get isProcessStateVisible() {
    return this.getBuildPlan.subType === ItemSubType.None && !this.getIBCPlan
  }

  get isButtonDisabled() {
    return this.getIsLoading || this.isDisabled
  }

  get lockedParts() {
    const parts = this.getSelectedBuildPlanItems.filter(isBuildPlanItemBodyFunctionLocked).map((item) => item.part)
    return [...new Map(parts.map((part) => [part.id, part])).values()]
  }

  getIconColor(isLocked: boolean, isActive: boolean): string {
    if (isLocked) {
      return '#00000066'
    }

    return isActive ? 'blue' : 'primary_grey'
  }

  toggleBodyFunctionMenu() {
    if (this.isButtonDisabled) {
      return
    }

    this.bodyFunctionTooltip = false
    this.isShownBodyFunctionMenu = !this.isShownBodyFunctionMenu
  }

  toggleProcessStateMenu() {
    if (this.isButtonDisabled) {
      return
    }

    this.processStateTooltip = false
    this.isShownProcessStateMenu = !this.isShownProcessStateMenu
  }

  togglePartParamsMenu() {
    if (this.isButtonDisabled) {
      return
    }

    this.partParameterTooltip = false
    this.isShownPartParamsMenu = !this.isShownPartParamsMenu
  }

  toggleLockedBodyFunctionModal() {
    this.isShownLockedBodyFunctionModal = !this.isShownLockedBodyFunctionModal
  }

  async onProcessStateChange(newProcessState: ProcessState) {
    this.setIsLoading(true)

    const legacyPartNames: string[] = []

    try {
      const dtos = this.getSelectedBuildPlanItems
        .map((item) => {
          if (item.part && item.part.isPublishedAsScaled) {
            legacyPartNames.push(item.part.name)
            return null
          }

          const partProperties = JSON.parse(JSON.stringify(item.partProperties))
          partProperties.forEach((partProperty) => (partProperty.processState = newProcessState))

          const buildPlanItemDto: IUpdateBuildPlanItemParamsDto = {
            partProperties,
            buildPlanItemId: item.id,
          }

          return buildPlanItemDto
        })
        .filter((item) => item)

      if (legacyPartNames.length) {
        this.$emit('toggle-process-state-change-modal', true, {
          sinterPartsCount: legacyPartNames.length,
          partNames: legacyPartNames,
          processStateName: newProcessState,
        })
      }

      if (dtos.length) {
        await this.updateBuildPlanItems(dtos)
      }
    } catch (error) {
      const msg = i18n.t('partPropsToolbar.updatePartPropertiesFailed') as string
      messageService.showErrorMessage(`${msg} ${error.message}`)
    } finally {
      this.setIsLoading(false)
    }
  }

  async onChangeBodyFunction(value: GeometryType) {
    this.setIsLoading(true)
    try {
      const partPropsMap: { [key: string]: PartProperty[] } = {}
      const bpItemsToProcess = this.getSelectedBuildPlanItems.filter((item) => {
        return item.partProperties.length && !isBuildPlanItemBodyFunctionLocked(item)
      })

      const bpItemsDto = bpItemsToProcess.map((item) => {
        const partProperties = JSON.parse(JSON.stringify(item.partProperties))
        // updating a part property
        partProperties.forEach((partProperty) => (partProperty.type = value))

        partPropsMap[item.id] = partProperties

        const buildPlanItemDto: IUpdateBuildPlanItemParamsDto = {
          partProperties,
          buildPlanItemId: item.id,
        }

        return buildPlanItemDto
      })

      if (this.getSelectedBuildPlanItems.some(isBuildPlanItemBodyFunctionLocked)) {
        this.toggleLockedBodyFunctionModal()
      }

      if (bpItemsDto.length) {
        this.updateSceneGeometries(partPropsMap, value)
        await this.updateBuildPlanItems(bpItemsDto)
        const bodyProperties = []
        for (const bpItem of bpItemsToProcess) {
          for (const before of bpItem.partProperties) {
            const bpItemDto = bpItemsDto.find((dto) => dto.buildPlanItemId === bpItem.id)
            if (bpItemDto) {
              const after = bpItemDto.partProperties.find((partProperty) => partProperty.id === before.id)
              bodyProperties.push({ bpItem, before, after })
            }
          }
        }

        if (bodyProperties.length) {
          await this.updateRelatedLabelsOnBodyFunctionChangeBatch(bodyProperties)
        }
      }
    } catch (error) {
      const msg = i18n.t('partPropsToolbar.updatePartPropertiesFailed') as string
      messageService.showErrorMessage(`${msg} ${error.message}`)
    } finally {
      this.setIsLoading(false)
    }
  }

  isBuildPlanItemBodyFunctionLocked(item: IBuildPlanItem) {
    throw new Error('Method not implemented.')
  }

  async onChangePartParams(paramValue: VersionablePk) {
    this.setIsLoading(true)
    const bpItems = this.getSelectedBuildPlanItems.filter((item) => {
      return item.partProperties.length
    })
    try {
      let partPropsUpdateDto: IUpdateBuildPlanItemParamsDto[]
      if (paramValue) {
        partPropsUpdateDto = bpItems.map((bpItem) => {
          const groupId = createGuid()
          const partProperties = bpItem.partProperties.map((partProperty) => {
            partProperty.printStrategyParameterSetId = paramValue.id
            partProperty.printStrategyParameterSetVersion = paramValue.version
            partProperty.groupId = groupId
            return partProperty
          })

          const buildPlanItemDto: IUpdateBuildPlanItemParamsDto = {
            partProperties,
            buildPlanItemId: bpItem.id,
          }

          return buildPlanItemDto
        })
      } else {
        // default part params
        const defaults = this.getBuildPlanPrintStrategy.defaults

        partPropsUpdateDto = bpItems.map((bpItem) => {
          const relationsGroupIdMap = new Map<PartPropGeometryKey, string>()
          bpItem.partProperties.forEach((p) => {
            relationsGroupIdMap.set(
              {
                type: p.type,
                bodyType: p.bodyType,
                printStrategyParameterSetId: p.printStrategyParameterSetId,
                printStrategyParameterSetVersion: p.printStrategyParameterSetVersion,
              },
              p.groupId,
            )
          })

          const partProperties = bpItem.partProperties.map((partProperty) => {
            partProperty.printStrategyParameterSetId = DEFAULT_PRINT_STRATEGY_PARAMETER_SET_ID
            partProperty.printStrategyParameterSetVersion = DEFAULT_PRINT_STRATEGY_PARAMETER_SET_VERSION

            const key: PartPropGeometryKey = {
              type: partProperty.type,
              bodyType: partProperty.bodyType,
              printStrategyParameterSetId: partProperty.printStrategyParameterSetId,
              printStrategyParameterSetVersion: partProperty.printStrategyParameterSetVersion,
            }
            const groupId = relationsGroupIdMap.get(key)
            if (groupId) {
              partProperty.groupId = groupId
            } else {
              partProperty.groupId = createGuid()
              relationsGroupIdMap.set(key, partProperty.groupId)
            }

            return partProperty
          })

          const buildPlanItemDto: IUpdateBuildPlanItemParamsDto = {
            partProperties,
            buildPlanItemId: bpItem.id,
          }

          return buildPlanItemDto
        })
      }

      if (partPropsUpdateDto.length) {
        await this.updateBuildPlanItems(partPropsUpdateDto)
      }
    } catch (error) {
      const msg = i18n.t('partPropsToolbar.updatePartPropertiesFailed') as string
      messageService.showErrorMessage(`${msg} ${error.message}`)
    } finally {
      this.setIsLoading(false)
    }
  }

  updateSceneGeometries(partPropsMap: { [key: string]: PartProperty[] }, geometryType: GeometryType) {
    const updatedGeometryTypeVisibility = this.isGeometryTypeVisible(geometryType)
    const items = Object.entries(partPropsMap).map(([buildPlanItemId, partProps]) => {
      const bodyIds = partProps.map((p) => p.geometryId)
      return { bodyIds, buildPlanItemId }
    })
    this.updateGeometriesOnTypeChange({
      items,
      geometryType,
      visibility: updatedGeometryTypeVisibility,
    })
  }

  private isGeometryTypeVisible(geometryType: GeometryType) {
    switch (geometryType) {
      case GeometryType.Production:
        return this.displayToolbarStateByVariantId(this.getBuildPlan.id).isShowingProductionGeometry
      case GeometryType.Support:
        return this.displayToolbarStateByVariantId(this.getBuildPlan.id).isShowingSupportGeometry
      case GeometryType.Coupon:
        return this.displayToolbarStateByVariantId(this.getBuildPlan.id).isShowingCouponGeometry
    }
  }
}
