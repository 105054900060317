export class SimCompVersion {
  private yearVersion: number
  private monthVersion: number
  private dayVersion: number
  private buildVersion: number

  constructor(line?: string) {
    if (!line || !line.length) {
      this.setVersionParams(1)
    } else {
      const splitted = line.split('.').map((str) => parseInt(str, 10))
      this.setVersionParams(splitted[0], splitted[1], splitted[2], splitted[3])
    }
  }

  get year() {
    return this.yearVersion
  }

  get month() {
    return this.monthVersion
  }

  get day() {
    return this.dayVersion
  }

  get build() {
    return this.buildVersion
  }

  isGreaterOrEqual(version: SimCompVersion): boolean {
    if (!version) return false

    if (this.year !== version.year) return this.year > version.year
    if (this.month !== version.month) return this.month > version.month
    if (this.day !== version.day) return this.day > version.day
    if (this.build !== version.build) return this.build > version.build

    return true
  }

  private setVersionParams(major: number, minor?: number, fix?: number, build?: number) {
    this.yearVersion = Number.isNaN(major) ? 1 : major
    this.monthVersion = !minor || Number.isNaN(minor) ? 0 : minor
    this.dayVersion = !fix || Number.isNaN(fix) ? 0 : fix
    this.buildVersion = !build || Number.isNaN(build) ? 0 : build
  }
}
