
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { FolderDetailsDto } from '@/types/FileExplorer/ItemDetails'
import { ActionDateByUserMixin } from '@/components/layout/mixins/ActionDateByUserMixin'

@Component
export default class DetailsFolder extends ActionDateByUserMixin {
  @Prop() item: FileExplorerItem
  @Prop() itemDetails: FolderDetailsDto
  @Prop({ default: false }) disableHyperlinks: boolean

  share() {
    this.$emit('share')
  }

  get colaboratorsInfo(): string {
    const collaboratorsCount = this.itemDetails.collaborators.length
    return `${collaboratorsCount} ${
      collaboratorsCount !== 1 ? this.$i18n.t('collaborators') : this.$i18n.t('collaborator')
    }`
  }
}
