import { format } from 'date-fns'
import { isObject as isObjectEx, isEqual, transform } from 'lodash'

export function createGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // tslint:disable-next-line:no-bitwise
    const r = (Math.random() * 16) | 0
    // tslint:disable-next-line:no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item) && item !== null
}

export function merge(target: object, source: object) {
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!target[key] || !isObject(target[key])) {
          target[key] = source[key]
        }
        merge(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    })
  }
  return target
}

/**
 * Returns difference between two objects.
 * @param object Target object.
 * @param objectToCompareWith Object to compare with target object.
 */
export function difference(object: Object, objectToCompareWith: Object): Object {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, objectToCompareWith[key])) {
      result[key] =
        isObject(value) && isObjectEx(objectToCompareWith[key]) ? difference(value, objectToCompareWith[key]) : value
    }
  })
}

export const isOfType = <T>(varToBeChecked: any, propertyToCheckFor: keyof T): varToBeChecked is T =>
  (varToBeChecked as T)[propertyToCheckFor] !== undefined

export function isTabVisible() {
  return document.visibilityState === 'visible'
}

const SLEEPER_DELAY: number = 33

/**
 * Chunks low performance process in order to prevent screen freezing
 */
export const occasionalSleeper = (() => {
  let lastSleepingTime = performance.now()

  return () => {
    if (performance.now() - lastSleepingTime > SLEEPER_DELAY) {
      lastSleepingTime = performance.now()
      return new Promise((resolve) => setTimeout(resolve, 0))
    }

    return Promise.resolve()
  }
})()

export const isNil = (val: unknown) => val === undefined || val === null

export class CancellationToken {
  private isCancellationRequested: boolean

  constructor() {
    this.isCancellationRequested = false
  }

  get cancellationRequested() {
    return this.isCancellationRequested
  }

  cancel() {
    this.isCancellationRequested = true
  }
}

// repeats async function call if it throws an error
// stops if promise resolves or is repeated more than maxNumberOfCalls
export function repeatAsyncFunction(maxNumberOfCalls: number, func: (...funcArg) => Promise<void>, ...args) {
  if (maxNumberOfCalls < 1) {
    return
  }

  repeat(0, maxNumberOfCalls, func, ...args)
}

async function repeat(callNumber: number, maxNumberOfCalls: number, func: (...funcArg) => Promise<void>, ...args) {
  try {
    await func(...args)
  } catch (err) {
    const nextCallNumber = callNumber + 1
    if (nextCallNumber >= maxNumberOfCalls) {
      return
    }

    repeat(nextCallNumber, maxNumberOfCalls, func, ...args)
  }
}

/**
 * Limits concurrent promises in order to prevent memory crashes
 * @param {number} n Amount of concurrent promises
 * @param {Array<() => Promise<T>>} list List of functions that generates promises
 * @param {Function} progressWatcher requests watcher method that gets current and total values of requests
 * @returns {Promise<T[]>} Array of results
 */
export const promiseAllLimit = async <T>(n: number, list: Array<() => Promise<T>>, progressWatcher?: Function) => {
  const head = list.slice(0, n)
  const tail = list.slice(n)
  const result: T[] = []
  const execute = async (promise: () => Promise<T>, i: number, next: () => Promise<void>) => {
    result[i] = await promise()
    await next()
  }

  const runNext = async () => {
    const i = list.length - tail.length
    if (progressWatcher) {
      progressWatcher(result.length, list.length)
    }
    const promise = tail.shift()
    if (promise !== undefined) {
      await execute(promise, i, runNext)
    }
  }

  await Promise.all(head.map((promise, i) => execute(promise, i, runNext)))
  return result
}

/**
 * @returns given date as sting in format 'MMM d, yyyy'. Example: 'Jun 6, 2022'
 */
export const formatDateWithDefaultFormat = (date: string | Date): string => {
  let formattedDate = ''
  try {
    formattedDate = format(new Date(date), 'MMM d, yyyy')
  } catch (err) {
    console.warn(`trying to format non-date value: ${date}`)
  }

  return formattedDate
}
