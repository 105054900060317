
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { ROUTER_PERMISSIONS, RouterNames } from '@/router'
import { IUser } from '@/types/User/IUser'
import { isAuthorized } from '@/utils/user'

const userStore = namespace(StoresNamespaces.User)
const tenantsToShowBJProcessParametersPage = ['dev', 'qa', 'geadditive']

@Component
export default class TenantAdminNavigationPanel extends Vue {
  @userStore.Getter('getUserDetails') getUserDetails: IUser

  get navigationItems() {
    const user: IUser = this.getUserDetails
    const items = []
    if (isAuthorized(user, [...ROUTER_PERMISSIONS.PrintModel])) {
      items.push({
        name: RouterNames.SitesAdmin,
        label: this.$i18n.t('tenantAdminNavigationPanel.sites'),
        testAttribute: 'tenant-sites-tab',
      })
    }
    if (isAuthorized(user, [...ROUTER_PERMISSIONS.JobsAdmin])) {
      items.push({
        name: RouterNames.JobsDashboard,
        label: this.$i18n.t('tenantAdminNavigationPanel.tasks'),
        testAttribute: 'tenant-process-tab',
      })
    }
    if (isAuthorized(user, [...ROUTER_PERMISSIONS.UserAdmin])) {
      items.push({
        name: RouterNames.UserAdmin,
        label: this.$i18n.t('tenantAdminNavigationPanel.users'),
        testAttribute: 'tenant-users-tab',
      })
    }
    return items
  }

  get activePageName() {
    return this.$route.name
  }

  get activePageMetaName() {
    return this.$route.meta.routerName
  }

  async route(routeName) {
    const routerObj: any = {
      name: routeName,
    }
    // @ts-ignore
    this.$router.safePush(routerObj)
  }
}
