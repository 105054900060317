import { Visualization } from '@/visualization'
import { OuterEvents } from '@/visualization/types/Common'
import { InteractiveServiceEvents } from '@/types/InteractiveService/InteractiveServiceEvents'
import { eventBus } from '@/services/EventBus'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { BoundingBox2D } from '@/visualization/models/DataModel'
import { IPendingInsights } from '@/types/BuildPlans/IBuildPlanInsight'
import { IVisualizationState } from '@/store/modules/visualization/types'
import { DragCommand } from '@/types/UndoRedo/DragCommand'
import { IBuildPlanItemsTransformationData } from '@/types/IBuildPlanItemsTransformationData'
import { IMoveBuildPlanItemDto, SelectionUnit } from '@/types/BuildPlans/IBuildPlan'
import { ManualPatch } from '@/types/Label/Patch'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { Epsilon } from '@babylonjs/core/Maths'
import { RestoreSelectedPartsType } from '@/types/BuildPlans/RestoreSelectedPartsType'
import { ResultsManagerEvent } from '@/visualization/types/SimulationTypes'
import { Clearance, ClearanceModes } from '@/visualization/types/ClearanceTypes'

const updateLabelQueue = new Array<{
  labelId: string
  waitUpdated?: () => Promise<unknown>
  notifyUpdated?: Function
}>()

export default function createVisualizationPlugin() {
  return (store) => {
    // Handle store mutations and call appropriate functions inside visualization
    store.subscribe(async (mutation, state) => {
      if (mutation.payload && mutation.payload.silent) {
        return
      }

      const visualizationState: IVisualizationState = state.visualizationModule as IVisualizationState
      const visualization: Visualization = visualizationState.visualization

      const handleResultsManagerEvent = (event: ResultsManagerEvent, args) => {
        if (event === ResultsManagerEvent.ColoringInitialized) {
          store.commit(event, args.model)
          if (args.current) {
            store.commit('visualizationModule/changeSelectedColoringView', args.current.name)
            store.commit('visualizationModule/changeSelectedColoringMode', {
              name: args.current.selectedMode.name,
              silent: true,
            })
          }
        } else {
          store.commit(event, args)
        }
      }

      switch (mutation.type) {
        case 'visualizationModule/init':
          await visualization.init(
            mutation.payload.canvasId,
            mutation.payload.sceneMode,
            mutation.payload.viewMode,
            mutation.payload.loadDefaultPlate,
          )

          visualization.initApi()

          store.commit('visualizationModule/initialized')
          if (store.state.visualizationModule.needToDispose) {
            store.commit('visualizationModule/dispose')
            return
          }

          if (visualization.configLoadedEvent) {
            visualization.configLoadedEvent.on((payload) => {
              store.dispatch('buildPlans/createBuildPlanItem', { ...payload, silent: true })
            })
          }

          if (visualization.configsLoadedEvent) {
            visualization.configsLoadedEvent.on((payload) => {
              store.dispatch('buildPlans/saveReplacementBuildPlanItems', payload)
            })
          }

          if (visualization.elementsSelected) {
            visualization.elementsSelected.on(async (selectedElements) => {
              store.commit('buildPlans/selectItems', { ...selectedElements, silent: true })
            })
          }

          if (visualization.selectedElementsCollisions) {
            visualization.selectedElementsCollisions.on((collidedElements) => {
              store.commit('buildPlans/setSelectedPartsCollisions', { elements: collidedElements, silent: true })
            })
          }

          if (visualization.transformationChange) {
            visualization.transformationChange.on((payload) => {
              store.dispatch('buildPlans/updateBuildPlanItem', { params: { ...payload } })
              store.commit('buildPlans/updateSelectedItemTranslation', {
                buildPlanItemId: payload.buildPlanItemId,
                translation: payload.translation,
              })
              store.commit('visualizationModule/setBoundingBox', {
                boundingBox: visualization.getBoundingBoxDetails(),
              })
              store.dispatch('buildPlans/refreshBoundingBoxForPartsOnly')

              if (payload.beforeDragStartTransformation && payload.afterDragEndTransformation) {
                store.commit(
                  'commandManager/addCommand',
                  new DragCommand(
                    store.getters['buildPlans/getSelectedBuildPlanItems'],
                    {
                      beforeDragStartTransformation: payload.beforeDragStartTransformation,
                      afterDragEndTransformation: payload.afterDragEndTransformation,
                    },
                    store.getters['buildPlans/getCommandType'],
                    payload.forceSupportsUpdate,
                    store.dispatch,
                    store.commit,
                  ),
                )
              }
            })
          }

          if (visualization.transformationChangeBatch) {
            visualization.transformationChangeBatch.on(async (payload: IBuildPlanItemsTransformationData) => {
              const moveBuildPlanItemDtos: IMoveBuildPlanItemDto[] = payload.transformationData.map((tdata) => {
                return {
                  id: tdata.buildPlanItemId,
                  transformationMatrix: tdata.transformationMatrix,
                } as IMoveBuildPlanItemDto
              })

              await store.dispatch('buildPlans/batchMoveBuildPlanItems', moveBuildPlanItemDtos)

              payload.transformationData.forEach((data) => {
                store.commit('buildPlans/updateSelectedItemTranslation', {
                  buildPlanItemId: data.buildPlanItemId,
                  translation: data.translation,
                })
              })

              store.commit('visualizationModule/setBoundingBox', {
                boundingBox: visualization.getBoundingBoxDetails(),
              })
              store.dispatch('buildPlans/refreshBoundingBoxForPartsOnly')

              const affectedBpItemsIDs = payload.transformationData.map((data) => data.buildPlanItemId)
              if (payload.beforeDragStartTransformation && payload.afterDragEndTransformation) {
                // Determine which action was performed: move or rotate
                const affectedBpItemId = affectedBpItemsIDs[0]

                const stateBeforeAction = payload.beforeDragStartTransformation.find(
                  (tData) => tData.buildPlanItemId === affectedBpItemId,
                ).transformationMatrix

                const stateAfterAction = payload.afterDragEndTransformation.find(
                  (tData) => tData.buildPlanItemId === affectedBpItemId,
                ).transformationMatrix

                const isMovement =
                  Math.abs(stateBeforeAction[0] - stateAfterAction[0]) < Epsilon &&
                  Math.abs(stateBeforeAction[1] - stateAfterAction[1]) < Epsilon &&
                  Math.abs(stateBeforeAction[2] - stateAfterAction[2]) < Epsilon &&
                  Math.abs(stateBeforeAction[4] - stateAfterAction[4]) < Epsilon &&
                  Math.abs(stateBeforeAction[5] - stateAfterAction[5]) < Epsilon &&
                  Math.abs(stateBeforeAction[6] - stateAfterAction[6]) < Epsilon &&
                  Math.abs(stateBeforeAction[8] - stateAfterAction[8]) < Epsilon &&
                  Math.abs(stateBeforeAction[9] - stateAfterAction[9]) < Epsilon &&
                  Math.abs(stateBeforeAction[10] - stateAfterAction[10]) < Epsilon

                await store.dispatch('label/setUpdateLabelSetsOnTransformationChange', {
                  isMovement,
                  affectedBpItemsIDs,
                })

                const viewMode = store.getters['buildPlans/getBuildPlanViewMode']
                let toolName: ToolNames

                if (viewMode === ViewModeTypes.Move) {
                  toolName = ToolNames.MOVE
                } else if (viewMode === ViewModeTypes.Rotate) {
                  toolName = ToolNames.ROTATE
                }

                const command = new DragCommand(
                  store.getters['buildPlans/getSelectedBuildPlanItems'],
                  {
                    beforeDragStartTransformation: payload.beforeDragStartTransformation,
                    afterDragEndTransformation: payload.afterDragEndTransformation,
                  },
                  store.getters['buildPlans/getCommandType'],
                  payload.forceSupportsUpdate,
                  store.dispatch,
                  store.commit,
                )

                if (toolName) {
                  command.toolName = toolName
                }

                store.commit('commandManager/addCommand', command)
              } else if (payload.restoreOption >= 0) {
                const isMovementAction = payload.restoreOption !== RestoreSelectedPartsType.RestoreImportedRotation
                await store.dispatch('label/setUpdateLabelSetsOnTransformationChange', {
                  affectedBpItemsIDs,
                  isMovement: isMovementAction,
                })
              }

              if (payload.updateGeometryProperties) {
                payload.transformationData.map((tdata) => visualization.updateGeometryProperties(tdata.buildPlanItemId))
              }
            })
          }

          if (visualization.crossSectionChange) {
            visualization.crossSectionChange.on((crossSectionMatrix) => {
              store.dispatch('buildPlans/updateCrossSectionMatrix', crossSectionMatrix)
            })
          }

          if (visualization.labelAdded) {
            visualization.labelAdded.on(async (payload) => {
              await store.dispatch('buildPlans/addBuildPlanItemLabel', payload)
              store.commit('visualizationModule/setIsLoading', false)
            })
          }

          if (visualization.labelUpdated) {
            visualization.labelUpdated.on(async (payload) => {
              let notifyUpdated: Function
              const promise = new Promise((resolve) => {
                notifyUpdated = resolve
              })
              updateLabelQueue.push({
                notifyUpdated,
                labelId: payload.label.id,
                waitUpdated: (
                  () => () =>
                    promise
                )(),
              })
              if (updateLabelQueue.length > 1) {
                const previous = updateLabelQueue[updateLabelQueue.length - 2]
                await previous.waitUpdated()
              }
              store.commit('visualizationModule/setIsLoading', true)
              store.commit('buildPlans/setInsightSelectionIsEnabled', false)
              const bpItem = store.getters['buildPlans/getBuildPlanItemByLabelId'](payload.label.id)
              const label = store.getters['buildPlans/getAllBuildPlanMarkLabels'].find((l) => l.id === payload.label.id)
              payload.label.fileKey = label.fileKey
              payload.label.s3FileName = label.s3FileName
              payload.label.createdAt = label.createdAt
              if (bpItem.id === payload.buildPlanItemId) {
                await store.dispatch('buildPlans/updateBuildPlanItemLabel', payload)
              } else {
                await store.dispatch('buildPlans/deleteBuildPlanItemLabel', {
                  label,
                  buildPlanItemId: bpItem.id,
                  leftOnScene: true,
                })
                await store.dispatch('buildPlans/addBuildPlanItemLabel', payload)
              }

              store.commit('buildPlans/setInsightSelectionIsEnabled', true)
              store.commit('visualizationModule/setIsLoading', false)
              updateLabelQueue[0].notifyUpdated()
              updateLabelQueue.shift()
            })
          }

          if (visualization.labelPlaced) {
            visualization.labelPlaced.on(() => {
              store.commit('visualizationModule/setIsLoading', true)
              store.commit('visualizationModule/deactivateLabelCreation')
            })
          }

          if (visualization.labelOrientationSelected) {
            visualization.labelOrientationSelected.on((payload: ManualPatch) => {
              store.dispatch('label/addManualPlacements', {
                labelSetId: store.getters['label/activeLabelSet'].id,
                patches: [payload],
              })
            })
          }

          if (visualization.labelOrientationChanged) {
            visualization.labelOrientationChanged.on(
              (payload: { manualPatches: ManualPatch[]; singleLabelUpdate?: boolean }) => {
                store.dispatch('label/updateManualPlacements', {
                  labelSetId: store.getters['label/activeLabelSet'].id,
                  patches: payload.manualPatches,
                  singleLabelUpdate: payload.singleLabelUpdate,
                })
              },
            )
          }

          if (visualization.initializeSlicer) {
            visualization.initializeSlicer.on((payload) => {
              store.commit('visualizationModule/initSlicer', { ...payload, silent: true })
            })
          }

          if (visualization.resultsManagerEvent) {
            visualization.resultsManagerEvent
              .filter((h) => !!h)
              .forEach((h) => h.on((payload) => handleResultsManagerEvent(payload.event, payload.args)))
          }

          if (visualization.visualizationModeChanged) {
            visualization.visualizationModeChanged.on((payload) => {
              store.commit('visualizationModule/changeVisualizationMode', payload)
            })
          }

          if (visualization.changeIsLoading) {
            visualization.changeIsLoading
              .filter((h) => !!h)
              .forEach((h) =>
                h.on((payload) => {
                  store.commit('visualizationModule/setIsLoading', payload.isLoading)
                }),
              )
          }

          if (visualization.updateItemPreviewEvent) {
            visualization.updateItemPreviewEvent.on(async (payload) => {
              const url: string = await store.dispatch('visualizationModule/updateItemPreview', {
                ...payload,
                silent: true,
              })

              store.commit('buildPlans/setPreviewImageUrl', url)

              const item = store.getters['fileExplorer/find'](payload.itemId)

              if (item) {
                await store.dispatch('fileExplorer/fetchAndUpdateItem', payload.itemId)
              }

              if (store.getters['visualizationModule/getPreviewCreationPromise']) {
                store.getters['visualizationModule/getPreviewCreationPromise'].done()
              }
            })
          }

          if (visualization.addGeometryProperties) {
            visualization.addGeometryProperties.on(async (payload) => {
              await store.dispatch('buildPlans/updateBuildPlanItem', { params: { ...payload, silent: true } })
              store.dispatch('buildPlans/refreshBoundingBoxForPartsOnly')
            })
          }

          if (visualization.changeBuildPlate) {
            visualization.changeBuildPlate.on((payload) => {
              store.commit('buildPlans/setBuildPlate', payload)
            })
          }

          if (visualization.generateOverhangMeshByClickEvent) {
            visualization.generateOverhangMeshByClickEvent.on((payload) => {
              eventBus.$emit(InteractiveServiceEvents.GenerateOverhanhgMeshByClick, payload)
            })
          }

          if (visualization.generateOverhangMeshEventDebounced) {
            visualization.generateOverhangMeshEventDebounced.on((payload) => {
              eventBus.$emit(InteractiveServiceEvents.GenerateOverhangMeshDebounced, payload)
            })
          }

          if (visualization.selectSupportEvent) {
            visualization.selectSupportEvent.on((payload) => {
              eventBus.$emit(InteractiveServiceEvents.SelectSupport, payload)
            })
          }

          if (visualization.hoverSupportEvent) {
            visualization.hoverSupportEvent.on((payload) => {
              eventBus.$emit(InteractiveServiceEvents.HoverSupport, payload)
            })
          }

          if (visualization.hoverDefect) {
            visualization.hoverDefect.on((payload) => {
              store.commit('parts/setHoveredDefect', payload.type)
            })
          }

          if (visualization.selectDefectsEvt) {
            visualization.selectDefectsEvt.on((payload) => {
              store.commit('parts/setSelectedDefectsTypes', payload)
            })
          }

          if (visualization.onGetBPNameByBPId) {
            visualization.onGetBPNameByBPId.on((payload) => {
              const name = store.getters['buildPlans/getBPNameByBPId'](payload.buildPlanId)
              payload.callback(name)
            })
          }

          if (visualization.deletePartsInState) {
            visualization.deletePartsInState.on((payload) => {
              store.commit('buildPlans/deletePartsInState', payload)
              store.dispatch('buildPlans/refreshBoundingBoxForPartsOnly')
              // Should be trigerred in this way due to watch restriction
              setTimeout(() => store.dispatch('buildPlans/refreshLabelInsights'), 0)
            })
          }

          if (visualization.downwardPlaneRotationStarted) {
            visualization.downwardPlaneRotationStarted.on(() => {
              store.commit('visualizationModule/toggleDownwardPlaneRotationProgress', {
                isDownwardPlaneRotationInProgress: true,
                silent: true,
              })
            })
          }

          if (visualization.downwardPlaneRotationEnded) {
            visualization.downwardPlaneRotationEnded.on(
              (payload: { isSheetBody: boolean; flipArrowLocation: { x: number; y: number } }) => {
                store.commit('visualizationModule/toggleDownwardPlaneRotationInitialization', {
                  isDownwardPlaneRotationInitialized: false,
                  silent: true,
                })
                store.commit('visualizationModule/toggleDownwardPlaneRotationProgress', {
                  isDownwardPlaneRotationInProgress: false,
                  silent: true,
                })
                store.commit('visualizationModule/setFlipArrowLocation', payload.flipArrowLocation)
                store.commit('visualizationModule/displayFlipArrow', payload.isSheetBody)
              },
            )
          }

          if (visualization.overhangsElementsEvent) {
            visualization.overhangsElementsEvent.on((payload) => {
              eventBus.$emit(InteractiveServiceEvents.GetOverhangElements, payload)
            })
          }

          if (visualization.deleteOverhangsAndSupportsEvent) {
            visualization.deleteOverhangsAndSupportsEvent.on((payload) => {
              // emit event for locking overhangs and support interactive rendering if support tab is opened
              eventBus.$emit(InteractiveServiceEvents.LockOverhangsAndSupportsRendering, payload)
            })
          }

          if (visualization.onSelectionBoundingGeometryReadyEvent) {
            visualization.onSelectionBoundingGeometryReadyEvent.on((payload: BoundingBox2D) => {
              store.commit('visualizationModule/setBoundingBox2D', payload)
            })
          }

          if (visualization.onSetInstancingIsRunning) {
            visualization.onSetInstancingIsRunning.on((isRunning: boolean) => {
              store.commit('visualizationModule/setInstancingIsRunning', isRunning)
            })
          }

          if (visualization.reportInsightIssues) {
            visualization.reportInsightIssues.on((insights: IPendingInsights[]) => {
              store.commit('buildPlans/reportInsightIssues', insights)
            })
          }

          if (visualization.onCameraPositionChangedEvent) {
            visualization.onCameraPositionChangedEvent.on((position) => {
              store.commit('visualizationModule/setCameraPostion', position)
            })
          }

          if (visualization.hoverBody) {
            visualization.hoverBody.on((body) => {
              store.commit('buildPlans/hoverBody', { ...body })
            })
          }

          if (visualization.hoverLabel) {
            visualization.hoverLabel.on((payload) => {
              store.commit('visualizationModule/hoverLabel', payload)
            })
          }

          if (visualization.configFileReady) {
            visualization.configFileReady.on((parts) => store.commit('parts/configFile', { ...parts }))
          }

          if (visualization.setCollectorItems) {
            visualization.setCollectorItems.on((items) => {
              store.commit('optionalMultiItemCollector/setCollectorItems', { ...items })
            })
          }

          if (visualization.onPartElevate) {
            visualization.onPartElevate.on((payload) => {
              eventBus.$emit(InteractiveServiceEvents.OnPartElevate, payload)
            })
          }

          if (visualization.selectRelatedBodies) {
            visualization.selectRelatedBodies.on((payload) => {
              eventBus.$emit(InteractiveServiceEvents.SelectRelatedBodies, payload)
            })
          }

          if (visualization.addSelectedLabeledBodies) {
            visualization.addSelectedLabeledBodies.on((labeledBodies) => {
              store.dispatch('label/addSelectedBodies', labeledBodies)
            })
          }

          if (visualization.removeSelectedLabeledBodies) {
            visualization.removeSelectedLabeledBodies.on((labeledBodies) => {
              store.dispatch('label/removeSelectedBodies', labeledBodies)
            })
          }
          break
        case 'visualizationModule/initDetailsPreview':
          visualization
            .initDetailsPreview(mutation.payload.canvasId, mutation.payload.sceneMode)
            .then((isCanvasExists) => {
              store.commit('visualizationModule/initialized')
              if (!isCanvasExists || store.state.visualizationModule.needToDispose) {
                store.commit('visualizationModule/disposePreview')
              }
            })
          break
        case 'visualizationModule/dispose':
          await visualization.dispose()
          visualization.disposeApi()

          // Call to resolve promise if passed to make dispose synchronous
          if (mutation.payload) {
            mutation.payload()
          }
          break
        case 'visualizationModule/disposePreview':
          await visualization.disposeDetailsPreview()
          break
        case 'visualizationModule/clearDetailsPreview':
          visualization.clearDetailsPreview()
          break
        case 'visualizationModule/showGizmos':
          visualization.showGizmos()
          break
        case 'visualizationModule/hideGizmos':
          visualization.disableGizmos(true)
          break
        case 'visualizationModule/deselect':
          const params = mutation.payload || { items: null, isSilent: null }
          visualization.deselect(params.items, params.isSilent)
          break
        case 'visualizationModule/deselectNonBarGeometry':
          visualization.deselectNonBarGeometry()
          break
        case 'visualizationModule/checkCollision':
          visualization.checkCollision()
          break
        case 'visualizationModule/enableClearanceTool':
          const { isEnabled, restoreSelectionManagerState } = mutation.payload as {
            isEnabled: boolean
            restoreSelectionManagerState: boolean
          }

          if (
            !visualization.getRenderScene() ||
            !visualization.getRenderScene().getSelectionManager() ||
            !visualization.getRenderScene().getClearanceManager()
          ) {
            return
          }

          const bpViewMode = store.getters['buildPlans/getBuildPlanViewMode']
          if (bpViewMode || !isEnabled) {
            store.commit('buildPlans/setIsToolMaskDisplaying', isEnabled)
          }

          const oldActiveCollectorId = isEnabled
            ? store.getters['optionalMultiItemCollector/getActiveCollectorId']
            : store.getters['visualizationModule/getSavedActiveCollectorId']

          if (isEnabled) {
            if (bpViewMode && bpViewMode === ViewModeTypes.Duplicate) {
              visualization.hideClearClearances(true, (mode) => mode === ClearanceModes.Duplicate)
            }
            store.commit('visualizationModule/saveActiveCollectorId', oldActiveCollectorId)
            store.commit('optionalMultiItemCollector/setActiveCollector', null)
            visualization.saveSelectionManagerState()
            visualization.deselect()
            // visualization.setSelectionMode(SelectionUnit.Body)
            visualization.setSelectionMode(SelectionUnit.Part)
            visualization.addDuplicatedPartsToGPUPicker()
            store.commit('visualizationModule/changeViewMode', ViewModeTypes.ClearanceTool)
          } else {
            if (bpViewMode && bpViewMode === ViewModeTypes.Duplicate) {
              visualization.clearClearances((mode) => mode !== ClearanceModes.Duplicate)
              visualization.hideClearClearances(false, (mode) => mode === ClearanceModes.Duplicate)
            } else {
              visualization.clearClearances()
            }

            store.commit('visualizationModule/showRubberBand', { isShown: false })
            // If buildPlanViewMode is null change view mode to Layout
            // (Case, when no tool was opened before Clearance Tool)
            let newViewMode: ViewModeTypes = ViewModeTypes.Layout
            // Otherwise if buildPlanViewMode is not null and is Slicing, change view mode to PrintOrderPreview
            // (Case, when Clearance Tool was opened on Print Order)
            if (bpViewMode && bpViewMode === ViewModeTypes.Slicing) {
              newViewMode = ViewModeTypes.PrintOrderPreview
            }
            // Otherwise if buildPlanViewMode is not null and is not Slicing, change view mode to bpViewMode
            // (Case, when another tool was opened before Clearance Tool)
            else if (bpViewMode && bpViewMode !== ViewModeTypes.Slicing) {
              newViewMode = bpViewMode
            }

            store.commit('visualizationModule/changeViewMode', newViewMode)
            visualization.removeDuplicatedPartsFromGPUPicker()
            if (restoreSelectionManagerState) {
              visualization.restoreSelectionManagerState()
            }
            store.commit('optionalMultiItemCollector/setActiveCollector', oldActiveCollectorId)
          }

          visualization.setIsReadOnly(isEnabled ? isEnabled : store.getters['buildPlans/isSceneReadOnly'])
          break
        case 'visualizationModule/saveSelectionManagerState':
          visualization.saveSelectionManagerState()
          break
        case 'visualizationModule/restoreSelectionManagerState':
          visualization.restoreSelectionManagerState()
          break
        case 'visualizationModule/showRubberBand':
          visualization.showRubberBand(mutation.payload.isShown)
          break
        case 'visualizationModule/toggleClearanceHighlight':
          visualization.toggleClearanceHighlight(mutation.payload.clearanceId, mutation.payload.showHighlight)
          break
        case 'visualizationModule/measureDistanceToEnvironment':
          visualization.measureDistanceToEnvironment(mutation.payload)
          break
        case 'visualizationModule/changeClearanceSelectionObserver':
          visualization.handleOuterEvent(OuterEvents.ClearanceChangeSelectionObserver, mutation.payload)
          break
        case 'visualizationModule/undo':
          visualization.undo()
          break
        case 'visualizationModule/enableCrossSectionMode':
          visualization.setCrossSection(mutation.payload)
          break
        case 'visualizationModule/recenterCrossSection':
          visualization.recenterCrossSection()
          break
        case 'visualizationModule/axisAlignCrossSection':
          visualization.axisAlignCrossSection()
          break
        case 'visualizationModule/enableSlicerMode':
          visualization.setSlicer(mutation.payload)
          break
        case 'visualizationModule/changeCurrentSlicerValue':
          visualization.changeCurrentSlicer(mutation.payload)
          break
        case 'visualizationModule/showMeshes':
          visualization.showMeshes()
          break
        case 'visualizationModule/hideMeshes':
          visualization.hideMeshes()
          break
        case 'visualizationModule/showSlicePolylines':
          visualization.showSlicePolylines(
            mutation.payload.polylines,
            mutation.payload.type,
            mutation.payload.polylineMeshName,
          )
          break
        case 'visualizationModule/hideSlicePolylines':
          if (mutation.payload) {
            visualization.hideSlicePolylines(mutation.payload.type, mutation.payload.polylineMeshName)
          } else {
            visualization.hideSlicePolylines()
          }
          break
        case 'visualizationModule/selectAndHighlightPart':
          visualization.selectAndHighlightPart(
            mutation.payload.buildPlanItemId,
            mutation.payload.meshId,
            mutation.payload.deselectIfSelected,
            mutation.payload.showGizmo,
          )
          break

        case 'visualizationModule/selectAndHighlightParts':
          visualization.selectAndHighlightParts(
            mutation.payload.buildPlanItemIds,
            mutation.payload.deselectIfSelected,
            mutation.payload.showGizmo,
          )
          break
        case 'visualizationModule/selectDefects':
          visualization.selectDefects(mutation.payload)
          break
        case 'visualizationModule/toggleHighlight':
          visualization.toggleHighlight(
            mutation.payload.buildPlanItemId,
            mutation.payload.meshId,
            mutation.payload.highlight,
          )
          break
        case 'visualizationModule/toggleMultiHighlight':
          visualization.toggleMultiHighlight(mutation.payload.buildPlanItemIds, mutation.payload.highlight)
          break
        case 'visualizationModule/toggleDefectsHighlight':
          visualization.toggleDefectsHighlight(mutation.payload)
          break
        case 'visualizationModule/selectAndHighlightPartWithAttach':
          visualization.selectAndHighlightPartWithAttach(mutation.payload.buildPlanItemIds)
          break
        case 'visualizationModule/selectAndHighlightPartsAfterRemove':
          visualization.selectAndHighlightPartsAfterRemove(mutation.payload.buildPlanItemIds)
          break
        case 'visualizationModule/rotatePart':
          visualization.rotatePart(
            mutation.payload.partItemIndex,
            mutation.payload.x,
            mutation.payload.y,
            mutation.payload.z,
            mutation.payload.transformation,
            mutation.payload.zTranslation,
          )
          break
        case 'visualizationModule/savePartOrientation':
          visualization.savePartOrientation(mutation.payload.partItemIndex)
          break
        case 'visualizationModule/changeViewMode':
          visualization.changeViewMode(mutation.payload)
          break
        case 'visualizationModule/changeVisualizationMode':
          visualization.changeVisualizationMode(mutation.payload)
          break
        case 'visualizationModule/changeView':
          visualization.changeView(mutation.payload)
          break
        case 'visualizationModule/setViewLocked':
          visualization.setViewLocked(mutation.payload)
          break
        case 'visualizationModule/setLabelStyle':
          visualization.setLabelStyle(mutation.payload)
          break
        case 'visualizationModule/refreshInsights':
          visualization.refreshInsights()
          break
        case 'visualizationModule/updateLabelAppearance':
          await visualization.updateLabelAppearance(
            mutation.payload.id,
            mutation.payload.style,
            mutation.payload.shouldLabelBeSelected,
            mutation.payload.updateStore,
          )
          break
        case 'visualizationModule/activateLabelManualPlacement':
          visualization.activateLabelManualPlacement()
          visualization.handleOuterEvent(OuterEvents.LabelEnterManualPlacement)
          break
        case 'visualizationModule/deactivateLabelManualPlacement':
          const labelSetId = mutation.payload ? mutation.payload.labelSetId : undefined
          const isSwitchedToAutomatic = mutation.payload ? mutation.payload.isSwitchedToAutomatic : undefined
          visualization.deactivateLabelManualPlacement(labelSetId, isSwitchedToAutomatic)
          visualization.handleOuterEvent(OuterEvents.LabelExitManualPlacement)
          const activeLabelSet = store.getters['label/activeLabelSet']
          if (activeLabelSet && mutation.payload && mutation.payload.isSwitchedToAutomatic) {
            store.commit('label/removeManualPlacementsForLabelSet', { labelSetId: activeLabelSet.id })
          }
          break
        case 'visualizationModule/activateLabelBarPlacement':
          visualization.handleOuterEvent(OuterEvents.LabelEnterBarPlacement)
          break
        case 'visualizationModule/deactivateLabelBarPlacement':
          visualization.handleOuterEvent(OuterEvents.LabelExitBarPlacement)
          break
        case 'visualizationModule/activateLabelPlacement':
          visualization.handleOuterEvent(OuterEvents.LabelPlacementEnabled)
          break
        case 'visualizationModule/deactivateLabelPlacement':
          visualization.handleOuterEvent(OuterEvents.LabelPlacementDisabled)
          break
        case 'visualizationModule/activateLabelCreation':
          visualization.activateLabelCreation()
          break
        case 'visualizationModule/deactivateLabelCreation':
          visualization.deactivateLabelCreation()
          break
        case 'visualizationModule/showManuallyPlacedLabelOrigins':
          visualization.showManuallyPlacedLabelOrigins(mutation.payload.patches, mutation.payload.labelSetId)
          break
        case 'visualizationModule/activateLabelInteraction':
          visualization.activateLabelInteraction(mutation.payload)
          break
        case 'visualizationModule/deactivateLabelInteraction':
          visualization.deactivateLabelInteraction(mutation.payload)
          break
        case 'visualizationModule/toggleComponentHighlight':
          visualization.toggleComponentHighlight(
            mutation.payload.componentId,
            mutation.payload.showHighlight,
            mutation.payload.excludedBPItems,
          )
          break
        case 'visualizationModule/labelInstance':
          visualization.labelInstance(mutation.payload)
          break
        case 'visualizationModule/initBuildPlatformCanvas':
          visualization.initBuildPlatformCanvas(mutation.payload.canvasId, mutation.payload.buildPlateId)
          break
        case 'buildPlans/loadIBCPlan':
          // Clear undo/redo build plan stack and tool stack
          store.commit('commandManager/disposeBuildPlanStack')
          store.commit('commandManager/disposeToolStack')

          store.commit('buildPlans/setIsLoading', true)
          visualization
            .loadIBCPlan(mutation.payload.ibcPlan, mutation.payload.options)
            .then(() => {
              store.dispatch('buildPlans/changeVisibilityByIBCDisplayToolbarState')

              // TODO Do we need this?
              store.commit('visualizationModule/setBoundingBox', { boundingBox: visualization.getBoundingBoxDetails() })
              store.dispatch('buildPlans/refreshBoundingBoxForPartsOnly')
            })
            .then(() => {
              if (!store.buildPlans || !store.buildPlans.buildPlan) {
                store.commit('buildPlans/setIsLoading', false)
                return
              }
              const { id: activeBuildPlanId } = store.state.buildPlans.buildPlan
              if (activeBuildPlanId === mutation.payload.id) {
                store.commit('buildPlans/setIsLoading', false)
              }
            })
          break
        case 'buildPlans/updateBuildPlan':
          store.commit('visualizationModule/setIsLoading', true)
          visualization.updateBuildPlan(mutation.payload).then(() => {
            store.commit('visualizationModule/setIsLoading', false)
          })
          break
        case 'buildPlans/selectBuildPlate':
          if (mutation.payload.buildPlanSubType !== ItemSubType.SinterPlan) {
            visualization
              .loadBuildPlate(
                mutation.payload.buildPlatePk,
                mutation.payload.machineConfigPk,
                mutation.payload.modality,
              )
              .then((isLoaded) => {
                if (isLoaded) {
                  store.dispatch('buildPlans/selectBuildPlate', mutation.payload.buildPlatePk)
                  store.commit('visualizationModule/setBoundingBox', {
                    boundingBox: visualization.getBoundingBoxDetails(),
                  })
                  store.dispatch('buildPlans/refreshBoundingBoxForPartsOnly')
                }
              })
          } else {
            visualization
              .loadSinterPlate(mutation.payload.buildPlatePk, mutation.payload.machineConfigPk)
              .then((isLoaded) => {
                if (isLoaded) {
                  store.dispatch('buildPlans/selectBuildPlate', mutation.payload.buildPlatePk)
                }
              })
          }
          break
        case 'buildPlans/setSelectedBuildPlanJobs':
          const isSceneReadOnly = await store.getters['buildPlans/isSceneReadOnly']
          visualization.setIsReadOnly(isSceneReadOnly)
          break
        case 'visualizationModule/changeSelectedColoringMode':
          const model = store.getters['visualizationModule/coloringModel']
          visualization.handleOuterEvent(OuterEvents.ColoringModeChanged, model.selectedView)
          break
        case 'visualizationModule/changeSimulationSlicer':
          visualization.handleOuterEvent(OuterEvents.SimulationSlicerChanged, { current: mutation.payload })
          break
        case 'visualizationModule/setResultsPathData':
          visualization.handleOuterEvent(OuterEvents.SetResultsPathData, mutation.payload)
          break
        case 'visualizationModule/changeSimulationTimestamp':
          visualization.handleOuterEvent(OuterEvents.SimulationTimestampChanged, { currentTimestamp: mutation.payload })
          break
        case 'visualizationModule/viewSimulationResults':
          visualization.viewSimulationResults()
          break
        case 'visualizationModule/setXrayView':
          visualization.handleOuterEvent(OuterEvents.SetXrayView, mutation.payload)
          break
        case 'visualizationModule/resetSimulationState':
          visualization.clearResults()
          break
        case 'visualizationModule/setActiveScene':
          visualization.handleOuterEvent(OuterEvents.SetActiveScene, { active: mutation.payload })
          break
        case 'visualizationModule/showBridgingElements':
          visualization.handleOuterEvent(OuterEvents.ShowBridgingElements)
          break
        case 'visualizationModule/showMeshing':
          visualization.handleOuterEvent(OuterEvents.ShowMeshing, { visible: mutation.payload })
          break
        case 'visualizationModule/showCompensated':
          visualization.handleOuterEvent(OuterEvents.ShowCompensatedGeometry, { visible: mutation.payload })
          break
        case 'visualizationModule/showGreenCompensated':
          visualization.handleOuterEvent(OuterEvents.ShowGreenCompensatedGeometry, { visible: mutation.payload })
          break
        case 'visualizationModule/showGreenNominal':
          visualization.handleOuterEvent(OuterEvents.ShowGreenNominalGeometry, { visible: mutation.payload })
          break
        case 'visualizationModule/showNominalGeometry':
          visualization.handleOuterEvent(OuterEvents.ShowNominalGeometry, { visible: mutation.payload })
          break
        case 'visualizationModule/highlightDetailsInfo':
          visualization.handleOuterEvent(OuterEvents.HightlightDetailsInfo, mutation.payload)
          break
        case 'visualizationModule/setAllStepsColorRange':
          visualization.handleOuterEvent(OuterEvents.SetAllStepsColorRange, mutation.payload)
          break
        case 'visualizationModule/setWarpingParameters':
          visualization.handleOuterEvent(OuterEvents.SetWarpingParameters, mutation.payload)
          break
        case 'visualizationModule/setHandlerVisibility':
          visualization.handleOuterEvent(OuterEvents.SetHandlerVisibility, mutation.payload)
          break
        case 'visualizationModule/deleteRenderedLabel':
          visualization.deleteLabel(mutation.payload)
          break
        case 'visualizationModule/toggleLabelHighlight':
          visualization.toggleLabelHighlight(mutation.payload.labelId, mutation.payload.highlight)
          break
        case 'visualizationModule/applyTransformationMatrix':
          visualization.applyTransformatonMatrix(
            mutation.payload.buildPlanItemId,
            mutation.payload.transformation,
            mutation.payload.options,
          )
          break
        case 'visualizationModule/applyTransformationMatrixBatch':
          visualization.applyTransformatonMatrixBatch(mutation.payload.buildPlanItems)
          break
        case 'visualizationModule/addOverhangMesh':
          visualization.addOverhangMesh(mutation.payload.buildPlanItemId, mutation.payload.id, mutation.payload.config)
          break
        case 'visualizationModule/updateOverhangMesh':
          visualization.updateOverhangMesh(
            mutation.payload.buildPlanItemId,
            mutation.payload.id,
            mutation.payload.overhang,
          )
          break
        case 'visualizationModule/clearOverhangMesh':
          visualization.clearOverhangMesh(mutation.payload)
          break
        case 'visualizationModule/setGeometriesVisibility':
          visualization.setGeometriesVisibility(
            mutation.payload.items,
            mutation.payload.geometryType,
            mutation.payload.visibility,
          )
          break
        case 'visualizationModule/setGeometryVisibility':
          visualization.setGeometryVisibility(mutation.payload.items, mutation.payload.visibility)
          break
        case 'visualizationModule/setOverhangMeshVisibility':
          visualization.setOverhangMeshVisibility(mutation.payload.items, mutation.payload.visibility)
          break
        case 'visualizationModule/setIsHiddenForBuildPlanItemMesh':
          visualization.setIsHiddenForBuildPlanItemMesh(
            mutation.payload.buildPlanItemId,
            mutation.payload.makeHidden,
            mutation.payload.showHiddenAsTransparent,
          )
          break
        case 'visualizationModule/addSupportMesh':
          visualization.addSupportMesh(
            mutation.payload.buildPlanItemId,
            mutation.payload.sdata,
            mutation.payload.belongsToOverhangElementName,
          )
          break
        case 'visualizationModule/addSupportBvhAndHull':
          visualization.addSupportBvhAndHull(
            mutation.payload.buildPlanItemId,
            mutation.payload.supportsBvhFileKey,
            mutation.payload.supportsHullFileKey,
          )
          break
        case 'visualizationModule/loadSupports':
          visualization.loadSupports(
            mutation.payload.buildPlanItemId,
            mutation.payload.supports,
            mutation.payload.supportsBvhFileKey,
            mutation.payload.supportsHullFileKey,
            mutation.payload.visibility,
          )
          break
        case 'visualizationModule/clearSupports':
          visualization.clearSupports(
            mutation.payload.buildPlanItemId,
            mutation.payload.overhangElementsToClear,
            mutation.payload.skipGeomProps,
          )
          break
        case 'visualizationModule/setBodiesVisibility':
          visualization.setBodiesVisibility(mutation.payload.ids, mutation.payload.isVisible)
          break
        case 'visualizationModule/highlightErrorOverhangZone':
          visualization.highlightErrorOverhangZone(mutation.payload.buildPlanItemId, mutation.payload.overhangZoneName)
          break
        case 'visualizationModule/addFailedOverhangZone':
          visualization.addFailedOverhangZone(mutation.payload.buildPlanItemId, mutation.payload.overhangZoneName)
          break
        case 'visualizationModule/updateItemPreview':
          visualization.updateItemPreview(mutation.payload.itemId)
          break
        case 'visualizationModule/setDefaultOverhangMaterial':
          visualization.setDefaultOverhangMaterial(
            mutation.payload.buildPlanItemId,
            mutation.payload.overhangElementsToClear,
          )
          break
        case 'buildPlans/loadInsights':
          await visualization.loadInsigths(mutation.payload)
          break
        case 'visualizationModule/setSelectionModeAndReselect':
          visualization.setSelectionModeAndReselect(mutation.payload.mode)
          break
        case 'visualizationModule/highlightSupports':
          visualization.highlightSupports(mutation.payload.buildPlanItemId, mutation.payload.overhangElementNames)
          break
        case 'visualizationModule/hoverSupport':
          visualization.hoverSupport(mutation.payload.buildPlanItemId, mutation.payload.overhangElementName)
          break
        case 'visualizationModule/transferSupports':
          visualization.transferSupports(mutation.payload.sourceId, mutation.payload.targetIds)
          break
        case 'visualizationModule/setPartsVisibility':
          visualization.setPartsVisibility(mutation.payload.ids, mutation.payload.visibility)
          break
        case 'visualizationModule/setSendBoundingAnchorPoints':
          visualization.setSendBoundingAnchorPoints(mutation.payload)
          break
        case 'visualizationModule/getItemsBoundingBox2D':
          const points: BoundingBox2D = visualization.getItemsBoundingBox2D(mutation.payload)
          store.commit('visualizationModule/setBoundingBox2D', points)
          break
        case 'visualizationModule/resizeCanvas':
          visualization.resizeCanvas()
          break
        case 'visualizationModule/setMeshesVisibilityByName':
          visualization.setMeshesVisibilityByName(mutation.payload.name, mutation.payload.visibility)
          break
        case 'visualizationModule/setBuildPlateMeshVisibility':
          visualization.setBuildPlateMeshVisibility(mutation.payload.visibility, mutation.payload.isSinterPlan)
          break
        case 'visualizationModule/zoomToFitCamera':
          visualization.zoomToFitCamera()
          break
        case 'visualizationModule/highlightBody':
          visualization.highlightBody(mutation.payload.id, mutation.payload.showHighlight, mutation.payload.bpItemId)
          break
        case 'visualizationModule/highlightSupport':
          visualization.highlightSupport(
            mutation.payload.bpItemId,
            mutation.payload.overhangZoneName,
            mutation.payload.showHighlight,
          )
          break
        case 'visualizationModule/calcGeometryPropsForSinglePart':
          visualization
            .getSinglePartGeometryProps()
            .then((props) => store.commit('parts/setPreviewGeometryProps', props))
          break
        case 'visualizationModule/rebuildSupports':
          visualization.rebuildSupports()
          break
        case 'visualizationModule/updateMoveIncrement':
          visualization.updateMoveIncrement(mutation.payload)
          break
        case 'visualizationModule/updateRotateIncrement':
          visualization.updateRotateIncrement(mutation.payload)
          break
        case 'visualizationModule/updateSupports':
          visualization.updateSupports(mutation.payload.buildPlanItemId, mutation.payload.supports)
          break
        case 'visualizationModule/toggleDownwardPlaneRotationInitialization':
          visualization.toggleDownwardPlaneRotation(mutation.payload.isDownwardPlaneRotationInitialized)
          break
        case 'visualizationModule/flipSelectedParts':
          visualization.flipSelectedParts()
          break
        case 'optionalMultiItemCollector/createCollector':
          visualization.createCollector(mutation.payload)
          break
        case 'optionalMultiItemCollector/setActiveCollector':
          visualization.setActiveCollector(mutation.payload)
          break
        case 'optionalMultiItemCollector/deselectLastCollectorItem':
          visualization.deselectLastCollectorItem(mutation.payload)
          break
        case 'optionalMultiItemCollector/deselectAllCollectorItems':
          visualization.deselectAllCollectorItems(mutation.payload)
          break
        case 'optionalMultiItemCollector/enableColoringForCollector':
          visualization.enableColoringForCollector(mutation.payload)
          break
        case 'optionalMultiItemCollector/disableColoringForCollector':
          visualization.disableColoringForCollector(mutation.payload)
          break
        case 'optionalMultiItemCollector/disposeCollectors':
          visualization.disposeCollectors()
          break
        case 'visualizationModule/selectBodies':
          visualization.selectBodies(mutation.payload.bodyIds, mutation.payload.attach)
          break
        case 'visualizationModule/selectAllInstances':
          visualization.selectAllInstances(mutation.payload)
          break
        case 'visualizationModule/updateGeometriesTypeOnScene':
          visualization.updateGeometriesType(mutation.payload.items, mutation.payload.geometryType)
          break
        case 'label/createLabelSetsSnapshot':
          visualization.cacheLabelMeshes()
          break
        case 'label/restoreLabelSetsFromSnapshot':
          visualization.restoreLabelsFromCache(mutation.payload)
          break
        case 'label/restoreLabelSetsFromCache':
          visualization.restoreLabelSetsFromCache(mutation.payload)
          break
        case 'label/clearLabelSetsSnapshot':
          visualization.clearLabelsCache(mutation.payload)
          break
        case 'label/setActiveLabelSet':
          visualization.changeActiveLabelSet(mutation.payload)
          break
        case 'visualizationModule/displaySpatialLetterGrid':
          visualization.displaySpatialLetterGrid(mutation.payload.isVisible, mutation.payload.settings)
          break
        case 'visualizationModule/clearSelection':
          visualization.clearSelection()
          break
        case 'buildPlans/setIsReadOnly':
          visualization.setIsReadOnly(mutation.payload.value)
          break
        case 'visualizationModule/setLabeledBodiesVisibility':
          visualization.setLabeledBodiesVisibility(mutation.payload.activeLabelSetId, mutation.payload.visibility)
          break
        case 'visualizationModule/setOverhangAreasAngle':
          visualization.setOverhangAreasAngle(mutation.payload)
          break
        case 'visualizationModule/hideManualLabelHandle':
          visualization.hideManualLabelHandle(mutation.payload)
          break
        case 'visualizationModule/hoverManualLabelSettings':
          visualization.hoverManualLabelSettings()
          break
        case 'visualizationModule/showPartInstability':
          visualization.showPartInstability(mutation.payload)
          break
        case 'visualizationModule/hidePartInstability':
          visualization.hidePartInstability()
          break
        case 'visualizationModule/setRotatePartsIndependentlyMode':
          visualization.setRotatePartsIndependentlyMode(mutation.payload)
          break
        case 'visualizationModule/showHiddenPartsTransparent':
          visualization.showHiddenPartsTransparent()
          break
        case 'visualizationModule/hideTransparentParts':
          visualization.hideTransparentParts()
          break
        default:
          break
      }
    })
  }
}
