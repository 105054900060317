
import Vue from 'vue'
import Component from 'vue-class-component'
import Button from '@/components/controls/Common/Button.vue'

@Component({
  components: {
    Button,
  },
})
export default class NotEnoughRoleModal extends Vue {
  isShown = false
  names = null
  length = 0
  resolve = null

  open(namesOfItems: string[]) {
    this.isShown = true
    this.length = namesOfItems.length
    this.names = `<b>${namesOfItems.join('</b>, <b>')}</b>`
    return new Promise<boolean>((resolve) => {
      this.resolve = resolve
    })
  }

  close() {
    this.isShown = false
    this.resolve(true)
  }
}
