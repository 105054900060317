
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import StoresNamespaces from '@/store/namespaces'
import Button from '@/components/controls/Common/Button.vue'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: {
    Button,
  },
})
export default class MoveErrorModal extends Vue {
  @fileExplorerStore.Getter('getErrorText') getErrorText: string
  @fileExplorerStore.Getter('isError') isError: boolean

  @Prop({ default: [] }) existingNames: string[]

  names: string = ''
  isShownCopyErrorDialog: boolean = true

  async beforeMount() {
    if (!this.existingNames && !this.existingNames.length) {
      return
    }

    this.names = this.existingNames.join(', ')
  }

  onClose() {
    this.$emit('onClose')
  }
}
