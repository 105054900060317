
import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { BuildPlanTool, ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import JobStatusIcon from '@/components/layout/buildPlans/JobStatusIcon.vue'
import { BadgesMixin } from '@/components/layout/mixins/BadgesMixin'
import { IBCPlanToolNames } from '../IBCPlan/IBCPlanSidebarTools'
import { VisualizationServiceMixin } from '@/components/layout/buildPlans/mixins/VisualizationServiceMixin'
import { JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import { InsightsSeverity } from '@/types/Common/Insights'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'

const labelStore = namespace(StoresNamespaces.Labels)
const commonStore = namespace(StoresNamespaces.Common)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component({ components: { JobStatusIcon } })
export default class BuildPlanToolHeaderBadges extends Mixins(BadgesMixin, VisualizationServiceMixin) {
  @commonStore.Getter tooltipOpenDelay: number

  @labelStore.Getter labelErrorMessage: (key) => string
  @labelStore.Getter sortedHeaders
  @labelStore.Getter labelIssues

  @buildPlansStore.Getter getRequiresLabelSetUpdates: boolean
  @buildPlansStore.Getter insights: IBuildPlanInsight[]

  @Prop({ required: true }) isToolReadOnly: boolean

  get isToolProgressing() {
    const buildPlanTool = this.tool as BuildPlanTool
    if (!buildPlanTool) return false

    return buildPlanTool.progressCriteria && buildPlanTool.progressCriteria()
  }

  get showReadOnlyTip() {
    // Read-only tip for Simulate tool is shown in tooltip for JobStatusIcon
    if (
      this.tool &&
      (this.tool.key === ToolNames.SIMULATE || this.tool.key === IBCPlanToolNames.DEVIATION_COMPENSATE)
    ) {
      return false
    }

    return this.isToolReadOnly
  }

  get readOnlyTip() {
    return this.tool.key === IBCPlanToolNames.INSPECTIONS
      ? this.$t('toolInformationSinterPlanReadOnly')
      : this.$t('toolInformationBuildPlanReadOnly')
  }

  get checkResultsButtonVisible() {
    if (
      this.tool.key !== ToolNames.SIMULATE ||
      this.serviceIsInactive ||
      this.getResultsFetching ||
      !this.hasRunningSimulation
    ) {
      return false
    }

    return true
  }

  get hasRunningSimulation() {
    return this.runningJobs.find(
      (job) => [JobType.COMPENSATE, JobType.SIMULATE].includes(job.jobType) && job.code === JobStatusCode.RUNNING,
    )
  }

  get shouldShowFailedLabels() {
    return (
      this.tool.key === ToolNames.LABEL &&
      this.insights.some(
        (insight) => insight.tool === ToolNames.LABEL && insight.severity === InsightsSeverity.Error,
      ) &&
      !this.getRequiresLabelSetUpdates
    )
  }

  async checkResults() {
    await this.connect(true)
  }
}
