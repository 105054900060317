import { EndpointsUrls } from '@/configs/config'
import { post } from '@/api/http'
import { handleAPIError } from '@/api/common'

const podPreparationTimeout = 3 * 60 * 1000

export default {
  async establishConnection(): Promise<{ sessionURL: string }> {
    try {
      // Left in for local development
      // return { sessionURL: 'http://localhost:3030' }
      return await post(`${EndpointsUrls.Simulations}/visualize`, {}, { timeout: podPreparationTimeout })
    } catch (error) {
      handleAPIError(error)
      return null
    }
  },
}
