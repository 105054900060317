
import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import buildPlans from '@/api/buildPlans'
import Button from '@/components/controls/Common/Button.vue'
import NumberField from '@/components/controls/Common/NumberField.vue'
import CommonBuildPlanToolsMixin from '@/components/layout/buildPlans/mixins/CommonBuildPlanToolsMixin'
import { DEBOUNCE_TIME } from '@/constants'
import CommunicationService from '@/services/CommunicationService'
import messageService from '@/services/messageService'
import StoresNamespaces from '@/store/namespaces'
import { IBuildPlan, IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { IArrangeConstraints } from '@/types/BuildPlans/IConstraints'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import { IBuildPlate } from '@/types/BuildPlates/IBuildPlate'
import { BrokerEvents } from '@/types/Common/BrokerEvents'
import { BrokerMessage } from '@/types/Common/BrokerMessage'
import { PrintingTypes } from '@/types/IMachineConfig'
import { INotification } from '@/types/Notification/INotification'
import { JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import { ArrangeToolCommand } from '@/types/UndoRedo/ArrangeToolCommand'
import {
  ChangeArrangeConstraintsCommand,
  IRepeatConstraintParams,
} from '@/types/UndoRedo/ChangeArrangeConstraintsCommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { ICommand } from '@/types/UndoRedo/ICommand'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const notificationsStore = namespace(StoresNamespaces.Notifications)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const commandManagerStore = namespace(StoresNamespaces.CommandManager)
const DEFAULT_MIN_MARGIN = 0.25

@Component({
  components: {
    NumberField,
    Button,
  },
})
export default class BuildPlanNestingTab extends Mixins(CommonBuildPlanToolsMixin) implements IToolComponent {
  get disableRunBtn() {
    const retVal =
      this.currentNestingJob &&
      (this.currentNestingJob.code === JobStatusCode.QUEUED || this.currentNestingJob.code === JobStatusCode.RUNNING)
    return retVal
  }

  get minPartMargin(): number {
    return DEFAULT_MIN_MARGIN
  }

  get maxPartMargin(): number {
    return this.getMaxMargin(this.minPartMargin)
  }

  get minWallsMargin(): number {
    if (this.buildPlan.modality === PrintingTypes.BinderJet) {
      return 0
    }
    return DEFAULT_MIN_MARGIN
  }

  get maxWallsMargin(): number {
    return this.getMaxMargin(this.minWallsMargin)
  }

  get wallsMarginCustomMessages() {
    return {
      min_value: this.$t('arrangeMarginValidationMessage', { min: this.minWallsMargin, max: this.maxWallsMargin }),
      max_value: this.$t('arrangeMarginValidationMessage', { min: this.minWallsMargin, max: this.maxWallsMargin }),
    }
  }

  get partMarginCustomMessages() {
    return {
      min_value: this.$t('arrangeMarginValidationMessage', { min: this.minPartMargin, max: this.maxPartMargin }),
      max_value: this.$t('arrangeMarginValidationMessage', { min: this.minPartMargin, max: this.maxPartMargin }),
    }
  }
  @buildPlansStore.Action('updateBuildPlanV1') updateBuildPlan: (payload: {
    buildPlan: IBuildPlan
    hideAPIErrorMessages?: boolean
  }) => Promise<IBuildPlan>
  @buildPlansStore.Action getBuildPlanById: (id: string) => IBuildPlan
  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter('getAllBuildPlates') allBuildPlates: IBuildPlate[]
  @buildPlansStore.Getter('getIsLoading') isGeometryLoading: boolean
  @buildPlansStore.Getter('isArrangeReadOnly') isArrangeReadOnly: boolean
  @buildPlansStore.Getter getCommandType: CommandType

  @notificationsStore.Action('fetchAllNotifications') fetchAllNotifications: () => Promise<INotification[]>
  @notificationsStore.Getter('getAllNotifications') allNotifications: INotification[]

  @visualizationStore.Mutation('applyTransformationMatrixBatch') applyTransformationMatrixBatch: Function
  @visualizationStore.Mutation('setIsLoading') setIsLoading: Function

  @commandManagerStore.Mutation addCommand: (command: ICommand) => void
  @commandManagerStore.Mutation enable: () => void
  @commandManagerStore.Mutation disable: () => void

  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }
  currentNestingJob: any = null
  lastCompletedJob: any = null
  loadingData: boolean = false
  applyingNest: boolean = false
  okIntervalId = null
  connector: CommunicationService = null
  disableInputFields: boolean = false
  inputTimer: NodeJS.Timeout = null

  arrangeConstraints: IArrangeConstraints = {
    wallsMargin: 5,
    partMargin: 2,
  }

  priorConstraints: IArrangeConstraints = null

  /**************************************
   * Generic tool method implementations
   **************************************/
  // need to mention these generic optional methods even if they are not implemented by the tool
  // due to TypeScript's weak type detection per https://stackoverflow.com/a/47930521
  private buildPlanItemsBeforeArrangeApplied: IBuildPlanItem[] = []

  async clickCancel() {
    await this.revertBuildPlanArrangeConstraints(true)
  }

  updateBuildPlanArrangeConstraintsDebounced() {
    this.$emit('setOkDisabled', true)
    if (this.inputTimer) {
      clearTimeout(this.inputTimer)
    }
    this.inputTimer = setTimeout(() => {
      this.updateBuildPlanArrangeConstraints()
    }, DEBOUNCE_TIME)
  }

  beforeMount() {
    this.connector = CommunicationService.getConnector()
    this.arrangeConstraints = { ...this.buildPlan.constraints }
    this.priorConstraints = { ...this.buildPlan.constraints }
  }

  async mounted() {
    this.$emit('mounted')
    await this.loadData()
  }

  destroyed() {
    clearInterval(this.okIntervalId)
  }

  @Watch('isGeometryLoading')
  @Watch('disableRunBtn')
  @Watch('loadingData')
  updateOkStatus() {
    const result = this.isGeometryLoading || this.disableRunBtn || this.loadingData
    this.$emit('setOkDisabled', result)
  }

  @Watch('buildPlan')
  onBuildPlanUpdated() {
    this.arrangeConstraints = { ...this.buildPlan.constraints }
  }

  getOkName() {
    return 'start'
  }

  async clickOk() {
    this.loadingData = true
    await this.updateBuildPlanArrangeConstraints(true)
    try {
      this.buildPlanItemsBeforeArrangeApplied = JSON.parse(JSON.stringify(this.buildPlan.buildPlanItems))

      this.$emit('creatingJob')
      this.currentNestingJob = await buildPlans.createNestingJob(this.buildPlan.id, true)
      this.currentNestingJob.code = this.currentNestingJob.status.code
      this.connector.subscribe(BrokerEvents.ArrangeApplied, this.applyNesting)
      this.loadingData = false
      this.$emit('jobCreated')

      // Need to disable Undo-Redo manager while job is queued or running.
      this.disable()
    } catch (error) {
      // error message reporting is handled at API call level now
      this.connector.unsubscribe(BrokerEvents.ArrangeApplied, this.applyNesting)
      this.loadingData = false
      this.buildPlanItemsBeforeArrangeApplied = []
      this.$emit('failedJob')
      // Enable Undo-Redo manager if job creation is failed
      this.enable()
      // This error will be caught in the parent component (BuildPlanSidebar)
      throw error
    }
  }

  async applyNesting(brokerMessage: BrokerMessage) {
    try {
      this.applyingNest = true
      const buildPlanItems = brokerMessage.message
      this.applyTransformationMatrixBatch({ buildPlanItems })

      this.addCommand(
        new ArrangeToolCommand(
          this.buildPlanItemsBeforeArrangeApplied,
          buildPlanItems,
          this.getCommandType,
          this.$store.dispatch,
          this.$store.commit,
        ),
      )

      this.connector.unsubscribe(BrokerEvents.ArrangeApplied, this.applyNesting)

      // Enable Undo-Redo manager after Arrange job done.
      this.enable()
    } catch (err) {
      // error message reporting is handled at API call level now
      console.error(err)
    } finally {
      this.applyingNest = false
    }
  }

  async loadData() {
    this.loadingData = true
    const jobs = await buildPlans.getJobs(this.buildPlan.id)
    jobs.sort((a, b) => b.number - a.number)
    const nestingJobs = jobs.filter((item) => item.jobType === JobType.NEST)
    if (nestingJobs.length > 0) {
      this.currentNestingJob = nestingJobs[0]
      this.loadingData = false
      if (this.currentNestingJob && this.currentNestingJob.code === JobStatusCode.COMPLETE) {
        this.lastCompletedJob = this.currentNestingJob
      }
    } else {
      this.loadingData = false
    }
  }

  updateStartButtonStatus() {
    this.$emit('setOkDisabled', true)
  }

  getMaxMargin(minMargin: number): number {
    let maxMargin = minMargin
    const selectedBuildPlate = this.allBuildPlates.find(
      (buildPlate) =>
        buildPlate.id === this.buildPlan.buildPlateId && buildPlate.version === this.buildPlan.buildPlateVersion,
    )
    if (selectedBuildPlate) {
      maxMargin =
        selectedBuildPlate.buildPlateDimensionX < selectedBuildPlate.buildPlateDimensionY
          ? selectedBuildPlate.buildPlateDimensionX / 2
          : selectedBuildPlate.buildPlateDimensionY / 2
      maxMargin = maxMargin * 1000 // to mm
    }

    return maxMargin
  }

  getWallsMarginRules() {
    return {
      required: true,
      min_value: this.minWallsMargin,
      max_value: this.maxWallsMargin,
    }
  }

  getPartMarginRules() {
    return {
      required: true,
      min_value: this.minPartMargin,
      max_value: this.maxPartMargin,
    }
  }

  async revertBuildPlanArrangeConstraints(hideErrorAndThrowException = false) {
    const beforeConstraints = this.buildPlan.constraints
    const afterConstraints = this.priorConstraints

    if (this.areArrangeConstraintsEqual(beforeConstraints, afterConstraints)) return

    const targetBuildPlan: IBuildPlan = this.buildPlan
    targetBuildPlan.constraints = afterConstraints

    try {
      await this.updateBuildPlan({ buildPlan: targetBuildPlan, hideAPIErrorMessages: true })

      const repeatConstraintParams: IRepeatConstraintParams = {
        before: beforeConstraints,
        after: afterConstraints,
      }

      this.addCommand(
        new ChangeArrangeConstraintsCommand(
          this.getCommandType,
          repeatConstraintParams,
          this.buildPlan,
          this.$store.dispatch,
          this.$store.commit,
        ),
      )
    } catch (error) {
      this.arrangeConstraints = beforeConstraints
      if (hideErrorAndThrowException) {
        // This error will be caught in the parent component (BuildPlanSidebar)
        throw error
      }
      messageService.showErrorMessage(error.message)
    }
  }

  async updateBuildPlanArrangeConstraints(hideErrorAndThrowException = false) {
    this.$nextTick(async () => {
      const isValid = await this.$refs.form.validate()
      this.$emit('setOkDisabled', !isValid)
      if (isValid) {
        const beforeConstraints = { ...this.buildPlan.constraints }
        const afterConstraints = { ...this.arrangeConstraints }

        if (this.areArrangeConstraintsEqual(beforeConstraints, afterConstraints)) return

        const targetBuildPlan: IBuildPlan = this.buildPlan

        targetBuildPlan.constraints = afterConstraints

        this.$emit('setOkDisabled', true)
        this.disableInputFields = true
        this.setIsLoading(true)

        try {
          await this.updateBuildPlan({ buildPlan: targetBuildPlan, hideAPIErrorMessages: true })

          const repeatConstraintParams: IRepeatConstraintParams = {
            before: beforeConstraints,
            after: afterConstraints,
          }

          this.addCommand(
            new ChangeArrangeConstraintsCommand(
              this.getCommandType,
              repeatConstraintParams,
              this.buildPlan,
              this.$store.dispatch,
              this.$store.commit,
            ),
          )
        } catch (error) {
          this.$emit('setOkDisabled', false)
          this.arrangeConstraints = beforeConstraints
          if (hideErrorAndThrowException) {
            // This error will be caught in the parent component (BuildPlanSidebar)
            throw error
          }

          messageService.showErrorMessage(error.message)
        } finally {
          this.setIsLoading(false)
        }

        this.disableInputFields = false
        this.$emit('setOkDisabled', false)
      }
    })
  }

  private areArrangeConstraintsEqual(oldConstraints: IArrangeConstraints, newConstraints: IArrangeConstraints) {
    return (
      oldConstraints.wallsMargin === newConstraints.wallsMargin &&
      oldConstraints.partMargin === newConstraints.partMargin
    )
  }
}
