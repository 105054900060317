
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { CharacterLengthControl, LabelDirtyState, MarkingContentElementType } from '@/types/Label/enums'
import { DEFAULT_PROMPT_TEXT } from '@/components/layout/buildPlans/marking/elementSettings/UserEntrySettings.vue'
import {
  ActiveDynamicElementDialogInfo,
  BodyOrderMethod,
  CounterSelectionOrder,
  defaultGridLetterSpacing,
  DynamicElementEvents,
  GridLetterDirection,
  GridLetterLocation,
  TextElement,
  UserEntryType,
  ZeroPaddingStyle,
} from '@/types/Label/TextElement'
import CounterTooltip from '@/components/layout/buildPlans/marking/elementTooltips/CounterTooltip.vue'
import GridLetterTooltip from '@/components/layout/buildPlans/marking/elementTooltips/GridLetterTooltip.vue'
import UserEntryTooltip from '@/components/layout/buildPlans/marking/elementTooltips/UserEntryTooltip.vue'
import PrintOrderTooltip from '@/components/layout/buildPlans/marking/elementTooltips/PrintOrderTooltip.vue'
import { DynamicElementSettingsMixin } from '@/components/layout/buildPlans/marking/mixins/DynamicElementSettingsMixin'
import { Mixins, Watch } from 'vue-property-decorator'
import { debounce } from '@/utils/debounce'
import { MULTI_LINE_LATIN_CHARACTERS_PATTERN } from '@/constants'
import { IBuildPlate } from '@/types/BuildPlates/IBuildPlate'
import { LabelServiceMixin } from './mixins/LabelServiceMixin'
import { isNumber } from '@/utils/number'
import { eventBus } from '@/services/EventBus'
import variables from '@/assets/styles/variables.scss'
import { IAnnouncement } from '@/types/Announcement/IAnnouncement'
import { ANNOUNCEMENT_HEIGHT } from '@/components/layout/buildPlans/marking/mixins/LabelTooltipMixin'
import { encodeHtmlCharacters } from '@/utils/string'
import { TrackableLabel } from '@/types/Label/TrackableLabel'

const labelStore = namespace(StoresNamespaces.Labels)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const announcementsStore = namespace(StoresNamespaces.Announcements)

const BACKSPACE_KEY_CODE = 'Backspace'
const DEL_KEY_CODE = 'Delete'
const ARROW_RIGHT_CODE = 'ArrowRight'
const ARROW_LEFT_CODE = 'ArrowLeft'
const ENTER_KEY_CODE = 'Enter'
const NUMPAD_ENTER_KEY_CODE = 'NumpadEnter'
const CONTAINS_DELIMITER_SYMBOLS = new RegExp('^[\u200C\u200A]*$')

interface IMixinInterface extends Vue, DynamicElementSettingsMixin, LabelServiceMixin {}

const DYNAMIC_ELEMENT_SETTINGS_LEFT = 250
const DEBOUNCE_TIME = 500
const DROP_SIDE_RATIO = 0.7
const ALIGN_CONTENT_CLASSNAME = 'd-flex align-baseline'

@Component
export default class LabelTextField extends Mixins<IMixinInterface>(
  Vue,
  DynamicElementSettingsMixin,
  LabelServiceMixin,
) {
  @announcementsStore.Getter('getAnnouncement') announcement: IAnnouncement

  @buildPlansStore.Getter getSelectedBuildPlate: IBuildPlate

  @labelStore.Getter getLastUpdatedDynamicTextElementId: (elementType: MarkingContentElementType) => number
  @labelStore.Getter getTextElementById: (elementId: number) => TextElement
  @labelStore.Action setActiveLabelSetSettingsProp: (payload: { propName: string; value: any }) => void
  @labelStore.Action addNewTextElement: (element: TextElement) => void
  @labelStore.Action updateLabelText: (payload: {
    labelSetId: string
    text: string
    elementToAdd?: TextElement
    elementToRemove?: TextElement
  }) => void

  @labelStore.Mutation removeDynamicElementFromLabelSet: (id: string | number) => void
  @labelStore.Mutation setActiveDynamicElementDialogInfo: (payload: { propertyName: string; value }) => void

  @labelStore.Getter getActiveDynamicElementDialogInfo: ActiveDynamicElementDialogInfo

  $refs!: {
    textField: HTMLElement
  }

  inputDebounced = debounce(DEBOUNCE_TIME, this.onInput)

  markingContentElementType = MarkingContentElementType
  dynamicContentElements = [
    MarkingContentElementType.Sequential_Integer,
    MarkingContentElementType.Grid_Letter,
    MarkingContentElementType.User_Entry,
    MarkingContentElementType.Build_Attribute,
  ]
  dialog: boolean = false
  textElementForDelete: TextElement = null
  textElementIdForDelete = null
  elementTypeForRemoval: MarkingContentElementType = null
  lastSelection: {
    node: Node
    offset: number
  } = null
  eventsMap: Map<string | number, { name: Function; remove: Function }> = new Map<
    string | number,
    { name: Function; remove: Function }
  >()
  dynamicElementsQuantity: Map<number, number[]> = new Map<number, number[]>()
  tooltipComponentId = null
  contenteditable: string = 'true'
  draggedItem: { id: number; index: number } = null
  dynamicElementsToVerify: Array<{ id: number; index: number }> = []
  textStringIsValid: boolean = true
  textElementForAdd: TextElement = null
  existingTextElements: TextElement[] = []

  /** Returns the list of used dynamic elements excluding Print Order ID. */
  get usedDynamicElements() {
    return this.variantDynamicElements.filter(
      (te: TextElement) => te.type !== MarkingContentElementType.Build_Attribute,
    )
  }

  /** Returns object of human-readable names of dynamic elements based on their enum values.
   * @param {MarkingContentElementType} element - enum value of an element.
   */
  elementName(element: MarkingContentElementType) {
    const elementNames = {
      [this.markingContentElementType.Sequential_Integer]: 'Counter',
      [this.markingContentElementType.User_Entry]: 'User Entry',
      [this.markingContentElementType.Grid_Letter]: 'Grid Letter',
      [this.markingContentElementType.Build_Attribute]: 'Print Order ID',
    }

    return elementNames[element]
  }

  /** Returns object of tooltips for dynamic elements based on their enum values.
   * @param {MarkingContentElementType} element - enum value of an element.
   */
  elementTooltip(element: MarkingContentElementType) {
    const elementTooltips = {
      [this.markingContentElementType.Sequential_Integer]: this.$t('labelTool.dynamicElementsTooltips.counter'),
      [this.markingContentElementType.User_Entry]: this.$t('labelTool.dynamicElementsTooltips.userEntry'),
      [this.markingContentElementType.Grid_Letter]: this.$t('labelTool.dynamicElementsTooltips.gridLetter'),
      [this.markingContentElementType.Build_Attribute]: this.$t('labelTool.dynamicElementsTooltips.printOrder'),
    }

    return elementTooltips[element]
  }

  /** Method adds drag and drop events onto each row of a text field. */
  addDragAndDropEvents() {
    const rows = this.$refs.textField.childNodes
    rows.forEach((row: HTMLElement) => {
      row.addEventListener('dragover', this.rowDragOver)
      row.addEventListener('dragenter', this.rowDragEnter)
    })
  }

  /** Prevents default behavior of drag over the element event.
   * @param {DragEvent} event - drag event
   */
  rowDragOver(event: DragEvent) {
    event.preventDefault()
  }

  /** Prevents default behavior of drag enter the element event.
   * @param {DragEvent} event - drag event
   */
  rowDragEnter(event: DragEvent) {
    event.preventDefault()
  }

  /** Method clears all variables related to dynamic elements dialogs when the dialog is getting closed. */
  onClose() {
    this.setActiveDynamicElementDialogInfo({ propertyName: 'activeDialogType', value: null })
    this.setActiveDynamicElementDialogInfo({ propertyName: 'textElement', value: null })
    this.setActiveDynamicElementDialogInfo({ propertyName: 'top', value: 0 })
    this.setActiveDynamicElementDialogInfo({ propertyName: 'left', value: 0 })
    this.dialog = false
  }

  /** Updates currently used counter to refresh parameter in a component
   * element {TextElement} - updated counter
   */
  onCounterUpdated(element: TextElement) {
    this.setActiveDynamicElementDialogInfo({ propertyName: 'textElement', value: element })
  }

  /** Method opens a dialog and applies the position based on a click event's coordinates.
   * @param {MouseEvent} event - mouse event
   */
  toggleDialog(event: MouseEvent) {
    this.dialog = !this.dialog
    const dialogElement = document.getElementsByClassName('dynamic-elements')[0] as HTMLElement
    const clientRect = (event.target as HTMLElement).getBoundingClientRect()
    const topOffset = parseInt(variables.labelToolDialogOffest, 0)
    const announcementHeight = this.announcement ? ANNOUNCEMENT_HEIGHT : 0
    dialogElement.style.top = `${clientRect.top - topOffset - announcementHeight}px`
  }

  /** Method saves the last cursor position of text element. */
  onBlur() {
    const selection: Selection = window.getSelection()
    this.lastSelection = this.$refs.textField.contains(selection.focusNode)
      ? { node: selection.focusNode, offset: selection.focusOffset }
      : null
  }

  /** Method validates the content of input and starts debounce to update the state. */
  onLabelTextChange() {
    this.setOkIsDisabled(true)
    this.inputDebounced()
    this.validateInput()
  }

  /** Pastes text from clipboard data into the label text field
   * Needed to allow pasting only plain text
   */
  onPaste(event: ClipboardEvent) {
    event.preventDefault()

    const pastedText = event.clipboardData.getData('text/plain')
    const selection = window.getSelection()
    if (!selection.rangeCount || !pastedText) {
      return
    }
    selection.deleteFromDocument()
    const textNode = document.createTextNode(pastedText)
    const caretPosition = selection.getRangeAt(0)
    caretPosition.insertNode(textNode)

    // If pasted text is not inside row set it inside next one
    const nextSibling = textNode.nextSibling
    if (
      nextSibling &&
      nextSibling.nodeType === nextSibling.ELEMENT_NODE &&
      textNode.parentElement === this.$refs.textField
    ) {
      ;(nextSibling as Element).prepend(textNode)
    }

    caretPosition.setEndAfter(textNode)
    caretPosition.collapse(false)

    this.onLabelTextChange()
  }

  /** Method adds new dynamic element.
   * @param {MarkingContentElementType} element - element enum value
   */
  onNewElementClick(element: MarkingContentElementType) {
    const id = this.getNextFreeId()
    let newElement: TextElement
    switch (element) {
      case MarkingContentElementType.Grid_Letter:
        newElement = this.createGridLetter(id)
        this.setActiveDynamicElementDialogInfo({ propertyName: 'textElement', value: newElement })
        break
      case MarkingContentElementType.User_Entry:
        newElement = this.createUserEntry(id)
        this.setActiveDynamicElementDialogInfo({ propertyName: 'textElement', value: newElement })
        break
      case MarkingContentElementType.Build_Attribute:
        newElement = this.createPrintOrderId(id)
        break
      case MarkingContentElementType.Sequential_Integer:
        this.setLastDeletedElementIDs([])
        newElement = this.createCounter(id)
        this.setActiveDynamicElementDialogInfo({ propertyName: 'textElement', value: newElement })
        break
    }

    this.textElementForAdd = newElement
    this.existingTextElements = [...this.activeLabelSet.settings.textElements, newElement]
    this.addNewTextElement(newElement)
    this.onDynamicElementAdded({ id, name: newElement.title })
    this.dialog = false
    this.$nextTick(() => this.openSettings(id))
  }

  /** Method creates a Print Order ID dynamic element with given ID
   * @param {number} id - id of new element
   */
  createPrintOrderId(id: number) {
    return {
      title: `Print Order ID`,
      elementIDNumber: id,
      type: MarkingContentElementType.Build_Attribute,
      isStaticValue: true,
      lengthControl: 0,
      mandatory: true,
      lengthMin: 6,
      lengthMax: 6,
      runTimeIndex: null,
      _cachedSpecificsJSON: JSON.stringify({ attribute: 0 }),
    }
  }

  /** Method creates a Counter dynamic element with given ID
   * @param {number} id - id of new element
   */
  createCounter(id: number) {
    const nameId = this.getNextFreeNameIdForType(MarkingContentElementType.Sequential_Integer)
    const lastUpdatedElementId = this.getLastUpdatedDynamicTextElementId(MarkingContentElementType.Sequential_Integer)
    const lastUpdatedElement = isNumber(lastUpdatedElementId) ? this.getTextElementById(lastUpdatedElementId) : null
    const matchesElementType =
      lastUpdatedElement && lastUpdatedElement.type === MarkingContentElementType.Sequential_Integer
    if (lastUpdatedElement && !matchesElementType) {
      // If cached element type does not match current element type we want to throw a warning with a
      // debug information. Text element data should be reset to default values.
      console.warn(
        'There was an attempt of creation of the Counter dynamic element of id ',
        id,
        ' using ',
        lastUpdatedElement.type,
        ' element type. Text element settings were reset to default.',
      )
    }

    let counter: TextElement
    if (isNumber(lastUpdatedElementId) && lastUpdatedElement && matchesElementType) {
      counter = JSON.parse(JSON.stringify(lastUpdatedElement))
      counter.title = `Counter ${nameId}`
      counter.elementIDNumber = id
    } else {
      counter = {
        title: `Counter ${nameId}`,
        elementIDNumber: id,
        type: MarkingContentElementType.Sequential_Integer,
        isStaticValue: true,
        lengthControl: 0,
        mandatory: true,
        lengthMin: 6,
        lengthMax: 6,
        runTimeIndex: null,
        _cachedSpecificsJSON: JSON.stringify({
          increment: 1,
          startNumber: 1,
          leadingZero: 0,
          zeroPadding: ZeroPaddingStyle.None,
          ordering: {
            method: BodyOrderMethod.CartesianSort,
            sortCoordinateOrder: [
              CounterSelectionOrder.LeftToRight,
              CounterSelectionOrder.FrontToBack,
              CounterSelectionOrder.BotToTop,
            ],
          },
        }),
      }
    }

    return counter
  }

  /** Method creates a User Entry dynamic element with given ID
   * @param {number} id - id of new element
   */
  createUserEntry(id: number) {
    const nameId = this.getNextFreeNameIdForType(MarkingContentElementType.User_Entry)
    const lastUpdatedElementId = this.getLastUpdatedDynamicTextElementId(MarkingContentElementType.User_Entry)
    const lastUpdatedElement = isNumber(lastUpdatedElementId) ? this.getTextElementById(lastUpdatedElementId) : null
    const matchesElementType = lastUpdatedElement && lastUpdatedElement.type === MarkingContentElementType.User_Entry
    if (lastUpdatedElement && !matchesElementType) {
      // If cached element type does not match current element type we want to throw a warning with a
      // debug information. Text element data should be reset to default values.
      console.warn(
        'There was an attempt of creation of the User Entry dynamic element of id ',
        id,
        ' using ',
        lastUpdatedElement.type,
        ' element type. Text element settings were reset to default.',
      )
    }

    let userEntry: TextElement
    if (isNumber(lastUpdatedElementId) && lastUpdatedElement && matchesElementType) {
      userEntry = JSON.parse(JSON.stringify(lastUpdatedElement))
      userEntry.title = `User Entry ${nameId}`
      userEntry.elementIDNumber = id
    } else {
      userEntry = {
        title: `User Entry ${nameId}`,
        elementIDNumber: id,
        type: MarkingContentElementType.User_Entry,
        isStaticValue: true,
        lengthControl: CharacterLengthControl.Specific,
        mandatory: true,
        lengthMin: 8,
        lengthMax: 8,
        runTimeIndex: null,
        _cachedSpecificsJSON: JSON.stringify({
          entryType: UserEntryType.AlphaNumeric,
          selectedInputType: CharacterLengthControl.Specific,
          minRange: 1,
          maxRange: 8,
          min: 8,
          max: 8,
          exactly: 8,
          promptText: DEFAULT_PROMPT_TEXT,
        }),
      }
    }

    return userEntry
  }

  /** Method creates a Grid Letter dynamic element with given ID
   * @param {number} id - id of new element
   */
  createGridLetter(id: number) {
    const nameId = this.getNextFreeNameIdForType(MarkingContentElementType.Grid_Letter)
    const lastUpdatedElementId = this.getLastUpdatedDynamicTextElementId(MarkingContentElementType.Grid_Letter)
    const lastUpdatedElement = isNumber(lastUpdatedElementId) ? this.getTextElementById(lastUpdatedElementId) : null
    const matchesElementType = lastUpdatedElement && lastUpdatedElement.type === MarkingContentElementType.Grid_Letter
    if (lastUpdatedElement && !matchesElementType) {
      // If cached element type does not match current element type we want to throw a warning with a
      // debug information. Text element data should be reset to default values.
      console.warn(
        'There was an attempt of creation of the Grid Letter dynamic element of id ',
        id,
        ' using ',
        lastUpdatedElement.type,
        ' element type. Text element settings were reset to default.',
      )
    }

    let gridLetter: TextElement
    if (isNumber(lastUpdatedElementId) && lastUpdatedElement && matchesElementType) {
      gridLetter = JSON.parse(JSON.stringify(lastUpdatedElement))
      gridLetter.title = `Grid Letter ${nameId}`
      gridLetter.elementIDNumber = id
    } else {
      gridLetter = {
        title: `Grid Letter ${nameId}`,
        elementIDNumber: id,
        type: MarkingContentElementType.Grid_Letter,
        isStaticValue: true,
        lengthControl: 0,
        mandatory: true,
        lengthMin: 6,
        lengthMax: 6,
        runTimeIndex: null,
        _cachedSpecificsJSON: JSON.stringify({
          direction: GridLetterDirection.XAxis,
          location: GridLetterLocation.Center,
          offset: -(this.getSelectedBuildPlate.buildPlateDimensionX / 2) * 1000,
          spacing: defaultGridLetterSpacing,
        }),
      }
    }

    return gridLetter
  }

  /** Method adds into a label set already existing element from this or another label set(-s).
   * @param {TextElement} element - existing dynamic element
   */
  addExistingElement(element: TextElement) {
    const index = this.activeLabelSet.settings.textElements.findIndex(
      (te: TextElement) => te.elementIDNumber === element.elementIDNumber,
    )
    if (index < 0) {
      this.textElementForAdd = element
      this.existingTextElements = [...this.activeLabelSet.settings.textElements, element]
    } else {
      this.existingTextElements = this.activeLabelSet.settings.textElements
    }
    this.dialog = !this.dialog
  }

  /** Callback of a keydown event. Makes actions based on key that was pressed.
   * @param {KeyboardEvent} event - keyboard event with a key code
   */
  onKeydown(event: KeyboardEvent) {
    const selection = document.getSelection()
    const node = selection.focusNode
    const nextSymbol = selection.focusNode.textContent[selection.focusOffset]
    const previousSymbol = selection.focusNode.textContent[selection.focusOffset - 1]
    const previousNode: Element = (node as HTMLElement).parentElement.previousElementSibling
    const nextNode: Element = (node as HTMLElement).parentElement.nextElementSibling

    if (selection.getRangeAt(0).toString() && selection.getRangeAt(0).toString().length > 1) {
      const selected = selection.getRangeAt(0)
      const clone = selected.cloneContents()
      const wrapper = document.createElement('div')
      wrapper.appendChild(clone)
      const elementsInSelection = wrapper.querySelectorAll('.element-chip')
      if (elementsInSelection.length) {
        const elementsIDsAndIndexes = []
        elementsInSelection.forEach((el: HTMLElement) => {
          const id = el.getAttribute('data-id')
          const elementIndex = el.getAttribute('data-index')
          const i = this.dynamicElementsToVerify.findIndex(
            (val) => val.id === parseInt(id, 10) && val.index === parseInt(elementIndex, 10),
          )

          if (i < 0) {
            elementsIDsAndIndexes.push({ id: parseInt(id, 10), index: parseInt(elementIndex, 10) })
          }
        })
        this.dynamicElementsToVerify.push(...elementsIDsAndIndexes)
      }
    }

    switch (event.code) {
      case BACKSPACE_KEY_CODE:
        this.lastSelection = { node: selection.focusNode, offset: selection.focusOffset }
        const testLeftResult = this.testLeftNodes(selection)
        if (testLeftResult.shouldDelete) {
          event.preventDefault()
          this.lastSelection = { node: testLeftResult.startAfter, offset: null }
          testLeftResult.nodes.forEach((n: HTMLElement, index) => {
            const isLast = index === testLeftResult.nodes.length - 1
            let parent = null
            if (isLast) {
              parent = n.parentElement
            }
            if (this.isElementChip(n)) {
              const id = n.getAttribute('data-id')
              const elementIndex = n.getAttribute('data-index')
              this.removeElement(id, +elementIndex)
            } else if (this.isEmptyTag(n)) {
              n.remove()
            }
            const noContent =
              parent && (parent.textContent === '' || RegExp(CONTAINS_DELIMITER_SYMBOLS).test(parent.textContent))
            if (parent && this.isWrapperTag(parent.parentElement) && noContent) {
              parent.remove()
              this.lastSelection = { node: null, offset: null }
            }
          })
        }
        break
      case DEL_KEY_CODE:
        this.lastSelection = { node: selection.focusNode, offset: selection.focusOffset }
        const testRightResult = this.testRightNodes(selection)
        if (testRightResult.shouldDelete) {
          event.preventDefault()
          this.lastSelection = { node: testRightResult.startAfter, offset: null }
          testRightResult.nodes.forEach((n: HTMLElement) => {
            if (this.isElementChip(n)) {
              const id = n.getAttribute('data-id')
              const elementIndex = n.getAttribute('data-index')
              this.removeElement(id, +elementIndex)
            } else if (this.isEmptyTag(n)) {
              n.remove()
            }
          })
        }
        break

      case ARROW_LEFT_CODE:
        if (event.shiftKey) {
          break
        }
        if (previousSymbol === '\u200C' && selection.focusOffset === 2) {
          const range = document.createRange()

          if (previousNode && previousNode.classList.contains('item')) {
            const previousEmpty = previousNode.previousElementSibling
            if (previousEmpty) {
              if (previousEmpty.classList.contains('empty')) {
                range.setStart(previousEmpty.childNodes[0], previousEmpty.childNodes[0].textContent.length - 1)
              }
            } else {
              range.setStartBefore(selection.focusNode)
            }
          } else {
            range.setStartBefore(selection.focusNode)
          }

          range.collapse(true)

          selection.removeAllRanges()
          selection.addRange(range)
        }
        break
      case ARROW_RIGHT_CODE:
        if (event.shiftKey) {
          break
        }
        if (nextSymbol === '\u200A') {
          const range = document.createRange()
          if (nextNode && nextNode.classList.contains('item')) {
            const nextEmpty = nextNode.nextElementSibling
            if (nextEmpty) {
              if (nextEmpty.classList.contains('empty')) {
                range.setStart(nextEmpty.childNodes[0], 0)
              }
            } else {
              range.setStartAfter(selection.focusNode)
            }
          } else {
            range.setStartAfter(selection.focusNode)
          }

          range.collapse(true)

          selection.removeAllRanges()
          selection.addRange(range)
        }

        break
      case ENTER_KEY_CODE:
      case NUMPAD_ENTER_KEY_CODE:
        event.preventDefault()
        this.insertNewRow(selection)
        this.onLabelTextChange()
        break
    }
  }

  /**
   * Inserts new row at a place of caret position.
   * Content of original row after caret position is carried over to new row
   */
  async insertNewRow(selection: Selection) {
    const range = selection.getRangeAt(0)

    // find row where caret is positioned
    let firstIntersectedDiv: Element = null
    let intersectedNodeIndex: number = 0
    for (const node of this.$refs.textField.children) {
      // if point equals 1 caret is positioned before row
      const point = range.comparePoint(node, 0)
      if (point === 1) {
        // in case caret is positioned before row
        // need to set caret inside that row
        range.setStart(node, 0)
      }

      if (range.intersectsNode(node)) {
        firstIntersectedDiv = node
        break
      }
      intersectedNodeIndex += 1
    }

    if (firstIntersectedDiv) {
      range.deleteContents()
      range.collapse(false)
      range.setEndAfter(firstIntersectedDiv)
      const elementsToCut = range.extractContents()
      firstIntersectedDiv.after(elementsToCut)

      range.collapse(false)
      range.setStartAfter(firstIntersectedDiv)
    } else {
      const newRow = this.createEmptyRow()
      this.$refs.textField.append(newRow)

      range.collapse(false)
      range.setStart(newRow, 0)
    }

    this.existingTextElements = this.activeLabelSet.settings.textElements
    this.encodeTextString()
    await this.$nextTick()
    this.setupLines()
    await this.$nextTick()
    this.setCaretOntoNewRow(intersectedNodeIndex + 1)
  }

  /**
   * Using the index of a new row sets a caret to it's start
   */
  setCaretOntoNewRow(index: number) {
    const selection = document.getSelection()
    const range = document.createRange()
    range.collapse(false)
    range.setStart(this.$refs.textField.children[index], 0)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  /**
   * Returns new empty row for text field
   */
  createEmptyRow() {
    const row = document.createElement('div')
    row.classList.add(...ALIGN_CONTENT_CLASSNAME.split(' '))
    return row
  }

  /** Method looks onto the left nodes recursively to define which nodes should be deleted while pressing backspace
   * or left arrow button. Spans with class 'Empty' should be deleted with dynamic elements and should be skipped by
   * left arrow button.
   * @param {Selection} selection - current position of a cursor
   */
  testLeftNodes(selection: Selection): { shouldDelete: boolean; nodes: HTMLElement[]; startAfter: Node } {
    const nodes = []
    const offset = selection.focusOffset
    const node = selection.focusNode as HTMLElement
    if (offset === 0 || ([1, 2].includes(offset) && ['\u200A', '\u200C'].includes(node.textContent[offset - 1]))) {
      const previousElement =
        this.isEmptyTag(node) || this.isWrapperTag(node)
          ? (node.previousSibling as HTMLElement)
          : (node.parentElement.previousSibling as HTMLElement)
      nodes.push(this.isEmptyTag(node) && node.textContent === '\u200A\u200C' ? node : node.parentElement)
      if (!this.isElementChip(previousElement)) {
        this.testSiblingElement(previousElement, nodes)
      } else {
        nodes.push(previousElement)
      }
    }

    const startAfter = this.getElementChipSiblingElement(nodes)

    if (!startAfter) {
      nodes.length = 0
    }

    if (!!nodes.length && nodes.every((n: HTMLElement) => this.isEmptyTag(n))) {
      const theMostLeftElement = nodes[nodes.length - 1]
      if (theMostLeftElement && !theMostLeftElement.previousSibling) {
        const rowElement: HTMLElement = theMostLeftElement.parentElement
        const allRowsNodes = Array.prototype.slice.call(this.$refs.textField.children)
        const rowIndex = allRowsNodes.indexOf(rowElement)
        this.$nextTick(() => {
          this.existingTextElements = this.activeLabelSet.settings.textElements
          this.encodeTextString(rowIndex)
          this.setupLines()
        })
        return {
          nodes: [],
          startAfter: null,
          shouldDelete: false,
        }
      }
    }

    return {
      nodes,
      startAfter,
      shouldDelete: !!nodes.length,
    }
  }

  /** Method looks onto the right nodes recursively to define which nodes should be deleted while pressing delete or
   * right arrow button. Spans with class 'Empty' should be deleted with dynamic elements and should be skipped by
   * right arrow button.
   * @param {Selection} selection - current position of a cursor
   */
  testRightNodes(selection: Selection): { shouldDelete: boolean; nodes: HTMLElement[]; startAfter: Node } {
    const nodes = []
    const offset = selection.focusOffset
    const node = selection.focusNode as HTMLElement
    if (
      offset === 0 ||
      offset === selection.focusNode.textContent.length - 1 ||
      ([1, 2].includes(offset) && '\u200A\u200C' === node.textContent)
    ) {
      const nextElement =
        this.isEmptyTag(node) || this.isWrapperTag(node)
          ? (node.nextSibling as HTMLElement)
          : (node.parentElement.nextSibling as HTMLElement)
      nodes.push(this.isEmptyTag(node) && node.textContent === '\u200A\u200C' ? node : node.parentElement)
      if (!this.isElementChip(nextElement)) {
        this.testSiblingElement(nextElement, nodes, false)
      } else {
        nodes.push(nextElement)
      }
    }

    const startAfter = this.getElementChipSiblingElement(nodes, false)

    if (!startAfter) {
      nodes.length = 0
    }

    if (!!nodes.length && nodes.every((n: HTMLElement) => this.isEmptyTag(n))) {
      const theMostRightElement = nodes[nodes.length - 1]
      if (theMostRightElement && !theMostRightElement.nextSibling) {
        const rowElement: HTMLElement = theMostRightElement.parentElement
        const allRowsNodes = Array.prototype.slice.call(this.$refs.textField.children)
        const rowIndex = allRowsNodes.indexOf(rowElement)
        this.$nextTick(() => {
          this.existingTextElements = this.activeLabelSet.settings.textElements
          this.encodeTextString(rowIndex + 1)
          this.setupLines()
        })
        return {
          nodes: [],
          startAfter: null,
          shouldDelete: false,
        }
      }
    }

    return {
      nodes,
      startAfter,
      shouldDelete: !!nodes.length,
    }
  }

  /** Method finds "empty" tag near the dynamic element chip. It is needed to remove or to skip empty tags while.
   * navigating with arrows in a text field.
   * @param {HTMLElement[]} nodes - nodes of a row with empty tags and element chips
   * @param {boolean} prev - indicates where to search empty tags - before element or after
   */
  getElementChipSiblingElement(nodes: HTMLElement[], prev: boolean = true) {
    const element = nodes && nodes.find((n: HTMLElement) => n.classList.contains('element-chip'))
    const allEmpty = nodes.every((n: HTMLElement) => this.isEmptyTag(n))
    let result = null
    if (element) {
      result = prev ? element.previousElementSibling : element.nextElementSibling
    } else if (allEmpty) {
      result = prev ? nodes[0] : nodes[nodes.length - 1]
    }
    return result
  }

  /** Method tests sibling elements to be either empty tag or dynamic element chip.
   * @param {HTMLElement} element - element to be tested
   * @param {HTMLElement[]} nodes - of a row
   * @param {boolean} prev - indicates if previous or next siblings should be tested
   */
  testSiblingElement(element: HTMLElement, nodes: HTMLElement[], prev: boolean = true) {
    if (this.isEmptyTag(element)) {
      nodes.push(element)
      this.testSiblingElement(
        prev ? (element.previousSibling as HTMLElement) : (element.nextSibling as HTMLElement),
        nodes,
        prev,
      )
    }
    if (this.isElementChip(element)) {
      nodes.push(element)
    }
  }

  /** Method tests whether html element is a dynamic element chip.
   * @param {HTMLElement} element - element to be tested
   */
  isElementChip(element: HTMLElement) {
    return element && element.classList && element.classList.contains('element-chip')
  }

  /** Method tests whether html element is an empty tag.
   * @param {HTMLElement} element - element to be tested
   */
  isEmptyTag(element: HTMLElement) {
    return (
      element &&
      element.classList &&
      element.classList.contains('empty') &&
      (element.textContent === '\u200A\u200C' || element.textContent === '')
    )
  }

  /** Method tests whether html element is a whitespace tag.
   * @param {HTMLElement} element - element to be tested
   */
  isWhiteSpaceTag(element: HTMLElement) {
    return element && element.classList && element.classList.contains('empty') && element.textContent === '<br>'
  }

  /** Method tests whether html element is row wrapper tag.
   * @param {HTMLElement} element - element to be tested
   */
  isWrapperTag(element: HTMLElement) {
    return element && element.classList && element.classList.contains('text-wrapper')
  }

  /** Callback of a text field input event that do validation of dynamic elements and encodes text string. */
  onInput() {
    this.existingTextElements = this.activeLabelSet.settings.textElements
    this.verifyDynamicElements()
    this.encodeTextString()
    this.clearCachedInsights()
    this.dynamicElementsToVerify = []
  }

  /** Method validates dynamic elements and if dynamic element is not found in a text string - deletes it from a state
   * and from internal variables.
   */
  verifyDynamicElements() {
    this.dynamicElementsToVerify.forEach((element) => {
      const tag = this.$refs.textField.querySelector(
        `.element-chip[data-id='${element.id}'][data-index='${element.index}']`,
      )
      if (!tag) {
        // delete if last and index
        this.removeDynamicElementIndex(element.id, element.index)
        this.removeDynamicElementFromActiveLabelSet(element.id)
        this.removeDynamicElementIfNotUsed(element.id)
      }
    })
  }

  /** Method validates input field to find cases where it is empty or filled with unneeded spaces or line breaks and
   * to fix these cases.
   * @param {string} textContent - text content of an input
   */
  validateInput(textContent?: string) {
    const childNodes = this.$refs.textField.childNodes
    if (!childNodes.length) {
      this.addFirstRow()
      return
    }
    if (childNodes.length === 1) {
      const node = childNodes[0] as Element
      if (node.tagName === 'BR') {
        node.remove()
        this.addFirstRow()
        return
      }
    }
    if (textContent === '') {
      this.$refs.textField.innerHTML = ''
      this.addFirstRow()
    }
  }

  /** Method adds first row with placeholder into an input. */
  addFirstRow() {
    const firstNode = document.createElement('div')
    firstNode.dataset.ph = 'Label text here'
    this.$refs.textField.appendChild(firstNode)
    this.lastSelection = null
  }

  /** Method encodes text string into a content that can be passed to the label core.
   * @param {number} removeNewLineRowIndex - removes newlines by index
   */
  encodeTextString(removeNewLineRowIndex?: number) {
    let text = ''
    this.$refs.textField.childNodes.forEach((row, i, array) => {
      row.childNodes.forEach((node: Element) => {
        if (!this.isWhiteSpaceTag(node as HTMLElement)) {
          text += this.getNodeText(node)
        }
      })
      if (i !== array.length - 1) {
        text += '\u000a'
      }
    })
    if (removeNewLineRowIndex) {
      const textByRows = text.split('\u000a')
      text = ''
      textByRows.forEach((t: string, index: number) => {
        if (index !== 0 && index !== removeNewLineRowIndex) {
          text += '\u000a'
        }
        text += t
      })
    }
    this.textStringIsValid = RegExp(MULTI_LINE_LATIN_CHARACTERS_PATTERN).test(text)
    const bodies = [...this.activeLabelSet.selectedBodies, ...this.activeLabelSet.relatedBodies]
    const shouldReCreateTrackableLabels =
      this.activeLabelSet.settings.textContent === '' &&
      text !== '' &&
      bodies.length &&
      (!this.activeLabelSet.labels.length ||
        this.activeLabelSet.labels.every((l: TrackableLabel) => l.dirtyState === LabelDirtyState.Remove))
    if (shouldReCreateTrackableLabels) {
      this.recreateTrackableLabels()
    }
    this.updateLabelText({
      text,
      labelSetId: this.activeLabelSet.id,
      elementToAdd: this.textElementForAdd,
      elementToRemove: this.textElementForDelete,
    })
    this.textElementForAdd = this.textElementForDelete = null

    return text
  }

  /** Method extracts text from an HTML node. It transforms dynamic elements into text representation. */
  getNodeText(node: Element): string {
    let text = ''
    if (node.classList && node.classList.contains('element-chip')) {
      const id = node.getAttribute('data-id')
      const index = this.existingTextElements.findIndex(
        (dynamicElement: TextElement) => dynamicElement.elementIDNumber.toString() === id,
      )
      return `{${index + 1}}`
    }

    if (node.tagName) {
      if (node.childNodes.length) {
        node.childNodes.forEach((n: Element) => {
          text += this.getNodeText(n)
        })
        return text
      }
      return node.textContent.replace(/\u200C/g, '').replace(/\u200A/g, '')
    }
    return node.textContent.replace(/\u200C/g, '').replace(/\u200A/g, '')
  }

  mounted() {
    this.setupLines()
    this.contenteditable = this.isLabelReadOnly ? 'false' : 'true'
    eventBus.$on(DynamicElementEvents.OnDialogClosed, this.onClose)
    eventBus.$on(DynamicElementEvents.OnElementAdded, this.onDynamicElementAdded)
    eventBus.$on(DynamicElementEvents.OnDialogUpdated, this.onCounterUpdated)
  }

  @Watch('activeLabelSet', { immediate: true })
  onActiveLabelSetChange() {
    this.lastSelection = null
    this.setupLines()
  }

  /** Initial creation of text field lines. Adds a row with a placeholder or inserts existing lines from a label set. */
  setupLines() {
    this.$nextTick(() => {
      const lines = this.getLines()
      if (!lines) {
        this.$refs.textField.innerHTML = `<div class="${ALIGN_CONTENT_CLASSNAME}" data-ph="Label text here"></div>`
      } else {
        this.$refs.textField.innerHTML = lines
        this.textStringIsValid = RegExp(MULTI_LINE_LATIN_CHARACTERS_PATTERN).test(
          this.activeLabelSet.settings.textContent,
        )
        this.addDragAndDropEvents()
      }
      this.setEventsForAllDynamicElements()
    })
  }

  /** Method iterates through dynamic HTML elements and adds events onto them. */
  setEventsForAllDynamicElements() {
    const chipElements: NodeListOf<Element> = this.$refs.textField.querySelectorAll('.element-chip')
    chipElements.forEach((element: HTMLElement) => {
      this.addEventsOntoElement(element)
    })
  }

  /** Method adds events onto a specific dynamic element's HTML element
   * @param {HTMLElement} element - dynamic element's HTML representation
   */
  addEventsOntoElement(element: HTMLElement) {
    const id = element.getAttribute('data-id')
    const elementIndex = element.getAttribute('data-index')
    const nameElement = element.querySelector('.name-element')
    const removeElement = element.querySelector('.chip-icon')
    const openMethod = this.openSettings.bind(this, id)
    const removeMethod = this.removeElement.bind(this, id, elementIndex)
    const dragStart = this.dragStarted.bind(this, id, elementIndex)
    const dragEnd = this.dynamicElementDrop.bind(this, id, elementIndex)
    nameElement.addEventListener('click', openMethod)
    removeElement.addEventListener('click', removeMethod)
    element.addEventListener('dragstart', dragStart)
    element.addEventListener('dragend', dragEnd)
    this.eventsMap.set(id, {
      name: openMethod,
      remove: removeMethod,
    })
  }

  /** Callback of a drag start event. Adds drop-zone class onto each row so dynamic element can be dropped onto it.
   * @param {number} id - id of an element that was dragged
   * @param {number} index - index of the element that was dragged
   */
  dragStarted(id: number, index: number) {
    this.draggedItem = { id, index }
    this.$refs.textField.childNodes.forEach((row: HTMLElement) => {
      row.childNodes.forEach((child: HTMLElement) => {
        if (child.tagName === 'DIV') {
          child.classList.add('drop-zone')
        }
        if (child.tagName === 'SPAN') {
          child.innerHTML = child.textContent
            .split('')
            .map((char: string) => {
              if (['\u200C', '\u200A'].includes(char)) {
                return char
              }
              return `<span class="drop-zone">${char}</span>`
            })
            .join('')
        }
        if (!child.tagName) {
          const parent = (child as Node).parentElement
          const wrapper = document.createElement('span')
          parent.insertBefore(wrapper, child)
          wrapper.appendChild(child)
          wrapper.innerHTML = wrapper.textContent
            .replace('&nbsp;', ' ')
            .split('')
            .map((char: string) => {
              return `<span class="drop-zone">${char}</span>`
            })
            .join('')
        }
      })
    })

    const dropZones = document.querySelectorAll('.drop-zone')
    dropZones.forEach((dropZone: HTMLElement) => {
      dropZone.addEventListener('dragover', this.rowDragOver)
      dropZone.addEventListener('dragenter', this.rowDragEnter)
      dropZone.addEventListener('drop', this.dropZoneOnDrop.bind(this))
    })

    const dynamicElements = document.querySelectorAll('.element-chip')
    dynamicElements.forEach((dynamicElement: HTMLElement) => {
      dynamicElement.addEventListener('drop', this.dynamicElementDrop.bind(this))
    })
  }

  /** Drop event callback. */
  dynamicElementDrop() {
    this.existingTextElements = this.activeLabelSet.settings.textElements
    this.$nextTick(() => {
      this.encodeTextString()
      this.setupLines()
    })
  }

  /** Method inserts dynamic element into a text string based on a coordinates given in an event.
   * @param {DragEvent} event - drag event
   */
  dropZoneOnDrop(event: DragEvent) {
    const target = event.target as HTMLElement
    const targetDropZone: HTMLElement = target.classList.contains('drop-zone') ? target : target.closest('.drop-zone')

    const dynamicElement: Element = this.$refs.textField.querySelector(
      `.element-chip[data-id='${this.draggedItem.id}'][data-index='${this.draggedItem.index}']`,
    )
    const { left, width } = targetDropZone.getBoundingClientRect()
    const { clientX } = event
    const append = left + width * DROP_SIDE_RATIO < clientX
    if (append) {
      targetDropZone.after(dynamicElement)
    } else {
      targetDropZone.before(dynamicElement)
    }
    this.existingTextElements = this.activeLabelSet.settings.textElements
    this.encodeTextString()
    this.$nextTick(() => {
      this.setupLines()
    })
  }

  /** Method opens a settings dialog box based on an element type.
   * @param {string | number} id - id of a dynamic element that had to be opened
   * @param {PointerEvent} event - pointer event with screen coordinates of a dynamic element
   */
  openSettings(id: string | number, event?: PointerEvent) {
    let top = 0
    if (event) {
      top = (event.target as Element).getBoundingClientRect().top
    } else {
      const eventTarget: Element = document.querySelector(`[data-id="${id}"]`)
      if (eventTarget) top = eventTarget.getBoundingClientRect().top
    }
    const topOffset = parseInt(variables.labelToolDialogOffest, 0)
    this.setActiveDynamicElementDialogInfo({ propertyName: 'top', value: top - topOffset })
    this.setActiveDynamicElementDialogInfo({ propertyName: 'left', value: DYNAMIC_ELEMENT_SETTINGS_LEFT })
    const textElement = this.variantDynamicElements.find(
      (s: TextElement) => s.elementIDNumber.toString() === id.toString(),
    )
    switch (textElement.type) {
      case MarkingContentElementType.Grid_Letter:
        if (this.getActiveDynamicElementDialogInfo.activeDialogType === MarkingContentElementType.Grid_Letter) {
          this.setActiveDynamicElementDialogInfo({ propertyName: 'activeDialogType', value: null })
          this.setActiveDynamicElementDialogInfo({ propertyName: 'textElement', value: null })
          this.dialog = false
        } else {
          this.setActiveDynamicElementDialogInfo({ propertyName: 'textElement', value: textElement })
          this.setActiveDynamicElementDialogInfo({
            propertyName: 'activeDialogType',
            value: MarkingContentElementType.Grid_Letter,
          })
        }
        break
      case MarkingContentElementType.User_Entry:
        if (this.getActiveDynamicElementDialogInfo.activeDialogType === MarkingContentElementType.User_Entry) {
          this.setActiveDynamicElementDialogInfo({ propertyName: 'activeDialogType', value: null })
          this.setActiveDynamicElementDialogInfo({ propertyName: 'textElement', value: null })
          this.dialog = false
        } else {
          this.setActiveDynamicElementDialogInfo({ propertyName: 'textElement', value: textElement })
          this.setActiveDynamicElementDialogInfo({
            propertyName: 'activeDialogType',
            value: MarkingContentElementType.User_Entry,
          })
        }
        break
      case MarkingContentElementType.Sequential_Integer:
        if (this.getActiveDynamicElementDialogInfo.activeDialogType === MarkingContentElementType.Sequential_Integer) {
          this.setActiveDynamicElementDialogInfo({ propertyName: 'activeDialogType', value: null })
          this.setActiveDynamicElementDialogInfo({ propertyName: 'textElement', value: null })
          this.dialog = false
        } else {
          this.setActiveDynamicElementDialogInfo({ propertyName: 'textElement', value: textElement })
          this.setActiveDynamicElementDialogInfo({
            propertyName: 'activeDialogType',
            value: MarkingContentElementType.Sequential_Integer,
          })
        }
        break
    }
  }

  /** Wipes all internal variables related to dialog state when settings dialog closes. */
  closeSettingsDialog() {
    this.setActiveDynamicElementDialogInfo({ propertyName: 'activeDialogType', value: null })
    this.setActiveDynamicElementDialogInfo({ propertyName: 'textElement', value: null })
  }

  /** Removes dynamic element from internal variables after it deletion.
   * @param {string | number} id - id of a dynamic element
   * @param {number} elementIndex - index of a dynamic element HTML element
   */
  removeElement(id: string | number, elementIndex: number) {
    if (this.isLabelReadOnly) {
      return
    }

    if (this.getActiveDynamicElementDialogInfo.activeDialogType) {
      this.closeSettingsDialog()
    }
    const textElement = this.variantDynamicElements.find(
      (s: TextElement) => s.elementIDNumber.toString() === id.toString(),
    )
    this.elementTypeForRemoval = textElement.type
    this.textElementForDelete = textElement
    this.textElementIdForDelete = elementIndex
    this.removeHTMLNode()
  }

  /** Method removes HTML element of a dynamic element on its deletion. */
  removeHTMLNode() {
    const elementId = this.textElementForDelete.elementIDNumber
    const elementIndex = this.textElementIdForDelete
    const chipHTMLElement: Element = this.$refs.textField.querySelector(
      `.element-chip[data-id='${elementId}'][data-index='${elementIndex}']`,
    )
    // Remove dynamic element from indices table in order to determine if the removed element was the last of its kind
    this.removeDynamicElementIndex(this.textElementForDelete.elementIDNumber, this.textElementIdForDelete)
    const indexes = this.dynamicElementsQuantity.get(this.textElementForDelete.elementIDNumber)
    if (!indexes.length) {
      // If dynamic element was last of its kind in a label set - remove it from label set dynamic elements list
      this.removeDynamicElementFromActiveLabelSet(this.textElementForDelete.elementIDNumber)
    }

    this.existingTextElements = this.activeLabelSet.settings.textElements.filter(
      (textElement) => indexes.length || textElement.elementIDNumber !== elementId,
    )
    const prevSibling = chipHTMLElement.previousSibling as HTMLElement
    const nextSibling = chipHTMLElement.nextSibling as HTMLElement
    if (this.isEmptyTag(prevSibling)) {
      prevSibling.remove()
    }

    if (this.isEmptyTag(nextSibling)) {
      nextSibling.remove()
    }

    chipHTMLElement.remove()
    this.lastSelection = null
    this.textElementIdForDelete = null
    if (indexes.length) {
      this.textElementForDelete = null
    }

    this.setForceRecalculateRelatedForIds(elementId.toString())
    const t = this.encodeTextString()
    if (!indexes.length) {
      // If dynamic element was last of its kind in all label sets - remove it from label sets dynamic elements list
      this.removeDynamicElementIfNotUsed(elementId)
    }
    this.validateInput(t)

    setTimeout(() => {
      this.existingTextElements = []
      if (this.lastSelection && this.lastSelection.node && this.lastSelection.node.isConnected) {
        const range = document.createRange()
        const selection = document.getSelection()
        const nodeOffset = this.lastSelection.offset
          ? this.lastSelection.offset
          : this.lastSelection.node.textContent.length - 1

        if (this.lastSelection.node.childNodes.length >= nodeOffset) {
          range.setStart(this.lastSelection.node, nodeOffset)
          range.collapse(false)

          selection.removeAllRanges()
          selection.addRange(range)
        } else {
          this.$refs.textField.focus()
        }
      }
    }, 0)
  }

  /** Method removes dynamic element from state of an active label set.
   * @param {number} id - id of dynamic element
   */
  removeDynamicElementFromActiveLabelSet(id: number) {
    const indexes = this.dynamicElementsQuantity.get(id)
    if (!indexes || !indexes.length) {
      this.removeDynamicElementFromLabelSet(id)
    } else {
      this.textElementForDelete = null
    }
  }

  /** Removes dynamic element from internal variables by it's id and it's HTML element index.
   * @param {number} id - dynamic element id
   * @param {number} index - dynamic element's HTML element index
   */
  removeDynamicElementIndex(id: number, index: number) {
    const indexes = this.dynamicElementsQuantity.get(id)
    this.dynamicElementsQuantity.set(
      id,
      indexes.filter((i) => i !== +index),
    )
  }

  /** Method decodes text string from an existing label set into text string lines. */
  getLines(): string {
    this.dynamicElementsQuantity.clear()
    if (!this.activeLabelSet.settings.textContent || !this.activeLabelSet.settings.textContent.length) {
      return ''
    }

    // Encode label text to prevent possible html injection
    // has to be done before generating HTML tags for dynamic elements
    const safeTextContent = encodeHtmlCharacters(this.activeLabelSet.settings.textContent)

    const lines = safeTextContent.split('\n')
    const linesFormatted = lines.map((line: string) => {
      const linePieces = line.split(/(?={[0-9]+})|(?<={[0-9]+})/g)
      return linePieces
        .map((piece: string) => {
          if (/{[0-9]+}/g.test(piece)) {
            const elementId = piece.replace('{', '').replace('}', '')
            const elementData = this.activeLabelSet.settings.textElements[+elementId - 1]
            const elementIndex = this.addNewDynamicElementIndex(elementData.elementIDNumber)
            return this.buildDynamicElement(
              elementData.title,
              elementData.elementIDNumber.toString(),
              elementIndex.toString(),
            )
          }
          return piece
        })
        .join('')
    })
    return linesFormatted
      .map((line: string) => {
        return this.wrapLineIntoDiv(line, ALIGN_CONTENT_CLASSNAME)
      })
      .join('')
  }

  /** Adds dynamic element of a type which was already used it this label set into a text string
   * @param {number} id - id of a dynamic element
   */
  addNewDynamicElementIndex(id: number) {
    const indexes = this.dynamicElementsQuantity.get(id)
    let index: number
    if (!indexes || !indexes.length) {
      index = 0
      this.dynamicElementsQuantity.set(id, [index])
      return index
    }
    if (indexes.length) {
      index = 0
      while (indexes.includes(index)) {
        index += 1
      }
      indexes.push(index)
      this.dynamicElementsQuantity.set(id, indexes)
      return index
    }
  }

  /** Method adds HTML element after the dynamic element is added. It wraps element with two empty tags, create element
   * chip and sets the position of a text caret.
   * @param {{ name: string; id: string | number }} elementData - settings of a new dynamic element
   */
  onDynamicElementAdded(elementData: { name: string; id: number }) {
    const range = document.createRange()
    const selection = document.getSelection()
    let n: Node
    let o: number
    let setAfter: boolean = false

    if (!!this.lastSelection && this.isWrapperTag(this.lastSelection.node as HTMLElement)) {
      this.lastSelection = null
    }

    if (this.lastSelection && this.lastSelection.node) {
      n = this.lastSelection.node
      o = this.lastSelection.offset
    } else {
      const childNodesList: NodeList = this.$refs.textField.childNodes
      if (childNodesList.length) {
        const lastNode = childNodesList[childNodesList.length - 1]
        if (lastNode.childNodes && lastNode.childNodes.length) {
          n = lastNode.childNodes[lastNode.childNodes.length - 1] as Element
          o = lastNode.childNodes[lastNode.childNodes.length - 1].textContent.length
          if ((n as Element).classList && (n as Element).classList.contains('element-chip')) {
            setAfter = true
          }
        } else {
          n = lastNode
          o = lastNode.textContent.length === 0 ? 0 : lastNode.textContent.length - 1
        }
      } else {
        const firstNode = document.createElement('div')
        this.$refs.textField.appendChild(firstNode)
        n = firstNode
        o = 0
      }
    }

    const isEmptySpan = (n as Element).tagName === 'SPAN' && (n as Element).classList.contains('empty')
    const isTextNode = n.nodeType === Node.TEXT_NODE
    const isChildOfEmpty =
      isTextNode &&
      (n as Element).parentElement.tagName === 'SPAN' &&
      (n as Element).parentElement.classList.contains('empty')

    if (isEmptySpan) {
      range.setStartAfter(n)
    } else if (isTextNode && isChildOfEmpty) {
      range.setStartAfter((n as Element).parentElement)
    } else {
      if (setAfter) {
        range.setStartAfter(n)
      } else {
        range.setStart(n, o)
      }
    }
    range.collapse(false)

    const dynamicElement = this.buildDynamicElementWithOutWrapper(elementData.name)
    const wrapper = document.createElement('div')
    wrapper.classList.add('item', 'element-chip', `element-chip-${elementData.id.toString()}`)
    wrapper.setAttribute('data-id', elementData.id.toString())
    wrapper.setAttribute('data-index', this.addNewDynamicElementIndex(elementData.id).toString())
    wrapper.setAttribute('draggable', 'true')
    wrapper.innerHTML = dynamicElement

    const prevZWNJ = document.createElement('span')
    const nextZWNJ = document.createElement('span')
    prevZWNJ.classList.add('empty')
    nextZWNJ.classList.add('empty')
    prevZWNJ.innerHTML = '&#x200A;&zwnj;'
    nextZWNJ.innerHTML = '&#x200A;&zwnj;'

    range.insertNode(prevZWNJ)
    prevZWNJ.after(wrapper)

    wrapper.after(nextZWNJ)
    range.setStartAfter(nextZWNJ)
    selection.removeAllRanges()
    selection.addRange(range)
    this.addEventsOntoElement(wrapper)

    if (isEmptySpan && prevZWNJ.previousElementSibling.innerHTML === '<br>') {
      prevZWNJ.previousElementSibling.remove()
    }

    setTimeout(() => {
      this.$refs.textField.focus()
      this.encodeTextString()
      this.existingTextElements = []
    }, 0)
  }

  /** Method copies dynamic element creating new one based on the existing one.
   * @param {TextElement} element - source dynamic element
   */
  copyDynamicElement(element: TextElement) {
    const elementIDNumber = this.getNextFreeId()
    const nameId = this.getNextFreeNameIdForType(element.type)
    const title = `${this.elementName(element.type)} ${nameId}`
    const newElement = { ...element, ...{ title, elementIDNumber } }
    this.textElementForAdd = newElement
    this.existingTextElements = [...this.activeLabelSet.settings.textElements, newElement]
    this.addNewTextElement(newElement)
    this.onDynamicElementAdded({ name: title, id: elementIDNumber })
    this.$nextTick(() => this.openSettings(elementIDNumber))
    this.dialog = !this.dialog
  }

  /** Method wraps line of text into a div HTML tag.
   * @param {string} line - text string
   * @param {string} [className] = The CSS class or space-separated classes to be added to the element
   */
  wrapLineIntoDiv(line: string, className: string = '') {
    return `<div class="${className}">${line}</div>`
  }

  /** Method builds HTML element of a dynamic element. It adds empty tags around, HTML chip element with needed data
   * properties and content of an element.
   * @param {string} name - name of an element
   * @param {string} id - id of a dynamic element
   * @param {string} index - index of a dynamic element between similar dynamic elements
   */
  buildDynamicElement(name: string, id: string, index: string) {
    return (
      `<span class="empty">&#x200A;&zwnj;</span>` +
      `<div class="item element-chip element-chip-${id}" data-id="${id}" data-index="${index}" draggable="true">` +
      `<div class="d-flex flex-row chip-row">` +
      `<div class="d-flex flex-column justify-center name-element" contenteditable="false">${name}</div>` +
      `<div class="d-flex flex-column justify-center full-height chip-icon ${
        this.isLabelReadOnly ? 'disabled' : ''
      }" contenteditable="false">` +
      `<i></i>` +
      `</div>` +
      `</div>` +
      `</div>` +
      `<span class="empty">&#x200A;&zwnj;</span>`
    )
  }

  /** Method builds HTML element of a dynamic element. It adds only content of an element.
   * @param {string} name - name of an element
   */
  buildDynamicElementWithOutWrapper(name: string) {
    return (
      `<div class="d-flex flex-row chip-row">` +
      `<div class="d-flex flex-column justify-center name-element" contenteditable="false">${name}</div>` +
      `<div class="d-flex flex-column justify-center full-height chip-icon ${
        this.isLabelReadOnly ? 'disabled' : ''
      }" contenteditable="false">` +
      `<i></i>` +
      `</div>` +
      `</div>`
    )
  }

  /** Method defines the component that should be used for a particular dynamic element.
   * @param {string | number} id - id of a dynamic element
   */
  variantElementTooltip(id: string | number) {
    const textElement = this.variantDynamicElements.find(
      (s: TextElement) => s.elementIDNumber.toString() === id.toString(),
    )
    switch (textElement.type) {
      case MarkingContentElementType.User_Entry:
        return UserEntryTooltip
      case MarkingContentElementType.Grid_Letter:
        return GridLetterTooltip
      case MarkingContentElementType.Sequential_Integer:
        return CounterTooltip
      case MarkingContentElementType.Build_Attribute:
        return PrintOrderTooltip
    }
  }
}
