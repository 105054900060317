
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import Button from '@/components/controls/Common/Button.vue'

const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component({
  components: { Button },
})
export default class NewSimulationTimestampModal extends Vue {
  @visualizationStore.Getter('getIsShownTimestampModal') getIsShownTimestampModal: boolean
  @visualizationStore.Getter('getSimulationTimestamps') getSimulationTimestamps: {
    stamps: number[]
    current: number
  }

  @visualizationStore.Mutation('setIsShownNewTimestampModal') setIsShownNewTimestampModal: Function
  @visualizationStore.Mutation('changeSimulationTimestamp') changeSimulationTimestamp: Function

  onOk() {
    this.changeSimulationTimestamp(this.getSimulationTimestamps.stamps.length - 1)
    this.setIsShownNewTimestampModal(false)
  }

  onClose() {
    this.setIsShownNewTimestampModal(false)
  }
}
