
import Button from '@/components/controls/Common/Button.vue'
import LabelToolTextField from '@/components/controls/LabelToolControls/LabelToolTextField.vue'
import StoresNamespaces from '@/store/namespaces'
import { VersionableModel } from '@/types/Common/VersionableModel'
import { BuildPlanPrintStrategyDto, PrintStrategyDefaultsDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { PrintStrategyMode } from '@/types/Sites/ParameterEditor'
import {
  EditableParameter,
  MachineConfigMaterialBinder,
  ParameterSet,
  PrintStrategyInfo,
  PrintStrategyParameterSetDto,
  PrintStrategyParameterType,
} from '@/types/Sites/Site'
import { convert } from '@/utils/converter/lengthConverter'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import ParameterEditor from '../../admin/printStrategy/ParameterEditor.vue'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component({
  components: {
    LabelToolTextField,
    ParameterEditor,
    Button,
  },
})
export default class CustomPrintStrategyModal extends Vue {
  @sitesStore.Getter getCopiedParameterSetId: number

  @sitesStore.Action deletePrintStrategyParameterSet: (params: {
    printStrategyId: number
    siteId: number
    parameterSetId: string
  }) => Promise<PrintStrategyInfo>
  @sitesStore.Action copyParameterSet: (payload: { parameterSetId: number; implicit: boolean }) => Promise<ParameterSet>
  @sitesStore.Action updatePrintStrategyDefaults: (params: {
    printStrategyId: number
    siteId: number
    defaults: object
  }) => Promise<PrintStrategyInfo>
  @sitesStore.Action addPrintStrategyParameterSet: (params: {
    printStrategyId: number
    siteId: number
    parameterSetId: number
    layerThickness?: number
  }) => Promise<PrintStrategyInfo>
  @sitesStore.Action fetchPrintStrategyAvailableMachineConfigMaterialBinders: (
    printStrategyId: number,
  ) => Promise<MachineConfigMaterialBinder[]>
  @sitesStore.Action setBinder: (payload: { printStrategyId; machineConfigMaterialBinderId }) => Promise<void>

  @Prop({ required: true }) value: boolean
  @Prop({ required: true }) printStrategy: BuildPlanPrintStrategyDto
  @Prop({ required: true }) readonly: boolean

  editableParameter: EditableParameter = null
  availableBinders: MachineConfigMaterialBinder[] = []

  convert = convert

  $refs!: {
    editor: ParameterEditor
  }

  async mounted() {
    await this.fetchAvailableBinders(this.printStrategy.printStrategyId)
  }

  @Watch('printStrategy')
  async updateAvailableBinders(newValue) {
    await this.fetchAvailableBinders(newValue.printStrategyId)
  }

  get printStrategyMode(): PrintStrategyMode {
    return PrintStrategyMode.Custom
  }

  get printStrategyMachineConfigMaterialBinderId() {
    return this.printStrategy && this.printStrategy.machineConfigMaterialBinderId
  }

  get binders() {
    return this.availableBinders
      .map((b) => {
        return { text: b.binder && b.binder.name, value: b.id }
      })
      .filter((b) => b.text && b.text.length)
  }

  get selectedBinder() {
    return this.binders.find((b) => this.printStrategyMachineConfigMaterialBinderId === b.value)
  }

  get defaults() {
    const defaults = (this.printStrategy && this.printStrategy.defaults) || ({} as PrintStrategyDefaultsDto)

    return [
      {
        function: 'Production',
        partParameter: defaults.productionId,
        fieldName: 'productionId',
      },
      {
        function: 'Coupon',
        partParameter: defaults.couponId,
        fieldName: 'couponId',
      },
      {
        function: 'Support',
        partParameter: defaults.supportImportedVolumeId,
        fieldName: 'supportImportedVolumeId',
      },
    ]
  }

  get processParameterName() {
    return (
      (this.printStrategy && this.printStrategy.productionSet && this.printStrategy.productionSet.productionSetName) ||
      ''
    )
  }

  get getSelectedPrintStrategyParameterSets() {
    return (
      this.printStrategy &&
      this.printStrategy.printStrategyParameterSets &&
      VersionableModel.getLatestVersions(this.printStrategy.printStrategyParameterSets)
    )
  }

  get isProcessParameterLoadedInEditor(): boolean {
    const processParamId = this.printStrategy && this.printStrategy.productionSet && this.printStrategy.productionSet.id
    if (processParamId === null) {
      return false
    }
    return this.isParameterLoadedInEditor(processParamId, PrintStrategyParameterType.Process)
  }

  close() {
    if (this.isParameterEditorHasUnsavedChanges()) {
      return
    }

    this.onParameterEditorClose()
    this.$emit('close')
  }

  async updateBinder(machineConfigMaterialBinderId: number) {
    if (this.readonly) {
      return
    }

    this.printStrategy.machineConfigMaterialBinderId = machineConfigMaterialBinderId
    await this.setBinder({
      machineConfigMaterialBinderId,
      printStrategyId: this.printStrategy.printStrategyId,
    })
    this.$emit('fetchBuildPlanInfo')
  }

  onViewProcessParameterClick() {
    if (this.editableParameter) {
      if (this.isParameterEditorHasUnsavedChanges()) {
        return
      }

      const { id, type } = this.editableParameter

      const isSameParameterChecked =
        id === this.printStrategy.productionSet.id && type === PrintStrategyParameterType.Process
      if (isSameParameterChecked) {
        this.editableParameter = null
        return
      }
    }

    this.editableParameter = {
      id: this.printStrategy.productionSet.id,
      type: PrintStrategyParameterType.Process,
      printStrategyId: this.printStrategy.printStrategyId,
    }
  }

  onViewPartParameterClick(item: PrintStrategyParameterSetDto) {
    if (this.editableParameter) {
      if (this.isParameterEditorHasUnsavedChanges()) {
        return
      }

      const { id, type } = this.editableParameter

      const isSameParameterChecked = id === item.parameterSetId && type === PrintStrategyParameterType.Part
      if (isSameParameterChecked) {
        this.editableParameter = null
        return
      }
    }

    this.editableParameter = {
      id: item.parameterSetId,
      type: PrintStrategyParameterType.Part,
      printStrategyId: this.printStrategy.printStrategyId,
    }
  }

  isParameterEditorHasUnsavedChanges(): boolean {
    if (this.$refs.editor.hasUnsavedChanges()) {
      this.$refs.editor.toggleEditingParameterModal(true)
      return true
    }
    return false
  }

  isPartParameterCopyInProgress(item) {
    return item.parameterSetId === this.getCopiedParameterSetId
  }

  async copyPartParameter(item: PrintStrategyParameterSetDto) {
    if (this.readonly) {
      return
    }

    const copiedPartParam = await this.copyParameterSet({ parameterSetId: item.parameterSetId, implicit: true })

    if (!copiedPartParam) return

    const partParameterId = copiedPartParam.id

    if (this.getSelectedPrintStrategyParameterSets.find((ps) => ps.id === partParameterId)) return

    await this.addPrintStrategyParameterSet({
      parameterSetId: partParameterId,
      printStrategyId: this.printStrategy.printStrategyId,
      siteId: null,
    })
    this.$emit('fetchBuildPlanInfo')
  }

  isPartParameterLoadedInEditor(item: PrintStrategyParameterSetDto): boolean {
    return this.isParameterLoadedInEditor(item.parameterSetId, PrintStrategyParameterType.Part)
  }

  isParameterLoadedInEditor(parameterId: number, parameterType: PrintStrategyParameterType): boolean {
    if (!this.editableParameter) {
      return false
    }

    return this.editableParameter.id === parameterId && this.editableParameter.type === parameterType
  }

  async deletePartParameter(item) {
    const isParameterActive = this.isParameterLoadedInEditor(item.parameterSetId, PrintStrategyParameterType.Part)
    if ((isParameterActive && this.isParameterEditorHasUnsavedChanges()) || this.isDeletePartParameterDisabled(item)) {
      return
    }
    const printStrategy = await this.deletePrintStrategyParameterSet({
      parameterSetId: item.id,
      printStrategyId: this.printStrategy.printStrategyId,
      siteId: null,
    })

    if (printStrategy && isParameterActive) {
      this.editableParameter = null
    }

    this.$emit('fetchBuildPlanInfo')
  }

  async updateDefault(e, fieldName) {
    if (this.readonly) {
      return
    }

    const defaults = {}
    defaults[fieldName] = e
    await this.updatePrintStrategyDefaults({
      defaults,
      printStrategyId: this.printStrategy.printStrategyId,
      siteId: null,
    })

    this.$emit('onChangeDefaultParameterSets')
  }

  dropDownParameterSetName(item) {
    return `${item.name} - ${parseFloat(convert('m', 'mm', item.layerThickness).toPrecision(3))}mm`
  }

  onParameterRenamed(payload: EditableParameter) {
    if (this.readonly) {
      return
    }

    if (this.editableParameter.type === PrintStrategyParameterType.Process) {
      this.printStrategy.productionSet.productionSetName = payload.name
    } else {
      const parameter = this.getSelectedPrintStrategyParameterSets.find((item) => item.parameterSetId === payload.id)
      if (parameter) {
        parameter.name = payload.name
      }
    }
  }

  onParameterEditorClose() {
    this.editableParameter = null
  }

  onParameterEditorSave() {
    this.$emit('onUpdateParameterSet', this.editableParameter)
  }

  async fetchAvailableBinders(id: number) {
    this.availableBinders = []
    this.availableBinders = await this.fetchPrintStrategyAvailableMachineConfigMaterialBinders(id)
  }

  get partParametersTableHeaders() {
    return [
      {
        text: this.$t('tenantAdminMessages.printStrategy.sections.partParameters'),
        value: 'name',
        sortable: false,
        width: '375px',
      },
      { text: '', value: 'actions', sortable: false, align: 'left' },
    ]
  }

  get defaultsTableHeaders() {
    return [
      {
        text: this.$t('tenantAdminMessages.printStrategy.headers.function'),
        value: 'function',
        sortable: false,
        width: 'auto',
      },
      {
        text: this.$t('tenantAdminMessages.printStrategy.headers.partParameter'),
        value: 'partParameter',
        sortable: false,
        align: 'left',
      },
    ]
  }

  isDeletePartParameterDisabled(item) {
    return this.readonly || item.isUsed
  }

  // todo not implemented zone
  /*get getListOfBinders() {
    return [{ name: 'binder 1', id: 1 }, { name: 'binder 2', id: 2 }]
  }

  updateBinder(value) {
    if (this.readonly) {
      return
    }

    alert('not implemented')
  }

  get binderName() {
    // todo get binder id from strategy
    const printStrategyBinderId = 1
    const binder = this.getListOfBinders.find(x => x.id === printStrategyBinderId)
    return binder ? binder.name : '-'
  }*/
}
