
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import VariantIsLockedModal from '@/components/layout/FileExplorer/VariantIsLockedModal.vue'
import VariantItem from '@/components/layout/buildPlans/variants/VariantItem.vue'
import ActivateVariantModal from '@/components/layout/buildPlans/variants/ActivateVariantModal.vue'
import RenameVariantModal from '@/components/layout/buildPlans/variants/RenameVariantModal.vue'
import EditVariantDescriptionModal from '@/components/layout/buildPlans/variants/EditVariantDescriptionModal.vue'
import DeleteVariantModal from '@/components/layout/buildPlans/variants/DeleteVariantModal.vue'
import MoveToTrashItemErrorModal from '@/components/layout/FileExplorer/MoveToTrashItemErrorModal.vue'
import { IBuildPlan, IVariantItem } from '@/types/BuildPlans/IBuildPlan'
import ModalsStateMixin from '../FileExplorer/Table/mixins/ModalsStateMixin'
import StoresNamespaces from '@/store/namespaces'
import { RouterNames } from '@/router'
import messageService from '@/services/messageService'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { CanBeTrashedResult } from '@/types/FileExplorer/CanBeTrashed'
import { IJob } from '@/types/PartsLibrary/Job'
import fileExplorerApi from '@/api/fileExplorer'
import i18n from '@/plugins/i18n'
import { ItemPermissionsRole, Permission } from '@/types/FileExplorer/Permission'
import Menu from '@/components/controls/Common/Menu.vue'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { Prop, Watch, Mixins } from 'vue-property-decorator'
import { INotification } from '@/types/Notification/INotification'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'
import {
  ActiveToolUnsavedChangesMixin,
  ExitToolAction,
} from '@/components/layout/buildPlans/mixins/ActiveToolUnsavedChangesMixin'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const notificationsStore = namespace(StoresNamespaces.Notifications)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const labelStore = namespace(StoresNamespaces.Labels)

// If you are going to change html elements or style in common variants container (ref="variants")
// (dividers or variants) - please make sure that all children in container are styled accordingly!!!
// VARIANT_CLASS_NAME for variant item
// DIVIDER_CLASS_NAME for divider
// This is necessary for item navigation!
const VARIANT_CLASS_NAME = 'variant-element'
const DIVIDER_CLASS_NAME = 'divider'

@Component({
  components: {
    VariantIsLockedModal,
    VariantItem,
    ActivateVariantModal,
    RenameVariantModal,
    EditVariantDescriptionModal,
    DeleteVariantModal,
    MoveToTrashItemErrorModal,
    Menu,
  },
})
export default class BuildPlanVariantsBar extends Mixins(ModalsStateMixin, ActiveToolUnsavedChangesMixin) {
  @buildPlansStore.Action createBuildPlanVariant: (buildPlanId: string) => Promise<IBuildPlan>
  @buildPlansStore.Action createIbcPlanVariant: (ibcPlanId: string) => Promise<IIBCPlan>
  @buildPlansStore.Action setVariantLabel: (buildPlan: IVariantItem) => Promise<boolean>
  @buildPlansStore.Action setVariantDescription: (bp: IVariantItem) => Promise<void>
  @buildPlansStore.Action setVariantOrder: (payload: { buildPlanId: string; order: number }) => Promise<void>
  @buildPlansStore.Action deleteVariant: (buildPlans: IVariantItem) => Promise<void>
  @buildPlansStore.Action fetchVariantJobsByBuildPlanId: (id: string) => Promise<void>
  @buildPlansStore.Action getBuildPlanById: (id: string) => Promise<IBuildPlan>
  @buildPlansStore.Action refreshBoundingBoxForPartsOnly: () => void
  @buildPlansStore.Action fetchIbcPlanJobs: (payload: {
    ibcPlanId: string
    fetchForRelatedVariants?: number
  }) => Promise<void>

  @buildPlansStore.Getter('getBuildPlanVariants') allVariants: IVariantItem[]
  @buildPlansStore.Getter isNotAbleToCreateBuildPlanVariant: boolean
  @buildPlansStore.Getter isVariantProcessing: boolean
  @buildPlansStore.Getter isBuildPlanDisposing: boolean
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes
  @buildPlansStore.Getter getSelectedBuildPlanPrintJobs: IJob[]
  @buildPlansStore.Getter getIsLoading: boolean
  @buildPlansStore.Getter getIBCPlan: IIBCPlan

  @fileExplorerStore.Getter permissionsByItemId: Record<string, Permission[]>

  @buildPlansStore.Mutation setIsVariantProcessing: (value: boolean) => void
  @buildPlansStore.Mutation setIsVariantCreating: (value: boolean) => void
  @buildPlansStore.Mutation setBuildPlanViewMode: (value) => void

  @notificationsStore.Action('fetchAllNotifications') fetchAllNotifications: () => Promise<INotification[]>

  @notificationsStore.Getter getAllNotifications: INotification[]

  @notificationsStore.Mutation('setItemVersionLabel') setNotificationItemVersionLabel: (payload: {
    itemId: string
    versionLabel: string
  }) => void

  @visualizationStore.Mutation deselect: () => void
  @visualizationStore.Mutation updateItemPreview: Function
  @visualizationStore.Mutation enableClearanceTool: Function
  @visualizationStore.Action generatePreviewCreationPromise: () => { promise: Promise<void>; done: Function }
  @visualizationStore.Getter getPreviewCreationPromise: { promise: Promise<void>; done: Function }
  @visualizationStore.Getter isClearanceToolEnabled: Function

  @labelStore.Action resetLabelSetsIDsForUpdate: (labelSetsIDs: string[]) => void

  @Prop({ default: true }) isOpenDetails: boolean

  $refs!: {
    variantsContainer: HTMLElement
    variants: HTMLElement
    variantItems: VariantItem[]
  }

  variantIdClass = VARIANT_CLASS_NAME
  dividerIdClass = DIVIDER_CLASS_NAME

  isShownRightSlider: boolean = true
  isShownActivateVariantDialog: boolean = false
  isShownRenameVariantDialog: boolean = false
  isShownEditDescriptionVariantDialog: boolean = false
  isShownDeleteVariantDialog: boolean = false
  isCreatingVariant: boolean = false
  isShownVariantContextMenu: boolean = false
  delayContextMenu: boolean = false
  delayedEvent: Event = null

  editingVariant: IVariantItem = null
  menuX: number = 0
  menuY: number = 0
  headIndex: number = 0
  isShownDropLocation = false
  variantItemsBoundingRects = []
  closestPointVariantOrder: number = 0
  rightSide: boolean = false
  itemTrashedProps: CanBeTrashedResult = null
  isViewerUser: boolean = false

  get variants() {
    let bpVariants: IVariantItem[]
    if (this.headIndex === 0) {
      bpVariants = this.allVariants
    } else {
      bpVariants = [...this.allVariants.slice(this.headIndex)]
    }

    // fix for compatibility with old build plans (without default variant labels)
    bpVariants.forEach((bp, index) => {
      if (!bp.versionLabel || bp.versionLabel === '') {
        bp.versionLabel = `Variant ${index + 1}`
      }
    })

    bpVariants.sort((bp1, bp2) => bp1.versionOrder - bp2.versionOrder)
    return bpVariants
  }

  get variantRelatedItems(): FileExplorerItem[] {
    return this.itemTrashedProps && this.itemTrashedProps.relatedItems ? this.itemTrashedProps.relatedItems : []
  }

  get isShownLeftSlider(): boolean {
    return this.headIndex > 0
  }

  get selectedVariantIndex() {
    return this.variants.findIndex((v) => v.version === this.currentPlan.version)
  }

  get selectedVariant() {
    return this.allVariants.find((v) => v.id === this.currentPlan.id)
  }

  get currentPlan(): IIBCPlan | IBuildPlan {
    if (this.isIbcPlanPage) {
      return this.getIBCPlan
    }
    if (this.isBuildPlanPage) {
      return this.getBuildPlan
    }
  }

  get isShownDeleteItemInMenu() {
    return this.variants.length > 1
  }

  get contextMenuItems() {
    return [
      {
        title: i18n.t('rename'),
        divide: false,
        condition: true,
        handler: this.toggleIsShownRenameVariantDialog,
        disabled: this.isViewerUser,
      },
      {
        title: i18n.t('description'),
        divide: this.isShownDeleteItemInMenu,
        condition: true,
        handler: this.toggleIsShownEditDescriptionDialog,
        disabled: this.isViewerUser,
      },
      {
        title: i18n.t('delete'),
        divide: false,
        condition: this.isShownDeleteItemInMenu,
        handler: this.openDeleteVariantDialog,
        disabled: this.isViewerUser,
      },
    ]
  }

  get isVariantCreatingAvailable() {
    return !this.isCreatingVariant && !this.getIsLoading
  }

  // use only for dividers
  isItemSelected(index: number) {
    return this.selectedVariantIndex === index
  }

  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResizeEvent)
    })

    const plan: IBuildPlan | IIBCPlan = this.getIBCPlan || this.getBuildPlan
    this.isViewerUser = await this.isViewer(plan.id)
    this.scrollToActiveVariant(this.selectedVariant)

    // Fetch jobs for all variants
    const spVariant = this.getBuildPlanVariants.find((variant) => variant.subType === ItemSubType.SinterPlan)
    const ibcVariant = this.getBuildPlanVariants.find((variant) => variant.itemType === ItemType.IbcPlan)
    if (spVariant) {
      await this.fetchVariantJobsByBuildPlanId(this.$route.params.id)
    }
    if (ibcVariant) {
      await this.fetchIbcPlanJobs({ ibcPlanId: ibcVariant.id })
    }
  }

  updated() {
    this.$nextTick(() => {
      this.isShownRightSlider = this.getIsVariansOverflow()
    })
  }

  async selectVariant(variant: IVariantItem) {
    const toolAction = await this.canExitActiveTool()
    if (this.isVariantProcessing || toolAction === ExitToolAction.DoNotExit) {
      return
    }

    try {
      this.closeSidebarTools()
      if (this.isClearanceToolEnabled) {
        this.enableClearanceTool({
          isEnabled: false,
          restoreSelectionManagerState: true,
        })
      }
      this.setIsVariantProcessing(true)
      // deselect selected items on canvas
      this.updatePreview()
      this.deselect()
      if (variant.itemType === ItemType.BuildPlan) {
        const buildPlan = await this.getBuildPlanById(variant.id)
        this.resetLabelSetsIDsForUpdate(buildPlan.labelSetIDsToUpdate ? buildPlan.labelSetIDsToUpdate : [])
        await this.checkVariantIsLockedAndEdit(variant.id, buildPlan)
      } else {
        await this.checkVariantIsLockedAndEdit(variant.id)
      }
    } finally {
      this.setIsVariantProcessing(false)
    }
  }

  async createVariant() {
    const action = await this.canExitActiveTool()
    if (this.isVariantProcessing || this.isNotAbleToCreateBuildPlanVariant || action === ExitToolAction.DoNotExit) {
      return
    }

    try {
      this.closeSidebarTools()
      if (this.isClearanceToolEnabled) {
        this.enableClearanceTool({
          isEnabled: false,
          restoreSelectionManagerState: true,
        })
      }
      // deselect selected items so that they are not present in the store if new variant is created
      this.deselect()
      this.generatePreviewCreationPromise()
      this.updatePreview()
      this.isCreatingVariant = true
      this.setIsVariantCreating(true)

      if (this.getPreviewCreationPromise) {
        await this.getPreviewCreationPromise.promise
      }

      let variant

      if (this.isIbcPlanPage) {
        const ibcPlanId = this.$route.params.ibcPlanId
        variant = await this.createIbcPlanVariant(ibcPlanId)
      }
      if (this.isBuildPlanPage) {
        const buildPlanId = this.$route.params.id
        variant = await this.createBuildPlanVariant(buildPlanId)
      }

      if (variant) {
        const promises = [this.checkVariantIsLockedAndEdit(variant.id, variant), this.closeSidebarTools()]

        if (!this.isReadOnly) {
          promises.push(this.unlockBuildPlanVariant())
        }

        await Promise.all(promises)
        await this.lockBuildPlanVariant(variant.id)
        if (variant.itemType === ItemType.BuildPlan) {
          this.resetLabelSetsIDsForUpdate(variant.labelSetIDsToUpdate ? variant.labelSetIDsToUpdate : [])
        }
      }
    } catch (error) {
      const msg = i18n.t('createVariantFailed') as string
      messageService.showErrorMessage(`${msg} ${error.message}`)
    } finally {
      this.setIsVariantCreating(false)
      this.isCreatingVariant = false
      this.$emit('variant-created')
    }
  }

  async isViewer(buildPlanId: string): Promise<boolean> {
    await this.getItemPermissions(buildPlanId)

    if (!this.permissionsByItemId[buildPlanId] || !this.getUserDetails) {
      return false
    }

    const userPermission = this.permissionsByItemId[buildPlanId].find(
      (permission) => permission.grantedTo === this.getUserDetails.id,
    )

    return userPermission ? userPermission.role === ItemPermissionsRole.Viewer : false
  }

  isPrintLocked(variant: IVariantItem): boolean {
    return this.getSelectedBuildPlanPrintJobs.some((job) => job.itemId === variant.id)
  }

  @Watch('getSelectedBuildPlanPrintJobs')
  async onPrintJobsChange() {
    if (this.delayContextMenu) {
      await this.showContextMenu(this.selectedVariant, this.delayedEvent)
      this.delayContextMenu = false
      this.delayedEvent = null
    }
  }

  @Watch('isCreatingVariant')
  async selectedBuildPlanChanged(cur, prev) {
    if (!cur && prev) {
      await this.$nextTick()
      this.scrollToActiveVariant(this.selectedVariant)
    }
  }

  async showContextMenu(variant: IVariantItem, event) {
    event.preventDefault()

    const isSelected = variant.version === this.currentPlan.version

    if (isSelected && this.isBuildPlanPage && this.isPrintLocked(variant)) {
      this.isShownVariantContextMenu = false
      return
    }

    if (!isSelected) {
      this.delayContextMenu = true
      this.delayedEvent = event
      await this.selectVariant(variant)
      return
    }

    this.isShownVariantContextMenu = false
    this.editingVariant = variant
    this.menuX = event.clientX
    this.menuY = event.clientY
    this.isShownVariantContextMenu = true
  }

  async closeSidebarTools() {
    this.setBuildPlanViewMode(null)
    if (this.isBuildPlanPage) {
      // @ts-ignore
      this.$router.safePush({ name: RouterNames.EditBuildPlan })
    }
    if (this.isIbcPlanPage) {
      // @ts-ignore
      this.$router.safePush({ name: RouterNames.FE_EditIbcPlan })
    }
  }

  async renameVariant(newLabel: string) {
    if (this.isVariantProcessing) {
      return
    }

    try {
      this.setIsVariantProcessing(true)
      const newVersionLabel = newLabel.trim()
      const result = await this.setVariantLabel({ ...this.editingVariant, versionLabel: newVersionLabel })

      if (result) {
        this.setNotificationItemVersionLabel({ itemId: this.editingVariant.id, versionLabel: newVersionLabel })
      }
    } catch (error) {
      const msg = i18n.t('renameVariantFailed') as string
      messageService.showErrorMessage(`${msg} ${error.message}`)
    } finally {
      this.setIsVariantProcessing(false)
    }
  }

  async editDescription(description: string) {
    if (this.isVariantProcessing) {
      return
    }

    try {
      this.setIsVariantProcessing(true)
      this.setVariantDescription({ ...this.editingVariant, versionDescription: description.trim() })
    } catch (error) {
      const msg = i18n.t('setDescriptionVariantFailed') as string
      messageService.showErrorMessage(`${msg} ${error.message}`)
    } finally {
      this.setIsVariantProcessing(false)
    }
  }

  async openDeleteVariantDialog() {
    if (this.isVariantProcessing) {
      return
    }

    try {
      this.setIsVariantProcessing(true)
      this.itemTrashedProps = await fileExplorerApi.canDeleteVariants(this.editingVariant.id)
      this.setSelectedItemsCanBeTrashed(this.itemTrashedProps)

      if (this.isCanBeTrashedStatusSuccess) {
        this.toggleIsShownDeleteVariantDialog(true)
      } else {
        this.toggleMoveToTrashErrorModal(true)
      }
    } catch (error) {
      const msg = i18n.t('deleteVariantFailed') as string
      messageService.showErrorMessage(`${msg} ${error.message}`)
    } finally {
      this.setIsVariantProcessing(false)
    }
  }

  async deleteSelectedVariant(variant: IVariantItem) {
    if (this.isVariantProcessing || this.isPrintLocked(variant)) {
      return
    }
    try {
      // find and activate previous variant
      const deletedIndex = this.allVariants.findIndex((v) => v.id === variant.id)
      const index = deletedIndex > 0 ? deletedIndex - 1 : 1
      const variantToSelect = this.allVariants[index]
      await this.activateVariantAndSlide(variantToSelect)

      // delete variant
      this.setIsVariantProcessing(true)
      await this.deleteVariant(variant)
      await this.fetchAllNotifications()
    } catch (error) {
      this.setIsVariantProcessing(false)
      const msg = i18n.t('deleteVariantFailed') as string
      messageService.showErrorMessage(`${msg}: ${error.message}`)
      await this.activateVariantAndSlide(variant)
    } finally {
      this.setIsVariantProcessing(false)
    }
  }

  showActivateVariantDialog(event) {
    event.preventDefault()
    this.isShownActivateVariantDialog = true
  }

  closeActivateVariantDialog() {
    this.isShownActivateVariantDialog = false
  }

  slideTabToLeft() {
    if (this.headIndex >= 1) {
      // tslint:disable-next-line: no-increment-decrement
      this.headIndex--
    }
  }

  slideTabToRight() {
    if (this.headIndex < this.allVariants.length - 1) {
      // tslint:disable-next-line: no-increment-decrement
      this.headIndex++
    }
  }

  getIsVariansOverflow() {
    const containerWidth = this.$refs.variantsContainer.offsetWidth
    const variantsWidth = this.$refs.variants.offsetWidth
    return variantsWidth > containerWidth
  }

  async slideTabToLeftCollapse() {
    if (this.headIndex > 0) {
      this.slideTabToLeft()
    }
  }

  async slideTabToRightCollapse() {
    const container = this.$refs.variantsContainer
    const variants = this.$refs.variants

    if (variants.offsetWidth < container.offsetWidth) {
      return
    }

    let index = 0
    let nextVisibleItem: HTMLElement = variants.children[index] as HTMLElement
    let itemsWidth = 0
    let visibleVariantsCounter = 0

    while (itemsWidth + nextVisibleItem.offsetWidth < container.offsetWidth) {
      // if divider or varint
      // (not menu element, the menu is not displayed on the bar but is located as an item)
      if (
        nextVisibleItem.className.includes(VARIANT_CLASS_NAME) ||
        nextVisibleItem.className.includes(DIVIDER_CLASS_NAME)
      ) {
        if (nextVisibleItem.className.includes(VARIANT_CLASS_NAME)) {
          // tslint:disable-next-line: no-increment-decrement
          visibleVariantsCounter++
        }
        itemsWidth += nextVisibleItem.offsetWidth
      }

      // tslint:disable-next-line: no-increment-decrement
      index++
      nextVisibleItem = variants.children[index] as HTMLElement
    }

    // calculating the number of elements to shift
    // if the selected element is partially displayed
    const firstVisibleItem = variants.children[1] as HTMLElement // first variant, 0 - is divider
    const newVariantsWidth = itemsWidth - firstVisibleItem.offsetWidth + nextVisibleItem.offsetWidth
    const countTabToSlide = newVariantsWidth <= container.offsetWidth ? 1 : 2

    this.headIndex += countTabToSlide
  }

  async activateVariantAndSlide(newSelectedVariant: IVariantItem) {
    // if selected variant is equal to the current variant
    if (this.getBuildPlan.id === newSelectedVariant.id) {
      return
    }

    this.scrollToActiveVariant(newSelectedVariant)
    await this.selectVariant(newSelectedVariant)
  }

  scrollToActiveVariant(newSelectedVariant: IVariantItem) {
    // if the new item is in the left (invisible) area (new variant index is less than head index)
    const newSelectedVariantIndex = this.allVariants.findIndex((v) => v.version === newSelectedVariant.version)

    if (newSelectedVariantIndex < this.headIndex) {
      const countToSlide = this.headIndex - newSelectedVariantIndex
      this.headIndex -= countToSlide
      return
    }

    // if the new item is in the visible area
    // (the new variant index >= head index, but <= the index of the last visible element)
    const containerWidth = this.$refs.variantsContainer.offsetWidth
    const variants = this.$refs.variants

    let index = 0
    let nextItem: HTMLElement = variants.children[0] as HTMLElement
    let itemsWidth = 0
    let visibleVariantsCounter = 0

    while (nextItem && itemsWidth + nextItem.offsetWidth < containerWidth) {
      // if divider or variant
      // (not menu element, the menu is not displayed on the bar but is located as an item)
      if (nextItem.className.includes(VARIANT_CLASS_NAME) || nextItem.className.includes(DIVIDER_CLASS_NAME)) {
        itemsWidth += nextItem.offsetWidth

        if (nextItem.className.includes(VARIANT_CLASS_NAME)) {
          // tslint:disable-next-line: no-increment-decrement
          visibleVariantsCounter++
        }
      }
      // tslint:disable-next-line: no-increment-decrement
      index++
      const item = variants.children[index] as HTMLElement
      // if divider or variant, not menu
      nextItem =
        item.className.includes(VARIANT_CLASS_NAME) || item.className.includes(DIVIDER_CLASS_NAME) ? item : null
    }

    const maxFullVisibleIndex = this.headIndex + visibleVariantsCounter - 1

    if (newSelectedVariantIndex >= this.headIndex && newSelectedVariantIndex <= maxFullVisibleIndex) {
      return
    }

    // if after visible area
    // DOM index calculation (each item has one divider + 1 first divider)
    let newSelectedVariantDOMIndex
    if (this.variants && this.allVariants && this.variants.length < this.allVariants.length) {
      const diff = this.allVariants.length - this.variants.length
      newSelectedVariantDOMIndex = (newSelectedVariantIndex - diff) * 2 + 1
    } else {
      newSelectedVariantDOMIndex = newSelectedVariantIndex * 2 + 1
    }
    itemsWidth = 0
    let prevItem: HTMLElement = variants.children[newSelectedVariantDOMIndex] as HTMLElement
    index = newSelectedVariantDOMIndex
    visibleVariantsCounter = -1
    while (itemsWidth + prevItem.offsetWidth < containerWidth) {
      // only divider or variant
      // (not menu element, the menu is not displayed on the bar but is located as an item)
      if (prevItem.className.includes(VARIANT_CLASS_NAME) || prevItem.className.includes(DIVIDER_CLASS_NAME)) {
        itemsWidth += prevItem.offsetWidth

        if (prevItem.className.includes(VARIANT_CLASS_NAME)) {
          // tslint:disable-next-line: no-increment-decrement
          visibleVariantsCounter++
        }
      }

      // tslint:disable-next-line: no-increment-decrement
      index--
      prevItem = variants.children[index] as HTMLElement
    }
    const newHeadIndex = newSelectedVariantIndex - visibleVariantsCounter
    this.headIndex = newHeadIndex
  }

  toggleIsShownRenameVariantDialog(value: boolean) {
    this.isShownRenameVariantDialog = value
  }

  toggleIsShownEditDescriptionDialog(value: boolean) {
    this.isShownEditDescriptionVariantDialog = value
  }

  toggleIsShownDeleteVariantDialog(value: boolean) {
    this.isShownDeleteVariantDialog = value
  }

  onDragStart() {
    this.isShownVariantContextMenu = false
    this.isShownDropLocation = true
    this.variantItemsBoundingRects = this.getBoundingRects()
  }

  onDrag(event: { x: number }) {
    const mousePositionX = event.x
    if (!mousePositionX) {
      return
    }
    let closestPoint = 1000000
    let closestPointVariantOrder
    let rightSide

    this.variantItemsBoundingRects.forEach(({ boundingRect, order }) => {
      const leftPoint = Math.abs(boundingRect.left - mousePositionX)
      if (leftPoint < closestPoint) {
        closestPoint = leftPoint
        closestPointVariantOrder = order
        rightSide = false
      }
      const rightPoint = Math.abs(boundingRect.right - mousePositionX)
      if (rightPoint < closestPoint) {
        closestPoint = rightPoint
        closestPointVariantOrder = order
        rightSide = true
      }
    })
    this.closestPointVariantOrder = closestPointVariantOrder
    this.rightSide = rightSide
  }

  onDragEnd(variant: IBuildPlan) {
    this.isShownDropLocation = false
    this.changeVariantPosition(variant)
    this.closestPointVariantOrder = null
  }

  getBoundingRects() {
    const variantItems = this.$refs.variantItems
    const variantItemsBoundingRects = []
    variantItems.forEach((item) => {
      variantItemsBoundingRects.push({
        boundingRect: item.$el.getBoundingClientRect(),
        order: item.variant.versionOrder,
      })
    })

    return variantItemsBoundingRects
  }

  changeVariantPosition(variant: IBuildPlan) {
    const variantIndex = this.variants.findIndex((item) => item.id === variant.id)
    let dropPosition = this.rightSide ? this.closestPointVariantOrder : this.closestPointVariantOrder

    if (dropPosition === this.variants.length) {
      dropPosition -= 1
    }

    if (variantIndex === dropPosition) {
      return
    }

    this.setVariantOrder({ buildPlanId: variant.id, order: dropPosition })
  }

  /**
   * Updates all variants info about jobs
   * in case user switches variants while job is running
   */
  @Watch('getAllNotifications')
  onNewSliceJobNotification() {
    const lastNotification = this.getAllNotifications.length ? this.getAllNotifications[0] : null
    if (!lastNotification || !lastNotification.jobType) {
      return
    }

    const buildPlanId = this.$route.params.id
    this.fetchVariantJobsByBuildPlanId(buildPlanId)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResizeEvent)
  }

  updatePreview() {
    if (this.currentPlan) {
      this.updateItemPreview({ itemId: this.currentPlan.id })
    }
  }

  private onResizeEvent() {
    this.isShownRightSlider = this.getIsVariansOverflow()
  }
}
