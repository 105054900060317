
/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import SearchField from '@/components/controls/Common/SearchField.vue'
import TextField from '@/components/controls/Common/TextField.vue'
import { PRINT_SITE_DOWNLOAD_DEFAULT_NAME, PRINT_SITE_DOWNLOAD_DISPLAY_NAME } from '@/constants'
import StoresNamespaces from '@/store/namespaces'
import { IJob } from '@/types/PartsLibrary/Job'
import { PrintOrderPreviewPatch } from '@/types/PrintOrder/PrintOrderPatch'
import PrintOrderPatchTooltip from './PrintOrderPatchTooltip.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { RouterNames } from '@/router'
import { IBuildPlan, ISelectable } from '@/types/BuildPlans/IBuildPlan'
import ToolMask from '@/components/modals/ToolMask.vue'
import { LabelSetDto } from '@/types/Label/LabelSetDto'

const jobsStore = namespace(StoresNamespaces.Jobs)
const labelsStore = namespace(StoresNamespaces.Labels)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const commonStore = namespace(StoresNamespaces.Common)

@Component({
  name: 'PrintOrderTab',
  components: {
    TextField,
    SearchField,
    PrintOrderPatchTooltip,
    ToolMask,
  },
})
export default class PrintOrderPreviewLabelDetails extends Vue {
  @jobsStore.Action fetchJob: (id: string) => IJob

  @labelsStore.Action highlightLabels: Function
  @labelsStore.Action deHighlightLabels: Function
  @labelsStore.Action getLabelSetsByBuildPlanId: (payload: {
    buildPlanId: string
    dirtyStateAddIfNew?: boolean
  }) => Promise<LabelSetDto[]>

  @labelsStore.Mutation setPrintOrderPreviewPatch: Function

  @buildPlansStore.Mutation setPrintOrderLabelSearch: (input: string) => void

  @visualizationStore.Mutation toggleHighlight: Function
  @visualizationStore.Mutation deselect: () => void
  @visualizationStore.Mutation selectAndHighlightPart: (payload: {
    buildPlanItemId: string
    deselectIfSelected: boolean
    showGizmo: boolean
  }) => void

  @buildPlansStore.Getter printOrderLabelSearchInput: string
  @buildPlansStore.Getter printOrderNavigatedFrom: string
  @buildPlansStore.Getter getSelectedParts: ISelectable[]
  @buildPlansStore.Getter isToolMaskDisplaying: boolean
  @buildPlansStore.Getter getBuildPlan: IBuildPlan

  @commonStore.Getter tooltipOpenDelay: number

  @Prop() job: IJob

  // True, if user selects part on buildPlan
  // False, if system selects part on buildPlan
  systemSelection: boolean = false
  markJob: IJob = null
  patches: PrintOrderPreviewPatch[] = []
  selectedPatchesMap: Map<string, PrintOrderPreviewPatch> = new Map()
  lastSelectedPatch: PrintOrderPreviewPatch = null
  lastCtrlPressed = false

  $refs!: {
    searchField: SearchField
  }

  get printSiteName() {
    if (this.job) {
      if (this.job.computeType.trim().toLowerCase() === PRINT_SITE_DOWNLOAD_DEFAULT_NAME) {
        return PRINT_SITE_DOWNLOAD_DISPLAY_NAME
      }
      return this.job.computeType
    }
    return ''
  }

  get filteredPatches(): PrintOrderPreviewPatch[] {
    if (this.printOrderLabelSearchInput) {
      const filteredPatches = this.filterPatchesByUserInput(this.printOrderLabelSearchInput)
      // remove elements in selectedPatches Map which are not in search result
      for (const [key] of this.selectedPatchesMap) {
        const keyIndex = filteredPatches.findIndex((patch) => patch.id === key)
        if (keyIndex === -1) {
          this.selectedPatchesMap.delete(key)
        }
      }
      this.setPrintOrderPreviewPatch(filteredPatches)

      this.systemSelection = true
      this.deselect()
      const selectedBuildPlanItemsId: Set<string> = new Set()
      this.selectedPatchesMap.forEach((item) => {
        const buildPlanItemId = item.buildPlanItemId
        if (!selectedBuildPlanItemsId.has(buildPlanItemId)) {
          selectedBuildPlanItemsId.add(buildPlanItemId)
          this.selectAndHighlightPart({
            buildPlanItemId,
            deselectIfSelected: false,
            showGizmo: false,
          })
        }
      })

      return filteredPatches
    }
    this.setPrintOrderPreviewPatch(this.patches)
    return this.patches
  }

  get isLabelsContainerDisplayed(): boolean {
    return this.patches.length > 0
  }

  @Watch('getSelectedParts')
  onSelectedPartsChange() {
    if (this.systemSelection) {
      this.systemSelection = false
      return
    }

    if (this.selectedPatchesMap.size > 0) {
      this.selectedPatchesMap.forEach((patch) => {
        patch.selected = false
        this.hoverOutOfPatchRow(patch)
      })
      this.selectedPatchesMap.clear()
      this.$forceUpdate()
    }
  }
  /**
   * returns label patches that contain user input. If input has multiple keywords
   * behaviour is similar to multi string search in Amp manager Search
   * (see GEAMPREQ-953)
   */
  filterPatchesByUserInput(userInput: string) {
    const searchQuery = userInput.toLocaleLowerCase().split(' ').filter(Boolean)

    return this.patches.filter((patch, index) => {
      // @ts-ignore
      const runTimeContentText: string = patch.runTimeContentText ? patch.runTimeContentText : patch.RunTimeContentText
      const searchMatch = searchQuery.every((keyword) => runTimeContentText.toLocaleLowerCase().includes(keyword))

      // remove highlight if label is not part of search result
      if (!searchMatch && patch.selected) {
        patch.selected = false
        this.hoverOutOfPatchRow(patch)
      }
      return searchMatch
    })
  }

  async mounted() {
    const printJobParameters = JSON.parse(this.job.parameters)
    if (printJobParameters.markJobId) {
      this.markJob = await this.fetchJob(printJobParameters.markJobId)
      await this.getLabelSetsByBuildPlanId({ buildPlanId: this.getBuildPlan.id })
    }

    // GEAMPREQ-953
    // If label search input is not empty and request same from search page
    // should add the search string to the Print Order search input panel
    if (this.printOrderNavigatedFrom !== RouterNames.FE_Search) {
      this.setPrintOrderLabelSearch('')
    }

    this.initializePatches()
  }

  beforeDestroy() {
    this.setPrintOrderPreviewPatch([])
    this.setPrintOrderLabelSearch('')
  }

  initializePatches() {
    if (this.markJob) {
      const markJobParams = JSON.parse(this.markJob.parameters)
      let patchList = []
      if (markJobParams.markingTemplate.Patches) {
        patchList = markJobParams.markingTemplate.Patches
      } else {
        patchList = markJobParams.markingTemplate.patches
      }

      this.patches = patchList.filter(
        (patch) =>
          (patch.runTimeContentText && patch.runTimeContentText.trim().length > 0) ||
          (patch.RunTimeContentText && patch.RunTimeContentText.trim().length > 0),
      )
      this.patches.forEach((patch, index) => {
        patch.index = index
        patch.selected = false
        patch.runTimeContentText = patch.runTimeContentText.replace(/{{/g, '{').replace(/}}/g, '}')
      })

      this.$forceUpdate()
    }
  }

  getPatchMultilineValue(patch) {
    if (patch.RunTimeContentText) {
      return patch.RunTimeContentText
    }
    return patch.runTimeContentText
  }

  onSearchInput(value: string) {
    this.setPrintOrderLabelSearch(value.trim())
  }

  hoverIntoPatchRow(patch: PrintOrderPreviewPatch) {
    if (patch.geometryId && patch.componentId) {
      const option = {
        parentId: patch.buildPlanItemId,
        componentId: patch.componentId,
        geometryId: patch.geometryId,
        patchId: patch.id,
        isPrintOrderPreviewLabel: true,
      }
      // this.highlightLabels([option])
    } else {
      this.toggleHighlight({ buildPlanItemId: patch.buildPlanItemId, highlight: true })
    }
  }

  hoverOutOfPatchRow(patch) {
    if (!patch.selected) {
      if (patch.geometryId && patch.componentId) {
        const option = {
          parentId: patch.buildPlanItemId,
          componentId: patch.componentId,
          geometryId: patch.geometryId,
          patchId: patch.id,
          isPrintOrderPreviewLabel: true,
        }
        // this.deHighlightLabels([option])
      } else {
        this.toggleHighlight({ buildPlanItemId: patch.buildPlanItemId, highlight: false })
      }
    }
  }

  clickedPatchRow(patch: PrintOrderPreviewPatch, event) {
    this.systemSelection = true
    this.deselect()
    const ctrlKey = event.ctrlKey || event.metaKey
    const { shiftKey } = event

    if (ctrlKey) {
      patch.selected = !patch.selected
      if (patch.selected) {
        this.selectedPatchesMap.set(patch.id, patch)
      } else {
        this.selectedPatchesMap.delete(patch.id)
      }
    } else if (shiftKey) {
      const mapItr = this.selectedPatchesMap.values()
      const firstSelectedPatch = mapItr.next().value
      if (firstSelectedPatch) {
        const firstIndex = firstSelectedPatch.index < patch.index ? firstSelectedPatch.index : patch.index
        const lastIndex = firstSelectedPatch.index > patch.index ? firstSelectedPatch.index : patch.index

        this.selectedPatchesMap.forEach((item) => {
          this.hoverOutOfPatchRow(item)
        })
        this.selectedPatchesMap.clear()
        this.filteredPatches.forEach((filteredPatch) => {
          if (filteredPatch.index >= firstIndex && filteredPatch.index <= lastIndex) {
            filteredPatch.selected = true
            this.selectedPatchesMap.set(filteredPatch.id, filteredPatch)
            this.hoverIntoPatchRow(filteredPatch)
          } else {
            filteredPatch.selected = false
            this.hoverOutOfPatchRow(filteredPatch)
          }
        })
      } else {
        this.selectedPatchesMap.set(patch.id, patch)
      }
    } else {
      this.selectedPatchesMap.forEach((item) => {
        item.selected = false
        this.hoverOutOfPatchRow(item)
      })
      this.selectedPatchesMap.clear()
      patch.selected = !patch.selected
      if (patch.selected) {
        this.hoverIntoPatchRow(patch)
        this.selectedPatchesMap.set(patch.id, patch)
      }
    }

    const selectedBuildPlanItemsId: Set<string> = new Set()
    this.selectedPatchesMap.forEach((item) => {
      const buildPlanItemId = item.buildPlanItemId
      if (!selectedBuildPlanItemsId.has(buildPlanItemId)) {
        selectedBuildPlanItemsId.add(buildPlanItemId)
        this.selectAndHighlightPart({
          buildPlanItemId,
          deselectIfSelected: false,
          showGizmo: false,
        })
      }
    })

    this.hoverOutOfPatchRow(patch)
    this.$forceUpdate()
  }
}
