
import Vue from 'vue'
import { required, email } from 'vee-validate/dist/rules'
import TextField from '@/components/controls/Common/TextField.vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { extend, ValidationObserver } from 'vee-validate'
import Selector from '@/components/controls/Common/Selector.vue'
import messageService from '@/services/messageService'

extend('required', { ...required, message: 'This field is required' })
extend('email', { ...email, message: 'Please enter a valid email' })

@Component({
  components: {
    TextField,
    Selector,
  },
})
export default class InviteUserModal extends Vue {
  @Prop() visible
  @Prop() allUsers
  @Prop() onOk: (string, boolean) => void
  @Prop() onClose: () => void

  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  email = ''
  inviteAsAdmin = false
  loading = false

  @Watch('visible')
  onVisibleChanged(val) {
    if (val) {
      this.email = ''
      this.loading = false
    }
    if (this.$refs.form) {
      this.$refs.form.reset()
    }
  }

  async onOkClick() {
    const inviteeEmail = this.email.toLowerCase()
    if (this.allUsers.filter((user) => user.email.toLowerCase() === inviteeEmail).length > 0) {
      const message = `${this.$i18n.t('userAlreadyExists')}`
      messageService.showErrorMessage(message)
    } else {
      this.loading = true
      await this.onOk(inviteeEmail, this.inviteAsAdmin)
      this.loading = false
    }
  }
}
