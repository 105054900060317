
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Menu from '@/components/controls/Common/Menu.vue'

@Component({
  components: {
    Menu,
  },
})
export default class JobsViewer extends Vue {
  @Prop() headerTitle: string
  @Prop() jobs: any[]
}
