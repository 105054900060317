import {
  MECHANICAL_NAME_PATTERN,
  MATERIAL_REMOVAL_NAME_PATTERN,
  STEP_NAME_PATTERN,
  STRESS_RELIEF_NAME_PATTERN,
} from '@/constants'
import i18n from '@/plugins/i18n'

export interface ISimulationStep {
  name: string
  value: string
  type: StepType
  size?: number
}

export enum StepType {
  DmlmBuild,
  DmlmMaterialRemoval,
  DmlmStressRelief,
  BinderJet,
  Compensation,
  Deviation,
}

export function dirToSimulationStep(dirname: string): ISimulationStep {
  if (!STEP_NAME_PATTERN.test(dirname)) return

  if (MECHANICAL_NAME_PATTERN.test(dirname)) {
    return {
      name: i18n.t('mechanicalStep').toString(),
      value: dirname,
      type: StepType.DmlmBuild,
    }
  }

  if (MATERIAL_REMOVAL_NAME_PATTERN.test(dirname)) {
    return {
      name: i18n.t('materialRemovalStep').toString(),
      value: dirname,
      type: StepType.DmlmMaterialRemoval,
    }
  }

  if (STRESS_RELIEF_NAME_PATTERN.test(dirname)) {
    return {
      name: i18n.t('stressReliefStep').toString(),
      value: dirname,
      type: StepType.DmlmStressRelief,
    }
  }

  return {
    name: i18n.t('sinterStep').toString(),
    value: dirname,
    type: StepType.BinderJet,
  }
}

export function sortSimulationSteps(steps: ISimulationStep[]) {
  steps.sort((a, b) => {
    // Compensation step should always be the last
    if (a.type === StepType.Compensation) return 1
    if (b.type === StepType.Compensation) return -1

    // Build step should always be the first
    if (a.type === StepType.DmlmBuild) return -1
    if (b.type === StepType.DmlmBuild) return 1

    const aIndex = +a.value.split('_')[0]
    const bIndex = +b.value.split('_')[0]

    return aIndex - bIndex
  })
}

export const COMPENSATION_STEP: ISimulationStep = {
  name: i18n.t('compensationStep').toString(),
  value: 'Compensation',
  type: StepType.Compensation,
}
