import Vue from 'vue'
import munchkin from '@/utils/marketo/munchkin'
import createAuth0Client from '@auth0/auth0-spa-js'
import * as Sentry from '@sentry/browser'
import { setAuthorizationToken, setTenant } from '@/api/http'
import router, { ROUTER_PERMISSIONS, RouterPaths } from '@/router'
import { AMP_CT_COOKIE, LOGOUT_EVENT } from '@/constants'

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

const auth0Provider = 'auth0'
const auth0ProviderDivider = '|'

let instance: any

/** Returns the current instance of the SDK */
export const getInstance = () => instance

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin, // + '/cost-estimations',
  ...options
}) => {
  if (instance) {
    return instance
  }

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        auth0Client: null,
        error: null,
        isAuthenticated: false,
        loading: true,
        popupOpen: false,
        user: {},
      }
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(o?: any) {
        this.popupOpen = true

        try {
          await this.auth0Client.loginWithPopup(o)
        } catch (e) {
          // eslint-disable-next-line
          // console.error(e);
        } finally {
          this.popupOpen = false
        }

        const auth0User = await this.auth0Client.getUser()
        this.setUser(auth0User)
        this.isAuthenticated = true
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          const auth0User = await this.auth0Client.getUser()
          this.setUser(auth0User)
          this.isAuthenticated = true
        } catch (e) {
          this.error = e
        } finally {
          this.loading = false
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o?: any) {
        return this.auth0Client.loginWithRedirect(o)
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o?: any) {
        return this.auth0Client.getIdTokenClaims(o)
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o?: any) {
        return this.auth0Client.getTokenSilently(o)
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o?: any) {
        return this.auth0Client.getTokenWithPopup(o)
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o?: any) {
        return this.auth0Client.logout(o)
      },
      localStorageListener(event) {
        // make sure we are catching logout dispatches only for the current domain
        if (event.key === `${LOGOUT_EVENT}-${window.location.host}`) {
          this.auth0Client.logout({ returnTo: window.location.origin })
        }
      },
      onLoginErrorRedirect() {
        if (window.location.search.includes('error=')) {
          const urlParams = new URLSearchParams(window.location.search)
          const message = urlParams.get('error_description')
          options.onLoginErrorRedirect(message)
        } else {
          // @ts-ignore
          router.safePush(RouterPaths.Home)
        }
      },
      setUser(auth0User: any) {
        this.user.id = auth0User.sub.includes(auth0Provider)
          ? auth0User.sub.split(auth0ProviderDivider)[1]
          : auth0User.sub
        this.user.firstName = auth0User[`${options.audience}/firstName`]
        this.user.lastName = auth0User[`${options.audience}/lastName`]
        this.user.email = auth0User.email
        this.user.company = auth0User[`${options.audience}/company`]
        this.user.country = auth0User[`${options.audience}/country`]
        this.user.optIn = auth0User[`${options.audience}/optIn`]
        this.user.emailVerified = auth0User[`${options.audience}/emailVerified`]
        this.user.consentGiven = auth0User[`${options.audience}/consentGiven`]
        this.user.acceptedTermsOfService = auth0User[`${options.audience}/acceptedTermsOfService`]
        this.user.roles = auth0User[`${options.audience}/roles`]
        this.user.oldUserId = auth0User[`${options.audience}/old_user_id`]
        this.user.ssoMigratedTenants = auth0User[`${options.audience}/ssoMigratedTenants`]
      },
      setTenant(auth0User: any) {
        const cookieTenant = Vue.$cookies.get(AMP_CT_COOKIE)
        const defaultTenant = window.env.VUE_APP_DEFAULT_TENANT
        const usersTenants = auth0User[`${options.audience}/tenants`]
        if (cookieTenant && usersTenants && usersTenants.includes(cookieTenant)) {
          this.user.tenant = cookieTenant
        } else if (defaultTenant && usersTenants && usersTenants.includes(defaultTenant)) {
          this.user.tenant = defaultTenant
        } else {
          this.user.tenant = usersTenants[0]
        }

        // overwrite cookie value here if necessary
        if (cookieTenant !== this.user.tenant) {
          Vue.$cookies.set(AMP_CT_COOKIE, this.user.tenant)
        }
        // add proper tenant tag to Sentry tracking
        Sentry.setUser({ id: this.user.id })
        Sentry.setTag('tenant', this.user.tenant)
        // set tenant for axios
        setTenant(this.user.tenant)
        this.user.tenants = usersTenants.sort()
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      let outsideResolve: any

      const promise = new Promise((resolve) => {
        outsideResolve = resolve
      })

      options.store.dispatch('user/setAuthPromise', promise)
      try {
        this.loading = true
        options.store.dispatch('user/setIsAuthenticating', true)
        this.auth0Client = await createAuth0Client({
          audience: options.audience,
          client_id: options.clientId,
          domain: options.domain,
          redirect_uri: options.redirectPath ? `${redirectUri}/${options.redirectPath}` : redirectUri,
        })

        options.store.dispatch('user/setAuth0Client', this.auth0Client)
        const accessToken = await this.auth0Client.getTokenSilently()
        if (accessToken) {
          this.isAuthenticated = true
          setAuthorizationToken(accessToken)
          const auth0User = await this.auth0Client.getUser()
          this.setUser(auth0User)
          this.setTenant(auth0User)
          this.user.permissions = await options.store.dispatch('user/getUserPermissions')
          await options.store.dispatch('user/authorizeResources')
          // SET THE S3 COOKIE HERE IF IT's NOT SET
          munchkin.associateLead(this.user)
          options.store.dispatch('user/setUserDetails', this.user)
          options.store.dispatch('user/setIsAuthenticated', true)
          // set the proper default route here based on user permissions
          if (this.user.tenant === 'www' && this.user.permissions.includes(...ROUTER_PERMISSIONS.CostEstimator)) {
            router.defaultRoute = RouterPaths.ManageCostEstimations
          } else if (this.user.tenant !== 'www' && this.user.permissions.includes(...ROUTER_PERMISSIONS.PrintModel)) {
            router.defaultRoute = RouterPaths.DefaultFileExplorer
          }
          if (window.location.pathname === '/') {
            options.onAutologinRedirect()
          }
          outsideResolve(true)
        } else {
          options.store.dispatch('user/setIsAuthenticated', false)
          outsideResolve(false)
        }
        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
          onRedirectCallback()
        }
      } catch (e) {
        this.error = e
        options.store.dispatch('user/setUserDetails', null)
        options.store.dispatch('user/setIsAuthenticating', false)
        options.store.dispatch('user/setIsAuthenticated', false)
        outsideResolve(false)
        this.onLoginErrorRedirect()
      } finally {
        this.loading = false
        options.store.dispatch('user/setIsAuthenticating', false)
      }

      window.addEventListener('storage', this.localStorageListener)
    },
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const auth0Plugin = {
  install(vueObj?: any, options?: any) {
    Vue.prototype.$auth = useAuth0(options)
  },
}
