import { Component, Vue } from 'vue-property-decorator'
import { EditViewMode } from '@/types/Sites/Site'
import { extend } from 'vee-validate'

@Component
export class SiteAdminMixin extends Vue {
  editMode: EditViewMode = EditViewMode.None
  viewModes = EditViewMode

  beforeMount() {
    extend('custom_more_value', {
      validate: (value: number, { limit }: { limit: number }) => {
        return value > limit
      },
      params: ['limit'],
      message: this.$t('tenantAdminMessages.siteConstantValidationMessages.moreValue').toString(),
    })
    extend('is_integer', {
      validate: (value) => {
        return /^\d+$/.test(value)
      },
    })
    extend('less_value', {
      validate: (value: number, { limit }: { limit: number }) => {
        return value < limit
      },
      params: ['limit'],
      message: this.$i18n.t('tenantAdminMessages.siteConstantValidationMessages.lessThan') as string,
    })
  }

  activateEditSiteRowItemMode(editMode: EditViewMode) {
    this.editMode = editMode
  }

  isCurrendEditViewMode(viewMode: EditViewMode) {
    return this.editMode === viewMode
  }

  get validationRules() {
    return {
      rules: {
        required: true,
        custom_more_value: 0,
      },
      customMessages: {
        required: this.$t('tenantAdminMessages.siteConstantValidationMessages.required').toString(),
      },
    }
  }

  get recoaterAndFilterLifeValidationRules() {
    return {
      rules: {
        required: true,
        custom_more_value: 0,
        is_integer: true,
        less_value: 100000,
      },
      customMessages: {
        required: this.$t('tenantAdminMessages.siteConstantValidationMessages.required').toString(),
        is_integer: this.$t('tenantAdminMessages.siteConstantValidationMessages.isNotInteger').toString(),
      },
    }
  }
}
