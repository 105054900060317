import { Vector3 } from '@babylonjs/core/Maths'
import { Mesh } from '@babylonjs/core/Meshes'
import { VertexBuffer } from '@babylonjs/core/Buffers'
import { Scene } from '@babylonjs/core/scene'

export class DataWarpingHandler {
  private currentWarpingFactor: number
  private scene: Scene
  private meshId: string
  private component0
  private component1
  private component2
  private initialized: boolean

  constructor(scene: Scene, meshId: string) {
    this.scene = scene
    this.meshId = meshId
  }

  get isInitialized() {
    return this.initialized
  }

  init(component0, component1, component2) {
    this.component0 = component0
    this.component1 = component1
    this.component2 = component2
    this.initialized = true
  }

  setWarpingFactor(factor: number) {
    if (!this.initialized) return

    const relativeFactor = this.calculateWarpingWactor(factor)
    if (!relativeFactor) return

    const mesh = this.scene.getMeshByID(this.meshId) as Mesh
    const coords = mesh.getVerticesData(VertexBuffer.PositionKind)
    for (let i = 0; i < coords.length; i += 3) {
      const v = new Vector3(coords[i], coords[i + 1], coords[i + 2])
      let displacement = new Vector3(this.component0[i / 3], this.component1[i / 3], this.component2[i / 3])
      displacement = displacement.multiplyByFloats(relativeFactor, relativeFactor, relativeFactor)
      v.addInPlace(displacement)
      coords[i] = v.x
      coords[i + 1] = v.y
      coords[i + 2] = v.z
    }

    mesh.updateVerticesData(VertexBuffer.PositionKind, coords)
    mesh.refreshBoundingInfo()
  }

  clear() {
    this.component0 = undefined
    this.component1 = undefined
    this.component2 = undefined
    this.currentWarpingFactor = undefined
    this.initialized = false
  }

  private calculateWarpingWactor(factor: number) {
    if (!this.currentWarpingFactor) {
      this.currentWarpingFactor = factor
      return factor
    }

    const previousFactor = this.currentWarpingFactor
    this.currentWarpingFactor = factor

    return factor - previousFactor
  }
}
