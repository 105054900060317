
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import VTextarea from 'vuetify/lib/components/VTextarea'

@Component
export default class TextArea extends Vue {
  @Prop({ default: false }) disabled: boolean
  @Prop() placeholder!: string
  @Prop() value: string
  @Prop({ type: [Object, String], default: '' }) readonly rules: object | string
  @Prop() height: string
  @Prop({ default: false }) fixed: boolean
  @Prop({ default: false }) counter: boolean

  $refs!: {
    textarea: VTextarea
  }

  // @ts-ignore
  model: string = this.value ? String(this.value) : ''

  input(e) {
    this.$emit('input', e)
  }

  focus() {
    this.$refs.textarea.focus()
  }

  selectAll() {
    this.$refs.textarea.$el.querySelector('textarea').select()
  }

  @Watch('value')
  onValueChanged(val) {
    this.model = val ? String(val) : ''
  }

  onInput() {
    return { on: ['input'] }
  }
}
