import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { IHighlightableDefect, IHighlightDefectPayload, ISelectableDefectPayload } from '@/types/Parts/IPartInsight'

const visualizationStore = namespace(StoresNamespaces.Visualization)
const partsStore = namespace(StoresNamespaces.Parts)

@Component
export class DefectInsightMixin extends Vue {
  @visualizationStore.Mutation selectDefects: (payload: ISelectableDefectPayload) => void
  @visualizationStore.Mutation toggleDefectsHighlight: (payload: IHighlightDefectPayload) => void

  @partsStore.Getter selectedDefect: IHighlightableDefect
  @partsStore.Getter hoveredDefect: IHighlightableDefect
  @partsStore.Mutation setSelectedDefect: (payload: {
    defectType: number
    defectIndex?: number
    shapeIndex?: number
  }) => void
  @partsStore.Mutation addSelectedDefect: (payload: {
    defectType: number
    defectIndex?: number
    shapeIndex?: number
  }) => void
  @partsStore.Mutation removeSelectedDefect: (payload: {
    defectType: number
    defectIndex?: number
    shapeIndex?: number
  }) => void
  @partsStore.Mutation setHoveredDefect: (payload: {
    defectType: number
    defectIndex?: number
    shapeIndex?: number
  }) => void
}
