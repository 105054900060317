import { MutationTree } from 'vuex'
import { IUsersState } from './types'
import { IUser } from '@/types/User/IUser'
import { ICountry } from '@/types/User/ICountry'
import Vue from 'vue'

export const mutations: MutationTree<IUsersState> = {
  setUser(state, user: IUser) {
    state.user = user
  },

  setIsAuthenticatedUser(state, isLoggedIn: boolean) {
    state.isLoggedIn = isLoggedIn
  },

  setIsAuthenticating(state, isAuthenticating: boolean) {
    state.isAuthenticating = isAuthenticating
  },

  setIsLoadingUsers(state, isLoadingUsers: boolean) {
    state.isLoadingUsers = isLoadingUsers
  },

  setAuth0Client(state, auth0Client) {
    state.auth0Client = auth0Client
  },

  setAuthPromise(state, authPromise) {
    state.authPromise = authPromise
  },

  setUsers(state, users: IUser[]) {
    users.forEach((user) => {
      Vue.set(state.users.byId, user.id, user)
    })
  },

  setCountries(state, countries: ICountry[]) {
    state.countries = countries
  },
}
