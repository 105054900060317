import { Commit, Dispatch } from 'vuex'
import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import buildPlanItemsService from '@/api/buildPlanItems'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

export abstract class Command {
  public readonly toolName: ToolNames
  protected readonly rootLevel = { root: true }
  protected readonly commit: Commit
  protected readonly dispatch: Dispatch

  protected deselectItems(): void {
    this.commit('visualizationModule/deselect', null, this.rootLevel)
  }

  protected selectAndHighlightPartsAfterRemove(buildPlanItems: IBuildPlanItem[]): void {
    this.selectParts(buildPlanItems, 'visualizationModule/selectAndHighlightPartsAfterRemove')
  }

  protected selectAndHighlightParts(buildPlanItems: IBuildPlanItem[]): void {
    this.selectParts(buildPlanItems, 'visualizationModule/selectAndHighlightPartWithAttach')
  }

  protected selectPartByBuildPlanItemId(buildPlanItemId: string): void {
    this.commit(
      'visualizationModule/selectAndHighlightPartWithAttach',
      {
        buildPlanItemIds: buildPlanItemId,
      },
      this.rootLevel,
    )
  }

  protected async createBuildPlanItems(itemsToCreate: IBuildPlanItem[]): Promise<IBuildPlanItem[]> {
    const buildPlanItemsToCreate = itemsToCreate.map((item) => {
      delete item.id
      return item
    })

    const createdBuildPlanItems = await buildPlanItemsService.createBuildPlanItems(buildPlanItemsToCreate)

    this.dispatch('buildPlans/loadBuildPlanItemsByConfig', createdBuildPlanItems, this.rootLevel)
    this.commit('buildPlans/addBuildPlanItems', createdBuildPlanItems, this.rootLevel)

    return createdBuildPlanItems
  }

  protected async deleteBuildPlanItems(
    itemsToDelete: IBuildPlanItem[],
    doNotCreateUndoCommand: boolean,
  ): Promise<void> {
    const actionPayload = {
      doNotCreateUndoCommand,
      buildPlanItemIds: itemsToDelete.map((bpi) => bpi.id),
    }

    await this.dispatch('buildPlans/deleteBuildPlanItemsById', actionPayload, this.rootLevel)
  }

  private selectParts(buildPlanItems: IBuildPlanItem[], mutationTypeToCommit: string): void {
    this.commit(
      mutationTypeToCommit,
      {
        buildPlanItemIds: buildPlanItems.map((item) => item.id),
      },
      this.rootLevel,
    )
  }
}
