
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { Mixins, Watch } from 'vue-property-decorator'

import BuildPlanLayoutTab from '@/components/layout/buildPlans/BuildPlanLayoutTab.vue'
import BuildPlanOrientationTab from '@/components/layout/buildPlans/BuildPlanOrientationTab.vue'
import BuildPlanSupportTab from '@/components/layout/buildPlans/BuildPlanSupportTab.vue'
import BuildPlanNestingTab from '@/components/layout/buildPlans/BuildPlanNestingTab.vue'
import SimulateTab from '@/components/layout/buildPlans/SimulateTab.vue'
import BuildPlanCompensationTab from '@/components/layout/buildPlans/BuildPlanCompensationTab.vue'
import BuildPlanPrintTab from '@/components/layout/buildPlans/BuildPlanPrintTab.vue'
import BuildPlanPublishTab from '@/components/layout/buildPlans/BuildPlanPublishTab.vue'
import BuildPlanMarkingTab from '@/components/layout/buildPlans/BuildPlanMarkingTab.vue'
import BuildPlanMoveTab from '@/components/layout/buildPlans/BuildPlanMoveTab.vue'
import BuildPlanRotateTab from '@/components/layout/buildPlans/BuildPlanRotateTab.vue'
import BuildPlanScaleTab from '@/components/layout/buildPlans/BuildPlanScaleTab.vue'
import BuildPlanDuplicateTab from '@/components/layout/buildPlans/BuildPlanDuplicateTab.vue'
import BuildPlanConstrainTab from '@/components/layout/buildPlans/BuildPlanConstrainTab.vue'
import BuildPlanSidebarBadges from '@/components/layout/buildPlans/BuildPlanSidebarBadges.vue'
import BuildPlanToolHeaderBadges from '@/components/layout/buildPlans/BuildPlanToolHeaderBadges.vue'
import BuildPlanReplaceTab from '@/components/layout/buildPlans/BuildPlanReplaceTab.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import AlertModal from '@/components/modals/AlertModal.vue'
import ConnectionErrorModal from '@/components/modals/ConnectionErrorModal.vue'
import {
  GeometryType,
  IBuildPlan,
  IBuildPlanItem,
  IPrintStrategyParameterSet,
  ISelectable,
  IVariantItem,
  SliceAlignment,
  Visibility,
} from '@/types/BuildPlans/IBuildPlan'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { RouterNames } from '@/router'
import { IJob, JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import BuildPlanSidebarTools, {
  BuildPlanTool,
  TOOL_HTML_TYPE,
  ToolNames,
  WAYS_TO_ENABLE_TOOLS,
} from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { orderBy } from '@/utils/array'
import { SortOrders } from '@/types/SortModes'
import buildPlans from '@/api/buildPlans'
import BuildPlanTransferPropsTool from '@/components/layout/buildPlans/stickyToPart/BuildPlanTransferPropsTool.vue'
import CommunicationService from '@/services/CommunicationService'
import { BrokerEvents } from '@/types/Common/BrokerEvents'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import BuildPlanPrintOrderTab from './BuildPlanPrintOrderTab.vue'
import Selector from '@/components/controls/Common/Selector.vue'
import messageService from '@/services/messageService'
import { isTabVisible } from '@/utils/common'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { InsightsSeverity } from '@/types/Common/Insights'
import { PrintingTypes } from '@/types/IMachineConfig'
import { VersionablePk } from '@/types/Common/VersionablePk'
import Menu from '@/components/controls/Common/Menu.vue'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { PRINT_JOB_EXCLUDE_STATUS_SET, SOCKET_TOOL_ACTIVATION_TIMEOUT } from '@/constants'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { getDefaultBaseOnType } from '@/utils/parameterSet/parameterSetUtils'
import LabelSetsUpdateDialog from '@/components/layout/buildPlans/marking/LabelSetsUpdateDialog.vue'
import SocketDisconnectModal from '@/components/layout/buildPlans/modals/SocketDisconnectModal.vue'
import ToolMask from '@/components/modals/ToolMask.vue'
import {
  createUpdateProcessComplete,
  isUpdated,
  LabelServiceMixin,
} from '@/components/layout/buildPlans/marking/mixins/LabelServiceMixin'
import {
  ActiveToolUnsavedChangesMixin,
  ExitToolAction,
} from '@/components/layout/buildPlans/mixins/ActiveToolUnsavedChangesMixin'
import { SidebarMixin } from '@/components/layout/buildPlans/mixins/SidebarMixin'
import ConfirmToolExitModal from '@/components/layout/buildPlans/ConfirmToolExitModal.vue'
import SessionModal from '@/components/modals/SessionModal.vue'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const partsStore = namespace(StoresNamespaces.Parts)
const commonStore = namespace(StoresNamespaces.Common)
const labelStore = namespace(StoresNamespaces.Labels)

const JOBS_POLLING_TIMEOUT = 5000
const DEFAULT_BUTTON_STYLE = 'button'
const PRINT_ORDER_BUTTON_STYLE = 'print-order-button'

const isNotAllowedJobStatusCodes = [
  JobStatusCode.RUNNING,
  JobStatusCode.CANCELLED,
  JobStatusCode.CANCELLING,
  JobStatusCode.ERROR,
]

const exportNotEnabledJobStatusCodes = [JobStatusCode.RUNNING, JobStatusCode.PENDING, JobStatusCode.QUEUED]

@Component({
  components: {
    BuildPlanLayoutTab,
    BuildPlanOrientationTab,
    BuildPlanSupportTab,
    BuildPlanNestingTab,
    BuildPlanCompensationTab,
    BuildPlanPrintTab,
    BuildPlanPrintOrderTab,
    BuildPlanPublishTab,
    BuildPlanMarkingTab,
    BuildPlanMoveTab,
    BuildPlanRotateTab,
    BuildPlanScaleTab,
    BuildPlanDuplicateTab,
    BuildPlanConstrainTab,
    BuildPlanSidebarBadges,
    BuildPlanToolHeaderBadges,
    BuildPlanReplaceTab,
    ConfirmModal,
    AlertModal,
    ConnectionErrorModal,
    Selector,
    Menu,
    LabelSetsUpdateDialog,
    SocketDisconnectModal,
    ConfirmToolExitModal,
    SessionModal,
    ToolMask,
  },
})
export default class BuildPlanSidebar extends Mixins(ActiveToolUnsavedChangesMixin, SidebarMixin, LabelServiceMixin) {
  get isExportButtonDisabled() {
    const results = this.getResultsAvailable
    return !this.checkSimComJobCompleted || (results && !results.exportData && !Object.keys(results.exportData).length)
  }

  get checkSimComJobCompleted() {
    const jobs = this.getSelectedBuildPlanJobs.filter(
      (job) => job.jobType === JobType.SIMULATE || job.jobType === JobType.COMPENSATE,
    )
    jobs.sort((a, b) => +new Date(a.updatedDate) - +new Date(b.updatedDate))
    const lastJob = jobs.length ? jobs[jobs.length - 1] : null

    return lastJob ? !exportNotEnabledJobStatusCodes.includes(lastJob.code) : false
  }

  get toolComponent() {
    let tool = null
    switch (this.getBuildPlanViewMode) {
      case ViewModeTypes.Move:
        tool = BuildPlanMoveTab
        break
      case ViewModeTypes.Rotate:
        tool = BuildPlanRotateTab
        break
      case ViewModeTypes.Constrain:
        tool = BuildPlanConstrainTab
        break
      case ViewModeTypes.Part:
        tool = BuildPlanLayoutTab
        break
      case ViewModeTypes.Orientation:
        tool = BuildPlanOrientationTab
        break
      case ViewModeTypes.Support:
        tool = BuildPlanSupportTab
        break
      case ViewModeTypes.Nesting:
        tool = BuildPlanNestingTab
        break
      case ViewModeTypes.SimulationCompensation:
        tool = SimulateTab
        break
      case ViewModeTypes.Marking:
        tool = BuildPlanMarkingTab
        break
      case ViewModeTypes.Print:
        tool = BuildPlanPrintOrderTab
        break
      case ViewModeTypes.Publish:
        tool = BuildPlanPublishTab
        break
      case ViewModeTypes.Duplicate:
        tool = BuildPlanDuplicateTab
        break
      case ViewModeTypes.TransferProps:
        tool = BuildPlanTransferPropsTool
        break
      case ViewModeTypes.Replace:
        tool = BuildPlanReplaceTab
        break
    }

    if (tool && tool.extendOptions.methods) {
      this.cancelAvailable = tool.options.methods.clickCancel !== undefined
      this.okAvailable = tool.options.methods.clickOk !== undefined
      this.exportAvailable = tool.options.methods.clickExport !== undefined
      this.okName = tool.options.methods.getOkName ? tool.options.methods.getOkName() : null
      this.cancelName = tool.options.methods.getCancelName ? tool.options.methods.getCancelName() : null
    } else {
      // In case when tool doesn't have extended options for the generic tool action buttons
      // we must reset action buttons to the default state, because previous state for these action buttons
      // was stored in the component
      this.cancelAvailable = false
      this.okAvailable = false
      this.exportAvailable = false
      this.okName = null
      this.cancelName = null
    }

    return tool
  }

  get buttonLoading() {
    return (this.isBusy || this.actionButtonsLoading) && !this.activeToolIsLabel
  }

  get activeToolIsSimulate() {
    return this.activeTool.viewMode === ViewModeTypes.SimulationCompensation
  }

  get activeToolIsLabel() {
    return this.getBuildPlanViewMode === ViewModeTypes.Marking
  }

  get activeTool() {
    return this.items.find((item) => item.viewMode === this.getBuildPlanViewMode)
  }

  get modalConfirmLabel() {
    return !this.activeTool || !this.activeToolIsSimulate ? this.$t('cancel') : this.$t('continueExportLabel')
  }

  get modalCancelLabel() {
    return !this.activeTool || !this.activeToolIsSimulate ? this.$t('continue') : this.$t('cancelExportLabel')
  }

  @buildPlansStore.Action fetchBuildPlanJobs: Function
  @buildPlansStore.Action lockBuildPlanVariant: Function
  @buildPlansStore.Action publishBuildPlan: Function
  @buildPlansStore.Action fetchVariantJobsByBuildPlanId: (id: string) => Promise<void>
  @buildPlansStore.Action fetchIbcPlanJobs: (payload: {
    ibcPlanId: string
    fetchForRelatedVariants?: number
  }) => Promise<void>
  @buildPlansStore.Action getBuildPlanById: (id: string) => Promise<IBuildPlan>

  @partsStore.Action fetchSinterPartById: (id: string) => Promise<IPartDto>

  @fileExplorerStore.Action getGetRunningAndFailedJobsByItemIds: Function

  @commonStore.Getter tooltipOpenDelay: number

  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter('getAllBuildPlanItems') buildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getSelectedBuildPlanJobs: IJob[]
  @buildPlansStore.Getter getSelectedBuildPlanExistingSimCompJobs: IJob[]
  @buildPlansStore.Getter getSelectedBuildPlanFinalizingJobs: IJob[]
  @buildPlansStore.Getter getBpNonSimCompFinalizingJobs: IJob[]
  @buildPlansStore.Getter getIsLoading: boolean
  @buildPlansStore.Getter('getSelectedParts') selectedItems: ISelectable[]
  @buildPlansStore.Getter isReadOnly: boolean
  @buildPlansStore.Getter isSelectedToolReadOnly: boolean
  @buildPlansStore.Getter isBuildPlanContainsSheetBodies: boolean
  @buildPlansStore.Getter isBuildPlanContainsProductionOrCouponBodies: boolean
  @buildPlansStore.Getter insights: IBuildPlanInsight[]
  @buildPlansStore.Getter isSimulateReadOnly: boolean
  @buildPlansStore.Getter isSinterPlan: boolean
  @buildPlansStore.Getter isLockedForViewer: boolean
  @buildPlansStore.Getter getBuildPlanPrintStrategy: BuildPlanPrintStrategyDto
  @buildPlansStore.Getter getPrintStrategyParameterSetByPk: (pk: VersionablePk) => IPrintStrategyParameterSet
  @buildPlansStore.Getter getRequiresLabelSetUpdates: boolean
  @buildPlansStore.Getter getRunningNestingJobsByVariantId: (id: string) => IJob[]
  @buildPlansStore.Getter getRunningSimulationJobsByVariantId: (id: string) => IJob[]
  @buildPlansStore.Getter getRunningMeshJobsByVariantId: (id: string) => IJob[]
  @buildPlansStore.Getter isToolMaskDisplaying: boolean
  @buildPlansStore.Getter('getBuildPlanVariants') allVariants: IVariantItem[]
  @buildPlansStore.Getter getIbcPlanJobs: IJob[]
  @buildPlansStore.Getter isVariantProcessing: boolean
  @buildPlansStore.Getter getSelectedBuildPlanSimCompJobs: IJob[]
  @buildPlansStore.Getter isTransferToolBusy: boolean
  @buildPlansStore.Getter isLabelRestoreInProgress: boolean

  @fileExplorerStore.Getter getRunningJobsByItemId: (id: string) => IJob[]

  @buildPlansStore.Mutation setBuildPlanViewMode: (mode: ViewModeTypes | null) => void
  @buildPlansStore.Mutation setPrintOrderNavigatedFrom: Function
  @buildPlansStore.Mutation setBuildPlan: (buildPlan: IBuildPlan) => void
  @buildPlansStore.Mutation setLabelToolPreparing: (isPreparing: boolean) => void
  @buildPlansStore.Mutation setSupportToolPreparing: (isPreparing: boolean) => void

  @visualizationStore.Getter getVisualizationServiceStarted: boolean

  @visualizationStore.Mutation setVisualizationServiceStarted: Function
  @visualizationStore.Mutation changeViewMode: Function
  @visualizationStore.Mutation toggleHighlight: (payload: { buildPlanItemId: string; highlight: boolean }) => void
  @visualizationStore.Mutation enableClearanceTool: Function
  @visualizationStore.Action setBpItemVisibility: (payload: {
    bpItem: IBuildPlanItem
    makeVisible: boolean
    showAsTransparent: boolean
  }) => void

  @labelStore.Mutation setLabelUpdateInProgress: (value: boolean) => void
  @labelStore.Mutation setWatchToolReady: (value: boolean) => void
  @labelStore.Getter getIsOkDisabled: boolean
  @labelStore.Getter getLabelToolIsValid: boolean
  @labelStore.Getter isInDirtyState: boolean

  $refs!: {
    session: InstanceType<typeof SessionModal>
    toolComponent: any
    confirm: InstanceType<typeof ConfirmModal>
    alert2: InstanceType<typeof AlertModal>
    connectionError: InstanceType<typeof ConnectionErrorModal>
    updateLabels: InstanceType<typeof LabelSetsUpdateDialog>
  }

  viewModes = ViewModeTypes
  navigationReady = false
  isDestroyed = false

  // generic tool action button flags
  actionButtonsLoading = false

  // job-related properties
  runningJobs = []
  runningJobsTimeout = null
  importJobsTimeout = null
  failedJobs = []
  completedJobs = []
  warningJobs = []

  items: BuildPlanTool[] = []

  isBusy: boolean = false

  supportThrottle: NodeJS.Timeout
  simulateThrottle: NodeJS.Timeout
  labelThrottle: NodeJS.Timeout

  connector: CommunicationService = null

  okName: string = null
  selectorItems: string[] = []
  menuItems: any[] = []

  TOOL_HTML_TYPE = TOOL_HTML_TYPE
  confirmationDialogShown: boolean = false

  get allIbcVariantsRunningJobs(): IJob[] {
    return this.getIbcPlanJobs.filter((job) => {
      return [JobStatusCode.RUNNING, JobStatusCode.PENDING, JobStatusCode.QUEUED, JobStatusCode.CREATED].includes(
        job.code,
      )
    })
  }

  get lockedForViewerWayToEnable() {
    return this.isSinterPlan
      ? WAYS_TO_ENABLE_TOOLS.SINTER_PLAN_LOCKED_FOR_VIEWER
      : WAYS_TO_ENABLE_TOOLS.BUILD_PLAN_LOCKED_FOR_VIEWER
  }

  sideBarItemText(item) {
    if (item.key === ToolNames.PRINT_ORDER) {
      return this.$t('newPrintOrder')
    }

    return this.$t(item.key)
  }

  creatingJob() {
    this.actionButtonsLoading = true
  }

  jobCreated() {
    this.actionButtonsLoading = false
    this.pollAllActiveJobs(true)
  }

  failedJob() {
    this.actionButtonsLoading = false
  }

  checkBuildPlanReadOnly(item, disableTool: boolean = false, onlyRunningJobs: boolean = false) {
    // tools that call this check are either
    // 1) *read-only* (disableTool === false) or
    // 2) *not available* (disableTool === true)
    // if either
    // a) a job is running (onlyRunningJobs === true) or
    // b) a job is running, or simulation results exist or slice results exist (onlyRunningJobs === false)

    // in this case all tools should be in read only mode
    const readOnlyWayToEnable = this.isSinterPlan
      ? WAYS_TO_ENABLE_TOOLS.SINTER_PLAN_READ_ONLY
      : WAYS_TO_ENABLE_TOOLS.BUILD_PLAN_READ_ONLY

    if (this.isReadOnly) {
      if (disableTool && !this.checkLockedForViewer(item)) {
        this.addWayToEnable(item, readOnlyWayToEnable)
      }

      return true
    }

    let result = false
    if (onlyRunningJobs) {
      if (this.actionButtonsLoading || this.runningJobs.length > 0) {
        if (disableTool) {
          this.addWayToEnable(item, readOnlyWayToEnable)
        }
        result = true
      }
    } else {
      if (
        this.actionButtonsLoading ||
        this.runningJobs.length > 0 ||
        this.getSelectedBuildPlanFinalizingJobs.length > 0
      ) {
        if (disableTool) {
          this.addWayToEnable(item, readOnlyWayToEnable)
        }
        result = true
      }
    }

    return result
  }

  checkArrangeIsRunning(item, disable: boolean = false) {
    const readOnlyWayToEnable = this.isSinterPlan
      ? WAYS_TO_ENABLE_TOOLS.SINTER_PLAN_READ_ONLY
      : WAYS_TO_ENABLE_TOOLS.BUILD_PLAN_READ_ONLY
    const hasRunningArrangeJobs = this.getRunningNestingJobsByVariantId(this.getBuildPlan.id).length > 0
    if (hasRunningArrangeJobs && disable) {
      this.addWayToEnable(item, readOnlyWayToEnable)
    } else {
      this.removeWayToEnable(item, readOnlyWayToEnable)
    }
  }

  checkSimCompIsRunning(item, disable: boolean = false) {
    const readOnlyWayToEnable = this.isSinterPlan
      ? WAYS_TO_ENABLE_TOOLS.SINTER_PLAN_READ_ONLY
      : WAYS_TO_ENABLE_TOOLS.BUILD_PLAN_READ_ONLY
    const hasRunningSimCompJobs = this.getRunningSimulationJobsByVariantId(this.getBuildPlan.id).length > 0
    const hasMeshJobs = this.getRunningMeshJobsByVariantId(this.getBuildPlan.id).length > 0
    if ((hasMeshJobs || hasRunningSimCompJobs) && disable) {
      this.addWayToEnable(item, readOnlyWayToEnable)
    } else {
      this.removeWayToEnable(item, readOnlyWayToEnable)
    }
  }

  checkPartSelections(item, selections) {
    if (selections.length < 1) {
      this.addUniqueWayToEnable(item, WAYS_TO_ENABLE_TOOLS.SELECT_PARTS)
    } else {
      this.removeWayToEnable(item, WAYS_TO_ENABLE_TOOLS.SELECT_PARTS)
    }
  }

  checkPartsSelections(item, selections) {
    if (selections.length === 0) {
      this.addUniqueWayToEnable(item, WAYS_TO_ENABLE_TOOLS.SELECT_PARTS)
    } else {
      this.removeWayToEnable(item, WAYS_TO_ENABLE_TOOLS.SELECT_PARTS)
    }
  }

  checkPartsSelectionsReplace(item, selections) {
    if (selections.length === 0) {
      this.addUniqueWayToEnable(item, WAYS_TO_ENABLE_TOOLS.SELECT_PARTS_REPLACE)
    } else {
      this.removeWayToEnable(item, WAYS_TO_ENABLE_TOOLS.SELECT_PARTS_REPLACE)
    }
  }

  checkParameterSetsLayerThickness(item: BuildPlanTool, buildPlanItem: IBuildPlanItem) {
    let allLayerThicknessesDefined = true
    if (buildPlanItem.partProperties) {
      buildPlanItem.partProperties.map((pp) => {
        let printStrategyParameterSetPk: VersionablePk
        if (pp.printStrategyParameterSetId) {
          printStrategyParameterSetPk = new VersionablePk(
            pp.printStrategyParameterSetId,
            pp.printStrategyParameterSetVersion,
          )
        } else {
          const defaults = this.getBuildPlanPrintStrategy.defaults
          printStrategyParameterSetPk = getDefaultBaseOnType(defaults, pp.type, pp.bodyType)
        }
        const printStrategyParameterSet = this.getPrintStrategyParameterSetByPk(printStrategyParameterSetPk)
        if (printStrategyParameterSet && !printStrategyParameterSet.layerThickness) {
          allLayerThicknessesDefined = false
          this.addWayToEnable(
            item,
            WAYS_TO_ENABLE_TOOLS.INVALID_PARAMETER_SET_LAYER_THICKNESS,
            printStrategyParameterSet.parameterSet.parameterSetName,
            buildPlanItem.part.name,
          )
        }
      })
    }
    return allLayerThicknessesDefined
  }

  checkBodyType(item) {
    if (this.isBuildPlanContainsSheetBodies) {
      this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.SHEET_BODIES_INSIDE_BUILD_PLAN)
    }
  }

  @Watch('$route.params.id')
  async onChangeVariant() {
    clearTimeout(this.importJobsTimeout)
    this.checkJobsAndParameterSets()
  }

  @Watch('getBuildPlan')
  @Watch('getBuildPlan.modality')
  async selectedBuildPlanChanged(buildPlan: IBuildPlan) {
    // backwards compatibility check
    const subType = buildPlan.subType ? buildPlan.subType : ItemSubType.None
    const sidebarItems = BuildPlanSidebarTools.getBuildPlanTools(subType, buildPlan.modality)
    const slicePublishIndex = sidebarItems.findIndex((sidebarItem) => sidebarItem.key === ToolNames.SLICE_PUBLISH)
    const slicePublishSidebarItem = slicePublishIndex > 0 ? sidebarItems[slicePublishIndex] : null

    if (slicePublishSidebarItem) {
      const selectedVariantJobs = this.getSelectedBuildPlanJobs.filter((job) =>
        [job.relatedPlanId, job.itemId].includes(this.$route.params.id),
      )

      if (selectedVariantJobs.length === 0 || this.isVariantProcessing) {
        const readOnlyWayToEnable = this.isSinterPlan
          ? WAYS_TO_ENABLE_TOOLS.SINTER_PLAN_READ_ONLY
          : WAYS_TO_ENABLE_TOOLS.BUILD_PLAN_READ_ONLY
        this.addWayToEnable(slicePublishSidebarItem, readOnlyWayToEnable)
      } else {
        let showPrintOrder = false
        const completedSlicePublishJobs = selectedVariantJobs.filter((job) => {
          return slicePublishSidebarItem.jobTypes.includes(job.jobType) && [JobStatusCode.COMPLETE].includes(job.code)
        })

        if (completedSlicePublishJobs.length > 0) {
          const isAllRequiredJobsCompleted =
            completedSlicePublishJobs.length === slicePublishSidebarItem.jobTypes.length
          if (!isAllRequiredJobsCompleted) {
            const isWayToEnableExists = slicePublishSidebarItem.waysToEnable.find(
              (way) => way.key === WAYS_TO_ENABLE_TOOLS.TOOL_INFO,
            )
            if (!isWayToEnableExists) {
              this.addWayToEnable(
                slicePublishSidebarItem,
                WAYS_TO_ENABLE_TOOLS.TOOL_INFO,
                this.$t('slicePublish'),
                this.$t('waitingForTasks'),
              )
            }

            this.pollAllActiveJobs(true)
          }

          showPrintOrder = isAllRequiredJobsCompleted
        }

        for (const sidebarItem of sidebarItems) {
          if (sidebarItem.key === ToolNames.SLICE_PUBLISH) {
            sidebarItem.hidden = showPrintOrder
          }
          if (sidebarItem.key === ToolNames.PRINT_ORDER) {
            sidebarItem.hidden = !showPrintOrder
          }
          if (showPrintOrder && sidebarItem.key === ToolNames.SELECT_PRINT_ORDER) {
            const printJobs = selectedVariantJobs.filter((job) => {
              return sidebarItem.jobTypes.includes(job.jobType)
            })
            if (printJobs.length > 0) {
              sidebarItem.hidden = false
              const index = sidebarItems.findIndex((sitem) => sitem.key === ToolNames.PRINT_ORDER)
              sidebarItems[index].divide = false
            }
          }
        }
      }
    }

    this.items = sidebarItems
  }

  @Watch('buildPlanItems')
  @Watch('selectedItems')
  @Watch('getSelectedBuildPlanJobs')
  @Watch('isReadOnly')
  async onBuildPlanItemsOrJobsChange() {
    for (const item of this.items) {
      item.waysToEnable = []
      const selectedVariantJobs = this.getSelectedBuildPlanJobs.filter((job) =>
        [job.relatedPlanId, job.itemId].includes(this.$route.params.id),
      )
      const readOnlyWayToEnable = this.isSinterPlan
        ? WAYS_TO_ENABLE_TOOLS.SINTER_PLAN_READ_ONLY
        : WAYS_TO_ENABLE_TOOLS.BUILD_PLAN_READ_ONLY

      switch (item.key) {
        case ToolNames.ADD_PART:
          this.checkBuildPlanReadOnly(item, true)
          break
        case ToolNames.ROTATE:
          if (this.buildPlanItems.length === 0) {
            this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.NO_PARTS)
          } else if (this.buildPlanItems.length > 0 && !this.checkBuildPlanReadOnly(item, true) && !this.isSinterPlan) {
            this.checkPartSelections(item, this.selectedItems)
          }

          break
        case ToolNames.ORIENT:
          this.checkItemsQuantity(item)
          this.checkBodyType(item)

          // orient should be disabled for viewer if part was not oriented
          if (this.isLockedForViewer && this.selectedItems.length === 1) {
            const bpItem = this.getBpItemFromFirstSelected()
            if (!bpItem || !bpItem.orientCriteria) {
              this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.PART_NOT_ORIENTED)
            }
          }
          break
        case ToolNames.SUPPORT:
          this.checkItemsQuantity(item)
          this.checkBodyType(item)
          if (item.isPreparing) {
            this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_RECONNECT)
          }

          if (this.isLockedForViewer && this.selectedItems.length === 1) {
            const bpItem = this.getBpItemFromFirstSelected()
            if (!bpItem || !bpItem.supports) {
              this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.NO_SUPPORTS_FOR_PART)
            }
          }
          break
        case ToolNames.CONSTRAIN:
          if (this.buildPlanItems.length === 0) {
            this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.NO_PARTS)
          } else {
            this.checkPartsSelections(item, this.selectedItems)
          }
          break
        case ToolNames.ARRANGE:
          if (this.buildPlanItems.length === 0) {
            this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.NO_PARTS)
          }
          if (item.isPreparing) {
            this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_RECONNECT)
          }

          this.checkBodyType(item)
          this.checkLockedForViewer(item)
          break
        case ToolNames.LABEL:
          if (this.buildPlanItems.length === 0) {
            this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.NO_PARTS)
          }
          if (item.isPreparing) {
            this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_RECONNECT)
          }

          if (this.isLabelRestoreInProgress) {
            this.addUniqueWayToEnable(item, WAYS_TO_ENABLE_TOOLS.RESTORE_LABELS_IN_PROGRESS)
          }

          this.checkBodyType(item)
          this.checkLockedForViewer(item)
          this.checkLegacyWhileLocked(item)
          break
        case ToolNames.PRINT:
          if (this.buildPlanItems.length === 0) {
            this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.NO_PARTS)
          }

          break
        case ToolNames.SIMULATE:
          if (item.isPreparing) {
            this.addUniqueWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_RECONNECT)
          }

          if (this.buildPlanItems.length === 0) {
            this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.NO_PARTS)
          }

          this.checkBodyType(item)

          if (this.isLockedForViewer) {
            this.lockToolOrSimContentForViewer(item)
          }
          break
        case ToolNames.SLICE_PUBLISH:
          let shouldShowSlicePublish = true

          if (this.buildPlanItems.length === 0) {
            this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.NO_PARTS)
          } else if (
            this.getBuildPlan.modality === PrintingTypes.DMLM &&
            !this.isBuildPlanContainsProductionOrCouponBodies
          ) {
            this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.NO_PRODUCTION_OR_COUPON_BODIES)
          } else {
            if (this.isVariantProcessing) {
              this.addWayToEnable(item, readOnlyWayToEnable)
            }

            const completedSlicePublishJobs = selectedVariantJobs.filter((job) => {
              return item.jobTypes.includes(job.jobType) && [JobStatusCode.COMPLETE].includes(job.code)
            })

            const isBuildPlanReadOnly = this.checkBuildPlanReadOnly(item, true, true)

            if (!isBuildPlanReadOnly) {
              const isLayerThicknessDefined = this.buildPlanItems.every((buildPlanItem) => {
                return this.checkParameterSetsLayerThickness(item, buildPlanItem)
              })

              if (isLayerThicknessDefined) {
                const runningSlicePublishJobs = selectedVariantJobs.filter((job) => {
                  return (
                    item.jobTypes.includes(job.jobType) &&
                    [JobStatusCode.QUEUED, JobStatusCode.RUNNING, JobStatusCode.PENDING].includes(job.code)
                  )
                })
                if (runningSlicePublishJobs.length > 0) {
                  if (!item.waysToEnable.find((way) => way.key === WAYS_TO_ENABLE_TOOLS.TOOL_INFO)) {
                    this.addWayToEnable(
                      item,
                      WAYS_TO_ENABLE_TOOLS.TOOL_INFO,
                      this.$t('slicePublish'),
                      this.$t('processing'),
                    )
                  }
                }

                if (completedSlicePublishJobs.length > 0) {
                  for (const jobType of item.jobTypes) {
                    const job = completedSlicePublishJobs.find((itemJob) => itemJob.jobType === jobType)
                    if (!job) {
                      if (!item.waysToEnable.find((way) => way.key === WAYS_TO_ENABLE_TOOLS.TOOL_INFO)) {
                        this.addWayToEnable(
                          item,
                          WAYS_TO_ENABLE_TOOLS.TOOL_INFO,
                          this.$t('slicePublish'),
                          this.$t('waitingForTasks'),
                        )
                      }
                      break
                    }
                  }

                  if (!this.getBuildPlan.isLocked) {
                    await this.lockBuildPlanVariant()
                  }
                }
              }
            }

            const isAllRequiredJobsCompleted = completedSlicePublishJobs.length === item.jobTypes.length

            shouldShowSlicePublish = !isAllRequiredJobsCompleted
          }

          this.toggleTool(ToolNames.SLICE_PUBLISH, shouldShowSlicePublish)
          this.toggleTool(ToolNames.PRINT_ORDER, !shouldShowSlicePublish)

          break
        case ToolNames.PRINT_ORDER:
          if (this.isVariantProcessing || selectedVariantJobs.length === 0) {
            this.addWayToEnable(item, readOnlyWayToEnable)
          } else {
            this.checkBuildPlanReadOnly(item, true, true)
          }

          break
        case ToolNames.SELECT_PRINT_ORDER:
          this.setSelectorItems(item)
          if (this.selectorItems.length > 0) {
            item.hidden = false
            const index = this.items.findIndex((sitem) => sitem.key === 'printOrder')
            this.items[index].divide = false
          } else {
            item.hidden = true
          }
          break
        case ToolNames.PUBLISH:
          item.hidden = false
          this.checkCompJobsExist(item)
          this.checkSuccessfulPublishedParts(item)
          break
        case ToolNames.SELECT_PUBLISHED_PARTS:
          this.setMenuItems(item)
          if (this.menuItems.length > 0) {
            item.hidden = false
          } else {
            item.hidden = true
          }
          break
        case ToolNames.DUPLICATE:
        case ToolNames.MOVE:
          if (this.buildPlanItems.length === 0) {
            this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.NO_PARTS)
          } else if (!this.checkBuildPlanReadOnly(item, true)) {
            this.checkPartsSelections(item, this.selectedItems)
          }
          break
        case ToolNames.TRANSFER_PROPS:
          if (!this.checkBuildPlanReadOnly(item, true)) {
            this.checkPartsIdenticalToSelected(item)
            if (this.isTransferToolBusy) {
              this.addUniqueWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_UNAVAILABLE)
            }
          }
          break
        case ToolNames.REPLACE:
          if (!this.checkBuildPlanReadOnly(item, true)) {
            // TODO: Use checkPartsSelections when info tip requirements will be updated for other tools.
            // Or remove this comment if the info tips will be different.
            this.checkPartsSelectionsReplace(item, this.selectedItems)
          }
          break
      }
    }
  }

  toggleTool(toolName: ToolNames, isShow: boolean) {
    const foundTool = this.items.find((tool) => tool.key === toolName)

    if (foundTool) {
      foundTool.hidden = !isShow
    }
  }

  checkLockedForViewer(item: BuildPlanTool): boolean {
    if (this.isLockedForViewer) {
      this.addWayToEnable(item, this.lockedForViewerWayToEnable)
    }

    return this.isLockedForViewer
  }

  checkLegacyWhileLocked(item: BuildPlanTool) {
    const bpItemsWithLabels: IBuildPlanItem[] = this.getBuildPlan.buildPlanItems.filter(
      (bpItem: IBuildPlanItem) => bpItem.labels && bpItem.labels.length,
    )

    if (bpItemsWithLabels.length && this.getBpNonSimCompFinalizingJobs.length > 0) {
      this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.LOCKED_WITH_LEGACY_LABELS)
    } else {
      this.removeWayToEnable(item, WAYS_TO_ENABLE_TOOLS.LOCKED_WITH_LEGACY_LABELS)
    }
  }

  getBpItemFromFirstSelected() {
    if (this.selectedItems.length === 0) {
      return null
    }
    return this.buildPlanItems.find((item) => item.id === this.selectedItems[0].id)
  }

  checkItemsQuantity(item) {
    if (this.buildPlanItems.length === 0) {
      this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.NO_PARTS)
    } else if (this.buildPlanItems.length > 0) {
      if (this.selectedItems.length !== 1) {
        this.addUniqueWayToEnable(item, WAYS_TO_ENABLE_TOOLS.SELECT_ONE_PART)
      } else {
        this.removeWayToEnable(item, WAYS_TO_ENABLE_TOOLS.SELECT_ONE_PART)
        switch (item.key) {
          case ToolNames.ORIENT:
            this.checkOnlyProductionBodiesSelected(item)
            break
          case ToolNames.SUPPORT:
            this.checkAtLeastOneProductionBodySelected(item)
            break
        }
      }
    }
  }

  checkCompJobsExist(item: BuildPlanTool) {
    let noResultsCode: WAYS_TO_ENABLE_TOOLS
    let jobs = []

    noResultsCode = WAYS_TO_ENABLE_TOOLS.NO_COMP_RESULTS_TO_PUBLISH

    if (this.buildPlanItems.length === 0) {
      this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.NO_PARTS)
    } else if (!this.checkBuildPlanReadOnly(item, true, true)) {
      jobs = this.getSelectedBuildPlanJobs.filter((job) => {
        return job.jobType === JobType.COMPENSATE
      })

      if (jobs.length === 0) {
        this.addWayToEnable(item, noResultsCode)
      } else {
        jobs.sort((a, b) => +new Date(a.updatedDate) - +new Date(b.updatedDate))
        const lastJobStatus = jobs[jobs.length - 1].code
        const isAllowedCode = !isNotAllowedJobStatusCodes.includes(lastJobStatus)

        if (!isAllowedCode) {
          this.addWayToEnable(item, noResultsCode)
        }
      }
    }
  }

  checkSuccessfulPublishedParts(item: BuildPlanTool) {
    const completedPublishJobs = this.completedJobs.filter((job) => item.jobTypes.includes(job.jobType))
    // check if all build plan items in the sinter plan got published successfully
    if (completedPublishJobs.length >= this.buildPlanItems.length) {
      item.hidden = true
    } else {
      item.hidden = false
    }
  }

  checkPartsIdenticalToSelected(tool: BuildPlanTool) {
    if (this.buildPlanItems.length === 0) {
      this.addWayToEnable(tool, WAYS_TO_ENABLE_TOOLS.NO_PARTS)
    } else {
      if (this.selectedItems.length !== 1) {
        this.addUniqueWayToEnable(tool, WAYS_TO_ENABLE_TOOLS.SELECT_ONE_PART)
        this.removeWayToEnable(tool, WAYS_TO_ENABLE_TOOLS.NO_IDENTICAL_PARTS)
      } else {
        this.removeWayToEnable(tool, WAYS_TO_ENABLE_TOOLS.SELECT_ONE_PART)

        let hasIdenticalParts = false
        const selectedItem: IBuildPlanItem = this.buildPlanItems.find((item) => item.id === this.selectedItems[0].id)

        if (selectedItem) {
          hasIdenticalParts = this.buildPlanItems.some(
            (item) => item.id !== selectedItem.id && item.part.id === selectedItem.part.id,
          )
        }

        if (!hasIdenticalParts) {
          this.addUniqueWayToEnable(tool, WAYS_TO_ENABLE_TOOLS.NO_IDENTICAL_PARTS)
        }
      }
    }
  }

  checkOnlyProductionBodiesSelected(item) {
    const bpItem = this.buildPlanItems.find((i) => i.id === this.selectedItems[0].id)
    if (bpItem && bpItem.partProperties) {
      if (!bpItem.partProperties.every((pp) => pp.type === GeometryType.Production)) {
        this.addUniqueWayToEnable(item, WAYS_TO_ENABLE_TOOLS.PRODUCTION_BODIES_ONLY)
      } else {
        this.removeWayToEnable(item, WAYS_TO_ENABLE_TOOLS.PRODUCTION_BODIES_ONLY)
      }
    }
  }

  checkAtLeastOneProductionBodySelected(item) {
    const bpItem = this.buildPlanItems.find((i) => i.id === this.selectedItems[0].id)
    if (bpItem && bpItem.partProperties) {
      if (!bpItem.partProperties.some((pp) => pp.type === GeometryType.Production)) {
        this.addUniqueWayToEnable(item, WAYS_TO_ENABLE_TOOLS.AT_LEAST_ONE_PRODUCTION_BODY)
      } else {
        this.removeWayToEnable(item, WAYS_TO_ENABLE_TOOLS.AT_LEAST_ONE_PRODUCTION_BODY)
      }
    }
  }

  async closeTool() {
    if (this.activeToolIsSimulate && this.isCompensationFileInProgress) {
      const confirm = await this.$refs.confirm.open(
        this.$t('exportInProgressTitle'),
        this.$t('exportInProgressMessage'),
      )
      if (confirm) return
    }

    if (this.activeTool.key === ToolNames.ORIENT) {
      const bpItem = this.getBpItemFromFirstSelected()
      if (bpItem.visibility === Visibility.Hidden) {
        // Turn back initial display settings of hidden part. Show part as transparent because it's selected.
        this.setBpItemVisibility({ bpItem, makeVisible: false, showAsTransparent: true })
      }
    }

    if (this.$refs.toolComponent && (this.$refs.toolComponent as any).clickClose) {
      ; (this.$refs.toolComponent as any).clickClose()
    }

    if (this.activeTool && !this.activeTool.routerName) {
      this.setBuildPlanViewMode(null)
      this.changeViewMode(ViewModeTypes.Layout)
      return
    }

    const onEditBuildPlanPage = this.$route.matched.some((route) => route.name === RouterNames.EditBuildPlan)
    if (onEditBuildPlanPage) {
      // @ts-ignore
      this.$router.safePush({ name: RouterNames.EditBuildPlan })
    }
  }

  async clickCancel() {
    const toolAction = await this.canExitActiveTool()
    if (toolAction === ExitToolAction.DoNotExit) return

    if ((this.$refs.toolComponent as any).clickCancel && toolAction === ExitToolAction.NoDataToSave) {
      ; (this.$refs.toolComponent as any).clickCancel()
      this.isBusy = false
    }

    this.closeTool()
  }

  async clickOk(closeTool: boolean = true) {
    try {
      const clickHandler = (this.$refs.toolComponent as any).clickOk
      if (clickHandler) {
        this.isBusy = true
        await clickHandler()
        this.isBusy = false
      }

      if (closeTool) {
        this.closeTool()
      }
    } catch (error) {
      this.$refs.connectionError.open(this.sideBarItemText(this.activeTool).toString(), error)
    } finally {
      this.isBusy = false
    }
  }

  clickExport() {
    if ((this.$refs.toolComponent as any).clickExport) {
      ; (this.$refs.toolComponent as any).clickExport()
    }
  }

  async clickSecondaryAction() {
    const clickHandler = (this.$refs.toolComponent as IToolComponent).clickSecondaryAction
    if (clickHandler) {
      try {
        this.isBusy = true
        await clickHandler()
      } finally {
        this.isBusy = false
      }
    }
  }

  beforeMount() {
    this.setBuildPlanViewMode(null)

    this.connector = CommunicationService.getConnector()
    this.connector.subscribe(BrokerEvents.ExposureJobCreated, this.jobCreated)
    this.connector.subscribe(BrokerEvents.PrintJobCreated, this.jobCreated)
    this.$root.$on('updateLabels', this.startLabelsUpdate)
  }

  beforeDestroy() {
    this.$root.$off('updateLabels', this.startLabelsUpdate)
    this.$root.$off('showToolConfirmationDialog', this.showToolConfirmationDialog)
  }

  async mounted() {
    this.checkJobsAndParameterSets()
    this.$root.$on('showToolConfirmationDialog', this.showToolConfirmationDialog)
  }

  async checkJobsAndParameterSets() {
    await this.fetchAllJobs()

    const ibcVariants = this.allVariants.filter((v) => v.itemType === ItemType.IbcPlan)
    if (ibcVariants.length > 0) {
      await this.fetchIbcPlanJobs({ ibcPlanId: ibcVariants[0].id, fetchForRelatedVariants: 1 })
    }

    if (this.runningJobs.length > 0 || this.allIbcVariantsRunningJobs.length > 0) {
      await this.pollAllActiveJobs(true)
    }

    const runningPublishJobs = this.runningJobs.filter((job) => job.jobType === JobType.PUBLISH)
    if (runningPublishJobs.length) {
      const jobItemIds = runningPublishJobs.map((job) => job.itemId)
      await this.pollImportJobs(jobItemIds)
    }
  }

  destroyed() {
    clearTimeout(this.runningJobsTimeout)
    clearTimeout(this.importJobsTimeout)
    this.isDestroyed = true
  }

  async pollAllActiveJobs(forceFetchJobs = false) {
    clearTimeout(this.runningJobsTimeout)

    const hasToCheckJobs = this.runningJobs.length > 0 || this.allIbcVariantsRunningJobs.length > 0 || forceFetchJobs
    if (!hasToCheckJobs || this.isDestroyed) {
      return
    }

    this.runningJobsTimeout = setTimeout(
      async () => {
        try {
          if (isTabVisible()) {
            await this.fetchAllJobs()
          }
        } finally {
          this.pollAllActiveJobs()
        }
      },
      // make poll immediately in case it was forced
      forceFetchJobs ? 0 : JOBS_POLLING_TIMEOUT,
    )
  }

  async pollImportJobs(itemIds) {
    if (isTabVisible()) {
      await this.getGetRunningAndFailedJobsByItemIds(itemIds)
      this.runningJobs = itemIds.reduce((arr: IJob[], id: string) => {
        arr.push(...this.getRunningJobsByItemId(id))
        return arr
      }, [])
    }
    if (this.runningJobs.length > 0) {
      this.importJobsTimeout = setTimeout(() => this.pollImportJobs(itemIds), JOBS_POLLING_TIMEOUT)
    } else {
      clearTimeout(this.importJobsTimeout)
      // refresh all jobs including the latest completed ones
      await this.fetchAllJobs()

      // notify the user about Publishing completion if all parts published successfully
      const buildPlanName = this.getBuildPlan.name
      const completedPublishJobs = this.completedJobs.filter((job) => job.jobType === JobType.PUBLISH)
      if (completedPublishJobs.length >= this.buildPlanItems.length) {
        await this.$refs.alert2.open(this.$t('publish'), this.$t('buildPlanPublishSuccess', { buildPlanName }))
      } else {
        await this.$refs.alert2.open(this.$t('publish'), this.$t('buildPlanPublishFailed', { buildPlanName }))
      }
    }
  }

  async fetchAllJobs() {
    await this.fetchVariantJobsByBuildPlanId(this.$route.params.id)

    if (this.allIbcVariantsRunningJobs.length > 0) {
      const { relatedPlanId, itemId } = this.allIbcVariantsRunningJobs[0]
      const ibcVariantId = relatedPlanId || itemId
      await this.fetchIbcPlanJobs({ ibcPlanId: ibcVariantId, fetchForRelatedVariants: 1 })
    }

    const allJobs = this.getSelectedBuildPlanJobs
    this.runningJobs = allJobs.filter((job) => {
      if (job.jobType === JobType.PRINT) {
        return [JobStatusCode.RUNNING, JobStatusCode.QUEUED, JobStatusCode.CREATED].includes(job.code)
      }
      return [JobStatusCode.RUNNING, JobStatusCode.PENDING, JobStatusCode.QUEUED, JobStatusCode.CREATED].includes(
        job.code,
      )
    })
    this.failedJobs = allJobs.filter(
      (job) =>
        job.code === JobStatusCode.ERROR ||
        job.code === JobStatusCode.CANCELLED ||
        job.code === JobStatusCode.CANCELLING,
    )
    this.failedJobs = orderBy(this.failedJobs, ['updatedDate'], [SortOrders.Descending])
    this.completedJobs = allJobs.filter((job) => job.code === JobStatusCode.COMPLETE)
    this.completedJobs = orderBy(this.completedJobs, ['updatedDate'], [SortOrders.Descending])
    this.warningJobs = allJobs.filter((job) => job.code === JobStatusCode.WARNING)
    this.warningJobs = orderBy(this.warningJobs, ['updatedDate'], [SortOrders.Descending])

    // also fetch published sinter parts, if there are any
    const completedPublishJobs = this.completedJobs.filter((job) => job.jobType === JobType.PUBLISH)
    for (const job of completedPublishJobs) {
      await this.fetchSinterPartById(job.itemId)
    }

    await this.onBuildPlanItemsOrJobsChange()
  }

  @Watch('getIsLoading')
  onBuildPlanLoaded(value) {
    if (value) {
      return
    }
    this.navigationReady = true
    const itemByRouteName = this.items.find(
      (item) => item.routerName && item.routerName.toLowerCase() === this.$router.currentRoute.name.toLowerCase(),
    )
    if (itemByRouteName) {
      // Add Part Tool: Ignore waysToEnable to make possible opening of Add Part Tool on new build/sinter plan creation
      if (itemByRouteName.waysToEnable.length === 0 || itemByRouteName.routerName === RouterNames.EBP_AddPart) {
        this.setBuildPlanViewMode(itemByRouteName.viewMode)

        // Add Part Tool: Prevent view mode from changing when the single part has been loaded
        if (itemByRouteName.routerName !== RouterNames.EBP_AddPart) {
          this.changeViewMode(itemByRouteName.viewMode)
        }
      } else {
        // @ts-ignore
        this.$router.safePush({ name: RouterNames.EditBuildPlan })
      }
    }
  }

  @Watch('isReadOnly')
  onIsReadOnlyChanged(value: boolean) {
    if (!value) {
      return
    }

    const itemByRouteName = this.items.find(
      (item) => item.routerName && item.routerName.toLowerCase() === this.$router.currentRoute.name.toLowerCase(),
    )

    // Add Part Tool: Go back to Edit Build Plan if build plan was opened by manually entering Add Part route
    // This is possible because of condition in onBuildPlanLoaded method that enables opening Add Part Tool on
    // new build/sinter plan creation. That condition ignores waysToEnable and hence make this case possible
    if (
      itemByRouteName &&
      itemByRouteName.routerName === RouterNames.EBP_AddPart &&
      itemByRouteName.waysToEnable.length
    ) {
      this.closeTool()
    }
  }

  // If export of compensation files has been finished before the user confirmed tab should be closed
  // dismiss the dialog
  @Watch('isCompensationFileInProgress')
  compensationInProgressChanged() {
    if (!this.isCompensationFileInProgress && this.$refs.confirm.isShow) {
      this.$refs.confirm.confirm()
    }
  }

  onRouteChanged(to) {
    this.$refs.session.clearSession()

    const itemByRouteName = this.items.find(
      (item) => item.routerName && item.routerName.toLowerCase() === to.name.toLowerCase(),
    )
    if (itemByRouteName && itemByRouteName.waysToEnable.length === 0) {
      this.setBuildPlanViewMode(itemByRouteName.viewMode)
      this.changeViewMode(itemByRouteName.viewMode)

      if (
        itemByRouteName.viewMode === ViewModeTypes.Support ||
        itemByRouteName.viewMode === ViewModeTypes.Marking ||
        (itemByRouteName.viewMode === ViewModeTypes.SimulationCompensation &&
          this.getSelectedBuildPlanExistingSimCompJobs.length !== 0)
      ) {
        this.$refs.session.prolongSession()
      }
    } else {
      // throttling logic moved here to disable re-entering certain tools
      // for 10 seconds after they're quit and make sure it would be applied either tool is closed
      // or user clicks Back button in the browser
      this.setThrottlingForSocketBasedTools()
      this.setBuildPlanViewMode(null)
      this.changeViewMode(ViewModeTypes.Layout)
    }
  }

  async onAlignSliceAndPublishOptionClick(item: BuildPlanTool) {
    await this.clickOnMenuItem(item, SliceAlignment.Bottom)
  }

  async clickOnMenuItem(item: BuildPlanTool, alignOptionUsed = SliceAlignment.None) {
    if (item.waysToEnable.length || !this.isToolAvailable(item)) {
      return
    }

    // locking upstream build plan item modifying tools when jobs are detected that
    // put the build plan in read-only mode
    // TODO: add conditions for the following tools when they are (fully) implemented
    // label, remove part, update part version
    switch (item.key) {
      case ToolNames.SUPPORT:
      case ToolNames.ORIENT:
        const bpItem = this.getBpItemFromFirstSelected()
        if (bpItem.visibility === Visibility.Hidden) {
          // Display the selected part as opaque but do not change Visibility settings ob bpItem.
          this.setBpItemVisibility({ bpItem, makeVisible: true, showAsTransparent: false })
        }
      case ToolNames.SIMULATE:
      case ToolNames.CONSTRAIN:
      case ToolNames.ARRANGE:
        // these tools are *read-only* if
        // a job is running, or simulation or slice results exist
        this.checkBuildPlanReadOnly(item)
        break
      case ToolNames.LABEL:
        // this tool is *read-only* if a job is running or a completed slicing job exists
        this.checkBuildPlanReadOnly(item, false, true)
        break
      case ToolNames.PRINT_ORDER:
        break
    }

    // if the tool is enabled we also need to check
    // for appropriate error insights and warn the user
    const shouldProceed = await this.checkForErrorInsights(item)
    if (shouldProceed) {
      return
    }

    if (item.key === ToolNames.SLICE_PUBLISH) {
      this.enableClearanceTool({
        isEnabled: false,
        restoreSelectionManagerState: true,
      })
      this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_INFO, this.$t('slicePublish'), this.$t('preparing'))
      this.creatingJob()
      if (this.getRequiresLabelSetUpdates && !this.isReadOnly) {
        createUpdateProcessComplete()
        this.startLabelsUpdate()
        this.setWatchToolReady(true)
        await isUpdated
      }
      buildPlans.createSliceJob(this.getBuildPlan.id, alignOptionUsed).then(async () => {
        this.jobCreated()
        const buildPlan = await this.getBuildPlanById(this.getBuildPlan.id)
        this.setBuildPlan(buildPlan)
      })
      return
    }

    if (item.key === ToolNames.SELECT_PRINT_ORDER) {
      return
    }

    if (item.key === ToolNames.PUBLISH) {
      const buildPlanName = this.getBuildPlan.name
      try {
        this.addWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_INFO, buildPlanName, this.$t('publishing'))
        const response = await this.publishBuildPlan()
        await this.pollImportJobs(response)
      } catch (error) {
        this.removeWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_INFO, buildPlanName, this.$t('publishing'))
        const msg = this.$i18n.t('publishTaskFailed') as string
        messageService.showErrorMessage(`${msg} ${error.message}`)
      }
    }

    if (item.routerName) {
      // @ts-ignore
      this.$router.safePush({ name: item.routerName })
    } else {
      if (item.setViewMode) {
        this.setBuildPlanViewMode(item.viewMode)
        this.changeViewMode(item.viewMode)
      }
    }
  }

  async checkForErrorInsights(item) {
    let result = false
    let errorInsights = []
    switch (item.key) {
      case ToolNames.SIMULATE:
        if (this.getSelectedBuildPlanFinalizingJobs.length === 0) {
          errorInsights = this.insights.filter((insight) => {
            return (
              [ToolNames.LAYOUT, ToolNames.SUPPORT, ToolNames.STABILITY].includes(insight.tool) &&
              insight.severity === InsightsSeverity.Error
            )
          })
        }
        break
      case ToolNames.SLICE_PUBLISH:
        errorInsights = this.insights.filter((insight) => {
          return (
            [ToolNames.LAYOUT, ToolNames.SUPPORT, ToolNames.LABEL].includes(insight.tool) &&
            insight.severity === InsightsSeverity.Error
          )
        })
        break
      case ToolNames.PRINT_ORDER:
        errorInsights = this.insights.filter((insight) => {
          return insight.tool === ToolNames.SLICE && insight.severity === InsightsSeverity.Error
        })
        break
    }

    if (errorInsights.length && !this.getSelectedBuildPlanSimCompJobs.length) {
      result = await this.$refs.confirm.open(this.$t('warning'), this.$t('errorInsightsWarning'))
    }

    return result
  }

  isToolActive(tool: BuildPlanTool): boolean {
    return this.getBuildPlanViewMode && this.getBuildPlanViewMode === tool.viewMode
  }

  isToolAvailable(tool: BuildPlanTool): boolean {
    if (!this.navigationReady) {
      return false
    }

    if (this.activeTool && this.activeTool.sidebarStyling && this.activeTool.sidebarStyling.keepList) {
      return this.activeTool.key === tool.key
    }

    if (tool.waysToEnable.length > 0) {
      return false
    }

    return true
  }

  setSelectorItems(item: BuildPlanTool) {
    this.selectorItems = []
    switch (item.key) {
      case ToolNames.SELECT_PRINT_ORDER:
        let printJobs = this.getSelectedBuildPlanJobs.filter((job) => {
          return job.jobType === JobType.PRINT && !PRINT_JOB_EXCLUDE_STATUS_SET.has(job.code)
        })
        if (printJobs && printJobs.length > 0) {
          printJobs = printJobs.sort((a: IJob, b: IJob) => {
            return a.number - b.number
          })
          for (let idx = 1; idx <= printJobs.length; idx += 1) {
            this.selectorItems.push(printJobs[idx - 1].jobName)
          }
        }

        break
    }
  }

  setMenuItems(item: BuildPlanTool) {
    this.menuItems = []
    switch (item.key) {
      case ToolNames.SELECT_PUBLISHED_PARTS:
        // get all completed publish jobs
        const completedPublishJobs = this.completedJobs.filter((job) => job.jobType === JobType.PUBLISH)
        const publishedBuildPlanItemIds = []
        for (const job of completedPublishJobs) {
          if (job.jobName) {
            const jobNameJSON = JSON.parse(job.jobName)
            publishedBuildPlanItemIds.push(jobNameJSON.buildPlanItemId)
          }
        }
        const failedPublishJobs = this.failedJobs.filter((job) => job.jobType === JobType.PUBLISH)
        const failedAndCompletedPublishJobs = [...failedPublishJobs, ...completedPublishJobs]
        failedAndCompletedPublishJobs.map((job) => {
          let sinterPartName = job.itemId
          let publishedBuildPlanItemId
          if (job.jobName) {
            const jobNameJSON = JSON.parse(job.jobName)
            sinterPartName = jobNameJSON.name
            publishedBuildPlanItemId = jobNameJSON.buildPlanItemId
            // filter out any incomplete (failed, cancelled) publish jobs for the build plan items
            // that have already been published successfully
            if (
              job.code !== JobStatusCode.COMPLETE &&
              job.code !== JobStatusCode.WARNING &&
              publishedBuildPlanItemIds.includes(publishedBuildPlanItemId)
            ) {
              return
            }
          }
          const sinterItemId = job.itemId
          this.menuItems.push({
            name: sinterPartName,
            value: sinterItemId,
            code: job.code,
            description: job.description,
            buildPlanItemId: publishedBuildPlanItemId,
          })
        })
        if (completedPublishJobs.length === 1) {
          item.placeholder = 'onePublishedPart'
        } else if (completedPublishJobs.length > 1) {
          item.placeholder = 'severalPublishedParts'
        }

        break
    }
  }

  @Watch('isTransferToolBusy')
  onTransferToolBusyChange(isBusy: boolean) {
    const transferTool = this.items.find((item) => item.key === ToolNames.TRANSFER_PROPS)
    if (!transferTool) {
      return
    }

    if (isBusy) {
      this.addUniqueWayToEnable(transferTool, WAYS_TO_ENABLE_TOOLS.TOOL_UNAVAILABLE)
    } else {
      this.removeWayToEnable(transferTool, WAYS_TO_ENABLE_TOOLS.TOOL_UNAVAILABLE)
    }
  }

  @Watch('isLabelRestoreInProgress')
  onLabelRestoreInProgressCahnge(isInProgress: boolean) {
    const labelTool = this.items.find((item) => item.key === ToolNames.LABEL)
    if (!labelTool) {
      return
    }

    if (isInProgress) {
      this.addUniqueWayToEnable(labelTool, WAYS_TO_ENABLE_TOOLS.RESTORE_LABELS_IN_PROGRESS)
    } else {
      this.removeWayToEnable(labelTool, WAYS_TO_ENABLE_TOOLS.RESTORE_LABELS_IN_PROGRESS)
    }
  }

  lockToolOrSimContentForViewer(item: BuildPlanTool) {
    const complitedSimJobs = this.filterJobsByJobTypes(this.completedJobs, item.jobTypes)
    const failedSimJobs = this.filterJobsByJobTypes(this.failedJobs, item.jobTypes)
    const completedJobsWithoutMesh = complitedSimJobs.filter((job) => job.jobType !== JobType.MESH)

    let lockOnlyContent = false
    if (completedJobsWithoutMesh.length > 0) {
      if (failedSimJobs.length > 0) {
        lockOnlyContent = failedSimJobs[0].updatedDate < complitedSimJobs[0].updatedDate
      } else {
        lockOnlyContent = true
      }
    }

    if (!lockOnlyContent) {
      this.addWayToEnable(item, this.lockedForViewerWayToEnable)
    }
  }

  getSinteredPartsLabel(item: BuildPlanTool) {
    const completedMenuItems = this.menuItems.filter((i) => i.code === JobStatusCode.COMPLETE)
    return this.$t(item.placeholder, { number: completedMenuItems.length })
  }

  getButtonStyle(item: BuildPlanTool) {
    if (!item.button) {
      return
    }

    switch (item.key) {
      case ToolNames.PRINT_ORDER:
        return PRINT_ORDER_BUTTON_STYLE
      default:
        return DEFAULT_BUTTON_STYLE
    }
  }

  getRunningJobsForBadgesByJobTypes(jobTypes: JobType[]) {
    return this.filterJobsByJobTypes(this.runningJobs, jobTypes)
  }

  getFailedJobsForBadgesByJobTypes(jobTypes: JobType[]) {
    return this.filterJobsByJobTypes(this.failedJobs, jobTypes)
  }

  getCompletedJobsForBadgesByJobTypes(jobTypes: JobType[]) {
    return this.filterJobsByJobTypes(this.completedJobs, jobTypes)
  }

  getWarningJobsForBadgesByJobTypes(jobTypes: JobType[]) {
    return this.filterJobsByJobTypes(this.warningJobs, jobTypes)
  }

  filterJobsByJobTypes(jobs: IJob[], jobTypes: JobType[]) {
    const filteredJobs = jobs.filter((job) => {
      if (jobTypes.includes(job.jobType)) {
        if (job.jobType === JobType.PRINT || job.jobType === JobType.MARK) {
          if (PRINT_JOB_EXCLUDE_STATUS_SET.has(job.code)) {
            return false
          }
        }
        return true
      }
    })
    return filteredJobs
  }

  printOrderSelected(printOrderName) {
    const printJobs = this.getSelectedBuildPlanJobs.filter((job) => {
      return job.jobType === JobType.PRINT
    })
    if (printJobs && printJobs.length > 0) {
      const printOrder = printJobs.find((printJob) => printJob.jobName === printOrderName)

      const route = {
        name: RouterNames.PreviewPrintOrder,
        params: {
          id: printOrder.id,
        },
      }

      this.setPrintOrderNavigatedFrom(RouterNames.EditBuildPlan)

      // @ts-ignore
      this.$router.safePush(route)
    }
  }

  isJobComplete(code) {
    return code === JobStatusCode.COMPLETE || code === JobStatusCode.WARNING
  }

  hoverIntoPart(buildPlanItemId) {
    this.toggleHighlight({ buildPlanItemId, highlight: true })
  }

  hoverOutOfPart(buildPlanItemId, meshId) {
    this.toggleHighlight({ buildPlanItemId, highlight: false })
  }

  confirmSocketDisconnectModal() {
    this.closeTool()
    this.socketDisconnectModalVisibility = false
  }

  // Fix for clicking outside Slice Publish option menu,
  // for Binder Jet click is handled separately
  clickOnSidebarItemAllowed(item: BuildPlanTool) {
    return item.key !== ToolNames.SLICE_PUBLISH || this.getBuildPlan.modality !== PrintingTypes.BinderJet
  }

  onSaveToolChanges() {
    this.confirmationDialogShown = false
    this.clickOk(false).then(() => this.$root.$emit('toolExitedWithAction', ExitToolAction.SaveData))
  }

  labelToolSaveDisabled() {
    return (
      this.isLabelToolActive() &&
      (this.getIsOkDisabled || !this.getLabelToolIsValid || this.isInDirtyState || !this.hasDataToSave)
    )
  }

  isLabelToolActive() {
    return this.activeTool && this.activeTool.key === ToolNames.LABEL
  }

  onDiscardToolChanges() {
    this.confirmationDialogShown = false

    const cancelHandler = this.$refs.toolComponent && (this.$refs.toolComponent as any).clickCancel
    if (cancelHandler) {
      Promise.resolve(cancelHandler()).then(() => this.$root.$emit('toolExitedWithAction', ExitToolAction.DiscardData))
    } else {
      this.$root.$emit('toolExitedWithAction', ExitToolAction.DiscardData)
    }
  }

  onContinueToolEditing() {
    this.confirmationDialogShown = false
    this.$root.$emit('toolExitedWithAction', ExitToolAction.DoNotExit)
  }

  private showToolConfirmationDialog() {
    this.confirmationDialogShown = true
  }

  private setThrottlingForSocketBasedTools() {
    if (this.activeTool) {
      const item = this.activeTool
      switch (item.key) {
        case ToolNames.SUPPORT:
          if (this.supportThrottle) {
            clearTimeout(this.supportThrottle)
          }

          item.isPreparing = true
          this.setSupportToolPreparing(true)
          this.supportThrottle = setTimeout(() => {
            item.isPreparing = false
            this.setSupportToolPreparing(false)
            this.removeWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_RECONNECT)
          }, SOCKET_TOOL_ACTIVATION_TIMEOUT)
          break

        case ToolNames.SIMULATE:
          if (this.simulateThrottle) {
            clearTimeout(this.simulateThrottle)
          }

          if (this.getVisualizationServiceStarted) {
            item.isPreparing = true
            this.addUniqueWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_RECONNECT)
            this.simulateThrottle = setTimeout(() => {
              item.isPreparing = false
              this.removeWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_RECONNECT)
              this.setVisualizationServiceStarted(false)
            }, SOCKET_TOOL_ACTIVATION_TIMEOUT)
          }
          break

        case ToolNames.LABEL:
          if (this.labelThrottle) {
            clearTimeout(this.labelThrottle)
          }

          item.isPreparing = true
          this.setLabelToolPreparing(true)
          this.labelThrottle = setTimeout(() => {
            item.isPreparing = false
            this.setLabelToolPreparing(false)
            this.removeWayToEnable(item, WAYS_TO_ENABLE_TOOLS.TOOL_RECONNECT)
          }, SOCKET_TOOL_ACTIVATION_TIMEOUT)
          break
      }
    }
  }

  private startLabelsUpdate() {
    this.$refs.updateLabels.updateLabels()
  }
}
