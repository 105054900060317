
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ShareItemModal from '@/components/layout/FileExplorer/ShareItemModal.vue'
import ItemListRowBadges from '@/components/layout/FileExplorer/Table/ItemListRowBadges.vue'
import ItemContextMenu from '@/components/layout/FileExplorer/Table/ItemContexMenu.vue'
import PrintOrderMixin from '@/components/layout/FileExplorer/Table/printOrder/PrintOrderMixin'
import MenuSection from '@/components/controls/FileExplorer/MenuSection.vue'
import VariantIsLockedModal from '@/components/layout/FileExplorer/VariantIsLockedModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import AlertModal from '@/components/modals/AlertModal.vue'
import FavoritesListRow from '../Favorites/FavoritesListRow.vue'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { isOfType } from '@/utils/common'
import { RouterNames } from '@/router'
import Menu from '@/components/controls/Common/Menu.vue'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { SearchResultSnapshot, SearchResultWayOfFinding } from '@/types/FileExplorer/SearchResultSnapshot'
import StoresNamespaces from '@/store/namespaces'
import { transformPrintOrderIdToItemId } from '@/utils/fileExplorerItem/fileExplorerItemUtils'
import RowItemMenu from '../RowItemMenu.vue'
import VariantIcon from '@/components/icons/VariantIcon.vue'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component({
  components: {
    MenuSection,
    ItemListRowBadges,
    ShareItemModal,
    ItemContextMenu,
    VariantIsLockedModal,
    ConfirmModal,
    AlertModal,
    Menu,
    RowItemMenu,
    VariantIcon,
  },
})
export default class SearchListRow extends mixins(FavoritesListRow, PrintOrderMixin) {
  @fileExplorerStore.Getter getSearchResultSnapshot: SearchResultSnapshot[]

  @buildPlansStore.Mutation setPrintOrderLabelSearch: (input: string) => void
  @buildPlansStore.Mutation setPrintOrderNavigatedFrom: (from: string) => void

  @Prop() setupContextMenu: (item: FileExplorerItem | PrintOrder, x: number, y: number) => Promise<void>
  @Prop() onMoveOrCopy: Function

  get itemName(): string {
    return `${this.item.name} (${this.isSearchItemPrintOrder ? 'Print Order' : this.itemType})`
  }

  get isSearchItemPrintOrder(): boolean {
    return isOfType<PrintOrder>(this.item, 'site')
  }

  get isBuildOrSinterPlan(): boolean {
    return this.item.itemType === ItemType.BuildPlan || this.isSinterVariant
  }

  get isSinterVariant(): boolean {
    const item = this.item as FileExplorerItem
    return (
      (item.itemType === ItemType.BuildPlan && item.subType === ItemSubType.SinterPlan) ||
      item.itemType === ItemType.IbcPlan
    )
  }

  async getSearchContextMenuItems() {
    if (this.isSearchItemPrintOrder) {
      this.contextMenuItems = this.getPrintOrderContextMenu(this.item as PrintOrder)
    } else {
      await this.getContextMenuItems(this.onMoveOrCopy)
    }
  }

  onPrintOrderItemPathClick() {
    const route = {
      name: RouterNames.EditBuildPlan,
      params: {
        id: this.item.parentId,
      },
    }

    // @ts-ignore
    this.$router.safePush(route)
  }

  // If print order was found by label search it should be opened
  // and search query should be entered as label search
  onPrintOrderItemNameClick() {
    const printOrderSnapshot = this.getSearchResultSnapshot.find(
      (snapshot) => transformPrintOrderIdToItemId(snapshot.id) === this.item.id,
    )
    const foundByLabel = printOrderSnapshot
      ? printOrderSnapshot.wayOfFinding === SearchResultWayOfFinding.ByLabel
      : false

    if (foundByLabel) {
      this.setPrintOrderLabelSearch(this.$route.params.query)
    } else {
      this.setPrintOrderLabelSearch('')
    }
    this.setPrintOrderNavigatedFrom(RouterNames.FE_Search)

    const route = {
      name: RouterNames.PreviewPrintOrder,
      params: {
        id: this.item.id,
      },
    }
    // @ts-ignore
    this.$router.safePush(route)
  }

  get fullParentPathLabel(): string {
    const item = this.item as PrintOrder
    return `${this.parentPathLabel}/${item.itemName}`
  }

  getPrintOrderVersion() {
    const item = this.item as PrintOrder
    const version = +item.parentVersion
    if (Number.isNaN(version)) {
      return item.parentVersion
    }

    return `${this.$t('variant')} ${version}`
  }

  async onTrashModalConfirm() {
    const ids = this.getMoveToTrashItems.map((item) => item.id)
    await this.confirmTrashModal(this.getMoveToTrashItems)
    this.$emit('trash', ids)
  }
}
