import { SelectionUnit } from '@/types/BuildPlans/IBuildPlan'
import { InstancedMesh, TransformNode } from '@babylonjs/core/Meshes'
import { MultiItemFaceColoringShader } from '@/visualization/rendering/MeshShader'
import { ISelectableNode } from '@/visualization/rendering/SelectionManager'
import { StandardMaterial } from '@babylonjs/core/Materials'
import { Color3 } from '@babylonjs/core/Maths/math.color'
import { RenderScene } from '@/visualization/render-scene'
import { SelectedItem } from '@/types/OptionalMultiItemCollector/SelectedItem'
import { DEFAULT_MATERIAL_NAME, PRIMARY_CYAN, PRIMARY_SELECTION, SHADER_HIGHLIGHT_MATERIAL_NAME } from '@/constants'
import { IComponentMetadata } from '@/visualization/types/SceneItemMetadata'
import { CollectorWithOptionalFilter } from '@/types/OptionalMultiItemCollector/MultiItemCollector'

export interface CollectorItem {
  sceneItem: TransformNode
  itemMetadata: SelectedItem
  selectionColor: Color3
  highlightColor: Color3
  initialColor: Color3
  initialMaterial: StandardMaterial
  isSelected: boolean
  isHighlighted: boolean
  renderScene: RenderScene

  highlight(showHighlight: boolean)
  select()
  deselect(silent?: boolean)
  isEqual(collectorItem: CollectorItem)
  dispose()
  restoreOriginalMaterial()
  setInstancesColors()
}

export class BodyItem implements CollectorItem {
  sceneItem
  itemMetadata: SelectedItem
  selectionColor: Color3 = PRIMARY_SELECTION
  highlightColor: Color3 = PRIMARY_CYAN
  initialColor: Color3
  isSelected: boolean
  isHighlighted: boolean
  renderScene: RenderScene
  highlightMaterial: StandardMaterial
  regularMaterial: StandardMaterial
  initialMaterial: StandardMaterial
  instanceIndex: number

  constructor(item: ISelectableNode, metadata: SelectedItem, selectionColor: Color3, renderScene: RenderScene) {
    this.sceneItem = item.body as InstancedMesh
    this.instanceIndex = this.sceneItem.sourceMesh.instances.findIndex((m) => m === this.sceneItem)
    this.renderScene = renderScene
    this.itemMetadata = metadata
    this.initialColor = this.sceneItem.instancedBuffers.color
    this.selectionColor = selectionColor.scale(0.5)
    this.highlightMaterial = this.renderScene
      .getScene()
      .getMaterialByName(SHADER_HIGHLIGHT_MATERIAL_NAME) as StandardMaterial
    this.initialMaterial = this.renderScene.getScene().getMaterialByName(DEFAULT_MATERIAL_NAME) as StandardMaterial
    this.setMaterial()
    this.setInstancesColors()
  }

  highlight(showHighlight: boolean) {
    this.setMaterial()

    if (showHighlight) {
      this.addHighlightedFaces()
    } else {
      this.removeHighlightedFaces()
    }

    this.sceneItem.sourceMesh.faceMaterial.setHighlightedFacesId(
      this.sceneItem.sourceMesh.metadata.collectorHighlightedFacesIds,
    )

    if (!showHighlight) {
      this.restoreOriginalMaterial()
    }

    this.isHighlighted = showHighlight
  }

  select() {
    this.setMaterial()
    this.addSelectedFaces()
    ;(this.sceneItem as any).sourceMesh.faceMaterial.setSelectedFacesId(
      this.sceneItem.sourceMesh.metadata.collectorFacesIds,
    )

    this.isSelected = true
  }

  deselect() {
    this.removeSelectedFaces()
    if (!(this.sceneItem as any).sourceMesh.faceMaterial) {
      return
    }

    ;(this.sceneItem as any).sourceMesh.faceMaterial.setSelectedFacesId(
      this.sceneItem.sourceMesh.metadata.collectorFacesIds,
    )

    this.restoreOriginalMaterial()
    this.isSelected = false
  }

  isEqual(collectorItem: CollectorItem) {
    return this.sceneItem === collectorItem.sceneItem
  }

  dispose() {
    this.deselect()
    this.restoreOriginalMaterial()
  }

  restoreOriginalMaterial() {
    let isSomethingInCollectorMetadata = false
    this.sceneItem.sourceMesh.metadata.collectorFacesIds.forEach((faces) => {
      if (faces.length) {
        isSomethingInCollectorMetadata = true
      }
    })

    if (this.sceneItem.sourceMesh.faceMaterial && !isSomethingInCollectorMetadata) {
      this.sceneItem.sourceMesh.faceMaterial.dispose()
      this.sceneItem.sourceMesh.faceMaterial = null
      this.sceneItem.sourceMesh.material = this.sceneItem.sourceMesh.metadata.originalMaterial = this.initialMaterial
    }
  }

  setInstancesColors() {
    const instances = []
    const colors = []

    this.sceneItem.sourceMesh.instances.forEach((instance, index) => {
      let colorIndex = colors.findIndex((color) => color === instance.instancedBuffers.color.scale(0.5))
      if (colorIndex === -1) {
        colorIndex = colors.push(instance.instancedBuffers.color.scale(0.5)) - 1
      }

      instances.push(index, colorIndex)
    })
    ;(this.sceneItem as any).sourceMesh.faceMaterial.setInstancesData(instances, colors)
  }

  private setMaterial() {
    if (
      !this.sceneItem.sourceMesh.faceMaterial ||
      !(this.sceneItem.sourceMesh.faceMaterial instanceof MultiItemFaceColoringShader)
    ) {
      this.sceneItem.sourceMesh.faceMaterial = new MultiItemFaceColoringShader(this.renderScene, this.highlightMaterial)
      this.sceneItem.sourceMesh.faceMaterial.shaderMaterial.isReady()

      this.sceneItem.sourceMesh.material = this.sceneItem.sourceMesh.metadata.originalMaterial = (
        this.sceneItem.sourceMesh as any
      ).faceMaterial.shaderMaterial
    }
  }

  private addSelectedFaces() {
    const existing = this.sceneItem.sourceMesh.metadata.collectorFacesIds.get(this.instanceIndex) || []
    existing.push(
      ...this.sceneItem.metadata.faces.map((face) => ({ faceId: face.id, selectionColor: this.selectionColor })),
    )

    this.sceneItem.sourceMesh.metadata.collectorFacesIds.set(this.instanceIndex, existing)
  }

  private removeSelectedFaces() {
    this.sceneItem.sourceMesh.metadata.collectorFacesIds.set(this.instanceIndex, [])
  }

  private addHighlightedFaces() {
    const existing = this.sceneItem.sourceMesh.metadata.collectorHighlightedFacesIds.get(this.instanceIndex) || []
    existing.push(...this.sceneItem.metadata.faces.map((face) => face.id))

    this.sceneItem.sourceMesh.metadata.collectorHighlightedFacesIds.set(this.instanceIndex, existing)
  }

  private removeHighlightedFaces() {
    const existing = this.sceneItem.sourceMesh.metadata.collectorHighlightedFacesIds.get(this.instanceIndex) || []
    const faceIds = this.sceneItem.metadata.faces.map((face) => face.id)
    const filtered = existing.filter((faceId) => !faceIds.includes(faceId))

    this.sceneItem.sourceMesh.metadata.collectorHighlightedFacesIds.set(this.instanceIndex, filtered)
  }
}

export class FaceItem implements CollectorItem {
  sceneItem
  itemMetadata: SelectedItem
  isSelected: boolean
  isHighlighted: boolean
  faceMaterial: MultiItemFaceColoringShader
  renderScene: RenderScene
  selectionColor: Color3 = PRIMARY_SELECTION
  highlightColor: Color3
  initialColor: Color3
  id: string
  faceId: number
  instanceIndex: number
  highlightMaterial: StandardMaterial
  regularMaterial: StandardMaterial
  initialMaterial: StandardMaterial

  constructor(item: ISelectableNode, metadata: SelectedItem, selectionColor: Color3, renderScene: RenderScene) {
    this.faceId = item.face.id
    this.renderScene = renderScene
    this.highlightMaterial = this.renderScene
      .getScene()
      .getMaterialByName(SHADER_HIGHLIGHT_MATERIAL_NAME) as StandardMaterial

    this.sceneItem = item.body
    this.instanceIndex = this.sceneItem.sourceMesh.instances.findIndex((m) => m === this.sceneItem)
    this.selectionColor = selectionColor
    this.itemMetadata = metadata
    this.initialColor = item.body.instancedBuffers.color
    this.setMaterial()
    this.setInstancesColors()
  }

  highlight(showHighlight: boolean) {
    this.setMaterial()
    if (showHighlight) {
      this.addHighlightedFace()
    } else {
      this.removeHighlightedFace()
    }

    this.sceneItem.sourceMesh.faceMaterial.setHighlightedFacesId(
      this.sceneItem.sourceMesh.metadata.collectorHighlightedFacesIds,
    )
    if (!showHighlight) {
      this.restoreOriginalMaterial()
    }

    this.isHighlighted = showHighlight
  }

  select() {
    this.setMaterial()
    this.addSelectedFace()
    ;(this.sceneItem as any).sourceMesh.faceMaterial.setSelectedFacesId(
      this.sceneItem.sourceMesh.metadata.collectorFacesIds,
    )

    this.isSelected = true
  }

  deselect() {
    this.removeSelectedFace()
    // nothing to set due to disposed material
    if (!(this.sceneItem as any).sourceMesh.faceMaterial) {
      return
    }

    ;(this.sceneItem as any).sourceMesh.faceMaterial.setSelectedFacesId(
      this.sceneItem.sourceMesh.metadata.collectorFacesIds,
    )

    this.restoreOriginalMaterial()
    this.isSelected = false
  }

  isEqual(collectorItem: FaceItem) {
    return (
      Object.keys(this.itemMetadata).every((key) => this.itemMetadata[key] === collectorItem.itemMetadata[key]) &&
      this.faceId === collectorItem.faceId
    )
  }

  dispose() {
    this.deselect()
    this.restoreOriginalMaterial()
  }

  restoreOriginalMaterial() {
    let isSomethingInCollectorMetadata = false
    this.sceneItem.sourceMesh.metadata.collectorFacesIds.forEach((faces) => {
      if (faces.length) {
        isSomethingInCollectorMetadata = true
      }
    })

    if (this.sceneItem.sourceMesh.faceMaterial && !isSomethingInCollectorMetadata) {
      this.sceneItem.sourceMesh.faceMaterial.dispose()
      this.sceneItem.sourceMesh.faceMaterial = null
      this.sceneItem.sourceMesh.material = this.sceneItem.sourceMesh.metadata.originalMaterial = this.initialMaterial
    }
  }

  setInstancesColors() {
    const instances = []
    const colors = []

    this.sceneItem.sourceMesh.instances.forEach((instance, index) => {
      let colorIndex = colors.findIndex((color) => color === instance.instancedBuffers.color.scale(0.5))
      if (colorIndex === -1) {
        colorIndex = colors.push(instance.instancedBuffers.color.scale(0.5)) - 1
      }

      instances.push(index, colorIndex)
    })
    ;(this.sceneItem as any).sourceMesh.faceMaterial.setInstancesData(instances, colors)
  }

  private addSelectedFace() {
    const existing = this.sceneItem.sourceMesh.metadata.collectorFacesIds.get(this.instanceIndex) || []
    if (!existing.find((existingFace) => existingFace.faceId === this.faceId)) {
      existing.push({ faceId: this.faceId, selectionColor: this.selectionColor })
    }
    this.sceneItem.sourceMesh.metadata.collectorFacesIds.set(this.instanceIndex, existing)
  }

  private removeSelectedFace() {
    const existing = this.sceneItem.sourceMesh.metadata.collectorFacesIds.get(this.instanceIndex) || []
    const filtered = existing.filter((faceData) => faceData.faceId !== this.faceId)
    this.sceneItem.sourceMesh.metadata.collectorFacesIds.set(this.instanceIndex, filtered)
  }

  private addHighlightedFace() {
    const existing = this.sceneItem.sourceMesh.metadata.collectorHighlightedFacesIds.get(this.instanceIndex) || []
    if (!existing.find((existingFaceId) => existingFaceId === this.faceId)) {
      existing.push(this.faceId)
    }
    this.sceneItem.sourceMesh.metadata.collectorHighlightedFacesIds.set(this.instanceIndex, existing)
  }

  private removeHighlightedFace() {
    const existing = this.sceneItem.sourceMesh.metadata.collectorHighlightedFacesIds.get(this.instanceIndex) || []
    const filtered = existing.filter((faceId) => faceId !== this.faceId)
    this.sceneItem.sourceMesh.metadata.collectorHighlightedFacesIds.set(this.instanceIndex, filtered)
  }

  private setMaterial() {
    if (
      !this.sceneItem.sourceMesh.faceMaterial ||
      !(this.sceneItem.sourceMesh.faceMaterial instanceof MultiItemFaceColoringShader)
    ) {
      this.sceneItem.sourceMesh.faceMaterial = new MultiItemFaceColoringShader(this.renderScene, this.highlightMaterial)
      this.sceneItem.sourceMesh.faceMaterial.shaderMaterial.isReady()

      this.sceneItem.sourceMesh.material = this.sceneItem.sourceMesh.metadata.originalMaterial = (
        this.sceneItem.sourceMesh as any
      ).faceMaterial.shaderMaterial
    }
  }
}

export class PartItem implements CollectorItem {
  sceneItem: TransformNode
  itemMetadata: SelectedItem
  selectionMaterial: StandardMaterial
  highlightMaterial: StandardMaterial
  initialMaterial: StandardMaterial
  highlightColor: Color3 = PRIMARY_CYAN
  selectionColor: Color3 = PRIMARY_SELECTION
  initialColor: Color3
  isSelected: boolean
  isHighlighted: boolean
  renderScene: RenderScene

  constructor(item: ISelectableNode, metadata: SelectedItem, selectionColor: Color3, renderScene: RenderScene) {
    this.renderScene = renderScene
    this.sceneItem = item.part
    this.itemMetadata = metadata
    this.initialColor = this.sceneItem.metadata.color.clone()
    this.selectionColor = selectionColor.scale(0.5)
    this.highlightMaterial = this.renderScene
      .getScene()
      .getMaterialByName(SHADER_HIGHLIGHT_MATERIAL_NAME) as StandardMaterial
    this.setMaterial()
    this.setInstancesColors()
  }

  highlight(showHighlight: boolean) {
    this.setMaterial()
    if (showHighlight) {
      this.addHighlightedFaces()
    } else {
      this.removeHighlightedFaces()
      this.restoreOriginalMaterial()
    }

    this.isHighlighted = showHighlight
  }

  select() {
    this.setMaterial()
    this.addSelectedFaces()
    this.isSelected = true
  }

  deselect() {
    this.removeSelectedFaces()
    this.restoreOriginalMaterial()
    this.isSelected = false
  }

  isEqual(collectorItem: CollectorItem) {
    return this.sceneItem === collectorItem.sceneItem
  }

  dispose() {
    this.deselect()
    this.restoreOriginalMaterial()
  }

  restoreOriginalMaterial() {
    const bodies = this.sceneItem.getChildMeshes().filter(this.renderScene.getMeshManager().isComponentMesh)
    bodies.forEach((body) => {
      const bodyItem = body as any
      let isSomethingInCollectorMetadata = false
      bodyItem.sourceMesh.metadata.collectorFacesIds.forEach((faces) => {
        if (faces.length) {
          isSomethingInCollectorMetadata = true
        }
      })

      if (bodyItem.sourceMesh.faceMaterial && !isSomethingInCollectorMetadata) {
        bodyItem.sourceMesh.faceMaterial.dispose()
        bodyItem.sourceMesh.faceMaterial = null
        bodyItem.sourceMesh.material = bodyItem.sourceMesh.metadata.originalMaterial = this.initialMaterial
      }
    })
  }

  setInstancesColors() {
    const bodies = this.sceneItem.getChildMeshes().filter(this.renderScene.getMeshManager().isComponentMesh)
    bodies.forEach((body) => {
      const bodyItem = body as any
      const instances = []
      const colors = []

      bodyItem.sourceMesh.instances.forEach((instance, index) => {
        let colorIndex = colors.findIndex((color) => color === instance.instancedBuffers.color.scale(0.5))
        if (colorIndex === -1) {
          colorIndex = colors.push(instance.instancedBuffers.color.scale(0.5)) - 1
        }

        instances.push(index, colorIndex)
      })

      bodyItem.sourceMesh.faceMaterial.setInstancesData(instances, colors)
    })
  }

  private setMaterial() {
    const bodies = this.sceneItem.getChildMeshes().filter(this.renderScene.getMeshManager().isComponentMesh)
    bodies.forEach((body) => {
      const bodyItem = body as any
      if (
        !bodyItem.sourceMesh.faceMaterial ||
        !(bodyItem.sourceMesh.faceMaterial instanceof MultiItemFaceColoringShader)
      ) {
        bodyItem.sourceMesh.faceMaterial = new MultiItemFaceColoringShader(this.renderScene, this.highlightMaterial)
        bodyItem.sourceMesh.faceMaterial.shaderMaterial.isReady()

        bodyItem.sourceMesh.metadata.originalMaterial = (bodyItem.sourceMesh as any).faceMaterial.shaderMaterial
        bodyItem.sourceMesh.material = bodyItem.sourceMesh.metadata.originalMaterial
      }
    })
  }

  private addSelectedFaces() {
    const bodies = this.sceneItem.getChildMeshes().filter(this.renderScene.getMeshManager().isComponentMesh)
    bodies.forEach((body) => {
      const bodyItem = body as InstancedMesh
      const existing =
        bodyItem.sourceMesh.metadata.collectorFacesIds.get(bodyItem.instancedBuffers.collectorInstanceId.id) || []
      existing.push(
        ...bodyItem.metadata.faces.map((face) => ({ faceId: face.id, selectionColor: this.selectionColor })),
      )

      bodyItem.sourceMesh.metadata.collectorFacesIds.set(bodyItem.instancedBuffers.collectorInstanceId.id, existing)
      ;(bodyItem as any).sourceMesh.faceMaterial.setSelectedFacesId(bodyItem.sourceMesh.metadata.collectorFacesIds)
    })
  }

  private removeSelectedFaces() {
    const bodies = this.sceneItem.getChildMeshes().filter(this.renderScene.getMeshManager().isComponentMesh)
    bodies.forEach((body) => {
      const bodyItem = body as InstancedMesh
      // there is no face material
      if (!(bodyItem as any).sourceMesh.faceMaterial) {
        return
      }

      bodyItem.sourceMesh.metadata.collectorFacesIds.set(bodyItem.instancedBuffers.collectorInstanceId.id, [])
      ;(bodyItem as any).sourceMesh.faceMaterial.setSelectedFacesId(bodyItem.sourceMesh.metadata.collectorFacesIds)
    })
  }

  private addHighlightedFaces() {
    const bodies = this.sceneItem.getChildMeshes().filter(this.renderScene.getMeshManager().isComponentMesh)
    bodies.forEach((body) => {
      const bodyItem = body as InstancedMesh
      const existing =
        bodyItem.sourceMesh.metadata.collectorHighlightedFacesIds.get(
          bodyItem.instancedBuffers.collectorInstanceId.id,
        ) || []

      existing.push(...bodyItem.metadata.faces.map((face) => face.id))
      bodyItem.sourceMesh.metadata.collectorHighlightedFacesIds.set(
        bodyItem.instancedBuffers.collectorInstanceId.id,
        existing,
      )
      ;(bodyItem as any).sourceMesh.faceMaterial.setHighlightedFacesId(
        bodyItem.sourceMesh.metadata.collectorHighlightedFacesIds,
      )
    })
  }

  private removeHighlightedFaces() {
    const bodies = this.sceneItem.getChildMeshes().filter(this.renderScene.getMeshManager().isComponentMesh)
    bodies.forEach((body) => {
      const bodyItem = body as InstancedMesh
      const existing =
        bodyItem.sourceMesh.metadata.collectorHighlightedFacesIds.get(
          bodyItem.instancedBuffers.collectorInstanceId.id,
        ) || []
      const faceIds = bodyItem.metadata.faces.map((face) => face.id)
      const filtered = existing.filter((faceId) => !faceIds.includes(faceId))
      bodyItem.sourceMesh.metadata.collectorHighlightedFacesIds.set(
        bodyItem.instancedBuffers.collectorInstanceId.id,
        filtered,
      )
      ;(bodyItem as any).sourceMesh.faceMaterial.setHighlightedFacesId(
        bodyItem.sourceMesh.metadata.collectorHighlightedFacesIds,
      )
    })
  }
}

export class Collector {
  id: string
  type: SelectionUnit
  items: CollectorItem[] = []
  selectionColor: Color3
  highlightedItems: CollectorItem[] = []
  filter: (item, index, array) => boolean
  renderScene: RenderScene
  collectorFilters: Map<SelectionUnit, (item: CollectorItem) => boolean>

  constructor(collectorData: CollectorWithOptionalFilter, renderScene: RenderScene) {
    this.type = collectorData.type
    this.renderScene = renderScene
    this.selectionColor = collectorData.selectionColor
    this.id = collectorData.id
    // init correct items
    this.collectorFilters = this.renderScene.getCollectorManager().getCollectorFilters
    this.items = this.itemsFactory(collectorData.items)
    this.items.forEach((item) => item.select())
    this.filter = collectorData.filter
  }

  get setCollectorItems() {
    return this.renderScene.getCollectorManager().setCollectorItems
  }

  select(pickedObjects: ISelectableNode[], attach: boolean = false, silent: boolean = false) {
    let itemsToAdd = this.createItemsFromPickedObjects(pickedObjects)
    if (this.collectorFilters.get(this.type)) {
      itemsToAdd = itemsToAdd.filter(this.collectorFilters.get(this.type))
    }

    if (!itemsToAdd.length) {
      return
    }

    if (!attach) {
      this.deselect()
    }

    const itemsToReuse = []
    const itemsToDeselect = []

    itemsToAdd = itemsToAdd.filter((item) => {
      const highlighted = this.highlightedItems.find((highlightedItem) => highlightedItem.isEqual(item))
      const selected = this.items.find((selectedItem) => selectedItem.isEqual(item))

      if (highlighted && !selected) {
        itemsToReuse.push(highlighted)
        return false
      }

      if (selected) {
        itemsToDeselect.push(selected)
        return false
      }

      return true
    })

    // deselect if item was created and present in selection
    itemsToDeselect.forEach((itemToDeselect) => itemToDeselect.deselect())
    this.items = this.items.filter((item) => {
      return !itemsToDeselect.find((itemToDeselect) => item.isEqual(itemToDeselect))
    })

    let items = [...itemsToReuse, ...itemsToAdd]

    // apply passed filter to selection candidates
    if (this.filter) {
      items = items.filter(this.filter)
    }

    this.items.push(...items)
    items.forEach((item) => item.select())

    if (!silent) {
      this.setCollectorItems.trigger({ items: this.items.map((selectedItem) => selectedItem.itemMetadata) })
    }
  }

  deselect(pickedObjects?: ISelectableNode[], silent: boolean = false) {
    if (!pickedObjects) {
      this.items.forEach((item) => item.deselect())
      this.items = []
    } else {
      this.items = this.items.filter((item) => {
        const foundItem = pickedObjects.find((selectedItem) => {
          return Object.entries(selectedItem).some(([key, value]) => item.sceneItem === value)
        })

        if (foundItem) {
          item.deselect()
        }

        return foundItem
      })
    }

    if (!silent) {
      this.setCollectorItems.trigger({ items: this.items.map((selectedItem) => selectedItem.itemMetadata) })
    }
  }

  highlight(pickedObjects: ISelectableNode[] = []) {
    this.highlightedItems.forEach((item) => item.highlight(false))

    const itemsToReuse = []
    let itemsToHighlight = this.createItemsFromPickedObjects(pickedObjects)

    itemsToHighlight = itemsToHighlight.filter((highlightedItem) => {
      const foundItem = this.items.find((item) => item.sceneItem === highlightedItem.sceneItem.parent)
      if (foundItem) {
        itemsToReuse.push(foundItem)
      }

      return !foundItem
    })

    if (this.collectorFilters.get(this.type)) {
      const filtered = itemsToHighlight.filter(this.collectorFilters.get(this.type))
      itemsToHighlight.forEach((item) => {
        const found = filtered.find((filteredItem) => filteredItem.isEqual(item))
        if (!found) {
          item.restoreOriginalMaterial()
        }
      })

      itemsToHighlight = filtered
    }

    this.highlightedItems = [...itemsToReuse, ...itemsToHighlight]

    if (this.filter) {
      const itemsToDispose: CollectorItem[] = []
      const newHighlightedItem: CollectorItem[] = []
      this.highlightedItems.forEach((highlightedItem, index, array) => {
        if (this.filter(highlightedItem, index, array)) {
          newHighlightedItem.push(highlightedItem)
        } else {
          itemsToDispose.push(highlightedItem)
        }

        itemsToDispose.forEach((itemToDispose) => itemToDispose.dispose())
        this.highlightedItems = newHighlightedItem
      })
    }

    this.highlightedItems.forEach((item) => item.highlight(true))
  }

  disableColoring() {
    this.highlightedItems.forEach((highlightedItem) => highlightedItem.highlight(false))
    this.items.forEach((selected) => selected.deselect())
  }

  enableColoring() {
    this.items.forEach((selected) => {
      selected.select()
      selected.setInstancesColors()
    })
  }

  dispose() {
    this.highlightedItems.forEach((highlightedItem) => highlightedItem.highlight(false))
    this.items.forEach((selected) => selected.dispose())
    this.highlightedItems = []
    this.items = []
  }

  private itemsFactory(selectedItems: SelectedItem[]): CollectorItem[] {
    return selectedItems
      .map((selectedItem) => {
        const bpItem = this.renderScene.getMeshManager().getBuildPlanItemMeshById(selectedItem.buildPlanItemId)
        const meshes = bpItem.getChildMeshes().filter(this.renderScene.getMeshManager().isComponentMesh)
        const body = meshes.find((mesh) => {
          const metadata = mesh.metadata as IComponentMetadata
          return metadata.componentId === selectedItem.componentId && metadata.geometryId === selectedItem.geometryId
        })

        switch (this.type) {
          case SelectionUnit.Part:
            const part = this.renderScene.getMeshManager().getBuildPlanItemMeshById(selectedItem.buildPlanItemId)
            return new PartItem({ part }, selectedItem, this.selectionColor, this.renderScene)
          case SelectionUnit.Body:
            return new BodyItem({ body }, selectedItem, this.selectionColor, this.renderScene)
          case SelectionUnit.FaceAndEdge:
            const [face] = body.metadata.faces.filter((partFace) => partFace.name === selectedItem.faceName)
            return new FaceItem({ body, face }, selectedItem, this.selectionColor, this.renderScene)
          default:
            return null
        }
      })
      .filter((item) => item)
  }

  private createItemsFromPickedObjects(pickedObjects: ISelectableNode[]): CollectorItem[] {
    return pickedObjects
      .map((pickedObject) => {
        let metadata: SelectedItem
        switch (this.type) {
          case SelectionUnit.Part:
            metadata = { buildPlanItemId: pickedObject.part.metadata.buildPlanItemId }
            return new PartItem({ part: pickedObject.part }, metadata, this.selectionColor, this.renderScene)
          case SelectionUnit.Body:
            metadata = {
              buildPlanItemId: pickedObject.body.parent.metadata.buildPlanItemId,
              geometryId: pickedObject.body.metadata.geometryId,
              componentId: pickedObject.body.metadata.componentId,
            }
            return new BodyItem({ body: pickedObject.body }, metadata, this.selectionColor, this.renderScene)
          case SelectionUnit.FaceAndEdge:
            const buildPlanItemId = pickedObject.body.parent.metadata
              ? pickedObject.body.parent.metadata.buildPlanItemId
              : pickedObject.part.metadata.buildPlanItemId
            metadata = {
              buildPlanItemId,
              geometryId: pickedObject.body.metadata.geometryId,
              componentId: pickedObject.body.metadata.componentId,
              faceName: pickedObject.face.name,
            }

            return new FaceItem(pickedObject, metadata, this.selectionColor, this.renderScene)
          default:
            return null
        }
      })
      .filter((item) => item)
  }
}
