import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import { MutationTree } from 'vuex'
import { ILabelState } from '@/store/modules/label/types'
import { TextElement } from '@/types/Label/TextElement'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { ManualPatch, Patch } from '@/types/Label/Patch'
import { CachedLabelInsight, IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { Setting } from '@/types/Label/Setting'
import { LabeledBody } from '@/types/Label/LabeledBody'
import { AutomaticPlacementInfo } from '@/types/Label/AutomaticPlacementInfo'
import { BooleanType, LabelDirtyState, LabelSetMode, MarkingContentElementType } from '@/types/Label/enums'
import { DEFAULT_LABEL_SET_SETTING, MAX_CHORD_HEIGHT, PART_BODY_ID_DELIMITER } from '@/constants'
import { getTextElementByType } from '@/utils/label/labelUtils'
import { Placement } from '@/types/Label/Placement'
import { ISelectable } from '@/types/BuildPlans/IBuildPlan'
import { LabeledBodyWIthTransformation } from '@/types/Label/LabeledBodyWIthTransformation'
import { AutomatedTrackableLabel, ErrorCodes, TrackableLabel } from '@/types/Label/TrackableLabel'
import { IInteractiveServiceCommand } from '@/types/InteractiveService/IInteractiveServiceCommand'
import { FontStyleHelpBalloonInfo } from '@/types/Label/FontStyleHelpBalloonInfo'
import { PrintOrderPreviewPatch } from '@/types/PrintOrder/PrintOrderPatch'

export const mutations: MutationTree<ILabelState> = {
  setActiveLabelSet(state, labelSet: InteractiveLabelSet) {
    state.activeLabelSet = labelSet
  },

  setActiveLabelSetSettingsProp(state, payload: { propName: string; value: any }) {
    Vue.set(state.activeLabelSet.settings, payload.propName, payload.value)
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === state.activeLabelSet.id)
    if (index !== -1) {
      Vue.set(state.labelSets, index, state.activeLabelSet)
    }

    Vue.set(state, 'lastUpdatedLabelSetId', state.activeLabelSet.id)
  },

  addNewTextElement(state, element: TextElement) {
    state.listOfTextElements.push(element)
    state.activeTextElement = null
    if (state.activeLabelSet) {
      Vue.set(state, 'lastUpdatedLabelSetId', state.activeLabelSet.id)
    }

    const textElement = getTextElementByType(element.type)
    if (textElement) {
      Vue.set(state.lastUpdatedDynamicTextElements, textElement, element.elementIDNumber)
    }
  },

  removeLabelSet(state, id: string) {
    const ids = state.labelSets.find((s) => s.id === id).settings.textElements.map((te) => te.elementIDNumber)
    const labelSets = state.labelSets.filter((s) => s.id !== id)

    Vue.set(state, 'lastDeletedElementIDs', ids)
    Vue.set(state, 'labelSets', labelSets)

    if (state.activeLabelSet && state.activeLabelSet!.id === state.lastUpdatedLabelSetId) {
      Vue.set(state, 'lastUpdatedLabelSetId', null)
    }
  },

  setLastDeletedElementIDs(state, ids: string[]) {
    Vue.set(state, 'lastDeletedElementIDs', ids)
  },

  removeDynamicElement(state, id: number | string) {
    const updatedSets = state.labelSets.map((ls: InteractiveLabelSet) => {
      ls.settings.textElements = ls.settings.textElements.filter((te: TextElement) => te.elementIDNumber !== id)
      return ls
    })
    const updatedElements = state.listOfTextElements.filter((te: TextElement) => te.elementIDNumber !== id)
    const element = state.listOfTextElements.find((te: TextElement) => te.elementIDNumber === id)

    Vue.set(state, 'listOfTextElements', updatedElements)
    Vue.set(state, 'labelSets', updatedSets)

    const textElement = getTextElementByType(element.type)
    if (textElement) {
      Vue.set(state.lastUpdatedDynamicTextElements, textElement, null)
    }
  },

  removeDynamicElementFromLabelSet(state, id: number | string) {
    Vue.set(state, 'lastDeletedElementIDs', [id])
  },

  clearDeletedElements(state) {
    Vue.set(state, 'lastDeletedElements', [])
  },

  updateDependentLabelSetsDynamicFields(
    state,
    listOfDependentSets: Array<{
      index: string
      updatedElements: TextElement[]
    }>,
  ) {
    listOfDependentSets.forEach((set) => {
      Vue.set(state.labelSets[set.index].settings, 'textElements', set.updatedElements)
    })
  },

  setActiveTextElement(state, value) {
    Vue.set(state, 'activeTextElement', value)
  },

  updateListOfTextElements(state, list: TextElement[]) {
    Vue.set(state, 'listOfTextElements', list)
  },

  updateActiveLabelSetTextElements(state, settingElementsList: TextElement[]) {
    Vue.set(state.activeLabelSet.settings, 'textElements', settingElementsList)
    const labelSetIndex = state.labelSets.findIndex((ls) => ls.id === state.activeLabelSet.id)
    if (labelSetIndex >= 0) {
      Vue.set(state.labelSets[labelSetIndex].settings, 'textElements', settingElementsList)
    }
  },

  setLastUpdatedLabelSetId(state, id: string) {
    Vue.set(state, 'lastUpdatedLabelSetId', id)
  },

  setLastUpdatedDynamicTextElement(state, payload: { textElement: string; id: string }) {
    Vue.set(state.lastUpdatedDynamicTextElements, payload.textElement, payload.id)
  },

  updateLabelText(
    state,
    payload: { labelSetId: string; text: string; elementToAdd?: TextElement; elementToRemove?: TextElement },
  ) {
    const settings: Setting = cloneDeep(state.activeLabelSet.settings)
    settings.textContent = payload.text

    if (payload.elementToAdd && state.activeLabelSet) {
      settings.textElements.push(payload.elementToAdd)
    }

    if (payload.elementToRemove && state.activeLabelSet) {
      settings.textElements = settings.textElements.filter(
        (textElement: TextElement) => textElement.elementIDNumber !== payload.elementToRemove.elementIDNumber,
      )
    }

    Vue.set(state.activeLabelSet, 'settings', settings)
    state.labelSets.forEach((labelSet: InteractiveLabelSet) => {
      if (labelSet.id === payload.labelSetId) {
        labelSet.settings.textContent = payload.text
        if (state.activeLabelSet && (payload.elementToAdd || payload.elementToRemove)) {
          labelSet.settings.textElements = state.activeLabelSet.settings.textElements
        }
      }
      return labelSet
    })

    Vue.set(state, 'lastUpdatedLabelSetId', state.activeLabelSet.id)
  },

  addLabelSet(state, labelSet: InteractiveLabelSet) {
    const list = state.labelSets
    list.push(labelSet)
    Vue.set(state, 'labelSets', list)
  },

  createLabelSetsSnapshot(state: ILabelState) {
    Vue.set(state, 'labelSetsSnapshot', cloneDeep(state.labelSets))
  },

  setEmptyLabelSetsSnapshot(state: ILabelState) {
    Vue.set(state, 'labelSetsSnapshot', [])
  },

  restoreLabelSetsFromSnapshot(state: ILabelState, labelSetIds: string[]) {
    Vue.set(state, 'labelSets', cloneDeep(state.labelSetsSnapshot))
  },

  restoreLabelSetsFromCache(state: ILabelState, labelSetIds: string[]) {
    labelSetIds.forEach((labelSetId) => {
      const index = state.labelSets.findIndex((labelSet) => labelSet.id === labelSetId)
      if (index !== -1) {
        const cachedLabelSet = state.labelSetsSnapshot.find((labelSet) => labelSet.id === labelSetId)
        if (cachedLabelSet) {
          Vue.set(state.labelSets, index, cloneDeep(cachedLabelSet))
        } else {
          // if there is no cached labelSet we should remove all patches
          Vue.set(state.labelSets[index], 'patches', [])
        }
      }
    })
  },

  clearLabelSetsSnapshot(state: ILabelState) {
    Vue.set(state, 'labelSetsSnapshot', null)
  },

  setLabelSets(state: ILabelState, labelSets: InteractiveLabelSet[]) {
    Vue.set(state, 'labelSets', labelSets)
  },

  setDynamicElements(state: ILabelState, labelSets?: InteractiveLabelSet[]) {
    const dynamicElements = []
    if (labelSets) {
      labelSets.forEach((ls: InteractiveLabelSet) => {
        ls.settings.textElements.forEach((te: TextElement) => {
          const elementIndex = dynamicElements.findIndex(
            (element: TextElement) => te.elementIDNumber === element.elementIDNumber,
          )
          if (elementIndex < 0) {
            dynamicElements.push(te)
          }
        })
      })
    } else {
      state.labelSets.forEach((ls: InteractiveLabelSet) => {
        ls.settings.textElements.forEach((te: TextElement) => {
          const elementIndex = dynamicElements.findIndex(
            (element: TextElement) => te.elementIDNumber === element.elementIDNumber,
          )
          if (elementIndex < 0) {
            dynamicElements.push(te)
          }
        })
      })
    }

    Vue.set(state, 'listOfTextElements', dynamicElements)
  },

  setLabelSetPatches(state: ILabelState, payload: { labelSetId: string; patches: Patch[] }) {
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === payload.labelSetId)
    if (index !== -1) {
      Vue.set(state.labelSets[index], 'patches', payload.patches)
    }
  },

  addLabelSetPatches(state: ILabelState, payload: { labelSetId: string; patches: Patch[] }) {
    const foundLabelSet = state.labelSets.find((labelSet) => labelSet.id === payload.labelSetId)
    if (foundLabelSet) {
      const mapPatchById = new Map<string, Patch>(foundLabelSet.patches.map((p) => [p.id, p]))
      payload.patches.forEach((p) => {
        mapPatchById.set(p.id, p)
      })
      Vue.set(foundLabelSet, 'patches', Array.from(mapPatchById.values()))
    }
  },

  setLabelSetPatch(state: ILabelState, payload: { labelSetId: string; patch: Patch }) {
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === payload.labelSetId)
    if (index !== -1) {
      const filteredPatches = state.labelSets[index].patches.filter((patch) => patch.id !== payload.patch.id)
      filteredPatches.push(payload.patch)
      Vue.set(state.labelSets[index], 'patches', filteredPatches)
    }
  },

  setLabelManualPlacements(
    state: ILabelState,
    payload: { labelSet: InteractiveLabelSet; manualPlacements: Placement[] },
  ) {
    const index: number = state.labelSets.findIndex((ls: InteractiveLabelSet) => ls.id === payload.labelSet.id)
    const lsWithPlacements: InteractiveLabelSet = {
      ...payload.labelSet,
      ...{ manualPlacements: payload.manualPlacements },
    }

    Vue.set(state.labelSets, index, lsWithPlacements)
    if (state.activeLabelSet && state.activeLabelSet.id === payload.labelSet.id) {
      Vue.set(state.activeLabelSet, 'manualPlacements', payload.manualPlacements)
    }
  },

  addManualPlacements(state: ILabelState, payload: { labelSetId: string; placementsWithTransformation: Placement[] }) {
    const index: number = state.labelSets.findIndex((ls: InteractiveLabelSet) => ls.id === payload.labelSetId)
    const labelSet: InteractiveLabelSet = state.labelSets.find(
      (ls: InteractiveLabelSet) => ls.id === payload.labelSetId,
    )

    const manualPlacements = labelSet.manualPlacements ? labelSet.manualPlacements : []
    const manualPlacementsCombined = [...manualPlacements, ...payload.placementsWithTransformation]
    Vue.set(state.labelSets[index], 'manualPlacements', manualPlacementsCombined)
    if (state.activeLabelSet && state.activeLabelSet.id === payload.labelSetId) {
      Vue.set(state.activeLabelSet, 'manualPlacements', manualPlacementsCombined)
    }
  },

  addAutoPlacements(state: ILabelState, payload: { labelSetId: string; manualPlacements: AutomaticPlacementInfo[] }) {
    const labelSetAP = state.automaticPlacements[payload.labelSetId]
    if (labelSetAP) {
      Vue.set(state.automaticPlacements, payload.labelSetId, [...labelSetAP, ...payload.manualPlacements])
    } else {
      Vue.set(state.automaticPlacements, payload.labelSetId, payload.manualPlacements)
    }
  },

  removeAutoPlacements(state: ILabelState, labelSetInfo) {
    const labelSetId = labelSetInfo.labelSetId
    if (labelSetId && state.automaticPlacements[labelSetId]) {
      const newSetOfPlacements = state.automaticPlacements[labelSetId].filter((info) => {
        return (
          info.buildPlanItemId !== labelSetInfo.buildPlanItemId ||
          info.componentId !== labelSetInfo.componentId ||
          info.geometryId !== labelSetInfo.geometryId
        )
      })
      Vue.set(state.automaticPlacements, labelSetId, newSetOfPlacements)
    }
  },

  clearLabelAutomaticPlacements(state: ILabelState) {
    Vue.set(state, 'automaticPlacements', {})
  },

  removeManualPlacementsForLabelSet(state: ILabelState, payload: { labelSetId: string; labelId?: string }) {
    const labelSet: InteractiveLabelSet = state.labelSets.find(
      (ls: InteractiveLabelSet) => ls.id === payload.labelSetId,
    )
    const labelSetIndex: number = state.labelSets.findIndex((ls: InteractiveLabelSet) => ls.id === payload.labelSetId)
    const updatedLabelSet: InteractiveLabelSet = cloneDeep(labelSet)
    const placementIndex: number = updatedLabelSet.manualPlacements.findIndex(
      (p: Placement) => p.id === payload.labelId,
    )
    updatedLabelSet.manualPlacements.splice(placementIndex, 1)

    Vue.set(state.labelSets[labelSetIndex], 'manualPlacements', updatedLabelSet.manualPlacements)
    if (state.activeLabelSet && labelSet.id === state.activeLabelSet.id) {
      Vue.set(state.activeLabelSet, 'manualPlacements', updatedLabelSet.manualPlacements)
    }
  },

  setIsLabelSetOpened(state: ILabelState, isOpened: boolean) {
    state.isLabelSetOpened = isOpened
  },

  setIsPatchedBodiesSelected(state: ILabelState, isSelected: boolean) {
    state.isPatchedBodiesSelected = isSelected
  },

  setIsLabelToolOpened(state: ILabelState, isOpened: boolean) {
    state.isLabelSetOpened = isOpened
  },

  resetTextElements(state: ILabelState) {
    Vue.set(state, 'listOfTextElements', [])
  },

  setIsRunning(state: ILabelState, isRunning: boolean) {
    state.isRunning = isRunning
  },

  cacheLabelInsights(state: ILabelState, insights: IBuildPlanInsight[]) {
    state.cachedLabelInsights = Object.freeze(cloneDeep(insights))
  },

  clearExecuteTimeout(state: ILabelState) {
    if (state.executeTimeout) {
      clearTimeout(state.executeTimeout)
    }

    Vue.set(state, 'executeTimeout', null)
  },

  setExecuteTimeout(state: ILabelState, timeout: NodeJS.Timeout) {
    Vue.set(state, 'executeTimeout', timeout)
  },

  setIsSilent(state: ILabelState, isSilent: boolean) {
    Vue.set(state, 'isSilent', isSilent)
  },

  setActiveSetLastSuccessfulManualPlacements(state: ILabelState, manualPlacements: Placement[]) {
    Vue.set(state, 'activeSetLastSuccessfulManualPlacements', manualPlacements)
  },

  clearActiveSetLastSuccessfulManualPlacements(state: ILabelState) {
    Vue.set(state, 'activeSetLastSuccessfulManualPlacements', [])
  },

  setLabelToolIsValid(state: ILabelState, value) {
    Vue.set(state, 'labelToolIsValid', value)
  },

  setActiveLabelIsValid(state: ILabelState, value) {
    Vue.set(state, 'activeLabelSetIsValid', value)
  },

  addCachedPatch(state: ILabelState, params: { labelSetId: string; patches: Patch[] }) {
    state.cachedPatches[params.labelSetId] = params.patches
  },

  clearCachedPatches(state: ILabelState) {
    Vue.set(state, 'cachedPatches', {})
  },

  setSaveComplete(state: ILabelState, value) {
    Vue.set(state, 'saveComplete', value)
  },

  setIsSaving(state: ILabelState, value) {
    Vue.set(state, 'isSaving', value)
  },

  setIsLabelExecuteTriggered(state: ILabelState, value: boolean) {
    state.isLabelExecuteTriggered = value
  },

  setSelectedBodies(state: ILabelState, bodies: LabeledBodyWIthTransformation[]) {
    // Update selected bodies for an active labelSet
    Vue.set(state.activeLabelSet, 'selectedBodies', bodies)
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === state.activeLabelSet.id)
    if (index !== -1) {
      // If an array of label sets has such label set update it too in order to have correct state
      Vue.set(state.labelSets, index, state.activeLabelSet)
    }
  },

  setRelatedBodies(state: ILabelState, payload: { bodies: LabeledBodyWIthTransformation[]; add?: boolean }) {
    // Update related bodies for an active labelSet
    const bodies = payload.add ? [...state.activeLabelSet.relatedBodies, ...payload.bodies] : payload.bodies
    Vue.set(state.activeLabelSet, 'relatedBodies', bodies)
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === state.activeLabelSet.id)
    if (index !== -1) {
      // If an array of label sets has such label set update it too in order to have correct state
      Vue.set(state.labelSets, index, state.activeLabelSet)
    }
  },

  addSelectedBodies(state: ILabelState, toAdd: LabeledBodyWIthTransformation[]) {
    const selectedBodies = [...state.activeLabelSet.selectedBodies, ...toAdd]
    // Update selected bodies for an active label set
    Vue.set(state.activeLabelSet, 'selectedBodies', selectedBodies)
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === state.activeLabelSet.id)
    if (index !== -1) {
      // If an array of label sets has such label set update it too in order to have correct state
      Vue.set(state.labelSets, index, state.activeLabelSet)
    }
  },

  addSelectedBodiesIntoLabelSet(
    state: ILabelState,
    payload: {
      labelSetId: string
      toAdd: LabeledBodyWIthTransformation[]
    },
  ) {
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === payload.labelSetId)
    const selectedBodies = [...state.labelSets[index].selectedBodies, ...payload.toAdd]
    // Update selected bodies for an active label set
    Vue.set(state.labelSets[index], 'selectedBodies', selectedBodies)
    if (state.activeLabelSet && payload.labelSetId === state.activeLabelSet.id) {
      Vue.set(state.activeLabelSet, 'selectedBodies', selectedBodies)
    }
  },

  addPatchesIntoLabelSet(
    state: ILabelState,
    payload: {
      labelSetId: string
      toAdd: Patch[]
    },
  ) {
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === payload.labelSetId)
    const patches = [...state.labelSets[index].patches, ...payload.toAdd]
    // Update selected bodies for an active label set
    Vue.set(state.labelSets[index], 'patches', patches)
    if (state.activeLabelSet && payload.labelSetId === state.activeLabelSet.id) {
      Vue.set(state.activeLabelSet, 'patches', patches)
    }
  },

  removeSelectedBodies(state: ILabelState, toRemove: ISelectable[]) {
    if (!state.activeLabelSet) {
      return
    }

    const selectedBodies = [...state.activeLabelSet.selectedBodies]
    if (toRemove) {
      toRemove.forEach((selectable) => {
        const [buildPlanItemId, componentId, geometryId] = selectable.id.split(PART_BODY_ID_DELIMITER)
        const selectedBodyIndex = selectedBodies.findIndex(
          (body) =>
            body.buildPlanItemId === buildPlanItemId &&
            body.componentId === componentId &&
            body.geometryId === geometryId,
        )

        // Remove item from the selected array if it is present
        if (selectedBodyIndex !== -1) {
          selectedBodies.splice(selectedBodyIndex, 1)
        }
      })
    }

    // Update selected bodies for an active label set
    Vue.set(state.activeLabelSet, 'selectedBodies', selectedBodies)
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === state.activeLabelSet.id)
    if (index !== -1) {
      // If an array of label sets has such label set update it too in order to have correct state
      Vue.set(state.labelSets, index, state.activeLabelSet)
    }
  },

  addRelatedBodies(state: ILabelState, bodies: LabeledBodyWIthTransformation[]) {
    const relatedBodies = [...state.activeLabelSet.relatedBodies, ...bodies]
    // Update an active label set
    Vue.set(state.activeLabelSet, 'relatedBodies', relatedBodies)
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === state.activeLabelSet.id)
    if (index !== -1) {
      // If an array of label sets has such label set update it too in order to have correct state
      Vue.set(state.labelSets, index, state.activeLabelSet)
    }
  },

  removeRelatedBodies(state: ILabelState, bodies: LabeledBodyWIthTransformation[]) {
    let relatedBodies = [...state.activeLabelSet.relatedBodies]
    if (!bodies) {
      relatedBodies = []
    } else {
      bodies.forEach((bodyToRemove) => {
        const relatedBodyIndex = relatedBodies.findIndex(
          (body) =>
            body.buildPlanItemId === bodyToRemove.buildPlanItemId &&
            body.componentId === bodyToRemove.componentId &&
            body.geometryId === bodyToRemove.geometryId,
        )

        if (relatedBodyIndex !== -1) {
          relatedBodies.splice(relatedBodyIndex, 1)
        }
      })
    }

    // Update related bodies for an active label set
    Vue.set(state.activeLabelSet, 'relatedBodies', relatedBodies)
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === state.activeLabelSet.id)
    if (index !== -1) {
      // If an array of label sets has such label set update it too in order to have correct state
      Vue.set(state.labelSets, index, state.activeLabelSet)
    }
  },

  resetLabelSetsIDsForUpdate(state: ILabelState, value: string[]) {
    Vue.set(state, 'labelSetsIDsToUpdate', value)
  },

  setLabelUpdateInProgress(state: ILabelState, value: boolean) {
    Vue.set(state, 'labelUpdateInProgress', value)
  },

  setWatchToolReady(state: ILabelState, value: boolean) {
    Vue.set(state, 'watchToolReady', value)
  },

  setSettingsAreValid(state: ILabelState, value: boolean) {
    Vue.set(state, 'settingsAreValid', value)
  },

  setUserSettingsAreValid(state: ILabelState, value: boolean) {
    Vue.set(state, 'userSettingsAreValid', value)
  },

  setHighlightedLabelSetId(state: ILabelState, value: string) {
    Vue.set(state, 'highlightedLabelSetId', value)
  },

  setDetectedDiff(state: ILabelState, diff: { toRemove: LabeledBody[]; toAdd: LabeledBody[]; shouldUseDiff: boolean }) {
    Vue.set(state, 'detectedDiff', diff)
  },

  setHasLabeledInstances(state: ILabelState, value: boolean) {
    const settings: Setting = cloneDeep(state.activeLabelSet.settings)
    settings.hasLabeledInstances = value

    Vue.set(state.activeLabelSet, 'settings', settings)
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === state.activeLabelSet.id)
    if (index !== -1) {
      Vue.set(state.labelSets, index, state.activeLabelSet)
    }
  },

  setDynamicElementsIDsToRefresh(state: ILabelState, ids: number[]) {
    Vue.set(state, 'dynamicElementsIDsToRefresh', ids)
  },

  changeBooleanType(state: ILabelState, booleanType: BooleanType) {
    if (state.activeLabelSet.settings.textBoolean === booleanType) {
      return
    }

    const settings: Setting = cloneDeep(state.activeLabelSet.settings)
    if (booleanType === BooleanType.Add) {
      settings.textExtrusionAboveSurface = DEFAULT_LABEL_SET_SETTING.textExtrusionAboveSurface
      settings.textExtrusionBelowSurface = MAX_CHORD_HEIGHT * 2
    } else {
      settings.textExtrusionAboveSurface = MAX_CHORD_HEIGHT * 2
      settings.textExtrusionBelowSurface = DEFAULT_LABEL_SET_SETTING.textExtrusionBelowSurface
    }

    settings.textBoolean = booleanType

    Vue.set(state.activeLabelSet, 'settings', settings)
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === state.activeLabelSet.id)
    if (index !== -1) {
      Vue.set(state.labelSets, index, state.activeLabelSet)
    }
  },

  setOkIsDisabled(state: ILabelState, isDisabled: boolean) {
    Vue.set(state, 'isOkDisabled', isDisabled)
  },

  resetLastUpdatedLabelSetId(state: ILabelState) {
    Vue.set(state, 'lastUpdatedLabelSetId', null)
  },

  resetLastUpdatedDynamicTextElements(state: ILabelState, elementType?: MarkingContentElementType) {
    if (!elementType) {
      Vue.set(
        state.lastUpdatedDynamicTextElements,
        getTextElementByType(MarkingContentElementType.Sequential_Integer),
        null,
      )
      Vue.set(state.lastUpdatedDynamicTextElements, getTextElementByType(MarkingContentElementType.Grid_Letter), null)
      Vue.set(state.lastUpdatedDynamicTextElements, getTextElementByType(MarkingContentElementType.User_Entry), null)
    } else {
      const textElement = getTextElementByType(elementType)
      if (textElement) {
        Vue.set(state.lastUpdatedDynamicTextElements, textElement, null)
      }
    }
  },

  setLabelIndicesForExecute(state: ILabelState, payload: { labelSetId: string; labelIndices: number[] }) {
    const labelSetLabelIndices = state.labelIndicesForExecute[payload.labelSetId]
    if (labelSetLabelIndices) {
      Vue.set(state.labelIndicesForExecute, payload.labelSetId, [...labelSetLabelIndices, ...payload.labelIndices])
    } else {
      Vue.set(state.labelIndicesForExecute, payload.labelSetId, [...payload.labelIndices])
    }
  },

  clearLabelSetLabelIndices(state: ILabelState, labelSetId: string) {
    Vue.set(state.labelIndicesForExecute, labelSetId, undefined)
  },

  clearLabelIndices(state: ILabelState) {
    Vue.set(state, 'labelIndicesForExecute', {})
  },

  setIsExitingFromTool(state: ILabelState, payload: boolean) {
    Vue.set(state, 'isExitingFromTool', payload)
  },

  setIsInDirtyState(state: ILabelState, payload: boolean) {
    Vue.set(state, 'isInDirtyState', payload)
  },

  addTrackableLabels(state: ILabelState, payload: TrackableLabel[]) {
    const labels = state.activeLabelSet.labels ? state.activeLabelSet.labels : []
    const trackableLabels = [...labels, ...payload]
    // Update an active label set
    Vue.set(state.activeLabelSet, 'labels', trackableLabels)
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === state.activeLabelSet.id)
    if (index !== -1) {
      // If an array of label sets has such label set update it too in order to have correct state
      Vue.set(state.labelSets, index, state.activeLabelSet)
    }
  },

  removeTrackableLabels(state: ILabelState, ids: string[]) {
    let trackableLabels = [...state.activeLabelSet.labels]
    if (!ids) {
      trackableLabels = []
    } else {
      trackableLabels = trackableLabels.filter((trackableLabel) => !ids.includes(trackableLabel.id))
    }

    // Update an active label set
    Vue.set(state.activeLabelSet, 'labels', trackableLabels)
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === state.activeLabelSet.id)
    if (index !== -1) {
      // If an array of label sets has such label set update it too in order to have correct state
      Vue.set(state.labelSets, index, state.activeLabelSet)
    }
  },

  removeTrackableLabelsByLabelSetId(state: ILabelState, payload: { labelSetId: string, ids: string[] }) {
    const { labelSetId, ids } = payload
    const labelSet = JSON.parse(JSON.stringify(state.labelSets.find(ls => ls.id === labelSetId)))
    if (!labelSet) {
      return
    }

    let trackableLabels = [...labelSet.labels]
    if (!ids) {
      trackableLabels = []
    } else {
      trackableLabels = trackableLabels.filter((trackableLabel) => !ids.includes(trackableLabel.id))
    }

    // Update an active labelSet in case of active label set are present
    if (state.activeLabelSet && state.activeLabelSet.id === labelSetId) {
      Vue.set(state.activeLabelSet, 'labels', trackableLabels)
    }


    const index = state.labelSets.findIndex((labelSet) => labelSet.id === labelSetId)
    if (index !== -1) {
      labelSet.labels = trackableLabels
      // If an array of label sets has such label set update it too in order to have correct state
      Vue.set(state.labelSets, index, labelSet)
    }

  },

  setTrackableLabelCommandId(
    state: ILabelState,
    payload: {
      labelSetId: string
      trackableLabelId: string
      commandId: string
    },
  ) {
    const labelSetIndex = state.labelSets.findIndex((ls) => ls.id === payload.labelSetId)
    if (labelSetIndex === -1) {
      return
    }

    const labelSet: InteractiveLabelSet = cloneDeep(state.labelSets[labelSetIndex])
    const label = labelSet.labels.find((trackableLabel) => trackableLabel.id === payload.trackableLabelId)
    label.commandId = payload.commandId

    // Update labelSet in the list of label sets
    Vue.set(state.labelSets, labelSetIndex, labelSet)
    if (state.activeLabelSet && state.activeLabelSet.id === labelSet.id) {
      // If this is an active label set we also should update active label set in order to have correct state
      Vue.set(state.activeLabelSet, 'labels', labelSet.labels)
    }
  },

  makeTrackableLabelDirty(
    state: ILabelState,
    payload: {
      labelSetId: string
      id: string
      dirtyState: LabelDirtyState
      force?: boolean
    },
  ) {
    const labelSetIndex = state.labelSets.findIndex((ls) => ls.id === payload.labelSetId)
    const labelSet = state.labelSets[labelSetIndex]
    // No label set to work with
    if (labelSetIndex === -1) {
      return
    }

    const trackableLabelIndex = labelSet.labels.findIndex((label) => label.id === payload.id)

    // No trackable label with such id
    if (trackableLabelIndex === -1) {
      return
    }

    const newTrackableLabel: TrackableLabel = {
      ...labelSet.labels[trackableLabelIndex],
      isDirty: true,
      commandId: null,
    }

    // Take into account dirtyState priority or force to update it
    if (payload.force || newTrackableLabel.dirtyState < payload.dirtyState) {
      newTrackableLabel.dirtyState = payload.dirtyState
    }

    // Set labels for label set in the list of label sets
    Vue.set(state.labelSets[labelSetIndex].labels, trackableLabelIndex, newTrackableLabel)
    if (state.activeLabelSet && state.activeLabelSet.id === labelSet.id) {
      // If this is an active label set we also should update active label set in order to have correct state
      Vue.set(state.activeLabelSet, 'labels', state.labelSets[labelSetIndex].labels)
    }
  },

  makeTrackableLabelsDirty(
    state: ILabelState,
    payload: Array<{
      labelSetId: string
      id: string
      dirtyState: LabelDirtyState
      force?: boolean
    }>,
  ) {
    const labelSetsIds = payload.map((trackableData) => trackableData.labelSetId)
    const labelSetsCopy: InteractiveLabelSet[] = cloneDeep(
      state.labelSets.filter((ls: InteractiveLabelSet) => labelSetsIds.includes(ls.id))
    )
    payload.forEach((trackableData) => {
      const labelSet = labelSetsCopy.find((ls) => ls.id === trackableData.labelSetId)
      // No label set to work with
      if (!labelSet) {
        return
      }

      const trackableLabel = labelSet.labels.find((label) => label.id === trackableData.id)

      // No trackable label with such trackId
      if (!trackableLabel) {
        return
      }

      // We have to reset commandId on each dirty update
      trackableLabel.commandId = null

      // Take into account dirtyState priority or force to update dirty state
      if (trackableData.force || trackableLabel.dirtyState < trackableData.dirtyState) {
        trackableLabel.dirtyState = trackableData.dirtyState
      }

      trackableLabel.isDirty = true
    })

    // Set labels for label set in the list of label sets
    labelSetsCopy.forEach((ls: InteractiveLabelSet) => {
      const index = state.labelSets.findIndex((labelSet: InteractiveLabelSet) => labelSet.id === ls.id)
      Vue.set(state.labelSets, index, ls)
    })
    if (state.activeLabelSet) {
      const activeLabelSetIndex = labelSetsCopy.findIndex((ls) => (ls.id = state.activeLabelSet.id))
      // If this is an active label set we also should update active label set in order to have correct state
      if (activeLabelSetIndex >= 0) {
        Vue.set(state.activeLabelSet, 'labels', labelSetsCopy[activeLabelSetIndex].labels)
      }
    }
  },

  makeAllTrackableLabelsDirtyByLabelSetId(state: ILabelState, payload: { id: string; dirtyState: LabelDirtyState }) {
    const labelSetIndex = state.labelSets.findIndex((labelSet) => labelSet.id === payload.id)
    // No labelSet to work with
    if (labelSetIndex === -1) {
      return
    }

    const labels: TrackableLabel[] = cloneDeep(state.labelSets[labelSetIndex].labels)
    labels.forEach((label) => {
      // We have to reset commandId on each dirty update
      label.commandId = null
      label.isDirty = true
      // Take into account dirtyState priority
      if (label.dirtyState < payload.dirtyState) {
        label.dirtyState = payload.dirtyState
      }
    })

    // Set labels for label set in the list of label sets
    Vue.set(state.labelSets[labelSetIndex], 'labels', labels)
    if (state.activeLabelSet && state.labelSets[labelSetIndex].id === state.activeLabelSet.id) {
      // If this is an active label set we also should update active label set in order to have correct state
      Vue.set(state.activeLabelSet, 'labels', labels)
    }
  },

  addCachedInsight(state: ILabelState, payload: CachedLabelInsight) {
    state.cachedInsightsWhileExecution.push(payload)
  },

  setCachedInsight(state: ILabelState, payload: CachedLabelInsight[]) {
    Vue.set(state, 'cachedInsightsWhileExecution', payload)
  },

  resetLabelDirtyState(state: ILabelState, payload: { trackId: string; labelSetId: string }) {
    const labelSet: InteractiveLabelSet = cloneDeep(
      state.labelSets.find((ls: InteractiveLabelSet) => ls.id === payload.labelSetId)
    )
    const index: number = state.labelSets.findIndex((ls: InteractiveLabelSet) => ls.id === payload.labelSetId)
    labelSet.labels = labelSet.labels.map((label: TrackableLabel) => {
      if (label.id === payload.trackId) {
        label.commandId = null
        label.isDirty = false
        label.dirtyState = LabelDirtyState.None
      }

      return label
    })
    Vue.set(state.labelSets[index], 'labels', labelSet.labels)
    if (state.activeLabelSet && labelSet.id === state.activeLabelSet.id) {
      Vue.set(state.activeLabelSet, 'labels', labelSet.labels)
    }
  },

  setLabelErrorCode(state: ILabelState, payload: { trackId: string; labelSetId: string; errorCode: ErrorCodes }) {
    const labelSet: InteractiveLabelSet = cloneDeep(
      state.labelSets.find((ls: InteractiveLabelSet) => ls.id === payload.labelSetId)
    )
    const index: number = state.labelSets.findIndex((ls: InteractiveLabelSet) => ls.id === payload.labelSetId)
    labelSet.labels = labelSet.labels.map((label: TrackableLabel) => {
      if (label.id === payload.trackId) {
        label.errorCode = payload.errorCode
      }

      return label
    })
    Vue.set(state.labelSets[index], 'labels', labelSet.labels)
    if (state.activeLabelSet && labelSet.id === state.activeLabelSet.id) {
      Vue.set(state.activeLabelSet, 'labels', labelSet.labels)
    }
  },

  resetLabelErrorCode(state: ILabelState, payload: { trackId: string; labelSetId: string }) {
    const labelSet: InteractiveLabelSet = cloneDeep(
      state.labelSets.find((ls: InteractiveLabelSet) => ls.id === payload.labelSetId)
    )
    const index: number = state.labelSets.findIndex((ls: InteractiveLabelSet) => ls.id === payload.labelSetId)
    labelSet.labels = labelSet.labels.map((label: TrackableLabel) => {
      if (label.id === payload.trackId) {
        label.errorCode = null
      }

      return label
    })
    Vue.set(state.labelSets[index], 'labels', labelSet.labels)
    if (state.activeLabelSet && labelSet.id === state.activeLabelSet.id) {
      Vue.set(state.activeLabelSet, 'labels', labelSet.labels)
    }
  },

  clearCachedInsights(state: ILabelState) {
    state.cachedInsightsWhileExecution = []
  },

  removeCachedInsightByIndex(state: ILabelState, index: number) {
    const cachedInsights: CachedLabelInsight[] = cloneDeep(state.cachedInsightsWhileExecution)
    cachedInsights.splice(index, 1)
    Vue.set(state, 'cachedInsightsWhileExecution', cachedInsights)
  },

  setCurrentCommand(state: ILabelState, command: IInteractiveServiceCommand) {
    Vue.set(state, 'currentCommand', command)
  },

  setIgnoreChangesByDirtyStates(state: ILabelState, value: boolean) {
    Vue.set(state, 'ignoreChangesByDirtyStates', value)
  },

  setLabelSetByIndex(state, payload: { labelSet: InteractiveLabelSet; index: number }) {
    Vue.set(state.labelSets, payload.index, payload.labelSet)
  },

  setActiveLabelSetMode(state: ILabelState, mode: LabelSetMode) {
    // Update mode for active label set
    Vue.set(state.activeLabelSet, 'mode', mode)
    // Update the label set in the label set list
    const index = state.labelSets.findIndex((labelSet) => labelSet.id === state.activeLabelSet.id)
    if (index !== -1) {
      Vue.set(state.labelSets, index, state.activeLabelSet)
    }
  },

  setForceRecalculateRelatedForIds(state: ILabelState, value: string) {
    const idsToRecalculate = state.forceRecalculateRelatedForIds
    idsToRecalculate.push(value)
    Vue.set(state, 'forceRecalculateRelatedForIds', idsToRecalculate)
  },

  resetForceRecalculateRelatedForIds(state: ILabelState) {
    Vue.set(state, 'forceRecalculateRelatedForIds', [])
  },

  removePatchById(state: ILabelState, patchId: string) {
    const labelSet: InteractiveLabelSet = state.labelSets.find((ls: InteractiveLabelSet) => {
      return ls.patches.some((p: Patch) => p.id === patchId)
    })
    if (!labelSet) {
      return
    }

    const patches: Patch[] = labelSet.patches.filter((p: Patch) => p.id !== patchId)
    
    if (state.activeLabelSet && labelSet.id === state.activeLabelSet.id) {
      Vue.set(state.activeLabelSet, 'patches', patches)
    }

    const index = state.labelSets.findIndex((ls: InteractiveLabelSet) => ls.id === labelSet.id)
    Vue.set(state.labelSets[index], 'patches', patches)
  },

  clearActiveLabelSetPatches(state: ILabelState) {
    const index = state.labelSets.findIndex((ls: InteractiveLabelSet) => ls.id === state.activeLabelSet.id)
    Vue.set(state.activeLabelSet, 'patches', [])
    Vue.set(state.labelSets[index], 'patches', [])
  },

  allowLabelAllInstances(state: ILabelState, isEnabled: boolean) {
    state.isLabelAllInstancesEnabled = isEnabled
  },

  allowApplyRotationToInstances(state: ILabelState, isEnabled: boolean) {
    state.isApplyRotationToInstancesEnabled = isEnabled
  },

  updateAutoTrackableLabelBodyId(state: ILabelState, payload: { trackId: string; bodyId: string }) {
    const labelSetIndex: number = state.labelSets.findIndex((ls: InteractiveLabelSet) => {
      return ls.labels.some((l: TrackableLabel) => l.id === payload.trackId)
    })
    const labelSet: InteractiveLabelSet = state.labelSets[labelSetIndex]
    const trackableLabelIndex: number = labelSet.labels.findIndex((l: TrackableLabel) => l.id === payload.trackId)
    const updatedLabel: AutomatedTrackableLabel = {
      ...labelSet.labels[trackableLabelIndex],
      bodyId: payload.bodyId,
    } as AutomatedTrackableLabel
    Vue.set(state.labelSets[labelSetIndex].labels, trackableLabelIndex, updatedLabel)
    if (labelSet.id === state.activeLabelSet.id) {
      Vue.set(state.activeLabelSet.labels, trackableLabelIndex, updatedLabel)
    }
  },

  setActiveDynamicElementDialogInfo(state: ILabelState, payload: { propertyName: string; value }) {
    Vue.set(state.activeDynamicElementDialogInfo, payload.propertyName, payload.value)
  },

  setFontStyleHelpBalloonInfo(state: ILabelState, info: FontStyleHelpBalloonInfo) {
    Vue.set(state, 'fontStyleHelpBalloonInfo', info)
  },

  createLabelPromise(state: ILabelState) {
    if (!state.labelAddedPromise) {
      let done
      const promise = new Promise<void>((resolve) => (done = resolve))
      Vue.set(state, 'labelAddedPromise', { promise, done })
    }
  },

  clearLabelPromise(state: ILabelState) {
    if (state.labelAddedPromise) {
      Vue.set(state, 'labelAddedPromise', null)
    }
  },

  setPrintOrderPreviewPatch(state: ILabelState, patches: PrintOrderPreviewPatch[]) {
    state.printOrder.patchList = patches
  },
}
