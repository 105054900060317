
import { ROOT_FOLDER_ID } from '@/constants'
import { RouterNames } from '@/router'
import StoresNamespaces from '@/store/namespaces'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { SimpleBuildPlanDetailsDto } from '@/types/FileExplorer/ItemDetails'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { ViewMode } from '@/types/FileExplorer/ViewMode'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const commonStore = namespace(StoresNamespaces.Common)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component
export default class DetailsUsedByBuildOrSinterPlan extends Vue {
  @commonStore.Getter tooltipOpenDelay: number
  @fileExplorerStore.Action openItemFolder: (itemId: string) => Promise<void>
  @fileExplorerStore.Mutation setViewMode: (viewMode: ViewMode) => void
  @fileExplorerStore.Action getParentFolder: (id: string) => Promise<FileExplorerItem>

  @Prop({ required: true }) readonly buildPlanDetails: SimpleBuildPlanDetailsDto
  @Prop({ default: false }) readonly isTargetShouldBeBlank: boolean

  editBuildPlanRouteName = RouterNames.EditBuildPlan
  editIbcPlanRouteName = RouterNames.FE_EditIbcPlan
  allFiles = RouterNames.FE_AllFiles
  rootFolderId = ROOT_FOLDER_ID

  get icon(): string {
    if (this.buildPlanDetails.itemType === ItemType.IbcPlan) {
      return 'mdi-fire'
    }

    switch (this.buildPlanDetails.subType) {
      case ItemSubType.None:
        return 'mdi-newspaper-variant'
      case ItemSubType.SinterPlan:
        return 'mdi-fire'
      default:
        return
    }
  }

  get itemColor(): string {
    if (this.buildPlanDetails.itemType === ItemType.IbcPlan) {
      return '#E86B5F'
    }

    switch (this.buildPlanDetails.subType) {
      case ItemSubType.None:
        return '#416AAA'
      case ItemSubType.SinterPlan:
        return '#E86B5F'
      default:
        return '#63666A'
    }
  }

  get formatUserRootLocation(): string {
    return `${this.$t('allFiles').toString().charAt(0).toUpperCase()}${this.$t('allFiles').toString().slice(1)}`
  }

  get linkTarget(): string {
    return this.isTargetShouldBeBlank ? '_blank' : '_self'
  }

  get buildPlanRoute() {
    return this.buildPlanDetails.itemType === ItemType.IbcPlan
      ? { name: this.editIbcPlanRouteName, params: { ibcPlanId: this.buildPlanDetails.id } }
      : { name: this.editBuildPlanRouteName, params: { id: this.buildPlanDetails.id } }
  }

  formatBuildPlanLocation(parentPathLabel: string): string {
    return parentPathLabel ? `${this.formatUserRootLocation}${parentPathLabel}` : this.formatUserRootLocation.toString()
  }

  async navigateToParentFolder() {
    if (this.isTargetShouldBeBlank) {
      const parentFolder = await this.getParentFolder(this.buildPlanDetails.id)
      const routeData = this.$router.resolve({
        name: RouterNames.FE_AllFiles,
        params: { itemId: parentFolder ? parentFolder.id : ROOT_FOLDER_ID },
      })
      window.open(routeData.href, this.linkTarget)
    } else {
      this.openItemFolder(this.buildPlanDetails.id)
    }
  }
}
