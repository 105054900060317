import { MutationTree } from 'vuex'
import { ICadHelperState } from './types'

export const mutations: MutationTree<ICadHelperState> = {
  setDownloadLink(state, link: string) {
    state.downloadUrl = link
  },

  setVersion(state, version: string) {
    state.version = version
  },
}
