
import Vue from 'vue'
import Component from 'vue-class-component'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import NumberField from '@/components/controls/Common/NumberField.vue'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { DuplicateToolState, IBuildPlan, IBuildPlanItem, ISelectable } from '@/types/BuildPlans/IBuildPlan'
import { Watch } from 'vue-property-decorator'
import { extend, ValidationObserver } from 'vee-validate'
import { PrintingTypes } from '@/types/IMachineConfig'
import { DuplicateAxes, DuplicateMode, DuplicatePayload } from '@/types/Duplicate/Duplicate'
import {
  X_SPACING_LEFT_LIMIT_1,
  X_SPACING_LEFT_LIMIT_2,
  X_SPACING_RIGHT_LIMIT_1,
  X_SPACING_RIGHT_LIMIT_2,
  Y_SPACING_LEFT_LIMIT_1,
  Y_SPACING_LEFT_LIMIT_2,
  Y_SPACING_RIGHT_LIMIT_1,
  Y_SPACING_RIGHT_LIMIT_2,
} from '@/constants'
import { eventBus } from '@/services/EventBus'
import { BuildPlanEvents } from '@/types/Label/BuildPlanEvents'

const GRID_PREVIEW_DEBOUNCE = 500

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const commonStore = namespace(StoresNamespaces.Common)

@Component({
  components: {
    NumberField,
  },
})
export default class BuildPlanDuplicateTab extends Vue implements IToolComponent {
  @buildPlansStore.Action createInstances: (hideAPIErrorMessages?: boolean) => Promise<void>
  @buildPlansStore.Action createVolumeGrid: Function
  @buildPlansStore.Action cancelDuplicateProcess: Function
  @buildPlansStore.Action clearUnfinishedInstances: Function

  @buildPlansStore.Mutation setDuplicateToolState: (payload: Partial<DuplicateToolState>) => void

  @visualizationStore.Mutation setInstancingIsRunning: (isRunning: boolean) => void

  @buildPlansStore.Getter getSelectedParts: ISelectable[]
  @buildPlansStore.Getter getAllBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getDuplicateToolState: DuplicateToolState
  @visualizationStore.Getter instancingIsRunning: boolean

  @commonStore.Getter tooltipOpenDelay: number

  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  duplicateAxes = DuplicateAxes
  duplicateMode = DuplicateMode

  duplicationProperties = {
    amountOfXCopies: 2,
    amountOfYCopies: 1,
    amountOfZCopies: 1,
    xSpacing: 2,
    ySpacing: 2,
    zSpacing: 2,
    mainAxis: DuplicateAxes.X,
    duplicatePartsIndependently: false,
    duplicateMode: DuplicateMode.BoundingBoxes,
  }

  isDuplicateDisabled = false
  isCancelDisabled = false
  isValid = true
  previewTimeout: number = null
  isCancelOrOkClicked = false
  digitsAfterDecimal: number = 3
  xSpacingIsFocused: boolean = false
  ySpacingIsFocused: boolean = false
  zSpacingIsFocused: boolean = false

  get isBinderJet() {
    return this.getBuildPlan.modality === PrintingTypes.BinderJet
  }

  get isXAxisDisabled() {
    return this.duplicationProperties.amountOfXCopies <= 1
  }

  get isYAxisDisabled() {
    return this.duplicationProperties.amountOfYCopies <= 1
  }

  get isZAxisDisabled() {
    return this.duplicationProperties.amountOfZCopies <= 1
  }

  get isAllAxesDisabled() {
    return this.isXAxisDisabled && this.isYAxisDisabled && this.isZAxisDisabled
  }

  /** Returns xSpacing valued based on a field's focus state. If field is focused - it does not mutate the value
   * in it. Value is changed only after the focusout event.
   */
  get xSpacingValue() {
    const value = this.duplicationProperties.xSpacing
    return this.xSpacingIsFocused ? value : value && (+value).toFixed(this.digitsAfterDecimal)
  }

  /** Returns ySpacing valued based on a field's focus state. If field is focused - it does not mutate the value
   * in it. Value is changed only after the focusout event.
   */
  get ySpacingValue() {
    const value = this.duplicationProperties.ySpacing
    return this.ySpacingIsFocused ? value : value && (+value).toFixed(this.digitsAfterDecimal)
  }
  /** Returns zSpacing valued based on a field's focus state. If field is focused - it does not mutate the value
   * in it. Value is changed only after the focusout event.
   */
  get zSpacingValue() {
    const value = this.duplicationProperties.zSpacing
    return this.zSpacingIsFocused ? value : value && (+value).toFixed(this.digitsAfterDecimal)
  }

  /**************************************
   * Generic tool method implementations
   **************************************/
  // need to mention these generic optional methods even if they are not implemented by the tool
  // due to TypeScript's weak type detection per https://stackoverflow.com/a/47930521
  clickCancel() {
    if (this.previewTimeout) {
      clearTimeout(this.previewTimeout)
    }

    this.isCancelOrOkClicked = true
    this.cancelDuplicateProcess()
    this.clearUnfinishedInstances()
    this.setInstancingIsRunning(false)
  }

  async clickOk() {
    this.isCancelOrOkClicked = true
    this.isCancelDisabled = true
    this.isDuplicateDisabled = true
    this.updateCancelStatus()
    this.updateOkStatus()
    await this.createInstances()
  }

  getOkName() {
    return 'save'
  }

  beforeMount() {
    this.extendValidationRules()
  }

  mounted() {
    this.isDuplicateDisabled = this.isAllAxesDisabled
    this.updateOkStatus()
    this.subscribeToEventBus()
  }

  subscribeToEventBus() {
    eventBus.$on(BuildPlanEvents.DuplicateToolStateChanged, this.setDuplicateToolState)
  }

  unsubscribeFromEventBus() {
    eventBus.$off(BuildPlanEvents.DuplicateToolStateChanged, this.setDuplicateToolState)
  }

  extendValidationRules() {
    extend('number', {
      validate: (value: string) => /^-?\d*(.\d*)?$/.test(value),
      message: this.$i18n.t('numberValidationMessage') as string,
    })

    extend('in_range_included', {
      validate: (
        value: number,
        { leftLimit, rightLimit, units }: { leftLimit: string; rightLimit: string; units: string },
      ) => {
        return value >= +leftLimit && value <= +rightLimit
      },
      params: ['leftLimit', 'rightLimit', 'units'],
      message: this.$i18n.t('inRangeValidationMessage') as string,
    })

    extend('in_ranges_included', {
      validate: (
        value: number,
        {
          leftLimit1,
          rightLimit1,
          leftLimit2,
          rightLimit2,
          units,
        }: { leftLimit1: string; rightLimit1: string; leftLimit2: string; rightLimit2: string; units: string },
      ) => {
        return (value >= +leftLimit1 && value <= +rightLimit1) || (value >= +leftLimit2 && value <= +rightLimit2)
      },
      params: ['leftLimit1', 'rightLimit1', 'leftLimit2', 'rightLimit2', 'units'],
      message: this.$i18n.t('inRangesValidationMessage') as string,
    })

    this.setInstancingIsRunning(true)
    this.createGridPreview()
  }

  updateOkStatus() {
    this.$emit('setOkDisabled', this.isDuplicateDisabled)
  }

  updateCancelStatus() {
    this.$emit('setCancelDisabled', this.isCancelDisabled)
  }

  @Watch('duplicationProperties', { deep: true })
  @Watch('instancingIsRunning')
  async onFormFailed() {
    this.$nextTick(async () => {
      if (!this.isAllAxesDisabled) {
        let mainAxis = this.duplicationProperties.mainAxis
        if (!mainAxis) {
          if (!this.isXAxisDisabled) {
            mainAxis = DuplicateAxes.X
          } else if (!this.isYAxisDisabled) {
            mainAxis = DuplicateAxes.Y
          } else if (!this.isZAxisDisabled) {
            mainAxis = DuplicateAxes.Z
          } else {
            mainAxis = null
          }
        }

        switch (mainAxis) {
          case DuplicateAxes.X:
            if (this.isXAxisDisabled) {
              mainAxis = null
            }

            break
          case DuplicateAxes.Y:
            if (this.isYAxisDisabled) {
              mainAxis = null
            }

            break
          case DuplicateAxes.Z:
            if (this.isZAxisDisabled) {
              mainAxis = null
            }

            break
          default:
            mainAxis = null
        }

        this.duplicationProperties.mainAxis = mainAxis
      } else {
        this.duplicationProperties.mainAxis = null
      }

      this.isValid = (await this.$refs.form.validate()) && !!this.duplicationProperties.mainAxis
      const isValid =
        this.isValid && !this.instancingIsRunning && !this.getDuplicateToolState.isDuplicateUnderBuildPlate

      this.isDuplicateDisabled = !isValid
      this.updateOkStatus()
    })
  }

  createGridPreview() {
    if (this.isValid) {
      const bpItems = this.getAllBuildPlanItems
      const itemsToInstantiate = this.getSelectedParts.map((item) => {
        const selectedBpItem = bpItems.find((bpItem) => bpItem.id === item.id)
        const { part } = selectedBpItem
        return {
          axisData: [
            {
              axisName: DuplicateAxes.X,
              isMain:
                this.duplicationProperties.duplicateMode === DuplicateMode.BoundingBoxes
                  ? this.getDuplicationMainAxisForBoundingBoxes() === DuplicateAxes.X
                  : this.duplicationProperties.mainAxis === DuplicateAxes.X,
              amount: this.duplicationProperties.amountOfXCopies,
              spacing: this.duplicationProperties.xSpacing,
            },
            {
              axisName: DuplicateAxes.Y,
              isMain:
                this.duplicationProperties.duplicateMode === DuplicateMode.BoundingBoxes
                  ? this.getDuplicationMainAxisForBoundingBoxes() === DuplicateAxes.Y
                  : this.duplicationProperties.mainAxis === DuplicateAxes.Y,
              amount: this.duplicationProperties.amountOfYCopies,
              spacing: this.duplicationProperties.ySpacing,
            },
            {
              axisName: DuplicateAxes.Z,
              isMain:
                this.duplicationProperties.duplicateMode === DuplicateMode.BoundingBoxes
                  ? this.getDuplicationMainAxisForBoundingBoxes() === DuplicateAxes.Z
                  : this.duplicationProperties.mainAxis === DuplicateAxes.Z,
              amount: this.duplicationProperties.amountOfZCopies,
              spacing: this.duplicationProperties.zSpacing,
            },
          ],

          buildPlanItemId: item.id,
          partId: part.id,
          copySupports: !this.isBinderJet,
        } as DuplicatePayload
      })

      this.createVolumeGrid({
        itemsToInstantiate,
        duplicatePartsIndependently: this.duplicationProperties.duplicatePartsIndependently,
        duplicateMode: this.duplicationProperties.duplicateMode,
      })
    } else {
      this.clearUnfinishedInstances()
      this.setInstancingIsRunning(false)
    }
  }

  onGridChanged() {
    this.cancelDuplicateProcess()
    this.setInstancingIsRunning(true)

    if (this.previewTimeout) {
      clearTimeout(this.previewTimeout)
    }

    this.previewTimeout = setTimeout(this.createGridPreview, GRID_PREVIEW_DEBOUNCE) as unknown as number
  }

  onMainAxisChanged(mainAxis: DuplicateAxes) {
    if (this.duplicationProperties.mainAxis === mainAxis) {
      return
    }

    this.duplicationProperties.mainAxis = mainAxis
    this.onGridChanged()
  }

  onDuplicateModeChanged(mode: DuplicateMode) {
    if (this.duplicationProperties.duplicateMode === mode) {
      return
    }

    this.duplicationProperties.duplicateMode = mode
    this.clearUnfinishedInstances()
    this.onGridChanged()
  }

  /** Changes spacing between duplicates along passed axis.
   * @param {string} value - spacing value
   * @param {string} axisSpacing - axis spacing property name
   */
  onSpacingChangeChange(value, axisSpacing) {
    this.duplicationProperties[axisSpacing] = +value
    this.onGridChanged()
  }

  getCopiesRules() {
    return {
      required: true,
      number: true,
      in_range_included: { leftLimit: '1', rightLimit: '100', units: '' },
    }
  }

  getXAndYSpacingRules() {
    return {
      required: true,
      number: true,
      in_ranges_included: {
        leftLimit1: X_SPACING_LEFT_LIMIT_1,
        rightLimit1: X_SPACING_RIGHT_LIMIT_1,
        leftLimit2: X_SPACING_LEFT_LIMIT_2,
        rightLimit2: X_SPACING_RIGHT_LIMIT_2,
        units: this.$i18n.t('millimeterAbbr'),
      },
    }
  }

  getBinderJetZSpacingRules() {
    return {
      required: true,
      number: true,
      in_ranges_included: {
        leftLimit1: Y_SPACING_LEFT_LIMIT_1,
        rightLimit1: Y_SPACING_RIGHT_LIMIT_1,
        leftLimit2: Y_SPACING_LEFT_LIMIT_2,
        rightLimit2: Y_SPACING_RIGHT_LIMIT_2,
        units: this.$i18n.t('millimeterAbbr'),
      },
    }
  }

  getDuplicationMainAxisForBoundingBoxes() {
    if (this.duplicationProperties.duplicateMode === DuplicateMode.BoundingBoxes) {
      if (this.duplicationProperties.amountOfXCopies > 1) {
        return DuplicateAxes.X
      }

      if (this.duplicationProperties.amountOfYCopies > 1) {
        return DuplicateAxes.Y
      }

      if (this.duplicationProperties.amountOfZCopies > 1) {
        return DuplicateAxes.Z
      }
    }
  }

  beforeDestroy() {
    if (!this.isCancelOrOkClicked) {
      this.clickCancel()
    }

    this.isCancelDisabled = false
    this.isDuplicateDisabled = false
    this.updateCancelStatus()
    this.updateOkStatus()
    this.unsubscribeFromEventBus()
  }
}
