
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Emit, Watch } from 'vue-property-decorator'

import { SnackbarMessageType } from '@/types/messages/SnackbarMessageType'
import SnackbarMessage from '@/utils/snackbar/SnackbarMessage'
import { SNACKBAR_TIMEOUT, SNACKBAR_EVENT, SNACKBAR_SLEEP_TIME } from '@/constants'

const sleepSnack = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

@Component
export default class Snackbar extends Vue {
  isShown = false
  message: string = ''
  type: SnackbarMessageType = null
  timeout = SNACKBAR_TIMEOUT
  multiline = false
  showCloseButton = false

  items: SnackbarMessage[] = []

  mounted() {
    window.addEventListener(SNACKBAR_EVENT, this.onReceiveMessage)
    this.$router.afterEach(() => {
      this.items = []
      this.isShown = false
    })
  }

  beforeDestroy() {
    this.reset()
  }

  destroyed() {
    window.removeEventListener(SNACKBAR_EVENT, this.onReceiveMessage)
  }

  onReceiveMessage(event: Event) {
    if (event instanceof MessageEvent && event.data instanceof SnackbarMessage) {
      this.items.push(event.data)
      if (!this.isShown) {
        this.isShown = true
      }
    }
  }

  @Watch('isShown')
  async onShowChange(isShown: boolean) {
    if (isShown) {
      const item = this.items.shift()
      if (item) {
        this.message = item.message
        this.type = item.type
        this.timeout = item.timeout
        this.multiline = item.multiline
        this.showCloseButton = item.closeable
      } else {
        this.isShown = false
      }
    } else {
      await sleepSnack(SNACKBAR_SLEEP_TIME)
      if (this.items.length > 0) {
        this.isShown = true
      } else {
        this.reset()
      }
    }
  }

  reset() {
    this.items = []
    this.message = ''
    this.type = null
    this.timeout = SNACKBAR_TIMEOUT
    this.multiline = false
    this.showCloseButton = true
  }
}
