import { InertGas, InertGasType } from '@/types/BuildPlans/IBuildPlan'

export function getInertGasTypeFromFilename(filename: string, inertGases: InertGas[]): InertGasType {
  // Ar|N|He etc.
  const aliases = Array.from(new Set(inertGases.map((inertGas) => inertGas.alias))).join('|')
  const reMatchAlias = new RegExp(`_(${aliases})_`)
  const result = filename.match(reMatchAlias)

  let inertGasType: InertGasType

  if (result) {
    const foundAlias = result[1]
    const foundInertGas = inertGases.find((inertGas) => inertGas.alias === foundAlias)

    if (foundInertGas) {
      inertGasType = foundInertGas.inertGasType
    }
  }

  return inertGasType
}
