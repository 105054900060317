import { LabelDirtyState, MarkingLocation } from '@/types/Label/enums'
import { createGuid } from '@/utils/common'

export interface TrackableLabel {
  id: string
  isDirty: boolean
  dirtyState: LabelDirtyState
  commandId: string
  errorCode: ErrorCodes
}

export enum ErrorCodes {
  CommonError = 1000,
}

export interface ManualTrackableLabel extends TrackableLabel {
  manualPlacementId: string
}

export interface AutomatedTrackableLabel extends TrackableLabel {
  bodyId: string
  autoLocation: MarkingLocation
}

/**
 * Creates manual label as a trackable object
 * @param {string} manualPlacementId - id of a manual placement, that represents the label
 * @param {LabelDirtyState} dirtyState - state of label
 * @param {string} id - trackable object id
 * @returns {ManualTrackableLabel} - manual label as a trackable object
 */
export const createManualTrackableLabel = (
  manualPlacementId: string,
  dirtyState?: LabelDirtyState,
  errorCode?: ErrorCodes,
  id?: string,
): ManualTrackableLabel => {
  return {
    manualPlacementId,
    commandId: null,
    dirtyState: dirtyState || LabelDirtyState.None,
    errorCode: errorCode || null,
    id: id || createGuid(),
    isDirty: dirtyState !== LabelDirtyState.None,
  }
}

/**
 * Creates automated label as a trackable object
 * @param {string} bodyId - guid of a body with id, which label is placed onto
 * @param {MarkingLocation} autoLocation - auto location of a label
 * @param {LabelDirtyState} dirtyState - state of label
 * @param {string} id - trackable object id
 * @returns {ManualTrackableLabel} - manual label as a trackable object
 */
export const createAutomatedTrackableLabel = (
  bodyId: string,
  autoLocation: MarkingLocation,
  dirtyState?: LabelDirtyState,
  errorCode?: ErrorCodes,
  id?: string,
): AutomatedTrackableLabel => {
  return {
    bodyId,
    autoLocation,
    commandId: null,
    dirtyState: dirtyState || LabelDirtyState.None,
    errorCode: errorCode || null,
    id: id || createGuid(),
    isDirty: dirtyState !== LabelDirtyState.None,
  }
}
