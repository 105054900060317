
import Vue from 'vue'
import Component from 'vue-class-component'
import IssuesContainers from '@/components/layout/buildPlans/insight/group/IssuesContainers.vue'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import IssueWrapper from '@/components/layout/buildPlans/insight/group/IssueWrapper.vue'
import {
  IBuildPlanInsight,
  IInsightSettings,
  InsightErrorCodes,
  InsightGroups,
} from '@/types/BuildPlans/IBuildPlanInsight'
import IssuesGroup from '@/components/layout/buildPlans/insight/group/IssuesGroup.vue'
import { InsightsSeverity } from '@/types/Common/Insights'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component({
  components: { IssuesContainers, IssueWrapper, IssuesGroup },
})
export default class ByInsight extends Vue {
  insightsSeverity = InsightsSeverity
  insightGroups = InsightGroups

  @buildPlansStore.Getter insights: IBuildPlanInsight[]
  @buildPlansStore.Getter insightSettings: IInsightSettings

  get errors() {
    return this.groupByErrorCode(this.insights.filter((i: IBuildPlanInsight) => i.severity === InsightsSeverity.Error))
  }

  get warnings() {
    return this.groupByErrorCode(
      this.insights.filter((i: IBuildPlanInsight) => i.severity === InsightsSeverity.Warning),
    )
  }

  groupByErrorCode(insights: IBuildPlanInsight[]) {
    let issuesMap: Partial<{ [key in InsightErrorCodes]: IBuildPlanInsight[] }> = null

    insights.forEach((i: IBuildPlanInsight) => {
      // Skip display of SimCompToolMeshInfo
      if (i.errorCode === InsightErrorCodes.SimCompToolMeshInfo) {
        return
      }
      // Skip label issues with empty related items
      if (i.tool === ToolNames.LABEL && (!i.details || !i.details.relatedItems || !i.details.relatedItems.length)) {
        return
      }
      if (!issuesMap) {
        issuesMap = { [i.errorCode]: [i] }
        return
      }
      if (!issuesMap[i.errorCode]) {
        issuesMap[i.errorCode] = [i]
        return
      }
      if (i.errorCode === InsightErrorCodes.LayoutToolPartIntersectsPart) {
        const hasIntersectError = issuesMap[i.errorCode].some(
          (insight: IBuildPlanInsight) =>
            (insight.details.bpItemId && insight.details.bpItemId === i.details.bpItemId) ||
            insight.details.meshId === i.details.meshId,
        )
        if (hasIntersectError) {
          return
        }
      }
      issuesMap[i.errorCode].push(i)
    })
    return issuesMap
  }
}
