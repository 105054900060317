
import {
  BodyMenuType,
  GeometryType,
  IPrintStrategyParameterSet,
  PartProperty,
  PartTypes,
  ProcessState,
} from '@/types/BuildPlans/IBuildPlan'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import BodyDropdownMenu from '@/components/layout/buildPlans/addPart/BodyDropdownMenu.vue'
import StoresNamespaces from '@/store/namespaces'
import PartPropertyItemMixin from '@/components/layout/buildPlans/mixins/PartPropertyItemMixin'
import { BodyOption, PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { getDefaultBaseOnType, getDefaultNameBasedOnBodyType } from '@/utils/parameterSet/parameterSetUtils'
import { convert } from '@/utils/converter/lengthConverter'
import { VersionableModel } from '@/types/Common/VersionableModel'
import LockedBodyFunctionModal from '../modals/LockedBodyFunctionModal.vue'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

export enum PropNames {
  Type = 'type',
  PrintStrategyParameterSetPk = 'printStrategyParameterSetPk',
  ProcessState = 'processState',
}
export enum PrintStrategyParameterSetPkPropSubNames {
  Id = 'printStrategyParameterSetId',
  Version = 'printStrategyParameterSetVersion',
}

@Component({
  components: {
    BodyDropdownMenu,
    LockedBodyFunctionModal,
  },
})
export default class MultiPartPropertySelector extends PartPropertyItemMixin {
  @buildPlansStore.Getter isSinglePartPropertyMode: boolean
  @Prop() readonly partsProperties: {
    [id: string]: PartProperty[]
  }
  @Prop() readonly parts: PartListItemViewModel[]

  isAssignToAllBodies: boolean = false
  selectedBodyType: GeometryType = null
  selectedParameterSetPk: VersionablePk = null
  selectedProcessState: ProcessState = null
  isShownLockedBodyFunctionModal: boolean = false

  @Watch('isAssignToAllBodies')
  onBodiesToggleChange(value: boolean) {
    if (!value) {
      return
    }

    if (this.selectedBodyType) {
      this.$emit('property-change', {
        sourceProperty: {
          propertyName: PropNames.Type,
          propertyValue: this.selectedBodyType,
        },
        isAssignToAllBodies: value,
      })
    }

    if (this.selectedParameterSetPk) {
      this.$emit('property-change', {
        sourceProperty: {
          propertyName: PropNames.PrintStrategyParameterSetPk,
          propertyValue: this.selectedParameterSetPk,
        },
        isAssignToAllBodies: value,
      })
    }

    if (this.selectedProcessState) {
      this.$emit('property-change', {
        sourceProperty: {
          propertyName: PropNames.ProcessState,
          propertyValue: this.selectedProcessState,
        },
        isAssignToAllBodies: value,
      })
    }
  }

  onBodyTypeChange(type: GeometryType) {
    this.selectedBodyType = type

    this.$emit('property-change', {
      sourceProperty: {
        propertyName: PropNames.Type,
        propertyValue: type,
      },
      isAssignToAllBodies: this.isAssignToAllBodies,
    })

    // When multiple parts with some published from sinter plans are selected
    // and body type function is changed - show locked body function modal
    if (this.lockedBodyFunctionParts.length) {
      this.toggleLockedBodyFunctionModal()
    }
  }

  onBodyParameterSetChange(printStrategyParameterSetPk: VersionablePk) {
    this.selectedParameterSetPk = printStrategyParameterSetPk

    this.$emit('property-change', {
      sourceProperty: {
        propertyName: PropNames.PrintStrategyParameterSetPk,
        propertyValue: printStrategyParameterSetPk,
      },
      isAssignToAllBodies: this.isAssignToAllBodies,
    })
  }

  onBodyProcessStateChange(processState: ProcessState) {
    this.selectedProcessState = processState

    this.$emit('property-change', {
      sourceProperty: {
        propertyName: PropNames.ProcessState,
        propertyValue: processState,
      },
      isAssignToAllBodies: this.isAssignToAllBodies,
    })
  }

  get processStateMenuType() {
    return BodyMenuType.ProcessState
  }

  get isEverySelectedPartLegacy() {
    return this.parts.every((part) => part.isPublishedAsScaled)
  }

  get processStateOptions(): BodyOption[] {
    const options = [
      {
        value: ProcessState.Nominal,
        name: this.$i18n.t('applyScaling').toString(),
        icon: 'mdi-resize',
      },
      {
        value: ProcessState.Green,
        name: this.isEverySelectedPartLegacy
          ? this.$t('simulationScaled').toString()
          : this.$t('doNotScale').toString(),
        icon: this.isEverySelectedPartLegacy ? 'mdi-resize' : 'mdi-rectangle-outline',
      },
    ]

    return options.map((option) => {
      const name: string = option.name.toString()
      option.name = name.charAt(0).toUpperCase() + name.substring(1)
      return option
    })
  }

  get getBodyTypeInitialValue() {
    if (this.selectedBodyType) {
      return this.selectedBodyType
    }
    const firstBodyType = this.partsProperties[Object.keys(this.partsProperties)[0]][0].type
    const isBodyTypeEqual = Object.values(this.partsProperties).every((pp) => {
      return pp.every((body) => body.type === firstBodyType)
    })

    return isBodyTypeEqual ? firstBodyType : null
  }

  get getProcessStateInitialValue() {
    if (!this.isSinglePartPropertyMode) {
      return
    }
    if (this.selectedProcessState) {
      return this.selectedProcessState
    }

    const firstProcessState = this.partsProperties[Object.keys(this.partsProperties)[0]][0].processState
    const isProcessStateEqual = Object.values(this.partsProperties).every((pp) => {
      return pp.every((body) => body.processState === firstProcessState)
    })

    return isProcessStateEqual ? firstProcessState : null
  }

  get getParameterSetInitialValue() {
    if (this.selectedParameterSetPk) {
      const selectedOption = this.bodyParameterOptions.find((option) => {
        return option.value instanceof VersionablePk
          ? option.value.equals(new VersionablePk(this.selectedParameterSetPk.id, this.selectedParameterSetPk.version))
          : false
      })
      if (selectedOption) {
        return selectedOption.value
      }
    }
    const defaultOption = this.bodyParameterOptions.find((x) => (x.value as VersionablePk).id === null)
    const defaultOptionValue = defaultOption && defaultOption.value

    const firstPartProperty = this.partsProperties[Object.keys(this.partsProperties)[0]][0]
    const isParameterSetEqual = Object.values(this.partsProperties).every((pp) => {
      return pp.every(
        (body) =>
          body.printStrategyParameterSetId === firstPartProperty.printStrategyParameterSetId &&
          body.printStrategyParameterSetVersion === firstPartProperty.printStrategyParameterSetVersion,
      )
    })

    if (isParameterSetEqual) {
      const bodyOption = this.bodyParameterOptions.find((option) => {
        return option.value instanceof VersionablePk
          ? option.value.equals(
              new VersionablePk(
                firstPartProperty.printStrategyParameterSetId,
                firstPartProperty.printStrategyParameterSetVersion,
              ),
            )
          : null
      })

      return bodyOption ? bodyOption.value : defaultOptionValue
    }

    return defaultOptionValue
  }

  get isProcessStateDisabled() {
    if (this.isSinterPlan) {
      return true
    }

    return this.isEverySelectedPartLegacy
  }

  // override getter of PartPropertyItemMixin
  get bodyParameterOptions(): BodyOption[] {
    const defaults = this.getBuildPlanPrintStrategy.defaults
    const params = [...this.parameters]
    // getBodyTypeInitialValue returns value only if all of items has same type
    if (this.getBodyTypeInitialValue) {
      let bodyType = null
      if (this.getBodyTypeInitialValue === GeometryType.Support) {
        const firstBodyType = this.partsProperties[Object.keys(this.partsProperties)[0]][0].bodyType
        const isBodyTypeEqual = Object.values(this.partsProperties).every((pp) => {
          return pp.every((body) => body.bodyType === firstBodyType)
        })
        // if all of the items has same boyd type - use it, if not - use default(null)
        bodyType = isBodyTypeEqual ? firstBodyType : bodyType
      }
      const printStrategyParameterSetPk = getDefaultBaseOnType(defaults, this.getBodyTypeInitialValue, bodyType)
      const defaultParam: IPrintStrategyParameterSet = this.parameters.find(
        (parameter) =>
          parameter.id === printStrategyParameterSetPk.id && parameter.version === printStrategyParameterSetPk.version,
      )

      if (this.defaultPartParamOptionAvailable) {
        if (defaultParam) {
          const defaultOption = {
            name: getDefaultNameBasedOnBodyType(this.getBodyTypeInitialValue, bodyType),
            id: null,
            parameterSetId: defaultParam.parameterSetId,
            allowSimCom: defaultParam.allowSimCom,
            layerThickness: defaultParam.layerThickness,
            parameterSet: defaultParam.parameterSet,
            parameterSetVersion: defaultParam.parameterSetVersion,
            version: 1,
            implicitVersion: defaultParam.implicitVersion,
          }
          params.unshift(defaultOption)
        } else {
          const defaultOption = {
            name: getDefaultNameBasedOnBodyType(this.getBodyTypeInitialValue, bodyType),
            id: null,
          }
          params.unshift(defaultOption as IPrintStrategyParameterSet)
        }
      }
    }

    const dropdownOprions = params.map((parameter) => {
      const layerThickness = parseFloat(convert('m', 'mm', parameter.layerThickness).toPrecision(3))
      const thicknessText = parameter.id ? ` - ${layerThickness}mm` : ''
      const name = parameter.name + thicknessText
      return {
        name,
        value: VersionableModel.getPk(parameter),
      }
    })
    return dropdownOprions
  }

  get shouldDisplayProcessState() {
    return this.isSinglePartPropertyMode && !this.isSinterPlan
  }

  get lockedBodyFunctionParts() {
    return this.parts.filter((part) => part.partType !== PartTypes.BuildPlanPart)
  }

  toggleLockedBodyFunctionModal() {
    this.isShownLockedBodyFunctionModal = !this.isShownLockedBodyFunctionModal
  }
}
