import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: { cspNonce: 'dQw4w9WgXcQ' },
    themes: {
      light: {
        accent: '#82B1FF',
        blue: '#005EB8',
        dark_grey: '#677E8C',
        error: '#FF5252',
        info: '#2196F3',
        main: '#13294b',
        secondary: '#00b5e2',
        success: '#4CAF50',
        warning: '#FF9800',
        panel_base: '#F1F3F7',
        panel_dark: '#DFE4EC',
        panel_darker: '#C6CDD5',
        primary_blue: '#005EB8',
        primary_grey: '#63666A',
        primary_text: '#13294B',
        primary_text_85: '#364966',
        primary_text_70: '#596981',
        secondary_grey: '#B1B3B3',
        secondary_blue: '#00B5E2',
        accent_orange: '#D75447',
        accent_red: '#D75447',
        accent_green: '#00BF6F',
        accent_yellow: '#FAED64',
      },
    },
  },
})
