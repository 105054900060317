
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Button from '@/components/controls/Common/Button.vue'

@Component({
  components: {
    Button,
  },
})
export default class ReplaceLabeledPartModal extends Vue {
  @Prop({ required: true }) value: boolean

  onCloseClick() {
    this.$emit('close')
  }
}
