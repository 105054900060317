var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"nav-menu d-flex flex-column"},_vm._l((_vm.navigationItems),function(item){return (!item.hidden)?_c('div',{key:item.name,class:{
      'text t-16 mb-5 d-flex no-select menu-item': true,
      pointer: _vm.activePageName !== item.name || _vm.activePageMetaName !== item.name,
      'menu-item--active font-weight-bold primary_blue--text':
        _vm.activePageName === item.name || _vm.activePageMetaName === item.name,
      'menu-item--margin-bottom': item.hasMarginBottom,
    },attrs:{"data-test":item.testAttribute}},[_c('div',{staticClass:"d-flex label-wrapper",on:{"click":function($event){return _vm.route(item.name)}}},[_c('v-icon',{staticClass:"menu-item-icon mr-2",attrs:{"size":"20","color":_vm.activePageName === item.name || _vm.activePageMetaName === item.name ? 'blue' : 'primary_text_70'}},[_vm._v(" "+_vm._s(item.mdi)+" ")]),_c('div',{staticClass:"menu-item-label",attrs:{"data-test":item.label}},[_vm._v(_vm._s(item.label))])],1),(item.divide)?_c('div',{staticClass:"menu-item-divide full-width"}):_vm._e()]):_vm._e()}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }