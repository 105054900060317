
import Vue from 'vue'
import Component from 'vue-class-component'
import IToolComponent from '@/types/BuildPlans/IToolComponent'

@Component
export default class BuildPlanScaleTab extends Vue implements IToolComponent {
  /**************************************
   * Generic tool method implementations
   **************************************/
  // need to mention these generic optional methods even if they are not implemented by the tool
  // due to TypeScript's weak type detection per https://stackoverflow.com/a/47930521
  clickOk?: () => void
  clickCancel?: () => void
}
