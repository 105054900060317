
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/components/controls/Common/Button.vue'
import Selector from '@/components/controls/Common/Selector.vue'
import CollaboratorsListItem from '@/components/layout/FileExplorer/CollaboratorsListItem.vue'
import DetailsManageCollaborators from '@/components/layout/FileExplorer/Details/DetailsManageCollaborators.vue'
import messageService from '@/services/messageService'
import { ItemDetailsPayload } from '@/store/modules/fileExplorer/types'
import StoresNamespaces from '@/store/namespaces'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemAction } from '@/types/FileExplorer/ItemAction'
import {
  ItemPermissionsRole,
  ModalLabelType,
  Permission,
  PermissionCollaborator,
  PermissionCollaboratorViewModel,
} from '@/types/FileExplorer/Permission'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import { IUser } from '@/types/User/IUser'
import { getAvailableRolesForShare, getRoleId, getRoleLabel } from '@/utils/user'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: {
    Button,
    Selector,
    CollaboratorsListItem,
  },
})
export default class ShareItemModal extends Mixins(DetailsManageCollaborators) {
  @fileExplorerStore.Getter permissionsByItemId: Permission[]
  @fileExplorerStore.Getter find: (id: string | number) => FileExplorerItem
  @fileExplorerStore.Getter getSelectedItem: FileExplorerItem

  @fileExplorerStore.Action fetchItemDetails: (payload: ItemDetailsPayload) => Promise<void>

  @fileExplorerStore.Mutation unsetSharedItem: (item: FileExplorerItem) => void
  @fileExplorerStore.Mutation unselectItem: (payload: { item: FileExplorerItem; selectionType: SelectionTypes }) => void
  @fileExplorerStore.Mutation clearCollaboratorsToRemoveList: () => void

  @Prop({ type: Array }) items: FileExplorerItem[]
  @Prop({ required: true }) item: FileExplorerItem

  dialog: boolean = true
  collaboratorList: PermissionCollaboratorViewModel[] = []
  itemsHolder: FileExplorerItem[] = []
  selectedUser: IUser = null
  selectedRole: ItemPermissionsRole = ItemPermissionsRole.Viewer
  message: string = ''

  deleteLabels: { confirmLabel: string; cancelLabel: string } = {
    confirmLabel: this.$root.$t('remove') as string,
    cancelLabel: this.$root.$t('cancel') as string,
  }

  confirmModalLabels: { [key in ModalLabelType]: { confirmLabel: string; cancelLabel: string } } = {
    [ModalLabelType.Delete]: {
      ...this.deleteLabels,
    },
    [ModalLabelType.DeleteInherited]: {
      ...this.deleteLabels,
    },
    [ModalLabelType.ChangeInherited]: {
      confirmLabel: this.$root.$t('change') as string,
      cancelLabel: this.$root.$t('cancel') as string,
    },
  }

  get confirmLabel() {
    return this.confirmModalLabels[this.modalLabelType].confirmLabel
  }

  get cancelLabel() {
    return this.confirmModalLabels[this.modalLabelType].cancelLabel
  }

  destroyed() {
    this.unsetCollaborationData()
  }

  get role() {
    return getRoleLabel(this.selectedRole)
  }

  set role(value) {
    this.selectedRole = getRoleId(value)
  }

  get isCollaboratorListChanged() {
    return this.collaboratorList.some(
      (collaborator: PermissionCollaboratorViewModel) => !collaborator.id || collaborator.hasChanged,
    )
  }

  get isCollaboratorsToRemoveList() {
    return this.getCollaboratorsToRemoveList && this.getCollaboratorsToRemoveList.length
  }

  get roles() {
    return Object.values(getAvailableRolesForShare())
  }

  @Watch('item')
  async watchItem(item) {
    if (item) {
      if (this.isCollaboratorsToRemoveList) return

      this.isLoading = false
      await this.getCollaborationData({ itemId: this.item.id, includeParentPermissions: true })
      this.createCollaboratorList()
      this.initPermissionsMap()

      await this.initInaccessibleItemsMap()
    }
  }

  initPermissionsMap() {
    this.collaborators.forEach((collaborator: PermissionCollaborator) => {
      this.mapUserIdToInitialRole.set(collaborator.grantedTo, collaborator.role)
    })
  }

  getAvailableRoles(collaborator: PermissionCollaboratorViewModel) {
    const allRoles = getAvailableRolesForShare()
    if (collaborator.grantedTo === this.userDetails.id) {
      return [allRoles[collaborator.role]]
    }
    return Object.values(allRoles)
  }

  close(shouldFetchDetails: boolean = true) {
    this.clearCollaboratorsToRemoveList()

    if ((!this.items || this.items.length === 1) && shouldFetchDetails) {
      this.fetchItemDetails({ itemId: this.item.id, itemType: this.item.itemType })
    }

    let isUnsetSharedItem = false
    // While closing we should check permissions that were applied earlier and not check collaborator's list
    // because there is a chance this list to contain unapplied collaborators
    if (this.items && this.items.length === 1) {
      const permissions = this.permissionsByItemId[this.item.id]
      const collaboratorsToCreate = this.collaboratorList.filter((collaborator) => collaborator.id === null)
      if (
        (!permissions || (permissions && !permissions.length)) &&
        !collaboratorsToCreate.length &&
        this.find(this.item.id)
      ) {
        this.unsetSharedItem(this.item)
        isUnsetSharedItem = true
      }
    }

    this.dialog = false
    this.$emit('onClose', { isUnsetSharedItem, isEndCollaboration: this.isEndCollaboration })
  }

  async share() {
    if (this.isCollaboratorsToRemoveList) await this.removeCollaborators()
    if (this.isCollaboratorListChanged) await this.shareWithCollaborators()

    this.close(false)
  }

  async removeCollaborators() {
    try {
      for (const collaborator of this.getCollaboratorsToRemoveList) {
        await this.removeCollaborator(collaborator)
      }

      if (!this.onBuildPlanPage) {
        const isFound = this.find(this.item.id)
        if (isFound) {
          await this.fetchItemDetails({ itemId: this.item.id, itemType: this.item.itemType })
        } else if (this.getSelectedItem) {
          await this.fetchItemDetails({ itemId: this.getSelectedItem.id, itemType: this.getSelectedItem.itemType })
        }
      }
    } catch (error) {
      messageService.showErrorMessage(error.message)
    } finally {
      this.clearCollaboratorsToRemoveList()
    }
  }

  async shareWithCollaborators() {
    const { id } = this.item
    await this.setLastAction({ itemId: id, action: ItemAction.Shared })
    this.onApplyPermissions(this.message)
  }
}
