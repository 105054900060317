
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Menu from '@/components/controls/Common/Menu.vue'
import { IItemTypeFilterValue, ItemFieldType, ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { ViewMode } from '@/types/FileExplorer/ViewMode'
import { FilterBy, FilterParamsKey, ItemTypeFilterParams } from '@/types/FileExplorer/FilterParamsKey'
import StoresNamespaces from '@/store/namespaces'
import { RouterNames } from '@/router'
import cloneDeep from 'lodash/cloneDeep'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: {
    Menu,
  },
})
export default class ItemTypeSelector extends Vue {
  @fileExplorerStore.Mutation('setRoot') setRoot: (item: any) => void
  @fileExplorerStore.Mutation unselectAllItems: () => void

  @fileExplorerStore.Action updateFilter: (filterParams: { key: string; filterBy: FilterBy }) => void

  @fileExplorerStore.State filterParams: FilterBy

  @fileExplorerStore.Getter getViewMode: ViewMode

  @Prop() selectorType: string
  @Prop({ default: () => null }) items: ItemTypeFilterParams[]
  @Prop({ default: 'allTypes' }) fullSelectionLabel: string
  @Prop({ default: 'itemType' }) field: string
  @Prop({ default: 180 }) width: number
  @Prop({ default: false }) isDisabled: boolean

  get itemsFormatted() {
    return this.itemTypeMap.map((item) => {
      return { ...item, checked: this.isChecked(item.value) }
    })
  }

  get itemTypeMap(): ItemTypeFilterParams[] {
    const itemTypes = this.items
      ? this.items
      : [
          {
            value: { fieldType: ItemFieldType.ItemType, typeValue: ItemType.Folder } as IItemTypeFilterValue,
            name: this.$i18n.t('folders'),
            testClass: 'folder-selector',
          },
          {
            value: { fieldType: ItemFieldType.ItemType, typeValue: ItemType.BuildPlan } as IItemTypeFilterValue,
            name: this.$i18n.t('buildPlans'),
            testClass: 'build-plan-selector',
          },
          {
            value: { fieldType: ItemFieldType.ItemSubType, typeValue: ItemSubType.SinterPlan } as IItemTypeFilterValue,
            name: this.$i18n.t('sinterPlans'),
            testClass: 'sinter-plans-selector',
          },
          {
            value: { fieldType: ItemFieldType.ItemType, typeValue: ItemType.BuildPart } as IItemTypeFilterValue,
            name: this.$i18n.t('parts'),
            testClass: 'part-selector',
          },
        ]

    if (!this.items && this.$route.name === RouterNames.FE_Search) {
      itemTypes.push({
        value: { fieldType: ItemFieldType.ItemType, typeValue: ItemType.PrintOrder } as IItemTypeFilterValue,
        name: this.$i18n.t('printOrders'),
        testClass: 'print-orders',
      })
    }

    if (this.selectorType === FilterParamsKey.AllFiles && this.getViewMode === ViewMode.List) {
      // If we go to a list mode then remove folder option
      itemTypes.shift()
      // If we go to a list mode then we should remove folder from filter if it was checked before
      if (
        this.filterParams[FilterParamsKey.AllFiles] &&
        this.filterParams[FilterParamsKey.AllFiles].value &&
        this.filterParams[FilterParamsKey.AllFiles].value.length
      ) {
        const values = this.filterParams[FilterParamsKey.AllFiles].value.filter(
          (value) => value.typeValue !== ItemType.Folder,
        )
        if (values.length !== this.filterParams[FilterParamsKey.AllFiles].value.length) {
          const newFilterState = { field: this.field, value: values }
          this.updateFilter({ key: this.selectorType, filterBy: newFilterState })
        }
      }
    }

    return itemTypes
  }

  onUpdateFilter(id) {
    if (this.isDisabled) {
      return
    }

    let newFilterState: FilterBy
    if (this.filterParams[this.selectorType]) {
      const filterStateClone: FilterBy = cloneDeep(this.filterParams[this.selectorType])
      const optionIndex =
        filterStateClone && filterStateClone.value.findIndex((f) => JSON.stringify(f) === JSON.stringify(id))
      if (optionIndex >= 0) {
        filterStateClone.value.splice(optionIndex, 1)
      } else {
        filterStateClone.value.push(id)
      }

      newFilterState = filterStateClone
    } else {
      newFilterState = { field: this.field, value: [id] }
    }

    this.unselectAllItems()

    this.updateFilter({ key: this.selectorType, filterBy: newFilterState })
  }

  toggleAll() {
    if (this.isDisabled) {
      return
    }

    this.updateFilter({
      key: this.selectorType,
      filterBy: { field: this.field, value: [] },
    })
  }

  get isAllSelected() {
    // true if everything is checked or everything is unchecked
    if (
      !this.filterParams ||
      !this.filterParams[this.selectorType] ||
      !this.filterParams[this.selectorType].value.length
    ) {
      return true
    }
    // since list mode don't have folders, we need to compare objects without folder option for list mode
    if (this.getViewMode === ViewMode.Folders) {
      return this.filtersAreEqual(this.itemTypeMap, this.filterParams[this.selectorType])
    }

    const paramsWithoutFolders = {
      field: this.filterParams[this.selectorType].field,
      value: this.filterParams[this.selectorType].value.filter(
        (f) => !(f.fieldType === ItemFieldType.ItemType && f.typeValue === ItemType.Folder),
      ),
    }

    return paramsWithoutFolders.value.length === 0 || this.filtersAreEqual(this.itemTypeMap, paramsWithoutFolders)
  }

  filtersAreEqual(map, filter): boolean {
    return (
      map.length === filter.value.length &&
      !this.itemTypeMap.some((i) => filter.value.findIndex((f) => JSON.stringify(f) === JSON.stringify(i.value)) < 0)
    )
  }

  isChecked(value): boolean {
    return (
      !!this.filterParams[this.selectorType] &&
      this.filterParams[this.selectorType].value.findIndex((f) => JSON.stringify(f) === JSON.stringify(value)) >= 0
    )
  }

  // remove filter params if they are not in the list of all filter params
  beforeMount() {
    if (!this.items || !this.filterParams[this.selectorType]) {
      return
    }

    const allFilterParams = this.items.map((item) => item.value)
    this.filterParams[this.selectorType].value = this.filterParams[this.selectorType].value.filter((filterParam) =>
      allFilterParams.includes(filterParam),
    )
  }

  get selectedItemTypeLabel() {
    const selectedItems = this.itemsFormatted.filter((item) => item.checked).map((item) => item.name)

    if (this.isAllSelected && selectedItems.length !== 1) {
      return this.$i18n.t(this.fullSelectionLabel)
    }

    if (this.itemsFormatted.length) {
      return selectedItems.join(', ')
    }
  }
}
