import { render, staticRenderFns } from "./SummaryPanel.vue?vue&type=template&id=12723259&scoped=true&lang=html"
import script from "./SummaryPanel.vue?vue&type=script&lang=ts"
export * from "./SummaryPanel.vue?vue&type=script&lang=ts"
import style0 from "./SummaryPanel.vue?vue&type=style&index=0&id=12723259&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12723259",
  null
  
)

export default component.exports