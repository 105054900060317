
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class TenantAdminContentTableControls extends Vue {
  @Prop({ default: false }) canBeEdited: boolean
  @Prop({ default: false }) canBeDeleted: boolean
  @Prop({ default: false }) canBeCopied: boolean

  remove() {
    this.$emit('onRemove')
    return null
  }

  copy() {
    this.$emit('onCopy')
    return null
  }

  edit() {
    return null
  }
}
