import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { ILabelState } from './types'
import { IRootState } from '@/store/types'

export const state: ILabelState = {
  contentElements: [],
  labelSets: [],
  manualPlacements: {},
  activeLabelSet: null,
  activeTextElement: null,
  listOfTextElements: [],
  labelSetsSnapshot: null,
  isLabelSetOpened: false,
  isPatchedBodiesSelected: false,
  isLabelToolOpened: false,
  isRunning: false,
  cachedLabelInsights: [],
  executeTimeout: null,
  isSilent: false,
  lastDeletedElementIDs: [],
  activeSetLastSuccessfulManualPlacements: [],
  labelToolIsValid: true,
  activeLabelSetIsValid: false,
  cachedPatches: {},
  saveComplete: false,
  isLabelExecuteTriggered: false,
  labelUpdateInProgress: false,
  labelSetsIDsToUpdate: [],
  watchToolReady: false,
  settingsAreValid: true,
  userSettingsAreValid: true,
  highlightedLabelSetId: null,
  automaticPlacements: {},
  detectedDiff: null,
  dynamicElementsIDsToRefresh: [],
  isOkDisabled: false,
  lastUpdatedLabelSetId: null,
  lastUpdatedDynamicTextElements: {
    counterElementId: null,
    gridLetterElementId: null,
    userEntryElementId: null,
  },
  labelIndicesForExecute: {},
  isExitingFromTool: false,
  isSaving: false,
  isInDirtyState: false,
  cachedInsightsWhileExecution: [],
  currentCommand: null,
  ignoreChangesByDirtyStates: false,
  forceRecalculateRelatedForIds: [],
  isLabelAllInstancesEnabled: true,
  isApplyRotationToInstancesEnabled: true,
  activeDynamicElementDialogInfo: { top: 0, left: 0, textElement: null, activeDialogType: null },
  fontStyleHelpBalloonInfo: { top: 0, left: 0, show: false, src: null },
  labelAddedPromise: null,
  printOrder: {
    patchList: [],
  },
}

const namespaced: boolean = true

export const label: Module<ILabelState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
