
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { PartDetailsDto } from '@/types/FileExplorer/ItemDetails'
import DetailsUsedByBuildOrSinterPlan from '@/components/layout/FileExplorer/Details/DetailsUsedByBuildOrSinterPlan.vue'

@Component({
  components: { DetailsUsedByBuildOrSinterPlan },
})
export default class DetailsUsedByBuildOrSinterPlansList extends Vue {
  @Prop() onListItemClick: (id: string) => Promise<void>
  @Prop() itemDetails: PartDetailsDto
  @Prop({ default: false }) readonly isTargetShouldBeBlank: boolean

  get listOfBuildOrSinterPlansDetails() {
    return (this.itemDetails && this.itemDetails.usedInAvailableBuildPlansDetails) || []
  }

  get usedInNotAvailableBuildOrSinterPlansCount() {
    return (
      (this.itemDetails.usedInNotAvailableBuildPlansCount || 0) +
      (this.itemDetails.usedInNotAvailableSinterPlansCount || 0)
    )
  }
}
