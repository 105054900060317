
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'

import StoresNamespaces from '@/store/namespaces'
import { ModelGeometryTypeLabel, IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import { IJob, JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import { PrintingTypes } from '@/types/IMachineConfig'
import { Watch } from 'vue-property-decorator'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const commonStore = namespace(StoresNamespaces.Common)

@Component
export default class BuildGeometryLabel extends Vue {
  @buildPlansStore.Getter getSelectedBuildPlanJobs: IJob[]
  @buildPlansStore.Getter getBuildPlan: IBuildPlan

  @commonStore.Getter tooltipOpenDelay: number

  simulationJobs: IJob[] = []
  compensationJobs: IJob[] = []

  mounted() {
    this.setJobData()
  }

  showGeometryType(): boolean {
    const hasSimulationJob: boolean = Array.isArray(this.simulationJobs) && this.simulationJobs.length > 0
    const hasCompensationJob: boolean = Array.isArray(this.compensationJobs) && this.compensationJobs.length > 0
    return (
      (hasSimulationJob || hasCompensationJob) && this.getBuildPlan && this.getBuildPlan.modality === PrintingTypes.DMLM
    )
  }

  fetchGeometryTypeLabel(): string {
    let geometryTypeLabel = ModelGeometryTypeLabel.Nominal
    let latestSimJob: IJob
    let latestCompJob: IJob
    let latestJob: IJob
    if (Array.isArray(this.simulationJobs) && this.simulationJobs.length > 0) {
      latestSimJob = this.simulationJobs[0]
      this.simulationJobs.forEach((job) => {
        if (latestSimJob.number < job.number) {
          latestSimJob = job
        }
      })
    }
    if (Array.isArray(this.compensationJobs) && this.compensationJobs.length > 0) {
      latestCompJob = this.compensationJobs[0]
      this.compensationJobs.forEach((job) => {
        if (latestCompJob.number < job.number) {
          latestCompJob = job
        }
      })
    }
    latestJob = latestSimJob ? latestSimJob : latestJob
    latestJob = latestCompJob
      ? latestSimJob
        ? latestSimJob.number > latestCompJob.number
          ? latestSimJob
          : latestCompJob
        : latestCompJob
      : latestJob

    if (latestJob.jobType === JobType.COMPENSATE && latestJob.code === JobStatusCode.COMPLETE) {
      geometryTypeLabel = ModelGeometryTypeLabel.Compensated
    }

    return geometryTypeLabel
  }

  @Watch('getSelectedBuildPlanJobs')
  setJobData() {
    this.simulationJobs = this.getSelectedBuildPlanJobs.filter((job) => job.jobType === JobType.SIMULATE)
    this.compensationJobs = this.getSelectedBuildPlanJobs.filter((job) => job.jobType === JobType.COMPENSATE)
  }
}
