import { ActionTree } from 'vuex'
import { CheckItemUniqPayload, IFileExplorerState, ItemDetailsPayload } from './types'
import { IRootState } from '@/store/types'
import { DataState } from '@/types/Common/DataState'
import { FileExplorerItem, IFolderDto, ItemFormat } from '@/types/FileExplorer/FileExplorerItem'
import { ICreateBuildPlanDto } from '@/types/BuildPlans/IBuildPlan'
import {
  GrantPermissionDto,
  UpdatePermissionDto,
  ItemCollaborationDto,
  Permission,
} from '@/types/FileExplorer/Permission'
import { DetailsPanelViewMode, ViewMode } from '@/types/FileExplorer/ViewMode'
import { ItemDetailsDto } from '@/types/FileExplorer/ItemDetails'
import {
  FILE_EXPLORER_TABLE_STATE_KEY,
  DEFAULT_ITEM_VERSION,
  FILE_EXPLORER_PATH_DELIMITER,
  DEFAULT_FILE_EXPLORER_TABLE_STATE,
  ROOT_FOLDER_ID,
  SORT_STRATEGY_BY_ITEM_TYPE,
  DEFAULT_PAGINATION_LIMIT,
  ITEM_ID_PREFIX_DELIMITER,
} from '@/constants'
import { FileExplorerTableState } from '@/types/FileExplorer/FileExplorerTableState'

import fileExplorerApi from '@/api/fileExplorer'
import { SortParams } from '@/types/FileExplorer/SortParamsKey'
import { FilterBy, FilterParamsKey, ItemTypeFilterName, TableFilterParams } from '@/types/FileExplorer/FilterParamsKey'
import { ISearchParamsInterface } from '@/types/ISearchParamsInterface'
import router, { RouterNames } from '@/router'
import { ItemType, ItemFieldType, IItemTypeFilterValue, ItemSubType } from '@/types/FileExplorer/ItemType'
import { ICopyItemsDto } from '@/types/FileExplorer/ICopyItemsDto'
import messageService from '@/services/messageService'
import localStorageService from '@/services/localStorageService'
import { ItemAction } from '@/types/FileExplorer/ItemAction'
import { RelatedItems } from '../../../types/FileExplorer/RelatedItems'
import { SortOrders } from '@/types/SortModes'
import { sortArrayByAnotherArray } from '@/utils/array'
import { ItemsQueryStringBuilder } from './helpers'
import { transformPrintOrderIdToItemId } from '@/utils/fileExplorerItem/fileExplorerItemUtils'
import { CreateIBCPlanDto } from '@/types/IBCPlans/IIBCPlan'

// TODO: Get rid of code duplication regarding data state setting

const fetchItemPromises: Map<string, Promise<FileExplorerItem>> = new Map()
const permissionPromises: Map<string, Promise<ItemCollaborationDto>> = new Map()
const fetchFolderPromises: Map<string, Promise<FileExplorerItem>> = new Map()

export const actions: ActionTree<IFileExplorerState, IRootState> = {
  async fetchItems({ commit, getters }) {
    commit('setDataState', { state: DataState.Loading })

    try {
      const { getTotalPages, getCurrentPageIndex, getPageSize, getSortParams, getFilterParams } = getters
      let sortBy
      let sortOrder

      if (getSortParams.allFiles) {
        sortBy = getSortParams.allFiles.sortBy
        sortOrder = getSortParams.allFiles.sortOrder
      }

      const filterValue = getFilterForFetchItemsRequest(getFilterParams.allFilesFilter, getters.getViewMode)

      if (getTotalPages && getTotalPages < getCurrentPageIndex) {
        return
      }

      const searchQuery = getSearchQuery(
        getCurrentPageIndex + 1,
        getPageSize,
        sortBy,
        sortOrder,
        filterValue,
        SORT_STRATEGY_BY_ITEM_TYPE,
      )
      const response = await fileExplorerApi.getItems(searchQuery)

      if (!response) {
        return
      }

      const {
        data: items,
        meta: { totalPages, currentPage },
      } = response

      commit('changeCurrentPage', currentPage)
      commit('updateTotalPages', totalPages)
      items.forEach((item) => {
        if (!item.hasNotAvailableRelatedItems) commit('addItem', item)
      })

      const numberOfHiddenItemsPerPage = items.filter((item) => item.hasNotAvailableRelatedItems).length
      const numberOfHiddenItemsTotal = getters.getNumberOfHiddenItemsInCurrentFolder + numberOfHiddenItemsPerPage
      commit('setNumberOfHiddenItemsInCurrentFolder', numberOfHiddenItemsTotal)
      commit('setDataState', { state: DataState.Loaded })

      return items
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async fetchAllMyFolders({ commit }) {
    commit('setDataState', { state: DataState.Loading })

    try {
      const folders = await fileExplorerApi.fetchAllMyFolders()

      folders.forEach((item) => {
        commit('addFolderItem', item)
      })
      commit('setDataState', { state: DataState.Loaded })
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async fetchItemsInFolder({ commit, getters }, { folderId, abortIfRootChanged }) {
    commit('setDataState', { state: DataState.Loading })
    try {
      const { getTotalPages, getCurrentPageIndex, getPageSize, getSortParams, getFilterParams } = getters
      let sortBy
      let sortOrder

      if (getSortParams.allFiles) {
        sortBy = getSortParams.allFiles.sortBy
        sortOrder = getSortParams.allFiles.sortOrder
      }

      const filterValue = getFilterForFetchItemsRequest(getFilterParams.allFilesFilter, getters.getViewMode)

      if (getTotalPages && getTotalPages < getCurrentPageIndex) {
        commit('setDataState', { state: DataState.Loaded })
        return
      }

      const searchQuery = getSearchQuery(
        getCurrentPageIndex + 1,
        getPageSize,
        sortBy,
        sortOrder,
        filterValue,
        SORT_STRATEGY_BY_ITEM_TYPE,
      )
      const {
        data: items,
        meta: { totalPages, currentPage },
      } = await fileExplorerApi.getItemsInFolder(folderId, searchQuery)

      const rootItemId = getters.getRootItem ? getters.getRootItem.id : ROOT_FOLDER_ID
      if (abortIfRootChanged && rootItemId !== folderId) {
        commit('setDataState', { state: DataState.Loaded })
        return []
      }

      commit('changeCurrentPage', currentPage)
      commit('updateTotalPages', totalPages)
      items.forEach((item) => {
        // Only build/sinter plans in which all build plan items are available are added to store here
        if (!item.hasNotAvailableRelatedItems) commit('addItem', item)
      })

      const numberOfHiddenItemsPerPage = items.filter((item) => item.hasNotAvailableRelatedItems).length
      const numberOfHiddenItemsTotal = getters.getNumberOfHiddenItemsInCurrentFolder + numberOfHiddenItemsPerPage
      commit('setNumberOfHiddenItemsInCurrentFolder', numberOfHiddenItemsTotal)
      commit('setDataState', { state: DataState.Loaded })

      return items
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.data.message })
    }
  },

  async fetchAllItemsInFolder({ commit, getters }, folderId: string): Promise<FileExplorerItem[]> {
    try {
      return await fileExplorerApi.getAllItemsInFolder(folderId)
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.data.message })
    }
  },

  async fetchItemById({ commit, getters }, itemId): Promise<FileExplorerItem> {
    commit('setDataState', { state: DataState.Loading })

    try {
      let itemPromise = fetchItemPromises.get(itemId)
      if (!itemPromise) {
        itemPromise = fileExplorerApi.getItemById(itemId)
        fetchItemPromises.set(itemId, itemPromise)
      }

      const item: FileExplorerItem = await itemPromise
      if (isItemInRootFolder(item, getters.getRootItem)) commit('addItem', item)

      commit('setDataState', { state: DataState.Loaded })
      return item
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    } finally {
      fetchItemPromises.delete(itemId)
    }
  },

  async fetchItemByIdWithoutAddingToState({}, itemId): Promise<FileExplorerItem> {
    try {
      const item: FileExplorerItem = await fileExplorerApi.getItemById(itemId)
      return item
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  },

  async fetchItemDetails({ commit, getters }, payload: ItemDetailsPayload) {
    commit('setDataState', { state: DataState.Loading })

    try {
      let details: ItemDetailsDto
      const requestTokenObj = {}
      commit('setDetailsRequestRaceConditionObj', requestTokenObj)

      if (payload.itemType === ItemType.BuildPlan) {
        details = await fileExplorerApi.getBuildPlanDetails(payload.itemId)
      } else if (payload.itemType === ItemType.IbcPlan) {
        details = await fileExplorerApi.getIbcPlanDetails(payload.itemId)
      } else if (payload.itemType === ItemType.Folder) {
        details = await fileExplorerApi.getFolderDetails(payload.itemId)
      } else if (payload.itemType === ItemType.BuildPart) {
        details = await fileExplorerApi.getPartDetails(payload.itemId)
      }

      if (!details || getters.getDetailsRequestRaceConditionObj !== requestTokenObj) {
        commit('setDataState', { state: DataState.Loaded })
        return
      }

      if (!payload.silent) {
        commit('setItemDetails', details)
      }
      commit('setDataState', { state: DataState.Loaded })

      return details
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async fetchPartDetails({}, itemId) {
    try {
      return await fileExplorerApi.getPartDetails(itemId)
    } catch (e) {
      return null
    }
  },

  async fetchFolderDetails({}, itemId) {
    try {
      return await fileExplorerApi.getFolderDetails(itemId, false)
    } catch (e) {
      return null
    }
  },

  async fetchItemWithNoStateUpdate({ commit }, itemId): Promise<FileExplorerItem> {
    try {
      return await fileExplorerApi.getItemById(itemId)
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  },

  async fetchSharedByMeItems({ commit, getters }) {
    try {
      commit('setDataState', { state: DataState.Loading })

      const { getTotalPages, getCurrentPageIndex, getPageSize, getSortParams, getFilterParams } = getters
      let sortBy
      let sortOrder

      if (getSortParams.shared) {
        sortBy = getSortParams.shared.sortBy
        sortOrder = getSortParams.shared.sortOrder
      }

      const filterValue = getFilterForFetchItemsRequest(getFilterParams.sharedFilter)

      if (getTotalPages && getTotalPages < getCurrentPageIndex) {
        return
      }

      const searchQuery = getSearchQuery(
        getCurrentPageIndex + 1,
        getPageSize,
        sortBy,
        sortOrder,
        filterValue,
        SORT_STRATEGY_BY_ITEM_TYPE,
      )
      const response = await fileExplorerApi.getSharedByMe(searchQuery)

      if (!response) {
        return
      }

      const {
        data: items,
        meta: { totalPages, currentPage },
      } = response

      commit('changeCurrentPage', currentPage)
      commit('updateTotalPages', totalPages)
      items.forEach((item) => {
        commit('setSharedItem', item)
      })
      commit('setDataState', { state: DataState.Loaded })

      return items
    } finally {
      commit('setDataState', { state: DataState.Loaded })
    }
  },

  async fetchRecentItems({ commit, getters }) {
    try {
      commit('setDataState', { state: DataState.Loading })

      const { getTotalPages, getCurrentPageIndex, getPageSize, getSortParams, getFilterParams } = getters
      let sortBy
      let sortOrder

      if (getSortParams.recent) {
        sortBy = getSortParams.recent.sortBy
        sortOrder = getSortParams.recent.sortOrder
      }

      const filterValue = getFilterForFetchItemsRequest(getFilterParams.recentFilter)

      if (getTotalPages && getTotalPages < getCurrentPageIndex) {
        return
      }

      const searchQuery = getSearchQuery(
        getCurrentPageIndex + 1,
        getPageSize,
        sortBy,
        sortOrder,
        filterValue,
        SORT_STRATEGY_BY_ITEM_TYPE,
      )
      const response = await fileExplorerApi.getRecentItems(searchQuery)

      if (!response) {
        return
      }

      const {
        data: items,
        meta: { totalPages, currentPage },
      } = response

      commit('changeCurrentPage', currentPage)
      commit('updateTotalPages', totalPages)

      items.forEach((item) => {
        if (!item.hasNotAvailableRelatedItems) commit('setRecentItem', item)
      })

      const numberOfHiddenItemsPerPage = items.filter((item) => item.hasNotAvailableRelatedItems).length
      const numberOfHiddenItemsTotal = getters.getNumberOfHiddenItemsInRecent + numberOfHiddenItemsPerPage
      commit('setNumberOfHiddenItemsInRecent', numberOfHiddenItemsTotal)
      commit('setDataState', { state: DataState.Loaded })

      return items
    } finally {
      commit('setDataState', { state: DataState.Loaded })
    }
  },

  async fetchTrash({ commit, getters }) {
    try {
      commit('setDataState', { state: DataState.Loading })

      const { getTotalPages, getCurrentPageIndex, getPageSize, getSortParams, getFilterParams } = getters
      let sortBy
      let sortOrder

      if (getSortParams.trash) {
        sortBy = getSortParams.trash.sortBy
        sortOrder = getSortParams.trash.sortOrder
      }

      const filterValue = getFilterForFetchItemsRequest(getFilterParams.trashFilter)

      if (getTotalPages && getTotalPages < getCurrentPageIndex) {
        return
      }

      const searchQuery = getSearchQuery(
        getCurrentPageIndex + 1,
        getPageSize,
        sortBy,
        sortOrder,
        filterValue,
        SORT_STRATEGY_BY_ITEM_TYPE,
      )
      const response = await fileExplorerApi.getTrash(searchQuery)

      if (!response) {
        return
      }

      const {
        data: items,
        meta: { totalPages, currentPage },
      } = response

      commit('changeCurrentPage', currentPage)
      commit('updateTotalPages', totalPages)
      items.forEach((item) => {
        commit('addItem', item)
      })

      return items
    } finally {
      commit('setDataState', { state: DataState.Loaded })
    }
  },

  async createFolder({ commit, state }, folderName: string) {
    commit('setDataState', { state: DataState.Loading })
    const folder: IFolderDto = { name: folderName }
    if (state.root && router.currentRoute.name !== RouterNames.FE_Search) {
      folder.parentId = state.root.id
    }

    try {
      const newFolder = await fileExplorerApi.createFolder(folder)
      commit('addNewItem', newFolder)
      commit('setLastAddedItem', newFolder)
      commit('setDataState', { state: DataState.Loaded })
      return newFolder
    } catch (error) {
      messageService.showErrorMessage(error.message)
      commit('setDataState', { errorText: error.message, state: DataState.Failed })
    }
  },

  async createBuildPlan({ commit, state }, bpDto: ICreateBuildPlanDto) {
    commit('setDataState', { state: DataState.Loading })

    if (state.root && router.currentRoute.name !== RouterNames.FE_Search) {
      bpDto.parentId = state.root.id
    }

    try {
      const newBp = await fileExplorerApi.createBuildPlan(bpDto)
      commit('addNewItem', newBp)
      commit('setLastAddedItem', newBp)
      commit('setDataState', { state: DataState.Loaded })
      return newBp
    } catch (error) {
      messageService.showErrorMessage(error.message)
      commit('setDataState', { errorText: error.message, state: DataState.Failed })
    }
  },

  async updateItem({ commit, state }, updatedItem) {
    commit('setDataState', { state: DataState.Loading })

    try {
      const item = await fileExplorerApi.updateItem(updatedItem)
      commit('updateItem', item)

      const index = state.favorites.items.findIndex((i) => i.id === item.id)
      if (index !== -1) {
        commit('fetchFavoritesByIdsDone', [item])
      }

      commit('setDataState', { state: DataState.Loaded })
    } catch (error) {
      messageService.showErrorMessage(error.message)
      commit('setDataState', { errorText: error.message, state: DataState.Failed })
    }
  },

  async unshareItems({ commit, dispatch, getters }, items: FileExplorerItem[]) {
    try {
      const promises = items.map((item) => fileExplorerApi.unshareItem(item))
      await Promise.all(promises)
      commit('unselectAllItems')
      items.forEach((item) => {
        const permissions: Permission[] = getters.permissionsByItemId[item.id] || []
        commit(
          'deletePermissions',
          permissions.map((p) => p.id),
        )

        if (getters.find(item.id)) {
          commit('unsetSharedItem', item)
        }
      })

      await Promise.all(
        items.map((item) => dispatch('setLastAction', { itemId: item.id, action: ItemAction.Unshared })),
      )
    } catch (error) {
      console.error(error)
    }
  },

  async setIsRemovedSelected({ commit, state }, itemsToTrash?) {
    commit('setDataState', { state: DataState.Loading })
    const items = itemsToTrash ? itemsToTrash : state.selectedItems.length ? state.selectedItems : [state.root]

    try {
      const updatedItems = await fileExplorerApi.trashItems(items.map((item) => item.id))

      updatedItems.forEach((item) => commit('deleteItem', item))

      commit('unselectAllItems')
      commit(
        'setDetailsPanelMode',
        state.root && !state.selectedItems.length ? DetailsPanelViewMode.Folder : DetailsPanelViewMode.Default,
      )
      commit('setDataState', { state: DataState.Loaded })
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async setIsRemovedItem({ dispatch }, item: FileExplorerItem) {
    item.isRemoved = true
    dispatch('updateItem', item)
  },

  async restoreSelectedItems({ commit, dispatch, getters, state }) {
    commit('setDataState', { state: DataState.Loading })
    const itemIds = state.selectedItems.map((x) => x.id)

    try {
      const restoredItems: FileExplorerItem[] = await fileExplorerApi.restoreItems(itemIds)
      restoredItems.forEach((item) => commit('updateItem', item))
      commit('unselectAllItems')
      commit('setDetailsPanelMode', { state: DataState.Loaded, mode: DetailsPanelViewMode.Default })
      commit('setDataState', { state: DataState.Loaded })

      await Promise.all(
        restoredItems.map((item) => dispatch('setLastAction', { itemId: item.id, action: ItemAction.Restored })),
      )
    } catch (error) {
      messageService.showErrorMessage(error.message)
      commit('setDataState', { errorText: error.message, state: DataState.Failed })
    }
  },

  async restoreAllItems({ commit, state }) {
    commit('setDataState', { state: DataState.Loading })
    try {
      const restoredItems: FileExplorerItem[] = await fileExplorerApi.restoreAllItems()
      restoredItems.forEach((item) => commit('updateItem', item))
      commit('unselectAllItems')
      commit('setDetailsPanelMode', { state: DataState.Loaded, mode: DetailsPanelViewMode.Default })
      commit('setDataState', { state: DataState.Loaded })
    } catch (error) {
      messageService.showErrorMessage(error.message)
      commit('setDataState', { errorText: error.message, state: DataState.Failed })
    }
  },

  async deleteSelectedItems({ commit, state }) {
    commit('setDataState', { state: DataState.Loading })
    const itemIds = state.selectedItems.map((x) => x.id)

    try {
      const deletedItemIds = await fileExplorerApi.deleteItems(itemIds)
      deletedItemIds.forEach((id) => commit('deleteItemById', id))
      commit('unselectAllItems')
      commit('setDetailsPanelMode', { state: DataState.Loaded, mode: DetailsPanelViewMode.Default })
      commit('setDataState', { state: DataState.Loaded })
    } catch (error) {
      messageService.showErrorMessage(error.message)
      commit('setDataState', { errorText: error.message, state: DataState.Failed })
    }
  },

  async deleteAllItems({ commit, state }) {
    commit('setDataState', { state: DataState.Loading })
    const items = Object.values(state.items.byId).filter((item) => item.isRemoved)

    try {
      const deletedItemIds = await fileExplorerApi.deleteItems(items.map((x) => x.id))
      deletedItemIds.forEach((id) => commit('deleteItemById', id))
      commit('setDataState', { state: DataState.Loaded })
      commit('unselectAllItems')
      commit('setDetailsPanelMode', { state: DataState.Loaded, mode: DetailsPanelViewMode.Default })
    } catch (error) {
      messageService.showErrorMessage(error.message)
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async deleteAllTrashedItems({ commit, getters }) {
    const result = await fileExplorerApi.deleteAllTrashedItems()

    if (!result || !result.length) {
      return
    }

    getters.getRemovedItems.forEach((item) => {
      if (!getters.getItemsWithoutAccess.map((a) => a.id).includes(item.id) && result.includes(item.id)) {
        commit('deleteItem', item)
      }
    })
    commit('unselectAllItems')
    commit('setDetailsPanelMode', { state: DataState.Loaded, mode: DetailsPanelViewMode.Default })
  },

  async switchBuildPlanActiveVersion(
    { commit, getters, dispatch },
    payload: { newVersionId: string; oldVersionId: string },
  ) {
    const buildPlanAsItem = getters.find(payload.oldVersionId)
    const newVersion = await dispatch('fetchItemById', payload.newVersionId)

    if (!newVersion || payload.newVersionId === payload.oldVersionId) return

    if (buildPlanAsItem) {
      commit('deleteItem', buildPlanAsItem)
    }

    await dispatch('unsetActiveVersion', payload.oldVersionId)
    if (getters.isError) {
      const errorMessage = `Can't switch variant: ${getters.getErrorText}`
      messageService.showErrorMessage(errorMessage)
      return
    }

    if (newVersion.version !== DEFAULT_ITEM_VERSION) {
      await dispatch('setActiveVersion', newVersion.id)

      if (getters.isError) {
        const errorMessage = `Can't switch variant: ${getters.getErrorText}`
        messageService.showErrorMessage(errorMessage)
      }
    }
  },

  async setActiveVersion({ commit }, itemId: string) {
    commit('setDataState', { state: DataState.Loading })

    try {
      await fileExplorerApi.setActiveVersion(itemId)
      commit('setDataState', { state: DataState.Loaded })
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async unsetActiveVersion({ commit }, itemId: string) {
    commit('setDataState', { state: DataState.Loading })

    try {
      await fileExplorerApi.unsetActiveVersion(itemId)
      commit('setDataState', { state: DataState.Loaded })
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async toggleIsFavoriteItem({ commit, dispatch, state }, itemId: string) {
    commit('setDataState', { state: DataState.Loading })
    const isFavorite = state.items.favoriteIds.some((id) => id === itemId)
    try {
      if (isFavorite) {
        await dispatch('removeFromFavorites', itemId)
      } else {
        await dispatch('addToFavorites', itemId)
      }

      commit('setDataState', { state: DataState.Loaded })
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async removeSelectedFromFavorites({ commit, dispatch, state }) {
    commit('setDataState', { state: DataState.Loading })
    const items = state.selectedItems

    try {
      await Promise.all(items.map((item) => dispatch('removeFromFavorites', item.id)))
      commit('setDataState', { state: DataState.Loaded })
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async addToFavorites({ commit }, itemId: string) {
    await fileExplorerApi.setIsFavorite(itemId)
    commit('addToFavorites', itemId)
  },

  async removeFromFavorites({ commit }, itemId: string) {
    await fileExplorerApi.unsetIsFavorite(itemId)

    commit('setDetailsPanelMode', { state: DataState.Loaded, mode: DetailsPanelViewMode.Default })
    commit('removeFromFavorites', itemId)
    commit('unselectAllItems')
  },

  async removeAllFromFavorites({ commit, dispatch, state }) {
    commit('setDataState', { state: DataState.Loading })

    try {
      await Promise.all(state.items.favoriteIds.map((itemId) => dispatch('removeFromFavorites', itemId)))
      commit('setDataState', { state: DataState.Loaded })
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async fetchFavoritesSnapshot({ commit, getters, state }, format?: string): Promise<FileExplorerItem[]> {
    try {
      commit('setDataState', { state: DataState.Loading })

      const { getCurrentPageIndex, getPageSize, getSortParams, getFilterParams } = getters
      let sortBy
      let sortOrder

      if (getSortParams.favorites) {
        sortBy = getSortParams.favorites.sortBy
        sortOrder = getSortParams.favorites.sortOrder
      }

      const filterValue = getFilterForFetchItemsRequest(getFilterParams.favoritesFilter)

      const searchQuery = getSearchQuery(
        getCurrentPageIndex + 1,
        getPageSize,
        sortBy,
        sortOrder,
        filterValue,
        SORT_STRATEGY_BY_ITEM_TYPE,
      )

      const items = await fileExplorerApi.fetchFavorites(`${searchQuery}&format=${ItemFormat.Minimal}`)

      commit('fetchFavoritesSnapshotDone', items)

      return items
    } finally {
      commit('setDataState', { state: DataState.Loaded })
    }
  },

  async fetchFavorites({ commit, getters, state }) {
    try {
      commit('setDataState', { state: DataState.Loading })

      const [startIndex, endIndex] = state.favorites.pageRange
      const pageIds = state.favorites.snapshot.slice(startIndex, endIndex).map((item) => item.id)

      if (pageIds.length === 0) {
        return []
      }

      const params = new URLSearchParams()
      pageIds.forEach((id) => {
        params.append('ids', id)
      })

      const allItems = await fileExplorerApi.getItemsByIds(params.toString())
      const availableItems = allItems.filter((item) => !item.hasNotAvailableRelatedItems)
      const sorted = sortArrayByAnotherArray(availableItems, pageIds, 'id')

      const numberOfHiddenItemsPerPage = allItems.filter((item) => item.hasNotAvailableRelatedItems).length
      const numberOfHiddenItemsTotal = getters.getNumberOfHiddenItemsInFavorites + numberOfHiddenItemsPerPage
      commit('setNumberOfHiddenItemsInFavorites', numberOfHiddenItemsTotal)

      const difference = pageIds.filter((itemId) => !sorted.some((item) => item.id === itemId))

      difference.forEach((itemId) => {
        commit('removeFromFavorites', itemId)
      })

      commit('fetchFavoritesByIdsDone', sorted)

      const nextStartIndex = endIndex + 1 - difference.length
      commit('setFavoritePageRange', [nextStartIndex, nextStartIndex + DEFAULT_PAGINATION_LIMIT])

      return availableItems
    } finally {
      commit('setDataState', { state: DataState.Loaded })
    }
  },

  async fetchFavoriteItemsIds({ commit }) {
    try {
      commit('setDataState', { state: DataState.Loading })

      const itemsIds = await fileExplorerApi.fetchFavoriteItemsIds()

      if (!itemsIds) {
        return
      }

      commit('setFavoriteItemsIds', itemsIds)
    } finally {
      commit('setDataState', { state: DataState.Loaded })
    }
  },

  async pasteMovedItems({ commit, dispatch, state }, rootFolder: FileExplorerItem) {
    const items = state.selectedItems as FileExplorerItem[]
    if (!items || !items.length) {
      return
    }
    const rootId = rootFolder ? rootFolder.id : null

    commit('setDataState', { state: DataState.Loading })

    try {
      const itemsIds = items.map((item: FileExplorerItem) => item.id)
      const updateItemsDto = { itemsIds, parentId: rootId, isRemoved: false }
      const updatedItems = await fileExplorerApi.updateItems(updateItemsDto)

      updatedItems.forEach((item) => {
        commit('updateItem', item)
      })
      commit('unselectAllItems')
      commit('setDataState', { state: DataState.Loaded })

      await Promise.all(
        updatedItems.map((item) => dispatch('setLastAction', { itemId: item.id, action: ItemAction.Moved })),
      )
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
      throw new Error(error.message)
    }
    state.rootOfMovingItems = null
  },

  /**
   * Moves root folder into newParentFolder
   * If newParentFolder null then move to All Files root
   */
  async moveRootItem({ commit, dispatch, state }, newParentFolder: FileExplorerItem) {
    if (!state.root) {
      return
    }
    commit('setDataState', { state: DataState.Loading })

    const itemId = state.root.id
    const newParentId = newParentFolder ? newParentFolder.id : null
    const updateItemDto = { id: itemId, parentId: newParentId, isRemoved: false }

    try {
      const updatedItem = await fileExplorerApi.updateItem(updateItemDto)
      commit('updateItem', updatedItem)
      commit('setRoot', updatedItem)

      await dispatch('setLastAction', { itemId, action: ItemAction.Moved })
      await dispatch('fetchItemDetails', { itemId, itemType: ItemType.Folder })

      commit('setDataState', { state: DataState.Loaded })
      state.rootOfMovingItems = null
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
      throw new Error(error.message)
    }
  },

  async checkItemForUnique({ commit }, payload: CheckItemUniqPayload) {
    commit('setDataState', { state: DataState.Loading })
    try {
      const isExist = await fileExplorerApi.checkIsPresent(
        payload.name,
        payload.parentId,
        payload.excludeItemId,
        payload.itemType,
        payload.itemSubType,
      )

      commit('setDataState', { state: DataState.Loaded })
      return isExist
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async generateUniqueName({ commit }, payload: { name: string; parentId: string; delimiter?: string }) {
    commit('setDataState', { state: DataState.Loading })
    let result

    try {
      result = await fileExplorerApi.getUniqueName(payload.name, payload.parentId, payload.delimiter)

      commit('setDataState', { state: DataState.Loaded })
      return result
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
      return null
    }
  },

  // Permissions
  async getAllMyPermissions({ commit }) {
    try {
      const permissions = await fileExplorerApi.getAllMyPermissions()
      commit('setPermissions', permissions)
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async fetchItemsPermissionsByIds({ commit }, ids: string[]) {
    try {
      const collaborations: ItemCollaborationDto[] = await fileExplorerApi.fetchItemCollaboratorsByIds(ids)

      for (const item of collaborations) {
        commit('addItemPermissionCollaborators', { id: item.itemId, collaborators: item.collaborators })
      }

      return collaborations
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async getItemPermissions({ commit }, itemId: string) {
    commit('setDataState', { state: DataState.Loading })

    try {
      let permissionPromise = permissionPromises.get(itemId)
      if (!permissionPromise) {
        permissionPromise = fileExplorerApi.getItemPermissions(itemId)
        permissionPromises.set(itemId, permissionPromise)
      }

      const collaboration: ItemCollaborationDto = await permissionPromise

      commit('updateItemPermissions', collaboration.collaborators)
      commit('updateItemPermissions', collaboration.relatedPermissions)
      commit('setDataState', { state: DataState.Loaded })
    } catch (error) {
      console.error(error)
      commit('setDataState', { errorText: error.message, state: DataState.Failed })
    } finally {
      permissionPromises.delete(itemId)
    }
  },

  async fetchItemsPermissions({ commit }, items: FileExplorerItem[]) {
    commit('setDataState', { state: DataState.Loading })
    try {
      const ids = []
      for (const item of items) {
        const activePermissionPromise = permissionPromises.get(item.id)
        if (!activePermissionPromise) {
          ids.push(item.id)
        }
      }

      const collaborations: ItemCollaborationDto[] = await fileExplorerApi.fetchItemCollaboratorsByIds(ids)
      const permissions = collaborations.flatMap((c) => c.collaborators.concat(c.relatedPermissions))
      commit('updateItemPermissions', permissions)
      commit('setDataState', { state: DataState.Loaded })
    } catch (error) {
      console.error(error)
      commit('setDataState', { errorText: error.message, state: DataState.Failed })
    }
  },

  async createPermission({ commit }, payload: GrantPermissionDto) {
    try {
      const permission = fileExplorerApi.createPermission(payload)
      commit('addPermission', permission)
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async updatePermission({ commit }, payload: { permissionId: string; data: UpdatePermissionDto }) {
    try {
      const permission = fileExplorerApi.updatePermission(payload.permissionId, payload.data)
      commit('addPermission', permission)
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async deletePermission({ commit }, payload: { permissionId: string; fullDeletion?: boolean }) {
    try {
      const ids = await fileExplorerApi.deletePermission(payload.permissionId, payload.fullDeletion)
      commit('deletePermissions', ids)
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  // Sorting
  async updateSort({ commit }, sortParams: { key: string; sort: SortParams }) {
    const { key } = sortParams
    let { sort } = sortParams

    if (!sort.sortBy || !sort.sortOrder) {
      sort = null
    }

    commit('setSortParams', {
      key,
      sort,
    })
  },

  async updateFilter({ commit, state }, filterParams: { key: string; filterBy: FilterBy }) {
    const { key, filterBy } = filterParams
    commit('setFilterParams', {
      key,
      filterBy,
    })
    commit('setNumberOfHiddenItemsInCurrentFolder', 0)
    commit('setNumberOfHiddenItemsInRecent', 0)
    commit('setNumberOfHiddenItemsInFavorites', 0)
  },

  async getFolderById({}, id: string) {
    try {
      let fetchFolderPromise = fetchFolderPromises.get(id)
      if (!fetchFolderPromise) {
        fetchFolderPromise = fileExplorerApi.getFolderById(id)
        fetchFolderPromises.set(id, fetchFolderPromise)
      }

      const folder = await fetchFolderPromise
      return folder
    } finally {
      fetchFolderPromises.delete(id)
    }
  },

  async getItemPathNames({ dispatch }, item: FileExplorerItem) {
    const itemBreadcrumbs = await dispatch('getBreadcrumbsForItem', item)

    // Exclude item variants from the path
    return itemBreadcrumbs.filter((ibc) => !ibc.id || ibc.itemType === ItemType.Folder).map((ibc) => ibc.name)
  },

  async getBreadcrumbsForRoot({ state, dispatch }) {
    return dispatch('getBreadcrumbsForItem', state.root)
  },

  async getBreadcrumbsForItem({ state }, rootItem: FileExplorerItem) {
    const bcrmbItems = []

    if (router.currentRoute.name === RouterNames.FE_Search && !state.isMoveItems) {
      const queryParams = router.currentRoute.params.query ? router.currentRoute.params.query : ''
      const searchParams = state.search.parameters.length ? state.search.parameters : queryParams

      bcrmbItems.push({ id: undefined, name: `Search results for ${searchParams}`, disabled: true })
      return bcrmbItems
    }

    bcrmbItems.push({ id: undefined, name: 'All Files', disabled: true })

    if (!rootItem) {
      return bcrmbItems
    }

    try {
      const pathIds = rootItem.path.split(FILE_EXPLORER_PATH_DELIMITER).slice(1)
      const promises = pathIds.map((id) => {
        const item = state.root && state.root.id === id ? state.root : state.items.byId[id]
        return item ? item : fileExplorerApi.getFolderById(id)
      })
      const items = await Promise.all(promises)

      items.forEach((item) => {
        bcrmbItems.push({ ...item, disabled: false })
      })
      bcrmbItems[0].disabled = false
      bcrmbItems[bcrmbItems.length - 1].disabled = true
      return bcrmbItems
    } catch (error) {
      console.error(error)
      return bcrmbItems // the default breadcrumb item will be returned
    }
  },

  async openItemFolder({ dispatch, commit }, fileExplorerItemId: string) {
    const parentFolder = await dispatch('getParentFolder', fileExplorerItemId)
    const newRootItemId = parentFolder ? parentFolder.id : ROOT_FOLDER_ID

    commit('setClickedNameItem', null)
    commit('setDetailsPanelMode', DetailsPanelViewMode.Default)
    commit('setViewMode', ViewMode.Folders)
    await dispatch('clearPaginatedData')
    commit('clearItems')
    await dispatch('fetchItemsInFolder', { folderId: newRootItemId, abortIfRootChanged: false })

    const route = {
      name: RouterNames.FE_AllFiles,
      params: {
        itemId: newRootItemId,
      },
    }

    // @ts-ignore
    await router.safePush(route)
  },

  async updateJobsDataByIds({ commit }, jobsIds: string[]) {
    try {
      const promises = jobsIds.map((id) => fileExplorerApi.getJobById(id))
      const jobs = await Promise.all(promises)
      commit('setJobs', jobs)
    } catch (error) {
      console.error(error)
    }
  },

  async fetchSearchResultSnapshot(
    { commit, getters, state },
    searchParams: ISearchParamsInterface,
  ): Promise<FileExplorerItem[]> {
    try {
      commit('setDataState', { state: DataState.Loading })

      const { getSortParams, getFilterParams } = getters
      let sortBy
      let sortOrder

      if (getSortParams.search) {
        sortBy = getSortParams.search.sortBy
        sortOrder = getSortParams.search.sortOrder
      }

      const searchFilter = getFilterParams[FilterParamsKey.Search]
      const poFilters = [
        getFilterParams[FilterParamsKey.SearchPrintOrdersBuildPlate],
        getFilterParams[FilterParamsKey.SearchPrintOrdersMachine],
        getFilterParams[FilterParamsKey.SearchPrintOrdersMaterial],
        getFilterParams[FilterParamsKey.SearchPrintOrdersStatus],
      ]

      const searchFilterValue = getFilterForFetchItemsRequest(searchFilter)

      if (searchFilterValue && searchFilterValue.length) {
        const itemTypes = searchFilterValue.map((value) => getQueryParameterValueByItemTypeFilter(value))
        searchParams.itemType = itemTypes
      }

      poFilters.forEach((filter) => {
        if (filter && (filter.value || filter.value.length)) {
          searchParams[filter.field] = filter.value
        }
      })

      const searchUrlParams = new URLSearchParams(Object.entries(searchParams))
      const itemsUrlParams = new ItemsQueryStringBuilder()
        .setSort({ field: sortBy, order: sortOrder })
        .setItemFormat(ItemFormat.Minimal)
        .buildAsUrlParams()

      itemsUrlParams.forEach((value, key) => {
        searchUrlParams.append(key, value)
      })

      // fix for incorrect comma encoding
      const query = searchUrlParams.toString().replace(/%2C/g, ',')
      const items = await fileExplorerApi.getItemsBySearchParams(query)

      commit('fetchSearchResultSnapshotDone', items)

      return items
    } finally {
      commit('setDataState', { state: DataState.Loaded })
    }
  },

  async fetchSearchResultItems({ commit, getters, state }, searchQuery: string) {
    try {
      commit('setDataState', { state: DataState.Loading })

      const [startIndex, endIndex] = state.search.pageRange
      const pageIds = state.search.snapshot.slice(startIndex, endIndex).map((item) => item.id)

      if (pageIds.length === 0) {
        return []
      }

      const params = new URLSearchParams()
      pageIds.forEach((id) => {
        params.append('ids', id)
      })

      const items = await fileExplorerApi.getItemsByIds(params.toString())
      // in case search query changed before request was complete
      // should discard this request as it is no longer needed
      if (searchQuery && router.currentRoute.params.query !== searchQuery) {
        return []
      }

      const itemIds = pageIds.map((id) => transformPrintOrderIdToItemId(id))
      const sorted = sortArrayByAnotherArray(items, itemIds, 'id')

      const difference = itemIds.filter((itemId) => !sorted.some((item) => item.id === itemId))

      difference.forEach((itemId) => {
        commit('removeFromSearchResult', itemId)
      })

      commit('fetchSearchResultByIdsDone', sorted)

      const nextStartIndex = endIndex - difference.length
      commit('setSearchResultPageRange', [nextStartIndex, nextStartIndex + DEFAULT_PAGINATION_LIMIT])

      return items
    } finally {
      commit('setDataState', { state: DataState.Loaded })
    }
  },

  async fetchItemsByIds({ commit, getters, state }, ids: string[]) {
    const params = new URLSearchParams()

    ids.forEach((id) => {
      params.append('ids', id)
    })

    try {
      const items = await fileExplorerApi.getItemsByIds(params.toString())
      return items
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async copyItems({ commit, dispatch, getters }, { items, rootFolder }) {
    const itemIds = items.map((i) => i.id)
    const newParentId = rootFolder ? rootFolder.id : null
    const copyDto: ICopyItemsDto = { itemIds, newParentId }

    const copiedItems = await fileExplorerApi.copyItems(copyDto)
    const partsForUpdate = []

    // items will be added to the start of items list, so first item should be added last
    // tslint:disable-next-line: no-increment-decrement
    for (let i = copiedItems.length - 1; i >= 0; i--) {
      const item = copiedItems[i]

      if (item.itemType === ItemType.BuildPart) {
        partsForUpdate.push(item)
      }

      if (shouldAddCopiedItems(item, getters.getRootItem)) {
        commit('addNewItem', item)
      }
    }

    await Promise.all(partsForUpdate.map((part) => dispatch('parts/getPartById', part.id, { root: true })))
    await Promise.all(
      copiedItems.map((item) => dispatch('setLastAction', { itemId: item.id, action: ItemAction.Copied })),
    )

    return copiedItems
  },

  async fetchAndUpdateItem({ commit }, itemId: string) {
    commit('setForceUpdateDetails', false)
    const item = await fileExplorerApi.getItemById(itemId)
    if (item) {
      commit('updateItem', item)
    }
    commit('setForceUpdateDetails', true)
  },

  async getParentFolder(context, itemId: string): Promise<FileExplorerItem> {
    try {
      return fileExplorerApi.getParentFolder(itemId)
    } catch (error) {
      console.error(error)
      return null
    }
  },

  async getCollaborationData({ commit }, payload: { itemId: string; includeParentPermissions?: boolean }) {
    const { itemId, includeParentPermissions = false } = payload
    commit('setDataState', { state: DataState.Loading })

    try {
      commit('unsetCollaborationData')

      const collaborationData: ItemCollaborationDto = await fileExplorerApi.getItemPermissions(
        itemId,
        includeParentPermissions,
      )

      commit('setCollaborationData', collaborationData)
      commit('setDataState', { state: DataState.Loaded })
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  setDefaultTableState() {
    localStorageService.set(FILE_EXPLORER_TABLE_STATE_KEY, DEFAULT_FILE_EXPLORER_TABLE_STATE)
  },

  getTableState() {
    return localStorageService.get(FILE_EXPLORER_TABLE_STATE_KEY)
  },

  async getGetRunningAndFailedJobsByItemIds({ commit }, itemIds: string[]) {
    try {
      const jobs = await fileExplorerApi.getGetRunningAndFailedJobsByItemIds(itemIds)
      commit('setJobs', jobs)
    } catch (error) {
      console.error(error)
      return []
    }
  },

  async setLastAction({ commit, state, dispatch }, payload: { itemId: string; action: ItemAction }) {
    commit('setDataState', { state: DataState.Loading })
    try {
      const updatedItem = await fileExplorerApi.setLastAction(payload.itemId, payload.action)

      if (updatedItem.subType !== ItemSubType.SinterPart) {
        if (!state.items.byId[payload.itemId]) {
          await dispatch('fetchItemById', payload.itemId)
        }

        const item = { ...state.items.byId[payload.itemId], ...updatedItem }
        commit('updateItem', item)
      }

      commit('setDataState', { state: DataState.Loaded })
    } catch (error) {
      messageService.showErrorMessage(error.message)
      commit('setDataState', { errorText: error.message, state: DataState.Failed })
    }
  },

  async fetchTrashTabRelatedItemsByItemIds({ commit, state, getters }, itemIds: string[] = []) {
    try {
      let selectedItemIds = state.selectedItems.map((x) => x.id)
      if (!selectedItemIds.length) {
        selectedItemIds = itemIds
      }
      if (!selectedItemIds.length) {
        return
      }
      const result: RelatedItems = await fileExplorerApi.getRelatedItemsForTrashTab(selectedItemIds)
      commit('setRelatedItemsInfo', result)
    } catch (error) {
      messageService.showErrorMessage(error.message)
      commit('setDataState', { errorText: error.message, state: DataState.Failed })
    }
  },

  async clearPaginatedData({ commit }) {
    commit('clearPaginationData')
    commit('setNumberOfHiddenItemsInCurrentFolder', 0)
    commit('setNumberOfHiddenItemsInRecent', 0)
    commit('setNumberOfHiddenItemsInFavorites', 0)
  },

  async fetchItemsWithoutAccess({ commit }) {
    try {
      const items = await fileExplorerApi.fetchItemsWithoutAccess()
      commit('setItemsWithoutAccess', items)
      return items
    } catch (error) {
      commit('setDataState', { state: DataState.Failed, errorText: error.message })
    }
  },

  async fetchIBCPlan(context, ibcPlanId: string) {
    return fileExplorerApi.fetchIBCPlan(ibcPlanId)
  },

  async createIBCPlan(context, createIbcPlanDto: CreateIBCPlanDto) {
    return fileExplorerApi.createIBCPlan(createIbcPlanDto)
  },

  async deleteIBCPlan({ commit }, payload: { ibcPlanId: string }) {
    commit('setDataState', { state: DataState.Loading })

    try {
      await fileExplorerApi.deleteIBCPlan(payload.ibcPlanId)
      commit('setDataState', { state: DataState.Loaded })
    } catch (error) {
      messageService.showErrorMessage(error.message)
      commit('setDataState', { errorText: error.message, state: DataState.Failed })
    }
  },
}

function getSearchQuery(
  page: number,
  size: number,
  sortBy: string = 'name',
  sortOrder: SortOrders = SortOrders.Ascending,
  filterValue: IItemTypeFilterValue[] = [],
  sortStrategy?: string,
) {
  let filters = filterValue
    .map((value) => {
      return getQueryParameterValueByItemTypeFilter(value)
    })
    .join(',')

  filters = filters ? `&itemType=${filters}` : ''

  let query = `?page=${page}&size=${size}&sortBy=${sortBy}:${sortOrder}${filters}`
  if (sortStrategy && (sortBy === 'itemType' || sortBy === 'name')) {
    query += `&sortStrategy=${sortStrategy}`
  }

  return query
}

function getQueryParameterValueByItemTypeFilter(filter: IItemTypeFilterValue): ItemTypeFilterName {
  let itemTypeKey: string

  if (filter.fieldType === ItemFieldType.ItemType) {
    itemTypeKey = Object.keys(ItemType).find((itemType) => ItemType[itemType] === filter.typeValue)
  } else {
    itemTypeKey = Object.keys(ItemSubType).find((itemType) => ItemSubType[itemType] === filter.typeValue)
  }

  return ItemTypeFilterName[itemTypeKey]
}

function getFilterForFetchItemsRequest(filter: FilterBy, viewMode = ViewMode.Folders) {
  let filterValue: IItemTypeFilterValue[]

  if (filter && filter.value.length) {
    filterValue = filter.value
  }

  if (!filterValue && viewMode === ViewMode.List) {
    // List View mode displays all types (if the filter is not specified by the user) of items except folders
    filterValue = [
      { fieldType: ItemFieldType.ItemType, typeValue: ItemType.BuildPlan },
      { fieldType: ItemFieldType.ItemSubType, typeValue: ItemSubType.SinterPlan },
      { fieldType: ItemFieldType.ItemType, typeValue: ItemType.BuildPart },
      { fieldType: ItemFieldType.ItemType, typeValue: ItemType.PrintOrder },
    ]
  }

  return filterValue
}

function isItemInRootFolder(item: FileExplorerItem, rootItem: FileExplorerItem): boolean {
  const parentFolderId = item.parentFolderId ? item.parentFolderId : item.parentId

  return (!parentFolderId && !rootItem) || (rootItem && rootItem.id === item.parentId)
}

function shouldAddCopiedItems(item: FileExplorerItem, rootItem: FileExplorerItem): boolean {
  let parentFolderId = item.parentId
  if (item.version !== DEFAULT_ITEM_VERSION) {
    parentFolderId = item.parentFolderId
  }

  return (!parentFolderId && !rootItem) || (rootItem && rootItem.id === parentFolderId)
}
