import { INotification } from '@/types/Notification/INotification'
import { EndpointsUrls } from '@/configs/config'
import { get, post, put } from '@/api/http'
import { handleAPIError } from '@/api/common'

export default {
  async getAllNotifications(searchQuery: string = '') {
    try {
      return await get<any>(`${EndpointsUrls.Notifications}${searchQuery}`)
    } catch (error) {
      // Swallow notifications error because it annoys user without giving useful information
      return []
    }
  },

  async readNotification(notificationId: string) {
    try {
      const url = `${EndpointsUrls.Notifications}/readNotification/${notificationId}`
      return await post<INotification>(url, {})
    } catch (error) {
      handleAPIError(error)
    }
  },

  async updateLastNotificationTime(time: string) {
    try {
      const url = `${EndpointsUrls.Notifications}/updateBellLastClicked`
      return await post<Date>(url, { time })
    } catch (error) {
      handleAPIError(error)
    }
  },
}
