
import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ItemTypeSelector from '@/components/controls/FileExplorer/ItemTypeSelector.vue'
import DetailsPanelMixin from '@/components/layout/FileExplorer/Details/DetailsPanelMixin'
import DetailsPanel from '@/components/layout/FileExplorer/DetailsPanel.vue'
import InfinityScrollArea from '@/components/layout/FileExplorer/InfinityScrollArea.vue'
import MoveToTrashItemErrorModal from '@/components/layout/FileExplorer/MoveToTrashItemErrorModal.vue'
import PermanentlyDeleteItemModal from '@/components/layout/FileExplorer/PermanentlyDeleteItemModal.vue'
import RestoreWithRelatedModal from '@/components/layout/FileExplorer/RestoreWithRelatedModal.vue'
import ShareItemModal from '@/components/layout/FileExplorer/ShareItemModal.vue'
import AllFilesSearchViewMixin from '@/components/layout/FileExplorer/Table/mixins/AllFilesSearchViewMixin'
import FileExplorerRedirectionMixin from '@/components/layout/FileExplorer/Table/mixins/FileExplorerRedirectionMixin'
import TrashListRow from '@/components/layout/FileExplorer/Table/Trash/TrashListRow.vue'
import TrashTableHeader from '@/components/layout/FileExplorer/Table/Trash/TrashTableHeader.vue'
import { DEFAULT_IS_ALLOWED_TO_BE_TRASHED_SELECTED_ITEMS, DeleteType } from '@/constants'

import { CanBeTrashedStatus } from '@/types/FileExplorer/CanBeTrashed'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { FilterParamsKey, TableFilterParams } from '@/types/FileExplorer/FilterParamsKey'
import { ItemType } from '@/types/FileExplorer/ItemType'
import { SortParamsKey, TableSortParams } from '@/types/FileExplorer/SortParamsKey'
import { DetailsPanelViewMode } from '@/types/FileExplorer/ViewMode'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { SortOrders } from '@/types/SortModes'
import { isOfType } from '@/utils/common'
import { getItemTypeName } from '@/utils/fileExplorerItem/fileExplorerItemUtils'

import StoresNamespaces from '@/store/namespaces'
import { ContextMenuItem } from '@/types/FileExplorer/ContextMenuItem'
import { IPartDto } from '@/types/PartsLibrary/Parts'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const partsStore = namespace(StoresNamespaces.Parts)

const SORT_BY_DEFAULT = 'name'
const SORT_ORDER_DEFAULT = SortOrders.Ascending

@Component({
  components: {
    TrashTableHeader,
    TrashListRow,
    DetailsPanel,
    PermanentlyDeleteItemModal,
    RestoreWithRelatedModal,
    ItemTypeSelector,
    InfinityScrollArea,
    MoveToTrashItemErrorModal,
    ShareItemModal,
  },
})
export default class Trash extends mixins(AllFilesSearchViewMixin, DetailsPanelMixin, FileExplorerRedirectionMixin) {
  @fileExplorerStore.Getter('getRemovedItems') getRemovedItems: FileExplorerItem[]
  @fileExplorerStore.Getter('getSelectedItems') getSelectedItems: FileExplorerItem[]
  @fileExplorerStore.Getter('getErrorText') getErrorText: string
  @fileExplorerStore.Getter('isError') isError: boolean
  @fileExplorerStore.Getter getRenameDialogState: boolean
  @fileExplorerStore.Getter getClickedNameItem: FileExplorerItem
  @fileExplorerStore.Getter getRelatedItemsToDelete: FileExplorerItem[]
  @fileExplorerStore.Getter getRelatedItemsToRestore: FileExplorerItem[]
  @fileExplorerStore.Getter getHasDeletePermissions: boolean
  @fileExplorerStore.Getter getHasRestorePermissions: boolean
  @fileExplorerStore.Getter isLoading: boolean
  @fileExplorerStore.Getter getSortParams: () => TableSortParams
  @fileExplorerStore.Getter getFilterParams: () => TableFilterParams
  @fileExplorerStore.Getter isForceUpdateDetails: () => boolean

  @fileExplorerStore.Action restoreSelectedItems: Function
  @fileExplorerStore.Action deleteSelectedItems: Function
  @fileExplorerStore.Action restoreAllItems: Function
  @fileExplorerStore.Action fetchTrashTabRelatedItemsByItemIds: Function
  @fileExplorerStore.Action fetchTrash: Function
  @fileExplorerStore.Action getGetRunningAndFailedJobsByItemIds: Function
  @fileExplorerStore.Action clearPaginatedData: Function
  @fileExplorerStore.Action deleteAllTrashedItems: () => Promise<boolean | undefined>

  @partsStore.Action actualizeParts: (items?: FileExplorerItem[]) => Promise<void>
  @partsStore.Action fetchAllParts: Function
  @partsStore.Action fetchAllSinterParts: Function
  @partsStore.Action fetchAllIbcParts: () => Promise<IPartDto[]>

  @fileExplorerStore.Mutation setDetailsPanelMode: (mode: DetailsPanelViewMode) => void
  @fileExplorerStore.Mutation clearItems: () => void
  @fileExplorerStore.Mutation clearRelatedItemsInfo: () => void

  filterType = FilterParamsKey.Trash

  isShownDeleteItemModal: boolean = false
  deleteType: DeleteType = null

  isShownRestoreItemModal: boolean = false
  isProcessing = false

  async beforeMount() {
    this.unselectAllItems()
    this.setRoot(null)
    await this.refreshItems()
  }

  // @ts-ignore
  get items(): FileExplorerItem[] {
    let items = this.getRemovedItems
    items = this.getFilteredItems(FilterParamsKey.Trash, items)
    return this.getOrderedItems(SortParamsKey.Trash, items, SORT_BY_DEFAULT, SORT_ORDER_DEFAULT)
  }

  get labelForDeleteModal(): string {
    const items = this.getSelectedItems
    switch (this.deleteType) {
      case DeleteType.All:
        return this.$i18n.t('allItems') as string

      case DeleteType.Selected:
        if (items.length === 0) return
        return items.length > 1
          ? `${items.length} ${this.$i18n.t('selectedItems')}`
          : `${getItemTypeName(items[0])} ${items[0].name}`

      default:
        return
    }
  }

  get contextMenuItems(): ContextMenuItem[] {
    return [
      {
        title: this.$t('restore') as string,
        divide: false,
        condition: this.getHasRestorePermissions,
        clickHandler: this.onRestoreSelected,
      },
      {
        title: this.$t('delete') as string,
        divide: false,
        condition: true,
        clickHandler: this.showDeleteModalSelected,
      },
    ]
  }

  async onItemListRowClick(payload) {
    const { item } = payload

    await this.onItemClick(payload)
    this.fetchTrashTabRelatedItemsByItemIds()
    this.defineDetailsViewMode()
  }

  defineDetailsViewMode() {
    if (!this.getSelectedItem || this.getSelectedItems.length > 1) {
      this.setDetailsPanelMode(DetailsPanelViewMode.Default)
      return
    }

    const item = this.getSelectedItem

    switch (item.itemType) {
      case ItemType.BuildPart:
        this.setDetailsPanelMode(DetailsPanelViewMode.Part)
        break
      case ItemType.BuildPlan:
        this.setDetailsPanelMode(DetailsPanelViewMode.BuildPlan)
        break
      case ItemType.Folder:
        this.setDetailsPanelMode(DetailsPanelViewMode.Folder)
        break

      case ItemType.PrintOrder || isOfType<PrintOrder>(item, 'site'):
        this.setDetailsPanelMode(DetailsPanelViewMode.PrintOrder)
        break
    }
  }

  async onRestoreSelected() {
    await this.fetchTrashTabRelatedItemsByItemIds()
    if (!this.getHasRestorePermissions) {
      return
    }

    if (this.getRelatedItemsToRestore.length) {
      this.isShownRestoreItemModal = true
      return
    }
    this.restoreSelected()
  }

  async restoreSelected() {
    await this.restoreSelectedItems()
    this.setDetailsPanelMode(DetailsPanelViewMode.Default)
    await this.fetchTrash()
    await this.fetchTrashTabRelatedItemsByItemIds()
    this.closeRestoreModal()
  }

  async onRestoreAll() {
    await this.restoreAllItems()
    this.setDetailsPanelMode(DetailsPanelViewMode.Default)
    await this.fetchTrash()
    await this.fetchTrashTabRelatedItemsByItemIds()
  }

  showDeleteModalAll() {
    this.deleteType = DeleteType.All
    this.isShownDeleteItemModal = true
  }

  @Watch('isShownMoveToTrashErrorModal')
  trashErrorModalChanged() {
    if (!this.isShownMoveToTrashErrorModal && this.getSelectedItems.length) this.isShownDeleteItemModal = true
  }

  async showDeleteModalSelected() {
    this.deleteType = DeleteType.Selected
    const selected = this.getSelectedItems.length ? this.getSelectedItems : [this.getRootItem]
    const noPermissionItems = selected.filter((a) => !a.grantedPermissions.itemDelete)

    if (noPermissionItems.length) {
      this.setSelectedItemsCanBeTrashed({
        permissionsResult: {
          status: CanBeTrashedStatus.NoPermission,
          selectedUnavailableItems: noPermissionItems,
          relatedUnavailableItems: [],
        },
        relateToPrintOrdersResult: DEFAULT_IS_ALLOWED_TO_BE_TRASHED_SELECTED_ITEMS.relateToPrintOrdersResult,
      })
      this.toggleMoveToTrashErrorModal()
      this.unselectCannotBeTrashedItems()
    }

    if (this.isCanBeTrashedStatusSuccess) {
      this.isShownDeleteItemModal = true
      return
    }
  }

  closeDeleteModal() {
    this.isShownDeleteItemModal = false
    this.toggleMoveToTrashModal(false)
  }

  closeRestoreModal() {
    this.isShownRestoreItemModal = false
  }

  async confirmDeleteModal() {
    if (this.isProcessing) {
      return
    }

    this.isProcessing = true

    switch (this.deleteType) {
      case DeleteType.All:
        await this.deleteAllTrashedItems()
        break

      case DeleteType.Selected:
        await this.deleteSelectedItems()
        break
    }

    this.isProcessing = false
    this.closeDeleteModal()
  }

  beforeDestroy() {
    this.setDetailsPanelMode(DetailsPanelViewMode.Default)
  }

  onIntersection() {
    this.fetchItems()
  }

  @Watch('getFilterParams', { deep: true })
  @Watch('getSortParams', { deep: true })
  async refreshItems() {
    await this.clearPaginatedData()
    this.clearItems()
    this.clearRelatedItemsInfo()
    this.fetchItems()
  }

  async fetchItems() {
    const itemsToGetJobs = await this.fetchTrash()
    await Promise.all([
      this.fetchAllSinterParts(),
      this.fetchAllParts(),
      this.fetchAllIbcParts(),
      this.actualizeParts(itemsToGetJobs),
    ])
    const itemIds = itemsToGetJobs.map((item: FileExplorerItem) => item.id)
    this.fetchTrashTabRelatedItemsByItemIds(itemIds)
    this.getGetRunningAndFailedJobsByItemIds(itemIds)
  }
}
