import { GetterTree } from 'vuex'
import { IVisualizationState } from './types'
import { IRootState } from '@/store/types'
import { BoundingBox, BoundingBox2D } from '@/visualization/models/DataModel'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { SimulationColoringModel } from '@/types/Simulation/SimulationColoringModel'
import VisualizationModeTypes from '@/visualization/types/VisualizationModeTypes'
import { ISimulationStep } from '@/types/Simulation/SimulationSteps'
import { GeometryType, ProcessingStage, CategoryKind } from '@/visualization/types/SimulationTypes'
import { IPartHeights } from '@/types/Simulation/SimulationCompensation'
import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { GeometryAvailability } from '@/visualization/types/Common'
import { Visualization } from '@/visualization'
import { LabelInsightRelatedItem } from '@/types/InteractiveService/LabelMessageContent'
import { DimensionBox, ClearanceTypes } from '@/visualization/types/ClearanceTypes'
import { Matrix } from '@babylonjs/core/Maths'

export const getters: GetterTree<IVisualizationState, IRootState> = {
  selectedComponentsIds(state: IVisualizationState): string[] {
    return state.selectedComponentsIds
  },

  isInitialized(state: IVisualizationState): boolean {
    return state.isInitialized
  },

  isShowingGizmos(state: IVisualizationState): boolean {
    return state.isShowingGizmos
  },

  isCrossSectionModeEnabled(state: IVisualizationState): boolean {
    return state.crossSectionMode.isEnabled
  },

  isClearanceToolEnabled(state: IVisualizationState): boolean {
    return state.clearanceMode.isEnabled
  },

  isClearanceFromEnabled: (state) => (clearanceType: ClearanceTypes) => {
    switch (clearanceType) {
      case ClearanceTypes.Parts:
        return state.clearanceMode.isEnabledFrom.parts
      case ClearanceTypes.Bodies:
        return state.clearanceMode.isEnabledFrom.bodies
      case ClearanceTypes.Walls:
        return state.clearanceMode.isEnabledFrom.walls
      case ClearanceTypes.PrintHeadLanes:
        return state.clearanceMode.isEnabledFrom.printHeadLanes
      case ClearanceTypes.Plate:
        return state.clearanceMode.isEnabledFrom.plate
      case ClearanceTypes.Ceiling:
        return state.clearanceMode.isEnabledFrom.ceiling
      default:
        return false
    }
  },

  enabledClearanceFrom(state: IVisualizationState): ClearanceTypes {
    return state.clearanceMode.enabledFrom
  },

  isClearanceToEnabled: (state) => (clearanceType: ClearanceTypes) => {
    switch (clearanceType) {
      case ClearanceTypes.Parts:
        return state.clearanceMode.isEnabledTo.parts
      case ClearanceTypes.Bodies:
        return state.clearanceMode.isEnabledTo.bodies
      case ClearanceTypes.Walls:
        return state.clearanceMode.isEnabledTo.walls
      case ClearanceTypes.PrintHeadLanes:
        return state.clearanceMode.isEnabledTo.printHeadLanes
      case ClearanceTypes.Plate:
        return state.clearanceMode.isEnabledTo.plate
      case ClearanceTypes.Ceiling:
        return state.clearanceMode.isEnabledTo.ceiling
      default:
        return false
    }
  },

  enabledClearanceTo(state: IVisualizationState): ClearanceTypes {
    return state.clearanceMode.enabledTo
  },

  isMeshToMeshClearance(state: IVisualizationState): boolean {
    return (
      (state.clearanceMode.isEnabledFrom.bodies && state.clearanceMode.isEnabledTo.bodies) ||
      (state.clearanceMode.isEnabledFrom.bodies && state.clearanceMode.isEnabledTo.parts) ||
      (state.clearanceMode.isEnabledFrom.parts && state.clearanceMode.isEnabledTo.bodies) ||
      (state.clearanceMode.isEnabledFrom.parts && state.clearanceMode.isEnabledTo.parts)
    )
  },

  isRubberBandShown(state: IVisualizationState): boolean {
    return state.clearanceMode.showRubberband
  },

  dimensionBoxes(state: IVisualizationState): DimensionBox[] {
    return state.clearanceMode.dimensionBoxes
  },

  getSavedActiveCollectorId(state: IVisualizationState): string {
    return state.clearanceMode.savedActiveCollectorId
  },

  highlightedClearanceIds(state: IVisualizationState): string[] {
    return state.clearanceMode.highlightedClearanceIds
  },

  isSlicerModeEnabled(state: IVisualizationState): boolean {
    return state.slicerMode.isEnable
  },

  crossSectionMode(state: IVisualizationState): { isEnabled: boolean } {
    return state.crossSectionMode
  },

  slicerMode(state: IVisualizationState): { isEnable: boolean; max: number; min: number; current: number } {
    return state.slicerMode
  },

  viewMode(state: IVisualizationState): { name: ViewModeTypes; isSelectable: boolean } {
    return state.viewMode
  },

  visualization(state: IVisualizationState): Visualization {
    return state.visualization
  },

  visualizationMode(state: IVisualizationState): VisualizationModeTypes {
    return state.visualizationMode
  },

  isViewLocked(state: IVisualizationState): boolean {
    return state.isViewLocked
  },

  isCrossSectionViewMode(state: IVisualizationState) {
    return state.viewMode.name === ViewModeTypes.CrossSection
  },

  isClearanceToolViewMode(state: IVisualizationState) {
    return state.viewMode.name === ViewModeTypes.ClearanceTool
  },

  isPrintOrderPreviewViewMode(state: IVisualizationState): boolean {
    return state.viewMode.name === ViewModeTypes.PrintOrderPreview
  },

  isLabelCreationMode(state: IVisualizationState): boolean {
    return state.isLabelCreationMode
  },

  isLabelManualPlacement(state: IVisualizationState): boolean {
    return state.isLabelManualPlacement
  },

  isDisplayFlipArrow(state: IVisualizationState): boolean {
    return state.isDisplayFlipArrow
  },

  flipArrowLocation(state: IVisualizationState): { x: number; y: number } {
    return state.flipArrowLocation
  },

  isDisplayManualLabelSettings(state: IVisualizationState): boolean {
    return state.isDisplayManualLabelSettings
  },

  manualLabelSettingsLocation(state: IVisualizationState): { x: number; y: number } {
    return state.manualLabelSettingsLocation
  },

  boundingBox(state: IVisualizationState): BoundingBox {
    return state.boundingBox
  },

  boundingBoxForPartsOnly(state: IVisualizationState): BoundingBox {
    return state.boundingBoxForPartsOnly
  },

  legend(state: IVisualizationState): { rangePoints: number[]; colors: number[][] } {
    return state.simulation.legend
  },

  coloringModel(state: IVisualizationState): SimulationColoringModel {
    return state.simulation.coloringModel
  },

  coloringModelViewsNames(state: IVisualizationState): string[] {
    return state.simulation.coloringModel.views.map((view) => view.name)
  },

  coloringModelSelectedViewModesNames(state: IVisualizationState): string[] {
    const selectedView = state.simulation.coloringModel.selectedView
    return selectedView ? selectedView.modes.map((mode) => mode.name) : []
  },

  getSimulationSlicer(state: IVisualizationState): { max: number; min: number; current: number } {
    return state.simulation.slicer
  },

  getStepBounds(state: IVisualizationState) {
    return state.simulation.boundingBox
  },

  getSimulationTimestamps(state: IVisualizationState): { stamps: number[]; current: number } {
    return state.simulation.timestamps
  },

  getSimulationSteps(state: IVisualizationState): ISimulationStep[] {
    return state.simulation.simulationSteps
  },

  getSelectedSimulationStep(state: IVisualizationState): ISimulationStep {
    return state.simulation.selectedSimulationStep
  },

  getIsShownTimestampModal(state: IVisualizationState): boolean {
    return state.simulation.isShownNewTimestampModal
  },

  getVisualizationLoading(state: IVisualizationState) {
    return state.isLoading
  },

  getAddPartPreviewLoading(state: IVisualizationState) {
    return state.addPart.previewIsLoading
  },

  isMouseOverCanvas(state: IVisualizationState) {
    return state.isMouseOverCanvas
  },

  hoveredLabel(state: IVisualizationState) {
    return state.hoveredLabelId
  },

  getLoadedSimulationSteps(state: IVisualizationState) {
    return state.simulation.loadedSimulationSteps
  },

  getPrintingLayers(state: IVisualizationState) {
    return state.simulation.printingLayers
  },

  getParaviewWebError(state: IVisualizationState) {
    return state.simulation.paraviewWebError
  },

  getWarpingInfo(state: IVisualizationState) {
    return state.simulation.warping
  },

  getBoundingBox2D(state: IVisualizationState): BoundingBox2D {
    return state.boundingBox2D
  },

  getActiveChart(state: IVisualizationState) {
    return state.simulation.activeChart
  },

  getChartsNames(state: IVisualizationState) {
    return state.simulation.charts ? state.simulation.charts.map((c) => c.name) : []
  },

  instancingIsRunning(state: IVisualizationState) {
    return state.instancingIsRunning
  },

  getDataRange(state: IVisualizationState) {
    return state.simulation.dataRange
  },

  getBridgingElements(state: IVisualizationState): boolean {
    return state.simulation.additionalGeometry[GeometryType.Bridging] !== undefined
  },

  getBridgingElementsVisible(state: IVisualizationState): boolean {
    const bridgingInfo = state.simulation.additionalGeometry[GeometryType.Bridging]
    return bridgingInfo ? bridgingInfo.visible : false
  },

  getBridgingElementsLoaded(state: IVisualizationState): boolean {
    const bridgingInfo = state.simulation.additionalGeometry[GeometryType.Bridging]
    return bridgingInfo ? bridgingInfo.loaded : false
  },

  getNominalGeometryAvailable(state: IVisualizationState): GeometryAvailability {
    const nominal = state.simulation.additionalGeometry[GeometryType.Nominal]
    if (!nominal) {
      return GeometryAvailability.Undefined
    }

    return nominal.notAvailable ? GeometryAvailability.NotAvailable : GeometryAvailability.Available
  },

  getNominalGeometryLoaded(state: IVisualizationState): boolean {
    const nominal = state.simulation.additionalGeometry[GeometryType.Nominal]
    return nominal ? nominal.loaded : false
  },

  getMeshingAvailable(state: IVisualizationState): boolean {
    return state.simulation.additionalGeometry[GeometryType.Meshing] !== undefined
  },

  getMeshingLoaded(state: IVisualizationState): boolean {
    const meshingInfo = state.simulation.additionalGeometry[GeometryType.Meshing]
    return meshingInfo ? meshingInfo.loaded : false
  },

  getMeshingVisibility(state: IVisualizationState): boolean {
    const meshingInfo = state.simulation.additionalGeometry[GeometryType.Meshing]
    return meshingInfo ? meshingInfo.visible : false
  },

  getNominalGeometryVisible(state: IVisualizationState): boolean {
    const nominal = state.simulation.additionalGeometry[GeometryType.Nominal]
    return nominal ? nominal.visible : false
  },

  getCompensatedGeometryVisible(state: IVisualizationState): boolean {
    const compensated = state.simulation.additionalGeometry[GeometryType.Compensated]
    return compensated ? compensated.visible : false
  },

  getCompensatedGeometryLoaded(state: IVisualizationState): boolean {
    const compensated = state.simulation.additionalGeometry[GeometryType.Compensated]
    return compensated ? compensated.loaded : false
  },

  getCompensatedGeometryAvailable(state: IVisualizationState): boolean {
    return state.simulation.additionalGeometry[GeometryType.Compensated] !== undefined
  },

  getGreenCompensatedGeometryVisible(state: IVisualizationState): boolean {
    const greenCompensated = state.simulation.additionalGeometry[GeometryType.GreenCompensated]
    return greenCompensated ? greenCompensated.visible : false
  },

  getGreenCompensatedGeometryLoaded(state: IVisualizationState): boolean {
    const greenCompensated = state.simulation.additionalGeometry[GeometryType.GreenCompensated]
    return greenCompensated ? greenCompensated.loaded : false
  },

  getGreenCompensatedGeometryAvailable(state: IVisualizationState): boolean {
    return state.simulation.additionalGeometry[GeometryType.GreenCompensated] !== undefined
  },

  getGreenNominalGeometryVisible(state: IVisualizationState): boolean {
    const greenNominal = state.simulation.additionalGeometry[GeometryType.GreenNominal]
    return greenNominal ? greenNominal.visible : false
  },

  getGreenNominalGeometryLoaded(state: IVisualizationState): boolean {
    const greenNominal = state.simulation.additionalGeometry[GeometryType.GreenNominal]
    return greenNominal ? greenNominal.loaded : false
  },

  getGreenNominalGeometryAvailable(state: IVisualizationState): boolean {
    return state.simulation.additionalGeometry[GeometryType.GreenNominal] !== undefined
  },

  getHandlerTogglesAvailable(state: IVisualizationState): boolean {
    return Object.keys(state.simulation.handlersLoaded).length > 1
  },

  getPartsVisible(state: IVisualizationState): boolean {
    const parts = state.simulation.handlersLoaded[CategoryKind.PARTS]
    return parts ? parts.visible : false
  },

  getSupportsVisible(state: IVisualizationState): boolean {
    const supports = state.simulation.handlersLoaded[CategoryKind.SUPPORTS]
    return supports ? supports.visible : false
  },

  getCouponsVisible(state: IVisualizationState): boolean {
    const coupons = state.simulation.handlersLoaded[CategoryKind.COUPONS]
    return coupons ? coupons.visible : false
  },

  getBuildPlateVisible(state: IVisualizationState): boolean {
    const buildPlate = state.simulation.handlersLoaded[CategoryKind.BUILDPLATE]
    return buildPlate ? buildPlate.visible : false
  },

  getSummaryData(state: IVisualizationState) {
    return state.simulation.summary
  },

  getResultsAvailable(state: IVisualizationState) {
    return state.simulation.resultsAvailable
  },

  getResultsFetching(state: IVisualizationState) {
    return state.simulation.processing[ProcessingStage.FetchingResults]
  },

  getVisualizationServiceStarted(state: IVisualizationState) {
    return state.simulation.serviceStarted
  },

  isSliderActive(state: IVisualizationState) {
    return state.slider.isActive
  },

  getSliderLayerNumber(state: IVisualizationState) {
    return state.slider.currentLayer
  },

  getPreviewCreationPromise(state: IVisualizationState) {
    return state.previewCreationPromise
  },

  isDownwardPlaneRotationInitialized(state: IVisualizationState) {
    return state.isDownwardPlaneRotationInitialized
  },

  isDownwardPlaneRotationInProgress(state: IVisualizationState) {
    return state.isDownwardPlaneRotationInProgress
  },

  getPartHeights:
    (state) =>
    (buildPlanItems: IBuildPlanItem[]): IPartHeights[] => {
      const renderScene = state.visualization.getRenderScene()
      const scene = renderScene.getScene()
      const meshManager = renderScene.getMeshManager()
      const partHeights: IPartHeights[] = []

      buildPlanItems.forEach((bPItem) => {
        const bPItemMesh = meshManager.getBuildPlanItemMeshById(bPItem.id)
        if (meshManager.isPartMesh(bPItemMesh)) {
          const meshes = bPItemMesh.getChildMeshes()

          if (meshes.length > 0) {
            const bb = scene.getWorldExtends((mesh) => mesh.id === meshes[0].id)
            const size = bb.max._z - bb.min._z
            partHeights.push({ partName: bPItem.part.name, bpItemId: bPItem.id, meshId: meshes[0].id, height: size })
          }
        }
      })

      return partHeights
    },

  getBuildBoundingBox: (state: IVisualizationState) => async () => {
    const sceneBoundingBox = await state.visualization.getBuildBoundingBox()
    return sceneBoundingBox
  },

  getSelectionBoundingBox: (state: IVisualizationState) => () => {
    const selectedBoundingBox = state.visualization.getSelectionBoundingBox()
    return selectedBoundingBox
  },

  getPartsBoundingBox: (state: IVisualizationState) => async () => {
    const partsBoundingBox = await state.visualization.getPartsBoundingBox()
    return partsBoundingBox
  },

  getIsSelectedPartBrep: (state: IVisualizationState) => () => {
    const isSelectedPartBrep = state.visualization.getIsSelectedPartBrep()
    return isSelectedPartBrep
  },

  getPartZTranslation: (state: IVisualizationState) => (buildPlanItemId: string) => {
    return state.visualization.getPartZTranslation(buildPlanItemId)
  },

  getSelectedParts: (state: IVisualizationState) => () => {
    const selectedParts = state.visualization.getSelectedParts()
    return selectedParts
  },

  isCompensationFileInProgress(state: IVisualizationState) {
    return state.isCompensationFileInProgress
  },

  isPartInstabilityShow(state: IVisualizationState) {
    return state.isPartInstabilityShow
  },

  getFacetDataByFaceId:
    (state: IVisualizationState) =>
    (componentId: string, geometryId: string, buildPlanItemId: string, faceName: string) => {
      const meshManager = state.visualization.getRenderScene().getMeshManager()
      const componentMesh = meshManager.getComponentMesh(componentId, geometryId, buildPlanItemId)
      let facetData = null

      if (componentMesh) {
        facetData = meshManager.getFacetsDataByFaceId(componentMesh, faceName)
        // since facet data is either empty array or object
        facetData = Array.isArray(facetData) && facetData.length === 0 ? null : facetData
      }
      return facetData
    },

  getWorldMatrixByBuildPlanItemId:
    (state: IVisualizationState) =>
    (buildPlanItemId: string): Matrix => {
      const renderScene = state.visualization.getRenderScene()
      const meshManager = renderScene.getMeshManager()
      const buildPlanItemMesh = meshManager.getBuildPlanItemMeshById(buildPlanItemId)
      return buildPlanItemMesh.getWorldMatrix()
    },

  getTransformationMatrixByBuildPlanItemId:
    (state: IVisualizationState) =>
    (buildPlanItemId: string): number[] => {
      const transformationMatrix = []
      const renderScene = state.visualization.getRenderScene()
      const meshManager = renderScene.getMeshManager()
      const buildPlanItemMesh = meshManager.getBuildPlanItemMeshById(buildPlanItemId)
      const { initialTransformation } = buildPlanItemMesh.metadata
      const partRelativeTransformation = meshManager.getRelativeTransformation(
        buildPlanItemMesh.getWorldMatrix(),
        initialTransformation,
      )

      meshManager
        .convertTranslationToMillimeters(partRelativeTransformation, buildPlanItemMesh.metadata.unitFactor)
        .transpose()
        .asArray()
        .forEach((item) => transformationMatrix.push(item))

      return transformationMatrix
    },

  isShowHiddenPartsAsTransparentMode(state: IVisualizationState) {
    return state.showHiddenPartsAsTransparentMode
  },

  isPartConfigLoading(state: IVisualizationState) {
    return state.isPartConfigLoading
  },

  /** Returns build plan insight object based on build plan id and related items of an insight
   */
  getMinPlacementCountInsight:
    (state: IVisualizationState) => (buildPlanId: string, relatedItems: LabelInsightRelatedItem[]) => {
      // TODO: stop using visualization variable from state and use another way of getting data from visualization module
      const renderScene = state.visualization.getRenderScene()
      const insightManager = renderScene.getInsightsManager()
      return insightManager.buildLabelToolMinimumCountInsight(relatedItems, buildPlanId)
    },
}
