import { Command } from '@/types/UndoRedo/Command'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { IBuildPlan, IBuildPlanItem, IUpdateBuildPlanItemParamsDto } from '@/types/BuildPlans/IBuildPlan'
import { Commit, Dispatch } from 'vuex'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { IBuildPlanInsight } from '../BuildPlans/IBuildPlanInsight'
import { VersionablePk } from '../Common/VersionablePk'
import { RenameItemPayload } from '../FileExplorer/FileExplorerItem'

export class EditBuildPlanCommand extends Command implements ICommand {
  public readonly commandType: CommandType
  public readonly parameterSetScaleFactor: number[]
  public readonly isShowHiddenPartsAsTransparentMode: boolean
  public readonly insights: IBuildPlanInsight[]
  public readonly toolName = ToolNames.BUILD_PLAN_PROPERTIES

  public readonly buildPlanBeforeEdit: IBuildPlan
  public readonly buildPlanAfterEdit: IBuildPlan
  public readonly buildPlanItemsBeforeEdit: IBuildPlanItem[]
  public readonly buildPlanItemsAfterEdit: IBuildPlanItem[]
  public readonly fullBuildPlan: IBuildPlan
  public readonly wasUpdated: boolean
  public readonly wasRenamed: boolean
  public readonly allAvailableBuildPlanVariants: IBuildPlan[]

  constructor(
    buildPlanBeforeEdit: IBuildPlan,
    buildPlanAfterEdit: IBuildPlan,
    buildPlanItemsBeforeEdit: IBuildPlanItem[],
    buildPlanItemsAfterEdit: IBuildPlanItem[],
    commandType: CommandType,
    wasUpdated: boolean,
    wasRenamed: boolean,
    allAvailableBuildPlanVariants: IBuildPlan[],
    protected dispatch: Dispatch,
    protected commit: Commit,
  ) {
    super()

    this.fullBuildPlan = JSON.parse(JSON.stringify(buildPlanBeforeEdit))
    this.buildPlanBeforeEdit = {
      id: buildPlanBeforeEdit.id,
      printStrategyId: buildPlanBeforeEdit.printStrategyId,
      printStrategyVersion: buildPlanBeforeEdit.printStrategyVersion,
      name: buildPlanBeforeEdit.name,
      materialId: buildPlanBeforeEdit.materialId,
      materialVersion: buildPlanBeforeEdit.materialVersion,
      machineConfigId: buildPlanBeforeEdit.machineConfigId,
      machineConfigVersion: buildPlanBeforeEdit.machineConfigVersion,
      buildPlateId: buildPlanBeforeEdit.buildPlateId,
      buildPlateVersion: buildPlanBeforeEdit.buildPlateVersion,
      modality: buildPlanBeforeEdit.modality,
      constraints: buildPlanBeforeEdit.constraints,
    } as IBuildPlan
    this.buildPlanAfterEdit = {
      id: buildPlanBeforeEdit.id,
      printStrategyId: buildPlanAfterEdit.printStrategyId,
      printStrategyVersion: buildPlanAfterEdit.printStrategyVersion,
      name: buildPlanAfterEdit.name,
      materialId: buildPlanAfterEdit.materialId,
      materialVersion: buildPlanAfterEdit.materialVersion,
      machineConfigId: buildPlanAfterEdit.machineConfigId,
      machineConfigVersion: buildPlanAfterEdit.machineConfigVersion,
      buildPlateId: buildPlanAfterEdit.buildPlateId,
      buildPlateVersion: buildPlanAfterEdit.buildPlateVersion,
      modality: buildPlanAfterEdit.modality,
      constraints: buildPlanAfterEdit.constraints,
    } as IBuildPlan

    this.buildPlanItemsBeforeEdit = JSON.parse(JSON.stringify(buildPlanItemsBeforeEdit))
    this.buildPlanItemsAfterEdit = JSON.parse(JSON.stringify(buildPlanItemsAfterEdit))
    this.commandType = commandType
    this.wasUpdated = wasUpdated
    this.wasRenamed = wasRenamed
    this.allAvailableBuildPlanVariants = allAvailableBuildPlanVariants
  }

  async undo(): Promise<void> {
    return this.undoRedoHandler(this.buildPlanBeforeEdit, this.buildPlanItemsBeforeEdit)
  }

  async redo(): Promise<void> {
    return this.undoRedoHandler(this.buildPlanAfterEdit, this.buildPlanItemsAfterEdit)
  }

  private async undoRedoHandler(buildPlan: IBuildPlan, buildPlanItems: IBuildPlanItem[]) {
    if (this.wasUpdated) {
      const updatedBuildPlan = await this.dispatch('buildPlans/updateBuildPlanV1', { buildPlan }, this.rootLevel)
      this.commit('buildPlans/updateVariant', updatedBuildPlan, this.rootLevel)
      this.commit(
        'buildPlans/selectBuildPlate',
        {
          buildPlatePk: new VersionablePk(buildPlan.buildPlateId, buildPlan.buildPlateVersion),
          machineConfigPk: new VersionablePk(buildPlan.machineConfigId, buildPlan.machineConfigVersion),
          buildPlanSubType: this.fullBuildPlan.subType,
          modality: buildPlan.modality,
        },
        this.rootLevel,
      )

      // preserve part parameter settings for build plan items and supports
      for (const buildPlanItem of buildPlanItems) {
        const updateBpItemDto: IUpdateBuildPlanItemParamsDto = {
          buildPlanItemId: buildPlanItem.id,
          partProperties: buildPlanItem.partProperties,
          supports: buildPlanItem.supports,
        }

        await this.dispatch('buildPlans/updateBuildPlanItem', {
          params: updateBpItemDto,
        })
      }
    }

    if (this.wasRenamed) {
      const renamePayload: RenameItemPayload = { id: buildPlan.id, name: buildPlan.name }
      await this.dispatch('fileExplorer/updateItem', renamePayload, this.rootLevel)
      this.commit('buildPlans/setBuildPlan', { ...this.fullBuildPlan, ...buildPlan }, this.rootLevel)
      this.commit(
        'buildPlans/setVariants',
        this.allAvailableBuildPlanVariants.map((variant) => {
          return { ...variant, name: buildPlan.name }
        }),
        this.rootLevel,
      )
    }

    this.commit('visualizationModule/deselect', this.rootLevel)
  }
}
