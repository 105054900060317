import { PrintingTypes } from '@/types/IMachineConfig'
import { IJob } from '@/types/PartsLibrary/Job'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { PrintOrderJobStatusCode } from '@/types/PrintOrder/PrintOrderJobStatusCode'

export function downloadFile(fileName: string, byteArray: BlobPart, mimeType: string) {
  const blob = new Blob([byteArray], { type: mimeType })
  const link = document.createElement('a')
  const fileExtension = mimeType.split('/').pop()

  // The name of the downloaded file will be without extension if fileName contains dots
  const downloadFileName = fileName.indexOf('.') === -1 ? fileName : `${fileName}.${fileExtension}`

  link.href = window.URL.createObjectURL(blob)
  link.download = downloadFileName
  link.click()
}

export function getDownloadPrintOrder(job: IJob) {
  if (!job) return null

  const parameters = job.parameters && JSON.parse(job.parameters)

  if (parameters) {
    return parameters && parameters.modality === PrintingTypes.BinderJet
      ? parameters.binderJet && parameters.binderJet.arsf && parameters.binderJet.arsf.name
      : parameters.dmlm
      ? parameters.dmlm.printPackageZip
        ? parameters.dmlm.printPackageZip.name
        : null
      : null
  }

  return null
}

export function getDownloadPrintOrderFileKey(job: IJob) {
  if (!job) return null

  const parameters = job.parameters && JSON.parse(job.parameters)
  // there can be scenarios where job does not have any parameters
  if (parameters) {
    return parameters && parameters.modality === PrintingTypes.BinderJet
      ? parameters.binderJet && parameters.binderJet.arsf && parameters.binderJet.arsf.s3FileName
      : parameters.dmlm
      ? parameters.dmlm.printPackageZip
        ? parameters.dmlm.printPackageZip.s3FileName
        : null
      : null
  }
  return null
}

export function isPrintOrderReadyForDownload(printOrder: PrintOrder): boolean {
  return printOrder.downloadFileKey !== null
}

export function getPrintOrderStatus(job: IJob) {
  const status = PrintOrderJobStatusCode[job.code].toLowerCase()
  return status
}
