export class Graph {
  adjacencyList

  constructor() {
    this.adjacencyList = {}
  }

  addVertex(vertex) {
    if (!this.adjacencyList[vertex]) {
      this.adjacencyList[vertex] = []
    }
  }

  addEdge(source, destination) {
    if (!this.adjacencyList[source]) {
      this.addVertex(source)
    }
    if (!this.adjacencyList[destination]) {
      this.addVertex(destination)
    }

    this.adjacencyList[source].push(destination)
    this.adjacencyList[destination].push(source)
  }

  removeEdge(source, destination) {
    this.adjacencyList[source] = this.adjacencyList[source].filter((vertex) => vertex !== destination)
    this.adjacencyList[destination] = this.adjacencyList[destination].filter((vertex) => vertex !== source)
  }

  removeVertex(vertex) {
    while (this.adjacencyList[vertex]) {
      const adjacentVertex = this.adjacencyList[vertex].pop()
      this.removeEdge(vertex, adjacentVertex)
    }
    delete this.adjacencyList[vertex]
  }

  bfs(start) {
    const queue = [start]
    const result = []
    const visited = {}
    visited[start] = true
    let currentVertex

    while (queue.length) {
      currentVertex = queue.shift()
      result.push(currentVertex)
      this.adjacencyList[currentVertex].forEach((neighbor) => {
        if (!visited[neighbor]) {
          visited[neighbor] = true
          queue.push(neighbor)
        }
      })
    }

    return result
  }

  dfs(start) {
    const result = []
    const stack = [start]
    const visited = {}
    visited[start] = true
    let currentVertex

    while (stack.length) {
      currentVertex = stack.pop()
      result.push(currentVertex)
      this.adjacencyList[currentVertex].forEach((neighbor) => {
        if (!visited[neighbor]) {
          visited[neighbor] = true
          stack.push(neighbor)
        }
      })
    }

    return result
  }
}
