
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import NumberField from '@/components/controls/Common/NumberField.vue'
import draggable from 'vuedraggable'
import { Mixins, Watch } from 'vue-property-decorator'
import {
  BodyOrderMethod,
  CounterJSON,
  CounterSelectionOrder,
  TextElement,
  ZeroPaddingStyle,
} from '@/types/Label/TextElement'
import { TranslateResult } from 'vue-i18n'
import { DynamicElementSettingsMixin } from '@/components/layout/buildPlans/marking/mixins/DynamicElementSettingsMixin'
import { LabelTooltipMixin } from '@/components/layout/buildPlans/marking/mixins/LabelTooltipMixin'
import { ClickOutsideMixin } from '@/components/layout/mixins/ClickOutsideMixin'
import { ISelectable } from '@/types/BuildPlans/IBuildPlan'
import { LabelServiceMixin } from '@/components/layout/buildPlans/marking/mixins/LabelServiceMixin'

const labelStore = namespace(StoresNamespaces.Labels)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

interface IMixinInterface
  extends Vue,
    DynamicElementSettingsMixin,
    LabelTooltipMixin,
    ClickOutsideMixin,
    LabelServiceMixin {}

@Component({ components: { NumberField, draggable } })
export default class CounterSettings extends Mixins<IMixinInterface>(
  Vue,
  DynamicElementSettingsMixin,
  LabelTooltipMixin,
  ClickOutsideMixin,
  LabelServiceMixin,
) {
  @buildPlansStore.Getter getSelectedParts: ISelectable[]
  @buildPlansStore.Getter isLabelReadOnly: boolean

  @labelStore.Action updateCounterDynamicElement: (element: TextElement) => void

  start: string = '1'
  incrementStep: string = '1'
  useSelectionOrder: boolean = true
  leadingZeros: boolean = false
  title: string = null
  sortOptions: CounterSelectionOrder[] = [
    CounterSelectionOrder.LeftToRight,
    CounterSelectionOrder.FrontToBack,
    CounterSelectionOrder.BotToTop,
  ]
  sets = []
  id: number = 0
  topOffset: number = 0
  leftOffset: number = 0
  triangleLeft: number = 22
  triangleTop: number = 15
  nameId: number = 0

  $refs!: {
    dialog: Element
  }

  get customMessages() {
    return {
      required: this.$t('labelTool.counterSettings.enterValue').toString(),
    }
  }

  mounted() {
    // Set values from dynamic element settings in a store to the local component variables
    this.setListenerForClickOutside(true, null, this.customHandler)
    const specificJSON: CounterJSON = JSON.parse(
      this.getActiveDynamicElementDialogInfo.textElement._cachedSpecificsJSON,
    )
    this.id = this.getActiveDynamicElementDialogInfo.textElement.elementIDNumber
    this.title = this.getActiveDynamicElementDialogInfo.textElement.title
    this.start = this.scientificToRegularNumber(specificJSON.startNumber)
    this.useSelectionOrder = specificJSON.ordering.method === BodyOrderMethod.CartesianSort
    this.leadingZeros = !!specificJSON.leadingZero
    this.sortOptions = specificJSON.ordering.sortCoordinateOrder
    this.incrementStep = this.scientificToRegularNumber(specificJSON.increment)
    this.nameId = +this.title.split(' ').pop()
    this.$forceUpdate()
    // On html content is loaded set the position of a dialog
    this.$nextTick(() => {
      const { leftOffset, triangleLeft } = this.setLeft(this.getActiveDynamicElementDialogInfo.left, this.$refs.dialog)
      const { topOffset, triangleTop } = this.setTop(this.getActiveDynamicElementDialogInfo.top, this.$refs.dialog)
      this.leftOffset = leftOffset
      this.topOffset = topOffset
      this.triangleLeft = triangleLeft
      this.triangleTop = triangleTop
    })
  }

  beforeDestroy() {
    this.setListenerForClickOutside(false, null, this.customHandler)
  }

  usedInArray() {
    if (this.getActiveDynamicElementDialogInfo.textElement) {
      const usedInLine = this.usedIn(this.getActiveDynamicElementDialogInfo.textElement, true)
      if (usedInLine.length) {
        return usedInLine.split(',')
      }
    }
    return []
  }

  scientificToRegularNumber(value: number): string {
    // Since requirements says that range of start number and increment number is unlimited we should handle
    // scientific notation for large numbers
    // TODO: use Number.prototype.toLocaleString options.useGrouping when/if it stops being experimental and
    // TODO: it is available for all browsers. This option will remove the need of replacing commas
    return value.toLocaleString().replaceAll(',', '')
  }

  close() {
    this.$emit('close')
  }

  save() {
    this.updateElement()
    this.$emit('close')
  }

  updateElement() {
    const element = JSON.parse(JSON.stringify(this.getActiveDynamicElementDialogInfo.textElement))
    const newCacheSpecificJson = JSON.stringify(this.generateSpecificJson())
    if (newCacheSpecificJson !== element._cachedSpecificsJSON) {
      const updatedElement = {
        ...element,
        _cachedSpecificsJSON: newCacheSpecificJson,
      }

      this.updateCounterDynamicElement(updatedElement)
      this.$emit('updated', updatedElement)
    }
  }

  @Watch('incrementStep')
  @Watch('start')
  @Watch('useSelectionOrder')
  @Watch('sortOptions')
  @Watch('leadingZeros')
  onValuesChanged() {
    this.updateElement()
  }

  customHandler(event: PointerEvent) {
    this.dialogOutsideClickHandler(event, 'counter-settings', `element-chip-${this.id}`, this.updateElement, this.close)
  }

  updated() {
    if (this.getActiveDynamicElementDialogInfo.textElement) {
      this.checkElementChanged(
        this.getActiveDynamicElementDialogInfo.textElement._cachedSpecificsJSON,
        JSON.stringify(this.generateSpecificJson()),
      )
    }
  }

  setUseSelectionOrder(value: boolean) {
    this.useSelectionOrder = value
  }

  generateSpecificJson(): CounterJSON {
    return {
      increment: +this.incrementStep,
      startNumber: +this.start,
      leadingZero: this.leadingZeros
        ? (+this.start + +this.incrementStep * (this.getSelectedParts.length - 1)).toString().length
        : 0,
      zeroPadding: this.leadingZeros ? ZeroPaddingStyle.Automatic : ZeroPaddingStyle.None,
      ordering: {
        method: this.useSelectionOrder ? BodyOrderMethod.CartesianSort : BodyOrderMethod.InOrderSelected,
        sortCoordinateOrder: this.sortOptions,
      },
    }
  }

  sortOptionName(sortOption: CounterSelectionOrder): TranslateResult {
    switch (sortOption) {
      case CounterSelectionOrder.FrontToBack:
        return this.$t('labelTool.counterSettings.frontToBack')
      case CounterSelectionOrder.BotToTop:
        return this.$t('labelTool.counterSettings.bottomToTop')
      case CounterSelectionOrder.LeftToRight:
        return this.$t('labelTool.counterSettings.leftToRight')
      case CounterSelectionOrder.BackToFront:
        return this.$t('labelTool.counterSettings.backToFront')
      case CounterSelectionOrder.TopToBot:
        return this.$t('labelTool.counterSettings.topToBot')
      case CounterSelectionOrder.RightToLeft:
        return this.$t('labelTool.counterSettings.rightToLeft')
    }
  }

  moveToTop(i: number) {
    const copy = [...[], ...this.sortOptions]
    const previous = copy[i - 1]
    const current = copy[i]
    copy[i] = previous
    copy[i - 1] = current
    this.sortOptions = copy
    this.$forceUpdate()
  }

  moveToBottom(i: number) {
    const copy = [...[], ...this.sortOptions]
    const previous = copy[i + 1]
    const current = copy[i]
    copy[i] = previous
    copy[i + 1] = current
    this.sortOptions = copy
    this.$forceUpdate()
  }

  flip(index: number) {
    const copy = [...[], ...this.sortOptions]
    switch (copy[index]) {
      case CounterSelectionOrder.FrontToBack:
        copy[index] = CounterSelectionOrder.BackToFront
        break
      case CounterSelectionOrder.BotToTop:
        copy[index] = CounterSelectionOrder.TopToBot
        break
      case CounterSelectionOrder.LeftToRight:
        copy[index] = CounterSelectionOrder.RightToLeft
        break
      case CounterSelectionOrder.BackToFront:
        copy[index] = CounterSelectionOrder.FrontToBack
        break
      case CounterSelectionOrder.TopToBot:
        copy[index] = CounterSelectionOrder.BotToTop
        break
      case CounterSelectionOrder.RightToLeft:
        copy[index] = CounterSelectionOrder.LeftToRight
        break
    }

    this.sortOptions = copy
    this.$forceUpdate()
  }
}
