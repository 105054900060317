
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import VSelect from 'vuetify/lib/components/VSelect'
import { IMarkTemplate } from '@/types/Marking/IMarkTemplate'
import { ClickOutsideMixin } from '@/components/layout/mixins/ClickOutsideMixin'

@Component
export default class MarkingAppearenceTab extends ClickOutsideMixin {
  @Prop() template: IMarkTemplate

  $refs!: {
    fontNameSelector: VSelect
    fontStyleSelector: VSelect
  }

  isMounted: boolean = false
  fontNames: any[] = ['Arial', 'Times New Roman', 'Courier New', 'Verdana', 'Georgia']
  selectedFontName: string = this.fontNames[0]
  fontStyle: any[] = [
    { text: 'Regular', value: 0 },
    { text: 'Bold', value: 1 },
    { text: 'Italic', value: 2 },
    { text: 'Bold Italic', value: 3 },
  ]
  selectedFontStyle: number = this.fontStyle[0].value
  isFontSizeFix: boolean = false
  fontTargetSize: number = 1
  fontMinSize: number = 1
  textMarginSize: number = 1
  vAlignment: any[] = [
    { text: 'Top', value: 0 },
    { text: 'Middle', value: 1 },
    { text: 'Bottom', value: 2 },
  ]
  hAlignment: any[] = [
    { text: 'Left', value: 0 },
    { text: 'Centre', value: 1 },
    { text: 'Right', value: 2 },
  ]
  selectedVAlignment: string = this.vAlignment[0].value
  selectedHAlignment: string = this.hAlignment[0].value
  textBooleanOptions: any[] = [
    { text: 'Add', value: 0 },
    { text: 'Subtract', value: 1 },
  ]
  textBoolean: number = 0
  teAboveSurface: number = 1
  teBelowSurface: number = 1

  get isActiveMenu() {
    if (!this.isMounted) {
      return false
    }

    const isFontNameSelectorMenuActive =
      this.$refs.fontNameSelector.$refs &&
      this.$refs.fontNameSelector.$refs.menu &&
      this.$refs.fontNameSelector.$refs.menu.isActive

    const isFontStyleSelectorMenuActive =
      this.$refs.fontStyleSelector.$refs &&
      this.$refs.fontStyleSelector.$refs.menu &&
      this.$refs.fontStyleSelector.$refs.menu.isActive

    return isFontNameSelectorMenuActive || isFontStyleSelectorMenuActive
  }

  @Watch('isActiveMenu')
  onIsMenuActiveChanged() {
    this.setListenerForClickOutside(this.isActiveMenu, this.closeAllMarkingTabSelectorsMenu)
  }

  closeAllMarkingTabSelectorsMenu() {
    this.$refs.fontNameSelector.$refs.menu.isActive = false
    this.$refs.fontStyleSelector.$refs.menu.isActive = false
  }

  mounted() {
    this.isMounted = true
  }
}
