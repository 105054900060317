/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Scene } from '@babylonjs/core/scene'
import {
  IResultsPathData,
  IDataDirectoryStructure,
  ResultsManagerEvent as RMEvent,
} from '@/visualization/types/SimulationTypes'
import { OuterEvents } from '@/visualization/types/Common'

import { Engine } from '@babylonjs/core/Engines/engine'
import { createGuid } from '@/utils/common'
import {
  IVisualizationServiceCommand,
  IVisualizationServiceMessage,
  ResultsManager,
  WsCommands,
} from '@/visualization/rendering/ResultsManager'
import { ISimulationStep, StepType } from '@/types/Simulation/SimulationSteps'
import { PARTS_PVD, SIZE_ADJUSTMENT_FACTOR, STL, SUPPORTS_PVD, VTP } from '@/constants'
import i18n from '@/plugins/i18n'
import { SimulationStepHandler } from '@/visualization/rendering/SimulationStepHandler'

const EXCLUDE_PATTERN = 'temp'
const IBC_FOLDER = 'IBC_Output'
const DEVIATION_PATTERN = /compensation_\d+/
const LEGACY_RESULT_NAME_PATTERN = /^ibc-[^]*scaled_down.vtk$/i
const EXPORT_FOLDER = 'results_export'
const IBC_REPORT_NAME = 'ibcreport.pdf'

export class DeviationManager extends ResultsManager {
  private browseDirCommand: IVisualizationServiceCommand
  private dirContent: IDataDirectoryStructure

  constructor(scene: Scene, engine: Engine) {
    super(scene, engine)
    this.initCommands()
  }

  get isDMLM() {
    return false
  }

  get analysisSettings() {
    return undefined
  }

  startVisualization() {
    if (!super.startVisualization()) return false

    this.loadStep()
    return true
  }

  handleOuterEvent(eventName: OuterEvents, payload: object) {
    if (super.handleOuterEvent(eventName, payload)) return true
    switch (eventName) {
      case OuterEvents.SetResultsPathData:
        const resultsPathData = payload as IResultsPathData
        this.setResultsPathData(resultsPathData)
        break

      default:
        const message: string = `Unknown event ${eventName}`
        console.log(message)
        throw new Error(message)
    }

    this.renderScene()
    return true
  }

  protected helloCommandHandler() {
    this.browseDirCommand.parameters = [this.buildPlanId, this.jobNumber, EXCLUDE_PATTERN]
    this.socket.emit('command', this.browseDirCommand)
  }

  /**
   * Sets default value for utility information
   */
  protected initCommands() {
    super.initCommands()
    this.browseDirCommand = { id: createGuid(), name: WsCommands.FullBrowseDirectory }

    this.commandMap.set(this.browseDirCommand.id, (msg: IVisualizationServiceMessage) => {
      this.handleBrowseDirectory(msg.message.result)
    })
  }

  private handleBrowseDirectory(result: { dirContent: IDataDirectoryStructure; exportConfig }) {
    this.dirContent = result.dirContent
    const currentFolder = result.dirContent.dirs[IBC_FOLDER]

    Object.keys(currentFolder.dirs).forEach((dir) => {
      if (DEVIATION_PATTERN.test(dir)) {
        const step = { name: i18n.t('ibcFromPreviousCompensation').toString(), value: dir, type: StepType.Deviation }
        if (this.checkStepResultsAvailable(currentFolder.dirs[dir], step)) {
          this.stepsList.push(step)

          // Set current simulation step directly as long we have only one
          this.setSimulationStep(step)
        }
      }
    })

    this.triggerEvent(RMEvent.SimulationStepsInitialized, { steps: this.stepsList })
    const results = { exportData: result.exportConfig, available: this.stepsList.length !== 0 }
    this.resultsEvent.trigger({ event: RMEvent.ResultsAvailable, args: results })
  }

  private loadStep() {
    const folderInfo = this.dirContent.dirs[IBC_FOLDER].dirs[this.currentSimulationStep.value]
    const simulationFiles = this.getSimulationFilenames(folderInfo)
    const step = new SimulationStepHandler(this, null, simulationFiles)
    step.openDataSet()
    this.simulationStepsData.set(this.currentSimulationStep.value, step)
    this.triggerEvent(RMEvent.SimulationStepLoaded, this.currentSimulationStep.value)
  }

  private getSimulationFilenames(resultsFolder) {
    let parts = this.getFileNamesByPattern(resultsFolder, (d) => d.label.toLowerCase() === PARTS_PVD)
    const supports = this.getFileNamesByPattern(resultsFolder, (d) => d.label.toLowerCase() === SUPPORTS_PVD)
    if (!parts) {
      // Backwards compatibility case, here we have only one VTK file
      parts = this.getFileNamesByPattern(resultsFolder, (f) => LEGACY_RESULT_NAME_PATTERN.test(f.label))
    }

    return {
      parts,
      supports,
      buildPlate: undefined,
      coupons: undefined,
    }
  }

  private checkStepResultsAvailable(stepDir: IDataDirectoryStructure, simStep: ISimulationStep): boolean {
    const filesInfo = this.getSimulationFilenames(stepDir)
    simStep.size = 0
    let showHandlerToggles = false
    for (const file of stepDir.files) {
      if (file.label.toLocaleLowerCase().endsWith(VTP)) {
        showHandlerToggles = true
        simStep.size += file.size * SIZE_ADJUSTMENT_FACTOR
      }

      // Backwards compatibility case, here we have only one VTK file
      if (LEGACY_RESULT_NAME_PATTERN.test(file.label)) {
        simStep.size = file.size * SIZE_ADJUSTMENT_FACTOR
      }
    }

    if (filesInfo.parts && showHandlerToggles) {
      this.triggerEvent(RMEvent.HandlerTogglesAvailable, undefined)
    }

    return !!filesInfo.parts
  }
}
