
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { FontStyleHelpBalloonInfo } from '@/types/Label/FontStyleHelpBalloonInfo'
import variables from '@/assets/styles/variables.scss'

const labelStore = namespace(StoresNamespaces.Labels)

const HEADER_HEIGHT = 54 // px
const BALLOON_HEIGHT = 180 // px
const BOTTOM_PAGE_OFFSET = 20 // px

@Component
export default class LabelFontStyleBalloonHelp extends Vue {
  @labelStore.Getter getFontStyleHelpBalloonInfo: FontStyleHelpBalloonInfo

  get topOffset() {
    let top = this.getFontStyleHelpBalloonInfo.top - parseInt(variables.applicationHeaderHeight, 0)
    // If block is expanding the page and adding the scroll to it, we should lift it up so the page is not
    // transformed due to the help balloon
    if (top + HEADER_HEIGHT + BALLOON_HEIGHT > window.innerHeight) {
      top = window.innerHeight - HEADER_HEIGHT - BALLOON_HEIGHT - BOTTOM_PAGE_OFFSET
    }
    return top
  }

  get leftOffset() {
    return this.getFontStyleHelpBalloonInfo.left
  }
}
