import { IRootState } from '@/store/types'
import { MultiItemCollector } from '@/types/OptionalMultiItemCollector/MultiItemCollector'
import { GetterTree } from 'vuex'
import { IOptionalMultiItemCollectorState } from './types'

export const getters: GetterTree<IOptionalMultiItemCollectorState, IRootState> = {
  isCollectorActive:
    (state: IOptionalMultiItemCollectorState) =>
    (collectorId: string): boolean => {
      return state.activeCollector && state.activeCollector.id === collectorId
    },

  getCollectorById:
    (state: IOptionalMultiItemCollectorState) =>
    (collectorId: string): MultiItemCollector => {
      return state.collectors.find((collector) => collector.id === collectorId)
    },

  getActiveCollectorId(state: IOptionalMultiItemCollectorState): string {
    return state.activeCollector ? state.activeCollector.id : null
  },
}
