
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import VMenu from 'vuetify/lib/components/VMenu'
import { ClickOutsideMixin } from '@/components/layout/mixins/ClickOutsideMixin'

@Component
export default class Menu extends ClickOutsideMixin {
  @Prop({ default: false }) value: boolean
  @Prop({ default: false }) openOnHover: boolean
  @Prop({ default: true }) openOnClick: boolean
  @Prop({ default: false }) absolute: boolean
  @Prop({ default: false }) allowOverflow: boolean
  @Prop({ default: false }) auto: boolean
  @Prop({ default: false }) top: boolean
  @Prop({ default: false }) bottom: boolean
  @Prop({ default: false }) right: boolean
  @Prop({ default: false }) left: boolean
  @Prop({ default: 0 }) closeDelay: number | string
  @Prop({ default: true }) closeOnClick: boolean
  @Prop({ default: true }) closeOnContentClick: boolean
  @Prop({ default: '' }) contentClass: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) fixed: boolean
  @Prop({ default: 'auto' }) maxHeight: number | string
  @Prop({ default: undefined }) minWidth: number | string
  @Prop({ default: 'auto' }) maxWidth: number | string
  @Prop({ default: 0 }) nudgeWidth: number | string
  @Prop({ default: false }) offsetOverflow: boolean
  @Prop({ default: false }) offsetX: boolean
  @Prop({ default: false }) offsetY: boolean
  @Prop({ default: undefined }) positionX: number
  @Prop({ default: undefined }) positionY: number
  @Prop({ default: undefined }) rounded: boolean | string

  $refs!: {
    menu: VMenu
  }

  // @ts-ignore
  model = this.value ? this.value : false

  @Watch('value')
  onValueChanged(val) {
    this.model = val
  }

  @Watch('model')
  onModelChanged(val) {
    this.setListenerForClickOutside(val, this.close)
  }

  input(e) {
    this.$emit('input', e)
  }

  close() {
    this.model = false
    this.input(this.model)
  }
}
