
import { format } from 'date-fns'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Selector from '@/components/controls/Common/Selector.vue'
import {
  ActiveToolUnsavedChangesMixin,
  ExitToolAction,
} from '@/components/layout/buildPlans/mixins/ActiveToolUnsavedChangesMixin'
import { LOGOUT_EVENT, passwordRules } from '@/constants'
import { RouterNames, RouterPaths, ROUTER_PERMISSIONS } from '@/router'
import StoresNamespaces from '@/store/namespaces'
import { IUser } from '@/types/User/IUser'
import clearAuth0Cookies from '@/utils/cookies/auth0'
import { isAuthorized } from '@/utils/user'

const userStore = namespace(StoresNamespaces.User)

@Component({
  components: {
    Selector,
  },
})
export default class ProfileMenu extends Mixins(ActiveToolUnsavedChangesMixin) {
  @userStore.Action('setUserDetails') setUserDetails: any
  @userStore.Action('setIsAuthenticated') setIsAuthenticated: any

  @userStore.Getter('getUserDetails') getUserDetails: IUser
  @userStore.Getter('getAuth0Client') auth0Client: any

  @Prop() user: IUser
  dialog: boolean = false
  changePasswordActive = false
  password: string = null
  confirmPassword: string = null
  currentPassword: string = null
  passwordRules: object = passwordRules
  formValid: boolean = true
  isInvalidPwd: boolean = false
  validPwd: boolean = false
  isAuthorizedToSeeTenantAdmin = false

  ampVersion: string = window.env.VUE_APP_AMP_VERSION
  serviceAgreementLink: string = window.env.VUE_APP_SERVICE_AGREEMENT_URL
  acceptedDate: string = null
  termsLink: string = '//www.ge.com/terms'
  privacyLink: string = '//www.ge.com/privacy'
  cookiesLink: string = '//info.evidon.com/pub_info/3428'
  integrityLink: string = '//www.ge.com/sustainability/ourculture'

  openProfileMenu() {
    this.dialog = !this.dialog
    if (this.dialog) {
      this.$emit('profileOpened')
    }
  }

  @Watch('dialog')
  checkTenantAdminVisibility(visible: boolean) {
    if (visible) {
      const user: IUser = this.getUserDetails
      if (user) {
        this.isAuthorizedToSeeTenantAdmin = isAuthorized(user, [
          ...ROUTER_PERMISSIONS.UserAdmin,
          ...ROUTER_PERMISSIONS.JobsAdmin,
        ])
        this.acceptedDate = this.formatDate(user.acceptedTermsOfService)
      }
    }
  }

  async onLogoutClick() {
    const toolAction = await this.canExitActiveTool()
    if (toolAction === ExitToolAction.DoNotExit) return

    this.setUserDetails(null)
    this.setIsAuthenticated(false)
    this.closeProfileMenu()
    // Clean up the cookies generated by Auth0 client first
    // because of https://github.com/auth0/auth0-spa-js/issues/217
    clearAuth0Cookies()
    // dispatch logout event to other browser tabs, if any
    localStorage.setItem(`${LOGOUT_EVENT}-${window.location.host}`, `${new Date().getTime()}`)
    // then log the user out and land them on the home page
    this.auth0Client.logout({ returnTo: window.location.origin })
  }

  goToHelpLibrary() {
    const routeData = this.$router.resolve({ name: RouterNames.HelpLibrary })
    window.open(routeData.href, '_blank')
  }

  goToTenantAdmin() {
    const user: IUser = this.getUserDetails
    if (isAuthorized(user, [...ROUTER_PERMISSIONS.PrintModel])) {
      // @ts-ignore
      this.$router.safePush(RouterPaths.Sites)
    } else {
      // @ts-ignore
      this.$router.safePush(RouterPaths.JobsDashboard)
    }
    this.closeProfileMenu()
  }

  goToTerms() {
    // @ts-ignore
    this.$router.safePush(RouterPaths.TermsAndConditions)
  }

  formatDate(dateStr: string) {
    return format(new Date(dateStr), this.$t('dateSlashFormat') as string)
  }

  closeProfileMenu() {
    this.dialog = false
  }
}
