
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import Button from '@/components/controls/Common/Button.vue'
import HoverableTooltip from '@/components/modals/HoverableTooltip.vue'

@Component({
  components: {
    Button,
    HoverableTooltip,
  },
})
export default class ProcessStateChangeModal extends Vue {
  @Prop({ required: true }) value: boolean
  @Prop() processStateName: string
  @Prop() sinterPartsCount: number
  @Prop() partNames: string[]

  onCancelClick() {
    this.$emit('close')
  }
}
