
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Prop } from 'vue-property-decorator'
import { IJob, JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import StoresNamespaces from '@/store/namespaces'
import { IBuildPlan, IVariantItem } from '@/types/BuildPlans/IBuildPlan'
import { VariantStatus, DEFAULT_FIRST_VARIANT_VERSION } from '@/constants'
import { capitalizeFirstLetter } from '@/utils/string'
import VariantPreview from '@/components/layout/buildPlans/variants/VariantPreview.vue'
import { formatDateWithDefaultFormat } from '@/utils/common'
import { RouterNames } from '@/router'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'
import { ItemType } from '@/types/FileExplorer/ItemType'
import VariantIcon from '@/components/icons/VariantIcon.vue'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const commonStore = namespace(StoresNamespaces.Common)
const userStore = namespace(StoresNamespaces.User)

@Component({
  components: { VariantPreview, VariantIcon },
})
export default class VariantItem extends Vue {
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getIBCPlan: IIBCPlan
  @buildPlansStore.Getter getIbcPlanJobs: IJob[]
  @buildPlansStore.Getter getCompleteVariantSlicingJobs: (id: string) => IJob[]
  @buildPlansStore.Getter getCompleteVariantPublishJobs: (id: string) => IJob[]
  @buildPlansStore.Getter getCompleteVariantIbcPublishJobs: (id: string) => IJob[]
  @buildPlansStore.Getter getRunningJobsByVariantId: (id: string) => IJob[]
  @buildPlansStore.Getter getIsVariantLockedForUserByVariantId: (id: string) => boolean
  @buildPlansStore.Getter isVariantProcessing: boolean
  @buildPlansStore.Getter isVariantCreating: boolean
  @buildPlansStore.Getter getLockedByForVariant: (id: string) => string
  @buildPlansStore.Getter getRunningSlicingJobsByVariantId: (id: string) => IJob[]
  @buildPlansStore.Getter getRunningSimulationJobsByVariantId: (id: string) => IJob[]
  @buildPlansStore.Getter getRunningNestingJobsByVariantId: (id: string) => IJob[]
  @buildPlansStore.Getter getBuildPlanVariantById: (id: string) => IBuildPlan
  @buildPlansStore.Getter variantHasRemovedParent: (id: string) => boolean
  @userStore.Getter lookupFullNameById: (userId: string) => string
  @commonStore.Getter tooltipOpenDelay: number

  @Prop() variant: IVariantItem
  @Prop() disabled: boolean
  @Prop() onVariantLeftClick: (variant: IVariantItem) => void
  @Prop() onVariantRightClick: (variant: IVariantItem, event) => void
  @Prop({ default: false }) isShownVariantContextMenu: boolean

  tooltipDisabled = false
  mouseX: number

  get isVariantSelected(): boolean {
    if (this.$router.currentRoute.matched.some((route) => route.name === RouterNames.EditBuildPlan)) {
      return this.variant.id === this.getBuildPlan.id
    }

    return this.variant.id === this.getIBCPlan.id
  }

  get isPublishedForPrinting(): boolean {
    return this.getCompleteVariantSlicingJobs(this.variant.id).length > 0
  }

  get isVariantPublished(): boolean {
    return (
      this.getCompleteVariantPublishJobs(this.variant.id).length > 0 ||
      this.getCompleteVariantIbcPublishJobs(this.variant.id).length > 0
    )
  }

  get isEditedBySomeone(): boolean {
    return this.getIsVariantLockedForUserByVariantId(this.variant.id)
  }

  get isIbcVariant(): boolean {
    return this.variant.itemType === ItemType.IbcPlan
  }

  get hasProcess(): boolean {
    const isProcessing = this.isVariantProcessing && this.isVariantSelected && !this.isVariantCreating

    if (isProcessing) {
      return true
    }

    let hasRunningJobs: boolean

    if (this.isIbcVariant) {
      hasRunningJobs = this.getIbcPlanJobs
        .filter((job) => {
          // For the PUBLISH job the 'itemId' property contains the ID of IBC Part
          if (job.jobType === JobType.IBC_PUBLISH) {
            return job.relatedPlanId === this.variant.id
          }
          return job.itemId === this.variant.id
        })
        .some((job) => {
          return [JobStatusCode.RUNNING, JobStatusCode.PENDING, JobStatusCode.QUEUED, JobStatusCode.CREATED].includes(
            job.code,
          )
        })
    } else {
      hasRunningJobs = this.getRunningJobsByVariantId(this.variant.id).length > 0
    }

    return hasRunningJobs
  }

  get variantStatus(): string {
    const { id } = this.variant
    const hasSlicingJobs = this.getRunningSlicingJobsByVariantId(id).length > 0
    const hasSimulationJobs = this.getRunningSimulationJobsByVariantId(id).length > 0
    const hasArrangeJobs = this.getRunningNestingJobsByVariantId(id).length > 0

    // locked by other user (edited by someone else)
    if (this.variant.isLocked && this.isEditedBySomeone) {
      const lockedBy = this.getLockedByForVariant(this.variant.id)
      const editedBy = this.$t(VariantStatus.EditedBy) as string
      return `${editedBy} ${lockedBy}`
    }

    if (this.isPublishedForPrinting) {
      return this.$t(VariantStatus.PublishedForPrinting) as string
    }

    if (hasSimulationJobs) {
      return this.$t(VariantStatus.RunningSimulation) as string
    }

    if (hasSlicingJobs) {
      return this.$t(VariantStatus.RunningSlicing) as string
    }

    if (hasArrangeJobs) {
      return this.$t(VariantStatus.RunningArrange) as string
    }

    return null
  }

  get createdBy(): string {
    return this.lookupFullNameById(this.variant.createdBy)
  }

  get createdOn(): string {
    return formatDateWithDefaultFormat(this.variant.createdAt)
  }

  get copiedFrom(): string {
    const deletedLabel = capitalizeFirstLetter(this.$t('deleted') as string)

    const parentId = this.variant.parentId
    const parentVariant = this.getBuildPlanVariantById(parentId)
    const parentLabel = parentVariant ? parentVariant.versionLabel : ''
    return this.variantHasRemovedParent(parentId) ? `${parentLabel} (${deletedLabel})` : `${parentLabel}`
  }

  get isFirstVersion(): boolean {
    return this.variant.version === DEFAULT_FIRST_VARIANT_VERSION
  }

  get isFirefox(): boolean {
    return navigator.userAgent.indexOf('Firefox') !== -1
  }

  leftVariantClick() {
    if (this.onVariantLeftClick && !this.isEditedBySomeone && !this.isVariantSelected && !this.disabled) {
      this.onVariantLeftClick(this.variant)
    }
  }

  rightVariantClick(event) {
    if (this.onVariantRightClick && !this.isEditedBySomeone && !this.disabled) {
      this.onVariantRightClick(this.variant, event)
    }
  }

  onDragStart(event) {
    const img = new Image()
    event.dataTransfer.setDragImage(img, 0, 0)
    event.dataTransfer.effectAllowed = 'move'
    this.tooltipDisabled = true
    this.$emit('drag-start')
    document.body.addEventListener('dragover', this.onDragOver)
  }

  onDrag(event) {
    const payload = { x: event.x }
    if (this.isFirefox) {
      // Firefox "drag" event is missing correct position values,
      // so we can use the values that can be obtained from the "dragover" event
      payload.x = this.mouseX
    }
    this.$emit('drag', payload)
  }

  onDragEnd() {
    this.tooltipDisabled = false
    this.$emit('drag-end', this.variant)
    document.body.removeEventListener('dragover', this.onDragOver)
  }

  onDragOver(event) {
    event.stopPropagation()
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
    this.mouseX = event.clientX
  }
}
