/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/

export enum SceneMode {
  BuildPlan,
  SinglePart,
  PreviewPart,
  PreviewPrintOrder,
  PreviewItem,
  PreviewDetails,
}
