import streamSaver from 'streamsaver'
import * as ponyfill from 'web-streams-polyfill/ponyfill'
import * as socketStream from 'socket.io-stream'
streamSaver.WritableStream = ponyfill.WritableStream
streamSaver.mitm = '/workers/streamsaver/mitm.html?version=2.0.0'
import { ISimulationExportInfo } from '@/visualization/types/SimulationTypes'
import store from '@/store'
import i18n from '@/plugins/i18n'
import { TOGGLE_COMPENSATION_FILE_PROGRESS } from '@/constants'
import messageService from '@/services/messageService'
import { ResultsManager, WsCommands } from '@/visualization/rendering/ResultsManager'
import { createGuid } from '@/utils/common'

export class StreamHandler {
  private bpName: string
  private bpVariant: string
  private filePrefix: string
  private manager: ResultsManager
  private stream
  private writer
  private fileSize: number
  private writeStream

  constructor(manager: ResultsManager) {
    this.manager = manager
    this.closeWriter = this.closeWriter.bind(this)
    this.fileSize = 0
  }

  set targetSize(value: number) {
    if (value <= 0) return
    this.fileSize = value
  }

  get targetSize(): number {
    return this.fileSize
  }

  get buildPlanName() {
    return this.bpName
  }

  get buildPlanVariant() {
    return this.bpVariant
  }

  setSimulationExportInfo(exportInfo: ISimulationExportInfo) {
    this.bpName = exportInfo.buildPlanName
    this.bpVariant = exportInfo.buildPlanVariant
    this.filePrefix = exportInfo.prefix
  }

  getFileStream(buildPlanId, jobNumber) {
    if (!this.bpName || !this.bpVariant) throw new Error('Invalid build plan data')
    this.toggleCompensationExportProgress(true)

    const fileName = `${this.filePrefix}_${this.bpName}_${this.bpVariant}`
    this.stream = socketStream.createStream()
    let options
    if (this.fileSize) {
      options = {
        size: this.fileSize,
      }
    }

    this.writeStream = streamSaver.createWriteStream(`${fileName}.zip`, options)
    this.writer = this.writeStream.getWriter()

    this.stream.on('data', async (data) => {
      if (this.writer.desiredSize === null) {
        // This case triggered when download cancelled
        this.stream.end()
      } else if (this.writer.desiredSize === 0) {
        // This logic for pause/unpause download
        this.stream.pause()
        // Catch branch will handle the cancellation of paused download
        this.writer.ready
          .then(() => {
            // Write data chunck on which download has been paused
            this.writer.write(data)
            this.stream.resume()
          })
          .catch((err) => this.stream && this.stream.resume())
      } else {
        // Catch is triggered when paused download is cancelled. We cannot just end paused stream,
        // in this case 'end' event is not raised. We need first resume the stream and end it after that
        this.writer.write(data).catch(() => this.stream && this.stream.end())
      }
    })

    this.stream.on('error', (err) => {
      this.closeWriter()
      messageService.showWarningMessage(i18n.t('exportCompensationError') as string)
    })

    this.stream.on('end', this.closeWriter)

    const command = {
      id: createGuid(),
      name: WsCommands.ExportCompensationFiles,
      parameters: [buildPlanId, jobNumber],
    }

    socketStream(this.manager.getSocket).emit('stream', this.stream, command)
  }

  clear() {
    this.bpName = ''
    this.bpVariant = ''

    if (this.stream) {
      this.stream.destroy()
    }

    if (this.writer) {
      // We need to release the lock on writer before we can abort the stream
      this.writer.releaseLock()
      this.writeStream.abort()
      this.writer = null
      this.stream = null
    }

    this.fileSize = 0
    this.closeWriter()
  }

  toggleCompensationExportProgress(isInProgress: boolean) {
    store.commit(TOGGLE_COMPENSATION_FILE_PROGRESS, isInProgress)
  }

  private closeWriter() {
    if (this.writer) {
      // Close the writer if download finished successfully
      if (this.writer.desiredSize !== null) {
        this.writer.close()
      }

      this.writer = null
      this.stream = null
    }

    this.toggleCompensationExportProgress(false)
  }
}
