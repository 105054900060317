import { ActionTree } from 'vuex'
import { IPartsState } from './types'
import { IRootState } from '@/store/types'
import { IPartDto } from '@/types/PartsLibrary/Parts'

import partsService from '@/api/parts'
import messageService from '@/services/messageService'
import { ItemType } from '@/types/FileExplorer/ItemType'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'

const partsComparator = (a, b) => {
  const dateA = a.createdDate
  const dateB = b.createdDate
  return dateA > dateB ? 1 : -1
}

export const actions: ActionTree<IPartsState, IRootState> = {
  async fetchAllParts({ commit }) {
    const parts = await partsService.getAllPartsV1()
    // Default sort by createDate ascending
    parts.sort(partsComparator)
    commit('setParts', parts)
    return parts
  },

  async fetchAllSinterParts({ commit }) {
    const sinterParts = await partsService.getAllSinterParts()
    sinterParts.sort(partsComparator)

    commit('setSinterParts', sinterParts)
    return sinterParts
  },

  async fetchSinterPartById({ state, commit }, id: string): Promise<IPartDto> {
    let part = state.sinterParts.find((p) => p.id === id)

    if (!part) {
      part = await partsService.getPartById(id)
      commit('setSinterPart', part)
    }

    return part
  },

  async getPartIdsWithUsedDate({ commit }, ids: string[]) {
    try {
      const partIdsWithUsedDate: Array<{ partId: string; usedDate: Date }> =
        await partsService.getPartIdsWithUsedDate(ids)
      commit('setPartIdsWithUsedDate', partIdsWithUsedDate)
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  },

  async getJobsByPartId({ commit }, partId: string) {
    return await partsService.getJobsByPartId(partId)
  },

  async getPartToken({ commit }) {
    return await partsService.getPartToken()
  },

  async getPartConfigFile({}, id: string) {
    const model = await partsService.getPartConfigFile(id)
    return model
  },

  async getPartById({ state, commit, dispatch }, id: string): Promise<IPartDto> {
    try {
      let part = state.parts.find((p) => p.id === id)
      if (!part) {
        part = await dispatch('fetchPartById', { id, needToUpdateStore: true })
        commit('addPart', part)
      }
      return part
    } catch (error) {
      console.error(error)
    }
  },

  async fetchPartById(
    { state, commit },
    params: {
      id: string
      needToUpdateStore?: boolean
    },
  ): Promise<IPartDto> {
    try {
      const part = await partsService.getPartById(params.id)

      if (!params.needToUpdateStore) {
        return part
      }

      const existedPart = state.parts.find((p) => p.id === params.id)
      const partMutationName = existedPart ? 'updatePart' : 'addPart'

      commit(partMutationName, part)

      return part
    } catch (error) {
      console.error(error)
    }
  },

  async updatePart({}, payload: { id: string; hiddenBodies: string[] | null }) {
    try {
      return await partsService.updatePart(payload.id, payload.hiddenBodies)
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  },

  async updatePartComponents({ commit }, payload: { id: string; components: string[] | null }) {
    try {
      const updatedPart = await partsService.updatePartComponents(payload.id, payload.components)
      commit('updatePartComponents', updatedPart)

      return updatedPart
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  },

  async actualizeParts({ rootGetters, dispatch }, feisToFilter?: FileExplorerItem[]): Promise<void> {
    const items = feisToFilter || rootGetters['fileExplorer/getItemsWithoutFolders']
    const partsInFolder = items.filter((item) => item.itemType === ItemType.BuildPart)
    if (partsInFolder.length) {
      await Promise.all(partsInFolder.map((part) => dispatch('getPartById', part.id)))
    }
  },

  async fetchAllIbcParts({ commit }) {
    const ibcParts = await partsService.getAllIbcParts()
    ibcParts.sort(partsComparator)
    commit('setIbcParts', ibcParts)
    return ibcParts
  },
}
