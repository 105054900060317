import {
  ELASTIC_STRAIN_FIELD_PATTERN,
  MAXIMUN_PRINCIPAL_PATTERN,
  MIDDLE_PRINCIPAL_PATTERN,
  MINIMUM_PRINCIPAL_PATTERN,
  VON_MISES_PATTERN,
} from '@/constants'
import { SimulationColoringView } from '@/types/Simulation/SimulationColoringView'
import { SimulationResultTypes } from '@/types/Simulation/SimulationResultTypes'

export class SimulationColoringMode {
  name: string
  metadataId: string

  private readonly parent: SimulationColoringView

  constructor(name: string, metadataId: string, parent: SimulationColoringView) {
    this.name = name
    this.metadataId = metadataId
    this.parent = parent
  }

  isNameMatches(pattern: RegExp) {
    return pattern.test(this.name)
  }

  get helpMessage(): string {
    if (this.parent.isNameMatches(ELASTIC_STRAIN_FIELD_PATTERN)) {
      if (this.isNameMatches(VON_MISES_PATTERN)) {
        return SimulationResultTypes.VonMisesStrain
      }
      if (this.isNameMatches(MAXIMUN_PRINCIPAL_PATTERN)) {
        return SimulationResultTypes.MaximumPrincipalStrain
      }
      if (this.isNameMatches(MIDDLE_PRINCIPAL_PATTERN)) {
        return SimulationResultTypes.MiddlePrincipalStrain
      }
      if (this.isNameMatches(MINIMUM_PRINCIPAL_PATTERN)) {
        return SimulationResultTypes.MinimumPrincipalStrain
      }
    }

    return SimulationResultTypes.Undefined
  }
}
