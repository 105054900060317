import { Command } from '@/types/UndoRedo/Command'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { BuildPlanItemOverhang, BuildPlanItemSupport, IBuildPlanItemSettings } from '@/types/BuildPlans/IBuildPlan'
import { IConstraints } from '@/types/BuildPlans/IConstraints'
import { Commit, Dispatch } from 'vuex'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

export class SaveSupportsCommand extends Command implements ICommand {
  readonly commandType: CommandType
  readonly toolName = ToolNames.SUPPORT

  constructor(
    private buildPlanItemId: string,
    private supports: BuildPlanItemSupport[],
    private supportsBvhFileKey: string,
    private supportsHullFileKey: string,
    private overhangs: BuildPlanItemOverhang,
    private settings: IBuildPlanItemSettings,
    private constraints: IConstraints,
    private dataToAddSupportMeshFromUndoManager: Array<{ belongsToOverhangElementName: string; sdata: ArrayBuffer }>,
    commandType: CommandType,
    protected dispatch: Dispatch,
    protected commit: Commit,
  ) {
    super()

    this.commandType = commandType
  }

  async redo(): Promise<void> {
    const payload = {
      params: {
        buildPlanItemId: this.buildPlanItemId,
        supports: this.supports,
        supportsBvhFileKey: this.supportsBvhFileKey,
        supportsHullFileKey: this.supportsHullFileKey,
        overhangs: this.overhangs,
        settings: this.settings,
        constraints: this.constraints,
      },
    }

    this.selectPartByBuildPlanItemId(this.buildPlanItemId)
    await this.dispatch('buildPlans/updateBuildPlanItem', payload, this.rootLevel)
    this.commit(
      'visualizationModule/addSupportBvhAndHull',
      {
        buildPlanItemId: this.buildPlanItemId,
        supportsBvhFileKey: this.supportsBvhFileKey,
        supportsHullFileKey: this.supportsHullFileKey,
      },
      this.rootLevel,
    )

    this.dataToAddSupportMeshFromUndoManager.forEach((data) => {
      this.commit('visualizationModule/addSupportMesh', {
        buildPlanItemId: this.buildPlanItemId,
        belongsToOverhangElementName: data.belongsToOverhangElementName,
        sdata: data.sdata,
      })
    })
  }

  async undo(): Promise<void> {
    const payload = {
      params: {
        buildPlanItemId: this.buildPlanItemId,
        supports: null,
        supportsBvhFileKey: null,
        supportsHullFileKey: null,
        overhangs: null,
        settings: null,
        constraints: {
          translation: { x: false, y: false, z: false },
          rotation: { x: false, y: false, z: false },
        },
      },
    }

    this.selectPartByBuildPlanItemId(this.buildPlanItemId)
    await this.dispatch('buildPlans/updateBuildPlanItem', payload, this.rootLevel)
    this.commit('visualizationModule/clearSupports', { buildPlanItemId: this.buildPlanItemId }, this.rootLevel)
    this.commit('visualizationModule/clearOverhangMesh', this.buildPlanItemId, this.rootLevel)
  }
}
