import { ActionTree } from 'vuex'
import { ICostEstimationState } from './types'
import { IRootState } from '@/store/types'
import { ICostEstimation } from '@/types/CostEstimations/ICostEstimation'
import { IPaginationOptions } from '@/types/IPaginationOptions'
import { PaginationKeyword } from '@/types/PaginationKeyword'

import costEstimation from '@/api/costEstimation'
import { SortParam } from '@/types/SortModes'
import { FilterParam } from '@/types/Filter'
import { IMaterial } from '@/types/IMaterial'
import { DataState } from '@/types/Common/DataState'
import { IMachineConfig } from '@/types/IMachineConfig'

enum CostEstimationFields {
  FilterField = 'materialId',
  SearchField = 'projectName',
}

interface IActionOptions {
  ifNeeded?: boolean
}

export const actions: ActionTree<ICostEstimationState, IRootState> = {
  async fetchMaterials({ commit, state }, options: IActionOptions = {}) {
    const { ifNeeded = true } = options

    if (ifNeeded && !shouldFetch(state, 'materials')) {
      return Promise.resolve()
    }

    const materials: IMaterial[] = await costEstimation.getMaterials()
    commit('setMaterials', materials)
  },

  async fetchParameterSets({ state, commit }, options: IActionOptions = {}) {
    const { ifNeeded = true } = options

    if (ifNeeded && !shouldFetch(state, 'parameterSets')) {
      return Promise.resolve()
    }

    commit('setParameterSets', await costEstimation.getParameterSets())
  },

  async fetchMachineConfigs({ state, commit }, options: IActionOptions = {}) {
    const { ifNeeded = true } = options

    if (ifNeeded && !shouldFetch(state, 'machineConfigs')) {
      return Promise.resolve()
    }

    const machineConfigs: IMachineConfig[] = await costEstimation.getMachineConfigs()
    commit('setMachineConfigs', machineConfigs)
  },

  async fetchRegions({ state, commit }, options: IActionOptions = {}) {
    const { ifNeeded = true } = options

    if (ifNeeded && !shouldFetch(state, 'regions')) {
      return Promise.resolve()
    }

    const regions = await costEstimation.getRegions()
    commit('setRegions', regions)
  },

  // fetch all cost estimations without pagination and filters
  async fetchAllCostEstimations({ commit }) {
    const estimations = await costEstimation.getCostEstimations()
    commit('setAllCostEstimations', estimations.items)
    commit('setAllCostEstimationsCount', estimations.count)
  },

  // fetch cost estimations with pagination
  async fetchFilteredCostEstimations({ commit, state }) {
    if (!state.needToUpdateCostEstimations) {
      return
    }

    try {
      commit('setDataState', { state: DataState.Loading })
      const options: IPaginationOptions = {}

      // search
      if (state.searchEstimationName) {
        options[PaginationKeyword.Search] = {
          field: CostEstimationFields.SearchField,
          value: state.searchEstimationName,
        }
      }

      // filter
      if (Object.keys(state.filterParams).length) {
        options[PaginationKeyword.Filter] = { ...state.filterParams }
      }

      // sort
      if (Object.keys(state.sortParams).length) {
        options[PaginationKeyword.Sort] = { ...state.sortParams }
      }

      options[PaginationKeyword.Page] = { limit: state.paginationLimit, offset: state.paginationOffset }

      const estimations = await costEstimation.getFilteredCostEstimations(options)
      commit('setFilteredCostEstimations', estimations.items)
      commit('setFilteredCostEstimationsCount', estimations.count)
      commit('setNeedToUpdateCostEstimations', false)
    } finally {
      commit('setDataState', { state: DataState.Loaded })
    }
  },

  async getCostEstimationById({ state, getters, dispatch }, id: number) {
    const record = getters.getCostEstimationById(id)
    if (record) {
      return record
    }
    return await costEstimation.fetchCostEstimationById(id)
  },

  async createCostEstimation({ commit }, estimation: ICostEstimation) {
    try {
      commit('setDataState', { state: DataState.Loading })
      const record = await costEstimation.createCostEstimation(estimation)
      commit('setNeedToUpdateCostEstimations', true)
      return record
    } finally {
      commit('setDataState', { state: DataState.Loaded })
    }
  },

  async estimateCost({ commit }, estimation: ICostEstimation) {
    const record = await costEstimation.estimateCost(estimation)
    if (record) {
      commit('updateCostEstimation', record)
    }
    return record
  },

  async deleteCostEstimation({ commit, dispatch, state }, id: number) {
    await costEstimation.deleteCostEstimation(id)

    const isFirstPage = state.filteredCostEstimationsCount - 1 === 0
    const needPrevPage = state.filteredCostEstimationsCount - 1 === state.paginationOffset
    if (needPrevPage && !isFirstPage) {
      const offset = state.paginationOffset - state.paginationLimit
      commit('setPaginationOffset', offset)
    }

    commit('setNeedToUpdateCostEstimations', true)
    await dispatch('fetchFilteredCostEstimations')
  },

  async cloneCostEstimation({ commit, dispatch }, id: number) {
    await costEstimation.clone(id)
    commit('setNeedToUpdateCostEstimations', true)
    await dispatch('fetchFilteredCostEstimations')
  },

  async updateCostEstimationImage({ commit }, payload: { id: number; image: File }) {
    return await costEstimation.updateCostEstimationImage(payload.id, payload.image)
  },

  async getCostEstimationsToken({ commit }) {
    return await costEstimation.getCostEstimationsToken()
  },

  async getMaterialById({ getters }, id: number, options: IActionOptions = {}) {
    const { ifNeeded = true } = options

    if (ifNeeded) {
      const material = getters.getMaterialById(id)
      if (material) {
        return Promise.resolve(material)
      }
    }

    return await costEstimation.getMaterialById(id)
  },

  async getMachineConfigById({ getters }, id: number, options: IActionOptions = {}) {
    const { ifNeeded = true } = options

    if (ifNeeded) {
      const machineConfig = getters.getMachineConfigById(id)
      if (machineConfig) {
        return Promise.resolve(machineConfig)
      }
    }

    return await costEstimation.getMachineConfigById(id)
  },

  async setSortParams({ commit, dispatch }, payload: SortParam) {
    commit('setSortParams', payload)
    commit('setNeedToUpdateCostEstimations', true)
    await dispatch('fetchFilteredCostEstimations')
  },

  async setFilterParams({ commit, dispatch }, payload: FilterParam) {
    commit('setFilterParams', payload)
    commit('setNeedToUpdateCostEstimations', true)
    await dispatch('fetchFilteredCostEstimations')
  },
}

// This is for avoiding network requests if values are already fetched
function shouldFetch(state: ICostEstimationState, key: string): boolean {
  const statePart = state[key]

  if (Array.isArray(statePart)) {
    return !statePart.length
  }

  if (statePart === null) {
    return true
  }

  return false
}
