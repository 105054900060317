
import Vue from 'vue'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'

const commonStore = namespace(StoresNamespaces.Common)

@Component({})
export default class LostConnectionToast extends Vue {
  @commonStore.Getter isDisconnected: boolean
}
