//
// TODO: This logic should be updated when updating the settings page
//

import { EndpointsUrls } from '@/configs/config'
import { get } from '@/api/http'
import { IMachineConfig } from '@/types/IMachineConfig'
import messageService from '@/services/messageService'
import i18n from '@/plugins/i18n'
import store from '@/store'
import clearAuth0Cookies from '@/utils/cookies/auth0'
import { LOGOUT_EVENT } from '@/constants'

export interface IFilteredResponse<T> {
  items: T[]
  count: number
}

export interface ApiValidationError {
  target: Object // Object that was validated.
  property: string // Object's property that haven't pass validation.
  value: any // Value that haven't pass a validation.
  constraints?: {
    // Constraints that failed validation with error messages.
    [type: string]: string
  }
  children?: ApiValidationError[] // Contains all nested validation errors of the property
}

export function handleAPIError(error, hideAPIErrorMsgAndGenerateException = false) {
  // always log the user out if an API request returns a 401
  if (error.status === 401) {
    const auth0Client = store.getters['user/getAuth0Client']
    clearAuth0Cookies()
    // dispatch logout event to other browser tabs, if any
    localStorage.setItem(`${LOGOUT_EVENT}-${window.location.host}`, `${new Date().getTime()}`)
    // then log the user out and land them on the home page
    auth0Client.logout({ returnTo: window.location.origin })
  } else if (hideAPIErrorMsgAndGenerateException) {
    const msg = error.message || error
    throw new Error(msg)
  } else if (error.validationMessages) {
    const messages = error.validationMessages as string[][]
    messages.flat().forEach((errorMessage) => {
      messageService.showErrorMessage(`${i18n.t('validationError')}: ${errorMessage}`)
    })
  } else {
    const errorMessage = error.message || error
    messageService.showErrorMessage(`${i18n.t('unexpectedError')}: ${errorMessage}`)
  }
}

export default {
  async getConnectedMachineConfigs() {
    // TODO: Update endpoint url for connected printers
    return get<IMachineConfig[]>(EndpointsUrls.MachineConfigs)
  },
}
