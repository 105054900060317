
import Vue from 'vue'
import Component from 'vue-class-component'
import { RouterPaths } from '@/router'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component
export default class TenantAdminTopBar extends Vue {
  @sitesStore.Getter previousPath: string

  navigateToAMP() {
    if (this.previousPath) {
      this.$router.push(this.previousPath)
      return
    }

    // @ts-ignore
    this.$router.safePush(RouterPaths.DefaultFileExplorer)
  }
}
