
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import echarts from '@/custom-echarts'
import {
  OrientGraphLineTypes,
  OrientSeriesItem,
  OrientSeriesDataItem,
  EChartPlotTypes,
} from '@/types/orientation/OrientTypes'
import OrientResult from '@/types/orientation/OrientResult'
import { roundToFloatLimit } from '@/utils/number'

const OUTSIDE_RANGE_COLOR = '#DFE4EC'
const SELECTED_RANGE_COLOR = '#005EB8'
const SELECTED_COLOR = '#FE5000'
const IMPORTED_COLOR = '#CC00FF'
const HOVER_COLOR = '#00FFFF'
const LAST_SAVED_COLOR = '#00BF6F'
const LEGEND_ITEM_GAP = 5
const LEGEND_BOTTOM = 30
const FLOAT_LIMIT = 3

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component
export default class OrientDataAnalysisMap extends Vue {
  @buildPlansStore.Getter('isOrientReadOnly') isOrientReadOnly: boolean

  @Prop() orientations: OrientResult[]
  @Prop() rotatePart: (orientationIndex: number, peek: boolean) => void
  @Prop() importedOrientationIndex: number
  @Prop() lastSavedOrientationIndex: number
  @Prop() isProhibitedSurfaceSelected: boolean
  @Prop() isPreferredSurfaceSelected: boolean
  @Prop() scrollId: string
  @Prop() isHaveGraphSolution: boolean

  parallelCoordinatePlotApache: string = 'parallelCoordinatePlotApache'
  selectedOrientationIndex: number = null

  parallelChart: echarts.ECharts = null
  parallelChartOption: any = null
  tooltipDataId: string = `tooltipData`
  minArray: any = []
  maxArray: any = []

  @Watch('scrollId')
  scrolled() {
    this.hideTooltip()
  }

  @Watch('orientations')
  orientationChanged() {
    this.drawApacheECharts()
  }

  mounted() {
    this.drawApacheECharts()
  }

  beforeDestroy() {
    this.removeTooltip()
  }

  drawApacheECharts() {
    if (this.lastSavedOrientationIndex != null && this.importedOrientationIndex != null) {
      const chartDom = document.getElementById('parallelCoordinatePlotApache')
      if (this.parallelChart) {
        this.parallelChart.dispose()
      }
      this.parallelChart = echarts.init(chartDom, null, { width: 245, height: 390 })
      // call parallelAxis method after calling getParallelSeriesData method
      const parallel = this.getParallel()
      const series = this.getParallelSeriesData()
      const parallelAxis = this.getParallelAxis()

      this.parallelChartOption = {
        parallelAxis,
        parallel,
        series,
        tooltip: {
          backgroundColor: '#f5f5f5',
          borderColor: '#cccccc',
          borderWidth: 1,
          formatter: (params) => {
            if (params && params.data && Array.isArray(params.data.value) && params.data.orientationIndex >= 0) {
              const supportAreaString = this.$t('orientChart.tooltip.supportArea')
              const supportVolumeString = this.$t('orientChart.tooltip.supportVolume')
              const buildHeightString = this.$t('orientChart.tooltip.buildHeight')
              const partFootprintsString = this.$t('orientChart.tooltip.partFootprint')
              const orientTypeString = `${this.$t('orientChart.tooltip.orientType')}`
              const prohibitPercentage = `${this.$t('orientChart.tooltip.prohibitPercentage')}`
              const preferredPercentage = `${this.$t('orientChart.tooltip.PreferredPercentage')}`
              const xAxisRotation = `${this.$t('orientChart.tooltip.xAxisRotation')}`
              const yAxisRotation = `${this.$t('orientChart.tooltip.yAxisRotation')}`
              const zAxisRotation = `${this.$t('orientChart.tooltip.zAxisRotation')}`

              const index = params.data.orientationIndex
              const supportArea = roundToFloatLimit(this.orientations[index].supportArea, FLOAT_LIMIT)
              const supportVolume = roundToFloatLimit(this.orientations[index].supportVolume, FLOAT_LIMIT)
              const buildHeight = roundToFloatLimit(this.orientations[index].buildHeight, FLOAT_LIMIT)
              const partFootprints = roundToFloatLimit(this.orientations[index].partProjectedArea, FLOAT_LIMIT)
              const rotXStr: string = `${this.orientations[index].x} deg`
              const rotYStr: string = `${this.orientations[index].y} deg`
              const rotZStr: string = `${this.orientations[index].z} deg`
              const orientType: string = this.getOrientType(index)
              const prohibitPercentageValue: string = `${this.orientations[index].prohibitedPercentage} %`
              const preferredPercentageValue: string = `${this.orientations[index].preferredPercentage} %`

              const getHeaderDiv = (heading) => {
                const div = document.createElement('div')
                div.innerText = heading
                div.style.cssText = `text-transform: capitalize;letter-spacing: 0.13px
              !important;line-height: 14px !important;font-size: 12px !important;`
                return div
              }

              const getDataDiv = (data) => {
                const div = document.createElement('div')
                div.innerText = data
                div.style.cssText = `color: #51627b !important;letter-spacing: 0.13px
              !important;line-height: 14px !important;font-size: 12px !important;`
                return div
              }

              const getListItem = (heading, data) => {
                const headerDiv = getHeaderDiv(heading)
                const dataDiv = getDataDiv(data)
                const li = document.createElement('li')
                li.appendChild(headerDiv)
                li.appendChild(dataDiv)
                li.style.cssText = `padding-bottom: 10px;`
                return li
              }

              const getList = () => {
                const ul = document.createElement('ul')
                ul.setAttribute(`id`, `${this.tooltipDataId}`)
                const saListItem = getListItem(`${supportAreaString}`, `${supportArea} mm²`)
                const svListItem = getListItem(`${supportVolumeString}`, `${supportVolume} mm³`)
                const bhListItem = getListItem(`${buildHeightString}`, `${buildHeight} mm`)
                const pfListItem = getListItem(`${partFootprintsString}`, `${partFootprints} mm²`)
                const orientTypeItem = getListItem(`${orientTypeString}`, orientType)
                const prohibitedPercentItem = getListItem(`${prohibitPercentage}`, prohibitPercentageValue)
                const preferredPercentItem = getListItem(`${preferredPercentage}`, `${preferredPercentageValue}`)
                const xAxisRotationItem = getListItem(`${xAxisRotation}`, `${rotXStr}`)
                const yAxisRotationItem = getListItem(`${yAxisRotation}`, `${rotYStr}`)
                const zAxisRotationItem = getListItem(`${zAxisRotation}`, `${rotZStr}`)

                if (orientType) {
                  ul.appendChild(orientTypeItem)
                }
                if (this.isProhibitedSurfaceSelected) {
                  ul.appendChild(prohibitedPercentItem)
                }
                if (this.isPreferredSurfaceSelected) {
                  ul.appendChild(preferredPercentItem)
                }
                ul.appendChild(pfListItem)
                ul.appendChild(svListItem)
                ul.appendChild(saListItem)
                ul.appendChild(bhListItem)
                ul.appendChild(xAxisRotationItem)
                ul.appendChild(yAxisRotationItem)
                ul.appendChild(zAxisRotationItem)

                ul.style.cssText = `list-style: none;padding-left: 8px;`
                return ul
              }

              return getList()
            }
          },
          position: (pos, params, dom, rect, size) => {
            const position = {
              top: 30,
              left: 271,
            }
            return position
          },
          appendToBody: true,
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          data: [],
          itemGap: 10,
          textStyle: {
            fontSize: 12,
            fontFamily: 'GE Inspira Sans',
          },
          left: 50,
        },
        animation: false,
      }

      this.updateParallelChart(this.parallelChartOption)
      this.addEvents()
      this.glowImportedOrLastSavedLines()
    }
  }

  glowImportedOrLastSavedLines() {
    if (this.isImportedAndLastSavedAreSameAngle(this.lastSavedOrientationIndex, this.importedOrientationIndex)) {
      this.selectLineForLastSavedAndImported(this.importedOrientationIndex)
    } else {
      this.selectLineForLastSavedAndImported(this.lastSavedOrientationIndex)
    }
  }

  public getIndex(x: number, y: number) {
    const xAxis = Math.floor(x / 5)
    const yAxis = Math.floor(y / 5)
    return xAxis + yAxis * 73
  }

  public getAngleX(index: number) {
    const x = (index % 73) * 5
    return x
  }

  public getAngleY(index: number) {
    const y = (Math.floor(index / 73) % 73) * 5
    return y
  }

  getParallelAxis() {
    // ensure interval is assigned to each object before min and max property
    return [
      {
        dim: 0,
        name: 'Part\nFootprint',
        axisTick: {
          interval: 0,
          inside: false,
          alignWithLabel: true,
        },
        axisLabel: {
          show: true,
          fontFamily: 'GE Inspira Sans',
          fontSize: 12,
        },
        areaSelectStyle: {
          width: 5,
        },
        type: 'value',
        nameTextStyle: {
          align: 'left',
          padding: [0, 40, 0, 0],
          fontFamily: 'GE Inspira Sans',
        },
        interval: this.computeInterval(0, 5),
        min: this.minArray[0],
        max: this.maxArray[0],
      },
      {
        dim: 1,
        name: 'Support\nVolume',
        nameTextStyle: {
          align: 'left',
          fontFamily: 'GE Inspira Sans',
        },
        axisLabel: {
          show: true,
          fontFamily: 'GE Inspira Sans',
          fontSize: 12,
        },
        areaSelectStyle: {
          width: 5,
        },
        interval: this.computeInterval(1, 5),
        min: this.minArray[1],
        max: this.maxArray[1],
      },
      {
        dim: 2,
        name: 'Support\nArea',
        nameTextStyle: {
          align: 'left',
          fontFamily: 'GE Inspira Sans',
        },
        axisLabel: {
          show: true,
          fontFamily: 'GE Inspira Sans',
          fontSize: 12,
        },
        areaSelectStyle: {
          width: 5,
        },
        interval: this.computeInterval(2, 5),
        min: this.minArray[2],
        max: this.maxArray[2],
      },
      {
        dim: 3,
        name: 'Build\nHeight',
        nameTextStyle: {
          align: 'left',
          fontFamily: 'GE Inspira Sans',
        },
        axisLabel: {
          show: true,
          fontFamily: 'GE Inspira Sans',
          fontSize: 12,
        },
        areaSelectStyle: {
          width: 5,
        },
        interval: this.computeInterval(3, 5),
        min: this.minArray[3],
        max: this.maxArray[3],
      },
    ]
  }

  getParallel() {
    return {
      left: '8%',
      top: '10%',
      right: '18%',
      bottom: '1%',
      parallelAxisDefault: {
        axisTick: {
          alignWithLabel: true,
        },
      },
      height: 280,
    }
  }

  /**
   * calculates and fills minArray and maxArray
   * iterates through orientArray dataset and computes min, max and for each dimension
   * @param orientArray array consisting of partProjectedArea, supportVolume, supportArea and buildHeight
   */
  assignMinMax(orientArray) {
    if (this.minArray.length !== 0 && this.maxArray.length !== 0) {
      // Case when minArray and maxArray consists of elements
      for (let i = 0; i < orientArray.length; i = i + 1) {
        this.minArray[i] = this.minArray[i] < orientArray[i] ? this.minArray[i] : orientArray[i]
        this.maxArray[i] = this.maxArray[i] > orientArray[i] ? this.maxArray[i] : orientArray[i]
      }
    } else {
      // Case when minArray and maxArray is empty
      // instaantiating both the arrays with same values
      for (const element of orientArray) {
        this.minArray.push(element)
        this.maxArray.push(element)
      }
    }
  }

  /**
   * Calculates a number closest to the number based on type
   * if type is min, then the nearest number calculated is less than or equal to it
   * if type is max, then the nearest number calculated is more than or equal to it
   * ex for min:- 17300 -> 17000, 2940 -> 2900, 49-> 40
   * ex for max:- 17350 -> 17400,  2940-> 3000, 49 -> 50
   * All computation are done by approximating from the middle of the the number to the end
   * @param number number to be approximated.
   * @param type string which takes the value min or max.
   * @return aprroximate min or max value based on the selection of type.
   */
  getNearestNumber(number, type) {
    const divisionFactor = 10 ** Math.floor(number.toString().length / 2)
    if (type === 'min') {
      return Math.floor(number / divisionFactor) * divisionFactor
    }
    if (type === 'max') {
      return Math.ceil(number / divisionFactor) * divisionFactor
    }
  }

  /**
   * Computes Interval for the required split in the axis
   * @param index index/dim of the paralled axis.
   * @param splitNumber required split/interval in the parallel axis.
   * @return interval value computed based on min, max and number of splits.
   */
  computeInterval(index, splitNumber) {
    let approximateInterval = 0
    if (this.minArray[index] !== this.maxArray[index]) {
      // condition when there is more than one dimension
      // where min and max would differ based on multiple parallel lines
      const min = this.getNearestNumber(Math.floor(this.minArray[index]), 'min')
      this.minArray[index] = min
      const interval = (this.maxArray[index] - min) / splitNumber
      // approximating the interval to a value higher than itself
      // done to prevent the computed max value to not go below the real max value
      approximateInterval = this.getNearestNumber(Math.ceil(interval), 'max')
      this.maxArray[index] = approximateInterval * splitNumber + min
    } else {
      // condition when there is only one dimension with one dataset
      // where min and max would be same
      // ensuring that the scale has 5 units in parallel axis
      // interval less than 5 makes the graph to have only one division in the axis
      this.minArray[index] = Math.floor(this.minArray[index] / 5) * 5
      const interval = (this.maxArray[index] - this.minArray[index]) / splitNumber
      // approximating the interval to a value higher than itself
      // done to prevent the computed max value to not go below the real max value
      approximateInterval = this.getNearestNumber(Math.ceil(interval), 'max')
      this.maxArray[index] = approximateInterval * splitNumber + this.minArray[index]
    }
    return approximateInterval
  }

  getParallelSeriesData() {
    let selectableOrientations: OrientSeriesItem
    let importedOrientations: OrientSeriesItem
    let lastSavedOrientations: OrientSeriesItem
    const parallelSeries = []

    if (this.isHaveGraphSolution) {
      this.orientations.forEach((orientation, index) => {
        if (this.isOrientCanRender(orientation, index)) {
          const supportArea = orientation.supportArea
          const supportVolume = orientation.supportVolume
          const buildHeight = orientation.buildHeight
          const partProjectedArea = orientation.partProjectedArea

          this.assignMinMax([partProjectedArea, supportVolume, supportArea, buildHeight])

          const seriesDataItem: OrientSeriesDataItem = this.getParallelSeriesDataItem(
            supportArea,
            supportVolume,
            buildHeight,
            partProjectedArea,
            OrientGraphLineTypes.Selectable,
            index,
          )

          selectableOrientations = {
            type: 'parallel',
            data: [seriesDataItem],
            lineStyle: this.getParallelSeriesLineStyle(OrientGraphLineTypes.Selectable, index),
            name: this.getParallelSeriesName(OrientGraphLineTypes.Selectable),
            emphasis: {
              disabled: true,
            },
            inactiveOpacity: 0.3,
          }
          parallelSeries.push(selectableOrientations)
        }
      })
    }

    if (this.importedOrientationIndex >= 0) {
      const supportArea = this.orientations[this.importedOrientationIndex].supportArea
      const supportVolume = this.orientations[this.importedOrientationIndex].supportVolume
      const buildHeight = this.orientations[this.importedOrientationIndex].buildHeight
      const partProjectedArea = this.orientations[this.importedOrientationIndex].partProjectedArea

      this.assignMinMax([partProjectedArea, supportVolume, supportArea, buildHeight])

      const seriesDataItem: OrientSeriesDataItem = this.getParallelSeriesDataItem(
        supportArea,
        supportVolume,
        buildHeight,
        partProjectedArea,
        OrientGraphLineTypes.Imported,
        this.importedOrientationIndex,
      )

      importedOrientations = {
        type: 'parallel',
        data: [seriesDataItem],
        lineStyle: this.getParallelSeriesLineStyle(OrientGraphLineTypes.Imported, this.importedOrientationIndex),
        name: this.getParallelSeriesName(OrientGraphLineTypes.Imported),
        emphasis: {
          disabled: true,
        },
        inactiveOpacity: 0.3,
      }
      parallelSeries.push(importedOrientations)
    }

    if (
      this.lastSavedOrientationIndex > 0 &&
      !this.isImportedAndLastSavedAreSameAngle(this.importedOrientationIndex, this.lastSavedOrientationIndex)
    ) {
      const supportArea = this.orientations[this.lastSavedOrientationIndex].supportArea
      const supportVolume = this.orientations[this.lastSavedOrientationIndex].supportVolume
      const buildHeight = this.orientations[this.lastSavedOrientationIndex].buildHeight
      const partProjectedArea = this.orientations[this.lastSavedOrientationIndex].partProjectedArea

      this.assignMinMax([partProjectedArea, supportVolume, supportArea, buildHeight])

      const seriesDataItem: OrientSeriesDataItem = this.getParallelSeriesDataItem(
        supportArea,
        supportVolume,
        buildHeight,
        partProjectedArea,
        OrientGraphLineTypes.LastSaved,
        this.lastSavedOrientationIndex,
      )

      lastSavedOrientations = {
        type: 'parallel',
        data: [seriesDataItem],
        lineStyle: this.getParallelSeriesLineStyle(OrientGraphLineTypes.LastSaved, this.lastSavedOrientationIndex),
        name: this.getParallelSeriesName(OrientGraphLineTypes.LastSaved),
        emphasis: {
          disabled: true,
        },
        inactiveOpacity: 0.3,
      }

      parallelSeries.push(lastSavedOrientations)
    }

    return parallelSeries
  }

  getParallelSeriesName(lineType: OrientGraphLineTypes) {
    let seriesName
    switch (lineType) {
      case OrientGraphLineTypes.Selectable:
        seriesName = `${OrientGraphLineTypes.Selectable} Orientations`
        break
      case OrientGraphLineTypes.Selected:
        seriesName = `${OrientGraphLineTypes.Selected} Orientation`
        break
      case OrientGraphLineTypes.Imported:
        seriesName = `${OrientGraphLineTypes.Imported}`
        break
      case OrientGraphLineTypes.LastSaved:
        seriesName = `${OrientGraphLineTypes.LastSaved}`
        break
    }
    return seriesName
  }

  getParallelSeriesLineStyle(lineType: OrientGraphLineTypes, indexNumber: number = 0) {
    let lineStyle
    switch (lineType) {
      case OrientGraphLineTypes.Selectable:
        lineStyle = {
          color: SELECTED_RANGE_COLOR,
          width: 1.5,
          opacity: 1,
          type: this.getEChartPlotType(indexNumber),
        }
        break
      case OrientGraphLineTypes.Selected:
        lineStyle = {
          width: 1.5,
          opacity: 1,
          shadowBlur: 5,
          color: this.getColorForSelectedLine(indexNumber),
          type: this.getEChartPlotType(indexNumber),
        }
        break
      case OrientGraphLineTypes.Imported:
        lineStyle = {
          color: IMPORTED_COLOR,
          width: 1.5,
          opacity: 1,
          type: this.getEChartPlotType(indexNumber),
        }
        break
      case OrientGraphLineTypes.LastSaved:
        lineStyle = {
          color: LAST_SAVED_COLOR,
          width: 1.5,
          opacity: 1,
          type: this.getEChartPlotType(indexNumber),
        }
        break
    }
    return lineStyle
  }

  getParallelSeriesDataItem(
    supportArea: number,
    supportVolume: number,
    buildHeight: number,
    partProjectedArea: number,
    lineType: OrientGraphLineTypes,
    orientationIndex: number,
  ): OrientSeriesDataItem {
    const value = [partProjectedArea, supportVolume, supportArea, buildHeight]
    const lineStyle = this.getParallelSeriesDataLineStyle(lineType)
    const seriesDataItem: OrientSeriesDataItem = {
      value,
      lineStyle,
      orientationIndex,
    }
    return seriesDataItem
  }

  getParallelSeriesDataLineStyle(lineType: OrientGraphLineTypes) {
    let lineStyle
    switch (lineType) {
      case OrientGraphLineTypes.Selectable:
        lineStyle = {}
        break
      case OrientGraphLineTypes.Selected:
        lineStyle = {}
        break
      case OrientGraphLineTypes.Imported:
        lineStyle = {}
        break
      case OrientGraphLineTypes.LastSaved:
        lineStyle = {}
        break
    }
    return lineStyle
  }

  addEvents() {
    // assign mouse click events on the graph only when build plan is not freezed
    if (!this.isOrientReadOnly) {
      this.parallelChart.on('mouseover', (event) => {
        if (event && Number.isInteger(event.dataIndex) && Number.isInteger(event.componentIndex) && event.data) {
          // @ts-ignore
          const orientationIndex = event.data.orientationIndex
          if (orientationIndex >= 0) {
            this.parallelLineHovered(event)
          }
        }
      })

      this.parallelChart.on('mouseout', (event) => {
        if (event && Number.isInteger(event.dataIndex) && Number.isInteger(event.componentIndex) && event.data) {
          // @ts-ignore
          const orientationIndex = event.data.orientationIndex
          if (orientationIndex >= 0) {
            this.parallelLineUnhovered(event)
          }
        }
      })

      this.parallelChart.on('click', (event) => {
        if (event && Number.isInteger(event.dataIndex) && Number.isInteger(event.componentIndex) && event.data) {
          // @ts-ignore
          const orientationIndex = event.data.orientationIndex
          if (orientationIndex >= 0) {
            this.parallelLineClicked(event)
          }
        }
      })
    }

    // assign legend selected change event for all the cases
    // this.parallelChart.on('legendselectchanged', (params: any) => {
    //   this.minArray = []
    //   this.maxArray = []
    //   for (const [key, value] of Object.entries(params.selected)) {
    //     if (value === true) {
    //       for (const seriesData of this.parallelChartOption.series) {
    //         if (seriesData.name.replace(/  */g, '').toLowerCase() === key.replace(/  */g, '').toLowerCase()) {
    //           for (const dataElement of seriesData.data) {
    //             this.assignMinMax(dataElement.value)
    //           }
    //           break
    //         }
    //       }
    //     }
    //   }
    //   this.parallelChartOption.parallelAxis = this.getParallelAxis()
    //   this.updateParallelChart(this.parallelChartOption)
    // })
  }

  parallelLineHovered(event) {
    const seriesDataItem = this.parallelChartOption.series[event.componentIndex].data[event.dataIndex]
    seriesDataItem.lineStyle.shadowColor = HOVER_COLOR
    seriesDataItem.lineStyle.color = HOVER_COLOR
    seriesDataItem.lineStyle.shadowBlur = 5
    this.parallelChartOption.series[event.componentIndex].data[event.dataIndex] = seriesDataItem
    this.updateParallelChart(this.parallelChartOption)
    const orientationIndex =
      this.parallelChartOption.series[event.componentIndex].data[event.dataIndex].orientationIndex
    if (orientationIndex > 0) {
      this.rotatePart(orientationIndex, true)
    }
  }

  parallelLineUnhovered(event) {
    delete this.parallelChartOption.series[event.componentIndex].data[event.dataIndex].lineStyle.width
    delete this.parallelChartOption.series[event.componentIndex].data[event.dataIndex].lineStyle.shadowBlur
    delete this.parallelChartOption.series[event.componentIndex].data[event.dataIndex].lineStyle.shadowColor
    const seriesDataItem = this.parallelChartOption.series[event.componentIndex].data[event.dataIndex]
    seriesDataItem.lineStyle.color = this.parallelChartOption.series[event.componentIndex].lineStyle.color
    this.updateParallelChart(this.parallelChartOption)

    if (this.selectedOrientationIndex && this.selectedOrientationIndex >= 0) {
      this.rotatePart(this.selectedOrientationIndex, false)
    } else if (this.lastSavedOrientationIndex > 0) {
      this.rotatePart(this.lastSavedOrientationIndex, true)
    } else if (this.importedOrientationIndex >= 0) {
      this.rotatePart(this.importedOrientationIndex, true)
    }
  }

  parallelLineScrolled() {
    if (this.selectedOrientationIndex && this.selectedOrientationIndex >= 0) {
      this.rotatePart(this.selectedOrientationIndex, false)
    } else if (this.lastSavedOrientationIndex > 0) {
      this.rotatePart(this.lastSavedOrientationIndex, true)
    } else if (this.importedOrientationIndex >= 0) {
      this.rotatePart(this.importedOrientationIndex, true)
    }
  }

  selectLineForLastSavedAndImported(orientIndex: number) {
    let componentIndex: number
    this.parallelChartOption.series.forEach((series, index) => {
      if (series.data[0].orientationIndex === orientIndex) {
        componentIndex = index
        return
      }
    })
    this.selectParallelLine(componentIndex, 0)
  }

  parallelLineClicked(event) {
    this.selectParallelLine(event.componentIndex, event.dataIndex)
  }

  selectParallelLine(componentIndex: number, dataIndex: number) {
    this.selectedOrientationIndex = this.parallelChartOption.series[componentIndex].data[dataIndex].orientationIndex
    const supportArea = this.orientations[this.selectedOrientationIndex].supportArea
    const supportVolume = this.orientations[this.selectedOrientationIndex].supportVolume
    const buildHeight = this.orientations[this.selectedOrientationIndex].buildHeight
    const partProjectedArea = this.orientations[this.selectedOrientationIndex].partProjectedArea

    const seriesDataItem: OrientSeriesDataItem = this.getParallelSeriesDataItem(
      supportArea,
      supportVolume,
      buildHeight,
      partProjectedArea,
      OrientGraphLineTypes.Selected,
      this.selectedOrientationIndex,
    )

    const selectedOrientation: OrientSeriesItem = {
      type: 'parallel',
      data: [seriesDataItem],
      lineStyle: this.getParallelSeriesLineStyle(OrientGraphLineTypes.Selected, this.selectedOrientationIndex),
      name: this.getParallelSeriesName(OrientGraphLineTypes.Selected),
      emphasis: {
        disabled: true,
      },
      inactiveOpacity: 0.3,
    }

    const series = this.parallelChartOption.series as any[]
    const selectedSeriesIndex = series.findIndex((seriesItem) => {
      if (seriesItem && seriesItem.name === this.getParallelSeriesName(OrientGraphLineTypes.Selected)) {
        return true
      }
      return false
    })
    if (selectedSeriesIndex >= 0) {
      series.splice(selectedSeriesIndex, 1)
    }
    series.push(selectedOrientation)
    this.parallelChartOption.series = series
    this.updateParallelChart(this.parallelChartOption)
    this.rotatePart(this.selectedOrientationIndex, false)
  }

  updateParallelChart(parallelChartOption) {
    parallelChartOption.legend = this.getParallelLegend(parallelChartOption)
    this.parallelChart.setOption(parallelChartOption)
  }

  getParallelLegend(parallelChartOption) {
    const parallelLegendData = this.getParallelLegendData(parallelChartOption.series)
    const itemGap = LEGEND_ITEM_GAP
    let bottom = LEGEND_BOTTOM
    if (Array.isArray(parallelLegendData)) {
      bottom = bottom - parallelLegendData.length * itemGap
    }
    const legend = {
      bottom,
      itemGap,
      type: 'scroll',
      orient: 'vertical',
      selectedMode: false,
      data: [...parallelLegendData],
      textStyle: {
        fontSize: 12,
        fontFamily: 'GE Inspira Sans',
      },
      left: '5%',
      borderRadius: 5,
      itemHeight: 10,
    }
    return legend
  }

  getParallelLegendData(parallelSeries) {
    const legendData = []
    if (parallelSeries && Array.isArray(parallelSeries)) {
      parallelSeries.forEach((series) => {
        if (series.name === OrientGraphLineTypes.Imported || series.name === OrientGraphLineTypes.LastSaved) {
          legendData.push(series.name)
        }
      })
    }
    return legendData
  }

  hideTooltip() {
    const ul = document.getElementById(`${this.tooltipDataId}`)
    if (ul && ul.parentElement) {
      ul.parentElement.style.visibility = 'hidden'
      this.parallelLineScrolled()
    }
  }

  removeTooltip() {
    const ul = document.getElementById(`${this.tooltipDataId}`)
    if (ul && ul.parentElement) {
      ul.parentElement.remove()
    }
  }

  getOrientType(selectedOrientIndex: number) {
    if (selectedOrientIndex === this.importedOrientationIndex) {
      return 'Imported orientation'
    }

    if (selectedOrientIndex === this.lastSavedOrientationIndex) {
      return 'Last Saved Orientation'
    }

    return ''
  }

  getEChartPlotType(orientIndex: number) {
    const orientation = this.orientations[orientIndex]

    if (orientation.prohibitedPercentage === 100 && orientation.preferredPercentage < 100) {
      return EChartPlotTypes.dotted
    }

    if (orientIndex === this.importedOrientationIndex || orientIndex === this.lastSavedOrientationIndex) {
      if (orientation.prohibitedPercentage < 100) {
        return EChartPlotTypes.dashed
      }
    }

    return EChartPlotTypes.line
  }

  getColorForSelectedLine(index: number) {
    if (index === this.importedOrientationIndex) {
      return IMPORTED_COLOR
    }

    if (index === this.lastSavedOrientationIndex) {
      return LAST_SAVED_COLOR
    }

    return SELECTED_RANGE_COLOR
  }

  isImportedAndLastSavedAreSameAngle(importedOrientIndex: number, lastSavedOrientationIndex: number) {
    const importedOrient = this.orientations[importedOrientIndex]
    const lastSavedOrient = this.orientations[lastSavedOrientationIndex]
    if (
      importedOrient.x === lastSavedOrient.x &&
      importedOrient.y === lastSavedOrient.y &&
      importedOrient.y === lastSavedOrient.y
    ) {
      return true
    }
    return false
  }

  isOrientCanRender(orient: OrientResult, index: number) {
    if (index === this.importedOrientationIndex || index === this.lastSavedOrientationIndex) {
      return false
    }

    if (orient.prohibitedPercentage < 100) {
      return false
    }

    if (
      orient.buildHeight === this.orientations[this.importedOrientationIndex].buildHeight &&
      orient.partProjectedArea === this.orientations[this.importedOrientationIndex].partProjectedArea &&
      orient.supportArea === this.orientations[this.importedOrientationIndex].supportArea &&
      orient.supportVolume === this.orientations[this.importedOrientationIndex].supportVolume
    ) {
      return false
    }

    if (
      orient.buildHeight === this.orientations[this.lastSavedOrientationIndex].buildHeight &&
      orient.partProjectedArea === this.orientations[this.lastSavedOrientationIndex].partProjectedArea &&
      orient.supportArea === this.orientations[this.lastSavedOrientationIndex].supportArea &&
      orient.supportVolume === this.orientations[this.lastSavedOrientationIndex].supportVolume
    ) {
      return false
    }

    return true
  }
}
