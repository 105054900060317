import { JobType } from './Job'
import { IFolder } from '@/types/Folders/Folders'
import { ItemSubType } from '@/types//FileExplorer/ItemType'
import { GeometryType } from '../BuildPlans/IBuildPlan'

export interface IPartDto {
  id: string
  scaleX: number
  scaleY: number
  scaleZ: number
  name: string
  createdBy: string
  createdDate: Date
  createdAt: string
  updatedBy: string
  updatedAt: string
  jobCount: number
  parentId: string
  jobType: JobType
  status: string
  path: string
  isRemoved: boolean
  itemType: number
  cadFileType: CadFileTypes
  parentFolderNames: string[]
  sinterPlanName?: string
  sinterPlanVersion?: string
  sinterPlanVersionLabel?: string
  printStrategyParameterSetId?: number
  printStrategyParameterSetVersion?: number
  subType: ItemSubType
  hasErrors: boolean
  hasSheetBodies: boolean
  metadata: object
  hiddenBodies: string[]
  originalName: string[]
  version: string
  versionLabel: string
  previewImageUrl: string
  visibility: boolean
  components: string[]
  nominalPartId?: string
  ibcPlanName?: string
  ibcPlanVersion?: string
  ibcPlanVersionLabel?: string
  isPublishedAsScaled: boolean
  originalPlanId?: string
  bodyFunction?: GeometryType
}

export interface IContentFolder {
  folder: IFolder
  parts: IPartDto[]
}

export interface IUploadedPartParams {
  name: string
  file: File
}

export interface ICreatePartDto {
  name: string
  parentId?: string
  scaleX: number
  scaleY: number
  scaleZ: number
  printStrategyParameterSetId?: number
  printStrategyParameterSetVersion?: number
}

export enum CadFileTypes {
  Brep,
  Mesh,
  BrepMesh,
}
