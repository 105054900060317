import { render, staticRenderFns } from "./SliceSettingsRow.vue?vue&type=template&id=67c5d5d6&scoped=true"
import script from "./SliceSettingsRow.vue?vue&type=script&lang=ts"
export * from "./SliceSettingsRow.vue?vue&type=script&lang=ts"
import style0 from "./SliceSettingsRow.vue?vue&type=style&index=0&id=67c5d5d6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67c5d5d6",
  null
  
)

export default component.exports