export enum Unit {
  Millimeter,
  MegaPascal,
  AngleDegree,
  CelsiusDegree,
  Percent,
  Strain,
  Undefined,
}

export function getUnitTolerance(unit: Unit): number {
  switch (unit) {
    case Unit.CelsiusDegree:
      return -1
    case Unit.MegaPascal:
      return 0
    case Unit.AngleDegree:
    case Unit.Percent:
    case Unit.Millimeter:
    case Unit.Strain:
      return -3
    case Unit.Undefined:
    default:
      return -2
  }
}

export function getUnitLabel(unit: Unit): string {
  switch (unit) {
    case Unit.CelsiusDegree:
      return 'C'
    case Unit.MegaPascal:
      return 'MPa'
    case Unit.AngleDegree:
      return '°'
    case Unit.Percent:
      return '%'
    case Unit.Millimeter:
      return 'mm'
    case Unit.Strain:
      return ''
    case Unit.Undefined:
    default:
      return ''
  }
}
