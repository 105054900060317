
import Button from '@/components/controls/Common/Button.vue'
import StoresNamespaces from '@/store/namespaces'
import { MachineMaterial, MachineTypeDto } from '@/types/Sites/Site'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component({ components: { Button } })
export default class PartParameterDuplicateErrorModal extends Vue {
  @sitesStore.Getter getSelectedMachineType: MachineTypeDto
  @sitesStore.Getter getSelectedMaterial: MachineMaterial

  @Prop() cancelUpload!: () => void
  @Prop() uploadDifferentFile!: () => void
  @Prop() useExistingParameter!: () => void

  @Prop({ required: true }) isOpen: boolean

  get machineType() {
    return this.getSelectedMachineType.name
  }

  get material() {
    return this.getSelectedMaterial.name
  }
}
