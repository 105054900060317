export interface IFileDetails {
  originalName: string
  name: string
  fileType: string
  units: string
  printQuality: string
  parameterSetName: string
  s3filename: string
  uploadedDate: string
  volumeUnits: string
  volume: string
  surfaceArea: string
  xDimension: string
  yDimension: string
  zDimension: string
  version: number
  key: string
  tag: string
}

export enum IFileTags {
  Slice = 'slice',
  Vis = 'vis',
  Parasolid = 'parasolid',
  Sim = 'sim',
  Oriend = 'orient',
  ADW_Output_Images = 'ADW_Output/Images',
  ADV_Output_Compensated_Files = 'ADW_Output/Compensated_Files',
  Bvh = 'bvh',
  Hull = 'hull',
}
