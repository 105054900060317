import { STEP_NAME_PATTERN } from '@/constants'

const COMPENSATION = 'Compensation_Scales'
const SIMULATUION_STEPS = 'Steps'
const MIN = 'Min'
const MAX = 'Max'
const DEFAULT_INDEX = '0'
const DISPLACEMENT_COMPONENTS = 'Displacement Components'
const DISPLACEMENT_MAGNITUDE = 'Displacement Magnitude'
const DISPLACEMENT = 'Displacement'

export class ScaleRange {
  min: Map<string, number>
  max: Map<string, number>

  constructor() {
    this.max = new Map<string, number>()
    this.min = new Map<string, number>()
  }

  setMinValue(key: string, value: number) {
    const current = this.min.has(key) ? this.min.get(key) : value
    this.min.set(key, Math.min(value, current))
  }

  setMaxValue(key: string, value: number) {
    const current = this.max.has(key) ? this.max.get(key) : value
    this.max.set(key, Math.max(current, value))
  }
}

export function getSimulationScales(rawData): Map<string, Map<string, ScaleRange>> {
  const result = new Map<string, Map<string, ScaleRange>>()
  if (!rawData[SIMULATUION_STEPS]) return result

  const stepsScales = Array.isArray(rawData[SIMULATUION_STEPS])
    ? rawData[SIMULATUION_STEPS][0]
    : rawData[SIMULATUION_STEPS]

  Object.keys(stepsScales)
    .filter((s) => STEP_NAME_PATTERN.test(s))
    .forEach((k) => {
      const reducedScale = stepsScales[k].reduce((topAcc, value) => {
        value.forEach((field) => {
          Object.keys(field).forEach((fieldKey) => {
            if (!topAcc.has(fieldKey)) topAcc.set(fieldKey, new ScaleRange())

            if (Array.isArray(field[fieldKey][MIN])) {
              field[fieldKey][MIN].forEach((cv, ci) => {
                topAcc.get(fieldKey).setMinValue(String(ci), cv)
              })

              field[fieldKey][MAX].forEach((cv, ci) => {
                topAcc.get(fieldKey).setMaxValue(String(ci), cv)
              })
            } else {
              topAcc.get(fieldKey).setMinValue(DEFAULT_INDEX, field[fieldKey][MIN])
              topAcc.get(fieldKey).setMaxValue(DEFAULT_INDEX, field[fieldKey][MAX])
            }
          })
        })

        return topAcc
      }, new Map<string, ScaleRange>())

      result.set(k, reducedScale)
    })

  return result
}

export function getCompensationScales(rawData): Map<string, ScaleRange> {
  const result = new Map<string, ScaleRange>()
  if (!rawData[COMPENSATION]) return result

  const compensationScale = rawData[COMPENSATION]
  const displacementMagnitude = compensationScale[DISPLACEMENT_MAGNITUDE]
  const displacementComponents = compensationScale[DISPLACEMENT_COMPONENTS]
  if (displacementComponents && displacementMagnitude) {
    delete compensationScale[DISPLACEMENT_MAGNITUDE]
    delete compensationScale[DISPLACEMENT_COMPONENTS]
    displacementComponents.Min.push(displacementMagnitude.Min)
    displacementComponents.Max.push(displacementMagnitude.Max)
    compensationScale[DISPLACEMENT] = displacementComponents
  }

  Object.keys(compensationScale).forEach((k) => {
    result.set(k, new ScaleRange())
    if (Array.isArray(compensationScale[k][MIN])) {
      compensationScale[k][MIN].forEach((cv, ci) => {
        if (cv != null) {
          result.get(k).min.set(String(ci), cv)
        }
      })

      compensationScale[k][MAX].forEach((cv, ci) => {
        if (cv != null) {
          result.get(k).max.set(String(ci), cv)
        }
      })
    } else {
      if (compensationScale[k][MIN] != null) {
        result.get(k).min.set(DEFAULT_INDEX, compensationScale[k][MIN])
        result.get(k).max.set(DEFAULT_INDEX, compensationScale[k][MAX])
      }
    }
  })

  return result
}
