import { del, get, post, put } from '@/api/http'
import { handleAPIError } from '@/api/common'
import { EndpointsUrls } from '@/configs/config'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { LabelSetDto } from '@/types/Label/LabelSetDto'

export default {
  async getLabelSetsByBuildPlanId(buildPlanId: string): Promise<LabelSetDto[]> {
    try {
      return await get<LabelSetDto[]>(`${EndpointsUrls.LabelSet}/${buildPlanId}`)
    } catch (error) {
      handleAPIError(error)
      return []
    }
  },

  async createLabelSets(labelSets: LabelSetDto[]) {
    try {
      return await post<LabelSetDto[]>(`${EndpointsUrls.LabelSet}`, { labelSets })
    } catch (error) {
      handleAPIError(error)
      return false
    }
  },

  async deleteLabelSet(labelSetId: string) {
    try {
      return await del<number>(`${EndpointsUrls.LabelSet}/${labelSetId}`)
    } catch (error) {
      handleAPIError(error)
      return false
    }
  },

  async deleteLabelSets(labelSetIds: string[]) {
    try {
      return await del<number>(`${EndpointsUrls.LabelSet}`, { params: { labelSetIds: labelSetIds.join() } })
    } catch (error) {
      handleAPIError(error)
      return false
    }
  },

  async updateLabelSet(labelSetId: string, labelSet: LabelSetDto) {
    try {
      return await put<LabelSetDto>(`${EndpointsUrls.LabelSet}/${labelSetId}`, { labelSet })
    } catch (error) {
      handleAPIError(error)
      return false
    }
  },

  async updateLabelSets(labelSets: LabelSetDto[]) {
    try {
      return await put<LabelSetDto[]>(`${EndpointsUrls.LabelSet}`, { labelSets })
    } catch (error) {
      handleAPIError(error)
      return false
    }
  },

  async deleteLabelSetPatch(labelSetId: string, patchId: string) {
    try {
      return await del<LabelSetDto>(`${EndpointsUrls.LabelSet}/${labelSetId}/patch/${patchId}`)
    } catch (error) {
      handleAPIError(error)
      return false
    }
  },

  async createLabelSetPatch(labelSetId: string, patches: any) {
    try {
      return await post<LabelSetDto>(`${EndpointsUrls.LabelSet}/${labelSetId}/patch`, { patches })
    } catch (error) {
      handleAPIError(error)
      return false
    }
  },
}
