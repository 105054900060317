
import { extend, ValidationObserver } from 'vee-validate'
import { integer, max, max_value, min_value, required } from 'vee-validate/dist/rules'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import costEstimationAPI from '@/api/costEstimation'
import Button from '@/components/controls/Common/Button.vue'
import NumberField from '@/components/controls/Common/NumberField.vue'
import Selector from '@/components/controls/Common/Selector.vue'
import TextField from '@/components/controls/Common/TextField.vue'
import CadHelperRunner from '@/components/layout/CadHelperRunner.vue'
import DownloadCadHelperModal from '@/components/layout/DownloadCadHelperModal.vue'
import FallbackImage from '@/components/layout/FallbackImage.vue'
import CadHelperLinkUpdateMixin from '@/components/layout/FileExplorer/Table/mixins/CadHelperLinkUpdateMixin'
import { EndpointsUrls } from '@/configs/config'
import {
  CadHelperProcess,
  DEFAULT_COST_ESTIMATION_PROJECT_NAME,
  DEFAULT_MACHINE_BUILD_CHAMBER_UNIT,
  ENVELOPE_X_Y_MODIFIER_FOR_LIKELY_AND_OPTIMISTIC,
  MAX_COST_ESTIMATION_SETS,
  measurementUnits,
  PRECISIONS,
} from '@/constants'
import { RouterNames, RouterPaths } from '@/router'
import CommunicationService from '@/services/CommunicationService'
import messageService from '@/services/messageService'
import StoresNamespaces from '@/store/namespaces'
import { IParameterSet } from '@/types/BuildPlans/IBuildPlan'
import { CadHelperLink, CadHelperLinkParameters } from '@/types/CadHelper/CadHelperLink'
import { BrokerEvents } from '@/types/Common/BrokerEvents'
import { BrokerMessage } from '@/types/Common/BrokerMessage'
import { ISocketError } from '@/types/Common/ISocketError'
import { EstimationCostInputType, ICostEstimation } from '@/types/CostEstimations/ICostEstimation'
import { EnvelopeShape, IMachineConfig, PrintingTypes } from '@/types/IMachineConfig'
import { IUnits } from '@/types/IUnits'
import { ManufacturingRegions } from '@/types/ManufacturingRegions'
import { IUser } from '@/types/User/IUser'
import round from '@/utils/arithmetic/round'
import { convert as convertLength } from '@/utils/converter/lengthConverter'
import { IMaterial } from '../../types/IMaterial'

const costEstimationStore = namespace(StoresNamespaces.CostEstimation)
const userStore = namespace(StoresNamespaces.User)
const cadHelperStore = namespace(StoresNamespaces.CadHelper)
const commonStore = namespace(StoresNamespaces.Common)

const DEFAULT_COST_ESTIMATION_ERROR_MESSAGE_ID = 'costEstimationCreateError'
const INVALID_FORM_MESSAGE_ID = 'common.form.invalid'
const NO_MACHINE_SELECTED_ERROR_MESSAGE_ID = 'costEstimationMachineError'
const SOCKET_MESSAGE_ERROR_CODE = 'socketMessageError'
const OUTSIZE_ERROR_CODE = 'Does not fit'
const UNKNOWN_MEASUREMENT_UNIT_ID = 5
const MACHINE_BUILD_CHAMBER_MIN_VALUE = 0.001
const NUMERIC_FIELD_PRECISION = 6
const PREVENT_TIMEOUT = 100

// TODO when this comes to advanced cost estimation and user could select "Base plate XY nesting estimate"
// consider that we need to use this modifier for one/conservative options
// ENVELOPE_X_Y_MODIFIER_FOR_LIKELY_AND_OPTIMISTIC

// TODO: PARTS_OFFSET = 0.08, but for better UI representation was setted to 1
// Come up with a better solution for UI representation
// Changed to 0 due to "feature/removing_offset_safely" changes on cost_estimation_core
const PARTS_OFFSET = 0
// Max PostgreSQL integer value is lower than max javascript integer value
const MAX_INTEGER = 2147483647
// Max PostgreSQL double is 15 decimal digits precision
const MAX_DECIMAL_DIGITS_PRECISION = 15
// Max PostgreSQL string value is lower than max javascript string value
const MAX_STRING_LENGTH = 255

enum EditingModes {
  Edit = 'edit',
  Create = 'create',
}

extend('required', { ...required, message: 'This field is required' })
extend('min_value', min_value)
extend('max_value', max_value)
extend('integer', integer)
extend('max', max)

interface IPrinterCard {
  printer: IMachineConfig
  disabled: boolean
}

@Component({
  components: {
    Button,
    Selector,
    NumberField,
    TextField,
    FallbackImage,
    CadHelperRunner,
    DownloadCadHelperModal,
  },

  beforeRouteLeave(to, _, next) {
    if (this.routerHookEnabled) {
      this.isShownLeaveConfirmationDialog = true
      this.routerTo = to
    } else {
      next()
    }
  },
})
export default class EditCostEstimation extends Mixins(CadHelperLinkUpdateMixin) {
  @costEstimationStore.Action fetchMaterials: any
  @costEstimationStore.Action fetchParameterSets: any
  @costEstimationStore.Action fetchMachineConfigs: any
  @costEstimationStore.Action estimateCost: any
  @costEstimationStore.Action getCostEstimationById: any
  @costEstimationStore.Action getCostEstimationsToken: any
  @costEstimationStore.Action deleteCostEstimation: any
  @costEstimationStore.Action fetchAllCostEstimations: any
  @costEstimationStore.Action updateCostEstimationImage: any

  @costEstimationStore.Getter getSelectedCostEstimation: ICostEstimation
  @costEstimationStore.Getter getMaterials: any
  @costEstimationStore.Getter getMachineConfigs: IMachineConfig[]
  @costEstimationStore.Getter getMachineConfigById: any
  @costEstimationStore.Getter getSelectedMachineConfigs: number[]
  @costEstimationStore.Getter getEnabledMachineConfigs: number[]
  @costEstimationStore.Getter getSelectedMaterial: number
  @costEstimationStore.Getter getParameterSets: IParameterSet[]

  @userStore.Getter('getUserDetails') getUser: IUser

  @cadHelperStore.Getter('getVersion') getVersionOfCadHelper: string
  @cadHelperStore.Getter('getDownloadLink') getDownloadLinkOfCadHelper: string

  @commonStore.Getter tooltipOpenDelay: number

  @costEstimationStore.Mutation setNeedToUpdateCostEstimations: any
  @costEstimationStore.Mutation setSelectedCostEstimation: (costEstimation: ICostEstimation) => void
  @costEstimationStore.Mutation toggleMachineConfigSelection: (machineConfigId: number) => void
  @costEstimationStore.Mutation selectMaterial: (material: IMaterial) => void

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>
    imageFileInput: HTMLFormElement
  }

  types = EstimationCostInputType
  manufacturingRegions = ManufacturingRegions
  isShownNameField: boolean = false
  isShownError: boolean = false
  isShownSocketError: boolean = false
  downloadCadHelperDialogVisible: boolean = false
  isShownLeaveConfirmationDialog: boolean = false
  isShownSpecifyMeasurementDialog: boolean = false
  isShownMeasurementUnitsSelector: boolean = false
  allowUnsavedChangesDialog: boolean = true
  isLoadingEstimate: boolean = false
  isWaitingForCadHelper: boolean = false
  allPrintingTypes: string = 'All'

  routerHookEnabled: boolean = true
  routerTo: Object = {}

  costEstimation: ICostEstimation = null
  costEstimationToken: string = ''
  costEstimationEditingMode: EditingModes = EditingModes.Create
  costEstimationErrorMessageId: string = DEFAULT_COST_ESTIMATION_ERROR_MESSAGE_ID

  printerCards: IPrinterCard[] = []
  image: File = null
  selectedImageURL: string = null

  connector: CommunicationService = null

  machineTechnologies: Array<{ disabled: boolean; id: string | PrintingTypes; name: string | PrintingTypes }> = [
    {
      disabled: false,
      id: this.allPrintingTypes,
      name: this.allPrintingTypes,
    },
    {
      disabled: false,
      id: PrintingTypes.BinderJet,
      name: PrintingTypes.BinderJet,
    },
    {
      disabled: false,
      id: PrintingTypes.DMLM,
      name: PrintingTypes.DMLM,
    },
    {
      disabled: false,
      id: PrintingTypes.EBM,
      name: PrintingTypes.EBM,
    },
  ]

  selectedMachineTechnology = this.allPrintingTypes

  readonly units: IUnits[] = measurementUnits
  normalElevationValue: number = 0
  selectedElevationValue: number = 4

  observerWatcher: Function = null

  get filteredMachineTechnologies() {
    return this.machineTechnologies.map((technology) => {
      return {
        id: technology.id,
        name: technology.name,
        disabled: !this.filterMachinesByTechnology(technology.id).length,
      }
    })
  }

  get getCadHelperLink() {
    const id = this.$route.params.costEstimationId
    const command = CadHelperProcess.EstimateCost
    const signedUrl = `${window.env.VUE_APP_API_URL}/${EndpointsUrls.CostEstimations}/${id}`
    const downloadUrl = this.getDownloadLinkOfCadHelper
    const token = this.costEstimationToken
    const cadHelperVersion = this.getVersionOfCadHelper
    const sessionId = this.getUser ? this.getUser.id : null
    const tenant = this.getUser ? this.getUser.tenant : null

    const parameters: CadHelperLinkParameters = {
      command,
      signedUrl,
      downloadUrl,
      token,
      cadHelperVersion,
      sessionId,
      tenant,
    }

    return new CadHelperLink(parameters)
  }

  get selectedUnitsSuffix(): string {
    const units = this.units.find((u) => u.id === this.costEstimation.unitsId)
    return units && units.alias
  }

  get machineBuildChamber() {
    const toUnit = this.selectedUnitsSuffix

    // Finding max possible sizes
    const maxLength = this.getMachineConfigs.reduce(
      (acc, cur) => (acc < cur.buildChamberX ? cur.buildChamberX : acc),
      0,
    )
    const maxWidth = this.getMachineConfigs.reduce((acc, cur) => (acc < cur.buildChamberY ? cur.buildChamberY : acc), 0)
    const maxHeight = this.getMachineConfigs.reduce(
      (acc, cur) => (acc < cur.buildChamberZ ? cur.buildChamberZ : acc),
      0,
    )

    // Get square-shaped envelope product lines
    const squareMachineConfigIds = this.getMachineConfigs
      .filter((config) => config.envelopeShape === EnvelopeShape.Square)
      .map((config) => config.id)

    // EBM machineConfigs with square Envelope shape got specific formula, were part width and length are multiply
    // by ENVELOPE_X_Y_MODIFIER_FOR_LIKELY_AND_OPTIMISTIC (1.41) to enshure that part isn`t too huge and can be build
    // via this printer, so next statement part_width/length * 1.41 < buildChamberX/Y must alwayes stay true.
    // In our case we need to divide buildChamberX/Y by this modifier,
    // to enshure that part X/Y dimensions are not out of possible build plate bounds
    const machineConfigs = this.getSelectedMachineConfigs
      .map((id) => this.getMachineConfigById(id))
      .map((machineConfig) =>
        squareMachineConfigIds.includes(machineConfig.id)
          ? {
              buildChamberX: machineConfig.buildChamberX / ENVELOPE_X_Y_MODIFIER_FOR_LIKELY_AND_OPTIMISTIC,
              buildChamberY: machineConfig.buildChamberY / ENVELOPE_X_Y_MODIFIER_FOR_LIKELY_AND_OPTIMISTIC,
              buildChamberZ: machineConfig.buildChamberZ,
            }
          : machineConfig,
      )

    const length = machineConfigs.reduce((acc, cur) => (acc > cur.buildChamberX ? cur.buildChamberX : acc), maxLength)
    const width = machineConfigs.reduce((acc, cur) => (acc > cur.buildChamberY ? cur.buildChamberY : acc), maxWidth)
    const height = machineConfigs.reduce((acc, cur) => (acc > cur.buildChamberZ ? cur.buildChamberZ : acc), maxHeight)

    return {
      length: convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, length),
      width: convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, width),
      height: convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, height),
    }
  }

  get machineBuildChamberMinValue() {
    return MACHINE_BUILD_CHAMBER_MIN_VALUE
  }

  get numericFieldPrecision() {
    return NUMERIC_FIELD_PRECISION
  }

  get maxDecimalDigitsPrecision() {
    return MAX_DECIMAL_DIGITS_PRECISION
  }

  get maxStringLength() {
    return MAX_STRING_LENGTH
  }

  get isDisabledUnitsField() {
    return this.costEstimation.inputType === EstimationCostInputType.Upload && !this.isShownMeasurementUnitsSelector
  }

  @Watch('costEstimation', { deep: true })
  async onCostEstimationChange() {
    if (this.isShownError) {
      this.$nextTick(async () => {
        const { isValid, errorMessageId } = await this.validate()
        this.toggleErrorAlert(!isValid, errorMessageId)
      })
    }
  }

  async beforeMount() {
    try {
      const materials = this.fetchMaterials()
      const machineConfigs = this.fetchMachineConfigs()
      const params = this.fetchParameterSets()
      const links = this.fetchLinks()

      await Promise.all([materials, machineConfigs, params, links])

      this.connector = CommunicationService.getConnector()
      this.connector.subscribe(BrokerEvents.CostEstimationSent, this.onSocketMessage)

      this.printerCards = this.getMachineConfigs.map((printer) => ({
        printer,
        disabled: false,
      }))

      const id = this.$route.params.costEstimationId
      const costEstimation = await this.getCostEstimationById(id)
      this.costEstimation = costEstimation

      if (!this.costEstimation) {
        messageService.showErrorMessage(this.$t('noCostEstimationFound') as string)
        throw new Error(this.$t('noCostEstimationFound') as string)
      }

      this.setSelectedCostEstimation(costEstimation)
      this.clearFormFields()
      await this.getTemporaryToken()
    } catch (error) {
      console.error(`Error while receiving Cost Estimation: ${error}`)
      this.routerHookEnabled = false
      // @ts-ignore
      this.$router.safePush(RouterPaths.ManageCostEstimations)
      return
    }

    if (this.costEstimation.costEstimationSet.length) {
      this.costEstimationEditingMode = EditingModes.Edit
      this.setNeedToUpdateCostEstimations(true)
    }
  }

  mounted() {
    window.addEventListener('beforeunload', this.beforeUnloadHandler)
    window.addEventListener('unload', this.syncDeleteCostEstimation)
  }

  async getTemporaryToken() {
    try {
      const { token } = await this.getCostEstimationsToken()
      this.costEstimationToken = token
    } catch (error) {
      const { data = { code: '', message: '' } } = error
      this.isShownError = true
      this.costEstimationErrorMessageId = this.getErrorMessageId(data.message)
    }
  }

  onSocketMessage(brokerMessage: BrokerMessage) {
    switch (brokerMessage.event) {
      case BrokerEvents.CostEstimationSent:
        this.onNewCostEstimation(brokerMessage.message)
        break

      case BrokerEvents.CadHelperStarted:
        this.onCadHelperStarted()
        break
    }
  }

  onNewCostEstimation(costEstimation: ICostEstimation) {
    const { length, width, height, surfaceArea, volume, modelName, unitsId, imageUrl } = costEstimation

    Object.assign(this.costEstimation, {
      modelName,
      unitsId,
      imageUrl,
      length: round(length, NUMERIC_FIELD_PRECISION),
      width: round(width, NUMERIC_FIELD_PRECISION),
      height: round(height, NUMERIC_FIELD_PRECISION),
      surfaceArea: round(surfaceArea, NUMERIC_FIELD_PRECISION),
      volume: round(volume, NUMERIC_FIELD_PRECISION),
    })

    if (unitsId === UNKNOWN_MEASUREMENT_UNIT_ID) {
      this.isShownSpecifyMeasurementDialog = true
      this.isShownMeasurementUnitsSelector = true
    }
  }

  onSocketConnected() {
    this.isShownSocketError = false
  }

  onSocketError(socketError: ISocketError) {
    console.error(socketError.errorMessage)
    this.isShownSocketError = true
  }

  onCadHelperTimeOut() {
    this.openDownloadCadHelperDialog()
  }

  onExtractDataClick() {
    this.preventSaveChangesDialog()
  }

  onCadHelperStarted() {
    this.closeDownloadCadHelperDialog()
  }

  openDownloadCadHelperDialog() {
    this.downloadCadHelperDialogVisible = true
  }

  closeDownloadCadHelperDialog() {
    this.downloadCadHelperDialogVisible = false
  }

  beforeDestroy() {
    if (this.observerWatcher) {
      this.observerWatcher()
    }

    this.connector.unsubscribe(BrokerEvents.CostEstimationSent, this.onSocketMessage)
    window.removeEventListener('beforeunload', this.beforeUnloadHandler)
    window.removeEventListener('unload', this.syncDeleteCostEstimation)
  }

  getBuildChamberMessage(machine) {
    const toUnit = this.selectedUnitsSuffix
    const length = round(
      convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, machine.buildChamberX),
      PRECISIONS[toUnit],
    )
    const width = round(
      convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, machine.buildChamberY),
      PRECISIONS[toUnit],
    )
    const height = round(
      convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, machine.buildChamberZ),
      PRECISIONS[toUnit],
    )
    return `${length} x ${width} x ${height}`
  }

  clearFormFields(): void {
    this.costEstimation.length = this.costEstimation.length || null
    this.costEstimation.width = this.costEstimation.width || null
    this.costEstimation.height = this.costEstimation.height || null
    this.costEstimation.volume = this.costEstimation.volume || null
    this.costEstimation.surfaceArea = this.costEstimation.surfaceArea || null
    this.costEstimation.numberOfPartsPerYear = this.costEstimation.numberOfPartsPerYear || null
  }

  async validate(): Promise<{ isValid: boolean; errorMessageId: string }> {
    const result = {
      isValid: true,
      errorMessageId: '',
    }

    const isValid = await this.$refs.observer.validate()

    if (!isValid) {
      result.isValid = false
      result.errorMessageId = INVALID_FORM_MESSAGE_ID
      this.isShownError = false
    } else if (!this.getSelectedMachineConfigs.length) {
      result.isValid = false
      result.errorMessageId = NO_MACHINE_SELECTED_ERROR_MESSAGE_ID
    }

    return result
  }

  toggleErrorAlert(isShow: boolean = false, errorMessageId: string) {
    this.costEstimationErrorMessageId = errorMessageId || DEFAULT_COST_ESTIMATION_ERROR_MESSAGE_ID

    if (errorMessageId !== INVALID_FORM_MESSAGE_ID) {
      this.isShownError = isShow
    }

    if (isShow) {
      this.scrollToError()
    }
  }

  async onEstimateClick() {
    const { isValid, errorMessageId } = await this.validate()

    if (!isValid) {
      this.toggleErrorAlert(true, errorMessageId)
      return
    }

    this.isLoadingEstimate = true

    this.routerHookEnabled = false
    this.isShownError = false
    const { projectName, modelName } = this.costEstimation

    if (projectName === DEFAULT_COST_ESTIMATION_PROJECT_NAME && modelName) {
      this.costEstimation.projectName = modelName
    }

    // TODO: Get rid of merging logic and use state to store cost estimation properties
    const estimationFromStore = this.getSelectedCostEstimation
    this.costEstimation.materialId = estimationFromStore.materialId
    this.costEstimation.costEstimationSet = estimationFromStore.costEstimationSet

    try {
      const { id } = await this.estimateCost(this.costEstimation)
      if (this.image) {
        await this.updateCostEstimationImage({ id, image: this.image })
        this.isLoadingEstimate = false
      }

      const routerObj = {
        name: RouterNames.CostEstimation,
        params: {
          costEstimationId: id,
        },
      }

      this.isLoadingEstimate = false
      // @ts-ignore
      this.$router.safePush(routerObj)
    } catch (error) {
      const { data = { code: '', message: '' } } = error
      this.isShownError = true
      this.costEstimationErrorMessageId = this.getErrorMessageId(data.message)
      this.isLoadingEstimate = false
    }
  }

  getErrorMessageId(errorCode: string) {
    switch (errorCode) {
      case OUTSIZE_ERROR_CODE:
        return 'costEstimationDoesNotFitError'
      case SOCKET_MESSAGE_ERROR_CODE:
        return 'costEstimationSocketMessageError'
      default:
        return DEFAULT_COST_ESTIMATION_ERROR_MESSAGE_ID
    }
  }

  async onCancelClick() {
    // @ts-ignore
    this.$router.safePush(RouterPaths.ManageCostEstimations)
  }

  onProjectNameMouseLeave() {
    this.isShownNameField = this.costEstimation.projectName.trim().length === 0
  }

  beforeUnloadHandler(e) {
    if (!this.allowUnsavedChangesDialog) {
      return
    }

    e.preventDefault()
    e.returnValue = ''
    return ''
  }

  syncDeleteCostEstimation() {
    if (this.costEstimationEditingMode === EditingModes.Edit) {
      return
    }
    costEstimationAPI.syncDeleteCostEstimation(this.costEstimation.id)
  }

  async leaveCostEstimation() {
    if (this.costEstimationEditingMode === EditingModes.Create) {
      const { id } = this.costEstimation
      await this.deleteCostEstimation(id)
    }
    this.isShownLeaveConfirmationDialog = false
    this.routerHookEnabled = false
    // @ts-ignore
    this.$router.safePush(this.routerTo)
  }

  remainCostEstimation() {
    this.isShownLeaveConfirmationDialog = false
  }

  preventSaveChangesDialog() {
    // This trick is needed because application is opened via anchor click and this triggers 'beforeunload' event
    // though the user is still on the same page. So we set a flag that allows to bypass Unsaved Changes dialog
    this.allowUnsavedChangesDialog = false
    setTimeout(() => (this.allowUnsavedChangesDialog = true), PREVENT_TIMEOUT)
  }

  getProjectNameValidationRules() {
    return {
      required: true,
      max: MAX_STRING_LENGTH,
    }
  }

  getMachineBuildChamberValidationRule(sizeField) {
    const toUnit = this.selectedUnitsSuffix
    return {
      required: true,
      max_value: this.machineBuildChamber[sizeField]
        ? {
            max: round(
              this.machineBuildChamber[sizeField] -
                convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, PARTS_OFFSET),
              PRECISIONS[toUnit],
            ),
          }
        : false,
      min_value: round(
        convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, this.machineBuildChamberMinValue),
        PRECISIONS[toUnit],
      ),
    }
  }

  getMachineBedVolumeValidationRule() {
    const toUnit = this.selectedUnitsSuffix
    const convertedPartsOffset = convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, PARTS_OFFSET)

    // Calculate volumes for selected circle-shaped envelope machines
    const circleMachineConfigIds = this.getMachineConfigs
      .filter((line) => line.envelopeShape === EnvelopeShape.Circle)
      .map((line) => line.id)

    const circleEnvelopeShapeMachineVolumes = this.getSelectedMachineConfigs
      .map((machineConfigId) => this.getMachineConfigById(machineConfigId))
      .filter((machineConfig) => circleMachineConfigIds.includes(machineConfig.id))
      .map((machineConfig) => ({
        volume:
          Math.PI *
          Math.pow(
            (convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, machineConfig.buildChamberX) -
              convertedPartsOffset) /
              2,
            2,
          ) *
          (convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, machineConfig.buildChamberZ) -
            convertedPartsOffset),
        id: machineConfig.id,
      }))

    const defaultShapeVolume =
      (this.machineBuildChamber.length - convertedPartsOffset) *
      (this.machineBuildChamber.width - convertedPartsOffset) *
      (this.machineBuildChamber.height - convertedPartsOffset)

    const circleShapedVolumesInSelectedMachines = circleEnvelopeShapeMachineVolumes
      .filter((machineConfig) => this.getSelectedMachineConfigs.includes(machineConfig.id))
      .map((machineConfig) => machineConfig.volume)

    const maxVolume = !this.getSelectedMachineConfigs.some((id) => circleMachineConfigIds.includes(id))
      ? defaultShapeVolume
      : Math.min(...circleShapedVolumesInSelectedMachines)

    return {
      required: true,
      max_value: round(maxVolume, toUnit === 'km' ? PRECISIONS[toUnit] * 3 : PRECISIONS[toUnit]),
      min_value: round(
        convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, this.machineBuildChamberMinValue),
        PRECISIONS[toUnit],
      ),
    }
  }

  getMachineBedAreaValidationRule() {
    const toUnit = this.selectedUnitsSuffix
    return {
      required: true,
      min_value: round(
        convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, this.machineBuildChamberMinValue),
        PRECISIONS[toUnit],
      ),
    }
  }

  getNumberOfPartsPerYearValidationRule() {
    return {
      required: true,
      min_value: 1,
      integer: true,
      max_value: MAX_INTEGER,
    }
  }

  async onFilePicked(image) {
    if (image) {
      if (image.type && (image.type === 'image/png' || image.type === 'image/jpeg' || image.type === 'image/bmp')) {
        this.selectedImageURL = URL.createObjectURL(image)
        this.image = image
      } else {
        const errMsg = `${this.$i18n.t('fileTypeNotSupported')}` as string
        messageService.showErrorMessage(errMsg)
      }
      return
    }

    URL.revokeObjectURL(this.selectedImageURL)
    this.selectedImageURL = null
    this.image = null
  }

  onImageError() {
    messageService.showErrorMessage(this.$i18n.t('unableUploadPicture') as string)
    this.$refs.imageFileInput.reset()
  }

  get filteredPrinterCards() {
    const filtered = this.filterMachinesByTechnology(this.selectedMachineTechnology)

    return filtered
      .map((card) => {
        const selected = this.getSelectedMachineConfigs.includes(card.printer.id)

        const isEnabledMachine = this.getEnabledMachineConfigs.includes(card.printer.id)
        const isMaxNumberMachineSelected = this.getSelectedMachineConfigs.length === MAX_COST_ESTIMATION_SETS
        const disabled = !isEnabledMachine || (!selected && isMaxNumberMachineSelected)

        return {
          ...card,
          selected,
          disabled,
          isPartSizeFit: disabled ? true : this.isPartSizeFit(card.printer),
          isCircleEnvelopeShapedMachine: this.isCircleEnvelopeShapedMachine(card.printer),
        }
      })
      .sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1))
  }

  get filteredMaterials() {
    if (this.selectedMachineTechnology === this.allPrintingTypes) {
      return this.getMaterials
    }

    const machineNames = this.filteredPrinterCards.map((printerCard) => printerCard.printer.machineName)
    const parameterSets = this.getParameterSets.filter((set) => machineNames.includes(set.machineName))
    return this.getMaterials
      .filter((material) => parameterSets.some((set) => set.materialId === material.id))
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  isPartSizeFit(machineConfig: IMachineConfig) {
    const toUnit = this.selectedUnitsSuffix
    const { buildChamberX, buildChamberY, buildChamberZ } = machineConfig
    let { length, width } = this.costEstimation

    const squareMachineConfigIds = this.getMachineConfigs
      .filter((mc) => mc.envelopeShape === EnvelopeShape.Square)
      .map((mc) => mc.id)

    if (squareMachineConfigIds.includes(machineConfig.id)) {
      length *= ENVELOPE_X_Y_MODIFIER_FOR_LIKELY_AND_OPTIMISTIC
      width *= ENVELOPE_X_Y_MODIFIER_FOR_LIKELY_AND_OPTIMISTIC
    }

    const lengthMax =
      convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, buildChamberX) -
      convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, PARTS_OFFSET)
    const widthMax =
      convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, buildChamberY) -
      convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, PARTS_OFFSET)
    const heightMax =
      convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, buildChamberZ) -
      convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, toUnit, PARTS_OFFSET)

    return length <= lengthMax && width <= widthMax && this.costEstimation.height <= heightMax
  }

  isCircleEnvelopeShapedMachine(machineConfig: IMachineConfig) {
    return machineConfig.envelopeShape === EnvelopeShape.Circle
  }

  getAdjustedBuildChamberByEnvelopeShape(machineConfig: IMachineConfig, buildChamberXOrY) {
    if (machineConfig.envelopeShape !== EnvelopeShape.Square) {
      return round(buildChamberXOrY, PRECISIONS[DEFAULT_MACHINE_BUILD_CHAMBER_UNIT])
    }

    return round(
      buildChamberXOrY / ENVELOPE_X_Y_MODIFIER_FOR_LIKELY_AND_OPTIMISTIC,
      PRECISIONS[DEFAULT_MACHINE_BUILD_CHAMBER_UNIT],
    )
  }

  getBuildChamberMm(defaultUnitBuildChamber: number) {
    return convertLength(DEFAULT_MACHINE_BUILD_CHAMBER_UNIT, 'mm', defaultUnitBuildChamber)
  }

  private filterMachinesByTechnology(technology: PrintingTypes | string) {
    let filtered = this.printerCards.filter((card) => card.printer.visibility)

    if (technology !== this.allPrintingTypes) {
      filtered = filtered.filter((card) => card.printer.printingType.toLowerCase() === technology.toLowerCase())
    }

    return filtered
  }

  private scrollToError() {
    this.$nextTick(() => {
      const formContainer = this.$el.querySelector('#form_container')
      const errorFields = formContainer.querySelectorAll(`.error-flat-form`)

      if (this.costEstimationErrorMessageId !== INVALID_FORM_MESSAGE_ID && errorFields.length === 0) {
        const ceError = this.$el.querySelector(`#ce_error`)
        ceError.scrollIntoView()
        return
      }

      const topErrorElement = this.getTopErrorElement(errorFields)
      if (!topErrorElement) return

      topErrorElement.scrollIntoView()
      formContainer.scrollTop -= 20
    })
  }

  private getTopErrorElement(elements: NodeListOf<Element>) {
    if (elements.length === 0) return

    const elementsArray = Array.prototype.slice.call(elements, 0)
    elementsArray.sort((element1, element2) => {
      const rect1 = element1.getBoundingClientRect()
      const rect2 = element2.getBoundingClientRect()

      return rect1.top - rect2.top
    })

    return elementsArray[0]
  }
}
