
import StoresNamespaces from '@/store/namespaces'
import { PrintOrderPreviewPatch } from '@/types/PrintOrder/PrintOrderPatch'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const commonStore = namespace(StoresNamespaces.Common)

@Component
export default class PrintOrderPatchTooltip extends Vue {
  @commonStore.Getter tooltipOpenDelay: number

  @Prop() patch: PrintOrderPreviewPatch

  getPatchMultilineValue(patch) {
    if (patch.RunTimeContentText) {
      return patch.RunTimeContentText
    }
    return patch.runTimeContentText
  }
}
