
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

import Button from '@/components/controls/Common/Button.vue'
import { JobDto } from '@/types/PartsLibrary/Job'

import jobApi from '@/api/jobs'
import { jobTypeToNameForDashboard } from '@/utils/string'

@Component({
  components: {
    Button,
  },
})
export default class JobCancelModal extends Vue {
  @Prop({ required: true }) value: boolean
  @Prop({ required: true }) job: JobDto

  get label(): TranslateResult {
    if (!this.job) {
      return
    }
    const jobTypeName = jobTypeToNameForDashboard(this.job.jobType)
    return this.$i18n.t('cancelTaskDialog.label', { jobType: jobTypeName, itemName: this.job.itemName })
  }

  onCancelClick() {
    this.$emit('close')
  }

  onConfirmClick() {
    jobApi.cancelJob(this.job.id, 'Cancelled from User Dashboard UI')
    this.$emit('success')
  }
}
