import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { IUndoableState } from './types'
import { IRootState } from '@/store/types'

export const state: IUndoableState = {
  isToolModeActive: false,
  isCommandManagerReady: false,
  buildPlanRedoStack: [],
  buildPlanUndoStack: [],
  toolRedoStack: [],
  toolUndoStack: [],
}

const namespaced: boolean = true

export const commandManager: Module<IUndoableState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
