import { RenderScene } from '@/visualization/render-scene'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import {
  IBuildPlan,
  SelectionUnit,
  IBuildPlanItem,
  BuildPlanItemSupport,
  BuildPlanItemOverhang,
  GeometryType,
  Visibility,
} from '@/types/BuildPlans/IBuildPlan'
import { OuterEvents } from '@/visualization/types/Common'
import VisualizationModeTypes from '@/visualization/types/VisualizationModeTypes'
import { RenderDetailsPreview } from '@/visualization/renderDetailsPreview'
import { IConstraints } from '@/types/BuildPlans/IConstraints'
import { ToolTypes } from '@/types/BuildPlans/ToolTypes'
import ITransformationDelta from '@/types/BuildPlans/ITransformationDelta'
import { InstanceLabel } from '@/visualization/types/InstanceLabel'
import { DuplicateMode, DuplicatePayload } from '@/types/Duplicate/Duplicate'
import { ILabelOrientation, ILabelStyle } from '@/types/Marking/ILabel'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { ILoadedDocument } from '@/visualization/rendering/ModelManager'
import { SceneMode } from '@/visualization/types/SceneTypes'
import { VisualizationApi } from '@/visualization/components/VisualizationApi'
import { IOverhangConfig } from '@/visualization/rendering/OverhangManager'
import { IHighlightDefectPayload, ISelectableDefectPayload } from '@/types/Parts/IPartInsight'
import { BuildPlatformScene } from '@/visualization/buildPlatformScene'
import { IPartRenderable } from '@/types/Parts/IPartRenderable'
import { PrintingTypes } from '@/types/IMachineConfig'
import { RestoreSelectedPartsType } from '@/types/BuildPlans/RestoreSelectedPartsType'
import { LoadBuildPlanOptions } from '@/visualization/types/LoadBuildPlanOptions'
import { ManualPatch } from '@/types/Label/Patch'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { CollectorWithOptionalFilter } from '@/types/OptionalMultiItemCollector/MultiItemCollector'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { GridLetterJSON } from '@/types/Label/TextElement'
import { LabelDirtyState } from '@/types/Label/enums'
import { Placement } from '@/types/Label/Placement'
import { IIBCPlan } from '@/types/IBCPlans/IIBCPlan'
import { Clearance, ClearanceModes, ClearanceTypes } from '@/visualization/types/ClearanceTypes'
import { ClearanceMeshToMesh } from '@/visualization/rendering/clearance/ClearanceMeshToMesh'
import { ISelectableNode } from '@/visualization/rendering/SelectionManager'

export class Visualization {
  private renderScene: RenderScene
  private renderDetailsPreview: RenderDetailsPreview
  private buildPlatformScene: BuildPlatformScene
  private initializationPromise: { promise: Promise<void>; done: Function } = this.createInitializationPromise()

  get configFileReady() {
    return this.renderScene.configFileReady
  }

  get transformationChange() {
    return this.renderScene.transformationChange
  }

  get transformationChangeBatch() {
    return this.renderScene.transformationChangeBatch
  }

  get crossSectionChange() {
    return this.renderScene.crossSectionChange
  }

  get labelAdded() {
    return this.renderScene.getModelManager() ? this.renderScene.getModelManager().labelAdded : null
  }

  get labelUpdated() {
    return this.renderScene.getModelManager() ? this.renderScene.getModelManager().labelUpdated : null
  }

  get labelPlaced() {
    return this.renderScene.getModelManager() ? this.renderScene.getModelManager().labelPlaced : null
  }

  get labelOrientationSelected() {
    return this.renderScene.getModelManager() ? this.renderScene.getModelManager().labelOrientationSelected : null
  }

  get labelOrientationChanged() {
    return this.renderScene.getModelManager() ? this.renderScene.getModelManager().labelOrientationChanged : null
  }

  get elementsSelected() {
    return this.renderScene.elementsSelected
  }

  get selectedElementsCollisions() {
    return this.renderScene.selectedElementsCollisions
  }

  get initializeSlicer() {
    return this.renderScene.initializeSlicer
  }

  get resultsManagerEvent() {
    return this.renderScene.resultsManagerEvent
  }

  get visualizationModeChanged() {
    return this.renderScene.visualizationModeChanged
  }

  get changeIsLoading() {
    return this.renderScene.changeIsLoading
  }

  get configLoadedEvent() {
    return this.renderScene.configLoadedEvent
  }

  get configsLoadedEvent() {
    return this.renderScene.configsLoadedEvent
  }

  get addGeometryProperties() {
    return this.renderScene.addGeometryProperties
  }

  get changeBuildPlate() {
    return this.renderScene.changeBuildPlate
  }

  get generateOverhangMeshByClickEvent() {
    return this.renderScene.generateOverhangMeshByClickEvent
  }

  get generateOverhangMeshEventDebounced() {
    return this.renderScene.generateOverhangMeshEventDebounced
  }

  get selectSupportEvent() {
    return this.renderScene.selectSupportEvent
  }

  get hoverSupportEvent() {
    return this.renderScene.hoverSupportEvent
  }

  get hoverDefect() {
    return this.renderScene.hoverDefect
  }

  get selectDefectsEvt() {
    return this.renderScene.selectDefects
  }

  get updateItemPreviewEvent() {
    return this.renderScene.updateItemPreviewEvent
  }

  get onGetBPNameByBPId() {
    return this.renderScene.onGetBPNameByBPId
  }

  get overhangsElementsEvent() {
    return this.renderScene.overhangsElementsEvent
  }

  get deletePartsInState() {
    return this.renderScene.deletePartsInState
  }

  get downwardPlaneRotationStarted() {
    return this.renderScene.downwardPlaneRotationStarted
  }

  get downwardPlaneRotationEnded() {
    return this.renderScene.downwardPlaneRotationEnded
  }

  get deleteOverhangsAndSupportsEvent() {
    const selectionManager = this.renderScene.getSelectionManager()
    return selectionManager ? selectionManager.deleteOverhangsAndSupportsEvent : null
  }

  get onSelectionBoundingGeometryReadyEvent() {
    const selectionManager = this.renderScene.getSelectionManager()
    return selectionManager ? selectionManager.onSelectionBoundingGeometryReadyEvent : null
  }

  get onSetInstancingIsRunning() {
    const modelManager = this.renderScene.getModelManager()
    return modelManager ? modelManager.setInstancingIsRunning : null
  }

  get reportInsightIssues() {
    const insightManager = this.renderScene.getInsightsManager()
    return insightManager ? insightManager.reportInsightIssues : null
  }

  get onCameraPositionChangedEvent() {
    return this.renderScene.cameraPositionChangedEvent
  }

  get loadedDocuments(): ILoadedDocument[] {
    if (!this.renderScene) return []
    const modelManager = this.renderScene.getModelManager()
    return modelManager ? modelManager.getLoadedDocuments() : []
  }

  get hoverBody() {
    return this.renderScene.hoverBody
  }

  get hoverLabel() {
    return this.renderScene.hoverLabel
  }

  get setCollectorItems() {
    return this.renderScene.getCollectorManager().setCollectorItems
  }

  get onPartElevate() {
    return this.renderScene.getSelectionManager().gizmos.onPartElevate
  }

  get selectRelatedBodies() {
    return this.renderScene.selectRelatedBodies
  }

  get addSelectedLabeledBodies() {
    return this.renderScene.getSelectionManager().addSelectedLabeledBodies
  }

  get removeSelectedLabeledBodies() {
    return this.renderScene.getSelectionManager().removeSelectedLabeledBodies
  }

  async init(canvasId: string, sceneMode: SceneMode, viewMode?: ViewModeTypes, loadDefaultPlate?: boolean) {
    // Create the RenderGraund using the 'renderCanvas'
    this.renderScene = new RenderScene(canvasId)
    // Create the scene
    await this.renderScene.createScene(sceneMode, viewMode, loadDefaultPlate)
    this.initializationPromise.done()
  }

  initApi() {
    window.visualizationApi = new VisualizationApi(this.renderScene)
  }

  async initDetailsPreview(canvasId: string, sceneMode: SceneMode) {
    this.renderDetailsPreview = new RenderDetailsPreview(canvasId, sceneMode)
    const isCanvasExists = this.renderDetailsPreview.isCanvasExists()

    if (isCanvasExists) {
      await this.renderDetailsPreview.createScene()
    } else {
      this.renderDetailsPreview.terminatePartLoading()
    }
    return isCanvasExists
  }

  handleOuterEvent(eventName: OuterEvents, payload?: object) {
    this.renderScene.handleOuterEvent(eventName, payload)
  }

  clearSelection() {
    this.renderScene.selectPickedObject(false, false, false, null)
  }

  setMaterial(materialName: string) {
    this.renderScene.setMaterial(materialName)
  }

  showGizmos() {
    this.renderScene.showGizmos()
  }

  disableGizmos(silent: boolean) {
    this.renderScene.disableGizmos(silent)
  }

  checkCollision() {
    this.renderScene.checkCollision()
  }

  saveSelectionManagerState() {
    this.renderScene.getSelectionManager().saveSelectionManagerState()
  }

  restoreSelectionManagerState() {
    const selectionManager = this.renderScene.getSelectionManager()
    selectionManager.restoreSelectionManagerState()
    if (selectionManager.getSelected().length > 0) {
      this.renderScene.showGizmos()
    }
  }

  showRubberBand(isShown: boolean) {
    const clearanceManager = this.renderScene.getClearanceManager()

    if (clearanceManager && clearanceManager.hasClearanceMode(ClearanceModes.Mesh)) {
      const clearanceMeshToMesh = clearanceManager.getClearanceMode(ClearanceModes.Mesh) as ClearanceMeshToMesh
      clearanceMeshToMesh.changeRubberBandVisibility(isShown)
    }
  }

  addDuplicatedPartsToGPUPicker() {
    const modelManager = this.renderScene.getModelManager()
    if (modelManager) {
      modelManager.duplicateMgr.addDuplicatedPartsToGPUPicker()
    }
  }

  removeDuplicatedPartsFromGPUPicker() {
    const modelManager = this.renderScene.getModelManager()
    if (modelManager) {
      modelManager.duplicateMgr.removeDuplicatedPartsFromGPUPicker()
    }
  }

  toggleClearanceHighlight(clearanceId: string, showHighlight: boolean) {
    const clearanceManager = this.renderScene.getClearanceManager()
    const meshManager = this.renderScene.getMeshManager()
    if (!clearanceManager || !meshManager) {
      return
    }

    this.renderScene.hoverPickedObject()
    if (showHighlight) {
      const tubeMesh = clearanceManager.utilScene.meshes.find(
        (mesh) =>
          meshManager.isClearanceSensitiveZone(mesh) &&
          mesh.metadata.clearance &&
          mesh.metadata.clearance.id === clearanceId,
      )

      this.renderScene.hoverPickedObject({ body: tubeMesh })
    }

    this.renderScene.animate(true)
  }

  measureDistanceToEnvironment(payload: {
    from: ClearanceTypes
    to: ClearanceTypes
    buildPlanItemId: string
    componentId?: string
    geometryId?: string
  }) {
    this.renderScene.measureDistanceToEnvironment(payload)
  }

  clearClearances(
    clearanceModePredicate?: (clearanceMode: ClearanceModes) => boolean,
    clearancePredicate?: (clearance: Clearance) => boolean,
  ) {
    this.renderScene.dehighlightClearances()
    this.renderScene.getClearanceManager().clearClearances(clearanceModePredicate, clearancePredicate)
  }

  hideClearClearances(
    hide: boolean,
    clearanceModePredicate?: (clearanceMode: ClearanceModes) => boolean,
    clearancePredicate?: (clearance: Clearance) => boolean,
  ) {
    this.renderScene.dehighlightClearances()
    this.renderScene.getClearanceManager().hideClearances(hide, clearanceModePredicate, clearancePredicate)
  }

  undo() {
    this.renderScene.undo()
  }

  async dispose() {
    if (this.renderScene) {
      await this.renderScene.dispose()
      this.renderScene.clearResults()
    }

    delete this.renderScene
    this.initializationPromise = this.createInitializationPromise()
  }

  async disposeDetailsPreview() {
    if (this.renderDetailsPreview) {
      await this.renderDetailsPreview.dispose()
    }
  }

  disposeApi() {
    delete window.visualizationApi
  }

  clearDetailsPreview() {
    this.renderDetailsPreview.clearScene()
  }

  setCrossSection(payload: { isEnabled: boolean; crossSectionMatrix: number[] }) {
    if (this.renderScene) {
      this.renderScene.setCrossSection(payload)
    }
  }

  recenterCrossSection() {
    if (this.renderScene) {
      this.renderScene.recenterCrossSection()
    }
  }

  axisAlignCrossSection() {
    if (this.renderScene) {
      this.renderScene.axisAlignCrossSection()
    }
  }

  setSlicer(isEnabled: boolean) {
    if (this.renderScene) {
      this.renderScene.setSlicer(isEnabled)
    }
  }

  changeCurrentSlicer(current: number) {
    this.renderScene.changeCurrentSlicer(current)
  }

  async changeViewMode(mode: ViewModeTypes) {
    this.renderScene.changeViewMode(mode)
  }

  changeVisualizationMode(mode: VisualizationModeTypes) {
    this.renderScene.getVisuzalizationModeManager().changeVisualizationMode(mode)
  }

  setOverhangAreasAngle(overhangAngle: number) {
    this.renderScene.getVisuzalizationModeManager().setOverhangAngle(overhangAngle)
  }

  changeView(view: string) {
    this.renderScene.changeView(view)
  }

  setViewLocked(value: boolean) {
    this.renderScene.setViewLocked(value)
  }

  getBoundingBoxDetails() {
    return this.renderScene ? this.renderScene.getBoundingBoxDetails() : null
  }

  getBoundingBoxDetailsForPartsOnly() {
    return this.renderScene ? this.renderScene.getBoundingBoxDetailsForCostCalculation() : null
  }

  getItemsBoundingBox2D(itemIds: string[]) {
    return this.renderScene ? this.renderScene.getItemsBoundingBox2D(itemIds) : null
  }

  showMeshes() {
    this.renderScene.showMeshes()
  }

  hideMeshes() {
    this.renderScene.hideMeshes()
  }

  rotatePart(partItemIndex: string, x: number, y: number, z: number, transformation?: number[], zTranslation?: number) {
    this.renderScene.rotatePart(partItemIndex, x, y, z, transformation, zTranslation)
  }

  selectAndHighlightPart(
    buildPlanItemId: string,
    meshId: string,
    deselectIfSelected: boolean = false,
    showGizmo: boolean = false,
  ) {
    this.renderScene.selectMeshByBuildPlanItemId(buildPlanItemId, meshId, true, true, deselectIfSelected, showGizmo)
  }

  selectAndHighlightParts(buildplanItemIds: string[], deselectIfSelected: boolean = false, showGizmo: boolean = false) {
    this.renderScene.selectMeshesByBuildPlanItemIds(buildplanItemIds, true, true, deselectIfSelected, showGizmo)
  }

  selectDefects(payload: ISelectableDefectPayload) {
    this.renderScene.getModelManager().selectDefects(payload)
  }

  toggleHighlight(buildPlanItemId: string, meshId: string, deselectIfSelected: boolean) {
    this.renderScene.toggleHighlight(buildPlanItemId, meshId, deselectIfSelected)
  }

  toggleMultiHighlight(buildPlanItemIds: string[], deselectIfSelected: boolean) {
    this.renderScene.toggleMultiHighlight(buildPlanItemIds, deselectIfSelected)
  }

  toggleDefectsHighlight(payload: IHighlightDefectPayload) {
    this.renderScene.getModelManager().toggleDefectsHighlight(payload)
  }

  selectAndHighlightPartWithAttach(buildPlanItemIds: string | string[]) {
    this.renderScene.getSelectionManager().deselect()

    if (Array.isArray(buildPlanItemIds)) {
      buildPlanItemIds.forEach((item) => this.renderScene.selectMeshByBuildPlanItemId(item, null, true, true))
    } else {
      this.renderScene.selectMeshByBuildPlanItemId(buildPlanItemIds, null, true, true)
    }

    this.renderScene.showGizmos()
  }

  selectAndHighlightPartsAfterRemove(buildPlanItemIds: string | string[]) {
    const isSingleMeshSelected = this.renderScene.getSelectionManager().getSelected(true)
    if (!isSingleMeshSelected) {
      this.renderScene.getSelectionManager().deselect()
    }

    setTimeout(() => {
      if (Array.isArray(buildPlanItemIds)) {
        buildPlanItemIds.forEach((item) => this.renderScene.selectAndHighlightPartsAfterRemove(item, true))
      } else {
        this.renderScene.selectAndHighlightPartsAfterRemove(buildPlanItemIds, true)
      }
    }, 0) // Tick delay for the render loop
  }

  savePartOrientation(partItemIndex: string) {
    this.renderScene.savePartOrientation(partItemIndex)
  }

  showSlicePolylines(polylines: [], type?: string, polylineMeshName?: string) {
    this.renderScene.addPolylines(polylines, type, polylineMeshName)
  }

  hideSlicePolylines(type?: string, polylineMeshName?: string) {
    this.renderScene.removePolylines(type, polylineMeshName)
  }

  async initBuildPlatformCanvas(canvasId: string, buildPlateId: number) {
    this.buildPlatformScene = new BuildPlatformScene(canvasId)
    await this.buildPlatformScene.createScene(buildPlateId)
    this.initializationPromise.done()
  }

  viewSimulationResults() {
    this.renderScene.viewSimulationResults()
  }

  clearResults() {
    if (this.renderScene) {
      this.renderScene.clearResults()
    }
  }

  async loadPartConfig(part: IPartRenderable) {
    await this.renderScene.loadPartConfig(part)
  }

  async replaceBuildPlanItems(configs: Array<{ partConfig: IPartRenderable; targetBuildPlanItemId: string }>) {
    await this.renderScene.getModelManager().replaceBuildPlanItems(configs)
  }

  async loadDetailsPreviewPartConfig(part: IPartRenderable) {
    await this.renderDetailsPreview.loadPartConfig(part)
    this.renderDetailsPreview
      .getScene()
      .whenReadyAsync()
      .then(() => {
        this.renderDetailsPreview.getScene().render()
      })
  }

  showLoadingPart(loadingPartIndex: number) {
    this.renderScene.showLoadingPart(loadingPartIndex)
  }

  hideLoadingPart(loadingPartIndex: number) {
    this.renderScene.hideLoadingPart(loadingPartIndex)
  }

  updateLoadingPartPosition(loadingPartIndex: number, pointerX: number, pointerY: number) {
    this.renderScene.updateLoadingPartPosition(loadingPartIndex, pointerX, pointerY)
  }

  async saveLoadingPart(loadingPartIndex: number, pointerX: number, pointerY: number) {
    await this.renderScene.saveLoadingPart(loadingPartIndex, pointerX, pointerY)
  }

  async savePartByClick(part: IPartRenderable) {
    await this.renderScene.savePartByClick(part)
  }

  setLoadingPartIndex(loadingPartIndex: number, buildPlanItemId: string) {
    this.renderScene.setLoadingPartIndex(loadingPartIndex, buildPlanItemId)
  }

  setLoadingPartIndices(loadingPartIndices: Array<{ loadingPartIndex: number; buildPlanItemId: string }>) {
    this.renderScene.setLoadingPartIndices(loadingPartIndices)
  }

  async disposeLoadingPart(loadingPartIndex: number) {
    await this.renderScene.disposeLoadingPart(loadingPartIndex)
  }

  async loadBuildPlan(buildPlan: IBuildPlan, options: LoadBuildPlanOptions = {}) {
    await this.renderScene.loadBuildPlan(buildPlan, options)
  }

  async loadIBCPlan(buildPlan: IIBCPlan, options: LoadBuildPlanOptions = {}) {
    await this.renderScene.loadIBCPlan(buildPlan, options)
  }

  async loadMeasurementsForIBCPlan(ibcPlan: IIBCPlan) {
    await this.renderScene.loadMeasurementsForIBCPlan(ibcPlan)
  }

  async deleteMeasurements(ids: string[]) {
    await this.renderScene.deleteMeasurements(ids)
  }

  toggleIBCMeasurement(measurementVisFileId: string, isVisible: boolean) {
    this.renderScene.changeIbcMeasurementsVisibility(measurementVisFileId, isVisible)
  }

  highlightIBCMeasurement(measurementVisFileId: string, showHighlight: boolean) {
    this.renderScene.highlightIBCMeasurement(measurementVisFileId, showHighlight)
  }

  async changeIbcPartVisibility(ibcPlan: IIBCPlan, isVisible: boolean) {
    const prodIbcPlanItem = ibcPlan.ibcPlanItems.find(
      (pi) => pi.geometryType && pi.geometryType === GeometryType.Production,
    )
    if (prodIbcPlanItem) {
      const items = [{ buildPlanItemId: prodIbcPlanItem.id }]
      this.renderScene.setGeometriesVisibility(items, GeometryType.Production, isVisible)
    }
  }

  async changeIbcSupportsVisibility(ibcPlan: IIBCPlan, isVisible: boolean) {
    const supportIbcPlanItems = ibcPlan.ibcPlanItems.filter(
      (pi) => pi.geometryType && pi.geometryType === GeometryType.Support,
    )
    if (supportIbcPlanItems) {
      supportIbcPlanItems.forEach((item) =>
        this.renderScene.setGeometriesVisibility([{ buildPlanItemId: item.id }], GeometryType.Support, isVisible),
      )
    }
  }

  async changeIbcPartAndSupportsVisibility(
    ibcPlan: IIBCPlan,
    isPartVisible: boolean,
    isSupportsVisible: boolean,
    isPartAndSupportsVisible: boolean,
  ) {
    await this.changeIbcPartVisibility(ibcPlan, isPartVisible && isPartAndSupportsVisible)
    await this.changeIbcSupportsVisibility(ibcPlan, isSupportsVisible && isPartAndSupportsVisible)
  }

  async updateBuildPlan(buildPlan: IBuildPlan) {
    await this.renderScene.updateBuildPlan(buildPlan)
  }

  async loadDetailsPreviewBuildPlan(buildPlan: IBuildPlan) {
    await this.renderDetailsPreview.loadBuildPlan(buildPlan)
    this.renderDetailsPreview
      .getScene()
      .whenReadyAsync()
      .then(() => {
        this.renderDetailsPreview.getScene().render()
      })
  }

  async loadBuildPlate(buildPlatePk: VersionablePk, machineConfigPk: VersionablePk, modality: PrintingTypes) {
    return await this.renderScene.loadBuildPlate(buildPlatePk, machineConfigPk, modality, true)
  }

  async loadSinterPlate(buildPlatePk: VersionablePk, machineConfigPk: VersionablePk) {
    return await this.renderScene.loadSinterPlate(buildPlatePk, machineConfigPk)
  }

  deleteParts(partsIds: string[]) {
    this.renderScene.deleteParts(partsIds)
  }

  clearUnfinishedInstances() {
    this.renderScene.clearUnfinishedInstances()
  }

  createVolumeGrid(
    duplicatePayload: DuplicatePayload[],
    duplicatePartsIndependently: boolean = true,
    duplicateMode: DuplicateMode = DuplicateMode.BoundingBoxes,
  ) {
    this.renderScene.createVolumeGrid(duplicatePayload, duplicatePartsIndependently, duplicateMode)
  }

  finishInstancingProcess(payload: { items: IBuildPlanItem[]; singleSelection: boolean }) {
    this.renderScene.finishInstancingProcess(payload)
  }

  cancelDuplicateProcess() {
    this.renderScene.cancelDuplicateProcess()
  }

  applyTransformatonMatrix(buildPlanItemId: string, transformation: number[], options?: object) {
    this.renderScene.applyTransformationMatrix(buildPlanItemId, transformation, options)
  }

  async updateScaleForSinglePart(selectedPartId: string, parameterSetScaleFactor: number[]) {
    await this.initializationPromise.promise
    this.renderScene.updateScaleForSinglePart(selectedPartId, parameterSetScaleFactor)
  }

  applyTransformatonMatrixBatch(buildPlanItems: object[]) {
    this.renderScene.applyTransformationMatrixBatch(buildPlanItems)
  }

  async setGizmoVisibility(isVisible: boolean) {
    if (this.renderScene) {
      await this.renderScene.setGizmoVisibility(isVisible)
    }
  }

  async setSelectionMode(mode: SelectionUnit, options?: { shouldAffectSelectionBox: boolean }) {
    await this.renderScene.setSelectionMode(mode, options)
  }

  async getBuildBoundingBox() {
    await this.initializationPromise.promise
    const result = await this.renderScene.getBuildBoundingBox()
    return result
  }

  getSelectionBoundingBox() {
    return this.renderScene.getSelectionBoundingBox()
  }

  getPartsBoundingBox(buildPlanItemId?: string, includeSupports?: boolean) {
    return this.renderScene && this.renderScene.getPartsBoundingBox(buildPlanItemId, includeSupports)
  }

  getBpItemDimensions(buildPlanItemId: string, includeSupports?: boolean) {
    return this.renderScene.getBpItemDimensions(buildPlanItemId)
  }

  getIsSelectedPartBrep() {
    return this.renderScene.getIsSelectedPartBrep()
  }

  getPartZTranslation(buildPlanItemId: string) {
    return this.renderScene.getPartZTranslation(buildPlanItemId)
  }

  getSelectedParts() {
    return this.renderScene.getSelectedParts()
  }

  getLabelMeshByTrackId(trackId: string) {
    return this.renderScene.getModelManager().labelMgr.getLabelMeshByTrackId(trackId)
  }

  setLabelStyle(style: ILabelStyle) {
    this.renderScene.getModelManager().setLabelStyle(style)
  }

  refreshInsights() {
    this.renderScene.getModelManager().refreshInsights()
    this.renderScene.checkCollision()
  }

  async updateLabelAppearance(
    labelId: string,
    newStyle: ILabelStyle,
    shouldLabelBeSelected: boolean = true,
    updateStore: boolean = true,
  ) {
    await this.renderScene
      .getModelManager()
      .updateLabelAppearance(labelId, newStyle, shouldLabelBeSelected, updateStore)
    const label = this.renderScene.getScene().getMeshByID(labelId)
    if (label) {
      this.renderScene.getModelManager().labelMgr.validateLabel(label)
      this.renderScene.getModelManager().refreshLabelInsights()
    }
  }

  deleteLabel(labelId: string) {
    this.renderScene.getModelManager().deleteLabel(labelId)
  }

  toggleLabelHighlight(labelId: string, highlight: boolean) {
    this.renderScene.getModelManager().toggleLabelHighlight(labelId, highlight)
  }

  activateLabelManualPlacement() {
    this.renderScene.getModelManager().activateLabelManualPlacement()
  }

  deactivateLabelManualPlacement(labelSetId?: string, removeOrigin: boolean = true) {
    this.renderScene.getModelManager().deactivateLabelManualPlacement(labelSetId, removeOrigin)
  }

  showManuallyPlacedLabelOrigins(patches: Placement[], labelSetId?: string) {
    this.renderScene.getModelManager().showManuallyPlacedLabelOrigins(patches, labelSetId)
  }

  activateLabelCreation() {
    this.renderScene.getModelManager().activateLabelCreation()
  }

  deactivateLabelCreation() {
    this.renderScene.getModelManager().deactivateLabelCreation()
  }

  activateLabelInteraction(labelId: string) {
    this.renderScene.getModelManager().activateLabelInteraction(labelId)
  }

  deactivateLabelInteraction(labelId: string) {
    if (this.renderScene) {
      this.renderScene.getModelManager().deactivateLabelInteraction(labelId)
    }
  }

  toggleComponentHighlight(componentId: string, showHighlight: boolean, excludedBPItems: string[]) {
    this.renderScene.getModelManager().toggleComponentHighlight(componentId, showHighlight, excludedBPItems)
  }

  labelInstance(payload: InstanceLabel) {
    this.renderScene.getModelManager().labelInstance(payload)
  }

  async addOverhangMesh(bpItemId: string, meshId: string, config: IOverhangConfig) {
    await this.renderScene.addOverhangMesh(bpItemId, meshId, config)
  }

  updateOverhangMesh(bpItemId: string, meshId: string, overhang: BuildPlanItemOverhang) {
    this.renderScene.updateOverhangMesh(bpItemId, meshId, overhang)
  }

  clearOverhangMesh(bpItemId: string) {
    this.renderScene.clearOverhangMesh(bpItemId)
  }

  setGeometriesVisibility(items: Array<{ buildPlanItemId: string }>, geometryType: GeometryType, visibility: boolean) {
    this.renderScene.setGeometriesVisibility(items, geometryType, visibility)
  }

  setGeometryVisibility(items: Array<{ buildPlanItemId: string; bodyIds?: string[] }>, visibility: boolean) {
    this.renderScene.setGeometryVisibility(items, visibility)
  }

  setIsHiddenForBuildPlanItemMesh(buildPlanItemId: string, makeHidden: boolean, showHiddenAsTransparent: boolean) {
    this.renderScene.setIsHiddenForBuildPlanItemMesh(buildPlanItemId, makeHidden, showHiddenAsTransparent)
  }

  setOverhangMeshVisibility(items: Array<{ buildPlanItemId: string }>, visibility: boolean) {
    this.renderScene.setOverhangMeshVisibility(items, visibility)
  }

  setDefaultOverhangMaterial(bpItemId: string, overhangElementsToClear?: string[]) {
    this.renderScene.setDefaultOverhangMaterial(bpItemId, overhangElementsToClear)
  }

  addSupportMesh(bpItemId: string, sdata: ArrayBuffer, belongsToOverhangElementName: string) {
    this.renderScene.addSupportMesh(bpItemId, sdata, belongsToOverhangElementName)
  }

  addSupportBvhAndHull(bpItemId: string, supportsBvhFileKey: string, supportsHullFileKey: string) {
    this.renderScene.addSupportBvhAndHull(bpItemId, supportsBvhFileKey, supportsHullFileKey)
  }

  loadSupports(
    bpItemId: string,
    supports: BuildPlanItemSupport[],
    supportsBvhFileKey: string,
    supportsHullFileKey: string,
    visibility: Visibility,
  ) {
    this.renderScene.loadSupports(bpItemId, supports, supportsBvhFileKey, supportsHullFileKey, visibility)
  }

  clearSupports(bpItemId: string, overhangElementsToClear?: string[], skipGeomProps?: boolean) {
    this.renderScene.clearSupports(bpItemId, overhangElementsToClear, skipGeomProps)
  }

  setBodiesVisibility(ids: string[], isVisible: boolean) {
    this.renderScene.setBodiesVisibility(ids, isVisible)
  }

  setPartsVisibility(ids: string[], visibility: boolean) {
    this.renderScene.setPartsVisibility(ids, visibility)
  }

  updateGeometriesType(items: Array<{ buildPlanItemId: string; bodyIds: string[] }>, geometryType: GeometryType) {
    this.renderScene.getModelManager().updateGeometriesType(items, geometryType)
  }

  async getBPItemGeometryPropertiesFromCache(payload) {
    return this.renderScene && (await this.renderScene.getBPItemGeometryPropertiesFromCache(payload))
  }

  highlightErrorOverhangZone(bpItemId: string, overhangZoneName?: string) {
    this.renderScene.highlightErrorOverhangZone(bpItemId, overhangZoneName)
  }

  addFailedOverhangZone(bpItemId: string, overhangZoneName: string) {
    this.renderScene.getModelManager().supportMgr.addFailedOverhangZone(bpItemId, overhangZoneName)
  }

  async updateItemPreview(itemId: string) {
    this.renderScene.updateItemPreview(itemId)
  }

  updateConstraints(buildPlanItemId: string, constraints: IConstraints) {
    this.renderScene.updateConstraints(buildPlanItemId, constraints)
  }

  deselect(items: ISelectableNode[] = null, silent?: boolean) {
    this.renderScene.getSelectionManager().deselect(items, silent)
  }

  deselectNonBarGeometry() {
    this.renderScene.getSelectionManager().deselectNonBarGeometry()
  }

  transformSelectedParts(delta: ITransformationDelta, type: ToolTypes) {
    this.renderScene.getSelectionManager().gizmos.transformSelectedParts(delta, type)
  }

  restoreSelectedParts(type: RestoreSelectedPartsType) {
    this.renderScene.getSelectionManager().gizmos.restoreSelectedParts(type)
  }

  async loadInsigths(insights: IBuildPlanInsight[]) {
    await this.initializationPromise.promise
    this.renderScene.loadInsights(insights)
  }

  refreshLabelInsights() {
    this.renderScene.getModelManager().refreshLabelInsights()
  }

  setSelectionModeAndReselect(mode: SelectionUnit) {
    this.renderScene.setSelectionModeAndReselect(mode)
  }

  highlightSupports(buildPlanItemId: string, overhangElementNames: string[]) {
    this.renderScene.highlightSupports(buildPlanItemId, overhangElementNames)
  }

  hoverSupport(buildPlanItemId: string, overhangElementName: string) {
    this.renderScene.hoverSupport(buildPlanItemId, overhangElementName)
  }

  transferSupports(sourceId: string, targetIds: string[]) {
    this.renderScene.transferSupports(sourceId, targetIds)
  }

  setSendBoundingAnchorPoints(shouldSend: boolean) {
    this.renderScene.setSendBoundingAnchorPoints(shouldSend)
  }

  async loadBuildPlanItemsByConfig(buildPlanItems: IBuildPlanItem[]): Promise<void> {
    await this.renderScene.loadBuildPlanItemsByConfig(buildPlanItems)
  }

  resizeCanvas() {
    if (this.renderScene) {
      this.renderScene.resizeCanvas()
    }
  }

  async setMeshesVisibilityByName(name: string, visibility: boolean) {
    await this.renderScene.setMeshesVisibilityByName(name, visibility)
  }

  async setBuildPlateMeshVisibility(visibility: boolean, isSinterPlan: boolean) {
    await this.renderScene.setBuildPlateMeshVisibility(visibility, isSinterPlan)
  }

  zoomToFitCamera() {
    this.renderScene.zoomToFitCamera()
  }

  setIsReadOnly(value: boolean) {
    if (this.renderScene && this.renderScene.getModelManager()) {
      this.renderScene.setIsReadOnly(value)
    }
  }

  highlightBody(id: string, showHighlight: boolean, bpItemId?: string) {
    this.renderScene.highlightBody(id, showHighlight, bpItemId)
  }

  async getSinglePartGeometryProps(buildPlanItemId?: string, includeSupports?: boolean, ignoreCache?: boolean) {
    return (
      this.renderScene &&
      (await this.renderScene.getSinglePartGeometryProps(buildPlanItemId, includeSupports, ignoreCache))
    )
  }

  rebuildSupports() {
    this.renderScene.rebuildSupports()
  }

  updateMoveIncrement(increment: number) {
    this.renderScene.updateMoveIncrement(increment)
  }

  updateRotateIncrement(increment: number) {
    this.renderScene.updateRotateIncrement(increment)
  }

  updateSupports(buildPlanItemId: string, supports: BuildPlanItemSupport[]) {
    this.renderScene.updateSupports(buildPlanItemId, supports)
  }

  updateParameterSetScale(updateBuildPlanItems: Array<{ buildPlanItemId: string; scaling: number[] }>) {
    this.renderScene.getSelectionManager().gizmos.updateParameterSetScale(updateBuildPlanItems)
  }

  toggleDownwardPlaneRotation(isDownwardPlaneRotation: boolean) {
    this.renderScene.getModelManager().toggleDownwardPlaneRotation(isDownwardPlaneRotation)
  }

  flipSelectedParts() {
    this.renderScene.getModelManager().flipSelectedParts()
  }

  async addLabelOnScene(
    id: string,
    buildPlanItemId: string,
    componentId: string,
    geometryId: string,
    labelSetId: string,
    drc: ArrayBuffer,
    isFailed?: boolean,
    orientation?: ILabelOrientation,
    rotationAngle?: number,
    trackId?: string,
  ) {
    return await this.renderScene
      .getModelManager()
      .labelMgr.addLabelOnScene(
        id,
        buildPlanItemId,
        componentId,
        geometryId,
        labelSetId,
        drc,
        isFailed,
        orientation,
        rotationAngle,
        trackId,
      )
  }

  removeLabels(
    options?: Array<{
      labelSetId?: string
      buildPlanItemId?: string
      componentId?: string
      geometryId?: string
      labelId?: string
      trackId?: string
    }>,
  ) {
    return this.renderScene.getModelManager().labelMgr.removeLabels(options)
  }

  removeLabelOrigins(labelIds: string[]) {
    return this.renderScene.getModelManager().labelMgr.removeLabelOrigins(labelIds)
  }

  applyRotationToAllOtherLabels(sourceLabel: ManualPatch, targetLabels: ManualPatch[]) {
    return this.renderScene.getModelManager()
      ? this.renderScene.getModelManager().labelMgr.applyRotationToAllOtherLabels(sourceLabel, targetLabels)
      : null
  }

  highlightLabels(
    options?: Array<{
      labelSetId?: string
      parentId?: string
      componentId?: string
      geometryId?: string
      patchId?: string
      isPrintOrderPreviewLabel: boolean
    }>,
  ) {
    this.renderScene.getModelManager().labelMgr.highlightLabels(options)
  }

  deHighlightLabels(
    options?: Array<{
      labelSetId?: string
      parentId?: string
      componentId?: string
      geometryId?: string
      patchId?: string
      labelSetHighlight?: boolean
      isPrintOrderPreviewLabel: boolean
    }>,
  ) {
    this.renderScene.getModelManager().labelMgr.deHighlightLabels(options)
  }

  selectBodies(
    bodyIds: Array<{ buildPlanItemId: string; componentId: string; geometryId: string }>,
    attach: boolean = false,
  ) {
    this.renderScene.selectBodies(bodyIds, attach)
  }

  selectAllInstances(params: {
    geometryIds: string[]
    labelId: string
    attach?: boolean
    silent?: boolean
    isAutomatic: boolean
    ignoreBodies?: Array<{ buildPlanItemId: string; geometryId: string; componentId: string; id: string }>
  }) {
    this.renderScene.selectAllInstances(params)
  }

  cacheLabelMeshes() {
    this.renderScene.getModelManager().labelMgr.cacheLabelMeshes()
  }

  restoreLabelsFromCache(labelSetIds: string[]) {
    this.renderScene.getModelManager().labelMgr.restoreLabelsFromCache(labelSetIds)
  }

  restoreLabelSetsFromCache(labelSetIds: string[]) {
    this.renderScene.getModelManager().labelMgr.restoreLabelSetsFromCache(labelSetIds)
  }

  clearLabelsCache(force = false) {
    this.renderScene.getModelManager().labelMgr.clearLabelsCache(force)
  }

  changeActiveLabelSet(labelSet: InteractiveLabelSet) {
    this.renderScene.getModelManager().labelMgr.changeActiveLabelSet(labelSet)
  }

  displaySpatialLetterGrid(isVisible: boolean, settings?: GridLetterJSON) {
    this.renderScene.getBuildPlateManager().displaySpatialLetterGrid(isVisible, settings)
  }

  getRenderScene(): RenderScene {
    return this.renderScene
  }

  createCollector(collector: CollectorWithOptionalFilter) {
    this.renderScene.getCollectorManager().createCollector(collector)
  }

  setActiveCollector(id: string) {
    this.renderScene.getCollectorManager().setActiveCollector(id)
  }

  deselectLastCollectorItem(collectorId: string) {
    this.renderScene.getCollectorManager().deselectLastCollectorItem(collectorId)
  }

  deselectAllCollectorItems(collectorId: string) {
    this.renderScene.getCollectorManager().deselectAllCollectorItems(collectorId)
  }

  enableColoringForCollector(collectorId: string) {
    this.renderScene.getCollectorManager().enableColoringForCollector(collectorId)
  }

  disableColoringForCollector(collectorId: string) {
    this.renderScene.getCollectorManager().disableColoringForCollector(collectorId)
  }

  disposeCollectors() {
    this.renderScene.getCollectorManager().disposeCollectors()
  }

  setLabeledBodiesVisibility(activeLabelSetId: string, visibility: boolean) {
    this.renderScene.getModelManager().labelMgr.setLabeledBodiesVisibility(activeLabelSetId, visibility)
  }

  elevatePart(elevationValue: number) {
    this.renderScene.getSelectionManager().gizmos.elevatePart(elevationValue)
  }

  erasePreviousOverhangContour() {
    this.renderScene.getModelManager().overhangMgr.erasePreviousOverhangContour()
  }

  hideManualLabelHandle(force?: boolean) {
    return this.renderScene.getModelManager()
      ? this.renderScene.getModelManager().labelMgr.hideManualLabelHandle(force)
      : null
  }

  updateGeometryProperties(bpItemId: string) {
    return this.renderScene.getModelManager() ? this.renderScene.updateGeometryProperties(bpItemId) : null
  }

  hoverManualLabelSettings() {
    this.renderScene.getModelManager().labelMgr.hoverManualLabelSettings()
  }

  showPartInstability(bpItemId: string) {
    this.renderScene.getModelManager().showPartFootprintAndCG(bpItemId)
  }

  hidePartInstability() {
    this.renderScene.getModelManager().hidePartFootprintAndGC()
  }

  setRotatePartsIndependentlyMode(areRotatePartsIndependently: boolean) {
    this.renderScene.setRotatePartsIndependentlyMode(areRotatePartsIndependently)
  }

  highlightSupport(bpItemId: string, overhangZoneName: string, showHighlight: boolean) {
    this.renderScene.highlightSupport(bpItemId, overhangZoneName, showHighlight)
  }

  showHiddenPartsTransparent() {
    this.renderScene.showHiddenPartsTransparent()
  }

  hideTransparentParts() {
    this.renderScene.hideTransparentParts()
  }

  invalidateLabels(
    payload: Array<{
      labelSetId: string
      id: string
      dirtyState: LabelDirtyState
    }>,
  ) {
    this.renderScene.getModelManager().labelMgr.invalidateLabels(payload)
  }

  markNotCreatedManualLabel(payload: { labelId: string, trackId: string }) {
    this.renderScene.getModelManager().labelMgr.markNotCreatedManualLabel(payload.labelId, payload.trackId)
  }

  private createInitializationPromise() {
    let done: Function
    const promise = new Promise<void>((resolve) => {
      done = resolve
    })

    return { done, promise }
  }
}
