/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Scene } from '@babylonjs/core/scene'
import { Engine } from '@babylonjs/core/Engines/engine'
import { InstancedMesh, Mesh, MeshBuilder, TransformNode } from '@babylonjs/core/Meshes'
import { Vector3, Plane, Color4, Quaternion, Matrix } from '@babylonjs/core/Maths'
import { VisualizationEvent } from '@/visualization/infrastructure/IVisualizationEvent'
import {
  CROSS_SECTION_PLANE_MATERIAL,
  INSIDE_MESH_NAME,
  LABEL,
  MESH_RENDERING_GROUP_ID,
  SINTER_PLATE_NAME,
  GROUND_BOX_NAME,
  CROSS_SECTION_MESH_MATERIAL,
  SECTION_PLANE_HELPER_MESH_NAME,
  SUPPORT_INSIDE_MESH_NAME,
  OVERHANG_INSIDE_MESH_NAME,
  MESH_INSIDE_MATERIAL_NAME,
  SUPPORT_INSIDE_MATERIAL,
  LABEL_INSIDE_MESH_NAME,
  LABEL_INSIDE_MATERIAL,
  CROSS_SECTION_SEMI_TRANSPARENT_MESH_MATERIAL,
  SEMI_TRANSPARENT_INSIDE_MATERIAL_NAME,
  SEMI_TRANSPARENT_SUPPORT_INSIDE_MATERIAL,
} from '@/constants'
import { RenderScene } from '@/visualization/render-scene'
import { IActiveToggle } from '@/visualization/infrastructure/IActiveToggle'
import { CrossSectionGizmo } from '@/visualization/rendering/CrossSectionGizmo'
import { BoundingBox } from '@babylonjs/core/Culling/boundingBox'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { SceneMode } from '@/visualization/types/SceneTypes'
import { SupportViewMode } from '@/visualization/infrastructure/ViewMode'

export class CrossSectionManager {
  private readonly onCrossSectionChange = new VisualizationEvent<number[]>()
  private renderScene: RenderScene
  private engine: Engine
  private scene: Scene
  private isEnabledCrossSectionMode: boolean = false
  private meshManager: MeshManager
  private crossSectionGizmo: CrossSectionGizmo
  private crossSectionPlaneMatrix: Matrix

  constructor(renderScene: RenderScene, dragListeners?: IActiveToggle[]) {
    this.renderScene = renderScene
    this.scene = renderScene.getScene()
    this.meshManager = renderScene.getMeshManager()
    this.engine = renderScene.getScene().getEngine()
    this.crossSectionGizmo = new CrossSectionGizmo(renderScene as RenderScene, this, dragListeners)
  }

  get crossSectionChange() {
    return this.onCrossSectionChange.expose()
  }

  get crossSectionMatrix() {
    return this.crossSectionPlaneMatrix
  }

  get isEnabled() {
    return this.isEnabledCrossSectionMode
  }

  get isSupportViewMode() {
    return this.renderScene.getViewMode() instanceof SupportViewMode
  }

  setCrossSection(payload: { isEnabled: boolean; crossSectionMatrix: number[] }) {
    this.isEnabledCrossSectionMode = payload.isEnabled

    if (!payload.isEnabled) {
      this.clearCrossSection()
      return
    }

    const boundingBox = this.renderScene.getBoundingBoxDetails()
    const bboxX = boundingBox.maxX - boundingBox.minX
    const bboxY = boundingBox.maxY - boundingBox.minY
    const bboxZ = boundingBox.maxZ - boundingBox.minZ
    let width
    let height
    const isSinterPlan = this.renderScene.buildPlanType === ItemSubType.SinterPlan
    const isPreview = this.renderScene.getSceneMode() === SceneMode.PreviewPart
    if (isSinterPlan || isPreview) {
      const plateMeshName = isSinterPlan || isPreview ? SINTER_PLATE_NAME : GROUND_BOX_NAME
      const sinterPlate = this.scene.getMeshByName(plateMeshName)
      const box = sinterPlate.getBoundingInfo().boundingBox
      const xDim = box.extendSizeWorld.x * 2
      const yDim = box.extendSizeWorld.y * 2
      width = Math.max(xDim, yDim)
      height = Math.max(xDim, yDim)
    } else {
      width = Math.max(bboxX, bboxY, bboxZ)
      height = Math.max(bboxX, bboxY, bboxZ)
    }

    const minVector = new Vector3(boundingBox.minX, boundingBox.minY, boundingBox.minZ)
    const maxVector = new Vector3(boundingBox.maxX, boundingBox.maxY, boundingBox.maxZ)
    this.crossSectionGizmo.setGizmoLimits(new BoundingBox(minVector, maxVector))

    const sectionPlaneHelperEdgeColor = new Color4(0, 0.37, 0.72, 1)
    const sectionPlaneHelper = MeshBuilder.CreatePlane(
      SECTION_PLANE_HELPER_MESH_NAME,
      {
        width,
        height,
        sideOrientation: Mesh.DOUBLESIDE,
      },
      this.scene,
    )
    sectionPlaneHelper.material = this.scene.getMaterialByName(CROSS_SECTION_PLANE_MATERIAL)
    sectionPlaneHelper.isPickable = false
    sectionPlaneHelper.renderingGroupId = MESH_RENDERING_GROUP_ID
    sectionPlaneHelper.enableEdgesRendering()
    sectionPlaneHelper.edgesColor = sectionPlaneHelperEdgeColor
    sectionPlaneHelper.edgesWidth = 5
    const stencilPlaneLine1 = MeshBuilder.CreateLines(
      'crossSectionReferenceLine1',
      {
        points: [new Vector3(-width / 2, 0, 0), new Vector3(width / 2, 0, 0)],
        colors: [sectionPlaneHelperEdgeColor, sectionPlaneHelperEdgeColor],
      },
      this.scene,
    )
    const stencilPlaneLine2 = MeshBuilder.CreateLines(
      'crossSectionReferenceLine2',
      {
        points: [new Vector3(0, -height / 2, 0), new Vector3(0, height / 2, 0)],
        colors: [sectionPlaneHelperEdgeColor, sectionPlaneHelperEdgeColor],
      },
      this.scene,
    )
    stencilPlaneLine1.renderingGroupId = stencilPlaneLine2.renderingGroupId = MESH_RENDERING_GROUP_ID
    stencilPlaneLine1.parent = stencilPlaneLine2.parent = sectionPlaneHelper

    // only set initial position if not already stored in crossSectionPlaneMatrix
    if (payload.crossSectionMatrix && payload.crossSectionMatrix.length > 0) {
      this.crossSectionPlaneMatrix = Matrix.FromArray(payload.crossSectionMatrix)
    }
    if (!this.crossSectionPlaneMatrix) {
      const stencilPlanePosition = new Vector3(
        (boundingBox.maxX + boundingBox.minX) / 2,
        (boundingBox.maxY + boundingBox.minY) / 2,
        (boundingBox.maxZ + boundingBox.minZ) / 2,
      )
      sectionPlaneHelper.position = stencilPlanePosition
      sectionPlaneHelper.rotationQuaternion = new Quaternion()
    } else {
      const rotation: Quaternion = Quaternion.Identity()
      const scaling: Vector3 = Vector3.Zero()
      this.crossSectionPlaneMatrix.decompose(scaling, rotation, sectionPlaneHelper.position)
      sectionPlaneHelper.rotationQuaternion = rotation
    }
    sectionPlaneHelper.computeWorldMatrix()

    const crossSectionMeshMaterial = this.scene.getMaterialByName(CROSS_SECTION_MESH_MATERIAL)
    const transparentMaterial = this.scene.getMaterialByName(CROSS_SECTION_SEMI_TRANSPARENT_MESH_MATERIAL)

    const meshesInside = this.scene.meshes.filter((mesh) => mesh.name.includes(INSIDE_MESH_NAME)) as InstancedMesh[]
    const meshesOutside = meshesInside.map((mesh) => mesh.parent) as InstancedMesh[]

    meshesInside.forEach((mesh) => {
      const meshOutside = mesh.parent as InstancedMesh
      mesh.isVisible = meshOutside.isVisible
      if (this.meshManager.isComponentMesh(meshOutside)) {
        mesh.sourceMesh.material = crossSectionMeshMaterial
      } else if (this.meshManager.isComponentCloneMesh(meshOutside)) {
        mesh.sourceMesh.material = transparentMaterial
      }
      mesh.sourceMesh.onBeforeRenderObservable.add(() => {
        this.crossSectionPlaneMatrix = sectionPlaneHelper.getWorldMatrix()
        const planeHelperNormal = sectionPlaneHelper.getFacetNormal(0)
        const a = planeHelperNormal.x
        const b = planeHelperNormal.y
        const c = planeHelperNormal.z
        const x = sectionPlaneHelper.position.x
        const y = sectionPlaneHelper.position.y
        const z = sectionPlaneHelper.position.z
        const d = a * x + b * y + c * z
        this.scene.clipPlane = new Plane(-a, -b, -c, d)
      })
      mesh.sourceMesh.onAfterRenderObservable.add(() => {
        this.scene.clipPlane = null
      })
    })

    meshesOutside.forEach((mesh) => {
      mesh.sourceMesh.onBeforeRenderObservable.add(() => {
        const planeHelperNormal = sectionPlaneHelper.getFacetNormal(0)
        const a = planeHelperNormal.x
        const b = planeHelperNormal.y
        const c = planeHelperNormal.z
        const x = sectionPlaneHelper.position.x
        const y = sectionPlaneHelper.position.y
        const z = sectionPlaneHelper.position.z
        const d = a * x + b * y + c * z
        this.scene.clipPlane = new Plane(-a, -b, -c, d)
      })
      mesh.sourceMesh.onAfterRenderObservable.add(() => {
        this.scene.clipPlane = null
      })
    })

    const supportMeshesInside = this.scene.meshes.filter((mesh) =>
      mesh.name.includes(SUPPORT_INSIDE_MESH_NAME),
    ) as InstancedMesh[]
    const supportMeshesOutside = supportMeshesInside.map((mesh) => mesh.parent) as InstancedMesh[]

    supportMeshesInside.forEach((mesh) => {
      const meshOutside = mesh.parent as Mesh
      mesh.isVisible = meshOutside.isVisible
      if (this.meshManager.isSupportMesh(meshOutside)) {
        mesh.sourceMesh.material = crossSectionMeshMaterial
      } else if (this.meshManager.isSupportCloneMesh(meshOutside)) {
        mesh.sourceMesh.material = transparentMaterial
      }
      mesh.sourceMesh.onBeforeRenderObservable.add(() => {
        const planeHelperNormal = sectionPlaneHelper.getFacetNormal(0)
        const a = planeHelperNormal.x
        const b = planeHelperNormal.y
        const c = planeHelperNormal.z
        const x = sectionPlaneHelper.position.x
        const y = sectionPlaneHelper.position.y
        const z = sectionPlaneHelper.position.z
        const d = a * x + b * y + c * z
        this.scene.clipPlane = new Plane(-a, -b, -c, d)
      })
      mesh.sourceMesh.onAfterRenderObservable.add(() => {
        this.scene.clipPlane = null
      })
    })

    supportMeshesOutside.forEach((mesh) => {
      if (mesh.sourceMesh.edgesRenderer) {
        const saveRender = mesh.sourceMesh.edgesRenderer.render.bind(mesh.sourceMesh.edgesRenderer)
        mesh.sourceMesh.edgesRenderer.render = () => {
          if (sectionPlaneHelper.getFacetLocalNormals().length) {
            const planeHelperNormal = sectionPlaneHelper.getFacetNormal(0)
            const a = planeHelperNormal.x
            const b = planeHelperNormal.y
            const c = planeHelperNormal.z
            const x = sectionPlaneHelper.position.x
            const y = sectionPlaneHelper.position.y
            const z = sectionPlaneHelper.position.z
            const d = a * x + b * y + c * z
            this.scene.clipPlane = new Plane(-a, -b, -c, d)
            saveRender()
            this.scene.clipPlane = null
          } else {
            saveRender()
          }
        }
      }
      mesh.sourceMesh.onBeforeRenderObservable.add(() => {
        const planeHelperNormal = sectionPlaneHelper.getFacetNormal(0)
        const a = planeHelperNormal.x
        const b = planeHelperNormal.y
        const c = planeHelperNormal.z
        const x = sectionPlaneHelper.position.x
        const y = sectionPlaneHelper.position.y
        const z = sectionPlaneHelper.position.z
        const d = a * x + b * y + c * z
        this.scene.clipPlane = new Plane(-a, -b, -c, d)
      })
      mesh.sourceMesh.onAfterRenderObservable.add(() => {
        this.scene.clipPlane = null
      })
    })

    const labelMeshesInside = this.scene.meshes.filter((mesh) =>
      mesh.name.includes(LABEL_INSIDE_MESH_NAME),
    ) as InstancedMesh[]
    const labelMeshesOutside = labelMeshesInside.map((mesh) => mesh.parent) as InstancedMesh[]

    labelMeshesInside.forEach((mesh) => {
      const meshOutside = mesh.parent as InstancedMesh
      mesh.isVisible = meshOutside.isVisible
      if (this.meshManager.isLabelMesh(meshOutside)) {
        mesh.sourceMesh.material = crossSectionMeshMaterial
      } else if (this.meshManager.isLabelCloneMesh(meshOutside)) {
        mesh.sourceMesh.material = transparentMaterial
      }

      mesh.sourceMesh.onBeforeRenderObservable.add(() => {
        const planeHelperNormal = sectionPlaneHelper.getFacetNormal(0)
        const a = planeHelperNormal.x
        const b = planeHelperNormal.y
        const c = planeHelperNormal.z
        const x = sectionPlaneHelper.position.x
        const y = sectionPlaneHelper.position.y
        const z = sectionPlaneHelper.position.z
        const d = a * x + b * y + c * z
        this.scene.clipPlane = new Plane(-a, -b, -c, d)
      })
      mesh.sourceMesh.onAfterRenderObservable.add(() => {
        this.scene.clipPlane = null
      })
    })

    labelMeshesOutside.forEach((mesh) => {
      mesh.sourceMesh.onBeforeRenderObservable.add(() => {
        const planeHelperNormal = sectionPlaneHelper.getFacetNormal(0)
        const a = planeHelperNormal.x
        const b = planeHelperNormal.y
        const c = planeHelperNormal.z
        const x = sectionPlaneHelper.position.x
        const y = sectionPlaneHelper.position.y
        const z = sectionPlaneHelper.position.z
        const d = a * x + b * y + c * z
        this.scene.clipPlane = new Plane(-a, -b, -c, d)
      })
      mesh.sourceMesh.onAfterRenderObservable.add(() => {
        this.scene.clipPlane = null
      })
    })

    const overhangMeshesInside = this.scene.meshes.filter((mesh) =>
      mesh.name.includes(OVERHANG_INSIDE_MESH_NAME),
    ) as InstancedMesh[]
    const overhangMeshesOutside = overhangMeshesInside.map((mesh) => mesh.parent) as InstancedMesh[]

    if (this.isSupportViewMode) {
      overhangMeshesInside.forEach((mesh) => {
        mesh.isVisible = false
        mesh.sourceMesh.onBeforeRenderObservable.add(() => {
          const planeHelperNormal = sectionPlaneHelper.getFacetNormal(0)
          const a = planeHelperNormal.x
          const b = planeHelperNormal.y
          const c = planeHelperNormal.z
          const x = sectionPlaneHelper.position.x
          const y = sectionPlaneHelper.position.y
          const z = sectionPlaneHelper.position.z
          const d = a * x + b * y + c * z
          this.scene.clipPlane = new Plane(-a, -b, -c, d)
        })
        mesh.sourceMesh.onAfterRenderObservable.add(() => {
          this.scene.clipPlane = null
        })
      })

      overhangMeshesOutside.forEach((mesh) => {
        mesh.isVisible = false
        mesh.sourceMesh.onBeforeRenderObservable.add(() => {
          const planeHelperNormal = sectionPlaneHelper.getFacetNormal(0)
          const a = planeHelperNormal.x
          const b = planeHelperNormal.y
          const c = planeHelperNormal.z
          const x = sectionPlaneHelper.position.x
          const y = sectionPlaneHelper.position.y
          const z = sectionPlaneHelper.position.z
          const d = a * x + b * y + c * z
          this.scene.clipPlane = new Plane(-a, -b, -c, d)
        })
        mesh.sourceMesh.onAfterRenderObservable.add(() => {
          this.scene.clipPlane = null
        })
      })
    }

    this.crossSectionGizmo.show(sectionPlaneHelper)
  }

  recenterCrossSection() {
    const boundingBox = this.renderScene.getBoundingBoxDetails()
    const stencilPlanePosition = new Vector3(
      (boundingBox.maxX + boundingBox.minX) / 2,
      (boundingBox.maxY + boundingBox.minY) / 2,
      (boundingBox.maxZ + boundingBox.minZ) / 2,
    )
    const sectionPlaneHelper = this.scene.getMeshByName(SECTION_PLANE_HELPER_MESH_NAME)
    sectionPlaneHelper.position = stencilPlanePosition
    sectionPlaneHelper.computeWorldMatrix()
    this.saveCrossSection()
  }

  axisAlignCrossSection() {
    const sectionPlaneHelper = this.scene.getMeshByName(SECTION_PLANE_HELPER_MESH_NAME)
    sectionPlaneHelper.rotationQuaternion = new Quaternion()
    sectionPlaneHelper.computeWorldMatrix()
    this.saveCrossSection()
  }

  updateGizmoScale() {
    this.crossSectionGizmo.updateGizmoScale()
  }

  saveCrossSection() {
    const isPreview = this.renderScene.getSceneMode() === SceneMode.PreviewPart
    if (!isPreview) {
      const sectionPlaneHelper = this.scene.getMeshByName(SECTION_PLANE_HELPER_MESH_NAME)
      this.crossSectionPlaneMatrix = sectionPlaneHelper.getWorldMatrix()
      const crossSectionMatrix = []
      this.crossSectionMatrix.asArray().forEach((item) => crossSectionMatrix.push(item))
      this.crossSectionChange.trigger(crossSectionMatrix)
    }
  }

  clearCrossSection() {
    const sectionPlaneHelper = this.scene.getMeshByName(SECTION_PLANE_HELPER_MESH_NAME)
    if (sectionPlaneHelper) {
      sectionPlaneHelper.dispose()
    }

    const meshInsideMaterial = this.scene.getMaterialByName(MESH_INSIDE_MATERIAL_NAME)
    const transparentInsideMaterial = this.scene.getMaterialByName(SEMI_TRANSPARENT_INSIDE_MATERIAL_NAME)

    const meshesInside = this.scene.meshes.filter((mesh) => {
      return mesh.name.includes(INSIDE_MESH_NAME) && !mesh.name.includes(SUPPORT_INSIDE_MESH_NAME)
    }) as InstancedMesh[]
    const meshesOutside = meshesInside.map((mesh) => mesh.parent) as InstancedMesh[]

    meshesInside.forEach((mesh) => {
      const meshOutside = mesh.parent as InstancedMesh
      mesh.isVisible = false
      if (this.meshManager.isComponentMesh(meshOutside)) {
        mesh.sourceMesh.material = meshInsideMaterial
      } else if (this.meshManager.isComponentCloneMesh(meshOutside)) {
        mesh.sourceMesh.material = transparentInsideMaterial
      }
      mesh.sourceMesh.onBeforeRenderObservable.clear()
      mesh.sourceMesh.onAfterRenderObservable.clear()
      this.scene.clipPlane = null
    })

    meshesOutside.forEach((mesh) => {
      mesh.sourceMesh.onBeforeRenderObservable.clear()
      mesh.sourceMesh.onAfterRenderObservable.clear()
      this.scene.clipPlane = null
    })

    const overhangMeshesInside = this.scene.meshes.filter((mesh) =>
      mesh.name.includes(OVERHANG_INSIDE_MESH_NAME),
    ) as InstancedMesh[]
    const overhangMeshesOutside = overhangMeshesInside.map((mesh) => mesh.parent) as InstancedMesh[]

    if (this.isSupportViewMode) {
      overhangMeshesInside.forEach((mesh) => {
        mesh.isVisible = true
        mesh.sourceMesh.onBeforeRenderObservable.clear()
        mesh.sourceMesh.onAfterRenderObservable.clear()
        this.scene.clipPlane = null
      })

      overhangMeshesOutside.forEach((mesh) => {
        mesh.isVisible = true
        mesh.sourceMesh.onBeforeRenderObservable.clear()
        mesh.sourceMesh.onAfterRenderObservable.clear()
        this.scene.clipPlane = null
      })
    }

    const supportMeshInsideMaterial = this.scene.getMaterialByName(SUPPORT_INSIDE_MATERIAL)
    const supportTransparentInsideMaterial = this.scene.getMaterialByName(SEMI_TRANSPARENT_SUPPORT_INSIDE_MATERIAL)

    const supportMeshesInside = this.scene.meshes.filter((mesh) =>
      mesh.name.includes(SUPPORT_INSIDE_MESH_NAME),
    ) as InstancedMesh[]
    const supportMeshesOutside = supportMeshesInside.map((mesh) => mesh.parent) as InstancedMesh[]

    supportMeshesInside.forEach((mesh) => {
      mesh.isVisible = false
      const meshOutside = mesh.parent as Mesh
      if (this.meshManager.isSupportMesh(meshOutside)) {
        mesh.sourceMesh.material = supportMeshInsideMaterial
      } else if (this.meshManager.isSupportCloneMesh(meshOutside)) {
        mesh.sourceMesh.material = supportTransparentInsideMaterial
      }
      mesh.sourceMesh.onBeforeRenderObservable.clear()
      mesh.sourceMesh.onAfterRenderObservable.clear()
      this.scene.clipPlane = null
    })

    supportMeshesOutside.forEach((mesh) => {
      mesh.sourceMesh.onBeforeRenderObservable.clear()
      mesh.sourceMesh.onAfterRenderObservable.clear()
      this.scene.clipPlane = null
    })

    const labelMeshInsideMaterial = this.scene.getMaterialByName(LABEL_INSIDE_MATERIAL)

    const labelMeshesInside = this.scene.meshes.filter((mesh) =>
      mesh.name.includes(LABEL_INSIDE_MESH_NAME),
    ) as InstancedMesh[]
    const labelMeshesOutside = labelMeshesInside.map((mesh) => mesh.parent) as InstancedMesh[]

    labelMeshesInside.forEach((mesh) => {
      const meshOutside = mesh.parent as InstancedMesh
      mesh.isVisible = false
      if (this.meshManager.isLabelMesh(meshOutside)) {
        mesh.sourceMesh.material = labelMeshInsideMaterial
      } else if (this.meshManager.isLabelCloneMesh(meshOutside)) {
        mesh.sourceMesh.material = transparentInsideMaterial
      }

      mesh.sourceMesh.onBeforeRenderObservable.clear()
      mesh.sourceMesh.onAfterRenderObservable.clear()
      this.scene.clipPlane = null
    })

    labelMeshesOutside.forEach((mesh) => {
      mesh.sourceMesh.onBeforeRenderObservable.clear()
      mesh.sourceMesh.onAfterRenderObservable.clear()
      this.scene.clipPlane = null
    })

    this.crossSectionPlaneMatrix = null
    this.crossSectionGizmo.hide()

    this.renderScene.animate()
  }

  dispose() {
    this.crossSectionGizmo.dispose()
  }
}
