import * as Sentry from '@sentry/vue'

export default {
  captureMessage(message: string) {
    Sentry.captureMessage(message)
  },

  captureError(err: Error) {
    Sentry.captureException(err)
  },
}
