export enum PaginationKeyword {
  Sort = 'sort',
  Order = 'order',
  Filter = 'filter', // field for filtering
  FilterItems = 'filterItems', // this field contains element for filtering in filter field
  Search = 'search',
  Limit = 'limit',
  Offset = 'offset',
  Page = 'page',
}
