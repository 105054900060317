
import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'

import DetailsPreview from '@/components/layout/FileExplorer/Details/DetailsPreview.vue'
import VariantIsLockedModal from '@/components/layout/FileExplorer/VariantIsLockedModal.vue'
import FallbackImage from '@/components/layout/FallbackImage.vue'
import DetailsUsedByBuildOrSinterPlansList from '@/components/layout/FileExplorer/Details/DetailsUsedByBuildOrSinterPlansList.vue'
import StoresNamespaces from '@/store/namespaces'
import { PartDetailsDto } from '@/types/FileExplorer/ItemDetails'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { IGeometryProperties, IPart } from '@/types/BuildPlans/IBuildPlan'
import ModalsStateMixin from '../Table/mixins/ModalsStateMixin'
import { formatDecimal } from '@/utils/number'
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo'
import { ActionDateByUserMixin } from '@/components/layout/mixins/ActionDateByUserMixin'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const commonStore = namespace(StoresNamespaces.Common)

interface IMixinInterface extends ModalsStateMixin, ActionDateByUserMixin {}

@Component({
  components: {
    DetailsPreview,
    VariantIsLockedModal,
    FallbackImage,
    DetailsUsedByBuildOrSinterPlansList,
  },
})
export default class DetailsPart extends Mixins<IMixinInterface>(ModalsStateMixin, ActionDateByUserMixin) {
  @fileExplorerStore.Getter hasRunningImportJob: (id: string) => boolean
  @fileExplorerStore.Getter hasFailedJobs: (id: string) => boolean
  @visualizationStore.Getter getPartsBoundingBox: () => Promise<BoundingInfo>
  @commonStore.Getter tooltipOpenDelay: number

  @fileExplorerStore.Action getItemPathNames: Function

  @Prop() item: FileExplorerItem
  @Prop() partDetails: PartDetailsDto
  @Prop() dimensions: IGeometryProperties
  @Prop({ default: false }) hidePreview: boolean
  @Prop({ default: true }) hidePath: boolean
  @Prop({ default: false }) disableHyperlinks: boolean

  buildPlanSubType = ItemSubType.None
  sinterPlanSubType = ItemSubType.SinterPlan
  itemPath = []
  detailsData: PartDetailsDto = null

  async beforeMount() {
    this.detailsData = this.partDetails ? this.partDetails : (this.itemDetails as PartDetailsDto)
  }

  async mounted() {
    if (!this.hidePath) {
      this.itemPath = await this.getItemPathNames(this.item)
    }
  }

  share() {
    this.$emit('share')
  }

  get itemHasFailedJobs() {
    return this.hasFailedJobs(this.item.id)
  }

  get colaboratorsInfo(): string {
    const collaboratorsCount = this.itemDetails.collaborators.length
    return `${collaboratorsCount} ${
      collaboratorsCount !== 1 ? this.$i18n.t('collaborators') : this.$i18n.t('collaborator')
    }`
  }

  async partDimensionsFormatted() {
    let dimensions: string
    const boundingInfo = await this.getPartsBoundingBox()
    if (boundingInfo.boundingBox.minimum && boundingInfo.boundingBox.maximum) {
      const minimum = boundingInfo.boundingBox.minimum
      const maximum = boundingInfo.boundingBox.maximum
      const x = formatDecimal(Math.abs(maximum.x - minimum.x))
      const y = formatDecimal(Math.abs(maximum.y - minimum.y))
      const z = formatDecimal(Math.abs(maximum.z - minimum.z))
      dimensions = `${x} X ${y} X ${z} (mm)`
    } else {
      dimensions = `N/A`
    }

    return dimensions
  }
}
