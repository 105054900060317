
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'

import { BuildDuration, BuildPlanTime } from '@/types/BuildPlans/IBuildPlan'
import StoresNamespaces from '@/store/namespaces'
import { BUILD_TIME_LIMIT_DAYS } from '@/constants'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const commonStore = namespace(StoresNamespaces.Common)

const DEFAULT_BUILD_TIME_LABEL = '-'

@Component
export default class BuildTimeLabel extends Vue {
  @buildPlansStore.Getter getCalcCostInProgress: boolean
  @buildPlansStore.Getter getBuildPlanTime: BuildPlanTime | null

  @commonStore.Getter tooltipOpenDelay: number

  get renderBuildTime(): string {
    if (this.getBuildPlanTime === null || !this.isBuildTimeValid) {
      return DEFAULT_BUILD_TIME_LABEL
    }

    const { min, max } = this.getBuildPlanTime
    return this.formatTimeRange(min, max)
  }

  get isBuildTimeValid(): boolean {
    const { min, max } = this.getBuildPlanTime
    const maxDays = Math.floor(Math.max(min, max) / 24)

    return maxDays < BUILD_TIME_LIMIT_DAYS
  }

  get isShowTooltip(): boolean {
    return this.getBuildPlanTime && !this.isBuildTimeValid
  }

  formatTimeRange(min: number = 0, max: number = 0): string {
    const minDuration = this.convertHoursToDuration(min)
    const maxDuration = this.convertHoursToDuration(max)

    // If both ends of the range are only in minutes, then hours will not be displayed (for example: 4m - 10m).
    const isMinutesOnly = [minDuration, maxDuration].every(
      (duration) => duration.days === 0 && duration.hours === 0 && duration.minutes !== 0,
    )
    if (isMinutesOnly) {
      return this.formatMinutes(minDuration, maxDuration)
    }

    // When the minimum time and maximum time have different most significant units,
    // then 3 values will be displayed (for example: 0d 17h 38m – 1d 2h 22m).
    const isDifferentUnits = [minDuration, maxDuration].some((duration, i, arr) => duration.days !== arr[0].days)
    if (isDifferentUnits) {
      return this.formatDaysHoursMinutes(minDuration, maxDuration)
    }

    // By default, the system shall display the 2 most significant values; i.e. either hours and minutes,
    // or days and hours (for example: 4h 5m – 10h 29m; 3d 19h - 4d 3h).
    const isHoursMinutes = minDuration.days === 0
    if (isHoursMinutes) {
      return this.formatHoursMinutes(minDuration, maxDuration)
    }
    return this.formatDaysHours(minDuration, maxDuration)
  }

  private formatMinutes(minDuration: BuildDuration, maxDuration: BuildDuration): string {
    if (minDuration.minutes === maxDuration.minutes) {
      return `${minDuration.minutes}m`
    }

    return `${minDuration.minutes}m - ${maxDuration.minutes}m`
  }

  private formatHoursMinutes(minDuration: BuildDuration, maxDuration: BuildDuration): string {
    const minStr = `${minDuration.hours}h ${minDuration.minutes}m`
    const maxStr = `${maxDuration.hours}h ${maxDuration.minutes}m`

    if (minStr === maxStr) {
      return minStr
    }

    return `${minStr} - ${maxStr}`
  }

  private formatDaysHours(minDuration: BuildDuration, maxDuration: BuildDuration): string {
    // When minutes are not displayed, hours will be rounded:
    // Up, for 30-59 minutes
    // Down, for 0-29 minutes
    const minStr = `${minDuration.days}d ${minDuration.hours + Math.round(minDuration.minutes / 60)}h`
    const maxStr = `${minDuration.days}d ${maxDuration.hours + Math.round(maxDuration.minutes / 60)}h`

    if (minStr === maxStr) {
      return minStr
    }

    return `${minStr} - ${maxStr}`
  }

  private formatDaysHoursMinutes(minDuration: BuildDuration, maxDuration: BuildDuration): string {
    const minStr = `${minDuration.days}d ${minDuration.hours}h ${minDuration.minutes}m`
    const maxStr = `${maxDuration.days}d ${maxDuration.hours}h ${maxDuration.minutes}m`

    if (minStr === maxStr) {
      return minStr
    }

    return `${minStr} - ${maxStr}`
  }

  private convertHoursToDuration(numOfHours: number): BuildDuration {
    const days = Math.floor(numOfHours / 24)
    const remainder = numOfHours % 24
    const hours = Math.floor(remainder)
    const minutes = Math.floor((remainder - hours) * 60)

    return { days, hours, minutes }
  }
}
