import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { BuildPlanTool, ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { IBCPlanTool, IBCPlanToolNames } from '@/components/layout/IBCPlan/IBCPlanSidebarTools'
import { IJob, JobType } from '@/types/PartsLibrary/Job'

@Component
export class BadgesMixin extends Vue {
  @Prop({ required: true }) tool: BuildPlanTool | IBCPlanTool
  @Prop({ required: true }) runningJobs: IJob[]
  @Prop({ required: true }) failedJobs: IJob[]
  @Prop({ required: true }) completedJobs: IJob[]
  @Prop({ required: true }) warningJobs: IJob[]

  get shouldShowSuccessIcon() {
    if (this.tool.key === ToolNames.SIMULATE || this.tool.key === IBCPlanToolNames.DEVIATION_COMPENSATE) {
      // don't show completion icon for finished MESH jobs
      const completedJobsWithoutMesh = this.completedJobs.filter((job) => job.jobType !== JobType.MESH)
      if (completedJobsWithoutMesh.length > 0) {
        if (this.failedJobs.length > 0) {
          return this.failedJobs[0].updatedDate < this.completedJobs[0].updatedDate
        }

        return true
      }
    }
    return false
  }

  get shouldShowFailedJobInfo() {
    if (this.tool.key !== ToolNames.PUBLISH) {
      if (this.failedJobs.length > 0) {
        const completedJobsOfSameType = this.completedJobs.filter((job) => job.jobType === this.failedJobs[0].jobType)
        if (completedJobsOfSameType.length > 0) {
          return this.failedJobs[0].updatedDate > completedJobsOfSameType[0].updatedDate
        }

        return true
      }
    }
    return false
  }

  get shouldShowWarningIcon() {
    return this.warningJobs.length > 0
  }

  get job() {
    return this.shouldShowWarningIcon
      ? this.warningJobs[0]
      : this.shouldShowSuccessIcon
      ? this.completedJobs[0]
      : this.failedJobs[0]
  }
}
