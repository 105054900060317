// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core'
// Import bar charts, all suffixed with Chart
import { LineChart, HeatmapChart, ParallelChart } from 'echarts/charts'
import { SurfaceChart, Line3DChart } from 'echarts-gl/charts'
import { Grid3DComponent } from 'echarts-gl/components'
// Import the tooltip, title, rectangular coordinate system, dataset and transform components
// all suffixed with Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  MarkLineComponent,
  VisualMapComponent,
  ParallelComponent,
} from 'echarts/components'
// Features like Universal Transition and Label Layout
import { LabelLayout, UniversalTransition } from 'echarts/features'
// Import the Canvas renderer
// Note that introducing the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  MarkLineComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
  HeatmapChart,
  CanvasRenderer,
  VisualMapComponent,
  SurfaceChart,
  Grid3DComponent,
  Line3DChart,
  ParallelComponent,
  ParallelChart,
  SVGRenderer,
])

export default echarts
