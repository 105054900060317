import { render, staticRenderFns } from "./TrashListRow.vue?vue&type=template&id=1c9dadee&scoped=true"
import script from "./TrashListRow.vue?vue&type=script&lang=ts"
export * from "./TrashListRow.vue?vue&type=script&lang=ts"
import style0 from "./TrashListRow.vue?vue&type=style&index=0&id=1c9dadee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c9dadee",
  null
  
)

export default component.exports