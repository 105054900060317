
import Component from 'vue-class-component'
import { Prop, Mixins } from 'vue-property-decorator'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'

import TableRowMixin from '@/components/layout/FileExplorer/Table/mixins/TableRowMixin'
import ItemListRowBadges from '@/components/layout/FileExplorer/Table/ItemListRowBadges.vue'
import RowItemMenu from '../RowItemMenu.vue'
import { ContextMenuItem } from '@/types/FileExplorer/ContextMenuItem'

@Component({
  components: { ItemListRowBadges, RowItemMenu },
})
export default class TrashListRow extends Mixins(TableRowMixin) {
  @Prop() item: FileExplorerItem
  @Prop() contextMenuItems: ContextMenuItem[]

  hoveredOverRow: boolean = false

  $refs: {
    rowItemMenu: RowItemMenu
  }

  onItemRightClick(event) {
    this.$refs.rowItemMenu.showContextMenu(event, false)
  }
}
