import { FileExplorerItem } from './FileExplorerItem'

export interface CanBeTrashedRelateToPrintOrdersResult {
  status: CanBeTrashedStatus
  relatedItems?: FileExplorerItem[]
  unavailableItemsToTrash?: FileExplorerItem[]
  reasons?: Array<{
    trashItem?: FileExplorerItem
    items?: FileExplorerItem[]
    trashItemVariants?: FileExplorerItem[]
  }>
}

export interface CanBeTrashedPermissionResult {
  status: CanBeTrashedStatus
  selectedUnavailableItems: FileExplorerItem[]
  relatedUnavailableItems: FileExplorerItem[]
}

export interface CanBeTrashedResult {
  permissionsResult: CanBeTrashedPermissionResult
  relateToPrintOrdersResult: CanBeTrashedRelateToPrintOrdersResult
  relatedItems?: FileExplorerItem[]
}

export enum CanBeTrashedStatus {
  Success,
  NoPermission,
  WasPrinted,
  VariantWasPrinted,
}
