import notificationsApi from '@/api/notifications'
import { INotificationState } from '@/store/modules/notifications/types'
import { IRootState } from '@/store/types'
import { ActionTree } from 'vuex'

export const actions: ActionTree<INotificationState, IRootState> = {
  async fetchAllNotifications({ commit, getters }) {
    commit('setIsLoading', true)

    const { getTotalPages, getCurrentPageIndex, getPageSize, getSelectedNotificationType } = getters

    if (getTotalPages && getTotalPages < getCurrentPageIndex) {
      return
    }

    const searchQuery = getSearchQuery(getCurrentPageIndex + 1, getPageSize, getSelectedNotificationType.value)

    const response = await notificationsApi.getAllNotifications(searchQuery)

    if (!response) {
      return
    }

    const {
      lastNotificationTime,
      data: items,
      meta: { totalPages, currentPage },
    } = response

    commit('changeCurrentPage', currentPage)
    commit('updateTotalPages', totalPages)

    commit('appendNotifications', items)
    commit('setLastNotificationTime', lastNotificationTime)

    commit('setIsLoading', false)
  },

  async readNotification({ commit }, notificationId: string) {
    await notificationsApi.readNotification(notificationId)
    commit('readNotification', notificationId)
  },

  async updateLastNotificationTime({ commit }, time: string) {
    if (time) {
      await notificationsApi.updateLastNotificationTime(time)
      commit('setLastNotificationTime', time)
    }
  },

  async clearPaginatedData({ commit }) {
    commit('clearPaginationData')
  },
}

function getSearchQuery(page: number, size: number, notificationType: string) {
  const query = `?page=${page}&size=${size}&jobType=${notificationType}`
  return query
}
