import { PrintingTypes } from '@/types/IMachineConfig'
import { CostEvaluationResultViewModel } from '@/types/CostEstimations/ICostEstimation'
import { IAdapter } from '@/types/Common/IAdapter'
import { BJResultAdapter } from './BJResultAdapter'
import { DMLMResultAdapter } from './DMLMResultAdapter'
import { EBMResultAdapter } from './EBMResultAdapter'

export class CostEvaluationResultAdapterFactory {
  static getAdapter(type: PrintingTypes): IAdapter<CostEvaluationResultViewModel> | never {
    switch (type) {
      case PrintingTypes.DMLM:
        return new DMLMResultAdapter()
      case PrintingTypes.BinderJet:
        return new BJResultAdapter()
      case PrintingTypes.EBM:
        return new EBMResultAdapter()
      default:
        throw new Error(`Missing Cost Evaluation Result adapter for printing type (${type})`)
    }
  }
}
