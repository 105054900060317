
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import Button from '@/components/controls/Common/Button.vue'
import {
  CanBeTrashedPermissionResult,
  CanBeTrashedRelateToPrintOrdersResult,
  CanBeTrashedResult,
  CanBeTrashedStatus,
} from '@/types/FileExplorer/CanBeTrashed'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { getItemTypeName } from '@/utils/fileExplorerItem/fileExplorerItemUtils'
import { DEFAULT_IS_ALLOWED_TO_BE_TRASHED_SELECTED_ITEMS } from '@/constants'
import { ItemType } from '@/types/FileExplorer/ItemType'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: {
    Button,
  },
  methods: {
    getItemTypeName,
  },
})
export default class MoveToTrashItemErrorModal extends Vue {
  @fileExplorerStore.Getter isLoading: boolean
  @fileExplorerStore.Mutation setSelectedItemsCanBeTrashed: (result: CanBeTrashedResult) => void

  @Prop({ default: false }) isDeleteError: boolean
  @Prop({ default: () => [] }) canBeTrashedResult: CanBeTrashedResult

  isVisible: boolean = true
  reasonIndex = 0
  currentCanBeTrashedItem: CanBeTrashedPermissionResult | CanBeTrashedRelateToPrintOrdersResult = null

  get status() {
    return this.currentCanBeTrashedItem.status
  }

  get reasons() {
    return this.canBeTrashedResult.relateToPrintOrdersResult.reasons
  }

  get isLastReason() {
    if (this.areNoPermissionErrors) {
      return this.canBeTrashedResult.relateToPrintOrdersResult.status === CanBeTrashedStatus.Success
    }

    return this.reasonIndex >= this.reasons.length - 1
  }

  get nextReasonButtonText() {
    return this.isLastReason ? this.$t('close') : this.$t('continue')
  }

  get header() {
    return this.status === CanBeTrashedStatus.VariantWasPrinted || this.isDeleteError
      ? this.$t('delete')
      : this.$t('trash')
  }

  get areNoPermissionErrors() {
    return this.status === CanBeTrashedStatus.NoPermission
  }

  get noPermissionItems() {
    return this.canBeTrashedResult.permissionsResult.selectedUnavailableItems
  }

  get noPermissionRelatedItems() {
    return this.canBeTrashedResult.permissionsResult.relatedUnavailableItems
  }

  get currentReasonItems() {
    if (!this.reasons[this.reasonIndex] || !this.reasons[this.reasonIndex].items) {
      return []
    }

    return this.reasons[this.reasonIndex].items
  }

  get currentReasonTrashItem() {
    return this.reasons[this.reasonIndex] && this.reasons[this.reasonIndex].trashItem
  }

  get bpOrVariantIsUsedByPrintedItemsMassage() {
    const itemType = getItemTypeName(this.currentReasonTrashItem)
    const itemName = this.currentReasonTrashItem.name

    if (this.currentReasonTrashItem.itemType === ItemType.Folder) {
      return `${itemType} ${itemName} ${this.$t('trashErrorFolderContains')}:`
    }

    return this.status === CanBeTrashedStatus.VariantWasPrinted
      ? `${this.$t('variant')} ${itemName} ${this.$t('trashErrorVariantUsedBy')}:`
      : `${itemType} ${itemName} ${this.$t('trashErrorItemUsedBy')}:`
  }

  get bpOrVariantIsUsedByPrintedItemMassage() {
    const reasonType = getItemTypeName(this.currentReasonItems[0])
    const reasonName = this.currentReasonItems[0].name
    const itemType = getItemTypeName(this.currentReasonTrashItem)
    const itemName = this.currentReasonTrashItem.name

    if (this.currentReasonTrashItem.itemType === ItemType.Folder) {
      return `${itemType} ${itemName} ${this.$t('trashErrorFolderContains')} ${reasonType} ${reasonName}.`
    }

    return this.status === CanBeTrashedStatus.VariantWasPrinted
      ? `${this.$t('variant')} ${itemName} ${this.$t('trashErrorVariantUsedBy')} ${reasonType} ${reasonName}.`
      : `${itemType} ${itemName} ${this.$t('trashErrorItemUsedBy')} ${reasonType} ${reasonName}.`
  }

  get bpOrVariantWasPrintedMassage() {
    const itemType = getItemTypeName(this.currentReasonTrashItem)
    const itemName = this.currentReasonTrashItem.name

    return this.status === CanBeTrashedStatus.VariantWasPrinted
      ? `${this.$t('variant')} ${itemName} ${this.$t('trashErrorVariantHasBeenPrinted')}`
      : `${itemType} ${itemName} ${this.$t('trashErrorItemHasBeenPrinted')}`
  }

  beforeMount() {
    this.currentCanBeTrashedItem = this.canBeTrashedResult.permissionsResult
    if (this.status === CanBeTrashedStatus.Success) this.nextCanBeTrashedItem()
  }

  nextCanBeTrashedItem() {
    if (this.currentCanBeTrashedItem === this.canBeTrashedResult.relateToPrintOrdersResult) {
      this.$emit('onClose')
      return
    }

    this.currentCanBeTrashedItem = this.canBeTrashedResult.relateToPrintOrdersResult
    this.reasonIndex = 0

    if (this.currentCanBeTrashedItem.status === CanBeTrashedStatus.Success) {
      this.$emit('onClose')
      return
    }

    for (const reason of this.reasons) {
      this.sortItems(reason.items)
    }
  }

  nextReason() {
    if (this.areNoPermissionErrors) {
      this.nextCanBeTrashedItem()
      return
    }

    if (this.reasonIndex + 1 >= this.reasons.length) {
      this.nextCanBeTrashedItem()
      return
    }

    this.reasonIndex += 1
  }

  beforeDestroy() {
    this.setSelectedItemsCanBeTrashed(DEFAULT_IS_ALLOWED_TO_BE_TRASHED_SELECTED_ITEMS)
  }

  private sortItems(items: FileExplorerItem[]) {
    if (!items) {
      return
    }

    items
      .sort((a, b) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()

        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        return 0
      })
      .sort((a, b) => {
        return a.itemType - b.itemType
      })
  }
}
