
import {
  BuildPlanItemSupport,
  BuildPlanItemSupportSettings,
  IPrintStrategyParameterSet,
} from '@/types/BuildPlans/IBuildPlan'
import { Component, Prop, Vue } from 'vue-property-decorator'
import BodyDropdownMenu from '@/components/layout/buildPlans/addPart/BodyDropdownMenu.vue'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { SupportTypes } from '@/types/BuildPlans/IBuildPlanItemSettings'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { defaultNameBasedOnSupportType, getSupportDefaultBasedOnType } from '@/utils/parameterSet/parameterSetUtils'
import { VersionableModel } from '@/types/Common/VersionableModel'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { convert } from '@/utils/converter/lengthConverter'
import { BodyTypeIcons } from '@/constants'

const commonStore = namespace(StoresNamespaces.Common)
const buildPlanStore = namespace(StoresNamespaces.BuildPlans)

@Component({
  components: {
    BodyDropdownMenu,
  },
})
export default class SupportPropertyListItem extends Vue {
  @commonStore.Getter tooltipOpenDelay: number
  @buildPlanStore.Getter getBuildPlanPrintStrategy: BuildPlanPrintStrategyDto
  @buildPlanStore.Getter defaultPartParamOptionAvailable: boolean

  @Prop() index: number
  @Prop({ required: true }) readonly support: BuildPlanItemSupport
  @Prop({ required: true }) readonly parameters: IPrintStrategyParameterSet[]
  @Prop({ default: false }) readonly isReadOnly: boolean
  @Prop({ default: false }) readonly disabled?: boolean

  get parameterSetInitialValue(): VersionablePk {
    const settings = this.support && this.support.settings
    const id = settings && settings.printStrategyParameterSetId
    const bodyOption = this.bodyParameterOptions.find((option) => {
      return option.value instanceof VersionablePk
        ? option.value.equals(new VersionablePk(id, settings && settings.printStrategyParameterSetVersion))
        : null
    })

    return bodyOption
      ? bodyOption.value
      : this.bodyParameterOptions.find((x) => (x.value as VersionablePk).id === null).value
  }

  get getSupportIcon() {
    return BodyTypeIcons.support
  }

  get bodyParameterOptions() {
    const settings = this.support && this.support.settings
    const defaults = this.getBuildPlanPrintStrategy.defaults
    const printStrategyParameterSetPk = getSupportDefaultBasedOnType(defaults, settings.strategy as SupportTypes)

    const defaultParam: IPrintStrategyParameterSet = this.parameters.find(
      (parameter) =>
        parameter.id === printStrategyParameterSetPk.id && parameter.version === printStrategyParameterSetPk.version,
    )

    const params = [...this.parameters]
    if (this.defaultPartParamOptionAvailable) {
      const defaultOption = {
        name: defaultNameBasedOnSupportType(settings.strategy as SupportTypes),
        id: null,
        parameterSetId: defaultParam.parameterSetId,
        allowSimCom: defaultParam.allowSimCom,
        layerThickness: defaultParam.layerThickness,
        parameterSet: defaultParam.parameterSet,
        parameterSetVersion: defaultParam.parameterSetVersion,
        version: 1,
        implicitVersion: defaultParam.implicitVersion,
      }
      params.unshift(defaultOption)
    }

    return params.map((parameter) => {
      const layerThickness = parseFloat(convert('m', 'mm', parameter.layerThickness).toPrecision(3))
      const thicknessText = parameter.id ? ` - ${layerThickness}mm` : ''
      const name = parameter.name + thicknessText
      return {
        name,
        value: VersionableModel.getPk(parameter),
      }
    })
  }

  get bodyParameterName(): string {
    const settings = this.support && this.support.settings
    let id = settings && settings.printStrategyParameterSetId
    let version = settings && settings.printStrategyParameterSetVersion

    if (!id) {
      const defaults = this.getBuildPlanPrintStrategy.defaults
      const pk = getSupportDefaultBasedOnType(defaults, settings.strategy as SupportTypes)
      id = pk.id
      version = pk.version
    }

    const bodyParameter = this.parameters.find((p) => p.id === id && p.version === version)
    return bodyParameter ? bodyParameter.name : '-'
  }

  get bodyName(): string {
    return this.support.overhangElementName || '-'
  }

  onBodyParameterSetChange(printStrategyParameterSetPk: VersionablePk) {
    if (!this.support.settings) {
      this.support.settings = {
        printStrategyParameterSetId: printStrategyParameterSetPk.id,
        printStrategyParameterSetVersion: printStrategyParameterSetPk.version,
      } as BuildPlanItemSupportSettings
    } else {
      this.support.settings.printStrategyParameterSetId = printStrategyParameterSetPk.id
      this.support.settings.printStrategyParameterSetVersion = printStrategyParameterSetPk.version
    }

    const prop = {
      support: this.support,
      id: this.support.fileKey,
    }
    this.$emit('parameterSetPkChange', prop)
  }

  onHoverItem(isHover: boolean) {
    this.$emit('support-property-hover', this.bodyName, isHover)
  }
}
