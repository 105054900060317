import { VersionableModel } from '@/types/Common/VersionableModel'

export interface IMachineConfig extends VersionableModel {
  name: string
  printHeadLaneDirection: Direction
  printHeadLaneWidth: number
  numberOfPrintHeadLanes: number
  firstPrintHeadLaneSide: number
  productLineName: string
  envelopeShape: EnvelopeShape
  machineName: string
  imageUrl: string
  alias: string
  printingType: PrintingTypes
  buildChamberX: number
  buildChamberY: number
  buildChamberZ: number
  visibility: boolean
  gasFlow: string
  recoaterDirection: string
  numberOfLasers: number
}

export enum Direction {
  X = '+X',
  Y = '+Y',
  NegativeX = '-X',
  NegativeY = '-Y',
}

export enum EnvelopeShape {
  Square = 'Square',
  Circle = 'Circle',
}

export enum PrintingTypes {
  DMLM = 'DMLM',
  BinderJet = 'Binder Jet',
  EBM = 'E-Beam',
}
