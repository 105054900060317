
import AlertModal from '@/components/modals/AlertModal.vue'
import { Component, Watch } from 'vue-property-decorator'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'
import { LocaleMessages } from 'vue-i18n'
import BuildPlanSimVisTab from '@/components/layout/buildPlans/simulate/BuildPlanSimVisTab.vue'
import BuildPlanCompensationTab from '@/components/layout/buildPlans/BuildPlanCompensationTab.vue'
import ChartsPanel from '@/components/layout/buildPlans/simulate/ChartsPanel.vue'
import SummaryPanel from '@/components/layout/buildPlans/simulate/SummaryPanel.vue'
import { SceneType } from '@/visualization/types/Common'
import { ContentViewModeTypes } from '@/visualization/types/ContentViewMode'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import { IJob, JobStatusCode as Status, JobType } from '@/types/PartsLibrary/Job'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import { ISimulationExportInfo } from '@/visualization/types/SimulationTypes'
import { PWError } from '@/visualization/rendering/ResultsManager'
import { ConnectionState } from '@/utils/socketConnection'
import { DATA_JSON, MAX_EXPORT_STRING_LENGTH } from '@/constants'
import buildPlans from '@/api/buildPlans'
import partsService from '@/api/parts'
import { VisualizationServiceMixin } from '@/components/layout/buildPlans/mixins/VisualizationServiceMixin'
import { centerTruncate } from '@/utils/string'
import { INotification } from '@/types/Notification/INotification'
import NoSimResults from '@/components/layout/buildPlans/simulate/NoSimResults.vue'
import SimResultsHeader from '@/components/layout/buildPlans/simulate/SimResultsHeader.vue'
import ExportFileSelectModal from '@/components/layout/buildPlans/modals/ExportFileSelectModal.vue'

const visualizationStore = namespace(StoresNamespaces.Visualization)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const notificationsStore = namespace(StoresNamespaces.Notifications)

enum SimulateSections {
  NoSection = -1,
  Settings = 0,
  Summary = 1,
  Details = 2,
  Charts = 3,
}

enum ToolState {
  NoJob,
  DataJsonMissing,
  DataJsonAvailable,
  JobIsInProgress,
}

@Component({
  components: {
    BuildPlanSimVisTab,
    BuildPlanCompensationTab,
    ChartsPanel,
    SummaryPanel,
    AlertModal,
    NoSimResults,
    SimResultsHeader,
    ExportFileSelectModal,
  },
})
export default class SimulateTab extends VisualizationServiceMixin implements IToolComponent {
  @visualizationStore.Action setSimulationExportInfo: (exportInfo: ISimulationExportInfo) => void
  @visualizationStore.Action setDataJsonContent: Function

  @visualizationStore.Getter getActiveChart: any
  @visualizationStore.Getter('getVisualizationLoading') getVisualizationLoading: boolean
  @visualizationStore.Getter('getBridgingElementsVisible') getBridgingElementsVisible: boolean
  @visualizationStore.Getter('getSummaryData') getSummaryData
  @visualizationStore.Getter getChartsNames: string[]
  @visualizationStore.Getter('getSimulationSteps') getSimulationSteps

  @visualizationStore.Mutation('setActiveScene') setActiveScene: Function
  @visualizationStore.Mutation('resetSimulationState') resetSimulationState: Function

  @buildPlansStore.Action('fetchBuildPlanJobs') fetchBuildPlanJobs: Function
  @buildPlansStore.Getter('getSelectedBuildPlanJobs') getSelectedBuildPlanJobs: IJob[]
  @buildPlansStore.Getter('getSelectedBuildPlanSimCompJobs') getSelectedBuildPlanSimCompJobs: IJob[]
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Mutation setContentViewMode: Function

  @notificationsStore.Getter getAllNotifications: INotification[]

  clickOk?: () => void
  clickCancel?: () => void

  sceneTypes = SceneType
  isReadOnly: boolean
  selectedSection: SimulateSections = SimulateSections.NoSection
  toolState: ToolState = ToolState.NoJob

  $refs!: {
    simComp
    selectExport: InstanceType<typeof ExportFileSelectModal>
    alert: InstanceType<typeof AlertModal>
  }

  private simJobStarted: boolean = false
  private jobNotificationWatch

  set simulationJobStarted(value: boolean) {
    this.simJobStarted = value
    this.isReadOnly = value
    if (value) {
      this.setContentViewMode(ContentViewModeTypes.Visualization)
      this.setActiveScene(SceneType.Visualization)
    } else {
      // If job is in progress it's OK if it at this moment does not have results
      if (this.toolState !== ToolState.JobIsInProgress) {
        this.toolState = ToolState.NoJob
      }
      this.setContentViewMode(ContentViewModeTypes.Layout)
      this.setActiveScene(SceneType.Layout)
      // TODO: Use action instead of using a reference to another's component delegate inside of simCompComponentClickOk
      this.clickOk = this.simCompComponentClickOk
    }
  }

  get simulationJobStarted() {
    return this.simJobStarted
  }

  get simulationExpanded() {
    return this.selectedSection === SimulateSections.Details
  }

  get summaryExpanded() {
    return this.selectedSection === SimulateSections.Summary
  }

  get settingsExpanded() {
    return this.selectedSection === SimulateSections.Settings
  }

  get noResultsLabel() {
    let key = 'noSimResults.loading'
    if (this.toolState === ToolState.NoJob) key = 'noSimResults.jobNotStarted'
    if (this.toolState === ToolState.JobIsInProgress) key = 'noSimResults.jobInProgress'

    return this.$t(key)
  }

  get checkInterimResults() {
    return this.toolState === ToolState.JobIsInProgress ? this.getResultsFetching : false
  }

  get checkingDetails() {
    return this.toolState === ToolState.DataJsonAvailable || this.toolState === ToolState.DataJsonMissing
  }

  get summaryLabel() {
    if (this.toolState === ToolState.JobIsInProgress && this.getSummaryData) {
      return `${this.$t('resultsSummary')}   (${this.$t('interim')})`
    }

    return this.$t('resultsSummary')
  }

  get detailsLabel() {
    if (this.toolState === ToolState.JobIsInProgress && this.getSimulationSteps.length) {
      return `${this.$t('resultsDetais')}   (${this.$t('interim')})`
    }

    return this.$t('resultsDetais')
  }

  get chartsLabel() {
    if (this.toolState === ToolState.JobIsInProgress && this.getChartsNames.length) {
      return `${this.$t('resultsCharts')}   (${this.$t('interim')})`
    }

    return this.$t('resultsCharts')
  }

  clickExport() {
    const buildPlanCompensateJob = this.getSelectedBuildPlanJobs.find((j) => j.jobType === JobType.COMPENSATE)
    const prefix = buildPlanCompensateJob
      ? this.$t('exportArchivePrefix.compensation')
      : this.$t('exportArchivePrefix.simulation')
    const buildPlanName = centerTruncate(this.getBuildPlan.name, MAX_EXPORT_STRING_LENGTH)
    const buildPlanVariant = this.getBuildPlan.versionLabel
      ? centerTruncate(this.getBuildPlan.versionLabel, MAX_EXPORT_STRING_LENGTH)
      : `variant_${this.getBuildPlan.version}`

    this.setSimulationExportInfo({
      buildPlanName,
      buildPlanVariant,
      prefix: prefix.toString(),
    })

    this.$refs.selectExport.open(buildPlanName, buildPlanVariant)
  }

  getOkName() {
    return 'start'
  }

  @Watch('getResultsAvailable') onResultsAvailable() {
    this.finishResultsCheck(this.getResultsAvailable)
  }

  @Watch('getParaviewWebError') async onParaviewWebError() {
    if (this.getParaviewWebError) {
      switch (this.getParaviewWebError.type) {
        case PWError.SocketError:
          await this.$refs.alert.open(this.$t('simulationToolLabel'), this.$t('simulationToolSocketError'))
          this.$emit('closeTool')
          break
        case PWError.ProcessExit:
          await this.$refs.alert.open(this.$t('simulationToolLabel'), this.$t('paraviewwebProcessClosed'))
          this.$emit('closeTool')
          break
        case PWError.SocketClosed:
          this.$emit('triggerSocketDisconnectModal', ConnectionState.SocketClosed)
          break
        case PWError.ErrorBeforeConnection:
          this.$emit('triggerSocketDisconnectModal', ConnectionState.FailedToConnect)
          break
        default:
          break
      }
    }
  }

  @Watch('getBridgingElementsVisible')
  onBridgingVisibilty() {
    if (this.getBridgingElementsVisible) {
      this.setSelectedSection(SimulateSections.Summary, true)
    }
  }

  @Watch('selectedSection')
  selectedSectionChanged(value: SimulateSections) {
    switch (value) {
      case SimulateSections.Settings:
        this.setContentViewMode(ContentViewModeTypes.Layout)
        this.setActiveScene(SceneType.Layout)
        break
      case SimulateSections.Summary:
      case SimulateSections.Details:
        this.setContentViewMode(this.simJobStarted ? ContentViewModeTypes.Visualization : ContentViewModeTypes.Layout)
        this.setActiveScene(this.simJobStarted ? SceneType.Visualization : SceneType.Layout)
        break
      case SimulateSections.Charts:
        this.setContentViewMode(ContentViewModeTypes.Charts)
        break
    }
  }

  beforeDestroy() {
    this.selectedSection = SimulateSections.NoSection
    this.setContentViewMode(ContentViewModeTypes.Layout)
    this.resetSimulationState()
    if (this.jobNotificationWatch) {
      this.jobNotificationWatch()
    }
  }

  async beforeMount() {
    const buildPlanId = this.$route.params.id
    const existingJobs = this.getSelectedBuildPlanSimCompJobs.sort((a, b) => b.number - a.number)

    if (existingJobs.length) {
      const latestJob = existingJobs[0]
      this.setSelectedSection(SimulateSections.Details)

      if (!latestJob || [Status.CANCELLING, Status.RUNNING, Status.QUEUED, Status.CREATED].includes(latestJob.code)) {
        this.toolState = ToolState.JobIsInProgress
        this.jobNotificationWatch = this.$watch('getAllNotifications', async () => {
          if (!this.getAllNotifications || !this.getAllNotifications[0]) return

          const latest = this.getAllNotifications[0]
          if (
            (latest.jobType === JobType.COMPENSATE || latest.jobType === JobType.SIMULATE) &&
            latest.pathname.includes(buildPlanId)
          ) {
            if (
              latest.template.toLowerCase().includes('failed') ||
              latest.template.toLowerCase().includes('cancelled')
            ) {
              await this.handleDataJsonMissingCase()
            } else if (this.getSimulationSteps.length) {
              this.toolState = ToolState.DataJsonAvailable
              await this.connect()
            } else {
              const files = await buildPlans.getAllFiles(buildPlanId)
              const jsonFileData = files.find((f) => f.name === DATA_JSON)
              await this.handleDataJsonAvailableCase(jsonFileData)
            }

            this.jobNotificationWatch()
          }
        })
      } else {
        // Set this to display the correct label in the panel content
        this.toolState = ToolState.DataJsonMissing
        const files = await buildPlans.getAllFiles(buildPlanId)
        const jsonFileData = files.find((f) => f.name === DATA_JSON)
        if (jsonFileData) {
          await this.handleDataJsonAvailableCase(jsonFileData)
        } else {
          await this.handleDataJsonMissingCase()
        }
      }
    } else {
      this.setSelectedSection(SimulateSections.Settings)
      this.finishResultsCheck(null)
    }
  }

  async showAlert(
    title: string | LocaleMessages,
    message: string | LocaleMessages,
    submitLabel?: string,
    options?: object,
  ) {
    if (submitLabel) {
      this.$refs.alert.submitLabel = submitLabel
    }
    await this.$refs.alert.open(title, message, options)
  }

  setOkDisabled(value) {
    this.$emit('setOkDisabled', value)
  }

  creatingJob() {
    this.$emit('creatingJob')
  }

  jobCreated() {
    this.$emit('jobCreated')
  }

  failedJob() {
    this.$emit('failedJob')
  }

  private async simCompComponentClickOk() {
    // TODO: Use action instead of using a reference to another's component delegate
    const simCompComponent = this.$refs.simComp as IToolComponent
    return simCompComponent.clickOk()
  }

  private async handleDataJsonMissingCase() {
    this.toolState = ToolState.DataJsonMissing
    await this.connect()
  }

  private async handleDataJsonAvailableCase(jsonFileData) {
    this.toolState = ToolState.DataJsonAvailable
    const dataJson = await partsService.getFileDataById(jsonFileData.key)
    this.setDataJsonContent(dataJson)
    await this.connect()
  }

  private finishResultsCheck(check: { available: boolean; exportData; meshAvailable: boolean }) {
    this.simulationJobStarted = check && check.available
    this.$emit('isOkAvailable', this)
  }

  // With force set to false we can prevent the logic from expanding the specific section
  // if the user already expanded another one
  private setSelectedSection(section: SimulateSections, force: boolean = false) {
    if (this.selectedSection === SimulateSections.NoSection || force) {
      this.selectedSection = section
    }
  }
}
