import StoresNamespaces from '@/store/namespaces'
import { SortOrders } from '@/types/SortModes'
import { areEqual, orderBy } from '@/utils/array'
import { ConnectionState } from '@/utils/socketConnection'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component
export class SidebarMixin extends Vue {
  @buildPlansStore.Getter getBuildPlanViewMode: ViewModeTypes

  @visualizationStore.Getter getVisualizationLoading: boolean
  @visualizationStore.Getter isCompensationFileInProgress: boolean
  @visualizationStore.Getter getResultsAvailable

  cancelAvailable = false
  okDisabled = false
  cancelDisabled = false
  okAvailable = false
  exportAvailable = false
  cancelName: string = null

  socketDisconnectModalVisibility: boolean = false
  socketDisconnectMessage: string = ''

  setOkDisabled(value) {
    this.okDisabled = value
  }

  setCancelDisabled(value: boolean) {
    this.cancelDisabled = value
  }

  setCancelName(name: string) {
    this.cancelName = name
  }

  addWayToEnable(item, key, ...params) {
    const wayToEnable = { key, params }
    item.waysToEnable.push(wayToEnable)
    item.waysToEnable = orderBy(item.waysToEnable, ['key'], [SortOrders.Ascending])
  }

  addUniqueWayToEnable(item, key, ...params) {
    const wayToEnable = { key, params }
    const index = item.waysToEnable.findIndex((way) => {
      return way.key === wayToEnable.key && areEqual(way.params, wayToEnable.params)
    })
    if (index === -1) {
      item.waysToEnable.push(wayToEnable)
      item.waysToEnable = orderBy(item.waysToEnable, ['key'], [SortOrders.Ascending])
    }
  }

  removeWayToEnable(item, key, ...params) {
    const wayToEnable = { key, params }
    const index = item.waysToEnable.findIndex((way) => {
      return way.key === wayToEnable.key && areEqual(way.params, wayToEnable.params)
    })
    if (index > -1) {
      item.waysToEnable.splice(index, 1)
      item.waysToEnable = orderBy(item.waysToEnable, ['key'], [SortOrders.Ascending])
    }
  }

  handleIsOkAvailabilityChanged(sender) {
    if (sender) {
      this.okAvailable = sender.clickOk !== undefined
      this.exportAvailable = sender.clickExport !== undefined
    }
  }

  triggerSocketDisconnectModal(state: ConnectionState) {
    if (state === ConnectionState.FailedToConnect) {
      this.socketDisconnectMessage = this.disconnectMessageOnError().toString()
    } else {
      this.socketDisconnectMessage = this.disconnectMessageOnClose().toString()
    }

    this.socketDisconnectModalVisibility = true
  }

  private disconnectMessageOnError() {
    switch (this.getBuildPlanViewMode) {
      case ViewModeTypes.Support:
        return this.$t('socketDisconnectModal.supportConnectionError')
      case ViewModeTypes.Marking:
        return this.$t('socketDisconnectModal.labelConnectionError')
      case ViewModeTypes.SimulationCompensation:
        return this.$t('socketDisconnectModal.simulateConnectionError')
      default:
        return this.$t('socketDisconnectModal.defaultConnectionError')
    }
  }

  private disconnectMessageOnClose() {
    switch (this.getBuildPlanViewMode) {
      case ViewModeTypes.Support:
        return this.$t('socketDisconnectModal.supportMessage')
      case ViewModeTypes.Marking:
        return this.$t('socketDisconnectModal.labelMessage')
      case ViewModeTypes.SimulationCompensation:
        return this.$t('socketDisconnectModal.simulateMessage')
      default:
        return this.$t('socketDisconnectModal.defaultMessage')
    }
  }
}
