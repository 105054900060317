
import Vue from 'vue'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'

import StoresNamespaces from '@/store/namespaces'
import { Prop, Watch } from 'vue-property-decorator'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemType } from '@/types/FileExplorer/ItemType'
import { SceneMode } from '@/visualization/types/SceneTypes'

const visualizationStore = namespace(StoresNamespaces.Visualization)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component
export default class DetailsPreview extends Vue {
  @visualizationStore.Getter getVisualizationLoading: boolean

  @visualizationStore.Action loadPartConfig: Function

  @visualizationStore.Mutation clearDetailsPreview: Function
  @visualizationStore.Mutation initDetailsPreview: Function
  @visualizationStore.Mutation dispose: Function

  @fileExplorerStore.Getter getSelectedItems: FileExplorerItem[]

  @Prop() item: FileExplorerItem

  async mounted() {
    await this.processItem(this.item)
  }

  async processItem(item: FileExplorerItem) {
    if (item.itemType !== ItemType.BuildPart || this.getSelectedItems.length !== 1) {
      return
    }

    // update preview only part item
    try {
      this.initDetailsPreview({
        canvasId: 'visualization_canvas',
        sceneMode: SceneMode.PreviewDetails,
      })
      // TODO: use part.previewImageUrl instead calling loadPartConfig for perfomance improvement
      // because this creates scene and load model geometry
      // but scene has not interation and can be replaced with image.
      this.loadPartConfig({ partId: item.id, partName: item.name, allowLoadPartConfigTermination: true })
    } catch (error) {
      console.error(`Error while receiving data: ${error}`)
    }
  }

  @Watch('item')
  async onItemChange(val) {
    const disposePromise = this.createDisposePromise()
    this.dispose(disposePromise.done)
    await disposePromise.promise
    await this.processItem(val)
  }

  private createDisposePromise() {
    let done: Function
    const promise = new Promise<void>((resolve) => {
      done = resolve
    })

    return { done, promise }
  }
}
