import {
  DUPLICATE_WRAPPER,
  FAILED_DUPLCIATE_COLOR,
  ITEM_ID_PREFIX_DELIMITER,
  LABEL,
  PARAMETER_SET_SCALE_NAME,
  SUPPORT,
  SUPPORT_PARENT,
} from '@/constants'
import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { DuplicateAxes, DuplicateData, DuplicateMode, DuplicatePayload } from '@/types/Duplicate/Duplicate'
import { Epsilon, Matrix, Vector3 } from '@babylonjs/core/Maths'
import { Scene } from '@babylonjs/core/scene'
import { BoundingBox } from '@babylonjs/core/Culling/boundingBox'
import { v4 as uuidv4 } from 'uuid'
import { VisualizationEvent } from '@/visualization/infrastructure/IVisualizationEvent'
import { IRenderable } from '@/visualization/types/IRenderable'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import { ModelManager } from '@/visualization/rendering/ModelManager'
import { IPartMetadata } from '@/visualization/types/SceneItemMetadata'
import { AbstractMesh, InstancedMesh, TransformNode } from '@babylonjs/core/Meshes'
import messageService from '@/services/messageService'
import { RenderScene } from '@/visualization/render-scene'
import { setNestedValue } from '@/utils/array'
import _ from 'lodash'
import { createGuid, occasionalSleeper } from '@/utils/common'
import { eventBus } from '@/services/EventBus'
import { BuildPlanEvents } from '@/types/Label/BuildPlanEvents'
import { Clearance, ClearanceModes } from '@/visualization/types/ClearanceTypes'

// We can have error in position around 0.25 mm
const ALLOWED_ERROR: number = 0.25
const CANCELATION_ERROR: string = 'cancelationError'

export class DuplicateManager {
  private readonly onSetInstancingIsRunning = new VisualizationEvent<boolean>()
  private renderScene: IRenderable
  private scene: Scene
  private meshManager: MeshManager
  private modelManager: ModelManager
  private cancelationPromise: { promise: Promise<void>; done: Function }
  private isRunning: boolean = false
  private oldGapMap: Map<string, { x: number; y: number; z: number; axisData: DuplicateData[] }> = new Map()
  private createdCopy: TransformNode = null
  private isLastGridIndependent: boolean = true

  constructor(renderScene: IRenderable, modelManager: ModelManager) {
    this.renderScene = renderScene
    this.scene = renderScene.getScene()
    this.meshManager = renderScene.getMeshManager()
    this.modelManager = modelManager
  }

  get setInstancingIsRunning() {
    return this.onSetInstancingIsRunning.expose()
  }

  async createVolumeGrid(
    gridData: DuplicatePayload[],
    independent: boolean = true,
    duplicateMode: DuplicateMode = DuplicateMode.BoundingBoxes,
  ) {
    ;(this.renderScene as RenderScene).sceneCheckDisabled = true
    switch (duplicateMode) {
      case DuplicateMode.BoundingBoxes:
        await this.createVolumeGridByAABB(gridData, independent)
        break
      case DuplicateMode.Geometry:
        await this.createVolumeGridByBVH(gridData, independent)
        break
    }

    ;(this.renderScene as RenderScene).sceneCheckDisabled = false
  }

  async createVolumeGridByBVH(gridData: DuplicatePayload[], independent: boolean = true) {
    if (!gridData.length) {
      return
    }

    const clearanceManager = (this.renderScene as RenderScene).getClearanceManager()
    const clearanceDuplicate = clearanceManager.getClearanceMode(ClearanceModes.Duplicate)
    ;(this.renderScene as RenderScene).animate(true, false)

    const selectedParent = this.renderScene.getSelectionManager().getSelected(true)
    const allSelectedParts = this.renderScene.getSelectionManager().getSelected()
    selectedParent.getChildTransformNodes(true).forEach((tn) => {
      tn.setParent(null)
    })

    this.isRunning = true
    let isMarked = false
    const instancingResult = []
    const axisData = gridData[0].axisData
    const { mainAxis, secondaryAxis, tertiaryAxis } = this.getAxisOrder(axisData)
    const groupMeshInfo: Map<string, Array<{ instancesTransformations: number[]; id: string }>> = new Map()
    const wrappers = this.getArrayWrappers(gridData, independent)
    const idsOfAllWrappers = wrappers.map((wrapper) => wrapper.metadata.combinedId).join(ITEM_ID_PREFIX_DELIMITER)
    const allClonedBuildPlanItems: TransformNode[] = []

    for (const wrapper of wrappers) {
      let instances = []

      const { minimalShift } = this.getExistingGapAndIndices(wrapper.metadata.combinedId, axisData, independent)
      this.clearExtraInstances(wrapper.metadata.combinedId, axisData)
      const isContinue = this.checkForUseExisting(wrapper.metadata.combinedId, axisData, independent)
      if (isContinue) {
        instances = this.actualizeInstancesArray(wrapper.metadata.combinedId, [
          tertiaryAxis.axisName,
          secondaryAxis.axisName,
          mainAxis.axisName,
        ])

        this.actualizeMeshInfo(instances, groupMeshInfo)
      } else {
        let specialInstancesId = wrapper.metadata.combinedId
        let useSplit = false
        if (this.isLastGridIndependent !== independent) {
          // was independent but now dependent
          if (!independent) {
            specialInstancesId = idsOfAllWrappers
            useSplit = true
          } else {
            // was dependent but now independent
            specialInstancesId = idsOfAllWrappers
          }
        }

        this.clearSpecialInstances(specialInstancesId, useSplit)
      }

      const mainPosition = wrapper.absolutePosition.clone()

      for (let k = 0; k < tertiaryAxis.amount; k += 1) {
        const plane = instances[k] || []
        for (let i = 0; i < secondaryAxis.amount; i += 1) {
          const row = plane[i] || []
          for (let j = 0; j < mainAxis.amount; j += 1) {
            try {
              await occasionalSleeper()
              if (row[j]) {
                continue
              }

              if (i === 0 && j === 0 && k === 0) {
                row.push(wrapper)
                continue
              }

              this.cancelationCheck()

              const cloneWrapper = this.createWrapperCopy(wrapper)
              const duplicateGridIndices = {}
              duplicateGridIndices[mainAxis.axisName] = j
              duplicateGridIndices[secondaryAxis.axisName] = i
              duplicateGridIndices[tertiaryAxis.axisName] = k
              cloneWrapper.metadata.duplicateGridIndices = duplicateGridIndices

              // In this case we calculate minimal main shift to use it in other instances
              if (i === 0 && j === 1 && k === 0) {
                const newPosition = mainPosition.clone()
                cloneWrapper.setAbsolutePosition(newPosition)
                cloneWrapper.computeWorldMatrix(true)
                ;(this.renderScene as RenderScene).setMeshVisibilityRec(cloneWrapper, false, false, true, true, true)

                const delta = await this.calculateDuplicateDelta(
                  [wrapper],
                  cloneWrapper,
                  mainAxis.axisName,
                  mainAxis.spacing,
                )

                this.cancelationCheck()

                await occasionalSleeper()

                minimalShift[mainAxis.axisName] = delta[mainAxis.axisName]
                // Add instance shift
                const shiftedPosition = cloneWrapper.absolutePosition.clone()
                shiftedPosition[mainAxis.axisName] += mainAxis.spacing
                cloneWrapper.setAbsolutePosition(shiftedPosition)
                cloneWrapper.computeWorldMatrix(true)
                ;(this.renderScene as RenderScene).setMeshVisibilityRec(cloneWrapper, true, false, true, true, true)
                this.oldGapMap.set(wrapper.metadata.combinedId, { axisData, ...minimalShift })

                /**
                 * Here should be added a calculate spacing dimension call for the main axis
                 */
                clearanceDuplicate.measureDistance({
                  wrapper,
                  cloneWrapper,
                  shiftedPosition,
                  axisName: mainAxis.axisName,
                  duplicateMode: DuplicateMode.Geometry,
                })
                // In this case we calculate minimal secondary axis shift to use it in other instances
              } else if (i === 1 && j === 0 && k === 0) {
                const newPosition = mainPosition.clone()
                cloneWrapper.setAbsolutePosition(newPosition)
                cloneWrapper.computeWorldMatrix(true)
                ;(this.renderScene as RenderScene).setMeshVisibilityRec(cloneWrapper, false, false, true, true, true)

                await occasionalSleeper()

                // Move part until it is stop colliding with near instances
                const delta = await this.calculateDuplicateDelta(
                  [wrapper, plane[i - 1][j + 1]],
                  cloneWrapper,
                  secondaryAxis.axisName,
                  secondaryAxis.spacing,
                )
                minimalShift[secondaryAxis.axisName] = delta[secondaryAxis.axisName]

                // We need to test collision with the nearest parts while we are finding minimal secondary axis shift
                // Shift clone part to next instance position if it possible
                if (secondaryAxis.amount > 1) {
                  const startClonePosition = cloneWrapper.absolutePosition.clone()

                  // We need to take into account next clone collision when we calculate minimal secondary axis shift
                  const nextClonePosition = Vector3.Zero()
                  nextClonePosition[mainAxis.axisName] =
                    startClonePosition[mainAxis.axisName] +
                    (j + 1) * (mainAxis.spacing + minimalShift[mainAxis.axisName])
                  nextClonePosition[secondaryAxis.axisName] = cloneWrapper.absolutePosition[secondaryAxis.axisName]
                  nextClonePosition[tertiaryAxis.axisName] = startClonePosition[tertiaryAxis.axisName]
                  cloneWrapper.setAbsolutePosition(nextClonePosition)
                  cloneWrapper.computeWorldMatrix(true)

                  await this.calculateDuplicateDelta(
                    [plane[i - 1][j], plane[i - 1][j + 1]],
                    cloneWrapper,
                    secondaryAxis.axisName,
                    secondaryAxis.spacing,
                  )

                  // In order to have constant gap along secondary axis we should check collision after adding spacing
                  let isFinished = false
                  while (!isFinished) {
                    isFinished = true
                    const newClonePosition = Vector3.Zero()
                    newClonePosition[mainAxis.axisName] =
                      startClonePosition[mainAxis.axisName] +
                      (j + 1) * (mainAxis.spacing + minimalShift[mainAxis.axisName])
                    newClonePosition[secondaryAxis.axisName] =
                      cloneWrapper.absolutePosition[secondaryAxis.axisName] + secondaryAxis.spacing
                    newClonePosition[tertiaryAxis.axisName] = startClonePosition[tertiaryAxis.axisName]
                    cloneWrapper.setAbsolutePosition(newClonePosition)
                    cloneWrapper.computeWorldMatrix(true)

                    // Test collisions between the clone wrapper and two nearest wrappers on lower row
                    const cloneWrapperGroup = cloneWrapper.getChildTransformNodes().filter(this.meshManager.isPartMesh)
                    const nearestWrappersGroup = [plane[i - 1][j], plane[i - 1][j + 1]]
                      .map((nearestWrapper) =>
                        nearestWrapper.getChildTransformNodes().filter(this.meshManager.isPartMesh),
                      )
                      .flat()
                    if (this.checkPartsToPartsCollision(cloneWrapperGroup, nearestWrappersGroup)) {
                      await this.calculateDuplicateDelta(
                        [plane[i - 1][j], plane[i - 1][j + 1]],
                        cloneWrapper,
                        secondaryAxis.axisName,
                        secondaryAxis.spacing,
                      )

                      isFinished = true
                    }
                  }

                  // We need to shift back clone
                  const shiftBackCloneMeshPosition = Vector3.Zero()
                  shiftBackCloneMeshPosition[mainAxis.axisName] = startClonePosition[mainAxis.axisName]
                  shiftBackCloneMeshPosition[secondaryAxis.axisName] =
                    cloneWrapper.absolutePosition[secondaryAxis.axisName] - secondaryAxis.spacing
                  shiftBackCloneMeshPosition[tertiaryAxis.axisName] =
                    cloneWrapper.absolutePosition[tertiaryAxis.axisName]

                  cloneWrapper.setAbsolutePosition(shiftBackCloneMeshPosition)
                  cloneWrapper.computeWorldMatrix(true)
                  // Set minimal secondary axis shift
                  minimalShift[secondaryAxis.axisName] = cloneWrapper.absolutePosition.subtract(
                    wrapper.absolutePosition,
                  )[secondaryAxis.axisName]
                }

                // Add instance shift to mesh positoin
                const shiftedPosition = cloneWrapper.absolutePosition.clone()
                shiftedPosition[secondaryAxis.axisName] += secondaryAxis.spacing
                cloneWrapper.setAbsolutePosition(shiftedPosition)
                cloneWrapper.computeWorldMatrix(true)
                ;(this.renderScene as RenderScene).setMeshVisibilityRec(cloneWrapper, true, false, true, true, true)
                this.oldGapMap.set(wrapper.metadata.combinedId, { axisData, ...minimalShift })
                /**
                 * Here should be added a calculate spacing dimension call for the secondary axis
                 */
                clearanceDuplicate.measureDistance({
                  wrapper,
                  cloneWrapper,
                  shiftedPosition,
                  axisName: secondaryAxis.axisName,
                  duplicateMode: DuplicateMode.Geometry,
                })
                // In this case we calculate minimal tertiary axis shift to use it in other instances
              } else if (i === 0 && j === 0 && k === 1) {
                const newPosition = mainPosition.clone()
                cloneWrapper.setAbsolutePosition(newPosition)
                cloneWrapper.computeWorldMatrix(true)
                ;(this.renderScene as RenderScene).setMeshVisibilityRec(cloneWrapper, false, false, true, true, true)

                const delta = await this.calculateDuplicateDelta(
                  [wrapper, instances[k - 1][i][j + 1], instances[k - 1][i + 1][j]],
                  cloneWrapper,
                  tertiaryAxis.axisName,
                  tertiaryAxis.spacing,
                )
                minimalShift[tertiaryAxis.axisName] = delta[tertiaryAxis.axisName]

                // We need to test collision with the nearest parts while we are finding minimal tertiary axis shift
                // Shift clone part to next diagonal instance position if it possible
                if (tertiaryAxis.amount > 1) {
                  const startClonePosition = cloneWrapper.absolutePosition.clone()

                  // We need to take into account next clone collision when we calculate minimal tertiary axis shift
                  const nextClonePosition = Vector3.Zero()
                  nextClonePosition[mainAxis.axisName] =
                    startClonePosition[mainAxis.axisName] +
                    (j + 1) * (mainAxis.spacing + minimalShift[mainAxis.axisName])
                  nextClonePosition[secondaryAxis.axisName] =
                    startClonePosition[secondaryAxis.axisName] +
                    (i + 1) * (secondaryAxis.spacing + minimalShift[secondaryAxis.axisName])
                  nextClonePosition[tertiaryAxis.axisName] = cloneWrapper.absolutePosition[tertiaryAxis.axisName]
                  cloneWrapper.setAbsolutePosition(nextClonePosition)
                  cloneWrapper.computeWorldMatrix(true)

                  await this.calculateDuplicateDelta(
                    [instances[k - 1][i][j], instances[k - 1][i][j + 1], instances[k - 1][i + 1][j]],
                    cloneWrapper,
                    secondaryAxis.axisName,
                    secondaryAxis.spacing,
                  )

                  // In order to have constant gap along tertiary axis we should check collision after adding spacing
                  let isFinished = false
                  while (!isFinished) {
                    isFinished = true
                    const newClonePosition = Vector3.Zero()
                    newClonePosition[mainAxis.axisName] =
                      startClonePosition[mainAxis.axisName] +
                      (j + 1) * (mainAxis.spacing + minimalShift[mainAxis.axisName])
                    newClonePosition[secondaryAxis.axisName] =
                      startClonePosition[secondaryAxis.axisName] +
                      (i + 1) * (secondaryAxis.spacing + minimalShift[secondaryAxis.axisName])
                    newClonePosition[tertiaryAxis.axisName] =
                      cloneWrapper.absolutePosition[tertiaryAxis.axisName] + tertiaryAxis.spacing
                    cloneWrapper.setAbsolutePosition(newClonePosition)
                    cloneWrapper.computeWorldMatrix(true)

                    const cloneWrapperGroup = cloneWrapper.getChildTransformNodes().filter(this.meshManager.isPartMesh)
                    const nearestWrappersGroup = [
                      instances[k - 1][i][j],
                      instances[k - 1][i][j + 1],
                      instances[k - 1][i + 1][j],
                    ]
                      .map((nearestWrapper) =>
                        nearestWrapper.getChildTransformNodes().filter(this.meshManager.isPartMesh),
                      )
                      .flat()

                    // Test collisions between the clone mesh and two nearest meshes on lower row
                    if (this.checkPartsToPartsCollision(cloneWrapperGroup, nearestWrappersGroup)) {
                      await this.calculateDuplicateDelta(
                        [instances[k - 1][i][j], instances[k - 1][i][j + 1], instances[k - 1][i + 1][j]],
                        cloneWrapper,
                        tertiaryAxis.axisName,
                        tertiaryAxis.spacing,
                      )

                      isFinished = false
                    }
                  }

                  // We need to shift back clone mesh
                  const shiftBackCloneMeshPosition = Vector3.Zero()
                  shiftBackCloneMeshPosition[mainAxis.axisName] = startClonePosition[mainAxis.axisName]
                  shiftBackCloneMeshPosition[secondaryAxis.axisName] = startClonePosition[secondaryAxis.axisName]
                  shiftBackCloneMeshPosition[tertiaryAxis.axisName] =
                    cloneWrapper.absolutePosition[tertiaryAxis.axisName] - tertiaryAxis.spacing

                  cloneWrapper.setAbsolutePosition(shiftBackCloneMeshPosition)
                  cloneWrapper.computeWorldMatrix(true)

                  // Set minimal tertiary axis shift
                  minimalShift[tertiaryAxis.axisName] = cloneWrapper.absolutePosition.subtract(
                    wrapper.absolutePosition,
                  )[tertiaryAxis.axisName]
                }

                // Add instance shift to mesh positoin
                const shiftedPosition = cloneWrapper.absolutePosition.clone()
                shiftedPosition[tertiaryAxis.axisName] += tertiaryAxis.spacing
                cloneWrapper.setAbsolutePosition(shiftedPosition)
                cloneWrapper.computeWorldMatrix(true)
                ;(this.renderScene as RenderScene).setMeshVisibilityRec(cloneWrapper, true, false, true, true, true)
                this.oldGapMap.set(wrapper.metadata.combinedId, { axisData, ...minimalShift })
                /**
                 * Here should be added a calculate spacing dimension call for the tertiary axis
                 */
                clearanceDuplicate.measureDistance({
                  wrapper,
                  cloneWrapper,
                  shiftedPosition,
                  axisName: tertiaryAxis.axisName,
                  duplicateMode: DuplicateMode.Geometry,
                })
              } else {
                const newCloneMeshPosition = Vector3.Zero()
                newCloneMeshPosition[mainAxis.axisName] =
                  mainPosition[mainAxis.axisName] + j * (mainAxis.spacing + minimalShift[mainAxis.axisName])
                newCloneMeshPosition[secondaryAxis.axisName] =
                  mainPosition[secondaryAxis.axisName] +
                  i * (secondaryAxis.spacing + minimalShift[secondaryAxis.axisName])
                newCloneMeshPosition[tertiaryAxis.axisName] =
                  mainPosition[tertiaryAxis.axisName] + k * (tertiaryAxis.spacing + minimalShift[tertiaryAxis.axisName])

                cloneWrapper.setAbsolutePosition(newCloneMeshPosition)
                cloneWrapper.computeWorldMatrix(true)
              }

              row[j] = cloneWrapper

              // actualize all world matrices in order to store correct hull data
              cloneWrapper.computeWorldMatrix(true)
              cloneWrapper.getChildTransformNodes().forEach((node) => node.computeWorldMatrix(true))
              cloneWrapper.getChildMeshes().forEach((child) => child.computeWorldMatrix(true))
              // update and cache hull bounding box info after the mesh has been transformed
              // calling this updates parentMesh.metadata.hull as well
              const clonedParts = cloneWrapper.getChildTransformNodes().filter(this.meshManager.isPartMesh)
              clonedParts.forEach((part) => {
                const initialTransformation = part.metadata.initialTransformation
                cloneWrapper.computeWorldMatrix(true)
                const relativeTransformation = this.meshManager.getRelativeTransformation(
                  part.getWorldMatrix(),
                  initialTransformation,
                )

                part.metadata.hullBInfo = this.meshManager.getHullBInfo(part)

                const cloneTransformation = []
                this.meshManager
                  .convertTranslationToMillimeters(relativeTransformation, part.metadata.unitFactor)
                  .transpose()
                  .toArray()
                  .forEach((item) => cloneTransformation.push(item))
                // here

                const meshInfo = groupMeshInfo.get(part.metadata.originBpItemId)
                if (meshInfo) {
                  meshInfo.push({
                    instancesTransformations: cloneTransformation,
                    id: part.id,
                  })
                  groupMeshInfo.set(part.metadata.originBpItemId, meshInfo)
                } else {
                  groupMeshInfo.set(part.metadata.originBpItemId, [
                    {
                      instancesTransformations: cloneTransformation,
                      id: part.id,
                    },
                  ])
                }

                part.getChildMeshes().map((m) => {
                  if (this.meshManager.isLabelMesh(m)) {
                    this.modelManager.labelMgr.translateLabelOrientation(m, true)
                  }
                })

                allClonedBuildPlanItems.push(part)
              })
            } catch (e) {
              if (e.message === CANCELATION_ERROR) {
                if (e.cause) {
                  const canceledAxisData: DuplicateData[] = [
                    { ...tertiaryAxis, amount: k + 1 },
                    { ...secondaryAxis, amount: i + 1 },
                    { ...mainAxis, amount: j + 1 },
                  ]
                  this.oldGapMap.set(wrapper.metadata.combinedId, { axisData: canceledAxisData, ...minimalShift })
                } else {
                  if (this.createdCopy) {
                    this.createdCopy
                      .getChildTransformNodes()
                      .filter(this.meshManager.isPartMesh)
                      .forEach((part) => {
                        // remove outdated meshes from the gpuPicker
                        if (this.renderScene.getGpuPicker()) {
                          this.renderScene.getGpuPicker().removePickingObjects(part.getChildMeshes())
                        }

                        this.scene.metadata.buildPlanItems.delete(part.metadata.buildPlanItemId)
                        this.scene.removeTransformNode(part)
                        part.dispose()
                      })

                    this.scene.removeTransformNode(this.createdCopy)
                    this.createdCopy.dispose()

                    this.removeWrapper(wrapper)
                  }
                }

                // Reselect selected parts in order to have selected group mesh
                this.renderScene.getSelectionManager().deselect()
                this.renderScene.getSelectionManager().select(
                  allSelectedParts.map((part) => ({ part })),
                  true,
                )
                this.isLastGridIndependent = independent
                ;(this.renderScene as RenderScene).stopAnimate()
                return
              }
            }
          }

          plane[i] = row
        }

        instances[k] = plane
      }

      if (allClonedBuildPlanItems.length) {
        this.renderScene.checkCollision(allClonedBuildPlanItems, true, true)
      }

      isMarked = this.markDuplicateUnderBuildPlate(
        instances
          .flat(2)
          .map((instance) => instance.getChildTransformNodes().filter(this.meshManager.isPartMesh))
          .flat(),
      )

      this.oldGapMap.set(wrapper.metadata.combinedId, { axisData, ...minimalShift })
      this.removeWrapper(wrapper)
    }

    groupMeshInfo.forEach((meshInfo, buildPlanItemId) => {
      instancingResult.push({ buildPlanItemId, meshInfo })
    })

    eventBus.$emit(BuildPlanEvents.DuplicateToolStateChanged, {
      instances: instancingResult,
      isDuplicateUnderBuildPlate: isMarked,
    })
    this.onSetInstancingIsRunning.trigger(false)
    this.isLastGridIndependent = independent
    if (isMarked) {
      messageService.showErrorMessage('One or more duplicated meshes are partially or fully under build plate')
    }

    // Reselect selected parts in order to have selected group mesh
    this.renderScene.getSelectionManager().deselect()
    this.renderScene.getSelectionManager().select(
      allSelectedParts.map((part) => ({ part })),
      true,
    )

    this.isRunning = false
    ;(this.renderScene as RenderScene).stopAnimate()
  }

  async createVolumeGridByAABB(gridData: DuplicatePayload[], independent: boolean = true) {
    if (!gridData.length) {
      return
    }

    const clearanceManager = (this.renderScene as RenderScene).getClearanceManager()
    const clearanceDuplicate = clearanceManager.getClearanceMode(ClearanceModes.Duplicate)
    ;(this.renderScene as RenderScene).animate(true, false)

    const selectedParent = this.renderScene.getSelectionManager().getSelected(true)
    const allSelectedParts = this.renderScene.getSelectionManager().getSelected()
    selectedParent.getChildTransformNodes(true).forEach((tn) => {
      tn.setParent(null)
    })

    this.isRunning = true
    let isMarked = false
    const instancingResult = []
    const axisData = gridData[0].axisData
    const { mainAxis, secondaryAxis, tertiaryAxis } = this.getAxisOrder(axisData)
    const groupMeshInfo: Map<string, Array<{ instancesTransformations: number[]; id: string }>> = new Map()
    const wrappers = this.getArrayWrappers(gridData, independent)
    const idsOfAllWrappers = wrappers.map((wrapper) => wrapper.metadata.combinedId).join(ITEM_ID_PREFIX_DELIMITER)
    const allClonedBuildPlanItems: TransformNode[] = []

    for (const wrapper of wrappers) {
      let instances = []
      const { minimalShift } = this.getExistingGapAndIndices(wrapper.metadata.combinedId, axisData, independent)

      this.clearExtraInstances(wrapper.metadata.combinedId, axisData)
      const isContinue = this.checkForUseExisting(wrapper.metadata.combinedId, axisData, independent)
      if (isContinue) {
        instances = this.actualizeInstancesArray(wrapper.metadata.combinedId, [
          tertiaryAxis.axisName,
          secondaryAxis.axisName,
          mainAxis.axisName,
        ])

        this.actualizeMeshInfo(instances, groupMeshInfo)
      } else {
        let specialInstancesId = wrapper.metadata.combinedId
        let useSplit = false
        if (this.isLastGridIndependent !== independent) {
          // was independent but now dependent
          if (!independent) {
            specialInstancesId = idsOfAllWrappers
            useSplit = true
          } else {
            // was dependent but now independent
            specialInstancesId = idsOfAllWrappers
          }
        }

        this.clearSpecialInstances(specialInstancesId, useSplit)
      }
      const mainPosition = wrapper.absolutePosition.clone()

      for (let k = 0; k < tertiaryAxis.amount; k += 1) {
        const plane = instances[k] || []
        for (let i = 0; i < secondaryAxis.amount; i += 1) {
          const row = plane[i] || []
          for (let j = 0; j < mainAxis.amount; j += 1) {
            try {
              await occasionalSleeper()
              if (row[j]) {
                continue
              }

              if (i === 0 && j === 0 && k === 0) {
                row.push(wrapper)
                continue
              }

              this.cancelationCheck()

              const cloneWrapper = this.createWrapperCopy(wrapper)
              const duplicateGridIndices = {}
              duplicateGridIndices[mainAxis.axisName] = j
              duplicateGridIndices[secondaryAxis.axisName] = i
              duplicateGridIndices[tertiaryAxis.axisName] = k
              cloneWrapper.metadata.duplicateGridIndices = duplicateGridIndices

              // In this case we calculate minimal main shift to use it in other instances
              if (i === 0 && j === 1 && k === 0) {
                const newPosition = mainPosition.clone()
                cloneWrapper.setAbsolutePosition(newPosition)
                cloneWrapper.computeWorldMatrix(true)
                ;(this.renderScene as RenderScene).setMeshVisibilityRec(cloneWrapper, false, false, true, true, true)

                const delta = this.calculateDuplicateDeltaByAABB(
                  [wrapper],
                  cloneWrapper,
                  mainAxis.axisName,
                  mainAxis.spacing,
                )

                this.cancelationCheck()

                await occasionalSleeper()

                minimalShift[mainAxis.axisName] = delta[mainAxis.axisName]
                // Add instance shift
                const shiftedPosition = cloneWrapper.absolutePosition.clone()
                shiftedPosition[mainAxis.axisName] += mainAxis.spacing
                cloneWrapper.setAbsolutePosition(shiftedPosition)
                cloneWrapper.computeWorldMatrix(true)
                cloneWrapper.getChildTransformNodes().forEach((node) => node.computeWorldMatrix(true))
                ;(this.renderScene as RenderScene).setMeshVisibilityRec(cloneWrapper, true, false, true, true, true)

                clearanceDuplicate.measureDistance({
                  wrapper,
                  cloneWrapper,
                  shiftedPosition,
                  axisName: mainAxis.axisName,
                  duplicateMode: DuplicateMode.BoundingBoxes,
                })
                // In this case we calculate minimal secondary axis shift to use it in other instances
              } else if (i === 1 && j === 0 && k === 0) {
                const newPosition = mainPosition.clone()
                cloneWrapper.setAbsolutePosition(newPosition)
                cloneWrapper.computeWorldMatrix(true)
                ;(this.renderScene as RenderScene).setMeshVisibilityRec(cloneWrapper, false, false, true, true, true)

                await occasionalSleeper()

                // Move part until it is stop colliding with near instances
                const delta = this.calculateDuplicateDeltaByAABB(
                  [wrapper, plane[i - 1][j + 1]],
                  cloneWrapper,
                  secondaryAxis.axisName,
                  secondaryAxis.spacing,
                )
                minimalShift[secondaryAxis.axisName] = delta[secondaryAxis.axisName]

                // Add instance shift to mesh positoin
                const shiftedPosition = cloneWrapper.absolutePosition.clone()
                shiftedPosition[secondaryAxis.axisName] += secondaryAxis.spacing
                cloneWrapper.setAbsolutePosition(shiftedPosition)
                cloneWrapper.computeWorldMatrix(true)
                cloneWrapper.getChildTransformNodes().forEach((node) => node.computeWorldMatrix(true))
                ;(this.renderScene as RenderScene).setMeshVisibilityRec(cloneWrapper, true, false, true, true, true)

                clearanceDuplicate.measureDistance({
                  wrapper,
                  cloneWrapper,
                  shiftedPosition,
                  axisName: secondaryAxis.axisName,
                  duplicateMode: DuplicateMode.BoundingBoxes,
                })
                // In this case we calculate minimal tertiary axis shift to use it in other instances
              } else if (i === 0 && j === 0 && k === 1) {
                const newPosition = mainPosition.clone()
                cloneWrapper.setAbsolutePosition(newPosition)
                cloneWrapper.computeWorldMatrix(true)
                ;(this.renderScene as RenderScene).setMeshVisibilityRec(cloneWrapper, false, false, true, true, true)

                const delta = this.calculateDuplicateDeltaByAABB(
                  [wrapper, instances[k - 1][i][j + 1], instances[k - 1][i + 1][j]],
                  cloneWrapper,
                  tertiaryAxis.axisName,
                  tertiaryAxis.spacing,
                )
                minimalShift[tertiaryAxis.axisName] = delta[tertiaryAxis.axisName]

                // Add instance shift to mesh positoin
                const shiftedPosition = cloneWrapper.absolutePosition.clone()
                shiftedPosition[tertiaryAxis.axisName] += tertiaryAxis.spacing
                cloneWrapper.setAbsolutePosition(shiftedPosition)
                cloneWrapper.computeWorldMatrix(true)
                cloneWrapper.getChildTransformNodes().forEach((node) => node.computeWorldMatrix(true))
                ;(this.renderScene as RenderScene).setMeshVisibilityRec(cloneWrapper, true, false, true, true, true)

                clearanceDuplicate.measureDistance({
                  wrapper,
                  cloneWrapper,
                  shiftedPosition,
                  axisName: tertiaryAxis.axisName,
                  duplicateMode: DuplicateMode.BoundingBoxes,
                })
              } else {
                const newCloneMeshPosition = Vector3.Zero()
                newCloneMeshPosition[mainAxis.axisName] =
                  mainPosition[mainAxis.axisName] + j * (mainAxis.spacing + minimalShift[mainAxis.axisName])
                newCloneMeshPosition[secondaryAxis.axisName] =
                  mainPosition[secondaryAxis.axisName] +
                  i * (secondaryAxis.spacing + minimalShift[secondaryAxis.axisName])
                newCloneMeshPosition[tertiaryAxis.axisName] =
                  mainPosition[tertiaryAxis.axisName] + k * (tertiaryAxis.spacing + minimalShift[tertiaryAxis.axisName])

                cloneWrapper.setAbsolutePosition(newCloneMeshPosition)
                cloneWrapper.computeWorldMatrix(true)
              }

              row[j] = cloneWrapper

              // actualize all world matrices in order to store correct hull data
              cloneWrapper.computeWorldMatrix(true)
              cloneWrapper.getChildTransformNodes().forEach((node) => node.computeWorldMatrix(true))
              cloneWrapper.getChildMeshes().forEach((child) => child.computeWorldMatrix(true))
              // update and cache hull bounding box info after the mesh has been transformed
              // calling this updates parentMesh.metadata.hull as well
              const clonedParts = cloneWrapper.getChildTransformNodes().filter(this.meshManager.isPartMesh)
              clonedParts.forEach((part) => {
                const initialTransformation = part.metadata.initialTransformation
                cloneWrapper.computeWorldMatrix(true)
                const relativeTransformation = this.meshManager.getRelativeTransformation(
                  part.getWorldMatrix(),
                  initialTransformation,
                )

                part.metadata.hullBInfo = this.meshManager.getHullBInfo(part)

                const cloneTransformation = []
                this.meshManager
                  .convertTranslationToMillimeters(relativeTransformation, part.metadata.unitFactor)
                  .transpose()
                  .toArray()
                  .forEach((item) => cloneTransformation.push(item))

                const meshInfo = groupMeshInfo.get(part.metadata.originBpItemId)
                if (meshInfo) {
                  meshInfo.push({
                    instancesTransformations: cloneTransformation,
                    id: part.id,
                  })
                  groupMeshInfo.set(part.metadata.originBpItemId, meshInfo)
                } else {
                  groupMeshInfo.set(part.metadata.originBpItemId, [
                    {
                      instancesTransformations: cloneTransformation,
                      id: part.id,
                    },
                  ])
                }

                part.getChildMeshes().map((m) => {
                  if (this.meshManager.isLabelMesh(m)) {
                    this.modelManager.labelMgr.translateLabelOrientation(m, true)
                  }
                })

                allClonedBuildPlanItems.push(part)
              })
            } catch (e) {
              if (e.message === CANCELATION_ERROR) {
                if (e.cause) {
                  const canceledAxisData: DuplicateData[] = [
                    { ...tertiaryAxis, amount: k + 1 },
                    { ...secondaryAxis, amount: i + 1 },
                    { ...mainAxis, amount: j + 1 },
                  ]
                  this.oldGapMap.set(wrapper.metadata.combinedId, { axisData: canceledAxisData, ...minimalShift })
                } else {
                  if (this.createdCopy) {
                    this.createdCopy
                      .getChildTransformNodes()
                      .filter(this.meshManager.isPartMesh)
                      .forEach((part) => {
                        // remove outdated meshes from the gpuPicker
                        if (this.renderScene.getGpuPicker()) {
                          this.renderScene.getGpuPicker().removePickingObjects(part.getChildMeshes())
                        }

                        this.scene.metadata.buildPlanItems.delete(part.metadata.buildPlanItemId)
                        this.scene.removeTransformNode(part)
                        part.dispose()
                      })

                    this.scene.removeTransformNode(this.createdCopy)
                    this.createdCopy.dispose()

                    this.removeWrapper(wrapper)
                  }
                }

                // Reselect selected parts in order to have selected group mesh
                this.renderScene.getSelectionManager().deselect()
                this.renderScene.getSelectionManager().select(
                  allSelectedParts.map((part) => ({ part })),
                  true,
                )
                this.isLastGridIndependent = independent
                ;(this.renderScene as RenderScene).stopAnimate()
                return
              }
            }
          }

          plane[i] = row
        }

        instances[k] = plane
      }

      isMarked = this.markDuplicateUnderBuildPlate(
        instances
          .flat(2)
          .map((instance) => instance.getChildTransformNodes().filter(this.meshManager.isPartMesh))
          .flat(),
      )

      this.oldGapMap.set(wrapper.metadata.combinedId, { axisData, ...minimalShift })
      this.removeWrapper(wrapper)
    }

    if (allClonedBuildPlanItems.length) {
      this.renderScene.checkCollision(allClonedBuildPlanItems, true, true)
    }

    groupMeshInfo.forEach((meshInfo, buildPlanItemId) => {
      instancingResult.push({ buildPlanItemId, meshInfo })
    })

    eventBus.$emit(BuildPlanEvents.DuplicateToolStateChanged, {
      instances: instancingResult,
      isDuplicateUnderBuildPlate: isMarked,
    })
    this.onSetInstancingIsRunning.trigger(false)
    this.isLastGridIndependent = independent
    if (isMarked) {
      messageService.showErrorMessage('One or more duplicated meshes are partially or fully under build plate')
    }

    // Reselect selected parts in order to have selected group mesh
    this.renderScene.getSelectionManager().deselect()
    this.renderScene.getSelectionManager().select(
      allSelectedParts.map((part) => ({ part })),
      true,
    )

    this.isRunning = false
    ;(this.renderScene as RenderScene).stopAnimate()
  }

  finishInstancingProcess(payload: { items: IBuildPlanItem[]; singleSelection: boolean }) {
    const { items, singleSelection } = payload
    const itemsCopy = [...items]
    this.oldGapMap.clear()
    /**
     * Here should be removed spacing dimensions for all instances
     */

    const clearanceManager = (this.renderScene as RenderScene).getClearanceManager()
    clearanceManager.clearClearances((mode) => mode === ClearanceModes.Duplicate)

    const wrappedInstances = this.scene.transformNodes.filter(
      (m) => this.meshManager.isDuplicateWrapper(m) && m.metadata.isInstancingInProgress,
    )

    const instances: TransformNode[] = []
    const deleteCollisionInsights: string[] = []
    for (const [index, wrappedInstance] of wrappedInstances.entries()) {
      const parts = wrappedInstance.getChildTransformNodes(false, this.meshManager.isPartMesh)
      instances.push(...parts)
      deleteCollisionInsights.push(...parts.map((part) => part.metadata.buildPlanItemId))
      this.removeWrapper(wrappedInstance)
      parts.forEach((instance) => {
        delete instance.metadata.duplicateGridIndices
        delete instance.metadata.originBpItemId
        // remove temporary bpItemId
        this.scene.metadata.buildPlanItems.delete(instance.metadata.buildPlanItemId)
        const initialTransformation = instance.metadata.initialTransformation
        const relativeTransformation = this.meshManager.getRelativeTransformation(
          instance.getWorldMatrix(),
          initialTransformation,
        )

        const itemIndex = itemsCopy.findIndex((item) => {
          const relativeMatrix = Matrix.FromArray(item.transformationMatrix).transpose()
          return relativeTransformation.equals(relativeMatrix) && item.part.id === instance.metadata.partId
        })
        const bpItem = itemsCopy[itemIndex]
        itemsCopy.splice(itemIndex, 1)

        const buildPlanItemId = bpItem.id

        // remove outdated meshes from the gpuPicker
        if (this.renderScene.getGpuPicker()) {
          this.renderScene.getGpuPicker().removePickingObjects(instance.getChildMeshes())
        }

        instance.metadata.buildPlanItemId = buildPlanItemId
        this.scene.metadata.buildPlanItems.set(buildPlanItemId, instance)
        instance.computeWorldMatrix(true)

        const labels = instance.getChildMeshes().filter((item) => this.meshManager.isLabelMesh(item))
        if (items[index].labels && items[index].labels.length) {
          items[index].labels.forEach((labelDto, ind) => (labels[ind].id = labelDto.id))
        }

        // update supports metadata for duplicated items with the new buildPlanItemId
        // for further correct collision detection
        const supportParentMesh = instance.getChildTransformNodes(true).find((m) => this.meshManager.isSupportMesh(m))
        if (supportParentMesh) supportParentMesh.metadata.buildPlanItemId = buildPlanItemId

        // add new instance to the picking scene
        if (this.renderScene.getGpuPicker()) {
          this.renderScene.getGpuPicker().addPickingObjects(instance.getChildMeshes())
        }

        this.modelManager.refreshSupportInsights()
      })
    }

    ;(this.renderScene as RenderScene).getInsightsManager().deleteCollisionInsights(deleteCollisionInsights, true)
    this.modelManager.labelMgr.refreshLabelInsights()

    if (singleSelection) {
      this.renderScene.getSelectionManager().select([{ part: instances[instances.length - 1] }], true)
    } else {
      for (const [index, instance] of instances.entries()) {
        this.renderScene.getSelectionManager().select([{ part: instance }], true)
      }
    }

    this.renderScene.getSelectionManager().showGizmos()
    this.onSetInstancingIsRunning.trigger(false)
    setTimeout(() => (this.renderScene as RenderScene).animate(), 0)
  }

  public addDuplicatedPartsToGPUPicker() {
    const gpuPicker = this.renderScene.getGpuPicker()
    if (gpuPicker) {
      const wrappedInstances = this.scene.transformNodes.filter(
        (m) => this.meshManager.isDuplicateWrapper(m) && m.metadata.isInstancingInProgress,
      )

      wrappedInstances.forEach((wrappedInstance) => {
        const parts = wrappedInstance.getChildTransformNodes(false, this.meshManager.isPartMesh)
        parts.forEach((instance) => {
          gpuPicker.addPickingObjects(instance.getChildMeshes())
        })
      })

      setTimeout(() => (this.renderScene as RenderScene).animate(), 0)
    }
  }

  public removeDuplicatedPartsFromGPUPicker() {
    const gpuPicker = this.renderScene.getGpuPicker()
    if (gpuPicker) {
      const wrappedInstances = this.scene.transformNodes.filter(
        (m) => this.meshManager.isDuplicateWrapper(m) && m.metadata.isInstancingInProgress,
      )

      wrappedInstances.forEach((wrappedInstance) => {
        const parts = wrappedInstance.getChildTransformNodes(false, this.meshManager.isPartMesh)
        parts.forEach((instance) => {
          gpuPicker.removePickingObjects(instance.getChildMeshes())
        })
      })

      setTimeout(() => (this.renderScene as RenderScene).animate(), 0)
    }
  }

  async clearUnfinishedInstances() {
    if (this.cancelationPromise) {
      await this.cancelationPromise.promise
      this.cancelationPromise = null
    }

    const wrappedInstances = this.scene.transformNodes.filter(
      (mesh) => this.meshManager.isDuplicateWrapper(mesh) && mesh.metadata.isInstancingInProgress,
    )

    this.oldGapMap.clear()

    const deleteCollisionInsights: string[] = []
    for (const wrappedInstance of wrappedInstances) {
      const parts = wrappedInstance.getChildTransformNodes(false, this.meshManager.isPartMesh)
      deleteCollisionInsights.push(...parts.map((part) => part.metadata.buildPlanItemId))
      parts.forEach((instance) => {
        // remove outdated meshes from the gpuPicker
        if (this.renderScene.getGpuPicker()) {
          this.renderScene.getGpuPicker().removePickingObjects(instance.getChildMeshes())
        }

        this.scene.metadata.buildPlanItems.delete(instance.metadata.buildPlanItemId)
        this.scene.removeTransformNode(instance)
        instance.dispose()
      })

      this.scene.removeTransformNode(wrappedInstance)
      wrappedInstance.dispose()
    }
    ;(this.renderScene as RenderScene).getInsightsManager().deleteCollisionInsights(deleteCollisionInsights, true)

    /**
     * Here should be removed spacing dimensions for all instances
     */

    const clearanceManager = (this.renderScene as RenderScene).getClearanceManager()
    clearanceManager.clearClearances((mode) => mode === ClearanceModes.Duplicate)
  }

  cancelDuplicateProcess() {
    if (this.isRunning && !this.cancelationPromise) {
      this.cancelationPromise = this.createCancelationPromise()
    }
  }

  private createCancelationPromise() {
    let done: Function
    const promise = new Promise<void>((resolve) => {
      done = resolve
    })

    return { done, promise }
  }

  private async calculateDuplicateDelta(
    origins: TransformNode[],
    clone: TransformNode,
    axis: DuplicateAxes,
    spacing: number,
  ) {
    const meshes = []
    origins.forEach((origin) => meshes.push(...origin.getChildMeshes()))
    const boundingInfo = this.meshManager.getTotalBoundingInfo(meshes, true, true)
    let left = clone.absolutePosition[axis]
    let right =
      spacing >= 0
        ? left + 2 * boundingInfo.boundingBox.extendSize[axis]
        : left - 2 * boundingInfo.boundingBox.extendSize[axis]
    let next = (left + right) / 2

    const cloneGroup = clone.getChildTransformNodes().filter(this.meshManager.isPartMesh)
    const originsGroup = origins
      .map((origin) => origin.getChildTransformNodes().filter(this.meshManager.isPartMesh))
      .flat()

    while (Math.abs(right - left) > Epsilon) {
      this.cancelationCheck(false)
      await occasionalSleeper()

      const position = clone.absolutePosition.clone()

      position[axis] = next
      clone.setAbsolutePosition(position)
      clone.computeWorldMatrix(true)
      await occasionalSleeper()

      if (this.checkPartsToPartsCollision(cloneGroup, originsGroup)) {
        left = next
        next = (left + right) / 2
      } else {
        right = next
        next = (left + right) / 2
      }
    }

    // We need to shift final possition by allowed error in order to guarantee absence of collisions
    const shiftedPosition = clone.absolutePosition.clone()

    shiftedPosition[axis] = spacing >= 0 ? next + ALLOWED_ERROR : next - ALLOWED_ERROR
    clone.setAbsolutePosition(shiftedPosition)
    clone.computeWorldMatrix(true)
    return clone.absolutePosition.subtract(origins[0].absolutePosition)
  }

  private calculateDuplicateDeltaByAABB(
    origins: TransformNode[],
    clone: TransformNode,
    axis: DuplicateAxes,
    spacing: number,
  ) {
    const meshes = []
    origins.forEach((origin) => meshes.push(...origin.getChildMeshes()))
    const boundingInfo = this.meshManager.getTotalBoundingInfo(meshes, true, true)
    const shiftedPosition = clone.absolutePosition.clone()
    shiftedPosition[axis] +=
      spacing >= 0 ? boundingInfo.boundingBox.extendSize[axis] * 2 : -boundingInfo.boundingBox.extendSize[axis] * 2
    clone.setAbsolutePosition(shiftedPosition)
    clone.computeWorldMatrix(true)
    return clone.absolutePosition.subtract(origins[0].absolutePosition)
  }

  private checkPartsToPartsCollision(firstGroup: TransformNode[], secondGroup: TransformNode[]): boolean {
    return secondGroup.some((meshFromSecondGroup) =>
      firstGroup.some((meshFromFirstGroup) =>
        this.checkCollisionUsingBoxAndBvh(
          meshFromFirstGroup.metadata.bvh,
          meshFromSecondGroup.metadata.bvh,
          meshFromFirstGroup,
          meshFromSecondGroup,
        ),
      ),
    )
  }

  private checkCollisionUsingBoxAndBvh(rootA, rootB, parentMeshA, parentMeshB) {
    if (parentMeshA.metadata && parentMeshA.metadata.hull) {
      parentMeshA.computeWorldMatrix(true)
      parentMeshA.metadata.hullBInfo = this.meshManager.getHullBInfo(parentMeshA)
    }

    if (parentMeshB.metadata && parentMeshB.metadata.hull) {
      parentMeshB.computeWorldMatrix(true)
      parentMeshB.metadata.hullBInfo = this.meshManager.getHullBInfo(parentMeshB)
    }

    const aabbA = parentMeshA.metadata.hullBInfo
      ? parentMeshA.metadata.hullBInfo.boundingBox
      : parentMeshA.metadata.bvh.bInfo.boundingBox
    const aabbB = parentMeshB.metadata.hullBInfo
      ? parentMeshB.metadata.hullBInfo.boundingBox
      : parentMeshB.metadata.bvh.bInfo.boundingBox

    const supportMeshA = parentMeshA.getChildTransformNodes(true).find((m) => this.meshManager.isSupportMesh(m))
    if (supportMeshA) {
      supportMeshA.metadata.hullBInfo = this.meshManager.getHullBInfo(supportMeshA)
    }

    const aabbSA = supportMeshA
      ? supportMeshA.metadata.hullBInfo
        ? supportMeshA.metadata.hullBInfo.boundingBox
        : supportMeshA.metadata.bvh.bInfo.boundingBox
      : null

    const supportMeshB = parentMeshB.getChildTransformNodes(true).find((m) => this.meshManager.isSupportMesh(m))
    if (supportMeshB) {
      supportMeshB.metadata.hullBInfo = this.meshManager.getHullBInfo(supportMeshB)
    }

    const aabbSB = supportMeshB
      ? supportMeshB.metadata.hullBInfo
        ? supportMeshB.metadata.hullBInfo.boundingBox
        : supportMeshB.metadata.bvh.bInfo.boundingBox
      : null

    if (aabbA && aabbB && rootA && rootB) {
      let isCollided = false
      // part to part
      if (BoundingBox.Intersects(aabbA, aabbB)) {
        isCollided = this.renderScene.getObbTree().bvhCollision(rootA, rootB, parentMeshA, parentMeshB)
      }

      // // partA to supportB
      if (!isCollided && aabbSB && BoundingBox.Intersects(aabbA, aabbSB)) {
        isCollided = this.renderScene
          .getObbTree()
          .bvhCollision(rootA, supportMeshB.metadata.bvh, parentMeshA, supportMeshB)
      }

      // // partB to supportA
      if (!isCollided && aabbSA && BoundingBox.Intersects(aabbB, aabbSA)) {
        isCollided = this.renderScene
          .getObbTree()
          .bvhCollision(rootB, supportMeshA.metadata.bvh, parentMeshB, supportMeshA)
      }

      // // supportA to supportB
      if (!isCollided && aabbSA && aabbSB && BoundingBox.Intersects(aabbSA, aabbSB)) {
        isCollided = this.renderScene
          .getObbTree()
          .bvhCollision(supportMeshA.metadata.bvh, supportMeshB.metadata.bvh, supportMeshA, supportMeshB)
      }

      return isCollided
    }
  }

  private createMeshCopy(mainPart: TransformNode, partId: string, copySupports: boolean = true) {
    const doc = this.modelManager.getLoadedDocuments().find((loadedDocument) => loadedDocument.partId === partId)
    const componentsId = doc.document.components.id
    const mainDocument = this.modelManager.getDocuments().get(componentsId)

    const temporaryBpItemId = createGuid()
    this.modelManager.render({
      partId,
      buildPlanItemId: temporaryBpItemId,
      partName: mainPart.metadata.partName,
      component: mainDocument.model.components,
      parts: mainDocument.parts,
    })
    const cloneMesh = this.meshManager.getBuildPlanItemMeshById(temporaryBpItemId)

    cloneMesh.metadata.initialTransformation = cloneMesh.getWorldMatrix().clone()
    // cloneMesh.metadata.isInstancingInProgress = true
    cloneMesh.metadata.bvh = this.modelManager.getBvhCache().get(componentsId)
    cloneMesh.metadata.hull = this.modelManager.getHullCache().get(componentsId)
    cloneMesh.metadata.hullBInfo = this.meshManager.getHullBInfo(cloneMesh)
    cloneMesh.metadata.isNonDefaultScaleFactor = mainPart.metadata.isNonDefaultScaleFactor
    cloneMesh.metadata.maxDistanceFromPlate = mainPart.metadata.maxDistanceFromPlate
    cloneMesh.metadata.originBpItemId = mainPart.metadata.buildPlanItemId
    cloneMesh.getChildMeshes().forEach((child) => {
      if (this.meshManager.isComponentMesh(child)) {
        const mainBody = mainPart
          .getChildMeshes()
          .find(
            (m) =>
              this.meshManager.isComponentMesh(m) &&
              m.metadata.componentId === child.metadata.componentId &&
              m.metadata.geometryId === child.metadata.geometryId,
          )
        child.metadata.bodyType = mainBody.metadata.bodyType

        this.meshManager.createTransparentClone(child as InstancedMesh, false)
        this.meshManager.setIsHidden(
          child as InstancedMesh,
          mainBody.metadata.isHidden,
          mainBody.metadata.showAsTransparent,
        )
      }
    })

    const supportsExist = mainPart
      .getChildTransformNodes()
      .some((mesh) => mesh.name === SUPPORT_PARENT || mesh.name === SUPPORT)

    if (copySupports && supportsExist) {
      const mainPartOverhangMesh = (mainPart.getChildMeshes() as InstancedMesh[]).find((mesh) =>
        this.meshManager.isOverhangSurface(mesh),
      )
      if (mainPartOverhangMesh) {
        this.modelManager.overhangMgr.duplicateOverhangMesh(mainPartOverhangMesh, cloneMesh)
      }

      this.modelManager.supportMgr.duplicateSupports(mainPart, cloneMesh)

      const mainPartMetadata = mainPart.metadata as IPartMetadata
      if (mainPartMetadata.failedOverhangZones && mainPartMetadata.failedOverhangZones.length > 0) {
        cloneMesh.metadata.failedOverhangZones = [...mainPartMetadata.failedOverhangZones]
      }
    }

    const srcLabels = mainPart.getChildMeshes().filter((child) => child.name === LABEL) as InstancedMesh[]

    srcLabels.forEach((srcLabel) => {
      this.modelManager.labelMgr.copyLabelMesh(srcLabel.sourceMesh, uuidv4(), LABEL, cloneMesh)
    })

    cloneMesh.position = mainPart.position.clone()
    cloneMesh.rotationQuaternion = mainPart.rotationQuaternion.clone()
    cloneMesh.scaling = mainPart.scaling.clone()

    const mainScaleNode = (mainPart.parent as TransformNode).clone(PARAMETER_SET_SCALE_NAME, null, true)
    const parameterSetScale = new TransformNode(PARAMETER_SET_SCALE_NAME, this.scene)
    parameterSetScale.position = mainScaleNode.position
    parameterSetScale.rotationQuaternion = mainScaleNode.rotationQuaternion
    parameterSetScale.scaling = mainScaleNode.scaling
    cloneMesh.parent = parameterSetScale
    cloneMesh.metadata.parameterSetScaleNode = cloneMesh.parent

    cloneMesh.computeWorldMatrix(true)

    if (mainPart.metadata.constraints) {
      // new added part does not have this field
      // cloneMesh.metadata.constraints = JSON.parse(JSON.stringify(mainPart.metadata.constraints))
      cloneMesh.metadata.constraints = _.cloneDeep(mainPart.metadata.constraints)
    }

    return cloneMesh
  }

  /**
   * Temporary solution until collision manager will be able to check case when mesh is under build plate
   * @param meshes - Array of meshes
   */
  private markDuplicateUnderBuildPlate(meshes: AbstractMesh[]) {
    let isMarked = false
    meshes.forEach((mesh) => {
      if (!mesh.metadata) {
        return
      }

      const meshBBox = mesh.metadata.hullBInfo.boundingBox
      const axisOffset = meshBBox.centerWorld.subtract(mesh.position).z
      const groundBBox = this.renderScene.getBuildPlateManager().groundBox.getBoundingInfo().boundingBox
      const minPosition = groundBBox.maximumWorld.z + meshBBox.extendSizeWorld.z - axisOffset

      // In this case we should highlight a duplicate mesh and disable duplicate button
      if (Math.abs(Math.abs(mesh.position.z) - Math.abs(minPosition)) > Epsilon && mesh.position.z < minPosition) {
        isMarked = true
        mesh.metadata.color = FAILED_DUPLCIATE_COLOR
        for (const child of mesh.getChildMeshes()) {
          if (!this.meshManager.isComponentMesh(child)) {
            continue
          }

          if (mesh.metadata.color) {
            child.instancedBuffers.color = FAILED_DUPLCIATE_COLOR
            child.metadata.isMarkedByDuplicateManager = isMarked
          }
        }
      }
    })

    return isMarked
  }

  private getAxisOrder(axisData: DuplicateData[]) {
    const mainAxis = axisData.find((axis) => axis.isMain)
    const subordinateAxes = axisData.filter((axis) => !axis.isMain)

    const secondaryAxis = subordinateAxes[0].amount > 1 ? subordinateAxes[0] : subordinateAxes[1]
    const tertiaryAxis = subordinateAxes[0].amount > 1 ? subordinateAxes[1] : subordinateAxes[0]

    return { mainAxis, secondaryAxis, tertiaryAxis }
  }

  private checkForUseExisting(combinedId: string, axisData: DuplicateData[], independent: boolean = true): boolean {
    const oldGap = this.oldGapMap.get(combinedId)
    if (!oldGap || this.isLastGridIndependent !== independent) {
      return false
    }

    return axisData.every((axis) => {
      const oldAxis = oldGap.axisData.find((oldGapAxis) => oldGapAxis.axisName === axis.axisName)
      return oldAxis.isMain === axis.isMain && oldAxis.spacing === axis.spacing
    })
  }

  private getExistingGapAndIndices(combinedId: string, axisData: DuplicateData[], independent: boolean = true) {
    const oldGap = this.oldGapMap.get(combinedId)
    if (!oldGap || !this.checkForUseExisting(combinedId, axisData, independent)) {
      return { minimalShift: { x: 0, y: 0, z: 0 } }
    }

    return {
      minimalShift: {
        x: oldGap.x,
        y: oldGap.y,
        z: oldGap.z,
      },
    }
  }

  private async clearExtraInstances(combinedId: string, axisData: DuplicateData[]) {
    const { mainAxis, secondaryAxis, tertiaryAxis } = this.getAxisOrder(axisData)

    if (this.cancelationPromise) {
      await this.cancelationPromise.promise
      this.cancelationPromise = null
    }

    const tertiaryAxisLimit = tertiaryAxis.amount ? tertiaryAxis.amount - 1 : tertiaryAxis.amount
    const secondaryAxisLimit = secondaryAxis.amount ? secondaryAxis.amount - 1 : secondaryAxis.amount
    const mainAxisLimit = mainAxis.amount ? mainAxis.amount - 1 : mainAxis.amount

    const extraWrappedInstances = this.scene.transformNodes.filter(
      (mesh) =>
        this.meshManager.isDuplicateWrapper(mesh) &&
        mesh.metadata.isInstancingInProgress &&
        mesh.metadata.originCombinedId === combinedId &&
        (mesh.metadata.duplicateGridIndices[tertiaryAxis.axisName] > tertiaryAxisLimit ||
          mesh.metadata.duplicateGridIndices[secondaryAxis.axisName] > secondaryAxisLimit ||
          mesh.metadata.duplicateGridIndices[mainAxis.axisName] > mainAxisLimit),
    )

    const combinedIdsToRemove = extraWrappedInstances.map((instance) => instance.metadata.combinedId)
    const clearanceManager = (this.renderScene as RenderScene).getClearanceManager()
    clearanceManager.clearClearances(
      (mode) => mode === ClearanceModes.Duplicate,
      (clearance: Clearance) => {
        return combinedIdsToRemove.includes(clearance.to.combinedId)
      },
    )

    const deleteCollisionInsights: string[] = []
    for (const wrappedInstance of extraWrappedInstances) {
      const parts = wrappedInstance.getChildTransformNodes(false, this.meshManager.isPartMesh)
      deleteCollisionInsights.push(...parts.map((part) => part.metadata.buildPlanItemId))
      parts.forEach((instance) => {
        // remove outdated meshes from the gpuPicker
        if (this.renderScene.getGpuPicker()) {
          this.renderScene.getGpuPicker().removePickingObjects(instance.getChildMeshes())
        }

        this.scene.metadata.buildPlanItems.delete(instance.metadata.buildPlanItemId)
        this.scene.removeTransformNode(instance)
        instance.dispose()
      })

      this.scene.removeTransformNode(wrappedInstance)
      wrappedInstance.dispose()
    }
    ;(this.renderScene as RenderScene).getInsightsManager().deleteCollisionInsights(deleteCollisionInsights, true)
  }

  private async clearSpecialInstances(combinedId: string, useSplit: boolean) {
    if (this.cancelationPromise) {
      await this.cancelationPromise.promise
      this.cancelationPromise = null
    }

    const splittedIds = combinedId.split(ITEM_ID_PREFIX_DELIMITER)

    const wrappedInstances = this.scene.transformNodes.filter(
      (mesh) =>
        this.meshManager.isDuplicateWrapper(mesh) &&
        mesh.metadata.isInstancingInProgress &&
        (mesh.metadata.originCombinedId === combinedId ||
          (useSplit && splittedIds.includes(mesh.metadata.originCombinedId))),
    )

    const combinedIdsToRemove = wrappedInstances.map((instance) => instance.metadata.combinedId)
    const clearanceManager = (this.renderScene as RenderScene).getClearanceManager()
    clearanceManager.clearClearances(
      (mode) => mode === ClearanceModes.Duplicate,
      (clearance: Clearance) => {
        return combinedIdsToRemove.includes(clearance.to.combinedId)
      },
    )

    const deleteCollisionInsights: string[] = []
    for (const wrappedInstance of wrappedInstances) {
      const parts = wrappedInstance.getChildTransformNodes(false, this.meshManager.isPartMesh)
      deleteCollisionInsights.push(...parts.map((part) => part.metadata.buildPlanItemId))
      parts.forEach((instance) => {
        // remove outdated meshes from the gpuPicker
        if (this.renderScene.getGpuPicker()) {
          this.renderScene.getGpuPicker().removePickingObjects(instance.getChildMeshes())
        }

        this.scene.metadata.buildPlanItems.delete(instance.metadata.buildPlanItemId)
        this.scene.removeTransformNode(instance)
        instance.dispose()
      })

      this.scene.removeTransformNode(wrappedInstance)
      wrappedInstance.dispose()
    }
    ;(this.renderScene as RenderScene).getInsightsManager().deleteCollisionInsights(deleteCollisionInsights, true)
  }

  private actualizeInstancesArray(combinedId: string, axisOrder: string[]) {
    const instances = []
    const meshes = []
    let mainWrapper
    this.scene.transformNodes.forEach((mesh) => {
      if (this.meshManager.isDuplicateWrapper(mesh)) {
        if (mesh.metadata.combinedId === combinedId) {
          mainWrapper = mesh
        } else if (mesh.metadata.originCombinedId === combinedId) {
          meshes.push(mesh)
        }
      }
    })

    const [tertiaty, secondary, main] = axisOrder

    setNestedValue(instances, [0, 0, 0], mainWrapper)
    meshes.forEach((mesh) => {
      const indices = mesh.metadata.duplicateGridIndices
      setNestedValue(instances, [indices[tertiaty], indices[secondary], indices[main]], mesh)
    })

    return instances
  }

  private actualizeMeshInfo(
    instances: TransformNode[],
    groupMeshInfo: Map<string, Array<{ instancesTransformations: number[]; id: string }>>,
  ) {
    const flatWrappedInstances = instances.flat(2).filter((instance) => instance.metadata.isInstancingInProgress)

    flatWrappedInstances.forEach((wrappedInstance) => {
      wrappedInstance.getChildTransformNodes(false, this.meshManager.isPartMesh).forEach((instance) => {
        const initialTransformation = instance.metadata.initialTransformation
        instance.computeWorldMatrix(true)
        const relativeTransformation = this.meshManager.getRelativeTransformation(
          instance.getWorldMatrix(),
          initialTransformation,
        )

        const cloneTransformation = []
        this.meshManager
          .convertTranslationToMillimeters(relativeTransformation, instance.metadata.unitFactor)
          .transpose()
          .toArray()
          .forEach((item) => cloneTransformation.push(item))

        const meshInfo = groupMeshInfo.get(instance.metadata.originBpItemId)
        if (meshInfo) {
          meshInfo.push({
            instancesTransformations: cloneTransformation,
            id: instance.id,
          })
          groupMeshInfo.set(instance.metadata.originBpItemId, meshInfo)
        } else {
          groupMeshInfo.set(instance.metadata.originBpItemId, [
            {
              instancesTransformations: cloneTransformation,
              id: instance.id,
            },
          ])
        }
      })
    })
  }

  private cancelationCheck(storeDeltas: boolean = true) {
    if (this.cancelationPromise) {
      this.cancelationPromise.done()
      this.isRunning = false
      const cancelationError = new Error(CANCELATION_ERROR) as any
      cancelationError.cause = storeDeltas
      throw cancelationError as Error
    }
  }

  private getArrayWrappers(gridData: DuplicatePayload[], independent: boolean = true): TransformNode[] {
    let result = []
    let wrapper = new TransformNode(DUPLICATE_WRAPPER, this.scene)
    const combinedId = gridData.map((partData) => partData.buildPlanItemId).join(ITEM_ID_PREFIX_DELIMITER)
    wrapper.metadata = { combinedId }

    for (const partData of gridData) {
      const { buildPlanItemId } = partData
      const bpItem = this.meshManager.getBuildPlanItemMeshById(buildPlanItemId)
      if (independent) {
        wrapper = new TransformNode(DUPLICATE_WRAPPER, this.scene)
        wrapper.metadata = { combinedId: buildPlanItemId }
        result.push(wrapper)
      }

      ;(bpItem.parent as TransformNode).setParent(wrapper)
    }

    if (!independent) {
      result = [wrapper]
    }

    return result
  }

  private createWrapperCopy(wrapper: TransformNode) {
    const bpItems = wrapper.getChildTransformNodes(false, this.meshManager.isPartMesh)
    const copyWrapper = new TransformNode(DUPLICATE_WRAPPER, this.scene)
    bpItems.forEach((bpItem) => {
      const copyMesh = this.createMeshCopy(bpItem, bpItem.metadata.partId)
      ;(copyMesh.parent as TransformNode).setParent(copyWrapper)
    })

    const combinedId = copyWrapper
      .getChildTransformNodes(false, this.meshManager.isPartMesh)
      .map((part) => (part.metadata as IPartMetadata).buildPlanItemId)
      .join(ITEM_ID_PREFIX_DELIMITER)
    copyWrapper.metadata = { combinedId, originCombinedId: wrapper.metadata.combinedId, isInstancingInProgress: true }
    this.createdCopy = copyWrapper

    return copyWrapper
  }

  private removeWrapper(wrapped: TransformNode) {
    wrapped.getChildTransformNodes(true).forEach((node) => node.setParent(null))
    this.scene.removeTransformNode(wrapped)
    wrapped.dispose()
  }
}
