import Vue from 'vue'
import SnackbarMessage from '@/utils/snackbar/SnackbarMessage'
import { SNACKBAR_EVENT } from '@/constants'

const snackbarPlugin = {
  install: (vueObj: typeof Vue): void => {
    vueObj.prototype.$snackbar = {
      notify(message: SnackbarMessage) {
        window.dispatchEvent(
          new MessageEvent(SNACKBAR_EVENT, {
            data: message,
          }),
        )
      },
    }
  },
}

Vue.use(snackbarPlugin)
