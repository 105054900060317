
import Component from 'vue-class-component'
import Vue from 'vue'
import { RouterNames } from '@/router'
import TenantAdminNavigationPanel from '@/components/layout/admin/TenantAdminNavigationPanel.vue'
import TenantAdminTopBar from '@/components/layout/admin/TenantAdminTopBar.vue'
import TenantAdminSitesBreadcrumbs from '@/components/layout/admin/TenantAdminSitesBreadcrumbs.vue'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component({
  components: { TenantAdminSitesBreadcrumbs, TenantAdminNavigationPanel, TenantAdminTopBar },
})
export default class TenantAdmin extends Vue {
  @sitesStore.Getter isLoading: boolean

  get isSitesPage() {
    return this.$route.meta.routerName === RouterNames.SitesAdmin
  }
}
