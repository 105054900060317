var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-dialog',{attrs:{"width":"320","no-click-animation":"","persistent":""},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('div',{staticClass:"move-to-trash-error-modal white"},[_c('div',{staticClass:"move-to-trash-error-modal__header"},[_c('div',{staticClass:"text t-14 capitalize"},[_vm._v(_vm._s(_vm.header))])]),(_vm.areNoPermissionErrors)?_c('div',[(_vm.noPermissionItems.length)?_c('div',{staticClass:"text t-14"},[_vm._v(" "+_vm._s(_vm.isDeleteError ? _vm.$t('deleteErrorNoItemsPermissions') : _vm.$t('trashErrorNoItemsPermissions'))+" "),_c('ul',{class:{
              relatedItems: true,
              noPermissionRelatedItems: _vm.noPermissionRelatedItems.length,
            }},_vm._l((_vm.noPermissionItems),function(item){return _c('li',{key:item.id,staticClass:"text-truncate capitalize-first"},[_vm._v(" "+_vm._s(`${_vm.getItemTypeName(item)} ${item.name}`)+" ")])}),0)]):_vm._e(),(_vm.noPermissionRelatedItems.length)?_c('div',{class:{
            'text t-14 relatedItems': true,
            'mt-4': _vm.noPermissionItems.length,
          }},[_vm._v(" "+_vm._s(_vm.$t('trashErrorNoRelatedItemsPermissions'))+" "),_c('ul',{class:{
              relatedItems: true,
              noPermissionRelatedItems: _vm.noPermissionItems.length,
            }},_vm._l((_vm.noPermissionRelatedItems),function(item,i){return _c('li',{key:i,staticClass:"text-truncate capitalize-first"},[_vm._v(" "+_vm._s(`${_vm.getItemTypeName(item)} ${item.name}`)+" ")])}),0)]):_vm._e()]):_c('div',{staticClass:"text t-14 capitalize-first"},[(_vm.currentReasonItems.length)?_c('div',[(_vm.currentReasonItems.length > 1)?_c('div',[_vm._v(" "+_vm._s(_vm.bpOrVariantIsUsedByPrintedItemsMassage)+" "),_c('ul',{staticClass:"relatedItems"},_vm._l((_vm.currentReasonItems),function(item,i){return _c('li',{key:i,staticClass:"text-truncate capitalize-first"},[_vm._v(" "+_vm._s(`${_vm.getItemTypeName(item)} ${item.name}`)+" ")])}),0)]):_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.bpOrVariantIsUsedByPrintedItemMassage)+" ")])])]):(_vm.currentReasonTrashItem)?_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.bpOrVariantWasPrintedMassage)+" ")])]):_vm._e()]),_c('div',{staticClass:"move-to-trash-error-modal__buttons-container d-flex flex-row justify-center"},[_c('Button',{attrs:{"loading":_vm.isLoading},on:{"click":_vm.nextReason}},[_vm._v(_vm._s(_vm.nextReasonButtonText))])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }