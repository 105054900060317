var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isShow)?_c('div',[_c('v-dialog',{attrs:{"width":"400","persistent":""},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.remove.apply(null, arguments)}]},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('v-card',{staticClass:"white d-flex flex-column justify-space-between text t-14 generic-modal generic-modal__form pt24"},[_c('div',{staticClass:"generic-modal__header"},[_c('div',{staticClass:"text t-14 capitalize-first font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('tenantAdminMessages.printStrategy.controls.deletePrintStrategy'))+" ")])]),(_vm.isLoading)?_c('v-progress-circular',{staticClass:"pa-3 mx-auto",attrs:{"size":60,"width":2,"indeterminate":"","color":"blue"}}):(_vm.printStrategy)?[(_vm.isDeleteAvailable)?_c('v-card-text',{staticClass:"p0",domProps:{"innerHTML":_vm._s(
            _vm.$t('tenantAdminMessages.printStrategy.dialogs.confirmRemovePrintStrategy', { name: _vm.printStrategy.name })
          )}}):[_c('v-card-text',{staticClass:"p0",domProps:{"innerHTML":_vm._s(
              _vm.$t('tenantAdminMessages.printStrategy.dialogs.disabledRemovePrintStrategy', {
                name: _vm.printStrategy.name,
                count: _vm.relatedBuildPlansCount,
              })
            )}})],(_vm.isDeleteAvailable)?_c('div',{staticClass:"generic-modal__buttons-container d-flex flex-row justify-end"},[_c('Button',{attrs:{"data-test":"confirm-ok-button"},on:{"click":_vm.remove}},[_vm._v(_vm._s(_vm.$t('delete')))]),_c('Button',{staticClass:"ml-2",attrs:{"data-test":"confirm-cancel-button","color":"grey"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('cancel')))])],1):_c('div',{staticClass:"generic-modal__buttons-container d-flex flex-row justify-center"},[_c('Button',{attrs:{"data-test":"confirm-cancel-button","color":"primary"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('close')))])],1)]:[_c('v-card-text',{staticClass:"p0",domProps:{"innerHTML":_vm._s(_vm.$t('tenantAdminMessages.printStrategy.dialogs.noLongerExist'))}}),_c('div',{staticClass:"generic-modal__buttons-container d-flex flex-row justify-end"},[_c('Button',{staticClass:"ml-2",attrs:{"data-test":"confirm-cancel-button","color":"primary"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('cancel')))])],1)]],2)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }