
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/components/controls/Common/Button.vue'
import StoresNamespaces from '@/store/namespaces'
import { IBuildPlanLockDto } from '@/types/BuildPlans/IBuildPlan'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: {
    Button,
  },
})
export default class VariantIsLockedModal extends Vue {
  @fileExplorerStore.Getter isLoading: boolean

  @Prop() previewVariant: IBuildPlanLockDto
  @Prop() editVariant: IBuildPlanLockDto
  @Prop() onPreview: (variant: IBuildPlanLockDto) => Promise<void>
  @Prop() onEdit: (varaintId: string) => Promise<void>

  dialog: boolean = true

  async onCancelClick() {
    this.dialog = false
    this.onClose()
  }

  async onPreviewClick() {
    await this.onPreview(this.previewVariant)
    this.dialog = false
  }

  async onEditClick() {
    await this.onEdit(this.editVariant.id)
    this.dialog = false
  }

  onClose() {
    this.$emit('onClose')
  }
}
