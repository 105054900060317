import { InsightsSeverity } from '../Common/Insights'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { LabelInsightContent } from '@/types/InteractiveService/LabelMessageContent'
import { MarkingLocation } from '@/types/Label/enums'

export interface IBuildPlanInsight {
  id?: string
  itemId: string
  accepted: boolean
  severity: InsightsSeverity
  tool: ToolNames
  errorCode: number
  details: any
}

export enum InsightErrorCodes {
  LayoutToolPartIntersectsPart = 1001,
  LayoutToolPartIsNotConnected = 1002,
  LayoutToolPartIsBiggerThanChamber = 1003,
  LayoutToolPartIsPartiallyOutOfPlate = 1004,
  LayoutToolPartIsOutOfPlate = 1005,
  LayoutToolPartIsTouchingCeiling = 1006,
  LayoutToolPartIntersectsKeepOutZone = 1007,
  LayoutToolPartFloatsAboveBuildPlate = 1008,
  LayoutToolPartBiggerThanBuildVolume = 1009,
  LayoutToolPartAboveSafeDosingHeight = 1010,
  LayoutToolPartTallerThanSafeDosingHeight = 1011,
  SupportToolConstructionFailed = 3001,
  LabelToolHighlyCurvedArea = 2001,
  LabelToolDownFacingArea = 2002,
  LabelToolDoNotFit = 2003,
  LabelToolMinimumCount = 2004,
  LabelIntersectingWithBody = 2005,
  LabelToolNotCreated = 2006,
  LabelCloseToBody = 2007,
  LabelIsSelfIntersecting = 2008,
  SimCompToolMeshBridgingDetected = 5001,
  SimCompToolMeshInfo = 5002,
  SimCompToolCustomScaleFactor = 5003,
  SimCompToolMacroLayersWarn = 5004,
  SliceToolThinContour = 6001,
  SliceToolSmallVoidBetweenContours = 6002,
  SliceToolOverlappingContours = 6003,
  SliceToolSelfIntersectingContours = 6004,
  PartPropertiesNonDefaultScale = 7001,
  UnbalancedPart = 8001,
}

export interface IPendingInsights {
  tool: ToolNames
  insights: IBuildPlanInsight[]
  stateOnly?: boolean
}

export enum InsightGroups {
  ByInsight,
}

export interface IInsightSettings {
  showWarnings: boolean
  groupBy: InsightGroups
}
export const messageMapByInsight = {
  [InsightErrorCodes.LayoutToolPartIntersectsPart]: 'insightsLabels.layoutTool.byInsight.intersectsPart',
  [InsightErrorCodes.LayoutToolPartIsNotConnected]: 'insightsLabels.layoutTool.byInsight.isNotConnected',
  [InsightErrorCodes.LayoutToolPartIsBiggerThanChamber]: 'insightsLabels.layoutTool.byInsight.isBiggerThanBuildChamber',
  [InsightErrorCodes.LayoutToolPartIsPartiallyOutOfPlate]:
    'insightsLabels.layoutTool.byInsight.isPartiallyOutsideOfBuildPlate',
  [InsightErrorCodes.LayoutToolPartIsOutOfPlate]: 'insightsLabels.layoutTool.byInsight.isOutsideOfBuildPlate',
  [InsightErrorCodes.LayoutToolPartIsTouchingCeiling]: 'insightsLabels.layoutTool.byInsight.intersectsCeiling',
  [InsightErrorCodes.LayoutToolPartIntersectsKeepOutZone]: 'insightsLabels.layoutTool.byInsight.intersectsKeepOutZone',
  [InsightErrorCodes.LayoutToolPartFloatsAboveBuildPlate]: 'insightsLabels.layoutTool.byInsight.floatsAboveBuildPlate',
  [InsightErrorCodes.LayoutToolPartBiggerThanBuildVolume]: 'insightsLabels.layoutTool.byInsight.biggerThanBuildVolume',
  [InsightErrorCodes.LayoutToolPartAboveSafeDosingHeight]: 'insightsLabels.layoutTool.byInsight.aboveSafeDosingHeight',
  [InsightErrorCodes.LayoutToolPartTallerThanSafeDosingHeight]:
    'insightsLabels.layoutTool.byInsight.tallerThanSafeDosingHeight',
  [InsightErrorCodes.SupportToolConstructionFailed]: 'insightsLabels.supportTool.byInsights.supportConstructionFailed',
  [InsightErrorCodes.LabelToolDoNotFit]: 'insightsLabels.labelTool.byInsight.doNotFit',
  [InsightErrorCodes.LabelToolDownFacingArea]: 'insightsLabels.labelTool.byInsight.downFacingArea',
  [InsightErrorCodes.LabelToolHighlyCurvedArea]: 'insightsLabels.labelTool.byInsight.highlyCurvedArea',
  [InsightErrorCodes.LabelIntersectingWithBody]: 'insightsLabels.labelTool.byInsight.labelIntersectingWithBody',
  [InsightErrorCodes.LabelCloseToBody]: 'insightsLabels.labelTool.byInsight.labelCloseToBody',
  [InsightErrorCodes.LabelIsSelfIntersecting]: 'insightsLabels.labelTool.byInsight.labelSelfIntersecting',
  [InsightErrorCodes.LabelToolMinimumCount]: 'insightsLabels.labelTool.byInsight.minimumCount',
  [InsightErrorCodes.LabelToolNotCreated]: 'insightsLabels.labelTool.byInsight.notCreated',

  [InsightErrorCodes.SimCompToolMeshBridgingDetected]:
    'insightsLabels.simCompTool.byInsight.simCompToolMeshBridgingDetected',
  [InsightErrorCodes.SimCompToolCustomScaleFactor]: 'insightsLabels.simCompTool.byInsight.SimCompToolCustomScaleFactor',
  [InsightErrorCodes.SimCompToolMacroLayersWarn]: 'insightsLabels.simCompTool.byInsight.SimCompToolMacroLayersWarn',
  [InsightErrorCodes.SliceToolThinContour]: 'insightsLabels.SliceTool.byInsight.thinContour',
  [InsightErrorCodes.SliceToolSmallVoidBetweenContours]: 'insightsLabels.SliceTool.byInsight.smallVoidBetweenContours',
  [InsightErrorCodes.SliceToolOverlappingContours]: 'insightsLabels.SliceTool.byInsight.overlappingContours',
  [InsightErrorCodes.SliceToolSelfIntersectingContours]: 'insightsLabels.SliceTool.byInsight.selfIntersectingContours',
  [InsightErrorCodes.PartPropertiesNonDefaultScale]: 'insightsLabels.partProperties.byInsight.nonDefaultScale',
  [InsightErrorCodes.UnbalancedPart]: 'insightsLabels.stabilityInsights.instabilityReport',
}

export type labelInsights =
  | InsightErrorCodes.LabelToolDoNotFit
  | InsightErrorCodes.LabelToolDownFacingArea
  | InsightErrorCodes.LabelToolHighlyCurvedArea
  | InsightErrorCodes.LabelIntersectingWithBody
  | InsightErrorCodes.LabelCloseToBody
  | InsightErrorCodes.LabelIsSelfIntersecting
  | InsightErrorCodes.LabelToolMinimumCount
  | InsightErrorCodes.LabelToolNotCreated

export interface CachedLabelInsight {
  content: string[]
  labelSetId: string
  itemId?: {
    bpItemId: string
    componentId: string
    geometryId: string
    labelId: string
    autoLocation?: MarkingLocation
    manualPlacementId?: string
  }
}
