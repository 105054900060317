
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import SiteConstantUpdater from './SiteConstantUpdater.vue'

@Component({
  components: { SiteConstantUpdater },
})
export default class TableRowNumberUpdater extends Vue {
  @Prop() suffix: string
  @Prop({ default: false }) disabled: boolean
  @Prop() placeholder: string
  @Prop() label: string
  @Prop() min!: number
  @Prop() max!: number
  @Prop() step!: number
  @Prop({ type: [Object, String], default: '' }) readonly rules: object | string
  @Prop({ type: [Object, String], default: '' }) readonly customMessages: object | string
  @Prop({ default: false }) readonly alignConstantUpdaterActions: boolean
  @Prop({ default: false }) hideDetails: boolean
  @Prop() upperDescription: string
  @Prop() upperDescriptionLink: string
  @Prop() upperDescriptionIcon: string
  @Prop() bottomDescription: string
  @Prop() bottomDescriptionLink: string
  @Prop() bottomDescriptionIcon: string

  inEditMode: boolean = false
  model
  initialValue

  enterEditMode() {
    this.inEditMode = true
    this.model = this.$attrs.value
  }

  onCloseEditMode() {
    this.inEditMode = false
  }

  onUpdateSiteConstants() {
    this.$emit('onUpdateSiteConstants', this.model)
    this.inEditMode = false
  }
}
