import { Component, Vue } from 'vue-property-decorator'
import { isClickedInside } from '@/utils/clickOutside/clickOutside'

const DEFAULT_LEFT_MOUSE_BTN = 0

/**
 * This mixin contains logic for click outside menus, selectors, etc.
 *
 * Default vuetify v-click-outside directive cannot handle right-click
 * or middle-click outside of the component (Vuetify 2.5.8).
 * This is an old problem and there is no solution yet.
 * https://github.com/vuetifyjs/vuetify/issues/11982
 *
 * Use this mixin to solve this problem.
 */
@Component
export class ClickOutsideMixin extends Vue {
  closeMenu = null
  handler = null
  // left mouse click can be handled in the vuetify v-click-outside directive
  excludeLeftMouseBtnClick: boolean = true // this value can be overridden in the component

  /**
   * Set and remove listener if menuIsOpen is true
   * @param menuIsOpen This flag indicates whether the menu is closed.
   * @param closeMenu Optional parameter. This method will be called if the click is outside the component.
   * @param customHandler Optional parameter. This handler can be used instead of the default handler.
   */
  setListenerForClickOutside(menuIsOpen: boolean, closeMenu?: Function, customHandler?: Function) {
    // This solution uses pointerdown instead of mousedown.
    // Pointerdown does not block Babilon.js by default

    if (menuIsOpen) {
      this.handler = customHandler ? customHandler.bind(this) : this.detectClickOutsideMenuAndClose.bind(this)
      if (closeMenu) {
        this.closeMenu = closeMenu.bind(this)
      }

      document.addEventListener('pointerdown', this.handler)
    } else {
      if (this.handler) {
        document.removeEventListener('pointerdown', this.handler)
        this.handler = null
        this.closeMenu = null
      }
    }
  }

  /**
   * Default click-outside handler
   */
  detectClickOutsideMenuAndClose(event) {
    // check pressed button
    if (this.excludeLeftMouseBtnClick && event.button === DEFAULT_LEFT_MOUSE_BTN) {
      return
    }

    if (!isClickedInside(event) && this.closeMenu) {
      this.closeMenu()
    }
  }

  dialogOutsideClickHandler(
    event: PointerEvent,
    dialogClassName: string,
    chipClassName: string,
    updateElement: Function,
    close: Function,
  ) {
    const target = event.target as Element
    // checking if its not a click on canvas or start of spin or pan or zoom event
    // by detecting if its center or right mouse button
    if (target.tagName !== 'CANVAS' || event.button === 0) {
      const isInsideDialog = isClickedInside(event, dialogClassName)
      const isInsideChip = isClickedInside(event, chipClassName)
      if (!isInsideDialog && !isInsideChip) {
        updateElement()
        close()
      }
    }
  }

  beforeDestroy() {
    if (this.handler) {
      document.removeEventListener('pointerdown', this.handler)
    }
  }
}
