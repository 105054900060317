
import { RouterNames } from '@/router'
import Vue from 'vue'
import Component from 'vue-class-component'
import BuildPlanFooter from './buildPlans/footer/BuildPlanFooter.vue'
import DefaultAppFooter from './DefaultAppFooter.vue'
import { Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import PartPreviewFooter from './parts/footer/PartPreviewFooter.vue'

@Component({
  components: {
    BuildPlanFooter,
  },
})
export default class AppFooter extends Vue {
  footerComponent: any = DefaultAppFooter
  $router: any

  @Watch('$route', { immediate: true, deep: true })
  setfooterComponent(route: Route) {
    let footer = null
    const routerName = route && route.name ? route.name.toLowerCase() : ''
    switch (routerName) {
      case RouterNames.EditBuildPlan:
      case RouterNames.EBP_AddPart:
      case RouterNames.EBP_Orient:
      case RouterNames.EBP_Arrange:
      case RouterNames.EBP_Support:
      case RouterNames.EBP_SimulateCompensate:
      case RouterNames.EBP_Label:
      case RouterNames.EBP_Slice:
      case RouterNames.EBP_Print:
      case RouterNames.EBP_Publish:
      case RouterNames.EBP_Move:
      case RouterNames.EBP_Rotate:
      case RouterNames.EBP_Scale:
      case RouterNames.EBP_Constrain:
      case RouterNames.EBP_Duplicate:
      case RouterNames.EBP_Replace:
      case RouterNames.PreviewPrintOrder:
      case RouterNames.FE_EditIbcPlan:
      case RouterNames.IBC_Inspections:
      case RouterNames.IBC_DeviationCompensate:
        footer = BuildPlanFooter
        break
      case RouterNames.PartPreview:
        footer = PartPreviewFooter
        break
      default:
        footer = DefaultAppFooter
        break
    }
    this.footerComponent = footer
  }
}
