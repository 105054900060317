
import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import StoresNamespaces from '@/store/namespaces'
import { BuildPlanItemSupport, IBuildPlanItem, ISelectable } from '@/types/BuildPlans/IBuildPlan'
import { SelectablePartInsightMixin } from '@/components/layout/buildPlans/mixins/SelectablePartInsightMixin'
import { InsightMixin } from '@/components/layout/buildPlans/mixins/InsightMixin'
import { DisableableInsightMixin } from '@/components/layout/buildPlans/mixins/DisableableInsightMixin'
import Vue from 'vue'
import { RouterNames } from '@/router'
import { ROOT_FOLDER_ID } from '@/constants'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component
export default class SupportIssueByInsight extends Mixins(
  Vue,
  InsightMixin,
  SelectablePartInsightMixin,
  DisableableInsightMixin,
) {
  @buildPlansStore.Getter buildPlanItemById: (id: string) => IBuildPlanItem
  @buildPlansStore.Getter insightSelectionEnabled: boolean
  @buildPlansStore.Getter('getSelectedParts') selectedParts: ISelectable[]
  @buildPlansStore.Getter isSupportToolPreparing: boolean

  @visualizationStore.Mutation selectAndHighlightPart: (payload: {
    buildPlanItemId: string
    deselectIfSelected: boolean
    showGizmo: boolean
  }) => void
  @visualizationStore.Mutation toggleHighlight: (payload: { buildPlanItemId: string; highlight: boolean }) => void
  @visualizationStore.Mutation deselect: () => void

  @Prop() insight: IBuildPlanInsight

  get partName() {
    const bpItem = this.buildPlanItemById(this.insight.details.bpItemId)
    return bpItem.part.name
  }

  get failedOverhangSets() {
    const bpItem = this.buildPlanItemById(this.insight.details.bpItemId)
    const allSupports = JSON.parse(JSON.stringify(bpItem.supports)) as BuildPlanItemSupport[]

    if (!allSupports) {
      return this.insight.details.failedOverhangZones.join(', ')
    }

    return this.$t('insightsLabels.supportTool.byInsights.failedSupportSet', {
      XX: allSupports.filter((s) => this.insight.details.failedOverhangZones.includes(s.overhangElementName)).length,
    })
  }

  hoverIntoPart(buildPlanItemId: string) {
    this.toggleHighlight({ buildPlanItemId, highlight: true })
  }

  hoverOutOfPart(buildPlanItemId: string) {
    this.toggleHighlight({ buildPlanItemId, highlight: false })
  }

  clickOnPart(buildPlanItemId: string) {
    if (!this.insightSelectionEnabled || this.isPartSelectionDisabled) {
      return
    }

    const isPartSelected = this.selectedParts.find((part) => part.id === buildPlanItemId)

    if (isPartSelected && this.selectedParts.length === 1) {
      return
    }

    if (!isPartSelected || this.selectedParts.length > 1) {
      this.deselect()
    }

    this.selectAndHighlightPart({ buildPlanItemId, deselectIfSelected: true, showGizmo: true })
  }

  clickOnSet(buildPlanItemId: string) {
    this.clickOnPart(buildPlanItemId)
    // @ts-ignore
    this.$router.safePush({ name: RouterNames.EBP_Support, params: { failedFirst: true } })
  }
}
