import { Component, Vue } from 'vue-property-decorator'
import { isClickedInside } from '@/utils/clickOutside/clickOutside'

/**
 * This mixin contains logic for input outside element with the specified class name.
 */
@Component
export class InputOutsideMixin extends Vue {
  action = null
  handler = null

  /**
   * Set and remove listener if menuIsOpen is true
   * @param isActive This flag indicates whether the element is active.
   * @param elementClassName The class name of the element.
   * @param closeMenu Optional parameter. This method will be called if the input is outside the element.
   * @param customHandler Optional parameter. This handler can be used instead of the default handler.
   */
  setListenerForInputOutside(isActive: boolean, elementClassName: string, action?: Function, customHandler?: Function) {
    if (isActive) {
      this.handler = customHandler
        ? customHandler.bind(this)
        : this.detectInputOutsideAndClose.bind(this, elementClassName)
      if (action) {
        this.action = action.bind(this)
      }

      document.addEventListener('pointerdown', this.handler)
      document.addEventListener('wheel', this.handler)
      document.addEventListener('keydown', this.handler)
      window.addEventListener('resize', this.handler)
    } else {
      if (this.handler) {
        document.removeEventListener('pointerdown', this.handler)
        document.removeEventListener('wheel', this.handler)
        document.removeEventListener('keydown', this.handler)
        window.removeEventListener('resize', this.handler)
        this.handler = null
        this.action = null
      }
    }
  }

  /**
   * Default input-outside handler
   */
  detectInputOutsideAndClose(elementClassName, event) {
    if (event.type === 'resize' || (!isClickedInside(event, elementClassName) && this.action)) {
      this.action()
    }
  }

  beforeDestroy() {
    if (this.handler) {
      document.removeEventListener('pointerdown', this.handler)
      document.removeEventListener('wheel', this.handler)
      document.removeEventListener('keydown', this.handler)
      window.removeEventListener('resize', this.handler)
    }
  }
}
