import { DefectShapeType, PartDefect } from '@/types/Parts/IPartInsight'

export interface IComponent {
  id: string
  name: string
  transformation: number[]
}

export class GeometryDefect {
  shapes: DefectShape[]
  type: PartDefect

  // for old configs
  positions: number[][]
}

export class DefectShape {
  positions: number[][]
  type: DefectShapeType

  // for defect shape type = face
  segments: number[][][]
  entity: number
  cadID: string
}

export class Geometry {
  public id: string
  public name: string
  public path: string
  public type: GeometryTypes
  public transformation: number[]
  public defects: any
  public bar: boolean
}

export class Part {
  public id: string
  public name: string
  public geometries: Geometry[]
}

export class PartComponent implements IComponent {
  public id: string
  public name: string
  public transformation: number[]
  public partID: string

  constructor(id: string, name: string, transformation: number[], partID: string) {
    this.id = id
    this.name = name
    this.transformation = transformation
    this.partID = partID
  }
}

export class AssemblyComponent implements IComponent {
  public id: string
  public name: string
  public transformation: number[]
  public children: IComponent[]

  constructor(id: string, name: string, transformation: number[], children: IComponent[]) {
    this.id = id
    this.name = name
    this.transformation = transformation
    this.children = children
  }
}

export class DocumentModel {
  public parts: Part[]
  public components: IComponent
  public bvhJson: object
  public hullJson: object
}

export class BoundingBox {
  public minX: number
  public maxX: number
  public minY: number
  public maxY: number
  public minZ: number
  public maxZ: number
}

export class BoundingBox2D {
  xMin: number
  xMax: number
  yMin: number
  yMax: number
}

export enum GeometryTypes {
  Solid = 'solid',
  Sheet = 'sheet',
}
