
import { Component, Vue, Watch } from 'vue-property-decorator'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'
import { ContentViewModeTypes } from '@/visualization/types/ContentViewMode'
import Selector from '@/components/controls/Common/Selector.vue'
import {
  xAxisConfig,
  yAxisConfig,
  ChartType,
  ChartView,
  OANGLE_SURFACE_LINE_WIDTH,
  OANGLE_CONTOUR_LINE_WIDTH,
  SCALE_RANGE_COEFFICIENT,
} from '@/visualization/charts'
import echarts from '@/custom-echarts'

const visualizationStore = namespace(StoresNamespaces.Visualization)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const CHART_FULL_SCALE_UPPER = 100

@Component({ components: { Selector } })
export default class ChartsPanel extends Vue {
  @visualizationStore.Getter getChartsNames: string[]
  @visualizationStore.Getter getActiveChart: any
  @buildPlansStore.Getter getContentViewMode: ContentViewModeTypes

  @visualizationStore.Mutation setActiveChart: Function

  chartViewValues = ChartView
  chartView: ChartView = ChartView.Surface
  chartAutoScale: boolean = true

  // Uncomment when find workaround with Contour chart type
  // @Watch('chartView') onChartView() {
  //   if (this.getActiveChart.optimalAnglesAdded) {
  //     const angleChartType = this.chartView === ChartView.Surface ? 'scatter3d' : 'scatter'
  //     const lineWidth = this.chartView === ChartView.Surface ? OANGLE_SURFACE_LINE_WIDTH : OANGLE_CONTOUR_LINE_WIDTH
  //     const arrayToUpdate = Array.from({ length: this.getActiveChart.optimalAnglesAdded }, (_, i) => i + 1)
  //   }
  //   this.setChartScale(this.chartAutoScale)
  // }

  @Watch('chartName') onChartName() {
    this.drawChart()
  }

  @Watch('getContentViewMode') onContentViewModeChange() {
    if (this.getContentViewMode === ContentViewModeTypes.Charts) {
      this.$nextTick(() => this.drawChart())
    }
  }

  mounted() {
    this.drawChart()
  }

  get chartName() {
    return this.getActiveChart ? this.getActiveChart.name : undefined
  }

  set chartName(chartName: string) {
    this.setActiveChart(chartName)
  }

  get chartViewVisible() {
    // TODO Remove false when find workaround with Contour chart type
    return false && this.getActiveChart !== undefined && this.getActiveChart.type === ChartType.Surface
  }

  get scaleChartVisible() {
    return this.getActiveChart !== undefined && this.getActiveChart.hasOwnProperty('max')
  }

  get chartScaleLabel() {
    return this.chartAutoScale ? this.$t('chartFullScale') : this.$t('chartAutoScale')
  }

  beforeDestroy() {
    const chartContainer = document.getElementById('chart')
    const chart = echarts.getInstanceByDom(chartContainer)
    if (chart) {
      chart.clear()
      chart.dispose()
    }
  }

  changeChartScale() {
    this.chartAutoScale = !this.chartAutoScale
    this.setChartScale(this.chartAutoScale)
  }

  private setChartScale(autoScale: boolean) {
    let maxValue = this.getActiveChart.max

    maxValue =
      maxValue > CHART_FULL_SCALE_UPPER
        ? Math.floor(maxValue * SCALE_RANGE_COEFFICIENT)
        : CHART_FULL_SCALE_UPPER * SCALE_RANGE_COEFFICIENT

    let update
    const chart = echarts.getInstanceByDom(document.getElementById('chart'))
    if (this.getActiveChart.type === ChartType.Line) {
      update = {
        yAxis: {
          max: !autoScale ? maxValue : null,
          scale: autoScale,
        },
      }
    } else {
      update = {
        zAxis3D: {
          scale: autoScale,
        },
        visualMap: {
          min: !autoScale ? 0 : this.getActiveChart.min,
        },
      }
    }
    chart.setOption(update)
  }

  private getLinePlotOption(chartData) {
    return {
      title: {
        left: 'center',
        top: 15,
        text: chartData.title || chartData.name,
      },
      legend: {
        left: 'right',
        top: 'bottom',
      },
      tooltip: {
        valueFormatter: (value) => (value ? +value.toFixed(chartData.tolerance) : 0),
        axisPointer: {
          label: {
            precision: chartData.tolerance,
          },
          type: 'cross',
        },
      },
      xAxis: Object.assign({}, xAxisConfig, chartData.xAxisConfig),
      yAxis: Object.assign({}, yAxisConfig, chartData.yAxisConfig),
      series: chartData.chartData,
    }
  }

  private getSurfacePlotOption(chartData) {
    return {
      title: {
        left: 'center',
        top: 15,
        text: chartData.title || chartData.name,
      },
      legend: {
        left: 'right',
        top: 'bottom',
      },
      tooltip: {},
      grid3D: {},
      visualMap: {
        show: false,
        min: chartData.min,
        max: chartData.max,
        seriesIndex: 0,
        inRange: {
          color: chartData.colorscale,
        },
      },
      xAxis3D: Object.assign({}, xAxisConfig, chartData.xAxisConfig),
      yAxis3D: Object.assign({}, yAxisConfig, chartData.yAxisConfig),
      zAxis3D: Object.assign({}, chartData.zAxisConfig),
      series: chartData.chartData,
    }
  }

  private drawChart(): void {
    if (this.getContentViewMode !== ContentViewModeTypes.Charts) return

    this.chartAutoScale = true
    const currentChartData = this.getActiveChart
    if (currentChartData.type === ChartType.Surface && currentChartData.chartData[0].type !== this.chartView) {
      this.chartView = currentChartData.chartData[0].type
    }

    const chartContainer = document.getElementById('chart')
    const chart = echarts.getInstanceByDom(chartContainer) || echarts.init(chartContainer)

    const option =
      currentChartData.type === ChartType.Surface
        ? this.getSurfacePlotOption(currentChartData)
        : this.getLinePlotOption(currentChartData)

    chart.setOption(option, true)
  }
}
