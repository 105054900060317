/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Scene } from '@babylonjs/core/scene'
import {
  ISimulationPathData,
  IDataDirectoryStructure,
  ResultsManagerEvent as RMEvent,
} from '@/visualization/types/SimulationTypes'
import { OuterEvents } from '@/visualization/types/Common'
import { SummarySelectionManager } from '@/visualization/rendering/SummarySelectionManager'
import {
  COMPENSATION_STEP,
  dirToSimulationStep,
  ISimulationStep,
  sortSimulationSteps,
  StepType,
} from '@/types/Simulation/SimulationSteps'
import { ChartController } from '@/visualization/charts'
import { Engine } from '@babylonjs/core/Engines/engine'
import { getCompensationScales, getSimulationScales, ScaleRange } from '@/visualization/scales'
import { getSimulationSummary, ISummaryDetails } from '@/visualization/summary'
import {
  ADW_DIR,
  ANALYSIS_SETTINGS,
  BIN_DIR,
  BUILD_PLAN_METADATA,
  DATA_JSON,
  MESHING_FILE,
  PARTS_PVD,
  PART_JOB_METADATA,
  PART_TYPE,
  SINTER_NAME_PATTERN,
  SIZE_ADJUSTMENT_FACTOR,
  STL,
  SUPPORTS_PVD,
  SUPPORT_TYPE,
  VTP,
} from '@/constants'
import { NominalGeometryManager } from './NominalGeometryManager'
import { createGuid } from '@/utils/common'
import {
  IVisualizationServiceCommand,
  IVisualizationServiceMessage,
  ResultsManager,
  WsCommands,
} from '@/visualization/rendering/ResultsManager'
import { SimulationStepHandler } from '@/visualization/rendering/SimulationStepHandler'
import { Vector3 } from '@babylonjs/core/Maths'
import { SimCompVersion } from '@/visualization/rendering/SimCompVersion'

const BUILDPLATE_PVD = 'buildplate.pvd'
const COUPONS_PVD = 'coupons.pvd'
const LAYERS_NAME = 'Fields_After_Recoating'
const LAYERS_PATTERN = /.*(Fields_After_Recoating)[^]*vtp$/
const END_NUMBER_PATTERN = /_(\d+).(vtp|vtk)$/
const END_NUMBER_GROUP_LABEL_PATTERN = /(\d+)_\*.vtp$/
const AFTER_RELAXATION_PATTERN = /.*(Export_After_Relaxation)[^]*vtp$/
const EXCLUDE_PATTERN = 'results/bin|thermal|data|postpro_src'
const COMP_BUILD_PLATE_PATTERN = /simplebuildplate/
const COMP_SUPPORTS_PATTERN = /ovhg_triangles/
const SINTER_PLATE_PATTERN = /sinter_plate/
const SINTER_PART_PATTERN = /^(?!.*(sinter_plate))/

const VTK = '.vtk'
const WARPED_STLS_DIR = 'warped_stls'
const TRANSIENT_RESULTS_DIR = 'transient_results'
const SCRATCH_DIR = 'scratch_dir'
const PROCESS_PARAMETERS_JSON = 'process_parameters.json'
const VERSION_KEY = 'version'

export class SimulationManager extends ResultsManager {
  private meshJobNumber: number
  private compensationEntry: IDataDirectoryStructure
  private simulationScales: Map<string, Map<string, ScaleRange>>
  private compensationScales: Map<string, ScaleRange>
  private chartController: ChartController
  private nominalGeometryHandler: NominalGeometryManager
  private summary: ISummaryDetails
  private simDirectoryContent: IDataDirectoryStructure
  private getChartsCommand: IVisualizationServiceCommand
  private getStepsCommand: IVisualizationServiceCommand
  private getProcessParametersCommand: IVisualizationServiceCommand
  private isDmlmSimulation: boolean
  private summarySelection: SummarySelectionManager
  private settings
  private resultFilesInfo: { part: string; support?: string }
  private dataJsonSetFromS3: boolean

  constructor(scene: Scene, engine: Engine) {
    super(scene, engine)
    this.summarySelection = new SummarySelectionManager(this.scene, this.simulationSlicerManager)
    this.simulationScales = new Map<string, Map<string, ScaleRange>>()
    this.compensationScales = new Map<string, ScaleRange>()
    this.chartController = new ChartController()
    this.nominalGeometryHandler = new NominalGeometryManager(this)
    this.initCommands()
    this.onSlicerAdjusted = this.onSlicerAdjusted.bind(this)
    this.sliceManager.slicerAdjusted.on(this.onSlicerAdjusted)
    this.resultFilesInfo = { part: '' }
    this.dataJsonSetFromS3 = false
  }

  get isDMLM() {
    return this.isDmlmSimulation
  }

  get analysisSettings() {
    return this.settings
  }

  startVisualization() {
    if (!super.startVisualization()) return false

    if (this.currentSimulationStep.type === StepType.Compensation) {
      this.viewCompensationResults()
    } else {
      this.browseDataDirectory()
    }

    return true
  }

  clearResults() {
    super.clearResults()
    this.meshJobNumber = undefined
    this.nominalGeometryHandler.clear()
    this.summarySelection.clear()
    this.settings = undefined
    this.resultFilesInfo = { part: '' }
    this.dataJsonSetFromS3 = false
  }

  setSimulationPathData(pathData: ISimulationPathData) {
    this.setResultsPathData(pathData)
    this.meshJobNumber = pathData.meshJobNumber || this.meshJobNumber

    if (this.getCurrentSimulationStep()) {
      this.getCurrentSimulationStep().activateStep(true)
    }

    this.nominalGeometryHandler.hideAll()
  }

  handleOuterEvent(eventName: OuterEvents, payload: object) {
    if (super.handleOuterEvent(eventName, payload)) return true

    switch (eventName) {
      case OuterEvents.SimulationSlicerChanged:
        const { current } = payload as { current: number }
        this.sliceManager.changeCurrentSimulationSlicer(current)

        break

      case OuterEvents.SetResultsPathData:
        const simulationPathData = payload as ISimulationPathData
        this.setSimulationPathData(simulationPathData)
        break

      case OuterEvents.SimulationTimestampChanged:
        const { currentTimestamp } = payload as { currentTimestamp: number }
        if (this.getCurrentSimulationStep()) {
          this.getCurrentSimulationStep().changeTimeStamp(currentTimestamp)
        }

        break

      case OuterEvents.ShowBridgingElements:
        if (this.getCurrentSimulationStep()) {
          this.getCurrentSimulationStep().activateStep(false)
        }

        this.nominalGeometryHandler.toggleBridgingElements(true)
        break

      case OuterEvents.ShowNominalGeometry:
        const { visible } = payload as { visible: boolean }
        this.nominalGeometryHandler.toggleNominalGeometry(visible)
        break

      case OuterEvents.ShowCompensatedGeometry:
        const showCompensated = payload as { visible: boolean }
        this.nominalGeometryHandler.toggleCompensated(showCompensated.visible)
        break

      case OuterEvents.ShowGreenCompensatedGeometry:
        const showGreenCompensated = payload as { visible: boolean }
        this.nominalGeometryHandler.toggleGreenCompensated(showGreenCompensated.visible)
        break

      case OuterEvents.ShowGreenNominalGeometry:
        const showGreenNominal = payload as { visible: boolean }
        this.nominalGeometryHandler.toggleGreenNominal(showGreenNominal.visible)
        break

      case OuterEvents.ShowMeshing:
        if (this.getCurrentSimulationStep()) {
          this.getCurrentSimulationStep().activateStep(false)
        }

        const showMeshing = payload as { visible: boolean }
        this.nominalGeometryHandler.toggleMeshing(showMeshing.visible)
        break

      case OuterEvents.HightlightDetailsInfo:
        const highlightData = payload as { highlight; coords; type }
        if (highlightData.highlight) {
          this.summarySelection.hightlight(highlightData.coords, highlightData.type)
        } else {
          this.summarySelection.hide()
        }

        break

      case OuterEvents.SetWarpingParameters:
        const factor = payload as any as number
        if (this.getCurrentSimulationStep()) {
          this.getCurrentSimulationStep().setWarpingParameters(factor)
        }

        break

      case OuterEvents.SetDataJsonContent:
        this.setDataJsonFileContent(payload)
        break

      case OuterEvents.FetchResults:
        if (this.socket.connected) {
          this.helloCommandHandler()
        }
        break

      default:
        throw new Error('Unknown external error')
    }

    this.renderScene()
    return true
  }

  protected helloCommandHandler() {
    this.getStepsCommand.parameters = [this.buildPlanId, this.jobNumber, EXCLUDE_PATTERN]
    this.socket.emit('command', this.getStepsCommand)
  }

  protected initCommands() {
    super.initCommands()
    this.getChartsCommand = { id: createGuid(), name: WsCommands.GetChartsData }
    this.getStepsCommand = { id: createGuid(), name: WsCommands.FullBrowseDirectory }
    this.getProcessParametersCommand = { id: createGuid(), name: WsCommands.GetChartsData }

    this.commandMap.set(this.getStepsCommand.id, (msg: IVisualizationServiceMessage) => {
      this.handleGetSimulationSteps(msg.message.result)
    })

    this.commandMap.set(this.getChartsCommand.id, (msg: IVisualizationServiceMessage) => {
      const result = msg.message.result
      if (!result.success) {
        const errorMessage = result.error ? 'Charts file format error' : 'Charts data file missing'
        console.warn(errorMessage) // tslint:disable-line
        return
      }

      this.handleChartsAndScalesData(result.data)
    })

    this.commandMap.set(this.getProcessParametersCommand.id, (msg: IVisualizationServiceMessage) => {
      const result = msg.message.result
      if (!result.success) {
        const errorMessage = result.error ? 'Process parameters file format error' : 'Process parameters file missing'
        console.warn(errorMessage) // tslint:disable-line

        // We need to report all available simulation steps without correction if process parameters file
        // fails to load
        this.triggerEvent(RMEvent.SimulationStepsInitialized, { steps: this.stepsList })
        return
      }

      this.handleGetProcessParameters(result.data)
    })
  }

  private handleGetSimulationSteps(result: { dirContent: IDataDirectoryStructure; exportConfig }) {
    this.simDirectoryContent = result.dirContent
    this.isDmlmSimulation = this.simDirectoryContent.dirs[SCRATCH_DIR]
      ? !Object.keys(this.simDirectoryContent.dirs[SCRATCH_DIR].dirs).find(d => SINTER_NAME_PATTERN.test(d))
      : !Object.keys(this.simDirectoryContent.dirs).find(d => SINTER_NAME_PATTERN.test(d))

    const targetDir = this.isDmlmSimulation
      ? this.simDirectoryContent.dirs[TRANSIENT_RESULTS_DIR] || this.simDirectoryContent
      : this.simDirectoryContent.dirs[SCRATCH_DIR] || this.simDirectoryContent

    Object.keys(targetDir.dirs).forEach((d) => {
      const simulationStep = dirToSimulationStep(d)
      if (simulationStep && this.checkStepResultsAvailable(simulationStep, targetDir)) {
        this.addSimulationStep(simulationStep)
      }
    })

    this.checkCompensationFilesAvailable()
    sortSimulationSteps(this.stepsList)

    let meshAvailable
    const adwDir = this.simDirectoryContent.dirs[ADW_DIR]

    if (adwDir && adwDir.dirs[BIN_DIR]) {
      meshAvailable = adwDir.dirs[BIN_DIR].files.find((f) => f.label.toLowerCase() === MESHING_FILE) !== undefined
    }

    const simResultsUnavailable = this.stepsList.length === 0
    const meshJobAvailable = this.meshJobNumber !== undefined

    if (simResultsUnavailable) {
      console.warn('Simulation results directory is empty') // tslint:disable-line
      this.changeIsLoading.trigger({ isLoading: false })

      if (!meshAvailable && !meshJobAvailable) {
        this.resultsEvent.trigger({ event: RMEvent.ResultsAvailable, args: undefined })
      }
    } else {
      let chartsDir = result.dirContent
      if (!chartsDir.files.find(f => f.label === DATA_JSON) && chartsDir.dirs[SCRATCH_DIR]) {
        chartsDir = chartsDir.dirs[SCRATCH_DIR]
      }

      // Do not request data.json file content from paraviewweb service if it was set using data from S3
      if (!this.dataJsonSetFromS3) {
        this.getChartsCommand.parameters = [`${chartsDir.path.slice(1).join('/')}/${DATA_JSON}`]
        this.socket.emit('command', this.getChartsCommand)
      }

      let parametersDir = result.dirContent
      if (!parametersDir.files.find(f => f.label === PROCESS_PARAMETERS_JSON) && parametersDir.dirs[SCRATCH_DIR]) {
        parametersDir = parametersDir.dirs[SCRATCH_DIR]
      }

      this.getProcessParametersCommand.parameters = [
        `${parametersDir.path.slice(1).join('/')}/${PROCESS_PARAMETERS_JSON}`,
      ]
      this.socket.emit('command', this.getProcessParametersCommand)
    }

    if (meshAvailable && simResultsUnavailable) {
      this.nominalGeometryHandler.initPaths(this.simDirectoryContent, !this.isDmlmSimulation, null)
    }

    if (
      !meshJobAvailable ||
      this.getStepsCommand.parameters[1] === this.meshJobNumber ||
      !simResultsUnavailable ||
      meshAvailable
    ) {
      const results = { meshAvailable, exportData: result.exportConfig, available: !!this.stepsList.length }
      this.resultsEvent.trigger({ event: RMEvent.ResultsAvailable, args: results })
    }

    if (!meshAvailable && simResultsUnavailable && meshJobAvailable) {
      this.getStepsCommand.parameters = [this.buildPlanId, this.meshJobNumber, EXCLUDE_PATTERN]
      this.socket.emit('command', this.getStepsCommand)
    }
  }

  private handleGetProcessParameters(result) {
    this.settings = result[ANALYSIS_SETTINGS]
    if (this.settings && this.settings.itermax === 0) {
      const compensationIndex = this.stepsList.findIndex((step) => step.type === StepType.Compensation)
      if (compensationIndex !== -1) {
        this.stepsList.splice(compensationIndex, 1)
      }
    }

    if (!this.isDmlmSimulation) {
      const partsData = result[BUILD_PLAN_METADATA] && result[BUILD_PLAN_METADATA][PART_JOB_METADATA]

      if (partsData) {
        partsData.forEach((pd) => {
          pd.geometryFileDataList.forEach((g) => {
            const fileName = `${pd.buildPlanItemId}_${g.groupId}`
            if (g.fileType === PART_TYPE) {
              this.resultFilesInfo.part = fileName
            } else if (g.fileType === SUPPORT_TYPE) {
              this.resultFilesInfo.support = fileName
            }
          })
        })
      }
    }

    this.triggerEvent(RMEvent.SimulationStepsInitialized, { steps: this.stepsList })
    this.nominalGeometryHandler.initPaths(this.simDirectoryContent, !this.isDmlmSimulation, result)
  }

  private browseDataDirectory() {
    const targetDir = this.isDmlmSimulation
      ? this.simDirectoryContent.dirs[TRANSIENT_RESULTS_DIR] || this.simDirectoryContent
      : this.simDirectoryContent.dirs[SCRATCH_DIR] || this.simDirectoryContent
    const dirContent =
      targetDir.dirs[this.currentSimulationStep.value].dirs.results || targetDir.dirs[this.currentSimulationStep.value]

    const searchResult = this.getSimulationFileName(dirContent, this.currentSimulationStep)

    if (!(searchResult.parts || searchResult.coupons)) {
      console.warn('No suitable data to visualize') // tslint:disable-line
      this.changeIsLoading.trigger({ isLoading: false })
      return
    }

    const scalesInfo = this.simulationScales.get(this.currentSimulationStep.value)
    const step = new SimulationStepHandler(this, scalesInfo, searchResult)

    step.openDataSet()
    this.simulationStepsData.set(this.currentSimulationStep.value, step)
    this.triggerEvent(RMEvent.SimulationStepLoaded, this.currentSimulationStep.value)
    if (this.currentSimulationStep.type === StepType.DmlmBuild) {
      this.getPrintingLayers(dirContent)
    }
  }

  private checkCompensationFilesAvailable(): boolean {
    const adwDir = this.simDirectoryContent.dirs[ADW_DIR]
    if (!adwDir || !adwDir.dirs[BIN_DIR]) return

    const compensationResults = adwDir.dirs[BIN_DIR]
    let compensationAvailable = false

    const compensation = Object.assign({}, COMPENSATION_STEP)
    const partsCollection = this.getFileNamesByPattern(compensationResults, (d) => d.label.toLowerCase() === PARTS_PVD)
    const vtkFilesGroup = compensationResults.groups.find((g) => g.label.toLowerCase().endsWith(VTK))
    if (partsCollection || vtkFilesGroup) {
      compensationAvailable = true
      this.setSimulationStepSize(compensation, compensationResults)
      this.addSimulationStep(compensation)
      this.compensationEntry = compensationResults
    }

    return compensationAvailable
  }

  private addSimulationStep(step: ISimulationStep) {
    if (!this.stepsList.find((s) => s.value === step.value)) {
      this.stepsList.push(step)
    }
  }

  private handleChartsAndScalesData(result) {
    try {
      this.currentSimCompVersion = new SimCompVersion(result[VERSION_KEY])
    } catch (error) {
      console.warn(`Cannot parse version information. ${error}`) // tslint:disable-line
    }

    try {
      const adjustedData = this.chartController.adjustChartsData(result)
      this.triggerEvent(RMEvent.ChartsInitialized, adjustedData)
    } catch (error) {
      console.warn(`Cannot parse charts information. ${error}`) // tslint:disable-line
    }

    try {
      this.simulationScales = getSimulationScales(result)
      this.compensationScales = getCompensationScales(result)
      this.summary = getSimulationSummary(result)
    } catch (error) {
      console.warn(`Cannot parse scales information. ${error}`) // tslint:disable-line
    }

    if (this.summary) {
      this.summary.deviation.average = this.chartController.convergence
      this.triggerEvent(RMEvent.SummaryInitilized, this.summary)
    }
  }

  private setDataJsonFileContent(dataJson) {
    this.dataJsonSetFromS3 = true
    this.handleChartsAndScalesData(dataJson)
  }

  private viewCompensationResults() {
    if (!this.compensationEntry) return

    const pathInfo = this.getCompensationFilesPaths()
    const step = new SimulationStepHandler(this, this.compensationScales, pathInfo)
    step.openDataSet()
    this.simulationStepsData.set(this.currentSimulationStep.value, step)
    this.triggerEvent(RMEvent.SimulationStepLoaded, this.currentSimulationStep.value)
  }

  private getSimulationFileName(result: IDataDirectoryStructure, simStep: ISimulationStep) {
    this.setSimulationStepSize(simStep, result)
    let parts = this.getFileNamesByPattern(result, (d) => d.label.toLowerCase() === PARTS_PVD)
    let supports = this.getFileNamesByPattern(result, (d) => d.label.toLowerCase() === SUPPORTS_PVD)
    let buildPlate = this.getFileNamesByPattern(result, (d) => d.label.toLowerCase() === BUILDPLATE_PVD)
    const coupons = this.getFileNamesByPattern(result, (d) => d.label.toLowerCase() === COUPONS_PVD)

    // Logic for backwards compatibility. All new simulation resutls should have PVD collection files
    if (simStep.type === StepType.BinderJet && !parts) {
      const checkExtensionAndPattern = (target, ext: string, pattern: RegExp) => {
        const lower = target.label.toLowerCase()
        return lower.endsWith(ext) && pattern.test(lower)
      }
      if (this.resultFilesInfo.part !== '') {
        parts = this.getFileNamesByPattern(
          result,
          (d) => checkExtensionAndPattern(d, VTP, SINTER_PART_PATTERN) && d.label.includes(this.resultFilesInfo.part),
        )
      } else {
        parts = this.getFileNamesByPattern(result, (d) => checkExtensionAndPattern(d, VTP, SINTER_PART_PATTERN))
      }

      if (this.resultFilesInfo.support) {
        supports = this.getFileNamesByPattern(
          result,
          (d) =>
            checkExtensionAndPattern(d, VTP, SINTER_PART_PATTERN) && d.label.includes(this.resultFilesInfo.support),
        )
      }

      buildPlate = this.getFileNamesByPattern(result, (d) => checkExtensionAndPattern(d, VTP, SINTER_PLATE_PATTERN))

      // Prior to VTP format we used to save BJ results in VTK format
      if (!parts) {
        if (this.resultFilesInfo.part !== '') {
          parts = this.getFileNamesByPattern(
            result,
            (d) => checkExtensionAndPattern(d, VTK, SINTER_PART_PATTERN) && d.label.includes(this.resultFilesInfo.part),
          )
        } else {
          parts = this.getFileNamesByPattern(result, (d) => checkExtensionAndPattern(d, VTK, SINTER_PART_PATTERN))
        }

        if (this.resultFilesInfo.support) {
          supports = this.getFileNamesByPattern(
            result,
            (d) =>
              checkExtensionAndPattern(d, VTK, SINTER_PART_PATTERN) && d.label.includes(this.resultFilesInfo.support),
          )
        }

        buildPlate = this.getFileNamesByPattern(result, (d) => checkExtensionAndPattern(d, VTK, SINTER_PLATE_PATTERN))
      }
    }

    return { parts, supports, buildPlate, coupons }
  }

  private getCompensationFilesPaths() {
    const path = this.compensationEntry.path.slice(1).join('/')
    let partsPaths: any = this.getFileNamesByPattern(this.compensationEntry, (d) => d.label.toLowerCase() === PARTS_PVD)
    let supportsPaths: any = this.getFileNamesByPattern(
      this.compensationEntry,
      (d) => d.label.toLowerCase() === SUPPORTS_PVD,
    )
    let platePaths: any = this.getFileNamesByPattern(
      this.compensationEntry,
      (d) => d.label.toLowerCase() === BUILDPLATE_PVD,
    )

    if (!partsPaths) {
      partsPaths = []
      supportsPaths = []
      platePaths = []

      for (const group of this.compensationEntry.groups) {
        if (
          !COMP_BUILD_PLATE_PATTERN.test(group.label.toLowerCase()) &&
          !COMP_SUPPORTS_PATTERN.test(group.label.toLowerCase())
        ) {
          partsPaths.push(group.files.map((f) => `${path}/${f.label}`))
        }

        if (COMP_BUILD_PLATE_PATTERN.test(group.label.toLowerCase())) {
          platePaths.push(group.files.map((f) => `${path}/${f.label}`))
        }

        if (COMP_SUPPORTS_PATTERN.test(group.label.toLowerCase())) {
          supportsPaths.push(group.files.map((f) => `${path}/${f.label}`))
        }
      }
    }

    return {
      parts: partsPaths,
      supports: supportsPaths && supportsPaths.length ? supportsPaths : undefined,
      buildPlate: platePaths && platePaths.length ? platePaths : undefined,
      coupons: undefined,
    }
  }

  private getPrintingLayers(dirInfo: IDataDirectoryStructure) {
    let layers = []
    let nameStart
    let index
    for (const group of dirInfo.groups) {
      if (LAYERS_PATTERN.test(group.label)) {
        index = group.label.indexOf(LAYERS_NAME)
        nameStart = group.label.substring(0, index)
        layers = group.files.map((f) => f.label.substring(index))
        break
      }
    }

    for (const file of dirInfo.files) {
      if (file.label.startsWith(nameStart) && file.label.endsWith(VTP)) {
        layers.push(file.label.substring(index))
      }
    }

    layers.sort((a, b) => {
      const aNumber = END_NUMBER_PATTERN.test(a) ? +END_NUMBER_PATTERN.exec(a)[1] : 0
      const bNumber = END_NUMBER_PATTERN.test(b) ? +END_NUMBER_PATTERN.exec(b)[1] : 0

      return aNumber - bNumber
    })

    this.triggerEvent(RMEvent.PrintingLayersInitilized, { layers })
  }

  private checkStepResultsAvailable(simStep: ISimulationStep, targetDir: IDataDirectoryStructure): boolean {
    if (!targetDir.dirs[simStep.value]) return false

    const stepDir = targetDir.dirs[simStep.value].dirs.results || targetDir.dirs[simStep.value]
    if (!stepDir) return false

    const simFilesInfo = this.getSimulationFileName(stepDir, simStep)
    if ((simFilesInfo.parts || simFilesInfo.coupons) && (simFilesInfo.supports || simFilesInfo.buildPlate)) {
      this.resultsEvent.trigger({ event: RMEvent.HandlerTogglesAvailable, args: undefined })
    }

    return simFilesInfo.parts !== undefined || simFilesInfo.coupons !== undefined
  }

  private setSimulationStepSize(step: ISimulationStep, dir: IDataDirectoryStructure) {
    // We need to sum the sizes of the files with the largest (which means the latest available in step) index
    const setIndexAndSize = (index: number, fileSize: number) => {
      if (index > maxIndex) {
        maxIndex = index
        size = fileSize
      } else if (index === maxIndex) {
        size += fileSize
      }
    }

    let size = 0
    let maxIndex = 0
    if (step.type === StepType.DmlmBuild) {
      for (const file of dir.files) {
        if (END_NUMBER_PATTERN.test(file.label)) {
          const index = +END_NUMBER_PATTERN.exec(file.label)[1]
          setIndexAndSize(index, file.size)
        }
      }
    } else if (step.type === StepType.DmlmMaterialRemoval) {
      // Material removal has only one time stamp that we're interested in
      // So we need to sum sizes of the files that match material removal step pattern
      for (const file of dir.files) {
        if (AFTER_RELAXATION_PATTERN.test(file.label)) {
          size += file.size
        }
      }

      for (const group of dir.groups) {
        if (group.label.endsWith(VTP)) {
          for (const file of group.files) {
            if (AFTER_RELAXATION_PATTERN.test(file.label)) {
              size += file.size
            }
          }
        }
      }
    } else if (step.type === StepType.DmlmStressRelief) {
      for (const group of dir.groups) {
        if (END_NUMBER_GROUP_LABEL_PATTERN.test(group.label)) {
          const index = +END_NUMBER_GROUP_LABEL_PATTERN.exec(group.label)[1]
          const fileSize = group.files[group.files.length - 1].size
          setIndexAndSize(index, fileSize)
        }
      }
    } else if (step.type === StepType.BinderJet) {
      for (const file of dir.files) {
        if (file.label.endsWith(VTK) || file.label.endsWith(VTP)) {
          size += file.size
        }
      }
    } else if (step.type === StepType.Compensation) {
      for (const group of dir.groups) {
        if (group.label.endsWith(VTK) || group.label.endsWith(VTP)) {
          for (const file of group.files) {
            if (END_NUMBER_PATTERN.test(file.label)) {
              const index = +END_NUMBER_PATTERN.exec(file.label)[1]
              setIndexAndSize(index, file.size)
            }
          }
        }
      }

      // This case means we have compensation for possibly cancelled job
      // because we have only one VTP/VTK file available
      if (!dir.groups.length || size === 0) {
        dir.files.forEach((f) => {
          if (END_NUMBER_PATTERN.test(f.label)) {
            const index = +END_NUMBER_PATTERN.exec(f.label)[1]
            setIndexAndSize(index, f.size)
          }
        })
      }
    }

    if (size > 0) {
      step.size = size * SIZE_ADJUSTMENT_FACTOR
    }
  }

  private onSlicerAdjusted(slicerInfo: { min: Vector3; max: Vector3 }) {
    const minZ = (slicerInfo.max.z - slicerInfo.min.z) * 0.01 + slicerInfo.min.z
    this.triggerEvent(RMEvent.SlicerInitialized, { max: slicerInfo.max.z, min: minZ })
  }
}
