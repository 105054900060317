/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { Mesh } from '@babylonjs/core/Meshes'
import { VertexBuffer } from '@babylonjs/core/Buffers'
import { Tools } from '@babylonjs/core/Misc/tools'

import { EndpointsUrls } from '@/configs/config'
import { FACE_NAMES_META_NAME, FACE_NAMES_META_VALUE } from '@/constants'
import { Face } from '@/visualization/components/DracoDecoder'

declare let DracoEncoderModule: any
declare let WebAssembly: any

export enum DracoExporterMethod {
  Sequential = 0,
  Edgebreaker = 1,
}

export interface IDracoEncodeConfig {
  encoderMethod?: DracoExporterMethod
  quantization?: number[]
  exportUvs: boolean
  exportNormals: boolean
}

export class DracoEncoder {
  private encoderModulePromise?: Promise<any>

  constructor() {
    this.encoderModulePromise = this.loadDracoEncoderAsync()
  }

  public async encodeMesh(mesh: Mesh, faces?: Face[], options?: IDracoEncodeConfig) {
    const config: IDracoEncodeConfig = {
      encoderMethod: DracoExporterMethod.Edgebreaker,
      exportUvs: true,
      exportNormals: true,
    }

    if (options) {
      Object.assign(config, options)
    }

    const module = (await this.encoderModulePromise).module
    const encoder = new module.Encoder()
    const meshBuilder = new module.MeshBuilder()
    const dracoMesh = new module.Mesh()
    const metadata = new module.Metadata()
    const metadataBuilder = new module.MetadataBuilder()

    const numFaces = mesh.getIndices().length / 3
    meshBuilder.AddFacesToMesh(dracoMesh, numFaces, mesh.getIndices())

    const positions = mesh.getVerticesData(VertexBuffer.PositionKind)
    meshBuilder.AddFloatAttributeToMesh(dracoMesh, module.POSITION, positions.length / 3, 3, positions)

    const normals = mesh.getVerticesData(VertexBuffer.NormalKind)
    if (config.exportNormals && normals) {
      meshBuilder.AddFloatAttributeToMesh(dracoMesh, module.NORMAL, normals.length / 3, 3, normals)
    }

    const uvs = mesh.getVerticesData(VertexBuffer.UVKind)
    if (config.exportUvs && uvs) {
      const uvws = []
      for (let i = 0; i < uvs.length - 1; i += 2) {
        uvws.push(uvs[i])
        uvws.push(uvs[i + 1])
      }

      meshBuilder.AddFloatAttributeToMesh(dracoMesh, module.TEX_COORD, uvws.length / 2, 2, uvws)
    }

    const faceIds = this.calcFaceAttrribute(faces, mesh)
    const genericAttId = meshBuilder.AddInt32Attribute(dracoMesh, module.GENERIC, faceIds.length, 1, faceIds)

    // add faceName
    metadataBuilder.AddStringEntry(metadata, FACE_NAMES_META_NAME, FACE_NAMES_META_VALUE)
    faces.forEach((face) => {
      metadataBuilder.AddIntEntry(metadata, face.name, face.id)
    })

    meshBuilder.SetMetadataForAttribute(dracoMesh, genericAttId, metadata)

    if (config.quantization) {
      for (let i = 0; i < 5; i += 1) {
        if (config.quantization[i]) {
          encoder.SetAttributeQuantization(i, config.quantization[i])
        }
      }
    }

    if (config.encoderMethod) {
      encoder.SetEncodingMethod(config.encoderMethod)
    }

    const encodedData = new module.DracoInt8Array()
    const encodedLen = encoder.EncodeMeshToDracoBuffer(dracoMesh, encodedData)
    if (encodedLen === 0) {
      throw new Error('Draco encoding failed')
    }

    const outputData = new Int8Array(new ArrayBuffer(encodedLen))
    for (let i = 0; i < encodedLen; i += 1) {
      outputData[i] = encodedData.GetValue(i)
    }

    module.destroy(dracoMesh)
    module.destroy(encoder)
    module.destroy(meshBuilder)
    module.destroy(encodedData)
    module.destroy(metadata)
    module.destroy(metadataBuilder)

    return outputData
  }

  private calcFaceAttrribute(faces: Face[], mesh: Mesh): Int32Array {
    const result = []
    const position = mesh.getVerticesData(VertexBuffer.PositionKind)

    const map = new Map<number, number>()
    for (const face of faces) {
      for (const index of face.indices) {
        if (map.has(index) && map.get(index) !== face.id) {
          throw new Error('dublicate indices in faces')
        }

        map.set(index, face.id)
      }
    }

    const positionCount = position.length / 3
    for (let i = 0; i < positionCount; i += 1) {
      const faceId = map.get(i)
      result.push(faceId)
    }

    return new Int32Array(result)
  }

  private async loadDracoEncoderAsync(): Promise<any> {
    const encoderUrls = {
      wasmBinaryUrl: new URL(EndpointsUrls.DracoEncoderWasmBinaryUrl, origin).toString(),
      wasmUrl: new URL(EndpointsUrls.DracoEncoderWasmUrl, origin).toString(),
      fallbackUrl: new URL(EndpointsUrls.DracoEncoderFallbackUrl, origin).toString(),
    }

    let wasmBinary
    if (encoderUrls.wasmBinaryUrl && encoderUrls.wasmUrl && typeof WebAssembly === 'object') {
      wasmBinary = await Tools.LoadFileAsync(encoderUrls.wasmBinaryUrl)
      await Tools.LoadScriptAsync(encoderUrls.wasmUrl)
    } else {
      wasmBinary = undefined
      await Tools.LoadScriptAsync(encoderUrls.fallbackUrl)
    }

    return new Promise((resolve) => {
      DracoEncoderModule({ wasmBinary }).then((module: any) => {
        resolve({ module })
      })
    })
  }
}
