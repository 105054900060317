
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Selector from '@/components/controls/Common/Selector.vue'
import VisualizationSlider from '@/components/layout/buildPlans/simulate/VisualizationSlider.vue'
import StoresNamespaces from '@/store/namespaces'
import SimulationColoring from '@/components/layout/buildPlans/simulate/SimulationColoring.vue'
import { ISimulationStep } from '@/types/Simulation/SimulationSteps'
import NewSimulationTimestampModal from '@/components/layout/buildPlans/simulate/NewSimulationTimestampModal.vue'
import { ContentViewModeTypes } from '@/visualization/types/ContentViewMode'
import { IDmlmSimulateCompensation, StepProcessType } from '@/types/Simulation/SimulationCompensation'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import { PrintingTypes } from '@/types/IMachineConfig'
import { getLabelFromPower, numberToPowerOf10 } from '@/utils/number'
import { IWarpingInfo } from '@/visualization/types/SimulationTypes'
import DistortionControl from '@/components/layout/buildPlans/simulate/DistortionControl.vue'
import SimulationColoringModeComponent from '@/components/layout/buildPlans/simulate/SimulationColoringModeComponent.vue'

const visualizationStore = namespace(StoresNamespaces.Visualization)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const BUILD_PLATE_NAME = 'build plate'

@Component({
  components: {
    Selector,
    SimulationColoring,
    SimulationColoringModeComponent,
    NewSimulationTimestampModal,
    VisualizationSlider,
    DistortionControl,
  },
})
export default class BuildPlanSimVisTab extends Vue {
  @visualizationStore.Getter('getSelectedSimulationStep') getSelectedSimulationStep: ISimulationStep
  @visualizationStore.Getter('getSimulationSteps') getSimulationSteps: ISimulationStep[]
  @visualizationStore.Getter('getVisualizationLoading') getVisualizationLoading: boolean
  @visualizationStore.Getter('getLoadedSimulationSteps') getLoadedSimulationSteps: string[]
  @visualizationStore.Getter('getWarpingInfo') getWarpingInfo: IWarpingInfo

  @visualizationStore.Mutation('setResultsPathData') setResultsPathData: Function
  @visualizationStore.Mutation('setIsLoading') setIsLoading: Function
  @visualizationStore.Mutation('viewSimulationResults') viewSimulationResults: Function
  @visualizationStore.Mutation('setWarpingParameters') setWarpingParameters: Function

  @buildPlansStore.Getter getContentViewMode: ContentViewModeTypes
  @buildPlansStore.Getter getSimulationParmeters
  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan

  simulationSteps: ISimulationStep[] = []

  @Prop() expanded: boolean

  @Watch('expanded') onTabExpanded() {
    if (this.expanded && this.getSelectedSimulationStep) {
      this.simulationStep = this.getSelectedSimulationStep
    }
  }

  @Watch('getContentViewMode') onContentViewModeChange() {
    if (this.getContentViewMode === ContentViewModeTypes.Visualization) {
      this.setResultsPathData({
        simulationStep: this.getSelectedSimulationStep,
      })
    }
  }

  get viewResultsLabel() {
    if (this.simulationStep) {
      const sizeInfo = numberToPowerOf10(this.simulationStep.size)
      if (sizeInfo) {
        const label = getLabelFromPower(sizeInfo.baseOf10)
        return `${this.$t('viewResults')} (${sizeInfo.number} ${label})`
      }
    }

    return this.$t('viewResults')
  }

  get isDMLM() {
    return this.buildPlan.modality === PrintingTypes.DMLM
  }

  get simulationStep() {
    return this.getSelectedSimulationStep
  }

  set simulationStep(step: ISimulationStep) {
    this.setResultsPathData({
      simulationStep: step,
    })
  }

  get simulateDisabled() {
    return (
      this.getVisualizationLoading ||
      this.simulationStep === undefined ||
      this.getLoadedSimulationSteps.indexOf(this.getSelectedSimulationStep.value) !== -1
    )
  }

  get xRayVisible() {
    return (
      this.simulationStep !== undefined &&
      this.getLoadedSimulationSteps.indexOf(this.getSelectedSimulationStep.value) !== -1
    )
  }

  get simulationStepDropdownDisabled() {
    return this.getSimulationSteps.length === 0 || this.getVisualizationLoading
  }

  get simulationStepDropdownPlaceholder() {
    return this.simulationStepDropdownDisabled ? this.$t('noResultsAvailable') : ''
  }

  get simulationStepDropdownSuffix() {
    if (this.getSimulationSteps.length === 0) return ''

    const index = this.getSimulationSteps.indexOf(this.simulationStep)
    return index === this.getSimulationSteps.length - 1 ? `(${this.$t('final')})` : ''
  }

  @Watch('getSimulationSteps') onSimulationStepsChanged() {
    if (!this.getSimulationSteps.length) return

    if (this.isDMLM) {
      const steps = this.getSimulationSteps
      const simulationParameters = this.getSimulationParmeters as IDmlmSimulateCompensation
      if (simulationParameters) {
        const processSteps = simulationParameters.processSteps
        for (let i = 1; i < processSteps.length && i <= steps.length; i += 1) {
          if (processSteps[i].processType !== StepProcessType.MaterialRemoval) {
            continue
          }

          const itemsToRemove = processSteps[i].itemsToRemove
          steps[i - 1].name =
            itemsToRemove.length === 1 && itemsToRemove[0] === BUILD_PLATE_NAME
              ? this.$t('plateRemovalStep').toString()
              : this.$t('supportRemovalStep').toString()
        }
      }

      this.simulationSteps = steps
    } else {
      this.simulationSteps = this.getSimulationSteps
    }
  }

  mounted() {
    this.onSimulationStepsChanged()
  }

  async onSimulate() {
    this.setIsLoading(true)
    this.setResultsPathData({
      simulationStep: this.getSelectedSimulationStep,
    })

    this.viewSimulationResults()
  }
}
