import { ICommand } from '@/visualization/infrastructure/ICommand'

export class CommandManager {
  private undoCommands: ICommand[] = []

  pushUndoCommand(command: ICommand) {
    this.undoCommands.push(command)
  }

  undo() {
    const command: ICommand = this.undoCommands.pop()
    if (command) {
      command.unExecute()
    }
  }
}
