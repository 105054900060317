
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Menu from '@/components/controls/Common/Menu.vue'
@Component({
  components: {
    Menu,
  },
})
export default class BuildPlanReportSelector extends Vue {
  @Prop({ default: 180 }) width: number
  @Prop() canBeDownloaded: Function
  @Prop() ondownloadFile: Function
  @Prop() isBinderJetModality: Function
  downloadFileclick(type) {
    if (this.ondownloadFile) this.ondownloadFile(type)
  }
  get itemsFormatted() {
    const itemTypes = [
      {
        name: 'Print Order',
        icon: 'mdi-printer-3d',
        value: 'pkg',
        available: true,
      },
      {
        name: 'Build Layout Report',
        icon: 'mdi-file-chart-outline',
        value: 'csv',
        available: this.isBinderJetModality,
      },
    ]
    return itemTypes
  }
}
