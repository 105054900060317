
import Vue from 'vue'
import Component from 'vue-class-component'
import { IJob, JobType } from '@/types/PartsLibrary/Job'
import { ToolNames, WAYS_TO_ENABLE_TOOLS_MAP } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import IWayToEnable from '@/types/BuildPlans/IWayToEnable'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { PrintOrderJobStatusCode } from '@/types/PrintOrder/PrintOrderJobStatusCode'
import { jobTypeToNameForDashboard } from '@/utils/string'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import { IBCPlanTool, IBCPlanToolNames } from '@/components/layout/IBCPlan/IBCPlanSidebarTools'
import JobStatusIcon from '@/components/layout/buildPlans/JobStatusIcon.vue'
import { BadgesMixin } from '@/components/layout/mixins/BadgesMixin'
import { Prop } from 'vue-property-decorator'
import { InsightsSeverity } from '@/types/Common/Insights'

const labelStore = namespace(StoresNamespaces.Labels)
const commonStore = namespace(StoresNamespaces.Common)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component({ components: { JobStatusIcon } })
export default class BuildPlanSidebarBadges extends BadgesMixin {
  @Prop({ required: true }) isBuildPlanReadonly: boolean

  @commonStore.Getter tooltipOpenDelay: number

  @buildPlansStore.Getter insights: IBuildPlanInsight[]
  @buildPlansStore.Getter getRequiresLabelSetUpdates: boolean
  @buildPlansStore.Getter isReadOnly: boolean
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getRunningJobsByVariantId: (id: string) => IJob[]
  @buildPlansStore.Getter getCompleteAndRunningSlicingJobs: (id: string) => IJob[]
  @buildPlansStore.Getter getRunningNestingJobsByVariantId: (id: string) => IJob[]
  @buildPlansStore.Getter getRunningSimulationJobsByVariantId: (id: string) => IJob[]
  @buildPlansStore.Getter getRunningMeshJobsByVariantId: (id: string) => IJob[]
  @buildPlansStore.Getter getIsLoading: boolean
  @buildPlansStore.Getter isTransferToolBusy: boolean

  @visualizationStore.Getter getVisualizationLoading: boolean

  @labelStore.Getter getLabelUpdateInProgress: boolean
  @labelStore.Getter labelErrorMessage: (key) => string
  @labelStore.Getter sortedHeaders
  @labelStore.Getter labelIssues

  @labelStore.Mutation setLabelUpdateInProgress: (value: boolean) => void
  @labelStore.Mutation setWatchToolReady: (value: boolean) => void

  jobType(job: IJob): string {
    return jobTypeToNameForDashboard(job.jobType)
  }

  getWayToEnableTranslation(wayToEnable: IWayToEnable) {
    const params = wayToEnable.params.map((param) => Vue.prototype.$sanitize(param))
    return this.$t(WAYS_TO_ENABLE_TOOLS_MAP[wayToEnable.key], params)
  }

  shouldShowFailedLabels() {
    return (
      this.tool.key === ToolNames.LABEL &&
      this.insights.some(
        (insight) => insight.tool === ToolNames.LABEL && insight.severity === InsightsSeverity.Error,
      ) &&
      !this.getRequiresLabelSetUpdates
    )
  }

  shouldShowIbcInspectionsStatusIcon() {
    return this.tool.key === IBCPlanToolNames.INSPECTIONS && (this.tool as IBCPlanTool).shouldShowStatusIcon
  }

  shouldShowUpdateLabelSets() {
    if (!this.getBuildPlan) {
      return false
    }

    const hasArrangeJobs = this.getRunningNestingJobsByVariantId(this.getBuildPlan.id).length > 0
    const hasRunningSimCompJobs = this.getRunningSimulationJobsByVariantId(this.getBuildPlan.id).length > 0
    const hasMeshJobs = this.getRunningMeshJobsByVariantId(this.getBuildPlan.id).length > 0

    return (
      this.tool.key === ToolNames.LABEL &&
      this.getRequiresLabelSetUpdates &&
      !this.isReadOnly &&
      !hasArrangeJobs &&
      !hasRunningSimCompJobs &&
      !hasMeshJobs &&
      !this.getIsLoading &&
      !this.getVisualizationLoading &&
      !this.isTransferToolBusy
    )
  }

  status(job: IJob) {
    if (job.jobType !== JobType.PRINT) {
      return job.code
    }
    return PrintOrderJobStatusCode[job.code]
  }

  onUpdateIconClick(event) {
    event.stopPropagation()
    this.$root.$emit('updateLabels')
    this.setWatchToolReady(true)
  }

  get updateLabelSetsIsInProgress() {
    return this.tool.key === ToolNames.LABEL && this.getLabelUpdateInProgress
  }

  get isTransferPropertiesInProgress() {
    return this.tool.key === ToolNames.TRANSFER_PROPS && this.isTransferToolBusy
  }
}
