import { DPI, INCH_IN_MM, MICRONS_IN_MILLIMETER } from '@/constants'
import i18n from '@/plugins/i18n'

const KILO_POWER = 3
const MEGA_POWER = 6
const GIGA_POWER = 9

export function numberToPowerOf10(number: number): { number: string | number; baseOf10?: number } {
  if (!number) return undefined

  const lto = Math.abs(number) < 1 && Math.abs(number) > 0

  if (lto) {
    const exponential = Math.abs(number).toExponential()
    const pow = +(exponential.indexOf('+') >= 0 ? exponential.split('+').pop() : exponential.split('-').pop())

    if (pow === 1) {
      return { number: number.toFixed(1) }
    }

    const tier = Math.floor((pow + 1) / 3)
    const scale = Math.pow(10, tier * 3)
    const res = (number * scale).toFixed(1)

    return { number: res, baseOf10: -3 * tier }
  }
  const i = Math.floor(Math.log(Math.abs(number)) / Math.log(1000))
  const result = parseFloat((number / Math.pow(1000, i)).toFixed(1))

  return { number: result, baseOf10: i !== 0 ? 3 * i : null }
}

export function getLabelFromPower(power: number) {
  switch (power) {
    case KILO_POWER:
      return i18n.t('kiloByte')
    case MEGA_POWER:
      return i18n.t('megaByte')
    case GIGA_POWER:
      return i18n.t('gigaByte')
    default:
      return ''
  }
}

export function convertMillimeterToPixel(millimeters: number) {
  return (millimeters * DPI) / INCH_IN_MM
}

export function convertPixelToMillimeter(pixels: number) {
  return (pixels * INCH_IN_MM) / DPI
}

export function micronToMillimeter(value: number) {
  return value / MICRONS_IN_MILLIMETER
}

export function formatDecimal(num: number, fractionDigits: number = 2): string {
  const returnVal: string = num.toFixed(fractionDigits)
  return returnVal
}

export function roundUptoDecimalPlaces(num: number, decimalPlaces: number = 0) {
  let returnVal = num
  if (decimalPlaces > 1) {
    returnVal = returnVal * Math.pow(10, decimalPlaces)
    returnVal = Math.round(returnVal)
    returnVal = returnVal / Math.pow(10, decimalPlaces)
  }
  return returnVal
}

export function roundToFloatLimit(val: number, floatLimit: number = 0) {
  return Math.round((val + Number.EPSILON) * 10 ** floatLimit) / 10 ** floatLimit
}

export function isNumber(val: any): boolean {
  return typeof val === 'number' && val === val
}

export function equalWithTolerance(a: number, b: number, tolerance: number): boolean {
  const absA = Math.abs(a)
  const absB = Math.abs(b)
  const diff = Math.abs(a - b)

  if (a === b) {
    return true
  }

  if (a === 0 || b === 0 || absA + absB < Number.MIN_VALUE) {
    return diff < tolerance * Number.MIN_VALUE
  }

  return diff / Math.min(absA, Number.MAX_VALUE) < tolerance && diff / Math.min(absB, Number.MAX_VALUE) < tolerance
}

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max)
}

export function extractNumberFromString(str: string) {
  const match = str.match(/\d+/)
  if (match) {
    return parseInt(match[0], 10)
  }
  return null
}
