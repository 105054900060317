import { ActionTree, Commit } from 'vuex'
import { IBuildPlansState } from './types'
import { IRootState } from '@/store/types'
import {
  BuildPlanItemInstancesDto,
  defaultDisplayToolbarState,
  defaultIBCDisplayToolbarState,
  GeometryType,
  IBuildPlan,
  IBuildPlanItem,
  IConstraintsBuildPlanItemsDto,
  ICreateBuildPlanDto,
  IDisplayToolbarState,
  IIBCDisplayToolbarState,
  IMoveBuildPlanItemDto,
  IMoveBuildPlanItemsDto,
  IPart,
  IPrintStrategyParameterSet,
  IScaleFactors,
  IUpdateBuildPlanItemParamsDto,
  IVariantItem,
  PartProperty,
  PartTypes,
  ProcessState,
  SelectionUnit,
  TransferPropsResponseDto,
  Visibility,
} from '@/types/BuildPlans/IBuildPlan'

import { BuildPlanCostCreateDTO } from '@/pages/BuildPlans/dtos/BuildPlanCostCreateDTO'
import { ILabel } from '@/types/Marking/ILabel'
import { IConstraints } from '@/types/BuildPlans/IConstraints'
import ITransformationDelta from '@/types/BuildPlans/ITransformationDelta'
import { ToolTypes } from '@/types/BuildPlans/ToolTypes'
import { InstanceLabel } from '@/visualization/types/InstanceLabel'
import { IBaseSimulateCompensation } from '@/types/Simulation/SimulationCompensation'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import messageService from '@/services/messageService'
import { RestoreSelectedPartsCommand } from '@/types/UndoRedo/RestoreSelectedPartsCommand'
import { IBuildPlanInsight, InsightErrorCodes, IPendingInsights } from '@/types/BuildPlans/IBuildPlanInsight'
import {
  BUILD_CHAMBER_POLYLINES_NAME,
  GAS_FLOW_MESH_NAME,
  LOCK_BUILD_PLAN_REQUEST_INTERVAL,
  LOCK_BUILD_PLAN_REQUEST_REPEAT_TIMES,
  MISSING_COST_PROPERTIES_ERROR,
  PRINT_HEAD_DIRECTION_MESH_NAME,
  PRINT_HEAD_LANES_MESH_NAME,
  PrintStrategyParameterSetErrorCodes,
  RECOATER_DIRECTION_MESH_NAME,
} from '@/constants'
import { LabelCommand, LabelMode } from '@/types/UndoRedo/LabelCommand'
import { BuildPlanItemsCommand } from '@/types/UndoRedo/BuildPlanItemsCommand'
import buildPlans from '@/api/buildPlans'
import sites from '@/api/sites'
import buildPlanItems from '@/api/buildPlanItems'
import buildPlates from '@/api/buildPlates'
import { PrintingTypes } from '@/types/IMachineConfig'
import { RestoreSelectedPartsType } from '@/types/BuildPlans/RestoreSelectedPartsType'
import VisualizationModeTypes from '@/visualization/types/VisualizationModeTypes'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { getDefaultBaseOnType } from '../../../utils/parameterSet/parameterSetUtils'
import { VersionableModel } from '@/types/Common/VersionableModel'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { repeatAsyncFunction } from '@/utils/common'
import { IbcMeasurementDeleteDto, IIBCPlan, IMeasurementWithProperties } from '@/types/IBCPlans/IIBCPlan'
import { ItemType } from '@/types/FileExplorer/ItemType'
import { LoadBuildPlanOptions } from '@/visualization/types/LoadBuildPlanOptions'
import { IPartRenderable } from '@/types/Parts/IPartRenderable'
import fileExplorer from '@/api/fileExplorer'
import { DuplicateMode } from '@/types/Duplicate/Duplicate'

export interface IBuildPlanItemPayload {
  buildPlan: IBuildPlan
  loadConfig: boolean
  partName: string
  transformationMatrix: number[]
  pointerX: number
  pointerY: number
}

const costCallQueue = []
const updateBuildPlanItemMap: Map<string, Array<{ done: Function; promise: Promise<void> }>> = new Map()

export const actions: ActionTree<IBuildPlansState, IRootState> = {
  async createBuildPlanVariant({ commit }, buildPlanId: string) {
    const bpVariant = (await buildPlans.createBuildPlanVariant(buildPlanId)) as IBuildPlan
    if (bpVariant) {
      const variant = createVariantItemFromPlan(bpVariant)
      commit('addVariant', variant)
      commit('addDisplayToolbarState', { buildPlanId: bpVariant.id, state: defaultDisplayToolbarState })
      commit('sortVariants')
    }
    return bpVariant
  },

  async fetchRelatedBuildPlanVariants({ commit, state }, variantId: string): Promise<void> {
    if (!variantId) {
      return
    }

    const bpVariants = await buildPlans.getRelatedBuildPlanVariants(variantId)
    commit('setVariants', bpVariants)
    commit('sortVariants')
  },

  async lockBuildPlanVariant({ commit, state }, id?: string) {
    const variantId = id || state.buildPlan.id

    await lockBuildPlanVariantRequest(commit, variantId)

    const intervalId = setInterval(() => {
      repeatAsyncFunction(LOCK_BUILD_PLAN_REQUEST_REPEAT_TIMES, lockBuildPlanVariantRequest, commit, variantId)
    }, LOCK_BUILD_PLAN_REQUEST_INTERVAL)

    if (state.lockBuildPlanIntervalId) {
      clearInterval(state.lockBuildPlanIntervalId)
    }

    commit('setlockBuildPlanIntervalId', intervalId)
  },

  async setVariantLabel({ commit, getters }, plan: IVariantItem): Promise<boolean> {
    try {
      const result = await buildPlans.setVariantLabel(plan.id, plan.versionLabel)
      if (!result) {
        return
      }

      const variant = getters.getBuildPlanVariantById(plan.id)
      variant.versionLabel = plan.versionLabel

      commit('updateVariant', variant)
      if (plan.itemType === ItemType.BuildPlan) {
        const buildPlan = getters.getBuildPlan
        buildPlan.versionLabel = plan.versionLabel
        commit('updateBuildPlan', buildPlan)
        commit('setBuildPlan', buildPlan)
      }
      if (plan.itemType === ItemType.IbcPlan) {
        const ibcPlan = getters.getIBCPlan
        ibcPlan.versionLabel = plan.versionLabel
        commit('setIBCPlan', ibcPlan)
      }

      return true
    } catch (error) {
      console.error(error)
    }
  },

  async deleteVariant({ commit }, variant: IVariantItem) {
    await buildPlans.deleteBuildPlanVariant(variant.id)

    variant.isRemoved = true

    commit('updateVariant', variant)

    if (variant.itemType === ItemType.IbcPlan) {
      commit('deleteIBCDisplayToolbarState', variant.id)
    } else {
      commit('deleteDisplayToolbarState', variant.id)
    }
  },

  async unlockBuildPlanVariant({ commit, state }, id?: string) {
    const variantId = id || state.buildPlan.id

    if (!variantId) {
      return
    }

    if (state.lockBuildPlanIntervalId) {
      clearInterval(state.lockBuildPlanIntervalId)
      commit('setlockBuildPlanIntervalId', null)
    }

    try {
      await buildPlans.unlockBuildPlanVariant(variantId)
      commit('unsetActiveVariantIsLocked')
    } catch (error) {
      console.error(error)
    }
  },

  async fetchMaterials({ commit, state }) {
    if (!shouldFetch(state, 'materials')) {
      return Promise.resolve()
    }

    commit('setMaterials', await buildPlans.getMaterials())
  },

  async fetchMachineConfigs({ commit, state }) {
    if (!shouldFetch(state, 'machineConfigs')) {
      return Promise.resolve()
    }

    commit('setMachineConfigs', await buildPlans.getMachineConfigs())
  },

  async fetchBuildPlates({ commit, state }) {
    const plates = await buildPlates.getBuildPlates()
    commit('setBuildPlates', plates)
  },

  async getDefaultBuildPlate({ }, machineConfigPk?: VersionablePk) {
    return buildPlates.getDefaultBuildPlate(machineConfigPk)
  },

  async fetchBuildPlateMaterials({ commit, state }) {
    if (!shouldFetch(state, 'buildPlateMaterials')) {
      return Promise.resolve()
    }

    const bpMaterials = await buildPlates.getBuildPlateMaterials()
    commit('setBuildPlateMaterials', bpMaterials)
  },

  async fetchBuildPlateMachineConfigs({ commit, state }) {
    const bpMachineConfigs = await buildPlates.getBuildPlateMachineConfigs()
    commit('setBuildPlateMachineConfigs', bpMachineConfigs)
  },

  async fetchPrintSites({ commit }) {
    const printSites = await buildPlans.getPrintSites()
    commit('setPrintSites', VersionableModel.getLatestVersions(printSites.items))
  },

  async fetchMachineConfigMaterialBinders({ commit }) {
    const machineConfigMaterialBinders = await buildPlans.getMachineConfigMaterialBinders()
    commit('setMachineConfigMaterialBinders', machineConfigMaterialBinders)
  },

  async fetchProductionSets({ commit }) {
    const productionSets = await buildPlans.getProductionSets()
    commit('setProductionSets', productionSets)
  },

  async fetchActivePrintStrategies({ state, commit }, buildPlanId: string = null) {
    const activePrintStrategies = await sites.fetchActivePrintStrategies(buildPlanId)
    commit('setActivePrintStrategies', activePrintStrategies)
  },

  async fetchPrintStrategies({ commit }, versions?: boolean) {
    commit('setPrintStrategies', await sites.fetchPrintStrategies(versions))
  },

  async getBuildPlanById({ commit }, id: string): Promise<IBuildPlan> {
    try {
      const bp = await buildPlans.getBuildPlanById(id)
      const buildPlanPrintStrategy = await buildPlans.getBuildPlanPrintStrategy(
        new VersionablePk(bp.printStrategyId, bp.printStrategyVersion),
      )

      commit('addBuildPlan', bp)
      commit('setBuildPlanPrintStrategy', buildPlanPrintStrategy)
      return bp
    } catch (error) {
      console.error(error)
    }
  },

  async getBuildPlanPrintStrategy({ commit }, printStrategyPk: VersionablePk): Promise<void> {
    try {
      const buildPlanPrintStrategy = await buildPlans.getBuildPlanPrintStrategy(printStrategyPk)
      commit('setBuildPlanPrintStrategy', buildPlanPrintStrategy)
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  },

  async hasCustomPrintStrategy({ }, buildPlanId: string): Promise<boolean> {
    try {
      const customPrintStrategy = await buildPlans.getCustomPrintStrategy(buildPlanId)
      return !!customPrintStrategy
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  },

  async createCustomPrintStrategy(
    { commit },
    payload: { buildPlanId: string; printStrategyPk: VersionablePk },
  ): Promise<BuildPlanPrintStrategyDto> {
    try {
      const customPrintStrategy = await buildPlans.createCustomPrintStrategy(
        payload.buildPlanId,
        payload.printStrategyPk,
      )

      commit('setBuildPlanPrintStrategy', customPrintStrategy)

      return customPrintStrategy
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  },

  async replaceCustomPrintStrategy(
    { commit },
    payload: { buildPlanId: string; printStrategyPk: VersionablePk },
  ): Promise<BuildPlanPrintStrategyDto> {
    try {
      const customPrintStrategy = await buildPlans.replaceCustomPrintStrategy(
        payload.buildPlanId,
        payload.printStrategyPk,
      )

      commit('setBuildPlanPrintStrategy', customPrintStrategy)

      return customPrintStrategy
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  },

  async fetchBuildPlanPrintStrategyByPk(
    { commit },
    printStrategyPk: VersionablePk,
  ): Promise<BuildPlanPrintStrategyDto> {
    let buildPlanPrintStrategy: BuildPlanPrintStrategyDto

    try {
      buildPlanPrintStrategy = await buildPlans.getBuildPlanPrintStrategy(printStrategyPk)
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }

    return buildPlanPrintStrategy
  },

  async createBuildPlan({ commit }, bpDto: ICreateBuildPlanDto) {
    // create new empty build plan and add to store
    const record = await buildPlans.createBuildPlan(bpDto)
    if (record) {
      commit('addBuildPlan', record)
    }
    return record
  },

  async updateBuildPlanV1({ commit, state }, payload: { buildPlan: IBuildPlan; hideAPIErrorMessages?: boolean }) {
    const { buildPlan, hideAPIErrorMessages } = payload
    const record = await buildPlans.updateBuildPlanV1(buildPlan, state.buildPlan.id, !!hideAPIErrorMessages)
    if (record) {
      const oldPrintStrategyId = state.buildPlan.printStrategyId
      const oldPrintStrategyVersion = state.buildPlan.printStrategyVersion

      const isChangedPrintStrategy =
        oldPrintStrategyId !== record.printStrategyId || oldPrintStrategyVersion !== record.printStrategyVersion

      commit('updateBuildPlan', record)
      commit('setBuildPlan', record)

      if (isChangedPrintStrategy) {
        const buildPlanPrintStrategy = await buildPlans.getBuildPlanPrintStrategy(
          new VersionablePk(record.printStrategyId, record.printStrategyVersion),
        )
        commit('setBuildPlanPrintStrategy', buildPlanPrintStrategy)
      }
    }

    return record
  },

  async fetchCollaborators({ state, commit }, buildPlanId: string) {
    const collaborationsDto = await fileExplorer.getItemPermissions(buildPlanId, true)
    commit('setCollaboratorsCount', collaborationsDto.collaborators.length)
  },

  async updateBuildPlanItemsDefaults({ state, dispatch }) {
    try {
      const buildPlanItemsDto: IUpdateBuildPlanItemParamsDto[] = []
      for (const buildPlanItem of state.buildPlan.buildPlanItems) {
        const buildPlanItemDto: IUpdateBuildPlanItemParamsDto = { buildPlanItemId: buildPlanItem.id }

        buildPlanItemDto.partProperties = buildPlanItem.partProperties.map((pp) => ({
          ...pp,
          printStrategyParameterSetId: null,
          printStrategyParameterSetVersion: 1,
        }))

        if (buildPlanItem.supports && buildPlanItem.supports.length > 0) {
          buildPlanItemDto.supports = buildPlanItem.supports.map((support) => ({
            ...support,
            settings: { ...support.settings, printStrategyParameterSetId: null, printStrategyParameterSetVersion: 1 },
          }))
        }

        buildPlanItemsDto.push(buildPlanItemDto)
      }

      if (buildPlanItemsDto.length) {
        await dispatch('updateBuildPlanItems', buildPlanItemsDto)
      }
    } catch (error) {
      console.error(error)
    }
  },

  async updateBuildPlanItems({ commit, getters, dispatch }, buildPlanItemsDtos: IUpdateBuildPlanItemParamsDto[]) {
    try {
      const updatedBuildPlanItems = buildPlanItemsDtos.map((bpItemDto) => {
        const bpItem = getters.buildPlanItemById(bpItemDto.buildPlanItemId)
        const bpItemCopy: IBuildPlanItem = JSON.parse(JSON.stringify(bpItem))

        // TODO: Update method to accept build plan item DTO separate from params not related to build plan item
        delete bpItemDto.updateStateOnly
        delete bpItemDto.silent

        const updateDto: IBuildPlanItem = Object.assign(bpItemCopy, bpItemDto)
        return updateDto
      })

      const updatedBuildPlanItemsDtos = await buildPlanItems.updateBuildPlanItems(updatedBuildPlanItems)
      commit('updateBuildPlanItems', updatedBuildPlanItemsDtos)

      dispatch(
        'parameterSetScaleUpdated',
        buildPlanItemsDtos.map((params) => {
          return { buildPlanItemId: params.buildPlanItemId, partProperties: params.partProperties }
        }),
      )

      return updatedBuildPlanItemsDtos
    } catch (error) {
      console.error(error)
    }
  },

  async updateConstraintsForBuildPlanItems(
    { commit, state, getters, dispatch },
    payload: {
      bpItems: IBuildPlanItem[]
    },
  ) {
    if (!state.buildPlan || !state.buildPlan.buildPlanItems || getters.isLockedForViewer) {
      return
    }

    const { bpItems } = payload
    const bpId = bpItems[0].buildPlanId
    const constraintsBuildPlanItemsDto: IConstraintsBuildPlanItemsDto = {
      buildPlanId: bpId,
      constraintsBuildPlanItemDtos: [],
    }
    bpItems.forEach((bpItem) => {
      dispatch('updateConstraints', {
        buildPlanItemId: bpItem.id,
        constraints: bpItem.constraints,
      })
      constraintsBuildPlanItemsDto.constraintsBuildPlanItemDtos.push({ constraints: bpItem.constraints, id: bpItem.id })
    })

    const updatedBuildPlanItems =
      await buildPlanItems.batchUpdateBuildPlanItemsConstraints(constraintsBuildPlanItemsDto)

    updatedBuildPlanItems.forEach((bpItem) => {
      commit('setBuildPlanItem', {
        buildPlanItem: bpItem,
        buildPlanItemId: bpItem.id,
      })
    })
  },

  parameterSetScaleUpdated(
    { commit, getters, dispatch },
    payload: Array<{ partProperties: PartProperty[]; buildPlanItemId: string }>,
  ) {
    // Let the visualization module know that parameter set scale factor is updated
    const updateBuildPlanItems: Array<{ buildPlanItemId: string; scaling: number[] }> = []
    for (const { partProperties, buildPlanItemId } of payload) {
      if (!partProperties) {
        continue
      }

      if (!getters.isSinterPlan && getters.printingType === PrintingTypes.BinderJet) {
        let scaleFactor = [1, 1, 1]
        const partProps = partProperties[0]
        if (partProps.processState !== ProcessState.Green) {
          let printStrategyParameterSetPk: VersionablePk
          if (partProps.printStrategyParameterSetId) {
            printStrategyParameterSetPk = new VersionablePk(
              partProps.printStrategyParameterSetId,
              partProps.printStrategyParameterSetVersion,
            )
          } else {
            const defaults = getters.getBuildPlanPrintStrategy.defaults
            printStrategyParameterSetPk = getDefaultBaseOnType(defaults, partProps.type, partProps.bodyType)
          }
          const printStrategyPartParameterScaleFactors =
            getters.getPrintStrategyParameterSetByPk(printStrategyParameterSetPk).parameterSet.partParameters
              .ScaleFactors
          scaleFactor = [
            printStrategyPartParameterScaleFactors.ScaleFactorX,
            printStrategyPartParameterScaleFactors.ScaleFactorY,
            printStrategyPartParameterScaleFactors.ScaleFactorZ,
          ]
        }

        updateBuildPlanItems.push({ buildPlanItemId, scaling: scaleFactor })
      }
    }

    if (updateBuildPlanItems.length) {
      dispatch('updateParameterSetScale', updateBuildPlanItems)
    }
  },

  async areSelectedPrintStrategyScaleFactorsChanged(
    { state, getters, dispatch },
    prevBuildPlanPrintStrategyPk: VersionablePk,
  ): Promise<boolean> {
    const prevBuildPlanPrintStrategy = await dispatch('fetchBuildPlanPrintStrategyByPk', prevBuildPlanPrintStrategyPk)

    let scaleFactorsChanged = false

    for (const buildPlanItem of state.buildPlan.buildPlanItems) {
      if (!buildPlanItem.partProperties) {
        continue
      }

      const bpItemProperties = buildPlanItem.partProperties[0]
      const bpItemPsParameterSet =
        getters.getPrintStrategyParameterSetByPk(
          new VersionablePk(
            bpItemProperties.printStrategyParameterSetId,
            bpItemProperties.printStrategyParameterSetVersion,
          ),
        ) || getPrintStrategyDefaultByGeometryType(bpItemProperties.type, prevBuildPlanPrintStrategy)

      if (!bpItemPsParameterSet) {
        continue
      }

      const scaleFactors = bpItemPsParameterSet.parameterSet.partParameters.ScaleFactors
      if (!scaleFactors) {
        continue
      }

      switch (bpItemProperties.type) {
        case GeometryType.Production:
          const newProductionScaleFactor = getPrintStrategyDefaultByGeometryType(
            GeometryType.Production,
            state.selectedBuildPlanPrintStrategy,
          ).parameterSet.partParameters.ScaleFactors
          scaleFactorsChanged = !areScaleFactorsEquals(newProductionScaleFactor, scaleFactors)
          break
        case GeometryType.Coupon:
          const newCouponScaleFactor = getPrintStrategyDefaultByGeometryType(
            GeometryType.Coupon,
            state.selectedBuildPlanPrintStrategy,
          ).parameterSet.partParameters.ScaleFactors
          scaleFactorsChanged = !areScaleFactorsEquals(newCouponScaleFactor, scaleFactors)
          break
        case GeometryType.Support:
          const newSupportScaleFactor = getPrintStrategyDefaultByGeometryType(
            GeometryType.Support,
            state.selectedBuildPlanPrintStrategy,
          ).parameterSet.partParameters.ScaleFactors
          scaleFactorsChanged = !areScaleFactorsEquals(newSupportScaleFactor, scaleFactors)
          break
      }

      if (scaleFactorsChanged) {
        return scaleFactorsChanged
      }
    }

    return scaleFactorsChanged
  },

  async updateBuildPlanItem(
    { commit, state, getters, dispatch },
    payload: {
      params: IUpdateBuildPlanItemParamsDto
      hideAPIErrorMessages?: boolean
    },
  ) {
    if (!state.buildPlan || !state.buildPlan.buildPlanItems) {
      return
    }

    const { params, hideAPIErrorMessages } = payload
    const silent = params.silent

    const updateBuildPlanItemQueue = updateBuildPlanItemMap.get(params.buildPlanItemId) || []
    if (params.takeIntoAccountRequestsQueue && updateBuildPlanItemQueue.length) {
      await Promise.all(updateBuildPlanItemQueue.map((request) => request.promise))
    }

    const bpItem = state.buildPlan.buildPlanItems.find((item) => item.id === params.buildPlanItemId)

    if (!bpItem) {
      return
    }

    const updateStateOnly = params.updateStateOnly || getters.isLockedForViewer
    // TODO: Update method to accept build plan item DTO separate from params not related to build plan item
    delete params.updateStateOnly
    delete params.silent

    if (params.geometryProperties) {
      // Deep merge geometry properties in order to not re-calculate volume each time user rotates geometry
      const geometryProperties = bpItem.geometryProperties
        ? {
          surfaceArea: bpItem.geometryProperties.surfaceArea,
          volume: bpItem.geometryProperties.volume,
          supportSurfaceArea: bpItem.geometryProperties.supportSurfaceArea,
          supportVolume: bpItem.geometryProperties.supportVolume,
        }
        : {}
      params.geometryProperties = Object.assign(geometryProperties, params.geometryProperties)
    }

    // Let the visualization module know that constraints are updated
    if (params.constraints) {
      dispatch('updateConstraints', {
        buildPlanItemId: params.buildPlanItemId,
        constraints: params.constraints,
      })
    }

    try {
      // Due to vue interactivity we can not use Object.assign() to bpItem,
      // because it changes object by link and update state implicitly
      const bpItemCopy: IBuildPlanItem = JSON.parse(JSON.stringify(bpItem))
      const updateDto: IBuildPlanItem = Object.assign(bpItemCopy, params)
      let updatedBuildPlanItem: IBuildPlanItem = null

      if (!updateStateOnly) {
        let done
        const promise = new Promise<void>((resolve) => (done = resolve))
        updateBuildPlanItemQueue.push({ done, promise })
        updateBuildPlanItemMap.set(bpItem.id, updateBuildPlanItemQueue)

        updatedBuildPlanItem = await buildPlanItems.updateBuildPlanItem(updateDto, !!hideAPIErrorMessages, silent)
        commit('removePendingPartElevationRequest')
      } else {
        updatedBuildPlanItem = updateDto
      }

      if (updatedBuildPlanItem) {
        commit('setBuildPlanItem', {
          buildPlanItem: updatedBuildPlanItem,
          buildPlanItemId: params.buildPlanItemId,
        })
        commit('updateVariant', createVariantItemFromPlan(state.buildPlan))

        dispatch('parameterSetScaleUpdated', [
          {
            buildPlanItemId: params.buildPlanItemId,
            partProperties: params.partProperties,
          },
        ])
      }
    } catch (error) {
      handleUpdateBuildPlanItemError(error, commit)
      throw error
    } finally {
      const bPItemQueue = updateBuildPlanItemMap.get(bpItem.id)
      if (bPItemQueue && bPItemQueue.length) {
        const updatePromise = bPItemQueue.shift()
        updatePromise.done()
        updateBuildPlanItemMap.set(bpItem.id, bPItemQueue)
      }
    }
  },

  // This is only for persistent supports
  async removeSupports(
    { commit, state },
    payload: {
      params: {
        buildPlanItemId: string
      }
      hideAPIErrorMessages?: boolean
    },
  ) {
    const { params, hideAPIErrorMessages } = payload
    const bpItem = state.buildPlan.buildPlanItems.find((item) => item.id === params.buildPlanItemId)
    if (
      !bpItem ||
      (!(bpItem.supports && bpItem.supports.length) &&
        !bpItem.overhangs &&
        !bpItem.supportsBvhFileKey &&
        !bpItem.supportsHullFileKey)
    ) {
      return
    }

    const updatedBuildPlanItem = await buildPlanItems.removeSupports(
      {
        id: bpItem.id,
        buildPlanId: bpItem.buildPlanId,
        overhangs: null,
        supports: null,
        supportsBvhFileKey: null,
        supportsHullFileKey: null,
      },
      !!hideAPIErrorMessages,
    )

    if (updatedBuildPlanItem) {
      commit('setBuildPlanItem', {
        buildPlanItem: updatedBuildPlanItem,
        buildPlanItemId: params.buildPlanItemId,
      })
    }
  },

  async createBuildPlanItem(
    { commit, state, dispatch, getters },
    params: {
      partId: string
      loadingPartIndex: number
      transformation: number[]
      isSuccess: boolean
      constraints?: IConstraints
    },
  ) {
    if (!params.isSuccess) {
      commit('updateLoadingPart', {
        id: params.loadingPartIndex,
        partId: params.partId,
        isSuccess: false,
      })
      return
    }

    const newBpItem: IBuildPlanItem = {} as IBuildPlanItem
    newBpItem.part = { id: params.partId } as IPart
    newBpItem.transformationMatrix = params.transformation
    newBpItem.overhangAngle = getters.getBuildPlanPrintStrategy.productionSet.overhangAngle
    newBpItem.buildPlanId = state.buildPlan.id
    if (params.constraints) {
      newBpItem.constraints = params.constraints
    }

    if (state.addPartTool.selectedPartProperties[params.partId].length) {
      newBpItem.partProperties = state.addPartTool.selectedPartProperties[params.partId]
    }

    const createdBuildPlanItem = await buildPlanItems.createBuildPlanItem(newBpItem)

    commit('setBuildPlanItem', { buildPlanItem: createdBuildPlanItem })

    commit('updateVariant', createVariantItemFromPlan(state.buildPlan))

    const buildPlanItemId = createdBuildPlanItem.id
    dispatch('setLoadingPartIndex', { buildPlanItemId, loadingPartIndex: params.loadingPartIndex })

    const command = new BuildPlanItemsCommand([createdBuildPlanItem], [], [], getters.getCommandType, dispatch, commit)
    command.toolName = ToolNames.ADD_PART

    commit('commandManager/addCommand', command, {
      root: true,
    })
    commit('updateLoadingPart', {
      id: params.loadingPartIndex,
      partId: params.partId,
      isSuccess: true,
    })
  },

  async addBuildPlanItemLabel(
    { dispatch, commit, getters },
    params: {
      buildPlanItemId: string
      label: ILabel
      needToLoadItemLabel: false
      file: File
      insights: IBuildPlanInsight[]
      doNotForceUndoRedoCommandCreation: false
    },
  ) {
    try {
      const updatedBuildPlanItem = await buildPlanItems.updateLabel(params.buildPlanItemId, params.label, params.file)
      commit('setBuildPlanItem', { buildPlanItem: updatedBuildPlanItem, buildPlanItemId: params.buildPlanItemId })

      if (params.needToLoadItemLabel) {
        const newLabel = updatedBuildPlanItem.labels.find((l) => l.id === params.label.id)
        const instanceLabelPayload: InstanceLabel = {
          itemId: updatedBuildPlanItem.id,
          label: newLabel,
          transformation: updatedBuildPlanItem.transformationMatrix,
          insights: params.insights,
        }

        commit('visualizationModule/labelInstance', instanceLabelPayload, { root: true })

        if (params.doNotForceUndoRedoCommandCreation) {
          return
        }

        commit(
          'commandManager/addCommand',
          new LabelCommand(
            updatedBuildPlanItem.id,
            newLabel,
            params.file,
            getters.getCommandType,
            LabelMode.AddLabel,
            dispatch,
            commit,
          ),
          { root: true },
        )
      } else {
        dispatch('refreshLabelInsightsOnScene')

        if (params.doNotForceUndoRedoCommandCreation) {
          return
        }

        // Get actual label that comes from the server and contains the fileKey property
        const label = updatedBuildPlanItem.labels.find((l) => l.id === params.label.id)
        commit(
          'commandManager/addCommand',
          new LabelCommand(
            updatedBuildPlanItem.id,
            label,
            params.file,
            getters.getCommandType,
            LabelMode.AddLabel,
            dispatch,
            commit,
          ),
          { root: true },
        )
      }
    } catch {
      messageService.showErrorMessage('Unable to add label')
    }
  },

  async copyBuildPlanItemLabel(
    { dispatch, commit, getters },
    params: {
      buildPlanItemId: string
      label: ILabel
      insights: IBuildPlanInsight[]
    },
  ) {
    try {
      const updatedBuildPlanItem = await buildPlanItems.copyLabel(params.buildPlanItemId, params.label)
      commit('setBuildPlanItem', { buildPlanItem: updatedBuildPlanItem, buildPlanItemId: params.buildPlanItemId })

      const newLabel = updatedBuildPlanItem.labels.find((l) => l.id === params.label.id)
      const instanceLabelPayload: InstanceLabel = {
        itemId: updatedBuildPlanItem.id,
        label: newLabel,
        transformation: updatedBuildPlanItem.transformationMatrix,
        insights: params.insights,
      }

      commit('visualizationModule/deleteRenderedLabel', instanceLabelPayload.label.id, { root: true })
      commit('visualizationModule/labelInstance', instanceLabelPayload, { root: true })
      dispatch('refreshLabelInsightsOnScene')
    } catch {
      messageService.showErrorMessage('Unable to copy label')
    }
  },

  async updateBuildPlanItemLabel(
    { commit, dispatch },
    params: {
      buildPlanItemId: string
      label: ILabel
      needToLoadItemLabel: false
      file?: File
      insights: IBuildPlanInsight[]
    },
  ) {
    try {
      const updatedBuildPlanItem = await buildPlanItems.updateLabel(params.buildPlanItemId, params.label, params.file)
      commit('setBuildPlanItem', { buildPlanItem: updatedBuildPlanItem, buildPlanItemId: params.buildPlanItemId })

      if (params.needToLoadItemLabel) {
        const newLabel = updatedBuildPlanItem.labels.find((l) => l.id === params.label.id)
        const instanceLabelPayload: InstanceLabel = {
          itemId: updatedBuildPlanItem.id,
          label: newLabel,
          transformation: updatedBuildPlanItem.transformationMatrix,
          insights: params.insights,
        }

        commit('visualizationModule/deleteRenderedLabel', params.label.id, { root: true })
        commit('visualizationModule/labelInstance', instanceLabelPayload, { root: true })
      } else {
        dispatch('refreshLabelInsightsOnScene')
      }
    } catch {
      messageService.showErrorMessage('Unable to update label')
    }
  },

  async deleteBuildPlanItemLabel(
    { dispatch, commit, getters },
    params: {
      buildPlanItemId: string
      label: ILabel
      leftOnScene: boolean
      file?: File
      doNotForceUndoRedoCommandCreation: false
    },
  ) {
    try {
      const updatedBuildPlanItem = await buildPlanItems.deleteLabel(params.buildPlanItemId, params.label.id)
      commit('setBuildPlanItem', { buildPlanItem: updatedBuildPlanItem, buildPlanItemId: params.buildPlanItemId })
      if (!params.leftOnScene) {
        commit('visualizationModule/deleteRenderedLabel', params.label.id, { root: true })
        dispatch('refreshLabelInsightsOnScene')
      }

      if (params.doNotForceUndoRedoCommandCreation) {
        return
      }

      commit(
        'commandManager/addCommand',
        new LabelCommand(
          params.buildPlanItemId,
          params.label,
          params.file,
          getters.getCommandType,
          LabelMode.RemoveLabel,
          dispatch,
          commit,
        ),
        { root: true },
      )
    } catch {
      messageService.showErrorMessage('Unable to delete label')
    }
  },

  async selectBuildPlate({ commit, state, dispatch }, pk: VersionablePk) {
    const bp: IBuildPlan = { buildPlateId: pk.id, buildPlateVersion: pk.version } as IBuildPlan

    commit('setBuildPlate', pk)
    dispatch('updateBuildPlanV1', { buildPlan: bp })
  },

  async updateCrossSectionMatrix({ commit, state, dispatch, getters }, crossSectionMatrix: number[]) {
    const bp = state.buildPlan
    bp.crossSectionMatrix = crossSectionMatrix

    commit('setCrossSectionMatrix', crossSectionMatrix)
    if (!getters.isLockedForViewer) {
      dispatch('updateBuildPlanV1', { buildPlan: bp })
    }
  },

  async deleteSelectedParts({ dispatch, state }) {
    try {
      const deletedItemIds = state.selectedItems.map((item) => item.id)
      if (!deletedItemIds.length) {
        return
      }
      await dispatch('deleteParts', { deletedItemIds })
    } catch (error) {
      console.error(`Error while deleting buildPlanItems: ${error}`)
    }
  },

  async deleteParts(
    { commit, dispatch, getters },
    payload: { deletedItemIds: string[]; doNotCreateUndoCommand: boolean },
  ) {
    try {
      const { deletedItemIds, doNotCreateUndoCommand } = payload

      const tempBuildPlanItemsToDelete = getters.getAllBuildPlanItems.filter((item) => deletedItemIds.includes(item.id))
      const buildPlanItemsToDelete = JSON.parse(JSON.stringify(tempBuildPlanItemsToDelete))
      const buildPlanItemInsightsToDelete = getters.insights.filter(
        (insight) =>
          insight.details &&
          insight.details.parts &&
          insight.details.parts.some((part) => deletedItemIds.includes(part.bpItemId)),
      )

      const buildPlanInsightIds = buildPlanItemInsightsToDelete.map((insight) => insight.id)
      if (buildPlanInsightIds.length) {
        await dispatch('deleteInsightMultiple', { insightsIds: buildPlanInsightIds })
      }

      await buildPlans.deleteBuildPlanItems(deletedItemIds)

      dispatch('deleteSelectedPartsFromScene', deletedItemIds)

      if (doNotCreateUndoCommand) {
        return
      }

      commit(
        'commandManager/addCommand',
        new BuildPlanItemsCommand(
          [],
          buildPlanItemsToDelete,
          buildPlanItemInsightsToDelete,
          getters.getCommandType,
          dispatch,
          commit,
        ),
        {
          root: true,
        },
      )
    } catch (error) {
      console.error(`Error while deleting buildPlanItems: ${error}`)
    }
  },

  /***
   * IMPORTANT: The following action is created for Undo - Redo functionality. Don't use it for removing any items from
   * the build plate, use instead the 'deleteSelectedParts' action, which is defined above.
   * @param payload { Object } - Action payload.
   * @param { Array<string> } payload.buildPlanItemIds - Array of the build plan item ids.
   * @param { boolean } payload.doNotCreateUndoCommand - Boolean flag which is force command creation by commandManager.
   */
  async deleteBuildPlanItemsById(
    { commit, dispatch, getters },
    payload: { buildPlanItemIds: string[]; doNotCreateUndoCommand: boolean },
  ) {
    try {
      await buildPlans.deleteBuildPlanItems(payload.buildPlanItemIds)

      if (!payload.doNotCreateUndoCommand) {
        const buildPlanItemsToDelete: IBuildPlanItem[] = payload.buildPlanItemIds.map((id) => {
          return getters.getAllBuildPlanItems.find((bpItem) => bpItem.id === id)
        })
        commit(
          'commandManager/addCommand',
          new BuildPlanItemsCommand([], buildPlanItemsToDelete, [], getters.getCommandType, dispatch, commit),
          {
            root: true,
          },
        )
      }

      dispatch('deleteSelectedPartsFromScene', payload.buildPlanItemIds)
    } catch (error) {
      messageService.showErrorMessage('Failed to execute action.')
      throw new Error(error.message)
    }
  },

  async fetchBuildPlanJobs({ commit }, id: string) {
    const bpJobs = await buildPlans.getJobs(id)
    commit('setSelectedBuildPlanJobs', bpJobs)
  },

  async createInstances({ commit, state, getters, dispatch, rootGetters }, hideAPIErrorMessages?: boolean) {
    const createInstancesResult = state.duplicateTool.instances.map((instance) => {
      const srcBuildPlanItem = state.buildPlan.buildPlanItems.find((bpItem) => bpItem.id === instance.buildPlanItemId)

      const positions = instance.meshInfo.map((meshInfo) => meshInfo.instancesTransformations)

      const buildPlanItemInstancesDto: BuildPlanItemInstancesDto = {
        srcBuildPlanItem,
        positions,
      }

      return buildPlanItemInstancesDto
    })

    const updatedItems = await buildPlanItems.instantiateBuildPlanItems(createInstancesResult, !!hideAPIErrorMessages)
    const stateItemsIndices = state.buildPlan.buildPlanItems.map((item) => item.id)
    const finishInstancingProcessItems = updatedItems.filter((item) => !stateItemsIndices.includes(item.id))

    const singleSelection = state.selectedBuildPlanViewMode !== ViewModeTypes.Duplicate

    const labelSetsIdsToUpdate = rootGetters['label/getLabelSetsToUpdateOnDuplicate'](createInstancesResult)

    const labelPromises = []
    if (labelSetsIdsToUpdate.length) {
      const buildPlan = getters.getBuildPlan
      // Previously added ids should also be included into update process
      buildPlan.labelSetIDsToUpdate = buildPlan.labelSetIDsToUpdate
        ? [...new Set([...buildPlan.labelSetIDsToUpdate, ...labelSetsIdsToUpdate])]
        : labelSetsIdsToUpdate
      commit('setRequiresLabelSetUpdates', true)
      dispatch('label/setLabelSetsIDsForUpdate', { ids: buildPlan.labelSetIDsToUpdate }, { root: true })
      labelPromises.push(
        ...[
          dispatch('updateBuildPlanV1', { buildPlan }),
          dispatch(
            'label/getLabelSetsByBuildPlanId',
            { buildPlanId: buildPlan.id, dirtyStateAddIfNew: true },
            { root: true },
          ),
        ],
      )
    }

    commit('addBuildPlanItems', finishInstancingProcessItems)
    dispatch('finishInstancingProcess', { singleSelection, items: finishInstancingProcessItems })
    commit('setDuplicateToolState', {
      instances: null,
    })
    dispatch('refreshBoundingBoxForPartsOnly')

    const command = new BuildPlanItemsCommand(
      finishInstancingProcessItems,
      [],
      [],
      getters.getCommandType,
      dispatch,
      commit,
    )
    command.toolName = ToolNames.DUPLICATE

    commit('commandManager/addCommand', command, {
      root: true,
    })

    if (labelPromises.length) {
      await Promise.all(labelPromises)
    }
  },

  async calcBuildPlanCost({ state, commit }, payload: { buildPlanId: string; dto: BuildPlanCostCreateDTO }) {
    commit('setCalcCostInProgress', true)
    costCallQueue.push(payload)
    try {
      const calculatedBuildPlan = await buildPlans.calcBuildPlanCost(payload.buildPlanId, payload.dto)
      if (state.buildPlan && state.buildPlan.id === payload.buildPlanId) {
        commit('setIsShownNoMaterialParamsTooltip', false)
        commit('updateBuildPlan', calculatedBuildPlan)
        commit('setBuildPlan', calculatedBuildPlan)
      }
    } catch (error) {
      if (state.buildPlan) {
        state.buildPlan.cost = null
      }

      if (error.data.message === MISSING_COST_PROPERTIES_ERROR) {
        commit('setIsShownNoMaterialParamsTooltip', true)
      }

      console.error(error)
    } finally {
      costCallQueue.shift()
      if (!costCallQueue.length) {
        commit('setCalcCostInProgress', false)
      }
    }
  },

  async moveSelectedItemsToDelta({ commit, getters, dispatch }, delta: ITransformationDelta) {
    const selectedItems: IBuildPlanItem[] = JSON.parse(JSON.stringify(getters.getSelectedBuildPlanItems))

    dispatch('transformSelectedParts', { delta, type: ToolTypes.MoveTool })
  },

  async recenter({ commit, dispatch, getters }) {
    const selectedItems: IBuildPlanItem[] = JSON.parse(JSON.stringify(getters.getSelectedBuildPlanItems))

    dispatch('restoreSelectedParts', RestoreSelectedPartsType.Recenter)

    const command = new RestoreSelectedPartsCommand(
      selectedItems,
      getters.getCommandType,
      RestoreSelectedPartsType.Recenter,
      dispatch,
      commit,
    )
    command.toolName = ToolNames.MOVE

    commit('commandManager/addCommand', command, {
      root: true,
    })
  },

  async restoreImportedLocation({ commit, dispatch, getters }) {
    const selectedItems: IBuildPlanItem[] = JSON.parse(JSON.stringify(getters.getSelectedBuildPlanItems))

    dispatch('restoreSelectedParts', RestoreSelectedPartsType.RestoreImportedLocation)

    const command = new RestoreSelectedPartsCommand(
      selectedItems,
      getters.getCommandType,
      RestoreSelectedPartsType.RestoreImportedLocation,
      dispatch,
      commit,
    )
    command.toolName = ToolNames.MOVE

    commit('commandManager/addCommand', command, {
      root: true,
    })
  },

  async restoreImportedRotation({ commit, dispatch, getters }) {
    const selectedItems: IBuildPlanItem[] = JSON.parse(JSON.stringify(getters.getSelectedBuildPlanItems))

    dispatch('restoreSelectedParts', RestoreSelectedPartsType.RestoreImportedRotation)

    const command = new RestoreSelectedPartsCommand(
      selectedItems,
      getters.getCommandType,
      RestoreSelectedPartsType.RestoreImportedRotation,
      dispatch,
      commit,
    )
    command.toolName = ToolNames.ROTATE

    commit('commandManager/addCommand', command, {
      root: true,
    })
  },

  async updateIncrement({ commit }, increment: number) {
    throw new Error('Not implemented.')
  },

  async rotateSelectedItem({ commit, dispatch, getters }, rotationValues: ITransformationDelta) {
    const selectedItems: IBuildPlanItem[] = JSON.parse(JSON.stringify(getters.getSelectedBuildPlanItems))

    dispatch('transformSelectedParts', { delta: rotationValues, type: ToolTypes.RotateTool })
  },

  async publishBuildPlan({ state }) {
    const id = state.buildPlan.id
    return await buildPlans.publishBuildPlan(id)
  },

  async updateBuildPlanSimulationParams(
    { commit, state, dispatch },
    payload: { simulationParams: IBaseSimulateCompensation; hideAPIErrorMessages?: boolean },
  ) {
    const { simulationParams, hideAPIErrorMessages } = payload
    const bp = state.buildPlan
    bp.simulationParameters = simulationParams
    dispatch('updateBuildPlanV1', { buildPlan: bp, hideAPIErrorMessages: !!hideAPIErrorMessages })
  },

  async fetchInsightsByBuildPlanId({ commit }, payload: { buildPlanId: string; changeState: boolean }) {
    const insights = await buildPlans.fetchInsightsByBuildPlanId(payload.buildPlanId)
    if (payload.changeState) {
      commit('loadInsights', insights)
    }

    return insights
  },

  async fetchInsightById({ commit }, insightId: string) {
    const insight = await buildPlans.fetchInsightById(insightId)
    commit('addInsights', [insight])
  },

  async createInsight({ commit }, insight: IBuildPlanInsight) {
    const created = await buildPlans.createInsight(insight)
    commit('addInsights', created)
  },

  async createInsightMultiple({ commit }, payload: { insights: IBuildPlanInsight[]; stateOnly: boolean }) {
    let insights = payload.insights
    if (!payload.stateOnly) {
      insights = await buildPlans.createInsightMultiple(payload.insights)
    }

    commit('addInsights', insights)

    return insights
  },

  async updateInsight({ commit }, insight: IBuildPlanInsight) {
    const updated = await buildPlans.updateInsight(insight)
    commit('updateInsights', [updated])
  },

  async updateInsightMultiple({ commit }, payload: { insights: IBuildPlanInsight[]; stateOnly: boolean }) {
    let insights = payload.insights
    if (!payload.stateOnly) {
      insights = await buildPlans.updateInsightMultiple(payload.insights)
    }

    commit('updateInsights', insights)
  },

  async deleteInsight({ commit }, payload: { insightId: string; stateOnly: boolean }) {
    if (!payload.stateOnly) {
      await buildPlans.deleteInsight(payload.insightId)
    }

    commit('removeInsights', [payload.insightId])
  },

  async deleteInsightMultiple(
    { commit },
    payload: {
      insightsIds: string[]
      stateOnly: boolean
      insights?: IBuildPlanInsight[]
    },
  ) {
    if (!payload.stateOnly && payload.insightsIds.length) {
      await buildPlans.deleteInsightMultiple(payload.insightsIds)
    }

    const allInsightsDefined: boolean = payload.insightsIds.length && payload.insightsIds.every((id) => !!id)
    if (allInsightsDefined) {
      commit('removeInsights', payload.insightsIds)
    } else {
      commit('removeInsightsByInsights', payload.insights)
    }
  },

  async deleteInsightsWithNoStateChanges({ }, insightsIds: string[]) {
    await buildPlans.deleteInsightMultiple(insightsIds)
  },

  async transferBuildPlanItemsProps(
    { commit, state },
    payload: {
      sourceId: string
      targets: Array<{ id: string; transformationMatrix: number[] }>
    },
  ) {
    try {
      const result: TransferPropsResponseDto = await buildPlanItems.transferBuildPlanItemsProps(payload)

      commit('updateBuildPlanItems', result.buildPlanItems)
      commit('addInsights', result.insights)

      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  getSignedUrlV1({ }, payload: { fileKey: string; newName?: string }) {
    return buildPlans.getSignedUrlV1(payload.fileKey, payload.newName)
  },

  async getHeadObject({ }, s3filename: string) {
    return await buildPlans.getHeadObject(s3filename)
  },

  async fetchVariantJobsByBuildPlanId({ commit }, buildPlanId: string) {
    if (!buildPlanId) {
      return
    }

    const allJobs = await buildPlans.getBuildPlanVariantJobs(buildPlanId)

    if (!allJobs) {
      return
    }

    // set variant jobs
    commit('updateBuildPlanVariantJobs', allJobs)
    // find and set selected build plan jobs
    const selectedBpJobs = allJobs.filter((j) => j.itemId === buildPlanId || j.relatedPlanId === buildPlanId)
    commit('setSelectedBuildPlanJobs', selectedBpJobs)
  },

  async setVariantDescription({ commit, getters }, plan: IVariantItem): Promise<void> {
    try {
      const result = await buildPlans.setVariantDescription(plan.id, plan.versionDescription)
      if (!result) {
        return
      }

      const variant: IBuildPlan = getters.getBuildPlanVariantById(plan.id)
      variant.versionDescription = plan.versionDescription

      commit('updateVariant', variant)
      if (plan.itemType === ItemType.BuildPlan) {
        const buildPlan = getters.getBuildPlan
        buildPlan.versionDescription = plan.versionDescription
        commit('updateBuildPlan', buildPlan)
        commit('setBuildPlan', buildPlan)
      }
      if (plan.itemType === ItemType.IbcPlan) {
        const ibcPlan = getters.getIBCPlan
        ibcPlan.versionDescription = plan.versionDescription
        commit('setIBCPlan', ibcPlan)
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async setVariantOrder({ commit, state }, payload: { buildPlanId: string; order: number }): Promise<void> {
    try {
      await buildPlans.setVariantOrder(payload.buildPlanId, payload.order)

      let buildPlanVariants = [...state.buildPlanVariants]
      buildPlanVariants.sort((firstVersion, secondVersion) => firstVersion.versionOrder - secondVersion.versionOrder)
      const variantIndex = buildPlanVariants.findIndex((variant) => variant.id === payload.buildPlanId)
      buildPlanVariants.splice(payload.order, 0, buildPlanVariants.splice(variantIndex, 1)[0])
      buildPlanVariants = buildPlanVariants.map((variant, index) => {
        variant.versionOrder = index

        return variant
      })

      commit('setVariants', buildPlanVariants)
      commit('sortVariants')
    } catch (error) {
      messageService.showErrorMessage(error.message)
    }
  },

  async createProductionSet(
    { commit },
    payload: {
      productionSetFile: File
      geDefault: boolean
      modality: PrintingTypes
      machineConfigId: number
      rasterSettingsFile: File
      machineType: string
    },
  ) {
    return await buildPlans.createProductionSet(payload)
  },

  changeRecoaterDirectionShaderVisibility({ commit, getters, state }, isVisible: boolean) {
    const displayToolbarState = getters.displayToolbarStateByVariantId(state.buildPlan.id)
    displayToolbarState.isShowingRecoaterDirectionShader = isVisible
    if (isVisible) {
      displayToolbarState.isShowingOverhangAreasShader = false
    }
    commit('updateDisplayToolbarState', { buildPlanId: state.buildPlan.id, state: displayToolbarState })
    const mode = isVisible ? VisualizationModeTypes.RecoaterDirection : VisualizationModeTypes.Default
    changeVisualizationMode(state, commit, mode)
  },

  changeOverhangAreasShaderVisibility({ commit, getters, state }, isVisible: boolean) {
    const displayToolbarState = getters.displayToolbarStateByVariantId(state.buildPlan.id)
    displayToolbarState.isShowingOverhangAreasShader = isVisible
    if (isVisible) {
      displayToolbarState.isShowingRecoaterDirectionShader = false
    }
    commit('updateDisplayToolbarState', { buildPlanId: state.buildPlan.id, state: displayToolbarState })
    const mode = isVisible ? VisualizationModeTypes.OverhangAreas : VisualizationModeTypes.Default
    changeVisualizationMode(state, commit, mode)
  },

  changeRecoaterDirectionLabelVisibility({ commit, getters, state }, isVisible: boolean) {
    const displayToolbarState = getters.displayToolbarStateByVariantId(state.buildPlan.id)
    displayToolbarState.isShowingRecoaterDirection = isVisible
    commit('updateDisplayToolbarState', { buildPlanId: state.buildPlan.id, state: displayToolbarState })
    changeMeshVisibilityByName(state, commit, RECOATER_DIRECTION_MESH_NAME, isVisible)
  },

  changeGasFlowDirectionVisibility({ commit, getters, state }, isVisible: boolean) {
    const displayToolbarState = getters.displayToolbarStateByVariantId(state.buildPlan.id)
    displayToolbarState.isShowingGasFlowDirection = isVisible
    commit('updateDisplayToolbarState', { buildPlanId: state.buildPlan.id, state: displayToolbarState })
    changeMeshVisibilityByName(state, commit, GAS_FLOW_MESH_NAME, isVisible)
  },

  changeBuildPlanVolumeVisibility({ commit, getters, state }, isVisible: boolean) {
    const displayToolbarState = getters.displayToolbarStateByVariantId(state.buildPlan.id)
    displayToolbarState.isShowingBuildPlanVolume = isVisible
    commit('updateDisplayToolbarState', { buildPlanId: state.buildPlan.id, state: displayToolbarState })
    changeMeshVisibilityByName(state, commit, BUILD_CHAMBER_POLYLINES_NAME, isVisible)
  },

  changeBuildPlateVisibility({ commit, getters, state }, isVisible: boolean) {
    const displayToolbarState = getters.displayToolbarStateByVariantId(state.buildPlan.id)
    displayToolbarState.isShowingBuildPlate = isVisible
    commit('updateDisplayToolbarState', { buildPlanId: state.buildPlan.id, state: displayToolbarState })
    changeBuildPlateMeshVisibility(state, commit, isVisible, getters.isSinterPlan)
  },

  changePrintHeadVisibility({ commit, getters, state }, isVisible: boolean) {
    const displayToolbarState = getters.displayToolbarStateByVariantId(state.buildPlan.id)
    displayToolbarState.isShowingPrintHead = isVisible
    commit('updateDisplayToolbarState', { buildPlanId: state.buildPlan.id, state: displayToolbarState })
    changeMeshVisibilityByName(state, commit, PRINT_HEAD_DIRECTION_MESH_NAME, isVisible)
  },

  changePrintHeadLanesVisibility({ commit, getters, state }, isVisible: boolean) {
    const displayToolbarState = getters.displayToolbarStateByVariantId(state.buildPlan.id)
    displayToolbarState.isShowingPrintHeadLanes = isVisible
    commit('updateDisplayToolbarState', { buildPlanId: state.buildPlan.id, state: displayToolbarState })
    changeMeshVisibilityByName(state, commit, PRINT_HEAD_LANES_MESH_NAME, isVisible)
  },

  changeProductionGeometryVisibility({ commit, getters, state }, isVisible: boolean) {
    const displayToolbarState = getters.displayToolbarStateByVariantId(state.buildPlan.id)
    displayToolbarState.isShowingProductionGeometry = isVisible
    commit('updateDisplayToolbarState', { buildPlanId: state.buildPlan.id, state: displayToolbarState })
    changeProductionMeshesVisibility(state, commit, isVisible)
  },

  changeSupportGeometryVisibility({ commit, getters, state }, isVisible: boolean) {
    const displayToolbarState = getters.displayToolbarStateByVariantId(state.buildPlan.id)
    displayToolbarState.isShowingSupportGeometry = isVisible
    commit('updateDisplayToolbarState', { buildPlanId: state.buildPlan.id, state: displayToolbarState })
    changeSupportMeshesVisibility(state, commit, isVisible)
  },

  changeCouponGeometryVisibility({ commit, getters, state }, isVisible: boolean) {
    const displayToolbarState = getters.displayToolbarStateByVariantId(state.buildPlan.id)
    displayToolbarState.isShowingCouponGeometry = isVisible
    commit('updateDisplayToolbarState', { buildPlanId: state.buildPlan.id, state: displayToolbarState })
    changeCouponMeshesVisibility(state, commit, isVisible)
  },

  changeLabeledBodiesVisibility({ commit, getters, rootGetters, state }, isVisible: boolean) {
    const displayToolbarState = getters.displayToolbarStateByVariantId(state.buildPlan.id)
    displayToolbarState.isShowingAllLabledBodies = isVisible
    commit('updateDisplayToolbarState', { buildPlanId: state.buildPlan.id, state: displayToolbarState })
    const activeLabelSet = rootGetters['label/activeLabelSet']
    const activeLabelSetId = activeLabelSet ? activeLabelSet.id : null
    commit(
      'visualizationModule/setLabeledBodiesVisibility',
      { activeLabelSetId, visibility: isVisible },
      { root: true },
    )
  },

  changeVisibilityByDisplayToolbarState({ commit, getters, state }) {
    if (!state.buildPlan) {
      return
    }

    const toolbarState: IDisplayToolbarState = getters.displayToolbarStateByVariantId(state.buildPlan.id)

    changeProductionMeshesVisibility(state, commit, toolbarState.isShowingProductionGeometry)
    changeSupportMeshesVisibility(state, commit, toolbarState.isShowingSupportGeometry)
    changeCouponMeshesVisibility(state, commit, toolbarState.isShowingCouponGeometry)
    changeMeshVisibilityByName(state, commit, RECOATER_DIRECTION_MESH_NAME, toolbarState.isShowingRecoaterDirection)
    changeMeshVisibilityByName(state, commit, GAS_FLOW_MESH_NAME, toolbarState.isShowingGasFlowDirection)
    changeMeshVisibilityByName(state, commit, BUILD_CHAMBER_POLYLINES_NAME, toolbarState.isShowingBuildPlanVolume)
    changeMeshVisibilityByName(state, commit, PRINT_HEAD_DIRECTION_MESH_NAME, toolbarState.isShowingPrintHead)
    changeMeshVisibilityByName(state, commit, PRINT_HEAD_LANES_MESH_NAME, toolbarState.isShowingPrintHeadLanes)
    changeBuildPlateMeshVisibility(state, commit, toolbarState.isShowingBuildPlate, getters.isSinterPlan)
  },

  async batchMoveBuildPlanItems({ state, commit }, moveBuildPlanItemDtos: IMoveBuildPlanItemDto[]) {
    if (!state.buildPlan || !state.buildPlan.buildPlanItems || !moveBuildPlanItemDtos.length) {
      return
    }

    const moveBuildPlanItemsQueryDto: IMoveBuildPlanItemsDto = {
      moveBuildPlanItemDtos,
      buildPlanId: state.buildPlan.id,
    }
    const updatedBuildPlanItems = await buildPlanItems.batchMoveBuildPlanItems(moveBuildPlanItemsQueryDto)

    commit('updateBuildPlanItems', updatedBuildPlanItems)
  },

  async checkAccessForCreateVariants({ state, commit }, buildPlanId: string) {
    const canCreate = await buildPlans.checkAccessForEditVariants(buildPlanId)
    commit('setCanCreateVariants', canCreate)
  },

  refreshLabelInsights({ commit, getters }) {
    const labelInsights: IBuildPlanInsight[] = JSON.parse(JSON.stringify(getters.labelInsights))

    const buildPlan: IBuildPlan = getters.getBuildPlan
    if (labelInsights.length) {
      labelInsights.forEach((insight) => {
        const bpItemIds = buildPlan.buildPlanItems.map((bpItem) => bpItem.id)
        const newRelatedItems = insight.details.relatedItems.filter((relatedItem) =>
          bpItemIds.includes(relatedItem.buildPlanItemId),
        )

        insight.details.relatedItems = newRelatedItems
      })

      const insights = labelInsights.filter((insight) => insight.details.relatedItems.length)
      const pendingInsights: IPendingInsights = { insights, tool: ToolNames.LABEL }
      commit('reportInsightIssues', [pendingInsights])
    }
  },

  async removeLegacyLabels({ state, commit }, buildPlanId: string) {
    const updatedBuildPlanItems = await buildPlanItems.removeLegacyLabels(buildPlanId)

    commit('updateBuildPlanItems', updatedBuildPlanItems)
  },

  async getBuildPlanLockInfo({ state, commit }, buildPlanVariantId: string) {
    const buildPlanLockInfo = await buildPlans.getBuildPlanLockInfo(buildPlanVariantId)
    commit('updateVariantLockInfo', buildPlanLockInfo)
    commit('fileExplorer/updateItemLockInfo', buildPlanLockInfo, { root: true })

    return buildPlanLockInfo
  },

  async updateVariantsLockInfo({ state, dispatch }) {
    const updatePromises = state.buildPlanVariants
      .filter((variant) => !variant.isRemoved)
      .map((variant) => dispatch('getBuildPlanLockInfo', variant.id))
    await Promise.all(updatePromises)
  },

  async addReplaceUndoRedoCommand(
    { commit, dispatch, getters },
    payload: { incomingBuildPlanItems: IBuildPlanItem[]; outgoingBuildPlanItems: IBuildPlanItem[] },
  ) {
    const deletedItemIds = payload.outgoingBuildPlanItems.map((item) => item.id)
    const buildPlanItemInsightsToDelete = getters.insights.filter(
      (insight) =>
        insight.details &&
        insight.details.parts &&
        insight.details.parts.some((part) => deletedItemIds.includes(part.bpItemId)),
    )

    const command = new BuildPlanItemsCommand(
      payload.incomingBuildPlanItems,
      payload.outgoingBuildPlanItems,
      buildPlanItemInsightsToDelete,
      getters.getCommandType,
      dispatch,
      commit,
    )
    commit('commandManager/addCommand', command, {
      root: true,
    })
  },

  async fetchSliceLayers({ commit }, payload: { buildPlanId: string; printJobId?: string }) {
    commit('setIsLayersLoading', true)

    let sliceLayers: number[]

    try {
      sliceLayers = await buildPlans.getSliceLayers(payload.buildPlanId, payload.printJobId)
    } catch (error) {
      messageService.showErrorMessage(error.message)
    } finally {
      commit('setIsLayersLoading', false)
    }

    return sliceLayers
  },

  async createIbcPlanVariant({ commit }, ibcPlanId: string) {
    const ibcVariant = (await buildPlans.createIbcPlanVariant(ibcPlanId)) as IIBCPlan
    if (ibcVariant) {
      const variant = createVariantItemFromPlan(ibcVariant)
      commit('addVariant', variant)
      commit('addIBCDisplayToolbarState', { buildPlanId: ibcVariant.id, state: defaultIBCDisplayToolbarState })
      commit('sortVariants')
    }
    return ibcVariant
  },

  async fetchIbcPlanJobs({ commit }, payload: { ibcPlanId: string; fetchForRelatedVariants?: number }) {
    if (!payload.ibcPlanId) {
      return
    }
    const jobs = await buildPlans.getIBCVariantJobs(payload.ibcPlanId, payload.fetchForRelatedVariants)
    commit('updateIbcPlanJobs', jobs)
  },

  async deleteMeasurements(
    { commit, rootGetters },
    payload: { measurements: IMeasurementWithProperties[]; ibcPlanId: string },
  ) {
    // Deletes measurements from the DB and state.
    const measurementsToRemove: IbcMeasurementDeleteDto[] = payload.measurements.map((measurement) => {
      return {
        measurementId: measurement.id,
      }
    })
    await buildPlans.deleteMeasurements(measurementsToRemove, payload.ibcPlanId)
    commit('deleteMeasurements', measurementsToRemove)

    // Deletes measurements from the scene.
    const measurementsVisFileIds = payload.measurements.map((measurement) => measurement.measurementVisFileId)
    await rootGetters['visualizationModule/visualization'].deleteMeasurements(measurementsVisFileIds)
  },

  async loadMeasurementsForIBCPlan({ commit, rootGetters }, ibcPlan: IIBCPlan) {
    commit('setIsLoading', true)

    try {
      await rootGetters['visualizationModule/visualization'].loadMeasurementsForIBCPlan(ibcPlan)
    } catch (error) {
      console.error(error)
    }

    commit('setIsLoading', false)
  },

  changeIbcPartVisibility(
    { commit, rootGetters, getters, state },
    payload: { isPartVisible: boolean; isPartAndSupportsVisible: boolean },
  ) {
    const ibcDisplayToolbarState: IIBCDisplayToolbarState = getters.ibcDisplayToolbarStateByVariantId(state.ibcPlan.id)
    ibcDisplayToolbarState.part = payload.isPartVisible
    commit('updateIBCDisplayToolbarState', { ibcPlanId: state.ibcPlan.id, state: ibcDisplayToolbarState })

    rootGetters['visualizationModule/visualization'].changeIbcPartVisibility(
      rootGetters['buildPlans/getIBCPlan'],
      payload.isPartVisible && payload.isPartAndSupportsVisible,
    )
  },

  changeIbcSupportsVisibility(
    { commit, rootGetters, state, getters },
    payload: { isSupportsVisible: boolean; isPartAndSupportsVisible: boolean },
  ) {
    const ibcDisplayToolbarState: IIBCDisplayToolbarState = getters.ibcDisplayToolbarStateByVariantId(state.ibcPlan.id)
    ibcDisplayToolbarState.supports = payload.isSupportsVisible
    commit('updateIBCDisplayToolbarState', { ibcPlanId: state.ibcPlan.id, state: ibcDisplayToolbarState })

    rootGetters['visualizationModule/visualization'].changeIbcSupportsVisibility(
      rootGetters['buildPlans/getIBCPlan'],
      payload.isSupportsVisible && payload.isPartAndSupportsVisible,
    )
  },

  changeIbcPartAndSupportsVisibility(
    { commit, rootGetters, state, getters },
    payload: { isPartVisible: boolean; isSupportsVisible: boolean; isPartAndSupportsVisible: boolean },
  ) {
    const ibcDisplayToolbarState: IIBCDisplayToolbarState = getters.ibcDisplayToolbarStateByVariantId(state.ibcPlan.id)
    ibcDisplayToolbarState.partAndSupports = payload.isPartAndSupportsVisible
    commit('updateIBCDisplayToolbarState', { ibcPlanId: state.ibcPlan.id, state: ibcDisplayToolbarState })

    rootGetters['visualizationModule/visualization'].changeIbcPartVisibility(
      rootGetters['buildPlans/getIBCPlan'],
      payload.isPartVisible && payload.isPartAndSupportsVisible,
    )
    rootGetters['visualizationModule/visualization'].changeIbcSupportsVisibility(
      rootGetters['buildPlans/getIBCPlan'],
      payload.isSupportsVisible && payload.isPartAndSupportsVisible,
    )
  },

  changeIbcMeasurementsVisibility(
    { commit, state, getters, rootGetters },
    payload: { isInspTool: boolean; isVisible: boolean },
  ) {
    if (!state.ibcPlan) {
      return
    }

    const ibcDisplayToolbarState: IIBCDisplayToolbarState = getters.ibcDisplayToolbarStateByVariantId(state.ibcPlan.id)
    if (payload.isInspTool) {
      ibcDisplayToolbarState.inspToolMeasurements = payload.isVisible
    } else {
      ibcDisplayToolbarState.measurements = payload.isVisible
    }
    commit('updateIBCDisplayToolbarState', { ibcPlanId: state.ibcPlan.id, state: ibcDisplayToolbarState })

    state.ibcPlan.measurements.forEach((measurement) => {
      rootGetters['visualizationModule/visualization'].toggleIBCMeasurement(
        measurement.measurementVisFileId,
        payload.isVisible && measurement.visibility === Visibility.Visible,
      )
    })
  },

  changeIbcBuildVolumeVisibility({ commit, rootGetters, state, getters }, payload: { isVisible: boolean }) {
    const ibcDisplayToolbarState: IIBCDisplayToolbarState = getters.ibcDisplayToolbarStateByVariantId(state.ibcPlan.id)
    ibcDisplayToolbarState.buildVolume = payload.isVisible
    commit('updateIBCDisplayToolbarState', { ibcPlanId: state.ibcPlan.id, state: ibcDisplayToolbarState })

    rootGetters['visualizationModule/visualization'].setMeshesVisibilityByName(
      BUILD_CHAMBER_POLYLINES_NAME,
      payload.isVisible,
    )
    rootGetters['visualizationModule/visualization'].setMeshesVisibilityByName(
      RECOATER_DIRECTION_MESH_NAME,
      payload.isVisible,
    )
    rootGetters['visualizationModule/visualization'].setMeshesVisibilityByName(
      PRINT_HEAD_DIRECTION_MESH_NAME,
      payload.isVisible,
    )
  },

  changeIbcBuildPlateVisibility({ commit, rootGetters, state, getters }, payload: { isVisible: boolean }) {
    const ibcDisplayToolbarState: IIBCDisplayToolbarState = getters.ibcDisplayToolbarStateByVariantId(state.ibcPlan.id)
    ibcDisplayToolbarState.buildPlate = payload.isVisible
    commit('updateIBCDisplayToolbarState', { ibcPlanId: state.ibcPlan.id, state: ibcDisplayToolbarState })

    rootGetters['visualizationModule/visualization'].setBuildPlateMeshVisibility(payload.isVisible, true)
  },

  changeVisibilityByIBCDisplayToolbarState({ rootGetters, getters, state }) {
    if (!state.ibcPlan) {
      return
    }

    const ibcDisplayToolbarState: IIBCDisplayToolbarState = getters.ibcDisplayToolbarStateByVariantId(state.ibcPlan.id)

    rootGetters['visualizationModule/visualization'].changeIbcPartVisibility(
      rootGetters['buildPlans/getIBCPlan'],
      ibcDisplayToolbarState.part && ibcDisplayToolbarState.partAndSupports,
    )
    rootGetters['visualizationModule/visualization'].changeIbcSupportsVisibility(
      rootGetters['buildPlans/getIBCPlan'],
      ibcDisplayToolbarState.supports && ibcDisplayToolbarState.partAndSupports,
    )
    rootGetters['visualizationModule/visualization'].setMeshesVisibilityByName(
      BUILD_CHAMBER_POLYLINES_NAME,
      ibcDisplayToolbarState.buildVolume,
    )
    rootGetters['visualizationModule/visualization'].setMeshesVisibilityByName(
      RECOATER_DIRECTION_MESH_NAME,
      ibcDisplayToolbarState.buildVolume,
    )
    rootGetters['visualizationModule/visualization'].setMeshesVisibilityByName(
      PRINT_HEAD_DIRECTION_MESH_NAME,
      ibcDisplayToolbarState.buildVolume,
    )
    rootGetters['visualizationModule/visualization'].setBuildPlateMeshVisibility(
      ibcDisplayToolbarState.buildPlate,
      true,
    )

    state.ibcPlan.measurements.forEach((measurement) => {
      rootGetters['visualizationModule/visualization'].toggleIBCMeasurement(
        measurement.measurementVisFileId,
        ibcDisplayToolbarState.measurements && measurement.visibility === Visibility.Visible,
      )
    })
  },

  async setIbcPlanItemMeasurement({ commit }, measurement: IMeasurementWithProperties) {
    const result = await buildPlans.setIbcPlanItemMeasurement(measurement)
    if (!result) {
      return
    }
    commit('setIbcPlanItemMeasurement', measurement)
  },

  async loadPartConfig(
    { commit, rootGetters },
    payload: {
      partName: string
      loadingPartIndex: number
      dragDrop: boolean
      partType: PartTypes
    },
  ) {
    commit('setIsLoading', true)
    await rootGetters['visualizationModule/visualization'].loadPartConfig(payload)
    commit('setIsLoading', false)
  },

  async loadBuildPlan(
    { rootGetters, dispatch, commit, getters },
    payload: {
      buildPlan: IBuildPlan
      options?: LoadBuildPlanOptions
    },
  ) {
    // Clear undo/redo build plan stack and tool stack
    commit('commandManager/disposeBuildPlanStack', null, { root: true })
    commit('commandManager/disposeToolStack', null, { root: true })

    commit('setIsLoading', true)
    await rootGetters['visualizationModule/visualization'].loadBuildPlan(payload.buildPlan, payload.options)
    dispatch('changeVisibilityByDisplayToolbarState')
    commit(
      'visualizationModule/setBoundingBox',
      { boundingBox: rootGetters['visualizationModule/visualization'].getBoundingBoxDetails() },
      { root: true },
    )
    dispatch('refreshBoundingBoxForPartsOnly')
    if (!getters.getBuildPlan) {
      commit('setIsLoading', false)
      return
    }
    const { id: activeBuildPlanId } = getters.getBuildPlan
    if (activeBuildPlanId === payload.buildPlan.id) {
      commit('setIsLoading', false)
    }
  },

  async loadPrintOrder(
    { rootGetters, getters, commit, dispatch },
    payload: { buildPlan: IBuildPlan; printOrderId: string },
  ) {
    commit('setIsLoading', true)
    await rootGetters['visualizationModule/visualization'].loadBuildPlan(payload.buildPlan, {
      printOrderId: payload.printOrderId,
    })
    dispatch('changeVisibilityByDisplayToolbarState')
    commit(
      'visualizationModule/setBoundingBox',
      { boundingBox: rootGetters['visualizationModule/visualization'].getBoundingBoxDetails() },
      { root: true },
    )
    dispatch('refreshBoundingBoxForPartsOnly')
    if (getters.getBuildPlan) {
      commit('setIsLoading', false)
      return
    }

    const { id: activeBuildPlanId } = getters.getBuildPlan
    if (activeBuildPlanId === payload.buildPlan.id) {
      commit('setIsLoading', false)
    }
  },

  async savePartByClick(
    { rootGetters, commit, dispatch },
    payload: {
      partId: string
      partName: string
      partType: PartTypes
      loadingPartIndex: number
      hiddenBodies: string[]
      hasSupportBodies: boolean
      excludeGeometries?: string[]
      parameterSetScaleFactor?: number[]
    },
  ) {
    commit('setIsLoading', true)
    await rootGetters['visualizationModule/visualization'].savePartByClick(payload)
    commit('setIsLoading', false)
    commit(
      'visualizationModule/setBoundingBox',
      { boundingBox: rootGetters['visualizationModule/visualization'].getBoundingBoxDetails() },
      { root: true },
    )
    dispatch('refreshBoundingBoxForPartsOnly')
  },

  showLoadingPart({ rootGetters }, partIndex: number) {
    rootGetters['visualizationModule/visualization'].showLoadingPart(partIndex)
  },

  erasePreviousOverhangContour({ rootGetters }) {
    rootGetters['visualizationModule/visualization'].erasePreviousOverhangContour()
  },

  hideLoadingPart({ rootGetters }, partIndex: number) {
    rootGetters['visualizationModule/visualization'].hideLoadingPart(partIndex)
  },

  async saveLoadingPart(
    { rootGetters, commit, dispatch },
    payload: {
      loadingPartIndex: number
      pointerX: number
      pointerY: number
    },
  ) {
    await rootGetters['visualizationModule/visualization'].saveLoadingPart(
      payload.loadingPartIndex,
      payload.pointerX,
      payload.pointerY,
    )
    commit(
      'visualizationModule/setBoundingBox',
      { boundingBox: rootGetters['visualizationModule/visualization'].getBoundingBoxDetails() },
      { root: true },
    )
    dispatch('refreshBoundingBoxForPartsOnly')
  },

  async setBPItemGeometryProperties({ rootGetters, dispatch }, payload: { partId: string; bpItemId: string }) {
    const geometryProperties =
      await rootGetters['visualizationModule/visualization'].getBPItemGeometryPropertiesFromCache(payload)
    if (geometryProperties) {
      dispatch('updateBuildPlanItem', {
        params: { geometryProperties, updateStateOnly: true, buildPlanItemId: payload.bpItemId },
      })
    }
  },

  setLoadingPartIndex({ rootGetters }, payload: { loadingPartIndex: number; buildPlanItemId: string }) {
    rootGetters['visualizationModule/visualization'].setLoadingPartIndex(
      payload.loadingPartIndex,
      payload.buildPlanItemId,
    )
  },

  setLoadingPartIndices({ rootGetters }, payload: Array<{ loadingPartIndex: number; buildPlanItemId: string }>) {
    rootGetters['visualizationModule/visualization'].setLoadingPartIndices(payload)
  },

  updateConstraints({ rootGetters }, payload: { buildPlanItemId: string; constraints: IConstraints }) {
    rootGetters['visualizationModule/visualization'].updateConstraints(payload.buildPlanItemId, payload.constraints)
  },

  updateParameterSetScale({ rootGetters }, payload: Array<{ buildPlanItemId: string; scaling: number[] }>) {
    rootGetters['visualizationModule/visualization'].updateParameterSetScale(payload)
  },

  updateLoadingPartPosition(
    { rootGetters },
    payload: {
      loadingPartIndex: number
      pointerX: number
      pointerY: number
    },
  ) {
    rootGetters['visualizationModule/visualization'].updateLoadingPartPosition(
      payload.loadingPartIndex,
      payload.pointerX,
      payload.pointerY,
    )
  },

  async disposeLoadingPart({ rootGetters }, payload: number) {
    await rootGetters['visualizationModule/visualization'].disposeLoadingPart(payload)
  },

  transformSelectedParts({ rootGetters }, payload: { delta: ITransformationDelta; type: ToolTypes }) {
    rootGetters['visualizationModule/visualization'].transformSelectedParts(payload.delta, payload.type)
  },

  restoreSelectedParts({ rootGetters }, payload: RestoreSelectedPartsType) {
    rootGetters['visualizationModule/visualization'].restoreSelectedParts(payload)
  },

  deleteSelectedPartsFromScene({ rootGetters, commit, dispatch }, selectedIds: string[]) {
    rootGetters['visualizationModule/visualization'].deleteParts(selectedIds)
    commit(
      'visualizationModule/setBoundingBox',
      { boundingBox: rootGetters['visualizationModule/visualization'].getBoundingBoxDetails() },
      { root: true },
    )
    dispatch('refreshBoundingBoxForPartsOnly')
  },

  createVolumeGrid(
    { rootGetters },
    payload: {
      itemsToInstantiate: [
        { rows: number; cols: number; xSpacing: number; ySpacing: number; buildPlanItemId: number; partId: string },
      ]
      duplicatePartsIndependently: boolean
      duplicateMode: DuplicateMode
    },
  ) {
    rootGetters['visualizationModule/visualization'].createVolumeGrid(
      payload.itemsToInstantiate,
      payload.duplicatePartsIndependently,
      payload.duplicateMode,
    )
  },

  finishInstancingProcess({ rootGetters }, bpItems: IBuildPlanItem) {
    rootGetters['visualizationModule/visualization'].finishInstancingProcess(bpItems)
  },

  cancelDuplicateProcess({ rootGetters }) {
    rootGetters['visualizationModule/visualization'].cancelDuplicateProcess()
  },

  clearUnfinishedInstances({ rootGetters }) {
    rootGetters['visualizationModule/visualization'].clearUnfinishedInstances()
  },

  loadBuildPlanItemsByConfig({ rootGetters }, bpItems: IBuildPlanItem[]) {
    rootGetters['visualizationModule/visualization'].loadBuildPlanItemsByConfig(bpItems)
  },

  refreshLabelInsightsOnScene({ rootGetters }) {
    rootGetters['visualizationModule/visualization'].refreshLabelInsights()
  },

  refreshBoundingBoxForPartsOnly({ rootGetters, commit }) {
    commit(
      'visualizationModule/setBoundingBoxForPartsOnly',
      { boundingBox: rootGetters['visualizationModule/visualization'].getBoundingBoxDetailsForPartsOnly() },
      { root: true },
    )
  },

  async changeSceneReadOnly({ rootGetters, getters }) {
    const isSceneReadOnly = await getters.isSceneReadOnly
    rootGetters['visualizationModule/visualization'].setIsReadOnly(isSceneReadOnly)
  },

  updateGeometryProperties({ rootGetters }, bpItemId: string) {
    rootGetters['visualizationModule/visualization'].updateGeometryProperties(bpItemId)
  },

  elevatePart({ rootGetters }, elevationValue: number) {
    rootGetters['visualizationModule/visualization'].elevatePart(elevationValue)
  },

  async replaceBuildPlanItems(
    { rootGetters, commit },
    payload: Array<{
      partConfig: IPartRenderable
      targetBuildPlanItemId: string
    }>,
  ) {
    commit('setIsLoading', true)
    await rootGetters['visualizationModule/visualization'].replaceBuildPlanItems(payload)
    commit('setIsLoading', false)
  },

  async saveReplacementBuildPlanItems(
    { commit, state, getters, dispatch },
    params: Array<{
      partConfig: {
        partId: string
        loadingPartIndex: number
        transformation: number[]
        isSuccess: boolean
        visibility: boolean
        constraints?: IConstraints
      }
      targetBuildPlanItemId: string
    }>,
  ) {
    const successParams = params.filter((param) => {
      const partConfig = param.partConfig
      if (!partConfig.isSuccess) {
        commit('updateLoadingPart', {
          id: partConfig.loadingPartIndex,
          partId: partConfig.partId,
          isSuccess: false,
        })
        return false
      }

      return true
    })
    if (!successParams.length) {
      return
    }

    const configs = successParams.map((param) => {
      const partConfig = param.partConfig
      const newBpItem: IBuildPlanItem = {} as IBuildPlanItem
      newBpItem.part = { id: partConfig.partId } as IPart
      newBpItem.transformationMatrix = partConfig.transformation
      newBpItem.overhangAngle = getters.getBuildPlanPrintStrategy.productionSet.overhangAngle
      newBpItem.buildPlanId = state.buildPlan.id
      if (partConfig.constraints) {
        newBpItem.constraints = partConfig.constraints
      }

      newBpItem.visibility = partConfig.visibility ? Visibility.Visible : Visibility.Hidden

      if (state.addPartTool.outgoingPartProperties) {
        // Gets part property from outgoing build plan items.
        newBpItem.partProperties = state.addPartTool.outgoingPartProperties[partConfig.loadingPartIndex]
      } else if (state.addPartTool.selectedPartProperties[partConfig.partId].length) {
        // Gets part property from property selected by user.
        newBpItem.partProperties = state.addPartTool.selectedPartProperties[partConfig.partId]
      }

      const targetInsights = getters.insights
        .filter(
          (insight) =>
            insight.details &&
            insight.details.parts &&
            insight.details.parts.some((part) => part.bpItemId === param.targetBuildPlanItemId),
        )
        .map((insight) => insight.id) as string[]

      return {
        replacementBuildPlanItem: newBpItem,
        targetBuildPlanItemId: param.targetBuildPlanItemId,
        targetBuildPlanItemInsightIds: targetInsights,
      }
    })

    const deletionBuildPlanItemIds = configs.map((config) => config.targetBuildPlanItemId)
    const deletionBuildPlanItemInsightIds = configs.flatMap((config) => config.targetBuildPlanItemInsightIds)

    const createdBuildPlanItems = await buildPlanItems.replaceBuildPlanItems(configs)

    await dispatch('deleteInsightMultiple', { insightsIds: deletionBuildPlanItemInsightIds, stateOnly: true })
    await dispatch('deleteSelectedPartsFromScene', deletionBuildPlanItemIds)

    commit('addBuildPlanItems', createdBuildPlanItems)
    commit('updateVariant', createVariantItemFromPlan(state.buildPlan))
    const loadingPartIndices: Array<{ buildPlanItemId: string; loadingPartIndex: number }> = []
    for (let i = 0; i < successParams.length; i += 1) {
      const buildPlanItemId = createdBuildPlanItems[i].id
      loadingPartIndices.push({ buildPlanItemId, loadingPartIndex: successParams[i].partConfig.loadingPartIndex })
    }
    dispatch('setLoadingPartIndices', loadingPartIndices)

    successParams.forEach((param) => {
      commit('updateLoadingPart', {
        id: param.partConfig.loadingPartIndex,
        partId: param.partConfig.partId,
        isSuccess: true,
      })
    })
  },

  async setSelectionMode(
    { rootGetters, commit },
    payload: {
      mode: SelectionUnit
      options: { shouldAffectSelectionBox: boolean }
    },
  ) {
    await rootGetters['visualizationModule/visualization'].setSelectionMode(payload.mode, payload.options)
    commit('setSelectionMode', payload.mode)
  },

  async setVisibleWhenPublishedSinterPlan({ commit }, payload: {
    sinterPlan: IBuildPlan,
    visibilityWhenPublished: boolean,
  }) {
    const { sinterPlan, visibilityWhenPublished } = payload
    const sinterParts = await buildPlans.setVisibleWhenPublishedSinterPlan(sinterPlan.id, visibilityWhenPublished)
    if (sinterParts) {
      commit('setSinterPlanVisibleWhenPublished', visibilityWhenPublished)
      sinterParts.forEach((sinterPart) => {
        commit(
          'parts/updateSinterPartVisibility',
          sinterPart,
          { root: true }
        )
      })
    }
  },

  async setVisibleWhenPublishedIbcPlan({ commit }, payload: {
    ibcPlan: IIBCPlan,
    visibilityWhenPublished: boolean,
  }) {
    const { ibcPlan, visibilityWhenPublished } = payload
    const ibcParts = await buildPlans.setVisibleWhenPublishedIbcPlan(ibcPlan.id, visibilityWhenPublished)
    if (ibcParts) {
      commit('setIbcPlanVisibleWhenPublished', visibilityWhenPublished)
      ibcParts.forEach((ibcPart) => {
        commit(
          'parts/updateIbcPartVisibility',
          ibcPart,
          { root: true }
        )
      })
    }
  }
}

// This is for avoiding network requests if values are already fetched
function shouldFetch(state: IBuildPlansState, key: string): boolean {
  const statePart = state[key]

  if (Array.isArray(statePart)) {
    return !statePart.length
  }

  if (statePart === null) {
    return true
  }

  return false
}

function changeProductionMeshesVisibility(state: IBuildPlansState, commit: Commit, isVisible: boolean) {
  const items = []
  state.buildPlan.buildPlanItems.forEach((bpItem) => {
    const bodyIds = bpItem.partProperties
      .filter((partProperty) => partProperty.type === GeometryType.Production)
      .map((productionBody) => productionBody.geometryId)
    if (bodyIds.length) {
      items.push({ bodyIds, buildPlanItemId: bpItem.id })
    }
  })
  if (!items.length) {
    return
  }

  commit(
    'visualizationModule/setGeometriesVisibility',
    {
      items,
      geometryType: GeometryType.Production,
      visibility: isVisible,
    },
    { root: true },
  )
}

function changeSupportMeshesVisibility(state: IBuildPlansState, commit: Commit, isVisible: boolean) {
  const items = []
  state.buildPlan.buildPlanItems.forEach((bpItem) => {
    const bodyIds = bpItem.partProperties
      .filter((partProperty) => partProperty.type === GeometryType.Support)
      .map((supportBody) => supportBody.geometryId)
    if (bodyIds.length || (bpItem.supports && bpItem.supports.length)) {
      items.push({ bodyIds, buildPlanItemId: bpItem.id })
    }
  })
  if (!items.length) {
    return
  }

  commit(
    'visualizationModule/setGeometriesVisibility',
    {
      items,
      geometryType: GeometryType.Support,
      visibility: isVisible,
    },
    { root: true },
  )
}

function changeCouponMeshesVisibility(state: IBuildPlansState, commit: Commit, isVisible: boolean) {
  const items = []
  state.buildPlan.buildPlanItems.forEach((bpItem) => {
    const bodyIds = bpItem.partProperties
      .filter((partProperty) => partProperty.type === GeometryType.Coupon)
      .map((couponBody) => couponBody.geometryId)
    if (bodyIds.length) {
      items.push({ bodyIds, buildPlanItemId: bpItem.id })
    }
  })
  if (!items.length) {
    return
  }

  commit(
    'visualizationModule/setGeometriesVisibility',
    { items, geometryType: GeometryType.Coupon, visibility: isVisible },
    { root: true },
  )
}

function changeMeshVisibilityByName(state: IBuildPlansState, commit: Commit, name: string, isVisible: boolean) {
  commit('visualizationModule/setMeshesVisibilityByName', { name, visibility: isVisible }, { root: true })
}

function changeBuildPlateMeshVisibility(
  state: IBuildPlansState,
  commit: Commit,
  visibility: boolean,
  isSinterPlan: boolean,
) {
  commit('visualizationModule/setBuildPlateMeshVisibility', { isSinterPlan, visibility }, { root: true })
}

function changeVisualizationMode(state: IBuildPlansState, commit: Commit, mode: VisualizationModeTypes) {
  commit('visualizationModule/changeVisualizationMode', mode, { root: true })
}

function getUpdatePartPropertiesDto(
  buildPlanItem: IBuildPlanItem,
  newDefaultSupportPrintStrategyParameterSet,
): PartProperty {
  return {
    ...buildPlanItem.partProperties[0],
    printStrategyParameterSetId: newDefaultSupportPrintStrategyParameterSet.id,
    printStrategyParameterSetVersion: newDefaultSupportPrintStrategyParameterSet.version,
  }
}

function getPrintStrategyDefaultByGeometryType(type: GeometryType, buildPlanPrintStrategy: BuildPlanPrintStrategyDto) {
  if (!buildPlanPrintStrategy) {
    return
  }

  const { couponId, couponVersion, productionId, productionVersion, supportAmpLineId, supportAmpLineVersion } =
    buildPlanPrintStrategy.defaults

  const newPrintStrategyParameterSets: IPrintStrategyParameterSet[] = buildPlanPrintStrategy.printStrategyParameterSets
  let defaultPrintStretegyParameterSet = null

  switch (type) {
    case GeometryType.Production:
      defaultPrintStretegyParameterSet = newPrintStrategyParameterSets.find(
        (ps) => ps.id === productionId && ps.version === productionVersion,
      )
      break
    case GeometryType.Coupon:
      defaultPrintStretegyParameterSet = newPrintStrategyParameterSets.find(
        (ps) => ps.id === couponId && ps.version === couponVersion,
      )
      break
    case GeometryType.Support:
      defaultPrintStretegyParameterSet = newPrintStrategyParameterSets.find(
        (ps) => ps.id === supportAmpLineId && ps.version === supportAmpLineVersion,
      )
      break
  }

  return defaultPrintStretegyParameterSet
}

function areScaleFactorsEquals(scaleFactors1: IScaleFactors, scaleFactors2: IScaleFactors) {
  return (
    scaleFactors1.ScaleFactorX === scaleFactors2.ScaleFactorX &&
    scaleFactors1.ScaleFactorY === scaleFactors2.ScaleFactorY &&
    scaleFactors1.ScaleFactorZ === scaleFactors2.ScaleFactorZ
  )
}

async function lockBuildPlanVariantRequest(commit: Commit, variantId: string) {
  const updatedItem = await buildPlans.lockBuildPlanVariant(variantId)
  commit('setVariantIsLocked', { variantId, lockWhitelist: updatedItem.lockWhitelist })
}

function handleUpdateBuildPlanItemError(error, commit: Commit) {
  if (error.data.code && error.data.code === PrintStrategyParameterSetErrorCodes.NotAvailable) {
    const { data: printStrategyParameterSetIds } = error.data
    printStrategyParameterSetIds.forEach((id: number) => {
      commit('removePrintStrategyParameterSet', id)
    })
  }
}

export function createVariantItemFromPlan(plan: IBuildPlan | IIBCPlan) {
  return {
    id: plan.id,
    path: plan.path,
    parentId: plan.parentId,
    version: plan.version,
    versionLabel: plan.versionLabel,
    versionDescription: plan.versionDescription,
    createdBy: plan.createdBy,
    createdAt: plan.createdDate,
    jobCount: plan.jobCount,
    name: plan.name,
    isRemoved: plan.isRemoved,
    isLocked: plan.isLocked,
    previewImageUrl: plan.previewImageUrl,
    lockWhitelist: plan.lockWhitelist,
    itemType: plan.itemType,
    subType: plan.subType,
    versionOrder: plan.versionOrder,
    isActiveVersion: plan.isActiveVersion,
  } as IVariantItem
}
