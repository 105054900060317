
import Component from 'vue-class-component'
import Vue from 'vue'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ShareItemModal from '@/components/layout/FileExplorer/ShareItemModal.vue'
import TableRowMixin from '@/components/layout/FileExplorer/Table/mixins/TableRowMixin'
import ItemListRowBadges from '@/components/layout/FileExplorer/Table/ItemListRowBadges.vue'
import ItemContextMenu from '@/components/layout/FileExplorer/Table/ItemContexMenu.vue'
import MenuSection from '@/components/controls/FileExplorer/MenuSection.vue'
import VariantIsLockedModal from '@/components/layout/FileExplorer/VariantIsLockedModal.vue'
import MoveOrCopyModal from '@/components/layout/FileExplorer/MoveOrCopyModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'

import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import StoresNamespaces from '@/store/namespaces'
import messageService from '@/services/messageService'
import { ItemDetailsType } from '@/types/FileExplorer/ItemDetails'
import { eventBus } from '@/services/EventBus'
import { FileExplorerEvent } from '@/types/FileExplorer/FileExplorerEvent'
import Menu from '@/components/controls/Common/Menu.vue'
import RowItemMenu from './RowItemMenu.vue'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: {
    MenuSection,
    ItemListRowBadges,
    ShareItemModal,
    ItemContextMenu,
    VariantIsLockedModal,
    ConfirmModal,
    MoveOrCopyModal,
    Menu,
    RowItemMenu,
  },
})
export default class ItemListRow extends TableRowMixin {
  @fileExplorerStore.Getter isItemFavorite: (itemId: string) => boolean
  @fileExplorerStore.Getter getItemDetails: ItemDetailsType

  @fileExplorerStore.Mutation setIsMoveItems: (val: boolean) => void

  @Prop() item: FileExplorerItem

  $refs!: {
    confirm: InstanceType<typeof ConfirmModal>
    menuIcon: Vue
    rowItemMenu: RowItemMenu
  }

  isShownContextMenu: boolean = false
  isShownActionButtons: boolean = false
  canManageCollaboration: boolean = false
  contextMenuItems = []
  contextMenuItemId: string = null

  beforeMount() {
    eventBus.$on(FileExplorerEvent.Scroll, this.onScroll)
  }

  async getContextMenuItems() {
    await this.fetchPermissions()
    const canBeTrashedResult = await this.isAllowedToBeTrashed(this.getSelectedItems)
    const newContextMenuItems = []

    newContextMenuItems.push(
      {
        title: this.$i18n.t('open'),
        divide: false,
        clickHandler: (item: FileExplorerItem) => {
          this.onItemNameClick()
        },
        testAttribute: 'open-button',
        condition: this.item.grantedPermissions,
        disabled: !this.getSelectedItem,
      },
      {
        title: this.$i18n.t('share'),
        divide: false,
        clickHandler: this.toggleShareItemModal,
        testAttribute: 'share-button',
        condition: this.item.grantedPermissions.itemShare,
      },
      {
        title: this.$i18n.t('moveOrCopy'),
        divide: false,
        clickHandler: this.toggleMoveOrCopyModal,
        testAttribute: 'copy-button',
        condition: this.item.grantedPermissions.itemMove,
      },
      {
        title: this.$i18n.t('rename'),
        divide: this.item.grantedPermissions.itemDelete && !this.isAllowedToEndCollaboration(),
        clickHandler: this.toggleRenameItemModal,
        testAttribute: 'rename-button',
        condition: this.item.grantedPermissions.itemRename,
        disabled: !this.getSelectedItem,
      },
      {
        title: this.$t('endCollaboration'),
        divide: this.item.grantedPermissions.itemDelete && this.isAllowedToEndCollaboration(),
        clickHandler: async () => {
          await this.onRemoveFromCollaborationClick([this.item], this.$refs.confirm)
        },
        testAttribute: 'end-collaboration-button',
        condition: this.isAllowedToEndCollaboration(),
      },
      {
        title: this.$i18n.t('trash'),
        divide: false,
        clickHandler: canBeTrashedResult
          ? this.isCanBeTrashedStatusSuccess
            ? this.toggleMoveToTrashModal
            : this.onMoveToTrashError
          : () => null,
        testAttribute: 'delete-button',
        condition: this.item.grantedPermissions.itemTrash,
      },
    )
    this.contextMenuItems = newContextMenuItems
  }

  @Emit('manageCollaborators')
  onManageCollaborators() {
    return this.item
  }

  @Watch('isSelected')
  menuChangedBySelection(isSelectedNow: boolean) {
    this.isShownActionButtons = isSelectedNow
  }

  @Watch('getItemDetails')
  onItemDetailsChanged(details) {
    const id = this.getDetailsItemId(details)
    if (id === this.item.id) {
      this.getContextMenuItems()
    }
  }

  getDetailsItemId(details) {
    return (
      (details.part && details.part.id) ||
      (details.folder && details.folder.id) ||
      (details.buildPlan && details.buildPlan.id)
    )
  }

  async onMenuItemClicked(item: FileExplorerItem, clickHandler: Function) {
    await this.$emit('menuItemClick', this.item)
    clickHandler(item)
  }

  async fetchPermissions() {
    if (this.item.isShared) {
      try {
        await this.fetchItemById(this.item.id)
        // const collaboration: ItemCollaborationDto = await fileExplorerApi.getItemPermissions(this.item.id)
        // this.canManageCollaboration = collaboration.canManageCollaboration
      } catch (error) {
        messageService.showErrorMessage(error.message || 'Something went wrong')
      }
    }
  }

  showContextMenu(event) {
    this.$refs.rowItemMenu.showContextMenu(event, false)
  }

  onScroll() {
    if (this.isShownContextMenu) {
      this.contextMenuItemId = null
      this.isShownContextMenu = false
      ;(this.$refs.menuIcon.$el as HTMLElement).blur()
    }
  }

  beforeDestroy() {
    eventBus.$off(FileExplorerEvent.Scroll, this.onScroll)
  }

  private isAllowedToEndCollaboration(): boolean {
    const itemDetails = this.getItemDetails

    if (!itemDetails || !this.getUserDetails) {
      return false
    }

    if (itemDetails.collaborators.length > 0) {
      const currentUserId = this.getUserDetails.id

      const collaborator = itemDetails.collaborators.find((c) => c.grantedTo === currentUserId)
      return !!collaborator
    }

    return false
  }
}
