
import Vue from 'vue'
import Component from 'vue-class-component'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'

const notificationsStore = namespace(StoresNamespaces.Notifications)

@Component
export default class NotificationsInfinityScrollArea extends Vue {
  @notificationsStore.Getter isLoading: () => boolean
  @notificationsStore.Getter getCurrentPageIndex: number
  @notificationsStore.Getter getTotalPages: number

  async intersection(entries) {
    if (this.isLoading) {
      return
    }

    if (entries[0].intersectionRatio >= 0.5) {
      this.$emit('intersection')
    }
  }
}
