import { Commit, Dispatch } from 'vuex'

import StoresNamespaces from '@/store/namespaces'
import { IOrientCriteria } from '@/types/BuildPlans/IBuildPlan'
import { IConstraints } from '@/types/BuildPlans/IConstraints'
import { Command } from './Command'
import { CommandType } from './CommandType'
import { ICommand } from './ICommand'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

export type ChangePartOrientationCommandPayload = {
  orientCriteria: IOrientCriteria
  constraints: IConstraints
  partItemIndex: string
  transformation?: number[]
  zTranslation?: number
}

export class ChangePartOrientationCommand extends Command implements ICommand {
  public readonly toolName = ToolNames.ORIENT

  constructor(
    readonly commandType: CommandType,
    private buildPlanItemId: string,
    private readonly prevState: ChangePartOrientationCommandPayload,
    private readonly nextState: ChangePartOrientationCommandPayload,
    protected dispatch: Dispatch,
    protected commit: Commit,
  ) {
    super()
  }

  async undo(): Promise<void> {
    this.execute(this.prevState)
  }

  async redo(): Promise<void> {
    this.execute(this.nextState)
  }

  setBuildPlanItemId(id: string) {
    this.buildPlanItemId = id
    this.prevState.partItemIndex = id
    this.nextState.partItemIndex = id
  }

  getBuildPlanItemId(): string {
    return this.buildPlanItemId
  }

  private async execute(payload: ChangePartOrientationCommandPayload) {
    this.deselectItems()
    await this.updateBuildPlanItem(payload)

    if (this.isOrientCriteriaValid(payload)) {
      this.rotatePart(payload)
    } else {
      if (!this.isOrientCriteriaValid(payload)) {
        this.commit(`${StoresNamespaces.Visualization}/applyTransformationMatrix`, {
          buildPlanItemId: this.buildPlanItemId,
          transformation: payload.transformation,
        })
      }
    }

    this.savePartOrientation()
    this.selectPartByBuildPlanItemId(this.buildPlanItemId)
  }

  private isOrientCriteriaValid(payload: ChangePartOrientationCommandPayload): boolean {
    const { orientations, appliedOrientationIndex } = payload.orientCriteria
    return orientations[appliedOrientationIndex] !== undefined
  }

  private rotatePart(payload: ChangePartOrientationCommandPayload) {
    const { transformation, zTranslation } = payload

    if (!transformation || !zTranslation) return

    const { orientations, appliedOrientationIndex } = payload.orientCriteria
    const { x, y, z } = orientations[appliedOrientationIndex]

    this.commit(`${StoresNamespaces.Visualization}/rotatePart`, {
      x,
      y,
      z,
      transformation,
      zTranslation,
      partItemIndex: this.buildPlanItemId,
    })
  }

  private async updateBuildPlanItem(payload: ChangePartOrientationCommandPayload) {
    this.dispatch(`${StoresNamespaces.BuildPlans}/updateBuildPlanItem`, {
      params: {
        ...payload,
        buildPlanItemId: this.buildPlanItemId,
        updateStateOnly: true,
      },
    })
  }

  private savePartOrientation() {
    this.commit(`${StoresNamespaces.Visualization}/savePartOrientation`, {
      partItemIndex: this.buildPlanItemId,
    })
  }
}
