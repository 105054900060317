import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { RouterNames } from '@/router'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemDetailsDto } from '@/types/FileExplorer/ItemDetails'
import { ItemType } from '@/types/FileExplorer/ItemType'
import StoresNamespaces from '@/store/namespaces'
import { ItemDetailsPayload } from '@/store/modules/fileExplorer/types'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component
export default class FileExplorerRedirectionMixin extends Vue {
  @fileExplorerStore.Getter getLastAddedItem: FileExplorerItem
  @fileExplorerStore.Getter getSelectedItem: FileExplorerItem
  @fileExplorerStore.Getter getSelectedItems: FileExplorerItem[]

  @fileExplorerStore.Mutation setLastAddedItem: (item: FileExplorerItem) => void

  @fileExplorerStore.Action fetchItemDetails: (payload: ItemDetailsPayload) => Promise<ItemDetailsDto>

  @Watch('getLastAddedItem')
  onNewItem() {
    if (!this.getLastAddedItem || this.activePageName === RouterNames.FE_AllFiles) {
      return
    }

    switch (this.getLastAddedItem.itemType) {
      case ItemType.BuildPart:
      case ItemType.Folder:
        this.redirectToAllFiles()
        break
      case ItemType.BuildPlan:
        this.openBuildPlanForEdit(this.getLastAddedItem.id)
        break
    }
  }

  redirectToAllFiles() {
    if (this.activePageName === RouterNames.FE_AllFiles) return
    // @ts-ignore
    this.$router.safePush({ name: RouterNames.FE_AllFiles })
  }

  get activePageName() {
    return this.$route.name
  }

  openBuildPlanForEdit(buildPlanId: string) {
    this.setLastAddedItem(null)

    const route = {
      name: RouterNames.EBP_AddPart,
      params: { id: buildPlanId },
    }

    // @ts-ignore
    this.$router.safePush(route)
  }
}
