
import { Vue, Prop, Emit, Component } from 'vue-property-decorator'

import IconBase from './IconBase.vue'

import IconSortAlphaDownShort from './IconSortAlphaDownShort.vue'
import IconSortAlphaUpShort from './IconSortAlphaUpShort.vue'
import IconSortAlphaDown from './IconSortAlphaDown.vue'
import IconSortAlphaUp from './IconSortAlphaUp.vue'
import IconSortNumericDownShort from './IconSortNumericDownShort.vue'
import IconSortNumericUpShort from './IconSortNumericUpShort.vue'
import IconSortNumericDown from './IconSortNumericDown.vue'
import IconSortNumericUp from './IconSortNumericUp.vue'
import IconArrow from './IconArrow.vue'
import IconSearch from './IconSearch.vue'
import IconEdit from './IconEdit.vue'
import IconCopy from './IconCopy.vue'
import IconDelete from './IconDelete.vue'
import IconPerson from './IconPerson.vue'
import IconCalendar from './IconCalendar.vue'
import IconTopView from './IconTopView.vue'
import IconBottomView from './IconBottomView.vue'
import IconFrontView from './IconFrontView.vue'
import IconBackView from './IconBackView.vue'
import IconRightView from './IconRightView.vue'
import IconLeftView from './IconLeftView.vue'
import IconIsometricView from './IconIsometricView.vue'
import IconAllSteps from './IconAllSteps.vue'
import IconCurrentStep from './IconCurrentStep.vue'
import IconBuildArea from './IconBuildArea.vue'
import IconPartDimensions from './IconPartDimensions.vue'
import IconBuildHeight from './IconBuildHeight.vue'
import IconCompensatedScaled from './IconCompensatedScaled.vue'
import IconInspectionBasedCompensation from './IconInspectionBasedCompensation.vue'
import IconSimulationBasedCompensation from './IconSimulationBasedCompensation.vue'
import IconSimulate from './IconSimulate.vue'
import IconWalls from './IconWalls.vue'
import IconPrintLanes from './IconPrintLanes.vue'
import IconTotalVolume from './IconTotalVolume.vue'
import IconResetAxis from './IconResetAxis.vue'
import { toCamelCase } from '@/utils/string'

export enum IconNames {
  SortAlphaDownShort = 'sort-alpha-down-short',
  SortAlphaUpShort = 'sort-alpha-up-short',
  SortAlphaDown = 'sort-alpha-down',
  SortAlphaUp = 'sort-alpha-up',
  SortNumericDownShort = 'sort-numeric-down-short',
  SortNumericUpShort = 'sort-numeric-up-short',
  SortNumericDown = 'sort-numeric-down',
  SortNumericUp = 'sort-numeric-up',
  Arrow = 'arrow',
  Search = 'search',
  Edit = 'edit',
  Copy = 'copy',
  Delete = 'delete',
  Person = 'person',
  Calendar = 'calendar',
  FrontView = 'front-view',
  BackView = 'back-view',
  LeftView = 'left-view',
  RightView = 'right-view',
  TopView = 'top-view',
  BottomView = 'bottom-view',
  IsometricView = 'isometric-view',
  BuildArea = 'build-area',
  PartDimensions = 'part-dimensions',
  BuildHeight = 'build-height',
  CompensatedScaled = 'compensated-scaled',
  InspectionBasedCompensation = 'inspection-based-compensation',
  SimulationBasedCompensation = 'simulation-based-compensation',
  Simulate = 'simulate',
  Walls = 'walls',
  PrintLanes = 'print-lanes',
  TotalVolume = 'total-volume',
  ResetAxis = 'reset-axis',
}

export function getIconName(view: string) {
  let iconName
  switch (view) {
    case 'part-dimensions':
      iconName = IconNames.PartDimensions
      break
    case 'build-height':
      iconName = IconNames.BuildHeight
      break
    case 'build-area':
      iconName = IconNames.BuildArea
      break
    case 'total-volume':
      iconName = IconNames.TotalVolume
      break
    case 'reset-axis':
      iconName = IconNames.ResetAxis
      break
    case 'compensated-scaled':
      iconName = IconNames.CompensatedScaled
      break
  }
  return iconName
}

@Component({
  components: {
    IconBase,
    IconSortAlphaDownShort,
    IconSortAlphaUpShort,
    IconSortAlphaDown,
    IconSortAlphaUp,
    IconSortNumericDownShort,
    IconSortNumericUpShort,
    IconSortNumericDown,
    IconSortNumericUp,
    IconArrow,
    IconSearch,
    IconEdit,
    IconCopy,
    IconDelete,
    IconPerson,
    IconCalendar,
    IconTopView,
    IconBottomView,
    IconFrontView,
    IconBackView,
    IconRightView,
    IconLeftView,
    IconIsometricView,
    IconAllSteps,
    IconCurrentStep,
    IconBuildArea,
    IconPartDimensions,
    IconBuildHeight,
    IconCompensatedScaled,
    IconInspectionBasedCompensation,
    IconSimulationBasedCompensation,
    IconSimulate,
    IconWalls,
    IconPrintLanes,
    IconTotalVolume,
    IconResetAxis,
  },
})
export default class Icon extends Vue {
  @Prop({ type: String, default: 'edit' })
  readonly name: IconNames

  @Prop({ type: Number, default: 16 })
  readonly size: number

  @Prop({ type: String, default: '0 0 16 16' })
  readonly viewbox: string

  get computeIcon(): string {
    return toCamelCase(`icon-${this.name}`)
  }
}
