import { render, staticRenderFns } from "./BuildPlanTransferPropsTool.vue?vue&type=template&id=702dc204&scoped=true"
import script from "./BuildPlanTransferPropsTool.vue?vue&type=script&lang=ts"
export * from "./BuildPlanTransferPropsTool.vue?vue&type=script&lang=ts"
import style0 from "./BuildPlanTransferPropsTool.vue?vue&type=style&index=0&id=702dc204&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "702dc204",
  null
  
)

export default component.exports