
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/components/controls/Common/Button.vue'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import StoresNamespaces from '@/store/namespaces'
import { TranslateResult } from 'vue-i18n'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: {
    Button,
  },
})
export default class UnshareModal extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: () => [] }) items: FileExplorerItem[]
  @Prop({ default: false }) loading: boolean

  get label(): TranslateResult {
    let itemName = ''
    const isSingle = this.items.length === 1
    if (isSingle) {
      itemName = this.items[0].name
    } else {
      itemName = `${this.items.length} ${this.$i18n.t('unshareDialog.selectedItems')}`
    }
    return this.$i18n.t('unshareDialog.label', { itemName })
  }

  onCancelClick() {
    this.$emit('close')
  }

  onConfirmClick() {
    this.$emit('confirm', this.items)
  }
}
