
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import LabelSidebarMixin from '../mixins/LabelSidebarMixin'
import PlacementRules from '@/components/layout/buildPlans/marking/PlacementRules.vue'
import LabelToolSelect from '@/components/controls/LabelToolControls/LabelToolSelect.vue'
import NumberField from '@/components/controls/Common/NumberField.vue'
import LabelTextField from '@/components/layout/buildPlans/marking/LabelTextField.vue'
import BuildPlanStickyComponent from '@/components/layout/buildPlans/stickyToPart/BuildPlanStickyComponent.vue'
import {
  BooleanType,
  LabelDirtyState,
  LabelSetMode,
  MarkingLocation,
  SupportedFontStyles,
  TextAlign,
  TextAlignVertical,
} from '@/types/Label/enums'
import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { PrintingTypes } from '@/types/IMachineConfig'
import { ManualPatch } from '@/types/Label/Patch'
import { extend, ValidationObserver } from 'vee-validate'
import { Mixins, Watch } from 'vue-property-decorator'
import { LabelServiceMixin } from './mixins/LabelServiceMixin'
import { InputOutsideMixin } from '../../mixins/InputOutsideMixin'
import { LabeledBody } from '@/types/Label/LabeledBody'
import { InteractiveServiceEvents } from '@/types/InteractiveService/InteractiveServiceEvents'
import { eventBus } from '@/services/EventBus'
import { LabeledBodyWIthTransformation } from '@/types/Label/LabeledBodyWIthTransformation'
import { Placement } from '@/types/Label/Placement'
import {
  AutomatedTrackableLabel,
  createAutomatedTrackableLabel,
  ManualTrackableLabel,
  TrackableLabel,
} from '@/types/Label/TrackableLabel'
import { InsightErrorCodes } from '@/types/BuildPlans/IBuildPlanInsight'
import LabelFontStyleBalloonHelp from '@/components/layout/buildPlans/marking/LabelFontStyleBalloonHelp.vue'
import { FontNames, FontStyleItem, FontVisibleNames } from '@/types/Label/FontStyleHelpBalloonInfo'
import { BuildPlanEvents } from '@/types/Label/BuildPlanEvents'

const labelStore = namespace(StoresNamespaces.Labels)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

const EXTRUSION_UNITS = 'mm'
const ADJUSTMENT_FACTOR = 0.001

interface IMixinInterface extends LabelSidebarMixin, LabelServiceMixin, InputOutsideMixin {}

@Component({
  components: {
    PlacementRules,
    LabelToolSelect,
    NumberField,
    LabelTextField,
    BuildPlanStickyComponent,
    LabelFontStyleBalloonHelp,
  },
})
export default class LabelSettings extends Mixins<IMixinInterface>(
  LabelSidebarMixin,
  LabelServiceMixin,
  InputOutsideMixin,
) {
  @buildPlansStore.Getter isBuildPlanContainsCouponBody: boolean
  @buildPlansStore.Getter getAllBuildPlanItems: IBuildPlanItem[]

  @visualizationStore.Getter isLabelManualPlacement: boolean
  @visualizationStore.Getter hoveredLabel: string
  @visualizationStore.Getter isDisplayManualLabelSettings: boolean
  @visualizationStore.Getter manualLabelSettingsLocation: { x: number; y: number }

  @labelStore.Getter getRelatedBodiesFromActiveLabelSet: LabeledBody[]
  @labelStore.Getter isLabelSetHasLabelWithCommandId: (labelSetId: string) => boolean
  @labelStore.Getter isLabelAllInstancesEnabled: boolean
  @labelStore.Getter isApplyRotationToInstancesEnabled: boolean

  @labelStore.Mutation setSelectedBodies: (bodies: LabeledBodyWIthTransformation[]) => void
  @labelStore.Mutation setActiveSetLastSuccessfulManualPlacements: (manualPlacements: Placement[]) => void
  @labelStore.Mutation setSettingsAreValid: (value: boolean) => void
  @labelStore.Mutation setHasLabeledInstances: (value: boolean) => void

  @labelStore.Mutation setActiveLabelSetMode: (mode: LabelSetMode) => void

  @labelStore.Action applyRotationToAllOtherLabels: (payload: {
    sourceLabel: ManualPatch
    targetLabels: ManualPatch[]
  }) => void
  @labelStore.Action addManualPlacements: (payload: { labelSetId: string; patches: ManualPatch[] }) => void
  @labelStore.Action scheduleExecuteCommandAbortable: (shouldAbort: boolean) => void
  @labelStore.Action scheduleExecuteCommand: () => void
  @labelStore.Action setRelatedBodies: (payload: { bodies: LabeledBodyWIthTransformation[]; add?: boolean }) => void
  @labelStore.Action removeManuallyPlacedLabel: (payload: { labelSetId: string; labelId: string }) => void
  @labelStore.Action launchLabelAllInstances: () => void
  @labelStore.Action changeBooleanType: (booleanType: BooleanType) => void
  @labelStore.Action clearLabelInsightsByErrorCodes: (payload: {
    labelSetId: string
    errorCodes: InsightErrorCodes[]
    labelId?: string
  }) => void
  @labelStore.Action onDisplayManualLabelSettings: (payload: {
    isVisible: boolean
    settingsLocation: { x: number; y: number }
    disableLabelAllInstances?: boolean
    disableApplyRotationToInstances?: boolean
  }) => void

  @labelStore.Mutation removeManualPlacementsForLabelSet: (payload: { labelSetId: string; labelId?: string }) => void
  @labelStore.Mutation('setActiveLabelSetSettingsProp') setActiveLabelSetSettingsPropMutation: (payload: {
    propName: string
    value: any
  }) => void
  @visualizationStore.Mutation activateLabelManualPlacement: () => void
  @visualizationStore.Mutation displayManualLabelSettings: (isDisplay: boolean) => void
  @visualizationStore.Mutation hoverManualLabelSettings: () => void

  labelSetModes = LabelSetMode
  textHorizontalAlignment = TextAlign
  textVerticalAlignment = TextAlignVertical
  fonts: FontStyleItem[] = [
    {
      visibleName: FontVisibleNames.LinotteSemiBold,
      src: require('@/assets/images/labelFonts/linotte_semibold.svg'),
      fontName: FontNames.Linotte,
      fontStyle: SupportedFontStyles.Semi_Bold,
    },
    {
      visibleName: FontVisibleNames.NotoSansSemiBold,
      src: require('@/assets/images/labelFonts/noto_sans_semi_bold.svg'),
      fontName: FontNames.NotoSans,
      fontStyle: SupportedFontStyles.Semi_Bold,
    },
    {
      visibleName: FontVisibleNames.NotoSansBold,
      src: require('@/assets/images/labelFonts/noto_sans_bold.svg'),
      fontName: FontNames.NotoSans,
      fontStyle: SupportedFontStyles.Bold,
    },
    {
      visibleName: FontVisibleNames.NotoSansExtraBold,
      src: require('@/assets/images/labelFonts/noto_sans_extra_bold.svg'),
      fontName: FontNames.NotoSans,
      fontStyle: SupportedFontStyles.Extra_Bold,
    },
    {
      visibleName: FontVisibleNames.NotoSansBlack,
      src: require('@/assets/images/labelFonts/noto_sans_black.svg'),
      fontName: FontNames.NotoSans,
      fontStyle: SupportedFontStyles.Black,
    },
    {
      visibleName: FontVisibleNames.VarelaRoundRegular,
      src: require('@/assets/images/labelFonts/varela_round_regular.svg'),
      fontName: FontNames.VarelaRound,
      fontStyle: SupportedFontStyles.Regular,
    },
  ]

  lineSpacingVariants: number[] = [1, 1.25, 1.5, 1.75, 2]
  booleanType = BooleanType

  fontMinSize: number = 1.5
  fontMaxSize: number = 50
  fontSizeStep: number = 1
  labelsPerBodyViewsMin: number = 0
  labelsPerBodyViewsMax: number = 1
  labelsPerBodyBarMin: number = 0
  labelsPerBodyBarMax: number = 2
  cachedAutoPlacement = []
  cachedBarPlacements = []
  cachedMinMaxLabelsPerBody = []
  cachedMinMaxBarLabels = []
  cachedPlanarOnlyValue = false
  digitsAfterDecimal: number = 3
  attachmentDepthStep: number = 0.1
  extrusionMaxValue: number = 2.5
  extrusionMinValue: number = 0.25
  attachmentDepthMinValue: number = 0.01
  attachmentDepthMaxValue: number = 1
  defaultMarin: number = 0.78
  canvasOffset = { x: 0, y: 0 }
  activePlacementRuleCount = 0
  labelsPerCouponSliderStep = 1
  adjustmentFactor = ADJUSTMENT_FACTOR
  attachmentDepthIsFocused: boolean = false
  embossedHeightIsFocused: boolean = false
  recessedDepthIsFocused: boolean = false

  $refs!: {
    labelSetSettings: InstanceType<typeof ValidationObserver>
  }

  get isLabelsPerCouponSliderLocked(): boolean {
    return this.labelsPerCouponSliderStep === 0
  }

  beforeMount() {
    this.extendValidationRules()
  }

  get fontValue() {
    return this.fonts.find((item: FontStyleItem) => {
      return (
        item.fontName === this.activeLabelSet.settings.fontName &&
        item.fontStyle === this.activeLabelSet.settings.fontStyle
      )
    })
  }

  async mounted() {
    if (!this.isBuildPlanContainsCouponBody && !this.isActiveSetManuallyPlaced()) {
      await this.activateTab(LabelSetMode.Manual)
    }

    if (!this.isLabelReadOnly) {
      if (this.isLabelManualPlacement && this.activeTab !== LabelSetMode.Manual) {
        this.deactivateLabelManualPlacement()
      }

      if (!this.isLabelManualPlacement && this.activeTab === LabelSetMode.Manual) {
        this.activateLabelManualPlacement()
      }
    }

    this.cacheAutoPlacement()
    this.cacheBarPlacements()
    this.cacheLabelsPerBody()
    await this.$nextTick()
    this.setSettingsAreValid(await this.$refs.labelSetSettings.validate())
    this.validateTool()
    this.setIsLabelExecuteTriggered(false)
    this.activePlacementRuleCount = this.activeLabelSet.settings.placementAutoLocations.length
    this.subscribeToEventBus()
  }

  beforeDestroy() {
    this.unsubscribeFromEventBus()
  }

  subscribeToEventBus() {
    eventBus.$on(InteractiveServiceEvents.SelectRelatedBodies, this.handleSelectRelatedBodiesEvent)
    eventBus.$on(BuildPlanEvents.DisplayManualLabelSettings, this.onDisplayManualLabelSettings)
  }

  unsubscribeFromEventBus() {
    eventBus.$off(InteractiveServiceEvents.SelectRelatedBodies, this.handleSelectRelatedBodiesEvent)
    eventBus.$off(BuildPlanEvents.DisplayManualLabelSettings, this.onDisplayManualLabelSettings)
  }

  /** Method checks whether if an active label set is a manual placement type. */
  isActiveSetManuallyPlaced() {
    return this.activeLabelSet.mode === LabelSetMode.Manual
  }

  /** Method adds additional validation rules to the inputs enhancing the default behavior. */
  extendValidationRules() {
    extend('in_range_included', {
      validate: (value: number, { leftLimit, rightLimit }: { leftLimit: string; rightLimit: string }) => {
        return value >= +leftLimit && value <= +rightLimit
      },
      params: ['leftLimit', 'rightLimit'],
      message: this.$i18n.t('labelTool.enterValue').toString(),
    })
  }

  /** Getter returns label placement type. */
  get activeTab() {
    return this.activeLabelSet.mode
  }

  /** Checks if embossed height input should be shown. */
  get showEmbossedHeight() {
    return (this.isBJ && this.activeLabelSet.settings.textBoolean === this.booleanType.Add) || this.isDMLM
  }

  /** Checks if recessed depth input should be shown. */
  get showRecessedDepth() {
    return this.isBJ && this.activeLabelSet.settings.textBoolean === this.booleanType.Subtract
  }

  /** Checks if attachment depth input should be shown. */
  get showAttachmentDepth() {
    return this.isDMLM
  }

  /** Returns selected parts. */
  get bodiesSelected() {
    return this.getSelectedParts.length
  }

  /** Checks if current build plan is a Binder Jet build plan. */
  get isBJ() {
    return this.buildPlan.modality === PrintingTypes.BinderJet
  }

  @Watch('manualLabelSettingsLocation')
  onFlipArrowLocationChanged() {
    this.canvasOffset = this.manualLabelSettingsLocation
  }

  @Watch('isDisplayManualLabelSettings')
  onDisplayManualLabelSettingsChange() {
    this.setListenerForInputOutside(this.isDisplayManualLabelSettings, 'manual-label-menu', () =>
      this.displayManualLabelSettings(false),
    )
    if (!this.isDisplayManualLabelSettings) {
      this.hideManualLabelHandle()
    }
  }

  @Watch('activeLabelSetSettings', { deep: true })
  async onActiveLabelSetSettingsChanged() {
    await this.$nextTick()
    this.setSettingsAreValid(await this.$refs.labelSetSettings.validate())
  }

  /** Caches automatic placement of a label set when user switches from views mode to another one. */
  cacheAutoPlacement() {
    const locations = this.activeLabelSet.settings.placementAutoLocations
    if (this.activeLabelSet.mode === LabelSetMode.Views) {
      this.cachedAutoPlacement = [...locations]
    }
  }

  /** Caches bar placement of a label set when user switches from views mode to another one. */
  cacheBarPlacements() {
    const locations = this.activeLabelSet.settings.placementAutoLocations
    if (this.activeLabelSet.mode === LabelSetMode.Bars) {
      this.cachedBarPlacements = [...locations]
    }
  }

  /** Caches labels per body of a label set when user switches from one mode to another one. */
  cacheLabelsPerBody() {
    this.cachedMinMaxLabelsPerBody = [this.labelsPerBodyViewsMin, this.labelsPerBodyViewsMax]
    this.cachedMinMaxBarLabels = [this.labelsPerBodyBarMin, this.labelsPerBodyBarMax]
  }

  /** Activates new tab and reassigns variables related to new tab.
   * @param {LabelSetMode} toActivate - tab to be activated
   * @param {boolean} disabled - is target tab disabled
   */
  async activateTab(toActivate: LabelSetMode, disabled: boolean = false) {
    if (this.isLabelReadOnly || disabled) {
      return
    }

    if (
      this.activeTab === LabelSetMode.Bars &&
      (toActivate === LabelSetMode.Views || toActivate === LabelSetMode.Manual)
    ) {
      this.deactivateLabelBarPlacement()
    }

    if (
      this.activeTab === LabelSetMode.Manual &&
      (toActivate === LabelSetMode.Views || toActivate === LabelSetMode.Bars)
    ) {
      this.deselect()
      this.setActiveLabelSetSettingsProp({ propName: 'hasLabeledInstances', value: false })
      this.deactivateLabelManualPlacement({ isSwitchedToAutomatic: true })
      this.clearLabelInsightsByErrorCodes({
        labelSetId: this.activeLabelSet.id,
        errorCodes: [InsightErrorCodes.LabelToolNotCreated],
      })
      await this.addLabelSetInsights(this.getCachedInsights, [])
      this.setSelectedBodies([])
    }

    if (toActivate === LabelSetMode.Bars && this.activeTab !== LabelSetMode.Bars) {
      if (!this.isLabelReadOnly) {
        this.deselectNonBarGeometry()
        this.activateLabelBarPlacement()
      }
    }

    const activeTabCached = this.activeTab
    if (toActivate === LabelSetMode.Manual && this.activeTab !== LabelSetMode.Manual) {
      if (!this.isLabelReadOnly) {
        this.activateLabelManualPlacement()
        this.setRelatedBodies({ bodies: [] })
      }

      if (this.activeTab === LabelSetMode.Views || this.activeTab === LabelSetMode.Bars) {
        if (this.activeLabelSet.selectedBodies.length) {
          this.deselect()
        }

        this.setActiveLabelSetSettingsProp({ propName: 'hasLabeledInstances', value: false })
      }
    }

    const bodies = [...this.activeLabelSet.selectedBodies, ...this.activeLabelSet.relatedBodies]

    if (this.activeTab === LabelSetMode.Views) {
      this.cachedAutoPlacement = [...this.activeLabelSet.settings.placementAutoLocations]
      this.cachedMinMaxLabelsPerBody = [
        this.activeLabelSet.settings.placementMinCount,
        this.activeLabelSet.settings.placementMaxCount,
      ]
      this.cachedPlanarOnlyValue = this.activeLabelSet.settings.placementPlanarOnly
    }

    if (this.activeTab === LabelSetMode.Bars) {
      this.cachedBarPlacements = [...this.activeLabelSet.settings.placementAutoLocations]
      this.cachedMinMaxBarLabels = [
        this.activeLabelSet.settings.placementMinCount,
        this.activeLabelSet.settings.placementMaxCount,
      ]
      this.cachedPlanarOnlyValue = this.activeLabelSet.settings.placementPlanarOnly
    }

    switch (toActivate) {
      case LabelSetMode.Views:
        const cachedAutoPlacements =
          this.cachedAutoPlacement && this.cachedAutoPlacement.length ? this.cachedAutoPlacement : [MarkingLocation.Top]
        this.setActiveLabelSetMode(toActivate)
        this.setActiveLabelSetSettingsProp({ propName: 'placementAutoLocations', value: cachedAutoPlacements })
        this.setActiveLabelSetSettingsProp({ propName: 'placementMinCount', value: this.cachedMinMaxLabelsPerBody[0] })
        this.setActiveLabelSetSettingsProp({ propName: 'placementMaxCount', value: this.cachedMinMaxLabelsPerBody[1] })
        this.setActiveLabelSetSettingsProp({ propName: 'placementMethodAutomatic', value: true })
        this.setActiveLabelSetSettingsProp({ propName: 'textHorizontalAlignment', value: TextAlign.Center })
        this.setActiveLabelSetSettingsProp({ propName: 'textVerticalAlignment', value: TextAlignVertical.Center })
        this.setActiveLabelSetSettingsProp({ propName: 'textMarginSize', value: this.defaultMarin })
        this.setActiveLabelSetSettingsProp({ propName: 'placementPlanarOnly', value: this.cachedPlanarOnlyValue })
        this.removeManualPlacementsForLabelSet({ labelSetId: this.activeLabelSet.id })
        const viewsToAdd = []
        bodies.forEach((body) => {
          cachedAutoPlacements.forEach((placement) => {
            viewsToAdd.push(createAutomatedTrackableLabel(body.id, placement, LabelDirtyState.Add))
          })
        })
        this.addTrackableLabels(viewsToAdd)

        break
      case LabelSetMode.Bars:
        // Looks like we have to cache this value too
        // Need to be discussed
        const cachedBarPlacements =
          this.cachedBarPlacements && this.cachedBarPlacements.length
            ? this.cachedBarPlacements
            : [MarkingLocation.FarBarEnd, MarkingLocation.NearBarEnd]
        this.setActiveLabelSetMode(toActivate)
        this.setActiveLabelSetSettingsProp({
          propName: 'placementAutoLocations',
          value: cachedBarPlacements,
        })
        this.setActiveLabelSetSettingsProp({ propName: 'placementMinCount', value: this.cachedMinMaxBarLabels[0] })
        this.setActiveLabelSetSettingsProp({ propName: 'placementMaxCount', value: this.cachedMinMaxBarLabels[1] })
        this.setActiveLabelSetSettingsProp({ propName: 'placementMethodAutomatic', value: true })
        this.setActiveLabelSetSettingsProp({ propName: 'textHorizontalAlignment', value: TextAlign.Center })
        this.setActiveLabelSetSettingsProp({ propName: 'textVerticalAlignment', value: TextAlignVertical.Center })
        this.setActiveLabelSetSettingsProp({ propName: 'textMarginSize', value: this.defaultMarin })
        this.setActiveLabelSetSettingsProp({ propName: 'placementPlanarOnly', value: this.cachedPlanarOnlyValue })
        this.removeManualPlacementsForLabelSet({ labelSetId: this.activeLabelSet.id })
        const barsToAdd = []
        bodies.forEach((body) => {
          cachedBarPlacements.forEach((placement) => {
            barsToAdd.push(createAutomatedTrackableLabel(body.id, placement, LabelDirtyState.Add))
          })
        })
        this.addTrackableLabels(barsToAdd)
        break
      case LabelSetMode.Manual:
        this.setActiveLabelSetMode(toActivate)
        this.setActiveLabelSetSettingsProp({
          propName: 'placementAutoLocations',
          value: [MarkingLocation.NearestToPoint],
        })
        this.setActiveLabelSetSettingsProp({ propName: 'placementMethodAutomatic', value: false })
        this.setActiveLabelSetSettingsProp({ propName: 'placementMinCount', value: 0 })
        this.setActiveLabelSetSettingsProp({ propName: 'placementMaxCount', value: 100 })
        this.setActiveLabelSetSettingsProp({ propName: 'textHorizontalAlignment', value: TextAlign.Center })
        this.setActiveLabelSetSettingsProp({ propName: 'textVerticalAlignment', value: TextAlignVertical.Center })
        this.setActiveLabelSetSettingsProp({ propName: 'textMarginSize', value: this.defaultMarin })
        this.setActiveLabelSetSettingsProp({ propName: 'placementPlanarOnly', value: false })
        break
    }

    if (toActivate !== activeTabCached) {
      const labelSetName = this.getLabelSetName(this.activeLabelSet.id)
      this.setActiveLabelSetSettingsProp({ propName: 'labelSetName', value: labelSetName })
    }
  }

  /** Activates horizontal alignment for a label set.
   * @param {TextAlign} alignment - new alignment
   */
  activateHorizontalAlignment(alignment: TextAlign) {
    this.setActiveLabelSetSettingsProp({ propName: 'textHorizontalAlignment', value: alignment })
  }

  /** Activates vertical alignment for a label set.
   * @param {TextAlignVertical} alignment - new alignment
   */
  activateVerticalAlignment(alignment: TextAlignVertical) {
    this.setActiveLabelSetSettingsProp({ propName: 'textVerticalAlignment', value: alignment })
  }

  /** Switches flexible font size value for a label set.
   * @param {boolean} value - flexible font size state
   */
  switchFlexibleFontSize(value: boolean) {
    this.setActiveLabelSetSettingsProp({ propName: 'allowFontSizeVariation', value: !!value })
    if (this.activeLabelSet.settings.fontTargetSize <= this.activeLabelSet.settings.fontMinSize && value) {
      this.changeMinFontSize(this.activeLabelSet.settings.fontTargetSize)
      this.changeFontSize(this.activeLabelSet.settings.fontMinSize + 1)
    }
  }

  /** Changes minimal font size value for a label set.
   * @param {number} value - font size value
   */
  changeMinFontSize(value: number) {
    if (value !== null && value !== undefined && this.activeLabelSet.settings.fontTargetSize <= value) {
      this.setActiveLabelSetSettingsProp({ value: value + 1, propName: 'fontTargetSize' })
    }

    this.setActiveLabelSetSettingsProp({ value, propName: 'fontMinSize' })
  }

  /** Changes font size value for a label set.
   * @param {number} value - font size value
   */
  changeFontSize(value: number) {
    if (value !== null && value !== undefined && value <= this.activeLabelSet.settings.fontMinSize) {
      this.setActiveLabelSetSettingsProp({ value: value - 1, propName: 'fontMinSize' })
    }

    this.setActiveLabelSetSettingsProp({ value, propName: 'fontTargetSize' })
  }

  /** Changes font name value for a label set.
   * @param {string} value - font name value
   */
  changeFontName(value: FontVisibleNames) {
    const { fontName, fontStyle } = this.fonts.find((font: FontStyleItem) => font.visibleName === value)
    this.setActiveLabelSetSettingsProp({ value: fontName, propName: 'fontName' })
    this.setActiveLabelSetSettingsProp({ value: fontStyle, propName: 'fontStyle' })
  }

  /** Changes font spacing value for a label set.
   * @param {number} value - font spacing value
   */
  changeFontSpacing(value: number) {
    this.setActiveLabelSetSettingsProp({ value, propName: 'fontSpacing' })
  }

  /** Changes margin value for a label set.
   * @param {number} value - margin value
   */
  changeMargin(value: number) {
    this.setActiveLabelSetSettingsProp({ value, propName: 'textMarginSize' })
  }

  /** Changes rotation value for a label set.
   * @param {number} value - rotation value
   */
  changeRotation(value: number) {
    this.setActiveLabelSetSettingsProp({ value, propName: 'placementAngleOffset' })
  }

  /** Changes extrusion above surface value for a label set.
   * @param {number} value - extrusion above surface value
   */
  onExtrusionAboveChange(value: number) {
    this.setActiveLabelSetSettingsProp({ value: +value, propName: 'textExtrusionAboveSurface' })
    this.onLabelSetSettingChange('textExtrusionAboveSurface', +value)
  }

  /** Changes extrusion below surface value for a label set.
   * @param {number} value - extrusion below surface value
   */
  onExtrusionBelowChange(value: number) {
    this.setActiveLabelSetSettingsProp({ value: +value, propName: 'textExtrusionBelowSurface' })
    this.onLabelSetSettingChange('textExtrusionBelowSurface', +value)
  }

  /** Changes text attachment value for a label set.
   * @param {number} value - text attachment value
   */
  onTextAttachmentChange(value: number) {
    this.setActiveLabelSetSettingsProp({ value: +value, propName: 'textExtrusionBelowSurface' })
    this.onLabelSetSettingChange('textExtrusionBelowSurface', +value)
  }

  /** Changes label planar faces value for a label set.
   * @param {number} value - planar faces only value
   */
  changePlanarOnly(value: number) {
    this.setActiveLabelSetSettingsProp({ value, propName: 'placementPlanarOnly' })
  }

  /** Reacts on changes related to the number of bodies per label for views mode.
   * @param values - min and max numbers of bodies
   */
  labelsPerBodyViewsRangeChange(values: number[]) {
    if (this.isLabelsPerCouponSliderLocked) {
      return
    }

    this.setActiveLabelSetSettingsProp({ propName: 'placementMinCount', value: values[0] })
    this.setActiveLabelSetSettingsProp({ propName: 'placementMaxCount', value: values[1] })
  }

  labelsPerBodyViewsRangeInput(values: [number, number]) {
    const [min, max] = values
    const maxValue = Math.max(min, max)
    // Temporary limitation for max value until we add ability to reorder active placements
    const isValueOutOfRange = maxValue > this.activePlacementRuleCount || maxValue < this.activePlacementRuleCount

    if (isValueOutOfRange) {
      const newMin = min > this.activePlacementRuleCount ? this.activePlacementRuleCount : min
      // Temporary limitation. For now the max count should be equal to the active placement rules count
      const newMax =
        max > this.activePlacementRuleCount || max < this.activePlacementRuleCount ? this.activePlacementRuleCount : max
      this.setActiveLabelSetSettingsProp({ propName: 'placementMinCount', value: newMin })
      this.setActiveLabelSetSettingsProp({ propName: 'placementMaxCount', value: newMax })
      this.lockLabelsPerCouponSlider()
    }
  }

  /** Reacts on changes related to the number of bodies per label for bars mode.
   * @param value - min and max numbers of bodies
   */
  labelsPerBodyBarRangeChange(value) {
    const prev = this.activeLabelSet.settings.placementMaxCount
    const labels: AutomatedTrackableLabel[] = this.activeLabelSet.labels as AutomatedTrackableLabel[]
    const nearBarLabels = labels.filter((label) => label.autoLocation === MarkingLocation.NearBarEnd)
    const farBarLabels = labels.filter((label) => label.autoLocation === MarkingLocation.FarBarEnd)
    const bodies = [...this.activeLabelSet.selectedBodies, ...this.activeLabelSet.relatedBodies]

    // We remove 1 bar label
    if (prev === 2 && value === 1) {
      const barsToRemove = nearBarLabels.map((label) => ({
        labelSetId: this.activeLabelSet.id,
        id: label.id,
        dirtyState: LabelDirtyState.Remove,
      }))
      this.makeTrackableLabelsDirty(barsToRemove)
      // Get new locations for bars
      const locationIds = this.activeLabelSet.settings.placementAutoLocations.filter(
        (location) => location !== MarkingLocation.NearBarEnd,
      )
      // Set new locations for bars
      this.setActiveLabelSetSettingsPropMutation({ propName: 'placementAutoLocations', value: locationIds })
    } else if (prev === 0 && value === 1) {
      // In this case we have to add 1 farBarEnd for each selected and related bodies
      const toForceMarkAsAdded = []
      const toAdd = bodies
        .map((body) => {
          const existingLabel = labels.find(
            (label) => label.bodyId === body.id && label.autoLocation === MarkingLocation.FarBarEnd,
          )
          if (!existingLabel) {
            return createAutomatedTrackableLabel(body.id, MarkingLocation.FarBarEnd, LabelDirtyState.Add)
          }

          toForceMarkAsAdded.push({
            labelSetId: this.activeLabelSet.id,
            id: existingLabel.id,
            dirtyState: LabelDirtyState.Add,
            force: true,
          })
        })
        .filter((label) => label)
      this.addTrackableLabels(toAdd)
      this.makeTrackableLabelsDirty(toForceMarkAsAdded)
      // In this case we have no locations, so we have to add just 1
      this.setActiveLabelSetSettingsPropMutation({
        propName: 'placementAutoLocations',
        value: [MarkingLocation.FarBarEnd],
      })
    } else if (prev === 1 && value === 0) {
      // in this case we have to remove farBarEnd labels
      const barsToRemove = farBarLabels.map((label) => ({
        labelSetId: this.activeLabelSet.id,
        id: label.id,
        dirtyState: LabelDirtyState.Remove,
      }))
      this.makeTrackableLabelsDirty(barsToRemove)
      // In this case we have to clear placements
      this.setActiveLabelSetSettingsPropMutation({ propName: 'placementAutoLocations', value: [] })
    } else if (prev === 1 && value === 2) {
      // In this case we have to add NearBarEnd
      const toForceMarkAsAdded = []
      const toAdd = bodies
        .map((body) => {
          const existingLabel = labels.find(
            (label) => label.bodyId === body.id && label.autoLocation === MarkingLocation.NearBarEnd,
          )

          if (!existingLabel) {
            return createAutomatedTrackableLabel(body.id, MarkingLocation.NearBarEnd, LabelDirtyState.Add)
          }

          toForceMarkAsAdded.push({
            labelSetId: this.activeLabelSet.id,
            id: existingLabel.id,
            dirtyState: LabelDirtyState.Add,
            force: true,
          })
        })
        .filter((label) => label)
      this.addTrackableLabels(toAdd)
      this.makeTrackableLabelsDirty(toForceMarkAsAdded)

      // Set new locations for bars
      this.setActiveLabelSetSettingsPropMutation({
        propName: 'placementAutoLocations',
        value: [...this.activeLabelSet.settings.placementAutoLocations, MarkingLocation.NearBarEnd],
      })
    } else if (prev === 2 && value === 0) {
      // In this case we have to remove all bars
      const barsToRemove = [...farBarLabels, ...nearBarLabels].map((label) => ({
        labelSetId: this.activeLabelSet.id,
        id: label.id,
        dirtyState: LabelDirtyState.Remove,
      }))
      this.makeTrackableLabelsDirty(barsToRemove)
      // In this case we have to clear placements
      this.setActiveLabelSetSettingsPropMutation({ propName: 'placementAutoLocations', value: [] })
    } else if (prev === 0 && value === 2) {
      // In this case we have to add all bars
      const toAdd = []
      const toForceMarkAsAdded = []
      bodies.forEach((body) => {
        const existingFarBarEndLabel = labels.find(
          (label) => label.bodyId === body.id && label.autoLocation === MarkingLocation.FarBarEnd,
        )
        const existingNearBarEndLabel = labels.find(
          (label) => label.bodyId === body.id && label.autoLocation === MarkingLocation.NearBarEnd,
        )
        if (!existingFarBarEndLabel) {
          const farBarEnd = createAutomatedTrackableLabel(body.id, MarkingLocation.FarBarEnd, LabelDirtyState.Add)
          toAdd.push(farBarEnd)
        } else {
          toForceMarkAsAdded.push({
            labelSetId: this.activeLabelSet.id,
            id: existingFarBarEndLabel.id,
            dirtyState: LabelDirtyState.Add,
            force: true,
          })
        }

        if (!existingNearBarEndLabel) {
          const nearBarEnd = createAutomatedTrackableLabel(body.id, MarkingLocation.NearBarEnd, LabelDirtyState.Add)
          toAdd.push(nearBarEnd)
        } else {
          toForceMarkAsAdded.push({
            labelSetId: this.activeLabelSet.id,
            id: existingNearBarEndLabel.id,
            dirtyState: LabelDirtyState.Add,
            force: true,
          })
        }
      })

      this.addTrackableLabels(toAdd)
      this.makeTrackableLabelsDirty(toForceMarkAsAdded)
      // In this case we have to add both locations
      this.setActiveLabelSetSettingsPropMutation({
        propName: 'placementAutoLocations',
        value: [MarkingLocation.FarBarEnd, MarkingLocation.NearBarEnd],
      })
    }

    this.setActiveLabelSetSettingsPropMutation({ value, propName: 'placementMinCount' })
    this.setActiveLabelSetSettingsPropMutation({ value, propName: 'placementMaxCount' })

    // Due to fact that we changed settings without triggering execute we should do it manually
    const shouldAbort = this.isLabelSetHasLabelWithCommandId(this.activeLabelSet.id)
    this.scheduleExecuteCommandAbortable(shouldAbort)
  }

  /** Reacts on label all instances checkbox. Adds labels onto all instances of selected bodies.
   * @param {boolean} isChecked - state of a checkbox
   */
  onLabelAllInstances(isChecked: boolean) {
    if (!this.hasRelatedBodies) {
      this.launchLabelAllInstances()
    } else {
      this.setRelatedBodies({ bodies: [] })
      if (this.isLabelSetContainsCounter(this.activeLabelSet.id)) {
        // If label set contains counter - all trackable labels of selected bodies should be recalculated as well
        const selectedBodiesIds: string[] = this.activeLabelSet.selectedBodies.map(
          (body: LabeledBodyWIthTransformation) => body.id,
        )
        const labelsToChangeDirtyState = []
        this.activeLabelSet.labels.forEach((trackableLabel: TrackableLabel) => {
          if (selectedBodiesIds.includes((trackableLabel as AutomatedTrackableLabel).bodyId)) {
            labelsToChangeDirtyState.push({
              labelSetId: this.activeLabelSet.id,
              id: trackableLabel.id,
              dirtyState: LabelDirtyState.Update,
            })
          }
        })
        if (labelsToChangeDirtyState.length > 0) {
          this.makeTrackableLabelsDirty(labelsToChangeDirtyState)
        }
      }

      this.scheduleExecuteCommand()
    }

    this.setHasLabeledInstances(!!isChecked)
  }

  /** Callback of a select related bodies event. Sets needed internal variables.
   * @param { bodies: LabeledBodyWIthTransformation[] | Placement[], add: boolean } payload - related items
   * to be chosen and a mark if those items should be set or added to an existing list of bodies
   */
  handleSelectRelatedBodiesEvent(payload: {
    bodies: LabeledBodyWIthTransformation[]
    add: boolean
    ignored: LabeledBodyWIthTransformation[]
  }) {
    const relatedItems = payload.bodies
    if (!this.activeLabelSet.settings.placementMethodAutomatic) {
      this.displayManualLabelSettings(false)
      this.hideManualLabelHandle(true)
      if (relatedItems.length) {
        this.updateManualPlacementsForRelatedBodies(relatedItems as Placement[])
      }

      if (this.isLabelSetContainsCounter(this.activeLabelSet.id)) {
        // If label set contains counter - all existing manual placements should be recalculated as well
        const manualPlacementsIds: string[] = this.activeLabelSet.manualPlacements.map((mp: Placement) => mp.id)
        const labelsToChangeDirtyState = []
        this.activeLabelSet.labels.forEach((trackableLabel: TrackableLabel) => {
          if (manualPlacementsIds.includes((trackableLabel as ManualTrackableLabel).manualPlacementId)) {
            labelsToChangeDirtyState.push({
              labelSetId: this.activeLabelSet.id,
              id: trackableLabel.id,
              dirtyState: LabelDirtyState.Update,
            })
          }
        })
        if (labelsToChangeDirtyState.length) {
          this.makeTrackableLabelsDirty(labelsToChangeDirtyState)
        }
      }

      this.showManuallyPlacedLabelOrigins({ patches: relatedItems as Placement[] })
    } else {
      this.setRelatedBodies(payload)
      // For each selected body for each view create an automatic trackable label
      const trackableLabelsToAdd: AutomatedTrackableLabel[] = []
      ;(relatedItems as LabeledBodyWIthTransformation[]).forEach((body: LabeledBodyWIthTransformation) => {
        this.activeLabelSet.settings.placementAutoLocations.forEach((autoPlacement: MarkingLocation) => {
          trackableLabelsToAdd.push(createAutomatedTrackableLabel(body.id, autoPlacement, LabelDirtyState.Add))
        })
      })
      this.addTrackableLabels(trackableLabelsToAdd)
      if (this.isLabelSetContainsCounter(this.activeLabelSet.id)) {
        // If label set contains counter - all trackable labels of selected bodies should be recalculated as well
        const selectedBodiesIds: string[] = this.activeLabelSet.selectedBodies.map(
          (body: LabeledBodyWIthTransformation) => body.id,
        )
        const labelsToChangeDirtyState = []
        this.activeLabelSet.labels.forEach((trackableLabel: TrackableLabel) => {
          if (selectedBodiesIds.includes((trackableLabel as AutomatedTrackableLabel).bodyId)) {
            labelsToChangeDirtyState.push({
              labelSetId: this.activeLabelSet.id,
              id: trackableLabel.id,
              dirtyState: LabelDirtyState.Update,
            })
          }
        })
        if (labelsToChangeDirtyState.length) {
          this.makeTrackableLabelsDirty(labelsToChangeDirtyState)
        }
      }
      this.scheduleExecuteCommand()
    }

    // need to call this method in order to trigger labels re-generation
    if (this.activeLabelSetSettings.placementMethodAutomatic) {
      this.setHasLabeledInstances(true)
    }
  }

  /** Applies rotation onto all other manual labels of an active label set. */
  onApplyRotationToAllOtherLabels() {
    const manualPlacements = this.manualPlacementsForLabelSet(this.activeLabelSet.id)
    const targetLabels = manualPlacements.filter((mp) => mp.id !== this.hoveredLabel)
    const sourceLabel = manualPlacements.find((mp) => mp.id === this.hoveredLabel)
    this.applyRotationToAllOtherLabels({ sourceLabel, targetLabels })
    this.displayManualLabelSettings(false)
    this.hideManualLabelHandle(true)
  }

  /** Updates manual placements for related bodies on new related bodies selected.
   * @param {Placement[]} relatedItems - items that were selected
   */
  updateManualPlacementsForRelatedBodies(relatedItems: Placement[]) {
    const activeManualPlacements: Placement[] = this.manualPlacementsForLabelSet(this.activeLabelSet.id)
    const manualPlacementsToAdd: ManualPatch[] = []
    relatedItems.map((relatedItem) => {
      const manualPlacement = activeManualPlacements.find((mp) => mp.id === this.hoveredLabel)
      const manualPlacementCopy = JSON.parse(JSON.stringify(manualPlacement)) as ManualPatch
      manualPlacementCopy.id = relatedItem.id
      manualPlacementCopy.buildPlanItemId = relatedItem.buildPlanItemId
      manualPlacementCopy.componentId = relatedItem.componentId
      manualPlacementCopy.orientation = relatedItem.orientation
      manualPlacementsToAdd.push(manualPlacementCopy)
    })

    this.setActiveSetLastSuccessfulManualPlacements(activeManualPlacements)
    this.addManualPlacements({
      labelSetId: this.activeLabelSet.id,
      patches: manualPlacementsToAdd,
    })
  }

  /** Removes manually added label from a label set. */
  onRemoveLabel() {
    this.removeManuallyPlacedLabel({ labelSetId: this.activeLabelSet.id, labelId: this.hoveredLabel })
    this.displayManualLabelSettings(false)
  }

  onPlacementRulesChange(payload: { activeCount: number }) {
    this.activePlacementRuleCount = payload.activeCount
  }

  onExitRequested() {
    // Add needed logic
  }

  /** Returns recessed depth valued based on a field's focus state. If field is focused - it does not mutate the value
   * in it. Value is changed only after the focusout event.
   */
  get recessedDepthValue() {
    const value = this.activeLabelSet.settings.textExtrusionBelowSurface
    return this.recessedDepthIsFocused ? value : value && (+value).toFixed(this.digitsAfterDecimal)
  }

  /** Returns embossed height valued based on a field's focus state. If field is focused - it does not mutate the value
   * in it. Value is changed only after the focusout event.
   */
  get embossedHeightValue() {
    const value = this.activeLabelSet.settings.textExtrusionAboveSurface
    return this.embossedHeightIsFocused ? value : value && (+value).toFixed(this.digitsAfterDecimal)
  }

  /** Returns attachment depth valued based on a field's focus state. If field is focused - it does not mutate the value
   * in it. Value is changed only after the focusout event.
   */
  get attachmentDepthValue() {
    const value = this.activeLabelSet.settings.textExtrusionBelowSurface
    return this.attachmentDepthIsFocused ? value : value && (+value).toFixed(this.digitsAfterDecimal)
  }

  /** Returns views tick labels for labels per body slider. */
  get getViewsTickLabels() {
    return Array.from(Array(7).keys()).map((i) => i.toString())
  }

  /** Returns bars tick labels for labels per body slider. */
  get getBarsTickLabels() {
    return Array.from(Array(3).keys()).map((i) => i.toString())
  }

  /** Creates new validation rules for minimal font size field. */
  get fontMinSizeValidationRules() {
    const nativeRules: { required: boolean; min_value: number; max_value?: number } = {
      required: true,
      min_value: this.fontMinSize,
    }

    if (
      this.activeLabelSet.settings.fontTargetSize !== null &&
      this.activeLabelSet.settings.fontTargetSize !== undefined &&
      this.activeLabelSet.settings.fontTargetSize > this.fontMinSize
    ) {
      nativeRules.max_value =
        this.fontMaxSize > this.activeLabelSet.settings.fontTargetSize - ADJUSTMENT_FACTOR
          ? this.activeLabelSet.settings.fontTargetSize - ADJUSTMENT_FACTOR
          : this.fontMaxSize - ADJUSTMENT_FACTOR
    }

    return {
      nativeRules,
      customMessages: {
        min_value: this.$t('labelTool.validationMessages.validMinMaxFontSize', [
          this.fontMinSize.toFixed(this.digitsAfterDecimal),
          this.activeLabelSet.settings.fontTargetSize &&
          this.fontMaxSize > this.activeLabelSet.settings.fontTargetSize - ADJUSTMENT_FACTOR &&
          this.activeLabelSet.settings.fontTargetSize > this.fontMinSize
            ? (this.activeLabelSet.settings.fontTargetSize - ADJUSTMENT_FACTOR).toFixed(this.digitsAfterDecimal)
            : (this.fontMaxSize - ADJUSTMENT_FACTOR).toFixed(this.digitsAfterDecimal),
        ]),
        max_value: this.$t('labelTool.validationMessages.validMinMaxFontSize', [
          this.fontMinSize.toFixed(this.digitsAfterDecimal),
          this.activeLabelSet.settings.fontTargetSize &&
          this.fontMaxSize > this.activeLabelSet.settings.fontTargetSize - ADJUSTMENT_FACTOR &&
          this.activeLabelSet.settings.fontTargetSize > this.fontMinSize
            ? (this.activeLabelSet.settings.fontTargetSize - ADJUSTMENT_FACTOR).toFixed(this.digitsAfterDecimal)
            : (this.fontMaxSize - ADJUSTMENT_FACTOR).toFixed(this.digitsAfterDecimal),
        ]),
      },
    }
  }

  /** Creates new validation rules for maximal font size field. */
  get fontMaxSizeValidationRules() {
    const nativeRules: { required: boolean; min_value?: number; max_value: number } = {
      required: true,
      max_value: this.fontMaxSize,
    }

    if (
      this.activeLabelSet.settings.fontMinSize !== null &&
      this.activeLabelSet.settings.fontMinSize !== undefined &&
      this.activeLabelSet.settings.fontTargetSize > this.fontMinSize
    ) {
      nativeRules.min_value =
        this.fontMaxSize > this.activeLabelSet.settings.fontMinSize + ADJUSTMENT_FACTOR
          ? this.activeLabelSet.settings.fontMinSize + ADJUSTMENT_FACTOR
          : this.fontMinSize + ADJUSTMENT_FACTOR
    }

    return {
      nativeRules,
      customMessages: {
        min_value: this.$t('labelTool.validationMessages.validMinMaxFontSize', [
          this.activeLabelSet.settings.fontMinSize &&
          this.fontMaxSize > this.activeLabelSet.settings.fontMinSize + ADJUSTMENT_FACTOR
            ? (this.activeLabelSet.settings.fontMinSize + ADJUSTMENT_FACTOR).toFixed(this.digitsAfterDecimal)
            : (this.fontMinSize + ADJUSTMENT_FACTOR).toFixed(this.digitsAfterDecimal),
          this.fontMaxSize.toFixed(this.digitsAfterDecimal),
        ]),
        max_value: this.$t('labelTool.validationMessages.validMinMaxFontSize', [
          this.activeLabelSet.settings.fontMinSize &&
          this.fontMaxSize > this.activeLabelSet.settings.fontMinSize + ADJUSTMENT_FACTOR
            ? (this.activeLabelSet.settings.fontMinSize + ADJUSTMENT_FACTOR).toFixed(this.digitsAfterDecimal)
            : (this.fontMinSize + ADJUSTMENT_FACTOR).toFixed(this.digitsAfterDecimal),
          this.fontMaxSize.toFixed(this.digitsAfterDecimal),
        ]),
      },
    }
  }

  /** Creates new validation rules for font size field. */
  get fontSizeValidationRules() {
    return {
      nativeRules: {
        required: true,
        min_value: this.fontMinSize,
        max_value: this.fontMaxSize,
      },
      customMessages: {
        min_value: this.$t('labelTool.validationMessages.validMinMaxFontSize', [
          this.fontMinSize.toFixed(this.digitsAfterDecimal),
          this.fontMaxSize.toFixed(this.digitsAfterDecimal),
        ]),
        max_value: this.$t('labelTool.validationMessages.validMinMaxFontSize', [
          this.fontMinSize.toFixed(this.digitsAfterDecimal),
          this.fontMaxSize.toFixed(this.digitsAfterDecimal),
        ]),
      },
    }
  }

  /** Creates new validation rules for rotation field. */
  get rotationValidationRules() {
    return {
      nativeRules: {
        required: true,
        in_range_included: [(-360).toFixed(this.digitsAfterDecimal), (360).toFixed(this.digitsAfterDecimal)],
      },
      customMessages: {
        required: this.$t('labelTool.enterValue', {
          leftLimit: (-360).toFixed(this.digitsAfterDecimal),
          rightLimit: (360).toFixed(this.digitsAfterDecimal),
        }).toString(),
      },
    }
  }

  /** Creates new validation rules for margin field. */
  get marginValidationRules() {
    return {
      nativeRules: {
        required: true,
        in_range_included: [(0).toFixed(this.digitsAfterDecimal), (50).toFixed(this.digitsAfterDecimal)],
      },
      customMessages: {
        required: this.$t('labelTool.enterValue', {
          leftLimit: (0).toFixed(this.digitsAfterDecimal),
          rightLimit: (50).toFixed(this.digitsAfterDecimal),
        }).toString(),
      },
    }
  }

  /** Creates new validation rules for embossed height field. */
  get embossedHeightValidationRules() {
    return {
      nativeRules: {
        required: true,
        in_range_included: [
          this.extrusionMinValue.toFixed(this.digitsAfterDecimal),
          this.extrusionMaxValue.toFixed(this.digitsAfterDecimal),
        ],
      },
      customMessages: {
        required: this.$t('labelTool.enterValueWithUnits', {
          leftLimit: this.extrusionMinValue.toFixed(this.digitsAfterDecimal),
          rightLimit: this.extrusionMaxValue.toFixed(this.digitsAfterDecimal),
          units: EXTRUSION_UNITS,
        }).toString(),
        in_range_included: this.$t('labelTool.enterValueWithUnits', {
          leftLimit: this.extrusionMinValue.toFixed(this.digitsAfterDecimal),
          rightLimit: this.extrusionMaxValue.toFixed(this.digitsAfterDecimal),
          units: EXTRUSION_UNITS,
        }).toString(),
      },
    }
  }

  /** Creates new validation rules for attachment depth field. */
  get attachmentDepthValidationRules() {
    return {
      nativeRules: {
        required: true,
        in_range_included: [
          this.attachmentDepthMinValue.toFixed(this.digitsAfterDecimal),
          this.attachmentDepthMaxValue.toFixed(this.digitsAfterDecimal),
        ],
      },
      customMessages: {
        required: this.$t('labelTool.enterValueWithUnits', {
          leftLimit: this.attachmentDepthMinValue.toFixed(this.digitsAfterDecimal),
          rightLimit: this.attachmentDepthMaxValue.toFixed(this.digitsAfterDecimal),
          units: EXTRUSION_UNITS,
        }).toString(),
        in_range_included: this.$t('labelTool.enterValueWithUnits', {
          leftLimit: this.attachmentDepthMinValue.toFixed(this.digitsAfterDecimal),
          rightLimit: this.attachmentDepthMaxValue.toFixed(this.digitsAfterDecimal),
          units: EXTRUSION_UNITS,
        }).toString(),
      },
    }
  }

  /** Creates new validation rules for recessed depth field. */
  get recessedDepthValidationRules() {
    return {
      nativeRules: {
        required: true,
        in_range_included: [
          this.extrusionMinValue.toFixed(this.digitsAfterDecimal),
          this.extrusionMaxValue.toFixed(this.digitsAfterDecimal),
        ],
      },
      customMessages: {
        required: this.$t('labelTool.enterValueWithUnits', {
          leftLimit: this.extrusionMinValue.toFixed(this.digitsAfterDecimal),
          rightLimit: this.extrusionMaxValue.toFixed(this.digitsAfterDecimal),
          units: EXTRUSION_UNITS,
        }).toString(),
        in_range_included: this.$t('labelTool.enterValueWithUnits', {
          leftLimit: this.extrusionMinValue.toFixed(this.digitsAfterDecimal),
          rightLimit: this.extrusionMaxValue.toFixed(this.digitsAfterDecimal),
          units: EXTRUSION_UNITS,
        }).toString(),
      },
    }
  }

  /** Checks whether active label set has related bodies. */
  get hasRelatedBodies() {
    return !!this.activeLabelSet.relatedBodies.length && this.activeLabelSetSettings.placementMethodAutomatic
  }

  /**
   * Returns max value for the min font size number field
   */
  get maxValueForMinFontSize() {
    return this.activeLabelSet.settings.fontTargetSize - this.adjustmentFactor > this.fontMaxSize
      ? this.activeLabelSet.settings.fontTargetSize - this.adjustmentFactor
      : this.fontMaxSize - this.adjustmentFactor
  }

  /**
   * Returns min value for the max font size number field
   */
  get minValueForMaxFontSize() {
    return this.activeLabelSet.settings.fontMinSize + this.adjustmentFactor < this.fontMaxSize
      ? this.activeLabelSet.settings.fontMinSize + this.adjustmentFactor
      : this.fontMinSize + this.adjustmentFactor
  }

  /**
   * Briefly locks the movement of the slider tick.
   * Changing the step initiates a re-rendering of the slider's internal elements.
   * In this case, the slider tick is set to the previous valid position.
   * Since re-rendering performed instantly, it seems to the user that the slider is locked.
   * In the current implementation of the slider, there is no possibility to limit
   * the movement of the tick to a certain position.
   */
  private lockLabelsPerCouponSlider() {
    this.labelsPerCouponSliderStep = 0
    this.$nextTick(() => {
      this.labelsPerCouponSliderStep = 1
    })
  }
}
