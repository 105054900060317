/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { SimulationBase } from './SimulationBase'
import { VertexBuffer } from '@babylonjs/core/Buffers'
import { VertexData, Mesh } from '@babylonjs/core/Meshes'
import { int } from '@babylonjs/core/types'
import { SimulationColoringView, Components } from '@/types/Simulation/SimulationColoringView'
import { ColorPalettes } from '@/types/Simulation/SimulationColorFunctions'
import { SimulationColoringModel } from '@/types/Simulation/SimulationColoringModel'
import {
  CUTOUT_MATERIAL,
  NO_SPECULAR_MATERIAL_NAME,
  SOLID_MATERIAL,
  TEMPERATURE_FIELD_PATTERN,
  MESH_INSIDE_MATERIAL_NAME,
} from '@/constants'
import { SubMesh } from '@babylonjs/core/Meshes/subMesh'
import { MultiMaterial } from '@babylonjs/core/Materials/multiMaterial'
import { ScaleRange } from '@/visualization/scales'
import { getUnitTolerance } from '@/types/Simulation/Units'
import { OperationStatus } from '@/types/InteractiveService/IInteractiveServiceMessage'
import { createGuid } from '@/utils/common'
import { equalWithTolerance } from '@/utils/number'
import {
  IVisualizationServiceCommand,
  IVisualizationServiceMessage,
  ResultsManager,
  WsCommands,
} from '@/visualization/rendering/ResultsManager'
import { DataWarpingHandler } from '@/visualization/rendering/DataWarpingHandler'
import { VisualizationEvent } from '@/visualization/infrastructure/IVisualizationEvent'
import { CategoryKind } from '@/visualization/types/SimulationTypes'
import { DracoDecoder } from '@/visualization/components/DracoDecoder'

interface IUpdateResult {
  id: string
  timestamps: number[]
}

export class CategoryDataHandler extends SimulationBase {
  private currentTimeStamp: int
  private surfaceId: string
  private dataId: string
  private getTimestampsCommand: IVisualizationServiceCommand
  private changeTimestampCommand: IVisualizationServiceCommand
  private fieldsMap: Map<string, any>
  private colorView: SimulationColoringView
  private coloringModel: SimulationColoringModel
  private paraviewReaderId: string
  private simulationUpdateId = 'publish:simulation.update:0'
  private allStepsScales: Map<string, ScaleRange>
  private currentStepScales: Map<string, ScaleRange>
  private warpingHandler: DataWarpingHandler
  private warpingFactor: number
  private dataPath: string | string[]
  private isVisible: boolean
  private category: CategoryKind

  private readonly loaded = new VisualizationEvent<void>()
  private readonly warpingApplied = new VisualizationEvent<boolean>()
  private readonly timeStampsInitialized = new VisualizationEvent<{ update: boolean; timeStamps: number[] }>()
  private readonly fieldDataReceived = new VisualizationEvent<any>()
  private readonly colorSchemeApplied = new VisualizationEvent<void>()

  constructor(
    manager: ResultsManager,
    allStepsScales: Map<string, ScaleRange>,
    currentStepScales: Map<string, ScaleRange>,
    path: string | string[],
    stepId: string,
    category: CategoryKind,
  ) {
    super(manager)
    this.fieldsMap = new Map<string, any>()
    this.dataId = `${this.getMeshId()}${stepId}`
    this.allStepsScales = allStepsScales
    this.currentStepScales = currentStepScales
    this.initCommads()
    this.warpingHandler = new DataWarpingHandler(manager.getScene, this.dataId)
    this.warpingFactor = 1
    this.dataPath = path
    this.category = category
  }

  openDataSet(isVisible: boolean) {
    this.manager.getSocket.on('message', this.handleSocketCommunication)
    this.manager.getSocket.on('disconnect', this.stopSocketCommunication)

    const openCommand: IVisualizationServiceCommand = {
      id: createGuid(),
      name: WsCommands.ImportSkinSimulation,
      parameters: [this.dataPath],
    }

    this.commandMap.set(openCommand.id, (msg: IVisualizationServiceMessage) => {
      this.paraviewReaderId = msg.message.result.id
      this.surfaceId = msg.message.result.surfaceId
      this.getTimestampsCommand.parameters = [this.paraviewReaderId]
      this.manager.getSocket.emit('command', this.getTimestampsCommand)
    })

    this.manager.getSocket.emit('command', openCommand)
    this.isVisible = isVisible
  }

  changeDatasetColorView(view: SimulationColoringView) {
    if (!view || this.disabledForCurrentTimestamp) return

    this.colorView = view
    this.resetXrayView()

    if (this.fieldsMap.get(this.getCurrentComponentHash())) {
      this.applyColorScheme()
    } else {
      this.changeField()
    }
  }

  availableInTimestamp(timeStamp: int, totalTimeStamps: int): boolean {
    return this.toCorrespondingTime(timeStamp, totalTimeStamps) >= 0
  }

  changeTimeStamp(timeStamp: int, totalTimeStamps: int) {
    const newTimeStamp = this.toCorrespondingTime(timeStamp, totalTimeStamps)
    if (newTimeStamp < 0) {
      this.hide(true)
      this.setCurrentTimeStamp(newTimeStamp)
    }

    if (newTimeStamp === this.currentTimeStamp) return

    this.fieldsMap.clear()
    this.warpingHandler.clear()

    this.setCurrentTimeStamp(newTimeStamp)
    if (this.datasetInfo[newTimeStamp]) {
      this.handleDataSetInfo(this.datasetInfo[newTimeStamp])
    } else {
      this.getTimeStampData(newTimeStamp)
    }
  }

  clearData() {
    this.removeMeshById(this.dataId)
    this.fieldsMap.clear()
    this.commandMap.clear()
    this.warpingHandler.clear()
    this.warpingApplied.dispose()
    this.fieldDataReceived.dispose()
    this.loaded.dispose()
    this.timeStampsInitialized.dispose()
    this.colorSchemeApplied.dispose()
  }

  hide(hidden: boolean) {
    if (this.disabledForCurrentTimestamp) return

    const mesh = this.getMeshById(this.dataId)
    if (mesh) {
      mesh.visibility = hidden ? 0 : 1
    }

    this.showChildMesh(!hidden)
    this.isVisible = !hidden
  }

  activate(setActive: boolean) {
    this.hide(!setActive)

    this.resetXrayView()
    if (setActive) {
      this.adjustCamera()
    }
  }

  setXrayView(minBound: number, maxBound: number) {
    if (this.disabledForCurrentTimestamp) return

    this.toggleAdditionalGeometry(this.fitAllDataRange(minBound, maxBound))
    const colors = []
    const data = this.fieldsMap.get(this.getCurrentComponentHash())
    const existingMesh = this.getMeshById(this.dataId)
    const indices = existingMesh.getIndices()
    const indicesMap = new Map<number, number[]>()
    indices.forEach((value, index) => {
      if (!indicesMap.get(value)) {
        indicesMap.set(value, [])
      }

      indicesMap.get(value).push(index - (index % 3))
    })

    const triangles = new Set<number>()

    this.colorTransferFunction.setMappingRange(minBound, maxBound)
    for (let i = 0; i < data.length; i += 1) {
      const value = data[i]
      const color = this.colorTransferFunction.mapValue(value)
      if (value < minBound || value > maxBound) {
        if (indicesMap.has(i)) {
          indicesMap.get(i).forEach((triangle) => {
            triangles.add(triangle)
          })
        }
      }

      colors.push(...color.map((x) => x / 255))
    }

    const opaqueIndicesCount = indices.length - 3 * triangles.size
    let cutoutCount = 0
    let v1
    triangles.forEach((triangle) => {
      if (triangle < opaqueIndicesCount) {
        cutoutCount += 1
        while (triangles.has(indices.length - 3 * cutoutCount)) {
          cutoutCount += 1
        }

        for (let i = 0; i < 3; i += 1) {
          v1 = indices[triangle + i]
          indices[triangle + i] = indices[indices.length - 3 * cutoutCount + i]
          indices[indices.length - 3 * cutoutCount + i] = v1
        }
      }
    })

    const vertexData = new VertexData()
    vertexData.colors = colors
    vertexData.indices = indices
    vertexData.applyToMesh(existingMesh, true)

    const multiMaterial = new MultiMaterial('multi', this.manager.getScene)
    multiMaterial.subMaterials.push(this.manager.getScene.getMaterialByName(SOLID_MATERIAL))
    multiMaterial.subMaterials.push(this.manager.getScene.getMaterialByName(CUTOUT_MATERIAL))
    existingMesh.material = multiMaterial
    existingMesh.subMeshes = []
    const verticesCount = existingMesh.getTotalVertices()

    const s1 = new SubMesh(0, 0, verticesCount, 0, opaqueIndicesCount, existingMesh)
    const s2 = new SubMesh(1, 0, verticesCount, opaqueIndicesCount, 3 * triangles.size, existingMesh)
    this.manager.getScene.render()
  }

  resetXrayView() {
    if (this.disabledForCurrentTimestamp) return

    if (this.isVisible) {
      this.toggleAdditionalGeometry(true)
    }

    const existingMesh = this.getMeshById(this.dataId)
    if (!existingMesh || existingMesh.subMeshes.length === 1) return
    existingMesh.material = this.manager.getScene.getMaterialByName(NO_SPECULAR_MATERIAL_NAME)

    this.applyColorScheme()
  }

  applyColorScheme() {
    const data = this.fieldsMap.get(this.getCurrentComponentHash())
    if (!data || this.disabledForCurrentTimestamp) return

    const palette = this.colorView.isNameMatches(TEMPERATURE_FIELD_PATTERN)
      ? ColorPalettes.CoolToWarm
      : ColorPalettes.BlueToRed

    this.setColorPalette(palette)
    const colors = []
    const dataRange = this.targetDataRange
    const unit = this.coloringModel.selectedView.unit
    const belowTolerance = Math.abs(dataRange.max - dataRange.min) < Math.pow(10, getUnitTolerance(unit))
    this.colorTransferFunction.setMappingRange(belowTolerance ? 0 : dataRange.min, belowTolerance ? 1 : dataRange.max)

    for (const entry of data) {
      const color = belowTolerance
        ? this.colorTransferFunction.mapValue(0.5)
        : this.colorTransferFunction.mapValue(entry)
      colors.push(...color.map((x) => x / 255))
    }

    this.manager.getScene.getMeshById(this.dataId).setVerticesData(VertexBuffer.ColorKind, colors)
    this.colorSchemeApplied.trigger()
  }

  setWarpingParameters(factor: number, fieldKey: string) {
    if (this.disabledForCurrentTimestamp) return

    this.warpingFactor = factor
    if (this.warpingHandler.isInitialized) {
      this.warpingHandler.setWarpingFactor(factor)
      this.warpingApplied.trigger(false)
    } else {
      this.getDisplacementComponent(Components.X, fieldKey)
      this.getDisplacementComponent(Components.Y, fieldKey)
      this.getDisplacementComponent(Components.Z, fieldKey)
    }
  }

  setColoringInfo(model: SimulationColoringModel, view: SimulationColoringView) {
    if (!model || !view || this.disabledForCurrentTimestamp) return

    this.coloringModel = model
    this.colorView = Object.create(view)

    // Load the mesh data only if coloring scheme is available to make sure we have
    // all needed information to call changeField()
    const pointsHash = this.datasetInfo[this.currentTimeStamp].points.hash
    this.getBinaryBuffer(pointsHash).then((data) => {
      DracoDecoder.Default.decodeMeshPreservingOrder(data).then((decompressed) => {
        this.createMesh(decompressed)
        this.changeField()
      })
    })
  }

  get isReady(): boolean {
    const existingMesh = this.getMeshById(this.dataId)
    return existingMesh ? existingMesh.isReady() : false
  }

  get isWarpingDataInitialized() {
    return this.warpingHandler.isInitialized
  }

  get onLoaded() {
    return this.loaded.expose()
  }

  get onWarpingApplied() {
    return this.warpingApplied.expose()
  }

  get onTimeStampsInitialized() {
    return this.timeStampsInitialized.expose()
  }

  get onFieldDataReceived() {
    return this.fieldDataReceived.expose()
  }

  get onColorSchemeApplied() {
    return this.colorSchemeApplied.expose()
  }

  get transferFunction() {
    return this.colorTransferFunction
  }

  protected handleSocketCommunication(msg: IVisualizationServiceMessage) {
    super.handleSocketCommunication(msg)
    const sentCommand = this.commandMap.get(msg.id)
    if (!sentCommand && msg.status === OperationStatus.Success) {
      const id = msg.message.id
      if (id && id === this.simulationUpdateId) {
        this.handleSimulationUpdate(msg.message.result)
      }
    }
  }

  private get disabledForCurrentTimestamp() {
    return this.currentTimeStamp < 0
  }

  private initCommads() {
    this.getTimestampsCommand = { id: createGuid(), name: WsCommands.GetTimeStamps }
    this.changeTimestampCommand = { id: createGuid(), name: WsCommands.SetCurrentSkinTimeStamp }

    this.commandMap.set(this.getTimestampsCommand.id, (msg: IVisualizationServiceMessage) => {
      const timeStamps = Array.from<number>(msg.message.result)
      this.datasetInfo = new Array<any>(timeStamps.length)
      this.timeStampsInitialized.trigger({ timeStamps, update: false })
      this.changeTimeStamp(timeStamps.length - 1, timeStamps.length)
    })

    this.commandMap.set(this.changeTimestampCommand.id, (msg: IVisualizationServiceMessage) => {
      this.handleDataSetInfo(msg.message.result)
    })
  }

  private setCurrentTimeStamp(value: number) {
    // If handler has been unavailable in previous time stamp (meaining it was hidden) we should make it visible
    // in the next timestamp for which it is available and if the handler is not toggled off
    if (this.disabledForCurrentTimestamp && value >= 0 && this.manager.getHandlerVisibility(this.category)) {
      this.currentTimeStamp = value
      this.hide(false)
    }

    this.currentTimeStamp = value
  }

  private handleDataSetInfo(result) {
    this.datasetInfo[this.currentTimeStamp] = result
    this.fieldDataReceived.trigger(result.fields)
  }

  private getTimeStampData(newTimeStamp: number) {
    this.changeTimestampCommand.parameters = [
      this.surfaceId,
      newTimeStamp,
      this.settings.computePrincipals,
      this.settings.computeDerivatives,
    ]

    this.manager.getSocket.emit('command', this.changeTimestampCommand)
  }

  private get targetDataRange() {
    const rangeName = this.colorView.getSelectedModeMetadataId()
    const fieldName = this.colorView.internalName
    const scaleRange = this.manager.isCurrentComponentRange
      ? this.currentStepScales.get(fieldName)
      : this.allStepsScales.get(fieldName)

    return {
      min: scaleRange.min.get(rangeName),
      max: scaleRange.max.get(rangeName),
    }
  }

  private createMesh(data) {
    const existingMesh = this.getMeshById(this.dataId) as Mesh
    if (!existingMesh) {
      const mesh = new Mesh(this.dataId, this.manager.getScene)
      mesh.useVertexColors = true
      mesh.isPickable = false
      mesh.material = this.manager.getScene.getMaterialByName(NO_SPECULAR_MATERIAL_NAME)

      data.applyToMesh(mesh, true)
      mesh.renderingGroupId = 0
      mesh.visibility = this.isVisible ? 1 : 0

      // TODO Consider create an instanced mesh not clone
      const meshInside = mesh.clone()
      meshInside.material = this.manager.getScene.getMaterialByName(MESH_INSIDE_MATERIAL_NAME)
      meshInside.parent = mesh
      meshInside.renderingGroupId = 1
      meshInside.visibility = this.isVisible ? 1 : 0

      mesh.onMeshReadyObservable.addOnce(() => {
        this.loaded.trigger()
      })
    } else {
      data.applyToMesh(existingMesh, true)
      existingMesh.onMeshReadyObservable.addOnce(() => {
        this.loaded.trigger()
      })
    }
  }

  private getCurrentComponentData() {
    const modeId = this.colorView.getSelectedModeMetadataId()
    return this.datasetInfo[this.currentTimeStamp].fields[this.colorView.internalName][modeId]
  }

  private getCurrentComponentHash() {
    return this.getComponentHash()
  }

  private getComponentHash(viewName?: string, id?: string) {
    const name = viewName || this.colorView.internalName
    const mode = id || this.colorView.getSelectedModeMetadataId()
    const componentInfo = this.datasetInfo[this.currentTimeStamp].fields[name][mode]
    return `${name}${mode}${componentInfo.hash}`
  }

  private changeField() {
    const componentData = this.getCurrentComponentData()
    const storeHash = this.getCurrentComponentHash()
    if (componentData.codebook.length === 1) {
      // No need to request the field data from server if it has only one constant value
      const fieldData = Array(componentData.size).fill(componentData.codebook[0])
      this.fieldsMap.set(storeHash, fieldData)
      this.applyColorScheme()
    } else {
      this.getBinaryBuffer(componentData.hash).then((data) => {
        this.storeBinnedFieldData(data, componentData, storeHash)
        this.applyColorScheme()
      })
    }
  }

  private storeBinnedFieldData(data, componentData, storeHash) {
    const code = this.getArrayFromBinaryData(data, componentData.dataType)

    let fieldData = []
    if (code.length === 1) {
      fieldData = Array(componentData.size).fill(componentData.codebook[0])
    } else {
      const codebook = componentData.codebook
      code.forEach((c) => {
        fieldData.push(codebook[c])
      })
    }

    this.fieldsMap.set(storeHash, fieldData)
  }

  private getDisplacementComponent(component: Components, key: string) {
    const warpingComponents = this.datasetInfo[this.currentTimeStamp].fields[key]
    const componentHash = warpingComponents[component].hash

    this.getBinaryBuffer(componentHash).then((data) => {
      const componentData = warpingComponents[component]
      this.storeBinnedFieldData(data, componentData, this.getComponentHash(key, component))
      const x = this.fieldsMap.get(this.getComponentHash(key, Components.X))
      const y = this.fieldsMap.get(this.getComponentHash(key, Components.Y))
      const z = this.fieldsMap.get(this.getComponentHash(key, Components.Z))
      if (x && y && z) {
        this.warpingHandler.init(x, y, z)
        this.warpingHandler.setWarpingFactor(this.warpingFactor)
        this.warpingApplied.trigger(true)
      }
    })
  }

  private handleSimulationUpdate(result: IUpdateResult) {
    if (result.id === this.paraviewReaderId) {
      this.timeStampsInitialized.trigger({ update: true, timeStamps: result.timestamps })
    }
  }

  private showChildMesh(show: boolean) {
    const existingMesh = this.getMeshById(this.dataId)
    if (existingMesh && existingMesh.getChildMeshes().length) {
      existingMesh.getChildMeshes()[0].visibility = show ? 1 : 0
    }
  }

  private toggleAdditionalGeometry(enable: boolean) {
    const plane = this.manager.sliceManager.slicePlane
    if (plane) {
      plane.isVisible = enable
    }

    this.showChildMesh(enable)
  }

  private fitAllDataRange(min: number, max: number): boolean {
    const dataRange = this.targetDataRange

    return (
      equalWithTolerance(min, dataRange.min, Number.EPSILON) && equalWithTolerance(max, dataRange.max, Number.EPSILON)
    )
  }

  private toCorrespondingTime(timeStamp: int, totalTimeStamps: int) {
    // This adjustment needed to handle the case when collections in one simulation steps could have
    // different number of timestamps. It can happen during Printing step in DMLM simulations
    return Number(timeStamp) - (totalTimeStamps - this.datasetInfo.length)
  }
}
