
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { InsightsSeverity } from '@/types/Common/Insights'

const partsStore = namespace(StoresNamespaces.Parts)

@Component({
  components: {},
})
export default class DefectGroupWrapper extends Vue {
  @Prop() severity: InsightsSeverity

  @partsStore.Getter activeInsightDefectGroup: number[]
  @partsStore.Mutation setActiveInsightDefectGroup: (cId: number) => void

  insightsSeverity = InsightsSeverity

  get cId() {
    return (this as any)._uid
  }

  activate() {
    this.setActiveInsightDefectGroup(this.activeInsightDefectGroup === this.cId ? null : this.cId)
  }
}
