
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { IPartInsight, PartDefect } from '@/types/Parts/IPartInsight'
import { InsightsSeverity } from '@/types/Common/Insights'
import IssuesContainers from '@/components/layout/buildPlans/insight/group/IssuesContainers.vue'
import DefectGroup from '@/components/layout/parts/insight/group/DefectGroup.vue'

const partsStore = namespace(StoresNamespaces.Parts)

@Component({
  components: {
    IssuesContainers,
    DefectGroup,
  },
})
export default class PartInsightsPanel extends Vue {
  insightsSeverity = InsightsSeverity

  @partsStore.Getter insights: IPartInsight[]

  get errors() {
    return this.groupByType(this.insights.filter((i: IPartInsight) => i.severity === InsightsSeverity.Error))
  }

  get warnings() {
    return this.groupByType(this.insights.filter((i: IPartInsight) => i.severity === InsightsSeverity.Warning))
  }

  groupByType(insights: IPartInsight[]) {
    let issuesMap: Partial<{ [key in PartDefect]: IPartInsight[] }> = null

    insights.forEach((i: IPartInsight) => {
      if (!issuesMap) {
        issuesMap = { [i.type]: [i] }
        return
      }
      if (!issuesMap[i.type]) {
        issuesMap[i.type] = [i]
        return
      }
      issuesMap[i.type].push(i)
    })
    return issuesMap
  }
}
