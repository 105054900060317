import { RouterNames } from '@/router'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { JobType } from '@/types/PartsLibrary/Job'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { PrintingTypes } from '@/types/IMachineConfig'
import i18n from '@/plugins/i18n'
import store from '@/store'

export interface BuildPlanTool {
  key: ToolNames
  isPreview?: boolean
  isPreparing?: boolean
  routerName: RouterNames
  icon: string
  divide: boolean
  viewMode: ViewModeTypes
  jobTypes: any[]
  waysToEnable: any[]
  setViewMode?: boolean
  sidebarStyling?: {
    keepList: boolean
    hideHeader: boolean
    hideFooter: boolean
    hideBody: boolean
  }
  hidden?: boolean
  button?: true
  toolHtmlType?: TOOL_HTML_TYPE
  placeholder?: string
  secondaryAction?: {
    iconName: string
    tooltipText: string
  }
  progressCriteria?: () => boolean
}

export default class BuildPlanSidebarTools {
  static getBuildPlanTools(subType: ItemSubType, modality?: string): BuildPlanTool[] {
    let result: BuildPlanTool[] = []
    switch (subType) {
      case ItemSubType.None:
        switch (modality) {
          case PrintingTypes.DMLM:
            result = [
              {
                key: ToolNames.ADD_PART,
                routerName: RouterNames.EBP_AddPart,
                icon: 'mdi-cube-outline',
                divide: false,
                viewMode: ViewModeTypes.Part,
                jobTypes: [],
                waysToEnable: [],
                secondaryAction: {
                  iconName: 'mdi-plus-circle-outline',
                  tooltipText: i18n.t('addPartTool.addAndStayTool').toString(),
                },
              },
              {
                key: ToolNames.ORIENT,
                isPreview: true,
                routerName: RouterNames.EBP_Orient,
                icon: 'mdi-camera-control',
                divide: false,
                viewMode: ViewModeTypes.Orientation,
                jobTypes: [JobType.ORIENT],
                waysToEnable: [],
              },
              {
                key: ToolNames.SUPPORT,
                isPreview: true,
                routerName: RouterNames.EBP_Support,
                icon: 'mdi-window-open-variant',
                divide: false,
                viewMode: ViewModeTypes.Support,
                jobTypes: [],
                waysToEnable: [],
              },
              {
                key: ToolNames.CONSTRAIN,
                routerName: RouterNames.EBP_Constrain,
                icon: 'mdi-lock-outline',
                divide: false,
                viewMode: ViewModeTypes.Constrain,
                jobTypes: [],
                waysToEnable: [],
              },
              {
                key: ToolNames.DUPLICATE,
                routerName: RouterNames.EBP_Duplicate,
                icon: 'mdi-content-duplicate',
                divide: false,
                viewMode: ViewModeTypes.Duplicate,
                jobTypes: [],
                waysToEnable: [],
                progressCriteria: () => store.getters['visualizationModule/instancingIsRunning'],
              },
              {
                key: ToolNames.ARRANGE,
                routerName: RouterNames.EBP_Arrange,
                icon: 'mdi-arrange-send-to-back',
                divide: false,
                viewMode: ViewModeTypes.Nesting,
                jobTypes: [JobType.NEST],
                waysToEnable: [],
              },
              {
                key: ToolNames.SIMULATE,
                routerName: RouterNames.EBP_SimulateCompensate,
                icon: 'mdi-texture-box',
                divide: false,
                viewMode: ViewModeTypes.SimulationCompensation,
                jobTypes: [JobType.MESH, JobType.SIMULATE, JobType.COMPENSATE],
                waysToEnable: [],
              },
              {
                key: ToolNames.LABEL,
                routerName: RouterNames.EBP_Label,
                icon: 'mdi-tag-outline',
                divide: true,
                viewMode: ViewModeTypes.Marking,
                jobTypes: [],
                waysToEnable: [],
                secondaryAction: {
                  iconName: 'mdi-content-save-outline',
                  tooltipText: '',
                },
              },
              {
                key: ToolNames.SLICE_PUBLISH,
                routerName: null,
                icon: 'mdi-layers-triple',
                divide: true,
                viewMode: null,
                jobTypes: [JobType.SLICE],
                waysToEnable: [],
                hidden: false,
                button: true,
              },
              {
                key: ToolNames.PRINT_ORDER,
                routerName: RouterNames.EBP_Print,
                icon: 'mdi-printer-3d',
                divide: true,
                viewMode: ViewModeTypes.Print,
                jobTypes: [JobType.MARK, JobType.PRINT],
                waysToEnable: [],
                hidden: true,
                button: true,
              },
              {
                key: ToolNames.SELECT_PRINT_ORDER,
                routerName: null,
                icon: null,
                divide: true,
                viewMode: null,
                jobTypes: [],
                waysToEnable: [],
                hidden: true,
                toolHtmlType: TOOL_HTML_TYPE.SELECT,
                placeholder: 'printOrder',
              },
              {
                key: ToolNames.MOVE,
                routerName: RouterNames.EBP_Move,
                icon: 'mdi-cursor-default-outline',
                divide: false,
                viewMode: ViewModeTypes.Move,
                jobTypes: [],
                waysToEnable: [],
              },
              {
                key: ToolNames.ROTATE,
                routerName: RouterNames.EBP_Rotate,
                icon: 'mdi-rotate-orbit',
                divide: false,
                viewMode: ViewModeTypes.Rotate,
                jobTypes: [],
                waysToEnable: [],
              },
              {
                key: ToolNames.TRANSFER_PROPS,
                routerName: null,
                icon: 'mdi-transfer',
                divide: false,
                viewMode: ViewModeTypes.TransferProps,
                jobTypes: [],
                waysToEnable: [],
                sidebarStyling: {
                  keepList: true,
                  hideFooter: true,
                  hideHeader: true,
                  hideBody: true,
                },
                setViewMode: true,
              },

              // TODO: add these tools when they are implemented
              // {
              //   key: 'scale',
              //   routerName: RouterNames.EBP_Scale,
              //   icon: 'mdi-image-size-select-small',
              //   divide: false,
              //   viewMode: ViewModeTypes.Scale,
              //   jobTypes: [],
              //   waysToEnable: [],
              // },
            ]
            break
          case PrintingTypes.BinderJet:
            result = [
              {
                key: ToolNames.ADD_PART,
                routerName: RouterNames.EBP_AddPart,
                icon: 'mdi-cube-outline',
                divide: false,
                viewMode: ViewModeTypes.Part,
                jobTypes: [],
                waysToEnable: [],
                secondaryAction: {
                  iconName: 'mdi-plus-circle-outline',
                  tooltipText: i18n.t('addPartTool.addAndStayTool').toString(),
                },
              },
              {
                key: ToolNames.CONSTRAIN,
                routerName: RouterNames.EBP_Constrain,
                icon: 'mdi-lock-outline',
                divide: false,
                viewMode: ViewModeTypes.Constrain,
                jobTypes: [],
                waysToEnable: [],
              },
              {
                key: ToolNames.DUPLICATE,
                routerName: RouterNames.EBP_Duplicate,
                icon: 'mdi-content-duplicate',
                divide: false,
                viewMode: ViewModeTypes.Duplicate,
                jobTypes: [],
                waysToEnable: [],
                progressCriteria: () => store.getters['visualizationModule/instancingIsRunning'],
              },
              {
                key: ToolNames.LABEL,
                routerName: RouterNames.EBP_Label,
                icon: 'mdi-tag-outline',
                divide: true,
                viewMode: ViewModeTypes.Marking,
                jobTypes: [],
                waysToEnable: [],
                secondaryAction: {
                  iconName: 'mdi-content-save-outline',
                  tooltipText: '',
                },
              },
              {
                key: ToolNames.SLICE_PUBLISH,
                routerName: null,
                icon: 'mdi-layers-triple',
                divide: true,
                viewMode: null,
                jobTypes: [JobType.SLICE, JobType.EXPOSURE],
                waysToEnable: [],
                hidden: false,
                button: true,
                toolHtmlType: TOOL_HTML_TYPE.SLICE_AND_PUBLISH_MENU,
              },
              {
                key: ToolNames.PRINT_ORDER,
                routerName: RouterNames.EBP_Print,
                icon: 'mdi-printer-3d',
                divide: true,
                viewMode: ViewModeTypes.Print,
                jobTypes: [JobType.MARK, JobType.PRINT],
                waysToEnable: [],
                hidden: true,
                button: true,
              },
              {
                key: ToolNames.SELECT_PRINT_ORDER,
                routerName: null,
                icon: null,
                divide: true,
                viewMode: null,
                jobTypes: [],
                waysToEnable: [],
                hidden: true,
                toolHtmlType: TOOL_HTML_TYPE.SELECT,
                placeholder: 'printOrder',
              },
              {
                key: ToolNames.MOVE,
                routerName: RouterNames.EBP_Move,
                icon: 'mdi-cursor-default-outline',
                divide: false,
                viewMode: ViewModeTypes.Move,
                jobTypes: [],
                waysToEnable: [],
              },
              {
                key: ToolNames.ROTATE,
                routerName: RouterNames.EBP_Rotate,
                icon: 'mdi-rotate-orbit',
                divide: false,
                viewMode: ViewModeTypes.Rotate,
                jobTypes: [],
                waysToEnable: [],
              },
              {
                key: ToolNames.TRANSFER_PROPS,
                routerName: null,
                icon: 'mdi-transfer',
                divide: false,
                viewMode: ViewModeTypes.TransferProps,
                jobTypes: [],
                waysToEnable: [],
                sidebarStyling: {
                  keepList: true,
                  hideFooter: true,
                  hideHeader: true,
                  hideBody: true,
                },
                setViewMode: true,
              },
              {
                key: ToolNames.REPLACE,
                routerName: RouterNames.EBP_Replace,
                icon: 'mdi-swap-horizontal',
                divide: false,
                viewMode: ViewModeTypes.Replace,
                jobTypes: [],
                waysToEnable: [],
              },

              // TODO: add these tools when they are implemented
              // {
              //   key: 'scale',
              //   routerName: RouterNames.EBP_Scale,
              //   icon: 'mdi-image-size-select-small',
              //   divide: false,
              //   viewMode: ViewModeTypes.Scale,
              //   jobTypes: [],
              //   waysToEnable: [],
              // },
            ]
            break
        }
        break
      case ItemSubType.SinterPlan:
        result = [
          {
            key: ToolNames.ADD_PART,
            routerName: RouterNames.EBP_AddPart,
            icon: 'mdi-cube-outline',
            divide: false,
            viewMode: ViewModeTypes.Part,
            jobTypes: [],
            waysToEnable: [],
            secondaryAction: {
              iconName: 'mdi-plus-circle-outline',
              tooltipText: i18n.t('addPartTool.addAndStayTool').toString(),
            },
          },
          {
            key: ToolNames.ROTATE,
            routerName: RouterNames.EBP_Rotate,
            icon: 'mdi-rotate-orbit',
            divide: false,
            viewMode: ViewModeTypes.Rotate,
            jobTypes: [],
            waysToEnable: [],
          },
          {
            key: ToolNames.SIMULATE,
            routerName: RouterNames.EBP_SimulateCompensate,
            icon: 'mdi-texture-box',
            divide: true,
            viewMode: ViewModeTypes.SimulationCompensation,
            jobTypes: [JobType.SIMULATE, JobType.COMPENSATE],
            waysToEnable: [],
          },
          {
            key: ToolNames.PUBLISH,
            routerName: null,
            icon: 'mdi-publish',
            divide: false,
            viewMode: null,
            jobTypes: [JobType.PUBLISH],
            waysToEnable: [],
            button: true,
            hidden: false,
          },
          {
            key: ToolNames.SELECT_PUBLISHED_PARTS,
            routerName: null,
            icon: null,
            divide: false,
            viewMode: null,
            jobTypes: [],
            waysToEnable: [],
            hidden: true,
            toolHtmlType: TOOL_HTML_TYPE.MENU,
            placeholder: 'onePublishedPart',
          },
        ]
        break
    }
    return result
  }
}

export enum ToolNames {
  LAYOUT = 'layout',
  ADD_PART = 'addPart',
  ORIENT = 'orient',
  SUPPORT = 'support',
  CONSTRAIN = 'constrain',
  ARRANGE = 'arrange',
  SIMULATE = 'simulate',
  LABEL = 'label',
  SLICE_PUBLISH = 'slicePublish',
  PRINT_ORDER = 'printOrder',
  SELECT_PRINT_ORDER = 'selectPrintOrder',
  MOVE = 'move',
  ROTATE = 'rotate',
  TRANSFER_PROPS = 'transferProps',
  DUPLICATE = 'duplicate',
  PUBLISH = 'publish',
  SELECT_PUBLISHED_PARTS = 'selectPublishedParts',
  SLICE = 'slice',
  PRINT = 'print',
  MARK = 'mark',
  PART_PROPERTIES = 'partProperties',
  REPLACE = 'replace',
  BUILD_PLAN_PROPERTIES = 'buildPlanProperties',
  STABILITY = 'stability',
}

export enum WAYS_TO_ENABLE_TOOLS {
  TOOL_UNAVAILABLE,
  BUILD_PLAN_READ_ONLY,
  NO_PARTS,
  SELECT_ONE_PART,
  NO_PARAMETER_SET,
  INVALID_PARAMETER_SET_LAYER_THICKNESS,
  SIM_COM_NOT_ALLOWED_PART,
  SIM_COM_NOT_ALLOWED_SUPPORT,
  SELECT_PARTS,
  NO_SIM_COMP_RESULTS,
  NO_COMP_RESULTS_TO_PUBLISH,
  NO_IDENTICAL_PARTS,
  TOOL_INFO,
  SHEET_BODIES_INSIDE_BUILD_PLAN,
  BUILD_PLAN_LOCKED_FOR_VIEWER,
  NO_PRODUCTION_OR_COUPON_BODIES,
  PRODUCTION_BODIES_ONLY,
  AT_LEAST_ONE_PRODUCTION_BODY,
  SINTER_PLAN_READ_ONLY,
  TOOL_RECONNECT,
  PART_NOT_ORIENTED,
  NO_SUPPORTS_FOR_PART,
  LOCKED_WITH_LEGACY_LABELS,
  SELECT_PARTS_REPLACE,
  NO_MEASUREMENT_FILE,
  SINTER_PLAN_LOCKED_FOR_VIEWER,
  RESTORE_LABELS_IN_PROGRESS,
}

export const WAYS_TO_ENABLE_TOOLS_MAP = {
  0: 'toolInformationToolUnavailable',
  1: 'toolInformationBuildPlanReadOnly',
  2: 'toolInformationNoParts',
  3: 'toolInformationSelectOnePart',
  4: 'toolInformationNoParameterSet',
  5: 'toolInformationInvalidParameterSetLayerThickness',
  6: 'toolInformationSimComNotAllowedPart',
  7: 'toolInformationSimComNotAllowedSupport',
  8: 'toolInformationSelectPart',
  9: 'toolInformationNoSimCompResults',
  10: 'toolInformationNoCompResultsToPublish',
  11: 'toolInformationNoIdenticalParts',
  12: 'toolInformation',
  13: 'toolInformationSheetBodiesInsideBuildPlan',
  14: 'toolInformationBuildPlanLockedForViewer',
  15: 'toolInformationNoProductionOrCouponBodies',
  16: 'toolInformationProductionBodiesOnly',
  17: 'toolInformationAtLeastOneProductionBody',
  18: 'toolInformationSinterPlanReadOnly',
  19: 'toolReconnect',
  20: 'toolInformationPartNotOriented',
  21: 'toolInformationNoSupportsForPart',
  22: 'toolInformationLockedWithLegacyLabels',
  23: 'toolInformationSelectPartForReplace',
  24: 'toolInformationNoMeasurementsFile',
  25: 'toolInformationSinterPlanLockedForViewer',
}

export enum TOOL_HTML_TYPE {
  MENU = 'menu',
  SELECT = 'select',
  SLICE_AND_PUBLISH_MENU = 'slice_menu',
}
