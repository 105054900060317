enum StoresNamespaces {
  User = 'user',
  Jobs = 'jobs',
  Common = 'common',
  CostEstimation = 'costEstimation',
  Visualization = 'visualizationModule',
  SinglePartVisualization = 'singlePartVisualizationModule',
  Folders = 'folders',
  Parts = 'parts',
  BuildPlans = 'buildPlans',
  FileExplorer = 'fileExplorer',
  Notifications = 'notifications',
  Announcements = 'announcements',
  CommandManager = 'commandManager',
  CadHelper = 'cadHelper',
  Sites = 'sites',
  Labels = 'label',
  OptionalMultiItemCollector = 'optionalMultiItemCollector',
}

export default StoresNamespaces
