import StoresNamespaces from '@/store/namespaces'
import { IBuildPlan } from '@/types/BuildPlans/IBuildPlan'
import { PrintingTypes } from '@/types/IMachineConfig'
import { IMarkTemplate } from '@/types/Marking/IMarkTemplate'
import { convertMillimeterToPixel } from '@/utils/number'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component
export default class LabelSidebarMixin extends Vue {
  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan

  getDepthValue(style: IMarkTemplate) {
    if (this.buildPlan.modality.toUpperCase() === PrintingTypes.DMLM) {
      return style.textExtrusionAboveSurface
    }
    return style.textExtrusionBelowSurface
  }

  markTextContentBoxClass(fontName: string, fontTargetSize: number, fontStyle: number) {
    return {
      fontFamily: fontName,
      fontSize: `${convertMillimeterToPixel(fontTargetSize)}px`,
      fontStyle: fontStyle === 2 || fontStyle === 3 ? 'italic' : 'normal',
      fontWeight: fontStyle === 1 || fontStyle === 3 ? 'bold' : 'normal',
      width: '214px',
      textOverflow: 'ellipsis',
      overflow: 'hidden !important',
      whiteSpace: 'nowrap',
      display: 'inline-block',
      lineHeight: 1,
      textAlign: 'center',
    }
  }

  numberOfChars(style: IMarkTemplate) {
    return style.textContent.length
  }

  scrollItemIntoView(itemId: string) {
    if (document.getElementById(itemId)) {
      document.getElementById(itemId).scrollIntoView({ block: 'nearest', behavior: 'smooth' })
    } else {
      setTimeout(this.scrollItemIntoView, 200, itemId)
    }
  }
}
