
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class NoSimResults extends Vue {
  @Prop({ default: '' }) label: string
  @Prop({ default: false }) showLoading: boolean
}
