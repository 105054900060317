
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { DefectShape } from '@/visualization/models/DataModel'
import { PartDefect } from '@/types/Parts/IPartInsight'
import { DefectInsightMixin } from '@/components/layout/parts/insight/mixins/DefectInsightMixin'

@Component({
  components: {},
})
export default class PartDefectByInsight extends DefectInsightMixin {
  @Prop() defectType: PartDefect
  @Prop() defectIndex: number
  @Prop() shape: DefectShape
  @Prop() shapeIndex: number

  get shapeName() {
    return this.shape.cadID && this.shape.cadID.length !== 0
      ? `${this.shape.type.toString()} ${this.shape.cadID}`
      : `${this.$t('shape').toString()} ${this.shapeIndex + 1}`
  }

  isDefectShapeHovered() {
    return (
      this.hoveredDefect !== null &&
      this.hoveredDefect.type === this.defectType &&
      Boolean(
        this.hoveredDefect.indices.find(
          (ind) => ind.defectIndex === this.defectIndex && ind.shapeIndex === this.shapeIndex,
        ),
      )
    )
  }

  isDefectShapeSelected() {
    return (
      this.selectedDefect !== null &&
      this.selectedDefect.type === this.defectType &&
      Boolean(
        this.selectedDefect.indices.find(
          (ind) => ind.defectIndex === this.defectIndex && ind.shapeIndex === this.shapeIndex,
        ),
      )
    )
  }

  hoverIntoDefectShape() {
    const hoverableDefect = { defectType: this.defectType, defectIndex: this.defectIndex, shapeIndex: this.shapeIndex }
    this.setHoveredDefect(hoverableDefect)
    this.toggleDefectsHighlight({ defect: this.hoveredDefect, showHighlight: true })
  }

  hoverOutOfDefectShape() {
    this.toggleDefectsHighlight({ defect: this.hoveredDefect, showHighlight: false })
    this.setHoveredDefect(null)
  }

  selectDefectShape() {
    const defect = { defectType: this.defectType, defectIndex: this.defectIndex, shapeIndex: this.shapeIndex }
    if (this.isDefectShapeSelected()) {
      this.removeSelectedDefect(defect)
      this.selectDefects({
        defect: { type: this.defectType, indices: [{ defectIndex: this.defectIndex, shapeIndex: this.shapeIndex }] },
        deselectIfSelected: true,
      })
      this.hoverIntoDefectShape()
    } else {
      this.addSelectedDefect(defect)
      this.selectDefects({
        defect: { type: this.defectType, indices: [{ defectIndex: this.defectIndex, shapeIndex: this.shapeIndex }] },
        deselectIfSelected: false,
      })
    }
  }
}
