import Router from 'vue-router'
import { RawLocation } from 'vue-router/types/router'
import isURL from 'validator/lib/isURL'
import { RouterPaths } from '@/router'

const isURLOptions = {
  protocols: ['http', 'https'],
  require_protocol: true,
  require_valid_protocol: true,
  allow_protocol_relative_urls: false,
  require_tld: true,
  require_host: true,
  require_port: false,
  allow_underscores: false,
  host_whitelist: false,
  host_blacklist: false,
  allow_trailing_dot: false,
  disallow_auth: false,
  validate_length: true,
}

export default class VueRouter extends Router {
  async safePush(location: RawLocation) {
    // if location is string we can validate if it's a valid URL
    if (typeof location === 'string' && !this.isValidPathOrURL(location)) {
      return
    }
    // otherwise it's of type Location, for which Vue Router
    // does its own validation and error checking
    return this.push(location).catch((error) => {
      if (error && error.name === 'NavigationDuplicated') {
        return
      }
    })
  }

  private isValidPathOrURL(str: string) {
    return Object.values(RouterPaths).includes(str as RouterPaths) || isURL(str, isURLOptions)
  }
}
