import { TranslateResult } from 'vue-i18n'
import { IItemTypeFilterValue } from './ItemType'

export enum FilterParamsKey {
  AllFiles = 'allFilesFilter',
  Favorites = 'favoritesFilter',
  Recent = 'recentFilter',
  Shared = 'sharedFilter',
  Trash = 'trashFilter',
  PrintOrdersStatus = 'printOrdersStatusFilter',
  PrintOrdersMaterial = 'printOrdersMaterialFilter',
  PrintOrdersMachine = 'printOrdersMachineFilter',
  PrintOrdersParameter = 'printOrdersParameterFilter',
  PrintOrdersBuildPlate = 'printOrdersBuildPlateFilter',
  SearchPrintOrdersStatus = 'searchPrintOrdersStatusFilter',
  SearchPrintOrdersMaterial = 'searchPrintOrdersMaterialFilter',
  SearchPrintOrdersMachine = 'searchPrintOrdersMachineFilter',
  SearchPrintOrdersParameter = 'searchPrintOrdersParameterFilter',
  SearchPrintOrdersBuildPlate = 'searchPrintOrdersBuildPlateFilter',
  Search = 'searchFilter',
  JobTypes = 'jobTypesFilter',
}

export type ItemTypeFilterParams = {
  value: IItemTypeFilterValue | string | number
  name: string | TranslateResult
  testClass: string
}

export type FilterBy = {
  field: string
  value: Array<IItemTypeFilterValue | any>
}

export type TableFilterParams = {
  [key in FilterParamsKey]: FilterBy
}

export enum ItemTypeFilterName {
  BuildPart = 'buildPart',
  BuildPlan = 'buildPlan',
  Folder = 'folder',
  PrintOrder = 'printOrder',
  SinterPlan = 'sinterPlan',
  SinterPart = 'sinterPart',
}
