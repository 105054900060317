
/**
 * Alert Modal component
 *
 * Insert component where you want to use it:
 * <AlertModal ref="alert"></AlertModal>
 *
 * Call it:
 * if (await this.$refs.alert.open('Title', 'Message', { width: 200 })) {
 *   // submitted
 * }
 * else {
 *   // canceled
 * }
 */
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { LocaleMessages } from 'vue-i18n'

import Button from '@/components/controls/Common/Button.vue'

@Component({
  components: {
    Button,
  },
})
export default class AlertModal extends Vue {
  @Prop({ default: 'Ok' }) submitLabel?: string

  isShow = false
  title = null
  message = null
  resolve = null

  options = {
    width: 475,
    zIndex: 200,
    center: false,
  }

  open(title: string | LocaleMessages, message: string | LocaleMessages, options?: object) {
    this.isShow = true
    this.title = title
    this.message = message
    this.options = Object.assign(this.options, options)
    return new Promise<boolean>((resolve) => {
      this.resolve = resolve
    })
  }

  cancel() {
    this.isShow = false
    this.resolve(false)
  }

  submit() {
    this.isShow = false
    this.resolve(true)
  }
}
