
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import VTextField from 'vuetify/lib/components/VTextField'
import Icon from '@/components/icons/Icon.vue'
import { debounce } from '@/utils/debounce'

@Component({
  components: {
    Icon,
  },
})
export default class SearchField extends Vue {
  @Prop() placeholder!: string
  @Prop({ type: Number, default: 32 }) readonly height: number
  @Prop({ default: false }) isDisabled: boolean
  @Prop({ default: '' }) initialValue: string
  value: string = ''

  inputDebounced = debounce(1000, this.input)

  $refs!: {
    textField: VTextField
  }

  beforeMount() {
    this.value = this.initialValue
  }

  input() {
    this.$emit('input', this.value)
  }

  resetSearchValue() {
    this.value = ''
  }

  clear() {
    this.resetSearchValue()
    this.input()
  }

  focus() {
    this.$refs.textField.focus()
  }
}
