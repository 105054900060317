import { Commit, Dispatch } from 'vuex'
import { Command } from '@/types/UndoRedo/Command'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { IBuildPlanItem, IUpdateBuildPlanItemParamsDto } from '@/types/BuildPlans/IBuildPlan'
import { IRepeatBuildPlanItemParams } from './IRepeatBuildPlanItemParams'

const UPDATE_BUILD_PLAN_ITEM_ACTION_NAME = 'buildPlans/updateBuildPlanItem'

export class ChangePartPropertiesCommand extends Command implements ICommand {
  constructor(
    public readonly commandType: CommandType,
    private readonly buildPlanItem: IBuildPlanItem,
    private readonly repeatBuildPlanItemParams: IRepeatBuildPlanItemParams,
    protected dispatch: Dispatch,
    protected commit: Commit,
  ) {
    super()

    this.repeatBuildPlanItemParams = repeatBuildPlanItemParams
    this.commandType = commandType
    this.buildPlanItem = buildPlanItem
  }

  async undo(): Promise<void> {
    this.deselectItems()
    this.selectAndHighlightParts([this.buildPlanItem])
    this.applyBodyProperty(this.repeatBuildPlanItemParams.before)
  }

  async redo(): Promise<void> {
    this.deselectItems()
    this.selectAndHighlightParts([this.buildPlanItem])
    this.applyBodyProperty(this.repeatBuildPlanItemParams.after)
  }

  private applyBodyProperty(updateBPItemParamsDto: IUpdateBuildPlanItemParamsDto): void {
    this.dispatch(UPDATE_BUILD_PLAN_ITEM_ACTION_NAME, { params: updateBPItemParamsDto }, this.rootLevel)
  }
}
