
import StoresNamespaces from '@/store/namespaces'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const commonStore = namespace(StoresNamespaces.Common)

@Component
export default class SimResultsHeader extends Vue {
  @Prop({ default: '' }) label: string
  @Prop({ default: false }) showLoading: boolean

  @commonStore.Getter tooltipOpenDelay: number
}
