import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ActionBarMixin from '@/components/layout/FileExplorer/Table/mixins/ActionBarMixin'
import EndCollaborationMixin from '@/components/layout/FileExplorer/Table/mixins/EndCollaborationMixin'
import { ActionDateByUserMixin } from '@/components/layout/mixins/ActionDateByUserMixin'
import { ROOT_PATH_LABEL } from '@/constants'
import i18n from '@/plugins/i18n'
import StoresNamespaces from '@/store/namespaces'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { IItemClick } from '@/types/FileExplorer/IItemClick'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import { ViewMode } from '@/types/FileExplorer/ViewMode'
import { PrintOrder } from '@/types/PrintOrder/PrintOrderFE'
import { isOfType } from '@/utils/common'
import { getItemTypeName } from '@/utils/fileExplorerItem/fileExplorerItemUtils'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component
export default class TableRowMixin extends Mixins(ActionBarMixin, EndCollaborationMixin, ActionDateByUserMixin) {
  @fileExplorerStore.Getter getViewMode: ViewMode

  @fileExplorerStore.Mutation selectItem: (payload: { item: FileExplorerItem; selectionType: SelectionTypes }) => void

  @Prop() item: FileExplorerItem | PrintOrder

  viewModes = ViewMode

  get itemIcon(): string {
    if (isOfType<PrintOrder>(this.item, 'site')) {
      return 'mdi-printer-3d'
    }

    const item = this.item as FileExplorerItem
    if (item.subType) {
      switch (item.subType) {
        case ItemSubType.SinterPlan:
          return 'mdi-fire'

        case ItemSubType.SinterPart:
          return 'mdi-cube'

        default:
          return
      }
    }

    switch (item.itemType) {
      case ItemType.Folder:
        return 'mdi-folder-open'

      case ItemType.BuildPlan:
        return 'mdi-newspaper-variant'

      case ItemType.BuildPart:
        return 'mdi-cube-outline'

      // IBC should be treated as SBC in file explorer
      case ItemType.IbcPlan:
        return 'mdi-fire'

      default:
        return
    }
  }
  get itemColor(): string {
    if (isOfType<PrintOrder>(this.item, 'site')) {
      return '#5DA492'
    }

    const item = this.item as FileExplorerItem
    if (item.subType) {
      switch (item.subType) {
        case ItemSubType.SinterPlan:
          return '#E86B5F'

        case ItemSubType.SinterPart:
          return '#E86B5F'

        default:
          return
      }
    }

    switch (item.itemType) {
      case ItemType.Folder:
        return '#EDA600'

      case ItemType.BuildPlan:
        return '#416AAA'

      case ItemType.BuildPart:
        return '#63666A'

      // IBC should be treated as SBC in file explorer
      case ItemType.IbcPlan:
        return '#E86B5F'

      default:
        return
    }
  }

  get itemType(): string {
    return getItemTypeName(this.item as FileExplorerItem)
  }

  get isSelected(): boolean {
    return this.getSelectedItems.some((item) => item.id === this.item.id)
  }

  async onItemNameClick() {
    this.openItem(this.item as FileExplorerItem)
  }

  async onItemPathClick() {
    this.openItemFolder(this.item.id)
  }

  isFolderOfItemRemoved(item: FileExplorerItem) {
    return item.isParentFolderRemoved || false
  }

  getParentPathTitle(item: FileExplorerItem) {
    return `${this.parentPathLabel}${this.isFolderOfItemRemoved(item) ? ` (${i18n.t('removed')})` : ''}`
  }

  get isListView(): boolean {
    return this.getViewMode === ViewMode.List
  }

  get parentPathLabel(): string {
    const item = this.item as FileExplorerItem
    return item.parentPathLabel ? `${ROOT_PATH_LABEL}${item.parentPathLabel}` : ROOT_PATH_LABEL
  }

  @Emit('onItemClick')
  itemClick(event): IItemClick {
    const { ctrlKey, shiftKey, metaKey } = event
    return { ctrlKey, shiftKey, metaKey, item: this.item }
  }
}
