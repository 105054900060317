
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Prop, Mixins } from 'vue-property-decorator'

import ActionBarMixin from '@/components/layout/FileExplorer/Table/mixins/ActionBarMixin'

import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { JobDto, JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { DetailsPanelViewMode } from '@/types/FileExplorer/ViewMode'

import StoresNamespaces from '@/store/namespaces'
import messageService from '@/services/messageService'
import { RouterNames } from '@/router'
import { jobTypeToNameForDashboard } from '@/utils/string'
import { formatDateWithDefaultFormat } from '@/utils/common'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component({
  components: {},
  filters: {
    formatDate(date: string) {
      return formatDateWithDefaultFormat(date)
    },
  },
})
export default class UserJobTableRow extends Mixins(ActionBarMixin) {
  @fileExplorerStore.Action openItemFolder: (itemId: string) => Promise<void>

  @fileExplorerStore.Getter getSelectedItem: FileExplorerItem

  @buildPlansStore.Action fetchRelatedBuildPlanVariants: (variantId: string) => Promise<void>

  @Prop({ required: true }) job: JobDto

  hover = false

  get jobTypeIconName(): string {
    const { itemType, itemSubType } = this.job

    if (itemSubType) {
      switch (itemSubType) {
        case ItemSubType.SinterPlan:
          return 'mdi-fire'

        case ItemSubType.SinterPart:
          return 'mdi-cube'
      }
    }

    switch (itemType) {
      case ItemType.Folder:
        return 'mdi-folder-open'

      case ItemType.BuildPlan:
        return 'mdi-newspaper-variant'

      case ItemType.BuildPart:
        return 'mdi-cube-outline'

      case ItemType.PrintOrder:
        return 'mdi-television-guide'

      default:
        return 'mdi-help-circle-outline'
    }
  }

  get jobProgress(): number {
    switch (this.job.code) {
      case JobStatusCode.QUEUED:
        return 20
      case JobStatusCode.PENDING:
        return 30
      case JobStatusCode.RUNNING:
        return 60
      default:
        return 0
    }
  }

  get jobType(): string {
    return jobTypeToNameForDashboard(this.job.jobType)
  }

  get isJobCancellable(): boolean {
    return [JobStatusCode.QUEUED, JobStatusCode.RUNNING].includes(this.job.code)
  }

  get jobItemLocation(): string {
    return this.job.itemParentPathLabel
      ? `${this.$t('allFiles')}${this.job.itemParentPathLabel}`
      : this.$t('allFiles').toString()
  }

  get jobItemVariant(): string {
    if (this.job.jobType === JobType.IMPORT) {
      return ''
    }
    return this.job.itemVersionLabel || `${this.$i18n.t('variant')} ${this.job.itemVersion}`
  }

  async onItemNameClick() {
    await this.fetchRelatedBuildPlanVariants(this.job.itemId)

    const hasRunningImportJob =
      this.job.jobType === JobType.IMPORT && [JobStatusCode.QUEUED, JobStatusCode.RUNNING].includes(this.job.code)

    if (hasRunningImportJob) {
      const errMsg = `${this.$i18n.t('importSnackbarMsg')}`
      messageService.showErrorMessage(errMsg)
      return
    }

    if (this.job.jobType === JobType.IMPORT) {
      this.openPartPreview()
    } else if (this.job.jobType === JobType.PUBLISH && this.job.itemParentId) {
      // 'job.itemId' contains the ID of Sinter Part,
      // it's neccesary to use 'job.itemParentId' which contains the ID of Sinter Plan
      this.openBuildPlan(this.job.itemParentId)
    } else {
      this.openBuildPlan(this.job.itemId)
    }
  }

  openPartPreview() {
    const route = {
      name: RouterNames.PartPreview,
      params: {
        id: this.job.itemId,
      },
    }
    // @ts-ignore
    this.$router.safePush(route)
    this.setDetailsPanelMode(DetailsPanelViewMode.Part)
  }

  onItemPathClick() {
    this.openItemFolder(this.job.itemId)
  }

  onCancelJobClick() {
    if (this.isJobCancellable) {
      this.$emit('job-cancel')
    }
  }
}
