
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { BrokerEvents } from '@/types/Common/BrokerEvents'
import { BrokerMessage } from '@/types/Common/BrokerMessage'
import { CadHelperLink } from '@/types/CadHelper/CadHelperLink'
import { ISocketError } from '@/types/Common/ISocketError'
import Button from '@/components/controls/Common/Button.vue'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import CommunicationService from '@/services/CommunicationService'
import messageService from '@/services/messageService'

const CAD_HELPER_WAIT_TIMEOUT = 6000

const userStore = namespace(StoresNamespaces.User)

@Component({
  components: {
    Button,
  },
})
export default class CadHelperRunner extends Vue {
  @userStore.Getter('getUserDetails') getUserDetails: any

  @Prop() cadHelperLink: CadHelperLink
  @Prop() onMessage: (brokerMessage: BrokerMessage) => void
  @Prop() onError: (error: ISocketError) => void
  @Prop() onConnected: () => void
  @Prop() onTimeOut: () => void
  @Prop() onCadHelperRunnerClick: () => void
  @Prop() disabled: boolean
  @Prop({ default: 'cad-helper-runner mchNoDecorate' }) buttonStyle: string
  @Prop({ default: true }) block: boolean
  @Prop() nonWinOsErrorMessage: string

  allowUnsavedChangesDialog: boolean = true
  isWaitingForCadHelper: boolean = false
  downloadCadHelperDialogVisible: boolean = false

  connector: CommunicationService = null

  cadHelperWaitTimeout: NodeJS.Timeout

  beforeMount() {
    this.connector = CommunicationService.getConnector()
    this.connector.subscribe(BrokerEvents.CadHelperStarted, this.onCadHelperRunnerStarted)
  }

  beforeDestroy() {
    this.connector.unsubscribe(BrokerEvents.CadHelperStarted, this.onCadHelperRunnerStarted)
  }

  onCadHelperRunnerStarted(message) {
    this.stopWaitingForCadHelper()
    if (this.onMessage) {
      this.onMessage(message)
    }
  }

  waitingForCadHelper() {
    this.isWaitingForCadHelper = true
    this.cadHelperWaitTimeout = setTimeout(() => {
      this.isWaitingForCadHelper = false

      if (this.onTimeOut) {
        this.onTimeOut()
      }
    }, CAD_HELPER_WAIT_TIMEOUT)
  }

  get isWindowsOs() {
    const userAgentString = navigator.userAgent
    // detect if os is WinOs
    return (
      userAgentString.toLowerCase().indexOf('win') !== -1 &&
      // check if its not Windows Mobile
      userAgentString.toLowerCase().indexOf('mobile') === -1 &&
      userAgentString.toLowerCase().indexOf('phone') === -1
    )
  }

  get isFirefox(): boolean {
    return navigator.userAgent.indexOf('Firefox') !== -1
  }

  get getCadHelperLink() {
    if (this.isWindowsOs && !this.isFirefox) {
      return this.cadHelperLink
    }

    return null
  }

  async onExtractDataClick(event) {
    if (!this.isWindowsOs) {
      messageService.showWarningMessage(this.nonWinOsErrorMessage)
      return
    }

    if (!this.getUserDetails) {
      event.preventDefault()
      return
    }

    if (this.isFirefox) {
      // Special workaround for Firefox
      // Bug: Websocket closed when a custom URL Scheme is clicked by a user
      // https://bugzilla.mozilla.org/show_bug.cgi?id=896666
      // https://bugzilla.mozilla.org/show_bug.cgi?id=903171

      const iframe = document.createElement('iframe')
      iframe.src = this.cadHelperLink.toString()
      iframe.style.display = 'none'
      document.body.appendChild(iframe)
      iframe.remove()
    }

    this.isWaitingForCadHelper = true
    this.waitingForCadHelper()

    if (this.onCadHelperRunnerClick) {
      this.onCadHelperRunnerClick()
    }
  }

  stopWaitingForCadHelper() {
    clearTimeout(this.cadHelperWaitTimeout)
    this.isWaitingForCadHelper = false
  }
}
