/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { TransformNode } from '@babylonjs/core/Meshes'
import { BoundingBox } from '@babylonjs/core/Culling/boundingBox'
import { RenderScene } from '../render-scene'
import {
  IGNORED_MESH_NAMES,
  IGNORED_MESH_NAMES_EXCLUDING_KOZ,
  BuildVolumeLimitZoneType,
  MAX_SAFE_DOSING_HEIGHT_MM,
  H2_MACHINE_CONFIG_NAME,
} from '@/constants'
import { VisualizationEvent } from '../infrastructure/IVisualizationEvent'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { PrintingTypes } from '@/types/IMachineConfig'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'
import { occasionalSleeper } from '@/utils/common'
import { ConvexHull } from '@/visualization/rendering/ConvexHull'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import { OBBTree } from '@/visualization/OBBTree'
import store from '@/store'
import { IPartMetadata } from '@/visualization/types/SceneItemMetadata'

interface ICollisionNode {
  mesh: TransformNode
  floating: boolean
  aboveSafeDosingHeight: boolean
  colliding: boolean
  collidingWith: TransformNode[]
}

export class CollisionEventArgs {
  buildPlanItemId: string
  name: string

  constructor(buildPlanItemId: string, name: string = null) {
    this.buildPlanItemId = buildPlanItemId
    this.name = name
  }
}

export class CollisionManager {
  private readonly onSelectedElementsCollisions = new VisualizationEvent<CollisionEventArgs[]>()
  private selectedPartCollisions: CollisionEventArgs[]
  private collisionCheckThrottle: NodeJS.Timeout

  get selectedElementsCollisions() {
    return this.onSelectedElementsCollisions.expose()
  }

  async markCollidingItems(
    renderScene: RenderScene,
    selectedMeshes?: TransformNode[],
    silent?: boolean,
    returnInsights?: boolean,
    enableSleeper?: boolean,
  ): Promise<void> {
    // turn off collision checking for sinter plans
    if (renderScene.buildPlanType === ItemSubType.SinterPlan) {
      return
    }

    if (this.collisionCheckThrottle) {
      clearTimeout(this.collisionCheckThrottle)
    }

    if (silent) {
      this.collisionHelper({ renderScene, selectedMeshes, silent, returnInsights, enableSleeper })
    } else {
      const collisionMgr = renderScene.getCollisionManager()
      this.collisionCheckThrottle = setTimeout(collisionMgr.collisionHelper, 100, {
        renderScene,
        selectedMeshes,
        silent,
        returnInsights,
        enableSleeper,
      })
    }
  }

  private async collisionHelper(payload: {
    renderScene: RenderScene
    selectedMeshes?: TransformNode[]
    silent?: boolean
    returnInsights?: boolean
    enableSleeper?: boolean
  }) {
    const renderScene = payload.renderScene
    const selectedMeshes = payload.selectedMeshes
    const collidingItems = []
    const scene = renderScene.getScene()
    const obbTree = renderScene.getObbTree()
    const meshes = scene.transformNodes.concat(scene.meshes)
    const meshManager = renderScene.getMeshManager()
    const collisionMgr = renderScene.getCollisionManager()
    const insightMgr = renderScene.getInsightsManager()
    collisionMgr.selectedPartCollisions = []
    const buildPlateManager = renderScene.getBuildPlateManager()
    const buildPlateSizes = buildPlateManager.getBuildPlateSizes()

    // refresh cached hull binfo for all meshes (and their supports)
    meshes.forEach((mesh) => {
      if (mesh.metadata && mesh.metadata.hull) {
        mesh.computeWorldMatrix(true)
        mesh.metadata.hullBInfo = meshManager.getHullBInfo(mesh)

        const supportMesh = mesh.getChildTransformNodes(true).find((m) => meshManager.isSupportMesh(m))
        if (supportMesh) {
          supportMesh.metadata.hullBInfo = meshManager.getHullBInfo(supportMesh)
        }
      }
    })

    // get the meshes which are actually representing the parts
    const meshObj = meshes.reduce((filtered: ICollisionNode[], mesh) => {
      if (mesh.metadata && mesh.metadata.bvh) {
        filtered.push({
          mesh,
          floating: false,
          aboveSafeDosingHeight: false,
          colliding: false,
          collidingWith: [],
        })
      }
      return filtered
    }, [])
    const selectedCollisionNodes =
      selectedMeshes && selectedMeshes.length
        ? meshObj.filter((item) => selectedMeshes.some((selectedItem) => item.mesh.id === selectedItem.id))
        : null

    const itemMeshes = meshes.reduce((filtered, mesh) => {
      if (!IGNORED_MESH_NAMES.includes(mesh.name) && mesh.metadata && mesh.metadata.hull) {
        filtered.push({
          mesh,
        })
      }
      return filtered
    }, [])
    const firstLegacyItem = itemMeshes.find((itemMesh) => obbTree.isItemLegacy(itemMesh.mesh))
    const isBuildPlanLegacy = firstLegacyItem !== undefined

    // mesh-mesh collision and bolt holes
    // If user drag only small amount of buildPlanItems
    // no need to check collison between all pairs of buildPlanItems
    if (!selectedCollisionNodes) {
      await collisionMgr.manyToManyCollisionHelper(
        meshObj,
        meshManager,
        obbTree,
        payload.enableSleeper,
        isBuildPlanLegacy,
      )
    } else {
      await collisionMgr.oneToManyCollisionHelper(
        meshObj,
        selectedCollisionNodes,
        meshManager,
        obbTree,
        payload.enableSleeper,
        isBuildPlanLegacy,
      )
    }

    // containment checks for part/ support meshes
    const sideBuildVolumeLimitZoneMesh = meshObj.find(
      (mesh) =>
        meshManager.isBuildVolumeLimitZoneMesh(mesh.mesh) && mesh.mesh.metadata.type === BuildVolumeLimitZoneType.Side,
    )
    const topBuildVolumeLimitZoneMesh = meshObj.find(
      (mesh) =>
        meshManager.isBuildVolumeLimitZoneMesh(mesh.mesh) && mesh.mesh.metadata.type === BuildVolumeLimitZoneType.Top,
    )
    const yShiftSize = renderScene.yShiftSize
    for (const meshObjElement of selectedCollisionNodes ? selectedCollisionNodes : meshObj) {
      if (IGNORED_MESH_NAMES.includes(meshObjElement.mesh.name)) {
        continue
      }
      const mesh = meshObjElement.mesh
      if (!mesh.metadata) {
        continue
      }

      const buildPlanItemId = mesh.metadata.buildPlanItemId
      // exclude collision checks between items with the same buildPlanItemId
      // (e.g. part and its supports) and a build volume limit zone
      const bpCollisionWithBVZ = meshObj.find(
        (m) =>
          m.mesh.metadata &&
          m.mesh.metadata.buildPlanItemId === buildPlanItemId &&
          m.collidingWith.find((o) => meshManager.isBuildVolumeLimitZoneMesh(o)),
      )
      const bvzCollisionWithBP = meshObj.find(
        (m) =>
          meshManager.isBuildVolumeLimitZoneMesh(m.mesh) &&
          m.collidingWith.find((o) => o.metadata && o.metadata.buildPlanItemId === buildPlanItemId),
      )
      if (bpCollisionWithBVZ || bvzCollisionWithBP) {
        continue
      }
      if (mesh.metadata && mesh.metadata.hullBInfo) {
        const aabbPoints = mesh.metadata.hullBInfo.boundingBox.vectorsWorld
        const aabbContained = obbTree.pointsInside(buildPlateSizes, aabbPoints, yShiftSize)
        const aabbBelowBuildHeight = obbTree.pointsBelowBuildHeight(buildPlateSizes, aabbPoints)
        if (!aabbContained) {
          if (mesh.metadata && mesh.metadata.hull) {
            mesh.computeWorldMatrix(true)
            const trf = mesh.getWorldMatrix()
            const hull = mesh.metadata.hull as ConvexHull
            if (hull.hullPoints.length) {
              const hullContained = obbTree.transformedPointsInside(buildPlateSizes, hull.hullPoints, trf, yShiftSize)
              if (!hullContained) {
                meshObjElement.colliding = true
                meshObjElement.collidingWith.push(sideBuildVolumeLimitZoneMesh.mesh)
                sideBuildVolumeLimitZoneMesh.colliding = true
                sideBuildVolumeLimitZoneMesh.collidingWith.push(meshObjElement.mesh)
              }
            }
          }
        }
        if (!aabbBelowBuildHeight) {
          meshObjElement.colliding = true
          meshObjElement.collidingWith.push(topBuildVolumeLimitZoneMesh.mesh)
          topBuildVolumeLimitZoneMesh.colliding = true
          topBuildVolumeLimitZoneMesh.collidingWith.push(meshObjElement.mesh)
        }
      }
    }

    ;(selectedCollisionNodes ? selectedCollisionNodes : meshObj).forEach((obj) => {
      if (renderScene.modality === PrintingTypes.DMLM && meshManager.isPartMesh(obj.mesh)) {
        const meshMinZ = obj.mesh.metadata.hullBInfo.boundingBox.minimumWorld.z
        const buildPlateMaxZ = renderScene.getBuildPlateManager().groundBox.getBoundingInfo().boundingBox.maximumWorld.z
        const maxDistanceFromPlate = obj.mesh.metadata.maxDistanceFromPlate
        if (meshMinZ - buildPlateMaxZ - maxDistanceFromPlate > Number.EPSILON) {
          obj.floating = true
        }
      }

      if (
        renderScene.buildPlanType !== ItemSubType.SinterPlan &&
        renderScene.machineConfig === H2_MACHINE_CONFIG_NAME &&
        meshManager.isPartMesh(obj.mesh)
      ) {
        const meshMaxZ = obj.mesh.metadata.hullBInfo.boundingBox.maximumWorld.z
        const buildPlateMaxZ = renderScene.getBuildPlateManager().groundBox.getBoundingInfo().boundingBox.maximumWorld.z
        const safeDosingMaxZ = buildPlateMaxZ + MAX_SAFE_DOSING_HEIGHT_MM
        if (meshMaxZ - safeDosingMaxZ >= Number.EPSILON) {
          obj.aboveSafeDosingHeight = true
        }
      }

      if (obj.colliding) {
        collidingItems.push(obj.mesh)

        if (selectedMeshes) {
          // create messages
          obj.collidingWith.forEach((mesh) => {
            collisionMgr.findCollision(obj.mesh, mesh, renderScene, selectedMeshes)
          })
        }
      }
    })

    const insights: IBuildPlanInsight[] = await insightMgr.buildInsights(
      meshObj.filter((m) => m.floating || m.aboveSafeDosingHeight || (m.colliding && m.collidingWith)),
    )

    if (selectedCollisionNodes) {
      // If there are selected meshes
      // collision detection is performed only between selected meshes and BP items
      // so new insights will contain info only about collisions of selected items
      // and we need to get back old insights that are not related to selected items
      const ids = selectedCollisionNodes.map((item) => (item.mesh.metadata as IPartMetadata).buildPlanItemId)
      insightMgr.deleteCollisionInsights(ids, payload.silent)
    }

    if (collidingItems.length > 0) {
      renderScene.selectedElementsCollisions.trigger(collisionMgr.selectedPartCollisions)
    }
    renderScene.animate(true)

    if (payload.returnInsights) {
      return insights
    }

    if (selectedCollisionNodes) {
      store.dispatch('buildPlans/createInsightMultiple', { insights, stateOnly: payload.silent })
    } else {
      insightMgr.reportInsights([{ insights, tool: ToolNames.LAYOUT, stateOnly: payload.silent }])
    }
  }

  /**
   * Calculates collisions between all unique pairs of bp items
   * @param meshObjs array of objects that contains mesh and collision/floating info
   * @param meshManager mesh manager
   * @param obbTree obb tree
   * @param enableSleeper enable sleeper
   * @param isBuildPlanLegacy is BuildPlan legacy
   */
  private async manyToManyCollisionHelper(
    meshObj: ICollisionNode[],
    meshManager: MeshManager,
    obbTree: OBBTree,
    enableSleeper: boolean,
    isBuildPlanLegacy: boolean,
  ) {
    for (let i = 0; i < meshObj.length; i += 1) {
      for (let j = i + 1; j < meshObj.length; j += 1) {
        if (enableSleeper) {
          await occasionalSleeper()
        }
        let colliding = false
        // don't do collision checks between:
        // 1) two keep-out zones
        // 2) two meshes whose metadatas are related to the same build plan item (e.g. part and its supports)
        const buildPlanItemId1 = meshObj[i].mesh.metadata ? meshObj[i].mesh.metadata.buildPlanItemId : null
        const buildPlanItemId2 = meshObj[j].mesh.metadata ? meshObj[j].mesh.metadata.buildPlanItemId : null
        if (
          (IGNORED_MESH_NAMES.includes(meshObj[i].mesh.name) && IGNORED_MESH_NAMES.includes(meshObj[j].mesh.name)) ||
          (buildPlanItemId1 && buildPlanItemId2 && buildPlanItemId1 === buildPlanItemId2) ||
          IGNORED_MESH_NAMES_EXCLUDING_KOZ.includes(meshObj[i].mesh.name) ||
          IGNORED_MESH_NAMES_EXCLUDING_KOZ.includes(meshObj[j].mesh.name)
        ) {
          continue
        }
        // exclude collision checks between items with the same buildPlanItemId
        // (e.g. part and its supports) and a keep out zone
        if (
          (buildPlanItemId1 && meshManager.isKeepOutZoneMesh(meshObj[j].mesh)) ||
          (buildPlanItemId2 && meshManager.isKeepOutZoneMesh(meshObj[i].mesh))
        ) {
          const buildPlanItemId = buildPlanItemId1 || buildPlanItemId2
          const bpCollisionWithKOZ = meshObj.find(
            (m) =>
              m.mesh.metadata &&
              m.mesh.metadata.buildPlanItemId === buildPlanItemId &&
              m.collidingWith.find((o) => meshManager.isKeepOutZoneMesh(o)),
          )
          const kozCollisionWithBP = meshObj.find(
            (m) =>
              meshManager.isKeepOutZoneMesh(m.mesh) &&
              m.collidingWith.find((o) => o.metadata && o.metadata.buildPlanItemId === buildPlanItemId),
          )
          if (bpCollisionWithKOZ || kozCollisionWithBP) {
            continue
          }
        }

        // exclude collision checks between items with the same buildPlanItemId
        // if they are already colliding
        if (buildPlanItemId1 && buildPlanItemId2) {
          const bp1CollidesWithbp2 = meshObj.find(
            (m) =>
              m.mesh.metadata &&
              m.mesh.metadata.buildPlanItemId === buildPlanItemId1 &&
              m.collidingWith.find((o) => o.metadata && o.metadata.buildPlanItemId === buildPlanItemId2),
          )
          const bp2CollidesWithbp1 = meshObj.find(
            (m) =>
              m.mesh.metadata &&
              m.mesh.metadata.buildPlanItemId === buildPlanItemId2 &&
              m.collidingWith.find((o) => o.metadata && o.metadata.buildPlanItemId === buildPlanItemId1),
          )
          if (bp1CollidesWithbp2 || bp2CollidesWithbp1) {
            continue
          }
        }

        const meshA = meshObj[i].mesh
        if (!meshA.metadata) {
          continue
        }
        const aabbA = meshA.metadata.hullBInfo
          ? meshA.metadata.hullBInfo.boundingBox
          : meshA.metadata.bvh.bInfo.boundingBox
        const rootA = meshObj[i].mesh.metadata.bvh
        const meshB = meshObj[j].mesh
        if (!meshB.metadata) {
          continue
        }
        const aabbB = meshB.metadata.hullBInfo
          ? meshB.metadata.hullBInfo.boundingBox
          : meshB.metadata.bvh.bInfo.boundingBox
        const rootB = meshObj[j].mesh.metadata.bvh
        if (aabbA && aabbB && rootA && rootB) {
          if (BoundingBox.Intersects(aabbA, aabbB)) {
            if (!isBuildPlanLegacy) {
              const areNominal = obbTree.isNominal(meshA) || obbTree.isNominal(meshB)
              // use OBB collision for bolt holes or if any part is nominal
              if (rootA.obb === null || rootB.obb === null || areNominal) {
                colliding = obbTree.bvhCollision(rootA, rootB, meshA, meshB)
              } else {
                colliding = obbTree.rssCollision(rootA, rootB, meshA, meshB)
              }
            } else {
              // use OBB collision for legacy build plans
              colliding = obbTree.bvhCollision(rootA, rootB, meshA, meshB)
            }
            if (colliding) {
              meshObj[i].collidingWith.push(meshObj[j].mesh)
              meshObj[j].collidingWith.push(meshObj[i].mesh)

              meshObj[i].colliding = colliding
              meshObj[j].colliding = colliding
            }
          }
        }
      }
    }
  }

  /**
   * Calculates collisions only between selected items and bp items
   * @param meshObjs array of objects that contains mesh and collision/floating info
   * @param selectedMeshObjs array of objects that contains selected mesh and collision/floating info
   * @param meshManager mesh manager
   * @param obbTree obb tree
   * @param enableSleeper enable sleeper
   * @param isBuildPlanLegacy is BuildPlan legacy
   */
  private async oneToManyCollisionHelper(
    meshObjs: ICollisionNode[],
    selectedMeshObjs: ICollisionNode[],
    meshManager: MeshManager,
    obbTree: OBBTree,
    enableSleeper: boolean,
    isBuildPlanLegacy: boolean,
  ) {
    for (const selectedMeshObj of selectedMeshObjs) {
      for (const meshObj of meshObjs) {
        if (enableSleeper) {
          await occasionalSleeper()
        }
        let colliding = false

        // don't do collision checks between:
        // two meshes whose metadatas are related to the same build plan item (e.g. part and its supports)
        const buildPlanItemId1 = selectedMeshObj.mesh.metadata ? selectedMeshObj.mesh.metadata.buildPlanItemId : null
        const buildPlanItemId2 = meshObj.mesh.metadata ? meshObj.mesh.metadata.buildPlanItemId : null
        if (
          (buildPlanItemId1 && buildPlanItemId2 && buildPlanItemId1 === buildPlanItemId2) ||
          IGNORED_MESH_NAMES_EXCLUDING_KOZ.includes(meshObj.mesh.name)
        ) {
          continue
        }

        // exclude collision checks between items with the same buildPlanItemId
        // (e.g. part and its supports) and a keep out zone
        if (buildPlanItemId1 && meshManager.isKeepOutZoneMesh(meshObj.mesh)) {
          const bpCollisionWithKOZ = meshObjs.find(
            (m) =>
              m.mesh.metadata &&
              m.mesh.metadata.buildPlanItemId === buildPlanItemId1 &&
              m.collidingWith.find((o) => meshManager.isKeepOutZoneMesh(o)),
          )
          const kozCollisionWithBP = meshObjs.find(
            (m) =>
              meshManager.isKeepOutZoneMesh(m.mesh) &&
              m.collidingWith.find((o) => o.metadata && o.metadata.buildPlanItemId === buildPlanItemId1),
          )
          if (bpCollisionWithKOZ || kozCollisionWithBP) {
            continue
          }
        }

        // exclude collision checks between items with the same buildPlanItemId
        // if they are already colliding
        if (buildPlanItemId1 && buildPlanItemId2) {
          const bp1CollidesWithbp2 = meshObjs.find(
            (m) =>
              m.mesh.metadata &&
              m.mesh.metadata.buildPlanItemId === buildPlanItemId1 &&
              m.collidingWith.find((o) => o.metadata && o.metadata.buildPlanItemId === buildPlanItemId2),
          )
          const bp2CollidesWithbp1 = meshObjs.find(
            (m) =>
              m.mesh.metadata &&
              m.mesh.metadata.buildPlanItemId === buildPlanItemId2 &&
              m.collidingWith.find((o) => o.metadata && o.metadata.buildPlanItemId === buildPlanItemId1),
          )
          if (bp1CollidesWithbp2 || bp2CollidesWithbp1) {
            continue
          }
        }

        const meshA = selectedMeshObj.mesh
        if (!meshA.metadata) {
          continue
        }
        const aabbA = meshA.metadata.hullBInfo
          ? meshA.metadata.hullBInfo.boundingBox
          : meshA.metadata.bvh.bInfo.boundingBox
        const rootA = selectedMeshObj.mesh.metadata.bvh
        const meshB = meshObj.mesh
        if (!meshB.metadata) {
          continue
        }
        const aabbB = meshB.metadata.hullBInfo
          ? meshB.metadata.hullBInfo.boundingBox
          : meshB.metadata.bvh.bInfo.boundingBox
        const rootB = meshObj.mesh.metadata.bvh
        if (aabbA && aabbB && rootA && rootB) {
          if (BoundingBox.Intersects(aabbA, aabbB)) {
            if (!isBuildPlanLegacy) {
              const areNominal = obbTree.isNominal(meshA) || obbTree.isNominal(meshB)
              // use OBB collision for bolt holes or if any part is nominal
              if (rootA.obb === null || rootB.obb === null || areNominal) {
                colliding = obbTree.bvhCollision(rootA, rootB, meshA, meshB)
              } else {
                colliding = obbTree.rssCollision(rootA, rootB, meshA, meshB)
              }
            } else {
              // use OBB collision for legacy build plans
              colliding = obbTree.bvhCollision(rootA, rootB, meshA, meshB)
            }
            if (colliding) {
              selectedMeshObj.collidingWith.push(meshObj.mesh)
              meshObj.collidingWith.push(selectedMeshObj.mesh)

              selectedMeshObj.colliding = colliding
              meshObj.colliding = colliding
            }
          }
        }
      }
    }
  }

  private findCollision(
    meshA: TransformNode,
    meshB: TransformNode,
    renderScene: RenderScene,
    selectedMeshes: TransformNode[],
  ) {
    const rootA = renderScene.getMeshManager().getBuildPlanItemMeshByChild(meshA)
    const rootB = renderScene.getMeshManager().getBuildPlanItemMeshByChild(meshB)
    if (!selectedMeshes.includes(rootA) && !selectedMeshes.includes(rootB)) {
      return
    }

    const collisions = renderScene.getCollisionManager().selectedPartCollisions
    const root = selectedMeshes.includes(rootA) ? rootB : rootA
    const collisionEventArgs =
      root.metadata.buildPlanItemId !== undefined
        ? new CollisionEventArgs(root.metadata.buildPlanItemId)
        : new CollisionEventArgs(null, root.name)
    if (
      collisions.some(
        (c) =>
          c.buildPlanItemId === collisionEventArgs.buildPlanItemId ||
          (c.name !== null && c.name === collisionEventArgs.name),
      )
    ) {
      return
    }

    collisions.push(collisionEventArgs)
  }
}
