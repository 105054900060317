
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import NumberField from '@/components/controls/Common/NumberField.vue'
import draggable from 'vuedraggable'
import { Mixins, Watch } from 'vue-property-decorator'
import {
  defaultGridLetterSpacing,
  GridLetterDirection,
  GridLetterJSON,
  GridLetterLocation,
  TextElement,
} from '@/types/Label/TextElement'
import { TranslateResult } from 'vue-i18n'
import { DynamicElementSettingsMixin } from '@/components/layout/buildPlans/marking/mixins/DynamicElementSettingsMixin'
import { LabelTooltipMixin } from '@/components/layout/buildPlans/marking/mixins/LabelTooltipMixin'
import { extend, ValidationObserver } from 'vee-validate'
import { ClickOutsideMixin } from '@/components/layout/mixins/ClickOutsideMixin'
import { IBuildPlate } from '@/types/BuildPlates/IBuildPlate'
import { LabelServiceMixin } from '@/components/layout/buildPlans/marking/mixins/LabelServiceMixin'

const labelStore = namespace(StoresNamespaces.Labels)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)

interface IMixinInterface
  extends Vue,
    DynamicElementSettingsMixin,
    LabelTooltipMixin,
    ClickOutsideMixin,
    LabelServiceMixin {}

@Component({ components: { NumberField, draggable } })
export default class GridSettings extends Mixins<IMixinInterface>(
  Vue,
  DynamicElementSettingsMixin,
  LabelTooltipMixin,
  ClickOutsideMixin,
  LabelServiceMixin,
) {
  @labelStore.Action updateDynamicElement: (element: TextElement) => void

  @buildPlansStore.Getter getSelectedBuildPlate: IBuildPlate
  @buildPlansStore.Getter isLabelReadOnly: boolean
  @visualizationStore.Mutation displaySpatialLetterGrid: (payload: {
    settings: GridLetterJSON
    isVisible: boolean
  }) => void

  sets = []
  gridDirections = [GridLetterDirection.XAxis, GridLetterDirection.YAxis, GridLetterDirection.ZAxis]
  activeDirection = GridLetterDirection.XAxis
  boundingBoxOptions = [GridLetterLocation.MinimumCorner, GridLetterLocation.Center, GridLetterLocation.MaximumCorner]
  activeOption = GridLetterLocation.Center
  offset: number = 0
  spacing: number = 0
  title: string = null
  id: number = 0
  topOffset: number = 0
  leftOffset: number = 0
  triangleLeft: number = 22
  triangleTop: number = 15
  nameId: number = 0
  previousSettings: GridLetterJSON

  $refs!: {
    dialog: Element
    gridLetterSettings: InstanceType<typeof ValidationObserver>
  }

  beforeMount() {
    this.extendValidationRules()
    const specificJSON: GridLetterJSON =
      this.getActiveDynamicElementDialogInfo.textElement &&
      JSON.parse(this.getActiveDynamicElementDialogInfo.textElement._cachedSpecificsJSON)

    if (!specificJSON) {
      this.spacing = defaultGridLetterSpacing
      this.offset = this.getDefaultOffset()
    }
  }

  mounted() {
    // Set values from dynamic element settings in a store to the local component variables
    this.setListenerForClickOutside(true, null, this.customHandler)
    this.displaySpatialLetterGrid({
      isVisible: true,
      settings: this.getActiveDynamicElementDialogInfo.textElement
        ? JSON.parse(this.getActiveDynamicElementDialogInfo.textElement._cachedSpecificsJSON)
        : null,
    })
    const specificJSON: GridLetterJSON = JSON.parse(
      this.getActiveDynamicElementDialogInfo.textElement._cachedSpecificsJSON,
    )
    this.offset = specificJSON.offset
    this.spacing = specificJSON.spacing
    this.activeOption = specificJSON.location
    this.activeDirection = specificJSON.direction
    this.title = this.getActiveDynamicElementDialogInfo.textElement.title
    this.id = this.getActiveDynamicElementDialogInfo.textElement.elementIDNumber
    this.nameId = +this.title.split(' ').pop()
    this.$forceUpdate()
    // On html content is loaded set the position of a dialog
    this.$nextTick(() => {
      const { leftOffset, triangleLeft } = this.setLeft(this.getActiveDynamicElementDialogInfo.left, this.$refs.dialog)
      const { topOffset, triangleTop } = this.setTop(this.getActiveDynamicElementDialogInfo.top, this.$refs.dialog)
      this.leftOffset = leftOffset
      this.topOffset = topOffset
      this.triangleLeft = triangleLeft
      this.triangleTop = triangleTop
    })
  }

  beforeDestroy() {
    this.setListenerForClickOutside(false, null, this.customHandler)
    this.displaySpatialLetterGrid({
      isVisible: false,
      settings: null,
    })
  }

  get offsetValidation() {
    return {
      rules: {
        required: true,
        in_range_included: [-500, 500],
      },
      customMessages: {
        required: this.$t('labelTool.enterValueWithUnits', {
          leftLimit: -500,
          rightLimit: 500,
          units: this.$t('millimeterAbbr'),
        }).toString(),
        in_range_included: this.$i18n.t('labelTool.gridLetterSettings.enterValue').toString(),
      },
    }
  }

  get spacingValidation() {
    return {
      rules: {
        required: true,
        in_range_included: [1, 500],
      },
      customMessages: {
        required: this.$t('labelTool.enterValueWithUnits', {
          leftLimit: 1,
          rightLimit: 500,
          units: this.$t('millimeterAbbr'),
        }).toString(),
        in_range_included: this.$i18n.t('labelTool.gridLetterSettings.enterValue').toString(),
      },
    }
  }

  extendValidationRules() {
    extend('in_range_included', {
      validate: (value: number, { leftLimit, rightLimit }: { leftLimit: string; rightLimit: string }) => {
        return value >= +leftLimit && value <= +rightLimit
      },
      params: ['leftLimit', 'rightLimit'],
      message: this.$i18n.t('labelTool.gridLetterSettings.enterValue').toString(),
    })
  }

  @Watch('activeDirection')
  @Watch('activeOption')
  @Watch('offset')
  @Watch('spacing')
  onValuesChanged() {
    this.updateElement()
  }

  close() {
    this.$emit('close')
  }

  usedInArray() {
    if (this.getActiveDynamicElementDialogInfo.textElement) {
      const usedInLine = this.usedIn(this.getActiveDynamicElementDialogInfo.textElement, true)
      if (usedInLine.length) {
        return usedInLine.split(',')
      }
    }
    return []
  }

  save() {
    this.updateElement()
    this.$emit('close')
  }

  setActiveOption(option: GridLetterLocation) {
    this.activeOption = option
  }

  generateSpecificJson(): GridLetterJSON {
    return {
      direction: this.activeDirection,
      location: this.activeOption,
      offset: this.offset,
      spacing: this.spacing,
    }
  }

  updateElement() {
    const element = JSON.parse(JSON.stringify(this.getActiveDynamicElementDialogInfo.textElement))
    const updatedElement = {
      ...element,
      _cachedSpecificsJSON: JSON.stringify(this.generateSpecificJson()),
    }

    this.updateDynamicElement(updatedElement)
  }

  getLocation(location: GridLetterLocation): TranslateResult {
    switch (location) {
      case GridLetterLocation.Center:
        return this.$t('labelTool.gridLetterSettings.center')
      case GridLetterLocation.MaximumCorner:
        return this.$t('labelTool.gridLetterSettings.maximumCorner')
      case GridLetterLocation.MinimumCorner:
        return this.$t('labelTool.gridLetterSettings.minimumCorner')
    }
  }

  getDirection(direction: GridLetterDirection): TranslateResult {
    switch (direction) {
      case GridLetterDirection.XAxis:
        return this.$t('labelTool.gridLetterSettings.xAxis')
      case GridLetterDirection.YAxis:
        return this.$t('labelTool.gridLetterSettings.yAxis')
      case GridLetterDirection.ZAxis:
        return this.$t('labelTool.gridLetterSettings.zAxis')
    }
  }

  customHandler(event: PointerEvent) {
    this.dialogOutsideClickHandler(event, 'grid-settings', `element-chip-${this.id}`, this.updateElement, this.close)
  }

  onDirectionChange(direction: GridLetterDirection) {
    this.activeDirection = direction
    this.offset = this.getDefaultOffset()
  }

  getDefaultOffset() {
    switch (this.activeDirection) {
      case GridLetterDirection.XAxis:
        // convert to mm
        return -(this.getSelectedBuildPlate.buildPlateDimensionX / 2) * 1000
      case GridLetterDirection.YAxis:
        // convert to mm
        return -(this.getSelectedBuildPlate.buildPlateDimensionY / 2) * 1000
      default:
        return 0
    }
  }

  async updated() {
    if (this.getActiveDynamicElementDialogInfo.textElement) {
      const settings = this.generateSpecificJson()
      this.checkElementChanged(
        this.getActiveDynamicElementDialogInfo.textElement._cachedSpecificsJSON,
        JSON.stringify(settings),
      )

      await this.$nextTick()
      if (JSON.stringify(settings) !== JSON.stringify(this.previousSettings)) {
        const payload = (await this.$refs.gridLetterSettings.validate())
          ? { settings, isVisible: true }
          : { settings: null, isVisible: false }
        this.displaySpatialLetterGrid(payload)
      }

      this.previousSettings = settings
    }
  }
}
