
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Icon from '@/components/icons/Icon.vue'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'

const defaultSize: number = 16

@Component({ components: { Icon } })
export default class VariantIcon extends Vue {
  @Prop() variant: { itemType: ItemType; subType: ItemSubType }
  @Prop() size: number

  get isSinterVariant() {
    return this.variant.subType === ItemSubType.SinterPlan
  }

  get isIbcVariant() {
    return this.variant.itemType === ItemType.IbcPlan
  }

  get iconSize() {
    return this.size ? this.size : defaultSize
  }
}
