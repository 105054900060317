import { GetterTree } from 'vuex'
import { INotificationState } from './types'
import { IRootState } from '@/store/types'

export const getters: GetterTree<INotificationState, IRootState> = {
  isLoading(state) {
    return state.isLoading
  },

  getAllNotifications(state) {
    return state && state.notifications
  },

  getSelectedNotificationType(state): { text: string; value: string[] } {
    return state.selectedNotificationType
  },

  getCurrentPageIndex(state): number {
    return state.paginationData.currentPage
  },

  getTotalPages(state): number {
    return state.paginationData.totalPages
  },

  getPageSize(state): number {
    return state.paginationData.size
  },

  getLastNotificationTime(state) {
    return state && state.lastNotificationTime
  },
}
