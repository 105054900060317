/*
PLEASE READ BEFORE ADDING NEW IMPORTS!!!
Do not import '@babylonjs/core' use submodules '@babylonjs/core/.../submodule' instead
This is required in order to keep babylon build small and not inlcude unused features to vendor package
*/
import { MeshBuilder, Mesh, VertexData, TransformNode, InstancedMesh, AbstractMesh } from '@babylonjs/core/Meshes'
import { Buffer, VertexBuffer } from '@babylonjs/core/Buffers'
import {
  OVERHANG_ERROR_MATERIAL,
  OVERHANG_INSIDE_MATERIAL,
  OVERHANG_MATERIAL,
  OVERHANG_NAME,
  OVERHANG_RADIUS,
  OVERHANG_EDGE_WIDTH,
  OVERHANG_EDGES_NAME,
  OVERHANG_VERTICES_NAME,
  MESH_RENDERING_GROUP_ID,
  OVERHANG_INSIDE_MESH_NAME,
  OVERHANG_SELECTION_MATERIAL,
  OVERHANG_HOVER_MATERIAL,
  OVERHANG_COLOR,
  FACE_ID_ATTRIBUTE,
  COLOR_FOR_FACE,
  COLOR_FOR_BODY,
  COLOR_FOR_PART,
  HOVER_FACE_ID,
  OVERHANG_SELECTION_COLOR,
  PRIMARY_CYAN,
  COLLECTOR_INSTANCE_ID,
  OVERHANG_CONTOUR_NAME,
  OVERHANG_TRIANGLE_NAME,
} from '@/constants'
import { Color3, Color4, Vector3, Matrix } from '@babylonjs/core/Maths'
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { RenderScene } from '@/visualization/render-scene'
import { IEdgeInfo, IVertexInfo, ModelManager } from '@/visualization/rendering/ModelManager'
import { VisualizationEvent } from '@/visualization/infrastructure/IVisualizationEvent'
import { BuildPlanItemOverhang, SupportedElementTypes } from '@/types/BuildPlans/IBuildPlan'
import { MeshManager } from '@/visualization/rendering/MeshManager'
import { HoverableFace, OverhangShader } from '@/visualization/rendering/MeshShader'
import { IOverhangMetadata, IPartMetadata, SceneItemType } from '@/visualization/types/SceneItemMetadata'
import { Material } from '@babylonjs/core/Materials'
import { Scene } from '@babylonjs/core/scene'
import partsService from '@/api/parts'
import { Node } from '@babylonjs/core/node'
import { v4 as uuid } from 'uuid'
import { Ray } from '@babylonjs/core/Culling'
import { transform } from 'html2canvas/dist/types/css/property-descriptors/transform'

export interface IOverhangConfig {
  drc?: ArrayBuffer
  overhang?: BuildPlanItemOverhang
  isInvisible?: boolean
}

export class OverhangManager {
  modelManager: ModelManager
  renderScene: RenderScene
  scene: Scene
  private readonly onGetOverhangsElementsEvent = new VisualizationEvent<{
    elements: Array<{ overhangElementName: string; overhangElementType: SupportedElementTypes }>
    bpItemId: string
    overhangContourMap: Map<string, string[]>
  }>()
  private overhangPromises = new Map<string, Promise<void>>()
  private meshManager: MeshManager
  private regularMaterial: StandardMaterial
  private highlightMaterial: StandardMaterial
  private hoverMaterial: StandardMaterial
  private errorMaterial: StandardMaterial
  private previousContours = []
  private prevCurrentOverhangContourMap = new Map<string, string[]>()

  private overhangCache: Map<string, Mesh> = new Map<string, Mesh>()

  constructor(renderScene: RenderScene) {
    this.modelManager = renderScene.getModelManager()
    this.renderScene = renderScene
    this.scene = renderScene.getScene()
    this.meshManager = renderScene.getMeshManager()
    this.regularMaterial = this.renderScene.getScene().getMaterialByID(OVERHANG_MATERIAL) as StandardMaterial
    this.highlightMaterial = this.renderScene
      .getScene()
      .getMaterialByID(OVERHANG_SELECTION_MATERIAL) as StandardMaterial
    this.hoverMaterial = this.renderScene.getScene().getMaterialByID(OVERHANG_HOVER_MATERIAL) as StandardMaterial
    this.errorMaterial = this.renderScene.getScene().getMaterialByID(OVERHANG_ERROR_MATERIAL) as StandardMaterial
  }

  get overhangsElementsEvent() {
    return this.onGetOverhangsElementsEvent.expose()
  }

  async addOverhangMesh(bpItemId: string, meshId: string, config: IOverhangConfig, skipContour: boolean = false) {
    if (!config || (!config.drc && !config.overhang)) {
      return
    }

    this.prevCurrentOverhangContourMap = new Map<string, string[]>()
    const bpItemMesh = this.meshManager.getBuildPlanItemMeshById(bpItemId)
    let sourceOverhang: Mesh
    if (config.drc) {
      sourceOverhang = await this.createOverhangSurface(bpItemMesh, meshId, config.drc, skipContour)
    } else if (config.overhang && !this.overhangCache.has(config.overhang.awsFileKey)) {
      const overhangFile = await partsService.getBinaryFileFromAws(config.overhang.awsFileKey)
      sourceOverhang = await this.createOverhangSurface(bpItemMesh, meshId, overhangFile, skipContour)
      sourceOverhang.metadata.awsFileKey = config.overhang.awsFileKey
      this.overhangCache.set(config.overhang.awsFileKey, sourceOverhang)
    } else {
      sourceOverhang = this.overhangCache.get(config.overhang.awsFileKey)
    }

    const addOverhangPromise = this.createAddOverhangPromise()
    this.overhangPromises.set(bpItemId, addOverhangPromise.promise)
    const overhangInstance = this.createOverhangMeshInstance(sourceOverhang, bpItemMesh)
    overhangInstance.onDispose = () => {
      if (!sourceOverhang.hasInstances) {
        sourceOverhang.dispose()
        if (config.overhang) {
          this.overhangCache.delete(config.overhang.awsFileKey)
        }
      }
    }

    for (const child of sourceOverhang.getChildMeshes(true)) {
      const childInstance = this.createOverhangMeshInstance(child as Mesh, overhangInstance)
      if (child.metadata && child.metadata.sourceMeshInside) {
        this.createOverhangMeshInstance(child.metadata.sourceMeshInside, childInstance)
      }

      if (this.meshManager.isOverhangEdgeTube(child)) {
        childInstance.isVisible = false
      }
    }

    const overhangElements = this.getOverhangElements(overhangInstance)
    this.overhangsElementsEvent.trigger({
      elements: overhangElements,
      bpItemId: bpItemMesh.metadata.buildPlanItemId,
      overhangContourMap: this.prevCurrentOverhangContourMap,
    })

    addOverhangPromise.done()
  }

  computeFaceOverhangArea(overhangMesh: AbstractMesh, faceName: string) {
    let area = 0
    const metadata = overhangMesh.metadata as IOverhangMetadata
    const positions = overhangMesh.getVerticesData(VertexBuffer.PositionKind) as number[]
    const face = metadata.faces.find((f) => f.name === faceName)
    if (!face) {
      return area
    }

    for (let i = 0; i < face.indices.length; i += 3) {
      const v1 = Vector3.FromArray(positions, face.indices[i] * 3)
      const v2 = Vector3.FromArray(positions, face.indices[i + 1] * 3)
      const v3 = Vector3.FromArray(positions, face.indices[i + 2] * 3)
      area += this.meshManager.calculateFacetArea(v1, v2, v3, Matrix.Identity())
    }

    return area
  }

  computeFaceOverhangObb(overhangMesh: AbstractMesh, faceName: string) {
    const metadata = overhangMesh.metadata as IOverhangMetadata
    const positions = overhangMesh.getVerticesData(VertexBuffer.PositionKind) as number[]
    const face = metadata.faces.find((f) => f.name === faceName)
    if (!face || face.indices.length === 0) {
      return []
    }

    let vectors: Vector3[]
    if (faceName.startsWith(OVERHANG_TRIANGLE_NAME)) {
      const triangles = []
      for (let i = 0; i < face.indices.length; i += 3) {
        triangles.push(Vector3.FromArray(positions, face.indices[i] * 3))
        triangles.push(Vector3.FromArray(positions, face.indices[i + 1] * 3))
        triangles.push(Vector3.FromArray(positions, face.indices[i + 2] * 3))
      }
      vectors = this.meshManager.computeOptimalObbFromTriangles(triangles)
    } else {
      const path = []
      const worldMatrix = overhangMesh.getWorldMatrix()
      for (let i = 0; i < face.indices.length; i += 3) {
        const localPoints: Vector3[] = []
        const worldPoints: Vector3[] = []

        localPoints.push(Vector3.FromArray(positions, face.indices[i] * 3))
        localPoints.push(Vector3.FromArray(positions, face.indices[i + 1] * 3))
        localPoints.push(Vector3.FromArray(positions, face.indices[i + 2] * 3))

        localPoints.forEach((vec) => worldPoints.push(Vector3.TransformCoordinates(vec, worldMatrix)))

        let minZIndex = 0
        worldPoints.forEach((vec, idx) => {
          minZIndex = worldPoints[minZIndex].z < vec.z ? minZIndex : idx
        })
        localPoints.splice(minZIndex, 1)

        path.push(localPoints[0])
        path.push(localPoints[1])
      }
      vectors = this.meshManager.computeOptimalObbFromVertices(path)
    }

    const points: number[] = []
    vectors.forEach((vector) => {
      points.push(vector.x)
      points.push(vector.y)
      points.push(vector.z)
    })
    return points
  }

  updateOverhangMesh(bpItemId: string, meshId: string, overhang: BuildPlanItemOverhang) {
    const bpItemMesh = this.meshManager.getBuildPlanItemMeshById(bpItemId)
    const overhangMesh = bpItemMesh.getChildMeshes().find((m) => m.id === meshId) as InstancedMesh
    if (overhangMesh) {
      this.overhangCache.set(overhang.awsFileKey, overhangMesh.sourceMesh)
      overhangMesh.onDispose = () => {
        if (!overhangMesh.sourceMesh.hasInstances) {
          overhangMesh.sourceMesh.dispose()
          if (overhang) {
            this.overhangCache.delete(overhang.awsFileKey)
          }
        }
      }
    }
  }

  duplicateOverhangMesh(mesh: InstancedMesh, parent: Node) {
    const sourceOverhang = mesh.sourceMesh
    const overhangInstance = this.createOverhangMeshInstance(sourceOverhang, parent)
    overhangInstance.parent = parent
    overhangInstance.onDispose = () => {
      if (!sourceOverhang.hasInstances) {
        this.overhangCache.delete(sourceOverhang.metadata.awsFileKey)
        sourceOverhang.dispose()
      }
    }

    for (const child of sourceOverhang.getChildMeshes(true)) {
      const childInstance = this.createOverhangMeshInstance(child as Mesh, overhangInstance)
      if (!child.metadata || !child.metadata.sourceMeshInside) {
        continue
      }

      this.createOverhangMeshInstance(child.metadata.sourceMeshInside, childInstance)
    }

    this.renderScene.setMeshVisibilityRec(overhangInstance, false)
  }

  async highlightErrorOverhangZone(bpItemId: string, overhangZoneName?: string) {
    const overhangPromise = this.overhangPromises.get(bpItemId)
    await overhangPromise

    const bpItem = this.meshManager.getBuildPlanItemMeshById(bpItemId)
    const overhangMesh = bpItem.getChildMeshes().find((mesh) => this.meshManager.isOverhangSurface(mesh))

    const partMetadata = bpItem.metadata as IPartMetadata
    if (!partMetadata.failedOverhangZones) {
      partMetadata.failedOverhangZones = []
    }

    if (overhangMesh) {
      const overhangZones = overhangZoneName
        ? overhangMesh.metadata.faces.filter((face) => face.name === overhangZoneName)
        : overhangMesh.metadata.faces

      for (const overhangZone of overhangZones) {
        overhangMesh.metadata.failedZones.push(overhangZone.id)

        if (overhangZone.name.startsWith(OVERHANG_EDGES_NAME) || overhangZone.name.startsWith(OVERHANG_VERTICES_NAME)) {
          const overhangZoneMeshes = (overhangMesh as Mesh)
            .getChildMeshes()
            .filter((mesh) => mesh.name === overhangZone.name)
          overhangZoneMeshes.forEach((zoneMesh) => {
            zoneMesh.metadata.faceMaterial.setErrorFacesId([0])
          })
        }
      }

      overhangMesh.metadata.faceMaterial.setErrorFacesId(overhangMesh.metadata.failedZones)
    }
  }

  highlightOverhangZones(bpItemId: string, overhangZoneNames: string[], hoverOverhangZoneName?: string) {
    const overhangMesh = this.meshManager
      .getBuildPlanItemMeshById(bpItemId)
      .getChildMeshes()
      .find((mesh) => this.meshManager.isOverhangSurface(mesh)) as InstancedMesh

    if (overhangMesh) {
      const overhangZones = overhangMesh.metadata.faces
      const selectedZones = []
      let hoverFaceId

      for (const overhangZone of overhangZones) {
        let isFaceSelected = false
        const isFaceHovered = hoverOverhangZoneName && overhangZone.name === hoverOverhangZoneName

        if (overhangZoneNames.includes(overhangZone.name)) {
          selectedZones.push(overhangZone.id)
          isFaceSelected = true
        }
        if (isFaceHovered) {
          hoverFaceId = overhangZone.id
        }

        if (overhangZone.name.startsWith(OVERHANG_EDGES_NAME) || overhangZone.name.startsWith(OVERHANG_VERTICES_NAME)) {
          const overhangZoneMeshes = overhangMesh
            .getChildMeshes()
            .filter((mesh) => mesh.name === overhangZone.name) as InstancedMesh[]

          overhangZoneMeshes.forEach((zoneMesh) => {
            if (!zoneMesh.metadata || !zoneMesh.metadata.faceMaterial) {
              return
            }

            if (this.meshManager.isOverhangEdge(zoneMesh)) {
              const clr = isFaceHovered ? PRIMARY_CYAN : isFaceSelected ? OVERHANG_SELECTION_COLOR : OVERHANG_COLOR
              zoneMesh.edgesColor = new Color4(clr.r, clr.g, clr.b, 1)
              zoneMesh.edgesWidth = isFaceSelected || isFaceHovered ? OVERHANG_EDGE_WIDTH * 1.5 : OVERHANG_EDGE_WIDTH
            }

            const childInstanceIndex = zoneMesh.sourceMesh.instances.findIndex((i) => i === zoneMesh)
            this.setSelectedFaces(zoneMesh.sourceMesh, isFaceSelected ? [0] : null, childInstanceIndex)
            zoneMesh.metadata.faceMaterial.setSelectedFacesId(zoneMesh.sourceMesh.metadata.selectedFacesIds)

            if (isFaceHovered && hoverFaceId !== undefined) {
              zoneMesh.instancedBuffers.hoverFaceId = new HoverableFace(0)
              zoneMesh.metadata.faceMaterial.showHover(true)
            } else {
              zoneMesh.instancedBuffers.hoverFaceId = new HoverableFace(-1)
              zoneMesh.metadata.faceMaterial.showHover(false)
            }
          })
        }
      }

      const instanceIndex = overhangMesh.sourceMesh.instances.findIndex((i) => i === overhangMesh)
      this.setSelectedFaces(overhangMesh.sourceMesh, selectedZones, instanceIndex)
      overhangMesh.metadata.faceMaterial.setSelectedFacesId(overhangMesh.sourceMesh.metadata.selectedFacesIds)
      if (hoverFaceId !== undefined) {
        overhangMesh.instancedBuffers.hoverFaceId = new HoverableFace(hoverFaceId)
        overhangMesh.metadata.faceMaterial.showHover(true)
      } else {
        overhangMesh.instancedBuffers.hoverFaceId = new HoverableFace(-1)
        overhangMesh.metadata.faceMaterial.showHover(false)
      }

      this.renderScene.animate()
    }
  }

  setDefaultOverhangMaterial(bpItemId: string, overhangElementsToClear?: string[]) {
    let failedOverhangElementsToStore = []
    const overhangMesh = this.meshManager
      .getBuildPlanItemMeshById(bpItemId)
      .getChildMeshes()
      .find((mesh) => this.meshManager.isOverhangSurface(mesh)) as any

    if (!overhangMesh) {
      return
    }

    if (overhangElementsToClear) {
      const zoneIdsToClear = overhangMesh.metadata.faces
        .filter(
          (face) => overhangElementsToClear.includes(face.name) && overhangMesh.metadata.failedZones.includes(face.id),
        )
        .map((face) => face.id)

      const errorZones = overhangMesh.metadata.faces.filter((face) =>
        overhangMesh.metadata.failedZones.includes(face.id),
      )
      failedOverhangElementsToStore = errorZones.filter((errorZone) => !zoneIdsToClear.includes(errorZone.id))
    }

    overhangMesh.metadata.failedZones = []
    overhangMesh.metadata.faceMaterial.setErrorFacesId(overhangMesh.metadata.failedZones)
    overhangMesh.getChildMeshes().forEach((overhangChild) => {
      if (overhangChild.name.includes(OVERHANG_INSIDE_MESH_NAME)) {
        return
      }
      overhangChild.metadata.faceMaterial.setErrorFacesId([])
    })

    failedOverhangElementsToStore.forEach((failedOverhangElement) =>
      this.highlightErrorOverhangZone(bpItemId, failedOverhangElement.name),
    )
  }

  createAddOverhangPromise() {
    let done: Function
    const promise = new Promise<void>((resolve) => {
      done = resolve
    })

    return { done, promise }
  }

  clearOverhangMesh(bpItemId: string) {
    this.removeOverhangZonesFromGpuPicker(bpItemId)

    const overhangMesh = this.meshManager
      .getBuildPlanItemMeshById(bpItemId)
      .getChildMeshes()
      .find((mesh) => this.meshManager.isOverhangSurface(mesh))

    if (!overhangMesh) {
      return
    }

    this.renderScene.getScene().removeMesh(overhangMesh, true)
    overhangMesh.dispose()
  }

  setOverhangMeshVisibility(items: Array<{ buildPlanItemId: string }>, visibility: boolean) {
    for (const item of items) {
      const overhangMesh = this.meshManager
        .getBuildPlanItemMeshById(item.buildPlanItemId)
        .getChildMeshes()
        .find((mesh) => this.meshManager.isOverhangSurface(mesh))
      if (!overhangMesh) {
        continue
      }

      this.renderScene.setMeshVisibilityRec(overhangMesh, visibility)
    }
  }

  getOverhangElements(
    overhangMesh: InstancedMesh,
  ): Array<{ overhangElementName: string; overhangElementType: SupportedElementTypes; area?: number; obb?: number[] }> {
    for (let i = overhangMesh.metadata.faces.length - 1; i >= 0; i -= 1) {
      if (overhangMesh.metadata.faces[i].name.startsWith(OVERHANG_CONTOUR_NAME)) {
        overhangMesh.metadata.faces.splice(i, 1)
      }
    }

    return overhangMesh.metadata.faces.map((face) => {
      if (face.name.startsWith(OVERHANG_EDGES_NAME)) {
        return {
          overhangElementName: face.name,
          overhangElementType: SupportedElementTypes.Edge,
          obb: face.obb,
        }
      }
      if (face.name.startsWith(OVERHANG_VERTICES_NAME)) {
        return { overhangElementName: face.name, overhangElementType: SupportedElementTypes.Vertex }
      }
      return {
        overhangElementName: face.name,
        overhangElementType: SupportedElementTypes.Area,
        area: this.computeFaceOverhangArea(overhangMesh, face.name),
        obb: this.computeFaceOverhangObb(overhangMesh, face.name),
      }
    })
  }

  findAllOverhangsByBpItemId(bpItemId: string) {
    const bpItem = this.meshManager.getBuildPlanItemMeshById(bpItemId)

    const overhangParent = bpItem.getChildMeshes().find((child) => this.meshManager.isOverhangSurface(child))
    if (!overhangParent) {
      return []
    }

    const overhangMeshes = overhangParent
      .getChildMeshes()
      .filter((child) => child.name.startsWith(OVERHANG_VERTICES_NAME) || child.name.startsWith(OVERHANG_EDGES_NAME))

    overhangMeshes.push(overhangParent)
    return overhangMeshes
  }

  addOverhangZonesToGpuPicker(bpItemId: string) {
    const overhangMeshes = this.findAllOverhangsByBpItemId(bpItemId)
    this.renderScene.getGpuPicker().addPickingObjects(overhangMeshes)
  }

  removeOverhangZonesFromGpuPicker(bpItemId: string) {
    const overhangMeshes = this.findAllOverhangsByBpItemId(bpItemId)
    this.renderScene.getGpuPicker().removePickingObjects(overhangMeshes)
  }

  erasePreviousOverhangContour() {
    this.previousContours = []
    this.prevCurrentOverhangContourMap = new Map<string, string[]>()
  }

  private createInsideMesh(mesh: Mesh, name: string, material: Material) {
    const overhangInsideMesh = new Mesh(name, this.scene)
    overhangInsideMesh.material = material
    overhangInsideMesh.parent = mesh
    overhangInsideMesh.isPickable = false
    overhangInsideMesh.isVisible = false
    const vd = new VertexData()
    vd.indices = mesh.getIndices()
    vd.positions = mesh.getVerticesData(VertexBuffer.PositionKind)
    vd.normals = mesh.getVerticesData(VertexBuffer.NormalKind)
    vd.applyToMesh(overhangInsideMesh)

    return overhangInsideMesh
  }

  private createOverhangMeshInstance(mesh: Mesh, parent: Node) {
    const instance = mesh.createInstance(mesh.name)
    instance.id = mesh.id
    instance.parent = parent
    instance.isVisible = mesh.isVisible
    instance.isPickable = mesh.isPickable
    instance.renderingGroupId = mesh.renderingGroupId
    instance.metadata = mesh.metadata
    if (!instance.instancedBuffers) {
      instance.instancedBuffers = mesh.instancedBuffers
    }

    if (this.meshManager.isOverhangEdge(mesh)) {
      instance.enableEdgesRendering()
      instance.edgesWidth = OVERHANG_EDGE_WIDTH
      instance.edgesColor = new Color4(OVERHANG_COLOR.r, OVERHANG_COLOR.g, OVERHANG_COLOR.b, 1)
    }

    if (!instance.name.includes(OVERHANG_INSIDE_MESH_NAME)) {
      this.setupInstanceOverhangForGpuPicker(instance)
    }

    return instance
  }

  private createOverhangShader() {
    const overhangShader = new OverhangShader(
      this.renderScene,
      this.highlightMaterial,
      this.hoverMaterial,
      this.regularMaterial,
      this.errorMaterial,
    )
    overhangShader.getMaterial().zOffset = -1

    return overhangShader
  }

  private async createOverhangSurface(
    bpItemMesh: TransformNode,
    meshId: string,
    drc: ArrayBuffer,
    skipContour: boolean = false,
  ) {
    const existingOverhang = bpItemMesh.getChildMeshes().find((mesh) => this.meshManager.isOverhangSurface(mesh))
    if (existingOverhang) {
      existingOverhang.dispose()
    }

    const componentId = uuid()
    const geometryId = uuid()
    const pickingColor = Color3.FromInts(
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
    )
    const overhangShader = this.createOverhangShader()
    const transformMatrix = bpItemMesh.getWorldMatrix()
    const overhangs = await this.modelManager.createOverhangMesh(meshId, drc as ArrayBuffer, transformMatrix)
    const overhangMesh = overhangs.surface
    overhangMesh.metadata.pickingShader = this.renderScene.getGpuPicker().pickingShaderWithZOffset
    overhangMesh.material = overhangMesh.metadata.originalMaterial = overhangShader.getMaterial()
    overhangMesh.isPickable = false
    overhangMesh.isVisible = true
    overhangMesh.renderingGroupId = MESH_RENDERING_GROUP_ID
    overhangMesh.instancedBuffers.bColor = pickingColor
    overhangMesh.instancedBuffers.pColor = bpItemMesh.metadata.pickingColor
    overhangMesh.metadata.itemType = SceneItemType.OverhangSurface
    overhangMesh.metadata.faceMaterial = overhangShader
    overhangMesh.metadata.failedZones = []
    overhangMesh.metadata.componentId = componentId
    overhangMesh.metadata.geometryId = geometryId
    overhangMesh.metadata.pickingColor = pickingColor
    this.scene.removeMesh(overhangMesh)

    this.createOverhangEdge(overhangMesh, overhangs.edges)
    this.createOverhangVertex(overhangMesh, overhangs.vertices)

    if (!skipContour) {
      this.previousContours.push(overhangs.contours)
      if (this.previousContours.length > 2) {
        this.previousContours.splice(0, this.previousContours.length - 2)
      }

      if (this.previousContours.length === 2) {
        this.comparePreviousandCurrentOverhang()
      }
    }

    const overhangInsideMesh = this.createInsideMesh(
      overhangMesh,
      `${OVERHANG_INSIDE_MESH_NAME}_${OVERHANG_NAME}`,
      this.renderScene.getScene().getMaterialByID(OVERHANG_INSIDE_MATERIAL),
    )
    this.scene.removeMesh(overhangInsideMesh)

    return overhangMesh
  }

  private createOverhangEdge(overhangMesh: Mesh, edgesInfo: IEdgeInfo[]): Mesh[] {
    const lines: Mesh[] = []

    for (const info of edgesInfo) {
      const edgeTube = MeshBuilder.CreateTube(
        info.name,
        { path: info.path, cap: Mesh.CAP_ALL, radius: OVERHANG_RADIUS, sideOrientation: Mesh.BACKSIDE },
        this.renderScene.getScene(),
      )

      const componentId = uuid()
      const geometryId = uuid()
      const overhangShader = this.createOverhangShader()
      edgeTube.createNormals(true)
      edgeTube.id = info.id.toString()
      edgeTube.material = overhangShader.getMaterial()
      edgeTube.renderingGroupId = MESH_RENDERING_GROUP_ID
      edgeTube.parent = overhangMesh
      edgeTube.useVertexColors = false
      this.scene.removeMesh(edgeTube)

      const insideEdgeTube = this.createInsideMesh(
        edgeTube,
        `${OVERHANG_INSIDE_MESH_NAME}_${info.name}`,
        this.renderScene.getScene().getMaterialByName(OVERHANG_INSIDE_MATERIAL),
      )
      this.scene.removeMesh(insideEdgeTube)
      edgeTube.metadata = {
        componentId,
        geometryId,
        itemType: SceneItemType.OverhangEdgeTube,
        faces: [{ name: info.name, color: info.color }],
        faceMaterial: overhangShader,
        sourceMeshInside: insideEdgeTube,
        originalMaterial: overhangShader.getMaterial(),
        selectedFacesIds: new Map<number, number[]>(),
      }

      const edgeLine = MeshBuilder.CreateLines(info.name, { points: info.path }, this.renderScene.getScene())
      edgeLine.createNormals(true)
      edgeLine.id = info.id.toString()
      edgeLine.material = overhangShader.getMaterial()
      edgeLine.renderingGroupId = MESH_RENDERING_GROUP_ID
      edgeLine.parent = overhangMesh
      edgeLine.useVertexColors = false
      this.scene.removeMesh(edgeLine)
      edgeLine.metadata = {
        componentId,
        geometryId,
        itemType: SceneItemType.OverhangEdge,
        faces: [{ name: info.name, color: info.color }],
        faceMaterial: overhangShader,
        sourceMeshInside: null,
        originalMaterial: overhangShader.getMaterial(),
        selectedFacesIds: new Map<number, number[]>(),
      }

      edgeLine.metadata.pickingTube = edgeTube
      edgeTube.metadata.renderingLines = edgeLine

      this.setupSourceOverhangForGpuPicker(edgeTube)
      this.setupSourceOverhangForGpuPicker(edgeLine)

      lines.push(edgeLine)
    }

    return lines
  }

  private createOverhangVertex(overhangMesh: Mesh, verticesInfo: IVertexInfo[]): Mesh[] {
    const spheres: Mesh[] = []

    for (const info of verticesInfo) {
      const componentId = uuid()
      const geometryId = uuid()
      const overhangShader = this.createOverhangShader()
      const sphere = MeshBuilder.CreateSphere(
        info.name,
        { diameter: OVERHANG_RADIUS * 2, sideOrientation: Mesh.BACKSIDE },
        this.renderScene.getScene(),
      )
      sphere.createNormals(true)

      sphere.material = overhangShader.getMaterial()
      sphere.renderingGroupId = MESH_RENDERING_GROUP_ID
      sphere.position = info.origin
      sphere.parent = overhangMesh
      sphere.id = info.id.toString()
      sphere.useVertexColors = false
      this.scene.removeMesh(sphere)

      const insideSphere = this.createInsideMesh(
        sphere,
        `${OVERHANG_INSIDE_MESH_NAME}_${info.name}`,
        this.renderScene.getScene().getMaterialByName(OVERHANG_INSIDE_MATERIAL),
      )
      insideSphere.position = Vector3.Zero()
      this.scene.removeMesh(insideSphere)
      sphere.metadata = {
        componentId,
        geometryId,
        itemType: SceneItemType.OverhangVertex,
        faces: [{ name: info.name, color: info.color }],
        faceMaterial: overhangShader,
        sourceMeshInside: insideSphere,
        originalMaterial: overhangShader.getMaterial(),
        selectedFacesIds: new Map<number, number[]>(),
      }
      this.setupSourceOverhangForGpuPicker(sphere)

      spheres.push(sphere)
    }

    return spheres
  }

  private setupSourceOverhangForGpuPicker(overhang: Mesh) {
    if (this.meshManager.isOverhangSurface(overhang)) {
      return
    }

    const buffer = new Buffer(
      this.renderScene.getScene().getEngine(),
      new Array(overhang.getTotalVertices()).fill(0),
      false,
    )
    const faceColor = Color3.FromInts(
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
    )
    const pickingColor = Color3.FromInts(
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
      Math.ceil(Math.random() * 255),
    )
    overhang.setVerticesBuffer(buffer.createVertexBuffer(FACE_ID_ATTRIBUTE, 0, 1))
    overhang.registerInstancedBuffer(COLOR_FOR_FACE, 3)
    overhang.instancedBuffers.fColor = overhang.metadata.faces[0].color = faceColor
    overhang.registerInstancedBuffer(COLOR_FOR_PART, 3)
    overhang.instancedBuffers.pColor = Color3.White()
    overhang.registerInstancedBuffer(COLOR_FOR_BODY, 3)
    overhang.instancedBuffers.bColor = pickingColor
    overhang.registerInstancedBuffer(HOVER_FACE_ID, 1)
    overhang.instancedBuffers.hoverFaceId = new HoverableFace(-1)
    overhang.registerInstancedBuffer(COLLECTOR_INSTANCE_ID, 1)
    overhang.instancedBuffers.collectorInstanceId = new HoverableFace(-1)
    if (!overhang.metadata) {
      overhang.metadata = {}
    }

    overhang.metadata.pickingColor = pickingColor
    overhang.metadata.pickingShader = this.renderScene.getGpuPicker().pickingShaderWithZOffset
  }

  private setupInstanceOverhangForGpuPicker(overhang: InstancedMesh) {
    const bpItemMesh = this.meshManager.getBuildPlanItemMeshByChild(overhang)
    if (!this.meshManager.isOverhangSurface(overhang)) {
      overhang.instancedBuffers.fColor = overhang.sourceMesh.instancedBuffers.fColor
    }
    overhang.instancedBuffers.pColor = bpItemMesh.metadata.pickingColor
    overhang.instancedBuffers.bColor = overhang.sourceMesh.instancedBuffers.bColor
    overhang.instancedBuffers.hoverFaceId = new HoverableFace(-1)
  }

  private setSelectedFaces(source: Mesh, facesId: number[], instanceIndex: number) {
    if (!source) {
      return
    }

    if (!facesId || !facesId.length) {
      source.metadata.selectedFacesIds.delete(instanceIndex)
    } else {
      source.metadata.selectedFacesIds.set(instanceIndex, facesId)
    }
  }

  private isInsideBox(contour: Vector3[], box: Vector3[]): boolean {
    for (const vertex of contour) {
      if (
        vertex.x >= box[0].x &&
        vertex.x <= box[1].x &&
        vertex.y >= box[0].y &&
        vertex.y <= box[1].y &&
        vertex.z >= box[0].z &&
        vertex.z <= box[1].z
      ) {
        return true
      }
    }

    return false
  }

  private isInside(contour1: Vector3[], contour2: Vector3[]): boolean {
    const rayMagnitude = 1000
    let isIntersection = false
    contour2.push(contour2[0])
    for (const vertex of contour1) {
      const rayX = new Ray(new Vector3(vertex.x, vertex.y, 0), new Vector3(1, 0, 0), rayMagnitude)
      const rayY = new Ray(new Vector3(vertex.x, vertex.y, 0), new Vector3(0, 1, 0), rayMagnitude)
      for (let i = 0; i < contour2.length - 1; i += 1) {
        const isIntersectingX = rayX.intersectionSegment(
          new Vector3(contour2[i].x, contour2[i].y, 0),
          new Vector3(contour2[i + 1].x, contour2[i + 1].y, 0),
          1.0,
        )
        if (isIntersectingX > -1) {
          isIntersection = true
          break
        }

        for (let j = 0; j < contour2.length - 1; j += 1) {
          const isIntersectingY = rayY.intersectionSegment(
            new Vector3(contour2[j].x, contour2[j].y, 0),
            new Vector3(contour2[j + 1].x, contour2[j + 1].y, 0),
            1.0,
          )
          if (isIntersectingY > -1) {
            isIntersection = true
            break
          }
        }

        if (isIntersection) {
          break
        }
      }

      return isIntersection
    }
  }

  private comparePreviousandCurrentOverhang() {
    const previousContour = this.previousContours[0]
    const currentContour = this.previousContours[1]
    this.prevCurrentOverhangContourMap = new Map<string, string[]>()
    currentContour.vertices.forEach((currentContourData, currentContourName) => {
      const contourNames = []
      const currentOverhangBox = currentContour.box.get(currentContourName)
      const currentTriangleName = currentContourName.replace(OVERHANG_CONTOUR_NAME, OVERHANG_TRIANGLE_NAME)
      previousContour.vertices.forEach((previousContourData, previousContourName) => {
        const previousContourBox = previousContour.box.get(previousContourName)
        if (
          (this.isInside(previousContourData, currentContourData) ||
            this.isInside(currentContourData, previousContourData)) &&
          (this.isInsideBox(previousContourBox, currentOverhangBox) ||
            this.isInsideBox(currentOverhangBox, previousContourBox))
        ) {
          if (contourNames.length > 0) {
            const name = contourNames[0].replace(OVERHANG_TRIANGLE_NAME, OVERHANG_CONTOUR_NAME)
            const box1 = previousContour.box.get(name)
            const area1 = Math.abs(box1[1].x - box1[0].x) * Math.abs(box1[1].y - box1[0].y)

            const area2 =
              Math.abs(previousContourBox[1].x - previousContourBox[0].x) *
              Math.abs(previousContourBox[1].y - previousContourBox[0].y)

            if (area2 > area1) {
              contourNames[0] = previousContourName.replace(OVERHANG_CONTOUR_NAME, OVERHANG_TRIANGLE_NAME)
            }
          } else {
            contourNames.push(previousContourName.replace(OVERHANG_CONTOUR_NAME, OVERHANG_TRIANGLE_NAME))
          }
        }
      })

      this.prevCurrentOverhangContourMap.set(currentTriangleName, contourNames)
    })
  }
}
