
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import LabelSidebarMixin from '../mixins/LabelSidebarMixin'
import { ILabelStyle } from '@/types/Marking/ILabel'
import { PrintingTypes } from '@/types/IMachineConfig'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component
export default class MarkingTooltipContent extends LabelSidebarMixin {
  @buildPlansStore.Getter printingType: string

  @Prop() styleToShow: ILabelStyle

  get depthTooltipHeader(): string {
    if (this.printingType === PrintingTypes.DMLM) {
      return this.$t('labelTool.labelDepthInput.DMLM').toString()
    }
    if (this.printingType === PrintingTypes.BinderJet) {
      return this.$t('labelTool.labelDepthInput.BinderJet').toString()
    }

    return this.$t('labelTool.labelDepthInput.default').toString()
  }
}
