
import { Component, Mixins } from 'vue-property-decorator'

import { ToggleDetailsPanelButton } from '@/components/layout/FileExplorer/Details'
import TableHeaderMixin from '@/components/layout/FileExplorer/Table/mixins/TableHeaderMixin'
import TableHeaderCell from '@/components/layout/FileExplorer/Table/TableHeaderCell.vue'
import { DataTableHeaderCell } from '@/types/Common/DataTable'
import { SortParamsKey } from '@/types/FileExplorer/SortParamsKey'

const SORT_PARAMS_KEY = SortParamsKey.PrintOrders

@Component({
  components: { ToggleDetailsPanelButton, TableHeaderCell },
})
export default class PrintOrderHeader extends Mixins(TableHeaderMixin) {
  get headers(): { [key: string]: DataTableHeaderCell } {
    const headers: DataTableHeaderCell[] = [
      {
        text: 'Name',
        key: 'name',
        sortBy: 'name',
      },
      {
        text: 'Created on',
        key: 'createdAt',
        sortBy: 'createdAt',
      },
      {
        text: 'Status',
        key: 'status',
        sortBy: 'status',
      },
      {
        text: 'Site',
        key: 'site',
        sortBy: 'site',
      },
    ]

    return this.headersToMap(headers)
  }

  sortStatusKey = SORT_PARAMS_KEY
  sortSiteKey = SORT_PARAMS_KEY
}
