
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import VSelect from 'vuetify/lib/components/VSelect'
import { getPropertyFromItem } from 'vuetify/lib/util/helpers'
import { ClickOutsideMixin } from '@/components/layout/mixins/ClickOutsideMixin'
import MachineSelectorTemplate from '../Common/MachineSelectorTemplate.vue'
import LabelFontStyleSelectorRow from '@/components/layout/buildPlans/marking/LabelFontStyleSelectorRow.vue'
import LabelFontStyleSelectedValue from '@/components/layout/buildPlans/marking/LabelFontStyleSelectedValue.vue'

@Component({ components: { MachineSelectorTemplate, LabelFontStyleSelectorRow, LabelFontStyleSelectedValue } })
export default class LabelToolSelect extends ClickOutsideMixin {
  @Prop() placeholder: string
  @Prop() label: string
  @Prop() items: []
  @Prop() value: number
  @Prop() disabled: true
  @Prop({ default: false }) hideDetails: boolean
  @Prop({ type: [Object, String], default: '' }) readonly rules: object | string
  @Prop({ type: Boolean, default: false }) readonly delimiter: boolean
  @Prop({ type: [String, Array, Function], default: 'id' }) itemValue: object | string
  @Prop({ type: [String, Array, Function], default: 'name' }) itemText: object | string
  @Prop({ type: Boolean, default: false }) isMachineSelector: boolean
  @Prop({ type: Boolean, default: false }) validateImmediately: boolean
  @Prop({ type: Boolean, default: false }) isFontStyleSelector: boolean
  @Prop({ type: Boolean, default: false }) attach: boolean

  $refs!: {
    selector: VSelect
  }

  isMounted: boolean = false

  get isActiveMenu() {
    return (
      this.isMounted &&
      this.$refs.selector.$refs &&
      this.$refs.selector.$refs.menu &&
      this.$refs.selector.$refs.menu.isActive
    )
  }

  get isFocused() {
    return this.$refs.selector.isFocused
  }

  @Watch('isActiveMenu')
  onIsMenuActiveChanged() {
    this.setListenerForClickOutside(this.isActiveMenu, this.closeLabelToolMenu)
  }

  closeLabelToolMenu() {
    this.$refs.selector.$refs.menu.isActive = false
  }

  mounted() {
    this.isMounted = true
  }

  input(e) {
    this.$emit('input', e)
  }

  getItemText(item) {
    return getPropertyFromItem(item, this.itemText, item)
  }
}
