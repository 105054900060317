
import Selector from '@/components/controls/Common/Selector.vue'
import SimulationColoring from '@/components/layout/buildPlans/simulate/SimulationColoring.vue'
import StoresNamespaces from '@/store/namespaces'
import { ISimulationStep } from '@/types/Simulation/SimulationSteps'
import { getLabelFromPower, numberToPowerOf10 } from '@/utils/number'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const visualizationStore = namespace(StoresNamespaces.Visualization)
const commonStore = namespace(StoresNamespaces.Common)

@Component({ components: { Selector, SimulationColoring } })
export default class IbcPlanCompensateResults extends Vue {
  @visualizationStore.Mutation('viewSimulationResults') viewSimulationResults: Function
  @visualizationStore.Mutation('setIsLoading') setIsLoading: Function

  @visualizationStore.Getter('getSelectedSimulationStep') getSelectedSimulationStep: ISimulationStep
  @visualizationStore.Getter('getSimulationSteps') getSimulationSteps: ISimulationStep[]
  @visualizationStore.Getter('getLoadedSimulationSteps') getLoadedSimulationSteps: string[]
  @visualizationStore.Getter('getVisualizationLoading') getVisualizationLoading: boolean
  @commonStore.Getter tooltipOpenDelay: number

  get viewResultsLabel() {
    if (this.getSelectedSimulationStep) {
      const sizeInfo = numberToPowerOf10(this.getSelectedSimulationStep.size)
      if (sizeInfo) {
        const label = getLabelFromPower(sizeInfo.baseOf10)
        return `${this.$t('viewResults')} (${sizeInfo.number} ${label})`
      }
    }

    return this.$t('viewResults')
  }

  get simulateDisabled() {
    return (
      this.getVisualizationLoading ||
      !this.getSelectedSimulationStep ||
      this.getLoadedSimulationSteps.indexOf(this.getSelectedSimulationStep.value) !== -1
    )
  }

  showResults() {
    this.setIsLoading(true)
    this.viewSimulationResults()
  }
}
