
import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { FileExplorerItemSharedByUser } from '@/types/FileExplorer/FileExplorerItem'
import StoresNamespaces from '@/store/namespaces'
import TableRowMixin from '@/components/layout/FileExplorer/Table/mixins/TableRowMixin'
import ItemListRowBadges from '@/components/layout/FileExplorer/Table/ItemListRowBadges.vue'
import MoveOrCopyModal from '@/components/layout/FileExplorer/MoveOrCopyModal.vue'
import { formatDateWithDefaultFormat } from '@/utils/common'
import RowItemMenu from '../RowItemMenu.vue'
import { ContextMenuItem } from '@/types/FileExplorer/ContextMenuItem'

const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)

@Component({
  components: { ItemListRowBadges, RowItemMenu, MoveOrCopyModal },
})
export default class SharedByMeItemListRow extends Mixins(TableRowMixin) {
  @fileExplorerStore.Getter('isItemFavorite') isItemFavorite: (itemId: string) => boolean

  @Prop() item: FileExplorerItemSharedByUser
  @Prop() contextMenuItems: ContextMenuItem[]
  @Prop() beforeContextMenuOpen: () => Promise<void>

  hoveredOverRow: boolean = false

  $refs: {
    rowItemMenu: RowItemMenu
  }

  get sharedAt(): string {
    const date = new Date(this.item.sharedDate)
    return formatDateWithDefaultFormat(date)
  }

  onItemRightClick(event) {
    this.$refs.rowItemMenu.showContextMenu(event, false)
  }
}
