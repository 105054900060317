export enum WrxSupportedMachines {
  M2_400W_3D_Series4 = 'M2 400W 3D Series4',
  M2_400W_3D_Series4_PLUS = 'M2 400W 3D Series4+',
  M2_Series_5 = 'M2 Series 5',
  X_LINE_2000R_BC2X = 'X-Line 2000R BC2X',
}

export enum WrxUploadSupportedProcessParameterExtensions {
  PROPCAR = 'procpar',
}

export enum WrxUploadSupportedPartParameterExtensions {
  PAR = 'par',
  XPAR = 'xpar',
}
