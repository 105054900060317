import { Visualization } from '@/visualization'
import { InteractiveServiceEvents } from '@/types/InteractiveService/InteractiveServiceEvents'
import { eventBus } from '@/services/EventBus'
import { IPendingInsights } from '@/types/BuildPlans/IBuildPlanInsight'
import { BoundingBox2D } from '@/visualization/models/DataModel'
import { IVisualizationState } from '@/store/modules/visualization/types'
import { SINGLE_PART_VISUALIZATION_NAMESPACE } from '@/constants'
import { SceneMode } from '@/visualization/types/SceneTypes'

const loadPartConfigQueue = []

export default function createSinglePartVisualizationPlugin() {
  return (store) => {
    // Handle store mutations and call appropriate functions inside visualization
    // for the case when there are two canvases (build plan and single part) on the page at the same time
    store.subscribe(async (mutation, state) => {
      if (mutation.payload && mutation.payload.silent) {
        return
      }

      const visualizationState: IVisualizationState = state[SINGLE_PART_VISUALIZATION_NAMESPACE] as IVisualizationState

      if (!visualizationState) {
        return
      }

      const visualization: Visualization = visualizationState.visualization

      // TODO: Remove cases that are irrelevant for a part in the Add Part Tool
      switch (mutation.type) {
        case 'singlePartVisualizationModule/init':
          await visualization.init(
            mutation.payload.canvasId,
            SceneMode.SinglePart,
            mutation.payload.viewMode,
            mutation.payload.loadDefaultPlate,
          )

          store.commit('singlePartVisualizationModule/initialized')
          if (store.state.singlePartVisualizationModule.needToDispose) {
            store.commit('singlePartVisualizationModule/dispose')
            return
          }

          visualization.configLoadedEvent.on((payload) => {
            store.dispatch('buildPlans/createBuildPlanItem', { ...payload, silent: true })
          })

          visualization.configsLoadedEvent.on((payload) => {
            store.dispatch('buildPlans/saveReplacementBuildPlanItems', { ...payload, silent: true })
          })

          if (visualization.elementsSelected) {
            visualization.elementsSelected.on((selectedElements) => {
              store.commit('buildPlans/selectItems', { ...selectedElements, silent: true })
            })
          }

          if (visualization.selectedElementsCollisions) {
            visualization.selectedElementsCollisions.on((collidedElements) => {
              store.commit('buildPlans/setSelectedPartsCollisions', { elements: collidedElements, silent: true })
            })
          }

          if (visualization.labelAdded) {
            visualization.labelAdded.on((payload) => {
              store.commit('singlePartVisualizationModule/deactivateAddLabelMode')
              store.commit('buildPlans/setTempLabel', payload)
            })
          }

          if (visualization.labelUpdated) {
            visualization.labelUpdated.on((payload) => {
              store.dispatch('buildPlans/updateBuildPlanItemLabel', payload)
            })
          }

          if (visualization.initializeSlicer) {
            visualization.initializeSlicer.on((payload) => {
              store.commit('singlePartVisualizationModule/initSlicer', { ...payload, silent: true })
            })
          }

          if (visualization.changeIsLoading) {
            visualization.changeIsLoading
              .filter((h) => !!h)
              .forEach((h) =>
                h.on((payload) => {
                  store.commit('visualizationModule/setIsLoading', payload.isLoading)
                }),
              )
          }

          if (visualization.updateItemPreviewEvent) {
            visualization.updateItemPreviewEvent.on(async (payload) => {
              await store.dispatch('singlePartVisualizationModule/updateItemPreview', { ...payload, silent: true })
              await store.dispatch('fileExplorer/fetchAndUpdateItem', payload.itemId)
            })
          }

          if (visualization.addGeometryProperties) {
            visualization.addGeometryProperties.on((payload) => {
              store.dispatch('buildPlans/updateBuildPlanItem', { params: { ...payload, silent: true } })
            })
          }

          if (visualization.changeBuildPlate) {
            visualization.changeBuildPlate.on((payload) => {
              store.commit('buildPlans/setBuildPlate', payload)
            })
          }

          if (visualization.generateOverhangMeshByClickEvent) {
            visualization.generateOverhangMeshByClickEvent.on((payload) => {
              eventBus.$emit(InteractiveServiceEvents.GenerateOverhanhgMeshByClick, payload)
            })
          }

          if (visualization.generateOverhangMeshEventDebounced) {
            visualization.generateOverhangMeshEventDebounced.on((payload) => {
              eventBus.$emit(InteractiveServiceEvents.GenerateOverhangMeshDebounced, payload)
            })
          }

          if (visualization.onGetBPNameByBPId) {
            visualization.onGetBPNameByBPId.on((payload) => {
              const name = store.getters['buildPlans/getBPNameByBPId'](payload.buildPlanId)
              payload.callback(name)
            })
          }

          if (visualization.deletePartsInState) {
            visualization.deletePartsInState.on((payload) => {
              store.commit('buildPlans/deletePartsInState', payload)
            })
          }

          if (visualization.overhangsElementsEvent) {
            visualization.overhangsElementsEvent.on((payload) => {
              eventBus.$emit(InteractiveServiceEvents.GetOverhangElements, payload)
            })
          }

          if (visualization.deleteOverhangsAndSupportsEvent) {
            visualization.deleteOverhangsAndSupportsEvent.on((payload) => {
              store.dispatch('buildPlans/removeSupports', {
                params: {
                  buildPlanItemId: payload,
                  silent: true,
                },
              })

              // emit event for locking overhangs and support interactive rendering if support tab is opened
              eventBus.$emit(InteractiveServiceEvents.LockOverhangsAndSupportsRendering, payload)
            })
          }

          if (visualization.onSelectionBoundingGeometryReadyEvent) {
            visualization.onSelectionBoundingGeometryReadyEvent.on((payload: BoundingBox2D) => {
              store.commit('singlePartVisualizationModule/setBoundingBox2D', payload)
            })
          }

          if (visualization.onSetInstancingIsRunning) {
            visualization.onSetInstancingIsRunning.on((isRunning: boolean) => {
              store.commit('singlePartVisualizationModule/setInstancingIsRunning', isRunning)
            })
          }

          if (visualization.reportInsightIssues) {
            visualization.reportInsightIssues.on((insights: IPendingInsights[]) => {
              store.commit('buildPlans/reportInsightIssues', insights)
            })
          }

          if (visualization.onCameraPositionChangedEvent) {
            visualization.onCameraPositionChangedEvent.on((position) => {
              store.commit('singlePartVisualizationModule/setCameraPostion', position)
            })
          }

          if (visualization.hoverBody) {
            visualization.hoverBody.on((body) => {
              store.commit('buildPlans/hoverBody', { ...body })
            })
          }

          if (visualization.hoverDefect) {
            visualization.hoverDefect.on((payload) => {
              store.commit('parts/setHoveredDefect', payload.type)
            })
          }

          if (visualization.selectDefectsEvt) {
            visualization.selectDefectsEvt.on((payload) => {
              store.commit('parts/setSelectedDefectsTypes', payload)
            })
          }
          break
        case 'singlePartVisualizationModule/initDetailsPreview':
          visualization.initDetailsPreview(mutation.payload.canvasId, mutation.payload.sceneMode).then(() => {
            store.commit('singlePartVisualizationModule/initialized')
            if (store.state.singlePartVisualizationModule.needToDispose) {
              store.commit('singlePartVisualizationModule/disposeDetailsPreview')
            }
          })
          break
        case 'singlePartVisualizationModule/dispose':
          await visualization.dispose()
          if (mutation.payload) {
            mutation.payload()
          }
          break
        case 'singlePartVisualizationModule/clearDetailsPreview':
          visualization.clearDetailsPreview()
          break
        case 'singlePartVisualizationModule/showGizmos':
          visualization.showGizmos()
          break
        case 'singlePartVisualizationModule/hideGizmos':
          visualization.disableGizmos(true)
          break
        case 'singlePartVisualizationModule/deselect':
          visualization.deselect()
          break
        case 'singlePartVisualizationModule/checkCollision':
          visualization.checkCollision()
          break
        case 'singlePartVisualizationModule/undo':
          visualization.undo()
          break
        case 'singlePartVisualizationModule/enableCrossSectionMode':
          visualization.setCrossSection(mutation.payload)
          break
        case 'singlePartVisualizationModule/enableSlicerMode':
          visualization.setSlicer(mutation.payload)
          break
        case 'singlePartVisualizationModule/changeCurrentSlicerValue':
          visualization.changeCurrentSlicer(mutation.payload)
          break
        case 'singlePartVisualizationModule/showMeshes':
          visualization.showMeshes()
          break
        case 'singlePartVisualizationModule/hideMeshes':
          visualization.hideMeshes()
          break
        case 'singlePartVisualizationModule/showSlicePolylines':
          visualization.showSlicePolylines(mutation.payload.polylines, mutation.payload.type)
          break
        case 'singlePartVisualizationModule/hideSlicePolylines':
          visualization.hideSlicePolylines()
          break
        case 'singlePartVisualizationModule/selectAndHighlightPart':
          visualization.selectAndHighlightPart(
            mutation.payload.buildPlanItemId,
            mutation.payload.meshId,
            mutation.payload.deselectIfSelected,
            mutation.payload.showGizmo,
          )
          break
        case 'singlePartVisualizationModule/selectDefects':
          visualization.selectDefects(mutation.payload)
          break
        case 'singlePartVisualizationModule/toggleHighlight':
          visualization.toggleHighlight(
            mutation.payload.buildPlanItemId,
            mutation.payload.meshId,
            mutation.payload.highlight,
          )
          break
        case 'singlePartVisualizationModule/toggleDefectsHighlight':
          visualization.toggleDefectsHighlight(mutation.payload)
          break
        case 'singlePartVisualizationModule/selectAndHighlightPartWithAttach':
          visualization.selectAndHighlightPartWithAttach(mutation.payload.buildPlanItemIds)
          break
        case 'singlePartVisualizationModule/rotatePart':
          visualization.rotatePart(
            mutation.payload.partItemIndex,
            mutation.payload.x,
            mutation.payload.y,
            mutation.payload.z,
            mutation.payload.transformation,
          )
          break
        case 'singlePartVisualizationModule/savePartOrientation':
          visualization.savePartOrientation(mutation.payload.partItemIndex)
          break
        case 'singlePartVisualizationModule/changeViewMode':
          visualization.changeViewMode(mutation.payload)
          break
        case 'singlePartVisualizationModule/zoomToFitCamera':
          visualization.zoomToFitCamera()
          break
        case 'singlePartVisualizationModule/changeVisualizationMode':
          visualization.changeVisualizationMode(mutation.payload)
          break
        case 'singlePartVisualizationModule/changeView':
          visualization.changeView(mutation.payload)
          break
        case 'singlePartVisualizationModule/setViewLocked':
          visualization.setViewLocked(mutation.payload)
          break
        case 'visualizationModule/setLabelStyle':
          visualization.setLabelStyle(mutation.payload)
          break
        case 'visualizationModule/updateLabelAppearance':
          await visualization.updateLabelAppearance(mutation.payload.id, mutation.payload.style)
          break
        case 'visualizationModule/activateLabelCreation':
          visualization.activateLabelCreation()
          break
        case 'visualizationModule/deactivateLabelCreation':
          visualization.deactivateLabelCreation()
          break
        case 'singlePartVisualizationModule/activateLabelInteraction':
          visualization.activateLabelInteraction(mutation.payload)
          break
        case 'singlePartVisualizationModule/deactivateLabelInteraction':
          visualization.deactivateLabelInteraction(mutation.payload)
          break
        case 'singlePartVisualizationModule/deleteRenderedLabel':
          visualization.deleteLabel(mutation.payload)
          break
        case 'singlePartVisualizationModule/toggleComponentHighlight':
          visualization.toggleComponentHighlight(
            mutation.payload.componentId,
            mutation.payload.showHighlight,
            mutation.payload.excludedBPItems,
          )
          break
        case 'singlePartVisualizationModule/labelInstance':
          visualization.labelInstance(mutation.payload)
          break
        case 'singlePartVisualizationModule/loadPartConfig':
          store.commit('buildPlans/setIsLoading', true)
          loadPartConfigQueue.push(mutation.payload)
          await visualization.loadPartConfig(mutation.payload)
          loadPartConfigQueue.shift()
          if (!loadPartConfigQueue.length) {
            store.commit('buildPlans/setIsLoading', false)
          }

          break
        case 'singlePartVisualizationModule/toggleLabelHighlight':
          visualization.toggleLabelHighlight(mutation.payload.labelId, mutation.payload.highlight)
          break
        case 'singlePartVisualizationModule/applyTransformationMatrix':
          visualization.applyTransformatonMatrix(
            mutation.payload.buildPlanItemId,
            mutation.payload.transformation,
            mutation.payload.options,
          )
          break
        case 'singlePartVisualizationModule/addOverhangMesh':
          visualization.addOverhangMesh(mutation.payload.buildPlanItemId, mutation.payload.id, mutation.payload.config)
          break
        case 'visualizationModule/updateOverhangMesh':
          visualization.updateOverhangMesh(
            mutation.payload.buildPlanItemId,
            mutation.payload.id,
            mutation.payload.overhang,
          )
          break
        case 'singlePartVisualizationModule/clearOverhangMesh':
          visualization.clearOverhangMesh(mutation.payload)
          break
        case 'singlePartVisualizationModule/setGeometriesVisibility':
          visualization.setGeometriesVisibility(
            mutation.payload.items,
            mutation.payload.geometryType,
            mutation.payload.visibility,
          )
          break
        case 'singlePartVisualizationModule/addSupportMesh':
          visualization.addSupportMesh(
            mutation.payload.buildPlanItemId,
            mutation.payload.sdata,
            mutation.payload.belongsToOverhangElementName,
          )
          break
        case 'singlePartVisualizationModule/addSupportBvhAndHull':
          visualization.addSupportBvhAndHull(
            mutation.payload.buildPlanItemId,
            mutation.payload.supportsBvhFileKey,
            mutation.payload.supportsHullFileKey,
          )
          break
        case 'singlePartVisualizationModule/clearSupports':
          visualization.clearSupports(
            mutation.payload.buildPlanItemId,
            mutation.payload.overhangElementsToClear,
            mutation.payload.skipGeomProps,
          )
          break
        case 'singlePartVisualizationModule/highlightErrorOverhangZone':
          visualization.highlightErrorOverhangZone(mutation.payload.buildPlanItemId, mutation.payload.overhangZoneName)
          break
        case 'singlePartVisualizationModule/updateItemPreview':
          visualization.updateItemPreview(mutation.payload.itemId)
          break
        case 'singlePartVisualizationModule/setDefaultOverhangMaterial':
          visualization.setDefaultOverhangMaterial(
            mutation.payload.buildPlanItemId,
            mutation.payload.overhangElementsToClear,
          )
          break
        case 'singlePartVisualizationModule/highlightSupports':
          visualization.highlightSupports(mutation.payload.buildPlanItemId, mutation.payload.overhangElementNames)
          break
        case 'singlePartVisualizationModule/transferSupports':
          visualization.transferSupports(mutation.payload.sourceId, mutation.payload.targetIds)
          break
        case 'singlePartVisualizationModule/setPartsVisibility':
          visualization.setPartsVisibility(mutation.payload.ids, mutation.payload.visibility)
          break
        case 'singlePartVisualizationModule/setSendBoundingAnchorPoints':
          visualization.setSendBoundingAnchorPoints(mutation.payload)
          break
        case 'singlePartVisualizationModule/getItemsBoundingBox2D':
          const points: BoundingBox2D = visualization.getItemsBoundingBox2D(mutation.payload)
          store.commit('singlePartVisualizationModule/setBoundingBox2D', points)
          break
        case 'singlePartVisualizationModule/resizeCanvas':
          visualization.resizeCanvas()
          break
        case 'singlePartVisualizationModule/highlightBody':
          visualization.highlightBody(mutation.payload.id, mutation.payload.showHighlight)
          break
        case 'singlePartVisualizationModule/calcGeometryPropsForSinglePart':
          visualization
            .getSinglePartGeometryProps(null, false, true)
            .then((props) => store.commit('buildPlans/setAddPartGeometryProps', props))
          break
        case 'singlePartVisualizationModule/updateScaleForSinglePart':
          visualization.updateScaleForSinglePart(
            mutation.payload.selectedPartId,
            mutation.payload.parameterSetScaleFactor,
          )
          break
        default:
          break
      }
    })
  }
}
