import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'

export enum JobType {
  SIMULATE = 'SIMULATE',
  COMPENSATE = 'COMPENSATE',
  SIM = 'SIM',
  COMP = 'COMP',
  IMPORT = 'IMPORT',
  SLICE = 'SLICE',
  ESTIMATE = 'ESTIMATE',
  GENERATE_STL = 'GENERATE_STL',
  EXPOSURE = 'EXPOSURE',
  ORIENT = 'ORIENT',
  PRINT = 'PRINT',
  MARK = 'MARK',
  NEST = 'NEST',
  PUBLISH = 'PUBLISH',
  MESH = 'MESH',
  ICOMPENSATE = 'ICOMPENSATE',
  IBC_PUBLISH = 'PUBLISH',
}

export enum JobStatusCode {
  CREATED = 'CREATED',
  COMPLETE = 'COMPLETE',
  CANCELLING = 'CANCELLING',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  PENDING = 'PENDING',
  IN_PRINT_QUEUE = 'IN_PRINT_QUEUE',
  LOADED = 'LOADED',
  PRINTING = 'PRINTING',
  UNKNOWN = 'UNKNOWN',
  PRINT_ERROR = 'PRINT_ERROR',
  PRINT_CANCELLED = 'PRINT_CANCELLED',
  SITE_DOWNLOAD_ERROR = 'SITE_DOWNLOAD_ERROR',
  WARNING = 'WARNING',
}

export enum FileTypes {
  PART = 'PART',
  SUPPORT = 'SUPPORT',
  PARAMETER = 'PARAMETER',
  PART_STL = 'PART_STL',
  SUPPORT_STL = 'SUPPORT_STL',
  MM_STL = 'MM_STL',
  COMPENSATED_PART_STL = 'COMPENSATED_PART_STL',
  COMPENSATED_SUPPORT_STL = 'COMPENSATED_SUPPORT_STL',
  STREAMCACHE = 'STREAMCACHE',
  BT_METADATA = 'BT_METADATA',
  BV_METADATA = 'BV_METADATA',
  SLICE = 'SLICE',
  SLICE_EXPORT_ZIP = 'SLICE_EXPORT_ZIP',
  EXPOSURE = 'EXPOSURE',
  INITIAL_BUILD_DISTORTION = 'INITIAL_BUILD_DISTORTION',
  RECOATER_CLEARANCE_EVENT = 'RECOATER_CLEARANCE_EVENT',
  AERIAL_VIEW = 'AERIAL_VIEW',
  DISPLACEMENT_ANIMATION = 'DISPLACEMENT_ANIMATION',
  TEMPERATURE_ANIMATION = 'TEMPERATURE_ANIMATION',
  STRESS_ANIMATION = 'STRESS_ANIMATION',
  RESIDUAL_DISTORTION_ERROR = 'RESIDUAL_DISTORTION_ERROR',
  RESIDUAL_DISTORTION_ERROR_XRAY = 'RESIDUAL_DISTORTION_ERROR_XRAY',
  MAXIMUM_ERROR = 'MAXIMUM_ERROR',
  MEAN_ERROR = 'MEAN_ERROR',
  GEOMETRY_OVERLAY = 'GEOMETRY_OVERLAY',
  CHECKPOINT = 'CHECKPOINT',
  DRACO = 'DRACO',
  DRACO_CONFIG = 'DRACO_CONFIG',
  BVH = 'BVH',
  HULL = 'HULL',
  THUMBNAIL = 'THUMBNAIL',
  ORIENT = 'ORIENT',
  ARSF = 'ARSF',
  NEST = 'NEST',
  ORIENT_CLUSTER_DATA = 'ORIENT_CLUSTER_DATA',
  SUPPORTS_BVH = 'SUPPORTS_BVH',
  SUPPORTS_HULL = 'SUPPORTS_HULL',
}

export interface IJobStatus {
  code: JobStatusCode
  description: string
}

export interface IJobKey {
  projectId: string
  projectName: string
  projectType: string
  jobNumber: number
}

export interface IJob {
  id: string
  number: number
  cancelRequestDate: string
  cancelledByUser: string
  cancelledDate: string
  computeType: string
  createdDate: string
  endDate: string
  jobName: string
  jobType: JobType
  queueType: string
  queuedDate: string
  runByUser: string
  status: IJobStatus
  jobKey: IJobKey
  runningDate: string
  code: JobStatusCode
  description: string
  updatedDate: string
  itemId: string
  parameters?: any
  relatedPlanId?: string
}

export interface JobDto extends IJob {
  itemType: ItemType
  itemSubType: ItemSubType
  itemName: string
  itemVersion: string
  itemVersionLabel: string
  itemParentPathLabel?: string
  itemParentId?: string
  runByUserName: string
}
