
import Vue from 'vue'
import { extend } from 'vee-validate'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { CONTOUR_PIXEL_LEVEL_MAX, CONTOUR_PIXEL_LEVEL_MIN } from '@/constants'
import HoverableTooltip from '@/components/modals/HoverableTooltip.vue'

const CONTOUR_PIXEL_SLIDER_MIN = 1

@Component({ components: { HoverableTooltip } })
export default class ContourPixelLevelInput extends Vue {
  @Prop() contourPixelLevelModel: any
  @Prop() disabled: boolean

  contourPixelSliderValue = CONTOUR_PIXEL_SLIDER_MIN
  contourPixelSliderLevels = ['1', '2', '3']
  isContourPixelLevelEnabled = false
  constants = { CONTOUR_PIXEL_SLIDER_MIN, CONTOUR_PIXEL_LEVEL_MAX }

  get sliderInputColor() {
    const disabledColor = '#bfbfbf'
    const defaultColor = '#005eb8'

    return this.disabled ? disabledColor : defaultColor
  }

  get isContourPixelSliderDisabled() {
    return this.contourPixelLevelModel === 0
  }

  get rules() {
    return {
      range: {
        leftLimit: CONTOUR_PIXEL_LEVEL_MIN,
        rightLimit: CONTOUR_PIXEL_LEVEL_MAX,
        units: this.$i18n.t('pixelsAbbr'),
      },
    }
  }

  beforeMount() {
    this.extendValidationRules()
  }

  mounted() {
    this.contourPixelSliderValue = this.contourPixelLevelModel
    this.setContourPixelLevel(this.contourPixelLevelModel)
  }

  @Watch('isContourPixelLevelEnabled')
  onCounterPixelInputChanged() {
    if (this.isContourPixelLevelEnabled) {
      this.setContourPixelLevel(this.contourPixelSliderValue)
      return
    }

    this.setContourPixelLevel(0)
  }

  @Watch('contourPixelLevelModel')
  onCounterPixelLevelValueChange(value: number) {
    this.updateContourPixelInputs(value)
  }

  extendValidationRules() {
    extend('range', {
      params: ['leftLimit', 'rightLimit', 'units'],
      message: this.$i18n.t('inRangeValidationMessage').toString(),
      validate: (value) => {
        return value <= CONTOUR_PIXEL_LEVEL_MAX && value >= CONTOUR_PIXEL_LEVEL_MIN
      },
    })
  }

  updateContourPixelInputs(value: number) {
    this.isContourPixelLevelEnabled = !!value
    this.contourPixelSliderValue = value || 1
  }

  setContourPixelLevel(value: number) {
    this.updateContourPixelInputs(value)
    this.$emit('input', value)
  }

  onSliderValueChanged() {
    this.setContourPixelLevel(this.contourPixelSliderValue)
  }
}
