import { RouterNames } from '@/router'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { JobType } from '@/types/PartsLibrary/Job'
import { TOOL_HTML_TYPE } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

export interface IBCPlanTool {
  key: IBCPlanToolNames
  isPreview?: boolean
  isPreparing?: boolean
  routerName: RouterNames
  icon: string
  divide: boolean
  viewMode: ViewModeTypes
  setViewMode?: boolean
  waysToEnable: any[]
  jobTypes?: any[]
  sidebarStyling?: {
    keepList: boolean
    hideHeader: boolean
    hideFooter: boolean
    hideBody: boolean
  }
  hidden?: boolean
  button?: true
  toolHtmlType?: string
  shouldShowStatusIcon?: boolean
  placeholder?: string
}

export default class IBCPlanSidebarTools {
  static getIBCPlanTools(): IBCPlanTool[] {
    return [
      {
        key: IBCPlanToolNames.INSPECTIONS,
        routerName: RouterNames.IBC_Inspections,
        icon: 'mdi-chart-timeline-variant-shimmer',
        divide: false,
        viewMode: ViewModeTypes.Inspections,
        jobTypes: [],
        waysToEnable: [],
      },
      {
        key: IBCPlanToolNames.DEVIATION_COMPENSATE,
        routerName: RouterNames.IBC_DeviationCompensate,
        icon: 'mdi-car-windshield-outline mdi-flip-v',
        divide: true,
        viewMode: ViewModeTypes.DeviationCompensate,
        jobTypes: [JobType.ICOMPENSATE],
        waysToEnable: [],
      },
      {
        key: IBCPlanToolNames.PUBLISH,
        routerName: null,
        icon: 'mdi-publish',
        divide: false,
        viewMode: null,
        jobTypes: [JobType.IBC_PUBLISH],
        waysToEnable: [],
        button: true,
        hidden: false,
      },
      {
        key: IBCPlanToolNames.SELECT_PUBLISHED_PARTS,
        routerName: null,
        icon: null,
        divide: false,
        viewMode: null,
        jobTypes: [],
        waysToEnable: [],
        hidden: true,
        toolHtmlType: TOOL_HTML_TYPE.MENU,
        placeholder: 'onePublishedPart',
      },
    ]
  }
}

export enum IBCPlanToolNames {
  INSPECTIONS = 'inspections',
  DEVIATION_COMPENSATE = 'deviationCompensate',
  PUBLISH = 'publish',
  SELECT_PUBLISHED_PARTS = 'selectPublishedParts',
}
