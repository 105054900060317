
import { RouterPaths } from '@/router'
import StoresNamespaces from '@/store/namespaces'
import { IUser } from '@/types/User/IUser'
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'

const userStore = namespace(StoresNamespaces.User)

@Component({
  components: {},
})
export default class DefaultAppFooter extends Vue {
  @userStore.Getter isAuthenticated: boolean //
  @userStore.Getter('getUserDetails') getUserDetails: IUser

  goToSitemapPage() {
    // @ts-ignore
    this.$router.safePush(RouterPaths.SiteMap)
  }
}
