const VUETIFY_ROOT_APP_CLASS = 'v-application'

export const VUETIFY_MENU_CONTENT_CLASS = 'v-menu__content'

export function isClickedInside(event: any, elementClassName?: string) {
  let node = event.target
  let inside = false
  const className = elementClassName ? elementClassName : VUETIFY_MENU_CONTENT_CLASS

  while (node && !node.classList.contains(VUETIFY_ROOT_APP_CLASS)) {
    if (node.classList.contains(className) || node.classList.contains(VUETIFY_MENU_CONTENT_CLASS)) {
      inside = true
      break
    }
    node = node.parentElement
  }

  return inside
}
