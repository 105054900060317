export enum AmpUnit {
  CelsiusDegree = '°C',
  KilogramPerCubicMeter = 'kg/m^3',
  LiterPerMinute = 'L/min',
  Pascal = 'Pa',
  MegaPascal = 'MPa',
  Millimeter = 'mm',
  MillimeterOfWater = 'mmH20',
  MillimeterPerSecond = 'mm/s',
  Millisecond = 'ms',
  Second = 's',
  Percent = '%',
  RadiansPerSecond = '1/s',
  RevolutionsPerSecond = 'rev/s',
  RevolutionsPerMinute = 'rpm',
  Revolutions = 'rev',
}
