
import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

import StoresNamespaces from '@/store/namespaces'
import { IBuildPlan, IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'
import { IConstraintsOptions } from '@/types/BuildPlans/IConstraints'
import ITransformationDelta from '@/types/BuildPlans/ITransformationDelta'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import CommonBuildPlanToolsMixin from './mixins/CommonBuildPlanToolsMixin'
import NumberField from '@/components/controls/Common/NumberField.vue'
import SpinButtons from '@/components/controls/Common/SpinButtons.vue'
import { ToolTypes } from '@/types/BuildPlans/ToolTypes'
import { ICommand } from '@/types/UndoRedo/ICommand'
import { DragCommand } from '@/types/UndoRedo/DragCommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { ToolNames } from './BuildPlanSidebarTools'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const visualizationStore = namespace(StoresNamespaces.Visualization)
const commonStore = namespace(StoresNamespaces.Common)
const commandManager = namespace(StoresNamespaces.CommandManager)

const defaultMoveModel: ITransformationDelta = { x: null, y: null, z: null }

@Component({
  components: {
    NumberField,
    SpinButtons,
  },
})
export default class BuildPlanMoveTab extends Mixins(CommonBuildPlanToolsMixin) implements IToolComponent {
  @commonStore.Getter tooltipOpenDelay: number

  @buildPlansStore.Action('moveSelectedItemsToDelta') moveSelectedItemsToDelta: (delta: ITransformationDelta) => void
  @buildPlansStore.Action('recenter') recenter: () => void
  @buildPlansStore.Action('updateIncrement') updateIncrement: (increment: number) => void
  @buildPlansStore.Action restoreImportedLocation: () => void

  @buildPlansStore.Getter('getBuildPlan') buildPlan: IBuildPlan
  @buildPlansStore.Getter('getSelectedBuildPlanItems') buildPlanItems: IBuildPlanItem[]

  @visualizationStore.Mutation('updateMoveIncrement') updateMoveIncrement: Function

  @commandManager.Getter getToolUndoStack: ICommand[]

  @commandManager.Mutation addCommand: (command: ICommand) => void
  /**************************************
   * Generic tool method implementations
   **************************************/
  // need to mention these generic optional methods even if they are not implemented by the tool
  // due to TypeScript's weak type detection per https://stackoverflow.com/a/47930521
  clickOk?: () => void
  clickCancel?: () => void

  moveIncrementValue = 5
  moveModel = { ...defaultMoveModel }

  moveFieldsRules = {
    in_one_of_intervals: [
      { min: -3000, max: -0.001 },
      { min: 0.001, max: 3000 },
    ],
  }

  $refs: {
    incrementField: NumberField
  }

  get incrementFieldRules() {
    return {
      in_range_included: {
        leftLimit: 0.001,
        rightLimit: 3000,
        units: this.$i18n.t('millimeterAbbr'),
      },

      required_in_range: {
        leftLimit: 0.001,
        rightLimit: 3000,
        units: this.$i18n.t('millimeterAbbr'),
      },
    }
  }

  get moveConstraints(): IConstraintsOptions {
    const mergedConstraints: IConstraintsOptions = {
      x: false,
      y: false,
      z: false,
      variability: false,
    }

    this.buildPlanItems.forEach((item) => {
      const itemMoveConstraints = this.getToolConstraints(ToolTypes.MoveTool, item)
      mergedConstraints.x = mergedConstraints.x || itemMoveConstraints.x
      mergedConstraints.y = mergedConstraints.y || itemMoveConstraints.y
      mergedConstraints.z = mergedConstraints.z || itemMoveConstraints.z
      mergedConstraints.variability = mergedConstraints.variability || itemMoveConstraints.variability
    })

    return mergedConstraints
  }

  beforeMount() {
    this.extendValidationRules()
    this.moveIncrementValue = this.buildPlan.settings.move.increment
  }

  extendValidationRules() {
    extend('in_range_included', {
      validate: (
        value: number,
        { leftLimit, rightLimit, units }: { leftLimit: string; rightLimit: string; units: string },
      ) => {
        return value >= +leftLimit && value <= +rightLimit
      },
      params: ['leftLimit', 'rightLimit', 'units'],
      message: this.$i18n.t('inRangeValidationMessage').toString(),
    })

    extend('in_one_of_intervals', {
      validate: (value: number, { intervals }: { intervals: Array<{ min: number; max: number }> }) => {
        return intervals.some(({ min, max }) => value <= max && value >= min)
      },
      params: ['intervals'],
    })

    extend('required_in_range', {
      ...required,
      params: ['leftLimit', 'rightLimit', 'units'],
      message: this.$i18n.t('inRangeValidationMessage').toString(),
    })
  }

  onUpSpinClickX() {
    this.performTransformationDelta(ToolTypes.MoveTool, {
      ...defaultMoveModel,
      x: this.moveIncrementValue,
    })
  }

  onDownSpinClickX() {
    this.performTransformationDelta(ToolTypes.MoveTool, {
      ...defaultMoveModel,
      x: this.moveIncrementValue * -1,
    })
  }

  onUpSpinClickY() {
    this.performTransformationDelta(ToolTypes.MoveTool, {
      ...defaultMoveModel,
      y: this.moveIncrementValue,
    })
  }

  onDownSpinClickY() {
    this.performTransformationDelta(ToolTypes.MoveTool, {
      ...defaultMoveModel,
      y: this.moveIncrementValue * -1,
    })
  }

  onUpSpinClickZ() {
    this.performTransformationDelta(ToolTypes.MoveTool, {
      ...defaultMoveModel,
      z: this.moveIncrementValue,
    })
  }

  onDownSpinClickZ() {
    this.performTransformationDelta(ToolTypes.MoveTool, {
      ...defaultMoveModel,
      z: this.moveIncrementValue * -1,
    })
  }

  async calculateMovement(axis: string) {
    if (this.moveModel[axis] === 0) {
      return
    }

    const move = { ...defaultMoveModel }
    move[axis] = this.moveModel[axis]

    await this.performTransformationDelta(ToolTypes.MoveTool, move)
    this.moveModel[axis] = null
  }

  async updateBuildPlanIncrementValue() {
    const targetBuildPlan: IBuildPlan = this.buildPlan
    targetBuildPlan.settings.move.increment = this.moveIncrementValue

    this.updateMoveIncrement(this.moveIncrementValue)
    await this.updateBuildPlanIncrement(targetBuildPlan)
  }

  async onUpdateAxis(propertyName: string, propertyValue: false) {
    this.moveConstraints[propertyName] = propertyValue
    for (const item of this.buildPlanItems) {
      const temp: IConstraintsOptions = { ...item.constraints.translation }
      temp[propertyName] = propertyValue
      await this.updateSelectedConstraint(ToolTypes.MoveTool, item, temp)
    }
  }

  get moveFieldsCustomMessages() {
    return {
      in_one_of_intervals: this.$t('moveValidationMessage'),
    }
  }

  clickClose() {
    this.saveUndoData()
  }

  private saveUndoData() {
    /**
     * GEAMPREQ-141
     * If the tool was completed by clicking the “Close” and a change was done – the stored build plan stack
     * will be used again, and the tool undo stack will be added to it
     */
    const undoCommands = this.getToolUndoStack.filter((command) => command instanceof DragCommand) as DragCommand[]
    undoCommands.forEach((command) => {
      command.commandType = CommandType.BuildPlanCommand
      command.toolName = ToolNames.MOVE
      this.addCommand(command)
    })
  }

  private isInvalid(errors: { [key: string]: [] }, fieldName: string): boolean {
    return errors[fieldName] && !!errors[fieldName].length
  }
}
