
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IMachineCard } from '@/types/Common/IMachineCard'

@Component
export default class MachineCard extends Vue {
  @Prop() card: IMachineCard
}
