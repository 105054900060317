
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/components/controls/Common/Button.vue'
import CadHelperLinkUpdateMixin from '@/components/layout/FileExplorer/Table/mixins/CadHelperLinkUpdateMixin'
import StoresNamespaces from '@/store/namespaces'

const cadHelperStore = namespace(StoresNamespaces.CadHelper)

@Component({
  components: {
    Button,
  },
})
export default class DownloadCadHelperModal extends Mixins(CadHelperLinkUpdateMixin) {
  @cadHelperStore.Getter('getDownloadLink') getDownloadLinkOfCadHelper: string

  @Prop() onCancel: () => void
  @Prop() visible: boolean

  onCancelClick() {
    this.onCancel()
  }
}
