
import { TranslateResult } from 'vue-i18n'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { LabelTooltipMixin } from '@/components/layout/buildPlans/marking/mixins/LabelTooltipMixin'
import StoresNamespaces from '@/store/namespaces'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { BodyOrderMethod, CounterSelectionOrder, TextElement } from '@/types/Label/TextElement'

const labelStore = namespace(StoresNamespaces.Labels)

@Component
export default class CounterTooltip extends Mixins(LabelTooltipMixin) {
  @labelStore.Getter labelSets: InteractiveLabelSet[]

  @Prop() tooltipElement: TextElement

  get start(): string {
    return this.tooltipElement && JSON.parse(this.tooltipElement._cachedSpecificsJSON).startNumber
  }

  get increment(): string {
    return this.tooltipElement && JSON.parse(this.tooltipElement._cachedSpecificsJSON).increment
  }

  get leadingZeros(): string {
    return this.tooltipElement && !!JSON.parse(this.tooltipElement._cachedSpecificsJSON).leadingZero
      ? this.$t('yes').toString()
      : this.$t('no').toString()
  }

  get cartesianOrdering(): string {
    return this.tooltipElement &&
      JSON.parse(this.tooltipElement._cachedSpecificsJSON).ordering &&
      JSON.parse(this.tooltipElement._cachedSpecificsJSON).ordering.method === BodyOrderMethod.CartesianSort
      ? this.$t('yes').toString()
      : this.$t('no').toString()
  }

  get isCartesianOrderingUsed(): boolean {
    return (
      this.tooltipElement &&
      JSON.parse(this.tooltipElement._cachedSpecificsJSON).ordering &&
      JSON.parse(this.tooltipElement._cachedSpecificsJSON).ordering.method === BodyOrderMethod.CartesianSort
    )
  }

  get cartesianItems(): string[] {
    if (this.tooltipElement) {
      const jsonParams = JSON.parse(this.tooltipElement._cachedSpecificsJSON)
      if (jsonParams.ordering) {
        return jsonParams.ordering.sortCoordinateOrder.map((order: CounterSelectionOrder) => this.sortOptionName(order))
      }
    }
    return []
  }

  sortOptionName(sortOption: CounterSelectionOrder): TranslateResult {
    switch (sortOption) {
      case CounterSelectionOrder.FrontToBack:
        return this.$t('labelTool.counterSettings.frontToBack')
      case CounterSelectionOrder.BotToTop:
        return this.$t('labelTool.counterSettings.bottomToTop')
      case CounterSelectionOrder.LeftToRight:
        return this.$t('labelTool.counterSettings.leftToRight')
      case CounterSelectionOrder.BackToFront:
        return this.$t('labelTool.counterSettings.backToFront')
      case CounterSelectionOrder.TopToBot:
        return this.$t('labelTool.counterSettings.topToBot')
      case CounterSelectionOrder.RightToLeft:
        return this.$t('labelTool.counterSettings.rightToLeft')
    }
  }
}
