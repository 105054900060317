import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { IVisualizationState } from './types'
import { IRootState } from '@/store/types'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { SimulationColoringModel } from '@/types/Simulation/SimulationColoringModel'
import VisualizationModeTypes from '@/visualization/types/VisualizationModeTypes'
import { Visualization } from '@/visualization'
import { ClearanceTypes } from '@/visualization/types/ClearanceTypes'

export const state = (): IVisualizationState => ({
  visualization: new Visualization(),
  selectedComponentsIds: [],
  canvasId: '',
  isShowingGizmos: false,
  isInitialized: false,
  isLoading: false,
  isMouseOverCanvas: false,
  hoveredLabelId: null,
  needToDispose: false,
  crossSectionMode: { isEnabled: false },
  clearanceMode: {
    isEnabled: false,
    showRubberband: false,
    dimensionBoxes: [],
    highlightedClearanceIds: [],
    enabledFrom: ClearanceTypes.Parts,
    isEnabledFrom: {
      bodies: false,
      parts: true,
      walls: false,
      printHeadLanes: false,
      plate: false,
      ceiling: false,
    },
    enabledTo: ClearanceTypes.Parts,
    isEnabledTo: {
      bodies: false,
      parts: true,
      walls: false,
      printHeadLanes: false,
      plate: false,
      ceiling: false,
    },
    savedActiveCollectorId: null,
  },
  slicerMode: { isEnable: false, max: undefined, min: undefined, current: undefined },
  viewMode: { name: ViewModeTypes.Layout, isSelectable: true },
  visualizationMode: VisualizationModeTypes.Default,
  isViewLocked: false,
  isLabelCreationMode: false,
  isLabelManualPlacement: false,
  boundingBox: null,
  boundingBoxForPartsOnly: null,
  boundingBox2D: null,
  simulation: {
    legend: { rangePoints: [], colors: [] },
    coloringModel: new SimulationColoringModel([]),
    simulationSteps: [],
    selectedSimulationStep: undefined,
    charts: [],
    activeChart: undefined,
    loadedSimulationSteps: [],
    slicer: { min: undefined, max: undefined, current: undefined },
    timestamps: { stamps: [], current: undefined },
    isShownNewTimestampModal: false,
    dataRange: { allSteps: undefined, currentStep: undefined, xray: undefined },
    additionalGeometry: {},
    summary: undefined,
    printingLayers: [],
    resultsAvailable: undefined,
    paraviewWebError: undefined,
    warping: { factor: 1, available: false, loaded: false },
    handlersLoaded: {},
    boundingBox: { min: undefined, max: undefined },
    processing: {},
    serviceStarted: false,
  },
  instancingIsRunning: false,
  cameraPosition: null,
  slider: { isActive: false, currentLayer: 1 },
  previewCreationPromise: null,
  isDownwardPlaneRotationInitialized: false,
  isDownwardPlaneRotationInProgress: false,
  isDisplayFlipArrow: false,
  flipArrowLocation: { x: 0, y: 0 },
  addPart: { previewIsLoading: false },
  isCompensationFileInProgress: false,
  isDisplayManualLabelSettings: false,
  manualLabelSettingsLocation: { x: 0, y: 0 },
  isPartInstabilityShow: false,
  showHiddenPartsAsTransparentMode: false,
  isPartConfigLoading: false,
})

const namespaced: boolean = true

export const visualizationModule: Module<IVisualizationState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
