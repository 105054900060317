
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import Menu from '@/components/controls/Common/Menu.vue'

@Component({
  components: {
    Menu,
  },
})
export default class MenuSection extends Vue {
  @Prop() isShownActionButtons: boolean
  @Prop() menuItems
  @Prop() disabled: boolean

  isMenuOpened = false

  get items() {
    return this.menuItems
  }

  get isActionButtonsVisible() {
    return this.isShownActionButtons
  }

  onFetchPermissions() {
    this.$emit('fetchPermissions')
  }

  onMenuItemClick(clickHandler) {
    this.$emit('menuItemClick', {
      clickHandler,
    })
  }

  @Watch('isMenuOpened')
  menuChanged(isOpen: boolean) {
    this.$emit('menuChanged', {
      isOpen,
    })
  }
}
