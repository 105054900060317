
import Component from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { RouterNames, RouterPaths } from '@/router'
import ActionBar from '@/components/layout/FileExplorer/ActionBar.vue'
import AllFilesSearchViewMixin from '@/components/layout/FileExplorer/Table/mixins/AllFilesSearchViewMixin'
import ShareItemModal from '@/components/layout/FileExplorer/ShareItemModal.vue'
import MoveToTrashItemModal from '@/components/layout/FileExplorer/MoveToTrashItemModal.vue'
import RenameItemModal from '@/components/layout/FileExplorer/RenameItemModal.vue'
import MoveToTrashItemErrorModal from '@/components/layout/FileExplorer/MoveToTrashItemErrorModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import Splitter from '@/components/layout/Splitter.vue'
import PartInsightsPanel from '@/components/layout/parts/PartInsightsPanel.vue'
import { DetailsPart } from '@/components/layout/FileExplorer/Details'
import ViewModeTypes from '@/visualization/types/ViewModeTypes'
import { SceneMode } from '@/visualization/types/SceneTypes'
import { Geometry, Part } from '@/visualization/models/DataModel'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { ItemAction } from '@/types/FileExplorer/ItemAction'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import { FolderDetailsDto, PartDetailsDto } from '@/types/FileExplorer/ItemDetails'
import { DetailsPanelViewMode } from '@/types/FileExplorer/ViewMode'
import { IUser } from '@/types/User/IUser'
import { IGeometryProperties } from '@/types/BuildPlans/IBuildPlan'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { ItemPermissionsRole } from '@/types/FileExplorer/Permission'
import { IInsightsCount } from '@/types/Common/Insights'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import CanvasDisplayMenuMixin from '@/components/layout/buildPlans/mixins/CanvasDisplayMenuMixin'
import Icon from '@/components/icons/Icon.vue'
import { INCORRECT_UUID_MESSAGE_ERROR_2 } from '@/constants'
import messageService from '@/services/messageService'
import ToggleDetailsPanelButton from '@/components/layout/FileExplorer/Details/ToggleDetailsPanelButton.vue'
import variables from '@/assets/styles/variables.scss'

const TIMEOUT_FOR_UPDATE_GRID = 50
const userStore = namespace(StoresNamespaces.User)
const partsStore = namespace(StoresNamespaces.Parts)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const fileExplorerStore = namespace(StoresNamespaces.FileExplorer)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component({
  components: {
    ActionBar,
    ShareItemModal,
    RenameItemModal,
    MoveToTrashItemModal,
    MoveToTrashItemErrorModal,
    DetailsPart,
    ConfirmModal,
    Splitter,
    PartInsightsPanel,
    Icon,
    ToggleDetailsPanelButton,
  },
})
export default class PartPreview extends Mixins(AllFilesSearchViewMixin, CanvasDisplayMenuMixin) {
  // region fileExplorerStore
  @fileExplorerStore.Getter('find') findItemById: (itemId: string) => FileExplorerItem
  @fileExplorerStore.Getter getItemRole: (itemId: string) => ItemPermissionsRole

  @fileExplorerStore.Action fetchPartDetails: (itemId: string) => Promise<PartDetailsDto>
  @fileExplorerStore.Action fetchFolderDetails: (
    itemId: string,
  ) => Promise<{ item: FileExplorerItem; details: FolderDetailsDto }>
  @fileExplorerStore.Action fetchItemWithNoStateUpdate: (itemId: string) => Promise<FileExplorerItem>
  @fileExplorerStore.Action getAllMyPermissions: any
  @fileExplorerStore.Action getParentFolder: (itemId: string) => Promise<FileExplorerItem>
  // endregion fileExplorerStore

  // region buildPlansStore
  @buildPlansStore.Getter('getIsLoading') isLoading: boolean

  @buildPlansStore.Action loadPartConfig: Function
  // endregion buildPlansStore

  // region visualizationStore
  @visualizationStore.Getter isViewLocked: boolean
  @visualizationStore.Getter getVisualizationLoading: boolean
  @visualizationStore.Getter getPreviewCreationPromise: { promise: Promise<void>; done: Function }
  @visualizationStore.Getter isInitialized: boolean

  @visualizationStore.Mutation calcGeometryPropsForSinglePart: Function
  @visualizationStore.Mutation setBodiesVisibility: (payload: { ids: string[]; isVisible: boolean }) => void
  @visualizationStore.Mutation('init') initVisualizationPlugin: Function
  @visualizationStore.Mutation changeViewMode: Function
  @visualizationStore.Mutation setViewLocked: Function
  @visualizationStore.Mutation changeView: Function
  @visualizationStore.Mutation zoomToFitCamera: Function
  @visualizationStore.Mutation enableCrossSectionMode: Function
  @visualizationStore.Mutation recenterCrossSection: Function
  @visualizationStore.Mutation axisAlignCrossSection: Function
  @visualizationStore.Mutation updateItemPreview: Function
  @visualizationStore.Mutation resizeCanvas: Function
  // endregion visualizationStore

  // region userStore
  @userStore.Getter('getUserDetails') user: IUser
  // endregion userStore

  // region partsStore
  @partsStore.Getter('getPreviewedPartGeometryProps') geometryProps: IGeometryProperties
  @partsStore.Getter configFile: { parts: Part[] }
  @partsStore.Getter insightsCount: IInsightsCount

  @partsStore.Mutation clearPreviewInfo: Function
  @partsStore.Mutation setInsights: Function

  @partsStore.Action getPartById: (partId: string) => any
  @partsStore.Action updatePart: (payload: { id: string; hiddenBodies: string[] | null }) => any
  // endregion partsStore

  isCheckingAccess: boolean = true
  isLoadingPart: boolean = true
  item: FileExplorerItem = null
  parentFolder: FileExplorerItem = null
  partDetails: PartDetailsDto = null
  menuItems = []
  part: IPartDto = null
  role: ItemPermissionsRole = null
  isCrossSectionModeActive = false
  crossSectionDisabled = false
  isOpenDetails = true

  async beforeMount() {
    this.isCheckingAccess = true
    this.isLoadingPart = true

    const partId = this.$route.params.id
    let item: FileExplorerItem = this.findItemById(partId)
    if (!item) {
      item = await this.fetchItemById(partId)

      if (this.isError) {
        const errMsg =
          this.getErrorText === INCORRECT_UUID_MESSAGE_ERROR_2
            ? `${this.$i18n.t('incorrectPartId')}: ${partId}`
            : this.getErrorText
        messageService.showErrorMessage(errMsg)
        return
      }
    }

    if (!item) {
      // @ts-ignore
      this.$router.safePush(RouterPaths.Home)
      return
    }

    if (item.isRemoved) {
      // @ts-ignore
      this.$router.safePush(RouterPaths.DefaultFileExplorer)
      return
    }

    this.item = item
    this.isCheckingAccess = false

    const [part] = await Promise.all([
      this.getPartById(this.item.id),
      this.getAllMyPermissions(),
      this.fetchItemDetails({
        itemId: this.item.id,
        itemType: this.item.itemType,
      }),
    ])
    this.part = part
    this.role = this.getItemRole(item.id)

    if (item.parentId) {
      this.parentFolder = await this.getParentFolder(item.id)
    }

    const isAllowedToEndCollaboration = await this.checkAccessToEndCollaboration([partId])
    this.setIsAllowedToEndCollaboration(isAllowedToEndCollaboration)

    const partDetailsDto = await this.fetchPartDetails(item.id)
    this.partDetails = partDetailsDto

    this.$nextTick(() => {
      this.initVisualizationPlugin({
        canvasId: 'part_canvas',
        sceneMode: SceneMode.PreviewPart,
        viewMode: ViewModeTypes.PartLayout,
        loadDefaultPlate: false,
      })
      this.loadPartConfig({
        partId: this.item.id,
        partName: this.item.name,
        hideGeometries: this.part.hiddenBodies ? this.part.hiddenBodies : [],
      })
    })
  }

  get isSinterPart(): boolean {
    return this.item && this.item.subType === ItemSubType.SinterPart
  }

  get isIbcPart(): boolean {
    return this.item && this.item.subType === ItemSubType.IbcPart
  }

  get canIgnoreBrokenBodies(): boolean {
    return this.part && this.part.hasErrors && this.part.hiddenBodies === null
  }

  get brokenBodiesAreIgnored(): boolean {
    return this.part && this.part.hasErrors && this.part.hiddenBodies !== null
  }

  get canPerformBrokenBodiesActions(): boolean {
    return (
      this.role === ItemPermissionsRole.Editor ||
      this.role === ItemPermissionsRole.CoOwner ||
      this.role === ItemPermissionsRole.Owner
    )
  }

  @Watch('isLoading')
  loadingChanged(isLoading) {
    this.isLoadingPart = isLoading

    if (!this.geometryProps && !isLoading) {
      this.calcGeometryPropsForSinglePart()
    }
  }

  @Watch('configFile')
  configFileLoaded() {
    this.setInsights(this.configFile.parts)
  }

  navigateToParent() {
    if (!this.parentFolder) {
      // @ts-ignore
      this.$router.safePush(RouterPaths.DefaultFileExplorer)
      return
    }

    const route = {
      name: RouterNames.FE_AllFiles,
      params: { itemId: this.parentFolder.id },
    }
    // @ts-ignore
    this.$router.safePush(route)
  }

  async includeBrokenBodies(shouldInclude: boolean) {
    const parts: Part[] = this.configFile.parts
    const geometries = parts.reduce((arr: Geometry[], part: Part) => {
      arr.push(...part.geometries)
      return arr
    }, [])

    const brokenBodies = geometries
      .filter((geometry: Geometry) => (geometry as any).defects)
      .map((geometry: Geometry) => geometry.id)

    if (shouldInclude) {
      this.part = await this.updatePart({ id: this.part.id, hiddenBodies: null })
      this.setBodiesVisibility({ ids: brokenBodies, isVisible: true })
      return
    }

    this.part = await this.updatePart({ id: this.part.id, hiddenBodies: brokenBodies })
    this.setBodiesVisibility({ ids: brokenBodies, isVisible: false })
  }

  async trashModalOnClose() {
    await this.confirmTrashModal([this.item])
    this.setDetailsPanelMode(DetailsPanelViewMode.Default)
    this.navigateToParent()
  }

  onRenameItem(name: string) {
    this.renameItem(name, this.item)
  }

  async shareModalOnClose(args) {
    this.toggleShareItemModal(false)
    if (args && args.closePreview) {
      this.navigateToParent()
    } else {
      const partDetailsDto = await this.fetchPartDetails(this.item.id)
      this.partDetails = partDetailsDto
    }
  }

  async endCollaboration() {
    if (!this.user) {
      return
    }

    await this.getItemPermissions(this.item.id)
    const collaborators = this.permissionsByItemId[this.item.id]

    const collaborator = collaborators
      ? collaborators.find((c) => c.itemId === this.item.id && c.grantedTo === this.user.id)
      : null

    if (collaborator) {
      const modalTitle = this.$t('removeFromCollaborationDialog.title')
      const modalMessage = `<p class="text t-14">${this.$t('removeFromCollaborationDialog.removeYourselfLabel')}</p>`
      const shouldDelete = await this.$refs.confirm.open(modalTitle, modalMessage)

      if (shouldDelete) {
        await this.setLastAction({ itemId: this.item.id, action: ItemAction.Unshared })
        await this.deletePermission({ permissionId: collaborator.id, fullDeletion: true })
        this.deleteItem(this.item)
        this.unselectItem({ item: this.item, selectionType: SelectionTypes.Single })
        this.navigateToParent()
      }
    }
  }

  async trashItem() {
    const canBeTrashedResult = await this.isAllowedToBeTrashed([this.item])

    if (canBeTrashedResult) {
      if (this.isCanBeTrashedStatusSuccess) {
        this.toggleMoveToTrashModal()
        return
      }

      this.toggleMoveToTrashErrorModal()
    }
  }

  async getParentItem(item) {
    if (!item.parentId) {
      return
    }

    let parent: FileExplorerItem = this.findItemById(item.parentId)
    if (!parent) {
      parent = await this.fetchItemWithNoStateUpdate(item.parentId)
    }
    return parent
  }

  toggleCrossSection() {
    this.isCrossSectionModeActive = !this.isCrossSectionModeActive
    this.enableCrossSectionMode({
      isEnabled: this.isCrossSectionModeActive,
    })
    if (this.isCrossSectionModeActive) {
      this.changeViewMode(ViewModeTypes.CrossSection)
    } else {
      this.changeViewMode(ViewModeTypes.PartLayout)
    }
  }

  @Watch('isLoadingPart')
  async addPreviewUpdateEvent(isLoadingPart: boolean) {
    if (isLoadingPart && !this.item.previewImageUrl) {
      window.removeEventListener('beforeunload', this.updatePreview)
    } else {
      window.addEventListener('beforeunload', this.updatePreview)
    }
  }

  updatePreview() {
    if (this.item) {
      this.updateItemPreview({ itemId: this.item.id })
    } else {
      this.getPreviewCreationPromise.done()
    }
  }

  toggleDetails() {
    this.isOpenDetails = !this.isOpenDetails
    // This timeout is needed to render the canvas after resizing grid and canvas
    setTimeout(() => {
      this.resizeCanvas()
    }, TIMEOUT_FOR_UPDATE_GRID)
  }

  get detailsBtnXPosition() {
    const defaultRightPosition = variables.buildPlanDefDetailBtnPos
    const openedPanelRightPosition = variables.buildPlanOpenedDetailBtnPos
    const position = this.isOpenDetails ? openedPanelRightPosition : defaultRightPosition
    return `right: ${position};`
  }

  beforeDestroy() {
    this.clearPreviewInfo()
    if (!this.getVisualizationLoading && !this.isLoading && this.isInitialized && !this.item.previewImageUrl) {
      this.updatePreview()
    }

    window.removeEventListener('beforeunload', this.updatePreview)
  }
}
