import { del, get, post, put } from '@/api/http'
import { handleAPIError } from '@/api/common'
import { EndpointsUrls } from '@/configs/config'
import { FileExplorerItem, IFolderDto, PaginatedFileExplorerData } from '@/types/FileExplorer/FileExplorerItem'
import {
  GrantPermissionDto,
  ItemCollaborationDto,
  Permission,
  UpdatePermissionDto,
  ItemOwnerIds,
} from '@/types/FileExplorer/Permission'
import { ICreateBuildPlanDto } from '@/types/BuildPlans/IBuildPlan'
import { IJob } from '@/types/PartsLibrary/Job'
import { ItemDetailsDto, ItemDetailsTypes } from '@/types/FileExplorer/ItemDetails'
import { ICopyItemsDto } from '@/types/FileExplorer/ICopyItemsDto'
import { ItemSubType, ItemType } from '@/types/FileExplorer/ItemType'
import { ItemAction } from '@/types/FileExplorer/ItemAction'
import { RelatedItems } from '../types/FileExplorer/RelatedItems'
import { CanBeTrashedResult } from '@/types/FileExplorer/CanBeTrashed'
import { CreateIBCPlanDto, IIBCPlan } from '@/types/IBCPlans/IIBCPlan'

export default {
  async getItems(searchQuery: string = '') {
    try {
      return get<PaginatedFileExplorerData>(`${EndpointsUrls.Items}${searchQuery}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getItemsByIds(searchQuery: string) {
    try {
      return get<FileExplorerItem[]>(`${EndpointsUrls.Items}?${searchQuery}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchAllMyFolders() {
    try {
      return get<FileExplorerItem[]>(`${EndpointsUrls.Folders}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getItemsInFolder(folderId: string, searchQuery: string = ''): Promise<PaginatedFileExplorerData> {
    try {
      return get<PaginatedFileExplorerData>(`${EndpointsUrls.Folders}/${folderId}/items${searchQuery}`)
    } catch (error) {
      handleAPIError(error)
      return {
        data: [],
        meta: {
          currentPage: 1,
          totalItems: 0,
          totalPages: 1,
        },
      }
    }
  },

  async getAllItemsInFolder(folderId: string): Promise<FileExplorerItem[]> {
    try {
      return get<FileExplorerItem[]>(`${EndpointsUrls.Folders}/${folderId}/all-items`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getRecentItems(searchQuery: string = ''): Promise<PaginatedFileExplorerData | undefined> {
    try {
      return get<PaginatedFileExplorerData>(`${EndpointsUrls.Items}/recents${searchQuery}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getSharedByMe(searchQuery: string = ''): Promise<PaginatedFileExplorerData> {
    try {
      return get<PaginatedFileExplorerData>(`${EndpointsUrls.Items}/sharedByMe${searchQuery}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getTrash(searchQuery: string = ''): Promise<PaginatedFileExplorerData> {
    try {
      return get<PaginatedFileExplorerData>(`${EndpointsUrls.Items}/trash${searchQuery}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async canTrashItems(itemIds: string[]) {
    try {
      return await post<CanBeTrashedResult>(`${EndpointsUrls.Items}/canTrash`, itemIds)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async trashItems(itemIds: string[]) {
    try {
      return post<FileExplorerItem[]>(`${EndpointsUrls.Items}/trash`, itemIds)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async deleteAllTrashedItems(): Promise<string[]> {
    try {
      return await del<string[]>(`${EndpointsUrls.Items}/trash`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getAllMyPermissions() {
    try {
      return get<Permission[]>(`${EndpointsUrls.Permissions}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getItemPermissions(itemId: string, includeParentPermissions = false) {
    try {
      return get<ItemCollaborationDto>(
        `${EndpointsUrls.Items}/${itemId}/permissions?includeParentPermissions=${includeParentPermissions}`,
      )
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchItemCollaboratorsByIds(ids: string[]) {
    if (!ids) return

    try {
      return get<ItemCollaborationDto[]>(`${EndpointsUrls.Items}/collaborationsByIds/${ids.join(',')}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getBuildPlanDetails(itemId: string) {
    try {
      const details = await get<ItemDetailsDto>(`${EndpointsUrls.BuildPlansV1}/${itemId}/details`)
      details.type = ItemDetailsTypes.BuildPlan
      return details
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getIbcPlanDetails(itemId: string) {
    try {
      return get<ItemDetailsDto>(`${EndpointsUrls.IBCPlans}/${itemId}/details`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async createFolder(folder: IFolderDto) {
    try {
      return await post<FileExplorerItem>(`${EndpointsUrls.Folders}`, folder)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async createBuildPlan(bp: ICreateBuildPlanDto) {
    try {
      return await post<FileExplorerItem>(`${EndpointsUrls.BuildPlansV1}`, bp)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchFavorites(searchQuery: string = ''): Promise<FileExplorerItem[] | undefined> {
    try {
      return await get<FileExplorerItem[]>(`${EndpointsUrls.Items}/favorites${searchQuery}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  fetchFavoriteItemsIds(): Promise<string[] | undefined> {
    try {
      return get<string[]>(`${EndpointsUrls.Items}/favorites/id`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async setIsFavorite(itemId: string) {
    try {
      return await put(`${EndpointsUrls.Items}/${itemId}/setIsFavorite`, {})
    } catch (error) {
      handleAPIError(error)
    }
  },

  async unsetIsFavorite(itemId: string) {
    try {
      return await put(`${EndpointsUrls.Items}/${itemId}/unsetIsFavorite`, {})
    } catch (error) {
      handleAPIError(error)
    }
  },

  async setActiveVersion(itemId: string) {
    await put(`${EndpointsUrls.Items}/${itemId}/setActiveVersion`, {})
  },

  async unsetActiveVersion(itemId: string) {
    await put(`${EndpointsUrls.Items}/${itemId}/unsetActiveVersion`, {})
  },

  async getItemById(itemId: string): Promise<FileExplorerItem> {
    try {
      return await get<FileExplorerItem>(`${EndpointsUrls.Items}/${itemId}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getItemDetailsById(itemId: string): Promise<FileExplorerItem> {
    try {
      return get<FileExplorerItem>(`${EndpointsUrls.Items}/${itemId}/basicInfo`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async updateItem(updatedItem: Partial<FileExplorerItem>) {
    try {
      return put<FileExplorerItem>(`${EndpointsUrls.Items}/${updatedItem.id}`, updatedItem)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async updateItems(updateItemsDto) {
    try {
      return put<FileExplorerItem[]>(`${EndpointsUrls.Items}/batch`, updateItemsDto)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async setLastAction(itemId: string, action: ItemAction) {
    try {
      return put<FileExplorerItem>(`${EndpointsUrls.Items}/${itemId}/setLastAction`, { action })
    } catch (error) {
      handleAPIError(error)
    }
  },

  async createPermission(data: GrantPermissionDto) {
    try {
      return post<Permission>(`${EndpointsUrls.Permissions}`, data)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async updatePermission(permissionId: string, data: UpdatePermissionDto) {
    try {
      return put<Permission>(`${EndpointsUrls.Permissions}/${permissionId}`, data)
    } catch (error) {
      handleAPIError(error)
    }
  },

  /**
   * Send request to delete user permission. Only child permissions will be deleted by default (without full deletion)
   * @param permissionId - id of user permission
   * @param fullDeletion - (optional) this param required to full deletion granted user permissions
   * (parent and child). Required for 'End collaboration'
   * logic or waterfall permissions
   * @returns array of removed permission ids
   */
  async deletePermission(permissionId: string, fullDeletion?: boolean) {
    try {
      const data = fullDeletion ? { params: { fullDeletion } } : {}
      return del<string[]>(`${EndpointsUrls.Permissions}/${permissionId}`, data)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async unshareItem(item: FileExplorerItem) {
    try {
      return put(`${EndpointsUrls.Items}/${item.id}/unshare`, null)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async checkIsPresent(
    name: string,
    parentId: string,
    excludeItemId: string = null,
    itemType?: ItemType,
    itemSubType?: ItemSubType,
  ): Promise<boolean> {
    try {
      let params = `name=${name}&parentId=${parentId}`
      if (excludeItemId) {
        params += `&excludeItemId=${excludeItemId}`
      }

      if (itemType !== undefined) {
        params += `&itemType=${itemType}`
      }

      if (itemSubType !== undefined) {
        params += `&subType=${itemSubType}`
      }

      return get(`${EndpointsUrls.Items}/exists?${params}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getUniqueName(name: string, parentId: string, delimiter?: string) {
    try {
      let params = `name=${name}&parentId=${parentId}`

      if (typeof delimiter === 'string' && delimiter !== '') {
        params += `&delimiter=${encodeURIComponent(delimiter)}`
      }
      return await get(`${EndpointsUrls.Items}/unique-name?${params}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getFolderById(id: string) {
    try {
      return get<FileExplorerItem>(`${EndpointsUrls.Folders}/${id}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getJobById(id: string) {
    try {
      return get<IJob>(`${EndpointsUrls.Jobs}/${id}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getItemsBySearchParams(queryString: string) {
    try {
      return get<FileExplorerItem[]>(`${EndpointsUrls.Items}/search?${queryString}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async copyItems(copyDto: ICopyItemsDto) {
    try {
      return post<FileExplorerItem[]>(`${EndpointsUrls.Items}/copy`, copyDto)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getFolderDetails(itemId: string, showErrorMessage = true) {
    try {
      const details = await get<ItemDetailsDto>(`${EndpointsUrls.Folders}/${itemId}/details`)
      details.type = ItemDetailsTypes.Folder
      return details
    } catch (error) {
      if (showErrorMessage) {
        handleAPIError(error)
      }
    }
  },

  async getPartDetails(itemId: string) {
    try {
      const details = await get<ItemDetailsDto>(`${EndpointsUrls.PartsV1}/${itemId}/details`)
      details.type = ItemDetailsTypes.Part
      return details
    } catch (error) {
      handleAPIError(error)
    }
  },

  async updateItemPreviewImage(id: string, image: File) {
    const url = `${EndpointsUrls.Items}/${id}/updatePreview`
    const headers = {
      'Content-Type': 'multipart/form-data',
    }

    const formData = new FormData()
    formData.append('image', image)
    try {
      return put<string>(url, formData, { headers })
    } catch (error) {
      console.log(error)
    }
  },

  async getParentFolder(itemId: string): Promise<FileExplorerItem> {
    try {
      return get<FileExplorerItem>(`${EndpointsUrls.Items}/${itemId}/parent`)
    } catch (error) {
      return null
    }
  },

  async getGetRunningAndFailedJobsByItemIds(itemIds: string[]): Promise<IJob[]> {
    try {
      return post<IJob[]>(`${EndpointsUrls.Jobs}/byItemIds`, { itemIds })
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getRelatedItemsForTrashTab(itemIds: string[]): Promise<RelatedItems> {
    try {
      return post<RelatedItems>(`${EndpointsUrls.Items}/getRelatedItems`, itemIds)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async deleteItems(itemIds: string[]): Promise<string[]> {
    try {
      return post<string[]>(`${EndpointsUrls.Items}/bulkDelete`, itemIds)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async restoreItems(itemIds: string[]): Promise<FileExplorerItem[]> {
    try {
      return post<FileExplorerItem[]>(`${EndpointsUrls.Items}/restoreItems`, itemIds)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async restoreAllItems(): Promise<FileExplorerItem[]> {
    try {
      return post<FileExplorerItem[]>(`${EndpointsUrls.Items}/restoreItems`, {})
    } catch (error) {
      handleAPIError(error)
    }
  },

  async canDeleteVariants(itemId: string) {
    try {
      return await get<CanBeTrashedResult>(`${EndpointsUrls.Items}/${itemId}/canDeleteVariant`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getRelatedItemsForShare(itemId: string) {
    try {
      return await get<FileExplorerItem[]>(`${EndpointsUrls.Items}/${itemId}/relatedItemsForShare`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async isItemTrashed(itemId: string) {
    try {
      return await get<boolean>(`${EndpointsUrls.Items}/${itemId}/trashed`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async fetchItemsWithoutAccess() {
    try {
      return await get<FileExplorerItem[]>(`${EndpointsUrls.Items}/withoutAccess`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async itemExistsForUser(itemId: string, checkForPrintOrder = false) {
    try {
      return await get<boolean>(`${EndpointsUrls.Items}/${itemId}/exists?checkForPrintOrder=${checkForPrintOrder}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async getOwnerIdForItems(itemIds: string[], hideAPIErrorMessages = false) {
    try {
      return post<ItemOwnerIds[]>(`${EndpointsUrls.Items}/ownerId`, itemIds)
    } catch (error) {
      handleAPIError(error, hideAPIErrorMessages)
    }
  },

  async fetchIBCPlan(ibcPlanId: string) {
    try {
      return await get<IIBCPlan>(`${EndpointsUrls.IBCPlans}/${ibcPlanId}`)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async createIBCPlan(createIbcPlanDto: CreateIBCPlanDto) {
    try {
      return await post<IIBCPlan>(`${EndpointsUrls.IBCPlans}`, createIbcPlanDto)
    } catch (error) {
      handleAPIError(error)
    }
  },

  async deleteIBCPlan(ibcPlanId: string) {
    try {
      return del<IIBCPlan>(`${EndpointsUrls.IBCPlans}/${ibcPlanId}`)
    } catch (error) {
      handleAPIError(error)
    }
  },
}
