import { GetterTree } from 'vuex'
import { ICadHelperState } from './types'
import { IRootState } from '@/store/types'

export const getters: GetterTree<ICadHelperState, IRootState> = {
  getDownloadLink(state) {
    return state.downloadUrl
  },

  getVersion(state) {
    return state.version
  },
}
