
import '@/assets/styles/home.scss'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import StoresNamespaces from '@/store/namespaces'
import { SiteDto, MachineTypeDto, EditViewMode, MachineMaterial, UpdateMachineTypeDto } from '@/types/Sites/Site'
import SiteConstantUpdater from '@/components/layout/admin/sites/SiteConstantUpdater.vue'
import MaterialListRow from '@/components/layout/admin/sites/MaterialListRow.vue'
import { SiteAdminMixin } from './mixins/SiteAdminMixin'
import { UpdateAdminMaterialDTO } from '@/types/Sites/SiteAdminDTOs'
import { Watch } from 'vue-property-decorator'

const sitesStore = namespace(StoresNamespaces.Sites)

@Component({
  components: {
    SiteConstantUpdater,
    MaterialListRow,
  },
})
export default class MachineDetailsAdmin extends SiteAdminMixin {
  @sitesStore.Action fetchMaterialsByMachineConfigId: (machineConfigId: string) => Promise<MachineMaterial[]>
  @sitesStore.Action updateMaterial: (payload: {
    materialId: string
    dto: UpdateAdminMaterialDTO
  }) => Promise<MachineMaterial>
  @sitesStore.Action updateMachineType: (params: {
    machineTypeId: number
    updateMachineTypeDto: UpdateMachineTypeDto
  }) => Promise<MachineTypeDto>

  @sitesStore.Getter getSelectedMachineType: MachineTypeDto
  @sitesStore.Getter getMachineMaterials: MachineMaterial[]
  @sitesStore.Getter isBreadcrumbsReady: boolean

  @sitesStore.Mutation setMachineMaterials: (materials: MachineMaterial[]) => void

  isReady = false

  @Watch('isBreadcrumbsReady')
  onBreadcrumbsReadyStateChange(isReady: boolean) {
    if (isReady) {
      this.prepareData()
    }
  }

  onCloseEditMode() {
    this.editMode = EditViewMode.None
  }

  async prepareData() {
    try {
      const machineTypeId = this.$route.params.machineTypeId
      const materials = await this.fetchMaterialsByMachineConfigId(machineTypeId)
      this.setMachineMaterials(materials)
      this.isReady = true
    } catch (error) {
      this.isReady = false
    }
  }

  async updateMachineConfig() {
    this.editMode = EditViewMode.None

    const dto: UpdateMachineTypeDto = { filterPrice: this.getSelectedMachineType.filterPrice.value }

    await this.updateMachineType({
      machineTypeId: +this.getSelectedMachineType.id,
      updateMachineTypeDto: dto,
    })
  }

  async updateMaterialConstants(material: MachineMaterial, dto: UpdateAdminMaterialDTO) {
    await this.updateMaterial({ dto, materialId: material.id })
  }

  getItemBuildVolume(item: MachineTypeDto) {
    const { height, width, length, measurement } = item.buildVolume
    return `${height}x${width}x${length} ${measurement}`
  }
}
