
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Emit } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { FileExplorerItemSharedByUser } from '@/types/FileExplorer/FileExplorerItem'
import { Permission } from '@/types/FileExplorer/Permission'

import StoresNamespaces from '@/store/namespaces'
import { formatDateWithDefaultFormat } from '@/utils/common'

const userStore = namespace(StoresNamespaces.User)

@Component
export default class SharedDetails extends Vue {
  @userStore.State('users') users: any

  @Prop() item: FileExplorerItemSharedByUser
  @Prop({ default: () => [] }) permissions: Permission[]

  get sharedAt(): string {
    const date = new Date(this.item.sharedDate)
    return formatDateWithDefaultFormat(date)
  }

  @Emit('unshare-user')
  onUnshareClick(permission: Permission) {
    return permission
  }
}
