import { IRootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { IOptionalMultiItemCollectorState } from '@/store/modules/optionalMultiItemCollector/types'
import { MultiItemCollector } from '@/types/OptionalMultiItemCollector/MultiItemCollector'

export const actions: ActionTree<IOptionalMultiItemCollectorState, IRootState> = {
  createCollector({ state, commit }, collector: MultiItemCollector) {
    const existingCollector = state.collectors.find((stateCollector) => stateCollector.id === collector.id)
    if (existingCollector) {
      commit('enableColoringForCollector', collector.id)
    } else {
      commit('createCollector', collector)
    }
  },
}
