import { IBuildPlanItem } from '@/types/BuildPlans/IBuildPlan'

/**
 * Determine if BuildPlanItem body function can be changed
 * If Part has bodyFunction property set - body function on all bp items should be the same
 * However for legacy bp items:
 * If the part’s ‘Function’ in the Build Plan is different from the used ‘Function’ in the Sinter Plan
 * - use current behavior (do not lock the ‘Function’)
 * So for already created bp items function is locked only if bp items body function is the same as parts body function
 */
export function isBuildPlanItemBodyFunctionLocked(buildPlanItem: IBuildPlanItem) {
  return (
    buildPlanItem &&
    buildPlanItem.partProperties.length &&
    buildPlanItem.part.bodyFunction &&
    buildPlanItem.part.bodyFunction === buildPlanItem.partProperties[0].type
  )
}
