
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import StoresNamespaces from '@/store/namespaces'
import messageService from '@/services/messageService'
import { IJob, JobStatusCode, JobType } from '@/types/PartsLibrary/Job'

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)

@Component
export default class BuildPlanPublishTab extends Vue implements IToolComponent {
  /**************************************
   * Generic tool method implementations
   **************************************/
  @buildPlansStore.Action publishBuildPlan: Function
  @buildPlansStore.Getter('getSelectedBuildPlanJobs') getSelectedBuildPlanJobs: IJob[]

  // need to mention these generic optional methods even if they are not implemented by the tool
  // due to TypeScript's weak type detection per https://stackoverflow.com/a/47930521
  clickOk?: () => void
  clickCancel?: () => void

  isLoadingPublish: boolean = false

  get isAllowedPublish(): boolean {
    const failedJobs = this.getSelectedBuildPlanJobs
      .filter(
        (job) =>
          job.jobType === JobType.COMP ||
          job.jobType === JobType.COMPENSATE ||
          job.jobType === JobType.SIM ||
          job.jobType === JobType.SIMULATE,
      )
      .filter(
        (job) =>
          job.code === JobStatusCode.ERROR ||
          job.code === JobStatusCode.CANCELLED ||
          job.code === JobStatusCode.RUNNING,
      )

    return failedJobs.length > 0
  }

  async publish() {
    try {
      this.isLoadingPublish = true
      await this.publishBuildPlan()
      const msg = this.$i18n.t('publishTaskCreated') as string
      messageService.showSuccessMessage(msg)
    } catch (error) {
      const msg = this.$i18n.t('publishTaskFailed') as string
      messageService.showErrorMessage(`${msg} ${error.message}`)
    } finally {
      this.isLoadingPublish = false
    }
  }
}
