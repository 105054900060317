
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import fileExplorer from '@/api/fileExplorer'
import { IPartDto } from '@/types/PartsLibrary/Parts'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { IJob, JobStatusCode, JobType } from '@/types/PartsLibrary/Job'
import {
  AddPartToolState,
  GeometryType,
  IBuildPlan,
  IBuildPlanItem,
  ILoadingPart,
  IPrintStrategyParameterSet,
  PartProperty,
  PartTypes,
  ProcessState,
  IBinderJetParameterSetContent,
} from '@/types/BuildPlans/IBuildPlan'
import IToolComponent from '@/types/BuildPlans/IToolComponent'
import { PrintingTypes } from '@/types/IMachineConfig'
import { SortOrders } from '@/types/SortModes'
import { PaginationKeyword } from '@/types/PaginationKeyword'
import { FileExplorerItem } from '@/types/FileExplorer/FileExplorerItem'
import { PartListItemViewModel } from '@/components/layout/buildPlans/addPart/types'
import SearchField from '@/components/controls/Common/SearchField.vue'
import CommonBuildPlanToolsMixin from '@/components/layout/buildPlans/mixins/CommonBuildPlanToolsMixin'
import ViewOptionsMenu from '@/components/layout/buildPlans/addPart/ViewOptionsMenu.vue'
import PartListItem from '@/components/layout/buildPlans/addPart/PartListItem.vue'
import PartPropertyListItem from '@/components/layout/buildPlans/addPart/PartPropertyListItem.vue'
import SinglePartPropertyItem from '@/components/layout/buildPlans/addPart/SinglePartPropertyItem.vue'
import Splitter from '@/components/layout/Splitter.vue'
import { createGuid, isTabVisible } from '@/utils/common'
import StoresNamespaces from '@/store/namespaces'
import { visualizationModule } from '@/store/modules/visualization'
import {
  FILE_EXPLORER_PATH_DELIMITER,
  ITEM_VERSION_DELIMETER,
  PART_BODY_ID_DELIMITER,
  SINGLE_PART_VISUALIZATION_NAMESPACE,
} from '@/constants'
import messageService from '@/services/messageService'
import { ILoadedDocument } from '@/visualization/rendering/ModelManager'
import {
  AssemblyComponent,
  DocumentModel,
  Geometry,
  GeometryTypes,
  Part,
  PartComponent,
} from '@/visualization/models/DataModel'
import { Visualization } from '@/visualization'
import parts from '@/api/parts'
import i18n from '@/plugins/i18n'
import { SelectionTypes } from '@/types/FileExplorer/SelectionTypes'
import MultiPartPropertySelector, {
  PrintStrategyParameterSetPkPropSubNames,
  PropNames,
} from '@/components/layout/buildPlans/addPart/MultiPartPropertySelector.vue'
import PartTooltip from '@/components/layout/buildPlans/addPart/PartTooltip.vue'
import { BuildPlanPrintStrategyDto } from '@/types/PrintStrategy/BuildPlanPrintStrategy'
import { getDefaultBaseOnType } from '@/utils/parameterSet/parameterSetUtils'
import { VersionablePk } from '@/types/Common/VersionablePk'
import { equalWithTolerance } from '@/utils/number'
import PartsSearchMixin from './mixins/PartsSearchMixin'
import buildPlanItems from '@/api/buildPlanItems'

const partsStore = namespace(StoresNamespaces.Parts)
const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const singlePartVisualizationStore = namespace(StoresNamespaces.SinglePartVisualization)
const visualizationStore = namespace(StoresNamespaces.Visualization)

@Component({
  components: {
    SearchField,
    ViewOptionsMenu,
    PartListItem,
    PartPropertyListItem,
    Splitter,
    SinglePartPropertyItem,
    MultiPartPropertySelector,
    PartTooltip,
  },
})
export default class BuildPlanLayoutTab
  extends Mixins(CommonBuildPlanToolsMixin, PartsSearchMixin)
  implements IToolComponent
{
  @partsStore.Mutation updatePart: (updatedPart: IPartDto) => void

  @buildPlansStore.Action savePartByClick: Function

  @buildPlansStore.Mutation addToLoadingParts: Function
  @buildPlansStore.Mutation setAddPartToolState: (state: Partial<AddPartToolState>) => void
  @buildPlansStore.Mutation updatePartImportJobs: (jobs: IJob[]) => void
  @buildPlansStore.Mutation selectPart: (payload: {
    item: PartListItemViewModel
    selectionType: SelectionTypes
  }) => void
  @buildPlansStore.Mutation unselectPart: (payload: {
    item: PartListItemViewModel
    selectionType: SelectionTypes
  }) => void
  @buildPlansStore.Mutation unselectAllParts: Function
  @singlePartVisualizationStore.Mutation highlightBody: Function
  @singlePartVisualizationStore.Mutation calcGeometryPropsForSinglePart: Function
  @singlePartVisualizationStore.Mutation updateScaleForSinglePart: Function

  @buildPlansStore.Getter getLoadingParts: ILoadingPart[]
  @buildPlansStore.Getter getSelectedBuildPlanFinalizingJobs: IJob[]
  @buildPlansStore.Getter getBuildPlan: IBuildPlan
  @buildPlansStore.Getter getAllBuildPlanItems: IBuildPlanItem[]
  @buildPlansStore.Getter getIsLoading: boolean
  @buildPlansStore.Getter parameterSets: IPrintStrategyParameterSet[]
  @buildPlansStore.Getter parameterSetsLatestVersions: IPrintStrategyParameterSet[]
  @buildPlansStore.Getter getPartImportJobDescriptionByItemId: (id: string) => string
  @buildPlansStore.Getter getBuildPlanPrintStrategy: BuildPlanPrintStrategyDto
  @buildPlansStore.Getter isSinglePartPropertyMode: boolean
  @buildPlansStore.Getter('getAddPartToolSelectedParts') selectedParts: PartListItemViewModel[]

  @partsStore.Getter getAllParts: IPartDto[]
  @partsStore.Getter getAllSinterParts: IPartDto[]
  @partsStore.Getter getAllIbcParts: IPartDto[]
  @partsStore.Getter getPartIdsWithUsedDate: Array<{ partId: string; usedDate: Date }>

  @buildPlansStore.State parentFolder: FileExplorerItem

  @singlePartVisualizationStore.State visualization: Visualization

  @partsStore.Action getPartConfigFile: Function
  @partsStore.Action updatePartComponents: (payload: { id: string; components: string[] | null }) => Promise<any>
  @visualizationStore.Mutation deselect: () => void
  @visualizationStore.Getter isPartConfigLoading: boolean

  partProperties: {
    [id: string]: PartProperty[]
  } = {}
  partHiddenBodies: string[]
  partParametersScaleFactor: {
    [id: string]: number[]
  } = {}

  partStatusIntervalId: number
  partTypes = PartTypes
  isToolClosing: boolean = false

  hoveredItem = { item: null, top: 0 }
  isSecondaryActionClicked = false
  configsAreLoading = true
  configs = {}
  lastSelectedPart: PartListItemViewModel = null
  loadedPartConfigFiles: {
    [id: string]: DocumentModel
  } = {}
  partPropertiesLength: number = 0

  $refs!: {
    searchField: SearchField
  }

  /**************************************
   * Generic tool method implementations
   **************************************/
  // need to mention these generic optional methods even if they are not implemented by the tool
  // due to TypeScript's weak type detection per https://stackoverflow.com/a/47930521
  clickCancel: () => void

  async clickOk() {
    if (!this.selectedParts.length || this.getIsLoading) {
      return
    }
    this.isToolClosing = true

    this.setAddPartToolState({
      selectedPartProperties: this.partProperties,
      selectedPartsScale: this.partParametersScaleFactor,
    })

    this.selectedParts.forEach((selectedPart) => {
      this.loadPartOnScene(selectedPart.id, selectedPart.name, selectedPart.partType, selectedPart.hiddenBodies)
    })
  }

  async clickSecondaryAction() {
    this.onAddIconicButtonClick()
  }

  get partsUsedInBuildPlan() {
    return this.getAllBuildPlanItems.map((item) => item.part.id)
  }

  get partsList(): PartListItemViewModel[] {
    let partDtos: IPartDto[] = []

    if (this.filter.displayFolderContentOnly) {
      partDtos = this.getBuildPartsFromParentFolder()
    } else {
      partDtos = this.getAllParts
    }

    const hasFinalizingJobs = !!this.getSelectedBuildPlanFinalizingJobs.length

    // Only parts without errors should be present in the list
    let allParts: PartListItemViewModel[] = partDtos
      .map((part) => {
        const disabled =
          hasFinalizingJobs ||
          (part.status !== JobStatusCode.COMPLETE && part.status !== JobStatusCode.WARNING) ||
          this.isPartLoading(part.id) ||
          this.isPartDisabledDueToErrors(part) ||
          this.isPartWithSheetBodiesDisabled(part)
        const disabledDescription = disabled ? this.getDisabledDescription(part) : null
        return {
          disabled,
          disabledDescription,
          ...part,
          partType: PartTypes.BuildPlanPart,
          previewImageUrl: part.previewImageUrl,
        }
      })
      .filter((part) => !part.disabled && part.subType === ItemSubType.None && !part.isRemoved)

    // DMLM - display all the parts, excluding parts that were published from sinter plan.
    // BinderJet - display all the parts, including parts that were published from sinter plan or ibc plan
    if (this.printingType === PrintingTypes.BinderJet) {
      allParts = allParts.concat(this.sinterPartsList)
      allParts = allParts.concat(this.ibcPartsList)
    }

    const filteredList = this.filterAndSortPartsList(allParts, this.getAllBuildPlanItems)

    return filteredList
  }

  get sinterPartsList(): PartListItemViewModel[] {
    let partDtos: IPartDto[] = []

    if (this.filter.displayFolderContentOnly) {
      partDtos = this.getSinterPartsFromParentFolder()
    } else {
      partDtos = this.getAllSinterParts
    }

    const hasFinalizingJobs = !!this.getSelectedBuildPlanFinalizingJobs.length
    return partDtos
      .map((part) => {
        const disabled =
          hasFinalizingJobs ||
          (part.status !== JobStatusCode.COMPLETE && part.status !== JobStatusCode.WARNING) ||
          this.isPartLoading(part.id)
        const disabledDescription = disabled ? this.getDisabledDescription(part) : null
        return {
          disabled,
          disabledDescription,
          ...part,
          partType: PartTypes.SinterPart,
          previewImageUrl: part.previewImageUrl,
        }
      })
      .filter((part) => !part.disabled && part.visibility)
  }

  get ibcPartsList(): PartListItemViewModel[] {
    let partDtos: IPartDto[] = []

    if (!this.filter.displayFolderContentOnly) {
      partDtos = this.getAllIbcParts
    } else {
      partDtos = this.getIbcPartsFromParentFolder()
    }

    const hasFinalizingJobs = !!this.getSelectedBuildPlanFinalizingJobs.length
    return partDtos
      .map((part) => {
        const disabled =
          hasFinalizingJobs ||
          (part.status !== JobStatusCode.COMPLETE && part.status !== JobStatusCode.WARNING) ||
          this.isPartLoading(part.id)
        const disabledDescription = disabled ? this.getDisabledDescription(part) : null
        return {
          disabled,
          disabledDescription,
          ...part,
          partType: PartTypes.IbcPart,
          previewImageUrl: null,
        }
      })
      .filter((part) => !part.disabled && part.visibility)
  }

  get isShownSinterPartsList(): boolean {
    if (this.getBuildPlan.subType === ItemSubType.SinterPlan) {
      return false
    }

    return this.getBuildPlan.modality === PrintingTypes.BinderJet
  }

  get isSelectedPartsLoaded(): PartListItemViewModel[] {
    return this.selectedParts.length && !this.getIsLoading ? this.selectedParts : []
  }

  get contentFolderName(): string {
    if (typeof this.parentFolder === 'undefined') {
      return ''
    }
    return this.parentFolder ? this.parentFolder.name : this.$i18n.t('allFiles').toString()
  }

  get isShownMultiPartPropertySelector(): boolean {
    return !!this.partPropertiesLength && this.isSelectedMultipleParts && this.areSelectedPartsPropertiesLoaded
  }

  get areSelectedPartsPropertiesLoaded() {
    return this.partPropertiesLength >= this.selectedParts.length
  }

  get isSelectedSinglePart() {
    return this.selectedParts.length === 1
  }

  get isSelectedMultipleParts() {
    return this.selectedParts.length > 1
  }

  get selectedSinglePart() {
    if (this.isSelectedSinglePart) {
      const [selectedPart] = this.selectedParts
      return selectedPart
    }
  }

  get selectedSinglePartProperties() {
    if (this.selectedSinglePart) {
      const selectedSinglePartProperties = this.partProperties[this.selectedSinglePart.id]
      if (selectedSinglePartProperties) {
        return selectedSinglePartProperties.map((prop) => ({ ...prop }))
      }
    }
  }

  // If the part was published from a sinter plan - use initial body function
  get isBodyTypeOptionsDisabled() {
    return this.selectedParts.length && this.selectedParts.some((part) => part.bodyFunction)
  }

  isPartWithSheetBodiesDisabled(part: IPartDto) {
    return part.hasSheetBodies && this.printingType === PrintingTypes.BinderJet
  }

  isPartPropertyValid(prop: PartProperty): boolean {
    const hasType = this.isSinglePartPropertyMode
      ? prop.type !== null && prop.processState !== null
      : prop.type !== null

    const validParam =
      Boolean(prop.printStrategyParameterSetId === null && prop.printStrategyParameterSetVersion) ||
      this.parameterSetsLatestVersions.some(
        (ps) => ps.id === prop.printStrategyParameterSetId && ps.version === prop.printStrategyParameterSetVersion,
      )

    return hasType && validParam
  }

  @Watch('isPartConfigLoading')
  @Watch('isSelectedPartsLoaded')
  async onSelectedPartLoaded() {
    // The following check is needed because this event can be triggered during component disposal
    // and we don't want to handle such event
    if (this.isToolClosing) {
      return
    }

    if (!this.isSelectedPartsLoaded.length) this.disableOkButton(true)

    this.partParametersScaleFactor = {}

    if (this.isSelectedMultipleParts) {
      return
    }

    const [selectedPart] = this.selectedParts

    if (selectedPart && this.partProperties[selectedPart.id]) {
      const isPartEachBodyPropertyDefined = this.partProperties[selectedPart.id].every(this.isPartPropertyValid)
      this.disableOkButton(!isPartEachBodyPropertyDefined)
      return
    }

    this.partProperties = {}
    this.partPropertiesLength = 0

    if (!selectedPart || this.isPartConfigLoading) {
      return
    }

    const foundDocument: ILoadedDocument = this.visualization.loadedDocuments.find(
      (doc) => doc.partId === selectedPart.id,
    )
    if (foundDocument) {
      await this.setPartProperties({ [selectedPart.id]: foundDocument.document }, [selectedPart])
    }

    this.partPropertiesLength = Object.keys(this.partProperties).length

    this.partHiddenBodies = []
  }

  @Watch('selectedParts')
  async onMultiplePartsSelected() {
    if (!this.isSelectedMultipleParts) {
      return
    }

    this.partProperties = {}
    this.partPropertiesLength = 0
    this.disableOkButton(true)

    for (const part of this.selectedParts) {
      if (!this.loadedPartConfigFiles[part.id]) {
        this.loadedPartConfigFiles[part.id] = await this.getPartConfigFile(part.id)
      }
    }

    await this.setPartProperties(this.loadedPartConfigFiles, this.selectedParts)

    this.partHiddenBodies = []
  }

  @Watch('partProperties')
  onPartPropertiesChanged() {
    if (!this.partPropertiesLength) {
      this.disableOkButton(true)
      return
    }
    const isAllPartsEachBodyPropertyDefined: boolean = Object.values(this.partProperties).every((pp) => {
      const isPartEachBodyPropertyDefined = pp.every((prop) => {
        const hasType = this.isSinglePartPropertyMode
          ? prop.type !== null && prop.processState !== null
          : prop.type !== null
        const validParam =
          (prop.printStrategyParameterSetId === null && prop.printStrategyParameterSetVersion) ||
          this.parameterSetsLatestVersions.some(
            (ps) => ps.id === prop.printStrategyParameterSetId && ps.version === prop.printStrategyParameterSetVersion,
          )
        return hasType && validParam
      })

      if (isPartEachBodyPropertyDefined) {
        this.setPartPropertiesGroupId(pp)
      }

      return isPartEachBodyPropertyDefined
    })

    this.selectedParts.forEach((selectedPart) => {
      const scale = this.getPartScaleFactor(selectedPart.id)
      if (scale && !this.isScaleEqual(this.partParametersScaleFactor[selectedPart.id], scale)) {
        this.partParametersScaleFactor = { ...this.partParametersScaleFactor, [selectedPart.id]: scale }
      }
    })

    this.disableOkButton(!isAllPartsEachBodyPropertyDefined)
  }

  @Watch('partParametersScaleFactor')
  onPartParametersScaleFactorChange() {
    if (this.selectedParts && this.selectedParts.length && !this.getIsLoading) {
      const [selectedPart] = this.selectedParts
      if (this.partParametersScaleFactor && this.partParametersScaleFactor[selectedPart.id]) {
        this.updateScaleForSinglePart({
          selectedPartId: selectedPart.id,
          parameterSetScaleFactor: this.partParametersScaleFactor[selectedPart.id],
        })
        this.calcGeometryPropsForSinglePart()
      }
    }
  }

  @Watch('isSecondaryActionClicked')
  @Watch('selectedParts')
  updateCancelButtonName() {
    let name = this.$i18n.t('cancel')
    if (this.isSecondaryActionClicked) {
      name = this.selectedParts.length ? this.$i18n.t('cancel') : this.$i18n.t('close')
    }
    this.changeCancelButtonName(name.toString())
  }

  @Watch('getAllParts')
  onPartsChanged(curr: IPartDto[], prev: IPartDto[]) {
    if (!curr || !prev) return

    const nonExistentParts = prev.filter((p) => !curr.find((c) => c.id === p.id))

    if (!nonExistentParts.length) return

    for (const nonExistentPart of nonExistentParts) {
      messageService.showWarningMessage(
        this.$i18n.t('PartNoLongerExist', { partName: nonExistentPart.name }).toString(),
      )

      const selectedPart = this.selectedParts.find((p) => p.id === nonExistentPart.id)

      if (!selectedPart) continue

      this.unselectPart({ item: selectedPart, selectionType: SelectionTypes.Single })
    }
  }

  beforeCreate() {
    // Single part needs its own canvas element, instances of the Vuex visualization module and Visualization class
    this.$store.registerModule(SINGLE_PART_VISUALIZATION_NAMESPACE, visualizationModule)
  }

  mounted() {
    this.setFocus()
    this.disableOkButton(true)
    this.triggerPartJobStatusCheck()
    this.getDefectedPartsConfigs()
    this.deselect()
  }

  setFocus() {
    setTimeout(() => {
      if (this.$refs.searchField) {
        this.$refs.searchField.focus()
      }
    }, 0)
  }

  disableOkButton(value: boolean) {
    this.$emit('setOkDisabled', value)
  }

  async getDefectedPartsConfigs() {
    this.configsAreLoading = true
    const partsWithIgnoredBodies = this.getAllParts.filter(
      (p: IPartDto) => p.hasErrors && p.hiddenBodies && p.hiddenBodies.length,
    )

    const configs = await Promise.all(partsWithIgnoredBodies.map((p: IPartDto) => this.getPartConfigFile(p.id)))
    partsWithIgnoredBodies.forEach((p: IPartDto, index) => {
      this.configs = { ...this.configs, ...{ [p.id]: configs[index] } }
    })
    this.configsAreLoading = false
  }

  setPartPropertiesGroupId(partProperties: PartProperty[]): PartProperty[] {
    const mapKeyToGroupId = new Map<string, string>()

    partProperties.forEach((prop) => {
      const key = `${prop.type}_${prop.printStrategyParameterSetId}_${prop.bodyType}`
      let groupId: string = mapKeyToGroupId.get(key)

      if (!groupId) {
        groupId = createGuid()
        mapKeyToGroupId.set(key, groupId)
      }

      prop.groupId = groupId
    })

    return partProperties
  }

  async setPartProperties(
    partConfigFiles: { [partId: string]: DocumentModel },
    selectedParts: PartListItemViewModel[],
  ) {
    let selectedPartsProperties = { ...this.partProperties }

    for (const selectedPart of selectedParts) {
      const mostRecentlyUsedProperties: PartProperty[] = await this.getMostRecentlyUsedProperties(selectedPart)

      if (this.isPartPropertiesAbleToBeCloned(mostRecentlyUsedProperties)) {
        this.sortUsedPartProperties(mostRecentlyUsedProperties)
        this.setDefaultParameterSetIfNeeded(mostRecentlyUsedProperties)
        this.setUsedPartPropertiesIndexId(mostRecentlyUsedProperties)
        this.setPartPropertiesGroupId(mostRecentlyUsedProperties)
        this.setDefaultProcessStateIfNeeded(mostRecentlyUsedProperties, selectedPart.isPublishedAsScaled)

        selectedPartsProperties = { ...selectedPartsProperties, [selectedPart.id]: mostRecentlyUsedProperties }

        const componentsValues = mostRecentlyUsedProperties.map((p) => p.geometryId)

        if (componentsValues.length && !selectedPart.components.length) {
          await this.updatePartComponents({ id: selectedPart.id, components: componentsValues })
        }

        continue
      }

      // Traverse the component tree and get all the part components
      const model = partConfigFiles[selectedPart.id]

      if (selectedPart.components.length) {
        const existingPartProperties = this.getExistingPartProperties(model, selectedPart)
        selectedPartsProperties = { ...selectedPartsProperties, [selectedPart.id]: existingPartProperties }

        continue
      }

      const partComponents = this.getPartComponents(model)
      const partProperties: PartProperty[] = []
      const mapIdToPart = model.parts.reduce<Record<string, { geometries: Geometry[]; name: string }>>((map, part) => {
        map[part.id] = {
          geometries: selectedPart.hiddenBodies
            ? part.geometries.filter((g) => !selectedPart.hiddenBodies.includes(g.id))
            : part.geometries,
          name: part.name,
        }
        return map
      }, {})

      let id = 0
      partComponents.forEach((component) => {
        const part = mapIdToPart[component.partID]
        const geometries: Geometry[] = part ? part.geometries : []

        if (geometries.length) {
          geometries
            .filter((g) => !selectedPart.hiddenBodies || !selectedPart.hiddenBodies.includes(g.id))
            .forEach((geometry) => {
              id += 1

              const names: string[] = [component.name, part.name, geometry.name]
              const name: string = names
                .filter(Boolean)
                .map((item) => item.trim())
                .join('-')

              const property: PartProperty = {
                id,
                type: null,
                printStrategyParameterSetId: null,
                printStrategyParameterSetVersion: 1,
                geometryId: `${component.id}_${geometry.id}`,
                name: name || 'N/A',
                groupId: '',
                bodyType: geometry.type || GeometryTypes.Solid,
                processState: this.getDefaultProcessState(selectedPart),
              }

              partProperties.push(property)
            })
        }
      })

      selectedPartsProperties = { ...selectedPartsProperties, [selectedPart.id]: partProperties }
      const partComponentsValues = partProperties.map((p) => p.geometryId)

      if (partComponentsValues.length) {
        await this.updatePartComponents({ id: selectedPart.id, components: partComponentsValues })
      }
    }

    await this.setSinterPartDefaultProperties(selectedParts, selectedPartsProperties)

    this.partProperties = { ...selectedPartsProperties }
    this.partPropertiesLength = Object.keys(this.partProperties).length
  }

  getPartComponents(model: DocumentModel): PartComponent[] {
    const queue = [model.components as AssemblyComponent | PartComponent]
    const partComponents: PartComponent[] = []

    const isPartComponent = (arg: any): arg is PartComponent => arg.partID !== undefined

    while (queue.length) {
      const component = queue.shift()
      if (isPartComponent(component)) {
        partComponents.push(component)
      } else {
        if (component.children) {
          component.children.forEach((child) => queue.push(child as AssemblyComponent | PartComponent))
        }
      }
    }

    return partComponents
  }

  async getMostRecentlyUsedProperties(selectedPart: PartListItemViewModel): Promise<PartProperty[]> {
    const searchParams = new URLSearchParams()
    searchParams.append(PaginationKeyword.Sort, 'updatedAt')
    searchParams.append(PaginationKeyword.Order, SortOrders.Descending)
    searchParams.append(PaginationKeyword.Limit, '1')
    searchParams.append(PaginationKeyword.Offset, '0')
    searchParams.append(PaginationKeyword.Filter, 'deleted_at')
    searchParams.append(PaginationKeyword.FilterItems, 'null')

    const items: IBuildPlanItem[] = await parts.getBuildPlanItemsByPartId(selectedPart.id, searchParams.toString())

    if (!items.length) {
      return null
    }

    return items[0].partProperties
  }

  setDefaultParameterSetIfNeeded(properties: PartProperty[]): PartProperty[] {
    const ids: number[] = this.parameterSetsLatestVersions.map((set) => set.id)

    properties.forEach((prop) => {
      if (!ids.includes(prop.printStrategyParameterSetId)) {
        prop.printStrategyParameterSetId = null
        prop.printStrategyParameterSetVersion = 1
      }
    })

    return properties
  }

  setDefaultProcessStateIfNeeded(properties: PartProperty[], isPublishedAsScaled: boolean): PartProperty[] {
    properties.forEach((property) => {
      // If incoming part is legacy: Apply "Green".
      if (isPublishedAsScaled) {
        property.processState = ProcessState.Green
        return
      }

      if (!property.processState || (this.isSinterPlan && property.processState === ProcessState.Green)) {
        property.processState = ProcessState.Nominal
      }
    })

    return properties
  }

  sortUsedPartProperties(properties: PartProperty[]): PartProperty[] {
    const getValue = (type: GeometryType): number => {
      if (type === GeometryType.Production) {
        return 0
      }
      if (type === GeometryType.Support) {
        return 1
      }
      return 2
    }

    return properties.sort((a, b) => {
      return getValue(a.type) - getValue(b.type)
    })
  }

  setUsedPartPropertiesIndexId(properties: PartProperty[]): PartProperty[] {
    let id = 0
    properties.forEach((prop) => {
      id += 1
      prop.id = id
    })

    return properties
  }

  async triggerPartJobStatusCheck() {
    const mapIdToPart = this.getAllParts
      .filter(
        (part) =>
          part.jobType === JobType.IMPORT &&
          part.status !== JobStatusCode.COMPLETE &&
          part.status !== JobStatusCode.WARNING,
      )
      .reduce<Record<string, IPartDto>>((map, part) => {
        map[part.id] = part
        return map
      }, {})

    const itemIds: string[] = Object.keys(mapIdToPart)

    if (itemIds.length > 0) {
      await this.checkPartJobStatus(itemIds, mapIdToPart)

      if (this.partStatusIntervalId) {
        window.clearInterval(this.partStatusIntervalId)
      }

      this.partStatusIntervalId = window.setInterval(() => {
        this.checkPartJobStatus(itemIds, mapIdToPart)
      }, 5000)
    }
  }

  onPartClick(item: PartListItemViewModel, event) {
    if (item.disabled) {
      return
    }
    const { ctrlKey, shiftKey, metaKey } = event

    if (shiftKey && !!this.lastSelectedPart && this.lastSelectedPart.id !== item.id) {
      const prevIndex = this.partsList.findIndex((o) => this.lastSelectedPart.id === o.id)
      const curIndex = this.partsList.findIndex((o) => item.id === o.id)

      const start = prevIndex < curIndex ? prevIndex : curIndex
      const end = prevIndex < curIndex ? curIndex : prevIndex

      this.unselectAllParts()

      // tslint:disable-next-line: no-increment-decrement
      for (let i = start; i <= end; i++) {
        const curItem = this.partsList[i]
        this.selectPart({ item: curItem, selectionType: SelectionTypes.Multiple })
        this.lastSelectedPart = curItem
      }
      // On Macintosh keyboards the metaKey will be true when Command key is pressed.
    } else if (ctrlKey || metaKey) {
      if (this.selectedParts.some((o) => o.id === item.id)) {
        this.unselectPart({ item, selectionType: SelectionTypes.Multiple })
      } else {
        this.selectPart({ item, selectionType: SelectionTypes.Multiple })
        this.lastSelectedPart = item
      }
    } else {
      if (this.selectedParts.some((o) => o.id === item.id) && this.selectedParts.length === 1) {
        this.unselectPart({ item, selectionType: SelectionTypes.Single })
      } else {
        this.selectPart({ item, selectionType: SelectionTypes.Single })
        this.lastSelectedPart = item
      }
    }
  }

  onPartPropertyChange(changedProperty: PartProperty) {
    const { id } = this.selectedSinglePart

    const targetProperty = this.partProperties[id].find((property) => property.id === changedProperty.id)
    if (targetProperty) {
      Object.assign(targetProperty, changedProperty)
    }

    this.partProperties = { ...this.partProperties }
  }

  // Copy the body properties and apply them to all other bodies that are CAD instances
  onPartPropertyCopyInstances(sourceProperty: PartProperty) {
    const { id } = this.selectedSinglePart
    const sourceBodyId: string = sourceProperty.geometryId.split(PART_BODY_ID_DELIMITER)[1]
    this.partProperties[id].forEach((targetProperty) => {
      const targetBodyId: string = targetProperty.geometryId.split(PART_BODY_ID_DELIMITER)[1]
      if (targetBodyId === sourceBodyId && sourceProperty.id !== targetProperty.id) {
        const hasPrintStrategyParameterSetInSource =
          sourceProperty.hasOwnProperty('printStrategyParameterSetId') &&
          sourceProperty.hasOwnProperty('printStrategyParameterSetVersion')
        const printStrategyParameterSetId = hasPrintStrategyParameterSetInSource
          ? sourceProperty.printStrategyParameterSetId
          : targetProperty.printStrategyParameterSetId
        const printStrategyParameterSetVersion = hasPrintStrategyParameterSetInSource
          ? sourceProperty.printStrategyParameterSetVersion
          : targetProperty.printStrategyParameterSetVersion

        targetProperty.printStrategyParameterSetId = printStrategyParameterSetId
        targetProperty.printStrategyParameterSetVersion = printStrategyParameterSetVersion
        if (sourceProperty.type) {
          targetProperty.type = sourceProperty.type
        }
      }
    })
    this.partProperties = { ...this.partProperties }
  }

  onPartPropertyCopyAll(sourceProperty: PartProperty) {
    const [selectedPart] = this.selectedParts
    const id = selectedPart.id
    this.partProperties[id].forEach((targetProperty) => {
      const hasPrintStrategyParameterSetInSource =
        sourceProperty.hasOwnProperty('printStrategyParameterSetId') &&
        sourceProperty.hasOwnProperty('printStrategyParameterSetVersion')
      const printStrategyParameterSetId = hasPrintStrategyParameterSetInSource
        ? sourceProperty.printStrategyParameterSetId
        : targetProperty.printStrategyParameterSetId
      const printStrategyParameterSetVersion = hasPrintStrategyParameterSetInSource
        ? sourceProperty.printStrategyParameterSetVersion
        : targetProperty.printStrategyParameterSetVersion

      targetProperty.printStrategyParameterSetId = printStrategyParameterSetId
      targetProperty.printStrategyParameterSetVersion = printStrategyParameterSetVersion
      if (sourceProperty.type) {
        targetProperty.type = sourceProperty.type
      }
      if (sourceProperty.processState) {
        targetProperty.processState = sourceProperty.processState
      }
    })
    this.partProperties = { ...this.partProperties }
  }

  onPartsPropertiesAssignToAllBodies({ sourceProperty: { propertyName, propertyValue }, isAssignToAllBodies }) {
    Object.keys(this.partProperties).forEach((id) => {
      if (propertyName === PropNames.ProcessState) {
        const selectedPart = this.selectedParts.find((part) => part.id === id)

        if (selectedPart.isPublishedAsScaled) {
          return
        }
      }

      // Do not change Body Type Function if part is published from sinter plan
      if (propertyName === PropNames.Type) {
        const selectedPart = this.selectedParts.find((part) => part.id === id)
        if (selectedPart.partType !== PartTypes.BuildPlanPart) {
          return
        }
      }

      this.partProperties[id].forEach((targetProperty) => {
        if (propertyName === PropNames.PrintStrategyParameterSetPk) {
          this.applyPrintStrategyParameterSetPkToTargetProperty(targetProperty, propertyValue, isAssignToAllBodies)
        } else {
          targetProperty[propertyName] = isAssignToAllBodies
            ? propertyValue
            : targetProperty[propertyName] || propertyValue
        }
      })
    })
    this.partProperties = { ...this.partProperties }
  }

  applyPrintStrategyParameterSetPkToTargetProperty(
    targetProperty: PartProperty,
    printStrategyParameterSetPk,
    isAssignToAllBodies: boolean,
  ) {
    if (!isAssignToAllBodies && targetProperty[PrintStrategyParameterSetPkPropSubNames.Id]) {
      return
    }

    targetProperty[PrintStrategyParameterSetPkPropSubNames.Id] = printStrategyParameterSetPk.id
    targetProperty[PrintStrategyParameterSetPkPropSubNames.Version] = printStrategyParameterSetPk.version
  }

  onPartPropertyHover(property: PartProperty, isHover: boolean) {
    this.highlightBody({ id: property.geometryId, showHighlight: isHover })
  }

  onAddIconicButtonClick() {
    if (!this.selectedParts.length || this.getIsLoading) {
      return
    }

    this.setAddPartToolState({
      selectedPartProperties: this.partProperties,
      selectedPartsScale: this.partParametersScaleFactor,
    })

    this.selectedParts.forEach((selectedPart) => {
      this.loadPartOnScene(selectedPart.id, selectedPart.name, selectedPart.partType, selectedPart.hiddenBodies)
    })

    this.setAddPartToolState({
      selectedParts: [],
    })

    this.isSecondaryActionClicked = true
  }

  onPartHideBodies(hiddenBodies: string[]) {
    this.partHiddenBodies = hiddenBodies
  }

  loadPartOnScene(
    partId: string,
    partName: string,
    partType: PartTypes = PartTypes.BuildPlanPart,
    excludeGeometries: string[] = [],
  ) {
    const loadingPartIndex = this.getLoadingParts.length

    this.addToLoadingParts({ partId, name: partName, id: loadingPartIndex })
    const hasSupportBodies = this.partProperties[partId].some((p) => p.type === GeometryType.Support)
    const partProps = this.partProperties[partId][0]
    const geometryTypes = new Map<string, GeometryType>()
    this.partProperties[partId].forEach((property) => geometryTypes.set(property.geometryId, property.type))
    const parameterSetScaleFactor = this.getPartScaleFactor(partId, true)

    this.savePartByClick({
      partId,
      partName,
      loadingPartIndex,
      partType,
      hasSupportBodies,
      excludeGeometries,
      geometryTypes,
      parameterSetScaleFactor,
      processState: partProps.processState,
      dragDrop: false,
      pointerX: null,
      pointerY: null,
      hiddenBodies: this.partHiddenBodies,
    })
  }

  changeCancelButtonName(name: string) {
    this.$emit('setCancelName', name)
  }

  isPartSelected(part: PartListItemViewModel): boolean {
    if (!this.selectedParts.length) {
      return false
    }

    return this.selectedParts.some((o) => o.id === part.id)
  }

  isPartLoading(partId: string): boolean {
    return this.getLoadingParts.some((part) => part.partId === partId && part.isSuccess === null)
  }

  isPartDisabledDueToErrors(part: IPartDto): boolean {
    let allBodiesAreIgnored = part.hasErrors
    if (!this.configsAreLoading && part.hasErrors && part.hiddenBodies && part.hiddenBodies.length) {
      const geometries =
        this.configs[part.id] &&
        this.configs[part.id].parts.reduce((arr: Geometry[], p: Part) => {
          arr.push(...p.geometries)
          return arr
        }, [])
      allBodiesAreIgnored = part.hiddenBodies.length === geometries.length
    }
    return (part.hasErrors && part.hiddenBodies === null) || allBodiesAreIgnored
  }

  getOkName(): string {
    return 'add'
  }

  getDisabledDescription(part: IPartDto): string {
    if (this.isPartWithSheetBodiesDisabled(part)) {
      return i18n.t('disabledPartWithSheetBodiesTooltip') as string
    }

    const hasFinalizingJobs = !!this.getSelectedBuildPlanFinalizingJobs.length
    if (hasFinalizingJobs) {
      return i18n.t('finalizingTasksPropt') as string
    }

    const isImporting = part.status === JobStatusCode.RUNNING || part.status === JobStatusCode.QUEUED
    if (isImporting) {
      return i18n.t('importSnackbarMsg') as string
    }

    // Import errors
    const isImportError = part.status !== JobStatusCode.COMPLETE && part.status !== JobStatusCode.WARNING
    if (isImportError) {
      const msg = this.getPartImportJobDescriptionByItemId(part.id)
      return i18n.t('partImportError', { errorMessage: msg }) as string
    }

    if (part.hasErrors) {
      return i18n.t('partHasErrorsMsg') as string
    }

    return null
  }

  beforeDestroy() {
    clearInterval(this.partStatusIntervalId)
    this.$store.unregisterModule(SINGLE_PART_VISUALIZATION_NAMESPACE)
    this.setAddPartToolState({
      selectedParts: [],
      geometryProperties: null,
    })
  }

  partHovered(event: { top: number; item: PartListItemViewModel }) {
    this.hoveredItem = event
  }

  private async checkPartJobStatus(itemIds: string[], mapIdToPart: Record<string, IPartDto>) {
    if (!itemIds.length) {
      clearInterval(this.partStatusIntervalId)
      return
    }

    if (isTabVisible()) {
      const jobs = await fileExplorer.getGetRunningAndFailedJobsByItemIds(itemIds)
      const importJobs = jobs.filter((job) => job.jobType === JobType.IMPORT)
      this.updatePartImportJobs(importJobs)
      importJobs.forEach((job) => {
        if ([JobStatusCode.COMPLETE, JobStatusCode.WARNING, JobStatusCode.ERROR].includes(job.code)) {
          const part = mapIdToPart[job.itemId]
          if (part) {
            this.updatePart({ ...part, status: job.code })
            const index = itemIds.indexOf(job.itemId)
            if (index !== -1) {
              itemIds.splice(index, 1)
            }
          }
        }
      })
    }
  }

  private getBuildPartsFromParentFolder(): IPartDto[] {
    const folderId: string = this.parentFolder ? this.parentFolder.id : null
    return this.getAllParts.filter((part) => part.parentId === folderId)
  }

  private getSinterPartsFromParentFolder(): IPartDto[] {
    const folderId: string = this.parentFolder ? this.parentFolder.id : null

    return this.getAllSinterParts.filter((part) => {
      const ancestorIds = part.path.split(FILE_EXPLORER_PATH_DELIMITER).slice(1, -1)
      const depth = part.sinterPlanVersion.split(ITEM_VERSION_DELIMETER).length - 1
      const rootSinterPlanIdIndex = ancestorIds.length - depth - 1
      const rootSinterPlanParentId = ancestorIds[rootSinterPlanIdIndex - 1] || null

      return rootSinterPlanParentId === folderId
    })
  }

  private getIbcPartsFromParentFolder(): IPartDto[] {
    const folderId: string = this.parentFolder ? this.parentFolder.id : null

    return this.getAllIbcParts.filter((part) => {
      const ancestorIds = part.path.split(FILE_EXPLORER_PATH_DELIMITER).slice(1, -1)
      const depth = part.ibcPlanVersion.split(ITEM_VERSION_DELIMETER).length - 1
      const rootIbcPlanIdIndex = ancestorIds.length - depth - 1
      const rootIbcPlanParentId = ancestorIds[rootIbcPlanIdIndex - 1] || null

      return rootIbcPlanParentId === folderId
    })
  }

  private getDefaultProcessState(selectedPart: PartListItemViewModel): ProcessState {
    if (!this.selectedParts.length) return null

    if (selectedPart && selectedPart.partType === PartTypes.SinterPart && selectedPart.isPublishedAsScaled) {
      return ProcessState.Green
    }

    if (this.isSinterPlan) {
      return ProcessState.Nominal
    }

    return null
  }

  private isPartPropertiesAbleToBeCloned(properties: PartProperty[]): boolean {
    if (!properties) {
      return false
    }

    if (this.isSinglePartPropertyMode) {
      return this.isPartPropertiesSame(properties)
    }

    return true
  }

  private isPartPropertiesSame(properties: PartProperty[]): boolean {
    const [first] = properties
    return properties.every(
      (property) =>
        property.type === first.type &&
        property.printStrategyParameterSetId === first.printStrategyParameterSetId &&
        property.printStrategyParameterSetVersion === first.printStrategyParameterSetVersion &&
        property.processState === first.processState,
    )
  }

  private getMapIdToPartRecords(model: DocumentModel, selectedPart: PartListItemViewModel) {
    return model.parts.reduce<Record<string, { geometries: Geometry[]; name: string }>>((map, part) => {
      map[part.id] = {
        geometries: selectedPart.hiddenBodies
          ? part.geometries.filter((g) => !selectedPart.hiddenBodies.includes(g.id))
          : part.geometries,
        name: part.name,
      }
      return map
    }, {})
  }

  private generatePartProperty(
    id: number,
    geometryId: string,
    name: string,
    bodyType: GeometryTypes,
    selectedPart: PartListItemViewModel,
  ): PartProperty {
    if (!id || !geometryId) throw new Error('Id and geometryId are required properties for body')

    return {
      id,
      geometryId,
      name: name || 'N/A',
      bodyType: bodyType || GeometryTypes.Solid,
      type: null,
      printStrategyParameterSetId: null,
      printStrategyParameterSetVersion: 1,
      groupId: '',
      processState: this.getDefaultProcessState(selectedPart),
    }
  }

  private getPartPropertyName(componentName: string, partName: string, geometryName: string) {
    const names: string[] = [componentName, partName, geometryName]

    return names
      .filter(Boolean)
      .map((item) => item.trim())
      .join('-')
  }

  /** Get the part properties from the part's list of components */
  private getExistingPartProperties(model: DocumentModel, selectedPart: PartListItemViewModel) {
    const partComponents = this.getPartComponents(model)
    const mapIdToPart = this.getMapIdToPartRecords(model, selectedPart)
    const partProperties: PartProperty[] = []

    let id = 0

    for (const selectedPartComponent of selectedPart.components) {
      id += 1

      const splittedComponentValue = selectedPartComponent.split('_')
      const componentId = splittedComponentValue[0]
      const geometryId = splittedComponentValue[1]

      const component = partComponents.find((i) => i.id === componentId)
      const componentName = component.name
      const part = mapIdToPart[component.partID]
      const geometries: Geometry[] = part ? part.geometries : []
      const geometry = geometries.find((g) => g.id === geometryId)
      const propertyName = this.getPartPropertyName(componentName, part.name, geometry.name)
      const property = this.generatePartProperty(id, selectedPartComponent, propertyName, geometry.type, selectedPart)

      if (!property) return

      partProperties.push(property)
    }

    return partProperties
  }

  private getPartScaleFactor(partID: string, ignoreProcessState = false): number[] {
    const partPropertyList = this.partProperties[partID]

    if (!partPropertyList) {
      return null
    }

    const partProps = partPropertyList[0]
    let parameterSetScaleFactor: number[] = [1, 1, 1]
    if (
      !this.isSinterPlan &&
      this.printingType === PrintingTypes.BinderJet &&
      (ignoreProcessState || partProps.processState === ProcessState.Nominal)
    ) {
      let printStrategyParameterSetPk: VersionablePk
      if (partProps.printStrategyParameterSetId) {
        printStrategyParameterSetPk = new VersionablePk(
          partProps.printStrategyParameterSetId,
          partProps.printStrategyParameterSetVersion,
        )
      } else {
        const defaults = this.getBuildPlanPrintStrategy.defaults
        printStrategyParameterSetPk = getDefaultBaseOnType(defaults, partProps.type, partProps.bodyType)
      }

      if (!printStrategyParameterSetPk) return null

      const bjPartParameters = this.parameterSets.find((p) => {
        return p.id === printStrategyParameterSetPk.id && p.version === printStrategyParameterSetPk.version
      }).parameterSet.partParameters as IBinderJetParameterSetContent

      parameterSetScaleFactor = [
        bjPartParameters.ScaleFactors.ScaleFactorX,
        bjPartParameters.ScaleFactors.ScaleFactorY,
        bjPartParameters.ScaleFactors.ScaleFactorZ,
      ]
    }
    return parameterSetScaleFactor
  }

  private isScaleEqual(prevScale: number[], newScale: number[]): boolean {
    return (
      prevScale &&
      newScale &&
      prevScale.length === 3 &&
      newScale.length === 3 &&
      equalWithTolerance(prevScale[0], newScale[0], Number.EPSILON) &&
      equalWithTolerance(prevScale[1], newScale[1], Number.EPSILON) &&
      equalWithTolerance(prevScale[2], newScale[2], Number.EPSILON)
    )
  }

  private async setSinterPartDefaultProperties(
    selectedParts: PartListItemViewModel[],
    selectedPartsProperties: {
      [id: string]: PartProperty[]
    },
  ) {
    // According to https://jira-ebm.additive.ge.com/browse/YPVJZ-23565
    // point 17: If the part is not being used by any build plan – Use ‘Apply Scaling’
    // point 128: If the Print Strategy that is used by the Build Plan is the same Print Strategy
    //            that was used by the Sinter Plan from which the selected part was published – use the Part Parameter
    //            that was used by the part in the Sinter Plan
    // GEAMPREQ-1559:
    // If the part was published from a sinter plan (SBC or IBC) - always use the body function that was used in
    // the Sinter Plan when it was published
    for (const selectedPart of selectedParts) {
      if (selectedPart.partType === PartTypes.BuildPlanPart) {
        continue
      }

      const publishedPartProps: PartProperty[] = selectedPartsProperties[selectedPart.id]
      const propsWithoutType: PartProperty[] = publishedPartProps.filter((pp) => !pp.type)

      let spItem: IBuildPlanItem
      if (selectedPart.partType === PartTypes.IbcPart) {
        spItem = await buildPlanItems.getBuildPlanItemByIbcPartId(selectedPart.id)
      } else if (selectedPart.nominalPartId) {
        const spItems = await buildPlanItems.getBuildPlanItemsByBuildPlanIdAndPartId(
          selectedPart.parentId,
          selectedPart.nominalPartId,
        )
        spItem = spItems[0]
      }

      publishedPartProps.forEach((prop) => {
        prop.type = selectedPart.bodyFunction
      })

      if (!spItem) {
        continue
      }

      propsWithoutType.forEach((prop) => {
        const spItemProperty = spItem.partProperties.find((pp) => pp.id === prop.id)
        if (!spItemProperty) {
          return
        }

        const bpPsHaveSameParametersAsSpPs = this.getBuildPlanPrintStrategy.printStrategyParameterSets.some(
          (psps) =>
            psps.id === spItemProperty.printStrategyParameterSetId &&
            psps.version === spItemProperty.printStrategyParameterSetVersion,
        )

        prop.processState = ProcessState.Nominal

        if (bpPsHaveSameParametersAsSpPs) {
          prop.printStrategyParameterSetId = spItemProperty.printStrategyParameterSetId
          prop.printStrategyParameterSetVersion = spItemProperty.printStrategyParameterSetVersion
        }
      })
    }
  }
}
