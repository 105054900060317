import { ICommand } from '@/types/UndoRedo/ICommand'
import { CommandType } from '@/types/UndoRedo/CommandType'
import { ILabel, ILabelStyle } from '@/types/Marking/ILabel'
import UpsertMarkingLabel from '@/components/layout/buildPlans/marking/UpsertMarkingLabel.vue'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

export type LabelToolPropertyValue = string | number | null

export class LabelToolPropertyCommand implements ICommand {
  readonly commandType: CommandType
  readonly toolName = ToolNames.LABEL

  private readonly propertyName: string
  private readonly previousLabelPropertyValue: LabelToolPropertyValue
  private readonly nextLabelPropertyValue: LabelToolPropertyValue

  constructor(
    commandType: CommandType,
    propertyName: string,
    previousLabelPropertyValue: LabelToolPropertyValue,
    nextLabelPropertyValue: LabelToolPropertyValue,
    public context: UpsertMarkingLabel,
    public labelToUpdate: ILabel,
  ) {
    this.commandType = commandType
    this.propertyName = propertyName
    this.previousLabelPropertyValue = previousLabelPropertyValue
    this.nextLabelPropertyValue = nextLabelPropertyValue
  }

  async redo(): Promise<void> {
    // @ts-ignore
    await this.context.onUpdateLabelStyleValueByPropertyName(
      this.propertyName,
      this.nextLabelPropertyValue,
      this.labelToUpdate,
      false,
    )
  }

  async undo(): Promise<void> {
    // @ts-ignore
    await this.context.onUpdateLabelStyleValueByPropertyName(
      this.propertyName,
      this.previousLabelPropertyValue,
      this.labelToUpdate,
      false,
    )
  }
}
