import { FILTER_BY_ITEM_TYPE_PARAMETER, SORT_STRATEGY_PARAMETER } from '@/constants'
import { ItemFormat } from '@/types/FileExplorer/FileExplorerItem'
import { SortOrders } from '@/types/SortModes'

export class ItemsQueryStringBuilder {
  private searchParams: URLSearchParams

  constructor() {
    this.reset()
  }

  public reset() {
    this.searchParams = new URLSearchParams()
  }

  public setSort(sort: { field: string; order: SortOrders }) {
    const { field, order } = sort
    if (field && order) {
      this.searchParams.set('sortBy', `${field}:${order}`)
    }
    return this
  }

  public setSortStrategy(strategy: string) {
    if (strategy) {
      this.searchParams.set(SORT_STRATEGY_PARAMETER, strategy)
    }
    return this
  }

  public setItemTypeFilter(value: string) {
    if (value) {
      this.searchParams.set(FILTER_BY_ITEM_TYPE_PARAMETER, value)
    }
    return this
  }

  public setItemFormat(format: ItemFormat) {
    if (format) {
      this.searchParams.set('format', format)
    }
    return this
  }

  public buildAsString(): string {
    const str = this.searchParams.toString()
    this.reset()
    return str
  }

  public buildAsUrlParams(): URLSearchParams {
    const copy = new URLSearchParams(this.searchParams)
    this.reset()
    return copy
  }
}
