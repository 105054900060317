import { Component, Vue } from 'vue-property-decorator'
import {
  FilterOptions,
  PartListItemViewModel,
  PartListItemWithPartPathNames,
  PartListSortField,
  PartListTemViewModelWithUsedDate,
} from '../addPart/types'
import { IBuildPlan, IBuildPlanItem, PartTypes } from '@/types/BuildPlans/IBuildPlan'
import { CadFileTypes } from '@/types/PartsLibrary/Parts'
import StoresNamespaces from '@/store/namespaces'
import { namespace } from 'vuex-class'
import { ItemSubType } from '@/types/FileExplorer/ItemType'
import { PrintingTypes } from '@/types/IMachineConfig'
import { SortOrders } from '@/types/SortModes'
import { orderBy } from '@/utils/array'
import localStorageService, { LocalStorageKeys } from '@/services/localStorageService'

const SORT_FIELD_DEFAULT = PartListSortField.Name
const SORT_ORDER_DEFAULT = SortOrders.Ascending
const ALLOWED_PART_CAD_FILE_TYPE_FOR_SINTER_PLAN = [CadFileTypes.Brep, CadFileTypes.BrepMesh]
const SEARCH_FOLDER_SEPARATOR = '/'
const FOLDER_CONTENT_VISIBILITY_DEFAULT = false
const PARTS_FROM_SINTER_PLAN_VISIBILITY_DEFAULT = false
const SINTERED_PARTS_VISIBILITY_DEFAULT = false
const IBC_PARTS_VISIBILITY_DEFAULT = false

const buildPlansStore = namespace(StoresNamespaces.BuildPlans)
const partsStore = namespace(StoresNamespaces.Parts)

@Component
export default class PartsSearchMixin extends Vue {
  @buildPlansStore.Getter getBuildPlan: IBuildPlan

  @partsStore.Getter getPartIdsWithUsedDate: Array<{ partId: string; usedDate: Date }>

  search = ''
  sortOrder: SortOrders = null
  sortField: PartListSortField = null
  filter: FilterOptions = null

  get isSinterPlan(): boolean {
    return this.getBuildPlan.subType === ItemSubType.SinterPlan
  }

  get printingType(): string {
    return this.getBuildPlan.modality
  }

  get shouldDisplaySinteredPartsOnly(): boolean {
    return !this.isSinterPlan && this.printingType === PrintingTypes.BinderJet && this.filter.displaySinteredPartsOnly
  }

  get shouldDisplayIbcPartsOnly(): boolean {
    return !this.isSinterPlan && this.printingType === PrintingTypes.BinderJet && this.filter.displayIbcPartsOnly
  }

  created() {
    this.retrieveUserSettings()
  }

  onSearchInput(value: string) {
    this.search = value.trim()
  }

  onSortChange(payload: { field: PartListSortField; order: SortOrders }) {
    this.sortField = payload.field
    this.sortOrder = payload.order
    this.saveUserSettings()
  }

  onFilterChange(filter: FilterOptions) {
    this.filter = filter
    this.saveUserSettings()
  }

  /**
   * Filters and sorts list of parts by search string and selected options
   * @param parts parts to filter and sort
   * @param partsToExclude parts to exclude from search
   * @returns list of parts
   */
  filterAndSortPartsList(parts: PartListItemViewModel[], partsToExclude: IBuildPlanItem[]): PartListItemViewModel[] {
    const partsIdsToExclude = new Set(partsToExclude.map((item) => item.part.id))

    const filteredList: PartListItemViewModel[] = parts.filter((part) => {
      // Filters parts that appear in build plan.
      if (partsIdsToExclude.has(part.id)) {
        return false
      }

      // Display only sintered parts and ibc parts if both filters are enabled
      if (
        this.shouldDisplaySinteredPartsOnly &&
        this.shouldDisplayIbcPartsOnly &&
        ![PartTypes.SinterPart, PartTypes.IbcPart].includes(part.partType)
      ) {
        return false
      }

      // Display only sintered parts if the corresponding filter is enabled
      if (
        this.shouldDisplaySinteredPartsOnly &&
        !this.shouldDisplayIbcPartsOnly &&
        part.partType !== PartTypes.SinterPart
      ) {
        return false
      }

      // Display only ibc parts if the corresponding filter is enabled
      if (
        this.shouldDisplayIbcPartsOnly &&
        !this.shouldDisplaySinteredPartsOnly &&
        part.partType !== PartTypes.IbcPart
      ) {
        return false
      }

      // Filter parts for sinter plan
      if (this.getBuildPlan.subType === ItemSubType.SinterPlan) {
        if (!ALLOWED_PART_CAD_FILE_TYPE_FOR_SINTER_PLAN.includes(part.cadFileType)) {
          return false
        }
      }

      return true
    })

    const filteredBySearchStringList = this.getPartsBySearchString(filteredList)

    return this.sortListByField(filteredBySearchStringList, this.sortField, this.sortOrder)
  }

  /** Set initial filter and sort settings on component create */
  retrieveUserSettings() {
    const settings: {
      sortField: PartListSortField
      sortOrder: SortOrders
      filter: FilterOptions
    } = localStorageService.get(LocalStorageKeys.AddPartToolSettings) || {}

    this.sortField = settings.sortField || SORT_FIELD_DEFAULT
    this.sortOrder = settings.sortOrder || SORT_ORDER_DEFAULT

    const {
      displayFolderContentOnly = FOLDER_CONTENT_VISIBILITY_DEFAULT,
      displayPartsFromSinterPlans = PARTS_FROM_SINTER_PLAN_VISIBILITY_DEFAULT,
      displaySinteredPartsOnly = SINTERED_PARTS_VISIBILITY_DEFAULT,
      displayIbcPartsOnly = IBC_PARTS_VISIBILITY_DEFAULT,
    } = settings.filter || {}

    this.filter = {
      displayFolderContentOnly,
      displayPartsFromSinterPlans,
      displaySinteredPartsOnly,
      displayIbcPartsOnly,
    }
  }

  /** Save filter and sort settings when they change */
  saveUserSettings() {
    localStorageService.set(LocalStorageKeys.AddPartToolSettings, {
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      filter: this.filter,
    })
  }

  private sortListByField(list: PartListItemViewModel[], field: PartListSortField, order: SortOrders) {
    switch (field) {
      case PartListSortField.Name:
        return orderBy(list, ['name'], [order])
      case PartListSortField.CreationDate:
        return orderBy(list, ['createdAt'], [order])
      case PartListSortField.ModifiedDate:
        return orderBy(list, ['updatedAt'], [order])
      case PartListSortField.UsedDate:
        const listWithUsedDate = this.addUsedDatePropertyToPartList(list)
        return orderBy(listWithUsedDate, ['usedDate'], [order])
      default:
        return list
    }
  }

  private getPartsBySearchString(partsList: PartListItemViewModel[]): PartListItemViewModel[] {
    if (!this.search) {
      return partsList
    }

    const partMap = new Map<string, PartListItemWithPartPathNames>()

    partsList.forEach((part) => {
      let partPathNames = part.parentFolderNames ? [...part.parentFolderNames] : []
      partPathNames.unshift(this.$t('allFiles').toString())
      if (part.subType === ItemSubType.SinterPart) {
        partPathNames.push(part.sinterPlanName)
      }
      if (part.subType === ItemSubType.IbcPart) {
        partPathNames.push(part.ibcPlanName)
      }
      partPathNames.push(part.name)

      // If some folder names contains "/" need to separate names by "/" to be able to search for them
      partPathNames = partPathNames
        .map((name) => name.toLowerCase().split(SEARCH_FOLDER_SEPARATOR).filter(Boolean))
        .flat()

      const partInMap: PartListItemWithPartPathNames = {
        ...part,
        partPathNames,
      }
      partMap.set(part.id, partInMap)
    })

    const searchChunks = this.search.toLowerCase().split(SEARCH_FOLDER_SEPARATOR).filter(Boolean)
    const numberOfSearchChunks = searchChunks.length

    // Foreach chunk check all available parts.
    // if part path names contain chunk remove path names up to found name
    // else remove part from list of available parts
    searchChunks.forEach((chunk, chunkIndex) => {
      partMap.forEach((part, partId) => {
        const partPathNames = part.partPathNames
        // Remove part if number of chunks to check is bigger than part path names
        if (partPathNames.length < numberOfSearchChunks - chunkIndex) {
          partMap.delete(partId)
          return
        }

        const foundNameIndex = partPathNames.findIndex((name) => name.includes(chunk))
        if (foundNameIndex === -1) {
          partMap.delete(partId)
        } else {
          partPathNames.splice(0, foundNameIndex + 1)
        }
      })
    })

    return [...partMap.values()]
  }

  private addUsedDatePropertyToPartList(list: PartListItemViewModel[]): PartListTemViewModelWithUsedDate[] {
    return list.map((item) => {
      const { usedDate } = this.getPartIdsWithUsedDate.find((i) => i.partId === item.id)
      return {
        ...item,
        usedDate,
      }
    })
  }
}
