import { Color3, Matrix, Vector2, Vector3 } from '@babylonjs/core/Maths'
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo'
import { IUnits } from './types/IUnits'
import { FileExplorerTableState } from './types/FileExplorer/FileExplorerTableState'
import { SortParamsKey } from './types/FileExplorer/SortParamsKey'
import { ViewMode } from './types/FileExplorer/ViewMode'
import { FilterParamsKey } from './types/FileExplorer/FilterParamsKey'
import { IConstraints } from './types/BuildPlans/IConstraints'
import { CanBeTrashedResult, CanBeTrashedStatus } from './types/FileExplorer/CanBeTrashed'
import { JobStatusCode } from './types/PartsLibrary/Job'
import { ItemPermissionsRole } from './types/FileExplorer/Permission'
import ViewModeTypes from './visualization/types/ViewModeTypes'
import { Setting } from '@/types/Label/Setting'
import { BooleanType, MarkingLocation, SupportedFontStyles, TextAlign, TextAlignVertical } from '@/types/Label/enums'
import { InteractiveLabelSet } from '@/types/Label/InteractiveLabelSet'
import { FontNames } from '@/types/Label/FontStyleHelpBalloonInfo'

export const PLATFORM_NAME: string = 'Additive Manufacturing Platform'

export const SNACKBAR_TIMEOUT = 10000 // 10 seconds
export const SNACKBAR_EVENT = 'snackbar:push'
export const SNACKBAR_SLEEP_TIME = 200

export const NOTIFICATION_TIMEOUT = 30000 // 30 seconds

export const INCORRECT_UUID_MESSAGE_ERROR = 'Validation failed (uuid v4 is expected)'
export const INCORRECT_UUID_MESSAGE_ERROR_2 = 'Validation failed (uuid vundefined is expected)'
export const MISSING_COST_PROPERTIES_ERROR = 'Missing cost-related properties'

export const AMP_CT_COOKIE = 'amp_ct'

export const firstNameRules: object = [
  (v) => !!v || 'First Name is required',
  (v) => /^[a-zA-Z0-9_]+$/.test(v) || 'First Name must be valid',
]
export const lastNameRules: object = [
  (v) => !!v || 'Last Name is required',
  (v) => /^[a-zA-Z0-9_]+$/.test(v) || 'Last Name must be valid',
]
export const passwordRules: object = [
  (v) => !!v || 'Password is required',
  (v) => /.{8,}/.test(v) || 'Password should be minimum 8 characters',
]

export const emailRules: object = [
  (v) => !!v || 'E-mail is required',
  (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
]

export const imageRules: object = [(v) => !v || v.size < 2000000 || 'Image size should be lower that 2 MB']

export const LATIN_CHARACTERS_PATTERN = /^[a-zA-Z0-9`~!@#%&=;:'",<>_ \.\^\$\*\+\-\?\(\)\[\]\{\}\\\|\/]+$/
export const MULTI_LINE_LATIN_CHARACTERS_PATTERN = new RegExp(
  '^[a-zA-Z0-9`~!@#%&=;:\'",<>_ \\.\\^\\$\\*\\+\\-\\?\\(\\)\\[\\]\\{\\}\\\\|\\/\\r\\n\\s]+$',
)
export const ONLY_WHITESPACES_PATTERN = '^[\\s]+$'

export enum ViewModes {
  List = 'list',
  Grid = 'grid',
}

export enum DeleteType {
  All = 'all',
  Selected = 'selected',
}

export enum Role {
  Admin = 'Admin',
  ProjectCreator = 'Project Creator',
  SystemAdmin = 'System Admin',
  SystemUser = 'System User',
}

export enum Permission {
  CreateUsers = 'create:users',
  ReadUsers = 'read:users',
  UpdateUsers = 'update:users',
  DeleteUsers = 'delete:users',
  ReadJobs = 'read:jobs',
  AccessCostEstimator = 'access:costestimator',
  AccessPrintModel = 'access:printmodel',
}

export enum LabelTabStyleViewMode {
  List = 1,
  Edit,
  Create,
  Delete,
}

export const measurementUnits: IUnits[] = [
  { id: 0, name: 'millimeters', alias: 'mm' },
  { id: 1, name: 'centimeters', alias: 'cm' },
  { id: 2, name: 'meters', alias: 'm' },
  { id: 3, name: 'kilometers', alias: 'km' },
  { id: 4, name: 'inches', alias: 'in' },
  { id: 5, name: 'unknown', alias: 'n/a' },
]

export const DEFAULT_UNITS_ID: number = 0
export const DEFAULT_GRID_PAGINATION_LIMIT = 10
export const DEFAULT_LIST_PAGINATION_LIMIT = 25

export const DEFAULT_COST_ESTIMATION_PROJECT_NAME: string = 'Cost Study'
export const MAX_COST_ESTIMATION_SETS = 3

export const DPI: number = 96 // for browser the dpi is 96 by default
export const INCH_IN_MM: number = 25.4
export const MICRONS_IN_MILLIMETER = 1000
export const DEFAULT_SUPPORT_VOLUME_PERCENT_THAT_IS_USED = 5

export enum CadHelperProcess {
  EstimateCost = 'estimateCost',
  UploadModels = 'uploadModels',
  UploadMeasurementData = 'UploadMeasurementData',
}

// Specific modifier for EBM square-shaped envelope machines
export const ENVELOPE_X_Y_MODIFIER_FOR_LIKELY_AND_OPTIMISTIC = 1.41
export const DEFAULT_MACHINE_BUILD_CHAMBER_UNIT = 'm'
export const PRECISIONS = {
  m: 3,
  cm: 1,
  mm: 0,
  km: 6,
  in: 2,
  'n/a': 0,
}

export const BUILD_PLATE_SIZES_TO_MM = 1000
export const M_TO_MM = 1000
export const DEFAULT_TRANSFORMATION_MATRIX = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]
export const IDENTITY_MATRIX = Matrix.Identity()
export const DEFAULT_PATCH_TEMPLATE_ID = 0
// default max distance to the build plate dictated by Barracuda
export const DEFAULT_MAX_DISTANCE_TO_BUILD_PLATE = 1e-3

export const ACCURACY = 0.0001
export const MIN = 1e10
export const MAX = -1e10

export const BUILD_PLATE_GRID_RATIO = 10
export const DEFAULT_CAMERA_RADIUS = 100

export const POINTER_MOVE_THRESHOLD = 3
export const OVERHANG_MAX_ANGLE_VALUE = 45
export const RECOATER_MAX_ANGLE_VALUE = 20
export const MAX_DEPTH_DROP = 0.05 // depth color is in range [0, 1]
export const MAX_LABEL_ANGLE_BETWEEN_NORMALS = 45
export const MAX_LABEL_NORMALS_TOLERANCE = 75
export const TEXTURE_TEXT_SHARPNESS = 60
export const OVERHANG_RADIUS = 1
export const OVERHANG_EDGE_WIDTH = 10
export const DEFECT_POINT_DIAMETER = 1 / 2
export const DEFECT_TUBE_DIAMETER = 1 / 8
export const BUILD_CHAMBER_LINES_MIN_Z = 1
export const LABEL_SENSITIVE_ZONE_SCALE = 1.05
export const SYMBOL_UPPER_A_CODE = 65
export const LATIN_SYMBOLS_LENGTH = 26
export const MAX_SAFE_DOSING_HEIGHT_MM = 215

// SHADERS
export const VERTEX_SHADER = 'VertexShader'
export const FRAGMENT_SHADER = 'FragmentShader'
export const VERTEX_SHADER_COMMON = 'vertexShaderCommon'
export const FRAGMENT_SHADER_COMMON = 'fragmentShaderCommon'
export const OVERHANG_AREAS = 'overhangAreas'
export const RECOATER_DIRECTION = 'recoaterDirection'
export const LABEL_AREAS = 'labelAreas'
export const SECTION_COLORING = 'sectionColoring'
export const FACE_COLORING = 'faceColoring'
export const FACET_COLORING = 'facetColoring'
export const MULTI_ITEM_FACE_COLORING = 'multiItemFaceColoring'
export const PICKING = 'picking'
export const FLAT = 'flat'
export const DEPTH = 'depth'
export const POSITION_ATTRIBUTE = 'position'
export const NORMAL_ATTRIBUTE = 'normal'
export const COLOR_ATTRIBUTE = 'color'
export const FACE_ID_ATTRIBUTE = 'faceId'
export const COLOR_FOR_PART = 'pColor'
export const COLOR_FOR_FACE = 'fColor'
export const COLOR_FOR_BODY = 'bColor'
export const COLOR_SWITCHER = 'colorUnit'
export const FACET_COLOR_ATTRIBUTE = 'facetColor'
export const WORLD_MATRIX = 'world'
export const WORLD_VIEW_PROJECTION_MATRIX = 'worldViewProjection'
export const VIEW_PROJECTION_MATRIX = 'viewProjection'
export const REGULAR_COLOR = 'regularColor'
export const SPECULAR_COLOR = 'specularColor'
export const MAIN_CAMERA_NAME = 'mainCamera'
export const ZOOM_CAMERA_NAME = 'zoomCamera'
export const CAMERA_POSITION = 'cameraPosition'
export const MAX_CAMERA_DISTANCE = 'maxCameraDistance'
export const POINT_LIGHT_POSITION = 'pointLightPosition'
export const AMBIENT_LIGHT_DIRECTION = 'ambientLightDirection'
export const SECTIONS_COLORS = 'sectionColors'
export const VERTICAL_SECTIONS_COUNT = 'vSections'
export const HORIZONTAL_SECTIONS_COUNT = 'hSections'
export const SELECTED_FACES_ID = 'selectedFacesId'
export const MULTI_ITEM_COLLECTOR_SELECTION_COLORS = 'multiItemCollectorSelectionColors'
export const MULTI_ITEM_COLLECTOR_INSTANCES_COLORS = 'multiItemCollectorInstancesColors'
export const MULTI_ITEM_COLLECTOR_INSTANCES = 'multiItemCollectorInstances'
export const MULTI_ITEM_COLLECTOR_HIGHLIGHT_FACES = 'multiItemCollectorHighlightInstances'
export const SELECTED_FACES_COUNT = 'selectedFacesCount'
export const HIGHLIGHTED_FACES_COUNT = 'highlightedFacesCount'
export const INSTANCES_COUNT = 'instancesCount'
export const COLLECTOR_INSTANCE_ID = 'collectorInstanceId'
export const ERROR_FACES_ID = 'errorFacesId'
export const ERROR_FACES_COUNT = 'errorFacesCount'
export const OVERHANG_ANGLE = 'overhangAngle'
export const MAX_LABEL_ANGLE = 'maxLabelAngle'
export const OVERHANG_AREA_COLOR = 'overhangAreaColor'
export const RECOATER_DIRECTION_COLOR = 'recoaterDirectionColor'
export const LABEL_AREA_COLOR = 'labelAreaColor'
export const RECOATER_MAX_ANGLE = 'recoaterMaxAngle'
export const HOVER_COLOR = 'hoverColor'
export const ERROR_COLOR = 'errorColor'
export const SELECTION_COLOR = 'selectionColor'
export const HOVER_FACE_ID = 'hoverFaceId'
export const HOVER_FACET = 'hoverFacet'
export const SHOW_HOVER = 'showHover'
export const HIGHLIGHT_INSTANCE_ID = 'highlightInstanceId'
export const INSTANCE_ID = 'instanceId'
export const LABEL_ID = 'labelId'

export const GROUP_PARENT_MESH_NAME = 'Group Parent'
export const MOCK_PART_MESH_NAME = 'MockLoadingPart'
export const INSIDE_MESH_NAME = 'meshInside'
export const SECTION_PLANE_HELPER_MESH_NAME = 'sectionPlaneHelper'
export const SECTION_PLANE_HELPER_LINE_NAME_1 = 'crossSectionReferenceLine1'
export const SECTION_PLANE_HELPER_LINE_NAME_2 = 'crossSectionReferenceLine2'
export const OVERHANG_INSIDE_MESH_NAME = 'overhangInside'
export const SUPPORT_INSIDE_MESH_NAME = 'supportInside'
export const DEFECT_INSIDE_MESH_NAME = 'defectInside'
export const LABEL_INSIDE_MESH_NAME = 'labelInside'
export const GROUND_BOX_NAME = 'BuildPlate'
export const SINTER_PLATE_NAME = 'SinterPlate'
export const RECOATER_DIRECTION_MESH_NAME = 'recoaterDirectionMesh'
export const PRINT_HEAD_DIRECTION_MESH_NAME = 'printHeadDirectionMesh'
export const GAS_FLOW_MESH_NAME = 'gasFlowMesh'
export const INDICATOR_ARROW_MESH_NAME = 'indicatorArrowMesh'
export const FRONT_INDICATOR_MESH_NAME = 'frontIndicatorMesh'
export const BUILD_CHAMBER_BOTTOM_POLYLINES_NAME = 'buildChamberBottomPolylines'
export const BUILD_CHAMBER_TOP_POLYLINES_NAME = 'buildChamberTopPolylines'
export const BUILD_CHAMBER_SIDE_POLYLINES_NAME = 'buildChamberSidePolylines'
export const BUILD_CHAMBER_POLYLINES_NAME = 'buildChamberPolylines'
export const PRINT_HEAD_LANES_MESH_NAME = 'printHeadLanesMesh'
export const SLICE_POLYLINES_SYSTEM_NAME = 'polylinesSystem'
export const SLICE_POLYLINES_MARKER_NAME = 'polylinesMarker'
export const BVH_BOX = 'bvhBox'
export const MESH_HULL = 'meshHull'
export const SUPPORT_PARENT = 'supportParent'
export const SUPPORT = 'support'
export const LINE_SUPPORT = 'lineSupport'
export const TRANSPARENT_MESH_ENDING = '_transparent'
export const INSIDE_TRANSPARENT_MESH_ENDING = '_insideTransparent'
export const DEFAULT_MATERIAL_NAME = 'default'
export const SHADER_DEFAULT_MATERIAL_NAME = 'shaderDefault'
export const MESHING_MATERIAL = 'meshing'
export const SEMI_TRANSPARENT_DEFAULT_MATERIAL_NAME = 'defaultSemiTransparent'
export const SEMI_TRANSPARENT_INSIDE_MATERIAL_NAME = 'insideSemiTransparent'
export const HIGHLIGHT_MATERIAL_NAME = 'highlightMat'
export const SHADER_HIGHLIGHT_MATERIAL_NAME = 'shaderHighlightMat'
export const SHADER_SELECTION_MATERIAL_NAME = 'shaderSelectionMat'
export const SELECTION_MATERIAL_NAME = 'selectionMat'
export const HIGHLIGHT_ERROR_MATERIAL_NAME = 'highlightErrorMat'
export const SELECTION_ERROR_MATERIAL_NAME = 'selectionErrorMat'
export const MESH_INSIDE_MATERIAL_NAME = 'meshInsideMaterial'
export const BOX_MATERIAL_NAME = 'boxMat'
export const ERROR_BOX_MATERIAL_NAME = 'errorBoxMat'
export const WIREFRAME_MATERIAL_NAME = 'wireframe'
export const BUILD_PLATE_MATERIAL_NAME = 'buildPlateMaterial'
export const SINTER_PLATE_MATERIAL_NAME = 'sinterPlateMaterialName'
export const PART_PREVIEW_PLATE_MATERIAL_NAME = 'partPreviewPlateMaterialName'
export const RECOATER_ARROW_MATERIAL_NAME = 'recoaterArrowMaterial'
export const GAS_FLOW_ARROW_MATERIAL_NAME = 'gasFlowArrowMaterial'
export const PRINT_HEAD_ARROW_MATERIAL_NAME = 'printHeadArrowMaterial'
export const FRONT_INDICATOR_MATERIAL_NAME = 'frontIndicatorMaterial'
export const LABEL_MATERIAL_NAME = 'labelMaterial'
export const SEMI_TRANSPARENT_LABEL_MATERIAL_NAME = 'labelSemiTransparentMaterial'
export const LABEL_INSIDE_MATERIAL = 'labelInsideMaterial'
export const DEFECTIVE_FACES_MATERIAL_NAME = 'defectiveFacesMaterial'
export const FOOTPRINT_MATERIAL_NAME = 'footprintMaterial'
export const CENTER_OF_GRAVITY_MATERIAL_NAME = 'centerOfGravityMaterial'
export const SPATIAL_LETTER_GRID_NAME = 'spatialLetterGrid'
export const SUPPORT_MATERIAL = 'supportMaterial'
export const SUPPORT_INSIDE_MATERIAL = 'supportInsideMaterial'
export const SUPPORT_SELECTION_MATERIAL = 'supportSelectionMaterial'
export const SEMI_TRANSPARENT_SUPPORT_MATERIAL = 'supportMaterialSemiTransparent'
export const SEMI_TRANSPARENT_SUPPORT_INSIDE_MATERIAL = 'supportInsideMaterialSemiTransparent'
export const SEMI_TRANSPARENT_SUPPORT_SELECTION_MATERIAL = 'supportSelectionMaterialSemiTransparent'
export const OVERHANG_MATERIAL = 'overhangMaterial'
export const OVERHANG_INSIDE_MATERIAL = 'overhangInsideMaterial'
export const OVERHANG_ERROR_MATERIAL = 'overhangErrorMaterial'
export const OVERHANG_HOVER_MATERIAL = 'overhangHoverMaterial'
export const OVERHANG_SELECTION_MATERIAL = 'overhangSelectionMaterial'
export const CROSS_SECTION_PLANE_MATERIAL = 'crossSectionPlaneMaterial'
export const SLICING_MESH_MATERIAL = 'slicingMeshMaterial'
export const CROSS_SECTION_MESH_MATERIAL = 'crossSectionMeshMaterial'
export const CROSS_SECTION_SEMI_TRANSPARENT_MESH_MATERIAL = 'crossSectionSemiTransparentMeshMaterial'
export const POLYLINES_MARKER_WARNING_MATERIAL = 'polylinesMarkerWarningMaterial'
export const POLYLINES_MARKER_ERROR_MATERIAL = 'polylinesMarkerErrorMaterial'
export const FAILED_DUPLCIATE_MATERIAL = 'failedDuplicateMaterial'
export const DEFECT_MATERIAL = 'defectMaterial'
export const INSPECTION_MATERIAL_NAME = 'inspectionMaterial'
export const INSPECTION_HIGHLIGHT_MATERIAL_NAME = 'inspectionHighlightMaterial'
export const DEFECT_INSIDE_MATERIAL = 'defectInsideMaterial'
export const REGULAR_YELLOW_MATERIAL = 'yellowMat'
export const KEEP_OUT_ZONE = 'KeepOutZone'
export const KEEP_OUT_BOX = 'KeepOutBox'
export const BUILD_VOLUME_LIMIT_ZONE = 'BuildVolumeLimitZone'
export const BUILD_VOLUME_LIMIT_BOX = 'BuildVolumeLimitBox'
export const LABEL = 'Label'
export const LABEL_CACHED = 'LabelCached'
export const LABEL_MESH = 'LabelMesh'
export const LABEL_SENSITIVE_ZONE = 'LabelSensitiveZone'
export const LABEL_SENSITIVE_ZONE_CACHED = 'LabelSensitiveZoneCached'
export const DEFECT_GROUP = 'DefectGroup'
export const DEFECT = 'Defect'
export const POINT_DEFECT = 'PointDefect'
export const LINE_DEFECT = 'LineDefect'
export const LOOP_DEFECT = 'LoopDefect'
export const FACE_DEFECT = 'FaceDefect'
export const MESH_DEFECT = 'MeshDefect'
export const LABEL_ORIGIN = 'LabelOrigin'
export const OVERHANG_NAME = 'overhang'
export const OVERHANG_SHORT_NAME = 'ovhg'
export const CENTER_OF_GRAVITY = 'CenterOfGravity'
export const FOOTPRINT = 'Footprint'
export const OVERHANG = 'Overhang'
export const TEMP = 'temp'
export const CHARACTER = 'character'
export const PLANAR_GIZMO_NAME = 'planeDragBox'
export const ROTATION_GIZMO_ARROW_NAME = 'rotationArrow'
export const LABEL_HANDLE_BORDER = 'manualLabelHandleBorder'
export const DUPLICATE_WRAPPER = 'duplicateWrapper'
export const CLEARANCE_LINE = 'clearanceLine'
export const RUBBERBAND_LINE = 'rubberBandLine'
export const CLEARANCE_LINE_MATERIAL = 'clearancetLineMat'
export const CLEARANCE_PLANE_MATERIAL = 'clearancePlaneMat'
export const CLEARANCE_PLANE = 'clearancePlane'
export const CLEARANCE_PRINT_HEAD_LANES_ID = 'printHeadLane_'
export const CLEARANCE_BUILD_VOLUME_WALL_ID = 'buildVolumeWall_'
export const CLEARANCE_CEILING_ID = 'ceiling'
export const CLEARANCE_BUILDPLATE_ID = 'buildPlate'
export const IGNORED_MESH_NAMES = [
  BVH_BOX,
  INSIDE_MESH_NAME,
  KEEP_OUT_BOX,
  KEEP_OUT_ZONE,
  BUILD_VOLUME_LIMIT_ZONE,
  BUILD_VOLUME_LIMIT_BOX,
  LABEL,
  `${OVERHANG_INSIDE_MESH_NAME}_${OVERHANG_NAME}`,
  `${SUPPORT_INSIDE_MESH_NAME}_${LINE_SUPPORT}`,
  LINE_SUPPORT,
  OVERHANG_NAME,
  SECTION_PLANE_HELPER_MESH_NAME,
  CLEARANCE_LINE,
  RUBBERBAND_LINE,
  CLEARANCE_PLANE,
]
export const IGNORED_MESH_NAMES_EXCLUDING_KOZ = [
  BVH_BOX,
  INSIDE_MESH_NAME,
  BUILD_VOLUME_LIMIT_ZONE,
  BUILD_VOLUME_LIMIT_BOX,
  LABEL,
  `${OVERHANG_INSIDE_MESH_NAME}_${OVERHANG_NAME}`,
  `${SUPPORT_INSIDE_MESH_NAME}_${LINE_SUPPORT}`,
  LINE_SUPPORT,
  OVERHANG_NAME,
  SECTION_PLANE_HELPER_MESH_NAME,
  CLEARANCE_LINE,
  RUBBERBAND_LINE,
  CLEARANCE_PLANE,
]
export const WORLD_EXTENDS_EXCLUDED_MESHES = [
  BVH_BOX,
  INSIDE_MESH_NAME,
  KEEP_OUT_BOX,
  KEEP_OUT_ZONE,
  BUILD_VOLUME_LIMIT_ZONE,
  BUILD_VOLUME_LIMIT_BOX,
  BUILD_CHAMBER_BOTTOM_POLYLINES_NAME,
  BUILD_CHAMBER_SIDE_POLYLINES_NAME,
  BUILD_CHAMBER_TOP_POLYLINES_NAME,
  BUILD_CHAMBER_POLYLINES_NAME,
  RECOATER_DIRECTION_MESH_NAME,
  GAS_FLOW_MESH_NAME,
  INDICATOR_ARROW_MESH_NAME,
  PRINT_HEAD_DIRECTION_MESH_NAME,
  FRONT_INDICATOR_MESH_NAME,
  SINTER_PLATE_NAME,
  SECTION_PLANE_HELPER_MESH_NAME,
  SECTION_PLANE_HELPER_LINE_NAME_1,
  SECTION_PLANE_HELPER_LINE_NAME_2,
  MOCK_PART_MESH_NAME,
]
export const OVERHANG_EDGES_NAME = 'ovhg_edges'
export const OVERHANG_VERTICES_NAME = 'ovhg_vertices'
export const OVERHANG_CONTOUR_NAME = 'ovhg_contour'
export const OVERHANG_TRIANGLE_NAME = 'ovhg_triangles'
export const PARAMETER_SET_SCALE_NAME = 'parameterSetScale'

export const X_CHAR = 'x'
export const Y_CHAR = 'y'
export const Z_CHAR = 'z'

export const FILE_EXPLORER_PATH_DELIMITER = '/'
export const ROOT_FOLDER_ID = '0'

export const DEFAULT_ITEM_VERSION = '1'
export const ITEM_VERSION_DELIMETER = '.'

export const DEFAULT_USER_PERMISSION_ROLE_FOR_RELATED_ITEM = ItemPermissionsRole.Viewer

export const BACKQUOTE_CODE = 'Backquote'
export const DELETE_CODE = 'Delete'
export const ESCAPE_CODE = 'Escape'

export const NUMPAD_ADD_CODE = 'NumpadAdd'
export const NUMPAD_SUBTRACT_CODE = 'NumpadSubtract'

export const LOGOUT_EVENT = 'logout'

export enum KEYBOARD_KEYS {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
}

export enum KEYBOARD_KEY_CODES {
  Backspace = 8,
  Delete = 46,
  E = 69,
  W = 87,
}

export enum MOUSE_BUTTON {
  Left = 0,
  Middle = 1,
  Right = 2,
}

// colors
export const REGULAR_ORANGE = new Color3(0.99, 0.31, 0)
export const PRIMARY_BLUE = new Color3(0, 0.37, 0.72)
export const REGULAR_CYAN = new Color3(0, 0.71, 0.87)
export const PRIMARY_CYAN = new Color3(0, 0.71, 0.87).scale(1.7)
export const SHADER_CYAN = new Color3(0, 0.71, 0.87)
export const SPATIAL_LETTER_GRID_COLOR = Color3.FromHexString('#59CBE8')
export const PRIMARY_SELECTION = new Color3(0, 0.37, 0.72).scale(2)
export const SHADER_SELECTION = new Color3(0, 0.37, 0.72)
export const SECONDARY_INDIGO = new Color3(0.07, 0.16, 0.29)
export const DEFAULT_GREY = new Color3(0.5, 0.5, 0.5)
export const DEFAULT_LIGHT_GREY = new Color3(0.3, 0.3, 0.3)
export const REGULAR_MAGENTA = Color3.FromHexString('#CC33CC')
// new Color3(0.36, 0.34, 0.54).scale(2) - violet, like in CAD
export const DEFAULT_COLOR = new Color3(0.35, 0.35, 0.35).scale(2)
export const SHADER_DEFAULT_COLOR = new Color3(0.35, 0.35, 0.35)
export const SUPPORT_COLOR = Color3.FromHexString('#6699CC').scale(1.2)
export const FAILED_DUPLCIATE_COLOR = new Color3(0.6, 0, 0)
export const FAILED_LABEL_COLOR = Color3.Red()
export const OVERHANG_COLOR = Color3.FromHexString('#DBE2E9')
export const OVERHANG_SELECTION_COLOR = new Color3(1, 0.76, 0.6)
export const ORIENT_PREFERRED_SURFACE_COLOR = OVERHANG_SELECTION_COLOR
export const ORIENT_PROHIBITED_SURFACE_COLOR = Color3.Red()
export const MESH_RENDERING_GROUP_ID = 2
export const REGULAR_GREEN = Color3.FromHexString('#00BF6F')
export const REGULAR_RED = Color3.FromHexString('#FE5000')
export const DARK_GREEN = Color3.FromHexString('#007F2A')
export const DARK_RED = Color3.FromHexString('#BF3C00')
export const PRODUCTION_DEFAULT_COLOR = Color3.FromHexString('#969696').scale(1.2)
export const COUPON_DEFAULT_COLOR = Color3.FromHexString('#EBEBEB').scale(1.1)
export const IMPORTED_SUPPORT_DEFAULT_COLOR = SUPPORT_COLOR
export const HIGHLIGHTING_COLOR = Color3.FromHexString('#15FCFF').scale(1.5)
export const SELECTING_COLOR = Color3.FromHexString('#15D64E').scale(1.5)
export const INACTIVE_LABEL_COLOR = Color3.FromHexString('#00003D')
export const LABEL_COLOR = Color3.FromHexString('#666666')
export const ACTIVE_LABEL_FAILED_COLOR = DARK_RED
export const INACTIVE_LABEL_FAILED_COLOR = REGULAR_RED.scale(1.5)
export const LABEL_ORIGIN_REGULAR_COLOR = Color3.Yellow()
export const LABEL_ORIGIN_ERROR_COLOR = Color3.Red()
export const CLEARANCE_RUBBERBAND_COLOR = Color3.FromHexString('#0AD64F')
export const CLEARANCE_INACTIVE_COLOR = Color3.FromHexString('#13294B')
export const CLEARANCE_ACTIVE_COLOR = Color3.FromHexString('#005EB8')

export const EDGES_COLOR = new Color3(0, 0, 0)
// acos(0.1735) ~ 80 degrees between faces to draw an edge
export const EDGES_EPSILON = 0.1735
export const EDGES_WIDTH = 5.0
export const SUPPORT_EDGES_COLOR = SUPPORT_COLOR.scale(0.3)
// acos(0.342) ~ 70 degrees between faces to draw an edge
export const SUPPORT_EDGES_EPSILON = 0.342
export const SUPPORT_EDGES_WIDTH = 3.0

export const SEMITRANSPARENCY_ALPHA = 0.15

export const AXIS_X_2D = new Vector2(1, 0)

export const FILE_EXPLORER_TABLE_STATE_KEY = 'file-explorer-state'
export const DEFAULT_FILE_EXPLORER_TABLE_STATE: FileExplorerTableState = {
  sort: {
    [SortParamsKey.AllFiles]: null,
    [SortParamsKey.Favorites]: null,
    [SortParamsKey.Recent]: null,
    [SortParamsKey.Shared]: null,
    [SortParamsKey.Trash]: null,
    [SortParamsKey.PrintOrders]: null,
    [SortParamsKey.DashboardJobs]: null,
    [SortParamsKey.Search]: null,
    [SortParamsKey.IBCPlans]: null,
  },
  filters: {
    [FilterParamsKey.AllFiles]: null,
    [FilterParamsKey.Favorites]: null,
    [FilterParamsKey.Recent]: null,
    [FilterParamsKey.Shared]: null,
    [FilterParamsKey.Trash]: null,
    [FilterParamsKey.PrintOrdersStatus]: null,
    [FilterParamsKey.PrintOrdersMaterial]: null,
    [FilterParamsKey.PrintOrdersMachine]: null,
    [FilterParamsKey.PrintOrdersParameter]: null,
    [FilterParamsKey.PrintOrdersBuildPlate]: null,
    [FilterParamsKey.SearchPrintOrdersStatus]: null,
    [FilterParamsKey.SearchPrintOrdersMaterial]: null,
    [FilterParamsKey.SearchPrintOrdersMachine]: null,
    [FilterParamsKey.SearchPrintOrdersParameter]: null,
    [FilterParamsKey.SearchPrintOrdersBuildPlate]: null,
    [FilterParamsKey.Search]: null,
    [FilterParamsKey.JobTypes]: null,
  },
  viewMode: ViewMode.Folders,
}

export const DEFAULT_SINTER_PLATE_BOUNDING_INFO = new BoundingInfo(new Vector3(-200, -200, 0), new Vector3(200, 200, 0))
export const SINTER_PLATE_PADDING = 20
export const SINTER_PLAN_CONSTRAINTS: IConstraints = {
  translation: { x: true, y: true, z: true, variability: false },
  rotation: { x: false, y: false, z: false, variability: false },
}

export const BUILD_PLAN_CONSTRAINTS: IConstraints = {
  translation: { x: false, y: false, z: false, variability: false },
  rotation: { x: false, y: false, z: false, variability: false },
}

export const SINTER_PART_CONSTRAINTS: IConstraints = {
  translation: { x: false, y: false, z: false, variability: false },
  rotation: { x: true, y: true, z: true, variability: false },
}

export const SINTER_PART_ROTATION_INCREMENT = Math.PI

export const PART_SUPPORT_BODY_CONSTRAINTS: IConstraints = {
  translation: { x: false, y: false, z: false, variability: false },
  rotation: { x: true, y: true, z: true, variability: false },
}

export const NO_SPECULAR_MATERIAL_NAME = 'noSpecular'

export enum MouseButtons {
  LeftButton = 0,
  WheelButton = 1,
  RightButton = 2,
}

export enum PointerType {
  MOUSE = 'mouse',
  PEN = 'pen',
  TOUCH = 'touch',
}

export const STENCIL_PLANE_NAME = 'stencilPlane'
export const SIMULATION_DATA_SUFFIX = 'Simulation'
export const NOMINAL_GEOMETRY_SUFFIX = 'Nominal'
export const BUILD_PLAN_METADATA = 'buildPlanJobMetadata'
export const PART_JOB_METADATA = 'partJobMetadataList'

export const DEFAULT_BUILD_PLAN_COST = { min: 0, max: 0 }

export const FACE_NAMES_META_NAME = 'name'
export const FACE_NAMES_META_VALUE = 'sub_obj'
export const SEED_SURFACE = 'Seed_surface'
export const CUTOUT_MATERIAL = 'cutoutMaterial'
export const BRIDGING_MATERIAL = 'bridgingMaterial'
export const BRIDGING_TRANSPARENT = 'bridgingTransparent'
export const NOMINAL_GEOMETRY_MATERIAL = 'nominalGeometryMaterial'
export const SOLID_MATERIAL = 'solidMaterial'
export const DISPLACEMENT_FIELD_NAME = 'Displacement'
export const DISPLACEMENT_MM_FIELD_NAME = 'Displacement (mm)'
export const LOCAL_DISTORTION_FIELD_NAME = 'Local Distortion'
export const RESIDUAL_ERROR_FIELD_NAME = 'Residual Error'
export const HIGHLIGHT_INFO_MATERIAL = 'highlightInfoMaterial'
export const HIGHLIGHT_WARNING_MATERIAL = 'highlightWarningMaterial'
export const HIGHLIGHT_ERROR_MATERIAL = 'highlightErrorMaterial'
export const PART_TYPE = 'PART'
export const SUPPORT_TYPE = 'SUPPORT'
export const PARTS_PVD = 'parts.pvd'
export const SUPPORTS_PVD = 'supports.pvd'

// Simulation / Viz Patterns
export const MECHANICAL_NAME_PATTERN = /^\d+_mechanical/
export const MATERIAL_REMOVAL_NAME_PATTERN = /^\d+_material_removal/
export const STRESS_RELIEF_NAME_PATTERN = /^\d+_stress_relief/
export const SINTER_NAME_PATTERN = /^\d+_sinter/
export const STEP_NAME_PATTERN = /^\d+_mechanical|material_removal|stress_relief|sinter/
export const INVALID_JSON_ENTRIES_PATTERN = /\bNaN\b/g
export const FIELD_MM_PATTERN = /(D|d)isplac|(C|c)omp|(S|s)urface|(R|r)esidual|(L|l)ocal (D|d)ist/
export const TEMPERATURE_FIELD_PATTERN = /(T|t)emperature/
export const STRESS_FIELD_PATTERN = /(S|s)tress/
export const COMPENSATION_MM_FIELD_PATTERN = /(A|a)pplied (C|c)ompensation \(mm\)/
export const DISPLACEMENT_MM_FIELD_PATTERN = /(D|d)isplacement \(mm\)/
export const SURFACE_DEVIATION_MM_FIELD_PATTERN = /(S|s)urface (D|d)eviation \(mm\)/
export const MAXIMUM_STRESS_FIELD_PATTERN = /(M|m)aximum (P|p)rincipal (S|s)tress/
export const MINIMUM_STRESS_FIELD_PATTERN = /(M|m)inimum (P|p)rincipal (S|s)tress/
export const VON_MISES_STRESS_FIELD_PATTERN = /(V|v)on (M|m)ises (S|s)tress/
export const VON_MISES_STRAIN_FIELD_PATTERN = /(V|v)on (M|m)ises (S|s)train/
export const DISPLACEMENT_ITERATION__FIELD_PATTERN = /(D|d)isplacement (C|c)hange from (P|p)revious (I|i)teration/
export const RESIDUAL_ERROR_FIELD_PATTERN = /(R|r)esidual (E|e)rror/
export const SELF_INTERSECTING_NODES_ORIG_PATTERN = /(S|s)elf( |-)(I|i)ntersecting (N|n)odes (O|o)rig/
export const SELF_INTERSECTING_NODES_COMP_PATTERN = /(S|s)elf( |-)(I|i)ntersecting (N|n)odes (C|c)omp/
export const ELASTIC_STRAIN_FIELD_PATTERN = /(E|e)lastic (S|s)train/
export const VON_MISES_PATTERN = /(V|v)on (M|m)ises/
export const MAXIMUN_PRINCIPAL_PATTERN = /(M|m)aximum (P|p)rincipal/
export const MIDDLE_PRINCIPAL_PATTERN = /(M|m)iddle (P|p)rincipal/
export const MINIMUM_PRINCIPAL_PATTERN = /(M|m)inimum (P|p)rincipal/
export const ANALYSIS_SETTINGS = 'analysisSettings'
export const SHRINKAGE_ON = 'shrinkage_on'
export const DIFFERENCE_SIGNED = /difference_along_normal_signed/i
export const MAX_EXPORT_STRING_LENGTH = 100
export const DATA_JSON = 'data.json'

// As we can only report approximate size of data to be downloaded by the user
// we need this ajustment coefficient
export const SIZE_ADJUSTMENT_FACTOR = 0.6

export const SLICE_SLIDER_DEFAULT_HEIGHT = 225
export const SLICE_SLIDER_DEFAULT_MARGIN_BOTTOM = 140
export const SLICE_SLIDER_FLOATING_Z_HEIGHT = 57
export const SLICE_MICRON_OFFSET_MULTIPLIER = 10000

export const DEFAULT_SELF_SUPPORTING_ANGLE = 45

export const PRINT_SITE_DOWNLOAD_DISPLAY_NAME = 'None'
export const PRINT_SITE_DOWNLOAD_DEFAULT_NAME = 'print'

export enum BuildVolumeLimitZoneType {
  Top,
  Side,
}

export const ROOT_PATH_LABEL = 'All Files'
export const MIN_CANVAS_SIZE = 10

export const SINGLE_PART_VISUALIZATION_NAMESPACE = 'singlePartVisualizationModule'

export const NOT_AVAILABLE_LABEL = 'N/A'

export const PART_BODY_ID_DELIMITER = '_'

export const TOOLTIP_OPEN_DELAY = 500

export const GIZMO_ROTATION_TUBE_RADIUS = 33

export const DEFAULT_VALIDATION_MODE = 'aggressive'

export const enum VariantStatus {
  PublishedForPrinting = 'publishedForPrinting',
  EditedBy = 'editedBy',
  RunningSimulation = 'simulationIsRunning',
  RunningSlicing = 'slicingIsRunning',
  RunningArrange = 'arrangeIsRunning',
}

export const DEFAULT_FIRST_VARIANT_VERSION = '1'
export const FILTER_BY_ITEM_TYPE_PARAMETER = 'itemType'
export const SORT_STRATEGY_PARAMETER = 'sortStrategy'
export const SORT_STRATEGY_BY_ITEM_TYPE = 'by_item_type'

export const MAX_PH_INDEXING = 9
export const DEFAULT_Y_SHIFT_SIZE = 0

export const DEFAULT_PAGINATION_LIMIT = 20
export const DEFAULT_IS_ALLOWED_TO_BE_TRASHED_SELECTED_ITEMS: CanBeTrashedResult = {
  relatedItems: [],
  permissionsResult: { status: CanBeTrashedStatus.Success, selectedUnavailableItems: [], relatedUnavailableItems: [] },
  relateToPrintOrdersResult: { status: CanBeTrashedStatus.Success, reasons: [], relatedItems: [] },
}

export const JOB_TYPE_NAME_MAP = {
  IMPORT: 'upload',
  NEST: 'arrangement',
  MESH: 'meshing',
  SIMULATE: 'simulation',
  COMPENSATE: 'compensation',
  SLICE: 'slicing',
  EXPOSURE: 'slicing',
  MARK: 'printOrder',
  PRINT: 'printOrder',
  SHARING: 'sharing',
  PUBLISH: 'publishing',
  ICOMPENSATE: 'compensation',
}

export const JOB_TYPE_NAME_MAP_FOR_DASHBOARD = {
  IMPORT: 'partUploading',
  NEST: 'arranging',
  MESH: 'meshing',
  SIMULATE: 'simulating',
  COMPENSATE: 'simulating',
  SLICE: 'slicing',
  EXPOSURE: 'slicing',
  MARK: 'preparingPrintOrder',
  PRINT: 'preparingPrintOrder',
  PUBLISH: 'publishingSinteredPart',
  ICOMPENSATE: 'compensating',
}

export const NOTIFICATION_TYPE_NAME_MAP = {
  ALL: 'allNotificationTypes',
  IMPORT: 'partUploading',
  NEST: 'arranging',
  SIMULATE: 'simulating/compensating',
  COMPENSATE: 'simulating/compensating',
  SLICE: 'slicing',
  EXPOSURE: 'slicing',
  PRINT: 'printOrder',
  SHARING: 'sharing',
  PUBLISH: 'publishing',
  ICOMPENSATE: 'simulating/compensating',
}

export const PART_PARAMETER_DEFAULT_NAMES = {
  PRODUCTION: 'Print Strategy Default: Production',
  COUPON: 'Print Strategy Default: Coupon',
  SUPPORT: 'Print Strategy Default: Support',
  IMPORTED_SUPPORT: 'Print Strategy Default: Support - Imported Volume',
  IMPORTED_SHEET: 'Print Strategy Default: Support - Imported Sheet',
  AMP_GENERATED_SOLID_TYPE: 'Print Strategy Default: Support - Amp Solid',
  AMP_GENERATED_LINE_TYPE: 'Print Strategy Default: Support - Amp Line',
}

export const PRINT_ORDER_ITEM_ID_PREFIX = 'p'
export const ITEM_ID_PREFIX_DELIMITER = '_'

export const TEST_TENANTS = {
  dev: 'dev',
  qa: 'qa',
  www: 'www',
  geadditive: 'geadditive',
}

// Max PostgreSQL string value is lower than max javascript string value
export const MAX_STRING_LENGTH = 255
export const DEFAULT_DECIMAL_PRECISION = 3
export const ADW_DIR = 'ADW_Output'
export const BIN_DIR = 'bin'
export const MESHING_FILE = 'mesh_elements.vtk'
export const STL = '.stl'
export const VTP = '.vtp'
export const COMPENSATED_MATERIAL = 'compensatedMaterial'
export const GREEN_COMPENSATED_MATERIAL = 'greenCompensatedMaterial'
export const GREEN_NOMINAL_MATERIAL = 'greenNominalMaterial'

export const DUMMY_PRINT_STRATEGY_PARAMETER_SET_ID = 'DUMMY-ID'

export const LIST_OF_DISABLED_COMMAND_VIEW_MODE_TYPES = [ViewModeTypes.Duplicate]

export const DEBOUNCE_TIME = 600

// Duplicate Tool spacing ranges
export const X_SPACING_LEFT_LIMIT_1 = Number(-1000).toFixed(3)
export const X_SPACING_RIGHT_LIMIT_1 = Number(-0.25).toFixed(3)
export const X_SPACING_LEFT_LIMIT_2 = Number(0.25).toFixed(3)
export const X_SPACING_RIGHT_LIMIT_2 = Number(1000).toFixed(3)

export const Y_SPACING_LEFT_LIMIT_1 = Number(-500).toFixed(3)
export const Y_SPACING_RIGHT_LIMIT_1 = Number(-0.25).toFixed(3)
export const Y_SPACING_LEFT_LIMIT_2 = Number(0.25).toFixed(3)
export const Y_SPACING_RIGHT_LIMIT_2 = Number(500).toFixed(3)

export enum AnnouncementType {
  NewAmpVersion = 'newAmpVersion',
}

export const IS_COMPENSATION_FILE_IN_PROGRESS = 'visualizationModule/isCompensationFileInProgress'
export const TOGGLE_COMPENSATION_FILE_PROGRESS = 'visualizationModule/toggleCompensationFileProgress'
export const PART_CONFIG_LOADING_MUTATION_NAME = 'visualizationModule/setPartConfigLoading'
export const REMOVE_PART_BY_ID_MUTATION_NAME = 'parts/removePartById'
export const FETCH_PART_BY_ID_ACTION_NAME = 'parts/fetchPartById'

export const PRINT_JOB_EXCLUDE_STATUS_SET: Set<string> = new Set<string>([
  JobStatusCode.ERROR,
  JobStatusCode.CANCELLED,
  JobStatusCode.CANCELLING,
  JobStatusCode.CREATED,
])

export enum ScalingStatus {
  Scaled = 'scaled',
  Unscaled = 'unscaled',
  CompensatedAndScaled = 'compensatedAndScaled',
  CompensatedAndNotScaled = 'compensatedAndNotScaled',
  CompensatedAndSimulationScaled = 'compensatedAndSimulationScaled',
}

export const DEFAULT_PREVIEW_PATH = {
  buildPlan: require('@/assets/images/default_build_plan_preview.png'),
  sinterPlan: require('@/assets/images/default_sinter_plan_preview.png'),
}

export const BUILD_COST_LIMIT = 100000
export const BUILD_TIME_LIMIT_DAYS = 7

export enum ConstitutiveLawType {
  Thermal = 'thermal',
  ElasticIsotropic = 'elastic_isotropic',
  ElasticOrthotropic = 'elastic_orthotropic',
  ElastoplasticLudwik = 'elastoplastic_ludwik',
  ElastoviscoplasticNorton = 'elastoviscoplastic_norton',
  BinderJetTensileAndCompressiveModulus = 'binderjet_tensile_and_compressive_modulus',
  Elasticity = 'Elasticity',
  Density = 'Density',
  BilinearIsotropicHardening = 'Bilinear isotropic Hardening',
  IsotropicCoefficientOfThermalExpansion = 'Isotropic Coefficient of Thermal Expansion',
  OrthotropicCoefficientOfThermalExpansion = 'Orthotropic Coefficient of Thermal Expansion',
  ZeroThermalStrainReferenceTemperature = 'Zero-Thermal-Strain Reference Temperature',
}

export enum PhysicalPropertyType {
  Density = 'density',
  ThermalConductivity = 'thermal_conductivity',
  SpecificHeatCapacity = 'specific_heat_capacity',
  YoungsModulus = 'youngs_modulus',
  PoissonRatio = 'poisson_ratio',
  YieldStress = 'yield_stress',
  HardeningCoefficientLudwik = 'hardening_coefficient_ludwik',
  HardeningExponentLudwik = 'hardening_exponent_ludwik',
  CoefficientOfThermalExpansion = 'coefficient_of_thermal_expansion',
  NortonLawCoefficient = 'norton_law_coefficient',
  NortonLawExponent = 'norton_law_exponent',
  RelativeDensity = 'relative_density',
  BulkModulus = 'bulk_modulus',
  ShearModulus = 'shear_modulus',
  CoefficientOfThermalExpansionXDirection = 'coefficient_of_thermal_expansion_x_direction',
  CoefficientOfThermalExpansionYDirection = 'coefficient_of_thermal_expansion_y_direction',
  CoefficientOfThermalExpansionZDirection = 'coefficient_of_thermal_expansion_z_direction',
  YieldStrength = 'yield_strength',
  TangentModulus = 'tangent_modulus',
  TensileModulus = 'tensile_modulus',
  ZeroThermalStrainReferenceTemperature = 'zero_thermal_strain_reference_temperature',
  LiquidusTemperature = 'Liquidus Temperature',
  SolidusTemperature = 'Solidus Temperature',
  LatentHeatFusion = 'Latent Heat (Fusion)',
  EnergyAbsorption = 'Energy Absorption',
}

export enum MaterialState {
  Powder = 'powder',
  DMLMAsBuilt = 'dmlm_as_built',
  DMLMStressRelieved = 'dmlm_stress_relieved',
  BinderJetGreen = 'binder_jet_green',
  BinderJetSintered = 'binder_jet_sintered',
  EBMAsBuilt = 'ebm_as_built',
  EBMHipped = 'ebm_hipped',
  Wrought = 'wrought',
  Ceramic = 'Ceramic',
}

export const H2_MACHINE_CONFIG_NAME = 'H2'
export const H3_ALPHA_MACHINE_CONFIG_NAME = 'H3 Alpha'
export const H3_BETA_MACHINE_CONFIG_NAME = 'Binder Jet Line Series 3'

export const LENGTH_MM_DECIMAL_PLACES = 3
export const PERCENTAGE_DECIMAL_PLACES = 3
export const TEMPERATURE_CELSIUS_DEGREE_DECIMAL_PLACES = 1
export const DOSING_SPEED_DECIMAL_PLACES = 3

export const JSON_CONTENT_TYPE = 'application/json'

export const LOCK_BUILD_PLAN_REQUEST_INTERVAL = 240 * 1000 // 4 minutes
export const LOCK_BUILD_PLAN_REQUEST_REPEAT_TIMES = 5
export const DEFAULT_LABEL_SET_SETTING: Setting = {
  allowFontSizeVariation: true,
  fontColor: 216,
  fontMinSize: 3,
  fontName: FontNames.VarelaRound,
  fontStyle: SupportedFontStyles.Regular,
  fontTargetSize: 5,
  placementAngleOffset: 0,
  placementAutoLocations: [MarkingLocation.Top],
  placementMaxCount: 1,
  placementMethodAutomatic: true,
  placementMinCount: 0,
  placementPlanarOnly: false,
  recipeName: '',
  serialNumbers: [],
  textBoolean: BooleanType.Subtract,
  textContent: '',
  textElements: [],
  textExtrusionAboveSurface: 1.5,
  textExtrusionBelowSurface: 1.5,
  textHorizontalAlignment: TextAlign.Center,
  textMarginSize: 0.78,
  textVerticalAlignment: TextAlignVertical.Center,
  uniqueID: null,
  hasLabeledInstances: false,
  labelSetName: '',
  fontSpacing: 1.5,
}

export const DEFAULT_PARTIAL_LABEL_SET_SETTING_WITHOUT_COUPONS: Partial<Setting> = {
  placementAutoLocations: [MarkingLocation.NearestToPoint],
  placementMethodAutomatic: false,
  placementMinCount: 0,
  placementMaxCount: 100,
  textHorizontalAlignment: TextAlign.Center,
  textVerticalAlignment: TextAlignVertical.Center,
  textMarginSize: 0.78,
  fontSpacing: 1.5,
}

export const DEFAULT_ADDITIONAL_INTERACTIVE_LABEL_SET_DATA: Partial<InteractiveLabelSet> = {
  patches: [],
  selectedBodies: [],
  relatedBodies: [],
  labels: [],
  manualPlacements: [],
}

export const MAX_CHORD_HEIGHT = 0.005

export enum HELP_LIBRARY_CONTENT_TYPES {
  Video = 'Video',
  Document = 'Document',
}

export const MAX_CONCURRENT_REQUESTS = 100
export const THRESHOLD_RATIO = 0.02

export enum BodyTypeIcons {
  production = 'mdi-cube',
  support = 'mdi-window-open-variant',
  coupon = 'mdi-image-size-select-small',
  productionAndSupport = 'mdi-rectangle-outline',
  measurement = 'mdi-chart-timeline-variant-shimmer',
}

export const MAX_GEOMETRY_INSIGHT_COUNT_PER_TYPE = 50

export enum PrintStrategyParameterSetErrorCodes {
  NotAvailable = 'PSPS0001',
}

export const DEFAULT_PRINT_STRATEGY_PARAMETER_SET_ID = null
export const DEFAULT_PRINT_STRATEGY_PARAMETER_SET_VERSION = 1

export enum HatchType {
  Solid = 'Solid',
  RotatingGrid = 'Rotating Grid',
}

export const BULK_AREA_SLICE_SETTINGS_BAND_ID = -1

export const GET_LABEL_ADDED_PROMISE = 'label/getLabelAddedPromise'
export const CLEAR_LABEL_PROMISE = 'label/clearLabelPromise'

export const ICOMPENSATE_COMPUTE_TYPE = 'amp'
// 10 seconds is the time that the system waits before the user can
// re-enter a tool that needs a pod started
export const SOCKET_TOOL_ACTIVATION_TIMEOUT = 10 * 1000

export const CLEARANCE_MIN_DISPLAY_DISTANCE = 0.001
export const CLEARANCE_INVISIBLE_WALL_SIZE = 10000
export const CLEARANCE_SCALE_DIVIDER = 200
export const CLEARANCE_LEGACY_PART_WARNING_TIMEOUT = 2000

export const CONTOUR_PIXEL_LEVEL_MIN = 0
export const CONTOUR_PIXEL_LEVEL_MAX = 3
export const DEFAULT_CONTOUR_PIXEL_LEVEL = 0
