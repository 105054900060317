import { DefectShape } from '@/visualization/models/DataModel'
import { InsightsSeverity } from '../Common/Insights'

export interface IPartInsight {
  geometryId: string
  severity: InsightsSeverity
  tag: string
  type: PartDefect
  shapes: DefectShape[]
}

export interface IHighlightableDefectIndices {
  defectIndex: number
  shapeIndex?: number
}

export interface IHighlightableDefect {
  type: number
  indices: IHighlightableDefectIndices[]
}

export interface IHighlightDefectPayload {
  defect: IHighlightableDefect
  showHighlight: boolean
}

export interface ISelectableDefectPayload {
  defect: IHighlightableDefect
  deselectIfSelected: boolean
}

export enum DefectShapeType {
  Point = 'point',
  Line = 'line',
  Loop = 'loop',
  Face = 'face',
  Mesh = 'mesh',
}

export enum PartDefect {
  // Common defects
  DefectsDetectionFailed = -1,
  Other = 0,

  // Polygonal model defects
  LargeModel = 26000,
  Corrupt = 26001,
  NonManifold = 26002,
  MVertexNormal = 26003,
  DegenMFacet = 26004,
  FlatMFacet = 26005,
  SelfInt = 26006,
  Slit = 26007,
  Foldover = 26008,
  Disjoint = 26009,

  // BREP model defects
  Return = 13800,
  BodyInsideOut = 13801,
  BodyCorrupt = 13802,
  TopolNoGeom = 13803,
  FaceSelfInt = 13804,
  GeomSelfInt = 13805,
  GeomDegenerate = 13806,
  InconsistentGeomTopol = 13807,
  InvalidGeometry = 13808,
  SizeSettings = 13809,
  TopolSizeBox = 13810,
  TopolCheckFail = 13812,
  WithdrawnGeometry = 13813,
  ConsistencyMending = 13814,
  BodyInsideOut2 = 13815,
  BadFaceFace = 13816,
  EdgeOpen = 13817,
  EdgeBadVertex = 13818,
  EdgeReversed = 13819,
  EdgeBadSPCurve = 13820,
  EdgeVerticesTouch = 13821,
  FaceBadVertex = 13822,
  FaceBadEdge = 13823,
  FaceBadFaceOrder = 13824,
  FaceNoVrxAtSing = 13825,
  FaceBadLoops = 13826,
  TopolNotG1 = 13827,
  RegionBadShells = 13828,
  FaceCheckFail = 13829,
  FaceBadWireFaEd = 13830,
  EdgeBadWireEdEd = 13831,
  EdgeBadFaceOrder = 13832,
  ShellBadTopolGeom = 13833,
  ShellBadShSh = 13834,
  BodyBadRegions = 13835,
  BodyInvalidIdent = 13836,
  EdgeOpenNmnl = 13837,
  EdgeBadVertexNmnl = 13838,
  EdgeBadSPNmnl = 13839,
  EdgeReversedNmnl = 13840,
  AttdefBadName = 13841,
  AttribBadString = 13842,
  AttribInvalidAtt = 13843,
  AttribBadDataLen = 13844,
  AttribBadUstring = 13845,
  AttribByteOor = 13846,
  AttribEmptyField = 13847,
  AttribIntOor = 13848,
  AttribNonUnitVec = 13849,
  AttribRealOor = 13850,
  AttribShortOor = 13851,
  AttribPVectorOor = 13852,
  SessionCorrupt = 22890,
  BBCorrupt = 22891,
  KIList = 22892,
  GeomCorrupt = 22893,
  TransfCorrupt = 22894,
  AssemblyCorrupt = 22895,
  PartitionCorrupt = 22896,
  PartitionXref = 22897,
  ItemUnattached = 22898,
  BcurveCloseKnots = 22899,
  BsurfCloseUKnots = 22900,
  BsurfCloseVKnots = 22901,
  EdgeTouchEdge = 22902,
  TopolBadClosed = 22903,
  TopolUParmDegen = 22904,
  TopolVParmDegen = 22905,
  TopolParmDegen = 22906,
  TopolUPhysDegen = 22907,
  TopolVPhysDegen = 22908,
  TopolPhysDegen = 22909,
  AssemblyBadType = 24620,
  AssemblyCyclicRef = 24621,
  BodyBadType = 24622,
  BodyNoRegion = 24623,
  BodyNoShell = 24624,
  BodyNoSolidReg = 24625,
  BodyRegion1Solid = 24626,
  BodyShellNotFirst = 24627,
  BodyWrongExtReg = 24628,
  BodyWrongNumRegs = 24629,
  EdgeBadFins = 24630,
  EdgeBadTol = 24631,
  EdgeFinBadRing = 24632,
  EdgeFinEdNextPos = 24633,
  EdgeFinEdNotEd = 24634,
  EdgeFinNotPos = 24635,
  EdgeSingleVertex = 24636,
  EdgeWireCorrupt = 24637,
  EntityBadOwner = 24639,
  EntityHasAttGroup = 24640,
  EntityShared = 24641,
  FaceDiffShSameRg = 24642,
  FaceEdgeMoebius = 24643,
  FaceWrongSense = 24646,
  FinBad = 24647,
  FinBadEdFins = 24648,
  FinCorrupt = 24649,
  FinEdNextIsFin = 24650,
  FinEdgeDiffVertex = 24651,
  FinNoEdNextInSh = 24652,
  FinNonZeroIdent = 24653,
  FinNotEdNextPrev = 24654,
  FinNotLpNextPrev = 24655,
  FinVxNotVx = 24656,
  FinWrongVertex = 24657,
  GeomBadDepType = 24658,
  GeomBadGeomOwner = 24659,
  GeomBadOwner = 24660,
  GeomBadTopolOwner = 24661,
  GeomDupGeomOwner = 24662,
  GeomNotOwnersDep = 24663,
  GeomNotOwningDep = 24664,
  GroupBad = 24665,
  InstanceBadTransf = 24666,
  InstanceBadType = 24667,
  InstanceCorrupt = 24668,
  LoopIsolatedHasCu = 24669,
  PartBadAttrib = 24670,
  PartBadAttribList = 24671,
  PartBadState = 24672,
  PartCorrupt = 24673,
  PartHasKey = 24674,
  PartNoKey = 24675,
  RegionNoShell = 24676,
  RegionWronglySolid = 24677,
  ShellBadBody = 24678,
  ShellBadFaOrient = 24679,
  ShellBadWfAcorn = 24680,
  ShellBadWf = 24681,
  ShellClosedBadFa = 24682,
  ShellEdFaInAcorn = 24683,
  ShellEdsFragmented = 24684,
  ShellFasFragmented = 24685,
  ShellNoVxEdFa = 24686,
  ShellNotOwningVx = 24687,
  ShellOpenBadFa = 24688,
  ShellRegMeetAtEd = 24689,
  ShellRegMeetAtVx = 24690,
  ShellSheetNoFa = 24691,
  ShellTooManyFas = 24692,
  TopolBadBox = 24693,
  TopolBadGeomShare = 24694,
  TopolBadOwnerType = 24695,
  TopolShareNoGeom = 24696,
  VertexBadFin = 24697,
  VertexBadIsolated = 24698,
  VertexBadTol = 24699,
  VertexFinChains = 24700,
  VertexNonManifold = 24701,
  VertexNotDepOfSh = 24702,
  VertexOwnerNotSh = 24703,
  VertexSheetCorrupt = 24704,
  VertexTooManyEds = 24705,
  VertexWfEdBadSh = 24706,
  EntityInGroup = 24713,
  SpcurveBadLinear = 25760,
  BcurveVxPeriodic = 25761,
  BcurveKnotPeriodic = 25762,
  BcurveKnotBadMult = 25764,
  BcurveKnotVxCount = 25765,
  BcurveKnotCount = 25766,
  BcurveBadKnotSeq = 25767,
  BcurveBadDimen = 25768,
  BlendsfBadSpine = 25769,
  BsurfVxPeriodic = 25770,
  BsurfKnotVxCount = 25771,
  BsurfUknotPeriodic = 25772,
  BsurfVknotPeriodic = 25773,
  BsurfUknotBadMult = 25774,
  BsurfVknotBadMult = 25775,
  BsurfUknotCount = 25776,
  BsurfVknotCount = 25777,
  BsurfBadUknotSeq = 25778,
  BsurfBadVknotSeq = 25779,
  BsurfBadDimen = 25780,
  CircleBadRadius = 25781,
  GeomOutsideBox = 25782,
  LineBadUnitVec = 25783,
  OffsetBadDist = 25784,
  OffsetUnderSense = 25785,
  OffsetUnderNonG1 = 25786,
  SpcurveSurfSpan = 25787,
  SpcurveNonG1XU = 25788,
  SpcurveNonG1XV = 25789,
  SpcurveNonC1XU = 25790,
  SpcurveNonC1XV = 25791,
  SpcurveNonG1 = 25792,
  SpcurveBadDimen = 25793,
  SpunProfileXAxis = 25794,
  SweptBadMinRad = 25795,
  TrcurveEndsOpen = 25796,
  TrcurveEndsOrder = 25797,
  TrcurveEndsMatch = 25798,
  BsurfUknotVxCount = 25799,
  BsurfVknotVxCount = 25800,
  BsurfBadVxUperiod = 25801,
  BsurfBadVxVperiod = 25802,
  MeshBadMvxNormal = 25930,
  MeshCorrupt = 25931,
  MeshDegenMfacet = 25932,
  MeshFlatMfacet = 25933,
  MeshNonManifold = 25934,
  MeshSelfInt = 25935,
  MeshSlit = 25936,
  FaceDisjointMesh = 25937,
  MeshNotLoaded = 25938,
  MeshNotCreated = 25939,
  FaceBadPolyline = 26570,
}

export const messageMapByInsight = {
  [PartDefect.DefectsDetectionFailed]: 'insightsLabels.partInsights.byInsight.defectsDetectionFailed',
  [PartDefect.LargeModel]: 'insightsLabels.partInsights.byInsight.largeModel',
  [PartDefect.DegenMFacet]: 'insightsLabels.partInsights.byInsight.degenerateFacet',
  [PartDefect.Disjoint]: 'insightsLabels.partInsights.byInsight.disjointComponents',
  [PartDefect.FlatMFacet]: 'insightsLabels.partInsights.byInsight.flatFacet',
  [PartDefect.Foldover]: 'insightsLabels.partInsights.byInsight.folds',
  [PartDefect.MVertexNormal]: 'insightsLabels.partInsights.byInsight.incorrectNormals',
  [PartDefect.NonManifold]: 'insightsLabels.partInsights.byInsight.nonManifold',
  [PartDefect.SelfInt]: 'insightsLabels.partInsights.byInsight.selfIntersection',
  [PartDefect.Slit]: 'insightsLabels.partInsights.byInsight.slit',
  [PartDefect.Other]: 'insightsLabels.partInsights.byInsight.other',
  [PartDefect.Return]: 'insightsLabels.partInsights.byInsight.return',
  [PartDefect.BodyInsideOut]: 'insightsLabels.partInsights.byInsight.bodyInsideOut',
  [PartDefect.BodyCorrupt]: 'insightsLabels.partInsights.byInsight.bodyCorrupt',
  [PartDefect.TopolNoGeom]: 'insightsLabels.partInsights.byInsight.topolNoGeom',
  [PartDefect.FaceSelfInt]: 'insightsLabels.partInsights.byInsight.faceSelfInt',
  [PartDefect.GeomSelfInt]: 'insightsLabels.partInsights.byInsight.geomSelfInt',
  [PartDefect.GeomDegenerate]: 'insightsLabels.partInsights.byInsight.geomDegenerate',
  [PartDefect.InconsistentGeomTopol]: 'insightsLabels.partInsights.byInsight.inconsistentGeomTopol',
  [PartDefect.InvalidGeometry]: 'insightsLabels.partInsights.byInsight.invalidGeometry',
  [PartDefect.SizeSettings]: 'insightsLabels.partInsights.byInsight.sizeSettings',
  [PartDefect.TopolSizeBox]: 'insightsLabels.partInsights.byInsight.topolSizeBox',
  [PartDefect.TopolCheckFail]: 'insightsLabels.partInsights.byInsight.topolCheckFail',
  [PartDefect.WithdrawnGeometry]: 'insightsLabels.partInsights.byInsight.withdrawnGeometry',
  [PartDefect.ConsistencyMending]: 'insightsLabels.partInsights.byInsight.consistencyMending',
  [PartDefect.BodyInsideOut2]: 'insightsLabels.partInsights.byInsight.bodyInsideOut2',
  [PartDefect.BadFaceFace]: 'insightsLabels.partInsights.byInsight.badFaceFace',
  [PartDefect.EdgeOpen]: 'insightsLabels.partInsights.byInsight.edgeOpen',
  [PartDefect.EdgeBadVertex]: 'insightsLabels.partInsights.byInsight.edgeBadVertex',
  [PartDefect.EdgeReversed]: 'insightsLabels.partInsights.byInsight.edgeReversed',
  [PartDefect.EdgeBadSPCurve]: 'insightsLabels.partInsights.byInsight.edgeBadSPCurve',
  [PartDefect.EdgeVerticesTouch]: 'insightsLabels.partInsights.byInsight.edgeVerticesTouch',
  [PartDefect.FaceBadVertex]: 'insightsLabels.partInsights.byInsight.faceBadVertex',
  [PartDefect.FaceBadEdge]: 'insightsLabels.partInsights.byInsight.faceBadEdge',
  [PartDefect.FaceBadFaceOrder]: 'insightsLabels.partInsights.byInsight.faceBadFaceOrder',
  [PartDefect.FaceNoVrxAtSing]: 'insightsLabels.partInsights.byInsight.faceNoVrxAtSing',
  [PartDefect.FaceBadLoops]: 'insightsLabels.partInsights.byInsight.faceBadLoops',
  [PartDefect.TopolNotG1]: 'insightsLabels.partInsights.byInsight.topolNotG1',
  [PartDefect.RegionBadShells]: 'insightsLabels.partInsights.byInsight.regionBadShells',
  [PartDefect.FaceCheckFail]: 'insightsLabels.partInsights.byInsight.faceCheckFail',
  [PartDefect.FaceBadWireFaEd]: 'insightsLabels.partInsights.byInsight.faceBadWireFaEd',
  [PartDefect.EdgeBadWireEdEd]: 'insightsLabels.partInsights.byInsight.edgeBadWireEdEd',
  [PartDefect.EdgeBadFaceOrder]: 'insightsLabels.partInsights.byInsight.edgeBadFaceOrder',
  [PartDefect.ShellBadTopolGeom]: 'insightsLabels.partInsights.byInsight.shellBadTopolGeom',
  [PartDefect.ShellBadShSh]: 'insightsLabels.partInsights.byInsight.shellBadShSh',
  [PartDefect.BodyBadRegions]: 'insightsLabels.partInsights.byInsight.bodyBadRegions',
  [PartDefect.BodyInvalidIdent]: 'insightsLabels.partInsights.byInsight.bodyInvalidIdent',
  [PartDefect.EdgeOpenNmnl]: 'insightsLabels.partInsights.byInsight.edgeOpenNmnl',
  [PartDefect.EdgeBadVertexNmnl]: 'insightsLabels.partInsights.byInsight.edgeBadVertexNmnl',
  [PartDefect.EdgeBadSPNmnl]: 'insightsLabels.partInsights.byInsight.edgeBadSPNmnl',
  [PartDefect.EdgeReversedNmnl]: 'insightsLabels.partInsights.byInsight.edgeReversedNmnl',
  [PartDefect.AttdefBadName]: 'insightsLabels.partInsights.byInsight.attdefBadName',
  [PartDefect.AttribBadString]: 'insightsLabels.partInsights.byInsight.attribBadString',
  [PartDefect.AttribInvalidAtt]: 'insightsLabels.partInsights.byInsight.attribInvalidAtt',
  [PartDefect.AttribBadDataLen]: 'insightsLabels.partInsights.byInsight.attribBadDataLen',
  [PartDefect.AttribBadUstring]: 'insightsLabels.partInsights.byInsight.attribBadUstring',
  [PartDefect.AttribByteOor]: 'insightsLabels.partInsights.byInsight.attribByteOor',
  [PartDefect.AttribEmptyField]: 'insightsLabels.partInsights.byInsight.attribEmptyField',
  [PartDefect.AttribIntOor]: 'insightsLabels.partInsights.byInsight.attribIntOor',
  [PartDefect.AttribNonUnitVec]: 'insightsLabels.partInsights.byInsight.attribNonUnitVec',
  [PartDefect.AttribRealOor]: 'insightsLabels.partInsights.byInsight.attribRealOor',
  [PartDefect.AttribShortOor]: 'insightsLabels.partInsights.byInsight.attribShortOor',
  [PartDefect.AttribPVectorOor]: 'insightsLabels.partInsights.byInsight.attribPVectorOor',
  [PartDefect.SessionCorrupt]: 'insightsLabels.partInsights.byInsight.sessionCorrupt',
  [PartDefect.BBCorrupt]: 'insightsLabels.partInsights.byInsight.bBCorrupt',
  [PartDefect.KIList]: 'insightsLabels.partInsights.byInsight.kIList',
  [PartDefect.GeomCorrupt]: 'insightsLabels.partInsights.byInsight.geomCorrupt',
  [PartDefect.TransfCorrupt]: 'insightsLabels.partInsights.byInsight.transfCorrupt',
  [PartDefect.AssemblyCorrupt]: 'insightsLabels.partInsights.byInsight.assemblyCorrupt',
  [PartDefect.PartitionCorrupt]: 'insightsLabels.partInsights.byInsight.partitionCorrupt',
  [PartDefect.PartitionXref]: 'insightsLabels.partInsights.byInsight.partitionXref',
  [PartDefect.ItemUnattached]: 'insightsLabels.partInsights.byInsight.itemUnattached',
  [PartDefect.BcurveCloseKnots]: 'insightsLabels.partInsights.byInsight.bcurveCloseKnots',
  [PartDefect.BsurfCloseUKnots]: 'insightsLabels.partInsights.byInsight.bsurfCloseUKnots',
  [PartDefect.BsurfCloseVKnots]: 'insightsLabels.partInsights.byInsight.bsurfCloseVKnots',
  [PartDefect.EdgeTouchEdge]: 'insightsLabels.partInsights.byInsight.edgeTouchEdge',
  [PartDefect.TopolBadClosed]: 'insightsLabels.partInsights.byInsight.topolBadClosed',
  [PartDefect.TopolUParmDegen]: 'insightsLabels.partInsights.byInsight.topolUParmDegen',
  [PartDefect.TopolVParmDegen]: 'insightsLabels.partInsights.byInsight.topolVParmDegen',
  [PartDefect.TopolParmDegen]: 'insightsLabels.partInsights.byInsight.topolParmDegen',
  [PartDefect.TopolUPhysDegen]: 'insightsLabels.partInsights.byInsight.topolUPhysDegen',
  [PartDefect.TopolVPhysDegen]: 'insightsLabels.partInsights.byInsight.topolVPhysDegen',
  [PartDefect.TopolPhysDegen]: 'insightsLabels.partInsights.byInsight.topolPhysDegen',
  [PartDefect.AssemblyBadType]: 'insightsLabels.partInsights.byInsight.assemblyBadType',
  [PartDefect.AssemblyCyclicRef]: 'insightsLabels.partInsights.byInsight.assemblyCyclicRef',
  [PartDefect.BodyBadType]: 'insightsLabels.partInsights.byInsight.bodyBadType',
  [PartDefect.BodyNoRegion]: 'insightsLabels.partInsights.byInsight.bodyNoRegion',
  [PartDefect.BodyNoShell]: 'insightsLabels.partInsights.byInsight.bodyNoShell',
  [PartDefect.BodyNoSolidReg]: 'insightsLabels.partInsights.byInsight.bodyNoSolidReg',
  [PartDefect.BodyRegion1Solid]: 'insightsLabels.partInsights.byInsight.bodyRegion1Solid',
  [PartDefect.BodyShellNotFirst]: 'insightsLabels.partInsights.byInsight.bodyShellNotFirst',
  [PartDefect.BodyWrongExtReg]: 'insightsLabels.partInsights.byInsight.bodyWrongExtReg',
  [PartDefect.BodyWrongNumRegs]: 'insightsLabels.partInsights.byInsight.bodyWrongNumRegs',
  [PartDefect.EdgeBadFins]: 'insightsLabels.partInsights.byInsight.edgeBadFins',
  [PartDefect.EdgeBadTol]: 'insightsLabels.partInsights.byInsight.edgeBadTol',
  [PartDefect.EdgeFinBadRing]: 'insightsLabels.partInsights.byInsight.edgeFinBadRing',
  [PartDefect.EdgeFinEdNextPos]: 'insightsLabels.partInsights.byInsight.edgeFinEdNextPos',
  [PartDefect.EdgeFinEdNotEd]: 'insightsLabels.partInsights.byInsight.edgeFinEdNotEd',
  [PartDefect.EdgeFinNotPos]: 'insightsLabels.partInsights.byInsight.edgeFinNotPos',
  [PartDefect.EdgeSingleVertex]: 'insightsLabels.partInsights.byInsight.edgeSingleVertex',
  [PartDefect.EdgeWireCorrupt]: 'insightsLabels.partInsights.byInsight.edgeWireCorrupt',
  [PartDefect.EntityBadOwner]: 'insightsLabels.partInsights.byInsight.entityBadOwner',
  [PartDefect.EntityHasAttGroup]: 'insightsLabels.partInsights.byInsight.entityHasAttGroup',
  [PartDefect.EntityShared]: 'insightsLabels.partInsights.byInsight.entityShared',
  [PartDefect.FaceDiffShSameRg]: 'insightsLabels.partInsights.byInsight.faceDiffShSameRg',
  [PartDefect.FaceEdgeMoebius]: 'insightsLabels.partInsights.byInsight.faceEdgeMoebius',
  [PartDefect.FaceWrongSense]: 'insightsLabels.partInsights.byInsight.faceWrongSense',
  [PartDefect.FinBad]: 'insightsLabels.partInsights.byInsight.finBad',
  [PartDefect.FinBadEdFins]: 'insightsLabels.partInsights.byInsight.finBadEdFins',
  [PartDefect.FinCorrupt]: 'insightsLabels.partInsights.byInsight.finCorrupt',
  [PartDefect.FinEdNextIsFin]: 'insightsLabels.partInsights.byInsight.finEdNextIsFin',
  [PartDefect.FinEdgeDiffVertex]: 'insightsLabels.partInsights.byInsight.finEdgeDiffVertex',
  [PartDefect.FinNoEdNextInSh]: 'insightsLabels.partInsights.byInsight.finNoEdNextInSh',
  [PartDefect.FinNonZeroIdent]: 'insightsLabels.partInsights.byInsight.finNonZeroIdent',
  [PartDefect.FinNotEdNextPrev]: 'insightsLabels.partInsights.byInsight.finNotEdNextPrev',
  [PartDefect.FinNotLpNextPrev]: 'insightsLabels.partInsights.byInsight.finNotLpNextPrev',
  [PartDefect.FinVxNotVx]: 'insightsLabels.partInsights.byInsight.finVxNotVx',
  [PartDefect.FinWrongVertex]: 'insightsLabels.partInsights.byInsight.finWrongVertex',
  [PartDefect.GeomBadDepType]: 'insightsLabels.partInsights.byInsight.geomBadDepType',
  [PartDefect.GeomBadGeomOwner]: 'insightsLabels.partInsights.byInsight.geomBadGeomOwner',
  [PartDefect.GeomBadOwner]: 'insightsLabels.partInsights.byInsight.geomBadOwner',
  [PartDefect.GeomBadTopolOwner]: 'insightsLabels.partInsights.byInsight.geomBadTopolOwner',
  [PartDefect.GeomDupGeomOwner]: 'insightsLabels.partInsights.byInsight.geomDupGeomOwner',
  [PartDefect.GeomNotOwnersDep]: 'insightsLabels.partInsights.byInsight.geomNotOwnersDep',
  [PartDefect.GeomNotOwningDep]: 'insightsLabels.partInsights.byInsight.geomNotOwningDep',
  [PartDefect.GroupBad]: 'insightsLabels.partInsights.byInsight.groupBad',
  [PartDefect.InstanceBadTransf]: 'insightsLabels.partInsights.byInsight.instanceBadTransf',
  [PartDefect.InstanceBadType]: 'insightsLabels.partInsights.byInsight.instanceBadType',
  [PartDefect.InstanceCorrupt]: 'insightsLabels.partInsights.byInsight.instanceCorrupt',
  [PartDefect.LoopIsolatedHasCu]: 'insightsLabels.partInsights.byInsight.loopIsolatedHasCu',
  [PartDefect.PartBadAttrib]: 'insightsLabels.partInsights.byInsight.partBadAttrib',
  [PartDefect.PartBadAttribList]: 'insightsLabels.partInsights.byInsight.partBadAttribList',
  [PartDefect.PartBadState]: 'insightsLabels.partInsights.byInsight.partBadState',
  [PartDefect.PartCorrupt]: 'insightsLabels.partInsights.byInsight.partCorrupt',
  [PartDefect.PartHasKey]: 'insightsLabels.partInsights.byInsight.partHasKey',
  [PartDefect.PartNoKey]: 'insightsLabels.partInsights.byInsight.partNoKey',
  [PartDefect.RegionNoShell]: 'insightsLabels.partInsights.byInsight.regionNoShell',
  [PartDefect.RegionWronglySolid]: 'insightsLabels.partInsights.byInsight.regionWronglySolid',
  [PartDefect.ShellBadBody]: 'insightsLabels.partInsights.byInsight.shellBadBody',
  [PartDefect.ShellBadFaOrient]: 'insightsLabels.partInsights.byInsight.shellBadFaOrient',
  [PartDefect.ShellBadWfAcorn]: 'insightsLabels.partInsights.byInsight.shellBadWfAcorn',
  [PartDefect.ShellBadWf]: 'insightsLabels.partInsights.byInsight.shellBadWf',
  [PartDefect.ShellClosedBadFa]: 'insightsLabels.partInsights.byInsight.shellClosedBadFa',
  [PartDefect.ShellEdFaInAcorn]: 'insightsLabels.partInsights.byInsight.shellEdFaInAcorn',
  [PartDefect.ShellEdsFragmented]: 'insightsLabels.partInsights.byInsight.shellEdsFragmented',
  [PartDefect.ShellFasFragmented]: 'insightsLabels.partInsights.byInsight.shellFasFragmented',
  [PartDefect.ShellNoVxEdFa]: 'insightsLabels.partInsights.byInsight.shellNoVxEdFa',
  [PartDefect.ShellNotOwningVx]: 'insightsLabels.partInsights.byInsight.shellNotOwningVx',
  [PartDefect.ShellOpenBadFa]: 'insightsLabels.partInsights.byInsight.shellOpenBadFa',
  [PartDefect.ShellRegMeetAtEd]: 'insightsLabels.partInsights.byInsight.shellRegMeetAtEd',
  [PartDefect.ShellRegMeetAtVx]: 'insightsLabels.partInsights.byInsight.shellRegMeetAtVx',
  [PartDefect.ShellSheetNoFa]: 'insightsLabels.partInsights.byInsight.shellSheetNoFa',
  [PartDefect.ShellTooManyFas]: 'insightsLabels.partInsights.byInsight.shellTooManyFas',
  [PartDefect.TopolBadBox]: 'insightsLabels.partInsights.byInsight.topolBadBox',
  [PartDefect.TopolBadGeomShare]: 'insightsLabels.partInsights.byInsight.topolBadGeomShare',
  [PartDefect.TopolBadOwnerType]: 'insightsLabels.partInsights.byInsight.topolBadOwnerType',
  [PartDefect.TopolShareNoGeom]: 'insightsLabels.partInsights.byInsight.topolShareNoGeom',
  [PartDefect.VertexBadFin]: 'insightsLabels.partInsights.byInsight.vertexBadFin',
  [PartDefect.VertexBadIsolated]: 'insightsLabels.partInsights.byInsight.vertexBadIsolated',
  [PartDefect.VertexBadTol]: 'insightsLabels.partInsights.byInsight.vertexBadTol',
  [PartDefect.VertexFinChains]: 'insightsLabels.partInsights.byInsight.vertexFinChains',
  [PartDefect.VertexNonManifold]: 'insightsLabels.partInsights.byInsight.vertexNonManifold',
  [PartDefect.VertexNotDepOfSh]: 'insightsLabels.partInsights.byInsight.vertexNotDepOfSh',
  [PartDefect.VertexOwnerNotSh]: 'insightsLabels.partInsights.byInsight.vertexOwnerNotSh',
  [PartDefect.VertexSheetCorrupt]: 'insightsLabels.partInsights.byInsight.vertexSheetCorrupt',
  [PartDefect.VertexTooManyEds]: 'insightsLabels.partInsights.byInsight.vertexTooManyEds',
  [PartDefect.VertexWfEdBadSh]: 'insightsLabels.partInsights.byInsight.vertexWfEdBadSh',
  [PartDefect.EntityInGroup]: 'insightsLabels.partInsights.byInsight.entityInGroup',
  [PartDefect.SpcurveBadLinear]: 'insightsLabels.partInsights.byInsight.spcurveBadLinear',
  [PartDefect.BcurveVxPeriodic]: 'insightsLabels.partInsights.byInsight.bcurveVxPeriodic',
  [PartDefect.BcurveKnotPeriodic]: 'insightsLabels.partInsights.byInsight.bcurveKnotPeriodic',
  [PartDefect.BcurveKnotBadMult]: 'insightsLabels.partInsights.byInsight.bcurveKnotBadMult',
  [PartDefect.BcurveKnotVxCount]: 'insightsLabels.partInsights.byInsight.bcurveKnotVxCount',
  [PartDefect.BcurveKnotCount]: 'insightsLabels.partInsights.byInsight.bcurveKnotCount',
  [PartDefect.BcurveBadKnotSeq]: 'insightsLabels.partInsights.byInsight.bcurveBadKnotSeq',
  [PartDefect.BcurveBadDimen]: 'insightsLabels.partInsights.byInsight.bcurveBadDimen',
  [PartDefect.BlendsfBadSpine]: 'insightsLabels.partInsights.byInsight.blendsfBadSpine',
  [PartDefect.BsurfVxPeriodic]: 'insightsLabels.partInsights.byInsight.bsurfVxPeriodic',
  [PartDefect.BsurfKnotVxCount]: 'insightsLabels.partInsights.byInsight.bsurfKnotVxCount',
  [PartDefect.BsurfUknotPeriodic]: 'insightsLabels.partInsights.byInsight.bsurfUknotPeriodic',
  [PartDefect.BsurfVknotPeriodic]: 'insightsLabels.partInsights.byInsight.bsurfVknotPeriodic',
  [PartDefect.BsurfUknotBadMult]: 'insightsLabels.partInsights.byInsight.bsurfUknotBadMult',
  [PartDefect.BsurfVknotBadMult]: 'insightsLabels.partInsights.byInsight.bsurfVknotBadMult',
  [PartDefect.BsurfUknotCount]: 'insightsLabels.partInsights.byInsight.bsurfUknotCount',
  [PartDefect.BsurfVknotCount]: 'insightsLabels.partInsights.byInsight.bsurfVknotCount',
  [PartDefect.BsurfBadUknotSeq]: 'insightsLabels.partInsights.byInsight.bsurfBadUknotSeq',
  [PartDefect.BsurfBadVknotSeq]: 'insightsLabels.partInsights.byInsight.bsurfBadVknotSeq',
  [PartDefect.BsurfBadDimen]: 'insightsLabels.partInsights.byInsight.bsurfBadDimen',
  [PartDefect.CircleBadRadius]: 'insightsLabels.partInsights.byInsight.circleBadRadius',
  [PartDefect.GeomOutsideBox]: 'insightsLabels.partInsights.byInsight.geomOutsideBox',
  [PartDefect.LineBadUnitVec]: 'insightsLabels.partInsights.byInsight.lineBadUnitVec',
  [PartDefect.OffsetBadDist]: 'insightsLabels.partInsights.byInsight.offsetBadDist',
  [PartDefect.OffsetUnderSense]: 'insightsLabels.partInsights.byInsight.offsetUnderSense',
  [PartDefect.OffsetUnderNonG1]: 'insightsLabels.partInsights.byInsight.offsetUnderNonG1',
  [PartDefect.SpcurveSurfSpan]: 'insightsLabels.partInsights.byInsight.spcurveSurfSpan',
  [PartDefect.SpcurveNonG1XU]: 'insightsLabels.partInsights.byInsight.spcurveNonG1XU',
  [PartDefect.SpcurveNonG1XV]: 'insightsLabels.partInsights.byInsight.spcurveNonG1XV',
  [PartDefect.SpcurveNonC1XU]: 'insightsLabels.partInsights.byInsight.spcurveNonC1XU',
  [PartDefect.SpcurveNonC1XV]: 'insightsLabels.partInsights.byInsight.spcurveNonC1XV',
  [PartDefect.SpcurveNonG1]: 'insightsLabels.partInsights.byInsight.spcurveNonG1',
  [PartDefect.SpcurveBadDimen]: 'insightsLabels.partInsights.byInsight.spcurveBadDimen',
  [PartDefect.SpunProfileXAxis]: 'insightsLabels.partInsights.byInsight.spunProfileXAxis',
  [PartDefect.SweptBadMinRad]: 'insightsLabels.partInsights.byInsight.sweptBadMinRad',
  [PartDefect.TrcurveEndsOpen]: 'insightsLabels.partInsights.byInsight.trcurveEndsOpen',
  [PartDefect.TrcurveEndsOrder]: 'insightsLabels.partInsights.byInsight.trcurveEndsOrder',
  [PartDefect.TrcurveEndsMatch]: 'insightsLabels.partInsights.byInsight.trcurveEndsMatch',
  [PartDefect.BsurfUknotVxCount]: 'insightsLabels.partInsights.byInsight.bsurfUknotVxCount',
  [PartDefect.BsurfVknotVxCount]: 'insightsLabels.partInsights.byInsight.bsurfVknotVxCount',
  [PartDefect.BsurfBadVxUperiod]: 'insightsLabels.partInsights.byInsight.bsurfBadVxUperiod',
  [PartDefect.BsurfBadVxVperiod]: 'insightsLabels.partInsights.byInsight.bsurfBadVxVperiod',
  [PartDefect.MeshBadMvxNormal]: 'insightsLabels.partInsights.byInsight.meshBadMvxNormal',
  [PartDefect.MeshCorrupt]: 'insightsLabels.partInsights.byInsight.meshCorrupt',
  [PartDefect.MeshDegenMfacet]: 'insightsLabels.partInsights.byInsight.meshDegenMfacet',
  [PartDefect.MeshFlatMfacet]: 'insightsLabels.partInsights.byInsight.meshFlatMfacet',
  [PartDefect.MeshNonManifold]: 'insightsLabels.partInsights.byInsight.meshNonManifold',
  [PartDefect.MeshSelfInt]: 'insightsLabels.partInsights.byInsight.meshSelfInt',
  [PartDefect.MeshSlit]: 'insightsLabels.partInsights.byInsight.meshSlit',
  [PartDefect.FaceDisjointMesh]: 'insightsLabels.partInsights.byInsight.faceDisjointMesh',
  [PartDefect.MeshNotLoaded]: 'insightsLabels.partInsights.byInsight.meshNotLoaded',
  [PartDefect.MeshNotCreated]: 'insightsLabels.partInsights.byInsight.meshNotCreated',
  [PartDefect.FaceBadPolyline]: 'insightsLabels.partInsights.byInsight.faceBadPolyline',
}
