import _ from 'lodash'
import { IBuildPlanInsight } from '@/types/BuildPlans/IBuildPlanInsight'
import { ToolNames } from '@/components/layout/buildPlans/BuildPlanSidebarTools'

export class InsightsHelper {
  private readonly inStore: IBuildPlanInsight[]
  private readonly pending: IBuildPlanInsight[]
  private readonly tool: ToolNames
  private removeIsMissing: ToolNames[] = [
    ToolNames.LAYOUT,
    ToolNames.PART_PROPERTIES,
    ToolNames.LABEL,
    ToolNames.SUPPORT,
    ToolNames.STABILITY,
  ]

  constructor(inStore, pending, tool) {
    this.inStore = inStore
    this.pending = pending
    this.tool = tool
  }

  public processPending(): { add: IBuildPlanInsight[]; remove: IBuildPlanInsight[] } {
    let remove: IBuildPlanInsight[] = []
    let add: IBuildPlanInsight[] = []

    const existing: IBuildPlanInsight[] = this.findExisting()
    add = this.findToAdd(existing)
    if (this.removeIsMissing.includes(this.tool)) {
      remove = this.findToRemove(existing)
    }

    return {
      add,
      remove,
    }
  }

  private findExisting(): IBuildPlanInsight[] {
    return this.pending.filter((issue: IBuildPlanInsight) => {
      return this.inStore.some((i) => _.isEqual(_.omit(i, ['id']), issue))
    })
  }

  private findToAdd(existing: IBuildPlanInsight[]): IBuildPlanInsight[] {
    return this.pending.filter((issue: IBuildPlanInsight) => {
      return !existing.some((i) => _.isEqual(i, issue))
    })
  }

  private findToRemove(existing: IBuildPlanInsight[]): IBuildPlanInsight[] {
    return this.inStore.filter((issue: IBuildPlanInsight) => {
      return issue.tool === this.tool && !existing.some((i) => _.isEqual(i, _.omit(issue, ['id'])))
    })
  }
}
