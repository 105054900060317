
import Component from 'vue-class-component'
import { SortOrders } from '@/types/SortModes'
import { TenantAdminContentTableMixin } from '@/components/layout/admin/sites/mixins/TenantAdminContentTableMixin'
import { TableHeader } from '@/types/Sites/TenantAdminContentTableHeader'
import { TableCell } from '@/types/Sites/TenantAdminContentTableCell'
import { TenantAdminStressRelief } from '@/types/Sites/TenantAdminStressRelief'
import TenantAdminRow from '@/components/layout/admin/sites/contentTable/TenantAdminRow.vue'
import TenantAdminHeader from '@/components/layout/admin/sites/contentTable/TenantAdminHeader.vue'
import StressReliefProfileData from '@/components/layout/admin/sites/StressReliefProfileData.vue'

const DATA_MOCK: TenantAdminStressRelief[] = [
  {
    file: 'AAAAAAA_0000060_M2015_Ar_00114_pa_D',
    id: '1',
    name: 'Stress relief profile 1',
    downloadLink: '1',
    profileData: [
      {
        temperature: 12,
        time: 15,
      },
      {
        temperature: 12,
        time: 15,
      },
      {
        temperature: 12,
        time: 15,
      },
      {
        temperature: 12,
        time: 15,
      },
      {
        temperature: 12,
        time: 15,
      },
    ],
  },
  {
    file: 'AAAABBB_0000060_M2015_Ar_00114_pa_D',
    id: '2',
    name: 'Stress relief profile 2',
    downloadLink: '2',
    profileData: [
      {
        temperature: 12,
        time: 15,
      },
      {
        temperature: 12,
        time: 15,
      },
      {
        temperature: 12,
        time: 15,
      },
      {
        temperature: 12,
        time: 15,
      },
      {
        temperature: 12,
        time: 15,
      },
    ],
  },
  {
    file: 'AAAACCC_0000060_M2015_Ar_00114_pa_D',
    id: '3',
    name: 'Stress relief profile 3',
    downloadLink: '3',
    profileData: [
      {
        temperature: 12,
        time: 15,
      },
      {
        temperature: 12,
        time: 15,
      },
      {
        temperature: 12,
        time: 15,
      },
      {
        temperature: 12,
        time: 15,
      },
      {
        temperature: 12,
        time: 15,
      },
    ],
  },
]

@Component({
  components: { TenantAdminHeader, TenantAdminRow },
})
export default class TenantAdminStressReliefProfile extends TenantAdminContentTableMixin {
  mounted() {
    this.setupHeaders()
    this.setupRows()
  }

  setupHeaders() {
    this.headers = [
      {
        headerName: this.$root.$t('tenantAdminContentTableHeaders.stressReliefProfile.name').toString(),
        cols: 3,
        isSortCriteria: true,
        sortOrder: SortOrders.Descending,
      },
      {
        headerName: this.$root.$t('tenantAdminContentTableHeaders.stressReliefProfile.file').toString(),
        cols: 3,
        isSortCriteria: false,
        sortOrder: null,
      },
      {
        headerName: this.$root.$t('tenantAdminContentTableHeaders.stressReliefProfile.profile').toString(),
        cols: 5,
        isSortCriteria: false,
        sortOrder: null,
      },
      {
        headerName: '',
        cols: 1,
        isSortCriteria: false,
        sortOrder: null,
      },
    ]
  }

  setupRows() {
    this.rows = this.generateRows(DATA_MOCK)
  }

  generateColumns(relief: TenantAdminStressRelief): TableCell[] {
    return [
      {
        // Name
        value: relief.name,
        text: relief.name,
        textLink: relief.downloadLink,
        cols: 3,
        cellName: this.$root.$t('tenantAdminContentTableHeaders.stressReliefProfile.name').toString(),
      },
      {
        // Profile
        value: relief.file,
        text: relief.file,
        cols: 3,
        cellName: this.$root.$t('tenantAdminContentTableHeaders.stressReliefProfile.file').toString(),
      },
      {
        // Simulation-Ready
        value: relief.profileData,
        component: StressReliefProfileData,
        cols: 5,
        cellName: this.$root.$t('tenantAdminContentTableHeaders.printStrategyList.simulationReady').toString(),
      },
      {
        // Controls
        value: null,
        isControlsCell: true,
        cols: 1,
      },
    ]
  }

  sortChanged(header: TableHeader) {
    this.onSortChanged(header)
  }
}
