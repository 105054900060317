
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { DetailsPanelExpansionItem } from '@/types/FileExplorer/DetailsPanelExpansionItem'
import PartListItem from '@/components/layout/buildPlans/addPart/PartListItem.vue'

@Component({
  components: {
    PartListItem,
  },
})
export default class ExpansionItemsList extends Vue {
  @Prop() titleToDisplay: string
  @Prop() items: DetailsPanelExpansionItem[]
  @Prop() icon: string
  @Prop({ default: false }) disableHyperlinks: boolean

  onPreviewItem({ itemPreviewUrl, itemRouterName }) {
    const route = {
      name: itemRouterName,
      params: {
        id: itemPreviewUrl,
      },
    }

    // @ts-ignore
    this.$router.safePush(route)
  }
}
